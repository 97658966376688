import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rate-plan-template',
  templateUrl: './rate-plan-template.component.html',
  styleUrls: ['./rate-plan-template.component.scss']
})
export class RatePlanTemplateComponent implements OnInit {
  @Input() sessionData:any;
  @Input() party:any;
  slot:any;
  partyData: any;
  perItemEditable: any;
  OperationCurrency: any = '$';

  constructor() { }

  ngOnInit(): void {
    this.slot = this.sessionData;
    this.partyData = this.party;

  }

}
