import { Injectable, inject } from "@angular/core";
import { HostInfo, LoginAuthInfo, PropertyInfo } from "../interfaces/login";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ReservePOSSessionService {
    router = inject(Router);

    loginAuthInfo: LoginAuthInfo = {} as LoginAuthInfo;
    hostInfo: HostInfo | undefined;
    propertyInfo: PropertyInfo | undefined;
    private quickSelectGuestList: Map<number, any> = new Map();
    tempGuestStartIndex: number = 1;

    private userPreferences: UserPreference;

    get UserPreference(): UserPreference {
        if (!this.userPreferences) {
            let _userPreferences = sessionStorage.getItem('respos_userpreference');
            this.userPreferences = this.getParsedJsonValue(_userPreferences) || {};
        }
        return this.userPreferences;
    }

    setQuickGuestList(guest){

        if(this.quickSelectGuestList?.has(guest?.Id || guest?.ContactId)){
            return;
        }
        let guestDetail = {...guest}

        delete guestDetail.CoverTypeId;
        delete guestDetail.BookingTypeId;
        delete guestDetail.coverType;
        delete guestDetail.bookingType;
        this.quickSelectGuestList.set(guestDetail.Id || guestDetail.ContactIdentifier, guestDetail);
    }

    getQuickGuestList(){
        return this.quickSelectGuestList;
    }

    clearQuickList(){
        this.quickSelectGuestList.clear();
    }

    updateUserPreference(property: UserPreferences, value: any) {
        if(!this.userPreferences){
            let _userPreferences = sessionStorage.getItem('respos_userpreference');
            this.userPreferences = this.getParsedJsonValue(_userPreferences) || {};
        }
        this.userPreferences[property.toString()] = value;
        sessionStorage.setItem('respos_userpreference', JSON.stringify(this.userPreferences))
    }

    getParsedJsonValue(jsonString: string): any {
        try {
            return JSON.parse(jsonString);
        } catch (err) {
            return {}
        }
    }

    logout() {
        let language = sessionStorage.getItem('language');
        sessionStorage.clear();
        this.router.navigate(['login'], { queryParams: { language } })
    }
}


type UserPreference = {
    listingView: 'short' | 'detail';
    enableVirtualKeyboard: 'yes' | 'no';
    selectedCategories: number[];
}

export enum UserPreferences {
    ListingView = 'listingView',
    EnableVirtualKeyboard = 'enableVirtualKeyboard',
    SelectedCategories = 'selectedCategories'
}