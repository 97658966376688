<div class="confirmation-popup">
  <!-- <mat-dialog-content class="p-0 m-0 confirmation-popup__dialog-content"> -->
  <div *ngIf="!forConfirmationEmail">
    <div *ngIf="showAlert" class="confirmation-popup__show-alert">
      <i class="icon-warning seat-icon-color alert-icon"></i>
    </div>
    <div class="confirmation-popup__checkiconParent" *ngIf="hideTitle">
      <svg class="confirmation-popup__checkmark" viewBox="0 0 52 52">
        <circle  cx="26" cy="26" r="25" fill="white" />
        <path  fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
      <span class="confirmation-popup__message seat-alert-messages" id="confirmationMsg">{{confirmationMessage}}</span>
    </div>
    <div class="confirmation-popup__apiresponsediv" *ngIf="!hideTitle">
      <p class="confirmation-popup__message seat-alert-messages" id="confirmationMsg">{{confirmationMessage}}</p>
    </div>
  </div>
  <div *ngIf="forConfirmationEmail" class="confirmation-popup__email-message">
    <!-- <div class="confirmation-popup__guest-mail-details">
      <p class="seat-labels">{{'confirmationcode' | translate}}</p>
      <span class="seat-text-fields">{{data[0].confirmationCode}}</span>
    </div>
    <div class="confirmation-popup__guest-mail-details">
      <p class="seat-labels">{{'guestName' | translate}}</p>
      <span class="seat-text-fields">{{data[0].firstName}}  {{data[0].lastName}}</span>
    </div>
    <div class="confirmation-popup__guest-mail-details">
      <dynamic-form [config]="emailConfig" #form="dynamicForm" >
      </dynamic-form>
    </div> -->
    <app-prompt-host-for-confirmation [bookingContacts]="data?.[0]?.bookingContacts " [contactId]="data[0].contactId" [selectedContactId]="data?.[0]?.selectedContactId" [sessionType]="data?.[0]?.sessionType" ></app-prompt-host-for-confirmation>
  </div>
  <div *ngIf="listOfItem?.length > 0" style="height: 80%;margin-top: 10px;">
    <ng-scrollbar track="vertical">
      <div [title]="data" class="confirmation-popup__dataList" *ngFor="let data of listOfItem;let i=index">
          <span>{{data}}</span>
          <div *ngIf="listOfItem?.length-1 != i" style="border:1px solid #00000040;width: 305px;margin-left: 185px;"></div>
      </div>
    </ng-scrollbar>
  </div>
  <!-- </mat-dialog-content> -->
  <!-- <mat-dialog-actions class="dialog_actions_style w-100 m-0  d-block text-center">
    <activities-app-button  [buttontype]="buttonValueOk" (valueChange)='close($event)'></activities-app-button>
  </mat-dialog-actions> -->
</div>
