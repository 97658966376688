import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PartyService } from '@app/shared/services/party.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { uniqBy } from "lodash";
import { Observable } from 'rxjs/internal/Observable';
import { getMappedCreditCard } from '../rate-plan/rate-plan.component';

@Component({
  selector: 'app-payee-info',
  templateUrl: './payee-info.component.html',
  styleUrls: ['./payee-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PayeeInfoComponent implements OnInit {
  @Input() payeeDetails;
  @Input() bookingCreditCard;
  @Input() ratePlanObject;
  payeeInfoConfig: FieldConfig[] = [];
  @Input() bookingDataId = null;
  @Input() bookingData;
  @Input() creditCards;
  @Input() preSelectedCards = [];
  availableCards: any;
  cardHeader = ['', 'cardDetails', 'expiryDate'];
  @ViewChild('payerForm') payerForm: DynamicFormComponent;
  selectedCard = null;
  @ViewChild('cardInfoPopOver') public cardInfoPopOver: NgbPopover;
  @Output() selectedPayeeCardInfo: EventEmitter<any> = new EventEmitter<any>();
  constructor(public partyService: PartyService, private ts: TranslateService) { }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.ratePlanObject) {
      this.payeeDetails = uniqBy(this.payeeDetails, 'id');
      this.intializePayeeConfig(changes);
      }
    }
  

  ngDoCheck() {
  }

  intializePayeeConfig(changes) {
    this.payeeInfoConfig = [
      {
        type: 'select',
        name: 'payee',
        label: this.ts.instant('Payer'),
        class: 'payee-dropdown',
        options: this.payeeDetails,
        cellClick: this.setPayeeId.bind(this),
        value: this.payeeDetails?.length && this.bookingData?.LastPaidBy && !this.partyService.PayeeId ? this.bookingData?.LastPaidBy : (this.partyService.PayeeId || (this.payeeDetails?.length ? this.payeeDetails[0].id : null)),
        validation: [Validators.required],
        showErrorText: true,
        errorMessage: this.ts.instant('fieldRequired')
      }
    ];
 
    if(!this.partyService.PayeeId )
      this.partyService.PayeeId= this.payeeInfoConfig[0].value;
    if(!changes.bookingCreditCard?.currentValue){
      
    this.selectedPayeeCardInfo.emit({})
    }
  }

  setPayeeId(data) {
    this.partyService.PayeeId = this.payerForm?.form.controls.payee.value;

    if(this.creditCards?.length){
      const selectedCreditCard = getMappedCreditCard(this.creditCards, this.partyService.PayeeId, null);
      this.selectedCard = selectedCreditCard?.CreditCardId;
      this.selectedPayeeCardInfo.emit(selectedCreditCard || {});   
    }else{
      this.selectedPayeeCardInfo.emit({})
    }
  }

  generateOptions() {
    return

  }

  loadOptionsForPayee() {
    if(!this.cardInfoPopOver || !this.cardInfoPopOver.isOpen()){
      let _payeeContactId = this.payerForm?.form?.controls?.payee.value;
      this.partyService.fetchCardInfo(_payeeContactId || null, (this.bookingDataId || null)).subscribe((data) => {
        this.availableCards = _payeeContactId ? data.filter(({ContactId}) => ContactId === _payeeContactId) : data;
        let recentlyUsedCard =  this.availableCards?.find(card => card.RecentlyUsed);
        if(recentlyUsedCard){
          this.selectedCard = recentlyUsedCard.CreditCardId;
          this.selectedPayeeCardInfo.emit(recentlyUsedCard);   
        } else if(this.bookingCreditCard){
          this.selectedCard =this.bookingCreditCard.creditCardId;
        } else if(this.partyService?.PaymentCard?.length && this.partyService.PaymentCard[0].CreditCardId){
          this.selectedCard =  this.partyService.PaymentCard[0].CreditCardId;;
        } else {
          let defaultCard = this.availableCards?.find(card => card.IsDefault) || this.availableCards?.[0];
          if(defaultCard){
            this.selectedCard = defaultCard.CreditCardId
            this.selectedPayeeCardInfo.emit(defaultCard);   
          }
        }
        this.partyService.PayeeId = this.payerForm?.form.controls.payee.value;
        if (this.preSelectedCards?.length) {
          this.preSelectedCards.forEach(selectedCard => {
            if (selectedCard.PayeeContactIds.includes(this.payerForm?.form?.controls?.payee.value)) {
              if(!(selectedCard.CreditCardId && this.availableCards?.some(f=>f?.CreditCardId && f.CreditCardId == selectedCard.CreditCardId))){
                this.availableCards.push(selectedCard);
              }
            }
          });
        }
        // this.availableCards.forEach(card => {
        //   card.selected = this.bookingData?.PaidCard ? this.bookingData.PaidCard : this.partyService?.PaymentCard?.length ? true : card.IsDefault;
        //   if (card.selected) {
        //     this.selectedPayeeCardInfo.emit(card)
        //   }
        // })
      })
    }
  }

  updateCard(card){
    let selectedCard = this.availableCards.find(cardData => cardData.CreditCardId == this.selectedCard)
    selectedCard.selected = true;
    this.selectedPayeeCardInfo.emit(selectedCard)
    this.cardInfoPopOver.close();
  }

}
