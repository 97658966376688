<section class="errorpop-container">
  <ng-container [ngSwitch]="data?.type">
    <ng-template [ngSwitchCase]="alertType.Success">
      <div class="pop-head">
        <div class="alert-icon">
          <i  aria-hidden="true"  class="icon-confirmed"></i>
        </div>
        <div class="alert-type">
          <label class="inner-header">{{success}}</label>
        </div>
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="alertType.WellDone">
      <div class="pop-head">
        <div class="alert-icon">
          <i  aria-hidden="true" class="icon-confirmed"></i>
        </div>
        <div class="alert-type">
          <label class="inner-header">{{wellDone}}</label>
        </div>
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="alertType.Done">
      <div class="pop-head">
        <div class="alert-icon">
          <i  aria-hidden="true" class="icon-confirmed"></i>
        </div>
        <div class="alert-type">
          <label class="inner-header">{{done}}</label>
        </div>
      </div>
    </ng-template>

    <ng-template [ngSwitchCase]="alertType.Warning">
      <div class="pop-head">
        <div class="alert-icon">
          <i  aria-hidden="true" class="icon-alert"></i>
        </div>
        <div class="alert-type">
          <label class="inner-header">{{warning}}</label>
        </div>
      </div>
    </ng-template>

    <ng-template [ngSwitchCase]="alertType.Attention">
      <div class="pop-head">
        <div class="alert-icon">
          <i  aria-hidden="true" class="icon-warning-info-icon"></i>
        </div>
        <div class="alert-type">
          <label class="inner-header">{{attention}}</label>
        </div>
      </div>
    </ng-template>

    <ng-template [ngSwitchCase]="alertType.Error">
      <div class="pop-head">
        <div class="alert-icon">
          <i  aria-hidden="true" class="icon-warning-info-icon"></i>
        </div>
        <div class="alert-type">
          <label class="inner-header">{{error}}</label>
        </div>
        <div *ngIf="data.isUnhandledError" class="error-alert-message mt-4 text-center">
          <span class="white-space-nowrap font-weight-bolder font-size-12px">{{Caption_User_Notification_ErrorMessage}}</span>
        </div>
      </div>
    </ng-template>

    <ng-template [ngSwitchCase]="alertType.Info">       
        <div class="pop-head">
            <div class="alert-icon">
              <i  aria-hidden="true" class="icon-warning-icon"></i>
            </div>
            <div class="alert-type">
              <label class="inner-header" *ngIf="!data.header">{{info}}</label>
              <label class="alert-header" *ngIf="data.header">{{data.header}}</label>
            </div>
          </div>
    </ng-template>

    <ng-template [ngSwitchCase]="alertType.AccessDenied">       
      <div class="pop-head">
          <div class="alert-icon">
            <i  aria-hidden="true" class="icon-warning-icon"></i>
          </div>
          <div class="alert-type">
            <label class="inner-header">{{accessDenied}}</label>
          </div>
        </div>
  </ng-template>

  <ng-template [ngSwitchCase]="alertType.Confirmation">
    <div class="pop-head">
      <div class="alert-icon">
        <i  aria-hidden="true" class="icon-confirmation"></i>
      </div>
      <div class="alert-type">
        <label class="inner-header">{{confirmation}}</label>
      </div>
    </div>
  </ng-template>
  </ng-container>
  <div class="pop-contents mt-4">
    <mat-dialog-content>
      <div *ngIf="data.isUnhandledError" class="reference-text-id text-center">
        <span class="white-space-nowrap font-size-11px">{{data.referenceIdText}}</span>
        <div class="ag_display--flex align-items-center">
          <div class="pop-up-message ellipsis" [innerHTML]="data.message"></div>
          <i aria-hidden="true"  class="icon-copy ag_cursor--pointer ag_ml--2" (click)="copyToClipboard(data)"></i>
        </div>
     </div>
      <div  *ngIf="!(data.isUnhandledError)" class="pop-message" [innerHTML]="data.message"></div>
    </mat-dialog-content>
  </div>
  <mat-dialog-actions class="actions">

    <ng-container [ngSwitch]="data?.buttontype">
      <ng-template [ngSwitchCase]="buttonType.Continue">
        <button mat-button #btnRef="matButton" class="ag_button--primary" (click)="DialogClose('CONTINUE')">{{continueText}}</button>
      </ng-template>
      <ng-template [ngSwitchCase]="buttonType.ContinueCancel">
        <button mat-button #btnRef="matButton" class="ag_button--primary" (click)="DialogClose('CONTINUE')">{{continueText}}</button>
        <button mat-button class="ag_button--secondary" (click)="DialogClose('CANCEL')">{{cancelText}}</button>
      </ng-template>
      <ng-template [ngSwitchCase]="buttonType.Ok">
          <button mat-button #btnRef="matButton" class="ag_button--primary" (click)="DialogClose('CONTINUE')">{{okText}}</button>
        </ng-template>
        <ng-template [ngSwitchCase]="buttonType.OkCancel">
          <button mat-button #btnRef="matButton" class="ag_button--primary" (click)="DialogClose('CONTINUE')">{{okText}}</button>
          <button mat-button class="ag_button--secondary" (click)="DialogClose('CANCEL')">{{cancelText}}</button>
        </ng-template>
        <ng-template [ngSwitchCase]="buttonType.YesNo">
          <button mat-button #btnRef="matButton" class="ag_button--primary" (click)="DialogClose('YES')">{{YES}}</button>
          <button mat-button class="ag_button--secondary" (click)="DialogClose('NO')">{{NO}}</button>
        </ng-template>
        <ng-template [ngSwitchCase]="buttonType.YesNoCancel">
          <button mat-button #btnRef="matButton" class="ag_button--primary" (click)="DialogClose('YES')">{{YES}}</button>
          <button mat-button class="ag_button--secondary" (click)="DialogClose('NO')">{{NO}}</button>
          <button mat-button class="ag_button--tertiary" (click)="DialogClose('CANCEL')">{{cancelText}}</button>
          </ng-template>
    </ng-container>

  </mat-dialog-actions>
</section>
