import { ElasticTopExceptionDetails, ExceptionDetails, FilterData, TraceDetails, TransactionDetails} from "src/app/common/logviewer/Models/log-viewer-models";
import { GuestExportNotificationModel } from 'src/app/common/shared/shared.modal';

export class CommonDataAwaiters {

  private static defaultFunc = async function () {
    return null;
  };

  public static GetAllExceptions: (filterData: FilterData) => Promise<ExceptionDetails[]> = CommonDataAwaiters.defaultFunc;
  public static GetTopUserException : (filterData: FilterData) => Promise<ElasticTopExceptionDetails[]> = CommonDataAwaiters.defaultFunc;
  public static GetTopFrequentException : (filterData: FilterData) => Promise<ElasticTopExceptionDetails[]> = CommonDataAwaiters.defaultFunc;

  public static GetAllTransactions: (filterData: FilterData, recordStart: number) => Promise<TransactionDetails[]> = CommonDataAwaiters.defaultFunc;
  public static GetTopUserTransactions : (filterData: FilterData) => Promise<ElasticTopExceptionDetails[]> = CommonDataAwaiters.defaultFunc;
  public static GetTopFrequentTransactions : (filterData: FilterData) => Promise<ElasticTopExceptionDetails[]> = CommonDataAwaiters.defaultFunc;
  public static GetAllTransactionsForUser: (filterData: FilterData, userName: string) => Promise<TransactionDetails[]> = CommonDataAwaiters.defaultFunc;
  public static GetAllTransactionsForScreen: (filterData: FilterData, screenName: string) => Promise<TransactionDetails[]> = CommonDataAwaiters.defaultFunc;

  public static GetAllPerformance: (filterData: FilterData, seconds: number) => Promise<TransactionDetails[]> = CommonDataAwaiters.defaultFunc;

  public static GetTraceDetails: (traceId: string) => Promise<TraceDetails[]> = CommonDataAwaiters.defaultFunc;

  public static GetIntakeDetails: () => Promise<boolean> = CommonDataAwaiters.defaultFunc;

  public static GetAllContactLogType: (includeinactive: boolean) => Promise<any> = CommonDataAwaiters.defaultFunc;

  public static GetAllLogType: (includeinactive: boolean) => Promise<any> = CommonDataAwaiters.defaultFunc;

  public static CreateLogType: (logtype) => Promise<any> = CommonDataAwaiters.defaultFunc;

  public static EditLogType: (logtype, id) => Promise<any> = CommonDataAwaiters.defaultFunc;

  public static UpdateLogType: (inputdata) => Promise<any> = CommonDataAwaiters.defaultFunc;

  public static DeleteLogType: (id: number) => Promise<any> = CommonDataAwaiters.defaultFunc;

  public static CheckForAccess: () => Promise<any> = CommonDataAwaiters.defaultFunc;

  public static isViewOnly: () => Promise<any> = CommonDataAwaiters.defaultFunc;

  public static GetConfirmationSetting: () => Promise<boolean> = CommonDataAwaiters.defaultFunc;
  public static IsContactLogViewOnly: () => Promise<boolean> = CommonDataAwaiters.defaultFunc;

  public static SendGuestExportEmail: (details:GuestExportNotificationModel) => Promise<boolean> = CommonDataAwaiters.defaultFunc;

  public static GetAllVipType : (includeInactive: boolean) => Promise<any> = CommonDataAwaiters.defaultFunc;

  public static GetNextListOrderofVipType : () => Promise<any>  = CommonDataAwaiters.defaultFunc;

  public static CreateVipType : (vipType) => Promise<any> = CommonDataAwaiters.defaultFunc;

  public static UpdateVipType : (vipType, id) => Promise<any> = CommonDataAwaiters.defaultFunc;

  public static DeleteVipType : (id) => Promise<any> = CommonDataAwaiters.defaultFunc;

  public static DragDropVipType : (fromOrder, toOrder, includeInActive) => Promise<any> = CommonDataAwaiters.defaultFunc;
  
  public static GetVipTypeBreakpoint : () => Promise<any> = CommonDataAwaiters.defaultFunc;
  
}
