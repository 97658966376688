import { Pipe } from "@angular/core";
import { PartyState } from "../constants/commonenums";
import { WristbandState } from "../constants/globalConstants";

@Pipe({
    name: 'attendeesFilter'
})
export class AttendeesFilter {

    transform(attendees, searchText): any {
        searchText = searchText.toLowerCase();
        return attendees.filter(attendee => validStates.includes(attendee.State)
            && ((attendee.Contact.FirstName && attendee.Contact.FirstName?.toLowerCase().indexOf(searchText) != -1)
                || (attendee.Contact.LastName && attendee.Contact.LastName?.toLowerCase().indexOf(searchText) != -1)
                || (((attendee.Contact.FirstName + (attendee.Contact.LastName ? ' ' + attendee.Contact.LastName : '')).toLowerCase()).indexOf(searchText) != -1)
                || (attendee.Contact.PhoneNumber && attendee.Contact.PhoneNumber?.toLowerCase().indexOf(searchText) != -1)
                || (attendee?.GuestItineraryMapping?.length > 0 && // Ensure GuestItineraryMapping array has at least one element
                    (
                        attendee?.GuestItineraryMapping?.some(wristBand => wristBand.BookedSessionId === attendee.BookedSessionId && wristBand.StatusCode === WristbandState.Active && wristBand.WristbandId?.toString()?.toLowerCase() == searchText)
                    ))
                || (attendee.ConfirmationCode.toLowerCase().indexOf(searchText) != -1)
                || (attendee.BookingReferenceId.toLowerCase().indexOf(searchText) != -1)
                || this.filterByBookingContact(attendee.BookingContacts, searchText)
                || this.filterByAssetCode(attendee.BookingContactAddonItems, searchText)
            )
        );
    }
    filterByBookingContact(BookingContacts, searchText: string): boolean {
        if (BookingContacts?.length) {
            return BookingContacts.some(attendee => ((attendee.FirstName && attendee.FirstName?.toLowerCase().indexOf(searchText) != -1)
                || (attendee.LastName && attendee.LastName?.toLowerCase().indexOf(searchText) != -1)
                || (((attendee.FirstName + (attendee.LastName ? ' ' + attendee.LastName : '')).toLowerCase()).indexOf(searchText) != -1)
                || (attendee.PhoneNumber && attendee.PhoneNumber?.toLowerCase().indexOf(searchText) != -1)))
        } 
        return false;
    }

    filterByAssetCode(BookingContactAddonItems, searchText:string):boolean{
        if(BookingContactAddonItems?.length){
            return BookingContactAddonItems.some(attendee => attendee.AddonsRentalNotes?.RentalAssetCodes?.includes(searchText));
        }
        return false;
        
    }
}

const validStates = [
    PartyState.Pending,
    PartyState.Seated,
    PartyState.Left,
    PartyState.Cancelled,
    PartyState.OutForDelivery,
    PartyState.Collected,
    PartyState.Delivered
  ];