import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMPONENTINPUT } from '@app/popup-module/popup.service';

@Component({
  selector: 'app-reservation-guest-wrapper',
  templateUrl: './reservation-guest-wrapper.component.html',
  styleUrls: ['./reservation-guest-wrapper.component.scss']
})
export class ReservationGuestWrapperComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData, @Inject(COMPONENTINPUT) public data) { }

  ngOnInit(): void {
  }

}
