import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShopRoutingModule } from './shop-routing.module';
import { ShopComponenet } from './shop.component';
import { ViewCategoriesComponenet } from './view-categories/view-categories.component';
import { SharedModule } from '../../shared/shared.module';
import { OrderSummaryComponenet } from './order-summary/order-summary.component';
import { AssignCommissionPopupComponent } from './assign-commission-popup/assign-commission-popup.component';
import { ShopDialogPopUp } from './shop-dialog-popup/shop-dialog-popup.component';
import { ShippingInfoComponent } from './shippinginfo/shipping-info.component';
import { RetailTransactionsComponent } from './view-categories/retail-transactions/retail-transactions.component';
import { ShopItemDetailsComponent } from './shop-common-components/shop-item-details/shop-item-details.component';
import { ShopDetailsSectionComponent } from './shop-common-components/shop-details-section/shop-details-section.component';
import { ShopServiceItemsComponent } from './shop-common-components/shop-service-items/shop-service-items.component';
import { ShopServiceitemSummaryComponent } from './shop-common-components/shop-serviceitem-summary/shop-serviceitem-summary.component';
import { RetailItemsComponent, ShowItemPricePipe, CartCountPipe, showOriginalPricePipe, ShowDiscountPricePipe, SubtotalPricePipe, GetTimefromDatePipe } from './view-categories/retail-items/retail-items.component';
import { VoidReasonComponent } from './view-categories/void-reason/void-reason.component';
import { TicketDetailsComponent } from './shop-common-components/ticket-details/ticket-details.component';
import { RetailTableComponent } from './retail-table/retail-table.component';
import { ShowPaymentAmount } from './show-payment-amount.pipe';
import { ShopPaymentComponent } from './shop-payment/shop-payment-component';
import { RetailTransactionService } from './view-categories/retail-transactions/retail.transactions.service';
import { ApplyDiscountComponent } from './apply-discount/apply-discount.component';
import { ShopBussinessService } from './shop-business.service';
import { ApplyDiscountService } from './apply-discount/appply-discount.service';
import { ReceiptBusinessService } from './receipt/business/receipt-business.service';
import { AssignCommissionService } from './assign-commission-popup/assign-commission-popup.service';
import { CheckinnerboolPipe } from '../shared/pipes/checkinnerbool.pipe';
import { IfbooleanPipe } from '../shared/pipes/ifboolean.pipe';
import { CategoryPipe } from '../shared/pipes/category-linking-pipe.pipe'; 
import { RetailChipsAutocompleteExample } from '../shared/multiplesearch/multiplesearch.component';
import { OrderSummaryPipe } from '../shared/pipes/order-summary.pipe';
import { RetailImgThumbnailComponent } from '../shared/img-thumbnail/img-thumbnail.component';
import { RetailLocalization } from '../common/localization/retail-localization';
import { SessionManagerService } from '../common/services/session-manager.service';
import { BreakPointAccess } from '../shared/service/breakpoint.service';
import { UserAlerts } from '../common/config/alerts-config';
import { UserMachineConfigurationService } from '../common/services/user-machine-configuration.service';
import { ImageProcessorService } from '../shared/service/image-processor-service';
import { ReceiptService } from './receipt/business/receipt.service';
import { FormatText } from '../shared/pipes/formatText-pipe.pipe';
import { MoreSectionServiceService } from '../shared/more-section/more-section-service.service';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { AuthGuardService } from '../common/services/auth-guard.service';
import { RetailServiceRegistry } from '../shared/service/base.service';
import { SPAConfig } from '../common/config/SPA-config';
import { HttpServiceCall } from '../shared/service/http-call.service';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { RetailService } from '../retail.service';
import { HttpRequestService } from '../shared/service/http-request.service';
import { MessageService } from '../shared/service/messageservice.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UnpaidPlayersComponent } from './view-categories/unpaid-players/unpaid-players.component';
import { DatePickerComponent } from '../shared/date-picker/date-picker.component';
import { ButtonComponent } from '../shared/button/button.component';
import { MaterialModule } from '../material-module';
import { TablevirtualscrollComponent } from '../shared/tablevirtualscroll/tablevirtualscroll.component';
import { checkorderExistPipe } from '../shared/tablevirtualscroll/pipes/checkobjexist.pipe';
import { RetailImageUploaderComponent } from '../shared/image-uploader/image-uploader.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { LocalizeDateTimePipe } from '../common/localization/localize-dateTime.pipe';
import { numFormat } from '../shared/utilities/num-formatter.directive';
import { RetailSharedModule } from '../shared/retail-shared.module';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { RetailToggleSwitchModule } from '../retail-toggle-switch/retail-toggle-switch.module';
import { RetailGiftCardsComponent } from './view-categories/retail-gift-cards/retail-gift-cards.component';
import { RecentSearchesComponent } from './view-categories/retail-gift-cards/recent-searches/recent-searches.component';
import { GiftCardDetailsComponent } from './view-categories/retail-gift-cards/gift-card-details/gift-card-details.component';
import { RetailGiftCardsFormComponent } from './view-categories/retail-gift-cards-form/retail-gift-cards-form.component';
import { GiftCardPaymentSectionComponent } from './order-summary/gift-card-payment-section/gift-card-payment-section.component';
import { GiftCardPaymentPopupComponent } from './gift-card-payment-popup/gift-card-payment-popup.component';
import { CMSPatronSelectionDialogComponent } from './shop-common-components/cmspatron-selection-dialog/cmspatron-selection-dialog.component';
import { PropertySettingDataService } from '../sytem-config/property-setting.data.service';

import { TransationsComponent } from './view-categories/transations/transations.component';
import { ReprintAvancedSearchComponent } from './retail-table/reprint-advanced-search/reprint-advanced-search.component';
import { CommonCommentComponent } from './view-categories/common-comment/common-comment.component';
import { CaddyShackBusinessService } from './caddyshack/caddyshack-business.service';
import { CaddyShackService } from './caddyshack/caddyshack.service';
import { ReturnSettlementInfoDialogComponent } from './shop-common-components/return-settlement-info-dialog/return-settlement-info-dialog.component';
import { RetailFunctionalityService } from '../shared/service/retail-functionality.service';
import { RetailFunctionalityBusiness } from '../shared/business/retail-functionality.business';
import { PaymentComponent } from '../payment/payment.component';
import { paymentMessageComponent } from './paymentMessage/paymentMessage.component';
import { RetailCartComponent } from './view-categories/retail-cart/retail-cart.component';
import { FinancialBinHelper } from '../shared/business/FinancialBin-business';
import { RetailRevenuePostingLogsComponent } from './view-categories/retail-revenue-posting-logs/retail-revenue-posting-logs.component';
import { RetailTransactionsLogComponent } from './view-categories/retail-transactions-log/retail-transactions-log.component';
import { SaleChitBusinessService } from './salechit/salechit-business.service';
import { SaleChitService } from './salechit/salechit.service';
import { PaymentMethodBusiness } from '../retail-payment-method/payment-methods.business';
import { ShopAddItemComponent } from './shop-common-components/shop-add-item/shop-add-item.component';
import { ShopAddItemBusiness } from './shop-common-components/shop-add-item/shop-add-item.business';
import { MemberSelectionPopupComponent } from '../payment/member-selection-popup/member-selection-popup.component';
import { SelectOutletPopupComponent } from 'src/app/common/shared/shared/select-outlet-popup/select-outlet-popup.component';
import { dataMagineBusiness } from 'src/app/common/data-magine/data-magine-integration/data-magine-integration-business';
import { EformConfigBusiness } from 'src/app/common/eform-config/eform-config.business';
import { DatamagineConfigBusiness } from 'src/app/common/data-magine/data-magine-config/data-magine-config.business';
import { OrphanPaymentsComponent } from './view-categories/orphan-payments/orphan-payments.component';
import { FolioDialogPopupComponent } from './folio-dialog-popup/folio-dialog-popup.component';
import { EmailReceiptComponent } from './email-receipt/email-receipt.component';
import { CanDeactivateShopGuard } from './guards/can-deactivate-shop.guard';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, MY_DATE_FORMATS } from '../common/localization/custom.dateAdapter';
import { WalletCardSelectionDialogComponent } from './shop-common-components/walletcard-selection-dialog/walletcard-selection-dialog.component';
import { SignatureCaptureComponent } from './view-categories/signature-capture/signature-capture.component';
import { EditCustomFeeComponent } from './edit-custom-fee/edit-custom-fee.component';
import { RefundLogComponent } from './view-categories/refund-log/refund-log.component';
import { RetailAdvancedSearchComponent } from '../retail-advanced-search/retail-advanced-search.component';
import { RedirectRouteGuard } from 'src/app/common/services/redirect-route.guard.service';
import { AddCustomFeePopupComponent } from './add-custom-fee-popup/add-custom-fee-popup.component';
import { ViewRetailItemsComponent } from './view-retail-items/view-retail-items.component';
import { discountPipe } from './apply-discount/discount.pipe';
import { RentalItemControlComponent } from './view-categories/rental-item-control/rental-item-control.component';
import { RedeemMultipackPopupComponent } from './redeem-multipack-popup/redeem-multipack-popup.component';
import { ViewNonIntegratedPaymentsComponent } from './view-categories/view-non-integrated-payments/view-non-integrated-payments.component';
import { LockerControlComponent } from './view-categories/locker-control/locker-control.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonSharedModule } from '@app/common/shared/shared/shared.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
    imports: [
        CommonModule,
        ShopRoutingModule,
        SharedModule,
        BsDropdownModule.forRoot(),
        MaterialModule,
        RetailSharedModule,
        TranslateModule,
        HttpClientModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        VirtualScrollerModule,
        RetailToggleSwitchModule,
        ReactiveFormsModule,
        FormsModule,
        CommonSharedModule //Activities - Retail integration (Added CommonSharedModule)
    ],
    declarations: [
        ShippingInfoComponent,
        ShopComponenet,
        ViewCategoriesComponenet,
        OrderSummaryComponenet,
        AssignCommissionPopupComponent,
        RetailTableComponent,
        ShopDialogPopUp,
        RetailTransactionsComponent,
        ShopItemDetailsComponent,
        ShopDetailsSectionComponent,
        ShopServiceItemsComponent,
        ShopServiceitemSummaryComponent,
        RetailItemsComponent,
        VoidReasonComponent,
        CommonCommentComponent,
        TicketDetailsComponent,
        ShowPaymentAmount,
        ApplyDiscountComponent,
        ShopPaymentComponent,
        ShowItemPricePipe,
        CartCountPipe,
        CheckinnerboolPipe,
        IfbooleanPipe,
        CategoryPipe,
        RetailChipsAutocompleteExample,
        OrderSummaryPipe,
        discountPipe,
        RetailImgThumbnailComponent,
        UnpaidPlayersComponent,
        DatePickerComponent,
        ButtonComponent,
        TablevirtualscrollComponent,
        checkorderExistPipe,
        RetailImageUploaderComponent,
        LocalizeDateTimePipe,
        numFormat,
        FormatText,
        RetailGiftCardsComponent,
        RecentSearchesComponent,
        GiftCardDetailsComponent,
        RetailGiftCardsFormComponent,
        GiftCardPaymentSectionComponent,
        GiftCardPaymentPopupComponent,
        RetailAdvancedSearchComponent,
        CMSPatronSelectionDialogComponent,
        TransationsComponent,
        ReprintAvancedSearchComponent,
        ReturnSettlementInfoDialogComponent,
        PaymentComponent,
        paymentMessageComponent,
        RetailCartComponent,
        RetailTransactionsLogComponent,
        RetailRevenuePostingLogsComponent,
        ShopAddItemComponent,
        MemberSelectionPopupComponent,
        showOriginalPricePipe,
        ShowDiscountPricePipe,
        GetTimefromDatePipe,
        SubtotalPricePipe,
        OrphanPaymentsComponent,
        FolioDialogPopupComponent,
        EmailReceiptComponent,
        WalletCardSelectionDialogComponent,
        SignatureCaptureComponent,
        RefundLogComponent,
        ViewRetailItemsComponent,
        EditCustomFeeComponent,
        RefundLogComponent,
        AddCustomFeePopupComponent,
        RentalItemControlComponent,
		RedeemMultipackPopupComponent,
        LockerControlComponent,
		ViewNonIntegratedPaymentsComponent
    ],
    providers: [
        RetailTransactionService,
        ApplyDiscountService,
        ShopBussinessService,
        FinancialBinHelper,
        ReceiptBusinessService,
        CaddyShackBusinessService,
        AssignCommissionService,
        RetailLocalization,
        SessionManagerService,
        BreakPointAccess,
        UserAlerts,
        UserMachineConfigurationService,
        ImageProcessorService,
        ReceiptService,
        CaddyShackService,
        MoreSectionServiceService,
        RetailPropertyInformation,
        AuthGuardService,
        RetailServiceRegistry,
        SPAConfig,
        HttpServiceCall,
        RetailSetupService,
        HttpRequestService,
        MessageService,
        NgxImageCompressService,
        FormatText,
        PropertySettingDataService,
        RetailFunctionalityBusiness,
        RetailFunctionalityService,
        SaleChitBusinessService,
        SaleChitService,
        FinancialBinHelper, PaymentMethodBusiness,
        ShopAddItemBusiness,
        DatamagineConfigBusiness,
        dataMagineBusiness,
        EformConfigBusiness,
        CanDeactivateShopGuard,
        {
            provide: DateAdapter, useClass: CustomDateAdapter
          },
          {
            provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
          },
          RedirectRouteGuard
    ],
    exports: [ShippingInfoComponent, ShowPaymentAmount, ShopPaymentComponent, ShowItemPricePipe,
        CheckinnerboolPipe,
        IfbooleanPipe,
        CategoryPipe,
        RetailChipsAutocompleteExample,
        OrderSummaryPipe,
        discountPipe,
        RetailImgThumbnailComponent,
        DatePickerComponent,
        ButtonComponent,
        RetailImageUploaderComponent,
        LocalizeDateTimePipe,
        numFormat,
        FormatText,
        VirtualScrollerModule,
        PaymentComponent,
        paymentMessageComponent,
        VoidReasonComponent,
        MemberSelectionPopupComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class ShopModule { }
