<div class="parties-grid">
  <div *ngIf="parties?.length > 0" class="parties-grid__expand ">
    <div id="virtual-scroll-{{index}}" class="parties-grid__scroll-block" #scrollingBlock>
      <virtual-scroller [parentScroll]="scrollingBlock" #scroll [items]="parties" id="parties-grid-{{index}}">
        <ng-container *ngFor="let party of scroll.viewPortItems; trackBy: trackFunction;">
          <div class="parties-grid__virtual-grid" *ngIf="party.isShowParty || isNoShowSelected">
            <div id="div_{{party.Id}}" [ngClass]="{'parties-grid__active': party.isSelected}"
                 class="parties-grid__row-seat-register seat-border-color">
              <div class="parties-grid__colone-seat-register seat-secondary-bg-color">
                <div class="parties-grid__button-row" *ngIf="ReservationType.Waitlist != type">
                  <div class="parties-grid__party-details">
                    <span class="icon-VIP" *ngIf="party?.Contact?.IsVip"></span>
                  </div>
                  <div class="parties-grid__party-details">
                    <span class="parties-grid__table-number text-color-white"
                          [ngStyle]="{'background-color': (party.State | backgroundColorFormatter : party.StatusId : generalSettings) }"
                          [class.pointer]="party.State != PartyState.Seated && party.State != PartyState.Left && party.State != PartyState.Cancelled"
                          [ngClass]="{'no-show-selection':party.State == PartyState.NoShowed}"
                          *ngIf="party?.TableIds?.length < 2" (click)="openTable(party,ReservationType.Reservation,true)">
                      {{ party.TableNames?.length && party.TableNames[0] ? (party.TableIds | formattedTableName ): '+'}}
                    </span>
                    <span matTooltipClass="tooltip-class"
                          [ngStyle]="{'background-color': (party.State | backgroundColorFormatter : party.StatusId : generalSettings) }"
                          title="{{party.TableIds | formattedTableName}}" class="icon-link parties-grid__table-number"
                          *ngIf="party?.TableIds?.length > 1"
                          (click)="openTable(party,ReservationType.Reservation,true)"></span>
                  </div>
                  <div class="parties-grid__party-details">
                    <span class="icon-Favourites" *ngIf="party?.Contact?.IsFavorite"></span>
                  </div>
                </div>
                <div class="parties-grid__button-row" *ngIf="ReservationType.Waitlist == type">
                  <div class="parties-grid__party-details">
                    <span class="icon-VIP" *ngIf="party?.Contact?.IsVip"></span>
                  </div>
                  <div class="parties-grid__party-details">
                    <span class="parties-grid__table-number text-color-white"
                    [class.pointer]="party.State != PartyState.Seated || party.State != PartyState.Cancelle"
                          [ngStyle]="{'background-color': (party.State | backgroundColorFormatter : party.StatusId : generalSettings) }"
                          *ngIf="party?.TableIds?.length === 1" (click)="openTable(party,ReservationType.Waitlist)">
                      {{party.TableNames?.length && party.TableNames[0] ? (party.TableIds | formattedTableName ) : '+'}}
                    </span>
                    <span matTooltipClass="tooltip-class" [class.pointer]="party.State != PartyState.Seated || party.State != PartyState.Cancelled"
                          [ngStyle]="{'background-color': (party.State | backgroundColorFormatter : party.StatusId : generalSettings) }"
                          title="{{party.TableIds | formattedTableName}}" class="icon-link parties-grid__table-number"
                          (click)="openTable(party,ReservationType.Waitlist)" *ngIf="party?.TableIds?.length > 1"></span>
                    <button mat-raised-button
                            class="seat-border-color seat-icon-color parties-grid__table-number seat-secondary-bg-color"
                            [ngClass]="{'no-show-selection': party.State == PartyState.NoShowed }"
                            *ngIf="(!party.TableNames?.length || !this.party.TableNames[0]) && party?.TableIds?.length != 1" (click)="openTable(party,ReservationType.Waitlist)">
                          <i class="icon-Union-18"></i>
                    </button>
                  </div>
                  <div class="parties-grid__party-details">
                    <span class="icon-Favourites" *ngIf="party?.Contact?.IsFavorite"></span>
                  </div>
                </div>
              </div>
              <div class="parties-grid__coltwo-seat-register" (click)="SelectParty(party)">
                <ul class="parties-grid__list">
                  <div class="parties-grid__party-info">
                    <li>
                      <div class="parties-grid__party-details">
                        <p class="parties-grid__party-name seat-secondary-text" matTooltipClass="tooltip-class"
                           title="{{party.Contact | formattedName}}">
                          {{party.Contact | formattedName}}
                          <span *ngIf="_as.OTASourceId.includes(party.PartySourceId)" class="party-ota-icon"></span>
                        </p>
                      <span *ngIf="party | PartySourceIconPipe as IconInfo" class="icon-class {{IconInfo.iconclass}}" [matTooltip]="IconInfo.Name | translate"></span>
                     <span *ngIf="party.PageMethod == 0" class="seat-icon-color" [class]="party.PageMethod | communicationIcon "
                              [ngStyle]="{'background-color':party.pageBackgroundColor ? party.pageBackgroundColor : '#E8E8E8', 'border-radius': '4px', 'color': party.pageBackgroundColor ? 'white' : 'black', 'padding': '5px 5px 5px 4px'}"></span>
                        <span *ngIf="party.PageMethod != 0" class="seat-icon-color" [class]="party.PageMethod | communicationIcon "
                               [ngStyle]="{'background-color':party.pageBackgroundColor ? party.pageBackgroundColor : '#E8E8E8', 'border-radius': '4px', 'color': party.pageBackgroundColor ? 'white' : 'black', 'padding': '5px 5px 5px 4px'}"></span>
                        <!--  _as.getCommunicationIcon(party.PageMethod) -->
                      </div>
                    </li>
                    <li *ngIf="party?.Contact?.AliasName" >
                      <div class="seat-tertiary-text content-text-ellipsis"  matTooltipClass="tooltip-full-text"  matTooltip="{{party?.Contact?.AliasName}}">
                        
                          {{'guestBookDetailsAliasName' | translate }}: {{party?.Contact?.AliasName}}
                        
                      </div>
                    </li>
                    <li *ngIf="this._settings?.General?.EngageIntegrationDTO?.IsEnabled && party?.Contact?.TrackMembershipNumber && party?.Contact?.TrackMembershipNumber != ''" >
                      <span class="seat-tertiary-text content-text-ellipsis member-info" [autoClose]="'outside'" container="body" placement="auto"
                      [ngbPopover]="memberInfoPopover"  #memberInfo="ngbPopover" triggers="manual"
                      (click)="fetchMemberDetails(party?.Contact?.TrackMembershipNumber, $event, memberInfo)">                        
                          {{party?.Contact?.IsMemberActive ? ('memberText' | translate) : (('Inactive' | translate) +' '+ ('memberText'| translate)) }}                        
                    </span>
                    </li>
                    <li *ngIf="(type != ReservationType.Waitlist)">
                      <div class="seat-tertiary-text">
                        <span>
                          {{'roomNoText' | translate }}: {{party.RoomNumber != null ? party.RoomNumber : ('NA'| translate)}}
                        </span>
                      </div>
                    </li>
                    <li *ngIf="type == ReservationType.Reservation">
                      <div class="seat-tertiary-text seat-reservationdate-color">
                        {{party.ReservedFor | localizedDate: 'LT'}} / {{party.ReservedFor | localizedDate: 'D MMM, ddd'}}
                      </div>
                    </li>
                    <li class="seat-tertiary-text">
                      {{ party.Contact?.PhoneNumber | phoneNumberFormatter: party?.Contact?.CountryId }}
                    </li>
                    <li class="seat-tertiary-text" *ngIf="!party.Contact?.PhoneNumber && party.Contact?.PhoneNumber2">
                      {{ party.Contact?.PhoneNumber2 | phoneNumberFormatter: party?.Contact?.CountryId2 }}
                    </li>
                    <li *ngIf="type == ReservationType.Reservation && showCreatedDate">
                      <span class="seat-tertiary-text">{{'createdAt' | translate}} : </span>
                      <span class="seat-tertiary-text seat-reservationdate-color">
                        {{party.CreatedAt | localizedDate: 'LT'}} / {{party.CreatedAt | localizedDate: 'D MMM, ddd'}}
                      </span>
                    </li>
                    <li *ngIf="type == ReservationType.Waitlist && party.State != PartyState.Seated">
                      <mat-progress-bar [value]="party.progressBarValue" color="warn"></mat-progress-bar>
                    </li>
                    <li *ngIf="type == ReservationType.Waitlist && party.State != PartyState.Seated"
                        class="parties-grid__mat-spinner">
                      <span class="seat-tertiary-text" *ngIf="party.remainingTime > 0">
                        {{party.remainingTime | formatWaitTime}} / {{ (party.totalSeatingTime * 60) | formatWaitTime}}
                      </span>
                      <span class="seat-tertiary-text" *ngIf="(party.remainingTime < 0 || party.quotedTime == -1)">
                        {{'quoted' | translate}} - {{ (party.totalSeatingTime * 60) | formatWaitTime}}
                        <br />
                        {{'waited' | translate}} - <span class="waitedTime">{{  party.quotedTime + (-party.remainingTime) | formatWaitTime }}</span>
                      </span>
                    </li>
                    <li *ngIf="party.State == PartyState.Seated">
                      <span class="seat-tertiary-text" *ngIf="(party.remainingTime < 0 || party.quotedTime == -1)">
                        {{'estimated' | translate}} - {{party.quotedTime | formatWaitTime}}
                        <br />
                        {{'exceeded' | translate}} - {{ -party.remainingTime | formatWaitTime}}
                      </span>
                      <span class="seat-tertiary-text" *ngIf="(party.remainingTime > 0)">
                        {{'timeAtTable' | translate}} - {{(party.quotedTime - party.remainingTime)  | formatWaitTime}}
                      </span>
                    </li>
  
                    <li *ngIf="party.CommonPackageInformation && party.CommonPackageInformation.length > 0">
                      
                      <div class="CommonPackageInformation-detail">
                        
                        <div class="CommonPackageInformation-item">
                          <span class="seat-tertiary-text">{{'PackageName' | translate }} :</span>
                          <span class="seat-tertiary-text">{{party.CommonPackageInformation[0].PackageName}}</span>
                        </div>
  
                        <div class="CommonPackageInformation-item">
                          <span class="seat-tertiary-text">{{'PackageCode' | translate}} : </span>
                          <span class="seat-tertiary-text">{{party.CommonPackageInformation[0].PackageCode}}</span>
                        </div>
  
                      </div>
  
                      <div *ngIf="party.CommonPackageInformation.length > 1" [ngbPopover]="packageInformation" placement="bottom" [autoClose]="'outside'" container="body" (click)="dashboardFunctions.openPackageInfo(party.CommonPackageInformation)" >
                        ... {{'SeeMore' | translate}}
                      </div>
  
                    </li>
  
                  </div>
                  <li class="parties-grid__contact-details">
                    <div class="parties-grid__party-icons">
                      <div class="d-flex parties-grid__contact-notes">
                        <span class="parties-grid__contact-notes-spacing"
                              *ngFor="let item of party?.Contact?.Notes;let i= index">
                          <i (click)="tooltip.toggle()" #tooltip="matTooltip" matTooltipClass="tooltip-class" matTooltip="{{item.Text}}" *ngIf="party.BookingContactAddonItems?.length ? i < 3 : i < 4"
                             class="icon-{{item.Icon ? item.Icon : 'Default'}} seat-icon-color rs-pr-3"></i>
                        </span>
                        <span class="parties-grid__horizontal-dots-icon seat-triple-dots" [autoClose]="'outside'"
                              *ngIf="party?.Contact?.Notes?.length > 4" container="body" [ngbPopover]="GuestNotesPopover"
                              popoverClass="guest-notes-scroll"
                              placement="auto" (click)="mapGuestNotes(party?.Contact?.Notes)">
                          <span class="icon-Repeat-Grid-39"></span>
                        </span>
                      </div>
                      <div class="parties-grid__party-right-icons">
                        <div class="parties-grid__finance">
                          <span *ngIf="party.paymentAuthorised" class="icon-res-payment-aurthorized"
                                [autoClose]="'outside'" container="body" [placement]="['right-center', 'bottom', 'left-center' , 'auto']"
                                [ngbPopover]="financialStatusPopover" (click)="financialDetails(party, 'authorized', $event)"></span>
                          <span *ngIf="party.paymentPaid" class="icon-res-payment-Paid"
                                (click)="financialDetails(party, 'paid', $event)" container="body" [placement]="['right-center', 'bottom', 'left-center' , 'auto']"
                                [ngbPopover]="financialStatusPopover" [autoClose]="'outside'"></span>
                          <span *ngIf="party.paymentNotPaid" class="icon-res-payment-notpaid" [autoClose]="'outside'"
                                container="body" [placement]="['right-center', 'bottom', 'left-center' , 'auto']" (click)="financialDetails(party, 'notPaid', $event)"
                                [ngbPopover]="financialStatusPopover"></span>
                          <span *ngIf="party.paymentIgnored" class="icon-res-payment-aurthorized" container="body"
                                [placement]="['right-center', 'bottom', 'left-center' , 'auto']" (click)="financialDetails(party, 'ignored', $event)" [autoClose]="'outside'"
                                [ngbPopover]="financialStatusPopover"></span>
                        </div>
                        <div class="parties-grid__party-notes" *ngIf="party?.partyNotes?.length > 0">
                          <span class="icon-exclamation rs-fs-22" [autoClose]="'outside'" container="body" popoverClass="party-notes-scroll"
                                [ngbPopover]="partyNotesPopover" placement="auto" (click)="mapPartyNotes(party)">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span>
                          </span>
                        </div>
                        <div>
                          <div   *ngIf="party.BookingContactAddonItems?.length" class="parties-grid__party-addon" >
                            <span class="icon icon-addons mr-0" [autoClose]="'outside'" container="body" placement="auto" #addOnForBookingPopover="ngbPopover"
                            container="body" triggers="manual" [ngbPopover]="addOnForBooking" 
                            (click)="_ps.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(party)">
                            </span>
                        </div>
                        </div>
                        <div class="parties-grid__party-size-icon" [ngStyle]="{'background-color':(party | partyStatusColor : _ps.cs.settings.value : party.StatusId )}" #partyStatusPopup="ngbPopover" popoverClass="party-status-popover"
                        (click)="triggerPartyStatusPopup(partyStatusPopup,party)"
                        triggers="manual" [ngbPopover]="partyStatus" placement="auto" [autoClose]="'outside'" container="body">
                          <span class="parties-grid__partySize seat-tertiary-text float-right " [ngStyle]="{'color': (party | partyStatusColor : _ps.cs.settings.value : party.StatusId ) ? 'white':'inherit'}">{{party.Size}}</span><i class="icon_set icon-add-contact float-right seat-icon-color" [ngStyle]="{'color': (party | partyStatusColor : _ps.cs.settings.value : party.StatusId ) ? 'white':'inherit'}"></i>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="parties-grid__colthree-seat-register">
                <div class="parties-grid__button-actions" *ngIf="party.State != PartyState.Seated">
                  <button class="parties-grid__round-icon parties-grid__icon-green-color icon-Group-587 rounded-icon"
                          [disabled]="(party.ReservedFor && !(party.ReservedFor | EditOrSeatAction: party.State)) || party.State == PartyState.NoShowed || party.State == PartyState.Left || party.State == PartyState.Cancelled"
                          [ngClass]="{'parties-grid__disabled-icon': (party.ReservedFor && !(party.ReservedFor | EditOrSeatAction: party.State)) || party.State == PartyState.NoShowed || party.State == PartyState.Left || party.State == PartyState.Cancelled}"
                          (click)="seatParty(party, $event)">
                    <span class="path1"></span><span class="path2"></span>
                  </button>
                  <button class="icon-Group-591 parties-grid__hoverCancel parties-grid__round-icon rounded-icon"
                          [disabled]="(party.ReservedFor && !(party.ReservedFor | CancelorNoShowAction: party.State)) || party.State == PartyState.NoShowed || party.State == PartyState.Left || party.State == PartyState.Cancelled"
                          [ngClass]="{'parties-grid__disable-events parties-grid__hoverCancel-disabled': (party.ReservedFor && !(party.ReservedFor | CancelorNoShowAction: party.State)) || party.State == PartyState.NoShowed || party.State == PartyState.Left  || party.State == PartyState.Cancelled}"
                          (click)="cancelParty(party, $event)"></button>
  
                </div>
                <div class="parties-grid__spinner_row" *ngIf="party.State == PartyState.Seated">
                  <!-- Spinner for Waiting time -->
                  <div class="float-right text-center parties-grid__progress-spinner">
                    <mat-progress-spinner [ngClass]="{
                                          'first-percent' : party.progressBarValue < 50,
                      'second-percent': party.progressBarValue >= 50,
                      'third-percent': party.progressBarValue  >= 80}" class="parties-grid__spinner_required_width"
                      [color]="warn" [diameter]="55" [value]="party.progressBarValue">
                    </mat-progress-spinner>
                    <mat-progress-spinner class="parties-grid__spinner_full_width" [color]="primary" [diameter]="55"
                                          [value]="100">
                    </mat-progress-spinner>
                    <span *ngIf="(party.remainingTime > 0)"
                          class="parties-grid__spent_mins">{{ ( party.remainingTime)  | formatWaitTime }}</span>
                    <span *ngIf="( 0 > party.remainingTime)" class="parties-grid__spent_mins">0 {{'minsShortText' | translate}}</span>
                  </div>
                </div>
                <div *ngIf="party.State == PartyState.Seated" class="parties-grid__actual_mins">
  
                  <!-- {{ quotedTime | formatWaitTime }} -->
                </div>
                <!-- Not required for this build -->
                <!-- <div class="parties-grid__reservation-status seat-tertiary-text"
                  *ngIf="party.State == PartyState.Pending">
                  <span>{{party.ReservedFor | timeLapse: _settings  | negativeNumber :_as._headerDate : _settings  }}</span>
                </div> -->
              </div>
            </div>
          </div>
        </ng-container>
      </virtual-scroller>
      <div>
        <ng-template #financialStatusPopover>
          <div class="financial-details">
            <div class="financial-details__header seat-popover-header">
              {{financialDetail.header}}
            </div>
            <div class="financial-details__content seat-popover-content">
              <div *ngIf="IsCharged" class="financial-details__paid">
                <table *ngIf="financialDetail">
                  <tr>
                    <td>{{'datetime' | translate}}</td>
                    <td class="rs-pl-3">:</td>
                    <td class="rs-pl-5">
                      {{financialDetail.date | localizedDate: 'LT'}} / {{financialDetail.date | localizedDate: 'D MMM, ddd'}}
                    </td>
                  </tr>
                  <tr>
                    <td>{{'accordionstatustext' | translate}}</td>
                    <td class="rs-pl-3">:</td>
                    <td class="rs-pl-5"> {{cs.operationCurrency}}{{financialDetail.amount}} {{financialDetail.status}}</td>
                  </tr>
                </table>
              </div>
              <div *ngIf="!IsCharged" class="financial-details__notPaid">
                <span>{{financialDetail.status}}</span>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="parties-grid__guest-notes">
        <ng-template #partyNotesPopover>
          <div>
            <!-- <ng-scrollbar> -->
            <div class="parties-grid__scrollSize">
              <div class="reservation-notes">
                <div class="reservation-notes__header seat-popover-header">
                  {{'reservationNotes' | translate}}
                </div>
                <div class="reservation-notes__content seat-popover-content">
                  <div class="reservation-notes__notes-details seat-border-color" *ngFor="let notes of partyNotes">
                    <span class="seat-secondary-text" *ngIf="!notes.isSpecialMeal">{{notes.name}}</span>
                    <span *ngIf="notes.isSpecialMeal">
                      <span class="uppercase seat-secondary-text" *ngIf="notes.type == partyNoteType.SpecialMeal">
                        {{'specialMealText' | translate }}:
                      </span>
                      <span class="uppercase seat-secondary-text" *ngIf="notes.type == partyNoteType.SpecialRequest">
                        {{'specialRequest' | translate }}:
                      </span>
                      <span class="uppercase seat-secondary-text" *ngIf="notes.type == partyNoteType.PromoCode">
                        {{generalSettings.PromoCodeLabel}}:
                      </span>
                      <span>{{notes.name}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- </ng-scrollbar> -->
          </div>
        </ng-template>
      </div>
      <div class="parties-grid__guest-notes">
        <ng-template #GuestNotesPopover>
          <div>
            <!-- <ng-scrollbar> -->
            <div class="parties-grid__scrollSize">
              <div class="reservation-notes">
                <div class="reservation-notes__header seat-popover-header">
                  {{'guestNotes' | translate}}
                </div>
                <div class="reservation-notes__content seat-popover-content">
                  <div class="reservation-notes__notes-details seat-border-color" *ngFor="let notes of guestNotes">
                    <span class="seat-secondary-text">{{notes}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- </ng-scrollbar> -->
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div *ngIf="parties?.length == 0" class="parties-grid__no-reservations">
    <img src="{{reservationUrl}}" alt="" class="parties-grid__no-reservations-img">
    <span *ngIf="_as.selectedMenuId==Menu.Reservation"
          class="parties-grid__no-reservations-text seat-no-content-found">{{'noReservationsFound' | translate}}</span>
    <span *ngIf="_as.selectedMenuId==Menu.WaitList"
          class="parties-grid__no-reservations-text seat-no-content-found">{{'noWaitlistsFound' | translate}}</span>
  </div>
  <div class="demo-tab-group__party-status">
    <ng-template #partyStatus>
      <app-party-status-popup [party]="selectedStatusParty" (partyStatusId)="seatStatusChanged($event)"></app-party-status-popup>
    </ng-template>
  </div>
</div>


<!-- A Package Popover -->
<ng-template #packageInformation>
  <app-package-information [packageInfo]="dashboardFunctions.currentPackageInfo"></app-package-information>
</ng-template>


<!-- Add-on Popover -->
<ng-template #addOnForBooking>
 <app-add-on-popover [party]="partyWithAddon"></app-add-on-popover>
</ng-template>

 <!-- Member Information Popover -->
 <ng-template #memberInfoPopover >
  <app-member-info *ngIf="memberDetails" [memberDetails]="memberDetails"></app-member-info>
</ng-template>