// import _ from "lodash";

// export function printChit(divId, chitPrintStyles) {
//   closeAlreadyOpenwindow();
//   setTimeout(() => {
//     var panel = document.getElementById(divId).cloneNode(true);
//     openPrintWindow(divId, panel);
//   }, 100)
// }

// function openPrintWindow(divId, panel){
//   var printWindow = window.open('assets/print-chit/print-chit.html', 'print', 'height=800,width=800,fullscreen=no,scrollbars=yes,titlebar=yes,menubar=yes');

//   printWindow.onload = function () {
//     try{
//       printWindow.document.body.appendChild(panel);
//     }catch(error){
//       console.error("Chit Print Body AppendChild Error: ", error);
//       printWindow.close();
//     }
//     printWindow['printIntilalized'] = true;
//     printWindow.print();
//   }

//   // printWindow.onmouseover = function () {
//   //   if (printWindow['printIntilalized']) {
//   //     printWindow.close();
//   //   }
//   // }

//   printWindow.document.close();
//   printWindow.focus();
// }

// function closeAlreadyOpenwindow() {
//   var existingWindow = window.open('', 'print');
//   // Check if window.open returned a valid window object and it's not closed
//   if (existingWindow && !existingWindow.closed) {
//     existingWindow.close(); // Window with the same name is open
//   }
// }

export function printChit(divId, chitPrintStyles, callback?) {
  setTimeout(()=> {
  var panel = document.getElementById(divId);
  var printWindow = window.open('', 'print', 'height=800,width=800,fullscreen=no,scrollbars=yes,titlebar=yes,menubar=yes');
  printWindow.document.write('<html><head>');
  printWindow.document.write('<style>' + chitPrintStyles +
    
  '</style>')

  printWindow.document.write('</head><body onload="window.print(); window.printInitialized=true;" onmouseover="window.printInitialized && window.close();">');
  printWindow.document.write(panel.innerHTML);
  printWindow.document.write('</body></html>');
  printWindow.document.close();
  printWindow.focus();
    if(callback) callback();
},100)
}