import { Injectable } from "@angular/core";
import { RetailRoutes } from "src/app/retail/retail-route";
import { RetailCommunication } from "src/app/retail/retailcommunication.service";
import { SearchDiscountType, TransactionTicketDiscount } from "./transction-model";
import { TenderInfoTransactionContract } from "src/app/retail/retail-transaction-engine/transaction-engine-model";
import { Ticket } from "../common-variables.service";
import { DiscountType } from "src/app/retail/retail-code-setup/retail-discount-types/discount-types.model";
import { ClientMultipack, MultiPackDetails, MultiPackReturn } from "src/app/retail/retail.modals";

@Injectable({
    providedIn: 'root'
})
export class TransactionService {
    constructor(private retailCommunication: RetailCommunication){

    }

    public getTransactionTicketDiscount(transactionId: number): Promise<TransactionTicketDiscount[]>{
        return this.retailCommunication.getPromise<TransactionTicketDiscount[]>({route : RetailRoutes.GetTransactionDiscount, uriParams: {transactionId : transactionId} });
    }

    public ReCalculateTicketBasedOnTender(request: TenderInfoTransactionContract): Promise<Ticket>{
        return this.retailCommunication.postPromise<Ticket>({route : RetailRoutes.ReCalculateTicketBasedOnTender, body : request });
    }


    public SearchDiscountByBarCode(request: SearchDiscountType): Promise<DiscountType>{
        return this.retailCommunication.putPromise<DiscountType>({route : RetailRoutes.SearchDiscountByBarCode, body : request });
    }

    public GetDiscountTypeByIds(request: number[]): Promise<DiscountType[]>{
        return this.retailCommunication.putPromise<DiscountType[]>({route : RetailRoutes.GetDiscountTypesByIds, body : request });
    }

    public GetMultiPackDetailsByTransactionDetailIds(multiPackTransactionDetailId: number[]): Promise<MultiPackDetails[]> {
        return this.retailCommunication.putPromise<MultiPackDetails[]>({route : RetailRoutes.GetMultipackByDetailId, body : multiPackTransactionDetailId });
    }

    public async getClientMultiPacksBytransactionId(transactionId: number): Promise<ClientMultipack[]> {
        return await this.retailCommunication.getPromise<ClientMultipack[]>({
          route: RetailRoutes.GetMultipacksByTransactionId,uriParams: { transactionId }
        });
    }

   public async updateMultiPack(multipackreturn: MultiPackReturn) {
        const response = await this.retailCommunication.putPromise<MultiPackReturn>({ route: RetailRoutes.UpdateMultipack, body: multipackreturn });
        return response;
    }
}