<div class="guest-data">
  <div class="guest-data__select-guest">
    <ng-scrollbar>
      <div *ngIf="restaurantPolicyAvailable" class="guest-data__policy-table">
        <label class="guest-data__selected-tables seat-secondary-text"> {{ restaurantPolicy.PreReservationMessageTitle }} </label>
        <span class="guest-data__icon-exclamation icon-exclamation" (click)="ShowRestPolicyDetails()">
          <span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
      </div>

    <div class="guest-data__table-details" (click)="(data && data.isPastReservation) ? '' : goToPrevTab()">
      <label *ngIf="!partyService.isOverBookSlotSelected && !partyService.isStandbySlotSelected" class="guest-data__table-label seat-labels-v2">{{((partyService.reservationType == ReservationType.Reservation || partyService.standbyConversion) ? 'reservationDateTime' : (partyService.reservationType == ReservationType.StandbyParties) ? 'standbyDateTime' : 'waitListDateTime') | translate }}</label>
      <label *ngIf="partyService.isOverBookSlotSelected" class="guest-data__table-label seat-labels-v2">{{'overbookDateTime' | translate}}</label>
      <label *ngIf="partyService.isStandbySlotSelected" class="guest-data__table-label seat-labels-v2">{{'standbyDateTime' | translate}}</label>
      <div>
        <span class="guest-data__selected-tables guest-data__wait-time-width seat-secondary-text" *ngIf="partyService.totalWaitimeMinutes < 180">{{ selectedDateText }}</span>
        <span class="guest-data__selected-tables guest-data__wait-time-width seat-secondary-text" *ngIf="(partyService.reservationType === ReservationType.Waitlist) && partyService.totalWaitimeMinutes >= 180">{{partyService.totalWaitimeMinutes* 60 | customWaitTime}}</span>
        <!-- This icon will be enabled once icon for party size and seating area is given -->
        <!-- <span class="guest-data__slot-icon icon-clock seat-icon-color"></span> -->
      </div>
    </div>
    <div class="guest-data__table-details" (click)="goToPrevTab()">
      <label for="" class="guest-data__table-label seat-labels-v2">{{'partySize' | translate}}</label>
      <div>
        <span class="guest-data__selected-tables seat-secondary-text">{{ partyService.reservationFormGroup.value.selectedSize }}</span>
      </div>
    </div>
    <div class="guest-data__table-details" (click)="goToPrevTab()">
      <label for="" class="guest-data__table-label seat-labels-v2">{{'seatingArea' | translate}}</label>
      <div>
        <span class="guest-data__selected-tables seat-secondary-text" >{{ selectedAreaName }}</span>
      </div>
    </div>
    <div class="guest-data__table-details" (click)="goToPrevTab()">
      <label for="" class="guest-data__table-label seat-labels-v2">{{'seatingTypeText' | translate}}</label>
      <div>
        <!-- This icon will be enabled once icon for party size and seating area is given -->
        <!-- <span style="font-size: 20px;font-weight: 900;" class="icon-Group-749"></span> -->
        <span class="guest-data__selected-tables" style="display: inline-block;">{{ selectedSeatingType }}</span>
      </div>
    </div>
      <div class="guest-data__table-details" (click)="selectedTable($event)" *ngIf = "!(partyService.reservationType === ReservationType.Waitlist && !data)"
      [ngClass]="{'disable':partyService.tryUndoNoShowReservation}">

        <label for="" class="guest-data__table-label seat-labels">{{'guestDataTableText' | translate}}</label>
        <span class="guest-data__selected-tables seat-secondary-text">{{tablesText}}</span>
      </div>
      <div [ngClass]="{'disable':partyService.tryUndoNoShowReservation}" class="guest-data__assign-table" [hidden]="!showSuggestedTables">
        <activities-app-button [buttontype]="buttonTertiary" (valueChange)='assignSuggestedTable($event)'
          class="guest-data__suggest-tbl-btn"></activities-app-button>
      </div>
      <div class="guest-data__special-meal" *ngIf="partyService.reservationType === ReservationType.Reservation && !partyService.isStandbySlotSelected"
           [ngClass]="{'disable':partyService.tryUndoNoShowReservation}">
        <app-special-meal></app-special-meal>
      </div>
      <div class="guest-data__reservation-summary-view" *ngIf="partyService.reservationType === ReservationType.Reservation && !partyService.isStandbySlotSelected && totalAmount > 0">
        <label class="seat-text-fields">{{'reservationSummaryText' | translate}}</label>
        <ng-container *ngIf="RatePlanAmount > 0">
        <div *ngFor="let rp of ratePlanSummary" class="seat-tertiary-text guest-data__reservation-summary-data">
          <label class="guest-data__reservation-summary">{{rp.SeatingType}} ({{rp.SeatCount}})</label>
           <label class="guest-data__reservation-summary-amount">{{cs.operationCurrency}} {{rp.RatePlanAmount | currencyDecimalFormatter }}</label>
          </div>
        </ng-container>
        <div *ngIf="selectedSpecialMealTotalAmount > 0" class="seat-tertiary-text guest-data__reservation-summary-data">
          <label class="guest-data__reservation-summary">{{selectedSpecialMealName}} ({{partyService.selectedSize}})</label><label class="guest-data__reservation-summary-amount">{{cs.operationCurrency}} {{selectedSpecialMealTotalAmount | currencyDecimalFormatter }}</label></div>
        <div class="seat-text-fields"  *ngIf="false" class="guest-data__reservation-summary-total">
          <label class="guest-data__reservation-summary">{{'servercirclecomponentlabel1' | translate | uppercase}}</label><label class="guest-data__reservation-summary-amount">{{cs.operationCurrency}} {{totalAmount | currencyDecimalFormatter }}</label>
        </div>
        <div *ngIf="true" class="seat-text-fields guest-data__reservation-summary-totalwithtax">
          <div class="seat-text-fields" class="guest-data__reservation-summary-total-view">
            <div class="guest-data__tax-section">
              <label class="guest-data__reservation-summary">{{'subTotal' | translate }}</label><label class="guest-data__reservation-summary-amount">{{cs.operationCurrency}} {{(totalAmount) | currencyDecimalFormatter }}</label> <br>
            </div>
            <div class="guest-data__tax-section">
              <label class="pb-1 guest-data__reservation-summary">{{'serviceChargesWithPlural' | translate }}</label><label class="guest-data__reservation-summary-amount">{{cs.operationCurrency}} {{serviceChargeAmount | currencyDecimalFormatter }}</label> <br>
            </div>
            <div class="guest-data__tax-section" *ngIf="taxOnServiceChargeAmount > 0">
              <label class="pb-1 guest-data__reservation-summary">{{'serviceChargesTaxWithPlural' | translate }}</label><label class="guest-data__reservation-summary-amount">{{cs.operationCurrency}} {{taxOnServiceChargeAmount | currencyDecimalFormatter }}</label> <br>
            </div>
            <div class="guest-data__tax-section">
              <label class="pb-1 guest-data__reservation-summary">{{'tax' | translate }}</label><label class="guest-data__reservation-summary-amount">{{cs.operationCurrency}} {{taxAmount | currencyDecimalFormatter }}</label> <br>
            </div>
          </div>
          <div class="seat-text-fields" class="guest-data__reservation-summary-total">
            <label class="guest-data__reservation-summary">{{'findTotal' | translate | uppercase}}</label><label class="guest-data__reservation-summary-amount">{{cs.operationCurrency}} {{(totalAmount + taxAmount + serviceChargeAmount + taxOnServiceChargeAmount) | currencyDecimalFormatter }}</label>
          </div>
        </div>

        <div></div>
      </div>
      <div class="guest-data__Hosts"
        *ngIf="partyService.reservationType === ReservationType.Reservation || partyService.reservationType === ReservationType.StandbyParties" [ngClass]="{'disable':partyService.tryUndoNoShowReservation}">
        <mat-form-field>
          <mat-label class="guest-data__Hosts-font">{{ 'hostText' | translate }}</mat-label>
          <mat-select [(value)]="defaultHost" class="guest-data__Hosts-font">
            <mat-option *ngFor="let option of hosts" [value]="option.Name" (click)="setSelectedHost(option)">
              {{option.Name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="guest-data__pager" [ngClass]="{'disable':partyService.tryUndoNoShowReservation}">
        <dynamic-form [config]="pagerConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div class="guest-data__party-status">
        <dynamic-form [config]="partyStatusConfig" #partyStatusForm>
        </dynamic-form>
      </div>
      <div class="guest-data__hotelConcierge"
        *ngIf="(partyService.reservationType === ReservationType.Reservation || partyService.standbyConversion) && _settings.General.AllowHotelConciergeTracking && !partyService.isStandbySlotSelected"
           [ngClass]="{'disable':partyService.tryUndoNoShowReservation}">
        <label for="" class="guest-data__hotelConcierge-label seat-labels">{{'hotelConciergelbl' | translate}}</label>
        <div class="guest-data__hotel-sec" (click)="getConciergeTrackingList(ConciergeTrackingType.Hotel)">
          <span class="guest-data__hotel">{{hotelName | translate}}</span>
          <i class="icon-Group-591" [ngClass]="{'icon-left-arrow1':hotelName == 'Hotel'}"
            (click)="removeConciergeTrackingItem(ConciergeTrackingType.Hotel)"></i>
        </div>
        <div class="guest-data__concierge-sec" *ngIf="showConcierge"
          (click)="getConciergeTrackingList(ConciergeTrackingType.Concierge)">
          <span class="guest-data__concierge">{{conciergeName}}</span>
          <i class="icon-Group-591" [ngClass]="{'icon-left-arrow1':conciergeName == 'Concierge'}"
            (click)="removeConciergeTrackingItem(ConciergeTrackingType.Concierge)"></i>
        </div>
        <div class="guest-data__mobile-sec">
          <dynamic-form [config]="mobileConfig" #mobileNumberForm>
          </dynamic-form>
        </div>
      </div>
      <div class="guest-data__sales-contact" [hidden]="!showSalesContact">
        <label for="" class="guest-data__salesContact-label seat-labels">{{'salesContact' | translate}}</label>
        <dynamic-form [config]="salesContactConfig" #salesContactForm="dynamicForm">
        </dynamic-form>
      </div>
      <div class="preffered-communication">
        <app-prefered-communication [settings]="_settings" [PreferredPageMethod]="data?.PageMethod" [NotificationPreference]="data?.NotificationPreference" (setPagePreference)="updatePageMethod($event)"></app-prefered-communication>
      </div>
      <div class="guest-data__party-notes" [ngClass]="{'disable':partyService.tryUndoNoShowReservation}">
        <dynamic-form [config]="config" #form="dynamicForm">
        </dynamic-form>
      </div>
    </ng-scrollbar>
  </div>
  <div class="guest-data__guest-details">
    <app-guest-selection [selectedIndex]="selectedIndex"></app-guest-selection>
  </div>
</div>
