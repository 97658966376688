<section [formGroup]="taxexemptFormGrp" autocomplete="off">

  <app-retail-multi-select-dropdown *ngIf="allCategoryGroups" [dropDownFilterData]="allCategoryGroups"
    [dropDownControlname]="'reportcategory'" [dropDownName]="captions.ReportCategory"
    (dropDownFrmControl)="getFormControlValue($event)"
    (categoryGroup)="getCategoryGroupValue($event)"></app-retail-multi-select-dropdown>

  <app-retail-multi-select-dropdown *ngIf="categories" [dropDownFilterData]="categories"
    [dropDownControlname]="'category'" [dropDownName]="captions.Category"
    (dropDownFrmControl)="getFormControlValue($event)"></app-retail-multi-select-dropdown>
  <div *ngIf="showMachineDropDown">
    <app-retail-multi-select-dropdown *ngIf="machineName" [dropDownFilterData]="machineName"
      [dropDownControlname]="'machineName'" [dropDownName]=captions.Machines
      (dropDownFrmControl)="getFormControlValue($event)"></app-retail-multi-select-dropdown>
  </div>
  <div *ngIf="showIncludeTaxExempt" class="mb-3">
    <label class="LW14">{{captions.includePaymentTaxExempt}}</label>
    <app-retail-toggle-switch class="d-block" [formControl]="taxexemptFormGrp.controls['includePaymentTaxExempt']">
    </app-retail-toggle-switch>
  </div>

</section>
  