<div class="form-input-time {{config.class}}">
  <mat-label class="select-label">{{config.label  | translate}}</mat-label>
  <mat-form-field  [formGroup]="group" [hideRequiredMarker]="!config.isStarSymbolRequired" appearance="outline">
    <input matInput type="text" [ngxTimepicker]="toggleTimepicker"
      [formControlName]="config.name" name="timepicker-{{config.name}}"  placeholder="{{config.placeholder}}" [format]="config.timeFormat ? config.timeFormat : 24" [value]="config.value"autocomplete="off" readonly>
      <mat-error *ngIf="group.controls[config.name].hasError('invalid') && config.showErrorText">
        {{config.errorMessage | translate}}
      </mat-error>
  </mat-form-field>
  <ngx-material-timepicker-toggle [for]="toggleTimepicker">
    <i class="icon-clock cursor" ngxMaterialTimepickerToggleIcon></i>
  </ngx-material-timepicker-toggle>
  <ngx-material-timepicker #toggleTimepicker [minutesGap]="5" [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn" (timeSet)="setTime($event)"></ngx-material-timepicker>
  <ng-template #cancelBtn ><button class="timepickerCustBtn timepicker-button">{{'cancel' | translate}}</button></ng-template>
  <ng-template #confirmBtn ><button class="timepickerCustBtn timepicker-button">{{'ok' | translate}}</button></ng-template>
</div>