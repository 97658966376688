import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NotificationPreference } from '@app/shared/constants/commonenums';
import { FormChipService } from '@app/shared/dynamicform/components/form-chip/form-chip.service';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PageMethod, CommunicationMethods } from '@app/shared/models/InputContact';
import { TranslateService } from '@ngx-translate/core';
import { FacadeService } from '@services/facade.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-prefered-communication',
  templateUrl: './prefered-communication.component.html',
  styleUrls: ['./prefered-communication.component.scss']
})
export class PreferedCommunicationComponent implements OnInit {

  @Input() settings;
  @Input() PreferredPageMethod;
  @Input() NotificationPreference;
  @Output() setPagePreference = new EventEmitter<any>();
  @ViewChildren('communicationPrefConfigForm') communicationPrefConfigForm: QueryList<DynamicFormComponent>;
  @ViewChild('pagingType', { static: true }) basicSetupForm: DynamicFormComponent;
  communicationPrefConfig: FieldConfig[] = [];
  pagingTypeConfig: FieldConfig[] = [];
  subscriptions: Subscription = new Subscription();
  selectedNotification: NotificationPreference;
  preferenceType = NotificationPreference;
  selectedPageMethod: PageMethod;
  showPreferenceList: boolean = false;
  preferenceNote:String;

  constructor(public facadeService: FacadeService, private _ts: TranslateService, protected formChipService: FormChipService,) { }

  ngOnInit(): void {
    this.setCommunicationPrefList();
  }
  ngAfterViewInit() {
    this.formValueChanges();
    this.setPagePreference.emit({ pageMethod: this.PreferredPageMethod, NotificationPreference: this.selectedNotification });
    this.selectedPageMethod = this.PreferredPageMethod;
    this.showPreferenceList = this.selectedNotification == NotificationPreference.ReservationPageMethod;
  }
  formValueChanges() {
    this.subscriptions.add(this.formChipService.formChipChange$.subscribe((selectedChips) => {
      if (selectedChips.name === this.communicationPrefConfig.filter(config => config.name == "pageMethod")[0].name) {
        this.selectedPageMethod = selectedChips.data.find(({ setSelected }) => setSelected)?.Id || 0;
        this.setPagePreference.emit({ pageMethod: this.selectedPageMethod, NotificationPreference: this.selectedNotification });
      }
    }))
    this.basicSetupForm.form.valueChanges.subscribe((value => {
      this.selectedNotification = value?.NotificationPreference;
      this.showPreferenceList = this.selectedNotification == NotificationPreference.ReservationPageMethod;
     
      this.setPagePreference.emit({ pageMethod: this.selectedPageMethod, NotificationPreference: this.selectedNotification });
    }));
  }
  setCommunicationPrefList() {
    let defaultPageMethod = this.settings.General.DefaultPageMethod === CommunicationMethods.None ? PageMethod.Email : this.settings.General.DefaultPageMethod -1;
    let defaultPref = this.PreferredPageMethod === 0 || this.PreferredPageMethod ? this.PreferredPageMethod : defaultPageMethod;
    this.PreferredPageMethod = defaultPref;
    switch (this.settings.General.DefaultPageMethod) {
      case CommunicationMethods.Email: this.preferenceNote = this._ts.instant('emailPreferenceMsg'); break;
      case CommunicationMethods.Sms: this.preferenceNote = this._ts.instant('smsPreferencesMsg'); break;
      case CommunicationMethods.VoiceCall: this.preferenceNote = this._ts.instant('smsPreferencesMsg'); break;
      default: this.preferenceNote = null;
    }

    let _communicationTagList = this.facadeService.getCommunicationList();
    //this.bookingData.PageMethod = defaultPref;

    _communicationTagList.forEach(tagItem => {
      if (tagItem.Id === defaultPref) {
        tagItem.setSelected = true;
      }
    })

    this.communicationPrefConfig = [
      {
        type: 'chip',
        name: 'pageMethod',
        inputType: 'text',
        // label: 'preferredCommunication',
        class: 'guest-view__preferred-tags-edit', // Do not modify the className, there is a dependency for editGuest details.
        options: _communicationTagList,
        multipleChip: false
      }
    ]
    this.selectedNotification = this.NotificationPreference !== null && this.NotificationPreference !== undefined ? this.NotificationPreference : NotificationPreference.ReservationPageMethod
    this.pagingTypeConfig = [
      {
        name: 'NotificationPreference',
        type: 'radio',
        options: [{ id: NotificationPreference.ReservationPageMethod, value: this._ts.instant('ReservationPreference') }, { id: NotificationPreference.GuestPageMethod, value: this._ts.instant('GuestPreference') }],
        class: 'email-template-definition__reservationType',
        value: this.selectedNotification
      },

    ]
  }
}
