<lib-dialog-container [title]="data.title ? data.title : 'Errors' | translate" [showSubmit]="false" [cancelLabel]="'Ok' | translate">
    <!-- <div title> -->
        <span title-icon class="icon-respos-error respos-error-ft respos-medium-icon">
        </span>       
    <!-- </div> -->
    <div content class="respos-error-container">
        <ul>
            <li *ngFor="let error of data.errors">{{error}}</li>            
        </ul>
    </div>
</lib-dialog-container>