import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Product } from "../enums/shared-enums";
import { Localization } from "../localization/localization";

@Injectable()
export class RedirectRouteGuard implements CanActivate {
    constructor(private router: Router, private localization: Localization) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        let canRedirect = true;
        if(parseInt(this.localization.GetsessionStorageValue('propertyInfo', 'ProductId')) == Product.SPA){
let userTherapist = sessionStorage.getItem('UserTherapist');
        let isTherapistLogin = userTherapist ? true : false;
        let isRouteDisabledonSingleTherapist = route.data.disableRouteonSingleTherapist;
        if(isRouteDisabledonSingleTherapist && isTherapistLogin){
            this.redirectToApptURL('/appointment/newappointment');
            canRedirect = false;
        }
        }
        
        return canRedirect;
    }

    redirectToApptURL(redirectLocation) {
        this.router.navigate([redirectLocation]);
    }
    
}