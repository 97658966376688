import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { AgDateConfig, AgTimeConfig, ButtonValue, DropdownOptions, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { OutletSubProperty } from 'src/app/retail/shared/business/shared.modals';
import { Host } from 'src/app/retail/shared/globalsContant';
import { HttpServiceCall,HttpMethod, ServiceParamsAsync } from 'src/app/retail/shared/service/http-call.service';
import { BaseResponse, GolfScheduleTeeTimeDetails, InUseAvailableRentalItemsRequest, RentalItemControlDetails, RentalItemOutOfOrderDetails } from 'src/app/retail/shared/shared.modal';
import { RentalItemControlBusiness } from './rental-item-control.business';
import * as GlobalConst from '../../../shared/globalsContant';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { ImageProcessorService } from 'src/app/retail/shared/service/image-processor-service';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
@Component({
  selector: 'app-rental-item-control',
  templateUrl: './rental-item-control.component.html',
  styleUrls: ['./rental-item-control.component.scss'],
  providers : [RentalItemControlBusiness],
  encapsulation : ViewEncapsulation.None
})
export class RentalItemControlComponent implements OnInit {

  captions: any;
  dropdownoptions = [];
  dateInput: AgDateConfig;
  rentalForm: UntypedFormGroup;
  timeInput: AgTimeConfig;
  todayButton: ButtonValue;
  searchButton: ButtonValue;
  isInUse: boolean = false;
  isAvailable: boolean = false;
  isOutOfOrder: boolean = false;
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent= [];
  originalTableContent= [];
  availableItem = [];
  originalAvailableItem = [];
  searchPlaceHolder: any;
  outletsBySubProperty: any;
  outlets: OutletSubProperty[] = [];
  outletsList:DropdownOptions[];
  selectedOutletId: number;
  outOfOrderTableData: RentalItemOutOfOrderDetails[];
  inUseAvailableRequestBody: InUseAvailableRentalItemsRequest;
  defaultOutlet: number;
  rentalItemsDetails: RentalItemControlDetails[];
  scheduleTeeTimeDetails: GolfScheduleTeeTimeDetails[];
  retailimages = [];
  searchText: any;
  constructor(public localization: RetailLocalization,private fb: UntypedFormBuilder,
    private business:RentalItemControlBusiness, private http: HttpServiceCall,
    public propertyInfo: RetailPropertyInformation,private utils: CommonUtilities,
    public imgService: ImageProcessorService,public _utilities: RetailUtilities,
    public _commonVarService: CommonVariablesService) {
    this.captions = this.localization.captions;
   }

  async ngOnInit() {
    this.isInUse = true;
    if(this.isInUse){
      this.searchPlaceHolder = this.captions.lbl_searchByPlayerNameItemNumber;
    }else{
      this.searchPlaceHolder = this.captions.lbl_searchByNameItemNumber
    }
    this.formGenerator();
    this.headerOptions = this.business.getInuseHeaderOptions();
    this.tableOptions = this.business.getTableOptions();
    await this.loadOutlets();
    await this._commonVarService.GetMiscConfig();
    this.tableContent = await this.getInUseTableContent();
    this.originalTableContent = this.tableContent;
  }

   formGenerator(){
    this.rentalForm = this.fb.group({
      date: this.propertyInfo.CurrentDate,
      time: this.localization.getTime(this.localization.getDate(this.utils.getPropertyTime()), this.localization.getTimeFormat()),
      outletsdropdown: '',
    });
    this.dateInput = {
      form: this.rentalForm,
      formControlName: 'date',
      automationId:"'Txt_rentalItemControl_date'",
      placeHolder: this.captions.tbl_hdr_extractDate
    };
    this.timeInput = {
      form: this.rentalForm,
      formControlName: 'time',
      placeHolder: this.captions.tbl_hdr_time
    }
    this.todayButton = {
      type: 'secondary',
      label: this.localization.captions.btn_today,
      disabledproperty : false
    }
    this.searchButton = {
      type: 'primary',
      label: this.captions.shop.search,
      disabledproperty : false
    }

  }

  async onInUse(){
    this.isInUse = true;
    this.isAvailable = false;
    this.isOutOfOrder = false;
    this.searchPlaceHolder = this.captions.lbl_searchByPlayerNameItemNumber;
    this.headerOptions = this.business.getInuseHeaderOptions();
    this.tableContent = await this.getInUseTableContent();
    this.originalTableContent = this.tableContent;
    this.searchValueChange('');
  }
  async onAvailable(){
    this.isAvailable = true;
    this.isInUse = false;
    this.isOutOfOrder = false;
    this.searchPlaceHolder = this.captions.lbl_searchByNameItemNumber;
    this.availableItem = await this.getAvailableItemList();
    this.originalAvailableItem = this.availableItem;
    this.searchValueChange('')
  }
  async onOutOfOrder(){
    this.isOutOfOrder = true;
    this.isInUse = false;
    this.isAvailable = false;
    this.searchPlaceHolder = this.captions.lbl_searchByNameItemNumber;
    this.headerOptions = this.business.getOutOfOrderHeaderOptions();
    this.tableContent = await this.getOutOfOrderTableContent();
    this.originalTableContent = this.tableContent;
    this.searchValueChange('');
  }
  async loadOutlets() {
    let userMachineConfig = null;
    userMachineConfig = this._utilities.getUserDefaults();
    this.outletsBySubProperty = await this.getUserOutletSubProperty();
    const outletSubPropertyResult: any = await this.outletsBySubProperty.toPromise();
    if (outletSubPropertyResult && outletSubPropertyResult.length > 1) {
      this.outlets = outletSubPropertyResult[1].result.filter(o => o.isActive);
    } else {
      this.outlets = [];
    }
    this.outletsList = [];
    if (this.outlets && this.outlets.length) {
      this.outlets.forEach(x => {
        this.outletsList.push({
          id: x.subPropertyID,
          viewValue:x.subPropertyName,
          value: x.subPropertyID
        });
      });
    }
    this.outletsList = [...this.outletsList];
    this.defaultOutlet = userMachineConfig.defaultOutletId;
    if(this.defaultOutlet>0){
      this.rentalForm.controls['outletsdropdown'].setValue(this.outletsList.find(x=>x.id == this.defaultOutlet).id);
      console.log(" this.rentalForm.controls['outletsdropdown']", this.rentalForm.controls['outletsdropdown'].value)
    }
    else{
      this.rentalForm.controls['outletsdropdown'].setValue(this.outletsList[0].id);
      console.log(" this.rentalForm.controls['outletsdropdown'] 2", this.rentalForm.controls['outletsdropdown'].value)
    }
  }
  selectedOutletChange(arg) {
    
    if(this.isOutOfOrder == true){
      this.onOutOfOrder();
    }
   
  }
  getUserOutletSubProperty(): Observable<any> {

    let serviceParams: ServiceParamsAsync[] = [{
        callDesc: "GetOutletsByProperty",
        host: Host.retailManagement,
        method: HttpMethod.Get,
        uriParams: { propertyId: this.http.GetPropertyInfo("PropertyId") }
    },
    {
        callDesc: "GetSubPropertyAccessByUser",
        host: Host.retailManagement,
        method: HttpMethod.Get,
        uriParams: { userId: this.http.GetPropertyInfo("UserId") }
    }];

    return this.http.WaitForHttpCalls(serviceParams);
}
  async todayClick(){
    this.rentalForm.patchValue({
        date: this.propertyInfo.CurrentDate,
        time: this.localization.getTime(this.localization.getDate(this.utils.getPropertyTime()), this.localization.getTimeFormat()),
        outletsdropdown:this.rentalForm.controls.outletsdropdown.value? this.rentalForm.controls.outletsdropdown.value:this.outletsList[0]?.id,
      })
  }

  searchValueChange(e){
    this.searchText=e?e : '';
    if(this.searchText && this.searchText.length > 0)
    {
      if(this.isInUse){
        this.tableContent=this.originalTableContent.filter(x=>x.itemName.toLowerCase().includes(this.searchText.toLowerCase())|| x.categoryName?.toLowerCase().includes(this.searchText.toLowerCase())
        || x.itemNumber.toLowerCase().includes(this.searchText.toLowerCase()) || x.scheduledTeeTimePlayerName.toLowerCase().includes(this.searchText.toLowerCase()));
      }
      else if(this.isAvailable){
        this.availableItem=this.originalAvailableItem.filter(x=>x.itemName.toLowerCase().includes(this.searchText.toLowerCase()) || x.itemNumber.toLowerCase().includes(this.searchText.toLowerCase()));
      }
      else if(this.isOutOfOrder){
        this.tableContent=this.originalTableContent.filter(x=>x.itemName.toLowerCase().includes(this.searchText.toLowerCase())|| x.categoryName?.toLowerCase().includes(this.searchText.toLowerCase())
        || x.itemNumber.toLowerCase().includes(this.searchText.toLowerCase()));
      }
    }
    else{
      this.tableContent = this.originalTableContent;
      this.availableItem=this.originalAvailableItem
    }
  }

  async dateChange(e){
  }

  async timeChange(e){
  }

  async getInUseTableContent(){
   let apiDate = this.localization.convertDateToAPIdate(this.rentalForm.controls.date.value);
   let apiTime = this.localization.DeLocalizeTime(this.rentalForm.controls.time.value);
   this.localization.convertDateObjToAPIdate
    this.inUseAvailableRequestBody = {
      outletId : this.rentalForm.controls.outletsdropdown.value?this.rentalForm.controls.outletsdropdown.value:this.outletsList[0]?.id,
      isInUseRentalItemsDetails : true,
      requestStartDateTime :apiDate+'T'+apiTime
    };
    const response = await this.InvokeServiceCallAsync(RetailRoutes.GetInUseAvailableRentalItems, GlobalConst.Host.retailPOS, HttpMethod.Post,'',this.inUseAvailableRequestBody);
    this.rentalItemsDetails = response.result;
    let scheduleTeeTimePlayerIds = this.rentalItemsDetails.map(x=>x.scheduledTeeTimePlayerId);
      const res= await this.InvokeServiceCallAsync('GetScheduledTeeTimeByScheduledTeeTimePlayerIds', GlobalConst.Host.golfSchedule, HttpMethod.Put,'',scheduleTeeTimePlayerIds);
    if (res.result) {
      this.scheduleTeeTimeDetails = res?.result;
      this.scheduleTeeTimeDetails?.forEach(stt => {
        stt.scheduledTeeTimePlayers?.forEach(sttp => {
          this.rentalItemsDetails?.forEach(rid => {
            if (sttp.id == rid.scheduledTeeTimePlayerId) {
              rid.teeTime = this.localization.ConvertDateToTime(stt.scheduleDateTime),
                rid.scheduledTeeTimePlayerName = sttp.playerDetail.firstName + ' ' + sttp.playerDetail.lastName
              if (sttp.scheduledTeeTimePlayerRetailItem[0]) {
                rid.courseName = sttp.scheduledTeeTimePlayerRetailItem[0].courseName;
              }
            }
          });
        })
      })
    }
    return this.rentalItemsDetails;
  }
 async getAvailableItemList(){
  let apiDate = this.localization.convertDateToAPIdate(this.rentalForm.controls.date.value);
  let apiTime = this.localization.DeLocalizeTime(this.rentalForm.controls.time.value);
    this.inUseAvailableRequestBody = {
      outletId : this.rentalForm.controls.outletsdropdown.value?this.rentalForm.controls.outletsdropdown.value:this.outletsList[0]?.id,
      isInUseRentalItemsDetails : false,
      requestStartDateTime : apiDate+'T'+apiTime
    };
    const response = await this.InvokeServiceCallAsync(RetailRoutes.GetInUseAvailableRentalItems, GlobalConst.Host.retailPOS, HttpMethod.Post,'',this.inUseAvailableRequestBody);
    this.rentalItemsDetails = response.result;
    let itemIds = this.rentalItemsDetails?.map(x=>x.retailItemId);
    if(this._commonVarService.displayImagesForRentals && itemIds.length > 0){
      this.loadRetailItemImages(itemIds);
    }
    return this.rentalItemsDetails;
}
  async getOutOfOrderTableContent(){
    let selectedOutletId = this.rentalForm.controls.outletsdropdown.value?this.rentalForm.controls.outletsdropdown.value:this.outletsList[0]?.id
    const response = await this.InvokeServiceCallAsync(RetailRoutes.GetOutOfOrderRentalItems, GlobalConst.Host.retailManagement, HttpMethod.Get, { outletId: selectedOutletId}, '');
    this.outOfOrderTableData = response.result;
    this.outOfOrderTableData.forEach(x => {
      x.totalQuantity = x.outofOrderQuantity+ x.remainingQuantity
    })
    return this.outOfOrderTableData;
  }
  loadRetailItemImages = (retailItemIds) => {
    this.imgService.GetAllImagesByReference(retailItemIds, GlobalConst.ImgRefType.retailItem, false, this.successCallback.bind(this), this.errorCallback.bind(this), []);
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc === 'getAllImagesByReference') {
      this.retailimages = result.result as any;
      const value = [...this.mapImg(this.availableItem, this.retailimages)];
      this.availableItem = value;
    }
  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
    this._utilities.ToggleLoaderWithMessage(false);
  }
  mapImg(availableItem: any[], images: any[]) {
    images.forEach(element => {
      availableItem.forEach(itemArr => {
        if (itemArr.retailItemId== element.referenceId) {
          itemArr.image = element;
        }
      });
    });

    return availableItem;
  }
  async InvokeServiceCallAsync(route: string, domain: GlobalConst.Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async onSearch(){
    if(this.isInUse == true){
    this.tableContent = await this.getInUseTableContent();
    }
    else if(this.isAvailable == true){
      this.availableItem = await this.getAvailableItemList();
    }
    else if(this.isOutOfOrder == true){
    this.tableContent = await this.getOutOfOrderTableContent();
    }
    this.originalTableContent = this.tableContent;
    this.originalAvailableItem = this.availableItem;
    this.searchValueChange(this.searchText)
  }
}
