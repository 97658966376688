import { isArray } from "lodash";

export function groupBy(list: any[], keyProperty: string | string[]): Map<string | number, any[]> {
    
    const map = new Map();
    if(!list){
        return map;
    }
    list.forEach((item) => {
        const mapKey = isArray(keyProperty) ? keyProperty.map(key => item[key]).join('_') : item[keyProperty];
        const collection = map.get(mapKey);
        if (!collection) {
            map.set(mapKey, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export function generateCartGroupName(type, id: number, packageName: string, guestName: string): string {
    return [type, id, packageName, guestName, getRandomString() ].join("::");
}


function getRandomString(): string{
    return '#' + Math.floor(1 + Math.random() * 9) + (Math.random() + 1).toString(36).substring(2, 3) + Math.floor(10 + Math.random() * 90) + (Math.random() + 1).toString(36).substring(2, 3);
}


export function getExpireTime(expireTime): number{
    if(!expireTime){
        return 0;
    }
    let _expireTime = new Date(expireTime).getTime() - new Date(new Date().toISOString()).getTime();

    return _expireTime > 0 ? _expireTime : 0;
}

export function formatTime(time, minsText: string, secsText: string){
  return `${Math.floor(time / 60)} ${minsText} ${Math.round(Number(time % 60))} ${secsText}` ;
}

export function roundToDecimalPlaces(value, decimalPlaces):string {
    const factor = Math.pow(10, decimalPlaces);
    return (Math.round(value * factor) / factor).toFixed(decimalPlaces);
}

export function tConvert(tt) {
    let time = tt.substring(tt.indexOf('T') + 1, tt.length);
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
        time
    ];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[4] = " "; //Adjust Space
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}