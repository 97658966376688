import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { Localization } from 'src/app/common/localization/localization';
import { AgDateConfig, AgFieldConfig, AgTimeConfig, RadioOptions } from 'src/app/common/Models/ag-models';
import { CommonPropertyInformation } from '../../../services/common-property-information.service';
import { ActionMode } from '../../enums/enums';
import { CommonUtilities } from '../../utilities/common-utilities';
import { DaysModel, EventSchedulerInterval } from '../event-scheduler.model';
import { NotificationScheduleBusiness } from './notification-schedule.business';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-shared-notification-schedule',
  templateUrl: './notification-schedule.component.html',
  styleUrls: ['./notification-schedule.component.scss'],
  providers: [NotificationScheduleBusiness]
})
export class NotificationScheduleComponent implements OnInit {
  captions;
  notificationScheduleForm: UntypedFormGroup;
  eventOptions: RadioOptions[];
  endOptions: RadioOptions[];
  monthOptions: RadioOptions[];
  isRecurrenceAutoSchedule = false;
  scheduleOptions: {id: number; value: string | number; viewValue: string; isSelected: boolean; disabled: boolean}[];
  selectedScheduleType;
  isWeekDay = false;
  isDaily = true;
  isMonthly = false;
  isYearly = false;
  isWeekly = false;
  monthList: Observable<any>;
  monthsArr;
  days: DaysModel[];
  daysInput: AgFieldConfig;
  startDateInput: AgDateConfig;
  endDateInput: AgDateConfig;
  StartTimeInput: AgTimeConfig;
  recurEveryWeeksOnInput: AgFieldConfig;
  isAllSelected = false;
  monthDayInput: AgFieldConfig;
  ofEveryMonthInput: AgFieldConfig;
  ofEveryYearlyInput: AgFieldConfig;
  yearlyDaysInput: AgFieldConfig;
  isMonthFirstDay = true;
  isMonthFirstForYear = true;
  isEndDate: boolean = true;
  isOccurance: boolean;
  yearlyMonthsInput: AgFieldConfig;
  propertyDate: string;
  floatLabel: string;
  @Output() formReady: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Output() scheduleType: EventEmitter<string> = new EventEmitter();
  @Input() inputData: any;
  IsWeekChecked: boolean = false;
  mode;
  editMode = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input('crudInput')
  set setcrudInput(value){
    if(value){
      this.mode = value.mode;
    }
  }
  constructor(private localization: Localization, private fb:UntypedFormBuilder, private business: NotificationScheduleBusiness
    ,         private propertyInformation: CommonPropertyInformation,
              private utils: CommonUtilities) {
    this.captions = this.localization.captions.ReportSchedular;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.initialize();
    this.buildForm();
    this.patchFormData();
    this.notificationScheduleForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.formReady.emit(this.notificationScheduleForm);
    });
  }

  buildForm() {
    this.notificationScheduleForm = this.fb.group({
      event: this.eventOptions[0].id,
      scheduleType: this.scheduleOptions[0].value,
      days: '',
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      end: this.endOptions[0].id,
      recurEveryWeek: '',
      specificDays: this.fb.array([]),
      dayMonth: this.monthOptions[0].id,
      monthDays: '',
      yearlyDay: '',
      ofEveryMonth: '',
      yearlyDays: this.monthOptions[0].id,
      yearlyMonths: '',
      ofEveryYear: '',
      noOfOccurance: '',
      startTime: '',
      isWeekChecked: ''
    });
    this.formReady.emit(this.notificationScheduleForm);
    this.scheduleType.emit("Daily");
    this.daysInput = {
      className: 'ag_form-control--33',
      form: this.notificationScheduleForm,
      formControlName: 'days',
      placeHolderId: 'lbl_everyDays',
      placeHolder: this.captions.lbl_everyDays,
      maxlength: 2,
      isRequired: true,
      disabled: false,
      maxValue: 30,
      minValue: 1,
      maximumValueErrorMsg: this.captions.err_max,
      minimumValueErrorMsg: this.captions.err_min
    };
    this.startDateInput = {
      className: 'ag_form--control--33 ',
      form: this.notificationScheduleForm,
      formControlName: 'startDate',
      placeHolderId: 'lbl_startDate',
      placeHolder: this.captions.lbl_startDate,
      isDateRequired: true,
      errorMessage: this.captions.MissingStartDate
    };
    this.endDateInput = {
      className: 'ag_form--control--33 pr-5',
      form: this.notificationScheduleForm,
      formControlName: 'endDate',
      placeHolderId: 'lbl_endDate',
      placeHolder: this.captions.lbl_endDate,
      isDisabled: false,
      isDateRequired: true,
      errorMessage: this.captions.MissingEndDate
    };
    this.recurEveryWeeksOnInput = {
      className: 'ag_form-control--33',
      form: this.notificationScheduleForm,
      formControlName: 'recurEveryWeek',
      placeHolderId: 'lbl_everyWeeksOn',
      placeHolder: this.captions.lbl_everyWeeksOn,
      maxlength: 2,
      isRequired: false,
      disabled: false,
      maxValue: 30,
      minValue: 1,
      maximumValueErrorMsg: this.captions.err_max,
      minimumValueErrorMsg: this.captions.err_min
    };
    this.monthDayInput = {
      className: 'ag_form-control--33',
      form: this.notificationScheduleForm,
      formControlName: 'monthDays',
      placeHolderId: 'lbl_date',
      placeHolder: this.captions.tbl_hdr_days,
      maxlength: 2,
      isRequired: false,
      disabled: false,
      maxValue: 30,
      minValue: 1,
      maximumValueErrorMsg: this.captions.err_max,
      minimumValueErrorMsg: this.captions.err_min
    };
    this.ofEveryMonthInput = {
      className: 'ag_form-control--33',
      form: this.notificationScheduleForm,
      formControlName: 'ofEveryMonth',
      placeHolderId: 'lbl_everyMonths',
      placeHolder: this.captions.lbl_everyMonths,
      maxlength: 2,
      isRequired: false,
      disabled: false,
      maxValue: 12,
      minValue: 1,
      maximumValueErrorMsg: this.captions.err_max,
      minimumValueErrorMsg: this.captions.err_min
    };
    this.ofEveryYearlyInput = {
      className: 'ag_form-control--33',
      form: this.notificationScheduleForm,
      formControlName: 'ofEveryYear',
      placeHolderId: 'lbl_everyYearOf',
      placeHolder: this.captions.lbl_everyYearOf,
      maxlength: 2,
      isRequired: false,
      disabled: false,
      maxValue: 30,
      minValue: 1,
      maximumValueErrorMsg: this.captions.err_max,
      minimumValueErrorMsg: this.captions.err_min
    };
    this.yearlyMonthsInput = {
      className: 'ag_form-control--33',
      form: this.notificationScheduleForm,
      formControlName: 'yearlyMonths',
      placeHolderId: 'lbl_everyMonthOf',
      placeHolder: this.captions.lbl_everyMonthOf,
      maxlength: 2,
      isRequired: false,
      disabled: false,
      maxValue: 12,
      minValue: 1,
      maximumValueErrorMsg: this.captions.err_max,
      minimumValueErrorMsg: this.captions.err_min
    };
    this.yearlyDaysInput = {
      className: 'ag_form-control--33 ag_pr--5',
      form: this.notificationScheduleForm,
      formControlName: 'yearlyDay',
      placeHolderId: 'tbl_hdr_days',
      placeHolder: this.captions.tbl_hdr_days,
      maxlength: 2,
      isRequired: false,
      disabled: false,
      maxValue: 30,
      minValue: 1,
      maximumValueErrorMsg: this.captions.err_max,
      minimumValueErrorMsg: this.captions.err_min
    };
    this.StartTimeInput = {
      className: 'ag_form-control--sm',
      form: this.notificationScheduleForm,
      formControlName: 'startTime',
      defaultTime: '12:00 PM',
      placeHolderId:  'Start Time',
      placeHolder:  'Start Time', //localization
      isTimeRequired: true,
      errorMessageId: 'err_missingStartTime',
      errorMessage: this.captions.err_missingStartTime,
    };
    this.getDaysList();
    let minimumStartDate = new Date();
    this.startDateInput.minDate = minimumStartDate;
    this.notificationScheduleForm.controls.startDate.setValue(minimumStartDate);
    this.startDateInput = {...this.startDateInput};
    let minimumEndDate = new Date(minimumStartDate);
    minimumEndDate.setDate(minimumStartDate.getDate() + 1);
    this.endDateInput.minDate = minimumEndDate;
    this.notificationScheduleForm.controls.endDate.setValue(minimumEndDate);
    this.getDateWithoutTime(this.notificationScheduleForm.controls.startDate.value,this.notificationScheduleForm.controls.endDate.value);

  }

  getDateWithoutTime = (selectedStartDate, selectedEndDate) => {
    const startDate = this.utils.GetDateWithoutTime(selectedStartDate);
    const endDate = this.utils.GetDateWithoutTime(selectedEndDate);
    const dayDiff = this.utils.DateDiff.inDays(startDate, endDate) + 1;
    const monthDiff = this.utils.DateDiff.inMonths(startDate, endDate);
    const yearDiff = this.utils.DateDiff.inYears(startDate, endDate);
    this.scheduleOptions.forEach(repeatType => {
      switch (repeatType.id) {
        case EventSchedulerInterval.Daily:
          repeatType.disabled = dayDiff < 1;
          break;
        case EventSchedulerInterval.Weekly:
          repeatType.disabled = dayDiff < 8;
          break;
        case EventSchedulerInterval.Monthly:
          repeatType.disabled = monthDiff < 1;
          break;
        case EventSchedulerInterval.Yearly:
          repeatType.disabled = yearDiff < 1;
          break;
      }
    });
    const selectedOccuranceValues = this.selectedScheduleType && this.selectedScheduleType.length > 0 ? this.scheduleOptions.filter(selectedOccurances => selectedOccurances.id == Number(this.selectedScheduleType)) : [];
    if (selectedOccuranceValues && selectedOccuranceValues.length > 0 && selectedOccuranceValues[0].disabled) {
      this.notificationScheduleForm.controls.scheduleType.setValue(this.selectedScheduleType);
      this.onToggleChange({ value: "1" }, false);
    }
  }

  initialize() {
    // this.propertyDate = this.propertyInformation.CurrentDate;
    this.propertyDate = this.localization.getDate(this.propertyDate).toString();
    this.eventOptions = this.business.getEventOptionsList();
    this.scheduleOptions = this.business.generateScheduleButtons();
    this.selectedScheduleType = this.scheduleOptions[0].value;
    this.endOptions = this.business.getEndOptionsList();
    this.monthOptions = this.business.getMonthDayOptionsList();
  }

  getDaysList() {
    this.days = this.business.getDaysArray();
    const formArray = this.notificationScheduleForm.get('specificDays') as UntypedFormArray;
    this.days.forEach(x => formArray.push(new UntypedFormControl(false)));
  }

  onAllCheck(event) {
    this.isAllSelected = event.checked;
    this.notificationScheduleForm.controls.specificDays['controls'].forEach(control => control.setValue(event.checked));
    this.notificationScheduleForm.markAsDirty();
    this.checkWeekSelected();
  }

  updateAll(eve?) {
    this.isAllSelected = this.notificationScheduleForm.value.specificDays.every(value => value);
    this.checkWeekSelected();
  }

  checkWeekSelected() {
   const formArray = this.notificationScheduleForm.get('specificDays') as  UntypedFormArray;
   let res = formArray.value.filter(x => x === true);
   if (res.length>0) {
    this.notificationScheduleForm.controls.isWeekChecked.setValue(true);
   } else {
    this.notificationScheduleForm.controls.isWeekChecked.setValue('');
   }
  }

  eventChangingOption(e) {
    this.isRecurrenceAutoSchedule = (e.value === 2) ? true : false;
    if(this.isRecurrenceAutoSchedule){
        this.notificationScheduleForm.controls.scheduleType.disable(),
        this.notificationScheduleForm.controls.days.disable(),
        this.notificationScheduleForm.controls.startDate.disable(),
        this.notificationScheduleForm.controls.endDate.disable(),
        this.notificationScheduleForm.controls.end.disable(),
        this.notificationScheduleForm.controls.recurEveryWeek.disable(),
        this.notificationScheduleForm.controls.specificDays.disable(),
        this.notificationScheduleForm.controls.dayMonth.disable(),
        this.notificationScheduleForm.controls.monthDays.disable(),
        this.notificationScheduleForm.controls.yearlyDay.disable(),
        this.notificationScheduleForm.controls.ofEveryMonth.disable(),
        this.notificationScheduleForm.controls.yearlyDays.disable(),
        this.notificationScheduleForm.controls.yearlyMonths.disable(),
        this.notificationScheduleForm.controls.ofEveryYear.disable(),
        this.notificationScheduleForm.controls.noOfOccurance.disable(),
        this.notificationScheduleForm.controls.startTime.disable(),
        this.notificationScheduleForm.controls.isWeekChecked.disable();
    }
    else{
        this.notificationScheduleForm.controls.scheduleType.enable(),
        this.notificationScheduleForm.controls.days.enable(),
        this.notificationScheduleForm.controls.startDate.enable(),
        this.notificationScheduleForm.controls.endDate.enable(),
        this.notificationScheduleForm.controls.end.enable(),
        this.notificationScheduleForm.controls.recurEveryWeek.enable(),
        this.notificationScheduleForm.controls.specificDays.enable(),
        this.notificationScheduleForm.controls.dayMonth.enable(),
        this.notificationScheduleForm.controls.monthDays.enable(),
        this.notificationScheduleForm.controls.yearlyDay.enable(),
        this.notificationScheduleForm.controls.ofEveryMonth.enable(),
        this.notificationScheduleForm.controls.yearlyDays.enable(),
        this.notificationScheduleForm.controls.yearlyMonths.enable(),
        this.notificationScheduleForm.controls.ofEveryYear.enable(),
        this.notificationScheduleForm.controls.noOfOccurance.enable(),
        this.notificationScheduleForm.controls.startTime.enable(),
        this.notificationScheduleForm.controls.isWeekChecked.enable();
    }
  }

  onToggleChange(e, fromUI) {
    this.selectedScheduleType = e.value;
    this.scheduleOptions.forEach(x => {
      if (x.value === e.value) {
        x.isSelected = e.source?.checked? e.source.checked : true;
      } else {
        x.isSelected = false;
      }
    })
    this.scheduleType.emit(e.value);
    switch (Number(e.value)) {
      case EventSchedulerInterval.Weekly:
        this.isWeekly = true;
        this.isMonthly = false;
        this.isDaily = false;
        this.isYearly = false;
        //this.setValidator('recurEveryWeek');
        this.setValidator('isWeekChecked');
        this.removeValidator('days');
        this.removeValidator('monthDays');
        this.removeValidator('ofEveryMonth');
        this.removeValidator('ofEveryYear');
        this.removeValidator('yearlyDay');
        this.removeValidator('yearlyMonths');
        this.notificationScheduleForm.controls.days.setValue(null);
        this.notificationScheduleForm.controls.dayMonth.setValue(this.monthOptions[0].id);
        this.notificationScheduleForm.controls.monthDays.setValue(null);
        this.notificationScheduleForm.controls.ofEveryMonth.setValue(null);
        this.notificationScheduleForm.controls.yearlyDay.setValue('');
        this.notificationScheduleForm.controls.yearlyDays.setValue(this.monthOptions[0].id);
        this.notificationScheduleForm.controls.yearlyMonths.setValue(null);
        this.notificationScheduleForm.controls.ofEveryYear.setValue(null);
        break;
      case EventSchedulerInterval.Daily:
        this.isWeekly = false;
        this.isMonthly = false;
        this.isYearly = false;
        this.isDaily = true;
        this.setValidator('days');
        this.removeValidator('recurEveryWeek');
        this.removeValidator('monthDays');
        this.removeValidator('ofEveryMonth');
        this.removeValidator('ofEveryYear');
        this.removeValidator('yearlyDay');
        this.removeValidator('isWeekChecked');
        this.removeValidator('yearlyMonths');
        this.notificationScheduleForm.controls.recurEveryWeek.setValue('');
        this.notificationScheduleForm.controls.yearlyDay.setValue('');
        this.notificationScheduleForm.controls.monthDays.setValue(null);
        this.notificationScheduleForm.controls.ofEveryMonth.setValue(null);
        this.notificationScheduleForm.controls.ofEveryYear.setValue(null);
        this.notificationScheduleForm.controls.yearlyDays.setValue(this.monthOptions[0].id);
        this.notificationScheduleForm.controls.yearlyMonths.setValue(null);
        this.notificationScheduleForm.controls.dayMonth.setValue(this.monthOptions[0].id);
        this.onAllCheck({checked: false});
        this.isAllSelected = false;
        break;
      case EventSchedulerInterval.Monthly:
        this.isWeekly = false;
        this.isMonthly = true;
        this.isYearly = false;
        this.isDaily = false;
        this.isMonthFirstDay = true;
        if (this.isMonthFirstDay) {
          this.setValidator('monthDays');
        }
        if (this.inputData && (this.mode === ActionMode.update)) {
          // this.isMonthFirstDay = Boolean(this.inputData && this.inputData.monthDays && this.editMode ? this.inputData.monthDays : true);
          if (this.editMode && !fromUI) {
            this.isMonthFirstDay = this.inputData && this.inputData.monthDays ? true : false;
          } else {
            this.isMonthFirstDay = true;
          }
          if (!this.isMonthFirstDay) {
            this.removeValidator('monthDays');
          }
          else {
            this.setValidator('monthDays');
          }
        }
        this.setValidator('ofEveryMonth');
        this.removeValidator('days');
        this.removeValidator('recurEveryWeek');
        this.removeValidator('ofEveryYear');
        this.removeValidator('yearlyDay');
        this.removeValidator('isWeekChecked');
        this.removeValidator('yearlyMonths');
        this.notificationScheduleForm.controls.recurEveryWeek.setValue('');
        this.notificationScheduleForm.controls.yearlyDay.setValue('');
        this.notificationScheduleForm.controls.days.setValue(null);
        this.notificationScheduleForm.controls.ofEveryYear.setValue(null);
        this.notificationScheduleForm.controls.yearlyDays.setValue(this.monthOptions[0].id);
        this.notificationScheduleForm.controls.yearlyMonths.setValue(null);

        this.isAllSelected = false;
        this.onAllCheck({checked: false});
        break;
      case EventSchedulerInterval.Yearly:
        this.isWeekly = false;
        this.isMonthly = false;
        this.isYearly = true;
        this.isDaily = false;

        this.isMonthFirstForYear = true;
        if (this.isMonthFirstForYear) {
          this.setValidator('yearlyDay');
        }
        if (this.inputData && (this.mode === ActionMode.update)) {
          // this.isMonthFirstForYear = Boolean(this.inputData && this.inputData.yearlyDay && this.editMode ? this.inputData.yearlyDay : true);
          if (this.editMode && !fromUI) {
            this.isMonthFirstForYear = this.inputData && this.inputData.yearlyDay ? true : false;
          } else {
            this.isMonthFirstForYear = true;
          }
          if (!this.isMonthFirstForYear) {
            this.removeValidator('yearlyDay');
          }
          else {
            this.setValidator('yearlyDay');
          }
        }
        this.setValidator('ofEveryYear');
        this.setValidator('yearlyDay');
        this.setValidator('yearlyMonths');
        this.removeValidator('days');
        this.removeValidator('monthDays');
        this.removeValidator('recurEveryWeek');
        this.removeValidator('ofEveryMonth');
        this.removeValidator('isWeekChecked');
        this.notificationScheduleForm.controls.recurEveryWeek.setValue('');
        this.notificationScheduleForm.controls.monthDays.setValue(null);
        this.notificationScheduleForm.controls.days.setValue(null);
        this.notificationScheduleForm.controls.ofEveryMonth.setValue(null);
        this.notificationScheduleForm.controls.dayMonth.setValue(this.monthOptions[0].id);
        this.isAllSelected = false;
        this.onAllCheck({checked: false});
        break;
    }
    this.notificationScheduleForm.markAsUntouched();
    this.daysControlMandatoryCheck();
  }

  EnableAllOption() {
    this.scheduleOptions.forEach(repeatType => { repeatType.disabled = false; });
  }


  DisableAllOption(id) {
    if (id) {
      this.scheduleOptions.forEach((repeatType, i) => {
        repeatType.disabled = true;
      });
    }
  }
  daysControlMandatoryCheck() {
    this.daysInput.isRequired = this.isDaily;
    this.daysInput = { ...this.daysInput };
    this.recurEveryWeeksOnInput.isRequired = this.isWeekly;
    this.recurEveryWeeksOnInput = { ...this.recurEveryWeeksOnInput };
    this.monthDayInput.isRequired = this.isMonthly;
    this.monthDayInput = { ...this.monthDayInput };
    this.ofEveryMonthInput.isRequired = this.isMonthly;
    this.ofEveryMonthInput = { ...this.ofEveryMonthInput };
    this.ofEveryYearlyInput.isRequired = this.isYearly;
    this.ofEveryYearlyInput = { ...this.ofEveryYearlyInput };
    this.yearlyDaysInput.isRequired = this.isYearly;
    this.yearlyDaysInput = { ...this.yearlyDaysInput };
    this.yearlyMonthsInput.isRequired = this.isYearly;
    this.yearlyMonthsInput = { ...this.yearlyMonthsInput };
  }

  setValidator(controlName) {
    if (controlName && this.notificationScheduleForm.controls[controlName]) {
      this.notificationScheduleForm.get(controlName).setValidators([Validators.required]);
      this.notificationScheduleForm.get(controlName).updateValueAndValidity();
    }
  }

  removeValidator(controlName) {
    if (controlName && this.notificationScheduleForm.controls[controlName]) {
      this.notificationScheduleForm.get(controlName).clearValidators()
      this.notificationScheduleForm.get(controlName).updateValueAndValidity();
    }
  }

  monthChangingOption(e?) {
    this.editMode = false;
    this.isMonthFirstDay = (e && (e.value === 2 || e.value === 3)) ? false : true;
    if (!this.isMonthFirstDay) {
      this.removeValidator('monthDays');
      this.notificationScheduleForm.controls.monthDays.setValue(null);
    }
  }

  monthChangingOptionYear(e?) {
    this.editMode = false;
    this.isMonthFirstForYear = (e && (e.value === 2 || e.value === 3)) ? false : true;
    if (!this.isMonthFirstForYear) {
      this.removeValidator('yearlyDay');
      this.notificationScheduleForm.controls.yearlyDay.setValue(null);
    }
  }

  onEndChange(e?) {
    switch (this.notificationScheduleForm.value.end) {
      case 1:
        this.isEndDate = true;
        this.isOccurance = false;
        this.setValidator('endDate');
        this.startDateChange(null);
        //this.removeValidator('noOfOccurance');
        this.notificationScheduleForm.controls.noOfOccurance.setValue('');
        this.DisableAllOption(e?.value);
        break;
      case 2:
        this.isEndDate = false;
        //this.isOccurance = true;
        //this.setValidator('noOfOccurance');
        this.removeValidator('endDate');
        this.notificationScheduleForm.controls.endDate.setValue('');
        this.EnableAllOption();
        break;
      case 3:
        this.isEndDate = false;
        this.isOccurance = false;
        this.removeValidator('endDate');
        this.removeValidator('noOfOccurance');
        this.notificationScheduleForm.controls.endDate.setValue('');
        this.notificationScheduleForm.controls.noOfOccurance.setValue('');
        this.EnableAllOption();
        break;
    }

    this.endDateInput.isDateRequired = this.isEndDate;
    this.endDateInput = { ...this.endDateInput };

  }

  patchFormData() {
    if (this.inputData) {
      if (this.mode === ActionMode.update) this.editMode = true;
      this.notificationScheduleForm.patchValue(this.inputData);
      this.onToggleChange({ value: this.notificationScheduleForm.value.scheduleType }, false);
      this.onEndChange();
      this.updateAll();
      this.notificationScheduleForm.markAsPristine();
      this.notificationScheduleForm.controls.startDate.markAsTouched();
      this.notificationScheduleForm.controls.startDate.updateValueAndValidity();
    }
    else{
        this.notificationScheduleForm.controls.event.setValue(this.eventOptions[1].id);
        this.eventChangingOption({value: 2});
    }
  }

  startDateChange(event) {
    let endDate = new Date(this.notificationScheduleForm.value.startDate);
    if (this.notificationScheduleForm.value.end == 1) {
      endDate.setDate(endDate.getDate() + 1);
      this.endDateInput.minDate = endDate;
      if (this.notificationScheduleForm.value.startDate >= this.notificationScheduleForm.value.endDate) {
        this.notificationScheduleForm.controls.endDate.setValue(endDate);
      }
      this.endDateInput = { ...this.endDateInput };
      this.getDateWithoutTime(this.notificationScheduleForm.value.startDate, this.notificationScheduleForm.value.endDate);
    }
    else if (this.notificationScheduleForm.value.end == 2) {
      this.notificationScheduleForm.controls.endDate.setValue('');
    }
  }

  endDateChange(event) {
    this.getDateWithoutTime(this.notificationScheduleForm.value.startDate,this.notificationScheduleForm.value.endDate);
  }

}
