import { Utilities } from '@app/shared/utilities/utilities';
import { ChangeAction } from '@constants/commonenums';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { CoverTypeQuantityDTO, ReservationDTO } from '@models/InputContact';
import { BookingContactAddonItemDTO, PartyMessageDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import _, { uniqBy } from 'lodash';
import { CacheService } from '../services/cache.service';
import { Processor } from './processor';

export class PartyCoverTypeChangeProcessor implements Processor {

  private static instance: PartyCoverTypeChangeProcessor;
  private constructor(public partyService: PartyService, public cs: CacheService) { }

  public static Instance(partyService: any = PartyService, cs: CacheService): PartyCoverTypeChangeProcessor {
    if (!PartyCoverTypeChangeProcessor.instance) {
        PartyCoverTypeChangeProcessor.instance = new PartyCoverTypeChangeProcessor(partyService, cs);
    }

    return PartyCoverTypeChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: object, propertyId: number): void {
    let partiesList =[];
    let standBy = false;
    if (objectChange) {
      const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'PartyId').Value;
      if(this.partyService.StandbyParties$.value.filter(party =>party.Id == partyId).length > 0){
        partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.StandbyParties$.value : this.cs.propertySettings.value[propertyId].StandbyParties;
        standBy = true;       
      }
      else{
        partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.Parties$.value : this.cs.propertySettings.value[propertyId].reservations;
        standBy= false;
      }
      if (objectChange.Action === ChangeAction.Created) {
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'PartyId').Value;
        const party = partiesList.find(party => party.Id == partyId);
        
        if (party) {
            if(!party.CoverTypeQuantities){
                party.CoverTypeQuantities = [];
            }
          const partyContactAddon: CoverTypeQuantityDTO = new CoverTypeQuantityDTO();
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyContactAddon).includes(property.PropertyName.replace('Internal', ''))) {
                partyContactAddon[property.PropertyName.replace('Internal', '')] = property.Value;
            }
          });
          party.CoverTypeQuantities.push(partyContactAddon)
          party.CoverTypeQuantities = uniqBy(party.CoverTypeQuantities, 'Id');
          partiesList[partiesList.indexOf(party)] = party;
        }
      }
   
      if (objectChange.Action === ChangeAction.Updated) {
        const updatedPartyContactAddonId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party: ReservationDTO = partiesList.find(party => party.Id == partyId);
        if (party) {
            const partyContactAddon: CoverTypeQuantityDTO = party.CoverTypeQuantities.find(addon => addon.Id == updatedPartyContactAddonId);
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyContactAddon).includes(property.PropertyName.replace('Internal', ''))) {
                partyContactAddon[property.PropertyName.replace('Internal', '')] = property.Value;
            }
          });
          party.CoverTypeQuantities[party.CoverTypeQuantities.indexOf(partyContactAddon)] = partyContactAddon;
          partiesList[partiesList.indexOf(party)] = party;
          if (propertyId == Utilities.RestaurantId() && this.partyService.selectedParty$.value && this.partyService.selectedParty$.value.Id == party.Id) {
            this.partyService.selectedParty$.next(party);
          }
        }
      }
      if (objectChange.Action === ChangeAction.Removed) {
        const removedPartyContactAddonId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party: ReservationDTO = partiesList.find(party => party.Id == partyId);
        // Cancelled reservation has to be removed from the list and from the screen
        if (party) {
            const partyContactAddon: CoverTypeQuantityDTO = party.CoverTypeQuantities.find(addon => addon.Id == removedPartyContactAddonId);
          if (partyContactAddon) {
            party.CoverTypeQuantities = party.CoverTypeQuantities.filter(addon => addon.Id != removedPartyContactAddonId);
          }
          partiesList[partiesList.indexOf(party)] = party;
        }
      }
    }
    partiesList = _.uniqBy(partiesList, 'Id');
    if (propertyId == Utilities.RestaurantId()) {
      if(standBy) {
        this.partyService.StandbyParties$.next(partiesList);
        this.partyService.standbyPartiesList = partiesList;
        this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      } else {
        this.partyService.Parties$.next(partiesList);
        this.partyService.partiesList = partiesList;
        this.cs.propertySettings.value[propertyId].reservations = partiesList;
      }
    } else {
      if (standBy) {
        this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      } else {
         this.cs.propertySettings.value[propertyId].reservations = partiesList;
      }
    }
  }
}
