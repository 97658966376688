<div class="member-select">
    <dynamic-form [config]="membershipDetailConfig" #form="dynamicForm">
    </dynamic-form>
    <ng-container *ngIf="showSelectedGuest && memberShipdetail?.FName !== undefined">
        <label class="member-item" for="">{{'guestName' | translate}}</label>
        <p>{{memberShipdetail?.FName}} {{memberShipdetail?.LName}}</p>
        <label class="member-item" for="">{{'guestBookDetailsEmail' | translate}}</label>
        <p>{{memberShipdetail?.Email}}</p>
        <label class="member-item" for="">{{'guestBookDetailsMobile' | translate}}</label>
        <p>{{memberShipdetail?.PhoneNumber}}</p>
        <label class="member-item" for="">{{'CardNo' | translate}}</label>
        <p>{{memberShipdetail?.CardNo}}</p>
        <!-- <label class="member-item" for="" *ngIf="partyService.selectedGuest?.MembershipType">{{'MemberType' |
          translate}}</label> -->
        <!-- <p *ngIf="partyService.selectedGuest?.MembershipType" class="test">
          {{partyService.selectedGuest?.MembershipType?.AllocationName}}<span class="custom-error"
            *ngIf="partyService.selectedGuest?.MembershipType && !partyService.selectedGuest?.MemberActive">({{'Inactive'
            | translate}})</span></p> -->
      </ng-container>
</div>