import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { FormControl, UntypedFormGroup, ValidatorFn, Validators, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { Subscription } from 'rxjs/Subscription';
import { SettingsService } from '@app/shared/services/settings.service';
import { LessonType, ClassType, PricingBy, Status, buttonTypes, DayOfWeek, PaymentType, ComponentTypes, AttributeType, CategoryType, RatePlanPricingTypes, PaymentMethod, NotifyType, NotifyPeriod } from '@app/shared/constants/commonenums'
import _, { AnyKindOfDictionary, cloneDeep } from 'lodash';
import { SpecialMealDTO, BookingTypeActivitiesMappingDTO, ActivitySessionsDTO, CollectType, PrePaymentMode, CoverTypeShortDTO, BookingTypeShortDTO, BookingChargeType, BookingChargeActivityMappingDTO, OpenHoursDTO, LocationGroupDTO, AssetBookingType } from '@models/RestaurantDTO'
import { Utilities } from '@app/shared/utilities/utilities';
import moment from 'moment';
import { ActivityCreateFunctions } from "@app/shared/utilities/activity-create-functions";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { additionalInfo, days, popupDialogDimension } from '@constants/globalConstants';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { ConfirmationPopupComponent } from '../../confirmation-popup/confirmation-popup.component';
import { PopupService } from '@app/popup-module/popup.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CacheService } from '@core/services/cache.service';
import { AppService } from '@app/app.service';
import { formValidation } from '../session-mapping/session-mapping.component';
import { PartyService } from '@app/shared/services/party.service';
import { CRSPaymentHandlerComponent } from '@app/crs/crs-payment-handler/crs-payment-handler.component';
import { BookingTypeConfigurationComponent } from '../booking-type-configuration/booking-type-configuration.component';
import { ExcludeLocationGroupPipe } from '@app/shared/pipes/ExcludeLocationGroup.pipe';
import { GuestLinkType } from '@app/shared/models/FormCodeDTO';
@Component({
   selector: 'app-basic-setup',
   templateUrl: './basic-setup.component.html',
   styleUrls: ['./basic-setup.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class BasicSetupComponent extends Utilities implements OnInit, OnDestroy {
   @ViewChild('lessonDetails', { static: true }) classLessonDetailsForm: DynamicFormComponent;
   @ViewChild('basicSetup', { static: true }) basicSetupForm: DynamicFormComponent;
   @ViewChild('activitySetting', { static: true }) activitySettingForm: DynamicFormComponent;
   @ViewChild('OnlineReservation', { static: true }) OnlineReservationForm: DynamicFormComponent;
   @ViewChild('ticketPrint', { static: true }) ticketPrintForm: DynamicFormComponent;
   @ViewChild('rafting', { static: true }) raftingForm: DynamicFormComponent;
   @ViewChild('guestType', { static: true }) guestTypeForm: DynamicFormComponent;
   @ViewChild('bookingType', { static: true }) bookingTypeForm: DynamicFormComponent;
   @ViewChild('calendarDetails', { static: true }) calendarDetailsForm: DynamicFormComponent;
   @ViewChild('addonDetails', { static: true }) addonDetailsForm: DynamicFormComponent;
   @ViewChild('paymentDetails', { static: true }) paymentDetailsForm: DynamicFormComponent;
   @ViewChild('cancellationDetails', { static: true }) cancellationDetailsForm: DynamicFormComponent;
   @ViewChild('collectDepositDetails', { static: true }) collectDepositForm: DynamicFormComponent;
   @ViewChild('checkinCheckOutDetils', { static: true }) checkinCheckOutForm: DynamicFormComponent;
   @ViewChild('diferredDetails', { static: true }) diferredForm: DynamicFormComponent;
   @ViewChild('guestSettings', { static: true }) guestSettingsForm: DynamicFormComponent;
   @ViewChild('AllowAdditionalGuest', { static: true }) allowAdditionalGuestForm: DynamicFormComponent;
   @ViewChild('additionalSettings', { static: true }) additionalSettingsForm: DynamicFormComponent;
   @ViewChild('emailNotification', { static: true }) emailNotificationsForm: DynamicFormComponent;
   @ViewChild('shortNotice', { static: true }) shortNotificationForm: DynamicFormComponent;
   @ViewChild('additionalEmployee', { static: true }) additionalEmployeeForm: DynamicFormComponent;
   @ViewChild('remainderConfiguration', { static: true }) reminderConfigForm: DynamicFormComponent;
   @ViewChildren('form') components: QueryList<DynamicFormComponent>;
   @Input() activityData;
   @Input() isEdit;
   @Input() isTemplate;
   @Input() currentRestaurantDateTime;
   @Output() cancelEvent: EventEmitter<any> = new EventEmitter<any>();
   @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
   classLessonDetailsConfig: FieldConfig[];
   basicSetupConfig: FieldConfig[] = [];
   reservationTypeConfig: FieldConfig[] = [];
   activitySettingConfig: FieldConfig[] = [];
   slotSettingConfig: FieldConfig[] = [];
   ticketPrintConfig: FieldConfig[] = [];
   raftingConfig: FieldConfig[] = [];
   guestTypeConfig: FieldConfig[] = [];
   bookingTypeConfig: FieldConfig[] = [];
   calendarDetailsConfig: FieldConfig[] = [];
   paymentDetailsConfig: FieldConfig[] = [];
   defaultPaymentTypeConfig: FieldConfig[] = [];
   addonDetailsConfig: FieldConfig[] = [];
   collectDepositConfig: FieldConfig[] = [];
   checkinCheckOutConfig: FieldConfig[] = [];
   diferredConfig: FieldConfig[] = [];
   cancellationConfig: FieldConfig[] = [];
   additionalSettingsConfig: FieldConfig[] = [];
   MaxStandByStrengthConfig: FieldConfig[] = [];
   AllowStandByConfig: FieldConfig[] = [];
   AllowOverbookConfig: FieldConfig[] = [];
   IsForStandbyReservationsConfig: FieldConfig[] = [];
   AutoConfirmStandbyConfig: FieldConfig[] = [];
   paymentAvailableConfig: FieldConfig[] = [];
   guestLinkConfig: FieldConfig[] = [];
   serviceChargeConfig: FieldConfig[] = [];
   taxConfig: FieldConfig[] = [];
   formCodeConfig: FieldConfig[] = [];
   guestSettingsConfig: FieldConfig[] = [];
   AllowAdditionalGuestConfig: FieldConfig[] = [];
   OnlineReservationConfig: FieldConfig[] = [];
   emailNotificationConfig: FieldConfig[] = [];
   notifyStaffConfig: FieldConfig[] = [];
   shortNoticeConfig: FieldConfig[] = [];
   notificationCutOffConfig: FieldConfig[] = [];
   remainderConfig: FieldConfig[] = [];
   subscriptions: Subscription = new Subscription();
   activeMenuId: number = 1;
   sideMenuLists: any[] = [];
   basicSettings: any;
   activity: SpecialMealDTO;
   restaurantId: Number;
   addonsFromGroup: UntypedFormGroup;
   batchFormGroup: UntypedFormGroup;
   saveButton: ButtonValue;
   cancelButton: ButtonValue;
   templateButton: ButtonValue;
   addBatchButton: ButtonValue;
   saveBatchButton: ButtonValue;
   addTimeRangeButton: ButtonValue;
   sessionAvailableDaysOfWeek: any[] = [];
   allocationList: any[];
   locationList: any[];
   staffList: any[];
   sessionTempId: number = 1;
   activeDayOfWeek: number = 0;
   mealData: any;
   addonsList: any[] = [];
   IsPrepaymentRequire: boolean = false;
   PrePaymentModeType = PrePaymentMode.None;
   GuestLinkModeType = GuestLinkType.None;
   IsForStandbyReservations: boolean = false;
   AllowStandBy: boolean = false;
   AllowOverbook: boolean = false;
   classType: number = ClassType.Session;
   sessionGroups: any[] = [];
   public popover: NgbPopover;
   allSelected: boolean = false;
   currentExpandedPanel;
   applyCloneButton: ButtonValue;
   daysOfWeek: any = [];
   disableCloneButton: boolean = false;
   isRetailEnabledProperty: boolean;
   isServiceChargeAvailable: boolean;
   warningMessgaes: string;
   disableAddTimeRangeButon: boolean;
   days = days.map((day, index) => { return { id: index, day: day.slice(0, 3), selected: false , hasBookings: false } });
   cloneWeekDays = days.map((day, index) => { return { id: index, day: day.slice(0, 3), selected: false, hasBookings: false } });
   selectedCloneDay;
   InvalidSessionDays = [];
   allFormGroupList: any[] = [];
   requiredFieldValidation: boolean = false;
   updateAddonSize: boolean = false;
   activityName: string;
   partySize = {
      MinPartySize: 1,
      MaxPartySize: 1,
   }
   secondaryGuestSettings = { PrimaryContacts: false, SecondaryContacts: false, MembershipDetails: false, SpecialOccasions: false, ContactNotes: false, CustomGuestFields: false }
   guestTypeFormGroup: UntypedFormGroup;
   masterCategories: any;
   disableSendAllCommunication: boolean = true;
   additionalEmployeeConfig: FieldConfig[] = [];
   bookingsTypeSetting = [];
   locationGroup: LocationGroupDTO[];
   openHours: string;
   statusMaster = Status;
   showNotificationMinutes: boolean = false;
   constructor(public dialog: MatDialog, public partyService: PartyService, private ts: TranslateService, public ss: SettingsService, private activityCreateFunctions: ActivityCreateFunctions, private _fb: UntypedFormBuilder, private cs: CacheService, public ps: PopupService, public appService: AppService, public excludeLocationGroupPipe: ExcludeLocationGroupPipe) {
      super(dialog)
   }

   ngOnInit() {
      this.restaurantId = Utilities.RestaurantId();
      this.basicSettings = this.activityData;
      this.mealData = this.activityData?.SpecialMealDTO || null;
      this.activityName = this.isEdit ? (this.activityData?.SpecialMealDTO?.Name) : ''
      this.classType = this.mealData?.ClassType || ClassType.Session;
      this.IsForStandbyReservations = this.mealData?.IsForStandbyReservations || false;
      this.AllowStandBy = this.mealData?.AllowStandBy || false;
      this.AllowOverbook = this.mealData?.AllowOverbook || false;
      this.IsPrepaymentRequire = this.mealData?.DoesRequirePrepayment || false;
      this.PrePaymentModeType = this.mealData?.PrePaymentMode || PrePaymentMode.None;
      this.GuestLinkModeType = this.mealData?.GuestLinkType || GuestLinkType.None;
      this.activeDayOfWeek = new Date(this.activityData?.SpecialMealDTO?.StartDate).getDay() || new Date().getDay();
      this.isRetailEnabledProperty = Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)
      this.locationGroup = this.cs.settings.value?.LocationGroups?.filter(group => group.StatusCode == Status.Approved) || [];
      if (this.mealData?.DoesRequirePrepayment) { }
      this.batchFormGroup = this._fb.group({
         batch_Rows: this._fb.array([])
      })
      this.guestTypeFormGroup = this._fb.group({
         guestType_Rows: this._fb.array([])
      });
      this.setSideMenuList();
      this.loadButtonconfig();
      this.getLessonByIdAndRestaurant(this.activityData);
      this.addBasicSetupConfig();
      this.addBookingDetailsConfig();
      this.addActivitySetupConfig();
      this.addCalenderDetailsConfig();
      this.add_AddonSetupConfig();
      this.getpaymentDetailsConfig();
      this.getcancellationDetailsConfig();
      this.getGuestSettingsConfig();
      this.getOnlineReservationConfig();
      this.getadditionalSettingsConfig();
      this.checkSessionAvailableForDay();
      this.getFormCodeConfig();
   }
   ngOnChanges(changes: SimpleChanges) {

   }
   setSideMenuList() {
      this.sideMenuLists = [
         { id: 1, name: 'BasicSetup', icon: 'icon-settings' },
         { id: 3, name: 'CalendarDetails', icon: 'icon-Calender' },
         { id: 4, name: 'addons', icon: 'icon-addons1' },
         { id: 5, name: 'PaymentDetails', icon: 'icon-Payment' },
         { id: 6, name: 'CancellationDetails', icon: 'icon-res-payment-notpaid' },
         { id: 7, name: 'guestSettings', icon: '' },
         { id: 8, name: 'onlineReservationSettings', icon: '' },
         { id: 9, name: 'additionalSettings', icon: 'icon-res-payment-notpaid' },
      ]
   }
   ngAfterViewInit() {
      this.formValueChanges();
      this.getRatePlanOptions();
      // this.paymentDetailsConfig[1].options =  this.getSericeChargeOptions();
      this.getDateRange();
      this.dynamicFormValidation();
   }
   formValueChanges() {
      Object.keys(this.basicSetupForm.form.controls).forEach((key, value) => {
         this.subscriptions.add(this.basicSetupForm.form.controls[key].valueChanges.subscribe(data => {
            console.log(data);
            if (key == 'LessonType') {
               let classType = []
               for (let i = 0; i < Object.keys(ClassType).length / 2; i++) {
                  classType.push({
                     id: i,
                     value: this.ts.instant('By' + ClassType[i]),
                  })
               }
               if (data == LessonType.PrivateLesson) {
                  this.basicSetupConfig[1].options = classType.filter(option => option.id == ClassType.Duration);
                  this.basicSetupConfig[1].value = ClassType.Duration
                  this.classType = ClassType.Duration
                  this.IsForStandbyReservationsConfig[0].value = false;
                  let [IsForStandbyReservations, AllowoverBook, Allowstandby] = this.components
                  // IsForStandbyReservations.form.controls.IsForStandbyReservations.disable();
                  AllowoverBook.form.controls.AllowOverbook.setValue(false)
                  AllowoverBook.form.controls.AllowOverbook.disable();
                  Allowstandby.form.controls.AllowStandBy.setValue(false)
                  Allowstandby.form.controls.AllowStandBy.disable();
                  this.getRatePlanOptions();
               } else {
                  this.basicSetupConfig[1].options = classType.filter(option => option.id != ClassType.Duration);
                  this.basicSetupConfig[1].value = ClassType.Session
                  this.classType = ClassType.Session
                  this.IsForStandbyReservationsConfig[0].value = false;
                  let [IsForStandbyReservations, AllowoverBook, Allowstandby] = this.components
                  IsForStandbyReservations.form.controls.IsForStandbyReservations.enable();
                  AllowoverBook.form.controls.AllowOverbook.disable();
                  Allowstandby.form.controls.AllowStandBy.disable();
               }
               this.IsForStandbyReservations = this.IsForStandbyReservations ?? false;
               this.addBookingDetailsConfig();
               this.activitySettingConfig = [...this.activitySettingConfig];
               this.basicSetupForm.form.controls.ClassType.setValue(this.classType);
            }
            if (key == 'ClassType') {
               this.classType = data;
               this.ss.activitySessionList = [];
               this.timeRangeActions();
               if (this.batchFormGroup?.value.batch_Rows?.length == 0) {
                  this.addBatchName();
               }
               this.addNewSesionTimeRange(this.activeDayOfWeek);
               this.checkSessionAvailableForDay();
               this.getRatePlanOptions();
            }
            if (this.sessionGroups.length == 0 && this.classType == ClassType.Class) {
               this.warningMessgaes = this.ts.instant('batchNameWarning');
            } else {
               this.warningMessgaes = ''
            }
         }))
      })
      Object.keys(this.calendarDetailsForm.form.controls).forEach((key, value) => {
         this.subscriptions.add(this.calendarDetailsForm.form.controls[key].valueChanges.pipe(debounceTime(100),
            distinctUntilChanged()).subscribe(data => {
               if ((moment(this.calendarDetailsForm.form.controls.StartDate.value).format('YYYY-MM-DD') >= moment(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)).format('YYYY-MM-DD')) && (new Date(this.calendarDetailsForm.form.controls.EndDate.value) < new Date(this.calendarDetailsForm.form.controls.StartDate.value))) {
                  this.calendarDetailsConfig[1].minDate = new Date(this.calendarDetailsForm.form.controls.StartDate.value);
                  this.calendarDetailsForm.form.controls.EndDate.setValue(new Date(this.calendarDetailsForm.form.controls.StartDate.value));
               }
               else {
                  this.calendarDetailsConfig[1].minDate = new Date(this.calendarDetailsForm.form.controls.StartDate.value);
               }
               //              new Date(activitySelected.StartDate) > restaurantDate ? new Date(activitySelected.StartDate) : restaurantDate;

               if (key == 'StartDate' || key == 'EndDate') {
                  this.getDateRange();
                  this.checkSessionAvailableForDay(true);
                  this.getRatePlanOptions();
               }
            }))
         this.subscriptions.add(this.addonDetailsForm.form.valueChanges.subscribe(data => {
            if (data) {
               this.addonsList = this.basicSettings?.CategoryDTO?.filter(category => data.add_on.includes(category.Id))
            }
         }))
      })
      Object.keys(this.cancellationDetailsForm.form.controls).forEach((key, value) => {
         this.subscriptions.add(this.cancellationDetailsForm.form.controls[key].valueChanges.subscribe(data => {
            if (key == 'CancellationFeeType') {
               this.cancellationConfig[1].label = data == PaymentType.FlatFee ? this.ts.instant('CancellationFlatFee') : this.ts.instant('CancellationFee');
               this.cancellationDetailsForm.form.controls.CancellationFee.setValidators(data == PaymentType.FlatFee ? [Validators.min(0)] : [Validators.min(0), Validators.max(100)]);
               this.cancellationDetailsForm.form.controls.CancellationFee.updateValueAndValidity();
               this.dynamicFormValidation();
            }
         }))
      })
      Object.keys(this.additionalEmployeeForm.form.controls).forEach((key, value) => {
         this.subscriptions.add(this.additionalEmployeeForm.form.controls[key].valueChanges.subscribe(data => {
            if (key == 'TrackingofSalesPerson') {
               this.disableSendAllCommunication = !this.additionalEmployeeForm.form.controls?.TrackingofSalesPerson?.value
               if (this.disableSendAllCommunication) {
                  this.additionalEmployeeForm.form.controls?.IncludeSalesInAlerts?.disable()
               }
               else {
                  this.additionalEmployeeForm.form.controls?.IncludeSalesInAlerts?.enable();
               }
            }
         }))
      })
      this.components.forEach((x, index) => {
         this.subscriptions.add(x.form?.valueChanges.subscribe((value) => {
            if (value.IsForStandbyReservations !== undefined) {
               this.IsForStandbyReservations = value.IsForStandbyReservations;
            }
            if (value.AllowStandBy !== undefined) {
               this.AllowStandBy = value.AllowStandBy;
            }
            if (value.AllowOverbook !== undefined) {
               this.AllowOverbook = value.AllowOverbook;
            }
            if (value.GuestLinkType !== undefined) {
               this.GuestLinkModeType = value.GuestLinkType;
            }
            if (value.PrePaymentMode !== undefined) {
               if (value.PrePaymentMode !== 0) {
                  this.IsPrepaymentRequire = true;
                  this.PrePaymentModeType = value.PrePaymentMode
               } else {
                  this.IsPrepaymentRequire = false;
                  this.PrePaymentModeType = value.PrePaymentMode
               }
            }
            this.OnlineReservationConfig[2].class = this.PrePaymentModeType == PrePaymentMode.None ? "w-307px web-payment-disabled" : "w-307px web-payment";
            if (this.PrePaymentModeType == PrePaymentMode.None) {
               this.OnlineReservationForm.form.controls.WidgetPrePaymentMode.setValue(null);
            }
            this.dynamicFormValidation();
         })
         )
      })
      Object.keys(this.paymentDetailsForm.form.controls).forEach((key, value) => {
         this.subscriptions.add(this.paymentDetailsForm.form.controls[key].valueChanges.subscribe(data => {
            if (key === 'PrePaymentMode') {
               this.PrePaymentModeType = data;
              } 
         }));
     });
     
  
      this.subscriptions.add(this.activitySettingForm.form.valueChanges.subscribe(data => {
         if (data?.MinPartySize) {
            this.guestTypeFormGroup?.controls?.guestType_Rows['controls'].forEach(x => {
               if (x.value && x.value.isMapped && x.value.MinimumQuantity) {
                  x.controls.MinimumQuantity.setValue(data.MinPartySize, { emitEvent: true });
                  x.controls.MinimumQuantity.updateValueAndValidity();
               }
            });
         }
         if (data.MinDuration) {
            this.activitySettingForm.form.controls.MaxDuration.setValidators([Validators.min(data.MinDuration)]);
            this.activitySettingForm.form.controls.MaxDuration.updateValueAndValidity({ emitEvent: false });
         }
         this.updateAddonSize = true;
      }))
      Object.keys(this.bookingTypeForm.form.controls).forEach((key, value) => {
         this.subscriptions.add(this.bookingTypeForm.form.controls[key].valueChanges.subscribe(data => {
            if (data) {
               this.bookingTypeConfig.find(config => config.name == key).icon = 'icon-edit cursor';
            }
            else {
               this.bookingTypeConfig.find(config => config.name == key).icon = 'icon-edit pointer-none';
            }
         }))
      })
      this.shortNotificationForm.form.valueChanges.subscribe(data => {
         if (data) {
            this.showNotificationMinutes = data.NotificationPeriod == NotifyPeriod.Duration;
         }
      });
      this.allFormGroupList = [
         this.classLessonDetailsForm,
         this.basicSetupForm,
         this.activitySettingForm,
         this.calendarDetailsForm,
         this.collectDepositForm,
         this.diferredForm,
         this.additionalSettingsForm,
         this.cancellationDetailsForm,
      ]
      this.allFormGroupList.forEach((form => {
         if (form)
            this.subscriptions.add(form.form.valueChanges.subscribe(data => {
               this.dynamicFormValidation();
            }))
      }))
   }
   sessionChanges(value) {
      if (value) {
         this.dyanamicSessionValidation();
      }
   }
   chooseTemplates() {
      this.activityCreateFunctions.chooseTemplates();
   }
   scrollTop(id) {
      this.activeMenuId = id;
      this.ss.scrollTop(id);
   }
   dynamicFormValidation() {
      let [IsForStandbyReservations, AllowOverbook, AllowStandBy, AutoConfirmStandby, MaxStandByStrength, , ServiceCharges, Taxes, AllowAddingSecondaryGuest] = this.components
      let allowToSave = false;
      if (this.classLessonDetailsForm.form.valid && this.basicSetupForm.form.valid && this.activitySettingForm.form.valid  && this.cancellationDetailsForm.form.valid && this.additionalSettingsForm.form.valid) {
         allowToSave = true;
         if (this.IsPrepaymentRequire) {
            if (this.PrePaymentModeType == PrePaymentMode.DepositPaymentMode) {
               allowToSave = this.collectDepositForm.form.valid || false;
            }
         }
         if (this.IsForStandbyReservations) {
            allowToSave = MaxStandByStrength.form.valid || false;
         }
         else if (!this.IsForStandbyReservations) {
            if (this.AllowStandBy) {
               allowToSave = MaxStandByStrength.form.valid || false;
            } else {
               allowToSave = true;
            }
         }
      } else {
         allowToSave = false;
      }
      this.saveButton.disbaledproperity = !allowToSave;
   }
   getLessonByIdAndRestaurant(Payload) {
      if (this.basicSettings.SessionGroups?.length) {
         this.basicSettings.SessionGroups.forEach(group => {
            this.addBatchName(group);
         })
         setTimeout(() => { this.SaveSessionGroups() }, 500)
      }
      this.staffList = Payload.Instructors.map(options => { return { id: options.Id, value: options.Name, IsTemplate: options.IsTemplate } });
      this.ss.activitySessionList = Payload?.SpecialMealDTO?.ActivitySessions || []
      this.ss.activitySessionList = this.ss.activitySessionList.map(session => {
         session.tempId = this.sessionTempId; this.sessionTempId = this.sessionTempId + 1;
         if (!session.SessionLocationMappings) {
            session.SessionLocationMappings = [];
         }
         if (!session.SessionStaffMappings) {
            session.SessionStaffMappings = [];
         }
         return session;
      });
      this.addonsList = this.basicSettings.CategoryDTO = Payload.CategoryDTO?.filter(category => this.cs.settings?.value?.Categories?.some(x => x.Id === category.Id));
      this.ss.addOnsMappingList = Payload.CategoryDTO;
      this.setBookingTypeConfig(Payload.BookingTypeDTOs, Payload.BookingTypeShortDTOs);
      this.setGuestTypeFormGroup(Payload.CoverTypeDTOs, Payload.CoverTypeShortDTOs);
      let filteredLocation = this.excludeLocationGroupPipe.transform(this.basicSettings?.Locations);
      this.locationList = filteredLocation?.map(location => { return { id: location.Id, value: location.Name, IsTemplate: location.IsTemplate } })
      this.allocationList = this.basicSettings?.AllocationMappings?.map(type => { return { id: type.AllocationTypeId, value: type.AllocationName } });
      this.allocationList.unshift({ id: 0, value: this.ts.instant('OpentoAll') });
      this.isServiceChargeAvailable = Payload?.BookingCharges.filter(charge => charge.BookingChargeType == BookingChargeType.ServiceCharge)?.length > 0;
      this.IsForStandbyReservations = Payload?.SpecialMealDTO?.IsForStandbyReservations;
      if (this.ss.activitySessionList?.length == 0) {
         this.addNewSesionTimeRange(this.activeDayOfWeek);
      }
      this.checkSessionAvailableForDay();
      if (this.mealData?.ImageData) {
         this.ss.ImageData = this.mealData.ImageData;
      }
      this.setActiveDayOfWeek(this.activeDayOfWeek);
   }

   setActiveDayOfWeek(dayOfWeek) {
      if (dayOfWeek.available || this.isTemplate) {
         this.activeDayOfWeek = dayOfWeek.id;
      }
      this.timeRangeActions();
      this.getOpenHoursForDay(this.activeDayOfWeek);
   }

   getOpenHoursForDay(dayOfWeek) {
      let _openHourSlots = '';
      let daySlots = this.cs.settings.value.OpenHours.filter(hour => hour.DayOfWeek === dayOfWeek) as OpenHoursDTO[];
      daySlots.forEach((day, index) => {
         let range = moment(day.DisplayedRange.Start).format('LT') + " - " + moment(day.DisplayedRange.End).format('LT');
         let selectedDay = index == 0 ? (this.ts.instant('OpenHours') + " :") : "";
         range = `${selectedDay} ${range}  ${((daySlots.length - 1) == index ? "" : ', ')}`
         _openHourSlots = _openHourSlots.concat(range);
      });
      this.openHours = _openHourSlots || '';
   }
   timeRangeActions() {
      if (this.classType == ClassType.Duration) {
         let daySession = this.ss.activitySessionList?.filter(session => session.Dayofweek == this.activeDayOfWeek);
         this.disableAddTimeRangeButon = (daySession?.length == 1 || daySession.length > 1) ? true : false;
      } else {
         this.disableAddTimeRangeButon = false;
      }
   }
   addNewSesionTimeRange(dayOfWeek) {
      if (!this.disableAddTimeRangeButon) {
         let newSession = {
            ActivitySessionId: 0,
            SpecialMealId: 0,
            Dayofweek: dayOfWeek,
            StartTime: null,
            EndTime: null,
            BookedSlots: 0,
            IsBooked: false,
            AllocationTypeId: 0,
            SessionStaffMappings: [],
            SessionLocationMappings: [],
            SessionGroupId: null,
            tempId: this.sessionTempId
         }
         this.ss.activitySessionList.push(newSession);
         this.sessionTempId = this.sessionTempId + 1;
         this.checkSessionAvailableForDay(true);
         this.timeRangeActions();
      }
   }
   checkSessionAvailableForDay(includeCustomAndBookedSessions?: boolean) {
      if (this.ss.activitySessionList.length) {
         this.sessionAvailableDaysOfWeek.map(day => {
            if (this.ss.activitySessionList.find(session => session.Dayofweek == day.id)) {
               const customSessions = includeCustomAndBookedSessions ? (day.sessions?.filter(x => x.SessionDate) || []) : [];
               let bookedSessions = includeCustomAndBookedSessions ? (day.sessions?.filter(x => (x.IsBooked || x.BookedSessions?.length) && !x.SessionDate) || []) : [];
               let allSessionList = this.ss.activitySessionList.filter(session => session.Dayofweek == day.id && !session.SessionDate);

               if (includeCustomAndBookedSessions) {
                  bookedSessions.map(session => {
                     const matchedSessionIndex = allSessionList.findIndex(x => x.StartTime == session.StartTime && x.EndTime == session.EndTime);
                     if (matchedSessionIndex > -1) {
                        session.AllocationTypeId = allSessionList[matchedSessionIndex].AllocationTypeId || 0;
                        session.SessionGroupId = allSessionList[matchedSessionIndex].SessionGroupId;
                        session.SessionLocationMappings = allSessionList[matchedSessionIndex].SessionLocationMappings;
                        session.SessionStaffMappings = allSessionList[matchedSessionIndex].SessionStaffMappings;
                        allSessionList.splice(matchedSessionIndex, 1);
                     }
                     return session;
                  })
               }

               day.isSessionAvailable = true;
               day.sessions = [...allSessionList, ...customSessions, ...bookedSessions];
               day.sessions = _.orderBy(day.sessions, 'StartTime');
            } else {
               day.isSessionAvailable = false;
               day.sessions = [];
            }
            return day;
         })
      } else {
         this.sessionAvailableDaysOfWeek.map(day => {
            day.isSessionAvailable = false;
            day.sessions = [];
            return day;
         })
      }

      this.sessionAvailableDaysOfWeek = _.cloneDeep(this.sessionAvailableDaysOfWeek);

      if (includeCustomAndBookedSessions) {
         this.ss.activitySessionList = [];
         this.sessionAvailableDaysOfWeek.forEach(x => {
            this.ss.activitySessionList = [...x.sessions, ...this.ss.activitySessionList];
         })
      }
   }
   deleteSession(session) {
      if (session.SessionDate) return;
      if (session) {
         let index = this.ss.activitySessionList.findIndex(row => row.tempId == session.tempId);
         this.ss.activitySessionList = this.ss.activitySessionList.filter(row => row.tempId !== session.tempId);
         this.checkSessionAvailableForDay(true);
         this.dyanamicSessionValidation();
         this.timeRangeActions();
      }
   }
   removeGroup(i: number) {
      let row = this.batchFormGroup.get("batch_Rows") as UntypedFormArray;
      row.removeAt(i);
      this.SaveSessionGroups();
   }
   addBatchName(group?) {
      let row = this.batchFormGroup.get("batch_Rows") as UntypedFormArray;
      let newBatch = this._fb.group({
         Name: group?.Name || '',
         SelectedAllocationId: group?.SelectedAllocationId || 0,
         IsDeleted: group?.IsDeleted || false,
         IsMapped: group?.IsMapped || false,
         Id: group?.Id || 0,
         Index: this.appService.isTemplateUsed ? this.sessionTempId : (group?.Id ? null : this.sessionTempId),
         isNew: this.appService.isTemplateUsed ? true : (group?.Id ? false : true),
      })
      this.sessionTempId = this.sessionTempId + 1;
      row.push(newBatch);
   }
   mapSessionGroups() {
      let sessionGroups = this.batchFormGroup.value.batch_Rows;
      sessionGroups = sessionGroups.filter(row => row.Name !== '' && row.Name !== null)
      sessionGroups.map((row) => {
         if (row.SelectedAllocationId == 0) {
            row.SelectedAllocationId = null;
         }
         if (row.isNew) {
            row.Id = 0;
         }
         return row;
      })
      return sessionGroups;
   }
   SaveSessionGroups() {
      let sessionGroups = this.batchFormGroup.value.batch_Rows;
      sessionGroups = sessionGroups.filter(row => row.Name !== '' && row.Name !== null);
      sessionGroups = sessionGroups.map(row => { row.Id = row.Id ? row.Id : row.Index; return row });
      this.sessionGroups = sessionGroups || [];
      if (this.sessionGroups.length == 0 && this.classType == ClassType.Class) {
         this.warningMessgaes = this.ts.instant('batchNameWarning');
      } else {
         this.warningMessgaes = ''
      }

      // Session Group save before save the activity refer MP

      // sessionGroups.map((row) => {
      //    if (row.SelectedAllocationId == 0) {
      //       row.SelectedAllocationId = null;
      //    }
      //    return row;
      // })
      // this.ss.SaveSessionGroups(sessionGroups).subscribe(data => {
      //    console.log(data);
      //    this.batchFormGroup = this._fb.group({
      //       batch_Rows: this._fb.array([])
      //    })
      //    this.sessionGroups = data?.Payload?.SessionGroups || [];
      //    this.sessionGroups.forEach(group => {
      //       this.addBatchName(group);
      //    })
      // })
   }
   loadButtonconfig() {
      this.saveButton = {
         type: buttonTypes.actionPrimarySmall,
         label: this.ts.instant('Save'),
         disbaledproperity: true,
         customclass: 'action-bar__add-class-btn',
      };
      this.cancelButton = {
         type: buttonTypes.actionSecondarySmall,
         label: this.ts.instant('Cancel'),
         disbaledproperity: false,
         customclass: 'action-bar__add-class-btn',
      };
      this.templateButton = {
         type: buttonTypes.actionSecondarySmall,
         label: this.ts.instant('chooseTemplate'),
         disbaledproperity: false,
         customclass: 'action-bar__add-class-btn',
      };
      this.addBatchButton = {
         type: buttonTypes.actionPrimarySmall,
         label: this.ts.instant('addBatch'),
         disbaledproperity: false,
         customclass: 'action-bar__add-class-btn',
      };
      this.saveBatchButton = {
         type: buttonTypes.actionPrimarySmall,
         label: this.ts.instant('saveBatch'),
         disbaledproperity: false,
         customclass: 'action-bar__add-class-btn',
      };
      this.addTimeRangeButton = {
         type: buttonTypes.actionPrimarySmall,
         label: this.ts.instant('AddTimeRange'),
         customclass: 'weekday-shift__unselect-btn'
      };
      this.applyCloneButton = {
         type: buttonTypes.actionPrimarySmall,
         label: 'apply',
         customclass: 'cloneToDays__apply-btn'
      };
      let daysOfWeek = [];
      for (let i = 0; i < Object.keys(DayOfWeek).length / 2; i++) {
         // if (LessonType[i] !== "specialMeal")
         daysOfWeek.push({
            id: i,
            dayName: DayOfWeek[i],
         })
      }
      this.daysOfWeek = daysOfWeek;
      this.sessionAvailableDaysOfWeek = daysOfWeek;
   }
   setBookingTypeConfig(bookingType, BookingTypeShortDTOs) {
      this.bookingTypeConfig = [];
      if (bookingType?.length) {
         bookingType.forEach(type => {
            let isBookingTypeMapped = BookingTypeShortDTOs.find(cover => cover.Id == type.Id)?.isMapped || false;
            let bookingItem = {
               type: 'checkbox',
               placeholder: type.Name,
               value: isBookingTypeMapped,
               name: type.Id,
               icon: 'icon-edit pl-1' + (!isBookingTypeMapped ? ' pointer-none' : ' cursor'),
               onClick: this.loadBookingTypes.bind(this)
            }
            this.bookingTypeConfig.push(bookingItem)
         })
         this.bookingsTypeSetting = _.cloneDeep(BookingTypeShortDTOs);
      }
      this.bookingTypeConfig = [...this.bookingTypeConfig];
   }

   loadBookingTypes(bookingTypeData) {


      const componentDetails: ComponentDetails = {
         componentName: BookingTypeConfigurationComponent,
         popupType: 'active',
         dimensionType: 'small',
         popUpDetails: {
            isStepper: false,
            eventName: 'notifyParent'
         },
         popupInput: {
            bookingType: this.bookingsTypeSetting.find(type => type.Id == bookingTypeData.data),
         },
         popupTitle: 'editBookingType'
      };

      //const dialogRef = this.partyService.openAppPopup(componentDetails, ComponentTypes.commonconfirmmessage,
      //     popupDialogDimension.actionDialogWidth, 'auto', false, this.ts.instant('ok'), this.ts.instant('cancel'), true);
      const dialogRef = this.partyService.openAppPopup(componentDetails, ComponentTypes.timeline, popupDialogDimension.actionDialogWidth, 'auto', false, componentDetails.popupTitle, this.ts.instant('ok'),
         this.ts.instant('cancel'), true);
      dialogRef.afterClosed().subscribe((result: { action: "cancelled" | "confirmed", data }) => {
         if (result?.action === "confirmed") {
            if (!this.bookingsTypeSetting.find(type => type.Id == bookingTypeData.data)) {
               this.addBookingTypeSetting(bookingTypeData.data, result);
            }
            else {
               let index = this.bookingsTypeSetting.findIndex(type => type.Id == bookingTypeData.data);
               this.bookingsTypeSetting.splice(index, 1);
               this.addBookingTypeSetting(bookingTypeData.data, result);
            }
         }

      })
      bookingTypeData.event.stopPropagation();
   }

   addBookingTypeSetting(bookingTypeData, result) {
      let object = {};
      object['Id'] = bookingTypeData;
      let resultData = result['data'];
      object = { ...object, ...resultData };
      this.bookingsTypeSetting.push(object);
   }

   setCoverTypeConfig(guestType, CoverTypeShortDTOs) {
      this.guestTypeConfig = [];
      if (guestType?.length) {
         guestType.forEach(type => {
            let coverItem = {
               type: 'checkbox',
               placeholder: type.Name,
               value: CoverTypeShortDTOs.find(cover => cover.Id == type.Id)?.isMapped || false,
               name: type.Id,
            }
            this.guestTypeConfig.push(coverItem)
         })
      }
      this.guestTypeConfig = [...this.guestTypeConfig]
   }

   setGuestTypeFormGroup(guestTypeDTO, guestTypeShortDTO) {
      let row = this.guestTypeFormGroup.get("guestType_Rows") as UntypedFormArray;
      if (guestTypeDTO?.length) {
         guestTypeDTO?.forEach(type => {
            let newGuestType = this._fb.group({
               Id: type.Id || 0,
               Name: type.Name || '',
               isMapped: guestTypeShortDTO.find(cover => cover.Id == type.Id)?.isMapped || false,
               MinimumQuantity: guestTypeShortDTO.find(cover => cover.Id == type.Id)?.MinimumQuantity || 0,
            });
            row.push(newGuestType);
         });
      }
   }

   cancelActivity(value?) {
      if (!this.appService.showCreateActivity) {
         this.cancelEvent.emit(value);
      }
      this.appService.isTemplateUsed = false;
      this.appService.showCreateActivity = false;
   }
   openClonePopover(popOver) {
      if (!this.disableCloneButton) {
         this.days = this.cloneWeekDays.filter(x => this.sessionAvailableDaysOfWeek.find(y => y.id == x.id)?.available && x.id !== this.activeDayOfWeek);
         this.days.map(day => day.selected = false);
         this.days.map(day => day.hasBookings = !!this.ss.activitySessionList.filter(session => session.IsBooked && day.id === session.Dayofweek).length);
         this.allSelected = false;
         this.popover = popOver;
         this.popover.open();
      }
   }
   cancelClone() {
      this.popover.close();
   }
   applyClone(daysArr) {
      let clonedSessions = [];
      let dayOfWeekSessoin = this.ss.activitySessionList.filter(session => session.Dayofweek == this.activeDayOfWeek);
      daysArr.forEach((day) => {
         if (day.selected && this.activeDayOfWeek !== day.id) {
            dayOfWeekSessoin.forEach(x => {
               if (!x.SessionDate) {
                  let newSession = Object.assign({}, x)
                  newSession.ActivitySessionId = 0;
                  newSession.Dayofweek = day.id;
                  newSession.tempId = this.sessionTempId;
                  newSession.BookedSessions = [];
                  newSession.IsBooked = false;
                  if (x.SessionLocationMappings?.length) {
                     newSession.SessionLocationMappings = x.SessionLocationMappings.map(item => { item.ActivitySessionId = 0; item.Id = 0; return item })
                  } else {
                     newSession.SessionLocationMappings = []
                  }
                  if (x.SessionStaffMappings?.length) {
                     newSession.SessionStaffMappings = x.SessionStaffMappings.map(item => { item.ActivitySessionId = 0; item.Id = 0; return item })
                  } else {
                     newSession.SessionStaffMappings = []
                  }
                  clonedSessions.push(newSession);
                  this.sessionTempId = this.sessionTempId + 1;
               }
            })
         }
      })
      let otherClonedSessions = [];
      this.ss.activitySessionList.forEach(x => {
         if (!daysArr.find(day => day.id == x.Dayofweek)?.selected) {
            otherClonedSessions.push(x);
         }
      })
      otherClonedSessions.push(...clonedSessions)
      this.popover.close();
      this.ss.activitySessionList = otherClonedSessions;
      this.checkSessionAvailableForDay(true);
      this.dyanamicSessionValidation();
   }
   checkInstructorAvailability(sessions, callBack) {
      this.ss.CheckInstructorAvailability(this.GetcheckInstructorAvailability(sessions)).subscribe(data => {
         callBack(data);
      })
   }

   GetcheckInstructorAvailability(sessions) {
      let calendar = this.calendarDetailsForm.form.getRawValue();
      let payLoad = [];
      sessions.forEach(session => {
         let serverAvailabilityRequest = {
            StartDate: moment(calendar.StartDate).format('YYYY-MM-DD'),
            EndDate: moment(calendar.EndDate).format('YYYY-MM-DD'),
            StartTime: session.StartTime,
            EndTime: session.EndTime,
            DayOfWeek: session.Dayofweek,
            SelectedInstructorIds: session.SessionStaffMappings.map(x => { return x.ServerId })
         }
         payLoad.push(serverAvailabilityRequest);
      })
      return payLoad;
   }

   selectAllDays(value) {
      this.days.forEach((day, index) => {
         if (index !== this.currentExpandedPanel && !day.hasBookings) {
            day.selected = value
         }
      });
   }

   createSaveRequest() {
      this.ss.activitySessionList = this.mapActivitySessionList();
      if (!this.saveButton.disbaledproperity && this.dyanamicSessionValidation(true)) {
         let classDetails = this.classLessonDetailsForm.form.getRawValue();
         let basicSetup = this.basicSetupForm.form.getRawValue();
         let [IsForStandbyReservations, AllowOverbook, AllowStandBy, AutoConfirmStandby, MaxStandByStrength, , ServiceCharges, Taxes, DefaultPaymentType, AllowAddingSecondaryGuest , NotifyStaff ,NotificationCutOff] = this.components
         let activitySetting = this.activitySettingForm.value;
         let onlineReservationSetting = this.OnlineReservationForm.value;
         let ticketPrint = this.ticketPrintForm.value;
         let subCategoryId = basicSetup.Category;
         let payment = this.paymentDetailsForm.form.getRawValue();
         let calendar = this.calendarDetailsForm.form.getRawValue();
         let additionalSettings = this.additionalSettingsForm.value;
         let checkinCheckOutDetils = this.checkinCheckOutForm.value;
         let cancellationDetails = this.cancellationDetailsForm.value;
         let collectDepositDetails = this.collectDepositForm.value;
         let diferredDetails = this.diferredForm.value;
         let guestSettings = this.guestSettingsForm.value;
         let allowSecondaryGuest = this.allowAdditionalGuestForm.value;
         let additionalEmployees = this.additionalEmployeeForm.value;
         let emailNotifications = this.emailNotificationsForm.value;
         let shortNotice = this.shortNotificationForm.value;
         let remainderData = this.reminderConfigForm.value;
         let assetBookingType = this.raftingForm.value.AssetBookingType ? AssetBookingType.ExclusiveBooking : AssetBookingType.SharedBooking
         let categoryId = this.masterCategories?.map(category => category.options)?.flat().find(subCategory => subCategory.id === subCategoryId)?.masterId;
         let activity = {
            Id: this.mealData?.Id || 0,
            RestaurantId: this.restaurantId,
            Name: classDetails.Name,
            ActivityCode: classDetails.ActivityCode,
            Description: classDetails.Description,
            LessonType: basicSetup.LessonType,
            ClassType: basicSetup.ClassType,
            ActivityTypeId: basicSetup.ActivityTypeId,
            CutOffMinutes: basicSetup.CutOffMinutes,
            OnlineCancelCutOffMinutes:parseInt(onlineReservationSetting.cancellationCutOffMins),
            OnlineCancelCutOffDescription:onlineReservationSetting.cancellationCutOffDesc,
            SortOrder: basicSetup.SortOrder,
            WaiverFormCode: additionalSettings.WaiverFormCode,
            DataMagineEFormId: additionalSettings.DataMagineEFormId > 0 ? additionalSettings.DataMagineEFormId : null,
            categoryType: basicSetup.categoryType,
            CategoryId: categoryId,
            SubCategoryId: subCategoryId,
            RetailItemId: basicSetup.RetailItemId,
            IsForStandbyReservations: IsForStandbyReservations.value.IsForStandbyReservations,
            AllowStandBy: AllowStandBy.value.AllowStandBy,
            AllowOverbook: AllowOverbook.value.AllowOverbook,
            AutoConfirmStandby: AutoConfirmStandby.value.AutoConfirmStandby,
            MaxStandByStrength: MaxStandByStrength.value.MaxStandByStrength,
            CheckInCutOff: additionalSettings.CheckInCutOff,
            EnableTicketPrinting: ticketPrint.EnableTicketPrinting,
            TicketPerPerson: ticketPrint.TicketPerPerson,
            TrackingofSalesPerson: additionalEmployees?.TrackingofSalesPerson,
            IncludeSalesInAlerts: additionalEmployees?.IncludeSalesInAlerts,
            CoverTypeShortDTOs: this.getCoverTypeMapping(),
            BookingTypeShortDTOs: this.getBookingTypeMapping(),
            MaxActivityStrength: activitySetting.MaxActivityStrength,
            MinActivityStrength: activitySetting.MinActivityStrength,
            MinDuration: activitySetting.MinDuration,
            MaxDuration: activitySetting.MaxDuration,
            MinPartySize: activitySetting.MinPartySize,
            MaxPartySize: activitySetting.MaxPartySize,
            StartDate: moment(calendar.StartDate).format('YYYY-MM-DD') + "T00:00:00.000",
            EndDate: moment(calendar.EndDate).format('YYYY-MM-DD') + "T00:00:00.000",
            IsPrepaymentRequired: this.PrePaymentModeType == PrePaymentMode.None ? false : true,
            CoverTypePrices: [],
            BookingTypeActivitiesMappings: [],
            ActivitySessions: this.ss.activitySessionList,
            ChargeOnBooking: this.PrePaymentModeType == PrePaymentMode.PrepaidPaymentMode ? true : false,
            SelectedTaxIds: Taxes.value.Taxes || [],
            ImageData: this.ss.ImageData || '',
            ImageChanged: (this.ss.ImageData && this.appService.isTemplateUsed) ? true : (this.ss.ImageChanged || false),
            Price: 0,
            RatePlanId: (payment.RatePlanId == -1 ? null : payment.RatePlanId) || null,
            SelectedChargeIds: [...ServiceCharges.value.ServiceCharges, ...Taxes.value.Taxes],
            CollectType: checkinCheckOutDetils.CollectType,
            PrePaymentMode: this.PrePaymentModeType,
            ActivityAttributes: this.getSaveAttributeMappingValue(),
            CategoryWithAddOns: this.ss.addOnsMappingList,
            SessionGroups: (this.classType == ClassType.Class) ? this.mapSessionGroups() : [],
            MinTimeToCancelActivityReservationMinutes: cancellationDetails?.MinTimeToCancelActivityReservationMinutes ? Math.round(cancellationDetails.MinTimeToCancelActivityReservationMinutes * 60) : 0, //user gives hours, hence converting to minutes
            CancellationFeeType: cancellationDetails.CancellationFeeType,
            CancellationFee: this.IsPrepaymentRequire ? cancellationDetails.CancellationFee : 0,
            DepositType: collectDepositDetails.DepositType,
            Deposit: this.PrePaymentModeType == PrePaymentMode.DepositPaymentMode ? collectDepositDetails.Deposit : 0,
            DeferredPaymentInDays: diferredDetails.DeferredPaymentInDays,
            DoesRequirePrepayment: this.IsPrepaymentRequire,
            IsTemplate: this.isTemplate ? true : false,
            CancellationPolicy: cancellationDetails.CancellationPolicy,
            BookingChargeActivityMappings: this.getBookingChargeActivityMappingDTO(ServiceCharges.value.ServiceCharges, Taxes.value.Taxes),
            AllowAddingSecondaryGuest: allowSecondaryGuest.AllowAddingSecondaryGuest,
            GuestNames: true,
            PrimaryContacts: guestSettings.PrimaryContacts,
            SecondaryContacts: guestSettings.SecondaryContacts,
            MembershipDetails: guestSettings.MembershipDetails,
            SpecialOccasions: guestSettings.SpecialOccasions,
            ContactNotes: guestSettings.ContactNotes,
            CustomGuestFields: guestSettings.CustomGuestFields,
            HostId: Utilities.getHostId(),
            CheckInstructorAvailability: (this.classType !== ClassType.Duration && !this.isTemplate) ? this.GetcheckInstructorAvailability(this.ss.activitySessionList) : [],
            AllowOverride: false,
            WarningMessage: [],
            AvailableForWebReservation: onlineReservationSetting.AvailableForWebReservation,
            SlotCountInWidget: onlineReservationSetting.SlotCountInWidget,
            WidgetPrePaymentMode: onlineReservationSetting.WidgetPrePaymentMode,
            AssetBookingType: assetBookingType,
            GuestLinkType: this.GuestLinkModeType,
            DefaultPaymentType: DefaultPaymentType.value.DefaultPaymentType,
            NotificationPeriod:shortNotice.NotificationPeriod,
            NotifyStaff:NotifyStaff.value.NotifyStaff,
            NotificationCutOff:NotificationCutOff.value.NotificationCutOff,
            SalesContactIds:emailNotifications.SalesContactIds,
            NotificationEmailIds:emailNotifications.NotificationEmailIds,
            NotificationType:emailNotifications.NotificationType,
            ReminderScheduleMinutesInAdvance:remainderData.ReminderScheduleMinutesInAdvance ? Number(remainderData.ReminderScheduleMinutesInAdvance) : null ,
         }
         let templatePayload = _.cloneDeep(activity)
         if (!this.isEdit && this.appService.isTemplateUsed) {
           templatePayload = this.activityCreateFunctions.templateToActivityConverter(templatePayload, this.classType);
         }
        this.saveLesson(templatePayload, activity);
      }
   }

   private AllowOverLabs(callBack: any, activity: any, templatePayload: any, allowOverride: boolean) {
      let msg = this.ts.instant('AreyouSureWantToContinue');
      this.partyService.showWarningInfoPopUp(msg, ComponentTypes.BasicSetupConfirmation, '450px', '1000px', callBack.Payload?.WarningMessage);
      this.subscriptions.add(this.partyService.warningInfoDialogRef.afterClosed().subscribe(event => {
         confirmationSubscription?.unsubscribe();
      }));

      let confirmationSubscription = this.ps.confirmedAction$.subscribe(val => {
         if (val === ComponentTypes.BasicSetupConfirmation) {
            if (activity) {
               activity.AllowOverride = allowOverride;
               activity.WarningMessage = callBack.Payload?.WarningMessage;
            }
            if (templatePayload) {
               templatePayload.AllowOverride = allowOverride;
               templatePayload.WarningMessage = callBack.Payload?.WarningMessage;
            }
            confirmationSubscription?.unsubscribe();
            this.saveLesson(templatePayload, activity);
         }
      });
   }

   saveLesson(template, activity) {
      this.ss.SaveLesson((!this.isEdit && this.appService.isTemplateUsed) ? template : activity).subscribe(data => {
         if (data && data.Payload && data.Payload.WarningMessage && data.Payload.WarningMessage.length > 0) {
            this.AllowOverLabs(data, activity, template, true);
         } else {
            this.appService.isTemplateUsed = false;
            this.openConfirmationDialog();
            if (!this.appService.showCreateActivity) {
               this.saveEvent.emit(true);
            }
            this.appService.showCreateActivity = false;
         }
      });
   }

   getSaveAttributeMappingValue() {
      let headLine = [];
      let addtionalSettings = this.additionalSettingsForm.value;
      let privateHeadline = this.getActivityAttributes(addtionalSettings.PrivateHeadline, AttributeType.PrivateHeadlines, (this.activityData?.ActivityAttributes?.find(x => x.IsDefault == true && x.AttributeType == AttributeType.PrivateHeadlines)?.Id || 0));
      let publicHeadline = this.getActivityAttributes(addtionalSettings.PublicHeadline, AttributeType.PublicHeadlines, (this.activityData?.ActivityAttributes?.find(x => x.IsDefault == true && x.AttributeType == AttributeType.PublicHeadlines)?.Id || 0));
      if (privateHeadline) {
         headLine.push(privateHeadline);
      }
      if (publicHeadline) {
         headLine.push(publicHeadline);
      }
      return headLine?.length ? headLine : [];
   }

   dyanamicSessionValidation(saveValidation?) {
      if (!this.isTemplate) {
         const result = formValidation(this.classType, this.ss.activitySessionList, this.sessionAvailableDaysOfWeek, saveValidation, this.ts);

         this.requiredFieldValidation = result.requiredFieldValidation;
         this.InvalidSessionDays = result.InvalidSessionDays;
         if (result.scrollTop) this.scrollTop(3);
         if (result.warningMessgaes != '') {
            this.warningMessgaes = this.ts.instant(result.warningMessgaes);
         } else this.warningMessgaes = '';

         return result.result;
      }
      else {
         return true;
      }
   }

   mapActivitySessionList() {
      if (ClassType.Class) {
         this.ss.activitySessionList.map(x => {
            let group = this.sessionGroups.find(session => x.SessionGroupId == session.Id && session.isNew == true);
            x.AllocationTypeId = x.AllocationTypeId !== 0 ? x.AllocationTypeId : null;
            if (this.classType != ClassType.Class) {
               x.SessionGroupId = x.SessionGroupId !== 0 ? x.SessionGroupId : null;
            }
            if (group) {
               x.Index = group.Index;
            }
         })
         this.ss.activitySessionList = this.ss.activitySessionList.filter(session => this.sessionAvailableDaysOfWeek.find(day => day.id == session.Dayofweek && day.available == true));
         return this.ss.activitySessionList;
      } else {
         this.ss.activitySessionList = this.ss.activitySessionList.filter(session => this.sessionAvailableDaysOfWeek.find(day => day.id == session.Dayofweek && day.available == true));
         return this.ss.activitySessionList;
      }
   }
   openConfirmationDialog() {
      let msg = '';
      if (this.isTemplate) {
         msg = this.isEdit ? this.ts.instant("templateUpdateConfirmation") : this.ts.instant("templateConfirmation")

      } else {
         msg = this.isEdit ? this.ts.instant("activityUpdateConfirmation") : this.ts.instant("activityConfirmation")
      }
      const popUpMessage = [{
         confirmationMessage: msg,
         dialogTitle: null,
      }];
      let okbutton = this.ts.instant('ok');
      const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
         popUpMessage, popUpMessage[0].dialogTitle);
      let dialogRef = this.openCustomPopup(componentDetails, ComponentTypes.activityCreateConfirmation, '400px', '400px', true, '', okbutton,
         '', true);
      let popupSubscription = this.ps.confirmedAction$.subscribe(val => {
         // popupSubscription.unsubscribe();
      })
      popupSubscription = this.subscriptions.add(dialogRef.afterClosed().subscribe(val => {
         if (val == ComponentTypes.activityCreateConfirmation) {
            popupSubscription.unsubscribe();
         }
      }));
   }
   cloneDaySelect(value) {
      if(!value.hasBookings){
      if (!value) {
         this.allSelected = false;
      }
      else {
         if (this.days.find((day, index) => !day.selected && index !== this.currentExpandedPanel)) {
            this.allSelected = false;
         } else {
            this.allSelected = true;
         }
      }
   }
   else {
      event.preventDefault();
   }
   }
   getActivityAttributes(description, AttributeType, Id) {
      let calendar = this.calendarDetailsForm.form.getRawValue();
      if (description || (description == '' && Id !== 0)) {
         return {
            Id: Id !== 0 ? Id : 0,
            PropertyId: Utilities.RestaurantId(),
            ActivityId: this.mealData?.Id ? this.mealData?.Id : 0,
            ActivitySessionId: null,
            Description: description !== '' ? description : '',
            ApplyForAllSessions: true,
            AttributeType: AttributeType,
            StartDate: moment(calendar.StartDate).format('YYYY-MM-DD') + 'T' + moment(calendar.StartDate).format('HH:mm:ss'),
            EndDate: moment(calendar.EndDate).format('YYYY-MM-DD') + 'T' + moment(calendar.EndDate).format('HH:mm:ss'),
            IsDefault: true,
            CreatedBy: Utilities.getHostId()
         }
      } else {
         return null
      }

   }
   getDateRange() {
      let calendar = this.calendarDetailsForm?.form.getRawValue();
      let start = new Date(moment(calendar.StartDate).format('YYYY-MM-DD '));
      let end = new Date(moment(calendar.EndDate).format('YYYY MM DD '));
      let availableDays = [];
      while (start <= end) {
         availableDays.push(start.getDay())
         start.setDate(start.getDate() + 1);
         console.log(start.getDay());
      }
      availableDays = _.uniq(availableDays);
      if (availableDays && (availableDays.length == 1 || !availableDays.find(x => x == this.activeDayOfWeek))) {
         this.activeDayOfWeek = availableDays[0];
      }
      this.sessionAvailableDaysOfWeek = this.daysOfWeek.map(x => {
         if (availableDays.includes(x.id)) {
            x.available = true;
            x.sessions = this.ss.activitySessionList.filter(session => session.Dayofweek == x.id);
            x.sessions = _.orderBy(x.sessions, 'StartTime');
         } else {
            if (this.isTemplate) {
               x.available = true;
            } else {
               x.available = false;
            }
            x.sessions = [];
         }
         return x;
      }
      )
      this.disableCloneButton = !(this.sessionAvailableDaysOfWeek.filter(x => x.available)?.length > 1);
   }
   getRatePlanOptions() {
      if (this.basicSettings.RatePlans?.length) {
         let ratePlans = [];

         let calendar = this.calendarDetailsForm?.form.getRawValue();
         let start = calendar?.StartDate ? new Date(moment(calendar.StartDate).format('YYYY-MM-DD')) : new Date();
         let end = calendar.EndDate ? new Date(moment(calendar.EndDate).format('YYYY-MM-DD')) : new Date();
         if (this.classType == ClassType.Duration) {
            ratePlans = this.basicSettings.RatePlans.filter(e => (e.SelectedPricing == this.classType) && (e.RatePlanPricingType == RatePlanPricingTypes.PrivateLesson || e.RatePlanPricingType == RatePlanPricingTypes.Both) && (new Date(moment(e.StartDate).format('YYYY-MM-DD')) <= start && new Date(moment(e.EndDate).format('YYYY-MM-DD')) >= end));
         } else {
            ratePlans = this.basicSettings.RatePlans.filter(e => (e.SelectedPricing == this.classType) && (new Date(moment(e.StartDate).format('YYYY-MM-DD')) <= start && new Date(moment(e.EndDate).format('YYYY-MM-DD')) >= end));
         }
         if (calendar?.StartDate && calendar?.EndDate) {
            ratePlans = ratePlans.map(rateplan => { return { id: rateplan.RatePlanId, value: rateplan.RatePlanCode + " - " + rateplan.Description } })
         } else {
            ratePlans = [];
         }
         if (this.classType == ClassType.Duration) {
            let defaultOption = [{ id: -1, value: 'default' }];
            ratePlans = defaultOption.concat(ratePlans)
         }
         this.paymentDetailsConfig[0].options = ratePlans?.length ? ratePlans : [];
      }
   }
   getSericeChargeValue(type) {
      if (this.mealData?.SelectedChargeIds?.length) {
         let charges = this.basicSettings?.BookingCharges.filter(charge => charge.BookingChargeType == type && charge.StatusCode == Status.Approved && this.mealData?.SelectedChargeIds.includes(charge.Id));
         return charges.map(charge => { return charge.Id });
      } else {
         return [];
      }
   }
   getSericeChargeOptions(type) {
      if (this.basicSettings?.BookingCharges?.length) {
         let serviceCharge = this.basicSettings?.BookingCharges.filter(charge => charge.BookingChargeType == type && charge.StatusCode == Status.Approved);
         return serviceCharge.map(charge => { return { id: charge.Id, value: charge.Name } })
      }
   }
   getCoverTypeMapping() {
      let coverList = [];
      if (this.guestTypeFormGroup?.controls.guestType_Rows?.value) {
         let restaurant = this.restaurantId;
         let coverTypes = this.basicSettings.CoverTypeDTOs;
         this.guestTypeFormGroup?.controls.guestType_Rows?.value?.forEach(type => {
            let guest = {
               Id: type.Id,
               RestaurantId: restaurant,
               Name: coverTypes.find(cover => cover.Id == type.Id)?.Name,
               isMapped: type.isMapped,
               MinimumQuantity: type.isMapped ? type.MinimumQuantity : 0,
            } as CoverTypeShortDTO
            coverList.push(guest)
         });
      }
      return coverList || []
   }
   getBookingTypeMapping() {
      let bookingList = [];
      if (this.bookingTypeForm.value) {
         let restaurant = this.restaurantId;
         let bookingTypes = this.basicSettings.BookingTypeDTOs;
         let bookingsTypeSetting = this.bookingsTypeSetting;
         _.mapKeys(this.bookingTypeForm.value, function (value, key) {
            let bookingTypeSetting = bookingsTypeSetting.find(type => type.Id == Number(key))
            let guest = {
               Id: Number(key),
               RestaurantId: restaurant,
               Name: bookingTypes.find(booking => booking.Id == Number(key))?.Name,
               isMapped: value,
               AdvanceDays: bookingTypeSetting?.AdvanceDays,
               IsDefault: bookingTypeSetting?.IsDefault,
               IsWithinStayRange: bookingTypeSetting?.IsWithinStayRange,
               BookingGuestType: bookingTypeSetting?.BookingGuestType
            } as BookingTypeShortDTO
            bookingList.push(guest);
         })
      }
      return bookingList || [];
   }
   getBookingChargeActivityMappingDTO(ServiceCharge, Taxes) {
      let bookingCharges = [...ServiceCharge, ...Taxes];
      let chargeList = [];
      bookingCharges.forEach(charge => {
         let item = {
            Id: 0,
            ActivityId: this.mealData?.Id || 0,
            BookingChargeId: charge
         } as BookingChargeActivityMappingDTO
         chargeList.push(item)
      })
      return chargeList || [];
   }
   setSelectedMenu(id) {
      this.activeMenuId = id
   }

   setPhoto(url) {
      if (url !== undefined && url !== null) {
         this.ss.ImageData = url;
         this.ss.ImageChanged = true;
      }
   }
   addBasicSetupConfig() {
      let activityType = []
      for (let i = 0; i < Object.keys(LessonType).length / 2; i++) {
         if (LessonType[i] !== "specialMeal")
            activityType.push({
               id: i,
               value: this.ts.instant(LessonType[i]),
            })
      }
      let classType = []
      for (let i = 0; i < Object.keys(ClassType).length / 2; i++) {
         if ((this.mealData?.ClassType == ClassType.Duration && ClassType[i] == "Duration") || (this.mealData?.ClassType != ClassType.Duration && ClassType[i] !== "Duration") || (!this.mealData && ClassType[i] !== "Duration"))
            classType.push({
               id: i,
               value: this.ts.instant('By' + ClassType[i]),
            })
      }
      this.masterCategories = this.cs.settings.value.Categories.filter(category => category.CategoryType === CategoryType.Activity).map(category => {
         return {
            name: category.Text,
            options: category.SubCategories.map(subCategory => {
               return {
                  id: subCategory.Id,
                  value: subCategory.Text,
                  masterId: subCategory.CategoryId
               }
            })
         }
      });
      this.classLessonDetailsConfig = [
         {
            type: 'input',
            name: 'ActivityCode',
            inputType: 'text',
            label: this.isTemplate ? this.ts.instant('TemplateCode') : this.ts.instant('ActivityCode'),
            validation: [Validators.required],
            value: this.mealData?.ActivityCode || null,
            showErrorText: true,
            class: 'w-100',
            disableErrorStateMatcher: true,
            disabled: this.appService.isTemplateUsed ? false : this.mealData?.ActivityCode ? true : false,
            isStarSymbolRequired: true,
         },
         {
            type: 'input',
            name: 'Name',
            inputType: 'text',
            label: this.isTemplate ? this.ts.instant('TemplateName') : this.ts.instant('activityName'),
            value: this.mealData?.Name || null,
            validation: [Validators.required],
            showErrorText: true,
            class: 'w-100',
            disableErrorStateMatcher: true,
            isStarSymbolRequired: true,
         },
         {
            type: 'textarea',
            name: 'Description',
            label: this.isTemplate ? this.ts.instant('TemplateDescription') : this.ts.instant('Description'),
            inputType: 'text',
            showHint: true,
            charLength: 1000,
            value: this.mealData?.Description || '',
            appearance: false,
            class: 'activity-description'
         },
      ]
      this.basicSetupConfig = [
         {
            type: 'select',
            name: 'LessonType',
            label: this.ts.instant('activityType'),
            options: activityType,
            value: this.mealData?.LessonType || LessonType.GroupLesson,
            appearance: false,
            validation: [Validators.required],
            isTranslate: true,
            disableErrorStateMatcher: true,
            class: "basic-select",
            disabled: this.isEdit,
            isStarSymbolRequired: true,
         },
         {
            type: 'select',
            name: 'ClassType',
            label: this.ts.instant('classTypeText'),
            options: classType,
            value: this.mealData?.ClassType || ClassType.Session,
            appearance: false,
            validation: [Validators.required],
            isTranslate: true,
            disableErrorStateMatcher: true,
            class: "basic-select",
            disabled: this.isEdit,
            isStarSymbolRequired: true,
         },
         {
            type: 'select',
            name: 'ActivityTypeId',
            label: this.ts.instant('tagsText'),
            options: this.basicSettings?.ActivityTypes.map(type => { return { id: type.Id, value: type.TypeName } }),
            value: (this.mealData && this.mealData.ActivityTypeId) ? (this.mealData.ActivityTypeId) : (this.basicSettings && this.basicSettings.ActivityTypes.length) ? this.basicSettings?.ActivityTypes[0].Id : null,
            appearance: false,
            validation: [],
            isTranslate: true,
            disableErrorStateMatcher: true,
            class: "basic-select",
            selectAscendingSortOrder: true,
         },
         {
            type: 'groupSelect',
            name: 'Category',
            label: this.ts.instant('categoryText'),
            groups: this.masterCategories,
            value: (this.mealData && this.mealData.SubcategoryId) || null,
            appearance: false,
            validation: [],
            isTranslate: true,
            disableErrorStateMatcher: true,
            class: "basic-select",
            isStarSymbolRequired: true,
         },
         {
            type: 'input',
            name: 'CutOffMinutes',
            label: this.ts.instant('ActivityCut_offMins'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: [Validators.min(1)],
            value: this.mealData?.CutOffMinutes || null,
         },
         {
            type: 'input',
            name: 'SortOrder',
            label: this.ts.instant('SortOrder'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: [Validators.min(1)],
            value: this.mealData?.SortOrder || null,
         }
      ];
      if (this.isRetailEnabledProperty) {
         let retailItem = {
            type: 'select',
            name: 'RetailItemId',
            label: this.ts.instant('RetailItem'),
            options: this.basicSettings?.RetailItemDTO.map(type => { return { id: type.Id, value: type.RetailItemName } }),
            value: this.mealData?.RetailItemId || null,
            appearance: false,
            validation: this.isTemplate ? [] : [Validators.required],
            isTranslate: true,
            disableErrorStateMatcher: true,
            class: "basic-select",
            selectAscendingSortOrder: true,
            isStarSymbolRequired: true,

         }
         this.basicSetupConfig.push(retailItem);
      }
      this.AllowStandByConfig = [
         {
            type: 'checkbox',
            placeholder: this.ts.instant('AllowStandby'),
            value: this.mealData?.AllowStandBy || false,
            name: 'AllowStandBy',
            disabled: this.classType == ClassType.Duration ? true : false,
         },
      ]
      this.AllowOverbookConfig = [
         {
            type: 'checkbox',
            placeholder: this.ts.instant('AllowOverbook'),
            value: this.mealData?.AllowOverbook || false,
            name: 'AllowOverbook',
            disabled: this.classType == ClassType.Duration ? true : false,
         },
      ]
      this.AutoConfirmStandbyConfig = [
         {
            type: 'checkbox',
            placeholder: this.ts.instant('AutoConfirmStandby'),
            value: this.mealData?.AutoConfirmStandby || false,
            name: 'AutoConfirmStandby',
         },
      ]
      this.MaxStandByStrengthConfig = [
         {
            type: 'input',
            name: 'MaxStandByStrength',
            label: this.ts.instant('MaxStandbyStrength'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: this.isTemplate ? [] : [Validators.min(1), Validators.required],
            value: this.mealData?.MaxStandByStrength || null,
            class: 'w-100',
            isStarSymbolRequired: true,
            directive: 'numberOnly'
         },
      ]
      this.IsForStandbyReservationsConfig = [
         {
            type: 'radio',
            name: 'IsForStandbyReservations',
            placeholder: '',
            options: [
               { id: false, value: this.ts.instant('ConfirmedReservation') },
               { id: true, value: this.ts.instant('StandbyReservation') }
            ],
            value: this.mealData?.IsForStandbyReservations ?? false,
            class: 'activity-headlines__session-selection',
            // disabled: this.classType == ClassType.Duration ? true : false,
         }
      ];
   }
   addBookingDetailsConfig() {
      this.activitySettingConfig = [
         {
            type: 'input',
            name: 'MinPartySize',
            label: this.ts.instant('MinGroupSize'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: this.isTemplate ? [Validators.min(1)] : [Validators.required, Validators.min(1)],
            value: this.mealData?.MinPartySize || 1,
            class: 'w-100',
            disableErrorStateMatcher: true,
            isStarSymbolRequired: true,
            directive: 'numberOnly'
         },
         {
            type: 'input',
            name: 'MaxPartySize',
            label: this.ts.instant('MaxGroupSize'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: this.isTemplate ? [Validators.min(1)] : [Validators.required, Validators.min(1)],
            value: this.mealData?.MaxPartySize || 1,
            class: 'w-100',
            disableErrorStateMatcher: true,
            isStarSymbolRequired: true,
            directive: 'numberOnly'
         },
         {
            type: 'input',
            name: this.classType == ClassType.Duration ? 'MinDuration' : 'MinActivityStrength',
            label: this.classType == ClassType.Duration ? this.ts.instant('MinDuration') : this.ts.instant('MinActivityStrength'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: this.isTemplate ? [Validators.min(1)] : [Validators.required, Validators.min(1)],
            value: this.classType == ClassType.Duration ? (this.mealData?.MinDuration || null) : (this.mealData?.MinActivityStrength || 1),
            class: 'w-100',
            disableErrorStateMatcher: true,
            isStarSymbolRequired: true,
            directive: 'numberOnly'
         },
         {
            type: 'input',
            name: this.classType == ClassType.Duration ? 'MaxDuration' : 'MaxActivityStrength',
            label: this.classType == ClassType.Duration ? this.ts.instant('MaxDuration') : this.ts.instant('MaxActivityStrength'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: this.isTemplate ? [Validators.min(1)] : [Validators.required, Validators.min(1)],
            value: this.classType == ClassType.Duration ? (this.mealData?.MaxDuration || null) : (this.mealData?.MaxActivityStrength || 1),
            class: 'w-100',
            disableErrorStateMatcher: true,
            isStarSymbolRequired: true,
            directive: 'numberOnly'
         }
      ]
   }
   addActivitySetupConfig() {
      this.ticketPrintConfig = [
         {
            type: 'checkbox',
            placeholder: this.ts.instant('EnableTicketPrinting'),
            value: this.mealData?.EnableTicketPrinting || false,
            name: 'EnableTicketPrinting',
            class: 'w-100'
         },
         {
            type: 'checkbox',
            placeholder: this.ts.instant('TicketPerPerson'),
            value: this.mealData?.TicketPerPerson || false,
            name: 'TicketPerPerson',
            class: 'w-100'
         },
      ];

      this.raftingConfig = [
         {
            type: 'checkbox',
            placeholder: this.ts.instant('defaultEnableAsset'),
            value: this.mealData ? (this.mealData?.AssetBookingType == AssetBookingType.ExclusiveBooking ? true : false) : true,
            name: 'AssetBookingType',
            class: 'w-100 chip-conrtol'
         }
      ]
      this.additionalEmployeeConfig = [{
         type: 'checkbox',
         placeholder: this.ts.instant('collectSalesContact'),
         value: this.mealData?.TrackingofSalesPerson || false,
         name: 'TrackingofSalesPerson',
         class: 'w-100'
      },
      {
         type: 'checkbox',
         placeholder: this.ts.instant('sendAllCommunicationForSalesContact'),
         value: this.mealData?.IncludeSalesInAlerts || false,
         name: 'IncludeSalesInAlerts',
         class: 'w-100',
         disabled: this.mealData?.TrackingofSalesPerson ? false : this.disableSendAllCommunication
      },]
      this.remainderConfig =[
         {
            type: 'input',
            name: 'ReminderScheduleMinutesInAdvance',
            label: this.ts.instant('ReminderMinutesinAdvance'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: [Validators.min(0)],
            value: this.mealData ? this.mealData.ReminderScheduleMinutesInAdvance : this.cs.settings.value.MerchantSettings.ReminderMinutesinAdvance.SettingValue,
            directive: 'numberOnly'
         }

      ]
   }
   addCalenderDetailsConfig() {
      this.calendarDetailsConfig = [
         {
            type: 'date',
            name: 'StartDate',
            inputType: 'text',
            appearance: false,
            validation: this.isTemplate ? [] : [Validators.required],
            label: this.ts.instant("ActivityFromDate"),
            value: (this.appService.isTemplateUsed && (new Date(this.mealData.StartDate) < new Date()) ? new Date() : this.mealData?.StartDate) || this.currentRestaurantDateTime || new Date(),
            class: 'w-100',
            minDate: (this.mealData && this.mealData.StartDate && (new Date(this.mealData.StartDate) >= Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta))) ? (moment(this.mealData.StartDate).format('YYYY MM DD') <= moment(new Date()).format('YYYY MM DD')) ? new Date(this.mealData.StartDate) : new Date() : new Date(),
            isStarSymbolRequired: true,
            disabled: (this.mealData && this.mealData.StartDate && this.mealData.HasParties && (new Date(this.mealData.EndDate) >= Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta))) ? true : false
            // disabled : (this.mealData  && this.mealData.StartDate) ? (moment(this.mealData.StartDate).format('YYYY MM DD') <= moment(new Date()).format('YYYY MM DD')) : false
         },
         {
            type: 'date',
            name: 'EndDate',
            inputType: 'text',
            appearance: false,
            validation: this.isTemplate ? [] : [Validators.required],
            label: this.ts.instant("ActivityToDate"),
            value: (this.appService.isTemplateUsed && (new Date(this.mealData.EndDate) < new Date()) ? new Date() : this.mealData?.EndDate) || this.currentRestaurantDateTime || new Date(),
            class: 'w-100',
            minDate: (this.mealData && this.mealData.StartDate) ? (moment(this.mealData.StartDate).format('YYYY MM DD') >= moment(new Date()).format('YYYY MM DD')) ? new Date(this.mealData.StartDate) : new Date() : new Date(),
            isStarSymbolRequired: true,
         }
      ]
   }
   add_AddonSetupConfig() {
      this.addonDetailsConfig = [
         {
            type: 'select',
            name: 'add_on',
            label: this.ts.instant('categoryText'),
            options: this.basicSettings?.CategoryDTO?.map(type => { return { id: type.Id, value: type.Text } }) || [],
            value: this.basicSettings?.CategoryDTO?.map(type => { return type.Id }),
            appearance: false,
            validation: [],
            isTranslate: true,
            selectMultipleOptions: true,
            disableErrorStateMatcher: true,
            class: "basic-select",
            selectAscendingSortOrder: true,
         },
      ]
      // this.addonDetailsConfig[0].value.push(-1);
      // this.addonDetailsConfig[0].options.unshift({ id: -1, value: this.ts.instant('selectAllText') })
   }
   getFormCodeConfig() {
      this.guestLinkConfig = [
         {
            type: 'select',
            name: 'GuestLinkType',
            label: this.ts.instant('LinkType'),
            options: [
               { id: GuestLinkType.None, value: this.ts.instant('None') },
               { id: GuestLinkType.LeadBooker, value: this.ts.instant('LeadBooker') },
               { id: GuestLinkType.IndividualGuest, value: this.ts.instant('IndividualGuest') },
               { id: GuestLinkType.AllGuests, value: this.ts.instant('AllGuests') },
            ],
            value: this.mealData?.GuestLinkType || GuestLinkType.None,
            appearance: false,
            validation: [],
            isTranslate: true,
            disableErrorStateMatcher: true,
            class: "payment-select link-type",
         }
      ]
  }
   getpaymentDetailsConfig() {
      this.paymentAvailableConfig = [
         {
            type: 'select',
            name: 'PrePaymentMode',
            label: this.ts.instant('paymentRequired'),
            options: [
               { id: PrePaymentMode.None, value: this.ts.instant('no') },
               { id: PrePaymentMode.PrepaidPaymentMode, value: this.ts.instant('Yes') }
               // { id: PrePaymentMode.DepositPaymentMode, value: this.ts.instant('CollectDeposit') },
               // { id: PrePaymentMode.DefferredPaymentMode, value: this.ts.instant('DeferredPrepayment') },
               // { id: PrePaymentMode.PrepaidPaymentMode, value: this.ts.instant('Chargeonbooking') },
               // { id: PrePaymentMode.ChargeduringCheckIn, value: this.ts.instant('ChargeduringCheck-In') },
               // { id: PrePaymentMode.ChargeduringCheckOut, value: this.ts.instant('ChargeduringCheck-Out') },
            ],
            value: this.mealData?.PrePaymentMode || PrePaymentMode.None,
            appearance: false,
            validation: [],
            isTranslate: true,
            disableErrorStateMatcher: true,
            class: "payment-select",
         }
      ]
      this.serviceChargeConfig = [{
         type: 'select',
         name: 'ServiceCharges',
         label: this.ts.instant('ServiceCharge'),
         options: this.getSericeChargeOptions(BookingChargeType.ServiceCharge),
         value: this.getSericeChargeValue(BookingChargeType.ServiceCharge),
         appearance: false,
         validation: [],
         isTranslate: true,
         disableErrorStateMatcher: true,
         class: "payment-select",
         selectAscendingSortOrder: true,
         selectMultipleOptions: true,
      }]
      this.taxConfig = [{
         type: 'select',
         name: 'Taxes',
         label: this.ts.instant('Tax'),
         options: this.getSericeChargeOptions(BookingChargeType.Tax),
         value: this.getSericeChargeValue(BookingChargeType.Tax),
         appearance: false,
         validation: [],
         isTranslate: true,
         disableErrorStateMatcher: true,
         class: "payment-select",
         selectAscendingSortOrder: true,
         selectMultipleOptions: true,
      }]

      this.paymentDetailsConfig = [
         {
            type: 'select',
            name: 'RatePlanId',
            label: this.ts.instant('ratePlanAmount'),
            options: [],
            value: (this.mealData?.RatePlanId == null ? -1 : this.mealData?.RatePlanId) || null,
            appearance: false,
            validation: this.isTemplate || this.classType == ClassType.Duration ? [] : [Validators.required],
            isTranslate: true,
            disableErrorStateMatcher: true,
            class: "payment-select",
            selectAscendingSortOrder: true,
            isStarSymbolRequired: true,
         }
      ];

      this.defaultPaymentTypeConfig = [
         {
            type: 'select',
            name: 'DefaultPaymentType',
            label: this.ts.instant('DefaultPaymentType'),
            options: [
               { id: PaymentMethod.Prepayment, value: this.ts.instant('prepayment') },
               { id: PaymentMethod.Authorize, value: this.ts.instant('AuthorizeandPayLater') },
               { id: PaymentMethod.SkipPayment, value: this.ts.instant('SkipPayment') },
            ],
            value: this.mealData?.DefaultPaymentType || PaymentMethod.Prepayment,
            // validation: this.isTemplate || this.classType == ClassType.Duration ? [] : [Validators.required],
            isTranslate: true,
            disableErrorStateMatcher: true,
            class: "payment-select",
            selectAscendingSortOrder: true,
            isStarSymbolRequired: true,
         }
      ];

      this.collectDepositConfig = [
         {
            type: 'radio',
            name: 'DepositType',
            placeholder: '',
            options: [
               { id: PaymentType.FlatFee, value: this.ts.instant('FlatFee') },
               { id: PaymentType.Percentage, value: this.ts.instant('Percentage') }
            ],
            value: this.mealData?.DepositType || PaymentType.FlatFee,
            class: 'activity-headlines__session-selection',
         },
         {
            type: 'input',
            name: 'Deposit',
            label: this.ts.instant('Deposit'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: this.isTemplate ? [Validators.min(1)] : [Validators.required, Validators.min(1)],
            value: this.mealData?.Deposit || null,
            isStarSymbolRequired: true,
            disableErrorStateMatcher: true,
         }
      ]
      this.diferredConfig = [
         {
            type: 'input',
            name: 'DeferredPaymentInDays',
            label: this.ts.instant('PrepaymentAvailable'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: [Validators.min(0)],
            value: this.mealData?.DeferredPaymentInDays || 0,
         },

      ]
      this.checkinCheckOutConfig = [
         {
            type: 'radio',
            name: 'CollectType',
            placeholder: '',
            options: [
               { id: CollectType.CheckIn, value: this.ts.instant('Check-In') },
               { id: CollectType.CheckOut, value: this.ts.instant('Check-Out') }
            ],
            value: this.mealData?.CollectType || CollectType.CheckIn,
            class: 'activity-headlines__session-selection',
         },
      ]
   }
   getcancellationDetailsConfig() {
      this.cancellationConfig = [
         {
            type: 'radio',
            name: 'CancellationFeeType',
            placeholder: '',
            options: [
               { id: PaymentType.FlatFee, value: this.ts.instant('FlatFee') },
               { id: PaymentType.Percentage, value: this.ts.instant('Percentage') }
            ],
            value: this.mealData?.CancellationFeeType || PaymentType.FlatFee,
            class: 'activity-headlines__session-selection',
         },
         {
            type: 'input',
            name: 'CancellationFee',
            label: this.mealData?.CancellationFeeType == PaymentType.Percentage ? this.ts.instant('CancellationFee') : this.ts.instant('CancellationFlatFee'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: this.mealData?.CancellationFeeType == PaymentType.Percentage ? [Validators.min(0), Validators.max(100)] : [Validators.min(0)],
            value: this.mealData?.CancellationFee || 0,
         },
         {
            type: 'input',
            name: 'MinTimeToCancelActivityReservationMinutes',
            label: this.ts.instant('Cancelreservation'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: [Validators.min(0)],
            value: this.mealData?.MinTimeToCancelActivityReservationMinutes ? Math.round(this.mealData?.MinTimeToCancelActivityReservationMinutes / 60) : 0,
         },
         {
            type: 'textarea',
            name: 'CancellationPolicy',
            label: this.ts.instant('CancellationPolicy'),
            inputType: 'text',
            showHint: true,
            charLength: 1000,
            value: this.mealData?.CancellationPolicy || '',
            appearance: false,
            class: 'activity-description'
         },
      ]
   }
   getGuestSettingsConfig() {
      this.AllowAdditionalGuestConfig = [{
         type: 'checkbox',
         placeholder: this.ts.instant('AllowAdditionalGuest'),
         value: this.mealData ? this.mealData?.AllowAddingSecondaryGuest : false,
         name: 'AllowAddingSecondaryGuest',
      }]
      let guestConfig = [];
      Object.keys(this.secondaryGuestSettings).forEach((key) => {
         let config = {
            type: 'checkbox',
            placeholder: this.ts.instant(key == 'CustomGuestFields' ? 'Secondary_CustomGuestFields' : key),
            value: this.mealData ? this.mealData[key] : this.secondaryGuestSettings[key],
            name: key
         }
         guestConfig.push(config);
      })
      this.guestSettingsConfig = guestConfig;
   }

   getOnlineReservationConfig() {
      this.OnlineReservationConfig = [
         {
            type: 'checkbox',
            placeholder: this.ts.instant('availableforwebreservation'),
            value: this.mealData?.AvailableForWebReservation || false,
            name: 'AvailableForWebReservation',
            class: 'available-for-web-reservation'
         },
         {
            type: 'input',
            name: 'SlotCountInWidget',
            label: this.ts.instant('SlotCountinWidget'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: [Validators.min(0)],
            value: this.mealData?.SlotCountInWidget || null,
            class: '',
            directive: 'numberOnly'
         },
         {
            type: 'select',
            name: 'WidgetPrePaymentMode',
            label: this.ts.instant('PaymentType'),
            options: [
               { id: PrePaymentMode.PrepaidPaymentMode, value: this.ts.instant('Chargeonbooking') },
               { id: PrePaymentMode.CardOnFile, value: this.ts.instant('AuthorizeandPayLater') },
            ],
            value: this.mealData?.WidgetPrePaymentMode || PrePaymentMode.PrepaidPaymentMode,
            appearance: false,
            validation: [],
            isTranslate: true,

            disableErrorStateMatcher: true,
            class:  this.mealData?.PrePaymentMode  ?  this.mealData?.PrePaymentMode == PrePaymentMode.None ? "w-307px web-payment-disabled w-100" : "web-payment w-100" : "w-307px web-payment-disabled w-100",
         },
         {
            type: 'input',
            name: 'cancellationCutOffMins',
            label: this.ts.instant('Cancellation')+ ' ' + this.ts.instant('cutOffMinutes'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: [Validators.min(0)],
            value: this.mealData?.OnlineCancelCutOffMinutes || null,
            class: '',
            directive: 'numberOnly'
         },
         {
            type: 'textarea',
            name: 'cancellationCutOffDesc',
            inputType: 'text',
            label: this.ts.instant('cancellationDesc'),
            value: this.mealData?.OnlineCancelCutOffDescription || '',
            showHint: true,
            charLength: 1000,
            class:'w-100'
         },
      ]
   }

   getadditionalSettingsConfig() {
      this.additionalSettingsConfig = [
         {
            type: 'input',
            name: 'WaiverFormCode',
            inputType: 'text',
            label: this.ts.instant('GuestWaiverForm'),
            value: this.mealData?.WaiverFormCode || '',
            showErrorText: true,
            disableErrorStateMatcher: true,
         },
         {
            type: 'select',
            name: 'DataMagineEFormId',
            label: this.ts.instant('dmDataMagine'),
            options: this.activityData.DataMagineEForms.map(x => { return { id: x.Id, value: x.Name } }),
            value: this.mealData?.DataMagineEFormId || -1,
            appearance: false,
            validation: [],
            isTranslate: true,
            disableErrorStateMatcher: true,
            class: 'basic-select activity-creation__datamagine-form',
            selectAscendingSortOrder: false,
         },
         {
            type: 'input',
            name: 'PublicHeadline',
            inputType: 'text',
            label: this.ts.instant('PublicQuickNote'),
            value: this.activityData.ActivityAttributes.find(x => x.IsDefault == true && x.AttributeType == AttributeType.PublicHeadlines)?.Description || '',
            validation: [],
            showErrorText: true,
            disableErrorStateMatcher: true
         },
         {
            type: 'input',
            name: 'PrivateHeadline',
            inputType: 'text',
            label: this.ts.instant('PrivateQuickNote'),
            value: this.activityData.ActivityAttributes.find(x => x.IsDefault == true && x.AttributeType == AttributeType.PrivateHeadlines)?.Description || '',
            validation: [],
            showErrorText: true,
            disableErrorStateMatcher: true
         },
         {
            type: 'input',
            name: 'CheckInCutOff',
            label: this.ts.instant('Check_incutoff'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: [Validators.min(0)],
            value: this.mealData?.CheckInCutOff || null,
            class: 'w-100',
            directive: 'numberOnly'
         }
      ]

      this.additionalSettingsConfig[1].options.unshift({ id: -1, value: this.ts.instant('None') })

      let _staffList = cloneDeep(this.cs.settings.value.Hosts) as any;
      let selectedStaffList = [];
      if(this.mealData?.SalesContactIds?.length){
         selectedStaffList = _staffList.filter(staff => this.mealData.SalesContactIds.includes(staff.Id))?.map(contact => { contact.firstName = contact.value; return contact });
      }
      this.emailNotificationConfig = [
         {
            type: 'radio',
            name: 'NotificationType',
            options: [
               { id: NotifyType.None, value: this.ts.instant('None') },
               { id: NotifyType.Online, value: this.ts.instant('OnlineNotification') },
               { id: NotifyType.All, value: this.ts.instant('AllNotificationType') }
            ],
            value: this.mealData ? this.mealData?.NotificationType : NotifyType.None,
            class: 'activity-headlines__session-selection',
            // disabled: this.classType == ClassType.Duration ? true : false,
         },
         {
            type: 'autocomplete',
            name: 'SalesContactIds',
            options: _staffList.map(staff => { staff.firstName = staff.Name;staff.value = staff.Name; staff.id = staff.Id; return staff }) || [],
            label: this.ts.instant('NotificationStaff'),
            class: 'w-60',
            showErrorText: true,
            isTranslate: false,
            autoCompleteWithId: true,
            icon: 'icon-search',
            icon1: 'icon-Group-591',
            isChipsEnabled: true,
            isEmailChipsEnabled: false,
            value:selectedStaffList.map(staff => {return staff.Id}),
            multiChipValues: selectedStaffList,
            returnFunction: this.clearFormField.bind(this, 'SalesContactIds'),
         },
         {
            type: 'autocomplete',
            name: 'NotificationEmailIds',
            label: this.ts.instant('NotificationEmail'),
            class: 'w-60',
            showErrorText: true,
            isTranslate: false,
            autoCompleteWithId: true,
            icon: 'icon-search',
            icon1: 'icon-Group-591',
            isChipsEnabled: true,
            isEmailChipsEnabled: true,
            errorMessage: this.ts.instant('InvalidEmailFormat'),
            value:this.mealData ? this.mealData.NotificationEmailIds : [],
            multiChipValues: this.mealData ? this.mealData.NotificationEmailIds?.map(email => { return {value:email}}) : [],
            returnFunction: this.clearFormField.bind(this, 'NotificationEmailIds'),
         }
      ]

      this.shortNoticeConfig = [
         {
            type: 'radio',
            name: 'NotificationPeriod',
            placeholder: '',
            options: [
               { id: NotifyPeriod.None, value: this.ts.instant('None') },
               { id: NotifyPeriod.SameDay, value: this.ts.instant('sameDayAlert') },
               { id: NotifyPeriod.Duration, value: this.ts.instant('Duration') }
            ],
            value: this.mealData ? this.mealData?.NotificationPeriod : NotifyPeriod.None,
            class: 'activity-headlines__session-selection',
            // disabled: this.classType == ClassType.Duration ? true : false,
         }
      ]
      this.showNotificationMinutes = this.mealData?.NotificationPeriod == NotifyPeriod.Duration;
      this.notificationCutOffConfig = [
         {
            type: 'input',
            name: 'NotificationCutOff',
            label: this.ts.instant('motificationAlertMinutes'),
            inputType: 'number',
            appearance: false,
            isTranslate: true,
            validation: [Validators.min(0)],
            value: this.mealData?.NotificationCutOff || null,
            class: 'w-100',
            directive: 'numberOnly'
         }
      ];
      this.notifyStaffConfig = [

         {
            type: 'checkbox',
            placeholder: this.ts.instant('assignedStaff'),
            value: this.mealData?.NotifyStaff || false,
            name: 'NotifyStaff'
         },
      ]
   }

   clearFormField(formName) {
      this.emailNotificationsForm.form.get(formName).setValue('');
   }



   validateMinimumQuantity(event) {
      var bookingDetails = this.activitySettingForm.value;
      if (Number(event.target.value) < 0 || event.target.value == '') {
         event.target.value = 0;
      } else if (Number(event.target.value) > Number(bookingDetails?.MaxPartySize)) {
         event.target.value = bookingDetails?.MaxPartySize;
      }
   }
   ngOnDestroy() {
      this.ss.activitySessionList = [];
      this.ss.addOnsMappingList = [];
      this.ss.ImageData = '',
         this.ss.ImageChanged = false;

      if (this.subscriptions) {
         this.subscriptions.unsubscribe();
      }
   }
}


