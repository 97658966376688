import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { controlSettings } from '@app/shared/constants/globalConstants';
import { RestaurantStateService } from '@app/shared/services/restaurant-state.service';
import { ChangeAction, Menu, OperationResultState, PartyState, PartyType, SlottingType } from '@constants/commonenums';
import { ObjectChange, PropertyChange } from '@models/ChangeTrackingOperationResultDTO';
import { ContactVisitStatsDTO, PartyPrepaymentState, ReservationDTO } from '@models/InputContact';
import { StandByPartyDTO, WalkInDTO } from '@models/InputReservation';
import { Party } from '@models/Party';
import { ContactDTO, PartyChargeState } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import { SettingsService } from '@services/settings.service';
import { PartyMapper } from '@utilities/party-mapper';
import { Utilities } from '@utilities/utilities';
import { groupBy, isObject, uniqBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Processor } from './processor';

// TODO: Add Angular decorator.
@Injectable()
export class PartyChangeProcessor implements Processor {
  private static instance: PartyChangeProcessor;
  subscriptions: Subscription = new Subscription();

  private constructor(public partyService: PartyService, public as: AppService, public ss: SettingsService,
    public toastrService: ToastrService, public stateService: RestaurantStateService) {
  }

  public static Instance(ps: PartyService, _as: AppService, _ss: SettingsService, _ts: ToastrService,
    rss: RestaurantStateService): PartyChangeProcessor {
    if (!PartyChangeProcessor.instance) {
      PartyChangeProcessor.instance = new PartyChangeProcessor(ps, _as, _ss, _ts, rss);
    }

    return PartyChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: any, propertyId: number): void {
    let partiesList;
    let IsStandbyParty: boolean = false;
    let isNotHeaderDateParty: boolean = false;
    if (this.checkStandby(objectChange, propertyId)) {
      let reservations = propertyId == Utilities.RestaurantId() ? this.partyService.partiesList : this.as._cs.propertySettings.value[propertyId].reservations;
      let convertReservationToStandbyParty = reservations.find(party => party.Id == objectChange.ObjectId);
      if (convertReservationToStandbyParty) {
        this.partyService.standbyPartiesList?.push(convertReservationToStandbyParty);
        this.partyService.StandbyParties$.value?.push(convertReservationToStandbyParty);
        this.as._cs.propertySettings.value[propertyId].StandbyParties?.push(convertReservationToStandbyParty);
        this.partyService.partiesList = this.partyService.partiesList?.filter(party => party.Id !== objectChange.ObjectId);
        this.as._cs.propertySettings.value[propertyId].reservations = this.as._cs.propertySettings.value[propertyId].reservations?.filter(party => party.Id !== objectChange.ObjectId)
      }
      IsStandbyParty = true;
      partiesList = propertyId == Utilities.RestaurantId() ?
        (this.partyService.standbyPartiesList.length > 0 ? this.partyService.standbyPartiesList : this.partyService.StandbyParties$.value) :
        this.as._cs.propertySettings.value[propertyId].StandbyParties;
    }
    else {
      IsStandbyParty = false;
      partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.partiesList : this.as._cs.propertySettings.value[propertyId].reservations;
    }
    let reservationDate = objectChange.PropertyChanges.find(property => property.PropertyName == "DateTimeInternal");
    let startDate = objectChange.PropertyChanges.find(property => property.PropertyName == "StartDate")?.Value;
    let endDate = objectChange.PropertyChanges.find(property => property.PropertyName == "EndDate")?.Value;
    if ((!startDate || !endDate) && partiesList.find(party => party.Id == objectChange.ObjectId)) {
      var party = partiesList.filter(party => party.Id == objectChange.ObjectId)[0];
      startDate = !startDate ? party.StartDate : null;
      endDate = !endDate ? party.EndDate : null;
    }
    if (reservationDate && !this.isReservationFallsOnHeaderDate(reservationDate.Value, startDate ? startDate : null, endDate ? endDate : null, propertyId)) {
      let partyData = partiesList.find(party => party.Id == objectChange.ObjectId);
      if (partyData) {
        this.handlePartyDateChange(partyData, objectChange);
        partiesList = partiesList.filter(party => party.Id !== objectChange.ObjectId);
        this.refreshPartyData(partiesList, IsStandbyParty, propertyId);
      }
      isNotHeaderDateParty = true;
      if (this.as.selectedMenuId != Menu.GuestBook) {
        return;
      }
    }


    if (objectChange && partiesList) {
      if (additionaldata) {
        partiesList.forEach(party => {
          if (party.Contact && party.Contact.Id === additionaldata.Id && additionaldata.VisitStats && additionaldata.VisitStats.length > 0) {
            party.Contact.VisitStats = additionaldata.VisitStats as ContactVisitStatsDTO;
          }
        });
      }

      if (objectChange.Action === ChangeAction.Created) {
        const party = new Party();
        objectChange.PropertyChanges.forEach(property => {
          const isTypeObject = isObject(party[property.PropertyName.replace('Internal', '')]);
          if (property.PropertyName === 'CachedTablesInternal') {
            party.TableIds = JSON.parse(property.Value);
          }
          if (property.PropertyName === 'DepartureTimeBeforeSeatingInternal') {
            party.EstimatedDepartureTime = property.Value;
          }
          if (property.PropertyName === 'StartDate') {
            party.StartDate = property.Value;
          }
          if (property.PropertyName === 'EndDate') {
            party.EndDate = property.Value;
          }
          if (property.PropertyName === 'SessionGroupId') {
            party.SessionGroupId = property.Value;
          }
          if (property.PropertyName === 'PackageId') {
            party.PackageId = property.Value;
          }
          if (property.PropertyName === 'ReferenceNumber') {
            party.ReferenceNumber = property.Value;
          }
          if (Object.getOwnPropertyNames(party).includes(property.PropertyName.replace('Internal', ''))) {
            party[property.PropertyName.replace('Internal', '')] = isTypeObject ? JSON.parse(property.Value) : property.Value;
          }
          if (property.PropertyName === 'PackageDetails') {
            party.PackageDiscountAmount = JSON.parse(property.Value)?.DiscountedAmount;
          }
          if (property.PropertyName == 'PartySourceId' && this.as.OTASourceId.includes(property.Value) && this.as.showOTAMessage) {
            this.showOTANotification(IsStandbyParty);
          }
        });
        let updatedParty;
        if (party) {
          switch (party.Type) {
            case PartyType.Reservation:
              case PartyType.RentalReservation:
              updatedParty = new ReservationDTO();
              updatedParty = PartyMapper.mapPartyDTO(party);
              break;
            case PartyType.WalkIn:
              updatedParty = new WalkInDTO();
              updatedParty = PartyMapper.mapPartyDTO(party);
              break;
            case PartyType.StandBy:
              updatedParty = new StandByPartyDTO();
              updatedParty = PartyMapper.mapPartyDTO(party);
              break;
          }
          if (additionaldata && updatedParty && additionaldata.length > 0 && additionaldata.find(data => data.Id === updatedParty.Contact.Id)) {
            let additionalContactData = additionaldata.find(data => data.Id === updatedParty.Contact.Id);
            if (additionalContactData) {
              updatedParty.Contact = additionalContactData as ContactDTO;
            }
          }
          if (updatedParty && partiesList && partiesList.length >= 0 && !isNotHeaderDateParty) {
            if(this.as.propertyNotifications[updatedParty.RestaurantId] !== null && this.as.propertyNotifications[updatedParty.RestaurantId] !== undefined) 
            this.as.propertyNotifications[updatedParty.RestaurantId] = true;
            // switch (this.as.selectedMenuId) {
            // case Menu.Reservation:
            if (party.Type === PartyType.Reservation || party.Type === PartyType.StandBy || party.Type === PartyType.RentalReservation) {
               if(updatedParty.RestaurantId === +propertyId){
                partiesList.push({ ...updatedParty });
               }
            }
            //  break;
            // case Menu.WaitList:
            else if (party.Type === PartyType.WalkIn) {
              partiesList.push({ ...updatedParty });
            }
            //  break;
            /*  case Menu.Tables:
             case Menu.FloorPlan:
             case Menu.TimeLine:
             case Menu.Servers:
             case Menu.GuestBook:
             case Menu.ActivitiesTimeline:
               partiesList.push({ ...updatedParty });
               break; */
            // }
            if (party.Type === PartyType.WalkIn) {
              this.stateService.addPartyToState({ ...updatedParty }, propertyId);
            }
          }

          if (propertyId == Utilities.RestaurantId() && updatedParty && this.as.selectedMenuId == Menu.GuestBook
            && this.partyService.contacts$.value && this.partyService.contacts$.value.Id == updatedParty.Contact?.Id) {
            this.createPartyForContact(updatedParty);
          }
        }

        this.refreshPartyData(partiesList, IsStandbyParty, propertyId);
      }

      if (objectChange.Action === ChangeAction.Updated) {
        const isPartyRemoved = this.checkIfPartyIsRemoved(objectChange);
        let showCancelledReservation =  Utilities.controlValidate(controlSettings.Booking_Popup_Show_CancelledReservation, this.as.PropertyType);
        if (!isPartyRemoved || showCancelledReservation) {
          this.CheckIsReseatUpdate(partiesList, objectChange, propertyId);
          const partyDetails = partiesList.find((party) => party.Id === objectChange.ObjectId);
          if (partyDetails) {
            let currentState = objectChange.PropertyChanges?.find(property => property?.PropertyName === 'StateInternal')?.Value;
            objectChange.PropertyChanges.forEach(property => {
              const isTypeObject = isObject(partyDetails[property.PropertyName.replace('Internal', '')]);
              this.mapMissedProperties(property, partyDetails);
              if (Object.getOwnPropertyNames(partyDetails).includes(property.PropertyName.replace('Internal', ''))) {
                partyDetails[property.PropertyName.replace('Internal', '')] = isTypeObject ? JSON.parse(property.Value) : property.Value;
              }
              if (property.PropertyName === 'PackageDetails') {
                partyDetails.PackageDiscountAmount = JSON.parse(property.Value)?.DiscountedAmount;
              }
              if (property.PropertyName === 'DepartureTime' && currentState === PartyState.Left) {
                party.RentReturnTime = property.Value;
              }
              if (property.PropertyName === 'SeatingTime' && currentState === PartyState.Seated) {
                party.RentOutTime = property.Value;
              }
            });
            if (propertyId == Utilities.RestaurantId()) {
              if (this.partyService.selectedParty$.value && this.partyService.selectedParty$.value.Id == objectChange.ObjectId) {
                this.partyService.selectedParty$.next(partyDetails);
              }
              // if (this.partyService.selectedParty$.value && this.partyService.selectedParty$.value.Id != objectChange.ObjectId) {
              //   this.partyService.selectedParty$.next(partyDetails);
              // }
            }
            this.stateService.updatePartyInState(partyDetails, objectChange, propertyId);

          } else {
            if (!isNotHeaderDateParty) {
              this.stateService.processPartyInState(objectChange, this.mapMissedProperties, propertyId);
              let standbyParties = this.partyService.StandbyParties$.value;
              if (propertyId != Utilities.RestaurantId()) {
                standbyParties = this.as._cs.propertySettings.value[propertyId].StandbyParties;
              }
              const StandbypartyDetails = standbyParties?.find((party) => party.Id === objectChange.ObjectId);
              let objType = objectChange.PropertyChanges.filter(property => property.PropertyName.replace('Internal', '') == 'Type');

              if (StandbypartyDetails) {
                if (objType.length > 0 && objType[0].Value === PartyType.Reservation) {
                  StandbypartyDetails.Type = PartyType.Reservation;
                  StandbypartyDetails.ReservedFor = StandbypartyDetails.WishedTime;
                  if(objType[0].OriginalValue == PartyType.StandBy && objType[0].Value === PartyType.Reservation){
                    objectChange.PropertyChanges.forEach(property => {
                      if (property.PropertyName.replace('Internal', '') == 'SeatingTime') {
                        StandbypartyDetails.SeatingTime = property.Value;
                      }else if(property.PropertyName.replace('Internal', '') == 'DepartureTime'){
                        StandbypartyDetails.DepartureTime = property.Value;
                      }else if(property.PropertyName == 'Size'){
                        StandbypartyDetails.Size = property.Value;
                        StandbypartyDetails.BookingSize = property.Value;
                      }else if(property.PropertyName == 'TaxAmount'){
                        StandbypartyDetails.TaxAmount = property.Value;
                      }else if(property.PropertyName == 'RatePlanAmount'){
                        StandbypartyDetails.RatePlanAmount = property.Value;
                      }else if(property.PropertyName == 'RefundedAt'){
                        StandbypartyDetails.RefundedAt = property.Value;
                      }else if(property.PropertyName.replace('Internal', '') == 'DateTime' ){
                        StandbypartyDetails.DateTime = property.Value;
                        StandbypartyDetails.ReservedFor = property.Value;
                        
                      }else if(property.PropertyName.replace('Internal', '') == 'TableNames'){
                        StandbypartyDetails.TableNames = property.Value;
                      }
                    else if(property.PropertyName == 'SalesContactIds'){
                      StandbypartyDetails.SalesContactIds = property.Value;
                    }
                    else if(property.PropertyName  == 'CancellationFeeAmount'){
                      StandbypartyDetails.CancellationFeeAmount = property.Value;
                    }
                    });
                  }
                  partiesList.push(StandbypartyDetails);
                  // this.partyService.StandbyParties$.next(partiesList);
                  this.refreshPartyData(standbyParties.filter((party) => party.Id !== objectChange.ObjectId), true, propertyId);
                }
              } else {
                let _reservationDate = reservationDate;
                if(typeof reservationDate === 'object' && reservationDate.hasOwnProperty("Value")){
                  _reservationDate = reservationDate.Value;
                }
                if (_reservationDate && Utilities.Date(_reservationDate).diff(Utilities.Date(this.as._headerDate), 'd') == 0) {
                  this.subscriptions.add(this.loadParty(objectChange.ObjectId).subscribe(result => {
                    if (partiesList && result && result.State == OperationResultState.Success) {
                      partiesList.push(result.Payload);
                      partiesList = uniqBy(partiesList, 'Id');
                      if (propertyId == Utilities.RestaurantId()) {
                        this.partyService.Parties$.next(partiesList);
                        this.as._cs.propertySettings.value[propertyId].reservations = partiesList;
                      } else {
                        this.as._cs.propertySettings.value[propertyId].reservations = partiesList;
                      }
                    }
                  }));
                }
              }
            }
          }
          // The below code is for the move tables.
          //if (this.partyService.cs.state.value.SeatingParties.find(party => party.Id === objectChange.ObjectId)) {
          if (propertyId == Utilities.RestaurantId()) {
            let cachedTables = objectChange.PropertyChanges.filter(change => change.PropertyName.replace('Internal', '') === 'CachedTables');
            if (cachedTables && cachedTables.length > 0) {
              this.partyService.cachedMoveTables = [];
              let allCachedTables = JSON.parse(cachedTables[0].OriginalValue);
              this.partyService.cachedMoveTables.push(...allCachedTables);
            }
          }
          //}
          objectChange.PropertyChanges.forEach(property => {
            if (property.PropertyName === 'ContactId' && partyDetails.Contact) {
              partyDetails.Contact.Id = property.Value;
            }
          });

          if(party && (party.State === PartyState.Cancelled || party.State === PartyState.Left)){
            this.partyService.RemovedParty$.next([party]);
          }
          
        } else {
          if(party && this.as.propertyNotifications[party.RestaurantId] !== null && this.as.propertyNotifications[party.RestaurantId] !== undefined) 
          this.as.propertyNotifications[party.RestaurantId] = true;
          partiesList = uniqBy(partiesList.filter((party) => party.Id !== objectChange.ObjectId), 'Id');
        }

        this.updatePartyForContact(isPartyRemoved, objectChange);
        objectChange.PropertyChanges.forEach(property => {
          if (property.PropertyName.replace('Internal', '') === 'ChargeState') {
            if (property.Value == PartyChargeState.Charged || property.Value == PartyChargeState.Ignored) {
              this.ss.partyGuestCharges.next(this.ss.partyGuestCharges.value.filter(party => party.Id != objectChange.ObjectId));
            }
            else if (property.Value == PartyChargeState.ChargeFailed) {
              let partyList = this.ss.partyGuestCharges.value.filter(party => party.Id == objectChange.ObjectId);
              if (partyList && partyList.length) {
                partyList[0].ChargeFailedVisibility = true;
              }
            }
          }
          else if((property.PropertyName.replace('Internal', '') === 'PrepaymentState') && (property.Value == PartyPrepaymentState.Prepaid)){
            this.partyService.isPaymentSuccessfull$.next(objectChange.ObjectId);
        }
        });
        this.refreshPartyData(partiesList, IsStandbyParty, propertyId);
      }
      if (objectChange.Action === ChangeAction.Removed) {
        if(this.as.propertyNotifications[party.RestaurantId] !== null && this.as.propertyNotifications[party.RestaurantId] !== undefined) 
        this.as.propertyNotifications[party.RestaurantId] = true;
        this.as.removedWalkIn.next(objectChange.ObjectId);
        this.stateService.removePartyFromStateIfExisting(objectChange.ObjectId, propertyId);
        partiesList = partiesList.filter(party => party.Id !== objectChange.ObjectId);
        this.refreshPartyData(partiesList, IsStandbyParty, propertyId);
      }
    }

  }

  handlePartyDateChange(partyData, objectChange){
    objectChange.PropertyChanges.forEach(property => {
      const isTypeObject = isObject(partyData[property.PropertyName.replace('Internal', '')]);
      this.mapMissedProperties(property, partyData);
      if (Object.getOwnPropertyNames(partyData).includes(property.PropertyName.replace('Internal', ''))) {
        partyData[property.PropertyName.replace('Internal', '')] = isTypeObject ? JSON.parse(property.Value) : property.Value;
      }
      if (property.PropertyName === 'PackageDetails') {
        partyData.PackageDiscountAmount = JSON.parse(property.Value)?.DiscountedAmount;
      }
    });

    this.partyService.RemovedParty$.next([partyData]);

  }

  checkIfPartyIsRemoved(objectChange: ObjectChange) {
    let stateValue: number;
    let showCancelledReservation =  Utilities.controlValidate(controlSettings.Booking_Popup_Show_CancelledReservation, this.as.PropertyType);
    objectChange.PropertyChanges.forEach(property => {
      if (property.PropertyName.replace('Internal', '') == 'State') {
        stateValue = property.Value;
      }
    });
    //!showCancelledReservation && 
    if (stateValue && (stateValue === PartyState.Cancelled) && (this.as.selectedMenuId != Menu.Reservation && this.as.selectedMenuId != Menu.GuestBook)) {
      return true;
    }
    return false;
  }

  checkIfChangeAffectsTableAvailablity(objectChange: ObjectChange) {
    let stateValue: number;
    objectChange.PropertyChanges.forEach(property => {
      if (property.PropertyName.replace('Internal', '') == 'State') {
        stateValue = property.Value;
      }
    });
    if (stateValue && ((stateValue === PartyState.Seated) || (stateValue === PartyState.Left) || (stateValue === PartyState.Blocked) || (stateValue === PartyState.Cancelled))) {
      return true;
    }
    return false;
  }

  checkIfShiftTimeIsChanged(objectChange: ObjectChange) {
    objectChange.PropertyChanges.every(property => {
      if (property.PropertyName.replace('Internal', '') == 'SeatingTime') {
        let orginalDateTime = property.OriginalValue.toString();
        let changedDateTime = property.Value;
        return orginalDateTime === changedDateTime ? false : true;
      }
    });
    return false;
  }



  mapMissedProperties(property: PropertyChange, partyDetails: any) {
    if(!partyDetails.oldObj){
      partyDetails.oldObj = {};
    }
    if (property.PropertyName.includes('Internal')) {
      property.PropertyName = property.PropertyName.replace('Internal', '');
    }
    if (property.PropertyName === 'CachedTables') {
      partyDetails.TableIds = JSON.parse(property.Value);
      partyDetails.oldObj.TableIds = JSON.parse(property.OriginalValue);
    }
    if (property.PropertyName === 'DepartureTimeBeforeSeating') {
      partyDetails.EstimatedDepartureTime = property.Value;
      partyDetails.oldObj.EstimatedDepartureTime = property.OriginalValue;
    }
    if (property.PropertyName === 'DateTime') {
      if (partyDetails.Type == PartyType.StandBy) {
        partyDetails.WishedTime = property.Value;
        partyDetails.oldObj.WishedTime = property.OriginalValue;
      }
      else {
        partyDetails.ReservedFor = property.Value;
        partyDetails.oldObj.ReservedFor = property.OriginalValue;
      }
    }
    if (property.PropertyName === 'NoShowedAt') {
      partyDetails.NoShowedAt = property.Value;
      partyDetails.oldObj.NoShowedAt = property.OriginalValue;
    }
    if (property.PropertyName === 'DepositPaid') {
      partyDetails.DepositPaid = property.Value;
      partyDetails.oldObj.DepositPaid = property.OriginalValue;
    }
    if (property.PropertyName === 'StartDate') {
      partyDetails.StartDate = property.Value;
      partyDetails.oldObj.StartDate = property.OriginalValue;
    }
    if (property.PropertyName === 'EndDate') {
      partyDetails.EndDate = property.Value;
      partyDetails.oldObj.EndDate = property.OriginalValue;
    }
    if (property.PropertyName === 'SessionGroupId') {
      partyDetails.SessionGroupId = property.Value;
      partyDetails.oldObj.SessionGroupId = property.OriginalValue;
    }
    if (property.PropertyName === 'StatusId') {
      partyDetails.StatusId = property.Value;
      partyDetails.oldObj.StatusId = property.OriginalValue;
    }
    if (property.PropertyName === 'ConciergeId') {
      partyDetails.ConciergeId = property.Value;
      partyDetails.oldObj.ConciergeId = property.OriginalValue;
    }
    if (property.PropertyName === 'HotelId') {
      partyDetails.HotelId = property.Value;
      partyDetails.oldObj.HotelId = property.OriginalValue;
    }
    if (property.PropertyName === 'ChargedAt') {
      partyDetails.ChargedAt = property.Value;
      partyDetails.oldObj.ChargedAt = property.OriginalValue;
    }
    if (property.PropertyName === 'PagerNumber') {
      partyDetails.PagerNumber = property.Value;
      partyDetails.oldObj.PagerNumber = property.OriginalValue;
    }
    if (property.PropertyName === 'HostId') {
      partyDetails.HostId = property.Value;
      partyDetails.oldObj.HostId = property.OriginalValue;
    }
    if(property.PropertyName == 'CreditCardId'){
      partyDetails.CreditCardId = property.Value;
      partyDetails.oldObj.CreditCardId = property.OriginalValue;
    }
    if (partyDetails.LastPagedAt == undefined && property.PropertyName === 'LastPagedAt') {
      partyDetails.LastPagedAt = property.Value;
      partyDetails.oldObj.LastPagedAt = property.OriginalValue;
    }
    if (property.PropertyName === 'SeatingTime') {
      partyDetails.oldObj.SeatingTime = property.OriginalValue;
    }
    if (property.PropertyName === 'DepartureTime') {
      partyDetails.oldObj.DepartureTime = property.OriginalValue;
    }
    if(property.PropertyName  === 'SalesContactIds') {
      partyDetails.oldObj.SalesContactIds = property.OriginalValue;
    }
    else if(property.PropertyName  == 'CancellationFeeAmount'){
      partyDetails.CancellationFeeAmount = property.Value;
      partyDetails.oldObj.CancellationFeeAmount = property.OriginalValue;
    }
    else if(property.PropertyName == 'RoomNumber') {
      partyDetails.RoomNumber = property.Value;
      partyDetails.oldObj.RoomNumber = property.OriginalValue;
    }
    else if(property.PropertyName == 'CommonPackageInfo') {
      partyDetails.CommonPackageInfo = property.Value;
      partyDetails.oldObj.CommonPackageInfo = property.OriginalValue;
    }
    else if(property.PropertyName == 'ServerId') {
      partyDetails.ServerId = property.Value;
      partyDetails.oldObj.ServerId = property.OriginalValue;
    }else if(property.PropertyName == 'NegotiatedAmount'){
      partyDetails.NegotiatedAmount = property.Value;
      partyDetails.oldObj.NegotiatedAmount = property.OriginalValue;
    }
    else if(property.PropertyName == 'EmailAddress2'){
      partyDetails.EmailAddress2 = property.Value;
      partyDetails.oldObj.EmailAddress2 = property.OriginalValue;
    }
    else if(property.PropertyName == 'PhoneNumber2'){
      partyDetails.PhoneNumber2 = property.Value;
      partyDetails.oldObj.PhoneNumber2 = property.OriginalValue;
    }
  }

  loadParty(partyId: number) {
    return this.partyService.getPartyInfo(partyId);
  }

  //Parties Update For Contacts
  //PartyCreate 
  createPartyForContact(newParty) {
    let currentPartiesForContact = this.partyService.SelectedContactParties$.value?.length ? this.partyService.SelectedContactParties$.value : [];
    currentPartiesForContact.push(newParty);
    this.refreshPartiesForContact(currentPartiesForContact);
  }

  updatePartyForContact(isPartyRemoved, objectChange) {
    let currentPartiesForContact = this.partyService.SelectedContactParties$.value?.length ? this.partyService.SelectedContactParties$.value : [];
    if (currentPartiesForContact.length) {
      if (!isPartyRemoved) {
        const partyDetails = currentPartiesForContact.find((party) => party.Id === objectChange.ObjectId);
        if (partyDetails) {
          currentPartiesForContact.splice(currentPartiesForContact.findIndex(p => p.Id == objectChange.ObjectId), 1);
          objectChange.PropertyChanges.forEach(property => {
            const isTypeObject = isObject(partyDetails[property.PropertyName.replace('Internal', '')]);
            this.mapMissedProperties(property, partyDetails);
            if (Object.getOwnPropertyNames(partyDetails).includes(property.PropertyName.replace('Internal', ''))) {
              partyDetails[property.PropertyName.replace('Internal', '')] = isTypeObject ? JSON.parse(property.Value) : property.Value;
            }
          });
          currentPartiesForContact.push(partyDetails);
        }
      }
      else {
        currentPartiesForContact = currentPartiesForContact.filter((party) => party.Id !== objectChange.ObjectId);
      }
    }
    this.refreshPartiesForContact(currentPartiesForContact);
  }

  removePartyForContact(partyIdToBeRemoved) {
    let currentPartiesForContact = this.partyService.SelectedContactParties$.value?.length ? this.partyService.SelectedContactParties$.value : [];
    currentPartiesForContact = currentPartiesForContact.filter((party) => party.Id !== partyIdToBeRemoved);
    this.refreshPartiesForContact(currentPartiesForContact);
  }

  refreshPartiesForContact(newPartiesList) {
    newPartiesList?.length ? this.partyService.SelectedContactParties$.next(newPartiesList)
      : this.partyService.SelectedContactParties$.next([]);
  }

  refreshPartyData(partiesList: any[], isStandbyParty: boolean, propertyId) {
    partiesList = this.partiesMergeById(partiesList);
    if (propertyId == Utilities.RestaurantId()) {
      if (isStandbyParty) {
        this.partyService.StandbyParties$.next(partiesList);
        this.partyService.standbyPartiesList = partiesList;
        if(this.as._cs.propertySettings.value[propertyId])
        this.as._cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      }
      else {
        this.partyService.Parties$.next(partiesList);
        this.partyService.partiesList = partiesList;
        if(this.as._cs.propertySettings.value[propertyId]){
          this.as._cs.propertySettings.value[propertyId].reservations = partiesList;
        }
        
      }
    } else {
      if (isStandbyParty) {
        if(this.as._cs.propertySettings.value[propertyId])
        this.as._cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      } else {
        if(this.as._cs.propertySettings.value[propertyId])
        this.as._cs.propertySettings.value[propertyId].reservations = partiesList;
      }
    }
  }

  CheckIsReseatUpdate(partiesList, change: ObjectChange, propertyId) {
    let objType = change.PropertyChanges.filter(property => property.PropertyName.replace('Internal', '') == 'State');
    if (objType && objType.length > 0) {
      if (objType[0].Value == PartyState.Seated && objType[0].OriginalValue == PartyState.Left) {
        if (propertyId == Utilities.RestaurantId() && this.partyService.RecentlyLeftPartiesList && this.partyService.RecentlyLeftPartiesList.length > 0) {
          this.partyService.RecentlyLeftPartiesList.forEach(party => {
            if (party.Id == change.ObjectId) {
              if (partiesList.findIndex(party => party.Id == change.ObjectId) == -1) {
                partiesList.push(party);
              }
            }
          });
        } else {
          let recentlyLeftParties = this.as._cs.propertySettings.value[propertyId].state.RecentlyLeftParties;
          if (recentlyLeftParties.length) {
            recentlyLeftParties.forEach(party => {
              if (party.Id == change.ObjectId && partiesList.findIndex(party => party.Id == change.ObjectId) == -1) {
                partiesList.push(party);
              }
            })
          }
        }
      }
    }
  }

  checkStandby(change: ObjectChange, propertyId) {
    let objType = change.PropertyChanges.filter(property => property.PropertyName.replace('Internal', '') == 'Type');
    let partyDetail = null;
    if (propertyId == Utilities.RestaurantId()) {
      partyDetail = this.partyService.StandbyParties$.value.find((party) => party.Id === change.ObjectId);
    } else {
      partyDetail = this.as._cs.propertySettings.value[propertyId]?.StandbyParties?.filter((party) => party.Id === change.ObjectId);
    }
    if (objType.length > 0) {
      return objType[0].Value == PartyType.StandBy;
    }
    else if (partyDetail && partyDetail.Type == PartyType.StandBy) {
      return true;
    }
    return false;
  }

  isReservationFallsOnHeaderDate(reservationDate, startDate = null, endDate = null, propertyId) {
    if (this.as._cs.propertySettings.value[propertyId].settings.PropertySetting[0].SlottingType == SlottingType.NonDining && startDate && endDate) {
      let weekStartDate = new Date(new Date(this.partyService.weekStartDate).setHours(0, 0, 0, 0));
      let weekEndDate = new Date(new Date(this.partyService.weekEndDate).setHours(0, 0, 0, 0));
      startDate = new Date(new Date(startDate).setHours(0, 0, 0, 0));
      endDate = new Date(new Date(endDate).setHours(0, 0, 0, 0));
      return Utilities.datetimeBetween(weekStartDate, weekEndDate, startDate) || Utilities.datetimeBetween(weekStartDate, weekEndDate, endDate)
        || Utilities.datetimeBetween(startDate, endDate, weekStartDate);
    }

    const headerDate = this.as._cs.headerDate;
    const isReservationDateToday = Utilities.diffBetweenDates(reservationDate, headerDate) === 0;
    const isStartDateMatch = startDate && Utilities.diffBetweenDates(startDate, headerDate) === 0;
    const isEndDateMatch = endDate && Utilities.diffBetweenDates(endDate, headerDate) === 0;
    
    if (isReservationDateToday || isStartDateMatch || isEndDateMatch) {
      return true;
    }
    
    let isPartyNeedTobeAdded = false;
    let shifts = this.as._cs.propertySettings.value[propertyId].settings.Shifts;
    let restaurantShifts = Utilities.getRestaurantShiftForDay(shifts, new Date(headerDate));
    if (restaurantShifts && restaurantShifts.length > 0) {
      restaurantShifts.forEach(shift => {
        if (Utilities.datetimeBetween(shift.EffectiveRange.Start, shift.EffectiveRange.End, reservationDate)) {
          isPartyNeedTobeAdded = true;
        }
      });
    }
    return isPartyNeedTobeAdded;
  }

  showOTANotification(isStandby: boolean) {
    if (isStandby) {
      this.toastrService.info("A New standby reservation is created through 3rd party mobile application!", "", { timeOut: 10000, closeButton: true });
    }
    else {
      this.toastrService.info("A New reservation is created through 3rd party mobile application!", "", { timeOut: 10000, closeButton: true });
    }
  }

  partiesMergeById(list): any[] {
    let newList = [];
    let groups: any[] = <any>groupBy(list, "Id");

    Object.values(groups).forEach((group: any[]) => {
      let mergedValue = group[0];

      if (mergedValue.SpecialMealId && mergedValue.BookedSessions && !mergedValue.SessionGroupId) {
        let bookedSessions = group.map(({ BookedSessions }) => BookedSessions).flat();
        mergedValue.BookedSessions = uniqBy(bookedSessions, "Id");
      }
      newList.push(mergedValue);
    });

    return newList;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
