import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { ActiveCreditCardInfo, SurchargeDetailsResponse } from '../../shared/business/shared.modals';
import { CardEntryModeDialogResult, CardInputMethod, CardEntryModeDialogAction } from '../../shared/service/payment/payment-business.model';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';

@Component({
  selector: 'app-payment-card-on-file-select',
  templateUrl: './payment-card-on-file-select.component.html',
  styleUrls: ['./payment-card-on-file-select.component.scss']
})
export class PaymentCardOnFileSelectComponent implements OnInit {
  captions: any; 
  globalCaptions: any;
  result: { action: CardEntryModeDialogAction, tokenId: number, surchargeDetail: SurchargeDetailsResponse, includeSurchage: boolean  };
  CreditCardFormGroup: UntypedFormGroup;
  defaultCard: number;
  CardOnFiles: { value: CardInputMethod; viewValue: string; }[] = [];
  ccData: ActiveCreditCardInfo[];
  IsExpireDateVisible: boolean = false;
  floatLabel: string;
  surchargeDetailList: SurchargeDetailsResponse[];
  selectedCardSurcharge: SurchargeDetailsResponse;
  includeSurchage: boolean = true;
  isSurchargeEnabled: boolean = false;
  accessUrl: string = '';
  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<PaymentCardOnFileSelectComponent>,
    private localization: RetailLocalization,
    private utils: RetailUtilities
  ) {
    this.ccData = data?.ccData;
    this.IsExpireDateVisible = data?.IsExpireDateVisible;
    this.surchargeDetailList = data?.surchargeDetail;
    this.isSurchargeEnabled = data?.isSurchargeEnabled;
    this.globalCaptions = this.localization.captions;
    this.captions = this.localization.captions.setting;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.ccData.forEach(x => {      
      const cardNumber = this.utils.MaskCreditCardDataWithCardExpDate (x.cardInfo,this.IsExpireDateVisible);
      this.CardOnFiles.push({
        value: x.paymentTransactionId,
        viewValue: cardNumber
      });
    });
    this.CardOnFiles = Array.from(new Map(this.CardOnFiles.map(item => [item.value, item])).values());
    this.defaultCard = this.ccData[0].paymentTransactionId;
    this.accessUrl = this.ccData[0]?.cardInfo?.accessUrl;
    this.CreditCardFormGroup = this.fb.group({
      CreditCardInput: this.defaultCard,
      includeSurchage: this.includeSurchage
    }); 
    if(this.isSurchargeEnabled){
      this.selectedCardSurcharge = this.surchargeDetailList.find(x=> Number(x.tokenTransactionId) == this.defaultCard)
    }    
  }  
  onSave() {
    this.result = {
      action: CardEntryModeDialogAction.Proceed,
      tokenId: this.CreditCardFormGroup.get('CreditCardInput').value,
      surchargeDetail: this.selectedCardSurcharge,
      includeSurchage: this.includeSurchage
    };
    this.dialog.close(this.result);
  }
  onIncludeSurchageSelect(eve){
    this.includeSurchage = eve.checked ? true : false;
  }
  CreditCardChange(eve){
    this.CreditCardFormGroup.controls['CreditCardInput'].setValue(eve.value); 
    this.accessUrl = this.ccData.find(x => x.paymentTransactionId = eve?.value)?.cardInfo?.accessUrl;
    if(this.isSurchargeEnabled){
      const selectedCardSurcharge = this.surchargeDetailList.find(x=>x.tokenTransactionId == eve.value);
      if(JSON. stringify(selectedCardSurcharge) === '{}') {
        this.selectedCardSurcharge = {} as SurchargeDetailsResponse;
        return;
      }
      this.selectedCardSurcharge = this.surchargeDetailList.find(x=>x.tokenTransactionId == eve.value);
    }    
  }

  onCancel() {
    this.result = {
      action: CardEntryModeDialogAction.Cancel,
      tokenId: 0,
      surchargeDetail: {} as SurchargeDetailsResponse,
      includeSurchage: false
    };
    this.dialog.close(this.result);
  }
  
}
