import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'; 
import { Host, GridType, RetailBreakPoint } from '../shared/globalsContant';
import { RetailPopupComponent } from '../retail-popup/retail-popup.component';
import { HttpServiceCall, HttpMethod } from '../shared/service/http-call.service';
import { BaseResponse, CommissionTemplate, CommissionTemplateSplit } from '../shared/business/shared.modals';
import { RetailLocalization } from '../common/localization/retail-localization';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { Subscription } from 'rxjs';
import { CommonVariablesService } from '../shared/service/common-variables.service';


@Component({
  selector: 'app-retail-commission-template',
  templateUrl: './retail-commission-setup.component.html',
  styleUrls: ['./retail-commission-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailCommissionTemplateComponent implements OnInit, OnDestroy {
  tableoptions: any[];
  captions: any;
  IsViewOnly: boolean;
  type: any;
  dialogSubscription: Subscription;
  editDialogSubscription: Subscription;
  constructor(private retailService: RetailSetupService, public dialog: MatDialog,
     private http: HttpServiceCall, public localization: RetailLocalization, private Utilities: RetailUtilities, private shopService : CommonVariablesService) {

  }

  ngOnInit() {
    this.captions = this.localization.captions.retailsetup;
    this.InvokeServiceCall("GetCommissionTemplate", HttpMethod.Get, { productId: this.shopService.ProductId });
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(bp => bp.breakPointNumber == RetailBreakPoint.SplitCommissionTemplateSetup).view;
    this.retailService.tabLoaderEnable.next(false);
  }

  ngOnDestroy() {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
    if (this.editDialogSubscription) {
      this.editDialogSubscription.unsubscribe();
    }
  }

  openDialog = (type: any) => {
    let title = this.captions.NewTemplate,
      templateName = 'CT';
    const dialogRef = this.dialog.open(RetailPopupComponent, {
      width: '80%',
      maxWidth: '950px',
      maxHeight: '680px',
      height: '50%',
      disableClose: true,
      hasBackdrop: true,
      data: { headername: title, closebool: true, templatename: templateName, popupConfig: { operation: type }, automationId: 'retailCommissionSetup' }, //, templatename: templateName
      panelClass: 'small-popup'
    })
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      let gridData = this.GetGridData(this.shopService.commissionTemplate);
      this.LoadGrid(gridData);
    });
  }

  EditTemplate(event: any) {
    this.Utilities.ToggleLoader(true,this.captions.lbl_processing);
    let params = { 'id': event[0].id, 'productId': this.shopService.ProductId };
    this.InvokeServiceCall('GetCommissionTemplateById', HttpMethod.Get, params);
    this.Utilities.ToggleLoader(false);
  }

  OpenEditPopup(data: any) {

    let title = this.captions.EditTemplate,
      templateName = 'CT';
    const dialogRef = this.dialog.open(RetailPopupComponent, {
      width: '80%',
      maxWidth: '950px',
      maxHeight: '680px',
      height: '70%',
      disableClose: true,
      hasBackdrop: true,
      data: { headername: title, closebool: true, templatename: templateName, datarecord: data, popupConfig: { operation: 'edit' } }, //, templatename: templateName
      panelClass: 'small-popup'
    })
    this.editDialogSubscription = dialogRef.afterClosed().subscribe(result => {
      let gridData = this.GetGridData(this.shopService.commissionTemplate);
      this.LoadGrid(gridData);
    });
  }

  DeleteRecords(event: any) {
    this.Utilities.ToggleLoader(true,this.captions.lbl_processing);
    let params = { 'id': event[0].id, productId: this.shopService.ProductId };
    this.InvokeServiceCall('DeleteCommissionTemplateById', HttpMethod.Delete, params, null, [{ 'Action': 'Delete' }]);
    this.Utilities.ToggleLoader(false);
  }

  LoadGrid(data) {
    this.tableoptions = [
      {
        TableHdrData: [{ "title": this.captions.TemplateCode, "jsonkey": "code", "searchable": true, "sortable": true },
        { "title": this.captions.TemplateName, "jsonkey": "name", "searchable": true, "sortable": true },
        { "title": this.captions.CommissionOn, "jsonkey": "commissionOn", "searchable": false, "sortable": true },
        { "title": this.captions.SplitLevels, "jsonkey": "splitLevel", "searchable": false, "sortable": true }],
        TablebodyData: data,  
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: `${this.captions.TemplateCode}, ${this.captions.TemplateName}`,
        EnableActions: true,
        SelectRows: true,
        IsCommission: true, 
        Searchable: true,
        EditMoreOption: false,
        SelectedSettingId: GridType.commissionTemplate,
        Sortable: "name",
        TableId: GridType.commissionTemplate,
        disableDelete: false,
        IsViewOnly: this.IsViewOnly,
        automationId: 'retailCommissionSetup'
      }
    ];
  }

  InvokeServiceCall(route: string, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.commission,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetCommissionTemplate" || callDesc == 'CommissionTemplate' || (callDesc == 'DeleteCommissionTemplateById' && extraParams && extraParams[0].Action == 'Delete')) {
      let response: CommissionTemplate[] = <any>result.result;
      this.shopService.commissionTemplate = response;
      let gridData = this.GetGridData(response);
      this.LoadGrid(gridData);
    }
    else if (callDesc == 'GetCommissionTemplateById') {
      let response: CommissionTemplate = <any>result.result;
      this.OpenEditPopup(response);
    }
  }

  GetGridData(input: CommissionTemplate[]): any[] {
    let gridData: any[] = [];
    if (input && input.length > 0) {
      for (let data of input) {
        let commisionOn = data.commissionClassId > 0 ? `${this.captions.CommissionClassLevel} ${data.commissionClassId}` : this.captions.Employee;
        gridData.push(
          {
            'id': data.id,
            'code': data.templateCode,
            'name': data.templateName,
            'commissionOn': commisionOn,
            'splitLevel': this.FormSplitLevel(data.splitDetail)
          });
      }
    }
    return gridData;
  }

  FormSplitLevel(level: CommissionTemplateSplit[]): string {
    let result: string = '';
    for (let i = 0; i < level.length; i++) {
      result = `${result}${i > 0 ? ', ' : ''}${level[i].levelDescription} ${this.localization.localizePercentage(level[i].percentage)}% `;
    }
    return result;
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }


}
