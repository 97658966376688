<div class="layout" module-class [posView]="_as.posView">
  
      <div class="layout__header" [hidden]="_as.posView">
        <app-header (onCartClick)="cartClick($event)"  ></app-header>
      </div>
      <div class="respos-layout__header-container" respos-layout-header [hidden]="!_as.posView"></div>

      <app-aside [hidden]="_as.posView"></app-aside>
      

    <section class="layout__template-container" [class.w100]="_as.posView" *ngIf="_as.isHeaderLoaded  && !_as.showHelpScreen" [hidden]="_as.showCreateActivity"
    [ngClass]="{'layout__full-view':_as.hideSideMenu}">
    <section class="layout__cart-container showcart" *ngIf="ps.viewBookings" [ngClass]="{'layout__full-view':_as.hideSideMenu}">
      <app-booking-manager *ngIf="_as.sessionDataFilter" [filterData]="_as.sessionDataFilter"></app-booking-manager>
    </section>
    <div [class.showcart]="_as.showCartItem" class="layout__cart-container">
      <app-cart-items-display [showCartItem]="_as.showCartItem" [count]="ps.cartCount" [cartUpdated]="ps.isCartUpdated"></app-cart-items-display>
    </div>
    <router-outlet #o="outlet"></router-outlet>
  </section>
    <section class="layout__template-container" *ngIf="_as.showHelpScreen" [ngClass]="{'layout__full-view':_as.hideSideMenu}">
      <app-help></app-help>
    </section>
    <section class="layout__template-container" *ngIf="_as.showCreateActivity" [ngClass]="{'layout__full-view':_as.hideSideMenu}">
      <app-basic-setup  [activityData]=" _as.createActivitySettings" [isEdit]="false" [isTemplate]="false"></app-basic-setup>
    </section>
    
</div>

<!-- 
<div class="respos-layout">
    <div class="respos-layout__header-container" respos-layout-header></div>
    <div class="respos-layout__router-container respos-box-2">
        <router-outlet></router-outlet>
    </div>
</div> -->