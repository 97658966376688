<div class="application-information" *ngIf="productInfo" style="padding-left: 10px;">

    <div style="display: flex;gap: 10%;">
      <div>

        <img alt="logo" width="20%" style="padding: 15px 0px;" class="logo_image" src="assets/images/Agilysys_Reserve_Logo.png">

        <h5>Agilysys Reserve - current Version {{productInfo.CurrentVersion}}</h5>
        <p>Copyright © 2020-2024 by Agilysys NV, LLC.All rights reserved.</p>
        
      </div>
      <!-- <div style="    margin-top: 5%;">
        <p>Licence Status : Active</p>
        <p>Expire Date :  {{productInfo.LicenceStatus.ExpiryDate}}</p>
      </div> -->
    </div>
    
    <div style="padding-top: 5px;display: flex;">
      <div style="display: flex;flex-direction: column;">
        <div class="installed-product" style="min-width: 80%">
          <p>Integrated products:</p>
          <ul
            style="max-height: 200px; overflow-y: auto; padding: 0; margin: 0; list-style: none; border: 1px solid gray; color: gray;">
            <li *ngFor="let product of productInfo.CertifiedIntegratedProducts;"
              style="padding-left: 5px; transition: background-color 0.3s;    padding-bottom: 3px;"
              [ngClass]="{'selected': selectedProduct === product}"
              (click)="selectProduct(product)">
              <p> {{ product.ProductName }} <span *ngIf="product?.Version" >- {{ product.Version }} </span></p>
            </li>
          </ul>
        </div>
        <div style="padding-top: 10px;" >
          <span><strong>Warning</strong> This software is protected by copyright laws and international agreements. Any unauthorized copying or distribution of this program, in whole or in part, may lead to significant civil and criminal consequences and will be pursued to the fullest extent permitted by law.</span>
        </div>
      </div>
     
      <div style="    display: flex;flex-direction: column;justify-content: space-between; padding: 26px 7px 0px;">
        <div style="display: flex;flex-direction: column; gap: 7px;">
          <activities-app-button [buttontype]="externalHelpButton" (valueChange)='openExternalHelp()'></activities-app-button>
          <activities-app-button [buttontype]="downloadReleaseNotesButton" (valueChange)='downloadReleaseNotes()'></activities-app-button>
        </div>
        <div>
          <activities-app-button  [buttontype]="closeButton" (valueChange)='closePopup()'></activities-app-button>
        </div>
      </div>
    </div>
    
  </div>