<div class="form-date-picker {{config.containerClass}}">
 <a [ngClass]="{'link-disabled':config.disabled}"  *ngIf="config.showNavigation" href="javascript:void(0)" class="left-arrow" (click)="navigate($event,0)"><i class="icon-Path-490 left-navigation"></i></a>
 <mat-label *ngIf="!config.showNavigation" class="select-label">{{ config.label | translate}}</mat-label>
 <mat-form-field *ngIf="!enableClearDate" class="basic-date-picker {{ config.class}}" [hideRequiredMarker]="!config.isStarSymbolRequired" [ngClass]="{'input-with-boundary':config.appearance, 'header-dayname': showDayName}"  [formGroup]="group" [appearance]="!config.showNavigation ? 'outline' : ''">
        <!-- <mat-label class="select-label">{{ config.label }}</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="{{config.placeholder}}" (focus)="picker.open()"  (click)="picker.open()" readonly [formControlName]="config.name"  [attr.name]="config.name" [max]="config.maxDate">
         <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker> -->

                <p *ngIf="showDayName && config.showNavigation" class="dayname-text" (click)="!config.isReadOnly && picker.open()">{{selectedDate | weekDayPipe}},</p>
                <input [disabled]="config.disabled" [ngClass]="{'link-disabled':config.disabled, 'input-width': showDayName }" matInput [matDatepicker]="picker"  (click)="!config.isReadOnly && picker.open()" readonly [formControlName]="config.name"  [attr.name]="config.name" [min]="config.minDate" [max]="config.maxDate">
                <mat-datepicker-toggle class="seat-icon-color" matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

        <mat-error *ngIf="group.controls[FormControlName].hasError('required') && group.controls[FormControlName].touched && config.showErrorText">
                {{'fieldRequired' | translate}}
        </mat-error>
        <mat-error *ngIf="group.controls[FormControlName].hasError('matDatepickerMax')">
                {{'matDatepickerMaxErrorText' | translate: {date: group.controls[FormControlName].errors.matDatepickerMax.max | formatSettingsDate:
                        cs.settings.value.General.DateFormat} }}
        </mat-error>
        
</mat-form-field>
<!-- <mat-label *ngIf="!config.showNavigation" class="select-label">{{ config.label | translate}}</mat-label> -->
<mat-form-field *ngIf="enableClearDate" class="basic-date-picker {{ config.class}}" [hideRequiredMarker]="!config.isStarSymbolRequired" [ngClass]="{'input-with-boundary':config.appearance}"  [formGroup]="group" appearance="outline">
        <!-- <mat-label class="select-label">{{ config.label }}</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="{{config.placeholder}}" (focus)="picker.open()"  (click)="picker.open()" readonly [formControlName]="config.name"  [attr.name]="config.name" [max]="config.maxDate">
         <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker> -->

                <input [disabled]="config.disabled" [ngClass]="{'link-disabled':config.disabled}" matInput [matDatepicker]="picker"  (click)="!config.isReadOnly && picker.open()" readonly [formControlName]="config.name"  [attr.name]="config.name" [min]="config.minDate" [max]="config.maxDate">
                <mat-datepicker-toggle *ngIf="!selectedDate" class="seat-icon-color" matSuffix [for]="picker"></mat-datepicker-toggle>
                <i *ngIf="selectedDate" class="icon-close close-date" (click)="clearDateSelection($event)"></i>
                <mat-datepicker #picker></mat-datepicker>

        <mat-error *ngIf="group.controls[FormControlName].hasError('required') && group.controls[FormControlName].touched && config.showErrorText">
                {{'fieldRequired' | translate}}
        </mat-error>
</mat-form-field>

<a [ngClass]="{'link-disabled':config.disabled}" *ngIf="config.showNavigation" href="javascript:void(0)" (click)="navigate($event,1)" class="right-arrow"><i class="icon-left-arrow1 icon_center right-navigation"></i></a>
</div>
