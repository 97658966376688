import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { SelectOption } from '../interfaces/form';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export class InputField{


    @Input() control: any = new FormControl();
    @Input() secondaryControl?: FormControl = new FormControl();
    @Input() showSecondaryControl?: boolean = false;
    @Input() label: string = '';
    @Input() placeholder: string = '';
    @Input() type: string = 'text';
    @Input() isRequired?: boolean = false;
    @Input() suffixIcon?: string = '';
    @Input() directive?: string = '';
    @Input() disabled?: boolean = false;
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() blurEvent?: EventEmitter<any> = new EventEmitter();

    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();


    selectedOption: string = '';

    constructor(protected ts: TranslateService) { }

    changeSecondaryControl(name: string, value: string) {
        if (this.secondaryControl) {
            this.selectedOption = name;
            this.secondaryControl.setValue(value);
        }
    }

    noActionTrigger(event: Event){
        event.preventDefault();
        event.stopPropagation();
    }
}