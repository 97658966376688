<div class="activity-details">
    <section class="activity-details__input-data">
        <div for="" class="seat-panel-header">{{'activityDetails' | translate}}</div>
        <div>
            <dynamic-form [config]="eventConfig"></dynamic-form>
            <div class="seat-panel-header" for="">{{'chooseDate' | translate}}</div>
            <dynamic-form class="activity-details__date-form" [config]="dateConfig"></dynamic-form>
            <div class="seat-panel-header" for="">{{'coverSize' | translate}}</div>
            <dynamic-form [config]="coverConfig"></dynamic-form>
            <div class="seat-panel-header" for="">{{'coverType' | translate}}</div>
            <dynamic-form [config]="coverTypeConfig"></dynamic-form>
            <div class="seat-panel-header" for="">{{'membershipDetails' | translate}}</div>
            <dynamic-form [config]="membershipConfig"></dynamic-form>
        </div>
    </section>
    <section class="activity-details__time-slot">

    </section>

</div>