import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { UserAccessBusiness } from '@app/common/dataservices/authentication/useraccess.business';
import { Localization } from '@app/common/localization/localization';
import { UserdefaultsInformationService } from '@app/common/services/userdefaults-information.service';
import { PackagedItem } from '@app/common/shared/retail.modals';
import { ScheduledTeeTimeUnPaidPlayer, SearchType } from '@app/common/shared/shared.modal';
import { PlayerInformationService } from '@app/common/shared/shared/service/player.information.service';
import { RouteLoaderService } from '@app/core/services/route-loader.service';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { ClientCreditCardInfo, PayeeInfo, PaymentMethods } from '@app/retail/shared/business/shared.modals';
import { ServiceChargeGratuityPercentId, ServiceChargeGratuityValueType, THERAPIST, USER } from '@app/retail/shared/globalsContant';
import { RetailSharedVariableService } from '@app/retail/shared/retail.shared.variable.service';
import { PlayerTransactionStatus, TeeTimeConfig } from '@app/retail/shared/shared.modal';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { BookingBehavior, ComponentTypes, FinancialEffectType, OperationResultState, OperationType, Operations, PartyType, PaymentGateways, PaymentStates, ReservationEmailNotificationType } from './constants/commonenums';
import { GuestBookService } from './services/guestbook.service';
import { PartyService } from './services/party.service';
import { Utilities } from './utilities/utilities';
import { PopupService } from '@popup-module/popup.service';
import { urlConfig } from './constants/url-config';
import { seatRetailService } from './services/seatretail.service';
import { ISubscription } from 'rxjs-compat/Subscription';
import { ItemType, PartyEmailSendBehavior, SettingType, SupportedReservationEmailConfirmationTypes, TaxType } from './models/RestaurantDTO';
import { CheckinCheckOutBehaviour } from './models/InputContact';
import { OrderetailDTO, OrderItemDTO, OrderType, RetailTranactionDetailList, RetailTransactionType } from './services/retail/activities-retail-common';
import { RetailService } from '@app/retail/retail.service';
import { PlayerCategory } from '@app/common/enums/shared-enums';
import { TenderType } from '@app/retail/payment-manager/payment-manager.model';
import { DEFAULT_LANGUAGE_ID } from './constants/globalConstants';
import { elementAt } from 'rxjs/operators';
import { CacheService } from '@core/services/cache.service';
import { CommonVariablesService } from '@app/retail/shared/service/common-variables.service';



@Injectable({ providedIn: 'root' })
export class RetailBussinessService {
	private captions: any;
	PackageGroupId: number = 0;
	componentDetails: ComponentDetails;
	tempPackageItems = new Map<number, PackagedItem[]>();
	transactionInfo: any;
	constructor(
		//private _paymentBusinessService: PaymentBusinessService,
		private _retailService: RetailSharedVariableService,
		//private _golfAuthentication: AuthenticationCommunication,
		//private _golfSchedule: GolfScheduleCommunication,
		//private _retailmanagementCommunication: RetailmanagementCommunication,
		//private _utils: GolfUtilities,
		//private localization: GolfLocalization,
		private router: Router,
		private dialog: MatDialog,
		private userDefaultService: UserdefaultsInformationService,
		public _userAccessBusiness: UserAccessBusiness,
		//private propertyInfo: GolfPropertyInformation,
		//private _agreementBusiness: AgreementBusinessService,
		//private _teeTicketBusiness: TeeTicketBusiness,
		//private _playerDataService: PlayerDataService,
		//private _unPaidPlayerBusiness: UnPaidPlayersBusiness,
		//private _golfUserDataService: GolfUserConfigDataService,
		//private _redeemRainCheckService: RedeemRainCheckService,
		//private _negotiatedFeeDataService: NegotiatedFeeDataService,
		//private _acespaymentservice: ACESPaymentService,
		private _routeLoaderService: RouteLoaderService,
		//private _playerProfileDataService: PlayerProfileDataService,
		//private _sharedService: SharedService,
		private _playerService: PlayerInformationService,
		//private settingsDataService: SettingsDataService,
		//private _userDataService: UserDataService,
		//private _retailUtils: RetailUtilities,
		private localization: Localization,
		private party: PartyService,
		private guestbook: GuestBookService,
		public _as: AppService,
		public cs: CacheService,
		private popupService: PopupService,
		private ts: TranslateService,
		private _seatRetail: seatRetailService,
		private retailService: RetailService,
		public shopService: CommonVariablesService
		//private _rateTypeDataService: RateTypeDataService
	) {
		this.captions = this.localization.captions.shop;
	}

	async GetPartyById(guestid: number): Promise<PayeeInfo> {
		let guestInfo = await this.party.GetRetailGuestInfo([guestid]).toPromise();;
		if (guestInfo && guestInfo.Payload && guestInfo.Payload.length > 0) {
			let contact = guestInfo.Payload[0];
			const financialEffect = this.party.ReservationAttemptData?.RetailCreditCardPaymentTokenId;
			if (financialEffect) {
				contact.paymentReferenceId = financialEffect;
			}
			return this.MapToPayeeInfo(contact);
		}
	}

	async getActivitiesGuestInfo(guestids): Promise<PayeeInfo[]> {
		let guestInfo = await this.party.GetRetailGuestInfo(guestids).toPromise();
		let payeeInfo: PayeeInfo[] = [];
		if (guestInfo.Payload) {
			guestInfo.Payload.map((x) => {
				x.playerType = Number.parseInt(x.playerType) || 0;
				x.rateType = Number.parseInt(x.rateType) || 0;
				payeeInfo.push(this.MapToPayeeInfo(x));
			});
		}

		return payeeInfo;
	}

	async GetMemberInfo(cardNo: string, date: string): Promise<PayeeInfo> {
		/* let memberInfo: Tournament.UI.ContactInformation = await this._playerDataService.getMemberInfo(cardNo, date);
		if (memberInfo && memberInfo.cardStatus.toUpperCase() != 'ACTIVE') {
			this._retailUtils.showAlert(this.localization.captions.common.InActiveMember, AlertType.Info, ButtonType.Ok);
			return null;
		} else return this.MapToPayeeInfo(memberInfo); */
		return null;
	}

	MapToPayeeInfo(GuestDetail: any): PayeeInfo {
		let payeeInfo: PayeeInfo = null;
		if (GuestDetail) {
			let firstName = GuestDetail.firstName ? GuestDetail.firstName : GuestDetail.FirstName ? GuestDetail.FirstName : '';
			let lastName = GuestDetail.lastName ? GuestDetail.lastName : GuestDetail.LastName ? GuestDetail.LastName : ''
			payeeInfo = {
				name: `${firstName} ${lastName}`,
				address:
					this.ReplaceEmptyIfNull(GuestDetail.playerAddress, 'addressLine1') +
					' ' +
					this.ReplaceEmptyIfNull(GuestDetail.playerAddress, 'state'),
				country: this.ReplaceEmptyIfNull(GuestDetail.playerAddress, 'country'),
				zip: this.ReplaceEmptyIfNull(GuestDetail.playerAddress, 'zip'),
				city: this.ReplaceEmptyIfNull(GuestDetail.playerAddress, 'city'),
				guestProfileId: GuestDetail.TrackMembershipNumber ? GuestDetail.TrackMembershipNumber : (GuestDetail.guestId ? GuestDetail.guestId : (GuestDetail.playerLinkId)),
				cardInfo: [],
				id: GuestDetail.Id ? GuestDetail.Id : GuestDetail.id,
				balanceList: GuestDetail.balanceList,
				playerCategoryId: GuestDetail.TrackMembershipNumber ? PlayerCategory.Member : GuestDetail.playerCategoryId,
				golfPoints: GuestDetail.golfPoints,
				patronId:
					GuestDetail.loyaltyDetail && GuestDetail.loyaltyDetail.length > 0
						? GuestDetail.loyaltyDetail[0].patronId
						: '',
				rank:
					GuestDetail.loyaltyDetail && GuestDetail.loyaltyDetail.length > 0
						? GuestDetail.loyaltyDetail[0].patronId
						: '',
				lastName: lastName,
				firstName: firstName,
				RetailTicketNumber: GuestDetail.RetailTicketNumber,
				RetailTransactionId: GuestDetail.RetailTransactionId,
				TransactionDetailId: GuestDetail.TransactionDetailId ? GuestDetail.TransactionDetailId : null,
				IsActivitesInvolved: GuestDetail.IsActivitesInvolved,
				MembershipInvolved : GuestDetail.TrackMembershipNumber ?  true : false,
			};
			let cardInfo: ClientCreditCardInfo = null;
			if (GuestDetail.paymentReferenceId > 0) {  
				cardInfo = {
					clientId: GuestDetail.id,
					isActive: true,
					tokenTransId: GuestDetail.paymentReferenceId
				};
				if(this.party?.MultipleReservationAttemptData?.RetailCreditCardPaymentTokenId && this.party?.MultipleReservationAttemptData?.RetailCreditCardPaymentTokenId > 0){
					cardInfo.tokenTransId = this.party.MultipleReservationAttemptData.RetailCreditCardPaymentTokenId;
				}
				payeeInfo.cardInfo.push(cardInfo);
			}

			if(payeeInfo.playerCategoryId == PlayerCategory.Member) {
				this.shopService.memberCardNumber = payeeInfo.guestProfileId;
			}

			if (payeeInfo.guestProfileId?.trim())
				this.retailService.selectedClient = 1;
			else
				this.retailService.selectedClient = 2;

		}
		return payeeInfo;
	}

	private ReplaceEmptyIfNull(obj: object, key: string) {
		let hasObjKey = (obj && obj[key] ? obj[key] : '');
		return obj ? hasObjKey : '';
	}

	async searchGuest(Name, type: number): Promise<PayeeInfo[]> {
			type = type == 1 ? SearchType.Member : SearchType.GuestAndPlayer;
			let guestInfo: any = [];
			let payeeInfo: PayeeInfo[] = [];
			if (Name && Name.trim().length >= 2) {
				let guestInfo = await this.guestbook.getContactSuggestion(Name, false).toPromise();;
				guestInfo.map((x) => {
					x.playerType = Number.parseInt(x.playerType) || 0;
					x.rateType = Number.parseInt(x.rateType) || 0;
					payeeInfo.push(this.MapToPayeeInfo(x));
				});
			}
			return [guestInfo, payeeInfo];
	}

	async ProcessPartyTransaction(transactionInfos,from): Promise<any> {
		transactionInfos['from'] = from;
		let transactionInfo = transactionInfos.CreateRetailItemResponse;
		//transactionInfos._shopservice.selectedTransaction
		let transactions = this.processTransactions(transactionInfo, transactionInfos, this.party.ReservationAttemptData);
		let partyid = null;
		
		if (this.party.Operations == Operations.update) {
			// if (this.party.BookingBehaviour == BookingBehavior.OpenBooking) {
			// 	partyid = this.party.openBookingDataSaveObj.PartyId;
			// } else if (this.party.BookingBehaviour == BookingBehavior.ClassOrSession) {
			// 	partyid = this.party.sessionBookingDataSaveObj.PartyId;
			// } else if (this.party.BookingBehaviour == BookingBehavior.PrivateLesson) {
			// 	partyid = this.party.privateLessonBookingDataSaveObj.PartyId;
			// }
			partyid = this.party.addToCartRequestObj.PartyId;
		}
		if( this.party.Operations == Operations.checkIn || this.party.Operations == Operations.checkOut || this.party.Operations == Operations.charge){
			partyid = this.party.selectedParty$.value?.Id || this.party.selectedBooking?.Id;
		}
		if(this.party.Operations == Operations.cancel || this.party.Operations == Operations.Refund){
			partyid = this.party.cancelBookingDataObj?.Id || null
		}
		let opertaionType;
		if (this.party.Operations == Operations.checkIn) {
			opertaionType = CheckinCheckOutBehaviour.CheckIn;
		} else if (this.party.Operations == Operations.checkOut) {
			opertaionType = CheckinCheckOutBehaviour.CheckOut;
		}
		if (this.party.ReservationAttemptData) {
			if (this.party.ReservationAttemptData.CollectAdditionalCharge && (this.party.ReservationAttemptData.PartyPaymentType == FinancialEffectType.PartialCharge
				|| this.party.ReservationAttemptData.PartyPaymentType == FinancialEffectType.PartialRefund)) {
				if (this.party.Operations == Operations.checkIn) {
					opertaionType = CheckinCheckOutBehaviour.CheckinWithAdditionalCharge;
				} else if (this.party.Operations == Operations.checkOut) {
					opertaionType = CheckinCheckOutBehaviour.CheckOutWithAdditionalCharge;
				}
			}
			else if (this.party.ReservationAttemptData.CollectAdditionalCharge) {
				opertaionType = CheckinCheckOutBehaviour.AdditionalCharge;
			}
		}
		if (this.party.Operations && this.party.Operations != 3) {
			let dto = this.formAttemptDto(transactions,partyid,transactionInfos)
			if (this.party.Operations == Operations.checkOut || this.party.Operations == Operations.checkIn) {
				this.retailCheckInCheckOutOperation(dto, transactions, opertaionType);
			} else {
				this.UpdateReservationAttempt(dto, transactionInfos);
			}
		} else if (this.party.Operations == Operations.cancel || this.party.Operations == Operations.Refund) {
			let attemptids = this.getAttemptIds();
			if(transactions?.length && attemptids?.length){
                let dto = this.formAttemptDto(transactions,partyid,transactionInfos);
				this.UpdateReservationAttempt(dto, transactionInfos,transactions)
			}
			else{
			
			this.cancelOperation(partyid,transactions,transactionInfos,null);
			}
		}
		if (!this.party.Operations) {
			if (transactionInfos._shopservice && transactionInfos._shopservice.selectedTransaction) {
				this.party.setRetailTransaction(transactions).subscribe((response) => {
					let partyDetails = { PartyId: null, ContactId: null };
					partyDetails.ContactId = transactionInfos._shopservice.selectedTransaction.guestId;
					this.SaveRetailOrderDetails(transactionInfos, partyDetails);
				});
			} else if (transactionInfos._shopservice && transactionInfos._shopservice.selectedTransaction && transactionInfos._shopservice.selectedTransaction.guestId) {
				let partyDetails = { PartyId: null, ContactId: null };
				partyDetails.ContactId = transactionInfos._shopservice.selectedTransaction.guestId;
				this.SaveRetailOrderDetails(transactionInfos, partyDetails, true);
			}
		}
		return true;
	}

	formAttemptDto(transactions,partyid,transactionDetails){
		
		let MemberRound = [];
		let MultiTenders = [];

		let commonVariable = transactionDetails._ss || transactionDetails._shopservice;

		if(commonVariable){

			if(commonVariable?.memberRoundTransaction?.length){
				commonVariable.memberRoundTransaction.forEach(el=> {
					if(el?.length){
						el.forEach(tr=>{
							if(tr && tr.Holes > 0){
								MemberRound.push({ MemberId : tr.GuestCardNo, Points : tr.Holes, CourseName : tr.CourseName, GuestName : tr.GuestName });
							}
						});
					}
				});
			}

			let settlementHistory = [];
			if(transactionDetails?.SettlementHistory?.length){
				settlementHistory = transactionDetails.SettlementHistory
			}else{
				settlementHistory = transactionDetails?._sbs?.SettlementHistory || []
			}

			if(settlementHistory?.length){
				MultiTenders = settlementHistory.map((e)=> ({
					TransactionPaymentId : e.paymentReferenceId,
					Amount : e.amount,
					TenderType : e.paymentMethodId,
					MemberId : e.memberInfo?.memberCardNumber,
					IsReversed: e.isReversed
				}));
			}
		}

		let retailSum: number = 0;
		transactions?.forEach(element => {
			retailSum += (element.TotalAmount || 0) + (element.ServiceChargeAmount || 0)
			if(this._as._cs.settings.value.PropertySetting[0].TaxType != TaxType.VAT){
				retailSum += (element.TaxAmount || 0);
			}
		});
		return {
			RestaurantId: Utilities.RestaurantId(),
			PartyId: partyid,
			SlotLockIds: this.party.TotalCartBookingObj.filter(slot => slot.selected && slot.LockSlots != null)?.map(({ LockSlots }) => LockSlots)['flat']().map(({ Id }) => Id),
			BookingID: null,
			TokenType: "",
			//PaymentAmount: transactionInfo.transactionData.totalAmount,
			//PaymentAmount: (transactions[0]?.Amount || 0) + (transactions[0]?.TaxAmount || 0),
			PaymentAmount: Number(retailSum.toFixed(this.cs.getNumberOfFractionalDigits())),
			PaymentGateway: PaymentGateways.RetailPay,
			RetailTransactions: transactions,
			ReservationAttemptIds: this.getAttemptIds(),
			MultiTenders : MultiTenders,
			MemberRoundInformation : MemberRound
		};
	}


	cancelOperation(partyid,transactions,transactionInfos,ReservationAttemptId){
		let partyDetails = { PartyId: null, ContactId: null };
			partyDetails.ContactId = this.party.cancelBookingDataObj.Contact.Id;
			partyDetails.PartyId = partyid = this.party.cancelBookingDataObj.Id;
			//let cancellationFee = this.party.cancelBookingDataObj.CancellationFee;
			let applyCancellationCharge = this.party.cancelBookingDataObj.applyCancellationCharge;

			//let chargeCancellation = cancellationFee && (cancellationFee > 0);
			let cancellationReason = this.party.cancelBookingDataObj.cancellationReason;
			let booking = this.party.cancelBookingDataObj;

			this.party.cancelRetailOrderDetails(partyid, transactions, applyCancellationCharge, cancellationReason, ReservationAttemptId,booking.bookedSessionId,booking.retailTransactions, booking.cancelAllSessions,booking.financialEffectId).subscribe((response) => {
				this.SaveRetailOrderDetails(transactionInfos, partyDetails);
				if (this.party.cs.settings.value.General.HostCancellationEmailSendBehavior == PartyEmailSendBehavior.Prompt) {
					//this.party.openConfirmationDialog(this.party.bookingCancellationPayload, null, null, ReservationEmailNotificationType.Cancelled);
					this.party.showEmailNotification(response, ReservationEmailNotificationType.Cancelled);
				}
				this._retailService.SelecedSeatProducts = [];
				this.party.showPopUp(this.ts.instant('cancelSuccessMsg'));
			});
	}
	retailCheckInCheckOutOperation(Dto, transactions, opertaionType) {
		this.party.UpdateReservationAttempt(Dto).subscribe(data => {
			this.party.GetRegisterReservationAttempt(Dto.ReservationAttemptIds).subscribe(data => {
				let party = this.party.selectedParty$?.value || this.party.selectedBooking;
				if (data && (data.State == PaymentStates.Authorized || data.State == PaymentStates.ChargeCaptured)) { 
				let sessionId = party.Sessions?.SessionId 
								? party.Sessions.SessionId 
								: party.BookedSessions?.length > 0 
									? party.BookedSessions[0]?.Id 
									: null;

				if (this.party.Operations == Operations.checkIn) {
					this.party.retailCheckInOrCheckOut(party,party.Id, party.SpecialMealId,sessionId , false, transactions, opertaionType,(Dto?.ReservationAttemptIds?.length > 0 ) ? Dto.ReservationAttemptIds[0] : null);
					return true;
				}
				if (this.party.Operations == Operations.checkOut) {
					this.party.retailCheckInOrCheckOut(party,party.Id, party.SpecialMealId, sessionId, true, transactions, opertaionType,(Dto?.ReservationAttemptIds?.length > 0 ) ? Dto.ReservationAttemptIds[0] : null);
					return true;
				}
			}
			})
		})
	}
	async searchGuestForOrder(Name, type: number): Promise<PayeeInfo[]> {
		if (this._retailService.SelecedSeatProducts.length > 0) {
			let guestInfo: any = [];
			this._retailService.SelecedSeatProducts.forEach(value => {
				guestInfo.push(this.party.TotalCartBookingObj.filter(cartBooking => cartBooking.Id == value.CartId)[0].CartItemDetail.Contact)
			})
			guestInfo.forEach(guest => {
				guest.name = guest.FirstName ? guest.FirstName : '' + ' ' + guest.LastName ? guest.LastName : ''
			});
			guestInfo = _.uniqBy(guestInfo, (data: any) => [data.name, data.EmailAddress, data.PhoneNumber].join());
			let payeeInfo: PayeeInfo[] = [];
			let count = 1;
			guestInfo.map((x) => {
				x.playerType = Number.parseInt(x.playerType) || 0;
				x.rateType = Number.parseInt(x.rateType) || 0;
				x.id = count++;
				x.name = x.name;
				payeeInfo.push(this.MapToPayeeInfo(x));
			});
			return [guestInfo, payeeInfo];
		}
		else {
			type = type == 1 ? SearchType.Member : SearchType.GuestAndPlayer;
			let guestInfo: any = [];
			let payeeInfo: PayeeInfo[] = [];
			if (Name && Name.trim().length >= 2) {
				let guestInfo = await this.guestbook.getContactSuggestion(Name, false).toPromise();;
				guestInfo.map((x) => {
					x.playerType = Number.parseInt(x.playerType) || 0;
					x.rateType = Number.parseInt(x.rateType) || 0;
					payeeInfo.push(this.MapToPayeeInfo(x));
				});
			}
			return [guestInfo, payeeInfo];
		}
	}


	processTransactions(transactionInfo, transactionDetails, financialData) {
		let seatRetailTransactions = [];
		seatRetailTransactions = transactionInfo.transactionDetails;//.filter(x => this._retailService.SelecedSeatProducts.map(x => x.LineNumber).includes(x.lineNumber));
		if (this.party.Operations == Operations.create) {
			seatRetailTransactions = transactionInfo.transactionDetails.filter(x => this._retailService.SelecedSeatProducts.map(x => x.LineNumber).includes(x.lineNumber));
		} else if (this.party.Operations == Operations.update || this.party.Operations == Operations.cancel ||  this.party.Operations == Operations.Refund || this.party.Operations == Operations.checkIn || this.party.Operations == Operations.checkOut || this.party.Operations == Operations.charge) {
			
			seatRetailTransactions = transactionInfo.transactionDetails.filter(x => this._retailService.SelecedSeatProducts.map(x => x.LineNumber).includes(x.lineNumber));
			const financialEffect = this.party.ReservationAttemptData?.ReturnItem;
			
			if (financialEffect && ((this._retailService.SelecedSeatProducts.filter(x => x.fromWidget).length == 0) || this.party.Operations == Operations.cancel ||  this.party.Operations == Operations.Refund)) {
				
				var data = transactionInfo.transactionDetails.filter(x => financialEffect.map(y => y.TransactionDetailId).includes(x.transactionDetailLinkId))
				
				if (data && data.length > 0)
					seatRetailTransactions.push(data);
			
				let cancellationFeeItems = this.getCancellationRetailItems();
				
				if (cancellationFeeItems && cancellationFeeItems.length) {
					let cancellationFeeTransactions = transactionInfo.transactionDetails.filter(x => cancellationFeeItems.map(item => item.RetailItemId).includes(x.itemId) && !seatRetailTransactions.flat().map(t => t.id).includes(x.id));
					if (cancellationFeeTransactions && cancellationFeeTransactions.length) {
						seatRetailTransactions = [...seatRetailTransactions, ...cancellationFeeTransactions];
					}
				}
				seatRetailTransactions = seatRetailTransactions.filter(x => !x.fromWidget || !x.isReturn)
			}
			else {
				let currentproduct = this._retailService.SelecedSeatProducts.filter(x => !x.isReturn);
                let Widgetproduct = this._retailService.SelecedSeatProducts.filter(x => x.fromWidget);
                currentproduct = [...currentproduct, ...Widgetproduct];
                seatRetailTransactions = transactionInfo.transactionDetails.filter(x => currentproduct.map(x => x.LineNumber).includes(x.lineNumber));
			}
		} else if (transactionDetails._shopservice.selectedTransaction) {
			seatRetailTransactions = transactionInfo.transactionDetails?.filter(x => x.transactionDetailLinkId) || [];
		}
		let totalAmount = 0;
		let totalTaxAmount = 0;

		let RetailTransactionDetailList: RetailTranactionDetailList[] = [];
		let selectedProds = [];
		seatRetailTransactions?.flat().forEach(x => {
			totalAmount = totalAmount + x.costPrice;
			totalTaxAmount = totalTaxAmount + x.tax;

			if (this.party.Operations == 1) {
				let availableProduct = this._retailService.SelecedSeatProducts.filter(prod => prod.LineNumber == x.lineNumber);
				if (availableProduct && availableProduct.length) {
					x.ReservationAttemptId = availableProduct[0].ReservationAttemptId;
					x.Index = availableProduct[0].Index;
					selectedProds.push(x);
					
				}
			} else if (this.party.Operations == 2 || this.party.Operations == 3 || this.party.Operations == Operations.checkIn || this.party.Operations == Operations.checkOut || this.party.Operations == Operations.charge) {
				let transactionAttemptId = x.transactionDetailLinkId;


				let availableProduct = this._retailService.SelecedSeatProducts.filter(prod => prod.LineNumber == x.lineNumber);
				if (availableProduct && availableProduct.length) {
					x.ReservationAttemptId = availableProduct[0].ReservationAttemptId;
					x.TransDetailId = availableProduct[0].TransDetailId;
					x.Index = availableProduct[0].Index;
					selectedProds.push(x);
				}
				else {
					selectedProds.push(x);
				}
			} else{//while refunding the widget charge and purchasing the item
				let transactionDetail: RetailTranactionDetailList = {
					TransactionDetailId: x.id,
					LineNumber: x.lineNumber,
					ReservationAttemptIdorTranactionDetailId: x.transactionDetailLinkId
				};
				RetailTransactionDetailList.push(transactionDetail);
				let availableProduct = this._retailService.SelecedSeatProducts.filter(prod => prod.LineNumber == x.lineNumber);
				if (availableProduct?.length) {
					x.ReservationAttemptId = availableProduct[0].ReservationAttemptId;
					x.TransDetailId = availableProduct[0].TransDetailId;
					x.Index =  availableProduct[0].Index;
					selectedProds.push(x);
				}
				else {
					selectedProds.push(x);
				}
			}
		});
		let Transactions = [];
		//if(transacationDetails.transactions)
		selectedProds.forEach(product => {
			let dto = {
				TransactionId: transactionInfo.transactionData.id,
				CheckHandleGuid: transactionInfo.transactionData.checkHandleGuid,
				TenderId: '',
				TenderType: "",
				MultipleTender: JSON.stringify(null),
				TicketNumber: transactionInfo.transactionData.retailTicketNumber,
				TransactionPaymentId: 1,
				PaymentState: PaymentStates.ChargeCaptured,
				RetailTransactionStatus: RetailTransactionType.SALE,
				RetailTransactionType: 0,
				RestaurantId: Utilities.RestaurantId(),
				RetailTransactionDate: transactionInfo.transactionData.transactionDate,
				//RetailTranactionDetailList: RetailTransactionDetailList,
				ReservationAttemptDetailId: this.party.ReservationAttemptData?.ReturnItem?.length && this.party.ReservationAttemptData.ReturnItem[0].ReservationAttemptDetailId ? this.party.ReservationAttemptData.ReturnItem[0].ReservationAttemptDetailId : product.ReservationAttemptId,
				MachineNameId: transactionInfo.transactionData.machineNameId,
				OutletId: transactionInfo.transactionData.outletId,
				TransactionLinkedId: transactionInfo.transactionData.transactionLinkId,
				TransactionDetailId: product.id,
				LineNumber: product.Index,
				LinkedTransactionDetailId: product.transactionDetailLinkId,
				OldTransDetailId: product.TransDetailId,
				ServiceChargeAmount: _.sumBy(product.serviceChargeGratuity, (o) => o["totalServiceChargeWithTax"]),
				NetServiceCharge: _.sumBy(product.serviceChargeGratuity, (o) => o["netServiceCharge"]),
				ServiceChargeTax: _.sumBy(product.serviceChargeGratuity, (o) => o["serviceChargeTax"]),
				NetPrice : product.netPrice,
				Tax : product.tax,
				VAT : product.vat,
				IsVat : product.isVat,
				TotalAmount : product.totalAmount
			};

			Transactions.push(dto);
		})

		if(financialData && !financialData.CancellationAmount && (this.party.Operations == Operations.cancel ||  this.party.Operations == Operations.Refund))
			{
				let dto = {
					IsCancel : true,
					TransactionId: transactionInfo.transactionData.id,
					CheckHandleGuid: transactionInfo.transactionData.checkHandleGuid,				
					TenderId: '',
					TenderType: "",
					MultipleTender: JSON.stringify(null),
					TicketNumber: transactionInfo.transactionData.retailTicketNumber,
					TransactionPaymentId: 1,
					PaymentState: PaymentStates.ChargeCaptured,
					RetailTransactionStatus: RetailTransactionType.SALE,
					RetailTransactionType: 0,
					RestaurantId: Utilities.RestaurantId(),
					ReservationAttemptDetailId : this.party.ReservationAttemptData?.ReturnItem?.length ? this.party.ReservationAttemptData.ReturnItem[0].ReservationAttemptDetailId : null,
					RetailTransactionDate: transactionInfo.transactionData.transactionDate,
					//RetailTranactionDetailList: RetailTransactionDetailList,
					MachineNameId: transactionInfo.transactionData.machineNameId,
					OutletId: transactionInfo.transactionData.outletId,
					TransactionLinkedId: transactionInfo.transactionData.transactionLinkId
				};
	
				Transactions.push(dto);
	
			}

		return Transactions;
	}

	getCancellationRetailItems(propertyId?): any[] {
		let cancellationRetailItems = []
		if (propertyId) {
			cancellationRetailItems = this._as._cs.propertySettings.value[propertyId].settings.RetailItems.filter(r => (r.ItemType == ItemType.CancellationFee) || (r.ItemType == ItemType.DepositAndCancellationFee));
		}
		else {
			Object.keys(this._as._cs.propertySettings.value).forEach(key => {
				if (this._as._cs.propertySettings.value[key].settings.RetailItems && this._as._cs.propertySettings.value[key].settings.RetailItems.length) {
					let requiredItems = this._as._cs.propertySettings.value[key].settings.RetailItems.filter(r => (r.ItemType == ItemType.CancellationFee) || (r.ItemType == ItemType.DepositAndCancellationFee));
					cancellationRetailItems = [...cancellationRetailItems, ...requiredItems];
				}
			});
		}

		return cancellationRetailItems;
	}

	getAttemptIds() {
		if (this.party.Operations == 1 ) {
			return this.party.MultipleReservationAttemptData.ResponseDTOs.map(dto => {
				return dto.RatePlanTotal.Reservations.map(item => {
					if (item.ReservationAttemptId) {
						return item.ReservationAttemptId;
					} else if (item.Slots && item.Slots.length) {
						return item.Slots.filter(slot => slot.ReservationAttemptId).map(({ ReservationAttemptId }) => ReservationAttemptId)
					} else {
						return []
					}
				})
			}).flat().filter((element, index, id) => id.indexOf(element) == index);
		}
		if(this.party.Operations == Operations.checkIn || this.party.Operations == Operations.checkOut || this.party.Operations == Operations.charge){
			return this.party.ReservationAttemptData.RatePlan.Reservations.map(item => {
				if (item.ReservationAttemptId) {
					return item.ReservationAttemptId;
				} else if (item.Slots && item.Slots.length) {
					return item.Slots.filter(slot => slot.ReservationAttemptId).map(({ ReservationAttemptId }) => ReservationAttemptId)
				} else {
					return []
				}
			}).flat().filter((element, index, id) => id.indexOf(element) == index);
		}
		if ((this.party.Operations == Operations.update)) {
			return this.party.ReservationAttemptData.RatePlan.Reservations.map(item => {
				if (item.ReservationAttemptId) {
					return item.ReservationAttemptId;
				} else if (item.Slots && item.Slots.length) {
					return item.Slots.filter(slot => slot.ReservationAttemptId).map(({ ReservationAttemptId }) => ReservationAttemptId)
				} else {
					return []
				}
			}).flat().filter((element, index, id) => id.indexOf(element) == index);
		}
		if(this.party.Operations == Operations.cancel || this.party.Operations == Operations.Refund){
			return this.party.MultipleReservationAttemptData.RatePlan.Reservations.map(item => {
				if (item.ReservationAttemptId) {
					return item.ReservationAttemptId;
				} else if (item.Slots && item.Slots.length) {
					return item.Slots.filter(slot => slot.ReservationAttemptId).map(({ ReservationAttemptId }) => ReservationAttemptId)
				} else {
					return []
				}
			}).flat().filter((element, index, id) => id.indexOf(element) == index);
		}
		
		return []

	}


// UpdateReservationAttemptForCancel(Dto,  partyid,transactions,transactionInfos) {
// 		this.party.UpdateReservationAttempt(Dto).subscribe(data => {
// 			if (data && (data.ErrorMessage || data.ExceptionMessage || (data.ValidationMessages && data.ValidationMessages.length > 0) || data.length == 0)) {

// 				//this.showMessage(url);
// 			} else{
// 				if (data.ReservationState) {
// 					let isAuthorized = true;
// 					data.ReservationState.forEach(reservationData => {
// 						if (reservationData.State != PaymentStates.Authorized && reservationData.State != PaymentStates.ChargeCaptured) {
// 							isAuthorized = false;
// 						}
// 					});

// 					if (isAuthorized) {
// 						//	this.isUserAllowedToclickConfirm = true;
// 						this.cancelOperation(partyid,transactions,transactionInfos)
// 					} else {
// 						//	this.showMessage(this.ts.instant("CreditCardNotBeProcessed"));
// 					}
// 				}
				
// 			}

// 			})}

	UpdateReservationAttempt(Dto, transactionInfo,transactions?) {
		this.party.UpdateReservationAttempt(Dto).subscribe(data => {
			if (data && (data.ErrorMessage || data.ExceptionMessage || (data.ValidationMessages && data.ValidationMessages.length > 0) || data.length == 0)) {

				//this.showMessage(url);
			} else if (data.RedirectUrl) {
				/* this._as.offTimer();
				this.LoadIframe(data.RedirectUrl);
				setTimeout(() => {
				  this.showMessage("Your current payment of " + this._settings.General.OperationCurrency + Dto.PaymentAmount + " is processed successfully! Please proceed to pay your pending amount " + this._settings.General.OperationCurrency + data.PendingAmount + " to place your reservation.");
				}, 1);
				let timer = moment(data.ExtendedSlotLockResultDTO?.ExpiresAt).diff(moment(data.ExtendedSlotLockResultDTO?.LockedAt), "seconds");
				this._as.showTimer = true;
				this._as.runTimer(timer); */
			}
			else {

				if (this.party.ReservationAttemptData && this.party.ReservationAttemptData?.ReservationAttemptId > 0) {
					// this.party.GetRegisterReservationAttempt(this.party.ReservationAttemptData.ReservationAttemptId).subscribe(data => {
					// 	if (data && (data.State == PaymentStates.Authorized || data.State == PaymentStates.ChargeCaptured)) {
							// this.isUserAllowedToclickConfirm = true;
							this.reservationConfirm(transactionInfo, this.party.ReservationAttemptData.ReservationAttemptId);
							this._retailService.SelecedSeatProducts = [];
						// }
						// else {
						// 	//this.showMessage(this.ts.instant("CreditCardNotBeProcessed"));
						// }
					// });
				}
				else {
					const ReservationIds = this.party.Operations == 1 ? this.party.ReservationAttemptData.map((item) => {
						if (item.Slots && item.Slots.length) {
							return item.Slots.filter(slot => slot.ReservationAttemptId).map(x => x.ReservationAttemptId);
						} else {
							return item.ReservationAttemptId

						}
					}).flat().filter(id => id) :  this.getAttemptIds();
					if (data.ReservationState) {
						let isAuthorized = true;
						data.ReservationState.forEach(reservationData => {
							if (reservationData.State != PaymentStates.Authorized && reservationData.State != PaymentStates.ChargeCaptured) {
								isAuthorized = false;
							}
						});

						if (isAuthorized) {
							//	this.isUserAllowedToclickConfirm = true;
							this.reservationConfirm(transactionInfo, this.party.Operations == 2 || this.party.Operations == 3? ReservationIds[0] : null,transactions);
						} else {
							//	this.showMessage(this.ts.instant("CreditCardNotBeProcessed"));
						}
					}
				}
			}
		});
  }
  
	reservationConfirm(transactionInfo, ReservationAttemptId,transactions?) {

		if (this.party.Operations == 1) {
			var selectedCartItems = this.party.TotalCartBookingObj.filter(item => item.selected);
			var CartBookingInputList = [];
			if(!selectedCartItems?.length) {
				selectedCartItems = this.party.TotalCartBookingObj.filter(item => item.CartId == this.party.isfromBuyNowCartId);
			}
			selectedCartItems.forEach(item => {
				let settings;
				let _SlotLockResult = item.SlotLockResult ? item.SlotLockResult : item.CartItemDetail?.SlotLockResultDTO;

				if (item.RestaurantId) {
					settings = this._as._cs.propertySettings.value[item.RestaurantId].settings;
				} else if (item.PropertyId) {
					settings = this._as._cs.propertySettings.value[item.PropertyId].settings;
				}
				let _selectedSpecialMeal = settings.SpecialMeals.find(meal => meal.Id === item.CartItemDetail.SpecialMealId);
				var obj = {
					CartItemId: item.Id,
					BookingType: item.CartItemDetail.BookingBehavior,
					LockId: 0,
					SlotTime: item.CartItemDetail.BookingBehavior === BookingBehavior.RentalBooking ? item?.CartItemDetail?.Slots[0]?.Time : '',
					ReservationAttemptId: 0,
					PropertyId: item.PropertyId,
					EnableTicketPrinting: BookingBehavior.OpenBooking == item.CartItemDetail.BookingBehavior ? false : BookingBehavior.RentalBooking == item.CartItemDetail.BookingBehavior ? true : _selectedSpecialMeal?.EnableTicketPrinting || false,
					TicketPerPerson: BookingBehavior.OpenBooking == item.CartItemDetail.BookingBehavior ? false : BookingBehavior.RentalBooking == item.CartItemDetail.BookingBehavior ? true  : _selectedSpecialMeal?.TicketPerPerson || false,
					IsForStandbyReservations: BookingBehavior.OpenBooking == item.CartItemDetail.BookingBehavior ? false : (_selectedSpecialMeal?.IsForStandbyReservations || item.CartItemDetail.IsForStandbyReservations) || false, 
					PackageDetails: this.party.getDiscountPercentForCartItem(item.CartItemDetail?.SpecialMealId),
					PaymentMethod: this.party.bookingPaymentType,
					LanguageId: Number(sessionStorage.getItem('languageId')) || DEFAULT_LANGUAGE_ID
				}
				if (_SlotLockResult && _SlotLockResult.SlotLockIdDTO) {
					obj.LockId = _SlotLockResult.SlotLockIdDTO.Id;
						obj.ReservationAttemptId = this.getReservationAttemptId(item.Id);
					CartBookingInputList.push(obj);
				} else if (_SlotLockResult && !item.CartItemDetail.IsForStandbyReservations) {
					item.LockSlots = Object.keys(_SlotLockResult).forEach(slotLockKey => {
						let tempObj = { ...obj };
						let _selectedActivity =  settings?.SpecialMeals?.find(meal => meal.Id === item.CartItemDetail.SpecialMealId);
						tempObj.SlotTime = slotLockKey;
						tempObj.ReservationAttemptId = this.getOpenBookingReservationAttemptId(item.Id, slotLockKey);
						tempObj.LockId = _SlotLockResult[slotLockKey]?.SlotLockIdDTO?.Id;
						tempObj.EnableTicketPrinting = BookingBehavior.OpenBooking == item.CartItemDetail.BookingBehavior ? false : _selectedActivity?.EnableTicketPrinting;
						tempObj.TicketPerPerson = BookingBehavior.OpenBooking == item.CartItemDetail.BookingBehavior ? false : _selectedActivity?.TicketPerPerson;
						tempObj.IsForStandbyReservations = BookingBehavior.OpenBooking == item.CartItemDetail.BookingBehavior ? false : (_selectedActivity?.IsForStandbyReservations || item.CartItemDetail.IsForStandbyReservations)
						CartBookingInputList.push(tempObj);
					});
				} else if (item.CartItemDetail.IsForStandbyReservations) {
					if ((!_SlotLockResult || !_SlotLockResult[Object.keys(_SlotLockResult)[0]].SlotLockIdDTO) && (item.CartItemDetail?.BookingBehavior == BookingBehavior.PrivateLesson || item.CartItemDetail?.BookingBehavior == BookingBehavior.OpenBooking) ){
						item.CartItemDetail.Slots.forEach(slot => {
							let tempObj = { ...obj };
							tempObj.SlotTime = slot.Time;
							if (slot.LockId) {
								tempObj.ReservationAttemptId = this.getReservationAttemptId(item.Id);
							} else {
								tempObj.ReservationAttemptId = this.getReservationAttemptId(item.Id, slot.Time);
							}
						
							CartBookingInputList.push(tempObj);
						});
					}
					else {
						obj.ReservationAttemptId = this.getReservationAttemptId(item.Id);
						CartBookingInputList.push(obj);
					}
				}
			});

			let cartBookingInput = {PaymentCard:this.party.PaymentCard, CartItem:CartBookingInputList}
			this.bookCart(cartBookingInput, false, transactionInfo);

		}
		else if (this.party.Operations == 2) {
			this.transactionInfo = transactionInfo;
			let partyData =null;
			this.party.addToCartRequestObj.ReservationAttemptId = ReservationAttemptId;
			this.party.addToCartRequestObj.FinancialEffectId = this.party.paymentInfo.FinancialEffectId;
			if (this.party.BookingBehaviour == BookingBehavior.OpenBooking || this.party.addToCartRequestObj.BookingBehavior == BookingBehavior.RentalBooking) {
				if (ReservationAttemptId)
				//	this.party.addToCartRequestObj.ReservationAttemptId = ReservationAttemptId;
				this.party.confirmUpdateOpenBooking(this.party.addToCartRequestObj, false, null, this.party.addToCartRequestObj.selecteddate)

			} else if (this.party.BookingBehaviour == BookingBehavior.ClassOrSession) {
				if (ReservationAttemptId)
				//	this.party.addToCartRequestObj.ReservationAttemptId = ReservationAttemptId;
				this.party.confirmUpdateSessionBooking(this.party.addToCartRequestObj, false, null);

				this.party.sessionBookingDataSaveObj = null;
			} else if (this.party.BookingBehaviour == BookingBehavior.PrivateLesson) {
				
				this.party.confirmUpdatePrivateLessonBooking(this.party.addToCartRequestObj, false, null, this.party.addToCartRequestObj.selecteddate);
			}
			//this.SaveRetailOrderDetails(this.transactionInfo, null);

			let subscription: ISubscription = this.party.isRetailOpenItemUpdated$.subscribe(data => {
				this.SaveRetailOrderDetails(this.transactionInfo, data.Payload);
				subscription.unsubscribe();
			})

		} else if(this.party.Operations == Operations.charge || this.party.Operations == Operations.Refund ){
			this.party.confirmChargeGuest(this.getAttemptIds())
		}
		else if(this.party.Operations == Operations.cancel){
			this.cancelOperation(this.party.cancelBookingDataObj.Id, transactions,transactionInfo,ReservationAttemptId)
		}
			
			if(transactionInfo.from){
				if(transactionInfo?._ss?.allowAutoCloseTransaction){
					transactionInfo.PerformCloseTransaction();
				}
			}
			else{
				if(transactionInfo?._shopservice?.allowAutoCloseTransaction){
					transactionInfo._sbs.PerformCloseTransaction();
				}
			}
		
		

	}

	getReservationAttemptId(cartId , slotTime?) {
		let cart = this.party.ReservationAttemptData.find(item => +item.CartId === cartId);
		if(cart){
			cart.RatePlanTotal.Reservations.map((item) => {
				if (item.Sessions && item.Sessions.length) {
						return item.Sessions.filter(slot => slot.ReservationAttemptId)
						            .map(x => x.ReservationAttemptId);
				} else {
					return item.ReservationAttemptId

		}});
		}
		let attemptId;
		if (slotTime) {
			let reservation = this.party.ReservationAttemptData?.find(item => +item.CartId === cartId)?.RatePlanTotal?.Reservations;
			attemptId = reservation?.find(booking => booking.Sessions?.find(item => item.Date == slotTime))?.ReservationAttemptId;

		} else {
			attemptId = this.party.ReservationAttemptData?.find(item => +item.CartId === cartId)?.ReservationAttemptId;
		}
		return attemptId;
	}

	getOpenBookingReservationAttemptId(cartId, key) {
		//TODO
		//Multi slot, more then session
		let attemptId = null;
		this.party.ReservationAttemptData.map(data => {
			if(data.CartId == cartId)
				data.RatePlanTotal.Reservations.map(item => {
					item.Sessions.map(session => {
						if(session.Date == key) 
						attemptId = item.ReservationAttemptId;
					})
			})
		})
		return attemptId ? attemptId : this.party.ReservationAttemptData.find(item => +item.CartId === cartId)?.ReservationAttemptId;
	}


	bookCart(cartBookingInput, ignoreBookingValidation, transactionInfo) {

		this.party.bookCartItemsForMultipleProperties(cartBookingInput, ignoreBookingValidation).subscribe(response => {
			if (response.State == OperationResultState.ConsentMessages && response.ValidationMessages?.length) {
				this.showExistingRetailReservationPopup(ComponentTypes.CreateCartBooking, cartBookingInput.CartItem, response.ValidationMessages, transactionInfo);
				return;
			}
			this.party.proceedToRetailPayment = false;
			this.party.ReservationAttemptData = null;
			this.party.selectedPackage = null;
			this._as.offTimer();
			//this.cartSumaryDataList = this.cartSumaryDataList.filter(item => !item.selected);
			this.party.TotalCartBookingObj = this.party.TotalCartBookingObj.filter(x => x.Id != 0 && !cartBookingInput.CartItem.map(y => y.CartItemId).includes(x.Id));
			this.party.cartCount = this.party.TotalCartBookingObj.length;
			//this.calculateCartTotal();
			this.party.bookingConfirmationData = response.Payload;
			let enableTickets = cartBookingInput.CartItem.filter((ticket) => ticket.EnableTicketPrinting == true && ticket.IsForStandbyReservations === false);
			let anyConfirmedReservations = response.Payload?.flat()?.filter(party => party.ConfirmedSessionsForCart && party.ConfirmedSessionsForCart.length && party.ConfirmedSessionsForCart.filter(p => p.SessionType == PartyType.Reservation|| PartyType.RentalReservation)?.length);
			if(!this.party.redirectPath || !this.party.redirectPath.includes('pos')){
				if (enableTickets && enableTickets.length > 0 && anyConfirmedReservations && anyConfirmedReservations.length) {
					this.party.ShowTicket();
				} else {
					this.popupService.restrictCloseDialog = false;
					const _confirmationMessages = Utilities.getConfirmationMessage(response, this.ts.instant('CartBookSuccessMessageText'), null, null);
					let _title = Utilities.getConfirmationDialogTitle(null);
	
					this.party.openAppConfirmationDialog(response, _title, _confirmationMessages, 'action', this.ts.instant('ok'), '');
				}
				if (response.State == 0 && response.Payload && response.Payload.length) {
					this.SaveRetailOrderDetails(transactionInfo, response.Payload[0]);
					if(this.party.cs.settings.value.General.HostConfirmationEmailSendBehavior == PartyEmailSendBehavior.Prompt && this.cs?.settings?.value?.MerchantSettings?.EnableConsolidatedEmail?.SettingValue != SettingType.ENABLE)
					{
						if (response.Payload && response.Payload.length === 1) {
							this.party.showEmailPopup(response, response.Payload[0][0], ReservationEmailNotificationType.Created, response.Payload[0][0].PropertyId);
						} else {
							this.party.showEmailPopup(response, response.Payload[0][0], ReservationEmailNotificationType.Created);
						}
					}
				}
			} else if(this.party.redirectPath.includes('pos')){
				this.party.posBookingCompletion$.next(response.Payload);
			}

		})
	}

	showExistingRetailReservationPopup(fromType: ComponentTypes, CartBookingInputList, sessions, transactionInfo) {
		let proceedBooking = false;
		sessions = sessions.map(messageData => messageData?.Message || messageData);
		if (sessions && sessions.length) {
			let msg = this.ts.instant('existingBookingInfoMessage');
			this.party.showWarningInfoPopUp(msg, ComponentTypes.ExistngReservationWarning, '450px', '750px', sessions)
			this.party.warningInfoDialogRef.afterClosed().subscribe(event => {
				if (proceedBooking) {
					let cartBookingInput = {PaymentCard:this.party.PaymentCard, CartItem:CartBookingInputList}
					this.bookCart(cartBookingInput, true, transactionInfo);
				}
				confirmationSubscription.unsubscribe();
			});

			let confirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
				if (val === ComponentTypes.ExistngReservationWarning) {
					proceedBooking = true;
					this.party.warningInfoDialogRef.close();
				}
			});
		}
	}

	/**
   * @description Retrieves all the outlest based on PropertyId and UserId
   */

	/**
	 * @description Validates the user have the access to outlets
	 *               and also checks for default outlet selection
	 * @returns bool
	 */


	/**
	 * @description Validates the deposit item is from the selected outlet
	 * @returns bool
	 */
	async CheckDepositItemsAreFromSelectedOutlet(shopitems): Promise<boolean> {
		const selectedOutletItems = shopitems.filter((si) =>
			si.outletItem.some((o) => o.outletId == this._retailService.SelectedOutletId)
		);
		let depositItemInSelectedOutletItem: boolean = selectedOutletItems.some(
			(x) => x.retailItemDetail.itemType == 6
		);
		if (!depositItemInSelectedOutletItem) {
			const errorMsg = this.localization.getError(10722);
			this._retailService.selectedProducts = [];
			//	this._retailUtils.showAlert(errorMsg, AlertType.Warning, ButtonType.Ok);
		}
		return depositItemInSelectedOutletItem;
	}

	/**
	 * @description Validates the item is from the selected outlet
	 * @returns bool
	 */

	CheckPlayersHaveAnyPendingSettlements(selectedPlayers: ScheduledTeeTimeUnPaidPlayer[]): boolean {
		let playersHavePendingSettlements = false;
		if (selectedPlayers && selectedPlayers.length > 0) {
			if (selectedPlayers.some((p) => p.ticketNumber != '')) {
				playersHavePendingSettlements = true;
				let pendingPlayers = selectedPlayers.filter((p) => p.ticketNumber != '');
				let playerNamesWithTickets: string = '';
				pendingPlayers.map((p) => {
					playerNamesWithTickets += `<b>${p.playerName}</b> - ${p.ticketNumber}<br/>`;
				});
				let alertMsg =
					this.localization.captions.teetime.PlayerHasPendingSettlemtWarningMsg +
					'<br>' +
					playerNamesWithTickets;
				//	this._retailUtils.showAlert(alertMsg, AlertType.Info, ButtonType.Ok);
			}
		}
		return playersHavePendingSettlements;
	}




	GetCustomStaffId(serviceChargeGratuity: any): string {
		let customId: string = '';

		if (serviceChargeGratuity.staffType == THERAPIST) {
			customId = `T${serviceChargeGratuity.therapistId}`;
		}
		else if (serviceChargeGratuity.staffType == USER) {
			customId = `U${serviceChargeGratuity.therapistId}`;
		}
		return customId;
	}

	SetCustomStaffIdForCommission(commission: any[]): any[] {
		if (!commission || commission.length == 0) {
			return commission;
		}
		commission.forEach((c) => {
			if (c.staffType.toUpperCase() == THERAPIST) {
				c.id = `T${c.staffId}`;
			} else if (c.staffType.toUpperCase() == USER) {
				c.id = `U${c.staffId}`;
			}
		});
		return commission;
	}



	private SetServiceChargeGratuityValue(serviceChargeGratuity: any[], newObject: any, type: string) {
		if (serviceChargeGratuity && serviceChargeGratuity.length > 0) {
			switch (type) {
				case 'SERVICECHARGE': {
					var sc = serviceChargeGratuity.find((r) => r.serviceCharge != 0);
					if (sc) {
						let percentageId = this.GetPercentageId(sc.serviceChargePercent);
						if (percentageId > 0) {
							newObject.PercentageId = percentageId;
							newObject.Percentage = sc.serviceChargePercent;
						} else if (sc.serviceChargePercent > 0) {
							newObject.PercOrAmount = ServiceChargeGratuityValueType.CustomPercent;
							newObject.Amount = sc.serviceChargePercent;
						} else {
							newObject.PercOrAmount = ServiceChargeGratuityValueType.CustomAmount;
							newObject.Amount = sc.serviceCharge;
						}
					}
					break;
				}
				case 'GRATUITY': {
					var sc = serviceChargeGratuity.find((r) => r.gratuity != 0);
					if (sc) {
						let percentageId = this.GetPercentageId(sc.gratuityPercent);
						if (percentageId > 0) {
							newObject.PercentageId = percentageId;
							newObject.Percentage = sc.gratuityPercent;
						} else if (sc.gratuityPercent > 0) {
							newObject.PercOrAmount = ServiceChargeGratuityValueType.CustomPercent;
							newObject.Amount = sc.gratuityPercent;
						} else {
							newObject.PercOrAmount = ServiceChargeGratuityValueType.CustomAmount;
							newObject.Amount = sc.gratuity;
						}
					}
					break;
				}
			}
		}
	}

	GetPercentageId(percent: number): number {
		let percentageId = 0;
		if (percent == 10) {
			percentageId = ServiceChargeGratuityPercentId.TenPercent;
		} else if (percent == 15) {
			percentageId = ServiceChargeGratuityPercentId.FifteenPercent;
		} else if (percent == 20) {
			percentageId = ServiceChargeGratuityPercentId.TwentyPercent;
		} else if (percent == 25) {
			percentageId = ServiceChargeGratuityPercentId.TwentyFivePercent;
		}
		return percentageId;
	}


	getPrice(memberPrice: number, salesPrice: number, isMember: boolean) {
		return isMember ? memberPrice : salesPrice;
	}

	getPackageItemPrice(groupItemPrice: number, negotiatedPrice: number, packagedItemPrice: number) {
		const weightedPrice = groupItemPrice > 0 ? packagedItemPrice / groupItemPrice : 0;
		return negotiatedPrice * weightedPrice;
	}

	getPackageItemIds(selectedPlayers) {
		const groupedItems = [...selectedPlayers.filter(x => x.cartFeeRetailItem?.isGroupingKey).map(x => x?.cartFeeRetailItem?.id)]
			.concat([...selectedPlayers.filter(x => x.greenFeeRetailItem?.isGroupingKey).map(x => x?.greenFeeRetailItem?.id)])
			.concat([...selectedPlayers.filter(x => x.entryFeeRetailItem?.isGroupingKey).map(x => x?.entryFeeRetailItem?.id)]);
		return _.uniq(groupedItems);
	}
	/**
	 * @description Returns an object matching the SelectedProduct interface with the values from params
	 * @param player
	 * @param retailItem
	 * @param price
	 * @param lineNumber
	 */
	FormProductObj(player: ScheduledTeeTimeUnPaidPlayer, retailItem, price: number, lineNumber: number, isCartRetailItem?: boolean, IsIncludedInRound?: boolean,
		groupingId?: number, groupingItemDescription?: string, groupingUniqueIdentifier?: string) {
		return {
			id: player.playerId,
			payeeId: player.playerId,
			playerName: player.playerName,
			ItemId: retailItem.id,
			ItemType: retailItem.itemType,
			ExternalPOSItemId: retailItem.externalPOSId,
			ItemDescription: retailItem.itemDescription,
			ProductName: retailItem.itemDescription,
			ServiceId: 0,
			ProductPrice: price,
			SalesPrice: price,
			Noofitems: 1,
			Discount: 0,
			DiscountPercentage: 0,
			DiscountTypeId: 0,
			isEditDisabled: true,
			isDeleteDisabled: true,
			isGroupingKey: retailItem.isGroupingKey,
			isPackagedItem: retailItem.isPackagedItem,
			PackageItemId: 0,
			MultiPack: retailItem.isMultiPack,
			ClientMultiPackId: 0,
			PackageGroupId: 0,
			isOpenPricedItem: retailItem.isOpenItem,
			category: retailItem.category,
			LineNumber: lineNumber,
			isModificationRestricted: isCartRetailItem ? false : true,
			isCommissionable: retailItem.isCommissionable,
			isIncludedInRound: IsIncludedInRound ? true : false,
			costPrice: retailItem.costPrice,
			marginPercentage: retailItem.marginPercentage,
			isRequireComments: retailItem.isRequireComments,
			itemComments: "",
			isRequestName: retailItem.isRequestName,
			allowEarn: retailItem.allowEarn,
			discountComments: '',
			discountReason: 0,
			GroupingParentId: groupingId,
			GroupingUniqueIdentifier: groupingUniqueIdentifier,
			GroupingItemDescription: groupingItemDescription
		};
	}

	GetRetailItemsFromPlayers(SelectedPlayers) {
		let golfItems = [];
		if (SelectedPlayers) {
			SelectedPlayers.map((s) => {
				if (s.greenFeeRetailItem) {
					golfItems.push(s.greenFeeRetailItem);
				}
				if (s.cartFeeRetailItem) {
					golfItems.push(s.cartFeeRetailItem);
				}
				if (s.entryFeeRetailItem) {
					golfItems.push(s.entryFeeRetailItem);
				}
				if (s.tournamentId > 0 && s.otherItems.length > 0) {
					s.otherItems.map((item) => {
						if (item.retailItemDetail) {
							golfItems.push(item.retailItemDetail);
						}
					});
				}
			});
		}
		return golfItems;
	}

	/**
   * @description This method will trigger an API which retrieves the players transaction details with PlayerNames
   * @param transactionId
   */


	/**
  * @description This method will trigger an API which creates the player
  * @param clientobject
  */

	/**
	 * @description This method will get the configuration for rental agreements
	 */



	SetP1PlayerId() {
		let firstPlayer = _.orderBy(this._retailService.SelectedPlayers, 'playerSlotPosition', 'asc');
		let memberIndex = firstPlayer.findIndex((member) => member.playerCategoryId === 3); //For finding member index
		this._retailService.selectedSlotFirstPlayerId =
			memberIndex != -1 ? firstPlayer[memberIndex].playerId : firstPlayer[0].playerId;
		return this._retailService.selectedSlotFirstPlayerId;
	}

	ResetCourseTime() {
		this._retailService.SelectedDate = '';
		this._retailService.SeletedCourseId = 0;
	}


	GetDefaultOutlet() {
		return this.userDefaultService.GetDefaultOutlet();
	}

	private mapToUI(apiData: TeeTimeConfig): TeeTimeConfig {
		return {
			rainCheckNote: apiData.rainCheckNote,
			ticketNote: apiData.ticketNote,
			requirePlayerType: apiData.requirePlayerType,
			requireRateType: apiData.requireRateType,
			showMemberSignOnTeeGrid: apiData.showMemberSignOnTeeGrid,
			defaultToGraphicalTeeSheet: apiData.defaultToGraphicalTeeSheet,
			promptUserToPrintTeeTickets: apiData.promptUserToPrintTeeTickets,
			autoPrintTeeTickets: apiData.autoPrintTeeTickets,
			requireTeeTimesToPaid: apiData.requireTeeTimesToPaid,
			setPaidOnDayOfPlay: apiData.setPaidOnDayOfPlay
		};
	}

	async SaveRetailOrderDetails(transactionInfo, partyDetails?, directSale?, linkId?) {
		let RetailTransactions = [];
		if (this.party.Operations === 1) {
			RetailTransactions = transactionInfo.CreateRetailItemResponse.transactionDetails.filter(x => !this._retailService.SelecedSeatProducts.map(y => y.LineNumber).includes(x.lineNumber));
		} else if (this.party.Operations === 2 || (this.party.Operations == 3 && transactionInfo.CreateRetailItemResponse.transactionDetails.length)) {
			const financialEffect = this.party.ReservationAttemptData?.ReturnItem;
			if (this._seatRetail.oldReservation?.fromWidget) {
				RetailTransactions = transactionInfo.CreateRetailItemResponse.transactionDetails.filter(x => !this._retailService.SelecedSeatProducts.map(y => y.LineNumber).includes(x.lineNumber));
			}
			else {
				RetailTransactions = transactionInfo.CreateRetailItemResponse.transactionDetails.filter(x => x.transactionDetailLinkId != 0 && !financialEffect.map(y => y.TransactionDetailId).includes(x.transactionDetailLinkId));
				let updatedItems = transactionInfo.CreateRetailItemResponse.transactionDetails.filter(x => x.transactionDetailLinkId == 0);
				if (updatedItems.length) {
					updatedItems.forEach((item, index) => {
						if (index != 0) {
							RetailTransactions.push(item);
						}
					})
				}
			}


		} else if (transactionInfo._shopservice && transactionInfo._shopservice.selectedTransaction) {
			transactionInfo._shopservice.selectedTransaction?.activityTransactionId?.forEach(transactionId => {
				RetailTransactions.push(...transactionInfo.CreateRetailItemResponse.transactionDetails.filter(x => x.transactionDetailLinkId !== transactionId));
			})
		}
		else if (directSale) {
			RetailTransactions = transactionInfo.CreateRetailItemResponse.transactionDetails;
		}
		let transactionData = null;
		if (transactionInfo.CreateRetailItemResponse && transactionInfo.CreateRetailItemResponse.transactionData) {
			transactionData = transactionInfo.CreateRetailItemResponse.transactionData;
		}
		let req = {} as OrderetailDTO;
		if (RetailTransactions && RetailTransactions.length) {
			req.CheckId = transactionInfo.CreateRetailItemResponse.Id;
			req.RestaurantId = Utilities.RestaurantId();
			req.PartyId = partyDetails?.PartyId;
			req.OrderPrice = transactionData.totalPrice
			req.CreatedAt = transactionData.transactionDate;
			req.orderDetails = [];
			RetailTransactions?.forEach(order => {
				let item = {} as OrderItemDTO;
				item.Id = order.itemId;
				item.Qty = order.quantitySold;
				item.ExtPrice = order.netUnitPrice;
				item.Name = order.itemDescription;
				item.TaxPrice = order.tax;
				//item.RetailTransactionType = transactionData.retailTransactionType;
				item.RetailItemType = order.itemType;
				req.orderDetails.push(item);
			});
			req.RetailTransactionId = transactionData.id;
			req.OldRetailTransactionId = transactionData.transactionLinkId;
			req.ContactId = partyDetails?.ContactId || transactionData.guestId;
			//req.RetailTransactionType = transactionData.retailTransactionType;
			req.OldRetailTransactionType = null;
			req.OrderType = OrderType.RETAIL;
			if(!this.party.retailClientSearch || this.party.retailClientSearch !== 1 ){
				this.party.saveOrderDetails(req).subscribe(data => {
					this._retailService.SelecedSeatProducts = [];
					this.transactionInfo = [];
					this.party.ReservationAttemptData = null;
				});
			}
		} else if (linkId || (transactionData && transactionData.transactionLinkId)) {
			req.OldRetailTransactionId = linkId ? linkId : transactionData.transactionLinkId;
			if(!this.party.retailClientSearch || this.party.retailClientSearch !== 1 ){
			this.party.saveOrderDetails(req).subscribe(data => {
				this.transactionInfo = [];
				this.party.ReservationAttemptData = null;
			});
		}
		}
		this._retailService.SelecedSeatProducts = [];
	}

	destroyObjects() {
		this.party.Operations = null;
		this.party.BookingBehaviour = null;
		this.party.openBookingDataSaveObj = null;
		this.party.sessionBookingDataSaveObj = null;
		this.party.privateLessonBookingDataSaveObj = null;
		this._seatRetail.oldReservation = {};
		this.party.cancelBookingDataObj = null;
		this.party.cancelBookingContactObj = null;
	}

	resetRetailVariables(){
		this.shopService.isCreateOperation = false;
		this.shopService.isOperationFromActivities = false;
		this.shopService.memberRoundTransaction = [];
		this.shopService.isCancelOperation = false;
		this.shopService.sectionName = '';
		this.shopService.selectedRetailItem = null;
	}

	processRetailTransactions(transactionInfo) {
		let totalAmount = 0;
		let totalTaxAmount = 0;
		let RetailTransactions = [];
		RetailTransactions.forEach(x => {
			totalAmount = totalAmount + x.totalAmount;
			totalTaxAmount = totalTaxAmount + x.taxAmount;
		});
		let Transactions = [];
		let dto = {
			TransactionId: transactionInfo.transactionData.id,
			CheckHandleGuid: transactionInfo.transactionData.checkHandleGuid,
			Amount: totalAmount,
			TaxAmount: totalTaxAmount,
			TenderId: '',
			TenderType: "",
			MultipleTender: '',
			TicketNumber: transactionInfo.transactionData.ticketNumber,
			TransactionPaymentId: 1,
			PaymentState: PaymentStates.ChargeCaptured,
			RetailTransactionStatus: RetailTransactionType.SALE,
			RetailTransactionType: 0,
		};
		Transactions.push(dto);

		return Transactions;
	}

}