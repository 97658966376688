import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'gformControlErrors',
  standalone: true
})
export class GformControlErrorsPipe implements PipeTransform {

  errorMessages: any = {
      required: ' is required',
      maxlength: ' enter valid.',
      minlength: ' enter valid.',
      pattern: 'Please enter a valid',
      whiteSpace: "can't contain space",
      matDatepickerMin: "must be greater than",
      greaterTime: "End Time must be greater than Start Time",
      max: "Please enter a valid",
      email: "Please enter a valid email",
      nameMandatory: 'nameMandatory'
  }

  constructor(private ts: TranslateService) {}

  transform(errors: { [key: string]: any }, label: string): string[] {
    let errorList: string[] = [];
    if(errors){
      Object.entries(errors).forEach(([key, value]: [string, any]) => {
        if(value){
          errorList.push(this.ts.instant(this.errorMessages[key], {name: this.ts.instant(label) , value: key == 'matDatepickerMin' ? value?.min?.toLocaleString('en-US',{year: "numeric",month: "2-digit",day: "2-digit"}) :  value?.requiredLength || ''}));
        }
      })
    }
    return errorList;
  }

}
