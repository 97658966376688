<div>
    <div *ngIf="!showActivityCreation">
        <div *ngIf="showActivitiesList">
            <app-activity-table [tableData]="tableData" [createButton]="createButton" [hideDateFilter]="true" (filterEvent)="setFilterItem($event)"
                (createEvent)="createActivity($event)" (removeLesson)="removeActivity($event)" [isTemplate]="true">
            </app-activity-table>
        </div>
    </div>
    <div *ngIf="showActivityCreation">
        <app-basic-setup [activityData]="basicSettings" (cancelEvent)="cancelActivity()"
            (saveEvent)="refreshActivity($event)" [isEdit]="isEdit" [isTemplate]="true"></app-basic-setup>
    </div>
</div>