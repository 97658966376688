<div class="editor-joined" [hidden]="fs.joinTable">
  <label class="editor-joined__heading" for="">{{'joinedTables' | translate}}</label>
  <!-- <table class="editor-joined__table-view" border=1>
    <thead class="editor-joined__header-row">
      <th class="editor-joined__header-row-content">{{'standAloneTables' | translate}}</th>
      <th class="editor-joined__header-row-content">{{'sizeText' |translate}}</th>
      <th class="editor-joined__header-row-content">{{'actionText' | translate}}</th>
    </thead>
    <tbody class="editor-joined__body-row">
      <tr class="editor-joined__data-row" *ngFor="let item of joinedTablesArray;let i =index">
        <td class="editor-joined__data-row-content">
          <span *ngFor="let table of item.StandaloneTableIds;last as isLast">
            <span>{{table | getName }}</span>
            <span *ngIf="!isLast">, </span>
            </span>
        </td>
        <td class="editor-joined__data-row-content">{{item.MinPartySize}} - {{item.MaxPartySize}}</td>
        <td class="editor-joined__data-row-content">
          <activities-app-button [buttontype]="editJoinTable" (click)="editJoinedTable(i)"></activities-app-button>
          <activities-app-button [buttontype]="deleteTableBtn" (click)="deleteJoinedTable(i)"></activities-app-button>
        </td>
      </tr>
    </tbody>
  </table> -->

  <table mat-table [dataSource]="joinedTableData" class="editor-joined__table-view" *ngIf = "joinedTablesArray?.length > 0">

  <ng-container matColumnDef="standAlone">
    <th class="editor-joined__table-header" mat-header-cell  *matHeaderCellDef> {{ 'standAloneTables' | translate}} </th>
    <td class="editor-joined__table-data" mat-cell *matCellDef="let item">
      <span *ngFor="let table of item.StandaloneTableIds; last as isLast">
        <span>{{table | getName }}</span>
        <span *ngIf="!isLast">, </span>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="size">
    <th class="editor-joined__table-header" mat-header-cell  *matHeaderCellDef> {{ 'sizeText' | translate}} </th>
    <td class="editor-joined__table-data" mat-cell *matCellDef="let item"> {{item.MinPartySize}} - {{item.MaxPartySize}} </td>
  </ng-container>



  <ng-container matColumnDef="action">
    <th class="editor-joined__table-header" mat-header-cell *matHeaderCellDef> {{'actionText' | translate}} </th>
    <td class="editor-joined__table-data" mat-cell *matCellDef="let item;let i=index">
      <!-- <activities-app-button [buttontype]="editJoinTable" (click)="editJoinedTable(i)"></activities-app-button>
      <activities-app-button [buttontype]="deleteTableBtn" (click)="deleteJoinedTable(i)"></activities-app-button> -->
      <a class="icon-ic_create_24px " (click)="editJoinedTable(i)"></a>
      <a class="icon-ic_delete_24px editor-joined__action-icon" (click)="deleteJoinedTable(i)"></a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
</table>
<div class="editor-joined__no-data-content" *ngIf = "joinedTablesArray?.length == 0">
  {{'noJoinedTables' | translate}}
</div>

</div>
<div class="new-join" [hidden]="!fs.joinTable">
  <label class="new-join__heading" for="">{{'newJoinTable' | translate}}</label>
  <span class="new-join__error-msg" *ngIf="showCommunalTableError">{{'communalTableinJoinError' | translate }}</span>
  <div class="new-join__added-tables" *ngFor="let data of fs.joinedTables;let i = index">
    <span class="new-join__added-table-name">
      {{'tableText' | translate}} {{data | getName }}
    </span>
    <a class="new-join__action" (click)="removeTable(data,i)">-</a>
  </div>
  <div class="new-join__no-tables-found" *ngIf="fs?.joinedTables?.length == 0">
    <span>{{'noTablesAdded' | translate}}</span>
  </div>
  <div class="new-join__form-data">
    <dynamic-form [config]="joinedTableConfig" #form="dynamicForm">
    </dynamic-form>
  </div>
</div>