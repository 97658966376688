import { Injectable } from '@angular/core';
import { HttpClient   } from '@angular/common/http'; //Activities - Retail integration(Http replaced)
import { map } from 'rxjs/operators';


@Injectable()
export class ReportService {

  searchPath: string = "/assets/transaction-log.json";
  constructor(public http:HttpClient) { } //Activities - Retail integration(Http replaced)

  getTransactionLog() {
    //Activities - Retail integration(Http replaced)
    //return this.http.get(this.searchPath).pipe(map(response => response.json()));
    return this.http.get(this.searchPath).pipe(map(response => response)); 
  }


}
