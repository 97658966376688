<section class="seat-reservation-type" [class.no-gap]="partyService.isRentalIntegrated">
 <div class="create-reservation" (click)="selectReservationType(ReservationType.ActivityBooking)">
    <i class="icon-open-book-2 show-icon"></i>
   <span class=" reservation-label"  >{{'classBooking' | translate}}</span>
 </div>
 <div class="create-waitlist" (click)="selectReservationType(ReservationType.OpenBooking)">
    <i class="icon-ic_update_24px show-icon"></i>
  <span class="waitlist-label" >{{'openBooking' | translate}}</span>
 </div>
 <div class="create-waitlist" (click)="selectReservationType(ReservationType.PrivateLessonBooking)">
  <i class="icon-private_class_booking_icon show-icon show-icon-big"></i>
<span class="waitlist-label" >{{'privateLessonBooking' | translate}}</span>
</div>
<div class="create-waitlist" (click)="selectReservationType(ReservationType.RentalBooking)" *ngIf="partyService.isRentalIntegrated">
  <i class="icon-respos-inventory show-icon"></i>
<span class="waitlist-label" >{{'RentalBooking' | translate}}</span>
</div>
</section>

