import { Injectable } from "@angular/core";
import { RetailUtilities } from "./utilities/retail-utilities";
import { HttpServiceCall, HttpMethod } from "./service/http-call.service";
import { RetailLocalization } from "../common/localization/retail-localization";
import { TransactionLock, TransactionState, BaseResponse } from "../retail.modals";
import { Host, ButtonType, USER, THERAPIST, ServiceChargeGratuityValueType, OpenTransactionAction, GiftCardTransactionType } from "./globalsContant";
import { Gratuity, ServiceCharge, SelectedProducts } from "./shared.modal";
import { RetailPropertyInformation } from "../common/services/retail-property-information.service";
import { GiftCardMethods } from "../payment/PaymentFlowHelpers";
import { Localization } from "src/app/common/localization/localization";
import { Transaction } from "./business/shared.modals";

@Injectable({
  providedIn: 'root'
})

export class RetailValidationService {
  transactionLockId: number = 0;
  TaxValue: number = 0;
  lockedUserName: string = "";
  lockedUserFullName: string = "";
  isLockedCurrentUser:boolean = false;
  constructor(private utils: RetailUtilities, private http: HttpServiceCall, private localization: RetailLocalization, private propertyInfo: RetailPropertyInformation,
    private localize: Localization) {

  }
  async IsTicketInOpenStatus(transactionId: number): Promise<boolean> {
    let isTicketOpen = true;
    let response: any = await this.http.CallApiAsync<any>({
      host: Host.retailPOS,
      callDesc: "CheckTicketStatus",
      method: HttpMethod.Get,
      uriParams: { transactionId: transactionId }
    });
    if (response && !response.successStatus) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(response.result));
      isTicketOpen = false;
    }
    return isTicketOpen;
  }
  
  public async getClerkDetail(clerkId: number): Promise<any> {
    let response: BaseResponse<Transaction> = await this.http.CallApiAsync<Transaction>({
        callDesc: "GetAllUsersbyUserIds",
        host: Host.authentication,
        method: HttpMethod.Get,
        uriParams: { tenantId: this.utils.GetPropertyInfo('TenantId') },
        withQueryString: "ids",
        queryString: { key: "ids", value: [clerkId] }
    });

    if (!response.successStatus) {
        this.utils.showError("Invalid User");
    }
    return response.result;
}

  async IsTransactionLocked(transactionId: number): Promise<boolean> {
    let isLocked = false;
    var lockTransaction = await this.GetTransactionLock(transactionId);
    let currentUser = this.localize.GetsessionStorageValue('_userInfo','userId');
    if(lockTransaction && lockTransaction.length > 0)
    {
      this.transactionLockId = lockTransaction[0].id;
      var lockedByUserID =lockTransaction[0].lockedBy.toString();
      if(currentUser.toLowerCase() == lockedByUserID.toLocaleLowerCase())
      {
        isLocked = false;
        this.isLockedCurrentUser = true;
      }
      else{
        var userDetails = await this.getClerkDetail(lockTransaction[0].lockedBy);
        if(userDetails && userDetails.length > 0)
        {
          this.lockedUserName = userDetails[0].userName; 
          this.lockedUserFullName = userDetails[0].firstName + ' ' + userDetails[0].lastName;
        }
        isLocked = true;     
        this.isLockedCurrentUser = false;   
      }
    }
   
    return isLocked;  
  }

  async GetTransactionLock(transactionId: number): Promise<TransactionLock[]> {
    let tranLock: TransactionLock[] = [];
    let response: any = await this.http.CallApiAsync<any>({
      host: Host.retailPOS,
      callDesc: "GetTransactionLock",
      method: HttpMethod.Get,
      uriParams: { transactionId: transactionId }
    });
    if (response && response.successStatus) {
      tranLock = response.result;
    }
    return tranLock;
  }

  async CheckTransactionState(transactionId: number): Promise<TransactionState> {
    let canSettle = TransactionState.Valid;
    let [isTicketInOpen, isTransactionLocked] = await Promise.all([this.IsTicketInOpenStatus(transactionId), this.IsTransactionLocked(transactionId)]);
    canSettle = isTicketInOpen ? TransactionState.Valid : TransactionState.Invalid;
    if (canSettle == TransactionState.Valid && isTransactionLocked) {
      canSettle = TransactionState.Locked;
    }
    return canSettle;
  }

  async LockTransaction(transactionId: number, overrideExistingLock = false): Promise<number> {
    let lockId = 0;
    let response: any = await this.http.CallApiAsync<any>({
      host: Host.retailPOS,
      callDesc: "CreateTransactionLock",
      method: HttpMethod.Post,
      uriParams: { transactionId: transactionId, overrideExisting: overrideExistingLock }
    });
    if (response && response.successStatus) {
      lockId = response.result;
      this.transactionLockId = lockId;
    }
    return lockId;
  }

  async RemoveTransactionLock(lockId: number, clearLockId = true) {
    if (!lockId) {
      return;
    }
    if (clearLockId) this.transactionLockId = 0;
    await this.http.CallApiAsync<any>({
      host: Host.retailPOS,
      callDesc: "RemoveTransactionLock",
      method: HttpMethod.Delete,
      uriParams: { lockId: lockId }
    });
  }

  async ValidateSettleReopenAction(transactionId: number, action: string, callBack): Promise<boolean> {
    let allow = true;
    var transactionState = await this.CheckTransactionState(transactionId);
    if (transactionState == TransactionState.Invalid) {
      allow = false;
    }
    else if (transactionState == TransactionState.Locked) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.captions.shop.OverrideTransactionLock.replace('{lockedbyuser}', this.lockedUserFullName), ButtonType.YesNo, callBack, [transactionId, action]);
      allow = false;
    }
    return allow;
  }

  async CancelTransaction(transactionId: number): Promise<any> {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.retailPOS,
        callDesc: "CancelTransaction",
        method: HttpMethod.Delete,
        uriParams: { transactionId: transactionId, undoCheckoutAppointment: true }
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async UndoCheckOutAppointmentwithTransaction(transactionId: number): Promise<any> {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.schedule,
        callDesc: "UndoCheckOutAppointmentByTransactionId",
        method: HttpMethod.Put,
        uriParams: { transactionId: transactionId }
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async getRetailItemsDetailedInfoByIds(retailItemIds: any[]) {

    const result = await this.http.CallApiAsync<any>({
      host: Host.retailManagement,
      callDesc: "GetRetailItemDetailedInfoList",
      method: HttpMethod.Put,
      body: retailItemIds
    });

    return result.result;
  }

  async LoadSelectedProducts(transactionData: any, allItems: any[], action: string): Promise<SelectedProducts[]> {
    let excludeDiscount = action && action.toLowerCase() == "correct" && this.propertyInfo.UseRetailInterface;

    const retailItemId = transactionData.map(x => x.itemId);
    let allItemsById = await this.getRetailItemsDetailedInfoByIds(retailItemId);

    let selectedProducts: SelectedProducts[] = []
    for (let transaction of transactionData) {
      let item = allItemsById.filter(x => x.id == transaction.itemId);
      if (action == "settle") {
        this.TaxValue += transaction.tax;
      }
      let newGratuity: Gratuity[] = [];
      let newServiceCharge: ServiceCharge[] = [];
      if (transaction.serviceChargeGratuity && transaction.serviceChargeGratuity.length > 0) {
        let gratuityRows = transaction.serviceChargeGratuity.filter(a => a.totalGratuity > 0);
        for (let grat of gratuityRows) {
          newGratuity.push({
            Id: this.GetCustomStaffId(grat),
            TransactionDetailId: grat.transactionDetailId,
            TherapistId: grat.therapistId,
            Percentage: grat.gratuityPercent,
            PercentageId: 0,
            PercOrAmount: grat.gratuityPercent > 0 ? 1 : 2, //Percentage - 1 , Amount - 2
            Amount: grat.gratuity,
            gratuity: grat.gratuity,
            StaffType: grat.staffType,
            additionalGratuity: grat.additionalGratuity,
            customAmount: grat.additionalGratuity,
            customPercentage: grat.additionalGratuityPercent,
            customPercOrAmount: grat.additionalGratuityPercent > 0 ? 1 : 2
          });
        }

        let scRows = transaction.serviceChargeGratuity.filter(a => a.totalServiceCharge > 0);
        for (let sc of scRows) {
          newServiceCharge.push({
            Id: this.GetCustomStaffId(sc),
            TransactionDetailId: sc.transactionDetailId,
            TherapistId: sc.therapistId,
            Percentage: sc.serviceChargePercent,
            PercentageId: 0,
            PercOrAmount: sc.serviceChargePercent > 0 ? 1 : 2, //Percentage - 1 , Amount - 2
            Amount: sc.serviceCharge,
            ServiceCharge: sc.serviceCharge,
            StaffType: sc.staffType,
            additionalServiceCharge: sc.additionalServiceCharge,
            customAmount: sc.additionalServiceCharge,
            customPercentage: sc.additionalServiceChargePercent,
            customPercOrAmount: sc.additionalServiceChargePercent > 0 ? 1 : 2
          });
        }

      }
      selectedProducts.push({
        ItemDescription: transaction.customFeeId > 0 ? transaction.itemDescription : transaction.clientMultiPackRedeemId > 0 ? this.localization.captions.shop.RedeemingMultipack + item[0].retailItemDetail.itemDescription : item[0].retailItemDetail.itemDescription,
        ProductName: transaction.customFeeId > 0 ? transaction.itemDescription : transaction.clientMultiPackRedeemId > 0 ? this.localization.captions.shop.RedeemingMultipack + item[0].retailItemDetail.itemDescription : item[0].retailItemDetail.itemDescription,
        ItemType: transaction.itemType,
        ServiceId: transaction.serviceId,
        ProductPrice: transaction.unitPrice,
        SalesPrice: transaction.totalAmount,
        ExternalPOSItemId: transaction.externalPOSId,
        ItemId: transaction.itemId,
        Noofitems: transaction.quantitySold,
        Discount: excludeDiscount ? 0 : transaction.discount,
        DiscountPercentage: excludeDiscount ? 0 : transaction.discountPercentage,
        DiscountTypeId: excludeDiscount ? 0 : transaction.discountTypeId,
        isEditDisabled: action == "settle",
        isModificationRestricted: (action == "settle" || transaction.isModificationRestricted),
        Commission: this.SetCustomStaffIdForCommission(transaction.commission),
        isCommissionable: item[0].retailItemDetail.isCommissionable,
        Gratuity: newGratuity,
        ServiceCharge: newServiceCharge,
        isGroupingKey: item[0].retailItemDetail.isGroupingKey,
        isPackagedItem: transaction.packageItemId > 0 ? true : false,
        PackageItemId: transaction.packageItemId,
        MultiPack: transaction.clientMultiPackRedeemId > 0 ? true : false,
        ClientMultiPackId: 0,
        PackageGroupId: 1,
        LineNumber: transaction.lineNumber,
        Tax: transaction.tax,
        isOpenPricedItem: true, //Open Priced Item should always be true as the service may have different price than original price
        id: transaction.id,
        transactionDetailLinkId: transaction.transactionDetailLinkId ? transaction.transactionDetailLinkId : 0,
        isReturn: transaction.isReturn ? transaction.isReturn : false,
        category: item[0].retailItemDetail.category,
        isTaxExempt: transaction.isTaxExempt,
        retailItemType: item[0].retailItemDetail.itemType,
        scaledUnits: transaction.scaledUnit,
        unitOfMeasureId: transaction.unitOfMeasureId,
        uom: '',
        costPrice: transaction.costPrice,
        marginPercentage: transaction.marginPercentage,
        allowEarn: item[0].retailItemDetail.allowEarn,
        discountComments: excludeDiscount ? '' : transaction.discountComments,
        discountReason: excludeDiscount ? 0 : transaction.discountReason,
        sourceType: transaction.sourceType,
        sourceTypeId: transaction.sourceTypeId,
        customFee: transaction.transactionCustomFee,
        linkedItemLineNumber: transaction.linkedCustomFeeTransactionDetailId > 0 ? transactionData.find(x=>x.id == transaction.linkedCustomFeeTransactionDetailId).lineNumber : 0,
      });
    }
    return selectedProducts;
  }

  private SetServiceChargeGratuityValue(serviceChargeGratuity: any[], newObject: any, type: string) {
    if (serviceChargeGratuity && serviceChargeGratuity.length > 0) {
      switch (type) {
        case 'SERVICECHARGE': {
          var sc = serviceChargeGratuity.find(r => r.serviceCharge != 0);
          if (sc) {
            let percentageId = this.utils.GetPercentageId(sc.serviceChargePercent);
            if (percentageId > 0) {
              newObject.PercentageId = percentageId;
              newObject.Percentage = sc.serviceChargePercent;
            }
            else if (sc.serviceChargePercent > 0) {
              newObject.PercOrAmount = ServiceChargeGratuityValueType.CustomPercent;
              newObject.Amount = sc.serviceChargePercent;
            }
            else {
              newObject.PercOrAmount = ServiceChargeGratuityValueType.CustomAmount;
              newObject.Amount = sc.serviceCharge;
            }
          }
          break;
        }
        case 'GRATUITY': {
          var scGratuity = serviceChargeGratuity.find(r => r.gratuity != 0);
          if (scGratuity) {
            let percentageId = this.utils.GetPercentageId(scGratuity.gratuityPercent);
            if (percentageId > 0) {
              newObject.PercentageId = percentageId;
              newObject.Percentage = scGratuity.gratuityPercent;
            }
            else if (scGratuity.gratuityPercent > 0) {
              newObject.PercOrAmount = ServiceChargeGratuityValueType.CustomPercent;
              newObject.Amount = scGratuity.gratuityPercent;
            }
            else {
              newObject.PercOrAmount = ServiceChargeGratuityValueType.CustomAmount;
              newObject.Amount = scGratuity.gratuity;
            }
          }
          break;
        }
      }
    }
  }

  GetCustomStaffId(serviceChargeGratuity: any): string {
    let customId: string = '';

    if (serviceChargeGratuity.staffType == THERAPIST) {
      customId = `T${serviceChargeGratuity.therapistId}`;
    }
    else if (serviceChargeGratuity.staffType == USER) {
      customId = `U${serviceChargeGratuity.therapistId}`;
    }
    return customId;
  }

  SetCustomStaffIdForCommission(commission: any[]): any[] {
    if (!commission || commission.length == 0) {
      return commission;
    }
    commission.forEach(c => {
      if (c.staffType.toUpperCase() == THERAPIST) {
        c.id = `T${c.staffId}`;
      }
      else if (c.staffType.toUpperCase() == USER) {
        c.id = `U${c.staffId}`;
      }
    });
    return commission;
  }

  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  CheckIfLinkedTransactionExists(transactionInfo, action: OpenTransactionAction, isDayEnd = false) {
    let linkedTransExists = false;
    const RWT_TransactionTypes = [
      "RETURN WITH TICKET WITHOUT EXCHANGE",
      "RETURN WITH TICKET WITH EXCHANGE"
    ];
    if (transactionInfo && !transactionInfo.isCorrected && !transactionInfo.isVoided) {
      linkedTransExists = ((RWT_TransactionTypes.includes(transactionInfo.retailTransactionType)
        || (transactionInfo.transactionLinkId > 0
          && (transactionInfo.transactionDetails.some(x => x.transactionDetailLinkId > 0)
            || transactionInfo.transactionDetails.length == 0))));
      if (linkedTransExists && ((action != OpenTransactionAction.Settle) 
        || (this.propertyInfo.UseRetailInterface && action == OpenTransactionAction.Settle))) {
        const errMsgMap = {
          [OpenTransactionAction.Settle]: this.localization.captions.shop.SettleNotAllowedReturnInProgress,
          [OpenTransactionAction.Reopen]: this.localization.captions.shop.ReopenNotAllowedReturnInProgress,
          [OpenTransactionAction.Cancel]: this.localization.captions.shop.CancelNotAllowedReturnInProgress
        }
        const errMsg = errMsgMap[action];
        this.utils.ShowErrorMessage(this.localization.captions.common.Information, errMsg, ButtonType.Ok);
      }
    }
    return linkedTransExists;
  }

  async CheckIfAnyGiftcardIssuedForRefund(TransactionId) {
    let response: any = await this.http.CallApiAsync<any>({
      host: Host.retailPOS,
      callDesc: "PaymentHistory",
      method: HttpMethod.Get,
      uriParams: { transactionId: TransactionId, getAll: true },
    });
    if (response && response.successStatus) {
      const paymentHist = response.result;
      if (paymentHist && paymentHist.length > 0) {
        if (paymentHist.some(x => GiftCardMethods.includes(x.paymentMethodId)
          && x.giftcardTransactionType == GiftCardTransactionType.Issue)) {
          this.utils.ShowErrorMessage(
            this.localization.captions.common.Error,
            this.localization.captions.shop.CanNotVoidAlreadyIssuedAsGiftcard,
            ButtonType.Ok);
          return true;
        }
      }
    }
    return false;
  }
}