import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod, ServiceParamsAsync } from "../../../shared/service/http-call.service";
import { Host, Product } from "../../../shared/globalsContant";
import { RetailLocalization } from "../../../common/localization/retail-localization";
import { Observable } from "rxjs";
import { RetailUtilities } from "../../../shared/utilities/retail-utilities";
import { RetailShopItem, TransactionData, ApplyDiscount } from "../../shop.modals";
import { Ticket, ServiceChargeGratuity, CheckData, CommonVariablesService } from "../../../shared/service/common-variables.service";
import { RetailPropertyInformation } from "../../../common/services/retail-property-information.service";
import { BaseResponse } from "../../../shared/business/shared.modals";
import { MultiPackDetails, RetailItemType } from "../../../../retail/retail.modals";
import * as _ from 'lodash';
import { GiftCardPaymentRecord } from 'src/app/retail/shared/service/payment/payment-business.model';
import { TransactionEngineBusiness } from "src/app/retail/retail-transaction-engine/transaction-engine-business";


@Injectable()
export class RetailTransactionService {
    private readonly captions = this.localization.captions.shop;
    public ClosedTransactions: any[] = [];
    constructor(private http: HttpServiceCall, private localization: RetailLocalization, private utils: RetailUtilities,
        private PropertyInfo: RetailPropertyInformation, public _shopService: CommonVariablesService,
        private transactionEngineBusiness: TransactionEngineBusiness) {

    }

    getUserOutletSubProperty(): Observable<any> {

        let serviceParams: ServiceParamsAsync[] = [{
            callDesc: "GetOutletsByProperty",
            host: Host.retailManagement,
            method: HttpMethod.Get,
            uriParams: { propertyId: this.http.GetPropertyInfo("PropertyId") }
        },
        {
            callDesc: "GetSubPropertyAccessByUser",
            host: Host.retailManagement,
            method: HttpMethod.Get,
            uriParams: { userId: this.http.GetPropertyInfo("UserId") }
        }];

        return this.http.WaitForHttpCalls(serviceParams);
    }

    async CreateTicket(selectedProducts: any, isSettleOpenTransaction: boolean = false): Promise<Ticket> {
        let shopItems: RetailShopItem[] = [];
        let LineNumber: number = 1;
        let index = 0;
        for (const element of selectedProducts) {
            var servCharge: ServiceChargeGratuity[] = [];
            let totalGratuity = 0;
            let totalSc = 0;
            if (element.Gratuity && element.Gratuity.length > 0) {
                var allGratuities: ServiceChargeGratuity[] = [];
                for (const grat of element.Gratuity) {
                    if (grat.gratuity != 0) {
                        allGratuities.push({
                            Date: this.utils.convertDateFormat(this.PropertyInfo.CurrentDate),
                            TherapistId: grat.TherapistId,
                            ItemId: element.ItemId ? element.ItemId : 0,
                            ServiceChargePercent: 0,
                            GratuityPercent: grat.Percentage ? grat.Percentage : 0,
                            ServiceCharge: 0,
                            Gratuity: grat.gratuity,
                            Id: 0,
                            TransactionDetailId: grat.TransactionDetailId ? grat.TransactionDetailId : 0,
                            TotalGratuity: 0,
                            TotalServiceCharge: 0,
                            StaffType: element.ItemType != RetailItemType.RevenueItem ? grat.StaffType : "USER"
                        });
                        totalGratuity += grat.gratuity;
                    }
                }
                allGratuities.forEach(r => r.TotalGratuity = totalGratuity);
                servCharge.push(...allGratuities);
            }

            if (element.ServiceCharge && element.ServiceCharge.length > 0) {
                var allServiceCharges: ServiceChargeGratuity[] = [];
                for (const sc of element.ServiceCharge) {
                    if (sc.ServiceCharge != 0) {
                        allServiceCharges.push({
                            Date: this.utils.convertDateFormat(this.PropertyInfo.CurrentDate),
                            TherapistId: sc.TherapistId,
                            ItemId: element.ItemId ? element.ItemId : 0,
                            ServiceChargePercent: sc.Percentage ? sc.Percentage : 0,
                            GratuityPercent: 0,
                            ServiceCharge: sc.ServiceCharge,
                            Gratuity: 0,
                            Id: 0,
                            TransactionDetailId: sc.TransactionDetailId ? sc.TransactionDetailId : 0,
                            TotalGratuity: 0,
                            TotalServiceCharge: 0,
                            StaffType: element.ItemType != RetailItemType.RevenueItem ? sc.StaffType : "USER"
                        });
                        totalSc = sc.ServiceCharge;
                    }
                }
                allServiceCharges.forEach(r => r.TotalServiceCharge = totalSc);
                servCharge.push(...allServiceCharges);
            }
            let discountModel: ApplyDiscount = {
                index: index,
                isPercentage: element.DiscountTypeId > 0,
                value: element.DiscountTypeId > 0 ? element.DiscountPercentage : element.Discount
            };

            let obj: RetailShopItem = {
                ItemId: element.ItemId,
                ItemDescription: element.ItemDescription,
                ItemType: element.ItemType,
                ExternalPOSItemId: element.ExternalPOSItemId,
                QuantitySold: element.Noofitems,
                UnitPrice: element.ProductPrice,
                Discount: element.Discount,
                DiscountTypeId: element.DiscountTypeId,
                DiscountPercentage: element.DiscountPercentage,
                StaffTransactionDetail: element.StaffTransactionDetail,
                LineNumber: element.isGroupingKey && !element.isPackagedItem ? 0 : LineNumber,
                Tax: 0,
                BaseTax: 0,
                LinkedTax: 0,
                TotalAmount: element.Noofitems * element.ProductPrice,
                OutletId: this._shopService.SelectedOutletId,
                IsGroupingKey: element.isGroupingKey,
                IsPackagedItem: element.isPackagedItem,
                PackageItemId: element.PackageItemId,
                IsMultiPackRedeem: element.MultiPack,
                IsModificationRestricted: element.isModificationRestricted ? element.isModificationRestricted : false,
                ClientMultiPackId: element.ClientMultiPackId,
                PackageGroupId: element.PackageGroupId,
                IsOpenPricedItem: element.isOpenPricedItem,
                id: isSettleOpenTransaction ? element.id : 0,
                IsTaxExempt: element.IsTaxExempt,
                discountModel: discountModel,
                itemComments: element.itemComments,
                costPrice: element.costPrice,
                marginPercentage: element.marginPercentage,
                allowEarn: element.allowEarn,
                discountComments: element.discountComments,
                discountReason: element.discountReason,
                GroupingParentId: element.GroupingParentId ? element.GroupingParentId : 0,
                GroupingUniqueIdentifier: element.GroupingUniqueIdentifier ? element.GroupingUniqueIdentifier : null,
                GroupingItemDescription: element.GroupingItemDescription ? element.GroupingItemDescription : '',
                IsMultiPack: element.MultiPack,
            }
            if (element.Commission && element.Commission.length > 0) {
                obj.Commission = _.cloneDeep(element.Commission);
                obj.Commission.forEach(r => r.id = 0);
            }
            if (servCharge)
                obj.ServiceChargeGratuity = servCharge;

            shopItems.push(obj);

            if (!element.isGroupingKey || element.isPackagedItem) {
                index++;
            }

        }

        let body: TransactionData = {

            Id: 0,
            TransactionType: "Retail",
            TransactionDate: this.utils.convertDateFormat(this.PropertyInfo.CurrentDTTM),
            ClerkId: 1,
            TotalPrice: 0,
            TotalTax: 0,
            TotalAmount: 0,
            GuestId: 0,
            MemberId: "0",
            Comment: "",
            StayId: 0,
            IsTaxExempt: false,
            IsVoided: false,
            shopItems: shopItems,
            OutletId: this._shopService.SelectedOutletId,
            MachineNameId: this._shopService.ProductId == Product.PMS ? 0 : this.localization.GetMachineId(),
            TicketNumber: "",
            ProductId: this._shopService.ProductId,
            TerminalId: this._shopService.SelectedTerminalId,
            PatronId: "",
            GuestGuid: null,
            IsExcludeDiscountOnGratuity: this._shopService.isExcludeDiscOnGratuity,
            IsExcludeDiscountOnServiceCharge: this._shopService.isExcludeDiscOnServiceCharge,
            UserId: Number(this.localization.GetUserInfo('userId'))
        }

        let apiResponse: BaseResponse<Ticket> = await this.InvokeServiceCallAsync("CreateTicket", Host.retailPOS, HttpMethod.Post, '', body, true);
        return apiResponse.result;
    }

    async AddTicketTax(ticketNumber: any, checkHandleGuid: any, outletId: number): Promise<Ticket> {
        var params = {
            ticketNumber: ticketNumber,
            checkHandleGuid: checkHandleGuid,
            outletId: outletId,
            terminalId: this._shopService.SelectedTerminalId
        }
        let apiResponse: BaseResponse<Ticket> = await this.InvokeServiceCallAsync("AddTicketTax", Host.retailPOS, HttpMethod.Put, params);
        return apiResponse.result;
    }

    IsSPAAppointmentRelatedRetailItem(SelectedProduct: any) {
        return SelectedProduct.retailItemType &&
            (
                SelectedProduct.retailItemType === RetailItemType.SpaServices ||
                SelectedProduct.retailItemType === RetailItemType.SpaPackage ||
                SelectedProduct.retailItemType === RetailItemType.RetailItemAvailableForSpaPackages ||
                SelectedProduct.retailItemType === RetailItemType.Deposit ||
                SelectedProduct.retailItemType === RetailItemType.AppointmentAddon
            );
    }

    UpdatePackagedItemDiscount(shopItems: any[]) {
        var discountModel: ApplyDiscount[] = []
        var parentPackagedItemList: any[] = shopItems.filter(x => !x.isPackagedItem && x.PackageGroupId > 0);
        for (const element of parentPackagedItemList) {
            let childElements: any[] = shopItems.filter(x => x.isPackagedItem && x.PackageGroupId == element.PackageGroupId);
            if (childElements && childElements.length > 0) {
                let totalDiscount: number = childElements.map(x => (x.Discount ? x.Discount : 0)).reduce((a, b) => { return a + b }, 0);
                element.Discount = totalDiscount;
                element.DiscountTypeId = 0;
                element.DiscountPercentage = 0;
                let index = this._shopService.findParentPackageIndex(childElements[0].LineNumber, this._shopService.selectedProducts);
                element.discountModel = {
                    index: index,
                    isPercentage: false,
                    value: totalDiscount
                };
                discountModel.push(element.discountModel);
            }
        }
        return discountModel;
    }

    getPackageTotalDiscount(packageGroupId: number): number {
        let amount = 0;
        let childElements: any[] = this._shopService.selectedProducts.filter(x => x.isPackagedItem && x.PackageGroupId == packageGroupId);
        if (childElements && childElements.length > 0) {
            amount = childElements.map(x => (x.Discount ? x.Discount : 0)).reduce((a, b) => { return a + b }, 0);
        }
        return amount;
    }

    async DiscardCheck(checkData: CheckData) {
        if (!checkData || !checkData.checkNumber) {
            return;
        }
        let uriParams = {
            ticketNumber: checkData.checkNumber,
            outletId: this._shopService.SelectedOutletId,
            checkHandleGuid: checkData.checkHandleGuid,
            terminalId: this._shopService.SelectedTerminalId
        };
        await this.InvokeServiceCallAsync("CancelTicket", Host.retailPOS, HttpMethod.Post, uriParams);
    }

    async UndoCheckOut(transactionId: number) {
        await this.InvokeServiceCallAsync('UndoCheckOutAppointmentByTransactionId', Host.schedule, HttpMethod.Put, { transactionId: transactionId });

    }

    async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any, showError: boolean = false): Promise<BaseResponse<any>> {
        try {
            return await this.http.CallApiAsync({
                host: domain,
                callDesc: route,
                method: callType,
                body: body,
                uriParams: uriParams,
                showError: showError
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }
    async CalculateCommission(transactionId: number, transactionDate: any) : Promise<BaseResponse<any>> {
        let uriParams = {
            transactionId: transactionId,
            transactionDate: transactionDate,
            outletId: this._shopService.SelectedOutletId,
            productId: this._shopService.ProductId
        };
        let body = this._shopService.SelectedPlayers.length > 0 ? { firstName : this._shopService.SelectedPlayers[0].firstName ,
            lastName : this._shopService.SelectedPlayers[0].lastName,  
            taxNumber : this._shopService.SelectedPlayers[0].taxNumber
         } : null;
        return this.InvokeServiceCallAsync("CloseTransaction", Host.retailPOS, HttpMethod.Put, uriParams,body);
}

    async DeleteTransactionRecord(transactionId: number) {
        let uriParams = {
            transactionId: transactionId,
        };
        this.InvokeServiceCallAsync("DeleteTransaction", Host.retailPOS, HttpMethod.Delete, uriParams);
    }

    async UpdateCaddyShackComment(transactionId: number, caddyshackcomment?: string) {
        let uriParams = {
            transactionId: transactionId,
        };
        this.InvokeServiceCallAsync("UpdateCaddyShackComment", Host.retailPOS, HttpMethod.Put, uriParams, caddyshackcomment);
    }

    async SaveTicket(ticketNumber: string, checkHandleGuid: any, outletId: number) {
        let uriParams = {
            ticketNumber: ticketNumber,
            checkHandleGuid: checkHandleGuid,
            outletId: outletId,
            terminalId: this._shopService.SelectedTerminalId
        };
        this.InvokeServiceCallAsync("SaveTicket", Host.retailPOS, HttpMethod.Post, uriParams);
    }

    GetNetValue(itemValue: number, externalPOSId: number, ticket: Ticket, index: number, itemId: number = 0): number {
        let netUnitPrice = itemValue;
        if (ticket) {
            if (this.PropertyInfo.UseRetailInterface && ticket && ticket.lineItems && ticket.lineItems.length > 0) {
                const lineItem = ticket.lineItems.find(r => r.externalPOSItemId === externalPOSId && r.index === index);
                netUnitPrice = lineItem ? lineItem.netPrice : itemValue;
            }
            else {
                const lineItem = ticket.lineItems.find(r => r.itemId === itemId && r.index === index);
                netUnitPrice = lineItem ? lineItem.netPrice : itemValue;
            }
        }
        return netUnitPrice;
    }
    GetNetUnitValueWithoutDiscount(itemValue: number, externalPOSId: number, ticket: Ticket, index: number, itemId: number = 0): number {
        let netUnitPriceWithoutDiscount = itemValue;
        if (ticket) {
            if (this.PropertyInfo.UseRetailInterface && ticket && ticket.lineItems && ticket.lineItems.length > 0) {
                const lineItem = ticket.lineItems.find(r => r.externalPOSItemId === externalPOSId && r.index === index);
                netUnitPriceWithoutDiscount = lineItem ? lineItem.netUnitPriceWithoutDiscount : itemValue;
            }
            else {
                const lineItem = ticket.lineItems.find(r => r.itemId === itemId && r.index === index);
                netUnitPriceWithoutDiscount = lineItem ? lineItem.netUnitPriceWithoutDiscount : itemValue;
            }
        }
        return netUnitPriceWithoutDiscount;
    }

    async CreateTicketForItem(item: RetailShopItem[], terminalId: number = 0): Promise<Ticket> {
        let body: TransactionData = {
            Id: 0,
            TransactionType: "Retail",
            TransactionDate: this.utils.convertDateFormat(this.PropertyInfo.CurrentDTTM),
            ClerkId: 1,
            TotalPrice: 0,
            TotalTax: 0,
            TotalAmount: 0,
            GuestId: 0,
            MemberId: "0",
            Comment: "",
            StayId: 0,
            IsTaxExempt: false,
            IsVoided: false,
            shopItems: item,
            OutletId: this._shopService.SelectedOutletId,
            MachineNameId: this._shopService.ProductId == Product.PMS ? 0 : this.localization.GetMachineId(),
            TicketNumber: "",
            ProductId: Product.SPA,
            TerminalId: this._shopService.SelectedTerminalId == 0 ? terminalId : this._shopService.SelectedTerminalId,
            PatronId: "",
            GuestGuid: null,
            UserId: Number(this.localization.GetUserInfo('userId'))
        }
        let apiResponse: BaseResponse<Ticket> = await this.InvokeServiceCallAsync("CreateTicket", Host.retailPOS, HttpMethod.Post, '', body);
        return apiResponse.result;
    }

    async AddAndRemoveTicketItems(ticket: Ticket): Promise<Ticket> {
        let apiResponse: BaseResponse<Ticket> = await this.InvokeServiceCallAsync("AddAndRemoveTicketItems", Host.retailPOS, HttpMethod.Post,
            { outletId: this._shopService.SelectedOutletId, terminalId: this._shopService.SelectedTerminalId }, ticket);
        return apiResponse.result;
    }

    async AddGiftCardPaymentRecord(giftCardPaymentRecord: GiftCardPaymentRecord[]) {
        let apiResponse: BaseResponse<Ticket> = await this.InvokeServiceCallAsync("GiftCardPaymentRecord", Host.payment, HttpMethod.Post, null, giftCardPaymentRecord);
        return apiResponse.result;
    }
    /**
   * @description Retrieves the Multi Pack Retail Items
   */
    async GetMultiPackDetailsByTransactionDetailIds(multiPackTransactionDetailId: number[]): Promise<MultiPackDetails[]> {
        let apiResponse: BaseResponse<MultiPackDetails[]> = await this.InvokeServiceCallAsync("GetTeeTimeMultiPack", Host.retailPOS, HttpMethod.Put, null, multiPackTransactionDetailId);
        return apiResponse.result;
    }
    /**
    * @description Retrieves the retail transaction based on transactionIds
    * @param transactionIds number[]
    */
    async GetTransactionsByIds(transactionIds: number[]) {
        let apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('Transaction/GetTransactionsByIds', Host.retailPOS, HttpMethod.Put, null, transactionIds)
        return apiResponse.result;
    }
}
