import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { InputField } from '../input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GformControlErrorsPipe } from '../../pipes/gform-control-errors.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { SelectOption } from '../../interfaces/form';

@Component({
  selector: '[lib-input-select]',
  standalone: true,
  imports: [MatSelectModule, FormsModule, ReactiveFormsModule, CommonModule, GformControlErrorsPipe, TranslateModule],
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSelectComponent extends InputField {
  @Input() options?: SelectOption[] = [];
  @Input() sortBy2: string | null = 'subtext';
  @Input() showNone: boolean = false;

}
