<section class='assignCommision h-100'>
  <mat-dialog-content class="p-0">
    <mat-tab-group class="h-100" (selectedTabChange)="selectedTabChange($event)" #tabGroup [selectedIndex]="((this.data.data[0] && this.isCommissionAllowed) || (this.data.data[0] && this.data.data[0][0] && this.data.data[0][0].isCommissionable))?'0':'1'">
      <mat-tab label={{captions.CommissionSetup}} [disabled]='!((this.data.data[0] && this.isCommissionAllowed) || (this.data.data[0] && this.data.data[0][0] && this.data.data[0][0].isCommissionable))'>
        <ng-template matTabContent>
        <div *ngIf="this.ApplyCommissionAccess" class="pl-4" [ngClass]="defaultCommission == 'SC'? 'commission-data' : 'h-100'">
          <div class="productTitle">
            <span class="LW12 label-color">{{itemName | uppercase}}</span>
          </div>
          <div class="alertClient" *ngIf="gratuityStaffName.length > 0">
            <i  aria-hidden="true" class="icon-alert"></i>
            <span class="clientName LW16 value-color"> {{captions.StaffAssociatedOnTransaction}} :
              {{gratuityStaffName.join(', ')}}</span>
          </div>
          <div class="commissionOptions radio-class">
            <mat-radio-group [attr.automationId]="'Rdo_assignCommissionPopup_commission'" class="golf__radio" [value]="defaultCommission" [(ngModel)]="defaultCommission" (change)='commissionChange()'>
              <mat-radio-button class="pr-3 golf--form-radio-button" *ngFor="let commission of commissionOptions" [value]="commission.key">{{commission.value}}</mat-radio-button>
            </mat-radio-group>
          </div>  
          <!-- Clerk for Commission -->
          <div class="splitCommission">
            <div class="d-flex applyCommissionTemplate" *ngIf="defaultCommission == 'SC'">
              <div>
                <mat-form-field class="splitOptions" [floatLabel]="floatLabel">
                  <mat-select [attr.automationId]="'Dd_assignCommissionPopup_commissionTemplate'" [(ngModel)]='selectedTemplate' (selectionChange)='updateCommission()'
                    [placeholder]="captions.SelectCommissionTemplate"
                    autofocus required>
                    <mat-option *ngFor="let template of splitTemplate" [value]="template">{{template.templateName}}</mat-option>
                  </mat-select>
                  <mat-error>{{this.captions.missingCommissionTemplate}}</mat-error>
                </mat-form-field>
              </div>
              <div class="staffMessage" *ngIf="selectedTemplate">
                <span>({{this.captions.requires}} {{requiredStaff}} {{this.captions.selectedTherapist}})</span>
              </div>
            </div>
            <ng-scrollbar >
              <div class="staffMembers d-flex flex-wrap" [ngClass]="{'disabled' : defaultCommission == 'SC' && selectedTemplate == null}">
                <div *ngFor="let staff of staffArray; let i=index" class="LW14 staffDetails d-flex" [ngClass]="{'disabled': defaultCommission == 'SC' && staff.isStaffSelected == false && selectedStaff.length != 0 && selectedStaff.length == requiredStaff}">
                  <div>
                    <span class="pl-2"> 
                      <app-retail-img-thumbnail class="app-retail-img-thumbnail" [imageContent]="staff.imgObj" [thumbnail]="true" imageType="therapist"></app-retail-img-thumbnail>
                    </span>
                  </div>
                  <div class="staffSelect">
                    <p class="LW14 blackcolor therapist-name">{{staff.firstName}} {{staff.lastName}}</p>
                    <mat-form-field class="LW14 float-label-box" [floatLabel]="floatLabel">
                      <mat-select attr.automationId='Dd_assignCommissionPopup_staffSelect{{i}}' [disabled]="defaultCommission == 'CC' || !staff.isStaffSelected" placeholder={{captions.SelectLevel}}
                        name="level" [(ngModel)]="staff.staffLevel" (selectionChange)='SplitLevelChange($event, staff.id)'>
                        <mat-option *ngFor="let level of splitLevels" [value]="level.id">{{level.levelDescription}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="staffSelection">
                    <span attr.automationId='Icn_assignCommissionPopup_staffSelection{{i}}' (click)="this.editModeDirty.commissionDirty = true;selectStaff(staff)" [ngClass]="{disabled: defaultCommission == 'SC' && selectedTemplate == null && selectedStaff.length != 0 && selectedStaff.length == requiredStaff && staff.isStaffSelected == false }">
                      <a>
                        <i aria-hidden="true" [ngClass]="staff.isStaffSelected ? 'icon-SuccessDark text-color pt-2': 'icon-Success pt-2'"
                          class="float-right"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </ng-scrollbar>
          </div>
        </div>
        </ng-template>
      </mat-tab>
      <mat-tab label={{captions.GratuityServiceCharges}} [disabled]="disableServiceChargeGratuity" class="h-100">
        <ng-template matTabContent>
        <ng-scrollbar >
          <div [ngClass]="disableServiceChargeGratuity ? 'button_invalid' : ''" (keydown)="stopdefaultClick($event)">
          <div class="gratuityDetails h-100" >
            <div class="gratuityOptions p-4">
              <div >
                <label class="LW12 label-color">{{captions.SelectGratuityServicesCharge}}</label>
                <div class="gratuity pt-3">
                  <button attr.automationId='Btn_assignCommissionPopup_chooseGratuity{{i}}' mat-raised-button *ngFor="let gratuity of gratuityOptions; let i=index" [ngClass]="selectedGratuity.id == gratuity.id?'body-bordercolor LW14 selected':'body-bordercolor LW14'"
                    (click)="chooseGratuity($event, 'gratuity',gratuity)">{{gratuity.value}}</button>
                </div>
              </div>
            </div>
            <div class="gratuityDesc p-4">
              <div>
                <div class="valueLabel">
                  <label class="LW12 label-color">{{captions.Values}}</label>
                </div>
                <div class="itemValues d-flex">
                  <div>
                    <div class="LW12 label-color">{{captions.Item}}</div>
                    <div class='pt-2 LW16 value-color'>{{itemValues[0].item}}</div>
                  </div>
                  <div>
                    <div class="LW12 label-color">{{captions.Type}}</div>
                    <div class='pt-2 LW16 value-color'>{{itemValues[0].type}}</div>
                  </div>
                  <div>
                    <div class="LW12 label-color">{{captions.Itemvalue}}</div>
                    <div class='pt-2 LW16 value-color'>{{ShowPrice(itemValues[0].itemDisplayValue,itemValues[0].isReturn)}}</div>
                  </div>
                  <div>
                    <div class="LW12 label-color">{{captions.GratuityApprox}}</div>
                    <div class='pt-2  LW16 value-color'>{{displayGratScPrice(enumType.Gratuity)}}</div>
                  </div>
                  <div>
                    <div class="LW12 label-color">{{captions.ServiceChargeApprox}}</div>
                    <div class='pt-2 LW16 value-color'>{{displayGratScPrice(enumType.ServiceCharge)}}</div>
                  </div>
                </div>
              </div>
              <div class="staffOptions">
                <div *ngIf="selectedGratuity.id == 1">
                  <div *ngFor="let StaffDet of GS_AddedStaff[0].NumofStaffAdded;let i=index ;let f = first;let l=last;">
                    <div class="ag_display--inblock w-30">
                      <mat-form-field [floatLabel]="floatLabel">
                        <mat-select attr.automationId='Dd_assignCommissionPopup_gratuitySelect{{i}}' placeholder={{captions.SelectStaff}} (selectionChange)="this.editModeDirty.gratuityDirty = true;staffSelected($event,StaffDet, i)"
                          [value]="StaffDet.selectedIndexStaff">
                          <mat-option *ngFor="let staff of StaffDet.staffArray" [value]="staff">{{staff.firstName}}
                            {{staff.lastName}}</mat-option>
                        </mat-select>
                      </mat-form-field> 
                      <i attr.automationId='Icn_assignCommissionPopup_plus{{i}}' aria-hidden="true" class="icon-Minus" (click)="addremove('R',StaffDet,1)" *ngIf="!f || (f && !l)"></i>
                      <i attr.automationId='Icn_assignCommissionPopup_minus{{i}}' aria-hidden="true" class="icon-Plus" (click)="addremove('A',StaffDet,1)" *ngIf="l && (this.gratuityStaffList.length > i+1)"></i>
                      <i aria-hidden="true" class="icon-empty-space" *ngIf="!l"></i>
                    </div>
                    <!-- <button mat-button class="LW14 gratuity-serviceCharge" (click)="togglebuttonVal(enumType.Gratuity,i)">{{valueSymbol(StaffDet.isPercentage)}}</button> -->
                    <mat-button-toggle-group [attr.automationId]="'Tog_assignCommissionPopup_percentage'" class="" (change)="togglebuttonVal(enumType.Gratuity,i)">
                      <mat-button-toggle [checked]="StaffDet.isPercentage">{{valueSymbol(true)}}</mat-button-toggle>
                      <mat-button-toggle [checked]="!StaffDet.isPercentage">{{valueSymbol(false)}}</mat-button-toggle>
                   </mat-button-toggle-group>
                    <mat-form-field class="LW14 pl-4 therapist-input mr-4" [floatLabel]="floatLabel">
                      <input [attr.automationId]="'Txt_assignCommissionPopup_therapistValue'" matInput [(ngModel)]="StaffDet.value" name="therapistValue" type='text' RetailCurrencyFormatter [skipOnInit]= 'true'  [preDecimalLength] ='gratuityServiceChargeMaxlength'
                        placeholder={{customValueplaceholder}} id="therapist-commission-{{i}}"
                        (keyup)="CalculateAmount(enumType.Gratuity)" (focusin) ="setFocusIndex(i, false, enumType.Gratuity)">
                    </mat-form-field>
                    <a class="ag_link" (click)="enableAdditionGratuity(StaffDet)" [ngClass]="localization.currencyToSQLFormat(StaffDet.value) > 0? '': 'ag_section--disable'" *ngIf="!StaffDet.isAdditionalAdded">{{captions.lbl_AdditionalGratuity}}</a>
                    <mat-button-toggle-group *ngIf="StaffDet.isAdditionalAdded"  [attr.automationId]="'Tog_assignCommissionPopup_custompercentage'" class="" (change)="togglebuttonVal(enumType.Gratuity,i,StaffDet.isAdditionalAdded)">
                      <mat-button-toggle [checked]="StaffDet.isCustomPercentage">{{valueSymbol(true)}}</mat-button-toggle>
                      <mat-button-toggle [checked]="!StaffDet.isCustomPercentage">{{valueSymbol(false)}}</mat-button-toggle>
                   </mat-button-toggle-group>
                    <mat-form-field *ngIf="StaffDet.isAdditionalAdded" class="LW14 pl-4 therapist-input" [floatLabel]="floatLabel">
                      <input [attr.automationId]="'Txt_assignCommissionPopup_customtherapistValue'" matInput [(ngModel)]="StaffDet.Customvalue" name="customTherapistValue" type='text' RetailCurrencyFormatter [skipOnInit]= 'true'  [preDecimalLength] ='gratuityServiceChargeMaxlength'
                        placeholder={{customValueplaceholder}} id="therapist-commission-{{i}}"
                        (keyup)="CalculateAmount(enumType.Gratuity, true)" (focusin) ="setFocusIndex(i, true, enumType.Gratuity)">
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="selectedGratuity.id == 2">  
                  <div *ngFor="let StaffDet of GS_AddedStaff[1].NumofStaffAdded;let i=index ;let f = first;let l=last">
                    <div class="ag_display--inblock w-30">
                      <mat-form-field [floatLabel]="floatLabel">
                        <mat-select attr.automationId='Dd_assignCommissionPopup_gratuitySelect{{i}}' required placeholder={{captions.SelectStaff}} (selectionChange)="this.editModeDirty.serviceChargeDirty = true;staffSelected($event,StaffDet, i)"
                          [value]="StaffDet.selectedIndexStaff">
                          <mat-option *ngFor="let staff of StaffDet.staffArray" [value]="staff">{{staff.firstName}}
                            {{staff.lastName}}</mat-option>
                        </mat-select>
                      </mat-form-field> 
                      <i attr.automationId='Icn_assignCommissionPopup_gratuityMinus{{i}}' aria-hidden="true" class="icon-Minus" (click)="addremove('R',StaffDet,2)"  *ngIf="!f || (f && !l)"></i>
                      <i attr.automationId='Icn_assignCommissionPopup_gratuityPlus{{i}}' aria-hidden="true" class="icon-Plus" (click)="addremove('A',StaffDet,2)"  *ngIf="l && (this.serviceStaffList.length > i+1)"></i>
                      <i  aria-hidden="true" class="icon-empty-space" *ngIf="!l"></i>
                    </div>
                    <!-- <button mat-button class="LW14 gratuity-serviceCharge" (click)="togglebuttonVal(enumType.ServiceCharge,i)">{{valueSymbol(StaffDet.isPercentage)}}</button>                     -->
                    <mat-button-toggle-group attr.automationId='Tog_assignCommissionPopup_serviceChargePercentage{{i}}' class="" (change)="togglebuttonVal(enumType.ServiceCharge,i)">
                      <mat-button-toggle [checked]="StaffDet.isPercentage">{{valueSymbol(true)}}</mat-button-toggle>
                      <mat-button-toggle [checked]="!StaffDet.isPercentage">{{valueSymbol(false)}}</mat-button-toggle>
                   </mat-button-toggle-group>
                    <mat-form-field class="LW14 pl-4 therapist-input mr-4" [floatLabel]="floatLabel">
                      <input matInput attr.automationId='Txt_assignCommissionPopup_therapistValue{{i}}' [(ngModel)]="StaffDet.value" name="therapistValue" type='text' RetailCurrencyFormatter [skipOnInit]= 'true' [preDecimalLength] ='gratuityServiceChargeMaxlength'
                        placeholder={{customValueplaceholder}} id="therapist-commission-{{i}}"
                        (keyup)="CalculateAmount(enumType.ServiceCharge)" (focusin) ="setFocusIndex(i, false, enumType.ServiceCharge)">
                    </mat-form-field> 
                    <a class="ag_link" (click)="enableAdditionalSCharges(StaffDet)" [ngClass]="localization.currencyToSQLFormat(StaffDet.value) > 0? '': 'ag_section--disable'" *ngIf="!StaffDet.isAdditionalAdded">{{captions.lbl_AdditionalServicecharges}}</a>
                    <mat-button-toggle-group *ngIf="StaffDet.isAdditionalAdded" attr.automationId='Tog_assignCommissionPopup_serviceChargePercentage{{i}}' class="" (change)="togglebuttonVal(enumType.ServiceCharge,i,StaffDet.isAdditionalAdded)">
                      <mat-button-toggle [checked]="StaffDet.isCustomPercentage">{{valueSymbol(true)}}</mat-button-toggle>
                      <mat-button-toggle [checked]="!StaffDet.isCustomPercentage">{{valueSymbol(false)}}</mat-button-toggle>
                   </mat-button-toggle-group>
                    <mat-form-field *ngIf="StaffDet.isAdditionalAdded" class="LW14 pl-4 therapist-input" [floatLabel]="floatLabel">
                      <input matInput attr.automationId='Txt_assignCommissionPopup_therapistValue{{i}}' [(ngModel)]="StaffDet.Customvalue" name="customTherapistValue" type='text' RetailCurrencyFormatter [skipOnInit]= 'true' [preDecimalLength] ='gratuityServiceChargeMaxlength'
                        placeholder={{customValueplaceholder}} id="therapist-commission-{{i}}"
                        (keyup)="CalculateAmount(enumType.ServiceCharge, true)" (focusin) ="setFocusIndex(i, true, enumType.ServiceCharge)">
                    </mat-form-field> 
                  </div>
                </div>
              </div>
              <div class="commissionPercentage d-flex mt-2">
                <div class='preset'>
                  <label class='LW12 label-color'>{{captions.PresetPrecentage}}</label>
                  <button attr.automationId='Btn_assignCommissionPopup_commissionPercentage{{i}}' mat-raised-button *ngFor="let perset of presetPercentage; let i=index" class="body-bordercolor LW14 mb-2"
                    (click)="chooseGratuity($event,'preset', perset)" [ngClass]="[perset.isSelected ? 'highlight-btn':'']">{{perset.value}}</button>
                </div>
                <div class='customvalue' *ngIf="false">
                  <div class="custom-group">
                    <label class='LW12 label-color'>{{captions.CustomValues}}</label>
                     
                    <div class="d-inline toggleButton"><button mat-raised-button attr.automationId='Btn_assignCommissionPopup_chooseGratuity{{i}}' *ngFor="let value of customValue;let i =index" class="LW14" [ngClass]="[selectedValue.id == value.id?'body-bgcolor whitecolor mb-2 body-bordercolor':'body-bordercolor LW14 mb-2' , i==0 ? '':'ml-0',value.id == 1 ? 'percentButton' : 'AmtButton'  ]"
                    [value]="value.id" (click)="chooseGratuity($event,'customvalue',value)">{{value.value}}</button>
                  </div>
                    <mat-form-field class="LW14 percentInput" [floatLabel]="floatLabel">
                      <input [attr.automationId]="'Txt_assignCommissionPopup_saveAmount'" matInput [(ngModel)]="percentValue" name="percentValue" type='text' RetailCurrencyFormatter [preDecimalLength] ='gratuityServiceChargeMaxlength'
                        placeholder={{customValueplaceholder}} [disabled]="ispercentValueDisabled"
                        (keyup)="serviceChargeGratuitycalculator($event)" (blur)="saveAmount($event)">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </ng-scrollbar>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div>
      <button mat-button [attr.automationId]="'Btn_assignCommissionPopup_save'" class="mat-raised-button LW14 ml-3 text-capital" (click)="save()" [ngClass]="isAddDisabled()? 'button--disabled':'button--primary-save'"
        [disabled]="isAddDisabled()"> {{ (tabGroup && tabGroup.selectedIndex == 0) ? captions.ADD :
        captions.Save}}</button>
      <button mat-button [attr.automationId]="'Btn_assignCommissionPopup_cancel'" class="LW14 ml-2 text-capital" (click)="onCancelClick()">{{captions.Cancel}}</button>
    </div>
  </mat-dialog-actions>
</section>
