/**
 * THIS SERVICE IS ONLY USED FOR RETAIL MODULE INTERACION.
 * DONT USE THIS SERVICE FOR ANY OTHER IMPLEMENTATION.
 */

import { SelectedProducts, ScheduledTeeTimeUnPaidPlayer } from './shared.modal';
import { Injectable } from '@angular/core';
import * as GlobalConst from './globalsContant';
import { DepositEventModel } from './events/event.model';
import { RedirectToModules } from './utilities/retail-utilities';
import { Router, NavigationEnd } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { GuaranteeMethodDetail } from './business/shared.modals';
import { LinkedRetailItemsData, LockerAssignmentDetails } from '../shop/shop.modals';
import { ActivitiesRetailCommon } from '@app/shared/services/retail/activities-retail-common';

@Injectable({
  providedIn: 'root'
})
export class RetailSharedVariableService extends ActivitiesRetailCommon{ // Activities - Retail integration (Extends ActivitiesRetailCommon)
  SelectedPlayerId: number = 0;
  selectedProducts: SelectedProducts[] = [];
  SelectedPlayers: ScheduledTeeTimeUnPaidPlayer[] = [];
  isFromEditTeeTime: boolean = false;
  depositArray: DepositEventModel[] = [];
  isFromDeposit: boolean;
  excludeTaxForDeposit: boolean;
  unitOfMeasures: any;
  beoDeposit: number;
  PackageGroupId: number = 0;
  settleOpenTransaction: boolean = false;
  reOpenTransaction: boolean = false;
  correctTransaction: boolean = false;
  voidedTransactionId: number = 0;
  TaxValue: any;
  isFromRefund: any;
  isFromDayEnd: boolean;
  updateCancellationNoShowLedger: boolean;
  cancellationNoShowWaiveOff: boolean;
  isFromRetailDashBoard: boolean;
  fromTeeTimeCancellation: boolean = false;
  fromTeeTimeNoShow: boolean = false;
  sectionName: string;
  selectedRetailItem: any;
  isReturnWithoutTicket: boolean;
  appoinmentId: number;
  transactionId: number;
  RefundMethod: number;
  dropdownState: GlobalConst.RetailTransactions.returnwithticket;
  selectedSlotFirstPlayerId: number = 0;
  GoToRetailTransaction: boolean = false;
  SeletedCourseId: number = 0;
  SelectedDate: string = "";
  returnExchangeType: string = "";
  isfromReturnPage: boolean = false;
  selectedScheduledTeeTimeId: number = 0;
  isFromTeetimeSearch: boolean = false;
  checkOutIds: number[] = [];
  isFromCheckOut = false;
  isFromClassCheckOut : boolean = false;
  checkOutCallback: any;
  payeeId = 0;
  guestGuid: string = null;
  excludeTax = false;
  cancelDeposit: any[] = [];
  cancelDayPass: any[] = [];
  cancelRetailItems: any[] = [];
  cancelFlag = false;
  cancellationNoShowCharge: any[] = [];
  cancellationNoShowWaiveOffId: number = 0;
  statusChangeFlag: boolean = false;
  waiveOffFlag: boolean = false;
  RedirectModule: RedirectToModules;
  navigationWithinRetail: boolean = false;
  isFromSncBeo: boolean = false;
  bookingId: number;
  arAccountName: string;
  arAccountNumber: string;
  paymentTokenReferenceId: number;
  beoCheckDetails: any[];
  beoIds: number[];
  groupAppoinmentId: number = 0;
  receiptComment: string;
  selectedReservationRetailItem: number[];
  // Don't destroy Property configurations
  useRetailInterface = false;
  propertyDate: Date;
  SelectedOutletId = 0;
  SelectedTerminalId = 0;
  isReopenViewOnly: boolean = false;
  ticketNumber: string = '';
  memberCardNumber = "0";
  isFromTeeTimeGraphicalView = false;
  isTeeTimeAsPaid = false;
  isFromSpaWizard = false;
  isPromptedForTerminalSelect: boolean = false;
  DeletePreviousPlayer = false;
  selectedTransaction: any = false;
  isEditReservation: boolean = false;
  isFromReservation: boolean = false;
  isFromAppointment: boolean = false;
  addRetailItemToSource: boolean = false;
  isFromTherapistPortal: boolean = false;
  LinkedRetailItemDetails: LinkedRetailItemsData = null;
  hideReturnWithoutTicketToggle: boolean = false;
  GuaranteeMethodData: GuaranteeMethodDetail = null;
  RefundTicketTransactionIDs: number[] = [];
  isUpdateForReservation: boolean = false;
  enableAddToReservation: boolean = true;
  selectedResvProducts: SelectedProducts[] = [];
  isReset: boolean = false;
  canSelectProducts: boolean = false;
  arrivalDate: Date;
  departureDate: Date;
  isFromAudit: boolean = false;
  selectedappointments: any[] = [];
  isFromCheckInCheckOutPopUP?: boolean = false;
  isToMarkCheckIn?: boolean = false;
  manualEmailId: string[] = [];
  orgEmailId: string[] = [];
  transactionAdditionalReference: string = "";
  transactionAdditionalComment: string = "";
  sourceCorpId: number = 0;
	isDayPass:boolean = false;
  lockerAssignmentDetails: LockerAssignmentDetails = null;

  /**
   * Dictionary type, key will be Player / Client ID and Value will be array of Transaction IDs
   */
  dependentTransactions = new Map<number, number[]>();

  constructor(private router: Router) {
    /* router.navigate to child route re-renders the calling parent component #26749
      https://github.com/angular/angular/issues/26749
      To handle this, we are checking the previous Url and updating the flag to avoid reinitializing
      Bug 28282: Shop : User is not able to perform a pending settlement transaction - Fix
    */
   
      super(); //Activities - Retail integration (Calling super())
    this.router.events.pipe(filter(e => e instanceof NavigationEnd), pairwise())
      .subscribe((event: any[]) => {
        console.log(`navigationWithinRetail:${event[0].url.includes('shop')}`);
        this.navigationWithinRetail = event[0].url.includes('shop');
      });
  }

  Destroy() {
    this.SelectedPlayerId = 0;
    this.SelectedPlayers = [];
    this.isFromEditTeeTime = false;
    this.depositArray = [];
    this.isFromDeposit = false;
    this.excludeTaxForDeposit = false;
    this.unitOfMeasures = 0;
    this.PackageGroupId = 0;
    this.settleOpenTransaction = false;
    this.reOpenTransaction = false;
    this.correctTransaction = false;
    this.voidedTransactionId = 0;
    this.TaxValue = 0;
    this.isFromRefund = false;
    this.isFromDayEnd = false;
    this.updateCancellationNoShowLedger =false;
    this.cancellationNoShowWaiveOff = false;
    this.isFromRetailDashBoard = false;
    this.sectionName = "";
    this.selectedRetailItem = {};
    this.isReturnWithoutTicket = false;
    this.transactionId = 0;
    this.RefundMethod = 0;
    this.dropdownState = GlobalConst.RetailTransactions.returnwithticket;
    this.selectedSlotFirstPlayerId = 0;
    this.appoinmentId = 0;
    this.GoToRetailTransaction = false;
    this.returnExchangeType = "";
    this.isfromReturnPage = false;
    this.selectedScheduledTeeTimeId = 0;
    this.isFromTeetimeSearch = false;
    this.checkOutIds = [];
    this.isFromCheckOut = false;
    this.isFromClassCheckOut = false;
    this.checkOutCallback = {};
    this.payeeId = 0;
    this.guestGuid = null;
    this.excludeTax = false;
    this.cancelDeposit = [];
    this.cancelDayPass = [];
    this.cancelFlag = false;
    this.payeeId = 0;
    this.RedirectModule = RedirectToModules.none;
    this.isReopenViewOnly = false;
    this.ticketNumber = '';
    this.isReopenViewOnly = false;
    this.memberCardNumber = "0";
    this.isFromSncBeo = false;
    this.arAccountName = '';
    this.arAccountNumber = '';
    this.paymentTokenReferenceId = 0;
    this.isFromTeeTimeGraphicalView = false;
    this.receiptComment = '';
    this.isFromSpaWizard = false;
    this.DeletePreviousPlayer = false;
    this.selectedTransaction = null;
    this.isFromReservation = false;
    this.hideReturnWithoutTicketToggle = false;
    this.GuaranteeMethodData = null;
    this.RefundTicketTransactionIDs = [];
    this.enableAddToReservation = false;
    this.selectedResvProducts = [];
    this.canSelectProducts = false;
    this.transactionAdditionalReference = "";
    this.transactionAdditionalComment = "";
    this.addRetailItemToSource = false;
    this.LinkedRetailItemDetails = null;
    this.lockerAssignmentDetails = null;
  }
}
