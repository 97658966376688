<div class="editor-floors">
  <label class="editor-floors__heading" for="">{{'floorPlanView' | translate}}</label>
  <div class="editor-floors__floor-details">
    <ng-scrollbar>
      <div class="editor-floors__content">
        <!-- <table class ="editor-floors__table-view" border=1 >
          <thead class="editor-floors__header-row" >
            <th class="editor-floors__header-row-name" >{{'nameText' | translate}}</th>
            <th class="editor-floors__header-row-actions" style="padding: 5px;width: 100px;">{{'actionText' | translate}}</th>
          </thead>
          <tbody class="editor-floors__body-row">
            <tr class="editor-floors__data-row" [ngClass]="{'editor-floors__selected-row':floors.Id == fs.selectedFloorId}" *ngFor ="let floors of floorPlanViews;let i = index" (click)="selectedFloorPlan(floors)">

              <td class="editor-floors__data-row-name" >
                <span contenteditable="true" class="editor-floors__data-row-name-text" (blur)="onFloorPlanViewNameChanges(i, $event)">{{floors.Name}}</span></td>
              <td style="padding: 5px;" class="editor-floors__data-row-actions">
                <activities-app-button [buttontype]="deleteTableBtn" (click)="deleteFloorPlan(i)"></activities-app-button>
              </td>
            </tr>
          </tbody>
        </table> -->
        <table mat-table [dataSource]="floorPlanDetails" class="editor-floors__table-view">
          <ng-container matColumnDef="floor">
            <th class="editor-floors__table-header" mat-header-cell *matHeaderCellDef> {{ 'nameText' | translate}} </th>
            <td (click)="selectedFloorPlan(item)" class="editor-floors__table-data" mat-cell
              *matCellDef="let item;let i = index">
              <span contenteditable="true" (blur)="onFloorPlanViewNameChanges(i, $event)">{{item.Name}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th class="editor-floors__table-header" mat-header-cell *matHeaderCellDef> {{'actionText' | translate}} </th>
            <td class="editor-floors__table-data" mat-cell *matCellDef="let item;let i=index">
              <!-- <activities-app-button [buttontype]="deleteTableBtn" (click)="deleteFloorPlan(i)"></activities-app-button> -->
              <a class="icon-ic_delete_24px editor-floors__action-icon" (click)="deleteFloorPlan(i)"></a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
        </table>
      </div>
    </ng-scrollbar>
  </div>

</div>