<div class="measures-header d-flex flex-wrap ">
  <form class="formWidth h-100" [formGroup]="setupFormGroup">
    <div class="header-wrap"> 
      <mat-form-field [floatLabel]="floatLabel" class="float-left" *ngIf="!isSpecialCharAllowed" [ngClass]="{'button_invalid': isViewOnly}">
        <input matInput placeholder="{{setupName}}" (input)="checkValid($event)" formControlName="setupCodeName"
        [maxlength]="maxInputLength" [minlength]="minInputLength" autocomplete="off" inputtype = "nospecailchar"
         [skipInputValidation]="skipValidation" [showErrPopup] ="showValidationErrPopup" attr.automationId='Txt_{{automationId}}_{{setupName}}'>
        <mat-error
          *ngIf="setupFormGroup.controls['setupCodeName'].hasError('required') && !setupFormGroup.controls['setupCodeName'].valid">
          {{errorText}}
        </mat-error>
        <mat-error
        *ngIf="setupFormGroup.controls['setupCodeName'].errors?.minlength">
          {{minmumValidationErrMsg}}
      </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="floatLabel" class="float-left" *ngIf="isSpecialCharAllowed" [ngClass]="{'button_invalid': isViewOnly}">
        <input matInput placeholder="{{setupName}}" (input)="checkValid($event)" formControlName="setupCodeName"
        [maxlength]="maxInputLength" [minlength]="minInputLength" autocomplete="off"  attr.automationId='Txt_{{automationId}}_{{setupName}}'>
        <mat-error
          *ngIf="setupFormGroup.controls['setupCodeName'].hasError('required') && !setupFormGroup.controls['setupCodeName'].valid">
          {{errorText}}
        </mat-error>
        <mat-error
        *ngIf="setupFormGroup.controls['setupCodeName'].errors?.minlength">
          {{minmumValidationErrMsg}}
      </mat-error>
      </mat-form-field>

      <div class="guidId" *ngIf="isGuidInputEnable" >
        <mat-form-field [floatLabel]="floatLabel" class="float-left" [ngClass]="{'button_invalid': isViewOnly}">
          <input matInput [placeholder]="GuidName" (input)="checkValid($event)" formControlName="GuidID" attr.automationId='Txt_{{automationId}}_GuidID'>
       <mat-error
          *ngIf="setupFormGroup.controls['GuidID'].hasError('invalidGuid')">
          {{invalidGuidErrMsg}}
        </mat-error>
        </mat-form-field>
      </div>
      
    
      
      <mat-form-field [floatLabel]="floatLabel" class="float-left" *ngIf="enableTerminal" [ngClass]="{'button_invalid': isViewOnly}">
          <mat-select placeholder="{{terminalID}}" formControlName="terminalId"  (selectionChange)="terminalChange($event)" 
          [disabled]="inlineEditFlag || isAddButtonDisable" attr.automationId='Dd_{{automationId}}_terminalId'>
              <mat-option  [value]='' ></mat-option>
              <mat-option *ngFor='let Header of headerOptions' [value]='Header.id' >{{Header.id}}</mat-option>
          </mat-select>
          <mat-error *ngIf="setupFormGroup.controls['terminalId'].hasError('required') && !setupFormGroup.controls['terminalId'].valid">
          {{terminalMissing}}
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="floatLabel" class="float-left" *ngIf="enableMultiSelect" [ngClass]="{'button_invalid': isViewOnly}">
        <mat-select placeholder="{{selectedOptionPlacholder}}" (selectionChange)="OutletSelected($event)" formControlName="selectedOptions" 
          multiple [compareWith]="compareSelect" attr.automationId='Txt_{{automationId}}_selectedOptions'>
          <mat-checkbox class="mat-option all-multiselect" [disableRipple]="true" (change)="filterDropDownSelected($event)" 
          [checked]="setupFormGroup.controls['selectedOptions'].value?.length  == selectOptions?.length">
            {{defaultData?.viewValue}}
          </mat-checkbox>
          <mat-option *ngFor='let option of selectOptions' [value]="option">
              {{option?.viewValue}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="setupFormGroup.controls['selectedOptions'].hasError('required')">
        </mat-error>
    </mat-form-field>
      <div *ngIf="enableToggleButton" class="mr-3 float-left mb-2" attr.automationId='Tog_{{automationId}}_activetoggle'>
          <div><label class="LW12 pr-2 active-label label-color form-label-normal">{{active}}</label></div>
          <app-retail-toggle-switch formControlName="activetoggle" [disabled]="inlineEditFlag" (changeToggleEvent)="checkValidState($event)" class="toggle-switch"></app-retail-toggle-switch>
      </div>
      <div *ngIf="enableReceiptToggleButton" class="mr-3 float-left mb-2" attr.automationId='Tog_{{automationId}}_receipttoggle'>
        <div><label class="LW12 pr-2 active-label label-color form-label-normal">{{receiptToggleCaption}}</label></div>
        <app-retail-toggle-switch formControlName="receipttoggle" class="toggle-switch" (changeToggleEvent)="checkValidState($event)"></app-retail-toggle-switch>
    </div>
      <button mat-raised-button type="button" class="LW14 mr-2 button_height float-label-box" 
        [ngClass]="setupFormGroup.valid || isValidRoleName && !isAddButtonDisable ? 'button--primary' : 'button--disabled'" attr.automationId='Btn_{{automationId}}_{{headerButtonName}}'
        [disabled]="!setupFormGroup.valid || !isValidRoleName || isViewOnly || inlineEditFlag || isAddButtonDisable" (click)="onButtonClick()">{{headerButtonName}}</button>
      <a *ngIf="isCancelButtonAvailable" class=" LW14 ml-2 search_button_width cancelButton button_height float-label-box"
      attr.automationId='Btn_{{automationId}}_{{cancelButtonName}}' (click)="onButtonCancelClick($event)">{{cancelButtonName}}</a>
    </div> 
  </form>
  <div class="d-flex align-items-center width-class custSearchbox" >
  <mat-form-field *ngIf="!isEditFlag" class="searchArea spa-searchbox ml-auto" [floatLabel]="floatLabelNever"
    [ngClass]="{'retailSearchWidth': enableToggleButton}">
    <input matInput autocomplete="off" class="search-input" [(ngModel)]="searchText"
      [ngModelOptions]="{standalone: true}" type="text" placeholder="{{searchPlaceholderValue}}" [maxlength]="maxInputLength"
      value="" (keypress)="searchValue()" (ngModelChange)="searchValueChanged($event)" attr.automationId='Txt_{{automationId}}_searchText'>
    <i aria-hidden="true"  [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor" (click)="resetValue()" attr.automationId='Icn_{{automationId}}_icnSearchCancel'></i>
  </mat-form-field>
  <i aria-hidden="true" class="align-self-center icon-Search button_invalid ml-auto" *ngIf="isEditFlag" attr.automationId='Icn_{{automationId}}_icnSearch'></i>
</div>
</div>
