import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CacheService } from "@app/core/services/cache.service";
import { ReservationType } from "@app/shared/constants/commonenums";
import { PartyChargeState, PartyPrepaymentState } from "@app/shared/models/InputContact";
import { PartyService } from "@app/shared/services/party.service";
import { TranslateService } from "@ngx-translate/core";
import { round } from "lodash";

@Component({
    selector: 'app-crs-reservation-card',
    templateUrl: './crs-reservation-card.component.html',
    styleUrls: ['./crs-reservation-card.component.scss']
})
export class CRSReservationCard implements OnInit, OnChanges {

    @Input() reservation: any;
    @Input() showDate: boolean = true;
    @Input() primaryText: 'contact' | 'property';
    @Input() dateFormat: boolean = true;
    @Input() OperationCurrency: string;
    @Input('reservation-prepayment') reservationPrepayment: string;
    // @Input() headerText: string;
    // @Input() highlightText: string;
    // @Input() tables: string[];
    // @Input() size: string;
    // @Input() slotTime: string;
    // @Input('bg-color') bgColor;

    tableName: string;
    financialDetail;
    IsCharged;
    specialMealName: string;

    ICONS = {
        'auth': 'icon-res-payment-aurthorized',
        'ignored': 'icon-res-payment-aurthorized',
        'paid': 'icon-res-payment-Paid',
        'not-paid': 'icon-res-payment-notpaid'
    }

    constructor(private translate: TranslateService, private partyService: PartyService, private cacheService: CacheService) { }

    ngOnInit(): void {
        this.tableName = this.reservation.TableNames?.length && this.reservation.TableNames.reduce((res, curr) => `${res} ${res ? ',' : ''} ${curr}`, '');
    }

    ngOnChanges(changes: SimpleChanges){
        this.SetIconVisibility();
        this.specialMealName = this.cacheService.specialMealListForMerchant?.find(x => x.Id == this.reservation?.SpecialMealId)?.Name;
    }

    SetIconVisibility() {

        let paymentStatus: 'paid' | 'auth' | 'not-paid' | 'ignored';
        if ((this.reservation.IsCCAssociated || this.reservation.CreditCardId != null || this.reservation.SpecialMealId != null || this.reservation.RatePlanAmount != null) && this.reservation.PrepaymentState != PartyPrepaymentState.Refunded && this.anyAmountInvolved(this.reservation)) {
            if (this.reservation.PrepaymentState != PartyPrepaymentState.None && this.reservation.Type == ReservationType.Reservation) {
                // Paid SM
                const isSpecMealPrepaid = this.reservation.PrepaymentState == PartyPrepaymentState.Prepaid
                    || this.reservation.PrepaymentState == PartyPrepaymentState.Refunded
                    || this.reservation.PrepaymentState == PartyPrepaymentState.RefundFailed
                    || this.reservation.PrepaymentState == PartyPrepaymentState.PartialPrepayment;

                paymentStatus = isSpecMealPrepaid ? 'paid' : 'not-paid';

                if (!isSpecMealPrepaid && this.reservation.PrepaymentState == PartyPrepaymentState.DeferredPayment && this.reservation.CreditCardId != null) {
                    if (this.reservation.ChargeState == PartyChargeState.Charged) {
                        paymentStatus = 'paid';
                    } else if (this.reservation.ChargeState === PartyChargeState.Ignored) {
                        paymentStatus = 'ignored';
                    }
                    else {
                        paymentStatus = 'auth';
                    }
                }
            } else if (this.reservation.Type == ReservationType.Reservation) {
                // NoShowFee
                paymentStatus = this.reservation.CreditCardId != null && this.reservation.NoShowFeeAmount ? 'auth' : null;
            }
        }

        this.reservation.paymentStatus = paymentStatus;
    }

    financialDetails(party, status, event) {
        let amount;
        party.NoShowFeeAmount = party.NoShowFeeAmount ? party.NoShowFeeAmount : 0;
        party.RatePlanAmount = party.RatePlanAmount ? party.RatePlanAmount : 0;
        party.SpecialMealAmount = party.SpecialMealAmount ? party.SpecialMealAmount : 0;
        this.financialDetail = {
            status: '',
            date: '',
            amount: 0,
            header: ''
        };
        let _partyAmount = this.partyService.getBookingAmount(party);
        party['bookingAmount'] = _partyAmount.bookingAmount;
        party['taxOnServiceCharge'] = _partyAmount.taxOnServiceCharge;

        if (party.NoShowFeeAmount > 0 || this.anyAmountInvolved(party)) {
            if (status === 'auth' || status == 'ignored') {
                switch (status) {
                    case 'auth':
                        amount = ((party.NoShowFeeAmount) ? round(Number(party.NoShowFeeAmount), this.cacheService.getNumberOfFractionalDigits()) : 0) + ((party.RatePlanAmount) ? round(Number(party.RatePlanAmount), this.cacheService.getNumberOfFractionalDigits()) : 0) + ((party.SpecialMealAmount) ? round(Number(party.SpecialMealAmount), this.cacheService.getNumberOfFractionalDigits()) : 0);
                        amount = Number(amount.toFixed(this.cacheService.getNumberOfFractionalDigits())) + Number(party.TaxAmount || 0) + Number(party.TotalServiceChargeAmount || party['bookingAmount'] || 0) + Number(party.TaxOnServiceChargeAmount || party['taxOnServiceCharge'] || 0);
                        this.financialDetail = { status: this.translate.instant('wasauthorized'), date: party.CreatedAt, amount, header: this.translate.instant("financialdetails") };
                        this.IsCharged = true;
                        if (amount <= 0) {
                            this.financialDetail.status = this.translate.instant('noCreditCardText');
                            this.financialDetail.header = this.translate.instant('paymentDetails');
                            this.IsCharged = false;
                        }
                        return;
                    case 'ignored':
                        amount = round(Number(party.NoShowFeeAmount), this.cacheService.getNumberOfFractionalDigits()) + round(Number(party.RatePlanAmount), this.cacheService.getNumberOfFractionalDigits()) + round(Number(party.SpecialMealAmount), this.cacheService.getNumberOfFractionalDigits());
                        amount = Number(amount.toFixed(this.cacheService.getNumberOfFractionalDigits())) + Number(party.TaxAmount || 0) + Number(party.TotalServiceChargeAmount || party['bookingAmount'] || 0) + Number(party.TaxOnServiceChargeAmount || party['taxOnServiceCharge'] || 0);
                        this.financialDetail = { status: this.translate.instant('wasignored'), date: party.CreatedAt, amount, header: this.translate.instant("financialdetails") };
                        this.IsCharged = true;
                        if (amount <= 0) {
                            this.financialDetail.status = this.translate.instant('noPaymentText');
                            this.financialDetail.header = this.translate.instant('paymentDetails');
                            this.IsCharged = false;
                        }
                        return;
                }
            }
            this.partyService.getPartyPaymentStaus(party.Id).subscribe(data => {
                amount = data.Payload.PaidAmount;
                if (amount > 0 && amount <= data.Payload.TotalAmount) {
                    this.financialDetail = { status: this.translate.instant('hasPaid'), date: party.ChargedAt, amount, header: this.translate.instant("financialdetails") };
                    this.IsCharged = true;
                    return;
                }
                switch (status) {
                    case 'paid':
                        amount = amount.toFixed(this.cacheService.getNumberOfFractionalDigits());
                        this.financialDetail = { status: this.translate.instant('hasPaid'), date: party.ChargedAt, amount, header: this.translate.instant("financialdetails") };
                        this.IsCharged = true;
                        if (amount <= 0) {
                            this.financialDetail.status = this.translate.instant('noPaymentText');
                            this.financialDetail.header = this.translate.instant('paymentDetails');
                            this.IsCharged = false;
                        }
                        break;
                    case 'not-paid':
                        this.financialDetail.status = this.translate.instant('noPaymentText');
                        this.financialDetail.header = this.translate.instant('paymentDetails')
                        this.IsCharged = false;
                        break;
                }
            });
        }

        event.preventDefault();
        event.stopPropagation();
    }

    anyAmountInvolved(_party) {
        return (_party.NoShowFeeAmount || _party.RatePlanAmount || _party.SpecialMealAmount);
    }
}
export interface ReservationCardData {
    property: { Id: number, Name: string };
    activity: { Id: number, Name: string };
    slotTime: string;
}