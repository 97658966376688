import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CacheService } from '@core/services/cache.service';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { buttonTypes, ComponentTypes, filterType, PricingBy, RolesAndPermissionsType, Status } from '@app/shared/constants/commonenums';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@app/shared/services/settings.service';
import { FormatSettingsDatePipe } from '@app/shared/pipes/format-settings-date.pipe';
import { SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { PopupService } from '@popup-module/popup.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { T } from '@angular/cdk/keycodes';
import { ActivityCreateFunctions } from '@app/shared/utilities/activity-create-functions';
import { RolesAndPermissionPipe } from '@pipes/RolesAndPermission.pipe';
import { AppService } from '@app/app.service';
import { format } from 'date-fns';
import { groupBy } from 'lodash';
import { Router, RouterStateSnapshot } from '@angular/router';
import { PartyService } from '@app/shared/services/party.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import _ from 'lodash';
@Component({
  selector: 'app-activity-creation',
  templateUrl: './activity-creation.component.html',
  styleUrls: ['./activity-creation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityCreationComponent extends Utilities implements OnInit {
  tableDataSource: MatTableDataSource<{}>;
  activities: any[] = [];
  createButton: ButtonValue;
  cancelButton: ButtonValue;
  showActivityCreation: boolean = false;
  subscriptions: Subscription = new Subscription();
  basicSettings: any;
  tableData: any;
  settings: SettingsDTO;
  isEdit: boolean;
  showActivitiesList: boolean = false;
  rolesAndPermissionsType = RolesAndPermissionsType;
  currentRestaurantDateTime;
  filterMenu : any; 
  constructor(public dialog: MatDialog, private cs: CacheService, private ts: TranslateService, public ss: SettingsService, private fs: FormatSettingsDatePipe, private activityCreateFunctions: ActivityCreateFunctions,private rolesAndPermissionPipe :RolesAndPermissionPipe, private appService: AppService, private partService: PartyService, private router: Router) {
    super(dialog);
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this.settings = sett;
      this.currentRestaurantDateTime = new Date(Utilities.getRestaurantDateTime(this.settings.General.DaylightDelta));
      // this.activities = sett.SpecialMeals;
    }));
  }

  ngOnInit() {
    // this.partService.redirectPath = 'settings/activities';
    this.GetLessonRestaurant();
    this.loadConfig();
  }
  ngAfterViewInit() {
    this.subscriptions.add(this.appService.templateSelectionChange.subscribe(data => {
      if (data && !this.appService.showCreateActivity) {
        this.showActivityCreation = false;
        this.createActivity(data)
      }
    }))
  }
  GetLessonRestaurant() {
    this.ss.GetLessonDetailsByRestaurant().subscribe(res => {
      this.activities = res?.Payload?.SpecialMealDTO || [];
      // this.activities = res?.Payload?.SpecialMealDTO .filter( activity => activity.TemplateCode == 1);
      this.showActivitiesList = true;
      // this.loadTableData();
    this.setFilterItem(this.filterMenu);
    })
  }
  loadTableData(filteredData?) {
    let tableHeader = ["activityName", "DateRange", "GroupSizeRange", "activityType", "reservationtype", "paymentRequired", "Status", "Action",'auditlog'];
    let tableBody = [];
    let activities =  filteredData || this.activities;
    activities.map(data =>
      tableBody.push({
        "ActivityID":data.Id,
        "activityName": data.Name,
        "DateRange": this.fs.transform(data.StartDate, this.settings.General.DateFormat) + " to " + this.fs.transform(data.EndDate, this.settings.General.DateFormat),
        "GroupSizeRange": data.MinPartySize + " - " + data.MaxPartySize,
        "activityType": this.activityCreateFunctions.getActivityType(data.ClassType),
        "reservationtype": data.IsForStandbyReservations ? this.ts.instant('standby') : this.ts.instant('confirmed'),
        "paymentRequired": this.activityCreateFunctions.getPrePaymentMode(data.PrePaymentMode),
        "Status":  data.StatusCode == Status.Approved ? true : false,
        "start":new Date(data.StartDate),
        "end":new Date(data.EndDate),
        "isExpired": data.IsExpired
      }))

    this.tableData = {
      header: tableHeader,
      body: tableBody
    }
  }

  loadStatusConfig(StatusCode, id) {
    return [
      {
        type: 'switch',
        name: id,
        label: '',
        inputType: 'text',
        class: 'status-switch',
        checked: StatusCode == Status.Approved ? true : false,
        value: StatusCode == Status.Approved ? true : false,
        disabled: !this.rolesAndPermissionPipe.transform(RolesAndPermissionsType.ModifyActivity)
      }
    ];
  }

  createActivity(data) {
    this.isEdit = data?.isEdit || false;
    if(!data || this.isEdit ){
      this.appService.isTemplateUsed = false
    }
    this.subscriptions.add(this.ss.GetLessonByIdAndRestaurant(data?.id || 0).subscribe(data => {
      this.basicSettings = data.Payload;
      this.showActivityCreation = true;
    }))
  }
  removeActivity(data?) {
    if (data) {
      this.setFilterItem(this.filterMenu);
      this.subscriptions.add(this.ss.RemoveLesson(data.id).subscribe(data => {
        console.log(data);
        this.GetLessonRestaurant();
      }));
    }
  }
  setFilterItem(filterMenu?) {
    this.filterMenu = filterMenu;
    let filteredActivity;
    if(this.filterMenu){
      let filtersByType = groupBy(filterMenu.selection, 'key');
       filteredActivity = this.activities || [];
      if(filterMenu.filteredDate.startDate && filterMenu.filteredDate.endDate){
        filteredActivity = filteredActivity.filter(e => new Date(e.StartDate) >= new Date(filterMenu.filteredDate.startDate) && new Date(e.EndDate) <= new Date(filterMenu.filteredDate.endDate)) || [];
      }
      let StatusCode = filtersByType[filterType.StatusCode]?.map(status => { return status.value })
      let ClassType = filtersByType[filterType.ClassType]?.map(status => { return status.value })
      let StandbyReservations = filtersByType[filterType.StandbyReservations]?.map(status => { return status.value })
      let PrePaymentMode = filtersByType[filterType.PrePaymentMode]?.map(status => { return status.value })
      filteredActivity = filteredActivity?.filter(activity => {
        return (StatusCode ? StatusCode.includes(activity.StatusCode) : true) 
        && (ClassType ? ClassType.includes(activity.ClassType) : true) 
        && (StandbyReservations ? StandbyReservations.includes(activity.IsForStandbyReservations) : true)
        && (PrePaymentMode ? PrePaymentMode.includes(activity.PrePaymentMode) : true);
      });
    }else{
      filteredActivity = this.activities
    }
    this.loadTableData(filteredActivity);
  }
  cancelActivity(value?) {
    if (value) {
      this.GetLessonRestaurant();
    }
    this.showActivityCreation = false;
  }
  refreshActivity(value) {
    if (value) {
      this.GetLessonRestaurant();
      this.showActivityCreation = false;
    }
  }
  loadConfig() {
      this.createButton = {
        type: buttonTypes.actionPrimary,
        label: this.ts.instant('addNewActivity'),
        disbaledproperity: !this.rolesAndPermissionPipe.transform(RolesAndPermissionsType.CreateActivity),
        customclass: 'action-bar__add-class-btn',
        icon: "icon-add"
      };  
    this.cancelButton = {
      type: buttonTypes.actionPrimarySmall,
      label: this.ts.instant('Cancel'),
      disbaledproperity: false,
      customclass: 'action-bar__add-class-btn',
    };
  }

  hasUnsavedData(): boolean{
    return this.showActivityCreation;
  }

  displayConfirmDialog(nextState: RouterStateSnapshot){

      let message = "Are You sure want to quit activity edit?";
      const popUpMessage = [{
        confirmationMessage: message, dialogTitle: "Are you sure", showAlert: true
      }];
      const componentDetails: ComponentDetails = {
        componentName: ConfirmationPopupComponent,
        popupType: '',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: popUpMessage,
        popupTitle: this.ts.instant('alert')
      };
      const dialogRef = this.dialog.open(AppPopupComponent, {
        disableClose: true,
        width: '450px',
        height: 'auto',
        data: {
          showAction: true,
          update: 'Yes',
          cancel: 'No',
          componentDetails,
          back: false,
          standalone: true
        }
      });

      this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
        if(!event){
          this.showActivityCreation = false;
          this.router.navigate([nextState.url]);
        }
      }));
    }

    ngOnDestroy() {
      this.filterMenu = null;
      if(this.partService.redirectPath)
      this.partService.redirectPath = '';
    }
}
