import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { ComponentTypes, Menu, NotificationPreference, OperationResultState, PartyNoteType, PartyState, ReservationEmailNotificationType, ReservationType, SlottingMode } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { CacheService } from '@core/services/cache.service';
import { EngageIntegrationDTO, PageMethod, PartyEmailSendBehavior, SettingsDTO, SpecialMealDTO, StatusDTO, SupportedReservationEmailConfirmationTypes } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { DashboardFunctions } from '@utilities/dashboard-functions';
import { Utilities } from '@utilities/utilities';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';
import _ from 'lodash';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { EngageMemberByCardIdRequestDTO } from '@app/shared/models/EngageMemberByCardIdRequestDTO';

@Component({
  selector: 'app-partieslist',
  templateUrl: './partieslist.component.html',
  styleUrls: ['./partieslist.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PartieslistComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() parties: any;
  @Input() index: any;
  @Input() type: ReservationType;
  @Input() panelState: boolean;
  @Input() showCreatedDate: boolean;
  @Input() isNoShowSelected: boolean;
  @Input() generalSettings: {
    HasRoomNumberSearch: boolean,
    SpecialMeals: SpecialMealDTO[],
    Statuses: StatusDTO[],
    PromoCodeLabel,
    General: {
      DaylightDelta: string,
      NoShowsUndoTimeInMinutes: number,
      MinIntervalBetweenPartyPagesInMinutes: number,
      SupportedReservationEmailConfirmationTypes: SupportedReservationEmailConfirmationTypes,
      SlottingMode: SlottingMode,
      EngageIntegrationDTO: EngageIntegrationDTO
    }
  };
  selectedParty: any;
  ReservationType = ReservationType;
  setActionDisabled = false;
  @Output() partySelectedEvent = new EventEmitter<any>();
  PartyState = PartyState;
  hasRoomNUmberSearch = false;
  cancelSubscription: ISubscription;
  confirmSubscription: ISubscription;
  pageTimer: Subscription;
  subscriptions: Subscription = new Subscription();
  _settings: SettingsDTO = {} as SettingsDTO;
  reservationUrl: string = urlConfig.noReservationsUrl;
  headerSubscription: ISubscription;
  filteredParties: any[] = [];
  partyWithNotes: any;
  Menu = Menu;
  memberDetails:any;
  partyNoteType = PartyNoteType;
  guestNotes: any[] = []; 
  @ViewChild('partyStatusPopup') private partyStatusPopup: NgbPopover;
  partyStatusBasedonType: StatusDTO[];
  selectedStatusParty: any;
  partyStatusPopupHide: PartyState[] = [PartyState.Cancelled ,PartyState.Seated ,PartyState.Left ,PartyState.NoShowed];
  partyWithAddon: any;

  constructor(public _ps: PartyService, public _as: AppService, private dialog: MatDialog, private ps: PopupService,
    private dashboardFunctions: DashboardFunctions, private ts: TranslateService, public cs: CacheService,
    private cdf: ChangeDetectorRef) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
      this.hasRoomNUmberSearch = this._settings.HasRoomNumberSearch;
    }));
  }

  ngOnInit() {
  }


  fetchMemberDetails(membershipId,event,popover){
    let {SiteId, TenantId} = this.generalSettings.General.EngageIntegrationDTO;

    let req = new EngageMemberByCardIdRequestDTO(membershipId, TenantId, SiteId);

    this.subscriptions.add(this._ps.api.getEngageMemberByCardId(req).subscribe(memberInfo =>{
      this.memberDetails = memberInfo?.ProfileInfoGetByCardId?.ProfileValue;
      popover.open();
    }));
    
    event.stopPropagation();
    event.preventDefault()
  }

  ngOnChanges() {
    if (this.panelState) {
      console.log('Parties list - ngOnChanges');
      this.filterParties();
      if (this.parties.length > 0) {
        this.parties.forEach((party) => {
          this.setPageBackgroudColor(party);
        });
      }
      setTimeout(() => {
        const expansionPanel: any = document.getElementsByClassName('shift-mat-expansion-panel-header');
        let height = 0;
        for (const panel of expansionPanel) {
          height += panel.offsetHeight;
        }
        const windowHeight = window.innerHeight;
        const headerHeight: any = document.getElementsByClassName('layout__header').length > 0 ?
          document.getElementsByClassName('layout__header')[0]['offsetHeight'] : 0;
        let reservationHeader;
        let paddingHeight;
        if (this.type === ReservationType.Reservation) {
          reservationHeader = document.getElementsByClassName('reservation__row-header').length > 0 ?
            document.getElementsByClassName('reservation__row-header')[0]['offsetHeight'] : 0;
          paddingHeight = 50;
        } else if (this.type === ReservationType.Waitlist) {
          reservationHeader = document.getElementsByClassName('row-header').length > 0 ?
            document.getElementsByClassName('row-header')[0]['offsetHeight'] : 0;
          paddingHeight = 30;
        }
        const estimatedHeight = windowHeight - (height + headerHeight + reservationHeader + paddingHeight);
        const gridName = `parties-list-${this.index}`;
        const gridHeight = document.getElementById(gridName);
        if (gridHeight) {
          gridHeight.style.maxHeight = estimatedHeight + 'px';
        }
        const virtualDivName = `parties-list-scroll-${this.index}`;
        const virtualDivHeight = document.getElementById(virtualDivName);
        if (this.parties.length > 0) {
          virtualDivHeight.style.minHeight = 100 + 'px';
        }
      });
    }
  }

  mapPartyNotes(party) {
    this.partyWithNotes = { ...party };
  }


  filterParties() {
    this.filteredParties = [];
    this.parties.forEach(party => {
      if (party.NoShowedAt == null && party.State != 3) {
        this.filteredParties.push(party);
      }
      else if (party.NoShowedAt != null && party.State == 3) {
        let noShowDate = moment(party.ReservedFor);
        let currentDay = Utilities.Date(Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta));
        if ((currentDay.diff(noShowDate, 'minutes')) < this.generalSettings.General.NoShowsUndoTimeInMinutes) {
          this.filteredParties.push(party);
        }
      }
    })
  }


  showParty(party): boolean {
    if (party.NoShowedAt == null && party.State != PartyState.NoShowed) {
      return true;
    } else if (party.NoShowedAt != null && party.State == PartyState.NoShowed) {
      const noShowDate = Utilities.Date(party.ReservedFor);
      const currentDay = Utilities.Date(Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta));
      if ((currentDay.diff(noShowDate, 'minutes')) < this.generalSettings.General.NoShowsUndoTimeInMinutes) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  ngAfterViewInit() {
    this.headerSubscription = this._as.headerDate$.subscribe(date => {
      const currentdate = moment().format('DD/MM/YYYY');
      const headerdate = moment(date).format('DD/MM/YYYY');
      if (currentdate != headerdate) {
        this.setActionDisabled = true;
      }
    });
    this.subscriptions.add(this._ps.selectedParty$.subscribe(party => {
      this.selectedParty = party;
      this.setActive(party);
    if (party) {
      this.setPageBackgroudColor(party);
    }
    }));
    this.cdf.detectChanges();
    // const partyTableEle = document.getElementById('partyTable');
    // const tableListEle = document.getElementById('table-list');
    // if (tableListEle) {
    //   tableListEle.style.width = partyTableEle.style.width + 'px !important';
    // }
  }

  ngOnDestroy() {
    if (this.headerSubscription) {
      this.headerSubscription.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    console.log('Parties list - ngOnDestroy')
  }

  


  mapGuestNotes(Notes) {
    let guestNotesText = [];
    let customnotesText = [];
    let guestnotes = _.cloneDeep(Notes);
    if (guestnotes.length > 4) {
      let requiredNotes = guestnotes.splice(4, guestnotes.length);
      let NotesWithRelatedId = requiredNotes.filter(x => x.RelatedId != null);
      let NotesWithoutRelatedId = requiredNotes.filter(x => x.RelatedId == null);
      guestNotesText = NotesWithRelatedId.map(n => n.Text);
      if (NotesWithoutRelatedId && NotesWithoutRelatedId.length > 0) {
        NotesWithoutRelatedId.forEach(element => {
          let tempArray = [];
          tempArray = element.Text.split("\n");
          tempArray.forEach(e => {
            customnotesText.push(e);
          })
        });
      }
      this.guestNotes = [...guestNotesText, ...customnotesText];
    }
  }

  setActive(party) {
    const partyRows = document.querySelectorAll('[class^="party-"]');
    if (partyRows) {
      partyRows.forEach(element => {
        if (element.classList.contains('party-list__selected-row')) {
          element.classList.remove('party-list__selected-row');
        }
      });
    }
    if (this.selectedParty) {
      let element = document.getElementsByClassName('party-' + this.selectedParty.Id);
      if (element.length > 0) {
        element[0].classList.add('party-list__selected-row');
      }
    }
    if(party)
    party.isShowParty = this.showParty(party);
  }

  setPageBackgroudColor(party: any) {
    party.PageMethod = party.NotificationPreference == NotificationPreference.ReservationPageMethod ? party.PageMethod : party.Contact?.PreferredPageMethod;
    if (party && party.PageMethod == PageMethod.Manual) {
      if (!party.LastPagedAt) {
        return;
      }
      const lastPagedAt = moment(party.LastPagedAt);
      const time = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
      let differenceTime = (this._settings.General.MinIntervalBetweenPartyPagesInMinutes * 60) - (time.diff(lastPagedAt, 'seconds'));
      if ((time.diff(lastPagedAt, 'minutes')) >= this._settings.General.MinIntervalBetweenPartyPagesInMinutes) {
        party.pageBackgroundColor = 'red';
        if (this.pageTimer) {
          this.pageTimer.unsubscribe();
          this.subscriptions.remove(this.pageTimer);
        }
      } else {
        party.pageBackgroundColor = 'green';
        if (differenceTime > 0) {
          this.subscriptions.add(this.pageTimer);
        }
      }
    }
    if (party && (party.PageMethod == PageMethod.Sms || party.PageMethod == PageMethod.Sms2 || party.PageMethod == PageMethod.Email || party.PageMethod == PageMethod.Email2)) {
      if (party && party.Messages && party.Messages.length > 0) {
        party.pageBackgroundColor = 'green';
      }
      if (party && party.Messages && party.Messages.length > 0 && party.Messages[party.Messages.length - 1].IsIncoming) {
        party.pageBackgroundColor = 'red';
      }
    }
  }

  selectParty(party, eve) {
    this.selectedParty = party;
    this.setActive(party);
    this.partySelectedEvent.emit(party);
    this._ps.selectedParty$.next(party);
  }

  seatParty(party: any) {
    this.dashboardFunctions.seatPartyWithConfimation(party);
  }

  cancelParty(party) {
    if (this._as.OTASourceId.includes(party.PartySourceId)) {
      this.dashboardFunctions.showOTAAlert(party);
      return;
    }
    let msg = '';
    if (this.type === ReservationType.Waitlist) {
      msg = this.ts.instant('cancelWaitlist');
    } else if (this.type === ReservationType.Reservation) {
      msg = this.ts.instant('cancelReservation');
    }
    let cancelText = this.ts.instant('notext');
    let title = this.ts.instant('cancelConfirmationMsg');
    let noShowSet = false;
    let cancelReservation = false;
    let updateText = this.ts.instant('yestext');
    let showAlert = false;
    let noShowFeePopUp = false;
    let isWebReservation = false;
    if(party && party.PartySourceId){
      isWebReservation = this._ps.isWebReservation(party.PartySourceId);
    }
    const currentDate = new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    if (this.type === ReservationType.Reservation && new Date(party.ReservedFor) < currentDate ||
      this.type === ReservationType.Waitlist && new Date(party.ArrivedAt) < currentDate) {
      const reservationMsg = this.ts.instant('reservationCancelMsg');
      const waitlistMsg = this.ts.instant('waitlistCancelMsg');
      msg = this.type === ReservationType.Reservation ? reservationMsg : waitlistMsg;
      cancelText = this.type === ReservationType.Reservation ? this.ts.instant('cancelReservationMsg') :
                    this.ts.instant('cancelParty');
      cancelReservation = false;
      updateText = this.ts.instant('noShow');
      title = this.ts.instant('alert');
      showAlert = true;
      noShowFeePopUp = true;
    }

    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm', showAlert
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '600px',
      height: '350px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      if (cancelReservation) {
        if (this.type === ReservationType.Waitlist) {
          this.dashboardFunctions.cancelWaitlistPartyConfirm(party);
          cancelReservation = false;
        } else {
          this.subscriptions.add(this._ps.cancelReservation(party.Id).subscribe(
            (data) => {
              cancelReservation = false;
              if (data.Payload && (this.cs.settings.value.General.HostCancellationEmailSendBehavior == PartyEmailSendBehavior.Prompt)) {
                this._ps.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Cancelled);
              }
            }));
        }
      } else if (noShowSet && noShowFeePopUp) {
        this.subscriptions.add(this._ps.noShowParty(party.Id).subscribe((data) => {
          if (data.State === OperationResultState.Success) {
            noShowSet = false;
          }
        }));
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
      if (this.confirmSubscription) {
        this.confirmSubscription.unsubscribe();
      }
    }));
    this.confirmSubscription = this.ps.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.reservation && noShowFeePopUp) {
        noShowSet = true;
      }
      if (val === ComponentTypes.reservation && !noShowFeePopUp) {
        cancelReservation = true;
      }
    });
    this.cancelSubscription = this.ps.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.reservation && val.value === 1 && !cancelReservation && noShowFeePopUp) {
        cancelReservation = true;
      }
    });
  }

  triggerPartyStatusPopup(popover, party) {
    if (!this.partyStatusPopupHide.includes(party.State)) {
      popover.toggle();
      this.selectedStatusParty = party;
    }
  }

  seatStatusChanged(event) {
    this.subscriptions.add(this._ps.postSeatedPartyStatus(Utilities.RestaurantId(), this.selectedStatusParty.Id, event).subscribe(val => {

    }));
    if (this.partyStatusPopup)
      this.partyStatusPopup.close();
  }
  updateAddOnDetails(party) {
    this.partyWithAddon = party
  }
}



