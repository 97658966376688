import { Injectable, inject } from '@angular/core';
import { urlConfig } from '@app/shared/constants/url-config';
import { GetContactOptions } from '@app/shared/models/InputContact';
import { HttpService } from '@app/shared/services/http.service';
import { Utilities } from '@utilities/utilities';
import { Observable } from 'rxjs';
import { CacheService } from '@app/core/services/cache.service';
import { AssetsHoldResponse, IHoldAssetsRequest } from '../interfaces/model';
import { IAssetAvailabiltyRequest, IAssetAvailabiltyResponse, IRentalLocationResponse } from '../interfaces/asset-check-availability';
import { IAssetFilterResponse } from '../interfaces/asset-filter';
import { IRentalItemsResponse } from '../pages/rental-favorites/rental-favorites.component';

@Injectable({
  providedIn: 'root'
})
export class RentalApiService {

  httpService = inject(HttpService);
  cs = inject(CacheService);
  selectedAssets: any;

  constructor() { }

  getLocationByRevenueCenter(): Observable<IRentalLocationResponse> {
    return this.httpService.post(`${urlConfig.getLocationByRevenueCenter}?propertyId=${Utilities.RestaurantId()}`);
  }

  getFilterMasterData(): Observable<IAssetFilterResponse> {
    return this.httpService.get(`${urlConfig.getFilterMasterData}?propertyId=${Utilities.RestaurantId()}`);
  }

  getAssetAvailableAssets(req: IAssetAvailabiltyRequest): Observable<IAssetAvailabiltyResponse> {
    return this.httpService.post(`${urlConfig.getAssetAvailableAssets}?propertyId=${Utilities.RestaurantId()}`, req);
  }

  getContact(contactId, partyId = null) {
    const contactOptions: GetContactOptions = GetContactOptions.FullWithStatsAndPredefinedNotesIncludeCreditCards;
    return this.httpService.get(
      `${urlConfig.getguestInfoUrl}?restaurantId=${Utilities.RestaurantId()}&contactId=${contactId}&options=${contactOptions}&partyId=${partyId}`);
  }

  holdAssets(req: IHoldAssetsRequest): Observable<AssetsHoldResponse>{
    return this.httpService.post(`${urlConfig.holdAssets}?propertyId=${Utilities.RestaurantId()}`, req);
  }

  releaseHold(lockId: number): Observable<AssetsHoldResponse>{
    return this.httpService.post(`${urlConfig.releaseAssets}?propertyId=${Utilities.RestaurantId()}&lockId=${lockId}`);
  }

  syncRentalItems(): Observable<AssetsHoldResponse>{
    return this.httpService.get(`${urlConfig.syncRentalItems}?propertyId=${Utilities.RestaurantId()}`);
  }

  getAllRentalItems(): Observable<IRentalItemsResponse>{
    return this.httpService.post(`${urlConfig.getAllRentalItems}?propertyId=${Utilities.RestaurantId()}`);
  }

  setFavouritesOnRentalItems(isFavourite: boolean, addonList: number[]): Observable<IRentalItemsResponse>{
    return this.httpService.post(`${urlConfig.setFavouritesOnRentalItems}?propertyId=${Utilities.RestaurantId()}&isFavourite=${isFavourite}`, addonList);
  }

  getRentalInventoryItems(bookingId: number) {
    return this.httpService.get(`${urlConfig.getRentalInventoryItems}?propertyId=${Utilities.RestaurantId()}&bookingId=${bookingId}`);
  }
}
