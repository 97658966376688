<section class="retail-code-setup newVersionWrapper retail-tier-wrapper h-100" id="tierLevel">
    <app-table-search-header [attr.automationId]="'Srch_retailTierLevel_search'" [searchHeaderOption]="searchHeaderOption" (searchChange)="searchChange($event)"
      (showInactive)="showInactiveToggler($event)" (createType)="createEvent($event)">
    </app-table-search-header>
  
    <div class="retailGrid">
        <app-retail-table [attr.automationId]="'Tbl_retailDiscountTypes_retailDiscount'" [options]="tableoptions"
        (deleteEvt)="DeleteRecords($event)" (editEvt)="EditRecords($event)"></app-retail-table>
    </div>
  </section>
  