import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActionSupportService, AssignInstructorDTO } from "../privatelesson-booking-detail/action-support.service";
import { TranslateService } from "@ngx-translate/core";
import { FieldConfig } from "@app/shared/dynamicform/models/field-config.interface";
import { DynamicFormComponent } from "@app/shared/dynamicform/dynamic-form/dynamic-form.component";
import { Subscription } from "rxjs";
import _ from "lodash";
import { PopupService } from "@app/popup-module/popup.service";
import { ReservationDTO } from "@app/shared/models/InputContact";
import { MatDialog } from "@angular/material/dialog";
import { ComponentTypes } from "@app/shared/constants/commonenums";
import { ToastrService } from 'ngx-toastr';
import { ComponentDetails } from "@app/popup-module/models/popup.interface";
import { ConfirmationPopupComponent } from "@app/shared/components/confirmation-popup/confirmation-popup.component";
import { CustomPopupComponent } from "@app/popup-module/components/custom-popup/custom-popup.component";

@Component({
    selector: 'assign-instructor',
    templateUrl: './assign-instructor.component.html',
    styleUrls: ['./assign-instructor.component.scss']
})
export class AssignInstructorComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() data: { currentAssignedInstructor: number, reservation: ReservationDTO };

    searchConfig: FieldConfig[];
    searchText: string;
    instructors: AssignInstructorDTO[] = [];
    selectedInstructor: AssignInstructorDTO;
    subscriptions: Subscription = new Subscription();
    @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;

    constructor(private actionSupportService: ActionSupportService, private ts: TranslateService, private popupService: PopupService, private dialogRef: MatDialog, public toastrService?: ToastrService) {
        this.popupService.restrictCloseDialog = true;
    }

    ngOnInit(): void {
        this.searchConfig = [
            {
                type: 'input',
                name: 'searchText',
                label: this.ts.instant('serverSearchText'),
                //  class: 'server-selection__search-text',
                showErrorText: true,
                appearance: true,
                icon: 'icon-search',
                icon1: 'icon-Group-591',
                cellClick: this.clearSearch.bind(this)
            }
        ];
        this.actionSupportService.getInstructorsToAssign(this.data.reservation.Id).subscribe((response) => {
            this.setInstructors(response.Payload);
            this.popupService.restrictCloseDialog = true
        })

    }

    setInstructors(instructors: AssignInstructorDTO[]) {
        this.instructors = _.orderBy(instructors, ['IsTemplate', 'Name'], ['desc', 'asc']);

        if (this.data.currentAssignedInstructor) {
            this.selectedInstructor = this.instructors.find(({ Id }) => Id === this.data.currentAssignedInstructor);

            setTimeout(() => {
                let selectedInstructorElement = document.getElementById(`assign_instructor_${this.data.currentAssignedInstructor}`);
                if (selectedInstructorElement) {
                    const rect = selectedInstructorElement.getBoundingClientRect();
                    const isInView = rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);

                    // Only scroll if the element is not in view
                    if (!isInView) {
                        selectedInstructorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        selectedInstructorElement.focus();
                    }
                }
            }, 0);
        }
    }

    ngAfterViewInit() {
        this.dynamicFormChanges();
        this.subscriptions.add(this.popupService.confirmedAction$.subscribe((value) => {
            if(value === ComponentTypes.AssignInstructor){
                if (this.selectedInstructor?.Id) {
                    this.updateInstructor();
                }
            }
        }))
        this.subscriptions.add(
            this.popupService.cancelledAction$.subscribe((value) => {
                if(value.from === ComponentTypes.AssignInstructor){
                    this.closeDialog();
                }
            })
        )
    }

    updateInstructor(ignoreValidation:boolean = false) {
        let selectedStaff = this.instructors.find(staff => staff.Id == this.selectedInstructor?.Id && !staff.IsTemplate);
        if(!ignoreValidation && selectedStaff && !selectedStaff.IsAvailable){
            this.showWarningInfoPopUp(this.ts.instant('staffUnavailableMsg'));
            return;
        }
        if (this.data.currentAssignedInstructor === this.selectedInstructor?.Id) {
            this.closeDialog();
            return;
        }
        this.actionSupportService.assignInstructor(this.selectedInstructor.Id, this.data.reservation.Id).subscribe(response => {
            this.closeDialog();
            this.toastrService.success(this.ts.instant("StaffAssignedSuccessfully"), "", { timeOut: 1000, closeButton: true });
        })
    }

    showWarningInfoPopUp(message) {
        const popUpMessage = [
            {
                confirmationMessage: message,
                dialogTitle: "confirm",
                showAlert: true
            },
        ];
        const componentDetails: ComponentDetails = {
            componentName: ConfirmationPopupComponent,
            dimensionType: "small",
            popupType: "active",
            popUpDetails: {
                isStepper: false,
                eventName: "notifyParent",
            },
            popupInput: popUpMessage,
            popupTitle: popUpMessage[0].dialogTitle,
        };

        let warningInfoDialogRef = this.dialogRef.open(CustomPopupComponent, {
            disableClose: true,
            width: '500px',
            height: '400px',
            data: {
                title: 'confirmation',
                update: "Yes",
                cancel: "No",
                componentDetails,
                from: ComponentTypes.AssignStaffWarning,
                back: false,
                standalone: true,
                showAlert: true,
            },
        });
        let confirmAction = this.popupService.confirmedAction$.subscribe((val) => {
            if (val == ComponentTypes.AssignStaffWarning) {
                this.updateInstructor(true);
                confirmAction?.unsubscribe();
                warningInfoDialogRef.close();
                this.popupService.restrictCloseDialog = false;
            }
        })
        let refundCancelSubscription = this.popupService.cancelledAction$.subscribe(
            (val) => {
                this.popupService.restrictCloseDialog = false;
                confirmAction?.unsubscribe();
                warningInfoDialogRef.close();
                refundCancelSubscription?.unsubscribe();
            })
    }


    closeDialog() {
        this.actionSupportService.assignInstructorDialogRef.close();
    }

    dynamicFormChanges() {
        this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe(val => {
            this.searchText = val?.searchText;
        }))
    }

    selectInstructor(instructor) {
        this.selectedInstructor = instructor;
    }

    clearSearch() {
        this.searchText = "";
        this.dynamicForm.form.get('searchText').reset();
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
        this.popupService.restrictCloseDialog = false;
    }
}