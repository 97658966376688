<section class="newVersionWrapper rental-item-wrapper">
    <div class="rental-item--top-section bodyWrap" [formGroup]="rentalForm">
        <app-simple-search class='mr-3' [placeholder]="searchPlaceHolder"
        (searchChange)="searchValueChange($event)" [value]="searchText">
        </app-simple-search>
        <ag-dropdown  class="mr-3" [placeholder]="captions.lbl_Outlet" formControlName="outletsdropdown" [source]="outletsList" (selectionChange)="selectedOutletChange($event)">
        </ag-dropdown>
        <app-ag-date-picker *ngIf="!isOutOfOrder" class="mr-3" [inputs]="dateInput" (datePickerChange)="dateChange($event)">
        </app-ag-date-picker>
        <app-ag-time-picker *ngIf="!isOutOfOrder" class="mr-3"  [inputs]="timeInput" (timeChangeEvent)="timeChange($event)"></app-ag-time-picker>
        <app-button *ngIf="!isOutOfOrder" class="ag_mt--3" [buttontype]='todayButton' (valueChange)='todayClick()'></app-button>
        <app-button *ngIf="!isOutOfOrder" class="ag_mt--3" [buttontype]='searchButton' (valueChange)='onSearch()'></app-button>
        <div class="ag_display--flex ml-auto">
            <label class="mr-2 mt-3">{{captions.lbl_filterby}}</label>
            <div class="ag_display--flex mt-2">
               <div class="button-type" (click)="onInUse()" [ngClass]="{'clicked' : isInUse}">{{captions.lbl_inUse}}</div>
               <div class="button-type" (click)="onAvailable()" [ngClass]="{'clicked' : isAvailable}">{{captions.lbl_available}}</div>
               <div class="button-type" (click)="onOutOfOrder()" [ngClass]="{'clicked' : isOutOfOrder}">{{captions.lbl_outOfOrder}}</div>
            </div>
        </div>
    </div>
    <div *ngIf="isInUse" class="rental-item--table">
        <app-cdkvirtual [headerOptions]="headerOptions" [tableContent]="tableContent" [options]="tableOptions" >
        </app-cdkvirtual>
    </div>
    <div *ngIf="isAvailable" class="rental-item--available-section">
        <div *ngFor="let item of availableItem" class="rental-item-available--item-card">
            <div class="font-bold item-name" [matTooltip]="item.itemName">{{item.itemName}}</div>
            <div class="rental-item-available--number mt-1 mb-1" [matTooltip]="item.itemNumber">{{item.itemNumber}}</div>
            <div class="shop-image mb-2" *ngIf="_commonVarService.displayImagesForRentals" [ngClass]="[!item.image ? 'icon-img' : '']">

                <app-retail-img-thumbnail *ngIf="item.image" [imageContent]="item.image"
                  class="image app-retail-img-thumbnail" [thumbnail]="false" imageType="retailItem">
                </app-retail-img-thumbnail>

                <span *ngIf="!item.image">
                  <span class="icon-emptyshop"><span class="path1"></span><span class="path2"></span><span
                      class="path3"></span><span class="path4"></span><span class="path5"></span><span
                      class="path6"></span><span class="path7"></span><span class="path8"></span><span
                      class="path9"></span><span class="path10"></span><span class="path11"></span><span
                      class="path12"></span><span class="path13"></span><span class="path14"></span></span>
                </span>

              </div>
            <div class="rental-item-available--bottom-sect">
                <div class="available">{{captions.lbl_available}} - {{item.availableQuantity}}/{{item.totalQuantity}}</div>
                <div class="ag_display--flex align-items-baseline">
                    <i class="icon-buffer_time_16px" [matTooltip]="captions.lbl_bufferTIme"></i>
                    <span class="ml-1">{{item.bufferTime}} {{localization.captions.common.Mins}}</span>
                </div>
            </div>
        </div>
        <div class="ag_text--center w-100" *ngIf="!availableItem || (availableItem && availableItem.length == 0)">
            {{captions.bookAppointment.NoDataFound}}
        </div>

    </div>
    <div *ngIf="isOutOfOrder" class="rental-item--table">
        <app-cdkvirtual [headerOptions]="headerOptions" [tableContent]="tableContent" [options]="tableOptions" >
        </app-cdkvirtual>
    </div>
</section>
