import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPipe'
})
export class FilterPipe implements PipeTransform {

  transform(inputArray: any[], Value: string, headerKey: any[]): any[] {
    if (!inputArray) return [];
    if (!Value) return inputArray;
  
    const valueLower = Value.toLowerCase();
    return inputArray.filter(result => {
      return headerKey.some(key => {
        const fieldValue = result[key];
        if (fieldValue != null) { // Check if key exists in the object
          if (typeof fieldValue === 'string') {
            return fieldValue.toLowerCase().includes(valueLower);
          } else if (typeof fieldValue === 'number') {
            return fieldValue.toString().includes(valueLower);
          } else if (Array.isArray(fieldValue)) {
            // Check if any string in the array matches the Value
            return fieldValue.some(item => 
              typeof item === 'string' && item.toLowerCase().includes(valueLower)
            );
          }
        }
        return false;
      });
    });
  }
  
}


