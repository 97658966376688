import { Component, OnInit, inject } from '@angular/core';
// import { ReservePOSSessionService } from '../../services/respos-session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import { KeyboardService } from '@app/pos/keyboard/keyboard.service';
import { CartService } from '@app/pos/services/cart.service';
import { ReservePOSSessionService } from '@app/pos/services/respos-session.service';
import { CommonVariablesService } from '@app/retail/shared/service/common-variables.service';
import { Menu } from '@app/shared/constants/commonenums';
import { ActivitiesVenue } from '@app/shared/constants/globalConstants';
import { LoadMultiplePropertiesService } from '@app/shared/services/load-multiple-properties.service';
import { PartyService } from '@app/shared/services/party.service';
import { LoginFunction } from '@app/shared/utilities/login-function';
// import { CartService } from '../../services/cart.service';

@Component({
  selector: '[respos-layout-header]',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss']
})
export class LayoutHeaderComponent implements OnInit {

  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  // sessionService = inject(ReservePOSSessionService);
  cartService = inject(CartService);
  cs = inject(CacheService);
  ls = inject(LoadMultiplePropertiesService);
  as = inject(AppService);
  shopVariableService = inject(CommonVariablesService);
  partyService = inject(PartyService);
  loginFunction = inject(LoginFunction);
  public keyboardService = inject(KeyboardService);
  posSessionService = inject(ReservePOSSessionService);

  userName: string | undefined = '';

  ngOnInit(): void {
    this.keyboardService.enableKeyboard = this.keyboardService.enableKeyboard || this.posSessionService.UserPreference.enableVirtualKeyboard === 'yes';
    // if(this.sessionService.hostInfo){
    //   this.userName = this.sessionService.hostInfo.HostRole.Name;
    // }
    if(!this.cs.LoadMultiplePropertiesServiceInit){this.ls.init()};

    if(!this.cs.settings.value.AllowPOSReservation){
      this.exitPOS();
    }
  }

  goToHome(){
    this.router.navigate(['pos/home'], {relativeTo: this.activatedRoute})
  }

  goTocart(){
    this.router.navigate(['pos/cart'], {relativeTo: this.activatedRoute})
  }

  goToListing(){
    this.router.navigate(['pos/listing'], {relativeTo: this.activatedRoute})
  }

  exitPOS(){
        // Not resetting POS flags here to bug fix #41372
    setTimeout(() =>{
      // this.as.posView = false;
      this.loginFunction.navigateToReservation(true);
    },500);
  }

  enableDisableKeyboard(){
    this.keyboardService.enableDisableKeyboard(null);
  }

  logout() {
    if(this.as.showCartItem){
      this.as.showCartItem = false;
    }
    this.cs.locationListForMerchant = [];
    this.cs.LoadMultiplePropertiesServiceInit = false;
    sessionStorage.removeItem('section');
    // this.keyboardService.enableKeyboard = false;
    this.as.logout();
  }
}
