import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[restrictSpl]'
})
export class IncrementalDirective{
    regexStr = '^[0-9_]*$';
  
    constructor(private el: ElementRef) { }
  
  
    @HostListener('keypress', ['$event']) onKeyPress(event) {
      return new RegExp(this.regexStr).test(event.key);
    }
  
    @HostListener('paste', ['$event']) 
    @HostListener('drop', ['$event']) 
    blockPaste(event: KeyboardEvent) {
      this.validateFields(event);
    }
  
    validateFields(event) {
      setTimeout(() => {
  
        this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^0-9 ]/g, '').replace(/\s/g, '');
        event.preventDefault();
  
      }, 100)
    }
  
}