import { HistoryPartyState, TableBlockingRuleFrequencyType, HttpStatusCode, PermissionType, SlottingMode, PartyType, PartyState, DayOfWeek, ManualSlotSelection, LessonType, PricingBy, Status, ReservationEmailNotificationType, GuestFieldMetrics } from '@constants/commonenums';
import { RoleType } from './LoginResultDTO';
import { newTimeRangeDTO, TimeRangeDTO } from './TimeRangeDTO';
import { RatePlanDTO, RatePlanSeatingTypeMapping } from './RatePlanDTO';
import { CustomGuestFieldsMappingDTO, PartyPrepaymentState, ReservationDTO, SMSMessageTemplateModel } from './InputContact';
import { AddOnRequest, StandByPartyDTO } from './InputReservation';
import { ActivityPoint } from './EngageMemberDetailDTO';
import { BookingGuestType } from '../constants/globalConstants';
import { DataTypes, FieldType } from '@app/settings/setup-settings/setupSettingsDTO';
import { GuestLinkType } from './FormCodeDTO';

export interface RestaurantDTO {
  Layout: LayoutDTO;
  Settings: SettingsDTO;
  State: StateDTO;
  PendingChargePartiesCount: number;
  DataRetentionPolicy: DataRetentionPolicy[];
  ShowAliasName: boolean;
}

export interface LayoutDTO {
  Timestamp: Date;
  FloorPlans: FloorPlanDTO[];
}

export interface ContactHistoryItemDTO {
  DateTime: Date;
  DepartureTime: Date;
  OrderCheckInfo: CheckInfo;
  PartyId: number;
  PartySize: number;
  PartyState: HistoryPartyState;
  PartyType: PartyType;
  SeatingTime: Date;
  TableNames?: string[];
}


export interface CheckInfo {
  CheckId: number;
  TurnTime: number;
  TotalSpent: number;
  OrderItems: OrderItem[];
}

export interface OrderItem {
  Name: string;
  Quantity: number;
  PricePerItem: number;
  TotalPrice: number;
}

export interface FloorPlanDTO {
  Id: number;
  Name: string;
  Dimensions: DimensionsDTO[];
  IsDefault: boolean;
  IsRemoved: boolean;
  FloorPlanViews: FloorPlanViewDTO[];
  Labels: LabelDTO[];
  Shapes: ShapeDTO[];
  Walls: WallDTO[];
  StandaloneTables: StandaloneTableDTO[];
  JoinedTables: JoinedTableDTO[];
  Dates: RestaurantLayoutDateDTO[];
  ImageId: string;
}

export interface FloorPlanViewDTO {
  Id: number;
  Name: string;
}

export interface IFloorPlanViewObject {
  FloorPlanViewId: number;
  id?: number;
}

export class LabelDTO implements IFloorPlanViewObject {
  public FloorPlanViewId: number;
  public Text: string;
  public Left: number;
  public Top: number;
}

export class ShapeDTO implements IFloorPlanViewObject {
  public FloorPlanViewId: number;
  public Left: number;
  public Top: number;
  public Width: number;
  public Height: number;
  public Rotation: number;
  public Color: ColorDTO;
  public ShapeType: LayoutShapeType;
}

export enum LayoutShapeType {
  Rectangle,
  Ellipse
}

export class WallDTO implements IFloorPlanViewObject {
  public FloorPlanViewId: number;
  public Points: PointDTO[];
}

export interface DimensionsDTO {
  Height: number;
  Width: number;
}
export class PointDTO {
  X: number;
  Y: number;
}

export interface IUpdateableTable {
  Id: number;
  IsNew: boolean;
}

export class StandaloneTableDTO implements IUpdateableTable {
  public Id: number = null;
  public Name: string = null;
  public MinPartySize: number = null;
  public MaxPartySize: number = null;
  public IsAvailableForReservations: boolean = false;
  public IsAvailableForBooking: boolean = false;
  public IsCommunalTable: boolean = false;
  public ServerId: number = null;
  public SeatingAreaId: number = null;
  public SeatingTypeId: number = null;
  public SeatingArea: string = null;
  public SeatingType: string = null;
  public Price: string = null;
  public label: string = null;
  public radius: number = null;
  public IsNew: boolean = false;
  public FloorPlanViewId: number = null;
  public Left: number = null;
  public Top: number = null;
  public Width: number = null;
  public Height: number = null;
  public ShapeType: TableShapeType = null;
  public RotationAngle: number = null;
  public originX: string = null;
  public originY: string = null;
  public fill: string = null;
  public stroke: string = null;
  public strokeWidth: number = null;
  public scaleX: number = null;
  public scaleY: number = null;
  public Angle: number = null;
  public opacity: number = null;
  public rx: number = null;
  public ry: number = null;
  public Party?: any = null;
  public ImageId?: string = null;
  public BufferTimeInMinutes: number = 0;
  public IsTemplate?: boolean;
  public LocationGroupId?:boolean;
  
}
export class BookingChargeActivityMappingDTO {
  Id: number = null;
  ActivityId: number = null;
  BookingChargeId: number = null;
}
export enum TableShapeType {
  Rectangle,
  Ellipse,
  Pin,
  RectangleBasic,
  CircleBasic,
  Triangle,
  Pentagon,
  Octagon,
  Image
}

export class JoinedTableDTO implements IUpdateableTable {
  public Id: number;
  public MinPartySize: number;
  public MaxPartySize: number;
  public StandaloneTableIds: number[];
  public LayoutId: number;
  public IsNew: boolean;
}

export interface RestaurantLayoutDateDTO {
  Date: Date;
  Name: string;
  ShiftId: number;
}
export interface Category {
  Id?: number;
  MasterMerchantId: number;
  MerchantId: number;
  Label?: string;
  Text: string;
  Color: ColorDTO;
  Icon: string;
  CategoryType: any;
  SubCategories: SubCategoryDto[];
  IncludeInWebReservation: boolean;
  CreatedAt: Date;
  UpdatedAt: Date;
  IsVisible: boolean;
}

export interface SubCategoryDto {
  Id?: number;
  Text: string;
  Label?:string;
  CategoryId: number;
  PredefinedContactNote: PredefinedContactNoteDTO[];
  PredefinedPartyNote: PredefinedPartyNoteDTO[];
  SubCategoryPropertyMapping: SubCategoryPropertyMappingDto[];
  CreatedAt: Date;
  UpdatedAt: Date;
}

export interface SubCategoryPropertyMappingDto {
  Id?: number;
  SubCategoryId: number;
  PropertyId: number;
  PropertyName: string;
}
export interface SettingsDTO {
  Timestamp: Date;
  Categories: Category[];
  AllowAdditionalGuestDetails: boolean;
  AllowPOSReservation: boolean;
  TimelineHeaderMinutesInterval: number;
  General: TSGeneralSettingsDTO;
  Statuses: StatusDTO[];
  PredefinedPartyMessages: PredefinedPartyMessageDTO[];
  SeatingAreas: SeatingAreaDTO[];
  SeatingTypes: SeatingTypesDTO[];
  //PredefinedPartyNotes: PredefinedPartyNoteDTO[];
  //PredefinedContactNotes: PredefinedContactNoteDTO[];
  Servers: ServerDTO[];
  Hosts: NamedEntityDTO[];
  SpecialMeals: SpecialMealDTO[];
  PartySources: NullableIdNamedEntityDTO[];
  UsesGuestScore: boolean;
  PromoCodeLabel: string;
  HasRoomNumberSearch: boolean;
  IsOhipEnabled: boolean;
  CustomGuestFields: CustomGuestFieldDTO[];
  Countries: CountryDTO[];
  LayoutBackgroundImage: any;
  SeatingTypeSeatingAreaMappings: any;
  Shifts: ShiftDTO[];
  PropertySetting: any;
  RatePlans: RatePlanDTO[];
  RatePlanSeatingTypeMappings: RatePlanSeatingTypeMapping[];
  PropertyType: any;
  LanguageFileURL: string;
  PropertyID: any;
  OpenHours: OpenHoursDTO[];
  ShowOtherPropertyActivity: boolean;
  PropertiesUnderMerchant: PropertiesUnderMerchantDTO[];
  ExpertiseLevels: ExpertiseLevelDTO[];
  AllocationType: AllocationTypeDTO[];
  CoverTypes: any[];
  BookingTypes: any[];
  FeedbackQuestionaries: any[];
  LoadPermission: any[];
  HasCGPSearch: boolean;
  RetailItems: RetailItems[];
  customGuestFieldsMappingValidations: CustomGuestFieldsMappingDTO[];
  PrintersInfo: PrintersInfo[];
  BookingCharges: BookingChargeDTO[];
  Addons: AddonDTO[];
  AdvanceBookingOptionDisabled:boolean;
  HideResetAllLocations:boolean;
  DeveloperMode:boolean;
  LocationGroups: LocationGroupDTO[];
  MerchantSettings: MerchantSettings;
  RentalIntegrationEnabled: boolean;
  NumberOfFractionalDigits: number;
}

export class MemberInfoBase{
  /**
   *
   */
  constructor(memberId : string) {
   this.MemberId = memberId;
  }
  MemberId : string;
  CourseName : string;
  IsMembershipExpired : boolean
  PlayerType : string;
  MembershipType : string;
  RateType : string
  RateTypeAfterRoundsUsed : string;
  RateTypeMembershipExpiry : string
  ConsumedMinutes : number
  Bucket : Bucket[]
}

export class Bucket {
  BucketCode : string;
  BucketName : string;
  CourseCode : string;
  EligibleRounds : number;
  TotalRounds : number;
}

export class MemberInformation {
  MemberCardNumber : number;
  MemberType :string;
  MemberStats :boolean;
  ARCode :string;
}


export class AppSettingsDTO {
  Id: number;
  AppsettingsKey: string;
  AppsettingsValue: string;
  CreatedDate: Date;
  UpdatedDate: Date;
  IsEncrypted: boolean;
  IsUIRequired: boolean;
  AppSettingsMasterId: number;
  Environment:  string;
  PropertyId: number;
  MerchantId: number;
}

export class BookingChargeDTO {
  Id: number;
  BookingChargeType: BookingChargeType;
  MerchantId: number;
  Name: string;
  Value: number;
  CalculationType: CalculationType;
  StartDate: Date;
  EndDate: Date;
  StatusCode: Status;
  BookingChargePropertyMappings: BookingChargePropertyMapping[];
  BookingChargeActivityMappings: BookingChargeActivityMapping[];
  BookingChargeAddonMappings: BookingChargeAddonMapping[];
  BookingChargeTaxMappings: BookingChargeTaxMapping[];
}

export class BookingChargeActivityMapping {
  Id: number;
  SpecialMealId: number;
  BookingChargeId: number;
}

export class BookingChargeTaxMapping {
  Id: number;
  TaxId: number;
  BookingChargeId: number;
}

export class BookingChargeAddonMapping {
  Id: number;
  AddonId: number;
  BookingChargeId: number;
}

export class BookingChargePropertyMapping {
  Id: number;
  RestaurantId: number;
  BookingChargeId: number;
  IsDefault: boolean;
}

export enum BookingChargeType {
  Tax,
  ServiceCharge,
  Negotiation,
  MinRateCoverTypeAdjustment,
  BasePrice,
  Location,
  Staff,
  CoverType,
  BookingType,
  Addon,
  SpecialMeal,
  SpecialMealCovers,
  MembershipDiscount,
  AdditionalRate,
  CancellationFee,
  NoShowFee,
  MembershipRound,
  PackageDiscount,
  PromoCodeDiscount
}

export class AddonDTO {
  AddonId: number;
  SelectionType: SelectionType;
  IsOpenbookingEnabled: boolean;
  AddonName: string;
  CategoryId: number;
  SubCategoryId: number;
  Description: string;
  RetailItemId: number;
  ImageId: string;
  ImageFile: string;
  ImageChanged: string;
  RestaurantId: number;
  StatusMaster: Status
  AddonActivitiesMappings: AddonActivitiesMappingsDTO[];
  AddOnDetails: AddOnDetailsDto[];
  AvailableQuantity?: number;
  selectedQuantity?: number;
}

export class AddonBookingDTO extends AddonDTO {
  OverBooked?: number;
  OverBooking?: number;
  OverAllQuantity?: number;
}

export class AddOnDetailsDto {
  Id: number;
  AddOnId: number;
  ActivityId: number;
  StartDate: Date;
  EndDate: Date;
  StartTime:Date;
  EndTime:Date;
  Quantity: number;
  PricingCategory: PricingCategory;
  Duration: number;
  PricingType: PricingType
  Price: number;
  AdditionalPrice: number;
  AdditionalMinutes: number;
  StatusMaster: Status;
  IsUnlimited: boolean;

}
export class AddonActivitiesMappingsDTO {
  MappingId: number;
  AddonId: number;
  ActivityId: number;
  AvailableForWebReservation: boolean;
  IsMandatory: boolean;
  IsCategoryLevelMandatory
  IsVisible: boolean;
  MinQuantity: number;
  MaxQuantity: number;
  Price: number;
  PricingType: number;
  ConsiderForAvailability: boolean;
}
export enum SelectionType // addontype
{
  PerGuest = 1,
  PerBooking = 2,
  PerLesson = 3
}
export enum PricingCategory {
  BySession = 1,
  ByDuration
}

export enum PricingType {
  ByHour = 1,
  ByMinutes
}
export class TaxesDTO {
  Id: number;
  Name: string;
  TaxValue: number;
  TaxType: CalculationType;
  StartDate: Date;
  EndDate: Date;
  IsDefault: boolean;
  StatusCode: Status;
  TaxPropertyMappings: TaxPropertyMappingDTO[];
  TaxActivityMappings: TaxActivityMappingDTO[];
}

export class TaxPropertyMappingDTO {
  Id: number;
  PropertyId: number;
  TaxId: number;
  IsDefault: boolean;
  StatusCode: Status;
}

export class TaxActivityMappingDTO {
  Id: number;
  ActivityId: number;
  TaxId: number;
}

export class TaxableItem {
  Index: number;
  ActivityId: number;
  AddonId?: number;
  StartDate?: Date;
  EndDate?: Date;
  RatePlanAmount?: number;
  SpecialMealAmount?: number;
  TaxAmount?: number;
  ServiceChargeAmount?: number;
  Taxes: TaxItem[];
  Quantity?: number;
  RetailItemId?: number;
  RetailExternalPOSItemId?: number;
  AddonAmount: number;
  OriginalRatePlanAmount?: number;
  TaxOnServiceChargeAmount?: number;
}

export class TaxItem {
  TaxId: number;
  IsPercentage: boolean;
  TaxValue: number;
  TaxName: string;
  TaxAmount: number;
}

export enum CalculationType {
  FlatRate = 0,
  Percentage = 1
}

export enum IGCalculationType {
  FlatRate = 0,
  Percentage = 1
}

export enum PageMethod {
  Manual,
  Sms,
  VoiceCall,
  Email,
  Sms2,
  VoiceCall2,
  SMSProxyRelay,
  Email2
}

export enum LockMode {
  Auto = 1,
  Manual
}
export class TSGeneralSettingsDTO {
  RestaurantName: string;
  SlottingMode: SlottingMode;
  TableSuggestionMode: TableSuggestionMode;
  RequiredPartyFields: RequiredPartyFields;
  DefaultPageMethod: any;
  SupportedReservationEmailConfirmationTypes: SupportedReservationEmailConfirmationTypes;
  HostCancellationEmailSendBehavior:PartyEmailSendBehavior;
  HostUpdateEmailSendBehavior: PartyEmailSendBehavior;
  HostConfirmationEmailSendBehavior: PartyEmailSendBehavior;
  PrioritizedPartyListType: PartyListType;
  IgIntegrationDTO: IgIntegrationDTO;
  SupportsSlotLocking: boolean;
  ShowSeatedReservationsInLists: boolean;
  ShowSeatedWalkInsInLists: boolean;
  AllowHotelConciergeTracking: boolean;
  CollectCustomerDataInQuickSeat: boolean;
  IsIntegratedWithPartyPaymentService: boolean;
  UseMembershipNumber: boolean;
  MembershipNumberName: string;
  SpecialMealsCoverTypesEnabled: boolean;
  HoldTimeBeforeReservationInMinutes: number;
  HoldTimeBeyondReservationInMinutes: number;
  TimeSlotUnitInMinutes: number;
  MaxPastTimeForReservationsInMinutes: number;
  MaxPartySize: number;
  MaxPartySizeForDropDowns: number;
  MaxPartySizeForManualWaitTimeOverrides: number;
  MaxMinutesToReseatPartyAfterLeaving: number;
  RefreshStateIntervalInSeconds: number;
  HeartbeatIntervalInSeconds: number;
  NoShowsUndoTimeInMinutes: number;
  MinCharactersForContactSuggestion: number;
  MinDelayBetweenContactSuggestionInMilliseconds: number;
  MaxPartySizeForDashboard: number;
  MinSearchKeywordLength: number;
  MaxRestaurantsForDashboard: number;
  MaxPartySizeForDashboardAvailability: number;
  MaxContactNameLengthForWebParties: number;
  MinIntervalBetweenPartyPagesInMinutes: number;
  MaxHoursBackToNoShowOrCancelReservation: number;
  MaxMinutesBackToEditOrSeatReservation: number;
  MaxIntervalInDaysForReportScheduler: number;
  MaxPhotoWidth: number;
  MaxPhotoHeight: number;
  MaxHoursToMarkReservationAsConflicted: number;
  OperationCurrency: string;
  IsGDPREnabled: boolean;
  CustomPolicyText: string;
  WidgetUniqueName: string;
  Retentionpolicy: DataRetentionPolicy[];
  Country: string;
  PreferredPageMethod: string[] = Object.keys(PageMethod);
  TimeZoneIANAFormat: string;
  DaylightDelta: string;
  MerchantId: number;
  RetailIntegrationDTO: RetailIntegrationDTO;
  EngageIntegrationDTO: EngageIntegrationDTO;
  DateFormat: string;
  ValidateGuestPhoneNumber: boolean;
  TicketType: string;
  LayoutCode: string;
  PrinterApi: string;
  RequiredSecondaryGuestFields:RequiredPartyFields;
  IsFreedomPayCredentialsEntered:boolean;
  IsAuthourizePayCredentialsEntered:boolean;
  SMSProvider: number; 
  SlotLockInSec?: number;
  DefaultTimelineAxis: TimelineAxis;
  DefaultTimelineColumnWidth: number;

}

export enum TableSuggestionMode {
  Never,
  Suggest,
  SuggestAndAssign,
  NeverAssign
}

export enum RequiredPartyFields {
  None = 0,
  PhoneNumber = 1,
  EmailAddress = 2,
  Both = 3,
  SecondaryPhoneNumber = 4,
  SecondaryEmailAddress = 5,
  SecPhoneNumberSecEmailAddress = 6,
  PhoneNumberSecPhoneNumber = 7,
  PhoneNumberSecEmailAddress = 8,
  EmailAddressSecPhoneNumber = 9,
  EmailAddressSecEmailAddress = 10,
  PhoneNumberEmailAddressSecPhoneNumber = 11,
  PhoneNumberEmailAddressSecEmailAddress = 12,
  EmailAddressSecPhoneNumberSecEmailAddress = 13,
  SecPhoneNumberSecEmailAddressPhoneNumber = 14,
  All = 15,
  Anyone = 16
}

export enum SupportedReservationEmailConfirmationTypes {
  None = 0,
  Created = 1,
  Updated = 2,
  Cancelled = 4
}


export enum PartyEmailSendBehavior{
  Never,
  Prompt,
  Always
}

export enum PartyListType {
  ReservationList = 0,
  WaitList = 1,
  Timeline = 2
}

export interface IgIntegrationDTO {
  IsEnabled: boolean;
  MiddlewareIpAddress: string;
  IsHttpsEnabled: boolean;
}

export class RetailIntegrationDTO {
  public IsEnabled: boolean;
  public TenantId: number;
  public SiteId: number;
}

export class EngageIntegrationDTO {
  public IsEnabled: boolean;
  public TenantId: number;
  public SiteId: number;
}

export interface DataRetentionPolicy {
  Id: number;
  Tenantid: number;
  EffectiveDate: Date;
  CategoryId: number;
  PolicyType: PolicyType;
  CreatedDate: Date;
  PolicyDetails: DataRetentionPolicyDetails[];
}

export interface ManualSlotIdDTO {
  DateTime: Date;
  Id: number;
  IsDefault: boolean;
}
export enum PolicyType {
  Standard = 1,
  Consent = 2
}
export enum PolicyDataType {
  Name = 1,
  EmailAddress = 2,
  PhoneNumber = 3,
  Address = 4,
  GuestImage = 5,
  BirthdayAndAnniversary = 6,
  CustomGuestFields = 7
}

export interface DataRetentionPolicyDetails {
  Id: number;
  DataType: PolicyDataType;
  RetentionInDays: number;
  PolicyId: number;
  CreatedDate: Date;
}

export class ColorDTO {
  A: number;
  R: number;
  G: number;
  B: number;
}

export interface EditableStatusDTO {
  Id: number;
  Name: string;
  AppliesToReservations: boolean;
  AppliesToWalkIns: boolean;
  AppliesToSeatingParties: boolean;
  Color: ColorDTO;
}
export interface StatusDTO extends EditableStatusDTO {
  IsDefault: boolean;
}

// export interface SeatingInfoDTO{
//   StandaloneTableIds:Number[];
//   ServerIdForNonAssignedTables: Number;

// }

export interface PredefinedPartyMessageDTO {
  Id: number;
  Text: SMSMessageTemplateModel;
  AppliesToInitial: boolean;
  AppliesToResponses: boolean;
  IsDefault: boolean;
}

export interface SeatingAreaDTO {
  Id: number;
  Name: string;
  Abbreviation: string;
  Color?: ColorDTO;
  IsSeatingTypeMapped?:boolean;
}

export interface SeatingTypesDTO {
  Id: number;
  Name?: string;
  Abbreviation: string;
  Color?: ColorDTO;
  Description: string;
  IsDeleted: boolean;
  RestaurantId: number;
  SeatingArea: SeatingAreaDTO[];
  SeatingTypeSeatingAreaMappings: SeatingTypeSeatingAreaMappingDTO[];
}

export interface SeatingTypeSeatingAreaMappingDTO {
  Id: number;
  SeatingAreaId: number;
  SeatingTypeId: number;
}

export interface CoverTypeQuantitiesDTO {
  Id: number;
  CoverTypeId: number;
  Covers: number;
  CoverTypeName: string;
}
export interface PredefinedPartyNoteDTO {
  Id: number;
  Text: string;
  Label?: string;
  Icon: string;
  IsDietaryRestriction: boolean;
  SubCategoryId: number;
  Category: string;
}
export interface PredefinedContactNoteDTO {
  Id: number;
  Text: string;
  Label?: string;
  IsDietaryRestriction: boolean;
  Icon: string;
  SubCategoryId: number;
  Category: string;

}
export interface ServerDTO {
  Id: number;
  IgServerId: number;
  Name: string;
  StandaloneTables: StandaloneTableDTO[];
  color?: string;
  LastName: string;
  Code: string;
  ExpertiseLevelIds: number[];
  PhoneNumber: string;
  EmailAddress: string;
  CountryId: number;
  UserName: string;
  Photo: string;
  isPhotoUpload: boolean;
  RestaurantId?: number;
  LastStatusUpdatedAt?: string;
  Load?: number;
  Duration?: string | number;
  IsTemplate?: boolean;
  HostId: number;
}
export interface ShiftStatsDTO {
  ShiftId: number;
  ShiftName: string;
  DisplayedRange: TimeRangeDTO;
  CurrentGuestsSeating: number;
  CurrentPartiesSeating: number;
  TotalGuestsSeated: number;
  TotalPartiesSeated: number;
  AverageTurnTimeInMinutes: number;
  AverageWaitTimeInMinutes: number;
  ServerStats: ServerShiftStatsDTO[];
}
export interface ServerShiftStatsDTO {
  ServerId: number;
  Load: number;
  CurrentGuestsSeating: number;
  CurrentPartiesSeating: number;
  TotalGuestsSeated: number;
  TotalPartiesSeated: number;
  LastActionTime: Date;
}
export interface NamedEntityDTO {
  Id: number;
  Name: string;
}
export interface CoverTypePriceDTO {
  Id: number;
  Price: number;
  CoverTypeName: string;
  MinimumQuantity: number;
  MaximumQuantity: number;
  isMapped?: boolean;
}
export class RolesDTO {
  public Id: number;
  public Name: string;
  public PropertyId: number;
  public PropertyTypeId: number;
}


export class BookingTypeActivitiesMappingDTO {
  public Id: number;
  public Name: string;
  public BookingTypeName: string;
}

export interface SpecialMealDTO {
  Id: number;
  Name: string;
  IsPrepaymentRequired: boolean;
  CoverTypePrices: CoverTypePriceDTO[];
  BookingTypeActivitiesMappings: BookingTypeActivitiesMappingDTO[];
  ActivitySessions: ActivitySessionsDTO[]
  ChargeOnBooking: boolean;
  StartDate: Date;
  EndDate: Date;
  ShiftId: number;
  Price: number;
  ClassType: PricingBy;
  Description: string;
  MinActivityStrength: number;
  MaxActivityStrength: number;
  RatePlanId: number;
  MinPartySize: number;
  MaxPartySize: number;
  CollectType?: CollectType;
  IsForStandbyReservations: boolean;
  LessonType: LessonType;
  RetailItemId: number;
  StatusCode: number;
  PrePaymentMode: PrePaymentMode;
  EnableTicketPrinting: boolean;
  TicketPerPerson: boolean;
  AllowStandBy: boolean;
  ActivityCustomizations: ActivityCustomizationsDTO[];
  ActivityAttributes: ActivityAttributesDTO[];
  AvailableForWebReservation: boolean;
  ActivityTypeId: number;
  CutOffMinutes: number;
  SortOrder: number;
  WaiverFormCode: number;
  categoryType: number;
  AutoConfirmStandby: boolean;
  CoverTypeShortDTOs: CoverTypeShortDTO;
  BookingTypeShortDTOs: BookingTypeShortDTO;
  Color: string;
  CategoryId: number;
  SubcategoryId: number;
  ContactNotes: boolean;
  CustomGuestFields: boolean;
  PrimaryContacts: boolean;
  GuestNames: boolean;
  SpecialOccasions: boolean;
  MembershipDetails: boolean;
  AllowAddingSecondaryGuest: boolean;
  SecondaryContacts: boolean;
  StandbyBooked?: number;
  restaurantId?: number;
  SeatingAreaId?: number;
  AssetBookingType?: number;
  GuestLinkType?: GuestLinkType;
  DefaultPaymentType?: number;
  NotificationEmailIds?:string[];
  SalesContactIds?:number[];
}

export enum AssetBookingType
{
    ExclusiveBooking,
    SharedBooking
}

export interface ActivityAttributesDTO {
  Id: Number;
  PropertyId: Number;
  ActivityId: Number;
  ActivitySessionId: Number;
  Description: string;
  AttributeType: Number;
  ApplyForAllSessions: boolean;
  StartDate: Date;
  EndDate: Date;
  StatusCode: Number;
  CreatedBy: string;
  CreatedAt: Date;
  UpdatedAt: Date;

}
export class CoverTypeShortDTO {
  Id: number;
  RestaurantId: number;
  Name: string;
  isMapped: boolean;
  MinimumQuantity: number;
}
export class BookingTypeShortDTO {
  Id: number;
  RestaurantId: number;
  Name: string;
  isMapped: boolean;
  AdvanceDays: number;
  IsDefault: boolean;
  IsWithinStayRange: boolean;
  BookingGuestType: BookingGuestType;
}
export class SizeTypeMapping extends CoverTypeShortDTO{
  BookingTypeId?: number;
  BookingTypeName?: string;
}
export interface NullableIdNamedEntityDTO {
  Id: number;
  Name: string;
  ExternalId: string;
}

export enum ActivityCustomizationType {
  Block = 1
}

export enum ActivityCustomizationAppliesTo{	
  HostAppAndWidget = 0,	
  HostApp = 1,	
  Widget = 2	
 }

export class ActivitySessionsDTO {
  public Id: number;
  public ActivitySessionId: number;
  public SpecialMealId: number;
  public Dayofweek: number;
  public SessionName: string;
  public SessionDescription: string;
  public StartTime: string;
  public EndTime: string;
  public BookedSlots: number;
  public RatePlanId?: number;
  public SessionLocationMappings: SessionLocationMappingDTO[];
  public SessionStaffMappings: SessionStaffMappingDTO[];
  public SessionGroupName: string;
  public SessionGroupId: number;
  public ParentSessionId?: number;	
  public SessionDate?: string;
  public StatusCode?:Status;
  public Reason?: string;
  public SessionCustomization?: SessionCustomizationDTO;
  public LocationGroupId: number;
  
}

export class SessionCustomizationDTO{
public ActionBy: number;
public MaxActivityStrength : number;
}
export class SpecialMealSessionsDTO {
  public ActivitySessionId: number;
  public SpecialMealId: number;
  public Dayofweek: number;
  public SessionName: string;
  public SessionDescription: string;
  public StartTime: string;
  public EndTime: string;
  public BookedSlots: number;
  public IsBooked:boolean;
  public Index?: number
}
export class SessionLocationMappingDTO {
  public ActivitySessionId: number;
  public StandaloneTableId: number;
  public Id?: number;
}

export class SessionStaffMappingDTO {
  public ActivitySessionId: number;
  public ServerId: number;
  public Id?: number;
}

export class NullableIdNamedEntityDTO {
  public Id: number;
  public Name: string;
  public ExternalId: string;
}
export class CustomGuestFieldDTO {
  public Id: number = null;
  public MasterMerchantId: number = null;
  public FieldType: string = null;
  public FieldName: string = null;
  public FieldDescription: string = null;
  public IsCustomGuestFieldVisible: boolean = null;
  public FieldOptions: string = null;
  public MasterMerchant: MasterMerchant = null;
  public ContactCustomFields: any = null;
  public SelectedFieldMetric:GuestFieldMetrics = null;
}

export class ExpertiseLevelDTO {
  public RestaurantId: number;
  public Id: number = null;
  public ExpertiseCode: string;
  public ExpertiseDescription: string;
  public Value: string = "Test";
  public ExpertiseName: string;
  public StatusCode: number;
}

export class MasterMerchant implements IEntity, ITrackCreatedAtDateTime, ITrackUpdatedAtDateTime, INamedEntity {
  public Id: number;
  public Name: string;
  public CreatedAt: Date;
  public UpdatedAt: Date;
  public UseGuestScore: boolean;
  public GuestScoreVisits: number;
  public IntegrationTenantId: string;
  public Merchants: Merchant[];
  public Restaurants: any[];
  public Contacts: any[];
  public CustomGuestFields: any[];
}
export interface IEntity {
  Id: number;
}
export interface ITrackCreatedAtDateTime {
  CreatedAt: Date;
}
export interface ITrackUpdatedAtDateTime {
  UpdatedAt: Date;
}
export interface INamedEntity {
  Id: number;
  Name: string;
}
export interface IAuthenticatable extends IEntity {
  UserType: UserType;
  MerchantId: number;
  VerifyPassword(password: String, hashManager: IHashManager): boolean;
}
export enum UserType {
  Admin,
  MerchantUser,
  CRSUser
}
export interface IHashManager {
  GenerateSalt(): string;
  HashPassword(password: string, salt: string): string;
  VerifyPassword(password: string, hashedPass: string): boolean;
}
export interface IRestaurantEntity {
  RestaurantId: number;
}


export class Merchant implements IEntity, ITrackCreatedAtDateTime, ITrackUpdatedAtDateTime, INamedEntity {
  public Id: number;
  public Name: string;
  public MasterMerchantId: number;
  public CreatedAt: Date;
  public UpdatedAt: Date;
  public IsOnPremise: boolean;
  public LicenseKey: string;
  public EncryptedLicense: string;
  public MerchantUsers: MerchantUser[];
  public Restaurants: any[];
  public MasterMerchant: MasterMerchant;
}

export class MerchantUser implements IEntity, INamedEntity, IAuthenticatable, ITrackCreatedAtDateTime, ITrackUpdatedAtDateTime {
  constructor() {
    this.MerchantUserRestaurants = {} as MerchantUserRestaurant[];
    this.ApnsTokens = {} as ApnsToken[];
  }
  public Id: number;
  public MerchantId: number;
  public IsSuperAdmin: boolean;
  public IsTrained: boolean;
  public Name: string;
  public Email: string;
  public EncryptedPassword: string;
  public PasswordSalt: string;
  public ResetPasswordToken: string;
  public ResetPasswordSentAt: Date;
  public CreatedAt: Date;
  public UpdatedAt: Date;
  public Merchant: Merchant;
  public MerchantUserRestaurants: MerchantUserRestaurant[];
  public ApnsTokens: ApnsToken[];
  public VerifyPassword(password: string, hashManager: IHashManager): boolean {
    return hashManager.VerifyPassword(password, this.EncryptedPassword);
  }
  public get UserType(): UserType {
    return UserType.MerchantUser;
  }
  // public GenerateResetPasswordToken(): void {
  //    this.ResetPasswordToken = PasswordHelper.GenerateAlphaNumericPassword(32);
  //    this.ResetPasswordSentAt = Date.UTC;
  // }
  public ClearResetPasswordToken(): void {
    this.ResetPasswordToken = null;
    this.ResetPasswordSentAt = null;
  }
}

export class ApnsToken implements IEntity, ITrackCreatedAtDateTime, ITrackUpdatedAtDateTime {
  public Id: number;
  public RestaurantId: number;
  public Token: String;
  public MerchantUserId: number;
  public CreatedAt: Date;
  public UpdatedAt: Date;
  public Restaurant: any;
  public MerchantUser: MerchantUser;
}

export class MerchantUserRestaurant implements IEntity, IRestaurantEntity, ITrackCreatedAtDateTime, ITrackUpdatedAtDateTime {
  public Id: number;
  public MerchantUserId: number;
  public RestaurantId: number;
  public IsAdmin: boolean;
  public CreatedAt: Date;
  public UpdatedAt: Date;
  public MerchantUser: MerchantUser;
  public Restaurant: any;
}

export interface CountryDTO {
  Id: number;
  Name: string;
  CountryPhoneCode: string;
  PhoneNumberLength: number;
  PhoneNumberPattern: string;
  Label?: string;
}

export interface StateDTO {
  Revision: number;
  CurrentTime: Date;
  AreSpecialMealsEnabled: boolean;
  SeatingParties: PartyDTO[];
  WalkIns: PartyDTO[];
  RecentlyLeftParties: PartyDTO[];
  TableServerAssignments: TableServerDTO[];
  AutoServerAreas: AutoServerAreaDTO[];
}

export interface PartyDTO {
  Id: number;
  RestaurantId: number;
  Type: PartyType;
  State: PartyState;
  StateUpdateSource: PartyStateUpdateSource;
  Size: number;
  SeatingAreaId: number;
  TableIds: number[];
  TableNames: string[];
  SeatingTime: Date;
  DepartureTime: Date;
  NoShowedAt: Date;
  CancelledAt: Date;
  CreatedAt: Date;
  UpdatedAt: Date;
  HostId: number;
  StatusId: number;
  PartySourceId?: number;
  CancelationSourceId: number;
  ConfirmationCode: string;
  IsConfirmed: boolean;
  IsVipPartyServed: boolean;
  PagerNumber: string;
  PageMethod: PageMethod;
  LastPagedAt: Date;
  Contact: ContactDTO;
  Notes: PartyNoteDTO[];
  Messages: PartyMessageDTO[];
  CheckSum: number;
  NumberOfChecks: number;
  GuestScore: string;
  ActivityId: string;
  GuestProfileId: string;
  Covers: CoverTypeQuantityDTO[];
  SpecialMealId: number;
  SpecialMeal: SpecialMealsDTO;
  ChargeState: PartyChargeState;
  SeatingTypeId: number;
  ReservedFor:Date;
  SalesContactIds?:number[];
  NotificationEmailIds?:string[];
  CoverTypeQuantities?:CoverTypeQuantityDTO[];
  WishedTime?:string;
  ConciergeId?: number;
  HotelId?:number;
  SelectedAddOns?:AddOnRequest[];
  BookingContactAddonItems?:AddOnRequest[];
  CoverTypes?: CoverTypeQuantityDTO[];
}

export enum PartyChargeState {
  None,
  Pending,
  InProgress,
  Charged,
  ChargeFailed,
  Ignored,
  Refunded,
  RefundFailed
}
export interface SpecialMealsDTO {
  Id: number;
  RestaurantId: number;
  Name: string;
  MinPartySize: number;
  MaxPartySize: number;
  SeatingAreaId: number;
  DoesRequirePrepayment: boolean;
  ChargeOnBooking: boolean;
  DeferredPaymentInDays: number;
  CutOffMinutes: number;
  Price: number;
  SortOrder: number;
  CreatedAt: Date;
  UpdatedAt: Date;
}
export class CoverTypeQuantityDTO {
  Id: number;
  CoverTypeId: number;
  Covers: number;
  CoverTypeName: string;
  Price: Number;
}
export class BookingTypeQuantityDTO {
  public Id: number;
  public BookingTypeId: number;
  public BookingTypes: number;
  public BookingTypeName: string;
  public Price: Number;
}
export class PartyMessageDTO {
  public Id: number = null;
  //public PartyId: number = null;
  public CreatedAt: Date = null;
  public Text: string = null;
  public IsIncoming: boolean = null;
  public IsPostVisitSMS: boolean = null;
  public ContactId:number = null;
  public IsPagedIndividually: boolean = false;
}
export enum PartyNoteType {
  FreeFormNote,
  PredefinedNote,
  SpecialRequest,
  PromoCode,
  SpecialMeal
}
export class PartyNoteDTO {
  public Id: number = null;
  //public PartyId: number = null;
  public Text: string = null;
  public Type: PartyNoteType = null;
  public RelatedId: number = null;
  public addnlId: number;
}
export class ContactDTO {
  public Id: number = null;
  public AliasName: string = null;
  public ContactId: number = null;
  public FirstName: string = null;
  public LastName: string = null;
  public IsVip: boolean = null;
  public IsFavorite: boolean = null;
  public PhoneNumber: string = null;
  public PhoneNumber2: string = null;
  public EmailAddress: string = null;
  public EmailAddress2: string = null;
  public SiteUserId: number = null;
  public GuestScore: number = null;
  public CreatedAt: Date = null;
  public Notes: ContactNoteDTO[] = [];
  public CustomGuestField: CustomGuestFieldDTO[] = [];
  public ContactCustomFields: CustomFieldsDTO[] = [];
  public PIILastUsedDate: Date = null;
  public ConsentDate: Date = null;
  public ConsentPolicyId: number = null;
  public CountryId: number = null;
  public MemeberRoundRedeem : MemberRound = null
  public MemberInfo: any;
  public Staystartdate: string;
  public StayEnddate: string;
  public SearchType: number;
  public ContactIdentifier: string = null;
  public IsSystemGenerated: boolean = null;
  public CommonProfileTenantId: string = null;
  public CommonGuestProfileUUID: string = null;
  
}


export class GuestItineraryGridDate{
  public ActivityName:string = null
  public ConfirmationId:string = null
  public StartDateTime:Date = null
  public EndDateTime:Date = null
  public GroupSize: number
  public Price : any = null
  public Status:string = null
}

export class GuestItinerary extends GuestItineraryGridDate{
  public ProductName : string = null
  public ActivityUuid:string = null
  public ItineraryUuid:string = null
  public PartyId : number
  public LocalActivity : boolean
}

export class MemberRound { 
  CourseCode : string = null;
  CourseName : string = null;
  EligibleRounds : number;
  PlayerLinkId : string = null;
  ConsumedMinute :  number;
  playerCategoryId : number;
  SessionStartTime : string = null;
  RateTypeCode : string  = null;
};
export class CustomFieldsDTO {
  public Id: number = null;
  public ContactId: number = null;
  public CustomFieldId: number = null;
  public CustomFieldValue: string = null;
}
export class ContactNoteDTO {
  public Id: number = null;
  public Text: string = null;
  public RelatedId: number = null;
  public IsDietaryRestriction: boolean = null;
  public RestaurantId: number = null;
}
export enum PartyStateUpdateSource {
  TableSaver,
  CleanUpScript
}

export class TableServerDTO {
  StandaloneTableId: number;
  ServerId: number;
}
export class AutoServerAreaDTO {
  public Id: number = null;
  public ServerId: number = null;
  public FloorPlanViewId: number = null;
  public Color: ColorDTO = null;
  public Points: PointDTO[] = null;
  public LabelOrigin: PointDTO = null;
  public LabelWidth: number = null;
}
export interface PagingOptions {
  PartyId: number;
  pageMethod: PageMethod;
}
export interface SeatingInfoDTO {
  StandaloneTableIds: number[];
  ServerIdForNonAssignedTables?: number;
}
export class RestaurantNoteDTO {
  Id: number;
  Date?: Date;
  Text: string;
}
export interface ServerTableDataSource {
  firstName: string;
  lastName: string;
  // initials: string;
  expertiseLevel: string;
  serverCode: string;
  tables: any[];
  Id: number;
  phoneNumber: string;
  email: string;
  countryId: string;
  photo: string;
  IgServerId?: number;
}

export interface ShiftStatsTableDataSource {
  serverName: string;
  seated: string;
  load: number;
  current: string;
  tally: string;
  duration: string | number;
}

export class ServerScheduleTableDataSource {
  StartDate: string;
  EndDate: string;
  ScheduleId: number;

}


export class ServerSchedulesTableDataSource {
  firstName: string;
  lastName: string;
  expertiseLevel: string;
  noofShifts: number;

}

export interface SynchServerResponseDTO {
  Response: string;
  StatusCode: HttpStatusCode;
}
export interface UpdatedTableBlockingRuleDTO {
  Id?: number;
  FrequencyType: TableBlockingRuleFrequencyType;
  ShiftIds: number[];
  StartDate: string;
  EndDate: string;
  TableIds: number[];
  AllocationTypeId?: number;
  BlockingReason: string;
  BlockBy?: number;
  DayOfWeek: DayOfWeek;
  EffectiveRange?: newTimeRangeDTO[];
}

export interface UnblockingTablesRequestDTO {
  Date: string;
  FrequencyType: TableBlockingRuleFrequencyType;
  TableIds: number[];
  RuleId?: number;
}
export interface PendingChargePartyDTO {
  Id: number;
  PartySize: number;
  FullName: string;
  CountryId:number;
  PhoneNumber:string
  PartyDateTime: any;
  PartyEndDateTime: any;
  NSC: string;
  Time: string;
  OperationCurrency: string;
  NoShowFeeAmount: number;
  ChargeFailedVisibility: boolean;
  IsVip: boolean;
  PartyNotes: any[];
  ContactNotes: any[];
  SpecialMealId: number;
  State?: number;
  PaidAmount?: number;
  Type?: number;
  SpecialMealAmount?: number;
  PrepaymentState?: number;
  ChargeState?: number;
  ActivityName?: any;
  ConfirmationCode: string;
  coverQuantities?: any;
  TotalAmount?: any;
  mappedContact?:any;
  BookingReferenceId:string;
  showCollectCharge: boolean;
  showRefund: boolean;
  showTakeAction:boolean;
  RestaurantId:number;
  BookedSessions:any[];
  BookingContacts:any[];
  Contact?:any;
}
export interface RoleDTO {
  Role: RoleType;
  Permissions: PermissionType[];
}
export class PermissionDTO {
  constructor(name: string, permission?: PermissionType) {
    this.Name = name;
    this.Permission = permission;
    this.Checks = [];
  }
  public Name: string;
  public Permission?: PermissionType;
  public Checks: PermissionCheckDTO[];
}
export class PermissionCheckDTO {
  constructor(roleType: RoleType, isChecked: boolean, permissionType?: PermissionType) {
    this.RoleType = roleType;
    this.IsChecked = isChecked;
    this.PermissionType = permissionType;
  }
  public RoleType: RoleType;
  public PermissionType?: PermissionType;
  public IsChecked: boolean;
}
export class PermissionsList {
  private static _list: PermissionType[] = [
    PermissionType.AddSlots,
    PermissionType.RemoveSlots,
    PermissionType.EditSlots,
    PermissionType.AddReservations,
    PermissionType.EditReservations,
    PermissionType.NoShowReservations,
    PermissionType.CancelReservations,
    PermissionType.Overbook,
    PermissionType.AddToStandByList,
    PermissionType.EditStandByParties,
    PermissionType.RemoveStandByParties,
    PermissionType.BookInNonWebReservableSlots,
    PermissionType.BookInSlotsWithInappropriateSize,
    PermissionType.PageParties,
    PermissionType.SendFreeformPageMessages,
    PermissionType.ManageTableBlockingRules,
    PermissionType.BookOrSeatAtBlockedTables,
    PermissionType.MoveSeatedParties,
    PermissionType.AddReservationwithNeverAssign
  ]
  public static List: PermissionType[] = PermissionsList._list;
}
export interface PermissionTypeHelper {
  Id: number;
  Name: string;
}

export interface RolesPermissionDTO {
  Name: string;
  Permission: number;
  HostPremission: boolean;
  ReservationistPermission: boolean;
}

export interface ShiftDTO {
  Id: number;
  Name: string;
  EffectiveRange: TimeRangeDTO;
  DisplayedRange: TimeRangeDTO;
  IsWebReservable: boolean;
  DayOfWeek: DayOfWeek;
  Date: Date;

}

export interface OpenHoursDTO {
  Id: number;
  EffectiveRange: TimeRangeDTO;
  DisplayedRange: TimeRangeDTO;
  DayOfWeek: DayOfWeek;
  Date?: Date;
  Reservations: ReservationDTO[];
  StandByParties: StandByPartyDTO[];
}
export class ScheduleDTO {
  public ScheduleId: number;
  public ServerId: number;
  /*  public EnabledShiftIds: number[];
   public DisabledShiftIds: number[]; */
  public Shifts: ScheduleShiftDTO[];
  public BreakHours: BreakHoursDTO[];
  public StartDate: string;
  public EndDate: string;
  //public EndDate:string;
}

export class ScheduleShiftDTO {
  public ShiftId: number;
  public locationId: number;
  public isEnabled: boolean;
}

export class BreakHoursDTO {
  public BreakHourId: number;
  public ShiftId: number;
  public IsCustomBreak: boolean;
  public EffectiveRange: newTimeRangeDTO;
  public Date: string;
  public IsNew: boolean;
  public ServerId?: number
}


export class AllocationTypeDTO {
  public Id: number;
  public MerchantId: number;
  public AllocationName: string;
  public AllocationCode: string;
  public AllocationDescription: string;
  public BlockType: number;
  public AllocationPropertyMappings: any[];
  public color: any;
}

export class MissingPermissionDTO {
  constructor(name: string, isNotAvailable: boolean, availabilityText: string) {
    this.Name = name;
    this.IsNotAvailable = isNotAvailable;
    this.AvailabilityText = availabilityText;
  }
  public Name: string;
  public IsNotAvailable: boolean;
  public AvailabilityText: string;
}

export interface IGResponseDTO {
  Response: string;
  StatusCode: HttpStatusCode;
  ErrorMessage: string;
}

export interface SlotOutput {
  actionName: ManualSlotSelection;
  slot: any;
}

export class SecondaryContactDTO {
  Id?: number = null;
  FirstName: string = null;
  LastName: string = null;
  AliasName: string = null;
  ContactNotes: string = null;
  Notes: ContactNoteDTO[] = null;
  PartyId: number = null;
  PhoneNumber: string = null;
  EmailAddress: string = null;
  isNew?: boolean = null;
  tempId?: number = null;
  isFormValid?: boolean = null;
  ContactId?: number = null;
  TrackMembershipNumber?: string = null;
  CountryId?: number = null;
  Birthday?: string = null;
  Anniversary?: string = null;
  ContactCustomFields?: CustomFieldsDTO[] = [];
  PhoneNumber2?: string = null;
  EmailAddress2?: string = null;
  CountryId2?: number = null;
  MemberType?: string = null;
  StayConfirmationCode?: string = null;
  SearchType?: number = null;
  CreditCards =[] ;
  IsSystemGenerated:boolean = false;
}

export class BookedSessionDTO {
  Id?: Number = null;
  PartyId: Number = null;
  ActivitySessionId: Number = null;
  BookedDate: Date = null;
  BookingSize: Number = null;
  SessionState: PartyState = null;
  RatePlanAmount: Number = null;
  NegotiatedAmount: Number = null;
  SessionType: PartyType = null;
  UniqueId: string = null;
  TaxAmount: number = null;
  PackageDiscountedAmount: number = null;
  PackageDiscountPercent: number = null;
  OverBooked: number = 0;
  Locations:number[] =[];
  PaymentStatus:PartyPrepaymentState = null;
}

export class BookingAmountsDTO {
  AddonId: number = null;
  Amount: number = null;
  BookedSessionId: number = null;
  BookingChargeId: number = null;
  BookingChargeType: number = null;
  NegotiationChargeType: NegotiationChargeType = 0;
  Id: number = null;
  PartyId: number = null;
  NegotiatedAmount: number = null;
  Taxes: string = null;
  BookingChargeTaxAmount: number = null;
}

export class BookingContactAddonItemDTO {
  Id: number = null;
  AddonId: number = null;
  RestaurantId: number = null;
  PartyId: number = null;
  ContactId: number = null;
  BookedSessionId: number = null;
  SessionGroupId: number = null;
  BookingContactId:number = null;
  AddonCount: number = null;
  Amount: number = null;
  TaxAmount: number = null;
  OverBooked: number = 0;
}

export class BookingContactDTO {
  Id: number = null;
  PartyId: number = null;
  ContactId: number = null;
  CreditCardId?: number = null;
  CoverTypeId?: number = null;
  BookingTypeId?: number = null;
  BookedSessionId?: number = null;
  Size: number = null;
  Amount: number = null;
  State: PartyState = null;
  PaymentStatus: PartyPrepaymentState = null;
  MemberRateType: string = null;
  MemberBucket: string = null;
  MemberInformationId: number = null;
  FirstName?:string = null;
  LastName?:string = null;
  EmailAddress?:string = null;
  CountryId?:number = null;
  PhoneNumber?:number = null;
  IsVip:boolean = false;
  PreferredPageMethod?:number = null;
  WaiverFormURL:string = null;
}

export class BookingContactStateDTO {
  Id: number = null;
  BookingContactId:number = null;
  BookedSessionId?:number = null;
  CheckInSize: number = null;
  CheckOutSize: number = null;
  State:PartyState = null;
  PaymentStatus:PartyPrepaymentState = null;
}
export class AutoServerArea {
  Id: number = null;
  RestaurantId: number = null;
  ServerId: number = null;
  FloorPlanViewId: number = null;
  LabelWidth: number = null;
  //CreatedAt: Date;
  //UpdatedAt: Date;
  PointsInternal: string = null;
  LabelXInternal: number = null;
  LabelYInternal: number = null;
  IsLabelVisibleInternal: boolean = false;
  ColorAInternal: number = null;
  ColorRInternal: number = null;
  ColorGInternal: number = null;
  ColorBInternal: number = null;
}
export enum CollectType {
  CheckIn = 0,
  CheckOut = 1
}
export enum NegotiationChargeType {
  None = 0,
  BasePrice = 1,
  Location,
  Staff,
  CoverType,
  BookingType,
  Membership,
  AdditionalRate,
  SpecialMeal,
  Tax,
  Addon,
  ServiceCharge,
  PerSubTotalLesson,
  PerSubTotalAddon,
  PerTotal
}
export class RetailOpenItemDTO {
  Id?: number;
  PartyId: number;
  Description: string;
  ItemDescription: string;
  ServiceId: number;
  GuestId: number;
  GuestName: string;
  ProductPrice: number;
  SalesPrice: number;
  QuantitySold: number;
  ExternalPOSItemId: number;
  BookingType: any;
  ItemId: number;
  RatePlanAmount: number;
  costPrice: number;
  category: string;
  Tax: number;
  ActivitySessionId: number = null;
  BookedDate: Date = null;
  BookingSize: number = null;
  SessionState: PartyState = null;
  SessionType: PartyType = null;
  TransactionId: number;
  TicketNumber: string;
  BookingAttemptId?: number;
  fromWidget?: boolean;
  fromCancel?: boolean;
  CartId?: number;
  hideZeroPrice?: boolean;
  PayeeId?: number;
  CancellationFee: number;
  ignoreTransaction: boolean;
  TransDetailId?: number;
  serviceCharges?: any;
  MemberId?: string;
  MemberActive?: boolean; 
  MemberRound : ActivityPoint[];

}
export class RetailItems {
  public Id: number;
  public ExternalPOSItemId: number;
  public RetailItemId: number;
  public RetailItemName: string;
  public IsDefault: boolean;
  public IsActive: boolean;
  public ItemType: ItemType;
}

export class PrintersInfo {
  public Id: number;
  public PropertyId: number;
  public Port: number;
  public IPAddress: string;
  public PrinterName: string;
  public IsDefault: boolean;

}

export enum PrePaymentMode {
  None,
  PrepaidPaymentMode,
  DefferredPaymentMode,
  DepositPaymentMode,
  ChargeduringCheckIn,
  ChargeduringCheckOut,
  CardOnFile
}


export enum ItemType {
  None = 0,
  Deposit = 1,
  CancellationFee = 2,
  DepositAndCancellationFee = Deposit | CancellationFee
}


export interface PropertiesUnderMerchantDTO {
  RestaurantId: number;
  RestaurantName: string;
}


export class TicketModel {
  PropertyInfo: PropertyInfo[];
  BookingInfo: BookingInfo[];
}
export class PropertyInfo {
  Key: string;
  Order: number;
  Value: string;
}

export class BookingInfo {
  Key: string;
  Order: number;
  Value: string;
  Title: string;
  string: string;
}

export class ActivityCustomizationsDTO {	
  CustomizationId: number = null;	
  PropertyId: number =null;	
  CustomizationType: ActivityCustomizationType = null;	
  ActivityId: number = null;	
  //ActivitySessionId: number = null;	
  ActivitySessionIds: number[] = null;	
  StartDate: Date = null;	
  EndDate: Date = null;	
  ActionBy: string = null;	
  CreatedAt: Date = null;	
  UpdatedAt: Date = null;	
  ActionReason: string = null;	
  AppliesTo: ActivityCustomizationAppliesTo = null;	
}

export interface ActivityAttributesDTO {
  Id: Number;
  PropertyId: Number;
  ActivityId: Number;
  ActivitySessionId: Number;
  Description: string;
  AttributeType: Number;
  ApplyForAllSessions: boolean;
  StartDate: Date;
  EndDate: Date;
  StatusCode: Number;
  CreatedBy: string;
  CreatedAt: Date;
  UpdatedAt: Date;

}

export class ShopItem {
  ItemId: number;
  ItemDescription: string;
  ItemType: number;
  ExternalPOSItemId: number;
  QuantitySold: number;
  UnitPrice: number;
  Discount: number;
  DiscountTypeId: number;
  DiscountPercentage: number;
  LineNumber: number;
  Tax: number;
  TaxAmount: number;
  TotalAmount: number;
  OutletId: number;
  IsGroupingKey: number;
  IsPackagedItem: number;
  PackageItemId: number;
  IsMultiPackRedeem: number;
  IsModificationRestricted: number;
  ClientMultiPackId: number;
  PackageGroupId: number;
  IsOpenPricedItem: number;
  Id: number;
  DiscountModel: number;
  CostPrice: number;
  MarginPercentage: number;
  AllowEarn: number;
  DiscountComments: number;
  DiscountReason: number;
  GroupingParentId: number;
  GroupingUniqueIdentifier: number;
  GroupingItemDescription: number;
  BookingAttemptId: number;
  PartyId: number;
  Noofitems: number;
  GuestId: number;
  GuestName: string;
  category: string;
  FromWidget: boolean;
  hideZeroPrice: boolean;
  CartId: number;
  ignoreTransaction: boolean;
  TransDetailId:number ;
  ServiceChargeGratuity: ServiceChargeGratuity;
  ServiceChargeAmount: number;
  ContactId: number;
  ReservationAttemptId: number;
  ServiceCharges: any[];
  IsReturn: boolean;
  Gratuity?: any[];
}

export class ServiceChargeGratuity {
 /*  date: number;
  itemId: number;
  serviceChargePercent: number;
  serviceCharge: number;
  totalServiceCharge: number;
  id: number; */
  Id: any;
  TransactionDetailId: number;
  TherapistId: any;
  Percentage: number;
  PercentageId: number;
  PercOrAmount: number
  Amount: number;
  ServiceCharge: number;
  StaffType?: any;
  ServiceChargeTax?: number;
  additionalGratuity?: number;
  additionalGratuityPercent?: number;
  additionalServiceCharge?: number;
  additionalServiceChargePercent?: number;
  netGratuity?: number;
  netServiceCharge?: number;
}

export class LocationGroupDTO {
  LocationGroupId: number;
  PropertyId: number;
  Name: string;
  StatusCode: Status;
}
    
export enum TimelineAxis {
  X = 'X',
  Y = 'Y'
}

export enum TaxType {
  TAX = 0,
  VAT = 1
}

export interface MerchantSettings {
  AutoPrintChit: SettingHelper;
  IncludeAddOns: SettingHelper;
  IncludeContactNotes: SettingHelper;
  IncludeCovers: SettingHelper;
  IncludeNotes: SettingHelper;
  IdleTimeoutInSeconds:SettingHelper;
  EnableReminderSMSEmail: SettingHelper;
  WhoWillBeNotified: SettingHelper;
  ShowDayNameinTimeline: SettingHelper;
  TimelineHeaderMinutesInterval: SettingHelper;
  defaultColumnWidth: SettingHelper;
  PopulateDynamicGuests: SettingHelper;
  AutoSearchEnabled: SettingHelper;
  DefaultPaymentType: SettingHelper;
  EnableEngageAccrual: SettingHelper;
  NotificationUserIds:SettingHelper;
  NotificationType: SettingHelper;
  NotificationEmailIds: SettingHelper;
  NotificationCutOff: SettingHelper;
  ShortNoticeAlert: SettingHelper;
  EnableEmailNotes: SettingHelper;
  ReminderMinutesinAdvance: SettingHelper;
  SortingOrderBasedOn: SettingHelper;
  EnableWristband: SettingHelper;
  NumberOfFractionalDigits: SettingHelper; 
  EnableConsolidatedEmail: SettingHelper;
  OverrideExistingCreditCard: SettingHelper;
  EnableCardEncoding: SettingHelper;
  PermRoomNoForAccessKeys: SettingHelper;
  ZeroTenderName: SettingHelper;
}

export interface SettingHelper {
  CategoryName: string;
  IsVisible: boolean;
  SettingId: number;
  SettingName: string;
  SettingValue: string;
  SettingMasterId: number;
  ComponentType: FieldType;
  DataType: DataTypes;
  Description: string;
  IsToolTipVisible: boolean;
  MinValue: number;
  MaxValue: number;
  IsInfoIconVisible: boolean;
  OrderSequence: number;
  ComponentOptions: string;
}

export enum SettingType {
  DISABLE = "false",
  ENABLE = 'true'
}


export enum WhoWillBeNotifiedType {
  Primary = '0',
  IndividualGuest = '1',
}

export interface StaffBlockDTO {
  StaffIds: number[];
  isEdit?: boolean;
  BreakHourId: number;
  ShiftId?: number;
  StaffScheduleId?: number[]
  EffectiveRange: newTimeRangeDTO;
  Date: Date | string;
}


export class GuestNotificationRequestDTO
{
     ReservationId: number;
     ContactId: number;
     EmailAddress: string;
     PhoneNumber: string;
      CountryId: number;
     SendReminder: boolean;
     ConfirmationVia: ConfirmationVia;
     NotificationFor: NotificationFor;
}

export enum NotificationFor
{
     None = 0,
     Reservation = 1,
     Reminder = 2,
     ReservationAndReminder = 3
}

export class BookingNotificationRequestDTO
{
    CartBookingId: string;
    ReservationId: number;
    ReservationEmailNotificationType: ReservationEmailNotificationType;
    Language: string; 
    CancelledIds: number[];
    BookedSessionId: number; 
    ClassOrSessionBooking: boolean;
    IsStandBy: boolean;
    ConfirmedBookedSessionId: number;
    GuestNotificationRequest: GuestNotificationRequestDTO[];
 
}

export enum ConfirmationVia
{
  None =-1,
    Email,
    Sms,
    Both
    
}

export interface PageRequest {
  BookingId: number
  Message: string
  templateCode: string;
  GuestInfo: GuestDetails
}

export interface GuestDetails {
  ContactId: number;
  PageMethod: PageMethod
}
export interface PayingGuest {
  ActivitySessionId: number;
  BookingDate: Date | string;
  PayingContacts: number[];
  ContactIdentifier: string[];
}