<div class="reservation-date ">
  <div class="reservation-date__date-details">
    <div class="reservation-date__date-view">
      <span class="reservation-date__select-text reservation-select-label rs-Roboto-Regular-24">{{'reservationSelectDate'|translate}}</span>
      <span class="reservation-date__select-day">{{waitDate | localizedDate: 'dddd'}}</span>
      <span class="reservation-date__select-date">{{waitDate | localizedDate: 'MMM DD'}}th</span>
    </div>
    <div class="reservation-date__calendar-view">
      <div>
        <mat-calendar [selected]="partyService.reservationFormGroup.value.selectedDate"
          (selectedChange)="setSelectedDate($event)" [startAt]="partyService.reservationFormGroup.value.selectedDate"
          [minDate]="date" [maxDate]="maxResDate" *ngIf= !partyService.isPastReservation></mat-calendar>
          <mat-calendar [selected]="partyService.reservationFormGroup.value.selectedDate"
          (selectedChange)="setSelectedDate($event)" [startAt]="partyService.reservationFormGroup.value.selectedDate"
          [maxDate]="partyService.reservationFormGroup.value.selectedDate" [minDate]="partyService.reservationFormGroup.value.selectedDate" *ngIf= partyService.isPastReservation></mat-calendar>

      </div>
    </div>
  </div>
</div>