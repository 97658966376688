import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTableModule } from '@angular/material/table';
import { MaterialModule } from "@app/material-module";
import { DashboardStatusComponent } from "@app/shared/components/dashboard/status/dashboard-status.component";
import { SharedModule } from "@app/shared/shared.module";
import { MomentModule } from "angular2-moment";
import { NgDragDropModule } from "ng-drag-drop";
import { NgScrollbarModule } from "ngx-scrollbar";
import { ActivitiesTimelineRoutingModule } from "./activities-timeline-routing.module";
import { ActivitiesTimelineMaiComponent, ConfigDateFormatter, InstructorNameFormatPipe, PartyDataFiltPipe, SlotWidthDirective, TemplateColumnConfigPipe, TimeArrayRefactorPipe, WeekDateGeneratorPipe } from "./components/activities-timeline-main.component";
import { ActivityDetailsComponent } from './components/activity-details/activity-details.component';
import { CategoryIndicatorComponent } from "./components/category-indicator/category-indicator.component";
import { ClassDetailsComponent, FilterByType, StaffNameToolTipFormatter,PaymentDetailPipe } from "./components/class-details/class-details.component";
import { PrivateLessonBookingDetailComponent } from "./components/privatelesson-booking-detail/privatelesson-booking-detail.component";
import { ColumnGeneratorDirective, GridColumnsGeneratorDirective, SubColumnGeneratorDirective, TimelineRowComponent } from "./components/row/timeline-row.component";
import { SessionCardComponent } from "./components/session-card/session-card.component";
import { GetGridColumn, SortDataPipe, SpecialMealNamePipe, TimelineFilterDataPipe, TimelineMainComponent} from "./components/timeline-main.component";
import { FilterByNamePipe, SortByTimePipe, WeeklyViewComponent } from "./components/weekly-view/weekly-view.component";
import { ActivityColorPipe } from "./pipes/activity-color.pipe";
import { ActivitiesTimelineService } from "./services/activities-timeline.service";
import { OpenBookingDetailComponent } from "./components/open-booking-detail/open-booking-detail.component";
import { ChatInfoContainerComponent } from "./components/chat-info-container/chat-info-container.component";
import { UpdateConfirmationDetailComponent } from "./components/update-confirmation-detail/update-confirmation-detail.component";
import { TimelineColumnComponent, RowColumnGeneratorDirective, GridTemplateDirective } from './components/timeline-column/timeline-column.component';
import { AssignInstructorComponent } from "./components/assign-instructor/assign-instructor.component";

@NgModule({
    imports: [
      SharedModule,
      CommonModule,
      ActivitiesTimelineRoutingModule,
      MaterialModule,
      MatTableModule,
      NgScrollbarModule,
      MomentModule,   
      ScrollingModule,
      FormsModule,
      NgDragDropModule.forRoot()
    ],
    declarations: [
      ActivitiesTimelineMaiComponent, 
      TimelineMainComponent,
      SlotWidthDirective, ActivityDetailsComponent,
      PartyDataFiltPipe, 
      TimeArrayRefactorPipe,
      WeekDateGeneratorPipe,
      InstructorNameFormatPipe,
      ActivityColorPipe,
      FilterByNamePipe,
      SortByTimePipe,
      StaffNameToolTipFormatter,
      ConfigDateFormatter,
      FilterByType,
      ClassDetailsComponent,
      PrivateLessonBookingDetailComponent,
      TimelineRowComponent,
      WeeklyViewComponent,
      ColumnGeneratorDirective,
      GetGridColumn,
      GridColumnsGeneratorDirective,
      SubColumnGeneratorDirective,
      SpecialMealNamePipe,
      SortDataPipe,
      SessionCardComponent,
      TemplateColumnConfigPipe,
      PaymentDetailPipe,
      CategoryIndicatorComponent,
      TimelineFilterDataPipe,
      TimelineColumnComponent,
      RowColumnGeneratorDirective,
      UpdateConfirmationDetailComponent,
      GridTemplateDirective,
      AssignInstructorComponent
    ],
    entryComponents: [ActivityDetailsComponent, DashboardStatusComponent,ClassDetailsComponent,PrivateLessonBookingDetailComponent, UpdateConfirmationDetailComponent],  
    exports:[ActivitiesTimelineMaiComponent, 
      SlotWidthDirective, ActivityDetailsComponent,
      PartyDataFiltPipe, 
      TimeArrayRefactorPipe,
      InstructorNameFormatPipe,
      StaffNameToolTipFormatter,
      FilterByType,
      ClassDetailsComponent,      PrivateLessonBookingDetailComponent,
      ChatInfoContainerComponent,
      TimelineRowComponent,
      ColumnGeneratorDirective,
      GridColumnsGeneratorDirective,
      SubColumnGeneratorDirective,
      RowColumnGeneratorDirective,
      GridTemplateDirective
    ], 
    providers:[ PartyDataFiltPipe,TimeArrayRefactorPipe,WeekDateGeneratorPipe,InstructorNameFormatPipe, ActivityColorPipe, StaffNameToolTipFormatter,FilterByType, FilterByNamePipe, SortByTimePipe, ConfigDateFormatter,SpecialMealNamePipe, TimelineFilterDataPipe, ActivitiesTimelineService]
  })
  export class ActivitiesTimelineModule { }
