import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationMessagesPopupComponent } from '@components/confirmation-messages-popup/confirmation-messages-popup.component';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { ComponentTypes, ShapeTypeEnum, Status } from '@constants/commonenums';
import * as globalConst from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { FloorPlanImagesDto, InputImages } from '@models/FloorPlanImageInputDto';
import { SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { FloorplanService } from '@services/floorplan.service';
import { Utilities } from '@utilities/utilities';
import { Subscription } from 'rxjs/Subscription';
import { ActivitiesVenue,controlSettings} from '@constants/globalConstants';
import { AppService } from '@app/app.service';
import _ from 'lodash';

export interface ImgContents {
  name: string;
  imgURL: string;
}

@Component({
  selector: 'app-editor-tables',
  templateUrl: './editor-tables.component.html',
  styleUrls: ['./editor-tables.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditorTablesComponent extends Utilities implements OnInit, OnDestroy, AfterViewInit {
  tableConfig: FieldConfig[];
  labelConfig: FieldConfig[];
  snappingConfig: FieldConfig[];
  maxCount = 51;
  seatingTypes: any;
  seatingAreas: any;
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  @ViewChild('labelform', { static: true }) dynamicFormLabel: DynamicFormComponent;
  @ViewChild('snappingForm', { static: true }) dynamicFormSnapping: DynamicFormComponent;
  _settings: SettingsDTO = {} as SettingsDTO;
  tableName: string = '';
  joinedToCommunalError = false;
  objectSelected = false;
  subscriptions: Subscription = new Subscription();
  hideLabelTextEditor = false;
  imageLibrary: InputImages[] = [];
  maxFileCountToUpload = 200;
  availableFileExtensions = globalConst.fileExtensions;
  maxImageSize = globalConst.MaxFileSize;
  isEditGallery = false;
  uploadImages: string[] = [];
  selectedObjectInfo: any;
  isDefaultImage: boolean;
  isDefaultShapeSelected: boolean;
  //toolIndex = -1; moved to floorPlan service

  constructor(public fs: FloorplanService, private ts: TranslateService, private cs: CacheService,
              dialog: MatDialog, private popupService: PopupService, private appService : AppService) {
    super(dialog);
    this.subscriptions.add(this.cs.restaurantImages.subscribe((images: FloorPlanImagesDto) => {
      if (images)
        this.imageLibrary = images.ObjectImages;
    }));
  }

  ngOnInit() {
    const partySizeArray = this.fs.calculatePartySize(this.maxCount);
    const maxPartySize: number = this.fs._settings.General.MaxPartySize;
    this.tableConfig = [
      {
        type: 'input',
        name: 'tableNumber',
        label: this.ts.instant('guestBookTableNumberText'),
        class: 'table-number',
        showErrorText: true,
        appearance: false,
      },
      // {
      //   type: 'select',
      //   name: 'seatingType',
      //   label: 'Seating Type',
      //   options: [],
      //   class: 'seating-type',
      //   showErrorText: true,
      //   appearance: false,
      //   isTranslate: true,
      //   cellClick: this.setSeatingType.bind(this)
      // },
      {
        type: 'select',
        name: 'seatingArea',
        label: this.ts.instant('seatingArea'),
        options: [],
        class: 'seating-area ml-0',
        showErrorText: true,
        appearance: false,
        isTranslate: true
      },
      {
        type: 'switch',
        name: 'availableForReservation',
        inputType: 'text',
        label: 'availableForReservation',
        class: this.isActivityProperty() ? 'editor-tables__available-hide' : 'editor-tables__available'
      },
      {
        type: 'switch',
        name: 'communalTable',
        inputType: 'text',
        label: 'communalTableAvailability',
        class: this.isActivityProperty() ? 'editor-tables__communal-hide' : 'editor-tables__communal',
      },
      {
        type: 'autocomplete',
        name: 'minPartySize',
        label: 'minSize',
        options: partySizeArray,
        class: 'editor-tables__min-size',
        showErrorText: true,
        value: partySizeArray[0].id,
        partySize: true,
        charLength: maxPartySize.toString().length,
      },
      {
        type: 'autocomplete',
        name: 'maxPartySize',
        label: 'maxSize',
        options: partySizeArray,
        class: 'editor-tables__max-size',
        showErrorText: true,
        value: partySizeArray[0].id,
        partySize: true,
        charLength: maxPartySize.toString().length
      },
      {
        type: 'input',
        name: 'bufferTimeInMinutes',
        label: this.ts.instant('bufferTimeInMinutes'),
        class: 'editor-tables__buffer-time',
        showErrorText: true,
        appearance: false,        
        inputType: 'number'
      }
    ];
    this.snappingConfig = [
      {
        type: 'switch',
        name: 'nearestObjects',
        label: 'nearestObjects',
        class: 'editor-tables__grid-lines',
        checked: false,
      },
      {
        type: 'switch',
        name: 'gridlines',
        label: 'gridLines',
        class: 'editor-tables__grid-lines',
        checked: false,
      },
      {
        type: 'slider',
        name: 'gridlinesSlider',
        class: 'editor-tables__grid-lines',
        min: 0,
        max: 100,
        disabled: true,
        step: 10,
        cellClick: this.gridSliderChanged.bind(this),
      }
    ];
    this.labelConfig = [
      {
        type: 'input',
        name: 'labelText',
        label: 'text',
        class: 'table-number',
        showErrorText: true,
        appearance: false,
      }
    ];
    if(!Utilities.controlValidate(controlSettings.Layout_Show_BufferTime, this.appService.PropertyType)){
      this.tableConfig = _.remove(this.tableConfig , item => item.name !== 'bufferTimeInMinutes') 
    }
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this.seatingTypes = sett.SeatingTypes.filter(sType => sType.IsDeleted == false );
      this.seatingTypes =this.seatingTypes.map(x => ({ id: x.Id, value: x.Description }));
      this.seatingTypes = this.seatingTypes.filter(sa => sa.id != -1 );
      this.seatingAreas = sett.SeatingAreas.map(x => ({ id: x.Id, value: x.Name }));
      this.seatingAreas = this.seatingAreas.filter(sa => sa.id != -1);
      this.loadSeatingOptions();
    }));

    if (this.seatingTypes && this.seatingTypes.length > 0) {
      this.tableConfig.splice(1, 0, {
        type: 'select',
        name: 'seatingType',
        label: this.ts.instant('seatingType'),
        options: [],
        class: 'seating-type',
        showErrorText: true,
        appearance: false,
        isTranslate: true,
        cellClick: this.setSeatingType.bind(this)
      });
    }
    if (this.cs.settings.value?.LocationGroups?.length) {
      let locationGroup = this.cs.settings.value?.LocationGroups?.filter(group => group.StatusCode == Status.Approved);
      if(locationGroup?.length){
      let index = this.tableConfig.findIndex(config => config.name == 'seatingArea');
      let options = locationGroup?.map(location => { return {id : location.LocationGroupId, value: location.Name}}) || [];
      options.unshift({id:-1 , value: this.ts.instant('None')});
      this.tableConfig.splice((index + 1), 0, {
        type: 'select',
        name: 'LocationGroupId',
        label: this.ts.instant('locationGroup'),
        options: options || [],
        class: 'seating-type',
        showErrorText: true,
        appearance: false,
        isTranslate: true,
        value: -1,
      });
     }
    }
    this.loadSeatingOptions();
    this.subscriptions.add(this.fs.selectedSeatingTypeId.subscribe(val => {
      if (val != null) {
        this.setSeatingType(val);
      }
      else
        this.loadSeatingOptions();
    }));
  }

  loadSeatingOptions() {
    let _seatingType = this.tableConfig.filter(config => config.name == "seatingType");
    if (_seatingType.length > 0) {
      this.tableConfig.filter(config => config.name == "seatingType")[0].options = this.seatingTypes;
      this.tableConfig.filter(config => config.name == "seatingType")[0].value=_seatingType[0].value;
    }
   let tempseatareavalue= this.tableConfig.filter(config => config.name == "seatingArea")[0].value;
    this.tableConfig.filter(config => config.name == "seatingArea")[0].options = (_seatingType?.length &&  _seatingType?.[0].value) ? this.setSeatingType(_seatingType?.[0].value) :this.seatingAreas;
    this.tableConfig.filter(config => config.name == "seatingArea")[0].value =tempseatareavalue;
  }

  setSeatingType(val) {
    let value = isNaN(Number(val)) ? val.value : val;
    const seatingTypesMapping = this.cs.settings.value.SeatingTypeSeatingAreaMappings; //this.fs._settings.SeatingTypeSeatingAreaMappings;
    const filteredSeatingType = seatingTypesMapping.filter(x => value == x.SeatingTypeId);
    const seatingAreas = this.seatingAreas; //this.fs._settings.SeatingAreas;
    let selectedAreaSelected = [];
    seatingAreas.forEach(element => {
      if (filteredSeatingType.filter(x => x.SeatingAreaId == element.id).length > 0){
        var tempSeatingArea = Object.assign({},element);
        selectedAreaSelected.push(tempSeatingArea);
      }
    });
    let seatingAreaIndex = this.tableConfig.findIndex(config => config.name == 'seatingArea');
    this.tableConfig[seatingAreaIndex].options = selectedAreaSelected.map(x => ({ id: x.id, value: x.value }));
    if (isNaN(Number(val)))
      this.fs.tableSettingsForm.controls.seatingArea.setValue(selectedAreaSelected[0].id);
  }

  isActivityProperty(){
      return ActivitiesVenue.find(x=>x == this.fs._settings.PropertyType) ? true : false;
    }
  removeErrorMsg() {
    this.fs.joinedToCommunalError$.next({ value: false, name: '' });
  }

  gridSliderChanged(event) {
    this.fs.gridSliderValue$.next(event.value);
  }

  ngAfterViewInit() {
    this.fs.tableSettingsForm = this.dynamicForm.form;
    this.fs.labelSettingsForm = this.dynamicFormLabel.form;
    this.dynamicFormSnapping.form.valueChanges.subscribe(data => {
      
      if (data.gridlines) {
        this.fs.setFloorGridLines$.next(true);
        this.snappingConfig[2].disabled = false;
      }
      else {
        this.fs.setFloorGridLines$.next(false);
        this.snappingConfig[2].disabled = true;
      }
      if (data.nearestObjects) {
        this.fs.nearestObject = true;
      }
      else {
        this.fs.nearestObject = false;
      }
    });
    this.subscriptions.add(this.fs.joinedToCommunalError$.subscribe(val => {
      if (val) {
        this.joinedToCommunalError = val.value;
        this.tableName = val.name;
      }
    }));
    this.subscriptions.add(this.fs.onSelectionCleared.subscribe(val => {
      this.objectSelected = !val;
    }));
    this.subscriptions.add(this.fs.selectedTableBeforeAction$.subscribe(val => {
      console.log(this.fs.tableSettingsForm.value);
      let seatingAreaIndex = this.tableConfig.findIndex(config => config.name == 'seatingArea');
      let seatingTypeIndex = this.tableConfig.findIndex(config => config.name == 'seatingType');
      this.tableConfig[seatingAreaIndex].value =this.fs.tableSettingsForm.value.seatingArea;
      this.tableConfig[seatingTypeIndex].value = this.fs.tableSettingsForm.value.seatingType;
    }));
    this.subscriptions.add(this.fs.tableSelected$.subscribe(val => {
      this.objectSelected = true;
      this.hideLabelTextEditor = true;
      if (this.objectSelected && (val.ShapeType == 3 || val.ShapeType == 4)) {
        this.isDefaultShapeSelected = true;
        this.isEditGallery = false;
        this.selectedObjectInfo = val;
        this.imageLibrary.forEach((image) => {
          if (image.ImageId == val.ImageId) {
            image.isImageSelected = true;
          } else {
            image.isImageSelected = false;
          }
        })
        this.isDefaultImage = false;
      } else {
        this.isDefaultShapeSelected = false;
      }
    }));
    this.subscriptions.add(this.fs.labelSelected$.subscribe(val => {
      this.objectSelected = true;
      this.hideLabelTextEditor = false;
    }));
  }

  selectCutomObject(selectedImage) {
    if (selectedImage) {
      this.fs.toolIndex = -1;
      this.selectedTool(8);
      this.fs.customObjectImage = selectedImage;
    } else {
      this.selectedTool(8);
    }
  }

  selectedTool(data) {
    if (data == this.fs.toolIndex) {
      this.fs.selectedType.value == ShapeTypeEnum.Text ? this.setSelectedType(2) : this.fs.selectedType.next('');
      this.fs.toolIndex = -1;
    } else {
      this.fs.toolIndex = data;
      this.setSelectedType(data)
    }
  }

  setSelectedType(data) {
    switch (data) {
      case 0:
        this.fs.selectedType.next(ShapeTypeEnum.Rectangle);
        break;
      case 1:
        this.fs.selectedType.next(ShapeTypeEnum.Circle);
        break;
      case 2:
        this.fs.selectedType.next(ShapeTypeEnum.Text);
        this.fs.drawLabel$.next(true);
        break;
      case 3:
        this.fs.selectedType.next(ShapeTypeEnum.Area);
        break;
      case 4:
        this.fs.selectedType.next(ShapeTypeEnum.Wall);
        break;
      case 5:
        this.fs.selectedType.next(ShapeTypeEnum.BasicRectangle);
        break;
      case 6:
        this.fs.selectedType.next(ShapeTypeEnum.BasicCircle);
        break;
      case 7:
        this.fs.selectedType.next(ShapeTypeEnum.Pin);
        break;
      case 8:
        this.fs.selectedType.next(ShapeTypeEnum.Image);
        break;
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    this.fs.selectedSeatingTypeId.next(null);
  }

  async selectFiles(selectedFiles) {
    this.uploadImages = [];
    const selectedFilesCount = selectedFiles.target.files.length;
    const fileCount = selectedFilesCount + this.imageLibrary.length;
    const allAvailableFiles = selectedFiles.target.files;
    if (fileCount > this.maxFileCountToUpload) {
      this.openErrorPopup(`${this.ts.instant('imageUploadError')}`, this.ts.instant('imageUploadError'));
    } else {
      const duplicateFilecount = 0;
      let maxFileSizeExceededCount = 0;
      const duplicateFileNames = '';
      let maxSizeExceededFileNames = '';
      for (let i = 0; i < selectedFilesCount; i++) {
        if (allAvailableFiles[i].size > this.maxImageSize) {
          maxFileSizeExceededCount++;
          maxSizeExceededFileNames = maxSizeExceededFileNames.length > 0 ?
            `${maxSizeExceededFileNames}, ${allAvailableFiles[i].name.split('.')[0]}` : allAvailableFiles[i].name.split('.')[0];
        }
      }
      if (duplicateFilecount > 0) {
        const confirmationMessage = `${this.ts.instant('duplicateFileError')} "${duplicateFileNames}" ${this.ts.instant('alreadyExists')}`;
        const dialogTitle = this.ts.instant('duplicateFileError');
        this.openErrorPopup(confirmationMessage, dialogTitle);
        return;
      } else if (maxFileSizeExceededCount > 0) {
        this.openErrorPopup(`${this.ts.instant('fileSizeError')} "${maxSizeExceededFileNames}" ${this.ts.instant('file2MB')}`,
          this.ts.instant('fileSizeError'));
        return;
      } else {
        for (let i = 0; i < selectedFilesCount; i++) {
          if (allAvailableFiles[i].size <= this.maxImageSize) {
            await this.getBase64(allAvailableFiles[i]).then((file: string) => {
              const photo = file.replace(/^data:image.+;base64,/, '');
              this.uploadImages.push(photo);
            });
          }
        }
        this.fs.customImagesActionTriggered = true;
      }
    }
    this.fs.uploadObjectImages(this.uploadImages);
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  deleteImages() {
    const discardChanges: string = this.ts.instant('discardChangesMsg');
    const title: string = this.ts.instant('alert');
    const mappedMsg: string = this.ts.instant('objectImageDeleteConfirmMsg');
    var multipleMsgs = [];
    multipleMsgs.push(discardChanges);
    multipleMsgs.push(mappedMsg);
    var mapped = this.fs.checkObjectMappings();
    if (!this.fs.equal && mapped) {
      this.openConfirmationPopup(multipleMsgs, title, ConfirmationMessagesPopupComponent);
    }
    else if (!this.fs.equal) {
      this.openConfirmationPopup(discardChanges, title, ConfirmationPopupComponent);
    }
    else if (mapped) {
      this.openConfirmationPopup(mappedMsg, title, ConfirmationPopupComponent);
    }
    else {
      this.Delete();
    }
  }

  openConfirmationPopup(confirmationMessage, title, component: any) {
    const noText: string = this.ts.instant('notext');
    const yesText: string = this.ts.instant('yestext');
    const popUpMessage = [{
      confirmationMessage: confirmationMessage,
      dialogTitle: title,
      showAlert: true
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(component, 'small',
      'active', popUpMessage, '');
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: 'auto',
      data: {
        title: popUpMessage[0].dialogTitle,
        update: yesText,
        cancel: noText,
        componentDetails,
        from: ComponentTypes.floorPlanImage,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    const confirmSubscription = this.popupService.confirmedAction$.subscribe((val) => {
      if (!this.fs.equal) {
        this.fs.discardChanges$.next();
      }
      this.Delete();
    });

    dialogRef.afterClosed().subscribe(() => {
      if (confirmSubscription) {
        confirmSubscription.unsubscribe();
      }
    });
  }

  Delete() {
    if (this.fs.selectedObjectImageIds.length > 0) {
      this.fs.deleteObjectImages(this.fs.selectedObjectImageIds).subscribe((data) => {
        if (data.State === 0) {
          this.fs.selectedObjectImageIds = [];
          if (this.isEditGallery) {
            this.imageLibrary.forEach((img) => {
              img.isImageSelected = false;
            })
          }
        }
      });
    }
  }

  applyImage(image: InputImages) {
    if (this.objectSelected && !this.isEditGallery) {
      this.imageLibrary.forEach((img: InputImages) => img.isImageSelected = false);
      image.isImageSelected = true;
      if (image.ImageId == null) {
        this.isDefaultImage = true;
      } else {
        this.isDefaultImage = false;
      }
      this.fs.applySelectedImage$.next({ image, isLayoutSelected: false });
    } else {
      if (image.ImageId == null) {
        this.isDefaultImage = !this.isDefaultImage;
      } else {
        this.isDefaultImage = false;
        image.isImageSelected = !image.isImageSelected;
      }
      if (image.isImageSelected) {
        this.fs.selectedObjectImageIds.push(image.ImageId);
      } else {
        this.fs.selectedObjectImageIds = this.fs.selectedObjectImageIds.filter(imgID => imgID !== image.ImageId);
      }
    }
  }

  openErrorPopup(confirmationMessage, dialogTitle) {
    const popUpMessage = [{
      confirmationMessage,
      dialogTitle,
      showAlert: true
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small',
      'action', popUpMessage, '');
    this.openCustomPopup(componentDetails, ComponentTypes.floorPlanImage, '450px', 'auto', true, '', 'Ok',
      'Cancel', true);
  }

  editGallery() {
    this.isEditGallery = !this.isEditGallery;
    this.imageLibrary.forEach((image) => {
      image.isImageSelected = false;
    });
  }

  cancel() {
    this.isEditGallery = !this.isEditGallery;
    this.imageLibrary.forEach((image) => {
      if (image.ImageId == this.selectedObjectInfo.ImageId) {
        image.isImageSelected = true;
      } else {
        image.isImageSelected = false;
      }
    });
    this.fs.selectedObjectImageIds = [];
  }
}
