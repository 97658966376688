<section class="retailChild retailChildWrapper h-100 newVersionWrapper">

        <form [formGroup]="retailSetupFormGrp" >
        <div class="retail-header">
            <mat-form-field class="select-width" *ngIf="functionalities?.ShowRetailSetupSelectOutletField" [floatLabel]="floatLabel">
                <mat-select [attr.automationId]="'Dd_retailChildSetup_outletName'" placeholder="{{captions.SelectOutlet}}" *ngIf="functionalities?.ShowRetailSetupSelectOutletField" (selectionChange)="SearchByOutlet($event)" id="selectOutlet"  [(value)] = "selectedOutlet.id" >
                    <mat-option *ngFor="let Outlet of outlets" [value]="Outlet.id">{{Outlet.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class='d-flex flex-wrap align-items-center'>
                <mat-form-field class='mr-4 w-25 searchField spa-searchbox custSearchbox' [floatLabel]="floatLabelNever">
                    <input [attr.automationId]="'Txt_retailChildSetup_searchDescription'" matInput type="text" [placeholder]="captions.SearchByItemDescriptionCategory" class="searchbox-width"
                        autocomplete="off" [(ngModel)]='searchTxt' [ngModelOptions]="{standalone: true}">
                    <i  aria-hidden="true" [ngClass]="!searchTxt ? 'icon-Search' : 'icon-Cancel'" (click)="clearSearch()"></i>
                </mat-form-field>
                <button [attr.automationId]="'Btn_retailChildSetup_searchByDetails'" mat-raised-button id="Search" class="btn btn-default buttonLineHeight body-bgcolor whitecolor LW14 text-capital mr-4 sec-btn"
                    (click)='SearchByDetails()'  *ngIf="!enableAdvSearch" >{{caption.common.Search}}</button>
                <!-- <span class="golf__link cursor mr-4 LW14 uppercase boxed-icon" id="AdvancedSearch" (click)="toggleAdvancedSearch()" *ngIf="!enableAdvSearch"> {{captions.AdvancedSearch}}</span> -->

                <a *ngIf="!enableAdvSearch" class="ag_link mr-4 ag_pl--2 boxed-icon" href="javascript:void(0)" id="AdvancedSearch"
              (click)="toggleAdvancedSearch()"  [matTooltip]='captions.AdvancedSearch'>
              <i class="icon-Advanced-Search"></i></a>

                    <div class="d-inline-flex align-items-center">
                    <span class="LW14 pr-2">{{caption.common.ShowInactiveItem}}</span>


                    <app-retail-toggle-switch [attr.automationId]="'Txt_retailChildSetup_inactiveRecord'" class="d-block" formControlName="shownIactiveRecords"
          (changeToggleEvent)=shownIactiveRecords($event)></app-retail-toggle-switch>

                </div>
                <div class="btn-group ml-auto"  dropdown>
                    <button [attr.automationId]="'Btn_retailChildSetup_syncRetailItems'" mat-raised-button class="btn btn-default buttonLineHeight body-bgcolor whitecolor LW14 text-capital sec-btn" *ngIf="isSyncItem"
                        [disabled]="IsViewOnly || disableSyncUpBtn" (click)="SyncRetailItems()">{{captions.SyncItem}}</button>

                        <button [attr.automationId]="'Btn_retailChildSetup_transferInventory'" mat-raised-button class="btn btn-default buttonLineHeight body-bordercolor LW14 text-capital sec-btn mr-3" *ngIf="!isSyncItem && functionalities?.ShowAddOrTransferInventoryButton && !isEatecAsMaster"
                        [disabled]="IsViewOnly || !enabletransferButton" (click)="transferInventory()">{{captions.addTransferInventory}}</button>

                        <span  [attr.automationId]="'Lbl_retailChildSetup_sampleDownload'" class="golf__link cursor mr-4 LW14 uppercase sec-btn" href="javascript:void(0)" *ngIf="!isSyncItem && !isEatecAsMaster" (click)="downloadSampleCSV()">{{captions.downloadSampleCSV}}</span>
                        <input style="display: none" type="file" accept=".csv" (change)="importItems($event,fileInput)" #fileInput>
                        <button [attr.automationId]="'Btn_retailChildSetup_impItems'" mat-raised-button class="btn btn-default buttonLineHeight LW14 text-capital sec-btn mr-3"
                        [ngClass]="IsViewOnly || disableSyncUpBtn || enabletransferButton || importing? 'button--disabled': 'body-bgcolor whitecolor body-bordercolor '" *ngIf="!isSyncItem && !isEatecAsMaster"
                        [disabled]="IsViewOnly || disableSyncUpBtn || enabletransferButton || importing" (click)="fileInput.click()">{{captions.ImportItem}}</button>

                        <button [attr.automationId]="'Btn_retailChildSetup_importantStatus'" mat-raised-button class="btn btn-default buttonLineHeight LW14 text-capital sec-btn mr-3"
                        [ngClass]="IsViewOnly || disableSyncUpBtn || enabletransferButton? 'button--disabled': 'body-bgcolor whitecolor body-bordercolor '" *ngIf="!isSyncItem && !isEatecAsMaster"
                        [disabled]="IsViewOnly || disableSyncUpBtn || enabletransferButton" (throttleClick)="importItemStatus()" [appDisableDoubleClick]="2000">{{captions.ImportStatus}}</button>
                        
                        <button [attr.automationId]="'Btn_retailChildSetup_expItems'" mat-raised-button class="btn btn-default buttonLineHeight LW14 text-capital sec-btn mr-3"
                        [ngClass]="IsViewOnly || disableSyncUpBtn || enabletransferButton? 'button--disabled': 'body-bgcolor whitecolor body-bordercolor '" *ngIf="!isSyncItem && !isEatecAsMaster"
                        [disabled]="IsViewOnly || disableSyncUpBtn || enabletransferButton" (throttleClick)="exportItem()" [appDisableDoubleClick]="2000">{{captions.ExportItem}}</button>
                       
                        <button [attr.automationId]="'Btn_retailChildSetup_createRecords'" mat-raised-button class="btn btn-default buttonLineHeight LW14 text-capital sec-btn"
                        [ngClass]="IsViewOnly || disableSyncUpBtn || enabletransferButton? 'button--disabled': 'body-bgcolor whitecolor body-bordercolor '" *ngIf="!isSyncItem"
                        [disabled]="IsViewOnly || disableSyncUpBtn || enabletransferButton" (click)="createRecords()">{{captions.CreateItem}}</button>
                </div>



            </div>
        </div>
        <!-- Not In Use now. May be used in future-->
        <div class='AdvancedSearchDiv retail-select-container' *ngIf="enableAdvSearch">
            <label> {{captions.AdvancedSearch}}</label>
            <i   aria-hidden="true" class="icon-close inner-close float-md-right cursPointer mr-3" (click)="closeAdvanceSearch()"></i>
            <br />
            <div class='d-flex flex-row flex-wrap align-items-center'>
                <mat-form-field class="LW14 width_90 adv-category category" [floatLabel]="floatLabel">
                    <mat-select [attr.automationId]="'Dd_retailChildSetup_categoryName'" placeholder="{{captions.Category}}" [(ngModel)]="selectedCategory" [ngModelOptions]="{standalone: true}" (selectionChange)="OnCategoryChange($event)">
                        <mat-option *ngFor="let category of categories" [value]="category.id">{{category.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="LW14 width_90 adv-category" [floatLabel]="floatLabel">
                    <mat-select [attr.automationId]="'Dd_retailChildSetup_subCategoryOne'" placeholder="{{captions.Subcategory1}}" [(ngModel)]="selectedSubCategory1" [ngModelOptions]="{standalone: true}" (selectionChange)="OnSubCategory1Change($event)">
                        <mat-option *ngFor="let category of subCategoryOne" [value]="category.id">{{category.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="LW14  width_90 adv-category" [floatLabel]="floatLabel">
                    <mat-select [attr.automationId]="'Dd_retailChildSetup_categoryName2'" placeholder="{{captions.Subcategory2}}" [(ngModel)]="selectedSubCategory2" [ngModelOptions]="{standalone: true}" (selectionChange)="OnSubCategory2Change($event)">
                        <mat-option *ngFor="let category of subCategoryTwo" [value]="category.id">{{category.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="LW14 width_90 adv-category" [floatLabel]="floatLabel">
                    <mat-select [attr.automationId]="'Dd_retailChildSetup_categoryName3'" placeholder="{{captions.Subcategory3}}" [(ngModel)]="selectedSubCategory3" [ngModelOptions]="{standalone: true}" (selectionChange)="OnSubCategory3Change($event)">
                        <mat-option *ngFor="let category of subCategoryThree" [value]="category.id">{{category.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="LW14 width_90 adv-category" [floatLabel]="floatLabel">
                    <mat-select [attr.automationId]="'Dd_retailChildSetup_categoryName4'" placeholder="{{captions.Subcategory4}}" [(ngModel)]="selectedSubCategory4" [ngModelOptions]="{standalone: true}" (selectionChange)="OnSubCategory4Change($event)">
                        <mat-option *ngFor="let category of subCategoryFour" [value]="category.id">{{category.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="LW14 width_90 mr-3 adv-category" [floatLabel]="floatLabel">
                    <mat-select [attr.automationId]="'Dd_retailChildSetup_categoryName5'" placeholder="{{captions.Subcategory5}}" [(ngModel)]="selectedSubCategory5" [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let category of subCategoryFive" [value]="category.id">{{category.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="mr-4 w-25 searchField spa-searchbox custSearchbox" [floatLabel]="floatLabelNever">
                    <input [attr.automationId]="'Txt_retailChildSetup_vendorName'" type="text" [placeholder]="captions.searchByVendorIdName" class="searchbox-width" aria-label="Number"
                    matInput  [formControl]="searchControl" [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onOptionChange($event)">
                      <mat-option class="LW14" *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option.vendorCode}} - {{option.vendorName}}
                      </mat-option>
                    </mat-autocomplete>
                    <i aria-hidden="true"  class="float-right blckClr" [ngClass]="searchControl.value ? 'icon-Cancel cursor': ''"
                      (click)="clearSearchText($event)"></i>
                  </mat-form-field>

                <button [attr.automationId]="'Btn_retailChildSetup_searchByCategories'" mat-raised-button class="LW14 text_capitalize body-bordercolor mt-3 mb-3 sec-btn" (click)='SearchByCategories()'>{{caption.common.Search}}</button>
            </div>
        </div>
    </form>
        <app-retail-setup-table  [automationId]="'retailChildSetup'" #retailSetupTableComponent class="CustomTableGrid" [options]="tableoptions" (editEvt)="EditRecords($event)" (doneEvtEmitter)="Done($event)" (cancelEmitter)="Cancel($event)"  (RowSelectEmitter)="RowSelected($event)" [isEditModeSetFromParent]="isEditMode"
        (sortEmitter)="sortHandler($event)" [scrollPositionReset]="isScrollPositionReset" (RowSelectEmitter)="RowSelected($event)"
        (scrollEmitter)="virtualScrollHandler($event)" [isOnEditDisableFunctionAvailable]="onEditDisableFunction"></app-retail-setup-table>
        <div class="res-progress-bar" *ngIf="isLoading">
            <mat-progress-bar  mode="indeterminate"></mat-progress-bar>
          </div>
</section>
