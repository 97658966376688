import { Component, OnInit, ViewEncapsulation, OnDestroy, AfterViewChecked } from '@angular/core';
import * as _ from 'lodash'; // STORAGE THE BACK ARRAY
import { BaseResponse } from '../../../common/shared/shared.modal';
import { ManagementData } from '../../../shared/shared-models';
import { GridData, PendingAction, AppointmentData, GridAction, NotifyDayEnd, ErrorCodes } from '../../AuditModals';
import { AuditService } from '../../audit.service';
import { Router } from '@angular/router';
import { SubscriptionLike as ISubscription, ReplaySubject } from 'rxjs';
import { SubPropertyModel } from '../../../retail/retail.modals';
import { takeUntil } from 'rxjs/operators';
import { CommonVariablesService, TransactionStatus } from '../../../retail/shared/service/common-variables.service';
import { RetailSharedVariableService } from '../../../retail/shared/retail.shared.variable.service';
import { MiscellaneousSwitch } from '../../../retail/shared/globalsContant';
import { RetailValidationService } from '../../../retail/shared/retail.validation.service';
import {
  ButtonOptions, Product,
  RetailBreakPoint, SPAScheduleBreakPoint,
  ActionType, Host, ButtonType
} from 'src/app/common/shared/shared/globalsContant';
import { HttpMethod, KeyValuePair, HttpServiceCall, } from 'src/app/common/shared/shared/service/http-call.service';
import { BreakPointAccess } from 'src/app/common/shared/shared/service/breakpoint.service';
import { AppModuleService } from 'src/app/core/services/app.service';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { RedirectToModules } from 'src/app/common/shared/shared/utilities/common-utilities';
import { ButtonType as RetailButtonType, OpenTransactionAction } from 'src/app/retail/shared/globalsContant';
import { AlertType } from 'src/app/retail/shared/shared.modal';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { ButtonTypes } from 'src/app/common/Models/common.models';
import { RetailTaxesDataService } from 'src/app/retail/retail-code-setup/retail-taxes/retail-taxes-data.service';
import { ShopBussinessService } from 'src/app/retail/shop/shop-business.service';
import { PaymentHistoryDetails } from 'src/app/retail/shared/service/payment/payment-business.model';
import { MatDialog } from '@angular/material/dialog';
import { VoidReasonComponent } from 'src/app/retail/shop/view-categories/void-reason/void-reason.component';
import { FinancialBinHelper } from 'src/app/retail/shared/business/FinancialBin-business';
import { RevenuePostingDataService } from 'src/app/retail/sytem-config/data-service/revenue-posting.data.service';
import { RoomRevenuePostingRequest } from 'src/app/retail/shop/view-categories/retail-revenue-posting-logs/revenue-posting';
import { Localization } from 'src/app/common/localization/localization';
import { NightAuditBusiness } from 'src/app/common/night-audit/night-audit.business';

@Component({
    selector: 'app-day-end',
    templateUrl: './day-end.component.html',
    styleUrls: ['./day-end.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [AppModuleService, ShopBussinessService, FinancialBinHelper, NightAuditBusiness]
})
export class DayEndComponent implements OnInit, OnDestroy, AfterViewChecked {

  captions: any;
  getheight: NodeJS.Timer;
  tableData: any;
  tableData1: any[];
  header: any;
  DayendCollection: any = [];
  currSysDate: Date = this.propertyInfo.CurrentDate;
  newSysDate: Date = this.propertyInfo.CurrentDate;
  canProcess = false;
  isProcessClicked = true;
  success: any;
  GridData: GridData[] = [];
  AppointmentStatus: any;
  captionsBookApp: any;
  $destroyed: ReplaySubject<any> = new ReplaySubject(1);
  managementData: ManagementData;
  allShopItems: any[] = [];
  checkoutAppointments: any[] = []; // used to display appointment ids in open transaction
  appointmentActionHeader = '';
  currentDateForAPI = '';
  hasAccess = false;
  subscriptions: ISubscription[] = [];
  propOutlets: SubPropertyModel[] = [];
  allowFutureDate: boolean = false;
  allowDayEndOnRevenueFailure: boolean = false;
  revenuePostingUrl = ['/shop/viewshop/retailtransactions/revenuepostingslog'];
  revenuepostingsFailedCount: number = 0;
  revenuepostingsFailedText: string;
  showRevenuePostings: boolean =false;
  iconActions:any;
  isManualNightAuditRestricted = false;
  nightAuditRestricted: string;

  constructor(public localization: RetailLocalization, private utils: RetailUtilities, private http: HttpServiceCall,
    private auditService: AuditService, 
    public router: Router,
    private commonLocalization: Localization,
    // tslint:disable-next-line: max-line-length
    // private propertyInfo: PropertyInformation,
    private breakPoint: BreakPointAccess, 
    public ams: AppModuleService,
    private retailSharedService: RetailSharedVariableService, 
    private retailValidationService: RetailValidationService,
    private propertyInfo: RetailPropertyInformation, 
    private retailTaxService: RetailTaxesDataService, 
    private shopBusinessService: ShopBussinessService,
    public _shopservice: CommonVariablesService, 
    public dialog: MatDialog, 
    public revenuePostingDataService: RevenuePostingDataService,
    private nightAuditBusiness: NightAuditBusiness) {
      this.showRevenuePostings = !this.propertyInfo.UseRetailInterface && this.propertyInfo.HasRevenuePostingEnabled ;
  }

  ngOnInit() {
    this.ams.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
          loadingContainerMessage.innerText = loader;
        } else {
          loadingContainer.style.display = 'none';
          loadingContainerMessage.innerText = '';
        }
      }
    });

    this.captions = this.localization.captions.dayEnd;
    this.AppointmentStatus = this.localization.captions.appointmentSearch;
    this.captionsBookApp = this.localization.captions.bookAppointment;
    // this.hasAccess = this.breakPoint.CheckForAccess([SPAManagementBreakPoint.DayEnd]);
    this.hasAccess = true;
    this.GetNightAuditConfig();
    if (this.hasAccess) {
      this.currentDateForAPI = this.localization.convertDateObjToAPIdate(this.currSysDate);
      // this.newSysDate = this.newSysDate.setDate(this.newSysDate.getDate() + 1);
      this.newSysDate.setDate(this.currSysDate.getDate() + 1);
      this.InitializeGrid();
      const pmsSystem = sessionStorage.getItem('pmsSystem');
      if (this.showRevenuePostings && pmsSystem != null && pmsSystem.toLowerCase() === 'visualone'){
        this.getRevenuePostings();
      }else {
        this.showRevenuePostings = false;
      }

      this.GetGridData();
      // tslint:disable-next-line: max-line-length
      this.InvokeServiceCall('GetOutletsByProperty', Host.retailManagement, HttpMethod.Get, { PropertyId: Number(this.localization.GetPropertyInfo('PropertyId')) });
      this.InvokeServiceCall('GetMiscConfigurationByPropertyId', Host.retailManagement, HttpMethod.Get, { PropertyId: Number(this.localization.GetPropertyInfo('PropertyId')) });
    }
    this.ResetServiceObject();
  }

  async getRevenuePostings(){
    const request: RoomRevenuePostingRequest = {
      startDate: this.localization.convertDateObjToAPIdate(this.currSysDate),
      endDate: this.localization.convertDateObjToAPIdate(this.currSysDate),
      isFromDayEnd: false
    };
    const result = await this.revenuePostingDataService.getRoomRevenuePostingLogs(request);
    this.revenuepostingsFailedCount = result.failedCount;
    this.revenuepostingsFailedText = ' (' + this.revenuepostingsFailedCount + ')';
  }

  removeBtnCheck = () => this.allowDayEndOnRevenueFailure ? ((this.revenuepostingsFailedCount > 0) || this.isProcessClicked || this.isManualNightAuditRestricted) : (this.isProcessClicked || this.isManualNightAuditRestricted) ;

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => {
        if (sub) {
          sub.unsubscribe();
        }
      });
    }
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      this.calculateTableWidth();
    }, 1);
  }

  calculateTableWidth() {
    const tableElements = document.getElementsByClassName('DE_Table');
    for (let i = 0; i < tableElements.length; i++) {
      const tableHeight = tableElements[i]['offsetHeight'];
      const tableArea = document.getElementsByClassName('tableSec');
      if (tableHeight > 500) {
        tableArea[i]['style']['height'] = 500 + 'px';
      } else {
        tableArea[i]['style']['height'] = tableHeight + 'px';
      }
    }
  }

  private InitializeGrid() {
    const gridItems: any[] = [
      {
        status: PendingAction.OpenTransaction,
        displayName: this.captions.OpenTransactions,
        linkOptions: false
      },
      {
        status: PendingAction.RevenuePosting,
        displayName: this.captions.revenuePostings,
        linkOptions: true
      }
    ];

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < gridItems.length; i++) {
      this.GridData.push(
        {
          status: gridItems[i].status,
          displayName: gridItems[i].displayName,
          tableOptions: {
            TableHdrData: [],
            TablebodyData: []
          },
          options: [],
          dataCount: 0,
          isLoaded: false,
          linkOptions: gridItems[i].linkOptions
        }
      );
    }
  }

  GetGridData() {
    this.GetOpenTransactions();
    
  }
  routeTolink(){
    this.retailSharedService.isFromAudit = true;
  }

  private GetOpenTransactions() {
    this.InvokeServiceCall('GetAllTransactions', Host.retailPOS, HttpMethod.Get,
    {
      status: TransactionStatus.OPEN,
      outletId: 0,
      transactionDate: this.localization.ConvertDateToISODateTime(this.propertyInfo.CurrentDate)
    });
  }

  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): Promise<void> {
    switch (callDesc) {
      case 'GetAllTransactions': {
        const response = result.result as any;
        await this.BuildOpenTransactions(response);
        break;
      }
      case 'GetTransactionDetails': {
        const response = result.result as any;
        this.BuildTransactionDetails(response, extraParams ? extraParams[0] : '');
        break;
      }
      case 'GetShopItems': {
        this.allShopItems = result.result as any;
        break;
      }
      case 'PerformDayEnd': {
        const response = result.result as any;
        if (response.isSuccess) {
          this.propertyInfo.SetPropertyDate(this.newSysDate);
          this.UpdateInventoryAudit();          
          if (this.propertyInfo.HasRevenuePostingEnabled) {
            this.SendNewSystemDate();
          }
        } else {
          if (result.errorCode.toString() == ErrorCodes.UNABLE_TO_ROLL_TO_FUTURE_DATE) {
            this.utils.ShowErrorPopup([result.errorCode]);

          }
          else if (result.errorCode.toString() == ErrorCodes.NEWDATE_LESS_THAN_PROPERTYDATE) {
            var errorMessage = this.localization.getError(result.errorCode);
            errorMessage = this.localization.replacePlaceholders(errorMessage,
              ['CurrentSystemDate', 'RollOverDate'],
              [this.localization.LocalizeDate(response.currentSystemDate), this.localization.LocalizeDate(response.rollOverDate)]
            );
            this.utils.ShowError(this.localization.captions.common.Error, errorMessage, ButtonType.Ok);

          }
          else {
            this.isProcessClicked = false;
            this.utils.ShowError(this.localization.captions.common.Error, this.captions.ErrorInDayEnd, ButtonType.Ok);
          }

        }
        break;
      }
      case "NotifyDayEnd":
        {
          var response = <any>result.result;
          if (!response) {
            this.utils.ShowError(this.localization.captions.common.Error, this.captions.NotifyDayEnd, ButtonType.Ok);
          }
          break;
        }
      case 'GetOutletsByProperty': {
        const response: any = result.result as any ? result.result : [];
        if (response) {
          this.propOutlets = response.filter(x => x.isActive);
          console.log(this.propOutlets);
        }
        break;
      }
      case 'GetMiscConfigurationByPropertyId': {
        const response: any = result.result as any ? result.result : [];
        if (response) {
          this.allowFutureDate = response.find(x => x.switch == "ALLOW_DATE_FOR_FUTURE").value == "true" ? true : false;
          this.allowDayEndOnRevenueFailure = response.find(x => x.switch === 
            MiscellaneousSwitch.RESTRICT_DAYENDPROCESSWITHFAILEDREVENUEPOSTINGS).value === 'true' ? true : false;

        }
      }
    }
  }

  async UpdateInventoryAudit() {
    // tslint:disable-next-line: max-line-length
    this.InvokeServiceCall('UpdateInventoryAuditOnDayEnd', Host.retailManagement, HttpMethod.Post, { propertyId: Number(this.utils.GetPropertyInfo('PropertyId')) }, this.newSysDate);
  }

  async SyncUpItemAndTaxes(prevDate) {
    if (!this.propertyInfo.UseRetailInterface && this.propOutlets && this.propOutlets.length > 0) {
      try {
        let taxes = await this.retailTaxService.getAllTaxConfiguration();
        this.utils.ToggleLoaderWithMessage(true, this.captions.OutletSyncWait);
        let failedOutlet = [];
        for (let i = 0; i < this.propOutlets.length; i++) {
          if (taxes && taxes.length && taxes.find(x =>
            (this.propOutlets[i].subPropertyID == x.outletId || this.propertyInfo.IsVATEnabled) &&
            this.localization.getDateDifference(this.localization.getDate(x.endDate), prevDate) == 0)) {
            try {
              let result = await this.http.CallApiAsync<boolean>({
                host: Host.retailManagement,
                callDesc: 'SyncItemAndTax',
                method: HttpMethod.Get,
                showError: true,
                uriParams: { outletId: this.propOutlets[i].subPropertyID, type: 'DayEnd', operation: 'Sync', id: 0 }
              });
              if (!result.result) {
                failedOutlet.push(this.propOutlets[i].subPropertyName);
              }
            }
            catch (err) {
              console.log(`Sync Failed For Outlet :: ${this.propOutlets[i].subPropertyID} `, err);
              failedOutlet.push(this.propOutlets[i].subPropertyName);
            }
          }
        }
        if (failedOutlet.length) {
          this.utils.showCommonAlert(this.localization.replacePlaceholders(this.captions.ErrorSyncOutlet, ["OutletName"], ['<ul class="text-left pt-1"><li>' + failedOutlet.join('</li><li>') + '</li></ul>']), AlertType.Info, ButtonTypes.Ok);
        }
      }
      catch (ex) {
        console.dir(ex);
      }
      finally {
        this.utils.ToggleLoaderWithMessage(false);
      }
    }
  }

  SendNewSystemDate() {
    const pmsSystem = sessionStorage.getItem('pmsSystem');
    if (this.showRevenuePostings && pmsSystem != null && pmsSystem.toLowerCase() === 'visualone') {
      let obj: NotifyDayEnd = { DateTime: this.localization.convertDateObjToAPIdate(this.newSysDate)
        , NightAuditDateTime : this.localization.convertDateObjToAPIdate(this.currSysDate ) 
         }
      this.InvokeServiceCall('NotifyDayEnd', Host.retailManagement, HttpMethod.Put, {},
        obj, null, null, false);
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    switch (callDesc) {
      case 'PerformDayEnd': {
        this.isProcessClicked = false;
        break;
      }
      case "NotifyDayEnd": {
        this.utils.ShowError(this.localization.captions.common.Error, this.captions.NotifyDayEnd, ButtonType.Ok);
        break;
      }
    }
  }

  async getClerkInfo(): Promise<any[]> {
    const result = await this.http.CallApiAsync({
      callDesc: 'GetAllUsers',
      method: HttpMethod.Get,
      host: Host.authentication,
      uriParams: { tenantId: Number(this.utils.GetPropertyInfo('TenantId')) }
    });
    const response: any = result && result.result ? result.result : [];
    return response;
  }


  private async BuildOpenTransactions(response) {
    const gridData = this.GridData.find(r => r.status === PendingAction.OpenTransaction);
    if (response && response.length > 0) {
      this.isProcessClicked = true;
      response = response.filter(r => {
        return this.utils.GetDateWithoutTime(this.utils.getDate(r.transactionDate)).getTime() === this.propertyInfo.CurrentDate.getTime();
      });
    }
    if (!response || response.length === 0) {
      this.isProcessClicked = false;
      this.ClearGridDate(gridData);
      return;
    }
    var allClientIds = response.filter(r => r.guestId > 0).map(r => r.guestId);
    // All item info will be required when Reopen/Settle transaction from dayend
    let [clerkInfo, clients] = await Promise.all(
      [this.getClerkInfo(),
      this.getClients(allClientIds)]);

    const gridHeader = this.auditService.GetDayEndGridHeader(PendingAction.OpenTransaction);
    const gridActions = this.auditService.GetDayEndGridAction(PendingAction.OpenTransaction);
    this.iconActions = [{ 'label': this.captions.Settle, 'action': GridAction.Settle, 'redirectTo': '' }, { 'label': this.captions.Open, 'action': GridAction.ReOpen, 'redirectTo': '' }]
    const transactions: any[] = [];
    let transaction: any;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < response.length; i++) {
      const tran: any = response[i];
      const clerk = clerkInfo.filter(x => x.userId === tran.clerkId);
      const payeeName = this.getClientName(clients, tran.guestId);
      transaction = {
        Id: tran.id,
        TicketNumber: tran.ticketNumber,
        Date: this.localization.LocalizeShortDateTime(tran.transactionDate),
        ClerkID: (clerk && clerk.length > 0) ? clerk[0].userName : '',
        Outlet: tran.outletName,
        Amount: this.FormatCurrency(tran.totalAmount),
        ClientName: tran.memberId == "0" ? payeeName : "",
        ClientId: tran.guestId,
        MemberName: tran.memberId != "0" ? payeeName : "",
        AppointmentNumber: '',
        transactionInfo: tran,
        retailTransactionType: tran.retailTransactionType,
        paymentReceivedAmount: this.FormatCurrency(tran.paymentReceivedAmount)
      };
      transactions.push(transaction);
    }
    gridData.tableOptions = {
      TableHdrData: gridHeader,
      TablebodyData: transactions
    };
    gridData.options = gridActions;
    gridData.dataCount = transactions.length;
    gridData.isLoaded = true;
  }

  private FormatCurrency(amount): string {
    let formattedPrice = '';
    if (amount < 0) {
      formattedPrice = `(${this.localization.localizeCurrency(amount * -1, false)})`;
    } else {
      formattedPrice = this.localization.localizeCurrency(amount, false);
    }
    return formattedPrice;
  }

  public CanProcessDayEnd() {
    this.canProcess = false;
    const notLoadedData = this.GridData.filter(r => {
      return !r.isLoaded;
    });
    if (notLoadedData && notLoadedData.length > 0) {
      this.canProcess = false;
      return this.canProcess;
    }
    const pendingData = this.GridData.filter(r => {
      return r.dataCount > 0 && r.isLoaded;
    });
    this.canProcess = !(pendingData && pendingData.length > 0);
    return this.canProcess;
  }



  PerformDayend() {
    this.utils.ShowErrorMessage(this.captions.DAYEND, this.captions.DayEndProcess, RetailButtonType.YesNo, this.PopupCallback.bind(this));
  }


  async PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == "yes") {
      this.isProcessClicked = true;
      let uriParam = { currentDate: this.currentDateForAPI, isAllowFutureDate: this.allowFutureDate };
      this.InvokeServiceCall("PerformDayEnd", Host.retailPOS, HttpMethod.Put, uriParam);
    }
  }
  
  trackByFn(index, cell) {
    return index;
  }

  // tslint:disable-next-line: max-line-length
  InvokeServiceCall(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any, queryString?: KeyValuePair, extraParams?: any,
    showError: boolean = true) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body,
      showError: showError,
      extraParams,
      uriParams,
      queryString
    });
  }

  // private GetAppointmentStatus(status: string): string {
  //   let statusString = '';
  //   switch (status) {
  //     case 'RESV':
  //       statusString = this.AppointmentStatus.Scheduled;
  //       break;
  //     case 'CKIN':
  //       statusString = this.AppointmentStatus.CheckedIn;
  //       break;
  //     case 'CKOUT':
  //       statusString = this.AppointmentStatus.CheckedOut;
  //       break;
  //   }
  //   return statusString;
  // }

  getStatusColor(statuscode) {
    // return this.utils.getLegendColor(this._appService, statuscode);
  }

  OpenActionsDialog(actionId: ActionType) {

  }

  private IsAuthorized(action: GridAction): boolean {
    let isUserAuthorized = true;
    const breakpointNumber: number[] = [];
    switch (action) {
      case GridAction.CheckInCheckOut:
        breakpointNumber.push(SPAScheduleBreakPoint.CheckIn_CheckOutAppointment);
        breakpointNumber.push(SPAScheduleBreakPoint.CheckInAppointment);
        breakpointNumber.push(SPAScheduleBreakPoint.CheckOutAppointment);
        break;
      case GridAction.CheckOut:
        breakpointNumber.push(SPAScheduleBreakPoint.CheckOutAppointment);
        break;
      case GridAction.Move:
        breakpointNumber.push(SPAScheduleBreakPoint.MoveAppointment);
        break;
      case GridAction.UndoCheckIn:
        breakpointNumber.push(SPAScheduleBreakPoint.UndoCheckIn);
        break;
      case GridAction.ReOpen:
      case GridAction.Settle:
      case GridAction.CancelTransaction:
        breakpointNumber.push(RetailBreakPoint.ReOpenTransaction);
        break;
    }
    if (breakpointNumber.length > 0) {
      isUserAuthorized = this.breakPoint.CheckForAccess(breakpointNumber);
    }

    if (isUserAuthorized && (action === GridAction.ReOpen || action === GridAction.Settle || action === GridAction.CancelTransaction)) {
      isUserAuthorized = !this.breakPoint.IsViewOnly(breakpointNumber[0]);
      if (!isUserAuthorized) {
        this.breakPoint.showBreakPointPopup(this.localization.captions.breakpoint[RetailBreakPoint.ReOpenTransaction]);
      }
    }

    return isUserAuthorized;
  }

  async ActionClick(option: any, data: any) {
    if (!this.IsAuthorized(option.action)) {
      return;
    }
    if (option.action === GridAction.CheckInCheckOut) {
      const params = { isNoShow: false };
      const body: number[] = [data.AppointmentId];
      this.InvokeServiceCall('CheckinAppointment', Host.schedule, HttpMethod.Put, params, body, null, [data]);
    } else if (option.action === GridAction.Move || option.action === GridAction.CheckOut) {
      this.OpenActionsDialog(this.MapAppointmentActionData(option.action, data));
    } else if (option.action === GridAction.UndoCheckOut) {
      const idList: number[] = [data.AppointmentId];
      this.InvokeServiceCall('UndoCheckOutAppointment', Host.schedule, HttpMethod.Put, '', idList);
    } else if (option.action === GridAction.UndoCheckIn) {
      const uriParam = { id: data.AppointmentId };
      this.InvokeServiceCall('UndoCheckInAppointment', Host.schedule, HttpMethod.Put, uriParam);
    } else if (option.action === GridAction.ReOpen) {
      if (this.retailValidationService.CheckIfLinkedTransactionExists(data?.transactionInfo, OpenTransactionAction.Reopen)) { return; }
      var paymentHistoryDetails: PaymentHistoryDetails = await this.shopBusinessService.GetPaymentHistoryDetails(data.Id);
      if ((paymentHistoryDetails && (paymentHistoryDetails.paymentHistory.length > 0 || paymentHistoryDetails.isHavingPaymentHistory))) {
        const confirmationMsgForReopen = this.localization.replacePlaceholders(
          this.localization.captions.shop.ReOpenNotAllowed,
          ['TicketNumber'],
          [data.TicketNumber]
        );
        this.utils.ShowError(this.localization.captions.common.Error, confirmationMsgForReopen, ButtonType.Ok);
        this._shopservice.destroy();
        return;
      }
      this.retailSharedService.payeeId = data.ClientId;
      this.retailSharedService.memberCardNumber = data.transactionInfo.memberId;
      this.retailSharedService.settleOpenTransaction = false;
      this.retailSharedService.reOpenTransaction = true;
      this.retailSharedService.isReopenViewOnly = this.breakPoint.IsViewOnly(RetailBreakPoint.ReOpenTransaction);
      this.retailSharedService.transactionId = data.Id;
      this.retailSharedService.ticketNumber = data?.TicketNumber;      
      if (! await this.retailValidationService.ValidateSettleReopenAction(data.Id, 'reopen', this.TransactionLockCallback.bind(this))) {
        return;
      }
      this.retailValidationService.LockTransaction(data.Id);
      // tslint:disable-next-line: max-line-length
      this.InvokeServiceCall('GetTransactionDetails', Host.retailPOS, HttpMethod.Get, { transactionId: data.Id, productId: Product.SPA }, null, null, ['reopen']);
    } else if (option.action === GridAction.Settle) {                  
      this.retailSharedService.payeeId = data.ClientId;
      this.retailSharedService.memberCardNumber = data.transactionInfo.memberId;
      if (this.retailValidationService.CheckIfLinkedTransactionExists(data?.transactionInfo, OpenTransactionAction.Settle)) {
        this.retailSharedService.selectedRetailItem = data?.transactionInfo;
        this.retailSharedService.isFromDayEnd = true;
        this.retailSharedService.settleOpenTransaction = true;        
        this.router.navigate(["/shop/viewshop/shopItemDetails"]);
        return;
      }
      this.retailSharedService.reOpenTransaction = false;
      this.retailSharedService.settleOpenTransaction = true;
      this.retailSharedService.transactionId = data.Id;      
      this.retailSharedService.ticketNumber = data?.TicketNumber;      
      if (! await this.retailValidationService.ValidateSettleReopenAction(data.Id, 'settle', this.TransactionLockCallback.bind(this))) {
        return;
      }
      this.retailValidationService.LockTransaction(data.Id);
      // tslint:disable-next-line: max-line-length
      this.retailSharedService.selectedTransaction = data?.transactionInfo;
      this.InvokeServiceCall('GetTransactionDetails', Host.retailPOS, HttpMethod.Get, { transactionId: data.Id, productId: Product.SPA }, null, null, ['settle']);
    } else if (option.action === GridAction.CancelTransaction) {
      if (this.retailValidationService.CheckIfLinkedTransactionExists(data?.transactionInfo, OpenTransactionAction.Cancel)) { return; }
      if (await this.retailValidationService.IsTransactionLocked(data.Id)) {
        this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.TransactionLock, ButtonType.Ok);
        return;
      }
      this.retailSharedService.ticketNumber = data.TicketNumber;
      this.retailSharedService.transactionId = data.Id;
      // tslint:disable-next-line: max-line-length
      var paymentHistoryDetails: PaymentHistoryDetails = await this.shopBusinessService.GetPaymentHistoryDetails(data.Id);
      if ((paymentHistoryDetails && (paymentHistoryDetails.paymentHistory.length > 0 || paymentHistoryDetails.isHavingPaymentHistory))) {
        const confirmationMsgForCancel = this.localization.replacePlaceholders(
          this.localization.captions.shop.CancelNotAllowed,
          ['TicketNumber'],
          [this.retailSharedService.ticketNumber]
        );
        this.utils.ShowError(this.localization.captions.common.Error, confirmationMsgForCancel, ButtonType.Ok);
        this._shopservice.destroy();
        return;
      }
      const confirmationMsg = this.localization.replacePlaceholders(
        this.captions.CancelOpenTransaction,
        ['TicketNumber'],
        [this.retailSharedService.ticketNumber]
      );
      this.utils.ShowError(this.localization.captions.common.Warning, this.captions.CancelOpenTransaction, ButtonType.YesNo, this.CancelTransaction.bind(this));
    }
    else if (option.action === GridAction.Close) {
      this.shopBusinessService.CheckIfcloseTransactionllowed(data.Id, data.TicketNumber)
        .then(async x => {
          await this.CommentsPopup(data.Id, data.TicketNumber)
        }).catch()
    }
  }

  async CommentsPopup(transactionId: number, ticketNumber: string) {
    const dialogRef = this.dialog.open(VoidReasonComponent, {
      height: 'auto',
      width: '40%',
      data: { headername: this.localization.captions.shop.ReasonForClose, closebool: true },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(resultData => {
      if (resultData.action.toLowerCase() === 'ok') {
        this.shopBusinessService.closeTransaction(resultData.reason, transactionId, ticketNumber).then(
          x => this.GetGridData()
        )
      }
    });
  }

  async TransactionLockCallback(result: string, extraparams) {
    if (result.toLowerCase() === ButtonOptions.Yes.toLowerCase()) {
      this.retailValidationService.LockTransaction(extraparams[0], true);
      this.InvokeServiceCall('GetTransactionDetails',
        Host.retailPOS, HttpMethod.Get, { transactionId: extraparams[0], productId: Product.SPA }, null, null, [extraparams[1]]);
    } else {
      this.retailSharedService.settleOpenTransaction = false;
      this.retailSharedService.reOpenTransaction = false;
    }
  }


  async CancelTransaction(result) {
    if (result.toLowerCase() === 'yes') {
      this.ams.loaderEnable.next(this.localization.captions.shop.RefundInProgress);
      const response: any = await this.retailValidationService.CancelTransaction(this.retailSharedService.transactionId);
      this.ams.loaderEnable.next('');
      if (response && response.successStatus) {
        this.GetOpenTransactions();
        const undocheckoutrespone: any = await this.InvokeServiceCallAsync('UndoCheckOutAppointmentByTransactionId',
          Host.schedule, HttpMethod.Put, { transactionId: this.retailSharedService.transactionId });
        if (undocheckoutrespone && undocheckoutrespone.successStatus) {
          // this.GetCheckInAppointment();
        }
      } else {
        this.utils.ShowError(this.localization.captions.common.Information, this.localization.getError(response.result));
      }
    }
  }

  private MapAppointmentActionData(dayEndAction: GridAction, data: AppointmentData): ActionType {
    this.FillServiceData(data);
    let appointmentAction: ActionType;
    switch (dayEndAction) {
      case GridAction.CheckOut:
        this.appointmentActionHeader = this.localization.captions.bookAppointment.CheckOutAppointment;
        appointmentAction = ActionType.checkout;
        break;
      case GridAction.Move:
        this.appointmentActionHeader = this.localization.captions.bookAppointment.MoveAppointment;
        appointmentAction = ActionType.move;
        break;
    }
    return appointmentAction;
  }

  // private GetManagementNamebyId(id: number, type: ManagementDataType): string {
  //   let name = '';
  //   switch (type) {
  //     case ManagementDataType.Location: {
  //       const location = this.managementData.location.find(r => r.id === id);
  //       if (location) {
  //         name = location.description;
  //       } else {
  //         name = this.localization.captions.setting.Offsite;
  //       }
  //       break;
  //     }
  //     case ManagementDataType.Service: {
  //       const service = this.managementData.service.find(r => r.id === id);
  //       if (service) {
  //         name = service.description;
  //       }
  //       break;
  //     }
  //     case ManagementDataType.Package: {
  //       const packageData = this.managementData.package.find(r => r.id === id);
  //       if (packageData) {
  //         name = packageData.description;
  //       }
  //       break;
  //     }
  //     case ManagementDataType.Client: {
  //       const client = this.managementData.client.find(r => r.id === id);
  //       if (client) {
  //         name = `${client.firstName} ${client.lastName}`;
  //       }
  //     }
  //       break;
  //   }
  //   return name;
  // }

  // private GetTherapistName(id: number[]): string {
  //   const name: string[] = [];
  //   const therapist = this.managementData.therapist.filter(r => id.includes(r.id));
  //   if (therapist && therapist.length > 0) {
  //     therapist.forEach(t => {
  //       name.push(`${t.firstName} ${t.lastName}`);
  //     });
  //   }
  //   return name.join(',');
  // }

  async BuildTransactionDetails(result, action: string) {
    this.retailSharedService.selectedProducts = await this.retailValidationService.LoadSelectedProducts(result, this.allShopItems, action);
    this.retailSharedService.isFromDayEnd = true;
    this.retailSharedService.TaxValue = _.cloneDeep(this.retailValidationService.TaxValue);
    this.retailValidationService.TaxValue = 0;
    this.retailSharedService.GoToRetailTransaction = false;
    if (result && result.length > 0) {
      this.retailSharedService.SelectedOutletId = result[0].outletId;
    }
    this.retailSharedService.propertyDate = this.propertyInfo.CurrentDate;
    this.retailSharedService.useRetailInterface = this.propertyInfo.UseRetailInterface;
    if (this.retailSharedService.settleOpenTransaction) {
     this.utils.RedirectTo(RedirectToModules.order);
    } else if (this.retailSharedService.reOpenTransaction) {
     this.utils.RedirectTo(RedirectToModules.retail);
    }
  }

  private FillServiceData(data: AppointmentData) {
  }

  private ClearGridDate(gridData) {
    gridData.tableOptions = {
      TableHdrData: [],
      TablebodyData: []
    };
    gridData.dataCount = 0;
  }

  private ResetServiceObject() {
    this.retailSharedService.isFromDayEnd = false;
    this.retailSharedService.reOpenTransaction = false;
    this.retailSharedService.isReopenViewOnly = false;
    this.retailSharedService.settleOpenTransaction = false;
  }

  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    const result: BaseResponse<any> = await this.http.CallApiAsync({
      host: domain,
      callDesc: route,
      method: callType,
      body,
      uriParams,
    });
    return result;
  }
  private async getClients(clientId: number[]): Promise<any[]> {
    let result: any[] = [];
    if (clientId && clientId.length > 0) {
      clientId = Array.from(new Set(clientId)); // Unique
      let clientResponse: BaseResponse<any[]> = await this.InvokeServiceCallAsync("GetClientByIds", Host.retailPOS, HttpMethod.Put, { includeRelatedData: false }, clientId)
      if (clientResponse.result) {
        result = clientResponse.result;
      }
    }
    return result;
  }

  private getClientName(allClinets: any[], clientId: number): string {
    let clientName = '';
    if (allClinets && allClinets.length > 0) {
      var client = allClinets.find(r => r.id == clientId);
      if (client) {
        clientName = `${client.firstName} ${client.lastName}`
      }
    }
    return clientName;
  }

  async GetNightAuditConfig(){
    const result = await this.nightAuditBusiness.GetNightAuditConfig(Product.RETAIL);
    let configTime = this.localization.getDate(result.configTime);
    let currentDate = this.localization.LocalizeCurrentDateTimeFormatDDMMMYYYY(this.propertyInfo.CurrentDate.toString());
    let currentTime = this.localization.getDate(this.localization.LocalizeDateTimeFormatSecondsDDMMMYYYYheader(configTime));
    const timeDiff = this.localization.getTimeDifference(this.localization.getTime(currentTime, 24), this.localization.getTime(configTime, 24), 'Min')
    this.isManualNightAuditRestricted = timeDiff <= 10 && timeDiff >= 0;
    if(this.isManualNightAuditRestricted){
      const scheduledTime = this.localization.getTime(configTime, 12)
      this.nightAuditRestricted = this.localization.replacePlaceholders(this.captions.NightAuditScheduled, ["time"], [scheduledTime]);
    }
  }
}
