
import { forkJoin as observableForkJoin, Observable, Subscription} from 'rxjs';

import { timeout } from 'rxjs/operators';
import { Injectable,  OnDestroy } from "@angular/core";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import { SPAConfig } from "../../config/SPA-config";
import { BaseResponse } from '../business/shared.modals';
import { MatDialog } from "@angular/material/dialog";
import { DialogOverviewExampleDialog } from "../dialog-popup/dialogPopup-componenet";
import { Host } from "../globalsContant";
import { Localization } from "../../../localization/localization";
import { CommonPropertyInformation } from "../../services/common-property-information.service";
import { SessionManagerService } from '../../services/session-manager.service';
import { environment } from 'src/environments/environment';
import { AccountingUrl, FolioConstants } from 'src/app/common/constants';
import { Product } from 'src/app/common/enums/shared-enums';

export enum HttpMethod {
    Get = "GET",
    Post = "POST",
    Put = "PUT",
    Patch = "PATCH",
    Delete = "DELETE"
}

export interface ServiceParams {
    success: <T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]) => void
    error: <T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]) => void
    method: HttpMethod
    callDesc: string
    uriParams?: any
    header?: any
    body?: any
    showError?: boolean
    extraParams: any[]
    host: Host
    queryString?: KeyValuePair
    withQueryString?: string
}

export interface ServiceParamsAsync {
    method: HttpMethod
    callDesc: string
    uriParams?: any
    header?: any
    body?: any
    showError?: boolean
    host: Host
    queryString?: KeyValuePair
    withQueryString?: string
}

export interface KeyValuePair {
    key: string;
    value: any[];
}

@Injectable()
export class HttpServiceCall implements OnDestroy {

    private Subscriptions = new Subscription();

    exceptionHandle(error: any) {
        console.error(error);
        throw error;
    }
  
    ngOnDestroy(): void {
        try {
            if (this.Subscriptions) {
                this.Subscriptions.unsubscribe();
            }
        }
        catch (exeption) {

        }
    }

    private baseUrl: string = "";

    // injected http in constructor
    constructor(private http: HttpClient, private routeConfig: SPAConfig, private dialog: MatDialog, public localization: Localization, private PropertyInfo: CommonPropertyInformation
        , public sessionManagerService: SessionManagerService) {

    }

    private GetErrorCodebyHostName(Params: Host, err: any): number {
        let _errCode: number;
        _errCode = (err.error != null && err.error.errorCode && !err.error.successStatus) ? err.error.errorCode : -2;
        if (Params == Host.retailPOS) {
            return -200;
        }
        else {
            return _errCode;
        }

    }

    public async CallApiAsync<T>(serviceParamsAsync: ServiceParamsAsync, isLoaderRequired?:boolean, isSessionCheckNotRequired?:boolean): Promise<BaseResponse<T>> {
        try {
            this.baseUrl = this.getRouteHost(serviceParamsAsync.host);
            if (!this.baseUrl) {
                this.routeConfig.load();
                this.baseUrl = this.getRouteHost( serviceParamsAsync.host);
            }
            if (!this.baseUrl) return null;
            let returnPromise = this.CallApi<BaseResponse<T>>(serviceParamsAsync.method, serviceParamsAsync.callDesc, serviceParamsAsync.uriParams, serviceParamsAsync.header,
                serviceParamsAsync.body, serviceParamsAsync.queryString, '',isLoaderRequired, isSessionCheckNotRequired).toPromise();

            if (!serviceParamsAsync.showError) {
                returnPromise.catch(err => {
                    if (err && err.status == 401 && err.error == 'token_expired') {
                        this.sessionManagerService.logout();
                        this.sessionManagerService.goToLogin();
                        return;
                    }
                    let errCode = this.GetErrorCodebyHostName(serviceParamsAsync.host, err);
                    let errMsg: string = this.localization.getError(errCode);
                    this.ShowError(errMsg, this.localization.captions.common.Error);
                });
            }

            return returnPromise;
        }
        catch (ex) {
            return null;
        }
    }

    public async InvokeApiAsync<T>(uri: string, method: HttpMethod, body?: any, header?: any, isLoaderRequired?:boolean): Promise<T> {
        let headers = this.setHeaders(header, isLoaderRequired,false,uri);

        if (typeof (body) == "string") {
            body = JSON.stringify(body);
        }
        switch (method) {
            case HttpMethod.Post:
                return this.InvokePostApiMethod<T>(uri, headers, body).toPromise();
            case HttpMethod.Get:
                return this.InvokeGetApiMethod<T>(uri, headers).toPromise();
        }
    }

    private InvokePostApiMethod<T>(uri: string, header?: HttpHeaders, body?: any): Observable<T> {
        return this.http.post<T>(uri, body, {
            headers: header
        });
    }

    private InvokeGetApiMethod<T>(uri: string, header?: HttpHeaders): Observable<T> {
        return this.http.get<T>(uri, {
            headers: header
        });
    }

    public async InvokeApiAsyncWithTimeout<T>(uri: string, method: HttpMethod, body?: any, header?: any, timeoutDelay: number = 5000, isLoaderRequired?:boolean): Promise<T> {
        let headers = this.setHeaders(header, isLoaderRequired,false,uri);

        if (typeof (body) == "string") {
            body = JSON.stringify(body);
        }
        switch (method) {
            case HttpMethod.Post:
                return this.InvokePostApiMethodWithTimeout<T>(uri, headers, body, timeoutDelay).toPromise();
            case HttpMethod.Get:
                return this.InvokeGetApiMethodWithTimeout<T>(uri, header, timeoutDelay).toPromise();
        }
    }

    private InvokePostApiMethodWithTimeout<T>(uri: string, header?: HttpHeaders, body?: any, timeoutSeconds: number = 5000): Observable<T> {
        return this.http.post<T>(uri, body, {
            headers: header
        }).pipe(timeout(timeoutSeconds));
    }

    private InvokeGetApiMethodWithTimeout<T>(uri: string, header?: HttpHeaders, timeoutSeconds: number = 5000): Observable<T> {
        return this.http.get<T>(uri, {
            headers: header
        }).pipe(timeout(timeoutSeconds));
    }

    /*
     * Generic Api call with callback to get result
     * CallDesc - API Description/Key of Route detail
     * httpMethod - enum value of HttpMethod
     * header - Http header value - key value pair
     * body - Http body
     * callback - callback function
     * T- type( Generic )
     */
    public CallApiWithCallback<T>(serviceParams: ServiceParams,isSessionCheckNotRequired?:boolean): void {
        this.baseUrl = serviceParams.host ? this.getRouteHost(serviceParams.host) : "";
        if (!this.baseUrl) {
            if(RouteJsonConfig){
                this.routeJsonload(RouteJsonConfig, serviceParams, isSessionCheckNotRequired);
            }
            else {
                let jsonSubscribe: Subscription = this.routeConfig.loadRouteJson().subscribe(res => {
                    this.routeJsonload(res, serviceParams, isSessionCheckNotRequired);
                    if (jsonSubscribe) {
                        jsonSubscribe.unsubscribe();
                    }
                });
            }
        } else {
            this.InternalCallApiMethod<T>(serviceParams,isSessionCheckNotRequired);
        }
    }

    routeJsonload<T>(routesValue, serviceParams, isSessionCheckNotRequired?:boolean){
        this.routeConfig.RouteProperty = routesValue;
        this.baseUrl = serviceParams.host ? this.getRouteHost(serviceParams.host) : "";
        this.InternalCallApiMethod<T>(serviceParams, isSessionCheckNotRequired);
    }
    /*
     * Subscribe API, get the result, navigate to success/error/complete method
     * @param serviceParams
     */
    private InternalCallApiMethod<T>(serviceParams: ServiceParams,isSessionCheckNotRequired?:boolean): void {
        let httpSubscription: Subscription = this.CallApi<BaseResponse<T>>(serviceParams.method, serviceParams.callDesc, serviceParams.uriParams, serviceParams.header, serviceParams.body, serviceParams.queryString, serviceParams.withQueryString,false,isSessionCheckNotRequired)
            .subscribe(
                /* success */
                result => {
                    this.HttpCallSuccess(result, serviceParams);
                },
                /* error */
                error => {
                    this.HttpCallError(error, serviceParams);
                },
                /* complete */
                () => this.HttpCallComplete(httpSubscription)

            );
        this.Subscriptions.add(httpSubscription);
    }

    setHeaders(header?: any, isLoaderRequired?:boolean, isSessionCheckNotRequired?:boolean, url?:string): HttpHeaders {
        let token = sessionStorage.getItem("_jwt");
        let userSessionId = sessionStorage.getItem("userSession");
        let productId = Number(this.localization.GetsessionStorageValue('propertyInfo', 'ProductId'));
        if(url?.includes(AccountingUrl.accountingService) && (productId == Product.SPA || productId == Product.GOLF)){
            userSessionId = sessionStorage.getItem("_accUserSession");;
            token = sessionStorage.getItem('_accJwt');
        }
        if(url?.includes(FolioConstants.folioService) && (productId == Product.SPA || productId == Product.GOLF)){
            userSessionId = sessionStorage.getItem("_folioUserSession");;
            token = sessionStorage.getItem('_folioJwt');
        }
        return new HttpHeaders(header)
            .set('Accept-Language', navigator.language)
            .set('Content-Type', 'application/json')
            .set("Authorization", token ? 'Bearer ' + token : "")
            .set("SessionId", userSessionId ? userSessionId : "")
            .set("isLoaderRequired", isLoaderRequired? "true": "false")
            .set("ngsw-bypass", "") //Activities - Retail integration
            .set("isSessionCheckNotRequired", isSessionCheckNotRequired?  "true": "false");
       
    }
 
    public validateString(input: string) {
        if (input != "null" && input != null && input != undefined && input != '') {
            return true;
        }   
        return false;
    }

    /*
     * Generic Api call to get result
     * CallDesc - API Description/Key of Route detail
     * httpMethod - enum value of HttpMethod
     * header - Http header value - key value pair
     * body - Http body
     * callback - callback function
     * T- return type( Generic )
     */
    public CallApi<T>(method: HttpMethod, callDesc: string, uriParams?: any, header?: any, body?: any, quertString?: any, withQueryString?: string, isLoaderRequired?:boolean, isSessionCheckNotRequired?:boolean): Observable<T> {
        //Encode URI Params
        uriParams = uriParams ? uriParams : "";
        let getUrl = this.getApiUrl(callDesc, uriParams);
        let headers = this.setHeaders(header, isLoaderRequired, isSessionCheckNotRequired,getUrl);
        if (header) {
            headers.set("responseType", "text");
        }

        if (typeof (body) == "string") {
            body = JSON.stringify(body);
        }

        if (method == HttpMethod.Get) {
            return this.GetMethod<T>(callDesc, headers, uriParams, quertString, withQueryString);
        }
        else if (method == HttpMethod.Post) {
            return this.PostMethod<T>(callDesc, headers, body, uriParams);
        }
        else if (method == HttpMethod.Put) {
            return this.PutMethod<T>(callDesc, headers, body, uriParams);
        }
        else if (method == HttpMethod.Patch) {
            return this.PatchMethod<T>(callDesc, headers, body, uriParams);
        }
        else if (method == HttpMethod.Delete) {
            return this.DeleteMethod<T>(callDesc, headers, body, uriParams);
        }
    }

    /*
   * @param callDesc Paramter API call description - gets api url from config
   * @param headers - http header of the call
   * @param uriParams - Uri params to be added with Api URL
   * e.g -
   *  ?name=john&age=33
   *  /1234
   * @param arg -
   * @param callBack - callback function
   */

  
    public GetPropertyInfo(name: string) {
        var nameEQ = name + "=";
        var propertyInfo = sessionStorage.getItem("propertyInfo")
        if (propertyInfo != null) {
            var ca = propertyInfo.split(";");

            for (let property of ca) {
                var c = property.trim();
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
        }
        return "";
    }
    private GetMethod<T>(callDesc: string, header?: HttpHeaders, uriParams?: any, quertString?: any, withQueryString?: string): Observable<T> {
        let getUrl = this.getApiUrl(callDesc, uriParams);

        if (quertString && quertString.key != null && quertString.value != null) {
            let keyValueArray: any[] = quertString.value;
            let keyName: string = quertString.key;
            let queryValue: any = [];

            for (let key of keyValueArray) {
                queryValue.push(`${keyName}=${key}`);
            }

            if (queryValue.length > 0) {
                getUrl += `?${queryValue.join('&')}`;
            }
        }
        if (withQueryString) {
            getUrl = getUrl + withQueryString;
        }
        return this.http.get<T>(getUrl, {
            headers: header
        });

    }


    /*
    * Post Http call
    */
    private PostMethod<T>(callDesc: string, header?: HttpHeaders, body?: T, uriParams?: any): Observable<T> {
        let getUrl = this.getApiUrl(callDesc, uriParams);
        return this.http.post<T>(getUrl, body, {
            headers: header
        });
    }

    /*
    * Put Http call
    */
    private PutMethod<T>(callDesc: string, header?: HttpHeaders, body?: any, uriParams?: any): Observable<T> {
        let getUrl = this.getApiUrl(callDesc, uriParams);
        return this.http.put<T>(getUrl, body, {
            headers: header
        });
    }

    /*
    * Patch Http call
    */
    private PatchMethod<T>(callDesc: string, header?: HttpHeaders, body?: any, uriParams?: any): Observable<T> {
        let getUrl = this.getApiUrl(callDesc, uriParams);
        return this.http.patch<T>(getUrl, body, {
            headers: header
        });
    }

    /*
    * Delete Http call
    */
    private DeleteMethod<T>(callDesc: string, header?: HttpHeaders, body?: any, uriParams?: any): Observable<T> {
        let getUrl = this.getApiUrl(callDesc, uriParams);
        const httpOptions = {
            headers: header, body: body
        };
        return this.http.delete<T>(getUrl, httpOptions);

    }

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    /**
     * Form API URL based on Parameters
     * @param callDesc - API call desc
     * @param uriParams - URI parameters
     */
    private getApiUrl(callDesc: string, uriParams?: any): string {
        let url: string = `${this.baseUrl}/`;
        if (uriParams != undefined && uriParams != null && typeof uriParams == "object") {
            let uriParam = this.getRouteUrl(callDesc);
            let keys = Object.keys(uriParams);
            for (let key of keys) {
                const escapedKey = this.escapeRegExp(key);
                var regEx = new RegExp("{" + escapedKey + "}", "ig");
                uriParam = uriParam.replace(regEx, uriParams[key]);
            }
            url += uriParam;
        }
        else {
            let buildParams = (uriParams == undefined || uriParams == null || uriParams == "") ? "" : "/" + uriParams
            url += this.getRouteUrl(callDesc) + buildParams;
        }
        if (!(url.indexOf("http://") > -1 || url.indexOf("https://") > -1))
            url = window.location.origin + url;
        return url;
    }

    /*
     * @param callDesc - Call Description
     * Returns the Route URL
     */
    private getRouteUrl(callDesc: string): string {
        return this.routeConfig.getUrl(callDesc);
    }
     

    public getRouteHost(host: string): string {
        return environment[host];
    }

    /*
     * Open Error pop up
     */
    ShowError<T>(message: string, title: string, result?: BaseResponse<T>, serviceParams?: ServiceParams, error?: any, extraParams?: any[]): void {
        let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
            height: 'auto',
            width: '600px',
            data: { headername: title, closebool: true, templatename: 'In the Given Data', datarecord: message },
            panelClass: 'small-popup',
            disableClose: true,
            hasBackdrop: true
        });

        let errSubscription: Subscription = dialogRef.afterClosed().subscribe(x => {
            if (error && serviceParams.error) {
                serviceParams.error(error.result, serviceParams.callDesc, extraParams);
            }

            if (errSubscription) {
                errSubscription.unsubscribe();
            }
        });
    }

    getHTTPData(url: string, host?: Host): Observable<any> {
        let getURL = url;
        if (host) {
            getURL = this.getRouteHost(host) + getURL;
        }
      
        return this.http.get(getURL, { headers: this.setHeaders(undefined,false,false,url), responseType: 'text' });
    }

    getBlob(url) {
        return this.http.get(url, { headers: this.setHeaders(undefined,false,false,url), responseType: 'blob' });
    }

    putHTTPBlobData(url: string, body: any, APIHost?: Host): Observable<any> {
        let getURL: string = url;
        if (APIHost) {
            getURL = this.getRouteHost(APIHost) + getURL;
        }
        return this.http.put(getURL, body, { headers: this.setHeaders(undefined,false,false,url), responseType: 'blob' });
    }

    //TO DO Need to Remove
    putHTTPData(url: string, body: any, HostURL?: Host): Observable<any> {
        let getURL: string = url;
        if (HostURL) {
            getURL = this.getRouteHost(HostURL) + getURL;
        }
        return this.http.put(getURL, body, { headers: this.setHeaders(undefined,false,false,url), responseType: 'text' });

    }

    /*
     * Http Success handler
     * @param result - Result from API
     * @param serviceParams - Params used for API call
     */
    HttpCallSuccess<T>(result: BaseResponse<T>, serviceParams: ServiceParams): void {
        if (result.successStatus) {
            serviceParams.success(result, serviceParams.callDesc, serviceParams.extraParams);
        }
        else {
            if (serviceParams.showError) {
                let errorTxt: string = "";

                if (typeof result.result == "number") {
                    errorTxt = this.localization.getError(result.result as number);
                }
                else {
                    var resultArr = <any>result.result;
                    if (resultArr && resultArr.length > 0) {
                        for (let res of resultArr) {
                            errorTxt += "<span>" + this.localization.getError(res) + "</span></br></br>";
                        }
                    }
                }

                this.ShowError(errorTxt, this.localization.captions.common.Error, result, serviceParams, result, serviceParams.extraParams);
                serviceParams.error(result, serviceParams.callDesc, serviceParams.extraParams);
            } else {
                serviceParams.error(result, serviceParams.callDesc, serviceParams.extraParams);
            }
        }
    }

    /*
     * Http error handler
     * @param error - error from API
     * @param serviceParams - Params used for API call
     */
    HttpCallError(error: any, serviceParams: ServiceParams): void {
        let errorTxt: string = "";

        if (error && error.status == 401 && error.error == 'token_expired') {
            this.sessionManagerService.logout();
            this.sessionManagerService.goToLogin();
            return;
        }

        let errorObj = error.error;
        if (error.status == 0) {
            errorTxt = this.localization.getError(0);
        } else if (error.status == 404 || (errorObj && errorObj.errorCode == -1)) {
            errorTxt = this.localization.getError(-2);
            this.ShowError(errorTxt, this.localization.captions.common.Error, error, serviceParams, error, serviceParams.extraParams);
            return;
        } else if (error.status == 401) {
            this.ShowError(this.localization.captions.common.AuthorizationError, this.localization.captions.common.Error, error, serviceParams, error, serviceParams.extraParams);
            return;
        }
        if (serviceParams.showError && errorObj) {
            if (errorObj.errorCode) {
                errorTxt = this.localization.getError(errorObj.errorCode);
            }

            if ((typeof errorObj.result == "number" || typeof errorObj.result == "string") && errorObj.result) {
                errorTxt = this.localization.getError(errorObj.result);
            }
            else {
                var resultArr = <any>errorObj.result;
                if (resultArr && resultArr.length > 0) {
                    errorTxt = "";
                    for (let res of resultArr) {
                        errorTxt += "<span>" + this.localization.getError(res) + "</span></br></br>";
                    }
                }
            }
            this.ShowError(errorTxt, this.localization.captions.common.Error, error, serviceParams, error, serviceParams.extraParams);
            serviceParams.error(errorObj, serviceParams.callDesc, serviceParams.extraParams);
        }
        else if (errorObj) {
            serviceParams.error(errorObj, serviceParams.callDesc, serviceParams.extraParams);
        }
        else {
            this.ShowError(error.statusText, this.localization.captions.common.Error, error, serviceParams, errorObj, serviceParams.extraParams);
        }
    }

    /*
     * Http API call complete handler
     * @param httpSubscription - Subsription object
     */
    HttpCallComplete(httpSubscription: Subscription): void {
        if (httpSubscription) {
            httpSubscription.unsubscribe();
        }
    }

    /**
     * This method returns the observable of service call list and can be subscribed in calling method to wait for list of request to get completed.
     * @param httpReq List of Http Request to be sent
     */
    public WaitForHttpCalls(httpReq: ServiceParamsAsync | ServiceParamsAsync[]): Observable<any[]> {
        let observables: Promise<any>[] = [];
        if (Array.isArray(httpReq)) {
            httpReq.forEach(x => {
                let httpCall: Promise<any> = this.CallApiAsync(x);
                observables.push(httpCall);
            });
        }
        else if (httpReq) {
            observables.push(this.CallApiAsync(httpReq));
        }
        return observableForkJoin(observables);
    }
}
