import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
    selector: '[numMaxlength]'
})
export class NummaxLength {
    public numberValue;
    private minValue: number;
    private maxValue: number;
    constructor(el: ElementRef, private control: NgControl) {
        this.numberValue = el.nativeElement;
        this.minValue = parseInt(el.nativeElement.min);
        this.maxValue = parseInt(el.nativeElement.max); 
        if((this.numberValue.maxLength === -1) || ((this.numberValue.maxLength === undefined) || (this.numberValue.maxLength === null) || (this.numberValue.maxLength === "")) ){
            this.numberValue.maxLength = 9999999999999999;
        }
    }

    @HostListener('keydown', ['$event']) keydown($event: KeyboardEvent) {
        let arrayKeys: any = [9, 27, 13, 110, 190, 17, 86]
        let server = arrayKeys.find(x => x == $event.keyCode);
        if (server ||
            (($event.keyCode == 65 || $event.keyCode == 86 || $event.keyCode == 67) && ($event.ctrlKey === true || $event.metaKey === true)) ||
            ($event.keyCode >= 35 && $event.keyCode <= 40)) {
            $event.preventDefault();
        }
        if (($event.shiftKey || ($event.keyCode < 48 || $event.keyCode > 57)) && ($event.keyCode < 96 || $event.keyCode > 105) && ([8, 46].indexOf($event.keyCode) == -1)) {
            $event.preventDefault();
        }

    }
    @HostListener('keypress') keypress() {
        if (this.numberValue.value.length > this.numberValue.maxLength) {
            return false;
        }
    }

    @HostListener('input', ['$event']) oninput(event) {
        let value = event.target.value;
        if (this.minValue && value < this.minValue) {
            event.currentTarget.value = '';
            this.control.control.setValue(event.currentTarget.value);
        }
    }

}