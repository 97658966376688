import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { AddressComponent } from '@app/shared/components/create-update-guest-tab-layout/definitions/address/address.component';
import { Utilities } from '@app/shared/utilities/utilities';
import { PhoneNumberFormatterPipe } from '@app/shared/pipes/phone-number-formatter.pipe';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { AdditionalinfoComponent } from '@components/create-update-guest-tab-layout/definitions/additionalinfo/additionalinfo.component';
import { ContactComponent } from '@components/create-update-guest-tab-layout/definitions/contact/contact.component';
import { PersonalInfoComponent } from '@components/create-update-guest-tab-layout/definitions/personal-info/personal-info.component';
import { PreferencesComponent } from '@components/create-update-guest-tab-layout/definitions/preferences/preferences.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { buttonTypes, ReservationType, SlottingMode, ValidationMessageType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { FullContactDTO } from '@models/InputContact';
import { MemberInfoBase, SecondaryContactDTO, SettingType, SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { FacadeService } from '@services/facade.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { uniqBy } from 'lodash';
import { CartUtilities } from '@app/common/components/menu/vcart/cart.utilities';
import _ from 'lodash';
import { EventIntializers } from '@app/shared/constants/globalConstants';
import { EngageMemberByCardIdRequestDTO } from '@app/shared/models/EngageMemberByCardIdRequestDTO';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { BookingTypeHandler } from '@app/shared/utilities/BookingTypeHandler';
import moment from 'moment';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { CardInfoComponent } from '@app/shared/components/create-update-guest-tab-layout/definitions/card-info/card-info.component';


@Component({
  selector: 'app-guest-selection',
  templateUrl: './guest-selection.component.html',
  styleUrls: ['./guest-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GuestSelectionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() popTitleLabel: EventEmitter<any> = new EventEmitter();
  @Input() selectedIndex: number;
  guestSelectedIndex: any;
  searchValue: any;
  showAddNewGuestBtn = true;
  guestContacts: any = [];
  showSelectedGuest = false;
  isPMSSearchEnabled:boolean = false;
  isStayConfirmationEnabled:boolean = false;
  guestDetails: FullContactDTO;
  viewUserHolder: any;
  settingType = SettingType;
  componentDetails: ComponentDetails = null;
  buttonTertiary: ButtonValue;
  platformGuestSearchEnable: boolean = false;
  configGuestSearch: FieldConfig[];
  configGuestSearchBy: FieldConfig[];
  @ViewChild('configGuestSearchByForm', { static: true }) configGuestSearchByForm: DynamicFormComponent;
  @ViewChild('configGuestSearchForm', { static: true }) configGuestSearchForm: DynamicFormComponent;
  @ViewChild('vCartGuest') vCartGuestForm: DynamicFormComponent;
  isSelectedGuestVisible = false;
  isEditGuest = false;
  isAddNewGuest = false;
  roomnumbersearch = false;
  hasRoomNUmberSearch = false;
  hasCGPSearch = false;
  searchType = 1;
  configRoomNumber: FieldConfig[];
  isRoomNumberSearchEnabled = false;
  _merchantSetting: any
  subscription: Subscription = new Subscription();
  _settings: SettingsDTO = {} as SettingsDTO;
  isMembershipAvailable = false;
  secondaryGuestDetails: SecondaryContactDTO[] = [];
  partySourceId: number;
  partyID: any = null;
  refreshDialogRef: MatDialogRef<any>;
  partyEmailAndPhoneInfo = { GuestReservationEmail: null, GuestReservationPhoneNumber: null };
  pageNumber: number = 1;
  ExternalGuests: any[]
  vCartGuestConfig:FieldConfig[] = [];
  cartGuestFieldEdit:boolean = false;
  memberShipArr:any =[];
  buttonSearch: ButtonValue;
  autoSearchEnabled:boolean = true;

  showMore = false;
  constructor(public facadeService: FacadeService, public dialog: MatDialog,private ds:DashboardFunctions, public ps: PartyService, public appService: AppService,
    public dialogRef: MatDialogRef<GuestSelectionComponent>, public guestBookService: GuestBookService,
    private popupService: PopupService, @Inject(COMPONENTINPUT) private data, private cdf: ChangeDetectorRef,
    public cs: CacheService, private ts: TranslateService, private emailSelector: EmailSelectorPipe, private phoneSelector: phoneNumberSelectorPipe ,  private cu: CartUtilities, private apiService:ApiService,private bh:BookingTypeHandler) {
    this.subscription.add(cs.settings.subscribe(sett => {
      this._settings = sett;
      this._merchantSetting = this._settings.MerchantSettings;
      this.searchType = Number(this._merchantSetting?.DefaultGuestSearchOption?.SettingValue) || 1;
      if(!this._merchantSetting?.AutoSearchEnabled || this._merchantSetting?.AutoSearchEnabled?.SettingValue == SettingType.ENABLE) {
        this.autoSearchEnabled = true;
      }else {
        this.autoSearchEnabled = false;
      }
      this.loadSearchLabel(this.searchType);
      this.setSearchProperty();
      this.isMembershipAvailable = this._settings.General.UseMembershipNumber;
    }));
  }

  setSearchProperty(){
    this.isPMSSearchEnabled = this.platformGuestSearchEnable = (this.searchType == 7)  ? true: false;
    this.isStayConfirmationEnabled = this.searchType == 6 ? true: false;
    this.isRoomNumberSearchEnabled = this.searchType == 3? true: false;
  }

  loadSearchLabel(value) {
    switch (value) {
      case 1:
        return 'selectGuestSearchText'
      case 4:
        return 'searchbyphoneandemail'
      case 3:
        return 'searchByRoomText'
      case 7:
        return 'searchPMSLabel'
      case 6:
        return 'searchStayConfirmationLabel'
    }
  }

  memberSearchHandler(searchText: string) {
    if (searchText && searchText.length) {
      this.apiService.wildSearchEngageMember(searchText).subscribe(response => {
        this.memberShipArr = response.Payload;
        this.showAddNewGuestBtn = this.memberShipArr.length > 0 ? false :true
      })
    }
  }
  ngOnInit() {
    this.hasRoomNUmberSearch = this._settings.HasRoomNumberSearch;
    this.hasCGPSearch = this._settings.HasCGPSearch;
    let optionval = this.getsupportedoption();
    if(!optionval.find(option => option.id == this.searchType)){
      this.searchType = 1;
      this.setSearchProperty();
    }
    if(this.cs.isIframeEnabled){
      this.getExternalGuestConfig();
    }
    this.configGuestSearchBy = [
      {
        type: 'radio',
        name: 'byguestSearch',
        inputType: 'text',
        class: 'reservation-guest__search-field-input',
        options: optionval,
        cellClick: this.setGuestSearch.bind(this),
        value: this.searchType
      }
    ];
    this.configGuestSearch = [
      {
        type: 'input',
        name: 'guestSearch',
        inputType: 'text',
        label: this.loadSearchLabel(this.searchType),
        class: 'reservation-guest__search-field-input',
        blurClick: this.blurClick.bind(this)
      }
    ];
    this.buttonTertiary = {
      type: buttonTypes.actionSecondary,
      label: 'popUpTitleaddnewguest',
      customclass: 'reservation-guest__add-guest-btn'
    };

    this.buttonSearch = {
      type: buttonTypes.actionSecondary,
      label: 'searchText',
      customclass: 'reservation-guest__search-guest-btn'
    }

    const tabcomponentDetails = [
      {
        tabComponent: PersonalInfoComponent,
        enabledIcon: true,
        tabLabel: 'personalInfoTab',
        tabIcon: 'Path-503',
        isDisabled: false
      },
      {
        tabComponent: ContactComponent,
        enabledIcon: true,
        tabLabel: 'contactTab',
        tabIcon: 'Group-593',
        isDisabled: true
      },
      {
        tabComponent: AddressComponent,
        enabledIcon: true,
        tabLabel: 'addressTab',
        tabIcon: 'Group-593',
        isDisabled: true
      },
      {
        tabComponent: PreferencesComponent,
        enabledIcon: true,
        tabLabel: 'prefernencesTab',
        tabIcon: 'Group-601',
        isDisabled: true
      },
      {
        tabComponent: AdditionalinfoComponent,
        enabledIcon: true,
        tabLabel: 'additionalInfoTab',
        tabIcon: 'Group-602',
        isDisabled: true
      },
      {
        tabComponent: CardInfoComponent,
        enabledIcon: true,
        tabLabel: 'cardInfoTab',
        tabIcon: 'Card-empty-1',
        isDisabled: !this.guestBookService.editGuest
      }
    ];
    this.guestBookService.tabsModal = {
      tabs: tabcomponentDetails,
      default: true,
      isNextButtonAvailable: true,
      isEditTab: false
    };
    this.componentDetails = {
      componentName: TabsComponent,
      dimensionType: 'large',
      popupType: 'tabs',
      tabs: this.guestBookService.tabsModal,
      popUpDetails: {
        isStepper: true,
        eventName: 'notifyParent'
      }
    };
  }

  getStayContact(item){
    this.guestBookService.createGuestHandler(false, null, false, EventIntializers.CREATEGUESTFORRESERVATION, item, EventIntializers.CREATEGUESTFORRESERVATION);
   this.fetchContactDetails(item)

  }

  fetchContactDetails(data){
    let createEditGuestSubscription = this.guestBookService.createEditGuestSubscription$.subscribe((response) => {
      let contactId;
      if(response.Payload){
          contactId = response.Payload.ContactId || response.Payload.Id;
      }else if(response.Change?.ChangeSet?.AdditionalData[0]?.Id){
          contactId = response.Change?.ChangeSet?.AdditionalData[0]?.Id;
      }
      data.Id = contactId;
      data.SearchType = this.searchType;
      createEditGuestSubscription.unsubscribe();
      let payload = this.bh.formValidationDTO(data, this.ps.reservationFormGroup.value.selectedTime.DateTime, this.ps.reservationFormGroup.value.selectedTime.DateTime)
      this.bh.validateBookingTypeForGuest([payload]);   
      let subscription = this.bh.proceedValidation.subscribe(info =>{
        subscription.unsubscribe();
        if(info.state == 'valid' ){
          this.subscription.add(this.guestBookService.getContact(contactId).subscribe(({ Payload: contact }) => {
            contact.StayInfo = {startDate: data.Staystartdate,endDate: data.StayEnddate};
            contact.SearchType = this.searchType;
            contact.MemberInfo = this.memberInfo;
            contact.StayConfirmationCode = data.StayConfirmationNumber || null;
            contact.RoomNumber = data.RoomNumber;
            this.setguestDetails(contact);
            
          }))
        }
        else if(info.state == 'invalid'){
          this.popupService.previousEvent$.next(true)
        }
      });
  })
  }



  setGuestSearch(eve) {
    this.guestContacts = [];
    this.memberShipArr = [];
    this.searchType = eve.value;
    this.isRoomNumberSearchEnabled = false;
    this.configGuestSearchForm.form.controls.guestSearch.setValue('', { emitEvent: false })
    this.showAddNewGuestBtn = true;
    this.setSearchProperty();
    if (eve.value == 3)
      this.isRoomNumberSearchEnabled = true;
    this.isPMSSearchEnabled = eve.value == 7 ? true: false;
    this.isStayConfirmationEnabled = eve.value == 6 ? true: false;
    this.configGuestSearch[0].label = this.loadSearchLabel(this.searchType)
  }
  
  getExternalGuestConfig(){
      //VCart Selected Itinerary guest prefill
    this.mapExternalResourceGuest();
     this.vCartGuestConfig = [
        {
           type: 'select',
           name: 'cartGuest',
           label: this.ts.instant('cartGuest'),
           placeholder:this.ts.instant('cartGuest'),
           options: this.ExternalGuests?.length ?  this.ExternalGuests.map(x =>{ return  {id: x.primaryGuestUuId, value:x.viewValue}}) : [],
           value: this.ExternalGuests?.length ? this.ExternalGuests[0].primaryGuestUuId : null,
           appearance: true,
           validation: [Validators.required],
           isTranslate: true,
           disableErrorStateMatcher: false,
           class: "basic-select",           
        }]
  }

  memberInfo : MemberInfoBase;

  getMemberDetails(contact) {    
    
    let engageIntegrationData = this._settings.General.EngageIntegrationDTO;
    let obj: EngageMemberByCardIdRequestDTO = new EngageMemberByCardIdRequestDTO(contact.CardNo, engageIntegrationData?.TenantId, engageIntegrationData?.SiteId);
    this.memberInfo = new MemberInfoBase(contact.CardNo);
    this.apiService.getEngageMemberByCardId(obj).subscribe((memberResponse) => {
      if (memberResponse.SuccessCode) {
        this.setMemberDetails(memberResponse);
        let createContactReq = this.createContactReq(memberResponse.ProfileInfoGetByCardId.ProfileValue);
        this.guestBookService.createGuestHandler(false, null, false, EventIntializers.CREATEGUESTFORRESERVATION, createContactReq, EventIntializers.CREATEGUESTFORRESERVATION);
        this.fetchContactDetails(contact);
      }
    })
  }

  createContactReq(memberShipdetail) {
    let contact = {
       FirstName: memberShipdetail.FName,
       LastName: memberShipdetail.LName,
       EmailAddress: memberShipdetail?.EmailList[0]?.EmailId,
       PhoneNumber: memberShipdetail?.PhoneList[0]?.PhoneNumber,
       CountryId: this.ps.getCountryId(memberShipdetail?.PhoneList[0]?.PhoneCode),
       PIILastUsedDate: null,
       Birthday: memberShipdetail.Dob,
       ContactCustomFields: [],
       Notes: [],
       VisitStats: [],
       MarketingOptedIn: null,
       MemberAllocationTypesIds : [],
       TrackMembershipNumber: memberShipdetail.CardNo,
       RateType: memberShipdetail.RateType,
       MembershipType: memberShipdetail.MembershipType,
       MemberRateTypeAfterRoundsUsed : memberShipdetail.RateTypeAfterRoundsUsed,
       MemberRateTypeMembershipExpiry : memberShipdetail.RateTypeMembershipExpiry,
       IsMemberActive: !memberShipdetail.IsMembershipExpired,
       MemberInformation : this.ds.memberInfoMapper(this.memberInfo)
     }
     return contact;
   }

   setMemberDetails(memberResponse) {
    if (memberResponse?.ProfileInfoGetByCardId?.ProfileValue) {
      let profileValue = memberResponse.ProfileInfoGetByCardId.ProfileValue;

      this.memberInfo.IsMembershipExpired = profileValue.IsMembershipExpired;
      this.memberInfo.MembershipType = profileValue.MembershipType
      this.memberInfo.PlayerType = profileValue.PlayerType;

      this.memberInfo.RateType = profileValue.RateType;
      this.memberInfo.RateTypeAfterRoundsUsed = profileValue.RateTypeAfterRoundsUsed;
      this.memberInfo.RateTypeMembershipExpiry = profileValue.RateTypeMembershipExpiry;
      this.memberInfo.ConsumedMinutes = 0;
      this.memberInfo.CourseName = "";
      this.memberInfo.Bucket = memberResponse.GolfPoints;
    }
  
  }

  getsupportedoption() {
    const radioarray = [];
    
    this.hasRoomNUmberSearch = this._settings.HasRoomNumberSearch; this.hasCGPSearch = this._settings.HasCGPSearch;
    radioarray.push({ id: 1, value: this.ts.instant("reserveText") })
    if(this._settings.General?.EngageIntegrationDTO?.IsEnabled){
      radioarray.push({ id: 4, value: this.ts.instant("memberText")})
    }
    if (this.hasRoomNUmberSearch) {
      radioarray.push(
      { id: 3, value: this.ts.instant("RoomNo") },
      { id: 6, value: this.ts.instant("stayConfirmation")},
      // { id: 7, value: this.ts.instant("pmsSearch")}
    );
    }
    if(this.hasCGPSearch || this.hasRoomNUmberSearch){
      radioarray.push({ id: 7, value: this.ts.instant("pmsSearch")})
    }
    return radioarray;
  }

  ngAfterViewInit() {
    this.guestSelectedIndex = this.selectedIndex ? this.selectedIndex : this.ps.tabsModal.tabs.findIndex(x => x.tabComponent === GuestSelectionComponent);
    if (this.ps.selectedGuest) {
      this.guestDetails = this.viewUserHolder = this.ps.selectedGuest;
      this.ps.reservationFormGroup.get('selectedGuest').setValue(null);
      this.ps.reservationFormGroup.get('selectedGuest').setValue(this.guestDetails);
    }
    if (this.data && this.data.Contact && !this.data.addOpenBooking) {
      this.partyID = this.data.Id;
      this.ps.partyPageMethod = this.data.PageMethod;
      this.partyEmailAndPhoneInfo.GuestReservationEmail = this.data.GuestReservationEmail ? this.data.GuestReservationEmail : null;
      this.partyEmailAndPhoneInfo.GuestReservationPhoneNumber = this.data.GuestReservationPhoneNumber ? this.data.GuestReservationPhoneNumber : null;
      this.isEditGuest = true;
      if (this.data.Contact) {
        this.getGuestDetails(this.data.Contact.Id, true, this.data.Contact);
      }
      this.validateForm();
    }

    if (this.cs.isIframeEnabled && this.ExternalGuests?.length) {
        this.getCommonGuestProfile(this.ExternalGuests[0]);
    }



    this.subscription.add(this.ps.tabChange$.subscribe((data) => {
      $('.reservation-guest__search-field-input').find(':input').focus();
      if (data === this.guestSelectedIndex) {
        if (this.ps.selectedGuest) {
          this.showSelectedGuest = true;
        }
        if (this.ps.reservationType === ReservationType.Reservation && !this.selectedIndex) {
          if ((this.ps.reservationFormGroup.get('selectedTable').value && this.ps.reservationFormGroup.get('selectedTable').value.length > 0) || this._settings.General.SlottingMode == SlottingMode.Manual) {
            this.popupService.confirmationDialog = null;
          } else {
            this.confirmationDialog();
          }
        }
        this.validateForm();
      }

    }));
    this.formChange();
    this.cdf.detectChanges();
    if (this.ps.selectedGuest) {
      this.showSelectedGuest = true;
      this.ps.isNewGuestFromReservation = false;
    }
    this.subscription.add(this.ps.selectedMembershipDetail$.subscribe(data => {
      if (this.ps.selectedGuest) {
        this.showSelectedGuest = this.data?.Id ? false : data;
        this.ps.isNewGuestFromReservation = false;
      } else {
        this.showSelectedGuest = data;
      }
    }));
    
    this.vCartGuestForm?.form?.valueChanges.subscribe((data) => {
      if(data.cartGuest){
        let externalGuest = this.ExternalGuests.find(guest =>  guest.primaryGuestUuId == data.cartGuest);
        this.getGuestDetails(data.cartGuest, false , externalGuest);
      }
    });
  }

  formChange() {
    this.configGuestSearchForm.form?.valueChanges.pipe(debounceTime(600), distinctUntilChanged()).subscribe(value => {
      if (value.guestSearch) {
        this.guestBookService.searchStringForNewGuest = value.guestSearch;
        this.searchValue = value.guestSearch;
        if(this.autoSearchEnabled) {
          this.searchContacts(value.guestSearch, this.isRoomNumberSearchEnabled);
        }
      }
    });
  }

  confirmationDialog() {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant('noTablesAssignedValidation'),
      dialogTitle: 'deleteTitle',
      width: '500px',
      height: '300px',
      update: this.ts.instant('ok'),
      cancel: this.ts.instant('cancel'),
    }];
    let confirmationcomponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'active',
      dimensionType: 'small',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
    };
    this.popupService.confirmationDialog = confirmationcomponentDetails;
  }

  // Map VCart guest list
  mapExternalResourceGuest() {
    
      let guests = this.cu.getCartGuests()?.map(x => {
        return {
          primaryGuestId:x.guestId,
          primaryGuestUuId:x.platformGuestUuid ,
          viewValue: x.firstName + '  ' + x.lastName,
          disabled: false,
          isFromExternalResource:true,
        }
      });
      let cartGuests = [];
      if (guests && guests.length) {
        //cartGuests.push({ id: '0', viewValue: '', value: '', disabled: false });
        cartGuests.push(...guests);
      }
      let uniqById = _.uniqBy(cartGuests ,'primaryGuestUuId');
      if(!uniqById?.length){
         uniqById = _.uniqBy(cartGuests ,'primaryGuestId');
      }
      this.ExternalGuests =  uniqById
  }

  /* function to search the guest book based on the input text */
  searchContacts(value, isRoomNumberSearchEnabled) {
    let searchValue = value;
    if (this.searchType == 4) {
      this.memberSearchHandler(value);
      
    }
    else if (searchValue && (searchValue.length >= 2)) {
      this.subscription.add(this.facadeService.getContactSuggestion(searchValue, isRoomNumberSearchEnabled,0,10,this.platformGuestSearchEnable,this.isStayConfirmationEnabled, this.isPMSSearchEnabled).subscribe((data) => {
        if (data) {
          this.guestContacts = [];
          data.forEach(contact => {
            contact.modifiedEmail = this.emailSelector.transform(contact.EmailAddress, contact.EmailAddress2, searchValue);
            contact.modifiedPhone = this.phoneSelector.transform(contact.PhoneNumber, contact.PhoneNumber2, contact.CountryPhoneCode, contact.CountryPhoneCode2, searchValue);
          });
          this.guestContacts = this.guestContacts.concat(data);
          this.showAddNewGuestBtn = (this.guestContacts.length > 0 || this.memberShipArr.length > 0) ? false : true;
          this.showMore = data && data.length > 10;
        }
      }));
    } else {
      this.guestContacts = [];
      this.showAddNewGuestBtn = true;
    }
  }

  /* function to search the guest book based on the input text */
  searchContactsfromCGP(firstName?, lastName?, phoneNumber?, emailAddress?, membershipCode?, pageNumber?, pageSize?) {
    this.subscription.add(this.facadeService.getContactExSuggestion(firstName, lastName, phoneNumber, emailAddress, membershipCode, pageNumber, pageSize).subscribe((data) => {
      if (data) {
        this.guestContacts = [];
        data.forEach(contact => {
          contact.modifiedEmail = this.emailSelector.transform(contact.EmailAddress, contact.EmailAddress2, null);
          contact.modifiedPhone = this.phoneSelector.transform(contact.PhoneNumber, contact.PhoneNumber2, contact.CountryPhoneCode, contact.CountryPhoneCode2, null);
        });
        this.guestContacts = this.guestContacts.concat(data);
        this.showAddNewGuestBtn = (this.guestContacts.length > 0 || this.memberShipArr.length > 0) ? false : true;
        this.showMore = data && data.length > 10;
      }
    }));
  }
  showMoreContacts() {
    this.pageNumber += 10;
    let pageSize = 10;
    if (this.searchValue && this.searchValue.length >= 2) {
      this.subscription.add(this.facadeService.getContactSuggestion(this.searchValue, this.isRoomNumberSearchEnabled, this.pageNumber, pageSize).subscribe((data) => {
        if (data) {
          this.guestContacts = [];
          if (data.length == 0 || data.length < 10) {
            this.showMore = false;
          } else {
            this.showMore = true;
          }

          data.forEach(contact => {
            contact.modifiedEmail = this.emailSelector.transform(contact.EmailAddress, contact.EmailAddress2, this.searchValue);
            contact.modifiedPhone = this.phoneSelector.transform(contact.PhoneNumber, contact.PhoneNumber2, contact.CountryPhoneCode, contact.CountryPhoneCode2, this.searchValue);
          });
          this.guestContacts = this.guestContacts.concat(data);
          this.showAddNewGuestBtn = (this.guestContacts.length > 0 || this.memberShipArr.length > 0) ? false : true;
        }
      }));
    } else {
      this.guestContacts = [];
      this.showAddNewGuestBtn = true;
    }
  }

  addnewGuest(event) {
    this.showSelectedGuest = true;
    this.isAddNewGuest = true;
    this.ps.isNewGuestFromReservation = true;
    this.popupService.saveBtnEnable$.next(true);
    this.guestDetails = {} as FullContactDTO;
    this.guestBookService.searchStringForNewGuest = this.configGuestSearchForm.form?.value?.guestSearch;
  }
  cartGuestEdit(value){
    this.cartGuestFieldEdit = true;
  }
  getCommonGuestProfile(commonGuestProfile ,isEdit?) {
    this.subscription.add(this.facadeService.getCommonProfileContact(commonGuestProfile.primaryGuestId || commonGuestProfile.GuestProfileServiceId, commonGuestProfile.primaryGuestUuId).subscribe(data => {
      this.setguestDetails(data.Payload , isEdit);
    }));
  }

  platFormToggleChange(){
    if(this.searchValue)
    this.searchContacts(this.searchValue, this.isRoomNumberSearchEnabled);
  }

  getGuestDetails(contactId, isEdit?, fullContact?) {

 

    if (!contactId && (fullContact.primaryGuestId || fullContact.primaryGuestUuId || fullContact.GuestProfileServiceId) && (fullContact?.isFromExternalResource || this.platformGuestSearchEnable || !contactId)) {
      if((this.platformGuestSearchEnable && fullContact.CommonGuestProfileUUID) || (!contactId && fullContact.CommonGuestProfileUUID)){
        fullContact.primaryGuestId = '';
        fullContact.primaryGuestUuId = fullContact.CommonGuestProfileUUID;
      }
      this.showSelectedGuest = false;
      this.getCommonGuestProfile(fullContact , isEdit);
    } else {
      if (this.searchType !== 2 && contactId) {
        fullContact.SearchType = this.searchType;
        let payload = this.bh.formValidationDTO(fullContact, this.ps.reservationFormGroup.value.selectedTime.DateTime, this.ps.reservationFormGroup.value.selectedTime.DateTime)
        this.bh.validateBookingTypeForGuest([payload]);   
        let subscription = this.bh.proceedValidation.subscribe(info =>{   
          subscription.unsubscribe();
          if(info.state == 'valid'){
            let subscription = this.facadeService.getContact(contactId, this.partyID).subscribe(data => {
              this.setguestDetails(data.Payload, isEdit);   
              subscription.unsubscribe();
            });
          }
          else if(info.state == 'invalid'){
            this.popupService.previousEvent$.next(true)
          }
        }) 
      


      }
      else {
        let modifycontact = fullContact;
        if (fullContact.Id == 0) {
          modifycontact.Id = null;
        }
        this.setguestDetails(modifycontact, isEdit);
      }
    }
  
  }

  setguestDetails(data, isEdit?) {
    this.guestDetails = this.viewUserHolder = data;
    if (this.data && this.data.PartySourceId) {
      this.partySourceId = this.data.PartySourceId;
    }
    this.showSelectedGuest = true;
    this.popupService.saveBtnEnable$.next(true);
    this.ps.reservationFormGroup.get('selectedGuest').setValue(null);

    if (data) {
      data.memberShipId = this.ps.selectedGuest?.memberShipId;
      data.MembershipType = this.ps.selectedGuest?.MembershipType;
      data.MemberActive = this.ps.selectedGuest?.MemberActive;
      data.RateType = this.ps.selectedGuest?.RateType;
    } else {
      data = {};
      data.memberShipId = this.ps.selectedGuest?.memberShipId;
      data.MembershipType = this.ps.selectedGuest?.MembershipType;
      data.MemberActive = this.ps.selectedGuest?.MemberActive;
      data.RateType = this.ps.selectedGuest?.RateType;
    }

    if (this.data && this.data.Contact && !this.data.Id && this.data.Contact.SecondaryContactsList?.length) {
      data.SecondaryContactsList = this.data.Contact.SecondaryContactsList;
    }

    this.ps.reservationFormGroup.get('selectedGuest').setValue(data);
    this.ps.isNewGuestFromReservation = false;
    if (!isEdit) {
      this.ps.reservationFormGroup.markAsDirty();
      this.ps.reservationFormGroup.updateValueAndValidity();
    }
    if (this.data && this.data.Contact) {
      this.ps.isGuestComponentLoaded = true;
      this.ps.openNextPopUp$.next(true);
    }
    if (this.searchType == 2) {
      this.subscription.add(this.facadeService.getProfilePreference(data.CommonGuestProfileUUID).subscribe(data => {
        if (data) {
          if (Array.isArray(data)) {
            this.ps.selectedMemberGuestPreferences = data;
          } else {
            this.ps.selectedMemberGuestPreferences = [data];
          }
        }
      }));
    }
      this.ps.selectGuestSearch$.next(data);
  }
  changeGuest() {
    if (this.ps.sessionBookingData) {
      var hasMemberTypeSelectedSessions = !!this.ps.sessionBookingData.Slots.filter(slotData => {
        var allocationSessions = slotData.sessions.filter(session => session.Availability.AllocationTypeId);
        return !!allocationSessions.length
      }).length;
    }
    if (hasMemberTypeSelectedSessions) {
      this.showSettingsChangedInfo();
    } else {
      this.showSelectedGuest = false;
      this.isAddNewGuest = false;
      this.popupService.saveBtnEnable$.next(false);
      this.isSelectedGuestVisible = true;
      this.showAddNewGuestBtn = (this.guestContacts.length > 0  || this.memberShipArr.length > 0)? false : true;
      this.ps.reservationFormGroup?.get('selectedGuest')?.setValue(null);
      this.validateForm();
    }
    this.cartGuestFieldEdit = false;
  }

  isSelected(noteName) {
    if (this.guestDetails.Notes != null && this.guestDetails.Notes.length > 0) {
      return this.guestDetails.Notes.filter(x => x.Text.toLowerCase() === noteName.toLowerCase())[0] != null;
    }
    return false;
  }

  ngOnDestroy() {
    if (this.subscription) { this.subscription.unsubscribe(); }
    this.ps.reservationFormGroup.get('selectedGuest').setValue(null);
    //this.ps.selectedGuest = null;
  }

  validateForm() {
    let totalCovers = 0;
    let iscovertypeValid;
    if (this.ps.reservationFormGroup.value.selectedSpecialMeal) {
      if (this.ps.reservationFormGroup.value.selectedCoverTypes && this.ps.reservationFormGroup.value.selectedCoverTypes.length > 0) {
        this.ps.reservationFormGroup.value.selectedCoverTypes.forEach((covers) => {
          if (covers.Covers) {
            totalCovers = totalCovers + Number(covers.Covers);
          }
        });
        iscovertypeValid = (this.ps.reservationFormGroup.value.selectedSize == totalCovers) ? true : false;
      }
      else {
        iscovertypeValid = true;
      }
    }
    else {
      iscovertypeValid = true;
    }

    // New Dining booking popup validation changes trigger
    const isReservationFormValid = iscovertypeValid && this.ps.reservationFormGroup.dirty && this.ps.reservationFormGroup.valid;
    if (this.popupService.tabsActionData?.length) {
      this.popupService.tabsActionData[this.popupService.tabsActionData.length - 1].gotoNextTab = isReservationFormValid && this.ps.reservationFormGroup.value.selectedGuest ? true : false;
      this.popupService.tabsActions$.next(this.popupService.tabsActionData);
    }

    //Old dining booking popup validation changes trigger . once old popup removed need to remove below code also 
    this.popupService.formValid$.next(
      {
        isFormValid: isReservationFormValid && this.ps.reservationFormGroup.value.selectedGuest ? true : false,
        currentTab: this.guestSelectedIndex
      } as IFormValidDetails);
  }

  showSettingsChangedInfo() {
    const msg = this.ts.instant('memeberTypeInfoText');
    const title = this.ts.instant('alert');
    this.refreshDialogRef = Utilities.showSettingsChangedInfo(msg, title, this.dialog);
  }

  search(event) {
    var searchValue = this.configGuestSearchForm.form?.value?.guestSearch
    this.searchContactsfromCGP(searchValue.firstName ? searchValue.firstName : "", searchValue.lastName ? searchValue.lastName : "", searchValue.mobile ? searchValue.mobile : "", searchValue.email ? searchValue.email : "", searchValue.membershipCode ? searchValue.membershipCode : "");
    this.pageNumber = 1;
  }

  searchGuest(event) {
    if(this.searchValue && !this.autoSearchEnabled) {
      let roomNoSearch = this.searchType == 3;
      this.searchContacts(this.searchValue, roomNoSearch);
    }else {
      this.guestContacts = [];
      this.memberShipArr = [];
    }
  }

  blurClick(event) {
    if(event?.keyCode == 13 && !this.autoSearchEnabled) {
      this.searchValue = event.target.value;
      let roomNoSearch = this.searchType == 3;
      this.searchContacts(this.searchValue, roomNoSearch);
    }else if(event?.keyCode == 13 && !this.autoSearchEnabled){
      this.guestContacts = [];
      this.memberShipArr = [];
    }
  }
}
@Pipe({
  name: 'emailSelector'
})
export class EmailSelectorPipe implements PipeTransform {
  constructor(private ts: TranslateService) { }
  transform(value1: string, value2: string, searchString: string, allowNA: boolean = true): string {
    if (value1 && searchString && value1.indexOf(searchString) !== -1) {
      return value1;
    }
    else if (value2 && searchString && value2.indexOf(searchString) !== -1) {
      return value2;
    }
    else {
      return value1 ? value1 : value2 ? value2 : (allowNA ? this.ts.instant("NA") : null)
    }
  }

}

@Pipe({
  name: 'phoneNumberSelector'
})
export class phoneNumberSelectorPipe implements PipeTransform {
  constructor(private phoneNumberFormatterPipe: PhoneNumberFormatterPipe, private ps: PartyService, private ts: TranslateService) { }
  transform(value1: string, value2: string, countryCode1: string, countryCode2: string, searchString: string, allowNA: boolean = true,countryId1?: number, countryId2?: number): string {
    if(value1 && countryId1){
      let UniqueCountry = this.ps._settings.Countries.find((country) => { return country.Id == countryId1 })
      return this.phoneNumberFormatterPipe.transform(value1, UniqueCountry.Id);
    }
    else if(value2  && countryId2){
      let UniqueCountry = this.ps._settings.Countries.find((country) => { return country.Id == countryId2 })
      return this.phoneNumberFormatterPipe.transform(value1, UniqueCountry.Id);
    }
    else if (value1 && searchString && value1.indexOf(searchString) !== -1 && countryCode1) {
      let UniqueCountry = this.ps._settings.Countries.find((country) => { return country.CountryPhoneCode == countryCode1 })
      return this.phoneNumberFormatterPipe.transform(value1, UniqueCountry.Id);
    }
    else if (value2 && searchString && value2.indexOf(searchString) !== -1 && countryCode2) {
      let UniqueCountry = this.ps._settings.Countries.find((country) => { return country.CountryPhoneCode == countryCode2 })
      return this.phoneNumberFormatterPipe.transform(value2, UniqueCountry.Id);
    }
    else {
      let UniqueCountry1 = this.ps._settings.Countries.find((country) => { return country.CountryPhoneCode == countryCode1 })
      let UniqueCountry2 = this.ps._settings.Countries.find((country) => { return country.CountryPhoneCode == countryCode2 })
      if (UniqueCountry1 || UniqueCountry2)
        return value1 && countryCode1 ? this.phoneNumberFormatterPipe.transform(value1, UniqueCountry1.Id) : value2 && countryCode2 ? this.phoneNumberFormatterPipe.transform(value2, UniqueCountry2.Id) : (allowNA ? this.ts.instant("NA") : null)
      else {
        let defaultCountry = this.ps._settings.Countries.find(c => c.Name == 'USA').Id;
        return value1 && defaultCountry ? this.phoneNumberFormatterPipe.transform(value1, defaultCountry) : value2 && defaultCountry ? this.phoneNumberFormatterPipe.transform(value2, defaultCountry) : (allowNA ? this.ts.instant("NA") : null)
      }
    }
  }

}
