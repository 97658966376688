<div class="chat-info-container">
  <ng-container *ngIf="!bulkPagingRequest?.length">
  <a data-toggle="popover" [ngStyle]="{color:pageBackgroundColor}"
    *ngIf="(pageMethod == 3 || pageMethod == 7)" (click)="emailMessages()" #emailChat="ngbPopover" container="body" popoverClass="chat-info-container__email-messages"
    [ngbPopover]="messages" [autoClose]="false" triggers="manual"  [placement]="['right-center', 'bottom', 'left-center' , 'auto']" 
    class="icon-envelope chat-info-container__guest-actions seat-border-color" [ngClass]="{'chat-info-container__disable-icon': (getPastReservationStatus(party) && party.Type == PartyType.Reservation) || party.State == PartyState.Seated || !isValidContact,
              'chat-info-container__icon-bg': pageBackgroundColor}"></a>
  <a data-toggle="popover" [ngStyle]="{color:pageBackgroundColor}"
    *ngIf="(pageMethod == 1 || pageMethod == 4 || pageMethod == 6)" [ngbPopover]="messages" container="body"  popoverClass="chat-info-container__sms-messages"
    [autoClose]="false" triggers="manual" #messageChat="ngbPopover" (click)="mobileMessages()" [placement]="['right-center', 'bottom', 'left-center' , 'auto']"
    class="icon-SMS chat-info-container__guest-actions seat-border-color" [ngClass]="{'chat-info-container__disable-icon': (getPastReservationStatus(party) && party.Type == PartyType.Reservation) || party.State == PartyState.Seated || !isValidContact,
              'chat-info-container__icon-bg': pageBackgroundColor}"></a>
  <a [ngStyle]="{color:pageBackgroundColor}" *ngIf="pageMethod == 0" (click)="manualpage()"
    class="icon-Path-506 chat-info-container__guest-actions seat-border-color" [ngClass]="{'chat-info-container__disable-icon': (getPastReservationStatus(party) && party.Type == 0) || party.State == PartyState.Seated,
              'chat-info-container__icon-bg': pageBackgroundColor}"></a>
  <a [ngStyle]="{color:pageBackgroundColor}" *ngIf="(pageMethod == 2 || pageMethod == 5)"
    class="icon-ic_stay_current_portrait_24px chat-info-container__guest-actions seat-border-color" [ngClass]="{'chat-info-container__disable-icon': (getPastReservationStatus(party) && party.Type == PartyType.Reservation) || party.State == PartyState.Seated || !isValidContact,
              'chat-info-container__icon-bg': pageBackgroundColor}"></a>
  </ng-container>
  <ng-container *ngIf="bulkPagingRequest?.length > 1">
    <a data-toggle="popover" [ngStyle]="{color:pageBackgroundColor}"
    (click)="emailMessages()" #emailChat="ngbPopover" container="body" popoverClass="chat-info-container__email-messages"
    [ngbPopover]="messages" [autoClose]="false" triggers="manual"  [placement]="['right-center', 'bottom', 'left-center' , 'auto']" 
    class="icon-group-chat chat-info-container__guest-actions seat-border-color" [ngClass]="{'chat-info-container__disable-icon': (getPastReservationStatus(party) && party.Type == PartyType.Reservation) || party.State == PartyState.Seated || !isValidContact || !isGroupMsgValid,
              'chat-info-container__icon-bg': pageBackgroundColor}"></a>
  </ng-container>  
</div>
  <ng-template class="messages" class="arrow-up" #messages>
    <app-chat-view *ngIf="inputString == 'messages'" [messages]="predefinedMsgs$.asObservable() | async"
      [partyInfo]="party" [ContactId]="ContactId" [historyData]="history" (closePopover)="closeMobileChat()" [inputString]="inputString" [overlayDropdown]="true" [preferredPageMethod]="pageMethod" [bulkPagingRequest]="bulkPagingRequest">
    </app-chat-view>
  </ng-template>
  <ng-template class="history" #history>
    <app-chat-view *ngIf="inputString == 'history'" [messages]="predefinedMsgs$.asObservable() | async"
      [partyInfo]="party" [ContactId]="ContactId" [historyData]="historyData" [inputString]="inputString" [overlayDropdown]="true" [preferredPageMethod]="pageMethod" [bulkPagingRequest]="bulkPagingRequest"></app-chat-view>
  </ng-template>
