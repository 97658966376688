import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorInfoComponent } from '../util-components/error-info/error-info.component';
import { ConfirmationDialogComponent } from '../util-components/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessInfoComponent } from '../util-components/success-info/success-info.component';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  dialog = inject(MatDialog);
  ts = inject(TranslateService);
  snackBar = inject(MatSnackBar);

  constructor() { }

  showError(errors: string[], title?: string) {
    let errDialog = this.dialog.open(ErrorInfoComponent, {
      data: { errors, title },
      width: '400px'
    });

    return errDialog;
  }
  
  snackbarInfoMessage(message: string){
    this.snackBar.open(message, '' , {
      duration: 3000,
    });
  }

  showConfirmationDialog(title: string, message: string, saveLable: string, callFn): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        message: this.ts.instant(message),
        title: this.ts.instant(title),
        saveLabel: this.ts.instant(saveLable),
        cancelLabel: this.ts.instant('Cancel')
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        callFn();
      }
    })
  }

  showSuccessMessage(title: string, messages: string[]){
    let errDialog = this.dialog.open(SuccessInfoComponent, {
      data: { title, messages },
      width: '400px'
    });

    return errDialog;
  }
}
