<div class="rate-plan-view">
    <thead class="py-1 price-table-header d-flex flex-row seat-header-four">
        <th class="p-0 col-lg-4 col-4">
            <div class="activity-description-bolder">{{'Description' | translate}}</div>
        </th>
        <th class="p-0 col-lg-2 col-2 d-flex justify-content-center">
            <div class="activity-description-bolder">{{'Qty' | translate}}</div>
        </th>
        <th class="p-0 col-lg-2 col-2 d-flex justify-content-end text-align-right">
            <div class="activity-description-bolder retail-label">{{'OriginalAmount' | translate}}</div>
        </th>
        <th class="p-0 col-lg-2 col-2 d-flex justify-content-end">
            <div class="activity-description-bolder label-wrap-content">{{'SalePrice' | translate}}</div>
        </th>
        <th class="p-0 col-lg-2 col-2 d-flex justify-content-end">
            <div class="activity-description-bolder label-wrap-content">{{'ItemTotal' | translate}}</div>
        </th>
    </thead> 
      <tbody>
        <tr *ngIf="(!slot.coverTypeVariants || slot.coverTypeVariants.length == 0) && (!slot.bookingTypeVariants || slot.bookingTypeVariants.length == 0)">                        
              <td class="activity-booking-summary__rate-label p-0 col-lg-4 col-4 d-flex "> <span
                  class="activity-booking-summary__rate-label--main">{{'TotalBaseRate' | translate}}</span> 
              </td>
              <td class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-center">
                <span
                  class="activity-booking-summary__rate-label--main">{{partyData.BookingSize}}</span>
              </td>
              <td  class="activity-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">{{OperationCurrency}} {{
                slot.sessionChargeinitialValue |
                currencyDecimalFormatter}}</td>
              <td *ngIf="!perItemEditable" class="activity-booking-summary__rate">{{OperationCurrency}} {{
                slot.sessionCharge |
                currencyDecimalFormatter}}</td>
              <td class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
                *ngIf="perItemEditable">
                 <input
                  class="activity-description-bolder activity-booking-summary__editable-input-currency"
                  [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled> 
                  
                  <input DecimalNumbersOnly  (blur)="onBlur($event)"
                  class="activity-description-bolder activity-booking-summary__editable-input" type='number' min="1"
                  step="0.10" [(ngModel)]="slot.sessionCharge" (keyup)="calculateValue(slot)"
                   [ngClass]="{'pointer-active': perItemEditable}"
                  value=" slot.sessionCharge | currencyDecimalFormatter" > </td>
              <td class="activity-booking-summary__rate p-0 col-lg-2 col-2 d-flex justify-content-end">
                {{OperationCurrency}} {{(slot.sessionCharge * partyData.BookingSize) |
                currencyDecimalFormatter}}</td>                  
        </tr>

        <!--Location Variant-->
        <tr>
            <td class="activity-booking-summary__rate-label p-0 col-lg-4 col-4 d-flex "> 
                <span
                class="activity-booking-summary__rate-label">{{'LocationVariant' | translate}} <span
                  class="sub-text">({{'forTotalOf' | translate}} {{slot.size ? slot.size :
                  partyData?.BookingSize}}
                  )</span> </span>
            </td>
            <td class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-center">
              
            </td>
            <td  class="activity-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">{{OperationCurrency}} {{slot.RatePlanVariants.LocationVariant
                | currencyDecimalFormatter}}</td>
            <td *ngIf="!perItemEditable" class="activity-booking-summary__rate"> {{OperationCurrency}} {{slot.RatePlanVariants.LocationVariant
                | currencyDecimalFormatter}}</td>
            <td class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
              *ngIf="perItemEditable">
               <input
                class="activity-description-bolder activity-booking-summary__editable-input-currency"
                [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled> 
                
                <input DecimalNumbersOnly  (blur)="onBlur($event)"
                class="activity-description-bolder activity-booking-summary__editable-input" type='number' min="1"
                step="0.10" [(ngModel)]="slot.sessionCharge" (keyup)="calculateValue(slot)"
                 [ngClass]="{'pointer-active': perItemEditable}"
                value=" slot.sessionCharge | currencyDecimalFormatter" > </td>
            <td class="activity-booking-summary__rate p-0 col-lg-2 col-2 d-flex justify-content-end">
                {{OperationCurrency}} {{slot.RatePlanVariants.LocationVariant
                    | currencyDecimalFormatter}}</td>    


        </tr>

        <!--Member Variant-->
        <tr>
            <td class="activity-booking-summary__rate-label p-0 col-lg-4 col-4 d-flex "> 
                <span
                class="activity-booking-summary__rate-label">{{'MemberShipVariant' | translate}}  </span>
            </td>
            <td class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-center">
              
            </td>
            <td  class="activity-booking-summary__rate col-lg-2 col-2 justify-content-end text-align-right">{{OperationCurrency}} {{slot.MemberShipVariant
                | currencyDecimalFormatter}}</td>
            <td *ngIf="!perItemEditable" class="activity-booking-summary__rate">{{OperationCurrency}} {{slot.MemberShipVariant
                | currencyDecimalFormatter}}</td>
            <td class="activity-booking-summary__rate-label p-0 col-lg-2 col-2 d-flex justify-content-end"
              *ngIf="perItemEditable">
               <input
                class="activity-description-bolder activity-booking-summary__editable-input-currency"
                [ngClass]="{'pointer-active': perItemEditable}" value="{{OperationCurrency}}" disabled> 
                
                <input DecimalNumbersOnly  (blur)="onBlur($event)"
                class="activity-description-bolder activity-booking-summary__editable-input" type='number' min="1"
                step="0.10" [(ngModel)]="slot.sessionCharge" (keyup)="calculateValue(slot)"
                 [ngClass]="{'pointer-active': perItemEditable}"
                value=" slot.sessionCharge | currencyDecimalFormatter" > </td>
            <td class="activity-booking-summary__rate p-0 col-lg-2 col-2 d-flex justify-content-end">
                {{OperationCurrency}} {{slot.MemberShipVariant
                    | currencyDecimalFormatter}}</td>    
        </tr>
      </tbody>
 </div>