import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { RetailSetupService } from "./retail-setup.service";
import { Validators, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { RetailLocalization } from "../common/localization/retail-localization";
import { HttpMethod } from "../shared/service/http-call.service";
import { Host, SourceSystem } from "../shared/globalsContant";
import { RetailPopupComponent } from "../retail-popup/retail-popup.component";
import { popupConfig } from "../shared/business/shared.modals";
import { InventoryOutletToDisplay,RetailItemDetail, RoomTypeAssignmentRetailItem, UpdateCategoryLinking } from "../retail.modals";
import { RetailPropertyInformation } from "../common/services/retail-property-information.service";
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { RetailDataAwaiters } from "../shared/events/awaiters/retail.data.awaiters";
import { RetailFunctionalityBusiness } from "../shared/business/retail-functionality.business";

@Component({
  selector: 'app-retail-setup',
  templateUrl: './retail-setup.component.html',
  styleUrls: ['./retail-setup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RetailFunctionalityBusiness]
})
export class RetailSetupComponent implements OnInit {
  @ViewChild('stepper') matstep: MatStepper;
  @Input() dataInput: any;
  @Input() popupConfigs: popupConfig;
  captions: any;
  commonCaptions: any;
  selectedOutlets: Int16Array[] = [];
  steperBool: boolean;
  firstFormGrp: any;
  secondFormGrp: any;
  thirdFormGrp: any;
  fourthFormGrp: any;
  fifthFormGrp: any;
  isEdit: boolean;
  isCopyItem: boolean = false;
  isValidOtherInfo: boolean = false;
  isValidInventory: boolean = false;
  useInventory: boolean = false;
  roomTypes: Promise<RoomTypeAssignmentRetailItem[]>;
  validateBtn: boolean;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  functionalities: { [key: string]: boolean} = {};
  showRetailSetupRoomTypesAssignmentTab = true;
  retailsetupParentForm: UntypedFormGroup;
  validateBtns: boolean;
  isExternalItem: boolean = false;

  constructor(private dialogRef: MatDialogRef<RetailPopupComponent>, public _retailservice: RetailSetupService, private func: RetailFunctionalityBusiness
    ,private Form: UntypedFormBuilder, public localization: RetailLocalization,  public PropertyInfo: RetailPropertyInformation) {
    this.steperBool = false;
  }

  CategoryLinkAvailable(selectedCategory): boolean {
    return selectedCategory.length > 0 ? true : false;
  }

  setAvailableCategoryInfo(categoryInfo) {
    let selectedCategory = categoryInfo.categoryid;
    let selectedCategoryArray = this._retailservice.categories.filter(a => a.id == selectedCategory);
    categoryInfo.SubCategory1 = this._retailservice.subCategoryOne.filter(a => { return this.CategoryLinkAvailable(selectedCategoryArray[0].childSubCategories) ? selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id == a.id) : 0 });
    categoryInfo.SubCategory2 = this._retailservice.subCategoryTwo.filter(a => { return this.CategoryLinkAvailable(categoryInfo.SubCategory1) ? categoryInfo.SubCategory1.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) : 0 });
    categoryInfo.SubCategory3 = this._retailservice.subCategoryThree.filter(a => { return this.CategoryLinkAvailable(categoryInfo.SubCategory2) ? categoryInfo.SubCategory2.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) : 0 });
    categoryInfo.SubCategory4 = this._retailservice.subCategoryFour.filter(a => { return this.CategoryLinkAvailable(categoryInfo.SubCategory3) ? categoryInfo.SubCategory3.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) : 0 });
    categoryInfo.SubCategory5 = this._retailservice.subCategoryFive.filter(a => { return this.CategoryLinkAvailable(categoryInfo.SubCategory4) ? categoryInfo.SubCategory4.some(b => b.childSubCategories.some(sub1 => sub1.id == a.id)) : 0 });
  }



  IsViewOnlyEnabled() {
    if (this._retailservice.IsViewOnly) {
      this._retailservice.GeneralFormGrp.disable();
      this._retailservice.otherInfoFormGrp.disable();
      this._retailservice.InventoryGrp.disable();
      this._retailservice.packagedItemForm.disable();
      this._retailservice.multipackFormGrp.disable();
      this._retailservice.seasonalPriceFormGrp.disable();
      this._retailservice.rentalFormGrp.disable();
    }
  }

  ngOnInit() {
    this._retailservice.selectedRoomTypes = [];
    this._retailservice.showRetailSetupRoomTypesAssignmentTab = false;
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
      this.showRetailSetupRoomTypesAssignmentTab = this._retailservice.showRetailSetupRoomTypesAssignmentTab = this.functionalities.ShowRetailSetupRoomTypesAssignmentTab ?? false;
      this.InitializeFormValues();
      });
      this.formGenerator();
  }
  formGenerator(){
    this.retailsetupParentForm= this.Form.group({
    })  
  }
  InitializeFormValues(){
    this.captions = this.localization.captions.retailsetup;
    this.commonCaptions = this.localization.captions.common;
    this.steperBool = true;
    this._retailservice.itemInfoLoaded = new Subject<void>();
    this.initializeFormGroupValues();
    if (this.popupConfigs.operation == 'edit') {
      this._retailservice.GeneralFormGrp.controls["itemnumber"].disable();
      this.isEdit = true; 
      this._retailservice.SetRetailItemInfo(this.dataInput[0]);
      this._retailservice.setCategoryMapping();
      this.useInventory = this._retailservice.GeneralFormGrp.get('inventory').value;
      this.isExternalItem = this.dataInput[0]?.retailItemDetail?.isExternal && this.dataInput[0].retailItemDetail.sourceSystem == SourceSystem.Eatec;
      if (this.showRetailSetupRoomTypesAssignmentTab) {
        this.roomTypes = this._retailservice.GetRoomTypeAssignmentRetailItem(this.dataInput[0].retailItemDetail.id);
      }
      if (this.dataInput[1] && this.dataInput[1].outlets) {
        let selectedOuts: InventoryOutletToDisplay[] = [];
        let selected = this.dataInput[0].outletItem.map(y => y.outletId);
        selected.forEach(element => {
          let outlet = this._retailservice.outlets.filter(x => x.id === element)[0];
          if (outlet) {
            let inventory = this.dataInput[1].outlets.filter(x => x.outletId == outlet.id)[0];
            if (inventory) {
              outlet.itemPar = inventory.itemPar
              outlet.mtd = inventory.mtd
              outlet.inventoryItemId = inventory.inventoryItemId
              outlet.quantity = inventory.quantity
              outlet.unitCost = inventory.unitCost,
              outlet.weightedAverageCost = inventory.weightedAverageCost,
              outlet.ytd = inventory.ytd
            } else {
              outlet.itemPar = 0
              outlet.mtd = 0
              outlet.inventoryItemId = 0
              outlet.quantity = 0
              outlet.unitCost = 0
              outlet.ytd = 0,
              outlet.weightedAverageCost = 0
            }
            selectedOuts.push(outlet)
          }
        });

        this._retailservice.selectedOutletMgrArr.next(selectedOuts);
      }
      if (this.dataInput[2]){
        this._retailservice.rentalItemInformation = this.dataInput[2];
        }
      this._retailservice.isEdit =this.isEdit;
      this._retailservice.buildTaxArray(this._retailservice.selectedOutletMgrArr.value);
      this._retailservice.setTaxForRetailItem(this.dataInput[0].retailItemTax);
      this.IsViewOnlyEnabled();
    }
    else {
      this.isEdit = false;
      this._retailservice.GeneralFormGrp.controls["itemnumber"].enable();
      this._retailservice.isMultipack = false;
      if (this.showRetailSetupRoomTypesAssignmentTab) {
        this.roomTypes = this._retailservice.GetRoomTypes();
      }
    }
  }

  initializeFormGroupValues() {
    this._retailservice.GeneralFormGrp = this.Form.group({
      popupConfigs: this.popupConfigs.operation,
      copyitem: '',
      autogenerateno: false,
      itemnumber: '',
      itemdescription: '',
      salesprice: '',
      memberprice: '',
      suggestedprice: '',
      curitemcost: '',
      marginpercent: '',
      barcode: '',
      secbarcode: '',
      commissionableitem: false,
      commissionreq: false,
      taxConfig: this.Form.array([]),
      inventory: false,
      itemcatradiogrp: ['', Validators.required],
      categoryid: '',
      subcategory1: '',
      subcategory2: '',
      subcategory3: '',
      subcategory4: '',
      subcategory5: '',
      allowEarn: false,
      disposableItem : false,
      rentalItem: false
    });
    this._retailservice.selectedOutletMgrArr.next([]); 
    this._retailservice.otherInfoFormGrp = this.Form.group({
      groupKey: false,
      scaleItem: false,
      gratuity: false,
      serviceTax: false,
      inventoryItems: false,
      itemType: false,
      timeTrigger: false,
      cartTrigger: false,
      printCart: false,
      printClub: false,
      printShoe: false,
      printShack: false,
      printOne: false,
      printSaleChit:false,
      hangTicket: false,
      sticker: false,
      inactive: false,
      requireComments: false,
      requestName: false,
      openItem: false,
      pms: false,
      multipack: false,
      unit: '',
      gratuityValue: '',
      serviceTaxValue: '',
      perHourRentalRate: '',
      perDayRentalRate: '',
      cartTemplate: '',
      clubTemplate: '',
      shoeTemplate: '',
      saleChit:''
    });
    this._retailservice.InventoryGrp = this.Form.group({
      supplier: [''],
      supplierItem: '',
      leadDays: '',
      inactive: false
    });
    this._retailservice.multipackFormGrp = this.Form.group({
      dateofsalestart: ['dateofsale'],
      dateofexpireend: ['expiration'],
      specificstartdate: '',
      expireenddate: '',
      unlimited: false,
      category: '',
      item: ['',Validators.required],
      itemId: '',
      description: '',
      salesprice: '',
      memberprice: '',
      cost: '',
      service: '',
      gratutity: '',
      persale: ['',Validators.required],
      expiryDays: '',
      multipackArr: this.Form.array([
        this.Form.group({
          category: '',
          item: ['',Validators.required],
          itemId: '',
          description: '',
          salesprice: '',
          memberprice: '',
          cost: '',
          service: '',
          gratutity: ''
        }),
       
      ])
    });
    this._retailservice.rentalFormGrp = this.Form.group({
      rateType: 0,
      rate:'',
      availableOnWeb: false,
      outofOrderQuantity: '',
      bufferTime:'',
      quantity:'',
      tableContent:[],
      hourlyRate : this.Form.array([
        this.Form.group({
          slabStart: [1,Validators.required],
          slabEnd: ['',Validators.required],
          amount: ['',Validators.required]
        })
      ])
    });
    this._retailservice.validateBtn.pipe(takeUntil(this.destroyed$)).subscribe(isValid => 
      this.validateBtn = isValid
      );
  }

  ngOnDestroy(): void {
    this.updateCategoryLinking();
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._retailservice.ResetControls();
    this._retailservice.base64textString = '';
    if(this._retailservice.retailItemUpdateCommissionsubscription){
      this._retailservice.retailItemUpdateCommissionsubscription.unsubscribe();
    }
  }

  IsCategoryHasChanged(): boolean {
    let x: RetailItemDetail = this._retailservice.originalItemDetail.retailItemDetail;

    return (
      x.subCategory1 != this._retailservice.GeneralFormGrp.controls.subcategory1.value ||
      x.subCategory2 != this._retailservice.GeneralFormGrp.controls.subcategory2.value ||
      x.subCategory3 != this._retailservice.GeneralFormGrp.controls.subcategory3.value ||
      x.subCategory4 != this._retailservice.GeneralFormGrp.controls.subcategory4.value ||
      x.subCategory5 != this._retailservice.GeneralFormGrp.controls.subcategory5.value);
  }

  updateCategoryLinking() {
    if (this.isEdit && this.IsCategoryHasChanged()) {
      let updateBody = this.FormUpdatedCategoryLinking();
      let response;

      if (this.isEdit) {
        response = this._retailservice.InvokeServiceCallAsync("UpdateCategoryLinking", Host.retailManagement, HttpMethod.Put, updateBody)
      }
    }
  }

  FormUpdatedCategoryLinking(): UpdateCategoryLinking {
    return {
      retailItemId: this._retailservice.originalItemDetail.retailItemDetail.id,
      categoryId: this._retailservice.GeneralFormGrp.controls.categoryid.value,
      subCategoryId1: this._retailservice.GeneralFormGrp.controls.subcategory1.value || 0,
      subCategoryId2: this._retailservice.GeneralFormGrp.controls.subcategory2.value || 0,
      subCategoryId3: this._retailservice.GeneralFormGrp.controls.subcategory3.value || 0,
      subCategoryId4: this._retailservice.GeneralFormGrp.controls.subcategory4.value || 0,
      subCategoryId5: this._retailservice.GeneralFormGrp.controls.subcategory5.value || 0,
    };
  }


  StepperChange(event: any) {
    this._retailservice.ValidateUpdate();
    if (event.selectedIndex === 1 && this._retailservice.GeneralFormGrp.valid) {
      this.isValidOtherInfo = true;
    } else if (event.selectedIndex === 2 && this._retailservice.GeneralFormGrp.valid && this._retailservice.otherInfoFormGrp.valid) {
      this.isValidInventory = true;
    }
  }

  closeDialog(event) {
    if (event) {
      this.dialogRef.close();
    }
  }

  CommissionModified(event){
    this._retailservice.GeneralFormGrp.markAsDirty();
    this._retailservice.GeneralFormGrp.markAsTouched();
  }

  roomTypeSelection(arg: RoomTypeAssignmentRetailItem[]){
      this._retailservice.selectedRoomTypes =  arg ;
  }

  async Save(){
    this._retailservice.ValidateUpdate();
    if (!this.validateBtn) { return; }
    if (!this.isEdit) {
      this.validateBtn = false;
    }
    const updateBody = this._retailservice.FormBodyData(this.isEdit, this.PropertyInfo.UseRetailInterface);
    let response;
    let itemId = 0;
    if (this.isEdit) {
      response = await this._retailservice.InvokeServiceCallAsync("UpdateRetailItem", Host.retailManagement, HttpMethod.Put, updateBody, { id: updateBody.retailItem.retailItemDetail.id })
      if (response) {
        this._retailservice.updateRetailItemImage(updateBody.retailItem.retailItemDetail.id);
        itemId = updateBody.retailItem.retailItemDetail.id;
      }
    }
    else {
      response = await this._retailservice.InvokeServiceCallAsync("CreateRetailItem", Host.retailManagement, HttpMethod.Post, updateBody)
      if (response) {
        this._retailservice.saveImage(response.result)
        itemId = response.result;
      }
    }
    RetailDataAwaiters.createOrUpdateRoomTypeAssignment(updateBody.retailItem.roomTypeAssignmentRetailItemMapping , itemId);
    if (response && response.result) {
      this._retailservice.retailItemUpdateEvent.next(itemId);
      this.dialogRef.close(updateBody);
    }
    this._retailservice.isEdit = false;
    this._retailservice.selectedOutlet = [];
  }
}
