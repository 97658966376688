<form [formGroup]="AccountSearchForm" [class.align-items-horizontal]="lookupInput?.isCustomUIRequired"
      class="acctSearchForm">
  <div *ngIf="!isStay" class="acct-search-cont">
    <mat-radio-group formControlName="searchType" attr.automationId='Rdo_{{automation}}_searchType'>
      <div class="d-flex radio-padding-bottom" *ngFor="let type of searchTypeList">
        <mat-radio-button [disabled]="isView" (click)="selectSeachType(type)" [value]="type.id">
        </mat-radio-button>
        <div class="d-flex voucher-details">{{type?.name}}</div>
      </div>
    </mat-radio-group>
    <div class="acct-search">
      <mat-form-field class="rc-searchbox" [floatLabel]="floatLabelNever">
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let acct of selectedAccountList" [selectable]="selectable&&!isView" [removable]="removable&&!isView"
                    (removed)="remove(acct)">
            {{acct}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input *ngIf="selectedAccountList.length == 0" [placeholder]="searchBoxPlaceHolder"
                 [disabled]="selectedAccountList.length != 0 || isView" #acctSearch formControlName="acctSearch"
                 [matAutocomplete]="auto" [matChipInputFor]="chipList" #trigger="matAutocompleteTrigger"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="add($event)" attr.automationId='Txt_{{automation}}_acctSearch' />
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectAccount($event)">
          <mat-option matTooltip="{{ acct.accountName }} - {{ acct.accountNumber }}" *ngFor="let acct of ARAccountList" [value]="acct.accountName">
            {{ acct.accountName }} - {{ acct.accountNumber }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="icon-section" *ngIf="AccountSearchForm.controls.searchType.value == 1">
        <i aria-hidden="true" class="icon-search icon-Search" attr.automationId='Icn_{{automation}}_search'  [appDisableDoubleClick]="throttletime"
           (throttleClick)="searchArAccount(AccountSearchForm.controls['acctSearch'].value)"></i>
      </div>
    </div>
  </div>
  <div *ngIf="isStay" class="acct-search-cont">
    <label class="ag_display--block ag_form--label">{{captions?.ARAcctNameNumber}}</label>
    <div class="acct-search">
      <mat-form-field class="rc-searchbox" [floatLabel]="floatLabelNever">
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let acct of selectedAccountList" [selectable]="selectable&&!isView" [removable]="removable&&!isView"
                    (removed)="remove(acct)">
            {{acct}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input *ngIf="selectedAccountList.length == 0" [placeholder]="searchBoxPlaceHolder"
                 [disabled]="selectedAccountList.length != 0 || isView" #acctSearch formControlName="acctSearch"
                 [matAutocomplete]="auto" [matChipInputFor]="chipList" #trigger="matAutocompleteTrigger"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="add($event)" attr.automationId='Txt_{{automation}}_acctSearch' />
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectAccount($event)">
          <mat-option *ngFor="let acct of ARAccountList" [value]="acct.accountName">
            {{ acct.accountName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <ng-container *ngIf="selectedArAccount != null && !lookupInput?.isCustomCaptureSection">
    <div *ngIf="!isStay" class="voucher-cont">
      <div class="acct-detail-row">
        <div class="static-lbl">{{captions.ARAcctName}}:</div>
        <div class="selected-value">{{selectedArAccount.accountName}}</div>
      </div>
      <div class="acct-detail-row">
        <div class="static-lbl">{{captions.ARAcctNumber}}:</div>
        <div class="selected-value">{{selectedArAccount.accountNumber}}</div>
      </div>
    </div>
    <div *ngIf="isStay" class="voucher-cont">
      <div class="acct-detail-row">
        <div class="static-lbl">{{captions?.ARAccount}}:</div>
        <div>{{selectedArAccount.accountName}}</div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedArAccount != null && lookupInput?.isCustomCaptureSection">
    <ng-content>
    </ng-content>
  </ng-container>
</form>
