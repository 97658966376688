<!--category filter End-->
<form class="p-0" [formGroup]="categoryForm">
    <section autocomplete="off">
        <div class='d-flex flex-row flex-wrap' *ngIf="categoryForm['controls'].categoriesData.value.length > 0"
            formArrayName="categoriesData">
            <mat-form-field class="width_90 adv-category category" [floatLabel]="floatLabel"
                *ngFor="let catDD of categoryForm.get('categoriesData')['controls']; let i = index;"
                [formGroupName]="i">
                <mat-select [placeholder]="inventoryCaptions[catDD.controls.catName.value]"
                    formControlName="selectedCat" multiple>
                    <mat-option class="first-option-class">
                        <mat-checkbox class="mat-option reports-multiselect" (click)="$event.stopPropagation();"
                        [disableRipple]="true" (change)="toggleSelection($event,i,'All')"
                        [checked]="catDD.controls.selectedCat.value.length  == catDD.controls.catArr.value.length">
                        <span
                            [ngClass]="{'text-color':catDD.controls.selectedCat.value.length  == catDD.controls.catArr.value.length}">{{defaultData.description}}</span>
                    </mat-checkbox>
                    </mat-option>
                    <mat-option *ngFor="let category of catDD.controls.catArr.value" [value]="category"
                        (click)="toggleSelection($event,i,'other')">{{category.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            
        </div>
    </section>
</form>
<!--category filter End-->