<section class="creditcard-info creditCard-info-wrapper newVersionWrapper">
  <div class="creditcard-details">
    <ng-scrollbar >
      <form [formGroup]="creditcardInfo" autocomplete="off" [ngClass]="isViewOnly ? 'view-only' : ''">
        <div class="mb-3">
          <label class="form-label-normal">{{captions.ActivateInterface}}</label>
          <div>
            <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableActivateInterface'"
              formControlName="enableActivateInterface" (changeToggleEvent)="onActiveChange()">
            </app-retail-toggle-switch>
          </div>
        </div>
        <!-- <div class="mb-3">
          <label class="form-label-normal">{{captions.EnableIdTechForNonIntegratedCreditCard}}</label>
          <div>
            <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableActivateInterface'"
              formControlName="enableIdTech" (changeToggleEvent)="onActiveChange()">
            </app-retail-toggle-switch>
          </div>
        </div> -->
        <h5 class="LWB16 mb-3 ">{{captions.GatewayValues}}</h5>
        <div formGroupName="GatewayConfigPairs">
          <div
            *ngFor="let item of nonIntegratedCreditCardConfigs?.controls; let i = index;let last=last;let first= first"
            formArrayName="NonIntegratedCreditCardConfigs" class="w-100">
            <div class="align-items-center ag_display--flex">
              <mat-form-field class="w-225px" [formGroupName]="i" [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_creditCard_gatewayKey'" matInput name="key"
                  placeholder="{{captions.Key}}" (keypress)="keyPress($event)" [maxlength]="200" formControlName="Key">
              </mat-form-field>
              <mat-form-field class=" gateway-values ml-2" [formGroupName]="i" [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_creditCard_gatewayValue'" matInput name="value"
                  placeholder="{{captions.Value}}" (keypress)="keyPress($event)" [maxlength]="200"
                  [type]="isSensitiveField(item.get('Key').value) ? 'password' : 'text'"
                  formControlName="Value">
              </mat-form-field>
              <span class="icon-Minus" (click)="removeGateayArray(i)" *ngIf="!(first && last)"></span>
              <span class="icon-Plus" (click)="addGatewayArray($event)" *ngIf="last"></span>
            </div>
          </div>
        </div>
        <input [attr.automationId]="'Txt_creditCard_submit'" type="submit" [disabled]="!isUpdateEnabled"
          class="hidden-submit" tabindex="-1" (click)="save()" />
      </form>
      <div *ngIf="showSurchargeConfig" [ngClass]="isViewOnly ? 'view-only' : ''">
        <h5 class="mb-3 config-header">{{surchargeConfigCaption?.lbl_config}}</h5>
        <!--Surcharge Config Grid-->
        <div class="surcharge-grid-container">
          <div class="grid-header-container">
            <div>
              <mat-checkbox [attr.automationId]="'Chk_creditCard_selectAll'" [matTooltip]="overAllCBTooltipMsg"
                class="base-check-box pr-1" [(ngModel)]="selectAll" labelPosition="before"
                (change)="enableAllSurcharge($event)">
              </mat-checkbox>
            </div>
            <div>{{surchargeConfigCaption?.lbl_CardAssociation}}</div>
            <div>{{surchargeConfigCaption?.lbl_PercOrAmount}}</div>
            <div>{{localization.captions.shop.Value}}</div>
          </div>
          <div class="grid-rows-container">
            <div class="grid-rows mb-2" *ngFor="let config of surchargeConfig">
              <div>
                <mat-checkbox [attr.automationId]="'Txt_creditCard_changeStatus'" class="base-check-box pr-1"
                  labelPosition="before" [(ngModel)]="config.isActive"
                  (change)="changeStatus($event,config)"></mat-checkbox>
              </div>
              <div>
                <div>
                  <mat-form-field [floatLabel]="floatLabel">
                    <input [attr.automationId]="'Txt_creditCard_issuerName'" matInput
                      [disabled]="!config.isActive || config.isDefault " required name="surChargeIssuerName"
                      (input)="updateIssuerType($event, config)" [(ngModel)]="config.issuerName" type='text'
                      placeholder="" />
                    <span class="span-mat-error" *ngIf="config.isValid && config.isActive && config.issuerName.length == 0">
                      {{captions.requiredFields}}</span>
                    <span class="span-mat-error" *ngIf="!config.isValid">
                      {{surchargeConfigCaption.err_duplicate_issuer_type}}</span>
                  </mat-form-field>
                </div>
              </div>
              <div class="customvalue" [class.disabled]="!config.isActive">
                <div class="toggleButton">
                  <button [attr.automationId]="'Btn_creditCard_chooseType'" type="button" mat-raised-button class="LW14"
                    [ngClass]="[config.isPercentage ?'body-bgcolor whitecolor body-bordercolor'
                                    :'body-bordercolor LW14 ','percentButton']"
                    (click)="chooseType(true,config)">%</button>
                  <button [attr.automationId]="'Btn_creditCard_currencySymbol'" type="button" mat-raised-button
                    class="LW14" [ngClass]="[!config.isPercentage?'body-bgcolor whitecolor body-bordercolor'
                                    :'body-bordercolor LW14','ml-0','AmtButton']"
                    (click)="chooseType(false,config)">{{localization.currencySymbol}}</button>
                </div>
              </div>
              <div>
                <mat-form-field [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_creditCard_surChargeValue'" matInput RetailCurrencyFormatter
                    [disabled]="!config.isActive" [postDecimalLength]="config.decimalMaxLength" required
                    [preDecimalLength]="config.maxLength" name="surChargeValue"
                    [max]="getMaxSurchargeValue(config.isPercentage)" (input)="updateSCVal($event)"
                    [(ngModel)]="config.value" type='text' placeholder={{config.placeHolder}} />
                  <span class="span-mat-error" *ngIf="config.value == 0 && config.isActive">
                    {{captions.requiredFields}}</span>
                </mat-form-field>
              </div>
              <div>
                <div>
                  <span class="icon-Plus ag_pt--8 ag_ml--6 ag_cursor--pointer" (click)="addArray(config)"
                    *ngIf="config.lastIndex"></span>
                  <span class="icon-Minus ag_pt--8 ag_ml--6 ag_cursor--pointer" (click)="removeArray(config)"
                    *ngIf="!config.isDefault"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
  <div class="prop-actions">
    <div>
      <button [attr.automationId]="'Btn_creditCard_cancel'"
        [ngClass]="isUpdateEnabled ? 'spa-primary-default-save' : 'spa-primary-disabled'" [disabled]="!isUpdateEnabled"
        class="LW14 save" mat-button (click)="save()">{{captions.save}}</button>
      <a class="LW14 text-default-color" (click)="cancel()">{{captions.cancel}}</a>
    </div>
  </div>
</section>