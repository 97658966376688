<div class="payee-details d-flex align-items-center">
    <dynamic-form [config]="payeeInfoConfig" #payerForm></dynamic-form>
    <i class="icon-card-list pl-3 show-card-info cursor-pointer" (click)="loadOptionsForPayee()" [ngClass]="{'card-disabled' : !partyService.PayeeId}"  [ngbPopover]="cardInfo" placement="auto" container="body" [autoClose]="'outside'"  #cardInfoPopOver="ngbPopover" popoverClass="payee-card-popover"></i>
  </div>
  <ng-template #cardInfo>
    <table class="cardinfo-table">
        <thead class="cardinfo-header seat-grid-content">
            <tr>                
                <th class="pl-2" *ngFor="let header of cardHeader">{{header | translate}}</th>                
            </tr>
        </thead>
        <tbody class="cardinfo-body seat-header-three" >
            <ng-container *ngIf="availableCards && availableCards.length">
            <tr *ngFor="let cardInfo of availableCards" class="cardInfo-row">
                <td class="text-center pl-2">
                    <mat-radio-group [(ngModel)]="selectedCard" name="payee-selected-card"  (ngModelChange)="updateCard(cardInfo)">
                    <mat-radio-button class="radio-button" [value]="cardInfo.CreditCardId" >    
                      </mat-radio-button>
                      </mat-radio-group>
                </td>
                <td class="d-flex align-items-center card-info pl-1" >
                    <app-retail-card-type *ngIf="cardInfo?.CardIssuer" [type]="cardInfo.CardIssuer" class="pointer pt-1 card-issuer"></app-retail-card-type>
                    <span class="pl-2">{{cardInfo.MaskedCardNumber}}</span>
                    <span  *ngIf="cardInfo?.IsBooking" class="icon-warning-icon pl-2 warning-icon seat-header-bold" [ngbPopover]="'cardForReservation' | translate"  ></span>
                </td>               
                <td class="text-center pl-1" >
                    {{cardInfo.ExpiryDate | formatExpiryDate}} 
                </td>
            </tr>
        </ng-container>
        <tr *ngIf="availableCards && !availableCards.length" class="p-3 text-center seat-header-four">
            <td colspan="3" class="text-center p-3">{{'NoCardsAvailable' | translate }}</td>
        </tr>
        </tbody>
       
    </table>
    </ng-template>