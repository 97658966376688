import {
  CdkVirtualScrollViewport,
  FixedSizeVirtualScrollStrategy,
} from '@angular/cdk/scrolling';
/**
 * Data source
 */
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import {  filter, takeUntil, throttleTime } from 'rxjs/operators';
import { Injectable } from "@angular/core";

//const PAGESIZE = 50;
const ROW_HEIGHT = 38;
// const SCROLL_THROTTLE_TIME = 60;

export class GridTableDataSource extends DataSource<any> {
  pageSize = 200;
  findIndex(arg0: (d: any) => boolean) {
    throw new Error("Method not implemented.");
  }
  private _data: any[];

  get data(): any[] {
    return this._data && this._data.slice();
  }

  set data(data: any[]) {
    this._data = data;
    this.viewport.setTotalContentSize(this.itemSize * data.length);
    this.visibleData.next(this._data.slice(0, this.pageSize));
  }

  offset = 0;
  offsetChange = new BehaviorSubject(0);
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(initialData: any[], public viewport: CdkVirtualScrollViewport, private itemSize: number, public onDrag: BehaviorSubject<boolean>,pageSize: number) {
    super();
    this.pageSize = pageSize;
    this._data = initialData;
    this.viewport.elementScrolled()
      .pipe(
        filter(c => c.currentTarget != null),
        // throttleTime(SCROLL_THROTTLE_TIME),
        takeUntil(this.destroyed$)
      ).subscribe((ev: any) => {
        if(!this.onDrag.value){
          const start = Math.floor(ev.currentTarget.scrollTop / itemSize);
          const prevExtraData = start > 5 ? 5 : 0;
          const slicedData = this._data && this._data.slice(start - prevExtraData, start + (this.pageSize - prevExtraData));
          this.offset = itemSize * (start - prevExtraData);
          this.viewport.setRenderedContentOffset(this.offset);
          this.offsetChange.next(this.offset)
          this.visibleData.next(slicedData);
        }
      });
  }

  private readonly visibleData: BehaviorSubject<any[]> = new BehaviorSubject([]);

  connect(collectionViewer: import('@angular/cdk/collections').CollectionViewer): Observable<any[] | ReadonlyArray<any>> {
    return this.visibleData;
  }

  disconnect(collectionViewer: import('@angular/cdk/collections').CollectionViewer): void {
    /**
 * disconnect
 */
  }
}

/**
 * Virtual Scroll Strategy
 */
@Injectable()
export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(ROW_HEIGHT, 1000, 2000);
  }

  attach(viewport: CdkVirtualScrollViewport): void {
    this.onDataLengthChanged();
  }
}
