import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-common-filters',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss']
})
export class CommonFilterComponent implements OnInit {
  @Input() menuList;
  @Input() selectedFilters;
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  selectedSubMenu: any[] = [];
  constructor() { }

  ngOnInit(): void {
    this.selectedSubMenu = [];
    this.selectedSubMenu = this.selectedFilters || [];
    if(this.selectedSubMenu?.length){
      this.menuList?.forEach(menu =>{
        menu.subMenu?.map(sub => sub.selected = this.selectedFilters?.find(item => item.id === sub.id)?.selected || false);
      })
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(!changes.selectedFilters.currentValue){
      this.selectedSubMenu = [];
      this.menuList?.forEach(menu =>{
        menu.subMenu?.map(sub => sub.selected = false);
      })
      this.selectionChange.emit(this.selectedSubMenu);
    }
  }
  filterSelection(event, selection) {
    if (event.checked) {
      this.selectedSubMenu.push(selection);
    } else {
      this.selectedSubMenu = this.selectedSubMenu.filter(item => item.id !== selection.id);
    }
    this.selectionChange.emit(this.selectedSubMenu);
  }
}
