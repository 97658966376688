<section class="creditcard-info creditCard-info-wrapper newVersionWrapper">
  <div class="creditcard-details">
    <ng-scrollbar >
      <form [formGroup]="creditcardInfo" autocomplete="off" [ngClass]="isViewOnly ? 'view-only' : ''">
        <div class="mb-3">
          <label class="form-label-normal">{{captions.ActivateInterface}}</label>
          <div>
            <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableActivateInterface'"
              formControlName="enableActivateInterface" (changeToggleEvent)="onActiveChange()">
            </app-retail-toggle-switch>
          </div>
        </div>
        <div class="rquest-url">
          <mat-form-field class="" [floatLabel]="floatLabel">
            <input [attr.automationId]="'Txt_creditCard_payAgentUrl'" type="text" matInput
              placeholder="{{captions.rGuestPayAgentURL}}" name="rGuestPayAgentURL" formControlName="rGuestPayAgentURL">
          </mat-form-field>
        </div>
        <div class="mb-2 rquest-payment-gateway">
          <mat-form-field class="" [floatLabel]="floatLabel">
            <input [attr.automationId]="'Txt_creditCard_payGatewayId'" type="text" matInput
              placeholder="{{captions.rGuestPayGatewayID}}" name="rGuestPayGatewayID"
              formControlName="rGuestPayGatewayID">
            <mat-error *ngIf="creditcardInfo.controls['rGuestPayGatewayID'].invalid">
              {{ getErrorMessage('rGuestPayGatewayID', 0) }}
            </mat-error>
          </mat-form-field>
        </div>
        <h5 class="LWB16 mb-3 ">{{captions.GatewayValues}}</h5>
        <div
          *ngFor="let item of creditcardInfo.get('GatewayPairs')['controls']; let i = index;let last=last;let first= first"
          formArrayName="GatewayPairs" class="w-100">
          <div class="align-items-center ag_display--flex">
            <mat-form-field class="w-225px" [formGroupName]="i" [floatLabel]="floatLabel">
              <input [attr.automationId]="'Txt_creditCard_gatewayKey'" matInput name="key"
                placeholder="{{captions.Key}}" (keypress)="keyPress($event)" [maxlength]="200"
                formControlName="gateway_key">
            </mat-form-field>
            <mat-form-field class=" gateway-values ml-2" [formGroupName]="i" [floatLabel]="floatLabel">
              <input [attr.automationId]="'Txt_creditCard_gatewayValue'" matInput name="value"
                placeholder="{{captions.Value}}" (keypress)="keyPress($event)" [maxlength]="200"
                formControlName="gateway_value">
            </mat-form-field>
            <span class="icon-Minus" (click)="removeGatewayPairs(i)" *ngIf="!(first && last)"></span>
            <span class="icon-Plus" (click)="addGatewayPairs(i,'','')" *ngIf="last"></span>
          </div>
          <mat-error *ngIf="creditcardInfo.controls['GatewayPairs']['controls'][i].invalid">
            {{ getErrorMessage('GatewayPairs', i) }}
          </mat-error>
        </div>
        <h5 class="LWB16 mb-3">{{captions.lbl_creditCardOptions}}</h5>
        <div class="form-wrapper">
          <ng-container *ngIf='!hideInAccounting'>
            <div class="mt-1 ag_w--100 mb-3 w-225px in-block">
              <label class="form-label-normal">{{captions.PartialPaymentAllowed}}</label>
              <div>
                <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enablePartialPayment'"
                  formControlName="enablePartialPayment" (changeToggleEvent)="onActiveChange()">
                </app-retail-toggle-switch>
              </div>
            </div>
            <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
              <label class="form-label-normal">{{captions.ActivateAVS}}</label>
              <div>
                <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableActivateAvs'"
                  formControlName="enableActivateAVS" (changeToggleEvent)="onActiveChange()">
                </app-retail-toggle-switch>
              </div>
            </div>
            <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
              <label class="form-label-normal">{{captions.RestrictCreditByToken}}</label>
              <div>
                <app-retail-toggle-switch formControlName="restrictCreditToGuest"
                  (changeToggleEvent)="onActiveChange()">
                </app-retail-toggle-switch>
              </div>
            </div>
            <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
              <label class="form-label-normal">{{captions.RestrictPartialRefund}}</label>
              <div>
                <app-retail-toggle-switch formControlName="restrictPartialRefund"
                  (changeToggleEvent)="onActiveChange()">
                </app-retail-toggle-switch>
              </div>
            </div>
            <div *ngIf='showOtherCreditCardToggles'>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
                <label class="form-label-normal">{{captions.lbl_creditcardTracking}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableCcTracking'"
                    formControlName="enableCCTracking"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
                <label class="form-label-normal">{{captions.lbl_requestcardSwipe}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableRequestCardSwipe'"
                    formControlName="enableRequestCardSwipe"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
                <label class="form-label-normal">{{captions.lbl_printCCAuthorization}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableCCAuthorization'"
                    formControlName="enablePrintCCAuthorization"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
                <label class="form-label-normal">{{captions.lbl_deferCCDeposit}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_autoDeferAdvanceDeposit'"
                    formControlName="automaticallyDeferCCAdvanceDepositPost">
                  </app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
                <label class="form-label-normal">{{captions.lbl_requestSwipeAtCheckin}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_requestSwiprAtCheckIn'"
                    formControlName="alwaysRequestSwipeAtCheckIn"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
                <label class="form-label-normal">{{captions.lbl_houseNumber}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableHouseNo'"
                    formControlName="enableHouseNumber"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
                <label class="form-label-normal">{{captions.lbl_chipPinTest}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableChipPinTest'"
                    formControlName="enableChipPinTest"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
                <label class="form-label-normal">{{captions.lbl_chipPin}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableChipAndPin'"
                    formControlName="enableChipAndPin"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
                <label class="form-label-normal">{{captions.lbl_skipDecreaseAuth}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_skipDecreaseAuth'"
                    formControlName="skipDecreaseAuth"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
                <label class="form-label-normal">{{captions.lbl_incrementalAuthWhenAuthSupported}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_increementAuthNotSupported'"
                    formControlName="incrementalAuthWhenNotSupported"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
                <label class="form-label-normal">{{captions.lbl_enableCCAuthLimit}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableCCAuthLimit'"
                    formControlName="enableCCAuthLimit"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block" *ngIf="false">
                <label class="form-label-normal">{{captions.lbl_VoidAuthduringCheckout}} </label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableCCAuthLimit'"
                    formControlName="voidAuthAfterCO"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
                <label class="form-label-normal">{{captions.lbl_showCreditCardExpDate}} </label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_showCreditCardExpDate'"
                    formControlName="showCreditCardExpDate"></app-retail-toggle-switch>
                </div>
              </div>
            </div>
            <div *ngIf="functionalities.ShowCreditCardDefaults">
              <h5 class="LWB16 mb-3">{{captions.lbl_creditCardDefaults}}</h5>
              <div class="d-flex">
                <div class="w-50 ag-pr-4">
                  <app-ag-incremental [inputs]="noOfSVCReceiptsInput"></app-ag-incremental>
                </div>
                <div class="w-50 ag-pr-4">
                  <app-ag-incremental [inputs]="batchWaitTimeInput"></app-ag-incremental>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-50 ag-pr-4">
                  <app-ag-incremental [inputs]="pbfeNetbDelayInput"></app-ag-incremental>
                </div>
                <div class="w-50 ag-pr-4">
                  <app-ag-currency [inputs]="ccOverAuthThresholdInput"> </app-ag-currency>
                </div>
              </div>
              <h5 class="LWB16 mb-3">{{captions.lbl_compliancy}}</h5>

              <div class="mt-1 mb-3 w-50 in-block">
                <label class="form-label-normal">{{captions.lbl_activatePABP}}</label>
                <div>
                  <app-retail-toggle-switch formControlName="activateAllPABPRecommendation"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-50 ag-pr-4">
                  <app-ag-incremental [inputs]="purgeStayCCDaysInput"
                    [tooltip]="captions.lbl_purgeStayCCAfterCO"></app-ag-incremental>
                </div>
                <div class="w-50 ag-pr-4">
                  <app-ag-incremental [inputs]="purgeArchivedCCDaysInput"></app-ag-incremental>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-50 ag-pr-4">
                  <app-ag-incremental [inputs]="purgeGuestCCDaysInput"></app-ag-incremental>
                </div>
                <div class="w-50 ag-pr-4">
                  <app-ag-incremental [inputs]="purgeBookingCCDaysInput"></app-ag-incremental>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-50 ag-pr-4">
                  <app-ag-incremental [inputs]="purgePostingCCDaysInput"></app-ag-incremental>
                </div>
              </div>
              <div class="mt-1 mb-3 w-50 in-block">
                <label class="form-label-normal">{{captions.lbl_skipCCFauxPurge}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_skipNoPurgeDuringZOut'"
                    formControlName="skipCCFauxNoPurgeDuringZOut"></app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 mb-3">
                <label class="form-label-normal">{{captions.PartialPaymentAllowed}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_setPartialPayment'"
                    formControlName="SET_PARTIAL_PAYMENT" (changeToggleEvent)="onActiveChange()">
                  </app-retail-toggle-switch>
                </div>
              </div>
              <div class="mt-1 mb-3">
                <label class="form-label-normal">{{captions.ActivateAVS}}</label>
                <div>
                  <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_setActivateAvs'"
                    formControlName="SET_ACTIVATEAVS" (changeToggleEvent)="onActiveChange()">
                  </app-retail-toggle-switch>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="mt-1 mb-3" *ngIf="displayCardInputMethod">
            <label class="form-label-normal">{{captions.cardInputMethods}}</label>
            <div class="card-input-radio-class">
              <mat-radio-group [attr.automationId]="'Txt_creditCard_cardInput'" formControlName="cardInputMethod"
                (change)="onCardInputMethodsChange($event)">
                <mat-radio-button *ngFor="let cardInputMethod of cardInputMethods" [value]="cardInputMethod.value">
                  {{cardInputMethod.viewValue}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="mt-1 mb-3" *ngIf="IsBothCardInputsSelected">
              <label class="form-label-normal">{{captions.preferredEntryMode}}</label>
              <mat-form-field [floatLabel]="floatLabel">
                <mat-select [attr.automationId]="'Txt_creditCard_preferredCardEntry'"
                  formControlName="preferredCardEntryMode">
                  <mat-option *ngFor="let inputMethod of preferredCardInputs" [value]="inputMethod.value"
                    (click)="selectPreferredInput(inputMethod)">
                    {{ inputMethod.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <input [attr.automationId]="'Txt_creditCard_submit'" type="submit" [disabled]="!isUpdateEnabled"
          class="hidden-submit" tabindex="-1" (click)="save()" />
      </form>
      <ng-container *ngIf='!hideInAccounting'>
        <div *ngIf="showSurchargeConfig">
          <h5 class="mb-3 config-header">{{surchargeConfigCaption?.lbl_config}}</h5>
          <!--Surcharge Config Grid-->
          <div class="surcharge-grid-container">
            <div class="grid-header-container">
              <div>
                <mat-checkbox [attr.automationId]="'Chk_creditCard_selectAll'" [matTooltip]="overAllCBTooltipMsg"
                  class="base-check-box pr-1" [(ngModel)]="selectAll" labelPosition="before"
                  (change)="enableAllSurcharge($event)">
                </mat-checkbox>
              </div>
              <div>{{surchargeConfigCaption?.lbl_CardAssociation}}</div>
              <div>{{surchargeConfigCaption?.lbl_PercOrAmount}}</div>
              <div>{{localization.captions.shop.Value}}</div>
            </div>
            <div class="grid-rows-container">
              <div class="grid-rows mb-2" *ngFor="let config of surchargeConfig">
                <div>
                  <mat-checkbox [attr.automationId]="'Txt_creditCard_changeStatus'" class="base-check-box pr-1"
                    labelPosition="before" [(ngModel)]="config.isActive"
                    (change)="changeStatus($event,config)"></mat-checkbox>
                </div>
                <div>
                  <div>
                    <mat-form-field [floatLabel]="floatLabel">
                      <input [attr.automationId]="'Txt_creditCard_issuerName'" matInput
                        [disabled]="!config.isActive || config.isDefault " required name="surChargeIssuerName"
                        (input)="updateIssuerType($event, config)" [(ngModel)]="config.issuerName" type='text'
                        placeholder="" />
                      <span class="span-mat-error" *ngIf="config.isValid && config.isActive && config.issuerName.length == 0">
                        {{captions.requiredFields}}</span>
                      <span class="span-mat-error" *ngIf="!config.isValid">
                        {{surchargeConfigCaption.err_duplicate_issuer_type}}</span>
                    </mat-form-field>
                  </div>
                </div>
                <div class="customvalue" [class.disabled]="!config.isActive">
                  <div class="toggleButton">
                    <button [attr.automationId]="'Btn_creditCard_chooseType'" type="button" mat-raised-button
                      class="LW14" [ngClass]="[config.isPercentage ?'body-bgcolor whitecolor body-bordercolor'
                                  :'body-bordercolor LW14 ','percentButton']"
                      (click)="chooseType(true,config)">%</button>
                    <button [attr.automationId]="'Btn_creditCard_currencySymbol'" type="button" mat-raised-button
                      class="LW14" [ngClass]="[!config.isPercentage?'body-bgcolor whitecolor body-bordercolor'
                                  :'body-bordercolor LW14','ml-0','AmtButton']"
                      (click)="chooseType(false,config)">{{localization.currencySymbol}}</button>
                  </div>
                </div>
                <div>
                  <mat-form-field [floatLabel]="floatLabel">
                    <input [attr.automationId]="'Txt_creditCard_surChargeValue'" matInput RetailCurrencyFormatter
                      [disabled]="!config.isActive" [postDecimalLength]="config.decimalMaxLength" required
                      [preDecimalLength]="config.maxLength" name="surChargeValue"
                      [max]="getMaxSurchargeValue(config.isPercentage)" (input)="updateSCVal($event)"
                      [(ngModel)]="config.value" type='text' placeholder={{config.placeHolder}} />
                    <span class="span-mat-error" *ngIf="config.value == 0 && config.isActive">
                      {{captions.requiredFields}}</span>
                  </mat-form-field>
                </div>
                <div>
                  <div>
                    <span class="icon-Plus ag_pt--8 ag_ml--6 ag_cursor--pointer" (click)="addArray(config)"
                      *ngIf="config.lastIndex"></span>
                    <span class="icon-Minus ag_pt--8 ag_ml--6 ag_cursor--pointer" (click)="removeArray(config)"
                      *ngIf="!config.isDefault"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="allowTipConfig">
          <h5 class="mb-3 config-header">{{tipConfigCaption?.lbl_config}}</h5>
          <form [formGroup]="tipConfigForm">
            <div class="mt-1 ag_w--100  mb-3 w-225px in-block">
              <label class="form-label-normal">{{tipConfigCaption.AllowTipConfig}}</label>
              <div>
                <app-retail-toggle-switch [attr.automationId]="'Txt_creditCard_enableTip'" formControlName="enableTip"
                  (changeToggleEvent)="onActiveChange()">
                </app-retail-toggle-switch>
              </div>
            </div>
            <ng-container *ngIf="enableTip">
              <div class="customvalue" [class.disabled]="!enableTip">
                <div class="tip-n-label">{{tipConfigCaption.TipIn}}</div>
                <div class="toggleButton">
                  <button [attr.automationId]="'Btn_creditCard_chooseType'" type="button" mat-raised-button class="LW14" [ngClass]="[tipIsPercentage ?'body-bgcolor whitecolor body-bordercolor'
                                                                :'body-bordercolor LW14 ','percentButton']"
                    (click)="chooseTipType(true)">{{localization.captions.shop.Percentage}}</button>
                  <button [attr.automationId]="'Btn_creditCard_currencySymbol'" type="button" mat-raised-button class="LW14"
                    [ngClass]="[!tipIsPercentage?'body-bgcolor whitecolor body-bordercolor'
                                                                :'body-bordercolor LW14','ml-0','AmtButton']"
                    (click)="chooseTipType(false)">{{localization.captions.shop.Amount}} {{localization.currencySymbol}}</button>
                </div>
              </div>
              <div class="tip-preset-values">
                <label class="form-label-primary mb-3 d-block"><b>{{tipConfigCaption.TipPresetValues}}</b></label>
                <div
                  *ngFor="let item of tipConfigForm.get('tipPresetValues')['controls']; let i = index;let last=last;let first= first"
                  formArrayName="tipPresetValues" class="w-100 d-flex">
                  <div>
                    <mat-form-field class="ml-2" [formGroupName]="i" formControlName="presetValue" [floatLabel]="floatLabel">
                      <input [attr.automationId]="'Txt_tipPreset_value'" matInput [isFromTaxPercentage]="tipIsPercentage" required
                        RetailCurrencyFormatter [postDecimalLengthForTaxes]="decimalMaxLength" [preDecimalLength]="presetValMaxLength"
                        name="value" formControlName="presetValue" type='text' [placeholder]="tipValuePlaceHolder"
                        (keypress)="keyPress($event)"/>                      
                    </mat-form-field>
                    <mat-error *ngIf="tipConfigForm.controls['tipPresetValues']['controls'][i]['controls']['presetValue'].invalid">
                      {{ tipConfigCaption.InvalidValue }}
                    </mat-error>
                  </div>
                  <div class="icon-Minus" (click)="removeTipPresetValue(i)" *ngIf="!(first && last)"></div>
                  <div class="icon-Plus" (click)="addTipPresetValue()" *ngIf="last && i < 3"></div>
                </div>
              </div>
            </ng-container>
          </form>
        </div>
      </ng-container>
    </ng-scrollbar>
  </div>
  <div class="prop-actions">
    <div>
      <button [attr.automationId]="'Btn_creditCard_cancel'"
        [ngClass]="isUpdateEnabled ? 'spa-primary-default-save' : 'spa-primary-disabled'" [disabled]="!isUpdateEnabled"
        class="LW14 save" mat-button (click)="save()">{{captions.save}}</button>
      <a class="LW14 text-default-color" (click)="cancel()">{{captions.cancel}}</a>
    </div>
  </div>
</section>
