<div class="headercontainer" [ngClass]="{'vcart-container' : cs.isIframeEnabled }">
<div class="headercontainer">
  <div #daynoteView cdkDrag [showMoreTextHandler]="dayNote" class="view-note seat-body-text" *ngIf="showDayNote && dayNote">
    <span class="notes-text" >{{dayNote}}</span>
    <span class="icon-close close-note" (click)="closePopover()"></span>
<span (click)="expandDayNote()" *ngIf="showMoreOption" class="show-more">{{showMoreText}}...</span>
  </div>
  <div class="headercontainer__menu-section">

    <!-- Displaying the menu toggle button and logo -->
    <div class="headercontainer__logo-container" [hidden]="cs.isIframeEnabled">
      <div class="headercontainer__slider-menu">
        <a class="icon-Menu headercontainer__menu_icon " href="javascript:void(0)" (click)="toggleSideMenu()"></a>
      </div>
      <div class="headercontainer__seat-logo" *ngIf="logoUrl != null">
        <img src="{{logoUrl}}" alt="logo" class="logo_image">
      </div>
    </div>

    <!-- Displaying the heading and time -->
    <div class="headercontainer__display-section" >
  <div class="headercontainer__page-title page-title" *ngIf="!_as.showHelpScreen">
           <!--[ngClass]="{'header_name_withoutdatepicker': _as.selectedMenu=='guestBookMenuText'}">-->
        <!-- {{_as.selectedMenuId | headerName | translate}} - &nbsp; -->
        <dynamic-form [config]="restaurantNameConfig" #restaurantNameForm>
        </dynamic-form>       
      </div>
      <div class="headercontainer__page-title page-title" *ngIf="_as.showHelpScreen">
        {{_as.menuHeader}}
      </div>
      <div class="headercontainer__selection-panel">

        <!-- <input matInput [matDatepicker]="picker" #picker (click)="picker.open()" placeholder="{{'chooseDate' | translate}}" #start_time="ngModel" [(ngModel)]="date" class="headercontainer__display-selection input_bg"
                     (dateChange)="changeDate($event)">
              <mat-datepicker #picker class="icon-note headercontainer__datepicker-icon"></mat-datepicker> -->
        <!-- <mat-form-field class="input_bg headercontainer__display-selection">
                <mat-select id="cmbseatingarea" placeholder="{{'chooseSeatingArea' | translate}}">
                  <mat-option *ngFor="let area of seatingAreas" [value]="area.Id" (click)="setSelectedArea(area,$event)">
                    {{area.Name}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
        <!-- <dynamic-form [config]="datePickerconfig" #form="dynamicForm">
                </dynamic-form> -->

        <div class="headercontainer__date-section" [hidden]="this.datePickerconfig[0].disabled" (click)="showCalendarView()">
          <dynamic-form [config]="datePickerconfig" #form="dynamicForm">
          </dynamic-form>
          <!-- <button *ngIf="!hideTodayButton" class="form-date-picker btn-now" [disabled]="config.disabled" (click)="navigate(null,'now')">Today</button> -->
        </div>
        <div  *ngIf="showTodayButton" class="headercontainer__today-button">
          <activities-app-button [buttontype]="buttonToday" (valueChange)="navigateToToday()"></activities-app-button>
        </div>
        <div class="seating-area" [hidden]="restaurantNameConfig[0].disabled || this.datePickerconfig[0].disabled || (this._settings.PropertySetting[0]?.SlottingType == SlottingType.NonDining)">
          <dynamic-form [config]="dropDownconfig" #form="dynamicForm">
          </dynamic-form>
        </div>

        <div class="headercontainer__notes-section">
          <!--  [ngClass]="{'headercontainer__notes-customsection': this.datePickerconfig[0].disabled}" -->
          <a class="icon-note headercontainer__notes-view"  [ngbPopover]="dayNotes" placement="bottom"
          [autoClose]="'outside'" #dayNotePopOver="ngbPopover">
          </a>
          <span class="headercontainer__restaurant-notes" *ngIf="highlightNote"></span>

          <ng-template #dayNotes>
            <app-day-notes [dayNote]="dayNote" (saveDayNotes)="saveNote($event)" (closePopover)="close()"></app-day-notes>
            <!-- <div class="default headercontainer__dayNotes">
              <h4 class="seat-header-two">{{'dayNote' | translate}}</h4>
              <dynamic-form [config]="dayNoteconfig" #dayNoteForm>
              </dynamic-form>
              <div class="headercontainer__btn-Container">
                <activities-app-button [buttontype]="buttonCancel" (click)="close()"></activities-app-button>
                <activities-app-button [buttontype]="buttonSend" (click)="saveNote()"></activities-app-button>
              </div>
            </div> -->
          </ng-template>
          <!-- <popover-content #dayNotes  placement="bottom"  [closeOnClickOutside]="true">
            <h4 class="seat-header-two">{{'dayNote' | translate}}</h4>
            <dynamic-form [config]="dayNoteconfig" #dayNoteForm>
            </dynamic-form>
            <textarea rows="5" cols="65" [(ngModel)]="dayNote">Hi there</textarea>
            <div class="headercontainer__btn-Container">
              <activities-app-button [buttontype]="buttonCancel" (click)="close()"></activities-app-button>
              <activities-app-button [buttontype]="buttonSend" (click)="saveNote()"></activities-app-button>
            </div>
          </popover-content> -->
        </div>
      </div>
        </div>

        <div class="headercontainer__actions">
          <div class="headercontainer__action-icon timer">
            <span class="headercontainer__timer" *ngIf="IsRefreshChecked" [ngbPopover]="autorefresh" placement="bottom">
              {{TimerInterval}}({{'secsShortText' | translate}})
            </span>
            <span class="headercontainer__timer" [matTooltip]="'Auto refresh turned off' | translate" *ngIf="!IsRefreshChecked" [ngbPopover]="autorefresh" placement="bottom">
              {{'off' | translate}}
            </span>            
            <ng-template #autorefresh>
              <div class="autorefresh-container">
                <mat-checkbox (click)="autoRefreshClick($event)" (change)="OnCheckedChange()" [(ngModel)]="IsRefreshChecked">
                  {{'AutoRefresh' | translate}}</mat-checkbox>
              </div>
            </ng-template>
          </div>
          <div class="headercontainer__action-icon refresh" [matTooltip]="'Refresh'">
            <span class="icon-refresh headercontainer__signalR-icon" (click)="refreshCurrentComponent(true)"></span>
            <span class="headercontainer__signalR-count count-batch" *ngIf="cs.signalRMessageCount" (click)="refreshCurrentComponent(true)">{{cs.signalRMessageCount}}</span>
          </div>
          <div  class="headercontainer__action-icon notification" *ngIf="!isActivityProperty">
            <a class="notification-icon" href="javascript:void(0)" [ngbPopover]="notification" placement="bottom" #p2="ngbPopover" triggers="manual" (click)="triggerAlertPopup(p2);tooltip.toggle()" [autoClose]="_as.propertyNotifications[_as.restaurantId] || 'outside'">
              <span class="icon-Notifications headercontainer__notification-view"
              #tooltip="matTooltip" matTooltipClass="tooltip-class" matTooltip="{{'notifications' | translate}}" ></span>
              <span class="headercontainer__count" *ngIf="_as.propertyNotifications[_as.restaurantId]"></span>
            </a>
          </div>
          <div class="headercontainer__action-icon help" (click)="help()" [matTooltip]="'Help'"><a href="javascript:void(0)" class="icon-question"></a></div>
          <div class="headercontainer__action-icon headercontainer__cart-section" [matTooltip]="'Cart'" [class.mr25]="!hideHelpIcon" *ngIf="isActivityProperty && !cs.isIframeEnabled">
              <i class="icon-shop1 shopping_cart" (click)="showCartItems()" > <span class="count-batch" *ngIf="ps.cartCount > 0 && !cs.signalRMessageCount ">{{ps.cartCount}}</span></i>
            </div>
        </div>
    

    <!-- Displaying the icons for notiifcation search etc -->
    <div class="headercontainer__notifications-and-time" [hidden]="cs.isIframeEnabled">
      <!-- <div class="headercontainer__user-name">
        <span>
          <i class="icon-user rs-pr-5"></i>{{userName}}</span>
      </div> -->
      <div class="headercontainer__notification-items">
      <div class="headercontainer__restaurant-time">
        <span class="headercontainer__date">{{restaurantDateTime | localizedDate:'ddd, D MMM'}}</span>
        <span class="headercontainer__time">{{restaurantDateTime | localizedDate:'LT'}}</span>
      </div>
    </div>
    <!-- <div class="headercontainer__refresh-section" (click)="refreshCurrentComponent(true)">
      <i class="icon-Union-18 headercontainer__add-icon"></i>
    </div> -->
    </div>
    <ng-template #notification>
      <app-notification-view (viewAllEvent)="closePopOver($event)" (actionPerformed)="closePopOver($event)" [alertData]="alertHistory"></app-notification-view>
    </ng-template>
    <!-- <popover-content #notification [closeOnClickOutside]="true" class="default">
      <app-notification-view (viewAllEvent)="closePopOver()"></app-notification-view>
    </popover-content> -->
  </div>
  <!-- <div class="headercontainer__refresh-section" >
    <span class="headercontainer__signalR-count" (click)="refreshCurrentComponent(true)">{{cs.signalRMessageCount}}</span>
    <span class="icon-refresh headercontainer__signalR-icon" (click)="refreshCurrentComponent(true)"></span>

    <div class="headercontainer__autorefresh" *ngIf="showAutoRefresh">
      <span *ngIf="IsRefreshChecked" [ngbPopover]="autorefresh" placement="bottom" #autorefreshPopover="ngbPopover">
          {{TimerInterval}}(s)
      </span>

    </div>
  </div> -->
  <!-- Add reservation Plus Icon -->
  <a class="headercontainer__user-detail" [ngbPopover]="profiledetail" placement="bottom" [autoClose]="'outside'"  [hidden]="cs.isIframeEnabled">
    <div class="headercontainer__user-img">
      <i class="icon-user"></i>
    </div> 
    <span class="headercontainer__user-name" [matTooltip]="_as.currentUserName">{{_as.currentUserName}}</span>
    <i class="icon-down-arrow"></i>
  </a>
  <div class="headercontainer__create-section" (click)="createReservationAndWaitList()">
    <a href="javascript:void(0)" class=""><i class="icon-Union-18 headercontainer__add-icon"></i></a>
  </div>
</div>


<ng-template #profiledetail>
  <div class="detail-container">
    <!-- <div class="profile-data">
      {{_as.currentUserName}}
    </div> -->
    <a class="logout" (click)="switchToPOS()" [hidden]="!showPosSwitchOption">
      <i class="icon icon-swap"></i>
      {{'Switch to POS' | translate}}
    </a>
    <a class="logout" (click)="logout()">
      <i class="icon icon-Mask-Group-8"></i>
      {{'LogoutMenuText' | translate}}
    </a>
  </div>
</ng-template>

