import { Component, EventEmitter, Inject, OnInit, Output, Pipe, PipeTransform, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CacheService } from '@app/core/services/cache.service';
import { COMPONENTINPUT, PopupService } from '@app/popup-module/popup.service';
import { Status, buttonTypes } from '@app/shared/constants/commonenums';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { CreditCardDTO, FullContactDTO } from '@app/shared/models/InputContact';
import { GuestBookService } from '@app/shared/services/guestbook.service';
import { PartyService } from '@app/shared/services/party.service';
import { ButtonValue } from '@app/shared/shared-models';
import { Utilities } from '@app/shared/utilities/utilities';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardInfoComponent implements OnInit {
  addCardBtn: ButtonValue;
  swipeBtn: ButtonValue;
  showNewCard: boolean = false;
  cardConfig: FieldConfig[];
  cvvConfig: FieldConfig[];
  cardExpirationConfig: FieldConfig[];
  FormBuilder
  cardFormGroup: UntypedFormGroup;
  PaymentReferenceID = 0;
  status = Status;
  subscriptions: Subscription = new Subscription();
  retailEnabled: boolean = false;
  
  @Output() setRetailTokenId: EventEmitter<any> = new EventEmitter<any>();
  constructor( @Inject(COMPONENTINPUT) private data: FullContactDTO, public guestBookService: GuestBookService, private ps:PopupService , private partyService: PartyService, private cs: CacheService,private toastrService: ToastrService,private ts:TranslateService ) { }

  ngOnInit(): void {
    this.guestBookService.creditCardDetails =this.data?.CreditCards || []
    this.loadButtonConfig();
    this.retailEnabled = Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)

  }

  ngAfterViewInit(): void {
    let _selectedIndex = this.guestBookService.tabsModal.tabs.findIndex(x => x.tabComponent === CardInfoComponent);
    this.subscriptions.add(this.partyService.tabChange$.subscribe((data) => {
      if (this.ps.tabsActionData?.length && data === _selectedIndex) {
        this.ps.tabsActionData[_selectedIndex].gotoNextTab = true;
        this.ps.tabsActions$.next(this.ps.tabsActionData);
      }
    }));
    if(this.ps.tabsActionData?.length){
      this.ps.tabsActionData[_selectedIndex].gotoNextTab = this.data ? true : false;
    }
  }

  loadButtonConfig() {
    this.addCardBtn = {
      label: 'addCard',
      type: buttonTypes.actionPrimarySmall,
      disabledproperty: false,
      customclass: 'btn btn-primary'
    }
    this.swipeBtn = {
      label: 'swipeCard',
      type: buttonTypes.actionPrimarySmall,
      disabledproperty: false,
      customclass: 'btn btn-primary'
    }
  }
  cardInfo(cardData) {
  if(this.validateCard(cardData[0])){
    let creditCard: CreditCardDTO = {
      ContactId: this.data?.Id || null,
      CreditCardId: null,
      CardHolder: cardData[0].cardHolderName,
      CardIssuer: cardData[0].issuerType, //card
      MaskedCardNumber: cardData[0].cardNumber,
      ExpiryDate: cardData[0].expirationYearMonth,
      Token: cardData[0].token,
      TokenExpirationDate: cardData[0].tokenExpirationDate,
      PaymentTokenId: cardData[1],
      Status: Status.Approved,
      IsDefault: this.guestBookService.creditCardDetails.length ? false : true
    }
    this.PaymentReferenceID = cardData[1]
    this.guestBookService.creditCardDetails.push(creditCard);
    this.updateDefaultCardStatus();
    this.guestBookService?.guestForm?.markAsDirty();
  }
  else {
    this.toastrService.error(this.ts.instant("duplicateCardMsg"), "", { timeOut: 3000, closeButton: true });
  }
}

validateCard(cardData){
  if(this.guestBookService.creditCardDetails.length && cardData){
    let duplicateCardIndex = this.guestBookService.creditCardDetails.findIndex(card => card.MaskedCardNumber == cardData.cardNumber && card.Status != Status.Inactive);
    if(duplicateCardIndex != -1){
      let duplicateCard= this.guestBookService.creditCardDetails[duplicateCardIndex];
      return duplicateCard.ExpiryDate != cardData.cardExpiration && duplicateCard.CardHolder != cardData.cardHolderName
    }
    return true;
  }
  return true;
}

  deleteCardDetails(index) {
    
  }

  setDefaultCard(card,index) {
    this.guestBookService.creditCardDetails.forEach(cardData => {
        cardData.IsDefault = false;
       
    });
    this.guestBookService.creditCardDetails[index].IsDefault = true;
    this.guestBookService?.guestForm?.markAsDirty();
  }

  removeCard(data,index = null,card){
    if(data){
    
      index = this.guestBookService.creditCardDetails.findIndex(card => card?.PaymentTokenId == this.PaymentReferenceID);
      this.PaymentReferenceID = 0;
    }   
    if(index > -1) {

      let selectedCard = this.guestBookService.creditCardDetails.find(cardData => cardData.MaskedCardNumber == card?.MaskedCardNumber && cardData.Status == Status.Approved);
    if(selectedCard)  
    selectedCard.Status = Status.Inactive;
    if(selectedCard?.IsDefault && this.guestBookService.creditCardDetails.find(cardData => cardData.Status == Status.Approved)){
      this.guestBookService.creditCardDetails.find(cardData => cardData.Status == Status.Approved).IsDefault = true;
    }
        
    }
    this.guestBookService?.guestForm?.markAsDirty();
  }
  
  updateDefaultCardStatus() {

    if(!(this.guestBookService?.creditCardDetails?.length)){
      return;
    }

    const doesNotHaveStatus10AndDefault = !this.guestBookService.creditCardDetails.some(
      (card: any) => card.Status === 10 && card.IsDefault
    );

    if (doesNotHaveStatus10AndDefault) {
        const lastItemIndex = this.guestBookService.creditCardDetails.length - 1;
        this.guestBookService.creditCardDetails[lastItemIndex].IsDefault = true;
    }
  }

  generateMonthAsOptions() {
    let month = 0;
    let options = [];
    while (month <= 11) {
      options.push({ id: month, value: month });
      month++;
    }
    return options;
  }
  generateYearAsOptions() {
    let date = new Date();
    let currentYear = date.getFullYear();
    let minYear = 0;
    let options = [];
    while (minYear <= 14) {
      options.push({ id: currentYear++, value: currentYear++ });
      minYear++;
    }
    return options;
  }

  SaveReferenceId(tokenId) {
    if (tokenId) {
      this.guestBookService.ps.RetailCardTokenId = tokenId;
      this.setRetailTokenId.emit(tokenId);
      this.guestBookService.ps.paymentMethodChanges$.next(true);
    }

  }

}


@Pipe({
  name: 'cardStatusPipe',
  pure: false
})
export class CardStatusPipe implements PipeTransform {
  status = Status;
  transform(cardDetails: any): any {
    return cardDetails ? cardDetails.filter(shift => shift.Status == this.status.Approved) : [];
  }
}


