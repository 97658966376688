<div class="editor-tables" (click)="removeErrorMsg()">
  <!-- <div class="editor-tables__edit-tools" style="color:#5C5C5C;padding: 20px;"> -->
  <ng-scrollbar track="vertical" class="editor-tables-scrollbar">
    <div class="editor-tables__view">
      <div class="editor-tables__tools-view">
        <label class="editor-tables__tools-label seat-tertiary-text">{{'objects' | translate}}</label>
        <div class="editor-tables__tools-selection">
          <div>
            <label class="seat-labels editor-tables__object-labels">{{'tablesMenuText' | translate}}</label>
            <div style="border-right: 1px dashed #666666;">
              <button mat-button class="editor-tables__tool-select" (click)="selectedTool(0)"
                [ngClass]="{'editor-tables__selected-tool' : fs.toolIndex == 0}">
                <img class="tool-img" src="assets/images/rest.png" style="height: 35px;">
              </button>
              <button mat-button class="editor-tables__tool-select" (click)="selectedTool(1)"
                [ngClass]="{'editor-tables__selected-tool' : fs.toolIndex == 1}">
                <img class="tool-img" src="assets/images/circ.png" style="height: 35px;">
              </button>
            </div>
          </div>
          <div>
            <label class="seat-labels editor-tables__object-labels">{{'popUpArea' | translate}}</label>
            <div style="border-right: 1px dashed #666666;">
              <button mat-button class="editor-tables__tool-select" (click)="selectedTool(3)"
                [ngClass]="{'editor-tables__selected-tool' : fs.toolIndex == 3}">
                <img class="tool-img" src="assets/images/shape.png" style="height: 35px;">
              </button>
              <button mat-button class="editor-tables__tool-select" (click)="selectedTool(4)"
                [ngClass]="{'editor-tables__selected-tool' : fs.toolIndex == 4}">
                <img class="tool-img" src="assets/images/wall.png" style="height: 35px;">
              </button>
            </div>
          </div>
          <div>
            <label class="seat-labels editor-tables__object-labels">{{ 'text' | translate}}</label>
            <div>
              <button mat-button class="editor-tables__tool-select" (click)="selectedTool(2)"
                [ngClass]="{'editor-tables__selected-tool' : fs.toolIndex == 2}">
                <img class="tool-img" src="assets/images/text.png" style="height: 35px;">
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="editor-tables__tools-view">
        <label class="editor-tables__tools-label seat-tertiary-text">{{'shapes' | translate}}</label>
        <div class="editor-tables__tools-selection">
          <button mat-button class="editor-tables__tool-select" (click)="selectedTool(5)"
            [ngClass]="{'editor-tables__selected-tool' : fs.toolIndex == 5}">
            <img class="tool-img" src="assets/images/square.svg" style="height: 35px;">
          </button>
          <button mat-button class="editor-tables__tool-select" (click)="selectedTool(6)"
            [ngClass]="{'editor-tables__selected-tool' : fs.toolIndex == 6}">
            <img class="tool-img" src="assets/images/Ellipse.svg" style="height: 35px;">
          </button>
          <button mat-button class="editor-tables__tool-select" (click)="selectedTool(7)"
            [ngClass]="{'editor-tables__selected-tool' : fs.toolIndex == 7}">
            <img class="tool-img" src="assets/images/Pin.svg" style="height: 35px;">
          </button>
        </div>
      </div>
      <div class="editor-tables__tools-view">
        <app-image-gallery [isLayout]="false" (customObject)="selectCutomObject($event)"></app-image-gallery>
      </div>
      <div class="editor-tables__edit-property" [hidden]="!objectSelected">
        <label class="editor-tables__edit-property-name">{{'editingProperty'|translate}}</label>
        <span class="new-join__error-msg"
          *ngIf="joinedToCommunalError">{{'joinedTable' | translate }}{{tableName}}{{'communalChange' | translate}}</span>
        <div class="editor-tables__property-form" [hidden]="!hideLabelTextEditor">

          <dynamic-form [config]="tableConfig" #form="dynamicForm">
          </dynamic-form>
          <div *ngIf="isDefaultShapeSelected">
            <mat-accordion class="editor-tables__accordion">
              <mat-expansion-panel class="seat-secondary-bg-color">
                <mat-expansion-panel-header [collapsedHeight]="'54px'" [expandedHeight]="'64px'">
                  <div class="editor-tables__panel-header">
                    <span>{{'customImage' | translate}}</span>
                  </div>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <div class="editor-tables__gallery">
                    <span class="editor-tables__gallery-actions seat-secondary-text" (click)="editGallery()"
                      *ngIf="!isEditGallery">{{'serverEditText' | translate}}</span>
                    <div class="editor-tables__actions-group">
                      <label class="editor-tables__gallery-actions seat-secondary-text rs-pl-5" *ngIf="isEditGallery"
                        (click)="cancel()">{{ 'closeText' | translate}}</label>
                      <div class="editor-tables__upload-and-delete">
                        <label class="editor-tables__gallery-actions seat-secondary-text"
                          [ngClass]="{'editor-tables__add-disabled': fs?.selectedObjectImageIds?.length > 0}"
                          *ngIf="isEditGallery" for="add-custom-images">{{'addText' | translate}}</label>
                        <input style="display: none;" multiple type="file" [accept]="availableFileExtensions"
                          id="add-custom-images" (change)="selectFiles($event)">
                        <label class="editor-tables__gallery-actions editor-tables__delete seat-secondary-text"
                          *ngIf="isEditGallery" (click)="deleteImages()"
                          [ngClass]="{'editor-tables__add-disabled': fs?.selectedObjectImageIds?.length == 0}">{{'deleteText' | translate}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="editor-tables__image-library">
                    <div class="editor-tables__images">
                      <div class="editor-tables__display-images" *ngIf="!isEditGallery">
                        <img src="assets/images/default-image.svg" alt=""
                          class="editor-tables__uploaded-image" (click)="applyImage({ImageId: null, bytes: ''})">
                        <svg *ngIf="isDefaultImage" class="checkmark editor-tables__selected-tick" viewBox="0 0 52 52">
                          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                      </div>
                      <div class="editor-tables__display-images" *ngFor="let image of imageLibrary">
                        <img [src]="'data:image/png;base64,'+image.bytes" alt="" (click)="applyImage(image)"
                          class="editor-tables__uploaded-image">
                        <svg *ngIf="image.isImageSelected" class="checkmark editor-tables__selected-tick"
                          viewBox="0 0 52 52">
                          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div class="editor-tables__property-form" [hidden]="hideLabelTextEditor">
          <dynamic-form [config]="labelConfig" #labelform="dynamicForm">
          </dynamic-form>
        </div>
      </div>
      <div class="editor-tables__snapping-view" [ngClass]="{'editor-tables__snap-overflow': objectSelected}">
        <label class="editor-tables__edit-property-name">{{'snapping'|translate}}</label>
        <dynamic-form [config]="snappingConfig" #snappingForm="dynamicForm">
        </dynamic-form>
      </div>
    </div>
  </ng-scrollbar>
  <!-- </div> -->
</div>