<div class="h-100">
  <div class="external-guest"*ngIf="ExternalGuests?.length > 0" [hidden]="isAddNewGuest || cartGuestFieldEdit">
    <label>{{'cartGuest' | translate}}</label>
    <dynamic-form [config]="vCartGuestConfig" #vCartGuest></dynamic-form>
  </div>
  <div class="reservation-guest" [ngClass]="!showSelectedGuest ? '' : 'd-none'">
    <div class="seat-header-two">{{'waitListPopUpSelectGuest'|translate}}</div>
    <!-- <div class="toggle-button platform-guest pt-2" [hidden]="searchType !== 1 || !hasCGPSearch">
      <label class="switch-control__switch-label guest-form-label pr-2">{{ 'PlatformGuestsearch' | translate}}</label>
      <label class="switch"  >
          <input class="switch-input" type="checkbox" (change)="platFormToggleChange()" [(ngModel)]="platformGuestSearchEnable"  />
          <span class="slider round"></span>
      </label>
  </div> -->
    <div class="reservation-guest__search-field">
      <dynamic-form [config]="configGuestSearchBy" #configGuestSearchByForm></dynamic-form>
      <div class="reservation-guest__search-actions">
        <dynamic-form [config]="configGuestSearch" #configGuestSearchForm></dynamic-form>
        <activities-app-button *ngIf="!autoSearchEnabled" [buttontype]="buttonSearch" (valueChange)='searchGuest($event)'></activities-app-button>
      </div>
    </div>
    <div class="reservation-guest__add-guest-view">
      <activities-app-button [buttontype]="buttonTertiary" (valueChange)='addnewGuest($event)'></activities-app-button>
    </div>
    <div class="reservation-guest__table mt-1" [ngClass]="{'reservation-guest__table-height': !showAddNewGuestBtn}">
      <ng-scrollbar>
        <!-- <div class="reservation-guest__table-view" [ngClass]="!showAddNewGuestBtn ? '' : 'd-none'">
          <div class="reservation-guest__table-view-header">
            <div class="reservation-guest__table-view-row seat-grid-header">
              <div class="p-3 seat-border-color reservation-guest__table-view-cell">{{'guestNameText' | translate}}</div>
              <div class="p-3 seat-border-color reservation-guest__table-view-cell">{{'guestEmailText' | translate}}</div>
              <div class="p-3 seat-border-color reservation-guest__table-view-cell">{{'guestBookDetailsPhone' | translate}}</div>
              <div class="p-3 seat-border-color reservation-guest__table-view-cell" *ngIf="isMembershipAvailable">{{_settings.General.MembershipNumberName}}</div>
            </div>
          </div>
          <div class="reservation-guest__table-view-body">
            <div *ngFor="let item of guestContacts" class="reservation-guest__table-view-row" (click)="getGuestDetails(item.Id,false,item)">
              <div class="p-3 seat-border-color reservation-guest__table-view-cell">{{item.FirstName}} {{item.LastName}} <ng-container *ngIf="item?.AliasName">({{item.AliasName}})</ng-container></div>
              <div class="p-3 seat-border-color reservation-guest__table-view-cell">{{item.modifiedEmail}}</div>
              <div class="p-3 seat-border-color reservation-guest__table-view-cell">{{item.modifiedPhone}}</div>
              <div class="p-3 seat-border-color reservation-guest__table-view-cell" *ngIf="isMembershipAvailable">
                <span *ngIf="item.TrackMembershipNumber">{{item.TrackMembershipNumber}}</span>
                <span *ngIf="!item.TrackMembershipNumber">{{'NA' | translate}}</span>
              </div>
            </div>
          </div>
        </div> -->
        <div [ngClass]="!showAddNewGuestBtn ? '' : 'd-none'">
          <ng-container *ngIf="searchType != 4 && !isStayConfirmationEnabled && !isPMSSearchEnabled && !isRoomNumberSearchEnabled">
            <li *ngFor="let item of guestContacts" class="guest-card" (click)="getGuestDetails(item.Id,false,item)"  >
              <div class="guest-card__name seat-grid-header">{{item.FirstName}} {{item.LastName}} <ng-container *ngIf="item?.AliasName">({{item.AliasName}})</ng-container></div>
              <div class="guest-card__phone" *ngIf="item.modifiedPhone">{{item.modifiedPhone}}</div>
              <div class="guest-card__email" *ngIf="item.modifiedEmail">{{item.modifiedEmail}}</div>
              <hr class="mt-2 mb-2" *ngIf="item.TrackMembershipNumber || item.MembershipType">
              <div class="guest-card__membership" *ngIf="item.TrackMembershipNumber">
                {{_settings.General.MembershipNumberName}}: {{item.TrackMembershipNumber}}
              </div>
            </li>
          </ng-container>
          <ng-container *ngIf="searchType == 4 && memberShipArr.length">
            <li *ngFor="let item of memberShipArr" class="guest-card" (click)="getMemberDetails(item)" >
              <div class="guest-card__name seat-grid-header">{{item.FName}} {{item.LName}} <ng-container *ngIf="item?.AliasName">({{item.AliasName}})</ng-container></div>
              <div class="guest-card__phone" *ngIf="item.PhoneNumber">{{item.PhoneNumber}}</div>
              <div class="guest-card__email" *ngIf="item.Email">{{item.Email}}</div>
              <hr class="mt-2 mb-2" *ngIf="item.CardNo || item.MembershipType">
              <div class="guest-card__membership" *ngIf="_merchantSetting.ShowEngageMemberShipId.SettingValue == settingType.ENABLE">
                <i class="icon-card"></i><span class="pl-1">{{item.CardNo ? item.CardNo :('NA' | translate)}}</span>
              </div>
              <div class="guest-card__membership" *ngIf="_merchantSetting.ShowEngageMemberShipType.SettingValue == settingType.ENABLE">
                <i class="icon-member"></i><span class="pl-1">{{item.MembershipType? item.MembershipType :('NA' | translate)}}</span>
              </div>
            </li>
          </ng-container>
          <ng-container *ngIf="isStayConfirmationEnabled || isPMSSearchEnabled || isRoomNumberSearchEnabled">
            <li *ngFor="let item of guestContacts" class="guest-card" (click)="getStayContact(item)"  >
              <div class="guest-card__name seat-grid-header">{{item.Title}} {{item.FirstName}} {{item.LastName}} <ng-container *ngIf="item?.AliasName">({{item.AliasName}})</ng-container></div>
              <div class="guest-card__phone" *ngIf="item.PhoneNumber">{{item.PhoneNumber}}</div>
              <div class="guest-card__email" *ngIf="item.EmailAddress">{{item.EmailAddress}}</div>
              
             
                <app-search-based-guest-data [guestData]="item"></app-search-based-guest-data>

                  

            </li>
          </ng-container>
        </div>
        <span *ngIf="searchType === 2 && showMore && guestContacts.length >= 10" (click)="showMoreContacts()" class="show-more">{{'ShowMore' | translate}} </span>
      </ng-scrollbar>
      <li *ngIf="!guestContacts?.length && !memberShipArr?.length"  class="guest-card mt-4" >
        {{'noDataFound' | translate}}
      </li>
      
    </div>
  </div>
  <div class="selected-guest" *ngIf="showSelectedGuest">
    <app-view-selected-guest [guestDetails]="guestDetails" [isEdit]="isEditGuest" [isAddNewGuest]="isAddNewGuest"
      [partyEmailAndPhoneInfo]="partyEmailAndPhoneInfo" [fromCart]="data && data.isCartEdit" [ratePlan]="this.ps.ratePlanObject"
      [tabIndex]="selectedIndex" (guestChange)="changeGuest()" [partySourceId]="partySourceId" [partyId]="partyID"  (cartGuestEdit)="cartGuestEdit($event)" ></app-view-selected-guest>
  </div>
</div>
