<!-- <div [formGroup]="group">
  <mat-form-field appearance="outline" class="basic-input-field" [ngClass]="config.maxLengthHint ? 'setMargin':''">
    <mat-label class="select-label">{{config.label}}</mat-label>
    <textarea matInput #textarea [placeholder]="config.placeholder" [maxlength]="config.maxLengthCount" [formControlName]="config.name" [attr.name]="config.name"></textarea>

    <mat-hint class="w-100" *ngIf="config.maxLengthHint">
      <span>{{config.maxLengthText}}</span>
      <span class="float-right">{{textarea.value?.length || 0}} / {{config.maxLengthCount}}</span>
    </mat-hint>
    <mat-error *ngIf="group.controls[FormControlName].hasError('minlength') && config.showErrorText">
      Min length validate 6
    </mat-error>
    <mat-error *ngIf="group.controls[FormControlName].hasError('required') && group.controls[FormControlName].touched && config.showErrorText">
      Field Required Field
    </mat-error>
  </mat-form-field>
</div> -->
<div [formGroup]="group" class="form-text-area {{config.containerClass}}" [hidden]="config.isHidden" *ngIf="!config.textAreaAppearance && group.controls[config.name]">
  <mat-label *ngIf="!config.appearance" class="select-label">
    <span>{{config.label | translate}}</span>&nbsp;<span class="required-asterik"
      *ngIf="config.isStarSymbolRequired">*</span>
  </mat-label>
  <mat-form-field [ngClass]="config.class" [hideRequiredMarker]="!config.isStarSymbolRequired" class="rs-Roboto-Regular-14" [hintLabel]="hintLabel" [appearance]="appearenceValue || 'outline'">
    <textarea class="rs-Roboto-Regular-14" #textarea matInput maxlength="{{config.charLength}}"
      [formControlName]="config.name" [errorStateMatcher]="config.disableErrorStateMatcher ? false : matcher" [rows]="textRows" cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="5" (keyup)="onKeyup($event)"></textarea>
    <mat-hint class="rs-Roboto-Regular-10" *ngIf="config.showHint" align="end">
      <span>{{config.maxLengthText | translate}}</span>
      <span>{{textarea?.value?.length || 0}}/{{config.charLength}}</span>
      <span style="margin-left: 10px;" class="pointer error-message" *ngIf="config.showRemove" (click)="remove(config)">{{'Remove' | translate}}</span>
    </mat-hint>
  </mat-form-field>
</div>
<div [formGroup]="group" class="form-text-area" [hidden]="config.isHidden" *ngIf="config.textAreaAppearance">
  <mat-label *ngIf="!config.appearance" class="select-label">
    <span>{{config.label | translate}}</span>&nbsp;<span class="required-asterik"
      *ngIf="config.isStarSymbolRequired">*</span>
  </mat-label>
  <mat-form-field [ngClass]="config.class" [hideRequiredMarker]="!config.isStarSymbolRequired" class="rs-Roboto-Regular-14" [appearance]="appearenceValue || 'outline'">
    <textarea class="rs-Roboto-Regular-14" #textarea matInput maxlength="{{config.charLength}}" 
      [formControlName]="config.name" [errorStateMatcher]="config.disableErrorStateMatcher ? false : matcher" [rows]="textRows" cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="5" (keyup)="onKeyup($event)"></textarea>
      <mat-error class="mt-1"
      *ngIf="group.controls[FormControlName].hasError('pattern')">
      {{'invalidText' | translate}}
    </mat-error>
    <mat-error class="mt-1"
      *ngIf="group.controls[FormControlName].hasError('required') && config.showErrorText">
      {{config.errorMessage | translate}}
    </mat-error>
  </mat-form-field>
</div>
