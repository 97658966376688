<div class="saved-card ag-mb-3">
  <div class="d-flex justify-content-between">
    <label for="" class="form-label-normal">{{labels.SaveCardDetails}} </label>
    <div *ngIf="showDeleteButton" class="card-remove" (click)="removeCardDetails()">
      <i class="icon-Delete cursor"></i>
    </div>
  </div>
  <div class="card-details-container">
    <div class="saved-card-details">
      <span>{{cardData?.cardNumber}}</span>
      <span>{{cardData?.cardExpiration | formatExpiryDate}}</span>
      <span>
        <app-retail-card-type [type]="cardData?.issuerType"></app-retail-card-type>
      </span>
    </div>

  </div>
</div>