import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Compiler, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';

import { MachineName } from '@app/common/shared/shared.modal';
import { KeyboardService } from '@app/pos/keyboard/keyboard.service';
import { B2C_Constants, DEFAULT_LANGUAGE_CODE, DEFAULT_LANGUAGE_ID } from '@app/shared/constants/globalConstants';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { buttonTypes, ComponentTypes } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { AuthService } from '@core/services/auth.service';
import { CacheService } from '@core/services/cache.service';
import { LoginService } from '@core/services/login.service';
import { MultilingualService } from '@core/services/multilingual.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { AuthenticationResultDTO, RestaurantAvailableForLoginDTO, VerificationResultDTO } from '@models/AuthenticationResultDTO';
import { TranslateService } from '@ngx-translate/core';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { SignalrService } from '@services/signalr.service';
import { LoginFunction } from '@utilities/login-function';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';
import moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('1s cubic-bezier(.75,-0.48,.26,1.52)',
          style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0%)' }),
        animate('1s cubic-bezier(.75,-0.48,.26,1.52)',
          style({ transform: 'translateY(-100%)' }))

      ])
    ]),
    // trigger('leaveAnimation', [
    //   transition(':leave', [
    //     style({ transform: 'translateX(0%)' }),
    //     animate('1s cubic-bezier(.75,-0.48,.26,1.52)',
    //       style({ transform: 'translateX(-100%)' }))
    //   ])
    // ]
    //  )
  ],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent extends Utilities implements OnInit, AfterViewInit, OnDestroy {
  restaurantListControl: UntypedFormControl;
  filteredOptions: Observable<RestaurantAvailableForLoginDTO[]>;
  logoUrl: string = urlConfig.logoUrl_black;

  loginErrorMsg: string;
  restaurantList: any = [];
  unlockconfig: FieldConfig[] = [];
  loginConfig: FieldConfig[] = [];
  customerConfig: FieldConfig[] = [];
  mahcineConfig: FieldConfig[] = [];
  langJson: any;
  unlockButton: ButtonValue;
  ssoButton: ButtonValue;
  unlockWithPasswordButton: ButtonValue;
  loginButton: ButtonValue;
  machineButton: ButtonValue;
  verifyButton: ButtonValue;
  public loginFormGroup: UntypedFormGroup;
  showLogin = true;
  enableMultiLanguage = true;
  login$: Subject<any> = new Subject<any>();
  title = 'seat-v2';
  breakInClickCounter = 0;
  loginSubscription: ISubscription;
  unlockSubscription: ISubscription;
  data: Observable<RestaurantAvailableForLoginDTO[]>;
  @ViewChild('customerDetails') customerForm: DynamicFormComponent;
  @ViewChild('unlockForm') unlockForm: DynamicFormComponent;
  @ViewChild('pinLoginForm') pinLoginForm: DynamicFormComponent;
  @ViewChild('machineSelectionForm') machineSelectionForm: DynamicFormComponent;
  hidePassword = true;
  settingsSubscription: any;
  subscriptions: Subscription = new Subscription();
  isMachineDetailsAvailable = false;
  allMachines: MachineName[];
  availableLanguages = [];
  languageChosen = DEFAULT_LANGUAGE_CODE;
  currentYear:number = (new Date()).getFullYear();
  gotMerchant : boolean = false;
  showSsoLink: boolean = false;
  loginHeader: string = "Login";
  
  constructor(private router: Router, private loginService: LoginService, public as: AppService,
    private ms: MultilingualService, public fb: UntypedFormBuilder, public _cs: CacheService, public _signalRService: SignalrService,
    public translateService: TranslateService, dialog: MatDialog, public loginFn: LoginFunction, private compiler: Compiler, public keyboard: KeyboardService) {
    super(dialog);
    this.restaurantListControl = new UntypedFormControl();
    sessionStorage.setItem(`isAppLoggedIn${Utilities.getSessionStorageType()}`, 'false');
  }

  ngOnInit() {
    if(sessionStorage.getItem('_userInfo')) {
      this.as.setUserLoggedIn(false);
      this.as.logout();
    }
    this.compiler.clearCache();
    this.generateLanguageOptions();
    this.loginFn.retailLogin.retailIntilaize();
    this.loginFormGroup = this.fb.group({
      language: ['', Validators.required],
      restaurant: ['', Validators.required],
      lockPin: ['', Validators.required]
    });
    this.loginButton = {
      type: buttonTypes.actionPrimary,
      label: 'registerBtnText',
      disbaledproperity: this.login$,
      customclass: 'seat-login__submit',
    };
    this.unlockButton = {
      type: buttonTypes.actionPrimary,
      label: 'loginButtonText',
      disbaledproperity: false,
      customclass: 'seat-login__submit',
      icon: 'icon-padlock'
    };
    this.ssoButton = {
      type: buttonTypes.actionSecondary,
      label: 'ssoLoginButtonText',
      disbaledproperity: false,
      customclass: 'seat-login__submit',
      icon: 'icon-sphere'
    };
    this.unlockWithPasswordButton = {
      type: buttonTypes.actionSecondary,
      label: 'unlockWithPasswordButtonText',
      disbaledproperity: false,
      customclass: 'seat-login__submit',
      icon: 'icon-padlock'
    };
    this.verifyButton = {
      type: buttonTypes.actionPrimary,
      label: 'loginButtonText',
      disbaledproperity: false,
      customclass: 'seat-login__submit',
      icon: 'icon-padlock'
    }
    this.langJson = [{ id: 'en', value: 'langEnglishText' }, { id: 'fr', value: 'langFrenchText' }];
    const lastLoggedInRestaurant = sessionStorage?.restaurantName;
  
    this.loginConfig = [
      {
        type: 'autocomplete',
        label: 'registerPropertyText',
        name: 'restaurant',
        placeholder: 'registerPropertyText',
        options: [],
        class: 'seat-login__restaurant-selection',
        formInputIcon: 'icon-shop',
        showErrorText: true,
        isTranslate: true,
        value: lastLoggedInRestaurant?.Name || null,
        icon1: 'icon-Group-591',
        cellClick: (e) => {
          this.cellClick(e);
        },
        returnFunction: (event) => {
          this.clearData(event)
        },
        validation: [Validators.required],
        errorMessage: 'restaurantError',
        showIconAlways: true
      },
      {
        type: 'input',
        label: 'registerPinText',
        name: 'pinCode',
        inputType: 'password',
        placeholder: 'registerPinText',
        class: 'seat-login__unlock-pin',
        formInputIcon: 'icon-password',
        disableErrorStateMatcher: true,
        autocomplete: 'new-password',
        showErrorText: true,
        blurClick: (e) => {
          this.blurClick(e)
        },
        directive: 'numberOnly',
        charLength: 4,
        validation: [Validators.required, Validators.minLength(4)],
        showIconAlways: true
      }
    ];
    let lastLoginSSO = sessionStorage.getItem('Login_MerchantId') || null;
    this.unlockconfig = [
      {
        type: 'input',
        label: 'emailAddress',
        name: 'username',
        inputType: 'text',
        // placeholder: 'emailAddress', //Remove placeholder as per BA suggestion
        class: 'seat-login__username',
        formInputIcon: 'icon-login-user',
        showErrorText: true,
        value:lastLoginSSO || null,
        disableErrorStateMatcher:true
      },
      {
        type: 'input',
        label: 'loginPasswordText',
        name: 'password',
        inputType: 'password',
        // placeholder: 'loginPasswordText',//Remove placeholder as per BA suggestion
        class: 'seat-login__password',
        formInputIcon: 'icon-password',
        cellClick: (e) => {
          this.showPassword(e)
        },
        blurClick: (event) => {
          this.enterClick(event, "");
        },
        icon1: 'icon-eye',
        showIconAlways: true,
        showErrorText: true,
        disableErrorStateMatcher:true
      },
    ];
    this.customerConfig = [
      // {
      //   type: 'input',
      //   label: 'loginMerchantIdText',
      //   name: 'merchantId',
      //   inputType: 'text',
      //   class: 'seat-login__merchantId',
      //   formInputIcon: 'icon-availability',
      //   cellClick: (e) => {
      //     this.editMerchant();
      //   },
      //   blurClick: (event) => {
      //     this.enterClick(event, "getMerchant");
      //   },
      //   isReadOnly: false,
      //   showIconAlways: true,
      //   showErrorText: true,
      //   value: sessionStorage.getItem(`Login_MerchantId`) || null,
      //   validation:[Validators.required]
      // }
      {
        type: 'input',
        label: 'emailAddress',
        name: 'merchantId',
        inputType: 'text',
        class: 'seat-login__username',
        formInputIcon: 'icon-login-user',
        cellClick: (e) => {
          this.editMerchant();
        },
        blurClick: (event) => {
          this.enterClick(event, "getMerchant");
        },
        isReadOnly: false,
        showIconAlways: true,
        showErrorText: false,
        disableErrorStateMatcher:true,
        value: sessionStorage.getItem(`Login_MerchantId`) || null,
        validation:[],
        autocomplete:'off'
      }
    ];

    this.mahcineConfig = [
      {
        type: 'select',
        name: 'Machine',
        label: 'machine',
        placeholder: 'machine',
        options: [],
        class: 'seat-login__machine-field',
        showErrorText: true,
        appearance: false,
        isTranslate: false,
        value: 0
      }
    ];
    this.machineButton = {
      type: buttonTypes.actionPrimary,
      label: 'registerBtnText',
      disbaledproperity: true,
      // disbaledproperity: false,
      customclass: 'seat-login__submit',
    };

    this.gotMerchant = !(sessionStorage.getItem(`IsEditMerchant`)?.toLowerCase() === 'true' ? true : false) && !!sessionStorage.getItem(`Login_MerchantId`);
    this.customerConfig[0].disabled = this.gotMerchant;
    this.customerConfig[0].icon1 = this.gotMerchant ? "icon-checkedout" : '';
    this.unlock(true);
    this.showSsoLink = !this.gotMerchant;
    
  }

  enableONscreenKeyboard(){
    this.keyboard.enableDisableKeyboard(null)
  }

  editMerchant(){
    sessionStorage.setItem(`IsEditMerchant`, 'true');
    localStorage.removeItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`);
    sessionStorage.removeItem(`MerchantKey${Utilities.getSessionStorageType()}`);
    Utilities._restaurantId = null;
    this.loginFn.propListUpdated.next(null);
    this.as.logout();
    this.showLogin = true;
    this.gotMerchant = false;
    this.isMachineDetailsAvailable = false;
    this.customerConfig[0].isReadOnly = this.gotMerchant;
    this.customerConfig[0].icon1 = this.gotMerchant ? "icon-checkedout" : '';
    this.showSsoLink = !this.gotMerchant;
    this.loginHeader = 'loginButtonText';
  }

  showSSO(){
    this.showSsoLink = false;
    this.showLogin = false;
    if(this.unlockForm?.value?.username){
      this.customerForm.form.get('merchantId').setValue(this.unlockForm.value.username);
      this.getMerchant();
      }
    this.loginHeader = 'ssoLoginButtonText';
  }

  unlockWithPassword(){
    this.loginHeader = 'loginButtonText';
    this.showSsoLink = true;
    this.showLogin = true;
    if(this.customerForm?.value?.merchantId){
      this.unlockForm.form.get('username').setValue(this.customerForm.value.merchantId);
    }
  }

  getMerchant(){
    this.loginFn.verifyMerchantId(this.customerForm.value.merchantId).subscribe(data => {
      let verificationResultData: VerificationResultDTO = {} as VerificationResultDTO;
      verificationResultData = data.Payload;
      
      if(data.State == 0 && verificationResultData.IsSuccessful){
        sessionStorage.setItem(B2C_Constants.IsAzureB2CEnabled, verificationResultData.IsAzureB2CEnabled.toString());
        sessionStorage.setItem(`Login_MerchantId`, this.customerForm.value.merchantId || null);
        if(verificationResultData.IsAzureB2CEnabled){
          sessionStorage.setItem(B2C_Constants.Authority, verificationResultData.AzureAdB2C_Instance);
          sessionStorage.setItem(B2C_Constants.Tenant, verificationResultData.AzureAdB2C_Domain);
          sessionStorage.setItem(B2C_Constants.ClientID, verificationResultData.AzureAdB2C_ClientId);
          sessionStorage.setItem(B2C_Constants.SignIn_SignUp_User_Flow, verificationResultData.AzureAdB2C_SignUpSignInPolicyId);
          
          this.router.navigate(['./sso']);
        }else{
          sessionStorage.removeItem(B2C_Constants.Authority);
          sessionStorage.removeItem(B2C_Constants.Tenant);
          sessionStorage.removeItem(B2C_Constants.ClientID);
          sessionStorage.removeItem(B2C_Constants.SignIn_SignUp_User_Flow);
          sessionStorage.removeItem(B2C_Constants.AzureADB2C_Token);
          sessionStorage.removeItem(B2C_Constants.AzureADB2C_email);
          const popUpMessage = [{
            confirmationMessage: `${this.translateService.instant('ssologinNotEnabledMessage')}`,
            dialogTitle: this.translateService.instant('authFailed'),
            showAlert: true
          }];
          let okbutton = this.translateService.instant('ok');
          const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
            popUpMessage, popUpMessage[0].dialogTitle);
          this.openCustomPopup(componentDetails, ComponentTypes.login, '450px', 'auto', true, '', okbutton,
            '', true);
          this.gotMerchant = false;
          this.unlockWithPassword();
          // this.customerConfig[0].isReadOnly = this.gotMerchant;
          // this.customerConfig[0].icon1 = this.gotMerchant ? "icon-checkedout" : '';

        }
      } else {
        const popUpMessage = [{
          confirmationMessage: `${this.translateService.instant('loginFailMessage')}`,
          dialogTitle: this.translateService.instant('authFailed'),
          showAlert: true
        }];
        let okbutton = this.translateService.instant('ok');
        const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
          popUpMessage, popUpMessage[0].dialogTitle);
        this.openCustomPopup(componentDetails, ComponentTypes.login, '450px', 'auto', true, '', okbutton,
          '', true);
        this.gotMerchant = false;
        this.unlockWithPassword();
      }
    });
  }

  generateLanguageOptions() {
    this.as.getLanguages().subscribe(langs => {
      this.availableLanguages = langs.Payload;
      const sessionType = Utilities.getSessionStorageType()
      sessionStorage.setItem(`languages${sessionType}`, JSON.stringify(this.availableLanguages))

      let languageSelected = this.availableLanguages.find(lang => lang.Id == (sessionStorage.getItem(`languageId${sessionType}`) || DEFAULT_LANGUAGE_ID));
      sessionStorage.setItem(`languageId${sessionType}`, languageSelected.Id.toString())
      this.languageChosen = languageSelected?.Code || DEFAULT_LANGUAGE_CODE;
      this.setDefaultLanguage(languageSelected)
    })
  }

  showPassword(event) {
    this.hidePassword = !this.hidePassword;
    if (this.hidePassword) {
      this.unlockconfig[1].inputType = 'password';
      this.unlockconfig[1].icon1 = 'icon-viewPassword';
    } else {
      this.unlockconfig[1].inputType = 'text';
      this.unlockconfig[1].icon1 = 'icon-eye';
    }
  }

  cellClick(e) {
    const data = this.restaurantList.find(x => x.Name === e.option.value);
    this.loginFn.setRestaurant(data);
  }

  blurClick(e) {
    if (e.keyCode == 13) {
      this.login();
    }
  }

  enterClick(event, method: string) {
    if (event.keyCode == 13) {
      if(method === "getMerchant"){
        this.getMerchant();
      }else{
        this.unlock();
      }
    }
  }

  setDefaultLanguage(lang,isChosen?){
    if(lang){
    this.as.selectedLanguage = lang.Id;
    this.ms.selectedLanguage = lang.Code;
    sessionStorage.setItem(`languageId${Utilities.getSessionStorageType()}`,lang.Id);
    sessionStorage.setItem(`languageCode${Utilities.getSessionStorageType()}`, lang.Code);
    if(isChosen) window.location.reload();
    this.languageChosen = lang.Code;
    this.setLanguage({value : lang.Id})
    }
  }

  setLanguage(event) {
    this.as.propertyLanguage = event.value;

    this.as.setLanguage(event.value);
    this.ms.setLanguage(event.value);
  }

  ngOnDestroy() {
    this.loginConfig.forEach(config => {
      config.blurClick && config.blurClick.bind(null);
      config.cellClick && config.cellClick.bind(null);
      config.returnFunction && config.returnFunction.bind(null);
    });
    this.unlockconfig.forEach(config => {
      config.blurClick && config.blurClick.bind(null);
      config.cellClick && config.cellClick.bind(null);
      config.returnFunction && config.returnFunction.bind(null);
    })
    this.loginConfig = [];
    this.unlockconfig = [];
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
    if (this.unlockSubscription) { this.unlockSubscription.unsubscribe(); }
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

  clearData(event) {
    this.pinLoginForm.form.get('restaurant').setValue('');
  }

  login() {
    const sessionType = Utilities.getSessionStorageType();
    if (this.pinLoginForm.form.value.restaurant) {
      const data = this.restaurantList.find(x => x.Name === this.pinLoginForm.form.value.restaurant);
      data ? this.loginFn.setRestaurant(data) : sessionStorage.removeItem(`restaurantId${Utilities.getSessionStorageType()}`);
    }
    if (sessionStorage.getItem(`restaurantId${sessionType}`)) {
      this.loginFn.login(this.pinLoginForm.form.value.pinCode);
      //  this.as.setLanguage(this.as.selectedLanguage);
      let languageCode = JSON.parse(sessionStorage.getItem(`languages${sessionType}`))?.find(lang => lang.Id == sessionStorage.getItem(`languageId${sessionType}`))?.Code;
      this.ms.setLanguage(languageCode || DEFAULT_LANGUAGE_CODE);
    } else {
      const popUpMessage = [{
        confirmationMessage: `${this.translateService.instant('restaurantNotFound')}`,
        dialogTitle: this.translateService.instant('errorTitle'),
        showAlert: true
      }];
      let okbutton = this.translateService.instant('ok');
      const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
        popUpMessage, popUpMessage[0].dialogTitle);
      this.openCustomPopup(componentDetails, ComponentTypes.login, '450px', 'auto', true, '', okbutton,
        '', true);
    }
  }

  unlock(isinitialIzed?) {
    this.unlockconfig[1].inputType = 'password';
    this.unlockconfig[1].icon1 = 'icon-viewPassword';
    this.unlockconfig[1].formInputIcon = 'icon-password';
    if (this.unlockSubscription) { this.unlockSubscription.unsubscribe(); }
    let disableunclock = this.loginFn.unlock(isinitialIzed,  this.unlockForm?.form?.controls['username']?.value,this.unlockForm?.form?.controls['password']?.value);
    if (!isinitialIzed && disableunclock) {
      {
        this.unlockForm.form.controls['username'].setValidators([Validators.required, Validators.email]);
        this.unlockForm.form.controls['password'].setValidators([Validators.required]);
        this.unlockForm.form.controls['username'].updateValueAndValidity();
        this.unlockForm.form.controls['password'].updateValueAndValidity();
        this.unlockButton.disbaledproperity = true;
      }
    }
    this.loginIntiated();
  }

  

  private _filter(value: string): RestaurantAvailableForLoginDTO[] {
    const filterValue = value.toLowerCase();
    if (!this.as.unlockRestaurantData || !this.as.unlockRestaurantData.RestaurantsAvailableForLogin) {
      return;
    }
    const data = this.as.unlockRestaurantData.RestaurantsAvailableForLogin.filter(option =>
      option.Name.toLowerCase().includes(filterValue));
    const filteredata = [];
    data.forEach(element => {
      filteredata.push({ id: element.Id, value: element.Name });
    });
    return data;
  }

  ngAfterViewInit() {
    this.loginHeader = 'loginButtonText';
    this.customerForm?.form?.controls['merchantId'].setValidators([Validators.required, Validators.email]);
    this.customerForm?.form?.controls['merchantId'].updateValueAndValidity();
    this.subscriptions.add(this.loginService.resetPinChange$.subscribe((data) => {
      if (data) {
        if (this.pinLoginForm && this.pinLoginForm.form && this.pinLoginForm.form.get('pinCode')) {
          this.pinLoginForm.form.get('pinCode').setValue('');
        }
      }
    }));
    this.subscriptions.add(this.loginFn.retailLogin.selectedMachines$.subscribe((machineDetails: MachineName[]) => {
      console.log(machineDetails);
      if (machineDetails && machineDetails.length > 1) {
        this.isMachineDetailsAvailable = true;
        this.allMachines = machineDetails;
        let machineOptions = [];
        this.allMachines.forEach(machine => {
          machineOptions.push({
            id: machine.id,
            value: machine.name
          })
        });
        let defaultMachineId = -1;
        if (this.loginFn.retailLogin.defaultMachine) {
          let machine = machineOptions.filter(option => option.id == this.loginFn.retailLogin.defaultMachine);
          if (machine.length) {
            defaultMachineId = machine[0].id;
          }
        }
        this.mahcineConfig[0].options = machineOptions;
        this.mahcineConfig[0].value = defaultMachineId;
        this.machineButton.disbaledproperity =  defaultMachineId >= 0 ? false : true;
        setTimeout(() => {
          if (this.isMachineDetailsAvailable) {
            this.subscriptions.add(this.machineSelectionForm?.form?.valueChanges.subscribe((val) => {
              if (this.machineSelectionForm.form.valid) {
                this.machineButton.disbaledproperity = !this.machineSelectionForm.form.valid;
              } 
              else {
                this.machineButton.disbaledproperity = !this.machineSelectionForm.form.valid;
              }
            }));
          }
        })
      }
    }))
    this.subscriptions.add(this.unlockForm.form.valueChanges.subscribe((val) => {
      if (val.username || val.password) {
        this.unlockButton.disbaledproperity = !(this.unlockForm.form.valid && (this.unlockForm.form.controls['password'].value && this.unlockForm.form.controls['username'].value));
      }
    }));
    // this.subscriptions.add(this.customerForm.form.valueChanges.subscribe((val) => {
    //   if (val.merchantId) {
    //     this.ssoButton.disbaledproperity = !(this.customerForm.form.valid && this.customerForm.form.controls['merchantId'].value);
    //   }
    // }));
    setTimeout(() => {
      this.unlockButton.disbaledproperity = !(this.unlockForm.form.valid);
    }, 500)
  }

  selectedMachine() {
    let selectedMachine = this.allMachines.filter(machine => machine.id == this.machineSelectionForm?.form?.value?.Machine);
    console.log(selectedMachine);
    if (selectedMachine.length) {
      this.loginFn.retailLogin.CreateUserSession(this.loginFn.retailLogin.propertyInformation, false)
      this.loginFn.retailLogin.storeMachineInfo(selectedMachine[0]);
      this.loginFn.setLoginDetails();
    }
  }

  pinCodeChange() {
    if (!this.showLogin && !this.isMachineDetailsAvailable) {
      this.subscriptions.add(this.pinLoginForm.form.valueChanges.subscribe((val) => {
        if (this.pinLoginForm.form.valid) {
          this.loginButton.disbaledproperity = !this.pinLoginForm.form.valid;
        } else {
          this.loginButton.disbaledproperity = !this.pinLoginForm.form.valid;
        }
      }));
    }
  }

  loginIntiated() {
    this.subscriptions.add(this.loginFn.propListUpdated.subscribe((data) => {
      this.loginConfig[0].options=[];
      if (data?.RestaurantsAvailableForLogin?.length > 0) {        
        this.showLogin = false;
        if(this.unlockForm?.value?.username){
          this.customerForm.form.get('merchantId').setValue(this.unlockForm.value.username);
          this.customerForm.form.controls['merchantId'].setValue(this.unlockForm.value.username);
          sessionStorage.setItem(`Login_MerchantId`, this.unlockForm.value.username || null);
        }
        this.gotMerchant = true;
        this.showSsoLink = !this.gotMerchant;
        this.customerConfig[0].isReadOnly = this.gotMerchant;
        this.customerConfig[0].icon1 = this.gotMerchant ? "icon-Mask-Group-8" : '';
        this.customerConfig[0].class = this.customerConfig[0].class + ' user-prevent-action'
        this.loginButton.disbaledproperity = true;
        this.restaurantList = _.sortBy(data.RestaurantsAvailableForLogin, x => x.Name.toLowerCase());
        this.restaurantList.forEach(element => {
          this.loginConfig[0].options.push({ id: element.Id, value: element.Name, isLocked: element.IsLockedDueToBillingIssues });
        });
        const sessionType = Utilities.getSessionStorageType()
        const lastLoggedInRestaurant = this.restaurantList.find(r => r.Id === parseInt(sessionStorage.getItem(`lastLoggedInRestaurantId${sessionType}`)));
        if (sessionStorage.getItem(`lastLoggedInRestaurantId${sessionType}`) && lastLoggedInRestaurant) {
          let restaurantIndex = this.loginConfig.findIndex(config => config.name == 'restaurant')
          this.loginConfig[restaurantIndex].value = lastLoggedInRestaurant.Name;
          this.pinLoginForm?.form?.get('restaurant')?.setValue(lastLoggedInRestaurant.Name);
          this.loginFn.setRestaurant({ Id: lastLoggedInRestaurant.Id, Name: lastLoggedInRestaurant.Name });
        } else {
          let restaurantIndex = this.loginConfig.findIndex(config => config.name == 'restaurant')
          this.loginConfig[restaurantIndex].value = this.loginConfig[restaurantIndex].options[0].value;
          this.pinLoginForm?.form?.get('restaurant')?.setValue(this.loginConfig[0].options[0].value);
          this.loginFn.setRestaurant({ Id: this.loginConfig[restaurantIndex].options[0].id, Name: this.loginConfig[restaurantIndex].options[0].value });
        }

        setTimeout(() => {
          this.pinCodeChange();
        });
      }
    }));
  }
}

