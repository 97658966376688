import { Injectable,EventEmitter } from '@angular/core';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { APITitle,Country,NationalityResponse, UserModel } from 'src/app/common/shared/core.model';
import { CommonApiRoutes } from "../common-route";
@Injectable({
  providedIn: 'root'
})
export class CommonDataService {
  public PostTypeMappingData = new EventEmitter<{ PaymentPostTypeMapping: any }>();
  PostTypeMappingInput: any = { PaymentPostTypeMapping: '', hostId: 0 };
  
  constructor(private _Communication: TenantManagementCommunication
    , private _utils: CommonUtilities){

}

  public async GetTitles(tenantId: number): Promise<APITitle[]> {
    const result = await this._Communication.getPromise<APITitle[]>(
        { route: CommonApiRoutes.GetAllTitles, uriParams: { "tenantId" : tenantId , "includeInActive" : false }},false,true);
    return result;
  }

  public async GetAllNationality(tenantId :number): Promise<NationalityResponse[]> {
    const result = await this._Communication.getPromise<NationalityResponse[]>(
        { route: CommonApiRoutes.GetAllNationality , uriParams: { tenantId : tenantId,includeInactive : false} },false,true);
    return result;
  }

  public async GetAllCountry(): Promise<Country[]> {
    const result = await this._Communication.getPromise<Country[]>(
        { route: CommonApiRoutes.GetCountries },false,true);
    return result;
  }

  public getAllUsers(tenantId:number, isActive: boolean = true): Promise<UserModel[]> {    
    let result = this._Communication.getPromise<UserModel[]>(
      {
        route: CommonApiRoutes.GetAllUsers,
        uriParams: { tenantId, isActive }
      });
    return result;
  }
}