import { AfterViewInit, Component, HostListener, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { PopupService } from '@app/popup-module/popup.service';
import { ComponentTypes, OperationResultState, PartyState, PartyStatus, PartyType, ReservationEmailNotificationType, ReservationType, TableTabs } from '@app/shared/constants/commonenums';
import { controlSettings } from '@app/shared/constants/globalConstants';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PageMethod, PartyDTO, PartyEmailSendBehavior, PredefinedPartyMessageDTO, SettingsDTO, StatusDTO } from '@app/shared/models/RestaurantDTO';
import { PartyStatusColorPipe } from '@app/shared/pipes/party-status-color.pipe';
import { PartyService } from '@app/shared/services/party.service';
import { TablesService } from '@app/shared/services/tables.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment-timezone';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from '../../confirmation-popup/confirmation-popup.component';
import { CacheService } from '@core/services/cache.service';
import { environment } from '@environments/environment';
import { ReservationStateActionService } from '@app/features/reservations/actions/reservation-state-action.service';


@Component({
  selector: 'app-display-tablelist-data',
  templateUrl: './display-tablelist-data.component.html',
  styleUrls: ['./display-tablelist-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DisplayTablelistDataComponent extends Utilities implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() tabName: any;
  @Input() tab: number;
  @Input() seatingParties: PartyDTO[] = [];
  @Input() _settings: SettingsDTO;
  @Input() partyStatusBasedonType: StatusDTO[] = [];
  partyState = TableTabs;
  subscriptions: Subscription = new Subscription();
  item: any;
  hidePartyActions:boolean = false;
  PartyState = PartyState;
  PartyType = PartyType;
  @ViewChildren('partyStatusPopup') private partyStatusPopup: QueryList<NgbPopover>;
  @ViewChildren('messageChat') private messageChat: QueryList<NgbPopover>;
  @ViewChildren('emailChat') private emailChat: QueryList<NgbPopover>;
  isMobileChat = false;
  predefinedMsgs$ = new BehaviorSubject<PredefinedPartyMessageDTO[]>(null);
  party: any;
  inputString = '';
  searchConfig: FieldConfig[];
  searchString: string = '';
  showPackageWithName = false;
  @ViewChild('searchConfigForm', { static: true }) searchConfigForm: DynamicFormComponent;
  selectedParty: any;
  disableMessagePopup:boolean = false;

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    let openedPopup = this.partyStatusPopup.filter(popup => popup.isOpen());
    if (openedPopup.length) {
      openedPopup[0].close();
    }
  }

  constructor(private dashboardFunctions: DashboardFunctions, public dialog: MatDialog, private partyService: PartyService,
              private ts: TranslateService, private popupService: PopupService, private appService: AppService, 
              private tableService: TablesService, private partyStatusColor: PartyStatusColorPipe, public cs: CacheService, private reservationStateActionService: ReservationStateActionService) {
                super(dialog);
              }

  ngOnInit(): void {
    this.hidePartyActions = Utilities.controlValidate(controlSettings.Location_partySelect_Cancel_Hide,this.appService.PropertyType)
    this.searchConfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this.tabName.searchText,
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        class: 'reservation__search-by',
        cellClick: (event) => { this.clearSearchField(event); }
      }
    ];
  }

  clearSearchField(event) {
    this.searchString = '';
    this.searchConfigForm.form.get('searchText').setValue('');
  }
  
  ngOnChanges() {
    this.predefinedMsgs$.next(this._settings.PredefinedPartyMessages);
  }
  
 
  ngAfterViewInit() {
    setTimeout(() => {
      this.virtualScrollHeightCalculation();
    });
    this.subscriptions.add(this.searchConfigForm.form.valueChanges.subscribe(val => {
      this.searchString = val.searchText;
    }));

    let partiesExists = this.partyService.partiesList.findIndex(f=>f.CommonPackageInfo != undefined && f.CommonPackageInfo.length > 5)
    if(partiesExists != -1){
      this.showPackageWithName = true;
    }
  }
  ngOnDestroy() {
    if (this.subscriptions) {this.subscriptions.unsubscribe();}
  }

  virtualScrollHeightCalculation() {
    const tabName = `tab-${this.tab}`;
    const tabHeight = document.getElementById(tabName);
    const virtualScrollDiv = document.getElementById(`tabs-height-${this.tab}`);
    const windowHeight = window.innerHeight;
    const headerHeight: any = document.getElementsByClassName('layout__header').length > 0 ?
      document.getElementsByClassName('layout__header')[0]['offsetHeight'] : 0;
    const dashboardHeader = document.getElementsByClassName('dashboard-header');
    const dashboardHeaderHeight = dashboardHeader.length > 0 ? dashboardHeader[0]['offsetHeight'] : 0;
    const matGroupHeader = document.getElementsByClassName('mat-tab-label-container');
    const matGroupHeaderHeight = matGroupHeader.length ? matGroupHeader[0]['offsetHeight'] : 0;
    const dashboardActions = document.getElementsByClassName('dashboard__actions');
    const dashboardActionsHeight = dashboardActions.length ? dashboardActions[0]['offsetHeight'] : 0;
    const estimatedHeight = windowHeight - (headerHeight + dashboardHeaderHeight + matGroupHeaderHeight + dashboardActionsHeight);
    if (virtualScrollDiv) {
      virtualScrollDiv.style.maxHeight = `${estimatedHeight}px`;
    }
  }

  trackFunction(index) {
    return index;
  }

  editReservationOrWaitlist(party, status) {
    if(!this.hidePartyActions){
    switch (status.state) {
      case this.partyState.Reservation: {
        this.dashboardFunctions.createOrEditReservation(true, party)
        break;
      }
      case this.partyState.WaitLists: {
        this.dashboardFunctions.createOrEditWaitlist(true, party)
        break;
      }
    }
  }
}

  triggerPartyStatusPopup(popover, state, event, item) {
    if(!this.hidePartyActions){
    if (this.partyState.Seated != state) {
      this.item = item;
      popover.open();
      event.stopPropagation();
    }
  }
  }

  seatStatusChanged(event) {
    this.subscriptions.add(this.partyService.postSeatedPartyStatus(Utilities.RestaurantId(), this.item.Id, event).subscribe(val => {

    }));
    // event.stopPropagation();
  }

  seatReservation(party, event) {
    this.dashboardFunctions.seatParty(party);
    event.preventDefault();
    event.stopPropagation();
  }
  
  cancelParty(party, event) {
    let cancelSubscription: Subscription = null;
    let confirmSubscription: Subscription = null;
    if (this.appService.OTASourceId.includes(party.PartySourceId)) {
      this.dashboardFunctions.showOTAAlert(party);
      return;
    }
    let msg = '';
    if (party.Type === ReservationType.Waitlist) {
      msg = this.dashboardFunctions.translateService.instant('cancelWaitlist');
    } else if (party.Type === ReservationType.Reservation) {
      msg = party.HostId != null ? this.dashboardFunctions.translateService.instant('cancelReservation') : this.dashboardFunctions.translateService.instant('cancelReservationForWeb');
      msg = this.dashboardFunctions.translateService.instant('cancelReservation');
    }
    let cancelText = 'No';
    let title = 'cancelConfirmationMsg';
    let noShowSet = false;
    let cancelReservation = false;
    let updateText = 'Yes';
    let showAlert = false;
    let noShowFeePopUp = false;
    let currentRestaurantDateTime = new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    let isWebReservation = false;

    if(party && party.PartySourceId){
      isWebReservation = this.partyService.isWebReservation(party.PartySourceId);
    }
    if ((party.Type === ReservationType.Reservation && moment(party.ReservedFor) < moment(currentRestaurantDateTime)) ||
      (party.Type === ReservationType.Waitlist && moment(party.ArrivedAt) < moment(currentRestaurantDateTime))) {
      const reservationMsg = this.dashboardFunctions.translateService.instant('reservationCancelMsg');
      const waitlistMsg = this.dashboardFunctions.translateService.instant('waitlistCancelMsg');
      msg = party.Type === ReservationType.Reservation ? reservationMsg : waitlistMsg;
      cancelText = party.Type === ReservationType.Reservation ? this.dashboardFunctions.translateService.instant('cancelReservationMsg') :
        this.dashboardFunctions.translateService.instant('cancelParty');
      cancelReservation = false;
      updateText = this.dashboardFunctions.translateService.instant('noShow');
      title = this.ts.instant('alert');
      showAlert = true;
      noShowFeePopUp = true;
    }

    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm', showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '600px',
      height: '350px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      if (cancelReservation) {
        if (party.Type === ReservationType.Waitlist) {
          this.dashboardFunctions.cancelWaitlistPartyConfirm(party);
          cancelReservation = false;
        } else {
          this.subscriptions.add(this.partyService.cancelReservation(party.Id).subscribe(
            (data) => {
              if (data.Payload != null) {
                this.partyService.reservationType = ReservationType.Reservation;
                if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == 1) {
                  this.partyService.openConfirmationDialog(data, this.dashboardFunctions.translateService.instant('refundFor') + this.cs.operationCurrency + data.Payload.RefundAmount + " " + this.dashboardFunctions.translateService.instant('refundForCancellation'), party.SeatingTime);
                }
                if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == 2) {
                  this.partyService.openConfirmationDialog(data, this.dashboardFunctions.translateService.instant('attemptedRefund')+this.cs.operationCurrency + data.Payload.RefundAmount + " " + this.dashboardFunctions.translateService.instant('refundFailed'), party.SeatingTime);
                }
                if (data.Payload && data.Payload.EmailAddress && (this.cs.settings.value.General.HostCancellationEmailSendBehavior == PartyEmailSendBehavior.Prompt)) {
                  this.partyService.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Cancelled);
                }
              }
              cancelReservation = false;
            }));
        }
      } else if (noShowSet && noShowFeePopUp) {
        this.subscriptions.add(this.partyService.noShowParty(party.Id).subscribe((data) => {
          if (data.State === OperationResultState.Success) {
            noShowSet = false;
          }
        }));
      }
      if (confirmSubscription) confirmSubscription.unsubscribe();
      if (cancelSubscription) cancelSubscription.unsubscribe();
    }));

    confirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.reservation && noShowFeePopUp) {
        noShowSet = true;
      }
      if (val === ComponentTypes.reservation && !noShowFeePopUp) {
        cancelReservation = true;
      }
    });
    cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.reservation && val.value === 1 && !cancelReservation && noShowFeePopUp) {
        cancelReservation = true;
      }
    });
    event.preventDefault();
    event.stopPropagation();
  }

  emailMessages(popover, party) {
    this.openMessagePopup(popover, party);
  }

  mobileMessages(popover, party) {
    this.isMobileChat = true;
    this.openMessagePopup(popover, party);
  }

  openMessagePopup(popover, party) {
    this.party = party;
    this.inputString = 'messages';
    this.disableMessagePopup = (this.getPastReservationStatus(this.party) && this.party.Type == 0) || this.party.State == PartyState.Seated || this.party.State == PartyState.Left ? true : false;
    popover.open();
  }

  closeMobileChat() {
    if (this.isMobileChat && this.messageChat) {
      this.isMobileChat = false;
      this.messageChat.forEach((chatPopup, index) => {
        if (chatPopup) {
          chatPopup.close();
        }
      });
    } else if (this.emailChat) {
      this.emailChat.forEach((chatPopup, index) => {
        if (chatPopup) {
          chatPopup.close();
        }
      });
    }
  }

  manualpage(party) {
    if (party.State != PartyState.Pending || party.PageMethod != PageMethod.Manual) {
      return;
    }
    const time = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    if (!party.LastPagedAt || (time.diff(moment(party.LastPagedAt), 'minutes')) >= this._settings.General.MinIntervalBetweenPartyPagesInMinutes) {
      this.subscriptions.add(this.partyService.postPartyMessage(party.Id, party.PageMethod, null,null).subscribe((data) => {
        if (data.State == OperationResultState.Success) {
        }
      }));
    } else {
      this.showPagingError();
    }
  }

  showPagingError() {
    const popUpMessage = [{
      confirmationMessage: `${this.dashboardFunctions.translateService.instant('manualPagingError')}`,
      dialogTitle: this.dashboardFunctions.translateService.instant('Paging'),
      showAlert: true
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', popUpMessage,
      popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.reservation, '450px', 'auto', true, '', 'Ok',
      'Cancel', true);
  }

  handleDragStart(event: DragEvent, party) {
    if (party && party.State == PartyState.Pending) {
      if (this.tableService.selectedTableBeforeAction$.value) {
        this.tableService.selectedTableBeforeAction$.next(null);
      }
      this.selectedParty = party;
      this.partyService.selectedTableObjectForDrag = party;
      let ghostEle;
      ghostEle = document.createElement('div');
      ghostEle.classList.add('party-element');
      let partyDetails = document.createElement('div');
      let partyIcon = document.createElement('em');
      partyIcon.classList.add('icon-user');
      let partySizeEle = document.createElement('span');
      partyDetails.classList.add('party-size');
      partySizeEle.innerHTML = `${party.Size}`;
      let partySizeBGColor = this.partyStatusColor.transform(party, this._settings);
      console.log(partySizeBGColor);
      partyDetails.style.setProperty('--bgColor', partySizeBGColor);
      partyDetails.appendChild(partyIcon);
      partyDetails.appendChild(partySizeEle);
      let partyNameEle = document.createElement('span');
      let partyFullName;
      if (party.Contact && party.Contact.FirstName && party.Contact.LastName) {
        partyFullName = `${party.Contact.FirstName} ${party.Contact.LastName}`;
      } else if (party.Contact && party.Contact.FirstName) {
        partyFullName = `${party.Contact.FirstName}`;
      } else if (party.Contact && party.Contact.LastName) {
        partyFullName = `${party.Contact.LastName}`;
      }
      partyNameEle.classList.add('party-name');
      partyNameEle.innerHTML = `${partyFullName}`;
      ghostEle.appendChild(partyDetails);
      ghostEle.appendChild(partyNameEle);
      document.body.appendChild(ghostEle);
      event.dataTransfer.setDragImage(ghostEle, 0, 0);
    } else {
      event.preventDefault();
    }
  }

  dragend(event) {
    let ghostEle;
    ghostEle = document.getElementsByClassName('party-element');
    document.body.removeChild(ghostEle[0]);
  }

  checkin(reservation){
    this.reservationStateActionService.checkin(reservation);
  }

  undoCheckin(reservation){
    this.reservationStateActionService.undocheckin(reservation);
  }
  checkout(reservation){
    this.reservationStateActionService.checkout(reservation);
  }

  getPastReservationStatus(party) {
    let restaurantTime:any = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
    var dt = moment(restaurantTime._d.setHours(0, 0, 0, 0))
    var VisibilityLimit = dt.subtract({ hours: environment.MaxMinutesBackToEditOrSeatReservation });
    if (Utilities.parseDateString(party.ReservedFor) >= new Date(VisibilityLimit.valueOf())) {
      return false;
    } else {
      return true;
    }
  }
}
