<div *ngIf="mappedTypes.length" class="size-group__container">
    <div *ngFor="let grouppedTypes of mappedTypes | groupBy: 'BookingTypeId'" class="size-group">
        <label for="" class="size-group__header">{{grouppedTypes[0] != 0 ? bookingTypeName[grouppedTypes[0]] : ('Covers' | translate)}}</label>
        <div *ngFor="let cover of grouppedTypes[1]" class="size-group__cover">
            <!-- <label for="" class="size-group__label">
                {{cover.CoverTypeId ? coverTypeName[cover.CoverTypeId] : ('coverSize' | translate)}}
            </label> -->
            <div class="size-group__input">
                <mat-label class="seat-label">{{cover.CoverTypeId ? coverTypeName[cover.CoverTypeId] : ('coverSize' | translate)}}</mat-label>
                <mat-form-field appearance="outline" class="width_180px">
                    <input matInput [matAutocomplete]="auto" [(ngModel)]="cover.Covers" (input)="onSizeChange()">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onSizeChange()" [placeholder]="coverTypeName[cover.CoverTypeId] ">
                        <mat-option [value]="option" *ngFor="let option of sizeArr">{{option}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div class="error-message" *ngFor="let error of errors">
    {{error}}
</div>