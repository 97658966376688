import { RestaurantDTO, StateDTO } from '@models/RestaurantDTO';
import { ChangesByScreenDTO, CommonParamsDto, MessageRequestDto } from '@models/SignalRDto';
import sideMenu from '@data/sidemenu.json';

declare function postMessage(message: any): void;

export function processChange(input: MessageRequestDto) {

  enum ChangeAction {
    Created,
    Updated,
    Removed,
    None
  }

   enum ChangedEntityType {
    NoChange = 0,
    ChangeSet = 1,
    Settings = 2,
    Layout = 3,
    State = 4,
    DataRetentionPolicy = 5,
    StateAndChange = 6,
    SwitchFloorPlan = 7,
    FloorPlanImage = 8,
    SettingsAndLayout = 9,
    Users = 10,
    RefreshCart = 11,
    ActivityTimeLine = 12,
    ServerStatistics = 13,
    
    SettingsFull = 100, //To handle in signalR
    SettingsFullAndChange= 101,
    SettingsAndLayoutAndChange = 102,
    LayoutAndChange = 103,
    SettingsAndChange = 104,
    RefreshPackage = 15,
    RefreshWristBand = 16
  }

  enum HttpVerbs {
    GET, POST, PUT, DELETE
  }


  enum UpdatedEntityName {
    SpecialMeals = 0,
    PredefinedContactNotes = 1,
    PredefinedPartyMessages = 2,
    PredefinedPartyNotes = 3,
    LayoutBackgroundImage = 4,
    SeatingAreas = 5,
    SeatingTypes = 6,
    Servers = 7,
    Statuses = 8,
    None = 9,
    Reservation = 10,
    Waitlist = 11,
    SeatingParties = 12,
    SeatingTypeSeatingAreaMappings = 13,
    GeneralSettings = 14,
    FloorPlan = 15
  }

  let message: MessageRequestDto = input;
  const entitytype: ChangedEntityType = message.Message.ChangedEntityType;
  const propertyId: number = message.Message.RestaurantId;
  console.log("SignalR message has been received by worker Service !")
  console.log(message.Message);

  if (message.CommonParams.RestaurantId == message.CommonParams.RestaurantId) {
    switch (message.Message.ChangedEntityType) { 
      case ChangedEntityType.State:
      case ChangedEntityType.DataRetentionPolicy:
        LoadRestaurantSettings();
        break;
      case ChangedEntityType.ChangeSet:
        GetChange();
        break;
      case ChangedEntityType.SwitchFloorPlan:
        LoadState();
        break;
      case ChangedEntityType.FloorPlanImage:
        LoadFloorPlanImage();
        break;
      case ChangedEntityType.SettingsAndLayout:
        LoadLayoutAndSettings();
        break; 
      case ChangedEntityType.Layout: 
       LoadLayout();
       break; 
      case ChangedEntityType.Settings:
        LoadSettings();
        break;
      case ChangedEntityType.ActivityTimeLine:
        LoadActivityBookings();
        break;
      case ChangedEntityType.RefreshCart:
        LoadCart();
        break;
      case ChangedEntityType.ServerStatistics:
        LoadServerStats();
        break;
      default:
        LoadRestaurantSettings();
        break;
    }
  }

  function PostMessage(change: any, updatedSettings: RestaurantDTO) {
    var response = {
      ChangeSet: change,
      UpdatedSettings: updatedSettings,
      ChangedEntityType: entitytype,
      PropertyId: propertyId
    };

    postMessage(response);
  }

  function GetChange() {
    let latestRevision = message.Message.Revision;
    if (message.LastRevision < latestRevision) {
      return Get(message.CommonParams.BaseUrl + "?restaurantid=" + message.CommonParams.RestaurantId + '&afterRevision=' + message.LastRevision + '&screenname=' + message.Screen, message, GetCallback);
    }
  }

  function LoadRestaurantSettings() {
    return Get(message.CommonParams.BaseUrl + message.CommonParams.RestaurantId + '&options=Full&languageId=' + message.CommonParams.LanguageId , message, GetSettingsCallback);
  }

  function LoadLayout() {
    return Get(message.CommonParams.BaseUrl + message.CommonParams.RestaurantId + '&options=Layout&languageId=' + message.CommonParams.LanguageId, message, GetSettingsCallback);
  }

  function LoadLayoutAndSettings() {
    return Get(message.CommonParams.BaseUrl + message.CommonParams.RestaurantId + '&options=SettingsAndLayout&languageId=' + message.CommonParams.LanguageId, message, GetLayoutAndSettingsCallback);
  }

  function LoadSettings() {
    return Get(message.CommonParams.BaseUrl + message.CommonParams.RestaurantId + '&options=Settings&languageId=' + message.CommonParams.LanguageId, message, GetSettingsCallback);
  }

  function LoadState() {
    return Get(message.CommonParams.BaseUrl + message.CommonParams.RestaurantId + '&options=State', message, GetStateCallback);
  }

  function LoadFloorPlanImage()
  {
    return Get(message.CommonParams.BaseUrl + message.CommonParams.RestaurantId, message, GetCallback);
  }

  function LoadActivityBookings()
  {
    return Get(message.CommonParams.BaseUrl + '?partytype='+message.CommonParams.PartyType+'&propertyid='
    +message.CommonParams.RestaurantId+'&startdate='+ message.CommonParams.StartDate+'&enddate='+message.CommonParams.EndDate+ '&options='+message.CommonParams.Options, message, GetCallback);
  }

  function LoadCart()
  {
    return Get(message.CommonParams.BaseUrl + '?hostid='+ Number(message.CommonParams.HostId) +'&merchantid='+message.CommonParams.MerchantId, message, GetCallback);
  }

  function LoadServerStats()
  {
    return Get(message.CommonParams.BaseUrl +  "?restaurantid=" + message.CommonParams.RestaurantId, message, GetCallback);
  }

  /****** CALLS GETCHANGE API */
  function Get(baseUrl: string, message: MessageRequestDto, GetCallback: any): any {
    return callWebApi(baseUrl, HttpVerbs.GET, message, GetCallback, null);
  }


  function GetCallback(data): void {
    console.log("Web worker is about to post message !");
    PostMessage(data, null);
  }

  function GetSettingsCallback(data: RestaurantDTO) {
    console.log("Web worker is about to post settings message !");
    PostMessage(null, data);
  }

  function GetStateCallback(data: StateDTO) {
    console.log("Web worker is about to post settings message !");
    let restDto: RestaurantDTO = {} as RestaurantDTO;
    restDto.State = data;
    PostMessage(null, restDto);
  }

  function GetLayoutAndSettingsCallback(data: RestaurantDTO) {
    console.log("Web worker is about to post layout and settings message !");
    PostMessage(null, data);
  }

  function callWebApi(url: string, verb: HttpVerbs, message: MessageRequestDto, callback: any, data: any): void {

    let xhr = new XMLHttpRequest();

    xhr.onload = function () {
      let data = JSON.parse(xhr.responseText);
      callback(data);
    }

    xhr.onerror = function () {
      alert("Error while calling Web API");
    }

    let httpVerb: string;
    switch (verb) {
      case HttpVerbs.GET:
        httpVerb = "GET";
        break;
      case HttpVerbs.POST:
        httpVerb = "POST";
        break;
      case HttpVerbs.PUT:
        httpVerb = "PUT";
        break;
      case HttpVerbs.DELETE:
        httpVerb = "DELETE";
        break;
    }

    xhr.open(httpVerb, url);
    xhr.setRequestHeader('x-ts-client-uid', message.CommonParams.ClientUid)
    xhr.setRequestHeader('x-ts-merchant-auth-key', message.CommonParams.MerchantKey)
    xhr.setRequestHeader('x-ts-restaurant-api-key', message.CommonParams.RestaurantApiKey)
    xhr.setRequestHeader('x-ts-host-id', message.CommonParams.HostId)
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("ngsw-bypass","");


    if (data == null) {
      xhr.send();
    }
    else {
      xhr.send(JSON.stringify(data));
    }
  }
}