import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { UserAlerts } from '../../../common/config/alerts-config';
import { RetailLocalization } from '../../../common/localization/retail-localization';
import * as _ from 'lodash';
import * as myGlobals from '../../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { ListOrder } from '../../../retail.modals';
import { AlertMessagePopupComponent } from '../../../shared/alert-message-popup/alert-message-popup.component';
import { RetailSetupService } from '../../../retail-setup/retail-setup.service';
import { PropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';

@Component({
  selector: 'app-sub-category-table',
  templateUrl: './sub-category-table.component.html',
  styleUrls: ['./sub-category-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubCategoryTableComponent implements OnInit {

  @Input() options;
  @Input() isRoleSetUpReadOnly;
  @Output() afterEditClose: EventEmitter<any> = new EventEmitter();
  @Output() dragDropEvt: EventEmitter<any> = new EventEmitter();
  @Output() deleteEvt: EventEmitter<any> = new EventEmitter();
  @Output() editEvt: EventEmitter<any> = new EventEmitter();
  @Output() InActiveTherapistEvt: EventEmitter<any> = new EventEmitter();
  @Output() RowSelectEmitter: EventEmitter<any> = new EventEmitter();
  @Output() userActionEvt: EventEmitter<any> = new EventEmitter();
  @Output() CurrentRowSelectedEmitter: EventEmitter<any> = new EventEmitter();
  @Output() toggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() addClick: EventEmitter<any> = new EventEmitter();
  @Output() dropDownChange: EventEmitter<any> = new EventEmitter();
  @Output() printEvt: EventEmitter<any> = new EventEmitter();
  @Output() inactiveToggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() doneEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() LBLClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() radioClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openWaitlist: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('tableInput') tableInput: ElementRef;
  @Input() searchPipeText:any;
  @Input() editBool:boolean = false;
  @Input() isEditModeSet:boolean;
  SelectedData: any = [];
  sortingColoumn: any;
  setinterscroll: any;
  editEvent: any;
  InActiveTherapistChkBoxEvt: any;
  orderTypearr: any = [];
  orderType: any = 'asc';
  hdrArray: any = [];
  bodyArray: any = [];
  selectedDefaultHeader: any;
  searchText: any;
  hdrkeyArray: any = [];
  originalHdrKeyArray: any = [];
  Sortable: any;
  highlight: any;
  NewerData: any;
  currentPage: any = 1;
  givenOrderType: any;
  EditMoreOption: any;
  defaulSortColumn: any;
  dropdownOptions: any = []
  captions: any = {};
  disableDelete: any;
  toggleDisplayText: any;
  editableRow: any;
  tempTableData: any;
  editRecordsArray: any = [];
  doneCancelDetails: any;

  SelectRow: boolean = false;
  enableToggleButton: boolean = false;
  IfBooleanCheck: boolean = false;
  SetColumnGridValue: boolean = false;
  CheckInnerBoolValue: boolean = false;
  enableRowCheck: boolean = false;
  IsViewOnly: boolean = false;
  IsCheckAll: boolean = false;
  EnableActions: boolean;
  EnablePagination: boolean = true;
  ChkInactiveService: boolean = false;
  blnDraggable: boolean = true;
  editable: boolean = true;
  waitlistIcon: boolean = false;
  customHeader: boolean = false;
  sticky: boolean = false;
  DoneCancel: boolean = false;
  viewCheckedFlag: boolean = false;
  IsRetailCodeSetup: boolean;
  disableEditButton: boolean; //this boolean will prevent certain screens to show Edit button during view only break point applied.
  isValidRoleName: boolean = false;
  isEdit: boolean;
  setId:any;
  isReadOnly: boolean = false;
  isCancelAvailable: boolean;
  SelectedSettingId: number = 1;
  inputLength: number;

  PlaceHoldertext: string;
  overriddenSortColumn: string;
  sortColumnDataType: string;
  pageTitle: string;
  customHeaderButton: string;
  setupName: string;
  errorMessage: string;

  outlets: UntypedFormGroup;
  quicksale: UntypedFormGroup;
  measures: UntypedFormGroup;
  table: UntypedFormGroup;
  formGroupName: UntypedFormGroup;
  @Input() completeTable:any

  GridType = myGlobals.GridType;
  RetailTransactions = myGlobals.RetailTransactions;
  edittedIndex: any;
  floatLabel: string;
  @Input() throttleTime: number = 3000;



  constructor(public dialog: MatDialog, private userAlerts: UserAlerts, public el: ElementRef, public fb: UntypedFormBuilder, public localization: RetailLocalization, private _cdRef: ChangeDetectorRef, private retailService: RetailSetupService,
    private _PropertySettingDataService : PropertySettingDataService) {
    this.table = this.fb.group({
      IsCheckAll: false,
      tablebody: this.fb.array([this.fb.group({ id: '', activetoggle: false, donecancel: false, category: "" })])
    });
    this.outlets = this.fb.group({
      setupCodeName: ['', Validators.required],
      activetoggle: [false]
    });
    this.measures = this.fb.group({
      setupCodeName: ['', Validators.required],
      activetoggle: [false]
    });
    this.quicksale = this.fb.group({
      setupCodeName: ['', Validators.required],
      activetoggle: [false]
    });
    this.captions = this.localization.captions;
    this.customHeaderButton = this.localization.captions.setting.Add;
    this.floatLabel = this.localization.setFloatLabel;
    this.enableCancelButton();
  }

  ngOnChanges() {
    this.editRecordsArray = [];
    this.viewCheckedFlag = false;
    this.table.value.tablebody = this.CreateTablerowFormGroup(this.options);
    this.tempTableData = _.cloneDeep(this.options);
    this.IsCheckAll = false;
    if (typeof this.options == "undefined") { return; }
    this.orderTypearr = [];
    this.defaulSortColumn = this.options[0].Sortable;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = (this.selectedDefaultHeader && !this.options[0].isInitial) ? this.selectedDefaultHeader : temp;
    this.isReadOnly = this.options[0].IsReadOnly ? true : false;
    this.selectedDefaultHeader = this.Sortable;
    this.SelectRow = this.options[0].SelectRows;
    this.DoneCancel = this.options[0].DoneCancel;
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    this.blnDraggable = this.options[0].TableDraggable;
    this.editable = this.options[0].Editable != undefined ? this.options[0].Editable : true;
    this.waitlistIcon = this.options[0].waitlistIcon != undefined ? this.options[0].waitlistIcon : false;
    this.SelectedSettingId = this.options[0].SelectedSettingId;
    this.sticky = this.options[0].sticky ? this.options[0].sticky : false;
    this.hdrArray = this.options[0].TableHdrData;
    this.originalHdrKeyArray = this.options[0].TableHdrData;
    this.bodyArray = [];
    this.bodyArray = this.options[0].TablebodyData;
    this.SelectedData = [];
    //Set Checked items on load - based on the checked field in body data
    this.bodyArray.forEach(element => {
      if (element.checked != null && element.checked )
        this.SelectedData.push(element);
    });
    this.NewerData = this.options[0].NewData;
    this.NewerData = this.bodyArray.filter(o => {
      if (this.NewerData) {
        return ((o.code && o.code == this.NewerData.code) || (o.name && o.name == this.NewerData.name) || (o.addOnName && o.addOnName == this.NewerData.addOnName))
      }
    })
    if (this.NewerData[0]) {
      this.setinterscroll = setInterval(() => {
        const tempSet = (this.NewerData[0] ? this.NewerData[0].id : '');
        let curiddata = this.NewerData ? tempSet : '';
        this.autoscrolltocurrtime(curiddata);
      }, 500);

    }
    this.searchText = this.options[0].TableSearchText ? this.options[0].TableSearchText : this.searchText;
    this.hdrkeyArray = [];
    if (this.hdrArray) {
      for (let item of this.hdrArray) {
        if (item.searchable != undefined ? item.searchable : true)
          this.hdrkeyArray.push(item.jsonkey);
      }
    }
    this.PlaceHoldertext = this.options[0].PlaceHoldertext;
    this.EnableActions = this.options[0].EnableActions;
    this.disableDelete = this.options[0].disableDelete;
    this.EnablePagination = this.options[0].pagination;
    this.ChkInactiveService = this.options[0].InactiveService;
    this.EditMoreOption = this.options[0].EditMoreOption;
    this.customHeader = this.options[0].customHeader;
    this.pageTitle = this.options[0].pageTitle;
    this.setCodeSetupValues();
    this.dropdownOptions = this.options[0].dropdownOptions;

    // Load Only active services
    if (this.ChkInactiveService)
      this.InactiveService({ checked: false });
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    let overriddenSortColumn = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumn : null;
    let SortColumnDataType = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumndatatype : null;
    this.sortingFunc(this.selectedDefaultHeader, this.sortingColoumn, 'change', overriddenSortColumn, SortColumnDataType);
    this.table.controls['IsCheckAll'].setValue(this.bodyArray && this.bodyArray.length > 0 && (_.difference(this.bodyArray, this.SelectedData).length === 0));
    
    this.IsViewOnly = this.options[0].IsViewOnly;
    this.disableEditButton = this.options[0].disableEditButton;
    this.IsRetailCodeSetup = (this.options[0].ServiceId == 'quicksale' || this.options[0].ServiceId == 'measures' || this.options[0].ServiceId == 'outlets' || this.options[0].ServiceId == 'roleSetup');
    this.calwidthwithtimeout();
  }

  ngOnInit() {
    if (typeof this.options == "undefined") { return; }
  
    this.defaulSortColumn = this.options[0].sortable;
    this.setId = this.options[0].setId;
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = this.selectedDefaultHeader ? this.selectedDefaultHeader : temp;
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    this.selectedDefaultHeader = this.Sortable;
    let overriddenSortColumn = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumn : null;
    let SortColumnDataType = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumndatatype : null;
    this.orderTypearr = [];
    this.inputLength = 250;
    this.sortingFunc(this.selectedDefaultHeader, this.sortingColoumn, 'onInit', overriddenSortColumn, SortColumnDataType);
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.RetailSubCategories).view;
  }

  ngAfterViewInit() {
    this.calwidthwithtimeout();
    this._cdRef.detectChanges();
  }

  ngAfterViewChecked() {
    if (!this.viewCheckedFlag) {
      this.viewCheckedFlag = true;
      this.calwidthwithtimeout();
    }
    this._cdRef.detectChanges();
  }

  dragTableId: any;
  dropTableId: any;
  onDragOver(event, ele, newarr) {
    event.preventDefault(); 
  }

  onDrag(event, ele, newArr) {
    {
      this.dragTableId = ele.level;
    }

  }
  onItemDrop(event, ele, newarr) { 
    let draggeddata = event.dragData;
    this.dropTableId = event.nativeEvent.currentTarget.offsetParent.id;

    let droppeddata = ele;
    if (draggeddata.level == ele.level) {
      let dragindex = newarr.indexOf(draggeddata);
      let dropindex = newarr.indexOf(droppeddata);
      let dragLstOrder: ListOrder = draggeddata;
      let dropLstOrder: ListOrder = droppeddata;
      this.dragDropEvt.emit([dragLstOrder.listOrder, dropLstOrder.listOrder, this.SelectedSettingId, this.InActiveTherapistChkBoxEvt, draggeddata.level]);
      newarr.splice(dragindex, 1);
      newarr.splice(dropindex, 0, draggeddata);
    }
    else {
      console.log("drag", event.dragData.level);
      console.log("drop",this.dropTableId);
    }
  }

  allowDrop(event, ele, newarr) {
    let draggeddata = event.dragData;
    let dragindex = newarr.indexOf(draggeddata);
    newarr.splice(dragindex, 1);
  }

  checkValid(event) {
    if (event && event.target && event.target.value.trim() !== '') {
      this.isValidRoleName = true;
    }
    else {
      this.isValidRoleName = false;
    }
  }

  searchInputValue(event) {
    this.searchText = event;
    this.viewCheckedFlag = false;
  }

  CreateTablerowFormGroup(optionsData) {
    let bodyArr: any = this.table.get('tablebody') as UntypedFormArray;
    bodyArr.controls = [];
    bodyArr.value = [];

    if (optionsData) {
      for (let data of optionsData[0].TablebodyData) {
        let optionsValue =data;
        const tempActiveToggele = (optionsValue.active ? optionsValue.active : false);
        bodyArr.push(this.fb.group({
          id: optionsValue.id,
          activetoggle: optionsValue.isActive ? (optionsValue.isActive) : tempActiveToggele,
          donecancel: this.fb.control(false),
          category: this.fb.control(optionsValue.category),
          rowDisabled: this.editEvent && this.editEvent.id == optionsValue.id ? this.editEvent.rowDisabled : false
        }))
      }
    }

    if(this.doneCancelDetails) {
      let bodyFormControl = bodyArr.controls[this.doneCancelDetails.index] as UntypedFormGroup;
      bodyFormControl.controls['donecancel'].setValue(this.doneCancelDetails.doneCancel);
    }
    return bodyArr;
  }

  setMatformWidth(myElement) {
    if (this.tableInput) {
      let minWidth = myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0] ? myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0]['offsetWidth'] : 300; //min-300 max-470
      minWidth += myElement.getElementsByClassName('search-container')[0] ? myElement.getElementsByClassName('search-container')[0]['offsetWidth'] : 0;
      minWidth += myElement.getElementsByClassName('table-toggle-switches')[0] ? myElement.getElementsByClassName('table-toggle-switches')[0]['offsetWidth'] : 0;
      minWidth += myElement.getElementsByClassName('custom-retail-inputs')[0] ? myElement.getElementsByClassName('custom-retail-inputs')[0]['offsetWidth'] : 0;
      return minWidth;
    }
  }

  calculateWidth() {
    Array.from(document.querySelectorAll('#SPACustomTable>table')).forEach((table, index) => {
      if (table) {
        let tableHeight = table['offsetHeight'];
        let parentHeight = table.closest("#sub-category-table-container")['offsetHeight'];
  
            table.closest("#sub-category-table-container")['style']['height'] = tableHeight + 2 + 'px';
            table.closest("#SPACustomTable")['style']['height'] = tableHeight + 2 + 'px';
            table.closest("#sub-category-table-container")['style']['height'] = tableHeight + 2 + 'px';
            table.closest("#sub-category-table-container")['style']['transition'] = '0.5s';
            table.closest(".fixed-table-container")['style']['height'] = tableHeight + 2 + 'px';
            table.closest("#SPACustomTable")['style']['height'] = tableHeight + 2 + 'px';
            table.closest(".fixed-table-container")['style']['height'] = tableHeight + 2 + 'px';
            table.closest(".fixed-table-container")['style']['transition'] = '0.5s';
 

      }
    })
    let searchClass = document.getElementsByClassName('retail-CustomDataTable');
    for (let i = 0; i < searchClass.length; i++) {
      let pageHeader = searchClass[i].getElementsByClassName('page-header')[0] ? searchClass[i].getElementsByClassName('page-header')[0]['offsetWidth'] : 0;
      let searchInput = searchClass[i].getElementsByClassName('searchpt')[0];
      if (pageHeader > 0) {
        pageHeader = pageHeader - this.setMatformWidth(searchClass[i]) - 60;
      }
      let inputLength = this.tableInput ? this.tableInput.nativeElement.getAttribute('data-placeholder').length : 1;
      let inputWidth = inputLength <= 30 ? inputLength * 10 : inputLength * 7.5 + 20;
      if (searchInput && pageHeader > 0) {
        searchInput['style'].width = (pageHeader > inputWidth) ? inputWidth + 'px' : pageHeader + 'px';
      }
    }
  }

  setCodeSetupValues() {
    let retailsetup = this.captions.retailsetup;
    switch (this.pageTitle) {
      case 'outlets':
        this.assignSetupValues(this.outlets, retailsetup.OutletName, retailsetup.MissingOutletName);
        break;
      case 'quicksale':
        this.assignSetupValues(this.quicksale, retailsetup.QuickSaleCategory, retailsetup.MissingQuickSaleCategory);
        break;
      case 'measures':
        this.assignSetupValues(this.measures, retailsetup.UnitName, retailsetup.MissingUnitName);
        break;
    }
  }

  assignSetupValues(formName: UntypedFormGroup, setupName: string, errorMessage: string) {
    this.formGroupName = formName;
    this.setupName = setupName;
    this.errorMessage = errorMessage;
  }

  customtablealignment() {
    let dropdown: any = [];
    let groupArray: any = {
      "outlets": {
        "outletName": "25",
        "terminalId": "25",
        "active": "25",
        "outletNumber": "25"
      },
      "quicksale": {
        "checkbox": "0",
        "desc": "30",
        "itemid": "20",
        "temp": "10",
        "category": "15"
      },
      "retail": {
        "itemId": "10",
        "itemDescription": "30",
        "category": "10",
        "SalesPrice": "10",
        "dummy": "10",
        "Inactive": "10",
      },
      "subCategory":{
        "subCategory": "30",
        "ListOrder":'30',
        "active":"30"
      }
    };
    switch (this.SelectedSettingId) {
      case myGlobals.GridType.outlet:
        dropdown = (Object.values(groupArray.outlets));
        break;
      case myGlobals.GridType.retail:
        dropdown = (Object.values(groupArray.retail));
        break;
      case myGlobals.GridType.quickSale:
        dropdown = (Object.values(groupArray.quicksale));
        break;
        case myGlobals.GridType.subCategory: 
        break;

    } 
    let coloumncount = document.getElementsByTagName('th').length;
    let overallgivenwidth = 0;
    let ga;
    if (dropdown) {
      let parentWidth = document.getElementById('SPACustomTable') && document.getElementById('SPACustomTable').offsetWidth;
      for (ga = 0; ga < dropdown.length; ga++) {
        if (document.getElementsByTagName('th')[ga]) {
          document.getElementsByTagName('th')[ga].style.width = Object.values(dropdown)[ga] + '%';
        }
        overallgivenwidth += Number(dropdown[ga]);
      }
      if (dropdown && this.bodyArray.length > 0) {
        let tablerow = document.getElementsByTagName('tr');
        for (let i = 1; i <= this.bodyArray.length; i++) {
          if (tablerow[i]) {
            for (let j = 0; j < dropdown.length; j++) {
              if (document.getElementsByTagName('tr')[i].cells) {
                if (document.getElementsByTagName('tr')[i].cells[j].querySelectorAll("#content").length > 0) {
                  document.getElementsByTagName('tr')[i].cells[j].querySelectorAll("#content")[0]['style'].maxWidth = parentWidth * (Number(Object.values(dropdown)[j]) / 100) + 'px';
                }
              }
            }
          }
        }
      }
      for (let remCol = ga; remCol < coloumncount; remCol++) {
        if (document.getElementsByTagName('th')[coloumncount - 2] && document.getElementsByTagName('td')[ga]) {
          let width = (92 - overallgivenwidth) + '%';
          let maxWidth = parentWidth * (Number((92 - overallgivenwidth)) / 100) + 'px';
          this.setWidth(coloumncount, 2, width, maxWidth);
          if (this.EditMoreOption ) {
            this.setWidth(coloumncount, 1, '2%', '100px');
          }
          else {
            this.setWidth(coloumncount, 1, '3%', '150px');
          }
        }
      }
    }
  }

  setWidth(coloumncount, count, width, maxwidth) {
    document.getElementsByTagName('th')[coloumncount - count].style.width = width;
    document.getElementsByTagName('td')[coloumncount - count].style.maxWidth = maxwidth;
  }

  showInactiveRoles(event, rowData, index) {
    this.toggleEvtEmitter.emit(event);
    this.edittedIndex = index;
    this.editRecordsArray.push({ index: index, data: rowData });
    let doneCancelCount = _.reduce(this.table.value.tablebody, (acc, val) => {
      acc = val.donecancel ? (acc + 1) : acc;
      return acc;
    }, 0);
    let doneCancelRecords: any = _.filter(this.table.value.tablebody, (data) => {
      return data.donecancel;
    });
    let editRecordList = document.getElementsByClassName("rowDisabled");
    
    if (editRecordList.length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
      this.resetForm();
      this.enableToggleButton = false;
      this.enableDoneCancel(index, rowData);
    }
    else {
      this.enableDoneCancel(index, rowData);
    }
  }

  resetForm() {
    this.outlets.reset();
    this.measures.reset();
    this.quicksale.reset();
    this.customHeaderButton = this.localization.captions.setting.Add;
    this.enableCancelButton();
  }

  enableDoneCancel(index, rowData) {
    //This is required to preserve the donecancel state, when there is change in input from parent.
    this.doneCancelDetails = {index: index, rowData: rowData, doneCancel: true};
    this.table.value.tablebody[index].donecancel = true;
    let data = { 'value': event, 'data': rowData }
    this.inactiveToggleEvtEmitter.emit(data);
    this.isValidRoleName = true;
  }

  Done(rowData, index) {
    this.editRecordsArray = [];
    this.enableToggleButton = false;
    this.editEvent = {};
    this.doneCancelDetails = {index: index, rowData: rowData, doneCancel: false};
    this.table.value.tablebody[index].donecancel = false;
    this.doneEvtEmitter.emit(rowData);
    this.resetForm();
  }

  lblclick(rowData, index, clickable) {
    if (clickable == 'clickable') {
      this.LBLClickEvtEmitter.emit(rowData);
      return;
    } else {
      return false;
    }
  }

  Cancel(rowData, index, setId?) {
    let enableToggle;
    this.doneCancelDetails = {index: index, rowData: rowData, doneCancel: false};
    this.table.value.tablebody[index].donecancel = false;
    _.forEach(this.tempTableData[0].TablebodyData, (data) => {
      if (data.id == rowData.id) {
        enableToggle = data.hasOwnProperty('isActive') ? data.isActive : data.active;
      }
    });
    for(var i=0;i<this.completeTable.length;i++){
      if(this.completeTable[i].id == this.setId){
        this.completeTable[i].levels.forEach(element => {
          if(element.id == rowData.id){
            if (element.hasOwnProperty('isActive')) {
            element.isActive = !enableToggle;
          }
          else{
            element.active = !enableToggle;
          }
        }
        });
      }
    }
    if (this.options[0].TablebodyData[index].hasOwnProperty('isActive')) {
      this.options[0].TablebodyData[index].isActive = !enableToggle;
    }
    else {
      this.options[0].TablebodyData[index].active = !enableToggle;
    }
    this.table.value.tablebody[index].activetoggle = enableToggle;
    this.table.value.tablebody[index].donecancel = false;
    this.doneEvtEmitter.emit(rowData);
  }

  onButtonClick() {
    let belonTo = this.options[0].ServiceId;
    let type = this.customHeaderButton;
    this.editEvent = {};
    this.editRecordsArray = [];
    let data = {}
    switch (belonTo) {
      case 'outlets': data = { 'value': this.outlets, 'type': type }
        break;
      case 'measures': data = { 'value': this.measures, 'type': type }
        break;
      case 'quicksale': data = { 'value': this.quicksale, 'type': type }
        break;
      default: data = { 'value': '', 'type': type }
    }
    this.enableToggleButton = false;
    this.addClick.emit(data);
    /*Reset Form values to Default*/
    this.resetForm();
  }

  onButtonCancelClick(event) {
    if (document.getElementsByClassName('rowDisabled').length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
    }
    this.enableToggleButton = false;
    this.resetForm();
  }

  RowSelect(event, SelectedRow, Frm) {
    if (Frm == 'All') {
      if (event.checked ) {
        this.SelectedData = [];
        for (let item of SelectedRow) {
          this.SelectedData.push(item);
        }
      }
      else {
        this.SelectedData = _.difference(this.SelectedData, this.bodyArray);
      }
    }
    else {
      if (this.SelectedData.indexOf(SelectedRow) == -1) {
        this.SelectedData.push(SelectedRow);
      } else {
        this.SelectedData.splice(this.SelectedData.indexOf(SelectedRow), 1);
        let checkedRow = this.bodyArray.findIndex(item => item.id == SelectedRow.id);
        this.bodyArray[checkedRow].checked = false;
      }
      this.table.controls['IsCheckAll'].setValue(this.bodyArray && this.bodyArray.length > 0 && (_.difference(this.bodyArray, this.SelectedData).length === 0));
    }
    this.RowSelectEmitter.emit(this.SelectedData);
    this.CurrentRowSelectedEmitter.emit({ "event": event, "SelectedRow": SelectedRow, "From": Frm });
  }

  InactiveService(e) {
    if (e.checked) {
      this.bodyArray = [];
      this.bodyArray = this.options[0].TablebodyData;
    } else {
      let tempArr = [];
      this.bodyArray.forEach(row => {
        if (row.isActive)
          tempArr.push(row);
      });
      this.bodyArray = tempArr;
    }
  }

  openAlertPopup() {
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.captions.common.Warning, headerIcon: 'icon-warning-icon', headerMessage: this.captions.common.saveChangesMessage, buttonName: this.captions.common.Yes, noButton: true, noButtonName: this.captions.common.No, type: 'message'
      },
      disableClose: true,
    }); 
  }

  sortingFunc(dh, i, from, overriddenSortColumn, sortColumnDataType) {
    if (!this.isEditModeSet) {
      if (this.editRecordsArray.length > 0) {
        let dialogRef = this.openAlertPopup();
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'Yes') {
            this.Cancel(this.editRecordsArray[0].data, this.editRecordsArray[0].index);
            this.editRecordsArray = [];
            this.sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from);
          }
        });
      }
      else {
        this.sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from);
      }
    }
  }

  sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from) {
    this.selectedDefaultHeader = dh;
    this.overriddenSortColumn = overriddenSortColumn;
    this.sortColumnDataType = sortColumnDataType;
    if (!this.options[0].isInitial && this.defaulSortColumn == this.selectedDefaultHeader && from == "change") {
      if (this.orderTypearr.length > 0) {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
      }
      else {
        this.orderTypearr.push(dh);
      }
    }
    else {
      if (this.givenOrderType == 'desc') {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.givenOrderType = '';
      } else {
        this.givenOrderType = '';
      }
      if (this.orderTypearr.indexOf(dh) == -1) {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.orderType = 'asc';
      } else {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
        this.orderType = 'desc';
      }
    } 
  }
 

  async DeleteRecords(e) {
    let miscSetting = await this._PropertySettingDataService.GetMiscConfigurationSetting()
    if(miscSetting && miscSetting.length > 0){
      var enableDeleteConfirmation = miscSetting.filter(x => x.switch == 'ENABLE_CONFIRMATION_ON_DELETE');
      if(enableDeleteConfirmation && enableDeleteConfirmation[0] && enableDeleteConfirmation[0].value.toString().toLowerCase() === 'true'){
        this.userAlerts.showPrompt(myGlobals.PromptType.Prompt, this.onAlertClose.bind(this), e);
      }
      else {
        this.deleteEvt.emit(e);
      }
    }
    else{
      this.deleteEvt.emit(e);
    }
  }
  onAlertClose(res, e) {
    if (res.toLowerCase() == "yes") {
      this.deleteEvt.emit(e);
    } 
  }

  PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == "ok") {
      this.deleteEvt.emit([extraParams, this.options[0].ServiceId, this.InActiveTherapistChkBoxEvt]);
      this.givenOrderType = this.orderType;
    }
  }

  autoscrolltocurrtime(curid) {
    if (document.getElementsByClassName('highlight').length > 0) {
      let curRow = curid.toString();
      let elms = document.getElementById(curRow);
      if (elms) {
        let scrolltoPos = (document.getElementById(curRow).offsetTop);
        let thPos = (document.getElementById('SPAHeaderRow').offsetHeight);
        document.getElementById("SPACustomTable").scrollTop = scrolltoPos - thPos;
        clearInterval(this.setinterscroll);
      }
    }
  }

  openAlertDialog = (editableRecord, currentRecord, eventType, index, event, cancelCount) => {
    let dialogRef = this.openAlertPopup();
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        if (document.getElementsByClassName("rowDisabled").length > 0) {
          document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
          document.getElementsByClassName("rowDisabled")[0].classList.remove("rowDisabled");
        }
        if (cancelCount > 0) {
          let recordArray = this.editRecordsArray;
          recordArray.length == 1 ? this.Cancel(recordArray[0].data, recordArray[0].index) : this.Cancel(recordArray[recordArray.length - 2].data, recordArray[recordArray.length - 2].index)
        }
        if (eventType == 'toggle') {
          this.table.value.tablebody[index].donecancel = true;
          _.forEach(this.table.value.tablebody, (res, i) => {
            if (res.donecancel && res.id != this.table.value.tablebody[index].id) {
              this.table.value.tablebody[i].donecancel = !this.table.value.tablebody[i].donecancel;
            }
          })
          let data = { 'value': event, 'data': currentRecord }
          this.inactiveToggleEvtEmitter.emit(data);
          this.enableToggleButton = false;
          this.resetForm();
        }
        else {
          this.editEvent = { id: currentRecord.id, rowDisabled: true };
          _.forEach(this.table.value.tablebody, (data, i) => {
            if (data.donecancel) {
              data.donecancel = !data.donecancel;
            }
          });
          this.editRow(event, currentRecord, 'edit', this.options[0].ServiceId)
        }
      }
      else {
        if (this.editRecordsArray.length > 0) this.editRecordsArray.pop();
        if (eventType == 'toggle') {
          this.Cancel(currentRecord, index)
        }
      }
    })
  }

  editRow(event, e, type, belonTo, index?) {
    /*Updated Edit for Retail*/
    let doneCancelCount = _.reduce(this.table.value.tablebody, (acc, val) => {
      acc = val.donecancel ? (acc + 1) : acc;
      return acc;
    }, 0);
    let doneCancelRecords: any = _.filter(this.table.value.tablebody, (data) => {
      return data.donecancel
    });
    if (doneCancelCount > 0 && this.editRecordsArray.length > 0) {
      let lastRecord = this.editRecordsArray.pop();
      this.editRecordsArray = [];
      this.editRecordsArray.push(lastRecord);
    }
    else {
      this.editRecordsArray = [];
    }
    let editRecordList = document.getElementsByClassName("rowDisabled");
    if ((editRecordList.length > 0 || doneCancelCount > 0) && this.isValidRoleName) {
      let editableRecord = editRecordList.length > 0 ? _.filter(this.options[0].TablebodyData, data => data.id == editRecordList[0].id) : _.filter(this.options[0].TablebodyData, data => data.id == doneCancelRecords[0].id);
      let currentRecord = e;
      this.openAlertDialog(editableRecord, currentRecord, 'edit', index, event, doneCancelCount);
    } else {
      if (this.customHeader) {
        this.customHeaderButton = this.localization.captions.setting.UPDATE;
        this.enableToggleButton = true;
        switch (belonTo) {
          case 'outlets':
            this.setCustomHeaderValues(this.outlets, e.outletName, e.isActive, event);
            break;
          case 'measures':
            this.setCustomHeaderValues(this.measures, e.measuringUnit, e.active, event);
            break;
          case 'quicksale':
            this.setCustomHeaderValues(this.quicksale, e.quickSaleCategory, e.isActive, event);
            break;
        }
        if (typeof event == 'object') {
          event.target.parentElement.parentElement.classList.toggle("rowDisabled");
          if (document.getElementsByClassName("rowDisabled").length > 1) {
            let classList = document.getElementsByClassName("rowDisabled");
            for (let i = 0; i < classList.length; i++) {
              if (classList[i].id != e.id) {
                document.getElementsByClassName("rowDisabled")[i].classList.remove("highlight");
                document.getElementsByClassName("rowDisabled")[i].classList.remove("rowDisabled");
              }
            }
          }
        }
        this.EditRecords(e, type, index);
      }
      else {
        if (document.getElementsByClassName("highlight").length > 0) {
          document.getElementsByClassName("highlight")[0].classList.remove("highlight");
        }
        event.target.parentElement.parentElement.classList.add("highlight");
        this.EditRecords(e, type, index);
      }
    }
    this.enableCancelButton();
  }

  setCustomHeaderValues(setupFormGroupName: UntypedFormGroup, codeName: any, isActive: boolean, event: any) {
    setupFormGroupName.controls['setupCodeName'].setValue(codeName);
    setupFormGroupName.controls['activetoggle'].setValue(isActive);
    this.isValidRoleName = false;
    if (typeof event == 'object' && event.target.parentElement.parentElement.classList.contains("rowDisabled")) {
      setupFormGroupName.controls['setupCodeName'].setValue("");
      setupFormGroupName.controls['activetoggle'].setValue(false);
      this.customHeaderButton = this.localization.captions.setting.Add;
    }
  }

  enableCancelButton() {
    this.isCancelAvailable = this.customHeaderButton.toLowerCase() == this.localization.captions.setting.UPDATE.toLowerCase();
  }

  EditRecords(e, type, index?) {
    if (this.editEvt)
      if (this.SelectedSettingId == myGlobals.GridType.commission) {
        this.editEvt.emit([e, type, index]);
      } else {
        this.editEvt.emit([e, this.options[0].ServiceId, type]);
        this.givenOrderType = this.orderType;
      }
  }

  optionChange(e, belonTo, data) {
    // Needs to be moved to Retail table component
    if (this.GridType.quickSale == this.SelectedSettingId && e.value == 0) {
      this.SelectedData = this.SelectedData.filter(r => { return r.id != data.id });
    }
    this.dropDownChange.emit([e, belonTo, data])
  }

  calwidthwithtimeout() {
    setTimeout(() => {
      this.calculateWidth();
    }, 1);
  }

  clearSearchText() {
    this.searchText = "";
  }
}
