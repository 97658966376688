import { Injectable, ViewContainerRef, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CacheService } from '@app/core/services/cache.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { Subscription } from 'rxjs';
import { CartBookingSuccessComponent } from '../components/cart-booking-success/cart-booking-success.component';
import { WristbandMappingContainerComponent } from '../components/wristband-mapping-container/wristband-mapping-container.component';
import { ICartGroupItem, IUnlockCart } from '../interfaces/request';
import { CartPaymentHandler } from '../pages/cart-page/cart-payment-handlers';
import { POSApiService } from './api.service';
import { getExpireTime, groupBy } from './util.functions';
import { WristbandDetailResponse } from '../pages/home-page/home-page.component';

@Injectable({
  providedIn: 'root'
})
export class CartService extends CartPaymentHandler {

  protected dialog = inject(MatDialog);
  protected router = inject(Router);

  protected apiService = inject(POSApiService);
  protected cacheService = inject(CacheService);

  subscriptions: Subscription = new Subscription();

  payableAmount: number = 0;
  cartGroupItems: ICartGroupItem[] | any[] | null = null;
  cartItems: any[] | null = [];

  paymentSplit: {
    tax: number;
    subTotal: number;
    ServiceCharge: number;
    TaxOnServiceChargeAmount: number;
  } | null = {
      tax: 0,
      subTotal: 0,
      ServiceCharge: 0,
      TaxOnServiceChargeAmount: 0
    }

  expireTimeWatch = new Subscription();
  cartLockState: {
    [key: string | number]: 'Expired' | 'Availalble'
  } = {};

  constructor() {
    super();
    this.subscriptions.add(this.cacheService.settings.subscribe(sett => {
      this._settings = sett;
      this.currency = this._settings.General.OperationCurrency
    }));
  }

  removeCartItem(cartIds: number[]) {
    this.apiService.removeFromCart(cartIds).subscribe({
      next: data => {
        this.setCartDetails();
      }
    })
  }

  unlockAndRemoveCartItems(unlockCartItems: IUnlockCart[], cartIds: number[]) {
    this.apiService.unlockCart(unlockCartItems).subscribe(() => this.removeCartItem(cartIds), (err) => {
      console.error("Unlock Cart item Failed");
      this.removeCartItem(cartIds)
    })
  }

  setCartDetails() {
    this.apiService.getCartItems().subscribe((data) => {
      this.cartGroupItems = [];
      this.cartItems = []
      this.cartLockState = {};
      this.payableAmount = 0;
      this.cartItems = data;
      let _cartGroupItem = data ? groupBy(data, "CartGroupName") : [];

      _cartGroupItem.forEach((cartItems: any) => {

        let _cartDetail = cartItems[0];
        let _cartGroupInfo = this.getDetailFromCartName(_cartDetail.CartGroupName);
        let _cartGroupPrice = this.getCartGroupPrice(cartItems);

        let _cartGroup: ICartGroupItem = {
          GroupName: _cartDetail.CartGroupName,
          Name: _cartGroupInfo.Name,
          PropertyId: _cartDetail.PropertyId,
          Id: _cartGroupInfo.Id,
          BookingDate: _cartDetail.CartItemDetail.Sessions.Sessions[0].Date,
          Size: _cartDetail.CartItemDetail.Size,
          // CoverTypes: _cartDetail.CartItemDetail.CoverTypes,
          Contact: _cartDetail.CartItemDetail.Contact,
          GuestList: [],
          ImageURL: '',
          PackageActivities: [],
          PackageInfo: null,
          TotalPrice: _cartGroupPrice.Total,
          Items: cartItems,
          ExpireTime: _cartDetail.CartItemDetail.SlotLockResultDTO.ExpiresAtUTC,
          Type: _cartGroupInfo.Type,
          ServiceCharge: _cartGroupPrice.ServiceCharge,
          Tax: _cartGroupPrice.Tax,
          SubTotal: _cartGroupPrice.SubTotal,
          TaxOnServiceChargeAmount: _cartGroupPrice.TaxOnServiceChargeAmount
        }

        this.payableAmount += _cartGroup.TotalPrice;

        this.paymentSplit.subTotal += _cartGroup.SubTotal;
        this.paymentSplit.tax += _cartGroup.Tax;
        this.paymentSplit.ServiceCharge += _cartGroup.ServiceCharge;
        this.paymentSplit.TaxOnServiceChargeAmount += _cartGroup.TaxOnServiceChargeAmount;

        this.cartGroupItems?.push(_cartGroup);
      })

      this.cartGroupItems.forEach((cartGroupItem: ICartGroupItem) => {
        let _checkExpiryTime: number = getExpireTime(cartGroupItem.ExpireTime);
        setTimeout(() => {
          this.checkCartExpireState(cartGroupItem.GroupName, cartGroupItem.ExpireTime)
        }, _checkExpiryTime);
      })

      this.calculateTotalPrice();
    }, error => {
      this.cartGroupItems = [];
      this.cartItems = []
      this.cartLockState = {};
    })
  }

  getDetailFromCartName(cartGroupName) {
    let [Type, Id, Name] = cartGroupName.split('::');
    return { Type, Id, Name }
  }

  calculateTotalPrice() {
    this.payableAmount = 0;
    this.paymentSplit = {
      ServiceCharge: 0,
      subTotal: 0,
      tax: 0,
      TaxOnServiceChargeAmount: 0
    }
    this.cartGroupItems.forEach((cartGroupItem) => {
      if (this.cartLockState[cartGroupItem.GroupName] !== 'Expired') {
        this.payableAmount += cartGroupItem.TotalPrice;
        this.paymentSplit.subTotal += cartGroupItem.SubTotal;
        this.paymentSplit.tax += cartGroupItem.Tax;
        this.paymentSplit.ServiceCharge += cartGroupItem.ServiceCharge;
        this.paymentSplit.TaxOnServiceChargeAmount += cartGroupItem.TaxOnServiceChargeAmount;
      }
    }, 0)
  }

  checkCartExpireState(cartGroupName: string, expireTime: string) {
    let _checkExpiryTime: number = getExpireTime(expireTime);;
    if (_checkExpiryTime <= 0) {
      this.cartLockState[cartGroupName] = 'Expired';
      this.calculateTotalPrice();
      return;
    }
    setTimeout(() => {
      this.checkCartExpireState(cartGroupName, expireTime)
    }, _checkExpiryTime);
  }

  getCartGroupPrice(cartItems): { Total, SubTotal, ServiceCharge, Tax, TaxOnServiceChargeAmount } {
    let Total = 0, SubTotal = 0, Tax = 0, ServiceCharge = 0, TaxOnServiceChargeAmount = 0;

    cartItems.forEach((cartItem) => {
      Total += +(cartItem.RatePlan.Total);
      SubTotal += +(cartItem.RatePlan.SubTotal);
      ServiceCharge += +(cartItem.RatePlan.ServiceCharge);
      Tax += +(cartItem.RatePlan.Tax);
      TaxOnServiceChargeAmount += +(cartItem.RatePlan.TaxOnServiceChargeAmount);
    }, 0)

    return { Total, SubTotal, ServiceCharge, Tax, TaxOnServiceChargeAmount }
  }


  showCartBookingSuccess(viewContainerRef: ViewContainerRef, inputData: { cartBookingId: number, wristbandURL: string, screen: 'POS' | 'MODERNCLIENT' }): MatDialogRef<CartBookingSuccessComponent> {
    let dialogRef = this.dialog.open(CartBookingSuccessComponent, {
      width: '800px',
      minHeight: '300px',
      viewContainerRef: viewContainerRef,
      data: {}
    })

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.apiService.getWristbandURL(inputData.cartBookingId, 'cardBookingId').subscribe(({ Payload: dataResponse }) => {        
          this.showWristbandContainer(viewContainerRef, {cartBookingId: inputData.cartBookingId, wristbandInputDetail: dataResponse, screen: 'POS'}, 'pos/home')
        })
      } else {
        this.router.navigate(['pos/home']);
      }
    })

    return dialogRef;
  }

  showWristbandContainer(viewContainerRef: ViewContainerRef, { cartBookingId, wristbandInputDetail, screen }: { cartBookingId: number, wristbandInputDetail: WristbandDetailResponse, screen: 'POS' | 'MODERNCLIENT' }, navigateURL: string) {

    let encodingEnabled = this._settings?.MerchantSettings?.EnableCardEncoding?.SettingValue?.toUpperCase() === "TRUE"
    let dialogRef = this.dialog.open(WristbandMappingContainerComponent, {
      width: '90%',
      height: '90%',
      minHeight: '90%',
      maxWidth:'90vw',
      panelClass: 'wristband-print-container',
      disableClose: true,
      viewContainerRef: viewContainerRef,
      data: { cartBookingId, wristbandInputDetail, screen, encodingEnabled }
    })

    dialogRef.afterClosed().subscribe(data => {
      if(navigateURL) this.router.navigate([navigateURL]);
    })
  }

  showMessage(msg) {
    const title = this.ts.instant('alert');
    Utilities.showSettingsChangedInfo(msg, title, this.dialog);
  }

}


