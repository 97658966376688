<div class="page-preference-type">
  <div class="chip-control__label seat-tertiary-text"> {{'preferredCommunication' | translate}}</div>
  <dynamic-form [config]="pagingTypeConfig" #pagingType></dynamic-form>
</div>
<div class="alert alert-warning mb-0" role="alert" *ngIf="selectedNotification == preferenceType.ReservationPageMethod && preferenceNote">
  <span >{{preferenceNote}}</span>
</div>
<div [hidden]="!showPreferenceList">
  <dynamic-form [config]="communicationPrefConfig" #communicationPrefConfigForm>
  </dynamic-form>
</div>