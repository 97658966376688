<div class="image-library">
  <div class="image-library__gallery">
    <div class="image-library__bg-image" >
      <label class="seat-tertiary-text" *ngIf="isLayout">{{ 'backgroundImage' | translate}}</label>
      <label class="seat-tertiary-text" *ngIf="!isLayout"> {{'customObjects' | translate}}</label>
    </div>
    <div class="image-library__actions">
      <span class="image-library__gallery-actions seat-secondary-text" (click)="editGallery()"
        *ngIf="!isEditGallery">{{'serverEditText' | translate}}</span>
      <div class="image-library__actions-group">
        <label class="image-library__gallery-actions seat-secondary-text rs-pl-5" *ngIf="isEditGallery"
          (click)="cancel()">{{ 'closeText' | translate}}</label>
        <div class="image-library__upload-and-delete">
          <label class="image-library__gallery-actions seat-secondary-text"
            [ngClass]="{'image-library__add-disabled': fs?.selectedImageIds?.length > 0}" *ngIf="isEditGallery"
            for="add-images">{{'addText' | translate}}</label>
          <input style="display: none;" multiple type="file" [accept]="availableFileExtensions" id="add-images"
            (change)="selectFile($event)">
          <label class="image-library__gallery-actions image-library__delete seat-secondary-text" *ngIf="isEditGallery"
            (click)="deleteImages()"
            [ngClass]="{'image-library__add-disabled': fs?.selectedImageIds?.length == 0}">{{'deleteText' |
            translate}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="image-library__display" [ngClass]="{'image-library__layout-bg-height': !isLayout}">
    <!-- <ng-scrollbar> -->
      <div class="image-library__images">
        <div class="image-library__display-images" *ngIf="!isEditGallery && isLayout">
          <img src="assets/images/default-image.svg" alt=""
            class="image-library__uploaded-image seat-border-color" (click)="applyImage({ImageId: null, bytes: ''})">
          <svg *ngIf="isDefaultImage" class="checkmark image-library__selected-tick" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        </div>
        <div class="image-library__display-images" *ngFor="let image of displayImages; let i = index">
          <div>
            <img [src]="'data:image/png;base64,'+image.bytes" alt="" (click)="applyImage(image)"
              class="image-library__uploaded-image seat-border-color" [ngClass]="{'image-library__custom-object': !isLayout}">
            <svg *ngIf="image.isImageSelected" class="checkmark image-library__selected-tick" viewBox="0 0 52 52">
              <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
        </div>
      </div>
    <!-- </ng-scrollbar> -->
  </div>

</div>