import { Injectable } from '@angular/core';

@Injectable(
  {
    providedIn:'root'
   }
)
export class MoreSectionServiceService {

  filterOptions: { 'ShowMoreFilters': boolean; 'moreData': any; 'selectedMoreData': any; 'top': any; 'left': any; 'belongto':any; 'fromPage':string}; 
}
