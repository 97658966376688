<div class="rntl-form-field">    
    
    <mat-form-field appearance="outline" >
        <mat-select  [formControl]="control" [disabled]="disabled">
            <mat-option *ngIf="showNone">
                <span>{{'-- None --' | translate}}</span>
            </mat-option>
            <mat-option *ngFor="let option of options" [value]="option.value">
                <span>{{option.name}}</span>
                <span class="rntl-select-subtext" *ngIf="option.subtext">({{option.subtext}})</span>
            </mat-option>
        </mat-select>
        <mat-error *ngIf="control.invalid">
            <ng-container *ngIf="(control.errors | gformControlErrors: label) as errors">
                {{ errors[0] }}
            </ng-container>
        </mat-error>
    </mat-form-field>
    <label for="" class="rntl-form-field--label rntl-input-label rntl-fc-label">
        {{label | translate}}
    </label>
    <ng-content></ng-content>
</div>