import { Component, OnInit } from '@angular/core';
import { menuTypes } from 'src/app/common/components/menu/menu.constant';
import { RetailDataAwaiters } from '../shared/events/awaiters/retail.data.awaiters';
import { Product } from 'src/app/common/enums/shared-enums'
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-payment-manager',
  templateUrl: './payment-manager.component.html',
  styleUrls: ['./payment-manager.component.scss']
})
export class PaymentManagerComponent implements OnInit {
  menuList: any;
  menuType = menuTypes;
  codeRoute: any;
  constructor(private utils: CommonUtilities) { }

  ngOnInit(): void {
    let productId = Number(this.utils.GetPropertyInfo('ProductId'));
    if(Number(productId) == Product.ACCOUNTING){
      this.codeRoute = RetailDataAwaiters.GetChildMenu('/accounting/retailsetup/paymentmanager', menuTypes.vertical);
    }
    else{
       this.codeRoute = RetailDataAwaiters.GetChildMenu('/settings/retailsetup/paymentmanager', menuTypes.vertical);
    }

    const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
    const IsPropertyLevelSkipPMAgent = propConfig?.SkipPMAgent ?? '';
    this.codeRoute.linkedElement.map(res => {
      if (res) {
        if (res.routePath === '/settings/retailsetup/paymentmanager/payagents' || res.routePath === '/settings/retailsetup/paymentmanager/settlers') {
          res.visibility = !(IsPropertyLevelSkipPMAgent.trim().toLowerCase() == 'true')
        }
      }
    }
    );

    this.menuList = {
      menu: this.codeRoute.linkedElement,
      menuType : this.codeRoute.linkedElement[0].menuAlignment
    };
  }

}
