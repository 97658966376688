import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AllowedSettingsPublish } from '@app/shared/models/SignalRDto';
import { ServerService } from '@app/shared/services/server.service';
import { AppService } from '@app/app.service';
import { SettingsService } from '@app/shared/services/settings.service';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { ComponentTypes, LayoutUpdateStatus, Menu, PartyState, PartyType, ReservationType, TableLayoutConfig } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { LayoutConfig } from '@models/global.interface';
import { LayoutDTO, StandaloneTableDTO, StateDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { TablesService } from '@services/tables.service';
import { LayoutFunctions } from '@utilities/layout-functions';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';
import moment from 'moment';
import { ISubscription, Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-preferred-table',
  templateUrl: './preferred-table.component.html',
  styleUrls: ['./preferred-table.component.scss']
})
export class PreferredTableComponent extends Utilities implements OnInit, AfterViewInit, OnDestroy {
  config: FieldConfig[];
  floorPlan: any[] = [];
  displayLegends: any = [];
  layoutConfiguration: LayoutConfig;
  @Input() data: any;
  inputTables: any = [];
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  showMessage = false;
  seatPartyData: { seatParty: boolean; selectedTables: any; };
  tableSubscription: ISubscription;
  isPreferredTable = false;
  intialSet = true;
  subscriptions: Subscription = new Subscription();
  showmaxTablesExceedAlertdialogRef: MatDialogRef<any>;
  cancelSubscription: ISubscription;
  confirmSubscription: ISubscription;
  updatedSelectedTableNames: any[] = [];
  selectedTableIds = [];
  _state: StateDTO;
  ispageload = true;  
  layoutConfigurationData : LayoutConfig;

  constructor(public partyService: PartyService, public translateService: TranslateService,
    dialog: MatDialog, private ps: PopupService, public cs: CacheService, private ts: TablesService,
    private lf: LayoutFunctions, private ss: SettingsService, private serverService: ServerService, public _as: AppService) {
    super(dialog, translateService);
    // this.lf.loadIcons();
  }

  ngOnInit() {
    this.selectedTableIds = this.data?.dashboardData?.TableIds;
    this.displayLegends = [{ State: 1, name: 'Reserved' }, { State: 5, name: 'Blocked' }];
    this.floorPlan = this.cs.layout.value.FloorPlans.filter(x => x.IsDefault);
    this.getReservations();
    this.layoutChanges();
    this.stateChanges();
    this.loadfloorPlan();
    this.partyChanges();
  }

  getReservations() {
    this.partyService.partiesList = this.partyService.Parties$.value.concat(this.cs.state.value.SeatingParties);
    this.partyService.partiesList = _.uniqBy(this.partyService.partiesList, 'Id');
    this.partyService.Parties$.next(this.partyService.partiesList);
    this.setLayoutConfig();
  }

  setLayoutConfig() {
    if (this.partyService.Parties$.value && this.ts.tableBlockingRules$.value && this.cs.layout.value && this.cs.settings.value ) {
      let isUpdateFloorPlan = AllowedSettingsPublish[this.cs.settingsOperationName.value] == AllowedSettingsPublish['UpdateFloorPlan'];
      let pendingReservations = this.partyService.Parties$.value.filter(x => x.State == PartyState.Pending && x.Type == PartyType.Reservation);
      let seatedParties = this.partyService.Parties$.value.filter(x => x.State == PartyState.Seated)
      let CurrentReservations = (pendingReservations).concat(seatedParties.filter(x => (x.Type == PartyType.Reservation || (x.Type == PartyType.WalkIn && x.State == PartyState.Seated))));
      this.layoutConfiguration = {
        from: this.data && this.data.seatParty ? TableLayoutConfig.reservationTableSelection : TableLayoutConfig.preferredTables,
        tables: this.floorPlan[0].StandaloneTables,
        reservations: CurrentReservations,
        blockingRules: this.ts.getBlockingRulesForShift(this.getBlockingRulesFromDate(), this.getShiftIdFromDate()),
        floor: this.floorPlan,
        type: (this.intialSet || isUpdateFloorPlan) ? LayoutUpdateStatus.New : LayoutUpdateStatus.Update
      };
   
      this.cs.settingsOperationName.next(null);
      this.ts.standAloneTables = this.floorPlan[0].StandaloneTables;
      this.layoutConfiguration = { ...this.layoutConfiguration };
    }
  }

  async SetTablePrice(): Promise<any[]> {
    let tables = [];
    if (this.cs.settings && this.cs.settings.value && this.cs.settings.value.RatePlans && this.cs.settings.value.RatePlans.length > 0 && this.layoutConfiguration) {
      if (this.layoutConfiguration.tables && this.layoutConfiguration.tables.length > 0 && this.data && this.data.dashboardData && (this.data.dashboardData.ReservedFor || this.data.dashboardData.Type == ReservationType.Waitlist)) {
        let time = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
       let restaurantDateAndTime = (moment(time).format('YYYY-MM-DD') + 'T' + moment(time).format('HH:mm:ss'))
        tables = this.layoutConfiguration.tables;
        let seatingTypeIds = tables.map(({ SeatingTypeId }) => SeatingTypeId).filter(a => a);
      //  if (this.ts.seatingTypeIds.sort() != seatingTypeIds.sort() && this.ts.partyDateTime != this.data.dashboardData.ReservedFor) {
          this.ts.seatingTypeIds = _.uniq(seatingTypeIds);
          this.ts.seatingTypeIds = seatingTypeIds.filter(st => st != null);
          this.ts.partyDateTime = this.data.dashboardData.ReservedFor || restaurantDateAndTime;
          let response = await this.partyService.SetRatePlanBySeatingType(this.ts.seatingTypeIds, this.data.dashboardData.ReservedFor || restaurantDateAndTime , this.data.dashboardData , true);
          if (response != null && response.Payload != null && response.Payload.RatePlanCalculations) {
            if (response.Payload.RatePlanCalculations.length > 0) {
              let ratePlanCalculations = response.Payload.RatePlanCalculations;
              ratePlanCalculations?.forEach((ratePlan) => {
                  tables.map(t => { 
                    let price  = ratePlan.RatePlanVariants?.OriginalBaseRate + ( ratePlan.RatePlanVariants?.LocationVariant.find(variant => variant.SeatingTypeId == t.SeatingTypeId)?.OriginalVariant || 0 );
                    if(price){
                      t.Price = price.toString();
                    }
                  })
                  //tables.filter(t => t.SeatingTypeId == ratePlan.SeatingTypeId).map(table => table.Price = ratePlan.TotalAmount.toString());
              });
              this.ts.standAloneTables = this.floorPlan[0].StandaloneTables;
              this.layoutConfiguration = { ...this.layoutConfiguration };
            }
          }
       // }
      }
    }
    return tables;
  }

  layoutChanges() {
    this.subscriptions.add(this.cs.layout.subscribe((_layout: LayoutDTO) => {
      this.floorPlan = this.currentFloorPlan();
      this.serverService.currentLayoutId = _.cloneDeep(this.floorPlan[0].Id);
      this.loadfloorPlan();
      // this.floorPlanArray = Utilities.getFloorPlan(this.floorPlan[0].FloorPlanViews);
      // if (this.floorPlan[0].FloorPlanViews.length !== this.floorPlanArray) {
      //   this.floorPlanArray = Utilities.getFloorPlan(this.floorPlan[0].FloorPlanViews);
      //   this.serverConfig[0].options = this.floorPlanArray;
      // }
      if (this.ts.selectedFloorPlanId && this.ts.selectedFloorPlanId !== -1) {
        let floorPlanArray = Utilities.getFloorPlan(this.floorPlan[0].FloorPlanViews,this.translateService);
        floorPlanArray = floorPlanArray.map(option => {
          option.value = this.translateService.instant(option.value)
          return option;
        });
        const selectedFloors = floorPlanArray.filter(x => x.Id === this.ts.selectedFloorPlanId);
        if (selectedFloors.length === 0) {
          this.ts.selectedFloorPlanId = -1;
          // this.serverConfig[0].options = this.floorPlanArray;
          // this.serverConfig[0].value = this.floorPlanArray[0].id;
          // this.components.first.form.controls.floor.setValue(this.serverConfig[0].value);
        }
      }
    }));
  }

  stateChanges() {
    this.subscriptions.add(this.cs.state.subscribe((st: StateDTO) => {
      this._state = st;
      this.partyService.RecentlyLeftPartiesList = _.cloneDeep(st.RecentlyLeftParties);
      if (!this.ispageload) {
        this.floorPlan = this.currentFloorPlan();
        this.serverService.currentLayoutId = _.cloneDeep(this.floorPlan[0].Id);
        this.loadfloorPlan();
        const processedArray = this.getProcessedPartiesData(st);
        this.cs.isCustomFloorplan = false;
        this.partyService.Parties$.next(processedArray);
        this.setData();
      }
      this.ispageload = false;
    }));
  }

  settingChanges(){
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
     this.SetTablePrice();
  }));
  }

  partyChanges() {
    this.subscriptions.add(this.partyService.Parties$.subscribe((data) => {
      if (this.layoutConfiguration)
        this.setLayoutConfig();
    }));
   
  }

  getProcessedPartiesData(state: StateDTO) {
    let processedArray = [];
    processedArray = this.partyService.Parties$.value.filter(x => x.State != PartyState.Seated);
    processedArray.concat(state.SeatingParties);
    state.SeatingParties.forEach(data => {
      processedArray.push(data);
    });
    return processedArray;
  }

  setData() {

   

    if (this.data && this.data.seatParty) {
      let partyData = this.data?.dashboardData
      this.subscriptions.add(this.partyService.getStandaloneTablesFutureStateAllFloorTables(this.data?.propertyTime ||  partyData.ReservedFor  , partyData.Size , partyData.SeatingAreaId , (partyData?.SpecialMealId || this.data?.dashboardData?.SpecialMealId)).subscribe(tables => {
        if (tables) {
      if (this.data.isCurrentFloor) {
        let shiftid = this.currentShiftID(this.data.dashboardData.ReservedFor);
        this.floorPlan = Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, this.data.dashboardData.ReservedFor, shiftid);
      }else if(this.data?.isSeatParty){
       let propertyTime =  Utilities.getRestaurantDateTime(this.cs.settings?.value?.General?.DaylightDelta);
       let shiftid = this.currentShiftID(propertyTime);
       this.floorPlan = Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, propertyTime, shiftid);
      }
      else {
        this.floorPlan = this.currentFloorPlan();
      }
      let count = 0;
      // this.subscriptions.add(this.partyService.getStandaloneTablesFutureStateAllFloorTables(ReservedFor, Size, SeatingAreaId).subscribe(tables => {
      //   if (tables) {
      this.seatPartyData = { seatParty: true, selectedTables: this.selectedTableIds };
      this.updatedSelectedTableNames = Utilities.getTableNamesFromStandaloneTables(this.selectedTableIds, this.cs.layout.value.FloorPlans);
      let updatedTable = this.UpdateTableStatus(tables, this.floorPlan);
      this.layoutConfiguration = { ...updatedTable };
      this.partyService.layoutCofiguartion = this.layoutConfiguration;
      this.selectedTableIds.filter(tableID => {
        const selectedTables = this.layoutConfiguration.tables.filter((table) => table.Id == tableID);
        if (selectedTables.length > 0) {
          count++;
        }
      });
      if (!this.partyService.isAssignTables) {
        if (count === this.selectedTableIds.length) {
          this.selectedTableIds.length > 0 ? this.ps.saveBtnEnable$.next(true) : this.ps.saveBtnEnable$.next(false);
        } else {
          this.selectedTableIds = [];
          this.seatPartyData.selectedTables = [];
       this.seatPartyData = { ...this.seatPartyData };
        }
      }
      this.partyService.layoutCofiguartion = this.layoutConfiguration;
      this.intialSet = false;
      //   }
      // }));
      if (this.partyService.isAssignTables) {
        this.ps.saveBtnEnable$.next(false);
      }
    }
  }));
    } else {
      this.isPreferredTable = true;
      const date = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
      // this.subscriptions.add(this.partyService.getStandaloneTablesFutureStateAllFloorTables(date, 2, -1).subscribe(tables => {
      //   if (tables) {
      // const standaloneTables = this.floorPlan[0].StandaloneTables;
      // const StandaloneTablesWithStates = [];
      // for (const standaloneTable of standaloneTables) {
      //   StandaloneTablesWithStates.push({
      //     ...standaloneTable,
      //     ...(tables.find((state) => state.StandaloneTableId === standaloneTable.Id))
      //   });
      // }
      // const reservations = this.partyService.setReservations(tables);
      // let isUpdateFloorPlan = AllowedSettingsPublish[this.cs.settingsOperationName.value] == AllowedSettingsPublish['UpdateFloorPlan'];
      // this.layoutConfiguration = {
      //   from: TableLayoutConfig.preferredTables,
      //   tables: StandaloneTablesWithStates,
      //   selectedValues: this.data,
      //   reservations,
      //   blockingRules: this.ts.getBlockingRulesForShift(this.getBlockingRulesFromDate(), this.getShiftIdFromDate()),
      //   floor: this.floorPlan,
      //   type: (this.intialSet || isUpdateFloorPlan) ? LayoutUpdateStatus.New : LayoutUpdateStatus.Update
      // };
      this.cs.settingsOperationName.next(null);
     
      // this.layoutConfiguration = { ...this.layoutConfiguration };
      // this.partyService.layoutCofiguartion = this.layoutConfiguration;
      this.intialSet = false;
      //   }
      // }));
    }
    this.layoutConfigurationData = {...this.layoutConfiguration};
  }

  loadfloorPlan() {
    let floorPlanOptions = Utilities.getFloorPlan(this.floorPlan[0].FloorPlanViews,this.translateService);
    floorPlanOptions = floorPlanOptions.map(option => {
      option.value = this.translateService.instant(option.value)
      return option;
    })
    this.config = [
      {
        type: 'select',
        name: 'floorPlan',
        options: floorPlanOptions,
        class: 'login__language-selection',
        showErrorText: true,
        appearance: true,
        isTranslate: true,
        value: floorPlanOptions[0].id,
        cellClick: this.setSelectedFloorPlan.bind(this)
      }
    ];
  }

  setSelectedFloorPlan(eve) {
    this.ts.selectedFloorPlanPopup.next(eve.value);
  }

  UpdateTableStatus(fututreTableStates, currentFloorPlan) {
    let standaloneTables = currentFloorPlan[0].StandaloneTables;
    const StandaloneTablesWithStates = [];
    for (let i = 0; i < standaloneTables.length; i++) {
      StandaloneTablesWithStates.push({
        ...standaloneTables[i],
        ...(fututreTableStates.find((state) => state.StandaloneTableId === standaloneTables[i].Id))
      }
      );
    }
    const reservations = this.partyService.setReservations(fututreTableStates);
    let isUpdateFloorPlan = AllowedSettingsPublish[this.cs.settingsOperationName.value] == AllowedSettingsPublish['UpdateFloorPlan'];
    this.layoutConfiguration = {
      from: TableLayoutConfig.reservationTableSelection,
      tables: StandaloneTablesWithStates,
      reservations,
      floor: this.floorPlan,
      blockingRules: this.ts.getBlockingRulesForShift(this.getBlockingRulesFromDate(), this.getShiftIdFromDate()),
      type: (this.intialSet || isUpdateFloorPlan) ? LayoutUpdateStatus.New : LayoutUpdateStatus.Update
    };
    this.cs.settingsOperationName.next(null);
    return this.layoutConfiguration;
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.ss.blockingdataChange$.subscribe(val => {
      // this.setLayoutConfig();
      // if (this.layoutConfiguration) {
      //   this.layoutConfiguration.blockingRules = this.ts.getBlockingRulesForShift(this.getBlockingRulesFromDate(), this.getShiftIdFromDate());
      //   this.layoutConfiguration = {...this.layoutConfiguration};
      //   this.ts.getUpdateBlockingRules(val);

      // }
      if (this.partyService.Parties$) {
        let isUpdateFloorPlan = AllowedSettingsPublish[this.cs.settingsOperationName.value] == AllowedSettingsPublish['UpdateFloorPlan'];
        this.ts.getUpdateBlockingRules(val);
        this.setLayoutConfig();
        this.settingChanges();
        this.layoutConfiguration.type = (this.intialSet || isUpdateFloorPlan) ? LayoutUpdateStatus.New : LayoutUpdateStatus.Update;
        this.setData();
        this.intialSet = false;
      }
    }));
  }

  currentFloorPlan() {
    let customFloorPlan = false;
    let currentdate = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    if((this._as.selectedMenuId != Menu.WaitList) && (this._as.selectedMenuId != Menu.GuestBook)) {
      let currentDateAlone = new Date(currentdate.setHours(0, 0, 0, 0)).getTime();
      let headerDateAlone = new Date(this.cs.headerDate.setHours(0, 0, 0, 0)).getTime();
      if(currentDateAlone != headerDateAlone){
        currentdate = new Date(this.cs.headerDate);
      }
    }

    let CustomFloorPlanDeatils = Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, currentdate);
    if (CustomFloorPlanDeatils && CustomFloorPlanDeatils.length > 0 && CustomFloorPlanDeatils[0].Dates) {
      let getShiftForDate = CustomFloorPlanDeatils
        .filter((element) =>
          element.Dates.some((subElement) => subElement.ShiftId != null && subElement.ShiftId > 0))
        .map(element => {
          let newElt = Object.assign({}, element);
          return newElt.Dates.filter(subElement => subElement.ShiftId != null && subElement.ShiftId > 0);
        });
      if (getShiftForDate.length > 0) {
        customFloorPlan = true;
      }

    }
    let TodayShifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, currentdate);
    let curentshift = 0;
    if (customFloorPlan) {
      let currentdateval = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
      let selecteddate = new Date(currentdateval.setHours(0, 0, 0, 0)).getTime();
      let CurrentDateTime = new Date(new Date(currentdate).setHours(0, 0, 0, 0));
      if (CurrentDateTime.getTime() == selecteddate) {
        let CurrentShift = TodayShifts.filter(Shift => {
          if (Utilities.datetimeBetween(Shift.EffectiveRange.Start, Shift.EffectiveRange.End, currentdate)) {
            return true;
          }
        });
        if (CurrentShift && CurrentShift.length > 0) {
          curentshift = CurrentShift[0].Id;
        } else {
          curentshift = this.floorPlan.filter(x => x.IsDefault == true)[0].Id
          // let PrevShift = TodayShifts.filter(Shift => {
          //   //let ShiftStarthour = new Date(Shift.EffectiveRange.Start).getHours() + "." + new Date(Shift.EffectiveRange.Start).getMinutes();
          //   let Shiftendhour = Utilities.formateDateString(Shift.EffectiveRange.End).getHours() + "." + Utilities.formateDateString(Shift.EffectiveRange.End).getMinutes();
          //   if (Number(Shiftendhour) < Number(currentHour)) {
          //     return true;
          //   }
          // });

          // if (PrevShift && PrevShift.length > 0) {
          //   let maxtime = Math.max.apply(Math, PrevShift.map(function (o) { return new Date(Utilities.formateDateString(o.EffectiveRange.End)); }))
          //   let maxHour = new Date(maxtime).getHours() + "." + new Date(maxtime).getMinutes();
          //   let maxshift = PrevShift.filter(Shift => {
          //     let Shiftendhour = Utilities.formateDateString(Shift.EffectiveRange.End).getHours() + "." + Utilities.formateDateString(Shift.EffectiveRange.End).getMinutes();
          //     if (Number(Shiftendhour) == Number(maxHour)) {
          //       return true;
          //     }
          //   });
          //   if (maxshift && maxshift.length > 0) {
          //     curentshift = maxshift[0].Id;
          //   }
          //   else {
          //     curentshift = PrevShift[0].Id;
          //   }

          // } else {
          //   curentshift = TodayShifts[0].Id;
          // }

        }

      } else if (new Date(CurrentDateTime.setDate(CurrentDateTime.getDate() - 1)).getTime() == selecteddate) {
        let CurrentShift = TodayShifts.filter(Shift => {
          if (Utilities.datetimeBetween(Shift.EffectiveRange.Start, Shift.EffectiveRange.End, this.data?.dashboardData ? this.data?.dashboardData.ReservedFor: currentdate)) {
            return true;
          }
        });

        if (CurrentShift != null && CurrentShift.length > 0) {
          curentshift = CurrentShift[0].Id;
        }
        else {
          curentshift = TodayShifts[0].Id;
        }
      } else {
        curentshift = TodayShifts[0].Id;
      }

    } else {
      curentshift = 0;
    }

    return Utilities.GetLayoutForDate(this.cs.layout.value.FloorPlans, currentdate, curentshift);
  }

  currentShiftID(date) {
    const selecteddate = new Date(date);
    const selectedShift = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, new Date(date));
    const CurrentShift = selectedShift.filter(Shift => {
      if (Utilities.datetimeBetween(Shift.EffectiveRange.Start, Shift.EffectiveRange.End, selecteddate)) {
        return true;
      }
    });
    if (CurrentShift && CurrentShift.length > 0) {
      return CurrentShift[0].Id;
    }
    return 0;
  }

  ngOnDestroy() {
    this.partyService.layoutCofiguartion = { from: TableLayoutConfig.reservationTableSelection };
    if (this.tableSubscription) {
      this.tableSubscription.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.isPreferredTable = false;
  }

  selectedTables(tables) {
    this.updatedSelectedTableNames = Utilities.getTableNamesFromStandaloneTables(tables, this.cs.layout.value.FloorPlans);
    if (this.data && this.data.seatParty) {
      const allAvailableTables: StandaloneTableDTO[] = Utilities.getRestaurantTables(this.cs.layout.value.FloorPlans);
      let allselecteTablesPartySize = 0;
      tables.forEach((table) => {
        const selectedTableDetails = allAvailableTables.filter((standaloneTable) => standaloneTable.Id === table);
        if (selectedTableDetails.length > 0) {
          allselecteTablesPartySize += selectedTableDetails[0].MaxPartySize;
        }
      });
      let presentSeatedArray = this.checkCurrentSeatedParties(tables);
      if (allselecteTablesPartySize > 2 * this.data.dashboardData.Size && !this.partyService.isAssignTables && presentSeatedArray.length == 0) {
        this.showmaxTablesExceedAlert(allselecteTablesPartySize, this.data.dashboardData.Size);
      }
      this.partyService.selectedTable = tables;
      this.selectedTableIds = [...new Set([...tables])];
      this.seatPartyData.selectedTables = this.selectedTableIds;
    }
    if (this.isPreferredTable) {
      this.ps.saveBtnEnable$.next(true);
    } else if (this.data && this.data.selectTables) {
      this.ps.saveBtnEnable$.next(true);
    } else {
      tables.length > 0 ? this.ps.saveBtnEnable$.next(true) : this.ps.saveBtnEnable$.next(false);
    }
  }

  checkCurrentSeatedParties(tables) {
    const presentSeatedArray = [];
    tables.forEach(element => {
      this.cs.state.value.SeatingParties.forEach(status => {
        const data = status.TableIds.filter(x => x === element)[0];
        const standAloneTable = this.floorPlan[0].StandaloneTables.filter(table => table.Id == element);
        if (data && !standAloneTable[0].IsCommunalTable) {
          presentSeatedArray.push(data);
        }
      });
    });
    return presentSeatedArray;
  }

  showmaxTablesExceedAlert(totalSeatCount: number, partySize: number) {
    if (this.confirmSubscription) { this.confirmSubscription.unsubscribe(); }
    if (this.cancelSubscription) { this.cancelSubscription.unsubscribe(); }
    if (this.ps.isRestrictCloseDialog) {
      this.ps.restrictCloseDialog = false;
    }
    const popUpMessage = [{
      confirmationMessage: `${this.translateService.instant('areYouSure')} ${totalSeatCount}
                            ${this.translateService.instant('seatCountError')} ${partySize}?`,
      dialogTitle: this.translateService.instant('alert'),
      showAlert: true
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'active', popUpMessage,
      popUpMessage[0].dialogTitle);
    this.showmaxTablesExceedAlertdialogRef = this.openCustomPopup(componentDetails, ComponentTypes.maxTablesExceedAlert, '450px', 'auto', false, this.translateService.instant('alert'), this.translateService.instant('ok'),
      'Cancel', true);

    this.confirmSubscription = this.ps.confirmedAction$.subscribe((data) => {
      if (data && data == ComponentTypes.maxTablesExceedAlert && this.showmaxTablesExceedAlertdialogRef) {
        this.showmaxTablesExceedAlertdialogRef.close();
      }
    });

    this.cancelSubscription = this.ps.cancelledAction$.subscribe((data) => {
      if (data && data.from == ComponentTypes.maxTablesExceedAlert && this.showmaxTablesExceedAlertdialogRef) {
        this.showmaxTablesExceedAlertdialogRef.close();
      }
    });

    this.showmaxTablesExceedAlertdialogRef.afterClosed().subscribe(() => {
      if (this.confirmSubscription) { this.confirmSubscription.unsubscribe(); }
      if (this.cancelSubscription) { this.cancelSubscription.unsubscribe(); }
      if (this.ps.isRestrictCloseDialog) {
        this.ps.restrictCloseDialog = true;
      }
    });
  }

  getShiftIdFromDate() {
    let date = this.partyService.reservationDateForBlockingRule ? this.partyService.reservationDateForBlockingRule : new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
    return this.currentShiftID(date);
  }

  getBlockingRulesFromDate() {
    return this.ts.getBlockingRulesFortheDate(new Date(this.partyService.reservationDateForBlockingRule));
  }
}
