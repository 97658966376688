import { Pipe, PipeTransform } from "@angular/core";
import { PartyState } from "../constants/commonenums";

@Pipe({
    name: 'bookingContactState'
  })
  export class BookingContactState implements PipeTransform {
    constructor() { }
    transform(reservation): any {
      if(reservation.State != PartyState.Pending){
        return false;
      }
      return !reservation.BookingContacts?.some(bContact => bContact.BookingContactStates?.length && bContact.BookingContactStates[0].State != PartyState.Pending && bContact.BookingContactStates[0].State != PartyState.Cancelled)
    }
  }