<div class="booking-confirmation-details">
    <div class="confirmation-header">
        <dynamic-form [config]="selectAllConfig" class="text-center" #bookingSelectAllForm></dynamic-form>
        <span class="prompt-header" *ngFor="let header of availableHeaders">{{header | translate}}</span>
    </div>
    <div *ngFor="let contact of bookingContacts;let i = index" class="confirmation-details pt-3 pb-3">
        <mat-checkbox class="parent-label text-center" [(ngModel)]="contact.bookingSelected" (change)="selectContact()">

        </mat-checkbox>
        <span class="d-block text-center">{{i+1}}</span>
        <span>{{contact.FirstName}} {{contact.LastName}}</span>

        <section class="form-view">
            <!-- Email Address-->
            <div class="position-relative">
                <mat-form-field appearance="outline" class="basic-input-field width-90">
                    <input type="email" [(ngModel)]="contact.EmailAddress" matInput />
                </mat-form-field>


                <ng-container
                    *ngIf="contact.bookingSelected && (contact.ConfirmationVia == ConfirmationViaEnum.Email || contact.ConfirmationVia == ConfirmationViaEnum.Both)">
                    <span *ngIf="contact.bookingSelected && !(contact.EmailAddress) " class="error-text" #errorText>
                        {{'guestBookDetailsEmailError' | translate}}
                    </span>
                    <span
                        *ngIf="contact.bookingSelected && contact.EmailAddress && !(contact.EmailAddress | validateEmail) "
                        class="error-text" #errorText>
                        {{'guestBookDetailsInvalidEmail'| translate}}

                    </span>
                </ng-container>




            </div>

            <!-- Country Code -->
            <mat-form-field appearance="outline" class="basic-input-field width-90">
                <mat-select [(ngModel)]="contact.CountryCode" (selectionChange)="validateCountryFormat(contact)">
                    <mat-option *ngFor="let title of countryCodes" [value]="title.id">{{title.value}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Phone Number -->
            <div class="position-relative">
                <mat-form-field appearance="outline" class="basic-input-field width-90">
                    <input [(ngModel)]="contact.PhoneNumber" phoneNumber [maxlength]="contact.requiredPhoneNumberLength"
                        (keyup)="validateCountryFormat(contact)" matInput />
                </mat-form-field>


                <ng-container
                    *ngIf="contact.bookingSelected && (contact.ConfirmationVia == ConfirmationViaEnum.Sms || contact.ConfirmationVia == ConfirmationViaEnum.Both)">
                    <span *ngIf="contact.bookingSelected && !(contact.PhoneNumber) " class="error-text" #errorText>
                        {{'guestBookDetailsMobileError' | translate}}
                    </span>
                    <span
                        *ngIf="contact.bookingSelected && contact.PhoneNumber && contact.requiredPhoneNumberLength && (contact.PhoneNumber.length != contact.requiredPhoneNumberLength) "
                        class="error-text" #errorText>
                        {{'guestBookDetailsInvalidPhone'| translate}}

                    </span>
                </ng-container>
            </div>

            <!-- Confirmation Via -->
            <div class="position-relative">
                <mat-form-field appearance="outline" class="basic-input-field width-90">
                    <mat-select class="" [(ngModel)]="contact.ConfirmationVia">
                        <mat-option *ngFor="let title of ConfirmationViaOptions" [value]="title.id">{{title.value |
                            translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <!-- Send Reminder -->
            <div class="toggle-button platform-guest text-center" *ngIf="sessionType != partyType.StandBy">
                <label class="switch">
                    <input class="switch-input" type="checkbox" [(ngModel)]="contact.SendReminder" />
                    <span class="slider round"></span>
                </label>
            </div>
        </section>
    </div>
</div>