import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { Validators } from '@angular/forms';
import { buttonTypes } from '@constants/commonenums';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { PopupService } from '@popup-module/popup.service';
import { Subscription } from 'rxjs';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
@Component({
  selector: 'app-email-confirmation-popup',
  templateUrl: './email-confirmation-popup.component.html',
  styleUrls: ['./email-confirmation-popup.component.scss'],
  exportAs: 'dynamicForm' ,
  encapsulation: ViewEncapsulation.None
})
export class EmailConfirmationPopupComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  //@ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @ViewChild('form', { static: false }) dynamicForm: DynamicFormComponent;

  @Input() data: any;

  public confirmationMessage: string = '';
  public dialogTitle: string = '';
  public hideTitle: boolean = false;
  public showAlert: boolean = false;
  public forConfirmationEmail: boolean = true;
  public showConfirmationNumber: boolean = true;
  public emailConfig: FieldConfig[];
  public listOfItem: any = null;
  public buttonValueOk: ButtonValue;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private popupService: PopupService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initializeButtonValue();
    this.parseInputData();
    this.initializeEmailConfig();
  }

  ngAfterViewInit(): void {
    if (this.forConfirmationEmail && this.dynamicForm) {
      this.setInitialFormValues();
      this.subscribeToFormChanges();
    }

    this.triggerChangeDetection();
  }

  close(): void {
    this.emitCloseDialogEvent();
  }

  submit(): void {
    if (this.isFormValid()) {
      this.performFormSubmission();
    }
  }

  ngOnDestroy(): void {
    this.cleanupSubscriptions();
  }

  // -------------------- Private Methods --------------------

  /**
   * Initializes the default value for the OK button.
   */
  private initializeButtonValue(): void {
    this.buttonValueOk = {
      type: buttonTypes.actionSecondary,
      label: 'OK',
    };
  }

  /**
   * Parses the input data and sets relevant component properties.
   */
  private parseInputData(): void {
    if (this.data && this.data.length > 0) {
      const popupData = this.data[0];
      this.forConfirmationEmail = popupData.sendConfirmationEmail || false;
      this.showConfirmationNumber =
        popupData.showConfirmationNumber !== undefined
          ? popupData.showConfirmationNumber
          : true;
      this.dialogTitle = popupData.dialogTitle || '';
      this.confirmationMessage = popupData.confirmationMessage || '';
      this.showAlert = popupData.showAlert || false;
      this.hideTitle = !this.dialogTitle;
      this.listOfItem = popupData.ListOfItem;
    }
  }

  /**
   * Initializes the email configuration for the dynamic form.
   */
  private initializeEmailConfig(): void {
    this.emailConfig = [
      {
        type: 'input',
        name: 'email',
        inputType: 'text',
        label: 'Email Address',
        class: 'confirmation-popup__email',
        validation: [
          Validators.email,
          Validators.pattern(
            '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$'
          ),
        ],
        showErrorText: true,
      },
    ];
  }

  /**
   * Sets the initial form values for the email field.
   */
  private setInitialFormValues(): void {
    const initialEmail = this.data[0]?.email || '';
    this.dynamicForm.form.patchValue({ email: initialEmail });
  }

  /**
   * Subscribes to changes in the form and updates relevant properties.
   */
  private subscribeToFormChanges(): void {
    this.subscriptions.add(
      this.dynamicForm.form.valueChanges.subscribe((formData) => {
        this.data[0].email = formData.email;
        const isEmailValid =
          this.dynamicForm.form.controls['email'].valid && !!formData.email;
        this.popupService.cancelBtnEnable$.next(isEmailValid);
      })
    );
  }

  /**
   * Triggers Angular change detection to avoid lifecycle timing issues.
   */
  private triggerChangeDetection(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Emits the event to close the dialog.
   */
  private emitCloseDialogEvent(): void {
    this.popupService.closeDialog$.next();
  }

  /**
   * Checks if the form is valid.
   */
  private isFormValid(): boolean {
    return this.dynamicForm.form.valid;
  }

  /**
   * Performs form submission logic, such as updating data and closing the dialog.
   */
  private performFormSubmission(): void {
    console.log('Updated email:', this.data[0].email);
    this.popupService.closeDialog$.next();
  }

  /**
   * Cleans up subscriptions to avoid memory leaks.
   */
  private cleanupSubscriptions(): void {
    this.subscriptions.unsubscribe();
  }
}
