import _ from "lodash";
import { PackageShortDTO } from "./PackageDTO";
import { NotificationPreference } from "../constants/commonenums";

export class OpenBookingDTO {
    BookingId:number;
    FromDate: string;
    ToDate: string;
    Location: number[];
    LocationName: string[];
    BookingSize: number;
    SalesContactIds: number[];
    MembershipDetail: string;
    CoverTypes: CoverType[];
    BookingTypes:BookingType[];
    Slots: any[];
    MinRateAdjustment: number;
    OverBooked?: number;
    SizeMapping?: any;
    BookingContacts: any[];
    BookingBehavior?: number;
    SpecialMealId?: number;
    StaffId?: number;
    AllowStandBy: boolean;
    PageMethod: number;
    IsOverBook: boolean;
    PackageDetails?: PackageShortDTO;
    IsExclusiveBooking: boolean;
    AdditionalInfo: string[];
    NotificationPreference?:NotificationPreference;
    LastPaidBy: number;
    PaidCard: number;
    EmailNotes: string;
    NotificationEmailIds:string[];
    CreditCards: any
    constructor(bookingId, fromDate, toDate, location, locationName,
        bookingSize, membershipDetails, guestTypes,bookingTypes, slots,bookingBehavior,specialMealId,staffId,allowStandBy: boolean , PackageDetails? , IsExclusiveBooking?,AdditionalInfo?,NotificationPreference?, SalesContactIds: number[] = [], NotificationEmailIds: string[] = [], CreditCards: any = []){
        this.BookingId = bookingId;
        this.FromDate = fromDate;
        this.ToDate = toDate;
        this.Location = location;
        this.LocationName = locationName;
        this.BookingSize = bookingSize;
        this.MembershipDetail = membershipDetails;
        this.CoverTypes = getGuestTypes(guestTypes);
        this.BookingTypes=bookingTypes;
        this.Slots = slots;
        this.StaffId = staffId;
        this.BookingBehavior = bookingBehavior;
        this.SpecialMealId = specialMealId;
        this.AllowStandBy = allowStandBy;
        this.PackageDetails = PackageDetails
        this.IsExclusiveBooking = IsExclusiveBooking;
        this.AdditionalInfo = AdditionalInfo;
        this.NotificationPreference = NotificationPreference;
        this.LastPaidBy = this.LastPaidBy;
        this.PaidCard = this.PaidCard;
        this.SalesContactIds = SalesContactIds;
        this.NotificationEmailIds = NotificationEmailIds;
        this.CreditCards = CreditCards
    }

    
}

export class PrivateLessonBookingDTO {
    BookingId:number;
    ActivityId: number;
    ClassType: number;
    FromDate: string;
    ToDate: string;
    Location: number[];
    LocationName: string[];
    StaffId: number[];
    BookingSize: number;
    SalesContactIds: number[];
    MembershipDetail: string;
    CoverTypes: CoverType[];
    MinRateNegotiatedAdjustment?:any;
    BookingTypes: BookingType[];
    Slots: any[];
    MinRateAdjustment: number;
    BookingContacts: any[];
    OverBooked: number = 0;
    BookingBehavior?: number;
    SpecialMealId?: number;
    AllowStandBy: boolean;
    PageMethod: number;
    IsOverBook: boolean;
    PackageDetails?:PackageShortDTO;
    IsExclusiveBooking:boolean;
    AdditionalInfo: string[];
    NotificationPreference?:NotificationPreference;
    LastPaidBy: number;
    PaidCard: number;
    EmailNotes:string;
    NotificationEmailIds:string[];
    CreditCards: any;
    constructor(bookingId,activityId,classType, fromDate, toDate,location,locationName,StaffId,
        bookingSize, membershipDetails, guestTypes,bookingTypes, slots,bookingBehavior,specialMealId,allowStandBy, PackageDetails? ,IsExclusiveBooking?,additionalInfo?,NotificationPreference?, SalesContactIds: number[] = [], NotificationEmailIds: string[] = [], CreditCards: any = []){
            this.BookingId = bookingId;
            this.ActivityId = activityId;
            this.ClassType = classType;
        this.FromDate = fromDate;
        this.ToDate = toDate;
        this.Location = location ? [location] : [] ;
        this.LocationName = locationName;
        this.StaffId = StaffId;
        this.BookingSize = bookingSize;
        this.MembershipDetail = membershipDetails;
        this.CoverTypes = getGuestTypes(guestTypes);
        this.BookingTypes=bookingTypes;
        this.Slots = slots;
        this.BookingBehavior = bookingBehavior;
        this.SpecialMealId = specialMealId;
        this.AllowStandBy = allowStandBy;
        this.PackageDetails = PackageDetails;
        this.IsExclusiveBooking = IsExclusiveBooking;
        this.AdditionalInfo = additionalInfo;
        this.NotificationPreference = NotificationPreference;
        this.LastPaidBy = this.LastPaidBy;
        this.PaidCard = this.PaidCard;
        this.SalesContactIds = SalesContactIds;
        this.NotificationEmailIds = NotificationEmailIds;
        this.CreditCards = CreditCards;
    }
}
export class CoverType {
    CoverTypeId: number;
    CoverTypeName: number;
    BookingTypeId: number;
    BookingTypeName: string;
    Covers: number;
}

export class BookingType {
    BookingTypeId: number;
    BookingTypes: number;
}

export function getGuestTypes(types){
    types = _.cloneDeep(types);
    types.forEach(type => {
        if(!type.BookingTypeId){
            type.BookingTypeId = null;
        }
        if(!type.CoverTypeId){
            type.CoverTypeId = null;
        }
    })

    return types;
}

export class StandaloneBookingDTO {
    BookingId:number;
    FromDate: string;
    ToDate: string;
    Location: number[];
    LocationName: string[];
    BookingSize: number;
    SalesContactIds: number[];
    MembershipDetail: string;
    CoverTypes: CoverType[];
    BookingTypes:BookingType[];
    Slots: any[];
    MinRateAdjustment: number;
    OverBooked?: number;
    SizeMapping?: any;
    BookingContacts: any[];
    BookingBehavior?: number;
    SpecialMealId?: number;
    StaffId?: number;
    AllowStandBy: boolean;
    PageMethod: number;
    IsOverBook: boolean;
    PackageDetails?: PackageShortDTO;
    IsExclusiveBooking: boolean;
    AdditionalInfo: string[];
    NotificationPreference?:NotificationPreference;
    constructor(bookingId, fromDate, toDate, location, locationName,
        bookingSize, salescontactIds, membershipDetails, guestTypes,bookingTypes, slots,bookingBehavior,specialMealId,staffId,allowStandBy: boolean , PackageDetails? , IsExclusiveBooking?,AdditionalInfo?,NotificationPreference?){
        this.BookingId = bookingId;
        this.FromDate = fromDate;
        this.ToDate = toDate;
        this.Location = location;
        this.LocationName = locationName;
        this.BookingSize = bookingSize;
        this.SalesContactIds = salescontactIds;
        this.MembershipDetail = membershipDetails;
        this.CoverTypes = getGuestTypes(guestTypes);
        this.BookingTypes=bookingTypes;
        this.Slots = slots;
        this.StaffId = staffId;
        this.BookingBehavior = bookingBehavior;
        this.SpecialMealId = specialMealId;
        this.AllowStandBy = allowStandBy;
        this.PackageDetails = PackageDetails
        this.IsExclusiveBooking = IsExclusiveBooking;
        this.AdditionalInfo = AdditionalInfo;
        this.NotificationPreference = NotificationPreference;
    }
}