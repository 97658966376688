import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { RetailSetupService } from './retail-setup/retail-setup.service';
import { RetailLocalization } from './common/localization/retail-localization';
import { MatSelectChange } from '@angular/material/select';
import { BreakPointAccess } from './shared/service/breakpoint.service';
import { RetailBreakPoint } from './shared/globalsContant';
import { Router, ActivatedRoute } from '@angular/router';
import { RetailMenu, menuTypes } from './retail.modals';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { RetailDataAwaiters } from './shared/events/awaiters/retail.data.awaiters';
import { RouteLoaderService } from '../core/services/route-loader.service';
import { CommonUtilities } from '../common/shared/shared/utilities/common-utilities';
import {Product} from 'src/app/common/enums/shared-enums'

@Component({
  selector: 'spa-retail',
  templateUrl: './retail.component.html',
  styleUrls: ['./retail.component.scss']
})
export class RetailComponent implements OnInit {
  quickSaleData: any = []
  retailId: string;
  captions: any;
  retails: any = [];
  selectedRetailId: string = 'codeSetup';
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() showLoader: EventEmitter<boolean> = new EventEmitter();

  menuList: any;
  menuType = menuTypes;
  value: any;
  currentProductId: number;

  constructor(public router: Router,private _rs: RetailSetupService, public localization: RetailLocalization, private breakpoint: BreakPointAccess, private routeDataService: RouteLoaderService, private utilities: CommonUtilities) {
    this.captions = this.localization.captions.retailsetup;
    this.retails = [
      { id: "codeSetup", viewValue: this.captions.CodeSetup, callDesc: "", IsAuthorized: true },
      { id: "retailSetup", viewValue: this.captions.RetailSetup, callDesc: "", breakPointNumber: RetailBreakPoint.ItemSetup, IsAuthorized: true },
      { id: "quickSaleSetup", viewValue: this.captions.QuickSaleSetup, callDesc: "", breakPointNumber: RetailBreakPoint.QuickSaleKeys, IsAuthorized: true },
      { id: 'commissionTemplate', viewValue: this.captions.CommissionTemplate, callDesc: "", breakPointNumber: RetailBreakPoint.SplitCommissionTemplateSetup, IsAuthorized: true },
      { id: 'discountConfiguration', viewValue: this.captions.discountConfiguration, callDesc: "", breakPointNumber: RetailBreakPoint.DiscountConfiguration, IsAuthorized: true },
      { id: 'categorylinking', viewValue: this.captions.categoryLinking, callDesc: "", breakPointNumber: RetailBreakPoint.CategoryLinking, IsAuthorized: true }
    ];
    this.currentProductId = Number(this.utilities.GetPropertyInfo('ProductId'));
    if(this.currentProductId == Product.ACCOUNTING){
      this.value = this.routeDataService.GetChildMenu('/accounting/retailsetup');  
    }
    else{
      this.value = RetailDataAwaiters.GetChildMenu('/settings/retailsetup');
    }
    this._rs.tabLoaderEnable.next(true);
  }





  ngOnInit() {
    this.retailId = this.retails[0].id;
    this.quickSaleData = this._rs.quickSaleItem;
    this._rs.tabLoaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader => {
      this.showLoader.emit(loader);

      //retail loader
      this.ToggleLoader(loader);

    });
    this._rs.GetRetailBreakPoints();    
    this.retails = this._rs.MapIsAuthorizedRetailMenu(this.retails);
    this.value = this._rs.AdjustMenuBasedOnRetailInterface(this.value.linkedElement);
    
    this.value = _.orderBy( this.value, ['order'], ['asc']);
    
    this.menuList = {
      menu: this.value,
      menuType: this.value[0].menuAlignment
    };
    }
  

  ToggleLoader(loader) {
    const loadingContainer = document.getElementById('cover-spin');
    if (loadingContainer) {
      if (loader) {
        loadingContainer.style.display = 'block';
      }
      else {
        loadingContainer.style.display = 'none';
      }
    }
  }

  OnSelectChange(event: MatSelectChange) {
    let _bpNumberOfSelected: number = this.retails.find(retail => retail.id == event.value).breakPointNumber;
    let _IsAuthorized = _bpNumberOfSelected ? this.breakpoint.CheckForAccess([_bpNumberOfSelected]) : true;
    if (_IsAuthorized) {
      this.selectedRetailId = event.value;
    }
  }

  accessCheckAndAlert(retailMenu: RetailMenu) {
    if (!retailMenu.IsAuthorized) {
      this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[retailMenu.breakPointNumber]);
    }
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }
}
