import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '@app/app.service';
import { urlConfig } from '@constants/url-config';
import { CacheService } from '@core/services/cache.service';
import { LayoutDTO, ScheduleDTO, ServerDTO, SettingsDTO, StaffBlockDTO, StandaloneTableDTO, TableServerDTO } from '@models/RestaurantDTO';
import { PopupService } from '@popup-module/popup.service';
import { HttpService } from '@services/http.service';
import _ from 'lodash';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Utilities } from '../utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class ServerService implements OnDestroy {
  editableMode = false;
  unselectedTableIds: number[] = [];
  selectedTableIds: number[] = [];
  serverDetails: BehaviorSubject<ServerDTO[]> = new BehaviorSubject<ServerDTO[]>(null);
  serverForm: UntypedFormGroup;
  switchServerId: number;
  unassignAllEvent = new Subject<any>();
  deSelectTablesOnUnassignAllEvent = new Subject<any>();
  resetSelectionArrayEvent = new Subject<any>();
  latestServers = new Subject<any>();
  _layout: LayoutDTO = {} as LayoutDTO;
  currentLayoutId: number;
  subscriptions: Subscription = new Subscription();
  instructorSelectedTables = {};
  isReload : boolean = false;
  isUserAction$ = new Subject<boolean>();

  constructor(public httpService: HttpService, public appService: AppService, public popupService: PopupService,
    public formBuilder: UntypedFormBuilder, public cs: CacheService) {
    //super(httpService, popupService, formBuilder, cs);
    this.subscriptions.add(cs.layout.subscribe(layt => {
      this._layout = layt;
      this.updateServerMappings(this.cs.settings?.value?.Servers, layt?.FloorPlans);

    }));

    this.subscriptions.add(this.cs.settings.subscribe((value: SettingsDTO) => {
      this.updateServerMappings(value?.Servers, this.cs.layout?.value?.FloorPlans);
    }));
    this.serverForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: [''],
      expertiseLevel: [],
      serverCode: ['', [Validators.required, Validators.maxLength(10)]],
      serverId: '',
      mobileCode:'',
      mobile:'',
      email:'',
      photo:'',
      isPhotoUpload:'',
    });
  }

  setServerOffDuty(layoutId: number, serverId: number) {
    const postURL = `${urlConfig.offDutyURL}?restaurantId=${this.appService.restaurantId}&layoutId=${layoutId}&serverId=${serverId}`;
    return this.httpService.post(postURL, null);
  }

  assignServers(serverDetails: TableServerDTO[]): Observable<any> {
    const postURL = `${urlConfig.assignServers}?restaurantId=${this.appService.restaurantId}`;
    return this.httpService.post(postURL, serverDetails);
  }

  getServers() {
    return this.httpService.get(`${urlConfig.getServersUrl}?restaurantId=${this.appService.restaurantId}&languageId=` +  sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`));
  }
  setServers(servers: ServerDTO[]) {
    const postURL = `${urlConfig.setServers}?restaurantId=${this.appService.restaurantId}`;
    return this.httpService.post(postURL, servers)
  }

  switchServer(layoutId: number, fromServer: number, toServer: number, ) {
    const postURL = `${urlConfig.switchServers}?restaurantId=${this.appService.restaurantId}&layoutId=${layoutId}&fromServerId=${fromServer}
&toServerId=${toServer}`;
    return this.httpService.post(postURL, null);
  }

  clearShift(layoutId: number) {
    const postURL = `${urlConfig.clearShift}?restaurantId=${this.appService.restaurantId}&layoutId=${layoutId}`;
    return this.httpService.post(postURL, null);
  }
  synchServers(igMiddlewareIpAddress: string) {

    const postURL = `${urlConfig.synchServers}?iGMiddlewareIpAddress=${igMiddlewareIpAddress}&restaurantId=${this.appService.restaurantId}`;
    return this.httpService.post(postURL, null);
  }

  addSchedules(schedules: ScheduleDTO) {
    const postURL = `${urlConfig.createSchedule}?restaurantId=${this.appService.restaurantId}&ignoreValidation=false`;
    return this.httpService.post(postURL, schedules);
  }

  updateSchedules(schedules: ScheduleDTO) {
    const postURL = `${urlConfig.updateSchedule}?restaurantId=${this.appService.restaurantId}&ignoreValidation=false`;
    return this.httpService.post(postURL, schedules);
  }
  getAllSchedules() {
    const getURL = `${urlConfig.getSchedules}?restaurantId=${this.appService.restaurantId}`;
    return this.httpService.get(getURL);
  }
  
  getAllServerSchedules(fromDate, toDate) {
    const getURL = `${urlConfig.getServerSchedules}?restaurantId=${this.appService.restaurantId}&startDate=${fromDate}&endDate=${toDate}`;
    return this.httpService.get(getURL);
  }

  getSchedules(serverId: number) {
    const getURL = `${urlConfig.getSchedule}?restaurantId=${this.appService.restaurantId}&serverId=${serverId}`;
    return this.httpService.get(getURL);
  }

  deleteSchedule(scheduleId: number) {
    const deleteURL = `${urlConfig.deleteSchedule}?restaurantId=${this.appService.restaurantId}&scheduleId=${scheduleId}`;
    return this.httpService.delete(deleteURL);
  }


  loadServers() {
    let isSettingsChanged: boolean = false;
    this.updateServerMappings(this.cs.settings.value.Servers, this.cs.layout?.value?.FloorPlans);
  }
  
  GetServerStats() {
    const getURL = `${urlConfig.getShiftStats}?restaurantId=${this.appService.restaurantId}`;
    return this.httpService.get(getURL);
  }


  updateServerMappings(serverList: any, layoutList: any) {
    let tableServerList = [];
    serverList?.forEach(server => {
      server.StandaloneTables = [] as StandaloneTableDTO[];
      var _mappedTable: StandaloneTableDTO[] = [] as StandaloneTableDTO[];
      layoutList?.forEach(plan => {
        let mappedTable = plan.StandaloneTables.filter(table => table.ServerId == server.Id);
        if (mappedTable && mappedTable.length > 0) {
          mappedTable.forEach(_table => _mappedTable.push(_table));
        }
      });
      server.StandaloneTables = _mappedTable;

    });
    tableServerList = _.cloneDeep(serverList);
    this.serverDetails.next(tableServerList);
  }

  

  // loadServers() {
  //   let isSettingsChanged: boolean = false;
  //   this.cs.settings.subscribe((value: SettingsDTO) => {
  //     isSettingsChanged = true;
  //     const serverList = value.Servers;
  //     let processedServers: number = 0;
  //     serverList.forEach(server => {
  //       server.StandaloneTables = [] as StandaloneTableDTO[];
  //       this.cs.layout.subscribe(_lyt => {
  //         processedServers++;
  //         var _mappedTable : StandaloneTableDTO[] = [] as StandaloneTableDTO[];
  //         this._layout.FloorPlans.forEach(plan => {
  //           let mappedTable = plan.StandaloneTables.filter(table => table.ServerId == server.Id);
  //           if (mappedTable && mappedTable.length > 0) {
  //             mappedTable.forEach(_table => _mappedTable.push(_table));
  //           }
  //         });
  //         server.StandaloneTables = _mappedTable;
  //         if (!isSettingsChanged && processedServers == serverList.length) {
  //           this.serverDetails.next(serverList);
  //           processedServers = 0;
  //           isSettingsChanged = false;
  //         }
  //       });
  //     });
  //     if (isSettingsChanged) {
  //       this.serverDetails.next(serverList);
  //       isSettingsChanged = false;
  //       processedServers = 0;
  //     }
  //   });
  // }

  mapServerColors(details) {
    //const serverAreas: AutoServerAreaDTO[] = this._state.AutoServerAreas;
    this.subscriptions.add(this.cs.state.subscribe(st => {
      details.forEach((server) => {
        const serverColor = this.cs.state.value?.AutoServerAreas.filter((serverArea) => serverArea.ServerId === server.Id);
        if (serverColor.length > 0) {
          server.color = `rgba(${serverColor[0].Color.R}, ${serverColor[0].Color.G}, ${serverColor[0].Color.B}, ${serverColor[0].Color.A})`;
        }
      });
    }));
  }

  createCustomBlockorBreakHours(customBlockorBreakHourRequest: StaffBlockDTO): Observable<any> {
    const postURL = `${urlConfig.CreateCustomBlockorBreakHours}?propertyId=${this.appService.restaurantId}&ignoreValidation=false`;
    return this.httpService.post(postURL, customBlockorBreakHourRequest);
  }
  removeBlockorBreakHours(breakHourId: number) {
    const postURL = `${urlConfig.RemoveBreakorBlockHours}?propertyId=${this.appService.restaurantId}&breakHourId=${breakHourId}`;
    return this.httpService.post(postURL, {});
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
