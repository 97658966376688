import { Component,Input, OnInit,Output, Pipe, PipeTransform } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { SettingsDTO, SettingType, StandaloneTableDTO } from '@app/shared/models/RestaurantDTO';
import { buttonTypes, ComponentTypes, ContentView, DayOfWeek, Menu, OperationResultState, PartyNoteType, PartyState, PartyStatus, PartyType, PopupType, ReservationStatus, ReservationType, TableBlockBy, TableBlockingRuleFrequencyType, TableStatus } from '@constants/commonenums';
@Component({
  selector: 'app-print-container',
  templateUrl: './print-container.component.html',
  styleUrls: ['./print-container.component.scss']
})
export class PrintContainerComponent implements OnInit {
  contentView: ContentView;
  ContentView = ContentView;
  isReservation: boolean;
  includePartyNotes = true;  
  includeContactNotes = true;
  includeAddons = true;
  includeGuestTypes = true;
  @Input() selectedParties:any;
  @Input() printOnEachPage:boolean = false;
  _settings: SettingsDTO = {} as SettingsDTO;
  ReservationType = ReservationType;
  standAloneTables = [];
  dateFormat: string = "dd-MM-yyyy hh:mm a";
  constructor(private _cs: CacheService) { }
  ngOnInit(): void {
    this.setStandaloneTables();
    this._settings = this._cs.settings.value;
    if(this._settings?.General?.DateFormat) {
      this.dateFormat = this._settings?.General?.DateFormat + ' hh:mm a';
      this.dateFormat = this.dateFormat.replace('DD', 'dd');
    }
    this.includePartyNotes = this._settings?.MerchantSettings?.IncludeNotes?.SettingValue == SettingType.ENABLE ? true : false;
    this.includeContactNotes = this._settings?.MerchantSettings?.IncludeContactNotes?.SettingValue == SettingType.ENABLE ? true : false;
    this.includeGuestTypes = this._settings?.MerchantSettings?.IncludeCovers?.SettingValue == SettingType.ENABLE ? true : false;
    this.includeAddons = this._settings?.MerchantSettings?.IncludeAddOns?.SettingValue == SettingType.ENABLE ? true : false;
  }

  setStandaloneTables() {
    this.standAloneTables = [];
    const floorPlan = this._cs.layout.value.FloorPlans;
    for (let i = 0; i < floorPlan.length; i++) {
      let Tables = floorPlan[i].StandaloneTables;
      Tables.forEach(element => {
        this.standAloneTables.push(element);
      });
    }
  }
  
}




@Pipe({
  name: 'getTableNameFromId'
})
export class GetTableName implements PipeTransform {

  transform(tableIds: number[], standAloneTables: StandaloneTableDTO[]): any {
   let tableName = '';
    tableIds?.forEach(tableId => {      
      tableName = tableName +  (standAloneTables.find(x => x.Id == tableId)?.Name || '') + ' ';

    })
    return tableName.trim().split(' ').join(',');
  }
}
