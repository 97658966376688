import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { RetailLocalization } from '../common/localization/retail-localization';
import { BaseResponse, UserBreakPoint, ImageData } from '../shared/business/shared.modals';
import { HttpServiceCall, HttpMethod } from '../shared/service/http-call.service';
import { Host, RetailBreakPoint, ImgRefType, SyncStatus, SourceSystem } from '../shared/globalsContant';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { BreakPointAccess } from '../shared/service/breakpoint.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject, Subscription, SubscriptionLike as ISubscription } from 'rxjs';
import {
  ConfiguredTax, InventoryInfo, InventoryOutlet,
  InventoryOutletToDisplay, ItemDetailInfo, LetterTemplateType, OutletItem, PackagedItem,
  QuickSaleCategory, QuickSaleItem, RetailItem, MultiPackDetail,
  RetailItemBarCode, RetailItemDetail, RetailItemTax, RetailItemTemplateConfig, RetailItemVendor,
  RetailMenu, RoomTypeAssignmentRetailItem, RoomTypeAssignmentRetailItemMapping, SelectedTaxForRetailItem, TaxConfiguration, UnitOfMeasure, SyncDetailInfo, UserInfo, CancelInventorySync, RentalItemInformation, RentalItemQuantity, RentalItemSlabRates, RentalItemRateType, RetailItemType
} from '../retail.modals';
import { RetailService } from '../retail.service';
import { ImageProcessorService } from '../shared/service/image-processor-service';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { RetailDataAwaiters } from '../shared/events/awaiters/retail.data.awaiters';
import { RetailItems } from '../package/PackageComponent/packagecomponent-model';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { Vendor } from '../retail-vendor-setup/retail-vendor-setup.modals';
import { RetailRoutes } from '../retail-route';
import { ReplaySubject } from 'rxjs';
import { CommonVariablesService } from '../shared/service/common-variables.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class RetailSetupService {

  outlets: InventoryOutletToDisplay[];
  allActiveOutlets: InventoryOutletToDisplay[];
  public selectedOutletMgrArr = new BehaviorSubject<any[]>([]);
  public retailItemUpdateEvent = new Subject<number>();
  public retailItemDescChangeEvent = new BehaviorSubject<string>('');
  public retailItemUpdateCommissionsubscription: ISubscription;
  packagedItemForm;
  InventoryGrp: UntypedFormGroup;
  quickSaleItem: any = [];
  quickSaleCategory: QuickSaleCategory[] = [];
  quickSaleItemList: QuickSaleItem[] = [];
  itemcatradiogrp: any = [];
  retailTable: any[];
  categories: Array<any> = [];
  categoriesDetails: Array<any> = [];
  allowCategory: boolean = false;
  selectedCategoryArray: Array<any> = [];
  selectedCategoryArrayDetails: Array<any> = [];
  selectAllowEarn: boolean = false;
  selectedSubCategory1Array: Array<any> = [];
  selectedSubCategory2Array: Array<any> = [];
  selectedSubCategory3Array: Array<any> = [];
  selectedSubCategory4Array: Array<any> = [];
  selectedSubCategory5Array: Array<any> = [];
  subCategoryOne: Array<any> = [];
  subCategoryTwo: Array<any> = [];
  subCategoryThree: Array<any> = [];
  subCategoryFour: Array<any> = [];
  subCategoryFive: Array<any> = [];
  ChildSubCategories: Array<any> = [];
  availableTaxes: SelectedTaxForRetailItem[] = [];
  public validateBtn = new BehaviorSubject<boolean>(false);
  captions: any;
  commonCaptions: any;
  shopItems: any[];
  packagedItems: any[];
  vendorItems: any[];
  originalItemDetail: any;
  inventoryInfo: InventoryInfo;
  packagedItemEdited: boolean = false;
  inventoryItemEdited: boolean = false;
  isCopyItem: boolean = false;
  retailSetupBreakPoints: UserBreakPoint[] = [];
  retailBreakPoints: UserBreakPoint[] = [];
  unitOfMeasure: UnitOfMeasure[] = [];
  public selectedOutlets: any[];
  selectedOutletSubscription: Subscription;
  IsViewOnly = false;
  AllActiveTaxes: ConfiguredTax[] = [];
  PreviousSelectedOutletMgrArr: any;
  selectedOutletTaxArray: any;
  isTaxAvailableOtherOutlet = false;
  allRetailItemWithOutletInfo: any = [];
  loginProd: string;
  isValidOtherInfo = false;
  SubCategory1: any = [];
  SubCategory2: any = [];
  SubCategory3: any = [];
  SubCategory4: any = [];
  SubCategory5: any = [];
  public selectedOutlet: any[] = [];
  isLoadCategoryMapping: boolean = false;
  isEdit: boolean;
  url: any;
  editImageId: any;
  sequenceNo: any;
  isImageRemoved: boolean;
  ImageUploaded: boolean = false;
  base64textString: any;
  selectedFile: File;
  thumbnailImg: any;
  oldMargin: any;
  minStartDate = this.PropertyInfo.CurrentDate;
  minEndDate = this.PropertyInfo.CurrentDate;
  maxStartDate: any;
  functionalities: { [key: string]: boolean } = {};
  isMultipleLinkedItem: boolean = false;
  tabLoaderEnable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  allBarCodesInfo: RetailItemBarCode[] = [];
  ShowOutletSelectionFieldInCreateRetailItem = true;
  isCustomFeeItem: boolean = false;
  rentalTableContent = new Subject<any[]>();
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  multipackFormGrpEmitter = new EventEmitter<boolean>();
  public vendorInfo: any[] = [];
  public vendorAPIInfo: RetailItemVendor[] = [];
  public VendorInfoChanges: boolean = false;
  public vendors: Vendor[] = [];
  public get ProductId() {
    return Number(this.utils.GetPropertyInfo('ProductId'));
  }

  public itemInfoLoaded = new Subject<void>();
  selectedRoomTypes: RoomTypeAssignmentRetailItem[];
  showRetailSetupRoomTypesAssignmentTab: boolean = false;
  rentalItemQuantities: RentalItemQuantity[];
  rentalItemSlabRates: RentalItemSlabRates[] ;
  constructor(private Form: UntypedFormBuilder, public localization: RetailLocalization, private http: HttpServiceCall, private utils: RetailUtilities, private breakpoint: BreakPointAccess,
    private _authenticatoin: TenantManagementCommunication,
    private PropertyInfo: RetailPropertyInformation,
    private imgService: ImageProcessorService,
    private business: RetailService,
    private domSanitizer: DomSanitizer,
    private func: RetailFunctionalityBusiness, private _commonVarService: CommonVariablesService) {
    this.captions = this.localization.captions.retailsetup;
    this.commonCaptions = this.localization.captions.common;
    this.itemInfoLoaded = new Subject<void>();
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
      if (this.functionalities.ShowMultipleLinkedRetailItemForMultipack) {
        this.isMultipleLinkedItem = true;
      }
    });
    /**
    * @description Every Default Value inject the Input text, Select, toogle
    */


    this.packagedItemForm = this.Form.group({
      PI_searchText: [''],
      PI_Category1: [''],
      PI_Category2: [''],
      PI_Category3: [''],
      PI_Category4: [''],
      PI_Category5: [''],
      PI_Category6: ['']
    });

    this.InventoryGrp = this.Form.group({
      supplier: [''],
      supplierItem: '',
      leadDays: '',
      inactive: false
    });

    this.quickSaleItem = [];
    this.quickSaleItemList = [];
  }

  isMultipack: boolean = false;
  GeneralFormGrp = this.Form.group({
    copyitem: '',
    autogenerateno: false,
    itemnumber: [''],
    duplicateItemNumber: '',
    itemdescription: '',
    salesprice: '',
    memberprice: '',
    suggestedprice: '',
    curitemcost: '',
    marginpercent: '',
    barcode: '',
    secbarcode: '',
    commissionableitem: false,
    commissionreq: false,
    taxConfig: this.Form.array([]),
    inventory: false,
    itemcatradiogrp: ['', Validators.required],
    categoryid: '',
    subcategory1: '',
    subcategory2: '',
    subcategory3: '',
    subcategory4: '',
    subcategory5: '',
    allowEarn: false,
    disposableItem: false,
    rentalItem: false
  });
  otherInfoFormGrp = this.Form.group({
    groupKey: false,
    scaleItem: false,
    gratuity: false,
    serviceTax: false,
    inventoryItems: false,
    itemType: false,
    timeTrigger: false,
    cartTrigger: false,
    printCart: false,
    printClub: false,
    printShoe: false,
    printShack: false,
    printOne: false,
    printSaleChit: false,
    hangTicket: false,
    sticker: false,
    inactive: false,
    requireComments: false,
    requestName: false,
    openItem: false,
    pms: false,
    multipack: false,
    unit: '',
    gratuityValue: '',
    serviceTaxValue: '',
    perHourRate: '',
    perDayRate: '',
    cartTemplate: '',
    clubTemplate: '',
    shoeTemplate: '',
    saleChit: ''
  });
  seasonalPriceFormGrp = this.Form.group({
    startDate: [''],//today date should not be set there
    endDate: [''],//today date should not be set there
    price: [''],
    memberPrice: ['']
  });

  multipackFormGrp = this.Form.group({
    dateofsalestart: ['dateofsale'],
    dateofexpireend: ['expiration'],
    specificstartdate: '',
    expireenddate: '',
    unlimited: false,
    category: '',
    item: ['', Validators.required],
    itemId: '',
    description: '',
    salesprice: '',
    memberprice: '',
    cost: '',
    service: '',
    gratutity: '',
    persale: ['', Validators.required],
    expiryDays: '',
    multipackArr: this.Form.array([
      this.Form.group({
        category: '',
        item: ['', Validators.required],
        itemId: [''],
        description: '',
        salesprice: '',
        memberprice: '',
        cost: '',
        service: '',
        gratutity: ''
      }),

    ])
  });
  rentalFormGrp = this.Form.group({
    rateType: 0,
    rate: ['', Validators.required],
    availableOnWeb: false,
    outofOrderQuantity: '',
    bufferTime: '',
    quantity: ['', Validators.required],
    tableContent: [],
    hourlyRate: this.Form.array([
      this.Form.group({
        slabStart: [1, Validators.required],
        slabEnd: ['', Validators.required],
        amount: ['', Validators.required]
      })
    ])
  });
  multipackArr: UntypedFormArray;
  rentalItemInformation: RentalItemInformation;
  async AddCatSubCatAndLinkAndMeasure(body: any): Promise<any> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'CreateCatSubCatAndLinkAndMeasure',
      host: Host.retailManagement,
      method: HttpMethod.Post,
      body: body,
    });

    let response1 = <any>result.result;
    let response = response1.categoryData;

    this.categories = response.category ? this.utils.sortObj(response.category, 'listOrder') : [];
    this.subCategoryOne = response.subCategoryOne ? this.utils.sortObj(response.subCategoryOne, 'listOrder') : [];
    this.subCategoryTwo = response.subCategoryTwo ? this.utils.sortObj(response.subCategoryTwo, 'listOrder') : [];
    this.subCategoryThree = response.subCategoryThree ? this.utils.sortObj(response.subCategoryThree, 'listOrder') : [];
    this.subCategoryFour = response.subCategoryFour ? this.utils.sortObj(response.subCategoryFour, 'listOrder') : [];
    this.subCategoryFive = response.subCategoryFive ? this.utils.sortObj(response.subCategoryFive, 'listOrder') : [];

    this.unitOfMeasure = response1.unitOfMeasure ? this.utils.sortObj(response1.unitOfMeasure, 'listOrder') : [];
    return true;
  }

  async ImportRetailitems(body: any): Promise<any> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'ImportRetailItem',
      host: Host.retailManagement,
      method: HttpMethod.Post,
      body: body,
    });
    return <any>result.result;
  }
  async getRetailItemsBySearch(search: string): Promise<BaseResponse<RetailItems[]>> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'GetRetailItemsByInventoryItem',
      host: Host.retailManagement,
      method: HttpMethod.Get,
      uriParams: { searchRetailItem: search },
    });
    return result;
  }

  async getRetailItemByInventoryId(id: number): Promise<BaseResponse<RetailItems>> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'GetRetailItemDetailByInventryId',
      host: Host.retailManagement,
      method: HttpMethod.Get,
      uriParams: { inventoryId: id },
    });
    return result;


  }

  async ValidatePreviousImport(): Promise<any> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'ValidateImport',
      host: Host.retailManagement,
      method: HttpMethod.Get,
    });
    return <any>result;
  }

  async GetImportStatus(): Promise<ItemDetailInfo[]> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'ImportStatus',
      host: Host.retailManagement,
      method: HttpMethod.Get,
    });
    return <any>result;
  }
  async GetSyncStatus(): Promise<SyncDetailInfo> {
    let result: any = await this.http.CallApiAsync({
      callDesc: RetailRoutes.InventorySyncStatus,
      host: Host.retailManagement,
      method: HttpMethod.Get,
    });
    console.log(result)
    return result.result;
  }

  async CancelInventorySync(model: CancelInventorySync): Promise<boolean> {
    let result: any = await this.http.CallApiAsync({
      callDesc: RetailRoutes.CancelInventorySync,
      host: Host.retailManagement,
      method: HttpMethod.Put,
      body: model
    });
    console.log(result)
    return result.result;
  }

  async getClerkInfo(): Promise<UserInfo[]> {
    const result = await this.http.CallApiAsync({
      callDesc: 'GetUserInfoByPropertyId',
      method: HttpMethod.Get,
      host: Host.authentication,
      uriParams: {
        propertyId: Number(this.utils.GetPropertyInfo('PropertyId')),
        productId: Number(this.utils.GetPropertyInfo('ProductId'))
      }
    });
    const response: any = result && result.result ? result.result : [];
    return response.map((element) => {
      return {
        id: element.userId,
        firstName: element.firstName,
        lastName: element.lastName,
        userName: element.userName
      };
    });
  }
  async Importitems(body: any, _isAuthorized: boolean): Promise<any> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'ImportItem',
      host: Host.retailManagement,
      method: HttpMethod.Post,
      body: body,
      uriParams: { isAuthorised: _isAuthorized },
    });
    return <any>result;
  }

  MapIsAuthorizedRetailMenu(service: RetailMenu[]): RetailMenu[] {
    let _service: RetailMenu[] = [...service];
    const bpNos: number[] = service.map(s => s.breakPointNumber);
    this.retailBreakPoints = this.breakpoint.GetBreakPoint(bpNos).result;
    _service.forEach(s => {
      if (s.breakPointNumber && s.breakPointNumber > 0) {
        s.IsAuthorized = this.IsActionAllowed(s.breakPointNumber);
        s.isViewOnly = this.isViewOnly(s.breakPointNumber)
      }
    });
    return _service;
  }

  //Initial Load of Retail Setup BreakPoints
  GetRetailBreakPoints() {
    this.retailSetupBreakPoints = this.breakpoint.GetBreakPoint([
      RetailBreakPoint.creditcardterminal,
      RetailBreakPoint.OutletSetup,
      RetailBreakPoint.ItemSetup,
      RetailBreakPoint.QuickSaleKeys,
      RetailBreakPoint.QuickSaleCategories,
      RetailBreakPoint.CategoryLinking,
      RetailBreakPoint.UnitofMeasure,
      RetailBreakPoint.SplitCommissionTemplateSetup,
      RetailBreakPoint.PaymentMethods,
      RetailBreakPoint.OutletSetup,
      RetailBreakPoint.RetailCategories,
      RetailBreakPoint.RetailSubCategories,
      RetailBreakPoint.CategoryGroup,
      RetailBreakPoint.Taxconfiguration,
      RetailBreakPoint.DiscountType,
      RetailBreakPoint.DiscountConfiguration,
      RetailBreakPoint.VATConfiguration,
      RetailBreakPoint.RetailFeatureConfiguration,
      RetailBreakPoint.CreditCards,
      RetailBreakPoint.GiftCards,
      RetailBreakPoint.PostTypeMapping,
      RetailBreakPoint.MachineNames,
      RetailBreakPoint.StoreTerminal,
      RetailBreakPoint.VendorType,
      RetailBreakPoint.VendorSetup,
      RetailBreakPoint.DiscountReason,
      RetailBreakPoint.MemberAccrualMapping,
      RetailBreakPoint.ResortFinancePostTypeMapping,
      RetailBreakPoint.GeneralLedgerMapping,
      RetailBreakPoint.NonIntegratedCreditCards]).result;
  }
  FormBodyData(isEdit: boolean, useRetailInterface: boolean): any {

    let item;
    if (isEdit) {
      item = this.originalItemDetail.retailItemDetail
    }
    // Get ProductFunctionality :
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
      if (this.functionalities.ShowMultipleLinkedRetailItemForMultipack) {
        this.isMultipleLinkedItem = true;
      }
    });

    this.isEdit = isEdit;
    const tempMultiExpDay = this.multipackFormGrp.controls.expiryDays.value == null ? null : Number(this.multipackFormGrp.controls.expiryDays.value);
    const isRentalSelected = Number(this.GeneralFormGrp.controls.itemcatradiogrp.value) === 7;
    let retailItem: RetailItemDetail =
    {
      id: isEdit ? item.id : 0,
      externalPOSId: isEdit ? item.externalPOSId : 0,
      itemNumber: isEdit ? item.itemNumber : this.GeneralFormGrp.controls.itemnumber.value,
      itemDescription: this.GeneralFormGrp.controls.itemdescription.value, //originalRetailItem.itemDescription,
      salesPrice: this.localization.currencyToSQLFormat(this.GeneralFormGrp.controls.salesprice.value),
      memberPrice: this.localization.currencyToSQLFormat(this.GeneralFormGrp.controls.memberprice.value),
      suggestedPrice: 0,
      costPrice: this.localization.currencyToSQLFormat(this.GeneralFormGrp.controls.curitemcost.value),
      marginPercentage: this.localization.currencyToSQLFormat(this.GeneralFormGrp.controls.marginpercent.value),
      barcode: this.GeneralFormGrp.controls.barcode.value ? this.GeneralFormGrp.controls.barcode.value : '',
      secondaryBarcode: this.GeneralFormGrp.controls.secbarcode.value ? this.GeneralFormGrp.controls.secbarcode.value : '',
      category: this.GeneralFormGrp.controls.categoryid.value ? this.GeneralFormGrp.controls.categoryid.value : 0,
      isCommissionable: this.GeneralFormGrp.controls.commissionableitem.value ? this.GeneralFormGrp.controls.commissionableitem.value : false,
      isCommissionRequired: this.GeneralFormGrp.controls.commissionreq.value ? this.GeneralFormGrp.controls.commissionreq.value : false,
      itemType: this.GeneralFormGrp.controls.itemcatradiogrp.value ? Number(this.GeneralFormGrp.controls.itemcatradiogrp.value) : 0,
      isGroupingKey: this.otherInfoFormGrp.controls.groupKey.value ? this.otherInfoFormGrp.controls.groupKey.value : false,
      isScaledItem: this.otherInfoFormGrp.controls.scaleItem.value ? this.otherInfoFormGrp.controls.scaleItem.value : false,
      unitOfMeasure: this.otherInfoFormGrp.controls.unit.value,
      isTeeTimeTrigger: this.otherInfoFormGrp.controls.timeTrigger.value ? this.otherInfoFormGrp.controls.timeTrigger.value : false,
      isCartTrigger: this.otherInfoFormGrp.controls.cartTrigger.value ? this.otherInfoFormGrp.controls.cartTrigger.value : false,
      isActive: this.otherInfoFormGrp.controls.inactive.value ? !this.otherInfoFormGrp.controls.inactive.value : true,
      isRequestName: this.otherInfoFormGrp.controls.requestName.value ? this.otherInfoFormGrp.controls.requestName.value : false,
      isPMSPackageItem: this.otherInfoFormGrp.controls.pms.value ? this.otherInfoFormGrp.controls.pms.value : false,
      isRequireComments: this.otherInfoFormGrp.controls.requireComments.value ? this.otherInfoFormGrp.controls.requireComments.value : false,
      isOpenItem: this.otherInfoFormGrp.controls.openItem.value ? this.otherInfoFormGrp.controls.openItem.value : false,
      isMultiPack: this.otherInfoFormGrp.controls.multipack.value ? this.otherInfoFormGrp.controls.multipack.value : false,
      isGratuityAllowed: this.otherInfoFormGrp.controls.gratuity.value ? this.otherInfoFormGrp.controls.gratuity.value : false,
      gratuity: this.otherInfoFormGrp.controls.gratuity.value && this.otherInfoFormGrp.controls.gratuityValue.value ? Number(this.localization.currencyToSQLFormat(this.otherInfoFormGrp.controls.gratuityValue.value)) : 0,
      isServiceChargeAllowed: this.otherInfoFormGrp.controls.serviceTax.value ? this.otherInfoFormGrp.controls.serviceTax.value : false,
      serviceCharge: this.otherInfoFormGrp.controls.serviceTax.value && this.otherInfoFormGrp.controls.serviceTaxValue.value ? Number(this.localization.currencyToSQLFormat(this.otherInfoFormGrp.controls.serviceTaxValue.value)) : 0,
      isPrintCartAgreement: this.otherInfoFormGrp.controls.printCart.value ? this.otherInfoFormGrp.controls.printCart.value : false,
      isPrintClubAgreement: this.otherInfoFormGrp.controls.printClub.value ? this.otherInfoFormGrp.controls.printClub.value : false,
      isPrintShoeAgreement: this.otherInfoFormGrp.controls.printShoe.value ? this.otherInfoFormGrp.controls.printShoe.value : false,
      isPrintToCaddyShack: this.otherInfoFormGrp.controls.printShack.value ? this.otherInfoFormGrp.controls.printShack.value : false,
      isPrintOnlyOnePerTansaction: this.otherInfoFormGrp.controls.printOne.value ? this.otherInfoFormGrp.controls.printOne.value : false,
      isHangingTicket: this.otherInfoFormGrp.controls.hangTicket.value ? this.otherInfoFormGrp.controls.hangTicket.value : false,
      isSmallTicket: this.otherInfoFormGrp.controls.sticker.value ? this.otherInfoFormGrp.controls.sticker.value : false,
      isPrintRetailSaleChit: this.otherInfoFormGrp.controls.printSaleChit.value ? this.otherInfoFormGrp.controls.printSaleChit.value : false,
      linkedItemId: this.multipackFormGrp.controls.itemId.value ? Number(this.multipackFormGrp.controls.itemId.value) : 0,
      multiStartDate: this.multipackFormGrp.controls.dateofsalestart.value === 'specific' ? this.utils.formatDate(this.multipackFormGrp.controls.specificstartdate.value) : null,
      multiEndDate: this.multipackFormGrp.controls.dateofexpireend.value === 'specific' ? this.utils.formatDate(this.multipackFormGrp.controls.expireenddate.value) : '',
      multiExpDays: this.multipackFormGrp.controls.dateofexpireend.value === 'expiration' ? tempMultiExpDay : null,
      multiSalesPrice: this.multipackFormGrp.controls.unlimited.value ? null : Number(this.localization.currencyToSQLFormat(this.multipackFormGrp.controls.salesprice.value)),
      multiMemberPrice: this.multipackFormGrp.controls.unlimited.value ? null : Number(this.localization.currencyToSQLFormat(this.multipackFormGrp.controls.memberprice.value)),
      multiCostPrice: this.multipackFormGrp.controls.unlimited.value ? null : Number(this.localization.currencyToSQLFormat(this.multipackFormGrp.controls.cost.value)),
      multiGratuity: this.multipackFormGrp.controls.unlimited.value ? null : Number(this.localization.currencyToSQLFormat(this.multipackFormGrp.controls.gratutity.value)),
      multiServiceCharge: this.multipackFormGrp.controls.unlimited.value ? null : Number(this.localization.currencyToSQLFormat(this.multipackFormGrp.controls.service.value)),
      multiPerSale: this.multipackFormGrp.controls.unlimited.value ? null : Number(this.multipackFormGrp.controls.persale.value),
      isMultiUnlimited: this.multipackFormGrp.controls.unlimited.value ? this.multipackFormGrp.controls.unlimited.value : false,
      seasonalStartDate: this.seasonalPriceFormGrp.controls.startDate.value ? this.utils.formatDate(this.seasonalPriceFormGrp.controls.startDate.value) : '',
      seasonalEndDate: this.seasonalPriceFormGrp.controls.endDate.value ? this.utils.formatDate(this.seasonalPriceFormGrp.controls.endDate.value) : '',
      seasonalPrice: Number(this.localization.currencyToSQLFormat(this.seasonalPriceFormGrp.controls.price.value)),
      seasonalMemberPrice: Number(this.localization.currencyToSQLFormat(this.seasonalPriceFormGrp.controls.memberPrice.value)),
      useInventory: this.GeneralFormGrp.controls.inventory.value ? this.GeneralFormGrp.controls.inventory.value : false,
      subCategory1: this.GeneralFormGrp.controls.subcategory1.value ? this.GeneralFormGrp.controls.subcategory1.value : 0,
      subCategory2: this.GeneralFormGrp.controls.subcategory2.value ? this.GeneralFormGrp.controls.subcategory2.value : 0,
      subCategory3: this.GeneralFormGrp.controls.subcategory3.value ? this.GeneralFormGrp.controls.subcategory3.value : 0,
      subCategory4: this.GeneralFormGrp.controls.subcategory4.value ? this.GeneralFormGrp.controls.subcategory4.value : 0,
      subCategory5: this.GeneralFormGrp.controls.subcategory5.value ? this.GeneralFormGrp.controls.subcategory5.value : 0,
      isItemNumberAutogenerate: this.GeneralFormGrp.controls.autogenerateno.value ? this.GeneralFormGrp.controls.autogenerateno.value : false,
      allowEarn: this.GeneralFormGrp.controls.allowEarn.value ? this.GeneralFormGrp.controls.allowEarn.value : false,
      disposableItem: this.GeneralFormGrp.controls.disposableItem.value ? this.GeneralFormGrp.controls.disposableItem.value : false,
      perDayRentalRate: this.otherInfoFormGrp.controls.perDayRentalRate.value && isRentalSelected ? Number(this.localization.currencyToSQLFormat(this.otherInfoFormGrp.controls.perDayRentalRate.value)) : 0,
      perHourRentalRate: this.otherInfoFormGrp.controls.perHourRentalRate.value && isRentalSelected ? Number(this.localization.currencyToSQLFormat(this.otherInfoFormGrp.controls.perHourRentalRate.value)) : 0,
      isMultipleLinkedItem: this.isMultipleLinkedItem ? this.isMultipleLinkedItem : false,
      isExternal: isEdit ? item.isExternal : false,
      sourceSystem: isEdit ? item.sourceSystem : SourceSystem.Internal
    }
    //Feature 57221: Cart, Shoe, Club Agreement ,Retail sale Chit , Retail sale chit Not Implemented 
    //Use Build Template cinfig to map Template
    let retailItemTemplateConfig: RetailItemTemplateConfig[] = [];//this.BuildTemplateConfig(retailItem);

    let packagedItems: PackagedItem[] = [];
    if (this.otherInfoFormGrp.controls.groupKey.value && this.packagedItems && this.packagedItems.length > 0) {
      packagedItems = this.packagedItems.map(x => {
        return {
          retailItemId: isEdit ? item.id : 0,
          parentItemId: x.id,
          startDate: this.localization.convertDateObjToAPIdate(x.startDate),
          endDate: this.localization.convertDateObjToAPIdate(x.endDate),
          price: x.salesPrice ? Number(this.localization.currencyToSQLFormat(x.salesPrice)) : 0,
          memberPrice: x.memberPrice ? Number(this.localization.currencyToSQLFormat(x.memberPrice)) : 0
        }
      })
    }
    const roomTypeAssignmentInfo = this.MapRoomTypeToRetailItem(this.selectedRoomTypes.filter(x => x.selected && x.roomTypeId !== 0), isEdit ? item.id : 0)
    let outletItem: OutletItem[] = [];
    if (this.selectedOutletMgrArr.value && this.selectedOutletMgrArr.value.length > 0) {
      outletItem = this.selectedOutletMgrArr.value.map(x => {
        return {
          retailItemId: isEdit ? item.id : 0,
          outletId: x.id,
          syncStatus: SyncStatus.SYNCED
          //syncStatus: useRetailInterface ? SyncStatus.SYNCED : SyncStatus.NOTSYNCED
        }
      })
    }

    let taxesInfo: RetailItemTax[] = this.BuildRetailTaxInfo();

    let barCodeInfo: RetailItemBarCode[] = this.BuildBarCodeObject(isEdit, item);
    let multiPackDetail: MultiPackDetail[] = this.multipackFormGrp.value.multipackArr.map(element => {
      if (element.itemId && element.itemId > 0) {
        return {
          retailItemId: isEdit ? item.id : 0,
          multiCostPrice: this.multipackFormGrp.controls.unlimited.value ? null : Number(this.localization.currencyToSQLFormat(element.cost ? element.cost : 0)),
          multiSalesPrice: this.multipackFormGrp.controls.unlimited.value ? null : Number(this.localization.currencyToSQLFormat(element.salesprice ? element.salesprice : 0)),
          multiMemberPrice: this.multipackFormGrp.controls.unlimited.value ? null : Number(this.localization.currencyToSQLFormat(element.memberprice ? element.memberprice : 0)),
          multiGratuity: this.multipackFormGrp.controls.unlimited.value ? null : Number(this.localization.currencyToSQLFormat(element.gratutity ? element.gratutity : 0)),
          multiServiceCharge: this.multipackFormGrp.controls.unlimited.value ? null : Number(this.localization.currencyToSQLFormat(element.service ? element.service : 0)),
          multiPerSale: this.multipackFormGrp.controls.persale.value ? this.multipackFormGrp.controls.persale.value : 0,
          multiStartDate: this.multipackFormGrp.controls.dateofsalestart.value === 'specific' ? this.utils.formatDate(this.multipackFormGrp.controls.specificstartdate.value) : null,
          multiEndDate: this.multipackFormGrp.controls.dateofexpireend.value === 'specific' ? this.utils.formatDate(this.multipackFormGrp.controls.expireenddate.value) : '',
          multiExpDays: this.multipackFormGrp.controls.dateofexpireend.value === 'expiration' ? tempMultiExpDay : null,
          isMultiUnlimited: this.multipackFormGrp.controls.unlimited.value ? this.multipackFormGrp.controls.unlimited.value : false,
          category: element.category ? element.category : 0,
          linkedItemid: element.itemId ? element.itemId : 0
        }
      }

    }).filter(x => x);

    let vendorInfo: RetailItemVendor[] = this.MapVendorToApi(this.vendorInfo, isEdit ? item.id : 0)

    this.VendorInfoChanges = false;

    let retailItemAggregate: RetailItem = {
      retailItemDetail: retailItem, outletItem: outletItem, packagedItem: packagedItems,
      retailItemTax: taxesInfo, retailItemBarCode: barCodeInfo, retailItemVendor: vendorInfo,
      roomTypeAssignmentRetailItemMapping: roomTypeAssignmentInfo,
      retailItemTemplateConfig: retailItemTemplateConfig,
      multipackDetail: multiPackDetail
    }


    let inventoryOutlet: InventoryOutlet[] = this.selectedOutletMgrArr.value.map(x => {
      return {
        id: x.outletId ? x.outletId : 0,
        inventoryItemId: x.inventoryItemId ? x.inventoryItemId : 0,
        itemId: retailItemAggregate.retailItemDetail.id ? retailItemAggregate.retailItemDetail.id : 0,
        outletId: x.id,
        quantity: x.quantity ? x.quantity : 0,
        updatedQuantity: this.hasValue(x.updatedQuantity) ? x.updatedQuantity : x.quantity,
        itemPar: x.itemPar ? x.itemPar : 0,
        unitCost: x.unitCost ? Number(this.localization.currencyToSQLFormat(x.unitCost)) : 0,
        weightedAverageCost: x.weightedAverageCost ? Number(this.localization.currencyToSQLFormat(x.weightedAverageCost)) : 0,
        reason: x.reason ? x.reason : '',
        mtd: x.mtd ? x.mtd : 0,
        ytd: x.ytd ? x.ytd : 0
      }
    })

    let inventoryInfo: InventoryInfo = {
      id: inventoryOutlet[0].inventoryItemId ? inventoryOutlet[0].inventoryItemId : 0,
      retailItemId: retailItemAggregate.retailItemDetail.id ? retailItemAggregate.retailItemDetail.id : 0,
      outlets: inventoryOutlet
    }
    if (retailItem.itemType == RetailItemType.PMSAddOnsRentalItem) {
      if (this._commonVarService.isRentalAtOutletLevel) {
        this.rentalItemQuantities = this.rentalFormGrp.value.tableContent;
      }
      else {
        this.rentalItemQuantities = [];
        this.rentalItemQuantities.push({
          id: 0,
          rentalItemInformationId: this.rentalFormGrp.value.rentalItemInformationId || 0,
          outletId: 0,
          quantity: this.rentalFormGrp.value.quantity || 1,
          outofOrderQuantity: this.rentalFormGrp.value.outofOrderQuantity || 0
        });
      }
    }
    if (this.rentalFormGrp.value.rateType == RentalItemRateType.Hourly) {
      this.rentalItemSlabRates = this.rentalFormGrp.value.hourlyRate?.map(x => {
        return {
          id: 0,
          rentalItemInformationId: this.rentalFormGrp.value.rentalItemInformationId ? this.rentalFormGrp.value.rentalItemInformationId : 0,
          slabStart: x.slabStart ? x.slabStart : 0,
          slabEnd: x.slabEnd ? x.slabEnd : 0,
          amount: x.amount ? this.localization.currencyToSQLFormat( x.amount): 0
        }
      })
    }
    let rentalItemInformation: RentalItemInformation = {
      id: this.rentalFormGrp.value.id ? this.rentalFormGrp.value.id : 0,
      retailItemId: retailItemAggregate.retailItemDetail.id ? retailItemAggregate.retailItemDetail.id : 0,
      rateType: this.rentalFormGrp.value.rateType ? this.rentalFormGrp.value.rateType : 0,
      rate: this.rentalFormGrp.value.rate ? this.localization.currencyToSQLFormat(this.rentalFormGrp.value.rate) : 0,
      bufferTime: this.rentalFormGrp.value.bufferTime ? this.rentalFormGrp.value.bufferTime : 0,
      isQuantityAtPropertyLevel: !this._commonVarService.isRentalAtOutletLevel,
      availableOnWeb: this.rentalFormGrp.value.availableOnWeb ? this.rentalFormGrp.value.availableOnWeb : false,
      rentalItemQuantity: this.rentalItemQuantities? this.rentalItemQuantities:[],
      rentalItemSlabRates: this.rentalFormGrp.value.rateType == RentalItemRateType.Hourly ? this.rentalItemSlabRates : []
    }
    return {
      retailItem: retailItemAggregate,
      inventoryInfo: inventoryInfo,
      rentalItemInformation: rentalItemInformation
    };
  }

  MapRoomTypeToRetailItem(model: RoomTypeAssignmentRetailItem[], itemId: number): RoomTypeAssignmentRetailItemMapping[] {
    if (model && model.length > 0) {
      return model.map(r => {
        return {
          id: r.id,
          retailItemId: itemId,
          roomTypeId: r.roomTypeId
        };
      });
    }
    else {
      return [];
    }
  }

  MapVendorToApi(data, retailItem) {
    if (this.vendorInfo && this.vendorInfo.length > 0) {
      return this.vendorInfo.map(c => {
        return {
          id: c.id,
          vendorId: c.vendorId,
          retailItemId: retailItem,
          vendorCode: c.vendorCode,
          vendorName: c.vendorName,
          supplierItemId: c.supplierItemNumber,
          quantiyOnHand: Number(c.qoh),
          leadDays: Number(c.leadDays),
          minOrder: Number(c.minimumOrder),
          unitQuantity: Number(c.unitQty),
        };
      });
    } else {
      return [];
    }
  }

  hasValue(valueToBeTested) {
    if (valueToBeTested > 0 || valueToBeTested == 0) { // check if the variable has a truthy value or not.
      return true;
    } else {
      return false;
    }
  }

  GetItemToEdit(id) {
    this.http.CallApiWithCallback<any>({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetShopItem",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { itemId: id },
      extraParams: []
    });
  }
  BuildTemplateConfig(retailItem: RetailItemDetail) {
    let retailTemplateConfig: RetailItemTemplateConfig[] = [];
    if (retailItem?.isPrintClubAgreement && this.otherInfoFormGrp.controls.clubTemplate.value) {
      retailTemplateConfig.push({
        id: 0,
        retailItemId: retailItem.id,
        templateId: this.otherInfoFormGrp.controls.clubTemplate.value,
        templateType: LetterTemplateType.ClubAgreement
      })
    }
    if (retailItem?.isPrintCartAgreement && this.otherInfoFormGrp.controls.cartTemplate.value) {
      retailTemplateConfig.push({
        id: 0,
        retailItemId: retailItem.id,
        templateId: this.otherInfoFormGrp.controls.cartTemplate.value,
        templateType: LetterTemplateType.CartAgreement
      })
    }
    if (retailItem?.isPrintShoeAgreement && this.otherInfoFormGrp.controls.shoeTemplate.value) {
      retailTemplateConfig.push({
        id: 0,
        retailItemId: retailItem.id,
        templateId: this.otherInfoFormGrp.controls.shoeTemplate.value,
        templateType: LetterTemplateType.ShoeAgreement
      })
    }
    if (retailItem?.isPrintRetailSaleChit && this.otherInfoFormGrp.controls.saleChit.value) {
      retailTemplateConfig.push({
        id: 0,
        retailItemId: retailItem.id,
        templateId: this.otherInfoFormGrp.controls.saleChit.value,
        templateType: LetterTemplateType.RetailSaleChit
      })
    }
    return retailTemplateConfig;
  }
  BuildRetailTaxInfo(): RetailItemTax[] {
    const resultArr: RetailItemTax[] = [];
    const taxConfig = this.GeneralFormGrp.controls["taxConfig"] as UntypedFormArray;;
    for (const tax of this.AllActiveTaxes) {
      const selectedTaxname = tax.taxName;
      let taxId = tax.id;
      if (!this.PropertyInfo.IsVATEnabled) {
        for (const config of taxConfig.value) {
          for (const res of config) {
            if (res["taxName"] === selectedTaxname && res["isSelected"] && res["taxId"] === taxId) {

              resultArr.push({
                id: 0,
                retailItemId: 0,
                taxName: selectedTaxname,
                taxId: taxId ? taxId : 0
              });
            }
          }
        }
      } else if (this.PropertyInfo.IsVATEnabled) {
        for (const val of taxConfig.value) {
          if (val["taxName"] === selectedTaxname && val["isSelected"]) {
            resultArr.push({
              id: 0,
              retailItemId: 0,
              taxName: selectedTaxname,
              taxId: taxId ? taxId : 0
            });
          }

        }
      }

    }
    return resultArr;
  }

  BuildBarCodeObject(isEdit: boolean, item: any): RetailItemBarCode[] {
    let barcode = this.GeneralFormGrp.controls.barcode.value ? this.GeneralFormGrp.controls.barcode.value : '';
    let secondaryBarcode = this.GeneralFormGrp.controls.secbarcode.value ? this.GeneralFormGrp.controls.secbarcode.value : '';
    let barCodeObj: RetailItemBarCode[] = [];
    if (barcode != '') {
      barCodeObj.push({
        id: 0,
        retailItemId: isEdit ? item.id : 0,
        barCode: barcode,
        isPrimary: true,
        isSecondary: false
      });
    }
    if (secondaryBarcode != '' || isEdit) {
      barCodeObj.push({
        id: 0,
        retailItemId: isEdit ? item.id : 0,
        barCode: secondaryBarcode,
        isPrimary: false,
        isSecondary: true
      });
    }
    return barCodeObj;
  }

  ResetControls() {
    this.GeneralFormGrp.reset();
    this.otherInfoFormGrp.reset();
    this.packagedItemForm.reset();
    this.multipackFormGrp.reset();
    this.seasonalPriceFormGrp.reset();
    this.rentalFormGrp.reset();
    this.packagedItems = null;
    this.vendorItems = [];
    this.isValidOtherInfo = false;
    this.GeneralFormGrp.controls.taxConfig = new UntypedFormArray([]);
    this.isCopyItem = false;
    this.SubCategory1 = [];
    this.SubCategory2 = [];
    this.SubCategory3 = [];
    this.SubCategory4 = [];
    this.SubCategory5 = [];
    this.editImageId = '';
    this.url = '';
    this.vendorAPIInfo = [];
    this.vendorInfo = [];
    this.VendorInfoChanges = false;
  }

  ValidateUpdate() {
    let _retailSetupIsViewOnly: boolean = this.retailSetupBreakPoints.some(bp => bp.breakPointNumber == RetailBreakPoint.ItemSetup) ? this.retailSetupBreakPoints.find(bp => bp.breakPointNumber == RetailBreakPoint.ItemSetup).view : false;
    if (!_retailSetupIsViewOnly) {
      if (this.otherInfoFormGrp.controls["multipack"].value) {
        this.validateBtn.next((this.otherInfoFormGrp.valid && this.packagedItemForm.valid &&
          this.multipackFormGrp.valid && this.seasonalPriceFormGrp.valid && this.InventoryGrp.valid)
          && ((this.otherInfoFormGrp.touched && this.otherInfoFormGrp.dirty) ||
            (this.GeneralFormGrp.touched && this.GeneralFormGrp.dirty) ||
            (this.packagedItemForm.touched || this.packagedItemForm.dirty) ||
            (this.InventoryGrp.touched || this.InventoryGrp.dirty) ||
            (this.multipackFormGrp.touched || this.multipackFormGrp.dirty))
          && (this.otherInfoFormGrp.controls.groupKey.value ? this.packagedItems && this.packagedItems.length > 0 : true))

      }
      else {
        this.validateBtn.next(((this.GeneralFormGrp.dirty && this.GeneralFormGrp.touched) ||
          (this.otherInfoFormGrp.valid && this.otherInfoFormGrp.dirty && this.otherInfoFormGrp.touched) ||
          (this.packagedItemForm.valid && this.packagedItemForm.dirty && this.packagedItemForm.touched) ||
          (this.multipackFormGrp.valid && this.multipackFormGrp.dirty && this.multipackFormGrp.touched) ||
          (this.VendorInfoChanges) || (this.rentalFormGrp.valid && this.rentalFormGrp.dirty && this.rentalFormGrp.touched) ||
          (this.seasonalPriceFormGrp.valid && this.seasonalPriceFormGrp.dirty) || // removed touch for update button validation
          this.packagedItemEdited || this.inventoryItemEdited) && !_retailSetupIsViewOnly && this.GeneralFormGrp.valid &&
          (this.otherInfoFormGrp.controls.groupKey.value ? this.packagedItems && this.packagedItems.length > 0 : true))
      }
    }
  }


  public DisableRetailSetup() {
    this.utils.disableControls(this.GeneralFormGrp);
    this.utils.disableControls(this.otherInfoFormGrp);
    this.utils.disableControls(this.seasonalPriceFormGrp);
    this.utils.disableControls(this.packagedItemForm, ["PI_Category1"]);
    this.utils.disableControls(this.multipackFormGrp);

  }


  public AdjustMenuBasedOnRetailInterface(retailMenus: any[]) {

    let configsToRemove = ['/settings/retailsetup/codesetup/taxes', '/settings/retailsetup/codesetup/discounttypes', '/settings/retailsetup/discountconfiguration',
      '/settings/retailsetup/codesetup/paymentmethods', '/settings/retailsetup/codesetup/creditcardterminals', '/settings/retailsetup/codesetup/vendortypes'
      , '/settings/retailsetup/vendorsetup', '/settings/retailsetup/codesetup/discountreason'];

    if (this.PropertyInfo.UseRetailInterface) {
      retailMenus = retailMenus.filter(r => !configsToRemove.includes(r.routePath));
    }

    //hiding menus for Eatec As Master
    if (this.PropertyInfo.IsEatecAsMaster) {
      let configsToRemove = ['/settings/retailsetup/codesetup/vendortypes'
        , '/settings/retailsetup/vendorsetup'];
      retailMenus = retailMenus.filter(r => !configsToRemove.includes(r.routePath));
    }
    else {
      const isEatecEnabled = this.PropertyInfo.IsEatecEnabled;
      if (!isEatecEnabled || (isEatecEnabled && this.PropertyInfo.IsRetailIC)) {
        let configToRemove = '/settings/retailsetup/eatecintegration';
        retailMenus = retailMenus.filter(x => x.routePath !== configToRemove);
      }
    }
    return retailMenus;
  }

  private compareDifference(currentArray, previosArray) {
    const result = [];
    if (currentArray && previosArray) {
      previosArray.forEach((element) => {
        if (element && !currentArray.some(x => x.id == element.id))
          result.push(element);
      });
    }
    return result;
  }

  public buildTaxArray(selectedOutletMgrArr, currentOption?: any) {
    let taxConfig = this.GeneralFormGrp.get('taxConfig') as UntypedFormArray;
    let unselectedOutlet = this.compareDifference(selectedOutletMgrArr, this.PreviousSelectedOutletMgrArr)
    this.PreviousSelectedOutletMgrArr = _.cloneDeep(selectedOutletMgrArr);
    this.availableTaxes = [];//Reset available Taxes for the Item

    selectedOutletMgrArr.forEach(element => {
      let FormGroupNew = new UntypedFormArray([]);


      if (this.PropertyInfo.IsVATEnabled) {
        this.selectedOutletTaxArray = selectedOutletMgrArr && selectedOutletMgrArr.length ? this.AllActiveTaxes : [];
      } else {
        this.selectedOutletTaxArray = this.AllActiveTaxes.filter(x => x.outletId == element.id);
        if (currentOption) {
          this.selectedOutletTaxArray = this.selectedOutletTaxArray.filter(x => x.outletId == currentOption.id);
        }
        if (!this.ShowOutletSelectionFieldInCreateRetailItem) {
          this.selectedOutletTaxArray = this.AllActiveTaxes;
        }

      }
      for (let outlet of this.selectedOutletTaxArray) {
        if (!this.PropertyInfo.IsVATEnabled && !taxConfig.value.some(x =>
          x.some(res => res.id === outlet.id && res.outletId === element.id))) {
          FormGroupNew.push(
            new UntypedFormGroup({
              outletId: new UntypedFormControl(element.id),
              outletName: new UntypedFormControl(element.name),
              id: new UntypedFormControl(outlet.id),
              taxName: new UntypedFormControl(
                outlet.taxName
              ),
              isSelected: new UntypedFormControl(this.isEdit ? false : outlet.isDefaultTax),
              taxId: new UntypedFormControl(outlet.id)
            })
          );
        } else if (this.PropertyInfo.IsVATEnabled) {
          let isTaxPresent = false;
          for (let tax of taxConfig.value) {
            if (
              tax.taxName ===
              outlet.taxName
            ) {
              isTaxPresent = true;
              break;
            }
          }
          if (!isTaxPresent) {
            taxConfig.push(
              new UntypedFormGroup({
                outletId: new UntypedFormControl(element.id),
                outletName: new UntypedFormControl(element.name),
                id: new UntypedFormControl(outlet.id),
                taxName: new UntypedFormControl(
                  outlet.taxName
                ),
                isSelected: new UntypedFormControl(this.isEdit ? false : outlet.isDefaultTax)
              })
            );
          }
        }
      }
      if (FormGroupNew && FormGroupNew.length > 0) {
        taxConfig.push(FormGroupNew);
      }
      this.availableTaxes = taxConfig.value;
    });

    if (this.PropertyInfo.IsVATEnabled && selectedOutletMgrArr.length == 0) {
      this.selectedOutletTaxArray = [];
      taxConfig.clear();
    }

    if (!this.PropertyInfo.IsVATEnabled && unselectedOutlet && unselectedOutlet.length > 0) {
      const index = this.GeneralFormGrp.value.taxConfig.findIndex(x => x.every(data => data.outletId === unselectedOutlet[0].id));
      index !== -1 ? taxConfig.removeAt(index) : null;
    }
  }
  private IsActionAllowed(breakPoint: number): boolean {
    let _breakPoint: UserBreakPoint = this.retailBreakPoints.find(bp => bp.breakPointNumber == breakPoint);
    return _breakPoint ? (_breakPoint.allow || _breakPoint.view) : false;
  }

  private isViewOnly(breakPoint: number): boolean {
    let _breakPoint: UserBreakPoint = this.retailBreakPoints.find(bp => bp.breakPointNumber == breakPoint);
    return _breakPoint ? _breakPoint.view : false;
  }

  validateNext(type) {
    if (type == 'general') {
      return this.GeneralFormGrp.valid
    } else if (type == 'other') {
      return this.otherInfoFormGrp.valid
    }
  }

  InvokeServiceCall(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, body?: any, uriParams?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public cancellableObservalble(notifier: Subject<void>, route: string, domain: Host, callType: HttpMethod, body?: any, uriParams?: any): Observable<BaseResponse<any>> {
    return this.http.cancellableObservalble({
      host: domain,
      callDesc: route,
      method: callType,
      body: body,
      uriParams: uriParams
    }, notifier);
  }


  async GenerateItemNumber(): Promise<number> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'GenerateItemNumber',
      host: Host.retailManagement,
      method: HttpMethod.Get
    });
    return result.result;
  }

  async GetItemByItemNumber(itemnumber: number): Promise<any> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'GetItemByItemNumber',
      host: Host.retailManagement,
      method: HttpMethod.Get,
      uriParams: { itemnumber: itemnumber },
    });
    return result.result;
  }

   ExportRetailItem(fileType:string, body: any): Observable<Blob> {
    const downloadUrl = environment["retailManagement"] + '/' + RetailRoutes.ExportRetailItems;
    var result = this.http.putHTTPBlobData(downloadUrl, body).pipe(
      map((res: Blob) => {
        return new Blob([res], { type: fileType });
      })
    );

    return result;
  }  

  async GetAllCategory(): Promise<any> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'GetCategoryAndSubCategoryLink',
      host: Host.retailManagement,
      method: HttpMethod.Get,
      uriParams: { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
    });

    let response = <any>result.result;

    this.categories = response.category ? this.utils.sortObj(response.category, 'listOrder') : [];
    this.subCategoryOne = response.subCategoryOne ? this.utils.sortObj(response.subCategoryOne, 'listOrder') : [];
    this.subCategoryTwo = response.subCategoryTwo ? this.utils.sortObj(response.subCategoryTwo, 'listOrder') : [];
    this.subCategoryThree = response.subCategoryThree ? this.utils.sortObj(response.subCategoryThree, 'listOrder') : [];
    this.subCategoryFour = response.subCategoryFour ? this.utils.sortObj(response.subCategoryFour, 'listOrder') : [];
    this.subCategoryFive = response.subCategoryFive ? this.utils.sortObj(response.subCategoryFive, 'listOrder') : [];

  }

  async GetAllCategoryDetails(): Promise<any> {

    let result: any = await this.http.CallApiAsync({
      callDesc: 'GetAllCategories',
      host: Host.retailManagement,
      method: HttpMethod.Get,
      uriParams: { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
    });

    let response = <any>result.result;

    this.categoriesDetails = response;

  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    switch (callDesc) {
      case "GetActiveUnitOfMeasures":
        {
          if (result.result) {
            let res = <any>result.result;
            this.unitOfMeasure = res.map(x => {
              return {
                id: x.id,
                name: x.name,
                listOrder: x.listOrder,
                isActive: x.isActive
              }
            })
          }
        }
        break;

      case "GetStandAloneProducts":
        {
          if (result.result) {
            let res = <any>result.result;
            let prodId = this.utils.GetPropertyInfo('ProductId');
            this.loginProd = res.filter(x => x.id == prodId)[0].productName;
          }
        }
        break;

      case "GetShopItem":
        if (result.result) {
          let data = [];
          let res = <any>result.result;
          this.PopulateCopyItemValues(res);
        }
        break;
      case "getImagesByReference": {
        const retailItemImageDetails = result.result;
        if (retailItemImageDetails[0]) {
          this.url = `${retailItemImageDetails[0].contentType},${retailItemImageDetails[0].data}`;
          this.editImageId = retailItemImageDetails[0].id;
          this.sequenceNo = retailItemImageDetails[0].sequenceNo;
        } else {
          this.url = '';
          this.editImageId = '';
        }
      }
    }
  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }
  /**
   * @function updateLoaderState
   * @param isCallBack
   * @param isInitialLoad
   * @param callCounter
   */
  updateLoaderState(isCallBack, isInitialLoad, callCounter) {
    if (isInitialLoad) {
      if (isCallBack) {
        callCounter--;
      }
      else {
        callCounter++;
      }
      if (callCounter === 0) {
        this.tabLoaderEnable.next(false)
        isInitialLoad = false;
      }
    }
    return [isInitialLoad, callCounter];
  }

  updateRetailItemImage(retailItemId: number) {
    if (this.base64textString && typeof this.base64textString !== 'undefined') {
      const base64result = this.base64textString.split(',');
      const base64Thumbnail = this.thumbnailImg.split(',');
      const imageDataObj: ImageData = {
        referenceId: retailItemId,
        referenceType: ImgRefType.retailItem,
        data: base64result[1],
        id: this.editImageId ? this.editImageId : 0,
        thumbnailData: base64Thumbnail[1],
        contentType: base64result[0],
        sequenceNo: this.sequenceNo
      };
      if (this.editImageId) {
        this.imgService.updateImage(this.editImageId, imageDataObj, this.successCallback.bind(this), this.errorCallback.bind(this), []);
      } else {
        this.imgService.saveImage(imageDataObj, this.successCallback.bind(this), this.errorCallback.bind(this), []);
      }
    } else {
      if (this.editImageId && this.isImageRemoved) {
        this.imgService.DeleteImageByReference(retailItemId, ImgRefType.retailItem, this.successCallback.bind(this), this.errorCallback.bind(this), []);
      }
    }
  }

  saveImage(retailItemId: number) {
    if (this.base64textString && typeof this.base64textString !== 'undefined') {
      const base64result = this.base64textString.split(',');
      const base64Thumbnail = this.thumbnailImg.split(',');
      const imageDataObj: ImageData = {
        referenceId: retailItemId,
        referenceType: ImgRefType.retailItem,
        data: base64result[1],
        id: 0,
        thumbnailData: base64Thumbnail[1],
        contentType: base64result[0],
        sequenceNo: 0
      };
      this.imgService.saveImage(imageDataObj, this.successCallback.bind(this), this.errorCallback.bind(this), []);
    }
  }

  calculateItemPrice(event, type) {
    let salesPrice = this.GeneralFormGrp.controls.salesprice.value ? this.GeneralFormGrp.controls.salesprice.value : 0;
    let costPrice = this.GeneralFormGrp.controls.curitemcost.value ? this.GeneralFormGrp.controls.curitemcost.value : 0;
    let marginPercent = this.GeneralFormGrp.controls.marginpercent.value ? this.GeneralFormGrp.controls.marginpercent.value : 0;
    switch (type) {
      case "SP":
        {
          this.CalcMarginPercent(salesPrice, costPrice);
        }
        break;
      case "CP":
        {
          this.CalcMarginPercent(salesPrice, costPrice);
          this.GeneralFormGrp.controls["curitemcost"].setValue(costPrice);
        }
        break;
      case "MP":
        {

          if ((this.GeneralFormGrp.controls.curitemcost.value) && this.GeneralFormGrp.controls.salesprice.value && this.oldMargin == marginPercent) {
            this.CalcMarginPercent(salesPrice, costPrice);
          }
          else if (this.GeneralFormGrp.controls.salesprice.value && Number(this.GeneralFormGrp.controls.salesprice.value) != 0 && this.GeneralFormGrp.controls.marginpercent.value) {
            this.CalcCostPrice(salesPrice, marginPercent);
            this.oldMargin = marginPercent;
            this.GeneralFormGrp.controls["marginpercent"].setValue(this.oldMargin);
          }
          else if (this.GeneralFormGrp.controls.curitemcost.value && Number(this.GeneralFormGrp.controls.curitemcost.value) != 0 && this.GeneralFormGrp.controls.marginpercent.value) {
            this.CalcSalesPrice(costPrice, marginPercent);
            this.oldMargin = marginPercent;
            this.GeneralFormGrp.controls["marginpercent"].setValue(this.oldMargin);
          }
        }
    }

  }


  CalcCostPrice(salesPrice: string, marginPercent: string): void {
    //CP = (SP-(MP*SP))/ 100 )
    let SalesPrice = salesPrice ? this.localization.currencyToSQLFormat(salesPrice) : 0;
    let MarginPercent = marginPercent ? this.localization.currencyToSQLFormat(marginPercent) : 0;
    let result = (SalesPrice - ((SalesPrice * MarginPercent) / 100));
    result = !isFinite(result) ? 0 : Number(result.customToFixed());
    let localizedValue = this.localization.localizeCurrency(result, false);
    this.GeneralFormGrp.controls["curitemcost"].setValue(localizedValue);
    this.updateUnitCost();
  }

  CalcMarginPercent(salesPrice: string, costPrice: string): void {
    //MP = (((SP-CP)/SP)*100)
    let SalesPrice = salesPrice ? this.localization.currencyToSQLFormat(salesPrice) : 0;
    let CostPrice = costPrice ? this.localization.currencyToSQLFormat(costPrice) : 0;
    let result = ((((SalesPrice) - (CostPrice)) / (SalesPrice)) * 100);
    result = !isFinite(result) ? 0 : Number(result.toFixed(2));
    this.oldMargin = this.localization.localizePercentage(result.toString());
    this.GeneralFormGrp.controls["marginpercent"].setValue(this.oldMargin);
    this.updateUnitCost();
  }

  CalcSalesPrice(costPrice: string, marginPercent: string): void {
    let MarginPercent = marginPercent ? this.localization.currencyToSQLFormat(marginPercent) : 0;
    let CostPrice = costPrice ? this.localization.currencyToSQLFormat(costPrice) : 0;
    let result = ((CostPrice * 100) / (100 - MarginPercent));
    result = !isFinite(result) ? 0 : Number(result.customToFixed());
    let localizedValue = this.localization.localizeCurrency(result, false);
    this.GeneralFormGrp.controls["salesprice"].setValue(localizedValue);
    this.updateUnitCost();

  }

  updateUnitCost() {
    let obj = this.selectedOutlet;
    let unitCostGetter;
    obj.forEach(element => {
      unitCostGetter = element.unitCost ? this.localization.currencyToSQLFormat(element.unitCost) : 0;
      element.quantity = element.quantity ? element.quantity : 0,
        element.weightedAverageCost = element.weightedAverageCost ? this.localization.currencyToSQLFormat(element.weightedAverageCost) : 0;
      element.mtd = element.mtd ? element.mtd : 0
      element.ytd = element.ytd ? element.ytd : 0
      const tempUnitCost = element.unitCost ? this.localization.currencyToSQLFormat(element.unitCost) : 0;
      element.unitCost = this.isEdit ? tempUnitCost : this.localization.currencyToSQLFormat(this.GeneralFormGrp.controls.curitemcost.value)
    });
    this.selectedOutletMgrArr.next(obj);
  }

  async getAllTaxConfigurations() {
    this.AllActiveTaxes = await this.getAllTaxConfiguration();
  }

  public async getAllTaxConfiguration(): Promise<ConfiguredTax[]> {
    let result: any = await this.InvokeAPICalls<ConfiguredTax[]>(Host.retailManagement, "GetActiveTaxes", HttpMethod.Get);
    let taxConfigs: ConfiguredTax[] = []

    result = result || [];
    result.forEach(element => {
      if (this.utils.getDate(element.startDate) <= this.PropertyInfo.CurrentDate && this.PropertyInfo.CurrentDate <= this.utils.getDate(element.endDate)) {
        let taxConfig: ConfiguredTax = {
          id: element.id,
          outletId: element.outletId,
          taxName: element.taxName,
          isDefaultTax: element.isDefaultTax
        }
        taxConfigs.push(taxConfig);
      }
    });

    return taxConfigs;
  }

  public async InvokeAPICalls<T>(host: Host, callDesc: string, callType: HttpMethod, body?: TaxConfiguration, uRIParams?: any): Promise<T> {

    try {
      let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
        callDesc: callDesc,
        host: host,
        method: callType,
        body: body,
        uriParams: uRIParams
      });

      return response.result;
    }
    catch (ex) {
      return null;
    }
  }

  async SetRetailItemInfo(dataInput) {
    let x: RetailItemDetail = dataInput.retailItemDetail;
    let barCodes = dataInput.retailItemBarCode;
    let retailItemTemplateConfig: RetailItemTemplateConfig[] = dataInput.retailItemTemplateConfig;
    let primaryBarCode = barCodes ? barCodes.filter(t => t.isPrimary)[0] : undefined;
    let secondaryBarCode = barCodes ? barCodes.filter(o => o.isSecondary)[0] : undefined;
    let General = {
      itemnumber: x.itemNumber, itemdescription: x.itemDescription, salesprice: x.salesPrice,
      memberprice: x.memberPrice, suggestedprice: x.suggestedPrice, curitemcost: x.costPrice, marginpercent: x.marginPercentage, barcode: primaryBarCode ? primaryBarCode.barCode : '',
      secbarcode: secondaryBarCode ? secondaryBarCode.barCode : '', commissionableitem: x.isCommissionable, commissionreq: x.isCommissionRequired, inventory: x.useInventory, itemcatradiogrp: (x.itemType > 0 ? x.itemType : ''),
      categoryid: x.category, subcategory1: x.subCategory1, subcategory2: x.subCategory2, subcategory3: x.subCategory3, subcategory4: x.subCategory4, subcategory5: x.subCategory5, allowEarn: x.allowEarn, disposableItem: x.disposableItem
    }

    this.setAvailableCategoryInfo(General);

    let otherInfo = {
      groupKey: x.isGroupingKey, scaleItem: x.isScaledItem, gratuity: x.isGratuityAllowed,
      serviceTax: x.isServiceChargeAllowed, timeTrigger: x.isTeeTimeTrigger, cartTrigger: x.isCartTrigger, printCart: x.isPrintCartAgreement,
      printClub: x.isPrintClubAgreement, printShoe: x.isPrintShoeAgreement, printShack: x.isPrintToCaddyShack, printOne: x.isPrintOnlyOnePerTansaction,
      hangTicket: x.isHangingTicket, sticker: x.isSmallTicket,
      printSaleChit: x.isPrintRetailSaleChit,
      inactive: !x.isActive, requireComments: x.isRequireComments, requestName: x.isRequestName,
      openItem: x.isOpenItem, pms: x.isPMSPackageItem, multipack: x.isMultiPack, unit: x.unitOfMeasure, gratuityValue: x.gratuity, serviceTaxValue: x.serviceCharge,
      perHourRentalRate: x.perHourRentalRate, perDayRentalRate: x.perDayRentalRate,

      //Feature 57221: Cart, Shoe, Club Agreement ,Retail sale Chit , Retail sale chit Not Implemented 
      //Uncomment Below mapping
      cartTemplate: null,
      clubTemplate: null,
      shoeTemplate: null,
      saleChit: null

      //   cartTemplate : x.isPrintCartAgreement ? this.setRetailTemplateId(LetterTemplateType.CartAgreement,retailItemTemplateConfig): null,
      //   clubTemplate : x.isPrintClubAgreement ? this.setRetailTemplateId(LetterTemplateType.ClubAgreement,retailItemTemplateConfig): null,
      //   shoeTemplate : x.isPrintShoeAgreement ? this.setRetailTemplateId(LetterTemplateType.ShoeAgreement,retailItemTemplateConfig): null,
      //   saleChit:x.isPrintRetailSaleChit ?  this.setRetailTemplateId(LetterTemplateType.RetailSaleChit,retailItemTemplateConfig): null
    }
    this.vendorAPIInfo = dataInput.retailItemVendor;

    this.vendorInfo = this.MapVendorToUI(dataInput.retailItemVendor);

    this.GeneralFormGrp.patchValue(General);
    this.otherInfoFormGrp.patchValue(otherInfo);

    this.formControlSetting();
    let seasonal = { startDate: x.seasonalStartDate ? this.localization.getDate(x.seasonalStartDate) : '', endDate: x.seasonalEndDate ? this.localization.getDate(x.seasonalEndDate) : '', price: x.seasonalPrice && this.localization.localizeCurrency(x.seasonalPrice, false), memberPrice: x.seasonalMemberPrice && this.localization.localizeCurrency(x.seasonalMemberPrice, false) }

    this.seasonalPriceFormGrp.patchValue(seasonal);

    if (otherInfo.multipack) {
      if (this.isMultipleLinkedItem) {
        const itemArr: number[] = [];
        dataInput.multiPackDetail.forEach(a => {
          itemArr.push(a.linkedItemId)
        });

        const response = await this.cancellableObservalble(
          new Subject<void>(),
          'GetRetailItemDetailsByIds',
          Host.retailManagement,
          HttpMethod.Put,
          itemArr,
          ''
        ).toPromise();

        const itemdesc = response.result;

        dataInput.multiPackDetail.forEach(ele => {
          this.multipackArr = this.multipackFormGrp.get('multipackArr') as UntypedFormArray;
          this.multipackArr.push(this.Form.group({
            category: ele.category,
            item: [itemdesc.filter(e => e.id == ele.linkedItemId).map(z => z.itemNumber) + " - " + itemdesc.filter(e => e.id == ele.linkedItemId).map(z => z.itemDescription), Validators.required],
            itemId: ele.linkedItemId,
            description: "",
            salesprice: ele.multiSalesPrice,
            memberprice: ele.multiMemberPrice,
            cost: ele.multiCostPrice,
            service: ele.multiServiceCharge,
            gratutity: ele.multiGratuity,
            persale: ele.multiPerSale,
          }));
        });
        this.multipackArr.removeAt(0);
        const mutipack = {
          dateofsalestart: x.multiStartDate ? 'specific' : 'dateofsale', dateofexpireend: x.multiEndDate ? 'specific' : 'expiration', specificstartdate: (x.multiStartDate == null ? x.multiStartDate : this.utils.getDate(x.multiStartDate)),
          expireenddate: (x.multiEndDate == null ? x.multiEndDate : this.utils.getDate(x.multiEndDate)), unlimited: x.isMultiUnlimited, salesprice: x.multiSalesPrice, memberprice: x.multiMemberPrice,
          cost: x.multiCostPrice, service: x.multiServiceCharge, gratutity: x.multiGratuity, persale: x.multiPerSale, expiryDays: x.multiExpDays
        } as any;

        this.isMultipack = true;
        if (!mutipack?.persale) {
          this.multipackFormGrp.controls["persale"].clearValidators();
        }
        this.multipackFormGrp.patchValue(mutipack);
        this.multipackFormGrpEmitter.emit();
      }
      else {
        const mutipack = {
          dateofsalestart: x.multiStartDate ? 'specific' : 'dateofsale', dateofexpireend: x.multiEndDate ? 'specific' : 'expiration', specificstartdate: (x.multiStartDate == null ? x.multiStartDate : this.utils.getDate(x.multiStartDate)),
          expireenddate: (x.multiEndDate == null ? x.multiEndDate : this.utils.getDate(x.multiEndDate)), unlimited: x.isMultiUnlimited, salesprice: x.multiSalesPrice, memberprice: x.multiMemberPrice,
          cost: x.multiCostPrice, service: x.multiServiceCharge, gratutity: x.multiGratuity, persale: x.multiPerSale, expiryDays: x.multiExpDays
        } as any;

        if (x.linkedItemId) {
          const response = await this.cancellableObservalble(
            new Subject<void>(),
            'GetRetailItemById',
            Host.retailManagement,
            HttpMethod.Get,
            undefined,
            {
              itemId: x.linkedItemId
            }).toPromise();

          const linkedItemDetail = response.result.retailItemDetail;

          mutipack.description = linkedItemDetail.itemDescription;
          mutipack.category = linkedItemDetail.category;
          mutipack.item = linkedItemDetail.itemNumber + ' - ' + linkedItemDetail.itemDescription;
          mutipack.itemId = linkedItemDetail.id;
        }
        this.isMultipack = true;
        if (!mutipack?.persale) {
          this.multipackFormGrp.controls["persale"].clearValidators();
        }
        this.multipackFormGrp.patchValue(mutipack);
      }
      this.GeneralFormGrp.controls['commissionableitem'].setValue(false);
      this.GeneralFormGrp.controls['commissionreq'].setValue(false);
      this.GeneralFormGrp.controls['commissionableitem'].disable();
      this.GeneralFormGrp.controls['commissionreq'].disable();
      if (this.utils.getDate(x.multiStartDate) > this.PropertyInfo.CurrentDate) {
        this.minStartDate = this.PropertyInfo.CurrentDate;
      }
      else {
        this.minStartDate = this.utils.getDate(x.multiStartDate);
      }
      this.minEndDate = this.utils.getDate(x.multiStartDate);
    }
    else {
      this.isMultipack = false;
    }


    if (otherInfo.groupKey) {

      const parentIds = dataInput.packagedItem.map(res => res.parentItemId);

      const response = await this.cancellableObservalble(
        new Subject<void>(),
        'GetRetailItemDetailedInfoList',
        Host.retailManagement,
        HttpMethod.Put,
        parentIds,
        ''
      ).toPromise();


      const result = response.result;

      this.packagedItems = dataInput.packagedItem.map(data => {

        const currentItem = result.find(r => r.id === data.parentItemId);
        const current = currentItem ? currentItem.retailItemDetail : undefined;
        return {
          id: data.parentItemId,
          itemNumber: current ? current.itemNumber : '',
          itemDescription: current ? current.itemDescription : '',
          isDone: true,
          startDate: data.startDate,
          endDate: data.endDate,
          salesPrice: this.localization.localizeCurrency(data.price),
          memberPrice: this.localization.localizeCurrency(data.memberPrice)
        };
      });
    }

    if (otherInfo.scaleItem) {
      this.otherInfoFormGrp.controls['groupKey'].setValue(false);
      this.otherInfoFormGrp.controls['multipack'].setValue(false);
      this.otherInfoFormGrp.controls['groupKey'].disable();
      this.otherInfoFormGrp.controls['multipack'].disable();
    }
    this.imgService.GetImagesByReference(x.id, ImgRefType.retailItem, this.successCallback.bind(this), this.errorCallback.bind(this), []);

    this.itemInfoLoaded.next();
  }

  PopulateCopyItemValues(dataInput) {
    this.isCopyItem = true;
    this.SetRetailItemInfo(dataInput.retailItem);
    let CopyItem = {
      itemnumber: '',
      barcode: '',
      secbarcode: ''
    }

    this.setCategoryMapping();

    //For making vendor empty on copy
    this.vendorAPIInfo = [];
    this.vendorInfo = [];

    this.GeneralFormGrp.patchValue(CopyItem);
    if (dataInput.inventoryInfo.outlets && dataInput.inventoryInfo.outlets.length > 0) {
      let selectedOuts: InventoryOutletToDisplay[] = [];
      let selected = dataInput.retailItem.outletItem.map(y => y.outletId);
      selected.forEach(element => {
        let outlet = this.outlets.filter(x => x.id === element)[0];
        if (outlet != undefined) {
          outlet.itemPar = 0
          outlet.mtd = 0
          outlet.inventoryItemId = 0
          outlet.quantity = 0
          outlet.unitCost = 0
          outlet.weightedAverageCost = 0
          outlet.ytd = 0
          selectedOuts.push(outlet)
        }
      });
      this.selectedOutletMgrArr.next(selectedOuts);
    }
    this.updateUnitCost();
    this.buildTaxArray(this.selectedOutletMgrArr.value);
    this.setTaxForRetailItem(dataInput.retailItem.retailItemTax);
  }

  CategoryLinkAvailable(selectedCategory): boolean {
    return selectedCategory.length > 0 ? true : false;
  }

  GetSubCategoryId(categoryArray, Id): number {
    let id: number = 0;
    if (categoryArray.length > 0) {
      if (categoryArray[0].childSubCategories && categoryArray.length > 0) {
        id = categoryArray[0].childSubCategories.some(x => x.id == Id) ? Id : 0;
      }
    }
    return id;
  }

  MapVendorToUI(data: RetailItemVendor[]) {
    if (data && data.length > 0) {
      return data.map(c => {
        return {
          id: c.id,
          vendorId: c.vendorId,
          retailItemId: c.retailItemId,
          vendorCode: c.vendorCode,
          vendorName: c.vendorName,
          supplierItemNumber: c.supplierItemId,
          qoh: c.quantiyOnHand,
          leadDays: c.leadDays,
          minimumOrder: c.minOrder,
          unitQty: c.unitQuantity
        }
      });
    }
    else return [];
  }
  setRetailTemplateId(letterTemplateType: LetterTemplateType, retailTemplateInfo: RetailItemTemplateConfig[]) {
    return retailTemplateInfo?.find(t => t.templateType == letterTemplateType)?.templateId;
  }
  setAvailableCategoryInfo(categoryInfo) {
    let selectedCategory = categoryInfo.categoryid;
    let selectedCategoryArray = this.categories.filter(a => a.id == selectedCategory);
    if (selectedCategoryArray.length > 0) {
      categoryInfo.subcategory1 = this.GetSubCategoryId(this.categories.filter(a => a.id == selectedCategory), categoryInfo.subcategory1);
      categoryInfo.subcategory2 = this.GetSubCategoryId(this.subCategoryOne.filter(x => x.id == categoryInfo.subcategory1), categoryInfo.subcategory2);
      categoryInfo.subcategory3 = this.GetSubCategoryId(this.subCategoryTwo.filter(x => x.id == categoryInfo.subcategory2), categoryInfo.subcategory3);
      categoryInfo.subcategory4 = this.GetSubCategoryId(this.subCategoryThree.filter(x => x.id == categoryInfo.subcategory3), categoryInfo.subcategory4);
      categoryInfo.subcategory5 = this.GetSubCategoryId(this.subCategoryFour.filter(x => x.id == categoryInfo.subcategory4), categoryInfo.subcategory5);
    }
    else {
      // To Check Required validation in UI for category id
      categoryInfo.categoryid = null;
    }
  }

  setTaxForRetailItem(retailItemTaxInfo) {
    let frmArr: UntypedFormArray = this.GeneralFormGrp.get('taxConfig') as UntypedFormArray;
    if (frmArr.controls.length == 0) {
      this.buildTaxArray(this.selectedOutletMgrArr.value);
    }
    if (!this.PropertyInfo.IsVATEnabled) {
      for (let index = 0; index < frmArr.value.length; index++) {
        for (let j = 0; j < frmArr.value[index].length; j++) {
          const _retailItemTax = frmArr.value[index][j];
          retailItemTaxInfo.forEach(element => {
            if (element.taxName == _retailItemTax.taxName || element.taxId == _retailItemTax.taxId) {
              let taxArray = frmArr.controls[index] as UntypedFormArray;
              taxArray
                .at(j)
                .patchValue({
                  id: element.id,
                  taxName: element.taxName,
                  isSelected: true,
                });
            }
          });
        }
      }
    } else if (this.PropertyInfo.IsVATEnabled) {
      for (let index = 0; index < this.availableTaxes.length; index++) {
        const _retailItemTax = this.availableTaxes[index];
        retailItemTaxInfo.forEach(element => {
          if (element.taxName == _retailItemTax.taxName) {
            frmArr
              .at(index)
              .patchValue({
                id: element.id,
                taxName: element.taxName,
                isSelected: true
              });
          }
        });
      }
    }
  }

  formControlSetting() {
    if (this.otherInfoFormGrp.controls['groupKey'].value) {
      this.otherInfoFormGrp.controls['multipack'].setValue(false);
      this.otherInfoFormGrp.controls['multipack'].disable();
    }
  }

  setCategoryMapping() {
    this.isLoadCategoryMapping = true;
    if (this.GeneralFormGrp.controls.categoryid.value > 0) {
      this.OnCategoryChange(this.GeneralFormGrp.controls.categoryid.value);
    }
    if (this.GeneralFormGrp.controls.subcategory1.value > 0) {
      this.OnSubCategory1Change(this.GeneralFormGrp.controls.subcategory1.value);
    }
    if (this.GeneralFormGrp.controls.subcategory2.value > 0) {
      this.OnSubCategory2Change(this.GeneralFormGrp.controls.subcategory2.value);
    }
    if (this.GeneralFormGrp.controls.subcategory3.value > 0) {
      this.OnSubCategory3Change(this.GeneralFormGrp.controls.subcategory3.value);
    }
    if (this.GeneralFormGrp.controls.subcategory4.value > 0) {
      this.OnSubCategory4Change(this.GeneralFormGrp.controls.subcategory4.value);
    }
    this.isLoadCategoryMapping = false;
  }


  OnCategoryChange($event: any) {
    let selectedCategory = $event;
    this.selectedCategoryArray = this.categories.filter(a => a.id == selectedCategory);
    this.selectedCategoryArrayDetails = this.categoriesDetails.filter(c => c.id == selectedCategory);
    // Reset category source
    this.SubCategory1 = [];
    this.SubCategory2 = [];
    this.SubCategory3 = [];
    this.SubCategory4 = [];
    this.SubCategory5 = [];
    //Reset category values
    if (!this.isLoadCategoryMapping) {
      let categoryReset = {
        subcategory1: "",
        subcategory2: "",
        subcategory3: "",
        subcategory4: "",
        subcategory5: "",
        allowEarn: this.selectedCategoryArrayDetails[0].allowEarn
      }
      this.allowCategory = this.selectedCategoryArrayDetails[0].allowEarn ? this.selectedCategoryArrayDetails[0].allowEarn : false;
      this.GeneralFormGrp.patchValue(categoryReset);
    }


    if (this.selectedCategoryArray.length > 0) {
      this.SubCategory1 = this.subCategoryOne.filter(a => { return this.CategoryLinkAvailable(this.selectedCategoryArray[0].childSubCategories) ? this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id == a.id) : 0 });
    }
  }

  OnSubCategory1Change($event: any) {
    let selectedSubCategory1 = $event;
    this.selectedCategoryArray = this.subCategoryOne.filter(a => a.id == selectedSubCategory1);
    this.SubCategory2 = [];
    this.SubCategory3 = [];
    this.SubCategory4 = [];
    this.SubCategory5 = [];
    //Reset category values
    if (!this.isLoadCategoryMapping) {
      let categoryReset = {
        subcategory2: "",
        subcategory3: "",
        subcategory4: "",
        subcategory5: ""
      }
      this.GeneralFormGrp.patchValue(categoryReset);
    }
    if (this.selectedCategoryArray.length > 0) {
      this.SubCategory2 = this.subCategoryTwo.filter(a => { return this.CategoryLinkAvailable(this.selectedCategoryArray[0].childSubCategories) ? this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id == a.id) : 0 });
    }
  }

  OnSubCategory2Change($event: any) {
    let selectedSubCategory2 = $event;
    this.selectedCategoryArray = this.subCategoryTwo.filter(a => a.id == selectedSubCategory2);
    this.SubCategory3 = [];
    this.SubCategory4 = [];
    this.SubCategory5 = [];
    //Reset category values
    if (!this.isLoadCategoryMapping) {
      let categoryReset = {
        subcategory3: "",
        subcategory4: "",
        subcategory5: ""
      }
      this.GeneralFormGrp.patchValue(categoryReset);
    }
    if (this.selectedCategoryArray.length > 0) {
      this.SubCategory3 = this.subCategoryThree.filter(a => { return this.CategoryLinkAvailable(this.selectedCategoryArray[0].childSubCategories) ? this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id == a.id) : 0 });
    }
  }

  OnSubCategory3Change($event: any) {
    let selectedSubCategory3 = $event;
    this.selectedCategoryArray = this.subCategoryThree.filter(a => a.id == selectedSubCategory3);
    this.SubCategory4 = [];
    this.SubCategory5 = [];
    //Reset category values
    if (!this.isLoadCategoryMapping) {
      let categoryReset = {
        subcategory4: "",
        subcategory5: ""
      }
      this.GeneralFormGrp.patchValue(categoryReset);
    }
    if (this.selectedCategoryArray.length > 0) {
      this.SubCategory4 = this.subCategoryFour.filter(a => { return this.CategoryLinkAvailable(this.selectedCategoryArray[0].childSubCategories) ? this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id == a.id) : 0 });
    }
  }

  OnSubCategory4Change($event: any) {
    let selectedSubCategory4 = $event;
    this.selectedCategoryArray = this.subCategoryFour.filter(a => a.id == selectedSubCategory4);
    if (this.selectedCategoryArray.length > 0) {
      this.SubCategory5 = this.subCategoryFive.filter(a => { return this.CategoryLinkAvailable(this.selectedCategoryArray[0].childSubCategories) ? this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id == a.id) : 0 });
    }
  }

  onFileChanged(event: any) {
    this.selectedFile = event.target.files[0];
    const file = event.target.files[0];
    // convert to MB
    const fileSize = file.size / (1024 * 1024);
    // Allow upload if size is lesser than or equal to 2 MB
    if (fileSize <= 2) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
      this.GeneralFormGrp.markAsDirty();
    } else {
      this.business.openErrorDialog(this.captions.common.FileSizeExceeded, this.captions.common.Error, this.captions.common.Error);
      this.url = './assets/images/greenish-wide.jpg';
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.bindImage(this.selectedFile.type, this.base64textString);
  }

  private bindImage(fileContentType: any, fileContent: any) {
    const _placeholderBase64 = `data:${fileContentType};base64,${fileContent}`;
    // this.url = this.domSanitizer.bypassSecurityTrustUrl(_placeholderBase64);
    this.url = _placeholderBase64;
    this.ImageUploaded = true;
  }

  onFileDelete(event: any) {
    this.isImageRemoved = true;
    this.ImageUploaded = false;
    this.GeneralFormGrp.markAsDirty();
  }
  fileDeleted() {
    this.isImageRemoved = true;
    this.ImageUploaded = false;
    this.base64textString = '';
    this.GeneralFormGrp.markAsDirty();
  }
  fileUploaded(data) {
    this.base64textString = data['orgImg'];
    this.thumbnailImg = data['tmbImg'];
    this.GeneralFormGrp.markAsDirty();
  }
  fileSizeExceeded() {
    this.business.openErrorDialog(this.captions.common.FileSizeExceeded, this.captions.common.Error, this.captions.common.Error);
  }

  public setPropertyConfiguration() {
    this.PropertyInfo.SetDefaultDataOnLoad();
  }

  async getRetailItemsDetailedInfoByIds(retailItemIds: any[]): Promise<any[]> {
    const result = await this.http.CallApiAsync<any>({
      host: Host.retailManagement, callDesc: "GetRetailItemDetailedInfoList",
      method: HttpMethod.Put, body: retailItemIds
    });
    return result.result;
  }

  async GetRoomTypes(): Promise<RoomTypeAssignmentRetailItem[]> {
    const all: RoomTypeAssignmentRetailItem = {
      id: 0,
      selected: false,
      code: this.commonCaptions.all,
      description: this.commonCaptions.all,
      retailItemId: 0,
      roomTypeId: 0
    };
    const roomTypes = await RetailDataAwaiters.getAllRoomTypes();
    this.selectedRoomTypes = roomTypes ? roomTypes.map(x => ({ id: 0, code: x.code, description: x.name, selected: false, roomTypeId: x.id, retailItemId: 0 })) : [];
    this.selectedRoomTypes.unshift(all);
    return Promise.resolve(this.selectedRoomTypes);
  }

  async GetRoomTypeAssignmentRetailItem(itemId: number): Promise<RoomTypeAssignmentRetailItem[]> {
    this.selectedRoomTypes = await RetailDataAwaiters.getRoomTypeAssignmentAggregate(itemId);
    const all: RoomTypeAssignmentRetailItem = {
      id: 0,
      selected: this.selectedRoomTypes.length === this.selectedRoomTypes.filter(s => s.selected).length,
      code: this.commonCaptions.all,
      description: this.commonCaptions.all,
      retailItemId: itemId,
      roomTypeId: 0,
    };
    this.selectedRoomTypes.forEach(x => {
      x.id = x.id ? x.id : 0;
      x.retailItemId = itemId;
    });
    this.selectedRoomTypes.unshift(all);
    return Promise.resolve(this.selectedRoomTypes);
  }
  public async GetAllTemplatesForRetailDropdown(includeInactive: boolean = false) {
    let result = await this.http.CallApiAsync<any[]>({
      callDesc: "GetAllTemplatesForRetailDropdown",
      host: Host.retailManagement,
      method: HttpMethod.Get,
      uriParams: { includeInactive: includeInactive },
    });
    return result?.result;
  }
  public async GetCustomFeeConfigurationSetting(): Promise<any> {
    let result = await this.http.CallApiAsync<any[]>({
      callDesc: "GetConfiguration",
      host: Host.retailManagement,
      method: HttpMethod.Get,
      uriParams: { module: 3, Switch: 'REQUIRE_CUSTOM_FEE' },
    });
    return result?.result;
  }
}

