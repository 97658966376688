import { Pipe, PipeTransform } from "@angular/core";
import { GuestFieldMetrics } from "../constants/commonenums";
import { GuestMetricsinText } from "@app/common/shared/shared/globalsContant";
import cloneDeep from 'lodash/cloneDeep';


@Pipe({
    name: 'customGuestFieldPipe'
  })
  export class customGuestFieldConfigPipe implements PipeTransform {
    constructor() { }
    transform(config: any, selectedFieldMetric): any {
      let placeholder = Object.values(GuestFieldMetrics)[selectedFieldMetric];
      let fieldConfig = cloneDeep(config.map(field => {
        field.placeholder = placeholder;
        field.inputType = GuestMetricsinText.includes(Object.values(GuestFieldMetrics)[selectedFieldMetric]) ? 'text' : 'number';
        return field
      }))
      console.log(config)
      return fieldConfig;
    }
  }