<section id="StoM" class="h-100">
  <form autocomplete="off" name="AddTransferSM" [formGroup]="singletomultipleFG" class="h-100" container="body">
    <div class="grid-container ">
      <ng-scrollbar>
      <div class="w-100">
        <div class="pt-2 clearfix">
          <div class="float-left w415">
            <mat-form-field class='w-100' [floatLabel]="floatLabel">
              <input type="text" class="LW14" [placeholder]="captions.retailsetup.IDCsearch" matInput [matAutocomplete]="auto"
                (input)="filterFucntion($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="searchTxt">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)='SelectedRecord($event.option)'>
                <mat-option *ngFor="let option of searchFilter" [value]="option" class="CustOptionStyle">
                  <div class="LW14 text-capitalize"> {{ option.itemNumberColumn }} - {{ option.itemName }} </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="float-left ml-4 mt-2">
              <button class="btn btn-default LW14 text-capital button-height" type="button" (click)="addItemToTable()" [ngClass]="disableChange? 'button--disabled': 'body-bgcolor whitecolor body-bordercolor'" [disabled]="disableChange">{{captions.common.CHANGE}}</button>
          </div>
          <div class="float-right mt-3">
            <label class="pl-3"> {{captions.retailsetup.totalvalueofTransfer}}
            <span class="pl-2">{{this.transferquantity | Currency}}</span>
            </label>
          </div>
        </div>
      
        <div class="w-100 mb-4 mt-4" id="stomTableid">
          <table aria-describedby="" class="stomTableclass w-100" #stomcellref>
            <thead>
              <tr>
                <th  scope='col'  *ngFor="let thdata of TableHdrData;let hi=index">
                  <span *ngIf="thdata.jsonkey != 'isInActive'">{{thdata.title}}</span>
                  <span *ngIf="thdata.jsonkey == 'isInActive'">{{status}}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let eachrow of selectedtablerow;let i=index">
                <td *ngFor="let thdata of TableHdrData;let hi=index">
                    <span *ngIf="thdata.jsonkey != 'isInActive'"> {{eachrow[thdata.jsonkey]}}</span>
                    <span *ngIf="thdata.jsonkey == 'isInActive' && eachrow[thdata.jsonkey] == true">{{captions.retailsetup.Inactive}}</span>
                    <span *ngIf="thdata.jsonkey == 'isInActive' && eachrow[thdata.jsonkey] == false">{{captions.retailsetup.Active}}</span>

                </td>
              </tr>
              <tr *ngIf="selectedtablerow && selectedtablerow.length == 0">
                <td [colSpan]="TableHdrData.length" class="text-center">{{this._Localization.captions.common.NoDataFound}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="pt-3">
          <mat-form-field [floatLabel]="floatLabel" class="mr-5 pointerevents-none">
            <mat-select [placeholder]="captions.retailsetup.fromOutlet" id="fromOutlet" formControlName="fromOutlet">
              <mat-option *ngFor='let FOutlet of fromOutlets' [value]='FOutlet.id'>{{FOutlet.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div >
          <mat-form-field [floatLabel]="floatLabel" class="w415">
            <input matInput [placeholder]="captions.retailsetup.transferReason" type="text" [maxlength]="300" formControlName="transferReason" required>
            <mat-error>
              {{this.captions.common.Missing}} {{captions.retailsetup.transferReason}}
          </mat-error>
          </mat-form-field>
        </div>

        <div class="w-100" id="stomtooutlet">
        <div *ngFor="let eachoutlet of singletomultipleFG.get('Reason')['controls'];let i=index ;let f = first;let l=last" formArrayName = "Reason">
          <mat-form-field [floatLabel]="floatLabel" class="mr-5" [formGroupName]=i>
            <mat-select [placeholder]="captions.retailsetup.toOutlet" (selectionChange)="outletsSelected($event,i)" formControlName="outlet" required>
              <mat-option *ngFor='let TOutlet of multipleOutlet.tooutletdisplay[i] && multipleOutlet.tooutletdisplay[i].outlet' [value]='TOutlet.id'>{{TOutlet.name}}</mat-option>
            </mat-select>
            <mat-error>
              {{captions.retailsetup.toOutletMissing}}
            </mat-error>
          </mat-form-field>
          <mat-form-field [floatLabel]="floatLabel" [formGroupName]="i">
            <input matInput [placeholder]=" captions.retailsetup.transferqty" type="text" required inputtype="nonnegative,nodecimal" maxlength="5" required  formControlName="quantity" (input)="updateoverallqty()" required>
            <mat-error>
                {{this._Localization.captions.common.Missing}} {{captions.retailsetup.transferqty}}
            </mat-error>
          </mat-form-field>
          
          <span class="icon-Minus" (click)="addremove('R',i)" *ngIf="!f || (f && !l)" ></span>
          <span class="icon-Plus" (click)="addremove('A',i)" *ngIf="l && (this.toOutlets.length > i+1)"></span>
        </div>
        </div>
      </div>
    </ng-scrollbar>
    </div>
    <mat-dialog-actions class=" pl-4">
        <button mat-raised-button type="button" (click)="transferdata()"   [disabled]="singletomultipleFG.invalid"
          class="btn btn-default LW14 text-capital" [disabled]="(singletomultipleFG.invalid || !checkedTableRowBool)? true : false" [ngClass]="(singletomultipleFG.invalid || !checkedTableRowBool)? 'button--disabled':' body-bgcolor whitecolor body-bordercolor'">{{this.captions.retailsetup.transfer}}</button>
        <button mat-raised-button type="button" (click)="close()">{{this.captions.retailsetup.CANCEL}}</button>
      </mat-dialog-actions>
  </form>
</section>
