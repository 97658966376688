import { inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as en_data from '@assets/i18n/en.json';
import { ValidationMessageType } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { CacheService } from '@core/services/cache.service';
import { MultilingualService } from '@core/services/multilingual.service';
import reportsList from '@data/reportslist.json';
import sideMenu from '@data/sidemenu.json';
import { AuthenticationResultDTO } from '@models/AuthenticationResultDTO';
import { LoginResultDTO } from '@models/LoginResultDTO.js';
import { RestaurantDTO, RestaurantNoteDTO, SettingsDTO } from '@models/RestaurantDTO';
import { HttpService } from '@services/http.service';
import { SignalrService } from '@services/signalr.service';
import { Utilities } from '@utilities/utilities';
import { startOfDay } from 'date-fns';
import { BehaviorSubject, interval, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as FullStory from '@fullstory/browser';
import { ManageSessionService } from './login/manage-session.service';
import { RetailLoginService } from './shared/services/retail-login.service';
import moment from 'moment';
import { RetailFeatureFlagInformationService } from './retail/shared/service/retail.feature.flag.information.service';
import { ScreenType } from './shared/constants/globalConstants';
import { TranslateService } from '@ngx-translate/core';
import { RentalService } from './shared/services/rental/rental.service';

var intervalID;
@Injectable({
  providedIn: 'root'
})
export class AppService implements OnDestroy {
  dateSelected: Subject<any> = new Subject<any>();
  restaurantName = '';
  restaurantId: number = null;
  restaurantSettings$: SettingsDTO;
  reservationMenuCount: number;
  waitlistMenuCount: number;
  _headerDate: Date = new Date();
  headerDate$ = new BehaviorSubject<Date>(null);
  headerSelectedDate: Date = new Date();
  headerSelectedDate$ = new Subject<Date>();
  seatingAreaChanged = new Subject<any>();
  pageRefreshed = new Subject<any>();
  public signalrChanged = new Subject<any>();
  public myEventSubscription: any;
  public StateeventSubscription: any;
  isdisconnectedbyuser: boolean;
  menuHeader = '';
  hideSideMenu = false;
  reservationSettings:number[] = [];
  unlockRestaurantData: AuthenticationResultDTO = {} as AuthenticationResultDTO;
  selectedMenuId: number = 0;
  oldMenuId: number = 0;
  seatingArea: any;
  listners = new Subject<any>();
  updatedContacts$ = new Subject<any>();
  updateWalkins$ = new Subject<any>();
  updateTimeline$ = new Subject<any>();
  menuNames: any = []; // array to hold menu names
  reportsList: any = [];
  showDashboard: boolean;
  showLegends: boolean;
  seatingAreaFilterDisabled = new Subject<any>();
  datePickerDisabled = new Subject<any>();
  propertySwitchDisabled = new Subject<any>();
  datePickerRangeChange = new Subject<any>();
  partyStatusList: any;
  languageJson: any;
  isHeaderLoaded: boolean;
  PropertyType: any;
  LanguageFileURL: string;
  OTASourceId: number[] = [];
  PropertyID: any;
  closeNav: boolean = false;
  triggerCurrentMenu$ = new Subject<number>();
  private userLoggedIn = new Subject<boolean>();
  minuteTimer$ = new Subject<boolean>();
  toggleMenu$ = new Subject<boolean>();
  standbyListCount: number;
  showOTAMessage: boolean = true;
  subscriptions: Subscription = new Subscription();
  removedWalkIn = new BehaviorSubject<any>(null);
  _setting: SettingsDTO;
  permissionList: any;
  showCartItem: boolean = false;
  showCartItemOnEdit: boolean = false;
  isCartPaymentInProgress: boolean = false;
  showTimer: boolean = false;
  timerSubscription: Subscription = new Subscription();
  minutes: Number;
  seconds: Number;
  totalTime: number;
  TimerOff$: Subject<any> = new Subject<boolean>();
  engageIntegration: boolean = false;
  propertyChanged = new BehaviorSubject<any>(null);
  propertyLanguage = localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_language`);
  editItemParameters: any = {};
  showHelpScreen = false;
  isFullStoryExecuted = false;
  isFirstLogin = true;
  isRetailLoggedinFailed = false;
  retailLoginSubscription: Subscription = new Subscription();
  showNotificationSection: boolean;
  fullStoryProperties = [];
  showCreateActivity :boolean = false;
  sessionDataFilter:any;
  createActivitySettings:any;
  templateSelectionChange : Subject<any> = new Subject<any>();
  isTemplateUsed : boolean ;
  updateActivitiesTimeline = new BehaviorSubject<any>(null);
  refreshURL: string;
  propertyNotifications = {} as any;
  currentUserName: string = '';
  selectedLanguage = 1;
  filterOptionsForReservation = [];
  filterOptionsForWaitlist = [] ;
  public iframeready = new Subject<any>();
  posView: boolean = !!sessionStorage.getItem('posView') || null;
  isPropertyChanged: boolean = false;
  
  rentalService = inject(RentalService);
  constructor(public _hs: HttpService, public translate: MultilingualService, private router: Router,
    public signalrService: SignalrService, public _cs: CacheService, private ngZone: NgZone,
     private authservice: ManageSessionService, public retailLogin:RetailLoginService,
     private retailFeatureInformationService: RetailFeatureFlagInformationService , public translateService: TranslateService) {
    this.isHeaderLoaded = false;
    this.restaurantId = + sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`);
    this.menuNames = sideMenu;
    this.selectedMenuId = sideMenu.menus[0].id;
    this.reportsList = reportsList;
    this.showDashboard = true;
    this.showLegends = false;
    this.seatingAreaFilterDisabled.next(true);
    this.datePickerDisabled.next(true);
    this.datePickerRangeChange.next(1);
    this.getLocaleLanguageJSON();
    this.setPropertyType();
this.setFilters()
    this.userLoggedIn.next(false);

    this.subscriptions.add(this._cs.settings.subscribe(_setting => {
      if (_setting) {
        this._setting = _setting;
        this.updatePropertyWithLatestSetting(_setting);
      }
    }));
    this.subscriptions.add(this.headerDate$?.subscribe(date => {
      this.rentalService.updateCalendarFilter(date);
    }))
  }


  setFilters(){
    let reservationSettings = localStorage?.getItem('reservationSettings')
    if(reservationSettings){
    this.filterOptionsForReservation =  JSON.parse(reservationSettings).reservationFilter;
    this.filterOptionsForWaitlist =  JSON.parse(reservationSettings).waitListFilter;
    this.reservationSettings = JSON.parse(reservationSettings).settings;
    }
  }

  public communicationList: any = [{ name: 'Manual', icon: 'icon-Path-506', id: 0, setSelected: true },
  { name: 'Sms', icon: 'icon-SMS', id: 1, setSelected: false },
  { name: 'VoiceCall', icon: 'icon-ic_stay_current_portrait_24px', id: 2, setSelected: false },
  { name: 'Email', icon: 'icon-envelope', id: 3, setSelected: false },
  { name: 'Sms2', icon: 'icon-SMS', id: 4, setSelected: false },
  { name: 'VoiceCall2', icon: 'icon-ic_stay_current_portrait_24px', id: 5, setSelected: false },
  { name: 'SMSProxyRelay', icon: 'icon-SMS', id: 6, setSelected: false },
  { name: 'Email2', icon: 'icon-envelope', id: 7, setSelected: false }];


  applySetting(selectedmenu: number) {
    this.oldMenuId = this.selectedMenuId;
    this.selectedMenuId = selectedmenu ? selectedmenu : 0;
    if (this._setting) {
      this.updatePropertyWithLatestSetting(this._setting);
    }
  }

  updatePropertyWithLatestSetting(_setting: SettingsDTO) {
    this.restaurantSettings$ = _setting;
    this.engageIntegration = this._setting.General?.EngageIntegrationDTO?.IsEnabled;
    this.menuHeader = this.restaurantSettings$.General.RestaurantName;
    this._headerDate = Utilities.getRestaurantDateTime(_setting.General.DaylightDelta);
    this.PropertyID = _setting.PropertyID;
    this.PropertyType = _setting.PropertyType;
    this.LanguageFileURL = _setting.LanguageFileURL;
    this.loadPartySources();
    let loginHostId = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostId;
    if (_setting.LoadPermission && _setting.LoadPermission !== null && _setting.LoadPermission.length > 0) {
      let hostUserPermissions = _setting.LoadPermission.filter(host => host.HostId == loginHostId);
      if (hostUserPermissions && hostUserPermissions.length > 0) {
        this.permissionList = hostUserPermissions[0].HostPermissions
      }
    }
  }

  addDefaultSeatingArea(settings: SettingsDTO) {
    if (settings.SeatingAreas.filter(x => x.Id === -1).length <= 0) {
      settings.SeatingAreas.push({ Id: -1, Abbreviation: 'ANY', Name: this.translateService.instant('anySeatingArea') });
    } // setting default seating area
  }

  addDefaultSeatingType(settings: SettingsDTO) {
    if (settings.SeatingTypes.filter(x => x.Id === -1).length <= 0) {
      // settings.SeatingTypes.push({ Id: -1, Abbreviation: 'ANY', Name: 'Any seating type' });
      settings.SeatingTypes.push({
        Id: -1,
        Abbreviation: 'ANY',
        Description: this.translateService.instant('anySeatingType'),
        IsDeleted: false,
        RestaurantId: this.restaurantId,
        SeatingArea: null,
        SeatingTypeSeatingAreaMappings: null
      }); // setting default seating area
    }
  }

  resetPOS(){
    this.posView = false;
    // this.keyBoardService.enableDisableKeyboard(false);
    sessionStorage.setItem('posView', '');
  }

  logout(isAutoLogout:boolean =false) {
    if (!this._cs.isIframeEnabled) {
    if(isAutoLogout){
      this.rentalService.reset();
      this._cs.locationListForMerchant = [];
      this._cs.LoadMultiplePropertiesServiceInit = false;
      sessionStorage.removeItem('section');
    }
    let loginResult: LoginResultDTO = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    this.refreshURL = null;
    if (this.restaurantId && this.restaurantId != 0 && loginResult && loginResult.DeviceId) {
      return this._hs.post(urlConfig.logoutUrl + loginResult.LoggedInRestaurantId + '&deviceId=' + loginResult.DeviceId)
        .subscribe(async (_data) => {
          sessionStorage.setItem(`isAppLoggedIn${Utilities.getSessionStorageType()}`, 'false');
          if(this.posView) {
          this.removeModuleClass();
          }
          this.retailLogin.isSwitchProperty = false;
          if (_data != null && _data.State == 0) {
            this.signalrService.stopClient();
            this.ngZone.run(() => {
              this.router.navigate(['./login']);
              // if(isAutoLogout){
              //   setTimeout(() => {
              //     location.reload();
              //   }, 100);
              // }
            });
            const sessionType = Utilities.getSessionStorageType()
            const tempClientUid = sessionStorage.getItem(`ClientUid${sessionType}`);
            const tempMerchantKey = sessionStorage.getItem(`MerchantKey${sessionType}`);
            const tempIsGlobalAdmin = sessionStorage.getItem(`IsGlobalAdmin${sessionType}`);
            const tempIsMerchantUser = sessionStorage.getItem('IsMerchantUser');
            const restaurantId = sessionStorage.getItem(`restaurantId${sessionType}`);
            const lastLoggedInRestaurantId = sessionStorage.getItem(`lastLoggedInRestaurantId${sessionType}`);
            const tempsession=sessionStorage.getItem(`sessionGUID${sessionType}`);
            const tempMerchantId = sessionStorage.getItem(`Login_MerchantId`);
            const tempPosView = sessionStorage.getItem(`posView`);
            if (Utilities.isRetailEnabledPropertyWithSession(this._cs.settings.value.General.RetailIntegrationDTO)) {
              await this.authservice.logout();
              this.retailFeatureInformationService.reset();
              
            }
            if (this.retailLoginSubscription)
              this.retailLoginSubscription.unsubscribe();

            this._cs.isSilentLogin = false;
            localStorage.setItem('reservationSettings', JSON.stringify(this.loadReservationSettings()))
            sessionStorage.setItem(`ClientUid${sessionType}`, tempClientUid);
            sessionStorage.setItem(`MerchantKey${sessionType}`, tempMerchantKey);
            sessionStorage.setItem(`IsGlobalAdmin${sessionType}`, tempIsGlobalAdmin);
            sessionStorage.setItem('IsMerchantUser', tempIsMerchantUser);
            sessionStorage.setItem(`lastLoggedInRestaurantId${sessionType}`, lastLoggedInRestaurantId);
            sessionStorage.setItem(`restaurantId${sessionType}`, restaurantId);
            sessionStorage.setItem(`sessionGUID${sessionType}` , tempsession);
            sessionStorage.setItem(`Login_MerchantId` , tempMerchantId);
            sessionStorage.setItem(`posView` , tempPosView);
            Utilities._restaurantId = null;
            this.restaurantId = null;
            this.seatingArea = null;
            //TODO : Show logout successfully messag
          }
          else {
            //TODO : Show error message
            if (Utilities.isRetailEnabledPropertyWithSession(this._cs.settings.value.General.RetailIntegrationDTO)) {
              await this.authservice.logout();
              this.retailFeatureInformationService.reset();
              
            }
          }
          this.clearLocalStorage();
          if (this.isFullStoryExecuted) {
            FullStory.shutdown();
          }
          this.refreshURL = null;

          //Refresh the screen after Auto logout has completed
          setTimeout(() => {
            if (isAutoLogout) {
              location.reload();
            }
          }, 1000)

        }), (error) => {
          this.refreshURL = null;
          console.log(error);
          this.signalrService.stopClient();
          if (Utilities.isRetailEnabledPropertyWithSession(this._cs.settings.value.General.RetailIntegrationDTO)) {
            this.authservice.logout();
            this.clearLocalStorage();
            this.retailFeatureInformationService.reset();
            
          }
        };
    }
    else {
      this.ngZone.run(() => {
        this.router.navigate(['./login']);
      })
      this.signalrService.stopClient();
      this.refreshURL = null;
      if (Utilities.isRetailEnabledPropertyWithToken(JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_${this.restaurantId}_restaurantSettings`))?.General.RetailIntegrationDTO)) {
        this.authservice.logout();
       
      }
      this.clearLocalStorage();
    }
  }
}

  removeModuleClass() {
    var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
    var body = document.getElementsByTagName('body')[0]; // '0' to assign the first (and only `BODY` tag)
    root.classList.remove('respos');
    body.classList.add('greentheme');
    root.classList.add('reserve');
  }

  loadReservationSettings(){
    return {
      reservationFilter : this.filterOptionsForReservation,
      waitListFilter : this.filterOptionsForWaitlist,
      settings: this.reservationSettings
    }
  }

  clearLocalStorage() {
    const sessionGUID = sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`);
    this.clearStoargeSessionData(sessionGUID);
  }

  clearStoargeSessionData(sessionGUID){
    let loginResult: LoginResultDTO = JSON.parse(localStorage.getItem(`${sessionGUID}_loginResult`));
    loginResult?.RestaurantsAvailableForLogin?.forEach(availableProperty => {
      localStorage.removeItem(`${sessionGUID}_${availableProperty.Id}_revision`);
      localStorage.removeItem(`${sessionGUID}_${availableProperty.Id}_restaurantSettings`);
    });
    localStorage.removeItem(`${sessionGUID}_userLocale`);
    localStorage.removeItem(`${sessionGUID}_loginResult`);
    localStorage.removeItem(`${sessionGUID}_hubConnectionId`);
    localStorage.removeItem(`${sessionGUID}_SignalRConnectionCount`);
    localStorage.removeItem(`${sessionGUID}_language`);
    localStorage.removeItem(`${sessionGUID}_retainSignalR`);
  }

  async logOutFromRetailProperty(propertyId) {
    if (Utilities.isRetailEnabledPropertyWithSession(JSON.parse(localStorage.getItem(`${sessionStorage.getItem('sessionGUID')}_${propertyId}_restaurantSettings`))?.General.RetailIntegrationDTO)){
      //this.authservice.logout();      
      this.authservice.stopTimerForNotification();
      await this.authservice.doLogoutActivities();
      await this.authservice.updateSession();
      this.authservice.removeToken();
      this.authservice.dialogRef.closeAll();
      this.authservice.http.removeHelpUserSession();     
     this.clearLocalStore();
    }
  }

  clearLocalStore() {
    sessionStorage.removeItem("propertyDate");
    sessionStorage.removeItem("propertyInfo");        
    sessionStorage.removeItem("_userInfo");
    sessionStorage.removeItem("userSession");
    localStorage.removeItem("propertyDate");
    localStorage.removeItem("propertyInfo");
    localStorage.removeItem("_userInfo");
    localStorage.removeItem("userSession");
    sessionStorage.removeItem("taskReminderPopUp");
  }

  listen(): Observable<any> {
    return this.listners.asObservable();
  }

  triggerReservationReload() {
    this.listners.next();
  }

  triggerContactReload() {
    this.updatedContacts$.next();
  }

  updateContacts(): Observable<any> {
    return this.updatedContacts$.asObservable();
  }

  triggerWaitlistReload() {
    this.updateWalkins$.next();
  }

  updateWaitlist(): Observable<any> {
    return this.updateWalkins$.asObservable();
  }

  triggerTimelineReload() {
    this.updateTimeline$.next();
  }

  updateTimeline(): Observable<any> {
    return this.updateTimeline$.asObservable();
  }

  loadSettings() {
    if (this.restaurantId && this.restaurantId !== 0) {
      return this._hs.get(urlConfig.loadRestaurantUrl + this.restaurantId + '&options=Full')
        .pipe(map(data => data as RestaurantDTO));
      // .map((_data: { Payload: RestaurantDTO; }) => _data);
    }
  }

  /* to get the names of the side menu bar (e.g Reservarion,Tables etc) */
  getMenuNames() {
    return this._hs.get(urlConfig.getMenuNameUrl)
      .pipe(map(data => data));
  }

  setLanguage(lang: any) {
    this.translate.setLanguage(lang);
  }

  async getLocaleLanguageJSON() {
    const jsondata = (en_data as any);
    this.languageJson = jsondata.default;
  }


  isOperationFailed(data) {
    if (data.ExceptionMessage) { return true; }

    if (data.ValidationMessages && data.ValidationMessages.length > 0) {
      if (data.ValidationMessages[0].Type === ValidationMessageType.Error) { return true; } else
        if (data.ValidationMessages[0].Type === ValidationMessageType.Warning) { return false; }
    }

    return false;
  }

  formatPhoneNumber(phoneNumber: string) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    let formattedphoneNumber;
    if (match) {
      const internationalCode = (match[1] ? '+1 ' : '+1');
      return [internationalCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    if (cleaned.length != 10 && cleaned.length > 0) {
      if (cleaned.startsWith('1')) {
        formattedphoneNumber = '+ ' + '(' + cleaned.substr(0, 3) + ') ' + cleaned.substr(3, 3) + '-' + cleaned.substr(6);
      } else {
        formattedphoneNumber = '(' + cleaned.substr(0, 3) + ') ' + cleaned.substr(3, 3) + '-' + cleaned.substr(6);
      }
      return formattedphoneNumber;
    }
    return null;
  }

  getCommunicationIcon(pagemethod: number) {
   let _type =  this.communicationList.find(x => x.id == pagemethod);
    if (_type && _type.icon != undefined) {
      return _type.icon;
    }
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  getDayNote() {
    return this._hs.get(
      `${urlConfig.getDayNote}?restaurantId=${this.restaurantId}` + '&localDate=' + moment(this._cs.headerDate).format("YYYY-MM-DD"));
  }

  setDayNote(dayNote) {
    let requestedPayload: RestaurantNoteDTO = {
      Id: this.restaurantId,
      Date: this._cs.headerDate,
      Text: dayNote
    }
    const postURL = `${urlConfig.setDayNote}?restaurantId=${this.restaurantId}`;
    return this._hs.post(postURL, { Id: requestedPayload.Id, Date: moment(requestedPayload.Date).format("YYYY-MM-DD"), Text: requestedPayload.Text });
  }
  setPropertyType() {
    let restaurant = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_${this.restaurantId}_restaurantSettings`));
    if (restaurant && restaurant.PropertyType) {
      this.PropertyType = restaurant.PropertyType;
      this.LanguageFileURL = restaurant.LanguageFileURL;
      this.translate.setPropertyType(this.PropertyType, restaurant.LanguageFileURL);
    }
  }

  setSignalrClose(Signalrvalue: boolean) {
    this.signalrChanged.next(Signalrvalue);
    this.iframeready.next(true);
  }

  loadPartySources() {
    var partySources = this.restaurantSettings$.PartySources;
    this.OTASourceId = partySources.filter(x => x.ExternalId == 'ota').map(({ Id }) => Id);
  }

  getPartySourceName(sourceId: number) {
    var partySources = this.restaurantSettings$.PartySources;
    if (partySources.filter(x => x.Id == sourceId)[0].Name != undefined)
      return partySources.filter(x => x.Id == sourceId)[0].Name;
  }
  hasPermission(permissionKey: string) {
    if (this.permissionList && this.permissionList !== null && this.permissionList.length > 0) {
      let permission = this.permissionList.filter(permissionValue => permissionValue.Key == permissionKey);
      return permission.length && permission[0].Value;
    }
  }
  runTimer(timerValue) {
    this.totalTime = timerValue;
    this.minutes = Math.floor(this.totalTime / 60);
    this.seconds = Number(this.totalTime % 60);
    this.timerSubscription = interval(1000 * 1).subscribe(() => {
      this.totalTime = this.totalTime - 1;
      this.minutes = Math.floor(this.totalTime / 60);
      this.seconds = Number(this.totalTime % 60);
      if (this.totalTime <= 0) {
        // this.showCartItem = false;
        this.offTimer();
      }
    })
  }

  offTimer() {
    this.TimerOff$.next(false);
    this.showTimer = false;
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  getLatestRevision() {
    return this._hs.get(`${urlConfig.getLatestRevisionUrl}?restaurantId=${Utilities.RestaurantId()}`);
  }
  GetLessonByIdAndRestaurant(id) {
    const getUrl = `${urlConfig.GetLessonByIdAndRestaurant}?id=${id}&restaurantId=${Utilities.RestaurantId()}`
    return this._hs.get(getUrl);
  }

  getLanguages(){
    return this._hs.get(`${urlConfig.getLanguages}`);
  }

  setMomentLocale(){
   //moment.locale('ko'); 
   //moment.locale(this.translate.selectedLanguage); 
  }



  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
