import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewEncapsulation, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Utilities } from '@app/shared/utilities/utilities';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { AuditlogComponent } from '@app/shared/components/auditlog/auditlog.component';
import { ActivityType, AuditableEntityType, BookingBehavior, ComponentTypes, FinancialEffectAction, LessonType, NotificationPreference, OperationResultState, PartyNoteType, PartyState, PartyType, PermissionType, ReservationEmailNotificationType, RolesAndPermissionsType } from '@app/shared/constants/commonenums';
import { DEFAULT_LANGUAGE_CODE, popupDialogDimension } from '@app/shared/constants/globalConstants';
import { TranslateService } from '@ngx-translate/core';
import sortBy from 'lodash/sortBy';
import { PartyService } from '@app/shared/services/party.service';
import { Subscription } from 'rxjs';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { RejectStanbyComponent } from '@app/shared/components/reject-stanby/reject-stanby.component';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { PopupService } from '@app/popup-module/popup.service';
import moment from 'moment';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { RolesAndPermissionPipe } from '@app/shared/pipes/RolesAndPermission.pipe';
import { ConfirmationVia, NotificationFor, PartyEmailSendBehavior, PayingGuest } from '@app/shared/models/RestaurantDTO';
import { BookingHandlerService } from '../booking-handler.service';
import { PageMethod, PartyDTO, PartyPrepaymentState, ReservationDTO } from '@app/shared/models/InputContact';
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';
import { AlertType, ButtonType } from '@app/shared/shared-models';
import { NotesAvailablePipe } from '@app/shared/pipes/NotesAvailablePipe';
import { EngageMemberByCardIdRequestDTO } from '@app/shared/models/EngageMemberByCardIdRequestDTO';
import { POSApiService } from '@app/pos/services/api.service';
import { CartService } from '@app/pos/services/cart.service';
import { ConfigTheme } from '@app/pos/const/global.const';

@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BookingListComponent extends Utilities implements OnInit, OnChanges {
  @Input() reservations: any[];
  @Input() settings: any;
  @Input() mappedGuestFields: any[];
  @Input() FloorPlans: any[];
  @Input() showTimeColumn: boolean;
  @Input() showAssetIcon: boolean;
  @Input() searchText: string;
  @Input() filterData: any;
  memberDetails: any;
  financialPartyData: any;
  allSelected: boolean = false;
  PartyState = PartyState;
  PartyType = PartyType;
  notesReservation: any[];
  notesGuest : any[];
  partyWithAddon: any;
  wristbandId: number;
  customGuestFieldsForBooking: any[] = [];
  locationListGroup: any[] = [];
  subscriptions: Subscription = new Subscription();
  showStayInfo: boolean = false;
  columnSpan: number = 7;
  financialInput: any = {};
  dueState = [PartyPrepaymentState.PartialPrepayment ,PartyPrepaymentState.PaymentDuePending, PartyPrepaymentState.PrepaymentRequired ];
  paidState = [PartyPrepaymentState.DepositCollected , PartyPrepaymentState.Prepaid ];
  refundState = [PartyPrepaymentState.Refunded, PartyPrepaymentState.CancellationFeeApplied];
  financialEffectAction = FinancialEffectAction;
  enableWristband: boolean = false;


  sortData: {
    sortBy: string,
    sortOrder: 'asc' | 'desc'
  } = {
      sortBy: 'FirstName',
      sortOrder: 'asc'
    }

  sortOptions = {
    guestName: 'GuestName',
    size: 'Size',
    status: 'State',
    time: "StartTime",
    LocationName: "LocationName"
  }

  constructor(
    public dialog: MatDialog,
    public ts: TranslateService,
    public ps: PartyService,
    public dashboardFunctions: DashboardFunctions,
    public toastrService: ToastrService,
    public popupService: PopupService,
    private bookingHandler: BookingHandlerService,
    private utilities: CommonUtilities,
    private notesAvailablePipe: NotesAvailablePipe,
    private posApiService: POSApiService,
    private posCartService: CartService,
    private viewContainerRef: ViewContainerRef,
    private permissionPipe?: RolesAndPermissionPipe

  ) {
    super(dialog);
    this.enableWristband = this.settings?.value?.EnableWristband || localStorage.getItem('enableWristband') === 'true';
  }

  ngOnInit(): void {
    this.showStayInfo = this.settings.HasRoomNumberSearch || this.settings.HasCGPSearch;
    if (this.showStayInfo && this.showTimeColumn) {
      this.columnSpan = 8;
    } else if (!this.showStayInfo && !this.showTimeColumn) {
      this.columnSpan = 6;
    } else {
      this.columnSpan = 7;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.reservations?.currentValue) {
      this.reservations.forEach(res => res.expanded = this.bookingHandler.openedTogglesIds.includes(res.Id));
    }
  }

  // Primary Functional Methods
  financialDetails(reservation: any, BookingContact: any) {
    this.financialInput['Party'] = reservation;
    this.financialInput['bookingContact'] = BookingContact || null;
  }

  isNotesAvailable(attendee: any): boolean {
    return !this.notesAvailablePipe.transform(attendee);
  }
  
  moveToShopScreen(attendee,reservation) {
    
    this.ps.currentPartyId = reservation ? reservation.Id : attendee.Id;
    if (Utilities.isUserHasAccessToRetail(Utilities.RestaurantId())) {
      this.ps.retailservice.SetShopItems([], null, null, reservation ? reservation.Id : attendee.Id, null,null, reservation ? attendee?.ContactId : attendee.Contact.Id,null,null, reservation ? attendee.TrackMembershipNumber : attendee?.Contact?.TrackMembershipNumber );
      this.ps.viewBookings = false;
    } else {
      this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
    }
  }

  reservationNotes(attendee: any, isBookingContact:boolean) {
    let notesReservation: any[] = [];
    let notesGuest: any[] = [];

    if (this.settings.Categories?.length) {
        let partyNotes: any[] = [];
        let contactNotes: any[] = [];
        let bookingContactNotes : any[] = [];
        let contactNote : any[] = [];

        this.settings.Categories.forEach(category => {
            category.SubCategories?.forEach(subCategory => {
                if (subCategory.PredefinedPartyNote) {
                    partyNotes.push(...subCategory.PredefinedPartyNote);
                }
                if (subCategory.PredefinedContactNote) {
                    contactNotes.push(...subCategory.PredefinedContactNote);
                }
            });
        });

        let partyNote = attendee?.Notes?.filter(note => 
            partyNotes.some(partyNote => partyNote.Id === note.RelatedId) && 
            note.Type !== PartyNoteType.SpecialMeal
        );

        if(isBookingContact){
          bookingContactNotes = attendee?.BookingContacts
          .flatMap(contact => contact.Notes)?.filter(note =>
          note.Type !== PartyNoteType.SpecialMeal
          );
        }
        else{
          contactNote = attendee?.Notes?.filter(note =>
            note.Type !== PartyNoteType.SpecialMeal
        );
        }

        let reservationNotes = [
          ...(partyNote || [])
        ]

        let guestNotes = [
          ...(bookingContactNotes || []),
          ...(contactNote || [])
        ];

        let uniqueReservationNotes = reservationNotes.filter((note, index, self) =>
          index === self.findIndex((n) => n.Text === note.Text)
        );

        let uniqueGuestNotes = guestNotes.filter((note, index, self) =>
          index === self.findIndex((n) => n.Text === note.Text)
        );

        if (uniqueReservationNotes?.length) {
            notesReservation = sortBy(uniqueReservationNotes, 'Text');
        }
        if(uniqueGuestNotes?.length){
          notesGuest = sortBy(uniqueGuestNotes, 'Text');
        }
    }
    this.notesReservation = notesReservation;
    this.notesGuest = notesGuest;
}

  setWristBand(attendee:any, event?: any){
    event?.stopPropagation();
    event?.preventDefault();
    this.wristbandId = attendee?.WristbandId;
    return this.wristbandId
  }

  setCustomGuestField(partyContact: any) {
    this.customGuestFieldsForBooking = [];
    if (partyContact?.ContactCustomFields) {
      this.customGuestFieldsForBooking = partyContact.ContactCustomFields
        .filter(currentField => this.mappedGuestFields.includes(currentField.CustomFieldId))
        .map(currentField => {
          currentField.CustomFieldName = this.settings?.CustomGuestFields
            .find(masterFieldId => masterFieldId.Id === currentField.CustomFieldId)?.FieldName || '';
          return currentField;
        });
    }
    if(partyContact?.BookingContacts){
      this.customGuestFieldsForBooking = partyContact.BookingContacts
        .flatMap(bookingContact => bookingContact.ContactCustomFields)
        .filter(currentField => this.mappedGuestFields.includes(currentField.CustomFieldId))
        .map(currentField => {
          currentField.CustomFieldName = this.settings?.CustomGuestFields
            .find(masterField => masterField.Id === currentField.CustomFieldId)?.FieldName || '';
          return currentField;
        })
        .filter((currentField, index, self) =>
          index === self.findIndex((field) =>
            field.CustomFieldValue === currentField.CustomFieldValue &&
            field.CustomFieldName === currentField.CustomFieldName
          )
        );
      }
  }

  showPartyAuditLog(attendee: any, reservation?: any) {
    let party = reservation ? reservation : attendee;
    const showAlert = false;
    const title = this.ts.instant('auditlog') + " - " + party.ConfirmationCode;
    const popUpMessage = [{
      dialogTitle: title, showAlert, type: AuditableEntityType.Party, party: party
    }];

    const componentDetails: ComponentDetails = Utilities.setComponentDetails(AuditlogComponent, 'large', 'action', popUpMessage, popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.reservation, popupDialogDimension.createDialogMaxWidth, popupDialogDimension.createDialogMaxHeight, false, title);
  }

  editParty(partyInfo: any) {
    if (!partyInfo?.showEditable) {
      return;
    }
    if ((partyInfo.State == PartyState.Seated && !this.dashboardFunctions.checkEditCheckedInReservationsConfig()) && !this.settings?.PropertySetting[0]?.EnableMultiSlotSelection) {
      this.dashboardFunctions.editGuest(partyInfo.Contact.Id, partyInfo.Id);
    } else {
      this.subscriptions.add(this.ps.getPartyInfo(partyInfo.Id).subscribe(response => {
        if (response.Payload) {
          this.ps.retailservice.oldReservation = _.cloneDeep(response.Payload);
          response.Payload['isConfirmedReservation'] = partyInfo.Type == PartyType.Reservation;
          response.Payload['isStandByLessonBooked'] = partyInfo.Type == PartyType.Reservation;
          response.Payload['bookingRatePlan'] = response.Payload;
          let activity = this.settings.SpecialMeals.find(activity => activity.Id == partyInfo.SpecialMealId);
          if (activity?.LessonType == LessonType.PrivateLesson) {
            this.ps.selectedParty$.next(response.Payload);
            this.dashboardFunctions.createOrEditPrivateActivityBooking(true, response.Payload);
          } else if (activity?.LessonType == LessonType.GroupLesson) {
            this.dashboardFunctions.createOrEditActivityBooking(true, response.Payload);
          } else if (!activity) {
            this.ps.selectedParty$.next(response.Payload);
            this.dashboardFunctions.createOrEditOpenBooking(true, response.Payload);
          }
        }
      }));
    }
  }

  cancelParty(partyInfo: any) {
    this.ps.selectedParty$.next(partyInfo);
    if (partyInfo.SpecialMealId) {
      let activity = this.settings.SpecialMeals.find(activity => activity.Id == partyInfo.SpecialMealId);
      this.ps.BookingBehaviour = activity.LessonType == LessonType.PrivateLesson ? BookingBehavior.PrivateLesson : BookingBehavior.ClassOrSession;
      this.dashboardFunctions.CancelPartyForSessionOrClass(partyInfo);
    } else {
      this.ps.BookingBehaviour = BookingBehavior.OpenBooking;
      this.dashboardFunctions.cancelOpenBooking(partyInfo);
    }
  }

  sendConfirmation(attendee: ReservationDTO , bookingContactId:number = null ){   
    let _attendee = Object.assign(attendee);
    _attendee.ContactId = attendee.Contact?.Id;
    _attendee.SelectedContactId = bookingContactId;
    _attendee.PartyId = attendee.Id;
    _attendee.EmailAddress = attendee.Contact.EmailAddress || null;
    _attendee.BookingContacts = attendee.BookingContacts.filter(contact => contact.State != PartyState.Cancelled)
    let parseData = JSON.stringify(_attendee);
    let input =   {
      ValidationMessages:[],
      Payload: JSON.parse(parseData),
    };
    if(input?.Payload?.NotificationPreference == NotificationPreference.ReservationPageMethod){
      input.Payload.BookingContacts?.forEach(contact => contact.ConfirmationVia = this.getPageMethod(input.Payload.PageMethod));
    }else if(input?.Payload?.NotificationPreference == NotificationPreference.GuestPageMethod){
      input.Payload.BookingContacts?.forEach(contact => contact.ConfirmationVia = this.getPageMethod(contact.PreferredPageMethod));
    }
      this.ps.showEmailNotification(input, ReservationEmailNotificationType.Created);
  }

  getPageMethod(pageMethod:PageMethod){
    if(pageMethod == PageMethod.Email){
      return ConfirmationVia.Email;
    }else if(pageMethod == PageMethod.Sms){
      return ConfirmationVia.Sms;
    }else {
      return ConfirmationVia.None;
    }
  }

  chargeGuest(attendee, reservation, isRefund: boolean = false, selectedItem) {
    if (attendee.refundWithCancel || attendee.showCollectCharge) {
      this.bookingHandler.chargeGuest(attendee, reservation, null, isRefund ? 'refundText' : 'chargeGuest' , this.settings);
    } else {
      this.performRateChanges(reservation || attendee, FinancialEffectAction.Refund, selectedItem)
    }
  }

  performRateChanges(party, actionType, payingGuest?) {
    let _payingGuest: PayingGuest[];
    if(payingGuest?.BookingContacts?.length || payingGuest?.ContactId){
      let _session = party.BookedSessionId ? party.BookedSessions.find(({Id, BookedDate}) => Id === party.BookedSessionId) : null;
      _payingGuest = [{
        ActivitySessionId: _session?.ActivitySessionId || null,
        BookingDate: _session?.BookedDate || null,
        PayingContacts: payingGuest?.BookingContacts?.length ? _.map(party.BookingContacts,'ContactId') : [payingGuest.ContactId],
        ContactIdentifier: []
      }]
    }
    this.ps.getFinancialDetails(party.Id, actionType, null, null, false, _payingGuest).subscribe(
      (data) => {
        this.ps.openCheckInWithDuesPopUp(
          party,
          data.Payload,
          ComponentTypes.RateChanges,
          _payingGuest,
          this.fetchActionName(actionType),
          actionType
        );
      }
    );
  }
  
  fetchActionName(actionType) {
    switch (actionType) {
      case FinancialEffectAction.Reprice:
        return this.ts.instant("RepriceText");
      case FinancialEffectAction.Refund:
        return this.ts.instant("refundText");
      case FinancialEffectAction.WaivedOff:
        return this.ts.instant("Waive-Off");
    }
  }
  fetchMemberDetails(membershipId,event,popover){
    let {SiteId, TenantId} =  this.settings.General.EngageIntegrationDTO;

    let req = new EngageMemberByCardIdRequestDTO(membershipId, TenantId, SiteId);

    this.subscriptions.add(this.ps.api.getEngageMemberByCardId(req).subscribe(memberInfo =>{
      this.memberDetails = memberInfo?.ProfileInfoGetByCardId?.ProfileValue;
      popover.open();
    }));
    
    event.stopPropagation();
    event.preventDefault()
  }

  triggerEmail(attendee: any) {
    if (!attendee || !attendee?.BookingContacts) {
      console.error("Attendee or BookingContacts is null or undefined.");
      return;  
    }

    if (attendee.BookingContacts && Array.isArray(attendee.BookingContacts)) {
      attendee.BookingContacts.forEach(contact => {
          contact.SendReminder = false;
          contact.ConfirmationVia = ConfirmationVia.Email; 
          contact.NotificationFor = NotificationFor.Reservation;
          contact.bookingSelected = true;
      });
  }

    const party = {
        CartBookingId: attendee.CartBookingId, 
        partyId: attendee.Id, 
        reservationEmailNotificationType: attendee.NotificationPreference, 
        cancelledIds: [], 
        bookedSessionId: attendee.BookedSessions?.[0]?.Id ?? null,
        classOrSessionBooking: false, 
        bookingContacts: attendee.BookingContacts
    };

    const filteredContact = attendee.BookingContacts.find(contact => contact.ContactId === attendee.Contact.Id);
    var email = filteredContact ? filteredContact.EmailAddress : null;

    this.ps.SendConfirmaionEmail(
        party,
        attendee.partyId, 
        email, 
        attendee.NotificationPreference, 
        [],
        attendee.BookedSessions[0].Id, 
        false 
    ).subscribe(response => {
        console.log("Email sent successfully", response);
    }, error => {
        console.error("Failed to send email", error);
    });
}
  
  setBookingState(pickedLineItem: any, State: any, party?: any, checkInWithDues?: boolean, skipValidation?: boolean) {
    if (!this.checkUserPermissions(State == PartyState.Pending ? RolesAndPermissionsType.UndoCheckIn : RolesAndPermissionsType.CheckInCheckOutGuest)) {
      return;
    }
    let booking = party ? party : pickedLineItem;
    let _BookingContactStateIds = pickedLineItem.contactStatesId  ? [pickedLineItem.contactStatesId ] : this.getContactStateIds(pickedLineItem);
    let payload = {
      State: State,
      BookingId: booking?.Id,
      BookingSessionId: booking?.BookedSessionId || null,
      BookingContactStateIds: _BookingContactStateIds
    }
    this.ps.ratePlanObject = null;
    if (checkInWithDues) {
      if (this.validateCheckInOperation(pickedLineItem , party, State, checkInWithDues, skipValidation)) {
        this.checkInWithDues(booking ,pickedLineItem);
      }
    } else {
      if ((State == PartyState.Seated && this.validateCheckInOperation(pickedLineItem , party, State, checkInWithDues, skipValidation)) || State !== PartyState.Seated) {
        let activity = this.settings.SpecialMeals?.find(activity => activity.Id == booking?.SpecialMealId);
        if (State == PartyState.Left && (activity == null || undefined || activity.LessonType == LessonType.PrivateLesson)) {
          let { party, payingGuest } = this.payingGuest(booking ,pickedLineItem);
    
          this.ps.CalculateAdditionalCharge(party.Id,payingGuest).subscribe(response =>{
    
            if(response?.Payload?.RatePlan && response?.Payload?.RatePlan?.Unpaid !== 0 ){
    
              if (Utilities.isUserHasAccessToRetail(Utilities.RestaurantId())) {
                this.checkOutWithDues(booking ,pickedLineItem,response.Payload);
              }
              else{
                this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
              }
            }
            else{
              this.ps.setBookingState(payload).subscribe(response => {
                this.confirmationPopUp(response, State, booking);
              })
            }
          })
      
        }
        else if (pickedLineItem.showCheckoutWithDues && State == PartyState.Left) {
          this.checkOutWithDues(booking ,pickedLineItem);
        } 
        else{
          this.ps.setBookingState(payload).subscribe(response => {
            this.confirmationPopUp(response, State, booking);
          })
        } 
      } else if (pickedLineItem.showCheckoutWithDues && State == PartyState.Left) {
        this.checkOutWithDues(booking ,pickedLineItem);
      } 
    }
  }

  getContactStateIds(pickedLineItem):any[] {
    let _ids = [];
    if(pickedLineItem?.mappedContact?.length){
      _ids = pickedLineItem?.mappedContact?.filter(item => item.selected && item.State !== PartyState.Cancelled)?.map(item => item.contactStatesId) || [];
      if (!_ids?.length) {
        _ids = pickedLineItem.mappedContact.filter(contact => contact.State == pickedLineItem.State && contact.State !== PartyState.Cancelled ).map(item => item.contactStatesId)
      }
    }else if(pickedLineItem?.BookingContacts){
      _ids = pickedLineItem?.BookingContacts.map(item => item.BookingContactStates?.filter(contact => contact.BookedSessionId == pickedLineItem.BookedSessionId)).flat()?.map(bookingContact => bookingContact.Id) || [];
    }
    return _ids;
  }
  confirmationPopUp(response, State, booking) {
    if (response.State == OperationResultState.Success) {
      let msg = '';
      let activityName = this.ps.getActivityName(booking?.SpecialMealId)

      if (State == PartyState.Seated) {
        msg = this.ts.instant('attendeeCheckedInSession' , {activityName})
      }
      else if (State == PartyState.Left) {
        msg = this.ts.instant('attedeeCheckedOutSession' , {activityName})
      } else if (State == PartyState.Pending) {
        msg = this.ts.instant('attendeeReInState');
      }
      this.ps.openConfirmationDialog(response, msg, null, null, null, null);
      this.toggleAllCheckboxes(false, booking)
    }
  }
  checkUserPermissions(_permissionType: RolesAndPermissionsType = null): boolean {
    if (!this.permissionPipe.transform(_permissionType)) {
      this.toastrService.warning(this.ts.instant('accessDenied'), "", { timeOut: 5000, closeButton: true });
      return false;
    } else {
      return true;
    }
  }
  validateCheckInOperation(pickedLineItem:any , attendee: any, State: any, checkInWithDues: boolean, skipValidation: boolean = false) {
    let allowCheckIn = true;

    const staffTbaId = this.settings?.Servers?.find(x => x.IsTemplate)?.Id;
    if((pickedLineItem?.ServerId == staffTbaId && pickedLineItem?.isPrivateLessonBooking) || (attendee?.ServerId == staffTbaId && attendee?.isPrivateLessonBooking)){
      this.showStaffAssignError();
      return;
    }

    if (skipValidation) {
      return true;
    }
    let booking = attendee ? attendee : pickedLineItem;
    const checkInPastReservation = this.dashboardFunctions.checkBookPastDaysReservationsConfig();
    let propertyTime = moment(moment(Utilities.getRestaurantDateTime(this.settings.General.DaylightDelta)).format('YYYY-MM-DDTHH:mm'))
    let activity = this.settings.SpecialMeals?.find(activity => activity.Id == booking?.SpecialMealId);
    if (activity && activity.LessonType == LessonType.GroupLesson) {
      if(activity.ClassType === ActivityType.SessionBooking){
        let activityCutOff = this.settings.General.TimeSlotUnitInMinutes;
        let minutesBeforeBooking = 0;
        let sessionTime;
        if(this.filterData){
          sessionTime = moment(this.filterData.weeklyViewDate || this.filterData.date).format('YYYY-MM-DD') + `T${this.filterData.startTime}`;
          activityCutOff = activity.CheckInCutOff || this.settings.General.TimeSlotUnitInMinutes;
          minutesBeforeBooking = moment(sessionTime).diff(propertyTime, 'minutes');
        }
        if(isNaN(minutesBeforeBooking)) minutesBeforeBooking = 0;
  
        if (minutesBeforeBooking > activityCutOff) {
          let allowedCheckingTime = moment(sessionTime).subtract(activityCutOff, 'minutes');
          let message = this.ts.instant('timeNotArrivedMessage') + allowedCheckingTime.locale(this.getLocaleCode()).format("DD/MM/yyyy hh:mm a");
          allowCheckIn = false;
          this.showWarningInfoPopUp(message, false, pickedLineItem , attendee, State, checkInWithDues);
        }
      }
      return allowCheckIn;

    } else {
      let privateBookingWarning = null
      if(activity && activity.LessonType == LessonType.PrivateLesson){
        privateBookingWarning = this.isScheduleTimeArrived(activity , booking.StartTime);
      }
   
      if (privateBookingWarning == 'block' || (!privateBookingWarning && moment(moment(booking.SeatingTime).format('YYYY-MM-DDTHH:mm')).diff(propertyTime, 'minutes') > this.settings.General.TimeSlotUnitInMinutes)) {
        let message = this.ts.instant('timeNotArrivedMessage') + moment(booking.SeatingTime).locale(this.getLocaleCode()).format("DD/MM/yyyy hh:mm a");
        allowCheckIn = false;
        this.showWarningInfoPopUp(message, false, pickedLineItem , attendee, State, checkInWithDues);
      }
      else if (privateBookingWarning == 'warn' || (!privateBookingWarning && moment(moment(booking.DepartureTime).format('YYYY-MM-DDTHH:mm')).diff(propertyTime, 'minutes') <= 0)) {
        let message = this.ts.instant('sessionExpiredMessage');
        if (checkInPastReservation) {
          message = message + this.ts.instant('warningForProceeding');
        }
        if(privateBookingWarning == 'warn'){
           let formattedSeatingTime = moment(booking.StartTime).diff(propertyTime, 'days') == 0 ? moment(booking.StartTime).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm a") : moment(booking.StartTime).locale(this.dashboardFunctions.getLocaleCode()).format("DD-MMM-yyyy hh:mm a");
          
           this.ts.get('CheckInConfirm', { sessionTime: formattedSeatingTime }).subscribe(msgVal => message = msgVal);
        }
        allowCheckIn = false;
        this.showWarningInfoPopUp(message, true, pickedLineItem , attendee, State, checkInWithDues);

      } else {
        allowCheckIn = true;
      }
    }

    return allowCheckIn;
  }

  isScheduleTimeArrived(selectedActivity, seatingTime): 'block' | 'warn' | 'allow'{
    
    let currentTime = Utilities.getRestaurantDateTime(this.settings.General.DaylightDelta);
    let diffMin = moment(seatingTime).diff(currentTime, 'minute');
    if (selectedActivity.CheckInCutOff && diffMin > selectedActivity.CheckInCutOff) {
      return 'block';
    } else if(!selectedActivity.CheckInCutOff && diffMin > 0){
      return 'warn'
    } 
    return 'allow';
  }


  
  showStaffAssignError(){
    this.dashboardFunctions.showErrorPopUp('Assign Staff to checkin', 'auto')
  }
  showWarningInfoPopUp(message, isActionPopup, pickedLineItem:any , attendee: any, State: any, checkInWithDues: boolean) {
    let cancelText = isActionPopup ? 'No' : '';


    let updateText = isActionPopup ? 'Yes' : 'Ok';
    let showAlert = !isActionPopup;
    let title = this.ts.instant('CheckinInfoText');

    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: title, showAlert: showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: isActionPopup ? 'active' : 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    let dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '350px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.CheckinSession,
        back: false,
        standalone: true,
        showAlert: true,
        showAction: true,
      }
    });
    let confirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val == ComponentTypes.CheckinSession && isActionPopup) {
        this.setBookingState(pickedLineItem , State, attendee, checkInWithDues, true)
      }
    });

    dialogRef.afterClosed().subscribe(event => {
      if (confirmSubscription)
        confirmSubscription.unsubscribe();
    });

  }
  getLocaleCode(): string {
    let defaultCode = DEFAULT_LANGUAGE_CODE;
    let languageCode = sessionStorage.getItem('languageCode') || DEFAULT_LANGUAGE_CODE;
    let allAvailableLanguages = JSON.parse(sessionStorage.getItem('languages')) || [];
    if (languageCode) {
      return languageCode;
    }
    return defaultCode;
  }

  confirmClick(attendee: any) {
    let confirmBooking = false;
    let selectedActivity = this.settings.SpecialMeals.find(activity => activity.Id == attendee.SpecialMealId) || null;
    if (selectedActivity || !attendee.SpecialMealId) {
      let msg = this.ts.instant('areYouSureYouWanttoConfirm');
      this.ps.showWarningInfoPopUp(msg, ComponentTypes.ConfirmBooking, '250px', '450px', this.ts.instant('confirmReservation'))
      this.subscriptions.add(this.ps.warningInfoDialogRef?.afterClosed().subscribe(event => {
        this.UnSubscribePopUpAction(confirmInPopUpConfirmationSubscription);
      }));

      let confirmInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.ConfirmBooking) {
          confirmBooking = true;
          this.ps.convertActivityStandbyToReservation(attendee, false, null);
          confirmInPopUpConfirmationSubscription.unsubscribe();
        }
      });
    }
  }

  rejectClick(attendee: any) {
    let rejectBooking = false;
    this.ps.cancelBookingContactObj = attendee?.Contact;
    let selectedActivity = this.settings.SpecialMeals.find(activity => activity.Id == attendee.SpecialMealId) || null;
    if (selectedActivity || !attendee.SpecialMealId) {
      const componentDetails: ComponentDetails = {
        componentName: RejectStanbyComponent,
        dimensionType: 'small',
        popupType: 'active',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: '',
        popupTitle: this.ts.instant('rejectionReason'),
      };
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '450px',
        height: '350px',
        data: {
          title: this.ts.instant('rejectionReason'),
          update: 'Yes',
          cancel: 'No',
          componentDetails,
          from: ComponentTypes.RejectBooking,
          back: false,
          standalone: true,
          showAlert: true
        }
      });
      this.subscriptions.add(dialogRef.afterClosed().subscribe(data => {
        if (rejectBooking) {
          alert('reject Click');
        }
        this.UnSubscribePopUpAction(rejectInPopUpConfirmationSubscription);
      }));

      let rejectInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.RejectBooking) {
          this.ps.rejectStandActivity(this.ps.ReasonForReject, attendee, attendee.Contact.EmailAddress, attendee.BookedSessionId);
          rejectInPopUpConfirmationSubscription.unsubscribe();
        }
      });
    }
  }

  UnSubscribePopUpAction(obj: Subscription) {
    if (obj) {
      obj.unsubscribe();
    }
  }

  checkInWithDues(attendee: any , bookingContact:any) {
    if (Utilities.isUserHasAccessToRetail(attendee.RestaurantId)) {
      let party = Object.assign({}, attendee)
      party.FinancialEffectId = null;
      party.Sessions = { SessionId: party.BookedSessionId };
      let payingGuest = this.bookingHandler.composePayingGuest(attendee , party.BookedSessionId , bookingContact?.ContactId || null);
      this.ps.getFinancialDetails(party.Id, FinancialEffectAction.CheckIn, null, null, false, payingGuest).subscribe(data => {
        this.ps.openCheckInWithDuesPopUp(party, data.Payload, ComponentTypes.CheckInRatePlanSummary, payingGuest , 'CheckIn',FinancialEffectAction.CheckIn,null,bookingContact);
      })
    } else {
      this.toastrService.warning(this.ts.instant('userPermissionMsg'));
    }
  }

  checkOutWithDues(attendee: any , bookingContact:any,financialData:any = null) {
    if (Utilities.isUserHasAccessToRetail(attendee.RestaurantId)) {
      let { party, payingGuest } = this.payingGuest(attendee, bookingContact);
      if(financialData == null){
        this.ps.getFinancialDetails(party.Id, FinancialEffectAction.CheckOut, null, null, false, payingGuest).subscribe(data => {
          this.ps.openCheckInWithDuesPopUp(party, data.Payload, ComponentTypes.CheckOutRatePlanSummary, payingGuest , 'CheckOut',FinancialEffectAction.CheckOut);
        })
      }else{
        this.ps.openCheckInWithDuesPopUp(party, financialData, ComponentTypes.CheckOutRatePlanSummary, payingGuest , 'CheckOut',FinancialEffectAction.CheckOut);
      }
    } else {
      this.toastrService.warning(this.ts.instant('userPermissionMsg'));
    }
  }

  private payingGuest(attendee: any, bookingContact: any) {
    let party = Object.assign({}, attendee);
    party.FinancialEffectId = null;
    party.Sessions = { SessionId: party.BookedSessionId };
    let payingGuest = this.bookingHandler.composePayingGuest(attendee, party.BookedSessionId, bookingContact?.ContactId || null);
    return { party, payingGuest };
  }

  undoCheckIn(attendee: any) {
    this.ps.reinstateReservation(attendee);
  }

  showPartyWaiverForm(attendee: any, reservation: any) {
    let booking = reservation ? reservation : attendee;
    let contactId = attendee.ContactId || attendee.Contact.Id;
    this.subscriptions.add(this.ps.getWaiverFormUrl(booking.ConfirmationCode, booking.BookingReferenceId, contactId).subscribe(data => {
      if (data.State == OperationResultState.Success) {
        if (!(data.Payload)) {
          let bookingContact = booking.BookingContacts.filter(x => x.ContactId == contactId);
          if(bookingContact != null)
            window.open(bookingContact[0].WaiverFormURL, 'Waiver Form');
        }
        else {
          const binaryString = window.atob(data.Payload);
          const bytes = new Uint8Array(binaryString.length);
          const binaryToBlob = bytes.map((byte, i) => binaryString.charCodeAt(i));
          const blob = new Blob([binaryToBlob], { type: 'application/pdf' });
          this.downloadFile(blob, "123.pdf");
        }
      }
    }));
  }

  private downloadFile(blob: any, fileName: string): void {
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    window.open(url, '_blank');

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 15 * 60 * 1000);
  }

  locationListUpdate(attendee: any) {
    let locationList = [];
    if (attendee.BookedSessions?.length) {
      let selectedSession = attendee.BookedSessions.find(session => session.Id === attendee.BookedSessionId);
      let defaultLayouts = this.FloorPlans?.find(floor => floor.IsDefault)?.StandaloneTables;
      selectedSession?.Locations?.forEach(location => {
        let loc = {
          Name: defaultLayouts?.find(table => table.Id === location)?.Name
        };
        locationList.push(loc);
      });
    }
    this.locationListGroup = locationList;
  }

  updateAddOnDetails(activity: any , bookingContact: any) {
    let _partyWithAddon = Object.assign(activity);
    _partyWithAddon['BookedSessionId'] = bookingContact?.bookingStates?.BookedSessionId || null;
    _partyWithAddon['BookingContactId'] = bookingContact?.bookingStates?.BookingContactId || null;
    this.partyWithAddon = _partyWithAddon;
  }

  toggleAllCheckboxes(isSelected, booking) {
    this.bookingHandler.toggleAllCheckboxes(isSelected, booking)
  }
  selectBookingContacts(isSelected, BookingContact, reservation) {
    if (isSelected && reservation.mappedContact.some(contact => contact.State !== BookingContact.State && contact.selected)) {
      this.toastrService.warning(this.ts.instant('bookincontactSelectionWarning'), "", { timeOut: 5000, closeButton: true });
      setTimeout(() => {
        BookingContact.selected = false;
      }, 0);
    } else {
      BookingContact.selected = isSelected;
      let _mappedContact = reservation?.mappedContact?.filter(contact => contact.selected)?.length 
      if(_mappedContact == reservation?.mappedContact?.length){
        reservation.selected = true;
        reservation['indeterminate'] = false;
      }else if(_mappedContact > 0){
        reservation['indeterminate'] = true;
      }else{
        reservation.selected = false;
        reservation['indeterminate'] = false;
      }
    }
  }

  toggleReservation(attendee: any) {
    if(attendee?.mappedContact.length > 0 ){
      this.bookingHandler.toggleReservation(attendee);
    }
  }

  sortClick(sortBy) {
    if (this.sortData.sortBy === sortBy) {
      if (this.sortData.sortOrder === 'asc') {
        this.sortData.sortOrder = 'desc';
      } else {
        this.sortData.sortOrder = 'asc';
      }
      this.sortData = { ...this.sortData };
    } else {
      this.sortData = {
        sortBy: sortBy,
        sortOrder: 'asc'
      }
    }
    this.reservations = _.orderBy(this.reservations, this.sortData.sortBy, this.sortData.sortOrder);
  }

  addWristband(bookingReferenceID: string, type: 'cardBookingId' | 'bookingreferenceNo') {
    if(bookingReferenceID){
      this.posApiService.getWristbandURL(bookingReferenceID, type).subscribe(({ Payload: responseData }) => {
        if (responseData) {
          this.posCartService.showWristbandContainer(this.viewContainerRef, { cartBookingId: 0, wristbandInputDetail: responseData, screen: 'MODERNCLIENT' }, null)
        }else{
          this.dashboardFunctions.showErrorPopUp("Wristband mapping is not enabled.", "auto")
        }
      })
    }
  }

}
