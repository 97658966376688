<div class="reservation-container">
  <ng-container>
    <div class="attendees">
      <table class="table">
        <thead class="user-card user-card__data-header">
          <tr>
            <th>
              <div class="user-sno">
                <!-- <mat-checkbox (change)="toggleSelectAll($event)"></mat-checkbox> -->
              </div>
            </th>
            <th>
              <div class="user-card__content-view pointer noSelect" (click)="sortClick(sortOptions.guestName)">
                {{'GuestDetailText' | translate}}
                <i class="sortIcon show"
                  [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.guestName && sortData.sortOrder==='asc', 'icon-Sort-Down': sortData.sortBy===sortOptions.guestName && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.guestName}"></i>
              </div>
            </th>
            <th *ngIf="showStayInfo">
              <div class="pointer noSelect stay-info">
                {{'StayInfo' | translate}}
              </div>
            </th>
            <th>
              <div class="pointer noSelect reference-number">
                {{'bookingAndConfirmation' | translate}}
              </div>
            </th>

            <th>
              <div class="pointer noSelect user-card__covers" (click)="sortClick(sortOptions.size)">
                {{'sizeText' | translate}}
                <i class="sortIcon show"
                  [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.size && sortData.sortOrder==='asc', 'icon-Sort-Down': sortData.sortBy===sortOptions.size && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.size}"></i>
              </div>
            </th>
            <th *ngIf="showTimeColumn">
              <div class="user-card__timing" (click)="sortClick(sortOptions.time)">
                {{'time' | translate}}
                <i class="sortIcon show"
                  [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.time && sortData.sortOrder==='asc', 'icon-Sort-Down': sortData.sortBy===sortOptions.time && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.time}"></i>

              </div>
            </th>
            <th *ngIf="showTimeColumn">
              <div class="user-card__timing" (click)="sortClick(sortOptions.LocationName)">
                {{'Tables' | translate}}
                <i class="sortIcon show"
                  [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.Tables && sortData.sortOrder==='asc', 'icon-Sort-Down': sortData.sortBy===sortOptions.Tables && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.Tables}"></i>

              </div>
            </th>
            <th>
              <div class="user-card__payment">
                {{'paymentText' | translate}}

              </div>
            </th>
            <th>
              <div class="user-card__status-col pointer noSelect" (click)="sortClick(sortOptions.status)">
                {{'Status' | translate}}
                <i class="sortIcon show"
                  [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.status && sortData.sortOrder==='asc', 'icon-Sort-Down': sortData.sortBy===sortOptions.status && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.status}"></i>

              </div>
            </th>
            <th class="table-action" >
              <div class="user-card__actions">
                {{'actionText' | translate}}
              </div>
            </th>
            <th>
              <div class="user-card__delete">

              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let attendee of reservations; let i = index">
            <tr class="user-card">
              <ng-template [ngTemplateOutlet]="reservationTemplate" [ngTemplateOutletContext]="{ attendee: attendee}">
              </ng-template>
            </tr>
            <ng-container *ngIf="attendee?.mappedContact?.length > 0">
              <ng-container *ngIf="(attendee?.mappedContact | searchActiveWristband : searchText) as showExpanded">
                <tr colspan="columnSpan" class="user-card" [ngClass]="{'expanded' : attendee.expanded}"
                [class.filtered-attendee]="searchText && bookingContact?.WristbandId === searchText && bookingContact.IsActiveWristBand"
                  [hidden]="!attendee.expanded && showExpanded === 'no'" *ngFor="let bookingContact of attendee?.mappedContact">
                  <!-- Use ng-template for reusing reservation component -->
                  <ng-template [ngTemplateOutlet]="bookingContactTemplate"
                    [ngTemplateOutletContext]="{ attendee: bookingContact , party: attendee }">
                  </ng-template>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
          <tr *ngIf="reservations.length < 1" class="mt-4">
            <td colspan="7">
              <div class="no-attendees">
                {{'NoAttendees' | translate}}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </ng-container>
</div>

<!-- Define the template for reservation -->
<ng-template #reservationTemplate let-attendee="attendee">
  <td>
    <div class="user-sno">
      <ng-container *ngIf="(attendee?.mappedContact | searchActiveWristband : searchText) as showExpanded">
      <div class="arrow-container" [ngClass]="{'icon-disabled' : attendee?.mappedContact.length == 0 || showExpanded === 'yes' }"
        (click)="toggleReservation(attendee)">
        
          <span class="icon-Down-Arrow"
            [hidden]="!attendee.expanded && showExpanded !== 'yes'"></span> <span class="icon-right-arrow" [hidden]="attendee.expanded || showExpanded === 'yes'"></span>        
      </div>
    </ng-container>
      <mat-checkbox (change)="toggleAllCheckboxes($event.checked ,attendee)" [(ngModel)]="attendee.selected" [indeterminate]="attendee?.indeterminate"
        [disabled]="attendee.disableCheckbox"></mat-checkbox>
      <i class="icon-VIP" [ngClass]="{'VIP-selected':attendee?.Contact.IsVip}"></i>
    </div>
  </td>
  <td (click)="editParty(attendee)" class="cursor">
    <div class="user-card__content-view">
      <h3 class="user-card__name text-ellipsis" *ngIf="attendee.Contact">
        {{attendee?.Contact?.FirstName}} {{attendee?.Contact?.LastName || ''}}  
        <span *ngIf="attendee.isWristBandIssued" [matTooltip]="(attendee.IsActiveWristBand ? 'Active' : 'InActive') | translate" 
            [ngClass]="attendee.IsActiveWristBand ? 'icon-wristband active' : 'icon-wristband_off inactive'"
            [autoClose]="'outside'" container="body" placement="auto"
            [ngbPopover]="wristbandContainer" triggers="manual" #wristbandPopOver="ngbPopover"
            (click)="!attendee?.mappedContact.length && wristbandPopOver.open();setWristBand(attendee, $event)">
          </span>
        </h3>
      <p class="user-card__mobile text-ellipsis">
        {{attendee?.Contact?.PhoneNumber | phoneNumberFormatter: attendee?.Contact?.CountryId}}</p>
        <p *ngIf="attendee?.Contact?.TrackMembershipNumber && attendee?.Contact?.TrackMembershipNumber != ''" class="user-card__member-info mb-0 seat-body-text"
        [autoClose]="'outside'" container="body" placement="auto"
       [ngbPopover]="memberInfoPopover"  #memberInfo="ngbPopover" triggers="manual" popoverClass="memberinfo-popover"
       (click)="fetchMemberDetails(attendee?.Contact?.TrackMembershipNumber, $event, memberInfo)">
       {{attendee?.Contact?.IsMemberActive ? ('memberText' | translate) : (('Inactive' | translate) +' '+ ('memberText'| translate)) }}</p>
        
    </div>
  </td>

  <td *ngIf="showStayInfo">
    <div class="stay-info">
      <div *ngIf="!attendee.RoomNumber  || attendee.RoomNumber.length == 0"> {{'NA' | translate}} </div>
      <div *ngIf="attendee.RoomNumber  && attendee.RoomNumber.length > 0" style="cursor: pointer;"
        [autoClose]="'outside'" container="body" [ngbPopover]="packageInformation" placement="bottom"
        (click)="dashboardFunctions.openPackage(attendee)">
        <p *ngIf="attendee.CommonPackageInfo == undefined">{{'RoomNo' | translate}} : {{ attendee.RoomNumber
          }}
        </p>
        <u *ngIf="attendee.CommonPackageInfo && attendee.CommonPackageInfo.length > 5">
          {{'RoomNo' | translate}} : {{ attendee.RoomNumber }}
        </u>
      </div>
    </div>
  </td>
  <td>
    <div class="reference-number">
      <div class="conirmation">
        {{attendee?.ConfirmationCode}}
      </div>
      <div class="reference">
        {{attendee?.BookingReferenceId}}
      </div>
    </div>
  </td>

  <td>
    <div class="user-card__covers">
      <span *ngIf="attendee.Size" [class.overbooked-bg]="attendee?.OverBooked"
        [matTooltip]="attendee?.OverBooked ? (('OverbookedBy' | translate) + ' ' + attendee?.OverBooked) : attendee.Size"><span
          class="icon icon-add-contact"></span>
        {{attendee.Size}}</span>
    </div>
  </td>
  <td *ngIf="showTimeColumn">
    <div class="user-card__timing content">
      {{ attendee.StartTime | localizedDate : 'LT' }}
      - {{ attendee.EndTime | localizedDate : 'LT' }}
    </div>
  </td>
  <td *ngIf="showTimeColumn">
    <div class="user-card__timing content">
      {{ attendee.LocationName}}
    </div>
  </td>
  <td>
    <div class="user-card__payment">
      <ng-container *ngIf="(attendee | partyPaymentState) as paymentState">
        <span *ngIf="paymentState!=='NA'" class="user-card__payment--label payment-{{paymentState}}" container="body"
          placement="auto" [autoClose]="'outside'" [ngbPopover]="financialStatusPopover" 
          (click)="financialDetails(attendee)">
          {{ paymentState | translate }}
        </span>
        <span *ngIf="paymentState=='NA'">
          {{'NA' | translate}}
        </span>
      </ng-container>
    </div>
  </td>
  <td>
    <div class="user-card__status-col">
      <div *ngIf="attendee.Type == PartyType.StandBy && attendee.State == PartyState.Pending ;else reservationStatus" 
        class="user-card__status">
        <span class="user-card__status user-card__status--7">
          {{'Standby'| translate}}</span>
      </div>
      <ng-template #reservationStatus>
        <span class="user-card__status user-card__status--{{attendee.State}}">
          {{'Status_'+PartyState[attendee.State]| translate}}</span>
      </ng-template>
    </div>
  </td>
  <td class="table-action" >
    <ng-template [ngTemplateOutlet]="reservationActions" [ngTemplateOutletContext]="{ attendee: attendee}">
    </ng-template>

  </td>
  <td>
    <div class="user-card__cancel">
      <div class="action-icons">
        <div class="action-icon-list" *ngIf="showAssetIcon"
          [ngClass]="{'exclusive-booking' : attendee.IsExclusiveBooking}">
          <span class="icon icon-rntl-inventory mr-0" [ngClass]="{'icon-disabled' : !attendee.isLocationGroupMapped}"
            matTooltip="{{'Asset' | translate}}" [autoClose]="'outside'" container="body" placement="auto"
            triggers="manual" #locationGroupPopOver="ngbPopover" container="body" [ngbPopover]="locationFieldsContainer"
            (click)="(!attendee.isLocationGroupMapped) ? return : locationGroupPopOver.open();locationListUpdate(attendee)">
          </span>
        </div>
        <div class="action-icon-list">
          <div [ngClass]="{'icon-disabled' : !(attendee.PackageName !== null && attendee.PackageName !== undefined)}"
            [matTooltipDisabled]="(attendee.PackageName !== null && attendee.PackageName !== undefined)"
            matTooltip="{{'packages' | translate}}">
            <span class="icon-offer-redemption"
              [matTooltipDisabled]="!(attendee.PackageName !== null && attendee.PackageName !== undefined)"
              matTooltip="{{attendee.PackageName}}"></span>
          </div>
        </div>
        <div>

        </div>
        <div class="action-icon-list ">
          <span class="icon icon-note1" [ngClass]="{'icon-disabled': !(attendee | notesAvailable)}"
            matTooltip="{{'notes' | translate}}" [autoClose]="'outside'" container="body" placement="auto"
            [ngbPopover]="reservationNotesPopOver" triggers="manual" #reservationNotePopOver="ngbPopover"
            (click)="isNotesAvailable(attendee) ? null : reservationNotePopOver.open();reservationNotes(attendee , true)"></span>
        </div>
        <div class="action-icon-list">
          <div class="mt_5" *ngIf="(attendee.Contact | partyGuestFieldMapping:  mappedGuestFields) as partyGuestFields"
            matTooltip="{{'CustomGuestFields'| translate}}">
            <div *ngIf="partyGuestFields?.length ; else additionalField">
              <span class="icon icon-Group-197 mr-0" [autoClose]="'outside'" container="body" placement="auto"
                triggers="manual" #customGuestFieldsPopover="ngbPopover" container="body"
                [ngbPopover]="customGuestFieldsContainer"
                (click)="!(partyGuestFields?.length) ? return : customGuestFieldsPopover.open();setCustomGuestField(attendee)">

              </span>
            </div>
            <ng-template #additionalField><span class="icon icon-Group-197 icon-disabled "></span></ng-template>
          </div>
        </div>
        <div class="action-icon-list">
          <div
            [ngClass]="{'icon-disabled' : !(attendee?.BookingContactAddonItems?.length > 0) , 'mt_5' : attendee?.BookingContactAddonItems?.length > 0 }"
            matTooltip="{{'addons'| translate}}">
            <span class="icon icon-addons mr-0" [autoClose]="'outside'" placement="auto" triggers="manual"
              #addOnForBookingPopover="ngbPopover" container="body" [ngbPopover]="addOnForBooking"
              (click)=" !(attendee?.BookingContactAddonItems?.length > 0) ? return : addOnForBookingPopover.open();updateAddOnDetails(attendee)">
            </span>
          </div>
        </div>
        <div class="action-icon-list w-40" *ngIf="!noActionRequired">
          <chat-info-container class="user-card__data-align-content" [party]="attendee"
            [bulkPagingRequest]="attendee.PageRequest"
            [ContactId]="attendee?.mappedContact.length == 0 ? attendee?.Contact?.Id : null"
            [guestPageMethod]="attendee?.Contact?.PreferredPageMethod"
            [isGuestPreferred]="attendee.NotificationPreference ? true :  false" [isValidContact]="true"></chat-info-container>
        </div>
        <div class="action-icon-list">
          <span class="icon-more user-card__data-align-content" mat-button [matMenuTriggerData]="{attendee:attendee}"
            [matMenuTriggerFor]="moreOptionsReservationTab"></span>
        </div>
      </div>

    </div>
  </td>

</ng-template>

<ng-template #bookingContactTemplate let-attendee="attendee" let-party="party">
  <td>
    <div class="user-sno">
      <mat-checkbox (change)="selectBookingContacts($event.checked , attendee , party)" [(ngModel)]="attendee.selected"
        [disabled]="attendee.disableCheckbox"></mat-checkbox>
      <i class="icon-VIP" [ngClass]="{'VIP-selected':attendee?.IsVip}"></i>
    </div>
  </td>
  <td>
    <div class="user-card__content-view">
      <h3 class="user-card__name text-ellipsis">
       {{attendee?.FirstName}} {{attendee?.LastName || ''}} 
       <span *ngIf="attendee.isWristBandIssued" [matTooltip]="(attendee.IsActiveWristBand ? 'Active' : 'InActive') | translate" 
       [ngClass]="attendee.IsActiveWristBand ? 'icon-wristband active' : 'icon-wristband_off inactive'"
       [autoClose]="'outside'" container="body" placement="auto"
       [ngbPopover]="wristbandContainer" triggers="manual" #wristbandPopOver="ngbPopover"
       (click)="wristbandPopOver.open();setWristBand(attendee)">
     </span>
      </h3>
      <p class="user-card__mobile text-ellipsis">
        {{attendee?.PhoneNumber | phoneNumberFormatter: attendee?.CountryId}}</p>
        <p *ngIf="attendee?.TrackMembershipNumber && attendee?.TrackMembershipNumber != ''" class="user-card__member-info mb-0 seat-body-text"
        [autoClose]="'outside'" container="body" placement="auto"
       [ngbPopover]="memberInfoPopover"  #memberInfo="ngbPopover" triggers="manual" popoverClass="memberinfo-popover"
       (click)="fetchMemberDetails(attendee?.TrackMembershipNumber, $event, memberInfo)">
       {{attendee?.IsMemberActive ? ('memberText' | translate) : (('Inactive' | translate) +' '+ ('memberText'| translate)) }}</p>
    </div>
  </td>

  <td *ngIf="showStayInfo">
    <div class="stay-info">
      <!-- Room Number --> -
    </div>
  </td>
  <td>
    <div class="reference-number">
      <!-- {{attendee?.ConfirmationCode}} --> -
    </div>
  </td>

  <td>
    <div class="user-card__covers">
      -
    </div>

  </td>
  <td *ngIf="showTimeColumn">
    <div class="user-card__timing">
      -
    </div>
  </td>
  <td *ngIf="showTimeColumn">
    <div class="user-card__timing">
      -
    </div>
  </td>
  <td>
    <div class="user-card__payment">
      <ng-container *ngIf="(attendee | partyPaymentState : null : party) as paymentState">
        <span *ngIf="paymentState!=='NA'" class="user-card__payment--label payment-{{paymentState}}" container="body"
          placement="auto" [autoClose]="'outside'" [ngbPopover]="financialStatusPopover" 
          (click)="financialDetails(party , attendee)">
          {{ paymentState | translate }}
        </span>
        <span *ngIf="paymentState=='NA'">
          {{'NA' | translate}}
        </span>
      </ng-container>
    </div>
  </td>
  <td>
    <div class="user-card__status-col">
      <div *ngIf="attendee.Type == PartyType.StandBy && attendee.State == PartyState.Pending ;else reservationStatus" 
          class="user-card__status">
          <span class="user-card__status user-card__status--7">
            {{'Standby'| translate}}</span>
      </div>
      <ng-template #reservationStatus>
        <span class="user-card__status user-card__status--{{attendee.State}}">
          {{'Status_'+PartyState[attendee.State]| translate}}</span>
        </ng-template>
    </div>
  </td>
  <td class="table-action">
    <ng-template [ngTemplateOutlet]="reservationActions"
      [ngTemplateOutletContext]="{ attendee: attendee , party:party}">
    </ng-template>
  </td>
  <td>
    <div class="user-card__cancel">
      <div class="action-icons">
        <div>
        </div>
        <div class="action-icon-list ">
          <span class="icon icon-note1" [ngClass]="{'icon-disabled' : !(attendee | notesAvailable)}"
            matTooltip="{{'notes' | translate}}" [autoClose]="'outside'" container="body" placement="auto"
            [ngbPopover]="reservationNotesPopOver" triggers="manual" #reservationNotePopOver="ngbPopover"
            (click)="isNotesAvailable(attendee) ? null : reservationNotePopOver.open();reservationNotes(attendee , false)"></span>
        </div>
        <div class="action-icon-list">
          <div class="mt_5" *ngIf="(attendee | partyGuestFieldMapping:  mappedGuestFields) as partyGuestFields"
            matTooltip="{{'CustomGuestFields'| translate}}">
            <div *ngIf="partyGuestFields?.length ; else additionalField">
              <span class="icon icon-Group-197 mr-0" [autoClose]="'outside'" container="body" placement="auto"
                triggers="manual" #customGuestFieldsPopover="ngbPopover" container="body"
                [ngbPopover]="customGuestFieldsContainer"
                (click)="!(partyGuestFields?.length) ? return : customGuestFieldsPopover.open();setCustomGuestField(attendee)">

              </span>
            </div>
            <ng-template #additionalField><span class="icon icon-Group-197 icon-disabled "></span></ng-template>
          </div>
        </div>
        <div class="action-icon-list">
          <div
            [ngClass]="{'icon-disabled' : !(attendee?.BookingContactAddonItems?.length > 0) , 'mt_5' : attendee?.BookingContactAddonItems?.length > 0 }"
            matTooltip="{{'addons'| translate}}">
            <span class="icon icon-addons mr-0" [autoClose]="'outside'" placement="auto" triggers="manual"
              #addOnForBookingPopover="ngbPopover" container="body" [ngbPopover]="addOnForBooking"
              (click)=" !(attendee?.BookingContactAddonItems?.length > 0) ? return : addOnForBookingPopover.open();updateAddOnDetails(party , attendee)">
            </span>
          </div>
        </div>
        <div class="action-icon-list w-40" *ngIf="!noActionRequired">
          <chat-info-container class="user-card__data-align-content" [party]="party" [ContactId]="attendee?.ContactId" [isValidContact] ="attendee?.EnablePaging"
            [guestPageMethod]="attendee.PreferredPageMethod"
            [isGuestPreferred]="party.NotificationPreference ? true :  false"></chat-info-container>
        </div>
        <div class="action-icon-list">
          <span class="icon-more user-card__data-align-content" mat-button
            [matMenuTriggerData]="{attendee:attendee , reservation: party}"
            [matMenuTriggerFor]="moreOptionsReservationTab"></span>
        </div>
      </div>

    </div>

  </td>

</ng-template>
<ng-template #reservationActions let-attendee="attendee" let-party="party">
  <div class="user-card__actions" *ngIf="!attendee.showStandByActions && !attendee.noChildActions" [ngClass]="{'prevent-action-one': !attendee.showOptions}">
    <button *ngIf="attendee.showCheckIn && !attendee.showCheckInWithDues" mat-button class="btn btn--checkin"
      (click)="setBookingState(attendee , PartyState.Seated , party)"
      [disabled]="noActionRequired || isSessionCancelled">
      {{attendee.CheckInText | translate}}
    </button>
    <button *ngIf="attendee.showCheckInWithDues" mat-button [matMenuTriggerFor]="menu" class="btn btn--checkin"
      [disabled]="noActionRequired || isSessionCancelled">
      {{attendee.CheckInText | translate}} <span class="icon-down-arrow" style="font-size:10px"></span></button>
    <mat-menu #menu="matMenu" [hidden]="!(attendee.checkInWithDues && attendee.State == PartyState.Pending)">
      <button mat-menu-item (click)="setBookingState(attendee , PartyState.Seated , party)">{{attendee.CheckInText |
        translate}}</button>
      <button mat-menu-item (click)="setBookingState(attendee , PartyState.Seated , party , true)">{{'checkInWithDues' |
        translate}}</button>
    </mat-menu>
    <button *ngIf="attendee.showCheckedOut" mat-button [matMenuTriggerFor]="menuForCheckOut" class="btn btn--checkin"
      [disabled]="noActionRequired"> {{attendee.CheckOutText | translate}} <span class="icon-down-arrow"
        style="font-size:10px"></span></button>
    <mat-menu #menuForCheckOut="matMenu">
      <button mat-menu-item *ngIf="!attendee?.hideUndoCheckin" (click)="setBookingState(attendee , PartyState.Pending , party)">{{attendee.UnseatText |
        translate}}</button>
      <button mat-menu-item (click)="setBookingState(attendee , PartyState.Left , party)">{{attendee.CheckOutText |
        translate}}</button>
    </mat-menu>

  </div>
  <div class="user-card__standby-actions" *ngIf="attendee.showStandByActions">
    <button mat-button class="btn btn--checkin" (click)="rejectClick(attendee)"
      [disabled]="noActionRequired || attendee.State == PartyState.Cancelled">
      {{ 'RejectText' | translate}}
    </button>
    <button *ngIf="!attendee?.isPast"
      [disabled]="attendee?.isPast || noActionRequired || isSessionCancelled ||  attendee.State == PartyState.Cancelled"
      mat-button class="btn btn--accept" (click)="confirmClick(attendee)">
      {{'AcceptText' | translate}}
    </button>
  </div>
</ng-template>
<ng-template #reservationNotesPopOver>
  <div>
    <div class="parties-grid__scrollSize">
      <div class="reservation-notes">
        
        <div *ngIf= "notesReservation?.length > 0" class="reservation-notes__header seat-popover-header">
          {{'reservationNotes' | translate}}
        </div>
        <div class="reservation-notes__content seat-popover-content">
          <div class="reservation-notes__notes-details seat-border-color"
            style="justify-content: space-between;gap:20px" *ngFor="let notes of notesReservation">
            <div>{{notes.Text}}</div>
          </div>
        </div>

        <div *ngIf= "notesGuest?.length > 0" class="reservation-notes__header seat-popover-header">
          {{'Guest Notes' | translate}}
        </div>
        <div class="reservation-notes__content seat-popover-content">
          <div class="reservation-notes__notes-details seat-border-color"
            style="justify-content: space-between;gap:20px" *ngFor="let notes of notesGuest">
            <div>{{notes.Text}}</div>
          </div>
        </div>

      </div>
    </div>
   
  </div>
</ng-template>

<!-- Add-on Popover -->
<ng-template #addOnForBooking>
  <app-add-on-popover [party]="partyWithAddon"></app-add-on-popover>
</ng-template>
<ng-template #customGuestFieldsContainer>
  <div>
    <div class="parties-grid__scrollSize">
      <div class="reservation-notes">
        <div class="reservation-notes__header seat-popover-header">
          {{'CustomGuestFields' | translate}}
        </div>
        <div class="reservation-notes__content seat-popover-content">
          <div class="reservation-notes__notes-details seat-border-color list-item"
            *ngFor="let guestField of customGuestFieldsForBooking">
            <label class="guest-field-name">
              {{guestField.CustomFieldName}}
            </label>
            <span>: {{guestField.CustomFieldValue}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #wristbandContainer>
    <div class="wristband-grid__scrollSize seat-popover-content">
        <label class="wristband-label">
          {{'WristbandId' | translate}}
        </label>
        <span class="wristband-Id">{{wristbandId}}</span>
    </div>
</ng-template>

<mat-menu #moreOptionsReservationTab="matMenu">
  <ng-template matMenuContent let-attendee="attendee" let-reservation="reservation">
    <button mat-menu-item *ngIf="attendee.showCollectCharge" (click)="chargeGuest(attendee , reservation)">{{'chargeGuest' |
      translate}}</button>
    <!-- <button mat-menu-item *ngIf="attendee.showRefund" (click)="chargeGuest(attendee , reservation , true)">{{'refundText' |
      translate}}</button> -->
    <button mat-menu-item *ngIf="attendee?.WaiverFormURL != null" (click)="showPartyWaiverForm(attendee , reservation)">
      {{'waiverForm' | translate}}</button>
    <button mat-menu-item (click)="showPartyAuditLog(attendee , reservation)"> {{'auditlog' |
      translate}}</button>
      <!-- New Trigger Email option -->
    <!-- <button mat-menu-item (click)="triggerEmail(attendee)">
      {{'triggerEmail' | translate}}
    </button> -->
    <!-- When VCart is enabled, actions are not permitted  -->
    <ng-container *ngIf="!noActionRequired">
      <button mat-menu-item *ngIf="attendee.showShopping" (click)="moveToShopScreen(attendee,reservation)">{{'shop' |
        translate}}</button>
      <button mat-menu-item *ngIf="attendee.showEditable" (click)="editParty(attendee)">{{'edit' | translate}}</button>
      <button mat-menu-item *ngIf="attendee.showCancelled" (click)="cancelParty(attendee)">{{'Cancel' |
        translate}}</button>
      <button mat-menu-item *ngIf="attendee.showResend"  (click)="sendConfirmation(reservation ? reservation : attendee , attendee.ContactId)">{{'Re-send' |
        translate}}</button>
        <button mat-menu-item *ngIf="(attendee.State) != PartyState.Cancelled && attendee.showOptions"
        (click)="performRateChanges(reservation || attendee,financialEffectAction.Reprice, attendee)">{{'RepriceText' | translate}}</button>
        <button mat-menu-item *ngIf="(attendee.showRefund || attendee.refundWithCancel) && attendee.showOptions"
        (click)=" chargeGuest(attendee , reservation , true , attendee)">{{'refundText' | translate}}</button>
        <button mat-menu-item *ngIf="attendee.showWaivedOff"
        (click)="performRateChanges(reservation || attendee,financialEffectAction.WaivedOff, attendee)">{{'Waive-Off' | translate}}</button>

    </ng-container>
    <button mat-menu-item *ngIf="attendee?.showWristband" (click)="addWristband(attendee?.BookingReferenceId, 'bookingreferenceNo')"> {{'Add WristBand' |
      translate}}</button>
  </ng-template>
</mat-menu>

<ng-template #locationFieldsContainer>
  <div>
    <div class="parties-grid__scrollSize">
      <div class="reservation-notes">
        <div class="reservation-notes__header seat-popover-header">
          {{'Asset' | translate}}
        </div>
        <div class="reservation-notes__content seat-popover-content">
          <div class="reservation-notes__notes-details seat-border-color"
            style="justify-content: space-between;gap:20px" *ngFor="let location of locationListGroup">
            <div>{{location.Name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

 <!-- Member Information Popover -->
 <ng-template #memberInfoPopover >
  <app-member-info *ngIf="memberDetails" [memberDetails]="memberDetails"></app-member-info>
</ng-template>
<ng-template #packageInformation>
  <app-package-information [packageInfo]="dashboardFunctions.currentPackageInfo"></app-package-information>
</ng-template>


<ng-template #financialStatusPopover>
  <app-financial-status-popup [partyData]="financialInput.Party" [bookingContact]="financialInput.bookingContact"></app-financial-status-popup>
</ng-template>
