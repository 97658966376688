<div class="crs__confirmation">
    {{'ReservationFrom' | translate}}
    <app-crs-reservation-card [reservation]="data?.reservationData" [showDate]="true" [dateFormat]="data.dateFormat" [primaryText]="'property'">
    </app-crs-reservation-card>
    
    {{'UpdateTo' | translate}}
    <app-crs-reservation-card [reservation]="data?.updateData" [showDate]="true" [primaryText]="'contact'" [dateFormat]="data.dateFormat" [primaryText]="'property'">        
    </app-crs-reservation-card>

    <div class="crs__confirmation__messageText" *ngIf="data?.updateConfirmationFinancialEffectText">
        <strong>{{data?.updateConfirmationFinancialEffectText}}</strong>
    </div>
</div>