import { Pipe, PipeTransform } from '@angular/core';
import { SimpleAuditLogItemDetailDTO, AuditLogItemDetailType } from '@models/SimpleAuditLogItemDTO';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'auditlogItemFilter' })

export class AuditlogItemFilterPipe implements PipeTransform {
  transform(details: SimpleAuditLogItemDetailDTO[], type: AuditLogItemDetailType) {
    switch (type) {
      case AuditLogItemDetailType.Table:
        return details.filter(d => d.Type == AuditLogItemDetailType.Table);
      case AuditLogItemDetailType.Personal:
        return details.filter(d => d.Type == AuditLogItemDetailType.Personal);
      case AuditLogItemDetailType.Other:
        return details.filter(d => d.Type == AuditLogItemDetailType.Other);
      case AuditLogItemDetailType.Session:
        return details.filter(d => d.Type == AuditLogItemDetailType.Session);
      case AuditLogItemDetailType.Payment:
        return details.filter(d => d.Type == AuditLogItemDetailType.Payment);
      case AuditLogItemDetailType.LogisticPreference:
        return details.filter(d => d.Type == AuditLogItemDetailType.LogisticPreference);
    }
  }
}

@Pipe({ name: 'sessionMultiValuePipe' })
export class SessionMultiValuePipe implements PipeTransform {
  transform(value: string, name: string, filterArray, formatArray?): string {
    if (filterArray.includes(name)) {
      if (formatArray?.length && formatArray?.includes(name)) {
        let formattedString = '';
        let stringArray = value?.split('-');
        stringArray?.forEach(x => {
          formattedString = formattedString + '\n' + x.trim();
        })
        value = formattedString;
      }
      if (value?.includes('\u001f')) {
        return value.split('\u001f').join(`\n`);
      } else {
        return value;
      }
    }
    else {
      return value;
    }
  }
}

@Pipe({ name: 'fromatStringValuePipe' })
export class FormatStringPipe implements PipeTransform {
  constructor(private ts:TranslateService){}
  transform(value: string): string {
    let stringArray = value?.split('-');
    if (stringArray.length > 0) {
      return stringArray.map(string => this.ts.instant(string.trim())).join(' - ');
    }
    else{
     return  this.ts.instant(value)
    }
    

   
  }
}