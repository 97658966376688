<form [formGroup]="transitionDetails" class="h-100 transition-details shop-payment-summary shop-payment-summary-wrapper" #paymentmethod>
  <div class="payment-form-inner">
    <div [class.disabled]="(GuestProfileRequired && payeeInfo.id == 0) || DisableIfFolioNotSelected"
      class="payment-inner">
      <!-- TOP NON SCROLLABLE SECTION WHICH NEED TO BE SHOWN ALWAYS -->
      <div id="payment-top-noscroll-section">
        <ng-content select="[paymentmethod]"></ng-content>
        <div *ngIf="IsFolioDefinitionScreen && !IsResortFinanceFolioAuthFlow">
          <div class="ag_mb--4">
            <mat-checkbox [disableRipple]="true" (change)="checkboxclick($event)" formControlName="thirdparty" [attr.automationId]="'Chk_payment_receiptComment'">
              <span [attr.LiteralID]="'lbl_ThirdPartyPayment'">{{ShopCaptions.lbl_ThirdPartyPayment}}</span>
            </mat-checkbox>
          </div>
          <div class="ag_mb--4">
            <mat-checkbox [disableRipple]="true" (change)="checkboxclick($event)" formControlName="donotdisclose" [attr.automationId]="'Chk_payment_donotdisclose'">
              <span
                [attr.LiteralID]="'lbl_DoNotDiscloseTransactionToGuest'">{{ShopCaptions.lbl_DoNotDiscloseTransactionToGuest}}</span>
            </mat-checkbox>
          </div>
        </div>
        <!--PAYMENT MODE SECTION-->
        <ng-container *ngIf="false">
          <section class="example-section radio-class payment-method"
            *ngIf="IsShopScreen && ((!isReturnWithoutTicket && !PropertyInfo.UseRetailInterface) || (isReturnWithoutTicket && !PropertyInfo.UseRetailInterface))">
            <label
              class="payment-radio-group-label LW12 mb-2 label-color">{{localization.captions.shop.FullOrMultiplePayment}}</label>
            <mat-radio-group formControlName="paymentMode" aria-labelledby="payment-radio-group-label" [attr.automationId]="'Rdo_payment_paymentMode'"
              class="golf__radio d-flex justify-content-between">
              <mat-radio-button [disabled]="disabledRadiobool" class="example-margin pr-2  golf--form-radio-button w-50"
                [value]="paymentMethod.id" (change)="PaymentOption(paymentMethod)"
                *ngFor="let paymentMethod of paymentMethodType; let i = index">{{paymentMethod.name}}
              </mat-radio-button>
            </mat-radio-group>
          </section>
          <section class="example-section radio-class payment-method"
            *ngIf="(IsCheckOutScreen || (IsMakePaymentScreen && !IsFolioDepositTransaction)) && remainingAmount != 0">
            <label
              class="payment-radio-group-label LW12 mb-2 label-color">{{localization.captions.shop.FullOrMultiplePayment}}</label>
            <mat-radio-group formControlName="paymentMode" aria-labelledby="payment-radio-group-label" [attr.automationId]="'Rdo_payment_paymentMode'"
              class="golf__radio d-flex justify-content-between">
              <mat-radio-button [disabled]="disabledRadiobool" class="example-margin pr-2  golf--form-radio-button w-50"
                [checked]="i === defaultSelectchecked" [value]="paymentMethod.id"
                (change)="PaymentOption(paymentMethod)" *ngFor="let paymentMethod of paymentMethodType; let i = index">
                {{paymentMethod.name}}
              </mat-radio-button>
            </mat-radio-group>
          </section>
        </ng-container>
        <div class="payment-row spa-row-payment-method" *ngIf="!IsNonPaymentScreen">
          <div class="full-payment">
            <label class="LW12 fullpayment-label label-color">{{ AmountDueMsg }}</label>
            <span class="LWB14 mt-2 value-color">{{ FormatPrice(totalAmount)}}</span>
          </div>
          <ng-container *ngIf="!PropertyInfo?.UseRetailInterface">
            <div class="partial-payment">
              <label class="LW12 partialpayment-label label-color">{{ AmountPaidMsg }}</label>
              <span class="LWB14 mt-2 value-color">{{ FormatPrice(SettledAmt) }}</span>
            </div>
            <div class="partial-payment">
              <label class="LW12 partialpayment-label label-color">{{localization.captions.shop.RemainingDue}}</label>
              <span class="LWB14 mt-2 value-color">{{ FormatPrice(remainingAmount) }}</span>
            </div>
          </ng-container>
        </div>
        <div class="payment-row-folio spa-row-payment-method" *ngIf="IsCheckOutScreen || IsMakePaymentScreen">
          <div class="full-payment">
            <label class="LW12 fullpayment-label label-color">{{localization.captions.shop.AmounttobePaid}}
            </label>
            <span class="LWB14 mt-2 value-color">{{ FormatPrice(totalAmount)}}</span>
          </div>
        </div>
      </div>
      <!-- PAYMENT METHODS AND SETTLEMENTS SECTION -->
      <div id="payment-scrollable-section">
        <!-- <!- -Payment type section- -> -->
        <section class="example-section amount"
          *ngIf="(!isReturnWithoutTicket && !PropertyInfo.UseRetailInterface) || (isReturnWithoutTicket && !PropertyInfo.UseRetailInterface)">
          <div class="shop-payment-type" [class.disabled]="!ShowPaymentMethods">
            <label class="shop-payment-label LW12 label-color mb-2">{{localization.captions.shop.PaymentMethods}}
              <span *ngIf="isComponentAccessLinkingFilterApplied"
                class="label-bold">({{localization.captions.shop.ComponentLinkAppliedBanner}})</span></label>

            <div class="shop-payment-method" [class.disabled]="isAPICallInProgress">
              <div #buttonView class="show-container">
                <div class="btn-div d-flex flex-wrap w-100">
                  <!-- // Activities - retail integration changes
                     type.isReserveZeroTender && remainingAmount != 0 added
                  -->
                  <div *ngFor="let type of paymentMethods|slice: 0:4"
                    [class.disabled]="type.isDisabled || (!IsFolioDefinitionScreen && remainingAmount == 0 && !type.allowZeroPosting) || (type.isReserveZeroTender && remainingAmount != 0)"
                    class="payment-icon" (click)="selectpaymentMethod(type)">
                    <i class="payment-type icon-{{type.type}}" aria-hidden="true" attr.automationId='Icn_payment_{{type.type}}'
                      [ngClass]="{'selected-payment': checkCurrentPaymentSelection(type)}"></i>
                    <label class="LW14 value-color"
                      [ngClass]="{'font-weight-bold': checkCurrentPaymentSelection(type)}">{{type.paymentMethod}}</label>
                  </div>
                </div>
                <div class="btn-div d-flex flex-wrap w-100"
                  [ngClass]="showAllPaymentMethods ? 'show-container_pc' : 'hide-container'">
                  <!-- // Activities - retail integration changes
                     type.isReserveZeroTender && remainingAmount != 0 added
                  -->
                  <div *ngFor="let type of paymentMethods|slice: 4:paymentMethods.length"
                    [class.disabled]="type.isDisabled || (!IsFolioDefinitionScreen && remainingAmount == 0 && !type.allowZeroPosting) || (type.isReserveZeroTender && remainingAmount != 0)"
                    class="payment-icon" (click)="selectpaymentMethod(type)">
                    <i class="payment-type icon-{{type.type}}" aria-hidden="true" attr.automationId='Icn_payment_{{type.type}}'
                      [ngClass]="{'selected-payment': checkCurrentPaymentSelection(type)}"></i>
                    <label class="LW14 value-color"
                      [ngClass]="{'font-weight-bold': checkCurrentPaymentSelection(type)}">{{type.paymentMethod}}</label>
                  </div>
                </div>
              </div>
              <!-- CREDIT CARD ISSUER TYPE SELECTION RADIO GROUP SECTION -->
              <div *ngIf="ShowSurchargeSelection">
                <mat-radio-group formControlName="creditcardType" [attr.automationId]="'Rdo_payment_creditcardType'">
                  <div class="issuer-type-select-container">
                    <div *ngFor="let cardIssuer of cardIssuerTypes" (click)="selectcardIssuer(cardIssuer)" class="issuerType"
                      [class.selected]="cardIssuer.checked">
                      <div class="issuer-icon-group">
                        <mat-radio-button [checked]="cardIssuer.checked" [value]="cardIssuer.type"></mat-radio-button>
                        <app-retail-card-type [type]="cardIssuer?.type"></app-retail-card-type>
                        <div>{{cardIssuer?.name}}</div>
                      </div>
                      <div *ngIf="cardIssuer?.isPercentage">{{cardIssuer?.surchargeValue}}%</div>
                      <div *ngIf="!cardIssuer?.isPercentage">{{cardIssuer?.surchargeValue | Currency}}</div>
                    </div>
                  </div>
                </mat-radio-group>
              </div>
            </div>


            <div class="toggle-section" [class.disabled]="!(paymentMethods.length > 4)">
              <button class="toggle-arrow body-bgcolor" (click)="togglePaymentGrid()" [attr.automationId]="'Rdo_payment_togglePaymentGrid'">
                <i aria-hidden="true" class="pointercursor icon-filled_up_arrow whitecolor"
                  [ngClass]="!showAllPaymentMethods ? 'default-arrow-position' : ''"></i>
              </button>
            </div>
          </div>
          <div class="shop-amount-tendered set-widt" [class.shop-surcharge-wrapper]="IsFolioPaymentMethod"
          [class.surcharge-applied]="isSurchargeApplied && !IsFolioPaymentMethod"
            *ngIf="multiplePayment && selectedpayment?.paymentTypeId != PayMethodConst.RainCheck && !IsResortFinanceFolioAuthFlow && !IsFolioDefinitionScreen">
            <mat-form-field [ngClass]="{ 'amountTendered-witherror': transitionDetails['controls'].amounttendered.hasError('max') }">
              <input matInput placeholder="{{lblamountTentered}}" #amounttenderedField [attr.automationId]="'Txt_payment_amounttendered'"
                cAllowNegative="{{IsRefundFlow ? 'true':'false'}}" RetailCurrencyFormatter
                formControlName="amounttendered" required (keyup)="tenderedAmount()" (blur)="onAmtTenderBlur()"> 
              <mat-error *ngIf="transitionDetails['controls'].amounttendered.hasError('max')">
                {{oCaptions.shop.AmountTenderValidationMsg}}</mat-error>
              <mat-error *ngIf="transitionDetails['controls'].amounttendered.hasError('required')">
                {{MissingAmountTenderMsg}}</mat-error>
            </mat-form-field>
            <div class="surcharge-applied-indicator" *ngIf="isSurchargeApplied && !IsPMSFolioDefinitionFlow">
              <span #SCpopOverTarget *ngIf="!IsFolioPaymentMethod">
                <i aria-hidden="true" class="icon-information i-info surcharge-info" id="surcharge-info" [attr.automationId]="'Icn_payment_surcharge'"
                  [popover]="surchargePopover" [popoverOnHover]="false" (onShown)="showSurchargePopover($event)"></i>
              </span>
              {{ShopCaptions.lbl_Surcharge}} : {{surchargeAmount | Currency}}
            </div>
            <div class="surcharge-applied-indicator" *ngIf="IsShopScreen && TaxExemptByPaymentApplied > 0 && !isTaxExempted">
              <span #SCpopOverTarget *ngIf="!IsFolioPaymentMethod">
                <i aria-hidden="true" class="icon-information i-info surcharge-info" id="surcharge-info" [attr.automationId]="'Icn_payment_TaxExemptByPaymentApplied'"
                  [popoverOnHover]="false"></i>
              </span>
              {{taxExemptCaption}} : {{TaxExemptByPaymentApplied | Currency}}
            </div>
            <div class="surcharge-applied-indicator" *ngIf="IsShopScreen && ExemptedDiscount > 0">
              <span #SCpopOverTarget *ngIf="!IsFolioPaymentMethod">
                <i aria-hidden="true" class="icon-information i-info surcharge-info" id="surcharge-info" [attr.automationId]="'Icn_payment_ExemptedDiscount'"
                  [popoverOnHover]="false"></i>
              </span>
              {{ShopCaptions.lbl_DiscountExemptByPaymentMethod}} : {{ExemptedDiscount | Currency}}
            </div>
          </div>

          <div class="shop-amount-tendered set-widt" id="payComment" *ngIf="isEnablePaymentComment && !IsResortFinanceFolioAuthFlow && !IsFolioDefinitionScreen">
            <mat-form-field [floatLabel]="floatLabel" class="txt-area-height">
              <textarea [required]="isPaymentCommentRequired" #paymentCommentField matInput
                placeholder="{{oCaptions.shop.PaymentComments}}" maxlength="30" [attr.automationId]="'Ta_payment_paymentComment'"
                formControlName="paymentComment"></textarea>
            </mat-form-field>
          </div>
        </section>
        <ng-content select="[returnCashCreditLimit]"></ng-content>
        <!-- <!- --credit card section- -> -->
        <section class="select-device" *ngIf="(!PropertyInfo.UseRetailInterface &&
                      (SelectedPaymentMethodEquals(PayMethodConst.CreditCard) ||
                      SelectedPaymentMethodEquals(PayMethodConst.IDTECH)) ||
                      SelectedPaymentMethodEquals(PayMethodConst.CardOnFile) || SelectedPaymentMethodEquals(PayMethodConst.NonIntegratedCreditCard)) || isResortFinanceCreditCartAuthFlow">
          <div class="device-type" *ngIf="((SelectedPaymentMethodEquals(PayMethodConst.CreditCard) ||
                        SelectedPaymentMethodEquals(PayMethodConst.IDTECH) || SelectedPaymentMethodEquals(PayMethodConst.NonIntegratedCreditCard)) && !cardDetailsBool) || isResortFinanceCreditCartAuthFlow">

            <!-- DEVICE SELECTION SECTION -->
            <ng-container *ngIf="!IsFolioDefinitionScreen && !IsCheckOutScreen && !IsMakePaymentScreen && !SelectedPaymentMethodEquals(PayMethodConst.NonIntegratedCreditCard) ">
              <!-- TERMINAL SELECTION SECTION REQUIRED ONLY INCASE OF FOLIO BASED CARD METHODS-->
              <mat-form-field class="terminal-select" *ngIf="IsFolioPaymentMethod" [floatLabel]="floatLabel">
                <mat-select placeholder="{{oCaptions.shop.SelectTerminal}}" formControlName="selectedTerminal" 
                required [attr.automationId]="'Dd_payment_selectedTerminal'">
                  <mat-option *ngFor="let terminal of terminalList" [value]="terminal.terminalId"
                    (click)="changeTerminal(terminal)">
                    {{ terminal.terminalId }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field [floatLabel]="floatLabel">
                <mat-select placeholder="{{oCaptions.shop.SelectDevice}}" formControlName="selectedDevice" 
                required [attr.automationId]="'Dd_payment_selecetedDevice'">
                  <mat-option *ngFor="let device of deviceList" [value]="device.name" (click)="selectDevice(device)">
                    {{ device.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- PREFERRED CARD ENTRY MODE BANNER FOR ADYEN GATEWAY -->
              <div *ngIf="showPreferredCardEntryBanner">
                <div class="preferred-selection-link">{{preferredInputSelectedBanner}}
                  <span [class.link]="IsProceedBtnEnabled" [class.disabled]="!IsProceedBtnEnabled" [attr.automationId]="'Lbl_payment_AdyenGateway'"
                    (click)="_pblh.proceedForAdyenGatewayPayment(true)">
                    {{oCaptions.shop.clickHere}}</span>
                  <span> {{oCaptions.shop.ToChange}}</span>
                </div>
              </div>
            </ng-container>
            <!-- TERMINAL BASED DEVICE SELECTION FOR FOLIO DEF SCREEN -->
            <ng-container *ngIf="IsFolioDefinitionScreen">
              <div class="folio-process-container" *ngIf="!showNonIntegratedCreditCard">
                <mat-form-field *ngIf ="!this.IsResortFinanceFolioAuthFlow && !this.IsResortFinanceMakePaymentFlow" class="terminal-select right-spacing" [floatLabel]="floatLabel">
                  <mat-select placeholder="{{oCaptions.shop.SelectTerminal}}" formControlName="selectedTerminal"
                    required [attr.automationId]="'Dd_payment_selectedTerminal'">
                    <mat-option *ngFor="let terminal of terminalList" [value]="terminal.terminalId"
                      (click)="changeTerminal(terminal)">
                      {{ terminal.terminalId }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <app-button [buttontype]="moreoptionButton" [actions]="actionList" class="ag_mt--1"
                  (valueChange)='onmoreAction($event, type)'>
                </app-button>
              </div>
              <div class="folio-split-capture-card-div">
                <app-retail-capture-card [disableCardCapture]="disableCardCapture" [GatewayConfiguration]="GatewayConfiguration"
                  [IsResortFinanceFolioAuthFlow]="IsResortFinanceFolioAuthFlow"
                  [PaymentReferenceID]="PaymentReferenceIDMap.get(selectedpayment.id)" [authInput]="AdditionalInputs"
                  [folioSettings]="folioSettings" [CEDS]="CEDS_ByTerminal" [displayHeader]="false"
                  [IsExpireDateVisible]="IsExpireDateVisible" [showCardRemovableIcon]="!IsFolioDefinitionScreen"
                  [showNonIntegratedCreditCard]="showNonIntegratedCreditCard" [showIframeDetails]="showNonIntegratedCreditCard"
                  [isCardRemovable]="!IsFolioDefinitionScreen" [showDeviceCapture]="!showNonIntegratedCreditCard" [outletId]="terminalOutlet"
                  (NewPaymentReferenceID)="SaveReferenceId($event)" (AuthTransactionID)="SaveAuthTransactionId($event)"
                  (removeDetails)="removeCardReference()" (CardInfo)="SaveCardInfo($event)">
                </app-retail-capture-card>
              </div>
              <div *ngIf="!SelectedPaymentMethodEquals(PayMethodConst.NonIntegratedCreditCard)">
                <div>
                 <app-ag-currency  [inputs]="creditLimitInput"></app-ag-currency>
                </div>
                <div>
                <app-ag-currency (input)="authAmountChange($event)"  [inputs]="authorizationAmtInput"></app-ag-currency>
                </div>
                <div>
                <app-ag-currency  [inputs]="newcreditLimitInput"></app-ag-currency>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- TERMINAL BASED DEVICE SELECTION FOR FOLIO CHECKOUT SCREEN  ***** NEED TO REMOVE THIS SECTION IN FUTURE *****-->
          <ng-container *ngIf="IsCheckOutScreen || IsMakePaymentScreen">
            <div *ngIf="isDeviceTransaction()">
              <mat-form-field *ngIf="!this.IsResortFinanceFolioAuthFlow && !this.IsResortFinanceMakePaymentFlow" class="terminal-select w-100" [floatLabel]="floatLabel">
                <mat-select placeholder="{{oCaptions.shop.SelectTerminal}}" formControlName="selectedTerminal" 
                required  [attr.automationId]="'Dd_payment_selectedTerminal'">
                  <mat-option *ngFor="let terminal of terminalList" [value]="terminal.terminalId"
                    (click)="changeTerminal(terminal)">
                    {{ terminal.terminalId }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="w-100" [floatLabel]="floatLabel">
                <mat-select placeholder="{{oCaptions.shop.SelectDevice}}" formControlName="selectedDevice" 
                required [attr.automationId]="'Dd_payment_selectedDevice'">
                  <mat-option *ngFor="let device of deviceList" [value]="device.name" (click)="selectDevice(device)">
                    {{ device.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="showPreferredCardEntryBanner">
                <div class="preferred-selection-link">{{preferredInputSelectedBanner}}
                  <span [class.link]="IsProceedBtnEnabled" [class.disabled]="!IsProceedBtnEnabled"
                    (click)="_pblh.proceedForAdyenGatewayPayment(true)" [attr.automationId]="'Lbl_payment_adyenGateway'">
                    {{oCaptions.shop.clickHere}}</span>
                  <span> {{oCaptions.shop.ToChange}}</span>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="zip-code" *ngIf="GatewayConfiguration?.isAVSActive">
            <mat-form-field class="zip-code-mat-field" [floatLabel]="floatLabel">
              <input matInput placeholder="{{oCaptions.shop.Zipcode}}" (change)="checkZipCodeValidity()"
                formControlName="zipcode" [maxlength]="10" required  [attr.automationId]="'Txt_payment_zipcode'">
              <mat-error>{{oCaptions.shop.ZipcodeRequired}}</mat-error>
            </mat-form-field>
          </div>
          <div class="card-details" *ngIf="cardDetailsBool">
            <div class="LW12">{{oCaptions.shop.SelectedDevice}}</div>
            <label class="device-name blckclr">{{deviceName}}</label>
            <mat-form-field class="d-block" [floatLabel]="floatLabel">
              <input matInput placeholder="{{oCaptions.shop.CardType}}" formControlName="cardtype" class="blckclr"  [attr.automationId]="'Txt_payment_cardType'">
            </mat-form-field>
            <mat-form-field class="d-block" [floatLabel]="floatLabel">
              <input matInput placeholder="{{oCaptions.shop.CardNumber}}" formControlName="cardnumber" class="blckclr"  [attr.automationId]="'Txt_payment_cardnumber'">
            </mat-form-field>
            <mat-form-field class="d-block" [floatLabel]="floatLabel">
              <input matInput placeholder="{{oCaptions.shop.TransactionAmount}}" formControlName="transactionnumber"  [attr.automationId]="'Txt_payment_transactionnumber'"
                class="blckclr">
            </mat-form-field>
          </div>
        </section>
        <!-- <!- -Room charge section- -> -->
        <section class="room-charge" *ngIf="(IsRoomOrGroupCharge || IsHotelCompPayment) && !PropertyInfo.UseRetailInterface">
          <div class="assign-rrom">
            <mat-radio-group formControlName="activityRoomSelection" class="golf__radio" [attr.automationId]="'Rdo_payment_activityRoomSelection'">
              <div class="room-details mb-2" *ngFor="let rooms of roomDetailsArray">
                <div class="room-row LW14 radio-class">
                  <label class="blckclr">{{oCaptions.shop.RoomChargeNumber}} # {{rooms.roomNo}}</label>
                  <mat-radio-button [value]="rooms.roomNo" (change)="SelectRoomFromActivityResult(rooms)">
                  </mat-radio-button>
                </div>
                <div class="room-price LW20 blckclr">
                </div>
              </div>
            </mat-radio-group>
          </div>
          <app-guest-room-lookup (GuestRoomSelected)="SelectGuestRoom($event)" 
            [payeeInfo]="payeeInfo"
            [profitCenter]="profitCenter" 
            [paymentMethodId]="roomChargePostingPayMethodId"
            [selectRoomFromResult]="selectRoomFromResult"
            [OriginalTenderId]="selectedPaymentMethod"
            [SelectedPaymentMethod]="selectedpayment"
            [AuthAmount]="AmountEntered"
            [selectedPaymentTypeId]="selectedpayment?.paymentTypeId" 
            [GuestRoomNo]="SelectedRoomNo" 
            [isDefaultLookUpByName]="isDefaultRoomLookUpByName"            
            (IsCheckZoomEnabled)="SetCheckZoomFlag($event)"
            [isResortFinanceMakePaymentFlow]="IsResortFinanceMakePaymentFlow">
          </app-guest-room-lookup>
        </section>
        <!-- <!- ---Settlement History- -> -->

        <section class="cms-payment" *ngIf="IsCompOrOfferRedemption && !PropertyInfo.UseRetailInterface">
          <form [formGroup]="CMSForm" autocomplete="off">
            <div class="rain-check-search">
              <mat-form-field class="rc-searchbox" [floatLabel]="floatLabel" [ngStyle]="{'width.ch': width}" [floatLabel]="floatLabel">
                <input type="text" [placeholder]="searchPlaceholder" #cmsSearchField matInput
                  formControlName="cmsSearch" maxlength="25" [attr.automationId]="'Txt_payment_cmsSearch'"
                  (keydown.enter)="searchCMSPlayer(); $event.preventDefault()" (input)="resetPlayerPin()"
                  autocomplete="off">
              </mat-form-field>
              <div class="icon-section">
                <i aria-hidden="true" class="icon-search icon-Search"  [attr.automationId]="'Icn_payment_search'" (click)="searchCMSPlayer()"></i>
              </div>
            </div>
            <div *ngIf="IsCMSRequirePin">
              <mat-form-field class="cms-pin-field" [floatLabel]="floatLabel">
                <input [type]="hidePassword ? 'text' : 'password'" numMaxlength minlength="4"  [attr.automationId]="'Txt_payment_cmsPin'"
                  [placeholder]="ShopCaptions.CMS.CmsPin" matInput formControlName="cmsPin" maxlength="10"
                  (keydown.enter)="focusCMSSearch(); $event.preventDefault()" autocomplete="off">
                <!-- <i matSuffix class="IC18" [ngClass]="hidePassword ? 'icon-password-show' : 'icon-password-hide '"
                  (click)="hidePassword=!hidePassword"></i> -->
                <mat-error>
                  {{ShopCaptions.CMS.MissingCMSPinError}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="cms-search-result-cont" *ngIf="selectedCMSPlayer.PatronId != ''">
              <div class="d-flex client-info-row">
                <div class="info-lbl">{{ShopCaptions.CMS.GuestName}}:</div>
                <div>{{selectedCMSPlayer.PlayerName}}</div>
              </div>
              <div class="d-flex client-info-row">
                <div class="info-lbl">{{ShopCaptions.CMS.PatronId}}:</div>
                <div>{{selectedCMSPlayer.PatronId}}</div>
              </div>
              <div
                *ngIf="SelectedPaymentMethodEquals(PayMethodConst.CompRedemption) && cmsPlayerHandles.length > 0">
                <div class="balance-hdr">{{ShopCaptions.CMS.Balance}}</div>
                <div>
                  <mat-radio-group formControlName="cmsPaymentSelection" [attr.automationId]="'Rdo_payment_cmsPaymentSelection'">
                    <div class="payment-type-row d-flex" *ngFor="let playerInfo of cmsPlayerHandles">
                      <mat-radio-button (click)="selectCMSPaymentType(playerInfo,$event)" [value]="playerInfo.type">
                      </mat-radio-button>
                      <div class="d-flex cms-player-type">
                        <div class="flx-grw">{{_sbs.GetCMSHandleName(playerInfo.type)}}</div>
                        <div>{{playerInfo.balance | Currency}}</div>
                      </div>
                    </div>
                  </mat-radio-group>
                </div>
              </div>
              <div *ngIf="SelectedPaymentMethodEquals(PayMethodConst.OfferRedemption)">
                <ng-container *ngIf="Vouchers.length > 0">
                  <div class="balance-hdr">{{ShopCaptions.CMS.OffersAndVoucher}}</div>
                  <div>
                    <mat-radio-group formControlName="voucherSelection"  [attr.automationId]="'Rdo_payment_voucherSelection'">
                      <div class="voucher-cont" *ngFor="let voucher of Vouchers">
                        <mat-radio-button (click)="selectVoucher(voucher)" [value]="voucher.voucherId">
                        </mat-radio-button>
                        <div class="d-flex voucher-details">
                          <div class="flx-grw">
                            <div>{{voucher.name}}</div>
                            <div>({{voucher.voucherId}})</div>
                          </div>
                          <div>{{voucher.voucherAmount | Currency}}</div>
                        </div>
                      </div>
                    </mat-radio-group>
                  </div>
                </ng-container>
                <ng-container *ngIf="Vouchers.length == 0">
                  <div class="no-offer-msg">{{ShopCaptions.CMS.NoOffersAvailableMsg}}</div>
                </ng-container>
              </div>
            </div>
          </form>
        </section>

        <section class="gift-card-selection-section" *ngIf="isGiftCardSelected && remainingAmount != 0">
          <app-gift-card-payment-section [isRefund]="IsRefundFlow" [tender]="selectedpayment"
            (selectGiftCard)="SelectGiftCard($event)">
          </app-gift-card-payment-section>
        </section>

        <section class="pay-wallet-section">
            <div class="shop-amount-tendered" *ngIf="SelectedPaymentMethodEquals(PayMethodConst.Wallet)">
              <mat-form-field [floatLabel]="floatLabel">
                <input matInput #walletInputField minlength="4" maxlength="256" [attr.automationId]="'Txt_payment_walletInput'"
                    [placeholder]="ShopCaptions.WalletNumber" formControlName="walletInput"
                    (keydown.enter)="focusWalletInput(); $event.preventDefault()" autocomplete="off" required>
                  <mat-error>{{oCaptions.shop.MissingWalletCardNumber}}</mat-error>
              </mat-form-field>
            </div>
        </section>

        <section class="example-section amount mb-3 mt-3" *ngIf="SelectedPaymentMethodEquals(PayMethodConst.ARAcctPost) && (isAllowARPayments || (this.arAccountName != null && this.arAccountName != ''))">
          <div *ngIf="isUseNewArCustomerSearch;else isUseOldArCustomerSearch">
            <app-simple-search (click)="searchValue($event)" [readonly]="true" [placeholder]="arSearchPlaceHolder" [value]="selectedText"  [attr.automationId]="'Srch_payment_arSearch'"
             (searchChange)="searchValueChangeEmit($event)" (searchIconClick)="searchValue($event)" [disabled]="!isAllowARPayments">
            </app-simple-search>
          </div>
        </section>

        <ng-template #isUseOldArCustomerSearch>
          <app-account-lookup [lookupInput]="accountLookupComponentInput" (AcctSelected)="SelectAccount($event)">
          </app-account-lookup>
        </ng-template>

        <section *ngIf="SelectedPaymentMethodEquals(PayMethodConst.CompSlipRedemption)">
          <app-comp-slip-lookup [patronId]="payeeInfo?.patronId" (VoucherSelected)="selectCompSlip($event)">
          </app-comp-slip-lookup>
        </section>

        <section *ngIf="SelectedPaymentMethodEquals(PayMethodConst.NonIntegratedCreditCard)">
          <div class="button-row text-center" *ngIf="!IsFolioDefinitionScreen">
            <app-retail-capture-card
            [PaymentReferenceID]="nonIntegratedPaytransId"
            [showDeviceCapture]="false"
            [showIframeDetails]="true"
            [isShowPMAgentValidationMessage]="false"
            (NewPaymentReferenceID)="SavePaymentReferenceId($event)"
            (removeDetails)="removeCardReference()">
           </app-retail-capture-card>
          </div>
          
        </section>

        <section *ngIf="ShowSettlemtHist && !PropertyInfo.UseRetailInterface"
          class="settlment-hist spa-row-payment-method">
          <label class="LW14">{{oCaptions.shop.SettlementHistory}}</label>
          <div *ngFor="let settlement of SettlementHistory" class="LW14">
            <ng-container *ngIf="!settlement.isReversed">
              <div class="settlement-row">
                <div *ngIf="settlement.paymentReferenceComment" class="cash-label">{{settlement.paymentMethod}} |
                  {{settlement.paymentReferenceComment}}</div>
                <!--<div *ngIf="!settlement.paymentReferenceComment" class="cash-label">{{settlement.paymentMethod}}</div> -->
                <div *ngIf="!settlement.paymentReferenceComment && ((settlement?.paymentMethodId != PayMethodConst.PostToFolio && settlement?.parentTenderId != PayMethodConst.PostToFolio) || this._ss?.settleOpenTransaction)" class="cash-label">{{settlement.paymentMethod}}</div>
                <div *ngIf="!settlement.paymentReferenceComment && (settlement?.paymentMethodId == PayMethodConst.PostToFolio || settlement?.parentTenderId == PayMethodConst.PostToFolio) && IsUserHasFolioAccess && !this._ss?.settleOpenTransaction" class="cash-label"><a class="ag_link ag_cursor--pointer underline" (click)="viewFolioInNewTab(settlement)">{{settlement.paymentMethod}}</a></div>
                <div>{{settlement.amount | Currency}}</div>
                <div class="icon-Delete cursor" *ngIf="AllowPaymentReversal" (click)="ReversePayment(settlement)"></div>
              </div>
              <div
                *ngIf="settlement.paymentMethodId == PayMethodConst.CreditCard || settlement.paymentMethodId == PayMethodConst.CardOnFile || settlement.paymentMethodId == PayMethodConst.IDTECH"
                class="card-details-container">
                <mat-form-field class="d-block" [floatLabel]="floatLabel">
                  <input matInput placeholder="{{localization.captions.shop.CardType}}" [value]="settlement.issuerType"
                    [disabled]="true" class="blckclr"  [attr.automationId]="'Txt_payment_cardType'">
                </mat-form-field>
                <mat-form-field class="d-block" [floatLabel]="floatLabel">
                  <input matInput placeholder="{{localization.captions.shop.CardNumber}}"
                    [value]="settlement.cardNumber" [disabled]="true" class="blckclr" [attr.automationId]="'Txt_payment_cardNumber'">
                </mat-form-field>
              </div>

              <div *ngIf="utils.GetOriginalTenderId(settlement.paymentMethodId, settlement?.paymentMethodInfo?.parentTypeId??0) == PayMethodConst.Cash && settlement.cashPaymenHandle">
                <div class="settlement-row">
                  <div class="cash-label cashPaymentDue">{{localization.captions.shop.lbl_payment_received}}</div>
                  <div>{{settlement.cashPaymenHandle.amtReceived | Currency}}</div>
                </div>
                <div class="settlement-row">
                  <div class="cash-label cashPaymentDue"><b>{{localization.captions.shop.remainingChange}}</b></div>
                  <div><b>{{settlement.cashPaymenHandle.amtBalanceDue | Currency}}</b></div>
                </div>
              </div>
            </ng-container>
          </div>
        </section>

        <section class="rain-check-payment"
          *ngIf="showRainCheckSectionForIG || (SelectedPaymentMethodEquals(PayMethodConst.RainCheck) && !PropertyInfo.UseRetailInterface && remainingAmount != 0)">
          <form [formGroup]="RainCheckForm">
            <div class="d-flex">
              <div class="rain-check-section">
                <app-date-picker [inputs]="dateInput" (inputDateChange) = "IssuedDateInputChange($event)" (datePickerChange)="dateChanged($event)"></app-date-picker>
              </div>
              <div class="rain-check-search">
                <mat-form-field class="rc-searchbox" [floatLabel]="floatLabel" [ngStyle]="{'width.ch': width}">
                  <input type="text" [placeholder]="searchPlaceholder" matInput formControlName="searchText" [attr.automationId]="'Txt_payment_searchText'"
                    (input)="searchValueChange()" autocomplete="off">
                </mat-form-field>                
                <div class="icon-section" [ngClass]="searchDisable ? 'ag_section--disable' : ''">
                  <i aria-hidden="true" class="icon-search" (click)="RainCheckGrid()" [attr.automationId]="'Icn_payment_rainCheck'"></i>
                </div>
              </div>
            </div>            
            <span class = 'colourRed' *ngIf="showErrMsg">
              {{oCaptions.shop.showErrMsg}}</span>
            <div *ngIf="!IsRainCheckGridData" class="norecords-alignment p-4">
              {{localization.captions.shop.NoDataFound}}
            </div>
            <mat-radio-group *ngFor="let raincheck of rainCheckTransaction;let i = index;" class="golf__radio rain-check-transaction"  aria-labelledby="example-radio-group-label" [attr.automationId]="'Rdo_payment_rainCheckSelection'"
              [(ngModel)]="rainCheckSelection" [ngModelOptions]="{standalone: true}" (click)="SelectRainCheck(raincheck)">            
            
              <ng-container  >
                  <mat-radio-button [value]="raincheck.id" [disabled]='raincheck.isRainCheckExpired'  class="golf--form-radio-button rain-check-radio" [ngClass]="raincheck.isRainCheckExpired ? 'ag_section--disable' : ''">
                  </mat-radio-button>
                 <div class="rain-check-div" [ngClass]="raincheck.isRainCheckExpired ? 'ag_section--disable' : ''">
                  <div class="rain-check-details-section">
                    <div class="rain-check-details">
                      <div [ngClass]="{'expired-item': raincheck.isRainCheckExpired}">
                        <div class="LWB16 player-name" [matTooltip]="raincheck.playerName">{{raincheck.playerName}}</div>
                        <div *ngIf="raincheck.isRainCheckExpired" class="expired-tag"><span>{{this.localization.captions.lbl_expired}}</span></div>
                      </div>
                      <div>
                        <span class=" rain-details-data">{{raincheck.courseName}}</span>
                        <span class="pl-2 rain-details-data">{{raincheck.number}}</span>
                      </div>
                        <div class="rain-details-data ">
                          <span><b>{{this.localization.captions.rainCheckIssuedDate}}: </b></span>{{raincheck.issuedDateDisplay}}
                        </div>
                        <div class="rain-details-data" *ngIf= "expiryDays > 0" >
                          <span><b>{{this.localization.captions.rainCheckExpiryDate}}: </b></span><span class="expired-color">{{raincheck.expiryDate}}</span>
                        </div>
                    </div>
                    <div class="rain-check-payment-details">
                      <div class="rain-check-payment-details-inner">
                        <div class="LWB16">{{raincheck.rainCheckValue | Currency}}</div>
                        <div class="LW12 mt-1">
                          {{raincheck.greenFee | Currency}} & {{raincheck.cartFee | Currency}}
                          <span #popOverTarget>
                            <i aria-hidden="true" class="icon-information i-info i-RC" [popover]="rainCheckPopover"
                              (click)="setTaxValues(raincheck,$event)" [popoverOnHover]="false"
                              (onShown)="onShown($event,i)"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </mat-radio-group>
          </form>
        </section>

        <section class="ml-3 mr-3 pl-2 pt-2 post-to-folio" *ngIf="folioSection">
          <mat-radio-group class="" [(ngModel)]="postTofolio" [attr.automationId]="'Rdo_payment_postTofolio'">
              <mat-radio-button value="'folio'" class="golf--form-radio-button w-100" checked="true">
                  <div class="ag_display--flex align-items-center">
                    <label class="LW11">{{postToFolioInfo?.selectedFolio?.sourceType == addonMember ? ShopCaptions.memberName : ShopCaptions.guestName}}</label>
                    <label class="LWB12 ml-3 text-ellipsis" [matTooltip]="postToFolioInfo?.selectedGuest?.name">{{postToFolioInfo?.selectedGuest?.name}}</label>
                  </div>
                  <div class="ag_display--flex align-items-center">
                    <label class="LW11">{{ShopCaptions.lbl_folio_name}}</label>
                    <label class="LWB12 ml-3 text-ellipsis" [matTooltip]="postfolioData?.name">{{postfolioData?.name}}</label>
                  </div>
                  <div class="ag_display--flex">
                    <label class="LW11">{{this.localization.captions.lbl_folio_number}}</label>
                    <a class="ag_link text-ellipsis ml-3 ag_cursor--pointer underline" [matTooltip]="postfolioData?.folioInvoiceNumber" (click)="viewFolioInNewTab(null)">{{postfolioData?.folioInvoiceNumber}}</a>
                  </div>
                  <div class="ag_display--flex align-items-center">
                    <label class="LW11">{{this.localization.captions.lbl_creditCardLimit}}</label>
                    <label class="LWB12 ml-3 text-ellipsis" [matTooltip]="postfolioData?.balance">{{postfolioData?.balance}}</label>
                  </div>
                  <div class="ag_display--flex align-items-center">
                    <label class="LW11">{{ShopCaptions.lbl_outstanding_balance}}</label>
                    <label class="LWB12 ml-3 text-ellipsis" [matTooltip]="postfolioData?.outstandingBalance">{{postfolioData?.outstandingBalance}}</label>
                  </div>
              </mat-radio-button>
          </mat-radio-group>
        </section>

        <div [ngClass]="{'prevent-action': _ams.paymentProcessing}">
          <ng-content select="[returnReason]" #returnReason> </ng-content>
        </div>

        <div class="button-row text-center" *ngIf="!IsFolioDefinitionScreen">
          <button type="button" class="pull-right LW14 button--primary-save" mat-raised-button
            *ngIf="PropertyInfo.UseRetailInterface && showRainCheckButtonIG" [attr.automationId]="'Btn_payment_rainCheckButton'"
            (click)="rainCheckIG()">{{this.localization.captions.settings.rainCheck}}</button>
          <button type="button" id="proceedBtn" class="pull-right LW14" [appDisableDoubleClick]="2000" [disabled]= "isPayButtonDisable"
            [ngClass]=" CheckButtonState() ? 'button--primary-save' : 'button--disabled'" mat-raised-button [attr.automationId]="'Btn_payment_paymentTransaction'"
            (throttleClick)="onPaymentTransactionClick()"> {{ paymentText }}
          </button>
          <div class="pull-right text-uppercase txtgray LW14 d-inline-block payment-cancelbutton" [attr.automationId]="'Lbl_payment_cancelOrder'"
            [class.disabled]="isAPICallInProgress" *ngIf="AllowCancel" (click)="CancelOrder()">
            {{localization.captions.shop.Cancel}}</div>
        </div>
      </div>
    </div>
  </div>
  <popover-content class="order-summary-popover" #rainCheckPopover placement="auto" [animation]="true" container='body'
    [closeOnClickOutside]="true">
    <section class="morelegend">
      <div class="d-flex justify-content-between fee-info mb-1" *ngFor="let cartFee of cartFees">
        <span>{{cartFee.name}}</span>
        <span>{{cartFee.price}}</span>
      </div>
    </section>
  </popover-content>
  <popover-content class="order-summary-popover" #surchargePopover placement="auto" [animation]="true" container='body'
    [closeOnClickOutside]="true">
    <section class="morelegend">
      <div class="surcharge-popover">
        <div class="info-row" *ngIf="selectedPaymentMethod == PayMethodConst.CardOnFile">
          <div>{{ShopCaptions.lbl_cardType}}</div>
          <div>: {{selectedIssuerType?.name}}</div>
        </div>
        <div class="info-row">
          <div>{{ShopCaptions.lbl_AmountTendered}}</div>
          <div>: {{AmtForSurchargeCalc| Currency}}</div>
        </div>
      </div>
    </section>
  </popover-content>
</form>
