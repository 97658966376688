import { Injectable } from "@angular/core";
import { urlConfig } from "@app/shared/constants/url-config";
import { ContactDTO } from "@app/shared/models/RestaurantDTO";
import { HttpService } from "@app/shared/services/http.service";
import { Utilities } from "@app/shared/utilities/utilities";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'any'
})
export class CRSAPIService {

    constructor(private httpService: HttpService) {

    }

    getSlots(date: string, size: number, time = '', communalTable = false): Observable<any> {
        let languageId =  sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`);
        return this.httpService.get(`${urlConfig.slotAvailability}?dates=${date}&partySize=${size}&restaurantId=&time=${time}&isCommunalTable=${communalTable}&languageId=${languageId}`, true);
        // return of(sampleResponse);
    }

    slotLock(propertyId: number, payload: SlotLockRequest): Observable<any> {
        return this.httpService.post(`${urlConfig.crsSlotLock}?restaurantId=${propertyId}`, payload);
        // return of(financialEffectSampleResponse);
    }

    getFinancialEffectsForUpdate(propertyId: number, payload: UpdatePayloadDTO): Observable<any> {
        return this.httpService.post(`${urlConfig.getFinancialEffectForUpdatedReservation}?restaurantId=${propertyId}`, payload);
        // return of(financialEffectSampleResponse);
    }

    reservationUpdate(propertyId: number, payload: UpdatePayloadDTO): Observable<any> {
        return this.httpService.post(`${urlConfig.crsUpdate}?restaurantId=${propertyId}`, payload)
    }

    registerReservationAttempt(propertyId: number, payload: RegisterReservationAttemptRequest): Observable<any> {
        return this.httpService.post(`${urlConfig.crsReservationAttempt}?restaurantId=${propertyId}`, payload)
    }

    updateReservationAttempt(payload: CRSUpdateReservationAttempt): Observable<any> {
        return this.httpService.post(`${urlConfig.crsUpdateReservationAttempt}`, payload)
    }

    checkForReservationAttemptStatus(reservationAttemptId: number): Observable<any> {
        return this.httpService.get(`${urlConfig.GetReservationAttemptState}?reservationAttemptId=${reservationAttemptId}`)
    }

    searchGuest(propertyId: number, queryParams: { [key: string]: string | number }) {
        let queryParamsString: string = '';
        Object.keys(queryParams).forEach(queryParamKey => {
            queryParamsString += `&${queryParamKey}=${queryParams[queryParamKey]}`;
        });
        return this.httpService.get(`${urlConfig.searchGuest}?restaurantId=${propertyId}${queryParamsString}`,)
    }
}

export interface SlotLockRequest {
    FromWebsite: boolean,
    IsCommunalTable: boolean,
    LocalTime: string
    PartySize: number
    SeatingAreaId: number,
    SpecialMealId: number
}

export interface UpdatePayloadDTO {
    AgreedWithTermsOfUse: boolean;
    Contact: ContactDTO;
    FromWebsite: boolean;
    Id: number;
    IsCommunalTable: boolean;
    Notes: { Type: number; Text: string }[];
    OriginalRestaurantId: number;
    RefundData: [];
    RemindAboutVisit: boolean;
    RemindAboutVisitEmail: boolean;
    SeatingAreaId: number;
    SeatingTime: string | Date;
    Size: number;
    ReservationAttemptId?: number;
    Slot: {
        Time: string;
        LockId: number;
        Type: string;
        IsTimeInUTC: boolean;
        ReservationAttemptId?: number;
    };
    SpecialMealId: number | null;
}

export interface RegisterReservationAttemptRequest {
    CoverTypes: any[];
    LocalTime: string;
    PartyId: number;
    PartySize: number;
    SeatingAreaId: number;
    SpecialMealId: number;
}

export interface CRSUpdateReservationAttempt {
    AuthorizeTransactions: {
        Amount: number,
        AuthorizeTransactionID: string,
        BucketName: null,
        CardNumber: string,
        ConfirmationNumber: null,
        ExpirationDate: string,
        InvoiceID: number,
        LoyaltyAccountNumber: number,
        LoyaltyTransactionId: number,
        Name: string,
        PaymentType: number,
        RoomNumber: number,
        Token: string,
        TransactionDate: string,
        TransactionFollowOnData: string
    }[],
    CardNumber: string,
    PartyPaymentId: number,
    PaymentAmount: number,
    PaymentGateway: string,
    ReservationAttemptId: number,
    ReservationAttemptIds: number[],
    RestaurantId: number,
    SlotLockIds: number[],
    PartyId: number,
    TokenType: string
}