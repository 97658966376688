import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '@app/app.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { DynamicFormService } from '@dynamicform/service/dynamic-form.service';
import { FullContactDTO } from '@models/InputContact';
import { CountryDTO, SettingsDTO } from '@models/RestaurantDTO';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import { ISubscription, Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit, AfterViewInit, OnDestroy {
  // @Input() guestForm: FormGroup;
  contactDetailsForm: UntypedFormGroup;
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;

  config: FieldConfig[];
  selectedIndex: number;
  tabChangeSubscription: ISubscription;
  settingsSubscription: ISubscription;
  guestBookControls: string[];
  mobileRequired = false;
  countryCodes: { id: string; value: string; countryCode: string; countryId: number }[];
  _settings: SettingsDTO = {} as SettingsDTO;
  subscriptions: Subscription = new Subscription();

  constructor(public fb: UntypedFormBuilder, private gbs: GuestBookService, private ps: PopupService,
    @Inject(COMPONENTINPUT) private data: FullContactDTO, private partyService: PartyService,
    private cdf: ChangeDetectorRef, private appService: AppService, private dfs: DynamicFormService, public cs: CacheService) {
    this.settingsSubscription = cs.settings.subscribe(sett => {
      this._settings = sett;
    });

  }

  ngOnInit() {
    this.countryCodes = (this._settings.Countries as CountryDTO[]).map((country: CountryDTO) => {
      return {
        id: country.Name,
        value: `${country.Label} (${country.CountryPhoneCode})`,
        countryCode: country.CountryPhoneCode,
        countryId: country.Id
      };
    });
    const defaultCountryCode = Utilities.getDefaultCountry(this.countryCodes, this._settings);
    this.config = [
      {
        type: 'select',
        name: 'mobileCode',
        inputType: 'number',
        label: 'primaryCodeLbl',
        containerClass: 'contact-info__mobile-code',
        options: this.countryCodes,
        cellClick: this.countryCodeChange.bind(this, true),
        value: defaultCountryCode.id
      },
      {
        type: 'input',
        name: 'mobile',
        inputType: 'text',
        label: 'guestBookDetailsMobile',
        containerClass: 'contact-info__mobile',
        directive: 'numberOnly',
        showErrorText: true,
        isPhoneNumber: true,
        blurClick: this.mobileNumberValidation.bind(this),
        // disabled: true
      },
      {
        type: 'select',
        name: 'secondaryMobileCode',
        inputType: 'number',
        label: 'secondaryCodeLbl',
        containerClass: 'contact-info__mobile-code',
        options: this.countryCodes,
        cellClick: this.countryCodeChange.bind(this, false),
        value: defaultCountryCode.id
      },
      {
        type: 'input',
        name: 'phone',
        inputType: 'text',
        label: 'secondaryPhoneLabel',
        directive: 'numberOnly',
        containerClass: 'contact-info__mobile',
        showErrorText: true,
        isPhoneNumber: true,
        blurClick: this.phoneNumberValidation.bind(this),
      },
      {
        type: 'input',
        name: 'email',
        inputType: 'text',
        label: 'guestBookDetailsEmail',
        class: 'contact-info__email',
        validation: [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')], //[Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
        showErrorText: true
      },
      {
        type: 'input',
        name: 'secondaryEmail',
        inputType: 'text',
        label: 'secondaryEmailLabel',
        class: 'contact-info__email',
        validation: [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')], //[Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
        showErrorText: true
      }
    ];
    this.dfs.selectedCountryCode = defaultCountryCode.countryCode;
    this.dfs.selectedCountryId = defaultCountryCode.countryId;
    const contactFormGroup = this.fb.group({
      mobileCode: '',
      mobile: ['', this.config[1].validation ? this.config[1].validation : [Validators.nullValidator]],
      secondaryMobileCode: '',
      phone: ['', this.config[3].validation ? this.config[3].validation : [Validators.nullValidator]],
      email: ['', this.config[4].validation ? this.config[4].validation : [Validators.email]],
      secondaryEmail: ['', this.config[5].validation ? this.config[5].validation : [Validators.email]]
    });
    this.gbs.guestForm.addControl('contactDetailsForm', contactFormGroup);
  }

  ngAfterViewInit() {
    // this.ps.nextBtnEnabled.emit(!this.dynamicForm.form.valid);
    // this.dynamicForm.form.controls.mobile.disable();
    this.selectedIndex = this.gbs.tabsModal.tabs.findIndex(x => x.tabComponent === ContactComponent);
    this.guestBookControls = Object.keys(this.gbs.guestForm.controls);
    this.tabChangeSubscription = this.partyService.tabChange$.subscribe((data) => {
      if (data === this.selectedIndex) {
         this.tabsValidation();
      }
      // else if (this.dynamicForm.form.valid && this.gbs.guestForm.controls[this.guestBookControls[0]].valid) {
      //   this.ps.formValid$.next(
      //     {isFormValid: this.dynamicForm.form.valid, currentTab: this.selectedIndex } as IFormValidDetails);
      // }
    });
    this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe((data) => {
      this.gbs.guestForm.get('contactDetailsForm').patchValue(data);
      if (this.dynamicForm.form.dirty) {
        this.gbs.guestForm.get('contactDetailsForm').markAsDirty();
        this.gbs.guestForm.markAsDirty();
      }
      this.ps.formValid$.next({
        isFormValid: this.gbs.guestForm.get('personalDetailsForm').valid &&
          this.dynamicForm.form.valid, currentTab: this.selectedIndex
      } as IFormValidDetails);
      // this.mobileNumberValidation(data);
      this.tabsValidation();
    }));
    if (this.data) {
      const { Street1 = '', Street2 = '', City = '', Country = '', PostalCode = '' } =
        this.data.ContactAddress?.length > 0 && this.data.ContactAddress[0];
      const { PhoneNumber, PhoneNumber2, EmailAddress, CountryId, EmailAddress2, CountryId2 } = this.data;
      let countryPhoneCode: CountryDTO;
      let countryPhoneCode2: CountryDTO;
      if (CountryId) {
        countryPhoneCode = (this._settings.Countries as CountryDTO[]).filter((country: CountryDTO) => {
          return country.Id === CountryId;
        })[0];
      }
      if (CountryId2) {
        countryPhoneCode2 = (this._settings.Countries as CountryDTO[]).filter((country: CountryDTO) => {
          return country.Id === CountryId2;
        })[0];
      }
      this.dfs.selectedCountryCode = countryPhoneCode ? countryPhoneCode.CountryPhoneCode : this.dfs.selectedCountryCode;
      this.dfs.selectedCountryId = countryPhoneCode ? countryPhoneCode.Id : this.dfs.selectedCountryId;
      this.clearPhonevalidations();
      this.dynamicForm.form.patchValue({
        mobileCode: countryPhoneCode ? countryPhoneCode.Name : this.config[0].value,
        secondaryMobileCode: countryPhoneCode2 ? countryPhoneCode2.Name : this.config[2].value,
        mobile: PhoneNumber,
        phone: PhoneNumber2,
        email: EmailAddress,
        secondaryEmail: EmailAddress2
      });
      this.mobileNumberValidation();
      this.phoneNumberValidation();
    }
    if(this.ps.tabsActionData?.length){
    this.ps.tabsActionData[this.selectedIndex].gotoNextTab = this.data ? true : false;
    }
    this.cdf.detectChanges();
  }

  tabsValidation(){
    if (this.ps.tabsActionData?.length) {
      this.ps.tabsActionData[this.selectedIndex].gotoNextTab = this.dynamicForm.form.valid;
    }
    this.ps.tabsActions$.next(this.ps.tabsActionData);
  }

  ngOnDestroy() {
    this.dynamicForm.form.reset();
    if (this.tabChangeSubscription) {
      this.tabChangeSubscription.unsubscribe();
    }
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

  clearPhonevalidations(){
    this.dynamicForm.form.get('mobile').clearValidators();
    this.dynamicForm.form.get('phone').clearValidators();
    this.dynamicForm.form.get('mobile').updateValueAndValidity();
    this.dynamicForm.form.get('phone').updateValueAndValidity();
    this.gbs.guestForm.get('contactDetailsForm')['controls']['mobile'].clearValidators();
    this.gbs.guestForm.get('contactDetailsForm')['controls']['phone'].clearValidators();
    this.gbs.guestForm.get('contactDetailsForm')['controls']['mobile'].updateValueAndValidity();
    this.gbs.guestForm.get('contactDetailsForm')['controls']['phone'].updateValueAndValidity();
  }

  countryCodeChange(isPrimaryNumber, event) {
    this.setUpCountryCodeForMobileOrPhone(isPrimaryNumber ? true : false);
    if (isPrimaryNumber) {
      this.dynamicForm.form.controls.mobile.enable();
      const mobileValue = this.dynamicForm.form.get('mobile').value;
      let mobileNumber: any;
      mobileNumber = this.dfs.onInputChange(mobileValue ? mobileValue : event.value, false);
      if (this.dynamicForm.form.get('mobile').value && this.dynamicForm.form.get('mobile').value.length > 0) {
        this.dynamicForm.form.get('mobile').setValue(mobileNumber);
      }
    }
    else {
      this.dynamicForm.form.controls.phone.enable();
      const phoneValue = this.dynamicForm.form.get('phone').value;
      let phoneNumber: any;
      phoneNumber = this.dfs.onInputChange(phoneValue ? phoneValue : event.value, false);
      if (this.dynamicForm.form.get('phone').value && this.dynamicForm.form.get('phone').value.length > 0) {
        this.dynamicForm.form.get('phone').setValue(phoneNumber);
      }
    }
    if (this.dfs.requiredPhoneNumberLength$) {
      const count = this.dfs.requiredPhoneNumberLength$;
      if (isPrimaryNumber) {
        this.dynamicForm.form.get('mobile').setValidators([Validators.minLength(count), Validators.maxLength(count)]);
        this.gbs.guestForm.get('contactDetailsForm')['controls']['mobile'].setValidators([Validators.minLength(count), Validators.maxLength(count)]);
        this.dynamicForm.form.get('mobile').updateValueAndValidity();
        this.gbs.guestForm.get('contactDetailsForm')['controls']['mobile'].updateValueAndValidity();
      }
      else {
        this.dynamicForm.form.get('phone').setValidators([Validators.minLength(count), Validators.maxLength(count)]);
        this.gbs.guestForm.get('contactDetailsForm')['controls']['phone'].setValidators([Validators.minLength(count), Validators.maxLength(count)]);
        this.dynamicForm.form.get('phone').updateValueAndValidity();
        this.gbs.guestForm.get('contactDetailsForm')['controls']['phone'].updateValueAndValidity();
      }
    } else {
      if (isPrimaryNumber) {
        this.dynamicForm.form.get('mobile').setValidators(null);
        this.gbs.guestForm.get('contactDetailsForm')['controls']['mobile'].setValidators(null);
        this.dynamicForm.form.get('mobile').updateValueAndValidity();
        this.gbs.guestForm.get('contactDetailsForm')['controls']['mobile'].updateValueAndValidity();
      } else {
        this.dynamicForm.form.get('phone').setValidators(null);
        this.gbs.guestForm.get('contactDetailsForm')['controls']['phone'].setValidators(null);
        this.dynamicForm.form.get('phone').updateValueAndValidity();
        this.gbs.guestForm.get('contactDetailsForm')['controls']['phone'].updateValueAndValidity();
      }
    }
  }

  mobileNumberValidation(event?) {
    this.setUpCountryCodeForMobileOrPhone(true);
    const mobile = this.dynamicForm.form.get('mobile').value;
    let mobileNumber: any;

    if (mobile || (event && event.value))
      mobileNumber = this.dfs.onInputChange(mobile ? mobile : event.value, false);

    if (this.dynamicForm.form.get('mobile').value && this.dynamicForm.form.get('mobile').value.length > 0) {
      this.dynamicForm.form.get('mobile').setValue(mobileNumber);
    }

    if (this.dfs.requiredPhoneNumberLength$) {
      const count = this.dfs.requiredPhoneNumberLength$;
      if (this._settings.General.ValidateGuestPhoneNumber) {
        this.dynamicForm.form.get('mobile').setValidators([Validators.minLength(count), Validators.maxLength(count)]);
        this.gbs.guestForm.get('contactDetailsForm')['controls']['mobile'].setValidators([Validators.minLength(count), Validators.maxLength(count)]);
      } else {
        this.dynamicForm.form.get('mobile').setValidators(null);
        this.gbs.guestForm.get('contactDetailsForm')['controls']['mobile'].setValidators(null);
      }
      this.dynamicForm.form.get('mobile').updateValueAndValidity();
      this.gbs.guestForm.get('contactDetailsForm')['controls']['mobile'].updateValueAndValidity();
    }
  }

  phoneNumberValidation(event?) {
    this.setUpCountryCodeForMobileOrPhone(false);
    const phoneValue = this.dynamicForm.form.get('phone').value;
    let phoneNumber: any;
    if (phoneValue || (event && event.value)) {
      phoneNumber = this.dfs.onInputChange(phoneValue ? phoneValue : event.value, false);
      if (this.dynamicForm.form.get('phone').value && this.dynamicForm.form.get('phone').value.length > 0) {
        this.dynamicForm.form.get('phone').setValue(phoneNumber);
      }

      if (this.dfs.requiredPhoneNumberLength$) {
        const count = this.dfs.requiredPhoneNumberLength$;
        if (this._settings.General.ValidateGuestPhoneNumber) {
          this.dynamicForm.form.get('phone').setValidators([Validators.minLength(count), Validators.maxLength(count)]);
          this.gbs.guestForm.get('contactDetailsForm')['controls']['phone'].setValidators([Validators.minLength(count), Validators.maxLength(count)]);
        } else {
          this.dynamicForm.form.get('phone').setValidators(null);
          this.gbs.guestForm.get('contactDetailsForm')['controls']['phone'].setValidators(null);
        }
        this.dynamicForm.form.get('phone').updateValueAndValidity();
        this.gbs.guestForm.get('contactDetailsForm')['controls']['phone'].updateValueAndValidity();
      }
    } else {
      this.dynamicForm.form.get('phone').clearValidators();
      this.gbs.guestForm.get('contactDetailsForm')['controls']['phone'].clearValidators();
      this.dynamicForm.form.get('phone').updateValueAndValidity();
      this.gbs.guestForm.get('contactDetailsForm')['controls']['phone'].updateValueAndValidity();
    }

  }

  setUpCountryCodeForMobileOrPhone(isMobile) {
    const selectedCountryCode = this.countryCodes.filter((country) => {
      return country.id === this.dynamicForm.form.get(isMobile ? 'mobileCode' : 'secondaryMobileCode').value;
    })[0];
    this.dfs.selectedCountryCode = selectedCountryCode.countryCode;
    this.dfs.selectedCountryId = selectedCountryCode.countryId;
  }

}
