import { Component, Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TimelineDragHandlerService } from "@app/activities-timeline/services/timeline-scroll-handler.service";
import { PopupService } from "@app/popup-module/popup.service";
import { ViewBy } from "@app/shared/constants/commonenums";
import { PartyDTO } from "@app/shared/models/InputContact";
import { SlotDTO } from "@app/shared/models/InputReservation";
import { SlotProcessor } from "../SlotProcessor";
import { PartyService } from "@app/shared/services/party.service";
import { TranslateService } from "@ngx-translate/core";
import { BlockingrulePopupservice } from "@app/shared/components/create-update-blocking-tab-layout/blockingrulepopup.service";
import { SettingsService } from "@app/shared/services/settings.service";
import { CacheService } from "@app/core/services/cache.service";
import { TablesService } from "@app/shared/services/tables.service";
import { ConfigDateFormatter } from "../activities-timeline-main.component";
import { DashboardFunctions } from "@app/shared/utilities/dashboard-functions";
@Component({
  selector: 'app-timeline-row',
  templateUrl: './timeline-row.component.html',
  styleUrls: ['./timeline-row.component.scss']
})

export class TimelineRowComponent extends SlotProcessor implements OnInit, OnChanges {
  @Input() columnWidth: number;

  constructor(dialog: MatDialog, public partyService: PartyService, private ts: TranslateService, protected popUpService: PopupService, public dragHandlerService:TimelineDragHandlerService,
    blockingrule: BlockingrulePopupservice, settingservice: SettingsService, tableService: TablesService,
     cs: CacheService, protected dateFormatter: ConfigDateFormatter, protected dashboardFunctions: DashboardFunctions
  ){
    super(dialog, blockingrule, popUpService, settingservice, tableService, cs, ts, dateFormatter, dashboardFunctions);
  }

  ngOnInit(): void {
    this.setSessions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rowData'] || changes['noOfBookings'] || changes['selectedViewId'] || changes['CurrentDateSelected'] || changes['staffSchedule']) {
      this.setSessions();
    }
  }

}


@Directive({
  selector: "[columnGenerator]"
})
export class ColumnGeneratorDirective implements OnChanges {

  @Input('columnGenerator') group;
  @Input('columnWidth') columnWidth;

  constructor(private el: ElementRef) {
  }

  ngOnChanges(): void {
    let value = (this.group.groupStart == this.group.groupEnd) ? this.group.groupStart + '/' + (this.group.groupEnd) : this.group.groupStart + '/' + (this.group.groupEnd + 1)
    this.el.nativeElement.style.gridColumn = value;

    let noOfColumns = this.group.groupEnd - this.group.groupStart;
    let innerValue = 'repeat(' + (noOfColumns || 1) + ', minmax(' + this.columnWidth + 'px, 1fr))';
    this.el.nativeElement.style.gridTemplateColumns = innerValue;
  }
}


@Directive({
  selector: "[subColumnGenerator]"
})
export class SubColumnGeneratorDirective implements OnChanges {

  @Input('subColumnGenerator') group;
  @Input('item') item;

  constructor(private el: ElementRef) {
  }

  ngOnChanges(): void {
    let value = (this.item.gridStart == this.group.groupStart ? 1 : this.item.gridStart - this.group.groupStart + 1) + '/ span ' + (this.item.gridEnd - this.item.gridStart + 1);
    this.el.nativeElement.style.gridColumn = value;
  }
}

@Directive({
  selector: "[gridColumnsGenerator]"
})

export class GridColumnsGeneratorDirective implements OnChanges {

  @Input('gridColumnsGenerator') timeArrayGenerated;
  @Input('columnWidth') columnWidth;
  @Input('isWeekView') isWeekView;

  constructor(private el: ElementRef) {
  }

  ngOnChanges(): void {
    let value = `repeat(${this.timeArrayGenerated},minmax(${this.columnWidth}px, ${this.columnWidth}px))`;
    if(this.isWeekView) {
      value = `repeat(${this.timeArrayGenerated},minmax(${this.columnWidth}px, 1fr))`;
    }
    this.el.nativeElement.style.gridTemplateColumns = value;
  }
}
