<section [ngSwitch]="type" class="h-100 passcode-section-wrapper">
<ng-template [ngSwitchCase]="PasscodeTypesEnum.Passcode">
    <app-dialog-header [title]="title" (closeDialog)="close()"></app-dialog-header>
        <div class="passcode-wrapper ag_container--padding-md">
            <table *ngIf="overSellInfo && overSellInfo.length>0" class="overSellInfo" aria-label="Over Cell Table">
                <thead>
                    <tr>
                        <th id="date">{{captions.lbl_date}}</th>
                        <th id="roomType">{{captions.lbl_roomType}}</th>
                        <th id="availability">{{captions.lbl_availability}}</th>
                        <th id="hote">{{captions.lbl_hotel}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let obj of overSellInfo">
                        <td>{{obj.date}}</td>
                        <td>{{obj.roomType}}</td>
                        <td>{{obj.availability}}</td>
                        <td>{{obj.hotel}}</td>
                    </tr>
                </tbody>
               </table>
            <form class="passcode-form" [formGroup]="passcodeForm" autocomplete="off">
                <div>
                    <ag-textbox (keydown.enter)="!this.buttonObj.disabled ? save($event) : ''" [config]="passcodeInputConfig"></ag-textbox>
                </div>
            </form>
        </div>
    <app-dialog-footer [buttonObj]="buttonObj" (cancel)="close()" (save)="save($event)"></app-dialog-footer>
</ng-template>

<ng-template [ngSwitchCase]="PasscodeTypesEnum.QuickId">
    <app-dialog-header [title]="title" (closeDialog)="close()"></app-dialog-header>
    <div class="passcode-wrapper ag_container--padding-md" [formGroup]="quickIdForm">
        <table *ngIf="overSellInfo && overSellInfo.length>0" class="overSellInfo" aria-label="Over Cell Table">
            <thead>
                <tr>
                    <th id="date">{{captions.lbl_date}}</th>
                    <th id="roomType">{{captions.lbl_roomType}}</th>
                    <th id="availability">{{captions.lbl_availability}}</th>
                    <th id="hote">{{captions.lbl_hotel}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let obj of overSellInfo">
                    <td>{{obj.date}}</td>
                    <td>{{obj.roomType}}</td>
                    <td>{{obj.availability}}</td>
                    <td>{{obj.hotel}}</td>
                </tr>
            </tbody>
           </table>
        <h4 class="ag_form--label ag_font--bold">{{headerDescription}}</h4>
        <div class="ag_form--label">{{description}}</div>
        <div>
            <ag-input [automationId]="'Txt_passcode_quickId'"  formControlName="quickId" [placeholder]="commonCaptions.lbl_quickId"
            (keydown.enter)="!this.quickIdButtonObj.disabled ? confirm($event) : ''"  class="LW14 d-block w-60" required>
            </ag-input>
        </div>
    </div>
    <app-dialog-footer [buttonObj]="quickIdButtonObj" (cancel)="close()" (save)="confirm($event)"></app-dialog-footer>
</ng-template>

<ng-template [ngSwitchCase]="PasscodeTypesEnum.ManagerAuth">
    <app-dialog-header [title]="title" (closeDialog)="close()"></app-dialog-header>
    <div class="passcode-wrapper ag_container--padding-md" [formGroup]="managerAuthForm">
        <table *ngIf="overSellInfo && overSellInfo.length>0" class="overSellInfo" aria-label="Over Cell Table">
            <thead>
                <tr>
                    <th id="date">{{captions.lbl_date}}</th>
                    <th id="roomType">{{captions.lbl_roomType}}</th>
                    <th id="availability">{{captions.lbl_availability}}</th>
                    <th id="hote">{{captions.lbl_hotel}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let obj of overSellInfo">
                    <td>{{obj.date}}</td>
                    <td>{{obj.roomType}}</td>
                    <td>{{obj.availability}}</td>
                    <td>{{obj.hotel}}</td>
                </tr>
            </tbody>
           </table>
        <h4 class="ag_form--label ag_font--bold">{{headerDescription}}</h4>
        <div class="ag_display--flex-wrap mt-3 auth-wrapper">
            <ag-input [automationId]="'Txt_passcode_userName'" (keydown.enter)="!this.managerAuthButtonObj.disabled ? approve($event) : ''"  formControlName="username" [placeholder]="commonCaptions.lbl_userName"
                 class="LW14 d-block mr-4" required>
            </ag-input>
            <mat-form-field [floatLabel]="floatLabel" class="LW14 d-block">
               <input [attr.automationId]="'Txt_passcode_password'" matInput type="password" formControlName="password" autocomplete="off"
                [placeholder]="commonCaptions.lbl_password" (keydown.enter)="!this.managerAuthButtonObj.disabled ? approve($event) : ''">
                <mat-error *ngIf="managerAuthForm.controls['password'].hasError('required')">
                    {{commonCaptions.lbl_errorMessage}}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <app-dialog-footer [buttonObj]="managerAuthButtonObj" (cancel)="close()" (save)="approve($event)"></app-dialog-footer>
</ng-template>
<ng-template [ngSwitchCase]="PasscodeTypesEnum.Warning">
    <!-- <app-dialog-header [title]="title" (closeDialog)="close()"></app-dialog-header> -->
    <h1 class='ag_p--4 ag_m--0 ag_display--flex align-items-center' mat-dialog-title>{{title}}
        <i class="icon-warning-icon pl-3"></i>
        <i class='icon-close ag_ml--auto ag_cursor--pointer'
            (click)="close()">&nbsp;</i>
    </h1>
    <div class="passcode-wrapper ag_container--padding-md warning" [formGroup]="passcodeForm">
        <table *ngIf="overSellInfo && overSellInfo.length>0" class="overSellInfo" aria-label="Over Cell Table">
            <thead>
                <tr>
                    <th id="date">{{captions.lbl_date}}</th>
                    <th id="roomType">{{captions.lbl_roomType}}</th>
                    <th id="availability">{{captions.lbl_availability}}</th>
                    <th id="hote">{{captions.lbl_hotel}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let obj of overSellInfo">
                    <td>{{obj.date}}</td>
                    <td>{{obj.roomType}}</td>
                    <td>{{obj.availability}}</td>
                    <td>{{obj.hotel}}</td>
                </tr>
            </tbody>
           </table>
           <h4 class="ag_form--label ag_font--bold">{{description}}</h4>
        <h4 class="ag_form--label ag_font--bold">{{headerDescription}}</h4>
    </div>
    <app-dialog-footer [buttonObj]="okButtonObj" (cancel)="close()" (save)="ok($event)"></app-dialog-footer>
</ng-template>
</section>