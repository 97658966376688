import { DropDownConfig } from '@dynamicform/models/field-config.interface';
import { PropertyType, ViewBy, GuestFieldValidationType, ViewPeriod, ReservationType, FinancialEffectAction } from './commonenums';
import { PartyPrepaymentState } from '../models/InputContact';


/*  Add New Guest PopUp Title*/
export const back = 'Back';
export const popUpTitle = 'Add New Guest';
export const editPopUpTitle = 'Edit Guest Details';

/*  Add New Guest PopUp TabName*/
export const personalInfo = 'Personal Info';
export const contact = 'Contact';
export const preferences = 'Preferences';
export const additionalInfo = 'Additional Info';

/* Add New Guest PopUp - AdditionalInfo */
export const notes = 'Notes';
export const specialPreference = 'Special Preferences';
export const history = 'history'

export const EmailAddress = 'rguestseat@agilysys.com';

export const countNotificationMenus = ['reservationMenuText', 'waitListMenuText'];
export const DEFAULT_LANGUAGE_ID = 1;
export const DEFAULT_LANGUAGE_CODE = 'en';
/**
 * Export Common Constant value
 */

export const CONSTANTVALUE: any = {
  SEATED: 'seated',
  ARRIVED: 'Arrived',
  OPENTABLE: 'openTable'
};
export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export let actionsIcons: any = [
  { id: 'edit', icon: 'edit', }, { id: 'history', icon: 'recent-history' }];

export let tableActionsIcons: any = actionsIcons.concat({ id: 'move', icon: 'Union-20' }, { id: 'unassignserver', icon: '' }, { id: 'reseat', icon: '' })
// [{id:'edit', icon:'edit' }, {id:'move', icon:'Union-20'},{id:'unassignserver',icon:''} ];

export const ICONARRAY: any = [
  { name: 'Glutens Allergy', icon_name: 'icon-Gluten-Free' },
  { name: 'Birthday', icon_name: 'icon-Birthday' },
  { name: 'Egg Allergy', icon_name: 'icon-Eggs' },
  { name: 'Peanuts Allergy', icon_name: 'icon-Peanuts' },
  { name: 'Vegetarian', icon_name: 'icon-Vegetarian' },
  { name: 'Anniversary', icon_name: 'icon-Anniversary' },
  { name: 'High Chair Required', icon_name: 'icon-High-Chair' },
  { name: 'Baby Carrier Required', icon_name: 'icon-Baby-Carrier' },
  { name: 'Need High Chair', icon_name: 'icon-High-Chair' },
  { name: 'edit', icon_name: 'icon-edit' },
  { name: 'VIP', icon_name: 'icon-Path-495' },
  { name: 'Allergy', icon_name: 'icon-Gluten-Free' }

];

export const ERRORMESSAGES: any = {
  BREAKHOURSNOTINSHIFTRANGE: 'Break hours not in the shift range',
  SELECTEDTIMESNOTINCORRECTRANGE: 'selected times are not in correct range',
  BREAKHOURSINTERSECTS: 'Break hours intersects with the already added break hours'
};

export const legendArray: any = [
  { State: 0, name: 'Reserved' },
  { State: 1, name: 'Seated' },
  { State: 2, name: 'No Show' },
  { State: 3, name: 'Cancelled' },
];


export enum mandatoryCommn {
  phone,
  email,
  secondaryPhone,
  secondaryEmail,
  anyOne
}

export enum BookingGuestType {
  None = 0,
  HotelGuest = 1,
  Member = 2,
  Loyalty = 3,
  Others = 4
}

export const BookingGuestTypeConfig: DropDownConfig[] = [
  { id: BookingGuestType.None, value: 'None' },
  { id: BookingGuestType.HotelGuest, value: 'hotelGuest' },
  { id: BookingGuestType.Member, value: 'memberText' },
  { id: BookingGuestType.Loyalty, value: 'loyaltyText' },
  { id: BookingGuestType.Others, value: 'Others' },
];


export const reservationSettings: any = [
  { id: 1, value: "showCreatedDate", isSelected: false },
  { id: 11, value: "expandAll", isSelected: false }
]

export const popupDialogDimension: any = {
  actionDialogWidth: '600px',
  actionDialogHeight: '400px',
  createDialogWidth: '1000px',
  createDialogHeight: '700px',
  actionDialogMinHeight: '200px',
  actionDialogMaxHeight: '600px',
  createDialogMaxHeight: '850px',
  createDialogMaxWidth: '1500px',
  guestItineraryactionDialogMaxWidth: '15000px'
};

export const itemsPerPage: any = [
  { id: 1, value: 10 },
  { id: 2, value: 25 },
  { id: 3, value: 50 },
  { id: 4, value: 75 },
  { id: 5, value: 100 }];

export const contactsSort: DropDownConfig[] = [
  { id: 'FirstName', value: 'First Name' },
  { id: 'LastName', value: 'Last Name' },
  { id: 'Email', value: 'Email' },
  { id: 'Date', value: 'Date' }];


export const dateNavigation: any = {
  previous: 0,
  next: 1
};

// export const reservationViewBy = {
//   Twodays: 0 ,
//   Oneday: 1,
//   Today: 2,
//   Week: 3
// };
export const reservationViewBy = {
  Today: 0,
  Week: 1
};

export const fieldType: string[] = ['Text Field', 'Drop Down'];

export enum serverActions {
  offDuty = 0,
  done,
  editData,
  switchServer,
  assignServer,
  unassignAll,
  cancel
}

export const MaxFileSize = 2000000;
export const fileExtensions = ['.jpg', '.png', '.jpeg'];

export const AutoRefreshTimeInSecs = 5;

export const activitiesTimelineView: DropDownConfig[] = [
  { id: ViewBy.Instructor, value: 'Instructor' },
  { id: ViewBy.Lessons, value: 'Lessons', isDefault: true },
  { id: ViewBy.location, value: 'location' }
]

export const ActivitiesTimeLineOperation = {
  BookActivityFromHostApp: 'BookActivityFromHostApp',
  BookActivityFromWebsite: 'BookActivityFromWebsite',
  BookMulptipleActivityFromHostApp: 'BookMulptipleActivityFromHostApp',
  BookMultipleActivityFromWebsite: 'BookMultipleActivityFromWebsite'
}

export const theatreTimelineView: DropDownConfig[] = [
  { id: ViewBy.Shows, value: 'Shows' },
  { id: ViewBy.Layouts, value: 'Layouts' },
]
export const timelineViewPeriod: DropDownConfig[] = [
  { id: ViewPeriod.Day, value: 'Day' },
  { id: ViewPeriod.Week, value: 'Week' },
]
export const AnyVenue = [
  PropertyType.Restaurant,
  PropertyType.Auditorium,
  PropertyType.Cabana,
  PropertyType.MovieTheatre
]

export const ActivitiesVenue = [
  PropertyType.TennisCenter,
  PropertyType.General
]

export const DineInVenue = [
  PropertyType.Restaurant,
  PropertyType.Cabana
]
export const RestaurantVenue = [
  PropertyType.Restaurant
]

export const SeatVenue = [
  PropertyType.MovieTheatre
]

export const propertyWithDefaultCourtArea = [
  PropertyType.TennisCenter
]

export const propertyOfTypeTheatre = [
  PropertyType.MovieTheatre
]

export const RejectReasonTag = "Reasons";

export const controlSettings = {
  Layout_ViewByShiftDropdown_Hide: [
    PropertyType.MovieTheatre,
    PropertyType.TennisCenter,
    PropertyType.General
  ],
  Layout_ViewByFloors_Hide: [
    PropertyType.MovieTheatre
  ],
  Layout_FloorsTab_Hide: [
    PropertyType.MovieTheatre
  ],
  BookingGuest_SecondaryContact_Hide: [
    PropertyType.MovieTheatre,
    PropertyType.TennisCenter,
    PropertyType.General
  ],
  Booking_Shows_CurrentDate: [
    PropertyType.General
  ],
  Location_ViewByShiftDropdown_Hide: [
    PropertyType.MovieTheatre,
    PropertyType.TennisCenter,
    PropertyType.General
  ],
  Location_AdvanceBlock_Hide: [
    PropertyType.MovieTheatre,
  ],
  Location_partySelect_Cancel_Hide: [
    PropertyType.TennisCenter,
    PropertyType.General
  ],
  Header_SeatingArea_Disable: [
    PropertyType.MovieTheatre,
    PropertyType.TennisCenter,
    PropertyType.General
  ],
  Cart_Enable: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre
  ],
  Location_waitList_Hide: [
    PropertyType.General,
    PropertyType.TennisCenter,
  ],
  Location_MoveAction_Hide: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre
  ],
  Location_TurnTime_Hide: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre
  ],
  ShiftStatistics_WaitTime_Hide: [
    PropertyType.General,
    PropertyType.TennisCenter,
  ],
  CoverReport_WalkIn_Hide: [
    PropertyType.General,
    PropertyType.TennisCenter,
  ],
  Blocks_AuditLog_Hide: [
    PropertyType.General,
    PropertyType.TennisCenter
  ],
  Location_layoutEditor_emptyFloor_minSize_1: [
    PropertyType.MovieTheatre
  ],
  Location_layoutEditor_emptyFloor_maxSize_1: [
    PropertyType.MovieTheatre
  ],
  Location_layoutEditor_edit_default_floorName: [
    PropertyType.MovieTheatre
  ],
  Location_layoutEditor_hide_viewBy: [
    PropertyType.MovieTheatre
  ],
  Location_layoutEditor_set_default_floorplanViewId: [
    PropertyType.MovieTheatre
  ],
  Location_layoutEditor_enable_edit: [
    PropertyType.MovieTheatre
  ],
  Timeline_view_config: [
    PropertyType.MovieTheatre
  ],
  Location_partySize_hide: [
    PropertyType.MovieTheatre,
    PropertyType.TennisCenter,
    PropertyType.General
  ],
  Location_serverName_hide: [
    PropertyType.MovieTheatre,
    PropertyType.TennisCenter,
    PropertyType.General
  ],
  Location_moveTable_hide: [
    PropertyType.MovieTheatre,
    PropertyType.TennisCenter,
    PropertyType.General
  ],
  dashboard_QuickSeat_Hide: [
    PropertyType.TennisCenter,
    PropertyType.General
  ],
  propertyType_LessonBooking: [
    PropertyType.TennisCenter,
    PropertyType.General
  ],
  Location_EditUpcomingParty_Hide: [
    PropertyType.TennisCenter,
    PropertyType.General
  ],
  SideMenu_SwitchOption_Hide: [
    PropertyType.TennisCenter,
    PropertyType.General,
    PropertyType.MovieTheatre,
    PropertyType.Auditorium

  ],
  SideMenu_CRS_VISIBLE: [
    PropertyType.Restaurant
  ],
  MembershipCode_Visible: [
    PropertyType.Restaurant,
    PropertyType.Cabana,
    PropertyType.Auditorium,
    PropertyType.MovieTheatre
  ],
  MembershipType_Hide: [
    PropertyType.Restaurant,
    PropertyType.Cabana,
    PropertyType.Auditorium,
    PropertyType.MovieTheatre
  ],
  Location_rightPannel_CheckIn_Hide: [
    PropertyType.General,
    PropertyType.TennisCenter
  ],
  Tables_Show_Assignserver_QuickSeat: [
    PropertyType.Cabana,
    PropertyType.Restaurant,
  ],
  Settings_Show_Package_configuration: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.Auditorium,
    PropertyType.MovieTheatre,
  ],
  Settings_Show_Email_configuration: [
    PropertyType.Restaurant,
    PropertyType.Cabana
  ],
  Layout_Show_BufferTime: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.Auditorium,
    PropertyType.MovieTheatre,
  ],
  Header_Dining_Logo: [
    PropertyType.Restaurant,
    PropertyType.Cabana
  ],
  Header_Non_Dining_Logo: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre,
    PropertyType.Auditorium
  ],
  Settings_Show_Activities_Creations: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre,
    PropertyType.Auditorium
  ],
  Settings_Show_SpecialMeal_Creations: [
    PropertyType.Restaurant,
    PropertyType.Cabana
  ],
  AuditLog_Show_Size_Logo: [
    PropertyType.Restaurant,
    PropertyType.Cabana
  ],
  Tables_SererLoad_Show: [
    PropertyType.Restaurant,
    PropertyType.Cabana,
    PropertyType.Auditorium,
    PropertyType.MovieTheatre
  ],
  Server_Assignment_Hide: [
    PropertyType.TennisCenter,
    PropertyType.General
  ],
  Server_Background_Toggle_Enable: [
    PropertyType.Restaurant,
    PropertyType.Cabana
  ],
  Server_Sync_Server_Button: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre,
    PropertyType.Auditorium
  ],
  Booking_Popup_Show_CancelledReservation: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre,
    PropertyType.Auditorium
  ],
  Calendar_View_Hide: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre,
    PropertyType.Auditorium
  ],
  Header_Help_Icon_Hide: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre,
    PropertyType.Auditorium
  ],
  Location_StandbyList_Hide: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre,
    PropertyType.Auditorium
  ],
  AdvanceBlock_Activities_Show: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre,
    PropertyType.Auditorium
  ],
  Overbook_Permission_Popup: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre,
    PropertyType.Auditorium
  ],
  Widget_Dining_Cart: [
    PropertyType.Restaurant,
    PropertyType.Cabana
  ],
  Widget_Non_Dining_cart: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre,
    PropertyType.Auditorium
  ],
  BookingCentral_Menu_Hide: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre,
    PropertyType.Auditorium
  ],
  VCart_Dining_Show: [
    PropertyType.Restaurant,
    PropertyType.Cabana,
    PropertyType.Auditorium
  ],
  VCart_NonDining_Show: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre
  ],
  Hide_Reports_By_Permission: [
    PropertyType.Cabana,
    PropertyType.Restaurant,
  ],

  ActivityType: [
    PropertyType.General,
    PropertyType.TennisCenter,
    PropertyType.MovieTheatre,
    PropertyType.Cabana
  ],

  Addon_For_CRS: [
    PropertyType.Restaurant,
    PropertyType.Cabana,
    PropertyType.Auditorium,
    PropertyType.MovieTheatre
  ],
  Report_AuditableEntityType_Offer_Show: [
    PropertyType.Restaurant,
    PropertyType.Cabana
  ]
}
export const GuestFieldValidation = [
  GuestFieldValidationType.Min,
  GuestFieldValidationType.Max,
  GuestFieldValidationType.Equal,
  GuestFieldValidationType.NotEqual,
  GuestFieldValidationType.NotNull,
  GuestFieldValidationType.Exists
]

export const DueState = [PartyPrepaymentState.PartialPrepayment, PartyPrepaymentState.PaymentDuePending];
export const PaidState = [PartyPrepaymentState.DepositCollected, PartyPrepaymentState.Prepaid, PartyPrepaymentState.None];

export const TimelineDefaultFilter = {
  GuestTypeFilterCollapsed: true,
  PaymentTypeFilterCollapsed: true,
  ActivityFilterCollapsed: true,
  GuestTypeFilterState: {
    allSelected: false,
    showBookedSessions: false,
    showBookingConfirmedSessions: false,
    showStandbyBookedSessions: false,
    showOverbookedSessions: false,
    showCancelledReservations: false,
    showCheckedOutReservations: false,
  },
  PaymentTypeFilterState: {
    allSelected: false,
    showPaidBookings: false,
    showPartialPaidBookings: false,
    showUnpaidBookings: false,
  },
  ActivityFilterState: {
    allSelected: false,
    showCancelledSessions: false,
    showBlockedSessions: false
  }
}
export enum ActivityState {
  Unknown = -1,
  Reserved,
  CheckedIn,
  CheckedOut,
  NoShow,
  Cancelled,
  CancellationInprogress
}

export enum EventIntializers {
  CREATEGUESTFORRESERVATION = 'CREATEGUESTFORRESERVATION'
}

export const ticketPrintStyles = `@media print {
  #ticket-print-section{
  break-inside: avoid;
  font-family: 'Roboto-Regular';
  }
  .ticket__container.print-selection {
  display: block !important;
  visibility: visible !important;
  border: 2px solid black;
  width: 98%;
  color: black !important;
  break-inside: avoid;
  font-family: 'Roboto-Regular';
  }


  .panel.left-panel tr {
      border-bottom: 2px solid black !important;
  }


  .right-panel .scan {
      position: absolute !important;
      right: 5% !important;
  top: 0px !important;
  }


  tr {
  border-bottom: 2px solid black !important;
  }
  td {
  padding: 4px 4px;
  font-size: 14px;
  border-bottom: 2px solid black !important;
  }

  td:first-child {
  width: 55%;
  }

  td:last-child {
  width: 50%;
  }

  .ticket-col-8 {
  width: 60%;
  padding-right: 10px;


  }
  .user-counter-details {

 margin-top:15px;
  }
  .user-counter-details .group{
  border-right: 1px solid black;
  }
  .user-counter-details .group:last-child{
  border-right: none;
  }

  .user-detail {
  padding: 0px 10px 0px 10px;
  width:100%;
  }
  .ticket-row{
  display: inline-block;
  width: 100%;

  }
  .left-section{
  float: left;
  width: 60%;
  }
  .right-section{
  float: left;
  width: 40%;

  }
  .right-section-div{
    border-left: 3px dotted;
  }
  .right-panel{
  position: relative;
  width: 25%;
  float: left;
  }
  .left-panel{
  width:75%;
  float: left;
  }
  .ticket__container {
  margin-top: 20px;
  position: relative;
  border: 1px solid rgba(171, 171, 171, 0.3);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 7px;
  }
  .ticket-col-4 {
  width: 37%;


  }
  .row-width{
    width:100%;
  }
  .counter-area {

    margin-left: 10px;
  }
  .counter-area  .group{
    border-right: 1px solid black;
  }
  .counter-area .group:last-child{
    border-right: none;
  }
  .bottom-session.time-details {
      text-align: center;
      margin-left: 10%;
  }
  .time-title{
    text-align: center;
  font-size: 15px;
  margin-top: 13px;
  }
  .time-title span{
    font-weight:bold
  }
  .user-counter-details .group{
    
            display: inline-block;
            font-size: 12px;
            padding: 7px 4px;
            border-right: 1px solid hsla(0, 0%, 67.1%, .3);
            width: 47%;
            float: left;

  }
  .propertyname {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
  }
  .text-bold{
    font-weight:bold
  }
  .ticketinfo{
    font-weight:bold;
  }
  .user-name, name{
  font-weight: 600;
  font-size: 18px;
  line-height: 12px;
  }
  label {
  font-size: 13px;
  padding: 5px 0;
  font-weight: 500;
  }
  .user-counter-details .group-number{
  float:right;
  width: 47%;
  }
  .court-section {
  text-align: center;

  border-radius: 8px;
  margin: 2px 0px 0px 0px;
  }

  .col-list {
  display: table-cell !important;
  position:relative;

  }
  table{
    width:98%;
  }
  .counter-area .group{
  display: inline-block;
  font-size: 12px;
  padding: 7px 4px;
  border-right: 1px solid rgba(171, 171, 171, 0.3);
  }
  .counter-area .group-name{
  display: inline-block;
  font-size: 12px;
  padding: 7px 4px;
  }
  .left-bor {

  }
  }`;

export const chitPrintStyles = `  @media print {
  body{
    margin:0px;
  }
  .chit-print__print-section {
    width: 106mm;

    font-size: 17px;
  }
  .chit-print__print-eachPageEnabled {
    color: black;
    width: 105mm !important;
    border: 1px solid;
    display: block !important;
    padding: 5px;
    margin-bottom: 120px !important;
    break-inside: avoid;
  }

  .chit-print__print-eachPageEnabled p {
    margin-bottom: 0px;
    overflow: hidden;
  }

  .chit-print__print-eachPageEnabled i {
    float: right;
    color: #0d0d0d;
    font-size: 19px;
    font-weight: 900;
    margin-right: 5px;
    margin-top: 4px;
  }
  .chit-print__print-eachPageDisabled {
    color: black;
    font-weight: 500;
    width: 51mm !important;    
    border: 1px solid;
    float: left;
    display: inline-block;
    padding-left: 5px;
    margin: 0px;
    height: 400px ;    
    flex-direction: column;
    break-inside: avoid;
  }
  
  .chit-print__print-eachPageDisabled p {
    margin-bottom: 0px !important;
    overflow: hidden;
    margin: 0;
    padding-top: 10px;
  }

  .chit-print__print-eachPageDisabled .chit-print__guest-info{
    overflow: hidden;
    height: calc(100% - 250px);
  }
  
  .chit-print__notes-section {
    line-height: 1.5em;
    height: 85px;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .chit-print__addons {
    line-height: 1.5em;
    height: 100px;
    overflow: hidden;
    margin-bottom: 5px;
  }
  .chit-print__print-eachPageDisabled i {
    float: right;
    color: #0d0d0d;
    font-size: 19px;
    font-weight: 900;
    margin-right: 5px;
    margin-top: 4px;
  }
  .print-container__print-section{
    color: black;
    width: 106mm !important;
    display: block !important;
    padding: 5px;
    margin-bottom: 120px !important;
    break-inside: avoid;
  }
}`

export const contactsSearch: DropDownConfig[] = [
  { id: 'contactDetails', value: 'Contact Details' },
  { id: 'confirmationCode', value: 'Confirmation/Ref #' }];

export const partyStatusBasedType = {
  [ReservationType.Reservation]: 'AppliesToReservations',
  [ReservationType.Waitlist]: 'AppliesToWalkIns'
}

export enum ScreenType {
  ReservationSetting = 1,
  ReservationMessaging,
  MerchantSetting


}

export const SessionStorageKeys = {
  ExpandAllShifts: 'expandAllShits',
  InactiveSessions: 'InactiveSessions'
}

export const chineseLanguageCodes = ['zh-s', 'zh-t'];

export const rateChanges = [FinancialEffectAction.Reprice, FinancialEffectAction.Refund, FinancialEffectAction.WaivedOff]

export const AnyMembers = 'AnyMembers';

export enum B2C_Constants {
  Authority = 'B2C_Authority',
  Tenant = 'B2C_Tenant',
  ClientID = 'B2C_ClientID',
  SignIn_SignUp_User_Flow = 'B2C_SignIn_SignUp_User_Flow',
  AzureADB2C_Token = 'AzureADB2C_Token',
  AzureADB2C_email = 'AzureADB2C_email',
  IsAzureB2CEnabled = 'IsAzureB2CEnabled'
}

export enum WristbandState {
  Active = 10,
  Inactive = 90
}