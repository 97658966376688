<section class="retail-setup h-100">
  <div class="h-100">
    <mat-horizontal-stepper linear="true" [attr.automationId]="'Ms_retailSetup_createItem'" (selectionChange)="StepperChange($event)" class="h-100 horizontal-stepper-class" #stepper>
      <ng-template matStepperIcon="edit">
        <i  aria-hidden="true" class="icon-done whitecolor IC12"></i>
      </ng-template>
      <!-- General -->
      <mat-step [completed]="_retailservice.GeneralFormGrp.valid" [label]="captions.GENERAL">
        <app-general [automationId]="'retailSetup'" [isEdit]="isEdit" [parentForm]="retailsetupParentForm"></app-general>
      </mat-step>
      <!-- Other Information -->
      <mat-step  [completed]="isValidOtherInfo && _retailservice.otherInfoFormGrp.valid"  [stepControl]="secondFormGrp" [label]="captions.OTHERINFORMATION" *ngIf="steperBool">
        <app-other-information [isExternalItem]="isExternalItem" [isEdit]="isEdit" [parentForm]="retailsetupParentForm"></app-other-information>
      </mat-step>
      <!-- Inventory -->
      <mat-step [completed]="isValidInventory &&  _retailservice.InventoryGrp.valid" [stepControl]="thirdFormGrp" [label]="captions.Inventory" [selectedIndex]="stepper.selectedIndex == 2" *ngIf=" _retailservice.GeneralFormGrp.controls['inventory'].value &&  _retailservice.GeneralFormGrp.controls['itemcatradiogrp'].value != 10 && !isExternalItem">
        <app-inventory [isEdit]="isEdit" [useInventory]="useInventory" [parentForm]="retailsetupParentForm"></app-inventory>
      </mat-step>
      <!-- Packaged Items -->
      <mat-step [stepControl]="fourthFormGrp" [label]="captions.PACKAGEDITEMS" *ngIf="_retailservice.otherInfoFormGrp.controls['groupKey'].value && _retailservice.GeneralFormGrp.controls['itemcatradiogrp'].value != 10">
        <app-packaged-items [isEdit]="isEdit" [parentForm]="retailsetupParentForm"></app-packaged-items>
      </mat-step>
      <!-- Rental Items -->
      <mat-step [label]="captions.RentalInformation" *ngIf=" _retailservice.GeneralFormGrp.controls['itemcatradiogrp'].value == 14">
        <app-rental-information [isEdit]="isEdit" [parentForm]="retailsetupParentForm"></app-rental-information>
      </mat-step>
      <!-- Multi Pack -->
      <mat-step [stepControl]="fourthFormGrp" [label]="captions.MULTIPACK" *ngIf="_retailservice.otherInfoFormGrp.controls['multipack'].value &&  _retailservice.GeneralFormGrp.controls['itemcatradiogrp'].value != 10">
        <app-multi-pack [isEdit]="isEdit" [parentForm]="retailsetupParentForm"></app-multi-pack>
      </mat-step>
      <!-- Commission Setup -->
      <mat-step [label]="captions.commission" *ngIf="_retailservice.GeneralFormGrp.controls['commissionableitem'].value">
        <app-retail-commission-setup [isEdit]="isEdit" [fromScreen]="'retailsetup'" (closeRetailDialog)="closeDialog($event)" (isCommissionModified)="CommissionModified($event)"></app-retail-commission-setup>
      </mat-step>
      <mat-step *ngIf = "showRetailSetupRoomTypesAssignmentTab" [label]="captions.tab_title_roomTypeAssignment">
        <app-room-type-assignment [roomTypes]="roomTypes | async" (roomTypeSelection)="roomTypeSelection($event)">
        </app-room-type-assignment>
        <mat-dialog-actions class="float-right actionButtons pr-4">
          <button [attr.automationId]="'Btn_retailSetup_cancel'" mat-button type="button" (click)="closeDialog($event)">{{commonCaptions.CANCEL}}</button>
          <button [attr.automationId]="'Btn_retailSetup_previous'" mat-button matStepperPrevious type="button"
            class="LW14 text_capitalize  body-bordercolor">{{commonCaptions.PREVIOUS}}</button>
          <button [attr.automationId]="'Btn_retailSetup_save'"mat-button type="button" (click)="Save()" color="primary"
            [ngClass]="validateBtn ? 'button_valid' : 'button_invalid'"
            class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{isEdit? commonCaptions.Update:
              commonCaptions.SAVE}}</button>
        </mat-dialog-actions>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</section>
