import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';

@Component({
  selector: 'app-retail-transaction-log-report',
  templateUrl: './transaction-log-report.component.html',
  styleUrls: ['./transaction-log-report.component.scss']
})
export class RetailTransactionLogReportComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  TransactionLogFormGrp: UntypedFormGroup;
  @Input() outlets: any;
  minDate : Date;
  captions: any;
  placeHolderFormat: string;
  floatLabel: string;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization) { 
    this.captions = this.localization.captions.common;
    this.placeHolderFormat = this.localization.inputDateFormat;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.TransactionLogFormGrp = this.fb.group({
      logtype: '',
      transactionid: '',
      firstname: '',
      lastname: '',
      date: '',
      appointmentid: '',
      userid: '',
      otherscannedcode: ''
    });
    this.formReady.emit(this.TransactionLogFormGrp);
  }
 
  formInitialized(form: UntypedFormGroup) {
    this.TransactionLogFormGrp.setControl('transactionlog', form);
  }
}
