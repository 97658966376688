import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Localization } from '../../../localization/localization';

@Component({
  selector: 'app-common-table-header',
  templateUrl: './common-table-header.component.html',
  styleUrls: ['./common-table-header.component.scss']
})
export class commonTableHeaderComponent implements OnInit {
  @Input() searchText: any;
  @Input() enableToggleButton: boolean;
  @Input() enableTerminal: boolean;
  @Input() headerOptions: any;
  @Input() isCancelButtonAvailable: boolean;
  @Input() isRoleSetUpReadOnly: boolean;
  @Input() isValidRoleName: boolean;
  @Input() isViewOnly: boolean;
  @Input('setupFormGroup') public setupFormGroup: UntypedFormGroup;
  @Input() maxInputLength: number = 40;
  @Input() minInputLength: number = 0;
  @Input() active: string;
  @Input() cancelButtonName: string;
  @Input() errorText: string;
  @Input() headerButtonName: string;
  @Input() headerName: string;
  @Input() searchPlaceholderValue: string;
  @Input() setupName: string;
  @Input() terminalID: string;
  @Input() terminalMissing: string;
  @Input() isEditFlag;
  @Input() inlineEditFlag;
  @Output() inputChange: EventEmitter<any> = new EventEmitter();
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  @Output() buttonCancelClick: EventEmitter<any> = new EventEmitter();
  @Output() searchInputChange: EventEmitter<any> = new EventEmitter();
  @Output() searchKeyPress: EventEmitter<any> = new EventEmitter();
  @Output() resetSearchValue: EventEmitter<any> = new EventEmitter();
  @Output() validToggleState: EventEmitter<any> = new EventEmitter();
  @Output() validSelectState: EventEmitter<any> = new EventEmitter();
  @Input() skipValidation: boolean = true;
  @Input() showValidationErrPopup: boolean = false;

  captions: any;
  minmumValidationErrMsg: string;
  floatLabel: string;
  floatLabelNever: string;

  constructor(private localization: Localization) {
    this.captions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  ngOnInit() {
    this.minmumValidationErrMsg = this.localization.replacePlaceholders(this.captions.AtleastXcharactersneedtobeentered,["noOfCharacters"],[this.minInputLength]);
  }

  ngOnChanges() {
    this.inlineEditFlag ? this.setupFormGroup.controls['setupCodeName'].disable() : this.setupFormGroup.controls['setupCodeName'].enable();
  }

  checkValid(event) {
    this.inputChange.emit(event);
  }

  checkValidState(event) {
    this.validToggleState.emit(event);
  }

  checkSelectState(event) {
    this.validSelectState.emit(event);
  }

  onButtonClick() {
    this.buttonClick.emit();
  }

  onButtonCancelClick(event) {
    this.buttonCancelClick.emit(event);
  }

  searchValue() {
    this.searchKeyPress.emit();
  }

  searchValueChanged(event) {
    this.searchInputChange.emit(event);
  }

  resetValue() {
    this.resetSearchValue.emit();
  }

  terminalChange(event) {
    this.checkSelectState(event);
  }
}
