import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CacheService } from '@core/services/cache.service';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { buttonTypes, ComponentTypes, filterType, PricingBy, Status } from '@app/shared/constants/commonenums';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@app/shared/services/settings.service';
import { FormatSettingsDatePipe } from '@app/shared/pipes/format-settings-date.pipe';
import { SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { PopupService } from '@popup-module/popup.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivityCreateFunctions } from '@app/shared/utilities/activity-create-functions';
import { groupBy } from 'lodash';
import { PartyService } from '@app/shared/services/party.service';

@Component({
  selector: 'app-activity-templates',
  templateUrl: './activity-templates.component.html',
  styleUrls: ['./activity-templates.component.scss']
})
export class ActivityTemplatesComponent  extends Utilities implements OnInit {
  tableDataSource: MatTableDataSource<{}>;
  activities: any[] = [];
  createButton: ButtonValue;
  cancelButton: ButtonValue;
  showActivityCreation: boolean = false;
  subscriptions: Subscription = new Subscription();
  basicSettings: any;
  tableData: any;
  settings: SettingsDTO;
  isEdit: boolean;
  showActivitiesList: boolean = false;
  constructor(public dialog: MatDialog,private cs: CacheService, private ts: TranslateService, public ss: SettingsService, private fs: FormatSettingsDatePipe,private activityCreateFunctions: ActivityCreateFunctions,private partyService: PartyService) { 
    super(dialog);
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this.settings = sett;
      // this.activities = sett.SpecialMeals;
    }));
  }

  ngOnInit() {
    // this.partyService.redirectPath = 'settings/templates';
    this.GetLessonRestaurant();
    this.loadConfig();
  }
  GetLessonRestaurant() {
    this.ss.GetActivityTemplates().subscribe(res => {
      this.activities = res?.Payload || [];
      this.showActivitiesList = true;
      this.loadTableData();
    })
  }
  loadTableData(filteredData?) {
    let tableHeader = [ "TemplateCode","templateName", "GroupSizeRange", "activityType", "reservationtype", "PaymentType","Status", "Action"];
    let tableBody = [];
    let activities = filteredData || this.activities;
    activities.map(data =>
      tableBody.push({
       "TemplateCode":data.ActivityCode || '-',
        "templateName": data.Name,
        // "DateRange": this.fs.transform(data.StartDate, this.settings.General.DateFormat) + " to " + this.fs.transform(data.EndDate, this.settings.General.DateFormat),
        "GroupSizeRange": data.MinPartySize + " - " + data.MaxPartySize,
        "activityType": this.activityCreateFunctions.getActivityType(data.ClassType),
        "reservationtype": data.IsForStandbyReservations ? this.ts.instant('standby') : this.ts.instant('confirmed'),
        "PaymentType": this.activityCreateFunctions.getPrePaymentMode(data.PrePaymentMode),
        "Status":  data.StatusCode == Status.Approved ? true : false,
        "ActivityID":data.Id,
        "start":new Date(data.StartDate),
        "end":new Date(data.EndDate)

      }))

    this.tableData = {
      header: tableHeader,
      body: tableBody
    }
  }

  setFilterItem(filterMenu?) {
    let filtersByType = groupBy(filterMenu.selection, 'key')
    let filteredActivity = this.activities || [];
    let StatusCode = filtersByType[filterType.StatusCode]?.map(status => { return status.value })
    let ClassType = filtersByType[filterType.ClassType]?.map(status => { return status.value })
    let StandbyReservations = filtersByType[filterType.StandbyReservations]?.map(status => { return status.value })
    let PrePaymentMode = filtersByType[filterType.PrePaymentMode]?.map(status => { return status.value })
    filteredActivity = filteredActivity?.filter(activity => {
      return (StatusCode ? StatusCode.includes(activity.StatusCode) : true) 
      && (ClassType ? ClassType.includes(activity.ClassType) : true) 
      && (StandbyReservations ? StandbyReservations.includes(activity.IsForStandbyReservations) : true)
      && (PrePaymentMode ? PrePaymentMode.includes(activity.PrePaymentMode) : true);
    });
    this.loadTableData(filteredActivity);
  }

  loadStatusConfig(StatusCode, id) {
    return [
      {
        type: 'switch',
        name: id,
        label: '',
        inputType: 'text',
        class: 'status-switch',
        checked: StatusCode == Status.Approved ? true : false,
        value: StatusCode == Status.Approved ? true : false
      }
    ];
  }

  createActivity(data) {
    this.isEdit = data?.isEdit || false;
    this.showActivityCreation = false;
    this.subscriptions.add(this.ss.GetLessonByIdAndRestaurant(data?.id || 0).subscribe(data => {
      this.basicSettings = data.Payload;
      this.showActivityCreation = true;
    }))
  }
  removeActivity(data?) {
    if (data) {
      this.subscriptions.add(this.ss.RemoveLesson(data.id).subscribe(data => {
        console.log(data);
        this.GetLessonRestaurant();
      }));
    }
  }
  cancelActivity(value?) {
    if (value) {
      this.GetLessonRestaurant();
    }
    this.showActivityCreation = false;
  }
  refreshActivity(value){
    if(value){
      this.GetLessonRestaurant();
      this.showActivityCreation = false;
    }
  }
  loadConfig() {
    this.createButton = {
      type: buttonTypes.actionPrimary,
      label: this.ts.instant('addNewTemplate'),
      disbaledproperity: false,
      customclass: 'action-bar__add-class-btn',
      icon:"icon-add"
    };
    this.cancelButton = {
      type: buttonTypes.actionPrimarySmall,
      label: this.ts.instant('Cancel'),
      disbaledproperity: false,
      customclass: 'action-bar__add-class-btn',
    };
  }
  ngOnDestroy() {
    if(this.partyService.redirectPath)
    this.partyService.redirectPath = '';
  }
}
