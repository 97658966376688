import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import _ from 'lodash';
import { Observable, of, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ButtonValue, IntegrationOperationsTransactionType, OrphanPayments } from 'src/app/retail/retail.modals';
import { RetailService } from 'src/app/retail/retail.service';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { TransactionsLogSearchRequest } from '../retail-transactions-log/retail-transactions-log';
import { OrphanPaymentsBusiness } from './orphan-payments.business';

@Component({
  selector: 'app-orphan-payments',
  templateUrl: './orphan-payments.component.html',
  styleUrls: ['./orphan-payments.component.scss'],
  providers: [OrphanPaymentsBusiness]
})
export class OrphanPaymentsComponent implements OnInit {
  commonCaptions: any;
  captions: any;
  transcForm: UntypedFormGroup;
  searchText = '';
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  originalData$: Promise<any[]>;
  originalData: OrphanPayments[];
  tableContent: Observable<any[]>;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  searchButton: ButtonValue;
  linkdisabled = false;
  isAllSelected: boolean;
  userList: any[] = [];
  showLoader = false;
  floatLabel: string;
  loaderCaptions: any;

  constructor(private fb: UntypedFormBuilder
    , private localization: RetailLocalization
    , private orphanPaymentsBusiness: OrphanPaymentsBusiness
    , public propertyInfo: RetailPropertyInformation
    , private _utils: RetailUtilities
    , public _ams: RetailService
  ) {
    this.captions = this.localization.captions.shop;
    this.commonCaptions = this.localization.captions.common;
    this.loaderCaptions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.formGenerator()
    this.tableOptions = this.orphanPaymentsBusiness.getTableOptions();
    this.headerOptions = this.orphanPaymentsBusiness.getHeaderOptions();    
    
    this.searchButton = {
      type: 'primary',
      label: this.captions.search,
      disabledproperty: false
    }
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.reset,
      disabledproperty: true
    }
    this.transcForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.searchButton.disabledproperty = !(this.transcForm.valid && this.transcForm.dirty);
      this.cancelButton.disabledproperty = !(this.transcForm.valid && this.transcForm.dirty);
    });
    this.AddLoaderSubscription();
    this.getTableData();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  AddLoaderSubscription() {
    this._ams.loaderEnable.pipe(takeUntil(this.destroyed$)).subscribe(loader => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
          loadingContainerMessage.innerText = loader;
        }
        else {
          loadingContainer.style.display = 'none';
          loadingContainerMessage.innerText = '';
        }
      }
    });
  }

  formGenerator() {
    this.transcForm = this.fb.group({
      fromDate: this.propertyInfo.CurrentDate,
      toDate: this.propertyInfo.CurrentDate,
      tableData: '',
      searchByTicket: '',
      searchByClerkID: '',
      searchByAmount: ''
    })
  }

  fromDate(e) {
    const startDate = this.localization.getDate(this.transcForm.controls.fromDate.value);
    const endDate = this.localization.getDate(this.transcForm.controls.toDate.value);
    if (startDate >= endDate) {
      this.transcForm.controls.toDate.setValue(startDate);
    }
  }

  toDate(e) {
    const startDate = this.localization.getDate(this.transcForm.controls.fromDate.value);
    const endDate = this.localization.getDate(this.transcForm.controls.toDate.value);
    if (startDate >= endDate) {
      this.transcForm.controls.fromDate.setValue(endDate);
    }
  }

  async getTableData() {
    this.showLoader = true;
    this.originalData = [];
    const clerkIds = this.transcForm.controls.searchByClerkID.value ?
      this.userList.filter(x => (x.userName.toLowerCase().includes(this.transcForm.controls.searchByClerkID.value.toLowerCase()))) : [];
    const request: TransactionsLogSearchRequest = {
      startDate: this.localization.ConvertDateToISODateTime(this.transcForm.controls.fromDate.value),
      endDate: this.localization.ConvertDateToISODateTime(this.transcForm.controls.toDate.value),
      ticketNumber: this.transcForm.controls.searchByTicket.value,
      amount: this.localization.currencyToSQLFormat(this.transcForm.controls.searchByAmount.value),
      clerkIdList: clerkIds ? clerkIds.map(x => x.id) : [],
      pageStart: 0
    }
    this.originalData$ = this.orphanPaymentsBusiness.getTabledata(request);
    this.originalData$.then(response => {
      this.AdditionalPropertyMapper(response).then(() => {
        this.originalData = response;
        this.tableContent = of(response);
        this.transcForm.get('tableData').setValue(response);
        this.showLoader = false;
        this.searchButton.disabledproperty = false;
      });
    }).catch(ex => {
      console.log(ex);
      this.showLoader = false;
      this.searchButton.disabledproperty = false;
    });
  }

  async AdditionalPropertyMapper(response: OrphanPayments[]) {
    let clerkIds = Array.from(new Set(...[response.map(x => Number(x.createdBy))]));
    let clerkDetails = await this.orphanPaymentsBusiness.getClerkDetail(clerkIds);
    const retailTypes = [
      IntegrationOperationsTransactionType.CreditCardSalePaymentForRetailTransaction,
      IntegrationOperationsTransactionType.CreditCardCreditPaymentForRetailTransaction,
      IntegrationOperationsTransactionType.CreditCardRefundPaymentForRetailTransaction,
      IntegrationOperationsTransactionType.CreditCardVoidPaymentForRetailTransaction,
      IntegrationOperationsTransactionType.GiftCardTopupForRetailTransaction,
      IntegrationOperationsTransactionType.GiftCardRedemptionForRetailTransaction,
      IntegrationOperationsTransactionType.GiftCardIssueForRetailTransaction
    ];
    const transTypeMap = {
      [IntegrationOperationsTransactionType.CreditCardSalePaymentForRetailTransaction]: this.localization.captions.lbl_sale,
      [IntegrationOperationsTransactionType.CreditCardCreditPaymentForRetailTransaction]: this.localization.captions.lbl_credit,
      [IntegrationOperationsTransactionType.CreditCardAuthForFolioPosting]: this.localization.captions.lbl_auth
    };
    if (clerkDetails && clerkDetails.length > 0) {
      response.map(x => {
        x.userName = clerkDetails.find(c => c.userId == x.createdBy)?.userName;
        x.origin = retailTypes.includes(x.type) ? this.localization.captions.lbl_retail : this.localization.captions.lbl_folio;
        x.transactionType = transTypeMap[x.type] ? transTypeMap[x.type] : "Other";
        x.amountFormatted = this.localization.localizeCurrency(x.amount);
        x.paymentMethod = this.localization.captions.shop.paymentMethods[x.tenderId];
        x.status = x.status == "Completed" ? "Yet To be Reversed": x.status;
        x.reversedDTM = x.reversedDTM == "0001-01-01T00:00:00" ? "" : x.reversedDTM;
      });
    }
  }

  resetSearch(event?: any): void {
    this.formGenerator();
    this.getTableData();
  }

}
