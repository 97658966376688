<ng-container>
    <app-dialog-header [title]="dialogData?.header" (closeDialog)="close($event)"></app-dialog-header>
</ng-container>

<div class="vip-wrapper">
    <div class="ag_modal--form-height">
        <div *ngIf = "showClose">
            <app-warning-bar [message]="message" *ngIf="isCopy" (closeWarning)="hideWarning()" [showClose]="showClose">
            </app-warning-bar>
        </div>
        <form class="VIPType-formwrapper ag_container--padding-md" [formGroup]="vipTypeForm" autocomplete="off">
           <div class="ag_form-control--3">
                <ag-textbox [config]="codeTextInputConfig"></ag-textbox>
            </div>
        <div class="ag_form-control--3">
            <ag-textbox [config]="nameTextInputConfig"></ag-textbox>
        </div>
        <div class="ag_form-control--3">
            <ag-textbox [config]="listOrderInput"></ag-textbox>
        </div>
        <!-- <div>
            <mat-checkbox class="form-group ag_checkbox" formControlName="includeOnDailyReport" [attr.LiteralID]="'VIPTypelbl_IncludeOnDailyReport'" >
                {{captions.VIPTypelbl_IncludeOnDailyReport}}
            </mat-checkbox>
        </div> -->
        <div>
            <!-- <mat-checkbox class="form-group" formControlName="isActive" (change)='switchActive()' 
            [attr.LiteralID]="'VIPType_grid_hdr_IsActive'" >{{captions.VIPType_grid_hdr_IsActive}}
            </mat-checkbox>
             -->
             <label class="ag_display--block">{{captions.VIPType_grid_hdr_IsActive}}</label>
            <app-toggle-switch class="form-group" formControlName="isActive" [attr.automationId]="'VIPType_grid_hdr_IsActive'"
                       
                        (changeToggleEvent)="switchActive()">
                    </app-toggle-switch>

        </div>
        <input type="submit" [disabled]="actionButton.disabledproperty" class="hidden-submit"
        tabindex="-1"  (click)="onAction($event)"/>
    </form>

    </div>
    <div class="vip-footer ag_footer--actions">
        <app-button [attr.automationId]="'Btn_vipType_action'" [buttontype]="actionButton" (valueChange)='onAction($event)'></app-button>
        <app-button [attr.automationId]="'Btn_vipType_cancel'" [buttontype]="cancelButton" class="ag-ml-2" (valueChange)='onCancel($event)'></app-button>
    </div>
</div>
