<mat-form-field [ngClass]="className" [formGroup]="form" appearance="legacy" [floatLabel]="floatLabel">
  <mat-label>{{placeHolder}}</mat-label>
  <input matInput [min]="minDate" dateRestricter [max]="maxDate" [matDatepicker]="datePicker" (dateChange)="dateChanged($event)" (input)="inputDateChanged($event)" [placeholder]="placeHolderFormat" [value]="value" [formControlName]="formControlName" [hidden]="hidden" attr.automationId='Txt_{{automationId}}_{{formControlName}}'>
 <mat-datepicker-toggle matSuffix [for]="datePicker">
   <i  aria-hidden="true" class="icon-calendar" matDatepickerToggleIcon></i>
 </mat-datepicker-toggle>
 <mat-datepicker #datePicker></mat-datepicker> 
 <mat-error *ngIf="form.controls[formControlName].hasError('matDatepickerParse')">{{captionsLocalization.dateFormat}}</mat-error>   
 <mat-error *ngIf="form.controls[formControlName].hasError('required') && !form.controls[formControlName].hasError('matDatepickerParse')">{{errorMessage}}</mat-error>
 <mat-error *ngIf="form.controls[formControlName].hasError('matDatepickerMin')">{{captionsLocalization.minimum}} {{formControlName}}</mat-error>
 <mat-error *ngIf="form.controls[formControlName].hasError('matDatepickerMax')">{{captionsLocalization.maximum}} {{formControlName}}</mat-error>
</mat-form-field>