<div class="booking-container">


    <div class="booking-manager__header">
        <div class="title">
            {{sessionDetail?.Name}}
        </div>
        <div class="booking-actions">
            <div class="actions-list" *ngIf="sessionDetail?.AllowReAssignAsset || sessionDetail?.AllowCancel"><span
                    class="icon-settings" mat-button [matMenuTriggerFor]="menu"></span>
                <mat-menu #menu="matMenu">
                    <button *ngIf="sessionDetail?.AllowReAssignAsset" mat-menu-item
                        (click)="bookingActions(_MESSAGES.labels.ReAssignAsset)">{{_MESSAGES.labels.ReAssignAsset |
                        translate}}</button>
                    <button *ngIf="sessionDetail?.AllowCancel" mat-menu-item
                        (click)="bookingActions(_MESSAGES.labels.cancelThisActivity)">{{_MESSAGES.labels.cancelThisActivity
                        | translate}}</button>
                </mat-menu>
            </div>
            <div class="actions-list" *ngIf="sessionDetail?.AllowBlock">
                <activities-app-button [buttontype]="blockButton"
                    (valueChange)="bookingActions(_MESSAGES.labels.block)">
                </activities-app-button>
            </div>
            <div class="actions-list" *ngIf="sessionDetail?.AllowUnblock">
                <activities-app-button [buttontype]="unBlockButton" (click)="bookingActions(_MESSAGES.labels.unBlock)">
                </activities-app-button>
            </div>
            <div class="actions-list">
                <activities-app-button [buttontype]="bookButton"
                    (valueChange)='bookingActions(_MESSAGES.labels.book)'></activities-app-button>
            </div>
            <div class="actions-list">
                <activities-app-button *ngIf="filterData.isPrivateLessonBooking && allReservations?.length === 1 && allReservations[0].State == PartyState.Pending" [buttontype]="assignStaffButton" (valueChange)="assignInstructor()">
                </activities-app-button>
            </div>
            <div class="actions-list"> <span class="icon-close" (click)="close()"></span></div>

        </div>

    </div>
    <div class="class-detail__filter">
        <div class="class-detail__search">
            <div class="actions-list">
                <div class="display_filter">
                    <a [ngbPopover]="filterPopover" placement="auto" [autoClose]="'outside'" container="body">
                        <span class="icon-Filter"><span class="path1"></span><span class="path2"></span><span
                                class="path3"></span></span>
                    </a>
                    <ng-template #filterPopover>
                        <app-filter-content [displayOptions]="displayOptions" [isMessageAvailable]="true"
                            (optionSelection)="filterReservations($event)"></app-filter-content>
                    </ng-template>
                </div>
            </div>
            <div #searchConfigFormContainer class="actions-list search-input"><dynamic-form style="width:100%" [config]="searchconfig"
                    #searchConfigForm>
                </dynamic-form></div>

        </div>
    </div>
    <div class="booking-manager">
        <div class="collapse-icon expandManu" (click)="expandMode=!expandMode" [hidden]="!expandMode">
            <span class="icon-Shrink"><span class="path1"></span><span class="path3"></span></span>
        </div>
        <div class="collapse-icon menuIcon" (click)="expandMode=!expandMode" [hidden]="expandMode">
            <span class="icon-Expand"><span class="path1"></span><span class="path3"></span></span>
            <!-- <div class="session-name">
                {{sessionDetail?.Name}}
            </div> -->
        </div>
        <div class="booking-manager__booking-details" [hidden]="!expandMode">
            <app-booking-details [_settings]="_settings" [filterData]="filterData"
                [actionEventChanges]="actionEventChanges" [allReservations]="allReservations"
                (bookingActions)="bookingActionsController($event)"></app-booking-details>
        </div>
        <!-- <div class="booking-manager__filter">

        <div class="filter-details">
            <ng-scrollbar>
                <div>
                    <mat-accordion class="example-headers-align" multi *ngIf="showFilter">
                        <mat-expansion-panel *ngFor="let item of filterList">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-checkbox (change)="selectActivity($event.checked , item)"
                                        [checked]="item.selected" (click)="$event.stopPropagation();">
                                        {{item.Name}}
                                    </mat-checkbox>

                                </mat-panel-title>
          
                            </mat-expansion-panel-header>
                            <div class="sessions" *ngFor="let slot of item.ActivitySessions">
                                <div class="slot" [ngClass]="{'selected' : slot.selected}">
                                    <div (click)="selectSlot(slot)"> {{ slot.StartTime }}
                                        - {{ slot.EndTime }}</div>
                                    <div (click)="viewSessionDetails(slot)"><span class="icon-Path-502"></span></div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-scrollbar>
            <div class="filter-actions">
                <activities-app-button [buttontype]=" cancelButton"
                    (valueChange)='resetFilter()'></activities-app-button>
                <activities-app-button [buttontype]="applyButton" (valueChange)='applyFilter()'></activities-app-button>

            </div>
        </div>
    </div> -->

        <div class="booking-manager__booking-list" [ngClass]="{'full-screen' : !expandMode}">
            <mat-tab-group animationDuration="400ms">
                <mat-tab [selectedIndex]="0">
                    <ng-template mat-tab-label>
                        <div class="count">{{'Reservations' | translate}} <span>{{parties?.length | number:
                                '2.0-0'}}</span>
                        </div>
                    </ng-template>
                    <ng-scrollbar track="all">
                        <app-booking-list
                            [reservations]="parties  | attendeesFilter : searchAttendeesText | filterByState : optionsForFilter"
                            [settings]="_settings" [mappedGuestFields]="mappedGuestFields" [searchText]="searchAttendeesText"
                            [FloorPlans]="cs?.layout?.value?.FloorPlans" [showTimeColumn]="showReservationTimeColumn"
                            [showAssetIcon]="showAssetIcon" [filterData]="filterData"></app-booking-list>
                    </ng-scrollbar>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="count standby">{{'StandByList' | translate}}<span>{{standByParties?.length | number:
                                '2.0-0'}}</span> </div>
                    </ng-template>
                    <ng-scrollbar track="all">
                        <div class="booking-list">
                            <app-booking-list
                                [reservations]="standByParties | attendeesFilter : searchAttendeesText | filterByState : optionsForFilter"
                                [settings]="_settings" [mappedGuestFields]="mappedGuestFields"
                                [FloorPlans]="cs?.layout?.value?.FloorPlans"
                                [showTimeColumn]="showReservationTimeColumn"
                                [searchText]="searchAttendeesText"
                                [filterData]="filterData"
                                [showAssetIcon]="showAssetIcon"></app-booking-list>

                        </div>
                    </ng-scrollbar> </mat-tab>
                <input matInput placeholder="Search tabs">
            </mat-tab-group>


        </div>


    </div>
</div>