import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { AgDropdownConfig, AgFieldConfig, AgInputFieldConfig, AgToggleConfig, ButtonValue, DropdownOptions } from 'src/app/common/Models/ag-models';
import { ActionMode } from 'src/app/common/shared/shared/enums/enums';
import { PaymentMethod } from 'src/app/common/shared/shared/setupConstants';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { PaymentMethodBusiness } from '../payment-methods.business';
import { CreatePaymentMethodsBusiness } from './create-payment-methods.business';
import { TaxType } from 'src/app/common/shared/shared/setupConstants';
import { PaymentMethods } from '../../shared/business/shared.modals';


@Component({
  selector: 'app-create-payment-method',
  templateUrl: './create-payment-method.component.html',
  styleUrls: ['./create-payment-method.component.scss'],
  providers: [CreatePaymentMethodsBusiness, PaymentMethodBusiness],
  encapsulation: ViewEncapsulation.None
})
export class CreatePaymentMethodComponent implements OnInit {
  paymentMethodsInputs;
  createPaymentMethodForm: UntypedFormGroup;
  captions: any;
  codeMinLength: number;
  codeMaxLength: number;
  nameMinLength: number;
  nameMaxLength: number;
  creditMaxLength: number;
  centralResvEquivalentMaxLength: number;
  listOrderMinLength: number = 1;
  listOrderMaxLength: number = 5;
  listOrderMaxValue: number = 99999;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  isCopy: boolean;
  showClassNameAlert: any;
  changeInputValues: any = '';
  formDisabledProperty = false;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  showMsg: boolean = false;
  paymentMethodCode: AgInputFieldConfig;
  centralReservationEquivalent: AgInputFieldConfig;
  paymentMethodName: AgInputFieldConfig;
  postType: AgDropdownConfig;
  creditCardType: AgDropdownConfig;
  listOrderInput: AgFieldConfig;
  creditCard: AgFieldConfig;
  surPercentInput: AgFieldConfig;
  surAmountInput: AgFieldConfig;
  currency: string;
  useSpecialGuarantee: AgToggleConfig;
  paymentMethodsCheckbox: { id: string; value: any; }[];
  phoneRestrictions: { id: number; value: any; }[];
  paymentTypes: DropdownOptions[] = [];
  searchPostTypeLength: number;
  postTypeOptions: any[] = [];
  isFocusSearchEmitRequired: boolean = true;
  masterPostTypes: any[] = [];
  isChipDisabled: boolean;
  selectedData: any[] = [];
  searchKey: string[] = ['id', 'name'];
  autoCompleteKeys: string[] = ['name'];
  selectedChipKey: string[] = ['name'];
  selectedNewChipKey: string[] = ['name'];
  surchargeType: { id: number; value: any; }[];
  setChipError: boolean;
  headerdata: string;
  isViewOnly: boolean;
  paymentTypeId: any;
  isPercentage: boolean = true;
  isSurchargeEnabled: boolean = false;
  showSurcharge: boolean = false;
  ccTypeId: number;
  fromFolio: boolean =false;
  additionalInfoForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
    private localization: RetailLocalization,
    private retailUtilities: RetailUtilities,
    private paymentBusiness: PaymentMethodBusiness,
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<CreatePaymentMethodComponent>,
    private business: CreatePaymentMethodsBusiness
  ) {
    this.paymentMethodsInputs = data.datarecord;
    this.headerdata = data.headername;
    this.isViewOnly = data.isViewOnly;
    this.captions = this.localization.captions;
    this.currency = this.localization.currencySymbol;
    this.fromFolio = data.fromFolio;
  }

  ngOnInit() {
    this.buildForm();
    this.getValidators();
    this.pageInitialization();
    // this.update();
  }

  update() {
    this.isCopy = this.paymentMethodsInputs.isCopy;
    if (this.isCopy) {
      this.showClassNameAlert = this.localization.replacePlaceholders(this.captions.lbl_copyText, ['code'], [this.paymentMethodsInputs.code]);
    }
    if ((this.paymentMethodsInputs.mode === ActionMode.update || this.paymentMethodsInputs.mode === ActionMode.copy)
      && this.paymentMethodsInputs.form) {
      const data = this.paymentMethodsInputs.form;
      if (this.paymentMethodsInputs.mode === ActionMode.copy) {
        data.code = "";
        data.id = 0;
      }
      this.bindForm(data);
    }
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  formStatusChangeEvent(e) {
    if (this.paymentMethodsInputs.mode === ActionMode.copy) {
      this.actionButton.disabledproperty = this.paymentMethodsInputs.isViewOnly ? true : !(e.formValid);
    } else {
      this.actionButton.disabledproperty = this.paymentMethodsInputs.isViewOnly ? true : !(e.formValid && e.objectNotEqual);
      this.formDisabledProperty = !(e.formValid && e.objectNotEqual);
    }
  }

  hideWarning() {
    this.isCopy = false;
  }

  onCancel(event) {
    const clickReturnValue = {
      from: ActionMode.cancel,
      formValues: this.createPaymentMethodForm.value
    };
    this.dialogRef.close(undefined);
  }

  async onAction(event) {
    this.retailUtilities.ToggleLoader(true,this.captions.lbl_processing);
    if (this.isSurchargeEnabled) this.assignSurchargeValue();
    const clickReturnValue = {
      from: this.paymentMethodsInputs.mode,
      formValues: this.createPaymentMethodForm.value,
      paymentTypeId: this.paymentTypeId,
      retailPayments: (<DropdownOptions[]>(await this.creditCardType.selectOptions)).map(x => x.otherData),
      // additionalConfig: this.additionalInfoForm.controls.additionalInfoFormArray.value
    };
    this.dialogRef.close(clickReturnValue);
    this.retailUtilities.ToggleLoader(false);
  }

  buildForm() {
    this.createPaymentMethodForm = this.fb.group({
      id: 0,
      code: ['', Validators.required],
      name: ['', Validators.required],
      ccTypeId: '',
      postTypeId: '',
      centralResvEquivalent: '',
      listOrder: '',
      creditLimit: '',
      isActive: true,
      isUseSpecialGuarantee: false,
      isRestrictPOSChanges: false,
      isInterfacePostToCheckoutFolio: false,
      isMovieRestrictions: true,
      isActivateInterface: true,
      phoneRestrictions: 0,
      paymentRefId: 0,
      isSurcharge: false,
      isPercent: 1,
      surchargePercent: 0,
      surchargeAmount: 0,
      surchargeValue: 0,
      requireComments: false
    });
    if(this.fromFolio){
      this.createPaymentMethodForm.controls.postTypeId.setValidators([Validators.required]);
      this.createPaymentMethodForm.controls.postTypeId.updateValueAndValidity();
    }
    this.additionalInfoForm = this.fb.group({
      additionalInfoFormArray: this.fb.array([
        this.fb.group({
          Key:'',
          Value:''
        })
      ]),
      data: {}
    });
  }

  bindForm(data) {
    this.createPaymentMethodForm.patchValue(
      {
        id: data.id,
        code: data.code,
        name: data.name,
        listOrder: data.listOrder,
        isActive: data.isActive,
        centralResvEquivalent: data.centralResvEquivalent,
        postTypeId: data.postTypeId,
        phoneRestrictions: data.phoneRestrictions,
        ccTypeId: data.ccTypeId,
        creditLimit: data.creditLimit,
        isUseSpecialGuarantee: data.isUseSpecialGuarantee,
        isRestrictPOSChanges: data.isRestrictPOSChanges,
        isInterfacePostToCheckoutFolio: data.isInterfacePostToCheckoutFolio,
        isActivateInterface: data.isActivateInterface,
        isMovieRestrictions: data.isMovieRestrictions,
        paymentRefId: data.paymentRefId,
        surchargeValue: data.surchargeValue,
        surchargeAmount: data.isPercent ? 0 : data.surchargeValue,
        surchargePercent: data.isPercent ? data.surchargeValue : 0,
        isSurcharge: data.isSurcharge,
        isPercent: data.isPercent ? 1 : 0,
        requireComments: data.requireComments ?? false
      }
    );
    this.isPercentage = data.isPercent;
    this.isSurchargeEnabled = data.isSurcharge;
    this.ccTypeId = data.ccTypeId;
  }

  pageInitialization() {
    this.paymentMethodsCheckbox = this.business.getpaymentMethodsCheckbox();
    this.phoneRestrictions = this.business.getPaymentMethodsRadioBox();
    this.surchargeType = this.business.getSurchargeType();
    this.actionButton = {
      type: 'primary',
      label: this.paymentMethodsInputs.actionButton,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
    this.paymentMethodCode = {
      className: 'ag_form-control--sm',
      errorMessageId: 'err_paymentMethodCode',
      errorMessage: this.captions.err_paymentMethodCode,
      form: this.createPaymentMethodForm,
      formControlName: 'code',
      placeHolderId: 'lbl_paymentMethodCode',
      placeHolder: this.captions.lbl_paymentMethodCode,
      maxlength: this.codeMaxLength,
      showRequired: true,
      inputType: 'noprespace,nospecailchar',
      changeLabelOnEdit: true,
      automationId: 'Txt_CreatePaymentMethod_code'
    };
    this.centralReservationEquivalent = {
      className: 'ag_form-control--sm',
      form: this.createPaymentMethodForm,
      formControlName: 'centralResvEquivalent',
      placeHolderId: 'lbl_centralReservationEquivalent',
      placeHolder: this.captions.lbl_centralReservationEquivalent,
      maxlength: this.centralResvEquivalentMaxLength,
      showRequired: false,
      inputType: 'noprespace,nospecailchar',
      automationId: 'Txt_CreatePaymentMethod_centralResvEquivalent'
    }
    this.paymentMethodName = {
      className: 'ag_form-control--sm',
      errorMessageId: 'err_paymentMethodName',
      errorMessage: this.captions.err_paymentMethodName,
      form: this.createPaymentMethodForm,
      formControlName: 'name',
      placeHolderId: 'lbl_paymentMethodName',
      placeHolder: this.captions.lbl_paymentMethodName,
      maxlength: this.nameMaxLength,
      showRequired: true,
      inputType: 'noprespace',
      automationId: 'Txt_CreatePaymentMethod_paymentMethodName'
    };
    this.listOrderInput = {
      className: 'ag_form-control--sm',
      form: this.createPaymentMethodForm,
      formControlName: 'listOrder',
      placeHolderId: 'lbl_listOrder',
      placeHolder: this.captions.lbl_listOrder,
      maxValue: this.listOrderMaxValue,
      minValue: this.listOrderMinLength,
      maxlength: this.listOrderMaxLength,
      isRequired: false,
      automationId: 'Txt_CreatePaymentMethod_listOrder'
    };
    this.creditCard = {
      className: 'ag_form-control--sm',
      form: this.createPaymentMethodForm,
      formControlName: 'creditLimit',
      maxlength: this.creditMaxLength,
      placeHolderId: 'lbl_creditCardLimit} (${this.currency})`',
      placeHolder: `${this.captions.lbl_creditCardLimit}`,
      automationId: 'Txt_CreatePaymentMethod_creditLimit'
    };
    this.useSpecialGuarantee = {
      group: this.createPaymentMethodForm,
      formControlName: 'isUseSpecialGuarantee',
      label: this.captions.lbl_useSpecialGuarantee,
      disabled: false
    };
    this.surPercentInput = {
      className: 'ag_form-control--lg',
      form: this.createPaymentMethodForm,
      formControlName: 'surchargePercent',
      maxlength: 2,
      placeHolderId: 'lbl_percent',
      placeHolder: this.captions.lbl_percent,
      errorMessageId: 'err_percentage',
      errorMessage: this.captions.err_percentage,
      isRequired: false,
      minValue: TaxType.TAXPERCENT_MINVALUE,
      maxValue: TaxType.TAXPERCENT_MAXVALUE,
      automationId: 'Txt_CreatePaymentMethod_surchargePercent'
    };
    this.surAmountInput = {
      className: 'ag_form-control--lg',
      form: this.createPaymentMethodForm,
      formControlName: 'surchargeAmount',
      placeHolderId: 'lbl_flatAmount',
      placeHolder: this.captions.lbl_flatAmount,
      maxlength: 10,
      errorMessageId: 'err_missingFlatAmount',
      errorMessage: this.captions.err_missingFlatAmount,
      isRequired: false,
      minValue: TaxType.TAXPERCENT_MINVALUE,
      automationId: 'Txt_CreatePaymentMethod_flatAmount'
    };
    this.creditCardType = {
      className: 'ag_form-control--sm',
      form: this.createPaymentMethodForm,
      formControlName: 'ccTypeId',
      isFirstEmpty: true,
      placeHolderId: 'lbl_paymentType',
      placeHolder: this.captions.lbl_paymentType,
      selectOptions: Promise.resolve([]),
      showRequired: true,
      automationId:"'Dd_createPaymentMethod_ccTypeId'",
    };

    this.bindDropdownValues();
    this.editAction();
  }

  bindDropdownValues() {
    if (this.paymentMethodsInputs.mode === ActionMode.create) {
      this.createPaymentMethodForm.patchValue({
        listOrder: this.paymentMethodsInputs.listOrder
      });
    }
    this.retailUtilities.ToggleLoader(true);
    let togFlag = 0;
    this.paymentBusiness.getPostTypeOptions().then(x => {
      this.postTypeOptions = x;
      if ((this.paymentMethodsInputs.mode === ActionMode.update || this.paymentMethodsInputs.mode === ActionMode.copy) && this.paymentMethodsInputs.form.postTypeId > 0) {
        this.createPaymentMethodForm.get('postTypeId').setValue(this.paymentMethodsInputs.form.postTypeId);
        let postType = this.postTypeOptions.filter(res => res.id == this.paymentMethodsInputs.form.postTypeId);
        if (postType.length > 0) {
          this.selectedData = [{ id: postType[0].id, name: `${postType[0].postTypeNumber} - ${postType[0].postTypeName}` }];
        }
      }
      togFlag++;
      if (togFlag == 2) this.retailUtilities.ToggleLoader(false);
    }).catch(ex => {
      togFlag++;
      if (togFlag == 2) this.retailUtilities.ToggleLoader(false);
    });
    this.paymentBusiness.getPaymentMethodByProduct(this.fromFolio).then(res => {
      this.paymentTypes = res;
      this.creditCardType.selectOptions = Promise.resolve(this.paymentTypes);
      this.creditCardType = { ...this.creditCardType };
      if (this.paymentMethodsInputs.mode === ActionMode.update || this.paymentMethodsInputs.mode === ActionMode.copy) {
        const paymentTypeData = this.paymentTypes.find(x => x.id === this.ccTypeId);
        this.createPaymentMethodForm.patchValue({ ccTypeId: paymentTypeData });
        this.paymentTypeId = paymentTypeData?.otherData?.paymentTypeId;
        this.showSurcharge = this.retailUtilities.GetOriginalTenderId(paymentTypeData?.otherData?.paymentTypeId, paymentTypeData?.otherData?.parentTypeId) == PaymentMethods.CreditCard;
      }
      togFlag++;
      if (togFlag == 2) this.retailUtilities.ToggleLoader(false);
    }).catch(ex => {
      togFlag++;
      if (togFlag == 2) this.retailUtilities.ToggleLoader(false);
    });    
  }

  enableSurcharge(eve) {
    this.resetSurcharge();
    if (eve) {
      this.isSurchargeEnabled = true;
    } else {
      this.isSurchargeEnabled = false;
    }
  }

  changeSurchargeType(eve) {
    this.createPaymentMethodForm.patchValue({ surchargeValue: Number(0) });
    if (eve.value == 1) {
      this.isPercentage = true;
    } else {
      this.isPercentage = false;
    }
    this.assignSurchargeValue();
  }

  assignSurchargeValue() {
    if (this.isPercentage) {
      const surchargePercent = this.localization.currencyToSQLFormat(this.createPaymentMethodForm.controls.surchargePercent.value);
      this.createPaymentMethodForm.patchValue({ surchargeValue: Number(surchargePercent) });
    } else {
      const surchargeAmount = this.localization.currencyToSQLFormat(this.createPaymentMethodForm.controls.surchargeAmount.value);
      this.createPaymentMethodForm.patchValue({ surchargeValue: Number(surchargeAmount) });
    }
  }

  editAction() {
    if (this.paymentMethodsInputs.mode === ActionMode.update) {
      this.paymentMethodCode.isEditMode = true;
      this.paymentMethodCode = { ...this.paymentMethodCode };
    }
    this.update();
  }

  getValidators() {
    this.codeMinLength = PaymentMethod.PAYMENTMETHODCODE_MINLENGTH;
    this.codeMaxLength = PaymentMethod.PAYMENTMETHODCODE_MAXLENGTH;
    this.nameMinLength = PaymentMethod.PAYMENTMETHODNAME_MINLENGTH;
    this.nameMaxLength = PaymentMethod.PAYMENTMETHODNAME_MAXLENGTH;
    this.creditMaxLength = PaymentMethod.PAYMENTMETHODCREDIT_MAXLENGTH;
    this.centralResvEquivalentMaxLength = PaymentMethod.PAYMENTMETHODCRV_MAXLENGTH;
    this.searchPostTypeLength = PaymentMethod.SEARCH_POSTTYPE_MAXLENGTH;
  }

  SearchTextHandler(arg) {
    this.searchMasterPostTypes(arg);
  }

  searchMasterPostTypes(pattern: string) {
    pattern = (pattern ? pattern.toLowerCase() : "").trim();
    this.masterPostTypes = [];
    if (this.postTypeOptions && this.postTypeOptions.length > 0) {
      this.masterPostTypes = this.postTypeOptions ? this.postTypeOptions.filter(x => x.postTypeName.toLowerCase().includes(pattern) ||
        x.postTypeNumber.toString().toLowerCase().includes(pattern)).map(x => {
          return {
            id: x.id,
            name: `${x.postTypeNumber} - ${x.postTypeName}`
          };
        }) : [];
    }
    else {
      this.retailUtilities.showError(this.captions.err_searchMasterPostType);
      this.isFocusSearchEmitRequired = false;
    }
  }

  selectedChipDataEmit(event) {
    let postType = 0;
    if (event && event.length > 0) {
      postType = event[0].id ? event[0].id : 0;
      this.actionButton.disabledproperty = false;
      this.createPaymentMethodForm.get('postTypeId').setValue(postType);
      this.createPaymentMethodForm.markAsDirty();
    }
    this.masterPostTypes = [];
  }

  onChipRemoveEvent(event) {
    this.createPaymentMethodForm.get('postTypeId').setValue('');
    this.masterPostTypes = [];
  }

  creditCardTypeChange(e) {
    this.resetSurcharge();
    this.paymentTypeId = e.otherData.paymentTypeId;
    if (e.otherData.paymentTypeId == 2) {
      this.showSurcharge = true;
    } else {
      this.showSurcharge = false;
    }
  }

  resetSurcharge() {
    this.isSurchargeEnabled = false;
    this.isPercentage = true;
    this.createPaymentMethodForm.patchValue(
      {
        isSurcharge: this.isSurchargeEnabled,
        isPercent: 1,
        surchargeAmount: 0,
        surchargePercent: 0,
        surchargeValue: 0
      }
    );
  }

  close(e) {
    this.dialogRef.close();
  }
  addArray(e) {
    const form = this.fb.group({
    Key:'',
    Value:''
    });
    const control = this.additionalInfoForm.get('additionalInfoFormArray') as UntypedFormArray;
    control.push(form);
  }

  removeArray(e) {
    const control = this.additionalInfoForm.get('additionalInfoFormArray') as UntypedFormArray;
    control.removeAt(e)
  }
}
