<section class="category-linking category-linking-wrapper newVersionWrapper">
    <div class="option-row">
        <div class="option-col">
            <mat-radio-group [attr.automationId]="'Rdo_CategoryLinking_category'" aria-label="Select an option" (change)="changeCategory($event)" class="radio-class golf__radio">
                <mat-radio-button value="1" [checked]="true" class="golf--form-radio-button">{{this.captions.retailCategory}}</mat-radio-button>
                <mat-radio-button value="2" class="golf--form-radio-button">{{this.captions.retailSubCategory}}</mat-radio-button>
              </mat-radio-group>
        </div>
        <div class="button-save" [ngClass]="{'button-subcategory': ifshowSelectCategory}">
            <button [attr.automationId]="'Btn_CategoryLinking_categorySave'" (click)="categorySave()" class="mat-button save_style" [ngClass]="{'button--disabled': !selectCategory || IsViewOnly, 'button--primary-save': selectCategory && !IsViewOnly }">{{this.captions.SAVE}}</button>
        </div>
    </div>
    <div class="category-select layout" *ngIf="ifshowSelectCategory">
            <mat-form-field [floatLabel]="floatLabel" class="float-label-box">
                    <mat-select [attr.automationId]="'Dd_CategoryLinking_name'"  [(value)]="selectedDefault" (selectionChange)="changeSelection($event)">
                      <mat-option *ngFor="let subcategories of ( subCategoryoptionArray | slice: 0 : subCategoryoptionArray.length - 1 )" [value]="subcategories.id">
                        {{subcategories.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
    </div>
    <div class="inner-section-row" [ngClass]="{'inner-row-height-extend': ifshowSelectCategory}">
        <div class="category-col">
           <div class="category-header">
               <label>{{this.primaryCategoryLabel}}</label>
               <label>{{primaryCategoryArray.length}} {{this.captions.records}}</label>
           </div>
           <div class="category-body">
            <ng-scrollbar >
           <div widthcalculate class="category-records LW14 radio-class" *ngFor="let category of primaryCategoryArray | CategoryPipe" >
           <mat-radio-button [attr.automationId]="'Rdo_CategoryLinking_category'" [value]="category" [checked]="currentCategory == category.id"  [matTooltip]="category.name" (change)="categorySelect(category)">{{category.name}}</mat-radio-button>
            </div>
            </ng-scrollbar>
           </div>
        </div>
        <div class="sub-category-col">
                <div class="sub-category-header">
                        <label>{{this.secondaryCategoryLabel}}</label>
                        <label>{{ secondaryCategoryArray.length }} {{this.captions.records}}</label>
                    </div>
        <div class="sub-category-body">
        <ng-scrollbar >
            <div class="sub-category-records LW14" *ngFor="let subCategoryOne of secondaryCategoryArray | CategoryPipe">
                <span (click)="changeSubcategrory(subCategoryOne)"  class="sub-check-box" [ngClass]="{'body-color': subCategoryOne.isSelected, 'selected-circle': subCategoryOne.isSelected }">
                   <i  aria-hidden="true" class="icon-done" [ngClass]="{'category-selected-icon': subCategoryOne.isSelected}"></i>
                </span>
                <label (click)="changeSubcategrory(subCategoryOne)">{{subCategoryOne.name}}</label>
            </div>
        </ng-scrollbar>
        </div>
        </div>

    </div>
</section>
