import { Input, Output, EventEmitter, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CacheService } from "@app/core/services/cache.service";
import { CustomPopupComponent } from "@app/popup-module/components/custom-popup/custom-popup.component";
import { PopupService } from "@app/popup-module/popup.service";
import { ConfirmationPopupComponent } from "@app/shared/components/confirmation-popup/confirmation-popup.component";
import { BlockingrulePopupservice } from "@app/shared/components/create-update-blocking-tab-layout/blockingrulepopup.service";
import { ComponentTypes, PartyState, TableBlockingRuleFrequencyType, ViewBy } from "@app/shared/constants/commonenums";
import { PartyDTO } from "@app/shared/models/InputContact";
import { SlotDTO } from "@app/shared/models/InputReservation";
import { UnblockingTablesRequestDTO } from "@app/shared/models/RestaurantDTO";
import { TableBlockingRuleDTO } from "@app/shared/models/TableBlockingDTO";
import { SettingsService } from "@app/shared/services/settings.service";
import { TablesService } from "@app/shared/services/tables.service";
import { Utilities } from "@app/shared/utilities/utilities";
import { TranslateService } from "@ngx-translate/core";
import _ from "lodash";
import moment, { Moment } from "moment";
import { ConfigDateFormatter } from "./activities-timeline-main.component";
import { QuickBlockLocationComponent } from "@app/shared/components/quick-block-location/quick-block-location.component";
import { DashboardFunctions } from "@app/shared/utilities/dashboard-functions";
import { tConvert } from "@app/pos/services/util.functions";

@Component({
  template: ''
})

export class SlotProcessor extends Utilities {

  @Input() timeArrayGenerated: Moment[];
  @Input() rowData: {
    Id: number;
    Name: string;
    CategoryId: number;
    BufferTimeInMinutes: number;
    OpenPrivateReservations: PartyDTO[];
    Sessions: any;
    StaffSchedule: any;
  };
  @Input() lastIndex: number;
  @Input() selectedViewId: number;
  @Input() OpenHours;
  @Input() TimeSlotUnitInMinutes: number;

  @Input() CurrentDateSelected: Date;
  @Input() switchAxis: boolean;

  @Input() noOfBookings: number;
  @Output('addBookingToSlot') addBookingToSlotEvent = new EventEmitter();
  @Output('activitySelected') activitySelectedEvent = new EventEmitter();
  @Output('promptBlockSession') promptBlockSessionEvent = new EventEmitter();
  @Output('modifySession') modifySessionEvent = new EventEmitter();
  @Output('cancelSession') cancelSessionEvent = new EventEmitter();
  @Output('blockStaff') blockStaffEvent = new EventEmitter();
  @Output('deleteStaffBlock') deleteStaffBlockEvent = new EventEmitter();

  allSessions: Group[];
  showExpandButton: boolean = false;
  expandMode: boolean = false;
  occupiedSlots: {
    [key: string]: boolean
  }

  constructor(protected dialog: MatDialog, protected blockingrule: BlockingrulePopupservice,
    protected ps: PopupService, protected settingservice: SettingsService, protected tableService: TablesService,
    protected cs: CacheService, protected translateService: TranslateService, protected dateFormatter: ConfigDateFormatter,
    protected dashboardFunctions: DashboardFunctions) { super(dialog); }


  setSessions() {
    let openSlots = this.getAllOpenBookingSlots(0, {});
    if (this.rowData) {
      let sessionReservations = this.getSessionsAndReservations();
      let slotsByReservation = this.getBufferSlots(this.rowData.BufferTimeInMinutes || 0, this.rowData.OpenPrivateReservations);
      let bufferSlots = slotsByReservation.filter(bSlot => bSlot.bufferSlot);
      // bufferSlots = this.mergeOverlapBufferSlots(bufferSlots);
      let newOpenSlots = slotsByReservation.filter(bSlot => !bSlot.bufferSlot);
      let [grouppedSessions, showExpandButton] = this.setGroups([..._.orderBy([...sessionReservations, ...bufferSlots], ['State', 'StartTime']), ..._.orderBy([...openSlots, ...newOpenSlots], ['StartTime'])]);
      this.allSessions = grouppedSessions;
      this.showExpandButton = showExpandButton;
    } else {
      this.allSessions = this.getAllOpenBookingSlots(0, this.occupiedSlots);
    }

  }

  mergeOverlapBufferSlots(slots): any[] {
    slots.sort((a, b) => (a.endTime > b.endTime ? 1 : -1));
    const mergedSlots = [];

    slots.forEach(slot => {
      const lastMergedSlot = mergedSlots[mergedSlots.length - 1];
      const shouldNotMerge = slot.StartTime === "00:00:00" || slot.EndTime === "00:00:00";

      if (lastMergedSlot && !shouldNotMerge && lastMergedSlot.EndTime >= slot.StartTime && this.getMinDifference(lastMergedSlot.EndTime, slot.StartTime) > this.rowData.BufferTimeInMinutes) {
        // Merge slots if no merge condition is triggered
        lastMergedSlot.EndTime = slot.EndTime > lastMergedSlot.EndTime ? slot.EndTime : lastMergedSlot.EndTime;
        lastMergedSlot.gridColumnEnd = Math.max(lastMergedSlot.gridColumnEnd, slot.gridColumnEnd);
        lastMergedSlot.timeInterval = this.calculateTimeInterval(lastMergedSlot.StartTime, lastMergedSlot.EndTime);
      } else {
        // If no overlap or cannot merge, add the current slot to the result
        slot.timeInterval = this.calculateTimeInterval(slot.StartTime, slot.EndTime);
        mergedSlots.push({ ...slot });
      }
    });

    return mergedSlots || [];
  }

  getMinDifference(start: string, end: string) {
    const startTime = new Date(`1970-01-01T${start}Z`);
    const endTime = new Date(`1970-01-01T${end}Z`);
    const diffMs = (endTime.getTime() - startTime.getTime() / (1000 * 60));
    return diffMs % 60;
  }

  calculateTimeInterval(startTime: string, endTime: string): string {
    const start = new Date(`1970-01-01T${startTime}Z`);
    const end = new Date(`1970-01-01T${endTime}Z`);
    const diffMs = end.getTime() - start.getTime();
    const diffMinutes = Math.floor(diffMs / (1000 * 60));

    // Calculate hours and remaining minutes
    const hours = Math.floor(diffMinutes / 60);
    const minutes = diffMinutes % 60;

    return `${hours > 0 ? hours + " hour" + (hours > 1 ? "s " : " ") : ""}${minutes > 0 ? minutes + " minute" + (minutes > 1 ? "s" : "") : ""}`;
  }

  getSessionsAndReservations() {
    let allSessions = this.rowData.Sessions ? this.rowData.Sessions.filter(({ privateActivity }) => !privateActivity) : [];
    let OpenPrivateReservations = this.rowData.OpenPrivateReservations ? this.rowData.OpenPrivateReservations : [];
    let StaffSchediules = this.rowData.StaffSchedule ? this.rowData.StaffSchedule : [];
    return [...allSessions, ...OpenPrivateReservations, ...StaffSchediules];
  }

  getBufferSlots(bufferTime, reservations) {
    let bufferSlots = [];
    if (!bufferTime) {
      return bufferSlots;
    }

    reservations.forEach(reservation => {
      if (reservation.State != PartyState.Cancelled && !reservation.BlockId) {
        //Before reservation Buffer
        let additionalSlotBeforeTime = new Date(reservation.StartTime);
        additionalSlotBeforeTime.setMinutes(additionalSlotBeforeTime.getMinutes() - bufferTime);
        let reservationTime = new Date(reservation.StartTime);
        reservationTime.setMinutes(reservationTime.getMinutes() - this.TimeSlotUnitInMinutes - bufferTime);
        if (this.timeArrayGenerated[1].diff(additionalSlotBeforeTime) <= 0 || moment(reservationTime) <= this.timeArrayGenerated[1]) {
          if (this.timeArrayGenerated[0].isAfter(additionalSlotBeforeTime)) {
            var additionalSlotBefore = this.getOpenSlotDataObj(this.timeArrayGenerated[0].toDate(), this.TimeSlotUnitInMinutes, true)[0];
          } else {
            additionalSlotBefore = this.getOpenSlotDataObj(additionalSlotBeforeTime, bufferTime, true)[0];
          }
          if (additionalSlotBefore.gridColumnStart != reservation.gridColumnStart) {
            bufferSlots.push(additionalSlotBefore);
          }
        }

        let additionalOpenSlotBeforeTime = new Date(additionalSlotBeforeTime);
        additionalOpenSlotBeforeTime.setMinutes(additionalOpenSlotBeforeTime.getMinutes() - this.TimeSlotUnitInMinutes - bufferTime);
        if (this.timeArrayGenerated[1].diff(additionalOpenSlotBeforeTime) <= 0) {
          let additionalOpenSlotBefore = this.getOpenSlotDataObj(additionalOpenSlotBeforeTime, this.TimeSlotUnitInMinutes, false)[0];
          bufferSlots.push(additionalOpenSlotBefore);
        }

        //After Reservation Buffer
        let bufferSlot = this.getOpenSlotDataObj(new Date(reservation.EndTime), bufferTime, true)[0];

        if (bufferSlot.gridColumnStart > reservation.gridColumnStart) {
          bufferSlots.push(bufferSlot);

          //New Open Slot
          let additionalSlotTime = new Date(bufferSlot.DateTime.split('T')[0] + 'T' + bufferSlot.EndTime);
          let additionalSlot = this.getOpenSlotDataObj(additionalSlotTime, this.TimeSlotUnitInMinutes, false)[0];

          bufferSlots.push(additionalSlot);
        }
      }
    });

    return bufferSlots;
  }

  activitySelected(slot, instructor) {
    // if(this.selectedViewId !== ViewBy.Instructor)
    if (!slot.openBookingSlot) {
      this.activitySelectedEvent.emit([slot, instructor]);
    }
  }

  addBookingToSlot(instructor, column, index) {
    this.addBookingToSlotEvent.emit([instructor, column, index])
  }

  promptBlockSession(activityData, item) {
    this.promptBlockSessionEvent.emit([activityData, item])
  }

  modifySession(activityData, item) {
    this.modifySessionEvent.emit([activityData, item])
  }
  cancelSession(activityData, item) {
    this.cancelSessionEvent.emit([activityData, item])
  }

  blockStaff(activityData, item) {
    this.blockStaffEvent.emit([activityData, item]);
  }
  addEditLocationBlock({blockData: data, type}: { blockData: any, type: 'quick' | 'advanced'}, rowData) {
    const requestedPayload = (data.blockData || {}) as TableBlockingRuleDTO;
    if (!data.blockData) {
      requestedPayload.StartDate = this.CurrentDateSelected;
      requestedPayload.EndDate = this.CurrentDateSelected;
      requestedPayload.Frequency = { Type: 1, DayOfWeek: 0, Date: this.CurrentDateSelected };
      requestedPayload.BlockBy = 0;
      requestedPayload.TableIds = [rowData.Id];
      requestedPayload.EffectiveRange = [
        { Start: data.StartTime, End: data.EndTime }
      ]
    }

    if(type === 'quick'){
      this.blockLocation(requestedPayload);
    }else{
      this.blockingrule.createTabsModalDetailsAdvanceedBlock(false, true, !!data.blockData, requestedPayload);
    }
  }

  blockLocation(tableBlockData: TableBlockingRuleDTO) {
    const popUpMessage = [{
      dialogTitle: this.translateService.instant('blockTable'),
      showAlert: false,
      tableBlockData: tableBlockData
    }];
    const componentInfo = Utilities.setComponentDetails(QuickBlockLocationComponent, 'medium', '', popUpMessage, popUpMessage[0].dialogTitle);
    let dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.quickBlock,
      '700px', 'auto', false, popUpMessage[0].dialogTitle, this.translateService.instant('save'), this.translateService.instant('Cancel'), true);
    dialogRef.afterClosed().subscribe((result: { action: "cancelled" | "confirmed", reqObj }) => {
      if (result?.action === "confirmed") {
        this.quickBlockHandler(result.reqObj);
      }
    });
  }

  quickBlockHandler(requestedPayload: TableBlockingRuleDTO){
    if (requestedPayload.Id) {
      this.settingservice.UpdateBlocks(requestedPayload).subscribe((response) => {
        this.dashboardFunctions.showSuccessPopup(this.translateService.instant('blockingSuccessMsgsForActivity'));
      });

    } else {

      this.settingservice.SetQuickBlock(requestedPayload).subscribe((response) => {        
        this.dashboardFunctions.showSuccessPopup(this.translateService.instant('blockingSuccessMsgsForActivity'));
      });
    }
  }

  deleteLocationBlock({ blockData }) {
    this.deleteLocationBlockConfirmation(blockData);
  }

  deleteLocationBlockConfirmation(selectedblocks: TableBlockingRuleDTO) {
    const popUpMessage = [{
      confirmationMessage: this.translateService.instant('deleteConfirmationText'),
      dialogTitle: this.translateService.instant("alert"),
      showAlert: false
    }];
    let componentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
    };

    let dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '300px',
      width: '500px',
      data: {
        title: this.translateService.instant('alert'),
        update: 'ok',
        cancel: 'cancel',
        componentDetails,
        back: false,
        from: ComponentTypes.deleteblockingrule,
        standalone: true
      }
    });
    let confirmedSubscription = this.ps.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.deleteblockingrule)
        this.settingservice.DeleteBlocks(selectedblocks.Id).subscribe((result) => {
          //this.ts.getBlocks();
        });
    });
    let cancelledSubscription = this.ps.cancelledAction$.subscribe(val => {
      this.tableService.shiftSelectedForBlockingRule = 0;
      this.tableService.getBlockingRulesFortheDate(new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)));
    });
    dialogRef.afterClosed().subscribe(() => {
      if (confirmedSubscription) { confirmedSubscription.unsubscribe(); }
      if (cancelledSubscription) { cancelledSubscription.unsubscribe(); }
    })


  }

  unblockLocation(blockData, rowData){
    let startTime = tConvert(blockData.EffectiveRange[0].Start);
    let endTime = tConvert(blockData.EffectiveRange[0].End);
      const popUpMessage = [{
        confirmationMessage: this.translateService.instant('Are you sure want to unblock the location', {locationNames: rowData.Name, unblockDate: this.dateFormatter.transform(this.CurrentDateSelected), startTime: startTime, endTime: endTime}),
        dialogTitle: this.translateService.instant("unblockbuttontext"),
        showAlert: false
      }];
      let componentDetails = {
        componentName: ConfirmationPopupComponent,
        popupType: 'active',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: popUpMessage,
      };
  
      let dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        height: '300px',
        width: '600px',
        data: {
          title: this.translateService.instant('alert'),
          update: 'ok',
          cancel: 'cancel',
          componentDetails,
          back: false,
          from: ComponentTypes.Unblock,
          standalone: true
        }
      });
      let confirmedSubscription = this.ps.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.Unblock)
          this.unblockLocationHanlder(blockData, rowData)
      });
      dialogRef.afterClosed().subscribe(() => {
        if (confirmedSubscription) { confirmedSubscription.unsubscribe(); }
      })
  
  }

  unblockLocationHanlder(blockData, rowData) {
    const requestedPayload = {} as UnblockingTablesRequestDTO;
    requestedPayload.Date = this.formatDate(this.CurrentDateSelected);
    requestedPayload.FrequencyType = TableBlockingRuleFrequencyType.TheOnlyDay;
    requestedPayload.TableIds = [rowData.Id];
    requestedPayload.RuleId = blockData?.Id;
    this.settingservice.SetUnblock(requestedPayload).subscribe((response) => {
      const selectedtableNames = rowData.Name;
      const msg = 'unblockingSuccessMsg';
      let message = '';
      this.translateService.get(msg, { selectedtableNames }).subscribe(msgVal => message = msgVal);
      
    });
  }

  deleteStaffBlock(activityData, item) {
    this.deleteStaffBlockEvent.emit([activityData, item]);
  }
  setGroups(allSessions): [Group[], false] {
    let groupItems: Group[] = [];
    let showExpandButton: boolean = false
    // allSessions = _.sortBy(allSessions, 'StartTime');
    allSessions.forEach(sessionItem => {

      if (!this.occupiedSlots) {
        this.occupiedSlots = {};
      }
      for (let idx = sessionItem.gridColumnStart; idx <= sessionItem.gridColumnEnd; idx++) {
        this.occupiedSlots[idx] = true;
      }

      let groupDetail = groupItems.find(groupItem => {
        return sessionItem.gridColumnStart === groupItem.gridColumnStart
          || sessionItem.gridColumnEnd === groupItem.gridColumnEnd
          || (sessionItem.gridColumnStart > groupItem.gridColumnStart && sessionItem.gridColumnStart < groupItem.gridColumnEnd)
          || (sessionItem.gridColumnStart <= groupItem.gridColumnStart && sessionItem.gridColumnEnd >= groupItem.gridColumnEnd)
          || (groupItem.gridColumnStart > sessionItem.gridColumnStart && groupItem.gridColumnStart < sessionItem.gridColumnEnd)
          || (groupItem.gridColumnStart <= sessionItem.gridColumnStart && groupItem.gridColumnEnd >= sessionItem.gridColumnEnd)
      })
      sessionItem.tempUniqValueData = [sessionItem.StartTime, sessionItem.Id].join(',');
      if (!groupDetail) {
        groupDetail = {
          gridColumnStart: sessionItem.gridColumnStart,
          gridColumnEnd: sessionItem.gridColumnEnd,
          items: [sessionItem],
          occupiedColumns: {},
          rows: 1,
          group: true,
          defaultMaxRowDisplay: 0
        }

        for (let col = sessionItem.gridColumnStart; col < sessionItem.gridColumnEnd; col++) {
          groupDetail.occupiedColumns["_" + col] = groupDetail.occupiedColumns["_" + col] ? groupDetail.occupiedColumns["_" + col] + 1 : 1;
        }

        groupItems.push(groupDetail);
      } else {

        if (!sessionItem.openBookingSlot || (sessionItem.openBookingSlot && this.hasOverlapSlots(groupDetail, sessionItem))) {

          if (groupDetail.gridColumnStart > sessionItem.gridColumnStart) groupDetail.gridColumnStart = sessionItem.gridColumnStart;
          if (groupDetail.gridColumnEnd < sessionItem.gridColumnEnd) groupDetail.gridColumnEnd = sessionItem.gridColumnEnd;
          for (let col = sessionItem.gridColumnStart; col < sessionItem.gridColumnEnd; col++) {
            groupDetail.occupiedColumns["_" + col] = groupDetail.occupiedColumns["_" + col] ? groupDetail.occupiedColumns["_" + col] + 1 : 1;
            if (groupDetail.occupiedColumns["_" + col] > groupDetail.rows) {
              groupDetail.rows = groupDetail.occupiedColumns["_" + col];
            }
          }
          groupDetail.items.push(sessionItem);
          if (groupDetail.items.filter(groupItem => !groupItem.openBookingSlot).length > 1) {
            showExpandButton = true;
          }

        }
      }

    });


    return [groupItems, showExpandButton];
  }

  hasOverlapSlots(groupDetail, sessionItem) {
    let activeBooking = groupDetail.items.find(groupItem => (((groupItem.State && groupItem.State != PartyState.Cancelled) || groupItem.State == PartyState.Pending)));
    let bufferOrDuplicateOpenSlot = this.hasOverlapBufferOrOpenSlots(groupDetail, sessionItem);
    return !(activeBooking || bufferOrDuplicateOpenSlot);
  }

  hasOverlapBufferOrOpenSlots(groupDetail, sessionItem) {
    if (sessionItem.bufferSlot) {
      let overlap = groupDetail.items.find(groupItem => {
        return groupItem.gridColumnStart === sessionItem.gridColumnStart && (groupItem.bufferSlot || !groupItem.openBookingSlot)
      })
      return overlap;
    } else if (sessionItem.openBookingSlot) {
      let overlap = groupDetail.items.find(groupItem => {
        return (groupItem.bufferSlot || groupItem.staffScheduleType || (groupItem.gridColumnStart === sessionItem.gridColumnStart && groupItem.openBookingSlot))
      })
      return overlap;
    }
  }

  getSlotIndex([classHour = 0, classMinutes = 0], timeArrayGenerated) {
    return timeArrayGenerated.findIndex((time) => time.minutes() === +classMinutes && time.hours() === +classHour);
  }

  getAllOpenBookingSlots(bufferTime = 0, occupiedSlots = {}) {
    let openBookingSlots = [];
    let shiftArray = _.cloneDeep(this.OpenHours);
    let date = this.CurrentDateSelected;
    let dayOfWeek = moment.isMoment(date) ? date.day() : date.getDay();
    let allShifts = shiftArray.filter(shift => shift.DayOfWeek == dayOfWeek);
    const Slotval = this.TimeSlotUnitInMinutes;

    if (allShifts && allShifts.length) {
      allShifts.sort((a: any, b: any) => {
        let _a: any = new Date(a.EffectiveRange.Start);
        let _b: any = new Date(b.EffectiveRange.Start);
        return _a - _b;
      })

      let Startval = allShifts[0].EffectiveRange.Start.toString().split('Z')[0];
      Startval = this.formatDate(date) + 'T' + Startval.split('T')[1];
      let endDate = new Date(this.CurrentDateSelected);
      if (new Date(allShifts[allShifts.length - 1].EffectiveRange.End).getDate() > new Date(allShifts[0].EffectiveRange.Start).getDate()) {
        endDate.setDate(date.getDate() + 1)
      }
      let Endvalue = allShifts[allShifts.length - 1].EffectiveRange.End.toString().split('Z')[0];
      const Endval = new Date(this.formatDate(endDate) + 'T' + Endvalue.split('T')[1]);
      let Startloop = new Date(Startval);
      let estEndLoop = new Date(Startval);
      estEndLoop.setMinutes(estEndLoop.getMinutes() + Slotval);

      while (estEndLoop <= Endval) {
        let [slot, startloop] = this.getOpenSlotDataObj(Startloop, Slotval);
        Startloop = startloop;

        let availableSlot = true;
        if (slot.gridColumnStart == slot.gridColumnEnd - 1) {
          if (occupiedSlots[slot.gridColumnStart] && occupiedSlots[slot.gridColumnEnd]) {
            availableSlot = false;
          }
        } else {
          for (let idx = slot.gridColumnStart + 1; idx < slot.gridColumnEnd; idx++) {
            if (occupiedSlots[idx]) {
              availableSlot = false;
              break;
            }
          }
        }
        if (availableSlot) {
          openBookingSlots.push(slot);
        }

        //Buffer slots
        if (Startloop <= Endval && bufferTime) {
          let [slot, startloop] = this.getOpenSlotDataObj(Startloop, bufferTime, true);
          Startloop = startloop;
          openBookingSlots.push(slot);
        }

        estEndLoop = new Date(startloop);
        estEndLoop.setMinutes(estEndLoop.getMinutes() + Slotval);
      }

      openBookingSlots.sort((a: any, b: any) => {
        let _a: any = new Date(a.startTime);
        let _b: any = new Date(b.startTime);
        return _a - _b;
      });
    }

    return openBookingSlots
  }

  getOpenSlotDataObj(Startloop, Slotval, bufferSlot?) {
    let slotObj;
    const tempTimeSlot = Startloop;
    const slot: SlotDTO = <SlotDTO>{};
    let slotTime = Utilities.Date(Startloop);
    slot.DateTime = slotTime.format('YYYY-MM-DDTHH:mm:ss');
    let endTime = new Date(slot.DateTime);
    endTime.setMinutes(endTime.getMinutes() + Slotval);
    let columnStartIndex = this.getSlotIndex([slotTime.hours(), +slotTime.minutes()], this.timeArrayGenerated);
    let gridColumnStart = columnStartIndex < 0 ? 1 : columnStartIndex + 1;
    let gridColumnEnd = this.getSlotIndex([endTime.getHours(), +endTime.getMinutes()], this.timeArrayGenerated) + 1;
    if (gridColumnEnd < gridColumnStart) {
      /** When Per slot time configuration is updated after some booking created */
      // let numOfSlot = ((endTime.getHours()*60) + endTime.getMinutes() - (slotTime.hours()*60) + slotTime.minutes()) / 5;
      // gridColumnEnd = gridColumnStart + numOfSlot;
      gridColumnEnd = this.timeArrayGenerated.length + 1;
    }
    slotObj = { StartTime: slotTime.format('HH:mm:ss'), EndTime: moment(endTime).format('HH:mm:ss'), bufferSlot, openBookingSlot: true, gridColumnStart, gridColumnEnd, DateTime: slot.DateTime, endTime: endTime };
    const newMinites = new Date(tempTimeSlot).getMinutes() + Slotval;
    const newDate = tempTimeSlot.setMinutes(newMinites);
    Startloop = new Date(newDate);

    return [slotObj, Startloop]
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
}

interface Group {
  gridColumnStart: number;
  gridColumnEnd: number,
  items: any[],
  occupiedColumns: {
    [key: string]: number
  },
  rows: number,
  group: boolean,
  defaultMaxRowDisplay: number
}