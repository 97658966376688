import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as myGlobals from '../shared/globalsContant';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { RetailLocalization } from '../common/localization/retail-localization';
import { HttpServiceCall, HttpMethod } from '../shared/service/http-call.service';
import { BaseResponse, PaymentMethods } from '../shared/business/shared.modals';
import { PaymentMethod } from '../retail.modals';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import {
  UntypedFormBuilder, UntypedFormGroup,
  Validators
} from '@angular/forms';
import { TableSearchHeader } from 'src/app/common/Models/ag-models';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CreatePaymentMethodComponent } from './create-payment-method/create-payment-method.component';
import { ActionMode } from 'src/app/common/shared/shared/enums/enums';
import { RetailFunctionalityBusiness } from '../shared/business/retail-functionality.business';
import { SPAConfig } from '../common/config/SPA-config';
import { PaymentMethodBusiness } from './payment-methods.business';
import { AlertType } from 'src/app/common/enums/shared-enums';
import { RetailFeatureFlagInformationService } from '../shared/service/retail.feature.flag.information.service';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { CreateCashEqivalentPaymentMethodComponent } from './create-cash-eqivalent-payment-method/create-cash-eqivalent-payment-method.component';
import { DatamagineConfigBusiness } from 'src/app/common/data-magine/data-magine-config/data-magine-config.business';
import { Product } from '../shared/globalsContant';
import { ActivatedRoute } from '@angular/router';
import { RetailRoutes } from '../retail-route';

@Component({
  selector: 'app-retail-payment-method',
  templateUrl: './retail-payment-method.component.html',
  styleUrls: ['./retail-payment-method.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SPAConfig, PaymentMethodBusiness]
})
export class RetailPaymentMethodComponent implements OnInit {
  tableoptions: any[];
  currIndex: any;
  captions: any = this.localization.captions.retailsetup;
  type: any;
  paymentMethod: PaymentMethod[] = [];
  FormGrp: UntypedFormGroup;

  PaymentMethodFormGroup: UntypedFormGroup = this.Form.group({
    paymentMethod: ['', Validators.required],
    activetoggle: true
  });
  headerButtonName: any;
  cancelButtonName: any;
  paymentMethods: any;
  active: any;
  get searchPlaceholderValue(): string {
    return this.functionalities?.IsFolioPaymentMethod ? this.localization.captions.retailsetup.PaymentMethodSearch
      : this.localization.captions.retailsetup.SearchByPaymentMethod
  }
  enableToggleButton = false;
  IGRetail = false;
  isCancelButtonAvailable = false;
  isValidRoleName = false;
  searchText = '';
  errorText = this.localization.captions.retailsetup.MissingPaymentMethod;
  isViewOnly = false;
  maxInputLength = 100;



  onEditDisableFunction: boolean = true;
  indexValue: any = [];
  // isEditFlag is used commonly toggle searchbar on edit and inline edit
  isEditFlag: boolean = false;
  // inlineEditOn is used to disable the form fields, update, cancel buttons on inline edit
  inlineEditOn: boolean = false;
  searchHeaderOption: TableSearchHeader = {} as TableSearchHeader;
  enableFeature: boolean = true;
  EditCaption: any;
  dialogSubscription: Subscription;
  functionalities: { [key: string]: boolean } = {};
  actionType: myGlobals.GridOperationType;
  sigCapProduts : number[] = [Product.SPA, Product.GOLF, Product.RETAIL];
  isSigCapEnabled: boolean =false;
  fromFolio: boolean = false;

  constructor(private retailSetup: RetailSetupService
    , private localization: RetailLocalization
    , private http: HttpServiceCall
    , private utils: RetailUtilities
    , private Form: UntypedFormBuilder
    , private dialog: MatDialog
    , private func: RetailFunctionalityBusiness
    , private featureFlagInfo: RetailFeatureFlagInformationService
    , private paymentMethodBusiness: PaymentMethodBusiness
    , private dataMagineConfigBusiness: DatamagineConfigBusiness
    , private route: ActivatedRoute
        ) {
    this.headerButtonName = this.localization.captions.setting.Add;
    this.cancelButtonName = this.localization.captions.retailsetup.CANCEL;
    this.paymentMethods = this.localization.captions.retailsetup.paymentMethods;
    this.active = this.localization.captions.retailsetup.Active;
    this.route.data.subscribe(v =>{
      this.fromFolio = v.fromFolio;
    });
  }

  ngOnInit() {
    this.captions = this.localization.captions.retailsetup;
    this.FormGrp = this.Form.group({
      PaymentMethod: []
    });
    this.RefreshGrid();
    this.isViewOnly = this.retailSetup?.retailSetupBreakPoints?.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.PaymentMethods)?.view;
    this.func.getRetailFunctionality().then(res => {
      this.utils.ToggleLoader(true,this.captions.lbl_processing);
      this.functionalities = res;
      this.setSearchHeaderOption(this.captions.btn_createPaymentMethod, this.searchPlaceholderValue);
      this.load();

    });
    let productId = Number(this.utils.GetPropertyInfo('ProductId'));
    if(this.sigCapProduts.includes(productId)){
      this.dataMagineConfigBusiness.GetDMConfig().then(dmConfig => {
        if (dmConfig && dmConfig?.enableDatamagine && dmConfig?.enableSignatureCapture){
          this.isSigCapEnabled = true;
        }
      });
    }
    
  }

  private load() {
    if (!(this.functionalities.IsFolioPaymentMethod || this.fromFolio)) {
      this.InvokeServiceCall("PaymentMethod", myGlobals.Host.payment, HttpMethod.Get);
    } else {
      const folioroute = this.featureFlagInfo.IsResortFinanceEnabled ? "GetPaymentMethodWithReferences" : "GetAllPaymentMethod";
      this.InvokeServiceCall(folioroute, myGlobals.Host.folio, HttpMethod.Get, { includeInactive: true });
    }
  }


  addPayment(data: any, e?: any) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    if (this.tableoptions && this.tableoptions.length > 0 && this.tableoptions[0].TablebodyData) {

      if (this.headerButtonName.toLowerCase() == this.localization.captions.setting.Add.toLowerCase()) {
        let newPayment: PaymentMethod = {
          "id": 0,
          "paymentTypeId": 0, // paymentTypeId is generated from API, hence it is set as 0
          isActive: true,
          requireComments: true,
          requireSignatureCapture: false,
          paymentMethod: data.controls.paymentMethod.value.trim(),
          listOrder: 0,
          isDefault: false,
          allowEarn: false,
          noOfReceipts: 0,
          requireReceipts: false,
          includeSignatureLine: true,
          isAutoRemoveTax: false,
        };
      }
      else if (this.headerButtonName.toLowerCase() == this.localization.captions.setting.update.toLowerCase()) {
        let newPayment: PaymentMethod = {
          "id": this.tableoptions[0].TablebodyData[this.currIndex].id,
          "paymentTypeId": this.tableoptions[0].TablebodyData[this.currIndex].paymentTypeId,
          isActive: data.controls.activetoggle.value,
          requireComments: true,
          requireSignatureCapture: false,
          paymentMethod: data.controls.paymentMethod.value.trim(),
          "listOrder": this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
          isDefault: false,
          allowEarn: false,
          noOfReceipts: 0,
          requireReceipts: false,
          includeSignatureLine: true,
          isAutoRemoveTax: false
        };
      }
      return true;
    }
  }
  onButtonClick() {
    this.addPayment(this.PaymentMethodFormGroup);
    this.headerButtonName = this.localization.captions.setting.Add;
    this.enableToggleButton = false;
    this.isCancelButtonAvailable = false;
    if (document.getElementsByClassName('rowDisabled').length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
    }
    this.isEditFlag = false;
  }

  searchValue(event) {
    this.searchText = event.target.value;
  }

  EditRecords(data?: any, type?: any) {
    const isCopy = (data?.[2] == 'copy');
    const actionMode = isCopy ? ActionMode.copy : ActionMode.update;
    const caption = isCopy ? this.captions.COPY_HEADER : this.captions.UPDATE;
    const gridOperation = isCopy ? myGlobals.GridOperationType.Copy : myGlobals.GridOperationType.Edit;
    let paymentMethodsInput = {
      mode: actionMode,
      actionButton: caption,
      form: data[0],
      listOrder: ''
    };
    this.openDialog(gridOperation, paymentMethodsInput);
  }

  async DeleteRecords(event: any) {
    setTimeout(()=>{
      this.utils.ToggleLoader(true,this.captions.lbl_processing);
    }, 1)
    if (this.tableoptions && this.tableoptions.length > 0 && this.tableoptions[0].TablebodyData) {
      let currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == event[0].id);
      this.tableoptions[0].TablebodyData.splice(currIndex, 1);
      this.tableoptions = [...this.tableoptions];
      if (!(this.functionalities.IsFolioPaymentMethod || this.fromFolio)) {
        this.InvokeServiceCall("PaymentMethodWithId", myGlobals.Host.payment, HttpMethod.Delete, { id: event[0].id });
        setTimeout(()=>{
          this.utils.ToggleLoader(false);
        }, 1)
      } else {
        await this.paymentMethodBusiness.delete(event[0].id);
        setTimeout(()=>{
          this.utils.ToggleLoader(false);
        }, 1)
      }
    }
  }
  sliderChange(event: any) {
    if (this.tableoptions && this.tableoptions.length > 0 && this.tableoptions[0].TablebodyData) {
      this.inlineEditOn = true;
      this.disableFormElements(this.inlineEditOn);
    }
  }

  Done(event) {
    if (event) {
      this.isEditFlag = false;
      this.inlineEditOn = false;
      this.enableFormElements(this.inlineEditOn);
      let body: PaymentMethod = event;
      if (!body.isActive) {
        let AllowPaymentMethodDeactivation = true;
        this.validatePaymentMethodDeactivation(body.paymentTypeId).then(result => {
          AllowPaymentMethodDeactivation = result;
          if (!AllowPaymentMethodDeactivation) {
            const errMsg = this.localization.getError(100714);
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
            this.InvokeServiceCall("PaymentMethod", myGlobals.Host.payment, HttpMethod.Get);
            return;
          } else {
            if (this.functionalities.IsFolioPaymentMethod || this.fromFolio) {
              this.paymentMethodBusiness.getPaymentMethodByProduct().then(retailPayment => {
                this.paymentMethodBusiness.update(event, event.ccTypeId, retailPayment).then(() => {
                  this.load();
                  this.utils.showCommonAlert('', AlertType.Success);
                }).catch((ex) => {
                  console.error(ex);
                  this.utils.showCommonAlert('', AlertType.Error);
                });
              });

            }
            else {
              this.InvokeServiceCall("PaymentMethodWithId", myGlobals.Host.payment, HttpMethod.Put, { id: body.id }, body);
            }

          }
        });
      }
      else {
        if (this.functionalities.IsFolioPaymentMethod || this.fromFolio) {
          this.paymentMethodBusiness.getPaymentMethodByProduct().then(retailPayment => {
            this.paymentMethodBusiness.update(event, event.ccTypeId, retailPayment).then(() => {
              this.load();
              this.utils.showCommonAlert('', AlertType.Success);
            }).catch((ex) => {
              console.error(ex);
              this.utils.showCommonAlert('', AlertType.Error);
            });
          });

        }
        else {
          this.InvokeServiceCall("PaymentMethodWithId", myGlobals.Host.payment, HttpMethod.Put, { id: body.id }, body);
        }
      }
    }
  }

  async dragDrop(event: any) {
    setTimeout(()=>{
      this.utils.ToggleLoader(true,this.captions.lbl_processing);
    }, 1)
    if (!(this.functionalities.IsFolioPaymentMethod || this.fromFolio)) {
      let params = { 'fromorder': event[0], 'toorder': event[1] }
      this.InvokeServiceCall("UpdatePaymentMethodListOrder", myGlobals.Host.payment, HttpMethod.Put, params);
    } else {
      const p = this.tableoptions[0].TablebodyData.find(x => x.listOrder === event[0]);
      p.listOrder = event[1];
      this.paymentMethodBusiness.updateListOrder(p).then(() => {
        this.load();
      });
    }
  }

  public async validatePaymentMethodDeactivation(paymentMethodId: PaymentMethods): Promise<boolean> {
    // Check if any transactions are associated with the paymentmethod
    let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
      callDesc: 'GetTransactionCountByPaymentMethod',
      host: myGlobals.Host.retailPOS,
      method: HttpMethod.Get,
      uriParams: { paymentMethodId: paymentMethodId }
    });

    if (!response.successStatus) {
      this.showError(response.errorCode);
    }
    let transactionCount = response.result;
    return (transactionCount === 0);
  }

  private showError(errorCode: number) {
    let errMsg = this.localization.getError(errorCode);
    this.utils.ShowErrorMessage("Error", errMsg);
  }

  InvokeServiceCall(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == 'PaymentMethod' || callDesc == "AddPaymentMethod" || callDesc == 'PaymentMethodWithId' || callDesc == 'UpdatePaymentMethodListOrder') {
      this.paymentMethod = <any>result.result;
      const HiddenPaymentMethods = [PaymentMethods.IDTECH, PaymentMethods.V1GiftCardIdTech, PaymentMethods.ExternalGiftCardIdTech, PaymentMethods.AgilysysGiftCardIdTech, PaymentMethods.RedeemPoint];
      this.paymentMethod = this.paymentMethod.filter(x => !HiddenPaymentMethods.includes(x.paymentTypeId) && !HiddenPaymentMethods.includes(x.parentTypeId)); // This is done inorder to hide 'IDTech' methods from UI

      this.paymentMethod.forEach((method) => {
        let paymentMethodName = myGlobals.PaymentMethodValue[method.paymentTypeId];
        if (method.isDefault && method.paymentMethod == paymentMethodName) {
          method.paymentMethod = this.localization.captions.shop.paymentMethods[method.paymentTypeId];
        } else {
          method.paymentMethod = method.paymentMethod;
        }
      });

      // let giftcardMethod = this.paymentMethod.find(x => x.paymentTypeId == PaymentMethods.ExternalGiftCard);
      // if (giftcardMethod && this.featureFlagInfo.GatewayType) {
      //   const paymentMethod = this.localization.replacePlaceholders(this.localization.captions.shop.paymentMethods[giftcardMethod.paymentTypeId], ["Third Party"], [this.featureFlagInfo.GatewayType]);
      //   giftcardMethod.paymentMethod = paymentMethod;
      // }

      if (callDesc == 'UpdatePaymentMethodListOrder') {
        this.indexValue = this.paymentMethod.map(element => {
          return element.id;
        });
      }
      this.enableToggleButton = false;
      this.RefreshGrid();
    }

    const mapPaymentMethods = () => {
      this.paymentMethod.forEach(pm => pm.isDefault = false);
      this.enableToggleButton = false;
      this.IGRetail = false;
      this.RefreshGrid();
    }
    if (callDesc === 'GetAllPaymentMethod') {
      this.paymentMethod = (<any>result.result).map(x => ({ ...x, paymentMethod: x.name }));
      mapPaymentMethods();
    }
    if (callDesc === 'GetPaymentMethodWithReferences') {
      let paymentMethodWithRefs = (<any>result.result);
      let paymentMethods: PaymentMethod[] = [];
      paymentMethodWithRefs.forEach(x => {
        const paymentServiceObj = x.paymentMethodServiceReferences.find(o => o.paymentMethodId == x.paymentMethod.id);
        x.paymentMethod.paymentTypeId = paymentServiceObj?.paymentTypeId;
        x.paymentMethod.paymentMethod = x.paymentMethod.name;
        paymentMethods.push(x.paymentMethod)
      })
      if (this.featureFlagInfo.IsResortFinanceEnabled) {
        this.paymentMethod = paymentMethods.filter(x => Object.values(myGlobals.ResortFinancePaymentMethods).includes(x.paymentTypeId));
      }
      else {
        this.paymentMethod = paymentMethods;
      }
      mapPaymentMethods();
    }
    this.utils.ToggleLoader(false);
  }

  onButtonCancelClick(event) {
    if (document.getElementsByClassName('rowDisabled').length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
    }
    this.enableToggleButton = false;
    this.isCancelButtonAvailable = false;
    this.headerButtonName = this.localization.captions.setting.Add;
    this.PaymentMethodFormGroup.reset();
    this.isEditFlag = false;
  }
  errorCallback<T>(): void {
    this.enableToggleButton = false;
    this.utils.ToggleLoader(false);
  }

  RefreshGrid() {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    let TableHeadders = [];

    if (this.featureFlagInfo.AllowAccrualPoints && Number(this.utils.GetPropertyInfo('ProductId')) != Product.PMS) {
      this.tableoptions = [
        {
          TableHdrData: [
            { "title": this.paymentMethods, "jsonkey": "paymentMethod", "sortable": true, "searchable": true },
            { "title": this.captions.Active, "jsonkey": "isActive", type: "toggle", "sortable": true, "searchable": false },
            { "title": this.captions.AllowEarn, "jsonkey": "allowEarn", type: "toggle", "sortable": true, "searchable": false },
            { "title": this.captions.ListOrder, "jsonkey": "listOrder", "alignType": "right", "sortable": true, "searchable": false }],
          TablebodyData: this.paymentMethod,
          pagination: false,
          sortable: true,
          CustomColumn: true,
          PlaceHoldertext: this.captions.Search,
          EnableActions: true,
          SelectRows: true,
          IsCommission: true,
          Searchable: false,
          EditMoreOption: false,
          Editable: false,
          SelectedSettingId: myGlobals.GridType.outlet,
          Sortable: "listOrder",
          TableId: myGlobals.GridType.outlet,
          disableDelete: true,
          customHeader: false,
          pageTitle: 'payment',
          ServiceId: 'payment',
          TableDraggable: true,
          IsViewOnly: this.isViewOnly
        }
      ];
    }
    else {
      this.tableoptions = [
        {
          TableHdrData: [
            { "title": this.paymentMethods, "jsonkey": "paymentMethod", "sortable": true, "searchable": true },
            { "title": this.captions.Active, "jsonkey": "isActive", type: "toggle", "sortable": true, "searchable": false },
            { "title": this.captions.ListOrder, "jsonkey": "listOrder", "alignType": "right", "sortable": true, "searchable": false }],
          TablebodyData: this.paymentMethod,
          pagination: false,
          sortable: true,
          CustomColumn: true,
          PlaceHoldertext: this.captions.Search,
          EnableActions: true,
          SelectRows: true,
          IsCommission: true,
          Searchable: false,
          EditMoreOption: false,
          Editable: false,
          SelectedSettingId: myGlobals.GridType.outlet,
          Sortable: "listOrder",
          TableId: myGlobals.GridType.outlet,
          disableDelete: true,
          customHeader: false,
          pageTitle: 'payment',
          ServiceId: 'payment',
          TableDraggable: true,
          IsViewOnly: this.isViewOnly,
          automationId: 'retailPaymentMethod'
        }
      ];
    }
    this.PaymentMethodFormGroup.reset();
    if (document.getElementsByClassName("rowDisabled") && document.getElementsByClassName("rowDisabled").length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
    }
    this.utils.ToggleLoader(false);
  }

  resetValue() {
    this.searchText = '';
  }


  checkValid(event) {
    if (event && event.target && event.target.value.trim() !== '') {
      this.isValidRoleName = true;
    } else {
      this.isValidRoleName = false;
    }
  }

  Cancel(event) {
    this.isEditFlag = event;
    this.inlineEditOn = false;
    this.enableFormElements(this.inlineEditOn);
  }

  // Method to disable the form elements
  disableFormElements(isEditFlag) {
    this.PaymentMethodFormGroup.controls['paymentMethod'].disable();
    // This flag is set to false on inline edit mode to disable the ADD button.
    this.isValidRoleName = false;
  }

  checkValidState(event) {
    this.isValidRoleName = true;
  }
  enableFormElements(isEditFlag) {
    this.PaymentMethodFormGroup.controls['paymentMethod'].enable();
  }

  setSearchHeaderOption(createBtnLabel, searchPlaceHolder) {
    this.searchHeaderOption = {
      createBtnLabel,
      searchPlaceHolder,
      showInactive: false,
      createBtnDisabled: this.isViewOnly,
      hasHeaderAction: !this.functionalities.ShowCreatePaymentMethod,
      hideActionBtn: !this.functionalities.ShowCreatePaymentMethod,
      automationId: "Srch_paymentMethods_payementSearch"
    };
  }

  searchChange(e) {
    this.searchText = e;
  }

  async createEvent(e) {
    let existingListOrders: number[] = [];
    this.paymentMethod.forEach(m => existingListOrders.push(m.listOrder));
    const nextListOrder = existingListOrders && existingListOrders.length > 0 ? Math.max(...existingListOrders) + 1 :1;
    let paymentMethodsInput = {
      mode: ActionMode.create,
      actionButton: this.captions.btn_create,
      form: undefined,
      listOrder: nextListOrder,
      noOfReceipts: this.paymentMethodBusiness.defaultNoOfReceipt
    };
    this.openDialog(myGlobals.GridOperationType.Create, paymentMethodsInput);
  }

  openDialog(preTit: any, data?: any): any {
    this.isEditFlag = true;
    this.actionType = preTit;
    const popupTitleCaptionMap = {
      [myGlobals.GridOperationType.Edit]: this.localization.captions.common.EDIT,
      [myGlobals.GridOperationType.Copy]: this.localization.captions.common.COPY_HEADER,
    }
    this.EditCaption = (popupTitleCaptionMap[preTit]) ?
      popupTitleCaptionMap[preTit]
      : this.localization.captions.common.pg_title_create;
    let afterClosed = true;
    if (this.functionalities.PaymentMethodWithFolioDetails || this.fromFolio) {
      return this.triggerPopup(afterClosed, data);
    } else {
      return this.triggerCreateCashEquiavalentMethodPopup(data);
    }
  }


  triggerPopup(afterClosed = true, data: any): any {
    const Dialogtitle = this.captions.pg_title_PaymentMethod;
    const dialogRef = this.dialog.open(CreatePaymentMethodComponent, {
      width: '90%',
      maxWidth: "1024px",
      height: "80%",
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data: {
        headername: this.EditCaption + '  ' + Dialogtitle,
        closebool: true,
        isViewOnly: this.isViewOnly,
        datarecord: data,
        fromFolio: this.fromFolio
      },
      disableClose: true
    });

    if (afterClosed) {
    
      this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
        this.isEditFlag = false;
        if (result) {
        
          if (this.actionType === myGlobals.GridOperationType.Create) {
            this.paymentMethodBusiness.create(result.formValues, result.paymentTypeId, result.retailPayments).then(() => {
              this.load();
              this.utils.showCommonAlert('', AlertType.Success);
              setTimeout(()=>{
                this.utils.ToggleLoader(false);
              }, 1)
            }).catch((ex) => {
              console.error(ex);
              this.utils.showCommonAlert('', AlertType.Error);
              setTimeout(()=>{
                this.utils.ToggleLoader(false);
              }, 1)
            });
          } else if (this.actionType === myGlobals.GridOperationType.Edit) {
            this.paymentMethodBusiness.update(result.formValues, result.paymentTypeId, result.retailPayments).then(() => {
              this.load();
              this.utils.showCommonAlert('', AlertType.Success);
              setTimeout(()=>{
                this.utils.ToggleLoader(false);
              }, 1)
            }).catch((ex) => {
              console.error(ex);
              this.utils.showCommonAlert('', AlertType.Error);
              setTimeout(()=>{
                this.utils.ToggleLoader(false);
              }, 1)
            });
          }
        }
        if (document.getElementsByClassName("rowDisabled") && document.getElementsByClassName("rowDisabled").length > 0) {
          document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
          document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
        }
      });
    }
    return dialogRef;
  }

  triggerCreateCashEquiavalentMethodPopup(data: any): any {
    const Dialogtitle = this.captions.pg_title_PaymentMethod;
    const dialogRef = this.dialog.open(CreateCashEqivalentPaymentMethodComponent, {
      width: '40%',
      maxWidth: "1024px",
      height: "650px",
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data: {
        headername: this.EditCaption + '  ' + Dialogtitle,
        closebool: true,
        isViewOnly: this.isViewOnly,
        datarecord: data,
        isClonnedPayment: (this.actionType === myGlobals.GridOperationType.Copy),
        isSigCapEnabled : this.isSigCapEnabled
      },
      disableClose: true
    });

    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      this.isEditFlag = false;
      if (result) {
        const isCopyMethod = (this.actionType === myGlobals.GridOperationType.Copy);
        let paymentMethodObj: PaymentMethod = this.actionType === myGlobals.GridOperationType.Edit ? data.form : {};
        if (this.actionType === myGlobals.GridOperationType.Create || isCopyMethod) {
          paymentMethodObj = {
            isActive: result?.formValues?.isActive,
            requireComments: result?.formValues?.requireComments,
            requireSignatureCapture: result?.formValues?.requireSignatureCapture,
            paymentMethod: result?.formValues?.paymentMethodName,
            allowEarn: result?.formValues?.allowEarn,
            listOrder: result?.formValues?.listOrder ?
              !isCopyMethod ? result?.formValues?.listOrder
                : Number(result?.formValues?.listOrder) + 1
              : 0,
            paymentTypeId: 0,
            parentMethodId: data.form?.id,
            parentTypeId: data.form?.paymentTypeId,
            isCloned: isCopyMethod,
            additionalConfigurations: JSON.stringify(result.additionalConfig),
            id: 0,
            isDefault: isCopyMethod ? data.form?.isDefault : false,
            noOfReceipts: result?.formValues?.noOfReceipts ? result?.formValues?.noOfReceipts :
              this.paymentMethodBusiness.defaultNoOfReceipt,
            requireReceipts: result?.formValues?.requireReceipts,
            InitiateFormControlsAndVariables: result?.formValues?.financialBinLevel,
            includeSignatureLine: result?.formValues?.includeSignatureLine,
            isAutoRemoveTax: result?.formValues?.isAutoRemoveTax,
            postToPMS: result?.formValues?.postToPMS,
            tenderReducesDiscount: result?.formValues?.tenderReducesDiscount
          }
          this.InvokeServiceCall("AddPaymentMethod", myGlobals.Host.payment, HttpMethod.Post, null, paymentMethodObj);
        } else if (this.actionType === myGlobals.GridOperationType.Edit) {
          const updatePaymentMethod = () => {
            paymentMethodObj.allowEarn = result?.formValues?.allowEarn;
            paymentMethodObj.isActive = result?.formValues?.isActive;
            paymentMethodObj.requireComments = result?.formValues?.requireComments;
            paymentMethodObj.requireSignatureCapture = result?.formValues?.requireSignatureCapture;
            paymentMethodObj.paymentMethod = result?.formValues?.paymentMethodName;
            paymentMethodObj.listOrder = result?.formValues?.listOrder ? result?.formValues?.listOrder : 0;
            paymentMethodObj.noOfReceipts = result?.formValues?.noOfReceipts ? result?.formValues?.noOfReceipts :
              this.paymentMethodBusiness.defaultNoOfReceipt;
            paymentMethodObj.additionalConfigurations = JSON.stringify(result.additionalConfig),
              paymentMethodObj.requireReceipts = result?.formValues?.requireReceipts,
              paymentMethodObj.includeSignatureLine = result?.formValues?.includeSignatureLine,
              paymentMethodObj.isAutoRemoveTax = result?.formValues?.isAutoRemoveTax,
              paymentMethodObj.financialBinLevel = result?.formValues?.financialBinLevel,
              paymentMethodObj.tenderReducesDiscount = result?.formValues?.tenderReducesDiscount,
              paymentMethodObj.postToPMS = result?.formValues?.postToPMS
            this.InvokeServiceCall("PaymentMethodWithId", myGlobals.Host.payment, HttpMethod.Put,
              { id: paymentMethodObj.id }, paymentMethodObj);
          }
          if (!result?.formValues?.isActive) {
            this.validatePaymentMethodDeactivation(paymentMethodObj.paymentTypeId).then(res => {
              let AllowPaymentMethodDeactivation = res;
              if (!AllowPaymentMethodDeactivation) {
                const errMsg = this.localization.getError(100714);
                this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
                this.InvokeServiceCall("PaymentMethod", myGlobals.Host.payment, HttpMethod.Get);
                return;
              } else {
                updatePaymentMethod();
              }
            });
          } else {
            updatePaymentMethod();
          }
        }
      } else {
        this.Cancel(false);
        this.RefreshGrid();
      }
    });

    return dialogRef;
  }

}
