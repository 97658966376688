<div class="privatelesson-booking">

  <div class="privatelesson-booking__form-main">
    <ng-scrollbar>
      <div class="privatelesson-booking__date">
        <label class="privatelesson-booking__data-header seat-label" for="">{{'Date' | translate}}</label>
        <dynamic-form [config]="bookingFromDateConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div class="privatelesson-booking__class-name">
        <label class="privatelesson-booking__data-header seat-label" for=""> {{'ClassName' | translate}}</label>
        <dynamic-form [config]="lessonConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div class="privatelesson-booking__location">
        <label class="privatelesson-booking__data-header seat-label" for=""> {{'location' | translate}}</label>
        <dynamic-form [config]="locationConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div class="privatelesson-booking__location">
        <label class="privatelesson-booking__data-header seat-label" for=""> {{'instructor' | translate}}</label>
        <dynamic-form [config]="instructorConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
      <div class="guest-data__size"> 
        <!-- [ngClass]="{'privatelesson-booking__size-disabled': data?.isConfirmedReservation}" -->
        <size-mapping [coverTypes]="coverTypes" [bookingTypes]="bookingTypes" [minSize]="minSizeAllowed" [maxSize]="maxSizeAllowed" [(size)]="sizeMapping" (sizeChange)="onSizeChange()" [dataCovers]="data?.CoverTypeQuantities || []" [dataSize]="data?.Size"></size-mapping>
      </div>
      <div class="activity-booking__emailNotifications" *ngIf="_settings">
        <app-staff-notifications [settings]="_settings" [specialMealId]="partyService.selectedSpecialMealId" [emailList]="data?.NotificationEmailIds" [SalesContactIds]="data?.SalesContactIds" (notificationEvent)="notificationEvents($event)"></app-staff-notifications>
      </div>
    </ng-scrollbar>
  </div>
  <div *ngIf="displaySlots; else noSlots" class="privatelesson-booking__time-slot">
    <div class="allocation-types">
      <div class="allocation-type" *ngFor="let allocType of allocationCodes"
        [ngStyle]="{'border-color':allocType.color, 'color': allocType.color}">{{allocType.AllocationName}}</div>
    </div>
    <div class="privatelesson-booking__duration-info" *ngIf="selectedActivity?.MinDuration">
      <div class="privatelesson-booking__duration-text">
        <span>{{'Min Duration(in mins)' |
          translate}}
        </span>:&nbsp;<span class="privatelesson-booking__duration-value">{{selectedActivity.MinDuration}}</span>
      </div>
      <div class="privatelesson-booking__duration-text">
        <span>{{'Max Duration(in mins)' |
          translate}}</span>:&nbsp;<span
          class="privatelesson-booking__duration-value">{{selectedActivity.MaxDuration}}</span>
      </div>
    </div>
    <app-wait-time [selectedActivity]="selectedActivity" [isEditCheckInAllowed]="editCheckedInReservation" (slotUpdateEvent)="onSlotUpdate($event)"
      [isenableMultipleSlotSelection]="isMultipleSlotSelectionEnabled"></app-wait-time>
  </div>
  <ng-template #noSlots>
    <div ngClass="noDataFound"><span>{{'noSlotsAvailable' | translate }}</span></div>
  </ng-template>
</div>
