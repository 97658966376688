import { Directive, ElementRef, Host, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HeaderComponent } from '@app/layout/header/header.component';

@Directive({
    selector: '[showMoreTextHandler]'
})
export class ShowMoreTextDirective implements OnChanges{

    @Input('showMoreTextHandler') showMoreText;

    constructor(private el: ElementRef, @Host() private headerComponent: HeaderComponent) {
        this.handler();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.handler();
    }

    handler(){
        setTimeout(() => {
            if (this.el.nativeElement.scrollHeight > 55) {
                this.headerComponent.showMoreOption = true;
                this.headerComponent.showMoreText = this.headerComponent.dayNoteElement.nativeElement.scrollHeight > this.headerComponent.dayNoteElement.nativeElement.clientHeight ? this.headerComponent.trs.instant('accordionmoretext') :  this.headerComponent.trs.instant('accordionlesstext');
            }else{
                this.headerComponent.showMoreOption = false;   
            }
        }, 100);
    }
}
