export class FormCodeDTO {
  Id: number;
  FormType: FormType;
  GuestLinkType: GuestLinkType;
  Code: string;
  CreatedAt: Date;
  UpdatedAt: Date;
  Status: boolean;

  constructor(init?: Partial<FormCodeDTO>) {
    Object.assign(this, init);
  }
}

export enum FormType {
  None = 0,
  AuthorizeAndPay = 1,
  Prepayment = 2
}

export enum GuestLinkType {
  None = 0,
  LeadBooker = 1,
  IndividualGuest = 2,
  AllGuests = 3
}

export enum FormTypeName{
  None = 'None',
  AuthorizeAndPay = 'AuthorizeAndPay',
  Prepayment = 'Prepayment'
}
