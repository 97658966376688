import { Injectable } from '@angular/core';
import { PartyPrepaymentState } from '../models/InputContact';
import { FinancialEffectAction,  PartyState } from '../constants/commonenums';
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { PayingGuest } from '../models/RestaurantDTO';
import { Utilities } from '../utilities/utilities';
import { PartyService } from '../services/party.service';
import { InvoiceLevel } from '@app/shared/constants/commonenums';

@Injectable({
  providedIn: 'root'
})
export class BookingHandlerService {
  openedTogglesIds: any[] = [];
  constructor(public ts: TranslateService, public toastrService: ToastrService, public ps: PartyService) { }

  dueStates = [PartyPrepaymentState.PrepaymentRequired,
  PartyPrepaymentState.PrepaymentInProgress,
  PartyPrepaymentState.PrepaymentFailed,
  PartyPrepaymentState.PartialPrepayment,
  PartyPrepaymentState.DepositCollected,
  PartyPrepaymentState.AdditionalPaymentPending,
  PartyPrepaymentState.PaymentDuePending,
  PartyPrepaymentState.RefundDuePending
  ]

  RefundState = [PartyPrepaymentState.RefundDuePending,
  PartyPrepaymentState.RefundFailed]

  refunRestrictedStates = [PartyPrepaymentState.PartialPrepayment ,PartyPrepaymentState.PaymentDuePending, PartyPrepaymentState.PrepaymentRequired, PartyPrepaymentState.Refunded , PartyPrepaymentState.None, PartyPrepaymentState.CancellationFeeApplied];


  showCollectChargeOperation(PrepaymentState, bookedSession: any = null , partyState:any = null) {
    let _paymentState = bookedSession ? bookedSession.PaymentStatus : PrepaymentState;
    let _state = bookedSession ? bookedSession.SessionState: partyState;
    return this.dueStates.includes(_paymentState) &&  _state !== PartyState.Cancelled;
  }
  showRefundOperation(PrepaymentState, bookedSession: any = null) {
    let _state = bookedSession ? bookedSession.PaymentStatus : PrepaymentState || PartyPrepaymentState.None;
    return !this.refunRestrictedStates.includes(_state) ;
  }
  showRefundWithCancel(refundAvailable, State) {
    return refundAvailable && State === PartyState.Cancelled;
  }
  setBookedSessionState(State, bookedSession) {
    let _state = bookedSession ? bookedSession.SessionState : State;
    return _state;
  }
  bookingContactMapper(selectedParty) {
    let _contacts = [];
    if (selectedParty.BookingContacts?.length > 1) {
      if (selectedParty.BookedSessions?.length) {
        selectedParty.BookedSessions?.forEach((_bookedSessions) => {
          selectedParty['PageRequest'] = []
          selectedParty.BookingContacts?.forEach(bookingContact => {
            let _mappedStates = bookingContact?.BookingContactStates?.find(_contact => _contact.PaymentStatus !== PartyPrepaymentState.None && (_contact.BookedSessionId == _bookedSessions?.Id || !_contact.BookedSessionId)) || null;
            if(_mappedStates){
              let mappedObj = Object.assign({}, this.mapBookingContactValue(selectedParty, bookingContact, _mappedStates, _bookedSessions))
              _contacts.push(mappedObj);
            }
            selectedParty['selected'] = false;
          })
        });
      } else {
        selectedParty.BookingContacts?.forEach(bookingContact => {
          let _mappedStates = bookingContact?.BookingContactStates?.find(_contact => !_contact.BookedSessionId) || null;
          if(_mappedStates){
            let mappedObj = Object.assign({}, this.mapBookingContactValue(selectedParty, bookingContact, _mappedStates, null));
            _contacts.push(mappedObj);  
          }
          selectedParty['selected'] = false;
        })
      }

      return  _contacts ? _.orderBy(_contacts , 'Id') : [];
    } else {
      if(selectedParty.Contact.Id === selectedParty.BookingContacts[0].ContactId && selectedParty?.BookedSessions?.length  == 1) {
        return [];
      }else {
        if (selectedParty?.BookedSessions?.length) {
          selectedParty?.BookedSessions?.forEach((_bookedSessions) => {
            selectedParty['PageRequest'] = []
            selectedParty?.BookingContacts?.forEach(bookingContact => {
              let _mappedStates = bookingContact?.BookingContactStates?.find(_contact => _contact.BookedSessionId == _bookedSessions?.Id || !_contact.BookedSessionId) || null;
              if (_mappedStates) {
                let mappedObj = Object.assign({}, this.mapBookingContactValue(selectedParty, bookingContact, _mappedStates, _bookedSessions))
                _contacts.push(mappedObj);
              }
              selectedParty['selected'] = false;
            })
          });
        }else{
          selectedParty?.BookingContacts?.forEach(bookingContact => {
            let _mappedStates = bookingContact?.BookingContactStates?.find(_contact => !_contact.BookedSessionId) || null;
          if(_mappedStates){
            let mappedObj = Object.assign({}, this.mapBookingContactValue(selectedParty, bookingContact, _mappedStates, null))
            _contacts.push(mappedObj);
          } 
            selectedParty['selected'] = false;
          })
        }
        return  _contacts ? _.orderBy(_contacts , 'Id') : [];
      }
    }

  }

  toggleReservation(attendee: any) {
    attendee.expanded = !attendee.expanded;
    if (attendee.expanded) {
      this.openedTogglesIds.push(attendee.Id);
    } else {
      this.openedTogglesIds = this.openedTogglesIds.filter(toggleId => toggleId !== attendee.Id);
    }
  }
  mapBookingContactValue(selectedParty, bookingContact, _mappedStates, _bookedSessions) {
    if (_mappedStates) {
      bookingContact['bookingStates'] = _mappedStates;
      bookingContact['State'] = _mappedStates.State;
      bookingContact['PrepaymentState'] = _mappedStates.PaymentStatus;
      bookingContact['showCollectCharge'] = this.showCollectChargeOperation(_mappedStates.PaymentStatus , null ,  bookingContact['State'] );
      bookingContact['showRefund'] = this.showRefundOperation(_mappedStates.PaymentStatus)
      bookingContact['refundWithCancel'] = this.showRefundWithCancel(bookingContact['showRefund'], bookingContact['State']);
      bookingContact['contactStatesId'] = _mappedStates?.Id || null;
      bookingContact['disableCheckbox'] = _mappedStates.State == PartyState.Cancelled || _mappedStates.State == PartyState.Left;
      bookingContact['PaidAmount'] = _mappedStates.PaymentSummary?.PaidAmount || 0;
      bookingContact['NoShowFeeAmount'] = _mappedStates.State != PartyState.Cancelled ? _mappedStates.PaymentSummary?.PendingAmount : _mappedStates.PaymentSummary?.RefundPendingAmount;
      bookingContact['startTime'] = _bookedSessions  ? new Date('1970-01-01 ' + _bookedSessions?.StartTime) : selectedParty?.ReservedFor,
        bookingContact['endTime'] = _bookedSessions ? new Date('1970-01-01 ' + _bookedSessions?.EndTime) : selectedParty?.DepartureTime;
      bookingContact['showTakeAction'] = false;
      bookingContact['primaryContact'] = selectedParty.Contact.Id;
      bookingContact['RestaurantId'] = selectedParty.RestaurantId || Utilities.RestaurantId();
      bookingContact['BookedSessionId'] = _bookedSessions?.Id || null;

      return bookingContact;
    }
  }
  toggleAllCheckboxes(isSelected, reservation: any) {
    let bookingContacts = reservation.mappedContact.filter(guest => guest.State !== PartyState.Cancelled);

    if (_.uniqBy(bookingContacts, 'State')?.length == 1 && isSelected) {
      reservation.mappedContact.forEach(guest => {
        if (isSelected && guest.State !== PartyState.Cancelled && guest.State !== PartyState.Left) {
          guest.selected = isSelected
        } else if (isSelected) {
          guest.selected = false;
          //    this.toastrService.warning(this.ts.instant('bookincontactSelectionWarning'), "", { timeOut: 5000, closeButton: true }); 
        }
      });
    } else if (isSelected) {
      setTimeout(() => { reservation.selected = false; }, 0)
      let selectedState = reservation.BookedSessionId ? (reservation.BookedSessions.find(session => session.Id == reservation.BookedSessionId)?.SessionState) : reservation.State;
      reservation.mappedContact.forEach(guest => guest.selected = selectedState == guest.State);
      reservation['indeterminate'] = true;
      this.toastrService.warning(this.ts.instant('bookincontactSelectionWarning'), "", { timeOut: 5000, closeButton: true });
    } else if (!isSelected) {
      reservation.mappedContact.forEach(guest => guest.selected = isSelected);
    }
    reservation.selected = isSelected;
  }

  composePayingGuest(attendee: any, sessionId: number = null, guestId: number = null) {
    let payingGuestsArr = [];
    if (attendee.BookedSessions?.length) {
      attendee.BookedSessions?.forEach(session => {
        let _payingGuest = {} as PayingGuest;
        let _allowRefund = this.showRefundOperation(session.PaymentStatus, session);
        let _allowChargeGuest = this.showCollectChargeOperation(session.PaymentStatus, session );
        if ((_allowChargeGuest || _allowRefund ) && (sessionId == session.Id || !sessionId)) {
          _payingGuest.ActivitySessionId = session.ActivitySessionId;
          _payingGuest.BookingDate = session.BookedDate;
          _payingGuest.PayingContacts = guestId ? [guestId] : this.getPayingContactIds(attendee.BookingContacts);
          payingGuestsArr.push(_payingGuest);
        }
      })
    } else {
      let _payingGuest = {} as PayingGuest;
      _payingGuest.ActivitySessionId = null;
      _payingGuest.BookingDate = attendee.ReservedFor;
      _payingGuest.PayingContacts = guestId ? [guestId] : attendee.BookingContacts.map(contact => contact.ContactId);
      payingGuestsArr.push(_payingGuest);
    }

    return payingGuestsArr;
  }

  getPayingContactIds(bookingContacts: any[]) {
    let contacts = bookingContacts.filter(contact => contact?.bookingStates?.PaymentStatus == PartyPrepaymentState.PrepaymentRequired && contact.State !== PartyState.Cancelled);
    if (!contacts.length) {
      contacts = bookingContacts.filter(contact => this.showCollectChargeOperation(contact?.bookingStates?.PaymentStatus));
    }
    return contacts.map(contact => contact.ContactId) || [];
  }

  chargeGuest(attendee, reservation, bookedSessionId: number = null, actionText: string = null, propertySettings:any) {
    this.ps.showRefundMsg = attendee.showRefund || false;
    let party = Object.assign({}, reservation ? reservation : attendee);
    this.ps.ratePlanObject = null;
    if (Utilities.isUserHasAccessToRetail(party.RestaurantId)) {
      let contactId = attendee.BookingContacts?.length == 1 ? attendee?.Contact?.Id : attendee?.ContactId;
      if(propertySettings &&  propertySettings.PropertySetting[0].InvoiceLevel === InvoiceLevel.PrimaryGuest){
        contactId = reservation ? reservation.Contact.Id : attendee?.Contact?.Id;
      } 
      let payingGuest = this.composePayingGuest(party, bookedSessionId || party?.BookedSessionId, contactId);
      if (attendee.refundWithCancel) {
        this.refundCancelledBooking(party, payingGuest,actionText , attendee?.ContactId);
      } else {
        this.ps.chargeGuest(party, false, payingGuest, actionText)
      }
    } else {
      this.toastrService.warning(this.ts.instant('userPermissionMsg'));
    }
  }

  refundCancelledBooking(party, payingGuest,actionText , bookingContactId) {
    this.ps.getCancelFinancialDetails(party.Id, [], false, false, false, payingGuest, FinancialEffectAction.Refund).subscribe(data => {
      if (data) {
        this.ps.refundChargeGuestOperation(party, data.Payload,actionText,bookingContactId);
      }
    })
  }
}
