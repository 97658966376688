import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, of, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { TranscationLogBusiness } from './retail-transactions-log.business';
import { RetailPropertyInformation } from '../../../common/services/retail-property-information.service';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { AlertType } from 'src/app/retail/shared/shared.modal';
import { BatchProcessStatus, FailedTransactions, TransactionsLogSearchRequest } from './retail-transactions-log';
import { RetailService } from 'src/app/retail/retail.service';
import { UserInfo } from '../retail-revenue-posting-logs/revenue-posting';


@Component({
  selector: 'app-retail-transactions-log',
  templateUrl: './retail-transactions-log.component.html',
  styleUrls: ['./retail-transactions-log.component.scss'],
  providers: [TranscationLogBusiness],
  encapsulation: ViewEncapsulation.None
})
export class RetailTransactionsLogComponent implements OnInit {
  commonCaptions: any;
  captions: any;
  transcForm: UntypedFormGroup;
  searchText = '';
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  originalData$: Promise<any[]>;
  originalData: FailedTransactions[];
  tableContent: Observable<any[]>;
  retryButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  searchButton: ButtonValue;
  refreshButton: ButtonValue;
  linkdisabled = false;
  isAllSelected: boolean;
  userList: UserInfo[] = [];
  showLoader = false;
  isAnyBatchInProgress = false;
  batchInProgressMsg = "";
  floatLabel: string;
  loaderCaptions: any;


  constructor(private fb: UntypedFormBuilder
    , private localization: RetailLocalization
    , private transcationLogBusiness: TranscationLogBusiness
    , public propertyInfo: RetailPropertyInformation
    , private _utils: RetailUtilities
    , public _ams: RetailService
  ) {
    this.captions = this.localization.captions.shop;    
    this.commonCaptions=this.localization.captions.common;
    this.loaderCaptions=this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.formGenerator()
    this.tableOptions = this.transcationLogBusiness.getTableOptions();
    this.headerOptions = this.transcationLogBusiness.getHeaderOptions();

    this.retryButton = {
      type: 'primary',
      label: this.captions.retry,
      disabledproperty: true
    }
    this.refreshButton = {
      type: 'primary',
      label: this.captions.TransactionLogScreen.Refresh,
      disabledproperty: true
    };
    this.searchButton = {
      type: 'primary',
      label: this.captions.search,
      disabledproperty: false
    }    
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.reset,
      disabledproperty:true
    }    
    this.transcForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.searchButton.disabledproperty = !(this.transcForm.valid && this.transcForm.dirty);
      this.cancelButton.disabledproperty = !(this.transcForm.valid && this.transcForm.dirty);
    });
    this.AddLoaderSubscription();
    this.getTableData();
    this.GetActiveBatchStatus(true);
    this.batchInProgressMsg = this.captions.TransactionLogScreen.BatchInProgressBannerLbl;
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  AddLoaderSubscription() {
    this._ams.loaderEnable.pipe(takeUntil(this.destroyed$)).subscribe(loader => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
          loadingContainerMessage.innerText = loader;
        }
        else {
          loadingContainer.style.display = 'none';
          loadingContainerMessage.innerText = '';
        }
      }
    });
  }

  formGenerator() {
    this.transcForm = this.fb.group({
      fromDate: this.propertyInfo.CurrentDate,
      toDate: this.propertyInfo.CurrentDate,
      tableData: '',
      searchByTicket: '',
      searchByClerkID: '',
      searchByAmount: ''
    })
  }

  fromDate(e) {
    const startDate = this.localization.getDate(this.transcForm.controls.fromDate.value);
    const endDate = this.localization.getDate(this.transcForm.controls.toDate.value);
    if (startDate >= endDate) {
      this.transcForm.controls.toDate.setValue(startDate);
    }
    this.retryButton.disabledproperty = true;
  }

  toDate(e) {
    const startDate = this.localization.getDate(this.transcForm.controls.fromDate.value);
    const endDate = this.localization.getDate(this.transcForm.controls.toDate.value);
    if (startDate >= endDate) {
      this.transcForm.controls.fromDate.setValue(endDate);
    }
    this.retryButton.disabledproperty = true;
  }

  async getTableData() {
    this.showLoader = true;
    this.originalData = [];
    this.disableSearchAndRefresh();    
    this.userList=await this.transcationLogBusiness.getClerkInfo();
    const clerkIds = this.transcForm.controls.searchByClerkID.value ?
    this.userList.filter(x => (x.userName.toLowerCase().includes(this.transcForm.controls.searchByClerkID.value.toLowerCase()))) : [];
    const request : TransactionsLogSearchRequest= {
      startDate : this.localization.ConvertDateToISODateTime(this.transcForm.controls.fromDate.value),
      endDate : this.localization.ConvertDateToISODateTime(this.transcForm.controls.toDate.value),
      ticketNumber : this.transcForm.controls.searchByTicket.value,
      amount : this.transcForm.controls.searchByAmount.value,
      clerkIdList: clerkIds ? clerkIds.map(x => x.id) : [],
      pageStart : 0
    }
    this.originalData$ = this.transcationLogBusiness.getTabledata(request,this.userList);
    this.originalData$.then(response => {
      this.originalData = response;
      this.tableContent = of(response);
      this.transcForm.get('tableData').setValue(response);
      this.showLoader = false;
      this.searchButton.disabledproperty = false;
    }).catch(ex => {
      console.log(ex);
      this.showLoader = false;
      this.searchButton.disabledproperty = false;
    });
  }

  tableAction(e) {
    if (this.isAnyBatchInProgress) {
      this.retryButton.disabledproperty = true;
      return;
    }
    switch (e.fromType) {
      case FromTypeEnum.allcheckbox:
        this.isAllSelected = e.value;
        this.settableData(e);
        this.retryButton.disabledproperty = !e.value;
        break;
      case FromTypeEnum.rowcheck:
        this.settableData(e);
        this.retryButton.disabledproperty = e.checkedData.length > 0 ? false : true;
        break;
      default:
        this.retryButton.disabledproperty = true;

    }
  }

  retrySelectedTransactions(e) {
    const data = this.transcForm.get('tableData').value.filter(x => x.checked);
    if (data?.length > 0) {      
      this.RetryTransactions(data, true);
    }
  }

  DisableActionsForBatchRetry(){
    if(this.isAnyBatchInProgress){
      this.headerOptions[0].isCheckboxDisabled = true;
      this.retryButton.disabledproperty = true;
      this.originalData.forEach(row => row.isCheckBoxDisabled = true);
      this.tableContent = of(this.originalData);
    }
  }

  RetryTransactions(data, isBatch = false) {
    if (!data) return;
    this._ams.loaderEnable.next(this.captions.TransactionInprogressMsg);
    const isProcessAll = (this.isAllSelected == true);
    try {
      const batchRes = this.transcationLogBusiness.BatchProcessFailedTransactions(isProcessAll, isProcessAll ? [] : data.map(x => x.id));
      batchRes.then(res => {
        this._ams.loaderEnable.next("");
        const alertType = res ? AlertType.Success : AlertType.Error;
        let infoMsg = "";
        if (isProcessAll || isBatch) {
          infoMsg = res ? this.captions.TransactionLogScreen.BatchInitSuccessMsg : this.captions.TransactionLogScreen.BatchInitFailureMsg;
          this.isAnyBatchInProgress = res;
          this.refreshButton.disabledproperty = false;
          this.retryButton.disabledproperty = true;
          this.linkdisabled = true;
          this.refreshButton = { ...this.refreshButton };
        } else {
          infoMsg = res ? this.captions.TransactionLogScreen.RetrySuccessMsg : this.captions.TransactionLogScreen.RetryFailureMsg;
        }
        this._utils.showAlert(infoMsg, alertType);
        if (res) this.getTableData();
        console.log(this.transcForm.value)
      }).catch(err => {
        this._utils.showAlert(
          isProcessAll || isBatch ? this.captions.TransactionLogScreen.BatchInitFailureMsg
            : this.captions.TransactionLogScreen.RetryFailureMsg
          , AlertType.Error);
        this._ams.loaderEnable.next("");
        console.log(err);
      });
    } catch (error) {
      this._ams.loaderEnable.next("");
      console.log(error);
    }
  }

  disableSearchAndRefresh(){
    this.retryButton.disabledproperty = true;
    //this.refreshButton.disabledproperty = true;
  }  

  async refreshGrid($event) {
    this.disableSearchAndRefresh();
    await this.GetActiveBatchStatus();    
  }

  async GetActiveBatchStatus(isFromOnInit:boolean = false) {
    const activeBatch = await this.transcationLogBusiness.getActiveBatchStatus();
    if (activeBatch != null) {
      console.log(activeBatch);
      this.isAnyBatchInProgress = (activeBatch.Status == BatchProcessStatus.Started);
      if (this.isAnyBatchInProgress) {
        this.refreshButton.disabledproperty = false;
        this.linkdisabled = true;
        this.DisableActionsForBatchRetry();
      } else {
        if(!isFromOnInit)
        {
        this.refreshButton.disabledproperty = true;
        this.retryButton.disabledproperty = false;
        this.linkdisabled = false;
        this.getTableData();
        }
      }
    }
  }

  searchActivity(e) {
    this.disableSearchAndRefresh();
  }

  tableRetry(e, key, event) {
    event.stopPropagation();
    console.log(e);
    if (e) { this.RetryTransactions([e]); }
  }

  cancel(e) {
    this.transcForm.reset();
    this.transcForm.get('fromDate').setValue(this.propertyInfo.CurrentDate)
    this.transcForm.get('toDate').setValue(this.propertyInfo.CurrentDate)
    this.retryButton.disabledproperty = true;
    this.tableContent = of([]);
    this.transcForm.get('tableData').setValue(this.tableContent);
    this.searchButton.disabledproperty = false;
  }

  settableData(e) {
    this.transcForm.markAsDirty();
    this.transcForm.get('tableData').setValue(e.array);
  }

}
