import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Pipe, PipeTransform, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { LoaderService } from '@app/core/services/loader.service';
import { LayoutFunctions } from '@app/shared/utilities/layout-functions';
import { ChitPrintComponent } from '@components/chit-print/chit-print.component';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { buttonTypes, ComponentTypes, ContentView, Menu, OperationResultState, PartyState, PartyType, ReservationEmailNotificationType, ReservationStatus, ReservationType, SlottingMode } from '@constants/commonenums';
import * as globals from '@constants/globalConstants';
import { urlConfig } from '@constants/url-config';
import { CacheService } from '@core/services/cache.service';
import DisplayOptions from '@data/displayFilterOptions.json';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { ReservationDTO } from '@models/InputContact';
import { DateShiftDTO, DateShiftStateDTO, DateStateDTO, InputWaitlistDTO, StandByPartyDTO, TableAvailabilityDTO, UpdatedWalkInDTO } from '@models/InputReservation';
import { EngageIntegrationDTO, PageMethod, PartyEmailSendBehavior, SeatingInfoDTO, SettingsDTO, SpecialMealDTO, StatusDTO, SupportedReservationEmailConfirmationTypes } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails, TabConfig } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { DashboardFunctions } from '@utilities/dashboard-functions';
import { Utilities } from '@utilities/utilities';
import { endOfDay, format, startOfDay } from 'date-fns';
import {cloneDeep, orderBy, sortBy} from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { MultiPagingComponent } from './multi-paging/multi-paging.component';
import { MatAccordion } from '@angular/material/expansion';


@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReservationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  @ViewChild('searchConfigForm', { static: true }) searchConfigForm: DynamicFormComponent;
  contentView: ContentView;
  ContentView = ContentView;
  ReservationType = ReservationType;
  parties: any[]; // copy of reservations to handle the filter
  totalReservations: any = [];
  dashboardData: any;
  config: FieldConfig[];
  sortByConfig: FieldConfig[];
  displayConfig: FieldConfig[];
  showCreatedDateConfig: FieldConfig[];
  showCreatedDate = false;
  displayLegends: any;
  componentDetails: ComponentDetails;
  selectedParty: any;
  tabcomponentDetails: TabConfig[];
  isPartySelected: boolean;
  quickBlockBtn: ButtonValue;
  serverId: number;
  reservationsViewBy = globals.reservationViewBy;
  reservationUrl: string = urlConfig.noReservationsUrl;
  showMultiPaging = false;
  MultiPagingAvailability: number;
  showByPartySize = false;
  seatingInfo: SeatingInfoDTO;
  reservations: ReservationDTO[] = [];
  filteredReservations: ReservationDTO[];
  IsCurrentDate = false;
  minDate: Date;
  _settings: SettingsDTO = {} as SettingsDTO;
  tempDashboardData: any;
  viewBy = globals.reservationViewBy.Today;
  allShiftsData: any;
  subscriptions: Subscription = new Subscription();
  confirmSubscription: Subscription;
  cancelSubscription: Subscription;
  isPastDate: boolean;
  standbyParties: StandByPartyDTO[];
  isNoShowSelected: boolean = false;
  isSeatedSelected: boolean = false;
  isReservedSelected: boolean = false;
  isClearedSelected: boolean = false;
  partyRefreshing: number = 0;
  displayOptions = [];
  settingOptions = globals.reservationSettings;
  selectedOptions = [];
  searchString: string = '';
  searchConfig: FieldConfig[];
  tableAvailability: TableAvailabilityDTO[] = [];
  generalSettings: {
    HasRoomNumberSearch: boolean,
    SpecialMeals: SpecialMealDTO[],
    Statuses: StatusDTO[],
    PromoCodeLabel: string,
    General: {
      DaylightDelta: string,
      NoShowsUndoTimeInMinutes: number,
      MinIntervalBetweenPartyPagesInMinutes: number,
      SupportedReservationEmailConfirmationTypes: SupportedReservationEmailConfirmationTypes,
      SlottingMode: SlottingMode,
      ShowSeatedReservationsInLists: boolean,
      EngageIntegrationDTO: EngageIntegrationDTO
    }
  }
  headerDateRange: {
    Start: string ,
    End:  string
  }
  isCancelledSelected:boolean = false;
  expandAll: boolean = Utilities.getLocalStorage(globals.SessionStorageKeys.ExpandAllShifts) === 'true';

  @ViewChild(MatAccordion) accordion: MatAccordion;


  constructor(public _as: AppService, private dialog: MatDialog, public partyService: PartyService,
    private popupService: PopupService, private dashboardFunctions: DashboardFunctions,
    private ts: TranslateService, public cs: CacheService, private cdf: ChangeDetectorRef,
    private router: Router,private ls:LoaderService,private lf: LayoutFunctions) {
    this.displayLegends = globals.legendArray;
    this.subscriptions.add(cs.settings.subscribe(sett => {
      const {
        HasRoomNumberSearch,
        SpecialMeals,
        Statuses,
        PromoCodeLabel,
        General: {
          DaylightDelta,
          NoShowsUndoTimeInMinutes,
          MinIntervalBetweenPartyPagesInMinutes,
          SupportedReservationEmailConfirmationTypes,
          SlottingMode,
          ShowSeatedReservationsInLists,
          EngageIntegrationDTO
        } = {}
      } = sett;
      this.generalSettings = {
        HasRoomNumberSearch,
        SpecialMeals,
        Statuses,
        PromoCodeLabel,
        General: {
          DaylightDelta,
          NoShowsUndoTimeInMinutes,
          MinIntervalBetweenPartyPagesInMinutes,
          SupportedReservationEmailConfirmationTypes,
          SlottingMode,
          ShowSeatedReservationsInLists,
          EngageIntegrationDTO
        }
      };
      this.regenerateDisplayOptionsIfRequired();
    }));

    this.subscriptions.add(cs.state.subscribe(ste => {
      this.partyService.RecentlyLeftPartiesList = cloneDeep(ste.RecentlyLeftParties);
    }));
    _as.applySetting(Menu.Reservation);
  //  this.lf.loadIcons();
  }

  ngOnInit() {
    this._as.showDashboard = true;
    this.dashboardData = [{ partyInfo: null, countInfo: [], status: [], messages: [], actions: [] }];
    this.config = [
      {
        type: 'select',
        name: 'viewBy',
        placeholder: '',
        options: this.partyService.DayArray,
        class: 'view-by',
        showErrorText: true,
        value: this.partyService.DayArray[globals.reservationViewBy.Today].id,
        appearance: true,
        isTranslate: true
      },

    ];
    this.sortByConfig = [
      {
        type: 'select',
        name: 'sortBy',
        placeholder: '',
        options: this.partyService.TimeArray,
        class: 'reservation__sort-by-select',
        showErrorText: true,
        value: this.partyService.TimeArray[0].id,
        appearance: true,
        isTranslate: true
      }
    ];
    this.searchConfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this.ts.instant('searchReservation'),
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        class: 'reservation__search-by',
        cellClick: (event) => { this.clearSearchField(event); }
      }
    ];
    this.generateSettingOptions();
    this.generateDisplayOptions();
    this.subscriptions.add(this._as.headerDate$.subscribe((date) => {
      if (date && Utilities.diffBetweenDates(this._as.headerSelectedDate, date) == 0) {
        this.parties = [];
        const currentRestaurantDate = Utilities.Date(Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta));
        const currentHeaderDate = Utilities.Date(this._as._headerDate);
        //this.loadReservations(date, date, globals.reservationViewBy.Today);
        if (this.config) {
          this.config[0].value = globals.reservationViewBy.Today;
        }
        this.viewBy = globals.reservationViewBy.Today;

        if (currentRestaurantDate !== currentHeaderDate) {
          //this.showSeatActionConfirmation = true;
          this.IsCurrentDate = false;
        } else {
          //this.showSeatActionConfirmation = false;
          this.IsCurrentDate = true;
        }
        if (Utilities.diffBetweenDates(currentHeaderDate, currentRestaurantDate) < 0) {
          this.partyService.isPastReservation = true;
        } else {
          this.partyService.isPastReservation = false;
        }

        this.minDate = new Date(this.cs.state.value.CurrentTime);
        this.minDate.setDate(this.minDate.getDate() - 3);
      }

      this.isPastDate = this.getPastReservationStatus();
      this.headerDateRange = {
        Start : moment(startOfDay(date)).format('YYYY-MM-DDTHH:mm:ss'),
        End : moment(endOfDay(date)).format('YYYY-MM-DDTHH:mm:ss')
      }
    }));
    this.subscriptions.add(this._as.headerSelectedDate$.subscribe((date) => {
      this._as.headerSelectedDate = date;
    }));
    this.contentView = ContentView.grid;
    this.quickBlockBtn = {
      label: 'quickBlock',
      type: buttonTypes.roundedBtnSmall
    };
    this._as.datePickerDisabled.next(false);
    this._as.seatingAreaFilterDisabled.next(false);
  }

  checkIfPartyIsRemoved(objectChange: ObjectChange) {
    let isPartyRemoved: boolean;
    objectChange.PropertyChanges.forEach(property =>
      property.PropertyName.includes('CancelledAt') ? isPartyRemoved = true : isPartyRemoved = false);
    return isPartyRemoved;
  }

  propertySelectionChanged(item) {
    item.isSelected = !item.isSelected;
    this.selectedOptions = this.displayOptions.filter(x => x.isSelected).map(x => x.id);

    if (this.selectedOptions && this.selectedOptions.length == 0) {
      item.isSelected = !item.isSelected;

      return;
    }
    this._as.filterOptionsForReservation = this.selectedOptions.reduce((option, value) => {
      return {...option, [value]: true};
    }, {});
    

    this.displaySelectionChanged(this.selectedOptions);

  }

  generateSettingOptions(){
    this.settingOptions.forEach(data => {
      if(data.id === 11){
        data.isSelected = this.expandAll;
      }else{
        data.isSelected = false
      }
    });
    this._as.reservationSettings.forEach(itemSetting => {
      if(this.settingOptions.find(data => data.id == itemSetting) && itemSetting != 11){
        this.settingOptions.find(data => data.id == itemSetting).isSelected = true;
        this.fetchDataForSetting(itemSetting)
      }
    });
  }

  applySetting(item){

    

    if(item.id === 11){
      item.isSelected = !item.isSelected;
      if(item.isSelected){
        this.accordion.openAll();
        this.expandAll = true;
        localStorage.setItem(globals.SessionStorageKeys.ExpandAllShifts, 'true')
      }else{
        localStorage.setItem(globals.SessionStorageKeys.ExpandAllShifts, 'false')
        this.accordion.closeAll();
        this.expandAll = false;
      }
    }else{
      this.showCreatedDate = false;
      item.isSelected = !item.isSelected;
      if(item.isSelected){
        this._as.reservationSettings.push(item.id);
        this.fetchDataForSetting(item.id)
      }
      else{
        let index = this._as.reservationSettings.findIndex(data => item.id == data);
        this._as.reservationSettings.splice(index,1)
      }
    }
    
  }

  fetchDataForSetting(itemId){
    switch(itemId){
      case 1:
        this.showCreatedDate = true;
    }
  }

  generateDisplayOptions() {
    this.isNoShowSelected = true;
    this.isReservedSelected = true;
    this.isClearedSelected = false;
    this.isCancelledSelected = false;
    if (this.generalSettings.General.ShowSeatedReservationsInLists) {
      this.isSeatedSelected = true;
      let index = DisplayOptions.findIndex(x => x.value == "Seated");
      if (index == -1) {
        DisplayOptions.push({ id: 3, value: "Seated", isSelected: true });
      }
    } else {
      this.isSeatedSelected = false;
      let index = DisplayOptions.findIndex(x => x.value == "Seated");
      if (index > -1) {
        DisplayOptions.splice(index, 1);
      }
    }

    this.displayOptions = DisplayOptions;
    if(this._as.filterOptionsForReservation.length == 0){
    this.displayOptions.forEach(x => {
      x.isSelected = (x.value != "Cleared" && x.value != "Cancelled");
    });
  }
  else{
    this.displayOptions.forEach(x => {
      x.isSelected =this._as.filterOptionsForReservation[x.id] ? true : false;
    });
  }
    this.selectedOptions = this.displayOptions.filter(x => x.isSelected).map(x => x.id);
    this.displaySelectionChanged(this.selectedOptions);
    
  }

  regenerateDisplayOptionsIfRequired() {
    if (this.generalSettings.General.ShowSeatedReservationsInLists) {
      if (this.displayOptions) {
        let index = this.displayOptions.findIndex(x => x.value == "Seated");
        if (index == -1) {
          this.generateDisplayOptions();
          ++this.partyRefreshing;
        }
      }
    } else {
      if (this.displayOptions) {
        let index = this.displayOptions.findIndex(x => x.value == "Seated");
        if (index > -1) {
          this.generateDisplayOptions();
          ++this.partyRefreshing;
        }
      }
    }
  }

  displaySelectionChanged(valueSelected: any[]) {
    this.isNoShowSelected = false;
    this.isSeatedSelected = false;
    this.isReservedSelected = false;
    this.isClearedSelected = false;
    this.isCancelledSelected = false;
    if (valueSelected && valueSelected.length > 0) {
      valueSelected.forEach(val => {
        switch (val) {
          case 0:
            this.isNoShowSelected = true;
            this.isSeatedSelected = true;
            this.isReservedSelected = true;
            this.isClearedSelected = true;
            this.isCancelledSelected = true;
            break;
          case 1:
            this.isNoShowSelected = true;
            break;
          case 2:
            this.isReservedSelected = true;
            break;
          case 3:
            this.isSeatedSelected = true;
            break;
          case 4:
            this.isClearedSelected = true;
            break;
            case 5:
              this.isCancelledSelected = true;
              break;
          default:
            break;
        }
      });
    }
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.components.forEach((element, index) => {
      if (index === 0) {
        this.subscriptions.add(element.form.valueChanges.subscribe(val => {
          this.viewReservationsBy(val.viewBy);
        }));
      }
      if (index === 1) {
        this.subscriptions.add(element.form.valueChanges.subscribe(val => {
          switch (val.sortBy) {
            case 1:
              this.showByPartySize = false;
              this.filterBySeatingArea();
              break;
            case 2:
              this.showByPartySize = true;
              this.filterBySeatingArea();
              break;
          }
        }));
      }
    }));
    this.subscriptions.add(this.partyService.statusChange$.subscribe(data => {
      this.reservations.filter(x => {
        if (this.selectedParty && this.selectedParty.Id == x.Id) {
          x.StatusId = data.statusId;
        }
      });
    }));
    this.subscriptions.add(this._as.seatingAreaChanged.subscribe(area => {
      this.partyService.currentSeatingAreaId = area[0].Id;
    }));
    this.subscriptions.add(this.partyService.shifts$.subscribe((data) => {
      if (data && data.length > 0 && this.cs.propertySettings.value[this._as.restaurantId]) {
        this.cs.propertySettings.value[this._as.restaurantId]['shifts'] = data;
        setTimeout(() => {
          data.forEach(shift => {
            this.calculateWidth(shift);
          });
        });
      }
      if(data?.length == 1) {
          let shift: any = data[0];
          shift.panelOpenState = true;
          setTimeout(() => {
            this.accordion?.openAll();
          })
      }else if(data?.length && this.expandAll && this.accordion){
        data.forEach(dShift => dShift['panelOpenState'] = true);
        setTimeout(() => {
          this.accordion.openAll();
          // this.accordion._headers['_results'].forEach(matpanel => {
          //   if(matpanel.panel._expanded){
          //     matpanel.panel.open()
          //   }
          // });
        }, 0)
      }
    }));
    this.subscriptions.add(this._as.minuteTimer$.subscribe(val => {
      this.setTimerValues(this.partyService.Parties$.value);
    }));

    this.subscriptions.add(this.partyService.StandbyParties$.subscribe(data => {
      this._as.standbyListCount = data.length;
    }));

    this.subscriptions.add(this.searchConfigForm.form.valueChanges.subscribe(val => {
      this.searchString = val.searchText;
    }));
    this.subscriptions.add(this.partyService.Parties$.subscribe((data) => {
      this.setTimerValues(this.partyService.Parties$.value);
      this.SetMultiPagingButton(data);
    }))
    this.subscriptions.add(this.partyService.Parties$.subscribe((data) => {
      ++this.partyRefreshing;
      if (data.length > this.reservations.length) {
        this.reservations = data;
      }
    }));
    this.cdf.detectChanges();
    this.ls.showMessage.next(false);
  }

  removeOldSeatingParties(){
    startOfDay(this._as.headerSelectedDate);
   // this.partyService.Parties$.value.filter(a => a.)

  }

  clearSearchField(event) {
    this.searchString = '';
    this.searchConfigForm.form.get('searchText').setValue('');
  }

  setTimerValues(parties) {
    parties.forEach(party => {
      if (party.State == PartyState.Seated) {
        party.progressBarValue = this.calculatePercentile(party);
        party.quotedTime = this.getQuotedTime(party);
        party.remainingTime = this.getRemainingTime(party);
      }
    });
  }

  getRemainingTime(party): number {
    let time: any;
    if (party.State === PartyState.Seated) {
      time = Utilities.parseDateString(party.DepartureTime);
    } else if (party.State !== PartyState.Seated) {
      time = (party.SeatingTime) ? Utilities.parseDateString(party.SeatingTime) : (party.ReservedFor) ?
        Utilities.parseDateString(party.ReservedFor) : Utilities.parseDateString(party.ArrivedAt);
    }

    return Math.floor((time - Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta).getTime()) / 1000);
  }

  getQuotedTime(party): number {
    if (party.State == PartyState.Seated) {
      return Math.round((new Date(moment(party.DepartureTime).valueOf()).getTime() -
        new Date(moment(party.SeatingTime).valueOf()).getTime()) / 1000);
    } else {
      return 0;
    }
  }

  getTotalSeatingTime(party): number {
    if (party.State == PartyState.Seated) {
      return Math.round((new Date(moment(party.DepartureTime).valueOf()).getTime() -
        new Date(moment(party.SeatingTime).valueOf()).getTime()) / 60000);
    } else {
      return 0;
    }
  }

  calculatePercentile(party) {
    let seatedTimeTill = Math.round((Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta).getTime() -
      new Date(moment(party.SeatingTime).valueOf()).getTime()) / 60000);
    return (seatedTimeTill / this.getTotalSeatingTime(party)) * 100;
  }

  getFinalDate(startDate: Date) {
    const finalDate = new Date();
    return finalDate.setDate(startDate.getDate() + 6);
  }

  viewReservationsBy(viewBy) {
    let startDate;
    let finalDate;
    const showAlertForMaxLimit = false;
    this.viewBy = viewBy;
    switch (viewBy) {
      case globals.reservationViewBy.Today:
        startDate = Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta);
        //this.loadReservations(startDate, startDate, viewBy);
        break;
      case globals.reservationViewBy.Week:
        startDate = Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta);
        finalDate = this.getFinalDate(startDate);
        this.parties = [];
        //this.loadReservations(startDate, finalDate, viewBy);
        break;
      default:
        break;
    }
    if (showAlertForMaxLimit) {
      const message = this.ts.instant('minDateValidationMessage', {hours: this.cs.AllowEditForPastReservationsInDays * 24});
      const popUpMessage = [{
        confirmationMessage: message, dialogTitle: 'confirm', showAlert: true
      }];
      const componentDetails: ComponentDetails = {
        componentName: ConfirmationPopupComponent,
        popupType: 'action',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: popUpMessage,
        popupTitle: this.ts.instant('alert')
      };
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '450px',
        height: 'auto',
        data: {
          showAction: true,
          update: 'ok',
          componentDetails,
          from: ComponentTypes.reservation
        }
      });
    }
  }

  ngOnDestroy() {
    this.partyService.reservationType = null;
    //this.partyService.Parties$.next([]);
    this.partyService.selectedParty$.next(null);
    this.reservations = [];
    //this.partyService.StandbyParties$.next([]);
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

  quickBlock(seatSize) {
    const buildWaitListRequest = this.buildWaitListRequest(seatSize);
    this.partyService.createWaitList(buildWaitListRequest);
  }

  buildWaitListRequest(partySize): any {
    const waitListRequest: UpdatedWalkInDTO = {} as UpdatedWalkInDTO;
    waitListRequest.Type = 1;
    waitListRequest.IsQuickWalkIn = true;
    waitListRequest.ManualWaitTimeInMinutesFromNow = 0;
    waitListRequest.Size = partySize;
    waitListRequest.WaitTimeType = 0;
    waitListRequest.SeatingAreaId = null;
    waitListRequest.Contact = null;
    const createRequest: InputWaitlistDTO = {} as InputWaitlistDTO;
    createRequest.RestaurantId = this._as.restaurantId;
    createRequest.WalkInDTO = waitListRequest;
    return waitListRequest;
  }

  changeView(view: ContentView) {
    this.contentView = view;
  }

  trackByFn(index) {
    return index;
  }

  loadReservations(startDate, endDate, selectedValue?, triggeredFromListener?) {
    const partyData = [];
    this.subscriptions.add(this.partyService.getParties(startDate, endDate).subscribe((data) => {
      if (data) {
        this.partyService.partiesList = [];
        this.tableAvailability = [];
        const currentReservations: DateStateDTO[] = data.Payload.filter((reservations: DateStateDTO) => {
          const startdate = format(new Date(startDate), 'MM/DD/YYYY');
          const enddate = format(new Date(endDate), 'MM/DD/YYYY');
          const reservationDate = format(new Date(reservations.Date), 'MM/DD/YYYY');
          return (startdate <= reservationDate) && (reservationDate <= enddate);
        });
        const allReservations: ReservationDTO[] = [];
        const allStandByReservations: StandByPartyDTO[] = [];
        switch (selectedValue) {
          case globals.reservationViewBy.Today:
            // case globals.reservationViewBy.Twodays:
            // case globals.reservationViewBy.Oneday:
            currentReservations.forEach(current => {
              current.Shifts.forEach((shift: DateShiftStateDTO) => {
                shift.Reservations.forEach((reservation: ReservationDTO) => {
                  allReservations.push(reservation);
                });
                partyData.push({
                  Shift: {
                    Name: shift.Shift.Name,
                    EffectiveRange: shift.Shift.EffectiveRange,
                    Id: shift.Shift.Id
                  },
                  Reservations: shift.Reservations
                });
                shift.StandByParties.forEach((reservation: StandByPartyDTO) => {
                  allStandByReservations.push(reservation);
                });
              });

            });
            const shifts = [...new Set(partyData.map(x => x.Shift.Name))];
            this.parties = [];
            shifts.forEach(shift => {
              this.parties.push({
                Shift: {
                  Name: shift
                },
                Reservations: []
              });
            });
            partyData.forEach(data => {
              this.parties.forEach(shift => {
                if (shift.Shift.Name == data.Shift.Name) {
                  shift.Reservations = shift.Reservations.concat(data.Reservations);
                }
              });
            });
            this.reservations = [...allReservations];
            this.standbyParties = cloneDeep(allStandByReservations);
            this.partyService.standbyPartiesList = this.standbyParties;
            this.partyService.StandbyParties$.next(this.standbyParties);
            this.allShiftsData = partyData.map((party: DateShiftStateDTO) => party.Shift);
            break;
          case globals.reservationViewBy.Week:
            this.reservations = [];
            const days = [...new Set(currentReservations.map(x => x.Date))];
            this.parties = [];
            days.forEach(currentDay => {
              this.parties.push({
                Shift: {
                  Name: format(new Date(currentDay), 'MM/DD'),
                  EffectiveRange: {
                    Start: startOfDay(currentDay),
                    End: endOfDay(currentDay)
                  }
                },
                Reservations: []
              });
            });

            currentReservations.forEach(data => {
              this.parties.forEach(shift => {
                if (shift.Shift.Name === format(data.Date, 'MM/DD')) {
                  data.Shifts.forEach(reservationShifts => {
                    shift.Reservations = shift.Reservations.concat(reservationShifts.Reservations);
                    reservationShifts.Reservations.forEach(reservation => {
                      this.reservations.push(reservation);
                    });
                  });
                }
              });
            });
        }
        if (this.selectedParty) {
          this.parties.forEach((reservation) => {
            if (reservation.Id === this.selectedParty.Id) {
              this.selectedParty = reservation;
            }
          });
        }
        this.totalReservations = cloneDeep(this.parties);
        this._as.reservationMenuCount = this.reservations && this.reservations.filter(x => x.State == PartyState.Pending && x.Type == PartyType.Reservation).length > 0 ?
          this.reservations.filter(x => x.State == PartyState.Pending && x.Type == PartyType.Reservation).length : 0;
        this.setTimerValues(this.reservations);
        this.dashboardFunctions.updateShiftName(this.allShiftsData);
        this.partyService.shifts$.next(this.allShiftsData);
        this.partyService.partiesList = this.reservations;
        this.filterBySeatingArea();
        if (triggeredFromListener)
        this.cs.signalRMessageCount = 0;
      }
    }));
  }
  loadReservationsLocal(data) {
    const partyData = [];
   
     // if (data) 
      {
        this.partyService.partiesList = [];
        this.tableAvailability = [];      
        let allReservations: ReservationDTO[] = [];
        let allStandByReservations: StandByPartyDTO[] = [];
         {        
   
            const shifts =  this.partyService.shifts$.value;
            allReservations=data.filter(x=>x.Type==PartyType.Reservation);
            allStandByReservations=this.partyService.StandbyParties$.value;
            this.reservations = [...allReservations];
            this.standbyParties = cloneDeep(allStandByReservations);
            this.partyService.standbyPartiesList = this.standbyParties;
           // this.partyService.StandbyParties$.next(this.standbyParties);
            this.allShiftsData = shifts;
            //break;
          }
        if (this.selectedParty) {
          this.parties.forEach((reservation) => {
            if (reservation.Id === this.selectedParty.Id) {
              this.selectedParty = reservation;
            }
          });
        }
        this.totalReservations = cloneDeep(this.parties);
        this._as.reservationMenuCount = this.reservations && this.reservations.filter(x => x.State == PartyState.Pending && x.Type == PartyType.Reservation).length > 0 ?
        this.reservations.filter(x => x.State == PartyState.Pending && x.Type == PartyType.Reservation).length : 0;
        this.setTimerValues(this.reservations);
        //this.partyService.shifts$.next(this.allShiftsData);
        //this.partyService.partiesList = this.reservations;
       // this.filterBySeatingArea();
       
      }
  
  }
  loadStandbysLocal(data) {
    let allStandByReservations: StandByPartyDTO[] = [];   
            allStandByReservations=data;            
            this.standbyParties = cloneDeep(allStandByReservations);
  }
  setupTableAvailablity(){
    this.partyService.getTableAvailablity(this._as._headerDate, this._as._headerDate).subscribe((data) => {

      if(data.Payload){
        let tempTableAvailabilityArr = [];
        let tableAvailability = data.Payload;
        tableAvailability.forEach(availableTable => {
        tempTableAvailabilityArr.push(availableTable);
        });
       this.tableAvailability =  tempTableAvailabilityArr.length > 0 ? tempTableAvailabilityArr : this.tableAvailability;
      }
    });
  }

  filterBySeatingArea() {
    if (this.showByPartySize) {
      let parties = orderBy(this.partyService.partiesList, 'Size', 'asc');
      this.partyService.Parties$.next(parties);
    } else {
      this.partyService.Parties$.next(this.partyService.partiesList);
    }
  }

  printChit(view) {

    this.componentDetails = {
      componentName: ChitPrintComponent,
      popupType: '',
      popupInput: { type: ReservationType.Reservation, contentView: this.contentView },
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
    this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '90%',
      width: '90%',
      backdropClass: 'backdropBackground',
      autoFocus: false,
      data: {
        title: this.ts.instant('printChit'), update: this.ts.instant('printText'), cancel: this.ts.instant('cancel'), componentDetails: this.componentDetails, popupType: '', standalone: true, from: ComponentTypes.printChit, printSectionId: "Chit-Print_Print-Section"
      }
    });
  }

  multiPaging() {
    const partyAvailability = this.SetMultiPagingButton(this.partyService.Parties$.value);
    let alertMessage = '';
    switch (partyAvailability) {
      case 'NoPartyAvailable':
        this.showMultiPaging = false;
        alertMessage = this.ts.instant('noPartiesToMsg');
        break;
      case 'LessPartyAvailable':
        this.showMultiPaging = false;
        alertMessage = this.ts.instant('maxPartiesToMsg');
        break;
      case 'AvailableForMultiPaging':
        this.showMultiPaging = true;
        this.componentDetails = {
          componentName: MultiPagingComponent,
          popupType: '',
          dimensionType: 'large',
          popUpDetails: {
            isStepper: false,
            eventName: 'notifyParent'
          },
        };
        this.dialog.open(CustomPopupComponent, {
          disableClose: true,

          height: '90%',
          width: '90%',
          backdropClass: 'backdropBackground',
          data: {
            title: 'multiplePaging', update: 'SAVE', cancel: 'CANCEL', componentDetails: this.componentDetails, popupType: '', standalone: true, showFooter: false
          }
        });

        break;
      case 'NotAvailableForMultiPaging':
        this.showMultiPaging = false;
        alertMessage = this.ts.instant('maxPartiesToMsg');
        break;
    }

    if (!this.showMultiPaging) {
      const popUpMessage = [{
        confirmationMessage: alertMessage, dialogTitle: 'confirm', showAlert: true
      }];
      const componentDetails: ComponentDetails = {
        componentName: ConfirmationPopupComponent,
        dimensionType: 'small',
        popupType: 'action',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: popUpMessage,
        popupTitle: this.ts.instant('alert')
      };
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '450px',
        height: 'auto',
        data: {
          title: this.ts.instant('alert'),
          showAction: true,
          update: 'ok',
          componentDetails,
          from: ComponentTypes.reservation,
          back: false,
          standalone: true,
          showAlert: true
        }
      });
    }
  }

  SetMultiPagingButton(allReservations) {
    const filteredReservations = [];
    this.MultiPagingAvailability = 0;

    if (allReservations.length < 1) {
      this.showMultiPaging = false;
      return 'NoPartyAvailable';
    } else if (allReservations.length === 1) {
      this.showMultiPaging = false;
      return 'LessPartyAvailable';
    } else if (allReservations.length > 1) {
      allReservations.forEach(reservation => {
        if ((reservation.PageMethod === PageMethod.Email || reservation.PageMethod === PageMethod.Sms
          || reservation.PageMethod === PageMethod.Sms2 || reservation.PageMethod === PageMethod.Email2) && reservation.State === PartyState.Pending) {
          filteredReservations.push(reservation);
          this.MultiPagingAvailability++;
        }
      });
      if (this.MultiPagingAvailability >= 2) {
        let sortedReservations = sortBy(filteredReservations, x => x.ReservedFor);
        this.partyService.filteredReservations = sortedReservations;
        this.showMultiPaging = true;

        return 'AvailableForMultiPaging';
      } else {
        this.showMultiPaging = false;
        return 'NotAvailableForMultiPaging';
      }
    } else {
      this.showMultiPaging = false;
      return '';
    }
  }

  getPastReservationStatus() {
    let selectedDate = Utilities.Date(this._as.headerDate$.value);
    let restaurantDate = new Date(Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta));
    let diff = Utilities.diffBetweenDates(selectedDate, restaurantDate);
    if (Utilities.diffBetweenDates(selectedDate, restaurantDate) >= 0) {
      return false;
    } else {
      return true;
    }
  }
  create(btnType: ButtonValue) {
    this.isPartySelected = true;
    switch (btnType.actionName) {
      case ReservationStatus.Arrived:
        break;
      case ReservationStatus.Cancel:
        this.cancelParty(this.partyService.selectedParty$.value);
        break;
      case ReservationStatus.ReleaseTable:
        this.subscriptions.add(this.partyService.unseatParty(this.partyService.selectedParty$.value.Id).subscribe((response) => {
          this.partyService.MoveCheck();
        }));
        break;
      case ReservationStatus.ClearTable:
        this.subscriptions.add(this.partyService.clearParty(this.partyService.selectedParty$.value.Id).subscribe((response) => { }));
        break;
      case ReservationStatus.Reopen:
        break;
      case ReservationStatus.UndoNoShow:
        this.partyService.tryUndoNoShowReservation = true;
        this.dashboardFunctions.createOrEditReservation(true, this.partyService.selectedParty$.value);
        break;
      case ReservationStatus.Reservation:
        this.dashboardFunctions.createOrEditReservation(false, null);
        break;
      case ReservationStatus.SeatParty:
        this.seatParty(this.partyService.selectedParty$.value);
        break;
      case ReservationStatus.Reseat:
        this.reSeatParty(this.partyService.selectedParty$.value);
        break;
    }
  }

  cancelParty(party) {
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
    if (this._as.OTASourceId.includes(party.PartySourceId)) {
      this.dashboardFunctions.showOTAAlert(party);
      return;
    }
    let cancelText = this.ts.instant('no');
    let title = this.ts.instant('cancelConfirmationMsg');
    let noShowSet = false;
    let cancelReservation = false;
    let updateText = 'Yes';
    let showAlert = false;
    let noShowFeePopUp = false;
    let isWebReservation = false;

    if(party && party.PartySourceId){
      isWebReservation = this.partyService.isWebReservation(party.PartySourceId);
    }

    let msg = isWebReservation? this.ts.instant('cancelReservationForWeb') : this.ts.instant('cancelReservation');
    msg = this.ts.instant('cancelReservation');

    if (Utilities.Date(Utilities.getRestaurantDateTime(this.generalSettings.General.DaylightDelta)).diff(Utilities.Date(party.ReservedFor)) > 0) {
      msg = this.ts.instant('reservationCancelMsg');
      cancelText = this.ts.instant('cancelReservationMsg');
      cancelReservation = false;
      updateText = this.ts.instant('noShow');
      title = this.ts.instant('alert');
      showAlert = true;
      noShowFeePopUp = true;
    }

    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm', showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '350px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      if (cancelReservation) {
        this.subscriptions.add(this.partyService.cancelReservation(party.Id).subscribe(
          (data) => {
            if (data.Payload != null) {
              this.partyService.reservationType = ReservationType.Reservation;
              if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == 1) {
                const message = `${this.ts.instant('refundFor')} ${this.cs.operationCurrency} ${data.Payload.RefundAmount} ${this.ts.instant('refundForCancellation')}`;
                this.partyService.openConfirmationDialog(data, message, party.SeatingTime);
              }
              if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == 2) {
                const message = `${this.ts.instant('attemptedRefund')} ${this.cs.operationCurrency} ${data.Payload.RefundAmount} ${this.ts.instant('refundFailed')}`;
                this.partyService.openConfirmationDialog(data, message, party.SeatingTime);
              }
              if (data.Payload && (this.cs.settings.value.General.HostCancellationEmailSendBehavior == PartyEmailSendBehavior.Prompt)) {
                this.partyService.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Cancelled);
              }
            }
            cancelReservation = false;
          }));
      } else if (noShowSet && noShowFeePopUp) {
        this.subscriptions.add(this.partyService.noShowParty(party.Id).subscribe((data) => {
          if (data.State == OperationResultState.Success) {
            noShowSet = false;
          }
        }));
      }
      if (this.confirmSubscription) {this.confirmSubscription.unsubscribe();}
      if (this.cancelSubscription) {this.cancelSubscription.unsubscribe();}
    }));

    this.confirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.reservation && noShowFeePopUp) {
        noShowSet = true;
      }
      if (val === ComponentTypes.reservation && !noShowFeePopUp) {
        cancelReservation = true;
      }
    });
    this.cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.value === ComponentTypes.reservation && !cancelReservation && noShowFeePopUp) {
        cancelReservation = true;
      }
    });
  }

  seatParty(party: any) {
    this.dashboardFunctions.seatPartyWithConfimation(party);
  }

  reSeatParty(party: any) {
    if (this.dashboardFunctions.IsReseatApplicable(party)) {
      this.subscriptions.add(this.partyService.reseatParty(party).subscribe(data => {
        if (data) {
          if (data.State === OperationResultState.Success) {
          }
        }
      }));
    }
  }



  actions(selectedAction: any) {
    const actions = globals.actionsIcons;
    switch (actions[selectedAction.index]) {
      case actions[0]:
        this.dashboardFunctions.createOrEditReservation(true, this.partyService.Parties$.value.find(data => selectedAction.partyInfo.Id == data.Id) ||selectedAction.partyInfo);
        break;
      case actions[1]:
        break;
      case actions[2]:
        break;
      case actions[3]:
        break;
      case actions[4]:
        break;
    }
  }

  calculateWidth(shift: DateShiftDTO) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '27px Roboto-Light';
    const metrics = context.measureText(shift.Name);
    if (canvas) {
      canvas.remove();
    }
    const elementId = `food_time-${shift.Name}`;
    const shiftEle = document.getElementById(elementId);
    if (shiftEle) {
      shiftEle.style.width = (Math.ceil(metrics.width)) + 'px';
      const dividerId = `divider-${shift.Name}`;
      const dividerEle = document.getElementById(dividerId);
      dividerEle.style.width = 'calc(100% - ' + (Math.ceil(metrics.width)) + 'px)';
    }
  }
  navigateToTablesOrTimeline(navigateTo: number, shift: any) {
    this.partyService.navigationShiftIdFromReservation = shift.Id;
    switch (navigateTo) {
      case 0:
        this.router.navigate(['./tables']);
        this._as.selectedMenuId = Menu.TimeLine;
        break;
      case 1:
        this.router.navigate(['./timeline']);
        this._as.selectedMenuId = Menu.TimeLine;
        break;
    }
  }
}

@Pipe({
  name: 'expandPanelWithShift'
})
export class ExpandPanelWithShiftPipe implements PipeTransform {

  transform(shiftRange: any, daylightDelta: string): any {
    let effectiveStartDate: Date;
    let effectiveEndDate: Date;
    if (typeof shiftRange.Start === 'string' && typeof shiftRange.End === 'string') {
      effectiveStartDate = shiftRange.Start.includes('Z') ? Utilities.parseDateString(shiftRange.Start.split('Z')[0]) :
        Utilities.parseDateString(shiftRange.Start);
      effectiveEndDate = shiftRange.End.includes('Z') ? Utilities.parseDateString(shiftRange.End.split('Z')[0]) :
        Utilities.parseDateString(shiftRange.End);
    } else {
      effectiveStartDate = Utilities.parseDateString(shiftRange.Start);
      effectiveEndDate = Utilities.parseDateString(shiftRange.End);
    }
    const restaurantDate = Utilities.getRestaurantDateTime(daylightDelta);
    return restaurantDate >= effectiveStartDate && restaurantDate <= effectiveEndDate;
  }
}

@Pipe({
  name: 'showTableAvailability'
})
export class ShowTableAvailabilityPipe implements PipeTransform {

  transform(tableAvailabilityPerShift: TableAvailabilityDTO[], shiftId: number): any {
    let ava = tableAvailabilityPerShift.filter(t => t.ShiftId == shiftId);
    if (ava && ava.length) {
      return '(' + ava[0].AvailableTablesForShift + '/' + ava[0].TotalTablesForShift + ')';
    }
    return;
  }
}

@Pipe({
  name: 'onlyReservation'
})
export class OnlyReservationPipe implements PipeTransform {

  transform(allParties: any): any { 
    if(allParties){
      allParties = allParties.filter((party: ReservationDTO) => {      
        return (party.Type == PartyType.Reservation);
      });
      return allParties;    
    }else{
      return [];
    }
  }

}
