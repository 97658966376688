import { Injectable } from '@angular/core';
import { NonIntegratedCreditCardConfiguration,StoreTokenForNonIntegratedCreditCard } from '../../shared/service/payment/payment-model';
import { BaseResponse, ClientCreditCardInfo } from '../../retail.modals';
import { Host } from '../../shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { RetailRoutes } from '../../retail-route';
import { CardInfo, PaymentBaseResponse } from '../../shared/business/shared.modals';
import { PayAgentService } from '../../shared/service/payagent.service';

@Injectable({
  providedIn: 'root'
})
export class NonIntegratedCreditCardService {

  constructor(private http: HttpServiceCall,private _payAgentService: PayAgentService) { }

  public async getNonIntegratedCreditCardConfiguration(): Promise<NonIntegratedCreditCardConfiguration> {
    let result: NonIntegratedCreditCardConfiguration;
    try {
      return await this.InvokeNonIntegratedCreditCardConfigurationCalls<NonIntegratedCreditCardConfiguration>(Host.payment, RetailRoutes.GetNonIntegratedCreditCardConfiguration, HttpMethod.Get);

    }
    catch (ex) {
      console.log(ex);
    }
    return result;
  }

  public async getIFrameUrl(): Promise<string> {
    let result: string;
    try {
      return await this.InvokeNonIntegratedCreditCardConfigurationCalls<string>(Host.payment, RetailRoutes.GetIFrameURL, HttpMethod.Get);

    }
    catch (ex) {
      console.log(ex);
    }
    return result;
  }

  public async createOrUpdateNonIntegratedCreditCardConfiguration(body: NonIntegratedCreditCardConfiguration): Promise<NonIntegratedCreditCardConfiguration> {
    try {
      return await this.InvokeNonIntegratedCreditCardConfigurationCalls<NonIntegratedCreditCardConfiguration>(Host.payment, RetailRoutes.UpdateNonIntegratedCreditCardConfiguration, HttpMethod.Put, body);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }


  public async StoreAndGetSwippedCardInfo(storeTokenResponse: any): Promise<[CardInfo, number]> {
    let newCardInfo: CardInfo;
    newCardInfo = {
      cardNumber: storeTokenResponse.cardInfo.accountNumberMasked,
      cardHolderName: storeTokenResponse.cardInfo.cardholderName,
      entryMode: storeTokenResponse.cardInfo.entryMode,
      issuerType: storeTokenResponse.cardInfo.cardIssuer,
      cardExpiration: storeTokenResponse.cardInfo.expirationYearMonth,
      cardType: storeTokenResponse.cardInfo.cardType,
      accessUrl:storeTokenResponse.accessUrl
    };


    let storeTokenReq: StoreTokenForNonIntegratedCreditCard = {
      cardInfo: newCardInfo,
      token : storeTokenResponse?.token,
      accessUrl: storeTokenResponse?.accessUrl,
      payResponse:JSON.stringify(storeTokenResponse)
    };

    const baseResponse: PaymentBaseResponse = await this.InvokeNonIntegratedCreditCardConfigurationCalls<PaymentBaseResponse>(Host.payment, RetailRoutes.StoreTokenForNonIntegratedCreditCard, HttpMethod.Post,storeTokenReq);


    newCardInfo.cardNumber = this._payAgentService.MaskCreditCardNumber(newCardInfo.cardNumber);
    newCardInfo.cardExpiration = this._payAgentService.formatCreditCardExpiryDate(newCardInfo.cardExpiration);
    return [newCardInfo, baseResponse.transactionId];
  }

  private async InvokeNonIntegratedCreditCardConfigurationCalls<T>(host: Host, callDesc: string, callType: HttpMethod, body?: any, uRIParams?: any): Promise<T> {

    try {
      let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
        callDesc: callDesc,
        host: host,
        method: callType,
        body: body,
        uriParams: uRIParams
      });

      return response.result;
    }
    catch (ex) {
      return null;
    }
  }

}
