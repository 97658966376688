<section class="newSupplier h-100">
  <div class="leftSide">
      <mat-dialog-content>
          <ng-scrollbar>
      <form [formGroup]="FormGrp" autocomplete="off" class="pl-4 createForm float-left">


            <div class="pt-4">
                <mat-form-field class="LW14 pr-3 codefield" [floatLabel]="floatLabel">
                    <input [attr.automationId]="'Txt_retailCreateNewSupplier_code'" matInput placeholder="{{textCaptions.Code}}" name="code" class="text-capitalize" formControlName="code" required />
                    <mat-error *ngIf="FormGrp.controls['code'].hasError('required') && FormGrp.controls['code'].touched && !FormGrp.controls['code'].valid">
                      {{textCaptions.MissingCode}}
                    </mat-error>
                 </mat-form-field>

                 <mat-form-field class=" LW14 namefield" [floatLabel]="floatLabel">
                    <input [attr.automationId]="'Txt_retailCreateNewSupplier_name'" matInput placeholder="{{textCaptions.Name}}" name="name" class="text-capitalize" formControlName="name" required />
                    <mat-error *ngIf="FormGrp.controls['name'].hasError('required') && FormGrp.controls['name'].touched && !FormGrp.controls['name'].valid">
                     {{textCaptions.MissingName}}
                    </mat-error>
                 </mat-form-field>
            </div>
            <div class="pt-4">
                <mat-form-field class="LW14 accountrepfield" [floatLabel]="floatLabel">
                    <input [attr.automationId]="'Txt_retailCreateNewSupplier_accountRep'" matInput placeholder="{{textCaptions.AccountRep}}" name="accountrep" class="text-capitalize" formControlName="accountrep" required/>
                    <mat-error *ngIf="FormGrp.controls['accountrep'].hasError('required') && FormGrp.controls['accountrep'].touched && !FormGrp.controls['accountrep'].valid">
                      {{textCaptions.MissingAccountRep}}
                    </mat-error>
                  </mat-form-field>
            </div>
            <h5 class="pt-4">{{textCaptions.Contact}}</h5>
            <div class="pt-2">
                <div *ngFor="let item of FormGrp.get('phone')['controls']; let i = index;" formArrayName="phone" class="clearfix ">
                    <div class="phone-controls-wrapper float-left width-490px">
                      <div>
                        <mat-form-field class="inputwidth20 LW14 width-90px" [formGroupName]="i" [floatLabel]="floatLabel">
                          <mat-select [attr.automationId]="'Dd_retailCreateNewSupplier_phoneNo'" placeholder="{{textCaptions.Phone}}" id="PhoneNumberLabel" name="TSpdd" class="LW14"
                            formControlName="PhoneNumberLabel">
                            <mat-option *ngFor="let ct of contactTypePhone" [value]="ct.id">{{ct.description}}
                            </mat-option>
                          </mat-select>
                          <mat-error>
                             {{validatePhoneType}}
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="inputwidth70 pl-2 LW14 width-287px" [formGroupName]="i" [floatLabel]="floatLabel">
                          <input [attr.automationId]="'Txt_retailCreateNewSupplier_phoneNo'" matInput name="TSpi" [maxlength]="30" inputtype="onlynumber" textmask="{{textmaskFormat}}" id="PhoneNumber" required formControlName="PhoneNumber">
                          <mat-error class="" *ngIf="FormGrp.controls['phone'].touched && !FormGrp.controls['phone'].valid">
                            {{textCaptions.InvalidPhoneNumber}}
                          </mat-error>
                        </mat-form-field>


                        <span (click)="removePhoneItem(i,'','');"  *ngIf="i != 0" class="icon-Minus buttonCust1 m-md-2 text-color"></span>

                        <span class="icon-Plus buttonCust1 m-md-2 text-color" (click)="addPhoneItem(i, '', '');" *ngIf="(i == currentIndexPhone && contactTypePhone.length > i)"></span>
                      </div>

                    </div>

                  </div>

            </div>
            <div class="pt-4">
                <div *ngFor="let item of FormGrp.get('email')['controls']; let j = index;" formArrayName="email" class="w-100 emailDiv">
                    <div class="float-left width-490px" [formGroupName]="j">
                      <mat-form-field class="inputwidth20 LW14 width-90px" [floatLabel]="floatLabel">
                        <mat-select [attr.automationId]="'Dd_retailCreateNewSupplier_email'" [placeholder]="textCaptions.Email" id="EmailSelect" name="TSedd"
                          formControlName="EmailLabel" class="LW14">
                          <mat-option *ngFor="let ct of contactTypeEmail" [value]="ct.id">{{ct.description}}
                          </mat-option>
                        </mat-select>
                          <mat-error>
                           {{validateEmailType}}
                          </mat-error>
                      </mat-form-field>
                      <mat-form-field class="inputwidth70 pl-2 LW14 width-287px" [floatLabel]="floatLabel">
                        <input [attr.automationId]="'Txt_retailCreateNewSupplier_email'" matInput name="TSei" [maxlength]="30" inputtype="email" id="EmailInput"
                          formControlName="EmailId" required>
                          <mat-error class="" *ngIf="!FormGrp.controls['email'].valid && FormGrp.controls['email'].touched && !this.FormGrp['controls'].email.value[j].EmailId ==  null ">
                           <!-- Invalid Email Format -->
                           {{textCaptions.InvalidEmailFormat}}
                          </mat-error>
                          <mat-error class="" *ngIf="!FormGrp.controls['email'].valid && FormGrp.controls['email'].touched && !this.FormGrp['controls'].email.value[j].EmailId">
                            <!-- Missing Email Address -->
                            {{textCaptions.MissingEmail}}
                          </mat-error>
                      </mat-form-field>


                      <span (click)="removeEmailItem(j,'','');" *ngIf="j != 0" class="icon-Minus buttonCust1 m-md-2 text-color"></span>

                      <span class="icon-Plus buttonCust1 m-md-2 text-color" (click)="addEmailItem(j,'','');" *ngIf="(j == currentIndexemail && contactTypeEmail.length > j) "></span>
                    </div>

                  </div>
            </div>
            <h5 class="pt-4 clearBoth">{{textCaptions.Address}}</h5>
            <div class="pt-2">
              <mat-form-field class="LW14 addressfiled" [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_retailCreateNewSupplier_address'" matInput placeholder="{{textCaptions.Address}}" name="address" class="text-capitalize" formControlName="address" />
                  <mat-error *ngIf="FormGrp.controls['address'].hasError('required') && FormGrp.controls['address'].touched && !FormGrp.controls['address'].valid">
                    {{textCaptions.MissingAccountRep}}
                  </mat-error>
                </mat-form-field>
            </div>
            <div class="pt-4">
                <mat-form-field class="LW14 codefield" [floatLabel]="floatLabel">
                    <input [attr.automationId]="'Txt_retailCreateNewSupplier_postalCode'" matInput placeholder="{{textCaptions.PostalCode}}" name="address" class="text-capitalize" formControlName="postalcode" />
                    <mat-error *ngIf="FormGrp.controls['postalcode'].hasError('required') && FormGrp.controls['postalcode'].touched && !FormGrp.controls['postalcode'].valid">
                      {{textCaptions.MissingAccountRep}}
                    </mat-error>
                  </mat-form-field>
              </div>
              <div class="pt-4">

                  <mat-form-field class="LW14 codefield" [floatLabel]="floatLabel">
                      <input [attr.automationId]="'Txt_retailCreateNewSupplier_account'" matInput placeholder="{{textCaptions.Account}}" name="address" class="text-capitalize" formControlName="account" />
                      <mat-error *ngIf="FormGrp.controls['account'].hasError('required') && FormGrp.controls['account'].touched && !FormGrp.controls['account'].valid">
                        {{textCaptions.MissingAccountRep}}
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field class="pl-3 LW14 codefield" [floatLabel]="floatLabel">
                        <input [attr.automationId]="'Txt_retailCreateNewSupplier_terms'" matInput placeholder="{{textCaptions.Terms}}" name="address" class="text-capitalize" formControlName="terms" />
                        <mat-error *ngIf="FormGrp.controls['terms'].hasError('required') && FormGrp.controls['terms'].touched && !FormGrp.controls['terms'].valid">
                          {{textCaptions.MissingAccountRep}}
                        </mat-error>
                      </mat-form-field>
                </div>
                <div class="pt-4">

                    <mat-form-field class="LW14 codefield" [floatLabel]="floatLabel">
                        <input [attr.automationId]="'Txt_retailCreateNewSupplier_ap'" matInput placeholder="{{textCaptions.AP}}" name="address" class="text-capitalize" formControlName="ap" />
                        <mat-error *ngIf="FormGrp.controls['ap'].hasError('required') && FormGrp.controls['ap'].touched && !FormGrp.controls['ap'].valid">
                          {{textCaptions.MissingAccountRep}}
                        </mat-error>
                      </mat-form-field>
                  </div>


        </form>
        <div class="comment float-left pl-4">
            <div class="pt-4">
                <mat-form-field class="LW14 codefield" [floatLabel]="floatLabel">
                    <input [attr.automationId]="'Txt_retailCreateNewSupplier_comment'" matInput placeholder="{{textCaptions.Comment}}" name="comment" class="text-capitalize" formControlName="comment" />
                    <mat-error *ngIf="FormGrp.controls['comment'].hasError('required') && FormGrp.controls['comment'].touched && !FormGrp.controls['comment'].valid">
                      {{textCaptions.MissingAccountRep}}
                    </mat-error>
                  </mat-form-field>
              </div>

            </div>
          </ng-scrollbar>
          </mat-dialog-content>
  </div>

  <div mat-dialog-actions class="actions-div">
      <div class="pl-4">
        <button [attr.automationId]="'Btn_retailCreateNewSupplier_save'" [ngClass]="( FormGrp.valid) ? 'button--primary-save' : 'button--disabled'"
          mat-button [disabled]="!FormGrp.valid" (click)="saveServiceGroup(FormGrp.value)">{{clickbutton}}</button>
        <button [attr.automationId]="'Btn_retailCreateNewSupplier_cancel'" class="spa-button-cancel" mat-button (click)="onNoClick()">CANCEL</button>
      </div>
    </div>
</section>
