import { Component, ViewChild } from '@angular/core';
import { PartyDTO, SeatingAreaDTO, SeatingTypesDTO, SettingsDTO, SpecialMealDTO, TableSuggestionMode } from '@app/shared/models/RestaurantDTO';
import { ButtonValue, DropDownConfig, FieldConfig } from '@dynamicform/models/field-config.interface';
import { CacheService } from '@core/services/cache.service';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, uniqBy } from 'lodash';
import { PartyService } from '@services/party.service';
import { ComponentTypes, ConciergeTrackingType, ReservationType, buttonTypes } from '@app/shared/constants/commonenums';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { Utilities } from '@app/shared/utilities/utilities';
import { Subscription } from 'rxjs';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { MatDialog } from '@angular/material/dialog';
import { HotelConciergeListComponent } from '../hotel-concierge-list/hotel-concierge-list.component';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { AppService } from '@app/app.service';
import { PopupService } from '@app/popup-module/popup.service';
import moment from 'moment';

@Component({
  template: ''
})
export class SpecialMealConfig {
  @ViewChild('specialMealDetails', { static: true }) specialMealForm: DynamicFormComponent;
  @ViewChild('coverTypeDetails', { static: true }) coverTypeForm: DynamicFormComponent;
  @ViewChild('partySizeDetails', { static: true }) partySizeForm: DynamicFormComponent;
  @ViewChild('bookingFormDetails', { static: true }) bookingForm: DynamicFormComponent;
  @ViewChild('dateFormDetails', { static: true }) dateForm: DynamicFormComponent;
  bookingFormConfig: FieldConfig[] = [];
  specialMealConfig: FieldConfig[]= [];
  dateConfig: FieldConfig[]= [];
  coverTypeConfig: FieldConfig[]= [];
  partySizeConfig: FieldConfig[]= [];
  buttonTertiary: ButtonValue;
  seatingArea: SeatingAreaDTO[];
  seatingType: SeatingTypesDTO[];
  specialMeals: SpecialMealDTO[];
  seatingTypeData: DropDownConfig[];
  partySizeArray: any = [];
  settings: SettingsDTO;
  mealData: PartyDTO;
  displayLegends: any;
  restaurantDate: Date;
  tableSuggestionMode: TableSuggestionMode;
  subscriptions: Subscription = new Subscription();
  conciergeTrackingList: any;
  showConcierge: boolean = false;
  confirmButton: ButtonValue;
  cancelButton: ButtonValue;
  reservationDate: Date;



  constructor(protected ts: TranslateService, protected cs: CacheService, protected partyService: PartyService, protected appService: AppService, protected dashboardFunctions: DashboardFunctions, protected ps: PopupService, public dialog: MatDialog) { }

  getLegends() {
    this.displayLegends = [{ State: 9, name: this.ts.instant('availableForStandby') },
    { State: 8, name: this.ts.instant('partyStatusNotAvailableText') },
    { State: 7, name: this.ts.instant('partyStatusAvailableText') },
    { State: 11, name: this.ts.instant('mayBeOverbookedText') }];
  }
  loadButtonConfig() {
    this.confirmButton = {
      type: buttonTypes.actionPrimarySmall,
      label: this.ts.instant('Ok'),
      disbaledproperity: false,
      customclass: 'warning-button',
    };
    this.cancelButton = {
      type: buttonTypes.actionSecondarySmall,
      label: this.ts.instant('Cancel'),
      disbaledproperity: false,
      customclass: 'warning-button-cancel',
    };
    this.buttonTertiary = {
      type: buttonTypes.actionSecondary,
      label: this.ts.instant('selectTable'),
      customclass:'table-action',
      disbaledproperity: this.mealData ? false : true,
      icon: 'icon-TableServer mr-2'
    };
  }
  formConfig() {
    this.getReservationDate();
    this.specialMealConfig = [
      {
        type: 'autocomplete',
        name: 'SpecialMealId',
        inputType: 'number',

        appearance: false,
        validation: [],
        label: this.ts.instant("specialMeal_Event"),
        value: this.mealData && this.mealData.SpecialMealId ? this.mealData.SpecialMealId : -1,
        options: this.getSpecialMealList(),
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        autoCompleteWithId: true,
        returnFunction: this.clearSpecialMeal.bind(this),
        disableErrorStateMatcher: true,
        onfocusOutClick: this.onFocusOutClick.bind(this),
        disabled: this.mealData ? true : false,
      }
    ];
    const maxPartySize: number = this.cs.settings.value.General.MaxPartySize;
    this.partySizeConfig = [
      {
        type: 'autocomplete',
        name: 'size',
        label: this.ts.instant("partySize"),
        options: this.partySizeArray,
        directive: 'numberOnly',
        showErrorText: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        appearance: false,
        isTranslate: false,
        value: this.mealData ? this.mealData.Size : 2,
        partySize: true,
        charLength: maxPartySize.toString().length,
        // cellClick: (event) => { this.cellClick(event) },
        returnFunction: (event) => { this.clearSize(event) },
        validation: [Validators.required, Validators.min(1)],
        class: 'open-reservation__party-size'
      }
    ];
    this.partySizeConfig[0].options = this.calculatePartySize(1, 51, true);
    
    const sessionGUIDKey = `sessionGUID${Utilities.getSessionStorageType()}`;
    const loginResultKey = `${sessionStorage.getItem(sessionGUIDKey)}_loginResult`;
    const loginResult = localStorage.getItem(loginResultKey);    
    let hostId = loginResult ? JSON.parse(loginResult).HostId : undefined;
    
      this.dateConfig = [
        {
          type: 'date',
          name: 'date',
          inputType: 'text',
          label: 'Date',
          value: this.reservationDate,
          appearance: false,
          class: 'w-100',
          minDate: this.restaurantDate
        },
      ]
    this.bookingFormConfig = [
      {
        type: 'select',
        label: 'seatingArea',
        name: 'SeatingAreaId',
        options: this.seatingArea,
        value: this.mealData?.SeatingAreaId || -1,
        showErrorText: true,
        isTranslate: false,
        class: 'w-100',
        cellClick: (event) => { this.setSeatingAreaBasedOnType(event); }
      },
      {
        type: 'select',
        label: 'seatingType',
        name: 'SeatingTypeId',
        options: this.seatingType,
        value: this.mealData?.SeatingTypeId || -1,
        showErrorText: true,
        isTranslate: false,
        class: 'w-100'
      },
      {
        type: 'select',
        label: 'hostText',
        name: 'HostId',
        options: this.settings.Hosts?.map(host => { return { id: host.Id, value: host.Name } }),
        value: this.mealData ? this.mealData.HostId : hostId,
        showErrorText: true,
        isTranslate: false,
        class: 'w-100'
      },
      {
        type: 'input',
        name: 'PagerNumber',
        inputType: 'text',
        label: 'pager',
        class: 'w-100',
        value: this.mealData?.PagerNumber || null,
      },
      {
        type: 'select',
        name: 'StatusId',
        options: this.setPartyStatusOptions(),
        label: 'Party Status',
        showColor: true,
        class: 'w-100',
        value: this.mealData && this.mealData.StatusId ? this.mealData.StatusId : -1
      },

    ];
    if (this.settings.General.AllowHotelConciergeTracking && (this.partyService.reservationType === ReservationType.Reservation || this.partyService.standbyConversion) && this.settings.General.AllowHotelConciergeTracking && !this.partyService.isStandbySlotSelected) {
      this.bookingFormConfig.push({

        type: 'select',
        name: 'HotelId',
        label: 'hotelConciergelbl',
        class: 'w-100',
        options: [],
        value: null,
        onClick: (event) => { this.getConciergeTrackingList(ConciergeTrackingType.Hotel); }
      })
    }
    if (this.mealData?.ConciergeId) {
      this.addConciergeField();
    }
    this.coverTypeConfig = this.getCoverTypeConfig([]);
    this.setSeatingArea();
    this.setSeatingType();
  }
  getReservationDate() {
    let reservationDate;
    if (this.mealData) {
      switch (this.partyService.reservationType) {
        case ReservationType.Reservation:
          reservationDate = Utilities.formateDateString(this.mealData.ReservedFor);
          break;
        case ReservationType.StandbyParties:
          reservationDate = Utilities.formateDateString(this.mealData.WishedTime);
          break;
        default:
          reservationDate = this.restaurantDate;
      }
    } else {
      reservationDate = this.appService.headerDate$.value > this.restaurantDate ? this.appService.headerDate$.value : this.restaurantDate
    }
    this.reservationDate = reservationDate;
  }
  onFocusOutClick() {
    if (this.ps.reservationData.selectedMeal) {
      this.specialMealForm.form.get('SpecialMealId').setValue(this.ps.reservationData.selectedMeal.Id, { emitEvent: false });
    } else {
      this.specialMealForm.form.get('SpecialMealId').setValue(-1, { emitEvent: false });
    }
  }
  clearSpecialMeal() {
    this.specialMealForm.form.get('SpecialMealId').setValue(null, { emitEvent: false });
  }
  clearSize(event) {
    this.partySizeForm.form.get('size').setValue(null);
  }
  clearSalesContact() {
    this.bookingForm.form.get('SalesContactIds').setValue([]);
  }
  loadHosts() {
    this.settings.Hosts.forEach(host => {

    });
  }
  getCoverTypeConfig(coverTypes): FieldConfig[] {
    let _config: FieldConfig[] = [];

    coverTypes.sort((a, b) => (a.CoverTypeName || a.Name).localeCompare(b.CoverTypeName || b.Name)).forEach(coverType => {
      let activeType = this.settings.CoverTypes.find(_sCoverType => _sCoverType.Id === coverType.Id);

      if (this.mealData || activeType) {
        let field = {
          type: 'select',
          label: coverType.CoverTypeName ? coverType.CoverTypeName : coverType.Name,
          name: coverType.Id,
          value: (this.mealData && this.mealData?.CoverTypeQuantities && this.mealData.CoverTypeQuantities.filter(ct => ct.CoverTypeId == coverType.Id && ct.Covers > 0).length > 0) ? this.partySizeArray[this.mealData.CoverTypeQuantities.filter(ct => ct.CoverTypeId == coverType.Id)[0].Covers - 1].id : 0,
          options: [{ id: 0, value: this.ts.instant("0") }, ...this.partySizeArray],
          class: "basic-select",
          showErrorText: true,
          isTranslate: false,
          validation: [Validators.required],
          toolTipEnabled: true,
          inputType: 'number',
          isRemoved: !activeType
        } as FieldConfig;
        _config.push(field);
      }
    })

    return _config;
  }
  getSpecialMealList() {
    let restaurantTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    let date = this.dateForm?.value?.date ? this.dateForm?.value?.date : this.reservationDate;
    date = moment(new Date(date), 'YYYY-MM:DD');
    let activeMeal = this.specialMeals.filter(meal => !meal.EndDate || (Utilities.datetimeBetween(meal.StartDate,meal.EndDate,date) && this.checkAvailableDay(meal, date)));
    let meals = activeMeal?.length ? activeMeal.map(meal => { return { id: meal.Id, value: meal.Name, icon: 'icon-res-payment-Paid reservation-charge__paymentIcon' } }) : [];
    meals.unshift({ id: -1, value: this.ts.instant('None'), icon: null });
    return meals;
  }
  checkAvailableDay(meal: SpecialMealDTO, date) {
    let dayOfWeek = new Date(date).getDay();
    return meal.ActivitySessions.find(session => session.Dayofweek == dayOfWeek) ? true : false;
  }
  setUpConciergeDetails() {
    this.subscriptions.add(this.partyService.getConcierge(this.mealData.ConciergeId).subscribe(response => {
      if (response.Payload) {
        let hotel = response.Payload.Hotel
        let hotelIdIndex = this.bookingFormConfig.findIndex(config => config.name == 'HotelId');
        this.bookingFormConfig[hotelIdIndex].options = [{ id: hotel.Id, value: hotel.Name }];
        this.bookingForm.form.get('HotelId').setValue(hotel.Id);
        if (this.mealData.ConciergeId) {
          let index = this.bookingFormConfig.findIndex(config => config.name == 'ConciergeId');
          this.bookingFormConfig[index].options = [{ id: response.Payload.Id, value: response.Payload.Name }];
          this.bookingForm.form.get('ConciergeId').setValue(response.Payload.Id);
        }
      }
    }));
  }


  getConciergeTrackingList(type: ConciergeTrackingType) {
    this.conciergeTrackingList = { conciergeTrackingType: type, response: [] };
    let hotelId = this.partyService.reservationFormGroup.value.selectedHotelId ? this.partyService.reservationFormGroup.value.selectedHotelId : null;
    this.subscriptions.add(this.partyService.getConciergeTrackingList(type, hotelId).subscribe(data => {
      if (data.Payload) {
        this.conciergeTrackingList.response = data.Payload;
        this.hotelConciergePopUp(this.conciergeTrackingList);
      }
    }));
  }

  hotelConciergePopUp(conciergeTrackingList) {
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(HotelConciergeListComponent, 'small', 'active',
      conciergeTrackingList, '');

    const conciergePopUpRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '300px',
      autoFocus: false,
      data: {
        title: this.ts.instant(conciergeTrackingList.conciergeTrackingType == ConciergeTrackingType.Hotel ? "editHotel" : "editConcierge"),
        showFooter: false,
        componentDetails,
        from: ComponentTypes.hotelConciergeList,
        standalone: true,
        back: false
      }
    });
    this.partyService.conciergeTrackingDialogRef = conciergePopUpRef;
    this.subscriptions.add(conciergePopUpRef.afterClosed().subscribe((item) => {
      if (item) {
        if (this.conciergeTrackingList.conciergeTrackingType == ConciergeTrackingType.Hotel) {
          let index = this.bookingFormConfig.findIndex(config => config.name == 'HotelId');
          this.bookingFormConfig[index].options = [{ id: item.Id, value: item.Name }];
          this.bookingForm.form.get('HotelId').setValue(item.Id);
          if (!this.bookingFormConfig.find(config => config.name == 'ConciergeId')) {
            this.addConciergeField();
          }
        } else {
          let index = this.bookingFormConfig.findIndex(config => config.name == 'ConciergeId');
          this.bookingFormConfig[index].options = [{ id: item.Id, value: item.Name }];
          this.bookingForm.form.get('ConciergeId').setValue(item.Id);
        }

      }
    }));
  }
  addConciergeField() {
    let conciergeConfig = {

      type: 'select',
      name: 'ConciergeId',
      label: 'conciergeLbl',
      class: 'w-100',
      options: [],
      value: null,
      onClick: (event) => { this.getConciergeTrackingList(ConciergeTrackingType.Concierge); }
    }
    let index = this.bookingFormConfig.findIndex(config => config.name == 'HotelId');
    this.bookingFormConfig.splice(index + 1, 0, conciergeConfig);
    this.bookingFormConfig = [...this.bookingFormConfig];
  }

  calculatePartySize(minSize: number, maxSize: number, allowAdditionalParty: boolean) {
    this.partySizeArray = [];
    for (let i = minSize; i <= maxSize; i++) {
      this.partySizeArray.push({ id: i, value: i.toString() });
    }
    if (allowAdditionalParty) {
      this.partySizeArray[this.partySizeArray.length - 1].value = this.partySizeArray[this.partySizeArray.length - 1].value + '+';
    }
    return this.partySizeArray;
  }
  setSeatingArea() {
    this.seatingArea = this.cs.settings.value.SeatingAreas;
    this.seatingArea.forEach((area) => { if (area.Id === null) { area.Id = -1; } return area; });
    this.seatingArea.unshift(this.seatingArea.splice(this.seatingArea.findIndex(item => item.Id === -1), 1)[0]);
    let index = this.bookingFormConfig.findIndex(item => item.name === 'SeatingAreaId');
    this.bookingFormConfig[index].options = this.seatingArea.map(x => ({ id: x.Id, value: this.ts.instant(x.Name) }));
    this.bookingFormConfig[index].value = this.mealData?.SeatingAreaId || this.bookingFormConfig[index].options.filter(x => x.id == -1)[0].id;
  }

  setSeatingType() {
    this.seatingType = this.cs.settings.value.SeatingTypes.filter(sType => sType.IsDeleted == false);
    this.seatingType.forEach((type) => { if (type.Id === null) { type.Id = -1; } return type; });
    this.seatingType.unshift(this.seatingType.splice(this.seatingType.findIndex(item => item.Id === -1), 1)[0]);
    let index = this.bookingFormConfig.findIndex(item => item.name === 'SeatingTypeId')
    this.seatingTypeData = this.seatingType.map(x => ({ id: x.Id, value: this.ts.instant(x.Description) }));
    this.bookingFormConfig[index].options = cloneDeep(this.seatingTypeData);
    this.bookingFormConfig[index].value = this.mealData?.SeatingTypeId || -1;
  }
  setSeatingAreaBasedOnType(event) {
    let value = isNaN(Number(event)) ? event.value : event;
    let seatingAreaMappings = this.cs.settings.value.SeatingTypeSeatingAreaMappings.filter(x => x.SeatingAreaId == value);
    let index = this.bookingFormConfig.findIndex(config => config.name == 'SeatingTypeId');
    if (value && value != -1) {
      let options = [];
      options.push(this.seatingTypeData.filter(x => x.id == -1)[0]);
      seatingAreaMappings.forEach(element => {
        let seatingTypes = this.seatingTypeData.filter(x => x.id == element.SeatingTypeId);
        if (seatingTypes && seatingTypes.length) {
          seatingTypes.forEach(seatingType => options.push(seatingType));
        }
      });
      this.bookingFormConfig[index].options = [];
      options = uniqBy(options, 'id');
      this.bookingFormConfig[index].options = options;
    } else {
      this.bookingFormConfig[index].options = cloneDeep(this.seatingTypeData);
    }
    this.bookingFormConfig[index].value = -1;
    this.bookingForm.form.get('SeatingTypeId').setValue(this.bookingFormConfig[index].value);
  }

  setPartyStatusOptions() {
    let options = [];
    if (ReservationType.Reservation == this.partyService.reservationType) {
      options = [{ id: -1, value: this.ts.instant('none'), color: null }, ...this.settings.Statuses.filter(status => status.AppliesToReservations).map(status => ({ id: status.Id, value: status.Name, color: 'rgba(' + status.Color.R + ',' + status.Color.G + ',' + status.Color.B + ',' + status.Color.A + ')' }))]
    }
    else if (ReservationType.Waitlist == this.partyService.reservationType) {
      options = [{ id: -1, value: this.ts.instant('none'), color: null }, ...this.settings.Statuses.filter(status => status.AppliesToWalkIns).map(status => ({ id: status.Id, value: status.Name, color: 'rgba(' + status.Color.R + ',' + status.Color.G + ',' + status.Color.B + ',' + status.Color.A + ')' }))]
    }
    return options;
  }
}