import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { PopupService } from '@app/popup-module/popup.service';
import { ITabOutputDetails } from '@app/settings/models/common.interface';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { ComponentTypes, GuestBookTabs, GuestBookingValidationDTO, Labels, Menu } from '@constants/commonenums';
import { EventIntializers, contactsSort, popupDialogDimension } from '@constants/globalConstants';
import { urlConfig } from '@constants/url-config';
import { CacheService } from '@core/services/cache.service';
import { CommunicationList, ContactAddressDTO, ContactDTO, ContactNoteDTO, CreditCardDTO, CustomFieldsDTO, CustomGuestField, FullContactDTO, FullUpdatedContactDTO, GetContactOptions, ReservationDTO } from '@models/InputContact';
import { CountryDTO, LayoutDTO, PageMethod, PredefinedContactNoteDTO, PredefinedPartyNoteDTO, ServerDTO, SettingsDTO, SubCategoryDto } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails, TabConfig, TabsModel } from '@popup-module/models/popup.interface';
import { HttpService } from '@services/http.service';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ISubscription } from 'rxjs-compat/Subscription';
import { map } from 'rxjs/operators';
import { AdditionalinfoComponent } from '../components/create-update-guest-tab-layout/definitions/additionalinfo/additionalinfo.component';
import { AddressComponent } from '../components/create-update-guest-tab-layout/definitions/address/address.component';
import { ContactComponent } from '../components/create-update-guest-tab-layout/definitions/contact/contact.component';
import { PersonalInfoComponent } from '../components/create-update-guest-tab-layout/definitions/personal-info/personal-info.component';
import { PreferencesComponent } from '../components/create-update-guest-tab-layout/definitions/preferences/preferences.component';
import { TabsComponent } from '../components/tabs/tabs.component';
import { PartyService } from './party.service';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { CustomTabsComponent } from '../components/custom-tabs/custom-tabs.component';
import { ToastrService } from 'ngx-toastr';
import { CardInfoComponent } from '../components/create-update-guest-tab-layout/definitions/card-info/card-info.component';

@Injectable({
  providedIn: 'root'
})
export class GuestBookService implements OnDestroy {
  public contacts: ContactDTO[];
  public contactDetails: FullContactDTO;
  public isGuestDetailsVisible = new BehaviorSubject(false);
  public isFavourite: boolean;
  public isVIP: boolean;
  public isFeedback: boolean;
  public isRecent: boolean;
  public searchString: string;
  public sortBy: string;
  public pageSize = 100;
  public pageNumber = 0;
  allReservations: ReservationDTO[];
  selectedContactIndex: number;
  public guestForm: UntypedFormGroup;
  public serverList: any[];
  public selectedServers: ServerDTO = {} as ServerDTO;
  public selectedTables: any = {};
  creditCardDetails: CreditCardDTO[] =[];
  selectedServerArray: any = [];
  dataAvailable: Subject<any> = new Subject<any>();
  guestListTabChange$: Subject<any> = new Subject<any>();
  createdGuestData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  totalCount: number;
  selectedPreferenceTags: any[] = [];
  selectedCustomTags: any[] = [];
  preferredCommunication: any = [];
  allAvailableContacts =  new BehaviorSubject<ContactDTO[]>([]);
  updateGuestId:number;
  stayPayload: GuestBookingValidationDTO[];
  page = 1;
  isSearch = false;
  tabsModal: TabsModel;
  contactId: number;
  currentGuestTab: GuestBookTabs;
  dataRetentionPolicyChecked: boolean = false;
  _settings: SettingsDTO = {} as SettingsDTO;
  _layout: LayoutDTO = {} as LayoutDTO;
  dropDownFieldOptions = [];
  fieldType: string[] = ['Text Field', 'Drop Down'];
  searchStringForNewGuest: any;
  editGuest = false;
  createContactDialogRef: MatDialogRef<any>;
  subscriptions: Subscription = new Subscription();
  Feedbackaction: Subject<any> = new Subject<any>();
  FeedbackFilterOption:number;
  feedbackLastDate:Date;
  feedbackSort:number;
  feedbackActionTacken=false;
  isConfirmationCodeSearch=false;
  createEditGuestSubscription$:Subject<any>  = new Subject<ContactDTO>();


  constructor(public _as: AppService, public _hs: HttpService, public fb: UntypedFormBuilder, private dialog: MatDialog,
    private ts: TranslateService, public cs: CacheService, public ps:PartyService, private popupService:PopupService ,  private toastrService?: ToastrService) {
    this.contacts = [];
    this.guestForm = this.fb.group({});
    this.sortBy = contactsSort.filter(x => x.id == 'LastName')[0].id;
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
    this.subscriptions.add(cs.layout.subscribe(layt => {
      this._layout = layt;
    }));
  }

  getContacts(skip: number = 0, take: number = 10, isRecent: boolean, isVIP: boolean,
    isFavourite: boolean, isFeedback: boolean, searchQuery: string, sortBy: string, isRoomNumberSearchEnabled: boolean = false,feedbackFilter: number=0,feedbackDays:string=null,feedbackSort:number=0): Observable<ContactDTO[]> {
    return this._hs.get(
      `${urlConfig.getContacts}?restaurantId=${this._as.restaurantId}&skip=${skip}&take=${take}&isRecent=${isRecent}&isVIP=${isVIP}
&isFavourite=${isFavourite}&value=${searchQuery}&sortBy=${sortBy}&isRoomNumberSearch=${isRoomNumberSearchEnabled}&isFeedback=${isFeedback}&feedbackFilter=${feedbackFilter}&feedbackDays=${feedbackDays}&feedbackSort=${feedbackSort}&isConfirmationCodeSearch=${this.isConfirmationCodeSearch}`)
      .pipe(map((data: any) => {
        this.totalCount = data.Payload ? data.Payload.TotalCount : 0;
        return data.Payload ? data.Payload.Contacts : [];
      }));
  }

  getContactSuggestion(searchString: string, isRoomNumberSearch: boolean = false,pageNumber = 0,pageSize = 10 , directCGPS:boolean = false, isStayConfirmation: boolean = false, isPMSSearch = false) {
    return this._hs.get(
      `${urlConfig.getExContactSuggestionUrl}?restaurantId=${this._as.restaurantId}&searchString=${searchString}&isRoomNumberSearch=${isRoomNumberSearch}&isStayCnfNumberSearch=${isStayConfirmation}&isStayWildSearch=${isPMSSearch}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .pipe(map((data: any) => {
        return data.Payload ? data.Payload : [];
      }));
  }

  getContactExSuggestion(firstName?:string,lastName?:string,phoneNumber?:string,emailAddress?:string,membershipCode?:string,pageNumber?:number,pageSize?:number, aliasname?:string) {
    return this._hs.get(
      `${urlConfig.getExContactExSuggestionUrl}?restaurantId=${this._as.restaurantId}&firstName=${firstName}&lastName=${lastName}&phoneNumber=${phoneNumber}&emailAddress=${emailAddress}&trackMembershipNumber=${membershipCode}&aliasname=${aliasname}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .pipe(map((data: any) => {
        return data.Payload ? data.Payload : [];
      }));
  }

  validateBooking(request) {
    return this._hs.post(`${urlConfig.ValidateBookingType}?propertyId=${Utilities.RestaurantId()}`, request)
}



  loadContacts() {
    this.subscriptions.add(this.getContacts(this.pageNumber * this.pageSize, this.pageSize, this.isRecent,
      this.isVIP, this.isFavourite, this.isFeedback, this.searchString, this.sortBy,false,this.FeedbackFilterOption,this.feedbackLastDate !== null ? moment(this.feedbackLastDate).format('YYYY-MM-DD') : null,this.feedbackSort).subscribe((contacts) => {
        this.contacts = contacts.filter(contact => contact.FirstName != null || contact.LastName != null);
        this.loadAvailableContacts();
      }));
  }
  getBasicContactInformation(contactId, partyId = null) {
    const contactOptions: GetContactOptions = GetContactOptions.BasicContactInformation;
    return this._hs.get(
      `${urlConfig.getguestInfoUrl}?restaurantId=${this._as.restaurantId}&contactId=${contactId}&options=${contactOptions}&partyId=${partyId}`);
  }
  getContact(contactId, partyId = null) {
    const contactOptions: GetContactOptions = GetContactOptions.FullWithStatsAndPredefinedNotesIncludeCreditCards;
    return this._hs.get(
      `${urlConfig.getguestInfoUrl}?restaurantId=${this._as.restaurantId}&contactId=${contactId}&options=${contactOptions}&partyId=${partyId}`);
  }

  GetItineraryByProfile(tenantId, profileUuid,contactId,pageSize = 100,pageNumber = 0, pageDataType = 0){
    return this._hs.get(`${urlConfig.GetGuestItineraryUrl}?restaurantId=${this._as.restaurantId}&contactId=${contactId}&tenantId=${tenantId}&profileUuid=${profileUuid}&pageSize=${pageSize}&pageNumber=${pageNumber}&pageDataType=${pageDataType}`,pageNumber != 0);
  }

  NotifyItinerary(itineraryNotifyRequest){
    return this._hs.post(`${urlConfig.NotifyItinerary}?restaurantId=${this._as.restaurantId}`,itineraryNotifyRequest,true);
  }

  getPartyStayInfo(confirmationNumber: string){
    return this._hs.get(`${urlConfig.getPartyStayInfo}?restaurantId=${this._as.restaurantId}&confirmationNumber=${confirmationNumber}`);
  }

  getCommonGuestProfile(primaryGuestId , commonGuestProfileUUID){
    const contactOptions: GetContactOptions = GetContactOptions.FullWithStatsAndPredefinedNotesIncludeCreditCards;
    return this._hs.get(
      `${urlConfig.getCommonGuestProfile}?restaurantId=${this._as.restaurantId}&guestProfileServiceId=${primaryGuestId}&commonGuestProfileUUID=${commonGuestProfileUUID}&options=${contactOptions}`);
  }

  getProfilePreference(profileuuid) {
    return this._hs.get(
      `${urlConfig.getCommonProfilepreference}?restaurantId=${this._as.restaurantId}&ProfileUUID=${profileuuid}`);
  }

  getGuest(contactId, isEdit, isServers) {
    // This code is not required as we can directly get the servers fro Settings itself
    // if (isServers) {
    //   this.subscriptions.add(this.getServerDetails().subscribe((servers) => {
    //     this.serverList = servers.Payload;
    //   }));
    // }
    this.subscriptions.add(this.getContact(contactId).subscribe(data => {
      this.contactDetails = { ...data.Payload };
      this.ps.contactData$.next(this.contactDetails);
      this.ps.contacts$.next(this.contactDetails);
      const isEditable = isEdit ? false : true;
      this.isGuestDetailsVisible.next(isEditable);
      this.dataAvailable.next(isEdit);
    }));
  }

  createGuest(isConfirmationNeeded: boolean, createContactDialog?: MatDialogRef<any>, fromRetail?, from? , eventIntializers?) {
    const request = this.buildCreateGuestAPIRequest();
    
    this.createGuestHandler(isConfirmationNeeded, createContactDialog, fromRetail, from, request , eventIntializers);
  }

  createGuestHandler(isConfirmationNeeded: boolean, createContactDialog: MatDialogRef<any>, fromRetail, from, request , eventIntializers?){
    return this._hs.post(`${urlConfig.addContactsUrl}?restaurantId=${this._as.restaurantId}&fromExternalSystem=${this.cs.isIframeEnabled}`, request)
    .subscribe(response => {
      if (response) {        
        if(eventIntializers === EventIntializers.CREATEGUESTFORRESERVATION){
          this.createEditGuestSubscription$.next(response);
          this.toastrService.success(this.ts.instant(Labels[Labels.contactaddedconfirmationtext]), "", { timeOut: 5000, closeButton: true });
          createContactDialog?.close(response?.Payload?.ContactId);
        }else if(fromRetail){
          this.createdGuestData$.next(response);
      } 
        if (eventIntializers !== EventIntializers.CREATEGUESTFORRESERVATION && isConfirmationNeeded) {
          this.openConfirmationDialog(response, this.ts.instant(Labels[Labels.contactaddedconfirmationtext]), createContactDialog,false,fromRetail);
        }
      }
    });
  }

  buildCreateGuestAPIRequest(guestDetails?: any) {
    const personalInfo = this.guestForm.get('personalDetailsForm').value;
    const contactInfo = this.guestForm.get('contactDetailsForm').value;
    const preferenceInfo = this.guestForm.get('preferencesDetailsform').value;
    let additionalInfo;
    if (this.cs.settings.value.CustomGuestFields.length > 0 ) {
      additionalInfo = this.guestForm.get('notesForm').value;
    }
    const data: FullUpdatedContactDTO = {} as FullUpdatedContactDTO;
    const { guestTitle = 0, firstName = '',aliasName = '', lastName = '', dob = '', anniversary = '', vipStatus = false, memberShipId = '', photo = '', ShouldUpdatePhoto = false } = personalInfo;
    const { mobile = '', phone = '', email = '', mobileCode = null, secondaryMobileCode = null, secondaryEmail = '' } = contactInfo;
    const countryCodes = this.cs.settings.value.Countries.filter((country: CountryDTO) => {
      return country.Name === mobileCode;
    })[0];
    const secondaryCountryCodes = this.cs.settings.value.Countries.filter((country: CountryDTO) => {
      return country.Name === secondaryMobileCode;
    })[0];
    let UTCDob;
    let UTCAnniversary;
    let addressFormGroup = this.guestForm.get('contactAddressDetailsForm') as UntypedFormGroup;
    let contactAddressesInfo = addressFormGroup.controls.addresses as UntypedFormArray;

    if (dob) {
      const momentDob = moment(dob).toDate();
      UTCDob = new Date(Date.UTC(momentDob.getFullYear(), momentDob.getMonth(), momentDob.getDate()));
    }
    if (anniversary) {
      const momentAnniversary = moment(anniversary).toDate();
      UTCAnniversary = new Date(Date.UTC(momentAnniversary.getFullYear(), momentAnniversary.getMonth(),
        momentAnniversary.getDate()));
    }
    // Personal Info
    data.FirstName = firstName;
    data.LastName = lastName;
    data.AliasName = aliasName;
    data.PhoneNumber = mobile;
    data.CountryId = mobileCode ? (countryCodes && countryCodes.Id ? countryCodes.Id : null): mobileCode;
    data.CountryId2 = secondaryMobileCode ? (secondaryCountryCodes && secondaryCountryCodes.Id ? secondaryCountryCodes.Id : null): secondaryMobileCode;
    data.PhoneNumber2 = phone;
    data.EmailAddress = email;
    data.EmailAddress2 = secondaryEmail;
    data.Birthday = UTCDob ? UTCDob : dob;
    data.Anniversary = UTCAnniversary ? UTCAnniversary : anniversary;
    data.IsVip = vipStatus;
    data.TrackMembershipNumber = memberShipId;
    data.Id = guestDetails && guestDetails.Id ? guestDetails.Id : null;
    data.Photo = photo;
    data.ShouldUpdatePhoto = ShouldUpdatePhoto;
    data.GuestTitle = guestTitle;

    //policy info
    data.PIILastUsedDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
    if (this.dataRetentionPolicyChecked) {
      data.PIILastUsedDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
      data.ConsentDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
      this.subscriptions.add(this.cs.dataRetentionPolicy.subscribe(policy => {
        data.ConsentPolicyId = policy && policy[1] ? (policy[1]?.Id || null) : null;
      }));
    }

    // Contact Info
    if (contactAddressesInfo && contactAddressesInfo.controls.length > 0) {
      data.Addresses = [];
      contactAddressesInfo.controls.forEach((formGroup, index) => {
        const contactAddressesdetail = formGroup.value;
        if (contactAddressesdetail.addressLine1 || contactAddressesdetail.addressLine2 || contactAddressesdetail.zipcode || contactAddressesdetail.city || (contactAddressesdetail.country && contactAddressesdetail.country > 0 )) {
          const contactAddress: ContactAddressDTO = {} as ContactAddressDTO;
          contactAddress.Id = contactAddressesdetail && contactAddressesdetail.id ? contactAddressesdetail.id : null;
          contactAddress.Name = contactAddressesdetail.name ? contactAddressesdetail.name : (data.FirstName + data.LastName);
          contactAddress.Street1 = contactAddressesdetail.addressLine1;
          contactAddress.Street2 = contactAddressesdetail.addressLine2;
          contactAddress.PostalCode = contactAddressesdetail.zipcode;
          contactAddress.City = contactAddressesdetail.city;
          contactAddress.Country = this.getCountryName(contactAddressesdetail.country);
          data.Addresses.push(contactAddress);
        }
      });
    }

    // Additional Info
    const { partnerName = '', partnerBirthday = '' } = preferenceInfo;
    let UTCPartnerDOB;
    if (partnerBirthday) {
      const momentPartnerDOB = moment(partnerBirthday).toDate();
      UTCPartnerDOB = new Date(Date.UTC(momentPartnerDOB.getFullYear(), momentPartnerDOB.getMonth(),
        momentPartnerDOB.getDate()));
    }
    data.PreferredTableId = this.selectedTables.id ? this.selectedTables.id :
      (this.selectedTables && this.selectedTables.Id ? this.selectedTables.Id : null);
    data.PreferredServerId = this.selectedServers && this.selectedServers.Id ? this.selectedServers.Id : null;
    data.PartnerName = partnerName;
    data.PartnerBirthday = UTCPartnerDOB ? UTCPartnerDOB : partnerBirthday;

    // Notes
    data.Notes = [];
    for (let preferenceProperty in preferenceInfo) {
      if (preferenceProperty.includes('otherNotes')) {
        const otherNote: ContactNoteDTO = {} as ContactNoteDTO;
        otherNote.Text = preferenceInfo[preferenceProperty];
        if (otherNote.Text) {
          const preferencePropertyId = preferenceProperty.match(/(\d+)/);
          if (preferencePropertyId) {
            otherNote.RelatedId = Number(preferencePropertyId[0]);
            const preferredOtherTags = this.selectedPreferenceTags.filter((note) => note.Id === otherNote.RelatedId);
            if (preferredOtherTags && preferredOtherTags.length > 0) {
              otherNote.Id = preferredOtherTags[0].noteId ? preferredOtherTags[0].noteId : null;
            }
          }
          data.Notes.push({ ...otherNote });
        }
      }
    }

    // Pref Tags
    if (this.selectedPreferenceTags && this.selectedPreferenceTags.length > 0) {
      for (const selectedPreference of this.selectedPreferenceTags) {
        const contactNotesPreNotes: ContactNoteDTO = {} as ContactNoteDTO;
        if (selectedPreference.setSelected && selectedPreference.Name !== 'Others') {
          contactNotesPreNotes.RelatedId = selectedPreference && selectedPreference.Id ? selectedPreference.Id : null;
          contactNotesPreNotes.Text = selectedPreference.Name;
          contactNotesPreNotes.Id = selectedPreference.noteId ? selectedPreference.noteId : null;
          contactNotesPreNotes.Icon = selectedPreference.Icon ? selectedPreference.Icon : null;
          data.Notes.push(contactNotesPreNotes);
        }
      }
    }
    // Clear Selected Server and Table
    this.selectedServers = {} as ServerDTO;
    this.selectedTables = {};

    // Custom Guest Fields
    const customGuestFields: CustomGuestField[] = this.cs.settings.value.CustomGuestFields;
    data.ContactCustomFields = [];
    for (const [index, customField] of customGuestFields.entries()) {
      const customFields: CustomFieldsDTO = {} as CustomFieldsDTO;
      const customFieldName = `customField${index}`;

      if (customField.FieldType == this.fieldType[1]) {
        const customFieldOption = this.dropDownFieldOptions.filter(dropDownField => dropDownField.dropDownCustomFieldId == customField.Id);
        let customFieldOptionValue = null;
        if (customFieldOption.length > 0 && this.cs.settings.value.CustomGuestFields.length > 0 && additionalInfo) {
          let customFieldOptionValueArr = customFieldOption[0].options.filter(option => option.id == additionalInfo[customFieldName]);
          if (customFieldOptionValueArr.length > 0) {
            customFieldOptionValue = customFieldOptionValueArr[0].value;
          }
        }
        if (customFieldOptionValue == "None") {
          customFields.CustomFieldValue = null;
        } else
          customFields.CustomFieldValue = customFieldOptionValue;

      } else {
        if (this.cs.settings.value.CustomGuestFields.length > 0 && additionalInfo) {
          customFields.CustomFieldValue = additionalInfo[customFieldName];
        } else {
          customFields.CustomFieldValue = null;
        }
      }
      customFields.ContactId = guestDetails && guestDetails.Id ? guestDetails.Id : null;
      customFields.CustomFieldId = customField && customField.Id ? customField.Id : null;
      customFields.Id = guestDetails && guestDetails.ContactCustomFields.length > 0 ?
        this.getCustomGuestFiledsId(guestDetails, customField) : null;
      data.ContactCustomFields.push(customFields);
    }

    // Pref Communication
    if (this.preferredCommunication.length > 0) {
      for (const communication of this.preferredCommunication) {
        if (communication.setSelected) {
          data.PreferredPageMethod = PageMethod[PageMethod[communication.Id]];
        }
      }
    }
    data.CreditCards = this.creditCardDetails;
    
    return data;
  }

  getServerDetails() {
    return this._hs.get(`${urlConfig.getServersUrl}?restaurantId=${this._as.restaurantId}`);
  }

  updateGuest(isConfirmationNeeded: boolean, guestDetails, createContactDialog?: MatDialogRef<any>,fromRetail?, eventIntializers?) {
    if (this.guestForm.valid) {
      this.updateGuestId = guestDetails.Id || guestDetails.ContactId;
      return this._hs.post(`${urlConfig.updateContactsUrl}?restaurantId=${this._as.restaurantId}&fromExternalSystem=${this.cs.isIframeEnabled}`,
        this.buildUpdateGuestAPIRequest(guestDetails)).subscribe(response => {
          if (response) {     
            if(eventIntializers === EventIntializers.CREATEGUESTFORRESERVATION){
              this.createEditGuestSubscription$.next(response);
              this.toastrService.success(this.ts.instant(Labels[Labels.contactupdatedconfirmationtext]), "", { timeOut: 5000, closeButton: true });
              createContactDialog?.close(response?.Payload?.ContactId);
            }else if(fromRetail){
                this.createdGuestData$.next(response);
            }       
            if (eventIntializers !== EventIntializers.CREATEGUESTFORRESERVATION && isConfirmationNeeded) {
              this.openConfirmationDialog(response, this.ts.instant(Labels[Labels.contactupdatedconfirmationtext]), createContactDialog, true,fromRetail);
            }
          }
        });
    }
  }

  openConfirmationDialog(response, label, createContactDialog?: MatDialogRef<any>, getUpdatedGuestDetails = false,fromRetail?) {
    const confirmationMessages = Utilities.getConfirmationMessage(response, label);
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'action',
      dimensionType: 'small',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: confirmationMessages,
      popupTitle: confirmationMessages[0].dialogTitle
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: popupDialogDimension.actionDialogHeight,
      width: popupDialogDimension.actionDialogWidth,
      data: {
        update: this.ts.instant('ok'),
        componentDetails,
        from: ComponentTypes.guestBookService,
        back: false,
        standalone: true,
        showAction: true,
      },
    });
    this.subscriptions.add(dialogRef.afterClosed().subscribe(() => {
      if (createContactDialog) {
        createContactDialog.close(response?.Payload?.ContactId);
      }
      if (!this._as.isOperationFailed(response)) {
        if(fromRetail && response.Payload){
          let contactId = this.editGuest ? this.updateGuestId: response.Payload.ContactId;
          this.createdGuestData$.next(response);
          // this.getGuest(contactId, false, true);
        }
        else{
          if(this._as.selectedMenuId == Menu.GuestBook)
          {
            this.contacts = [];
            this.loadContacts();                        
            if (this.isGuestDetailsVisible.getValue() && getUpdatedGuestDetails) {
              this.getGuest(this.contactId, false, true);
            }        
          }
      }
    }
    }));
  }

  deleteContacts(contactIds) {
    this.subscriptions.add(this._hs.post(`${urlConfig.HideContactsFromSearchUrl}?restaurantId=${this._as.restaurantId}`, contactIds)
      .subscribe(response => {
        if (response) {
          if (!this._as.isOperationFailed(response)) {
            this.openConfirmationDialog(response, this.ts.instant(Labels[Labels.contactdeletedconfirmationtext]));
          }
        }
      }));
  }

  getTablesList() {
    let _layout = this._layout;
    const tableList: any = [];
    if (_layout &&
      _layout.FloorPlans != null && _layout.FloorPlans.length > 0) {
      for (let i = 0; i < _layout.FloorPlans.length; i++) {
        if (_layout.FloorPlans[i].Name == '<default>' &&
          _layout.FloorPlans[i].IsDefault && !_layout.FloorPlans[i].IsRemoved) {
          const tables = _layout.FloorPlans[i].StandaloneTables;
          if (tables != null && tables.length > 0) {
            for (let j = 0; j < tables.length; j++) {
              tableList.push({ value: tables[j].Id, name: tables[j].Name });
            }
          }
          break;
        }
      }
    }

    return tableList;
  }

  getServersList() {
    const serverList: any = [];
    if (this._settings &&
      this._settings.Servers != null && this._settings.Servers.length > 0) {
      for (let i = 0; i < this._settings.Servers.length; i++) {
        serverList.push({
          value: this._settings.Servers[i].Id,
          name: this._settings.Servers[i].Name
        });
      }
      this.serverList = serverList;
    }
    return serverList;
  }

  getTags() {
    const tagsList: any = [];
    let PredefinedContactNotes: PredefinedContactNoteDTO[] = [];
    if (this._settings &&
      this._settings.Categories != null &&
      this._settings.Categories.length > 0)
      {
        this._settings.Categories.forEach(category => {
          const { SubCategories = [] } = category;
          SubCategories.forEach((SubCategory: SubCategoryDto) => {
            const { PredefinedContactNote = [] } = SubCategory;
            PredefinedContactNotes.push(...PredefinedContactNote);
          });
        });
        if(PredefinedContactNotes.length > 0)
        {
          for (const contactNotes of PredefinedContactNotes) {
            tagsList.push({ id: contactNotes.Id, name: contactNotes.Text, icon: contactNotes.Icon, setSelected: false, isDisabled: true });
          }
        }
      }
    return tagsList;
  }

  getPredefinedPartyTags() {
    const tagsList: any = [];
    let PredefinedPartyNotes: PredefinedPartyNoteDTO[] = [];
    if (this._settings &&
      this._settings.Categories != null &&
      this._settings.Categories.length > 0)
      {
        this._settings.Categories.forEach(category => {
          const { SubCategories = [] } = category;
          SubCategories.forEach((SubCategory: SubCategoryDto) => {
            const { PredefinedPartyNote = [] } = SubCategory;
            PredefinedPartyNotes.push(...PredefinedPartyNote);
          });
        });
        if(PredefinedPartyNotes.length > 0)
        {
          for (const partyNotes of PredefinedPartyNotes) {
            tagsList.push({ id: partyNotes.Id, name: partyNotes.Text, icon: partyNotes.Icon, setSelected: false, isDisabled: true });
          }
        }
      }
    return tagsList;
  }

  getCommunicationList(): CommunicationList[] {
    const communicationIconCollection: string[] = ['Path-506', 'SMS', 'ic_call_24px', 'envelope', 'SMS', 'ic_call_24px', 'envelope'];
    let communicationList: CommunicationList[] = [];
    let i = 0;
    for (const enumMember in PageMethod) {
      if (enumMember) {
        const isValueProperty = parseInt(enumMember, 10) >= 0;
        if (isValueProperty) {
          communicationList.push({ Id: i, Name: this.ts.instant(PageMethod[enumMember]), setSelected: false, Value: '', Icon: communicationIconCollection[i] });
        }
        ++i;
      }
    }
    communicationList =  communicationList.filter((list) => list.Id != PageMethod.SMSProxyRelay);
    return communicationList;
  }
  updateContactListStatus(index) {
    this.subscriptions.add(this._hs.get(`${urlConfig.updateContactIsVIPIsFavoriteUrl}?restaurantId=${this._as.restaurantId}&contactId=${this.contacts[index].Id}&isVip=${this.contacts[index].IsVip}&isFavourite=${this.contacts[index].IsFavorite}&isFeedback=${this.contacts[index].IsFeedback}`)
      .subscribe(data => {
        this.loadAvailableContacts(true);
        if (this.isGuestDetailsVisible.getValue() && this.contactDetails.Id === this.contacts[index].Id) {
          this.contactDetails.IsVip = this.contacts[index].IsVip;
          this.contactDetails.IsFavorite = this.contacts[index].IsFavorite;
          this.contactDetails.IsFeedback = this.contacts[index].IsFeedback;
        }
      }));
  }

  updateContactVIPStatus(contactId, IsVip, IsFavorite) {
    this._hs.get(`${urlConfig.updateContactIsVIPIsFavoriteUrl}?restaurantId=${this._as.restaurantId}&contactId=${contactId}&isVip=${IsVip}&isFavourite=${IsFavorite}`).subscribe(data => {
    });
  }

  loadAvailableContacts(needtoremove?) {
    if (this.isRecent) {
      this.allAvailableContacts.next([...this.contacts]);
    } else if (this.isVIP) {
      this.allAvailableContacts.next(this.contacts.filter((contactDetails: ContactDTO) => contactDetails.IsVip));
      if (this.pageSize > this.allAvailableContacts.value.length && needtoremove) {
        this.totalCount = this.totalCount - 1;
      }
      // this.totalCount = (this.allAvailableContacts) ? this.allAvailableContacts.length : 0;
    } else if (this.isFavourite) {
      this.allAvailableContacts.next(this.contacts.filter((contactDetails: ContactDTO) => contactDetails.IsFavorite));
      if (this.pageSize > this.allAvailableContacts.value.length && needtoremove) {
        this.totalCount = this.totalCount - 1;
      }
      //  this.totalCount = (this.allAvailableContacts) ? this.allAvailableContacts.length : 0;

    } else if (this.isFeedback) {
      this.allAvailableContacts.next(this.contacts.filter((contactDetails: ContactDTO) => contactDetails.IsFeedback));
      if (this.pageSize > this.allAvailableContacts.value.length && needtoremove) {
        this.totalCount = this.totalCount - 1;
      }
      //  this.totalCount = (this.allAvailableContacts) ? this.allAvailableContacts.length : 0;
    } else {
      this.allAvailableContacts.next([...this.contacts]);
    }
    this.ps.contactData$.next(this.contacts)
    this.ps.contactList$.next(this.allAvailableContacts.value);
  }

  updateContactPreferences(restaurantId: number, contactId: number, preferredPageMethod: number) {
    this.subscriptions.add(this._hs.get(
      `${urlConfig.updateContactPreferences}?restaurantId=${restaurantId}&contactId=${contactId}&defaultPageMethod=${preferredPageMethod}`).subscribe(data => {
        this.contactDetails.PreferredPageMethod = data.Payload.PreferredPageMethod;
        this.contactDetails = { ...this.contactDetails };
        return data.Payload;
      }));
  }

  updateFavouriteContact(index: number) {
    this.contacts[index].IsFavorite = !this.contacts[index].IsFavorite;
    this.updateContactListStatus(index);
  }

  updateVipContact(index: number) {
    this.contacts[index].IsVip = !this.contacts[index].IsVip;
    this.updateContactListStatus(index);
  }

  updateFeedbackContact(index: number) {
    this.contacts[index].IsFeedback = !this.contacts[index].IsFeedback;
    this.updateContactListStatus(index);
  }

  resetGuestForm() {
    this.guestForm.reset();
  }

  setTabsModel(tabsModal: TabsModel) {
    this.tabsModal = tabsModal;
  }

  buildUpdateGuestAPIRequest(guestDetails?: any) {
    const personalInfo = this.guestForm.get('personalDetailsForm').value;
    const contactInfo = this.guestForm.get('contactDetailsForm').value;
    const preferenceInfo = this.guestForm.get('preferencesDetailsform').value;
    let additionalInfo = null;
    if(this.guestForm.get('notesForm') && this.guestForm.get('notesForm').value)
    {
     additionalInfo = this.guestForm.get('notesForm').value;
    }
    const data: FullUpdatedContactDTO = {} as FullUpdatedContactDTO;
    const { firstName = '', lastName = '', aliasName = '', dob = '', anniversary = '', vipStatus = false, memberShipId = '', photo = '', guestTitle = 0, ShouldUpdatePhoto = false } = personalInfo;
    const { mobile = '', phone = '', email = '', mobileCode = null, secondaryMobileCode = null, secondaryEmail = '' } = contactInfo;
    const countryCodes = this._settings.Countries.filter((country: CountryDTO) => {
      return country.Name === mobileCode;
    })[0];
    const secondaryCountryCodes = this._settings.Countries.filter((country: CountryDTO) => {
      return country.Name === secondaryMobileCode;
    })[0];
    let UTCDob;
    let UTCAnniversary;
    let addressFormGroup = this.guestForm.get('contactAddressDetailsForm') as UntypedFormGroup;
    let contactAddressesInfo = addressFormGroup.controls.addresses as UntypedFormArray;
    if (dob) {
      const momentDob = moment(dob).toDate();
      UTCDob = new Date(Date.UTC(momentDob.getFullYear(), momentDob.getMonth(), momentDob.getDate()));
    }
    if (anniversary) {
      const momentAnniversary = moment(anniversary).toDate();
      UTCAnniversary = new Date(Date.UTC(momentAnniversary.getFullYear(), momentAnniversary.getMonth(),
        momentAnniversary.getDate()));
    }
    // Personal Info
    data.FirstName = firstName;
    data.LastName = lastName;
    data.AliasName = aliasName; 
    data.PhoneNumber = mobile;
    data.CountryId = mobileCode ? countryCodes.Id : mobileCode;
    data.CountryId2 = secondaryMobileCode ? secondaryCountryCodes.Id : secondaryMobileCode;
    data.PhoneNumber2 = phone;
    data.EmailAddress = email;
    data.EmailAddress2 = secondaryEmail;
    data.Birthday = UTCDob ? UTCDob : dob;
    data.Anniversary = UTCAnniversary ? UTCAnniversary : anniversary;
    data.IsVip = vipStatus;
    data.TrackMembershipNumber = guestDetails?.TrackMembershipNumber || memberShipId;
    data.Id = guestDetails?.Id || guestDetails?.ContactId || null;
    data.Photo = photo;
    data.ShouldUpdatePhoto = ShouldUpdatePhoto;
    data.IsFavorite = guestDetails.IsFavorite;
    data.GuestTitle = guestTitle;
    data.IsMemberActive = guestDetails?.MemberInfo ? !guestDetails.MemberInfo.IsMembershipExpired : false;
    data.MemberInfo = guestDetails?.MemberInfo || null
    data.MarketingOptedIn = guestDetails.MarketingOptedIn;

    //policy info
    data.PIILastUsedDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
    if (this.dataRetentionPolicyChecked) {
      data.PIILastUsedDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
      data.ConsentDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
      this.subscriptions.add(this.cs.dataRetentionPolicy.subscribe(policy => {
        data.ConsentPolicyId = policy[1].Id;
      }));
    }

    // Contact Info
    if (contactAddressesInfo && contactAddressesInfo.controls.length > 0) {
      data.Addresses = [];
      contactAddressesInfo.controls.forEach((formGroup, index) => {
        const contactAddressesdetail = formGroup.value;
        if (contactAddressesdetail.addressLine1 || contactAddressesdetail.addressLine2 || contactAddressesdetail.zipcode || contactAddressesdetail.city || (contactAddressesdetail.country && contactAddressesdetail.country > 0 )) {
          const contactAddress: ContactAddressDTO = {} as ContactAddressDTO;
          contactAddress.Id = contactAddressesdetail.id ? contactAddressesdetail.id : null;
          contactAddress.Name = contactAddressesdetail.name ? contactAddressesdetail.name : (data.FirstName + data.LastName);
          contactAddress.Street1 = contactAddressesdetail.addressLine1;
          contactAddress.Street2 = contactAddressesdetail.addressLine2;
          contactAddress.PostalCode = contactAddressesdetail.zipcode;
          contactAddress.City = contactAddressesdetail.city;
          contactAddress.Country = this.getCountryName(contactAddressesdetail.country);
          data.Addresses.push(contactAddress);
        }
      })
    }

    // Additional Info
    const { partnerName = '', partnerBirthday = '' } = preferenceInfo;
    let UTCPartnerDOB;
    if (partnerBirthday) {
      const momentPartnerDOB = moment(partnerBirthday).toDate();
      UTCPartnerDOB = new Date(Date.UTC(momentPartnerDOB.getFullYear(), momentPartnerDOB.getMonth(),
        momentPartnerDOB.getDate()));
    }
    data.PreferredTableId = this.selectedTables.id ? this.selectedTables.id :
      this.selectedTables.Id;
    if (this.selectedServers) {
      data.PreferredServerId = this.selectedServers.Id;
    }
    data.PartnerName = partnerName;
    data.PartnerBirthday = UTCPartnerDOB ? UTCPartnerDOB : partnerBirthday;

    // Notes
    data.Notes = [];
    for (let preferenceProperty in preferenceInfo) {
      if (preferenceProperty.includes('otherNotes')) {
        const otherNote: ContactNoteDTO = {} as ContactNoteDTO;
        otherNote.Text = preferenceInfo[preferenceProperty];
        if (otherNote.Text) {
          const preferencePropertyId = preferenceProperty.match(/(\d+)/);
          const noteId = preferenceProperty.substring((preferenceProperty.lastIndexOf('_')+1), preferenceProperty.length);
          if (preferencePropertyId) {
            otherNote.RelatedId = Number(preferencePropertyId[0]);
            const preferredOtherTags = this.selectedPreferenceTags.filter((note) => note.Id === otherNote.RelatedId && (!noteId || noteId == note.noteId || note.extraNotesIds?.findIndex(x => x == noteId) > -1));
            if (preferredOtherTags && preferredOtherTags.length > 0) {
              otherNote.Id = noteId || (preferredOtherTags[0].noteId ? preferredOtherTags[0].noteId : null);
            }
          }
          data.Notes.push({...otherNote});
        }
      }
    }

    // Pref Tags
    if (this.selectedPreferenceTags && this.selectedPreferenceTags.length > 0) {
      for (const selectedPreference of this.selectedPreferenceTags) {
        const contactNotesPreNotes: ContactNoteDTO = {} as ContactNoteDTO;
        if (selectedPreference.setSelected && selectedPreference.Name !== 'Others') {
          contactNotesPreNotes.RelatedId = selectedPreference.Id;
          contactNotesPreNotes.Text = selectedPreference.Name;
          contactNotesPreNotes.Id = selectedPreference.noteId ?  selectedPreference.noteId : null;
          contactNotesPreNotes.Icon = selectedPreference.Icon ? selectedPreference.Icon : null;
          data.Notes.push(contactNotesPreNotes);
        }
      }
    }

    if (this.selectedCustomTags && this.selectedCustomTags.length > 0) {
      for (const selectedCustomTag of this.selectedCustomTags) {
        const contactNotesPreNotes: ContactNoteDTO = {} as ContactNoteDTO;
        if (selectedCustomTag.setSelected) {
          contactNotesPreNotes.Text = selectedCustomTag.Name;
          contactNotesPreNotes.Id = selectedCustomTag.noteId ?  selectedCustomTag.noteId : null;
          data.Notes.push(contactNotesPreNotes);
        }
      }
    }
    // Clear Selected Server and Table
    this.selectedServers = {} as ServerDTO;
    this.selectedTables = {};

    // Custom Guest Fields
    const customGuestFields: CustomGuestField[] = this._settings.CustomGuestFields;
    data.ContactCustomFields = [];
    for (const [index, customField] of customGuestFields.entries()) {
      const customFields: CustomFieldsDTO = {} as CustomFieldsDTO;
      const customFieldName = `customField${index}`;

      if (customField.FieldType == this.fieldType[1]) {
        const customFieldOption = this.dropDownFieldOptions.filter(dropDownField => dropDownField.dropDownCustomFieldId == customField.Id);
        let customFieldOptionValue = null;
        if (customFieldOption.length > 0 && additionalInfo) {
          let customFieldOptionValueArr = customFieldOption[0].options.filter(option => option.id == additionalInfo[customFieldName]);
          if (customFieldOptionValueArr.length > 0) {
            customFieldOptionValue = customFieldOptionValueArr[0].value;
          }
        }
        if (customFieldOptionValue == "None") {
          customFields.CustomFieldValue = null;
        } else
          customFields.CustomFieldValue = customFieldOptionValue;

      } else {
        if(additionalInfo)
        {
        customFields.CustomFieldValue = additionalInfo[customFieldName];
        }
      }
      customFields.ContactId = guestDetails ? guestDetails.Id : null;
      customFields.CustomFieldId = customField.Id;
      customFields.Id = guestDetails && guestDetails.ContactCustomFields.length > 0 ?
        this.getCustomGuestFiledsId(guestDetails, customField) : null;
      data.ContactCustomFields.push(customFields);
    }

    // Pref Communication
    if (this.preferredCommunication.length > 0) {
      for (const communication of this.preferredCommunication) {
        if (communication.setSelected) {
          data.PreferredPageMethod = PageMethod[PageMethod[communication.Id]];
        }
      }
    }
    data.CreditCards = this.creditCardDetails;
    return data;
  }

  getCustomGuestFiledsId(guestDetails, customField: CustomGuestField): number {
    const contactCustomField = guestDetails.ContactCustomFields.filter((field) => field.CustomFieldId === customField.Id);
    let customFieldId = null;
    if (contactCustomField.length > 0) {
      customFieldId = contactCustomField[0].Id;
    }
    return customFieldId;
  }

  getCountryId(countryName:string):Number{
    if(countryName){
      let selectedCountry = this._settings.Countries.filter(country=>country.CountryPhoneCode.trim().toLocaleLowerCase() === countryName.trim().toLocaleLowerCase() || country.Name.trim().toLocaleLowerCase() === countryName.trim().toLocaleLowerCase());
      if(selectedCountry && selectedCountry.length > 0){
        return selectedCountry[0].Id;
      }
    }
    return 0 ; // 0 is the id of the None Option
  }

  getCountryName(id: number):string{
    if(id > 0){
      var selectedCountry = this._settings.Countries.filter(country=>country.Id === id);
      if(selectedCountry && selectedCountry.length >0){
        return selectedCountry[0].Name;
      }
    }

    return null;
  }

addGuest(fromEdit,id){
  this.editGuest = fromEdit;
  this.getServersList();             
  if(!fromEdit){
      this.guestForm.reset();
      this.createTabsModal(true, true, fromEdit, fromEdit? this.contactDetails : null);     
      this.openDialogAdd(this.ts.instant('popUpTitleaddnewguest'));        
      }
  else {
    if (id) {
      this.getGuest(id, fromEdit, null);
      setTimeout(() => {
        this.createTabsModal(true, true, fromEdit, fromEdit ? this.contactDetails : null);
        this.openDialogAdd('Edit Guest');
      }, 1500);
    } else {
      this.createTabsModal(true, true, fromEdit, fromEdit ? this.contactDetails : null);
      this.openDialogAdd('Edit Guest');
    }
  }
}

createTabsModal(isDefault: boolean, isNext: boolean, isEdit: boolean, componentInput: any,selectedTabNumber = null) {
 
  this.tabsModal = {
    tabs: this.getGuestTabs(),
    default: isDefault,
    componentInput,
    isNextButtonAvailable: isNext,
    isEditTab: isEdit,selectedTabNumber:selectedTabNumber
  };
  }

  getGuestTabs() {
  const tabComponent: TabConfig[] = [
    {
    tabComponent: PersonalInfoComponent,
    enabledIcon: true,
    tabLabel: 'personalInfoTab',
    tabIcon: 'Path-503 ',
    isDisabled: false
    },
    {
    tabComponent: ContactComponent,
    enabledIcon: true,
    tabLabel: 'contactTab',
    tabIcon: 'Group-593',
    isDisabled: !this.editGuest
    },
    {
    tabComponent: AddressComponent,
    enabledIcon: true,
    tabLabel: 'addressTab',
    tabIcon: 'Group-593',
    isDisabled: !this.editGuest
    },
    {
    tabComponent: PreferencesComponent,
    enabledIcon: true,
    tabLabel: 'prefernencesTab',
    tabIcon: 'Group-601',
    isDisabled: !this.editGuest
    },
    {
      tabComponent: CardInfoComponent,
      enabledIcon: true,
      tabLabel: 'cardInfoTab',
      tabIcon: 'Card-empty-1',
      isDisabled: !this.editGuest
    }
  ] as TabConfig[];
  if (this._settings.CustomGuestFields.length > 0) {
    tabComponent.push({
    tabComponent: AdditionalinfoComponent,
    enabledIcon: true,
    tabLabel: 'additionalInfoTab',
    tabIcon: 'Group-602',
    isDisabled: !this.editGuest
    });
  }
  return tabComponent;
  }

  openDialogAdd(popupTitle: string, from?: ComponentTypes, additionalInfo? , eventIntializers?) {
  let confirmSubscription: ISubscription = null;
  this.createContactDialogRef = this.dialog.open(AppPopupComponent, {
    disableClose: true,
    height: '90%',
    width: '90%',
    maxWidth: '70vw',
    data: {
    service: this.popupService,
    title: popupTitle,
    update: 'ok',
    cancel: 'cancel',
    componentDetails: this.getTabComponentDetails(),
    from: from || ComponentTypes.guestBook,
    additionalInfo,
    back: true,
    standalone: true
    },
  });
  this.subscriptions.add(this.createContactDialogRef.afterClosed().subscribe(result => {
    this.selectedServers = {} as ServerDTO;
    this.selectedTables = '';
    if (confirmSubscription) { confirmSubscription.unsubscribe();}
  }));
  let tabInfoData;
  confirmSubscription = this.popupService.confirmedAction$.subscribe((tabOutputInfo: ITabOutputDetails) => {
    if (tabOutputInfo.isEdit && tabOutputInfo.fromComponent === ComponentTypes.guestBook) {
      tabInfoData = _.cloneDeep(tabOutputInfo);
      if (this.guestForm.valid) {
        this.updateGuest(true, tabOutputInfo.inputData, this.createContactDialogRef,true , eventIntializers);
      }
      } else if (tabOutputInfo.fromComponent === ComponentTypes.guestBook) {
      if (this.guestForm.valid) {
      this.createGuest(true, this.createContactDialogRef,true, from , eventIntializers);
    }
    if (confirmSubscription) { confirmSubscription.unsubscribe();}
  }
  });
  this.subscriptions.add(this.createdGuestData$.subscribe(result =>{

 //   this.newCreatedContact = tabInfoData?.isEdit ?  tabInfoData.inputData.Id: result.Payload.ContactId;

  }))

  }


  getTabComponentDetails() {
  return {
    componentName: CustomTabsComponent,
    popupType: 'tabs',
    tabs: this.tabsModal,
    popUpDetails: {
    isStepper: true,
    eventName: 'notifyParent'
    }
  };
  }


  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
