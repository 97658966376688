import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RetailLocalization } from '@app/retail/common/localization/retail-localization';
import {  UserBreakPoint } from '@app/retail/shared/business/shared.modals';
import { RetailBreakPoint } from '@app/retail/shared/globalsContant';
import { BreakPointAccess } from '@app/retail/shared/service/breakpoint.service';
import { RetailUtilities } from '@app/retail/shared/utilities/retail-utilities';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {
  @Input() cardData;
  @Output() removeDetails = new EventEmitter<any>();
  @Input() ShowConfirmationForRemoval = true;
  @Input() showDeleteButton: boolean = true;

  constructor(
    public BPoint: BreakPointAccess,
    private _localization: RetailLocalization,
    private _utils: RetailUtilities
  ) {
  }

  ngOnInit(): void {
    this.validateRemoveCOFUserAccess();
    this.showDeleteButton = this.showDeleteButton ?? true;
  } 

  labels = {
    SaveCardDetails : "Save Card Details"
  }
  allowCOFRemoval = false;

  validateRemoveCOFUserAccess() {
    const userBreakPoints : UserBreakPoint[] =  this.BPoint.GetBreakPoint([RetailBreakPoint.REMOVE_CARD_ON_FILE]).result;
    this.allowCOFRemoval = userBreakPoints.find(x => x.breakPointNumber == RetailBreakPoint.REMOVE_CARD_ON_FILE)?.allow ?? false;
  }

  removeCardDetails() {
    //Validate User access
    if (!this.allowCOFRemoval) {
      this.BPoint.showBreakPointPopup(this._localization.captions.breakpoint[RetailBreakPoint.REMOVE_CARD_ON_FILE]);
      return;
    }
    //Validate if confirmation popup is required
    if (!this.ShowConfirmationForRemoval) {
      this.removeDetails.emit(this.cardData);
      return;
    }
    //Prompt for user confirmation
    this._utils.ShowErrorMessage(
      this._localization.captions.common.Warning,
      this._localization.captions.shop.COF_RemovalConfirmationMsg,
      GlobalConst.ButtonType.YesNo,
      this.CardRemovalConfirmation.bind(this)
    );
  }

  CardRemovalConfirmation(result: string) {
    if (result.toLowerCase() == GlobalConst.ButtonOptions.Yes.toLowerCase()) {
      this.removeDetails.emit(this.cardData);
    }
  }
}