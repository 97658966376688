<div class="pms-integration-wrapper" [formGroup]="pmsIntegrationHostSetupFormGroup">
    <div class="pms-integration-inner-wrapper" [ngClass]="viewOnly ? 'view-only' : ''">
        <h4 class="ag_group--headers header-margin">Host Configurations</h4>
        <ng-container
            *ngFor="let pmsIntegrationHostConfigs of pmsIntegrationHostSetupFormGroup.get('pmsIntegrationHostConfigs')['controls']; let index = index; let last=last;let first= first"
            formArrayName="pmsIntegrationHostConfigs">
            <div class="w-100">
                <div class="management-section">
                    <ng-container [formGroupName]="index">
                        <div class="ui-switch ag_display--flex">
                            <div class="ag_display--flex-wrap">
                                <label class="ag_w--100 ag_mb--1">{{captions.lbl_internalorExternal}}</label>
                                <ui-switch formControlName="switch" [checkedLabel]="captions.yes" [uncheckedLabel]="captions.no">
                                </ui-switch>
                            </div>
                            <a class="ag_link ag_ml--2" (click)="openModal(index,pmsIntegrationHostConfigs['controls']['switch'].value,pmsIntegrationHostConfigs)">Open Modal</a>
                        </div>
                        <ag-input (change)='hostNumberChange($event.target.value, index)' type="NUMBER"
                            class='host-width' [attr.automationId]="'Txt_pmsIntegration_subject'"
                            formControlName="hostNumber" [placeholder]="captions.lbl_host">
                        </ag-input>
                        <h5 class="LWB16 mb-3 ">Config Values</h5>
                        <div *ngFor="let item of pmsIntegrationHostConfigs.get('ConfigPairs')['controls']; let i = index;let last=last;let first= first"
                            formArrayName="ConfigPairs" class="">
                            <mat-form-field class=" ml-2 key-width" [formGroupName]="i" [floatLabel]="floatLabel">
                                <input [matTooltipClass]="'AgysMatCustTooltip'"
                                    matTooltip="{{item.controls.configurationKey.value}}" matInput name="key"
                                    placeholder="{{captions.Key}}" (keypress)="keyPress($event)" [maxlength]="1000"
                                    formControlName="configurationKey">
                            </mat-form-field>
                            <mat-form-field class=" ml-2 config-width" [formGroupName]="i" [floatLabel]="floatLabel">
                                <input [matTooltipClass]="'AgysMatCustTooltip'"
                                    matTooltip="{{item.controls.configurationValue.value}}" matInput name="value"
                                    placeholder="{{captions.Value}}" (keypress)="keyPress($event)" [maxlength]="7000"
                                    formControlName="configurationValue">
                            </mat-form-field>
                            <span class="icon-Minus themecolor"
                                (click)="onRemoveConfigPairFromPMSHostConfigGroup(pmsIntegrationHostSetupFormGroup,'pmsIntegrationHostConfigs',i, index)"
                                *ngIf="!(first && last)"></span>
                            <span class="icon-Plus themecolor"
                                (click)="onAddConfigPairFromPMSHostConfigGroup(pmsIntegrationHostSetupFormGroup,'pmsIntegrationHostConfigs',i,'','',selectedFeature?.id,true, index)"
                                *ngIf="last"></span>
                            <!-- <mat-error *ngIf="pmsIntegrationHostConfigs.controls['ConfigPairs']['controls'][i]?.invalid">
                                {{ getErrorMessage('ConfigPairs', i) }}
                            </mat-error> -->
                        </div>
                    </ng-container>
                </div>
                <div class="d-inline-block">
                    <span class="icon-Minus themecolor"
                        (click)="onRemoveConfigPairFromPMSHostConfigGroup(pmsIntegrationHostSetupFormGroup,'pmsIntegrationHostConfigs',index, null)"
                        *ngIf="!(first && last)"></span>
                    <span class="icon-Plus themecolor"
                        (click)="onAddConfigPairFromPMSHostConfigGroup(pmsIntegrationHostSetupFormGroup,'pmsIntegrationHostConfigs',index,'','',selectedFeature?.id,true, index+1)"
                        *ngIf="last"></span>
                </div>
            </div>
        </ng-container>
    </div>
    <div  *ngIf="showbuttons" class="ag_footer--actions footer">
        <app-button  [buttontype]="actionButton" 
            (valueChange)='savePMSIntegrationHostConfiguration()'></app-button>
        <app-button [buttontype]="cancelButton" class="ag_ml--2" (valueChange)='onCancel($event)'></app-button>
    </div>
</div>