<form autocomplete="off" class="h-100 " [formGroup]="seasonalPriceFormGrp">
    <section class="pl-4 ml-1 pr-4 pt-3">
        <div [ngClass]="isEdit && IsViewOnly ? 'disableItemSetup' : ''" (click)="stopdefaultClick($event)">
            <div class="outlet-height LW16 pl-2">
                <i  aria-hidden="true" class="icon-information pr-2 float-left"></i>
                <span class="float-left "> {{captions.SelectedOutlets}} </span>
                <div class="float-left  available-div pl-1" [popover]="myPopover" popoverPlacement="bottom"
                    [popoverOnHover]="false" [popoverCloseOnMouseOutside]="true">
                    <span *ngFor="let item of selectedOutletMgrArr" class="availableOutlet" [matTooltip]="popTemplate">
                        {{GetOutletName(item)}} </span>
                </div>
            </div>
            <popover-content #myPopover placement="bottom" [animation]="true" id="PopoverID" [closeOnMouseOutside]="true">
                <div class="float-left  available-div">
                    <span *ngFor="let item of selectedOutletMgrArr" class="availableOutlet" [matTooltip]="popTemplate">
                        {{GetOutletName(item)}} </span>
                </div>
            </popover-content>
            <label for="" class="blckclr LW16 blckclr pt-3 d-block">{{captions.SEASONALRATE}}</label>

            <div class="float-left pr-4 mt-3 mb-3">
                <label for="" class="LW12 d-block mb-0 rel-pos">{{captions.StartDate}}</label>
               <div class="d-inline-block">
                    <mat-form-field floatLabel="floatLabelNever"  appearance="legacy" class="date-picker-width">
                        <input matInput [matDatepicker]="picker" dateRestricter (ngModelChange)="fromDateChange($event)"  formControlName='startDate'
                        [min]="minFromDate" [placeholder]="placeHolderFormat">
                        <mat-datepicker #picker [disabled] = "IsViewOnly"></mat-datepicker>
                        <i  aria-hidden="true" matSuffix class="icon-Calender cursor fs-xlarge position-relative d-inline-block customCalendar" (click)="picker.open()" ></i>
                        <mat-error *ngIf="seasonalPriceFormGrp.controls['startDate'].hasError('matDatepickerParse')">{{captionsCommon.dateFormat}}</mat-error>   
                        <mat-error *ngIf="seasonalPriceFormGrp.controls['startDate'].hasError('required') && !seasonalPriceFormGrp.controls['startDate'].hasError('matDatepickerParse')">{{captionsCommon.Missing}} {{captions.StartDate}}</mat-error>   
                        <mat-error *ngIf="seasonalPriceFormGrp.controls['startDate'].hasError('matDatepickerMin')">{{captionsCommon.minimum}} {{captions.StartDate}}</mat-error>
                        <mat-error *ngIf="seasonalPriceFormGrp.controls['startDate'].hasError('matDatepickerMax')">{{captionsCommon.maximum}} {{captions.StartDate}}</mat-error>
                    </mat-form-field>

                </div>
            </div>
            <div class="float-left mt-3 mb-3">
                <label for="" class="LW12 d-block mb-0 rel-pos">{{captions.EndDate}}</label>
                <div class="d-inline-block">
                    <mat-form-field [floatLabel]="floatLabelNever"  appearance="legacy" class="date-picker-width">
                        <input matInput [matDatepicker]="picker1" dateRestricter formControlName='endDate'
                        [min]="minToDate" [placeholder]="placeHolderFormat">
                        <mat-datepicker #picker1 [disabled] = "IsViewOnly"></mat-datepicker>
                        <i  aria-hidden="true" matSuffix class="icon-Calender cursor fs-xlarge position-relative d-inline-block customCalendar" (click)="picker1.open()"></i>
                        <mat-error *ngIf="seasonalPriceFormGrp.controls['EndDate'].hasError('matDatepickerParse')">{{captionsCommon.dateFormat}}</mat-error>   
                        <mat-error *ngIf="seasonalPriceFormGrp.controls['EndDate'].hasError('required') && !seasonalPriceFormGrp.controls['EndDate'].hasError('matDatepickerParse')">{{captionsCommon.Missing}} {{captions.EndDate}}</mat-error>   
                        <mat-error *ngIf="seasonalPriceFormGrp.controls['EndDate'].hasError('matDatepickerMin')">{{captionsCommon.minimum}} {{captions.EndDate}}</mat-error>
                        <mat-error *ngIf="seasonalPriceFormGrp.controls['EndDate'].hasError('matDatepickerMax')">{{captionsCommon.maximum}} {{captions.EndDate}}</mat-error>
                    </mat-form-field>

                </div>
            </div>

            <div class="price-detail  mt-3">
                <mat-form-field class="mr-4" [floatLabel]="floatLabel">
                    <input matInput [placeholder]="captions.Price + ' (' +localization.currencySymbol+')'" RetailCurrencyFormatter formControlName='price'  [attr.datatype]="'number'" [readonly]="IsViewOnly" type="text" >
                </mat-form-field>
                <mat-form-field [floatLabel]="floatLabel">
                    <input matInput [placeholder]="captions.MemberPrice +' (' +localization.currencySymbol+')'" RetailCurrencyFormatter formControlName='memberPrice' [attr.datatype]="'number'" [readonly]="IsViewOnly" type="text" >
                </mat-form-field>
            </div>
        </div>
    </section>
    <mat-dialog-actions class="float-right actionButtons pr-4">
        <button mat-button type="button" (click)="close()">{{this.localization.captions.common.CANCEL}}</button>
        <button mat-button matStepperPrevious type="button"
            class="LW14 text_capitalize  body-bordercolor">{{this.localization.captions.common.PREVIOUS}}</button>
        <button mat-button type="button" *ngIf="!isEdit" (click)="save()" color="primary"
            [ngClass]="this.validateBtn ? 'button_valid' : ' button_invalid'"
            class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.SAVE}}</button>
        <button mat-button type="button" *ngIf="isEdit" (click)="save()" color="primary"
            [ngClass]="this.validateBtn ? 'button_valid' : ' button_invalid'"
            class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.Update}}</button>
    </mat-dialog-actions>
</form>
