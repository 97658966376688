import { Utilities } from '@app/shared/utilities/utilities';
import { ChangeAction } from '@constants/commonenums';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { ReservationDTO } from '@models/InputContact';
import { BookingContactAddonItemDTO, BookingContactDTO, PartyMessageDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import _, { uniqBy } from 'lodash';
import { CacheService } from '../services/cache.service';
import { Processor } from './processor';

export class PartyBookingContactChangeProcessor implements Processor {

  private static instance: PartyBookingContactChangeProcessor;
  private constructor(public partyService: PartyService, public cs: CacheService) { }

  public static Instance(partyService: any = PartyService, cs: CacheService): PartyBookingContactChangeProcessor {
    if (!PartyBookingContactChangeProcessor.instance) {
      PartyBookingContactChangeProcessor.instance = new PartyBookingContactChangeProcessor(partyService, cs);
    }

    return PartyBookingContactChangeProcessor.instance;
  }

  Process(objectChange: ObjectChange, additionaldata: any, propertyId: number): void {
    let partiesList = [];
    let standBy = false;
    if (objectChange) {
      const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'PartyId').Value;
      if (this.partyService.StandbyParties$.value.filter(party => party.Id == partyId).length > 0) {
        partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.StandbyParties$.value : this.cs.propertySettings.value[propertyId].StandbyParties;
        standBy = true;
      }
      else {
        partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.Parties$.value : this.cs.propertySettings.value[propertyId].reservations;
        standBy = false;
      }
      if (objectChange.Action === ChangeAction.Created) {
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'PartyId').Value;
        const party = partiesList.filter(party => party.Id == partyId);

        if (party && party.length) {
          if (!party[0].BookingContacts) {
            party[0].BookingContacts = [];
          }
          let partyContactAddon: BookingContactDTO = new BookingContactDTO();
          objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyContactAddon).includes(property.PropertyName.replace('Internal', ''))) {
              partyContactAddon[property.PropertyName.replace('Internal', '')] = property.Value;
            }
          });
          partyContactAddon =  this.mapAdditionalData(additionaldata , partyContactAddon);
          party[0].BookingContacts.push(partyContactAddon)
          party[0].BookingContacts = uniqBy(party[0].BookingContacts, 'Id');
        }
      }

      if (objectChange.Action === ChangeAction.Updated) {
        const updatedPartyContactAddonId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party: ReservationDTO = partiesList.find(party => party.Id == partyId);
        if (party) {
          let partyContactAddon: BookingContactDTO = party.BookingContacts.find(addon => addon.Id == updatedPartyContactAddonId);
          if(partyContactAddon) {
            objectChange.PropertyChanges.forEach(property => {
            if (Object.getOwnPropertyNames(partyContactAddon)?.includes(property.PropertyName.replace('Internal', ''))) {
              partyContactAddon[property.PropertyName.replace('Internal', '')] = property.Value;
            }
          });
          partyContactAddon =  this.mapAdditionalData(additionaldata , partyContactAddon);
          party.BookingContacts[party.BookingContacts.indexOf(partyContactAddon)] = partyContactAddon;
        }
          partiesList[partiesList.indexOf(party)] = party;
        }
      }
      if (objectChange.Action === ChangeAction.Removed) {
        const removedPartyContactAddonId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
        const party: ReservationDTO = partiesList.find(party => party.Id == partyId);
        // Cancelled reservation has to be removed from the list and from the screen
        if (party) {
          let partyContactAddon: BookingContactDTO = party.BookingContacts.find(addon => addon.Id == removedPartyContactAddonId);
          partyContactAddon =  this.mapAdditionalData(additionaldata , partyContactAddon);
          if (partyContactAddon) {
            party.BookingContacts = party.BookingContacts.filter(addon => addon.Id != removedPartyContactAddonId);
          }
          partiesList[partiesList.indexOf(party)] = party;
        }
      }
    }
    partiesList = _.uniqBy(partiesList, 'Id');
    if (propertyId == Utilities.RestaurantId()) {
      if (standBy) {
        this.partyService.StandbyParties$.next(partiesList);
        this.partyService.standbyPartiesList = partiesList;
        this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      } else {
        this.partyService.Parties$.next(partiesList);
        this.partyService.partiesList = partiesList;
        this.cs.propertySettings.value[propertyId].reservations = partiesList;
      }
    } else {
      if (standBy) {
        this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
      } else {
        this.cs.propertySettings.value[propertyId].reservations = partiesList;
      }
    }
  }
  mapAdditionalData(additionaldata , partyContactAddon){
    if (additionaldata) {
      let guest = additionaldata.find(item => item.Id == partyContactAddon.ContactId);
     if(guest){
      partyContactAddon.FirstName = guest.FirstName;
      partyContactAddon.LastName = guest.LastName;
      partyContactAddon.EmailAddress = guest.EmailAddress;
      partyContactAddon.CountryId = guest.CountryId;
      partyContactAddon.PhoneNumber = guest.PhoneNumber;
      partyContactAddon.IsVip = guest.IsVip;
      partyContactAddon.PreferredPageMethod = guest.PreferredPageMethod;
      partyContactAddon.Notes = guest.Notes;
      partyContactAddon.IsMemberActive = guest.IsMemberActive;
      partyContactAddon.TrackMembershipNumber = guest.TrackMembershipNumber;
      partyContactAddon.ContactCustomFields = guest.ContactCustomFields;
     }
    }
    return partyContactAddon;
  }
}
