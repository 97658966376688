import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { RetailPropertyInformation } from "../../common/services/retail-property-information.service";
import { RetailService } from "../../retail.service";
import { ShopBussinessService } from "../../shop/shop-business.service";
import { GiftCardTransactionType, Host } from "../globalsContant";
import { CommonVariablesService } from "../service/common-variables.service";
import { HttpMethod, HttpServiceCall, ServiceParamsAsync } from "../service/http-call.service";
import { ArAccount, PaymentDetails, PaymentHistory, PaymentMethod } from "../service/payment/payment-business.model";
import { BaseResponse } from "../shared.modal";
import { RetailUtilities } from "../utilities/retail-utilities";
import { PaymentMethods, Transaction, TransactionDetail } from "./shared.modals";
import * as GlobalConst from 'src/app/retail/shared/globalsContant';
import { WalletBusinessService } from "./Wallet-business.service";

@Injectable()
export class SettleRefundTransactionBusiness {
    constructor(
        private utils: RetailUtilities
        , private http: HttpServiceCall
        , private localization: RetailLocalization
        , private propertyInfo: RetailPropertyInformation
        , private cvs: CommonVariablesService
        , private sbs: ShopBussinessService
        , private router: Router
        , private loaderService: RetailService
        , private _walletBusiness: WalletBusinessService
    ) {

    }

    private async getTransactionAggregateDetail(transactionIds: number[]): Promise<Transaction[]> {
        if (!this.ValidateIds(transactionIds)) { return }
        let response: BaseResponse<Transaction[]> = await this.http.CallApiAsync<Transaction[]>({
            callDesc: "GetAggregateTransactionByIds",
            host: Host.retailPOS,
            method: HttpMethod.Get,
            withQueryString: "ids",
            queryString: { key: "ids", value: transactionIds }
        });
        return response.result;
    }

    private ValidateIds(Ids: number[]) {
        return (Ids.length > 0 && Ids.every(x => x > 0));
    }

    async FormReturnedRetainedExchangedItems(transactionInfo) {
        if (transactionInfo) {
            try {
                //loadingData
                this.loaderService.loaderEnable.next(this.localization.captions.common.loadingData);
                const parentTransactionId: number = transactionInfo.transactionLinkId;
                const currentTransctionId: number = transactionInfo.id;
                const transactionList = await this.getTransactionAggregateDetail([parentTransactionId, currentTransctionId]);
                const parentTransaction = transactionList.find(x => x.id == parentTransactionId);
                const currentTransaction = transactionList.find(x => x.id == currentTransctionId);

                let activeItemsInTrans: TransactionDetail[] = [], retainedItems = [], exchangedItems = [], returnedItems = [];
                //Return with exchange or partial return
                if (currentTransaction && currentTransaction.transactionDetails && currentTransaction.transactionDetails.length > 0) {
                    activeItemsInTrans = currentTransaction.transactionDetails.filter(x => !x.isReturn); //retained or exchanged
                }
                const parentTransItems = parentTransaction && parentTransaction.transactionDetails.filter(x => !x.isReturn);
                const partialReturnItems = currentTransaction.transactionDetails.filter(x => x.transactionDetailLinkId > 0);
                parentTransItems.forEach(p => {
                    //Considering an item as returned if it is not present in current transaction
                    if (!currentTransaction.transactionDetails.some(x => x.id == p.id)) {
                        //check if any partial return exist and update quantity
                        const partialReturnedInCurrent = currentTransaction.transactionDetails.find(x => x.transactionDetailLinkId == p.id);
                        if (partialReturnItems && partialReturnItems.length > 0 && partialReturnedInCurrent) {
                            p.quantitySold = p.quantitySold - partialReturnedInCurrent.quantitySold;
                        }
                        if (p.quantitySold >= 1) { returnedItems.push(p); }
                    }
                });

                if (activeItemsInTrans && activeItemsInTrans.length > 0) {
                    activeItemsInTrans.forEach(x => {

                        if (currentTransaction.transactionDetails.some(y => y.id == x.id && !y.isReturn) &&
                            !parentTransaction.transactionDetails.some(y => y.id == x.id && !y.isReturn) &&
                            x.transactionDetailLinkId == 0) {
                            exchangedItems.push(x);
                        } else {
                            retainedItems.push(x);
                        }
                    });
                }

                const allItemsInvolvedInTrans = returnedItems.concat(retainedItems).concat(exchangedItems);
                console.log({ returnedItems });
                console.log({ retainedItems });
                console.log({ exchangedItems });
                console.log({ allItemsInvolvedInTrans });

                //Form settlement history
                const settleFlowDesc = "settle";
                const retailItemIds = [...new Set(allItemsInvolvedInTrans.map(x => x.itemId))]; 
                const allItems = await this.cvs.getRetailItemsDetailedInfoByIds(retailItemIds);                
                this.cvs.selectedExchangeRetailProducts = this.cvs.FormSelectedProduct(exchangedItems, allItems, settleFlowDesc);
                this.cvs.selectedRetainedRetailProducts = this.cvs.FormSelectedProduct(retainedItems, allItems, settleFlowDesc);
                this.cvs.selectedReturnedRetailProducts = this.cvs.FormSelectedProduct(returnedItems, allItems, settleFlowDesc);
                //Fill necessary Flags before redirection                
                this.loaderService.paymentProcessing = true;
                this.cvs.returnReason = transactionInfo.returnReason;
                this.cvs.returnExchangeType = exchangedItems.length > 0 ? "item_WE" : "item_WOE";
                this.cvs.ReturnMethod = exchangedItems.length > 0 ? 2 : 1;
                this.cvs.selectedPayeeId = currentTransaction.transactionData.guestId;
                this.cvs.selectedGuestGuid = currentTransaction.transactionData.guestGuid;
                this.sbs.ReturnWithTicketResponse = currentTransaction;
                this.cvs.sectionName = "RIS"; //Retail transaction Item Detail
                this.cvs.settleRWTTransaction = true;
                let settlementHistory: PaymentHistory[] = [];
                let paymentMethods: any[] = [];
                if (currentTransaction && currentTransaction.transactionPayments && currentTransaction.transactionPayments.length > 0) {
                    const paymentReferenceIds = currentTransaction.transactionPayments.filter(x => !x.doNotDisplay).map(x=> x.paymentReferenceId);
                    const originalSettlementIds = parentTransaction.transactionPayments.map(x => x.paymentReferenceId);
                    let serviceParams: ServiceParamsAsync;
                        serviceParams = {
                            callDesc: "GetPaymentConfiguration",
                            host: GlobalConst.Host.payment,
                            method: HttpMethod.Get
                        }
                    const res =await this.http.CallApiAsync<any>(serviceParams);
                    paymentMethods = res.result.paymentMethods;
                    const paymentTransactionDetails: PaymentDetails[] = await this.sbs.GetPaymentTransactionDetails(paymentReferenceIds);
                    console.log(paymentTransactionDetails);
                    if (paymentTransactionDetails && paymentTransactionDetails.length > 0) {
                        paymentTransactionDetails.forEach(paymentTrans => {
                            if (!originalSettlementIds.includes(paymentTrans.paymentReferenceId)) {
                                const transactionPayment = currentTransaction.transactionPayments?.find(x => x.paymentReferenceId == paymentTrans.paymentReferenceId)
                                let settlement: PaymentHistory = {
                                    amount: paymentTrans.amount,
                                    paymentMethod: this.FormSettlementHistoryLabel(paymentTrans,paymentMethods),
                                    parentTenderId: paymentTrans?.parentTenderId,
                                    paymentMethodId: paymentTrans.paymentMethodId,
                                    cardNumber: paymentTrans.cardNumber,
                                    entryMode: paymentTrans.entryMode,
                                    issuerType: paymentTrans.issuerType,
                                    isPrefilled: true,
                                    disableDelete: (paymentTrans.giftcardTransactionType == GiftCardTransactionType.Issue),
                                    paymentReferenceComment : paymentTrans?.referenceComment,
                                    taxExemptRatio: transactionPayment ? transactionPayment.taxExemptRatio : 0,
                                    discountExemptRatio : transactionPayment ? transactionPayment.discountExemptRatio : 0,
                                    tenderReducesDiscount : transactionPayment?.discountExemptRatio > 0,
                                    tenderReducesTax : transactionPayment?.taxExemptRatio > 0,
                                    paymentReferenceId : transactionPayment.paymentReferenceId
                                }
                                settlementHistory.push(settlement);
                            }
                        });
                        console.log(settlementHistory);
                    }
                }

                
                this.sbs.SettlementHistory = settlementHistory;
                this.sbs.ShowSettlemtHist = (settlementHistory.length > 0);
                this.cvs.settleRWTTransaction = true;
                this.cvs.reOpenTransaction = this.cvs.settleOpenTransaction = false;
                this.cvs.selectedRetailItem = transactionInfo;
                this.cvs.isReturnWithoutTicket = false;
                this.cvs.selectedTransactionRetailProducts = [];                                
                this.cvs.LoadSelectedProducts(allItemsInvolvedInTrans, [], settleFlowDesc);
                this.cvs.returnWithticketItems = this.cvs.selectedProducts;
                this.cvs.selectedTransactionRetailProducts = this.cvs.selectedProducts;                
                this.loaderService.loaderEnable.next("");
                //Redirect to Return with ticket screen
                this.router.navigate(['/shop/viewshop/shopItemDetails']);

            } catch (error) {
                console.log("error occurred while processing the settle request for RWT trans" + error);
                this.loaderService.loaderEnable.next("");
            }
        }
    }

    private FormSettlementHistoryLabel(paymentTransDetail: PaymentDetails, paymentMethods: PaymentMethod[] = []) {
        const V1GiftCardMethods = [
            PaymentMethods.V1GiftCard,
            PaymentMethods.V1GiftCardIdTech
        ]
        const GiftCardMethods: PaymentMethods[] = [
            PaymentMethods.V1GiftCard,
            PaymentMethods.V1GiftCardIdTech,
            PaymentMethods.ExternalGiftCard,
            PaymentMethods.ExternalGiftCardIdTech,
            PaymentMethods.AgilysysGiftCard,
            PaymentMethods.AgilysysGiftCardIdTech
        ];
        const paymentTypeId = paymentTransDetail.paymentMethodId;
        const patronId = paymentTransDetail.patronId;
        const acctdetails: ArAccount = {
            accountName: paymentTransDetail.aRAccountName,
            accountNumber: paymentTransDetail.aRAccountNumber
        }
        let paymentLabel = this.localization.captions.shop.paymentMethods[paymentTypeId];
        if (paymentTypeId == PaymentMethods.CompRedemption && paymentTransDetail.patronId != '') {
            paymentLabel = `${this.localization.captions.shop.paymentMethods[paymentTypeId]} | ${patronId} | ${this.localization.captions.shop.CMS.handleType[paymentTransDetail.redemptionType]}`;
        } else if (paymentTypeId == PaymentMethods.OfferRedemption && patronId != '') {
            paymentLabel = `${this.localization.captions.shop.paymentMethods[paymentTypeId]} | ${patronId} | ${paymentTransDetail.offerName}`;
        } else if (V1GiftCardMethods.includes(paymentTypeId) && paymentTransDetail.cardNumber) {
            paymentLabel = `${this.localization.captions.shop.paymentMethods[paymentTypeId]} | ${paymentTransDetail.cardNumber}`;
        } else if (GiftCardMethods.includes(paymentTypeId) && paymentTransDetail.cardNumber) {
            const paymentMethod = this.localization.replacePlaceholders(this.localization.captions.shop.paymentMethods[paymentTypeId], ["Third Party"], [paymentTransDetail.vendorName])
            paymentLabel = `${paymentMethod} | ${paymentTransDetail.cardNumber}`;
        } else if (paymentTypeId == PaymentMethods.ARAcctPost && acctdetails) {
            paymentLabel = `${paymentTransDetail.paymentMethod} | ${acctdetails.accountName} | ${acctdetails.accountNumber}`;
        } else if (paymentTypeId == PaymentMethods.RoomCharge && paymentTransDetail.roomNumber) {
            paymentLabel = `${this.localization.captions.shop.paymentMethods[paymentTypeId]} | ${paymentTransDetail.roomNumber}`
        } else if (paymentTypeId == PaymentMethods.Wallet && paymentTransDetail && paymentTransDetail.walletNumber) {
            let maskedWalletNumber = this._walletBusiness.MaskWalletNumber(paymentTransDetail.walletNumber)
            paymentLabel = `${paymentTransDetail.paymentMethod} | ${maskedWalletNumber}`;
        }
        else {
            paymentLabel = paymentTransDetail.paymentMethod;
        }
        return paymentLabel;
    }
}