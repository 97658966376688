import { Product } from 'src/app/common/Models/common.models';
import { DropdownOptions } from '../../Models/ag-models';
export namespace UI {
    export interface DmConfig {
        id: number;
        enableDatamagine: boolean;
        product: Product;
        serverurl: string;
        username: string;
        password: string;
        scannerEndPoint: string;
        signature: boolean;
        signCapEndPoint: string;
        enablelink ?: boolean;
        enableScanner ?: boolean;
        enableSignatureCapture ?: boolean;
        showPreview ?: boolean;
        enableEforms ?: boolean;
        propertyID: string,
        tenantID: string,
        authurl: string,
        eformurl: string,
        eformlisturl: string,
        showDuringCheckIn: boolean,
        clientId: string,
        clientSecret: string,
        dmEformConfigId: number,
        eFormConfigurations: DmEformsConfig;
        postCheckReceiptToDM:boolean;
        viewName:string;
        supportedLetter?: Number[] | DropdownOptions[];
        bookingViewText?: string;
        accountViewText?: string;
        proformaViewText?: string;
        beoViewText?: string;
        beoCheckViewText?: string,
        accountDocSignExpiaryDate?: Number,
        bookingDocSignExpiaryDate?: Number,
        proformaDocSignExpiaryDate?: Number,
        beoDocSignExpiaryDate?: Number,
        beoCheckDocSignExpiaryDate?: Number
    }
    export interface DmEformsConfig {
        useEformSignCapture: boolean;
        showOnCheckin: boolean;
        showOnPreview: boolean;
        searchTemplate: string;
        defaultTemplate: boolean;
        signEndPoint: string;
    }


}
export enum LetterType {
    Booking = 1,
    Cancellation = 2,
    InquiryDenial = 3,
    SalesCall = 4,
    Account = 5,
    Proforma = 6,
    Beo = 8,
    Check = 9,

}
