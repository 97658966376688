<div class="financial-details" *ngIf="financialDetail">
    <div class="financial-details__header seat-popover-header">
      {{financialDetail.header}}
    </div>
    <div class="financial-details__content seat-popover-content">
      <div class="financial-details__paid">
        <div>{{financialDetail.message}}</div>
        <div class="financial-details__due" *ngIf = "financialDetail.paymentduemessage">{{financialDetail.paymentduemessage}}</div>
        <div class="financial-details__due cancelled-booking-financial-details" *ngIf="financialDetail.paymentMessage">
          {{financialDetail.paymentMessage}}</div>
        <div class="financial-details__due cancelled-booking-financial-details" *ngIf="financialDetail.subMessage">
          {{financialDetail.subMessage}}</div>

            <div *ngIf="paymentResponse.PaidPoints > 0">{{'Paid'| translate}} {{'roundsText' | translate}}: {{paymentResponse.PaidPoints}}</div>
           <div *ngIf="paymentResponse.PendingPoints > 0"> {{'pending' | translate}} {{'roundsText' | translate}}: {{paymentResponse.PendingPoints}}</div>
         
      </div>
      <div class="financial-details__Ticket" *ngIf="financialDetail?.TicketNumber">
        {{'ticketNumber'| translate}} : {{financialDetail?.TicketNumber}}
      </div>
      <ng-container *ngIf="financialDetail?.Transactions.length">
        <div class="financial-details__header seat-popover-header mt-1">
          {{'Tender Info' | translate}}
        </div>
        <div *ngFor="let transactionType of financialDetail?.Transactions;let i=index;let last = last" class="p-1" [ngClass]="{'border-bottom' : i != last}">
          <span>{{transactionType.Tender}} - </span>
          <span>{{OperationCurrency}}{{transactionType.Amount}}</span>
       </div>
      </ng-container>
    </div>
  </div>