<div [ngSwitch]="buttontype?.type" class="custom-button" appShowellipsisText>
    <button class="primary-green" [ngClass]="buttontype.customclass" #dynamicbutton *ngSwitchCase="'primary-green'"
        [disabled]='buttontype.disbaledproperity' mat-button (click)="handleclick($event)"><i class="btn-icon"
            [ngClass]="buttontype.icon"></i>
        <span>{{ buttontype.label | translate }}</span></button>

    <button class="primary-red" [ngClass]="buttontype.customclass" #dynamicbutton *ngSwitchCase="'primary-red'"
        [disabled]='buttontype.disbaledproperity' mat-button (click)="handleclick($event)"><i class="btn-icon"
            [ngClass]="buttontype.icon"></i> {{ buttontype.label | translate }}</button>

    <button class="secondary action-secondary-small red-colored-btn" [ngClass]="buttontype.customclass" #dynamicbutton
        *ngSwitchCase="'action-secondary-small-red'" [disabled]='buttontype.disbaledproperity' mat-button
        (click)="handleclick($event)"><i class="btn-icon" [ngClass]="buttontype.icon"></i>
       <!-- <i class="btn-icon rs-mr-8" [ngClass]=""></i>  -->
        {{ buttontype.label | translate }}</button>
            
    <button class="secondary action-secondary-small orange-colored-btn" [ngClass]="buttontype.customclass" #dynamicbutton
        *ngSwitchCase="'action-secondary-small-orange'" [disabled]='buttontype.disbaledproperity' mat-button
        (click)="handleclick($event)"><i class="btn-icon" [ngClass]="buttontype.icon"></i>
        <!-- <i class="btn-icon rs-mr-8" [ngClass]=""></i>  -->
        {{ buttontype.label | translate }}</button>
        <button class="secondary action-secondary-small orange-full-btn" [ngClass]="buttontype.customclass" #dynamicbutton
        *ngSwitchCase="'action-secondary-fully-orange'" [disabled]='buttontype.disbaledproperity' mat-button
        (click)="handleclick($event)"><i class="btn-icon" [ngClass]="buttontype.icon"></i>
        <!-- <i class="btn-icon rs-mr-8" [ngClass]=""></i>  -->
        {{ buttontype.label | translate }}</button>
    <button class="secondary" [ngClass]="buttontype.customclass" #dynamicbutton *ngSwitchCase="'secondary'"
        [disabled]='buttontype.disbaledproperity' mat-button (click)="handleclick($event)"><i class="btn-icon"
            [ngClass]="buttontype.icon"></i>  {{ buttontype.label | translate }}</button>

    <button class="primary-green action-primary" [ngClass]="buttontype.customclass" #dynamicbutton
        *ngSwitchCase="'action-primary'" [disabled]='buttontype.disbaledproperity' mat-button
        (click)="handleclick($event)"><i class="btn-icon"
        [ngClass]="buttontype.icon"></i> {{ buttontype.label | translate }}
        <span class="d-block rs-Roboto-Regular-10" *ngIf="buttontype.additionalText">{{buttontype.additionalText | translate}}</span></button>

    <button class="primary-green action-primary-small" [ngClass]="buttontype.customclass" #dynamicbutton
        *ngSwitchCase="'action-primary-small'" [disabled]='buttontype.disbaledproperity' mat-button
        (click)="handleclick($event)"><i class="btn-icon"
        [ngClass]="buttontype.icon"></i>  {{ buttontype.label | translate }}</button>

    <button class="primary-green action-primary-small" [ngClass]="buttontype.customclass" #dynamicbutton *ngSwitchCase="'action-multi-primary-small'"
    [disabled]='buttontype.disbaledproperity' mat-button [matMenuTriggerFor]="menu"> <i class="btn-icon"
    [ngClass]="buttontype.icon"></i>  {{ buttontype.label | translate}} <span *ngIf="buttontype.menu?.length > 0" class="icon-Up-Arrow ml-1"></span></button>
        <mat-menu  #menu="matMenu">
            <ng-container *ngFor="let menuLabel of buttontype?.menu">
                <button mat-menu-item  (click)="handleclick($event)" [id]="menuLabel">{{ menuLabel | translate}}</button> 
            </ng-container>
        </mat-menu>

    <button class="secondary action-secondary" [ngClass]="buttontype.customclass" #dynamicbutton
        *ngSwitchCase="'action-secondary'" [disabled]='buttontype.disbaledproperity' mat-button
        (click)="handleclick($event)" [ngStyle]="buttontype.customStyle"><i class="btn-icon"
            [ngClass]="buttontype.icon"></i> {{ buttontype.label | translate }}
        <span class="d-block rs-Roboto-Regular-10" *ngIf="buttontype.additionalText">{{buttontype.additionalText | translate}}</span></button>

    <button class="secondary action-secondary-small" [ngClass]="buttontype.customclass" #dynamicbutton
        *ngSwitchCase="'action-secondary-small'" [disabled]='buttontype.disbaledproperity' mat-button
        (click)="handleclick($event)"><i class="btn-icon"
        [ngClass]="buttontype.icon"></i>
        <!-- <i class="btn-icon rs-mr-8" [ngClass]=""></i>  -->
         {{ buttontype.label | translate }}</button>


    <button class="secondary action-secondary-small rounded-btn" [ngClass]="buttontype.customclass" #dynamicbutton
        *ngSwitchCase="'rounded-btn-small'" [disabled]='buttontype.disbaledproperity' mat-button
        (click)="handleclick($event)"><i class="btn-icon"
        [ngClass]="buttontype.icon"></i> {{ buttontype.label | translate }}</button>

    <button class="secondary action-secondary rounded-btn" [ngClass]="buttontype.customclass" #dynamicbutton
        *ngSwitchCase="'rounded-btn'" [disabled]='buttontype.disbaledproperity' mat-button
        (click)="handleclick($event)"><i class="btn-icon"
        [ngClass]="buttontype.icon"></i>  {{ buttontype.label | translate }}</button>

    <button class="primary-green action-primary" [ngClass]="buttontype.customclass" #dynamicbutton
    *ngSwitchCase="'action-primary-print'" [disabled]='buttontype.disbaledproperity' mat-button
    [useExistingCss]="true"
    printSectionId="{{buttontype.additionalText}}"
    ngxPrint><i class="btn-icon"
    [ngClass]="buttontype.icon"></i> {{ buttontype.label | translate }}
    </button>

    <button class="primary-green action-primary test" [ngClass]="buttontype.customclass" #dynamicbutton
    *ngSwitchCase="'action-primary-print-ngxPrint'" [disabled]='buttontype.disbaledproperity' mat-button
    (click)="handleclick($event,buttontype.printId,buttontype.additionalText,buttontype.printerType)"><i class="btn-icon"
    [ngClass]="buttontype.icon"></i> {{ buttontype.label | translate }}
    </button>

    <button class="primary-purple action-primary" [ngClass]="buttontype.customclass" #dynamicbutton
    *ngSwitchCase="'action-primary-overbook'" [disabled]='buttontype.disbaledproperity' mat-button
    (click)="handleclick($event)"><i class="btn-icon"
    [ngClass]="buttontype.icon"></i> {{ buttontype.label | translate }}
    <span class="d-block rs-Roboto-Regular-10" *ngIf="buttontype.additionalText">{{buttontype.additionalText | translate}}</span></button>

    <button class="secondary-purple action-secondary" [ngClass]="buttontype.customclass" #dynamicbutton
    *ngSwitchCase="'action-secondary-overbook'" [disabled]='buttontype.disbaledproperity' mat-button
    (click)="handleclick($event)"><i class="btn-icon"
    [ngClass]="buttontype.icon"></i> {{ buttontype.label | translate }}
    <span class="d-block rs-Roboto-Regular-10" *ngIf="buttontype.additionalText">{{buttontype.additionalText | translate}}</span></button>

</div>

<ng-content></ng-content>