<div class="advanced-search-section">
    <div class="progressbar-div">
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="advanced-search-header">
        <span class="advanced-search-label">{{captions.shop.AdvancedSearch}}</span>
    </div>
    <form class="advanced-search-inputs" [formGroup]="formGroup">
        <input [attr.automationId]="'Txt_reprintAdvancedSearch_clientName'" matInput autocomplete="off" formControlName="clientName" class="search-input" type="text"
            [placeholder]="captions.shop.ClientName" />
        <input [attr.automationId]="'Txt_reprintAdvancedSearch_memberName'" matInput autocomplete="off" formControlName="memberName" class="search-input" type="text"
            [placeholder]="captions.shop.MemberName" />
        <input [attr.automationId]="'Txt_reprintAdvancedSearch_clerkId'" matInput autocomplete="off" formControlName="clerkId" class="search-input" type="text"
            [placeholder]="captions.shop.SoldBy" />
        <input [attr.automationId]="'Txt_reprintAdvancedSearch_ticketNumber'" matInput autocomplete="off" formControlName="ticketNumber" class="search-input" type="text"
            [placeholder]="captions.shop.TicketNumber" />
        <input [attr.automationId]="'Txt_reprintAdvancedSearch_cardNumber'" matInput autocomplete="off" formControlName="cardNumber" class="search-input" inputtype="nonnegative,nodecimal"  [maxlength]="4"
            [placeholder]="captions.shop.CardNumberSearch" />
        <input [attr.automationId]="'Txt_reprintAdvancedSearch_cardType'" matInput autocomplete="off" formControlName="cardType" class="search-input" inputtype="notallowspace,nospecailchar"  [maxlength]="30"
            [placeholder]="captions.shop.CardType" />
        <input [attr.automationId]="'Txt_reprintAdvancedSearch_amount'" matInput autocomplete="off" formControlName="amount" class="search-input" inputtype="nonnegative,decimal,roundoff2"
            [placeholder]="captions.shop.Amount" />
        <ng-container *ngIf="fromScreen != retailTransactions.opentransactions && fromScreen != retailTransactions.NonIntegratedPaymentLog">
            <input [attr.automationId]="'Txt_reprintAdvancedSearch_voidedByClerkId'" matInput autocomplete="off" formControlName="voidedByclerkId" class="search-input" type="text"
            [placeholder]="captions.shop.CorrectedOrVoidedByID" />
        </ng-container>
        <input *ngIf="fromScreen != retailTransactions.NonIntegratedPaymentLog" [attr.automationId]="'Txt_reprintAdvancedSearch_transactionType'" matInput autocomplete="off" formControlName="transactiontype" class="search-input" type="text"
            [placeholder]="captions.shop.lbl_transactionType" />
        <ng-container *ngIf="fromScreen == retailTransactions.opentransactions">
            <input [attr.automationId]="'Txt_reprintAdvancedSearch_amountReceived'" matInput autocomplete="off" formControlName="amountReceived" class="search-input" inputtype="nonnegative,decimal,roundoff2"
            [placeholder]="captions.shop.lbl_payment_received" />
        </ng-container>
        <input [attr.automationId]="'Txt_reprintAdvancedSearch_transactionAdditionReference'" *ngIf="productIdcheck==productId" matInput autocomplete="off" formControlName="transactionAdditionalReference" class="search-input" inputtype="nonnegative,nodecimal" [maxlength]="10"
            [placeholder]="captions.shop.lbl_beoNumber" />
        <div *ngIf="fromScreen != retailTransactions.NonIntegratedPaymentLog" class="item-search">
            <app-search-linked-retail-items [automationId]="'reprintAdvancedSearch'" [filteredData]="retailItems" [displayTexts]="itemNumberCaptions"
                (searchTextEmit)="onItemSearch($event)" [autocomplete]="formGroup.controls.itemId"
                (doneEvtEmitter)="onItemChosen($event)">
            </app-search-linked-retail-items>
        </div>
        <button [attr.automationId]="'Btn_reprintAdvancedSearch_search'" matButton class="advanced-search-button body-bgcolor whitecolor LW14 ag_button--primary "
            (click)="onSearch()">{{captions.shop.Search}}</button>
            <button [attr.automationId]="'Btn_reprintAdvancedSearch_reset'" matButton class="advanced-search-button ml-auto secondary-default-btn"[ngClass]="this.validateBtn ? 'button_valid' : 'button_invalid'"
            (click)="OnReset()">{{captions.shop.GiftCard.Reset}}</button>
            <input [attr.automationId]="'Txt_reprintAdvancedSearch_submit'" type="submit" class="hidden-submit"
        tabindex="-1"  (click)="onSearch()"/>
    </form>
</div>  