<section [formGroup]="customFeeForm" class="ag_container--padding-sm custom-fee-wrapper">
  <div [ngClass]="IsViewOnly ? 'ag_section--disable' : '' ">
    <div class="LW14 pb-2 display-text">{{captions.lbl_requireCustomFee}}</div>
    <ui-switch class="LW14 switch-toggle" size="small" checkedLabel={{captions.checkedLable}} formControlName="requireAdditionalFee" [attr.automationId]="'Tog_customfee_requireAdditionalFee'"
      uncheckedLabel={{captions.uncheckedLable}} (change)="sliderChange($event)"></ui-switch>
  </div>
  <div class="pt-2" *ngIf="isAdditionalFeeEnabled" [ngClass]="!isAdditionalFeeEnabled ? 'ag_section--disable' : '' ">
    <div class="ag_display--flex-wrap align-items-baseline">
      <app-ag-date-picker [inputs]="fromDateInputs" (datePickerChange)="onStartDateChanged($event)"></app-ag-date-picker>
      <app-ag-date-picker [inputs]="toDateInputs" (datePickerChange)="onEndDateChanged($event)"></app-ag-date-picker>
      <!-- <ag-dropdown class="ag_form-control ag_display--inblock" [isAll]='true' [multiple]="true" [placeholder]="captions.lbl_fee" formControlName="fee" [source]="feeOptions">
      </ag-dropdown> -->
      <!-- <app-ag-dropdown id="ag-automation-select-status" [inputs]="feeInput"></app-ag-dropdown> -->
      <app-button [buttontype]="applyButton"  (valueChange)='getCustomFeeDataByFilters()' [attr.automationId]="'Btn_customFee_selectedTerminal'"></app-button>
      <app-simple-search class='ag_ml--auto pr-4' [placeholder]="captions.lbl_searchByCustomFeeNameDate" [attr.automationId]="'Srch_customFee_customFeeSearch'"
                (searchChange)="searchValueChange($event)">
      </app-simple-search>
      <app-button [buttontype]="createButton" [ngClass]="IsViewOnly ? 'ag_section--disable' : '' " (valueChange)='openDialog(1)' [attr.automationId]="'Btn_customFee_selectedTerminal'"></app-button>
    </div>
  </div>
  <div class="table_hgt" *ngIf="isAdditionalFeeEnabled" [attr.automationId]="'Tbl_customFee_customFeeTable'">
    <app-cdkvirtual [IsEditModeEnabled]="IsEditModeEnabledFlag" [headerOptions]="headerOptions" [searchOptions]="searchText"
      [tableContent]="tableContent" [options]="options" (EmittedData)="tableAction($event)"></app-cdkvirtual>
  </div>
</section>

