import { Component, OnInit, OnDestroy, HostListener, HostBinding, ElementRef, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { KeyboardService } from './keyboard.service';
import Keyboard from "simple-keyboard";

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent implements AfterViewInit {
  @HostBinding('class.shown')
  private shown: boolean;

  private keyboardSubscription: Subscription;
  public spaceValue: string = ' ::: ::: ';


  keyboard: Keyboard;

  constructor(private el: ElementRef, public keyboardService: KeyboardService) {
  }

  ngOnInit() {
    this.keyboardSubscription = this.keyboardService.keyboardRequested.subscribe(show => {
      let overlayContainer = document.getElementsByClassName("cdk-overlay-container")[0] as HTMLElement;

      if (show) {
        this.shown = true;
        if(overlayContainer) overlayContainer.style.height = "calc(100vh - 270px)"        
      }
      else {
        this.shown = false;
        if(overlayContainer) overlayContainer.style.removeProperty("height");
      }
    });
  }

  ngOnDestroy() {
    this.keyboardSubscription.unsubscribe();
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('click', ['$event'])
  onMouseEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  
  @HostListener('touchend', ['$event'])
  onMouseUpEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }


  ngAfterViewInit() {
    this.keyboard = new Keyboard({
      layout: {
        default: [
          '1 2 3 4 5 6 7 8 9 0 - {bksp}',
          'q w e r t y u i o p',
          '\\ a s d f g h j k l ;',
          '{shift} z x c v b n m , . / {shift}',
          '@ {space} .com'
        ],
        shift: [
          '~ ! # $ % &amp; * ( ) _ + {bksp}',
          'Q W E R T Y U I O P',
          '\\ A S D F G H J K L :',
          '{shift} Z X C V B N M , . / {shift}',
          '@ {space} .com'
        ]
      },
      mergeDisplay: true,
      display: {
        '{bksp}': 'Backspace',
        '{enter}': 'Enter'
      },
      onKeyPress: button => this.onKeyPress(button)
    });
  }

  onKeyPress = (button: string) => {

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") {
      this.handleShift()
    } else if (button === "{bksp}") {
      this.keyboardService.fireBackspacePressed();
    } else if (button === "{space}") {
      this.keyboardService.fireKeyPressed(' ');
    } else {
      this.keyboardService.fireKeyPressed(button);
    }

    this.keyboard.clearInput();
  };

  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value);
  };

  handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  };
}