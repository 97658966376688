<section id="rental-item" class="newVersionWrapper">
    <ng-scrollbar   >
        <div [ngClass]="isEdit && IsViewOnly ? 'disableItemSetup' : ''" (click)="stopdefaultClick($event)"
        (keydown)="stopdefaultClick($event)">
        <form autocomplete="off" [formGroup]="rentalInfoForm" class="rental-wrapper">
          <div class="bodyWrap">
            <h4 class="ag_group--headers">{{captions.lbl_rentalInfo}}</h4>
            <div class="ag_display--flex">
              <div class="ag_form-control--25 pr-5">
                  <label class="text-label-new mb-1">{{captions.rateType}}</label>
                  <mat-button-toggle-group [attr.automationId]="'Tog_rentalInfo_rateType'" name="fontStyle" aria-label="Font Style" class="LW14 w-100"
                    formControlName="rateType" (change)="rateTypeChange($event)">
                    <mat-button-toggle class="w-50" [value]="0">{{captions.hourlyRate}}</mat-button-toggle>
                    <mat-button-toggle class="w-50" [value]="1">{{captions.fixedRate}}</mat-button-toggle>
                  </mat-button-toggle-group>
              </div>
              <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--25 pr-5">
                <input [attr.automationId]="'Txt_rentalInfo_rate'" matInput CurrencyFormatter [fieldUpdate]="triggerPatch" placeholder='{{captions.rate}} ({{currencySymbol}})'
                    formControlName="rate">
                    <mat-error *ngIf="this.rentalInfoForm.controls.rate.hasError('required')">
                      {{captions.err_missingRate}}
                    </mat-error>
              </mat-form-field>
              <mat-form-field  [floatLabel]="floatLabel" class="ag_form-control--25 pr-5" *ngIf="!_commonVarService.isRentalAtOutletLevel">
                <input required [attr.automationId]="'Txt_rentalInfo_quantity'" matInput [placeholder]="captions.quantity" (input)="rentalQuantityChange($event)"
                  formControlName="quantity" type="number" numMaxlength inputtype="nonnegative,nodecimal" min="1" max="999" maxlength="3">
                  <mat-error *ngIf="this.rentalInfoForm.controls.quantity.hasError('required')">
                    {{commonCaption.err_missingQuantity}}
                  </mat-error>
                  <mat-error *ngIf="this.rentalInfoForm.controls.quantity.hasError('min')">
                    {{commonCaption.InvalidValue}}
                  </mat-error>
                  <mat-error *ngIf="this.rentalInfoForm.controls.quantity.hasError('max')">
                    {{commonCaption.InvalidValue}}
                  </mat-error>
              </mat-form-field>
              <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--25" *ngIf="!_commonVarService.isRentalAtOutletLevel">
                <input [attr.automationId]="'Txt_rentalInfo_outofOrderQuantity'"  matInput [placeholder]="captions.outOforderQty" (input)="outOfOrderChange($event)"
                  formControlName="outofOrderQuantity" type="number" numMaxlength inputtype="nonnegative,nodecimal" min="0" max="999" maxlength="3">
                <mat-error *ngIf="this.rentalInfoForm.controls.outofOrderQuantity.hasError('invalid')">
                  {{captions.err_outOfOrder}} 
                </mat-error>
              </mat-form-field>
          </div>

          <div *ngIf="rentalInfoForm.controls.rateType.value == 0">
            <div class="ag_display--flex label-width">
              <div class="w-66 ml-1">{{captions.minutes}}</div>
              <div class="">{{captions.rate}} ({{currencySymbol}})</div>
            </div>
            <div class="hourly-section" formArrayName="hourlyRate" *ngFor="let row of rentalInfoForm.get('hourlyRate')['controls']; let i = index; let last = last">
              <div [formGroupName]="i" class="hourly-rate" [ngClass]="{'pb-3':last}">
                <mat-form-field [floatLabel]="floatLabel" class="w-33 pr-3 mt-0 after-liner">
                  <input [attr.automationId]="'Txt_rentalInfo_slabStart'" min="1" max="999" numMaxlength maxlength="3" matInput inputtype="nonnegative,nodecimal"
                    formControlName="slabStart" type="number" (input)="minutesInputChange(i)" readonly>
                    <mat-error *ngIf="rentalInfoForm.get('hourlyRate')['controls'][i].controls.slabStart.hasError('invalid')">
                      {{captions.InvalidValue}}
                    </mat-error>
                    <mat-error *ngIf="rentalInfoForm.get('hourlyRate')['controls'][i].controls.slabStart.hasError('required')">
                      {{captions.MissingValue}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field [floatLabel]="floatLabel" class="w-33 pr-3 mt-0 after-liner">
                  <input [attr.automationId]="'Txt_rentalInfo_slabEnd'" min="1" max="999" numMaxlength maxlength="3"  matInput inputtype="nonnegative,nodecimal"
                    formControlName="slabEnd" type="number" (input)="minutesInputChange(i)">
                  <mat-error *ngIf="rentalInfoForm.get('hourlyRate')['controls'][i].controls.slabEnd.hasError('invalid')">
                    {{captions.InvalidValue}}
                  </mat-error>
                  <mat-error *ngIf="rentalInfoForm.get('hourlyRate')['controls'][i].controls.slabEnd.hasError('required')">
                    {{captions.MissingValue}}
                  </mat-error>
                </mat-form-field>
                <mat-form-field [floatLabel]="floatLabel" class="w-33 mt-0">
                  <input [attr.automationId]="'Txt_rentalInfo_amount'"  matInput CurrencyFormatter
                    formControlName="amount" >
                    <mat-error *ngIf="rentalInfoForm.get('hourlyRate')['controls'][i].controls.amount.hasError('required')">
                      {{captions.MissingValue}}
                    </mat-error>
                </mat-form-field>
              </div>
              <span attr.automationId='Icn_rentalInfo_Plus{{i}}' class="icon-Add-inside-circle mr-3 ml-2" [ngClass]="{'ag_section--disable':(!rentalInfoForm.get('hourlyRate').valid)}" (click)="addItem(i)"></span>
              <span attr.automationId='Icn_rentalInfo_Minus{{i}}' class="icon-Minus-inside-circle" [ngClass]="{'ag_section--disable':rentalInfoForm.get('hourlyRate')['controls'].length == 1}" (click)="removeItem(i)"></span>
            </div>
          </div>

          </div>
          <div class="bodyWrap">
            <h4 class="ag_group--headers">{{captions.lbl_otherInfo}}</h4>
            <div class="ag_display--flex">
              <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--25 pr-5">
                <input [attr.automationId]="'Txt_rentalInfo_bufferTime'" min="0" max="999" numMaxlength maxlength="3" matInput [placeholder]="captions.bufferTime"
                  formControlName="bufferTime" type="number" inputtype="nonnegative,nodecimal">
              </mat-form-field>
                <div *ngIf="false"> //#127503 fix
                    <label class="ag_display--block text-label-new">{{captions.lbl_availableOnWeb}}</label>
                    <app-retail-toggle-switch [attr.automationId]="'Tog_multipack_unlimited'" class="ml-1" formControlName="availableOnWeb"
                    (changeToggleEvent)="toggleClick($event)">
                    </app-retail-toggle-switch>
                </div>
            </div>
          </div>
          <div class="bodyWrap table-height" *ngIf="_commonVarService.isRentalAtOutletLevel">
            <h4 class="ag_group--headers">{{captions.lbl_quantityAgainstEachOutlet}}</h4>
            <div class="table-inner">
              <app-cdkvirtual [options]="tableOptions" [headerOptions]="headerOptions" [tableContent]="tableContent"
              [childTemplate]="childTemplate">
              </app-cdkvirtual>
            </div>
              <ng-template #childTemplate let-element="element" let-key="key" let-index="idx">
                <ng-container [ngSwitch]="key">
                    <ng-container *ngSwitchCase="'quantity'">
                      <mat-form-field class="float-label-box custom-amountvalue" [floatLabel]="floatLabel">                  
                          <input [(ngModel)]="element[key]" [ngModelOptions]="{standalone: true}" [attr.automationId]="'Txt_quantity'"  matInput min="1"
                          (input)="quantityInput($event,key,element.index)" [value]="element.quantity" numMaxlength type="number" inputtype="nonnegative,nodecimal" max="999" maxlength="3"/>                
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="'outofOrderQuantity'">
                      <mat-form-field class="float-label-box custom-amountvalue" [floatLabel]="floatLabel">                  
                        <input [(ngModel)]="element[key]" [ngModelOptions]="{standalone: true}" [attr.automationId]="'Txt_outofOrderQuantity'"  matInput  min="0"
                        (input)="quantityInput($event,key,element.index)" [value]="element.outofOrderQuantity" numMaxlength type="number" inputtype="nonnegative,nodecimal" [max]="element.quantity" maxlength="3"/>                
                    </mat-form-field>
                   </ng-container>
                </ng-container>
              </ng-template>
          </div>
        
        </form>
        </div>
    </ng-scrollbar>
</section>
<mat-dialog-actions class="float-right actionButtons pr-4">
    <button [attr.automationId]="'Btn_rentalInfo_cancel'"  mat-button type="button" (click)="close()">{{this.localization.captions.common.CANCEL}}</button>
    <button [attr.automationId]="'Btn_rentalInfo_previous'" mat-button matStepperPrevious type="button"
      class="LW14 text_capitalize  body-bordercolor">{{this.localization.captions.common.PREVIOUS}}</button>
    <button [attr.automationId]="'Btn_rentalInfo_save'"  mat-button type="button" *ngIf="!isEdit" (click)="save()" color="primary"
      [ngClass]="rentalInfoForm.valid ? 'button_valid' : 'button_invalid'"
      class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.SAVE}}</button>
    <button [attr.automationId]="'Btn_rentalInfo_update'" mat-button type="button" *ngIf="isEdit" (click)="save()" color="primary"
      [ngClass]="(rentalInfoForm.valid && (parentForm.get('generalFormGroup').dirty || parentForm.get('otherInfoFormGroup').dirty || parentForm.get('inventoryFormGroup')?.dirty
      || parentForm.get('rentalFormGrp')?.dirty )) ? 'button_valid' : 'button_invalid'"
      class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.Update}}</button>
</mat-dialog-actions> 