import { Component, ViewEncapsulation, AfterViewInit, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { LoaderService } from '@app/core/services/loader.service';
import { RouteLoaderService } from '@app/core/services/route-loader.service';
import { KeyboardService } from '@app/pos/keyboard/keyboard.service';
import { RetailPropertyInformation } from '@app/retail/common/services/retail-property-information.service';
import { menuTypes } from '@app/retail/retail.modals';
import { RetailSharedVariableService } from '@app/retail/shared/retail.shared.variable.service';
import { RetailFeatureFlagInformationService } from '@app/retail/shared/service/retail.feature.flag.information.service';
import { PropertyFeaturesConfigurationService } from '@app/retail/sytem-config/payment-features-config/property-feature-config.service';
import { LoadMultiplePropertiesService } from '@app/shared/services/load-multiple-properties.service';
import { PartyService } from '@app/shared/services/party.service';
import { SignalrService } from '@app/shared/services/signalr.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { OperationType } from '@constants/commonenums';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements AfterViewInit, OnInit, OnDestroy {
  title = 'seat-v2';
  menuList: any;
  idleState = 'Not started.';
  timedOut = false;
  isRetailLoadSettingsTriggered: boolean = false;
  subscriptions: Subscription = new Subscription();
  idleSubscriptions: Subscription = new Subscription();
  defaultIdleSeconds: number = 900;
  idleTimeOutTriggered: boolean = false;
  previousIdleTime: number = 900;
  constructor(public _as: AppService, public signalrService: SignalrService, public router: Router, private cdf: ChangeDetectorRef,
    public ps: PartyService, private routeDataService: RouteLoaderService, private ls: LoaderService, private ts: TranslateService, private loadProperties: LoadMultiplePropertiesService, private rs: RetailSharedVariableService, private pf: PropertyFeaturesConfigurationService, private rp: RetailPropertyInformation, private idle: Idle, private keyboardService: KeyboardService) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (!event.url.includes('/shop')) {
          // this.ls.showMessage.next(true);
          // this.ls.loaderMessage.next(this.ts.instant('loadingMessage'));
          this.ls.disableLoader.next(false);
        } else {
          this.ls.disableLoader.next(true)
        }
      }
      if (event instanceof NavigationEnd) {
        this.ls.showMessage.next(false);
      }
      if (event instanceof NavigationError) {
        this.ls.showMessage.next(false);
      }
      if (event instanceof NavigationCancel) {
        this.ls.showMessage.next(false);
      }
    });

    this.subscriptions.add(this.ps?.cs?.settings?.subscribe(settings => {
      if (settings?.MerchantSettings?.IdleTimeoutInSeconds?.SettingValue && 
        (!this.idleTimeOutTriggered || this.previousIdleTime.toString() !== settings?.MerchantSettings?.IdleTimeoutInSeconds?.SettingValue)) {
          this.initializeIdleTimeout(Number(settings?.MerchantSettings.IdleTimeoutInSeconds.SettingValue));
      } else if (!this.idleTimeOutTriggered) {
        this.initializeIdleTimeout(this.defaultIdleSeconds);
      }
      this.idleTimeOutTriggered = true;
      this.reset();
    }))

    document.addEventListener('visibilitychange', this.handleVisibilityChange);

  }

  handleVisibilityChange(){
    if(document.visibilityState === 'visible'){
      let guid = sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`);
      let hasStorageData = localStorage.getItem(`${guid}_loginResult`);
      if(!hasStorageData){
        location.reload();
      }
    }
  }

  initializeIdleTimeout(idleTimeout: number) {
    this.previousIdleTime = idleTimeout;
    idleTimeout = idleTimeout > 0 ? idleTimeout : this.defaultIdleSeconds;
    this.idleSubscriptions.unsubscribe();
    this.idleSubscriptions = new Subscription();
    this.idle.stop();
    this.idle.setIdleName("IdleProperty_" + Utilities.RestaurantId());
    this.idle.setIdle(30);// how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(idleTimeout);// how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);// sets the default interrupts, in this case, things like clicks, scrolls, touches to the document

    this.idleSubscriptions.add(this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    }));
    let iframeData = sessionStorage.getItem('isIframeEnabled');
    iframeData = JSON.parse(iframeData)
    if (!iframeData) {
      this.idleSubscriptions.add(this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;
        this.ps.popupService.closeDialog$.next();
        console.log(this.idleState);
        // this.keyboardService.enableDisableKeyboard(false);
        this._as.logout(true);
      }));
    }

    this.idleSubscriptions.add(this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
    }));

    this.idleSubscriptions.add(this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    }));
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }


  ngOnInit() {
    //  this.loadProperties.init();  

    if (!this.routeDataService.retailMenus) {
      this.rs.usersession.subscribe(data => {
        if (data && Utilities.isRetailEnabledPropertyWithSession(this._as._cs.settings.value.General.RetailIntegrationDTO) && !this.isRetailLoadSettingsTriggered) {
          this.isRetailLoadSettingsTriggered = true;
          this.routeDataService.loadSettings().then(result => {
            if (result) {
              this.loadMenus();
            }
          });
        }
      });
    }
    else {
      this.loadMenus();
    }
  }
  loadMenus() {
    const value = this.routeDataService.GetChildMenu('/');
    this.menuList = {
      menuType: menuTypes.primary,
      menu: value
    };
    if (!sessionStorage.getItem("giftCardConfiguration")) {
      this.pf.GetGiftCardConfiguration().then((config) => {
        this.rp.SetGiftCardConfiguration(config);
      });
    }
  }

  ngAfterViewInit() {
    this.cdf.detectChanges();
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }

  cartClick(data) {
    this._as.showCartItem = !this._as.showCartItem;
    if (!this._as.showCartItem) {
      this._as.showCartItemOnEdit = false;
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }
}
