import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActionType, AllowedOperationServerStatiticsRefresh, ChangedEntityType, HttpStatusCode, NotificationMessageType, OperationForCartRefresh, OperationForTimelineRefresh, OperationType, PartyType, SignalRState } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { CacheService } from '@core/services/cache.service';
import { WebworkerService } from '@core/services/worker.service';
import { environment } from '@environments/environment';
import { LoginResultDTO } from '@models/LoginResultDTO';
import { RestaurantDTO, SettingsDTO, StateDTO } from '@models/RestaurantDTO';
import { AllowedSettingsPublish, ChangesByScreenDTO, HeartBeatRequestDto, loginState, MessageRequestDto, NotificationMessageDto, ReLoadSettingsRequired, ScreenChangeDTO, updateLatestRevision } from '@models/SignalRDto';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from '@services/http.service';
import { sendHeartBeat } from '@utilities/HeartbeatHandler';
import { processChange } from '@utilities/NotificationHandler';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { interval, Subject, Subscription } from 'rxjs';
import { ChangeDTO } from '../models/ChangeTrackingOperationResultDTO';
import { GetChangesByScreen } from '../utilities/ChangesByScreenHandler';
import { screenChange } from '../utilities/SwitchScreenHandler';
import { PartyService } from './party.service';
import sideMenu from '@data/sidemenu.json';
import { LatestRevision } from '../utilities/LatestRevisionHandler';
import { ActivitiesTimeLineOperation } from '../constants/globalConstants';
import { FloorplanService } from './floorplan.service';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';


var intervalID;
var heartBeatIntervalId;
@Injectable({
  providedIn: 'root'
})
export class SignalrService implements OnDestroy {
  public signalrStateChanged = new Subject<any>();
  _settings: SettingsDTO = {} as SettingsDTO;
  _state: StateDTO = {} as StateDTO;
  private hubConnection: HubConnection;
  signalRState: SignalRState;
  subscriptions: Subscription = new Subscription();
  selectedMenuId: number;
  hubConnectionId$ = new Subject<any>();
  headerSelectedDate: Date = new Date();
  signalRChangeScreenInput: NotificationMessageDto;
  propertyIdsChanged: number[] = [];
  delayIntervalServerStatsRefresh = environment.DelayIntervalServerStatsRefresh;
  refreshServerStats = true;
  nextRefreshServerStatsInputMessage:any;
  constructor(public toastrService: ToastrService, public ts: TranslateService, public router: Router,
    public workerService: WebworkerService, public cs: CacheService, public _hs: HttpService, public _http: HttpClient,
    public ps: PartyService, public fs: FloorplanService) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
    this.subscriptions.add(cs.state.subscribe(stat => {
      this._state = stat;
    }));
  }

  private getSignalRConnection() {
    return this._http.get(environment.signalR_AzureFunction_negotiate_url);
    //return this._http.get(`${environment.signalR_AzureFunction_negotiate_url}${Utilities.RestaurantId()}`);
  }

  init() {
    if (!this.hubConnection) {
      if (environment.UseStandaloneSignalrService) {
        this.hubConnection = new HubConnectionBuilder()
          .withUrl(environment.StandaloneSignalRUrl + '/notificationhub?ngsw-bypass=""')
          .configureLogging(LogLevel.Information)
          .build();
        this.setConnectionProperties();
      }
      else {
        this.subscriptions.add(this.getSignalRConnection().subscribe((con: SignalRConnection) => {
          const options = {
            accessTokenFactory: () => con.accessToken,
          };
          this.hubConnection = new HubConnectionBuilder()
            .withUrl(con.url, options)
            .configureLogging(LogLevel.Information)
            .build();
          this.setConnectionProperties();
        }));
      }
    }
  }

  getLatestRevision() {
    let restaurantId = Utilities.RestaurantId();
    const headers = Utilities.getHeaders(restaurantId).headers;
    let input: updateLatestRevision = {
      restaurantId,
      CommonParams: {
        RestaurantId: restaurantId,
        BaseUrl: urlConfig.getLatestRevisionUrl,
        RestaurantApiKey: headers.get('x-ts-restaurant-api-key'),
        ClientUid: headers.get('x-ts-client-uid'),
        HostId: headers.get('x-ts-host-id'),
        MerchantKey: headers.get('x-ts-merchant-auth-key')
      }
    }
    this.workerService.run(LatestRevision, input).then((result: any) => {
      console.log(result);
      Utilities.setLatestRevision(result.Payload);
    })
  }

  switchScreen(oldScreenId: number, newScreenId: number, loginState: number, restaurantId = Utilities.RestaurantId()) {
    this.selectedMenuId = newScreenId;
    let connectionId = Utilities.getHubConnectionId();
    const headers = Utilities.getHeaders(restaurantId).headers;
    let input: ScreenChangeDTO = {
      oldscreenId: oldScreenId,
      newscreenId: this.selectedMenuId,
      merchantId: this._settings.General.MerchantId,
      restaurantId,
      connectionId: connectionId,
      loginState: loginState,
      CommonParams: {
        RestaurantId: restaurantId,
        BaseUrl: urlConfig.switchScreenUrl,
        RestaurantApiKey: headers.get('x-ts-restaurant-api-key'),
        ClientUid: headers.get('x-ts-client-uid'),
        HostId: headers.get('x-ts-host-id'),
        MerchantKey: headers.get('x-ts-merchant-auth-key'),
        Username: environment.StandaloneSignalrUserName,
        Password: atob(environment.StandaloneSignalrPassword)
      }
    }
    // screenChange(input)
    this.workerService.run(screenChange, input).then((result: any) => {
      console.log(result);
    })
  }

  getChangesByScreen(input: ChangesByScreenDTO) {
    this.workerService.run(GetChangesByScreen, input).then((result: any) => {
      const changes: ChangeDTO[] = result.Payload as ChangeDTO[];
      changes.forEach(change => change.ChangeSet.RestaurantId = input.restaurantId)
      if (this.ps) this.ps.cachedMoveTables = [];
      this.cs.changes.next(changes);
    }).catch(console.error);
  }
  setConnectionProperties() {
    this.hubConnection.keepAliveIntervalInMilliseconds = 10800000;
    this.hubConnection.serverTimeoutInMilliseconds = 10800000;
    this.signalRState = SignalRState.ReadyToConnect;
    this.startConnection();
    this.hubConnection.on('notify', (NotificationModel) => this.onMessageReceived(NotificationModel));
  }

  checkHeartBeatUpdate() {
    heartBeatIntervalId = setInterval(() => {
      this.onHeartBeatSender();
    }, 600000);
  }
  checkSignalRHealth() {
    if (Utilities.GetRetainSignalR()) {
      console.log("checkSignalRHealth() --> Checks SignalR Health periodically and establish connection if needed !");
      intervalID = setInterval(() => {
        if (!this.hubConnection) this.init();
        else {
          this.startConnection();
        }
      }, 30000);
    } else {
      this.disableSignalRHealthCheck();
      this.disableHeartBeatUpdate();
    }
  }

  public startConnection() {

    if (this.hubConnection && this.hubConnection.state == HubConnectionState.Disconnected)
      console.log("SignalR Disconnected");

    if (Utilities.GetRetainSignalR() && (this.signalRState != SignalRState.Disconnected
      && this.signalRState != SignalRState.DisconnectionFailed
      && this.signalRState != SignalRState.DisconnectionRequested
      && this.hubConnection.state == HubConnectionState.Disconnected)) {
      if (this.hubConnection
        && this.hubConnection.state == HubConnectionState.Disconnected) {
        console.log('startConnection() --> SignalR connection restoration starts !');
        this.hubConnection.start().then(r => {
          this.signalRState = SignalRState.Connected;
          console.log("startConnection() -->  SignalR connection is alive !")
          const connectionId : string =   this.hubConnection.connectionId;  
          if(connectionId)
          {
            localStorage.setItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_hubConnectionId`, connectionId);
            this.hubConnectionId$.next(connectionId);
          }
          if (localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_hubConnectionId`) && localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_hubConnectionId`) != connectionId) {
            this.switchScreen(this.selectedMenuId, null, loginState.logout);
          }
          this.disableSignalRHealthCheck();
          this.disableHeartBeatUpdate();
          this.setSignalRConnectionCount();
          this.checkSignalRHealth();
          this.checkHeartBeatUpdate();
        }).catch(error => {
          if (error && error.statusCode == HttpStatusCode.Unauthorized) {
            this.hubConnection = null;
          }
          this.disableSignalRHealthCheck();
          this.disableHeartBeatUpdate();
          this.signalRState = SignalRState.ConnectionFailed;
          this.checkSignalRHealth();
          this.toastrService.error("Unable to contact server. Please contact rguestseat@agilysys.com.", "",
            {
              timeOut: 3600000,
              //toastClass : "signalrFailureTost"
            });
        });
        this.signalRState = SignalRState.ConnectionRequested;
      } else {
        this.init();
      }
    }
  }

  disableSignalRHealthCheck() {
    if (intervalID)
      clearInterval(intervalID);
  }

  disableHeartBeatUpdate() {
    if (heartBeatIntervalId)
      clearInterval(heartBeatIntervalId);
  }

  setSignalRConnectionCount() {
    if (!localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_SignalRConnectionCount`)) localStorage.setItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_SignalRConnectionCount`, "0");
    let _count = Number(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_SignalRConnectionCount`));
    _count++;
    localStorage.setItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_SignalRConnectionCount`, _count.toString());
  }

  public stopClient() {
    if (this.hubConnection && this.hubConnection.state == HubConnectionState.Connected) {
      this.signalRState = SignalRState.DisconnectionRequested;
      this.RemoveHubGroups();
      this.hubConnection.stop().then(res => {
        this.signalRState = SignalRState.Disconnected;
      }).catch((error) => {
        this.signalRState = SignalRState.DisconnectionFailed;
        console.log('stopClient() --> SignalR Error : stop receiving signalR messages :- ' + error)
      });
      console.log('stopClient() --> SignalR connection stopped!');
    }
    this.hubConnection = null;
    Utilities.SetRetainSignalR("false");
    if (this.ps)
      this.ps.Parties$.next([]);
    this.disableSignalRHealthCheck();
    this.disableHeartBeatUpdate();
  }

  private RemoveHubGroups() {
    let loginResult: LoginResultDTO = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    loginResult.RestaurantsAvailableForLogin.forEach(property => {
      this.switchScreen(this.selectedMenuId, null, loginState.logout, property.Id);
    })
    localStorage.removeItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_hubConnectionId`);
  }

  private onMessageReceived(message: any) {
    const data: NotificationMessageDto = JSON.parse(message);
    if (data.BroadcastType === 'M' && data.RestaurantId == null) {
      data.RestaurantId = Utilities.RestaurantId();
      data.SupportedProperties = Object.keys(this.cs.propertySettings.value).filter(propertyId => data.RestaurantId != Number(propertyId)).map(p => Number(p));
    }
    const restaurantId = data.RestaurantId;
    this.signalRChangeScreenInput = data;
    this.propertyIdsChanged.push(restaurantId);
    if (data.BroadcastType === 'MRS') {
      if (Utilities.getHubConnectionId() === data.ConnectionId) return;
      this.cs.signalRMessageCount = this.cs.signalRMessageCount + 1;
      return;
    }
    if (data.BroadcastType === 'MR' || data.BroadcastType === 'M') {
      // Here loadRestaurant with Restaurant level settings
      // return this._hs.get(urlConfig.loadRestaurantUrl + restaurantId + '&options=Settings').subscribe((data) => {
      //     this.cs.settings.next(data.Payload.Settings)
      //   });
      const headers = Utilities.getHeaders(restaurantId).headers;
      const baseUrl = data.ChangedEntityType === ChangedEntityType.ChangeSet ? urlConfig.getChanges : urlConfig.loadRestaurantUrl;
      
      let input: MessageRequestDto = {
        Message: _.cloneDeep(data),
        LastRevision: Utilities.getLatestRevision(restaurantId),
        CacheService: null,
        worker: true,
        CommonParams: {
          RestaurantId: restaurantId,
          BaseUrl: baseUrl,
          RestaurantApiKey: headers.get('x-ts-restaurant-api-key'),
          ClientUid: headers.get('x-ts-client-uid'),
          HostId: headers.get('x-ts-host-id'),
          MerchantKey: headers.get('x-ts-merchant-auth-key'),
          LanguageId: Utilities.getLanguageId()
        },
        RestaurantSettings: this._settings,
        Screen: sideMenu.menus[this.selectedMenuId]?.screen
      };

      if (this._settings && this._state) {
       

        if ((data.ChangedEntityType == ChangedEntityType.RefreshCart || Object.values(OperationForCartRefresh).includes(data.OperationName)) && data.ConnectionId != Utilities.getHubConnectionId()) {
          let inputData = _.cloneDeep(input);
          inputData.CommonParams.BaseUrl = urlConfig.getCartList;
          inputData.Message.ChangedEntityType = ChangedEntityType.RefreshCart;
          inputData.CommonParams.RestaurantId = restaurantId;
          inputData.CommonParams.MerchantId = this.cs.settings.value.General.MerchantId;
          this.delegateTaskToWebworker(inputData);
        }

        if ( data.ChangedEntityType == ChangedEntityType.ServerStatistics || Object.values(AllowedOperationServerStatiticsRefresh).includes(data.OperationName)) {
          let inputData = _.cloneDeep(input);
          inputData.CommonParams.BaseUrl = urlConfig.getShiftStats;
          inputData.Message.ChangedEntityType = ChangedEntityType.ServerStatistics;
          inputData.CommonParams.RestaurantId = restaurantId;
          inputData.CommonParams.MerchantId = this.cs.settings.value.General.MerchantId;
          this.RefreshServerStats(inputData);
        }


        if (data.ChangedEntityType == ChangedEntityType.SettingsAndLayout) {
          input.CommonParams.BaseUrl = urlConfig.loadRestaurantUrl;
          input.Message.ChangedEntityType = ChangedEntityType.SettingsAndLayout;
          this.delegateTaskToWebworker(input);
        }
        else if (data.ChangedEntityType == ChangedEntityType.Layout) {
          input.CommonParams.BaseUrl = urlConfig.loadRestaurantUrl;
          input.Message.ChangedEntityType = ChangedEntityType.Layout;
          this.delegateTaskToWebworker(input);
        }
        else if (data.ChangedEntityType == ChangedEntityType.Settings) {
          input.CommonParams.BaseUrl = urlConfig.loadRestaurantUrl;
          input.Message.ChangedEntityType = ChangedEntityType.Settings;
          this.delegateTaskToWebworker(input);
        }
        else if (data.ChangedEntityType == ChangedEntityType.FloorPlanImage) {
          input.CommonParams.BaseUrl = urlConfig.getLayoutImagesURL;
          input.Message.ChangedEntityType = ChangedEntityType.FloorPlanImage;
          this.delegateImageTaskToWebWorker(input);
        }
        else if (data.ChangedEntityType == ChangedEntityType.ChangeSet) {
          if (Utilities.getHubConnectionId() === data.ConnectionId) return;
          this.cs.signalRMessageCount = this.cs.signalRMessageCount + 1;
          this.signalRChangeScreenInput = data;
        }else if(data.ChangedEntityType == ChangedEntityType.RefreshPackage ){
          if(this.cs){
            this.cs.refreshPackages.next(true);
          }
        }else if(data.ChangedEntityType == ChangedEntityType.RefreshWristBand) {
          if(this.cs) {
            this.cs.refreshWristBand$.next(true);
          }
        }

      }

      if (data.ChangedEntityType == ChangedEntityType.Users) {
        if(ReLoadSettingsRequired[input.Message.OperationName] && this._settings && this._state){
          let inputData = _.cloneDeep(input);
          inputData.Message.SupportedProperties = Object.keys(this.cs.propertySettings.value).filter(propertyId => data.RestaurantId != Number(propertyId)).map(p => Number(p));
          inputData.CommonParams.RestaurantId = restaurantId;
          inputData.CommonParams.MerchantId = this.cs.settings.value.General.MerchantId;
          inputData.CommonParams.BaseUrl = urlConfig.loadRestaurantUrl;
          inputData.Message.ChangedEntityType = ChangedEntityType.Settings;
          this.delegateTaskToWebworker(inputData);
        }       
        this.cs.roles_OperationType.next(data.OperationName)
      }
    }


    // if (data.ChangedEntityType == ChangedEntityType.SwitchFloorPlan) {
    //   this.cs.isCustomFloorplan = true;
    // }
    // console.log("Received signalR message below :");
    // console.log(message);
    // const data: NotificationMessageDto = JSON.parse(message);
    // if (data.RestaurantId && restaurantId != data.RestaurantId) return;
    // this.selectedMenuId
    // const headers = Utilities.getHeaders(restaurantId).headers;
    // const baseUrl = data.ChangedEntityType === ChangedEntityType.ChangeSet ? urlConfig.getChanges : urlConfig.loadRestaurantUrl;
    // if (this._settings && this._state) {
    //   let input: MessageRequestDto = {
    //     Message: data,
    //     LastRevision: Utilities.getLatestRevision(),
    //     CacheService: null,
    //     worker: true,
    //     CommonParams: {
    //       RestaurantId: Utilities.RestaurantId(),
    //       BaseUrl: baseUrl,
    //       RestaurantApiKey: headers.get('x-ts-restaurant-api-key'),
    //       ClientUid: headers.get('x-ts-client-uid'),
    //       HostId: headers.get('x-ts-host-id'),
    //       MerchantKey: headers.get('x-ts-merchant-auth-key')
    //     },
    //     RestaurantSettings: this._settings,
    //     Screen: sideMenu.menus[this.selectedMenuId].screen
    //   };
    //   if (data.ChangedEntityType == ChangedEntityType.SwitchFloorPlan) {
    //     this.cs.isCustomFloorplan = true;
    //   }

    //   if (data.ChangedEntityType == ChangedEntityType.SettingsAndChange) {
    //     //Load Settings
    //     input.CommonParams.BaseUrl = urlConfig.loadRestaurantUrl;
    //     input.Message.ChangedEntityType = ChangedEntityType.Settings;
    //     this.delegateTaskToWebworker(input);

    //     //Get Changes
    //     input.CommonParams.BaseUrl = urlConfig.getChanges;
    //     input.Message.ChangedEntityType = ChangedEntityType.ChangeSet;
    //     input.Screen = sideMenu.menus.filter(x => x.id == this.selectedMenuId)[0].screen;
    //     this.delegateTaskToWebworker(input);
    //   }
    //   else if (data.ChangedEntityType == ChangedEntityType.FloorPlanImage) {
    //     input.CommonParams.BaseUrl = urlConfig.getLayoutImagesURL;
    //     input.Message.ChangedEntityType = ChangedEntityType.FloorPlanImage;
    //     this.delegateImageTaskToWebWorker(input);
    //     if(data.OperationName == 'DeleteLayoutImages' || data.OperationName == 'DeleteObjectImages' || data.OperationName == 'DeleteCustomImages')
    //     {
    //       //Load settings
    //       input.CommonParams.BaseUrl = urlConfig.loadRestaurantUrl;
    //       input.Message.ChangedEntityType = ChangedEntityType.Settings;
    //       this.delegateTaskToWebworker(input);
    //     }
    //   }
    //   else {
    //     input.Screen = sideMenu.menus.filter(x => x.id == this.selectedMenuId)[0].screen;
    //     this.delegateTaskToWebworker(input);
    //   }

    //   //processChange(input);
    // }
  }

  RefreshServerStats(input){
    if(this.refreshServerStats){
      this.refreshServerStats = false;
      this.delegateTaskToWebworker(input);
      let timeSubsription = interval(1000 * this.delayIntervalServerStatsRefresh).subscribe(() => {
        console.log("Refresh server -"+ new Date());
        if(this.nextRefreshServerStatsInputMessage){
          this.delegateTaskToWebworker(this.nextRefreshServerStatsInputMessage);
          this.nextRefreshServerStatsInputMessage = null;
        }
        else{
          this.refreshServerStats = true;
          timeSubsription.unsubscribe();
        }
      });
    }
    else{
      this.nextRefreshServerStatsInputMessage = input;
    }

  }

  delegateImageTaskToWebWorker(input: any) {
    this.workerService.run(processChange, input).then(
      (result: any) => {
        console.log('Worker Service associated with the layout job done!')
        console.log(result);
        this.setFloorPlanImages(result, input.Message);
        if (input.CommonParams.RestaurantId == Utilities.RestaurantId())
          this.setSettingsOperationNameInCache(input.Message.OperationName);
      }).catch(console.error);
  }

  setFloorPlanImages(response: any, data: any) {
    if (response) {
      if (response.ChangeSet && response.ChangeSet.State == 0 && response.ChangeSet.Payload) {
        if (data.RestaurantId == Utilities.RestaurantId())
          this.cs.restaurantImages.next(response.ChangeSet.Payload);
        this.cs.propertySettings.value[data.RestaurantId].restaurantImages = response.ChangeSet.Payload;
      }
    }
  }

  private onHeartBeatSender() {
    const restaurantId = Utilities.RestaurantId();
    let loginResult: LoginResultDTO = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    const headers = Utilities.getHeaders(restaurantId).headers;
    const baseUrl = urlConfig.heartBeatUrl;
    if (this._settings && this._state && loginResult) {
      let input: HeartBeatRequestDto = {
        DeviceId: loginResult.DeviceId,
        CommonParams: {
          RestaurantId: loginResult.LoggedInRestaurantId,
          BaseUrl: baseUrl,
          RestaurantApiKey: headers.get('x-ts-restaurant-api-key'),
          ClientUid: headers.get('x-ts-client-uid'),
          HostId: headers.get('x-ts-host-id'),
          MerchantKey: headers.get('x-ts-merchant-auth-key'),
        }
      };
      this.delegateHeartBeatToWebWorker(input);
    }
  }
  delegateHeartBeatToWebWorker(input: any) {
    this.workerService.run(sendHeartBeat, input).then(
      (result) => {
        console.log("Heart beat updated");
        console.log(result);
      }
    ).catch(console.error);
  }

  delegateTaskToWebworker(input: any) {
    if (input.Message.ChangedEntityType == ChangedEntityType.Settings && AllowedSettingsPublish[input.Message.OperationName]) {
      this.toastrService.info(this.ts.instant('settingsUpdateMsg'), "", { timeOut: 10000, closeButton: true });
    }

    this.processTask(input);
    if(input.Message.SupportedProperties && input.Message.SupportedProperties.length){
      input.Message.SupportedProperties.forEach(propertyId => {
        let newInput = Object.assign({},input);//_.clone(input);
        const headers = Utilities.getHeaders(propertyId).headers;
        newInput.Message.RestaurantId = propertyId;
        newInput.CommonParams.RestaurantId = propertyId;
        newInput.CommonParams.RestaurantApiKey = headers.get('x-ts-restaurant-api-key'),
        newInput.CommonParams.ClientUid= headers.get('x-ts-client-uid'),
        newInput.CommonParams.HostId= headers.get('x-ts-host-id'),
        newInput.CommonParams.MerchantKey= headers.get('x-ts-merchant-auth-key'),
        this.processTask(newInput);
      });
    }
   
  }

  processTask(input: any){
    this.workerService.run(processChange, input).then(
      (result: any) => {
        console.log('Worker Service done with the job associated !');
        if (ChangedEntityType.ActivityTimeLine == input.Message.ChangedEntityType) {
          this.setActivitiesBookingData(result)
        }
        else if (input.Message.ChangedEntityType == ChangedEntityType.RefreshCart) {
          this.setCartItems(result);
        }
        else if(input.Message.ChangedEntityType == ChangedEntityType.ServerStatistics){
          this.setServerStatistics(result);
        }
        else {
          this.setsessionStorage(result, result.PropertyId);
          if (input.CommonParams.RestaurantId == Utilities.RestaurantId())
          this.setSettingsOperationNameInCache(input.Message.OperationName);
        }

      }).catch(console.error);
  }

  setCartItems(result){
   this.cs.refreshCart$.next(result.ChangeSet.Payload ? [...result.ChangeSet.Payload] : []);
  }

  setServerStatistics(result){
    this.cs.refreshServerStatistics$.next(result.ChangeSet.Payload) ;
   }

  setActivitiesBookingData(result) {
    this.cs.activitiesData.next(result.ChangeSet.Payload);
  }
  setSettingsOperationNameInCache(operationName: string) {
    this.cs.settingsOperationName.next(operationName);
  }

  setsessionStorage(response: any, propertyId) {
    if (response) {
      if (response.ChangeSet && response.ChangeSet.State == 0) {
        if (this.ps) this.ps.cachedMoveTables = [];
        this.cs.changes.next(response.ChangeSet.Payload);
      }
      // Checked with the latest revision and then updated the settings. Fix for Bug Id 20432.
      const latestRevision = Utilities.getLatestRevision(propertyId);
      if (response.UpdatedSettings && response.UpdatedSettings.State === 0 && response.UpdatedSettings.Payload && response.UpdatedSettings.Payload.State &&
        response.UpdatedSettings.Payload.State.Revision >= latestRevision) {
        if (response.UpdatedSettings.Payload && response.UpdatedSettings.Payload.Settings)
          this.cs.loadSettingsInStorage(response.UpdatedSettings.Payload.Settings, propertyId);        
        if (response.UpdatedSettings.Payload && response.UpdatedSettings.Payload.Layout) {
          this.updateLayout(response, propertyId);
        }
        if (response.UpdatedSettings.Payload && response.UpdatedSettings.Payload.State)
          this.updateState(response, propertyId);
      }
      else {
        if (response.UpdatedSettings && response.UpdatedSettings.State && response.UpdatedSettings.State.State === 0 && response.UpdatedSettings.State.Payload
          && response.UpdatedSettings.State.Payload.State && response.UpdatedSettings.State.Payload.State.Revision >= latestRevision) {
          this.updateState(response, propertyId);
        }
        if (response.UpdatedSettings && response.UpdatedSettings.Payload && response.UpdatedSettings.Payload.Settings) {
          this.cs.loadSettingsInStorage(response.UpdatedSettings.Payload.Settings, propertyId);
        }
        if (response.UpdatedSettings?.Payload) {
          const { Layout } = response.UpdatedSettings.Payload;
          
          if (propertyId === Utilities.RestaurantId()) {
            if (Layout) {
              this.cs.layout.next(Layout);
            }
            this.cs.state.next(this.cs.state.value);
          }
        
          if (Layout) {
            this.cs.propertySettings.value[propertyId].layout = Layout;
          }
        }
        
      }
    }
  }

  updateState(response, propertyId) {
    if (propertyId == Utilities.RestaurantId())
      this.cs.state.next(response.UpdatedSettings.State.Payload.State);
    this.cs.propertySettings.value[propertyId].state = response.UpdatedSettings.State.Payload.State;
  }

  updateLayout(response, propertyId) {
    if (propertyId == Utilities.RestaurantId())
      this.cs.layout.next(response.UpdatedSettings.Payload.Layout);
    this.cs.propertySettings.value[propertyId].layout = response.UpdatedSettings.Payload.Layout;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}

export class NotificationModelDTO {
  public OperationType: OperationType;
  public MessageType: NotificationMessageType;
  public RestaurantId: number;
  public UpdatedDataLists: UpdatedDataList[];
  public CacheDTOName: string;
}

export class UpdatedDataList {
  public Action: ActionType;
  public JOSNUpdatedData: any;
  public UpdatedData: any;

}

export class SignalRConnection {
  url: string;
  accessToken: string;
}
