import { Component, OnInit, Input, ViewEncapsulation, Pipe, PipeTransform, ViewChild} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { uniquephoneValidator } from './ag-duplicate-contact-directive';
import { PhoneTypes, ContactType, DefaultPhoneTypes  } from '../../enums/shared-enums';
import { AgContactConfig, AgPhoneDetail } from '../../Models/ag-models';
import { MatDialog } from '@angular/material/dialog';
import { AgMenuEditComponent } from '../ag-menu-edit/ag-menu-edit.component';
import { Localization } from '../../localization/localization';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import countrylist from '../ag-phone-number/country-code.json';
import {cloneDeep, isEmpty } from 'lodash';
import { countryCodeValue } from '../ag-phone-number/ag-phone-number-model';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Product } from '../../Models/common.models';
import { PropertyInformationDataService } from '../../dataservices/authentication/property-information.data.service';
@Component({
  selector: 'app-ag-phone-number',
  templateUrl: './ag-phone-number.component.html',
  styleUrls: ['./ag-phone-number.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgPhoneNumberComponent implements OnInit {
  customClass: string;
  customClass1: string;
  othersCustomClass: string;
  errorMessage: string;
  contactForm: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  typeControlName: string;
  typeClass: string;
  options: any[];
  arrayName: string;
  textMaskPhone: string;
  phoneTypes = PhoneTypes;
  captions: any;
  textMaskExtension: string;
  showSwitches: boolean;
  primarySwitchName: string;
  privateSwitchName: string;
  extensionClass: string;
  extensionPlaceHolder: string;
  duplicateError: string;
  extensionLength: number;
  primaryLabel: string;
  privateLabel: string;
  phoneLength: number;
  isPhoneDisabled: boolean;
  countryCodePlaceHolder: string;
  countryCodePlaceHolderId: string;
  phoneNumberPlaceHolder: string;
  phoneNumberPlaceHolderId: string;
  defaultCountrydialCode: string;
  countryCodeLength: number;
  allowOnlyPrimaryOrPrivate: boolean;
  errorMessageId : string;
  placeHolderId : string;
  isPhoneNumberRequired : boolean;
  searchText = '';
  countrylist: countryCodeValue[];
  unfilteredcountrylist:  countryCodeValue[];
  textMaskPhoneArray: any = [];
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  currentProductId: number;
  defaultphoneTypeObj = '';
  floatLabel: string;
  minPhoneLength : number = 8;
  maxPhoneLength : number = 12;
  automationId: string;
  code: any;
  othersEnabled: boolean;
  showMoreTypes: boolean = false;
  requestFromGuest: boolean = false;
  isOnlyOneContact: boolean;
  phoneNoMaxLengthforOthers: number = 20;
  showOthers: boolean;

  @Input('inputs')
  set inputOptions(value: AgContactConfig) {
      if(value) {
        this.floatLabel = this.localization.setFloatLabel? this.localization.setFloatLabel: '';
        this.errorMessage = value.errorMessage ? value.errorMessage : this.captions.err_invalidPhone;
        this.errorMessageId =  value.errorMessageId ? value.errorMessageId :'err_invalidPhone';
        this.placeHolder = value.placeHolder ? value.placeHolder : this.captions.lbl_phone;
        this.placeHolderId =  value.placeHolderId ? value.placeHolderId :'lbl_phone';
        this.extensionPlaceHolder = value.extnPlaceHolder ? value.extnPlaceHolder : this.captions.lbl_extension;
        this.countryCodePlaceHolder = this.localization.captions.common.countryCode;
        this.code = (this.localization.captions as any).lbl_code;
        this.countrylist = countrylist;
        this.countryCodePlaceHolderId = 'common$countryCode'
        this.controlName = value.formControlName ? value.formControlName : 'phoneNumber';
        this.typeControlName = value.typeControlName ? value.typeControlName : 'phoneType';
        this.allowOnlyPrimaryOrPrivate = value.allowOnlyPrimaryOrPrivate ? value.allowOnlyPrimaryOrPrivate : false;
        this.customClass = 'w-50';
        this.othersCustomClass = 'w-75';
        this.customClass1 = 'w-23 mr-1';
        this.typeClass = value.typeClass ? value.typeClass : 'ag_form-control--xs';
        this.extensionClass = value.extensionClass ? value.extensionClass : 'ag_w--75';
        this.primaryLabel = value.primarySwitchLabel ? value.primarySwitchLabel : this.captions.lbl_PrimaryPhone;
        this.privateLabel = value.privateSwitchLabel ? value.privateSwitchLabel : this.captions.lbl_PrivateInformation;
        this.isPhoneDisabled = value.disabled ? value.disabled : false;
        this.isPhoneNumberRequired = value.isPhoneNumberRequired ? value.isPhoneNumberRequired: false;
        this.phoneNumberPlaceHolder = this.localization.captions.common.phoneNo;
        this.phoneNumberPlaceHolderId = 'common$phoneNo';
        this.automationId = value.automationId ? value.automationId : '';
        this.showSwitches = value.showSwitches;
        if (this.showSwitches) {
          this.primarySwitchName = value.isPrimaryName ? value.isPrimaryName : 'primaryPhone';
          this.privateSwitchName = value.isPrivateName ? value.isPrivateName : 'privateInformtionPhone';
        }

        this.contactForm = value.form;
        this.arrayName = value.formArrayName;
        this.showMoreTypes = value.showMoreTypes;
        this.showOthers = value.showOthers == undefined || value.showOthers ? true : false;
        this.requestFromGuest = value.requestFromGuest;

        if(this.isPhoneNumberRequired)
        {
          this.isPhoneNumberRequiredCall();
        }
        else
        {
          this.isPhoneNumberRequiredCall(false);
        }

        if (!this.arrayName) {
          if (!this.contactForm.get('countryCode')) {
            this.contactForm.addControl('countryCode', new UntypedFormControl());
          }
          this.contactForm.get('countryCode').disable();
          this.contactForm.get(this.controlName).disable();
        } else {
          const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
          phone.controls.forEach((obj: UntypedFormGroup) => {
            if (!obj.get('countryCode')) {
              obj.addControl('countryCode', new UntypedFormControl());
            }
            if (!obj.value[this.typeControlName]) {
              obj.get('countryCode').disable();
              obj.get(this.controlName).disable();
            }
          });
        }
        this.currentProductId = Number(this.utilities.GetPropertyInfo('ProductId'));
        if(this.currentProductId === Product.PMS){
          const uiDefaultScreenValue = JSON.parse(sessionStorage.getItem('UiDefaultsSettingsValue'));
          this.defaultCountrydialCode = uiDefaultScreenValue?.countryCode ? uiDefaultScreenValue.countryCode : '';
          let defaultPhoneType = uiDefaultScreenValue?.phoneType? uiDefaultScreenValue.phoneType: '';
          this.setDefaultCountryCode(this.defaultCountrydialCode, this.getDefaultPhoneType(defaultPhoneType));

        }else if(this.currentProductId == Product.GOLF){
          let defaultsettings = JSON.parse(sessionStorage.getItem('defaultSettings'));
          this.defaultCountrydialCode = defaultsettings?.defaultCountryCode;
          let defaultPhoneType = defaultsettings?.defaultPhoneTypeOption
         this.setDefaultCountryCode(this.defaultCountrydialCode,defaultPhoneType)
        } else if(this.currentProductId == Product.SPA){
          let defaultsettings : any[];
          defaultsettings = JSON.parse(sessionStorage.getItem('defaultSettings'));
          let defaultCountryCodeSetting = defaultsettings.find(x=> x.switch === 'DEFAULT_COUNTRY_CODE');
          this.defaultCountrydialCode = parseInt(defaultCountryCodeSetting?.value) ? defaultCountryCodeSetting.value: '';
          let defaultPhoneTypeSetting = defaultsettings.find(x => x.switch === 'DEFAULT_PHONE_TYPE');
          let defaultPhoneType  = defaultPhoneTypeSetting? Number(defaultPhoneTypeSetting.value): ''
          this.setDefaultCountryCode(this.defaultCountrydialCode,defaultPhoneType)
        }
         else {
          var propertyId = Number(this.localization.GetsessionStorageValue('propertyInfo', 'PropertyId'));
          this.propertyInfoService.getPropertyInformation(propertyId).then(x=>{
            this.defaultCountrydialCode = x['defaultCountryCode'];
            this.contactForm.get('phone')['controls']?.forEach((element,index) =>{
              if(element.get('countryCode').value === ""){
                 element.get('countryCode').setValue(this.defaultCountrydialCode);
              }
            })
          });
        }
      }

  }

  @Input('valuePatched')
  set ControlState(value: boolean) {
    if (!this.contactForm.get('countryCode')) {
      this.contactForm.addControl('countryCode', new UntypedFormControl());
    }
    const countrycodeControl = this.contactForm.get('countryCode');
    const contactControl = this.contactForm.get(this.controlName);
    if (!this.arrayName && !this.isViewOnly && this.contactForm.get(this.typeControlName).value) {
      contactControl.enable();
      countrycodeControl.enable();
      this.contactForm.controls['countryCode'].setValidators([Validators.required]);
      this.contactForm.controls[this.controlName].setValidators([Validators.required]);
    } else {
      contactControl.disable();
      countrycodeControl.disable();
    }
  }

  @Input('values')
  set SetFormArray(value: AgPhoneDetail[]) {
    if (value && this.arrayName) {
      this.patchOrAdd(value);
      this.phoneFieldDisabler(this.isPhoneDisabled);
    }
    if (this.requestFromGuest) {
      this.ChangePrimaryToggle();
    }
  }

  @Input() isViewOnly = false;

  constructor(private fb: UntypedFormBuilder, private localization: Localization,private utilities: CommonUtilities, public dialog: MatDialog,public propertyInfoService:PropertyInformationDataService) {
    this.captions = this.localization.captions.common;
    this.duplicateError = this.captions.duplicatePhone;
  }

  ngOnInit() {
    this.options = this.getPhoneOptions();
    this.textMaskPhone = this.maskPhoneNo();
    this.textMaskExtension = this.maskExtension();
    this.setMaxPhoneNoValidationforOthers();
    this.extensionLength = 5;
    this.phoneLength = 12;
    this.countryCodeLength = 5;
    this.countrylist = countrylist;
    this.unfilteredcountrylist = cloneDeep(this.countrylist)
  }

  private setMaxPhoneNoValidationforOthers() {
    let maxLength = JSON.parse(sessionStorage.getItem("propConfig"))?.PhoneNumberMaxLength;

    if (maxLength > 0) {
      this.phoneNoMaxLengthforOthers = maxLength;
    }
  }

  filterdata(eve){
    this.searchText = eve?eve.target.value.trim().toLowerCase():'';
    this.countrylist = this.searchText ? this.unfilteredcountrylist?.filter(x=>x.dialCode.toLowerCase().includes(this.searchText) || x.name.toLowerCase().includes(this.searchText)) : this.unfilteredcountrylist;
  }

  getDefaultPhoneType(defaultPhoneType){
    switch(defaultPhoneType){
      case DefaultPhoneTypes.home:
        this.defaultphoneTypeObj = this.options.find(x=> x.id === this.phoneTypes.home)?  this.options.find(x=> x.id === this.phoneTypes.home).id: '';
        break;
      case DefaultPhoneTypes.office:
        this.defaultphoneTypeObj = this.options.find(x=> x.id === this.phoneTypes.office)? this.options.find(x=> x.id === this.phoneTypes.office).id: '';
        break;
      case DefaultPhoneTypes.mobile:
        this.defaultphoneTypeObj = this.options.find(x => x.id === this.phoneTypes.mobile) ? this.options.find(x => x.id === this.phoneTypes.mobile).id : '';
        break;
      case DefaultPhoneTypes.business:
        this.defaultphoneTypeObj = this.options.find(x => x.id === this.phoneTypes.business) ? this.options.find(x => x.id === this.phoneTypes.business).id : '';
        break;
      case DefaultPhoneTypes.work:
        this.defaultphoneTypeObj = this.options.find(x=> x.id === this.phoneTypes.work)? this.options.find(x=> x.id === this.phoneTypes.work).id:'';
        break;
      case DefaultPhoneTypes.others:
        this.defaultphoneTypeObj = this.options.find(x=> x.id === this.phoneTypes.others)? this.options.find(x=> x.id === this.phoneTypes.others).id:'';
    }
    return this.defaultphoneTypeObj;
  }
  setDefaultCountryCode(defaultCountryCode, defaultPhoneType){
    if(this.contactForm.get('phone')) {
      this.contactForm.get('phone')['controls']?.forEach((element,index) =>{
        if(element.get('countryCode').value === ""){
           element.get('countryCode').setValue(defaultCountryCode);
        }
        if(element.get(this.typeControlName).value === ""){
          element.get(this.typeControlName).setValue(defaultPhoneType);
          element.get('countryCode').enable()
          element.get('phoneNumber').enable()
        }
        if(element.get('countryCode').value && element.get(this.typeControlName).value && !element.get(this.typeControlName).disabled ){
          element.get('countryCode').enable();
          element.get(this.controlName).enable();
        }
      })
    }

  }


  ngOnChanges(){
    if(this.arrayName){
      this.phoneFieldDisabler(this.contactForm.get(this.arrayName).disabled);
    }
  }

  isPhoneNumberRequiredCall(isRequired: boolean = true)
  {
    if(isRequired){
      this.contactForm.get(this.arrayName)['controls'].forEach((element,index) =>{
        element.get('phoneType').setValidators([Validators.required]);
        element.get('phoneNumber').setValidators([Validators.required]);
        element.updateValueAndValidity();
      })
    }
    else{
      this.contactForm.get(this.arrayName)['controls'].forEach((element,index) =>{
        element.get('phoneType').setValidators(null);
        element.get('phoneNumber').setValidators(null);
        element.updateValueAndValidity();
      })
    }
  }

  phoneFieldDisabler(isDisable){
    let phoneField = this.contactForm.get(this.arrayName);
    if(isDisable){
      this.isPhoneDisabled = true;
      phoneField['controls'].forEach(element => {
        element.disable();
      });
    }
  }

  checkValidity(e, i) {
    const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
    const contactControl = phone.controls[i].get(this.controlName);
    const isContactTypeOther : boolean = this.contactForm.controls.phone['controls'][i].controls.phoneType.value == this.phoneTypes.others;
    this.checkPhoneControlValidity(contactControl, i,isContactTypeOther);
  }

  checkValiditySinglePhone(e) {
    const contactControl = this.contactForm.get(this.controlName);
    const isContactTypeOther : boolean = this.contactForm.get('phoneType').value == this.phoneTypes.others;
    this.checkPhoneControlValidity(contactControl,isContactTypeOther);
  }

  checkPhoneControlValidity(contactControl , index? ,isContactTypeOther : boolean = false) {
    const val = contactControl.value;
    const res = val.replace(/\D/g, '');
    let placeholderLength;
    let cCode;
    if (this.arrayName){
      cCode = this.contactForm.controls.phone['controls'][index].controls.countryCode.value;
    }else{
      cCode = this.contactForm.get('countryCode').value;
    }
    if (cCode){
      const cObj = this.countrylist.find(x => x.dialCode == cCode);
      if (!(isEmpty(cObj))){
        // placeholderLength = cObj.placeHolder.replace(/\D/g, '').length;
        placeholderLength = cObj.placeHolder.length
        this.maxPhoneLength = placeholderLength
      }
    } else {
      this.maxPhoneLength = isContactTypeOther ? this.phoneNoMaxLengthforOthers : 12;
    }
    if (contactControl.dirty && (res.length < this.minPhoneLength || res.length > this.maxPhoneLength) && (res.length !== 0)) {
      contactControl.setErrors({ incorrect: true });
      this.arrayName && this.contactForm.get(this.arrayName)['controls'] &&  this.contactForm.get(this.arrayName)['controls'].length > 0 ? this.contactForm.get(this.arrayName)['controls'].forEach((element,index) =>{
        element.updateValueAndValidity();
      }) : null
    }else{
      contactControl.setErrors({ incorrect: null });
      contactControl.updateValueAndValidity();
   }
  }

  // Dynamic Phone field addition Logic
  addPhoneArray(index, phoneLabel, countryCode, phoneNumber, extn, isPrimary?, isPrivate?, isSynced?): UntypedFormGroup {
    const currentForm = this.fb.group({
      contactType: ContactType.phone,
      extension: extn,
      id: index,
      isSynced: isSynced
    });
    if(this.isPhoneNumberRequired)
    {
    this.isPhoneNumberRequiredCall();
    }
    else
    {
      this.isPhoneNumberRequiredCall(false);
    }
    currentForm.addControl(this.typeControlName, new UntypedFormControl(phoneLabel));
    currentForm.addControl(this.controlName, new UntypedFormControl({ value: phoneNumber, disabled: !phoneLabel }));
    currentForm.addControl('countryCode', new UntypedFormControl({ value: countryCode, disabled: !phoneLabel}));
    if (this.showSwitches) {
      currentForm.addControl(this.primarySwitchName, new UntypedFormControl(isPrimary));
      currentForm.addControl(this.privateSwitchName, new UntypedFormControl(isPrivate));
    }
    if(phoneLabel == this.phoneTypes.others) {
      currentForm.controls.countryCode.disable();
    }
    return currentForm;
  }

  addPhoneItem(index, phoneLabel: string, countryCode: string | number, phoneNumber: string | number, extn: string | number, primaryPhone: string | boolean, privateInformtionPhone: string | boolean, idx: number | string, isSynced: string| boolean): void {
    if (!this.isViewOnly) {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      if(this.isPhoneNumberRequired)
      {
      this.isPhoneNumberRequiredCall();
      }
      else
      {
        this.isPhoneNumberRequiredCall(false);
      }
      phone.push(this.addPhoneArray(index, phoneLabel, countryCode, phoneNumber, extn, primaryPhone, privateInformtionPhone, isSynced));

      if (idx) {
        const group = phone.controls[idx] as UntypedFormGroup;
        // group.controls['countryCode'].setValidators([Validators.required]);
        group.controls[this.controlName].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, idx)]);
      }
    }
    if (this.requestFromGuest) {
      this.ChangePrimaryToggle();
    }
  }

  removePhoneItem(index: number): void {
    if (!this.isViewOnly) {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      this.contactForm.markAsDirty();
      phone.removeAt(index);
    }
    if (this.requestFromGuest) {
      this.ChangePrimaryToggle();
    }
  }

  togglePrimaryContact(formGroupName: number) {
    const arr = this.contactForm.get(this.arrayName) as UntypedFormArray;
    const ctrls = arr.controls.filter((x, idx) => idx !== formGroupName);
    if (!this.requestFromGuest) {
      ctrls.forEach(x => {
        const grp = x as UntypedFormGroup;
        grp.controls[this.primarySwitchName].setValue(false);
      });
      if (this.allowOnlyPrimaryOrPrivate) {
        const currentCtrl = arr.controls.filter((x, idx) => idx == formGroupName);
        currentCtrl.forEach(x => {
          const grp = x as UntypedFormGroup;
          if (grp.value[this.primarySwitchName]) {
            grp.controls[this.privateSwitchName].setValue(false);
          }
        });
      }
    } else {
      var validatePrimary = arr.value.filter((e: any) => e.isPrimary)
      if (validatePrimary?.length > 0) {
        ctrls.forEach(x => {
          const grp = x as UntypedFormGroup;
          grp.controls[this.primarySwitchName].setValue(false);
        });
      } else {
        const ctrlsTrue = arr.controls.filter((x, idx) => idx === formGroupName);
        ctrlsTrue.forEach(x => {
          const grp = x as UntypedFormGroup;
          grp.controls[this.primarySwitchName].setValue(true);
        });
      }
    }
  }

  togglePrivateContact(formGroupName: number) {
    if (!this.requestFromGuest) {
      const arr = this.contactForm.get(this.arrayName) as UntypedFormArray;
      if (this.allowOnlyPrimaryOrPrivate) {
        const currentCtrl = arr.controls.filter((x, idx) => idx == formGroupName);
        currentCtrl.forEach(x => {
          const grp = x as UntypedFormGroup;
          if (grp.controls[this.privateSwitchName]) {
            grp.controls[this.primarySwitchName].setValue(false);
          }
        });
      }
    }
  }

  ChangePrimaryToggle() {
    let contactArray = this.contactForm.get(this.arrayName) as UntypedFormArray;
    if (contactArray?.value?.length == 1) {
      const ctrls = contactArray.controls.filter((x, idx) => idx === 0);
      ctrls.forEach(x => {
        const grp = x as UntypedFormGroup;
        grp.controls[this.primarySwitchName].setValue(true);
      });
      this.isOnlyOneContact = true;
    } else {
      this.isOnlyOneContact = false;
    }
  }

  typeChange(arg, formGroup: UntypedFormGroup, i) {
    if(formGroup.get(this.typeControlName).value != this.phoneTypes.others){
      this.othersEnabled = false;
      formGroup.setControl('othersEnabled', new FormControl(this.othersEnabled));
      formGroup.get('countryCode').enable();
      this.maxPhoneLength = 12;
    } else{
      formGroup.get('countryCode').setValue('');
      formGroup.get('countryCode').clearValidators();
      formGroup.get('countryCode').disable();
      let trimmedValue = formGroup.get('phoneNumber').value.replace(/[\s()-]/g, '');
      formGroup.get('phoneNumber').setValue(trimmedValue);
      this.textMaskPhoneArray[i]='';
      this.othersEnabled = true;
      formGroup.setControl('othersEnabled', new FormControl(this.othersEnabled));
      this.maxPhoneLength = this.phoneNoMaxLengthforOthers;
      // formGroup.updateValueAndValidity();
    }
    formGroup.get(this.controlName).enable();
    if(!arg.value){
      formGroup.get('countryCode').setValue('');
      formGroup.get('extension').setValue('');
      formGroup.get(this.controlName).setValue('');
      formGroup.get('countryCode').disable();
      formGroup.get(this.controlName).disable();
      formGroup.get(this.controlName).clearValidators();
      formGroup.get(this.controlName).updateValueAndValidity();
    }else{
      if(this.currentProductId !== Product.PMS) this.phonenumberRequiredCheck( this.contactForm, i);
    }
    if (arg.value === this.phoneTypes.office && !formGroup.get('extension')) {
      formGroup.addControl('extension', new UntypedFormControl());
    }
    // this.countryCodeRequiredCheck(i);
  }


  private patchOrAdd(value) {

    const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;

    value.forEach((obj, idx) => {
      const group = phone.controls[idx] as UntypedFormGroup;
      if (group) {
        if(group.get('phoneNumber').value){
          group.controls['countryCode'].setValidators([Validators.required]);
        } else {
          group.controls['countryCode'].clearValidators();
        }
        group.controls[this.controlName].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, idx)]);

        if(this.controlName=='phoneNumber' && !this.isPhoneNumberRequired) //for bugfix 94926
        {
          group.controls['phoneNumber'].clearValidators();
        }

        if (!group.get('id')) {
          group.addControl('id', new UntypedFormControl());
        }
        if (!group.get('contactType')) {
          group.addControl('contactType', new UntypedFormControl(ContactType.phone));
        }
        if (!group.get('countryCode')) {
          group.addControl('countryCode', new UntypedFormControl());
          }
          if (!group.get('isPrimary')) {
              group.addControl('isPrimary', new UntypedFormControl());
          }
          if (!group.get('isPrivate')) {
              group.addControl('isPrivate', new UntypedFormControl());
          }
        if (obj && obj[this.typeControlName] === this.phoneTypes.office) {
          group.addControl('extension', new UntypedFormControl());
        }
        if (obj && obj[this.typeControlName] && !this.isViewOnly) {
          group.get('countryCode').enable();
          group.get(this.controlName).enable();
        }
        group.patchValue(obj);
        if (obj && obj[this.typeControlName] === this.phoneTypes.others) {
          group.get('countryCode').setValue('');
          group.get('countryCode').disable();
          group.controls['countryCode'].clearValidators();
        }
      } else {
        const id = obj ? obj.id : '';
        const phoneType = obj ? obj[this.typeControlName] : '';
        const countryCode = obj && obj[this.typeControlName] != this.phoneTypes.others ? obj.countryCode : '';
        const phoneNumber = obj ? obj[this.controlName] : '';
          const extension = obj ? obj.extension : '';
          const primaryPhone = obj ? obj.isPrimary : '';
          const privateInformtionPhone = obj ? obj.isPrivate : '';
        const isSynced = obj? obj.isSynced:false;
        // if (obj && obj[this.typeControlName] === this.phoneTypes.others) {
        //   group.get('countryCode').setValue('');
        //   group.get('countryCode').disable();
        //   group.controls['countryCode'].clearValidators();
        // }
        this.addPhoneItem(id, phoneType, countryCode, phoneNumber, extension, primaryPhone, privateInformtionPhone, idx, isSynced);
      }
      let filteredMaskCode = countrylist.filter(x=>x.dialCode === obj.countryCode);
      this.textMaskPhoneArray[idx] = countrylist.find(x=>x.dialCode === obj.countryCode)? countrylist.find(x=>x.dialCode === obj.countryCode).placeHolder:'';
      this.getphoneformatmask1(filteredMaskCode, idx);
      if(this.arrayName){
        this.checkValidity('', idx);
      }
    });
  }
  private maskPhoneNo(): string {
    return this.utilities.getPhoneFormat();
  }

  private maskExtension(): string {
    return this.utilities.getExtPhoneFormat();
  }

  private getPhoneOptions() {
    let options = [{ id: this.phoneTypes.mobile, description: this.captions.drp_txt_mobile, type: ContactType.phone },
      { id: this.phoneTypes.home, description: this.captions.drp_txt_home, type: ContactType.phone },
      { id: this.phoneTypes.office, description: this.captions.drp_txt_office, type: ContactType.phone }];
    if (this.showMoreTypes) {
      options.push({ id: this.phoneTypes.business, description: this.captions.drp_txt_business, type: ContactType.phone },
        { id: this.phoneTypes.work, description: this.captions.drp_txt_work, type: ContactType.phone });
    }
    if(this.showOthers)
        options.push({ id: this.phoneTypes.others, description: this.captions.drp_txt_others, type: ContactType.phone })
    return options;
  }

  setmandatoryField(curr, altcontrol, formGroup: UntypedFormGroup, i?) {
    if (i || i >= 0) {
      const arr = formGroup.get(this.arrayName) as UntypedFormArray;
      const group = arr.controls[i] as UntypedFormGroup;
      let curvalue = group.get(curr).value;

      if(curr === "countryCode") {
        let getPlaceholder = this.countrylist.filter(city => city.dialCode == curvalue);
        this.getphoneformatmask1(getPlaceholder,i);
      }
      if (curvalue != '') {
        if (curr == 'countryCode') {
          if(this.isPhoneNumberRequired) // for bugFix 94926
          {
            group.controls[altcontrol].clearValidators();
            group.controls[altcontrol].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, i)]);
            group.controls[altcontrol].markAsTouched();
          }
        } else {
          group.controls[altcontrol].clearValidators();
          group.controls[curr].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, i)]) //For BugFix 126818

          if (group.controls[this.typeControlName].value === PhoneTypes.mobile)
          {
            group.controls[altcontrol].setValidators([Validators.required])
          }
          group.controls[altcontrol].markAsTouched();
        }
      } else {
        if (!this.isPhoneNumberRequired && (group.controls[this.typeControlName].value == null || group.controls[this.typeControlName].value == ''))
          group.controls[altcontrol].clearValidators();
      }
      group.controls[altcontrol].updateValueAndValidity();
    } else {
      let curvalue = formGroup.get(curr).value;
      if (curvalue != '') {
        formGroup.controls[altcontrol].setValidators([Validators.required]);
        formGroup.controls[altcontrol].markAsTouched();
      } else {
        formGroup.controls[altcontrol].clearValidators();
      }
      formGroup.controls[altcontrol].updateValueAndValidity();
    }
    this.countryCodeRequiredCheck(curr, altcontrol, formGroup, i);
    if(this.arrayName){
      this.checkValidity('', i);
    }
    else{
      this.checkValiditySinglePhone('')
    }
  }

  clearOutCountryCode(curvalue, formGroup, i){
    const arr = formGroup.get(this.arrayName) as UntypedFormArray;
    const group = arr.controls[i] as UntypedFormGroup;
    let getPlaceholder = this.countrylist.filter(city => city.dialCode == curvalue.trim());
    if(getPlaceholder.length === 0){
      group.controls['countryCode'].setValue('');
    }
  }
  phonenumberRequiredCheck(formGroup, index){
    const arr = formGroup.get(this.arrayName) as UntypedFormArray;
    const group = arr.controls[index] as UntypedFormGroup;
    group.controls['phoneNumber'].setValidators([Validators.required]);
    group.controls['phoneNumber'].markAsDirty();
    group.controls['phoneNumber'].markAsTouched();
    group.controls['phoneNumber'].updateValueAndValidity();
  }
  countryCodeRequiredCheck(curr, altcontrol, formGroup, index) {
    const arr = formGroup.get(this.arrayName) as UntypedFormArray;
    const group = arr.controls[index] as UntypedFormGroup;
    let curvalue = group.get(curr).value;
    if(curr == 'phoneNumber'){
      if(curvalue == '' || curvalue == undefined || curvalue == null){
        group.controls[altcontrol].clearValidators();
      } else {
        group.controls[altcontrol].setValidators([Validators.required]);
        group.controls[altcontrol].markAsDirty();
      }
      group.controls[altcontrol].updateValueAndValidity();
    }
    // if (this.contactForm.get(this.typeControlName)) {
    //   if ((this.contactForm.get(this.typeControlName).value === PhoneTypes.mobile || this.contactForm.get(this.typeControlName).value === PhoneTypes.home || this.contactForm.get(this.typeControlName).value === PhoneTypes.office) && this.contactForm.get(this.controlName).value) {
    //     this.contactForm.controls['countryCode'].setValidators([Validators.required]);
    //   } else {
    //     this.contactForm.controls['countryCode'].clearValidators();
    //   }
    //   this.contactForm.controls['countryCode'].updateValueAndValidity();
    // } else if (this.contactForm.get('phone')) {
    //   const phoneControl = this.contactForm.controls.phone['controls'][index]['controls'];
    //   if ((phoneControl[this.typeControlName].value === PhoneTypes.mobile || phoneControl[this.typeControlName].value === PhoneTypes.home || phoneControl[this.typeControlName].value === PhoneTypes.office)) {
    //     phoneControl['countryCode'].setValidators([Validators.required]);
    //   } else {
    //     phoneControl['countryCode'].clearValidators();
    //   }
    //   phoneControl['countryCode'].updateValueAndValidity();
    // }
  }

  onControlClick(placeHolder?:string, errorMessage? : string, countryCodePlaceHolder?:string) {
    if (document.designMode == 'on') {
      let placeHolderValue:string;
      if(placeHolder)
        placeHolderValue = placeHolder ? placeHolder:'';
      else
        placeHolderValue = countryCodePlaceHolder? countryCodePlaceHolder:''

     this.dialog.open(AgMenuEditComponent, {
        width: '700px',
        height: '700px',
        data: {
          oldPlaceHolder: placeHolderValue,
          oldErrorMessage: errorMessage?errorMessage:''
        },
        disableClose: true
      }).afterClosed().subscribe(result => {
        if (result != undefined) {

          if(placeHolder)
            this.placeHolder = result.newplaceholder;
          else if(countryCodePlaceHolder)
            this.countryCodePlaceHolder = result.newplaceholder;

          this.errorMessage = result.newErrorMessage;
        }
      });
    }
  }
  getphoneformatmask1(eve,i){
    if (eve.length > 0) {
      this.textMaskPhoneArray[i] = eve[0].placeHolder;
      this.contactForm.controls.phone['controls'][i]['controls']['countryCode'].setValue(eve[0].dialCode);
      let newformatvalue = this.appendphoneFormat(this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.value,this.textMaskPhoneArray[i] );
      this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.setValue(newformatvalue);
      this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.markAsTouched();
    } else {
      this.textMaskPhoneArray[i] = '99999999999999999999' //no formate done
      let newformatvalue = this.appendphoneFormat(this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.value,this.textMaskPhoneArray[i] );
      this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.setValue(newformatvalue);
      this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.markAsTouched();
    }
  }
  getphoneformatmask(eve,i){
    if (eve.option.value.placeHolder) {
      this.textMaskPhoneArray[i] = eve.option.value.placeHolder;
      this.contactForm.controls.phone['controls'][i]['controls']['countryCode'].setValue(eve.option.value.dialCode);
      let newformatvalue = this.appendphoneFormat(this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.value,this.textMaskPhoneArray[i] );
      this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.setValue(newformatvalue);
      this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.markAsTouched();

    }
  }
  appendphoneFormat(value:string, format:string){
    if (!value && value == "") {
        return "";
    }
    let userInput = value.toString();
    userInput = userInput.replace(/[^0-9]/g,'')
    let returnVal:string ="";
    let indexOfUserValue:number = 0;
    if (format && format == "") {
        return value;
    }
    userInput = (userInput.length > 1 && userInput[0] === '0' && format[1] === '0' ? userInput.replace(/[0]/, '') : userInput);
    for (let type of format) {
        const char = type;
        let charCode = char.toString().charCodeAt(0);
        const IsFormatNumber:boolean = (charCode === 57);
        if (!IsFormatNumber) {
           returnVal = returnVal+type;
        }else{
            if (userInput[indexOfUserValue]) {
                returnVal = returnVal+userInput[indexOfUserValue];
                indexOfUserValue++;
            }else{
                break;
            }
        }
    }
    return returnVal;
  }

  updateMask(index) {
    let cCode = this.contactForm.controls.phone['controls'][index].controls.countryCode.value;
    if (cCode) {
      let cObj = countrylist.find(x => x.dialCode === cCode);
      if (cObj) {
        this.textMaskPhoneArray[index] = cObj.placeHolder;
      }
    }
  }
  //do not allow + in input field
  restrictCharacter(eve) {
    if(eve.key.match(/[+]/))
    {
      eve.preventDefault()
    }
  }
}

@Pipe({
  name: "flagSelector"
})
export class FlagSelectorPipe implements PipeTransform {
  transform(dialCode: any): string {
    let trimmedDialCode = dialCode.trim();
    let isTextString = parseInt(trimmedDialCode);

    if (trimmedDialCode) {
      let flagObj;
      if(isNaN(isTextString)) {
        flagObj = countrylist.find((x) => {
          let name = x.name.toLowerCase();
          let dialText = trimmedDialCode.toLowerCase();
          return name.includes(dialText)
        } );
      }
      else {
        flagObj = countrylist.find(x => x.dialCode === trimmedDialCode);
      }

      if (flagObj) {
        return flagObj.flagClass;
      }
    }

    return '';
  }
}
