<app-dialog-header [title]="dataInput?.headername" (closeDialog)="close($event)"></app-dialog-header>
<div class="create-payment-method-wrapper horizontal-stepper-class">
    <div class="ag_modal--form-height">
        <mat-horizontal-stepper linear #stepper>
            <mat-step>
                <ng-template matStepLabel>{{localization.captions.lbl_basic}}</ng-template>
                <div class="ag_container--padding-md">
                    <form class="create-payment-method-form-wrapper" [formGroup]="cashEQForm" autocomplete="off">
                        <div class="ag_display--flex-wrap">
                            <div class="w-50">
                                <ag-textbox class="w-50" [config]="paymentMethodNameFieldConfig"></ag-textbox>
                                <div class="ln-ht10 togglePadding w-50">
                                    <div class="mb-1"><label class="ag_form--label">{{captions.Active}}</label></div>
                                    <app-retail-toggle-switch [attr.automationId]="'Txt_createCashEqivalentPayment_isActive'" formControlName="isActive" class="toggle-switch">
                                    </app-retail-toggle-switch>
                                </div>
                                <div>
                                    <mat-form-field class="" [floatLabel]="floatLabel">
                                        <input [attr.automationId]="'Txt_createCashEqivalentPayment_listOrder'" matInput type="number" retailinputtype="onlynumber"
                                            inputtype="nonnegative,nodecimal" [placeholder]="captions.ListOrder"
                                            formControlName="listOrder" min="1" max="9999">
                                        <mat-error *ngIf="cashEQForm?.controls['listOrder']?.hasError('min')"
                                            [attr.LiteralID]="err_min">
                                            {{captions.err_min}}
                                        </mat-error>
                                        <mat-error *ngIf="cashEQForm?.controls['listOrder']?.hasError('max')"
                                            [attr.LiteralID]="err_max">
                                            {{captions.err_max}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                               
                                <div class="d-inline-flex" class="ln-ht10 togglePadding"
                                *ngIf="featureFlagInfo?.AllowAccrualPoints">
                                    <div class="mb-1"><label class="ag_form--label">{{captions.AllowEarn}}</label></div>
                                    <app-retail-toggle-switch [attr.automationId]="'Txt_createCashEqivalentPayment_allowEarn'" formControlName="allowEarn" class="toggle-switch">
                                    </app-retail-toggle-switch>
                               </div>
                            <div class="ln-ht10 togglePadding w-50" *ngIf="!isDefault && !isClonnedPayment">
                                <div class="mb-1"><label class="ag_form--label">{{captions.RequirePaymentComments}}</label>
                                </div>
                                <app-retail-toggle-switch [attr.automationId]="'Txt_createCashEqivalentPayment_requireComments'" formControlName="requireComments" class="toggle-switch">
                                </app-retail-toggle-switch>
                            </div>
                            <div class="" class="ln-ht10 togglePadding" *ngIf="!isPendingSettlement && !isVatEnabled">
                                <div class="mb-1"><label class="ag_form--label">{{captions.isAutoRemoveTax}}</label></div>
                                <app-retail-toggle-switch formControlName="isAutoRemoveTax" [attr.automationId]="'Txt_createCashEqivalentPayment_isAutoRemoveTax'" class="toggle-switch">
                                </app-retail-toggle-switch>
                            </div>
                            <div class="" class="ln-ht10 togglePadding" *ngIf="!isPendingSettlement">
                                <div class="mb-1"><label class="ag_form--label">{{captions.PostToPMS}}</label></div>
                                <app-retail-toggle-switch formControlName="postToPMS" [attr.automationId]="'Txt_createCashEqivalentPayment_PostToPMS'" class="toggle-switch">
                                </app-retail-toggle-switch>
                            </div>
                            <div class="ln-ht10 togglePadding w-50" *ngIf="!isPendingSettlement">
                                <div class="mb-1"><label class="ag_form--label">{{captions.lbl_tenderReducesDiscount}}</label></div>
                                <app-retail-toggle-switch formControlName="tenderReducesDiscount" [attr.automationId]="'Txt_createCashEqivalentPayment_tenderReducesDiscount'" class="toggle-switch">
                                </app-retail-toggle-switch>
                            </div>
                        </div>
                        <div class="w-50">
                                <div class="d-inline-flex" class="ln-ht10 togglePadding" *ngIf="enableFinancialBin">
                                    <div class="mb-1"><label class="ag_form--label">{{captions.FinancialLevelSplitUp}}</label>
                                    </div>
                                    <div class="d-flex">
                                        <mat-radio-group class="aside-filter-radiogroup radioOption" [attr.automationId]="'Rdo_createCashEqivalentPayment_financialBin'"
                                            formControlName="financialBinLevel">
                                            <mat-radio-button class="ag_form--radio-button "
                                                *ngFor="let option of radioOptions;" [value]="option.id"
                                                (change)="onChangeFinancialSplit($event)">
                                                {{option.value}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="ln-ht10 togglePadding w-50">
                                     <div class="mb-1"><label class="ag_form--label">{{captions.RequireReceipts}}</label></div>
                                     <app-retail-toggle-switch formControlName="requireReceipts" [attr.automationId]="'Txt_createCashEqivalentPayment_requireReceipts'"
                                         (changeToggleEvent)="IsRequireReceipts($event)" class="toggle-switch">
                                     </app-retail-toggle-switch>
                                </div>
                                <div *ngIf="cashEQForm?.controls.requireReceipts.value">
                                         <mat-form-field class="" [floatLabel]="floatLabel">
                                             <input matInput type="number" retailinputtype="onlynumber" [attr.automationId]="'Txt_createCashEqivalentPayment_noOfReceipts'"
                                                 inputtype="nonnegative,nodecimal" [placeholder]="captions.NumberOfReceipts"
                                                 formControlName="noOfReceipts" min="1" max="99" required>
                                             <mat-error *ngIf="cashEQForm?.controls['noOfReceipts'].hasError('required')">
                                                 {{captions.NumberOfReceiptsRequired}}
                                             </mat-error>
                                             <mat-error *ngIf="cashEQForm?.controls['noOfReceipts']?.hasError('min')"
                                                 [attr.LiteralID]="err_min">
                                                 {{captions.err_min}}
                                             </mat-error>
                                             <mat-error *ngIf="cashEQForm?.controls['noOfReceipts']?.hasError('max')"
                                                 [attr.LiteralID]="err_max">
                                                 {{captions.err_max}}
                                             </mat-error>
                                         </mat-form-field>
                                </div>
                                <div class="ln-ht10 togglePadding w-50" *ngIf="cashEQForm?.controls.requireReceipts.value && !isPendingSettlement">
                                    <div class="mb-1"><label class="ag_form--label">{{captions.includeSignatureLine}}</label></div>
                                    <app-retail-toggle-switch formControlName="includeSignatureLine" (changeToggleEvent)="validateSignature($event)" [attr.automationId]="'Txt_createCashEqivalentPayment_includeSignatureLine'" class="toggle-switch">
                                    </app-retail-toggle-switch>
                                </div>
                                <div class="" class="ln-ht10 togglePadding" *ngIf="showSignatureCapture && cashEQForm?.controls.requireReceipts.value">
                                    <div class="mb-1"><label class="ag_form--label">{{captions.RequireSignatureCapture}}</label></div>
                                    <app-retail-toggle-switch [attr.automationId]="'Txt_createCashEqivalentPayment_requireSigCapture'" (changeToggleEvent)="validateSigCap($event)" formControlName="requireSignatureCapture" class="toggle-switch">
                                    </app-retail-toggle-switch>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>{{localization.captions.lbl_additional}}</ng-template>
                <form [formGroup]="additionalInfoForm" class="ag_container--padding-md additional-info-wrapper">
                    <div formArrayName="additionalInfoFormArray">
                        <div *ngFor="let row of additionalInfoForm.get('additionalInfoFormArray')['controls']; let i=index; let first=first"
                            [formGroupName]="i">
                            <div class="ag_display--flex">
                                <ag-input class="w-45" formControlName="Key" [attr.automationId]="'Txt_createCashEqivalentPayment_key'"
                                    [placeholder]="localization.captions.setting.Key" [maxlength]="200"></ag-input>
                                <ag-input class="ag_pl--8 w-45" formControlName="Value" [attr.automationId]="'Txt_createCashEqivalentPayment_value'"
                                    [placeholder]="localization.captions.lbl_value" [maxlength]="200"></ag-input>
                                <span class="icon-Plus ag_pt--8 ag_ml--6 ag_cursor--pointer" (click)="addArray(i)"
                                    *ngIf="first"></span>
                                <span class="icon-Minus ag_pt--8 ag_ml--6 ag_cursor--pointer" (click)="removeArray(i)"
                                    *ngIf="!first"></span>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
    <div class="create-payment-method-footer ag_footer--actions">
        <app-button [attr.automationId]="'Btn_createCashEqivalentPayment_action'" [buttontype]="actionButton" (valueChange)='onAction($event)'></app-button>
        <app-button [attr.automationId]="'Btn_createCashEqivalentPayment_cancel'" [buttontype]="cancelButton" class="ag-ml-2" (valueChange)='onCancel($event)'></app-button>
    </div>
</div>
