import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-member-info',
  templateUrl: './member-info.component.html',
  styleUrls: ['./member-info.component.scss']
})
export class MemberInfoComponent implements OnInit {
  @Input() memberDetails:any;
  constructor() { }

  ngOnInit(): void {
  }

}
