import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShopComponenet } from './shop.component';
import { ViewCategoriesComponenet } from './view-categories/view-categories.component';
import { OrderSummaryComponenet } from './order-summary/order-summary.component';
import { ShopItemDetailsComponent } from './shop-common-components/shop-item-details/shop-item-details.component';
import { TicketDetailsComponent } from './shop-common-components/ticket-details/ticket-details.component';
import { RetailItemsComponent } from './view-categories/retail-items/retail-items.component';
import { RetailTransactionsComponent } from './view-categories/retail-transactions/retail-transactions.component';
import { RetailGiftCardsComponent } from './view-categories/retail-gift-cards/retail-gift-cards.component';
import { TransationsComponent } from './view-categories/transations/transations.component';
import { RetailTransactions, RetailBreakPoint } from '../shared/globalsContant';
import { RouteGuardService } from '../shared/service/route-gaurd.service';
import { UnpaidPlayersComponent } from './view-categories/unpaid-players/unpaid-players.component';
import { RetailRevenuePostingLogsComponent } from './view-categories/retail-revenue-posting-logs/retail-revenue-posting-logs.component';
import { RetailTransactionsLogComponent } from './view-categories/retail-transactions-log/retail-transactions-log.component';
import { OrphanPaymentsComponent } from './view-categories/orphan-payments/orphan-payments.component';
import { CanDeactivateShopGuard } from './guards/can-deactivate-shop.guard';
import { SignatureCaptureComponent } from './view-categories/signature-capture/signature-capture.component';
import { RefundLogComponent } from './view-categories/refund-log/refund-log.component';
import { RedirectRouteGuard } from 'src/app/common/services/redirect-route.guard.service';
import { ViewRetailItemsComponent } from './view-retail-items/view-retail-items.component';
import { RentalItemControlComponent } from './view-categories/rental-item-control/rental-item-control.component';
import { ViewNonIntegratedPaymentsComponent } from './view-categories/view-non-integrated-payments/view-non-integrated-payments.component';
import { LockerControlComponent } from './view-categories/locker-control/locker-control.component';

const routes: Routes = [
  {
    path: 'viewshop',
    component: ShopComponenet,
    canActivate: [RouteGuardService],
    children: [
      { path: 'order', component: OrderSummaryComponenet, canActivate:[RedirectRouteGuard], data:{disableRouteonSingleTherapist: true} },
      { path: 'shopItemDetails', component: ShopItemDetailsComponent, canActivate:[RedirectRouteGuard], data:{disableRouteonSingleTherapist: true} },
      { path: 'ticketDetails/:selectedTicketNumber', component: TicketDetailsComponent, canActivate:[RedirectRouteGuard], data:{disableRouteonSingleTherapist: true} },
      {
        path: '', component: ViewCategoriesComponenet, canActivate:[RedirectRouteGuard], data:{disableRouteonSingleTherapist: true},
        children: [
          { path: '', redirectTo: 'retailitems', pathMatch: 'full' },
          {
            path: 'retailitems', component: RetailItemsComponent,
            data: { frompage: 'RI', buyretailItem: ShopComponenet.getRandomDecimal(), redirectTo: '/shop/viewshop/retailtransactions', hasChild: false, disableRouteonSingleTherapist: true },
            canActivate: [RouteGuardService, RedirectRouteGuard],canDeactivate: [CanDeactivateShopGuard], 
          },
          {
            path: 'retailtransactions', component: TransationsComponent,
            data: { redirectTo: '/shop/viewshop/giftcards', hasChild: true, disableRouteonSingleTherapist: true },
            canActivate: [RouteGuardService, RedirectRouteGuard],
            children: [
              { path: '', redirectTo: 'opentransactions', pathMatch: 'full' },
              {
                path: 'opentransactions', component: RetailTransactionsComponent, //canActivate: [RouteGuardService],
                canActivate:[RedirectRouteGuard],
                data: { id: RetailTransactions.opentransactions, breakPointNumber: RetailBreakPoint.ReOpenTransaction, redirectTo: '/shop/viewshop/retailtransactions/correctvoid', disableRouteonSingleTherapist: true }
              },

              {
                path: 'correctvoid', component: RetailTransactionsComponent,
                canActivate:[RedirectRouteGuard],
                data: { id: RetailTransactions.correctvoid, redirectTo: '/shop/viewshop/retailtransactions/returnwithticket', disableRouteonSingleTherapist: true }
              },

              {
                path: 'returnwithticket', component: RetailTransactionsComponent,
                canActivate:[RedirectRouteGuard],
                data: { id: RetailTransactions.returnwithticket, redirectTo: '/shop/viewshop/retailtransactions/returnwithoutticket', disableRouteonSingleTherapist: true }
              },

              {
                path: 'returnwithoutticket', component: RetailTransactionsComponent,
                canActivate:[RedirectRouteGuard],
                data: { id: RetailTransactions.returnwithoutticket, redirectTo: '/shop/viewshop/retailtransactions/modifypostedcommission', disableRouteonSingleTherapist: true }
              },

              {
                path: 'modifypostedcommission', component: RetailTransactionsComponent, canActivate: [RouteGuardService, RedirectRouteGuard],
                data: { id: RetailTransactions.modifypostedcommission, breakPointNumber: RetailBreakPoint.ModifyPostedCommission, redirectTo: '/shop/viewshop/retailtransactions/reprintticket', disableRouteonSingleTherapist: true }
              },

              {
                path: 'reprintticket', component: RetailTransactionsComponent, canActivate: [RouteGuardService, RedirectRouteGuard],
                data: { id: RetailTransactions.reprintticket, breakPointNumber: RetailBreakPoint.ReprintTicket, redirectTo: '/shop/viewshop/retailtransactions/revenuepostingslog', disableRouteonSingleTherapist: true }
              },
              {
                path: 'transactionslog', component: RetailTransactionsLogComponent, canActivate: [RouteGuardService, RedirectRouteGuard], data:{disableRouteonSingleTherapist: true}
              },
              {
                path: 'revenuepostingslog', component: RetailRevenuePostingLogsComponent, canActivate: [RouteGuardService, RedirectRouteGuard],
                data: { id: RetailTransactions.RevenuePostingsLog, breakPointNumber: RetailBreakPoint.RevenuePostingsLog, redirectTo: '', disableRouteonSingleTherapist: true }
              },
              {
                path: 'unsettledpayments', component: OrphanPaymentsComponent, canActivate: [RouteGuardService, RedirectRouteGuard],
                data: { id: RetailTransactions.OrphanPayments, breakPointNumber: RetailBreakPoint.OrphanPayments, redirectTo: '', disableRouteonSingleTherapist: true }
              },
              {
                path: 'datamaginereceiptlog', component: SignatureCaptureComponent, canActivate: [RouteGuardService, RedirectRouteGuard],
                data: { id: RetailTransactions.DataMagineReceiptLog, breakPointNumber: RetailBreakPoint.DataMagineLog, redirectTo: '', disableRouteonSingleTherapist: true }
              },
              {
                path: 'refundLog', component: RefundLogComponent, canActivate: [RouteGuardService, RedirectRouteGuard],
                 data: { id: RetailTransactions.RefundLog, breakPointNumber: RetailBreakPoint.RefundFailure, redirectTo: '', disableRouteonSingleTherapist: true }
              },
              {
                path: 'nonintegratedpaymentlog', component: ViewNonIntegratedPaymentsComponent, canActivate: [RouteGuardService, RedirectRouteGuard],
                data: { id: RetailTransactions.NonIntegratedPaymentLog, breakPointNumber: RetailBreakPoint.NonIntegratedPaymentLog, redirectTo: '', disableRouteonSingleTherapist: true }
              }
            ]
          },
          { path: 'giftcards', component: RetailGiftCardsComponent,canActivate: [RedirectRouteGuard], data: { redirectTo: '', hasChild: false, disableRouteonSingleTherapist: true }, },
          {
            path: 'unpaidplayers', component: UnpaidPlayersComponent, canActivate: [RouteGuardService, RedirectRouteGuard],
            data: { redirectTo: '', breakPointNumber: RetailBreakPoint.TeeTimePay, hasChild: false, disableRouteonSingleTherapist: true },
          },
          {
            path: 'rentalitemcontrol', component: RentalItemControlComponent,canActivate: [RouteGuardService, RedirectRouteGuard],
            data: { redirectTo: '',breakPointNumber: RetailBreakPoint.RENTALITEMCONTROL, hasChild: false },
          },
          {
            path: 'lockercontrol', component: LockerControlComponent,canActivate: [RouteGuardService, RedirectRouteGuard],
            data: { redirectTo: '', breakPointNumber: RetailBreakPoint.LOCKERCONTROL, hasChild: false },
          },
        ]
      }
    ]
  },
  {
    path: 'viewshop/:globalslug',
    component: ShopComponenet,
    canActivate: [RedirectRouteGuard],
    data:{disableRouteonSingleTherapist: true},
    children: [
      { path: '', component: ViewCategoriesComponenet },
    ]
  },
  {
    path: 'viewretailitem',
    component: ViewRetailItemsComponent,
    children: [
      { path: '', redirectTo: 'retailitems', pathMatch: 'full' },
      {
        path: 'retailitems', component: RetailItemsComponent,
        data: { frompage: 'RI', buyretailItem: ShopComponenet.getRandomDecimal()},
        canActivate: [RouteGuardService],canDeactivate: [CanDeactivateShopGuard]
      },
    ]
  },
  {
    path: 'viewgolfretailitem',
    component: ViewRetailItemsComponent,
    canDeactivate: [CanDeactivateShopGuard],
    data: { frompage: 'RI', buyretailItem: ShopComponenet.getRandomDecimal()},
  },
  { path: '', redirectTo: 'viewshop', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule { }
