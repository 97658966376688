<section class="retailDiscount retailDiscountWrapper newVersionWrapper" [formGroup]='filterForm'>
  <div *ngIf="errorMessage" class="error-msg ag_form--label">{{errorMessage}}</div>
  <div class="ag_display--flex h-100">
  <div class="tablesection" [ngClass]="[showFilters ? 'w-80' : 'w-100']">
      <div class="header bodyWrap">
        <div class="leftSection pl-4">
          <mat-form-field class="pt-4 spa-searchbox custSearchbox" [floatLabel]="floatLabelNever">
            <input [attr.automationId]="'Txt_retailDiscountConfiguration_search'" matInput autocomplete="off" class="serachBoxWidth" formControlName ="searchText" (input)="search($event)" type="text" placeholder="{{captions.SearchByCategory}}" [disabled]="userEditing" >
            <i  aria-hidden="true" *ngIf="filterForm.controls.searchText.value ==''" class="icon-Search" [ngClass]="userEditing?'disableIcon':''" (click)="clear();"></i>
            <i  aria-hidden="true" *ngIf="filterForm.controls.searchText.value != '' " class="icon-Cancel" [ngClass]="userEditing?'disableIcon':''" (click)="clear();"></i>
          </mat-form-field>
        </div>
        <div class="rightSection" [ngClass]="IsViewOnly || userEditing ? 'button_invalid' : ''" >
          <div class="icon-filter boxed-icon mt-1 ml-2" matBadge="0" [matBadgeHidden]="hideBadge" (click)="openFilter()" matTooltip="{{captions.lbl_filters}}">
          </div>
          <div  class="icon-Edit boxed-icon mt-1 ml-2" matTooltip="{{captions.bulkEdit}}"  [popover]="bulkEditPopover" popoverPlacement="bottom" [popoverOnHover]="false"
          [popoverCloseOnMouseOutside]="false" [attr.automationId]="'Icn_retailDiscountConfiguration_filter'"
          [popoverCloseOnClickOutside]="true"></div>
        </div>

      </div>
      <div class="dataList">
            <app-retail-table-inline-edit [EnableEdit]="editData" [disableActions]="disableEditButton" [options]="tableoptions" [searchString]="filterForm.controls.searchText.value" (DeleteData)="deletedRecord($event)" (userEditing)="userEdit($event)"  (doneEvtEmitter)="Done($event)" (errormsgEmit)="maxError($event)"></app-retail-table-inline-edit>
      </div>
  </div>
  <div *ngIf="showFilters" class="filter bodyWrap">
    <div class="filter-header">
      <span class="spa-filter-title" >{{captions.lbl_filters}}</span>
      <i class="icon-Cancel" (click)="openFilter()"></i>
    </div>
    <div class="filter-body">
      <app-ag-dropdown [inputs]="filterdiscountTypeOptions"></app-ag-dropdown>
      <app-ag-dropdown [inputs]="filtercategoryOptions"></app-ag-dropdown>
      <!-- <ag-dropdown class='selectWidth' formControlName='playerType' [source]="playerTypeOptions" [placeholder]="captions.selectPlayerType">
      </ag-dropdown>
      <ag-dropdown class='selectWidth' formControlName='rateType' [source]="rateTypeOptions" [placeholder]="captions.selectRateType">
      </ag-dropdown> -->
    </div>
    <div class='ag_footer--actions'>
      <app-button [buttontype]='applyResetButton' [attr.automationId]="'Btn_retailDiscountConfiguration_save'" (valueChange)='onSave()'></app-button>
      <app-button [buttontype]='resetFilterButton' [attr.automationId]="'Btn_retailDiscountConfiguration_resetFilter'" (valueChange)='reset()'></app-button>
  </div>
  </div>
</div>
      <popover-content #bulkEditPopover class="bulkEdit-popover popover-content" title="" placement="left auto" [animation]="true"
                 [closeOnClickOutside]="false">
  <ng-container>
    <section class="bulkEdit-wrapper">
      <div class='header_title'>
        <h4 class="ag_group--headers">{{captions.bulkEdit}}</h4>
        <i class="icon-Cancel cursor ml-auto" (click)="resetPopOver();bulkEditPopover.hide()"></i>
      </div>
      <div class="bulkedit-body">
        <div class="bulkedit-body-innerWrap">
          <div class="control-width">
            <label class="mb-1">{{localization.captions.percentAmount}}</label>
            <mat-button-toggle-group formControlName='percentAmount'
              [attr.automationId]="'Tog_assignCommissionPopup_percentage'" class="w-100"
              (change)="togglebuttonVal($event)">
              <mat-button-toggle [value]="0" class="w-50"><span class="icon-percent"></span></mat-button-toggle>
              <mat-button-toggle [value]="1" class="w-50"><span class="icon-Flat-Rate"></span></mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <app-ag-dropdown [inputs]="discountTypeOptions"></app-ag-dropdown>
          <!-- <ag-dropdown class='dropdown control-width' formControlName='discountType' required  [source]="discountTypeOptions" [placeholder]="captions.selectDiscountType" [multiple]="true" [isAll]="true">
          </ag-dropdown> -->
        </div>
        <div class="align-items-center d-flex">
          <app-ag-dropdown class="w-50" [inputs]="categoryOptions"></app-ag-dropdown>
          <!-- <ag-dropdown class='dropdown control-width' required formControlName='category' [source]="categoryOptions" [placeholder]="captions.selectCategory" [multiple]="true" [isAll]="true">
          </ag-dropdown> -->
          <mat-form-field [floatLabel]="floatLabel" *ngIf="isPercentage">
            <input placeholder="{{captions.enterValue}}" [attr.automationId]="'Txt_retailDiscountConfiguration_applyValue'" required matInput autocomplete="off" class="serachBoxWidth" type="text"  value="" [disabled]="userEditing" formControlName='applyValue' RetailCurrencyFormatter  [preDecimalLength]="maxLength" max="100" (input)="checkValid()" >
            <mat-error *ngIf="filterForm.controls['applyValue'].hasError('max')">
              {{captions.err_max}}
            </mat-error>
          </mat-form-field>
          <mat-form-field [floatLabel]="floatLabel" *ngIf="!isPercentage">
            <input placeholder="{{captions.enterValue}}" [attr.automationId]="'Txt_retailDiscountConfiguration_applyValue'" required matInput autocomplete="off" class="serachBoxWidth" type="text"  value="" [disabled]="userEditing" formControlName='applyValue' RetailCurrencyFormatter [preDecimalLength]="maxLength" (input)="checkValid()" >
          </mat-form-field>
        </div>
        <!-- <mat-checkbox class=''[checked]=""
        (change)='includeInactive($event)' [attr.automationId]="'Chk_retailDiscountConfiguration_includeInactive'">
        {{captions.lbl_includeInactive}}
        </mat-checkbox> -->
      </div>
      <div class='ag_footer--actions pt-2 p-0'>
        <app-button [buttontype]='applyButton' [attr.automationId]="'Btn_retailDiscountConfiguration_apply'" (valueChange)='onAction($event);bulkEditPopover.hide();'></app-button>
        <app-button [buttontype]='resetButton' [attr.automationId]="'Btn_retailDiscountConfiguration_reset'" (valueChange)='resetPopOver();'></app-button>
    </div>
    </section>
  </ng-container>
</popover-content>

</section>
