
import { Injector } from "@angular/core";
import { QuickLoginUtilities } from "@app/common/shared/shared/utilities/quick-login-utilities";
import { RetailPropertyInformation } from "@app/retail/common/services/retail-property-information.service";
import { RetailItemType } from "@app/retail/retail.modals";
import { ConfigKeys, RetailFeatureFlagInformationService } from "@app/retail/shared/service/retail.feature.flag.information.service";
import { SelectedProducts } from "@app/retail/shared/shared.modal";
import { PropertyFeaturesConfigurationService } from "@app/retail/sytem-config/payment-features-config/property-feature-config.service";
import { Subject } from "rxjs";




export class ActivitiesRetailCommon {

  SelecedSeatProducts: SelectedProducts[] = [];
  isOperationFromActivities: boolean = false;
  isOperationFromPos: boolean = false;
  isOperationFromRental: boolean = false;
  isCancelOperation = false;
  isCreateOperation = false;
  guestId = 0;
  isFirstInitialisation = false;
  isEatecEnabled: boolean;
  seatedProductsBookingAttemptId:any;
  usersession: Subject<any> = new Subject<any>();
  returnReason:string ='';
  routepath:string = '';
	ReserveZeroTenderName: string;

  private _propertyFeatureService;
  private quickLoginUtils;
  public _featureFlagService;
  private _propertyInfonew;

  constructor(injector?: Injector) {
    if (injector) {
      this._propertyFeatureService = injector.get(PropertyFeaturesConfigurationService);
      this.quickLoginUtils = injector.get(QuickLoginUtilities);
      this._featureFlagService = injector.get(RetailFeatureFlagInformationService);
      this._propertyInfonew = injector.get(RetailPropertyInformation);    
    }
  }

  refreshConfig(){
    this._featureFlagService.refreshConfig();
  }

  setQuickLogin() {
    this.quickLoginUtils.resetQuickIdDetails();
    if (!sessionStorage.getItem("QuickIdConfig")) {
      this._propertyFeatureService.SetQuickIdConfigSettingForRetail("QuickIdConfig");
    }
  }


  setGiftCardConfiguration() {
    // Gift card for activities  
    if (!sessionStorage.getItem("giftCardConfiguration")) {
      this._propertyFeatureService.GetGiftCardConfiguration().then((config) => {
        this._propertyInfonew.SetGiftCardConfiguration(config);
      });
    }
    this._featureFlagService.RefreshConfig();

    if (!sessionStorage.getItem("EatecURI")) {
      var configValue = "";
      let featureNames = ["Enhanced Inventory"];
      this._propertyFeatureService.GetFeatureConfigurations(featureNames).then((featureconfigurations) => {
        if (featureconfigurations != null) {
          this.isEatecEnabled = true;
          sessionStorage.setItem('isEatecEnabled', 'true')
          let eatecUser = featureconfigurations.find(f => f.configurationKey == ConfigKeys.Eatec.EatecTenantUser);
          let uri = featureconfigurations.find(f => f.configurationKey == ConfigKeys.Eatec.EatecURI);
          if (eatecUser && eatecUser.configurationValue && uri && uri.configurationValue) {
            configValue = uri.configurationValue
          }
        }
        else {
          this.isEatecEnabled = false;
          sessionStorage.setItem('isEatecEnabled', 'false')
        }
        this._propertyInfonew.SetEatecRI(configValue)
      });
    }

    this._propertyFeatureService.GetMiscConfig();
  }


  
}

export interface ActivitySelectedProducts {
  ReservationAttemptId?: any;
  isDisabledActivityItem?: boolean;
  isEditableActivityItem?: boolean;
  fromWidget?: boolean;
  hideZeroPrice?: boolean;
  CartId?: number;
  guestId?: number;
  ignoreTransaction?:boolean;
  TransDetailId?: number;
  Index? : number;
}

export interface ActivityPayeeInfo {
  RetailTicketNumber?: string;
  IsActivitesInvolved?: boolean;
  TransactionDetailId?: number;
  RetailTransactionId?: number;
  MembershipInvolved?: boolean;

}
export interface OrderetailDTO {
  Id: number;
  CheckId: number;
  RestaurantId: number;
  PartyId: number;
  ContactId: number;
  orderDetails: OrderItemDTO[];
  OrderPrice: number;
  CreatedAt: Date;
  RetailTransactionId: number;
  OldRetailTransactionId: number;
  RetailTransactionType: RetailTransactionType;
  OldRetailTransactionType: RetailTransactionType;
  OrderType: OrderType;
  IsActive: boolean;
}



export interface OrderItemDTO {
  Id: number;
  Qty: number;
  ExtPrice: number;
  Name: string;
  TaxPrice?: number;
  RetailTransactionType?: RetailTransactionType;
  RetailItemType?: RetailItemType;
  ItemStatus?: ItemStatus;
}

export enum ItemStatus {
  OPEN = 1,
  CLOSED
}
export enum OrderType {
  IG = 0,
  RETAIL
}


export enum RetailTransactionType {
  VOID = 1,
  SETTLE,
  SALE,
  RETURN_WITHOUT_TICKET,
  RETURN_WITH_TICKET_WITHOUT_EXCHANGE,
  RETURN_WITH_TICKET_WITH_EXCHANGE,
  REOPEN,
  CORRECT,
}


export interface RetailTranactionDetailList {
  TransactionDetailId: number;
  LineNumber: number;
  ReservationAttemptIdorTranactionDetailId: number;
}


