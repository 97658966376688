import { Injectable } from '@angular/core';
import { CommonUtilities } from '../shared/shared/utilities/common-utilities';
import { Localization } from '../shared/localization/Localization';
import { Localization as commonLocalization } from 'src/app/common/localization/localization';
import { CommonApiRoutes } from '../common-route';
import { ReportCommunication } from '../communication/services/report.service';
import { JReportFormat, JasperReportParameter, JasperReportRequest, Jaspersoftconstants, ReportDownloadFormat, ReportDownloadFormatOptions, ReportOutputFormat, ResponseType } from './jasperreport.model';
import { JasperReportDetails } from './jasperreport.model';
import { JasperServerCommonDataService } from '../dataservices/jasperServerCommon.data.service';

@Injectable({
  providedIn: 'root'
})
export class JasperReportBusiness{
    constructor(private localization:Localization,private utils:CommonUtilities,private http: ReportCommunication,
      private jasperservercommonservice: JasperServerCommonDataService){

    }
    jreportseletor : any;
    captions : any = this.localization.captions;
    getDownloadoptions(isJasperReport:boolean,) {
        return isJasperReport ?
          [
            { id: 1, value: ReportDownloadFormatOptions.PDF, viewValue: "PDF" },
            { id: 2, value: ReportDownloadFormatOptions.EXCELPAGINATED, viewValue: "EXCEL(Paginated)" },
            { id: 3, value: ReportDownloadFormatOptions.EXCEL, viewValue: "EXCEL" },
            { id: 4, value: ReportDownloadFormatOptions.CSV, viewValue: "CSV" },
            { id: 5, value: ReportDownloadFormatOptions.DOCX, viewValue: "DOCX" },
            { id: 6, value: ReportDownloadFormatOptions.RTF, viewValue: "RTF" },
            { id: 7, value: ReportDownloadFormatOptions.ODT, viewValue: "ODT" },
            { id: 8, value: ReportDownloadFormatOptions.ODS, viewValue: "ODS" },
            { id: 9, value: ReportDownloadFormatOptions.XLSXPAGINATED, viewValue: "XLSX(Paginated)" },
            { id: 10, value: ReportDownloadFormatOptions.XLSX, viewValue: "XLSX" },
            { id: 11, value: ReportDownloadFormatOptions.PPTX, viewValue: "PPTX" },
            { id: 12, value: ReportDownloadFormatOptions.CSVMETADATA, viewValue: "CSV Metadata" }
          ] : [
          { id: 1, value: ReportDownloadFormatOptions.PDF, viewValue: "PDF" },
            { id: 2, value: ReportDownloadFormatOptions.WORD, viewValue: "WORD" },
            { id: 3, value: ReportDownloadFormatOptions.EXCEL, viewValue: "EXCEL" },
            { id: 4, value: ReportDownloadFormatOptions.RAWDATA, viewValue: "RAWDATA" }
          ]
      }

      async sendJasperReportRequest(body: any,jasperportDetails:JasperReportDetails,Reportbasepath:string,isLoaderRequired:boolean = true, isResortFinance:boolean = false) :Promise<any>{
          const jasperapiresponseType: string = ReportDownloadFormatOptions.HTML;
          var data = this.http.putHTTPBlobData({
            route: isResortFinance?`${Reportbasepath}/${CommonApiRoutes.JaspersoftFolioReportExecutionAPI}`:`${Reportbasepath}/${CommonApiRoutes.JaspersoftReportExecutionAPI}`,
            body: await this.GetReportBody(body,jasperportDetails)
          }, body.format=='HTML'?ResponseType.HTML:ResponseType.BLOB,isLoaderRequired).toPromise();

          data.catch(x=> {
            if(!isResortFinance)
              this.utils.showError(this.captions.jasperReportErrorInGenerate);
          });

        return  data.then(x=>{
          return x;
        });
       
      }
      #endregion
      GetFileNameWithExtensionSUffixDate(type, reportcode): string {
        let extension = ReportOutputFormat[type];
        let currentDate: Date = this.localization.getCurrentDate();
        let dateTime: string = currentDate.toLocaleDateString(this.localization.localeCode);
        let downloadSuffixDate: string = dateTime.replace(/\D/g, '');
        return `${reportcode}_${downloadSuffixDate}.${extension}`
      }
      GetFileNameWithExtension(type, reportcode): string {
        let extension = ReportOutputFormat[type];
        let currentDate: Date = this.localization.getCurrentDate();
        let dateTime: string = currentDate.toLocaleDateString(this.localization.localeCode);
        return `${reportcode}.${extension}`
      }
      getalldata(){
        return ''
      }
      async GetReportBody(body,jasperportDetails:JasperReportDetails): Promise<JasperReportRequest> {
        let jreportoutputFormat: JReportFormat = this.GetExcelFormat(body.format);
        var result: JasperReportRequest = {
          reportUnitUri: jasperportDetails.reportUrlPath,
          async: false,
          interactive: false,
          ignorePagination: jreportoutputFormat.ignorePagination,
          freshData:true,
          saveDataSnapshot:true,
          transformerKey:null,
          baseUrl:"/jasperserver-pro",
          outputFormat: jreportoutputFormat.outputFormat,
          parameters: {
            reportParameter: await this.formReportParamsMapper(body)
          }
      
        }
        return result;
      }
      async formReportParamsMapper(body: any) : Promise<JasperReportParameter[]> {
        var result:JasperReportParameter[] = [];
       
        if(body.filterBody && typeof body.filterBody =='object')
        {
           result =  this.ReportInputMapper(body.filterBody);
        }
        else if (body.filterBody){
          result = this.ReportInputMapper(JSON.parse(body.filterBody));
        }
        let UItoken = await this.jasperservercommonservice.GetJWTTokenForJasper();
        result.push({ name: Jaspersoftconstants.Bearertoken, value: [UItoken] });
        return result;
    }
        
      ReportInputMapper(obj) : JasperReportParameter[] {
        let data = [];
        Object.keys(obj).map(key => (
          data.push({
            name: key,
            value: Array.isArray(obj[key]) ? obj[key] : [obj[key]]
          })));
        return data;
      }

      GetExcelFormat(responseType: ReportDownloadFormat): JReportFormat {
        switch (responseType) {
          case ReportDownloadFormatOptions.PDF:
            return {
              ignorePagination: false,
              outputFormat: ReportOutputFormat.PDF
            }
          case ReportDownloadFormatOptions.EXCELPAGINATED:
            return {
              ignorePagination: true,
              outputFormat: ReportOutputFormat.EXCELPAGINATED
            }
          case ReportDownloadFormatOptions.EXCEL:
            return {
              ignorePagination: false,
              outputFormat: ReportOutputFormat.EXCEL
            }
          case ReportDownloadFormatOptions.CSV:
            return {
              ignorePagination: false,
              outputFormat: ReportOutputFormat.CSV
            }
          case ReportDownloadFormatOptions.DOCX:
            return {
              ignorePagination: false,
              outputFormat: ReportOutputFormat.DOCX
            }
          case ReportDownloadFormatOptions.RTF:
            return {
              ignorePagination: false,
              outputFormat: ReportOutputFormat.RTF
            }
          case ReportDownloadFormatOptions.ODT:
            return {
              ignorePagination: false,
              outputFormat: ReportOutputFormat.ODT
            }
          case ReportDownloadFormatOptions.ODS:
            return {
              ignorePagination: false,
              outputFormat: ReportOutputFormat.ODS
            }
          case ReportDownloadFormatOptions.XLSXPAGINATED:
            return {
              ignorePagination: true,
              outputFormat: ReportOutputFormat.XLSXPAGINATED
            }
          case ReportDownloadFormatOptions.XLSX:
            return {
              ignorePagination: false,
              outputFormat: ReportOutputFormat.XLSX
            }
          case ReportDownloadFormatOptions.PPTX:
            return {
              ignorePagination: false,
              outputFormat: ReportOutputFormat.PPTX
            }
          case ReportDownloadFormatOptions.CSVMETADATA:
            return {
              ignorePagination: false,
              outputFormat: ReportOutputFormat.CSVMETADATA
            }
          case ReportDownloadFormatOptions.HTML:  
            return {
              ignorePagination: false,
              outputFormat: ReportOutputFormat.HTML
            }
        }
      }
}
