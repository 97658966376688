import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RetailLocalization } from '../common/localization/retail-localization';
import { GridType } from '../shared/globalsContant';
import { RetailPopupComponent } from '../retail-popup/retail-popup.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-scheduled-markdown',
  templateUrl: './scheduled-markdown.component.html',
  styleUrls: ['./scheduled-markdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduledMarkdownComponent implements OnInit {
  tableoptions:any;
  selectedData:any =[];
  tabledata:any[]=[
  { "id":1,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":2,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":3,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":4, "item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":5,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":6,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":7,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":8,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":9,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":10,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":11,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":12, "item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":13,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":14,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":15,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":16, "item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":17,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":18,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":19,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":20,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":21,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":22,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":23,"item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00},
    { "id":24, "item":1,"description":"Pro Shop","categories":"Golf Apparel","quantity":"4","price":"35.78","cost":"35.45","startDate":"03/01/2014","endDate":"03/01/2025","priceSample":10.00,"discount":2.00,
    "adjustedPrice":8.00,"currentMember":20.00,"discountMember":2.00,"adjustedMemberPrice": 16.00}
 ];
  captions: any = this.localization.captions.retailsetup;
  constructor(public localization:RetailLocalization, public dialog:MatDialog) { }

  ngOnInit() {
    this.BindTableData();
  }
  BindTableData() {
    this.tableoptions = [{
      TableHdrData: [{ "title": this.captions.Item, "jsonkey": "item", "sortcolumndatatype": "number", "searchable": true ,"sortable":true},
      { "title": this.captions.Description, "jsonkey": "description", "searchable": true,"sortable":true },
      { "title": this.captions.Category, "jsonkey": "categories","searchable": true,"sortable":true },
      { "title": this.captions.qtyAvailable, "jsonkey": "quantity","searchable": false,"sortable":true,"alignType":"right"},
      { "title": this.localization.replacePlaceholders(this.captions.price, ["option"], [this.localization.currencySymbol]), "jsonkey": "price","searchable": false,"sortable":false,"alignType":"right" ,"datatype":"money"},
      { "title": this.localization.replacePlaceholders(this.captions.cost, ["option"], [this.localization.currencySymbol]), "jsonkey": "cost" ,"searchable": false,"sortable":false,"alignType":"right", "datatype":"money"},
      { "title": this.localization.replacePlaceholders(this.captions.currentPrice, ["option"], [this.localization.currencySymbol]), "jsonkey": "priceSample" ,"searchable": false,"sortable":false,"alignType":"right","datatype":"money"},
      { "title": this.captions.discount, "jsonkey": "discount","searchable": false,"sortable":false,"alignType":"right", "datatype":"percentage" },
      { "title": this.localization.replacePlaceholders(this.captions.adjustedPrice, ["option"], [this.localization.currencySymbol]), "jsonkey": "adjustedPrice" ,"searchable": false,"sortable":false,"alignType":"right","datatype":"money"},
      { "title": this.localization.replacePlaceholders(this.captions.currentMember, ["option"], [this.localization.currencySymbol]), "jsonkey": "currentMember","searchable": false,"sortable":false,"alignType":"right","datatype":"money" } ,
      { "title": this.captions.discountMember, "jsonkey": "discountMember","searchable": false,"sortable":false, "alignType":"right","datatype":"percentage"},
      { "title": this.localization.replacePlaceholders(this.captions.adjustedMemberPrice, ["option"], [this.localization.currencySymbol]), "jsonkey": "adjustedMemberPrice" ,"searchable": false,"sortable":false,"alignType":"right","datatype":"money"},
      { "title": this.captions.startDate, "jsonkey": "startDate","searchable": false,"sortable":false },
      { "title": this.captions.endDate, "jsonkey": "endDate" ,"searchable": false,"sortable":false}      
       ],                    
      TablebodyData: this.tabledata,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      EditMoreOption: true,
      PlaceHoldertext: this.captions.markDownSearchText,
      EnableActions: true,
      SelectedSettingId:"markdown",
      SelectRows: true,
      Searchable: GridType.customfield,
      Sortable: "Outlet",
      TableId: GridType.taxes,
      disableDelete: false,
      showToggle: false,
      IsViewOnly: false,
      sticky: true,
      rowDisabledProperty: false
    }];

  }

  selectedRows(eve){
    this.selectedData = eve;
  }

  DeleteRecords(event){
    console.log("records to be deleted",event[0]);
  }

  openDialog(dataSet, eve){
   if(this.selectedData.length > 0){
    this.selectedData.forEach(element => {
     element.checked = true;
   });
  }
    let data;let editSelected=[];

    if (eve == "Edit") {
      dataSet[0].checked = true;
      editSelected.push(dataSet[0]);
      this.tableoptions[0].TablebodyData = editSelected;

      data = {"templatename":"SM","headername":this.captions.editMarkDown ,"closebool":true,"datarecord":this.tableoptions,"selectedArray": editSelected,"editedData":dataSet[0],"status":"edit"}
    } else {
      this.tableoptions[0].TablebodyData = this.selectedData;
      data = {"templatename":"SM","headername": this.captions.addMarkDown,"closebool":true,"datarecord":this.tableoptions,"selectedArray": this.selectedData,"editedData":dataSet[0],"status":"add"}
    }
    const dialogRef = this.dialog.open(RetailPopupComponent, {
      width: '85%',
      maxWidth: "1500px;",
      height: '80%',
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay' ,
      disableClose: true,
      data:data
    });

    dialogRef.afterClosed().subscribe(result => {
      if(eve == "Edit"){ 
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
      } 
    });

  }


}
