
import { Product } from '../shared/globalsContant';
import { Injectable } from '@angular/core';
import { RetailItemType, ImportRetailitemsColumn } from './retail-child-setup.model';

@Injectable()
export class RetailChildSetupCSVValidator {

    validItemTypes: any[];

    setValidRetailItemTypesForProduct(productId: Product): any[] {
        let result = [];
        switch (productId) {
            case Product.SPA:
                result = [
                    RetailItemType.RetailItemRetailPOSOnly,
                    RetailItemType.RetailItemAvailableForSpaPackages,
                    RetailItemType.SpaServices,
                    RetailItemType.SpaPackage,
                    RetailItemType.AppointmentAddon,
                    RetailItemType.Deposit,
                    RetailItemType.Classes,
                    RetailItemType.Lockers,
                    RetailItemType.CustomFee, 
                    RetailItemType.RentalItem    
                ];
                break;
            case Product.GOLF:
                result = [
                    RetailItemType.RetailItemRetailPOSOnly,
                    RetailItemType.Deposit,
                    RetailItemType.PMSAddOnsRentalItem
                ];
                break;
            case Product.SNC:
                result = [
                    RetailItemType.RetailItemRetailPOSOnly,
                    RetailItemType.Deposit,
                    RetailItemType.RevenueItem];
                break;
            case Product.RETAIL:
                result = [
                    RetailItemType.RetailItemRetailPOSOnly
                ];
                break;
            case Product.PMS:
                result = [
                    RetailItemType.RetailItemRetailPOSOnly,
                    RetailItemType.RentalItem
                ];
                break;
        }
        this.validItemTypes = result;
        return result;
    }

    setDefaultItemTypeIfEmpty(row: any) {
      const itemType = row[ImportRetailitemsColumn.ItemType];
      if (itemType === '') {
        row[ImportRetailitemsColumn.ItemType] = RetailItemType.RetailItemRetailPOSOnly;
      }
    }

    isValidItemType(row: any): boolean {
        const itemType = row[ImportRetailitemsColumn.ItemType];
       return this.validItemTypes.some(x => x == itemType);
    }

    isServiceChargeOrGratuityApplicable(row: any): boolean {
        const itemType = row[ImportRetailitemsColumn.ItemType];
        const serviceCharge = row[ImportRetailitemsColumn.ServiceCharge];
        const gratuity = row[ImportRetailitemsColumn.Gratuity];
        if ((itemType == RetailItemType.RetailItemRetailPOSOnly ||
            itemType == RetailItemType.RetailItemAvailableForSpaPackages ||
            itemType == RetailItemType.SpaPackage) &&
            (this.isGreaterThanZero(serviceCharge) || this.isGreaterThanZero(gratuity))
        ) {
            return false;  
        }
        return true;
    }

    isGreaterThanZero(value: any): boolean {
        return !isNaN(value) && value > 0;
    }

}
