import { Component, EventEmitter, OnInit, Output, ViewChild, Input, SimpleChanges } from '@angular/core';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { PartyService } from '@app/shared/services/party.service';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { PaymentMethod, PaymentType, Status } from '@constants/commonenums';
import { TranslateService } from '@ngx-translate/core';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { CacheService } from '@app/core/services/cache.service';
import { RetailPropertyInformation } from '@app/retail/common/services/retail-property-information.service';
import { CreditCardDTO } from '@app/shared/models/InputContact';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormType, GuestLinkType } from '@app/shared/models/FormCodeDTO';
import { FormCodeDTO } from '@app/shared/models/FormCodeDTO';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { ChangeDetectorRef } from '@angular/core';
import { SettingsService } from '@services/settings.service';
import { getMappedCreditCard } from '../rate-plan/rate-plan.component';

@Component({
  selector: 'app-common-booking-payments',
  templateUrl: './common-booking-payments.component.html',
  styleUrls: ['./common-booking-payments.component.scss']
})
export class CommonBookingPaymentsComponent implements OnInit {
  paymentConfig: FieldConfig[] = [];
  saveCardAgainstGuestConfig: FieldConfig[] = [];
  @ViewChild('paymentType', { static: true }) paymentTypeForm: DynamicFormComponent;
  @ViewChild('formCodeList', { static: true }) authorizeFormCode: DynamicFormComponent;
  // @ViewChild('sendLinkType', { static: true }) sendLinkForm: DynamicFormComponent;
  @ViewChild('saveCardAgainstGuestForm', { static: true }) saveCardAgainstGuestForm: DynamicFormComponent;
  @ViewChild('form') dynamicForm: DynamicFormComponent;
  subscriptions: Subscription = new Subscription();
  sendLinkOptionsConfig: FieldConfig[] = [];
  public PaymentMethod = PaymentMethod;
  @Output() setPayment: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveCardAgainstGuest: EventEmitter<any> = new EventEmitter<any>();
  @Output() setRetailTokenId: EventEmitter<any> = new EventEmitter<any>();
  @Output() authorizedCard: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeCardList: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedIndex?: any;
  @Input() hideBorder = false;
  @Input() defaultPaymentType?: PaymentMethod;
  @Input() cardList: any;
  @Input() bookingCreditCard: any;
  @Input () guestLinkType?:GuestLinkType;
  PaymentReferenceID = 0;
  enableAuthorize: boolean;
  pmAgentEnable: boolean = true;
  selectedSendLinkOption: string;
  formCodes: FormCodeDTO[] = [];
  IsPrepayment: boolean = true;
  IsSkipPayment: boolean = false;
  showCardInformation: boolean = false;
  card:{
    cardNumber : string,
    cardExpiration : string,
    issuerType : string
    creditCardId : number 
  } = {
    cardNumber: '',
    cardExpiration: '',
    issuerType : '',
    creditCardId : null
  };

  constructor(public partyService: PartyService, public ts: TranslateService, private cs: CacheService, private rb: RetailPropertyInformation, private fb: FormBuilder, private settingsService: SettingsService) { }

  ngOnInit() {
    this.pmAgentEnable = sessionStorage.getItem("installedPMAgentVersion") != 'VERSION NOT DETECTED' ? true : false
    this.defaultPaymentType = Number(this.defaultPaymentType || PaymentMethod.Prepayment);
    this.partyService.IsSkipPayment = false;
    this.partyService.bookingPaymentType = this.defaultPaymentType;
    this.partyService.RetailCardTokenId = null;
    this.intializePaymentConfig();
    this.setPaymentValue(this.defaultPaymentType);
    this.intializesaveCardAgainstGuestForm();
    this.partyService.bookingPaymentType = this.defaultPaymentType;
    this.partyService.RetailCardTokenId = null;
    this.partyService.IsSkipPayment = false;

    this.checkFormCodes(() => {
      this.intializeLinkConfig();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.defaultPaymentType?.currentValue && this.paymentTypeForm?.form) {
      this.paymentTypeForm?.form?.get('PaymentMethod')?.setValue(changes.defaultPaymentType.currentValue);
    }
    if(changes?.bookingCreditCard?.currentValue){
      this.cardInfo([changes?.bookingCreditCard?.currentValue]);
    }
    if (changes?.guestLinkType?.currentValue) {
      if (changes.guestLinkType.currentValue !== GuestLinkType.None) {
        this.authorizeFormCode?.form?.get('SelectPaymentLink')?.setValue(changes?.guestLinkType.currentValue);
        if (this.cardList?.length > 0) {
          this.cardInfo(this.cardList);
        }
      }
    }
  }

  ngAfterViewInit() {
    this.saveCardAgainstGuestForm?.form?.valueChanges.subscribe(saveCardValue => {
      this.saveCardAgainstGuest.emit(saveCardValue.saveCardToPayer);
    });
    this.paymentTypeForm.form.valueChanges.subscribe((changes) => {
      if (changes) {
        this.setPaymentValue(changes.PaymentMethod);
        this.sendLinkOptionsConfig[0].options = this.enableDropdown();
      }
    });

    this.authorizeFormCode.form.valueChanges.subscribe(changes => {
      if (changes.SelectPaymentLink) {
        this.partyService.selectedSendLinkOption = changes.SelectPaymentLink;
        this.partyService.IsSkipPayment = true;
        this.showCardInformation = false;
        this.removeDetails(null);
        this.partyService.paymentMethodChanges$.next(true);
      }else {
        this.partyService.selectedSendLinkOption =  GuestLinkType.None;
        this.partyService.IsSkipPayment = false;
        this.showCardInformation = true;
        this.partyService.paymentMethodChanges$.next(true);
      }
      if(this.paymentTypeForm.form.get('PaymentMethod').value  == PaymentMethod.Authorize || this.paymentTypeForm.form.get('PaymentMethod').value  == PaymentMethod.SkipPayment) {
        this.partyService.IsSkipPayment = true;
      }
    })
    setTimeout(() => {
      this.setPayment.emit(this.partyService.bookingPaymentType);
      this.partyService.paymentMethodChanges$.next(true);
    }, 500)
  }

  intializesaveCardAgainstGuestForm() {
    this.saveCardAgainstGuestConfig = [
      {
        type: 'checkbox',
        placeholder: this.ts.instant("SaveCardToPayerProfile"),
        value: false,
        name: 'saveCardToPayer',
        cellClick: this.saveCardAgainstGuestInfo.bind(this)
      }
    ]
  }

  cardInfo(cardData) {
    if (cardData?.length) {
      if((this.partyService.bookingPaymentType == PaymentMethod.Authorize)||(this.paymentTypeForm?.value?.PaymentMethod == PaymentMethod.Authorize)){
        this.bookingCreditCard = {
          cardExpiration: cardData[0]?.expirationYearMonth ?? cardData[0]?.cardExpiration,
          cardNumber: cardData[0]?.cardNumber,
          issuerType: cardData[0]?.issuerType,
          creditCardId : cardData[0]?.creditCardId
        }
      }else{
       // this.bookingCreditCard = null
        this.setRetailTokenId.emit(null)
      }
      
      let creditCard: CreditCardDTO = {
        ContactId: null,
        CreditCardId: cardData[0]?.creditCardId,
        CardHolder: cardData[0].cardHolderName,
        CardIssuer: cardData[0].issuerType, //card
        MaskedCardNumber: cardData[0].cardNumber,
        ExpiryDate: cardData[0]?.expirationYearMonth ?? cardData[0].cardExpiration,
        PaymentTokenId: cardData[0]?.PaymentTokenId || null,
        Status: Status.Approved,
        IsDefault: false,
        Token: cardData[0].token,
        TokenExpirationDate: cardData[0].tokenExpirationDate,
      }
      this.authorizedCard.emit(creditCard);
      
    }
  }

  enableDropdown() {
    let availableGuestLinkTypes = this.getAvailableGuestLinkTypes();
    let options = [
      { id: GuestLinkType.None, value: this.ts.instant('None') },
      { id: GuestLinkType.LeadBooker, value: this.ts.instant('LeadBooker') },
      { id: GuestLinkType.IndividualGuest, value: this.ts.instant('IndividualGuest') },
      { id: GuestLinkType.AllGuests, value: this.ts.instant('AllGuests') }
    ]
    options = options.filter(option => availableGuestLinkTypes.includes(option.id));
    options.push({id:GuestLinkType.None , value: this.ts.instant('None')});
    return options || [];
  }

  setPaymentValue(_paymentMethod) {
    this.saveCardAgainstGuest.emit(false);

    if (_paymentMethod == PaymentMethod.Authorize || _paymentMethod == PaymentMethod.SkipPayment) {
      this.partyService.IsSkipPayment = true;
    } else {
      this.partyService.IsSkipPayment = false;
    }
    this.partyService.bookingPaymentType = _paymentMethod;
    if (_paymentMethod == PaymentMethod.Authorize) {
      this.enableAuthorize = true;
    } else {
      this.enableAuthorize = false;
      //    this.partyService.RetailCardTokenId = null;
    }

    this.IsPrepayment = _paymentMethod === PaymentMethod.Prepayment;


    this.setPayment.emit(this.partyService.bookingPaymentType);

    if(this.cardList?.length){
      let _filteredCard = getMappedCreditCard(this.cardList, null, null);
      this.bookingCreditCard = {
        cardExpiration:_filteredCard?.ExpiryDate,
        cardNumber:_filteredCard?.MaskedCardNumber,
        issuerType:_filteredCard?.CardIssuer ? _filteredCard?.CardIssuer : _filteredCard?.issuerType,
        creditCardId:_filteredCard?.CreditCardId,
      }

      let creditCard: CreditCardDTO = {
        ContactId: null,
        CreditCardId: _filteredCard?.CreditCardId,
        CardHolder: _filteredCard?.CardHolder,
        CardIssuer: _filteredCard?.CardIssuer, //card
        MaskedCardNumber: _filteredCard?.MaskedCardNumber,
        ExpiryDate: _filteredCard.expirationYearMonth,
        PaymentTokenId: _filteredCard?.PaymentTokenId,
        Status: Status.Approved,
        Token: _filteredCard?.token,
        TokenExpirationDate: _filteredCard?.tokenExpirationDate,
        IsDefault: false
      }
      this.authorizedCard.emit(creditCard);
    }else{
      this.bookingCreditCard = null
      this.setRetailTokenId.emit(null)
    }

    this.partyService.paymentMethodChanges$.next(true);
  }

  intializePaymentConfig() {
    let paymentOptions = [];
    paymentOptions.push({ id: PaymentMethod.Prepayment, value: this.ts.instant('prepayment') });

    if (!this.cs.isIframeEnabled) {
      paymentOptions.push({ id: PaymentMethod.Authorize, value: this.ts.instant('AuthorizeandPayLater'), disable: !this.pmAgentEnable && !this.rb.SkipPMAgent })
    }
    paymentOptions.push({ id: PaymentMethod.SkipPayment, value: this.ts.instant('SkipPayment') });

    this.paymentConfig = [
      {
        type: 'radio',
        name: 'PaymentMethod',
        placeholder: '',
        options: paymentOptions,
        value: this.defaultPaymentType || PaymentMethod.Prepayment,
        class: 'activity-headlines__session-selection',
        disabled: false,
      }
    ];


  }

  intializeLinkConfig() {
    this.sendLinkOptionsConfig = [
      {
        type: 'select',
        name: 'SelectPaymentLink',
        options: this.enableDropdown(),
        label: this.ts.instant('SendLink'),
        // cellClick: this.setLinkType.bind(this), 
        value: this.getDefaultGuestLinkType(),
        class: 'activity-headlines__session-selection',
        disabled: false,
      }
    ];
  }


  SaveReferenceId(tokenId) {
    if (tokenId) {
      this.PaymentReferenceID = tokenId;
      this.partyService.RetailCardTokenId = tokenId;
      this.setRetailTokenId.emit(tokenId);
      this.partyService.paymentMethodChanges$.next(true);
    }

  }

  saveCardAgainstGuestInfo(saveGuest){
    this.saveCardAgainstGuest.emit(saveGuest.checked);
  }
  removedCard: any[] = [];
  removeDetails(cardData) {
    this.removedCard.push(cardData);
    this.PaymentReferenceID = 0;
    this.setRetailTokenId.emit(null);
    this.removeCardList.emit();
    this.bookingCreditCard = null;
    this.partyService.paymentMethodChanges$.next(true);
  }

  checkFormCodes(callback: Function) {
    // Check if formCodes is undefined or empty before making the API call
    if (!this.formCodes || this.formCodes.length === 0) {
      this.settingsService.getFormCodes(this.cs?.settings?.value?.General?.MerchantId).subscribe(response => {
        if (response.Payload) {
          this.formCodes = response.Payload as FormCodeDTO[];
          callback();
        }
      });
    } else {
      console.log("Form codes already loaded, skipping API call.");
      callback();
    }
  }

  getDefaultGuestLinkType(): GuestLinkType {
    let availableGuestLinkTypes = this.getAvailableGuestLinkTypes();
    if (availableGuestLinkTypes.length === 0) {
      this.showCardInformation = true;
      return GuestLinkType.None;
    }
    let specialMealItem = this.cs.specialMealListForMerchant.find(item => item.Id === this.partyService.selectedSpecialMealId);
    let defaultGuestLinkType = specialMealItem ? specialMealItem.GuestLinkType : GuestLinkType.None;
    this.partyService.selectedSendLinkOption = defaultGuestLinkType;
   this.showCardInformation = this.partyService.selectedSendLinkOption == GuestLinkType.None;
    return availableGuestLinkTypes.includes(defaultGuestLinkType)
      ? defaultGuestLinkType
      : GuestLinkType.None;
  }

  getAvailableGuestLinkTypes(): GuestLinkType[] {
    let filteredFormCodes = this.formCodes.filter(fc =>
      ((this.IsPrepayment && fc.FormType === FormType.Prepayment) ||
        (this.enableAuthorize && fc.FormType === FormType.AuthorizeAndPay)) &&
      fc.Status === true
    );

    return filteredFormCodes.map(fc => fc.GuestLinkType);
  }


}
