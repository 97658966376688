import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CacheService } from '@app/core/services/cache.service';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { PopupService } from '@app/popup-module/popup.service';
import { ComponentTypes, PaymentMethod } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-payment-confirmation-popup',
  templateUrl: './payment-confirmation-popup.component.html',
  styleUrls: ['./payment-confirmation-popup.component.scss']
})
export class PaymentConfirmationPopupComponent implements OnInit {

  @Input() data;
  confirmationMessage: string;
  dialogTitle: string;
  showAlert: boolean = false;
  modifyDetails: any;
  paymentOptionsConfig: FieldConfig[] = [];
  @ViewChild('paymentType', { static: true }) paymentTypeForm: DynamicFormComponent;
  chosenPaymentType: number;
  isCancelled: boolean = false;

  constructor(private cs: CacheService,private partyService: PartyService,private ts: TranslateService,private popupService: PopupService, public dialogRef: MatDialogRef<PaymentConfirmationPopupComponent>) { }

  ngOnInit(): void {
    this.dialogTitle = this.data[0].dialogTitle;
    this.confirmationMessage = this.data[0].confirmationMessage;
    this.showAlert = this.data[0].showAlert ? this.data[0].showAlert : false;
    this.modifyDetails = this.data[0].request?.requestObj?.modifyDetails;
    this.paymentOptionsConfig = [
      {
        type: 'radio',
        name: 'PaymentMethod',
        placeholder: '',
        options: [
           { id: PaymentMethod.Prepayment,value : this.ts.instant('prepayment') },
           { id: PaymentMethod.SkipPayment, value: this.ts.instant('SkipPayment')}

        ],
        value: (this.data?.length &&  this.data[0]?.request?.requestObj?.PaymentMethod  == PaymentMethod.Prepayment) ?  PaymentMethod.Prepayment  : PaymentMethod.SkipPayment,
        class: 'activity-headlines__session-selection',
        disabled: false,
     }
   ];
    this.popupService.restrictCloseDialog = true;
    let cancelledSubscription = this.popupService.cancelledAction$.subscribe(data => {
      this.isCancelled = true;
      this.data[0].request.dialog?.close();
      this.dialogRef.close();
  })
    let confirmedSubscription = this.popupService.confirmedAction$.subscribe(data => {
      if(data != ComponentTypes.ModifySessionConfirmation) {
      let newamount = this.data[0].request.refund && this.data[0].request.refund.PartyPaymentType == 9 ? -this.data[0].request.refund.PaymentAmount : this.data[0].request.refund.PaymentAmount;
      if(this.chosenPaymentType == PaymentMethod.Prepayment) {
      if (this.data[0].request.requestObj && this.cs.settings.value.General.RetailIntegrationDTO.IsEnabled && this.cs.settings.value.RetailItems && this.cs.settings.value.RetailItems.length > 0) {
        this.data[0].request.requestObj.PaymentMethod = PaymentMethod.Prepayment;
        this.data[0].request.dialog?.close();
        if (this.data[0].request.financialData) {
          this.partyService.ReservationAttemptData = this.data[0].request.financialData;
        }
        this.partyService.RetailUpdateOperation(this.data[0].request.requestObj.PartyId, newamount, this.data[0].request.requestObj.refund, this.data[0].request.requestObj.IsForStandbyReservations, this.data[0].request.financialData , this.data[0].request);

      }
      else {
        if (this.data[0].request.refund && this.data[0].request.requestObj.refund.RefundOption) {
          this.partyService.showRefundOptions(this.data[0].request.refund, data, this.data[0].request.requestObj, this.data[0].request.date, this.data[0].request.dialog, this.data[0].request.componentType);
        } else {
          this.partyService.updateRefundReservation(data, this.data[0].request.requestObj, this.data[0].request.date, this.data[0].request.dialog, this.data[0].request.date.IsCreditCardNeeded);
        }
      }
    }
    else {
      this.data[0].request.requestObj.PaymentMethod = PaymentMethod.SkipPayment;
      this.skipPayment();
    }
    }
      this.data[0].request.dialog?.close();
      this.dialogRef?.close();
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.popupService.restrictCloseDialog = false;
      if(this.partyService.slotLockId && this.isCancelled) {
        this.partyService.unlockSlot(this.partyService.slotLockId);
      }
      if(cancelledSubscription) {
        cancelledSubscription.unsubscribe();
      }
      if(confirmedSubscription) {
        confirmedSubscription.unsubscribe();
      }
    });
  }

  ngAfterViewInit() {
    this.paymentTypeForm.form.valueChanges.subscribe((changes) => {
      if(changes) {
        this.chosenPaymentType = changes.PaymentMethod;
      }
    })
  }

  skipPayment() {
    if (this.data[0].request.componentType == ComponentTypes.EditOpenBooking && !this.data[0].request.IsCreditCardNeeded) {
      this.partyService.confirmUpdateOpenBooking(this.data[0].request.requestObj, false, this.data[0].request.dialog, this.data[0].request.date);
    }
    else if(this.data[0].request.componentType == ComponentTypes.EditPrivateLessonBooking && !this.data[0].request.IsCreditCardNeeded) {
      this.partyService.confirmUpdatePrivateLessonBooking(this.data[0].request.requestObj, false, this.data[0].request.dialog, this.data[0].request.date);
    }
    this.data[0].request.dialog?.close();
    this.dialogRef.close();
  }

}
