import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityDetailsComponent implements OnInit {
  eventConfig:FieldConfig[] = [];
  dateConfig:FieldConfig[] = [];
  coverConfig:FieldConfig[] =[];
  coverTypeConfig:FieldConfig[] = [];
  membershipConfig:FieldConfig[] = [];
  constructor() { }

  ngOnInit(): void {
    this.setEventConfig();
    this.setDateConfig();
    this.setCoverConfig();
    this.setCoverTypeConfig();
    this.setMembershipConfig();
  }

  setCoverConfig(): void{
    let coverConfigOptions = [
      {name:'coverSize',placeholder:'coverSize',options:[],class:'activity-details__cover-size',isTranslate:true}    
    ]
    coverConfigOptions.forEach(data =>{
      this.coverConfig.push(this.generateDropdown(data.name,data.placeholder,data.options,data.class,data.isTranslate,0))
    })
  }

  setCoverTypeConfig():void{
    let coverTypeConfigOptions = [
      {name:'adult',placeholder:'Adult',options:[],class:'activity-details__adult',isTranslate:true},
      {name:'youth',placeholder:'Youth',options:[],class:'activity-details__youth',isTranslate:true},
      {name:'teen',placeholder:'Teen',options:[],class:'activity-details__teen',isTranslate:true}    
    ]
    coverTypeConfigOptions.forEach(data =>{
      this.coverTypeConfig.push(this.generateDropdown(data.name,data.placeholder,data.options,data.class,data.isTranslate,0))
    })
  }

  setMembershipConfig():void{
    let membershipConfigOptions = [
      {name:'eventName',placeholder:'eventName',options:[],class:'activity-details__membership',isTranslate:true}    
    ]
    membershipConfigOptions.forEach(data =>{
      this.membershipConfig.push(this.generateDropdown(data.name,data.placeholder,data.options,data.class,data.isTranslate,0))
    })
  }

  setDateConfig(): void{   
    this.dateConfig = [{
      type: 'date',
      name: 'forceDate',
      inputType: 'text',
      class: 'activity-details__from-date',
      appearance: false,
      value: new Date(),
      showNavigation: false,     
      disabled: false
    },
    {
      type: 'date',
      name: 'forceDate',
      inputType: 'text',
      class: 'activity-details__to-date',
      appearance: false,
      value: new Date(),
      showNavigation: false,    
      disabled: false
    }]
    }


  setEventConfig(): void{
    let eventConfigOptions = [
      {name:'eventName',placeholder:'eventName',options:[{id:0,value:"Adult Drill Session"},{id:1,value:"Adult Fitness Session"}],class:'activity-details__event-name',isTranslate:true},
      {name:'eventLocation',placeholder:'eventLocation',options:[{id:0,value:"Court 1"},{id:1,value:"Court 2"}],class:'activity-details__event-location',isTranslate:true},
      {name:'eventInstructor',placeholder:'selectInstructor',options:[{id:0,value:"Tom Parthy"},{id:1,value:"Jim Kale"}],class:'activity-details__event-instructor',isTranslate:true}
    ]
    eventConfigOptions.forEach(data =>{
      this.eventConfig.push(this.generateDropdown(data.name,data.placeholder,data.options,data.class,data.isTranslate,0))
    });
    }

  generateDropdown(name:string, placeholder:string, options:any[],customClass: string, isTranslate:boolean,value: any): FieldConfig{
    let dropDownData = {
      type: 'select',
      name: name,
      placeholder: placeholder,
      options: options,
      class:customClass,
      showErrorText: true,
      appearance: false,
      isTranslate: isTranslate,     
      value: value         
  }
    return dropDownData;
      
  }


}
