import { Injectable } from '@angular/core';
import { HttpServiceCall, HttpMethod } from './http-call.service';
import { BaseResponse } from '../../retail.modals';
import { Host } from '../globalsContant';
import { CMSPatronLookupRequest, CMSPlayerInfo ,CMSMultiplePatronLookupRequest,CMSMultiPlayerInfo} from 'src/app/common/Models/common.models';

@Injectable({ providedIn: 'root' })
export class PlayerInformationService {

    constructor(private http: HttpServiceCall) {
    }

    async InvokeServiceCallAsync<T>(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any, showError = false): Promise<T> {
        try {
            let result: BaseResponse<T> = await this.http.CallApiAsync<T>({
                host: domain,
                callDesc: route,
                method: callType,
                body: body,
                uriParams: uriParams,
                showError: showError
            });
            return result.result;
        } catch (ex) {

        }
    }

    async GetPlayerInformation(patronId: string, pinNumber: string = "", includePointsCompsDetails = false, showError = false, excludeFutureOffers = true, profitCenterId: Number = 0): Promise<CMSPlayerInfo> {
        return this.InvokeServiceCallAsync<CMSPlayerInfo>(
            'GetPatronInformation'
            , Host.payment,
            HttpMethod.Post
            , {}
            , <CMSPatronLookupRequest>{
                playerId: patronId
                , pinNumber: pinNumber
                , includePointsCompsDetails: includePointsCompsDetails
                , excludeFutureOffers: excludeFutureOffers
                , outletProfitCenter : profitCenterId
            }
            , showError
        );
    }
    async GetMultiplePlayerInformation(patronId: string[], pinNumber: string [], includePointsCompsDetails :boolean[], showError = false, excludeFutureOffers = true, profitCenterId: Number = 0): Promise<CMSMultiPlayerInfo> {
        return this.InvokeServiceCallAsync<CMSMultiPlayerInfo>(
            'GetPatronInformation'
            , Host.payment,
            HttpMethod.Post
            , {}
            , <CMSMultiplePatronLookupRequest>{
                playerId: patronId,
                pinNumber: pinNumber,
                includePointsCompsDetails: includePointsCompsDetails,
                excludeFutureOffers: excludeFutureOffers,
                outletProfitCenter: profitCenterId
            }
            , showError
        );
    }
}
