import { Injectable } from '@angular/core';
import { CommonApiRoutes } from '../common-route';
import { TenantManagementCommunication } from '../communication/services/tenantmanagement-communication-service';
import{ API } from 'src/app/common/shared/system-passcode.model'
import { NewPasswordDetail } from '../Models/common.models';


@Injectable({
    providedIn: 'root'
})
export class SystemPasscodeDataService {
    constructor(private _tenantManagement: TenantManagementCommunication) { }

    public async getAllSystemPasscodes(): Promise<API.SystemPasscode[]> {
        const response: API.SystemPasscode[] = await this._tenantManagement.getPromise({
            route: CommonApiRoutes.GetAllSystemPasscode
        });
        return response;
    }

    public async getSystemPasscodeByPasscodeTag(passcodeTag: string): Promise<API.SystemPasscodePrompt> {
        const response: API.SystemPasscodePrompt = await this._tenantManagement.getPromise({
            route: CommonApiRoutes.GetSystemPasscodeByPasscodeTag, uriParams: { passcodeTag }
        });
        return response;
    }

    public async updateSystemPasscodes(body: API.SystemPasscode[],isLoaderRequired :boolean=false): Promise<API.SystemPasscode[]> {
        const response: API.SystemPasscode[] = await this._tenantManagement.putPromise({
            route: CommonApiRoutes.UpdateSystemPasscodes,
            body: body
        },isLoaderRequired);
        return response;
    }

    public async ValidateSystemPasscode(validateSystemPasscodeRequest: API.ValidateSystemPasscodeRequest): Promise<boolean> {
        const response: boolean = await this._tenantManagement.putPromise({
            route: CommonApiRoutes.ValidateSystemPasscode,
            body: validateSystemPasscodeRequest
        });
        return response;
    }

    public async GetSystemPasscodeStatus(passcodeTag: string[]): Promise<API.SystemPassCodeStatus[]> {
        const response: API.SystemPassCodeStatus[] = await this._tenantManagement.putPromise({
            route: CommonApiRoutes.GetSystemPasscodeStatus,
            body: passcodeTag
        },false,true);
        return response;
    }

    public async createSystemPassCodeAudit(body: API.SystemPassCodeAudit): Promise<API.SystemPassCodeAudit> {
        const response: API.SystemPassCodeAudit = await this._tenantManagement.postPromise({
            route: CommonApiRoutes.CreateSystemPassCodeAudit,
            body: body
        });
        return response;
    }

    async GetEncryptionKey() : Promise<any>{  
        return await this._tenantManagement.getPromise({
            route: CommonApiRoutes.GetEncryptKey           
        });  
      }

    async ValidateUserPassword(credentials :NewPasswordDetail) :Promise<boolean>{
        const response:boolean = await this._tenantManagement.putPromise({
            route: CommonApiRoutes.VerifyEncryptedPassword,
            body: credentials
        });
        return response;
    }

    
}
