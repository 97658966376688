import { Component, OnInit } from '@angular/core';
import { FloorplanService } from '@app/shared/services/floorplan.service';

@Component({
  selector: 'app-editor-layout',
  templateUrl: './editor-layout.component.html',
  styleUrls: ['./editor-layout.component.scss']
})
export class EditorLayoutComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
