import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MenuSearchTitle, searchtitleenum, MenuSearchModel, MenuSearchData } from './menu-search.model';
import { Router } from '@angular/router';
import { MenuSearchBusiness } from './menu-search.business';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { UserAccessDataService } from 'src/app/common/dataservices/authentication/useraccess.data.service';
import { Localization } from 'src/app/common/localization/localization';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { EditMenuPopupComponent } from './edit-menu-popup/edit-menu-popup.component';
import { MatDialog } from '@angular/material/dialog'
import { MenuSearchDataService } from './menu-search.service';
import { routingMenuService } from 'src/app/common/components/menu/menu.service';
import { Product } from 'src/app/common/enums/shared-enums';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';



@Component({
  selector: 'app-menu-search',
  templateUrl: './menu-search.component.html',
  styleUrls: ['./menu-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuSearchBusiness, UserAccessBusiness, UserAccessDataService, MenuSearchDataService]
})
export class MenuSearchComponent implements OnInit, AfterViewInit {
  isDesignAuthor: boolean;
  filterData: any = [];
  searchGroupOptions: MenuSearchModel[];
  menuSearchForm: UntypedFormGroup;
  captions: any;
  @Output() onMenuSearch = new EventEmitter();
  @Output() OnMenuOptionSelected = new EventEmitter();
  @ViewChild('menuSearchText') menuSearchText: ElementRef;
  baseRoute = '/settings/enhancedInventory';
  floatLabelNever: string;
  EnableRetailIC: boolean;
  currentProductId: number;
  isPms = false;


  constructor(private _router: Router, private _menuSearchBusiness: MenuSearchBusiness, private _formBuilder: UntypedFormBuilder,private routeDataService: RouteLoaderService,
    private _userAccessBusiness: UserAccessBusiness, private _localization: Localization, public dialog: MatDialog,private _routingMenuService:routingMenuService, private utilities: CommonUtilities) { 
      this.floatLabelNever = this._localization.setFloatLabelNever;
      let propConfig = sessionStorage.getItem('propConfig') ? JSON.parse(sessionStorage.getItem('propConfig')) : null;
      this.EnableRetailIC = propConfig? (propConfig.EnableRetailIC == 'true'? true: false): null;
    }

  ngOnInit() {
    this.captions = this._localization.captions.common;
    this.isDesignAuthor = (this._localization.GetUserInfo('roleName') == 'Design Author') ? true : false;
    this.currentProductId = Number(this.utilities.GetPropertyInfo('ProductId'));
    this.isPms = this.currentProductId === Product.PMS;
    this.menuSearchForm = this._formBuilder.group({
      searchGroup: '',
    });
  }

  ngAfterViewInit() {
    if (this.menuSearchText && this.menuSearchText.nativeElement) {
      this.menuSearchText.nativeElement.focus();
    }
  }

  onInput(e) {
    const inputValue = e.target.value;
    if (inputValue.length > 3) {
      console.log(e.target.value);
    }
    this.onMenuSearch.emit();
  }

  public async filterGroup(value: string) {
    this.filterData = [];
    value = value ? value.toString() : '';
    value = value.trim();
    if (value.length >= 3) {
      this.searchGroupOptions = await this._menuSearchBusiness.menuSearch(value);
      this.searchGroupOptions = this.searchGroupOptions.map(t => {
        return {
          title: t.title,
          dataCollection: t.dataCollection.sort(this.sortMenu)
        }
      });
    } else if (value.length <= 2) {
      this.filterData = [];
      this.searchGroupOptions = [];
    }
  }

  sortMenu = (a, b) => {
    if (a.menuPosition < b.menuPosition) {
      return -1;
    }
    if (a.menuPosition > b.menuPosition) {
      return 1;
    }
    if (a.menuPosition == b.menuPosition) {
      if (a.value.toLocaleLowerCase() < b.value.toLocaleLowerCase()) {
        return -1;
      }
      if (a.value.toLocaleLowerCase() > b.value.toLocaleLowerCase()) {
        return 1;
      }
      return 0;
    }
  }

  async linkClicked(title: MenuSearchTitle, data: MenuSearchData, e) {
    data['text'] = data.value; // added for element Navigation purpose like all menu, all report menu
    this._routingMenuService.setselectedRoutingMenu(data);
    if (title.name == searchtitleenum.MENUSEARCH) {
      let result: UserAccessModel.BreakPointResult
      let routepathUrl;
      let productId = parseInt(this._localization.GetPropertyInfo('ProductId'));
      if (data.breakPointNo > 0) {
        result = await this._userAccessBusiness.getUserAccess(data.breakPointNo, true);
        if (result.isAllow || result.isViewOnly) {

          routepathUrl = data?.routePath?.split('/');
         if ((productId === Product.PMS || productId === Product.ACCOUNTING) && routepathUrl && routepathUrl.length > 0 && routepathUrl[1] == 'reports') {

            this._router.onSameUrlNavigation = 'reload';

          }
          let checkparent:boolean = !(data.linkedElement.length>0);
          this._router.navigate([data.routePath], { state: { ShowPopup: checkparent, data,frommenu:true} });
        }
      }
      //NEED TO REMOVE THIS CODE AFTER BREAKPOINT NO INSERTED IN DATABASE
      else {
        
        routepathUrl = data?.routePath?.split('/');
        if ((productId === Product.PMS || productId === Product.ACCOUNTING) && routepathUrl && routepathUrl.length > 0 && routepathUrl[1] == 'reports') {

          this._router.onSameUrlNavigation = 'reload';
        }
        let checkparent:boolean = !(data.linkedElement.length>0);
        this._router.navigate([data.routePath], { state: { ShowPopup: checkparent, data ,frommenu:true } });
      }
      if(!this.EnableRetailIC){
        if(data.routePath.includes(this.baseRoute)){
          const value = this.routeDataService.GetChildMenu(this.baseRoute);
          if(value.routePath == data.routePath){
            this._router.navigate([this.baseRoute], { state: { data : {parent :0 , child: 0, id:value.elementID}} });
          }
          value.linkedElement.forEach((x ,index)=>{
            if(x.routePath == data.routePath){
              this._router.navigate([this.baseRoute], { state: { data : {parent :index, child: index, id:x.elementID}} });
            }
            x.linkedElement.forEach((y ,yindex)=>{
              if(y.routePath == data.routePath){
                this._router.navigate([this.baseRoute], { state: { data : {parent :index, child: yindex, id:y.elementID}} });
              }
            });
          })
        }
      }
    }
    this.searchGroupOptions = [];
    this.OnMenuOptionSelected.emit();

  }

  valueMapper() {
    return '';
  }

  edit(value) {
     this.dialog.open(EditMenuPopupComponent, {
      width: '350px',
      height: '350px',
      data: {
        menuDetails: value
      },
      disableClose: true
    }).afterClosed().subscribe(result => {
      if (result) {
// result
      }
    });
  }
}
