import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsGraphHttpService } from '../common/ms-graph-http.service';
import { GraphServiceParams } from '../../Models/ms-graph-http.model';
import { Localization } from '../../localization/localization';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { StorageService } from '../../shared/services/session-utilization.service';

@Injectable({
    providedIn: 'root'
})
/**
 * Communication layer for Snc management micro service
 * HttpCalls can be overriden here
**/
export class MsGraphApiCommunication extends MsGraphHttpService {

    captions: any;
    constructor(
        propertyInfo: CommonPropertyInformation,
        localization: Localization,
        utilities: CommonUtilities,
        storageService: StorageService) {
        super(propertyInfo, localization, utilities, storageService);
    }

    exceptionHandle(error: any) {
        console.error(error);
        throw error;
    }

    public async getPromise<T>(params: GraphServiceParams, handleErr: boolean = true): Promise<T> {
        try {
            const response$: Promise<T> = super.getPromise<T>(params);

            // on error =>
            response$.catch(err => this.error(err, handleErr));

            // on success =>
            return await response$;
        } catch (e) {
            this.exceptionHandle(e);
        }
    }


    public async postPromise<T>(params: GraphServiceParams, handleErr: boolean = true): Promise<T> {
        try {
            const response$: Promise<T> = super.postPromise<T>(params);

            // on error =>
            response$.catch(err => this.error(err, handleErr));

            // on success =>
            return await response$;
        }
        catch (e) {
            this.exceptionHandle(e);
        }
    }

    public async putPromise<T>(params: GraphServiceParams, handleErr: boolean = true): Promise<T> {
        try {
            const response$: Promise<T> = super.putPromise<T>(params);

            // on error =>
            response$.catch(err => this.error(err, handleErr));

            // on success =>
            return await response$;
        } catch (e) {
            this.exceptionHandle(e);
        }
    }

    public async deletePromise<T>(params: GraphServiceParams, handleErr: boolean = true): Promise<T> {
        try {
            const response$: Promise<T> = super.deletePromise<T>(params);

            // on error =>
            response$.catch(err => this.error(err, handleErr));

            // on success =>
            return await response$;
        } catch (e) {
            this.exceptionHandle(e);
        }
    }

    public async openWebMailWithMessage(params: GraphServiceParams, handleErr: boolean = true): Promise<any> {
        try {
            const response$: Promise<any> = super.postPromise<any>(params);

            // on error =>
            response$.catch(err => this.error(err, handleErr));

            // on success =>
            return await response$;
        } catch (e) {
            this.exceptionHandle(e);
        }
    }



    private error(err: HttpErrorResponse, handleErr: boolean) {
        if (handleErr) {
            super.errorHandler(err);
        } else {
            throw err;
        }
    }


}

