import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { PopupService } from '@app/popup-module/popup.service';
import { FloorPlanImagesDto, InputImages } from '@app/shared/models/FloorPlanImageInputDto';
import { FloorplanService } from '@app/shared/services/floorplan.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-image-actions',
  templateUrl: './image-actions.component.html',
  styleUrls: ['./image-actions.component.scss']
})
export class ImageActionsComponent implements OnInit, OnDestroy {
  @Input() data: InputImages[] = [];
  selectedImages: InputImages[] = [];
  subscription: Subscription = new Subscription();
  constructor(private ps: PopupService, private fs: FloorplanService, private cs: CacheService) {
    this.ps.restrictCloseDialog = true;
    this.subscription.add(this.cs.restaurantImages.subscribe((images: FloorPlanImagesDto) => {
      this.selectedImages = _.cloneDeep(images.LayoutImages);
    }));
   }

  ngOnInit(): void {  }

  selectImage(selectedImage: InputImages) {
    this.ps.restrictCloseDialog = false;
    const isSelectedImageAvailable = this.fs.selectedImageIds.filter((imgId) => imgId === selectedImage.ImageId).length > 0;
    if (isSelectedImageAvailable) {
      selectedImage.isImageSelected = false;
      this.fs.selectedImageIds = this.fs.selectedImageIds.filter(imgId => imgId !== selectedImage.ImageId);
    } else {
      selectedImage.isImageSelected = true;
      this.fs.selectedImageIds.push(selectedImage.ImageId);
    }
    if (this.fs.selectedImageIds.length > 1) {
      this.ps.cancelledAction$.next(false);
      this.ps.saveBtnEnable$.next(false);
    } else if (this.fs.selectedImageIds.length === 1) {
      this.ps.cancelledAction$.next(false);
      this.ps.saveBtnEnable$.next(true);
    } else {
      this.ps.cancelledAction$.next(true);
      this.ps.saveBtnEnable$.next(false);
    }
  }

  ngOnDestroy() {
    this.fs.selectedImageIds = [];
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
