
  <div class="open-booking">

    <div class="open-booking__form-main">
      <ng-scrollbar>
        <div class="open-booking__date">
          <label class="open-booking__data-header seat-label" for="">{{'fromDate' | translate}}</label>
          <dynamic-form [config]="bookingFromDateConfig" #form="dynamicForm">
          </dynamic-form>
        </div>
        <div class="open-booking__date">
          <label class="open-booking__data-header seat-label" for="">{{'toDate' | translate}}</label>
          <dynamic-form [config]="bookingToDateConfig" #form="dynamicForm">
          </dynamic-form>
        </div>
        <div class="open-booking__location">
          <label class="open-booking__data-header seat-label" for=""> {{'location' | translate}}</label>
          <dynamic-form [config]="locationConfig" #form="dynamicForm">
          </dynamic-form>
        </div>

        <div class="guest-data__table-details" (click)="selectedTable($event)">
          <span class="icon-my_location_FILL0_wght400_GRAD0_opsz48"></span>
          <label for="" class="guest-data__table-label seat-label">{{'Choose Location' | translate}}</label>
        </div>
        <div class="guest-data__size">
          <size-mapping [coverTypes]="coverTypes" [bookingTypes]="bookingTypes" [maxSize]="maxSizeAllowed" [(size)]="sizeMapping" (sizeChange)="updateFormValidity()" [dataCovers]="data?.CoverTypeQuantities || []" [dataSize]="data?.Size"></size-mapping>
        </div>
        <div class="activity-booking__emailNotifications" *ngIf="_settings">
          <app-staff-notifications [settings]="_settings" [specialMealId]="null" [emailList]="data?.NotificationEmailIds" [SalesContactIds]="data?.SalesContactIds" (notificationEvent)="notificationEvents($event)"></app-staff-notifications>
        </div>
        <!-- <div class="open-booking__sales-contact" [style.height]="!showSalesContact ? '0' : 'auto'"
        [hidden]="!showSalesContact">
        <label class="open-booking__data-header seat-label" for="">{{'salesContact' | translate}}</label>
        <dynamic-form [config]="salesContactConfig" #salesContactForm="dynamicForm">
        </dynamic-form>
      </div> -->
      </ng-scrollbar>
    </div>
    <div *ngIf="displaySlots" class="open-booking__time-slot">
      <div class="allocation-types">
        <div class="allocation-type" *ngFor="let allocType of allocationCodes" [ngStyle]="{'border-color':allocType.color, 'color': allocType.color}">{{allocType.AllocationName}}</div>
      </div>
      <app-wait-time (slotUpdateEvent)="onSlotUpdate($event)" [isEditCheckInAllowed]="editCheckedInReservation" [isenableMultipleSlotSelection]="isMultipleSlotSelectionEnabled"></app-wait-time>
    </div>
  </div>

