import { Injectable, OnDestroy } from '@angular/core';
import { ChangeAction, OperationResultState, PartyState, PartyType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { ContactDTO, PartyNoteDTO, SettingsDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestaurantStateService implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  isStateUpdated: boolean = false;
  settings: SettingsDTO;

  constructor(public cs: CacheService, public ps: PartyService) {
    this.subscriptions.add(this.cs.settings.subscribe(settings => {
      if (!this.settings) {
        this.settings = settings;
      }
      if (settings && this.cs.state.value) {
        let serverMappingChanged = false;
        let existingServerList = settings.Servers.map(x => x.Id);
        this.cs.state.value.TableServerAssignments?.forEach(mapping => {
          if (mapping.ServerId && !existingServerList.includes(mapping.ServerId)) {
            this.cs.state.value.AutoServerAreas.splice(this.cs.state.value.AutoServerAreas.findIndex(s => s.ServerId == mapping.ServerId), 1);
            mapping.ServerId = null;
            serverMappingChanged = true;
            this.cs.layout.value.FloorPlans.forEach(plan => {
              var property = settings.PropertiesUnderMerchant.find(x => x.RestaurantName === settings.General.RestaurantName);
              if (property && Utilities._restaurantId == property.RestaurantId && plan.StandaloneTables.findIndex(table => table.Id == mapping.StandaloneTableId) > -1) {
                plan.StandaloneTables.filter(table => table.Id == mapping.StandaloneTableId)[0].ServerId = null;
              }
            })
          }
        });
        this.ps.changedServerIds = [];
        if (!serverMappingChanged && this.settings && this.settings.Servers) {
          let changes = _.differenceBy(this.settings.Servers, settings.Servers, 'Name');
          serverMappingChanged = changes.length > 0;
          this.ps.changedServerIds = changes.map(server => server.Id);
        }
        this.settings = settings;

        if (serverMappingChanged) {
          this.cs.layout.next(this.cs.layout.value);
          this.cs.state.next(this.cs.state.value);
        }
      }
    }));
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  processPartyInState(change: ObjectChange, mapMissedProperties, propertyId) {
    let currentState = this.cs.state.value; //_.cloneDeep(this.cs.state.value);
    if (propertyId !== Utilities.RestaurantId()) {
      currentState = this.cs.propertySettings.value[propertyId].state;
    }
    if (currentState.WalkIns && currentState.WalkIns.length > 0 && currentState.WalkIns.find(p => p.Id == change.ObjectId)) {
      const partyDetails = currentState.WalkIns.find((party) => party.Id === change.ObjectId);
      if (partyDetails) {
        change.PropertyChanges.forEach(property => {
          const isObject = _.isObject(partyDetails[property.PropertyName.replace('Internal', '')]);
          mapMissedProperties(property, partyDetails);
          if (Object.getOwnPropertyNames(partyDetails).includes(property.PropertyName.replace('Internal', ''))) {
            partyDetails[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
          }
        });

        this.updatePartyInState(partyDetails, change, propertyId);
        return;
      }
    }
    else if (currentState.SeatingParties && currentState.SeatingParties.length > 0 && currentState.SeatingParties.find(p => p.Id == change.ObjectId)) {
      const partyDetails = currentState.SeatingParties.find((party) => party.Id === change.ObjectId);
      if (partyDetails) {
        change.PropertyChanges.forEach(property => {
          const isObject = _.isObject(partyDetails[property.PropertyName.replace('Internal', '')]);
          mapMissedProperties(property, partyDetails);
          if (Object.getOwnPropertyNames(partyDetails).includes(property.PropertyName.replace('Internal', ''))) {
            partyDetails[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
          }
        });

        this.updatePartyInState(partyDetails, change, propertyId);
        return;
      }
    }
    else if (currentState.RecentlyLeftParties && currentState.RecentlyLeftParties.length > 0 && currentState.RecentlyLeftParties.find(p => p.Id == change.ObjectId)) {
      const partyDetails = currentState.RecentlyLeftParties.find((party) => party.Id === change.ObjectId);
      if (partyDetails) {
        change.PropertyChanges.forEach(property => {
          const isObject = _.isObject(partyDetails[property.PropertyName.replace('Internal', '')]);
          mapMissedProperties(property, partyDetails);
          if (Object.getOwnPropertyNames(partyDetails).includes(property.PropertyName.replace('Internal', ''))) {
            partyDetails[property.PropertyName.replace('Internal', '')] = isObject ? JSON.parse(property.Value) : property.Value;
          }
        });

        this.updatePartyInState(partyDetails, change, propertyId);
        return;
      }
    }
    //corresponding Party could not be found in State, So fetching from Api
    let objState = change.PropertyChanges.filter(property => property.PropertyName.replace('Internal', '') == 'State');
    if (change.Action == ChangeAction.Updated && objState && objState.length > 0 && (objState[0].Value == PartyState.Pending || objState[0].Value == PartyState.Seated)) {
      this.ps.getPartyInfo(change.ObjectId).subscribe(result => {
        if (result && result.State == OperationResultState.Success && result.Payload && result.Payload.Type == PartyType.WalkIn) {
          let currentState = this.cs.state.value; //_.cloneDeep(this.cs.state.value);
          if (!currentState.WalkIns) {
            currentState.WalkIns = [];
          }
          currentState.WalkIns.push({ ...result.Payload });
          //this.cs.state.next(currentState);
          this.isStateUpdated = true;
        }
        else if (result && result.State == OperationResultState.Success && result.Payload && result.Payload.State == PartyState.Seated) {
          if (!currentState.SeatingParties) {
            currentState.SeatingParties = [];
          }
          currentState.SeatingParties.push({ ...result.Payload });
          this.cs.propertySettings.value[propertyId].state = currentState;
          this.isStateUpdated = true;
        }
      });
    }

  }

  updatePartyInState(partyDetails, change: ObjectChange, propertyId = Utilities.RestaurantId()) {
    let objState = change.PropertyChanges.filter(property => property.PropertyName.replace('Internal', '') == 'State');
    if (objState && objState.length > 0) {
      if (objState[0].Value == PartyState.Left) {
        this.addPartyToRecentlyLeft(partyDetails, propertyId)
      }
      else if (objState[0].Value == PartyState.Seated) {
        this.addPartyToSeating(partyDetails, propertyId);
      }
      else if (objState[0].Value == PartyState.Pending) {
        this.addPartyToState(partyDetails, propertyId);
      }
      else if ((objState[0].Value == PartyState.NoShowed || objState[0].Value == PartyState.Cancelled) && Utilities.IsRestaurantVenue(this.cs.propertySettings.value[propertyId]?.settings.PropertyType)) {
        this.removePartyFromWaitlist(partyDetails, propertyId);
      }
    }
    else {
      this.updatePartyPropertiesInState(partyDetails, propertyId);
    }
  }

  addPartyToSeating(partyDetails, propertyId) {
    let currentState = this.cs.state.value; // _.cloneDeep(this.cs.state.value);
    if (propertyId !== Utilities.RestaurantId()) {
      currentState = this.cs.propertySettings.value[propertyId].state;
    }
    if (!currentState.SeatingParties) {
      currentState.SeatingParties = [];
    }

    if (currentState.SeatingParties && currentState.SeatingParties.length > 0 && currentState.SeatingParties.find(p => p.Id == partyDetails.Id)) {
      currentState.SeatingParties.splice(currentState.SeatingParties.findIndex(p => p.Id == partyDetails.Id), 1)
    }

    currentState.SeatingParties.push(partyDetails);

    if (currentState.WalkIns && currentState.WalkIns.length > 0 && currentState.WalkIns.find(p => p.Id == partyDetails.Id)) {
      currentState.WalkIns.splice(currentState.WalkIns.findIndex(p => p.Id == partyDetails.Id), 1)
    }

    if (currentState.RecentlyLeftParties && currentState.RecentlyLeftParties.length > 0 && currentState.RecentlyLeftParties.find(p => p.Id == partyDetails.Id)) {
      currentState.RecentlyLeftParties.splice(currentState.RecentlyLeftParties.findIndex(p => p.Id == partyDetails.Id), 1)
    }
    this.cs.propertySettings.value[propertyId].state = currentState;
    //this.cs.state.next(currentState);
    this.isStateUpdated = true;
  }

  addPartyToState(party, propertyId = Utilities.RestaurantId()) {
    let currentState = this.cs.state.value; //_.cloneDeep(this.cs.state.value);
    if (propertyId !== Utilities.RestaurantId()) {
      currentState = this.cs.propertySettings.value[propertyId].state;
    }
    if (!currentState.WalkIns) {
      currentState.WalkIns = [];
    }
    if (!currentState.SeatingParties) {
      currentState.WalkIns = [];
    }

    if (!currentState.RecentlyLeftParties) {
      currentState.RecentlyLeftParties = [];
    }

    if (currentState.WalkIns && currentState.WalkIns.length > 0 && currentState.WalkIns.find(p => p.Id == party.Id)) {
      currentState.WalkIns.splice(currentState.WalkIns.findIndex(p => p.Id == party.Id), 1)
    }
    else if (currentState.RecentlyLeftParties && currentState.RecentlyLeftParties.length > 0 && currentState.RecentlyLeftParties.find(p => p.Id == party.Id)) {
      currentState.RecentlyLeftParties.splice(currentState.RecentlyLeftParties.findIndex(p => p.Id == party.Id), 1)
    }
    else if (currentState.SeatingParties && currentState.SeatingParties.length > 0 && currentState.SeatingParties.find(p => p.Id == party.Id)) {
      currentState.SeatingParties.splice(currentState.SeatingParties.findIndex(p => p.Id == party.Id), 1)
    }

    if (party.State == PartyState.Seated) {
      currentState.SeatingParties.push(party);
    }
    else if (party.State == PartyState.Pending && party.Type == PartyType.WalkIn) {
      currentState.WalkIns.push(party);
    }
    else if (party.State == PartyState.Left) {
      currentState.RecentlyLeftParties.push(party);
    }
    this.cs.propertySettings.value[propertyId].state = currentState;
    //this.cs.state.next(currentState);
    this.isStateUpdated = true;
  }

  addPartyToRecentlyLeft(partyDetails, propertyId) {
    let currentState = this.cs.state.value; //_.cloneDeep(this.cs.state.value);
    if (propertyId !== Utilities.RestaurantId()) {
      currentState = this.cs.propertySettings.value[propertyId].state;
    }
    if (currentState.SeatingParties && currentState.SeatingParties.length > 0) {
      let partyUpdated = currentState.SeatingParties.filter(p => p.Id == partyDetails.Id)[0];
      if (partyUpdated) {
        currentState.SeatingParties.splice(currentState.SeatingParties.findIndex(p => p.Id == partyDetails.Id), 1);
      }
    }
    if (!currentState.RecentlyLeftParties) {
      currentState.RecentlyLeftParties = [];
    }
    currentState.RecentlyLeftParties.push(partyDetails);
    this.cs.propertySettings.value[propertyId].state = currentState;
    //this.cs.state.next(currentState);
    this.isStateUpdated = true;
  }

  removePartyFromWaitlist(partyDetails, propertyId) {
    let currentState = this.cs.state.value; //_.cloneDeep(this.cs.state.value);
    if (propertyId !== Utilities.RestaurantId()) {
      currentState = this.cs.propertySettings.value[propertyId].state;
    }
    if (currentState.WalkIns && currentState.WalkIns.length > 0) {
      let index = currentState.WalkIns.findIndex(p => p.Id == partyDetails.Id);
      if (index > -1) {
        currentState.WalkIns.splice(index, 1);
      }
    }
    this.cs.propertySettings.value[propertyId].state = currentState;
    //this.cs.state.next(currentState);
    this.isStateUpdated = true;
  }

  updatePartyPropertiesInState(partyDetails, propertyId) {
    let currentState = this.cs.state.value; //_.cloneDeep(this.cs.state.value);
    if (propertyId !== Utilities.RestaurantId()) {
      currentState = this.cs.propertySettings.value[propertyId].state;
    }
    if (currentState.WalkIns && currentState.WalkIns.length > 0 && currentState.WalkIns.find(p => p.Id == partyDetails.Id)) {
      currentState.WalkIns.splice(currentState.WalkIns.findIndex(p => p.Id == partyDetails.Id), 1, partyDetails)
    }
    else if (currentState.SeatingParties && currentState.SeatingParties.length > 0 && currentState.SeatingParties.find(p => p.Id == partyDetails.Id)) {
      currentState.SeatingParties.splice(currentState.SeatingParties.findIndex(p => p.Id == partyDetails.Id), 1, partyDetails)
    }
    else if (currentState.RecentlyLeftParties && currentState.RecentlyLeftParties.length > 0 && currentState.RecentlyLeftParties.find(p => p.Id == partyDetails.Id)) {
      currentState.RecentlyLeftParties.splice(currentState.RecentlyLeftParties.findIndex(p => p.Id == partyDetails.Id), 1, partyDetails)
    }
    this.cs.propertySettings.value[propertyId].state = currentState;
    //this.cs.state.next(currentState);
    this.isStateUpdated = true;

  }

  removePartyFromStateIfExisting(partyId, propertyId) {
    let currentState = this.cs.state.value; //_.cloneDeep(this.cs.state.value);
    if (propertyId !== Utilities.RestaurantId()) {
      currentState = this.cs.propertySettings.value[propertyId].state;
    }
    if (currentState.WalkIns && currentState.WalkIns.length > 0 && currentState.WalkIns.find(p => p.Id == partyId)) {
      currentState.WalkIns.splice(currentState.WalkIns.findIndex(p => p.Id == partyId), 1)
    }

    if (currentState.RecentlyLeftParties && currentState.RecentlyLeftParties.length > 0 && currentState.RecentlyLeftParties.find(p => p.Id == partyId)) {
      currentState.RecentlyLeftParties.splice(currentState.RecentlyLeftParties.findIndex(p => p.Id == partyId), 1)
    }

    if (currentState.SeatingParties && currentState.SeatingParties.length > 0 && currentState.SeatingParties.find(p => p.Id == partyId)) {
      currentState.SeatingParties.splice(currentState.SeatingParties.findIndex(p => p.Id == partyId), 1)
    }
    this.cs.propertySettings.value[propertyId].state = currentState;
    //this.cs.state.next(currentState);
    this.isStateUpdated = true;
  }

  processContactChange(change: ObjectChange, additionaldata, propertyId) {
    let currentState = this.cs.propertySettings.value[propertyId].state;

    let contact = null; // there may be scenarios where contact visits has to be updated
    if (currentState.SeatingParties && currentState.SeatingParties.length > 0 && currentState.SeatingParties.find(p => p.Contact && p.Contact.Id == change.ObjectId)) {
      contact = currentState.SeatingParties.filter(p => p.Contact && p.Contact.Id == change.ObjectId)[0].Contact;
    }
    else if (currentState.WalkIns && currentState.WalkIns.length > 0 && currentState.WalkIns.find(p => p.Contact && p.Contact.Id == change.ObjectId)) {
      contact = currentState.WalkIns.filter(p => p.Contact && p.Contact.Id == change.ObjectId)[0].Contact;
    }
    else if (currentState.RecentlyLeftParties && currentState.RecentlyLeftParties.length > 0 && currentState.RecentlyLeftParties.find(p => p.Contact && p.Contact.Id == change.ObjectId)) {
      contact = currentState.RecentlyLeftParties.filter(p => p.Contact && p.Contact.Id == change.ObjectId)[0].Contact;
    }

    if (contact) {
      if (additionaldata) {
        var isobject = additionaldata.length; // to check additional data is list or not
        if (isobject) {
          let additionalContactData = additionaldata.find(data => data.Id == change.ObjectId);
          if (additionalContactData) {
            contact = additionalContactData as ContactDTO;
          }
        }
        else {
          contact = additionaldata as ContactDTO;
        }
      }
      else {
        change.PropertyChanges.forEach(property => {
          if (Object.getOwnPropertyNames(contact).includes(property.PropertyName.replace('Internal', ''))) {
            contact[property.PropertyName.replace('Internal', '')] = property.Value;
          }
        });
      }

      if (currentState.SeatingParties && currentState.SeatingParties.length > 0 && currentState.SeatingParties.find(p => p.Contact && p.Contact.Id == change.ObjectId)) {
        currentState.SeatingParties.forEach(party => {
          if (party.Contact && party.Contact.Id == change.ObjectId) {
            party.Contact = contact
          }
        });
      }
      else if (currentState.WalkIns && currentState.WalkIns.length > 0 && currentState.WalkIns.find(p => p.Contact && p.Contact.Id == change.ObjectId)) {
        currentState.WalkIns.forEach(party => {
          if (party.Contact && party.Contact.Id == change.ObjectId) {
            party.Contact = contact
          }
        });
      }
      else if (currentState.RecentlyLeftParties && currentState.RecentlyLeftParties.length > 0 && currentState.RecentlyLeftParties.find(p => p.Contact && p.Contact.Id == change.ObjectId)) {
        currentState.RecentlyLeftParties.forEach(party => {
          if (party.Contact && party.Contact.Id == change.ObjectId) {
            party.Contact = contact
          }
        });
      }
      this.cs.propertySettings.value[propertyId].state = currentState;
      this.isStateUpdated = true;
    }
  }

  processNoteChange(change: ObjectChange, propertyId) {
    let currentState = this.cs.state.value; //_.cloneDeep(this.cs.state.value);
    if (propertyId !== Utilities.RestaurantId()) {
      currentState = this.cs.propertySettings.value[propertyId].state;
    }
    const partyId = change.PropertyChanges.find(property => property.PropertyName == 'PartyId').Value;
    if (currentState.SeatingParties && currentState.SeatingParties.length > 0 && currentState.SeatingParties.find(p => p.Id == partyId)) {
      change.Action == ChangeAction.Created ? this.addNoteInPartyList(currentState.SeatingParties.filter(p => p.Id == partyId)[0], change)
        : change.Action == ChangeAction.Updated ? this.updateNoteInPartyList(currentState.SeatingParties.filter(p => p.Id == partyId)[0], change)
          : this.removeNoteInPartyList(currentState.SeatingParties.filter(p => p.Id == partyId)[0], change)
    }
    else if (currentState.WalkIns && currentState.WalkIns.length > 0 && currentState.WalkIns.find(p => p.Id == partyId)) {
      change.Action == ChangeAction.Created ? this.addNoteInPartyList(currentState.WalkIns.filter(p => p.Id == partyId)[0], change)
        : change.Action == ChangeAction.Updated ? this.updateNoteInPartyList(currentState.WalkIns.filter(p => p.Id == partyId)[0], change)
          : this.removeNoteInPartyList(currentState.WalkIns.filter(p => p.Id == partyId)[0], change)
    }
    else if (currentState.RecentlyLeftParties && currentState.RecentlyLeftParties.length > 0 && currentState.RecentlyLeftParties.find(p => p.Id == partyId)) {
      change.Action == ChangeAction.Created ? this.addNoteInPartyList(currentState.RecentlyLeftParties.filter(p => p.Id == partyId)[0], change)
        : change.Action == ChangeAction.Updated ? this.updateNoteInPartyList(currentState.RecentlyLeftParties.filter(p => p.Id == partyId)[0], change)
          : this.removeNoteInPartyList(currentState.RecentlyLeftParties.filter(p => p.Id == partyId)[0], change)
    }

    //this.cs.state.next(currentState);
    this.cs.propertySettings.value[propertyId].state = currentState;
    this.isStateUpdated = true;
  }

  addNoteInPartyList(party, change: ObjectChange) {
    const partyNote: PartyNoteDTO = new PartyNoteDTO();
    change.PropertyChanges.forEach(property => {
      if (Object.getOwnPropertyNames(partyNote).includes(property.PropertyName.replace('Internal', ''))) {
        partyNote[property.PropertyName.replace('Internal', '')] = property.Value;
      }
    });
    party.Notes.push({ ...partyNote });
    party.Notes = _.uniqBy(party.Notes, 'Id');
  }

  updateNoteInPartyList(party, change: ObjectChange) {
    const updatedPartyNoteId = change.PropertyChanges.find(property => property.PropertyName == 'Id').Value;

    if (party) {
      const partyNote: PartyNoteDTO = party.Notes.find(note => note.Id == updatedPartyNoteId);
      change.PropertyChanges.forEach(property => {
        if (Object.getOwnPropertyNames(partyNote).includes(property.PropertyName.replace('Internal', ''))) {
          partyNote[property.PropertyName.replace('Internal', '')] = property.Value;
        }
      });
      party.Notes[party.Notes.indexOf(partyNote)] = partyNote;
      //partiesList[partiesList.indexOf(party)] = party;
    }
  }

  removeNoteInPartyList(party, change: ObjectChange) {
    const removedPartyNoteId = change.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
    // Cancelled reservation has to be removed from the list and from the screen
    if (party) {
      const partyNote: PartyNoteDTO = party.Notes.find(note => note.Id == removedPartyNoteId);
      if (partyNote) {
        party.Notes = party.Notes.filter(note => note.Id != removedPartyNoteId);
      }
      //partiesList[partiesList.indexOf(party)] = party;
    }
  }

  updateRecentyLeftPartiesList() {
    let currentRestaurantTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    if (this.cs.state.value.RecentlyLeftParties && this.cs.state.value.RecentlyLeftParties.length > 0) {
      let partiesToBeRemoved = this.cs.state.value.RecentlyLeftParties.filter(p => p.DepartureTime && moment(currentRestaurantTime).diff(moment(p.DepartureTime), "minutes") > this.cs.settings.value.General.MaxMinutesToReseatPartyAfterLeaving);
      if (partiesToBeRemoved && partiesToBeRemoved.length > 0) {
        partiesToBeRemoved.forEach(pr => {
          this.cs.state.value.RecentlyLeftParties.splice(this.cs.state.value.RecentlyLeftParties.findIndex(p => p.Id == pr.Id), 1);
        });
      }
    }
  }

}
