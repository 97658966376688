import { Component, Inject, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { SaveButtonObj, AgInputFieldConfig } from 'src/app/common/Models/ag-models';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { SystemPasscodeDataService } from '../../services/systempasscode.data.service';
import { PropertyInformation } from '../../shared/services/property-information.service';
import { API } from 'src/app/common/shared/system-passcode.model';
import { PasscodeTypes } from 'src/app/common/constants';
import { CryptoUtility } from '../../crypto.utility';
import { NewPasswordDetail } from '../../Models/common.models';
import { UTempDataUtilities } from '../../shared/shared/utilities/utempdata-utilities';


@Component({
  selector: 'app-passcode-alert',
  templateUrl: './passcode-alert.component.html',
  styleUrls: ['./passcode-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasscodeAlertComponent implements OnInit {

  data: any;
  captions: any;
  buttonObj: SaveButtonObj;
  managerAuthButtonObj: SaveButtonObj;
  quickIdButtonObj: SaveButtonObj;
  okButtonObj :SaveButtonObj
  passcodeForm: UntypedFormGroup;
  quickIdForm: UntypedFormGroup;
  managerAuthForm: UntypedFormGroup;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  passcodeInputConfig: AgInputFieldConfig;
  title:string;
  passcodeId :string;  
  commonCaptions: any;
  type:any;
  PasscodeTypesEnum = PasscodeTypes;
  floatLabel: string;
  headerDescription :string;
  description:string;
  overSellInfo: any[];
  bookingId: any;
  isAllowOversell:boolean = false;
  uTempDataPrimary: string;
  uTempDataSecondary: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<PasscodeAlertComponent>,
    private utils: CommonUtilities,
    private fb: UntypedFormBuilder,
    private localization: Localization, private SystemPasscodeDataService: SystemPasscodeDataService
    , private propertyInfo: PropertyInformation
    ,private crypto: CryptoUtility,
    private utempdatautils: UTempDataUtilities) {
    this.captions = this.localization.captions;
    this.commonCaptions = this.localization.captions.common
    this.data = data;
    this.passcodeId = this.data.passcodeInputConfig?.id;
    this.floatLabel = this.localization.setFloatLabel;
    this.type = (this.data.passcodeType && this.data.passcodeType>0)?
     this.data.passcodeType : 
     (this.data?.passcodeType == PasscodeTypes.Warning) ? PasscodeTypes.Warning : PasscodeTypes.Passcode;
     
    this.title = (this.type==PasscodeTypes.Passcode)? this.captions.pg_title_Passcode:(this.type==PasscodeTypes.QuickId)?this.captions.hdr_quickId : (this.type==PasscodeTypes.ManagerAuth)?this.captions.hdr_confirmAuth:(this.type ==PasscodeTypes.Warning)?this.captions.alertPopup.warning: this.captions.pg_title_Passcode;
     
    this.bookingId = data?.id??0;
    this.isAllowOversell = data?.isAllowOversell??false;
    if(data?.overSellInfo)
    {
      this.overSellInfo = data.overSellInfo;
    }else{
      this.overSellInfo =[];
    }

  }

  ngOnInit(): void {
    this.buttonObj = {
      customSaveText: this.data.buttonObj?.customSaveText ? this.data.buttonObj?.customSaveText : this.captions.btn_ok,
      customCancelText: this.data.buttonObj?.customCancelText ? this.data.buttonObj?.customCancelText : this.captions.btn_cancel,
      disabled: this.data.buttonObj?.disabled ? this.data.buttonObj?.disabled : true,
      isEdit: this.data.buttonObj?.isEdit ? this.data.buttonObj?.isEdit : false,
      automationId : "'Btn_passcode_passcodeBtn'"
    };
    this.quickIdButtonObj = {
      customSaveText: this.commonCaptions.btn_confirm,
      customCancelText: this.commonCaptions.btn_cancel,
      disabled: this.data.buttonObj?.disabled ? this.data.buttonObj?.disabled : true,
      isEdit: this.data.buttonObj?.isEdit ? this.data.buttonObj?.isEdit : false,
      automationId : "'Btn_passcode_quickIDBtn'"
    }
    this.managerAuthButtonObj = {
      customSaveText: this.commonCaptions.btn_approve,
      customCancelText: this.commonCaptions.btn_cancel,
      disabled: this.data.buttonObj?.disabled ? this.data.buttonObj?.disabled : true,
      isEdit: this.data.buttonObj?.isEdit ? this.data.buttonObj?.isEdit : false,
      automationId : "'Btn_passcode_managerAuthBtn'"
    }
    this.okButtonObj ={
      customSaveText:  this.captions.btn_yes,    
      customCancelText :this.captions.btn_no,
      disabled: false,
      isEdit: false,
      automationId : "'Btn_passcode_okBtn'"
    }
    this.formBuilder();
    if(this.type == PasscodeTypes.Passcode)
    this.initializeInput();
  if(this.type ==PasscodeTypes.QuickId)
  this.description=this.captions.lbl_enterQuickId;
  if(this.type ==PasscodeTypes.ManagerAuth)
  this.description="";
if(this.type==PasscodeTypes.Warning)
this.description =this.captions.lbl_warn_sure
  
  this.headerDescription=this.data.passcodeInputConfig.placeHolder ? this.data.passcodeInputConfig.placeHolder :"";
  this.uTempDataPrimary = this.utempdatautils.GetUTempData(3);
  this.uTempDataSecondary = this.utempdatautils.GetUTempData(1);
  }

  ngAfterViewInit() {
    if(this.type == PasscodeTypes.Passcode){
      let type = this.passcodeId ? this.passcodeId : "password";
      document.getElementById("passcodeMask").getElementsByTagName('input')[0].setAttribute("type", type);
    }
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  formBuilder() {
    this.passcodeForm = this.fb.group({
      passcode: ''
    });
    this.quickIdForm = this.fb.group({
      quickId: ['',Validators.required]
    })
    this.managerAuthForm = this.fb.group({
      username: ['',Validators.required],
      password: ['',Validators.required]
    })

    this.passcodeForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if(this.passcodeForm.controls.passcode.value)
      this.passcodeForm.markAsDirty();
      this.buttonObj.disabled = this.passcodeForm.controls.passcode.value == '' ? false : !(this.passcodeForm.valid && this.passcodeForm.dirty);
      this.buttonObj = { ...this.buttonObj };
    });
    this.passcodeForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if(this.passcodeForm.controls.passcode.value)
      this.passcodeForm.markAsDirty();
      this.buttonObj.disabled = this.passcodeForm.controls.passcode.value == '' ? false : !(this.passcodeForm.valid && this.passcodeForm.dirty);
      this.buttonObj = { ...this.buttonObj };
    });
    this.quickIdForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.quickIdButtonObj.disabled = !(this.quickIdForm.valid && this.quickIdForm.dirty);
      this.quickIdButtonObj = { ...this.quickIdButtonObj };
    });
    this.managerAuthForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.managerAuthButtonObj.disabled = !(this.managerAuthForm.valid && this.managerAuthForm.dirty);
      this.managerAuthButtonObj = { ...this.managerAuthButtonObj };
    });
  }

  async initializeInput() {
    this.passcodeInputConfig = {
      id: "passcodeMask",
      className: this.data.passcodeInputConfig.className ? this.data.passcodeInputConfig.className : 'ag_form-control--4',
      form: this.passcodeForm,
      formControlName: 'passcode',
      floatLabel: this.data.passcodeInputConfig.floatLabel ? this.data.passcodeInputConfig.floatLabel : '',
      placeHolderId: 'lbl_Passcode',
      placeHolder: this.data.passcodeInputConfig.placeHolder ? this.data.passcodeInputConfig.placeHolder : this.captions.lbl_Passcode,
      maxlength: this.data.passcodeInputConfig.maxlength ? this.data.passcodeInputConfig.maxlength : 30,
      showRequired: this.data.passcodeInputConfig.showRequired ? this.data.passcodeInputConfig.showRequired : true,
      errorMessageId: 'err_Passcode',
      errorMessage: this.data.passcodeInputConfig.errorMessage ? this.data.passcodeInputConfig.errorMessage : this.captions.err_Passcode,
      changeLabelOnEdit: this.data.passcodeInputConfig.changeLabelOnEdit ? this.data.passcodeInputConfig.changeLabelOnEdit : false,
      inputType: this.data.passcodeInputConfig.inputType ? this.data.passcodeInputConfig.inputType : '',
      automationId : "'Txt_passcode_passcode'"
    };
  }

  close() {
    this.dialogRef.close({ from: 'cancel'});
  }

    async save(arg) {

      let request : API.ValidateSystemPasscodeRequest = {
        passcodeTag: this.data.passcodeTag,
        userInput: this.passcodeForm.controls['passcode'].value,
        passcodeType : this.type,
        userName: (this.type==PasscodeTypes.ManagerAuth)?this.managerAuthForm.controls['username'].value:""
      };
  
      let isSuccess = await this.SystemPasscodeDataService.ValidateSystemPasscode(request);
  
      if (isSuccess || this.data.skipValidation) {

        if(!this.data.skipValidation){
          if(this.type !=PasscodeTypes.Passcode)
          this.passcodeForm.controls['passcode'].setValue("");
          this.savePassCodeAudit(this.passcodeForm.controls['passcode'].value);
        }

        this.dialogRef.close({
            from: 'save',
            data: this.passcodeForm.value
        });

      } else {
        if(this.type==PasscodeTypes.Passcode)
          this.utils.showAlert(this.captions.lbl_please_enter_the_Correct_Passcode, AlertType.AccessDenied, ButtonType.Ok, null);
          if(this.type==PasscodeTypes.QuickId)
          this.utils.showAlert(this.captions.lbl_please_enter_the_Correct_QuickId, AlertType.AccessDenied, ButtonType.Ok, null);
      }
    }


    async savePassCodeAudit(userEnteredPasscode: string)
    {
      let request : API.SystemPassCodeAudit = {
        id: 0,
        userId: Number(this.localization.GetsessionStorageValue('_userInfo', 'userId')),
        passcodeTag: this.data.passcodeTag,
        transactionTime: this.localization.convertDateTimeToAPIDateTime(this.localization.getCurrentDate()),
        propertyTransactionTime: this.localization.convertDateTimeToAPIDateTime(this.propertyInfo.CurrentDTTM),
        isMasterOverride: false,
        userInput: userEnteredPasscode
      };
      this.SystemPasscodeDataService.createSystemPassCodeAudit(request);
    }

    approve(event){
      var passcode =  this.managerAuthForm.controls['password'].value;
      var userName = this.managerAuthForm.controls['username'].value;
      this.CheckUseNameAndPassword(userName,passcode,event);

    }
    confirm(event){
      var passcode =  this.quickIdForm.controls['quickId'].value;
      this.passcodeForm.controls['passcode'].setValue(passcode);
      this.save(event);
    }
    ok(event){
      this.dialogRef.close({
        from: 'save',
        data: ""
      });
    }

CheckUseNameAndPassword(username,password,event)
{
  if(this.uTempDataPrimary && this.uTempDataSecondary)
  {
    let newPasswordDetail : NewPasswordDetail = { userName: username, newPassword: password, tenantId: this.utils.GetLocalStorageValue('propertyInfo', 'TenantId') , oldPassword :"",isPasswordEncrypted:true } ;
   newPasswordDetail.newPassword=this.crypto.EncryptString(password,this.uTempDataPrimary,this.uTempDataSecondary);
   this.SystemPasscodeDataService.ValidateUserPassword(newPasswordDetail).then(x=>{
    if(x==true){
      this.save(event);
    }else{
      this.utils.showAlert(this.captions.login.invaliduserIdLogin,AlertType.Warning, ButtonType.Ok);
    }
   })
  }
}

}
