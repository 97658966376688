<div class="image-actions">
  <ng-scrollbar>
    <div class="image-actions__display-images">
      <div class="image-actions__image-properties seat-border-color" *ngFor="let image of selectedImages; let i = index" (click)="selectImage(image)">
        <img [src]="'data:image/png;base64,'+image.bytes" alt="" class="image-actions__uploaded-image">
        <svg *ngIf="image.isImageSelected" class="checkmark image-actions__selected-tick" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      </div>
    </div>
  </ng-scrollbar>
</div>