<section [formGroup]="TopSpendersFormGrp" class="top-spender-report-container" autocomplete="off">

  <div>
    <app-retail-multi-select-dropdown *ngIf="ShowOutletSelection" [dropDownFilterData]="outlets" [dropDownControlname]="'outlets'"
      [dropDownName]=captions.Outlets (dropDownFrmControl)="getFormControlValue($event)">
    </app-retail-multi-select-dropdown>
  </div>

  <div class="">
    <mat-form-field class="form-groups w-100" [floatLabel]="floatLabel">
      <input matInput [placeholder]="captions.TopSpenders" type="number" min="1"  numMaxlength maxlength="6" formControlName="topSpenders">
    </mat-form-field>
  </div>

  <div class="">
    <mat-radio-group class="report-radio-group" formControlName="itemFilter">
      <mat-radio-button *ngFor="let data of radioFilter" class="report-radio-button w-100" [value]="data.id" [matTooltip]="data.name">
        {{data.name}}
      </mat-radio-button>
    </mat-radio-group>

  </div>

  <div *ngIf="TopSpendersFormGrp.value.itemFilter">
    <app-retail-multi-select-dropdown *ngIf="allCategoryGroups" [dropDownFilterData]="allCategoryGroups"
      [dropDownControlname]="'reportcategory'" [dropDownName]=captions.CategoryGroups
      (dropDownFrmControl)="getFormControlValue($event)" (categoryGroup)="getCategoryGroupValue($event)">
    </app-retail-multi-select-dropdown>
  </div>
  <div *ngIf="!TopSpendersFormGrp.value.itemFilter">
    <app-retail-multi-select-dropdown *ngIf="categories" [dropDownFilterData]="categories"
      [dropDownControlname]="'category'" [dropDownName]=captions.RetailCategories
      (dropDownFrmControl)="getFormControlValue($event)"></app-retail-multi-select-dropdown>
  </div>

  <div class="mb-3">
    <label class="LW14">{{captions.IncludeInactiveOutlets}}</label>
    <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleIncludeInactive($event[0], 'outlets')"
      [formControl]="TopSpendersFormGrp.controls['includeInactiveOutlets']"></app-retail-toggle-switch>

  </div>
  <div class="mb-3">
    <label class="LW14">{{captions.IncludeInactiveRetailCategories}}</label>
    <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleIncludeInactive($event[0], 'categoryGroups')"
      [formControl]="TopSpendersFormGrp.controls['includeInactiveCategoryGroups']"></app-retail-toggle-switch>

  </div>
  <div class="mb-3">
    <label class="LW14">{{captions.IncludeInactiveCategoryGroups}}</label>
    <app-retail-toggle-switch class="d-block" (changeToggleEvent)="toggleIncludeInactive($event[0], 'retailCategories')"
      [formControl]="TopSpendersFormGrp.controls['includeInactiveRetailCategories']"></app-retail-toggle-switch>

  </div>
</section>
