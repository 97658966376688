<div class="confirmation-messages-popup">
  <!-- <mat-dialog-content class="p-0 m-0 confirmation-popup__dialog-content"> -->
    <div *ngIf="showAlert" class="confirmation-messages-popup__show-alert">
      <i class="icon-warning seat-icon-color alert-icon"></i>
    </div>
    <div class="confirmation-messages-popup__apiresponsediv">
        <ul class="rs-mt-15">
      <li class="confirmation-messages-popup__message seat-alert-messages" *ngFor="let message of confirmationMessages;let i=index">{{message}}</li>
        </ul>
    </div>
</div>