import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { AppService } from '@app/app.service';
import { buttonTypes } from '@app/shared/constants/commonenums';
import { AuthenticationResultDTO } from '@app/shared/models/AuthenticationResultDTO';
import { ButtonValue, FieldConfig } from "@dynamicform/models/field-config.interface";
import { LoginFunction } from '@app/shared/utilities/login-function';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ISubscription, Subscription } from 'rxjs-compat/Subscription';
import { LoginService } from '../services/login.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { SignalrService } from '@app/shared/services/signalr.service';
import { PartyService } from '@app/shared/services/party.service';
import { LoaderService } from '../services/loader.service';
import { LoadMultiplePropertiesService } from '@app/shared/services/load-multiple-properties.service';
import { LocalStorage } from '@ng-idle/core';
import { CacheService } from '../services/cache.service';
import { CartUtilities } from '@app/common/components/menu/vcart/cart.utilities';
import { IcartDataService } from '@app/common/dataservices/icart.data.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { VCartMessageHandler } from '@app/common/components/menu/vcart/vcart.MessageHandler';
import { loginState } from '@app/shared/models/SignalRDto';
import { CartWindowMessageType } from '@app/common/components/menu/vcart/vcart.modal';


@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],encapsulation:ViewEncapsulation.None
})
export class WidgetComponent implements OnInit {
  loginSubscription: ISubscription;
  unlockSubscription: ISubscription;
  subscriptions: Subscription = new Subscription();
  pincode = '';
  currentresId = '';
  tempvalues = true;
  isLoaded = false;
  disableCart = false;
  restaurantList: any = [];
  standByListButton: ButtonValue;
  Activitiestimeline = false;
  propertyChangesInitiated: boolean = false;

  @HostListener('window:message', ['$event'])
  onMessage({ origin, data }) {
    if (data) {
      try {
        var messageData = JSON.parse(data);
        if (messageData.channel == 'VCART') {
          this.vcartMessageHandler.processWindowMessage(messageData);
        } else {
          // other window messages can be handled here
        }
      } catch (e) {
      }
      // other window messages can be handled here, if not JSON
    }
  }

  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(public ps: PartyService, public _as: AppService, public loginFn: LoginFunction, public translateService: TranslateService, public signalr: SignalrService,
    private ls: LoaderService, private loadProperties: LoadMultiplePropertiesService, private cs: CacheService, private cu: CartUtilities, private vcartMessageHandler: VCartMessageHandler, private IcartDataService: IcartDataService,) { }

  ngOnInit(): void {
    this.localStorageHandler();
    this.cs.isIframeEnabled = true;
    sessionStorage.setItem("isIframeEnabled", 'true');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const MerchantKey = urlParams.get('merchantkey');
    const Token = urlParams.get('token');
    this.currentresId = urlParams.get('restaurantid');
    this.pincode = urlParams.get('pincode');
    const sessionType = Utilities.getSessionStorageType()
    if (Token != null) {
      sessionStorage.setItem(`restaurantId${sessionType}`, this.currentresId);
      sessionStorage.setItem(`merchantPortalRedirectToken${sessionType}`, Token);
      sessionStorage.setItem(`MerchantKey${sessionType}`, MerchantKey);
    }
    if (MerchantKey && (Token || this.pincode)) {
      this.loginFn.retailLogin.retailIntilaize();
      this.standByListButton = {
        type: buttonTypes.actionSecondaryOrange,
        label: 'StandByList',
        disbaledproperity: false,
        customclass: 'action-bar__book-btn',
      };
      this.cs.state.subscribe((state) => {
        if (state) {
          if (Utilities.AnyVenue(this._as.PropertyType)) {
            this.Activitiestimeline = false;
          } else {
            this.Activitiestimeline = true;
          }
        }
      });
      this._as.iframeready.subscribe(date => {
        if (date) {
          Utilities.SetRetainSignalR('true');
          this.loadProperties.init();
          this.isLoaded = true;
          this.cu.pushMessageToParentWindow(CartWindowMessageType.IFrameLoaded);
          setTimeout(() => {
            this.signalr.init();
            this.setActiveItinerary();
          }, 1000)
        }
      });
      this.unlock(true);

    } else {
      alert("auth info not passed");
    }
    this.subscriptions.add(this.signalr.hubConnectionId$.subscribe(() => {
      this.signalr.switchScreen(null, 11, loginState.login)
    }));
  }
  ngAfterViewInit() {
    this.externalResourcesHandler();
  }
  localStorageHandler(){
    if(!JSON.parse(sessionStorage.getItem('initialStorageCleared'))){
      sessionStorage.setItem('initialStorageCleared' , JSON.stringify(true));
      localStorage.removeItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)
    }else{
      sessionStorage.removeItem('initialStorageCleared');
    }
  }
  setActiveItinerary() {
    let itineraries = JSON.parse(sessionStorage.getItem('CartItineraryList'));
    let selectedData = itineraries?.find(itinerary => itinerary.expanded);
    if (selectedData) {
      this.cs.VCartUpdatedData = selectedData;
      this.cs.VCartItineraryUpdated$.next({ Type: CartWindowMessageType.CartItinerarySelected, data: selectedData });
    }
  }
  externalResourcesHandler() {

    this._as.propertyChanged.pipe(debounceTime(100)).subscribe(data => {
      if (data && this.propertyChangesInitiated && this.cs.isIframeEnabled && this.cs.VCartUpdatedData?.cartPayload) {
        this.propertyChangesInitiated = false;
        let cart = JSON.parse(this.cs.VCartUpdatedData?.cartPayload.bookAPI.payload);
        this.cs.VCartItineraryUpdated$.next({ Type: CartWindowMessageType.CartItemEdited, data: cart.BookingInputs[0]?.CartItemId })
      }
    });

    //Edit cart operation emitter 
    this.IcartDataService.CartItemEdited.pipe(takeUntil(this.destroyed$)).subscribe(async x => {
      if (x && x.cartDetail) {
        this.cs.VCartUpdatedData = x.cartDetail;
        this.cs.VCartUpdatedData['primaryGuestUuId'] = x.cartItinerary?.primaryGuestUuId;
        let cart = JSON.parse(x.cartDetail.cartPayload.bookAPI.payload);
        this.propertyChangesInitiated = false;
        if (cart.BookingInputs[0]?.PropertyId == Utilities.RestaurantId()) {
          this.cs.VCartItineraryUpdated$.next({ Type: CartWindowMessageType.CartItemEdited, data: cart.BookingInputs[0]?.CartItemId })
        } else {
          this.propertyChangesInitiated = true;
          this.cs.propertyChangeEmitter$.next({ value: cart.BookingInputs[0]?.PropertyId });
        }

      }
    })

    //VCart Itinerary Select operation emitter
    this.IcartDataService.CartItinerarySelected.pipe(takeUntil(this.destroyed$)).subscribe(async x => {
      this.cs.VCartUpdatedData = x;
      this.cs.VCartItineraryUpdated$.next({ Type: CartWindowMessageType.CartItinerarySelected, data: x });
    });

    //VCart Itinerary remove operation emitter
    this.IcartDataService.CartItineraryRemoved.pipe(takeUntil(this.destroyed$)).subscribe(async x => {
      let itineraries = JSON.parse(sessionStorage.getItem('CartItineraryList'));
      if (!itineraries?.length || itineraries?.length == 1) {
        this.cs.VCartUpdatedData = null;
        this.cs.VCartItineraryUpdated$.next({ Type: CartWindowMessageType.CartItineraryRemoved, data: x });
      }
    })
    // this.IcartDataService.CartItemAdded.pipe(takeUntil(this.destroyed$)).subscribe(async cartPayload => { })
    // this.IcartDataService.CartItinerarySelected.pipe(takeUntil(this.destroyed$)).subscribe(s => { })
  }
  unlock(isinitialIzed?) {
    this.loginFn.unlock(isinitialIzed, null, null, true);
    this.loginIntiated();
    this.cu.vCartEnabled = '1';
  }





  loginIntiated() {
    this.subscriptions.add(this.loginFn.propListUpdated.subscribe((data) => {
      if (data?.RestaurantsAvailableForLogin?.length > 0) {
        this.restaurantList = _.sortBy(data.RestaurantsAvailableForLogin, x => x.Name.toLowerCase());
        let restId = parseInt(sessionStorage.getItem(`lastLoggedInRestaurantId${Utilities.getSessionStorageType()}`) ?? this.currentresId);
        const lastLoggedInRestaurant = this.restaurantList.find(r => r.Id === restId);
        if (restId && lastLoggedInRestaurant) {
          this.loginFn.setRestaurant({ Id: lastLoggedInRestaurant.Id, Name: lastLoggedInRestaurant.Name });
        }
      }
    }));
  }
ngOnDestroy() {
  sessionStorage.clear();
  localStorage.clear();
}

}


