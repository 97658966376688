<section class="ReportSection d-block h-100" [ngClass]="[AllowJasperReport?'jasperEnabled':'']" *ngIf = "!reportViewDisable">
    <div class="report-container-row" *ngIf="this.pages.length != 0">
      <div class="left-container">
        <div class="report-print" (click)="reportDownloadandPrint()"><i  aria-hidden="true" matTooltip="{{captions.Print}}"  class="material-icons">print</i></div>
        <div class="report-print" (click)="reportDownload()"><i  aria-hidden="true" [ngClass]="{'active-print': ifPrintable}"  matTooltip="{{captions.Save}}" class="material-icons">save</i></div>

        <div class="download-container LW12" *ngIf="this.pages.length != 0 && ifPrintable" [ngClass]="{'active-printable': ifPrintable}">
          <div *ngIf="!AllowJasperReport">
          <mat-radio-group class="radio-class" aria-label="Select an option">
            <mat-radio-button value="1" (click)="downloadReport('PDF')" >PDF</mat-radio-button>
            <mat-radio-button value="2" (click)="downloadReport('WORD')">WORD</mat-radio-button>
            <mat-radio-button value="3" (click)="downloadReport('EXCEL')">EXCEL</mat-radio-button>
            <!-- <mat-radio-button value="4" (click)="downloadReport('CSV')">CSV</mat-radio-button> -->
            <mat-radio-button value="4" (click)="downloadReport('RAWDATA')">REPORT DATA</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="AllowJasperReport">
          <ag-dropdown class="customJPR" [placeholder]="selectformat" floatLabel="never" [showEmptyOption]='false' [source]="formats" (selectionChange)="downloadReportData($event)" required> </ag-dropdown>
        </div>
        </div>
        
        <div *ngIf="isSearchAvailable" class="report-search">
          <input [ngClass]="{'add-width': ifInputShow}" [(ngModel)]="findText" (keyup)="searchtext(findText)" type="text" />
          <i  aria-hidden="true" *ngIf="!ifCancelBool" class="material-icons" matTooltip="{{captions.Search}}" (click)="searchContent()">search</i>
          <i  aria-hidden="true" *ngIf="ifCancelBool" class="material-icons" matTooltip="{{captions.Cancel}}" (click)="searchContent(); searchtext('')">cancel</i>
        </div>
    </div>
    <div class="right-container">
      <div class="report-prev" matTooltip="{{captions.Previous}}"  (click)="setPage(this.getPageNo('PREVIOUS'))"><i aria-hidden="true" [ngClass]="{'active-icon': ispageReference == 'PREVIOUS' }" class="material-icons">arrow_upward</i></div>
      <div class="report-next" matTooltip="{{captions.Next}}" (click)="setPage(this.getPageNo('NEXT'))"><i aria-hidden="true" [ngClass]="{'active-icon': ispageReference == 'NEXT' }" class="material-icons">arrow_downward</i></div>
      <div class="report-pagestart">
        <mat-form-field [floatLabel]="floatLabel" class="layout">
          <input matInput (scrollState)="setPage(pageNumber)"  (keyup.enter)="setPage(pageNumber)" (keyup)="validatePage(pageNumber)" inputtype="onlynumber,nonnegative" [(ngModel)]="pageNumber" (blur)="defaultFirstSelection(pageNumber)">
        </mat-form-field></div>
      <div class="report-pageend">{{this.pages.length - 1}}</div>
      <div class="report-zoomout" (click)="zoom('OUT')">
        <i  aria-hidden="true" [ngClass]="{'active-icon': isZoomReference == 'OUT' }" class="material-icons" matTooltip="{{captions.ZoomOut}}">
        remove
        </i>
      </div>
      <div class="report-zoomin" (click)="zoom('IN')">
      <i  aria-hidden="true" [ngClass]="{'active-icon': isZoomReference == 'IN' }" class="material-icons" matTooltip="{{captions.ZoomIn}}">
        add
      </i>
      </div>
    </div>
    </div>
    

    <div class="report d-flex justify-content-center" id="report" [ngClass] = "reportHTML ? 'bg-grey' : '' " *ngIf="reportHTML">
    <div class="textcenter-Web" id="textcenter-Web">
      <!-- // Activities - retail integration changes -->
            <ng-scrollbar    #scrollable (wheel)="moveToNextPage($event)" track="all">
        <div class="reportData" id="reportData" [innerHtml]="reportHTML" style="background: white" [ngClass]="{'Zoom13':AllowJasperReport}"></div>
            </ng-scrollbar>
        </div>
    </div>
    <div class="ag_display--none" id="jasperreportdata"></div>
<div class="h-100 position-relative" *ngIf="reportHTML == ''"  id="no-data">
    <div class="textcenter-Web text-align-center nodata">
          {{this.captions.NoRecordsToDisplay}}
        </div>
    </div>
</section>
