<!-- <div>
  <app-payee-info [payeeDetails]="payeeDetails"></app-payee-info>
</div> -->
<div class="payment-options" [ngClass]="{'hide-border': hideBorder}">
  <!-- Main payment options -->
  <dynamic-form [config]="paymentConfig" #paymentType></dynamic-form>
  <div class="send-link-container">
    <div class="send-link-row">
      
        <!-- Conditionally render the dropdown when SendLink is selected -->
        <div [hidden]="!(IsPrepayment || enableAuthorize)" class="margin_issue">
            <dynamic-form [config]="sendLinkOptionsConfig" #formCodeList></dynamic-form>
        </div>
    </div>
</div>

  <!-- Authorization section -->
  <div>
    <div *ngIf="enableAuthorize && showCardInformation && !bookingCreditCard" class='authorize'>
      <div style="width: 100%;">
        <app-retail-capture-card 
          [PaymentReferenceID]="PaymentReferenceID" (removeDetails)="removeDetails($event)"  (displayCardData)="cardInfo($event)"
          [isShowPMAgentValidationMessage]="false"
          (NewPaymentReferenceID)="SaveReferenceId($event)">
        </app-retail-capture-card>
      </div>    
    </div>
    <div *ngIf="bookingCreditCard && showCardInformation">
      <app-credit-card (removeDetails)="removeDetails($event)" [cardData]="this.bookingCreditCard"></app-credit-card>
      <dynamic-form *ngIf="!bookingCreditCard?.creditCardId" [config]="saveCardAgainstGuestConfig" #saveCardAgainstGuestForm></dynamic-form>
    </div>
  </div>
</div>
