import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { InstalledProduct, ProductInfo } from './ProductInfo';
import { buttonTypes } from '@app/shared/constants/commonenums';
import { ButtonValue } from '@app/shared/dynamicform/models/field-config.interface';
import { MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '@app/shared/services/dashboard.service';
import { ActionMode } from '@app/common/enums/shared-enums';

@Component({
  selector: 'app-application-information',
  templateUrl: './application-information.component.html',
  styleUrls: ['./application-information.component.scss']
})
export class ApplicationInformationComponent implements OnInit {
  @Output() handleClickEvent = new EventEmitter();
  private jsonUrl = 'assets/reserve-application-info.json'; // URL to the JSON file
  constructor(public dashboardService: DashboardService,private dialogRef: MatDialogRef<ApplicationInformationComponent>) { }
  selectedProduct: InstalledProduct = null;
  productInfo: ProductInfo | null = null;
  closeButton: ButtonValue;
  externalHelpButton: ButtonValue;
  downloadReleaseNotesButton: ButtonValue;

  ngOnInit(): void {
    this.setDefaultButtonConfig();
    this.getProductInfo();
  }
 getProductInfo() {
    this.dashboardService.applicationVersion().subscribe(info=>{
      this.productInfo = info.Payload;
      this.productInfo.ProductName = "Reserve";
      this.selectedProduct = info.Payload.CertifiedIntegratedProducts[0];
    });
  }
  downloadReleaseNotes(){
    this.dashboardService.downloadReleaseNotes().subscribe(blob=>{
     
      // Create a URL for the blob object
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'downloaded.pdf'; // Set the filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }

  setDefaultButtonConfig() {

    this.closeButton = {
      type: buttonTypes.actionSecondarySmall,
      label: 'OK',
      disbaledproperity: false,
      customclass: 'action-bar__book-btn ok_btn',
     
    };
    this.externalHelpButton = {
      type: buttonTypes.actionSecondarySmall,
      label: 'Help',
      disbaledproperity: false,
      customclass: 'action-bar__book-btn help_btn'
    };
    this.downloadReleaseNotesButton = {
      type: buttonTypes.actionSecondarySmall,
      label: 'Release_Notes',
      disbaledproperity: false,
      customclass: 'action-bar__book-btn help_btn'
    };
  }
  selectProduct(product: InstalledProduct): void {
    this.selectedProduct = product;
  }

  openExternalHelp(){
    window.open("help", "Agilysys_Reserve_Help");  
  }

  closePopup(){
    this.dialogRef.close();
  }
}
