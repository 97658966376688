import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notesAvailable'
})
export class NotesAvailablePipe implements PipeTransform {

  transform(attendee: any): boolean {
    return attendee?.Notes?.length > 0 || attendee?.BookingContacts?.some(contact => contact.Notes?.length > 0);
  }

}
