<div class="d-flex h-100">


    <div class="wristband-encode" *ngIf="wristbandInputDetail && data.encodingEnabled && encodeState !== 'completed' && encodeState != 'skipped'">
        <div class="wristband-encode__booking-detail">
            <h4 class="respos-h4 w-100"> {{'Booking Info' | translate}} </h4>

            <div class="wristband-encode__item-row">
                <div class="wristband-encode__item-label respos-ft-light">
                    {{'First Name' | translate}}
                </div>
                <div class="wristband-encode__item-value respos-h5">
                    {{wristbandInputDetail.KeyRequestDTO.GuestFirstName}}
                </div>
            </div>
            <div class="wristband-encode__item-row">
                <div class="wristband-encode__item-label respos-ft-light">
                    {{'Last Name' | translate}}
                </div>
                <div class="wristband-encode__item-value respos-h5">
                    {{wristbandInputDetail.KeyRequestDTO.GuestLastName}}
                </div>
            </div>
            <div class="wristband-encode__item-row">
                <div class="wristband-encode__item-label respos-ft-light">
                    {{'Party Size' | translate}}
                </div>
                <div class="wristband-encode__item-value respos-h5">
                    {{wristbandInputDetail.KeyRequestDTO.GroupSize}}
                </div>
            </div>
            <div class="wristband-encode__item-row">
                <div class="wristband-encode__item-label respos-ft-light">
                    {{'Reference#' | translate}}
                </div>
                <div class="wristband-encode__item-value respos-h5">
                    {{wristbandInputDetail.KeyRequestDTO.BookingReferenceId}}
                </div>
            </div>
            <div class="wristband-encode__item-row">
                <div class="wristband-encode__item-label respos-ft-light">
                    {{'startDate' | translate}}
                </div>
                <div class="wristband-encode__item-value respos-h5">
                    {{wristbandInputDetail.KeyRequestDTO.ItineraryStartDate | date: dateFormat}}
                </div>
            </div>
            <div class="wristband-encode__item-row">
                <div class="wristband-encode__item-label respos-ft-light">
                    {{'endDate' | translate}}
                </div>
                <div class="wristband-encode__item-value respos-h5">
                    {{wristbandInputDetail.KeyRequestDTO.ItineraryEndDate | date: dateFormat}}
                </div>
            </div>

        </div>

        <div class="wristband-encode__placeholder d-flex">
            <div class="wristband-encode__msg-container respos-h3" *ngIf="encodeState==='unknown'">
                {{'Please select device to proceed' | translate}}
                <br>
                <br>
            </div>
            <div class="wristband-encode__msg-container respos-h3"
                *ngIf="encodeState==='ready' || encodeState==='initiated'">
                <ng-container *ngIf="encodeState==='initiated'">
                    {{'Please tap the Wristband/Card one by one when device is ready.' | translate}}
                    <br> <br>
                </ng-container>
                <ng-container *ngIf="encodeState==='ready'">
                    {{'Device ready! Please tap Wristband/Card one by one.' | translate}}
                    <br>
                    <br>
                    {{'Once done, tap Associate Wristband/Card to the Reservation.' | translate}}
                </ng-container>
                <div class="lds-spinner" *ngIf="encodeState==='initiated'">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>

        <div class="d-flex wristband-encode__footer">
            <div class="wristband-encode__close">
                <button class="respos-btn respos-btn-outline" (click)="closeDialog()">
                    {{'Cancel' | translate}}
                </button>
            </div>
            <div class="wristband-encode__encoder-control" lib-input-select [disabled]="encodeState==='initiated'"
                [label]="'Select Device' | translate" [control]="deviceForm.controls['encoder']" [options]="deviceList">
            </div>
            <div class="wristband-encode__actions">
                
                <button *ngIf="encodeState==='unknown'" [disabled]="encodeState==='initiated'"
                    class="respos-btn respos-btn-fill ml-small" (click)="intiateEncode()">
                    {{'Initiate Encoding' | translate}} &nbsp;
                </button>
                <button class="respos-btn respos-btn-outline" *ngIf="encodeState==='initiated'"
                    (click)="cancelEncode()">
                    {{'Cancel Encoding' | translate}}
                </button>
                <div class="respos-separator"></div>
                <button *ngIf="encodeState!=='ready'"
                    class="respos-btn respos-btn-fill ml-small wristband-encode__skip-btn" (click)="skipEncode()">
                    {{'Skip Encoding' | translate}} &nbsp;
                </button>
                <button *ngIf="encodeState==='ready'" class="respos-btn respos-btn-fill"
                    (click)="startWristbandMapping()">
                    {{'Associate Wristband' | translate}} &nbsp;
                </button>

            </div>
        </div>
    </div>
    <iframe *ngIf="encodeState === 'completed' || encodeState === 'skipped' || !data.encodingEnabled" class="w-100 respos-wb-container"
        [src]="wristbandmappingurl"></iframe>
</div>