<div class="rate-plan p-2" *ngIf="!diningProperty || (diningProperty && ratePlanSummary && ratePlanSummary.Total > 0)">
<ng-scrollbar>
  <div *ngIf="ratePlanSummary" class="m-10">
    <h4 class="seat-header-three">{{'rateSummary' | translate}}</h4>
    <div class="alert alert-warning" role="alert" *ngIf="showTransactionError">
      {{'transactionWarning' | translate}}
    </div>
    <mat-accordian class="">
      <ng-container *ngFor="let reservation of ratePlanSummary.Reservations">
        <ng-container *ngFor="let session of reservation.Sessions">
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header class="rate-plan__panel-header mr-1">
              <mat-panel-title class="seat-header-four">
              <div class="rate-selection">
                <mat-checkbox class="example-margin" [disabled]="fromRateChanges" [(ngModel)]="session.select" [indeterminate]="session.indeterminate" 
                  (change)="selectAllGuest($event , session)" (click)="$event.stopPropagation()">
                </mat-checkbox>
                <div class="rate-selection-name">
                  {{session.TimeRange.Start | localizedDate : 'D MMMM, LT'}} - {{session.TimeRange.End |
                  localizedDate
                  : 'D MMMM, LT'}}
                </div>
              </div>
                <mat-panel-description class="justify-content-end m-0">
                  {{currencySymbol}} {{session.Total | currencyDecimalFormatter}}
                </mat-panel-description>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngTemplateOutlet="sessionData; context: { $implicit:session}"></ng-container>
          </mat-expansion-panel>
          <hr />
        </ng-container>
      </ng-container>
    </mat-accordian>
  <ng-container *ngTemplateOutlet="ratePlanSummaryTemplate; context: { $implicit:ratePlanSummary}">
    </ng-container>
    <ng-container *ngIf="enableEdit || fromRateChanges ">
      <ng-container *ngTemplateOutlet="reasonNegotiation"></ng-container>
      
      <div class="rate-plan__action-view pb-2 pt-3 d-flex align-items-center justify-content-between" style="flex-direction: row-reverse;">
       
        <div class="d-flex" *ngIf="this.dialogData.bookingData?.actionType != financialEffectAction.WaivedOff">
          <activities-app-button [buttontype]="applyNegotiationBtn" class="pr-2"(valueChange)='applyChanges()'></activities-app-button>
          <activities-app-button [buttontype]="cancelNegotiationBtn"(valueChange)='discardChanges()'></activities-app-button>
        </div>
        
      </div>

      <div class="rate-plan__payee-info-view pb-2 pt-3 d-flex justify-content-between">

        <app-payee-info class="payee-info" 
          (selectedPayeeCardInfo)="selectedPayeeCard($event)" 
          *ngIf="partyService.ratePlanObject"
          [ratePlanObject]="partyService.ratePlanObject" 
          [payeeDetails]="payingGuest" 
          [bookingData]="bookingData || this.dialogData?.bookingData?.party"
          [creditCards]="bookingData?.CreditCards?.length ?  bookingData?.CreditCards : this.dialogData.bookingData?.financialData?.ContactCreditCardList" 
          [bookingDataId]=" bookingData?.BookingId || this.dialogData?.bookingData?.party?.Id"
          [bookingCreditCard] ="this.bookingCreditCard">
        </app-payee-info>
        <ng-container *ngIf="this.dialogData.bookingData?.fromCheckInOrCheckout">
        <app-credit-card style="width: 100%" *ngIf="this.bookingCreditCard?.cardNumber" [showDeleteButton]="false" [cardData]="this.bookingCreditCard" (removeDetails)="removeDetails($event)"></app-credit-card>
      </ng-container>
      </div>
    
    </ng-container>

      <!-- Load Sessions-->
      <ng-template #sessionData let-session>
        <mat-expansion-panel class="rate-plan__panel" expanded *ngFor="let guestType of session.GuestPrices">
          <mat-expansion-panel-header class="rate-plan__panel-header">
            <mat-panel-title>
              <ng-container *ngTemplateOutlet="guestTypeTemplate; context: { $implicit:guestType , viewItems: false}">
              </ng-container>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngTemplateOutlet="guestTypeTemplate; context: { $implicit:guestType, viewItems: true}">
          </ng-container>
        </mat-expansion-panel>
      </ng-template>

      <!-- Reason For Negotiation-->
      <ng-template #reasonNegotiation>
        <div class="d-fles" class="rate-plan__reason-view pt-1">
          <div class="seat-grid-content pt-2">{{'ReasonForNegotiation' | translate}}</div>
          <dynamic-form class="w-100" [config]="rateNegotiateConfig" #negotiateReasonForm="dynamicForm">
          </dynamic-form>
        </div>
      </ng-template>

      <!-- Display RatePlan Summary -->
      <ng-template #ratePlanSummaryTemplate let-ratePlanTotal>
        <ng-container class="seat-header-four rate-plan__summary">
          <div class="seat-grid-content rate-plan__summary pointer" (click)="showNegDis = !showNegDis">
            <span class="tax-label">{{(enableEdit ? 'totalValue' : 'reservationAmount') | translate}}
              <span class="down-icon " [ngClass]="{'expandedView': showNegDis}"></span>
            </span>
            <span>{{currencySymbol}} {{ratePlanTotal.SubTotal | currencyDecimalFormatter}}</span>
          </div>
          <div *ngIf="showNegDis" class="seat-grid-content rate-plan__summary pl-3">
            <span>{{'negotiateValue' | translate}}</span>
            <span>{{currencySymbol}} {{(ratePlanTotal.Negotiated < 0 ? (ratePlanTotal.Negotiated * -1) :
                ratePlanTotal.Negotiated)| currencyDecimalFormatter}}</span>
          </div>


          <div class="rate-plan__vat-summary pl-3" *ngIf="ratePlanTotal.Taxes.length && taxType && showNegDis">
            <!--VAT-->
            <div class="seat-grid-content rate-plan__summary">
              <span class="tax-label">
                {{ "VAT" | translate}}
              </span>
              <span>{{currencySymbol}} {{ratePlanTotal.VAT | currencyDecimalFormatter}}</span>
            </div>
            <ng-container *ngTemplateOutlet="taxDataTemplate; context: { $implicit:ratePlanTotal.Taxes}">
            </ng-container>
          </div>

          <!-- VAT on Service Charge -->
          <div class="seat-grid-content rate-plan__summary" *ngIf="taxType">
            <span class="tax-label pointer">
              <span (click)="showSCDetails = !showSCDetails">{{ "serviceCharges" | translate}}</span>
              <span class="down-icon " (click)="showSCDetails = !showSCDetails"
                [ngClass]="{'expandedView': showSCDetails}"></span>
              <ng-container>
                <span [hidden]="!(fromRateChanges) && !enableEdit " class="pl-1"><dynamic-form [config]="recalculateConfig"
                    #recalculateForm="dynamicForm">
                  </dynamic-form></span>
              </ng-container>
            </span>
            <span>{{currencySymbol}} {{ratePlanTotal.ServiceCharge | currencyDecimalFormatter}}</span>
          </div>
          <div *ngIf="taxType && showSCDetails">
            <ng-container
              *ngTemplateOutlet="ServiceChargeDataTemplate; context: { $implicit:ratePlanTotal.ServiceCharges}">
            </ng-container>
          </div>


          <!-- Service Charge -->
          <div class="seat-grid-content rate-plan__summary" *ngIf="!taxType">
            <span class="service-charge-label pointer"> <span
                (click)="showSCDetails = !showSCDetails">{{'serviceCharges' | translate}}</span>
              <ng-container>
                <span class="down-icon" (click)="showSCDetails = !showSCDetails"
                  [ngClass]="{'expandedView': showSCDetails}"></span>
                <span [hidden]="!(fromRateChanges) && !enableEdit " class="pl-1"><dynamic-form [config]="recalculateConfig"
                    #recalculateForm="dynamicForm">
                  </dynamic-form></span>
              </ng-container>
            </span>
            <span>{{currencySymbol}} {{ratePlanTotal.ServiceCharge | currencyDecimalFormatter}}</span>
          </div>
          <span *ngIf="showSCDetails && !taxType">
            <ng-container
              *ngTemplateOutlet="ServiceChargeDataTemplate; context: { $implicit:ratePlanTotal.ServiceCharges}">
            </ng-container>
          </span>


          <span *ngIf="appliedOfferCode && appliedOfferCode.ApplyDiscount == applyDiscount.BeforeTax">
            <ng-container *ngTemplateOutlet="offerCode">
            </ng-container>
          </span>

          <!--Tax-->
          <div class="seat-grid-content rate-plan__summary" *ngIf="ratePlanTotal.Taxes.length && !taxType">
            <span class="tax-label pointer" (click)="showTaxDetails = !showTaxDetails">
              {{ "Tax" | translate}}
              <span class="down-icon " [ngClass]="{'expandedView': showTaxDetails}"></span>
            </span>

            <span>{{currencySymbol}} {{ratePlanTotal.Tax | currencyDecimalFormatter}}</span>
          </div>



          <span *ngIf="showTaxDetails && !taxType">
            <ng-container *ngTemplateOutlet="taxDataTemplate; context: { $implicit:ratePlanTotal.Taxes}">
            </ng-container>
          </span>


          <!-- Tax on Service Charge -->
          <div class="seat-grid-content rate-plan__summary" *ngIf="ratePlanTotal.TaxOnServiceChargeAmount && !taxType">
            <span class="tax-label">{{ "serviceChargesTaxWithPlural" | translate}}
            </span>
            <span>{{currencySymbol}} {{ratePlanTotal.TaxOnServiceChargeAmount | currencyDecimalFormatter}}</span>
          </div>
          <span *ngIf="appliedOfferCode && appliedOfferCode.ApplyDiscount == applyDiscount.AfterTax">
            <ng-container *ngTemplateOutlet="offerCode">
            </ng-container>
          </span>

          <ng-template #offerCode>
            <div class="d-flex justify-content-between seat-grid-content align-items-center" *ngIf="appliedOfferCode">
              <span class=" d-flex align-items-center offer-code-view">

                <span>{{'promo Code' | translate}}</span><span
                  class="offer-code s ml-1">{{appliedOfferCode.OfferCode}}</span>
                <span class="icon-cancel pointer" (click)="removeOffer()"></span></span>
              <span class="">{{currencySymbol}} {{ratePlanTotal.Discount | currencyDecimalFormatter}}</span>
            </div>
          </ng-template>


          <div class="seat-grid-content rate-plan__summary pt-1" *ngIf="ratePlanTotal.Paid > 0">
            <span class="">{{'paidAmount' | translate}}</span>
            <span class="">{{currencySymbol}} {{ratePlanTotal.Paid | currencyDecimalFormatter}}</span>
          </div>

          <div class="seat-grid-content rate-plan__summary pt-1">
            <span class="bold-font">{{'findTotal' | translate}}</span>
            <span class="bold-font">{{currencySymbol}} {{ratePlanTotal.Total | currencyDecimalFormatter}}</span>
          </div>

          <div class="seat-grid-content rate-plan__summary pt-1" *ngIf="ratePlanTotal.Unpaid > 0">
            <span class="">{{'AmountUnPaid' | translate}}</span>
            <span class="">{{currencySymbol}} {{ratePlanTotal.Unpaid | currencyDecimalFormatter}}</span>
          </div>

          <div class="seat-grid-content rate-plan__summary pt-1" *ngIf="ratePlanTotal.TotalRefund > 0">
            <span class="">{{'RefundAmount' | translate}}</span>
            <span class="">{{currencySymbol}} {{ratePlanTotal.TotalRefund | currencyDecimalFormatter}}</span>
          </div>
          

        </ng-container>
      </ng-template>


    <!-- Display Guest Details -->
    <ng-template #guestTypeTemplate let-guestType let-viewType="viewItems">
      <div *ngIf="!viewType" class="">
        <div class="rate-selection">
          <mat-checkbox class="example-margin" [disabled]="fromRateChanges"
            (change)="someComplete($event , guestType)" [(ngModel)]="guestType.select" (click)="$event.stopPropagation()">
          </mat-checkbox>
          <div class="rate-selection-name">
            {{guestType.GuestName}} <span *ngIf="guestType.TicketNumber">(#{{guestType.TicketNumber}})</span>
          </div>
        </div>
        </div>
      <mat-panel-description *ngIf="!viewType" class="justify-content-end m-0">
        <div class="summary-description">
         <ng-container *ngIf="showPaymentStatus">
          <ng-container *ngIf="(guestType | partyPaymentState) as paymentState">
            <span *ngIf="paymentState!=='NA'" class="user-card__payment--label payment-{{paymentState}}">
              {{ paymentState | translate }}
            </span>
            <!-- <span *ngIf="paymentState=='NA'">
              {{'NA' | translate}}
            </span> -->
          </ng-container>
        </ng-container>
        </div>
        <app-retail-card-type *ngIf="guestType.CreditCards?.[0]?.CardIssuer && guestType.GuestId" class=" pr-2 card-issuer cursor-pointer" 
        (click)="editGuest(guestType.GuestId)" [type]="guestType.CreditCards?.[0]?.CardIssuer"></app-retail-card-type>
        <i *ngIf="guestType.CreditCards?.length && !guestType.CreditCards?.[0]?.CardIssuer && guestType.GuestId" class="icon-Unkown-Card pr-2 card-issuer-unknown cursor-pointer"  (click)="editGuest(guestType.GuestId)">
         <span class="path1"></span><span class="path2"></span>
        </i>
        <i *ngIf="!guestType.CreditCards?.length && !diningProperty && guestType.GuestId" class="icon-card-add-new pr-2 cursor-pointer card-issuer" (click)="editGuest(guestType.GuestId)"></i>
        <div> {{currencySymbol}} {{guestType.Total | currencyDecimalFormatter}}</div>
       
      </mat-panel-description>
      <ng-container *ngIf="viewType">
        <ng-container *ngTemplateOutlet="sessionItems; context: { $implicit:guestType.Items, guestSelected: guestType.select}">
        </ng-container>

          <div class="rate-plan__summary pt-1 seat-grid-content bold-font"
            *ngIf="guestType.MemberInfo?.TotalMinutes > 0">
            <span>{{'memberTotalMinuteText' | translate}}</span>
            <span class="pr-1"> {{guestType.MemberInfo?.TotalMinutes}}</span>
          </div>

          <div class="rate-plan__summary pt-1 seat-grid-content bold-font" *ngIf="guestType.Items?.length">
            <span>{{'Total' | translate}}</span>
            <span class="pr-1"> {{guestType.SubTotal | currencyDecimalFormatter}}</span>
          </div>
        </ng-container>

      </ng-template>

      <!-- Display Line Items -->
      <ng-template #sessionItems let-items let-guestSelected="guestSelected">
        <div class="rate-plan__items rate-plan__items--color seat-body-text" *ngIf="items?.length">
          <span class="text-left pr-2">{{'Description' | translate}}</span>
          <span class="text-right pr-2">{{'Qty' | translate}}</span>
          <span *ngIf="this.dialogData.bookingData?.actionType != financialEffectAction.Refund" class="text-right pr-2">{{(!taxType ? 'PrevSalePrice' : 'PrevGrossPrice') | translate}}
            ({{currencySymbol}})</span>
          <span class="text-right pr-2">{{(!taxType ? 'SalePrice' : 'gross')| translate}} ({{currencySymbol}})</span>
          <span *ngIf="this.dialogData.bookingData?.actionType == financialEffectAction.Refund" class="text-right pr-2">{{ 'Refund' | translate}} ({{currencySymbol}})</span>
          <span class="text-right ">{{'ItemTotal' | translate}} ({{currencySymbol}})</span>
        </div>
        <div class="rate-plan__items seat-grid-content pb-1" *ngFor="let lineItem of items">
          <span class="text-left wrap-text mt-1 pr-2" title="{{lineItem.Name}}"> {{lineItem.Name}}</span>
          <span class="text-right mt-1 pr-2">{{lineItem.Count}}</span>
          <span *ngIf="this.dialogData.bookingData?.actionType != financialEffectAction.Refund" class="text-right mt-1 pr-2">{{lineItem.PastPrice | currencyDecimalFormatter}}</span>
          
          <input (keyup)="setConfigChanges()" ngxNumberFormat = "{{numberFormat}}" [allowNegative]="true" type='text' min="1"
            [ngClass]="{'events-none': (!enableEdit || lineItem.Type == paymentItemType.PackageDiscount || (!guestSelected && fromRateChanges))}" step="0.10"
            class="text-right rate-plan__item-bg pr-2" [(ngModel)]="lineItem.PayableUnitPrice"  />

          <input *ngIf="this.dialogData.bookingData?.actionType == financialEffectAction.Refund" (keyup)="setConfigChanges()" 
            ngxNumberFormat = "{{numberFormat}}" [allowNegative]="true" type='text' min="1"  [ngClass]="{'events-none': (!guestSelected && fromRateChanges)}" 
            step="0.10" class="text-right rate-plan__item-bg pr-2" [(ngModel)]="lineItem.Refund" />

          <span class="text-right rate-plan__item-bg rate-plan__item--total ">{{lineItem.SubTotal | currencyDecimalFormatter}}</span>
        </div>
      </ng-template>

      <!--Tax Data-->
      <ng-template #taxDataTemplate let-taxes>
        <div class="seat-labels d-flex justify-content-between pl-3 pb-1" *ngFor="let taxItem of taxes">
          <span *ngIf="taxItem.IsPercentage">{{taxItem.ChargeName}} @ {{taxItem.ChargeValue}}% </span>
          <span *ngIf="!taxItem.IsPercentage">{{taxItem.ChargeName}} </span>
          <span>{{currencySymbol}} {{taxItem.Amount | currencyDecimalFormatter}}</span>
        </div>
      </ng-template>

      <!--Service Charge Data-->
      <ng-template #ServiceChargeDataTemplate let-serviceCharges>
        <div class="seat-labels between pl-3" *ngFor="let serviceItem of serviceCharges">
          <span class="d-inline-flex w-80 pb-1" *ngIf="serviceItem.IsPercentage">{{serviceItem.ChargeName}} @
            {{serviceItem.ChargeValue}}% </span>
          <span *ngIf="!serviceItem.IsPercentage">{{serviceItem.ChargeName}} </span>
          <span class="d-inline-flex w-20 justify-content-end">{{currencySymbol}} {{serviceItem.Amount |currencyDecimalFormatter}}</span>
          <span *ngIf="taxType">
            <ng-container *ngTemplateOutlet="taxDataTemplate; context: { $implicit:serviceItem.Taxes}"></ng-container>
          </span>
        </div>
      </ng-template>

    </div>
  </ng-scrollbar>
</div>
