import { inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CommonUtilities } from "@app/common/shared/shared/utilities/common-utilities";
import { CacheService } from "@app/core/services/cache.service";
import { CustomPopupComponent } from "@app/popup-module/components/custom-popup/custom-popup.component";
import { ComponentDetails } from "@app/popup-module/models/popup.interface";
import { PopupService } from "@app/popup-module/popup.service";
import { ConfirmationPopupComponent } from "@app/shared/components/confirmation-popup/confirmation-popup.component";
import { ComponentTypes, FinancialEffectAction, LessonType, OperationResultState, PartyState, RolesAndPermissionsType } from "@app/shared/constants/commonenums";
import { DEFAULT_LANGUAGE_CODE } from "@app/shared/constants/globalConstants";
import { PartyPrepaymentState, ReservationDTO } from "@app/shared/models/InputContact";
import { PayingGuest } from "@app/shared/models/RestaurantDTO";
import { RolesAndPermissionPipe } from "@app/shared/pipes/RolesAndPermission.pipe";
import { PartyService } from "@app/shared/services/party.service";
import { AlertType, ButtonType } from "@app/shared/shared-models";
import { DashboardFunctions } from "@app/shared/utilities/dashboard-functions";
import { Utilities } from "@app/shared/utilities/utilities";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: 'root'
})
export class ReservationStateActionService {

    permissionPipe = inject(RolesAndPermissionPipe)
    dashboardFunctions = inject(DashboardFunctions)
    translateService = inject(TranslateService)
    popupService = inject(PopupService)
    partyService = inject(PartyService)
    cacheService = inject(CacheService)
    utilities = inject(CommonUtilities)
    toastrService = inject(ToastrService)
    dialog = inject(MatDialog)


    get settings() {
        return this.cacheService.settings?.value
    }


    dueStates = [PartyPrepaymentState.PrepaymentRequired,
    PartyPrepaymentState.PrepaymentInProgress,
    PartyPrepaymentState.PrepaymentFailed,
    PartyPrepaymentState.PartialPrepayment,
    PartyPrepaymentState.DepositCollected,
    PartyPrepaymentState.AdditionalPaymentPending,
    PartyPrepaymentState.PaymentDuePending,
    PartyPrepaymentState.RefundDuePending
    ]

    RefundState = [PartyPrepaymentState.RefundDuePending,
    PartyPrepaymentState.RefundFailed]

    refunRestrictedStates = [PartyPrepaymentState.PartialPrepayment, PartyPrepaymentState.PaymentDuePending, PartyPrepaymentState.PrepaymentRequired, PartyPrepaymentState.Refunded, PartyPrepaymentState.None, PartyPrepaymentState.CancellationFeeApplied];


    protected handleCheckInState(state: PartyState, permission: RolesAndPermissionsType, reservation: ReservationDTO, actionContact) {
        if (!this.checkUserPermissions(permission)) {
            return;
        }

        const payload = this.getPayload(state, reservation, actionContact);

        this.partyService.setBookingState(payload).subscribe(response => {
            if (response.State === OperationResultState.Success) {
                this.confirmationPopUp(response, state, reservation);
            }
        });
    }

    checkin(reservation: ReservationDTO, actionContact?) {
        this.handleCheckInState(PartyState.Seated, RolesAndPermissionsType.CheckInCheckOutGuest, reservation, actionContact);
    }

    undocheckin(reservation: ReservationDTO, actionContact?) {
        this.handleCheckInState(PartyState.Pending, RolesAndPermissionsType.UndoCheckIn, reservation, actionContact);
    }

    checkout(reservation: ReservationDTO, actionContact?) {
        this.checkOutWithDues(reservation, actionContact);
    }

    getPayload(updateState: PartyState, reservation, actionContact?) {
        let _BookingContactStateIds = this.getContactStateIds(reservation, actionContact);

        return {
            State: updateState,
            BookingId: reservation?.Id,
            BookingSessionId: reservation?.BookedSessionId || null,
            BookingContactStateIds: _BookingContactStateIds
        }
    }
    checkOutWithDues(attendee: any, bookingContact: any, financialData: any = null) {
        if (Utilities.isUserHasAccessToRetail(attendee.RestaurantId)) {
            let { party, payingGuest } = this.payingGuest(attendee, bookingContact);
            if (financialData == null) {
                this.partyService.getFinancialDetails(party.Id, FinancialEffectAction.CheckOut, null, null, false, payingGuest).subscribe(data => {
                    this.partyService.openCheckInWithDuesPopUp(party, data.Payload, ComponentTypes.CheckOutRatePlanSummary, payingGuest, 'CheckOut', FinancialEffectAction.CheckOut);
                })
            } else {
                this.partyService.openCheckInWithDuesPopUp(party, financialData, ComponentTypes.CheckOutRatePlanSummary, payingGuest, 'CheckOut', FinancialEffectAction.CheckOut);
            }
        } else {
            this.toastrService.warning(this.translateService.instant('userPermissionMsg'));
        }
    }

    private payingGuest(reservation: any, actionContact: any) {
        let party = Object.assign({}, reservation);
        party.FinancialEffectId = null;
        party.Sessions = { SessionId: party.BookedSessionId };
        let payingGuest = this.composePayingGuest(reservation, party.BookedSessionId, actionContact?.ContactId || null);
        return { party, payingGuest };
    }

    setBookingState(pickedLineItem: any, State: any, party?: any, checkInWithDues?: boolean, skipValidation?: boolean) {
        if (!this.checkUserPermissions(State == PartyState.Pending ? RolesAndPermissionsType.UndoCheckIn : RolesAndPermissionsType.CheckInCheckOutGuest)) {
            return;
        }
        let booking = party ? party : pickedLineItem;
        let _BookingContactStateIds = pickedLineItem.contactStatesId ? [pickedLineItem.contactStatesId] : this.getContactStateIds(pickedLineItem);
        let payload = {
            State: State,
            BookingId: booking?.Id,
            BookingSessionId: booking?.BookedSessionId || null,
            BookingContactStateIds: _BookingContactStateIds
        }
        this.partyService.ratePlanObject = null;
        // if (checkInWithDues) {
        //     if (this.validateCheckInOperation(pickedLineItem, party, State, checkInWithDues, skipValidation)) {
        //         this.checkInWithDues(booking, pickedLineItem);
        //     }
        // } else {
        //     if ((State == PartyState.Seated && this.validateCheckInOperation(pickedLineItem, party, State, checkInWithDues, skipValidation)) || State !== PartyState.Seated) {
        //         let activity = this.settings.SpecialMeals?.find(activity => activity.Id == booking?.SpecialMealId);
        //         if (State == PartyState.Left && (activity == null || undefined || activity.LessonType == LessonType.PrivateLesson)) {
        //             let { party, payingGuest } = this.payingGuest(booking, pickedLineItem);

        //             this.partyService.CalculateAdditionalCharge(party.Id, payingGuest).subscribe(response => {

        //                 if (response?.Payload?.RatePlan && response?.Payload?.RatePlan?.Unpaid !== 0) {

        //                     if (Utilities.isUserHasAccessToRetail(Utilities.RestaurantId())) {
        //                         this.checkOutWithDues(booking, pickedLineItem, response.Payload);
        //                     }
        //                     else {
        //                         this.utilities.showAlert(this.translateService.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
        //                     }
        //                 }
        //                 else {
        //                     this.partyService.setBookingState(payload).subscribe(response => {
        //                         this.confirmationPopUp(response, State, booking);
        //                     })
        //                 }
        //             })

        //         }
        //         else if (pickedLineItem.showCheckoutWithDues && State == PartyState.Left) {
        //             this.checkOutWithDues(booking, pickedLineItem);
        //         }
        //         else {
        //             this.partyService.setBookingState(payload).subscribe(response => {
        //                 this.confirmationPopUp(response, State, booking);
        //             })
        //         }
        //     } else if (pickedLineItem.showCheckoutWithDues && State == PartyState.Left) {
        //         this.checkOutWithDues(booking, pickedLineItem);
        //     }
        // }
    }

    composePayingGuest(reservation: any, sessionId: number = null, guestId: number = null) {
        let payingGuestsArr = [];
        if (reservation.BookedSessions?.length) {
            reservation.BookedSessions?.forEach(session => {
                let _payingGuest = {} as PayingGuest;
                let _allowRefund = this.canRefund(session.PaymentStatus, session);
                let _allowChargeGuest = this.canChargeGuest(session.PaymentStatus, session);
                if ((_allowChargeGuest || _allowRefund) && (sessionId == session.Id || !sessionId)) {
                    _payingGuest.ActivitySessionId = session.ActivitySessionId;
                    _payingGuest.BookingDate = session.BookedDate;
                    _payingGuest.PayingContacts = guestId ? [guestId] : this.getPayingContactIds(reservation.BookingContacts);
                    payingGuestsArr.push(_payingGuest);
                }
            })
        } else {
            let _payingGuest = {} as PayingGuest;
            _payingGuest.ActivitySessionId = null;
            _payingGuest.BookingDate = reservation.ReservedFor;
            _payingGuest.PayingContacts = guestId ? [guestId] : reservation.BookingContacts.map(contact => contact.ContactId);
            payingGuestsArr.push(_payingGuest);
        }

        return payingGuestsArr;
    }

    getPayingContactIds(bookingContacts: any[]) {
        let contacts = bookingContacts.filter(contact => contact?.bookingStates?.PaymentStatus == PartyPrepaymentState.PrepaymentRequired && contact.State !== PartyState.Cancelled);
        if (!contacts.length) {
            contacts = bookingContacts.filter(contact => this.canChargeGuest(contact?.bookingStates?.PaymentStatus));
        }
        return contacts.map(contact => contact.ContactId) || [];
    }

    canChargeGuest(PrepaymentState, bookedSession: any = null, partyState: any = null) {
        let _paymentState = bookedSession ? bookedSession.PaymentStatus : PrepaymentState;
        let _state = bookedSession ? bookedSession.SessionState : partyState;
        return this.dueStates.includes(_paymentState) && _state !== PartyState.Cancelled;
    }
    canRefund(PrepaymentState, bookedSession: any = null) {
        let _state = bookedSession ? bookedSession.PaymentStatus : PrepaymentState || PartyPrepaymentState.None;
        return !this.refunRestrictedStates.includes(_state);
    }
    showRefundWithCancel(refundAvailable, State) {
        return refundAvailable && State === PartyState.Cancelled;
    }

    confirmationPopUp(response, State, booking) {
        if (response.State !== OperationResultState.Success) return;

        let msg = '';
        const activityName = this.partyService.getActivityName(booking?.SpecialMealId);

        const stateMessages = {
            [PartyState.Seated]: this.translateService.instant('attendeeCheckedInSession', { activityName }),
            [PartyState.Left]: this.translateService.instant('attedeeCheckedOutSession', { activityName }),
            [PartyState.Pending]: this.translateService.instant('attendeeReInState')
        };

        msg = stateMessages[State] || '';  // Default to empty string if no matching state

        if (msg) {
            this.partyService.openConfirmationDialog(response, msg, null, null, null, null);
        }
    }

    validateCheckInOperation(pickedLineItem: any, attendee: any, State: any, checkInWithDues: boolean, skipValidation: boolean = false) {
        let allowCheckIn = true;

        const staffTbaId = this.settings?.Servers?.find(x => x.IsTemplate)?.Id;
        if ((pickedLineItem?.ServerId == staffTbaId && pickedLineItem?.isPrivateLessonBooking) || (attendee?.ServerId == staffTbaId && attendee?.isPrivateLessonBooking)) {
            this.showStaffAssignError();
            return;
        }

        if (skipValidation) {
            return true;
        }
        let booking = attendee ? attendee : pickedLineItem;
        const checkInPastReservation = this.dashboardFunctions.checkBookPastDaysReservationsConfig();
        let propertyTime = moment(moment(Utilities.getRestaurantDateTime(this.settings.General.DaylightDelta)).format('YYYY-MM-DDTHH:mm'))
        let activity = this.settings.SpecialMeals?.find(activity => activity.Id == booking?.SpecialMealId);
        if (activity && activity.LessonType == LessonType.GroupLesson) {
            return true;
        } else {
            if (moment(moment(booking.SeatingTime).format('YYYY-MM-DDTHH:mm')).diff(propertyTime, 'minutes') > this.settings.General.TimeSlotUnitInMinutes) {
                let message = this.translateService.instant('timeNotArrivedMessage') + moment(booking.SeatingTime).locale(this.getLocaleCode()).format("DD/MM/yyyy hh:mm a");
                allowCheckIn = false;
                this.showWarningInfoPopUp(message, false, pickedLineItem, attendee, State, checkInWithDues);
            }
            else if (moment(moment(booking.DepartureTime).format('YYYY-MM-DDTHH:mm')).diff(propertyTime, 'minutes') <= 0) {
                let message = this.translateService.instant('sessionExpiredMessage');
                if (checkInPastReservation) {
                    message = message + this.translateService.instant('warningForProceeding');
                }
                allowCheckIn = false;
                this.showWarningInfoPopUp(message, true, pickedLineItem, attendee, State, checkInWithDues);

            } else {
                allowCheckIn = true;
            }
        }

        return allowCheckIn;
    }

    getContactStateIds(pickedLineItem, actionContact?): any[] {
        if (actionContact?.contactStatesId) {
            return [actionContact?.contactStatesId]
        }
        let _ids = [];
        if (pickedLineItem?.mappedContact?.length) {
            _ids = pickedLineItem?.mappedContact?.filter(item => item.selected && item.State !== PartyState.Cancelled)?.map(item => item.contactStatesId) || [];
            if (!_ids?.length) {
                _ids = pickedLineItem.mappedContact.filter(contact => contact.State == pickedLineItem.State && contact.State !== PartyState.Cancelled).map(item => item.contactStatesId)
            }
        } else if (pickedLineItem?.BookingContacts) {
            _ids = pickedLineItem?.BookingContacts.map(item => item.BookingContactStates?.filter(contact => contact.BookedSessionId == pickedLineItem.BookedSessionId)).flat()?.map(bookingContact => bookingContact.Id) || [];
        }
        return _ids;
    }

    checkUserPermissions(_permissionType: RolesAndPermissionsType = null): boolean {
        if (!this.permissionPipe.transform(_permissionType)) {
            this.toastrService.warning(this.translateService.instant('accessDenied'), "", { timeOut: 5000, closeButton: true });
            return false;
        } else {
            return true;
        }
    }

    showWarningInfoPopUp(message, isActionPopup, pickedLineItem: any, attendee: any, State: any, checkInWithDues: boolean) {
        let cancelText = isActionPopup ? 'No' : '';


        let updateText = isActionPopup ? 'Yes' : 'Ok';
        let showAlert = !isActionPopup;
        let title = this.translateService.instant('CheckinInfoText');

        const popUpMessage = [{
            confirmationMessage: message, dialogTitle: title, showAlert: showAlert
        }];

        const componentDetails: ComponentDetails = {
            componentName: ConfirmationPopupComponent,
            dimensionType: 'small',
            popupType: isActionPopup ? 'active' : 'action',
            popUpDetails: {
                isStepper: false,
                eventName: 'notifyParent'
            },
            popupInput: popUpMessage,
            popupTitle: popUpMessage[0].dialogTitle
        };

        let dialogRef = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            width: '450px',
            height: '350px',
            data: {
                title,
                update: updateText,
                cancel: cancelText,
                componentDetails,
                from: ComponentTypes.CheckinSession,
                back: false,
                standalone: true,
                showAlert: true,
                showAction: true,
            }
        });
        let confirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
            if (val == ComponentTypes.CheckinSession && isActionPopup) {
                this.setBookingState(pickedLineItem, State, attendee, checkInWithDues, true)
            }
        });

        dialogRef.afterClosed().subscribe(event => {
            if (confirmSubscription)
                confirmSubscription.unsubscribe();
        });

    }

    showStaffAssignError() {
        this.dashboardFunctions.showErrorPopUp('Assign Staff to checkin', 'auto')
    }

    getLocaleCode(): string {
        let defaultCode = DEFAULT_LANGUAGE_CODE;
        let languageCode = sessionStorage.getItem('languageCode') || DEFAULT_LANGUAGE_CODE;
        let allAvailableLanguages = JSON.parse(sessionStorage.getItem('languages')) || [];
        if (languageCode) {
            return languageCode;
        }
        return defaultCode;
    }

}