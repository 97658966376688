<lib-dialog-container [title]="'Booked Successfully!' | translate" [showFooter]="false">
    <div class="respos-cart-success" content>
        <p class="respos-h2">{{'You may now proceed to print the wristband for guest.'}}</p>
        <!-- <p class="respos-h2">{{'You may now proceed to print the wristband for guest'}}</p> -->
        <button class="respos-btn respos-btn-fill" (click)="proceedToPrint()">{{'Add Wristband' | translate}}</button>
        <div class="respos-separator w-100"></div>

        <button class="respos-btn respos-btn-outline" (click)="goToHome()">
            <span class="respos-icon icon-respos-home"></span> {{'Home' | translate}}
        </button>
    </div>
</lib-dialog-container>