import { ServiceCharge, Commission, Gratuity, ServiceChargeGratuity, ReturnWithTicketType, SelectedProducts as CommonSelectedProducts, CustomFeeConfig, TransactionCustomFee, GroupRetailItems } from "../shared/service/common-variables.service";
import { GiftCardType, GiftCardTransactionType, CustomFeeSourceType } from '../shared/globalsContant';
import { clientMultiPack, HandleResponse, multiItemMultiPack } from '../shared/business/shared.modals';
import { SelectedProducts } from "../shared/shared.modal";
import { DiscountAdditionalProperties, Pricing } from "../retail-code-setup/retail-discount-types/discount-types.model";
import { RetailItemType } from "src/app/retail/retail.modals";
export interface popupConfig {
    operation: string;
    maxListOrder?: number;
}

export enum ItemType {
    retailItem,
    PMSAddOnsRentalItem
}

export interface GroupedRetailItemForShop {
    id: number;
    name: string;
    data: ItemForShop[];
    hidden?: boolean;
    isViewMoreEnable?: boolean;
    listOrder?: number;
    viewMoreclicked? : boolean;
}

export interface dateRangeUIModel {
    dateRange: { startDate: Date, endDate: Date };
    value: Date;
    controlchanged: string;
}

export interface shopPricePrompt {
    res: string;
    price: number;
    itemName: string;
}

export interface enterCommentPrompt {
    res: string;
    comment: string;
}

export interface dateRangeUIModel {
    dateRange: { startDate: Date, endDate: Date };
    value: Date;
    controlchanged: string;
}

export interface shopScaledItems {
    res: string;
    unit: number;
}
export interface ItemForShop {
    itemId: number;
    itemNumber: number;
    itemType: number;
    itemDescription: string;
    externanId?: number;
    title: string;
    dispPrice: number;
    price: number;
    memberPrice: number;
    multipack: boolean;
    image?: string;
    discountpercentage?: number;
    gratuity?: number;
    serviceCharge?: number;
    isOpenItem: boolean;
    isGroupingKey: boolean;
    scaledUnit?: number;
    uom?: string;
    unitOfMeasureId?: number;
    category: number;
    isScaledItem: boolean;
    secondaryBarcode?: string;
    isGiftCardItem?: boolean;
    isGiftCardCustomValueItem?: boolean;
    barCodes: string[],
    GiftCardTransactionItem?: GiftCardShopDetails,
    isRequestName?: boolean,
    isRequireComments?: boolean,
    itemComments?: string,
    costPrice: number,
    marginPercentage: number,
    allowEarn: boolean,
    listOrder?: number;
    period?: number;
    rentType?: number;
    perHourRentalRate?: number;
    perDayRentalRate?: number;
    itemNotSynced?: boolean;
    isCategoryMapped?: boolean;
    vendorName?: string;
    availableQty?:number;
    totalQty?:number;
    bufferTime?:number;
    useInventory?: boolean
}

export interface GiftCardShopDetails {
    cardType: GiftCardType;
    amount: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    cardNumber: string;
    expiryDate: string;
    handleInfo: HandleResponse | string;
    isIssue: boolean;
    isVoided?: boolean;
    transactionType: GiftCardTransactionType;
    paymentMethodId?: number;
    //Will be implemented for other details
}

export interface StayRetailItemInfo {
    stayId: number;
    itemId: number;
    staydate: string;
    quantity: number;
    price: number;
    hours: number;
    itemDetails: any;
    roomIndex?: number;
}

export interface ShopAddItemModal {
    products: CommonSelectedProducts;
    stayarrivalDate: Date;
    staydepartureDate: Date;
    inventory: any;
    productPrice: number;
    quantity: number;
    editFlag: boolean;
    rentHours: number;
    rentTypeFlag: boolean;
    comments : string;
}

export interface TransactionData {
    Id: number
    TransactionType: string
    TransactionDate: string
    ClerkId: number
    TotalPrice: number
    TotalTax: number
    TotalAmount: number
    GuestId: number
    MachineNameId: number
    MemberId: string
    Comment: string
    StayId: number
    IsVoided: boolean
    shopItems: RetailShopItem[];
    OutletId: number
    PropertyId?: number
    SubPropertyId?: number,
    TicketNumber?: string,
    VoidReason?: string,
    ProductId: number,
    IsTaxExempt?: boolean,
    TerminalId: number,
    ReceiptComment?: string,
    PatronId: string,
    GuestGuid: string,
    PlayerPin?: string,
    IsExcludeDiscountOnGratuity?: boolean,
    IsExcludeDiscountOnServiceCharge?: boolean,
    UserId?: number,
    EmailId?:string[]
    TransactionAdditionalReference?: string,
    TransactionAdditionalComment?: string,
    TaxWithoutPaymentMethodExemption? : number,
    SourceCorpId?: number
    transactionTicketDiscounts?: TransactionTicketDiscount[],
    dependentTransactions?: DependentTransactions[]
}

export interface DependentTransactions {
    parentTransactionId?: number;
    dependentTransactionId: number;
    sourceType: TransactionSourceType;
    sourceTypeId: number;
    linkActive: boolean;
}

export enum TransactionSourceType {
    RetailTransaction = 0,
    AppointmentDeposit,
    AppointmentPayment,
    SpaCancellation,
    SpaNoShow,
    TeetimeDeposit,
    TeetimePayment,
    GolfCancellation,
    GolfNoShow,
    DepositRefund
}

export interface TransactionTicketDiscount {
    id?: number;
    transactionId?: number;
    discount: number;
    discountTypeId: number;
    discountPercentage: number;
    discountAmount: number;
    discountReasonId: number;
    discountComments: string;
    isDiscountPercentage?:boolean;
    appliedDiscount?:number;
    discountMinimumAmountInCart?:number;
}

export interface RetailShopItem {
    ItemId: number
    ItemType: number
    ItemDescription: string
    ExternalPOSItemId: number
    LineNumber: number
    StaffTransactionDetail?: StaffTransactionDetail[]
    QuantitySold: number
    UnitPrice: number
    Discount: number;
    weightedDiscount?: number
    DiscountTypeId: number
    DiscountName?: string
    DiscountPercentage: number
    Commission?: Commission[]
    ServiceChargeGratuity?: ServiceChargeGratuity[]
    Tax: number;
    BaseTax: number;
    LinkedTax: number;
    IsTaxExempt: boolean
    TotalAmount: number
    OutletId: number
    IsGroupingKey: boolean
    IsPackagedItem: boolean
    PackageItemId: number
    IsMultiPackRedeem: boolean
    IsMultiPack?: boolean
    ClientMultiPackId: number
    PackageGroupId: number
    IsOpenPricedItem: boolean
    transactionDetailLinkId?: number
    id?: number
    isReturn?: boolean;
    unitOfMeasureId?: number;
    scaledUnit?: number;
    discountModel?: ApplyDiscount;
    IsModificationRestricted: boolean;
    netUnitPrice?: number;
    netUnitPriceWithoutDiscount?: number;
    GiftCardTransactionItem?: GiftCardShopDetails;
    itemComments?: string;
    costPrice: number;
    marginPercentage: number,
    allowEarn: boolean,
    discountComments: string,
    discountReason: number,
    returnType?: ReturnWithTicketType,
    returnLinkId?: number,
    netPrice?: number;
    originalQuantity?: number;
    GroupingParentId?: number;
    GroupingUniqueIdentifier?: string;
    GroupingItemDescription?: string;
    multiPackTransactionDetailId?: number;
    customFee?: TransactionCustomFee;
    linkedCustomFeeItemLineNumber?: number;
    sourceTypeId? : number;
    sourceType?: CustomFeeSourceType;
    isDiscountPercentage?:boolean;
    appliedDiscount?:number;
    discountMinimumAmountInCart?:number;
    multipackCustomFee?: number;
    multipackMemberCustomFee?: number;
    IsRetained?:boolean;
    rentalItemPurchaseId?:number;
    lockerAssignmentId ?: number;
    costPlusIsPercentage?: boolean;
    costPlusDiscountValue?: number;
}


export interface StaffTransactionDetail {
    StaffId: number
    StaffType: string
}

export interface ReturnWithTicketData {
    retainedItem: SelectedProducts[];
    returnedItem: SelectedProducts[];
    newItem: SelectedProducts[];
}

export interface ReturnTicketData {
    retainedItem: RetailShopItem[];
    returnedItem: RetailShopItem[];
    newItem: RetailShopItem[];
    retainedTicketDiscount?: TransactionTicketDiscount[];
    reason: string;
    receiptComment: string;
    guestId: number;
    MachineNameId: number;
    emailId? : string[];
}

export interface ReturnItemTotal {
    retainedItemGratuity: number;
    retainedItemServiceCharge: number;
    retainedItemSubTotal: number;
    retainedItemTax: number;
    retainedItemTaxOnTax: number;
    retainedItemGratuityTax: number;
    retainedItemServiceChargeTax: number;
    retainedItemVat: number;
    retainedItemDiscount: number;
    exchangedItemGratuity: number;
    exchangedItemServiceCharge: number;
    exchangedItemSubtotal: number;
    exchangedItemTax: number;
    exchangedItemTaxOnTax: number;
    exchangedItemGratuityTax: number;
    exchangedItemServiceChargeTax: number;
    exchangedItemDiscount: number;
    returnedItemGratuity: number;
    returnedItemServiceCharge: number;
    returnedItemSubtotal: number;
    returnedItemTax: number;
    returnedItemTaxOnTax: number;
    returnedItemGratuityTax: number;
    returnedItemServiceChargeTax: number;
    returnedItemVat: number;
    returnedItemDiscount: number;
    returnedItemWeightedDiscount:number;
    retainedItemTicketDiscount:number;
}


export interface ApplyCommissionServGrat {
    commission: Commission[],
    ServiceCharge: ServiceCharge[],
    Gratuity: Gratuity[],
}


export interface PostedCommissions {
    itemId: number;
    lineNumber: number;
    itemNumber: number;
    itemDescription: string;
    isCommissionable: boolean;
    radioDisabled: boolean;
    radioSelected: boolean;
    quantity: number;
    staffId: number;
    staffType: string;
    staffName: string;
    commissionAmount: number | string;
    commissionPercentage: number | string;
    amount: number;
    commissionType: GivenCommissionAmountType
    eligibilityId: number;
    eligibleAmount: number;
    discount: number;
    transactionDetailId: number;
    radioToolTip: string;
    postedCommissionType: CommissionType;
    outletId: number;
    netEligibleAmount: number;
    netEligibleWithoutDiscount: number
}

export enum CommissionType {
    Item = "Item",
    Revenue = "Revenue"
}

export enum RentTypes {
    Days = 1,
    Hours = 2
}

export enum GivenCommissionAmountType {
    Percentage = 1,
    Amount = 2
}

export interface TransactionDetailWithPostedCommisisons {
    transactionDetailId: number
    itemId: number
    itemNumber: string
    itemDescription: string
    isCommissionable: boolean;
    quantity: number
    staffId: number
    staffType: string
    staffName: string
    commissionAmount: number
    commissionPercentage: number
    totalAmount: number
    eligibilityId: number
    eligibleAmount: number
    discount: number
    commissionType: CommissionType
    outletId: number
    netEligibleAmount: number
    netEligibleWithoutDiscount: number
}

export interface CommissionableStaff {
    id: string;
    staffName: string;
    staffId: number,
    staffType: string;
    commissionClass: number;
}

export enum StaffType {
    Therapist = 1,
    User = 2
}

export interface ModifyPostedCommission {
    staffId: number;
    staffType: string;
    itemId: number;
    commissionAmount: number;
    commissionPercentage: number;
    eligibleAmount: number;
    quantity: number;
    trandetailId: number;
    transactionId: number;
    staffCommissionEligiblityId: number;
    productId: number;
    commissionClass?: number;
    commissionType: CommissionType;
    outletId: number;
    isAfterDiscount: boolean;
    netEligibleAmount: number;
    netEligibleWithoutDiscount: number;
    isRevenueCommissionOnNet: boolean;
    isItemCommissionOnNet: boolean;
}

export interface ItemDiscount {
    itemId: number;
    discountId: number;
    discountPercentage: number;
    discountAmount: number;
    discountReason: number;
    discountComment: string;
    isPercentage?: boolean;
    discountDetails?:DiscountTypeDetails;
    minimumAmountInCart?: number;
    isCostDiscount?: boolean;
    isCostPlusDiscount?: boolean;
    costPlusDiscountDetails? : CostPlusDiscountDetails;
    discountName?: string;
}

export interface CostPlusDiscountDetails{
    isPercentage: boolean;
    value: number;
}

export interface RetailCategoryAndDiscountTypeDetails {
    retailCategoryId: number;
    retailCategoryName: string;
    eligibleForDiscount: boolean;
    discountTypeDetails: DiscountTypeDetails[];
    eligibleForCostOrCostPlus: boolean;
}

export interface DiscountTypeDetails{
    discountTypeId: number;
    discountTypeName: string;
    discountValue: number;
    pricing?: Pricing;
    isPercentage?: boolean;
    amount?: number;
    minimumAmountInCart?: number;
    tenderReducesDiscount?: boolean;
}

export interface DiscountType extends DiscountAdditionalProperties {
    discountTypeId?: number;
    id: number;
    type: string;
    isActive: boolean;
    listOrder: number;
}

export interface DiscountReason {
    id: number;
    description: string;
    isActive: boolean;
    listOrder: number;
}
export interface ApplyDiscount {
    index: number
    isPercentage: boolean
    value: number,
    minimumAmountInCart?:number;
    appliedDiscount?:number;
    lineNumber?: number;
}

export interface ReceiptConfiguration {
    id: number;
    outletId: number;
    numberOfReceipts: number;
    displayDiscount: boolean;
    addSecondLine: boolean;
    displayOnlyPackageItem: boolean;
    suppressClerkId: boolean;
    suppressPrintedBy: boolean;
    displayPackageDescription: boolean;
    displayPackagePrice: boolean;
    displayPackageAppointmentID: boolean;
    displayPackageStaffCode: boolean;
    serviceChargeGratuityDisplay: number;
    gratuityLine?: any;
    receiptNote?: any;
    displayGroupedItem?: boolean;
    clerkIdPrintValue: number;
    printedByPrintValue: number;
    displayDetailedCustomFee?: boolean;
}

export interface PropertyConfigurationModel {
    configValue: any;
    defaultValue: any;
    id: number;
    moduleName: string;
    screenName: string;
}
export interface UserDetails {
    tenantId: number;
    userId: number;
    userName: string;
    firstName: string;
    lastName: string;
    password?: any;
    isActive: boolean;
    isNewUser: boolean;
    isPasswordExpired: boolean;
    lastAccessDate: string;
    passwordExpireDate: string;
    createdOn: string;
    quickId?: any;
    roleId: number;
    languageId: number;
    email: string;
    loggedUser?: any;
    propertyId: number;
    userPropertyAccesses: any[];
    isServiceUser:boolean;
}

export interface UserRetailConfigurations {
    id: number;
    userId: number;
    allowGratuity: boolean;
    allowServiceCharge: boolean;
    allowCommission: boolean;
    commissionClass: boolean;

}

export interface AssignCommissionStaffArrayUIModel {
    id: string;
    staffId: number;
    firstName: string;
    lastName: string;
    image: any;
    staffLevel: string | number;
    isStaffSelected: boolean;
    staffType: string;
}

export interface StaffsUIModel {

    id: number;
    code: string;
    firstName: string;
    lastName: string;
    gender: string;
    dateOfHire: string;
    seniorityLevel: string;
    alsoKnownAs: string;
    allowGratuity: boolean;
    allowServiceCharge: boolean;
    allowCommission: boolean;
    isActive: boolean;
    listOrder: number;
    commissionClassId: number;

}

export class ReportAPIOptions {
    code: string;
    format?: ReportDownloadFormat = 'HTML';
    params: ReportParams[];
    URIParams: ReportParams[];
    filters: any;
    pageBreak: boolean;
    downloadFileName?: string;
}
export interface TransactionSignature {
    signature: string;
}
export interface ReportParams {
    [parameter: string]: string | number | boolean;
}
export interface ReceiptInputs
{
    transactionId?: string;
    ticketNumber?: string;
    receiptFormat?: string;
    returnTypeAsString?: boolean;
}

export type ReportDownloadFormat = 'PDF' | 'WORD' | 'EXCEL' | 'IMAGE' | 'HTML' | 'RAWDATA';

export enum AllReports {
    AppointmentListingByLocation = 'AppointmentByLocation',
    AppointmentListing = 'AppointmentListing',
    AppointmentByStaffMember = 'AppointmentByStaffMember',
    AppointmentByLinkCode = 'AppointmentByLinkCode',
    Itinerary = 'Itinerary',
    CommissionReport = 'CommissionReport',
    Gratuity = 'Gratuity',
    CustomField1 = 'CustomField1',
    CustomField2 = 'CustomField2',
    CustomField3 = 'CustomField3',
    CustomField4 = 'CustomField4',
    CustomField5 = 'CustomField5',
    Cancellation = 'Cancellation',
    NoShowAppointment = 'NoShowAppointment',
    ServiceCharge = 'ServiceCharge',
    StaffMemberSummary = 'StaffMemberSummary',
    MultiPackItems = 'MultiPackItems',
    ReturnedItems = 'ReturnedItems',
    InventorySummary = 'InventorySummary',
    InventoryDetails = 'InventoryDetails',
    InventoryTransfer = 'InventoryTransfer',
    InventoryWash = 'InventoryWash',
    InventoryAudit = 'InventoryAudit',
    SalesByDiscountTypes = 'SalesByDiscountTypes',
    SalesBySubCategory = 'SalesBySubCategory',
    SalesByCategory = 'SalesByCategory',
    SaleByItem = 'SaleByItem',
    CorrectVoid = 'CorrectVoid',
    Shift = 'Shift',
    RevenueSummary = 'RevenueSummary',
    Transaction = 'Transaction',
    Receipt = 'Receipt',
    ShoeRentalAgreement = 'ShoeRentalAgreement',
    GolfClubRentalAgreement = 'GolfClubRentalAgreement',
    CartRentalAgreement = 'CartRentalAgreement',
    GolfReceipt = 'GolfReceipt',
    SNCReceipt = "SNCReceipt",
    RetailReceipt = "RetailReceipt",
    PMSReceipt = "PMSReceipt",
    CheckZoom = 'CheckZoom'
}


export class UserSessionConfiguration {
    id?: number;
    userId: number;
    defaultOutletId?: number;
    defaultCourseId?: number;
    defaultPaymentDevice?: string;
    defaultDeviceName?: string;
    isIdtechSred: boolean;
    smallStickersPrinter?: string;
    hangingTicketsPrinter?: string;
    propertyId: number;
}

export interface ClientInfo {
    id: number;
    client: Client;
    phoneNumbers: PhoneNumber[];
    addresses: Address;
    emails: Email[];
    clientCreditCardInfo: ClientCreditCardInfo[];
   }  

   export interface ClientCreditCardInfo {
    id: number;
    clientId: number;
    tokenTransId: number;
    isActive: boolean;
    createdTime: Date | string | null;
  }

  export interface Client {
    id: number;
    guestId?: string,
    title: string;
    firstName: string;
    lastName?: string;
    pronounce: string;
    gender: string;
    dateOfBirth: string;
    comments:string;
    lastChangeId?: string;
    interfaceGuestId: string;
    loyaltyDetail: LoyaltyDetail[];
    memberId?: string;
    ClientCategoryId: number;
    consent:Date;
    consentExpiryDate:Date;
    consentPolicyId:number;
    isPurged:boolean;
    policyComments : string
  }

  
  export interface PhoneNumber {
    id ?: number;
    contactTypeId: number;
    clientId: number;
    countryCode ?: string | number;
    number: string;
    extension ?: string;
    isPrivate: boolean;
    isPrimary: boolean;
  }

  export interface Email {
    id ?: number;
    contactTypeId: number;
    clientId: number;
    emailId: string;
    isPrivate: boolean;
    isPrimary: boolean;
  }

  export interface Address {
    clientId: number;
    contactTypeId: number;
    addressLine1?: string;
    addressLine2: string;
    addressLine3: string;
    state?: string;
    State?: string;
    city: string;
    country?: string;
    Country?: string;
    zip: string;
    zipCode: string;
    isPrivate: boolean;
    county?: string;
  }

  export interface LoyaltyDetail{
    patronId: string;
    rank: string;
  }

  export interface UserTherapist{
    id: number;
    userId: number;
    therapistId: number;
  }

export interface LinkedRetailItemsData {
    id: number;
    linkedRetailItemDetails: string;
    linkCreatedDate?: Date;
    linkUpdatedDate?: Date;
    isActive?: boolean;
    sourceType?: string;
    sourceTypeId?: number;
    isEdit?: boolean;
    outletId?: number;
}

export interface TeeTimeLinkedRetailItemsData {
    playerRetailItemDetails: GroupRetailItems[][];
    isEdit?: boolean;
    retailItemType?: RetailItemType;
    outletId?: number
    
}

export interface PlayerRetailItem{
    id: number;
    scheduledTeeTimePlayerId: number;
    retailItems: string;
    retailItemType: RetailItemType;
    outletId? : number;
}

export interface RedeemMultiGridData {
    name: string;
    clientName: string;
    soldDate: string;
    expiryDate: string;
    remainingCount: number;
    isExpired: boolean;
    isSelected: boolean;
    retailItemId: number;
    multipackData: multiItemMultiPack;
    transactionDetailId: number;
}

export interface LockerAssignmentDetails {
    id: number;
    lockerId: number;
    uId: string;
    clientId: string;
    bookingType: LockerBookingSourceType;
    bookingTypeId: number;
    lockerPrefix: string;
    lockerBookingStartTime: string;
    lockerBookingEndTime: string;
    duration: number;
    lockerSequence: number;
    lockerNumber: string;
    isActive: boolean;
    amount: number;
    comments: string;
    transactionId?: Number;
    transactionDetailId ?: number;
    retailTicketNumber ?: number;
  }

  export enum LockerBookingSourceType {
    Client = 1,
    SpaAppointment,
    SpaClasses
  }

export interface ApplyTipAsGratuityData {
    paymentTransactionId: number;
    tipAmount: number;
    quickUserId: number;
}