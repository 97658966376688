import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CacheService } from "@app/core/services/cache.service";
import { PopupService } from "@app/popup-module/popup.service";
import { TableBlockBy, TableBlockingRuleFrequencyType } from "@app/shared/constants/commonenums";
import { DynamicFormComponent } from "@app/shared/dynamicform/dynamic-form/dynamic-form.component";
import { FieldConfig } from "@app/shared/dynamicform/models/field-config.interface";
import { TableBlockingRuleDTO, TableBlockingRuleFrequencyDTO, TableBlockingRuleRequest } from "@app/shared/models/TableBlockingDTO";
import { ExcludeLocationGroupPipe } from "@app/shared/pipes/ExcludeLocationGroup.pipe";
import { PartyService } from "@app/shared/services/party.service";
import { Utilities } from "@app/shared/utilities/utilities";
import { TranslateService } from "@ngx-translate/core";
import { orderBy } from "lodash";
import moment from "moment";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: 'app-quick-block-location',
    templateUrl: './quick-block-location.component.html',
    styleUrls: ['./quick-block-location.component.scss']
})
export class QuickBlockLocationComponent implements OnInit, AfterViewInit {

    blockLocationConfig: FieldConfig[] = [];
    locationBlockData: TableBlockingRuleDTO;
    subscriptions: Subscription = new Subscription();
    @ViewChild('blockLocationForm', { static: true }) blockLocationForm: DynamicFormComponent;
    errors: string[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public dialogData, public dialogRef: MatDialogRef<QuickBlockLocationComponent>, private _ts: TranslateService,
        private _cs: CacheService, private _pp: PopupService, public excludeLocationGroupPipe: ExcludeLocationGroupPipe, private partyService: PartyService) {
        this.locationBlockData = this.dialogData.componentDetails.popupInput[0].tableBlockData;
    }

    ngOnInit(): void {
        this.loadFormConfig();
    }

    loadFormConfig() {
        this.blockLocationConfig = [
            {
                type: 'date',
                name: 'CurrentDate',
                inputType: 'text',
                appearance: false,
                validation: [Validators.required],
                label: this._ts.instant("reservationSelectDate"),
                value: this.locationBlockData?.StartDate ? new Date(this.locationBlockData?.StartDate) : this._cs.headerDate,
                class: 'w-50',
                minDate: Utilities.getRestaurantDateTime(this._cs.settings.value.General.DaylightDelta),
                isStarSymbolRequired: true,
            },
            {
                type: 'select',
                name: 'Locations',
                label: this._ts.instant('Locations'),
                options: this.getLocations(),
                value: this.locationBlockData?.TableIds || [],
                appearance: false,
                validation: [Validators.required],
                isTranslate: true,
                selectMultipleOptions: true,
                disableErrorStateMatcher: true,
                class: "basic-select w-50",
                selectAscendingSortOrder: true,
                isStarSymbolRequired: true
            },
            {
                type: 'timepicker',
                name: 'StartTime',
                placeholder: this._ts.instant('starttime'),
                appearance: false,
                label: this._ts.instant('starttime'),
                class: "block-location__date w-100",
                validation: [Validators.required],
                value: this.locationBlockData?.EffectiveRange[0]?.Start ? this.locationBlockData?.EffectiveRange[0]?.Start.split(":").splice(0, 2).join(":") : null,
                isStarSymbolRequired: true,
            },
            {
                type: 'timepicker',
                name: 'EndTime',
                placeholder: this._ts.instant('endtime'),
                appearance: false,
                label: this._ts.instant('endtime'),
                class: "block-location__date w-100",
                validation: [Validators.required],
                value: this.locationBlockData?.EffectiveRange[0]?.End ? this.locationBlockData?.EffectiveRange[0]?.End.split(":").splice(0, 2).join(":") : null,
                isStarSymbolRequired: true,
            },
            {
                type: 'textarea',
                name: 'BlockingReason',
                label: this._ts.instant('ModifyReason'),
                inputType: 'text',
                showHint: true,
                charLength: 400,
                validation: [],
                value: this.locationBlockData?.BlockingReason || '',
                appearance: false,
                class: 'block-location__description w-100',
            }
        ];
    }

    ngAfterViewInit(): void {
        this.formValueChanges();
        this.isFormValid();
        let cancelSubscription = this._pp.cancelledAction$.subscribe(cancelled => {
            this.dialogRef.close({ action: "cancelled" });
            cancelSubscription?.unsubscribe();
        })
        let confirmSubscription = this._pp.confirmedAction$.subscribe(confirmed => {
            confirmSubscription?.unsubscribe();
            let reqObj: TableBlockingRuleDTO = this.getLocationBlockObj();
            this.dialogRef.close({ action: "confirmed", reqObj: reqObj });
        })
    }

    formValueChanges() {
        this.subscriptions.add(this.blockLocationForm.form.valueChanges
            .pipe(map((_) => this.blockLocationForm.form.getRawValue()))
            .subscribe(data => {
                if (data.Locations && data.StartTime && data.StartTime?.toLowerCase() != 'invalid date' && data.EndTime && data.EndTime?.toLowerCase() != 'invalid date') {

                }
                this.isFormValid();
            }));
    }

    isFormValid() {
        this.errors = [];
        if (!this.isValidTimeRangeSelected()) {
            this.errors = ["Invalid Time Range"]
        }
        if (this.blockLocationForm.valid && !this.errors.length) {
            this._pp.saveBtnEnable$.next(true);
        } else {
            this._pp.saveBtnEnable$.next(false);
        }
    }

    isValidTimeRangeSelected() {
        let [startHour, startMinutes] = this.blockLocationForm.value.StartTime.split(":");
        let [endHour, endMinutes] = this.blockLocationForm.value.EndTime.split(":");

        if (((+startHour * 60) + +startMinutes) > ((+endHour * 60) + +endMinutes)) {
            return false;
        }

        return true;
    }

    getLocations() {
        let locationList = [];
        let floorPlan = this._cs?.layout?.value?.FloorPlans;
        let allLocations = floorPlan?.filter(x => x.IsDefault == true)[0]?.StandaloneTables;
        allLocations = this.excludeLocationGroupPipe.transform(allLocations);
        allLocations = orderBy(allLocations, ['Name'], ['asc'])
        allLocations.forEach(table => {
            if (table.IsAvailableForReservations && !table.IsTemplate) {
                locationList.push({ id: table.Id, value: table.Name });
            }
        });

        return locationList;
    }

    getLocationBlockObj(): TableBlockingRuleDTO {
        let selectedDate = this.blockLocationForm?.form?.get('CurrentDate')?.value;
        let dateString: any = moment(selectedDate).format('YYYY-MM-DD');
        let locations = this.blockLocationForm?.form?.get('Locations')?.value;
        let startTime = this.blockLocationForm?.form?.get('StartTime')?.value;
        let endTime = this.blockLocationForm?.form?.get('EndTime')?.value;
        let reason = this.blockLocationForm?.form?.get('BlockingReason')?.value;
        return {
            StartDate: dateString,
            EndDate: dateString,
            Frequency: { Date: null, Type: TableBlockingRuleFrequencyType.TheOnlyDay },
            TableIds: locations,
            Id: this.locationBlockData?.Id,
            BlockBy: TableBlockBy.ByTimeRange,
            BlockingReason: reason,
            DayOfWeek: selectedDate.GetDay(),
            FrequencyType: TableBlockingRuleFrequencyType.TheOnlyDay,
            EffectiveRange: [{ Start: startTime, End: endTime, Reason: reason }]
        } as TableBlockingRuleRequest
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

}