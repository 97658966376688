<div *ngIf="!switchAxis; else switchAxisView" class="table-timeline-main">
  <div class="table-timeline__body" *ngIf="timeArrayGenerated && timeArrayGenerated.length && timeLineData ">
    <ng-container
      *ngIf="((timeLineData | timelineFilter : filterData : searchText)  | sortData: sortBy)  as filteredTimeLineData">
      <div *cdkVirtualFor="let rowData of filteredTimeLineData">
        <app-timeline-row [timeArrayGenerated]="timeArrayGenerated" [rowData]="rowData" [lastIndex]="lastIndex"
          [columnWidth]="columnWidth" [selectedViewId]="selectedViewId" [CurrentDateSelected]="CurrentDateSelected"
          [OpenHours]="OpenHours" [TimeSlotUnitInMinutes]="TimeSlotUnitInMinutes"
          [noOfBookings]="rowData.OpenPrivateReservations?.length" (addBookingToSlot)="addBookingToSlot($event)"
          (activitySelected)="activitySelected($event)" (promptBlockSession)="promptBlockSession($event)"
          (modifySession)="modifySession($event)" (cancelSession)="cancelSession($event)" (blockStaff)="blockStaff($event)" (deleteStaffBlock)="deleteStaffBlock($event)" (addEditLocationBlock)="addEditLocationBlock($event, rowData)" (deleteLocationBlock)="deleteLocationBlock($event, rowData)"></app-timeline-row>
      </div>

      <div class="noDataFound" style="padding: 10px 0;text-align: center;"
        *ngIf="!filteredTimeLineData || (filteredTimeLineData && !filteredTimeLineData.length)">
        {{'noDataFound' | translate}}
      </div>
    </ng-container>

  </div>
</div>
<ng-template #switchAxisView>
  <div class="table-timeline-main__switch">
    <div class="table-timeline__body--switch align"
      *ngIf="timeArrayGenerated && timeArrayGenerated.length && timeLineData ">
      <ng-container
        *ngIf="((timeLineData | timelineFilter : filterData : searchText)  | sortData: sortBy)  as filteredTimeLineData">
        <div *cdkVirtualFor="let rowData of filteredTimeLineData" class="column-container" [style.flexBasis]="rowData.expandMode ? 'auto' : ats.sessionWidth+'px'">
          <app-timeline-column style="display: inline-block;" [style.width]="rowData.expandMode ? 'auto' : ats.sessionWidth+'px'" [timeArrayGenerated]="timeArrayGenerated" [rowData]="rowData" [(expandMode)]="rowData.expandMode" [lastIndex]="lastIndex"
            [rowWidth]="columnWidth" [selectedViewId]="selectedViewId" [CurrentDateSelected]="CurrentDateSelected"
            [OpenHours]="OpenHours" [TimeSlotUnitInMinutes]="TimeSlotUnitInMinutes" [sessionWidth]="ats.sessionWidth"
            [noOfBookings]="rowData.OpenPrivateReservations?.length" (addBookingToSlot)="addBookingToSlot($event)" [switchAxis]="switchAxis" [sessionLength]="filteredTimeLineData.length"
            (activitySelected)="activitySelected($event)" (promptBlockSession)="promptBlockSession($event)"
            (modifySession)="modifySession($event)" (cancelSession)="cancelSession($event)" (blockStaff)="blockStaff($event)" (deleteStaffBlock)="deleteStaffBlock($event)"
            (addEditLocationBlock)="addEditLocationBlock($event, rowData)" (deleteLocationBlock)="deleteLocationBlock($event, rowData)"></app-timeline-column>
        </div>
        <div class="noDataFound" style="padding: 10px 0;text-align: center;"
          *ngIf="!filteredTimeLineData || (filteredTimeLineData && !filteredTimeLineData.length)">
          {{'noDataFound' | translate}}
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>