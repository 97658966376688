import { inject } from "@angular/core";
import { SettingsDTO } from "@app/shared/models/RestaurantDTO";
import { PartyService } from "@app/shared/services/party.service";
import { TranslateService } from "@ngx-translate/core";

export class CartPaymentHandler{

    partyService = inject(PartyService);
    ts = inject(TranslateService);
    ReservationAttemptData: any;

    _settings: SettingsDTO;

    currency: string = '$';

    proceedForPayment(_cartItems) {
        if (_cartItems && _cartItems.length) {
            let reservationAttemptDto = []
            _cartItems.forEach(cartItem => {
                cartItem.CartItemDetail.Sessions.SessionDTOs = cartItem.CartItemDetail.Sessions.Sessions.map(sessionValue => {
                    return {
                        Date: sessionValue.Date,
                        SessionId: sessionValue.SessionIds[0]
                    }
                })
                let _payingContacts = cartItem.CartItemDetail.BookingContacts.filter(({ContactId}) => ContactId).map(({ ContactId }) => ContactId);
                let _contactIdentifier = cartItem.CartItemDetail.BookingContacts.filter(({ContactIdentifier, IsSystemGenerated}) => IsSystemGenerated && ContactIdentifier).map(({ ContactIdentifier }) => ContactIdentifier);
                let _payingGuests = cartItem.CartItemDetail.Sessions.Sessions.map(session => {
                    return {
                        ActivitySessionId: session.SessionIds[0],
                        BookingDate: session.Date,
                        PayingContacts: _payingContacts,
                        ContactIdentifier: _contactIdentifier
                    }
                })
                let _cartAttepmtDTO = {
                    PropertyId: cartItem.PropertyId,
                    PartySize: cartItem.CartItemDetail.Size,
                    ActivityId: cartItem.CartItemDetail.SpecialMealId,
                    InstructorIds: cartItem.CartItemDetail.InstructorIds,
                    SeatingTypeIds: cartItem.CartItemDetail.SeatingTypeIds,
                    TableIds: cartItem.CartItemDetail.TableIds,
                    Slots: cartItem.CartItemDetail.Slots?.map(slotData => { return { LocalTime: slotData.Time, Duration: slotData.DurationInMinutes } }),
                    Sessions: cartItem.CartItemDetail.Sessions,
                    CoverTypes: cartItem.CartItemDetail.CoverTypes,
                    BookingTypes: cartItem.CartItemDetail.BookingTypes,
                    BookingBehavior: cartItem.CartItemDetail.BookingBehavior,
                    NegotiatedAmount: cartItem.CartItemDetail.NegotiatedAmount,
                    MemberType: cartItem.CartItemDetail.Contact?.IsMemberActive && cartItem.CartItemDetail.Contact.MemberType ? cartItem.CartItemDetail.Contact.MemberType : null,
                    CartId: cartItem.Id,
                    StartDate: cartItem.CartItemDetail.StartDate,
                    EndDate: cartItem.CartItemDetail.EndDate,
                    AddOns: cartItem.CartItemDetail.SelectedAddOns,
                    PayingGuests: _payingGuests,
                    PaidBy: cartItem.CartItemDetail.PaidBy || cartItem.CartItemDetail.Contact.Id
                }
               
                reservationAttemptDto.push(_cartAttepmtDTO)
            })
            this.prePaymentBookingOperation(reservationAttemptDto, _cartItems)

            //   if (this.partyService.bookingPaymentType == PaymentMethod.Prepayment) {
            //     this.prePaymentBookingOperation(reservationAttemptDto, cartIds)
            //   } else if (this.partyService.IsSkipPayment) {
            //     this.skipPaymentOperation(cartIds);
            //   }

        }
    }

    prePaymentBookingOperation(reservationAttemptDto, cartItems, isIgnored: boolean = false) {
        this.partyService.proceedCartBookingForMultipleProperties(reservationAttemptDto, isIgnored).subscribe(data => {
            let _cartIds = cartItems.map((cartItem: any) => cartItem.Id);
            this.ReservationAttemptData = data.Payload.ResponseDTOs;
            let _cartItemsSelected = cartItems.map(item => {
                let data = this.ReservationAttemptData.find(cart => cart.CartId == item.Id)
                if (data) {
                    item.TotalPackageDiscountPercent = data.TotalPackageDiscountPercent ? data.TotalPackageDiscountPercent : null;
                    item.TotalPackageDiscountedAmount = data.TotalPackageDiscountedAmount ? data.TotalPackageDiscountedAmount : null;
                    item.PackageId = this.partyService.selectedPackage ? this.partyService.selectedPackage.Id : null;
                    item.selected = true;
                }
                return item;
            })
            this.partyService.TotalCartBookingObj = _cartItemsSelected;
            this.partyService.ReservationAttemptData = data.Payload.ResponseDTOs;
            this.partyService.MultipleReservationAttemptData = data.Payload;
            this.partyService.MultipleReservationAttemptData.PaidBy = reservationAttemptDto[0]?.PaidBy;
            if (data.Payload.ShouldCreditCardBeEntered && (data.Payload.ShopItems.length || data.Payload.ReturnItem.length)) {
                if (this._settings.General.RetailIntegrationDTO.IsEnabled) {
                    this.partyService.Operations = 1;
                    this.partyService.proceedToRetailPayment = true;
                    this.partyService.RetailCreateOperation(_cartIds);
                }
                else {
                    if (data.Payload.CreditCardIframeUrl) {
                        // this.showIframe = true;
                        // this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.Payload.CreditCardIframeUrl + "&origin=" + window.location.origin)
                        // this.iframeURL = this.iframeURL.changingThisBreaksApplicationSecurity ? this.iframeURL.changingThisBreaksApplicationSecurity : this.iframeURL;
                        // this.PaymentGateway = data.Payload.PaymentGateway;
                        // this._as.isCartPaymentInProgress = true;
                    }
                    else {
                        this.showMessage(this.ts.instant('SorryForPaymentOffline'));
                    }
                }
            }
        });
    }

    showMessage(msg: string){}
}