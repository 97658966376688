<div id="cust-header-id">
    <app-dialog-header [title]="captions.addCustomFeeTitle" (closeDialog)="close()"></app-dialog-header>
</div>
<div class="edit-custom-fee-wrapper ag_container--padding-md">
    <form class="edit-custom-fee-popover-body" autocomplete="off" [formGroup]="addCustomFeeForm">
        <!-- <label for="" class="text-calitalise d-block form-label-normal">Custom Fee</label> -->
        <div>
            <ag-dropdown class="ag_display--inblock ag_form-control--3" [placeholder]="captions.customFeeCheckbox"
            formControlName="customfee" [source]="dropdown" [multiple]="true" [isAll]="shouldAllBeEnabled" [dropdownWithSearch]="true">
            </ag-dropdown>
        </div>
    </form>
</div>
<div class="edit-custom-fee-popover-footer">
<button mat-button class="mt-2" [disabled]="!isFormValid()"
    [ngClass]="!isFormValid() ? 'button--disabled' : 'button--primary-save'"
    (click)="apply()">{{captions.customFee_apply}}</button>
        <button mat-button (click)="close()" class="text-capital ml-2">{{captions.customFee_Cancel}}</button>
</div> 