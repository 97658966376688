import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { buttonTypes, ComponentTypes, filterType, PricingBy, RolesAndPermissionsType, Status } from '@app/shared/constants/commonenums';
import { popupDialogDimension } from '@app/shared/constants/globalConstants';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { AuditableEntityType } from '@app/shared/models/SimpleAuditLogItemDTO';
import { Utilities } from '@app/shared/utilities/utilities';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuditlogComponent } from '../auditlog/auditlog.component';
import { QrCodeComponent } from '../qr-code/qr-code.component';
import { GetSnackBarMessagesPipe } from '@pipes/snackbar-messages.pipe'
import { PrePaymentMode } from '@app/shared/models/RestaurantDTO';
import { urlConfig } from '@app/shared/constants/url-config';
import { groupBy } from 'lodash';
/// <reference types="../../clipboard" />



@Component({
  selector: 'app-activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityTableComponent extends Utilities implements OnInit {
  @Input() tableData;
  @Input() createButton;
  @Input() isEdit;
  @Input() isTemplate;
  @Input() componentName;
  @Input() hideDateFilter;
  @Input() activities;
  @Input() selectedFilters;
  resetButton: ButtonValue;
  config: FieldConfig[];
  displayedColumns;
  dataSource;
  sortedData: any;
  reservationUrl: string = urlConfig.noReservationsUrl;
  subscriptions: Subscription = new Subscription();
  activityFilter:any;
  selectedMenu: any[] = [];
  @Output() createEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeLesson: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('configForm', { static: true }) configForm: DynamicFormComponent;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  rolesAndPermissionsType = RolesAndPermissionsType;

  constructor(protected dialog: MatDialog, public ts: TranslateService, public popupService: PopupService , private snackbar: GetSnackBarMessagesPipe) {
    super(dialog)
  }

  ngOnInit() {
    this.loadDateConfig();
    this.displayedColumns = this.tableData.header;
    this.dataSource = new MatTableDataSource(this.tableData.body);
    this.dataSource.sort = this.sort;
    this.getFilterList();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.tableData?.currentValue) {
      this.dataSource = new MatTableDataSource(this.tableData.body);
      if(this.configForm?.form?.controls?.searchText?.value) {
        this.dataSource.filter = this.configForm?.form?.controls?.searchText?.value?.trim()?.toLowerCase();
      }
    }
  }

  ngAfterViewInit() {
    this.configForm.form.valueChanges.subscribe(date => {
      this.filterDateRange(date);
    })
  }
  toggleChange(event, data) {
    event
    if (data) {
      this.removeLesson.emit({ id: data.ActivityID, value: event.target.checked });
    }
  }
  deleteItem(data?){
    if (data) {
      this.removeLesson.emit({ id: data.ActivityID, value: true });
    }
  }

  filterDateRange(dateValue) {
    if (dateValue.startDate && dateValue.endDate) {
      this.dataSource.data = this.tableData.body.filter(e => e.start >= new Date(dateValue.startDate) && e.end <= new Date(dateValue.endDate));
    }
  }

  applyFilter(date) {
    const filterValue = date.currentTarget.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  createActivity(data?) {
    this.createEvent.emit(data?.ActivityID ? { id: data.ActivityID, isEdit: true } : null);
  }
  loadDateConfig() {

    this.config = [
      {
        type: 'date',
        name: 'startDate',
        inputType: 'text',
        class: 'activity-details__from-date',
        label: this.ts.instant("fromDate"),
        appearance: false,
        value: null,
        showNavigation: false,
        disabled: false
      },
      {
        type: 'date',
        name: 'endDate',
        inputType: 'text',
        class: 'activity-details__to-date',
        label: this.ts.instant("toDate"),
        appearance: false,
        value: null,
        showNavigation: false,
        disabled: false
      },
    {
      type: 'input',
      name: 'searchText',
      label: this.isTemplate ? this.ts.instant('SearchTemplate') : this.ts.instant('SearchActivity'),
      class: 'search-text',
      showErrorText: true,
      appearance: true,
      icon: 'icon-search',
      icon1: 'icon-Group-591',
      cellClick: this.clearSearch.bind(this),
      blurClick: this.applyFilter.bind(this)
    }];
    if(this.hideDateFilter){
     this.config.splice(0,2);
  }
  if(this.componentName == ComponentTypes.templateSelection){
    this.config.splice(0,2);
 }
    this.resetButton = {
      label: 'reset',
      type: buttonTypes.actionPrimarySmall
    };
  }

  resetFields() {
    this.configForm.form.controls.startDate?.setValue(null, { emitEvent: false });
    this.configForm.form.controls.endDate?.setValue(null, { emitEvent: false });
    this.configForm.form.controls.searchText?.setValue('', { emitEvent: false });
    this.dataSource.filter = "";
    this.filterEvent.emit({'selection':this.selectedMenu,'filteredDate': this.componentName != ComponentTypes.templateSelection ? {startDate: this.configForm.form.controls.startDate?.value,endDate: this.configForm.form.controls.endDate?.value}: null});
    this.dataSource = new MatTableDataSource(this.tableData.body);
    this.selectedFilters = null;
  }

  clearSearch(data) {
    this.configForm.form.controls.searchText.setValue('');
    this.dataSource.filter = "";
  }

  create() {
    this.createEvent.emit()
  }

  loadQRCode(data?) {
    let activity = this.activities.find(activity => activity.Id == data?.ActivityID);
    let url = activity?.ActivityBookingURL;
    let qrImage = activity?.ActivityQRCode
    const popUpMessage = [{
      dialogTitle: this.ts.instant('QRCode'),
      showAlert: false,
      qrCode: url
    }];
    const popupType = window.navigator['clipboard'] && window.navigator['clipboard']['write'] ? '' : 'action';
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(QrCodeComponent, 'small', popupType, popUpMessage,
      popUpMessage[0].dialogTitle);
    let headlinePopup = this.openCustomPopup(componentDetails, ComponentTypes.ActivitiesQRCode, '400px', 'auto', false, popUpMessage[0].dialogTitle, this.ts.instant('CopyBookingUrl'),
      this.ts.instant('CopyQRCode'), true);
    let urlCopyConfirmation = this.popupService.confirmedAction$.subscribe(val => {
      if (urlCopyConfirmation) {
        urlCopyConfirmation.unsubscribe();
      }
      if (val === ComponentTypes.ActivitiesQRCode) {
        var aux = document.createElement("input");
        aux.setAttribute("value", url);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        this.snackbar.transform(this.ts.instant('BookingUrlCopyConfirmation'));
      }
    })
    let ImageCopyConfirmation = this.popupService.cancelledAction$.subscribe(async val => {
      if (ImageCopyConfirmation) {
        ImageCopyConfirmation.unsubscribe();
      }
      if (val.from === ComponentTypes.ActivitiesQRCode && val.value == 1) {
        //Commented for build issue - Future reference
        const canvasBlob = this.base64ToBlob(qrImage,'image/png',512);
        await window.navigator['clipboard']['write']([
          new window['ClipboardItem']({
            'image/png': canvasBlob
          }),
        ]);
        this.snackbar.transform(this.ts.instant('QRcodeCopyConfirmation'));
      }
    })
  }
  sortData(sort: Sort) {
    const data = this.tableData.body.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  showPartyAuditLog(tableData) {
    const showAlert = false;
    const title = this.ts.instant('auditlog') + ` - ${tableData.activityName}`
    const popUpMessage = [{
      dialogTitle: title, showAlert, type: AuditableEntityType.Activity, activity: tableData
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(AuditlogComponent, 'large', 'action', popUpMessage, popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.reservation, popupDialogDimension.createDialogMaxWidth, popupDialogDimension.createDialogMaxHeight, false, title);
  }

  base64ToBlob(base64Data, contentType, sliceSize) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  getFilterList() {
    this.activityFilter = [
      {
        menuName: this.ts.instant('Status'),
        subMenu: [
          {
            value: Status.Approved,
            key: filterType.StatusCode,
            name: this.ts.instant('Active'),
            id: 1,
            selected:false,

          },
          {
            value: Status.Inactive,
            key: filterType.StatusCode,
            name: this.ts.instant('InActive'),
            id: 2,
            selected:false
          }
        ]
      },
      {
        menuName: this.ts.instant('activityType'),
        subMenu: [
          {
            value: PricingBy.Session,
            key: filterType.ClassType,
            name: this.ts.instant('Session'),
            id: 3,
            selected:false

          },
          {
            value: PricingBy.Class,
            key: filterType.ClassType,
            name: this.ts.instant('Class'),
            id: 4,
            selected:false
          },
          {
            value: PricingBy.Duration,
            key: filterType.ClassType,
            name: this.ts.instant('Private'),
            id: 5,
            selected:false
          },
        ]
      },
      {
        menuName: this.ts.instant('reservationtype'),
        subMenu: [
          {
            value: false,
            key: filterType.StandbyReservations,
            name:this.ts.instant('confirmed'),
            id: 6,
            selected:false

          },
          {
            value: true,
            key: filterType.StandbyReservations,
            name: this.ts.instant('standby'),
            id: 7,
            selected:false
          }
        ]
      },
      {
        menuName: this.ts.instant('PaymentType'),
        subMenu: [
          {
            value:PrePaymentMode.None,
            key: filterType.PrePaymentMode,
            name:this.ts.instant('None'),
            id: 8,
            selected:false,

          },
          {
            value: PrePaymentMode.PrepaidPaymentMode,
            key: filterType.PrePaymentMode,
            name: this.ts.instant('Chargeonbooking'),
            id: 9,
            selected:false
          }
        ]
      }
    ];
  }
  filterSelection(selection) {
    // let filtersByType = groupBy(selection, 'key')
    // let filteredActivity = this.dataSource.data || [];
    // let StatusCode = filtersByType[filterType.StatusCode]?.map(status => { return status.value })
    // let ClassType = filtersByType[filterType.ClassType]?.map(status => { return status.value })
    // let StandbyReservations = filtersByType[filterType.StandbyReservations]?.map(status => { return status.value })
    // let PrePaymentMode = filtersByType[filterType.PrePaymentMode]?.map(status => { return status.value })
    // this.dataSource.data  = filteredActivity?.filter(activity => {
    //   return (StatusCode ? StatusCode.includes(activity.StatusCode) : true) 
    //   && (ClassType ? ClassType.includes(activity.ClassType) : true) 
    //   && (StandbyReservations ? StandbyReservations.includes(activity.IsForStandbyReservations) : true)
    //   && (PrePaymentMode ? PrePaymentMode.includes(activity.PrePaymentMode) : true);
    // });
    this.selectedMenu = selection;
    this.filterEvent.emit({'selection':selection,'filteredDate': this.componentName != ComponentTypes.templateSelection ? {startDate: this.configForm.form.controls.startDate.value,endDate: this.configForm.form.controls.endDate.value}: null});
  }
}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


