import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '@app/app.service';
import { TabsModel } from '@app/popup-module/models/popup.interface';
import { Validations } from '@app/reports/report.model';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { environment } from '@environments/environment';
import { CustomGuestField, FullContactDTO } from '@models/InputContact';
import { SettingsDTO } from '@models/RestaurantDTO';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { customGuestFieldConfigPipe } from '@app/shared/pipes/custom-guest-field-validation.pipe';

@Component({
  selector: 'app-additionalinfo',
  templateUrl: './additionalinfo.component.html',
  styleUrls: ['./additionalinfo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditionalinfoComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() tabsConfig: TabsModel;
  config: FieldConfig[];
  notesForm: UntypedFormGroup;
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  selectedIndex: number;
  tabChangeSubscription: ISubscription;
  settingsSubscription: ISubscription;
  customFields: CustomGuestField[] = [];
  fieldType: string[] = ['Text Field', 'Drop Down']
  _settings: SettingsDTO = {} as SettingsDTO;
  dropDownFieldOption: { dropDownCustomFieldId: any, options: { id: number, value: string }[] };
  subscriptions: Subscription = new Subscription();

  constructor(public fb: UntypedFormBuilder, private gbs: GuestBookService, @Inject(COMPONENTINPUT) private data: FullContactDTO,
    private partyService: PartyService, private ps: PopupService, private cdf: ChangeDetectorRef,
    private as: AppService, public cs: CacheService , public _customGuestFieldConfigPipe :customGuestFieldConfigPipe) {
    this.settingsSubscription = cs.settings.subscribe(sett => {
      this._settings = sett;
    });
  }

  ngOnInit() {
    this.customFields = this._settings.CustomGuestFields;
    this.gbs.dropDownFieldOptions = [];
    this.config = [];
    if (this.customFields.length > 0) {
      for (const [index, customField] of this.customFields.entries()) {
        let fieldConfig: FieldConfig;
          switch (customField.FieldType) {
            case this.fieldType[0]:
              fieldConfig = {
                type: 'input',
                name: `customField${index}`,
                inputType: 'text',
                label: customField.FieldName,
                placeholder: customField.FieldDescription,
                class: 'additional-info__custom-fields',
                id: customField.Id,
                validation: this.isRequiredCustomField(customField.Id) ? [Validators.required] : [],
                isReadOnly: (environment.SEAProduction == true && environment.MerchantId == this._settings.General.MerchantId && customField.FieldName == 'Total Spend') ? true : false
              };
              let updateConfig =  this._customGuestFieldConfigPipe.transform([fieldConfig] , customField.SelectedFieldMetric)
               fieldConfig = updateConfig ? updateConfig[0] : fieldConfig;
              break;
            case this.fieldType[1]:
              const fieldOptions = customField.FieldOptions.split(',').map((option, i) => {
                return { id: i + 1, value: option };
              });
              let fieldValidation = this.isRequiredCustomField(customField.Id) ? [Validators.required] : []
              this.dropDownFieldOption = { dropDownCustomFieldId: customField.Id, options: fieldOptions };
              this.gbs.dropDownFieldOptions.push(this.dropDownFieldOption);
              fieldOptions.splice(0, 0, { id: 0, value: "None" });
              fieldConfig = {
                type: 'select',
                label: customField.FieldName,
                name: `customField${index}`,
                placeholder: customField.FieldDescription,
                options: fieldOptions,
                class: 'additional-info__custom-fields',
                id: customField.Id,
                validation: fieldValidation,
                isReadOnly: (environment.SEAProduction == true && environment.MerchantId == this._settings.General.MerchantId && customField.FieldName == 'Total Spend') ? true : false,
                highlightRequired :fieldValidation?.length > 0
              };
              break;
          }
          this.config.push(fieldConfig);
      }
    }
    const allNotes = {};
    for (const addInfo of this.config) {
      allNotes[addInfo.name] = '';
    }
    const notesFormGroup = this.fb.group(allNotes);
    this.gbs.guestForm.addControl('notesForm', notesFormGroup);
  }

  isRequiredCustomField(fieldId){
    return this.ps.additionalInfo?.requiredCustomGuestFields ? this.ps.additionalInfo.requiredCustomGuestFields[fieldId] : false;
  }

  ngAfterViewInit() {
    this.selectedIndex = this.gbs.tabsModal.tabs.findIndex(x => x.tabComponent === AdditionalinfoComponent);
    this.tabChangeSubscription = this.partyService.tabChange$.subscribe((data) => {
      if (data === this.selectedIndex) {
        const isGuestBookFormValid = this.gbs.guestForm.dirty;
        if (this.ps.tabsActionData?.length) {
          this.ps.tabsActionData[this.selectedIndex].gotoNextTab = isGuestBookFormValid && this.dynamicForm.form.valid;
        }
        this.ps.tabsActions$.next(this.ps.tabsActionData);
      }
    });
    this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe((data) => {
      this.gbs.guestForm.get('notesForm')?.patchValue(data);
      if (this.dynamicForm.form.dirty) {
        this.gbs.guestForm.get('notesForm')?.markAsDirty();
        this.gbs.guestForm.markAsDirty();
      }
      if (this.dynamicForm.form.valid) {
        if (this.ps.tabsActionData?.length) {
          this.ps.tabsActionData[this.selectedIndex].gotoNextTab = this.dynamicForm.form.valid;
        }
        this.ps.tabsActions$.next(this.ps.tabsActionData);
      }
    }));
    if (this.data) {
      const availableNotes = this.data.Notes?.filter((note) => note.RelatedId === null);
      this.dynamicForm.form.patchValue({
        internalNotes: (availableNotes?.length && availableNotes[0]) ? availableNotes[0].Text : ''
      });
      this.config.forEach((data, index) => {
        if (data.name.includes('customField')) {
          const customFieldValue = this.customFields.filter((field) => field.Id === data.id);
          const contactCustomField = this.data.ContactCustomFields.filter((contact) => contact.CustomFieldId === customFieldValue[0].Id);
          if (customFieldValue[0].FieldType === this.fieldType[0]) {
            this.dynamicForm.form.get(data.name).setValue(
              contactCustomField.length > 0 ? contactCustomField[0].CustomFieldValue : '');
          } else {
            if (contactCustomField && contactCustomField.length > 0) {
              const customField = this.gbs.dropDownFieldOptions.filter(dropDownField => dropDownField.dropDownCustomFieldId == customFieldValue[0].Id);
              let customFieldOptionArr = [];
              if (customField.length > 0) {
                customFieldOptionArr = customField[0].options.filter(option => option.value == contactCustomField[0].CustomFieldValue);
              }
              this.dynamicForm.form.get(data.name).setValue(customFieldOptionArr.length > 0 ? customFieldOptionArr[0].id : '');
            }
          }
        }
      });
    }
    this.cdf.detectChanges();
  }

  ngOnDestroy() {
    this.dynamicForm.form.reset();
    if (this.tabChangeSubscription) {
      this.tabChangeSubscription.unsubscribe();
    }
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
