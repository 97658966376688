<div class="layout iframe-load h-100">
  <div class="layout__header " *ngIf="isLoaded">
    <app-header (onCartClick)="cartClick($event)"></app-header>
    <div [class.showcart]="disableCart" [hidden]="true" class="layout__cart-container">
      <app-cart-items-display [showCartItem]="disableCart" [count]="ps.cartCount" [cartUpdated]="ps.isCartUpdated"></app-cart-items-display>
    </div>
  </div>
  <div class="layout h-100" *ngIf="isLoaded && !Activitiestimeline">   
    <app-time-line></app-time-line>
  </div>

  <div class="layout h-100" *ngIf="isLoaded && Activitiestimeline">
    <app-activities-main-timeline></app-activities-main-timeline>
  </div>

  <section class="layout__template-container" *ngIf="ps.viewBookings">
    <app-booking-manager [filterData]="_as?.sessionDataFilter"></app-booking-manager>
  </section>
</div>