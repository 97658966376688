<div class="open-reservation">
  <div class="open-reservation__form-main">
    <ng-scrollbar >
      <div class="pr-3">
        <dynamic-form [config]="dateConfig" #dateFormDetails></dynamic-form>
        <div [hidden]= "standbySlotTime &&  ps.reservationData.selectedSlot && !this.partyService.isOverBookSlotSelected && ps.reservationData?.selectedSlot?.LimitedMaxPartySize <= partyService.bookingSize">
        <dynamic-form [config]="specialMealConfig" #specialMealDetails></dynamic-form>
        </div>
        <div [hidden]="showCoverTypes">
          <dynamic-form [config]="partySizeConfig" #partySizeDetails></dynamic-form>
        </div>
        <div [hidden]="!showCoverTypes">
          <div class="coverType-seat-label">
            <label class="seat-labels">
              {{'coverType' | translate}}
            </label>
          </div>
          <div [hidden]="!showCoverTypes" class="cover-types" [ngClass]="{'w-100' : coverTypeConfig?.length == 1}">
            <div class="cover-types-addon">
              <dynamic-form [config]="coverTypeConfig" #coverTypeDetails></dynamic-form>
            </div>
          </div>
        </div>
        <dynamic-form [config]="bookingFormConfig" #bookingFormDetails></dynamic-form>
        <div class="activity-booking__emailNotifications" *ngIf="settings">
          <app-staff-notifications [settings]="settings" [specialMealId]="null" [emailList]="mealData?.NotificationEmailIds" [SalesContactIds]="mealData?.SalesContactIds" (notificationEvent)="notificationEvents($event)"></app-staff-notifications>
        </div>
      </div>
    </ng-scrollbar>
  </div>
  <div class="open-reservation__time-slot">
    <div class="general-info__view-legends">
      <app-display-legend [legends]="displayLegends"></app-display-legend>
    </div>
    <div class="open-reservation__slot-details">
      <div class="time-details">
        <div class="slot-title"> {{(ps.reservationData.selectedSlot ? 'PopUpSelectedTime' : 'PopUpSelectTime') |
          translate}}</div>
          <div *ngIf="ps.reservationData.selectedSlot && partyService.slots_holder?.length > 0 && ps.reservationData?.selectedSlot?.LimitedMaxPartySize >= partyService.bookingSize" class="slot-time"> {{ps.reservationData.selectedSlot.slotName}} --
          {{ps.reservationData.selectedSlot.DateTime | slotTimeFormatter}}</div>
          <div *ngIf="standbySlotTime && this.ps.reservationData.selectedSlot && !this.partyService.isOverBookSlotSelected && ps.reservationData?.selectedSlot?.LimitedMaxPartySize <= partyService.bookingSize" class="slot-time standby">
            {{'standby' | translate}} : {{standbySlotTime | slotTimeFormatter}}</div>
      </div>
      <div class="table-details">
        <div class="suggest-tables" *ngIf="showSuggestedTables && suggestedTableName" (click)="assignSuggestedTable()">
          <span>{{suggestedTableName}}</span> <span class="icon-check_circle_FILL0_wght400_GRAD0_opsz24"></span>
        </div>
        <div class="open-reservation__table-assign">
          <activities-app-button [buttontype]="buttonTertiary" (valueChange)="selectedTable()"></activities-app-button>
        </div>
      </div>
    </div>
    <div class="specialmeal__time-slot">
      <ng-scrollbar>
      <app-wait-time (slotUpdateEvent)="onSlotUpdate($event)" [specialMealFilter]="{filterByMeal : true , meal : ps.reservationData.selectedMeal}" [defaultValueForAllSlots]="standbySlotTime"></app-wait-time>
      </ng-scrollbar>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="showWarning && warningMessage">
      <span class="icon-error_FILL0_wght400_GRAD0_opsz24"></span> <span> {{warningMessage}}</span>
      <button type="button" class="close cursor" (click)="closeWarning()" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="alert alert-warning no-table" role="alert" *ngIf="showTableConfirmation && !showWarning">
      <div> <span class="icon-error_FILL0_wght400_GRAD0_opsz24"></span> {{ warningMessage | translate}} </div>
      <div class="table-action">
        <activities-app-button [buttontype]=" cancelButton" (valueChange)='confirmAction(false)'></activities-app-button>
        <activities-app-button [buttontype]="confirmButton" (valueChange)='confirmAction(true)'></activities-app-button>
      </div>
    </div>
  </div>
</div>