import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { ApiService } from "@app/activities-timeline/services/api.service";
import { DynamicFormComponent } from "@app/shared/dynamicform/dynamic-form/dynamic-form.component";
import { FieldConfig } from "@app/shared/dynamicform/models/field-config.interface";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'member-select',
    templateUrl: './member-select.component.html',
    styleUrls: ['./member-select.component.scss']
})
export class MemberSelectComponent implements OnInit, AfterViewInit {
    
    @Input() showSelectedGuest: boolean = true;
    @Output() onMemberSelect: EventEmitter<any> = new EventEmitter();
    
    public membershipDetailConfig: FieldConfig[];
    public MemberDetailsArray = [];
    @ViewChildren('form') components: QueryList<DynamicFormComponent>;
    subscriptions: Subscription = new Subscription();
    memberShipArr: any[];
    memberShipdetail: any;

    constructor(private apiService: ApiService) {

    }
    ngOnInit(): void {
        this.membershipDetailConfig = [{
            type: 'autocomplete',
            name: 'membershipId',
            inputType: 'text',
            options: this.MemberDetailsArray,
            showErrorText: true,
            icon: 'icon-search',
            icon1: 'icon-Group-591',
            appearance: false,
            isTranslate: false,
            partySize: false,
            label: 'searchbyphoneandemail',
            cellClick: this.setSelectedGuest.bind(this),
            returnFunction: this.clearmembershipDetail.bind(this)
        }];


    }

    ngAfterViewInit(): void {
        const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.components.forEach((x, index) => {
            this.subscriptions.add(x.form?.valueChanges.pipe(debounceTime(100),
            distinctUntilChanged()).subscribe(value => {
                if ((value.membershipId.length == 16 && !isNaN(value.membershipId)) || (value.membershipId.length == 9 && !isNaN(value.membershipId))|| regularExpression.test(String(value.membershipId).toLowerCase())) {
                    this.memberShipdetail = {};
                    this.CheckMemberShip(value.membershipId, null);
                }
            }))
        })
    }

    CheckMemberShip(membershipId, editContactData) {
        this.memberShipArr = [];
        this.subscriptions.add(this.apiService.wildSearchEngageMember(membershipId).subscribe(response => {
            this.memberShipArr = response.Payload;
            this.MemberDetailsArray = [];
            this.memberShipArr.forEach((element, index) => {
                this.MemberDetailsArray.push({
                    id: index,
                    value: this.getMemberOptionValue(element, index)
                })
            });
            this.membershipDetailConfig[0].options = this.MemberDetailsArray;
            this.membershipDetailConfig[0].value = membershipId;
        }));
    }


    getMemberOptionValue(data, index) {
        return '' + (index + 1) + ' ' + data.FName + ' - ' + data.CardNo + ' - ' + data.Email + ' - ' + data.PhoneNumber
    }

    setSelectedGuest() {
        const [memberdetail] = this.getFormComponent('membershipId');
        const seectedGuestValue = memberdetail.form.controls.membershipId.value;
        let selectedIndex = Number(seectedGuestValue.substring(0, 1));
        memberdetail.form.controls.membershipId.setValue('');
        this.memberShipdetail = this.memberShipArr[selectedIndex - 1];
        this.onMemberSelect.emit(this.memberShipdetail);
    }

    clearmembershipDetail(event) {
        const [memberdetail] = this.getFormComponent('membershipId');
        memberdetail.form.controls.membershipId.setValue('');
    }

    getFormComponent(...args) {
        return args.map(arg => this.components.find(form => form.value[arg]))
    }
}