import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { GetReservationsOperationOptions, LoadRestaurantOptions, LoginOperation, Menu, propertyOperationType, SlottingType } from '../constants/commonenums';
import { urlConfig } from '../constants/url-config';
import { PayloadOperationResultDTO } from '../models/ChangeTrackingOperationResultDTO';
import { FloorPlanImagesDto } from '../models/FloorPlanImageInputDto';
import { LoginResultDTO, RestaurantAvailableForLoginDTO, RoleType } from '../models/LoginResultDTO';
import { RestaurantDTO, RestaurantNoteDTO } from '../models/RestaurantDTO';
import { loginState } from '../models/SignalRDto';
import { Utilities } from '../utilities/utilities';
import { HttpService } from './http.service';
import { SignalrService } from './signalr.service';
import { endOfDay, format, startOfDay } from 'date-fns';
import { map } from 'rxjs/operators';
import { DateStateDTO } from '../models/InputReservation';
import { DashboardFunctions } from '../utilities/dashboard-functions';
import { PartyService } from './party.service';
import { SettingsService } from './settings.service';
import { TablesService } from './tables.service';
import sideMenu from '@data/sidemenu.json';
import sidemenuforhost from '@data/sidemenuforhost.json';
import _ from 'lodash';
import sidemenuforTennisProperty from '@data/sidemenuforTennisProperty.json';
import sidemenuforTheatreProperty from '@data/sidemenuforTheatreProperty.json'
import { LoginFunction } from '../utilities/login-function';
import { MachineName } from '@app/common/shared/shared.modal';
import * as globals from '@constants/globalConstants';
import { RetailLoginService } from './retail-login.service';
import { LoaderService } from '@app/core/services/loader.service';
import { RetailUtilities } from '@app/retail/shared/utilities/retail-utilities';


export enum HttpVerbs {
  GET, POST, PUT, DELETE
}
@Injectable({
  providedIn: 'root'
})
export class LoadMultiplePropertiesService {
  availablePropertiesForLogin:RestaurantAvailableForLoginDTO[];
  lastLoggedInRestaurant: number;
  callBackCount:number;
  editFromCart : boolean = false;
  constructor(private http: HttpService, private cs: CacheService, private signalR: SignalrService, private as: AppService,
              private dashboardFunctions: DashboardFunctions,private ss:SettingsService, private ps:PartyService,public ts:TablesService,private lf:LoginFunction, private retailLogin:RetailLoginService,private ls:LoaderService,private utils: RetailUtilities) { }

  init() {
    let loginResult: LoginResultDTO = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    let currentPropertyId = +sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`);
    let availablePropertiesForLogin: RestaurantAvailableForLoginDTO[] = loginResult.RestaurantsAvailableForLogin.filter(property => property.Id !== currentPropertyId);
    let propertySettings = {};
    this.ps.SelectedTimelineView =  this.ps.getDefaultTimelineView(globals.activitiesTimelineView);
    loginResult.RestaurantsAvailableForLogin.forEach(availableProperty => {
      propertySettings[availableProperty.Id] = {};
    });
    this.cs.propertySettings.next(propertySettings);
    this.callBackCount = 0;
     availablePropertiesForLogin.forEach(availableProperty => {
       this.cs.isSilentLogin = true;
      this.cs.propertySettings.value[availableProperty.Id].headerDate = this.as.headerDate$.value;
       this.loadPropertyData(availableProperty)
    });
    this.loadLoggedInPropertyData();
    let signalRSubscription: Subscription = this.signalR.hubConnectionId$.subscribe(() => {
      this.loadLoggedInPropertyData();
      availablePropertiesForLogin.forEach(availableProperty => {
        this.signalR.switchScreen(null, this.as.selectedMenuId, loginState.login, availableProperty.Id)
      });
      signalRSubscription.unsubscribe();
    });
      this.cs.specialMealListForMerchant.push(...this.cs.settings.value.SpecialMeals);
      this.cs.instructorListForMerchant.push(...this.cs.settings.value.Servers.map(server => {
        return { id: server.Id, value: server.Name + (server.LastName ? ' ' + server.LastName : '') }
      }));
      this.cs.layout.value.FloorPlans.find(x => x.IsDefault == true).StandaloneTables.map(location =>{
        this.cs.locationListForMerchant.push({ id: location.Id, value: location.Name });
      });
      this.cs.LoadMultiplePropertiesServiceInit = true;
  }

  loadLoggedInPropertyData(){
    let loggedInPropertyId = +sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`)
    this.cs.propertySettings.value[loggedInPropertyId] = {
      settings: this.cs.settings.value,
      state: this.cs.state.value,
      layout: this.cs.layout.value,
      dataRetentionPolicy: this.cs.dataRetentionPolicy.value,
      restaurantImages: this.cs.propertySettings.value[loggedInPropertyId]?.restaurantImages ,
      tableBlockingRules: this.ss.blockingdataChange$.value,
      reservations: this.ps.Parties$.value,
      StandbyParties: this.ps.StandbyParties$.value,
      shifts: this.ps.shifts$.value,
      headerDate : this.as.headerDate$.value,
      restaurantNote: this.cs.restuarantNote,
      activityBlockingRules :  this.ps.activityBlockingChange$.value //this.ss.activitiesBlocked$.value
    };
  }

  loadPropertyData(property: RestaurantAvailableForLoginDTO) {
    this.loadSettings(property, this.successCallback.bind(this)); 
    this.loadRestaurantDayNote(property, this.successCallback.bind(this));
    this.loadTableBlockingRules(property, this.successCallback.bind(this));
    this.loadActivityBlockingRule(property, this.successCallback.bind(this))
  }
  loadSettings(property: RestaurantAvailableForLoginDTO, callBack: any) {
    ++this.callBackCount;
    this.http.get(urlConfig.loadRestaurantUrl + property.Id + '&languageId='+ sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`)+'&options=' + LoadRestaurantOptions.Full, true, property.Id ).subscribe((result) => {
      callBack(result, propertyOperationType.LoadSettings, property);
      this.loadReservations(property, this.successCallback.bind(this));
    },(error) => {
       --this.callBackCount;
       this.setCallBackCount();
       });
  }

  loadImages(property: RestaurantAvailableForLoginDTO) {
    this.http.get(urlConfig.getLayoutImagesURL + property.Id, true, property.Id).subscribe((images: PayloadOperationResultDTO) => {
      this.successCallback(images, propertyOperationType.GetImages, property);
    });
  }

  loadTableBlockingRules(property: RestaurantAvailableForLoginDTO, callBack: any) {
    ++this.callBackCount;
    const blockingURL = `${urlConfig.getBlockingRule}?restaurantId=${property.Id}`;
    this.http.get(blockingURL, true, property.Id).subscribe((result) => {
      callBack(result, propertyOperationType.TableBlockingRules, property);
    }, (error) => { 
      --this.callBackCount;
      this.setCallBackCount(); 
    });
  }

  loadActivityBlockingRule(property: RestaurantAvailableForLoginDTO, callBack: any) {
    ++this.callBackCount;
    const blockingURL = `${urlConfig.getActivitiesBlockData}?restaurantId=${property.Id}`;
    this.http.get(blockingURL, true, property.Id).subscribe((result) => {
      callBack(result, propertyOperationType.ActivityBlockingRules, property);
    })
  }

  loadReservations(property: RestaurantAvailableForLoginDTO, callBack: any) {
    ++this.callBackCount;
    let Start = startOfDay(this.cs.headerDate);
    let End = endOfDay(this.cs.headerDate);
    Start = new Date(Start.getTime());
    End = new Date(End.setDate(End.getDate() + 1))
    this.http.post(
      `${urlConfig.loadReservationsURL}?restaurantId=${property.Id}&options=${GetReservationsOperationOptions.Full}`,
      { Start: moment(Start).format('YYYY-MM-DD'), End: moment(End).format('YYYY-MM-DD') },true, property.Id)
      .subscribe((result) =>  {
         callBack(result, propertyOperationType.Reservations, property) 
        }, (error) => { 
          --this.callBackCount ; 
           this.setCallBackCount(); 
          }
      );
  }

  loadRestaurantDayNote(property: RestaurantAvailableForLoginDTO,callBack: any) {
    ++this.callBackCount;
    let currentDate = new Date(startOfDay(this.cs.headerDate).getTime() - (startOfDay(this.cs.headerDate).getTimezoneOffset() * 60000));
    this.http.get(`${urlConfig.getDayNote}?restaurantId=${property.Id}` + '&localDate=' + currentDate.toJSON(),true,property.Id).subscribe((result) => {
      callBack(result, propertyOperationType.RestaurantNote, property)
    }, (error) => {
       --this.callBackCount;
       this.setCallBackCount();
      });
  }

  successCallback(response, operationType: propertyOperationType, extraParams) {
    --this.callBackCount;
    if (operationType == propertyOperationType.LoadSettings) {
       let _restaurantSettings: RestaurantDTO = response.Payload as RestaurantDTO;
      if(!this.cs.propertySettings.value[extraParams.Id]){
        this.cs.propertySettings.value[extraParams.Id] = {};
      }

      this.cs.propertySettings.value[extraParams.Id]['settings'] =  _restaurantSettings.Settings;
      this.cs.propertySettings.value[extraParams.Id]['state'] =  _restaurantSettings.State;
      this.cs.propertySettings.value[extraParams.Id]['layout'] =  _restaurantSettings.Layout;
      this.cs.propertySettings.value[extraParams.Id]['dataRetentionPolicy'] =  _restaurantSettings.DataRetentionPolicy;
      this.cs.propertySettings.value[extraParams.Id]['headerDate'] =  this.as.headerDate$.value;

      this.cs.specialMealListForMerchant.push(..._restaurantSettings.Settings.SpecialMeals);
      this.cs.instructorListForMerchant.push(...this.cs.settings.value.Servers.map(server => {
        return { id: server.Id, value: server.Name + (server.LastName ? ' ' + server.LastName : '') }
      }));
      response.Payload.Layout.FloorPlans.find(x => x.IsDefault == true).StandaloneTables.map(location =>{
        this.cs.locationListForMerchant.push({ id: location.Id, value: location.Name });
      });
      Utilities.setLatestRevision(_restaurantSettings.State.Revision, extraParams.Id);
    } else if (operationType == propertyOperationType.GetImages) {
      const allAvailableImages = response.Payload as FloorPlanImagesDto;
      if( this.cs.propertySettings.value?.[extraParams.Id]){
        this.cs.propertySettings.value[extraParams.Id]['restaurantImages'] = allAvailableImages;
      }
      this.cs.restaurantImages.next(allAvailableImages)
    } else if (operationType == propertyOperationType.TableBlockingRules) {
      const resultdata = response.Payload.filter(data => {
        return data.StartDate != null && data.EndDate != null && (!data.ActivitySessionId || data.ActivitySessionId == null);
      });
      this.cs.propertySettings.value[extraParams.Id]['tableBlockingRules'] = resultdata;
    }else if (operationType == propertyOperationType.ActivityBlockingRules) {
      const resultdata = response.Payload
      // .filter(data => {
      //   return data.StartDate != null && data.EndDate != null && (!data.ActivitySessionId || data.ActivitySessionId == null);
      // });
      
      this.cs.propertySettings.value[extraParams.Id]['activityBlockingRules'] = this.ts.setActivityBlock(resultdata);
      
    } else if (operationType == propertyOperationType.Reservations && this.cs.propertySettings.value[extraParams.Id]?.settings && this.cs.propertySettings.value[extraParams.Id]?.state) {
      const currentReservations: DateStateDTO[] = response.Payload.filter((reservations: DateStateDTO) => {
        const startdate = format(new Date(this.as.headerSelectedDate), 'MM/DD/YYYY');
        const enddate = format(new Date(this.as.headerSelectedDate), 'MM/DD/YYYY');
        const reservationDate = format(new Date(reservations.Date), 'MM/DD/YYYY');
        return (startdate <= reservationDate) && (reservationDate <= enddate);
      });
      if (this.cs.propertySettings.value[extraParams.Id]?.settings?.PropertySetting[0]?.SlottingType == SlottingType.NonDining) {
      this.dashboardFunctions.LoadPartiesForNonDinningProperties(currentReservations, extraParams.Id);      
      } else {
       this.dashboardFunctions.LoadPartiesForDinningParties(currentReservations, extraParams.Id);      
      }
    
    } else if (operationType == propertyOperationType.RestaurantNote) {
      let restaurantNote = response.Payload as RestaurantNoteDTO;
      this.cs.propertySettings.value[extraParams.Id]['restaurantNote'] = restaurantNote ? restaurantNote : {};
    }
    this.setCallBackCount();
  }
  setCallBackCount(){
    if(this.callBackCount == 0){
      this.cs.isSilentLogin = false;
  }
  }
  loadCurrentPropertyData(propertyId) {
    localStorage.removeItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_${this.lastLoggedInRestaurant}_restaurantSettings`);
    localStorage.setItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_${propertyId}_restaurantSettings`, JSON.stringify(this.cs.propertySettings.value[propertyId].settings));
    this.ps.SelectedTimelineView = this.ps.getDefaultTimelineView(globals.activitiesTimelineView);
    this.cs.settings.next(this.cs.propertySettings.value[propertyId].settings);
    this.cs.state.next(this.cs.propertySettings.value[propertyId].state);
    this.cs.layout.next(this.cs.propertySettings.value[propertyId].layout);
    this.cs.dataRetentionPolicy.next(this.cs.propertySettings.value[propertyId].dataRetentionPolicy);
    this.cs.restaurantImages.next(this.cs.propertySettings.value[propertyId].restaurantImages);
    this.ss.blockingdataChange$.next(this.cs.propertySettings.value[propertyId].tableBlockingRules);
    this.ps.activityBlockingChange$.next(this.cs.propertySettings.value[propertyId].activityBlockingRules)
    this.dashboardFunctions.updateShiftName(this.cs.propertySettings.value[propertyId].shifts);
    this.ps.shifts$.next(this.cs.propertySettings.value[propertyId].shifts);
    this.ps.Parties$.next(this.cs.propertySettings.value[propertyId].reservations);
    this.ps.StandbyParties$.next(this.cs.propertySettings.value[propertyId].StandbyParties);
    this.ps.partiesList = this.cs.propertySettings.value[propertyId].reservations;
    this.ps.standbyPartiesList = this.cs.propertySettings.value[propertyId].StandbyParties;
    this.ts.overallTableBlocking = this.cs.propertySettings.value[propertyId].tableBlockingRules;
    this.ps.isRentalIntegrated = this.cs.propertySettings.value[propertyId].settings?.RentalIntegrationEnabled;
  }

  updatePreviousPropertyDataInPropertySettingsIfNotExists(){
    let unSelectedPropertyId = Utilities.RestaurantId();
    if(!this.cs.propertySettings.value[unSelectedPropertyId]){
      this.cs.propertySettings.value[unSelectedPropertyId] = {}
    }
      this.cs.propertySettings.value[unSelectedPropertyId].settings = this.cs.settings.value;
      this.cs.propertySettings.value[unSelectedPropertyId].state = this.cs.state.value;
      this.cs.propertySettings.value[unSelectedPropertyId].layout = this.cs.layout.value;
      this.cs.propertySettings.value[unSelectedPropertyId].dataRetentionPolicy = this.cs.dataRetentionPolicy.value;
      this.cs.propertySettings.value[unSelectedPropertyId].restaurantImages = this.cs.restaurantImages.value;
      this.cs.propertySettings.value[unSelectedPropertyId].tableBlockingRules = this.ss.blockingdataChange$.value;
      this.cs.propertySettings.value[unSelectedPropertyId].activityBlockingRules =  this.ps.activityBlockingChange$.value;
      this.cs.propertySettings.value[unSelectedPropertyId].shifts =  this.ps.shifts$.value;
      this.cs.propertySettings.value[unSelectedPropertyId].reservations = this.ps.Parties$.value;
      this.cs.propertySettings.value[unSelectedPropertyId].StandbyParties = this.ps.StandbyParties$.value;
      this.ps.partiesList = this.cs.propertySettings.value[unSelectedPropertyId].reservations;
      this.ps.standbyPartiesList = this.cs.propertySettings.value[unSelectedPropertyId].StandbyParties;
  }

 async setDataForSelectedRestaurant(restaurantData, fromEdit?) {
  this.editFromCart = fromEdit;
    this.lastLoggedInRestaurant = +sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`);
    await this.as.logOutFromRetailProperty(this.lastLoggedInRestaurant);

    setTimeout(async () => {   
    this.lf.retailLogin.isSwitchProperty = false;
  
    let loginResult: LoginResultDTO = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    let currentPropertyId = +sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`);
    this.availablePropertiesForLogin = loginResult.RestaurantsAvailableForLogin;
    this.as.isRetailLoggedinFailed=false;
    //let allavailablePropertiesForLogin= loginResult.RestaurantsAvailableForLogin;
    //this.availablePropertiesForLogin = loginResult.RestaurantsAvailableForLogin.filter(property => property.Id !== currentPropertyId);
    if (Utilities.isRetailEnabledProperty(this.cs.propertySettings.value[restaurantData].settings?.General.RetailIntegrationDTO)) {
     
      try {
        await this.retailLogin.setEncryptKey();
        await this.retailLogin.getCustomerId();
        setTimeout(() => {
          this.retailLogin.login$.next(LoginOperation.Inprogress);
        }, 200); 
       } catch (error) {
        setTimeout(() => {
          this.retailLogin.login$.next(LoginOperation.Failed);
        }, 200); 
       }
      let loginResult: LoginResultDTO = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
      if (loginResult.RetailDetailDTO) {
        let retailData = loginResult.RetailDetailDTO.find(retail => retail.RestaurantId == restaurantData);
        if (retailData && retailData.RetailUserName && retailData.RetailPassword) {
          this.lf.retailLogin.isSwitchProperty = true;
          this.cs.isSilentLogin=false;
            
          let loginSubscription: Subscription = this.lf.retailLogin.login$.subscribe(async (val) => {
           
            this.setSwitchPropertySettings(restaurantData);
            if (val == LoginOperation.Inprogress) {
              this.utils.ToggleLoader(true);
              this.lf.getRetailToken(retailData)
              this.utils.ToggleLoader(false);
              this.lf.getRetailToken(retailData);
              if (this.as.retailLoginSubscription)
                this.as.retailLoginSubscription.unsubscribe();
            }
            else if (val == LoginOperation.Failed) {
          //    this.lf.setLoginDetails();
              //this.lf.setLoginDetails();
              this.lf.setAutoRetailLogin();
            }
            if (val == LoginOperation.Completed) {
              this.lf.subscribeRetailData();
              this.lf.setRetailMachineDetails();
            }
            if(loginSubscription && val != LoginOperation.Inprogress )
            loginSubscription.unsubscribe();

          });
        } else {        
            if(this.editFromCart){
              this.setSwitchPropertySettings(restaurantData);
            }
            else{
            this.setSwitchPropertySettings(restaurantData);
            }                  
        }
      }
    } else {
      this.setSwitchPropertySettings(restaurantData);
    }
  }, 500);
  }

 
  setSwitchPropertySettings(restaurantData) {     
    setTimeout(() => {
      this.as.restaurantId = restaurantData;
      const sessionType = Utilities.getSessionStorageType()
      this.loadCurrentPropertyData(restaurantData);
      sessionStorage.setItem(`restaurantId${sessionType}`, restaurantData);
      sessionStorage.setItem(`lastLoggedInRestaurantId${sessionType}`, restaurantData);
      let selectedRestaurant = this.availablePropertiesForLogin.find(availproperty => availproperty.Id == restaurantData);
      this.as.setLanguage(this.as.selectedLanguage);  
      this.as.PropertyType = Utilities.getPropertyType(selectedRestaurant.Id);
      sessionStorage.setItem(`restaurantName${sessionType}`, selectedRestaurant.Name);
      this.as.isPropertyChanged = true;
      this.as.propertyChanged.next(restaurantData);
      if(this.editFromCart){
        this.editFromCart = false;
        this.dashboardFunctions.editItem(this.as.editItemParameters.event, this.as.editItemParameters.data)
      }
    })
  }



}
