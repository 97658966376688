<ng-scrollbar>
  <div class="containterM">
    <div class="rowM box-1M">
      <div class="title-boxM">
        <h5>{{'reservationSummaryText'| translate}}</h5>
      </div>
      <div class=" min-box-containerM">
        <div class="rowM min-boxM">
          <p class="title-1M">{{'guestName' | translate}}</p>
          <p class="desc-textM">{{feedbacks.FirstName}} {{feedbacks.LastName}}</p>
        </div>
        <div class="rowM min-boxM">
          <p class="title-1M">{{'registerRestaurantText' | translate}}</p>
          <p class="desc-textM">{{restaurantSettings.General.RestaurantName}}</p>
        </div>
        <div class="rowM min-boxM">
          <p class="title-1M">{{'startDate' | translate}}</p>
          <p class="desc-textM">{{(pastResv.StartDate ? pastResv.StartDate: pastResv.ArrivedAt) | localizedDate:'ddd, MMM D'}}</p>
        </div>
        <div class="rowM min-boxM">
          <p class="title-1M">{{'endDate' | translate}} </p>
          <p class="desc-textM">{{(pastResv.EndDate ? pastResv.EndDate : pastResv.ArrivedAt) | localizedDate:'ddd, MMM D'}}</p>
        </div>
        <div class="rowM min-boxM">
          <p class="title-1M">{{'Party Size' | translate}}</p>
          <p class="desc-textM">{{pastResv.Size}}</p>
        </div>
        <div class="rowM min-boxM">
          <p class="title-1M">{{'Confirmation #' | translate}}</p>
          <p class="desc-textM">{{pastResv.ConfirmationCode}}</p>
        </div>
        <div class="rowM min-boxM">
          <p class="title-1M">{{'Reference' | translate}}</p>
          <p class="desc-textM">{{pastResv.BookingReferenceId}}</p>
        </div>
      </div>
    </div>
  
    <div class="rowM">
      <p class="N-texM" style="margin-left: 30px ! important">{{'responseAndResults' | translate}}</p>
    </div>
    <div class="row-container">
      <div class="">
        <div class="question-containerM pb-1 " *ngFor="let item of reviews, let i = index">
          <div class="question-boxM rowM" *ngIf="item.type == 'rating'">
            <p class="N-textM">
              {{i + 1}}.{{item.Question}}?
            </p> 
            <div class="star-rating=boxM columnM" style="display: inline-block; list-style: none;">
              <div class="rating">
                <span class="icon-" *ngFor="let rating of (item.Rating | customerRating)">{{rating}}</span>
              </div>
              <!-- <i class="icon-VIP guestlist__status-icon" [ngClass]="{'guestlist__vip-selected':contact.IsVip}"></i> -->
            </div>
          </div>
          <div class="question-boxM rowM" *ngIf="item.type == 'question'">
            <div class="star-rating=boxM columnM" style="display: inline-block; list-style: none;">
              <mat-label>{{item.label}}</mat-label>
              <mat-form-field class="form__input"  appearance="outline">
                <input [(ngModel)]="item.value" disabled="true" pattern="^[^<>]+$" matInput>
                <mat-error >
                  {{'invalidText' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class=" pb-1">
          <div class="comment-boxM rowM">
            <div class="star-rating=boxM columnM question-containerM" style="display: inline-block; list-style: none;">
              <mat-label>{{'managerComment' | translate}}</mat-label>
              <mat-form-field class="form__input"  appearance="outline">
              
                <textarea [disabled]="btnTextboxdisabled" [(ngModel)]="managerComment" (ngModelChange)="textChanged()" pattern="^[^<>]+$" [errorStateMatcher]="matcher" matInput ></textarea>
                <mat-error >
                {{'invalidText' | translate}}
              </mat-error>
              </mat-form-field>
            </div>
            <button [disabled]="btnDisable" mat-raised-button class="action-taken-btn"  (click)="submit()"><span>{{'actionTaken' | translate}}</span></button>          
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-scrollbar>