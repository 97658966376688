import { Component, Input, OnInit } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { roundToDecimalPlaces } from '@app/pos/services/util.functions';
import { PartyState, PaymentMethod } from '@app/shared/constants/commonenums';
import { PartyChargeState, PartyPrepaymentState } from '@app/shared/models/InputContact';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-financial-status-popup',
  templateUrl: './financial-status-popup.component.html',
  styleUrls: ['./financial-status-popup.component.scss']
})
export class FinancialStatusPopupComponent implements OnInit {
  @Input() partyData;
  @Input() bookingContact;
  financialDetail: any;
  paymentResponse: any;
  subscriptions: Subscription = new Subscription();
  OperationCurrency: string;

  constructor(private partyService: PartyService, private translateService: TranslateService, private cs: CacheService) { }

  ngOnInit(): void {
    this.financialDetails(this.partyData, this.bookingContact)
  }

  financialDetails(attendee, bookingContact) {
    this.OperationCurrency = this.cs.settings.value.General.OperationCurrency
    this.subscriptions.add(this.partyService.getGuestPaymentStatus(attendee.Id, attendee.BookedSessionId || null, bookingContact?.Id || null).subscribe(responseData => {
      let response = responseData.Payload;
      this.paymentResponse = response;
      let paymentState = response.PrepaymentState
      this.financialDetail = {};
      this.financialDetail['TicketNumber'] = this.paymentResponse?.TicketNumber || null;
      this.financialDetail['Transactions'] = this.paymentResponse?.Transactions || [];
      this.financialDetail.header = this.translateService.instant("financialdetails");
      if ((!response.CreditCard && paymentState == PartyPrepaymentState.DeferredPayment && attendee.ChargeState == PartyChargeState.Pending)) {
        this.financialDetail.message = this.translateService.instant('nocreditcardcollected');
      }
      else if (paymentState == PartyPrepaymentState.DepositCollected || paymentState == PartyPrepaymentState.PaymentDuePending) {
        if (response) {
          this.financialDetail.message = this.translateService.instant('guestPaidText', { paymentAmount: this.OperationCurrency + roundToDecimalPlaces(Number(response.PaidAmount), this.cs.getNumberOfFractionalDigits()) });
          this.financialDetail.paymentduemessage = this.translateService.instant('paymentDue', { paymentAmount: this.OperationCurrency + roundToDecimalPlaces(Math.abs(Number(response.PendingAmount)), this.cs.getNumberOfFractionalDigits()) });
        }
        else {
          this.financialDetail.message = this.translateService.instant("guestPaymentDueInfoText");
        }
      }
      else if (attendee.SessionGroupId > 0 && (paymentState == PartyPrepaymentState.Prepaid || (paymentState == PartyPrepaymentState.DeferredPayment && attendee.ChargeState == PartyChargeState.Charged)) && attendee.CreditCard) {
        this.financialDetail.message = this.translateService.instant('guestPaidForClassText', { paymentAmount: this.OperationCurrency + roundToDecimalPlaces(Number(response.PaidAmount), this.cs.getNumberOfFractionalDigits()) });
      }
      else if (attendee.SessionGroupId > 0 && paymentState == PartyPrepaymentState.DeferredPayment && attendee.ChargeState == PartyChargeState.Pending && attendee.CreditCard) {
        this.financialDetail.message = this.translateService.instant('guestCardAuthorizedForClassText', { paymentAmount: this.OperationCurrency + roundToDecimalPlaces(Number(response.PendingAmount), this.cs.getNumberOfFractionalDigits()) });
      }
      else if (paymentState == PartyPrepaymentState.Prepaid || (paymentState == PartyPrepaymentState.DeferredPayment && attendee.ChargeState == PartyChargeState.Charged) && response.CreditCard) {
        let amount = roundToDecimalPlaces(Number(response.PaidAmount), this.cs.getNumberOfFractionalDigits())
        this.financialDetail.message = this.translateService.instant('guestPaidForSessionText', { paymentAmount: this.OperationCurrency + amount  }); //for now displaying full party amount against each session
      }
      else if (paymentState == PartyPrepaymentState.DeferredPayment && attendee.ChargeState == PartyChargeState.Pending && response.CreditCard) {
        
        let amount = roundToDecimalPlaces(Number(response.PendingAmount), this.cs.getNumberOfFractionalDigits());
        this.financialDetail.message = this.translateService.instant('guestCardAuthorizedForSessionText', { paymentAmount: this.OperationCurrency + amount });
      }
      else if (attendee.CreditCard && attendee.NoShowFeeAmount > 0) {
        this.financialDetail.message = this.translateService.instant('guestAuthorized', { paymentAmount: this.OperationCurrency + roundToDecimalPlaces(Number(attendee.NoShowFeeAmount), this.cs.getNumberOfFractionalDigits()) });
      }
      else if (paymentState == PartyPrepaymentState.PrepaymentRequired) {
        this.financialDetail.message = attendee?.PaymentMethod == PaymentMethod.Authorize ? this.translateService.instant('CardAuthorized') : this.translateService.instant('NoPaymentCollected');
        this.financialDetail.paymentduemessage = this.translateService.instant('paymentDue', { paymentAmount: this.OperationCurrency + roundToDecimalPlaces(Number(response.PendingAmount), this.cs.getNumberOfFractionalDigits()) });
      }

   
      else if (paymentState == PartyPrepaymentState.RefundDuePending) {
        if (response) {
          if (response.PaidAmount) {
            this.financialDetail.message = this.translateService.instant('guestPaidText', { paymentAmount: this.OperationCurrency + roundToDecimalPlaces(Number(response.PaidAmount), this.cs.getNumberOfFractionalDigits()) });
          }

          this.financialDetail.subMessage = this.translateService.instant('RefundPendingAmountText', { paymentAmount: this.OperationCurrency + roundToDecimalPlaces(Math.abs(Number(response.RefundPendingAmount)), this.cs.getNumberOfFractionalDigits())});
        }
        else {
          this.financialDetail.message = this.translateService.instant('GuestPaymentRefundPending');
        }
      }
      else if (attendee.State == PartyState.Cancelled && paymentState !== PartyPrepaymentState.None) {
        if (response) {
          if (response.PrepaidAmount) {
            this.financialDetail.paymentMessage = this.translateService.instant('guestPaidForBookingText', { paymentAmount: `${this.OperationCurrency}${roundToDecimalPlaces(Number(response.PrepaidAmount), this.cs.getNumberOfFractionalDigits())}` });
          }
          if (response.RefundedAmount) {
            this.financialDetail.subMessage = this.translateService.instant('guestRefundedForBookingText', { paymentAmount: `${this.OperationCurrency}${roundToDecimalPlaces(Math.abs(Number(response.RefundedAmount)), this.cs.getNumberOfFractionalDigits())}` });
          }
        }
        if (paymentState == PartyPrepaymentState.CancellationFeeApplied || attendee.CancellationFeeAmount || attendee.CancellationFee)
          this.financialDetail.message = this.translateService.instant('guestChanrgeForCancellationText', { paymentAmount: `${this.OperationCurrency}${roundToDecimalPlaces(Number(attendee.CancellationFeeAmount || attendee.CancellationFee || 0), this.cs.getNumberOfFractionalDigits())}` });

      }
      else if (((attendee.RatePlanAmount > 0 || attendee.AddonItemAmount > 0) && !response.RefundedAmount && response.PaidAmount == 0)) {
        this.financialDetail.message = this.translateService.instant("CompleteWaiveoffText");
      }
      else {
        this.financialDetail.message = this.translateService.instant("NoPaymentInvolvedText");
        if (response) {
          if (response.PrepaidAmount) {
            this.financialDetail.paymentMessage = this.translateService.instant('guestPaidForBookingText', { paymentAmount: `${this.OperationCurrency}${roundToDecimalPlaces(Number(response.PrepaidAmount), this.cs.getNumberOfFractionalDigits())}` });
          }
          if (response.RefundedAmount) {
            this.financialDetail.subMessage = this.translateService.instant('guestRefundedForBookingText', { paymentAmount: `${this.OperationCurrency}${roundToDecimalPlaces(Math.abs(Number(response.RefundedAmount)), this.cs.getNumberOfFractionalDigits())}` });
          }
        }
      }
    }));
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
