<div class="dashboard-party-info seat-primary-bg-color">
   <button *ngIf="!fromTables" class="float-right icon-Group-591 rounded-icon user_profile_action" (click)="closeGuestInfoPanel()"></button>
  <!-- Table Number -->
  <div class="dashboard-party-info__guest" *ngIf="!fromTables">
    <div class="dashboard-party-info__guest-data">
      <span *ngIf="showNavigation" (click)="navigateLeft()" class="icon-Path-490"></span>
      <div class="dashboard-party-info__table-wrapper" #dataContainer [ngClass]="{'guest__navigation':showNavigation}"
        *ngIf="party?.TableIds?.length > 0">
        <span *ngFor="let tableId of party.TableIds"
          [ngStyle]="{'padding': party?.TableNames?.length > 1 ? '20px 17px 0' : '10px 17px 0' }">
          <div class="dashboard-party-info__guest-intial"
            [ngStyle]="{'background-color':  (party.State | backgroundColorFormatter : party.StatusId : _settings)}">
            <span [ngClass]="{'guest__guest-number': party?.TableNames?.length > 1}">
              {{tableId | tableNameSetter: standAloneTables}}</span>
            <div
              *ngIf="party?.TableNames?.length > 1 && (type != fromType.Reservation && type != fromType.Timeline)"
              class="dashboard-party-info__guest-circle"
              [ngStyle]="{'background-color':  (party.State | backgroundColorFormatter : party.StatusId : _settings)}"
              [autoClose]="'outside'" triggers="manual" #jt="ngbPopover"
              (click)="party?.TableNames?.length > 1 && jt.open()" data-container="body"
              [ngbPopover]="jointable" [popoverTitle]="popTitle" placement="top">
              <span class="icon-link"></span>
            </div>
            <ng-template #jointable>
              <div class="reservation-notes">
                {{joinTableNames}}
              </div>
            </ng-template>
          </div>

          <div class="dashboard-party-info__server-name seat-tertiary-text" *ngIf="!hide_serverName">
            {{tableId | serversSetter: servers:serverTableAssignment: type:party}}
          </div>

        </span>
      </div>
      <span *ngIf="showNavigation" (click)="navigateRight()" class="icon-left-arrow1"></span>
    </div>
    <div class="guest__client-name">
      <span class="seat-panel-header">{{party.Contact | formattedName}}
        <span *ngIf="_as.OTASourceId.includes(party.PartySourceId)" class="party-ota-icon"></span>
      </span>
      
      <span *ngIf="party.State == PartyState.Seated && !party.IsCommunal" class="icon-ic_create_24px"
      (click)="editGuest()"></span>
      
      <span class="guest__communal-icon seat-icon-color icon-communal" *ngIf="party.IsCommunal"></span>
    </div>
    <div *ngIf="party?.Contact?.AliasName" class="guest__phone-number seat-secondary-text content-text-ellipsis w-100"   matTooltipClass="tooltip-full-text" [matTooltip]="party?.Contact?.AliasName">
      {{'guestBookDetailsAliasName' | translate}} : {{ party?.Contact?.AliasName}}
    </div>
    <div class="guest__phone-number seat-secondary-text">
      {{ party?.Contact?.PhoneNumber | phoneNumberFormatter: party?.Contact?.CountryId }}
    </div>
    <div class="dashboard-party-info__capacity seat-tertiary-text" *ngIf="party.State == PartyState.Open">
      {{'capacity' | translate}} - {{'max' | translate}} {{party.MaxPartySize}} / {{'min' | translate}}
      {{party.MinPartySize}}
    </div>
    <div *ngIf="party.State != PartyState.Open && party.State != PartyState.Blocked" class="guest__location-details seat-tertiary-text">
      <span class="rs-mr-5">{{ party | seatingAreaName : _settings : standAloneTables}}</span> <i class="icon-placeholder rs-mr-5"></i>
      <span class="rs-mr-5" *ngIf="!hide_partySize">{{party.Size}}</span> <i class="icon-add-contact"></i>
    </div>

    <div class="dashboard-party-info__guest-preference"
      *ngIf="(party.State != PartyState.Open && party.State != PartyState.Blocked && type != fromType.Tables) || (type == fromType.Tables && party.State == PartyState.Pending && party.State != PartyState.Open && party.State != PartyState.Blocked && party.IsCommunal == undefined )">

     <a class="dashboard-party-info__guest-actions seat-border-color" *ngFor="let iconData of actionsData;let i = index"
        [ngClass]="{'dashboard-party-info__disable-icon': (party.State === 1 || party.State === 2 || (party.ReservedFor && !(party.ReservedFor | EditOrSeatAction: party.State)) && party.State != 3) && iconData.icon === 'edit' }"
        [autoClose]="'outside'" [ngbPopover]="history" placement="bottom" popoverClass="history-popover" container="body" (click)="partyActions(iconData,i)">
        <i class="icon-{{iconData.icon}}"></i>
      </a>
      <a class="dashboard-party-info__guest-actions seat-border-color"
        [ngClass]="{'dashboard-party-info__actions-vip': party?.Contact?.IsVip,'dashboard-party-info__vip-party': !(party.Contact.FirstName || party.Contact.LastName) }"
        (click)="setVIP(party.Contact)">
        <i class="icon-star"></i>
      </a>
      <a data-toggle="popover" [ngStyle]="{background:pageBackgroundColor}" *ngIf="(party.PageMethod == 3 || party.PageMethod == 7)"
        (click)="emailMessages()" #emailChat="ngbPopover" [ngbPopover]="messages" [autoClose]="false" triggers="manual"
        placement="bottom-right" container="body" class="icon-envelope dashboard-party-info__guest-actions seat-border-color"
        [ngClass]="{'dashboard-party-info__disable-icon': (getPastReservationStatus(party) && party.Type == PartyType.Reservation) || party.State == PartyState.Cancelled || party.State == PartyState.Left,
        'dashboard-party-info__icon-bg': pageBackgroundColor}"></a>
      <a data-toggle="popover" [ngStyle]="{background:pageBackgroundColor}" 
        *ngIf="(party.PageMethod == 1 || party.PageMethod == 4 || party.PageMethod == 6)" [ngbPopover]="messages"  popoverClass="messages-popover"
        [autoClose]="false" triggers="manual" #messageChat="ngbPopover" (click)="mobileMessages()"
        placement="bottom-right" container="body" class="icon-SMS dashboard-party-info__guest-actions seat-border-color"
        [ngClass]="{'dashboard-party-info__disable-icon': (getPastReservationStatus(party) && party.Type == PartyType.Reservation) || party.State == PartyState.Cancelled || party.State == PartyState.Left,
        'dashboard-party-info__icon-bg': pageBackgroundColor}"></a>
      <a [ngStyle]="{background:pageBackgroundColor}" *ngIf="party.PageMethod == 0" (click)="(getPastReservationStatus(party) && party.Type == 0) || party.State == PartyState.Seated || party.State == PartyState.Left ? false : manualpage()"
        class="icon-Path-506 dashboard-party-info__guest-actions seat-border-color"
        [ngClass]="{'dashboard-party-info__disable-icon': (getPastReservationStatus(party) && party.Type == PartyType.Reservation) || party.State == PartyState.Cancelled || party.State == PartyState.Left,
        'dashboard-party-info__icon-bg': pageBackgroundColor}"></a>
      <a [ngStyle]="{background:pageBackgroundColor}" *ngIf="(party.PageMethod == 2 || party.PageMethod == 5)"
        class="icon-ic_stay_current_portrait_24px dashboard-party-info__guest-actions seat-border-color"
        [ngClass]="{'dashboard-party-info__disable-icon': (getPastReservationStatus(party) && party.Type == PartyType.Reservation) || party.State == PartyState.Cancelled || party.State == PartyState.Left,
        'dashboard-party-info__icon-bg': pageBackgroundColor}"></a>
      <a *ngIf="party.State == -1 && !hide_moveTable" class="icon-Union-20 dashboard-party-info__guest-actions seat-border-color"></a>
      <a  matTooltip="{{'auditlog' | translate}}" (click)="showPartyAuditLog()"
        class="icon-Auditlog2 dashboard-party-info__guest-actions seat-icon-color seat-border-color" >
        <span class="path1"></span><span class="path2"></span><span class="path3"></span>
      </a>
      <a class="dashboard-party-info__guest-actions seat-icon-color seat-border-color icon-printChit"
          (click)="printChit('Chit-Print_Print-Section'); tooltip.toggle()"
          #tooltip="matTooltip" matTooltipClass="tooltip-class" matTooltip="{{'printChit' | translate}}"></a>
      <a *ngIf="party.BookingContactAddonItems?.length" matTooltip="{{'addons' | translate}}"
        class="icon-addons dashboard-party-info__guest-actions seat-icon-color seat-border-color" [autoClose]="'outside'"
        container="body" placement="auto" #addOnForBookingPopover="ngbPopover" container="body" triggers="manual"
        [ngbPopover]="addOnForBooking"
        (click)="ps.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(party)">
      </a>
      <!-- Commented to implment the party messages feature in Tables screen on click of table -->
      <!-- <a class="dashboard-party-info__guest-actions seat-border-color"
      [autoClose]="'outside'" [ngbPopover]="inbox" placement="bottom" popoverClass="history-popover" container="body" (click)="partyActions(iconData,i)">
      <i class="icon-edit"></i>
    </a>
    <ng-template class="messages" class="arrow-up" #inbox>
      <app-dashboard-message *ngIf="party && party.Messages"></app-dashboard-message>
    </ng-template> -->
      <ng-template class="messages" class="arrow-up" #messages>
        <app-chat-view  *ngIf="inputString == 'messages'" [messages]="predefinedMsgs$.asObservable() | async"
          [partyInfo]="party" [preferredPageMethod]="party.PageMethod" [ContactId]="party.Contact?.Id" [historyData]="history" (closePopover)="closeMobileChat()" [inputString]="inputString" [disableMessagePopup]="disableMessagePopup">
        </app-chat-view>
      </ng-template>
     
      <ng-template class="history" #history>
        <app-chat-view *ngIf="inputString == 'history'" [messages]="predefinedMsgs$.asObservable() | async"
          [partyInfo]="party" [preferredPageMethod]="party.PageMethod"  [ContactId]="party.Contact?.Id"  [historyData]="historyData" [inputString]="inputString"></app-chat-view>
      </ng-template>
    </div>
    <div
      *ngIf="type == fromType.Tables && party.State != PartyState.Open && party.State != PartyState.Blocked && party.IsCommunal == undefined && party.State == PartyState.Seated"
      class="dashboard-party-info__table-preference">
      <a class="dashboard-party-info__guest-actions seat-border-color" [autoClose]="false" triggers="manual" #ps="ngbPopover"
        (click)="partySizeChanged(ps);ps.toggle()" [ngbPopover]="partySize" [popoverTitle]="popTitle" popoverClass="party-popover"
        placement="bottom-left" container="body">
        <i class="icon-edit"></i>
      </a>
      <a class="dashboard-party-info__guest-actions seat-border-color dashboard-party-info__vip-party"
        [ngClass]="{'dashboard-party-info__actions-vip': party?.Contact?.IsVip, 'dashboard-party-info__vip-party': !(party.Contact.FirstName || party.Contact.LastName)}"
        (click)="setVIP(party.Contact)">
        <i class="icon-star"></i>
      </a>
      <a class="dashboard-party-info__guest-actions seat-border-color" [ngClass]="{'dashboard-party-info__partynotes': partyNotes || contactNotes}" [autoClose]="'outside'"
        triggers="manual" #p="ngbPopover" (click)="(partyNotes || contactNotes) && p.open()" [ngbPopover]="partynotes"
        [popoverTitle]="popTitle" placement="bottom" container="body" *ngIf="partyNotes?.length > 0 || contactNotes?.length > 0">
        <i class="icon-note"></i>
      </a>
      <a *ngIf="party.State == PartyState.Seated && !hide_moveActionIcon" class="dashboard-party-info__guest-actions seat-border-color"
        (click)="partyActions(tableActionsData[1],3)">
        <i class="icon-Union-20"></i>
      </a>
      <a matTooltip="{{'auditlog' | translate}}" (click)="showPartyAuditLog()"
        class="icon-Auditlog2 dashboard-party-info__guest-actions seat-border-color">
        <span class="path1"></span><span class="path2"></span><span class="path3"></span>
      </a>
      <a *ngIf="party.BookingContactAddonItems?.length" matTooltip="{{'addons' | translate}}"
      class="icon-addons dashboard-party-info__guest-actions seat-icon-color seat-border-color" [autoClose]="'outside'"
      container="body" placement="auto" #addOnForBookingPopover="ngbPopover" container="body" triggers="manual"
      [ngbPopover]="addOnForBooking"
      (click)="ps.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(party)">
    </a>
      <!-- Commented to implment the party messages feature in Tables screen on click of table -->
      <!-- <a class="dashboard-party-info__guest-actions seat-border-color"
      [autoClose]="'outside'" [ngbPopover]="inbox" placement="bottom" popoverClass="history-popover" container="body" (click)="partyActions(iconData,i)">
      <i class="icon-edit"></i>
    </a> -->
      <a class="dashboard-party-info__guest-actions seat-border-color" [ngClass]="{'dashboard-party-info__partynotes': partyNotes || contactNotes}" [autoClose]="'outside'"
        triggers="manual" #p="ngbPopover" (click)="(partyNotes || contactNotes) && p.open()" [ngbPopover]="partynotes"
        [popoverTitle]="popTitle" placement="bottom" container="body" *ngIf="partyNotes?.length || contactNotes?.length > 0 && fromTables">
        <i class="icon-note"></i>
      </a>
      <!-- Commented to implment the party messages feature in Tables screen on click of table -->
      <!-- <ng-template class="messages" class="arrow-up" #inbox>
        <app-dashboard-message *ngIf="party && party.Messages"></app-dashboard-message>
      </ng-template> -->
      <ng-template #partySize style="margin-left:-8px">
        <div class="party__size-section">
          <div class="party__size-header seat-popover-header">
            <span class="party__size__header-label">{{'selectPartySize' | translate}}</span>
            <a class="icon-Group-591 seat-icon-color party__size-close" (click)="ps.close();"></a>
          </div>
          <div class="party__size-content seat-popover-content">
            <dynamic-form [config]="setSizeConfig" #form="dynamicForm">
            </dynamic-form>
            <div class="party__size-confirm-size">
              <activities-app-button [buttontype]="SizeOkBtn" (click)="ps.close();partySizeupdate()"></activities-app-button>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #partynotes >
          <div>
          <perfect-scrollbar>
            <div class="Notes_scrollSize">
        <div class="reservation-notes"  *ngIf = "partyNotes && partyNotes?.length > 0">
          <div class="reservation-notes__header seat-popover-header">
            {{'reservationNotes' | translate}}
          </div>
          <div class="reservation-notes__content seat-popover-content">
            <div class="reservation-notes__notes-details seat-border-color" *ngFor="let notes of partyNotes">
              <span class="seat-secondary-text" *ngIf="!notes.isSpecialMeal">{{notes.name}}</span>
              <span *ngIf="notes.isSpecialMeal">
                <span class="uppercase seat-secondary-text"  *ngIf="notes.type == partyNoteType.SpecialMeal">
                  {{'specialMealText' | translate }}:
                </span>
                <span class="uppercase seat-secondary-text" *ngIf="notes.type == partyNoteType.SpecialRequest">
                  {{'specialRequest' | translate }}:
                </span>
                <span class="uppercase seat-secondary-text" *ngIf="notes.type == partyNoteType.PromoCode">
                  {{_settings.PromoCodeLabel}}:
                </span>
                <span>{{notes.name}}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="reservation-notes" *ngIf = "contactNotes && contactNotes?.length > 0">
          <div class="reservation-notes__header seat-popover-header">
            {{'guestNotesText' | translate}}
          </div>
          <div class="reservation-notes__content seat-popover-content" >
            <div class="reservation-notes__notes-details seat-border-color" *ngFor="let notes of contactNotes">
              <span class="seat-secondary-text" *ngIf="!notes.isSpecialMeal">{{notes.name}}</span>
            </div>
          </div>
        </div>
      </div>
        </perfect-scrollbar>
      </div>
      </ng-template>

    </div>
  </div>
  <div class="dashboard-party-info__guest" *ngIf="fromTables && (party.State != PartyState.Open && party.State != PartyState.Blocked)">
    <div class="dashboard-party-info__gufest-data" *ngIf="(party.State != PartyState.Seated && party.State != PartyState.Pending) && fromTables ">
      <span *ngIf="showNavigation" (click)="navigateLeft()" class="icon-Path-490"></span>
      <div class="dashboard-party-info__table-wrapper" #dataContainer [ngClass]="{'guest__navigation':showNavigation}"
        *ngIf="party?.TableIds?.length > 0">
        <span *ngFor="let tableId of party.TableIds"
          [ngStyle]="{'padding': party?.TableNames?.length > 1 ? '20px 17px 0' : '10px 17px 0' }">
          <div class="dashboard-party-info__guest-intial"
            [ngStyle]="{'background-color':  (party.State | backgroundColorFormatter : party.StatusId : _settings)}">
            <span [ngClass]="{'guest__guest-number': party?.TableNames?.length > 1}">
              {{tableId | tableNameSetter: standAloneTables}}</span>
            <div
              *ngIf="party?.TableNames?.length > 1 && (type != fromType.Reservation && type != fromType.Timeline)"
              class="dashboard-party-info__guest-circle"
              [ngStyle]="{'background-color':  (party.State | backgroundColorFormatter : party.StatusId : _settings)}"
              [autoClose]="'outside'" triggers="manual" #jt="ngbPopover"
              (click)="party?.TableNames?.length > 1 && jt.open()" data-container="body"
              [ngbPopover]="jointable" [popoverTitle]="popTitle" placement="top">
              <span class="icon-link"></span>
            </div>
            <ng-template #jointable>
              <div class="reservation-notes">
                {{joinTableNames}}
              </div>
            </ng-template>
          </div>

          <div class="dashboard-party-info__server-name seat-tertiary-text" *ngIf="!hide_serverName">
            {{tableId | serversSetter: servers:serverTableAssignment: type:party}}
          </div>

        </span>
      </div>
      <span *ngIf="showNavigation" (click)="navigateRight()" class="icon-left-arrow1"></span>
    </div>
    <div class="guest__client-name">
      <span class="seat-panel-header">{{party.Contact | formattedName}}
        <span *ngIf="_as.OTASourceId.includes(party.PartySourceId)" class="party-ota-icon"></span>
      </span>
      
        <span *ngIf="party.State == PartyState.Seated && !party.IsCommunal" 
        class="icon-ic_create_24px" (click)="editGuest()"></span>

      <span class="guest__communal-icon seat-icon-color icon-communal" *ngIf="party.IsCommunal"></span>
    </div>
    <div class="guest__phone-number seat-secondary-text">
      {{ party?.Contact?.PhoneNumber | phoneNumberFormatter: party?.Contact?.CountryId }}
    </div>
    <div class="dashboard-party-info__capacity seat-tertiary-text" *ngIf="party.State == PartyState.Open">
      {{'capacity' | translate}} - {{'max' | translate}} {{party.MaxPartySize}} / {{'min' | translate}}
      {{party.MinPartySize}}
    </div>
    <div *ngIf="party.State != PartyState.Open && party.State != PartyState.Blocked" class="guest__location-details seat-tertiary-text">
      <span class="rs-mr-5">{{ party | seatingAreaName : _settings : standAloneTables}}</span> <i class="icon-placeholder rs-mr-5"></i>
      <span class="rs-mr-5" *ngIf="!hide_partySize">{{party.Size}}</span> <i class="icon-add-contact"></i>
    </div>

    <div class="dashboard-party-info__guest-preference"
      *ngIf="(party.State != PartyState.Open && party.State != PartyState.Blocked && type != fromType.Tables) || (type == fromType.Tables && party.State == PartyState.Pending && party.State != PartyState.Open && party.State != PartyState.Blocked && party.IsCommunal == undefined )">

     <a class="dashboard-party-info__guest-actions seat-border-color" *ngFor="let iconData of actionsData;let i = index"
        [ngClass]="{'dashboard-party-info__disable-icon': (party.State === 1 || party.State === 2 || (party.ReservedFor && !(party.ReservedFor | EditOrSeatAction: party.State)) && party.State != 3) && iconData.icon === 'edit' }"
        [autoClose]="'outside'" [ngbPopover]="history" placement="bottom" popoverClass="history-popover" container="body" (click)="partyActions(iconData,i)">
        <i class="icon-{{iconData.icon}}"></i>
      </a>
      <a class="dashboard-party-info__guest-actions seat-border-color"
        [ngClass]="{'dashboard-party-info__actions-vip': party?.Contact?.IsVip,'dashboard-party-info__vip-party': !(party.Contact.FirstName || party.Contact.LastName) }"
        (click)="setVIP(party.Contact)">
        <i class="icon-star"></i>
      </a>
      <a data-toggle="popover" [ngStyle]="{background:pageBackgroundColor}" *ngIf="(party.PageMethod == 3 || party.PageMethod == 7)"
        (click)="emailMessages()" #emailChat="ngbPopover" [ngbPopover]="messages" [autoClose]="false" triggers="manual"
        placement="bottom-right" container="body" class="icon-envelope dashboard-party-info__guest-actions seat-border-color"
        [ngClass]="{'dashboard-party-info__disable-icon': (getPastReservationStatus(party) && party.Type == PartyType.Reservation) || party.State == PartyState.Seated || party.State == PartyState.Left,
        'dashboard-party-info__icon-bg': pageBackgroundColor}"></a>
      <a data-toggle="popover" [ngStyle]="{background:pageBackgroundColor}" 
        *ngIf="(party.PageMethod == 1 || party.PageMethod == 4 || party.PageMethod == 6)" [ngbPopover]="messages"  popoverClass="messages-popover"
        [autoClose]="false" triggers="manual" #messageChat="ngbPopover" (click)="mobileMessages()"
        placement="bottom-right" container="body" class="icon-SMS dashboard-party-info__guest-actions seat-border-color"
        [ngClass]="{'dashboard-party-info__disable-icon': (getPastReservationStatus(party) && party.Type == PartyType.Reservation) || party.State == PartyState.Seated || party.State == PartyState.Left,
        'dashboard-party-info__icon-bg': pageBackgroundColor}"></a>
      <a [ngStyle]="{background:pageBackgroundColor}" *ngIf="party.PageMethod == 0" (click)="manualpage()"
        class="icon-Path-506 dashboard-party-info__guest-actions seat-border-color"
        [ngClass]="{'dashboard-party-info__disable-icon': (getPastReservationStatus(party) && party.Type == 0) || party.State == PartyState.Seated || party.State == PartyState.Left,
        'dashboard-party-info__icon-bg': pageBackgroundColor}"></a>
      <a [ngStyle]="{background:pageBackgroundColor}" *ngIf="(party.PageMethod == 2 || party.PageMethod == 5)" (click)="voiceCallPage()"
        class="icon-ic_stay_current_portrait_24px dashboard-party-info__guest-actions seat-border-color"
        [ngClass]="{'dashboard-party-info__disable-icon': (getPastReservationStatus(party) && party.Type == PartyType.Reservation) || party.State == PartyState.Seated || party.State == PartyState.Left,
        'dashboard-party-info__icon-bg': pageBackgroundColor}"></a>
      <a *ngIf="party.State == -1 && !hide_moveTable" class="icon-Union-20 dashboard-party-info__guest-actions seat-border-color"></a>
      <a  matTooltip="{{'auditlog' | translate}}" (click)="showPartyAuditLog()"
        class="icon-Auditlog2 dashboard-party-info__guest-actions seat-icon-color seat-border-color" >
        <span class="path1"></span><span class="path2"></span><span class="path3"></span>
      </a>
      <a *ngIf="party.BookingContactAddonItems?.length" matTooltip="{{'addons' | translate}}"
      class="icon-addons dashboard-party-info__guest-actions seat-icon-color seat-border-color" [autoClose]="'outside'"
      container="body" placement="auto" #addOnForBookingPopover="ngbPopover" container="body" triggers="manual"
      [ngbPopover]="addOnForBooking"
      (click)="ps.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(party)">
    </a>
      <!-- Commented to implment the party messages feature in Tables screen on click of table -->
      <!-- <a class="dashboard-party-info__guest-actions seat-border-color"
      [autoClose]="'outside'" [ngbPopover]="inbox" placement="bottom" popoverClass="history-popover" container="body" (click)="partyActions(iconData,i)">
      <i class="icon-edit"></i>
       </a> -->
      <ng-template class="messages" class="arrow-up" #messages>
        <app-chat-view  *ngIf="inputString == 'messages'" [messages]="predefinedMsgs$.asObservable() | async"
          [partyInfo]="party" [preferredPageMethod]="party.PageMethod"   [ContactId]="party.Contact?.Id"  [historyData]="history" (closePopover)="closeMobileChat()" [inputString]="inputString">
        </app-chat-view>
      </ng-template>
      <ng-template class="history" #history>
        <app-chat-view *ngIf="inputString == 'history'" [messages]="predefinedMsgs$.asObservable() | async"
          [partyInfo]="party" [preferredPageMethod]="party.PageMethod"  [ContactId]="party.Contact?.Id" [historyData]="historyData" [inputString]="inputString"></app-chat-view>
      </ng-template>
       <!-- Commented to implment the party messages feature in Tables screen on click of table -->
      <!-- <ng-template class="messages" class="arrow-up" #inbox>
        <app-dashboard-message *ngIf="party && party.Messages"></app-dashboard-message>
      </ng-template> -->
    </div>
    <div
      *ngIf="type == fromType.Tables && party.State != PartyState.Open && party.State != PartyState.Blocked && party.IsCommunal == undefined && party.State == PartyState.Seated"
      class="dashboard-party-info__table-preference">
      <a class="dashboard-party-info__guest-actions seat-border-color" [autoClose]="false" triggers="manual" #ps="ngbPopover"
        (click)="partySizeChanged(ps);ps.toggle()" [ngbPopover]="partySize" [popoverTitle]="popTitle" popoverClass="party-popover"
        placement="bottom-left" container="body">
        <i class="icon-edit"></i>
      </a>
      <a class="dashboard-party-info__guest-actions seat-border-color dashboard-party-info__vip-party"
        [ngClass]="{'dashboard-party-info__actions-vip': party?.Contact?.IsVip, 'dashboard-party-info__vip-party': !(party.Contact.FirstName || party.Contact.LastName)}"
        (click)="setVIP(party.Contact)">
        <i class="icon-star"></i>
      </a>
      <a class="dashboard-party-info__guest-actions seat-border-color" [ngClass]="{'dashboard-party-info__partynotes': partyNotes || contactNotes}" [autoClose]="'outside'"
        triggers="manual" #p="ngbPopover" (click)="(partyNotes || contactNotes) && p.open()" [ngbPopover]="partynotes"
        [popoverTitle]="popTitle" placement="bottom" container="body" *ngIf="partyNotes?.length > 0 || contactNotes?.length > 0">
        <i class="icon-note"></i>
      </a>
      <a *ngIf="party.State == PartyState.Seated && !hide_moveActionIcon" class="dashboard-party-info__guest-actions seat-border-color"
        (click)="partyActions(tableActionsData[1],3)">
        <i class="icon-Union-20"></i>
      </a>
      <a matTooltip="{{'auditlog' | translate}}" (click)="showPartyAuditLog()"
        class="icon-Auditlog2 dashboard-party-info__guest-actions seat-border-color">
        <span class="path1"></span><span class="path2"></span><span class="path3"></span>
      </a>
      <a *ngIf="party.BookingContactAddonItems?.length" matTooltip="{{'addons' | translate}}"
      class="icon-addons dashboard-party-info__guest-actions seat-icon-color seat-border-color" [autoClose]="'outside'"
      container="body" placement="auto" #addOnForBookingPopover="ngbPopover" container="body" triggers="manual"
      [ngbPopover]="addOnForBooking"
      (click)="ps.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(party)">
    </a>
      <!-- Commented to implment the party messages feature in Tables screen on click of table -->
      <!-- <a class="dashboard-party-info__guest-actions seat-border-color"
      [autoClose]="'outside'" [ngbPopover]="inbox" placement="bottom" popoverClass="history-popover" container="body" (click)="partyActions(iconData,i)">
      <i class="icon-edit"></i>
    </a>   -->
    <!-- <ng-template class="messages" class="arrow-up" #inbox>
      <app-dashboard-message *ngIf="party && party.Messages"></app-dashboard-message>
    </ng-template> -->
      <ng-template #partySize style="margin-left:-8px">
        <div class="party__size-section">
          <div class="party__size-header seat-popover-header">
            <span class="party__size__header-label">{{'selectPartySize' | translate}}</span>
            <a class="icon-Group-591 seat-icon-color party__size-close" (click)="ps.close();"></a>
          </div>
          <div class="party__size-content seat-popover-content">
            <dynamic-form [config]="setSizeConfig" #form="dynamicForm">
            </dynamic-form>
            <div class="party__size-confirm-size">
              <activities-app-button [buttontype]="SizeOkBtn" (click)="ps.close();partySizeupdate()"></activities-app-button>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #partynotes >
          <div>
          <perfect-scrollbar>
            <div class="Notes_scrollSize">
        <div class="reservation-notes"  *ngIf = "partyNotes && partyNotes?.length > 0">
          <div class="reservation-notes__header seat-popover-header">
            {{'reservationNotes' | translate}}
          </div>
          <div class="reservation-notes__content seat-popover-content">
            <div class="reservation-notes__notes-details seat-border-color" *ngFor="let notes of partyNotes">
              <span class="seat-secondary-text" *ngIf="!notes.isSpecialMeal">{{notes.name}}</span>
              <span *ngIf="notes.isSpecialMeal">
                <span class="uppercase seat-secondary-text"  *ngIf="notes.type == partyNoteType.SpecialMeal">
                  {{'specialMealText' | translate }}:
                </span>
                <span class="uppercase seat-secondary-text" *ngIf="notes.type == partyNoteType.SpecialRequest">
                  {{'specialRequest' | translate }}:
                </span>
                <span class="uppercase seat-secondary-text" *ngIf="notes.type == partyNoteType.PromoCode">
                  {{_settings.PromoCodeLabel}}:
                </span>
                <span>{{notes.name}}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="reservation-notes" *ngIf = "contactNotes && contactNotes?.length > 0">
          <div class="reservation-notes__header seat-popover-header">
            {{'guestNotesText' | translate}}
          </div>
          <div class="reservation-notes__content seat-popover-content" >
            <div class="reservation-notes__notes-details seat-border-color" *ngFor="let notes of contactNotes">
              <span class="seat-secondary-text" *ngIf="!notes.isSpecialMeal">{{notes.name}}</span>
            </div>
          </div>
        </div>
      </div>
        </perfect-scrollbar>
      </div>
      </ng-template>

    </div>
  </div>
  <div class="text-center d-block roboto_light font_color_gray max_capacity_font">
    <!-- seat status and status time display -->
    <div class="reservation-status ">
      <div class="reservation-status__reservation-details">
        <div class="reservation-status__displaytableinfoicon">
          <h3 class="reservation-status__reservation-tag seat-reservation-status"
            [ngStyle]="{'color': party.State | reservationStatus: reservationColor}">
            {{((party.Type == 1 && party.State == PartyState.Pending)? 'arrived' : party.State) | reservationStatus: reservationType |translate}}
          </h3>
          <span
            *ngIf="party.State != PartyState.Seated && party.istableAvailableForReservation != undefined && !party.istableAvailableForReservation && party.isserverneed"
            class="icon-exclamation reservation-status__infoicon" (click)="ShowTableInfo()">
            <span class="path1"></span><span class="path2"></span><span class="path3"></span>
          </span>
        </div>
        <div class="reservation-status__reservation-timeline"
          *ngIf="party.State == PartyState.Pending && party.IsCommunal == undefined">
          <span
            class="reservation-status__time-status seat-border-color seat-secondary-text">{{((party.Type == 1)? party.ArrivedAt : party.ReservedFor) | formatTime}}</span>
          <span class="icon-clock seat-icon-color reservation-status__icon "></span>
          <span class="reservation-status__time seat-tertiary-text">{{party.CreatedAt  |  timeLapse: _settings }} {{'ago' | translate}}</span>
        </div>
        <div class="reservation-status__reservation-timeline"
          *ngIf="party.State == PartyState.Seated  && party.IsCommunal == undefined">
          <span
            class="reservation-status__time-status seat-border-color seat-secondary-text">{{party.SeatingTime | formatTime}}</span>
          <span class="icon-clock seat-icon-color reservation-status__icon "></span>
          <span class="reservation-status__time seat-tertiary-text">{{party.SeatingTime  |  timeLapse: _settings }} {{'ago' | translate}}</span>
        </div>
        <div class="reservation-status__reservation-timeline"
          *ngIf="party.State == PartyState.Left">
          <span
            class="reservation-status__time-status seat-border-color seat-secondary-text">{{party.DepartureTime | formatTime}}</span>
          <span class="icon-clock seat-icon-color reservation-status__icon "></span>
          <span class="reservation-status__time seat-tertiary-text">{{party.DepartureTime  |  timeLapse: _settings }} {{'ago' | translate}}</span>
        </div>
        <div class="reservation-status__reservation-timeline"
          *ngIf="party.State == PartyState.NoShowed  && party.IsCommunal == undefined">
          <span
            class="reservation-status__time-status seat-border-color seat-secondary-text">{{party.NoShowedAt | formatTime}}</span>
          <span class="icon-clock seat-icon-color reservation-status__icon "></span>
          <span class="reservation-status__time seat-tertiary-text">{{party.NoShowedAt  |  timeLapse: _settings }} {{'ago' | translate}}</span>
        </div>
        <div class="reservation-status__reservation-timeline" *ngIf="party.State == PartyState.Open">
          <span
            class="reservation-status__time-status">{{ ( (party.isserverneed != undefined && !party.isserverneed) ?   'noserver' :  party.istableAvailableForReservation ? 'freeText' : 'notAvailableForReservations' )| translate}}</span>
          <span
            *ngIf="party.istableAvailableForReservation != undefined && !party.istableAvailableForReservation && party.isserverneed "
            class="seat-error-messages"><b>{{ 'notext' | translate}}</b></span>
        </div>
        <div class="reservation-status__reservation-timeline" *ngIf="party.State == PartyState.Blocked">
          <span class="reservation-status__time-status">{{'blockeduntilText' | translate}}</span>
          <span class="icon-recent-history"></span>
          {{ blockUntilDetail }}
        </div>
        <div class="reservation-status__reservation-timeline"
          *ngIf="party.State != PartyState.Open && party.State != PartyState.Seated && party.isserverneed && party.istableAvailableForReservation != undefined &&  !party.istableAvailableForReservation">
          <span class="reservation-status__time-status">{{'notAvailableForReservations' | translate}}</span>
          <span class="seat-error-messages"><b>{{ 'notext' | translate}}</b></span>
        </div>
      </div>
      <!-- Spinner for Waiting time -->
      <!-- Progress Bar is not needed as per UX -->
      <div class="reservation-status__time-indicator" (click)="overrideTimeRemaining(party)"
        *ngIf="party.State == PartyState.Seated && party.IsCommunal == undefined && !hide_turnTimeIcon">
        <span
          class="reservation-status__override-icon icon-edit"></span>
        <mat-progress-spinner class="reservation-status__total-time" [diameter]="50" [value]="100">
        </mat-progress-spinner>
        <mat-progress-spinner [ngClass]="{
                              'first-percent' : party.progressBarValue < 50,
            'second-percent': party.progressBarValue >= 50,
            'third-percent': party.progressBarValue  >= 80}" class="reservation-status__time-loader" mode="determinate"
          [diameter]="50" [value]="progressBarValue">
        </mat-progress-spinner>
        <span *ngIf="(party.remainingTime > 0)" cli class="reservation-status__total-mins seat-labels">
          {{ party.remainingTime | formatWaitTime }}
        </span>
        <span *ngIf="(0 > party.remainingTime)" cli class="reservation-status__total-mins seat-labels">
          0{{'minsShortText' | translate }}
        </span>
      </div>
    </div>
    <div class="reservation__partysize" *ngIf="isPartysizeneed" [hidden]="coverTypeConfig?.length > 0">
      <div class="reservation__partysize__content">
        <span class="seat-header-three">  <i class="icon-Union-17 iconval"></i>{{(party.State == PartyState.Pending ? 'newPartySize' : 'partySize') | translate}}</span>
        <dynamic-form class="formval" [config]="setPartySizeConfig" #form="dynamicForm">
        </dynamic-form>

      </div>
   </div>
    <div class="reservation__cover-types" [hidden]="!isPartysizeneed || coverTypeConfig?.length == 0">
      <span class="seat-header-three"> <i class="icon-Union-17 iconval"></i> {{'coverType' | translate}}</span>
      <dynamic-form [config]="coverTypeConfig" #coverTypeDetails></dynamic-form>
    </div>
    <div class="reseat" *ngIf="showReseat && party.State != PartyState.Seated && party.isserverneed">
      <div class="reseat_time">{{'unseated' | translate}} {{leftTableAgo}} {{'minAgo' | translate}}</div>
      <div class="reseat_contact">
        <span class="icon-Union-17"></span>
        <span class="reseat_contact_name">{{guestName}}</span>
        <span class=reseat_Btn>
          <activities-app-button [buttontype]="reseatbtn" (valueChange)="partyActions(0,5)"></activities-app-button>
        </span>
      </div>
    </div>
    <div class="tabledetails" *ngIf="party.State == PartyState.Blocked">
      <div *ngFor="let rule of blockTableDetails">
        <div>
          {{'shifts'  | translate}} : {{rule.Shifts}}
        </div>
        <div>
          {{'frequency' | translate}} : {{rule.Frequency}}
        </div>
        <div>
          {{'starts' | translate}} : {{rule.StartDate == '--' ? '--' :  (rule.StartDate |  localizedDate: (cs.settings.value.General.DateFormat || 'll'))}}
        </div>
        <div>
          {{'ends' | translate}} :{{rule.EndDate == '--' ? '--' :  (rule.EndDate |  localizedDate: (cs.settings.value.General.DateFormat || 'll'))}}
        </div>
        <div>
          {{'AllocationType' | translate}} : {{rule.AllocationType}}
        </div>
        <div>
          {{'blockingreason' | translate}} : {{rule.BlockingReasion}}
        </div>
        <hr>
      </div>
      <a *ngIf="showMoreSpan && blockTableDetails.length > 1" class="show-more seat-primary-text" (click)="showMoreData()">{{'ShowMore' | translate}}...</a>
      <a *ngIf="!showMoreSpan && blockTableDetails.length > 1" class="show-less seat-primary-text" (click)="showLessData()">{{'ShowLess' | translate}}...</a>
    </div>

    <div *ngIf="(party.State === PartyState.Seated || party.State === PartyState.Pending || (party.Type == 1 && party.State == PartyState.Pending)) && party.IsCommunal == undefined">
      <client-section [seatData]="party" (close)="closeGuestInfoPanel()"></client-section>
    </div>
    <app-contact-list
      *ngIf="dashboardData?.IsCommunalTable && dashboardData?.communalTableData && dashboardData?.communalTableData?.length > 0"
      (selectedParty)="selectedPartyInCommunal($event)" [communalTableReservations]="dashboardData.communalTableData">
    </app-contact-list>
    <app-dashboard-status [dashboardStatus]="dashboardStatus"></app-dashboard-status>
    <app-dashboard-message *ngIf="party && party.Messages && !fromTables"></app-dashboard-message>
  </div>
  <app-print-container [selectedParties]="print" ></app-print-container>
  </div>

<!-- Add-on Popover -->
<ng-template #addOnForBooking>
  <app-add-on-popover [party]="partyWithAddon"></app-add-on-popover>
 </ng-template>