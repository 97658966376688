import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { RetailSetupService } from '../retail-setup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailBreakPoint, Maxlength, Host, Product } from '../../shared/globalsContant';
import { BaseResponse } from '../../shared/business/shared.modals';
import { HttpMethod } from '../../shared/service/http-call.service';
import { RetailDataAwaiters } from '../../shared/events/awaiters/retail.data.awaiters';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { LetterTemplateType, RetailItemTemplateUI } from '../../retail.modals';
import { Subscription } from 'rxjs';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { DmConfig} from 'src/app/common/Models/common.models';
import { RetailItemType } from 'src/app/common/shared/retail.modals';
@Component({
  selector: 'app-other-information',
  templateUrl: './other-information.component.html',
  styleUrls: ['./other-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OtherInformationComponent implements OnInit {
  otherInfoFormGrp: UntypedFormGroup;
  Units: any = [];
  captions: any;
  generalFormGroup: UntypedFormGroup;
  isSyncItem: boolean;
  @Input() isEdit;
  IsViewOnly: boolean = false;
  otherChanges: any;
  validateNext: boolean = false;
  validateBtn: boolean = false;
  SNCProduct: number = Product.SNC;
  Golf: string = 'GOLF';
  validateBtnSubscription: Subscription;
  productUiConfig = {
    pms: { hidden: false },
    multipack: { hidden: false },
    gratuityServiceCharge: { hidden: false }
  };

  loginProd: string;
  percentageMaxLength = Maxlength.PERCENTAGE;
  functionalities: { [key: string]: boolean } = {};
  clubAgreementOptions: DropdownOptions[];
  cartAgreementOptions: DropdownOptions[];
  shoeAgreementOptions: DropdownOptions[];
  saleChitOptions: DropdownOptions[];
  floatLabel: string;
  dmConfig: DmConfig;
  isEformsEnabled = false;
  retailItemTypes = RetailItemType;
  @Input() isExternalItem = false;
  @Input() parentForm;
  throttleTime: number = 1000;
  constructor(public _retailService: RetailSetupService, private dialogRef: MatDialogRef<RetailPopupComponent>,
    public localization: RetailLocalization, public PropertyInfo: RetailPropertyInformation, private func: RetailFunctionalityBusiness
    ,private dmConfigDataService: DMConfigDataService) {
    this.isSyncItem = PropertyInfo.UseRetailInterface;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.setProductBasedUI();
    this.getDmconfig();
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
    });
    this.captions = this.localization.captions.retailsetup;
    this.validateBtnSubscription = this._retailService.validateBtn.subscribe(isValid => this.validateBtn = isValid);
    this.Units = [];
    this.generalFormGroup = this._retailService.GeneralFormGrp;
    this.otherInfoFormGrp = this._retailService.otherInfoFormGrp;
    if (this.otherInfoFormGrp.controls.scaleItem.value && this.otherInfoFormGrp.controls.unit.value == 0) {
      this.otherInfoFormGrp.controls.unit.markAsTouched();
    }
    this.IsViewOnly = this._retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == RetailBreakPoint.ItemSetup).view;
    this.validateNext = this._retailService.validateNext('other');
    this.loginProd = this._retailService.loginProd;
    if (this.loginProd.toLowerCase() != this.Golf.toLowerCase()) {
      this.otherInfoFormGrp.controls.timeTrigger.setValue(false);
      this.otherInfoFormGrp.controls.cartTrigger.setValue(false);
      this.otherInfoFormGrp.controls.printCart.setValue(false);
      this.otherInfoFormGrp.controls.printClub.setValue(false);
      this.otherInfoFormGrp.controls.printShoe.setValue(false);
    }
    if(this.parentForm){
      this.parentForm.addControl('otherInfoFormGroup', this.otherInfoFormGrp)
    }
    //Feature 57221: Cart, Shoe, Club Agreement ,Retail sale Chit , Retail sale chit Not Implemented 

    // this.otherInfoFormGrp.controls.cartTemplate.setValidators(Validators.required);
    // this.otherInfoFormGrp.controls.clubTemplate.setValidators(Validators.required);
    // this.otherInfoFormGrp.controls.cartTemplate.setValidators(Validators.required);
    // this.otherInfoFormGrp.controls.saleChit.setValidators(Validators.required);
    //this.getTemplateDropdownData();
  }

  ngAfterViewInit() {
    this.formValueChanges();
  }

  async getDmconfig()
  {
    await this.dmConfigDataService.getDataMagineConfig().then(res => {
      this.dmConfig = res;
      this.isEformsEnabled = this.dmConfig && this.dmConfig.enableDataMagine && this.dmConfig.dmEformsConfig.enableEforms ? true : false;
      console.log(this.isEformsEnabled)
    });    
  }
  getTemplateDropdownData() {
    this._retailService.GetAllTemplatesForRetailDropdown().then(templates => {
      if (this.loginProd.toLowerCase() == this.Golf.toLowerCase()) 
      {
        this.cartAgreementOptions = this.TemplateDropdownMapper(templates.filter(t => t.type == LetterTemplateType.CartAgreement));
        this.clubAgreementOptions = this.TemplateDropdownMapper(templates.filter(t => t.type == LetterTemplateType.ClubAgreement));
        this.shoeAgreementOptions = this.TemplateDropdownMapper(templates.filter(t => t.type == LetterTemplateType.ShoeAgreement));
        if (!this.otherInfoFormGrp.controls.clubTemplate.value)
        this.otherInfoFormGrp.controls.clubTemplate.setValue(templates.find(t => t.isDefault && t.type == LetterTemplateType.ClubAgreement)?.id);
      if (!this.otherInfoFormGrp.controls.cartTemplate.value)
        this.otherInfoFormGrp.controls.cartTemplate.setValue(templates.find(t => t.isDefault && t.type == LetterTemplateType.CartAgreement)?.id);
      if (!this.otherInfoFormGrp.controls.shoeTemplate.value)
        this.otherInfoFormGrp.controls.shoeTemplate.setValue(templates.find(t => t.isDefault && t.type == LetterTemplateType.ShoeAgreement)?.id);
      }
         this.saleChitOptions = this.TemplateDropdownMapper(templates.filter(t => t.type == LetterTemplateType.RetailSaleChit));
       if (!this.otherInfoFormGrp.controls.saleChit.value)
        this.otherInfoFormGrp.controls.saleChit.setValue(templates.find(t => t.isDefault && t.type == LetterTemplateType.RetailSaleChit)?.id);

    });
  }
  TemplateDropdownMapper(templateData): DropdownOptions[] {
    const options: DropdownOptions[] = templateData.map((a: RetailItemTemplateUI) => {
      const option: DropdownOptions = {
        id: a.id,
        value: a.id,
        viewValue: a.name,
        disabled: false,
      }
      return option;
    });
    return options;
  }
  formValueChanges() {
    this.otherChanges = this.otherInfoFormGrp.valueChanges.subscribe(data => {
      this.otherInfoFormGrp.markAsTouched();
      this._retailService.ValidateUpdate();
      this.validateNext = this._retailService.validateNext('other');
    });
  }

  toogleChange(event) {
    if (event[0]) {
      this.generalFormGroup.controls['commissionableitem'].setValue(false);
      this.generalFormGroup.controls['commissionreq'].setValue(false);
      this.generalFormGroup.controls['commissionableitem'].disable();
      this.generalFormGroup.controls['commissionreq'].disable();
      this.otherInfoFormGrp.controls['groupKey'].setValue(false);
      this.otherInfoFormGrp.controls['scaleItem'].setValue(false);
      this.otherInfoFormGrp.controls['groupKey'].disable();
      this.otherInfoFormGrp.controls['scaleItem'].disable();
      this.otherInfoFormGrp.controls['openItem'].setValue(false);
      this.otherInfoFormGrp.controls['openItem'].disable();
    } else {
      this.generalFormGroup.controls['commissionableitem'].enable();
      this.generalFormGroup.controls['commissionreq'].enable();
      this.otherInfoFormGrp.controls['groupKey'].enable();
      this.otherInfoFormGrp.controls['scaleItem'].enable();
      this.otherInfoFormGrp.controls['openItem'].enable();
    }
  }

  GKtoogleChange(event) {
    if (event[0]) {
      this.otherInfoFormGrp.controls['multipack'].setValue(false);
      this.otherInfoFormGrp.controls['scaleItem'].setValue(false);
      this.otherInfoFormGrp.controls['multipack'].disable();
      this.otherInfoFormGrp.controls['scaleItem'].disable();
    } else {
      this.otherInfoFormGrp.controls['multipack'].enable();
      this.otherInfoFormGrp.controls['scaleItem'].enable();
    }
  }

  SItoogleChange(event) {

    if (event[0]) {
      this.otherInfoFormGrp.controls['multipack'].setValue(false);
      this.otherInfoFormGrp.controls['multipack'].disable();
      this.otherInfoFormGrp.controls['groupKey'].setValue(false);
      this.otherInfoFormGrp.controls['groupKey'].disable();
      this.otherInfoFormGrp.controls['unit'].enable();
      this.otherInfoFormGrp.controls.unit.setValidators([Validators.required]);

    }
    else {
      this.otherInfoFormGrp.controls['multipack'].enable();
      this.otherInfoFormGrp.controls['groupKey'].enable();
      this.otherInfoFormGrp.controls.unit.setErrors(null);
      this.otherInfoFormGrp.controls['unit'].setValue(null);
      this.otherInfoFormGrp.controls['unit'].disable();
    }
    this.otherInfoFormGrp.controls.unit.updateValueAndValidity();
    this.otherInfoFormGrp.controls.unit.markAsTouched();
  }
  close() {
    this.dialogRef.close();
  }
  stopdefaultClick(e) {
    if (this.isEdit && this.IsViewOnly) {
      e.preventDefault();
    }
  }
  async save() {

    this._retailService.ValidateUpdate();
    if (!this.validateBtn) return;
    let updateBody = this._retailService.FormBodyData(this.isEdit, this.PropertyInfo.UseRetailInterface);
    let response;
    let itemId = 0;
    if (!this.isEdit) {
      this.validateBtn = false;
    }
    if (this.isEdit) {
      response = await this._retailService.InvokeServiceCallAsync("UpdateRetailItem", Host.retailManagement, HttpMethod.Put, updateBody, { id: updateBody.retailItem.retailItemDetail.id })
      if (response) {
        this._retailService.updateRetailItemImage(updateBody.retailItem.retailItemDetail.id);
        itemId = updateBody.retailItem.retailItemDetail.id;
      }
    }
    else {
      response = await this._retailService.InvokeServiceCallAsync("CreateRetailItem", Host.retailManagement, HttpMethod.Post, updateBody)
      if (response) {
        this._retailService.saveImage(response.result);
        itemId = response.result;
      }
    }
    if (this._retailService.showRetailSetupRoomTypesAssignmentTab) {
      RetailDataAwaiters.createOrUpdateRoomTypeAssignment(updateBody.retailItem.roomTypeAssignmentRetailItemMapping, itemId)
    }
    if (response && response.result) {
      this.dialogRef.close();
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "UpdateRetailItem") {
      if (result.result) {
        this.dialogRef.close();
      }
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }

  private setProductBasedUI() {
    switch (this._retailService.ProductId) {
      case Product.SPA:
        this.productUiConfig.pms.hidden = true;
        break;
      case Product.GOLF:
        this.productUiConfig.pms.hidden = true;
        this.productUiConfig.multipack.hidden = false;
        break;
      case Product.RETAIL:
        this.productUiConfig.pms.hidden = true;
        this.productUiConfig.gratuityServiceCharge.hidden = true;
        break;
      default:
        break;
    }
  }
  Validate(control, validate) {
    validate ? this.setValidator(control) : this.removeValidator(control)
  }
  setValidator(controlName) {
  //Feature 57221: Cart, Shoe, Club Agreement ,Retail sale Chit , Retail sale chit Not Implemented

    // this.otherInfoFormGrp.controls[controlName].setValidators([Validators.required]);
    // this.otherInfoFormGrp.controls[controlName].updateValueAndValidity();
    // this.otherInfoFormGrp.controls[controlName].markAsDirty();
  }

  removeValidator(controlName) {
    //Feature 57221: Cart, Shoe, Club Agreement ,Retail sale Chit , Retail sale chit Not Implemented

    // this.otherInfoFormGrp.controls[controlName].clearValidators();
    // this.otherInfoFormGrp.controls[controlName].updateValueAndValidity();
    // this.otherInfoFormGrp.controls[controlName].markAsDirty();
  }
  ngOnDestroy() {
    this.validateBtnSubscription.unsubscribe();
  }
}
