import { Injectable, inject } from '@angular/core';
import { Utilities } from '@utilities/utilities';
import { PartyService } from '@app/shared/services/party.service';
import { BookingBehavior, ComponentTypes } from '@app/shared/constants/commonenums';
import moment from 'moment';
import { HoldReservationDetails, IResetGuestType } from '../../../rental/interfaces/model';
import { SettingsDTO, StateDTO } from '@app/shared/models/RestaurantDTO';
import { CacheService } from '@app/core/services/cache.service';
import { Subject, Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { RentalApiService } from '../../../rental/services/rental.api.service';
import { LocalizedDatePipe } from '@app/shared/pipes/localize-date.pipe';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RentalRoutes } from "@app/shared/constants/commonenums";
import { StandaloneBookingDTO } from '@app/shared/models/OpenBookingDTO';
import { TimeInterval } from '@app/rental/const/rental.const';
import { addLeadingZero, convertTimeFormat, getGuestTypes } from '@app/rental/rental-utilities';
import { ISelectedRentalItems } from '@app/rental/interfaces/asset-check-availability';
import { ICart } from '@app/rental/interfaces/cart.interface';

@Injectable({
  providedIn: 'root'
})
export class RentalService {

  holdAssetsResponse: HoldReservationDetails;
  filterData: IRentalFilterData = {
    fromDate: null,
    toDate: null,
    fromTime: '',
    toTime: ''
  }
  selectedAddons: ISelectedRentalItems[] = [];
  rentalCartDetails: ICart;
  isPaymentStarted = false;
  _state: StateDTO = {} as StateDTO;
  _settings: SettingsDTO = {} as SettingsDTO;
  subscriptions: Subscription = new Subscription();
  isCartEdit = false;
  isReservationEdit = false;
  isFilterOpened = false;
  coverCount = 1;
  isGuestSelected: boolean = false;
  isFromRentalDashboard = false;
  releaseAssetWarning = true;
  currentDate: Date;
  appliedFilter = {
    CategoryTypes: [],
    CategoryGroups: [],
    CategorySubGroups: [],
    CategorySubTypes: []
  };
  
  partyService = inject(PartyService);
  cs = inject(CacheService);
  rentalApiService = inject(RentalApiService);
  router = inject(Router);
  ts = inject(TranslateService);
  reload$: Subject<any> = new Subject<number>();
  updateCalenderFilter$: Subject<boolean> = new Subject<boolean>();
  localizedDate = inject(LocalizedDatePipe);

  guestSelectionValidity = new Subject<boolean>();
  resetGuestSelection: Subject<IResetGuestType> = new Subject<IResetGuestType>();
  invokeRatePlanSummary: Subject<boolean> = new Subject<boolean>();
  appliedPromoCode: any;
  constructor() {
    this.subscriptions.add(this.cs.state?.subscribe(ste => {
      this._state = ste;
      this.currentDate = this._state?.CurrentTime;
      setTimeout(() => {
        this.setupCalenderFilter();
      }, 0);
    }));
    this.subscriptions.add(this.cs.settings?.subscribe(sett => {
      this._settings = sett;
    }));
    this.subscriptions.add(this.guestSelectionValidity.subscribe(value => {
      this.isGuestSelected = value;
    }));
  }

  setupCalenderFilter(): void {
    if (!this.currentDate) {
      return;
    }
    const parsedDate = Utilities.parseDateString(this.currentDate);
    const restaurantDate = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta).setMinutes(0));

    this.filterData.fromDate = parsedDate;
    this.filterData.toDate = moment(parsedDate).add(TimeInterval.amount, TimeInterval.unit);
    this.filterData.fromTime = this.localizedDate.transform(restaurantDate, 'LT');
    this.filterData.toTime = this.localizedDate.transform(restaurantDate.clone().add(TimeInterval.amount, TimeInterval.unit), 'LT');
  }

  updateCalendarFilter(date): void {
    this.currentDate =  date;
    this.setupCalenderFilter();
  }

  getDialogMessageConfig(msg: string) {
    const popUpMessage = [{
      confirmationMessage: msg,
      dialogTitle: null
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
      popUpMessage, popUpMessage[0].dialogTitle);

    return componentDetails;
  }

  getSlotDetails() {
    try {
      let fromTime = addLeadingZero(this.filterData.fromTime);
      let toTime = addLeadingZero(this.filterData.toTime);
      let startDate = `${moment(this.filterData.fromDate).format('YYYY-MM-DD')}T${convertTimeFormat(fromTime)}:00`;
      let endDate = `${moment(this.filterData.toDate).format('YYYY-MM-DD')}T${convertTimeFormat(toTime)}:00`;
      return [{
        Time: startDate,
        DurationInMinutes: moment.duration(moment.utc(new Date(endDate), 'LT').diff(moment.utc(new Date(startDate), 'LT')), 'milliseconds').asMinutes(),
        LockId: this.holdAssetsResponse?.LockId,
        Type: BookingBehavior.RentalBooking,
        EndTime: endDate,
        OverBooked: null
      }]
    } catch (ex) {
      console.error(ex);
    }
  }

  reset(resetFilter = true): void {
    this.coverCount = 1;
    this.isPaymentStarted = false;
    this.holdAssetsResponse = null;
    this.rentalCartDetails = null;
    this.isCartEdit = false;
    this.isReservationEdit = false;
    resetFilter && this.setupCalenderFilter();
    this.resetStandaloneBooking();
    this.resetGuestSelection.next({type: 'RESET', value: true});
    this.appliedPromoCode = null;
    this.rentalApiService.selectedAssets = null;
  }

  updateCartDetails(data, isReservationEdit = false): void {
    this.isCartEdit = isReservationEdit ? false : true;
    this.isReservationEdit = isReservationEdit;
    this.isPaymentStarted = false;
    let slotData = data?.Slots[0];
    this.holdAssetsResponse = {
      LockId: slotData.LockId,
      ExpireAt: '',
      HoldReferenceCode: ''
    }
    this.coverCount = data?.BookingContacts?.length;
    this.rentalCartDetails = data;
    this.filterData.toDate = new Date(slotData?.EndTime);
    this.filterData.fromDate = new Date(slotData?.Time);
    this.filterData.fromTime = this.localizedDate.transform(slotData?.Time, 'LT');
    this.filterData.toTime = this.localizedDate.transform(slotData?.EndTime, 'LT');

  }

  ratePlanRequest() {
    return {
      Slots: this.getSlotDetails(),
      SessionSlot: {},
      TableIds: [],
      CoverTypes: null,
      ActivityId: null,
      FinancialEffectId: null,
      BookingBehavior: BookingBehavior.RentalBooking,
      FromDate: `${moment(this.filterData.fromDate).format('YYYY-MM-DD')}T${convertTimeFormat(addLeadingZero(this.filterData.fromTime))}:00`,
      ToDate: `${moment(this.filterData.toDate).format('YYYY-MM-DD')}T${convertTimeFormat(addLeadingZero(this.filterData.toTime))}:00`,
      RatePlan: null,
      AddOns: null,
      InstructorIds: null,
      BookingId: this.rentalCartDetails?.Id,
      PackageDetails: null,
      IsExclusiveBooking: false,
      PromoCode: null,
      ClearDiscount: null
    };

  }

  canExit(): boolean {
    if (this.holdAssetsResponse?.HoldReferenceCode && this.releaseAssetWarning) {
      if (this.isPaymentStarted) {
        return true;
      }
      if (confirm(this.ts.instant('combineGuest_warning'))) {
        this.rentalApiService.releaseHold(this.holdAssetsResponse.LockId).subscribe((_res) => {
        });
        return true;
      } else {
        return false;
      }
    }
  }

  releaseHoldAssets(reload = true) {
    this.rentalApiService.releaseHold(this.holdAssetsResponse.LockId).subscribe((_res) => {
      this.releaseAssetWarning = true;
      if (this.router.url?.includes(RentalRoutes.RentalBooking)) {
        reload && this.reload$.next();
      }
    });
  }

  async checkRentalBookingStatus(reload = false): Promise<boolean> {
    if (this.router.url?.includes(RentalRoutes.RentalBooking) && this.holdAssetsResponse?.HoldReferenceCode) {
      if (confirm(this.ts.instant('combineGuest_warning'))) {
        try {
          await this.rentalApiService.releaseHold(this.holdAssetsResponse.LockId).toPromise();
          if (reload) {
            this.reload$.next();
            this.reset();
          }
          return true;
        } catch (error) {
          console.error('Error releasing hold:', error);
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }


  get bookingData() {
    return this.partyService?.standaloneBookingData;
  }

  resetStandaloneBooking(): void {
    this.partyService.bookingSize = this.coverCount;
    this.partyService.standaloneBookingData = new StandaloneBookingDTO(
      null,
      this.filterData.fromDate,
      this.filterData.toDate,
      [],
      null,
      1,
      [],
      null,
      [{
        BookingTypeId: 0,
        BookingTypeName: null,
        CoverTypeId: 0,
        CoverTypeName: null,
        Covers: this.coverCount,

      }],
      null,
      null,
      BookingBehavior.RentalBooking,
      null,
      null, null, null
    );
  }
}


export interface IRentalFilterData {
  fromDate: any;
  toDate: any;
  fromTime: string;
  toTime: string;
}