import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { AuthenticationService } from '@app/common/shared/services/authentication.service';
import { LoaderService } from '@app/core/services/loader.service';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { ResetPinComponent } from '@components/reset-pin/reset-pin.component';
import { ComponentTypes, LoadRestaurantOptions, LoginOperation, Menu, VCartActivityType } from '@constants/commonenums';
import { ActivitiesVenue, ScreenType , controlSettings } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { LoginService } from '@core/services/login.service';
import { MultilingualService } from '@core/services/multilingual.service';
import { Host } from '@models/HostDTO';
import { LoginResultDTO, LoginResultType, RetailDetailsDTO } from '@models/LoginResultDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { RetailLoginService } from '@services/retail-login.service';
import { SettingsService } from '@services/settings.service';
import { Utilities } from '@utilities/utilities';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import * as FullStory from '@fullstory/browser';
import fullStoryRestaurants from '@data/fullStory.json';
import { environment } from '@environments/environment';
import { MachineName } from '@app/common/shared/shared.modal';
import { I } from '@angular/cdk/keycodes';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { AuthenticationResultDTO } from '../models/AuthenticationResultDTO';

@Injectable()
export class LoginFunction extends Utilities implements OnDestroy {

  loginErrorMsg: string = "";
  errorMsg: string;
  subscriptions: Subscription = new Subscription();
  ComponentType: ComponentTypes;
  pinSubscription: Subscription;
  cancelSubscription: Subscription;
  dialogRef: MatDialogRef<CustomPopupComponent>;
  failedAttempts: number = 0;
  confSubscription: Subscription;
  retailSubscription: Subscription = new Subscription();
  loginSubscription: Subscription = new Subscription();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  isIframe = false;
  isFirstLogin = true;
  public propListUpdated: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private as: AppService, private ls: LoginService, private ts: TranslateService, private cs: CacheService, private ms: MultilingualService, private router: Router, dialog: MatDialog,
    private ss: SettingsService, private popupService: PopupService, private loaderService: LoaderService,
    private authService: AuthenticationService, public retailLogin: RetailLoginService, private _snackBar: MatSnackBar) {
    super(dialog);
  }
  VCartPropertyTypeFiler(RestaurantsAvailableForLogin){
    let properties = RestaurantsAvailableForLogin;
    const urlParams = new URLSearchParams(window.location.search);
    const activityType = urlParams.get('activityType');
    if(activityType == VCartActivityType.Dining ){
      properties = RestaurantsAvailableForLogin.filter(property => controlSettings.VCart_Dining_Show.includes(property.PropertyType));
    }else if(activityType == VCartActivityType.Activity ){
      properties =RestaurantsAvailableForLogin.filter(property => controlSettings.VCart_NonDining_Show.includes(property.PropertyType));
    }
    return properties
  }
  async loadLoginInfo(data, restID) {
    let loginResult: LoginResultDTO = {} as LoginResultDTO;
    this.as.restaurantId = Number(restID); // logout and login called asynchronously while switching and causing restaurant id to null
    if (data && data.State === 0 && data.Payload && data.Payload.Result == LoginResultType.Successful) {
      loginResult = data.Payload;
      if(this.cs.isIframeEnabled && loginResult){
        loginResult.RestaurantsAvailableForLogin = this.VCartPropertyTypeFiler(loginResult.RestaurantsAvailableForLogin);
      }
      loginResult.LoggedInRestaurantId = Number(restID);
      sessionStorage.setItem(`sessionGUID${Utilities.getSessionStorageType()}`, this.generateGuid());
      localStorage.setItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`, JSON.stringify(loginResult));
      if (loginResult.IsFirstTimeLogin && loginResult.AllowResetPin) {
        this.ss.GetHostUser(loginResult.HostId).subscribe((data) => {
          if (data.Payload) {
            let hostData = data.Payload;
            this.performResetPassword(hostData);
          }
        });
      }
      else {
        this.as.isRetailLoggedinFailed = false;
        let restaurantId = +sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`);
        if (loginResult.RetailDetailDTO) {
          let retailData = loginResult.RetailDetailDTO.find(retail => retail.RestaurantId == restaurantId);
          if (retailData && retailData.RetailUserName && retailData.RetailPassword) {
            try {
              await this.retailLogin.setEncryptKey();
              await this.retailLogin.getCustomerId();
              setTimeout(() => {
                this.retailLogin.login$.next(LoginOperation.Inprogress);
              }, 10);
            } catch (error) {
              setTimeout(() => {
                this.retailLogin.login$.next(LoginOperation.Failed);
              }, 10);
            }

            if(this.loginSubscription){
              this.loginSubscription.unsubscribe();
            }

            this.loginSubscription = this.retailLogin.login$.subscribe((val) => {
              if (val == LoginOperation.Inprogress) {
                this.getRetailToken(retailData);
                if (this.as.retailLoginSubscription)
                  this.as.retailLoginSubscription.unsubscribe();
              }
              else if (val == LoginOperation.Failed) {
                this.setLoginDetails();
                this.setAutoRetailLogin();
              }
              if (val == LoginOperation.Completed) {
                this.subscribeRetailData();
              }
              if (this.loginSubscription && val != LoginOperation.Inprogress) {

                this.loginSubscription.unsubscribe();
              }
            });
          } else {
            this.setLoginDetails();
          }
        } else {
          this.setLoginDetails();
        }
      }
    } else if (data && data.State == 5) {
      this._snackBar.open(this.ts.instant('sessionExpiredWarningMsg'), 'Close', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 2000,
      });
      sessionStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    else {
      switch (data.Payload.Result) {
        case LoginResultType.TemporarilyLocked:
          const lockedMinutes = data.Payload.LockedForMinutes;
          this.loginErrorMsg = `${this.ts.instant('lockedMessage')} ` + lockedMinutes +
            ` ${this.ts.instant('Minutes')}`;
          break;
        case LoginResultType.WrongCredentials:
          this.loginErrorMsg = `${this.ts.instant('loginpinFailMessage')}`;
          break;
        case LoginResultType.ConcurrentUsersLimitationExceeded:
          this.loginErrorMsg = `${this.ts.instant('concurrentUserFailMessage')}`;
          break;
        case LoginResultType.LockedDueToBillingIssues:
          const contactMessage: string = `${this.ts.instant('contactSeatMessage')}`;
          this.loginErrorMsg = `${this.ts.instant('lockedBillingIssueMsg')}` + contactMessage;
          break;
        case LoginResultType.InActive:
          this.loginErrorMsg = `${this.ts.instant('inActiveRoleMessage')}`;
          break;
        default:
          this.loginErrorMsg = `${this.ts.instant('loginpinFailMessage')}`;
          break;
      }
      const popUpMessage = [{
        confirmationMessage: this.loginErrorMsg,
        dialogTitle: this.ts.instant('authFailed'),
        showAlert: true
      }];
      let okbutton = this.ts.instant('ok');
      const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
        popUpMessage, popUpMessage[0].dialogTitle);
      this.openCustomPopup(componentDetails, ComponentTypes.login, '450px', 'auto', true, '', okbutton,
        '', true, null, null, false, 'dialog');
      this.as.logout();
    }
  }
  login(pincode: string) {
    let restID = sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`);
    this.as.restaurantId = Number(restID);
    Utilities._restaurantId = Number(restID);
    //const password: string = breakin ? ('****' + sessionStorage.getItem('MerchantKey')) : pincode;
    const password: string = pincode;
    this.subscriptions.add(this.ls?.login(password).subscribe(async (data) => {
      this.loadLoginInfo(data, restID);
    }));
  }

  generateGuid(): string {
    return 'xxxxxxxx-xxxx-8xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Utilities.getRandomDecimal() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  loginViaToken(token: string) {
    let restID = sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`);
    this.as.restaurantId = Number(restID);
    Utilities._restaurantId = Number(restID);
    // const password: string = breakin ? ('****' + sessionStorage.getItem('MerchantKey')) : pincode;
    this.subscriptions.add(this.ls.loginViaToken(token).subscribe((data) => {
      this.loadLoginInfo(data, restID);
    }));
  }

  performResetPassword(hostData: Host) {
    this.showResetPinpopup(hostData);
  }

  showResetPinpopup(hostData: Host) {
    if (this.pinSubscription) {
      this.pinSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    let resetPinValue = null;
    const resetPinTitle: string = this.ts.instant('resetPinTitle');
    const incorrectPinTitle: string = this.ts.instant('incorrectPinTitle');
    const resetBtn: string = this.ts.instant('reset');
    const cancelBtn: string = this.ts.instant('cancel');

    const componentDetails: ComponentDetails = {
      componentName: ResetPinComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: hostData
    };
    this.dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '350px',
      minHeight: '500px',
      height: '30%',
      data: {
        title: this.failedAttempts > 0 ? incorrectPinTitle : resetPinTitle,
        update: resetBtn,
        cancel: cancelBtn,
        componentDetails,
        from: ComponentTypes.resetPin,
        back: false,
        standalone: true
      }
    });
    this.popupService.restrictCloseDialog = true;

    this.confSubscription = this.popupService.confirmedAction$.subscribe((val) => {
      if (val == ComponentTypes.resetPin) {
        hostData.PinCode = resetPinValue;
        hostData.IsFirstTimeLogin = false;
        this.ss.host = { ...hostData };
        this.ss.UpdateHost().subscribe(data => {
          if (data.State == 0) {
            this.dialogRef.close();
            this.ls.resetPinChange$.next(true);
            localStorage.removeItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`);
          }
        });
      }
    });

    this.subscriptions.add(this.dialogRef.afterClosed().subscribe(() => {
      if (this.confSubscription) {
        this.confSubscription.unsubscribe();
      }
      if (this.pinSubscription) {
        this.pinSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
    }));

    this.cancelSubscription = this.popupService.cancelledAction$.subscribe((val) => {
      if (val.from == ComponentTypes.resetPin) {
        this.failedAttempts = 0;
        this.dialogRef.close();
      }
    });

    this.pinSubscription = this.loaderService.resetPin$.subscribe((userPin) => {
      resetPinValue = userPin;
    });

  }

  setLoginDetails() {
    //this.getRestaurantImages();
    this.loadRestaurantSettings();
    if (this.retailSubscription) {
      this.retailSubscription.unsubscribe();
    }
  }

  startFullstoryRec() {
    sessionStorage.setItem(`isAppLoggedIn${Utilities.getSessionStorageType()}`, 'true');
    if (this.as.restaurantId && this.as.fullStoryProperties.find(rest => rest.restaurantId === this.as.restaurantId)) {
      this.as.isFullStoryExecuted = true;
      window['_fs_org'] = environment.fullStory;
      if (!this.isFirstLogin) {
        FullStory.restart()
      } else {
        window['fullStoryExecutable'](window, document, window['_fs_namespace'], 'script', 'user');
        this.isFirstLogin = false;
      }
    } else {
      this.as.isFullStoryExecuted = false;
    }
  }

  async loadRestaurantSettings() {
    this.startFullstoryRec();
    this.cs.restoreSettingsAndFillCache(LoadRestaurantOptions.Full).subscribe((data) => {
     console.log("file.login-functions [loadRestaurantSettings] restoreSettingsAndFillCache response: ", this.cs.settings, data)
      if(data && this.cs.settings.value){
        console.log("[loadRestaurantSettings] Before PrioritizedPartyListType switch")
      switch (this.cs.settings.value?.General.PrioritizedPartyListType) {
          case 0:
            this.as.applySetting(Menu.Reservation);
            break
          case 1:
            this.as.applySetting(Menu.WaitList);
            break;
          case 2:
            this.as.applySetting(Menu.TimeLine);
            break;
        }
 console.log("file.login-functions [loadRestaurantSettings] restoreSettingsAndFillCache response");
      this.navigateToReservation(sessionStorage.getItem('posView') !== "true");
        this.setPropertyType();
      }
    })
  }


  getRestaurantImages() {
    this.cs.getRestaurantImages();
  }

  getRetailToken(retailData: RetailDetailsDTO, SilentLogin?) {
    if (retailData && retailData.RetailUserName && retailData.RetailPassword) {
      this.retailLogin.userName = retailData.RetailUserName;
      this.retailLogin.password = retailData.RetailPassword;
      this.retailLogin.siteId = +retailData.RetailSiteId;
      const credentials = {
        UserName: retailData.RetailUserName,
        Password: retailData.RetailPassword,
        TenantId: retailData.RetailTenantId,
        TenantCode: "",
        Property: "Agilysys",
        ProductId: 2
      };

      this.retailLogin.validateCredentials(credentials);
      //this.authService.login(this.retailLogin.userName, this.retailLogin.password, this.retailLogin, retailData.RetailTenantId);
    }
  }

  subscribeRetailData() {
    this.retailSubscription = this.retailLogin.singleMachine$.subscribe((data) => {
      if (data && (data?.length == 1 || data?.length == 0)) {
        this.retailLogin.CreateUserSession(this.retailLogin.propertyInformation, false);
        this.setLoginDetails();
        this.retailLogin.singleMachine$.next(null);
      }
    });
  }

  navigateToReservation(exitPOS?) {
    console.log("file.login-functions [navigateToReservation] Start")

    this.as.setUserLoggedIn(true);
    let menuFromMP = sessionStorage.getItem('section');
    if (!this.cs.isIframeEnabled) {

    console.log("file.login-functions [navigateToReservation] After setUserLoggedIn-fnCall RefreshUrl: ", this.as.refreshURL)

      if (menuFromMP && menuFromMP != "null") {
        if (menuFromMP.toLowerCase() == "reports") {
          this.router.navigate(['./reports/report']);
        }
        if (menuFromMP.toLowerCase() == "layout") {
          this.router.navigate(['./floorplan']);
        }
        sessionStorage.removeItem(`merchantPortalRedirectToken${Utilities.getSessionStorageType()}`);
      }else if (!exitPOS && this.cs.settings.value.AllowPOSReservation) {
        this.as.selectedMenuId = Menu.ActivitiesTimeline;
        this.router.navigate(['./pos']);
      } 
      else if(this.as.refreshURL) {
        console.log("file.login-functions [navigateToReservation] inside refreshURL If block: ", this.as.refreshURL)
        this.router.navigate(['./'+this.as.refreshURL]);
        this.as.resetPOS();
          this.as.refreshURL = null;
        }
      else if (ActivitiesVenue.indexOf(this.as.PropertyType) > -1) {
        this.as.selectedMenuId = Menu.ActivitiesTimeline;
        this.router.navigate(['./activities-timeline']);
      }
      else {
      console.log("file.login-functions [navigateToReservation] Before PrioritizedPartyListType Switch: ", this.cs.settings.value.General.PrioritizedPartyListType)

      switch (this.cs.settings.value.General.PrioritizedPartyListType) {
          case 0:
            this.router.navigate(['./reservation']);
            break
          case 1:
            this.router.navigate(['./waitlist']);
            break;
          case 2:
            this.router.navigate(['./timeline']);
            break;
        }
        this.as.resetPOS();
        // if(this.appService.posView){
                
      }
    }
    this.as.setSignalrClose(true);
  }

  setPropertyType() {
    this.ms.setPropertyType(this.as.PropertyType, this.as.LanguageFileURL);
  }

  setAutoRetailLogin() {
    this.as.isRetailLoggedinFailed = true;
    if (this.as.retailLoginSubscription)
      this.as.retailLoginSubscription.unsubscribe();
    this.as.retailLoginSubscription = interval(300000).subscribe(async () => {
      let settings = this.cs.settings.value;
      this.as.isRetailLoggedinFailed = true;

      if (Utilities.isRetailEnabledProperty(settings.General.RetailIntegrationDTO)) {
        let loginResult: LoginResultDTO = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
        if (loginResult.RetailDetailDTO) {

          this.retailLogin.isSilientLogin = true;
          this.cs.isSilentLogin = true;
          let retailData = loginResult.RetailDetailDTO.find(retail => retail.RestaurantId == Utilities.RestaurantId());
          if (retailData && retailData.RetailUserName && retailData.RetailPassword) {
            try {
              await this.retailLogin.setEncryptKey();
              await this.retailLogin.getCustomerId();
              this.retailLogin.login$.next(LoginOperation.Inprogress)
            } catch (error) {
              this.retailLogin.login$.next(LoginOperation.Failed);
            }
            this.retailLogin.isSwitchProperty = true;

            let loginSubscription: Subscription = this.retailLogin.login$.subscribe((loginResult) => {
              if (loginResult == LoginOperation.Completed) {
                this.setRetailMachineDetails();

              }
              if (loginResult == LoginOperation.Inprogress) {
                this.getRetailToken(retailData, true);
                this.as.isRetailLoggedinFailed = false;
                this.as.retailLoginSubscription.unsubscribe();
              } else {
                console.log("login Falied");
              }
              if (loginResult == LoginOperation.Failed) {
                this.setLoginDetails();
                this.setAutoRetailLogin();
              }

              this.cs.isSilentLogin = false;
              if (loginSubscription && loginResult != 1)
                loginSubscription.unsubscribe();
            });
          }
        }
      }
    });

  }

  setRetailMachineDetails() {
    let machineSubscription: Subscription = this.retailLogin.selectedMachines$.subscribe((machineDetails: MachineName[]) => {
      if (machineDetails && machineDetails.length > 1) {
        if (this.retailLogin.defaultMachine) {
          let machine = machineDetails.find(option => option.id == this.retailLogin.defaultMachine);
          if (machine) {
            this.retailLogin.storeMachineInfo(machine);
          }
        } else {
          this.retailLogin.storeMachineInfo(machineDetails[0])
        }

      }
      this.retailLogin.CreateUserSession(this.retailLogin.propertyInformation, false);
      machineSubscription.unsubscribe();
    })
  }

  unlock(isinitialIzed?, Username?, Password?, fromIframe = false): boolean {
    //this.unlockconfig[1].inputType = 'password';
    //this.unlockconfig[1].icon1 = 'icon-eye';
    //if (this.unlockSubscription) { this.unlockSubscription.unsubscribe(); }

    let merchantKey = sessionStorage.getItem(`MerchantKey${Utilities.getSessionStorageType()}`);
    this.cs.isIframeEnabled = fromIframe;
    this.retailLogin.isIframesEnabled = fromIframe;
    if (merchantKey && merchantKey != null) {
      this.ls.authenticate().subscribe((data) => {
        this.unlockLoginOrRestaurant(data);
      });
    } else if ((Username && Password)) {
      this.ls.unlockRestaurant({ 'username': Username, 'password': Password }).subscribe(data => {
        this.unlockLoginOrRestaurant(data);
      });
    } else {
      if (!isinitialIzed) {
        if ((Username && Password)) {
          const popUpMessage = [{
            confirmationMessage: `${this.ts.instant('loginFailMessage')}`,
            dialogTitle: this.ts.instant('authFailed'),
            showAlert: true
          }];
          let okbutton = this.ts.instant('ok');
          const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
            popUpMessage, popUpMessage[0].dialogTitle);
          this.openCustomPopup(componentDetails, ComponentTypes.login, '450px', 'auto', true, '', okbutton,
            '', true);

        } else {
          return true;
        }
      }

    }
    return false;
  }

  verifyMerchantId(merchantId:string){
    // return this.ls.verifyMerchantId({ 'merchantId': merchantId });
    return this.ls.verifyMerchantId({ 'email': merchantId });
  }

  UnlockWithAzureADB2CToken(email: string, token: string, merchantId: string){
    return this.ls.unlockWithAzureADB2CToken({'email': email, 'token': token, 'merchantId': merchantId});
  }


  public unlockLoginOrRestaurant(data, fromIframe = false) {
    const sessionType = Utilities.getSessionStorageType()
    if (data !== null) {
      if (data && (data.State !== 0 || !data.Payload || !data.Payload.IsSuccessful)) {
        this.router.navigate(['./login']);
        sessionStorage.removeItem(`MerchantKey${sessionType}`);
        const popUpMessage = [{
          confirmationMessage: `${this.ts.instant('loginFailMessage')}`,
          dialogTitle: this.ts.instant('authFailed'),
          showAlert: true
        }];
        let okbutton = this.ts.instant('ok');
        const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
          popUpMessage, popUpMessage[0].dialogTitle);
        this.openCustomPopup(componentDetails, ComponentTypes.login, '450px', 'auto', true, '', okbutton,
          '', true);
      } else if (data && data.State === 0) {
        let unlockRestaurantData: AuthenticationResultDTO = {} as AuthenticationResultDTO;
        data = unlockRestaurantData = data.Payload;
        this.as.unlockRestaurantData = unlockRestaurantData;
        if (unlockRestaurantData.ClientUid) {
          sessionStorage.setItem(`ClientUid${sessionType}`, unlockRestaurantData.ClientUid);
        }
        sessionStorage.setItem(`MerchantKey${sessionType}`, unlockRestaurantData.MerchantKey);
        sessionStorage.setItem(`IsGlobalAdmin${sessionType}`, unlockRestaurantData.IsGlobalAdmin.toString());
      }
      //this.loginConfig[0].options = [];
      let token = sessionStorage.getItem(`merchantPortalRedirectToken${sessionType}`);
      if (token && token != 'null') {

        if (data && data.RestaurantsAvailableForLogin && data.RestaurantsAvailableForLogin.length > 0) {
          if (fromIframe && !sessionStorage.getItem(`restaurantId${sessionType}`)) {
            sessionStorage.setItem(`restaurantId${sessionType}`, data.RestaurantsAvailableForLogin[0].Id);
          }
          this.loginViaToken(token);
        }
      }
      else {
        this.propListUpdated.next(data);
      }
    } else {
      this.as.logout();
    }
  }


  setRestaurant(restaurant) {
    if (!restaurant.Id || !restaurant.Name) {
      return;
    }
    this.as.restaurantId = restaurant.Id;
    const sessionType = Utilities.getSessionStorageType()
    sessionStorage.setItem(`restaurantId${sessionType}`, restaurant.Id);
    sessionStorage.setItem(`restaurantName${sessionType}`, restaurant.Name);
    sessionStorage.setItem(`lastLoggedInRestaurantId${sessionType}`, restaurant.Id);
    this.as.restaurantName = restaurant.Name;
    this.as.menuHeader = restaurant.Name;
    this.errorMsg = '';
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
