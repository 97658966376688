<div class='header_title'>
    <app-dialog-header [title]="header" (closeDialog)="cancel($event)"></app-dialog-header>
  </div>
  <div class='tier-wrapper newVersionWrapper' [formGroup]='tierLevelForm'>
      <div class="setup">
        <ag-textbox class="ag_display--block" [config]="tierCodeConfig"></ag-textbox>
        <ag-textbox class="ag_display--block" [config]="tierNameConfig"></ag-textbox>
        <ag-textbox class="ag_display--block" [config]="descriptionConfig"></ag-textbox>
        <ag-dropdown class='dropdown' formControlName='discountType' [placeholder]='captions.discountType'
        [source]="discountTypeInput" required>
         </ag-dropdown>
      </div>
    <mat-dialog-actions class="dialog-container__actions pt-3">
        <app-button [buttontype]='saveButton' [attr.automationId]="'Btn_CreateTier_save'" (valueChange)='save($event)'></app-button>
        <app-button [buttontype]='cancelButton' [attr.automationId]="'Btn_CreateTier_cancelBtn'" (valueChange)='cancel($event)'></app-button>
    </mat-dialog-actions>
</div>