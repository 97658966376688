import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { Product } from '../globalsContant'; 

@Component({
  selector: 'app-celopay-payment-detail',
  templateUrl: './celopay-payment-detail.component.html',
  styleUrls: ['./celopay-payment-detail.component.scss']
})
export class CelopayPaymentDetailComponent implements OnInit {

  iframeSrc: string;
  IfrCameLoaded: boolean = false;
  captions: any;
  productName:string;
  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _localization: RetailLocalization,
    private utils: CommonUtilities,) { }

  ngOnInit(): void {
    this.captions = this._localization.captions.shop;
    let productId = parseInt(this._localization.GetPropertyInfo('ProductId'));
    switch (productId) {

      case Product.SPA:
        this.productName = "Spa"
        break;
      case Product.PMS:
        this.productName = "pms"
        break;
      case Product.GOLF:
        this.productName = "Golf"
        break;
      case Product.RETAIL:
        this.productName = "Retail"
        break;
      case Product.SNC:
        this.productName = "snc"
        break;
    }
    let style = `${window.location.origin}/${this.productName}/CeloPay.css`;
    this.iframeSrc = this.data?.iFrameUrl.replace('StyleURL', style);
    this.utils.ToggleLoader(true);
  }

  onIframeLoad(): void {
    this.utils.ToggleLoader(false);
  }
  closeDialog(params?) {
    this.dialogRef.close(params);
  }
  validateSave() {
    return true;
  }
  promptUserForUnsavedChanges() {
    this.closeDialog();
  }

}
