<div class="newVersionWrapper retail-transaction-wrapper list-container h-100">
  <!--Start  Select Dropdropdown Container div -->
  <div class="select-container-bg" *ngIf="isOpenTrasactionList && PropertyInfo.UseRetailInterface">
    <div class="select-container select-class">

      <div class="d-inline-block float-right buttonSection actionitems" id ="actionitems">
        <button [attr.automationId]="'Btn_retailTransactions_syncupText'" class=" body-bgcolor createNewBtn whitecolor mr-2 text-capital"
          [ngClass]="disbleSyncUpBtn ? 'button_invalid' : 'button_valid'" mat-raised-button
          (click)="CloseOpenTransaction()">{{syncupText}}</button>
      </div>
    </div>
  </div>
  <div class="tableSection " [ngClass] =  "isOpenTrasactionList ? 'openTransactionTableSection' : ''" *ngIf="(this.serviceId != 8 && this.serviceId !=  3) || this.serviceId ==  3">
    <ng-container *ngIf="tableoptions">
      <app-shop-table [attr.automationId]="'Tbl_retailTransactions_retailTransactions'" [options]="tableoptions" [showOptionsArray]="displayOptions" (editEvt)="EditRecords($event)"
        (InActiveTherapistEvt)="showInactiveTherapist($event)" (afterEditClose)="afterEditPopupClose($event)"
        (dragDropEvt)="dragDrop($event)" (deleteEvt)="DeleteConfirm($event)" (OutletChanged)="OutletChangedEvt($event)"
        (dateEvtEmitter)="reprintDateRangeChange($event)"
        (advancedSearch)="onAdvancedSearch($event)"
        (advancedSearchClose)="onAdvancedSearchClose($event)"
        [isAdvancedSearchLoading]="isAdvancedSearchLoading"  [fromScreen]="serviceId"
        [isPrintGiftReceiptEnabled]="isGiftReceiptEnabled">
      </app-shop-table>
    </ng-container>
    <ng-container *ngIf="!tableoptions">
      <div class="d-flex h-100 align-items-center">
        <div id="cover-spin-retail">
          <div class="customspinnerimg">
            <img src="./assets/images/agil_favicon.ico" style="width: 100%;">
          </div>
          <div id="default-message">{{loaderCaptions.lbl_processing}}</div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="MPC-Section" *ngIf="this.serviceId  == 8">
    <!-- modified posted Commission -->
    <section class="modify-commission h-100">
      <form name="modify-commission-form" [formGroup]="FormGrp" class="h-100" autocomplete="off">

        <div class="result-wrapper h-100">
          <section class="filter mt-0">
            <app-aside-filter-component [automationId]="'retailTransactions'" [Categories]="Categories" (resetEventEmitter)="filterReset($event)"
              (filterEmitter)="filterChange($event)" [dateDefaultValue]="this.filterDateDefault"
              [dateMaxValue]="this.filterDateDefault" [dateSelection]="true"
              (dateChangedEmitter)="dateChange($event.value)"></app-aside-filter-component>
          </section>
          <section class="result-grid w-100">
            <div class="user-search">
              <mat-form-field class="pr-3 d-inline-block spa-searchbox" [floatLabel]="floatLabelNever">
                <!--  for placeholder -->
                <input [attr.automationId]="'Txt_retailTransactions_search'" type="text" class="LW14" placeholder="{{captions.SearchByTicketNumber}}" matInput
                  formControlName="searchtext" (input)="onChangesearchtext()" autocomplete="off">
                <i [attr.automationId]="'Icn_retailTransactions_search'"  aria-hidden="true" [ngClass]="!FormGrp.get('searchtext').value ? 'icon-Search' : 'icon-Cancel'" (click)="
                  FormGrp.controls['searchtext'].setValue('');onChangesearchtext();"></i>
              </mat-form-field>
            </div>
            <ng-container *ngIf="tableoptions_New">
              <app-shop-table [attr.automationId]="'Tbl_retailTransactions_retailTransactionsNew'" [showOptionsArray]="displayOptions" [options]="tableoptions_New"
                (dateEvtEmitter)="dateChangeEvent($event)" (LBLClickEvtEmitter)="gotoRecords($event)"></app-shop-table>
            </ng-container>
            <ng-container *ngIf="!tableoptions_New">
              <div class="d-flex h-100 align-items-center">
                <div id="cover-spin-retail">
                  <div class="customspinnerimg">
                    <img src="./assets/images/agil_favicon.ico" style="width: 100%;">
                  </div>
                  <div id="default-message">{{loaderCaptions.lbl_processing}}</div>
                </div>
              </div>
            </ng-container>
          </section>
        </div>
      </form>
    </section>
  </div>
</div>
