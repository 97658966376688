<form autocomplete="off" name="General" [formGroup]="inventoryFormGroup" class="h-100 inventoryContainer">
	<div class="invContent">
	  <a href="javascript:void(0);" [attr.automationId]="'Lbl_inventory_assignVendors'" *ngIf="!useRetailInterface && !isSNC" class="ml-4 mr-4 d-block LW14 text-color layout" (click)="assignVendors($event)">{{captions.ViewAssignVendor}}</a>
		<ng-scrollbar class="boxlayout"  >
				<div [ngClass]="isEdit && IsViewOnly ? 'disableItemSetup' : ''" (click)="stopdefaultClick($event)" (keydown)="stopdefaultClick($event)">
			<div formArrayName="inventoryFormArr" class="outletDetails" *ngIf="selectedOutletMgrArr.length > 0">
				<div [formGroupName]="i" *ngFor="let outlet of inventoryFormGroup.get('inventoryFormArr')['controls'];let i=index">
					<div class="outletDescription">
						<div class="outletName d-flex LW14">
							<div class="outMangName LW16 text-ellipsis" [matTooltip]="selectedOutletMgrArr[i]?.name | uppercase" *ngIf="this.functionalities['ShowOutletLabelOnRetailItemCreationInventoryTab'] || false" >
								{{selectedOutletMgrArr[i]?.name | uppercase}}
							</div>
							<div *ngIf="!eatecEnabled">
								<span class="d-flex quantity">{{this.captions.QuantityonHand}}</span>
								<span>{{selectedOutletMgrArr[i]?.quantity}}</span>
							</div>
							<div>
								<span class="d-flex quantity">{{this.captions.MTDQty}}</span>
								<span>{{selectedOutletMgrArr[i]?.mtd}}</span>
							</div>
							<div>
								<span class="d-flex quantity">{{this.captions.YTDQty}}</span>
								<span>{{selectedOutletMgrArr[i]?.ytd}}</span>
							</div>
						</div>
						<div class="editOutlet">
							<div class="d-flex LW14">
								<mat-form-field [floatLabel]="floatLabel">
									<input [attr.automationId]="'Txt_inventory_UpdatedQuantity'" inputtype="nonnegative, nodecimal" [min]="0" maxlength="5" matInput formControlName="UpdatedQuantity" [placeholder]="this.captions.UpdatedQuantity" (focus)="SetTempQuantity(outlet)" (input)="UpdatedQuantityChanged($event,i,outlet,'updatedQuantity');CheckForQUpdate(outlet)" [readonly]="IsViewOnly">
								</mat-form-field>
								<mat-form-field [floatLabel]="floatLabel">
									<input [attr.automationId]="'Txt_inventory_ItemPar'" inputtype="nonnegative, nodecimal" formControlName="ItemPar" maxlength="5" matInput [placeholder]="this.captions.ItemPar" (change)="inventoryTabChanged(i,outlet,'itemPar')" [readonly]="IsViewOnly">
								</mat-form-field>
								<mat-form-field [floatLabel]="floatLabel" *ngIf="showUnitCostField(i)" >
									<input [attr.automationId]="'Txt_inventory_UnitCost'" type="text" RetailCurrencyFormatter formControlName="UnitCost" matInput [placeholder]="this.captions.UnitCost"
									 (change)="inventoryTabChanged(i,outlet,'unitCost')" [readonly]="IsViewOnly" >
								</mat-form-field>
								<mat-form-field [floatLabel]="floatLabel" *ngIf="eatecEnabled" >
									<input  [attr.automationId]="'Txt_inventory_WeightedAverageCost'" type="text" RetailCurrencyFormatter formControlName="WeightedAverageCost" matInput [placeholder]="this.captions.weightedAverageCost"  [disabled] = "eatecEnabled" >
								</mat-form-field>
							</div>
							<div class="reason">
								<mat-form-field class="LW14 reasonWidth" [floatLabel]="floatLabel">
									<input [attr.automationId]="'Txt_inventory_ReasonforInventoryAdjustment'" type="text" #ReasonforInventoryAdjustment [required]="outlet['controls'].UpdatedQuantity.touched && outlet['controls'].ReasonforInventoryAdjustment.hasError('required')" maxlength="250" formControlName="ReasonforInventoryAdjustment" matInput [placeholder]="this.captions.ReasonforInventoryAdjustment" (input)="inventoryTabChanged(i,outlet,'reason')"  [readonly]="IsViewOnly">
									<mat-error class="" *ngIf="outlet['controls'].ReasonforInventoryAdjustment.invalid">{{captions.MissingReasonforInventoryAdjustment}}</mat-error>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
			</div>
				</div>
		</ng-scrollbar>
	</div>
	<mat-dialog-actions class="float-right actionButtons pr-4">
		<button [attr.automationId]="'Btn_inventory_cancel'" class="" mat-button type="button" (click)="close()">{{this.localization.captions.common.CANCEL}}</button>
		<button [attr.automationId]="'Btn_inventory_previous'" class="" mat-button matStepperPrevious type="button" class="LW14 text_capitalize  body-bordercolor" >{{this.localization.captions.common.PREVIOUS}}</button>
		<button [attr.automationId]="'Btn_inventory_next'"  class="" mat-button matStepperNext type="button"  color="primary"
		[ngClass]="!inventoryFormGroup.hasError('required') && inventoryFormGroup.valid ? 'button_valid' : ' button_invalid'"
		*ngIf="retailService.GeneralFormGrp.controls['commissionableitem'].value || retailService.otherInfoFormGrp.controls['groupKey'].value ||
		retailService.otherInfoFormGrp.controls['multipack'].value || (functionalities['ShowRetailSetupRoomTypesAssignmentTab'] || false) || (retailService.GeneralFormGrp.controls['itemcatradiogrp'].value == 14)"
		 class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.NEXT}}</button>

		 <button [attr.automationId]="'Btn_inventory_save'" mat-button type="button" *ngIf="!isEdit && !(retailService.GeneralFormGrp.controls['commissionableitem'].value|| retailService.otherInfoFormGrp.controls['groupKey'].value ||
		 retailService.otherInfoFormGrp.controls['multipack'].value || (functionalities['ShowRetailSetupRoomTypesAssignmentTab'] || false) || (retailService.GeneralFormGrp.controls['itemcatradiogrp'].value == 14))" [appDisableDoubleClick]="throttleTime" (throttleClick)="save()" color="primary"
            [ngClass]="inventoryFormGroup.valid ? 'button_valid' : ' button_invalid'"
            class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.SAVE}}</button>
        <button [attr.automationId]="'Btn_inventory_update'" mat-button type="button" *ngIf="isEdit && !(retailService.GeneralFormGrp.controls['commissionableitem'].value || retailService.otherInfoFormGrp.controls['groupKey'].value ||
		retailService.otherInfoFormGrp.controls['multipack'].value || (functionalities['ShowRetailSetupRoomTypesAssignmentTab'] || false) || (retailService.GeneralFormGrp.controls['itemcatradiogrp'].value == 14))" [appDisableDoubleClick]="throttleTime" (throttleClick)="save()" color="primary"
            [ngClass]="(inventoryFormGroup.valid && (parentForm.get('generalFormGroup').dirty || parentForm.get('otherInfoFormGroup').dirty || parentForm.get('inventoryFormGroup')?.dirty
			|| parentForm.get('multipackFormGroup')?.dirty || parentForm.get('packagedItemFormGroup')?.dirty ||  this.retailService?.VendorInfoChanges)) ? 'button_valid' : ' button_invalid'"
            class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.Update}}</button>
	</mat-dialog-actions>
</form>
