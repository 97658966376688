<ng-scrollbar track="all">
  <div class="class-detail">

    <div class="class-header">
      <div class="row m-0">
        <div class="col-lg-12 col-md-12 d-flex">
          <div class="header-info" *ngIf="classDetail">
            <div class="card-body pt-1">
              <div class="content-view">
                <div class="fs-16" [category-indicator]="classDetail.CategoryId"> {{classDetail.Name}}</div>
                <div class="class-detail__location text-muted data-content-view" *ngIf="staffs && staffs.length">
                  <div *ngFor="let staff of staffs  | slice:0:(showAll.staff ? staffs.length : 1 )"
                    [title]="staff.fullName">
                    <span class="trainer-name text-ellipsis">
                      {{staff.fullName}}
                    </span>
                  </div>
                  <div class="class-detail__slots-show-action" *ngIf="staffs && staffs.length > 1"
                    [ngbPopover]="staffPopover" placement="auto" [autoClose]="'outside'" container="body" popoverClass="activities-popover">
                    +{{staffs.length-1}}
                  </div>
                </div>
                <div class="class-detail__location text-muted">
                  <div *ngFor="let location of locations  | slice:0:(showAll.location ? locations.length : 1 )"
                    [title]="location">
                    <span class="icon icon-placeholder"></span>
                    {{location}}
                  </div>
                  <div class="class-detail__slots-show-action text-muted ml-2" *ngIf="locations && locations.length > 1"
                    [ngbPopover]="locationPopover" placement="auto" [autoClose]="'outside'" container="body" popoverClass="activities-popover">
                    +{{locations.length-1}}
                  </div>
                </div>
                <div *ngIf="LocationGroupDetails" class="class-detail__location text-muted"
                  matTooltip="{{'Asset' | translate}}">
                  <div class="inventory"><span class="icon  icon-rntl-inventory mr-1"></span> {{LocationGroupDetails}}
                  </div>
                </div>
                <div class="class-detail__cancellation" *ngIf="isSessionCancelled">
                  {{'sessionCancelled' | translate}}
                </div>
              </div>
              <div class="location-info">
                <div class="text-muted"> <span class="icon-Calender"></span> {{classDetailDate | formatSettingsDate:
                  cs.settings.value.General.DateFormat}} | {{ classDetail.startTime | localizedDate : 'LT' }}
                  - {{ classDetail.endTime | localizedDate : 'LT' }}</div>
              </div>
            </div>
          </div>
          <div class="class-detail__filter">
            <div class="class-detail__search">
              <dynamic-form style="width:100%" [config]="searchconfig" #searchConfigForm>
              </dynamic-form>
              <div class="display_filter">
                <a [ngbPopover]="filterPopover" placement="auto" [autoClose]="'outside'" container="body" popoverClass="activities-popover">
                  <span class="icon-Filter"><span class="path1"></span><span class="path2"></span><span
                      class="path3"></span></span>
                </a>
                <ng-template #filterPopover>
                  <app-filter-content [displayOptions]="displayOptions" [isMessageAvailable]="true"
                    (optionSelection)="filterReservations($event)"></app-filter-content>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #descriptionPopover>
        <div class="description">{{classDetail.Description || ('No Description' | translate)}}</div>
      </ng-template>
    </div>
    <div class="class-detail__subheader">
      <div class="class-detail__seat-actions">
 
        <ng-container *ngIf="classDetail?.ClassType == classType.Session">
          <activities-app-button *ngFor="let control of buttonControls | slice:0:1" [buttontype]="control"
            (valueChange)="changeEvent(control.name)">
          </activities-app-button>

          <div class="d-flex">
          <button mat-button class=" button-group d-flex" (click)="changeEvent(buttonControls[1].name)">
            <span> {{(buttonControls | slice:1)[0]?.label | translate}}</span>
           </button>
          <button [matMenuTriggerFor]="menuForActivityAction" class=" button-group button-arrow d-flex justify-content-center align-items-center" >          
            <span class="icon-down-arrow" style="font-size:11px"></span></button>
          </div>

          <mat-menu #menuForActivityAction="matMenu">
            <ng-container *ngFor="let control of buttonControls | slice:1">
              <button mat-menu-item (click)="changeEvent(control.name)">{{control.label | translate}}</button>
            </ng-container>
            <!-- <activities-app-button *ngFor="let control of buttonControls | slice:1" [buttontype]="control"
            (valueChange)="changeEvent(control.name)">
          </activities-app-button> -->
          </mat-menu>
        </ng-container>
        
        <ng-container *ngIf="classDetail?.ClassType == classType.Class">
          <activities-app-button *ngFor="let control of buttonControls" [buttontype]="control"
            (valueChange)="changeEvent(control.name)">
          </activities-app-button>
        </ng-container>

      </div>
    </div>
    <div class="booking-pannel">
      <div class="session-details">
        <div class="available-detail">

          <div class="availability-container">
            <div class="available-row"><span class="count"
                matTooltip="{{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.AvailableCapacityByTotalCapacity  ? ('AvailableToBook' | translate) : ('Booked' | translate)}}">
                {{ classDetail | sessionAvailability: 'numerator' }}</span><span class="avail-title">{{'TotalCapacity' |
                translate}}
                <span class="total-count" matTooltip="{{'TotalCapacity' | translate}}"> {{classDetail.available +
                  classDetail.booked}}
                  <span class="overbooked-text" *ngIf="classDetail.overbooked">({{'OverbookedBy' | translate}}
                    {{classDetail.overbooked}})</span>
                </span>
              </span>
            </div>
          </div>

        </div>
        <div class="notes-view">
          <div class="notes-container">
            <div class="notes-section">
              <ng-container *ngIf="publicQuickNote?.values?.length == 0">
                <div class="add-note"
                  (click)="!noActionRequired ? addHeadlinesForActivity(classDetail,'',publicQuickNote) : false">
                  <span class="headline-description ">
                    <i class="icon-public" matTooltip="{{'PublicQuickNote' | translate}}"></i>
                    {{'AddPublicQuickNote' | translate}}</span>
                  <span *ngIf="!noActionRequired" (click)="addHeadlinesForActivity(classDetail,'',publicQuickNote)"
                    class="icon-add-solid"></span>
                </div>
              </ng-container>
              <ng-container *ngIf="publicQuickNote?.values?.length > 0">
                <div class="headline-container" *ngFor="let data of publicQuickNote?.values">
                  <div class="headline-header">
                    <div class="headline-text">
                      <div class="headline-title">
                        <i class="icon-public" matTooltip="{{'PublicQuickNote' | translate}}"></i>
                        {{'PublicNote' | translate}}
                      </div>
                    </div>
                    <div class="headline-actions" *ngIf="!noActionRequired">
                      <i class="icon-ic_create_24px"
                        (click)="addHeadlinesForActivity(classDetail,data,publicQuickNote)"></i>
                      <i class="icon-Delete" (click)="deleteHeadlinesForActivity(classDetail,data,publicQuickNote)"></i>
                    </div>
                  </div>
                  <div class="headline-description pl-3">
                    <span *ngIf="data">
                      {{data.Description}}</span>
                  </div>
                </div>
              </ng-container>

            </div>
            <div class="notes-section">
              <ng-container *ngIf="privateQuickNote?.values?.length == 0">
                <div class="add-note"
                  (click)="!noActionRequired ? addHeadlinesForActivity(classDetail,'',privateQuickNote) : false">
                  <span class="headline-description ">
                    <i class="icon-public_off" matTooltip="{{'PrivateQuickNote' | translate}}"></i>
                    {{'AddPrivateQuickNote' | translate}}</span>
                  <span *ngIf="!noActionRequired" (click)="addHeadlinesForActivity(classDetail,'',privateQuickNote)"
                    class="icon-add-solid"></span>
                </div>
              </ng-container>
              <ng-container *ngIf="privateQuickNote?.values?.length > 0">
                <div class="headline-container" *ngFor="let data of privateQuickNote?.values">
                  <div class="headline-header">
                    <div class="headline-text">
                      <div class="headline-title">
                        <i class="icon-public_off" matTooltip="{{'PrivateQuickNote' | translate}}"></i>
                        {{'PrivateNote' | translate}}
                      </div>
                    </div>
                    <div class="headline-actions" *ngIf="!noActionRequired">
                      <i class="icon-ic_create_24px"
                        (click)="addHeadlinesForActivity(classDetail,data,privateQuickNote)"></i>
                      <i class="icon-Delete"
                        (click)="deleteHeadlinesForActivity(classDetail,data,privateQuickNote)"></i>
                    </div>
                  </div>
                  <div class="headline-description pl-3">
                    <span *ngIf="data">
                      {{data.Description}}</span>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="notes-section block-session pl-1" *ngIf="blockedSession && blockedSessionDetails">
              <div class="headline-header">
                <div class="headline-text">
                  <div class="headline-description d-flex">
                    <i class="icon-block_1" matTooltip="{{'blocks' | translate}}"></i>
                    <div class="class-detail__blocked-details" *ngIf="blockedSession && blockedSessionDetails" style="">
                      <div class="class-detail__blocked-data"><span>{{ 'blockedBy' | translate }} :
                        </span><span>{{blockedSessionDetails?.blockedBy}}</span></div>
                      <div class="class-detail__blocked-data"><span>{{'blockedUntil' | translate}} : </span><span>
                          {{blockedSessionDetails?.blockedUntil | localizedDate:'LT'}}</span></div>
                      <div class="class-detail__blocked-data"><span>{{'blockingreason' | translate}} : </span><span>
                          {{blockedSessionDetails?.blockingReason}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="notes-section cancel-session pl-1" *ngIf="isSessionCancelled && cancellationDetails">
              <div class="headline-header">
                <div class="headline-text">
                  <div class="headline-description d-flex">
                    <span class="icon-booking-cancel-icon" matTooltip="{{'Cancelled' | translate}}"><span
                        class="path1"></span><span class="path2"></span></span>
                    <div class="class-detail__blocked-details" *ngIf="cancellationDetails" style="">
                      <div class="class-detail__blocked-data"><span>{{ 'cancelledBy' | translate }} :
                        </span><span>{{cancellationDetails?.CancelledBy}}</span></div>
                      <div class="class-detail__blocked-data"><span>{{'cancelledUntil' | translate}} : </span><span>
                          {{cancellationDetails?.CancelledUntil | localizedDate:'LT'}}</span></div>
                      <div class="class-detail__blocked-data"><span>{{'cancelledReason' | translate}} : </span><span>
                          {{cancellationDetails?.CancelledReason}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="addon-notes" *ngIf="addonForActivity.length > 0 && showAddOnIcon">
          <div class="addon-container">
            <div class="addon-title"><i class="icon icon-addons"></i> {{'addons' | translate}}</div>
            <div class="addon-description">
              <div class="reservation-notes__notes-details" *ngFor="let addOn of addonForActivity">
                <div>
                  <span>{{addOn.AddonId | getAddonName : _settings}}<span style="color:red" *ngIf="addOn.IsMandatory">
                      *</span></span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div *ngIf="addonsForBookingPerLesson?.length" class="shared-resources">
          <span class="icon-No-sahre2"><span class="path1"></span><span class="path2"></span><span
              class="path3"></span></span>
          <div class="shared-list">
            <div
              *ngFor="let addOn of addonsForBookingPerLesson  | slice:0:(showAddOns.addOn ? addonsForBookingPerLesson.length : 1 )">
              <div class="seat-label" [ngStyle]="{'color' : addOn.CategoryColor}">{{addOn.AddonId | getAddonName :
                _settings}}
              </div>
            </div>
          </div>
        </div>
        <div class="class-detail__slots-show-action"
          *ngIf="addonsForBookingPerLesson && addonsForBookingPerLesson.length > 1"
          (click)="showAddOns.addOn = !showAddOns.addOn">
          {{showAddOns.addOn ? ('ShowLess' | translate) : '+'+(addonsForBookingPerLesson.length-1)+'
          '+('accordionmoretext' | translate)}}
        </div>
      </div>
      <div class="booking-details">
        <mat-tab-group
          [selectedIndex]="attendees && attendees.length > 0 ? 0 : classDetail?.IsForStandbyReservations && (standbyAttendees && standbyAttendees.length > 0) ? 1 : 0">
          <mat-tab [label]="'Reservations' | translate">
            <ng-container
              *ngIf="(attendees | attendeesFilter : searchAttendeesText | filterByState : optionsForFilter) as result">
              <div class="attendees">
                <table class="table">
                  <thead class="user-card user-card__data-header">
                    <tr>
                      <th>
                        <div class="user-sno">
                          <!-- <mat-checkbox></mat-checkbox> -->
                        </div>
                      </th>
                      <th>
                        <div class="user-card__content-view pointer noSelect"
                          (click)="sortClick(sortOptions.guestName)">
                          {{'GuestDetailText' | translate}}
                          <i class="sortIcon show"
                            [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.guestName && sortData.sortOrder==='asc', 'icon-Sort-Down': sortData.sortBy===sortOptions.guestName && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.guestName}"></i>
                        </div>
                      </th>

                      <th>
                        <div class="pointer noSelect">
                          {{'StayInfo' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="pointer noSelect">
                          {{'bookingAndConfirmation' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="pointer noSelect" (click)="sortClick(sortOptions.size)">
                          {{'sizeText' | translate}}
                          <i class="sortIcon show"
                            [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.size && sortData.sortOrder==='asc', 'icon-Sort-Down': sortData.sortBy===sortOptions.size && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.size}"></i>
                        </div>
                      </th>
                      <th>
                        <div class="user-card__payment">
                          {{'paymentText' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="user-card__status-col pointer noSelect" (click)="sortClick(sortOptions.status)">
                          {{'Status' | translate}}
                          <i class="sortIcon show"
                            [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.status && sortData.sortOrder==='asc', 'icon-Sort-Down': sortData.sortBy===sortOptions.status && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.status}"></i>
                        </div>
                      </th>
                      <th class="table-action">
                        <div class="user-card__actions">
                          {{'actionText' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="user-card__delete">

                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="user-card"
                      *ngFor="let attendee of result | sortData: sortData.sortBy: sortData.sortOrder">
                      <td>
                        <div class="user-sno">
                          <!-- <mat-checkbox [checked]="attendee.selected == true"></mat-checkbox> -->
                          <i class="icon-VIP" [ngClass]="{'VIP-selected':attendee?.Contact.IsVip}"></i>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__content-view"
                          (click)="attendee.State != PartyState.Cancelled && attendee.State != PartyState.Left && !noActionRequired  ? editParty(attendee) : false">
                          <h3 class="user-card__name text-ellipsis" *ngIf="attendee.Contact">
                            {{attendee?.Contact?.FirstName}} {{attendee?.Contact?.LastName || ''}}
                          </h3>
                          <p class="user-card__mobile text-ellipsis">
                            {{attendee?.Contact?.PhoneNumber | phoneNumberFormatter: attendee?.Contact?.CountryId}}</p>

                            <p *ngIf="attendee?.Contact.TrackMembershipNumber && attendee?.Contact.TrackMembershipNumber != '' " class="user-card__member-info mb-0 seat-body-text"
                            [autoClose]="'outside'" container="body" placement="auto"
                           [ngbPopover]="memberInfoPopover"  #memberInfo="ngbPopover" triggers="manual"
                           (click)="fetchMemberDetails(attendee?.Contact.TrackMembershipNumber, $event, memberInfo)" popoverClass="activities-popover">
                           {{party?.Contact?.IsMemberActive ? ('memberText' | translate) : (('Inactive' | translate) +' '+ ('memberText'| translate)) }}</p>
                        </div>
                      </td>

                      <td>
                        <div *ngIf="!attendee.RoomNumber  || attendee.RoomNumber.length == 0"> {{'NA' | translate}}
                        </div>
                        <div *ngIf="attendee.RoomNumber  && attendee.RoomNumber.length > 0" style="cursor: pointer;"
                          [autoClose]="'outside'" container="body" [ngbPopover]="packageInformation" placement="bottom"
                          (click)="dashboardFunctions.openPackage(attendee)" popoverClass="activities-popover">
                          <p class="mb-0" *ngIf="attendee.CommonPackageInfo == undefined"><i class="icon-reserve_v1crs" [matTooltip]="'RoomNumber' | translate" ></i> {{
                            attendee.RoomNumber }}</p>
                          <u *ngIf="attendee.CommonPackageInfo && attendee.CommonPackageInfo.length > 5">
                            <i class="icon-reserve_v1crs" [matTooltip]="'RoomNumber' | translate" ></i> {{ attendee.RoomNumber }}
                          </u>
                        </div>
                      </td>
                      <td>
                        <div class="reference-number">
                          <div class="conirmation">
                            {{attendee?.ConfirmationCode}}
                          </div>
                          <div class="reference">
                            {{attendee?.BookingReferenceId}}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__covers">
                          <span *ngIf="attendee.Size" [class.overbooked-bg]="attendee?.OverBooked"
                            [matTooltip]="attendee?.OverBooked ? (('OverbookedBy' | translate) + ' ' + attendee?.OverBooked) : attendee.Size"><span
                              class="icon icon-add-contact"></span>
                            {{attendee.Size}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__payment">
                          <ng-container *ngIf="(attendee | partyPaymentState) as paymentState">
                            <span *ngIf="paymentState!=='NA'" class="user-card__payment--label payment-{{paymentState}}"
                              container="body" placement="auto" [autoClose]="'outside'"
                              [ngbPopover]="financialStatusPopover" (click)="financialDetails(attendee)" popoverClass="activities-popover">
                              {{ paymentState | translate }}
                            </span>
                            <span *ngIf="paymentState=='NA'">
                              {{'NA' | translate}}
                            </span>
                          </ng-container>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__status-col">
                          <div class="user-card__status user-card__status--{{attendee.State}}">
                            {{'Status_'+PartyState[attendee.State]
                            | translate}}</div>
                        </div>
                      </td>
                      <td class="table-action">
                        <div class="user-card__actions">
                          <button *ngIf="attendee.State == PartyState.Pending && !attendee.checkInWithDues" mat-button
                            class="btn btn--checkin" (click)="checkInClick(attendee)"
                            [disabled]="noActionRequired || isSessionCancelled">
                            {{'CheckIn' | translate}}
                          </button>
                          <button *ngIf="attendee.checkInWithDues && attendee.State == PartyState.Pending" mat-button
                            [matMenuTriggerFor]="menu" class="btn btn--checkin"
                            [disabled]="noActionRequired || isSessionCancelled"> {{'CheckIn' | translate}} <span
                              class="icon-down-arrow" style="font-size:10px"></span></button>
                          <mat-menu #menu="matMenu"
                            [hidden]="!(attendee.checkInWithDues && attendee.State == PartyState.Pending)">
                            <button mat-menu-item (click)="checkInClick(attendee)">{{'CheckIn' | translate}}</button>
                            <button mat-menu-item (click)="checkInClick(attendee , true)">{{'checkInWithDues' |
                              translate}}</button>
                          </mat-menu>
                          <button *ngIf="attendee.State == PartyState.Seated " mat-button
                            [matMenuTriggerFor]="menuForCheckOut" class="btn btn--checkin"
                            [disabled]="noActionRequired"> {{'CheckOut' | translate}} <span class="icon-down-arrow"
                              style="font-size:10px"></span></button>
                          <mat-menu #menuForCheckOut="matMenu">
                            <button mat-menu-item (click)="undoCheckIn(attendee)">{{'Unseat' | translate}}</button>
                            <button mat-menu-item (click)="checkOutClick(attendee)">{{'CheckOut' | translate}}</button>
                          </mat-menu>
                          
                          <!-- collectDues -->
                          <button *ngIf="attendee.State == PartyState.Left && attendee.PrepaymentState == PartyPrepaymentState.PaymentDuePending" 
                                  mat-button class="btn btn--checkin" (click)="collectDues(attendee)">
                            {{'CollectDues' | translate}}
                          </button>
                          
                        </div>
                      </td>
                      <td>
                        <div class="user-card__cancel">
                          <div class="action-icons">
                            <div class="action-icon-list"
                              [ngClass]="{'exclusive-booking' : attendee.IsExclusiveBooking}">
                              <span class="icon icon-rntl-inventory mr-0"
                                [ngClass]="{'icon-disabled' : !attendee.isLocationGroupMapped}"
                                matTooltip="{{'Asset' | translate}}" [autoClose]="'outside'" container="body"
                                placement="auto" triggers="manual" #locationGroupPopOver="ngbPopover" container="body"
                                [ngbPopover]="locationFieldsContainer"
                                (click)="(partyService.isChatPopoverOpened || !attendee.isLocationGroupMapped) ? return : locationGroupPopOver.open();locationListUpdate(attendee)" popoverClass="activities-popover">
                              </span>
                            </div>
                            <div class="action-icon-list">
                              <div
                                [ngClass]="{'icon-disabled' : !(attendee.PackageName !== null && attendee.PackageName !== undefined)}"
                                [matTooltipDisabled]="(attendee.PackageName !== null && attendee.PackageName !== undefined)"
                                matTooltip="{{'packages' | translate}}">
                                <span class="icon-offer-redemption"
                                  [matTooltipDisabled]="!(attendee.PackageName !== null && attendee.PackageName !== undefined)"
                                  matTooltip="{{attendee.PackageName}}"></span>
                              </div>
                            </div>
                            <div>

                            </div>
                            <div class="action-icon-list ">
                              <span class="icon icon-note1"
                                [ngClass]="{'icon-disabled' : !(attendee?.Notes?.length > 0) && !(attendee.Contact.Notes?.length > 0)}"
                                matTooltip="{{'notes' | translate}}" [autoClose]="'outside'" container="body"
                                placement="auto" [ngbPopover]="reservationNotesPopOver" triggers="manual"
                                #reservationNotePopOver="ngbPopover"
                                (click)="partyService.isChatPopoverOpened || !(attendee?.Notes?.length > 0 || attendee.Contact.Notes?.length > 0) ? return : reservationNotePopOver.open();reservationNotes(attendee)" popoverClass="activities-popover"></span>
                            </div>
                            <div class="action-icon-list">
                              <div class="mt_5"
                                *ngIf="(attendee.Contact | partyGuestFieldMapping:  mappedGuestFields) as partyGuestFields"
                                matTooltip="{{'CustomGuestFields'| translate}}">
                                <div *ngIf="partyGuestFields?.length ; else additionalField">
                                  <span class="icon icon-Group-197 mr-0" [autoClose]="'outside'" container="body"
                                    placement="auto" triggers="manual" #customGuestFieldsPopover="ngbPopover"
                                    container="body" [ngbPopover]="customGuestFieldsContainer"
                                    (click)="partyService.isChatPopoverOpened || !(partyGuestFields?.length) ? return : customGuestFieldsPopover.open();setCustomGuestField(attendee.Contact)" popoverClass="activities-popover">
                                    <!-- <span class="class-detail__profile-icon icon-profile1"></span> -->
                                  </span>
                                </div>
                                <ng-template #additionalField><span
                                    class="icon icon-Group-197 icon-disabled "></span></ng-template>
                              </div>
                            </div>
                            <div class="action-icon-list">
                              <div
                                [ngClass]="{'icon-disabled' : !(attendee?.BookingContactAddonItems?.length > 0) , 'mt_5' : attendee?.BookingContactAddonItems?.length > 0 }"
                                matTooltip="{{'addons'| translate}}">
                                <span class="icon icon-addons mr-0" [autoClose]="'outside'" placement="auto"
                                  triggers="manual" #addOnForBookingPopover="ngbPopover" container="body"
                                  [ngbPopover]="addOnForBooking"
                                  (click)="partyService.isChatPopoverOpened || !(attendee?.BookingContactAddonItems?.length > 0) ? return : addOnForBookingPopover.open();updateAddOnDetails(attendee)" popoverClass="activities-popover">
                                  <!-- <span class="class-detail__profile-icon icon-profile1"></span> -->
                                </span>
                              </div>
                            </div>
                            <div class="action-icon-list w-40" *ngIf="!noActionRequired">
                              <chat-info-container class="user-card__data-align-content"
                                [party]="attendee" [popupCloseAction]="popupCloseAction"></chat-info-container>
                            </div>
                            <div class="action-icon-list">
                              <span class="icon-more user-card__data-align-content" mat-button
                                [matMenuTriggerData]="{attendee:attendee}"
                                [matMenuTriggerFor]="moreOptionsReservationTab"
                                [ngClass]="{'noPointerEvents': partyService.isChatPopoverOpened}"></span>
                            </div>
                          </div>

                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="result.length < 1" class="mt-4">
                      <td colspan="7">
                        <div class="no-attendees">
                          {{'NoAttendees' | translate}}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </ng-container>
          </mat-tab>
          <mat-tab [label]="'StandByList' | translate" *ngIf="classDetail?.IsForStandbyReservations || AllowStandBy">
            <ng-container
              *ngIf="(standbyAttendees | attendeesFilter : searchAttendeesText | filterByState : [PartyState.Pending , PartyState.Cancelled ] ) as result">
              <div class="attendees">
                <table class="table">
                  <thead class="user-card user-card__data-header">
                    <tr>
                      <th>
                        <div class="user-sno">
                          <!-- <mat-checkbox></mat-checkbox> -->
                        </div>
                      </th>
                      <th>
                        <div class="user-card__content-view pointer noSelect"
                          (click)="sortClick(sortOptions.guestName)">
                          {{'GuestDetailText' | translate}}
                          <i class="sortIcon show"
                            [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.guestName && sortData.sortOrder==='asc', 'icon-Sort-Down': sortData.sortBy===sortOptions.guestName && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.guestName}"></i>
                        </div>
                      </th>
                      <th>
                        <div class="pointer noSelect">
                          {{'bookingAndConfirmation' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="user-card__covers pointer noSelect" (click)="sortClick(sortOptions.size)">
                          {{'sizeText' | translate}}
                          <i class="sortIcon show"
                            [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.size && sortData.sortOrder==='asc', 'icon-Sort-Down': sortData.sortBy===sortOptions.size && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.size}"></i>
                        </div>
                      </th>
                      <th>
                        <div class="user-card__payment">
                          {{'paymentText' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="user-card__status-col pointer noSelect" (click)="sortClick(sortOptions.status)">
                          {{'Status' | translate}}
                          <i class="sortIcon show"
                            [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.status && sortData.sortOrder==='asc', 'icon-Sort-Down': sortData.sortBy===sortOptions.status && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.status}"></i>
                        </div>
                      </th>
                      <th class="standby-table-action">
                        <div class="user-card__standby-actions">
                          {{'actionText' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="user-card__delete">

                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="user-card"
                      *ngFor="let attendee of result | sortData: sortData.sortBy: sortData.sortOrder">
                      <td>
                        <div class="user-sno">
                          <!-- <mat-checkbox [checked]="attendee.selected == true"></mat-checkbox> -->
                          <i class="icon-VIP" [ngClass]="{'VIP-selected':attendee?.Contact.IsVip}"></i>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__content-view"
                          (click)="attendee.State == PartyState.Pending && !noActionRequired ?  editParty(attendee) : false">
                          <h3 class="user-card__name text-ellipsis" *ngIf="attendee.Contact">
                            {{attendee?.Contact?.FirstName}} {{attendee?.Contact?.LastName || ''}}
                          </h3>
                          <p class="user-card__mobile text-ellipsis">
                            {{attendee?.Contact?.PhoneNumber | phoneNumberFormatter: attendee?.Contact?.CountryId}}</p>
                            <p *ngIf="attendee?.Contact.TrackMembershipNumber && attendee?.Contact.TrackMembershipNumber != '' " class="user-card__member-info mb-0 seat-body-text"
                            [autoClose]="'outside'" container="body" placement="auto"
                           [ngbPopover]="memberInfoPopover"  #memberInfo="ngbPopover" triggers="manual"
                           (click)="fetchMemberDetails(attendee?.Contact.TrackMembershipNumber, $event, memberInfo)" popoverClass="activities-popover">
                           {{party?.Contact?.IsMemberActive ? ('memberText' | translate) : (('Inactive' | translate) +' '+ ('memberText'| translate)) }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="reference-number">
                          <div class="conirmation">
                            {{attendee?.ConfirmationCode}}
                          </div>
                          <div class="reference">
                            {{attendee?.BookingReferenceId}}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__covers">
                          <span *ngIf="attendee.Size"><span class="icon icon-add-contact"></span>
                            {{attendee.Size}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__payment">
                          <ng-container>
                            <span class="user-card__payment--label payment-{{attendee | partyPaymentState }}"
                              container="body" placement="auto" [autoClose]="'outside'"
                              [ngbPopover]="financialStatusPopover" (click)="financialDetails(attendee)" popoverClass="activities-popover">
                              {{attendee | partyPaymentState | translate }}
                            </span>
                          </ng-container>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__status-col">
                          <span class="user-card__status user-card__status--7"
                            *ngIf="attendee.State !== PartyState.Cancelled">{{'Standby'
                            | translate}}</span>
                          <span class="user-card__status user-card__status--4"
                            *ngIf="attendee.State == PartyState.Cancelled">{{'Cancelled'
                            | translate}}</span>
                        </div>
                      </td>
                      <td class="standby-table-action">
                        <div class="user-card__standby-actions">
                          <button mat-button class="btn btn--checkin" (click)="rejectClick(attendee)"
                            [disabled]="noActionRequired || attendee.State == PartyState.Cancelled">
                            {{ 'RejectText' | translate}}
                          </button>
                          <button *ngIf="!attendee?.isPast"
                            [disabled]="attendee?.isPast || noActionRequired || isSessionCancelled ||  attendee.State == PartyState.Cancelled"
                            mat-button class="btn btn--accept" (click)="confirmClick(attendee)">
                            {{'AcceptText' | translate}}
                          </button>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__cancel">
                          <div class="action-icons">
                            <div class="action-icon-list">
                              <div
                                [ngClass]="{'icon-disabled' : !(attendee.PackageName !== null && attendee.PackageName !== undefined)}"
                                [matTooltipDisabled]="(attendee.PackageName !== null && attendee.PackageName !== undefined)"
                                matTooltip="{{'packages' | translate}}">
                                <span class="icon-offer-redemption"
                                  [matTooltipDisabled]="!(attendee.PackageName !== null && attendee.PackageName !== undefined)"
                                  matTooltip="{{attendee.PackageName}}"></span>
                              </div>
                            </div>
                            <div class="action-icon-list">
                              <span class="icon icon-note1"
                                [ngClass]="{'icon-disabled' : !(attendee?.Notes?.length > 0) && !(attendee.Contact.Notes?.length > 0) }"
                                matTooltip="{{'notes' | translate}}" [autoClose]="'outside'" container="body"
                                placement="auto" [ngbPopover]="reservationNotesPopOver" triggers="manual"
                                #reservationNotePopOver="ngbPopover" 
                                (click)="partyService.isChatPopoverOpened || !(attendee?.Notes?.length > 0 || attendee.Contact.Notes?.length > 0) ? return : reservationNotePopOver.open();reservationNotes(attendee)" popoverClass="activities-popover" ></span>
                            </div>
                            <div class="action-icon-list">
                              <div
                                *ngIf="( mappedGuestFields && mappedGuestFields.length && attendee?.Contact?.ContactCustomFields?.length ) ; else additionalField "
                                matTooltip="{{'CustomGuestFields'| translate}}">
                                <span class="icon icon-Group-197 mr-0" [autoClose]="'outside'" container="body"
                                  placement="auto" triggers="manual" #customGuestFieldsPopover="ngbPopover"
                                  container="body" [ngbPopover]="customGuestFieldsContainer"
                                  (click)="partyService.isChatPopoverOpened ? return : customGuestFieldsPopover.open();setCustomGuestField(attendee.Contact)" popoverClass="activities-popover">
                                  <!-- <span class="class-detail__profile-icon icon-profile1"></span> -->
                                </span>
                              </div>
                              <ng-template #additionalField><span
                                  class="icon icon-Group-197 icon-disabled "></span></ng-template>
                            </div>
                            <div class="action-icon-list">
                              <div
                                [ngClass]="{'icon-disabled' : !(attendee?.BookingContactAddonItems?.length > 0) , 'mt_5' : attendee?.BookingContactAddonItems?.length > 0 }"
                                matTooltip="{{'addons'| translate}}">
                                <span class="icon icon-addons mr-0" [autoClose]="'outside'" container="body"
                                  placement="auto" triggers="manual" container="body" [ngbPopover]="addOnForBooking"
                                  (click)="partyService.isChatPopoverOpened ||  !(attendee?.BookingContactAddonItems?.length > 0) ? return : addOnForBookingPopover.open();updateAddOnDetails(attendee)"
                                  #addOnForBookingPopover="ngbPopover" popoverClass="activities-popover">
                                  <!-- <span class="class-detail__profile-icon icon-profile1"></span> -->
                                </span>
                              </div>
                            </div>
                            <div class="action-icon-list w-40" *ngIf="!noActionRequired">
                              <chat-info-container class="user-card__data-align-content"
                                [party]="attendee" [popupCloseAction]="popupCloseAction"></chat-info-container>
                            </div>
                            <div class="action-icon-list">
                              <span class="icon-more user-card__data-align-content" mat-button
                                [matMenuTriggerData]="{attendee:attendee}" [matMenuTriggerFor]="moreOptionsStandByTab"
                                [ngClass]="{'noPointerEvents': partyService.isChatPopoverOpened}"></span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="result.length < 1" class="mt-4">
                      <td colspan="7">
                        <div class="no-attendees">
                          {{'NoAttendees' | translate}}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-container>
          </mat-tab>
        </mat-tab-group>

      </div>
    </div>
  </div>
</ng-scrollbar>

<div>
  <ng-template #financialStatusPopover>
    <app-financial-status-popup [partyData]="financialPartyData"></app-financial-status-popup>
  </ng-template>
</div>
<!-- Reservation Notes popover -->
<div>
  <ng-template #reservationNotesPopOver>
    <div>
      <div class="parties-grid__scrollSize">
        <div class="reservation-notes" *ngIf="notesReservation.length">
          <div class="reservation-notes__header seat-popover-header">
            {{'reservationNotes' | translate}}
          </div>
          <div class="reservation-notes__content seat-popover-content">
            <div class="reservation-notes__notes-details seat-border-color"
              style="justify-content: space-between;gap:20px" *ngFor="let notes of notesReservation">
              <div>{{notes.Text}}</div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="guestNotesForParty.length">
        <div class="reservation-notes__header seat-popover-header">
          {{'guestNotes' | translate}}
        </div>
        <div class="reservation-notes__content seat-popover-content">
          <div class="reservation-notes__notes-details seat-border-color"
            style="justify-content: space-between;gap:20px" *ngFor="let guestNote of guestNotesForParty">
            <div>{{guestNote.Text}}</div>
          </div>
        </div>
      </ng-container>
      </div>
      </div>
  </ng-template>
  <ng-template #memberInfoPopover >
    <div>
      <div class="memberInfo-scrollSize">
        <div class="reservation-notes">
          <div class="reservation-notes__header seat-popover-header">
            {{'memberText' | translate}} {{'informationText' | translate}}
          </div>
          <!-- <div class="reservation-notes__content seat-popover-content">
            <div class="reservation-notes__notes-details seat-border-color"
              style="justify-content: space-between;gap:20px" *ngFor="let notes of guestNotesForParty">
              <div>{{notes.Text}}</div>
            </div>
          </div> -->
          <div>
            <div class="member-info-container d-flex pt-2 pl-2 pr-2 seat-grid-content">
              <div class="member-info-container__title font-bold">{{'type' | translate}}: </div>
              <div class="member-info-container__value">  &nbsp;{{memberDetails?.MembershipType || 'NA'}}</div>
            </div>
            <div class="member-info-container d-flex  pt-2 pl-2 pr-2 seat-grid-content">
              <div class="member-info-container__title font-bold">{{'Status' | translate}}: </div>
              <div class="member-info-container__value"> &nbsp;{{!memberDetails?.IsMembershipExpired ? ('Active' | translate) : ('expired' | translate)}}</div>
            </div>
            <div class="member-info-container d-flex  pt-2 pl-2 pr-2 seat-grid-content">
              <div class="member-info-container__title font-bold">{{'CardNumber' | translate}}: </div>
              <div class="member-info-container__value">  &nbsp;{{memberDetails?.CardNo || 'NA'}}</div>
            </div>  
          </div>
        </div>
</div>
</div>
</ng-template>

<!-- Add-on Popover -->
<ng-template #addOnForBooking>
  <app-add-on-popover [party]="partyWithAddon"></app-add-on-popover>
</ng-template>

<!-- Custom Guest Fileds for booking -->

<ng-template #customGuestFieldsContainer>
  <div>
    <div class="parties-grid__scrollSize">
      <div class="reservation-notes">
        <div class="reservation-notes__header seat-popover-header">
          {{'CustomGuestFields' | translate}}
        </div>
        <div class="reservation-notes__content seat-popover-content">
          <div class="reservation-notes__notes-details seat-border-color list-item"
            *ngFor="let guestField of customGuestFieldsForBooking">
            <label class="guest-field-name">
              {{guestField.CustomFieldName}}
            </label>
            <span>: {{guestField.CustomFieldValue}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<mat-menu #moreOptionsReservationTab="matMenu">
  <ng-template matMenuContent let-attendee="attendee">
    <button mat-menu-item *ngIf="attendee?.WaiverFormURL != null" (click)="showPartyWaiverForm(attendee)">
      {{'waiverForm' | translate}}</button>
    <button mat-menu-item (click)="showPartyAuditLog(attendee)"> {{'auditlog' |
      translate}}</button>
    <!-- When VCart is enabled, actions are not permitted  -->
    <ng-container *ngIf="!noActionRequired">
      <button mat-menu-item
        *ngIf="isRetailEnabled && attendee.State != PartyState.Cancelled && attendee.State != PartyState.Left && !isSessionCancelled"
        (click)="moveToShopScreen(attendee)">{{'shop' |
        translate}}</button>
      <button mat-menu-item *ngIf="attendee.State != PartyState.Cancelled && attendee.State != PartyState.Left"
        (click)="editParty(attendee)">{{'edit' | translate}}</button>
      <button mat-menu-item *ngIf="attendee.State == PartyState.Pending"
        (click)="cancelPartyForSessionOrClass(attendee)">{{'Cancel' | translate}}</button>
        <button mat-menu-item 
        (click)="partyService.performRateChanges(attendee,financialEffectAction.Reprice)">{{'RepriceText' | translate}}</button>
        <button mat-menu-item *ngIf="dueState.includes(attendee.PrepaymentState) || paidState.includes(attendee.PrepaymentState)"
        (click)="partyService.performRateChanges(attendee,financialEffectAction.Refund)">{{'refundText' | translate}}</button>
        <button mat-menu-item *ngIf="(attendee | partyPaymentState) != 'Waived__Off'"
        (click)="partyService.performRateChanges(attendee,financialEffectAction.WaivedOff)">{{'Waive-Off' | translate}}</button>
    </ng-container>
    <button mat-menu-item [hidden]="true" (click)="dashboardFunctions.PrintWristBand(attendee)"> {{'PrintWristBand' |
      translate}}</button>
  </ng-template>
</mat-menu>

<mat-menu #moreOptionsStandByTab="matMenu">
  <ng-template matMenuContent let-attendee="attendee">
    <button mat-menu-item (click)="showPartyAuditLog(attendee)"> {{'auditlog' |
      translate}}</button>
    <button mat-menu-item [hidden]="true" (click)="dashboardFunctions.PrintWristBand(attendee)"> {{'PrintWristBand' |
      translate}}</button>
    <!-- When VCart is enabled, actions are not permitted  -->
    <ng-container *ngIf="!noActionRequired">
      <button mat-menu-item *ngIf="attendee.State == PartyState.Pending" (click)="editParty(attendee)">{{'edit' |
        translate}}</button>
    </ng-container>
  </ng-template>
</mat-menu>


<ng-template #staffPopover>
  <div class="staff-list" *ngFor="let staff of staffs">
    <div class="staff-name"> {{staff.fullName}}</div>
  </div>
</ng-template>


<ng-template #locationPopover>
  <div class="staff-list" *ngFor="let location of locations">
    <div class="staff-name"> {{location}}</div>
  </div>
</ng-template>

<ng-template #packageInformation>
  <app-package-information [packageInfo]="dashboardFunctions.currentPackageInfo"></app-package-information>
</ng-template>

<ng-template #locationFieldsContainer>
  <div>
    <div class="parties-grid__scrollSize">
      <div class="reservation-notes">
        <div class="reservation-notes__header seat-popover-header">
          {{'Asset' | translate}}
        </div>
        <div class="reservation-notes__content seat-popover-content">
          <div class="reservation-notes__notes-details seat-border-color"
            style="justify-content: space-between;gap:20px" *ngFor="let location of locationListGroup">
            <div>{{location.Name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>