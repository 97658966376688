<div class="server-shift-statistics__server-table">
    <!-- <div *ngIf="!showServerLoad" (click) = "showServerLoad = !showServerLoad">
        <span>{{'Server Load' | translate }}</span>
    </div>
    <div *ngIf="showServerLoad"> -->
        <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header class="seat-border-color">
                <mat-panel-title>
                  <span class="seat-header-three header_serverLoad">{{'serverLoad' | translate}}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
      <table mat-table [dataSource]="tableDataSource" matSort class="mat-elevation-z8" matSortDirection="desc"
        matSortDisableClear (matSortChange)="onSortData($event)">
        <ng-container matColumnDef="serverName">
          <th class="seat-grid-header" mat-sort-header mat-header-cell *matHeaderCellDef arrowPosition="before">
            {{ 'serverName' | translate}} </th>
          <td class="seat-grid-content" mat-cell *matCellDef="let server">
            <div>
              <span class="roundSpan" [style.background]="getBackGroundColor(server.load)">
              </span>
              <span>{{server.serverName}}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="load">
          <th class="seat-grid-header" mat-sort-header mat-header-cell *matHeaderCellDef arrowPosition="before">
            {{ 'load' | translate}} </th>
          <td class="load seat-grid-content" mat-cell *matCellDef="let server">
            <div><span [style.background]="getBackGroundColorLoad(server.load)">
                {{server.load}} %
              </span></div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
      </table>
    <!--</div> -->
    </mat-expansion-panel>
  </div>
