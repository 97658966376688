import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ApiService } from "@app/activities-timeline/services/api.service";
import { AppService } from "@app/app.service";
import { CacheService } from "@app/core/services/cache.service";
import { COMPONENTINPUT, PopupService } from "@app/popup-module/popup.service";
import {
  PartyNoteType,
  ComponentTypes,
  BookingBehavior,
  ClassType,
  PartyType,
  PartyState,
  PaymentMethod,
  OperationResultState,
  NotificationPreference,
} from "@app/shared/constants/commonenums";
import { FormChipService } from "@app/shared/dynamicform/components/form-chip/form-chip.service";
import { DynamicFormComponent } from "@app/shared/dynamicform/dynamic-form/dynamic-form.component";
import { FieldConfig } from "@app/shared/dynamicform/models/field-config.interface";
import {
  Category,
  PartyNoteDTO,
  SettingsDTO,
} from "@app/shared/models/RestaurantDTO";
import { PartyNotes } from "@app/shared/models/global.interface";
import { PartyService } from "@app/shared/services/party.service";
import { Utilities } from "@app/shared/utilities/utilities";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { LockSessionRequestDTO } from "@app/shared/models/ActivityLockSessionDTO";
import _, { uniq } from "lodash";
import moment from "moment";
import { SessionBookingDTO } from "@app/shared/models/SessionBookingDTO";
import { ITabOutputDetails } from "@app/settings/models/common.interface";
import { ComponentDetails } from "@app/popup-module/models/popup.interface";
import { ConfirmationPopupComponent } from "@app/shared/components/confirmation-popup/confirmation-popup.component";
import { DashboardFunctions } from "@app/shared/utilities/dashboard-functions";
import { FacadeService } from "@services/facade.service";
import { GuestLinkType } from "@app/shared/models/FormCodeDTO";
import { FormTextAreaService } from "@app/shared/dynamicform/components/form-textarea/form-textarea.service";
import { PreferedCommunicationComponent } from "@app/shared/components/prefered-communication/prefered-communication.component";

@Component({
  selector: "app-reservation-summary",
  templateUrl: "./reservation-summary.component.html",
  styleUrls: ["./reservation-summary.component.scss"],
})
export class ReservationSummaryComponent
  extends Utilities
  implements OnInit, OnDestroy
{
  @ViewChild('emailNote', { static: true }) emailNoteForm: DynamicFormComponent;
  subscriptions: Subscription = new Subscription();
  selectedPartyPreferenceTags: any[] = [];
  _settings: SettingsDTO;
  restaurantPolicy: any;
  partyNotes: PartyNotes[] = [];
  categories: Category[] = [];
  includeOthers = false;
  othersId: number[] = [];
  config: FieldConfig[] = [];
  classType = ClassType;
  bookingBehavior = BookingBehavior;
  customPartyNotes: any[] = [];
  @ViewChildren("form") components: QueryList<DynamicFormComponent>;
  invokeRatePlan: Subject<any> = new Subject<any>();
  promoCodeEligibleProperty: number[] = [];
  assetDetails: string;
  isSessionClass: boolean = false;
  communicationTagList: any[] = [];
  communicationPrefConfig: FieldConfig[] = [];
  emailNoteConfig: FieldConfig[] = [];
  showAsset: boolean = false;
  PaymentType = PaymentMethod;
  notificationPreference = NotificationPreference;
  payeeCardInfo: any = {};
  saveCard = false;
  defaultPaymentType: PaymentMethod;
  transactionError: boolean = false;
  hideEmailNotes : boolean = false;
  cardDetails = {};
  bookingCreditCard:any;
  defaultGuestLinkType:GuestLinkType
  @ViewChild(PreferedCommunicationComponent) communicationChild!: PreferedCommunicationComponent;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    public _as: AppService,
    public partyService: PartyService,
    public cs: CacheService,
    private _ts: TranslateService,
    private _api: ApiService,
    private _ps: PartyService,
    private formChipService: FormChipService,
    private ps: PopupService,
    private dashboardFunctions: DashboardFunctions,
    private formTextAreaService: FormTextAreaService,
    public facadeService: FacadeService,
    @Inject(COMPONENTINPUT) public data
  ) {
    super(dialog);
    this.subscriptions.add(
      this.cs.settings.subscribe((sett) => {
        this._settings = sett;
        // this.isTaxConfigured = this._settings.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax)?.length > 0;
        // this.isServiceConfigured = this._settings.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.ServiceCharge)?.length > 0;
        // this.isRetailEnabledProperty = Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO);
        // this.OperationCurrency = this._settings.General.OperationCurrency;
        // this.coverTypes = [...this._settings.CoverTypes];
        // this.bookingTypes = [...this._settings.BookingTypes];
        // this.partyService.isNegotiationEnabled = this._as.hasPermission(RolesAndPermissionsType.NegotiateRates);
        if (
          this._settings.PropertySetting.length > 0 &&
          this._settings.PropertySetting[0].PreReservationMessage &&
          this._settings.PropertySetting[0].PreReservationMessageTitle
        ) {
          this.restaurantPolicy = this._settings.PropertySetting[0];
        }
        this.categories = this._settings.Categories.filter(
          (category) => category.Text != "Reasons"
        );
        if (
          dialogData &&
          this._as.OTASourceId.includes(dialogData.PartySourceId)
        ) {
          this.includeOthers = true;
        }
        if (
          dialogData &&
          this.dialogData?.componentDetails?.tabs?.componentInput?.Notes?.length
        ) {
          this.othersId =
            this.dialogData?.componentDetails?.tabs?.componentInput?.Notes?.filter(
              (x) => x.Type == PartyNoteType.FreeFormNote
            ).map(({ RelatedId }) => RelatedId);
        }
        this.partyNotes = Utilities.getRestaurantPredefinedPartyNotes(
          this.categories,
          this.includeOthers,
          this.othersId
        );
      })
    );
  }

  saveCardAgainstGuest(saveCardInfo) {
    this.saveCard = saveCardInfo;
    if(this.partyService.addToCartRequestObj?.PaymentCard?.length){
      this.partyService.addToCartRequestObj.PaymentCard[0].SaveToContact = this.saveCard;
    }
  }

  intialize() {
    this.partyService.PayeeId = null;
    if (this.dialogData?.componentDetails?.tabs?.componentInput?.Contact) {
      this.partyService.selectedGuest =
        this.dialogData?.componentDetails?.tabs?.componentInput?.Contact;
    }

    if (
      this.dialogData?.componentDetails?.tabs?.componentInput?.Notes?.length > 0
    ) {
      //  let filteredNotes = this.dialogData.Notes.filter(_note => _note.Type == PartyNoteType.SpecialMeal);
      let filteredNotes =
        this.dialogData?.componentDetails?.tabs?.componentInput?.Notes;
      if (filteredNotes && filteredNotes.length > 0) {
        filteredNotes.forEach((element) => {
          this.partyService.partyNotes.push(element);
        });
      }
    }
    if (this.bookingData) {
      let selectedSession: any = this._settings.SpecialMeals.find(
        (meals) => meals.Id == this.bookingData.SpecialMealId
      );
      this.isSessionClass = selectedSession?.ClassType == ClassType.Session;
      let specialMeal = this.cs.settings.value.SpecialMeals.find(
        (meals) => meals.Id == this.bookingData.SpecialMealId
      );
      if (specialMeal) {
        this.showAsset =
          specialMeal.ActivitySessions.filter(
            (location) => location.LocationGroupId
          )?.length > 0;
      }
    }
    this.setPackageDetails();
    this.setPaidDetails();
    this.initializeEmailNoteConfig();
    this.setCommunicationPrefList();
    this.bindGuestPartyNotes();
    if (
      this.dialogData?.componentDetails?.tabs?.componentInput?.Notes?.length > 0
    ) {
      this.partyService.partyNotes = [];
      this.mapPartyNotes();
      if (this.customPartyNotes.length) {
        this.mapCustomPartyNotes();
      }
    }
    this.formChipsChange();
    this.textAreaSubscription();
    this.formChangeSubscribe();
    if (this.partyService.BookingBehaviour === BookingBehavior.ClassOrSession) {
      this.lockSessions(false);
    } else {
      this.lockTables(
        !!this.dialogData?.componentDetails?.tabs?.componentInput?.CartId
      );
    }
  }

  textAreaSubscription() {
    this.subscriptions.add(
      this.formTextAreaService.formTextAreaChange$.subscribe((data) => {
        let index = this.config.findIndex((itm) => itm.name === data.name);
        if (index != -1) {
          this.config.splice(index, 1);
          let [relatedTag, uniqId] = data.name.split("$multinotes");
          let length = this.config.filter((itm) =>
            itm.name.includes(relatedTag)
          ).length;
          if (!length && this.config[index - 1]) {
            let othersIndex = this.config[index - 1].options.findIndex(
              (option) => option.Name === "Others"
            );
            this.config[index - 1].options[othersIndex].setSelected = false;
          }
          let partyNoteIndex = this.partyService.partyNotes.findIndex(
            (note) =>
              note.Id == uniqId ||
              (note.RelatedId == relatedTag.match(/(\d+)/)[0] &&
                note.addnlId == uniqId)
          );
          this.partyService.partyNotes.splice(partyNoteIndex, 1);
          this.partyService.reservationFormGroup.markAsDirty();
          this.partyService.reservationFormGroup.updateValueAndValidity();
        }
      })
    );
  }
  initializeEmailNoteConfig(){
    this.hideEmailNotes =  (this._settings?.MerchantSettings?.EnableEmailNotes?.SettingValue) ? JSON.parse(this._settings?.MerchantSettings?.EnableEmailNotes?.SettingValue) :false;
    this.emailNoteConfig = [{
      type: 'textarea',
      name: 'EmailNotes',
      inputType: 'text',
      showHint: true,
      rows: 2,
      charLength: 1000,
      value: this.data?.EmailNotes || '',
      textAreaAppearance:true,
      class: 'email-description w-100',
      appearance: true
   }]
  }

  setPackageDetails() {
    this.defaultGuestLinkType = this.data?.GuestLinkType || GuestLinkType.None
    this.defaultPaymentType =
      this.data?.PaymentMethod ||
      this._settings.SpecialMeals?.find(
        (activity) => activity.Id == this.bookingData.SpecialMealId
      )?.DefaultPaymentType ||
      Number(
        this._settings?.MerchantSettings?.DefaultPaymentType?.SettingValue
      );
    if (this.data?.PackageId) {
      this.bookingData.PackageDetails =
        this.partyService.getDiscountPercentForCartItem(
          this.data?.SpecialMealId || null,
          this.data?.PackageId || null
        );
    } else {
      this.bookingData.PackageDetails = null;
    }
  }
  setCommunicationPrefList(){
    let defaultPageMethod = this._settings.General.DefaultPageMethod ? this._settings.General.DefaultPageMethod - 1 : 0
    let defaultPref = this.bookingData.PageMethod === 0 || this.bookingData.PageMethod ? this.bookingData.PageMethod : defaultPageMethod;
    let _communicationTagList = this.facadeService.getCommunicationList();
    this.bookingData.PageMethod  =  defaultPref;

    _communicationTagList.forEach(tagItem => {
      if(tagItem.Id === defaultPref){
        tagItem.setSelected = true;
      }
    })

    this.communicationPrefConfig = [
      {
        type: 'chip',
        name: 'pageMethod',
        inputType: 'text',
        label: 'preferredCommunication',
        class: 'guest-view__preferred-tags-edit', // Do not modify the className, there is a dependency for editGuest details.
        options: _communicationTagList,
        multipleChip: false
      }
    ]
  }

  getOpenObjectRequest(bookingData) {
    return {
      Slots: this.getRequestObjectSlots(),
      AttemptSlots: this.getAttemptSlotObj(bookingData.Slots),
    };
  }

  getSessionObjectRequest(bookingData) {
    return {
      Slot: this.getRequestObjectSession(),
      Sessions: this.getRequestObjectSessions(),
      InstructorIds: this.partyService.sessionBookingData.StaffId,
      SpecialMealId: bookingData.ActivityId,
      RestaurantId: Utilities.RestaurantId(),
    };
  }

  getRequestObjectSessions() {
    var bookingData = this.partyService.sessionBookingData;
    var sessionClass =
      this.partyService.sessionBookingData.ClassType === ClassType.Session;

    return {
      LockId: this.partyService.lockData?.SlotLockIdDTO?.Id || null,
      Sessions: sessionClass ? bookingData.Slots : [],
      SessionGroupId: sessionClass ? null : bookingData.SessionGroupId,
      OverBooked: bookingData.OverBooked,
      BookedSessionIds:
        this.data?.BookedSessions &&
        this.dashboardFunctions.checkEditCheckedInReservationsConfig()
          ? this.fetchIdsOfSelectedSessions(this.data.BookedSessions)
          : [],
    };
  }

  fetchIdsOfSelectedSessions(bookedSessions) {
    let sessionIds = [];
    let activityMinDate = this.dashboardFunctions.getMinDateBasedonConfig(
      this._settings.SpecialMeals.find(
        (meal) => meal.Id == this.partyService.selectedSpecialMealId
      ),
      Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)
    );
    activityMinDate.setHours(0);
    activityMinDate.setMinutes(0);
    activityMinDate.setSeconds(0);
    activityMinDate.setMilliseconds(0);
    bookedSessions.map((slot) => {
      if (
        new Date(slot.BookedDate).getTime() >=
          new Date(activityMinDate).getTime() &&
        slot.SessionState != PartyState.Left &&
        slot.Id
      ) {
        sessionIds.push(slot.Id);
      }
    });
    return sessionIds;
  }

  getRequestObjectSession() {
    var bookingData: SessionBookingDTO = this.partyService.sessionBookingData;

    return {
      Time: new Date(bookingData.FromDate).toDateString(),
      LockId: this.partyService.lockData?.SlotLockIdDTO?.Id || null,
      Type: bookingData.AvailabilityCriteria,
    };
  }

  getStartDate() {
    let dateArray = this.getRequestObjectSessions().Sessions.map(
      (x) => new Date(x.Date)
    );
    return (
      moment(Math.min.apply(null, dateArray)).format("YYYY-MM-DD") +
      "T00:00:00.000"
    );
  }

  getEndDate() {
    let dateArray = this.getRequestObjectSessions().Sessions.map(
      (x) => new Date(x.Date)
    );
    return (
      moment(Math.max.apply(null, dateArray)).format("YYYY-MM-DD") +
      "T00:00:00.000"
    );
  }

  bindGuestPartyNotes() {
    this.config = [];
    this.partyNotes.forEach((partyNote, index) => {
      const tag = {
        type: "chip",
        name: `preferredPartyTags${index}`,
        inputType: "text",
        label: partyNote.CategoryName,
        class: "guest-view__preferred-tags-edit", // Do not modify the className, there is a dependency for editGuest details.
        options: this.getOptions(partyNote.partyNotes, partyNote.Color),
        multipleChip: true,
        multipleOther: true,
      };
      this.config.push(tag);
    });
    if (
      _.get(
        this.dialogData?.componentDetails?.tabs?.componentInput,
        ["Notes"],
        []
      ).length
    ) {
      this.customPartyNotes =
        this.dialogData?.componentDetails?.tabs?.componentInput?.Notes?.filter(
          (note) => !note.RelatedId && note.Type == PartyNoteType.FreeFormNote
        );
      if (this.customPartyNotes && this.customPartyNotes.length) {
        const tag = {
          type: "chip",
          name: `partyfreeTags`,
          inputType: "text",
          label: "freeNoteText",
          class: "guest-view__free-tags-edit",
          options: this.getFreeTextOptions(),
          multipleChip: true,
          multipleOther: true,
        };
        this.config.unshift(tag);
      }
    }
  }

  getFreeTextOptions() {
    let freeTextOptions = [];
    let freeNotes =
      this.dialogData?.componentDetails?.tabs?.componentInput?.Notes?.filter(
        (notes) => !notes.RelatedId
      );
    freeNotes.forEach((notes) => {
      freeTextOptions.push({
        Id: notes.Id,
        Name: notes.Text,
        Icon: "None",
        Value: "",
      });
    });
    return freeTextOptions;
  }

  mapPartyNotes() {
    const preferredTags = this.config.filter((fieldConfig) =>
      fieldConfig.name.includes("preferredPartyTags")
    );
    this.dialogData?.componentDetails?.tabs?.componentInput?.Notes?.forEach(
      (note) => {
        preferredTags.forEach((fieldConfig) => {
          if (
            note.Type == PartyNoteType.FreeFormNote ||
            note.Type == PartyNoteType.PredefinedNote ||
            note.Type == PartyNoteType.SpecialRequest
          ) {
            const filteredNotes = fieldConfig.options.find(
              (data) => data.Id === note.RelatedId
            );
            if (filteredNotes) {
              filteredNotes.setSelected = true;
              filteredNotes.noteId = note.Id;
              if (filteredNotes.Name.toLowerCase() == "others") {
                let otherNotesName = `otherNotes${filteredNotes.Id}`;
                if (filteredNotes.setSelected) {
                  const otherNotes: FieldConfig = {
                    name: otherNotesName + "$multinotes" + note.Id,
                    type: "textarea",
                    inputType: "text",
                    label: "notes",
                    showHint: true,
                    class: "guest-data__party-other-tags mb-3 w-80",
                    charLength: 1000,
                    value: note.Text,
                    showRemove: true,
                  };
                  const preferredTagsIndex = this.config.findIndex(
                    (preferenceConfig) =>
                      preferenceConfig.name == fieldConfig.name
                  );
                  this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
                }
              }
              this.partyService.partyNotes.push(note);
            }
          }
        });
      }
    );
    this.config = [...this.config];
  }

  mapCustomPartyNotes() {
    const freeTags = this.config.filter((fieldConfig) =>
      fieldConfig.name.includes("partyfreeTags")
    );
    this.dialogData?.componentDetails?.tabs?.componentInput?.Notes?.forEach(
      (note) => {
        freeTags.forEach((fieldConfig) => {
          const filteredNotes = fieldConfig.options.find(
            (data) => data.Id === note.Id
          );
          if (filteredNotes) {
            filteredNotes.setSelected = true;
            this.partyService.partyNotes.push(note);
          }
        });
      }
    );
  }
  getOptions(partyNotes, categoryColor): any {
    let configOptios = [];
    partyNotes.forEach((notes) => {
      configOptios.push({
        Id: notes.Id,
        Name: notes.Text,
        Icon: notes.Icon,
        Value: "",
        color: categoryColor,
      });
    });
    return configOptios;
  }

  lockTables(bypassLock) {
    this.partyService.BookingBehaviour = this.bookingData.BookingBehavior;
    var { Slots, CoverTypes, BookingTypes, Location, BookingSize, StaffId } =
      this.bookingData;
    var slots = Slots.map((slot) => {
      return {
        LocalTime: slot.Time,
        PartySize: BookingSize,
        OverBooked: this.bookingData?.OverBooked ? BookingSize : 0,
        SeatingAreaId: null,
        SeatingTypeId: null,
        IsCommunalTable: false,
        FromWebsite: true,
        DurationInMinutes: slot.DurationInMinutes,
        ReservationIdToIgnore:
          this.dialogData?.componentDetails?.tabs?.componentInput?.Id,
        TableIds: Location ? Location?.flat() : [],
        InstructorIds: StaffId,
        AddOns: [],
        ExpireAt: null,
        SpecialMealId: this.bookingData?.SpecialMealId,
        lockIdToIgnore:
          this.dialogData?.componentDetails?.tabs?.componentInput
            ?.bookingRatePlan?.SlotLockResultDTO?.[slot.LocalTime]
            ?.SlotLockIdDTO?.Id ||
          (this.partyService.slotLockIds?.length
            ? this.partyService.slotLockIds[0]
            : null),
      };
    });

    this.changeFormState(false);
    if (slots.length && !this.checkStandByReservations()) {
      if (!bypassLock) {
        this.subscriptions.add(
          this._api
            .lockTables(
              slots,
              this.dialogData?.componentDetails?.tabs?.componentInput?.Type == 2
                ? true
                : this.partyService.isStandBy
                ? true
                : false
            )
            .subscribe((response) => {
              let lockResponse = response.Payload;
              const lockData = Object.values(lockResponse) as any[];
              this.partyService.lockData = response.Payload;
              this.partyService.isSlotLocked = true;
              this.partyService.slotLockIds = lockData?.map((x) => {
                if (x?.SlotLockIdDTO?.Id) {
                  return x?.SlotLockIdDTO?.Id;
                } else {
                  return 0;
                }
              });
              let tableLockIds = lockData
                ?.map((x) => {
                  if (x?.TableLockedDTO?.TableIds) {
                    return x?.TableLockedDTO?.TableIds;
                  }
                  return [];
                })
                .flat()
                .filter((locationId) => locationId);

                if (!this.bookingData.Location || this.bookingData.Location.length == 0) {
                this.bookingData.Location = uniq(tableLockIds);              
              }
              if (!this.partyService.addToCartRequestObj?.TableIds?.length) {
                this.partyService.addToCartRequestObj.TableIds =
                  uniq(tableLockIds);
              }
              this.invokeRatePlan.next(true);
            })
        );
      }
      else
      this.invokeRatePlan.next(true);
      //this.getRatePlanForActivity(this.bookingData);
    } else {
      if (this.checkStandByReservations()) {
        this.invokeRatePlan.next(true);
      }
      // this.getRatePlanForActivity(this.bookingData);
    }
  }

  checkStandByReservations() {
    if (
      this.partyService.selectedOpenBookingSlots &&
      Object.values(this.partyService.selectedOpenBookingSlots).filter(
        (value) => value
      )?.length == 1
    ) {
      let isStandBy = false;
      Object.keys(this.partyService.selectedOpenBookingSlots).forEach((key) => {
        if (this.partyService.selectedOpenBookingSlots[key]) {
          isStandBy =
            this.partyService.slots_holder.find((item) => item.Id == key)
              .IsStandByBookingSlot && !this.partyService.isOverBookSlotSelected
              ? true
              : false;
        }
      });
      return isStandBy;
    } else {
      return false;
    }
  }

  get bookingData() {
    if (
      this.dialogData.from === ComponentTypes.EditOpenBooking ||
      this.dialogData.from === ComponentTypes.AddOpenBooking
    ) {
      return this.partyService.openBookingData;
    } else if (
      this.dialogData.from === ComponentTypes.EditActivityBooking ||
      this.dialogData.from === ComponentTypes.AddActivityBooking
    ) {
      return this.partyService.sessionBookingData;
    } else if (
      this.dialogData.from === ComponentTypes.EditPrivateLessonBooking ||
      this.dialogData.from === ComponentTypes.AddPrivateLessonBooking
    ) {
      return this.partyService.privateLessonBookingData;
    }
  }

  formChipsChange() {
    this.subscriptions.add(
      this.formChipService.formChipChange$.subscribe((selectedChips) => {
        if (selectedChips.name === "partyfreeTags") {
          selectedChips.data.forEach((tags) => {
            if (!tags.setSelected) {
              const index = this.partyService.partyNotes.findIndex(
                (note) => note.Id === tags.Id
              );
              if (index > -1) {
                this.partyService.partyNotes =
                  this.partyService.partyNotes.filter(
                    (note) => note.Id != tags.Id
                  );
              }
            } else {
              const index = this.partyService.partyNotes.findIndex(
                (note) => note.Id === tags.Id
              );
              if (index == -1) {
                const freeTextNotes: PartyNoteDTO = {} as PartyNoteDTO;
                freeTextNotes.Id = tags.Id;
                freeTextNotes.Text = tags.Name;
                freeTextNotes.Type = PartyNoteType.FreeFormNote;
                this.partyService.partyNotes.push(freeTextNotes);
              }
            }
          });
        } else if (
          this.config.filter(
            (fieldConfig) => fieldConfig.name == selectedChips.name
          ).length > 0
        ) {
          const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
          this.selectedPartyPreferenceTags = selectedChips.data;
          const preferredTagsIndex = this.config.findIndex(
            (fieldConfig) => fieldConfig.name == selectedChips.name
          );
          const otherTags = selectedChips.data.filter(
            (chip) => chip.Name == "Others"
          );
          if (otherTags && otherTags.length > 0) {
            let otherNotesName = `otherNotes${otherTags[0].Id}`;
            let isOtherNoteAvailable = this.config.filter((config) =>
              config.name.includes(otherNotesName)
            ).length;
            const otherNotesControl = this.components.last.config.filter(
              (config) => config.name.includes(otherNotesName)
            );
            if (
              (selectedChips.isOthersAdd || !isOtherNoteAvailable) &&
              otherTags.length > 0 &&
              otherTags[0].setSelected
            ) {
              let currDate = new Date();
              const otherNotes: FieldConfig = {
                name: otherNotesName + "$multinotes" + currDate.getTime(),
                type: "textarea",
                inputType: "text",
                label: "notes",
                showHint: true,
                class: "guest-data__party-other-tags w-80",
                charLength: 1000,
                isHidden: false,
                showRemove: true,
              };
              this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
            } else if (isOtherNoteAvailable) {
              if (otherTags[0].setSelected) {
                otherNotesControl.forEach((control) => {
                  control.isHidden = false;
                });
                const otherNotes = this.partyService.partyNotes.find(
                  (note) => note.RelatedId == otherTags[0].Id
                );
                if (!otherNotes) {
                  const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
                  let editPartyNote = null;
                  if (this.data) {
                    editPartyNote = this.data.Notes.find(
                      (note) => note.RelatedId == otherTags[0].Id
                    );
                    if (editPartyNote) {
                      this.partyService.partyNotes.push(editPartyNote);
                    }
                  } else {
                    partyNotes.Id = null;
                    partyNotes.Type = PartyNoteType.FreeFormNote;
                    partyNotes.RelatedId = otherTags[0].Id;
                    partyNotes.Text =
                      this.components.last.form.value[otherNotesName];
                    this.partyService.partyNotes.push(partyNotes);
                  }
                }
                // this.partyService.partyNotes = this.partyService.partyNotes.filter((note) => note.RelatedId != otherTags[0].Id)
              } else {
                otherNotesControl.forEach((control) => {
                  control.isHidden = true;
                });
                this.partyService.partyNotes =
                  this.partyService.partyNotes.filter(
                    (note) => note.RelatedId != otherTags[0].Id
                  );
              }
              // this.config.splice(preferredTagsIndex + 1, 1);
            }
          }
          this.config = [...this.config];
          this.selectedPartyPreferenceTags.forEach((_partyNotes) => {
            if (_partyNotes.Name !== "Others") {
              const preferredData = this.partyService.partyNotes.filter(
                (note) => note.RelatedId === _partyNotes.Id
              )[0];
              if (_partyNotes.setSelected && !preferredData) {
                partyNotes.RelatedId = _partyNotes.Id;
                partyNotes.Text = _partyNotes.Name;
                partyNotes.Id = _partyNotes.noteId ? _partyNotes.noteId : null;
                partyNotes.Type = PartyNoteType.PredefinedNote;
                this.partyService.partyNotes.push(partyNotes);
              } else if (!_partyNotes.setSelected) {
                const index = this.partyService.partyNotes.findIndex(
                  (note) => note.RelatedId === _partyNotes.Id
                );
                if (index > -1) {
                  this.partyService.partyNotes.splice(index, 1);
                }
              }
            }
          });
        }
        this.partyService.addToCartRequestObj.Notes =
          this.partyService.partyNotes;
        this.partyService.reservationFormGroup.markAsDirty();
      })
    );
  }

  formChangeSubscribe() {
    this.components.forEach((x, index) => {
      this.subscriptions.add(x.form?.valueChanges.pipe(debounceTime(100),
      distinctUntilChanged()).subscribe(value => {
        this.formChangeHandler(value);
      }));
      this.subscriptions.add(this.formChipService.formChipChange$.subscribe((selectedChips) => {
        if (selectedChips.name === this.communicationPrefConfig.filter(config => config.name == "pageMethod")[0].name) {
          let pageMethod = selectedChips.data.find(({setSelected}) => setSelected)?.Id || 0;
          this.bookingData.PageMethod = pageMethod;
        }
      }))
    });
    this.emailNoteForm?.form?.valueChanges.pipe(debounceTime(100),
      distinctUntilChanged()).subscribe((value) => {
        if(value){
          this.partyService.addToCartRequestObj.EmailNotes = value.EmailNotes;
        }
      })
  }
  updatePageMethod(pageData) {
    if (this.bookingData && this.partyService?.addToCartRequestObj) {
      this.bookingData.PageMethod = pageData?.pageMethod;
      this.bookingData.NotificationPreference =
        pageData?.NotificationPreference;
        this.partyService.addToCartRequestObj.PageMethod = pageData?.pageMethod;
      this.partyService.addToCartRequestObj.NotificationPreference =
        pageData?.NotificationPreference;
    }
  }
  bindInternalPartyNotes(dialogData: any) {
    for (let preferenceProperty in dialogData) {
      if (preferenceProperty.includes("otherNotes")) {
        const otherNotesInConfig = this.config.find(
          (configProp) => configProp.name == preferenceProperty
        );
        if (otherNotesInConfig && !otherNotesInConfig.isHidden) {
          const partyNotes: PartyNoteDTO = {} as PartyNoteDTO;
          partyNotes.Text = dialogData[preferenceProperty] || "";
          let mulNoteArr = preferenceProperty.split("$multinotes");
          let addnlId = null;
          if (mulNoteArr.length > 1) {
            addnlId = mulNoteArr[1];
          }
          const preferencePropertyId = preferenceProperty.match(/(\d+)/);
          if (preferencePropertyId) {
            partyNotes.RelatedId = Number(preferencePropertyId[0]);
            partyNotes.addnlId = addnlId;
            const preferredOtherTags = this.partyService.partyNotes.find(
              (note) =>
                note.RelatedId === partyNotes.RelatedId &&
                note.addnlId == partyNotes.addnlId
            );
            if (preferredOtherTags) {
              partyNotes.Id = preferredOtherTags.Id
                ? preferredOtherTags.Id
                : null;
              partyNotes.Type = PartyNoteType.FreeFormNote;
            } else {
              partyNotes.Id = null;
              partyNotes.Type = PartyNoteType.FreeFormNote;
            }
          }
          this.partyService.partyNotes = [
            ...this.partyService.partyNotes.filter(
              (note) => !note.addnlId && note.RelatedId != partyNotes.RelatedId
            ),
            ...this.partyService.partyNotes.filter(
              (note) =>
                note.addnlId &&
                !(
                  note.RelatedId == partyNotes.RelatedId &&
                  note.addnlId == partyNotes.addnlId
                )
            ),
          ];
          this.partyService.partyNotes.push({ ...partyNotes });
        }
      }
    }

    this.partyService.addToCartRequestObj.Notes = this.partyService.partyNotes;
    //this.changeFormState(true)
    // if (!this.holdFormValidation) {
    //   this.partyService.reservationFormGroup.markAsDirty();
    //   this.partyService.reservationFormGroup.updateValueAndValidity();
    // }
    // this.updateFormValidity();
  }

  changeFormState(enable) {}
  formChangeHandler(value) {
    this.bindInternalPartyNotes(value);

    // this.enableNegotiationOption(value);
    // this.applyTaxForNegotiated(value);
    // this.addNegotiationReason(value);
  }

  cartActionsHandler() {
    this.subscriptions.add(
      this.ps.addToCartAction$.subscribe((action) => {
        if (action) {
          this.addToCart(action);
        }
      })
    );
    this.subscriptions.add(
      this.ps.confirmedAction$.subscribe((tabOutput: any) => {
        let cartComponents = [
          ComponentTypes.EditOpenBooking,
          ComponentTypes.AddOpenBooking,
          ComponentTypes.AddPrivateLessonBooking,
          ComponentTypes.EditPrivateLessonBooking,
          ComponentTypes.AddActivityBooking,
          ComponentTypes.EditActivityBooking,
        ];
        if (cartComponents.includes(tabOutput.fromComponent)) {
          tabOutput.isEdit = true;
          this.addToCart(tabOutput);
        }
      })
    );
  }

  addToCart(action) {
    let val = action.isEdit;
    let cartGroupData = action.outputData;
    if (
      val &&
      Utilities.isRetailEnabledProperty(
        this.cs.settings.value.General.RetailIntegrationDTO
      )
    ) {
      if (
        Utilities.isUserHasAccessToRetail(this._as.restaurantId) ||
        !this.validateTotalAmount() ||
        this.partyService.IsSkipPayment
      ) {
        this.buyAndAddToValidation(cartGroupData, val, action);
      } else {
        const popUpMessage = [
          {
            confirmationMessage: this._ts.instant("userPermissionMsg"),
            dialogTitle: this._ts.instant("authFailed"),
          },
        ];
        let okbutton = this._ts.instant("ok");
        const componentDetails: ComponentDetails =
          Utilities.setComponentDetails(
            ConfirmationPopupComponent,
            "small",
            "action",
            popUpMessage,
            popUpMessage[0].dialogTitle
          );
        this.openCustomPopup(
          componentDetails,
          ComponentTypes.RoleTypeAccess,
          "450px",
          "auto",
          true,
          "",
          okbutton,
          "",
          true
        );
      }
    } else {
      this.buyAndAddToValidation(cartGroupData, val, action);
    }
  }

  validateTotalAmount() {
    if (this.partyService.BookingBehaviour === BookingBehavior.ClassOrSession) {
      return (
        this.partyService.sessionBookingDataSaveObj &&
        this.partyService.sessionBookingDataSaveObj.TotalPayable > 0
      );
    } else if (
      this.partyService.BookingBehaviour === BookingBehavior.PrivateLesson
    ) {
      return (
        this.partyService.privateLessonBookingDataSaveObj &&
        this.partyService.privateLessonBookingDataSaveObj.TotalPayable > 0
      );
    } else if (
      this.partyService.BookingBehaviour === BookingBehavior.OpenBooking
    ) {
      return (
        this.partyService.openBookingDataSaveObj &&
        this.partyService.openBookingDataSaveObj.TotalPayable > 0
      );
    }
  }

  buyAndAddToValidation(cartGroupData, val, action) {
    let dataSet = {
      inputData: action.inputData,
      outputData: cartGroupData,
      fromComponent: action.fromComponent,
      isEdit: val,
    } as ITabOutputDetails;

    if (this.bookingData.BookingId) {
      if (this.partyService.BookingBehaviour === BookingBehavior.OpenBooking) {
        this.dashboardFunctions.openBookingConfirmationHandler(dataSet, true);
      }
      if (
        this.partyService.BookingBehaviour === BookingBehavior.ClassOrSession
      ) {
        this.dashboardFunctions.activityBookingConfirmationHandler(
          dataSet,
          true
        );
      }
      if (
        this.partyService.BookingBehaviour === BookingBehavior.PrivateLesson
      ) {
        this.dashboardFunctions.privateBookingConfirmationHandler(
          dataSet,
          true
        );
      }
    } else {
      this.ps.confirmAddToCartAction$.next(dataSet);
    }
  }

  ngAfterViewInit() {
    this.cartActionsHandler();
    this.partyService.paymentMethodChanges$.subscribe((data) => {
      if (data) {
       this.transactionErrorHandle();
      }
    });
  }

  transactionErrorHandle(event?) {
    let AuthorizePaymentValidation =
          this.partyService.bookingPaymentType !== PaymentMethod.Authorize
            ? true
            : (this.partyService.RetailCardTokenId || ((this.data?.CreditCards  ||  (this.cardDetails && Object.keys(this.cardDetails).length > 0) )&&    this.partyService.bookingPaymentType == PaymentMethod?.Authorize)) ||
              this.partyService.selectedSendLinkOption !== GuestLinkType.None
            ? true
            : false;
      this.transactionError = event ? event : false;
        if (this.ps.tabsActionData?.length) {
          this.ps.tabsActionData[
            this.ps.tabsActionData.length - 1
          ].gotoNextTab = AuthorizePaymentValidation && !this.transactionError ? true : false;
          this.ps.tabsActions$.next(this.ps.tabsActionData);
        }
  }
  setRetailCardTokenId(data) {
    if (this.bookingData) {
      this.partyService.RetailCardTokenId = data;
      if(this.partyService.addToCartRequestObj)
      this.partyService.addToCartRequestObj.RetailCardTokenId = data;
      //this.saveCard = false;
    }
  }
  setPartyPaymentType(data) {
    if (data && this.bookingData) {
      this.partyService.bookingPaymentType = data;
      this.partyService.addToCartRequestObj.PaymentMethod = data;
    }
  }

  isForStandbyReservations(standbymeal, specialMeal) {
    if (specialMeal) {
      return (
        (standbymeal && this.data?.Type != PartyType.Reservation) ||
        (this.bookingData.AllowStandBy && !this.bookingData.OverBooked)
      );
    }
    return this.checkStandByReservations();
  }

  createSaveRequestObject() {
    var bookingData = this.bookingData;
    let standbymeal = false;
    this.bookingData.PageMethod = this.communicationChild?.selectedPageMethod ?? null;
    this.bookingData.NotificationPreference = this.communicationChild?.selectedNotification ?? null;
    const specialMeal = this._settings.SpecialMeals.find(
      (meal) => meal.Id === bookingData?.SpecialMealId
    );
    if (specialMeal) {
      standbymeal = specialMeal.IsForStandbyReservations;
      this.partyService.isStandBy = standbymeal;
    }

    let isStandBy = this.isForStandbyReservations(standbymeal, specialMeal);

    this.partyService.addToCartRequestObj = {
      //Id: this.dialogData?.addOpenBooking ? null : (this.dialogData && this.dialogData.Id) ? this.dialogData.Id : null,

      Size: this.bookingData.BookingSize,
      RemindAboutVisit: false,
      RemindAboutVisitEmail: false,
      AgreedWithTermsOfUse: true,
      Contact: {},
      SeatingAreaId: null,
      SeatingTypeId: null,
      ConfirmationMessageOption: 0,
      Notes: this.partyService.partyNotes,
      TableIds:
        this.partyService.BookingBehaviour === BookingBehavior.ClassOrSession
          ? []
          : this.bookingData.Location?.flat(),
      CoverTypes: this.bookingData.CoverTypes,
      BookingTypes: this.bookingData.BookingTypes,
      BookingContacts: this.bookingData.BookingContacts,
      IsCommunalTable: false,
      FromWebsite: true,
      Language: 0,
      ReservationAttemptId: null,
      waiverFormTransactionId: null,
      SecondaryContactsList: null,
      BookingBehavior: bookingData.BookingBehavior,
      StartDate: new Date(bookingData.FromDate).toDateString(),
      EndDate: new Date(bookingData.ToDate).toDateString(),
      NegotiatedAmount: 0,
      Comments: "",
      TotalPayable: null,
      TaxAmount: null,
      NotificationPreference: this.bookingData.NotificationPreference,
      PageMethod: this.bookingData.PageMethod || (this._settings.General.DefaultPageMethod ? this._settings.General.DefaultPageMethod - 1 : 0),
      PackageConfirmationId:
        this.dialogData &&
        this.dialogData?.componentDetails?.tabs?.componentInput
          ?.PackageConfirmationId
          ? this.dialogData?.componentDetails?.tabs?.componentInput
              ?.PackageConfirmationId
          : null,
      PackageId:
        this.dialogData &&
        this.dialogData?.componentDetails?.tabs?.componentInput?.PackageId
          ? this.dialogData?.componentDetails?.tabs?.componentInput?.PackageId
          : null,
      IsForStandbyReservations: isStandBy,
      Type:
        (standbymeal || isStandBy) && this.data?.Type != PartyType.Reservation
          ? PartyType.StandBy
          : this.bookingData.AllowStandBy && !this.bookingData.OverBooked
          ? PartyType.StandBy
          : PartyType.Reservation,
      HostId: this.dialogData?.componentDetails?.tabs?.componentInput?.Id
        ? this.dialogData?.componentDetails?.tabs?.componentInput?.HostId
        : JSON.parse(
            localStorage.getItem(
              `${sessionStorage.getItem(
                `sessionGUID${Utilities.getSessionStorageType()}`
              )}_loginResult`
            )
          ).HostId,
      FinancialEffectId: this.partyService.FinancialEffectId,
      RetailCardTokenId: this.partyService.RetailCardTokenId,
      PaymentMethod: this.partyService.bookingPaymentType,
      summaryState: this.partyService.openBookingDataSaveObj?.summaryState,
      SalesContactIds: this.bookingData?.SalesContactIds,
      NotificationEmailIds:this.bookingData.NotificationEmailIds || [],
      PartyId: this.bookingData.BookingId || null,
      OverBooked: this.bookingData?.OverBooked,
      MinRateNegotiatedAdjustment: null,
      SpecialMealId: bookingData.SpecialMealId,
      InstructorIds: bookingData.StaffId,
      PackageDetails: bookingData.PackageDetails || null,
      IsPackageBroken: false,
      IsExclusiveBooking: this.bookingData?.IsExclusiveBooking || false,
      EmailNotes:this.emailNoteForm?.value?.EmailNotes || null,
      offerCode:
        this.partyService.promoCodeApplied$.value?.offer?.OfferCode || null,
      AdditionalBookingInfo: this.bookingData?.AdditionalInfo,
      classType:
        this.partyService.BookingBehaviour === BookingBehavior.ClassOrSession
          ? this.partyService.sessionBookingData?.ClassType
          : null,
      //PaymentCard: this.getPaymentInfo()
    };
    if (this.partyService.BookingBehaviour === BookingBehavior.ClassOrSession) {
      this.partyService.addToCartRequestObj = {
        ...this.partyService.addToCartRequestObj,
        ...this.getSessionObjectRequest(bookingData),
      };
    } else {
      this.partyService.addToCartRequestObj = {   
        ...this.partyService.addToCartRequestObj,
        ...this.getOpenObjectRequest(bookingData),
      };
    }
  }

  selectedCard(card){
    this.cardDetails = card || null;
  //  this.bookingCreditCard = card || {};
    this.selectedPayeeCardInfo(this.cardDetails);
  }

  selectedPayeeCardInfo(card) {
    // let payee:PaymentCard =  {
    //   PayeeContactIds: payeeCardInfo[0]
    //   BookingContactIds: this.partyService.ratePlanObject.PayingGuests[0].PayingContacts,
    //   SaveToContact: this.saveCard
    // }
    // this.partyService.addToCartRequestObj.PaymentCard = [{...payee, ...card}]
    if(this.partyService.addToCartRequestObj)
    this.partyService.addToCartRequestObj.PaymentCard =
      this.partyService.selectedPayeeCardInfo((card && Object.keys(card).length) ? card: this.cardDetails , this.saveCard);
      this.transactionErrorHandle();
  }

  removeCardList(check){
    if(this.data){
      this.data["CreditCards"] = null;
    }
    if(    this.partyService.addToCartRequestObj?.PaymentCard){
      this.partyService.addToCartRequestObj.PaymentCard = this.partyService.selectedPayeeCardInfo(null,false);
    }
    this.partyService.PaymentCard = this.partyService.selectedPayeeCardInfo(null,false);
  }

  setPackageBrokenData(IsPackageBroken) {
    if (IsPackageBroken) {
      this.partyService.addToCartRequestObj.PackageDetails = null;
      this.partyService.addToCartRequestObj.IsPackageBroken = true;
    } else {
      this.partyService.addToCartRequestObj.PackageDetails =
        this.bookingData?.PackageDetails;
      this.partyService.addToCartRequestObj.IsPackageBroken = false;
    }
  }

  getRequestObjectSlots() {
    var bookingData = this.bookingData;

    return bookingData.Slots.map((slot, index) => {
      return {
        Time: slot.Time,
        LockId: this.partyService.lockData
          ? this.partyService.lockData[slot.LocalTime]?.SlotLockIdDTO?.Id
          : null,
        Type: 3,
        DurationInMinutes: slot.DurationInMinutes,
        EndTime: moment(slot.EndTime),
        OverBooked: this.partyService.isOverBookSlotSelected
          ? bookingData.OverBooked
          : null,
      };
    });
  }

  getAttemptSlotObj(timeslots) {
    return timeslots.map((slot) => {
      return {
        LocalTime: slot.LocalTime,
        Duration: slot.Duration,
        ReservationAttemptId: null,
      };
    });
  }
  lockSessions(bypassLock) {
    this.partyService.BookingBehaviour = BookingBehavior.ClassOrSession;
    let {
      ActivityId,
      FromDate,
      Slots,
      CoverTypes,
      BookingTypes,
      Location,
      StaffId,
      BookingSize,
      SessionGroupId,
      OverBooked,
      IsExclusiveBooking,
    } = this.partyService.sessionBookingData;
    const classType = this.partyService.sessionBookingData.ClassType;
    let _lockIdToIgnore = null;

    let _cartlockId = null;

    if (this.data?.isCartEdit) {
      _cartlockId = this.data.SlotLockResult?.Id;
    }

    let lockRequest = {
      ActivityId: ActivityId,
      StartDate: FromDate,
      BookingSize: BookingSize,
      ClassType: classType,
      IsForStandbyReservations:
        this.partyService.sessionBookingData.AllowStandBy,
      lockIdToIgnore:
        _lockIdToIgnore || this.partyService.slotLockId || _cartlockId,
      Sessions: classType == ClassType.Class ? [] : Slots,
      SessionDTOs: this.dashboardFunctions.getExclusiveSession(
        classType,
        Slots
      ),
      SessionGroupId: classType == ClassType.Class ? SessionGroupId : null,
      OverBooked,
      ReservationIdToIgnore:
        this.partyService.sessionBookingData?.BookingId || null,
      AddOns: [],
      ExpireAt: null,
      IsExclusiveBooking: IsExclusiveBooking,
    } as LockSessionRequestDTO;
    this.changeFormState(false);
    if (!bypassLock) {
      this.subscriptions.add(
        this._api
          .lockSessions(
            lockRequest,
            this.partyService.sessionBookingData.AllowStandBy
          )
          .subscribe((response) => {
            let lockResponse = response.Payload;
            this.partyService.lockData = response.Payload;
            this.partyService.slotLockId = lockResponse?.SlotLockIdDTO?.Id;
            this.partyService.slotLockIds = [lockResponse.SlotLockIdDTO?.Id];
            if (this.partyService.addToCartRequestObj?.Sessions) {
              this.partyService.addToCartRequestObj.Sessions["SessionDTOs"] =
                lockResponse?.TableLockedDTO?.SessionDTOs || null;
              this.getAssetDetails(lockResponse?.TableLockedDTO?.SessionDTOs);
            }
            this.invokeRatePlan.next(true);
          })
      );
      //   this.getRatePlanForActivity(this.bookingData);
    } else {
      this.invokeRatePlan.next(true);
      //this.getRatePlanForActivity(this.bookingData);
    }
  }

  getAssetDetails(SessionDTOs) {
    if (
      this.bookingData.IsExclusiveBooking ||
      this.isSessionClass ||
      this.showAsset
    ) {
      let locations = this.cs.layout?.value.FloorPlans?.find(
        (floor) => floor.IsDefault
      )?.StandaloneTables;
      let assetName = "";
      SessionDTOs?.forEach((sessionDTO) => {
        sessionDTO.LocationIds?.forEach((asset, index) => {
          let preferredAsset =
            locations?.find((location) => asset == location.Id)?.Name +
            (sessionDTO.LocationIds?.length - 1 == index ? "" : ",");
          assetName = assetName.concat(preferredAsset);
        });
      });
      this.assetDetails = assetName || null;
    }
  }

  ngOnInit(): void {
    this.promoCodeEligibleProperty = [Utilities.RestaurantId()];
    this.subscriptions.add(
      this.partyService.tabChangeEvent$.subscribe((value) => {
        let components = [
          ComponentTypes.EditActivityBooking,
          ComponentTypes.AddActivityBooking,
          ComponentTypes.AddOpenBooking,
          ComponentTypes.EditOpenBooking,
          ComponentTypes.AddPrivateLessonBooking,
          ComponentTypes.EditPrivateLessonBooking,
        ];

        if (
          value?.index == 2 &&
          components.includes(value?.from) &&
          this.bookingData
        ) {
          this.intialize();
          this.createSaveRequestObject();
          // if (this.dialogData?.componentDetails?.tabs?.componentInput?.addPrivateLessonBooking &&
          //   !this.dialogData?.componentDetails?.tabs?.componentInput?.CartId) {
          //   // && this.dialogData?.componentDetails?.tabs?.componentInput?.isConfirmedReservation
          //   this.ps.tabsActionData[this.ps.tabsActionData.length - 1].gotoNextTab = false;
          // } else {
          this.ps.tabsActionData[
            this.ps.tabsActionData.length - 1
          ].gotoNextTab = true;
          // }
          this.ps.tabsActionData[
            this.ps.tabsActionData.length - 1
          ].showAddToCart =
            this.bookingData.BookingId ||
              this.dialogData?.componentDetails?.tabs?.componentInput?.CartId
              ? false
              : true;
          this.ps.tabsActionData[
            this.ps.tabsActionData.length - 1
          ].actionButtonText = this.dialogData?.componentDetails?.tabs
            ?.componentInput?.CartId
              ? "UpdateCart"
              : this.dialogData?.componentDetails?.tabs?.isEditTab
                ? "Update"
                : "BuyNow";
          this.ps.tabsActionData[
            this.ps.tabsActionData.length - 1
          ].hideSaveAction = this.dialogData?.componentDetails?.tabs
            ?.componentInput?.CartId
              ? false
              : this.cs.isIframeEnabled;
          this.ps.tabsActions$.next(this.ps.tabsActionData);
        }
      })
    );

    // if(this.data && this.data.PaymentMethod != PaymentMethod?.Authorize){
    //   this.data["CreditCards"] = null;
    // }
  }
  setBookingCreditCard(card){
    this.bookingCreditCard = card;
  }
  

  promoCodeApplied(offer) {
    let requestPayload = {
      CartIds: null,
      PromoCode: offer.OfferCode,
      RatePlans: [
        {
          RatePlan: this.partyService.ratePlanObject.RatePlan,
          PropertyId: Utilities.RestaurantId(),
        },
      ],
      PartyId: this.data?.Id || null,
    };

    this.subscriptions.add(
      this._api.calculateDiscount(requestPayload).subscribe((data) => {
        if (data.State == OperationResultState.Success) {
          this.partyService.promoCodeApplied$.next({
            payload: data.Payload.RatePlans[0],
            offer: offer,
          });
        }
      })
    );
  }

  setPaidDetails() {
    if (this.data?.LastPaidBy || this.data?.PaidCard) {
      this.bookingData.LastPaidBy = this.data.LastPaidBy;
      this.bookingData.PaidCard = this.data.PaidCard;
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
