<div id="cust-header-id">
  <app-dialog-header [title]="IsTicketDiscount ? captions.APPLYTICKETDISCOUNT : captions.APPLYDISCOUNT" (closeDialog)="close()"></app-dialog-header>
</div>

<div class="discount-wrapper">
  <form class="discount-popover-body" #dicountForm="ngForm" autocomplete="off" [formGroup]="discountForm">
    <div class="custom-item-details">
      <div class="detail-sec" *ngIf="showItemDetails">
        <div>
          <label class="LW12 pt-2 pb-2">{{captions.ItemName}}</label>
          <p [matTooltip]="itemName">{{itemName}}</p>
        </div>
        <div>
          <label class="LW12 pt-2 pb-2">{{captions.ItemPrice}}</label>
          <p [matTooltip]="itemPrice">{{(itemPrice | Currency)}}</p>
        </div>
        <div *ngIf="!IsTicketDiscount">
          <label class="LW12 pt-2 pb-2">{{captions.lbl_costPrice}}</label>
          <p [matTooltip]="costPrice">{{(costPrice | Currency)}}</p>
        </div>
      </div>
      <div class="toggle-cont" *ngIf="!this.IsTicketDiscount" [ngClass]="!isCostOrCostPlusEligible ? 'ag_section--disable' : ''" [class.alignRight]="!showItemDetails">
        <div class="d-flex">
          <div class="mb-1 ag_display--block">
            <div class="LW12 pt-2 toggle-lbl pb-2">{{captions.lbl_costDiscount}}</div>
          </div>
          <app-retail-toggle-switch [attr.automationId]="'Tog_applyDiscount_cost'"
            (changeToggleEvent)="onCostDiscountTriggered($event)" formControlName="costDiscount" class="toggle-switch">
          </app-retail-toggle-switch>
        </div>
        <div class="d-flex">
          <div class="mb-1 ag_display--block">
            <div class="LW12 toggle-lbl pt-2 pb-2">{{captions.lbl_costPlusDiscount}}</div>
          </div>
          <app-retail-toggle-switch [attr.automationId]="'Tog_applyDiscount_costPlus'"
            (changeToggleEvent)="onCostPlusDiscountTriggered($event)" formControlName="costPlusDiscount"
            class="toggle-switch">
          </app-retail-toggle-switch>
        </div>
      </div>
    </div>   
    <div class="discount-section">
      <div class="ag_form-control--2" [ngClass]="isCostDiscountSelected ? 'ag_section--disable': ''">
        <label class="LW12 pt-2 pb-2">{{captions.Discount}}</label>
        <mat-radio-group [attr.automationId]="'Rdo_shopApplyDiscount_discount'" [disabled]="!customDiscountsAccess" placeholder="Discount Type" class="d-block golf__radio pb-2" formControlName="type" (change)="typeChange($event)">
          <mat-radio-button [value]="1" class="pr-3 golf--form-radio-button">{{captions.PercentageWithoutSymbol}}</mat-radio-button>
          <mat-radio-button [value]="2" class="golf--form-radio-button">{{captions.FlatAmount}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="search-activity">
        <app-simple-search [attr.automationId] = "'Srch_discount'" [placeholder]="captions.SearchBySKU" [value]="selectedText" [disabled]="isCostDiscountSelected || isCostPlusDiscountSelected"
        (searchChange)="searchDiscountByBarCode($event)"></app-simple-search>
      </div>
    </div>
    <div class="discount-popover-content">
     <div class="custom-set-display-fl"  *ngIf="selectedType == 1 && !this.IsTicketDiscount" [ngClass]="isCostDiscountSelected ? 'ag_section--disable': ''">
      <mat-form-field class="d-block pr-3 pt-2 w-50" (click)="checkCustomDiscountControls($event)" [floatLabel]="floatLabel">
        <input RetailCurrencyFormatter [attr.automationId]="'Txt_shopApplyDiscount_percentageChange'" type="text" (blur)="onPercentageChange($event)" matInput formControlName="percentage" [preDecimalLength]="3" [postDecimalLength]="2" placeholder="{{captions.Value}} (%)">
      </mat-form-field>
      <mat-form-field  class="pt-2 w-50 pr-3 w-50" [floatLabel]="floatLabel">
        <mat-label>{{captions.DiscountPercentage}}</mat-label>
        <mat-select  [attr.automationId]="'Dd_shopApplyDiscount_discountPercentage'" formControlName="discType" (selectionChange)="discountTypeSelected($event)" [disabled]="isCostPlusDiscountSelected || discountForm.controls.percentage.value > 0">
          <mat-option></mat-option>
          <mat-option *ngFor="let discount of discountList | discountPipe : true : false" [value]="discount.discountTypeId" 
          matTooltipClass="tooltipClass" matTooltip= "{{discount.discountTypeName}} {{ !this.IsTicketDiscount && this.ApplyDiscountInput.CategoryId == -1 ? '' : (' - ' + discount.discountValue
          + '%')}}">
            {{ getDiscountPercentageCaption(discount) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>
      <div  class="custom-set-display-fl" *ngIf="selectedType == 1  && this.IsTicketDiscount">
       <mat-form-field class="d-block pr-3 pt-2 w-50" (click)="checkCustomDiscountControls($event)" [floatLabel]="floatLabel">
        <input RetailCurrencyFormatter [attr.automationId]="'Txt_shopApplyDiscount_percentageChange'" type="text" (blur)="onPercentageChange($event)" matInput formControlName="percentage" [preDecimalLength]="3" [postDecimalLength]="2" placeholder="{{captions.Value}} (%)">
        <mat-error *ngIf="discountForm.controls.percentage.hasError('required')">{{captions.MissingValue}}</mat-error> 
        <mat-error *ngIf="discountForm.controls.percentage.hasError('max')">{{getErrorMessage()}}</mat-error> 
      </mat-form-field>
      <mat-form-field class="pt-2 w-50 pr-3 w-50" [floatLabel]="floatLabel">
        <mat-label>{{captions.DiscountPercentage}}</mat-label>
        <mat-select [attr.automationId]="'Dd_shopApplyDiscount_discountPercentage'" formControlName="discType" (selectionChange)="discountTypeSelected($event)" [disabled]="validateTicketDiscPercent()">
          <mat-option></mat-option>
          <mat-option *ngFor="let discount of discountList | discountPipe : true : true" [value]="discount.discountTypeId" 
          matTooltipClass="tooltipClass" matTooltip= "{{discount.discountTypeName}} {{ discount.pricing == 1 ? '' : (' - ' + discount.amount + '%')}}">
            {{discount.discountTypeName}}  {{ discount.pricing == 1 ? '' : (' - ' + discount.amount + '%')}}
          </mat-option>
        </mat-select>
      </mat-form-field>
     </div> 
   

    
      <div  class="custom-set-display-fl" *ngIf="selectedType == 2 && !this.IsTicketDiscount" [ngClass]="isCostDiscountSelected ? 'ag_section--disable': ''">
        <mat-form-field class="d-block w-50 pt-2 pr-3" (click)="checkCustomDiscountControls($event)" (blur)="onFlatAmountChange($event)" [floatLabel]="floatLabel">
          <input [attr.automationId]="'Txt_shopApplyDiscount_amount'" type="text" matInput formControlName="amount" placeholder="{{captions.Value}} ({{localization.currencySymbol}})" RetailCurrencyFormatter>
          <mat-error>Missing {{captions.Value}} ({{localization.currencySymbol}})</mat-error>
        </mat-form-field>
        <mat-form-field class="pt-2 w-50 pr-3 w-50" [floatLabel]="floatLabel">
          <mat-label>{{captions.Discount}} ({{localization.currencySymbol}})</mat-label>
          <mat-select [attr.automationId]="'Dd_shopApplyDiscount_discountflat'" formControlName="flatdiscType" (selectionChange)="flatdiscountTypeSelected($event)" [disabled]="isCostPlusDiscountSelected || discountForm.controls.amount.value > 0">
            <mat-option></mat-option>
            <mat-option *ngFor="let discount of discountList | discountPipe : false : false" [value]="discount.discountTypeId" 
            matTooltipClass="tooltipClass" matTooltip= "{{discount.discountTypeName}} {{ this.ApplyDiscountInput.CategoryId == -1 ? '' : (' - ' + discount.discountValue + localization.currencySymbol)}}">
            {{ getFlatDiscountCaption(discount) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="custom-set-display-fl" *ngIf="selectedType == 2 && this.IsTicketDiscount">
        <mat-form-field  class="d-block w-50 pt-2 pr-3"   [floatLabel]="floatLabel">
          <input [attr.automationId]="'Txt_shopApplyDiscount_amount'" type="text" matInput formControlName="amount"  [preDecimalLength]="3" [postDecimalLength]="2" (blur)="onFlatAmountChange($event)" placeholder="{{captions.Value}} ({{localization.currencySymbol}})" RetailCurrencyFormatter>
          <mat-error *ngIf="discountForm.controls.amount.hasError('required')">{{captions.MissingValue}}</mat-error>
          <mat-error *ngIf="discountForm.controls.amount.hasError('max')">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field class="pt-2 w-50 pr-3 w-50" [floatLabel]="floatLabel">
          <mat-label>{{captions.Discount}} ({{localization.currencySymbol}})</mat-label>
          <mat-select [attr.automationId]="'Dd_shopApplyDiscount_discountflat'" formControlName="flatdiscType" (selectionChange)="flatdiscountTypeSelected($event)" [disabled]="validateTicketDiscAmount()">
            <mat-option></mat-option>
            <mat-option *ngFor="let discount of discountList | discountPipe : false : true" [value]="discount.discountTypeId" 
            matTooltipClass="tooltipClass" matTooltip= "{{discount.discountTypeName}} {{ discount.pricing == 1 ? '' : (' - ' + discount.amount  + localization.currencySymbol)}}">
              {{discount.discountTypeName}}  {{ discount.pricing == 1 ? '' : (' - ' + discount.amount  + localization.currencySymbol)}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="custom-set-display-fl">
      <mat-form-field class="d-block pt-2 w-50 pr-3" [floatLabel]="floatLabel">
        <input [attr.automationId]="'Txt_shopApplyDiscount_comment'" type="text" matInput maxlength="50" formControlName="comment" placeholder="{{captions.DiscountComment}}" [required] ="commentRequired">
        <mat-error *ngIf="commentRequired">Missing {{captions.DiscountComment}}</mat-error>
      </mat-form-field>
      <mat-form-field class="pt-2 w-50 pr-3" [floatLabel]="floatLabel">
        <mat-label>{{captions.DiscountReason}} <span> </span></mat-label>
        <mat-select [attr.automationId]="'Dd_shopApplyDiscount_reason'" formControlName="reason" [required] ="reasonRequired">
          <mat-option></mat-option>
          <mat-option *ngFor="let discreason of discountReason | async" [value]="discreason.id">
            {{discreason.description}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="reasonRequired">Missing {{captions.DiscountReason}}</mat-error>
      </mat-form-field>
    </div>
 
  </div>
  
  </form>
</div>
<div class="discount-popover-footer pt-1">
  <button mat-button [attr.automationId]="'Btn_shopApplyDiscount_apply'" class="" [disabled]="!isFormValid()"
    [ngClass]="!isFormValid() ? 'button--disabled' : 'button--primary-save'"
    (click)="apply()">{{captions.Apply}}</button>
        <button mat-button [attr.automationId]="'Btn_shopApplyDiscount_cancel'" (click)="close()" class="text-capital ml-2">{{captions.Cancel}}</button>
</div>

