import { Pipe, PipeTransform } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';

@Pipe({
  name: 'currencyDecimalFormatter'
})
export class CurrencyDecimalFormater implements PipeTransform {

  /**
   *
   */
  constructor(private CacheService: CacheService) {}
  
  transform(number: number): any {
    if(isNaN(number)){
      number = 0;
    }
   return Number(number).toFixed(this.CacheService.getNumberOfFractionalDigits())
  }
}