import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[LimitExceed]'
})
export class LimitExceedDirective {
  previousValue: any;

  constructor(public el: ElementRef) { }
 
  @Input() numUpto;
  @HostListener('input', ['$event']) keydown($event: KeyboardEvent) {
    let topNum = this.numUpto ? this.numUpto : 100 ;

    let data = this.el.nativeElement.value;
    if(parseInt(data) < 0  || parseInt(data) > topNum) {
      // If it does, revert to the previous valid value
      this.el.nativeElement.value = this.previousValue;
    } else {
      // Otherwise, update the previous value
      this.previousValue = this.el.nativeElement.value;
    }
  }


}
