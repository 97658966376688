import { Component, Input, ViewEncapsulation, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { MatTabChangeEvent } from '@angular/material/tabs';
import * as _ from 'lodash'; // STORAGE THE BACK ARRAY
// import { ShopDialogPopUp } from '../shop-dialog-popup/shop-dialog-popup.component';
import { CommonVariablesService, SelectedProducts } from '../../shared/service/common-variables.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { Host, ButtonType, ButtonOptions, Product } from '../../shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { BaseResponse, ShopItem } from '../../shared/business/shared.modals';
import { GroupedRetailItemForShop, ItemForShop, shopPricePrompt } from '../shop.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
// import { DialogOverviewExampleDialog } from '../../shared/dialog-popup/dialogPopup-componenet';
import { ReplaySubject } from 'rxjs';
// import { ReceiptBusinessService } from '../receipt/business/receipt-business.service';
import { takeUntil } from 'rxjs/operators';
// import { RetailValidationService } from '../../shared/retail.validation.service';
// import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { GiftCardBusiness } from '../../sytem-config/gift-cards/gift-cards.business';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';
import { menuTypes, RetailItemType } from '../../retail.modals';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import * as GlobalConst from '../../shared/globalsContant';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';

@Component({
  selector: 'shop-categories',
  templateUrl: './view-categories.component.html',
  styleUrls: ['./view-categories.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ViewCategoriesComponenet implements OnDestroy {
  // enableNGXScrollBar: boolean = false; 
  AllShopItems: ShopItem[] = [];
  products: GroupedRetailItemForShop[] = [];
  RetailProducts: any[] = [];
  ShopitemsDetails: any;
  isOverflown: boolean = false;
  ShopFormGrp: UntypedFormGroup;
  item: UntypedFormArray;
  selectedTabIndex: number = 0;
  transactions: boolean = true;
  quickSaleItems: any = [];
  packagedItemIds: number[] = [];
  packagedItem: ShopItem[] = [];
  packagedItemCount: number;
  packagedItemIndex: number = 0;
  packagedItemPrice: number;
  selectedParentItem: ShopItem[] = [];
  noOfButtons: number;
  noOfProducts: any[] = [];
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  eventChangebuy: any;
  ProductGolfId: Product;
  currentProductId: number;
  useRetailInteface: boolean;
  menuList: any;
  stayId: number;
  menuType = menuTypes;
  constructor(
    public dialog: MatDialog,
    public _shopservice: CommonVariablesService,
    private Form: UntypedFormBuilder,
    private http: HttpServiceCall,
    public route: ActivatedRoute,
    public localization: RetailLocalization,
    public propertyInformation: RetailPropertyInformation,
    public giftcardService: GiftCardBusiness,
    private routeDataService: RouteLoaderService,
    private utils: RetailUtilities,
    private _dmConfig: DMConfigDataService
  ) {

    this.useRetailInteface = this.propertyInformation.UseRetailInterface;
    this._shopservice.UpdateGiftTabStatus.pipe(takeUntil(this.$destroyed)).subscribe(x => this.UpdateGiftCardTabState());
    route.queryParams.pipe(takeUntil(this.$destroyed)).subscribe(val => {
      if (val) {
        this.stayId = val?.stayId;
      }
    });
  }

  categorySelected = [];
  @Input()
  selected = 1;
  numbers: any[] = [1, 2, 3, 4, 5];
  viewmore: any = [];
  noofproducts: any = [];
  swiperDisabled: boolean = false;
  product: any;
  categories: any[] = []

  ngOnInit() {
    this.UpdateGiftCardTabState();
    //DMConfig API might take some time, so invoking the method to refresh the menu
    this._dmConfig.DMConfigNotifier.subscribe(x => { this.UpdateGiftCardTabState(); });
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  UpdateGiftCardTabState() {
    const value = this.routeDataService.GetChildMenu('/shop/viewshop');
    value.linkedElement.map(res => {
      if (res && res.routePath === '/shop/viewshop/giftcards') {
        res.visibility = this.stayId ? false : (!this.propertyInformation.UseRetailInterface && this.propertyInformation.UseGiftCardInterface);
      }
      if (res && res.routePath === '/shop/viewshop/retailtransactions') {
        res.visibility = !this.stayId;
        res.linkedElement.map(result => {
          if (result && result.routePath === '/shop/viewshop/retailtransactions/revenuepostingslog') {
            result.visibility = (!this.propertyInformation.UseRetailInterface && this.propertyInformation.HasRevenuePostingEnabled);
          }
          if (result && result.routePath === '/shop/viewshop/retailtransactions/transactionslog') {
            result.visibility = (!this.propertyInformation.UseRetailInterface);
          }
          if(result && result.routePath ==="/shop/viewshop/retailtransactions/datamaginereceiptlog"){
            result.visibility=(this.propertyInformation.IsDMPostingEnabled);
          }
          if(result && result.routePath === "/shop/viewshop/retailtransactions/refundLog"){
            result.visibility = (this.propertyInformation.SkipPMAgent);
          }
          if(result && result.routePath === "/shop/viewshop/retailtransactions/nonintegratedpaymentlog"){
            result.visibility = (this.propertyInformation.IsNonIntegratedCreditCard);
          }
        });
      }
    });
    let linkedElement = this.updateMenuByProductId(cloneDeep(value.linkedElement));
    this.menuList = {
      menu: linkedElement,
      menuType: value.linkedElement[0].menuAlignment
    };
  }

  async ngAfterViewInit() {
    setTimeout(() => { this.checkServiceGrpWidth(); }, 500);
  }


  checkServiceGrpWidth() {
    let servicegrpdiv = document.getElementsByClassName('items-collapse');
    if (servicegrpdiv.length > 0) {
      let servicegrpWidth = servicegrpdiv[0]['offsetWidth'];
      let buttonWidth = 153;
      this.noOfButtons = Math.floor(servicegrpWidth / buttonWidth);
      this.RetailProducts.forEach((res, i) => this.noOfProducts[i] = this.noOfButtons);
    }
  }

  async getRetailItemsDetailedInfoByIds(retailItemIds: any[]) {
    const result = await this.http.CallApiAsync<any>({
      host: Host.retailManagement,
      callDesc: "GetRetailItemDetailedInfoList",
      method: HttpMethod.Put,
      body: retailItemIds
    });

    return result.result;
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == 'GetShopItems') {

      this.AllShopItems = result.result ? <any>result.result : [];
      this._shopservice.AllShopItems = _.cloneDeep(this.AllShopItems);
      let isrevenueitemincluded = this._shopservice.selectedProducts.some(x => x.ItemType && x.ItemType == RetailItemType.RevenueItem);
      const fixedDescItemTypes = [
        RetailItemType.RevenueItem
        , RetailItemType.Deposit
        , RetailItemType.SpaPackage
        , RetailItemType.SpaServices
        , RetailItemType.AppointmentAddon
      ];
      this._shopservice.selectedProducts.forEach(x => {
        if (!x.MultiPack) {
          let item = this.AllShopItems.filter(a => { return a.id == x.ItemId });
          if (item && item.length > 0) {
            let allowdescriptionchange = (isrevenueitemincluded && item[0].retailItemDetail.itemType == RetailItemType.RevenueItem || item[0].retailItemDetail.itemType == RetailItemType.Deposit)
              || (fixedDescItemTypes.includes(item[0].retailItemDetail.itemType));
            x.ExternalPOSItemId = item[0].retailItemDetail.externalPOSId;
            x.ItemDescription = allowdescriptionchange ? x.ItemDescription : item[0].retailItemDetail.itemDescription;
            x.ProductName = allowdescriptionchange ? x.ItemDescription : item[0].retailItemDetail.itemDescription;
          }
        }
      });
    }

    if (callDesc == 'AllQuickSale') {

      let quickSaleCategories = result.result ? <any>result.result : {};
      this.categories.push({
        "id": 0,
        "name": this.localization.captions.shop.AllCategories
      });
      for (var quickCategory of quickSaleCategories.category) {
        this.categories.push({
          "id": quickCategory.id,
          "name": quickCategory.quickSaleCategory
        });

        this.products.push({
          id: quickCategory.id,
          name: quickCategory.quickSaleCategory,
          data: []
        });
      }

      this.products.push({
        id: -1,
        name: this.localization.captions.shop.Others,
        hidden: true,
        data: []
      });

      this.quickSaleItems = quickSaleCategories.items ? quickSaleCategories.items : [];
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }
  private updateMenuByProductId(linkedElement: any) {
    let productId = Number(this.utils.GetPropertyInfo('ProductId'));
    if (productId == GlobalConst.Product.GOLF) {
      let golfTeeTimeSetting = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
      let isCheckInCheckOutEnabled = golfTeeTimeSetting && golfTeeTimeSetting != null && golfTeeTimeSetting.requireCheckInCheckOut;
      if (isCheckInCheckOutEnabled) {
        linkedElement.map(x => {
          if (x.elementID == GlobalConst.Menu.unPaidPlayerElementId) {
            x.text = this.localization.captions.shop?.CheckedInUnpaidMenu;
          }
          return x;
        })
      }
    }
    return linkedElement;
  }
}



