<section class="miscSection miscellaneous-wrapper newVersionWrapper">
  <div class="formMargin box-style">
    <form [formGroup]="miscellaneousFormGrp">
      <div class="ag_display--flex">
      <div *ngIf="!isSNC"class="ag_w--33 ag_pr--8">
        <label class="form-label-normal">{{captions.AllowDateToFuture}}</label>
        <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_allowFutureDate'" class="d-block mt-2" formControlName="allowDateToFutureToggle"></app-retail-toggle-switch>
      </div>
      <div  *ngIf="!this.isRetailStandalone">
        <label class="form-label-normal">{{captions.ActivateRetailInterface}}</label>
        <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_retailInteface'" class="d-block mt-2" formControlName="retailInterfaceToggle" (changeToggleEvent)="onRetailInterfaceChanged($event)"></app-retail-toggle-switch>
      </div>
    </div>
      <div class="ag_display--flex">
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="isEmbededRetail">
          <label class="form-label-normal">{{captions.ExcludeDiscountOnServiceCharge}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_discountService'" class="d-block mt-2" formControlName="discountOnServiceChargeToggle">
          </app-retail-toggle-switch>
        </div>
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="isEmbededRetail">
          <label class="form-label-normal">{{captions.ExcludeDiscountOnGratuity}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_discountGratuity'" class="d-block mt-2" formControlName="discountOnGratuityToggle">
          </app-retail-toggle-switch>
        </div>
      </div>
      <!-- <div class="ag_display--flex">
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="isEmbededRetail">
          <label class="form-label-normal">{{captions.AllowTaxOnServiceCharge}}</label>
          <app-retail-toggle-switch class="d-block mt-2" formControlName="allowTaxOnServiceChargeToggle">
          </app-retail-toggle-switch>
        </div>
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="isEmbededRetail">
          <label class="form-label-normal">{{captions.AllowTaxOnGratuity}}</label>
          <app-retail-toggle-switch class="d-block mt-2" formControlName="allowTaxOnGratuityToggle">
          </app-retail-toggle-switch>
        </div>
      </div> -->
      <div class="ag_display--flex" *ngIf="isEmbededRetail">
        <div class="pad-top-10 ag_w--33 ag_pr--8">
          <label class="form-label-normal">{{captions.AllowReturnstoOriginalSettlement}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_returnOriginalPayment'" class="d-block mt-2" formControlName="returnOriginalPaymentOnly">
          </app-retail-toggle-switch>
        </div>
        <div class="ag_display--flex">
          <div class="pad-top-10 ag_w--33 ag_pr--8">
            <label class="form-label-normal">{{captions.DefaultRefundToOriginalSettlement}}</label>
            <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_defaultRefundOriginal'" class="d-block mt-2" formControlName="defaultRefundToOriginalSettlement">
            </app-retail-toggle-switch>
          </div>
        </div>
      </div>
      <div class="ag_display--flex" *ngIf="isEmbededRetail">
        <div class="pad-top-10 ag_w--33">
          <label class="form-label-normal">{{captions.DiscountCommentsRequired}}</label>
          <div class="ag_display--flex">
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_discountComments'" class="mr-5" (changeToggleEvent)="discCommentsChange($event)"
            formControlName="discountcommentsrequired"></app-retail-toggle-switch>
          <mat-radio-group [attr.automationId]="'Rdo_miscellaneous_discountComments'" class="aside-filter-radiogroup" formControlName="discountCommentsRadio"
            *ngIf="miscellaneousFormGrp.controls['discountcommentsrequired'].value">
            <mat-radio-button class="ag_form--radio-button" *ngFor="let option of radioOptions;" [value]="option.id"
              (change)="discCommentsChange($event)">
              {{option.value}}
            </mat-radio-button>
          </mat-radio-group>
          </div>
        </div>
        <div class="pad-top-10">
          <label class="form-label-normal">{{captions.EnableCheckZoom}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_enableCheckZoom'" class="d-block mt-2" formControlName="enableCheckZoom"></app-retail-toggle-switch>
        </div>
      </div>
      <div class="pad-top-10" *ngIf="isEmbededRetail">
        <label class="form-label-normal">{{captions.DiscountReasonRequired}}</label>
        <div class="ag_display--flex">
        <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_discountReason'" class="mr-5" (changeToggleEvent)="discReasonChange($event)"
          formControlName="discountreasonrequired"></app-retail-toggle-switch>
        <mat-radio-group class="aside-filter-radiogroup" formControlName="discountReasonRadio"
          *ngIf="miscellaneousFormGrp.controls['discountreasonrequired'].value">
          <mat-radio-button [attr.automationId]="'Rdo_miscellaneous_discountReason'" class="ag_form--radio-button" *ngFor="let option of radioOptions;" [value]="option.id"
            (change)="discReasonChange($event)">
            {{option.value}}
          </mat-radio-button>
        </mat-radio-group></div>
      </div>
      <div *ngIf="isEmbededRetail">
        <div class="pad-top-10">
          <label class="form-label-normal">{{roomKeySwipeCaptions.EnableRoomKeySwipe}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_enableKeySwipe'" class="d-block mt-2" formControlName="enableRoomKeySwipeWithMSR"
            (changeToggleEvent)="roomKeySwipeToggleChange($event)"></app-retail-toggle-switch>
        </div>
        <ng-container *ngIf="miscellaneousFormGrp.controls['enableRoomKeySwipeWithMSR'].value">
          <div class="ag_display--flex">
            <div class="pad-top-10 ag_pr--8">
              <label class="form-label-normal">{{roomKeySwipeCaptions.CardSourceType}}</label>
              <mat-radio-group [attr.automationId]="'Rdo_miscellaneous_roomKeyCard'" class="aside-filter-radiogroup" formControlName="roomKeyCardSourceType">
                <mat-radio-button class="ag_form--radio-button" *ngFor="let option of cardSourceTypes;"
                  [value]="option.id" (change)="cardSourceTypeChanged($event)">
                  {{option.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <mat-form-field [floatLabel]="floatLabel" class="pt-1 ag_pr--8 position-input">
              <input type="number" [attr.automationId]="'Txt_miscellaneous_cardDataStart'" inputtype="nonnegative" (change)="startPosChange($event)" matInput
                [placeholder]="roomKeySwipeCaptions.DataReadStartPosition" formControlName="cardDataReadStartPosition">
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabel" class="pt-1 ag_pr--8 position-input">
              <input type="number" [attr.automationId]="'Txt_miscellaneous_cardDataEnd'" inputtype="nonnegative" matInput (change)="endPosChange($event)"
                [placeholder]="roomKeySwipeCaptions.DataReadEndPosition" max="300"
                formControlName="cardDataReadEndPosition">
            </mat-form-field>
          </div>
        </ng-container>
      </div>
      <div class="ag_display--flex" [ngClass]="{'pad-top-10': !isEmbededRetail || !miscellaneousFormGrp.controls['enableRoomKeySwipeWithMSR'].value}">
        <div class="ag_w--33 ag_pr--8">
          <label class="form-label-normal">{{captions.Confirmationdialog}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_confirmation'" class="d-block mt-2" formControlName="enableRetailConfirmationDialog">
          </app-retail-toggle-switch>
        </div>
        <div  *ngIf="isEmbededRetail">
          <label class="form-label-normal">{{captions.ExpandPaymentMethods}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_expandPayment'" class="d-block mt-2" formControlName="expandPaymentMethodsToggle">
          </app-retail-toggle-switch>
        </div>
      </div>

      <div class="ag_display--flex pad-top-10">
        <div class="ag_w--33 ag_pr--8">
          <label class="form-label-normal">{{captions.EnableTransactionByMachine}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_transByMachineName'" class="d-block mt-2" formControlName="enableTransactionByMachineName"
            (changeToggleEvent)="toggleChange($event)"></app-retail-toggle-switch>
        </div>
        <div class="ag_w--33 ag_pr--8" *ngIf="enableLoginSelectionvisible">
          <label class="form-label-normal">{{captions.PromptForSelectionOnLogin}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_selectionOnLogin'" class="d-block mt-2" formControlName="enableSelectionOnLoginIn">
          </app-retail-toggle-switch>
        </div>
      </div>

      <div class="ag_display--flex pad-top-10">
        <div class="ag_w--33 ag_pr--8">
          <mat-form-field   class="w-100" [floatLabel]="floatLabel">
            <input [attr.automationId]="'Txt_miscellaneous_pmsProxyEndPoint'" type="text" matInput [placeholder]="captions.PMSProxyEndPoint" formControlName="pmsproxyendpoint">
          </mat-form-field>
        </div>

        <div class="printerManagerURI">
          <mat-form-field class="w-100" [floatLabel]="floatLabel">
              <input [attr.automationId]="'Txt_miscellaneous_printerManagerUri'"  matInput type="text" autocomplete="off" [placeholder]="captions.PrinterManagerURI" formControlName="printerManagerURI">
          </mat-form-field>
       </div>
      </div>
      <div class="ag_display--flex">
        <div class="ag_w--33 ag_pr--8">
          <mat-form-field class="mt-2 w-100" [floatLabel]="floatLabel">
            <input [attr.automationId]="'Txt_miscellaneous_itemCount'" type="text" matInput [placeholder]="captions.DefaultSearchRecordsInShop" formControlName="itemCount"
              inputtype="onlynumber" [maxlength]="3" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
            <mat-error *ngIf="miscellaneousFormGrp.controls['itemCount'].value <= 0">
              {{captions.ValueShouldBeGreaterThanZero}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="pad-top-10" *ngIf="isEmbededRetail && showRevenuePostings">
          <label class="form-label-normal">{{captions.EnableRevenuePostingsOnDayEnd}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_revenuePositingEnd'" class="d-block mt-2" formControlName="enableRevenuePostingsOnDayEnd"></app-retail-toggle-switch>
        </div>
      </div>
      <div class="ag_display--flex">
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="isEmbededRetail">
          <label class="form-label-normal">{{captions.CashPopUpZeroDollar}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_cashPopUpZeroDollar'" class="d-block mt-2" formControlName="cashPopUpZeroDollarPopulateToggle">
          </app-retail-toggle-switch>
        </div>
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="isEmbededRetail">
          <label class="form-label-normal">{{captions.DoNotDisplayItemName}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_dontDispplayItemName'" class="d-block mt-2" formControlName="ItemNameDeletePopulateToggle">
          </app-retail-toggle-switch>
        </div>
      </div>
      <div class="ag_display--flex">
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="isEmbededRetail && isSpaProduct">
          <label class="form-label-normal">{{captions.RequestPresettlementReceiptOnPendingSettlement}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_requestPresSettlement'" class="d-block mt-2" formControlName="RequestPresettlementReceiptOnPendingSettlementToggle">
          </app-retail-toggle-switch>
        </div>
        <div class="pad-top-10 ag_w--33 ag_pr--8">
          <label class="form-label-normal">{{captions.DisplayImagesInShop}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_displayImagesInShop'" class="d-block mt-2" formControlName="displayImagesInShop">
          </app-retail-toggle-switch>
        </div>
      </div>
      <div class="ag_display--flex">
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="isEmbededRetail">
          <label class="form-label-normal">{{captions.AllowPaymentSurcharges}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_allowPaymentSurcharge'" class="d-block mt-2" formControlName="AllowPaymentSurchargesToggle">
          </app-retail-toggle-switch>
        </div>
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="isEmbededRetail">
          <label class="form-label-normal">{{captions.EnableAutoRetailTransactionLogs}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_autoRetailTransaction'" class="d-block mt-2" formControlName="EnableAutoRetailTransactionLogsToggle">
          </app-retail-toggle-switch>
        </div>
      </div>
      <div class="ag_display--flex">
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="isEmbededRetail">
          <label class="form-label-normal">{{captions.EnableAutoCloseTransaction}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_autoCloseTransaction'" class="d-block mt-2" formControlName="enableAutoCloseTransaction">
          </app-retail-toggle-switch>
        </div>
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="isRevenuePostingEnabled">
          <label class="form-label-normal">{{captions.PostRevenueOnPropertyDate}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_revenuePostRevenueOnDate'" class="d-block mt-2" formControlName="enablePostRevenueOnPropertyDate">
          </app-retail-toggle-switch>
        </div>
      </div>
      <div class="ag_display--flex">
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="isEmbededRetail">
          <label class="form-label-normal">{{captions.ValidatePayAgentAndSettlerOnRefund}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_validatePayAgentOnRefund'" class="d-block mt-2" formControlName="validatePayAgentAndSettlerOnRefund">
          </app-retail-toggle-switch>
        </div>
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="showExtendedProfileSearchByDefault">
          <label class="form-label-normal">{{captions.platformGuestSearch}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_enableExtendedProfileSearchByDefault'"
            class="d-block mt-2" formControlName="enableExtendedProfileSearchByDefault">
          </app-retail-toggle-switch>
        </div>
      </div>
      <div class="ag_display--flex pt-2 gratuityCheck">
        <div class="ag_w--33 ag_display--flex align-items-baseline">
          <app-ag-percentage [inputs]="InhouseGratuityConfig"></app-ag-percentage>
          <span class="pl-2">%</span>
        </div>
        <div class="ag_w--33 ag_display--flex align-items-baseline">
          <app-ag-percentage [inputs]="InhouseServiceChargeConfig"></app-ag-percentage>
          <span class="pl-2">%</span>
        </div>
      </div>
      <div class="ag_display--flex pad-top-10 pt-2 glPostings">
        <div class="ag_w--33 ag_display--flex ag_pr--8 align-items-baseline" *ngIf="isEmbededRetail && showGLPostings">
          <mat-form-field [floatLabel]="floatLabel">
            <input [attr.automationId]="'Txt_miscellaneous_businessUnit'" type="text" matInput [placeholder]="captions.BusinessUnit" maxlength="5" formControlName="businessUnit">
          </mat-form-field>
        </div>

        <div class="ag_w--33 ag_display--flex align-items-baseline" *ngIf="isEmbededRetail && showGLPostings">
          <mat-form-field [floatLabel]="floatLabel">
              <input [attr.automationId]="'Txt_miscellaneous_operatingUnit'"  matInput type="text" autocomplete="off" [placeholder]="captions.OperatingUnit" maxlength="6" formControlName="operatingUnit">
          </mat-form-field>
       </div>
       </div>
       <div class="ag_display--flex">
        <div class="pad-top-10 ag_w--33 ag_pr--8">
          <label class="form-label-normal">{{captions.RequireReturnReason}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_requireReturnReason'" class="d-block mt-2" formControlName="requireReturnReason">
          </app-retail-toggle-switch>
        </div>
        <div class="pad-top-10 ag_w--33 ag_pr--8">
          <label class="form-label-normal">{{captions.lbl_excludeDiscountOnTax}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_lbl_excludeDiscountOnTax'" class="d-block mt-2" formControlName="excludeDiscountOnTax">
          </app-retail-toggle-switch>
        </div>
      </div>
      <div class="ag_display--flex">
        <div class="pad-top-10 ag_w--33 ag_pr--8" *ngIf="this.showRentalsRetailItemType && this.isRentalEnabledProperty">
          <label class="form-label-normal">{{captions.AllowQuantitySplitOfRentalsAcrossOutlets}}</label>
          <app-retail-toggle-switch [attr.automationId]="'Tog_miscellaneous_isRentalAtOutletLevel'" class="d-block mt-2" formControlName="isRentalAtOutletLevel">
          </app-retail-toggle-switch>
        </div>
      </div>
    </form>
  </div>

  <div class="misc-actions">
    <div>
      <button [attr.automationId]="'Btn_miscellaneous_save'" class="LW14 save" mat-button (click)="save()" [ngClass]="(enableSave && miscellaneousFormGrp.valid ) ? 'spa-primary-default-save' : 'spa-primary-disabled'">{{captions.save}}</button>
      <a [attr.automationId]="'Lbl_miscellaneous_cancel'" class="LW14 bl text-default-color" (click)="cancel()" [ngClass]="{'disabled': isDisabled}">{{captions.cancel}}</a>
    </div>
  </div>

</section>
