import { ReportSelector } from "./report.modals";
import { ReportBreakPoint, Product, JasperReportUrlPath } from "../../shared/globalsContant";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailFeatureFlagInformationService } from "../../shared/service/retail.feature.flag.information.service";
import { RetailPropertyInformation } from "../../common/services/retail-property-information.service";

export class ReportControlBuilder {

    private productId: number;
    constructor(private localize: RetailLocalization, private utilities: RetailUtilities, 
        private featureFlag: RetailFeatureFlagInformationService, private propertyInfo : RetailPropertyInformation) {
        this.productId = parseInt(this.utilities.GetPropertyInfo('ProductId'));
    }
    get reportSelector(): ReportSelector[] {
        return this._reporsSelector.filter(r => r.allowedProducts.includes(this.productId));
    }
    public getAllReportSelections():ReportSelector[]{
		return this._reporsSelector;
	}

    private captions: any = this.localize.captions.reports;


    public get _reporsSelector(): ReportSelector[] {
        let returnArray = [
            {
                group: "POS",
                code: "CommissionReport",
                value: this.captions.CommissionReport,
                breakPointNumber: ReportBreakPoint.CommissionReport,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: "POS",
                code: "Gratuity",
                value: this.captions.GratuityReport,
                breakPointNumber: ReportBreakPoint.GratuityReport,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: "POS",
                code: "ServiceCharge",
                value: this.captions.ServiceChargeReport,
                breakPointNumber: ReportBreakPoint.ServiceChargeReport,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: "POS",
                code: "StaffMemberSummary",
                value: this.captions.StaffMemberSummaryReport,
                breakPointNumber: ReportBreakPoint.StaffMemberSummaryReport,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.SNC, Product.RETAIL, Product.PMS],
                enableJasperReports: true,
                reportUrlpath: JasperReportUrlPath.StaffSummaryReport
            },
            {
                group: 'RETAIL',
                code: 'MultiPackItems',
                value: this.captions.MultiPackItemsReport,
                breakPointNumber: ReportBreakPoint.MultiPackItems,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.SNC, Product.RETAIL, Product.GOLF]
            },
            {
                group: 'RETAIL',
                code: 'CorrectVoid',
                value: this.captions.CorrectVoidReport,
                breakPointNumber: ReportBreakPoint.CorrectVoid,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS],
                enableJasperReports: true,
                reportUrlpath: JasperReportUrlPath.CorrectVoid
            },
            {
                group: 'RETAIL',
                code: 'ReturnedItems',
                value: this.captions.ReturnedItemsReport,
                breakPointNumber: ReportBreakPoint.ReturnedItems,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'RETAIL',
                code: 'RevenueSummary',
                value: this.captions.RevenueSummaryReport,
                breakPointNumber: ReportBreakPoint.RevenueSummary,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'RETAIL',
                code: 'SalesByCategory',
                value: this.captions.SalesByCategoryReport,
                breakPointNumber: ReportBreakPoint.SaleByCategory,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS],
                enableJasperReports: true,
                reportUrlpath: JasperReportUrlPath.SalesByCategory
            },
            {
                group: 'RETAIL',
                code: 'SalesByDiscountTypes',
                value: this.captions.SalesByDiscountTypeReport,
                breakPointNumber: ReportBreakPoint.SaleByDiscountType,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'RETAIL',
                code: 'SalesBySubCategory',
                value: this.captions.SalesBySubCategoryReport,
                breakPointNumber: ReportBreakPoint.SaleBySubCategory,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'RETAIL',
                code: 'SaleByItem',
                value: this.captions.SaleByItemReport,
                breakPointNumber: ReportBreakPoint.SaleByItem,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'RETAIL',
                code: 'SalesByVendor',
                value: this.captions.SalesByVendorReport,
                breakPointNumber: ReportBreakPoint.SalesByVendor,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'RETAIL',
                code: 'SalesMix',
                value: this.captions.SalesMix,
                breakPointNumber: ReportBreakPoint.SalesMix,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'REVENUE',
                code: 'SalesMix',
                value: this.captions.SalesMix,
                breakPointNumber: ReportBreakPoint.SalesMix,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'RETAIL',
                code: 'Shift',
                value: this.captions.ShiftReport,
                breakPointNumber: ReportBreakPoint.Shift,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'GIFTCARDS',
                code: 'GiftCardsIssued',
                value: this.captions.GiftCardIssued,
                breakPointNumber: ReportBreakPoint.GiftCardIssued,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'GIFTCARDS',
                code: 'GiftCardsRedeem',
                value: this.captions.GiftCardRedeem,
                breakPointNumber: ReportBreakPoint.GiftCardRedeem,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'REVENUE',
                code: 'Shift',
                value: this.captions.ShiftReport,
                breakPointNumber: ReportBreakPoint.Shift,
                IsAuthorized: true,
                allowedProducts: [Product.SNC]
            },
            {
                group: 'RETAIL',
                code: 'TaxExemptSales',
                value: this.captions.TaxExemptSales,
                breakPointNumber: ReportBreakPoint.TaxExemptSales,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'RETAIL',
                code: 'TopSpenders',
                value: this.captions.TopSpendersReport,
                breakPointNumber: ReportBreakPoint.TopSpenders,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'RETAIL',
                code: 'Transaction',
                value: this.captions.TransactionReport,
                breakPointNumber: ReportBreakPoint.Transaction,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'REVENUE',
                code: 'Transaction',
                value: this.captions.TransactionReport,
                breakPointNumber: ReportBreakPoint.Transaction,
                IsAuthorized: true,
                allowedProducts: [Product.SNC]
            },
            {
                group: 'REVENUE',
                code: 'TransactionDetail',
                value: this.captions.TransactionDetail,
                breakPointNumber: ReportBreakPoint.TransactionDetail,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },

            {
                group: 'REVENUE',
                code: 'SaleByItem',
                value: this.captions.SaleByItemReport,
                breakPointNumber: ReportBreakPoint.SaleByItem,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'REVENUE',
                code: 'SalesByCategory',
                value: this.captions.SalesByCategoryReport,
                breakPointNumber: ReportBreakPoint.SaleByCategory,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'REVENUE',
                code: 'SalesByDiscountTypes',
                value: this.captions.SalesByDiscountTypeReport,
                breakPointNumber: ReportBreakPoint.SaleByDiscountType,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'REVENUE',
                code: 'ItemsExtract',
                value: this.captions.ItemsExtract,
                breakPointNumber: ReportBreakPoint.ItemsExtract,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'RETAIL',
                code: 'TransactionDetail',
                value: this.captions.TransactionDetail,
                breakPointNumber: ReportBreakPoint.TransactionDetail,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'REVENUE',
                code: 'TaxExemptSales',
                value: this.captions.TaxExemptSales,
                breakPointNumber: ReportBreakPoint.TaxExemptSales,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'RETAIL',
                code: 'ItemsExtract',
                value: this.captions.ItemsExtract,
                breakPointNumber: ReportBreakPoint.ItemsExtract,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            },
            {
                group: 'RETAIL',
                code: 'InventoryAvailabilitySummary',
                value: this.captions.InventoryAvailability,
                breakPointNumber: ReportBreakPoint.InventoryAvailability,//need to implement
                IsAuthorized: true,
                allowedProducts: [Product.PMS]
            }
        ]

        if (!this.propertyInfo.IsEatecAsMaster) {
            const inventoryControlReports = [
                {
                    group: 'INVENTORYCONTROL',
                    code: 'PurchaseOrders',
                    value: this.captions.PurchaseOrders,
                    breakPointNumber: ReportBreakPoint.PurchaseOrders,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
                },
                {
                    group: 'INVENTORYCONTROL',
                    code: 'Receiving',
                    value: this.captions.Receiving,
                    breakPointNumber: ReportBreakPoint.Receiving,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
                },
                {
                    group: 'INVENTORYCONTROL',
                    code: 'InventoryVariance',
                    value: this.captions.InventoryVariance,
                    breakPointNumber: ReportBreakPoint.InventoryVariance,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
                },
                {
                    group: 'INVENTORYCONTROL',
                    code: 'InventoryValuation',
                    value: this.captions.InventoryValuation,
                    breakPointNumber: ReportBreakPoint.InventoryValuation,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
                },
                {
                    group: 'INVENTORYCONTROL',
                    code: 'InventoryValueAndActivity',
                    value: this.captions.InventoryValueandActivity,
                    breakPointNumber: ReportBreakPoint.InventoryValueandActivity,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
                }
            ];
            returnArray = [...returnArray, ...inventoryControlReports];
        }
        if(!this.propertyInfo.IsEatecAsMaster) {
            const inventoryReports = [
                {
                    group: 'RETAIL',
                    code: 'InventoryOnHand',
                    value: this.captions.InventoryOnHandReport,
                    breakPointNumber: ReportBreakPoint.InventoryOnHand,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS],
                    enableJasperReports: true,
                    reportUrlpath: JasperReportUrlPath.InventoryOnHand
                },
                {
                    group: 'RETAIL',
                    code: 'InventoryAudit',
                    value: this.captions.InventoryAuditReport,
                    breakPointNumber: ReportBreakPoint.InventoryAudit,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
                },
                {
                    group: 'RETAIL',
                    code: 'InventoryDetails',
                    value: this.captions.InventoryDetailsReport,
                    breakPointNumber: ReportBreakPoint.InventoryDetail,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
                },
                {
                    group: 'RETAIL',
                    code: 'InventoryPickupDelivery',
                    value: this.captions.InventoryPickupDelivery,
                    breakPointNumber: ReportBreakPoint.InventoryPickupDelivery,
                    IsAuthorized: true,
                    allowedProducts: [Product.PMS]
                },
                {
                    group: 'RETAIL',
                    code: 'InventoryReport',
                    value: this.captions.InventoryReport,
                    breakPointNumber: ReportBreakPoint.InventoryReport,
                    IsAuthorized: true,
                    allowedProducts: [Product.PMS]
                },
                {
                    group: 'RETAIL',
                    code: 'InventorySummary',
                    value: this.captions.InventorySummaryReport,
                    breakPointNumber: ReportBreakPoint.InventorySummary,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
                },
                {
                    group: 'RETAIL',
                    code: 'InventorySummaryVendor',
                    value: this.captions.InventorySummaryByVendorReport,
                    breakPointNumber: ReportBreakPoint.InventorySummaryByVendor,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
                },
                {
                    group: 'RETAIL',
                    code: 'InventoryTransfer',
                    value: this.captions.InventoryTransferReport,
                    breakPointNumber: ReportBreakPoint.InventoryTransfer,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL]
                },
                {
                    group: 'RETAIL',
                    code: 'InventoryWash',
                    value: this.captions.InventoryWashReport,
                    breakPointNumber: ReportBreakPoint.InventoryWash,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
                },
                {
                    group: 'RETAIL',
                    code: 'InventoryDetailVendor',
                    value: this.captions.VendorInventoryDetail,
                    breakPointNumber: ReportBreakPoint.VendorInventoryDetail,
                    IsAuthorized: true,
                    allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
                }
            ]
            returnArray = [...returnArray, ...inventoryReports];
        }
        if (this.featureFlag.AllowAccrualPoints) {
            returnArray.push({
                group: 'RETAIL',
                code: 'Accrual',
                value: this.captions.AccrualReport,
                breakPointNumber: ReportBreakPoint.Accrual,
                IsAuthorized: true,
                allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
            })
        }
        returnArray.push({
            group: "POS",
            code: "Surcharges",
            value: this.captions.SurchargesReport,
            breakPointNumber: ReportBreakPoint.SurchargesReport,
            IsAuthorized: true,
            allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
        })

        returnArray.push({
            group: 'RETAIL',
            code: 'TransactionExtract',
            value: this.captions.TransactionExtract,
            breakPointNumber: ReportBreakPoint.TransactionExtract,
            IsAuthorized: true,
            allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
        });
        returnArray.push({
            group: 'REVENUE',
            code: 'TransactionExtract',
            value: this.captions.TransactionExtract,
            breakPointNumber: ReportBreakPoint.TransactionExtract,
            IsAuthorized: true,
            allowedProducts: [Product.SPA, Product.GOLF, Product.SNC, Product.RETAIL, Product.PMS]
        });
        return returnArray;
    }




}
