import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import { controlSettings } from '@app/shared/constants/globalConstants';
import { PartyType, SlottingType, SlotType, TableBlockingRuleFrequencyType } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { AuditableActionType, AuditableEntityType, AuditLogItemDetailType, SimpleAuditLogItemDTO } from '@models/SimpleAuditLogItemDTO';
import { FacadeService } from '@services/facade.service';
import { Utilities } from '@utilities/utilities';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-auditlog',
  templateUrl: './auditlog.component.html',
  styleUrls: ['./auditlog.component.scss']
})
export class AuditlogComponent implements OnInit, OnDestroy {

  @Input() data: any;
  logItems: SimpleAuditLogItemDTO[] = [];
  contactDetails: any;
  party: any;
  rule: any;
  slot: any;
  activity:any;
  offerData:any;
  shiftId: number;
  PartyType = PartyType;
  profileImage: string;
  AuditableEntityType = AuditableEntityType;
  AuditableActionType = AuditableActionType;
  AuditLogItemDetailType = AuditLogItemDetailType;
  TableBlockingRuleFrequencyType = TableBlockingRuleFrequencyType;
  tableInfo = ["Tables", "Reservation Time", "Size", "Confirmation Code", "Party Notes", "Seating Area", "Host", "Status", "Is Confirmed","Booking Type"];
  personalInfo = ["First Name", "Last Name", "Email Address","Secondary Email Address", "Phone Number", "Secondary Phone Number", "Birthday", "Anniversary", "Contact Notes", "Masked Card Number", "Is VIP"];
  otherInfo = ["Page Method", "Pager Number", "Source", "Cancelation Source"];
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  sessionInfo = ["Activity Session","Session Date","Session Date","Session State","Guests Checked-In","Session Type","Confirmation code","Booking Size","Addon Count"];
  paymentInfo = ["Cancellation Fee","Tax","TAX","VAT", "Discount","Offer" ,"Tax Amount","Negotiated","Negotiated Amount","Session Amount","Additional Fee","Addon Amount","Package Discount Amount","Addon Tax Amount","Addon Negotiated Amount","Amount Charged","Amount Refunded","Service Charge","Service Charges", "VAT on Service Charges","Tax on Service Charges", "Min Rate Adjustment","Grand Total","Amount Charged","Amount to be Paid","Amount Refunded","Negotiated Amounts","Session Charge", "Booking Amount"];
  logisticPreferenceInfo = ["Pickup Mode", "Pickup At", "Pickup Latitude", "Pickup Longitude", "Drop Mode", "Drop At", "DropAt Latitude", "DropAt Longitude"]
  delimiterAuditLogValue = ["Negotiated Amounts","Public Headline","Offer Code","Promo Code","Private Headline","Warning Message","Activity Blocking Rules","Session / Class","Session","Class","Batch","Addon Items","Class / Batch"];
  formatText = ["Negotiated Amounts"]
  nodataImgUrl: string = urlConfig.noReservationsUrl;
  showPersonalTable: boolean = false;
  showPaymentTable: boolean = false;
  showParty: boolean = true;
  subscriptions: Subscription = new Subscription();
  isDining: boolean = true;
  sessionReservedFor:  any;
  displayTime = true;
  advanceSetting: any;
  scheduleId: number;
  _message = MESSAGE;
  isFromRentalBooking: boolean = true;

  constructor(public fs: FacadeService, public cs: CacheService,public appService: AppService) { }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    if (this.data && this.data.length > 0) {
      this.isFromRentalBooking = this.data[0].IsRentalBooking ?? false;
      switch (this.data[0].type) {
        case AuditableEntityType.Party:
          this.party = this.data[0].party;
          this.contactDetails = this.party.Contact;
          this.setProfileImage();
          this.getPartyAuditLog();
          break;
        case AuditableEntityType.ManualSlot:
          this.slot = this.data[0].slot;
          this.getManualSlotAuditLog();
          break;
        case AuditableEntityType.TableBlockingRule:
          this.rule = this.data[0].rule;
          this.getTBRAuditLog();
          break;
        case AuditableEntityType.Activity:
          this.activity = this.data[0].activity;
          this.getActivityAuditLog();
          break;
        case AuditableEntityType.Shift:
          this.shiftId = this.data[0].shiftId;
            this.getShiftAuditLog();
            break;
        case AuditableEntityType.ActivityCustomizations:
          this.rule = this.data[0].rule;
          this.getActivityBlockAuditLog();
          break;
        case AuditableEntityType.AdvancedSettings:
          this.advanceSetting = this.data[0].settingsConfig;
          this.getAdvancedSettingsAuditLog();
          break;
        case AuditableEntityType.Offer:
          this.offerData = this.data[0].promoCode;
          this.getPromoCodeAuditLog();
          break;
        case AuditableEntityType.StaffBlockorBreakHours:
          this.scheduleId = this.data[0].scheduleId;
          this.getStaffBlockorBreakHourAuditLog();
          break;
      }
    }
  }

  setProfileImage() {
    if (this.contactDetails) {
      this.profileImage = (this.contactDetails.Photo) ? `data:image/png;base64,${this.contactDetails.Photo}` : '';
    }
  }

  getPartyAuditLog() {
    if (this.party && this.party.Id) {
      this.showParty = Utilities.controlValidate(controlSettings.AuditLog_Show_Size_Logo, this.appService.PropertyType) ? true : false;
      this.subscriptions.add(this.fs.getPartyAuditLog(this.party.Id).subscribe(data => {
        this.logItems = (data.Payload) ? data.Payload as SimpleAuditLogItemDTO[] : [];
        this.removeAuditLogEmptyValue();
        this.setItemTypeForParty();
        this.updateBooleanValues();
        this.setPartyReservedFor();
      }));
    }
  }

  setPartyReservedFor(){
    if(this.cs.settings.value?.PropertySetting[0].SlottingType == SlottingType.NonDining){ 
      this.isDining = false;     
       if(this.party.SessionGroupId || this.party.BookedSessions?.length){
        this.displayTime = false
        this.sessionReservedFor = new Date(this.party.StartDate);
      }
      else if(this.party.BookedSessionId && this.party.BookedSessionId != null){
        var bookedSession = this.party.BookedSessions.filter(x => x.Id == this.party.BookedSessionId)[0];
        const sessionId = bookedSession.ActivitySessionId;
        let specialmeal = this.cs.settings.value.SpecialMeals.filter(s => s.Id == this.party.SpecialMealId)[0];
        let sessionDetails = specialmeal.ActivitySessions?.filter(x => x.ActivitySessionId == sessionId)[0];
        let times :any = sessionDetails.StartTime.split(':');
        this.sessionReservedFor = new Date(bookedSession.BookedDate).setHours(times[0],times[1],0,0);
      }

      else{
        this.sessionReservedFor = new Date(this.party.ReservedFor)
      }
    }
  }

  setItemTypeForParty() {
    this.logItems.forEach(item => {
      if (item.ActionType == AuditableActionType.Create && item.EntityType == AuditableEntityType.Party) {
        item.Details.forEach((detail:any) => {
          detail.Type = this.tableInfo.includes(detail.Name) ? AuditLogItemDetailType.Table : this.personalInfo.includes(detail.Name) ? AuditLogItemDetailType.Personal : this.paymentInfo.includes(detail.Name) ? AuditLogItemDetailType.Payment : AuditLogItemDetailType.Other;
          if(detail?.EntityDetails?.length > 0) {
            detail?.EntityDetails?.forEach(entity => {
              if(entity?.EntityDetails?.length > 0) {
                entity?.EntityDetails?.forEach(session => {
                  session.Type = this.sessionInfo.includes(session.Name) ? AuditLogItemDetailType.Session : this.paymentInfo.includes(session.Name) ? AuditLogItemDetailType.Payment : this.logisticPreferenceInfo.includes(session.Name) ? AuditLogItemDetailType.LogisticPreference : AuditLogItemDetailType.Other;
                });
              }
            });
          }
        });
        this.showPersonalTable = item.Details.find(detail => detail.Type == AuditLogItemDetailType.Personal) ? true : false;
        this.showPaymentTable = item.Details.find(detail => detail.Type == AuditLogItemDetailType.Payment) ? true : false;
      }
    });
  }

  updateBooleanValues() {
    this.logItems.forEach(item => {
      if (item.EntityType == AuditableEntityType.Party) {
        item.Details.forEach(detail => {
          if (detail.Name === "Is Confirmed" || detail.Name === "Is VIP") {
            detail.Value = (detail.Value === "True") ? "Yes" : "No";
            detail.OriginalValue = (detail.OriginalValue === "True") ? "Yes" : "No";
          }
          if ((detail.Name.includes('Time') || detail.Name.includes('time')) && detail.Name != "Wait Time Override") {
            detail.OriginalValue = (detail.OriginalValue != "(empty)") ? Utilities.Date(detail.OriginalValue).format(this.cs.settings.value.General.DateFormat).toString() + ' / ' + Utilities.Date(detail.OriginalValue).format('h:mm:ss A').toString() : detail.OriginalValue;
            detail.Value = (detail.Value != "(empty)") ? Utilities.Date(detail.Value).format(this.cs.settings.value.General.DateFormat).toString() + ' / ' + Utilities.Date(detail.Value).format('h:mm:ss A').toString() : detail.Value;
          }
        });
      }
      if (item.EntityType == AuditableEntityType.ManualSlot) {
        item.Details.forEach(detail => {
          if (detail.Name === "Is Disabled" || detail.Name === "Is Web Reservable") {
            detail.Value = (detail.Value === "True") ? "Yes" : "No";
            detail.OriginalValue = (detail.OriginalValue === "True") ? "Yes" : "No";
          }
        });
      }
    });
  }

  getTBRAuditLog() {
    if (this.rule && this.rule.Id) {
      this.subscriptions.add(this.fs.getTBRAuditLog(this.rule.Id).subscribe(data => {
        this.logItems = (data.Payload) ? data.Payload as SimpleAuditLogItemDTO[] : [];
        this.removeAuditLogEmptyValue();
        this.updateBooleanValues();
      }));
    }
  }

  getManualSlotAuditLog() {
    if (this.slot && this.slot.Id) {
      this.subscriptions.add(this.fs.getManualSlotAuditLog(this.slot.Id, this.slot.Type == SlotType.DefaultSlot).subscribe(data => {
        this.logItems = (data.Payload) ? data.Payload as SimpleAuditLogItemDTO[] : [];
        this.updateBooleanValues();
      }));
    }
  }

  getActivityAuditLog() {
    if(this.activity && this.activity.ActivityID) {
      this.subscriptions.add(this.fs.getActivityAuditLog(this.activity.ActivityID).subscribe(data => {
        this.logItems = (data.Payload) ? data.Payload as SimpleAuditLogItemDTO[] : [];
        this.removeAuditLogEmptyValue();
        this.setItemTypeForParty();
        this.updateBooleanValues();
      }));
    }
  }

  getActivityBlockAuditLog() {
    if(this.rule && this.rule.CustomizationId) {
      this.subscriptions.add(this.fs.getActivityBlockAuditLog(this.rule).subscribe(data => {
        this.logItems = (data.Payload) ? data.Payload as SimpleAuditLogItemDTO[] : [];
        this.removeAuditLogEmptyValue();
        this.setItemTypeForParty();
        this.updateBooleanValues();
      }));
    }
  }

  getShiftAuditLog(): void {
    if(this.shiftId == 0) {
      this.subscriptions.add(this.fs.getShiftAuditLog(this.appService.headerSelectedDate).subscribe(data => {
        this.logItems = (data.Payload) ? data.Payload as SimpleAuditLogItemDTO[] : [];
      }))

    }else {
      this.subscriptions.add(this.fs.getCurrentShiftAuditLog(this.appService.headerSelectedDate,this.shiftId).subscribe(data => {
        this.logItems = (data.Payload) ? data.Payload as SimpleAuditLogItemDTO[] : [];
      }))
    }
  }

  getAdvancedSettingsAuditLog(): void {
    if(this.advanceSetting?.SettingId) {
      let request = {EntityIds:[this.advanceSetting?.SettingId],Entities:[AuditableEntityType.AdvancedSettings],Actions:[],PropertyId:Utilities.RestaurantId(),MerchantId: this.cs.settings?.value?.General?.MerchantId};
      this.subscriptions.add(this.fs.getAdvancedSettingsAuditLog(request).subscribe(data => {
        this.logItems = (data.Payload) ? data.Payload as SimpleAuditLogItemDTO[] : [];
      }))
    }
  }

  getPromoCodeAuditLog() {
    if(this.offerData) {
      this.subscriptions.add(this.fs.getPromoCodeAuditLog(this.offerData).subscribe(data => {
        this.logItems = (data.Payload) ? data.Payload as SimpleAuditLogItemDTO[] : [];
        this.removeAuditLogEmptyValue();
      }));  
    }
  }

  getStaffBlockorBreakHourAuditLog() {
    if(this.scheduleId) {
      let request = {EntityIds:[this.scheduleId],Entities:[AuditableEntityType.StaffBlockorBreakHours],Actions:[],PropertyId:Utilities.RestaurantId(),MerchantId: this.cs.settings?.value?.General?.MerchantId};
      this.subscriptions.add(this.fs.getAdvancedSettingsAuditLog(request).subscribe(data => {
        this.logItems = (data.Payload) ? data.Payload as SimpleAuditLogItemDTO[] : [];
      }))
    }
  }

  hasProperty(detailName,value) {
    return detailName.hasOwnProperty(value);
  }

  removeAuditLogEmptyValue() {
    this.logItems?.map(item => {
      item.Details = this.removeEmpty(item.Details);
      item.Details?.map((entity: any) => {
        this.entityDetailsEmpty(entity);
      });
    });
  }

  removeEmpty(details: any[]): any[] {
    return details?.filter(x => !(x.Value?.includes(this._message.label.emptyText) && x.OriginalValue?.includes(this._message.label.emptyText)));
  }

  entityDetailsEmpty(entity) {
    entity.EntityDetails = this.removeEmpty(entity.EntityDetails);
    entity?.EntityDetails?.map(detail => {
      this.entityDetailsEmpty(detail);;
    })
  }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}

const MESSAGE = {
  label: {
    emptyText: 'empty'
  }
}