import { Utilities } from "@app/shared/utilities/utilities";
import _ from "lodash";
import moment from 'moment';
export function convertTimeFormat(timeData) {
    const [time, modifier] = timeData?.split(' ');

    let [hours, minutes] = time?.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (modifier?.toUpperCase() === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
}

export function addLeadingZero(time: string) {
    let str = time?.split(':')?.[0];
    if (str?.length === 1 && Number(str) < 10) {
        str = `0${time}`;
        return str;
    }
    return time;
}

export function deepClone(input) {
    return JSON.parse(JSON.stringify(input));
}

export function getCartGroupName({ FirstName, LastName }): string {
    return (FirstName || LastName || '') + Utilities.getRandomString();
}

export function getGuestTypes(types) {
    types = _.cloneDeep(types);
    types.forEach(type => {
        if (!type.BookingTypeId) {
            type.BookingTypeId = null;
        }
        if (!type.CoverTypeId) {
            type.CoverTypeId = null;
        }
    })

    return types;
}

export function resetDateHours(date) {
    return date ? moment(date).startOf('day') : null;
};