import { Pipe, PipeTransform } from "@angular/core";
import { PartyState } from "../constants/commonenums";

@Pipe({
  name: 'filterByState'
})
export class FilterByState implements PipeTransform {

  transform(data: any, states: PartyState[]): any {
    if (states.includes(5)) { // Filter for Exclusive Booking 
      return data.filter(attendee => attendee.IsExclusiveBooking && ( states.includes(attendee.State) || this.filterByBookingContact(attendee.BookingContacts , states)));
    } else {
      return data.filter(attendee => states.includes(attendee.State) || this.filterByBookingContact(attendee.BookingContacts , states));
    }

  }
  filterByBookingContact(BookingContacts, states): boolean {
    if (BookingContacts?.length) {
      return BookingContacts.some(contact => states.includes(contact.State));
    } else {
      return true;
    }

  }
}