import { PartyState } from "@app/shared/constants/commonenums"
import { DurationInputArg1, DurationInputArg2 } from "moment"

export enum Filtertypes {
  CalenderFilter,
  AdvancedFilter
}

export const INVENTORYSETUP = {
  SYSTEM_CODE: 'RESERVE',
  AVAILABILITY_CODE: 20,
  ASSETS_REQUIRED: 2,
  ASSET_LIST_TYPE: 0,
}

export enum Availabilitystatus {
  HIGH = 'rntl-availability-high',
  MEDIUM = 'rntl-availability-medium',
  LOW = 'rntl-availability-low'
}

export enum AVAILABILITY_CONFIG {
  LOW = 25,
  MEDIUM = 50
}

export enum RentalComponents {
  RentalPickItem,
  RentalItemSummary
}

export const logisticPreferOptions = [{
  value: 'Both',
  id: 1
}, {
  value: 'Pickup',
  id: 2
}, {
  value: 'Delivery',
  id: 3
}]

export const TimeInterval: { amount: DurationInputArg1, unit: DurationInputArg2 } = {
  amount: 1,
  unit: 'hours'
}


export enum LogisticPreferenceModes {
  Self = 1,
  DoorStepDelivery = 2
}
export enum ReportLogisticPreferenceModes {
  GuestPickupDropOff = 1,
  EmployeeDeliverCollect = 2,
  GuestPickupEmployeeCollect = 3,
  EmployeeDeliverGuestDropOff = 4

}
export enum ReportRentalStatusFilter{
  Reserved = 0,
  CheckedOut = 1,
  CheckIn = 2,
  Cancelled = 4,
  OutForDelivery = 6,
  Delivered = 7,
  Collected = 8
}
export const Rental_Master_Status = {
  [PartyState.Pending]: 'Status_Pending',
  [PartyState.Seated]: 'Status_CheckedOut',
  [PartyState.Left]: 'Status_CheckedIn',
  [PartyState.Cancelled]: 'Status_Cancelled',
  [PartyState.OutForDelivery]: 'Status_OutForDelivery',
  [PartyState.Delivered]: 'Status_Delivered',
  [PartyState.Collected]: 'Status_Collected'
}

export interface ICalenderChangeModel {
  reset: boolean;
  value?: any
}