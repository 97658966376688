import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonControllersRoutes } from '@app/common/communication/common-route';
import { RetailErrorCode } from '@app/shared/enums/constants';
import configjson from '@assets/json/config.json';
import loginkeys from '@assets/json/RetailLogin.json';
import { loaderDisabledUrls, urlConfig, urlWithLoaders, urlWithRetailHeaders } from '@constants/url-config';
import { CacheService } from '@core/services/cache.service';
import { LoaderService } from '@core/services/loader.service';
import { ChangeDTO, ChangeTrackingOperationResultDTO, OperationResultDTO } from '@models/ChangeTrackingOperationResultDTO';
import { TranslateService } from '@ngx-translate/core';
import { ResultProcesserFunction } from '@utilities/result-processer';
import { Utilities } from '@utilities/utilities';
import _ from 'lodash';
import { Observable, Subscription } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  private requestWithMessage: HttpRequest<any>[] = [];  
  private pendingRequests: HttpRequest<any>[] = [];
  private retailUrl:any;
  private retailloginUrl:any;
  clonedObs: any = null;
  managerPINSubscription: Subscription = new Subscription();
  userActionSubscription: Subscription = new Subscription();
  constructor(private loaderService: LoaderService, private cs: CacheService, private rp: ResultProcesserFunction,private ts:TranslateService) {
    this.retailUrl=configjson;
    this.retailloginUrl=loginkeys;
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    const j = this.requestWithMessage.indexOf(req);
    if (j >= 0) {
      this.requestWithMessage.splice(j, 1);
    }    
    this.loaderService.isLoading.next(this.requests.length > 0);
     if(this.requestWithMessage.length == 0){
       this.loaderService.showMessage.next(false);        
     }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    const restaurantId: number = +sessionStorage.getItem(`restaurantId${Utilities.getSessionStorageType()}`);
    let retailHeaderRequired = this.isRetailHeaderRequired(req.url);
    let headers = Utilities.getHeaders(restaurantId, retailHeaderRequired , req);
    let isForceDisableLoader = req.headers.get('disableLoader') == 'true';
    isForceDisableLoader = (this.cs.isSilentLogin || req.url.includes(this.retailUrl.PayAgent) || req.url.includes(this.retailUrl.PMAgent)) ? true : isForceDisableLoader;
    if (req.headers.get('restaurantID')) {
      headers = Utilities.getHeaders(+req.headers.get('restaurantID'), retailHeaderRequired , req);
    }
    let headersvalue=req.headers.has("Authorization");
    if (headers && !headersvalue) {
      headers.headers = headers.headers.set('ngsw-bypass', '');
      req = req.clone(headers);
    }
    if(!req.headers.has("ngsw-bypass") && !req.url.includes("/assets/"))   
    {
      let keys=req.headers.keys();    
      const httpOptions = {
        headers: new HttpHeaders({ 'ngsw-bypass': '' })
      }
      keys.forEach(k=>{
        let vals=req.headers.get(k);
        httpOptions.headers= httpOptions.headers.set(k,vals);
      });
      req = req.clone(httpOptions);
    }

    if (this.managerPINSubscription) {
      this.managerPINSubscription.unsubscribe();
    }
    if(this.userActionSubscription){
      this.userActionSubscription.unsubscribe();
    }
    //this.requests.push(req);

    if (!this.isLoaderDisabled(req.url) && !isForceDisableLoader) {
      this.requests.push(req);
      Promise.resolve().then(() => this.loaderService.isLoading.next(true));      
    }    
    this.requestWithMessage.push(req);
     if (!isForceDisableLoader)
     this.getLoaderMessage(req.url)
    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              if (event.status === 200 && event.body && (event.body as ChangeTrackingOperationResultDTO).Change) {
                const changes: ChangeDTO[] = [];
                changes.push((event.body as ChangeTrackingOperationResultDTO).Change);
                if(changes && changes.length > 0){
                  changes.forEach(c => {c.isPayloadchangeset = true,
                  c.ChangeSet.RestaurantId = Utilities.RestaurantId()});
                }
                this.cs.changes.next(changes);
              }
              if(event.status === 200 && event.body && (event.body as ChangeTrackingOperationResultDTO).Changes){
                 const changes = (event.body as ChangeTrackingOperationResultDTO).Changes;
                const changeDTOList: ChangeDTO[] = [];
                Object.keys(changes).forEach(c=>{
                  if(changes){
                    var change = changes[c];
                    if(change){
                      change.isPayloadchangeset = true,
                      change.ChangeSet.RestaurantId = c;
                      changeDTOList.push(change);
                    }
                  }
                })
                this.cs.changes.next(changeDTOList);
              }
              let isValid = true;
              if (event.status === 200 && event.body && (event.body as OperationResultDTO).State > 0) {

                isValid = this.rp.PayloadProcesser(event.body as OperationResultDTO , null , req.url.includes('ignoreValidation=false'));
                if ((event.body as OperationResultDTO).State == 3) {
                  this.handleInsufficientpermissions(req, next);
                  if (this.pendingRequests.length > 0 || this.clonedObs == null) {
                    this.clonedObs = _.cloneDeep(observer);
                  }
                  this.pendingRequests[0] = req;
                }else if((event.body as OperationResultDTO).State == 6){
                  this.handleWaringAction(req, next);
                  if (this.pendingRequests.length > 0 || this.clonedObs == null) {
                    this.clonedObs = _.cloneDeep(observer);
                  }
                  this.pendingRequests[0] = req;
                }else if((event.body as OperationResultDTO).State == 5){
                  isValid = true;
                  this.rp.OnOperationCompleted();                  
                }
                //isValid=false;
                // this.cs.changes.next(changes);
              }
              if (event.status === 200 && event.body && (event.body as OperationResultDTO).State == 0) {
                this.rp.OnOperationCompleted();
              }
              if (event.status === 200 && event.body && (event.body as OperationResultDTO)['errorCode'] == RetailErrorCode.PasswordExpire) {
                this.rp.ShowNotification({ExceptionMessage : event.body['errorDescription']}, false, false);
                this.rp.OnOperationCompleted();
              }
              this.removeRequest(req);             
              if (isValid) {
                observer.next(event);
              }else{
                observer.error(event);
              }
            }
          },
          err => {
            this.removeRequest(req);    
            let loginurlslist=this.retailloginUrl.loginkeys.filter(x=>{
              if(req.url.toLowerCase().includes(x.loginkey.toLowerCase()) )
              {
                return true;
              }
            })
            if(!req.url.includes(this.retailUrl.PayAgent) && !req.url.includes(this.retailUrl.PMAgent) && !req.url.includes(this.retailUrl.Check) && !this.cs.isSilentLogin && 
            (loginurlslist == null || loginurlslist.length ==0)   )  
            {
              this.cs.isSilentLogin=false;
            if(err && err.url && !(err.url.includes(CommonControllersRoutes.LogUserMenuAccess) 
                && err.statusText == "Unknown Error" && !err.error.errorCode)){
              this.rp.ShowNotification(err, false, false);
            }else{
              this.rp.ShowNotification(err, false, true);
            }          
           
          } 
          observer.error(err);
         
          },
          () => {
            this.removeRequest(req);            
            if (this.pendingRequests.length == 0) {
              this.clonedObs = null;
            }
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req); 
        subscription.unsubscribe();
      };
    });
  }

  private isLoaderDisabled(value: any) {
    let disabledUrlKeys = Object.keys(loaderDisabledUrls).filter(k => isNaN(Number(k)));
    return disabledUrlKeys.filter(k => value.includes(urlConfig[k])).length > 0;
  }

  private isRetailHeaderRequired(value: any) {
    let retailHeaderUrls = Object.keys(urlWithRetailHeaders).filter(k => isNaN(Number(k)));
    return retailHeaderUrls.filter(k => value.includes(urlConfig[k])).length > 0;
  }

  private getLoaderMessage(value:any){
    let disabledUrlKeys = Object.keys(urlWithLoaders).filter(k => isNaN(Number(k)));
    let urlWithMessage = disabledUrlKeys.filter(k => value.includes(urlConfig[k]));
    if(urlWithMessage.length > 0){
     let urlLoaded = this.loaderService.loaderMessageForUrl.filter(url => url.name == urlWithMessage[0]);
     if(urlLoaded.length > 0){
        this.loaderService.showMessage.next(true);
        this.loaderService.disableLoader.next(false); 
        this.loaderService.loaderMessage.next(urlLoaded[0].text);     
return true;
     }
     return false;    
    }
  }

  handleInsufficientpermissions(req: HttpRequest<any>, next: HttpHandler) {
    this.managerPINSubscription = this.loaderService.managerPin$.subscribe(val => {
      const newreq = this.pendingRequests[0];
      this.pendingRequests = [];
      return this.intercept(newreq, next).subscribe(event => { this.clonedObs.next(event) });
    });
  }

  handleWaringAction(req: HttpRequest<any>, next: HttpHandler) {
    this.userActionSubscription = this.loaderService.userAction$.subscribe(val => {
      let oldRequest = this.pendingRequests[0]
      let cloneReq = oldRequest;
      if (oldRequest.url.includes('ignoreValidation=false')) {
        cloneReq = oldRequest.clone({ url: oldRequest.url.replace('ignoreValidation=false', 'ignoreValidation=true') });
      }
      this.pendingRequests = [];
      return this.intercept(cloneReq, next).subscribe(event => { this.clonedObs.next(event) });
    });
  }
  
}
