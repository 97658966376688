import { Utilities } from '@app/shared/utilities/utilities';
import { ChangeAction } from '@constants/commonenums';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { ReservationDTO } from '@models/InputContact';
import { BookingContactAddonItemDTO, BookingContactDTO, BookingContactStateDTO, PartyMessageDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import _, { uniqBy } from 'lodash';
import { CacheService } from '../services/cache.service';
import { Processor } from './processor';

export class PartyBookingContactStateChangeProcessor implements Processor {

    private static instance: PartyBookingContactStateChangeProcessor;
    private constructor(public partyService: PartyService, public cs: CacheService) { }

    public static Instance(partyService: any = PartyService, cs: CacheService): PartyBookingContactStateChangeProcessor {
        if (!PartyBookingContactStateChangeProcessor.instance) {
            PartyBookingContactStateChangeProcessor.instance = new PartyBookingContactStateChangeProcessor(partyService, cs);
        }

        return PartyBookingContactStateChangeProcessor.instance;
    }

    Process(objectChange: ObjectChange, additionaldata: object, propertyId: number): void {
        let partiesList = [];
        let standBy = false;
        if (objectChange) {
            const BookingContactId = objectChange.PropertyChanges.find(property => property.PropertyName === 'BookingContactId').Value;
            let selectedParty = this.partyService.StandbyParties$.value.find(party => party?.BookingContacts?.length && party?.BookingContacts.find(contact => contact.Id == BookingContactId));
            if (selectedParty) {
                partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.StandbyParties$.value : this.cs.propertySettings.value[propertyId].StandbyParties;
                standBy = true;
            }
            else {
                partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.Parties$.value : this.cs.propertySettings.value[propertyId].reservations;
                standBy = false;
            }
            let party = partiesList.find(party => party?.BookingContacts?.length && party?.BookingContacts.find(contact => contact.Id == BookingContactId));
            let bookingContactsIndex = party?.BookingContacts?.findIndex(contact => contact.Id == BookingContactId);
            if (objectChange.Action === ChangeAction.Created) {
                if (party && bookingContactsIndex >=0 ) {
                    const updateContactStateId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
                    const isContactStateAvailable = party.BookingContacts[bookingContactsIndex]?.BookingContactStates?.length > 0;
                    if (!isContactStateAvailable) {
                        party.BookingContacts[bookingContactsIndex].BookingContactStates = [];
                    }
                    const partyContactAddon: BookingContactStateDTO = new BookingContactStateDTO();
                    objectChange.PropertyChanges.forEach(property => {
                        if (Object.getOwnPropertyNames(partyContactAddon).includes(property.PropertyName.replace('Internal', ''))) {
                            partyContactAddon[property.PropertyName.replace('Internal', '')] = property.Value;
                        }
                    });
                    party.BookingContacts[bookingContactsIndex].BookingContactStates.push(partyContactAddon)
                    party.BookingContacts[bookingContactsIndex].BookingContactStates = uniqBy(party.BookingContacts[bookingContactsIndex].BookingContactStates, 'Id');
                }
            }

            if (objectChange.Action === ChangeAction.Updated) {
                const updateContactStateId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
                if (party && bookingContactsIndex >=0 ) {
                    const contactStateIndex = party.BookingContacts[bookingContactsIndex]?.BookingContactStates.findIndex(addon => addon.Id == updateContactStateId);
                    let partyContactAddon: BookingContactStateDTO = party.BookingContacts[bookingContactsIndex]?.BookingContactStates[contactStateIndex]
                    objectChange.PropertyChanges.forEach(property => {
                        if (Object.getOwnPropertyNames(partyContactAddon).includes(property.PropertyName.replace('Internal', ''))) {
                            partyContactAddon[property.PropertyName.replace('Internal', '')] = property.Value;
                        }
                    });
                    party.BookingContacts[bookingContactsIndex].BookingContactStates[contactStateIndex] = partyContactAddon;
                    partiesList[partiesList.indexOf(party)] = party;
                }
            }
            if (objectChange.Action === ChangeAction.Removed) {
                const updateContactStateId = objectChange.PropertyChanges.find(property => property.PropertyName == 'Id').Value;
                if (party && bookingContactsIndex >= 0) {
                    if (party.BookingContacts[bookingContactsIndex].BookingContactStates?.length) {
                        party.BookingContacts[bookingContactsIndex].BookingContactStates = party.BookingContacts[bookingContactsIndex]?.BookingContactStates.filter(addon => addon.Id == updateContactStateId);
                    }
                    partiesList[partiesList.indexOf(party)] = party;

                }
            }
        }
        partiesList = _.uniqBy(partiesList, 'Id');
        if (propertyId == Utilities.RestaurantId()) {
            if (standBy) {
                this.partyService.StandbyParties$.next(partiesList);
                this.partyService.standbyPartiesList = partiesList;
                this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
            } else {
                this.partyService.Parties$.next(partiesList);
                this.partyService.partiesList = partiesList;
                this.cs.propertySettings.value[propertyId].reservations = partiesList;
            }
        } else {
            if (standBy) {
                this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
            } else {
                this.cs.propertySettings.value[propertyId].reservations = partiesList;
            }
        }
    }
}
