import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { SuccessInput } from '../../interfaces/common';
import { DialogContainerComponent } from '../dialog-container/dialog-container.component';

@Component({
  selector: 'lib-success-info',
  standalone: true,
  imports: [CommonModule, DialogContainerComponent, TranslateModule],
  templateUrl: './success-info.component.html',
  styleUrls: ['./success-info.component.scss']
})
export class SuccessInfoComponent {

  constructor(public dialogRef: MatDialogRef<SuccessInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SuccessInput){
    }
}
