<section class="CommissionOn">
  <form [formGroup]="FormGrp" autocomplete="off" class="h-100">
    <mat-dialog-content class="pt-3 m-0 w-100">
      <ng-scrollbar >
        <div class="pt-3">
          <mat-form-field class="w-50" [floatLabel]="floatLabel">
            <input matInput placeholder="{{captions.setting.Description}}" readonly="true" formControlName="itemDescription" required>
          </mat-form-field>
        </div>
        <div class="pt-3" *ngIf="showOutletsSelection">
          <mat-form-field class="w-50" [floatLabel]="floatLabel">
            <input matInput placeholder="{{captions.setting.Location}}" readonly="true" formControlName="locationDescription" required>
          </mat-form-field>
        </div>
        <div class="pt-3">
          <mat-form-field class="w-50" [floatLabel]="floatLabel">
            <input matInput placeholder="{{captions.setting.Threshold}}" type="text" id="threshold" formControlName="threshold" required
            RetailCurrencyFormatter >
          </mat-form-field>
        </div>
        <div class="pt-3">
          <mat-form-field class="w-50" [floatLabel]="floatLabel">
            <input matInput placeholder="{{captions.setting.FlatAmount}}" type="text" id="flatAmount" RetailCurrencyFormatter required  
            (keydown)="KeyDownEvent($event,'flat')" formControlName="flatAmount" >
          </mat-form-field>
        </div>
        <div class="pt-3">
          <mat-form-field class="w-50" [floatLabel]="floatLabel">
            <input matInput placeholder="{{captions.setting.Percentage}}" type="text" id="percentage" formControlName="percentage" percentinputtype='onlynumber,decimal,validPercentage,preMaxdecimal' [maxpredecimalValue]="commissionMaxLength"
              (keydown)="KeyDownEvent($event,'percentage')" required>
          </mat-form-field>
        </div>
        <div class="pt-3">
          <mat-form-field class="inputwidth20 LW14 w-50" [floatLabel]="floatLabel">
            <mat-select placeholder="{{captions.setting.CommissionOn}}" name="commissionOn" class="LW14" formControlName="commissionon" required>
              <mat-option value="{{captions.setting.Items}}">{{captions.setting.Items}}</mat-option>
              <mat-option value="{{captions.setting.Revenue}}">{{captions.setting.Revenue}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="pt-3">
            <label class="d-block form-label-normal">{{captions.setting.AfterDiscount}}</label>
            <app-retail-toggle-switch name="toggbut" formControlName="isAfterDiscount" ></app-retail-toggle-switch>
        </div>
      </ng-scrollbar>
    </mat-dialog-content>
    <mat-dialog-actions class="pl-4">
      <div class="pop-button">
        <a>
          <button class="LW14 text_capitalize" [ngClass]="FormGrp.valid && FormGrp.dirty? 'spa-primary-default-save' : 'spa-primary-disabled'"
            mat-button color="primary" [disabled]="!FormGrp.valid || !FormGrp.dirty" [appDisableDoubleClick]="throttleTime" (throttleClick)="UpdateCommissions()">{{clickbutton}}</button>
        </a>
        <a class="ml-2 text_capitalize LW14" (click)="cancel()"> {{captions.setting.cancel}} </a>
      </div>
    </mat-dialog-actions>
  </form>
</section>
