import { Component, OnInit } from '@angular/core';
import { BreakPointAccess } from '../shared/service/breakpoint.service';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { RetailMenu } from '../retail.modals';
import { RetailLocalization } from '../common/localization/retail-localization';
import { RetailBreakPoint } from '../shared/globalsContant';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { RetailDataAwaiters } from '../shared/events/awaiters/retail.data.awaiters';
import { menuTypes } from 'src/app/common/components/menu/menu.constant';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { Product } from 'src/app/common/enums/shared-enums';
import { RetailConstants } from '../shared/service/retail.feature.flag.information.service';

@Component({
  selector: 'app-code-setup',
  templateUrl: './retail-code-setup.component.html',
  styleUrls: ['./retail-code-setup.component.scss']
})
export class CodeSetupComponent implements OnInit {
  codeCategoryId: string;
  selectedCategory = "";
  captions: any = this.localization.captions.retailsetup;
  allCaptions: any = this.localization.captions;
  codeCategories: RetailMenu[] = [
    { id: 'cardTerminal', viewValue: this.captions.creditCardTerminals, callDesc: '', breakPointNumber: RetailBreakPoint.creditcardterminal, IsAuthorized: true },
    { id: 'outlets', viewValue: this.captions.outlets, callDesc: '', breakPointNumber: RetailBreakPoint.OutletSetup, IsAuthorized: true },
    { id: 'categoryGroup', viewValue: this.captions.CategoryGroups, breakPointNumber: RetailBreakPoint.CategoryGroup, callDesc: '', IsAuthorized: true },
    { id: 'retailCategories', viewValue: this.captions.retailCategories, breakPointNumber: RetailBreakPoint.RetailCategories, callDesc: '', IsAuthorized: true },
    { id: 'retailSubCategories', viewValue: this.captions.retailSubCategories, breakPointNumber: RetailBreakPoint.RetailSubCategories, callDesc: '', IsAuthorized: true },
    { id: 'unitofMeasure', viewValue: this.captions.unitofMeasure, breakPointNumber: RetailBreakPoint.UnitofMeasure, callDesc: '', IsAuthorized: true },
    { id: 'taxes', viewValue: this.captions.taxes, breakPointNumber: RetailBreakPoint.Taxconfiguration, callDesc: '', IsAuthorized: true },
    { id: 'discountTypes', viewValue: this.captions.discountTypes, breakPointNumber: RetailBreakPoint.DiscountType, callDesc: '', IsAuthorized: true },
    { id: "paymentMethods", viewValue: this.captions.paymentMethods, breakPointNumber: RetailBreakPoint.PaymentMethods, callDesc: '', IsAuthorized: true },
    { id: 'quickSaleCategories', viewValue: this.captions.quickSaleCategories, breakPointNumber: RetailBreakPoint.QuickSaleCategories, callDesc: '', IsAuthorized: true },
    { id: 'vatConfiguration', viewValue: this.allCaptions.bookAppointment.vatConfiguration, callDesc: '', IsAuthorized: true },
    { id: 'featureConfiguration', viewValue: this.allCaptions.bookAppointment.featureConfiguration,  breakPointNumber: RetailBreakPoint.RetailFeatureConfiguration, callDesc: '', IsAuthorized: true },
    { id: 'viptype', viewValue: this.allCaptions.bookAppointment.VipType,  breakPointNumber: RetailBreakPoint.VipType, callDesc: '', IsAuthorized: true }
  ];
  menuList: any;
  menuType = menuTypes;
  codeRoute: any;
  currentProductId: number;

  constructor(
    private retailService: RetailSetupService, 
    private breakpoint: BreakPointAccess, 
    private localization: RetailLocalization, 
    private propertyInfo: RetailPropertyInformation,
    private utils: CommonUtilities
    ) {
    if (!this.propertyInfo.UseRetailInterface) {
        this.codeCategories.push({ id: 'creditcards', viewValue: this.allCaptions.bookAppointment.Creditcards,  breakPointNumber: RetailBreakPoint.CreditCards, callDesc: '', IsAuthorized: true });
        this.codeCategories.push({ id: 'giftcardconfiguration', viewValue: this.allCaptions.bookAppointment.GiftCards, callDesc: '', IsAuthorized: true });
        if(this.propertyInfo?.HasRevenuePostingEnabled)
        {
          this.codeCategories.push({ id: 'posttypemapping', viewValue: this.allCaptions.bookAppointment.PostTypeMapping,  breakPointNumber: RetailBreakPoint.PostTypeMapping, callDesc: '', IsAuthorized: true });
        }
        if(this.propertyInfo?.isResortFinanceEnabled)
        {
          this.codeCategories.push({ id: 'resortfinanceposttypemapping', viewValue: this.allCaptions.bookAppointment.ResortFinancePostTypeMapping, breakPointNumber: RetailBreakPoint.ResortFinancePostTypeMapping, callDesc:'', IsAuthorized: true});
        }
        if(this.propertyInfo?.IsAcesMembershipEnabled)
        {
          this.codeCategories.push({ id: 'memberAccrualMapping', viewValue: this.allCaptions.bookAppointment.MemberAccrualMapping,  breakPointNumber: RetailBreakPoint.MemberAccrualMapping, callDesc: '', IsAuthorized: true });
        }
        if(this.propertyInfo?.IsGLExtractEnabled)
        {
          this.codeCategories.push({ id: 'generalLedgerMapping', viewValue: this.allCaptions.retailsetup.GeneralLedgerMapping,  breakPointNumber: RetailBreakPoint.GeneralLedgerMapping, callDesc: '', IsAuthorized: true });
        }
       
    } else {
      this.codeCategories.splice(2, 0, { id: 'outletTerminal', viewValue: this.allCaptions.bookAppointment.StoreTerminals, breakPointNumber: RetailBreakPoint.StoreTerminal, callDesc: '', IsAuthorized: true });      
    }
    if(this.propertyInfo.GetEnablemachineTransaction() == 'true')
    {
      this.codeCategories.push({ id: 'machineName', viewValue: this.captions.machineName, callDesc: '', IsAuthorized: true });        
    }
    this.currentProductId = Number(this.utils.GetPropertyInfo('ProductId'));
    if(this.currentProductId == Product.ACCOUNTING){
      this.codeRoute = RetailDataAwaiters.GetChildMenu('/accounting/retailsetup/codesetup', menuTypes.vertical);
    }
    else{
      this.codeRoute = RetailDataAwaiters.GetChildMenu('/settings/retailsetup/codesetup', menuTypes.vertical);
    }
    this.codeRoute.linkedElement.map(res => {
      if (res) {
        if ( this.propertyInfo.UseRetailInterface) 
        {
            if (res.routePath === '/settings/retailsetup/codesetup/creditcards' || res.routePath === '/settings/retailsetup/codesetup/giftcards' || res.routePath === '/settings/retailsetup/codesetup/posttypemapping') 
            {
                res.visibility = false;
            } 
            else if (res.routePath === '/settings/retailsetup/codesetup/storeterminals') 
            {
              res.visibility = true;
            }
            else if (res.routePath === '/settings/retailsetup/codesetup/generalledgermapping') 
            {
              res.visibility = false;
            }
        } 
        else 
        {
            if (res.routePath === '/settings/retailsetup/codesetup/creditcards' || res.routePath === '/settings/retailsetup/codesetup/giftcards' ) 
            {
                res.visibility = true;
            } 
            else if (res.routePath === '/settings/retailsetup/codesetup/posttypemapping') 
            {
              const pmsSystem = sessionStorage.getItem('pmsSystem');  
              if(this.propertyInfo.HasRevenuePostingEnabled && pmsSystem!=null && pmsSystem.toLowerCase() === 'visualone')
              {
                res.visibility = true;
              }
              else
              {  
                res.visibility = false;                           
              }                                         
            }
            else if(res.routePath === '/settings/retailsetup/codesetup/resortfinanceposttypemapping') 
            {
              if(this.propertyInfo.isResortFinanceEnabled)
              {
                res.visibility = true;
              }
              else
              {  
                res.visibility = false;                           
              }  
            }
            else if (res.routePath === '/settings/retailsetup/codesetup/storeterminals') 
            {
              res.visibility = false;
            }
            if (res.routePath === '/settings/retailsetup/codesetup/generalledgermapping') 
            {
                if(this.propertyInfo.IsGLExtractEnabled) {
                  res.visibility = true;
                }
                else{
                  res.visibility = false;
                }
            }
        }
        if(this.propertyInfo.GetEnablemachineTransaction() == 'false')
        {
          if(res.routePath === '/settings/retailsetup/codesetup/machinename') 
          {
              res.visibility = false;
          }
        }
        else
        {
          if(res.routePath === '/settings/retailsetup/codesetup/machinename') 
          {
              res.visibility = true;
          }
        }  
        if (res.routePath === '/settings/retailsetup/codesetup/posttypemapping') 
        {
            if(Number(this.utils.GetPropertyInfo('ProductId')) == Product.PMS) {
              res.visibility = true;
            }
        }
        if (res.routePath === '/settings/retailsetup/codesetup/memberAccrualMapping') 
        {
            if(!this.propertyInfo.IsAcesMembershipEnabled) {
              res.visibility = false;
            }
        }
      }
    });
  }

  ngOnInit() {
    // landing screen selection
    this.codeCategoryId = this.propertyInfo.UseRetailInterface ? this.codeCategories[1].id : this.codeCategories[0].id;
    this.codeCategories = this.retailService.MapIsAuthorizedRetailMenu(this.codeCategories);

    this.codeRoute = this.retailService.AdjustMenuBasedOnRetailInterface(this.codeRoute.linkedElement);
    this.menuList = {
      menu: this.codeRoute,
      menuType : this.codeRoute[0].menuAlignment
    };
     
    this.retailService.tabLoaderEnable.next(false);
  }

  categoryChange(event, selectedMenu: RetailMenu) {
    if (selectedMenu.IsAuthorized) {
      this.selectedCategory = event.target.id;
    } else {
      this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[selectedMenu.breakPointNumber]);
    }
  }

  private authorizeLandingScreen(landingScreenId: string): void {
    const landingScreenMenu: RetailMenu = this.codeCategories.find(c => c.id == landingScreenId);
    if (landingScreenMenu.IsAuthorized) {
      this.selectedCategory = landingScreenId;
    }else{
      this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[landingScreenMenu.breakPointNumber]);
    }
  }
}
