import { ObjectChange } from "@models/ChangeTrackingOperationResultDTO";

export interface Processor {
    Process(changes : ObjectChange, additiondata : object, propertyId: number) : void;
}

//DONT ADD OBJECT TYPES UNLESS IT HAS CORRESPONDING PROCESSOR
export enum ObjectType {
  Party,
  PartyNote,
  BookingDetail,
  PartyMessage,
  //PartyTable,
  Contact,
  ManualSlot,
  StandaloneTable,
  TableBlockingRule,
  TableBlockingRuleShift,
  TableBlockingRuleTable,
  AutoServerArea,
  AuditLogItem,
  BookedSessions,
  SecondaryContact,
  BookingContactAddonItem,
  BookingContact,
  BookingContactState,
  CoverTypeQuantity,
  BookingAmount,
  ContactCustomFields,
  ActivityCustomizations,
  AddonNote,
  GuestItineraryMapping
}

//export enum ObjectType {
//    Party = "Party",
//    PartyNote = "PartyNote",
//    PartyMessage = "PartyMessage",
//    Contact = "Contact",
//    TableBlockingRule = "TableBlockingRule",
//    TableBlockingRuleShift = "TableBlockingRuleShift",
//    TableBlockingRuleTable = "TableBlockingRuleTable",
//}
