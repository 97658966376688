<section class="custom-fee-pop-container newVersionWrapper">
  <div class="pop-head">
    <label class="inner-header pl-4">{{ headerTitle }}</label>
    <i aria-hidden="true" class="material-icons inner-close float-md-right cursPointer" (click)="onCancel()">close</i>
  </div>
  <div class="pop-contents radio-class">
    <section class="h-100">
      <form [formGroup]="customFeeForm" class="formheight" autocomplete="off">
        <mat-dialog-content class="p-4 m-0">
          <div class="h-100">
            <div class="ag_display--flex ">
                <mat-form-field class="w-40 pr-3" [floatLabel]="floatLabel" [ngClass]="[isEdit ? 'ag_section--disable' : '']">
                  <input matInput type="text" formControlName="code" [maxlength]="nameMaxLength" 
                    [placeholder]="captions.lbl_customFeeCode" [required]="true" inputtype="capitalise,notallowspace,nospecailchar"
                    class="nights-field" automationId="'Txt_createCustomFee_code'">
                  <mat-error>
                    {{ captions.err_missingCode }}
                  </mat-error>
                </mat-form-field>
                <ag-input formControlName="fee" inputtype="nospecailchar" [maxlength]="nameMaxLength"
                  [placeholder]="captions.lbl_fee" [required]="true" class="nights-field w-40" automationId="'Txt_createCustomFee_fee'">
                </ag-input>
            </div>
            <div class="ag_display--flex align-items-end">
              <div class="pr-3 w-40">
                <label class="labelcolor ag_w--100">
                  {{captions.lbl_valuein}}
                </label>
                <mat-button-toggle-group class="ag_w--100" (change)="selectionChange($event)" [value]="isAmount" [attr.automationId]="'Tog_createCustomFee_taxin'">
                  <mat-button-toggle value="false" class="w-50 LW14">{{
                    captions.lbl_percentage
                    }}</mat-button-toggle>
                  <mat-button-toggle value="true" class="w-50 LW14">{{
                    flatAmount
                    }}</mat-button-toggle> 
                </mat-button-toggle-group>
              </div>
              <ng-container *ngIf="isAmount == 'false'">
                <div class="w-40">
                  <app-ag-percentage [inputs]="percentageConfig" (onInputBlur)="percentageBlur($event)" (onInput)="percentageBlur($event)"></app-ag-percentage>  
                </div>
              </ng-container>
              <ng-container *ngIf="isAmount == 'true'">
                <div class="pos-rel-mat-err w-40" [ngClass]="customFeeForm.controls.amount.hasError('min')?'border-red':''">
                  <ag-input formControlName="amount" type="CURRENCY" min="0.001"
                    [placeholder]="captions.lbl_amount" required automationId="'Txt_createCustomFee_amount'"></ag-input>
                <mat-error *ngIf="customFeeForm.controls.amount.hasError('min')">
                  {{captions.err_min}}
                </mat-error>
                </div>
              </ng-container>
            </div>

            <div class="ag_display--flex ">
                <mat-form-field [floatLabel]="floatLabel" class="LW14 date-picker-width w-40 pr-3" appearance="legacy">
                  <mat-label>{{ captions.lbl_startDate }}</mat-label>
                  <input matInput [matDatepicker]="startDate" dateRestricter [placeholder]="placeholderFormat" [attr.automationId]="'Txt_createCustomFee_startDate'"
                    formControlName="startDate" [min]="minStartDate" (dateChange)="CheckAvailableDays('START')" />
                  <mat-datepicker #startDate (dateChange)="DateChanged('START')"></mat-datepicker>
                  <mat-error *ngIf="
                      customFeeForm.controls['startDate'].hasError(
                        'matDatepickerParse'
                      )
                    ">{{ captions.dateFormat }}</mat-error>
                  <mat-error *ngIf="
                      customFeeForm.controls['startDate'].hasError(
                        'required'
                      ) &&
                      !customFeeForm.controls['startDate'].hasError(
                        'matDatepickerParse'
                      )
                    ">{{ captions.Missing }}
                    {{ captions.lbl_startDate }}</mat-error>
                  <mat-error *ngIf="
                      customFeeForm.controls['startDate'].hasError(
                        'matDatepickerMin'
                      )
                    ">{{ captions.minimum }}
                    {{ captions.lbl_startDate }}</mat-error>
                  <mat-error *ngIf="
                      customFeeForm.controls['startDate'].hasError(
                        'matDatepickerMax'
                      )
                    ">{{ captions.maximum }}
                    {{ captions.lbl_startDate }}</mat-error>
                  <i aria-hidden="true" matSuffix
                    class="icon-Calender pointercursor fs-xlarge position-relative d-inline-block customCalendar"
                    (click)="startDate.open()"></i>
                </mat-form-field>
                <mat-form-field [floatLabel]="floatLabel" class="w-40 LW14 date-picker-width" appearance="legacy">
                  <mat-label>{{ captions.lbl_endDate }}</mat-label>
                  <input matInput [matDatepicker]="endDate" dateRestricter [placeholder]="placeholderFormat" [attr.automationId]="'Txt_createCustomFee_endDate'"
                    formControlName="endDate" [min]="minEndDate" (dateChange)="CheckAvailableDays('END')" />
                  <mat-datepicker #endDate (dateChange)="DateChanged('END')"></mat-datepicker>
                  <mat-error *ngIf="
                      customFeeForm.controls['endDate'].hasError(
                        'matDatepickerParse'
                      )
                    ">{{ captions.dateFormat }}</mat-error>
                  <mat-error *ngIf="
                      customFeeForm.controls['endDate'].hasError('required') &&
                      !customFeeForm.controls['endDate'].hasError(
                        'matDatepickerParse'
                      )
                    ">{{ captions.Missing }}
                    {{ captions.lbl_endDate }}</mat-error>
                  <mat-error *ngIf="
                      customFeeForm.controls['endDate'].hasError(
                        'matDatepickerMin'
                      )
                    ">{{ captions.minimum }}
                    {{ captions.lbl_endDate }}</mat-error>
                  <mat-error *ngIf="
                      customFeeForm.controls['endDate'].hasError(
                        'matDatepickerMax'
                      )
                    ">{{ captions.maximum }}
                    {{ captions.lbl_endDate }}</mat-error>
                  <i aria-hidden="true" matSuffix
                    class="icon-Calender cursor fs-xlarge position-relative d-inline-block customCalendar"
                    (click)="endDate.open()"></i>
                </mat-form-field>
            </div>

            <div class="d-inline-block pt-2">
              <label class="labelcolor">{{
                captions.lbl_available_days
                }}</label>
              <div class="pt-1 ag_display--flex-wrap">
                <button type="button" mat-raised-button [disabled]="availableDaysArr.indexOf(Day.name) == -1" attr.automationId='Btn_createCustomFee_{{Day}}'
                  class="maxwidth75 minwidth70 LW14 body-bordercolor p-0 mb-2 clickbtntxtcss" [ngClass]="
                    (daysArrayVar | isexistpipe : selectedDaysArr : Day)
                      ? 'body-bgcolor whitecolor'
                      : ''
                  " *ngFor="let Day of DaysArr" title="{{ Day.fullname }}"
                  (click)="toggleButtonClick($event, 'Day', Day)">
                  {{ Day.name }}
                </button>
              </div>
            </div>

            <div class="ag_display--flex  pt-3">
              <!-- <div class=" pr-4"> -->
                <!-- <div class="m_datepicker position-relative"> -->
                <!-- <mat-form-field [floatLabel]="floatLabel" class="timerWidth date-picker-width ag_w--100">
                  <input matInput type="text" [placeholder]="captions.lbl_startTime"
                    [format]="localization.getTimeFormat()" [ngxTimepicker]="toggleTimepicker" [attr.automationId]="'Txt_createCustomFee_startTime'"
                    formControlName="startTime" value="{{ currentTime }}" autocomplete="off" style="width: 86%"
                    [readonly]="true" />
                  <ngx-material-timepicker-toggle matSuffix class="pt-2 ngx-material-timepicker-toggle"
                    [for]="toggleTimepicker">
                    <i aria-hidden="true" class="icon-scheduledclock cursor" ngxMaterialTimepickerToggleIcon></i>
                  </ngx-material-timepicker-toggle>
                  <ngx-material-timepicker class="pt-2" #toggleTimepicker enableKeyboardInput="true"
                    (timeSet)="onStartTimeSet($event)" (closed)="isClosed($event)" [cancelBtnTmpl]="cancelBtn"
                    [confirmBtnTmpl]="confirmBtn"></ngx-material-timepicker>
                  <ng-template #cancelBtn><button class="timepickerCustBtn timepicker-button picker-cancel">
                      {{ captions.btn_cancel }}
                    </button></ng-template>
                  <ng-template #confirmBtn><button class="timepickerCustBtn timepicker-button picker-ok">
                      {{ captions.btn_ok }}
                    </button></ng-template>
                </mat-form-field> -->
                <app-ag-time-picker class="w-40 pr-3" [inputs]="startTimeInputs" (timeChangeEvent)="starttimeChange($event)"></app-ag-time-picker>
                <!-- </div> -->
              <!-- </div> -->
              <!-- <div class=""> -->
                <!-- <mat-form-field [floatLabel]="floatLabel" class="timerWidth date-picker-width ag_w--100">
                  <input matInput type="text" class="timer-field" [format]="this.localization.getTimeFormat()"
                    (focus)="openPicker($event)" [ngxTimepicker]="toggleEndTimepicker" [attr.automationId]="'Txt_createCustomFee_endTime'"
                    [placeholder]="captions.lbl_endTime" [min]="customFeeForm.controls.startTime.value" name="endTime"
                    autocomplete="off" [readonly]="true" formControlName="endTime" />
                  <ngx-material-timepicker-toggle class="ngx-material-timepicker-toggle" matSuffix
                    [for]="toggleEndTimepicker">
                    <i aria-hidden="true" class="icon-scheduledclock clock_icon cursor" ngxMaterialTimepickerToggleIcon
                      (click)="openPicker($event)"></i>
                  </ngx-material-timepicker-toggle>
                </mat-form-field>

                <ngx-material-timepicker #toggleEndTimepicker enableKeyboardInput="true"
                  (timeSet)="onEndTimeSet($event)" (closed)="isClosed($event)" [cancelBtnTmpl]="cancelBtn"
                  [confirmBtnTmpl]="confirmBtn"></ngx-material-timepicker>
                <ng-template #cancelBtn><button class="timepickerCustBtn timepicker-button">
                    {{ captions.btn_cancel }}
                  </button></ng-template>
                <ng-template #confirmBtn><button class="timepickerCustBtn timepicker-button">
                    {{ captions.btn_ok }}
                  </button></ng-template> -->
                  <app-ag-time-picker class="w-40" [inputs]="endTimeInputs"></app-ag-time-picker>
              <!-- </div> -->
            </div>


            <div class="ag_form-control--2">
              <mat-label class="labelcolor">{{captions.lbl_linkedItems}}</mat-label>
              <span class="mandatoryError">*</span>
              <app-chip-search [searchMaxCharLength]="50" [allData]="customFeeRetailItems" [selectedData]="selectedFromList"
                [searchKey]="searchKey" [autoCompleteKeys]="autoCompleteKeys" [selectedChipKey]="selectedChipKey"
                (selectedChipDataEmit)="selectedChipDataEmit($event)" (searchTextEmit)="searchByKeyword($event)"
                (onChipRemove)="onChipRemoveEvent($event)" [placeholderText]="searchPlaceholder" [attr.automationId]="'Srch_createCustomFee_chipSearch'"></app-chip-search>
            </div>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions class="pop-actions pl-4 m-0">
          <div class="pop-button ag_display--flex">
            <app-button [buttontype]="saveBtn" (valueChange)="save()"
              [attr.automationId]="'Btn_applyCreditCharges_save'" [attr.automationId]="'Btn_createCustomFee_save'"></app-button>
            <app-button class="" [buttontype]="cancelButton" (valueChange)="onCancel()" [attr.automationId]="'Btn_createCustomFee_cancel'"></app-button>

          </div>
        </mat-dialog-actions>
      </form>
    </section>
  </div>
</section>