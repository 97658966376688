import { Component, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { CacheService } from '@app/core/services/cache.service';
import { Utilities } from '@app/core/utilities';
import { PopupService } from '@app/popup-module/popup.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { CommentType } from '@app/shared/models/ActivityRatePlanRequestDTO';
import { PartyService } from '@app/shared/services/party.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rate-negotiation',
  templateUrl: './rate-negotiation.component.html',
  styleUrls: ['./rate-negotiation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RateNegotiationComponent implements OnInit {
  rateNegotiateConfig: FieldConfig[];
  OperationCurrency: any;
  subscriptions: Subscription = new Subscription();
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;

  constructor(public ps: PartyService, public cs: CacheService,public popUpService:PopupService) {
    this.subscriptions.add(this.cs.settings.subscribe(data => {
      this.OperationCurrency = data.General.OperationCurrency;
    }));
  }

  ngOnInit(): void {
    this.rateNegotiateConfig = [
      // {
      //   type: 'input',
      //   label: (this.ps.getSelectedAddOns().length > 0) ? 'TotalAmountWithoutAddOn' : 'TotalAmount',
      //   name: 'Total',
      //   class: 'reject-options__input',
      //   showErrorText: true,
      //   directive: 'DecimalNumbersOnly',
      //   prefix: this.OperationCurrency,
      //   isTranslate: false,
      //   value:Number(this.ps.rateNegotiationObject.Total).toFixed(this.cs.getNumberOfFractionalDigits()),
      //   disabled:true
      // },
      // {
      //   type: 'input',
      //   label: (this.ps.getSelectedAddOns().length > 0) ? 'PayableAmountWithoutAddOn' : 'PayableAmount',
      //   name: 'Payable',
      //   class: 'reject-options__input',
      //   directive: 'DecimalNumbersOnly',
      //   prefix: this.OperationCurrency,
      //   showErrorText: true,
      //   isTranslate: false,
      //   value: Number(this.ps.rateNegotiationObject.Payable).toFixed(this.cs.getNumberOfFractionalDigits())
      // },
      {
        name: 'reasonText',
        type: 'textarea',
        label: 'Reason',
        rows: 2,
        inputType: 'text',
        showHint: true,
        charLength: 400,
        textAreaAppearance: 'outline',
        class: 'reject-options__input',
        value: this.ps.rateNegotiationObject.Reason,
        validation:[Validators.required]
      }]
  }

  ngAfterViewInit() {
    this.rateNegotiateConfig.forEach(data => {
      data.validation = [Validators.required];
    });
    this.popUpService.saveBtnEnable$.next(true);
    this.formchangeSubscribe();
  }

  formchangeSubscribe() {
    this.components.first.form.valueChanges.subscribe(data => {
      this.setpopupData(data);
    //  (data.reasonText!='') ? this.popUpService.saveBtnEnable$.next(true) : this.popUpService.saveBtnEnable$.next(false);
    });
  }

  setpopupData(data) {
    this.ps.rateNegotiationObject = {
      Total: data.Total,
      Payable: data.Payable,
      Reason: data.reasonText.find(reason => reason.commentType == CommentType.Negotiation)
    }
  }
}
