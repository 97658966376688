import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { AppService } from '@app/app.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '@popup-module/popup.service';
import { FloorplanService } from '@services/floorplan.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-floor-plan',
  templateUrl: './new-floor-plan.component.html',
  styleUrls: ['./new-floor-plan.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewFloorPlanComponent implements OnInit, AfterViewInit, OnDestroy {
  floorDetailsconfig: FieldConfig[];
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  @Input() data: any;
  subscriptions: Subscription = new Subscription();
  constructor(private fs: FloorplanService, private appService: AppService, private ps: PopupService, private tranlateService: TranslateService) {
  }

  ngOnInit() {
    //this.hideLabel = this.data.inputData.basedOn=='' ? true :false,

    this.floorDetailsconfig = [{
      type: 'select',
      name: 'basedOn',
      label: 'basedOn',
      options: [],
      class: 'new-floorPlan__floor-based',
      showErrorText: true,
      isTranslate: false,
      value: this.data.inputData ? this.data.inputData.basedOn : this.data.layout[0].Id,
      validation: this.data.inputData.basedOn == '' ? [Validators.nullValidator] : [Validators.required]
    },
    {
      type: 'input',
      name: 'floorPlan',
      label: 'guestNameText',
      class: 'new-floorPlan__floor-name',
      showErrorText: true,
      validation: [Validators.required]
      //cellClick: this.setSelectedLayout.bind(this)
    }];
    this.getLayoutOptions();
    // if(this.data.inputData.basedOn==''){
    //   this.floorDetailsconfig.splice(this.floorDetailsconfig.findIndex(x=>x.name='basedOn'),1);
    // }
  }

  ngAfterViewInit() {
    this.fs.newFloorForm = this.dynamicForm.form;
    this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe(val => {
      this.ps.saveBtnEnable$.next(this.dynamicForm.form.valid)
    }));
    if (this.data) {
      this.dynamicForm.form.patchValue(this.data.inputData)

    }
    if (this.dynamicForm.form.controls.floorPlan.value && this.dynamicForm.form.controls.floorPlan.value.length > 0) {
      this.dynamicForm.form.controls.basedOn.disable();
      this.floorDetailsconfig[0].class = this.floorDetailsconfig[0].class + ' new-floorPlan__remove-floor-based';
    }
    // this.dynamicForm.form.valueChanges.subscribe(data => {
    //   this.fs.newFloorPlanName = data.floorPlan;
    // });
  }

  getLayoutOptions() {
    this.floorDetailsconfig[0].options = this.data.layout.map(x => { return { id: x.Id, value: x.Name } });
    this.floorDetailsconfig[0].options.push({ id: -1, value: this.tranlateService.instant('emptyPlan') });

  }
  // setModelFloorPlan(event){
  //   this.fs.selectedModelPlan = event.value;
  // }

  ngOnDestroy(): void {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

}
