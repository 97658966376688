import { Component, HostListener, Input, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { PopupService } from "@app/popup-module/popup.service";
import { environment } from "@environments/environment";
import { CRSAPIService, CRSUpdateReservationAttempt } from "../crs-api.service";
import { PartyService } from "@app/shared/services/party.service";
import { popupDialogDimension } from "@app/shared/constants/globalConstants";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-crs-payment-handler',
    templateUrl: './crs-payment-handler.component.html',
    styleUrls: ['./crs-payment-handler.component.scss']
})
export class CRSPaymentHandlerComponent implements OnInit {

    @Input() data: any;

    constructor(private popupService: PopupService, public dialogRef: MatDialogRef<CRSPaymentHandlerComponent>, private crsapi: CRSAPIService, private partyService: PartyService, private translate: TranslateService) {

    }

    @HostListener('window:message', ['$event'])
    onMessage({ origin, data }) {
        if (origin !== environment.authorizeIframeResponseOrigin && origin !== environment.rGuestPayIframeResponsOrigin) { // set your origin
            return false;
        }
        this.paymentHandler(data,)
        // this.dialogRef.close({ action: "confirmed", data });
    }

    ngOnInit(): void {
        this.popupService.confirmedAction$.subscribe(cancelled => {
            this.dialogRef.close({ action: "cancelled" });
        })
    }

    showError(message: string) {
        this.partyService.showErrorPopUp(message, popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeight, this.translate.instant('Error'));
    }

    paymentHandler(payResponse) {
        let gateway = "AuthorizePay";
        if (gateway == "AuthorizePay" && payResponse.type == "txn-completed" && payResponse.transactionInfo != null) { //Authorize Pay

            var transInfo = payResponse.transactionInfo.transactionInfo;

            if (transInfo.errorCode || transInfo.successCode == 0) {
                if (transInfo.errorMessage) {
                    this.showError(transInfo.errorMessage);
                }
                else {
                    this.showError(this.translate.instant("CardInformationProcessNotCompleted"));
                }
            } else if (payResponse && transInfo.successCode == 1) {
                var transactions = this.processTransactions(transInfo);
                var lockIds = [this.data.lockData.SlotLockIdDTO.Id];
                var updateReservationRequest: CRSUpdateReservationAttempt = {
                    RestaurantId: this.data.propertyId,
                    ReservationAttemptId: this.data.ReservationAttemptId,
                    ReservationAttemptIds: [this.data.ReservationAttemptId],
                    SlotLockIds: lockIds,
                    PartyId: this.data.partyId,
                    PartyPaymentId: null,
                    //BookingID: BookingID,
                    TokenType: "",
                    PaymentAmount: transInfo.paidAmount,
                    PaymentGateway: "AuthorizePay",
                    AuthorizeTransactions: transactions,
                    CardNumber: (transactions && transactions[0].CardNumber) ? transactions[0].CardNumber : ""
                };

                this.crsapi.updateReservationAttempt(updateReservationRequest).subscribe(updateReservationAttemptInfo => {

                    if (updateReservationAttemptInfo.PendingAmount && updateReservationAttemptInfo.PendingAmount > 0 && updateReservationAttemptInfo.RedirectUrl) {
                        this.data.CreditCardIFrameURL = updateReservationAttemptInfo.RedirectUrl;
                    } else {
                        this.dialogRef.close({ action: "confirmed", data: updateReservationAttemptInfo.ReservationState[0] });
                    }
                })
                //scope.UpdateStatus(dto);
                //postData(url, dto);
            } else {
                this.showError(this.translate.instant(''));
            }
        } else {
            if (payResponse.type != "txn-completed" && payResponse.type != "internal-process-msg" && payResponse.message) {
                this.showError(payResponse.message);
            }
        }
    }


    processTransactions(transInfo) {
        var transactions = transInfo.payments;
        var authorizeTransactions = [];

        if (transactions && transactions.length > 0) {
            transactions.forEach(transaction => {
                if (transaction.successCode == 1) {
                    var name = '';
                    var paymentDetail = transaction.paymentDetail;
                    var roomDetails = {
                        RoomNumber: null,
                        ConfirmationNumber: null,
                        LastName: null
                    };
                    var pointsDetails = {
                        AccountNumber: null,
                        BucketName: null,
                        Name: null,
                        LoyaltyTransactionId: null
                    };

                    if (transaction.paymentType == 1 || transaction.paymentType == 2) { //credit card
                        name = paymentDetail.accountName;
                    }

                    if (transaction.paymentType == 3) { //Post to room charges
                        roomDetails.RoomNumber = paymentDetail.tender;
                        roomDetails.ConfirmationNumber = paymentDetail.cardNumber;
                        roomDetails.LastName = paymentDetail.accountName;
                        name = paymentDetail.accountName;
                    }
                    if (transaction.paymentType == 5) { //Loyalty
                        pointsDetails.BucketName = paymentDetail.tender;
                        pointsDetails.AccountNumber = paymentDetail.cardNumber;
                        pointsDetails.Name = paymentDetail.accountName;
                        pointsDetails.LoyaltyTransactionId = paymentDetail.followOnData;
                        name = paymentDetail.accountName;
                    }
                    var dto = {
                        CardNumber: paymentDetail.cardNumber ? paymentDetail.cardNumber : "",
                        ExpirationDate: paymentDetail.expirationDate ? paymentDetail.expirationDate : "",
                        Name: name ? name : "",
                        Token: paymentDetail.token ? paymentDetail.token : null,
                        TransactionFollowOnData: paymentDetail.followOnData ? paymentDetail.followOnData : null,
                        AuthorizeTransactionID: transaction.authorizeTransactionID,
                        Amount: transaction.paidAmount,
                        PaymentType: transaction.paymentType ? transaction.paymentType : null,
                        InvoiceID: transaction.invoiceID ? transaction.invoiceID : null,
                        TransactionDate: transaction.transactionsDate ? transaction.transactionsDate : null,
                        RoomNumber: roomDetails && roomDetails.RoomNumber ? roomDetails.RoomNumber : null,
                        ConfirmationNumber: roomDetails && roomDetails.ConfirmationNumber ? roomDetails.ConfirmationNumber : null,
                        BucketName: pointsDetails && pointsDetails.BucketName ? pointsDetails.BucketName : null,
                        LoyaltyAccountNumber: pointsDetails && pointsDetails.AccountNumber ? pointsDetails.AccountNumber : null,
                        LoyaltyTransactionId: pointsDetails && pointsDetails.LoyaltyTransactionId ? pointsDetails.LoyaltyTransactionId : null,
                    };
                    authorizeTransactions.push(dto);
                } else if (transaction.errorMessage) {
                    this.partyService.showErrorPopUp(transaction.errorMessage, popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeight, this.translate.instant('Error'));
                }
            });
            return authorizeTransactions;
        }
    }

}