import { AfterViewInit, Component, Inject, Input, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CacheService } from '@app/core/services/cache.service';
import { SettingsDTO, SettingType, StandaloneTableDTO } from '@app/shared/models/RestaurantDTO';
import { buttonTypes, ComponentTypes, ContentView, DayOfWeek, Menu, OperationResultState, PartyNoteType, PartyState, PartyStatus, PartyType, PopupType, ReservationStatus, ReservationType, TableBlockBy, TableBlockingRuleFrequencyType, TableStatus } from '@constants/commonenums';
import * as GlobalConst from "@app/shared/constants/globalConstants";
import * as Util from "@app/shared/utilities/util-functions";

@Component({
    selector: 'app-print-manager',
    templateUrl: './print-manager.component.html',
    styleUrls: ['./print-manager.component.scss']
})
export class PrintManagerComponent implements OnInit, AfterViewInit {
    contentView: ContentView;
    ContentView = ContentView;
    isReservation: boolean;
    includePartyNotes = true;
    includeContactNotes = true;
    includeAddons = true;
    includeGuestTypes = true;
    _settings: SettingsDTO = {} as SettingsDTO;
    selectedParties = [];
    ReservationType = ReservationType;
    standAloneTables = [];
    dateFormat: string = "dd-MM-yyyy hh:mm a";
    constructor(private _cs: CacheService, public dialogRef: MatDialogRef<PrintManagerComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData) { }
    ngOnInit(): void {
        this.selectedParties = this.dialogData;
        this.setStandaloneTables();
        this._settings = this._cs.settings.value;
        if(this._settings?.General?.DateFormat) {
            this.dateFormat = this._settings?.General?.DateFormat + ' hh:mm a';
            this.dateFormat = this.dateFormat.replace('DD', 'dd');
        }
        this.includePartyNotes = this._settings?.MerchantSettings?.IncludeNotes?.SettingValue == SettingType.ENABLE ? true : false;
        this.includeContactNotes = this._settings?.MerchantSettings?.IncludeContactNotes?.SettingValue == SettingType.ENABLE ? true : false;
        this.includeGuestTypes = this._settings?.MerchantSettings?.IncludeCovers?.SettingValue == SettingType.ENABLE ? true : false;
        this.includeAddons = this._settings?.MerchantSettings?.IncludeAddOns?.SettingValue == SettingType.ENABLE ? true : false;
    }

    setStandaloneTables() {
        this.standAloneTables = [];
        const floorPlan = this._cs.layout.value.FloorPlans;
        for (let i = 0; i < floorPlan.length; i++) {
            let Tables = floorPlan[i].StandaloneTables;
            Tables.forEach(element => {
                this.standAloneTables.push(element);
            });
        }
    }

    ngAfterViewInit(): void {
        Util.printChit("Chit-Print_Print-Section-Manager", GlobalConst.chitPrintStyles, () => this.dialogRef.close());
    }

}
