<form autocomplete="off" name="General" [formGroup]="generalFormGroup" class="h-100 setupGeneral" container="body">
  <div class="grid-container">


    <div class="left-grid">
      <ng-scrollbar  [disabled] = "isSyncItem || IsViewOnly">
        <div [ngClass]="isEdit && IsViewOnly ? 'disableItemSetup' : ''" (click)="stopdefaultClick($event)" (keydown)="stopdefaultClick($event)">
        <div class="outletManager pt-4 pl20" *ngIf="functionalities?.ShowOutletSelectionFieldInCreateRetailItem">
          <div class="pb13">
            <label class="label1 pb-0 LW14">{{captions.OUTLETMANAGER}}</label>
          </div>

          <button attr.automationId='Btn_{{automationId}}_outletName{{i}}' retailViewMore mat-raised-button type="button" (NoOfElements)="bindLocations($event)"
            [ngClass]="_utilities.isExist(selectedOutletMgrArr, item) !=-1 ? 'spaClickButton mb-2 clickbtntxtcss mlneg1 body-bgcolor whitecolor body-bordercolor button--primary' : 'spaClickButton mb-2 clickbtntxtcss mlneg1 body-bordercolor button--secondary'"
            class=" filterBtn" *ngFor="let item of outlets | slice:0:noOfLocations; let i = index"
            value="{{item.name}}" title="{{item.name}}" (click)="toggleButtonClick($event,item)">
            <!--disabled-->
            {{item.name}}
          </button>
          <div class="d-inline-block" *ngIf="outlets && outlets.length > noOfLocations" container="body">
            <a attr.automationId='Lbl_{{automationId}}_viewMore' class="text-color LW14"
              (click)="this._ViewMoreServiceService.setdata($event,outlets,GetSelectedOutletMgrArrId(selectedOutletMgrArr) ,noOfLocations,'ServiceType',['id','name'])">{{outlets.length - noOfLocations}} {{this.localization.captions.common.More}}</a>
          </div>
          <mat-error class="" *ngIf="selectedOutletMgrArr.length == 0">
            {{captions.MissingOutletSelection}}
          </mat-error>
        </div>
        <div class="pl-4 pt25 pl20 pr-4">
          <div><label class="label1 LW14">{{captions.GENERALINFORMATION}}</label></div>
          <div>
            <div class="float-left imageupload py-3" [ngClass]=" generalFormGroup['controls'].itemcatradiogrp.value === 10 ? 'button_invalid' : ''  ">
                  <app-retail-image-uploader [isViewOnly]="IsViewOnly" [emptyImgCaption]="this.spacaptions.setting.UploadYourPhotoRetailImage" [imgData]="_retailsetupservice.url" (fileDeleted)="_retailsetupservice.fileDeleted()" (fileUploaded)="_retailsetupservice.fileUploaded($event)" (fileSizeExceeded)="_retailsetupservice.fileSizeExceeded()"></app-retail-image-uploader>
            </div>
            <div class="float-left genraldetail ml-4">
            <div class="pt-2 pt-2 w420">
              <app-search-linked-retail-items attr.automationId='Srch_{{automationId}}_copyItem' [Disabled]="isEdit" [displayTexts]="linkItemCaptions" [filteredData]="searchFilter" (searchTextEmit)="searchTextEmit($event)"
              [autocomplete]="generalFormGroup.controls.copyitem" (doneEvtEmitter)="LinkedItemEmit($event)">
            </app-search-linked-retail-items>
            </div>
            <div class="pt-2 pt-2">
              <div class="LW14 float-left pr-2 pt-1  textellipsis">{{captions.AutogenerateNumber}}</div>
              <div class="align-items-center  ml-auto mr-2">
                <app-retail-toggle-switch attr.automationId ='Tog_{{automationId}}_autoGenerateNo' formControlName="autogenerateno" (changeToggleEvent)=generateItemNumber($event)
                  [disableToggle]='isEdit'>
                </app-retail-toggle-switch>
              </div>
            </div>
            <div class="pt-2 pt-2 ">
              <mat-form-field class="mr60" [floatLabel]="floatLabel">
                <input attr.automationId = 'Txt_{{automationId}}_itemNo' matInput formControlName="itemnumber"  maxlength="17" inputtype="nonnegative,nodecimal" [duplicateItem]="duplicateItemNumber"
                  [placeholder]="captions.ItemNumber" (focusout)=getItemDetailsByItemNumber($event)>
                <mat-error class="" *ngIf="generalFormGroup.controls['itemnumber'].hasError('required')">
                  {{captions.MissingItemNumber}}
                </mat-error>
                <mat-error class="" *ngIf="generalFormGroup.controls['itemnumber'].hasError('invalid')">
                  {{captions.DuplicateItemNumber}}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w320" [floatLabel]="floatLabel">
                <input attr.automationId = 'Txt_{{automationId}}_itemDescription'  matInput formControlName="itemdescription" inputtype="restrictscript" maxlength="50" [placeholder]="captions.ItemDescription"
                  [readonly]="isSyncItem || IsViewOnly" (focusout)="itemDescChange()">
                <mat-error class="" *ngIf="generalFormGroup.controls['itemdescription'].hasError('required')">
                  {{captions.MissingItemDescription}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="pt-2">
              <mat-form-field class="mr60" [floatLabel]="floatLabel">
                <input attr.automationId = 'Txt_{{automationId}}_salePrice' type="text" matInput formControlName="salesprice"
                [placeholder]="captions.SalesPrice +' (' +localization.currencySymbol+')'" RetailCurrencyFormatter
                  (input)="_retailsetupservice.calculateItemPrice($event, 'SP');"
                  [readonly]="isSyncItem || IsViewOnly"
                  (blur)="OnSalePriceBlur($event)"
                   >
                <mat-error class="" *ngIf="generalFormGroup.controls['salesprice'].hasError('required')">
                  {{captions.MissingSalesPrice}}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="mr60" [floatLabel]="floatLabel">
                <input attr.automationId = 'Txt_{{automationId}}_memberprice' type="text" matInput formControlName="memberprice"
                [placeholder]="captions.MemberPrice +' (' +localization.currencySymbol+')'" RetailCurrencyFormatter
                  [readonly]="isSyncItem || IsViewOnly">
              </mat-form-field>
            </div>
            <div class=" pt-2">
              <mat-form-field class="mr60" [floatLabel]="floatLabel">
                <input attr.automationId = 'Txt_{{automationId}}_curitemcost' type="text" matInput formControlName="curitemcost"
                [placeholder]="captions.CurrentItemCost +' (' +localization.currencySymbol+')'" RetailCurrencyFormatter
                  (blur)="_retailsetupservice.calculateItemPrice($event, 'CP');"
                  [readonly]="isSyncItem || IsViewOnly " >
              </mat-form-field>
              <mat-form-field class="" [floatLabel]="floatLabel">
                <input attr.automationId = 'Txt_{{automationId}}_marginpercent' matInput formControlName="marginpercent"
                   [placeholder]="captions.MarginPercentage" percentinputtype='onlynumber,decimal,validPercentage,preMaxdecimal' [maxpredecimalValue]="percentageLength"
                  (blur)="_retailsetupservice.calculateItemPrice($event, 'MP');"
                  [readonly]="isSyncItem || IsViewOnly">
              </mat-form-field>
            </div>
            <div class="pt-2 pt-2">
              <mat-form-field class="mr60" [floatLabel]="floatLabel">
                <input attr.automationId = 'Txt_{{automationId}}_barcode' matInput formControlName="barcode" [placeholder]="captions.Barcode" [duplicateItem]="duplicateBarCode"
                  [readonly]="isSyncItem || IsViewOnly " (focusout)="ValidateDuplicateBarcode()" maxlength="100">
                <mat-error class="mb-2"  *ngIf="generalFormGroup.controls['barcode'].hasError('invalid')">
                  {{captions.DuplicateBarcode}}
                </mat-error>
              </mat-form-field>

              <mat-form-field class="" [floatLabel]="floatLabel">
                <input attr.automationId = 'Txt_{{automationId}}_secbarcod' matInput formControlName="secbarcode" [placeholder]="captions.SecondaryBarcode" [duplicateItem]="duplicateSecondaryBarcode"
                  [readonly]="isSyncItem || IsViewOnly" (focusout)="ValidateDuplicateSecondayBarcode()" maxlength="100">
                <mat-error class="mb-2"  *ngIf="generalFormGroup.controls['secbarcode'].hasError('invalid')">
                  {{captions.DuplicateSecondaryBarcode}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
            <div class="d-flex switchToggles pt25 clearFloat">
              <div class="toggletab pl-0 pr-3">
                <ul class="toggleArea w-100 pl-4 pt-2">
                  <li class="mb-2" *ngIf="functionalities?.ShowCommissionableItemToggleInCreateRetailItem"
                    [ngClass]="generalFormGroup['controls'].itemcatradiogrp.value === 4 || generalFormGroup['controls'].itemcatradiogrp.value === 10  || generalFormGroup['controls'].itemcatradiogrp.value == retailItemTypes.CustomFee  ? 'button_invalid' : ''  ">
                    <div class="LW14 float-left pr-2 pt-1 w-66 textellipsis">{{captions.CommisionableItem}}</div>
                    <div class="align-items-center float-right ml-auto mr-2">
                      <app-retail-toggle-switch attr.automationId = 'Tog_{{automationId}}_commissionableitem' formControlName="commissionableitem" (changeToggleEvent)="commissionableToggleChange($event[0])"></app-retail-toggle-switch>
                    </div>
                  </li>
                  <li class="mb-2" *ngIf="functionalities?.ShowCommissionRequiredToggleInCreateRetailItem"
                    [ngClass]="generalFormGroup['controls'].itemcatradiogrp.value === 4 || generalFormGroup['controls'].itemcatradiogrp.value === 10  || generalFormGroup['controls'].itemcatradiogrp.value == retailItemTypes.CustomFee  ? 'button_invalid' : ''  ">
                    <div class="LW14 float-left pr-2 pt-1 w-66 textellipsis">{{captions.CommisionRequired}}</div>
                    <div class="align-items-center float-right ml-auto  mr-2">
                      <app-retail-toggle-switch attr.automationId = 'Tog_{{automationId}}_commissionreq' formControlName="commissionreq"></app-retail-toggle-switch>
                    </div>
                  </li>
                  <li class="mb-2" [ngClass]=" generalFormGroup['controls'].itemcatradiogrp.value === 10  || generalFormGroup['controls'].itemcatradiogrp.value == retailItemTypes.CustomFee ? 'button_invalid':''">
                    <div class="LW14 float-left pr-2 pt-1 w-66 textellipsis">{{captions.UseInventory}}</div>
                    <div class="align-items-center float-right ml-auto mr-2">
                      <app-retail-toggle-switch attr.automationId = 'Tog_{{automationId}}_inventory'  formControlName="inventory" (changeToggleEvent)="commissionableToggleChange($event[0])" ></app-retail-toggle-switch>
                    </div>
                  </li>
                  <li *ngIf="featureFlagInfo.AllowAccrualPoints" class="mb-2" [ngClass]=" generalFormGroup['controls'].itemcatradiogrp.value === 10 ? 'button_invalid':''">
                    <div class="LW14 float-left pr-2 pt-1 w-66 textellipsis">{{captions.AllowEarn}}</div>
                    <div class="align-items-center float-right ml-auto mr-2">
                      <app-retail-toggle-switch attr.automationId = 'Tog_{{automationId}}_allowEarn' formControlName="allowEarn"  [disableToggle]="!featureFlagInfo.AllowAccrualPoints"></app-retail-toggle-switch>
                    </div>
                  </li>
                  <li  class="mb-2" *ngIf="_retailsetupservice.ProductId == 6">
                    <div class="LW14 float-left pr-2 pt-1 w-66 textellipsis">{{captions.DisposableItem}}</div>
                    <div class="align-items-center float-right ml-auto mr-2">
                      <app-retail-toggle-switch attr.automationId = 'Tog_{{automationId}}_disposableItem' formControlName="disposableItem"></app-retail-toggle-switch>
                    </div>
                  </li>
                  <!-- Retail Taxes start -->
                  <ng-container *ngIf="!PropertyInfo.IsVATEnabled">
                    <div formArrayName="taxConfig" *ngIf="!PropertyInfo.UseRetailInterface && generalFormGroup.value.taxConfig.length != 0">
                      <label>{{captions.Taxes}}</label>
                      <div class="divToggle"
                        *ngFor="let taxInfo of generalFormGroup.get('taxConfig')['controls'];let i= index;trackBy: trackByFn">
                        <div [formArrayName]="i">
                          <label class="toglelabel mb-10 LW14" *ngIf="this.functionalities['ShowOutletLabelOnRetailItemCreationGeneralTab'] || false" >{{taxInfo.value[0].outletName}}</label>
                          <li class="mb-2" *ngFor="let taxInfoDetails of taxInfo['controls'];let j= index;trackBy: trackByFn">
                            <div [formGroupName]="j">
                              <div class="LW14 float-left pr-2 pt-1 label-width textellipsis">{{taxInfoDetails.controls.taxName.value}}</div>
                              <div class="align-items-center float-right ml-auto mr-2">
                                <app-retail-toggle-switch attr.automationId = 'Tog_{{automationId}}_isSelected' formControlName="isSelected"
                                  (changeToggleEvent)="taxInfoToggleEvt($event, j, taxInfoDetails.value, i)"></app-retail-toggle-switch>
                              </div>
                            </div>
                        </li>
                      </div>
                      </div>

                </div>
            </ng-container>
            <ng-container *ngIf="PropertyInfo.IsVATEnabled">
              <div formArrayName="taxConfig">
              <li class="mb-2"
                    *ngFor="let taxInfo of generalFormGroup.get('taxConfig')['controls'];let j= index;trackBy: trackByFn"
                    [formGroupName]="j">
                    <div class="LW14 float-left pr-2 pt-1 w-66 textellipsis">{{taxInfo.controls.taxName.value}}</div>
                    <div class="align-items-center float-right ml-auto mr-2">
                      <app-retail-toggle-switch attr.automationId = 'Tog_{{automationId}}_disposableItem' formControlName="isSelected"
                        (changeToggleEvent)="taxInfoToggleEvt($event, j, taxInfo.value)"></app-retail-toggle-switch>
                    </div>
                  </li>
                </div>
            </ng-container>
                  <!-- Retail Taxes start -->
                </ul>
              </div>
              <div class="pl20 radioTab">
                <ul class="radioInputArea w-100 pl-0">
                  <mat-radio-group attr.automationId = 'Rdo_{{automationId}}_itemcatradiogrp' formControlName="itemcatradiogrp"  class="radio-class" [(value)]="defaultRadioValue"
                    [readonly]="IsViewOnly">
                    <li *ngFor="let itemcatradiogrp of itemcatradiogrp">
                      <mat-radio-button title='{{itemcatradiogrp.name}}' class="LW14 pt-2 d-block" [disabled]="itemcatradiogrp.disable"
                        (change)="radioChange($event)" [value]="itemcatradiogrp.id">{{itemcatradiogrp.name}}
                      </mat-radio-button>
                    </li>
                  </mat-radio-group>
                  <mat-error class=""
                    *ngIf="generalFormGroup.controls['itemcatradiogrp'].hasError('required') && generalFormGroup.controls['itemcatradiogrp'].invalid">
                    {{captions.MissingItemType}}
                  </mat-error>
                </ul>
              </div>
            </div>

          </div>

          </div>
        </div>
      </ng-scrollbar>


    </div>
    <div class="right-grid" [ngClass]="{isSyncItem : 'button_invalid'}">
      <ng-scrollbar >
        <div [ngClass]="isEdit && IsViewOnly ? 'disableItemSetup' : ''" (click)="stopdefaultClick($event)" (keydown)="stopdefaultClick($event)">
          <div class="pt25 pb13">
            <label class="mb-0 LW14">{{captions.CATEGORYINFORMATION}}</label>
          </div>
          <div>
            <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--3">
              <mat-select [attr.automationId]="'Dd_general_categoryName'" [placeholder]="captions.Category" formControlName="categoryid"
                (selectionChange)="_retailsetupservice.OnCategoryChange($event.value)" [disabled]="IsViewOnly">
                <mat-option *ngFor="let catgry of Category" [value]="catgry.id">
                  {{catgry.name}}
                </mat-option>
              </mat-select>
              <mat-error class="" *ngIf="generalFormGroup.controls['categoryid'].hasError('required')">
                {{captions.MissingCategory}}
              </mat-error>
            </mat-form-field>
          </div>

        <div>
          <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--3">
            <mat-select [attr.automationId]="'Dd_general_subCategory'" placeholder="{{captions.Subcategory}} 1" formControlName="subcategory1"
              (selectionChange)="_retailsetupservice.OnSubCategory1Change($event.value)" [disabled]="IsViewOnly || generalFormGroup['controls'].itemcatradiogrp.value === 10">
                <mat-option></mat-option>
                <mat-option *ngFor="let subcatgry1 of _retailsetupservice.SubCategory1" [value]="subcatgry1.id">
                  {{subcatgry1.name}}
                </mat-option>

            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--3">
            <mat-select [attr.automationId]="'Dd_general_subCategory2'" placeholder="{{captions.Subcategory}} 2" formControlName="subcategory2"
              (selectionChange)="_retailsetupservice.OnSubCategory2Change($event.value)" [disabled]="IsViewOnly || generalFormGroup['controls'].itemcatradiogrp.value === 10">
                <mat-option></mat-option>
                <mat-option *ngFor="let subcatgry2 of _retailsetupservice.SubCategory2" [value]="subcatgry2.id">
                  {{subcatgry2.name}}
                </mat-option>

            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--3">
            <mat-select [attr.automationId]="'Dd_general_subCategory3'" placeholder="{{captions.Subcategory}} 3" formControlName="subcategory3"
              (selectionChange)="_retailsetupservice.OnSubCategory3Change($event.value)" [disabled]="IsViewOnly || generalFormGroup['controls'].itemcatradiogrp.value === 10">
                <mat-option></mat-option>
                <mat-option *ngFor="let subcatgry3 of _retailsetupservice.SubCategory3" [value]="subcatgry3.id">
                  {{subcatgry3.name}}
                </mat-option>

            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--3">
            <mat-select [attr.automationId]="'Dd_general_subCategory4'" placeholder="{{captions.Subcategory}} 4" formControlName="subcategory4"
              (selectionChange)="_retailsetupservice.OnSubCategory4Change($event.value)" [disabled]="IsViewOnly || generalFormGroup['controls'].itemcatradiogrp.value === 10">
                <mat-option></mat-option>
                <mat-option *ngFor="let subcatgry4 of _retailsetupservice.SubCategory4" [value]="subcatgry4.id">
                  {{subcatgry4.name}}
                </mat-option>

            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--3">
            <mat-select [attr.automationId]="'Dd_general_subCategory5'" placeholder="{{captions.Subcategory}} 5" formControlName="subcategory5"
               [disabled]="IsViewOnly|| generalFormGroup['controls'].itemcatradiogrp.value === 10">
                <mat-option></mat-option>
                <mat-option *ngFor="let subcatgry5 of _retailsetupservice.SubCategory5" [value]="subcatgry5.id">
                  {{subcatgry5.name}}
                </mat-option>

              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
  <mat-dialog-actions class="float-right actionButtons pr-4">
    <button [attr.automationId]="'Btn_general_close'" mat-button type="button" (click)="close()">{{this.localization.captions.common.CANCEL}}</button>
    <button [attr.automationId]="'Btn_general_next'" mat-button matStepperNext type="button" color="primary"
      [ngClass]="IsViewOnly || (!generalFormGroup.hasError('required') && generalFormGroup.valid && selectedOutletMgrArr.length > 0) ? 'body-bgcolor whitecolor body-bordercolor' : ' spa-primary-disabled'"
      class="LW14 text_capitalize mat-button">{{this.localization.captions.common.NEXT}}</button>
  </mat-dialog-actions>
</form>
<app-retail-view-more fromSection='RS' [disabledflag]="isSyncItem"
  [displayDataDetails]="this._ViewMoreServiceService.VM_displayData" (clickEmit)="emitedValue($event)"></app-retail-view-more>
