<section id="SearchItemAutoCompleteSection" >
  <label class="LW12 requriedMB linked-item-color blckClr label-color">{{this.settingCaptions.LinkedItems}}</label>
  <mat-form-field style="position: relative;" class="LW16 d-inline-block spa-searchbox w-100 custSearchbox" [floatLabel]="'never'">
    <input type="text"  class="LW14"  placeholder="{{captions.ItemNumberDescription}}" matInput  [(ngModel)]="searchText"
      [formControl]="autocomplete" (input)="filterFucntion($event)" (click)="filterFucntion($event); autocompleteShow($event)">
 
      <mat-error class="" *ngIf="!linkedItemsClicked && !autocomplete.valid">
          {{settingCaptions.MissingLinkedItem}}
      </mat-error>
 
    <div id="searchOuter" *ngIf="((searchText && autocomplete.valid)|| linkedItemsClicked) && searchFilter.length>0"  class="w-100 search-class" clickOutside (clickOutside)="clickedOutside()">

        <div id="searchData" class="search-data">
            <ng-scrollbar  >
            <mat-option *ngFor="let option of searchFilter" (click)='SelectedRecord(option)' >
                <div class="LW14 text-capitalize" > {{ option.itemNumber }} - {{
                  option.itemDescription }}</div>
              </mat-option>
            </ng-scrollbar>
        </div>

    </div>
    <i  aria-hidden="true" [ngClass]="!searchText && !isRetailItemSelected  ? 'icon-Search' : 'icon-Cancel'" class="cursor" (click)="filterFucntion($event)"></i>
  </mat-form-field>
</section>
