export enum HdrActionTypeEnum {
  hdrAdd = 'hdrAdd',
  hdrCheckbox = 'hdrCheckbox',
  hdrFilter = 'hdrFilter',
  hdrNone = '',
  hdrSave = 'hdrSave'
}
export enum aligment {
  center = 'td-align-center',
  left = 'td-align-left',
  right = 'td-align-right'
}

export enum SorTypeEnum {
  asc = 'asc',
  desc = 'desc'
}
export enum FromTypeEnum {
  allcheckbox = 'allcheckbox',
  cancel = 'cancel',
  claim = 'claim',
  comment = 'comment',
  convert = 'convert',
  copy = 'copy',
  delete = 'delete',
  done = 'done',
  dragdrop = 'dragdrop',
  dropdown = 'dropdown',
  duration = 'duration',
  edit = 'edit',
  input = 'input',
  menuoption = 'menuoption',
  oninit = 'oninit',
  preview = 'preview',
  radioButton = 'radioButton',
  rowcheck = 'rowcheck',
  sort = 'sort',
  switch = 'switch',
  userBlock = 'userBlock',
  replace = 'replace',
  history = 'history',
  rowClick = 'rowClick',
  customAction  = 'customAction',
  folioAction = 'folioAction',
  sync = 'sync',
  inlineEdit = 'inlineEdit',
  valueBind = 'valueBind',
  download = 'download',
  denialAction = 'denialAction',
  reservationAction = 'ReservationAction'
}
export enum ActionTypeEnum {
  action = 'action',
  alphaNumeric = 'alphaNumeric',
  checkbox = 'checkbox',
  currencyInput = 'currencyInput',
  custom = 'custom',
  decimalInput = 'decimalInput',
  decimalInputMaxval = 'decimalInputMaxval',
  delete = 'delete',
  dragdrop = 'dragdrop',
  dropdown = 'dropdown',
  duration = 'duration',
  edit = 'edit',
  hdrAdd = 'hdrAdd',
  hdrSave = 'hdrSave',
  icon = 'icon',
  link = 'link',
  menu = 'menu',
  none = '',
  numberInput = 'numberInput',
  radioButton = 'radioButton',
  timeInput = 'timeInput',
  toggle = 'toggle',
  userBlock = 'userBlock',
  datepicker = 'datepicker',
  columnWithNumber = 'columnWithNumber'
}


export enum optionstypesenum {
  edit = 'edit',
  delete = 'delete',
  dragdrop = 'dragdrop',
  claim = 'claim',
  convert = 'convert',
  moreoption = 'moreoption',
  userBlock = 'userBlock',
  custommoreoption = 'custommoreoption'
}
export enum selecttypeenum {
  checkbox = 'checkbox',
  radiobutton = 'radiobutton'
}
export enum templatenameenum {
  toggleTemplate = 'toggleTemplate',
  inputTemplate = 'inputTemplate',
  templateIconName = 'templateIconName'
}
export enum isRestrictedEnum {
  isRestrictEdit = 'isRestrictEdit',
  isRestrictDelete = 'isRestrictDelete',
  isRestrictClaim = 'isRestrictClaim',
  isRestrictDrag = 'isRestrictDrag',
  isRestrictuserBlock = 'isRestrictuserBlock',
  isRestrictCheckbox = 'isRestrictCheckbox'
}
export enum SearchEnum {
  textbox = 'textbox',
  dropdown = 'dropdown'
}