import { Injectable } from '@angular/core';
import { CommonApiRoutes } from '../common-route';
import { InternalIntegrationCommunication } from '../communication/services/internal-intergration-service';
import { DmCodeAndSettings,DmCodeAndSettingsForUpdate } from '../Models/common.models';

@Injectable({
    providedIn: 'root'
})

export class DmSettingsDataService {
    constructor(private v1InternalIntegration: InternalIntegrationCommunication) {
    }

    public getDmSettings(): Promise<DmCodeAndSettings[]> {    
        const result = this.v1InternalIntegration.getPromise<DmCodeAndSettings[]>(
        { route: CommonApiRoutes.GetAllDmSettings }, false);
        return result;
    }

    public getDmSettingByScreenId(screenId: string): Promise<DmCodeAndSettings> {
        const result = this.v1InternalIntegration.getPromise<DmCodeAndSettings>(
            { route: CommonApiRoutes.GetDmSettingByScreenId, uriParams: { screenId } }, false);
        return result;
    }

    public updateDmSettings(body: DmCodeAndSettingsForUpdate[]): Promise<boolean> {
        const result = this.v1InternalIntegration.putPromise<boolean>(
            { route: CommonApiRoutes.UpdateDataMagineSettings, body }, false);
        return result;
    }

    public getAllViews(): Promise<any> {
        const result = this.v1InternalIntegration.getPromise<any>(
            { route: CommonApiRoutes.GetAllViews }, false);
        return result;
    }
}