<div [formGroup]="searchForm">

  <div class="header-wrapper"
    [ngClass]="{'--actionFirst':headerOption && headerOption.hasHeaderAction, 'h-75': headerOption?.searchBarhide}">
    <div class='search-wrapper'>
      <div class="search-container custom-align-center ag_align-items--center">
        <ng-container *ngIf="!showSearchDates">
          <ng-content select=".additional-dropdown"></ng-content>
          <ng-content select=".Total_count"></ng-content>
          <app-radio-btn-search *ngIf="IsRadioSearch" [disabled]="headerOption?.searchDisabled" [selectionData]="radioselectiondataobj" [includeHistory]="includeHistory" [filterStatus]="filterStatus" (searchButtonClicked)="handleSearchButtonClick($event)" (checkboxemit)="checkboxClicked($event)" [isClearSearchText]="isClearSearchText" (searchSelectionChange) ="searchSelectionChangeEmitter($event)"
          (searchChange)="searchValueChange($event)"></app-radio-btn-search>
          <div class="ag_display--flex">
            <app-simple-search *ngIf="!IsRadioSearch" [value]="simplesearchInput" [inputMaxLength]="headerOption?.maxLength"          
            [placeholder]="headerOption?.searchPlaceHolder" (searchChange)="searchValueChange($event)"
            [disabled]="headerOption?.searchDisabled" [ngClass]="{'ag_display--none': headerOption?.searchBarhide}"
            [noResize]="noResizeSimplesearch" [automationId]="automationId">
          </app-simple-search>
          <i class="icon-search ag_cursor--pointer iconbtnstyle" *ngIf="bookingSearch" 
            [ngClass]="{'disabledBtn':!(simplesearchInput?.length >= minSearchTextChar) }" (click)="searchTextChange($event)"> </i>
          </div>
          <mat-checkbox class="ag-pl-2" *ngIf="bookingSearch" (change)="checkboxClicked($event)">{{captions.lbl_include_history}}</mat-checkbox>
          <ng-container *ngIf="showAdvancedSearch && !fromModule">
            <a class="ag_link ag_pl--2 advanceSearch boxed-icon" href="javascript:void(0)" attr.automationId='Icn_{{automationId}}_advancedSearch'
              (click)="toggleAdvancedSearch()" [ngClass]="{'ag_section--disable': hideAdvaceSearch}" [matTooltip]='headerOption?.advancedSearch'>
              <i class="icon-Advanced-Search"></i>
            </a></ng-container>
          <ng-container *ngIf="showAdvancedSearch && fromModule">
            <a class="ag_link ag_pl--2 advanceSearch boxed-icon" href="javascript:void(0)" attr.automationId='Icn_{{automationId}}_advancedSearch' [matTooltip]='headerOption?.advancedSearch'
              (click)="toggleAdvancedSearch()"><i class="icon-Advanced-Search">

              </i></a></ng-container>
          <ng-container *ngIf="showLinks">
            <a class="ag_link ag_pl--2" href="javascript:void(0)" (click)="linksClick()">{{headerOption?.links}}</a>
          </ng-container>

        </ng-container>
        <ng-container *ngIf="showSearchDates">
          <div class="ag_display--flex datesDiv">
            <app-ag-date-picker [inputs]="searchStartDateInput" (datePickerChange)="onSearchDateChange($event)">
            </app-ag-date-picker>
            <app-ag-date-picker [inputs]="searchEndDateInput" (datePickerChange)="onSearchDateChange($event)">
            </app-ag-date-picker>
            <ng-container *ngIf="showsearchDatesButton">
              <app-button [buttontype]="searchDatesButton" (valueChange)='onSearchDates($event)'></app-button>
            </ng-container>
            <ng-container *ngIf="showAdvancedSearch">
              <button class="body-bordercolor mr-5 searchbutton"
            	(click)="toggleAdvancedSearch()" [ngClass]="{'ag_link--disabled': hideAdvaceSearch}">
                                <i  aria-hidden="true" class='icon-Advanced-Search text-color'
                                    matTooltip="{{headerOption?.advancedSearch}}"></i>
            </button>
            </ng-container>
          </div>
        </ng-container>
        <ng-content select=".additional-item"></ng-content> 
      </div>
      <section class="right-action-section" *ngIf="headerOption?.showPrint || headerOption?.showInactive || (!headerOption?.hasHeaderAction && !headerOption?.hideActionBtn)">
        <span *ngIf="headerOption?.showPrint" class='print-container ag_mr--4 ag_cursor--pointer'
          [ngClass]="{'ag_link--disabled': headerOption?.disablePrint}" (click)='printAction($event)'><i  aria-hidden="true" matTooltip="{{captions.Print}}"  class='icon-print'>&nbsp;</i></span>
        <app-ag-toggle [toggleInputs]=" switchInput" *ngIf="headerOption?.showInactive"
          (valueChange)="toggleChange($event)">
        </app-ag-toggle>
        <ng-container *ngIf="!headerOption?.hasHeaderAction && !headerOption?.hideActionBtn ">
          <ng-content select=".additional-btn"></ng-content>
          <app-button [throttleTime]="1000" [buttontype]="actionButton" [actions]="menuActions" (valueChange)="handleClick($event)" attr.automationId='Btn_{{automationId}}_action'>
          </app-button>
        </ng-container>
        <ng-content select=".more-action-btn"></ng-content>
      </section>
    </div>
    <ng-container *ngIf="headerOption?.hasHeaderAction">
      <section class="left-action-section" [ngClass]="{'disabled': headerOption?.headerActionDisabled}">
        <ng-content></ng-content>
      </section>
    </ng-container>
  </div>
</div>