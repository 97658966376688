import { Component, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { DropDownData, ItemType } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailReportControl } from '../../../basereport/base-report.component';
import { AgDropdownConfig } from 'src/app/common/Models/ag-models';
@Component({
  selector: 'app-inventory-Availability',
  templateUrl: './inventory-Availability.component.html',
  styleUrls: ['./inventory-Availability.component.scss']
})
export class InventoryAvailabilityComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  InventoryAvailabilityFormGrp: UntypedFormGroup;
  minEndDate: Date;
  public captions: any = this.localization.captions.reports;
  itemTypeFilter: { id: number; name: string; }[];
  categories: any[];
  allCategories: any[];

  itemtype: any[];
  allitemtype: any[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  valuesSelected: DropDownData[] = [];
  selectedDropdownType = '';
  editPatchValue : any;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, private dataService: ReportDataService
    ,private container: ViewContainerRef) { }

  ngOnInit() {
    
    this.InventoryAvailabilityFormGrp = this.fb.group({
      category: new UntypedFormControl([]),
      itemType: new UntypedFormControl([]),
      DetailView: false
    });
    this.formReady.emit(this.InventoryAvailabilityFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        this.onLoad();
      }
      if (this.localization.isFromJobScheduler && _parent.edit) {  
        let patchItem =  this.editPatchValue;
        
       
        if(patchItem.CategoryIds != undefined){
          this.dataService.GetAllCategories().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.CategoryIds.find(x => x == item.id);
            });
            this.categories = arr;
        });
      }
        }
    } 
  }

  private async onLoad() {
    this.categories = await this.dataService.GetAllCategories();
    this.allCategories = this.categories;
    this.business.FilterDataSource["categories"] = this.categories;
    this.allitemtype = this.itemtype = this.getItemType();
    this.business.FilterDataSource["itemtype"] = this.itemtype;

  }
 
  getItemType() {
    return [

      { id: 1, code: ItemType.RetailItemRetailPOSOnly, description:  ItemType.RetailItemRetailPOSOnly, showInDropDown: true , isActive: true  },
      { id: 7, code: ItemType.RentalItem, description: ItemType.RentalItem, showInDropDown: true  , isActive: true },
    ];
  }

  getFormControlValue(event) {
    if (event[0] === 'category') {
      this.InventoryAvailabilityFormGrp.setControl('category', event[1]);
      } else if (event[0] === 'itemType') {
        this.InventoryAvailabilityFormGrp.setControl('itemType', event[1]);
        }
  }
  

}
