import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CreateCustomFeePopupComponent } from './create-custom-fee-popup/create-custom-fee-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogCloseEnum, SessionConstants } from 'src/app/common/enums/shared-enums';
import { ButtonValue, AgFieldConfig, AgDateConfig, DropdownOptions, TableOptions, TableHeaderOptions, AgDropdownConfig } from 'src/app/common/Models/ag-models';
import { Localization } from "src/app/common/localization/localization";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PropertyInformation } from '../../services/property-information.service';
import { CustomFeeBusiness } from './custom-fee.business';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { CommonUtilities } from '../utilities/common-utilities';
import { HttpMethod, HttpServiceCall } from '../service/http-call.service';
import { Host, RetailBreakPoint } from '../globalsContant';
import { BaseResponse } from '../../retail.modals';
import { CustomFeeAPIModel, modes } from './custom-fee.model';
import { CommonApiRoutes } from 'src/app/common/common-route';
import { of, ReplaySubject } from 'rxjs';
import { AlertType } from '../enums/enums';
import { AlertAction, ButtonType } from '../../shared.modal';
import { FacadeService } from 'src/app/common/services/facade.service';
import { UserAccessService } from 'src/app/common/services/user-access.service';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-custom-fee',
  templateUrl: './custom-fee.component.html',
  styleUrls: ['./custom-fee.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CustomFeeBusiness, FacadeService,UserAccessService]
})
export class CustomFeeComponent implements OnInit {
  createButton: ButtonValue;
  applyButton: ButtonValue;
  captions: any;
  customFeeForm : UntypedFormGroup;
  fromDateInputs: AgDateConfig;
  toDateInputs: AgDateConfig;
  feeOptions: DropdownOptions[];
  IsEditModeEnabled = false;
  options: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent: any = [];
  IsEditModeEnabledFlag = false;
  isAdditionalFeeEnabled: boolean = false
  customFeeConfigSetting: any;
  customFeeConfigs: CustomFeeAPIModel[] = [];
  timeformat: number;
  feeInput: AgDropdownConfig;
  searchText: string;
  IsViewOnly: boolean = false;
  isUserAuthorized: boolean;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public dialog: MatDialog,
    private localization: Localization,       
    private propertyInformation: PropertyInformation,
    private fb: UntypedFormBuilder,
    private business: CustomFeeBusiness, 
    private utils: CommonUtilities,
    private http: HttpServiceCall,
    private _FacadeService: FacadeService
    ) {
    this.captions = this.localization.captions;

   }

  ngOnInit(): void {
    this.intialize();
    this.GetCustomFeeConfigurationSetting();
  }

  async validateUserAccess() {
    const userAccess = await this._FacadeService.getUserAccess(RetailBreakPoint.CustomFee, true);
    if(userAccess.isViewOnly) {
      this.IsViewOnly = userAccess.isViewOnly
    }
  }

  async intialize() {
    this.timeformat = this.localization.getTimeFormat();
    this.customFeeForm = this.fb.group({
    requireAdditionalFee: '',
      fromDate: [this.propertyInformation.CurrentDate, Validators.required],
      toDate: [this.propertyInformation.CurrentDate, Validators.required],
      fee: ''
    });
    this.fromDateInputs = {
      className: 'ag_form-control',
      form: this.customFeeForm,
      formControlName: 'fromDate',
      placeHolder: this.captions.lbl_fromDateRange,
      automationId: 'customFee',
      isDateRequired: true,
      errorMessage: this.captions.Missing + ' ' + this.captions.lbl_fromDateRange
    };
    this.toDateInputs = {
      className: 'ag_form-control',
      form: this.customFeeForm,
      formControlName: 'toDate',
      placeHolder: this.captions.lbl_toDateRange,
      minDate: this.customFeeForm.controls.fromDate.value,
      automationId: 'customFee',
      isDateRequired: true,
      errorMessage: this.captions.Missing + ' ' + this.captions.lbl_toDateRange
    };
    this.createButton = {
      type: 'primary',
      label: this.captions.btn_createFee,
      disabledproperty: false
    };
    this.applyButton = {
      type: 'primary',
      label: this.captions.btn_apply,
      disabledproperty: false
    };
    this.getCustomFeeDataByFilters();
    this.customFeeForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(res=>{
      this.applyButton.disabledproperty = !(this.customFeeForm.controls.fromDate.valid && this.customFeeForm.controls.toDate.valid);
    })
  }
  setDropDownOptions()
  {
    this.feeInput = {
      form: this.customFeeForm,
      formControlName: 'fee',
      placeHolder: this.captions.lbl_fee,
      selectOptions: of(this.feeOptions),
      isMultiSelect: true,
      isAll: true,
      showRequired:false,
      className: 'ag_form-control ag_display--inblock',
      disabled: false,
    };
  }
  async generateTable() {
    this.headerOptions = this.business.GetTableHeader();
    this.options = this.business.GetTableOptions(this.IsViewOnly);
  }

  async loadDropDownOptions() {
    await this.InvokeServiceCall(CommonApiRoutes.GetCustomFeeConfiguration, HttpMethod.Get, Host.retailManagement, [], [], false);
  }

  onStartDateChanged(eve) {
    if (this.customFeeForm.controls.toDate.value == null || this.isfromDateExceedstoDate()) {
      this.customFeeForm.controls.toDate.setValue(this.customFeeForm.controls.fromDate.value);
    }
    this.toDateInputs.minDate = this.customFeeForm.controls.fromDate.value;
    this.toDateInputs = { ...this.toDateInputs };
  }

  onEndDateChanged(eve){
    if (this.customFeeForm.controls.fromDate.value === null){
      this.customFeeForm.controls.fromDate.setValue(this.customFeeForm.controls.toDate.value);
    }
  }

  isfromDateExceedstoDate(): boolean {
    const fromDate = this.customFeeForm.controls.fromDate.value;
    const toDate = this.customFeeForm.controls.toDate.value;
    return this.resetTime(fromDate) > this.resetTime(toDate);
  }

  resetTime(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  async getCustomFeeDataByFilters() {
    await this.validateUserAccess();
    await this.generateTable();
    this.resetdatefilters();
    let body = {
      startDate: this.localization.convertDateTimeToAPIDateTime(this.customFeeForm.controls.fromDate.getRawValue()),
      endDate: this.localization.convertDateTimeToAPIDateTime(this.customFeeForm.controls.toDate.getRawValue()),
    }
    this.InvokeServiceCall(CommonApiRoutes.GetCustomFeeConfigByFilters, HttpMethod.Post, Host.retailManagement, body, [], false);
  }
  resetdatefilters() {
    if (this.customFeeForm.controls.fromDate.getRawValue() == null || this.customFeeForm.controls.toDate.getRawValue() == null) {
      this.customFeeForm.controls.fromDate.setValue(this.propertyInformation.CurrentDate);
      this.fromDateInputs.minDate = this.propertyInformation.CurrentDate;
      this.customFeeForm.controls.toDate.setValue(this.propertyInformation.CurrentDate);
      this.toDateInputs.minDate = this.propertyInformation.CurrentDate;
    }
  }

  errorCallBack<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == CommonApiRoutes.GetCustomFeeConfigByFilters) {
      this.utils.ShowError(this.localization.captions.common.Error, this.captions.error_customfee);
    }
    else if (callDesc == CommonApiRoutes.CreateCustomFeeConfiguration){
      this.resetdatefilters();
      let body = {
        startDate: this.localization.convertDateTimeToAPIDateTime(this.customFeeForm.controls.fromDate.getRawValue()),
        endDate: this.localization.convertDateTimeToAPIDateTime(this.customFeeForm.controls.toDate.getRawValue()),
      }
      this.InvokeServiceCall(CommonApiRoutes.GetCustomFeeConfigByFilters, HttpMethod.Post, Host.retailManagement, body, [], false);
    }
  }

  successCallBack<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == CommonApiRoutes.GetCustomFeeConfigByFilters || callDesc == CommonApiRoutes.UpdateCustomFeeConfiguration || callDesc == CommonApiRoutes.CreateCustomFeeConfiguration) {
      let response = <any>result.result;
      this.customFeeConfigs = [];
      response.forEach(element => {
        let customFee: CustomFeeAPIModel = {
          id: element.id,
          availableDays: element.availableDays,
          endTime: element.endTime,
          startTime: element.startTime,
          isPercentage: element.isPercentage,
          linkedItemId: element.linkedItemId,
          name: element.name,
          value: element.value,
          code: element.code
        }
        this.customFeeConfigs.push(customFee);
      });
      this.bindDataToTable();
      this.utils.ToggleLoader(false);
    }
    else if(callDesc == CommonApiRoutes.DeleteCustomFeeConfiguration){
      this.getCustomFeeDataByFilters();
    }
    else if (callDesc == "GetConfiguration") {
      this.customFeeConfigSetting = result.result;
      let requireCustomFee = (this.customFeeConfigSetting && JSON.parse(this.customFeeConfigSetting.value) && this.customFeeConfigSetting.isActive) ? true : false;
      this.customFeeForm.controls['requireAdditionalFee'].setValue(requireCustomFee);
      this.customFeeForm.updateValueAndValidity();
      this.isAdditionalFeeEnabled = requireCustomFee;
      this.utils.ToggleLoader(false);
      console.log(this.customFeeConfigSetting);
    }
  }
  bindDataToTable()
  {
    let res = [];
    let propertyConfigDecimal =  this.utils.GetPropertyInfo("MaxDecimalPlaces");
        let maxDecimalPlace : number = 2;
        if(Number(propertyConfigDecimal) >=0){
            maxDecimalPlace =  Number(propertyConfigDecimal);
        }
    this.customFeeConfigs.map(x=>{
      let startTime = this.localization.getTime(this.localization.ConvertStringDateTimeToDate(x.startTime, this.localization.LocalizeTime(x.startTime)), this.timeformat)
      let endTime = this.localization.getTime(this.localization.ConvertStringDateTimeToDate(x.endTime, this.localization.LocalizeTime(x.endTime)), this.timeformat)
      let val: any = {
        fee : x.name,
        dateRange: this.localization.LocalizeShortDate(new Date(x.startTime)) + " - " + this.localization.LocalizeShortDate(new Date(x.endTime)),
        time: startTime + " - " +endTime,
        price : x.isPercentage ? this.localization.localizePercentage(x.value.toString())+' %' : this.localization.localizeCurrency(x.value,true),
        id : x.id,
        sortingPrice: x.value,
        startDate: (new Date(x.startTime))
      }
      res.push(val);
    })
    this.tableContent = res;
  }

  openDialog(mode: any, editData?) {
    console.log(mode)
    this.dialog.open(CreateCustomFeePopupComponent, {
      height: 'auto',
      width: '50%',
      hasBackdrop: true,
      panelClass: 'small-popup',
      disableClose: true,
      data: {
        data: this.customFeeConfigs.find(x => x.id == editData?.id)??[],
        mode: mode,
      }
    }).afterClosed().subscribe(res => {
      if (res.from === DialogCloseEnum.Action) {
        this.IsEditModeEnabledFlag = false;
        this.tableContent = [];
        this.resetdatefilters();
        res.data.filter = {
          startDate: this.localization.convertDateTimeToAPIDateTime(this.customFeeForm.controls.fromDate.getRawValue()),
          endDate: this.localization.convertDateTimeToAPIDateTime(this.customFeeForm.controls.toDate.getRawValue()),
        }
        if (mode == modes.create) {
          this.InvokeServiceCall(CommonApiRoutes.CreateCustomFeeConfiguration, HttpMethod.Post, Host.retailManagement, res.data, [], true);
        }
        else if (mode == modes.edit) {
          this.InvokeServiceCall(CommonApiRoutes.UpdateCustomFeeConfiguration, HttpMethod.Put, Host.retailManagement, res.data, [], true);
        }
      }
      else if (res.from === DialogCloseEnum.Close || res.from === DialogCloseEnum.Cancel)
      {
        this.tableContent = [...this.tableContent]
      }
    });
  }

  sliderChange(eve) {
    if (!eve) {
      this.utils.showAlert(this.captions.disableCustomFeeToggle, AlertType.Warning, ButtonType.YesNo, res => {
        if (res === AlertAction.YES) {
          let body = {
            id: this.customFeeConfigSetting.id,
            moduleId: this.customFeeConfigSetting.moduleId,
            switch: this.customFeeConfigSetting.switch,
            value: eve
          }
          this.UpdateSettings([body])
          this.UpdateSessionValue(false);
        }
        else{
          this.customFeeForm.controls['requireAdditionalFee'].setValue(true);
          this.isAdditionalFeeEnabled = true;
        }
      })
    }
    else{
      let body = {
        id: this.customFeeConfigSetting.id,
        moduleId: this.customFeeConfigSetting.moduleId,
        switch: this.customFeeConfigSetting.switch,
        value: eve
      }
      this.UpdateSettings([body])
    }
    this.isAdditionalFeeEnabled = eve;
  }

  editEvent(e) {
    console.log(e);
    this.openDialog(modes.edit,e);
  }

  tableAction(eve) {
    switch (eve.fromType) {
      case FromTypeEnum.edit:
        this.IsEditModeEnabledFlag = true;
        this.editEvent(eve.Obj);
        break;
      case FromTypeEnum.delete:
        this.utils.showAlert(this.localization.replacePlaceholders(this.captions.warning_deletefee,["CustomFeeName"],[eve.Obj.fee]), AlertType.Warning, ButtonType.YesNo, res => {
          if (res === AlertAction.YES)
            this.deleteEvent(eve.Obj);
        })
        break;
    }
  }

  async deleteEvent(customFee: CustomFeeAPIModel)
  {
    try {
      await this.InvokeServiceCall(CommonApiRoutes.DeleteCustomFeeConfiguration, HttpMethod.Delete, Host.retailManagement, undefined, { id:customFee.id }, true);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
  public async GetCustomFeeConfigurationSetting(): Promise<any> {
    this.utils.ToggleLoader(true);
    try {
      await this.InvokeServiceCall("GetConfiguration", HttpMethod.Get, Host.retailManagement, undefined, { module: 3, Switch: 'REQUIRE_CUSTOM_FEE' }, true);
    } catch (e) {
      this.http.exceptionHandle(e);
      this.utils.ToggleLoader(false);
    }
  }

  private async InvokeServiceCall<T>(callDesc: string, callType: HttpMethod, host: Host, body?: any, uRIParams?: any, isShowError: boolean = false) {
       this.http.CallApiWithCallback<T>({
      host: host,
      callDesc: callDesc,
      method: callType,
      showError: isShowError,
      error: await this.errorCallBack.bind(this),
      success: await this.successCallBack.bind(this),
      uriParams: uRIParams,
      body: body,
      extraParams: []
    }, false);
  }

  private showError(errorCode: number) {
    let errMsg = this.localization.getError(errorCode);
    this.utils.ShowError("Error", errMsg);
  }

  public async UpdateSettings(body: any[]) {
    try {
        return await this.InvokeServiceCall("UpdateSetting", HttpMethod.Put, Host.retailManagement, body, [], true);
    } catch (e) {
        this.http.exceptionHandle(e);
    }
  }

  public async UpdateSessionValue(isCustomFeeEnabled: boolean)
  {
    sessionStorage.setItem(SessionConstants.IsCustomFeeEnabled,String(isCustomFeeEnabled));
  }

  searchValueChange(eve) {
    this.searchText = eve;
  }
}
