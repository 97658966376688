<form name="ServiceDetails" [formGroup]="FormGrp" class="h-100 newVersionWrapper commissionSetupWrapper" id="commissionsetup" autocomplete="off">
  <div class="thirdSecCS " [ngClass]="fromScreen === 'retailsetup' ? 'thirdSecCS-height' : 'h-100'">
    <ng-scrollbar>
      <div class="commissionContent">
        <div class="mr-2 w-100 commission ">
          <div class=" align-text-top  commissionValues h-100">
            <div class="table-data">
              <div class="Actions actionitems" id="actionitems" *ngIf="tableoptions && tableoptions.length > 0">
            <div>
              <h4 class="golf-form--label LW14 ag_group--headers">{{captions.CommissionValues}}</h4>
            </div>
            <div class="d-flex ml-auto align-items-baseline">
                <div class="pr-3 position-relative commission-type">
                  <!-- <span class="golf__link cursor ml-4 mr-4 LW14 uppercase" id="AdvancedSearch" (click)="toggleAdvancedSearch()" *ngIf="!enableAdvSearch && this.fromScreen !== retailSetupConstant"> {{captions.AdvancedSearch}}</span> -->

                  <a *ngIf="!enableAdvSearch && this.fromScreen !== retailSetupConstant" class="ag_link mr-4 ag_pl--2 boxed-icon" href="javascript:void(0)" id="AdvancedSearch"
                  (click)="toggleAdvancedSearch()"  [matTooltip]='captions.AdvancedSearch'>
                  <i class="icon-Advanced-Search"></i></a>

                  <mat-form-field class="LW14" [floatLabel]="floatLabel">
                    <mat-select [attr.automationId]="'Dd_commissionSetup_commissionType'" [(value)]="SelectedCommissionType" formControlName="CommissionType" class="LW14"
                      placeholder="{{captions.Apply}}" (selectionChange)="OnCommissionTypeChange($event)">
                      <mat-option [value]="type.name" *ngFor="let type of commissiontype">{{type.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="pr-3 w140 b5 position-relative">
                  <mat-form-field class="w-100 LW14" [floatLabel]="floatLabel">
                    <input [attr.automationId]="'Txt_commissionSetup_commssionValue'" *ngIf="SelectedCommissionType != 'CommissionOn' && SelectedCommissionType != 'AfterDiscount' && SelectedCommissionType != 'Percentage'"
                    [placeholder]="relativePlacehoder" RetailCurrencyFormatter [preDecimalLength]="commissionMaxLength" matInput [cDisabled] ='isDisabled'
                    formControlName="CommissionValue" />
                    <mat-select [attr.automationId]="'Dd_commissionSetup_commsionValue1'" *ngIf="SelectedCommissionType == 'CommissionOn'" formControlName="CommissionValue"
                      class="LW14" placeholder="{{captions.Select}}">
                      <mat-option value="{{captions.Items}}">{{captions.Items}}</mat-option>
                      <mat-option value="{{captions.Revenue}}">{{captions.Revenue}}</mat-option>
                    </mat-select>
                    <mat-select [attr.automationId]="'Dd_commissionSetup_commsionValue2'" *ngIf="SelectedCommissionType == 'AfterDiscount'" formControlName="CommissionValue"
                      class="LW14" placeholder="{{captions.Select}}">
                      <mat-option value="{{captions.Yes}}">{{captions.Yes}}</mat-option>
                      <mat-option value="{{captions.No}}">{{captions.No}}</mat-option>
                    </mat-select>
                    <input [attr.automationId]="'Txt_commissionSetup_commssionValue'" *ngIf="SelectedCommissionType == 'Percentage'"
                    [placeholder]="relativePlacehoder" percentinputtype='onlynumber,decimal,validPercentage,preMaxdecimal' [maxpredecimalValue]="commissionMaxLength" matInput [cDisabled] ='isDisabled'
                    formControlName="CommissionValue" />
                  </mat-form-field>
                </div>
                <div class="position-relative apply new-mat-pt">
                  <button [attr.automationId]="'Btn_commissionSetup_apply'" [disabled]="IsReadOnly" type="button" mat-raised-button class="body-bordercolor padding10 p-2 text-capital apply-btn"
                    (click)="applyall(FormGrp.controls['CommissionType'].value,FormGrp.controls['CommissionValue'].value)">{{applyBtn}}</button>
                </div>
              </div>
              </div>
              <div class="adv-wrapper">
                <div class='retail-search-container AdvancedSearchDiv d-flex'  *ngIf="(this.fromScreen === retailSetupConstant)">
                  <div>
                  <mat-form-field class="classtype-field control--margin" *ngIf="!(this.fromScreen === 'THERAPIST')">
                    <input [attr.automationId]="'Txt_commissionSetup_classType1'" matInput formControlName="ClassType" (change)="OnClassChange($event)" placeholder="{{captions.CommissionClass}}" [min]="0" type="number"
                       inputtype="nonnegative,nodecimal">
                  </mat-form-field>
                </div>

                <div *ngIf="showOutletsSelection" class="locations locations-area pr-3 pl-3 retail-commission-section">
                  <label class="golf-form--label LW14">{{captions.Outlets}}</label>
                  <div>
                    <button [attr.automationId]="'Btn_commissionSetup_toggle'" retailViewMore (NoOfElements)="bindLocations($event)" type="button"
                      *ngFor="let outlet of outlets | slice:0:noOfLocations; let k=index" mat-raised-button
                      [ngClass]="isExist(selectedOutlet, outlet, 'subPropertyID') !=-1 ? 'body-bgcolor whitecolor body-bordercolor' : 'body-bordercolor'"
                      (click)="toggleCommLoc(outlet)" class="p-2 LW12 mr-4 body-bordercolor location-btn"
                      [title]="outlet.subPropertyName">
                      <span class="buttonVA1 text-uppercase">{{outlet.subPropertyName}}</span>
                    </button>
                    <div class="d-inline-block" *ngIf="outlets && outlets.length > noOfLocations" container="body">
                      <a class="text-color LW14"
                        (click)="this._ViewMoreServiceService.setdata($event,outlets,GetSelectedOutletMgrArrId(selectedOutlet),noOfLocations,'Locations',['subPropertyID','subPropertyName'])">{{outlets.length - noOfLocations}}
                        {{this.localization.captions.common.More}}</a>
                    </div>
                  </div>
                </div>
                </div>
                <div class='retail-search-container AdvancedSearchDiv'
                  *ngIf="enableAdvSearch && this.fromScreen !== retailSetupConstant">
                <label class="golf-form--label LW14 d-block"> {{captions.AdvancedSearch}}
                  <span class="golf__link cursor ml-2 mr-4 LW11 uppercase" id="AdvancedSearch" (click)="cancelSearchFilters()"> {{captions.ClearAll}}</span>
                </label>
                <i   aria-hidden="true" class="icon-close inner-close float-md-right search--close" (click)="closeAdvanceSearch()"></i>
                <mat-form-field class="classtype-field control--margin" [floatLabel]="floatLabel" *ngIf="!(this.fromScreen === 'THERAPIST')">
                  <input [attr.automationId]="'Txt_commissionSetup_classType'" matInput formControlName="ClassType" (change)="OnClassChange($event)" placeholder="{{captions.CommissionClass}}" [min]="0" type="number"
                     inputtype="nonnegative,nodecimal">
                </mat-form-field>
                <app-multi-select-dropdown [attr.automationId]="'Dd_commissionSetup_category'" class="d-inline-block control--margin" [dropDownFilterData] = "CommissioncategoryArr" [dropDownControlname] = "'retailCategory'"
                [dropDownName] = "captions.RetailCategory" (dropDownFrmControl) = "getFormControlValue($event)"></app-multi-select-dropdown>
                <mat-form-field class="control--margin" [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_commissionSetup_itemDescription'" matInput [placeholder]="captions.ItemDescription" formControlName="itemDescription"/>
                </mat-form-field>
                <mat-form-field class="control--margin" [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_commissionSetup_threshold'" matInput [placeholder]="captions.Threshold" formControlName="threshold" RetailCurrencyFormatter/>
                </mat-form-field>
                <mat-form-field class="control--margin" [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_commissionSetup_flatAmount'" matInput placeholder="{{captions.FlatAmount}}({{currencySymbol}})" formControlName="flatAmount" RetailCurrencyFormatter [preDecimalLength]="flatAmountMaxLength"/>
                </mat-form-field>
                <mat-form-field class="control--margin" [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_commissionSetup_percentage'" matInput [placeholder]="captions.Percentage" formControlName="percentage" percentinputtype="nonnegative,decimal,validPercentage"  [maxpredecimalvalue]="percentageMaxLength"/>
                </mat-form-field>

                <mat-form-field class="control--margin" [floatLabel]="floatLabel">
                  <mat-select [attr.automationId]="'Dd_commissionSetup_commission'" formControlName="commissionOn"
                    [placeholder]="captions.CommissionOn">
                    <mat-option [value]="commission.id" *ngFor="let commission of commissionOn">{{commission.description}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="control--margin" [floatLabel]="floatLabel">
                  <mat-select [attr.automationId]="'Dd_commissionSetup_afterDiscount'" formControlName="afterDiscount"
                    [placeholder]="captions.AfterDiscount">
                    <mat-option [value]="discount.id" *ngFor="let discount of afterDiscount">{{discount.description}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="control--margin" [floatLabel]="floatLabel">
                  <mat-select [attr.automationId]="'Dd_commissionSetup_sortOrder'" formControlName="sortBy" (selectionChange)="OnSortByChange($event)"
                    [placeholder]="captions.SortBy">
                    <mat-option [value]="sort.id" *ngFor="let sort of sortByValues">{{sort.description}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="control--margin" [floatLabel]="floatLabel">
                  <mat-select [attr.automationId]="'Dd_commissionSetup_sortOrder'" formControlName="sortOrder"
                    [placeholder]="captions.SortOrder">
                    <mat-option [value]="order.id" *ngFor="let order of sortOrder">{{order.description}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-raised-button type="button" class="LW14 text-uppercase mt-3 body-bgcolor whitecolor location-btn p-2" (click)='SearchCommission()'>{{this.localization.captions.common.Search}}</button>

          <div  *ngIf="showOutletsSelection" class="locations locations-area pr-3">
            <label class="golf-form--label LW14">{{captions.Outlets}}</label>
            <div>
              <button [attr.automationId]="'Btn_commissionSetup_subPropertyName'" retailViewMore (NoOfElements)="bindLocations($event)" type="button" *ngFor="let outlet of outlets | slice:0:noOfLocations; let k=index" mat-raised-button [ngClass]="isExist(selectedOutlet, outlet, 'subPropertyID') !=-1 ? 'body-bgcolor whitecolor body-bordercolor' : 'body-bordercolor'" (click)="toggleCommLoc(outlet)" class="p-2 LW12 mr-4 body-bordercolor location-btn" [title]="outlet.subPropertyName">
                <span class="buttonVA1 text-uppercase">{{outlet.subPropertyName}}</span>
              </button>
              <div class="d-inline-block" *ngIf="outlets && outlets.length > noOfLocations" container="body">
                <a class="text-color LW14"
                  (click)="this._ViewMoreServiceService.setdata($event,outlets,GetSelectedOutletMgrArrId(selectedOutlet),noOfLocations,'Locations',['subPropertyID','subPropertyName'])">{{outlets.length - noOfLocations}} {{this.localization.captions.common.More}}</a>
              </div>
            </div>
          </div>
              </div>
              <div class="commission-table d-flex">
              <app-retail-commission-setup-table [attr.automationId]="'Tbl_commissionSetup_commissionSetup'"  class="w-100" [showOutletInGrid] ="showOutletInGrid" [options]="tableoptions" (RowSelectEmitter)="RowSelected($event)" (editEvt)="EditRecords($event)"
              (scrollEmitter)="virtualScrollHandler($event)" [scrollPositionReset]="isScrollPositionReset"></app-retail-commission-setup-table>
              </div>
              <div class="res-progress-bar" *ngIf="isLoading">
                <mat-progress-bar  mode="indeterminate"></mat-progress-bar>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
  <mat-dialog-actions class="actionButtons pr-4 footer-actions" *ngIf="fromScreen === 'retailsetup'">
		<button  [attr.automationId]="'Btn_commissionSetup_cancel'" class="" mat-button type="button"
			(click)="close()">{{localization.captions.common.CANCEL}}</button>
		<button  [attr.automationId]="'Btn_commissionSetup_previous'" mat-button matStepperPrevious type="button"
			class="LW14 text_capitalize body-bordercolor">{{localization.captions.common.PREVIOUS}}</button>
    <button  [attr.automationId]="'Btn_commissionSetup_next'" mat-button *ngIf="ShowRetailSetupRoomTypesAssignmentTab" matStepperNext type="button" color="primary"
			class="LW14 text_capitalize body-bordercolor">{{localization.captions.common.NEXT}}</button>
		<button  [attr.automationId]="'Btn_commissionSetup_save'" mat-button *ngIf="!isEdit && !ShowRetailSetupRoomTypesAssignmentTab" type="button" color="primary"
			[ngClass]="validateBtn ? 'button_valid' : ' button_invalid'" (click)="save()"
			class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{localization.captions.common.SAVE}}</button>
		<button [attr.automationId]="'Btn_commissionSetup_save1'" mat-button *ngIf="isEdit && !ShowRetailSetupRoomTypesAssignmentTab" type="button" color="primary"
			[ngClass]="validateBtn ? 'button_valid' : ' button_invalid'" (click)="save()"
			class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{localization.captions.common.Update}}</button>
	</mat-dialog-actions>
</form>
<app-retail-more-section sectionfrom="CoS" [filterOptions]="filterOptions"></app-retail-more-section>
<app-retail-view-more fromSection='Locations'
  [displayDataDetails]="this._ViewMoreServiceService.VM_displayData" (clickEmit)="emitedValue($event)"></app-retail-view-more>
