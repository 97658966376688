<div class="activity-headlines">
    <div class="activity-headlines__header-section" *ngIf="!data[0]?.isCancel">
        <div class="seat-header-bold">{{activityData.Name}}</div>
        <div>{{activityData.startTime | localizedDate : 'LT' }} - {{activityData.endTime | localizedDate : 'LT'}}</div>
    </div>
    <div class="cancel-section">
        <div class="warning-message" *ngIf="data[0]?.isCancel">
            {{ deleteMessage }}
        </div>
    </div>
    <div class="note-type"><span *ngIf="isPublicHeadline"><i class="icon-public"></i>{{'Public' |
            translate}}</span><span *ngIf="!isPublicHeadline"><i class="icon-public_off"></i>{{'Private' |
            translate}}</span></div>
    <dynamic-form [config]="headlinesConfig" #headlinesForm>
    </dynamic-form>
</div>