
export interface JasperReportRequest{
    reportUnitUri : string;
    async : boolean;
    interactive : boolean ;
    outputFormat : ReportOutputFormat;
    ignorePagination : boolean;
    allowInlineScripts?:boolean;
    markupType?:string;
    freshData:boolean;
    saveDataSnapshot:boolean;
    transformerKey?:any;
    attachmentsPrefix?:string;
    baseUrl?:string;
    parameters : JasperInputControls;
  }
  export interface JasperInputControls{
    reportParameter:JasperReportParameter[]
  }
  export interface JasperReportParameter{
    name : string;
    value : string[];
  }
  export enum ReportDownloadFormatOptions{
    PDF = 'PDF',
    WORD = 'WORD',
    EXCEL = 'EXCEL',
    IMAGE = 'IMAGE',
    HTML = 'HTML',
    CSV = 'CSV',
    RAWDATA = 'RAWDATA',
    RTF = 'RTF',
    XML = 'XML',
    PPTX = 'PPTX',
    ODT = 'ODT',
    TXT = 'TXT',
    DOCX = 'DOCX',
    XLSX = 'XLSX',
    ODS = 'ODS',
    EXCELPAGINATED = 'EXCELPAGINATED',
    XLSXPAGINATED = 'XLSXPAGINATED',
    CSVMETADATA = 'CSVMETADATA'
  }
  export enum Jaspersoftconstants{
    jwtidentifier = "Bearer ",
    Bearertoken = "BearerToken",
    tokenProvider = "oauth2",
    authtokenProvider = "authtokenProvider",
  }
  export enum ReportOutputFormat{ 
     PDF = 'pdf',
     WORD = 'docx',
     EXCEL = 'xls',
     HTML = 'html',
     CSV = 'csv',
     RTF = 'rtf',
     PPTX = 'pptx',
     ODT = 'odt',
     ODS = 'ods',
     DOCX = 'docx',
     XLSX = 'xlsx',
     EXCELPAGINATED = 'xls',
     XLSXPAGINATED = 'xlsx',
     CSVMETADATA = 'data_csv',
     RAWDATA = 'xlsx',
     IMAGE = 'jpeg',
  }
  
  export interface JReportFormat{
    ignorePagination : boolean,
    outputFormat : ReportOutputFormat
  }
 
  export enum ResponseType {
    HTML = 'text',
    BLOB = 'blob',
    JSON = 'json'
  }
  export interface JasperReportOutputData {
    TotalPages: number;
    ReportContent: any;
    OutputFormat: string;
  }
  
  export class ReportAPIOptions {
    code: string;
    format?: ReportDownloadFormat = 'HTML';
    params: ReportParams[];
    URIParams: ReportParams[];
    filters: any;
    pageBreak: boolean;
    isDateInputAvailable?:boolean = true;
    JasperReportURL?:string;
  }
  export interface ReportParams {
    [parameter: string]: string | number | boolean;
  }
  export interface JasperServerRequestHeaders {
    [key: string]: string; 
}
  export type ReportDownloadFormat = 'PDF' | 'WORD' | 'EXCEL' | 'IMAGE' | 'HTML' | 'CSV'| 'RAWDATA' | 'RTF' | 'XML' | 'PPTX' | 'ODT' | 'ODS' | 'TXT' | 'DOCX' | 'XLSX' | 'EXCELPAGINATED' | 'XLSXPAGINATED' | 'CSVMETADATA' ;
  
  export class JasperReportDetails{
    reportCode : string;
    isJaspersoftReport: boolean;
	reportUrlPath: string;
	reportUrlParameters? : string;
  }