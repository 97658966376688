import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Localization } from '../localization/Localization';
import { PROPERTY_DATE, USE_RETAIL_INTERFACE, PROPERTY_INFO, VAT_ENABLED } from 'src/app/app-constants';
import { PaymentConfiguration } from '../retail.modals';
import { ReplaySubject } from 'rxjs';
import { GiftCardConfigurationStore, GiftCardConfiguration } from '../../Models/payment.model';
import { PropertySetting } from '../../Models/property.information.model';
@Injectable({
    providedIn: 'root'
}) // root not in retail and common
export class PropertyInformation {

    private _currentDate: Date;
    private _useRetailInterface: boolean;
    private _propertyId: number;
    private _paymentConfiguration: PaymentConfiguration[];


    // retail and common
    private _useGiftCardInterface: boolean;
    private _VATEnabled: boolean;
    private _giftCardConfiguration: GiftCardConfigurationStore;
    private _appointmentConfigurations: any;   
    public _appointmentConfigurations$: ReplaySubject<any> = new ReplaySubject();

    constructor(private localization: Localization) {
        this.SetDefaultDataOnLoad();
    }

    public get CurrentDate(): Date {
         //to resolve cache issue
         return this.localization.getDate(sessionStorage.getItem(PROPERTY_DATE));
         // return cloneDeep(this._currentDate);
    }

    public get CurrentDateISO(): string {
        return this.localization.convertDateObjToAPIdate(sessionStorage.getItem(PROPERTY_DATE));
        // return cloneDeep(this.localization.convertDateObjToAPIdate(this._currentDate));
    }

    public get UseRetailInterface() {
        const retailSwitch: string = sessionStorage.getItem(USE_RETAIL_INTERFACE);
        if ((retailSwitch && retailSwitch.toLowerCase() == 'true')) {
            return true;
        } else {
            return false;
        }
        // return cloneDeep(this._useRetailInterface);
    }

    public get PropertyId() {
        const propertyId = this.GetPropertyInfoByKey('PropertyId');
        return Number(propertyId);
        // return cloneDeep(this._propertyId);
    }

    public get IsVATEnabled() {
        const VATEnableSwitch: string = sessionStorage.getItem('VATEnabled');
        if (VATEnableSwitch && VATEnableSwitch.toLowerCase() == 'true') {
            return true;
        } else {
            return false;
        }
        // return cloneDeep(this._VATEnabled);
    }  

    private set UpdateDate(newDate: Date) {
        this._currentDate = this.localization.getDate(newDate);
        this._currentDate.setHours(0, 0, 0, 0);
    }

    public get CurrentDTTM() {
        const now = this.localization.getCurrentDate();
        this._currentDate = this.localization.getDate(sessionStorage.getItem(PROPERTY_DATE));
        return new Date(this._currentDate.getFullYear(), this._currentDate.getMonth(), this._currentDate.getDate(), now.getHours(), now.getMinutes());
    }

    public SetPropertyDate(newDate: Date, updatelocalstore: boolean = true) {
        this.UpdateDate = newDate;
        if (updatelocalstore) {
            sessionStorage.setItem(PROPERTY_DATE, cloneDeep(this._currentDate).toString());
          
        }
    }



    public SetPropertyId(propertyId: number) {
        this._propertyId = propertyId;
    }

    public SetPropertySetting(settings: PropertySetting) {
        if (settings) {
            this._useRetailInterface = settings.activateRetailInterface;
            this._VATEnabled = settings.vatEnabled;            
        } else {
            this._useRetailInterface = false;
            this._VATEnabled = false;
        }
        sessionStorage.setItem(USE_RETAIL_INTERFACE, cloneDeep(this._useRetailInterface).toString());
        sessionStorage.setItem(VAT_ENABLED, cloneDeep(this._VATEnabled).toString());        
        sessionStorage.setItem('productVersion', settings.productVersion);
        sessionStorage.setItem('userProductVersion', settings.userProductVersion);
            
    }

    public SetPaymentConfiguration(payConfig: PaymentConfiguration[]) {
        this._paymentConfiguration = [];
        if (payConfig && payConfig.length > 0) {
            this._paymentConfiguration = payConfig;
        }
        sessionStorage.setItem('paymentConfiguration', cloneDeep(JSON.stringify(this._paymentConfiguration)));
    }

    public GetPaymentConfigValueByKey(configKey: string, outletId: number): string {
        const paymentConfiguration = this.GetPaymentInformation();
        let payConfigValue = '';
        
        let payConfig = paymentConfiguration .find(r => r.propertyId == this._propertyId && r.outletId == outletId && r.configKey == configKey);
        if (!payConfig) { // Get default pay configuration
            payConfigValue = paymentConfiguration .find(r => r.propertyId == 0 && r.outletId == 0 && r.configKey == configKey).configValue;
        } else {
            payConfigValue = payConfig.configValue;
        }
        return payConfigValue;
    }

    SetDefaultDataOnLoad() {
        // Setting propety Date
        const propertyData: any = sessionStorage.getItem(PROPERTY_DATE);
        if (propertyData) {
            this._currentDate = this.localization.getDate(propertyData);
        } else {
            this._currentDate = this.localization.getCurrentDate();
        }
        this._currentDate.setHours(0, 0, 0, 0);

        // Setting Retail-Interface switch value
        const retailSwitch: string = sessionStorage.getItem(USE_RETAIL_INTERFACE);
        if ((retailSwitch && retailSwitch.toLowerCase() == 'true')) {
            this._useRetailInterface = true;
        } else {
            this._useRetailInterface = false;
        }

        // Payment Configuration
        const paymentConfig: string = sessionStorage.getItem('paymentConfiguration');
        if (paymentConfig && paymentConfig != 'undefined' && JSON.parse(paymentConfig)) {
            this._paymentConfiguration = JSON.parse(paymentConfig);
        } else {
            this._paymentConfiguration = [];
        }
        //VAT Enable
        const VATEnableSwitch: string = sessionStorage.getItem('VATEnabled');
        if (VATEnableSwitch && VATEnableSwitch.toLowerCase() == 'true') {
            this._VATEnabled = true;
        } else {
            this._VATEnabled = false;
        }

        // Set PropertyId
        const propertyId = this.GetPropertyInfoByKey('PropertyId');
        if (propertyId) {
            this._propertyId = Number(propertyId);
        }        

        // GiftCard Configuration
        const giftCardConfig: string = sessionStorage.getItem('giftCardConfiguration');
        if (giftCardConfig && JSON.parse(giftCardConfig)) {
            this._giftCardConfiguration = JSON.parse(giftCardConfig);
        } else {
            this._giftCardConfiguration = null;
        }
    }

    public GetPropertyInfoByKey(name: string) {
        const nameEQ = name + '=';
        const propertyInfo = sessionStorage.getItem(PROPERTY_INFO);
        if (propertyInfo != null) {
            const ca = propertyInfo.split(';');

            for (let property of ca) {
                let c = property.trim();
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    //retail
    public get UseGiftCardInterface() {
        return cloneDeep(this._useGiftCardInterface);
    }

    public IsPaymentConfigExist(): boolean {
        const paymentConfiguration =this.GetPaymentInformation();
        return paymentConfiguration.length > 0;
    }
    private GetPaymentInformation(){
        const paymentConfig: string = sessionStorage.getItem('paymentConfiguration');
        let paymentConfiguration=[];
        if (paymentConfig && paymentConfig!='undefined'&& JSON.parse(paymentConfig)) {
            paymentConfiguration = JSON.parse(paymentConfig);
        } return paymentConfiguration;
    }


    public SetGiftCardConfiguration(giftcardConfig: GiftCardConfiguration) {
        this._giftCardConfiguration = null;
        if (giftcardConfig) {
            this._giftCardConfiguration = { activateGiftCardInterface: giftcardConfig.activateGiftCardInterface, giftCardType: giftcardConfig.giftCardType };
            this._useGiftCardInterface = giftcardConfig.activateGiftCardInterface;
        }
        sessionStorage.setItem('giftCardConfiguration', cloneDeep(JSON.stringify(this._giftCardConfiguration)));
    }

    //common
    public AppointmentConfigurations() {
        return cloneDeep(this._appointmentConfigurations);
    }

    public SetAppointmentConfigurations(config: any) {
        this._appointmentConfigurations = config;
        this._appointmentConfigurations$.next(this._appointmentConfigurations);
    }

    public get GiftCardConfig() {
        const giftCardConfig: string = sessionStorage.getItem('giftCardConfiguration');
        if (giftCardConfig && JSON.parse(giftCardConfig)) {
            this._giftCardConfiguration = JSON.parse(giftCardConfig);
        } else {
            this._giftCardConfiguration = null;
        }
        return cloneDeep(this._giftCardConfiguration);
    }
    public GetMultiPropertySessions<T>() {
        const sessions = sessionStorage.getItem('MultiPropertySession');
        if (sessions != null)
            return JSON.parse(sessions) as T[];
        return [];
    }
    public setCrossPropertySession(propertyid: number): boolean {

        const multipropsession = this.GetMultiPropertySessions<any>();
        if (multipropsession.length > 0) {
            let userSessionId = multipropsession.find(x => x.propertyId == propertyid).sessionId;
            sessionStorage.setItem('userSession', String(userSessionId))
            return true;
        }
        return false;

    }
    public GetCurrentProperty(propertyId: number): any {
        const multipropsession = this.GetMultiPropertySessions<any>();;
        let property = null;
        if (multipropsession.length > 0) {
            property = multipropsession.find(x => x.propertyId == propertyId);
        }
        return property;

    }
    public resetCrossPropertySession() {
        const multipropsession = this.GetMultiPropertySessions<any>();
        if (multipropsession.length > 0) {
            let userSessionId = multipropsession.find(x => x.isDefault).sessionId;
            sessionStorage.setItem('userSession', String(userSessionId))
            return true;
        }
        return false;
    }

    public GetPropertyConfiguration() {
        const config = sessionStorage.getItem('propConfig');
        return config && JSON.parse(config);
    }

}
