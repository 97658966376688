import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { Injectable } from '@angular/core';
import { ARAccountStatus, CustomerDataAPI, CustomerDataUI, SearchBy, EstCustomerAccountAPI, IntegrationFormSetting, EstSearchBy, EstAccountStatus } from './ar-customer-search.model';
import { ActionTypeEnum, HdrActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { Localization } from 'src/app/common/localization/localization';
import { SearchDropdownOptions } from 'src/app/common/shared/shared.modal';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { Host } from '../../shared/globalsContant';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { RetailRoutes } from '../../retail-route';

@Injectable()

export class ArCustomerSearchBusiness {
  enableestablishedintegration: boolean;
  captions: any;
  propertyConfig: any;
  constructor(private localization: Localization, private http: HttpServiceCall
    ,private PropertyInfo: CommonPropertyInformation) {
    this.captions = this.localization.captions;
  }

  getSearchDropdownOptions(): SearchDropdownOptions[] {

    this.propertyConfig = this.PropertyInfo.GetPropertyConfiguration();
    let isEstdAccounting = false;
    if(this.propertyConfig!=null && this.propertyConfig['IsESTAccounting']!=null)
    {
      isEstdAccounting = this.propertyConfig['IsESTAccounting'] == "true" ? true : false;
    }

    if(isEstdAccounting)
    {
      return [
        {
          id: SearchBy.AccountName,
          viewValue: this.captions.lbl_accountName
        },
        {
          id: SearchBy.AccountNumber,
          viewValue: this.captions.lbl_accountNumber
        }
      ]
    }

    else
    {
    return [
      {
        id: SearchBy.AccountName,
        viewValue: this.captions.lbl_accountName
      },
      {
        id: SearchBy.AccountNumber,
        viewValue: this.captions.lbl_accountNumber
      },      
      {
        id: SearchBy.Reference,
        viewValue: this.captions.lbl_reference
      }
    ]
   }
   
  }

  getTableOptions(isMultiple: boolean = false): TableOptions {
    return {
      defaultSortOrder: SorTypeEnum.asc,
      defaultsortingColoumnKey: 'accNumber',
      isHeaderCheckboxAllowed: isMultiple,
      isRowClick:true,
      uniqueKey:'accNumber'
    }
  }

  getHeaderOptions(searchEnum, isMultiple: boolean = false): TableHeaderOptions[] {
    const headers: TableHeaderOptions[] = [
      {
        key: 'accNumber',
        displayName: this.captions.tbl_hdr_accountNumber,
        displayNameId: 'tbl_hdr_accountNumber',
        sorting: true,
        searchable: searchEnum == SearchBy.AccountNumber
      },
      {
        key: 'accName',
        displayName: this.captions.tbl_hdr_accountName,
        displayNameId: 'tbl_hdr_accountName',
        sorting: true,
        searchable: searchEnum == SearchBy.AccountName
      },
      {
        key: 'status',
        displayName: this.captions.tbl_hdr_status,
        displayNameId: 'tbl_hdr_status',
        sorting: true,
        searchable: searchEnum == SearchBy.Status,
        templateName: ActionTypeEnum.custom
      },
      {
        key: 'preference',
        displayName: this.captions.tbl_hdr_reference,
        displayNameId: 'tbl_hdr_reference',
        sorting: searchEnum == SearchBy.Reference,
        searchable: true
      },
    ]

    if (isMultiple) {
      const checkbox: TableHeaderOptions = {
        key: 'checked',
        displayName: '',
        templateName: ActionTypeEnum.checkbox,
        hdrtemplateName: HdrActionTypeEnum.hdrCheckbox
      }
      headers.unshift(checkbox)
    }

    return headers;
  }

  async GetAccountsByFilter(searchText, searchBy,onlyActiveAccounts:boolean) {
    this.propertyConfig = this.PropertyInfo.GetPropertyConfiguration();
    if(this.propertyConfig!=null && this.propertyConfig['IsESTAccounting']!=null){
      this.enableestablishedintegration = this.propertyConfig['IsESTAccounting'] == "true" ? true : false;
    }
    if (this.enableestablishedintegration == true) {
      const CustomerDetails = await this.GetEstARAccountByFilter(this.MapToEstSearchBy(searchBy), searchText);
      return this.EstAccountUIMapper(CustomerDetails,onlyActiveAccounts);
    }
    else {
      const CustomerDetails = await this.GetARAccountByFilter(searchText, searchBy);
      return this.CustomerUIMapper(CustomerDetails,onlyActiveAccounts);
    }
  }
  public async getSettingByModuleScreen(module: string, screen: string): Promise<IntegrationFormSetting> {
    let apiResult = await this.GetSettingByModuelScreen(module, screen);
    return apiResult;
  }

  async GetARAccountByFilter(searchText: any, searchBy: any): Promise<any> {
    let result: any = await this.http.CallApiAsync({
      callDesc: RetailRoutes.GetARAccountByFilter,
      host: Host.account,
      method: HttpMethod.Get,
      uriParams: { searchText: searchText, searchBy: searchBy },
    });
    return <any>result.result;
  }

  async GetEstARAccountByFilter(searchText: any, searchBy: any): Promise<any> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'GetEstARAccounts',
      host: Host.internalIntegration,
      method: HttpMethod.Get,
      uriParams: { searchText: searchText, searchBy: searchBy }
    });
    return <any>result.result;
  }

  async getARCustomerAccounts(): Promise<any> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'GetARCustomerAccounts',
      host: Host.account,
      method: HttpMethod.Get
    });
    return <any>result.result;
  }

  async GetSettingByModuelScreen(module: string, screen: string): Promise<any> {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'GetSettingByModuleScreenInternalIntegration',
      host: Host.internalIntegration,
      method: HttpMethod.Get,
      uriParams: { module: module, screen: screen }
    });
    return <any>result.result;
  }

  async getAllArCustomerData$(): Promise<CustomerDataUI[]> {
    const accounts = await this.getARCustomerAccounts();
    return this.CustomerUIMapper(accounts);
  }
  private EstAccountUIMapper(apiModel: EstCustomerAccountAPI[],onlyActiveAccounts:boolean): CustomerDataUI[] {
    const result: CustomerDataUI[] = apiModel.filter(x=>(!this.GetEstAccountInActiveStatus().includes(x.status)  || !onlyActiveAccounts )).map(model => {
      const account: CustomerDataUI = {
        accountId: 0,
        statusId: this.getEstARAccountStatusid(model.status),
        checked: false,
        accNumber: model.arCode,
        accName: model.accountName,
        status: this.getStatusname(model.status),
        preference: model.preference,
        isExemptTax:model?.isExemptTax
      }
      return account;
    });
    return result;
  }
  private CustomerUIMapper(apiModel: CustomerDataAPI[],onlyActiveAccounts:boolean=false): CustomerDataUI[] {
    const result: CustomerDataUI[] = apiModel.filter(x=>(!this.GetArAccountInActiveStatus().includes(x.status)  || !onlyActiveAccounts)).map(model => {
      const account: CustomerDataUI = {
        checked: false,
        accountId: model.id,
        accNumber: model.code,
        accName: model.name,
        status: this.getARAccountStatus(model.status),
        statusId: model.status,
        preference: (model.arCustomerDetail && model.arCustomerDetail.preferences) ? model.arCustomerDetail.preferences : '',
        isExemptTax:model.isExemptTax,
        isRowClickDisable: this.checkRowDisability(model.status,onlyActiveAccounts)

      }
      return account;
    });
    return result;
  }

  checkRowDisability(status,onlyActiveAccounts){
    let isDisabled =false;
    if(onlyActiveAccounts)
    {
      isDisabled = (status == ARAccountStatus.Hold || status == ARAccountStatus.AccessToFacilities || status == ARAccountStatus.Frozen || status == ARAccountStatus.Expired || status == ARAccountStatus.Suspended)? true: false;
    }
    return isDisabled;
  }

  getARAccountStatus(type: number) {
    let status = '';
    switch (type) {
      case ARAccountStatus.Permanent:
        status = ARAccountStatus[ARAccountStatus.Permanent];
        break;
      case ARAccountStatus.Temporary:
        status = ARAccountStatus[ARAccountStatus.Temporary];
        break;
      case ARAccountStatus.Inactive:
        status = ARAccountStatus[ARAccountStatus.Inactive];
        break;
      case ARAccountStatus.Hold:
        status = ARAccountStatus[ARAccountStatus.Hold];
        break;
      case ARAccountStatus.AccessToFacilities:
        status = ARAccountStatus[ARAccountStatus.AccessToFacilities];
        break;
      case ARAccountStatus.Frozen:
        status = ARAccountStatus[ARAccountStatus.Frozen];
        break;
      case ARAccountStatus.Suspended:
        status = ARAccountStatus[ARAccountStatus.Suspended];
        break;
      case ARAccountStatus.Expired:
        status = ARAccountStatus[ARAccountStatus.Expired];
        break;

    }
    return status;
  }
  getEstARAccountStatusid(type: string): number {
    let statusId = 0;
    switch (type) {
      case "P":
        statusId = 1;
        break;
      case "E":
        statusId = 4;
        break;
      case "F":
        statusId = 5;
        break;
      case "I":
        statusId = 3;
        break;
      case "N":
        statusId = 6;
        break;
      case "S":
        statusId = 7;
        break;
      case "T":
        statusId = 2;
    }
    return statusId;
  }
  getStatusname(type: string): string {
    let status = '';
    switch (type) {
      case "P":
        status = ARAccountStatus[ARAccountStatus.Permanent];
        break;
      case "E":
        status = ARAccountStatus[ARAccountStatus.Expired];
        break;
      case "F":
        status = ARAccountStatus[ARAccountStatus.Frozen];
        break;
      case "I":
        status = ARAccountStatus[ARAccountStatus.Inactive];
        break;
      case "N":
        status = ARAccountStatus[ARAccountStatus.AccessToFacilities];
        break;
      case "S":
        status = ARAccountStatus[ARAccountStatus.Suspended];
        break;
      case "T":
        status = ARAccountStatus[ARAccountStatus.Temporary];
    }
    return status;
  }
 

  getStatus() {
    return [
      {
        id: ARAccountStatus.Permanent,
        name: ARAccountStatus[ARAccountStatus.Permanent],
        isSelected: false
      },
      {
        id: ARAccountStatus.Temporary,
        name: ARAccountStatus[ARAccountStatus.Temporary],
        isSelected: false
      },
      {
        id: ARAccountStatus.Inactive,
        name: ARAccountStatus[ARAccountStatus.Inactive],
        isSelected: false
      },
      {
        id: ARAccountStatus.Hold,
        name: ARAccountStatus[ARAccountStatus.Hold],
        isSelected: false
      },
      {
        id: ARAccountStatus.AccessToFacilities,
        name: ARAccountStatus[ARAccountStatus.AccessToFacilities],
        isSelected: false
      },
      {
        id: ARAccountStatus.Frozen,
        name: ARAccountStatus[ARAccountStatus.Frozen],
        isSelected: false
      },
      {
        id: ARAccountStatus.Expired,
        name: ARAccountStatus[ARAccountStatus.Expired],
        isSelected: false
      },
      {
        id: ARAccountStatus.Suspended,
        name: ARAccountStatus[ARAccountStatus.Suspended],
        isSelected: false
      }
    ]
  }

  MapToEstSearchBy(searchBy)
  {
     let key = Object.keys(SearchBy).find(x => SearchBy[x] === searchBy)

     if(key)
     {
     return EstSearchBy[key]
     }
     else
     {
       return searchBy
     }
  }
  private GetArAccountInActiveStatus() :number[]{
    return [ARAccountStatus.Inactive,ARAccountStatus.Expired];
  }
  private GetEstAccountInActiveStatus() :string[]{
    return [EstAccountStatus.Inactive,EstAccountStatus.Hold,EstAccountStatus.Frozen,EstAccountStatus.Expired,EstAccountStatus.Suspended];
  }
}
