import { Injectable, } from "@angular/core";
import { ReceiptService } from "./receipt.service";
import { BaseResponse, Transaction, TransactionDetail } from "../../../shared/business/shared.modals";
import { HttpServiceCall, HttpMethod } from "../../../shared/service/http-call.service";
import { Host, ReceiptType, Product, NamePrintConfig,DefaultSigCapCancellationTimeInSeconds,DefaultSigCapPollingTimeInSeconds } from "../../../shared/globalsContant";
import { CommonVariablesService } from "../../../shared/service/common-variables.service";
import { RetailLocalization } from "../../../common/localization/retail-localization";
import { RetailUtilities } from "../../../shared/utilities/retail-utilities";
import { ReportAPIOptions, AllReports, ReportParams, ReceiptConfiguration, PropertyConfigurationModel, ClientInfo, ReceiptInputs } from "../../shop.modals";
import { RetailPropertyInformation } from "../../../common/services/retail-property-information.service";
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { SubPropertyDataService } from 'src/app/retail/retail-code-setup/retail-outlets/subproperty-data.service';
import _ from 'lodash';
import { RetailRoutes } from "src/app/retail/retail-route";
import { MemberPaymentHistory } from "src/app/retail/shared/service/payment/payment-business.model";
import { DatamagineConfigBusiness } from "src/app/common/data-magine/data-magine-config/data-magine-config.business";
import { dataMagineBusiness } from "src/app/common/data-magine/data-magine-integration/data-magine-integration-business";
import { AlertType, ButtonTypes } from "src/app/common/enums/shared-enums";
import { SignatureCaptureService } from "../../signature-capture/signature-capture.service";
import { UI } from "src/app/common/data-magine/data-magine-config/data-magine-config.model";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { DMReceiptPosting } from 'src/app/common/data-magine/data-magine-integration/data-magine-models';
import { FastReportBusinessService } from '../../../retail-reports/fast-report/fastreport.business.service';
import { Outlet, OutletAddress } from 'src/app/retail/retail.modals';
import { map } from "rxjs/operators";
import { CUSTOM_FEE_RECEIPTNAME } from "src/app/common/shared/shared/custom-fee/custom-fee.model";
import { RetailItemType } from "src/app/retail/shared/shared.modal";
import { CancellationNoShowItemType } from "src/app/retail/payment/PaymentFlowHelpers";
// import { PaymentType } from "src/app/settings/system-setup/deposit-policy/deposit-policy.model";

@Injectable()
export class ReceiptBusinessService {

    public receiptConfiguration: ReceiptConfiguration;
    private salesDate = new Date();
    private selectedOutletName: string;
    private returnDate = new Date();
    public reportQuery: ReportAPIOptions;
    public PropertyConfigurationModel: PropertyConfigurationModel;
    public clientDetails: any = null;
    sigCapProduts: number[] = [Product.SPA, Product.GOLF, Product.RETAIL];
    dmConfig: UI.DmConfig;
    selectedOutletAddress: string = "";
    selectedOutletContactDetail: string = "";
    IncludeOutletAddress: string = "";
    selectedOutletAddress2: string = "";
    propConfig: any = [];
    sigcapDevicePollingTime: number;
    sigcapCancellationTime:number;
    constructor(private readonly reportService: ReceiptService
        , private localization: RetailLocalization
        , private http: HttpServiceCall
        , private shopService: CommonVariablesService
        , private utils: RetailUtilities
        , private PropertyInfo: RetailPropertyInformation
        , private _retailService: RetailSharedVariableService
        , private subPropService: SubPropertyDataService
        , private dataMagineConfigBusiness: DatamagineConfigBusiness
        , private dataMagineBusiness: dataMagineBusiness
        , private sigCapService: SignatureCaptureService
        ,private _fastReportBusinessService: FastReportBusinessService
    ) {
        this.propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
        const configuredPollingTime = Number(this.propConfig?.SigcapDevicePollingTime);
        const configuredCancellationTime = Number(this.propConfig?.SigcapCancellationTime);
        this.sigcapDevicePollingTime = !isNaN(configuredPollingTime) && configuredPollingTime > 0 ?
            Number(this.propConfig?.SigcapDevicePollingTime) : DefaultSigCapPollingTimeInSeconds;
        this.sigcapCancellationTime = !isNaN(configuredCancellationTime) && configuredCancellationTime > 0 ?
            Number(this.propConfig?.SigcapCancellationTime) : DefaultSigCapCancellationTimeInSeconds;
    }

    public async GetUserOutlet(selectedOutletId: Number) {
        var allOutlets: any = await this.subPropService.GetUserOutlets();
        return allOutlets.find(x => x.id == selectedOutletId);
    }

    public async GetUserOutletName(selectedOutletId: Number) {
        let outlet = await this.GetUserOutlet(selectedOutletId);
        return outlet.outletName || "";
    }
    public SetReceiptConfiguration(outletNumber: number): void {
        this.getReceiptConfig(outletNumber).then(resp => this.receiptConfiguration = resp);
    }
    public async SetPropertyReceiptConfiguration(){
        let propertyReceiptConfigString = sessionStorage.getItem('propertyReceiptConfiguration');
        if(!propertyReceiptConfigString ){
            this.PropertyConfigurationModel = await this.getPropertyReceiptConfig();
            propertyReceiptConfigString = JSON.stringify(this.PropertyConfigurationModel);
            sessionStorage.setItem("propertyReceiptConfiguration",propertyReceiptConfigString);
        }   
        this.PropertyConfigurationModel = JSON.parse(propertyReceiptConfigString);  
    }

    private formAddress1(outletAddress : OutletAddress): string{
        let Address : string[] = [outletAddress?.address1 , outletAddress?.address2, outletAddress?.address3];
        let address1 = Address?.filter(x=> x != null && x != "").join(', ')
        return address1;
    }
    private formAddress2(outletAddress : OutletAddress): string{
        let Address : string[] = [ outletAddress?.city, outletAddress?.state, outletAddress?.zip ,outletAddress?.country];
        let address2 = Address.filter(x=> x != null && x != "").join(', ')
        return address2;
    }

    private setOutletAddress(outlet: Outlet){
        if(outlet?.includeOutletAddress){
            this.selectedOutletAddress = this.formAddress1(outlet?.outletAddresses[0]);
            this.selectedOutletAddress2 = this.formAddress2(outlet?.outletAddresses[0]);
            this.selectedOutletContactDetail = outlet?.outletContacts[0]?.number?.replace('|',' ');
            this.IncludeOutletAddress = "true";
        }
        else
        {
            this.IncludeOutletAddress = "false";
        }
    }
    public async GenerateReceipt(ticketNumber: string, selectedOutletId: number, receiptType: ReceiptType, transactionId: number,
        memberCardNumber: string, clientName?: string, voidReason?: string, isPendingSettlement?: boolean,
        isReturn?: boolean, returnTransactionId?: number, callBack?: any, isTaxExempt?: boolean,
        isMemberTransaction?: boolean, availableRounds?: number, receiptComment?: string,
        saleDate: Date = this.PropertyInfo.CurrentDTTM, quickIdUser: string = '',
        updatedGiftCardBalance?: string, noOfReceipts?: number, requireReceipts: boolean = true,
        memberPaymentHistory?: MemberPaymentHistory[], isSigCapCompleted?: BehaviorSubject<[boolean, string]>, requireSignatureCapture?: boolean,
        isProceedAutoCloseTransaction?: BehaviorSubject<boolean>, isHotelGuest?: boolean, isHotelSpaGuest?: boolean , isPrintGiftReceipt?: boolean) {
        const sigCapReceiptTypes: ReceiptType[] = [ReceiptType.sales, ReceiptType.returnExchange, ReceiptType.preSettlement];
        if (this.PropertyInfo.UseRetailInterface) {
            return;
        }
        let outlet : Outlet  = await this.GetUserOutlet(selectedOutletId);
        if (this.sigCapProduts.includes(this.shopService.ProductId)) {
            this.dmConfig = await this.dataMagineConfigBusiness.GetDMConfig();
        }
        this.dmConfig = await this.dataMagineConfigBusiness.GetDMConfig();

        //post receipt to DM when receiptrequired is off or auto print receipt is off
        if(this.dmConfig && this.dmConfig?.postCheckReceiptToDM && (!requireReceipts ||(outlet && !outlet.autoReceipt) )){
            await this.PostReceiptToDM(ticketNumber,selectedOutletId, receiptType, transactionId,clientName,undefined
				,availableRounds,isHotelGuest,isHotelSpaGuest);
        }
        if ((receiptType != ReceiptType.reprint && outlet && !outlet.autoReceipt) || (receiptType == ReceiptType.sales && !requireReceipts)) {
            if (isSigCapCompleted) {
                isSigCapCompleted.next([true, '']);
                if (isProceedAutoCloseTransaction) {
                    isProceedAutoCloseTransaction.next(true);
                }
            }
            return;
        }
        this.selectedOutletName = outlet?.outletName || "";
        this.setOutletAddress(outlet);

        await this.getReceiptConfig(selectedOutletId).then(resp => this.receiptConfiguration = resp);
        this.salesDate = saleDate;
        let transactionDetails = null;
        let isVoided = false;
        let clerkId = 0;
        let voidedByClerkId = 0;
        let isCorrect = false;
        if (isReturn && this.shopService.settleRWTTransaction) {
            await this.getTransactionDetail(transactionId).then(resp => {
                returnTransactionId = resp.transactionData.transactionLinkId;
            });
        }
        if (receiptType == ReceiptType.reprint || receiptType == ReceiptType.void) {
            await this.getTransactionDetail(transactionId).then(resp => {
                transactionDetails = resp && resp.transactionData;
                isVoided = transactionDetails.isVoided;
                voidedByClerkId = transactionDetails.voidedByClerkId;
                isCorrect = transactionDetails.isCorrected;
                if (isVoided) {
                    voidReason = transactionDetails.voidReason;
                }
                this.salesDate = this.utils.getDate(resp.transactionData.transactionDate);
                receiptComment = resp.transactionData.receiptComment;
                if (receiptType == ReceiptType.reprint && (resp.transactionData.transactionLinkId || resp.transactionData.totalAmount < 0)) {
                    isReturn = true;                    
                    returnTransactionId = resp.transactionData.transactionLinkId;
                    this.getTransactionDetailsForReceipt(transactionId).then((transDt) => {
                        //Overriding isReturn flag for Deposit refunds as it is handled differently in receipts
                        if (resp.transactionData.totalAmount < 0
                            && transDt.some(x => x.itemType == RetailItemType.Deposit && x.totalAmount < 0)) {
                            isReturn = false;
                        }
                    });
                }
                clerkId = resp.transactionData.clerkId;
            });            

        }

        this.returnDate = this.salesDate;
        if (isReturn) {
            await this.getTransactionDetail(returnTransactionId).then(resp => {
                if (resp) {
                    transactionDetails = resp && resp.transactionData;
                    this.salesDate = new Date(resp.transactionData.transactionDate);
                    if (transactionDetails) {
                        if (transactionDetails.isVoided) {
                            isReturn = false;
                        }
                    }
                }
            });

        } else if (receiptType == ReceiptType.reprint && transactionDetails.transactionLinkId == 0) {

            await this.getTransactionDetailsForReceipt(transactionId).then(resp => {
                if (resp && resp.some(x => x.isReturn)) {
                    isReturn = true;
                }
            });

        }
        const quickUserid = quickIdUser ? quickIdUser : sessionStorage.getItem('quickIdUser');
        this.shopService.receiptClerkId = this.receiptConfiguration?.clerkIdPrintValue;
        this.shopService.receiptPrintedBy = this.receiptConfiguration?.printedByPrintValue;


        let receiptPrintedBy = this.GetReceiptPrintBy(this.shopService.receiptPrintedBy);
        let receiptClientBy = this.GetReceiptClient(this.shopService.receiptClerkId, quickUserid);

        let clerkName = receiptClientBy;
        const printedBy = receiptPrintedBy;
        clerkId = clerkId || transactionDetails?.clerkId;
        let voidedByClerkName = '';
        const UserId = Number(this.localization.GetsessionStorageValue('_userInfo', 'userId'));
        if ((receiptType == ReceiptType.reprint || receiptType == ReceiptType.void) && UserId && transactionDetails && clerkId != UserId) {
            const response: any[] = await this.getClerkDetail(clerkId);
            if (response && response.length > 0) {
                if (response[0] && response[0].userName) {
                    clerkName = this.MapQuickUserDetails(NamePrintConfig[this.shopService.receiptClerkId], response[0])
                }


            }
        }
        if (voidedByClerkId > 0) {
            if (voidedByClerkId == clerkId) voidedByClerkName = clerkName;
            else {
                const response: any[] = await this.getClerkDetail(voidedByClerkId);
                if (response && response.length > 0) { voidedByClerkName = response[0] && response[0].userName; }
            }
        }
        if (receiptType == ReceiptType.reprint && isVoided) {
            receiptType = ReceiptType.reprintVoid;
        }
        // map AuthCode:
        this.PropertyConfigurationModel = await this.getPropertyReceiptConfig();

        let isEnableMachineTransaction = sessionStorage.getItem('enableMachineTransaction') == "true" ? true : false;
        let options: ReportAPIOptions =await this.formReportAPIOptions(ticketNumber, selectedOutletId, receiptType, transactionId, clientName, voidReason,
            isPendingSettlement, isReturn, returnTransactionId, isTaxExempt, isMemberTransaction, availableRounds,
            receiptComment, clerkName, isEnableMachineTransaction, memberCardNumber, printedBy, voidedByClerkName, isCorrect, updatedGiftCardBalance, memberPaymentHistory,isHotelGuest,isHotelSpaGuest);
        this.reportQuery = _.cloneDeep(options);
        let sigCapOptions = _.cloneDeep(options);
        let giftReceiptType = receiptType == ReceiptType.reprint ? ReceiptType.reprint : ReceiptType.giftReceipt;
        let giftReceiptOption: ReportAPIOptions = isPrintGiftReceipt ? await this.formReportAPIOptions(ticketNumber, selectedOutletId, giftReceiptType,
            transactionId, clientName, voidReason, isPendingSettlement, isReturn, returnTransactionId, isTaxExempt, isMemberTransaction, availableRounds,
            receiptComment, clerkName, isEnableMachineTransaction, memberCardNumber, printedBy, voidedByClerkName, isCorrect,
            updatedGiftCardBalance, memberPaymentHistory, isHotelGuest, isHotelSpaGuest, isPrintGiftReceipt) : null;
        if (!transactionDetails && this.dmConfig && this.dmConfig?.postCheckReceiptToDM && receiptType != ReceiptType.reprint && receiptType != ReceiptType.reprintVoid && receiptType != ReceiptType.preSettlement ){
            await this.getTransactionDetail(transactionId).then(resp => {
                transactionDetails = resp && resp.transactionData;});
        }
        if (this.dmConfig && this.dmConfig?.enableDatamagine && this.dmConfig?.enableSignatureCapture && this.dmConfig?.signCapEndPoint
            && this.dmConfig?.signCapEndPoint != "" && sigCapReceiptTypes.includes(receiptType) && requireSignatureCapture) {
            this.utils.signatureLoader(true, this.localization.captions.initiate_SignatureCapture);
            let receiptString = "";          
            try {
                receiptString = await this.reportService.getReceiptAsStringForCheckZoom(sigCapOptions);
                this.utils.signatureLoader(true, this.localization.captions.initiate_SignatureCapture);
                let indexingDetails: DMReceiptPosting = {
                    receipt: undefined,
                    indexValueList: [],
                    receiptInput: "",
                    receiptResponse: "",
                    TransactionId: 0,
                    PCname: ""
                };
                // this.dmConfig.PostCheckReceiptToDM -- true should be replaced with the above condition
                 if (this.dmConfig && this.dmConfig?.postCheckReceiptToDM && receiptType != ReceiptType.reprint && receiptType != ReceiptType.reprintVoid && receiptType != ReceiptType.preSettlement ) {
                    // form IndexList as Designed in DM Server
                     let indexes = [];
                     indexes.push(ticketNumber);
                     indexes.push(transactionDetails.retailTransactionType);
                     isReturn ? indexes.push(this.localization.ConvertDateToISODateTime(this.returnDate)) : indexes.push(this.localization.ConvertDateToISODateTime(saleDate));

                    indexingDetails.indexValueList = indexes;
                    indexingDetails.TransactionId = transactionId;
                    indexingDetails.PCname = "Default";

                 }
                await this.inititateSigCapDevice(this.dmConfig, transactionId, receiptString, options, noOfReceipts, callBack, isSigCapCompleted, false, ticketNumber, isProceedAutoCloseTransaction,indexingDetails,isPrintGiftReceipt,giftReceiptOption);
            }
            catch (error) {
                const errorMessage = this.localization.replacePlaceholders(this.localization.captions.err_sigCapGenericMessage, ['reason'], ["Error during device content generation"]);
                this.utils.showCommonAlert(errorMessage, AlertType.Error, ButtonTypes.Ok);
                this.utils.signatureLoader(false);
                if (isSigCapCompleted) {
                    isSigCapCompleted.next([true, '']);
                }
                if (isProceedAutoCloseTransaction) {
                    isProceedAutoCloseTransaction.next(true);
                }
                this.reportService.printReceipt("PDF", options, noOfReceipts, callBack);
            }
        } else {
            if (isSigCapCompleted) {
                isSigCapCompleted.next([true, '']);
            }
            let indexingDetails: DMReceiptPosting = {
                receipt: undefined,
                indexValueList: [],
                receiptInput: "",
                receiptResponse: "",
                TransactionId: 0,
                PCname: ""
            };
            // this.dmConfig.PostCheckReceiptToDM -- true should be replaced with the above condition
             if (this.dmConfig && this.dmConfig?.postCheckReceiptToDM && receiptType != ReceiptType.reprint && receiptType != ReceiptType.reprintVoid && receiptType != ReceiptType.preSettlement) {
                
                // form IndexList as Designed in DM Server
                let indexes=[];
                indexes.push(ticketNumber);
                indexes.push(transactionDetails.retailTransactionType);
                isReturn ? indexes.push(this.localization.ConvertDateToISODateTime(this.returnDate)) : indexes.push(this.localization.ConvertDateToISODateTime(saleDate));

                indexingDetails.indexValueList = indexes;
                indexingDetails.TransactionId = transactionId;
                indexingDetails.PCname = "Default";
                this.reportService.printReceipt("PDF", options, noOfReceipts, callBack,indexingDetails);
             }
             else {
                //Restricting reprint receipt incase of reprint gift receipt
                 const shouldPrintReceipt = !isPrintGiftReceipt || receiptType !== ReceiptType.reprint;
                 if (shouldPrintReceipt) {
                     this.reportService.printReceipt("PDF", options, noOfReceipts, callBack);
                 }
             }
            if (isPrintGiftReceipt) {

                this.reportService.printReceipt("PDF", giftReceiptOption, noOfReceipts, callBack);

            }
           
            if (isProceedAutoCloseTransaction) {
                isProceedAutoCloseTransaction.next(true);
            }
        }

        this.shopService.ReceiptComment = "";

    }
    async inititateSigCapDevice(dmConfig, retailTransactionId, receiptString, options, noOfReceipts, callBack, isSigCapCompleted?: BehaviorSubject<[boolean, string]>, isFromPreSettlement: boolean = false, ticketNumber = "",
        isProceedAutoCloseTransaction?: BehaviorSubject<boolean>, indexingDetails: DMReceiptPosting = null, isPrintGiftReceipt: boolean = false, giftReceiptOption = null) {
        let signatureText = "";

        if (dmConfig?.signCapEndPoint) {
            var transactionId = this.dataMagineBusiness.getCurrentTimeStamp() + "-" + retailTransactionId;
            var deviceRes: any;
            try {
                this.utils.signatureLoader(true, this.localization.captions.initiate_SignatureCapture);
                deviceRes = await this.sigCapService.inititateSigCapDevice(
                    transactionId,
                    dmConfig?.signCapEndPoint
                    , receiptString
                    , retailTransactionId
                    , ticketNumber
                );
            } catch (error) {
                this.utils.signatureLoader(false);
                if (isSigCapCompleted) {
                    isSigCapCompleted.next([true, '']);
                }
                this.utils.showCommonAlert(this.localization.captions.err_sigCapEndpoint, AlertType.Error, ButtonTypes.Ok);
                if (!isFromPreSettlement) {
                    if (this.dmConfig.postCheckReceiptToDM) {
                        this.reportService.printReceiptSigcap("PDF", options, noOfReceipts, callBack, indexingDetails);
                    }
                    else {
                        this.reportService.printReceipt("PDF", options, noOfReceipts, callBack);
                    }

                    if (isPrintGiftReceipt) {
                        this.reportService.printReceipt("PDF", giftReceiptOption, noOfReceipts, callBack);
                    }

                    if (isProceedAutoCloseTransaction) {
                        isProceedAutoCloseTransaction.next(true);
                    }
                   
                }
                return;
            }
            if (deviceRes && deviceRes.code == 0 && deviceRes.message.toLowerCase() == "success") {
                let count = 0;

                let setIntervalConst = setInterval(async () => {
                    this.utils.signatureLoader(true, this.localization.captions.initiate_SignatureCapture);
                    count++;
                    let res = await this.sigCapService.getSigCapResponseByTransactionId(transactionId, dmConfig?.signCapEndPoint);

                    if (res && res.message) {
                        clearTimeout(setIntervalConst);

                        if (res.code == 0 && res.message.toLowerCase() === "success" && res.signatureFile && res.signatureFile.length > 0) {                         
                            signatureText = res.signatureFile;
                            this.utils.signatureLoader(true, this.localization.captions.success_SignatureCapture);
                            if (isSigCapCompleted) {
                                isSigCapCompleted.next([true, signatureText]);
                            }
                            if (!isFromPreSettlement) {
                                let isSaveSuccess = await this.sigCapService.addTransactionSignature(retailTransactionId, signatureText);
                                if(this.dmConfig.postCheckReceiptToDM){
                                    this.reportService.printReceiptSigcap("PDF", options, noOfReceipts, callBack,indexingDetails);
                                }
                                else{
                                    this.reportService.printReceipt("PDF", options, noOfReceipts, callBack);
                                }

                                if (isPrintGiftReceipt) {
                                    this.reportService.printReceipt("PDF", giftReceiptOption, noOfReceipts, callBack);
                                }

                                if (isProceedAutoCloseTransaction) {
                                    isProceedAutoCloseTransaction.next(true);
                                }

                            }
                            this.utils.signatureLoader(false);
                            this.utils.showCommonAlert(res.message, AlertType.Success, ButtonTypes.Ok);
                        } else {
                            if (isSigCapCompleted) {
                                isSigCapCompleted.next([true, '']);
                            }
                            if (!isFromPreSettlement) {
                                if (this.dmConfig.postCheckReceiptToDM) {
                                    this.reportService.printReceiptSigcap("PDF", options, noOfReceipts, callBack, indexingDetails);
                                }
                                else {
                                    this.reportService.printReceipt("PDF", options, noOfReceipts, callBack);
                                }

                                if (isPrintGiftReceipt) {
                                    this.reportService.printReceipt("PDF", giftReceiptOption, noOfReceipts, callBack);
                                }

                                if (isProceedAutoCloseTransaction) {
                                    isProceedAutoCloseTransaction.next(true);
                                }
                               
                            }
                            const errorMessage = this.localization.replacePlaceholders(this.localization.captions.err_sigCapGenericMessage, ['reason'], [res.message]);
                            this.utils.signatureLoader(false);
                            this.utils.showCommonAlert(errorMessage, AlertType.Error, ButtonTypes.Ok);
                        }
                        return;
                    }
                    if (count > (this.sigcapCancellationTime/this.sigcapDevicePollingTime)) {
                        clearTimeout(setIntervalConst);
                        this.utils.signatureLoader(false);
                        this.utils.showCommonAlert(this.localization.captions.noResponse, AlertType.Error, ButtonTypes.Ok);
                        if (isSigCapCompleted) {
                            isSigCapCompleted.next([true, '']);
                        }
                        if (!isFromPreSettlement) {
                            this.reportService.printReceipt("PDF", options, noOfReceipts, callBack);

                            if (isPrintGiftReceipt) {
                                this.reportService.printReceipt("PDF", giftReceiptOption, noOfReceipts, callBack);
                            }

                            if (isProceedAutoCloseTransaction) {
                                isProceedAutoCloseTransaction.next(true);
                            }
                        }
                    }

                }, this.sigcapDevicePollingTime * 1000);

            }
        }

    }
    
    async PostReceiptToDM(ticketNumber:string,outletId:number,receiptType:ReceiptType,transactionId:number,clientName:string,voidReason:string,availableRounds:number,isHotelGuest:boolean,isHotelSpaGuest:boolean){
        await this.getReceiptConfig(outletId).then(resp => this.receiptConfiguration = resp);
    
        let transactionDetails = null;
        let isVoided = false;
        let clerkId = 0;
        let voidedByClerkId = 0;
        let isCorrect = false;
        let receiptComment=null;
        
        this.shopService.receiptClerkId = this.receiptConfiguration.clerkIdPrintValue;
        this.shopService.receiptPrintedBy = this.receiptConfiguration.printedByPrintValue;
        this.dmConfig = await this.dataMagineConfigBusiness.GetDMConfig();
        const quickUserid = sessionStorage.getItem('quickIdUser');
        let receiptPrintedBy = this.GetReceiptPrintBy(this.shopService.receiptPrintedBy);
        let receiptClientBy = this.GetReceiptClient(this.shopService.receiptClerkId, quickUserid);

        let clerkName = receiptClientBy;
        const printedBy = receiptPrintedBy;
        clerkId = clerkId || transactionDetails?.clerkId;
        let voidedByClerkName = '';
        const UserId = Number(this.localization.GetsessionStorageValue('_userInfo', 'userId'));

        if (voidedByClerkId > 0) {
            if (voidedByClerkId == clerkId) voidedByClerkName = clerkName;
            else {
                const response: any[] = await this.getClerkDetail(voidedByClerkId);
                if (response && response.length > 0) { voidedByClerkName = response[0] && response[0].userName; }
            }
        }
        await this.getTransactionDetail(transactionId).then(resp => {
            transactionDetails = resp && resp.transactionData;
            isVoided = transactionDetails.isVoided;
            voidedByClerkId = transactionDetails.voidedByClerkId;
            isCorrect = transactionDetails.isCorrected;
            if (isVoided) {
                voidReason = transactionDetails.voidReason;
            }
            this.salesDate = this.utils.getDate(resp.transactionData.transactionDate);
            receiptComment = resp.transactionData.receiptComment;
            clerkId = resp.transactionData.clerkId;
        });

       
        let options: ReportAPIOptions = await this.formReportAPIOptions(ticketNumber, outletId, receiptType, transactionId, clientName, voidReason,
            false, false, 0, false, false, availableRounds,
            receiptComment, clerkName,false, '', printedBy, voidedByClerkName, isCorrect, '',undefined,isHotelGuest,isHotelSpaGuest);
            let indexingDetails: DMReceiptPosting = {
                receipt: undefined,
                indexValueList: [],
                receiptInput: "",
                receiptResponse: "",
                TransactionId: 0,
                PCname: ""
            };
            // this.dmConfig.PostCheckReceiptToDM -- true should be replaced with the above condition
             if (this.dmConfig && this.dmConfig?.postCheckReceiptToDM && receiptType != ReceiptType.reprint && receiptType != ReceiptType.preSettlement) {
                // form IndexList as Designed in DM Server
                let indexes=[];
                indexes.push(ticketNumber);
                indexes.push(transactionDetails.retailTransactionType);
                indexes.push(this.localization.ConvertDateToISODateTime(this.salesDate));

                indexingDetails.indexValueList = indexes;
                indexingDetails.TransactionId = transactionId;
                indexingDetails.PCname = "Default";
                this._fastReportBusinessService.PostReceiptToDM(options,indexingDetails)
             }
        
    }
    getKeyByValue(enumObject: any, value: any): string | undefined {
        return Object.keys(enumObject).find(key => enumObject[key] === value);
      }
    GetReceiptClient(clerkId: number, quickId: string) {
        let quickUserId = parseInt(quickId);
        let quickUseDetails = JSON.parse(sessionStorage.getItem('quickUserDetails'));

        let cleint = "";
        let user = "";
        switch (clerkId) {
            case 0:
                cleint = "userName";
                break;
            case 1:
                cleint = "FullName";
                break;
            case 2:
                cleint = "firstName";
                break;
            case 3:
                cleint = "lastName";
                break;
        }
        if (quickUseDetails != null) {
            user = this.MapQuickUserDetails(cleint, quickUseDetails);
        }
        else {
            user = this.GetReceiptPrintBy(clerkId);
        }

        return user;

    }

    MapQuickUserDetails(client: string, quickUseDetails: any) {
        let user = "";

        if (client == "FullName") {
            let firstName = quickUseDetails.firstName;
            let lastName = quickUseDetails.lastName;
            return firstName + ' ' + lastName;
        }
        else if (client == "userName") {
            user = quickUseDetails.userName;
        }
        else if (client == "firstName") {
            user = quickUseDetails.firstName;
        }
        else if (client == "lastName") {
            user = quickUseDetails.lastName;
        }
        return user;
    }

    GetReceiptPrintBy(PrintedBy: number) {
        let printedBy = "";
        switch (PrintedBy) {
            case 0:
                printedBy = "userName";
                break;
            case 1:
                printedBy = "FullName";
                break;
            case 2:
                printedBy = "firstName";
                break;
            case 3:
                printedBy = "lastName";
                break;
        }
        if (printedBy == "FullName") {
            let firstName = this.FramePrintedBy("firstName=");
            let lastName = this.FramePrintedBy("lastName=");
            return firstName + ' ' + lastName;
        }
        printedBy = printedBy + '=';
        let result = this.FramePrintedBy(printedBy);
        return result;

    }

    FramePrintedBy(printedBy: string) {
        let userInfo = sessionStorage.getItem('_userInfo');
        let userInfoArr = userInfo.split(';');
        for (let data of userInfoArr) {
            let c = data.trim();
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(printedBy) == 0) {
                return c.substring(printedBy.length, c.length);
            }
        }

    }


    public async getOutletName(selectedOutletId: number) {
        await this.GetUserOutletName(selectedOutletId).then((o) => {
            this.selectedOutletName = o;
        });
        if (this.selectedOutletName) {
            return true;
        } else {
            return false;
        }


    }
    public async getSalesDate(transactionId: number): Promise<Date> {
        let response: BaseResponse<Transaction> = await this.http.CallApiAsync<Transaction>({
            callDesc: "GetTransactionInfoById",
            host: Host.retailPOS,
            method: HttpMethod.Get,
            uriParams: { transactionId: transactionId }
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return new Date(response.result.transactionData.transactionDate);
    }

    public async getTransactionDetail(transactionId: number): Promise<Transaction> {
        let response: BaseResponse<Transaction> = await this.http.CallApiAsync<Transaction>({
            callDesc: "GetTransactionInfoById",
            host: Host.retailPOS,
            method: HttpMethod.Get,
            uriParams: { transactionId: transactionId }
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }
    public async getTransactionDetailsForReceipt(transactionId: number): Promise<TransactionDetail[]> {
        let response: BaseResponse<TransactionDetail[]> = await this.http.CallApiAsync<TransactionDetail[]>({
            callDesc: "GetTransactionDetailsForReceipt",
            host: Host.retailPOS,
            method: HttpMethod.Get,
            uriParams: { transactionId: transactionId }
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    public async getClerkDetail(clerkId: number): Promise<any> {
        let response: BaseResponse<Transaction> = await this.http.CallApiAsync<Transaction>({
            callDesc: "GetAllUsersbyUserIds",
            host: Host.authentication,
            method: HttpMethod.Get,
            uriParams: { tenantId: this.utils.GetPropertyInfo('TenantId') },
            withQueryString: "ids",
            queryString: { key: "ids", value: [clerkId] }
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    public getReceiptTitle(receiptType: ReceiptType) {
        switch (receiptType) {
            case ReceiptType.sales:
                return this.localization.captions.receipt.SalesTicket;
            case ReceiptType.void:
                return this.localization.captions.receipt.VoidTicket;
            case ReceiptType.reprint:
                return this.localization.captions.receipt.ReprintTicket;
            case ReceiptType.returnExchange:
                return this.localization.captions.receipt.ReturnExchangeTicket;
            default:
                return this.localization.captions.receipt.SalesTicket;
        }
    }

    GetReceiptCode(productid: number): string {
        switch (productid) {
            case Product.SPA:
                return AllReports.Receipt;
            case Product.GOLF:
                return AllReports.GolfReceipt;
            case Product.SNC:
                return AllReports.RetailReceipt;
            case Product.RETAIL:
                return AllReports.RetailReceipt;
            case Product.PMS:
                return AllReports.PMSReceipt;
            default:
                return AllReports.Receipt;
        }
    }

    public formPMSPaymentReportAPIOptions(clientName: string, clerkName: string, totalPostingAmt: number, postType: string, roomNumber: string,
        isEnableMachineTransaction: boolean, paymentTypeId: number, paymentType: string, confNumber: string,
        cardNumber: string, isCardPayment: boolean, postDate: string, araccountName: string,
        isReprint: boolean, postingId: string, surchargeAmount?: string, isResortFinance?:boolean, folioNumber?:string): ReportAPIOptions {
        return {
            code: "PMSPaymentReceipt",
            params: this.formPMSPaymentReportParams(clientName, clerkName, totalPostingAmt, postType, roomNumber, isEnableMachineTransaction,
                paymentTypeId, paymentType, confNumber, cardNumber, isCardPayment, postDate, araccountName, isReprint, postingId, surchargeAmount,
                isResortFinance, folioNumber),
            URIParams: [],
            filters: {},
            format: 'PDF',
            pageBreak: true,
            downloadFileName: "SettlementReceipt"
        };
    }

    private formPMSPaymentReportParams(
        clientName: string
        , clerkName?: string
        , totalPostingAmt?: number
        , postType?: string
        , roomNumber?: string
        , isEnableMachineTransaction?: boolean
        , paymentTypeId?: number
        , paymentType?: string
        , confNumber?: string
        , cardNumber?: string
        , isCardPayment?: boolean
        , postDate?: string
        , araccountName?: string
        , isReprint?: boolean
        , postingId?: string
        , surchargeAmount?: string
        , isResortFinance? : boolean
        , folioNumber? : string
    ): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{
            'pPrintedDateData': this.localization.ConvertDateToISODateTime(this.PropertyInfo.CurrentDTTM)
        },
        { 'pSalesDateData': postDate },
        { 'pPaymentType': paymentType },
        { 'pPaymentTypeId': paymentTypeId },
        { 'pClerkData': clerkName },
        { 'pClientName': clientName },
        { 'pTotalPostingAmt': totalPostingAmt },
        { 'pPostType': postType },
        { 'pRoomNumber': roomNumber },
        { 'pConfirmationNumber': confNumber },
        { 'pReceiptTitle': "Settlement Receipt" },
        { 'pCardNumber': cardNumber },
        { 'pIsCardPayment': isCardPayment },
        { 'pARAccountName': araccountName },
        { 'pIsReprint': isReprint },
        { 'pPostingId': postingId },
        { 'pSurchargeAmount': surchargeAmount },
        { 'pIsResortFinance' : isResortFinance },
        { 'pFolioNumber' : folioNumber}
        ];
    }


    public async formReportAPIOptions(ticketNumber: string, selectedOutletId: number, receiptType: ReceiptType, transactionId: number, clientName: string,
        voidReason?: string, isPendingSettlement?: boolean, isReturn?: boolean, returnTransactionId?: number, isTaxExempt?: boolean, isMemberTransaction?: boolean,
        availableRounds?: number, receiptComment?: string, clerkName?: string, isEnableMachineTransaction?: boolean, memberCardNumber: string = '',
        printedBy: string = '', voidedByClerkName: string = '', isCorrect: boolean = false, updatedGiftCardBalance: string = '',
        memberPaymentHistory?: MemberPaymentHistory[], isHotelGuest?: boolean, isHotelSpaGuest?: boolean,isprintGiftReceipt?:boolean): Promise<ReportAPIOptions> {
        if (this.selectedOutletName == null || this.selectedOutletAddress != undefined || this.selectedOutletAddress != "") {
            await this.GetUserOutlet(selectedOutletId).then(x => {
                this.selectedOutletName = x.outletName || "";
                this.setOutletAddress(x);
            });
        }
        return {
            code: this.GetReceiptCode(this.shopService.ProductId),
            params: this.formReportParams(ticketNumber, selectedOutletId, receiptType, clientName, voidReason, isPendingSettlement, isReturn, returnTransactionId,
                isTaxExempt, isMemberTransaction, availableRounds, receiptComment, clerkName, isEnableMachineTransaction, memberCardNumber,
                printedBy, voidedByClerkName, isCorrect, updatedGiftCardBalance, memberPaymentHistory, isHotelGuest, isHotelSpaGuest, isprintGiftReceipt),
            URIParams: this.formURIParams(transactionId),
            filters: [transactionId],
            format: 'PDF',
            pageBreak: true
        };
    }


    private formReportParams(
        ticketNumber: string
        , selectedOutletId: number
        , receiptType: ReceiptType
        , clientName: string
        , voidReason?: string
        , isPendingSettlement?: boolean
        , isReturn?: boolean
        , returnTransactionId?: number
        , isTaxExempt?: boolean
        , isMemberTransaction = false
        , availableRounds = 0
        , receiptComment?: string
        , clerkName?: string
        , isEnableMachineTransaction?: boolean
        , memberCardNumber: string = ''
        , printedBy: string = ''
        , voidedByClerkName: string = ''
        , isCorrect: boolean = false
        , updatedGiftCardBalance?: string
        , memberPaymentHistory?: MemberPaymentHistory[]
        , isHotelGuest?: boolean 
        , isHotelSpaGuest?: boolean
        , isPrintGiftReceipt?: boolean
    ): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ 'pPrintedDateData': this.localization.ConvertDateToISODateTime(this.PropertyInfo.CurrentDTTM) },
        { 'pSalesDateData': this.localization.ConvertDateToISODateTime(this.salesDate) },
        { 'pReturnDateData': this.localization.ConvertDateToISODateTime(this.returnDate) },
        { 'pOutletData':  this.selectedOutletName},
        { 'pOutletAddress':  this.selectedOutletAddress},
        { 'pOutletAddress2':  this.selectedOutletAddress2},
        { 'pContactDetail':  this.selectedOutletContactDetail},
        { 'pIsOutletAddressRequired':  this.IncludeOutletAddress},
        { 'pTicketData': ticketNumber },
        { 'pClerkData': clerkName },
        { 'pClientName': clientName },
        { 'pReceiptTitle': this.getReceiptTitle(receiptType) },
        { 'pReceiptTypeNumber': receiptType },
        { 'pNumberOfReceipts': this.receiptConfiguration.numberOfReceipts },
        { 'pDisplayDiscount': this.receiptConfiguration.displayDiscount },
        { 'pAddSecondLine': this.receiptConfiguration.addSecondLine },
        { 'pDisplayOnlyPackageItem': this.receiptConfiguration.displayOnlyPackageItem },
        { 'pSuppressClerkId': this.receiptConfiguration.suppressClerkId },
        { 'pSuppressPrintedBy': this.receiptConfiguration.suppressPrintedBy },
        { 'pDisplayPackageDescription': this.receiptConfiguration.displayPackageDescription },
        { 'pDisplayPackagePrice': this.receiptConfiguration.displayPackagePrice },
        { 'pDisplayPackageAppointmentID': this.receiptConfiguration.displayPackageAppointmentID },
        { 'pDisplayPackageStaffCode': this.receiptConfiguration.displayPackageStaffCode },
        { 'pServiceChargeGratuityDisplay': this.receiptConfiguration.serviceChargeGratuityDisplay },
        { 'pGratuityLine': this.receiptConfiguration.gratuityLine ? this.receiptConfiguration.gratuityLine : "" },
        { 'pReceiptNote': this.receiptConfiguration.receiptNote ? this.receiptConfiguration.receiptNote : "" },
        { 'pVoidedReason': voidReason ? voidReason : "" },
        { 'pIsPendingSettlement': isPendingSettlement },
        { 'pIsReturn': isReturn },
        { 'pReturnTransactionId': returnTransactionId },
        { 'pVATExempt': this.localization.captions.reports.VATExempt },
        { 'pVATEnabled': this.PropertyInfo.IsVATEnabled },
        { 'pIsTaxExempt': isTaxExempt },
        { 'pIsMemberTransaction': memberCardNumber && memberCardNumber !== '' && memberCardNumber !== '0' ? true : false },
        { 'pAvailableRounds': availableRounds },
        { 'pReceiptComment': receiptComment },
        { 'pPrintMachineName': isEnableMachineTransaction },
        { 'pEnableItemGroup': this.receiptConfiguration.displayGroupedItem },
        { 'pMember': memberCardNumber },
        { 'pDisplayMemberCard': memberCardNumber && memberCardNumber !== '' && memberCardNumber !== '0' ? true : false },
        { 'pPrintedBy': printedBy.toUpperCase() },
        { 'pVoidedByClerkID': voidedByClerkName.toUpperCase() },
        { 'pIsCorrected': isCorrect },
        { 'pUpdatedGiftCardBalance': updatedGiftCardBalance },
        { 'pAuthcodeReceiptName': this.getAuthCodeReceiptName() },
        { 'pReceiptFooterNote': this.getReceiptFooterNote() },
        { 'pDisplayAuthcode': this.PropertyConfigurationModel?.configValue?.displayAuthCode },
        { 'pMemberPaymentHistory': JSON.stringify(memberPaymentHistory) },
        { 'pDisplayChangeDue': this.PropertyConfigurationModel?.configValue?.displayChangeDue},        
        { 'pIsHotelGuest': isHotelGuest },
        { 'pIsHotelSpaGuest': isHotelSpaGuest },
        { 'pDisplayTeeTimeInfoInDeposit': this.PropertyConfigurationModel?.configValue?.displayTeeTimeInfoInDeposit ?? false},    
        { 'pDisplayDetailedCustomFee': this.receiptConfiguration.displayDetailedCustomFee },
        { 'pCustomFeeReceiptName': this.getCustomFeeReceiptName() },
        {'pIsGiftReceipt':isPrintGiftReceipt}
        ];
    }

    private getAuthCodeReceiptName(): string {
        return this.PropertyConfigurationModel ? this.PropertyConfigurationModel.configValue.displayAuthCode
            && this.PropertyConfigurationModel.configValue.authCodeReceiptName !== '' ?
            this.PropertyConfigurationModel.configValue.authCodeReceiptName : this.PropertyConfigurationModel.defaultValue.authCodeReceiptName : ''
    }
    private getCustomFeeReceiptName(): string {
        return this.PropertyConfigurationModel ? (this.PropertyConfigurationModel.configValue.customFeeReceiptName !== null && this.PropertyConfigurationModel.configValue.customFeeReceiptName !== '') ?
            this.PropertyConfigurationModel.configValue.customFeeReceiptName : CUSTOM_FEE_RECEIPTNAME : CUSTOM_FEE_RECEIPTNAME
    }

    private getReceiptFooterNote(): string {
        return this.PropertyConfigurationModel
            && this.PropertyConfigurationModel.configValue.receiptFooterNote !== '' ?
            this.PropertyConfigurationModel.configValue.receiptFooterNote : '';
    }

    private formURIParams(transactionId: number): ReportParams[] {
        return [{ 'transactionId': transactionId }];
    }

    private async getReceiptConfig(outletId: number): Promise<ReceiptConfiguration> {
        let response: BaseResponse<ReceiptConfiguration> = await this.http.CallApiAsync<ReceiptConfiguration>({
            callDesc: "GetReceiptConfigurationByOutletId",
            host: Host.retailManagement,
            method: HttpMethod.Get,
            uriParams: { id: outletId }
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }
    private async getPropertyReceiptConfig(): Promise<PropertyConfigurationModel> {
        let response: BaseResponse<PropertyConfigurationModel> = await this.http.CallApiAsync<PropertyConfigurationModel>({
            callDesc: RetailRoutes.GetPropertyReceiptConfiguration,
            host: Host.retailManagement,
            method: HttpMethod.Get
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
    }

    public async getClientInfobyGuid(GuidID: string): Promise<ClientInfo> {
        let response: BaseResponse<ClientInfo> = await this.http.CallApiAsync<ClientInfo>({
            callDesc: RetailRoutes.GetClientInfobyGuid,
            host: Host.retailPOS,
            method: HttpMethod.Get,
            uriParams: { guid: GuidID }
        });
        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }
}
