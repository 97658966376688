import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NotifyType } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { MerchantSettings, SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-staff-notifications',
  templateUrl: './staff-notifications.component.html',
  styleUrls: ['./staff-notifications.component.scss']
})
export class StaffNotificationsComponent implements OnInit {

  emailNotificationConfig: FieldConfig[] = [];
  @ViewChild('emailNotification', { static: true }) emailNotificationsForm: DynamicFormComponent;
  @Input() settings;
  @Input() specialMealId: number;
  @Input() SalesContactIds: number[];
  @Input() emailList: string[];
  @Output() notificationEvent: EventEmitter<any> = new EventEmitter<any>();
  predefinedStaffs: number[] = [];
  predefinedEmailList: string[] = [];
  showSalesContact: boolean;
  constructor(private ts: TranslateService) { }

  ngOnInit(): void {
    this.getPredefinedEmail();
    this.initializeConfig();
  }
  ngAfterViewInit() {
    this.emailNotificationsForm.form.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
      if (data && Array.isArray(data.NotificationEmailIds)) {
        this.notificationEvent.emit(this.showSalesContact ? data : null);
      }
    });
    this.updatePayload();

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.specialMealId?.currentValue && this.emailNotificationConfig?.length) {
      this.setupdatedValue();
    }
  }
  updatePayload() {
    setTimeout(() => {
      if (this.emailNotificationsForm?.value) {
        this.notificationEvent.emit(this.showSalesContact ? this.emailNotificationsForm?.value : null);
      }
    }, 200)
  }
  setupdatedValue() {
    this.getPredefinedEmail();
    let contactIndex = this.emailNotificationConfig.findIndex(config => config.name == 'SalesContactIds');
    let emailIndex = this.emailNotificationConfig.findIndex(config => config.name == 'NotificationEmailIds');
    let selectedStaffList = this.getMappedStaffs()[0];
    this.emailNotificationConfig[contactIndex].multiChipValues = selectedStaffList;
    this.emailNotificationConfig[contactIndex].value = this.predefinedStaffs;
    this.emailNotificationConfig[emailIndex].multiChipValues = this.predefinedEmailList.map(email => { return { value: email } });
    this.emailNotificationConfig[contactIndex].value = this.predefinedEmailList?.map(email => { return { value: email } });
    this.emailNotificationsForm.form.get('SalesContactIds').setValue(this.predefinedStaffs);
    this.emailNotificationsForm.form.get('NotificationEmailIds').setValue(this.predefinedEmailList);
    this.updatePayload();
  }
  getPredefinedEmail() {
    if (this.specialMealId) {
      let activity = this.settings.SpecialMeals.find(activity => activity.Id == this.specialMealId);
      this.showSalesContact = activity?.TrackingofSalesPerson || activity?.NotificationType == NotifyType.All;
      this.predefinedEmailList = activity?.NotificationEmailIds || [];
      this.predefinedStaffs = activity?.SalesContactIds || [];
    } else {
      let _merchantSettings = this.settings?.MerchantSettings as MerchantSettings;
      this.predefinedEmailList = _merchantSettings?.NotificationEmailIds?.SettingValue ? JSON.parse(_merchantSettings?.NotificationEmailIds?.SettingValue) : [];
      this.predefinedStaffs = _merchantSettings?.NotificationUserIds?.SettingValue ? JSON.parse(_merchantSettings?.NotificationUserIds?.SettingValue) : [];
      this.showSalesContact = this.settings.PropertySetting[0]?.TrackingofSalesPerson || Number(_merchantSettings?.NotificationType.SettingValue);
    }
  }
  getMappedStaffs() {
    let _staffList = cloneDeep(this.settings.Hosts) as any;
    let selectedStaffList = [];
    let _predefinedStaffList = this.SalesContactIds || this.predefinedStaffs;
    if (_predefinedStaffList?.length) {
      selectedStaffList = _staffList.filter(staff => _predefinedStaffList.includes(staff.Id))?.map(contact => { contact.firstName = contact.Name , contact.id = contact.Id; return contact });
    }
    return [selectedStaffList, _staffList]
  }
  initializeConfig() {

    let selectedStaffList = this.getMappedStaffs()[0]
    let _staffList = this.getMappedStaffs()[1]
    let _selectedEmails = this.emailList || this.predefinedEmailList;
    this.emailNotificationConfig = [
      {
        type: 'autocomplete',
        name: 'SalesContactIds',
        options: _staffList.map(staff => { staff.firstName = staff.Name; staff.value = staff.Name; staff.id = staff.Id; return staff }) || [],
        label: this.ts.instant('NotificationStaff'),
        class: 'w-90',
        showErrorText: true,
        isTranslate: false,
        autoCompleteWithId: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        isChipsEnabled: true,
        isEmailChipsEnabled: false,
        multiChipValues: selectedStaffList,
        value: selectedStaffList.map(staff => { return staff.Id }),
        returnFunction: this.clearFormField.bind(this, 'SalesContactIds'),
      },
      {
        type: 'autocomplete',
        name: 'NotificationEmailIds',
        options: this.emailList || [],
        label: this.ts.instant('NotificationEmail'),
        class: 'w-90',
        showErrorText: true,
        isTranslate: false,
        autoCompleteWithId: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        isChipsEnabled: true,
        isEmailChipsEnabled: true,
        value: _selectedEmails,
        errorMessage: this.ts.instant('InvalidEmailFormat'),
        multiChipValues: _selectedEmails?.map(email => { return { value: email } }),
        returnFunction: this.clearFormField.bind(this, 'NotificationEmailIds'),
      }
    ]
  }

  clearFormField(formName) {
    this.emailNotificationsForm.form.get(formName).setValue([]);
  }

}
