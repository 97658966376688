<div class="d-flex flex-column newVersionWrapper retail-sub-categories h-100">
  <div class="select-container-bg" #selectContainer>
    <form [formGroup]="setupFormGroup" class="d-flex w-100">
        <div class="level-selection d-flex w-100">
      <mat-form-field class="sub-category-select mr-3" [floatLabel]="floatLabel">
        <input [attr.automationId]="'Txt_retailSubCategories_subCategory'" #searchInput matInput placeholder={{captions.subCategoryName}} autocomplete="off" [maxlength]="25"  formControlName="subCategory" required>
        <mat-error
      *ngIf="setupFormGroup.controls['subCategory'].errors?.required">
        {{captions.MissingSubCategoryName}}
    </mat-error>
      </mat-form-field>
        <div class="selection-div mr-4">
        <div class="level-label LW12 label-color">{{captions.subCategoryLevels}}</div>

        <mat-radio-group [attr.automationId]="'Rdo_retailSubCategories_level'"  class="radio-class LW14 float-left level-selector mr-2 golf__radio"
          formControlName="levelValue">
          <mat-radio-button class="golf--form-radio-button" *ngFor="let value of levelValues" [value]="value.value" >{{value.value}}</mat-radio-button>
        </mat-radio-group>
      </div>

          <div class="mr-4 toggle-section" *ngIf="enableEdit">
            <label class="d-block LW12 pr-2 level-label label-color">{{captions.Active}}</label>
            <app-retail-toggle-switch [attr.automationId]="'Txt_retailSubCategories_active'" formControlName="activetoggle" (changeToggleEvent)="checkValidState($event)"
              class="toggle-switch"></app-retail-toggle-switch>
          </div>
          <div class="button-div">
          <button [attr.automationId]="'Btn_retailSubCategories_update'" mat-raised-button type="button"
            class="p-2 mr-3 LW14 float-left" [ngClass]="setupFormGroup.valid && setupFormGroup.dirty && (!IsViewOnly)? 'body-bgcolor whitecolor body-bordercolor button_valid': 'button_invalidbgcolor'"
            [disabled]="!setupFormGroup.valid || !setupFormGroup.dirty || IsViewOnly" (click)="updateValue()">{{setText}}</button>
          <a *ngIf="enableEdit" class="p-2 mt-1 LW14 search_button_width"
            (click)="cancelUpdate()">{{commonCaptions.Cancel}}</a>
        </div>

        <div class="search-text-input d-flex  ml-auto custSearchbox" >
          <mat-form-field class="searchArea spa-searchbox" [floatLabel]="floatLabelNever" *ngIf="isSearchEnabled"
            [ngClass]="{'retailSearchWidth': enableToggleButton}">
            <input [attr.automationId]="'Txt_retailSubCategories_search'" matInput autocomplete="off" class="search-input" [(ngModel)]="searchText"
              [ngModelOptions]="{standalone: true}" type="text" placeholder="{{commonCaptions.Search}}"
              [maxlength]="maxInputLength" (ngModelChange)="searchValueChanged($event)">
            <i  aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor" (click)="resetValue()"></i>
          </mat-form-field>
          <i  aria-hidden="true" class="icon-Search float-right search-padding text-align-center" *ngIf="!isSearchEnabled"></i>
        </div>
      </div>


    </form>

  </div>
  <div class="expand-section" #expandContainer >
    <div class="h-100" *ngIf="!isIpad; else expansionBody">
    <ng-scrollbar #scrollRef>
      <div *ngIf="!isIpad; then expansionBody">
      </div>
    </ng-scrollbar>
    </div>
  </div>
</div>

<ng-template #expansionBody>
        <div *ngFor="let level of availableLevels;let i=index" class="expand-section-data"
          [ngClass]="{'show-expanded':level.showExpanded}">
          <div class="available-levels">
            <span attr.automationId  ='Icn_retailSubCategories_levelPlus{{i}}' class="add-icon" *ngIf="!level.showExpanded" (click)="showExpandedData(level, false)">+</span>
            <span attr.automationId = 'Icn_retailSubCategories_levelMinus{{i}}' class="add-icon" *ngIf="level.showExpanded" (click)="showExpandedData(level, false)">-</span>
            <span attr.automationId = 'Icn_retailSubCategories_level{{i}}' class="expand-level-label pl-3 LWB16"  (click)="showExpandedData(level, false)">{{level.name}}</span>
          </div>
          <div class="expanded-data" [ngClass]="{'show-expanded subcategory-expanded':level.showExpanded}">
          <app-sub-category-table [attr.automationId]="'Tbl_retailSubCategories_retailSubCategory'" [searchPipeText]="searchText"  *ngIf="level.showExpanded"
            (toggleEvtEmitter)="onToggleChange($event)" (editEvt)="EditRecords($event)" (deleteEvt) ="delete($event)" (doneEvtEmitter)="Done($event)"
            [isEditModeSet]="isEditModeSet" [completeTable]="completeTableOptions" (dragDropEvt)="dragDrop($event)"
              [options]="level.tableoptions"></app-sub-category-table>
          </div>
        </div>
</ng-template>

