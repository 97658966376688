<div class="general-info">
  <div class="general-info__primary-data">
    <div class="general-info__party-size"
      [ngClass]="{'disable':partyService.tryUndoNoShowReservation, 'general-info__waitlist-width': partyService.reservationType == 1}">
      <div>
        <!-- <span class="icon-Group-748 seat-icon-color general-info__party-icon"> </span> -->
        <!-- <label class="general-info__data-header seat-labels" for="">{{'partySize' | translate}}</label> -->
        <dynamic-form [config]="generalSizeConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
    </div>
    <div *ngIf="partyService.reservationType != 1" class="general-info__date">
      <label class="general-info__data-header seat-labels" for="">{{'popUpDate' | translate}}</label>
      <dynamic-form [config]="generalDateConfig" #form="dynamicForm">
      </dynamic-form>
    </div>
    <div class="general-info__seating-area"
      [ngClass]="{'disable':partyService.tryUndoNoShowReservation, 'general-info__waitlist-width': partyService.reservationType == 1 }">
      <div>
        <!-- <span class="icon-Group-749 seat-icon-color general-info__seat-icon"></span> -->
        <!-- <label class="general-info__data-header seat-labels" for="">{{'seatingArea' | translate}}</label> -->
        <dynamic-form [config]="generalAreaConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
    </div>
    <div class="general-info__seating-type" [ngClass]="{'general-info__waitlist-width': partyService.reservationType == 1 }">
      <div>
        <!-- <span class="icon-Group-749 seat-icon-color general-info__seating-icon"></span> -->
        <!-- <label class="general-info__data-header seat-labels" for="">{{'seatingTypeText' | translate}}</label> -->
        <dynamic-form [config]="generalTypeConfig" #form="dynamicForm">
        </dynamic-form>
      </div>
    </div>
  </div>
  <div class="general-info__time-slot">
    <app-wait-time></app-wait-time>
  </div>
  <div class="general-info__view-legends" *ngIf="isReservationtypeReserve">
    <app-display-legend [legends]="displayLegends"></app-display-legend>
  </div>
</div>