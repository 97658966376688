import { AgProgressBarComponent } from './../../components/ag-progress-bar/ag-progress-bar.component';
import { AgDropdownSearchComponent } from './../../components/ag-dropdown-search/ag-dropdown-search.component';
import { AgAddressComponent } from '../../components/ag-address/ag-address.component';
import { AgCurrencyComponent } from '../../components/ag-currency/ag-currency.component';
import { AgDatePickerComponent } from '../../components/ag-date-picker/ag-date-picker.component';
import { AgDropdownComponent } from '../../components/ag-dropdown/ag-dropdown.component';
import { AgDurationComponent } from '../../components/ag-duration/ag-duration.component';
import { AgImageMultiUploadComponent } from '../../components/ag-image-multi-upload/ag-image-multi-upload.component';
import { AgImageUploadComponent } from '../../components/ag-image-upload/ag-image-upload.component';
import { AgImageUploaderComponent } from '../../components/image-uploader/image-uploader.component';
import { AgIncrementalComponent } from '../../components/ag-incremental/ag-incremental.component';
import { AgInputComponent } from '../../components/ag-input/ag-input.component';
import { AgMailIdComponent } from '../../components/ag-mail-id/ag-mail-id.component';
import { AgNumberComponent } from '../../components/ag-number/ag-number.component';
import { AgPercentageComponent } from '../../components/ag-percentage/ag-percentage.component';
import { AgPhoneNumberComponent, FlagSelectorPipe } from '../../components/ag-phone-number/ag-phone-number.component';
import { AgPostalCodeComponent } from '../../components/ag-postal-code/ag-postal-code.component';
import { AgToggleComponent } from '../../components/ag-toggle/ag-toggle.component';
import { AlertPopupComponent } from '../../components/alert-popup/alert-popup.component';
import { BreakPointAccess } from './service/breakpoint.service';
import { ButtonComponent } from '../../components/button/button.component';
import { CardSwipePopupComponent } from './card-swipe-popup/card-swipe-popup.component';
import { CdkvirtualComponent } from '../../components/cdkvirtual/cdkvirtual.component';
import { CheckboxComponent } from '../../components/checkbox/checkbox.component';
import { CheckinnerboolPipe } from './pipes/checkinnerbool.pipe';
import { checkorderExistPipe } from '../../components/cdkvirtual/checkobjexist.pipe';
import { ChipSearchComponent, TitlecomposePipe } from '../../components/chip-search/chip-search.component';
import { CommonAlertMessagePopupComponent } from './alert-message-popup/alert-message-popup.component';
import { CommonAlertPopupComponent } from './common-alert-popup/common-alert-popup.component';
import { CommonModule } from '@angular/common';
import { commonTableComponent } from './common-table/common-table.component';
import { commonTableHeaderComponent } from './common-table-header/common-table-header.component';
import { commonToggleSwitchModule } from './common-toggle-switch/common-toggle-switch.module';
import { CreateModalComponent } from '../../components/create-modal/create-modal.component';
import { CurrencyFormatterDirective } from '../../directives/currency.directive';
import { ExchangeConversionFormatterDirective } from '../../directives/exchangeconversion.directive';
import { DateRestricterDirective } from '../../directives/daterestricter.directive';
import { CustomCurrencyPipe } from '../../localization/currency.pipe';
import { DetectFormValueChangeDirective } from '../../directives/DetectFormValueChange.directive';
import { DialogFooterComponent } from '../../components/dialog-footer/dialog-footer.component';
import { DialogHeaderComponent } from '../../components/dialog-header/dialog-header.component';
import { DialogOverviewExampleDialog } from './dialog-popup/dialogPopup-componenet';
import { DisableDoubleClickDirective } from '../../directives/disable-double-click.directive';
import { DuplicateCheckDirective } from '../../components/ag-phone-number/ag-duplicate-contact-directive';
import { DurationFormatterDirective } from '../../components/ag-duration/ag-duration-formatter';
import { FilterDataSectionComponent } from './filter-data-section/filter-data-section.component';
import { FloatingNavigationComponent } from '../../components/floating-navigation/floating-navigation.component';
import { FormatText } from './pipes/formatText-pipe.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalSearchComponent } from '../../components/global-search/global-search.component';
import { HttpRequestService } from './service/http-request.service';
import { UserAlerts } from '../config/alerts-config';
import { FilterPipe } from './pipes/filter-pipe.pipe';
import { EnablerowPipe } from './pipes/enablerow.pipe';
import { HttpServiceCall } from './service/http-call.service';
import { IfbooleanPipe } from './pipes/ifboolean.pipe';
import { ImgThumbnailComponent } from '../../components/img-thumbnail/img-thumbnail.component';
import { InputHeaderActionComponent } from '../../components/input-header-action/input-header-action.component';
import { InputtypeDirective } from '../../directives/inputtype.directive';
import { IsEllipsisDirective } from '../../directives/isellipsis.directive';
import { LoadDecimalValuePipe } from './pipes/load-decimal-value.pipe';
import { LocalizeDatePipe } from '../../localization/localize-date.pipe';
import { MaxNumberValidator, MinNumberValidator } from '../../directives/custom-max-validator.directive';
import { MenuComponent } from '../../components/menu/menu.component';
import { MenuSearchComponent } from './components/menu-search/menu-search.component';
import { MessageService } from './service/messageservice.service';
import { MoreComponent } from '../../components/more/more.component';
import { MoreSectionComponent } from './more-section/more-section.component';
import { MoreSectionServiceService } from './more-section/more-section-service.service';
import { MoreSectionWithTabsComponent } from './more-section-with-tabs/more-section-with-tabs.component';
import { MultiSelectButtonComponent } from '../../components/multi-select-button/multi-select-button.component';
import { NgDragDropModule } from 'ng-drag-drop';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Sanitizer, ɵɵsanitizeStyle } from '@angular/core';
import { nowhitespaceDirective } from '../../directives/nowhitespace.directive';
import { numFormat } from '../../directives/num-formatter.directive';
import { NumMaxLengthDirective } from '../../directives/num-maxlength.directive';
import { ObjectindexofPipe } from '../../components/multi-select-button/objectindexof.pipe';
import { PlayerWorthDetailsComponent } from './player-worth-details/player-worth-details.component';
import { RouterModule } from '@angular/router';
import { SearchWithCheckboxComponent } from '../../components/ag-searchWithCheckbox/search-with-checkbox.component';
import { SessionManagerService } from '../services/session-manager.service';
import { SetcolumnvaluePipe } from './pipes/setcolumnvalue.pipe';
import { ShowmoreDirective } from '../../directives/show-more.directive';
import { SimpleSearchComponent } from '../../components/simple-search/simple-search.component';
import { SingleInputHeaderActionComponent } from '../../components/single-input-header-action/single-input-header-action.component';
import { SlideActionComponent } from './slide-action/slide-action.component';
import { SortOrderPipe } from '../../pipes/sort-order.pipe';
import { sortPipe } from './pipes/sort-pipe.pipe';
import { SPAConfig } from '../config/SPA-config';
import { SpecialCharacterDirective } from '../../directives/noSpecialCharacter.directive';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { TableSearchHeaderComponent } from '../../components/table-search-header/table-search-header.component';
import { TextMaskDirective } from '../../directives/mask.directive';

import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ViewmoreDirective } from '../../directives/viewmore.directive';
import { ViewSettingClientBusiness } from './common-functionalities/business/view-settings.business';
import { WarningBarComponent } from '../../components/warning-bar/warning-bar.component';
import { MaterialModule } from '../../material-module';

import { AsideFilterComponent } from '../../components/aside-filter/aside-filter.component';
import { FilterLengthPipe } from '../../components/aside-filter/aside-filter.pipe';
import { PopoverModule } from "ngx-smart-popover";
import { AgTimePickerComponent } from '../../components/ag-time-picker/ag-time-picker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { EditMenuPopupComponent } from './components/menu-search/edit-menu-popup/edit-menu-popup.component';
import { AgysMatInput } from '../../controls/ag-control-input-mat/ag-control-input-mat';
import { AgysMatDate } from '../../controls/ag-control-date-mat/ag-control-date-mat';
import { AgysMatDropDown } from '../../controls/ag-control-dropdown-mat/ag-control-dropdown-mat';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { CommonSharedPopupComponent } from './common-popup/common-popup.component';
import { AuthenticationService } from '../services/authentication.service';
import { QuickLoginComponent } from './quick-login/quick-login.component';
import { ServiceRegistry } from './service/base.service';
import { QuickLoginUtilities } from './utilities/quick-login-utilities';
import { CommonItineraryComponent } from './components/common-itinerary/common-itinerary.component';

import { LiteralsEditComponent } from '../../components/menu/literals-edit/literals-edit.component';
import { IsAllSelectedPipe } from '../../components/cdkvirtual/isAllSelected.pipe';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgMenuEditComponent } from '../../components/ag-menu-edit/ag-menu-edit.component';
import { FileDndDirective } from '../../components/ag-image-multi-upload/ag-image-drop.directive';
import { AgSnackBarComponent } from '../../components/ag-snack-bar/ag-snack-bar.component';
import { IncrementalDirective } from '../../components/ag-incremental/ag-incremental.directive';
import { ChangePropertyComponent } from '../../components/change-property/change-property.component';
import { DeleteDependencyAlertComponent } from '../../components/delete-dependency-alert/delete-dependency-alert.component';
import { AgCombineGuestRecordsComponent } from '../../components/combine-guest-records/combine-guest-records.component';
import { AgSourceSearchCriteriaComponent } from '../../components/combine-guest-records/combine-member/source-search-criteria/source-search-criteria.component';
import { AgValueToCombineComponent } from '../../components/combine-guest-records/combine-member/value-to-combine/value-to-combine.component';
import { AgCombineMemberComponent } from '../../components/combine-guest-records/combine-member/combine-member.component';
import { AgPreviewReportComponent } from 'src/app/common/components/preview-report/preview-report.component';
import { AgPreviewReportContentComponent } from 'src/app/common/components/preview-report/preview-report-content/preview-report-content.component';
import { PasscodeAlertComponent } from 'src/app/common/components/passcode-alert/passcode-alert.component';
import { PrinterDefaultConfigComponent } from './components/printer-default-config/printer-default-config.component';
import { CkeditorPopupComponent } from './ckeditor-popup/ckeditor-popup.component';
import { NgDompurifySanitizer, NgDompurifyModule, SANITIZE_STYLE } from '@tinkoff/ng-dompurify';
import { SafeHtmlPipe } from '../../pipes/safehtml.pipe';
import { HtmlPopupComponent } from './html-popup/html-popup.component';
import { ChildMasterComponentLinkingComponent } from '../../components/master-component-linking/master-component-linking.component';
import { ComponentLinkingBaseComponent } from '../../components/component-linking-base/component-linking-base.component';
import { CreateMasterComponentLinkingComponent } from '../../components/master-component-linking/create-master-component-linking/create-master-component-linking.component';
import { getDropdownTextPipe } from '../../components/component-linking-base/getDropdownText.pipe';
import { CommentsComponentComponent } from './comments-component/comments-component.component';
import { SendEmailComponent } from 'src/app/common/components/send-email/send-email.component';
import { ComponentsLinkingComponent } from '../../components/component-linking/component-linking.component';
import { ComponentLinkingConfigComponent } from '../../components/component-linking-config/component-linking-config.component';
import { RadioBtnSearchComponent } from '../../components/radio-btn-search/radio-btn-search.component';
import { SnackbarErrorComponent } from '../../components/snackbar-error/snackbar-error.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { loadingInterceptor } from './service/loadingintersepctor.service';
import { SumColumnPipe } from './pipes/sum-column.pipe';
import { AgButtonTogglerComponent } from '../../components/ag-button-toggler/ag-button-toggler.component';
import { RequiredInput } from '../../directives/requiredinput.directive';
import { RequiredAttribute } from '../../directives/requiredattribute.directive';
import { DmEformsSpaComponent } from '../../components/dm-eforms-spa/dm-eforms-spa.component';
import { ADB2CAuthConfiguration } from '../auth.config';
import { DmEformsComponent } from '../../data-magine/dm-eforms/dm-eforms.component';
import { SelectEFormsComponent } from './select-e-forms/select-e-forms.component';
import { SelectOutletPopupComponent } from './select-outlet-popup/select-outlet-popup.component';
import { EventSchedulerComponent } from './event-scheduler/event-scheduler.component';
import { CreateEventScheduleComponent } from './event-scheduler/create-event-schedule/create-event-schedule.component';
import { EventDetailsComponent } from './event-scheduler/event-details/event-details.component';
import { NotificationScheduleComponent } from './event-scheduler/notification-schedule/notification-schedule.component';
import { SftpSetupComponent } from './sftp-setup/sftp-setup.component';
import { FiscalPeriodComponent } from '../../components/fiscal-period/fiscal-period.component';
import { FiscalPeriodSliderActionComponent } from '../../components/fiscal-period/fiscalPeriod-slider-action/fiscal-period-slider-action.component';
import { DiaryComponent, DisplayDateTime } from '../../diary/diary.component';
import { CalendarDateSelectionComponent } from '../../diary/calendar-date-selection/calendar-date-selection.component';
import { StaffReaderBoardComponent } from '../../staff-reader-board/staff-reader-board.component'
import { CreateStaffReaderBoardComponent } from '../../staff-reader-board/create-staff-reader-board/create-staff-reader-board.component'
import { ViewNoteComponent } from '../../staff-reader-board/view-note/view-note.component'
import { AlertNewPopupComponent } from '../../components/alert-new-popup/alert-new-popup.component'
import { ListOfNotesComponent } from '../../components/list-of-notes/list-of-notes.component';
import { ViewImageComponent } from 'src/app/common/shared/shared/view-image/view-image.component';
import { CommonLocalizeDateTimePipe } from '../../localization/common-localize-datetime.pipe';
import { ConsentManagementComponent } from 'src/app/common/consent-management/consent-management.component'
import { ExportSendComponent } from 'src/app/common/export-send/export-send.component'
import { DataRetentionComponent } from 'src/app/common/data-retention/data-retention.component'
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, MY_DATE_FORMATS } from '../localization/custom.dateAdapter';
import { PercentInputtypeDirective } from '../../directives/percentage.directive';
import { EventSchedulerHistoryComponent } from './event-scheduler-history/event-scheduler-history.component';
import { JasperReportComponent } from './jasper-report/jasper-report.component';
import { CartTimeoutAlterComponent } from "../../components/menu/vcart/cart-timeout-alter/cart-timeout-alter.component";
import { minuteconverterPipe } from "../../components/menu/vcart/minuteconverter-pipe";
import { VcartComponent } from "../../components/menu/vcart/vcart.component";
import { CartTransferComponent } from "../../components/menu/cart-transfer/cart-transfer.component";
import { SetCartTimerComponent } from "../../components/menu/set-cart-timer/set-cart-timer.component";
import { CartAuthorizeComponent } from "../../components/menu/vcart/cart-authorize/cart-authorize.component";
import { totalestimatePipe } from "../../components/menu/vcart/vcart.component";
import { CartCommentsComponent } from "../../components/menu/cart-comments/cart-comments.component";
import { CartGuestSearchComponent } from "../../components/menu/cart-guest-search/cart-guest-search.component";
import { CardTypeComponent } from "../../components/menu/card-type/card-type.component";
import { JasperReportPopupComponent } from './jasper-report popup/jasper-report-popup.component';
import { AgServerPaginatorComponent } from '../../components/ag-server-paginator/ag-server-paginator.component';
import { MultiMailSearchComponent } from '../../components/multi-mail-search/multi-mail-search.component';
import { CommonAuthorizeIntegrationSetupComponent } from 'src/app/common/common-authorize-integration-setup/common-authorize-integration-setup.component';
import { ExportImportComponent } from '../../export-import/export-import.component';
import { ExportComponent } from '../../export-import/export/export.component';
import { ExportDataComponent } from '../../export-import/export/export-data/export-data.component';
import { FailurePopupComponent } from '../../export-import/import/failure-popup/failure-popup.component';
import { ImportComponent } from '../../export-import/import/import.component';
import { DataMigrationExpComponent } from './components/data-migration-exp/data-migration-exp.component';
import { DedupeGuestRecordsComponent } from '../../components/dedupe-guest-records/dedupe-guest-records.component';
import { CombineGuestComponent } from '../../components/dedupe-guest-records/combine-guest/combine-guest.component';
import { DropdownSearchComponent } from './components/dropdown-search/dropdown-search.component';
import { LanguageTemplateEditorComponent } from './components/language-template-editor/language-template-editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CommontableCountPipe } from './common-table/total-count-commontable.pipe';
import { SearchEformTemplateComponent } from '../../data-magine/search-eform-template/search-eform-template.component';
import { EformSignCaptureConfigComponent } from '../../data-magine/eform-sign-capture-config/eform-sign-capture-config.component';
import { DownloadTemplateComponent } from '../../export-import/import/download-template/download-template.component';
import { CommonSamsotechIntegrationComponent } from '../../common-samsotech-integration/common-samsotech-integration.component';
import { SamsotechScanReadchipComponent } from '../../samsotech-scan-readchip/samsotech-scan-readchip.component';
import { AgExchangeConversionComponent } from '../../components/ag-exchange-conversion/ag-exchange-conversion.component';
import { CommonPrototype } from '../../localization/common-prototype';
import { CgpsLoggingProfileSyncComponent } from '../../components/cgps-logging-profile-sync/cgps-logging-profile-sync.component';
import { CgpsSyncComponent } from '../../components/cgps-logging-profile-sync/cgps-sync/cgps-sync.component';
import { CustomFeeComponent } from './custom-fee/custom-fee.component';
import { CreateCustomFeePopupComponent, IsExistPipe } from './custom-fee/create-custom-fee-popup/create-custom-fee-popup.component';
import { SftpLandingComponent } from '../../sftp/sftp-landing/sftp-landing.component';
import { SftpComponent } from '../../sftp/sftp.component';
import { AlertPopupWithTimerComponent, UITimePipe } from './alert-popup-with-timer/alert-popup-with-timer.component';
import { SamsotechPreviewComponent } from '../../samsotech-preview/samsotech-preview.component';
import { ToolTipEnablerDirective } from '../../directives/tooltipEnabler.directive';
import { AgModalTimePickerComponent } from '../../components/ag-modal-time-picker/ag-modal-time-picker.component';
import { NoteComponent } from '../../components/note/note.component';
import { UTempDataUtilities } from './utilities/utempdata-utilities';
import { ViewAllItinerariesPopupComponent } from '../../components/menu/vcart/view-all-itineraries-popup/view-all-itineraries-popup.component';
import { CaptureCardModule } from '../../components/capture-card/capture-card.module';
import { VipTypeComponent } from 'src/app/common/components/vip-type/vip-type.component';
import { CreateVipTypeComponent } from 'src/app/common/components/vip-type/create-vip-type/create-vip-type.component';
import { PlayerDiscountTypeComponent } from '../../components/player-discount-type/player-discount-type.component';
import { CancellationNoShowPolicyComponent } from './cancellation-no-show-policy/cancellation-no-show-policy.component';
import { CreateCancellationNoShowPolicyComponent } from './cancellation-no-show-policy/create-cancellation-no-show-policy/create-cancellation-no-show-policy.component';
import { CancellationNoShowPolicyMappingComponent } from './cancellation-no-show-policy-mapping/cancellation-no-show-policy-mapping.component';
import { CreateCancellationNoShowMappingComponent } from './cancellation-no-show-policy-mapping/create-cancellation-no-show-mapping/create-cancellation-no-show-mapping.component';
import { CommonFilterComponent } from '../../components/common-filter/common-filter.component';
import { CharacterCountDirective } from '../../directives/character-count.directive';

import { RightclickNewtabDirective } from '../../directives/rightclicknewtab.directive';
import { NewTabDirective } from '../../directives/newtab.directive';
import { DashboardJasperConfigComponent } from '../../components/dashboard-jasper-config/dashboard-jasper-config.component';
import { DashboardJasperCommonPopupComponent } from '../../components/dashboard-jasper-common-popup/dashboard-jasper-common-popup.component';
import { AgShortcutKeyGuideComponent } from '../../components/ag-shortcut-key-guide/ag-shortcut-key-guide.component';
import { DashboardConfigurationComponent } from '../../components/dashboard-configuration/dashboard-configuration.component';
import { DataMigrationConfigComponent } from './components/data-migration-configuration/data-migration-configuration.component';
import { AgDateRangePickerComponent } from '../../components/ag-date-range-picker/ag-date-range-picker.component';
import { colorPickerModifier } from '../../pipes/colorPickerModifier.pipe';
import { DashboardConfigSliderComponent } from '../../components/dashboard-config-slider/dashboard-config-slider.component';
import { DuplicatePipe } from './cancellation-no-show-policy/create-cancellation-no-show-policy/duplicate-pipe.pipe';
import { AgCountryComponent } from '../../components/ag-country/ag-country.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 15,
    keyboard: {
        enabled: true,
    },
    mousewheel: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    slideToClickedSlide: true,
    loop: false,
    observer: true
};
@NgModule({
    declarations: [
        AgSnackBarComponent,
        AgTimePickerComponent,
        AgAddressComponent,
        AgCurrencyComponent,
        AgDatePickerComponent,
        AgCountryComponent,
        AgDropdownComponent,
        AgDurationComponent,
        AgImageMultiUploadComponent,
        AgImageUploadComponent,
        AgImageUploaderComponent,
        AgIncrementalComponent,
        AgInputComponent,
        AgMenuEditComponent,
        AgMailIdComponent,
        AgNumberComponent,
        AgNumberComponent,
        AgPercentageComponent,
        AgPhoneNumberComponent,
        AgPostalCodeComponent,
        AgToggleComponent,
        AlertPopupComponent,
        AsideFilterComponent,
        ButtonComponent,
        CardSwipePopupComponent,
        CdkvirtualComponent,
        CommonFilterComponent,
        CheckboxComponent,
        ChipSearchComponent,
        TitlecomposePipe,
        CommonAlertMessagePopupComponent,
        ChangePropertyComponent,
        DeleteDependencyAlertComponent,
        FormatText,
        AgProgressBarComponent,
        CommonAlertPopupComponent,
        commonTableComponent,
        commonTableHeaderComponent,
        CreateModalComponent,
        DialogFooterComponent,
        DialogHeaderComponent,
        DialogOverviewExampleDialog,
        FiscalPeriodComponent,
        FilterDataSectionComponent,
        EventSchedulerComponent,
        CreateEventScheduleComponent,
        EventDetailsComponent,
        NotificationScheduleComponent,
        FloatingNavigationComponent,
        GlobalSearchComponent,
        ImgThumbnailComponent,
        MenuComponent,
        MenuSearchComponent,
        MoreComponent,
        MoreSectionComponent,
        MoreSectionWithTabsComponent,
        MultiSelectButtonComponent,
        NoteComponent,
        numFormat,
        PlayerWorthDetailsComponent,
        SearchWithCheckboxComponent,
        SimpleSearchComponent,
        RadioBtnSearchComponent,
        SingleInputHeaderActionComponent,
        SlideActionComponent,
        TableSearchHeaderComponent,
        ToggleSwitchComponent,
        WarningBarComponent,
        CurrencyFormatterDirective,
        ExchangeConversionFormatterDirective,
        DateRestricterDirective,
        DetectFormValueChangeDirective,
        DisableDoubleClickDirective,
        DuplicateCheckDirective,
        DurationFormatterDirective,
        InputtypeDirective,
        PercentInputtypeDirective,
        CharacterCountDirective,
        NewTabDirective,
        RightclickNewtabDirective,
        RequiredInput,
        VcartComponent,
        CartTransferComponent,
        CartCommentsComponent,
        SetCartTimerComponent,
        CartTimeoutAlterComponent,
        CartAuthorizeComponent,
        CartGuestSearchComponent,
        CardTypeComponent,
        AgExchangeConversionComponent,
        AgModalTimePickerComponent,
        AgShortcutKeyGuideComponent,
        // Pipes
        totalestimatePipe,
        minuteconverterPipe,
        RequiredAttribute,
        IsEllipsisDirective,
        ToolTipEnablerDirective,
        MaxNumberValidator,
        MinNumberValidator,
        nowhitespaceDirective,
        NumMaxLengthDirective,
        ShowmoreDirective,
        SpecialCharacterDirective,
        ViewmoreDirective,
        FileDndDirective,
        EditMenuPopupComponent,
        LiteralsEditComponent,
        IncrementalDirective,
        SnackbarErrorComponent,
        DmEformsSpaComponent,
        DmEformsComponent,
        CommontableCountPipe,
        CgpsLoggingProfileSyncComponent,
        CgpsSyncComponent,
        // Pipes
        SafeHtmlPipe,
        colorPickerModifier,
        //adding after AOT compilation
        CheckinnerboolPipe,
        checkorderExistPipe,
        CustomCurrencyPipe,
        EnablerowPipe,
        FilterLengthPipe,
        FilterPipe,
        IfbooleanPipe,
        LoadDecimalValuePipe,
        ObjectindexofPipe,
        SetcolumnvaluePipe,
        SortOrderPipe,
        sortPipe,
        InputHeaderActionComponent,
        IsEllipsisDirective,
        TextMaskDirective,
        numFormat,
        CommonItineraryComponent,
        FilterLengthPipe,
        IsAllSelectedPipe,
        AgysMatInput,
        AgysMatDate,
        AgysMatDropDown,
        LocalizeDatePipe,
        DisplayDateTime,
        CommonLocalizeDateTimePipe,
        commonTableHeaderComponent,
        MultiSelectDropdownComponent,
        CommonSharedPopupComponent,
        QuickLoginComponent,
        AgCombineGuestRecordsComponent,
        DedupeGuestRecordsComponent,
        CombineGuestComponent,
        AgSourceSearchCriteriaComponent,
        AgValueToCombineComponent,
        AgCombineMemberComponent,
        AgPreviewReportComponent,
        AgDropdownSearchComponent,
        AgPreviewReportContentComponent,
        PasscodeAlertComponent,
        PrinterDefaultConfigComponent,
        ComponentLinkingBaseComponent,
        CreateMasterComponentLinkingComponent,
        ChildMasterComponentLinkingComponent,
        ComponentsLinkingComponent,
        ComponentLinkingConfigComponent,
        getDropdownTextPipe,
        CkeditorPopupComponent,
        HtmlPopupComponent,
        CommentsComponentComponent,
        SendEmailComponent,
        FlagSelectorPipe,
        SumColumnPipe,
        AgButtonTogglerComponent,
        SelectEFormsComponent,
        SelectOutletPopupComponent,
        SftpSetupComponent,
        FiscalPeriodSliderActionComponent,
        DiaryComponent,
        CalendarDateSelectionComponent,
        StaffReaderBoardComponent,
        CreateStaffReaderBoardComponent,
        ViewNoteComponent,
        AlertNewPopupComponent,
        ListOfNotesComponent,
        ViewImageComponent,
        ConsentManagementComponent,
        ExportSendComponent,
        DataRetentionComponent,
        EventSchedulerHistoryComponent,
        JasperReportComponent,
        AgServerPaginatorComponent,
        JasperReportPopupComponent,
        MultiMailSearchComponent,
        CommonAuthorizeIntegrationSetupComponent,
        ExportImportComponent,
        ExportComponent,
        ImportComponent,
        FailurePopupComponent,
        ExportDataComponent,
        DataMigrationExpComponent,
        DropdownSearchComponent,
        LanguageTemplateEditorComponent,
        SearchEformTemplateComponent,
        EformSignCaptureConfigComponent,
        DownloadTemplateComponent,
        CommonSamsotechIntegrationComponent,
        SamsotechScanReadchipComponent,
        CustomFeeComponent,
        CreateCustomFeePopupComponent,
        IsExistPipe,
        SftpLandingComponent,
        SftpComponent,
        AlertPopupWithTimerComponent,
        UITimePipe,
        SamsotechPreviewComponent,
        ViewAllItinerariesPopupComponent,
        PlayerDiscountTypeComponent,
        VipTypeComponent,
        CreateVipTypeComponent,
        CancellationNoShowPolicyComponent,
        CreateCancellationNoShowPolicyComponent,
        DuplicatePipe,
        CancellationNoShowPolicyMappingComponent,
        CreateCancellationNoShowMappingComponent,
        DashboardJasperConfigComponent,
        DashboardJasperCommonPopupComponent,
        DashboardConfigurationComponent,
        DataMigrationConfigComponent,
        AgDateRangePickerComponent,
        DashboardConfigSliderComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        NgScrollbarModule,
        SwiperModule,
        commonToggleSwitchModule,
        UiSwitchModule.forRoot({}),
        NgDragDropModule.forRoot(),
        PopoverModule,
        RouterModule,
        NgxMaterialTimepickerModule,
        GooglePlaceModule,
        NgDompurifyModule,
        CKEditorModule,
        CaptureCardModule
    ],
    providers: [
        { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
        {
            provide: Sanitizer,
            useClass: NgDompurifySanitizer,
        },
        {
            provide: SANITIZE_STYLE,
            useValue: ɵɵsanitizeStyle,
        },
        MoreSectionServiceService,
        SPAConfig,
        CommonPrototype,
        HttpServiceCall,
        HttpRequestService,
        SessionManagerService,
        FormatText,
        FilterPipe,
        EnablerowPipe,
        sortPipe,
        IfbooleanPipe,
        CheckinnerboolPipe,
        LoadDecimalValuePipe,
        SetcolumnvaluePipe,
        BreakPointAccess,
        MessageService,
        UserAlerts,
        CustomCurrencyPipe,
        ViewSettingClientBusiness,
        AuthenticationService,
        ServiceRegistry,
        QuickLoginUtilities,
        UTempDataUtilities,
        ADB2CAuthConfiguration,
        SafeHtmlPipe,
        colorPickerModifier,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: loadingInterceptor,
            multi: true,
        },
        {
            provide: DateAdapter, useClass: CustomDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
        },
    ],
    exports: [
        AgSnackBarComponent,
        AgTimePickerComponent,
        WarningBarComponent,
        AgInputComponent,
        AgysMatInput,
        AgysMatDate,
        AgysMatDropDown,
        AgMenuEditComponent,
        AgAddressComponent,
        AgCurrencyComponent,
        AgDatePickerComponent,
        AgCountryComponent,
        AgDropdownComponent,
        AgDurationComponent,
        AgImageMultiUploadComponent,
        AgImageUploadComponent,
        AgImageUploaderComponent,
        AgIncrementalComponent,
        AgMailIdComponent,
        AgNumberComponent,
        AgNumberComponent,
        AgPercentageComponent,
        AgPhoneNumberComponent,
        AgPostalCodeComponent,
        AgToggleComponent,
        AlertPopupComponent,
        AsideFilterComponent,
        ButtonComponent,
        CardSwipePopupComponent,
        CdkvirtualComponent,
        CommonFilterComponent,
        CheckboxComponent,
        ChipSearchComponent,
        CommonItineraryComponent,
        CommonAlertMessagePopupComponent,
        CommonAlertPopupComponent,
        commonTableComponent,
        commonTableHeaderComponent,
        CreateModalComponent,
        DeleteDependencyAlertComponent,
        DialogFooterComponent,
        DialogHeaderComponent,
        FiscalPeriodComponent,
        DialogOverviewExampleDialog,
        HtmlPopupComponent,
        FilterDataSectionComponent,
        GlobalSearchComponent,
        ImgThumbnailComponent,
        MenuComponent,
        MenuSearchComponent,
        MoreComponent,
        FormatText,
        AgDropdownSearchComponent,
        MoreSectionComponent,
        MoreSectionWithTabsComponent,
        MultiSelectButtonComponent,
        NoteComponent,
        numFormat,
        PlayerWorthDetailsComponent,
        ToggleSwitchComponent,
        MultiSelectDropdownComponent,
        QuickLoginComponent,
        SimpleSearchComponent,
        RadioBtnSearchComponent,
        TableSearchHeaderComponent,
        SingleInputHeaderActionComponent,
        SlideActionComponent,
        SearchWithCheckboxComponent,
        FloatingNavigationComponent,
        PrinterDefaultConfigComponent,
        NgxMaterialTimepickerModule,
        DisableDoubleClickDirective,
        DetectFormValueChangeDirective,
        DuplicateCheckDirective,
        DurationFormatterDirective,
        InputtypeDirective,
        PercentInputtypeDirective,
        CharacterCountDirective,
        RequiredInput,
        RequiredAttribute,
        IsEllipsisDirective,
        ToolTipEnablerDirective,
        MaxNumberValidator,
        MinNumberValidator,
        nowhitespaceDirective,
        NewTabDirective,
        RightclickNewtabDirective,
        ComponentLinkingBaseComponent,
        CreateMasterComponentLinkingComponent,
        ChildMasterComponentLinkingComponent,
        ComponentsLinkingComponent,
        ComponentLinkingConfigComponent,
        getDropdownTextPipe,
        CurrencyFormatterDirective,
        ExchangeConversionFormatterDirective,
        DateRestricterDirective,
        NumMaxLengthDirective,
        ShowmoreDirective,
        SpecialCharacterDirective,
        TextMaskDirective,
        ViewmoreDirective,
        FileDndDirective,
        SnackbarErrorComponent,
        EventSchedulerComponent,
        CreateEventScheduleComponent,
        EventDetailsComponent,
        NotificationScheduleComponent,
        SftpSetupComponent,
        DropdownSearchComponent,
        AgExchangeConversionComponent,
        CgpsLoggingProfileSyncComponent,
        CgpsSyncComponent,
        AgModalTimePickerComponent,
        AgShortcutKeyGuideComponent,
        // Pipes
        CustomCurrencyPipe,
        FilterPipe,
        EnablerowPipe,
        sortPipe,
        IfbooleanPipe,
        CheckinnerboolPipe,
        LoadDecimalValuePipe,
        ObjectindexofPipe,
        SetcolumnvaluePipe,
        FilterLengthPipe,
        SortOrderPipe,
        LocalizeDatePipe,
        DisplayDateTime,
        CommonLocalizeDateTimePipe,
        SafeHtmlPipe,
        colorPickerModifier,
        CommontableCountPipe,
        // AOT compilation
        checkorderExistPipe,
        IsAllSelectedPipe,
        InputHeaderActionComponent,
        UiSwitchModule,
        PopoverModule,
        MaterialModule,
        GooglePlaceModule,
        AgProgressBarComponent,
        AgPreviewReportComponent,
        AgPreviewReportContentComponent,
        PasscodeAlertComponent,
        CkeditorPopupComponent,
        SendEmailComponent,
        SumColumnPipe,
        AgButtonTogglerComponent,
        DmEformsSpaComponent,
        SelectEFormsComponent,
        SelectOutletPopupComponent,
        FiscalPeriodSliderActionComponent,
        DiaryComponent,
        CalendarDateSelectionComponent,
        StaffReaderBoardComponent,
        CreateStaffReaderBoardComponent,
        ViewNoteComponent,
        AlertNewPopupComponent,
        ListOfNotesComponent,
        ViewImageComponent,
        ConsentManagementComponent,
        ExportSendComponent,
        DataRetentionComponent,
        JasperReportComponent,
        VcartComponent,
        JasperReportPopupComponent,
        AgServerPaginatorComponent,
        MultiMailSearchComponent,
        CommonAuthorizeIntegrationSetupComponent,
        ExportImportComponent,
        ExportComponent,
        ImportComponent,
        FailurePopupComponent,
        ExportDataComponent,
        DataMigrationExpComponent,
        LanguageTemplateEditorComponent,
        SearchEformTemplateComponent,
        EformSignCaptureConfigComponent,
        DownloadTemplateComponent,
        CommonSamsotechIntegrationComponent,
        SamsotechScanReadchipComponent,
        SftpLandingComponent,
        SamsotechPreviewComponent,
        VipTypeComponent,
        CreateVipTypeComponent,
        CancellationNoShowPolicyComponent,
        DuplicatePipe,
        CreateCancellationNoShowPolicyComponent,
        CancellationNoShowPolicyMappingComponent,
        DashboardJasperConfigComponent,
        DashboardJasperCommonPopupComponent,
        DashboardConfigurationComponent,
        DataMigrationConfigComponent,
        AgDateRangePickerComponent,
        DashboardConfigSliderComponent
    ]
})
export class CommonSharedModule { }
