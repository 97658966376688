import { Directive, HostListener, ElementRef, OnInit, Input, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
@Directive({ selector: "[CurrencyFormatter]" })
export class CurrencyFormatterDirective implements OnInit, AfterViewInit {
  private el: HTMLInputElement;
  private negativeSign = '-';
  private allowNegative = false;
  private disabled = false;
  private decimalLocus = 3;
  DEFAULT_UNIT_LENGTH = 13;
  private regex: RegExp = new RegExp(/^\-?\d*\.?\d{0,2}$/g);
  @Input()
  skipInitialFormatting = false;
  @Input()
  cDisabled?: any = 'false';
  @Input()
  cAllowNegative?: any = 'false';
  @Input()
  cDecimalLocus = 3;
  @Input()
  preDecimalmaxLength?; // preDecimalLength in retail and common
  @Input()
  maxVal?: string | number; // in retail and common
  @Input()
  minVal?: string | number; // in retail and common
  @Input() allowPaste = false; // in retail and common
  
  @Input('fieldUpdate')
  set fieldupdateFunc(val:number)
  {
    if(val)
    this.formatCurrencyValue(this.el.value);
  }
  
  decimalLength: number;
  constructor(
    private elementRef: ElementRef, public _localization: Localization, private control: NgControl) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.setDirectiveConfigurations(this.cAllowNegative, this.cDisabled);
    if (this.disabled) {
      return;
    }
    this.el.value = this.el.value ? this.el.value : '';
    let delocalizedNumber: number;
    this._localization.fractionLength = parseInt(sessionStorage.getItem('noOfDecimalDigits'))
    const exp = '^-?\\d*\\' + this._localization.decimalSeparator + `?\\d{0,${this._localization.fractionLength}}$`;
    this.regex = new RegExp(exp, 'g');

    if (this.el.value.includes(this._localization.decimalSeparator)) {
      delocalizedNumber = this._localization.currencyToSQLFormat(this.el.value); // to fix _localization issue in 27126
    } else {
      delocalizedNumber = Number(this._localization.removeThousandSeparator(this.el.value));
    }

    this.el.value = isNaN(Number(delocalizedNumber)) || (this.skipInitialFormatting && !this.el.value) ? '' : this._localization.localizeCurrency(delocalizedNumber, false);
    if (this.el.value && !this.skipInitialFormatting) {
      if (this.el.value === this._localization.localizeCurrency(0, false)) {
        return;
      }
      this.control.control.setValue(this.el.value);
    }
    
  }

  ngAfterViewInit() {
    if(!this.skipInitialFormatting) {
    this.formatCurrencyValue(this.el.value);
    }
  }

  @HostListener('keyup', ['$event.target.value'])
  onKeyUp(value) {
    if (this.preDecimalmaxLength) {
      const splitedValue = value.split(this._localization.decimalSeparator);
      const afterDecimal = splitedValue[1] ? splitedValue[1] : '';
      if (splitedValue[0].length <= this.preDecimalmaxLength) {
        const combinedValue =
          splitedValue.length > 1 ? `${splitedValue[0]}${this._localization.decimalSeparator}${afterDecimal}` : splitedValue[0];
        this.control.control.setValue(combinedValue);
      } else {
        const combinedValue =
          splitedValue.length > 1 ?
            `${splitedValue[0].slice(0, this.preDecimalmaxLength)}${this._localization.decimalSeparator}${afterDecimal}` :
            splitedValue[0].slice(0, this.preDecimalmaxLength);
        this.control.control.setValue(combinedValue);
      }
    }

  }
  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.setDirectiveConfigurations(this.cAllowNegative, this.cDisabled);
    if (this.el.readOnly || this.disabled) {
      return;
    }
    this.el.maxLength = this._localization.getMaxCurrencyLength();
    if(this.el.attributes['id']?.nodeValue == 'accounting_unit'){
      this.el.maxLength = this.DEFAULT_UNIT_LENGTH;
    }
    this.el.value = this._localization.removeThousandSeparator(value);
    this.control.control.setValue(this.el.value, {emitEvent : false});
    this.el.select();
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.formatCurrencyValue(value);
  }
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if (this.el.readOnly || this.disabled) {
      return;
    }
    this.setDirectiveConfigurations(this.cAllowNegative, this.cDisabled);
    const e = event as KeyboardEvent;
    if (this.IsMaxLengthExceeded(e)) { // in retail and common
      e.preventDefault();
      return;
    }
    this.InputTypeNumberValidation(e);
  }

  private formatCurrencyValue(value) {
    if (value === this._localization.localizeCurrency(0, false)) {
      return;
    }
    value = (value || value == 0) ? value.toString() : '';
    this.setDirectiveConfigurations(this.cAllowNegative, this.cDisabled);
    if (this.el.readOnly || this.disabled) {
      return;
    }

    const splittedValue = value.split(this._localization.decimalSeparator);
    if (splittedValue[0] || splittedValue[0] == 0) {
      splittedValue[0] = splittedValue[0].replace(this._localization.thousandSeparator, '');
    }

    value = splittedValue.join(this._localization.decimalSeparator);
    // Return Negative Values
    if (value < 0 && !this.allowNegative) {
      value = '';
    }
    value = this._localization.currencyToSQLFormat(value);
    const maxValue = this.maxVal ? this._localization.currencyToSQLFormat(this.maxVal.toString()) : '';
    const minValue = this.minVal ? this._localization.currencyToSQLFormat(this.minVal.toString()) : '';
    if(this.el.attributes['id']?.nodeValue != 'accounting_unit'){
    this.el.value = this._localization.localizeCurrency(value, false);
    }
    this.el.maxLength = this.el.value.length;
    this.control.control.setValue(this.el.value);
    if (maxValue && value > maxValue) { // not in retail and common
      this.control.control.setErrors({ max: true });
    }
    if (minValue && value < minValue) { // not in retail and common
      this.control.control.setErrors({ min: true });
    }

  }


  InputTypeNumberValidation(e: KeyboardEvent): void {
    const input = (e.target as HTMLInputElement);
    let valueEntered: string = input.value;
    valueEntered = valueEntered ? valueEntered : '';
    if (
      // Allow function keys
      (e.keyCode >= 112 && e.keyCode <= 123) ||
      // allow minus - onlynumber,only negative
      (this.allowNegative && (input.selectionStart == 0 && e.key == this.negativeSign && (valueEntered.indexOf(this.negativeSign) == -1 || input.selectionEnd == valueEntered.length))) ||
      // Decimal Seperator - Region
      (valueEntered.indexOf(this._localization.decimalSeparator) == -1 && this._localization.decimalSeparator == e.key) ||
      // Allow default options like delete..
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105) ||
      // non negative , includes zero
      (e.key == this.negativeSign)
    ) {
      e.preventDefault();
    }
    if (valueEntered.indexOf(this._localization.decimalSeparator) == -1 && (this.el.value.length >= (this.el.maxLength - this.decimalLocus))) {
      e.preventDefault();
    }
    const position = this.el.selectionStart;
    const next: string = [valueEntered.slice(0, position), e.key == this._localization.decimalSeparator ? this._localization.decimalSeparator : e.key, valueEntered.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      e.preventDefault();
    }
  }
  setDirectiveConfigurations(cAllowNegative, cDisabled) {
    this.disabled = (cDisabled || cDisabled.toLowerCase()) == 'true' ? true : false;
    this.allowNegative = (cAllowNegative && cAllowNegative.toString().toLowerCase()) == 'true' ? true : false;
  }

  @HostListener("paste", ['$event']) blockPaste(e: ClipboardEvent) {
    if (!this.allowPaste) {
      e.preventDefault();
    } else {
      this.formatCurrencyValue(e.clipboardData.getData('text/plain'));
    }
  }

  IsMaxLengthExceeded(e: KeyboardEvent) {
    let isLengthExceeded = false;
    let postDecimalLength=this._localization.fractionLength
    if (e.key == this._localization.decimalSeparator || this.AllowDefaultKeys(e)) {
      return isLengthExceeded;
    }
    let input = (<HTMLInputElement>e.target);
    let valueWithoutSymbols = (input.value?.indexOf('-') == -1)? 0: 1;
    let value = input.value.toString().replace("-", "");
    if (value.indexOf(this._localization.decimalSeparator) == -1) { // validate non decimal when decimal separator not entered
      isLengthExceeded = value.length == this.preDecimalmaxLength;
    }
    else if ((input.selectionStart + valueWithoutSymbols) <= (input.value.indexOf(this._localization.decimalSeparator) + valueWithoutSymbols)) // validate non decimal when decimal separator entered
    {
      let splitArray = value.split(this._localization.decimalSeparator);
      isLengthExceeded = splitArray[0].length == this.preDecimalmaxLength;
    }
    else { // validate decimal length
      let splitArray = value.split(this._localization.decimalSeparator);
      if(sessionStorage.getItem('noOfDecimalDigits')){
        let propDigits = parseInt(sessionStorage.getItem('noOfDecimalDigits'));
        if(!isNaN(postDecimalLength)){
          postDecimalLength = propDigits;
        }
      }
      isLengthExceeded = splitArray[1].length == postDecimalLength;
    }
    return isLengthExceeded;
  }
  AllowDefaultKeys(e: KeyboardEvent) {
    if (  //Allow default options like delete..
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return true;
    }
    return false;
  }
}
