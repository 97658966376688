<div [formGroup]="group" *ngIf="!onlyNumber && !isAlphaNumeric && !decimalNumbersOnly"
  class="form-input {{config.containerClass}}" [hidden]="config.isHidden">
  <span *ngIf="config.formInputIcon" class="rs-mr-5" [hidden]="config.isHidden">
    <i class="{{config.formInputIcon}} seat-icon-color"></i>
  </span>
  <mat-label *ngIf="!config.appearance" class="select-label" [hidden]="config.isHidden">
    <span>{{config.label | translate}}</span>&nbsp;<span class="required-asterik"
      *ngIf="config.isStarSymbolRequired">*</span>
  </mat-label>
  <mat-form-field class="basic-input-field {{config.class}}" appearance="outline"
    [ngClass]="{'input--boundary':config.appearance}" [hidden]="config.isHidden">
    <span matPrefix *ngIf="prefix">{{prefix}} &nbsp;</span>
    <input appOskInput matInput [readonly]="config.isReadOnly" [type]="config.inputType? config.inputType:'text'"
      [placeholder]="config.appearance ? (config.label | translate) : ''" [formControlName]="config.name"
      [attr.name]="config.name" [errorStateMatcher]="config.disableErrorStateMatcher ? false : matcher"
      [maxlength]="config.charLength"
      [attr.autocomplete]="config.autocomplete != undefined ? config.autocomplete : 'off'"
      [ngClass]="{'adjusted-width':config.icon1}" (keyup)="onKeyup($event)">
    <i class="view-icon {{config.icon1}} seat-icon-color"
      *ngIf="config.icon1 && group.controls[FormControlName].value && !config.showIconAlways"
      (click)="iconClick($event)"></i>
    <i class="view-icon {{config.icon1}} seat-icon-color" *ngIf="config.icon1 && config.showIconAlways"
      (click)="iconClick($event)"></i>
    <i class="view-icon {{config.icon}} seat-icon-color" *ngIf="config.icon && !group.controls[FormControlName].value && !config.showIconAlways"></i>
    <mat-error
      *ngIf="!group.controls[FormControlName].hasError('email') && group.controls[FormControlName].hasError('pattern')">
      {{'invalidText' | translate}}
    </mat-error>
    <mat-error
      *ngIf="!group.controls[FormControlName].hasError('pattern') && ( group.controls[FormControlName].hasError('required') || config.showErrorText)">
      {{config.errorMessage | translate}}
    </mat-error>
    <mat-error
      *ngIf="group.controls[FormControlName].value && (group.controls[FormControlName].hasError('email')) && group.controls[FormControlName].dirty && config.showErrorText">
      {{'guestBookDetailsInvalidEmail' | translate}}
    </mat-error>
    <mat-error
      *ngIf="!group.controls[FormControlName].hasError('pattern') && group.controls[FormControlName].value && group.controls[FormControlName].hasError('max') && !config.numberType">
      {{'refundError' | translate}}
    </mat-error>
    <mat-error
      *ngIf="group.controls[FormControlName].value && group.controls[FormControlName].hasError('max') && config.numberType == 'discount'">
      {{'discountLimit' | translate}}
    </mat-error>
  </mat-form-field>
  <app-form-add-button *ngIf="config.addBtnConfig" [addBtnConfig]="config.addBtnConfig"></app-form-add-button>
</div>
<div [formGroup]="group" *ngIf="onlyNumber && !isPhoneNumber" [hidden]="config.isHidden">
  <span *ngIf="config.formInputIcon" class="rs-mr-5" [hidden]="config.isHidden">
    <i class="{{config.formInputIcon}} seat-icon-color"></i>
  </span>
  <mat-label *ngIf="!config.appearance" class="select-label" [hidden]="config.isHidden">{{config.label | translate}}&nbsp;<span
      class="required-asterik" *ngIf="config.isStarSymbolRequired">*</span></mat-label>
  <mat-form-field class="basic-input-field" appearance="outline" [ngClass]="config.class" [hidden]="config.isHidden">
    <span matPrefix *ngIf="prefix">{{prefix}} &nbsp;</span>
    <input appOskInput [readonly]="config.isReadOnly" matInput
      [placeholder]="config.appearance ? (config.label | translate) : ''"
      [type]="config.inputType? config.inputType:'text'" [formControlName]="config.name" (keyup)="onKeyup($event)"
      [attr.name]="config.name" [maxlength]="config.charLength"
      [errorStateMatcher]="config.disableErrorStateMatcher ? false : matcher" (blur)="onFocusOut($event, config.name)"
      [attr.autocomplete]="config.autocomplete != undefined ? config.autocomplete : 'off'" numbersOnly>
    <mat-error *ngIf="group.controls[FormControlName].hasError('required') && config.showErrorText">
      {{config.errorMessage | translate}}
    </mat-error>
    <mat-error
      *ngIf="group.controls[FormControlName].hasError('minlength') && group.controls[FormControlName].touched && config.showErrorText">
      {{ 'minimumCharacterErrorText' | translate : { charLength: config.charLength } }}
    </mat-error>
    <mat-error
      *ngIf="FormControlName == 'customSize' && group.controls[FormControlName].invalid && config.showErrorText">
      {{config.errorMessage | translate}}
    </mat-error>
    <mat-hint class="text-warning m-1" *ngIf="config.hint">{{config.hintText | translate}}</mat-hint>
  </mat-form-field>
</div>
<div [formGroup]="group" *ngIf="decimalNumbersOnly" [hidden]="config.isHidden">
  <mat-label *ngIf="!config.appearance" class="select-label" [hidden]="config.isHidden">{{config.label | translate}}&nbsp;<span
      class="required-asterik" *ngIf="config.isStarSymbolRequired">*</span></mat-label>
  <mat-form-field class="basic-input-field" appearance="outline" [ngClass]="config.class" [hidden]="config.isHidden">
    <span matPrefix *ngIf="prefix">{{prefix}} &nbsp;</span>
    <input appOskInput [readonly]="config.isReadOnly" matInput
      [placeholder]="config.appearance ? (config.label | translate) : ''"
      [type]="config.inputType? config.inputType:'text'" [formControlName]="config.name" (keyup)="onKeyup($event)"
      [attr.name]="config.name" [maxlength]="config.charLength"
      [errorStateMatcher]="config.disableErrorStateMatcher ? false : matcher"
      [attr.autocomplete]="config.autocomplete != undefined ? config.autocomplete : 'off'" DecimalNumbersOnly>
    <mat-error *ngIf="group.controls[FormControlName].hasError('required') && config.showErrorText">
      {{config.errorMessage | translate}}
    </mat-error>
    <mat-error
      *ngIf="group.controls[FormControlName].hasError('minlength') && group.controls[FormControlName].touched && config.showErrorText">
      {{ 'minimumCharacterErrorText' | translate : { charLength: config.charLength } }}
    </mat-error>
    <mat-error
      *ngIf="FormControlName == 'customSize' && group.controls[FormControlName].invalid && config.showErrorText">
      {{config.errorMessage | translate}}
    </mat-error>

    <mat-error
      *ngIf="FormControlName == 'refundAmountConfig' && group.controls[FormControlName].value && group.controls[FormControlName].hasError('max') && !config.numberType">
      {{'refundError' | translate}}
    </mat-error>
    <mat-error
      *ngIf="group.controls[FormControlName].value && group.controls[FormControlName].hasError('max') && config.numberType == 'discount'">
      {{'discountLimit' | translate}}
    </mat-error>
  </mat-form-field>
</div>
<div [formGroup]="group" *ngIf="onlyNumber && isPhoneNumber" [hidden]="config.isHidden" [class]="config.containerClass">
  <mat-label *ngIf="!config.appearance" class="select-label" [hidden]="config.isHidden">{{config.label | translate}}&nbsp;<span
      class="required-asterik" *ngIf="config.isStarSymbolRequired">*</span></mat-label>
  <mat-form-field class="basic-input-field" appearance="outline" [ngClass]="config.class" [hidden]="config.isHidden">
    <span matPrefix *ngIf="prefix">{{prefix}} &nbsp;</span>
    <input appOskInput [readonly]="config.isReadOnly" matInput
      [placeholder]="config.appearance ? (config.label | translate) : ''"
      [type]="config.inputType? config.inputType:'text'" [maxlength]="this.dfs.requiredPhoneNumberLength$"
      [formControlName]="config.name" (keyup)="onKeyup($event)" [attr.name]="config.name"
      [attr.autocomplete]="config.autocomplete != undefined ? config.autocomplete : 'off'"
      [errorStateMatcher]="config.disableErrorStateMatcher ? false : matcher" (blur)="onFocusOut($event, config.name)"
      phoneNumber>
    <i class="view-icon {{config.icon}} seat-icon-color" *ngIf="isNewPhoneNumber && config.icon"
      (click)="addNewPhoneNumber($event,config.index)"></i>
    <i class="view-icon {{config.icon1}} seat-icon-color" *ngIf="!isNewPhoneNumber && config.icon1"
      (click)="iconClick($event, config.name)"></i>
    <mat-error *ngIf="group.controls[FormControlName].hasError('required') && config.showErrorText">
      {{config.errorMessage | translate}}
    </mat-error>
    <mat-error
      *ngIf="(group.controls[FormControlName].hasError('minlength') || group.controls[FormControlName].hasError('maxlength')) && config.showErrorText">
      {{'guestBookDetailsInvalidPhone' | translate}}
    </mat-error>
  </mat-form-field>
</div>
<div [formGroup]="group" *ngIf="numberDivisibleOfFive" [hidden]="config.isHidden">
  <mat-label *ngIf="!config.appearance" class="select-label" [hidden]="config.isHidden">{{config.label | translate}}&nbsp;<span
      class="required-asterik" *ngIf="config.isStarSymbolRequired">*</span></mat-label>
  <mat-form-field class="basic-input-field" appearance="outline" [ngClass]="config.class" [hidden]="config.isHidden">
    <span matPrefix *ngIf="prefix">{{prefix}} &nbsp;</span>
    <input appOskInput [readonly]="config.isReadOnly" matInput
      [placeholder]="config.appearance ? (config.label | translate) : ''"
      [type]="config.inputType? config.inputType:'text'" [formControlName]="config.name" (keyup)="onKeyup($event)"
      [attr.name]="config.name" [attr.autocomplete]="config.autocomplete != undefined ? config.autocomplete : 'off'"
      [errorStateMatcher]="config.disableErrorStateMatcher ? false : matcher" (blur)="onFocusOut($event, config.name)"
      numberDivisibleOfFive>
    <mat-error *ngIf="group.controls[FormControlName].hasError('required') && config.showErrorText">
      {{config.errorMessage | translate}}
    </mat-error>
    <mat-error *ngIf="config.showErrorText">
      {{'bufferTimeErrorMsg' | translate}}
    </mat-error>
  </mat-form-field>
</div>
<div [formGroup]="group" *ngIf="isAlphaNumeric" [hidden]="config.isHidden">
  <mat-label *ngIf="!config.appearance" class="select-label" [hidden]="config.isHidden">{{config.label | translate}}&nbsp;<span
      class="required-asterik" *ngIf="config.isStarSymbolRequired">*</span></mat-label>
  <mat-form-field class="basic-input-field" appearance="outline" [ngClass]="config.class" [hidden]="config.isHidden">
    <span matPrefix *ngIf="prefix">{{prefix}} &nbsp;</span>
    <input appOskInput [readonly]="config.isReadOnly" matInput
      [placeholder]="config.appearance ? (config.label | translate) : ''"
      [type]="config.inputType? config.inputType:'text'" [formControlName]="config.name" (keyup)="onKeyup($event)"
      [attr.name]="config.name" [maxlength]="config.charLength"
      [attr.autocomplete]="config.autocomplete != undefined ? config.autocomplete : 'off'" alphaNumeric>
  </mat-form-field>
</div>