import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Host, Input, OnChanges, Optional, Output, SkipSelf } from '@angular/core';
import { ControlContainer, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subscription } from 'rxjs';
import { AgFieldConfig, AgInputFieldConfig, AgTextBoxAttributes, AgTextBoxType } from '../../Models/ag-models';
import { Localization } from '../../shared/localization/Localization';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { BaseAGYSControl } from '../base-agys-control';
import { AgInputBehaviour } from './ag-input-behaviour';

@Component({
    selector: 'ag-input',
    templateUrl: 'ag-control-input-mat.html',
    styleUrls: ['ag-control-input-mat.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: AgysMatInput },
    {
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => AgysMatInput),
    }]
})
export class AgysMatInput extends BaseAGYSControl<string | number, string | number>
    implements OnChanges, AfterViewInit {


    inputConfig: AgInputFieldConfig;
    incrementalConfig: AgFieldConfig;
    currencyFieldConfig: AgFieldConfig;
    exchangeConversionConfig: AgFieldConfig;
    percentConfig: AgFieldConfig;
    mapToForm(value: string | null| number): string | number {
        const behaviour = this.getDateCtlBehaviour();
        if (value === null || typeof value === undefined) {
            value = behaviour.defaultValue;
        }
        return behaviour.toFormMapper(value);
    }
    mapToDom(value: string | number): string | number {
        if (this.isWriteValueChange) {
            return this.getDateCtlBehaviour().toDomMapper(value);
        }

        return value;
    }

    @Input() minlength: number | undefined;
    @Input() maxlength: number | undefined;
    @Input() allowNegative: boolean;

    @Input() min: string | undefined;
    @Input() max: string | undefined;
    @Input() readonly: boolean;
    @Input() automationId: string;
    @Input() fieldUpdate;
    @Output() change: EventEmitter<string | number> = new EventEmitter<string | number>();
    @Output() onBluremitter: EventEmitter<string | number> = new EventEmitter<string | number>();
    @Output() onBlurincrementalemitter: EventEmitter<string | number> = new EventEmitter<string | number>();
    @Output() onPercentageBluremitter: EventEmitter<string | number> = new EventEmitter<string | number>();

    behaviour: AgInputBehaviour = new AgInputBehaviour(this.localization, this.commonUtilities);

    private getDateCtlBehaviour = () =>
        this._type && this.behaviour.inputTypes.find(o => o.type.toLowerCase() == this._type.toLowerCase());

    valueChangeSubscription: Subscription;

    @Input()
    get value(): string | number | null {
        if (this.customFormGrp.valid || this.customFormGrp.disabled) {
            const {
                value: { agysControl }
            } = this.customFormGrp;

            return this.mapToForm(agysControl);
        }
        return null;

    }
    set value(tel: string | number | null) {
        const telForm = tel ? this.mapToDom(tel) : tel;
        this.customFormGrp.controls['agysControl'].setValue(telForm, { emitEvent: false });
        this.stateChanges.next();
    }

    ngOnChanges() {
        this.updateControlComponent();
    }

    inputChanged(e) {
        this.isOnLoadCompleted = true;
        this.change.emit(this.value);
    }


    private _inputdirective: string = undefined;
    @Input()
    public get inputdirective(): string {
        return this._inputdirective;
    }
    public set inputdirective(value: string) {
        this._inputdirective = value;
    }

    ngAfterViewInit() {
        this.subscribeToValueChanges();
    }

    private setAgTextComponent(type: AgTextBoxType | undefined) {
        let directives: string[] = [];
        let className = 'ag_form-control--lg';
        let maxlength: number | undefined = this.maxlength || undefined;
        let minlength: number | undefined = this.minlength || undefined;

        if (typeof type !== 'undefined') {
            const ctlBehaviour = this.behaviour.inputTypes.find(o => o.type.toLowerCase() == this._type.toLowerCase());
            this.validateCtrl(ctlBehaviour, type, maxlength, minlength);
            directives = ctlBehaviour.directives;
            className = ctlBehaviour.customClass;
            maxlength = maxlength || (ctlBehaviour.maxLength ? ctlBehaviour.maxLength : 99999999);
            minlength = minlength || (ctlBehaviour.minLength ? ctlBehaviour.minLength : 0);
        }
        if (this.type == AgTextBoxType.Number) {
            this.incrementalConfig = {
                className: className,
                form: this.customFormGrp,
                formControlName: 'agysControl',
                placeHolder: this.placeholder,
                placeHolderId: this.placeholder,
                maxlength: maxlength,
                minlength: minlength,
                isRequired: this.required,
                errorMessage: `${this.localization.captions.common.Missing} ${this.placeholder}`,
                disabled: this.disabled,
                minValue: parseInt(this.min),
                maxValue: parseInt(this.max),
                automationId: this.automationId
            }
        } else if (this.type == AgTextBoxType.Currency) {
            this.currencyFieldConfig = {
                className: className,
                form: this.customFormGrp,
                formControlName: 'agysControl',
                placeHolder: this.placeholder,
                placeHolderId: '',
                maxlength: maxlength,
                minlength: minlength,
                errorMessage: `${this.localization.captions.common.Missing} ${this.placeholder}`,
                maximumValueErrorMsg: `${this.localization.captions.err_max}`,
                isRequired: this.required,
                disabled: this.disabled,
                allowNegative: this.allowNegative,
                minValue: parseInt(this.min),
                maxValue: parseInt(this.max),
                automationId: this.automationId,
                isfieldUpdate: this.fieldUpdate
            };

        }else if (this.type == AgTextBoxType.ExchangeConversion) {
            this.exchangeConversionConfig = {
                className: className,
                form: this.customFormGrp,
                formControlName: 'agysControl',
                placeHolder: this.placeholder,
                placeHolderId: '',
                maxlength: maxlength,
                minlength: minlength,
                errorMessage: `${this.localization.captions.common.Missing} ${this.placeholder}`,
                maximumValueErrorMsg: `${this.localization.captions.err_max}`,
                isRequired: this.required,
                disabled: this.disabled,
                allowNegative: this.allowNegative,
                minValue: parseInt(this.min),
                maxValue: parseInt(this.max),
                automationId: this.automationId,
                postdecimalmaxlength: this.decimallength
            };

        } else if (this.type == AgTextBoxType.Percent) {
            this.percentConfig = {
                className: className,
                form: this.customFormGrp,
                formControlName: 'agysControl',
                placeHolder: this.placeholder,
                placeHolderId: this.placeholder,
                maxlength: maxlength,
                minlength: minlength,
                isRequired: this.required,
                errorMessage: `${this.localization.captions.common.Missing} ${this.placeholder}`,
                disabled: this.disabled,
                minValue: parseInt(this.min),
                maxValue: parseInt(this.max),
                automationId: this.automationId
            }
        } else {
            this.inputConfig = {
                className: className,
                form: this.customFormGrp,
                formControlName: 'agysControl',
                placeHolder: this.placeholder,
                placeHolderId: '',
                maxlength: maxlength,
                minlength: minlength,
                errorMessage: `${this.localization.captions.common.Missing} ${this.placeholder}`,
                showRequired: this.required,
                inputType: this.getDirectives(type, directives.join(',')),
                disabled: this.disabled,
                readonly: this.readonly,
                automationId: this.automationId
            };
        }

    }

    getDirectives(type: AgTextBoxType, defaultDirectives: string): string {

        let directives: string = defaultDirectives;
        if (this.inputdirective && type && type === AgTextBoxType.FreeText) {
            directives = this.inputdirective;
        }

        return directives;
    }

    validateCtrl(ctlBehaviour: AgTextBoxAttributes, type: AgTextBoxType, maxlength: string | number | undefined, minlength: string | number | undefined) {
        if (!ctlBehaviour) {
            this.commonUtilities.safeAlert(`Control Type ${type} Not Found in ${this.formControlName}`);
        }

        if (typeof minlength !== 'undefined' && !Number.isInteger(Number(minlength))) {
            this.commonUtilities.safeAlert(`${this.formControlName} input control Having some invalid minlength`);
        }

        if (typeof maxlength !== 'undefined' && !Number.isInteger(Number(maxlength))) {
            this.commonUtilities.safeAlert(`${this.formControlName} input control Having some invalid maxlength`);
        }
    }

    private _type: AgTextBoxType = this.behaviour.defaultType;
    @Input()
    public get type(): AgTextBoxType {
        return this._type.toUpperCase() as unknown as AgTextBoxType;
    }
    public set type(value: AgTextBoxType) {
        this._type = value;
    }

    constructor(
        @Optional() @Host() @SkipSelf()
        public controlContainer: ControlContainer,
        public fb: UntypedFormBuilder,
        public localization: Localization, public cd: ChangeDetectorRef,
        public commonUtilities: CommonUtilities) {
        super(controlContainer, fb);
    }

    updateControlComponent() {
        this.setAgTextComponent(this.type);
        this.inputConfig = { ...this.inputConfig };

        if (this.type === AgTextBoxType.Currency) {
            this.currencyFieldConfig = { ...this.currencyFieldConfig };
        } 
        else if(this.type === AgTextBoxType.ExchangeConversion){
            this.exchangeConversionConfig = { ...this.exchangeConversionConfig };
        }
        else if(this.type === AgTextBoxType.Number){
            this.incrementalConfig = { ...this.incrementalConfig };
        }
    }

    onBlur(event)
    {
        this.onBluremitter.emit(event);
    }
    onPercentageBlur(event)
    {
        this.onPercentageBluremitter.emit(event);
    }


    onBlurincremental(event)
    {
        this.onBlurincrementalemitter.emit(event);
    }
}
