import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { Component, Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { AppService } from "@app/app.service";
import { CacheService } from "@app/core/services/cache.service";
import { ComponentDetails } from "@app/popup-module/models/popup.interface";
import { PopupService } from "@app/popup-module/popup.service";
import { ModifySessionComponent } from "@app/shared/components/modify-session/modify-session.component";
import { ComponentTypes, TimeLineAvailablityShowTypes } from "@app/shared/constants/commonenums";
import { ModifySessionRequestState } from "@app/shared/models/global.interface";
import { StaffBlockDTO } from "@app/shared/models/RestaurantDTO";
import { PartyService } from "@app/shared/services/party.service";
import { Utilities } from "@app/shared/utilities/utilities";
import { TranslateService } from "@ngx-translate/core";
import _ from "lodash";
import moment from "moment";
import { ActivitiesTimelineService } from "../services/activities-timeline.service";
import { BlockingrulePopupservice } from "@app/shared/components/create-update-blocking-tab-layout/blockingrulepopup.service";
import { TableBlockingRuleDTO } from "@app/shared/models/TableBlockingDTO";
@Component({
    selector: 'app-timeline-main',
    templateUrl: './timeline-main.component.html',
    styleUrls: ['./timeline-main.component.scss']
})
export class TimelineMainComponent {

    @Input('timelineView') timelineView: any;
    @Input('viewPort') viewPortContainer: any;
    @Input('timeLineData') timeLineData: any;
    @Input('timeArrayGenerated') timeArrayGenerated: any;
    @Input('searchText') searchText: any;
    @Input('viewBy') selectedViewId: number;
    @Input('lastIndex') lastIndex;
    @Input('filterData') filterData;
    @Input() CurrentDateSelected;
    @Input() OpenHours;
    @Input() TimeSlotUnitInMinutes;

    @Input('columnWidth') columnWidth;
    @Input('isSwitchAxis') switchAxis: boolean;
    @Output('addBookingToSlot') addBookingToSlotEvent = new EventEmitter();
    @Output('activitySelected') activitySelectedEvent = new EventEmitter();
    columnWidthCache: any;
    @ViewChild(CdkVirtualScrollViewport, { static: false }) public viewPort: CdkVirtualScrollViewport;

    @Input('staffSchedule') staffSchedule: any;

    sortBy = ['CategoryName', (data) => { return data.SortOrder ? data.SortOrder : undefined }, "IsTemplate", "Name"];
    overallWidth: number;

    constructor(public cs: CacheService, public appService: AppService, private ats: ActivitiesTimelineService,
        public partyService: PartyService, public translateService: TranslateService, public ps: PopupService) {
        this.ats.viewPort = null;
    }

    ngOnChanges() {
        if (!this.ats.viewPort) {
            this.ats.viewPort = this.viewPortContainer;
        }
        if (this.columnWidthCache && this.columnWidthCache != this.columnWidth) {
            this.columnWidthCache = this.columnWidth;
            return;
        }
        this.columnWidthCache = this.columnWidth;
        if (this.switchAxis)
            this.getScreenWidth();
    }

    activitySelected(data) {
        this.activitySelectedEvent.emit(data);
    }

    getSlotIndex([classHour = 0, classMinutes = 0], timeArrayGenerated) {
        return timeArrayGenerated.findIndex((time) => time.minutes() === +classMinutes && time.hours() === +classHour);
    }

    addBookingToSlot(data) {
        this.addBookingToSlotEvent.emit(data)
    }

    promptBlockSession([activityData, item]) {
        let blockSession = this.partyService.getActivityCustomisation(activityData, item);
        item.startTime = item.StartTime;
        item.ClassEndDate = this.partyService.cs.settings.value.SpecialMeals.find(meal => meal.Id == activityData.Id)?.EndDate;
        this.partyService.promptBlockSession(activityData, item, blockSession == this.translateService.instant('blockbuttontext'));
    }

    modifySession([activityData, item]) {
        this.ats.modifySession([activityData, item], this.CurrentDateSelected);
    }

    cancelSession([activityData, item]) {
        this.ats.cancelSession([activityData, item], this.CurrentDateSelected);
    }
    getScreenWidth() {
        let sessionLength = this.filterData?.activities?.length ? this.filterData?.activities?.length : this.timeLineData.length
        this.ats.sessionWidth = this.filterData?.activities?.length ? this.ats.sessionWidth : (this.cs.settings.value.General.DefaultTimelineColumnWidth || 300)
        this.overallWidth = screen.width - (150 + (this.appService.hideSideMenu ? 50 : 110));
        if (this.overallWidth / this.ats.sessionWidth > sessionLength) {
            this.ats.sessionWidth = this.overallWidth / sessionLength;
        }
    }

    blockStaff([activityData, item]) {
        if (item.openBookingSlot) {
            let startTime = moment(this.CurrentDateSelected).format("YYYY-MM-DD") + 'T' + item.StartTime;
            let endTime = moment(this.CurrentDateSelected).format("YYYY-MM-DD") + 'T' + item.EndTime;
            this.ats.createStaffBlockHr(this.CurrentDateSelected, this.staffSchedule, [activityData.Id], startTime, endTime);
        } else {
            this.ats.createStaffBlockHr(this.CurrentDateSelected, this.staffSchedule, [activityData.Id], item.Start, item.End, item.Reason, item.Id, true, item.ShiftId, item.ScheduleId);
        }
    }

    deleteStaffBlock([activityData, item]) {
        let staffBlockObj: StaffBlockDTO = { BreakHourId: item.Id, EffectiveRange: { Start: item.Start, End: item.End, Reason: item.Reason }, Date: this.appService.headerSelectedDate, isEdit: true, StaffIds: [activityData.Id], ShiftId: null, StaffScheduleId: [] };
        this.ats.deleteStaffBlock(staffBlockObj);
    }


}

@Directive({
    selector: '[getGridColumn]'
})
export class GetGridColumn implements OnChanges {

    @Input('getGridColumn') data: any;
    @Input('timeArray') timeArrayGenerated: number = 3;

    constructor(private el: ElementRef) {
    }

    ngOnChanges() {
        let startIndex = this.getSlotIndex(this.data.StartTime.split(":"), this.timeArrayGenerated);
        let endIndex = this.getSlotIndex(this.data.EndTime.split(":"), this.timeArrayGenerated);
        this.el.nativeElement.style.gridColumn = `${startIndex + 1} / ${endIndex + 1}`;
        // let idx=this.getSlotIndex(data, timeArrayGenerated);

    }

    getSlotIndex([classHour, classMinutes], timeArrayGenerated) {
        return timeArrayGenerated.findIndex((time) => time.minutes() === +classMinutes && time.hours() === +classHour);
    }
}


@Pipe({
    name: 'sortData'
})
export class SortDataPipe implements PipeTransform {

    transform(array, sortBy, sortOrder?): any {
        array = [...array];
        return _.orderBy(array, sortBy, sortOrder);
    }
}



@Pipe({
    name: 'timelineFilter'
})
export class TimelineFilterDataPipe implements PipeTransform {

    transform(array, filterData, searchText): any {
        let activities = filterData?.activities;
        if (array && _.isArray(array)) {
            return array.filter(o => (!activities || !activities.length || activities.includes(o.Id)) &&
                (!searchText || o.Name.toLowerCase().includes(searchText.toLowerCase())));
        }
        return array || [];
    }
}

@Pipe({
    name: 'specialMealName'
})
export class SpecialMealNamePipe implements PipeTransform {

    transform(specialMealId, settings): any {
        return settings.SpecialMeals.find(meal => meal.Id == specialMealId)?.Name;
    }
}


