import { Component, ElementRef, Input, OnInit, Pipe, PipeTransform, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { CacheService } from '@app/core/services/cache.service';
import { PopupService } from '@app/popup-module/popup.service';
import { PartyType } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { DynamicFormService } from '@app/shared/dynamicform/service/dynamic-form.service';
import { ContactDTO } from '@app/shared/models/InputContact';
import { BookingContactDTO, ConfirmationVia, CountryDTO, SettingsDTO, WhoWillBeNotifiedType } from '@app/shared/models/RestaurantDTO';
import { Utilities } from '@app/shared/utilities/utilities';
import { ISubscription } from 'rxjs/Subscription';



@Component({
  selector: 'app-prompt-host-for-confirmation',
  templateUrl: './prompt-host-for-confirmation.component.html',
  styleUrls: ['./prompt-host-for-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromptHostForConfirmationComponent implements OnInit {
  bookingConfirmationConfig: FieldConfig[] = [];
  @Input() bookingContacts:any[] =[];
  @Input() contactId: any[] = []; 
  @Input() selectedContactId: any;
  @Input() sessionType: any;
  selectConfig: FieldConfig[] = [];
  selectAllConfig: FieldConfig[] = [];
  settingsSubscription: ISubscription;
  _settings: SettingsDTO;
  partyType = PartyType;
  ConfirmationViaEnum = ConfirmationVia;
  @ViewChildren('errorText') errorText: QueryList<ElementRef>;
  @ViewChildren('bookingConfirmationForm') bookingConfirmationForm: QueryList<DynamicFormComponent>;
  @ViewChildren('bookingSelectedForm') bookingSelectedForm: QueryList<DynamicFormComponent>;
  @ViewChild('bookingSelectAllForm', { static: true }) bookingSelectAllForm: DynamicFormComponent;
  ConfirmationViaOptions = [{ id: ConfirmationVia.Email, value: 'Email' }, { id: ConfirmationVia.Sms, value: 'Sms' }, { id: ConfirmationVia.Both, value: 'both' }];
  availableHeaders = ['#', 'guestName', 'EmailAddress', 'PhoneNumber', 'pageMethod', 'sendReminder']

  bookingContactFormGroup: UntypedFormGroup;


  countryCodes: { id: string; value: string; countryCode: string; countryId: number, PhoneNumberLength: number }[];
  constructor(private cs: CacheService, private dfs:DynamicFormService, private fb:FormBuilder, private ps:PopupService) {
    this.settingsSubscription = cs.settings.subscribe(sett => {
      this._settings = sett;
    });
  }

  ngOnInit(): void {
    if(this.sessionType == this.partyType.StandBy){
      this.availableHeaders.pop();
    }
    this.loadCountryCodes();
    this.loadSelectConfig();
    this.bookingContactFormGroup = this.fb.group({
      bookingContactArray : new UntypedFormArray([])     
    });
  }

  /* load avaialble countries codes */
  loadCountryCodes() {
    this.countryCodes = (this._settings.Countries as CountryDTO[]).map((country: CountryDTO) => {
      return {
        id: country.Name,
        value: `${country.Label} (${country.CountryPhoneCode})`,
        countryCode: country.CountryPhoneCode,
        countryId: country.Id,
        PhoneNumberLength: country.PhoneNumberLength,       
      };
    });
  }

  ngDoCheck(){
    this.ps.cancelBtnEnable$.next(!this.errorText?.length && this.bookingContacts?.filter(contact => contact.bookingSelected).length);
  }

  validateCountryFormat(contact){
    contact.CountryId = this.countryCodes.find(country => country.id == contact.CountryCode)?.countryId
    this.setUpCountryCodeForMobileOrPhone(contact,contact.CountryId)
    contact.PhoneNumber = this.dfs.onInputChange(contact.PhoneNumber,false);
   
  }

  ngAfterViewInit(){
    if(this.bookingContacts?.length){
      let sendForAllGuest = false;     
      if(this.cs.settings.value.MerchantSettings.WhoWillBeNotified.SettingValue == WhoWillBeNotifiedType.IndividualGuest){
        sendForAllGuest = true;
        this.bookingSelectAllForm?.form?.controls.selectAll.setValue(this.selectedContactId ? false : true);
      }
      else {
        this.bookingSelectAllForm?.form?.controls.selectAll.setValue(this.bookingContacts.length == 1); 
      }
      this.bookingContacts.forEach((contact,index) => {
        contact.bookingSelected = this.selectedContactId ?  this.selectedContactId == contact.ContactId : (!sendForAllGuest || contact.IsSystemGenerated ? this.contactId.some(id => id === contact.ContactId) : sendForAllGuest);
        contact.CountryCode = this.setUpCountryCodeForMobileOrPhone(contact,contact.CountryId);
        contact.PhoneNumber = this.dfs.onInputChange(contact.PhoneNumber,null);
        contact.ConfirmationVia = (contact.ConfirmationVia == undefined || contact.ConfirmationVia == ConfirmationVia.None) ? ConfirmationVia.Email : contact.ConfirmationVia;
        contact.SendReminder = JSON.parse(this.cs.settings.value.MerchantSettings?.EnableReminderSMSEmail?.SettingValue.toLowerCase()) || false;      
      })
  }


    if(this.bookingSelectAllForm){
   // this.bookingSelectAllForm.form.controls.selectAll.setValue(true);
    this.bookingSelectAllForm.form.valueChanges.subscribe(value => {
    
        this.bookingContacts.forEach(contactInfo => {
          contactInfo.bookingSelected = value.selectAll;
        })
     
    })
  }
  }


  /* for loading config for booking confirmation */
  loadSelectConfig() {
    this.selectAllConfig.push(
      {
        type: 'checkbox',
        name: 'selectAll',
      }
    );
    this.selectConfig.push(
      {
        type: 'checkbox',
        name: 'bookingSelected',       
      }
    );

    
  }

  ngOnChanges(changes: SimpleChanges) {
    
  }

  setUpCountryCodeForMobileOrPhone(contact,countryId) {
    let selectedCountryCode = this.countryCodes.filter((country) => {
      return country.countryId === countryId;
    })[0];

    if(!selectedCountryCode){
      let propertyCountryCode = this._settings.General.Country;
      selectedCountryCode = this.countryCodes.filter((country) => {
        return country.id === propertyCountryCode;
      })[0];
    }
    if(selectedCountryCode){
    this.dfs.selectedCountryCode = selectedCountryCode.countryCode;
    this.dfs.selectedCountryId = selectedCountryCode.countryId;
    contact.requiredPhoneNumberLength = selectedCountryCode.PhoneNumberLength;
    return selectedCountryCode.id;  
    }
    return null;     
  }


  selectContact(){
    let allContactsSelected = this.bookingContacts.length == this.bookingContacts.filter(contact => contact.bookingSelected == true).length
    this.bookingSelectAllForm.form.controls.selectAll.setValue(allContactsSelected, { emitEvent: false });
  }

  ngOnDestroy() {

  }

}

@Pipe({
  name: 'validateEmail'
})
export class ValidateEmailPipe implements PipeTransform {

  transform(EmailAddress: string): any {
    return EmailAddress?.match(/^[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}/)
  }


}
