<ng-container [formGroup]="contactForm" *ngIf="arrayName; else singlePhone" class="phone-container">
  <div [formArrayName]="arrayName" class="ag_position--relative ag-phone-wrapper"
    *ngFor="let phone of contactForm.get(arrayName)['controls']; let i= index; let first = first" [ngClass]="[auto?.isOpen? 'show':'hide']">
    <div [formGroupName]="i" class="phone-wrapper">
      <div class='overlay-div'></div>
      <div class="ph-fixed-width--wrapper">
        <mat-form-field [floatLabel]="floatLabel" [ngClass]="typeClass" (click)="onControlClick(placeHolder)">
          <mat-select [placeholder]="placeHolder"  [attr.LiteralID]="placeHolderId" id="phone{{i}}" [formControlName]="typeControlName"
            (selectionChange)="typeChange($event, phone, i);" [required]="isPhoneNumberRequired" attr.automationId='Dd_{{automationId}}_typeControlName_{{i}}'>
            <mat-option></mat-option>
            <mat-option *ngFor="let phonetype of options" [value]="phonetype.id">
              {{phonetype.description}}
            </mat-option>
          </mat-select>
          <mat-error class="Textnowrap" *ngIf="phone.get(typeControlName).hasError('required')" [attr.LiteralID]="'typeControlName'" >
            {{captions.missingPhoneDrop}}
         </mat-error>
        </mat-form-field>

        <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--25 ag-pb-4 pr-2">
          <span *ngIf="CountryCodeRef.value" class="position-absolute">
            <div class="iti__flag-box">
              <div class="iti__flag" [ngClass]="CountryCodeRef.value | flagSelector"></div>
            </div>+
          </span>
          <input type="text"
          class="ag_pr--5 ag_pl--35px"
          [placeholder]="countryCodePlaceHolder"
          matInput #CountryCodeRef
          [matAutocomplete]="auto"
          formControlName="countryCode" attr.automationId='txt_{{automationId}}_countryCode{{i}}'
          (input)="setmandatoryField('countryCode',controlName,contactForm,i);"
          (blur)="setmandatoryField('countryCode',controlName,contactForm,i);clearOutCountryCode(CountryCodeRef.value,contactForm, i)"
          (keyup)="filterdata($event)" (keypress)="restrictCharacter($event)"
          >
        <mat-autocomplete [panelWidth]="300" autoActiveFirstOption #auto="matAutocomplete"  (optionSelected)="getphoneformatmask($event,i); setmandatoryField(controlName,'countryCode',contactForm,i)">
              <mat-option *ngFor="let option of countrylist"
                  [value]="option">
                  <div class="iti__flag-box">
                    <div class="iti__flag" [ngClass]="option.flagClass"></div>
                  </div>{{option.dialCode}} {{option.name}}
              </mat-option>
          </mat-autocomplete>
          <mat-error class="Textnowrap" *ngIf="phone.get('countryCode').hasError('required')"
            title="{{captions.Missing}} {{code}}">
            {{captions.Missing}} {{code}}
          </mat-error>
          </mat-form-field>

        <mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass" (click)="onControlClick(null,errorMessage,null)">
          <input matInput [name]="controlName" (input)="setmandatoryField(controlName,'countryCode',contactForm,i)" attr.automationId='txt_{{automationId}}_controlName{{i}}'
            (blur)="setmandatoryField(controlName,'countryCode',contactForm,i)" (focus)="updateMask(i)" [formControlName]="controlName" [placeholder]="phoneNumberPlaceHolder"
            [textmask]="textMaskPhoneArray[i]" [duplicateCheck]="controlName" [currentIndex]="i" [required]="isPhoneNumberRequired" [maxlength]="maxPhoneLength">
           <mat-error class="Textnowrap" *ngIf="phone.get(controlName).hasError('required')" [attr.LiteralID]="'missingPhone'" >
             {{captions.missingPhone}}
          </mat-error>
          <mat-error *ngIf="phone.get(controlName).hasError('duplicate')">
            {{duplicateError}}
          </mat-error>
          <mat-error *ngIf="phone.get(controlName).hasError('incorrect')"  [attr.LiteralID]="errorMessageId">
            {{errorMessage}}
          </mat-error>
        </mat-form-field>
        <span class="icon-plus" [ngClass]="{'ag_section--disable':isPhoneDisabled}" (click)="addPhoneItem(i,defaultphoneTypeObj,defaultCountrydialCode, '')"
          *ngIf="first" attr.automationId='Icn_{{automationId}}_plus'></span>
        <span class="icon-Minus" [ngClass]="{'ag_section--disable':isPhoneDisabled}" (click)="removePhoneItem(i)"
          *ngIf="!first" attr.automationId='Icn_{{automationId}}_minus{{i}}'></span>
          <!-- <ng-container *ngIf="showSwitches">
            <div class="ag-phone-switch primary-info-toggle ag_display--inblock">
              <label class="ag_form--label ag_common-label-width">{{primaryLabel}}</label>
              <mat-slide-toggle [formControlName]="primarySwitchName" disableRipple="true"
                (change)="togglePrimaryContact(i)"></mat-slide-toggle>
            </div>
            <div class="ag_pl--5 private-info-toggle ag_display--inblock">
              <label class="ag_form--label ag_common-label-width">{{privateLabel}}</label>
              <mat-slide-toggle [formControlName]="privateSwitchName" disableRipple="true" (change)="togglePrivateContact(i)"></mat-slide-toggle>

            </div>
          </ng-container> -->
      </div>
      <ng-container *ngIf="showSwitches">
        <div class="ag-phone-switch primary-info-toggle" attr.automationId='Tog_{{automationId}}_primarySwitchName{{i}}'>
          <label class="ag_form--label ag_common-label-width">{{primaryLabel}}</label>
          <mat-slide-toggle [formControlName]="primarySwitchName" disableRipple="false" [disabled]="isOnlyOneContact"
            (change)="togglePrimaryContact(i)"></mat-slide-toggle>
        </div>
        <div class="ag_pl--5 private-info-toggle" attr.automationId='Tog_{{automationId}}_privateSwitchName{{i}}'>
          <label class="ag_form--label ag_common-label-width">{{privateLabel}}</label>
          <mat-slide-toggle [formControlName]="privateSwitchName" disableRipple="true"
          (change)="togglePrivateContact(i)"></mat-slide-toggle>
        </div>
      </ng-container>
    </div>

    <div [formGroupName]="i" *ngIf="phone.get(typeControlName).value === phoneTypes.office"
      class="ag_display--flex ph-fixed-width--wrapper">
      <mat-form-field [floatLabel]="floatLabel" [ngClass]="extensionClass" class="ag_ml--auto">
        <input matInput name="extension" formControlName="extension" [placeholder]="captions.lbl_extension"
          [maxlength]="extensionLength" inputtype='nonnegative' attr.automationId='Txt_{{automationId}}_extension{{i}}'>
      </mat-form-field>
    </div>
  </div>
</ng-container>

<ng-template #singlePhone>
  <ng-container [formGroup]="contactForm">
    <mat-form-field [floatLabel]="floatLabel" [ngClass]="typeClass">
      <mat-select [placeholder]="placeHolder" [formControlName]="typeControlName"  attr.automationId='Dd_{{automationId}}_typeControlName'
        (selectionChange)="typeChange($event, contactForm);"  [attr.LiteralID]="placeHolderId">
        <mat-option></mat-option>
        <mat-option *ngFor="let phonetype of options" [value]="phonetype.id">
          {{phonetype.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- -->
    <mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass1" (click)="onControlClick(null,null,countryCodePlaceHolder)">
      <mat-label [attr.LiteralID]="countryCodePlaceHolderId" style="cursor:pointer">{{countryCodePlaceHolder}}</mat-label>
      <span *ngIf="SingleCountryCodeRef.value" class="position-absolute">+</span>
      <input matInput name="countryCode" #SingleCountryCodeRef  formControlName="countryCode" [placeholder]="countryCodePlaceHolder"
        [maxlength]="countryCodeLength" inputtype='nonnegative' attr.automationId='{{cCodeAutomationId}}'
        (input)="setmandatoryField('countryCode',controlName,contactForm)" attr.automationId='txt_{{automationId}}_countryCode'
        (blur)="setmandatoryField('countryCode',controlName,contactForm)">
      <mat-error *ngIf="contactForm.get('countryCode').hasError('required')">
        {{captions.Missing}} {{code}}
      </mat-error>
    </mat-form-field>
    <!---->

    <mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass">
      <input matInput [name]="controlName" (input)="setmandatoryField(controlName,'countryCode',contactForm)" (blur)="setmandatoryField(controlName,'countryCode',contactForm)" [formControlName]="controlName"
        [textmask]="textMaskPhone" [placeholder]="phoneNumberPlaceHolder" attr.automationId='txt_{{automationId}}_controlName'>
      <mat-error *ngIf="contactForm.get(controlName).hasError('incorrect')" [attr.LiteralID]="errorMessageId">
        {{errorMessage}}
      </mat-error>
    </mat-form-field>
    <div *ngIf="contactForm.get(typeControlName).value === phoneTypes.office" class="ag_display--flex">
      <mat-form-field [floatLabel]="floatLabel" [ngClass]="extensionClass" class="ag_ml--auto">
        <input matInput name="extension" formControlName="extension" [placeholder]="extensionPlaceHolder"
          [maxlength]="extensionLength" inputtype='nonnegative' attr.automationId='Txt_{{automationId}}_extension'>
      </mat-form-field>
    </div>
  </ng-container>
</ng-template>
