<span class="dialog-container__header--close icon-Cancel close-section" onKeyDown="" (click)="close()"></span>
<ng-container *ngFor="let guide of guideData;let i = index">
    <div  class="title-container" *ngIf="guide.isParent">
        <h3 class="title">{{guide.title}}</h3> <span class="shortcut-key">{{guide?.shortcutkey}}</span>
    </div>
    <div class="chip-container" *ngIf="guide.isParent">
        <ng-container *ngFor="let item of guide.list;let j = index">
            <div class="column">
                <div class="chip">
                    <span class="shortcut-key">{{item?.shortcutkey}}</span>
                    <span class="guide-value">{{item?.title}}</span>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>