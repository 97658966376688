<!--categories filter-->
<section [formGroup]="InventoryAvailabilityFormGrp" autocomplete="off">
  
  <div>
    <app-retail-multi-select-dropdown *ngIf="categories" [dropDownFilterData] = "categories" [dropDownControlname] = "'category'" [dropDownName] = captions.Category (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
  </div>
  <div>
    <app-retail-multi-select-dropdown *ngIf="itemtype" [dropDownFilterData] = "itemtype" [dropDownControlname] = "'itemType'" [dropDownName] = captions.ItemType (dropDownFrmControl) = "getFormControlValue($event)"></app-retail-multi-select-dropdown>
  </div>
  <div class="mb-3">
    <label class="LW14">{{this.localization.captions.reports.DetailView}}</label>
    <app-retail-toggle-switch  class="d-block" formControlName="DetailView"></app-retail-toggle-switch>
  </div>
  
</section>
