import { Processor } from "./processor";
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { CacheService } from '@app/core/services/cache.service';
import { PartyService } from "@app/shared/services/party.service";
import { Utilities } from '@app/shared/utilities/utilities';
import _, { isObject, uniqBy } from 'lodash';

export class GuestItineraryChangeProcessor implements Processor {

    private static instance: GuestItineraryChangeProcessor;
    private constructor(public cs: CacheService, public partyService: PartyService) {
    }

    public static Instance(_cs: CacheService, _ps: PartyService): GuestItineraryChangeProcessor {
        if (!GuestItineraryChangeProcessor.instance) {
            GuestItineraryChangeProcessor.instance = new GuestItineraryChangeProcessor(_cs, _ps);
        }
        return GuestItineraryChangeProcessor.instance;
    }

    Process(objectChange: ObjectChange, additionaldata: object, propertyId: number): void {
        let partiesList = [];
        let standByPartiesList = [];
        let isStandBy;
        if (objectChange) {
            partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.Parties$.value : this.cs.propertySettings.value[propertyId].reservations;
        }
        const cartBookingId = objectChange.PropertyChanges.find(property => property.PropertyName === 'CartBookingId')?.Value;
        const parties = partiesList.filter(party => party.CartBookingId == cartBookingId);

        parties.forEach(party => {
            if (!party) {
                const _standByPartyList = propertyId == Utilities.RestaurantId() ? this.partyService.StandbyParties$.value : this.cs.propertySettings.value[propertyId].StandbyParties
                const party = _standByPartyList.find(party => party.CartBookingId == cartBookingId);
                if (party) {
                    partiesList = _standByPartyList
                    isStandBy = true;
                }
            }

            if (party) {
                const _bookingContactId = objectChange.PropertyChanges.find(property => property.PropertyName === 'BookingContactId').Value;
                const _bookedSessionId = objectChange.PropertyChanges.find(property => property.PropertyName === 'BookedSessionId').Value;
                let _itineraryIndex = party.GuestItineraryMapping?.findIndex(({ BookingContactId, BookedSessionId, StatusCode }) => StatusCode === 10 && BookedSessionId === _bookedSessionId && BookingContactId === _bookingContactId);

                if (_itineraryIndex != -1) {
                    objectChange.PropertyChanges.forEach(property => {
                        if (property.PropertyName === 'StatusCode') {
                            party.GuestItineraryMapping[_itineraryIndex].StatusCode = property.Value;
                        } else if (property.PropertyName === 'WristbandUUID') {
                            party.GuestItineraryMapping[_itineraryIndex].WristbandId = property.Value;
                        } else if (property.PropertyName === 'ChargePrivilege') {
                            party.GuestItineraryMapping[_itineraryIndex].ChargePrivilege = property.Value;
                        }
                    })
                } else if (objectChange.PropertyChanges.find(({ PropertyName }) => PropertyName === 'StatusCode')?.Value == 10) {
                    let newItinerary = {};
                    objectChange.PropertyChanges.forEach(property => {
                        if (ITINERARY_PROPS.includes(property.PropertyName)) {
                            if(property.PropertyName === 'WristbandUUID'){
                                newItinerary['WristbandId'] = property.Value;
                            }else{
                                newItinerary[property.PropertyName] = property.Value;
                            }
                        }
                    })
                    party.GuestItineraryMapping.push(newItinerary);
                }
            }
        })
        partiesList = _.uniqBy(partiesList, 'Id');

        if (propertyId == Utilities.RestaurantId()) {
            if (isStandBy) {
                this.partyService.StandbyParties$.next(partiesList);
                this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
            }
            else {
                this.partyService.Parties$.next(partiesList);
                this.partyService.partiesList = partiesList;
                this.cs.propertySettings.value[propertyId].reservations = partiesList;
            }
        } else {
            if (isStandBy) {
                this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
            }
            else {
                this.cs.propertySettings.value[propertyId].reservations = partiesList;
            }
        }
    }
}

const ITINERARY_PROPS = ["BookedSessionId", "BookingContactId", "BookingReferenceId", "CartBookingId", "ChargePrivilege", "CommonItineraryID", "ContactId", "StatusCode", "WristbandUUID", "isModified"]