import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnChanges, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { ContactNotes } from '@app/shared/models/global.interface';
import { Utilities } from '@app/shared/utilities/utilities';
import { PreferredTableComponent } from '@components/preferred-table/preferred-table.component';
import { SelectServerComponent } from '@components/select-server/select-server.component';
import { ComponentTypes } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { FormChipService } from '@dynamicform/components/form-chip/form-chip.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { DynamicFormService } from '@dynamicform/service/dynamic-form.service';
import { ContactNoteDTO, FullContactDTO } from '@models/InputContact';
import { Category, FloorPlanDTO, LayoutDTO, ServerDTO, SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import * as _ from 'lodash';
import { ISubscription, Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreferencesComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  preferenceDetailsForm: UntypedFormGroup;
  serverList: any = []; // to hold the servers
  _tableList: any = []; // to hold the tables
  tagsList: any = []; // to hold the names of tags
  selectedPreferredTable: any;
  selectedPreferredServer: any;
  config: FieldConfig[];
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  selectedIndex: any;
  subscriptions: Subscription = new Subscription();
  standAloneTablesArray = [];
  guestBookControls: string[];
  floorPlan: FloorPlanDTO[] = [];
  selectedTable: any;
  serverArray: any[] = [];
  guestNotes: ContactNotes[] = [];
  constructor(private fb: UntypedFormBuilder, private ps: PopupService, private guestBookService: GuestBookService,
              private formChipService: FormChipService, @Inject(COMPONENTINPUT) private data: FullContactDTO, private dialog: MatDialog,
              private partyService: PartyService, private dfs: DynamicFormService, private cs: CacheService, private ts:TranslateService) {
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this.setFormConfig();
      this.setTags();
    }));
  }

  ngOnInit() {
    this.floorPlan = this.cs.layout.value.FloorPlans;
    const floorPlanArray = [];
    this.floorPlan.forEach(element => {
      floorPlanArray.push({ id: element.Id, value: element.Name });
    });
    const standAloneTables = this.floorPlan.filter(x => x.IsDefault == true)[0].StandaloneTables;

    standAloneTables.forEach(table => {
      if (!table.IsTemplate) {
        this.standAloneTablesArray.push({ id: table.Id, value: table.Name });
        if (this.data) {
          if (table.Id === this.data.PreferredTableId) {
            this.guestBookService.selectedTables = table;
          }
        }
      }      
    });

    this.getCommunicationList();
    this.setTags();

    const preferenceFormGroup = this.fb.group({
      preferredTags: '',
      preferredCommunication: '',
      preferredTable: '',
      preferredServer: '',
      partnerName: '',
      partnerBirthday: ''
    });
    this.guestBookService.guestForm.addControl('preferencesDetailsform', preferenceFormGroup);
  }

  setTags() {
    this.guestBookService.selectedPreferenceTags = [];
    const preferredTags = this.config.filter((fieldConfig) => fieldConfig.name.includes('preferredTags'));
    preferredTags.forEach((tag) => {
      this.guestBookService.selectedPreferenceTags.push(...tag.options);
    });
    if (this.data) {
      this.data.Notes?.forEach((note) => {
        preferredTags.forEach((tag) => {
          const filteredNotes = tag.options.find((data) => data.Id === note.RelatedId);
          if (filteredNotes) {
            filteredNotes.setSelected = true;
          }
        });
      });
    }
  }

  setServers() {
    this.cs.settings.value.Servers.forEach(server => {
      if (!server.IsTemplate) {
        this.serverArray.push({ id: server.Id, value: server.Name });
        if (this.data) {
          if (server.Id === this.data.PreferredServerId) {
            this.guestBookService.selectedServers = server;
            this.dynamicForm.form.patchValue({ preferredServer: server.Name });
          }
        }
      }
    });
  }

  ngOnChanges() {
    const preferredServer = this.config.filter((fieldConfig) => fieldConfig.name == 'preferredServer')[0];
    preferredServer.value = this.guestBookService.selectedServers.Name;
  }

  ngAfterViewInit() {
    this.setServers();
    this.addSubscriptions();
    this.setEditData();
    if(this.ps.tabsActionData?.length){
    this.ps.tabsActionData[this.selectedIndex].gotoNextTab = this.data ? true : false;
     }
  }

  addSubscriptions() {
    this.subscriptions.add(this.partyService.tableSelected.subscribe(val => {
      this.selectedTable = val;
      const preferredTable = this.config.filter((fieldConfig) => fieldConfig.name == 'preferredTable')[0];
      preferredTable.value = val.name;
    }));

    this.guestBookService.guestForm.get('preferencesDetailsform');
    this.selectedIndex = this.guestBookService.tabsModal.tabs.findIndex(x => x.tabComponent === PreferencesComponent);
    this.guestBookControls = Object.keys(this.guestBookService.guestForm.controls);
    this.subscriptions.add(this.partyService.tabChange$.subscribe((data) => {
      if (data === this.selectedIndex) {
        this.setCommunicationPreferences();
         if (this.ps.tabsActionData?.length) {
          this.ps.tabsActionData[this.selectedIndex].gotoNextTab = this.dynamicForm.form.valid;
        }
        this.ps.tabsActions$.next(this.ps.tabsActionData);
      }
    }));

    this.subscriptions.add(this.dynamicForm.form.valueChanges.subscribe((data) => {
      if (!data.preferredTable) {
        this.guestBookService.selectedTables = {};
      }
      if (!data.preferredServer) {
        this.guestBookService.selectedServers = {} as ServerDTO;
      }
      this.guestBookService.guestForm.get('preferencesDetailsform')?.patchValue(data);
      if (this.dynamicForm.form.dirty) {
        this.makeFormDirty();
      }
     if (this.ps.tabsActionData?.length) {
        this.ps.tabsActionData[this.selectedIndex].gotoNextTab = this.guestBookService.guestForm.get('contactDetailsForm').valid && this.dynamicForm.form.valid;
      }
      this.ps.tabsActions$.next(this.ps.tabsActionData);
    }));
    this.subscriptions.add(this.formChipService.formChipChange$.subscribe((selectedChips) => {
      if (selectedChips.name === this.config.filter((fieldConfig) => fieldConfig.name == 'preferredCommunication')[0].name) {
        this.guestBookService.preferredCommunication = selectedChips.data;
        this.makeFormDirty();
      } else if (this.config.filter((fieldConfig) => fieldConfig.name == selectedChips.name).length > 0) {
        const preferredTagsIndex = this.config.findIndex(fieldConfig => fieldConfig.name == selectedChips.name);
        const otherTags = selectedChips.data.filter(chip => chip.Name == 'Others');
        if (otherTags && otherTags.length > 0) {
          let otherNotesName = `otherNotes${otherTags[0].Id}`;
          let isOtherNoteAvailable = this.config.filter((config) => config.name == otherNotesName).length;
          const otherNotesControl = this.dynamicForm.config.filter((config) => config.name == otherNotesName);
          if (!isOtherNoteAvailable && otherTags.length > 0 && otherTags[0].setSelected) {
            const otherNotes: FieldConfig = {
              name: otherNotesName,
              type: 'textarea',
              inputType: 'text',
              label: 'notes',
              showHint: true,
              class: 'preferences__other-tags',
              charLength: 1000,
              isHidden: false
            }
            this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
            const prefrenceForm = this.guestBookService.guestForm.get('preferencesDetailsform') as UntypedFormGroup;
            prefrenceForm.addControl(otherNotesName, new UntypedFormControl(''));
          } else if (isOtherNoteAvailable) {
            if (otherTags[0].setSelected) {
              otherNotesControl[0].isHidden = false;
              const otherNotesText = this.dynamicForm.form.get(otherNotesName).value;
              const prefrenceForm = this.guestBookService.guestForm.get('preferencesDetailsform') as UntypedFormGroup;
              prefrenceForm.addControl(otherNotesName, new UntypedFormControl(otherNotesText));
            } else {
              this.config[preferredTagsIndex + 1].isHidden = true;
              const prefrenceForm = this.guestBookService.guestForm.get('preferencesDetailsform') as UntypedFormGroup;
              prefrenceForm.removeControl(otherNotesName);
            }
          }
        }
        this.config = [...this.config];
        this.dynamicForm.form.updateValueAndValidity();
        this.makeFormDirty();
      }
      this.ps.formValid$.next({
        isFormValid: this.guestBookService.guestForm.get('contactDetailsForm').valid &&
          this.dynamicForm.form.valid, currentTab: this.selectedIndex
      } as IFormValidDetails);
    }));
  }

  setEditData() {
    if (this.data) {
      const { PreferredServerId, PartnerName, PartnerBirthday, PreferredPageMethod } = this.data;
      this.dynamicForm.form.patchValue({
        preferredTable: this.guestBookService.selectedTables.Name,
        preferredServer: this.guestBookService.selectedServers ? this.guestBookService.selectedServers.Name : '',
        partnerName: PartnerName,
        partnerBirthday: PartnerBirthday ? this.setDateToCurrentYear(PartnerBirthday) : PartnerBirthday
      });
      const preferredTags = this.config.filter((fieldConfig) => fieldConfig.name.includes('preferredTags'));
      this.data.Notes?.forEach((note) => {
        preferredTags.forEach((fieldConfig) => {
          const filteredNotes = fieldConfig.options.find(data => data.Id === note.RelatedId);
          if (filteredNotes) {
            let currNoteId;

          if(!filteredNotes.noteId) {
            filteredNotes.setSelected = true;
            filteredNotes.noteId = currNoteId = note.Id;
          } else {
              if(!filteredNotes.extraNotesIds) 
                filteredNotes.extraNotesIds = [];
              currNoteId = note.Id;
              filteredNotes.extraNotesIds.push(note.Id);
          }
            if (filteredNotes.Name.toLowerCase() == 'others') {
              let otherNotesName = `otherNotes${filteredNotes.Id}`;
              if (filteredNotes.setSelected) {
                const otherNotes: FieldConfig = {
                  name: otherNotesName,
                  type: 'textarea',
                  inputType: 'text',
                  label: 'notes',
                  showHint: true,
                  class: 'preferences__other-tags',
                  charLength: 1000,
                  value: note.Text
                };
                const preferredTagsIndex = this.config.findIndex(preferenceConfig => preferenceConfig.name == fieldConfig.name);
                this.config.splice(preferredTagsIndex + 1, 0, otherNotes);
                const prefrenceForm = this.guestBookService.guestForm.get('preferencesDetailsform') as UntypedFormGroup;
                prefrenceForm.addControl(otherNotesName, new UntypedFormControl(note.Text));
                this.dynamicForm.form.addControl(otherNotesName, new UntypedFormControl(note.Text));
              }
            }
          }
        })
      });
      const freeNotes = this.data.Notes?.filter(note => !note.RelatedId);
      if (freeNotes && freeNotes.length) {
        this.config.unshift({
          type: 'chip',
          name: `freeTags`,
          inputType: 'text',
          label: 'freeNoteText',
          class: 'preferences__free-tags-edit', // Do not modify the className, there is a dependency for editGuest details.
          options: this.getFreeTextOptions(),
          multipleChip: true
        })
      }
      this.guestBookService.selectedCustomTags = [];
      const preferredFreeNoteTags = this.config.filter((fieldConfig) => fieldConfig.name.includes('freeTags'));
      preferredFreeNoteTags.forEach((tag) => {
        this.guestBookService.selectedCustomTags.push(...tag.options);
      });
      const freeTags = this.config.filter(config => config.name == 'freeTags');
      if (freeTags && freeTags.length) {
        freeNotes.forEach((freeNote) => {
          const filteredFreeNotes = freeTags[0].options.find((data) => data.Id === freeNote.Id);
          if (filteredFreeNotes) {
            filteredFreeNotes.setSelected = true;
          }
        });
      }
      this.config = [...this.config];
      const preferredCommunication = this.config.filter((fieldConfig) => fieldConfig.name == 'preferredCommunication')[0];
      preferredCommunication.options.forEach((data) => {
        if (data.Id === PreferredPageMethod) {
          data.setSelected = true;
        }
      });
    }
  }

  getFreeTextOptions() {
    let freeTextOptions = [];
    let freeNotes = this.data.Notes?.filter(notes => !notes.RelatedId);
    freeNotes.forEach((notes) => {
      freeTextOptions.push({
        Id: notes.Id,
        Name: notes.Text,
        Icon: 'None',
        Value: ''
      })
    })
    return freeTextOptions;
  }

  makeFormDirty() {
    this.guestBookService.guestForm.get('preferencesDetailsform').markAsDirty();
    this.guestBookService.guestForm.markAsDirty();
  }

  setDateToCurrentYear(date: Date) {
    date = new Date(date);
    let month = date.getMonth();
    let day = date.getDate();
    let dateInCurrentYear = new Date(new Date().getFullYear(), month, day);

    return dateInCurrentYear;
  }

  addPreferedServer() {
    const preferredServer = this.config.find((fieldConfig) => fieldConfig.name == 'preferredServer');
    preferredServer.value = this.guestBookService.selectedServers.Name;
    const componentDetails = {
      componentName: SelectServerComponent,
      dimensionType: 'large',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
    const serverDialog = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      width: '90%',
      height: '90%',
      autoFocus: false,
      data: {
        title: this.ts.instant('choosePreferredServer'),
        update: 'Ok',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.server,
        back: true,
        standalone: true,
        service:this.ps
      }
    });
    const confirmSubscription: ISubscription = this.ps.confirmedAction$.subscribe((data) => {
      if (data === ComponentTypes.server) {
        this.dynamicForm.form.controls.preferredServer.setValue(this.guestBookService.selectedServers.Name);
        if (this.guestBookService.selectedServers.Name) {
          this.makeFormDirty();
        }
      }
    });
    serverDialog.afterClosed().subscribe(() => {
      if (confirmSubscription) {confirmSubscription.unsubscribe();}
    })
  }

  addPreferedTable() {
    const componentDetails: ComponentDetails = {
      componentName: PreferredTableComponent,
      dimensionType: 'large',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: this.dynamicForm.form.controls.preferredTable.value
    };
    const tableDialog = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      panelClass: 'preferred-table-panel',
      data: {
        title: this.ts.instant('guestBookPrefTableText'),
        update: 'Ok',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.table,
        back: true,
        standalone: true,
        selectionView: true,
        service:this.ps
      }
    });
    const confirmSubscription: ISubscription = this.ps.confirmedAction$.subscribe((data) => {
      if (data === ComponentTypes.table) {
        this.guestBookService.selectedTables = _.cloneDeep(this.selectedTable);
        this.dynamicForm.form.controls.preferredTable.setValue(this.guestBookService.selectedTables.Name);
        if (this.guestBookService.selectedTables.Name) {
          this.makeFormDirty();
        }
      }
    });
    tableDialog.afterClosed().subscribe(() => {
      if (confirmSubscription) { confirmSubscription.unsubscribe();}
    })
  }

  clearTableData(event) {
    this.dynamicForm.form.markAsDirty();
    this.dynamicForm.form.get('preferredTable').setValue('');
    this.guestBookService.selectedTables = {};
  }
  clearServerData(event) {
    this.dynamicForm.form.markAsDirty();
    this.dynamicForm.form.get('preferredServer').setValue('');
    this.guestBookService.selectedServers = {} as ServerDTO;
  }

  cellClickTable(event) {
    this.standAloneTablesArray.forEach(table => {
      if (table.value == event.option.value) {
        this.guestBookService.selectedTables = table;
      }
    });
  }
  cellClickServer(event) {
    this.cs.settings.value.Servers.forEach(server => {
      if (server.Name == event.option.value) {
        this.guestBookService.selectedServers = server;
      }
    });
  }

  generatePreferenceDetailsForm() {
    this.preferenceDetailsForm = this.fb.group({
      preferedCommn: String[''],
      preferredTable: String[''],
      preferredServer: String[''],
      partnerName: String[''],
      partnerdob: ['', Validators.required],
    });
    this.guestBookService.guestForm.addControl('preferencesDetailsform', this.preferenceDetailsForm);
  }

  getCommunicationList() {
    if (this.cs.settings.value) {
      const communication = this.config.filter((fieldConfig) => fieldConfig.name == 'preferredCommunication')[0];
      communication.options = this.guestBookService.getCommunicationList();
      this.guestBookService.preferredCommunication = communication.options;
    }
  }

  ngOnDestroy() {
    this.guestBookService.preferredCommunication = [];
    this.guestBookService.selectedPreferenceTags = [];
    this.guestBookService.selectedTables = {};
    this.guestBookService.selectedServers = {} as ServerDTO;
    this.dfs.selectedCountryCode = null;
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.dynamicForm.form.reset();
  }

  setCommunicationPreferences() {
    const communication = this.config.filter((fieldConfig) => fieldConfig.name == 'preferredCommunication')[0];
    const selectedTags = communication.options.filter((option) => option.setSelected);

    if (selectedTags.length === 0) {
      communication.options[0].setSelected = true;
    } else {
      communication.options.forEach((option) => {
        const selectedOption = selectedTags[0].Id;
        if (option.Id === selectedOption) {
          option.setSelected = selectedTags[0].setSelected;
        }
      });
    }
  }

  setFormConfig() {
    this.guestNotes = Utilities.getRestaurantPredefinedContactNotes(this.cs.settings.value.Categories);
    this.config = [
      {
        type: 'chip',
        name: 'preferredCommunication',
        inputType: 'text',
        label: 'guestBookPreferredCommunicationText',
        class: 'preferences__preferred-commn',
        options: [],
        multipleChip: false
      },
      {
        type: 'autocomplete',
        label: 'guestBookPrefTableText',
        name: 'preferredTable',
        options: this.standAloneTablesArray,
        class: 'preferences__preferred-table',
        showErrorText: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.cellClickTable.bind(this),
        returnFunction: this.clearTableData.bind(this),
        // validation: [Validators.required],
        errorMessage: 'restaurantError',
        addBtnConfig: {
          type: 'addBtn',
          name: 'addBtn',
          cellClick: this.addPreferedTable.bind(this),
          class: 'preferences__add-table'
        }
      },
      {
        type: 'autocomplete',
        label: 'guestBookprefServerText',
        name: 'preferredServer',
        options: this.serverArray,
        class: 'preferences__preferred-server',
        showErrorText: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.cellClickServer.bind(this),
        returnFunction: this.clearServerData.bind(this),
        errorMessage: 'restaurantError',
        addBtnConfig: {
          type: 'addBtn',
          name: 'addBtn',
          cellClick: this.addPreferedServer.bind(this),
          class: 'preferences__add-server'
        },
      },
      {
        type: 'input',
        name: 'partnerName',
        inputType: 'text',
        label: 'guestBookPartnerNameText',
        containerClass: 'preferences__partner-name',
      },
      {
        type: 'date',
        name: 'partnerBirthday',
        inputType: 'text',
        label: 'guestBookPartnerBirthdayText',
        containerClass: 'preferences__partner-birthday',
        maxDate: new Date()
      }
    ];
    this.guestNotes.forEach((guestNote, index) => {
      this.config.unshift({
        type: 'chip',
        name: `preferredTags${index}`,
        inputType: 'text',
        label: guestNote.CategoryName,
        class: 'preferences__preferred-tags',
        options: this.getOptions(guestNote.contactNotes, guestNote.Color),
        multipleChip: true
      });
    });
  }

  getOptions(contactNotes, categoryColor): any {
    let configOptions = [];
    contactNotes.forEach((notes) => {
      configOptions.push({
        Id: notes.Id,
        Label: notes.Label,
        Name: notes.Text,
        Icon: notes.Icon,
        Value: '',
        color: categoryColor
      });
    })
    return configOptions;
  }
}
