<div class="activity-table ">
    <div class="config-section ">
        <div class="reset-options">
      
            <div class="activity-filter-options">
                <dynamic-form [config]="config" #configForm [ngClass]="{'template-view'  : hideDateFilter}"></dynamic-form>
                <activities-app-button [buttontype]="resetButton" (valueChange)='resetFields()'></activities-app-button>
                <ng-container *ngIf="!hideDateFilter">
                    <app-common-filters [menuList]="activityFilter" [selectedFilters]="selectedFilters" class="common-filter" (selectionChange)="filterSelection($event)"></app-common-filters>
                </ng-container>
            </div>
            <activities-app-button [buttontype]=" createButton" (valueChange)='createActivity()'></activities-app-button>
        </div>
    </div>
    <div *ngIf="dataSource" class="activity-list" [ngClass]="{'height-auto' : dataSource?.filteredData?.length == 0 }">
       <ng-scrollbar>
            <table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" matSort class="mat-elevation-z8 activity-table-view">
                <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 14px;"> {{ col | translate}} </th>
                    <ng-container *ngIf="col != 'Action' && col != 'Status' && col != 'auditlog'">
                        <td mat-cell *matCellDef="let element"> {{ element[col] }} </td>
                    </ng-container>
                    <ng-container *ngIf="col == 'Status'">
                        <td class="seat-grid-content" mat-cell *matCellDef="let element">
                            <div>
                                <div class="toggle-button">
                                    <label class="switch"  [ngClass]="{'expired': element.isExpired}" >
                                        <input class="switch-input" type="checkbox" [checked]="element?.Status" 
                                            (change)="toggleChange($event,element)" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="col == 'Action'">
                        <td class="seat-grid-content" mat-cell *matCellDef="let element">
                            <div class="actions-icon">
                            <i class="icon-edit1 pr-2 cursor" (click)="createActivity(element)" *ngIf="((rolesAndPermissionsType.ModifyActivity | rolesAndPermission) && !element.isExpired) || isTemplate"></i>
                            <i class="icon-qr_code_scanner_FILL0_wght400_GRAD0_opsz48 pr-2 cursor ml-2 mr-2" *ngIf="!isTemplate" (click)="loadQRCode(element)" ></i>
                            <i class="icon-function-delete pr-2 cursor"(click)="deleteItem(element)" *ngIf="element.enableDelete"></i>
                        </div>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="col == 'auditlog'">
                        <td class="seat-grid-content" mat-cell *matCellDef="let element">
                            <i class="icon-Auditlog2 pr-2 cursor" *ngIf="!isTemplate" (click)="showPartyAuditLog(element)"></i>
                        </td>
                    </ng-container>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" class="activity-table__header seat-grid-header">
                </tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="activity-table__row"></tr>
            </table>
        </ng-scrollbar>
    </div>
    <div class="no-sessions" *ngIf="dataSource?.filteredData?.length == 0"  >
        <img src="{{reservationUrl}}" alt="" class="no-reservations-img">
    <div class="no-data-found">
        {{'noDataFound' | translate}}
    </div>
</div>
</div>
