<div class="guest-view">
  <span class="guest-view__title seat-header-two">
    {{'guestDetailsText' | translate}} <div (click)='viewHistory()' *ngIf="!isAddNewGuest && isEditGuest"
      class="view-history"><span class="icon-eye mr-2"></span><u>{{'viewGuestDetails' | translate}}</u></div>
  </span>

  <div class="guest-view__edit-guest">
    <div class="guest-view__change-guest" *ngIf="!partyService.tryUndoNoShowReservation">
      <activities-app-button *ngIf="!isEditGuest && !isAddGuest" [buttontype]="guestSecondaryEdit" (valueChange)='editGuest()'>
      </activities-app-button>
    </div>
    <div class="guest-view__change-guest" *ngIf="!isEdit">
      <activities-app-button [buttontype]="guestSecondary" (valueChange)='changeGuest()'></activities-app-button>
    </div>
  </div>
  <div class="add-new-guest-form-conatiner">
    <ng-scrollbar>
    <div class="guest-view__all-details" [hidden]="!isEditGuest && !isAddNewGuest">
      <div class="guest-view__guest-info float-right pt-2">
        <div class="guest-view__profile-view">
          <!-- <a *ngIf="!guestDetails.Photo || guestDetails.Photo == ''" (click)="compressFile()"  class="guest-view__add-image">{{'guestBookAddImage' | translate}}</a> -->
          <span class="guest-view__image-view">
            <div class="guest-view__form-image">
              <span class="icon-VIP iconVIP seat-border-color" [class.iconVIPactive]="vipStatus"
                (click)="onVIPClick()"></span>
              <img class="guest-view__load-image" *ngIf="guestDetails?.Photo && guestDetails?.Photo != ''"
                [src]="'data:image/png;base64,'+guestDetails?.Photo" (click)="compressFile()" />
              <span *ngIf="!guestDetails?.Photo || guestDetails?.Photo == ''"
                class="guest-view__upload-image icon-avatar" (click)="compressFile()">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
              </span>
            </div>
          </span>
          <div class="guest-view__party-image">
            <a *ngIf="guestDetails?.Photo && guestDetails?.Photo != ''" (click)="deleteImage()"
              class="guest-view__edit-image pt-3">{{'guestBookRemoveImage' | translate}}</a>
          </div>
          <br />
        </div>
        <div class="guest-view__name-error" *ngIf="isErrorMessageAvailable">
          <span class="guest-view__error-msg rs-Roboto-Regular-14">{{ 'nameMandatory' | translate}}</span>
        </div>
        <span id="swapIcon" class="icon-repeat guest-view__swap seat-icon-color" [style.left.px]="setswapWidth()"
          *ngIf="(isEditGuest || isAddGuest) && showSwap" (click)="swapNames()"></span>
        <dynamic-form [config]="guestConfig" #guestDetailsForm>
        </dynamic-form>
            <dynamic-form [config]="retentionConfig" #retentionForm>
            </dynamic-form>
        <div class="rs-mt-12"></div>
      </div>
      <div *ngIf="!partyService.isNewGuestFromReservation"
        class="guest-view__guest-details seat-primary-bg-color seat-border-color">
        <div class="guest-view__guest-details-table">
          <div class="guest-view__guest-visits-info seat-panel-header seat-secondary-bg-color seat-border-color">
            {{statsInCurrentRest?.NumberOfVisits}}</div>
          <div class="guest-view__guest-visits-info seat-panel-header seat-secondary-bg-color seat-border-color">
            {{statsInCurrentRest?.NumberOfCancellations}}</div>
          <div class="guest-view__guest-visits-info seat-panel-header seat-secondary-bg-color seat-border-color">
            {{statsInCurrentRest?.NumberOfNoShows}}</div>
          <label class="guest-view__label-info seat-tertiary-text">{{'guestBookTotalVisitText' | translate}}</label>
          <label class="guest-view__label-info seat-tertiary-text">{{'guestSelectionCancellationsText' |
            translate}}</label>
          <label class="guest-view__label-info seat-tertiary-text">{{'guestSelectionNoShowText' | translate}}</label>
        </div>
        <div class="guest-view__guest-details-data">
          <div class="guest-view__guest-details-data-left ">
            <div class="guest-view__guest-details-data-details">
              <label for="last_visted"
                class="d-block mb-0 guest-view__info-key seat-labels">{{'guestBookLastVisitedDateText' |
                translate}}</label>
              <span class="data_value icon-Group-595">
                <span class="seat-secondary-text">{{guestLastVisitedDate ? (guestLastVisitedDate | formatSettingsDate:
                  cs.settings.value.General.DateFormat) :
                  ('never' | translate)}}</span>
              </span>
            </div>
            <div class="guest-view__guest-details-data-details">
              <label for="last_table" class="d-block mb-0 guest-view__info-key seat-labels">{{'lastTable' |
                translate}}</label>
              <span class="data_value icon-Group-595">
                <span class="seat-secondary-text">{{guestLastTable ? guestLastTable : ('-')}}</span>
              </span>
            </div>
            <div class="guest-view__guest-details-data-details">
              <label for="last_server" class="d-block mb-0 guest-view__info-key seat-labels">{{'lastServer' |
                translate}}</label>
              <span class="data_value icon-Group-595">
                <span class="seat-secondary-text">{{guestLastServer ? guestLastServer : ('-')}}</span>
              </span>
            </div>
          </div>
          <div class="w-50 float-left guest-view__guest-details-data-right pt-4">
            <table>
              <tbody>
                <tr>
                  <div class="guest-view__guest-details-data-details">
                    <label for="anniversary"
                      class="d-block mb-0 guest-view__info-key seat-labels">{{'guestSelectionAnniversaryText' |
                      translate}}</label>
                    <span class="data_value icon-Anniversary seat-secondary-text">
                      {{guestDetails?.Anniversary ? (guestDetails.Anniversary | formatSettingsDate: cs.settings.value.General.DateFormat) :
                      ('-') }}
                    </span>
                  </div>
                </tr>
                <tr>
                  <div class="guest-view__guest-details-data-details">
                    <label for="dob" class="d-block mb-0 guest-view__info-key seat-labels">{{'guestBookDetailsDOB' |
                      translate}}</label>
                    <span class="data_value icon-Birthday seat-secondary-text">
                      {{guestDetails?.Birthday ? (guestDetails.Birthday | formatSettingsDate: cs.settings.value.General.DateFormat) : ('-')
                      }}
                    </span>
                  </div>
                </tr>
                <label for="dob" *ngIf="selectedPreferenceTags?.length > 0"
                  class="d-block mb-0 guest-view__info-key seat-labels">{{'preferenceHeader' | translate}}</label>
                <tr *ngFor="let tags of selectedPreferenceTags">
                  <td class="guest-view__allergy_name seat-secondary-text" *ngIf="tags.setSelected">
                    <i class="icon-{{tags.Icon ? tags.Icon : 'Default'}} guest-view__tags-icon rs-mr-5"></i>
                    {{tags.Name}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="guest-view__selected-guest-accordion" *ngIf="!isEditGuest && !isAddNewGuest">
      <mat-accordion>
        <mat-expansion-panel class="guest-view__selected-guest-panel seat-primary-bg-color"
          [expanded]="secondaryContacts && secondaryContacts.length > 0 ? false : true"
          [ngClass]="{'guest-view__guest-panel-expanded': expansionPanel.panelOpenState }"
          (opened)="expansionPanel.panelOpenState = true" (closed)="expansionPanel.panelOpenState = false">
          <mat-expansion-panel-header>
            <div *ngIf="!expansionPanel.panelOpenState">
              <span
                class="rs-pr-7 seat-labels">{{guestDetails.GuestTitle | guestTitlePipe}} {{guestDetails.FirstName}} {{guestDetails.LastName}} <ng-container *ngIf="guestDetails?.AliasName"> ({{guestDetails?.AliasName}})</ng-container></span>
                
              <span class="seat-icon-color seat-labels rs-pr-7" *ngIf="guestDetails.PhoneNumber">
                | <i class="icon-ic_call_24px rs-pr-7 rs-pl-7"></i>{{ guestDetails.PhoneNumber ? (
                guestDetails.PhoneNumber |
                phoneNumberFormatter: guestDetails.CountryId) : 'NA'}}</span>
              <span class="seat-labels" *ngIf="guestDetails.EmailAddress"> |
                <i class="icon-ic_mail_outline_24px rs-pr-7 rs-pl-7"></i> {{guestDetails.EmailAddress}}</span>
            </div>
            <div *ngIf="expansionPanel.panelOpenState">
              <div class="guest-view__data-header">
                {{guestDetails.GuestTitle | guestTitlePipe}} {{guestDetails.FirstName}} {{guestDetails.LastName}}<ng-container *ngIf="guestDetails?.AliasName"> ({{guestDetails?.AliasName}})</ng-container></div>
            </div>
            
          </mat-expansion-panel-header>
          <div class="guest-view__selected-guest seat-primary-bg-color">
            <div class="guest-view__profile-data__container seat-border-color">
              <div class="guest-view__profile-data">
                <div class="guest-view__memberShip seat-secondary-text" *ngIf="guestDetails.TrackMembershipNumber">
                  <span class="guest-view__memberShipName">{{_settings.General.MembershipNumberName}}</span>
                  <span class="guest-view__memberShipValue">:{{guestDetails.TrackMembershipNumber}} </span>
                  <span class="guest-view__memberShipName"
                    *ngIf="_settings.General.EngageIntegrationDTO?.IsEnabled === true && (guestDetails.MembershipType?.AllocationName || guestDetails.MemebershipTypeFromEngage)"> (
                    {{guestDetails.MembershipType?.AllocationName ? guestDetails.MembershipType?.AllocationName
                    :guestDetails.MemebershipTypeFromEngage }} ) </span>
                  <span class="text-danger"
                    *ngIf="partyService.selectedGuest?.MembershipType && !partyService.selectedGuest?.MemberActive">
                    - {{'Inactive'
                    | translate}}</span>
                    <span class="guest-view__memberShipValue">, {{'rateType' | translate}}: {{guestDetails.RateType}} </span>
                </div>
                <div *ngIf="guestDetails.PhoneNumber"><span
                    class="icon-ic_call_24px guest-view__display-icons seat-icon-color"></span>
                  <span class="seat-secondary-text">{{ guestDetails.PhoneNumber ? ( guestDetails.PhoneNumber |
                    phoneNumberFormatter: guestDetails.CountryId) : ('NA' | translate)}}</span>
                </div>
                <div *ngIf="guestDetails.PhoneNumber2"><span
                    class="icon-ic_call_24px guest-view__display-icons seat-icon-color"
                    [ngClass]="{'guest-view__phone-color': partyEmailAndPhoneInfo.GuestReservationPhoneNumber}"></span>
                  <span class="seat-secondary-text">{{ guestDetails.PhoneNumber2 ? ( guestDetails.PhoneNumber2 |
                    phoneNumberFormatter: guestDetails.CountryId2) : ('NA' | translate)}}</span>
                </div>
                <div *ngIf="!guestDetails.PhoneNumber && !guestDetails.PhoneNumber2">
                  <span class="icon-ic_call_24px guest-view__display-icons seat-icon-color"></span>
                  <span class="missing-text seat-secondary-text">{{'MissingPhoneNumber' | translate}}</span>
                </div>
                <div class="content-text-ellipsis" *ngIf="guestDetails.EmailAddress"><span
                    class="icon-ic_mail_outline_24px guest-view__display-icons seat-icon-color"></span>
                  <span class="seat-secondary-text">{{guestDetails.EmailAddress}}</span>
                </div>
                <div class="content-text-ellipsis" *ngIf="guestDetails.EmailAddress2">
                  <span class="icon-ic_mail_outline_24px guest-view__display-icons seat-icon-color"
                    [ngClass]="{'guest-view__email-color': partyEmailAndPhoneInfo.GuestReservationEmail}"></span>
                  <span class="seat-secondary-text">{{guestDetails.EmailAddress2}}</span>
                </div>
                <div class="content-text-ellipsis" *ngIf="!guestDetails.EmailAddress && !guestDetails.EmailAddress2">
                  <span class="icon-ic_mail_outline_24px guest-view__display-icons seat-icon-color"></span>
                  <span class="missing-text seat-secondary-text">{{'MissingEmailAddress' | translate}}</span>
                </div>
                <div *ngIf="guestDetails.Birthday">
                  <span class="icon-Birthday guest-view__display-icons seat-icon-color"></span>
                  <span class="seat-secondary-text">{{'birthdayText' | translate}}:
                    {{ guestDetails.Birthday | formatSettingsDate:
                    cs.settings.value.General.DateFormat }}
                  </span>
                </div>
                <div *ngIf="guestDetails.Anniversary">
                  <span class="icon-Anniversary guest-view__display-icon-anniversary seat-icon-color"></span>
                  <span class="seat-secondary-text"> {{'anniversaryText' | translate}}:
                    {{ guestDetails.Anniversary | formatSettingsDate:
                    cs.settings.value.General.DateFormat }}
                  </span>
                </div>
              </div>
              <div class="avatar">
                <span class="icon-VIP iconVIPactive iconVIP seat-border-color seat-secondary-bg-color"
                  *ngIf="guestDetails.IsVip"></span>
                <img *ngIf="guestDetails?.Photo && guestDetails?.Photo != ''"
                  [src]="'data:image/png;base64,'+guestDetails?.Photo" alt="profile_pic"
                  class="userprofile__user-image">
                <div *ngIf="!guestDetails?.Photo || guestDetails?.Photo === ''" class="guest-initial"
                  [ngStyle]="{'background-color': '' | randomColor}">{{guestDetails | guestNameFormatter}}</div>

                <!------------------ Commented and must show when we get the no. of notifications -------------------->
                <!-- <span class="userprofile__notification rs-Roboto-Regular-12" *ngIf="totalVisits > 0">{{totalVisits}}</span> -->
              </div>
            </div>
            <div class="guest-view__free-notes" *ngIf="isFreeTextTagAvailable">
              <span class="seat-header-three">{{ 'freeNoteText' | translate}}</span>
              <div class="seat-tertiary-text guest-view__notes rs-pl-15" *ngFor="let notes of freeTextNotes">
                <li>{{notes.Text}}</li>
              </div>
            </div>
            <div class="guest-view__guest-notes" *ngIf="selectedNotesByCategory?.length > 0">
              <div *ngFor="let notesCategory of selectedNotesByCategory;let first = first; let last = last;">
                <div *ngIf="notesCategory?.notes?.length > 0" [ngStyle]="{'border-bottom': last ? '1px solid' : ''}"
                  class="seat-secondary-text seat-border-color guest-view__category">
                  <span class="seat-header-three"
                    *ngIf="notesCategory?.notes?.length > 0">{{notesCategory.category}}</span>
                  <div class="seat-tertiary-text guest-view__notes" *ngFor="let notes of notesCategory.notes">
                    <li>{{notes.Text}}</li>
                  </div>
                </div>
              </div>
            </div>
            <div class="guest-view__guest-summary">
              <div *ngIf="isStatsInCurrentRest">
                <div class="guest-view__data-header seat-header-three">{{'guestValueSummaryText' | translate}}</div>
                <div class="seat-secondary-text"><strong
                    class="strongcls">{{statsInCurrentRest?.NumberOfVisits}}</strong>
                  {{'guestBookTotalVisitText' | translate}},
                  <strong class="strongcls">{{statsInCurrentRest?.NumberOfVisitsLast30Days}}</strong> {{'inlast30days' |
                  translate}}
                </div>
                <div class="seat-secondary-text"><strong
                    class="strongcls">{{statsInCurrentRest?.NumberOfNoShows}}</strong>
                  {{'guestSelectionNoShowText' | translate}},
                  <strong class="strongcls">{{statsInCurrentRest?.NumberOfNoShowsLast30Days}}</strong> {{'inlast30days'
                  |
                  translate}}
                </div>
                <div class="seat-secondary-text"><strong
                    class="strongcls">{{statsInCurrentRest?.NumberOfCancellations}}</strong>
                  {{'guestSelectionCancellationsText' | translate}},
                  <strong class="strongcls">{{statsInCurrentRest?.NumberOfCancellationsLast30Days}}</strong>
                  {{'inlast30days' |
                  translate}}
                </div>
                <div class="seat-secondary-text">{{'lastVisit' | translate}}:
                  {{statsInCurrentRest.LastVisitedAt ? (statsInCurrentRest.LastVisitedAt | formatSettingsDate:
                  cs.settings.value.General.DateFormat) : ('never' | translate)}}</div>
                <div class="seat-secondary-text">{{'lastTable' | translate}}: {{statsInCurrentRest?.LastTableNames?
                  statsInCurrentRest?.LastTableNames : ('none' | translate)}}</div>
                <div class="seat-secondary-text">{{'lastServer' | translate}}: {{statsInCurrentRest?.LastServerName?
                  statsInCurrentRest?.LastServerName : ('none' | translate)}}</div>
              </div>
              <div *ngIf="!isStatsInCurrentRest">
                <p class="missing-text seat-no-content-found">
                  {{'nostatscurrentrestaurant' | translate}}
                </p>
              </div>
              <hr>
              <mat-accordion *ngIf="isStatsInOtherRest">
                <mat-expansion-panel [expanded]="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="seat-section-header">{{'guestHistoryText' | translate}}</div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <br>
                  <div class="rs-pt-10" *ngFor="let stats of statsInOtherRests">
                    <div class="seat-header-three">{{stats.RestaurantName}}</div>
                    <div class="seat-secondary-text"><strong class="strongcls">{{stats.NumberOfVisits}}</strong>
                      {{'guestBookTotalVisitText' | translate}},
                      <strong class="strongcls">{{stats.NumberOfVisitsLast30Days}}</strong> {{'inlast30days' |
                      translate}}
                    </div>
                    <div class="seat-secondary-text"><strong class="strongcls">{{stats.NumberOfNoShows}}</strong>
                      {{'guestSelectionNoShowText' | translate}},
                      <strong class="strongcls">{{stats.NumberOfNoShowsLast30Days}}</strong> {{'inlast30days' |
                      translate}}
                    </div>
                    <div class="seat-secondary-text"><strong class="strongcls">{{stats.NumberOfCancellations}}</strong>
                      {{'guestSelectionCancellationsText' | translate}},
                      <strong class="strongcls">{{stats.NumberOfCancellationsLast30Days}}</strong> {{'inlast30days' |
                      translate}}
                    </div>
                    <div class="seat-secondary-text">{{'lastVisit' | translate}}:
                      {{stats.LastVisitedAt ? (stats.LastVisitedAt | formatSettingsDate:
                      cs.settings.value.General.DateFormat) : ('never' | translate)}}</div>
                    <div class="seat-secondary-text">{{'lastTable' | translate}}: {{stats.LastTableNames?
                      stats.LastTableNames:('none' | translate)}}</div>
                    <div class="seat-secondary-text">{{'lastServer' | translate}}: {{stats.LastServerName?
                      stats.LastServerName:('none' | translate)}}</div>
                    <hr>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div [hidden]="!AllowAddingSecondaryGuest" class="guest-view__secondary-guest"
      *ngIf="this.partyService.bookingSize > 1 || secondaryContacts?.length > 0">
      <label class="seat-header-two rs-pb-10">{{'additionalGuest' | translate}}</label>
      <div class="guset-view__secondary-guest-details rs-pb-10"
        *ngFor="let secondaryGuest of secondaryContacts; let i = index;">
        <app-view-secondary-guest-details [secondaryContactDetails]="secondaryGuest"
          [countryCode]="guestDetails.CountryId" [guestCount]="i+1" [isEdit]="secondaryContacts.length > 0"
          (validateSecondaryGuest)="validateSecondaryGuest($event)"
          (deleteSecondaryGuest)="deleteSecondaryGuest($event)"></app-view-secondary-guest-details>
      </div>
        <activities-app-button *ngIf="(AllowAddingSecondaryGuest  && secondaryContacts )" [buttontype]="addSecondaryGuestButton"
          (valueChange)='addSecondaryGuest()'></activities-app-button>
    </div>
 
  </ng-scrollbar>
  </div>
</div>
<div class="mt-3 payer-details pt-2 d-flex justify-content-end">
  <app-payee-info class="payee-info"  *ngIf="this.partyService.ratePlanObject && this.partyService.ratePlanObject?.['PayerDetails']?.length"  [bookingCreditCard] ="this.bookingCreditCard" [ratePlanObject]="partyService.ratePlanObject" [payeeDetails]="this.partyService.ratePlanObject['PayerDetails']" [bookingData]="null" [bookingDataId]="null" (selectedPayeeCardInfo)="selectedPayeeCard($event)"></app-payee-info>
</div> 