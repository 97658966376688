export enum ReservationSource {
    POS = 6
}

export enum ClassType {
    BySession = 0,
    ByClass = 1
}

export enum PricingCategory {
    BySession = 1,
    ByDuration
}

export enum BookingBehavior {
    OpenBooking,
    ClassOrSession,
    PrivateLesson,
    Addon
}

export enum ConfigTheme{
    POS = "076063",
    CLIENT = '1DA664'
}