import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { ComponentTypes } from '@constants/commonenums';
import { FloorPlanViewDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { PopupService } from '@popup-module/popup.service';
import { FloorplanService } from '@services/floorplan.service';
import { ISubscription, Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-editor-floors',
  templateUrl: './editor-floors.component.html',
  styleUrls: ['./editor-floors.component.scss']
})
export class EditorFloorsComponent implements OnInit, OnDestroy {

  floorPlanViews: FloorPlanViewDTO[];
  confirmedSubscription: ISubscription;
  cancelledSubscription: ISubscription;
  dialogRefClosedSubscription: ISubscription;
  dataChangedSubscription: ISubscription;
  floorPlanDetails: MatTableDataSource<FloorPlanViewDTO>;
  tableColumns: string[] = ['floor', 'action'];
  subscriptions: Subscription = new Subscription();

  constructor(private fs: FloorplanService, private ps: PopupService, private dialog: MatDialog, private ts: TranslateService) { }

  ngOnInit() {
    if (this.fs.floorPlan) {
      this.floorPlanViews = this.fs.floorPlan.FloorPlanViews;
      this.floorPlanDetails = new MatTableDataSource(this.floorPlanViews);
      this.fs.selectedFloorId = this.fs.floorPlan.FloorPlanViews[0].Id;
      this.fs.selectedFloor$.next(this.fs.selectedFloorId);
    }
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.fs.dataChanged$.subscribe(val => {
      this.floorPlanViews = this.fs.floorPlan.FloorPlanViews;
      this.floorPlanDetails = new MatTableDataSource(this.floorPlanViews);
    }));
  }

  deleteFloorPlan(index) {
    let id = this.fs.floorPlan.FloorPlanViews[index].Id;
    let tablesMapped = this.fs.floorPlan.StandaloneTables.filter(x => x.FloorPlanViewId == id);
    let wallsMapped = this.fs.floorPlan.Walls.filter( x => x.FloorPlanViewId == id);
    let shapesMapped = this.fs.floorPlan.Shapes.filter( x => x.FloorPlanViewId == id);
    let labelsMapped = this.fs.floorPlan.Labels.filter( x => x.FloorPlanViewId == id);
    if (tablesMapped.length || wallsMapped.length || shapesMapped.length || labelsMapped.length) {
      this.openDeleteConfirmation(index, id);
    }
    else {
      this.fs.floorPlan.FloorPlanViews.splice(index, 1);
      this.fs.objectDeleted$.next('data');
      this.fs.dataChanged$.next('changed');
    }
  }
  selectedFloorPlan(floor) {
    this.fs.selectedFloorId = floor.Id;
    this.fs.selectedFloor$.next(this.fs.selectedFloorId);
  }

  onFloorPlanViewNameChanges(index, eve) {
    this.fs.floorPlan.FloorPlanViews[index].Name = eve.target.innerText;
    this.fs.objectDeleted$.next('nameChangeEvent');
  }


  openDeleteConfirmation(index, id) {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant('deleteFloorConfirmation'),
      dialogTitle: 'removeFloor'
    }];
    let keepObjects = false;
    let removeObjects = false;
    let componentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      // ""
    };

    let dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: 'auto',
      width: '450px',
      data: {
        title: 'removeFloor',
        update: 'keepObjects',
        cancel: 'removeObjects',
        componentDetails,
        back: false,
        from: 1,
        standalone: true
      }
    });

    this.dialogRefClosedSubscription = dialogRef.afterClosed().subscribe(() => {
      this.fs.selectedFloorId = this.fs.floorPlan.FloorPlanViews[index].Id;
      if (keepObjects) {
        this.fs.floorPlan.StandaloneTables.forEach(element => {
          if (element.FloorPlanViewId == this.fs.floorPlan.FloorPlanViews[index].Id) {
            element.FloorPlanViewId = null;
          }
        });
        this.fs.floorPlan.FloorPlanViews.splice(index, 1);
        this.fs.deleteSelectedFloorPlanTables$.next({ value: false, id });
        this.fs.objectDeleted$.next('data')
        this.fs.dataChanged$.next('changed');
      } else if (removeObjects) {
        this.fs.deleteSelectedFloorPlanTables$.next({ value: true, id });
        let standaloneTablesIds = [];
        this.fs.floorPlan.StandaloneTables.forEach((element, i) => {
          if (element.FloorPlanViewId === this.fs.floorPlan.FloorPlanViews[index].Id) {
            standaloneTablesIds.push(element.Id);
          }
        });
        this.fs.floorPlan.StandaloneTables = this.fs.floorPlan.StandaloneTables.filter(table => !standaloneTablesIds.includes(table.Id));
        this.fs.floorPlan.Walls = this.fs.floorPlan.Walls.filter(element => element.FloorPlanViewId != this.fs.floorPlan.FloorPlanViews[index].Id);
        this.fs.floorPlan.Labels = this.fs.floorPlan.Labels.filter(element => element.FloorPlanViewId != this.fs.floorPlan.FloorPlanViews[index].Id);
        this.fs.floorPlan.Shapes = this.fs.floorPlan.Shapes.filter(element => element.FloorPlanViewId != this.fs.floorPlan.FloorPlanViews[index].Id);
        this.fs.floorPlan.FloorPlanViews.splice(index, 1);
        this.fs.objectDeleted$.next('data');
        this.fs.dataChanged$.next('changed');
      }
      if (this.cancelledSubscription) {
        this.cancelledSubscription.unsubscribe();
      }
      if (this.confirmedSubscription) {
        this.confirmedSubscription.unsubscribe();
      }
    });

    this.confirmedSubscription = this.ps.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.reservation) {
        keepObjects = true;
        removeObjects = false;
      }
    });

    this.cancelledSubscription = this.ps.cancelledAction$.subscribe(val => {
      if (val.value === ComponentTypes.reservation) {
        removeObjects = true;
        keepObjects = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.cancelledSubscription) {
      this.cancelledSubscription.unsubscribe();
    }
    if (this.confirmedSubscription) {
      this.confirmedSubscription.unsubscribe();
    }
    if (this.dialogRefClosedSubscription) {
      this.dialogRefClosedSubscription.unsubscribe();
    }
    if (this.dataChangedSubscription) {
      this.dataChangedSubscription.unsubscribe();
    }
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

}
