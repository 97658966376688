<mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm" [formGroup]="form" [attr.automationId]="automationId">
    <input matInput
           [formControlName]="formControlName" 
           type="text"
           [matAutocomplete]="auto"
           [required]="isRequired"
           [placeholder]="captions.country"
           inputtype="textonly,nospecailchar,noprespace"
           [maxlength]="20"
           autocomplete="off"
           (blur)="clearOutCountry($event)"
           name="Country">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let country of filteredCountries | async" [value]="country.CountryName" (mousedown)=$event.preventDefault()  [matTooltip]="country.CountryName">
        {{country.CountryName}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="!form.get(formControlName).value && form.get(formControlName).hasError('required')">
      {{captions.missingCountryName}}
    </mat-error>
    <mat-error *ngIf="form.get(formControlName).value && form.get(formControlName).hasError('invalid')">
      {{captions.invalidCountryName}}
    </mat-error>
  </mat-form-field>