import { Pipe, PipeTransform } from '@angular/core';
import { ReservationDTO } from '@models/InputContact';
import _ from 'lodash';

@Pipe({
  name: 'partyDataFilter'
})
export class PartyDataFilterPipe implements PipeTransform {

  transform(reservations: ReservationDTO[], searchString: string): any[] {

    if (reservations.length == 0 || !searchString) {
      return reservations;
    }
    return reservations.filter(res =>
      ((res.Contact.FirstName && res.Contact.FirstName.toLowerCase().includes(searchString.toLowerCase())) ||
        (res.Contact.LastName && res.Contact.LastName.toLowerCase().includes(searchString.toLowerCase()))) || (res.Contact.FirstName && res.Contact.LastName && (this.getConcatedName(res.Contact.FirstName, res.Contact.LastName).includes(searchString.toLowerCase()))) ||
      (res.Contact.PhoneNumber && res.Contact.PhoneNumber.toLowerCase().includes(searchString.toLowerCase())) ||
      (res.Contact.EmailAddress && res.Contact.EmailAddress.toLowerCase().includes(searchString.toLowerCase())) ||
      (res.ConfirmationCode && res.ConfirmationCode.toLowerCase().includes(searchString.toLowerCase())) ||
      (res.BookingReferenceId && res.BookingReferenceId.toLowerCase().includes(searchString.toLowerCase())) ||
      (res.Contact.PhoneNumber2 && res.Contact.PhoneNumber2.toLowerCase().includes(searchString.toLowerCase())) ||
      (res.Contact.EmailAddress2 && res.Contact.EmailAddress2.toLowerCase().includes(searchString.toLowerCase()))
    );
  }

  getConcatedName(firstName?: string, LastName?: string) {
    if (firstName && LastName) {
      return firstName.toLowerCase() + ' ' + LastName.toLowerCase();
    }
    return '';
  }
}

