import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatExpiryDate'
})
export class FormatExpiryDatePipe implements PipeTransform {
  transform(expirationYearMonth: string): string {
    if (!expirationYearMonth || expirationYearMonth.trim().length !== 6) {
      return expirationYearMonth;
    } else {
      const year = expirationYearMonth.substring(2, 4);
      const month = expirationYearMonth.substring(4, 6);
      return `${month}/${year}`;
    }
  }
}
