<section class="SDS" [ngClass]="{'name-height' : showFullData}">
  <div class="clearfix data-section">
    <div *ngIf="this._ss.selectedclient" class="float-left pr-5 customSummaryWidth">
      <label class="d-block LWB16" *ngIf="!showFullData">{{localization.captions.shop.ClientDetails}}</label>
      <div class="ag_display--flex">
      <div class="mr-2" *ngIf="showProfilePicture">
        <ag-img-thumbnail  [memberImageUrl]="this._ss.memberImageUrl" [imageContent]="thumbnailImageData" [thumbnail]="false" imageType="client"></ag-img-thumbnail>
      </div>
      <div class="ag_display--flex align-items-center">
        <label class="d-block LW16" matTooltip="{{this._ss.selectedclient['name'] }}" >{{this._ss.selectedclient?.name }} </label>
        <i *ngIf="this._ss.selectedclient['vip']" aria-hidden="true" class="icon-vip ml-1 mb-2 pr-3"></i>
        <label class="d-block LW16" matTooltip="{{this._ss.selectedclient['address']}}" >{{this._ss.selectedclient?.address}}</label>
        <label class="d-block LW16" matTooltip="{{this._ss.selectedclient['city']}}"  >{{this._ss.selectedclient?.city}}</label>
        <label class="d-block LW16" matTooltip="{{this._ss.selectedclient['country']}}">{{this._ss.selectedclient?.country}}</label>
      </div>
    </div>
    </div>
    <div  class="float-left  pr-5 customSummaryWidth">
      <label class="d-block LWB16" *ngIf="false">{{localization.captions.shop.stayDetails}}</label>
      <label class="d-block LW16"> </label>
      <label class="d-block LW16"></label>
      <label class="d-block LW16"> </label>
    </div>
  </div>
  <i [attr.automationId]="'Icn_shopDetailsSection_showOrHide'" aria-hidden="true" class="icon-Up-Arrow accordian-icon float-left" [ngClass]="{'rotate-icon': showFullData}" (click)= "showOrHide()"></i>
</section>
