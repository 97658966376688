export class RetailErrorCode {
  static ITEM_NUMBER_MISSING = '330';
  static DESCRIPTION_MISSING = '331';
  static CATEGORY_MISSING = '332';
  static OUTLET_MISSING = '333';
  static OUTLET_NOT_EXISTS = '334';
  static OUTLET_SWITCH_MISSING = '335';
  static INVALID_OUTLET_SWITCH = '336';
  static ITEM_NUMBER_SHOULD_BE_NUMERIC = '337';
  static ITEM_NUMBER_SHOULD_NOT_BE_EXPONENTIAL = '338';
  static CANT_ENABLE_BOTH_GROUPING_KEY_AND_SCALED_ITEM = '339';
  static CANT_ENABLE_BOTH_GROUPING_KEY_SCALED_ITEM_AND_MULTIPACK = '70400';
  static SERVICE_CHARGE_OR_GRATUITY_NOT_APPLICABLE_FOR_SELECTED_RETAIL_ITEM_TYPE = '70401';
  static CANT_CONFIG_COMMISSION_FOR_MULTIPACK_OR_DEPOSIT_ITEMTYPE = '70402';
  static CANT_ENABLE_BOTH_GROUPING_KEY_AND_MULTIPACK = '70404';
  static CANT_ENABLE_BOTH_SCALED_ITEM_AND_MULTIPACK = '70403';
  static DUPLICATE_ITEM_NUMBER = '10111';
  static DUPLICATE_BARCODE = '10112';
  static DUPLICATE_SECONDARY_BARCODE = '10113';
  static COMMISION_NOT_APPLICABLE_FOR_SELECTED_TYPE = '70602';
  static COMMISION_PERCENTAGE_NOT_APPLICABLE_FOR_SELECTED_TYPE = '70603';
  static CANT_ENABLE_GROUPINGKEY_OPENITEM = '70604';
  static CANT_ENABLE_MULTIPACK_OPENITEM = '70605';
  static CANT_ENABLE_SCALEDITEM_OPENITEM = '70606';
  static QTY_CANT_BE_DECIMAL = '70607';
  static ITMPAR_CANT_BE_DECIMAL = '70608';
  static RETAIL_ITEM_TYPE_INVALID = '70610';
  static ITEM_NUMBER_CANT_BE_DECIMAL = '70616';
  static INVALID_VENDOR = '70618';
  static INVALID_SUPPLIERITEM_NUMBER = '70619';
  static SUPPLIERITEM_NUMBER_SHOULD_BE_ALPHANUMERIC = '70620';
}

export enum RetailItemType {
  RetailItemRetailPOSOnly = '1',
  RetailItemAvailableForSpaPackages = '2',
  SpaServices = '3',
  SpaPackage = '4',
  AppointmentAddon = '5',
  Deposit = '6',
  RentalItem = '7',
  InternalGiftCard = '8',
  ExternalGiftCard = '9',
  RevenueItem = '10',
  CustomFee = '11',
  CancellationFee = '12',
  NoShowFee = '13',
  PMSAddOnsRentalItem = '14',
  Classes = '15',
  Lockers = '16'
}

export enum ToggleState {
  Enable = '1',
  Disable = '0'
}

export enum ImportRetailitemsColumn {
  ID='ID',
  ItemDescription = 'ITEM DESCRIPTION',
  ItemNumber = 'ITEM NUMBER',
  Price = 'SALES PRICE',
  Cost = 'UNIT COST',
  BarCode = 'BAR CODE',
  SecondaryBarcode = 'SECONDARY BAR CODE',
  UnitOfMeasure = 'UNIT OF MEASURE',
  Category = 'CATEGORY',
  SubCategory1 = 'SUB CATEGORY 1',
  SubCategory2 = 'SUB CATEGORY 2',
  SubCategory3 = 'SUB CATEGORY 3',
  SubCategory4 = 'SUB CATEGORY 4',
  SubCategory5 = 'SUB CATEGORY 5',
  CommissionPercent = 'COMMISSION PERCENT',
  Quantity = 'QUANTITY',
  OutletCode = 'OUTLET CODE',
  OutletSwitch = 'OUTLET SWITCH',
  Tax = 'TAX',
  ItemType = 'ITEM TYPE',
  CommissionableItem = 'COMMISSIONABLE ITEM',
  CommissionRequired = 'COMMISSION REQUIRED',
  AllowEarn = 'ALLOW EARN',
  Vendor = 'VENDOR NAME',
  VendorId = 'VENDOR ID',
  SupplierItemNumber = 'SUPPLIER ITEM #',
  UseInventory = 'USE INVENTORY',
  GroupingKey = 'GROUPING KEY',
  RequestName = 'REQUEST NAME',
  PMSPackageItem = 'PMS PACKAGE ITEM',
  RequiredComments = 'REQUIRED COMMENTS',
  OpenItems = 'OPEN ITEMS',
  Multipacks = 'MULTIPACKS',
  Gratuity = 'GRATUITY',
  ServiceCharge = 'SERVICE CHARGE',
  ItemPar = 'ITEM PAR',
  ErrorMessage = 'ERROR MESSAGE',
  ErrorCode = 'ERROR CODE',
  DisposableItem = 'DISPOSABLE ITEM',
  SmallSticker = 'SMALL STICKER'
}
export enum RentalItemErrorCode{
  RentalItemInUseErrorCodeDelete = 260030010,
} 

