import { Injectable, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GuestBookService } from '@services/guestbook.service';
import { HttpService } from '@services/http.service';
import { PartyService } from '@services/party.service';
import { SettingsService } from '@services/settings.service';

@Injectable({
  providedIn: 'root'
})

export class FacadeService {

  // HTTP Service Injection
  private _httpService: HttpService;
  public get httpService(): HttpService {
    if (!this._httpService) {
      this._httpService = this.injector.get(HttpService);
    }
    return this._httpService;
  }

  private _partyService: PartyService;
  public get partyService(): PartyService {
    if (!this._partyService) {
      this._partyService = this.injector.get(PartyService);
    }
    return this._partyService;
  }

  private _guestbookService: GuestBookService;
  public get guestbookService(): GuestBookService {
    if (!this._guestbookService) {
      this._guestbookService = this.injector.get(GuestBookService);
    }
    return this._guestbookService;
  }

  private _settingService: SettingsService;
  public get settingService(): SettingsService {
    if (!this._settingService) {
      this._settingService = this.injector.get(SettingsService);
    }
    return this._settingService;
  }

  constructor(private injector: Injector) { }
  
  // getDashBoardPartyStatusAndMsgCounts(partyType, date) {
  //   return this.partyService.getDashboardStatusAndMessages(partyType, date);
  // }

  // GuestBook Services Wrapper Function Calls
  getContacts(skip, take, isRecent, isVIP, isFavourite, isFeedback, searchQuery, sortBy, isRoomNumberSearchEnabled) {
    return this.guestbookService.getContacts(skip, take, isRecent, isVIP, isFavourite, isFeedback, searchQuery, sortBy, isRoomNumberSearchEnabled);
  }

  getContactSuggestion(searchString: string, isRoomNumberSearch: boolean,pageNumber = 0,pageSize = 10 , directCGPS:boolean = false,isStayConfirmation:boolean = false, isPMSSearch:boolean = false) {
    return this.guestbookService.getContactSuggestion(searchString, isRoomNumberSearch,pageNumber,pageSize , directCGPS, isStayConfirmation, isPMSSearch)
  }
  getContactExSuggestion(firstName?:string,lastName?:string,phoneNumber?:string,emailAddress?:string,membershipCode?:string,aliasname?:string,pageNumber?:number,pageSize?:number) {
    return this.guestbookService.getContactExSuggestion(firstName,lastName,phoneNumber,emailAddress,membershipCode,pageNumber,pageSize,aliasname);
  }


  getPredefinedPartyTags() {
    return this.guestbookService.getPredefinedPartyTags();
  }

  getCommunicationList() {
    return this.guestbookService.getCommunicationList();
  }

  getBasicContactInformation(contactId: number, partyId: number = null) {
    return this.guestbookService.getBasicContactInformation(contactId, partyId);
  }

  getContact(contactId: number, partyId: number = null) {
    return this.guestbookService.getContact(contactId, partyId);
  }
  GetItineraryByProfile(tenantId, profileUuid,profileId,pageSize = 100,pageNumber = 0, pageDataType = 0) {
    return this.guestbookService.GetItineraryByProfile(tenantId, profileUuid,profileId,pageSize,pageNumber,pageDataType);
  }

  NotifyItinerary(itineraryNotifyRequest){
    return this.guestbookService.NotifyItinerary(itineraryNotifyRequest);
  }
  getCommonProfileContact(primaryGuestId :string = null , CommonGuestProfileUUID:string = null){
    return this.guestbookService.getCommonGuestProfile(primaryGuestId,CommonGuestProfileUUID);
  }
  getProfilePreference(profileuuid: any) {
    return this.guestbookService.getProfilePreference(profileuuid);
  }
  updateGuest(isConfirmationNeeded: boolean, guestData, contactDialogRef: MatDialogRef<any>) {
    return this.guestbookService.updateGuest(isConfirmationNeeded, guestData, contactDialogRef);
  }

  resetGuestForm() {
    return this.guestbookService.resetGuestForm();
  }

  setGuestBookTabsModel(tabsModal: any) {
    return this.guestbookService.setTabsModel(tabsModal);
  }

  getPartyAuditLog(partyId: number) {
    return this.partyService.getPartyAuditLog(partyId);
  }

  getActivityAuditLog(activityId: number) {
    return this.partyService.getActivityAuditLog(activityId);
  }

  getActivityBlockAuditLog(rule) {
    return this.partyService.getActivityBlockAuditLog(rule);
  }

  getManualSlotAuditLog(slotId: number, isDefault: boolean) {
    return this.partyService.getManualSlotAuditLog(slotId, isDefault);
  }

  getTBRAuditLog(ruleId: number) {
    return this.settingService.GetTBRAuditLog(ruleId);
  }

  getRestaurantAuditLog(start, end){
    return this.settingService.getRestaurantAuditLog(start,end)
  }

  getShiftAuditLog(date: Date){
    return this.settingService.getShiftAuditLog(date);
  }

  getCurrentShiftAuditLog(date: Date, shiftId: number) {
    return this.settingService.getCurrentShiftAuditLog(date, shiftId);
  }
  getAdvancedSettingsAuditLog(req: any) {
    return this.settingService.getAdvancedSettingsAuditLog(req);
  }

  getPromoCodeAuditLog(payload){
    return this.settingService.getPromoCodeAuditLog(payload);
  }
}
