<section id="day-end" class="day-end h-100 newVersionWrapper" *ngIf = "hasAccess">
  <ng-scrollbar  >
    <div class="horizontal-stepper-class p-3 h-100">
      <div *ngFor="let coll of GridData; let i = index" class="pb-2 Dayend-Sectable dayEnd-{{i}}" >
        <!-- header Section -->
        <div *ngIf="!coll.linkOptions" class="d-table" [ngClass]="{'hdrSection':coll.dataCount!=0}">
          <span class="d-table-cell V-Mid ">
            <i class="icon-success-icon blckClr mr-2 IC18"
              [ngClass]="(isProcessClicked) ? 'closedTextclr' : ''"></i>
          </span>
          <span class=" d-table-cell hdrlblwdth V-Mid LWB16">{{coll.displayName}}</span>

        </div>

        <!-- Table Section -->
        <div class="w-100 tableSec" *ngIf="coll.tableOptions.TablebodyData.length > 0 || (coll.dataCount==0 && (!canProcess || !successFlag))">
          <ng-scrollbar   >
          <table [attr.automationId]="'Tbl_dayEnd_dayEnd'"  class="w-100 DE_Table">
            <thead *ngIf="coll.tableOptions.TablebodyData.length > 0">
              <tr>
                <th *ngFor="let hdr of coll.tableOptions.TableHdrData;let i=index" class="LW14"  [ngClass]="[hdr.alignType=='right' ? 'text-right' : hdr.alignType=='center' ? 'text-center':'']">{{ hdr.title }}</th>
                <th class="fw100 LW14"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cell of coll.tableOptions.TablebodyData;let j=index;trackBy: trackByFn" >
                <td *ngFor="let hdr of coll.tableOptions.TableHdrData;let i=index" class="LW14" [ngClass]="[hdr.alignType=='right' ? 'text-right' : hdr.alignType=='center' ? 'text-center':'']">
                 <span *ngIf="hdr.jsonkey == 'Status'" class="legendIcon {{getStatusColor(cell.StatusCode)}} mr-1">
                 </span>
                 <span title="{{ cell[hdr.jsonkey] }}">{{ cell[hdr.jsonkey] }}</span>
                </td>
                <td class="fw100 LW14"  >
                  <!-- Will implement in future
                  <i class="icon-Settle cursor pr-2 blckClr" [matTooltip]="captions.tooltip_settle" (click)="ActionClick(iconActions[0],cell)" ></i>
                  <i class="icon-Reopen cursor blckClr" [matTooltip]="captions.tooltip_reopen" (click)="ActionClick(iconActions[1],cell)" ></i> -->
                  <div class="rotate90 ag_display--inflex"><i class="icon-More cursor blckClr" [matMenuTriggerFor]="menuOpt"></i></div>
                  <mat-menu #menuOpt="matMenu">
                    <button mat-menu-item class="custmenuBtn" *ngFor="let opt of coll.options;let k=index" (click)="ActionClick(opt,cell)">
                      <span class="LW14" >{{opt.label}}</span>
                    </button>
                  </mat-menu>
                </td>
              </tr>
            </tbody>
            <!-- Remove commented tfoot and also related SCSS -->
          </table>
          </ng-scrollbar>
        </div>

       <!-- Revenue Postings -->
        <div *ngIf="coll.linkOptions && showRevenuePostings" class="d-table revenue" [ngClass]="{'hdrSection':coll.dataCount!=0}">
          <span class="d-table-cell V-Mid ">
            <i *ngIf="revenuepostingsFailedCount > 0" class="icon-error-icon blckClr mr-2 IC18"></i>
            <i *ngIf="revenuepostingsFailedCount == 0" class="icon-success-icon blckClr mr-2 IC18"></i>
          </span>
         <a *ngIf ="revenuepostingsFailedCount > 0" (click)="routeTolink()"  [routerLink]="revenuePostingUrl">
            <span class=" d-table-cell hdrlblwdth V-Mid LWB16">{{coll.displayName}} {{revenuepostingsFailedText}} </span>
          </a>
          <span  *ngIf ="revenuepostingsFailedCount == 0" class=" d-table-cell hdrlblwdth V-Mid LWB16">{{coll.displayName}}</span>
        </div>

      </div>

      <!-- Final Section -->
      <div class="processSection">
        <div class="processSec">
          <div class="d-inline-block rghtBrder pr-4">
            <div class="dteFnt pb-2">{{commonLocalization.LocalizeDate(currSysDate)}}</div>
            <div class="subdteFnt LW16">{{this.captions.CurrentSystemDate}}</div>
          </div>
          <div class="d-inline-block pl-4">
            <div class="dteFnt pb-2" [ngClass]="{'bold':canProcess || successFlag}">{{commonLocalization.LocalizeDate(newSysDate)}}</div>
            <div class="subdteFnt LW16">{{this.captions.NewSystemDate}}</div>
          </div>
        </div>
        <div class="process-btn mb-3">
          <button [attr.automationId]="'Btn_dayEnd_process'" type="button" mat-button class="body-bgcolor whitecolor text_capitalize LW14 gold-btn w165" [ngClass]="{'btn-disabled': removeBtnCheck()}" (click)="PerformDayend()">{{this.captions.PROCESS}}</button>
        </div>
        <div class="w-100 mt-2" *ngIf="isManualNightAuditRestricted">
          <span class="mx-1">
            <i  aria-hidden="true" class="icon-information"></i>
          </span>
          <span>{{this.nightAuditRestricted}}</span>
        </div>
      </div> 
     
    </div>
  </ng-scrollbar>
</section>
