<div class="instructor-selection">
    <div class="instructor-selection__header">
        <span class="pop-up-label instructor-selection__available-instructor seat-header-two">{{'Instructors' |
            translate}}</span>
        <span class="instructor-selection__searching">
            <dynamic-form [config]="searchConfig" #form="dynamicForm">
            </dynamic-form>
        </span>
    </div>
    <div class="instructor-selection__instructor-info">
        <ng-scrollbar>
            <div class="instructor-selection__instructor-container">
                <ng-container *ngIf="instructors | searchData: searchText as searchedInstructors">
                    <div class=" mt-4 mr-4 instructor-selection__instructor-div" [id]="'assign_instructor_' + instructor.Id"
                        *ngFor="let instructor of searchedInstructors;let i=index"
                        [ngClass]="{'selected-border':selectedInstructor?.Id == instructor.Id}"
                        (click)="selectInstructor(instructor)">
                        <div class="float-left">
                            <img src="assets/images/user_image.jpg" class="profile-image" alt="profile_pic">
                        </div>
                        <div class="float-left text-justify instructor-selection__instructor-name-view">
                            <div class="instructor-selection__instructor-name " [matTooltip]="instructor.Name">{{instructor.Name}}</div>
                            <div *ngIf="!instructor.IsAvailable && !instructor.IsTemplate" class="occupied">{{'unavailableText' | translate}}
                                <span *ngIf="instructor.OverlapBookings" class="icon-info" (click)="$event.stopPropagation()" [ngbPopover]="overlapInfo" placement="bottom"
                                    [autoClose]="'outside'"><span class="path1"></span><span class="path2"></span></span>
                                <ng-template #overlapInfo>
                                    <div class="instructor-selection-bookings">
                                        <div class="instructor-selection-bookings__booking" *ngFor="let booking of instructor.OverlapBookings">
                                            <span class="instructor-selection-bookings__booking-name">{{booking.ActivityName}}</span>
                                            <span class="instructor-selection-bookings__booking-time">{{booking.StartTime | date: 'HH:mm a'}} - {{booking.EndTime | date: 'HH:mm a'}}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                            <div *ngIf="instructor.IsAvailable" class="available">{{'Available' | translate}}
                            </div>
                        </div>
                        <svg *ngIf="selectedInstructor?.Id == instructor.Id" class="checkmark instructor-selection__selected-tick" viewBox="0 0 52 52">
                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                    </div>
                    <div class="no-data"  *ngIf="!searchedInstructors.length">
                        {{'noDataFound' | translate}}
                    </div>
                </ng-container>
            </div>
        </ng-scrollbar>
    </div>
</div>