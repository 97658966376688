<mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass" [formGroup]="form" (click)="onclick()"> 
    <mat-label [attr.LiteralID]="placeHolderId" style="cursor:pointer">{{placeHolder}}</mat-label>
    
    <input [attr.automationId]="automationId"  type="number" (focus)="selectonfocus($event)" (input)="inputChanged($event)" (blur)="valueEmit($event)" matInput [min]="minValue" restrictSpl
            [formControlName]="controlName" [max]="maxValue" [required]="isRequired" [step]="increment" 
            >
    <mat-error *ngIf="form?.controls[controlName]?.hasError('required')" [attr.LiteralID]="err_missing">
            {{captions.common.Missing}} {{placeHolder}}
    </mat-error>
    <mat-error *ngIf="form?.controls[controlName]?.hasError('min')" [attr.LiteralID]="err_min">
            {{minError}}
    </mat-error>
    <mat-error *ngIf="form?.controls[controlName]?.hasError('max')" [attr.LiteralID]="err_max">
            {{maxError}}
    </mat-error>
    <mat-error *ngIf="form?.controls[controlName]?.hasError('invalid')" [attr.LiteralID]="customErrorMessageId">
            {{customErrorMessage}}
    </mat-error>
</mat-form-field>