<section id="retail-multipack">
  <ng-scrollbar   >
    <div [ngClass]="isEdit && IsViewOnly ? 'disableItemSetup' : ''" (click)="stopdefaultClick($event)"
      (keydown)="stopdefaultClick($event)">
      <form autocomplete="off" name="General" [formGroup]="multipackForm">
        <div class="multipack-content">
          <div class="intro-label textellipsis" *ngIf="selectedOutletMgrArr.length > 0">
            <span>
              <i aria-hidden="true" class="icon-information"></i>
              <label class="LW16 ml-2 mr-2">{{captions.SelectedOutlets}}</label>
              <label [ngClass]="{'outlet': selectedOutletMgrArr.length > 1}" [popover]="myPopover"
                popoverPlacement="bottom" [popoverOnHover]="false" [popoverCloseOnMouseOutside]="true"
                [matTooltip]="popTemplate" class="LW16"
                *ngFor="let outlet of selectedOutletMgrArr">{{GetOutletName(outlet.id)}}</label>
            </span>
          </div>
          <popover-content #myPopover placement="bottom" [animation]="true" id="PopoverID" [closeOnMouseOutside]="true">
            <span [ngClass]="{'outlet': selectedOutletMgrArr.length > 1}" *ngFor="let item of selectedOutletMgrArr"
              [matTooltip]="popTemplate">
              {{selectedOutletMgrArr.length}} {{item.name}} </span>
          </popover-content>
          <div class="date-col">
            <div class="start-date">
              <label class="LW14 d-block">{{captions.StartDate}}</label>
              <mat-button-toggle-group [attr.automationId]="'Tog_multipack_dateofsalestart'" name="fontStyle"
                aria-label="Font Style" class="LW14" [value]="selectedVal" formControlName="dateofsalestart"
                (change)="saleChange()">
                <mat-button-toggle value="dateofsale">{{captions.DateofSale}}</mat-button-toggle>
                <mat-button-toggle value="specific">{{captions.SpecificDate}}</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="end-date">
              <label class="LW14 d-block">{{captions.EndDate}}</label>
              <mat-button-toggle-group [attr.automationId]="'Tog_multipack_dateofexpireend'" name="fontStyle"
                aria-label="Font Style" class="LW14" [value]="selectedVal" formControlName="dateofexpireend"
                (change)="saleexpired()">
                <mat-button-toggle value="expiration">{{captions.ExpirationDays}}</mat-button-toggle>
                <mat-button-toggle value="specific">{{captions.SpecificDate}}</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="date-col-input">
            <div class="specific-date">
              <label class="LW14">{{multipackForm['controls'].dateofsalestart.value == 'dateofsale' ?
                captions.DateofSale : captions.SpecificDate}}</label>
              <div class="datepickerWidth_80">
                <mat-form-field appearance="legacy" [floatLabel]="floatLabel" class="date-picker-width layout_date">
                  <input  [attr.automationId]="'Txt_multipack_dateofsale'" matInput [matDatepicker]="specific"
                    dateRestricter [placeholder]="placeHolderFormat"  (dateChange)="OnFromDateValueChange($event)"
                    [min]="_retailService.minStartDate"
                    [disabled]="multipackForm['controls'].dateofsalestart.value == 'dateofsale'"
                    formControlName="specificstartdate">
                  <mat-datepicker #specific></mat-datepicker>
                  <!-- *ngIf="multipackForm['controls'].dateofsalestart.value != 'dateofsale'"  -->
                  <i [attr.automationId]="'Icn_multipack_dateofsale'" aria-hidden="true" matSuffix
                    class="icon-Calender cursor fs-xlarge position-relative d-inline-block customCalendar"
                    [ngClass]="{'ag_section--disable' : multipackForm['controls'].dateofsalestart.value == 'dateofsale'}"
                     (click)="specific.open()"></i>
                  <mat-error *ngIf="multipackForm.controls['specificstartdate'].hasError('matDatepickerParse')">
                    {{captionsCommon.dateFormat}}</mat-error>
                  <mat-error
                    *ngIf="multipackForm.controls['specificstartdate'].hasError('required') && !multipackForm.controls['specificstartdate'].hasError('matDatepickerParse')">
                    {{captionsCommon.Missing}} {{multipackForm['controls'].dateofsalestart.value == 'dateofsale' ?
                    captions.DateofSale : captions.SpecificDate}}</mat-error>
                  <mat-error *ngIf="multipackForm.controls['specificstartdate'].hasError('matDatepickerMin')">
                    {{captionsCommon.minimum}} {{multipackForm['controls'].dateofsalestart.value == 'dateofsale' ?
                    captions.DateofSale : captions.SpecificDate}}</mat-error>
                  <mat-error *ngIf="multipackForm.controls['specificstartdate'].hasError('matDatepickerMax')">
                    {{captionsCommon.maximum}} {{multipackForm['controls'].dateofsalestart.value == 'dateofsale' ?
                    captions.DateofSale : captions.SpecificDate}}</mat-error>
                </mat-form-field>

              </div>

            </div>
            <div class="expiration-days">
              <label class="LW14">{{expirationLabel}}</label>
              <div *ngIf="multipackForm['controls'].dateofexpireend.value == 'specific'" class="datepickerWidth_80">
                <mat-form-field appearance="legacy" [floatLabel]="floatLabel" class="date-picker-width layout_date">
                  <input [attr.automationId]="'Txt_multipack_expireenddate'" matInput [matDatepicker]="expiration"
                    dateRestricter [placeholder]="placeHolderFormat"  (dateChange)="OnToDateValueChange($event)"
                    [min]="_retailService.minEndDate" formControlName="expireenddate">
                  <mat-datepicker #expiration (selectedChanged)="searchAppointments($event,'from')"></mat-datepicker>
                  <i [attr.automationId]="'Icn_multipack_expireenddate'" matSuffix
                    class="icon-Calender cursor fs-xlarge position-relative d-inline-block customCalendar"
                     (click)="expiration.open()"></i>
                  <mat-error *ngIf="multipackForm.controls['expireenddate'].hasError('matDatepickerParse')">
                    {{captionsCommon.dateFormat}}</mat-error>
                  <mat-error
                    *ngIf="multipackForm.controls['expireenddate'].hasError('required') && !multipackForm.controls['expireenddate'].hasError('matDatepickerParse')">
                    {{captionsCommon.Missing}} {{expirationLabel}}</mat-error>
                  <mat-error *ngIf="multipackForm.controls['expireenddate'].hasError('matDatepickerMin')">
                    {{captionsCommon.minimum}} {{expirationLabel}}</mat-error>
                  <mat-error *ngIf="multipackForm.controls['expireenddate'].hasError('matDatepickerMax')">
                    {{captionsCommon.maximum}} {{expirationLabel}}</mat-error>
                </mat-form-field>

              </div>
              <div *ngIf="multipackForm['controls'].dateofexpireend.value == 'expiration'">
                <mat-form-field [floatLabel]="floatLabel"  >
                  <input [attr.automationId]="'Txt_multipack_expiryDays'" matInput type="number" min="0"
                    oninput="validity.valid||(value='');" formControlName="expiryDays">
                </mat-form-field>
              </div>

            </div>
          </div>
          <div class="unlimited-toggle yes-col ">
            <label class="LW14">{{captions.Unlimited}}</label>

            <app-retail-toggle-switch [attr.automationId]="'Tog_multipack_unlimited'" class="ml-1"
              formControlName="unlimited" [formControl]="multipackForm.controls['unlimited']"
              (changeToggleEvent)="toogleClick($event)">
            </app-retail-toggle-switch>

            <div class="per-sale custom-field-persale"
              [ngClass]="{'button_invalid': multipackForm['controls'].unlimited.value}">
              <mat-form-field [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_multipack_persale'" matInput [placeholder]="captions.persale"
                  maxlength="8" inputtype="onlynumber,nodecimal,nonnegative" [required]="isPerSaleMandatory"
                  formControlName="persale" (input)="onPerSaleChange()">
              </mat-form-field>
              <mat-error class=""
                *ngIf="(!this.multipackForm['controls'].unlimited.value && (this.multipackForm.controls['persale'].value <= 0))">
                {{errMissing}}
              </mat-error>
            </div>
          </div>

          <ng-container *ngIf="!isMultipleLinkedItem">
            <div class="linked-category">
              <div class="category">
                <mat-form-field class="LW14" [floatLabel]="floatLabel">
                  <mat-select [attr.automationId]="'Dd_multipack_category'" [placeholder]="captions.LinkedItemCategory"
                    formControlName="category" (selectionChange)="onCategoryChange($event)">
                    <mat-option *ngFor="let items of linkedCategory" [value]="items.id">
                      {{ items.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="items">
                <app-search-linked-retail-items [automationId]="'multipack'" [displayTexts]="linkItemCaptions"
                  [filteredData]="searchFilter" (searchTextEmit)="searchTextEmit($event)"
                  [autocomplete]="multipackForm.controls.item" (doneEvtEmitter)="LinkedItemEmit($event)">
                </app-search-linked-retail-items>
              </div>
            </div>
            <div class="sale-member-cost" [ngClass]="{'button_invalid': multipackForm['controls'].unlimited.value}">
              <div class="sale">
                <mat-form-field [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_multipack_salesprice'" matInput [placeholder]="captions.SalesPrice"
                    formControlName="salesprice" (input)="CalcSalesPrice()" RetailCurrencyFormatter>
                </mat-form-field>
              </div>
              <div class="member">
                <mat-form-field [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_multipack_memberprice'" matInput [placeholder]="captions.MemberPrice"
                    formControlName="memberprice" (input)="CalcSalesPrice()" RetailCurrencyFormatter>
                </mat-form-field>
              </div>
              <div class="cost">
                <mat-form-field [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_multipack_cost'" matInput [placeholder]="captions.Cost"
                    formControlName="cost" (input)="CalcSalesPrice()" RetailCurrencyFormatter>
                </mat-form-field>
              </div>
            </div>
            <div class="service-gratutity" [ngClass]="{'button_invalid': multipackForm.controls.unlimited.value}">
              <div class="service">
                <mat-form-field [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_multipack_service'" type="text" matInput
                    [placeholder]="captions.ServicePercentage" (input)="CalcSalesPrice()" RetailCurrencyFormatter
                    [preDecimalLength]="PercentageMaxLength" formControlName="service">
                </mat-form-field>
              </div>
              <div class="gratutity">
                <mat-form-field [floatLabel]="floatLabel">
                  <input [attr.automationId]="'Txt_multipack_gratutity'" type="text" matInput
                    [placeholder]="captions.GratuityPercentage" (input)="CalcSalesPrice()" RetailCurrencyFormatter
                    [preDecimalLength]="PercentageMaxLength" formControlName="gratutity">
                </mat-form-field>
              </div>
            </div>
            <!-- <div class="per-sale" [ngClass]="{'button_invalid': multipackForm['controls'].unlimited.value}">
              <mat-form-field>
                <input matInput [placeholder]="captions.persale" maxlength="8" inputtype="nonnegative"
                  [required]="isPerSaleMandatory" formControlName="persale" (input)="onPerSaleChange()">
              </mat-form-field>
              <mat-error class=""
                *ngIf="(!this.multipackForm['controls'].unlimited.value && (this.multipackForm.controls['persale'].value <= 0))">
                {{errMissing}}
              </mat-error>
            </div> -->
          </ng-container>
          <ng-container *ngIf="isMultipleLinkedItem">
            <div class="array-rel" formArrayName="multipackArr"
              *ngFor="let x of multipackForm.get('multipackArr').controls; let i = index;let isFirst=first; let isLast=last">
              <div [formGroupName]="i">
                <div class="per-sale">
                  <div class="linked-category">
                    <div class="category">
                      <mat-form-field class="LW14" [floatLabel]="floatLabel">
                        <mat-select [attr.automationId]="'Dd_multipack_category{{i}}'"
                          [placeholder]="captions.LinkedItemCategory" formControlName="category" [arrayIndex]="i"
                          (selectionChange)="categoryChange($event, i)">
                          <mat-option *ngFor="let items of linkedCategory" [value]="items.id">
                            {{ items.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="items">
                      <app-search-linked-retail-items [automationId]="'multipleLinkedItemMultipack'" [arrayIndex]="i"
                        [isMultipleLinkedItem]="isMultipleLinkedItem" [searchedIndex]="searchedIndex"
                        [displayTexts]="linkItemCaptions" [filteredData]="searchFilter"
                        (searchTextEmit)="searchTextEmit($event,i)" [autocomplete]="x.controls.item"
                        (doneEvtEmitter)="LinkedItemEmit($event)">
                      </app-search-linked-retail-items>
                    </div>
                  </div>
                  <div class="sale-member-cost"
                    [ngClass]="{'button_invalid': multipackForm['controls'].unlimited.value}">
                    <div class="sale">
                      <mat-form-field [floatLabel]="floatLabel">
                        <input [attr.automationId]="'Txt_multipack_multiSalesprice'" matInput
                          [placeholder]="captions.SalesPrice" formControlName="salesprice"
                          (input)="CalcMultipleItemSalesPrice()" RetailCurrencyFormatter>
                      </mat-form-field>
                    </div>
                    <div class="member">
                      <mat-form-field [floatLabel]="floatLabel">
                        <input [attr.automationId]="'Txt_multipack_multiMemberprice'" matInput
                          [placeholder]="captions.MemberPrice" formControlName="memberprice"
                          (input)="CalcMultipleItemSalesPrice()" RetailCurrencyFormatter>
                      </mat-form-field>
                    </div>
                    <div class="cost">
                      <mat-form-field [floatLabel]="floatLabel">
                        <input [attr.automationId]="'Txt_multipack_multiCost'" matInput [placeholder]="captions.Cost"
                          formControlName="cost" (input)="CalcMultipleItemSalesPrice()" RetailCurrencyFormatter>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="service-gratutity" [ngClass]="{'button_invalid': multipackForm.controls.unlimited.value}">
                    <div class="service">
                      <mat-form-field [floatLabel]="floatLabel">
                        <input [attr.automationId]="'Txt_multipack_multiService'" type="text" matInput
                          [placeholder]="captions.ServicePercentage"
                          percentinputtype='onlynumber,decimal,validPercentage,preMaxdecimal'
                          [maxpredecimalValue]="PercentageMaxLength" (input)="CalcMultipleItemSalesPrice()"
                          formControlName="service">
                      </mat-form-field>
                    </div>
                    <div class="gratutity">
                      <mat-form-field [floatLabel]="floatLabel">
                        <input [attr.automationId]="'Txt_multipack_multiGratutity'" type="text" matInput
                          [placeholder]="captions.GratuityPercentage"
                          percentinputtype='onlynumber,decimal,validPercentage,preMaxdecimal'
                          [maxpredecimalValue]="PercentageMaxLength" (input)="CalcMultipleItemSalesPrice()"
                          formControlName="gratutity">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="icon-list">
                <span class="icon-plus ag_mt--2" [ngClass]="{'ag_section--disable': isViewOnly}" (click)="addItem(i)"
                  *ngIf="isLast"></span>
                <span class="icon-minus ag_mt--2" [ngClass]="{'ag_section--disable': isViewOnly}" *ngIf="!isFirst"
                  (click)="removeItem(i)"></span>
              </div>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </ng-scrollbar>
</section>
<mat-dialog-actions class="float-right actionButtons pr-4">
  <button [attr.automationId]="'Btn_multipack_cancel'" mat-button type="button"
    (click)="close()">{{this.localization.captions.common.CANCEL}}</button>
  <button [attr.automationId]="'Btn_multipack_previous'" mat-button matStepperPrevious type="button"
    class="LW14 text_capitalize  body-bordercolor">{{this.localization.captions.common.PREVIOUS}}</button>
  <button [attr.automationId]="'Btn_multipack_save'" mat-button type="button" *ngIf="!isEdit" (click)="Save()"
    color="primary" [ngClass]="multipackForm.valid ? 'button_valid' : 'button_invalid'"
    class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.SAVE}}</button>
  <button [attr.automationId]="'Btn_multipack_update'" mat-button type="button" *ngIf="isEdit" (click)="Save()"
    color="primary"
    [ngClass]="(multipackForm.valid && (parentForm.get('generalFormGroup').dirty || parentForm.get('otherInfoFormGroup').dirty || parentForm.get('inventoryFormGroup')?.dirty
    || parentForm.get('multipackFormGroup')?.dirty || parentForm.get('packagedItemFormGroup')?.dirty)) ? 'button_valid' : 'button_invalid'"
    class="LW14 text_capitalize body-bgcolor whitecolor body-bordercolor">{{this.localization.captions.common.Update}}</button>
</mat-dialog-actions>