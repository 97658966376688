import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { CacheService } from '@app/core/services/cache.service';
import { BookingBehavior, ClassType, ComponentTypes, PartyNoteType, PartyType, SlottingType, ValidationMessageType, buttonTypes } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { ActivityRatePlanRequest, RatePlanRequest } from '@app/shared/models/ActivityRatePlanRequestDTO';
import { ContactDTO, PartyNoteDTO, PartyPrepaymentState } from '@app/shared/models/InputContact';
import { SessionBookingDTO } from '@app/shared/models/SessionBookingDTO';
import { PartyService } from '@app/shared/services/party.service';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { map } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Category, PayingGuest, SettingsDTO, TaxType } from '@app/shared/models/RestaurantDTO';
import { PartyNotes } from '@app/shared/models/global.interface';
import { FormChipService } from '@app/shared/dynamicform/components/form-chip/form-chip.service';
import { AppService } from '@app/app.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { RestaurantPoliciesComponent } from '../create-update-party-tab-layout/selections/restaurant-policies/restaurant-policies.component';
import { PopupService } from '@app/popup-module/popup.service';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { ITabOutputDetails } from '@app/settings/models/common.interface';
import { LockSessionRequestDTO } from '@app/shared/models/ActivityLockSessionDTO';
import moment from 'moment';
import { PaymentItemType } from '@app/shared/models/RatePlanSummary.model';
import { ApplyDiscount } from '@app/shared/models/PromoCodeDTO';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ToastrService } from 'ngx-toastr';
import { GuestBookService } from '@app/shared/services/guestbook.service';
import { EventIntializers } from '@app/shared/constants/globalConstants';
import { FinancialEffectAction } from '@app/shared/models/FinancialEffect.model';
import { rateChanges } from '@app/shared/constants/globalConstants';
import { actionType } from '@app/servers/server.component';
import { LoaderService } from '@app/core/services/loader.service';
import { RentalService } from '@app/shared/services/rental/rental.service';


@Component({
    selector: 'app-rate-plan',
    templateUrl: './rate-plan.component.html',
    styleUrls: ['./rate-plan.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RatePlanComponent extends Utilities implements OnInit, OnDestroy {
    @Input() bookingData;
    @Input() enableEdit = true;
    @Input() showPaymentStatus: boolean = true;
    applyDiscount = ApplyDiscount;
    subscriptions: Subscription = new Subscription();
    ratePlanSummary: any;
    originalRatePlan: any;
    currencySymbol: string = '';
    rateNegotiateConfig: FieldConfig[];
    recalculateConfig: FieldConfig[];
    applyNegotiationBtn: ButtonValue;
    cancelNegotiationBtn: ButtonValue;
    removeOfferCode = false;
    @Input() invokeRatePlan: Observable<any>;
    @Input() fromComponent: any;
    @Input() reservationCardId: number;
    restaurantPolicy: any;
    config: FieldConfig[] = [];
    includeOthers = false;
    othersId: number[] = [];
    appliedOfferCode = null;
    customPartyNotes: any[] = [];
    partyNotes: PartyNotes[] = [];
    categories: Category[] = [];
    _settings: SettingsDTO;
    showSCDetails = false;
    showNegDis = false;
    contactDetails: ContactDTO;
    financialEffectAction = FinancialEffectAction;
    showTaxDetails = false;
    @ViewChild('negotiateReasonForm') negotiateReasonForm: DynamicFormComponent;
    @ViewChild('recalculateForm') reCalculate: DynamicFormComponent;
    selectedPartyPreferenceTags: any[] = [];
    tabsConfig: any;
    diningProperty = false;
    taxType = TaxType.TAX
    paymentItemType = PaymentItemType;
    existingRatePlanSummary: any;
    originalShopItems: any[] = [];
    payingGuestsArr: PayingGuest[];
    payingGuest = [];
    bookingCreditCard: any;
    _PartyPrepaymentState = PartyPrepaymentState;
    _ticketNumbers = [];
    pickedTicketNumber: number;
    showTransactionError: boolean;
    fromRateChanges = false;
    numberFormat: string;
    @Output() IsPackageBroken = new EventEmitter<boolean>();
    @Output() selectedPayeeCardInfo = new EventEmitter<boolean>();
    @Output() transactionErrorEvent = new EventEmitter<boolean>();
    @Output() bookingCreditCardEvent = new EventEmitter<boolean>();

    //  ratePlanSummary: Observable<any>;
    constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public dialogData, public _as: AppService, public partyService: PartyService, public cs: CacheService, private _ts: TranslateService, private _api: ApiService, private _ps: PartyService, private ls: LoaderService,
        private guestBookService: GuestBookService, private formChipService: FormChipService, private ps: PopupService, private toastrService: ToastrService, public rentalService: RentalService) {
        super(dialog);
        this.subscriptions.add(this.cs.settings.subscribe(sett => {
            this._settings = sett;
            this.taxType = sett.PropertySetting[0].TaxType;
            // this.taxType = sett.PropertySetting.TaxType || TaxType.TAX
            // // this.isTaxConfigured = this._settings.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.Tax)?.length > 0;
            // // this.isServiceConfigured = this._settings.BookingCharges?.filter(charge => charge.BookingChargeType == BookingChargeType.ServiceCharge)?.length > 0;
            // // this.isRetailEnabledProperty = Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO);
            // // this.OperationCurrency = this._settings.General.OperationCurrency;
            // // this.coverTypes = [...this._settings.CoverTypes];
            // // this.bookingTypes = [...this._settings.BookingTypes];
            // // this.partyService.isNegotiationEnabled = this._as.hasPermission(RolesAndPermissionsType.NegotiateRates);
            // if (this._settings.PropertySetting.length > 0 && (this._settings.PropertySetting[0].PreReservationMessage && this._settings.PropertySetting[0].PreReservationMessageTitle)) {
            //     this.restaurantPolicy = this._settings.PropertySetting[0];
            // }
            // this.categories = this._settings.Categories.filter(category => category.Text != "Reasons");
            // if (dialogData && this._as.OTASourceId.includes(dialogData.PartySourceId)) {
            //     this.includeOthers = true;
            // }
            // if (dialogData && this.dialogData.Notes && this.dialogData.Notes.length) {
            //     this.othersId = this.dialogData.Notes.filter(x => x.Type == PartyNoteType.FreeFormNote).map(({ RelatedId }) => RelatedId);
            // }
            // this.partyNotes = Utilities.getRestaurantPredefinedPartyNotes(this.categories, this.includeOthers, this.othersId);

        }));
    }

    ngOnInit(): void {
        this.currencySymbol = this.cs.settings.value.General.OperationCurrency;
        this.numberFormat = this.cs.getNumberFormat();
        this.loadConfig();
        this.enableEdit = (this.dialogData.bookingData?.actionType == FinancialEffectAction.WaivedOff || this.dialogData.bookingData?.actionType == FinancialEffectAction.Refund) ? false : this.enableEdit;
        this.setCardInformation(this.getCreditCardInformation());
        this.subscriptions.add(this.guestBookService.createEditGuestSubscription$.subscribe(data => {
            if (this.contactDetails && this.contactDetails['updateCard']) {
                //this.guestBookService.getContact(data?.Payload?.Id).subscribe(({response}) => {
                    if(this.bookingData){
                        let _index  = this.bookingData?.BookingContacts?.findIndex(contact => contact.ContactId === data.Payload.Id)
                        this.bookingData.BookingContacts[_index].CreditCards = data.Payload?.CreditCards || [];
                    }
                   this.getRatePlanForActivity(true);
              //  });
                this.contactDetails = null;
            }
        }));
        if (this.dialogData.bookingData)
            this.dialogData.bookingData.actionType = this.dialogData.bookingData?.actionType != undefined ? this.dialogData.bookingData?.actionType : -1;
        this.fromRateChanges = rateChanges.includes(this.dialogData.bookingData?.actionType);
        if (this.dialogData && this.dialogData.bookingData) {
            this._ps.ratePlanObject = this.dialogData.bookingData.financialData;
            this.ratePlanSummary = this.dialogData.bookingData.financialData.RatePlan;
            this.payingGuestsArr = this.dialogData.bookingData.PayingGuest;
            this.payingGuestMapping();
            this.originalRatePlan = _.cloneDeep(this.ratePlanSummary);
            this.originalShopItems = _.cloneDeep(this.dialogData.bookingData.financialData.ShopItems);
            this.existingRatePlanSummary = _.cloneDeep(this.ratePlanSummary);
            this.partyService.ratePlanSummaryState = true;
            if (this.ratePlanSummary.Discount)
                this.appliedOfferCode = { OfferCode: this.ratePlanSummary.Discounts?.[0]?.ChargeName, ApplyDiscount: this.ratePlanSummary.Discounts?.[0]?.ApplyBeforeTax == true ? ApplyDiscount.BeforeTax : ApplyDiscount.AfterTax, OfferId: this.ratePlanSummary.Discounts?.[0]?.ChargeId }
        }
        if (this.dialogData?.componentDetails?.tabs?.componentInput?.RatePlan?.Discounts?.length) {
            let offer = null;
            offer = this.dialogData?.componentDetails?.tabs?.componentInput?.RatePlan?.Discounts?.[0];
            if (offer)
                this.appliedOfferCode = { OfferCode: offer.ChargeName, ApplyDiscount: offer.ApplyBeforeTax == true ? ApplyDiscount.BeforeTax : ApplyDiscount.AfterTax, OfferId: offer.ChargeId }
        }
        if (this.dialogData?.componentDetails?.tabs?.componentInput?.isCartEdit) {
            this.appliedOfferCode = { OfferCode: this.dialogData.componentDetails.tabs.componentInput?.bookingRatePlan?.rateSummary?.OfferCode, ApplyDiscount: 0 }
        }
        if (this.bookingData?.BookingBehavior == BookingBehavior.RentalBooking && this.rentalService.appliedPromoCode) {
            this.appliedOfferCode = {
                OfferCode: this.rentalService.appliedPromoCode.OfferCode, ApplyDiscount: this.rentalService.appliedPromoCode.ApplyDiscount == 0 ?
                    ApplyDiscount.BeforeTax : ApplyDiscount.AfterTax, OfferId: this.rentalService.appliedPromoCode.Id
            }
        }

        this.invokeRatePlan?.subscribe(data => {

            if (data) {

                if (this.partyService.addToCartRequestObj && this.partyService.BookingBehaviour == BookingBehavior.ClassOrSession && (!this.partyService.addToCartRequestObj?.Slot?.LockId || !this.partyService.addToCartRequestObj?.Sessions?.LockId)) {
                    this.partyService.addToCartRequestObj.Slot.LockId = this.partyService.lockData?.SlotLockIdDTO?.Id || null;
                    this.partyService.addToCartRequestObj.Sessions.LockId = this.partyService.lockData?.SlotLockIdDTO?.Id || null;
                }
                else {
                    this.partyService.addToCartRequestObj?.Slots?.map((slot) => {
                        slot.LockId = this.partyService.lockData?.SlotLockIdDTO?.Id || null;
                    })
                }
                this.diningProperty = this.cs.settings.value.PropertySetting[0]?.SlottingType == SlottingType.Dining;
                this.payingGuestsArr = [];
                this.getRatePlanForActivity();
            }
        })
        if (this.dialogData.bookingData?.actionType == FinancialEffectAction.WaivedOff) {
            this.dialogData.bookingData?.financialData?.RatePlan?.ReasonForNegotiation && this.dialogData.bookingData?.financialData?.RatePlan?.ReasonForNegotiation != '' ? this.ps.saveBtnEnable$.next(true) : this.ps.saveBtnEnable$.next(false);
        }
    }

    getCreditCardInformation(){
        if(this.bookingData?.CreditCards?.length){
           return getMappedCreditCard(this.bookingData?.CreditCards, null, this.reservationCardId)
        }else if(this.dialogData.bookingData?.financialData?.CreditCards){
            return getMappedCreditCard(this.dialogData.bookingData?.financialData?.ContactCreditCardList, null, this.dialogData.bookingData?.financialData?.CreditCard?.CreditCardId)
        }
        return this.dialogData.bookingData?.financialData?.CreditCard;
    }

    getRatePlanForActivity(ignoreValidation?: boolean) {

        let ratePlanRequest = this.formRatePlanRequestDTO(this.bookingData, this.appliedOfferCode?.OfferCode);
        this._ps.currentRatePlanRequest = ratePlanRequest;
        //   if (this.diningProperty || !this.dialogData.componentDetails.tabs.componentInput?.RatePlan || this.isBookingDataUpdated(ratePlanRequest, this.dialogData.componentDetails.tabs.componentInput) || this.removeOfferCode) {
        this._api.GetRatePlanCalculated(ratePlanRequest, null, ignoreValidation).subscribe(data => {
            if (data.Payload) {
                this.intializeRatePlan(data.Payload)
                if (data.Payload?.RatePlan?.Discount) {
                    this.partyService.promoCodeApplied$.next(
                        {
                            OfferName:data.Payload?.RatePlan?.Discounts?.[0]?.ChargeName
                        });
                    this.appliedOfferCode = { OfferCode: data.Payload?.RatePlan?.Discounts?.[0].ChargeName, ApplyDiscount: data.Payload?.RatePlan?.Discounts?.[0].ApplyBeforeTax == true ? ApplyDiscount.BeforeTax : ApplyDiscount.AfterTax, OfferId: data.Payload?.RatePlan?.Discounts?.[0].ChargeId }
                }
                else {
                    this.appliedOfferCode = null;
                }
                this.removeOfferCode = false;
                if (data.Payload.IsPackageBroken) {
                    this.IsPackageBroken.emit(true);


                    this.partyService.showWarningInfoPopUp(this.partyService.getPackageName(this.bookingData?.PackageDetails?.PackageId, 'packageBookingUpdateConfirmation'), ComponentTypes.packageBrokenWarning, '350px', '500px', []);
                    let confirmationSubscription = this.ps.confirmedAction$.subscribe(data => {
                        if (data === ComponentTypes.packageBrokenWarning) {
                            this.partyService.warningInfoDialogRef.close();
                        }
                    });
                    let cancelledSubscription = this.ps.cancelledAction$.subscribe(data => {
                        if (data.from == ComponentTypes.packageBrokenWarning) {
                            this.ps.tabsActionData[this.ps.tabsActionData.length - 1].gotoNextTab = false;
                            this.ps.tabsActions$.next(this.ps.tabsActionData);
                            this.partyService.warningInfoDialogRef.close();
                        }
                    })
                    this.partyService.warningInfoDialogRef.afterClosed().subscribe(event => {
                        confirmationSubscription?.unsubscribe();
                        cancelledSubscription?.unsubscribe();
                    });
                }

                else {
                    this.IsPackageBroken.emit(false);
                }
            }
        })
        //   }
        // else {
        //     this.intializeRatePlan(this.dialogData.componentDetails.tabs.componentInput)
        // }
    }

    intializeRatePlan(ratePlan, offerCode?) {
        this._ps.ratePlanObject = ratePlan;
        this._ps.ratePlanObject = { ...this._ps.ratePlanObject }
        if (ratePlan && ratePlan.RatePlan) {
            this.ratePlanSummary = ratePlan.RatePlan;
            if (this.partyService.addToCartRequestObj) {
                this.partyService.addToCartRequestObj.rateSummary = {
                    Amt: this.ratePlanSummary.SubTotal,
                    TaxAmt: this.ratePlanSummary.Tax,
                    SCAmt: this.ratePlanSummary.ServiceCharge,
                    TaxOnSCAmt: this.ratePlanSummary.TaxOnServiceChargeAmount,
                    VAT: this.ratePlanSummary.VAT,
                    VATSCAmt: this.ratePlanSummary.VATOnServiceChargeAmount,
                    Discount: this.ratePlanSummary.Discount,
                    OfferCode: this.appliedOfferCode?.OfferCode || null

                }

            }
            this.originalRatePlan = _.cloneDeep(this.ratePlanSummary);
            this.setConfigChanges();
        }
        this.originalRatePlan = _.cloneDeep(this.ratePlanSummary);
        this.originalShopItems = _.cloneDeep(ratePlan?.ShopItems);
        this.existingRatePlanSummary = _.cloneDeep(this.ratePlanSummary);
        this.setConfigChanges();
        this.payingGuestMapping();
        this.fetchPayingGuest();
    }
    isBookingDataUpdated(request, data) {
        let isGuestTypeChanged = false;
        let isBookingTypeChanged = false;
        let isSessionChanged = false;
        let isAddonChanged = false;
        let bookingSizeChanged = false;
        let isContactChanged = false;
        let isDateChanged = false;
        let isLocationChanged = false;
        let isStaffChanged = false;
        let isExclusiveBookingChanged = false;

        if (!data) {
            return false;
        }
        if (request.CoverTypes?.length && data.CoverTypeQuantities.length) {
            isGuestTypeChanged = request.CoverTypes.filter((reqGuestType) => {

                let savedGuestType = data.CoverTypeQuantities.find(srcGuestType => {
                    if (srcGuestType.CoverTypeId && srcGuestType.BookingTypeId) {
                        return srcGuestType.CoverTypeId === reqGuestType.CoverTypeId && srcGuestType.BookingTypeId === reqGuestType.BookingTypeId;
                    }
                    else if (srcGuestType.CoverTypeId) {
                        return srcGuestType.CoverTypeId === reqGuestType.CoverTypeId;
                    }
                    else if (srcGuestType.BookingTypeId) {
                        return srcGuestType.BookingTypeId === reqGuestType.BookingTypeId;
                    }
                    else {
                        return srcGuestType.Covers === reqGuestType.Covers
                    }
                })
                return !!((savedGuestType && savedGuestType.Covers) !== reqGuestType.Covers);
            }).length
        }


        if (request.coverTypes?.length) {
            bookingSizeChanged = _.sumBy(request.coverTypes, 'Covers') != request.Size
        }
        if (!request.CoverTypes?.length && !request.BookingTypes?.length) {
            bookingSizeChanged = data.Size != +request.Size;
        }
        if (request.BookingBehavior == BookingBehavior.ClassOrSession) {
            if (data.BookedSessions) {
                isSessionChanged = !(data.BookedSessions.length === request.SessionSlot.Sessions[0].SessionIds.length
                    && data.BookedSessions.filter(bookedSession =>
                        request.SessionSlot.Sessions[0].SessionIds.includes(bookedSession.ActivitySessionId)).length)
            } else if (data.SessionGroupId && request.SessionSlot) {
                isSessionChanged = data.SessionGroupId !== request.SessionSlot.SessionGroupId;
            }
        }
        else if (request.BookingBehavior == BookingBehavior.OpenBooking || request.BookingBehavior == BookingBehavior.PrivateLesson) {

            if (data.SeatingTime) {
                let durationOfActivity = moment.duration(moment(data.DepartureTime, 'YYYY/MM/DD HH:mm')
                    .diff(moment(data.SeatingTime, 'YYYY/MM/DD HH:mm'))).asMinutes();
                isSessionChanged = (data.SeatingTime != request.Slots[0].Time) || (durationOfActivity != request.Slots[0].DurationInMinutes)
            } else if (data.Slots && data.Slots[0]) {
                isSessionChanged = (data.Slots[0].Time != request.Slots[0].Time) || (data.Slots[0].DurationInMinutes != request.Slots[0].DurationInMinutes)
            } else {
                isSessionChanged = false;
            }

        }


        isAddonChanged = !!data.BookingContactAddonItems && (!(_.flatMap(request.BookingContacts, 'Addons').filter((reqAddon) => {
            let savedAddon = data.BookingContactAddonItems.find(srcAddon => srcAddon.AddonId === reqAddon.AddonId);
            return savedAddon && savedAddon.AddonCount === reqAddon.Quantity
        }).length === data.BookingContactAddonItems.length) || _.flatMap(request.BookingContacts, 'Addons').length !== data.BookingContactAddonItems.length);

        isDateChanged = !(moment(moment(request.StartDate).format('YYYY-MM-DD')).isSame(moment(data.StartDate).format('YYYY-MM-DD')) && moment(moment(request.EndDate).format('YYYY-MM-DD')).isSame(moment(data.EndDate).format('YYYY-MM-DD')));

        isContactChanged = (data.BookingContacts.length != request.BookingContacts.length) || _.differenceBy(request.BookingContacts, data.BookingContacts, 'ContactId').length > 0;

        isDateChanged = !(moment(moment(request.StartDate).format('YYYY-MM-DD')).isSame(moment(data.StartDate).format('YYYY-MM-DD')) && moment(moment(request.EndDate).format('YYYY-MM-DD')).isSame(moment(data.EndDate).format('YYYY-MM-DD')));

        if (request.BookingBehavior == BookingBehavior.OpenBooking || request.BookingBehavior == BookingBehavior.PrivateLesson)
            isLocationChanged = !(_.isEqual(data.TableIds, request.TableIds));


        if (request.BookingBehavior == BookingBehavior.PrivateLesson)
            isStaffChanged = !(_.isEqual(data.StaffIds, request.InstructorIds));


        isExclusiveBookingChanged = data.IsExclusiveBooking !== request.IsExclusiveBooking;
        /**Pending */
        //isGroupClassUpdated
        return !!isGuestTypeChanged || isSessionChanged || isAddonChanged || bookingSizeChanged || isContactChanged || isDateChanged || isLocationChanged || isStaffChanged || isExclusiveBookingChanged;
    }
    formRatePlanRequestDTO(bookingData, offerCode?) {
        if (bookingData) {
            return {
                Slots: bookingData.BookingBehavior == BookingBehavior.ClassOrSession ? [] : bookingData.Slots,
                SessionSlot:
                    bookingData.BookingBehavior == BookingBehavior.ClassOrSession ? {
                        Sessions: bookingData.SessionGroupId ? null : bookingData.Slots,
                        SessionGroupId: bookingData.SessionGroupId,
                        OverBooked: bookingData?.OverBooked || 0
                    } : {},
                Size: bookingData.BookingSize,
                TableIds: bookingData.BookingBehavior == BookingBehavior.ClassOrSession ? [] : bookingData.Location?.flat(),
                CoverTypes: bookingData.CoverTypes,
                ActivityId: bookingData.ActivityId,
                FinancialEffectId: null,
                BookingBehavior: bookingData.BookingBehavior,
                StartDate: bookingData.FromDate ? moment(bookingData.FromDate).format("YYYY-MM-DD") + 'T00:00:00.000' : null,
                EndDate: bookingData.ToDate ? moment(bookingData.ToDate).format("YYYY-MM-DD") + 'T00:00:00.000' : null,
                BookingContacts: bookingData.BookingContacts,
                RatePlan: null,
                AddOns: bookingData.SelectedAddOns,
                InstructorIds: bookingData.StaffId,
                PartyId: bookingData?.BookingId ? bookingData.BookingId : null,
                PackageDetails: bookingData?.PackageDetails || null,
                IsExclusiveBooking: bookingData.IsExclusiveBooking || false,
                PromoCode: offerCode ? offerCode : (this.appliedOfferCode?.OfferCode || null),
                ClearDiscount: this.removeOfferCode ? true : false
            };
        } else {
            let ratePlanRequestDTO = this.getRatePlanDTO(this.dialogData.party);
            return ratePlanRequestDTO;
        }
    }

    applyChanges() {
        let ratePlanRequest = { PromoCode: this.appliedOfferCode?.OfferCode || null, RatePlan: this.ratePlanSummary, ActivityId: 0, ReservationId: this.dialogData ? this.dialogData.bookingData?.party?.Id : this.bookingData ? this.bookingData?.BookingId : null, FinancialEffectAction: this.dialogData?.bookingData?.actionType };
        ratePlanRequest.RatePlan.Reservations.forEach(reservation => {
            reservation.Sessions.forEach(session => {
                session.GuestPrices.forEach(guestPrice => {
                    guestPrice.Items.forEach(item => {
                        item.PayableUnitPrice = +item.PayableUnitPrice;
                    });
                });
            });
        });

        ratePlanRequest.RatePlan.ServiceChargeRecalculate = this.reCalculate?.form.value.reCalculateServiceCharge || false;
        ratePlanRequest.RatePlan.ReasonForNegotiation = this.negotiateReasonForm.form.value.reasonText;

        ratePlanRequest.ActivityId = this.dialogData?.bookingData ? (this.dialogData?.bookingData.party.SpecialMealId || null) : (this.bookingData.ActivityId || null);

        this._api.getRatePlanReCalculation(ratePlanRequest).subscribe(dialogData => {
            this._ps.ratePlanObject = dialogData.Payload;
            this.ratePlanSummary = dialogData.Payload.RatePlan;
            this.ps.saveBtnEnable$.next(true);
            this.payingGuestMapping();
            //this._ps.currentRatePlanRequest = ratePlanRequest;
            if (this.partyService.addToCartRequestObj) {
                this.partyService.addToCartRequestObj.rateSummary = {
                    Amt: this.ratePlanSummary.SubTotal,
                    TaxAmt: this.ratePlanSummary.Tax,
                    SCAmt: this.ratePlanSummary.ServiceCharge,
                    TaxOnSCAmt: this.ratePlanSummary.TaxOnServiceChargeAmount,
                    VAT: this.ratePlanSummary.VAT,
                    VATSCAmt: this.ratePlanSummary.VATOnServiceChargeAmount,
                    Discount: this.ratePlanSummary.Discount,
                    OfferCode: this.ratePlanSummary?.Discount ? this.appliedOfferCode?.OfferCode : null
                }
            }
            this.existingRatePlanSummary = _.cloneDeep(this.ratePlanSummary)
            if (!this.ratePlanSummary.Discount && this.appliedOfferCode?.OfferCode) {
                this.appliedOfferCode = null;
                this.partyService.promoCodeApplied$.next(null);
                this.removeOfferCode = true;
            }
            this.setConfigChanges();
            this.formChangeSubscribe();
        })

    }

    formChangeSubscribe() {
        if (this.negotiateReasonForm) {
            this.negotiateReasonForm.form.valueChanges.subscribe(reasonText => {
                this.setConfigChanges();
            })
        }
    }

    selectedPayeeCard(paymentCard) {
        this.selectedPayeeCardInfo.emit(paymentCard);
        this.partyService.PaymentCard = this.partyService?.selectedPayeeCardInfo(paymentCard, false);
        if (!_.isEmpty(paymentCard)) {
            this.setCardInformation(paymentCard);
        }
    }

    private setCardInformation(creditCard) {
        if (creditCard) {
            this.bookingCreditCard = {
                cardNumber: creditCard.MaskedCardNumber,
                CardHolder: creditCard.CardHolder,
                issuerType: creditCard.CardIssuer,
                cardExpiration: creditCard.ExpiryDate,
                creditCardId: creditCard.CreditCardId
            };
        } else {
            this.bookingCreditCard = null;
        }
        this.bookingCreditCardEvent.emit(this.bookingCreditCard)
    }

    removeOffer() {
        this.appliedOfferCode = null;
        this.partyService.promoCodeApplied$.next(null);
        this.removeOfferCode = true;
        this.getRatePlanForActivity(true);
    }

    discardChanges() {
        this.applyNegotiationBtn.disbaledproperity = true;
        this.negotiateReasonForm.form.controls.reasonText.setValue('');
        this.ratePlanSummary = this.partyService.ratePlanObject.RatePlan = _.cloneDeep(this.originalRatePlan);
        this.partyService.ratePlanObject.ShopItems = _.cloneDeep(this.originalShopItems);
        this.calculateRateSummary();
        this.partyService.ratePlanSummaryState = true;
        this.ps.restrictCloseDialog = false;
        if (this.fromRateChanges || this.dialogData.bookingData?.fromCheckInOrCheckout) {
            this.partyService.getFinancialDetails(this.dialogData.bookingData?.party.Id, this.dialogData.bookingData?.actionType, null, null, true, this.payingGuestsArr).subscribe(data => { });
        }
        else {
            this.getRatePlanForActivity(true)
        }
    }

    calculateRateSummary() {
        if (this.partyService.addToCartRequestObj) {
            this.partyService.addToCartRequestObj.rateSummary = {
                Amt: this.ratePlanSummary.SubTotal,
                TaxAmt: this.ratePlanSummary.Tax,
                SCAmt: this.ratePlanSummary.ServiceCharge,
                TaxOnSCAmt: this.ratePlanSummary.TaxOnServiceChargeAmount,
                VAT: this.ratePlanSummary.VAT,
                VATSCAmt: this.ratePlanSummary.VATOnServiceChargeAmount,
                Discount: this.ratePlanSummary.Discount,
                OfferCode: this.ratePlanSummary?.Discount ? this.appliedOfferCode?.OfferCode : null
            }
        }
    }

    ngAfterViewInit() {
        this.subscriptions.add(this.partyService.promoCodeApplied$.subscribe(data => {
            if (data) {

                this.appliedOfferCode = data.offer;
                this.intializeRatePlan(data.payload);
            }
        }));
        this.subscriptions.add(this.ls.userActionCancelled$.subscribe(data => {
            if (data) {
                this.ps.changedTabIndex = -1;
                this.ps.nextEvent$.next(true);
            }
        }));
    }



    setConfigChanges() {
        this.partyService.ratePlanSummaryState = _.isEqual(this.ratePlanSummary, this.existingRatePlanSummary);
        if (this.dialogData.bookingData?.fromCheckInOrCheckout && !this.partyService.ratePlanSummaryState) {
            this.ps.restrictCloseDialog = true;
        } else if (this.dialogData.bookingData?.fromCheckInOrCheckout && this.partyService.ratePlanSummaryState) {
            this.ps.restrictCloseDialog = false;
        }
        if (this.ratePlanSummary?.ReasonForNegotiation != this.negotiateReasonForm?.form?.value?.reasonText) {
            this.ratePlanSummary.ReasonForNegotiation = this.negotiateReasonForm?.form?.value?.reasonText;
        }
        this.applyNegotiationBtn.disbaledproperity = this.negotiateReasonForm?.form?.value?.reasonText == '' || (JSON.stringify(this.ratePlanSummary) == JSON.stringify(this.existingRatePlanSummary))
        if (this.dialogData.bookingData?.actionType == FinancialEffectAction.WaivedOff) {
            this.ps.restrictCloseDialog = false;
            this.negotiateReasonForm?.form?.value?.reasonText != '' ? this.ps.saveBtnEnable$.next(true) : this.ps.saveBtnEnable$.next(false);
        }
    }

    loadConfig() {

        this.applyNegotiationBtn = {
            type: buttonTypes.actionPrimarySmall,
            label: 'apply',
            disbaledproperity: this.negotiateReasonForm ? this.negotiateReasonForm.form.value && this.ratePlanSummary != this.existingRatePlanSummary ? false : true : true,
            customclass: 'rate-plan__apply',
        }
        this.cancelNegotiationBtn = {
            type: buttonTypes.actionSecondarySmall,
            label: 'reset',
            disbaledproperity: false,
            customclass: 'rate-plan__cancel',
        }
        this.recalculateConfig = [{
            type: 'switch',
            name: 'reCalculateServiceCharge',
            inputType: 'text',
            label: '',
            class: 'rate-plan__recalculate seat-grid-content',
            checked: false,
            value: this.dialogData?.bookingData?.financialData?.RatePlan?.ServiceChargeRecalculate || this.dialogData?.componentDetails?.tabs?.componentInput?.RatePlan?.ServiceChargeRecalculate || false
        }
        ];
        this.rateNegotiateConfig = [
            {
                name: 'reasonText',
                type: 'textarea',
                rows: 1,
                inputType: 'text',
                class: 'w-100 rateplan__reason',
                value: this.dialogData?.componentDetails?.tabs?.componentInput?.RatePlan?.ReasonForNegotiation || this.dialogData?.bookingData?.financialData?.RatePlan?.ReasonForNegotiation || '',
                disableErrorStateMatcher: false,
                blurClick: this.setConfigChanges.bind(this),
                appearance: true
            }]
    }

    ShowRestPolicyDetailsClick() {
        this.ShowRestPolicyDetails(this.restaurantPolicy);
    }

    ShowRestPolicyDetails(restaurantPolicy) {
        const componentDetails: ComponentDetails = {
            componentName: RestaurantPoliciesComponent,
            popupType: 'action',
            popUpDetails: {
                isStepper: false,
                eventName: 'notifyParent'
            },
            popupTitle: restaurantPolicy.PreReservationMessageTitle
        };
        const dialogRef = this.dialog.open(CustomPopupComponent, {
            disableClose: true,
            height: '60%',
            width: '40%',
            data: {
                title: restaurantPolicy.PreReservationMessageTitle,
                update: 'ok',
                componentDetails,
                from: ComponentTypes.restaurantPolicies,
                back: false,
                standalone: true,
                showAction: true
            },
        });

    }
    selectAllGuest(event, session): void {
        let selectedList = [];
        session.GuestPrices.forEach(guest => {
            if (!guest.TicketNumber && event.checked) {
                guest['select'] = event.checked
                selectedList.push(guest);
            } else if (!this.pickedTicketNumber || guest.TicketNumber == this.pickedTicketNumber && event.checked) {
                this.pickedTicketNumber = guest.TicketNumber;
                guest['select'] = event.checked
                selectedList.push(guest);
            } else {
                guest['select'] = false;
            }
        });
        if (selectedList?.length == 0) {
            setTimeout(() => {
                session['select'] = false;
                session['indeterminate'] = false;
            })
        } else if (selectedList?.length && selectedList?.length !== session.GuestPrices?.length) {

            session['select'] = false;
        }
        this.composePayingGuest();
    }

    someComplete(event, guestType): void {
        guestType['selected'] = event.checked;
        this.composePayingGuest();
    }

    payingGuestMapping() {
        let _ticketNumber = '';
        let _preSelectAll = false;
        this.ratePlanSummary.Reservations.forEach(resevation => {
            resevation.Sessions.forEach((session, sessionIndex) => {
                let selectedGuest = this.payingGuestsArr?.find(guest => (guest.ActivitySessionId == session.Id) || !guest.ActivitySessionId) || null;
                session.GuestPrices.forEach((guest, guestIndex) => {
                    guest['PrepaymentState'] = this.getGuestPaymentState(guest);
                    if (selectedGuest?.PayingContacts?.length || selectedGuest?.ContactIdentifier?.length) {
                        guest['select'] = selectedGuest?.PayingContacts?.includes(guest.GuestId) || selectedGuest?.ContactIdentifier?.includes(guest.ContactIdentifier) || false;
                    } else if (guest.PrepaymentState == PartyPrepaymentState.PrepaymentRequired || guest.PrepaymentState == PartyPrepaymentState.PaymentDuePending) {
                        guest['select'] = guest.PrepaymentState == PartyPrepaymentState.PrepaymentRequired || guest.PrepaymentState == PartyPrepaymentState.PaymentDuePending;
                    } else if ((sessionIndex == 0 && guestIndex == 0) || (guest.TicketNumber && _ticketNumber === guest.TicketNumber) || (_preSelectAll)) {
                        if (!_preSelectAll && !session.GuestPrices.some(price => price.Unpaid)) {
                            _ticketNumber = guest.TicketNumber;
                            _preSelectAll = (!guest.TicketNumber && guest.PrepaymentState == PartyPrepaymentState.Prepaid) ? true : false;
                            guest['select'] = true;
                        } else if (_preSelectAll && guest.PrepaymentState == PartyPrepaymentState.Prepaid) {
                            guest['select'] = true;
                        }
                        else {
                            guest['select'] = true;
                        }
                    } else if ((!_ticketNumber && !guest.TicketNumber && guest.PrepaymentState == PartyPrepaymentState.None && guest.Total == 0)) {
                        guest['select'] = true;
                    }
                })
            })
        })
        this.composePayingGuest();
    }

    composePayingGuest() {
        let payingGuestsArr = [];
        let payingGuestTickets = [];
        this.ratePlanSummary.Reservations.forEach(resevation => {
            resevation.Sessions.forEach(session => {
                let _payingGuest = {} as PayingGuest;
                _payingGuest.ActivitySessionId = session?.Id;
                _payingGuest.BookingDate = session.Date;
                _payingGuest.PayingContacts = [];
                _payingGuest.ContactIdentifier = [];
                session.GuestPrices.forEach(guest => {
                    if (guest.select) {
                        if (guest.GuestId) {
                            _payingGuest.PayingContacts.push(guest.GuestId);
                            if (guest.TicketNumber) {
                                payingGuestTickets.push(guest.TicketNumber)
                            }
                        } else if (guest.ContactIdentifier) {
                            _payingGuest.ContactIdentifier.push(guest.ContactIdentifier);
                        }
                    }
                    if ((_payingGuest?.ContactIdentifier?.length || 0) + (_payingGuest?.PayingContacts?.length || 0) == session.GuestPrices.length) {
                        session.select = true;
                        session['indeterminate'] = false;
                    } else if (_payingGuest?.ContactIdentifier?.length > 0 || _payingGuest?.PayingContacts?.length > 0) {
                        session.select = false;
                        session['indeterminate'] = true;
                    } else {
                        session.select = false;
                        session['indeterminate'] = false;
                    }
                })
                if (_payingGuest?.PayingContacts?.length || _payingGuest.ContactIdentifier?.length) {
                    payingGuestsArr.push(_payingGuest);
                }

            })
        })
        this.payingGuestsArr = payingGuestsArr;
        this.ps.restrictCloseDialog = payingGuestsArr?.length == 0;
        this.ratePlanSummary['IsPayeeChanged'] = true;
        if (_.uniq(payingGuestTickets)?.length > 1) {
            this.toastrService.warning(this._ts.instant('transactionWarning'), "", { timeOut: 5000, closeButton: true });
            this.showTransactionError = true;
        } else {
            this.showTransactionError = false;
        }
        this.transactionErrorEvent.emit(this.showTransactionError);
        this.setupGuestDetails();
    }

    setupGuestDetails() {
        if (this.partyService.addToCartRequestObj) {
            this.partyService.addToCartRequestObj['PayingGuests'] = this.payingGuestsArr;
        }
        this._ps.ratePlanObject['PayingGuests'] = this.payingGuestsArr;
        if (this.dialogData?.bookingData?.financialData?.RatePlan) {
            this.dialogData.bookingData.financialData.RatePlan['PayingGuests'] = this.payingGuestsArr;
        }
        this.ratePlanSummary['PayingGuests'] = this.payingGuestsArr;
        let i = 0;
        let reservationGuests = _.uniqBy(_.flatMap(this.partyService.ratePlanObject?.RatePlan.Reservations[0].Sessions, 'GuestPrices'), 'GuestId');
        this._ps.ratePlanObject['PayerDetails'] = [];
        let payingContacts = _.uniq(_.flatMap(this.payingGuestsArr, 'PayingContacts'));
        while (i < payingContacts.length) {
            let guestData = reservationGuests.find(v => v.GuestId == payingContacts[i])
            this._ps.ratePlanObject['PayerDetails'].push({ id: guestData.GuestId, value: guestData.GuestName })
            i++;
        }
        this.fetchPayingGuest();
        // this.payingGuestsArr?.[0].PayingContacts
        // this._ps.ratePlanObject['PayerDetails'] = .map(x => { return { id: x, value: x.GuestName } })
        //   this._ps.ratePlanObject['PayingGuests'] = {...this._ps.ratePlanObject['PayingGuests']}
    }

    fetchPayingGuest() {
        this.payingGuest = [];
        this.payingGuest.push(this.partyService.ratePlanObject.RatePlan.Reservations[0].Sessions[0].GuestPrices?.filter(guest => guest.GuestId)?.map(x => { return { id: x.GuestId, value: x.GuestName } }))
        this.payingGuest = this.payingGuest.flat();
    }

    getGuestPaymentState(guest) {
        let paymentState = PartyPrepaymentState.None;
        if (guest.Total && guest.Unpaid == guest.Total) {
            paymentState = PartyPrepaymentState.PrepaymentRequired;
        } else if (guest.Unpaid > 0) {
            paymentState = PartyPrepaymentState.PaymentDuePending;
        } else if (guest.Unpaid < 0) {
            paymentState = PartyPrepaymentState.RefundDuePending;
        } else if (guest.Total && guest.Paid == guest.Total) {
            paymentState = PartyPrepaymentState.Prepaid
        } else {
            paymentState = PartyPrepaymentState.None;
        }
        return paymentState;
    }





    get requiredCustomGuestFields(): { [key: number]: boolean } {
        let requiredFields = {};
        let activitiesCustomGuestFields = this._settings.customGuestFieldsMappingValidations;
        let selectedActivityId = this.partyService.selectedSpecialMealId ? this.partyService.selectedSpecialMealId : -1;
        let CustomFields = activitiesCustomGuestFields.find(activity => activity.ActivityId == selectedActivityId)?.CustomFields

        CustomFields?.length && CustomFields.forEach(customField => {
            if (customField.Validations?.length) {
                requiredFields[customField.FieldId] = true;
            }
        })

        return requiredFields;
    }

    editGuest(contactId) {
        // if (this.createEditGuestSubscription) {
        //     this.createEditGuestSubscription.unsubscribe();
        // }        
        this.guestBookService.getContact(contactId).subscribe(({ Payload: contact }) => {
            if (contact) {
                contact.updateCard = true;
                this.contactDetails = contact;
                this.guestBookService.createTabsModal(true, true, contact ? true : false, contact, 4);
                this.guestBookService.openDialogAdd('Edit Guest', ComponentTypes.guestBook, { requiredCustomGuestFields: this.requiredCustomGuestFields }, EventIntializers.CREATEGUESTFORRESERVATION);
            }
        })

        //    this.createEditGuestSubscription = this.guestBookService.createEditGuestSubscription$.subscribe((response) => {});
    }

    updateAllComplete() {
        // this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
    }

    getRatePlanDTO(party) {
        let partyDetails = this.partyService.Parties$?.value?.find(data => data.Id == party.Id);
        this.getSelectedAddons(partyDetails);
        if (partyDetails.SpecialMealId && partyDetails.BookedSessions) {
            return {
                Slots: [],
                SessionSlot: {
                    Sessions: [
                        {
                            Date: partyDetails?.StartDate,
                            SessionIds: [partyDetails?.BookedSessions[0]?.ActivitySessionId],
                        }
                    ],
                    SessionGroupId: null,
                    OverBooked: partyDetails?.OverBooked || 0
                },
                Size: partyDetails.Size,
                TableIds: [],
                CoverTypes: partyDetails.CoverTypeQuantities,
                ClearDiscount: this.removeOfferCode || null,
                ActivityId: partyDetails.ActivityId || partyDetails.SpecialMealId,
                FinancialEffectId: null,
                BookingBehavior: BookingBehavior.ClassOrSession,
                StartDate: partyDetails.StartDate,
                EndDate: partyDetails.EndDate,
                BookingContacts: partyDetails.BookingContacts,
                RatePlan: null,
                AddOns: [],
                InstructorIds: partyDetails.StaffIds || [],
                PartyId: partyDetails.Id
            };
        }
        if (!partyDetails.SpecialMealId || partyDetails.SpecialMealId) {
            return {
                Slots: [{
                    Time: partyDetails.SeatingTime,
                    DurationInMinutes: partyDetails.Duration,
                    EndTime: partyDetails?.EndTime,
                    PartyId: partyDetails.Id,
                    AllocationTypeId: null
                }],
                SessionSlot: {
                    Sessions: [],
                },
                Size: partyDetails.Size,
                TableIds: partyDetails.TableIds || [],
                CoverTypes: partyDetails.CoverTypeQuantities,
                ActivityId: partyDetails.ActivityId || partyDetails.SpecialMealId || null,
                FinancialEffectId: null,
                BookingBehavior: !partyDetails.SpecialMealId ? BookingBehavior.OpenBooking : BookingBehavior.PrivateLesson,
                StartDate: partyDetails.SeatingTime,
                EndDate: partyDetails.SeatingTime,
                BookingContacts: partyDetails.BookingContacts,
                RatePlan: null,
                AddOns: [],
                InstructorIds: partyDetails.StaffIds || [],
                PartyId: partyDetails.Id
            };
        }
    }

    getSelectedAddons(partyData) {
        partyData.BookingContacts?.map((bookingContact) => {
            bookingContact.Addons = [];
            if (partyData.BookingContactAddonItems?.length)
                partyData.BookingContactAddonItems.map(addon => {
                    if (bookingContact.Id === addon.BookingContactId)
                        bookingContact.Addons.push({ AddonId: addon.AddonId, Quantity: addon.AddonCount });
                })
        })
    }

    ngOnDestroy(): void {
        this.appliedOfferCode = null;
        this.partyService.currentRatePlanRequest = null;
        this.partyService.promoCodeApplied$.next(null);
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
        this.partyService.PaymentCard = null;
        this.partyService.PayeeId = null;
        if (this.partyService.addToCartRequestObj) {
            this.partyService.addToCartRequestObj.PaymentCard = null;
        }
    }

}


@Pipe({
    name: 'getActivityName'
})
export class SpecialMealNamePipe implements PipeTransform {
    constructor(private ts: TranslateService) { }
    transform(specialMealId, settings): any {
        return settings.SpecialMeals.find(meal => meal.Id == specialMealId)?.Name || this.ts.instant('OpenBooking');
    }
}


export function getMappedCreditCard(creditCards: any[], payeeId: number, reservationCardId: number): any{
    let availableCards;

    if(!creditCards?.length) return null;

    if(payeeId) {
        availableCards = creditCards.filter(({ContactId}) => ContactId === payeeId);
    }else{
        availableCards = creditCards;
    }
    
    const recentlyUsedCard = availableCards.find(card => card.RecentlyUsed);
    if(recentlyUsedCard) return recentlyUsedCard;

    const reservationCreditCard = availableCards.find(card => card.CreditCardId === reservationCardId)
    if(reservationCreditCard) return reservationCreditCard;

    return availableCards.find(card => card.IsDefault) || availableCards[0];
}