import { Injectable } from "@angular/core";
import { TableHeaderOptions } from "src/app/common/Models/ag-models";
import { SorTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

@Injectable()
export class LockerControlBusiness {

    captions;
    constructor(
        public localization: RetailLocalization) {
          this.captions = this.localization.captions
    }

    getInuseHeaderOptions(): TableHeaderOptions[]{
        return[
          {
            key: 'lockerBookingStartTime',
            displayName: this.captions.tbl_hdr_beginAt,
            displayNameId: 'lockerBookingStartTime',
            sorting: true,
          },
          {
            key: 'lockerBookingEndTime',
            displayName: this.captions.tbl_hdr_endAt,
            displayNameId: 'lockerBookingStartTime',
            sorting: true,
          },
          {
            key: 'lockerName',
            displayName: this.captions.tbl_hdr_lockerName,
            displayNameId: 'tbl_hdr_lockerName',
            sorting: true,
            searchable: true
          },
          {
            key: 'lockerNumber',
            displayName: this.captions.tbl_hdr_lockerNumber,
            displayNameId: 'tbl_hdr_lockerNumber',
            sorting: true,
            searchable: true
          },
          {
            key: 'clientName',
            displayName: this.captions.tbl_hdr_client,
            displayNameId: 'tbl_hdr_client',
            sorting: true,
            searchable: true
          },
          {
            key: 'service',
            displayName: this.captions.service,
            displayNameId: 'service',
            sorting: true,
            searchable: true
          },
          {
            key: 'appointmentTime',
            displayName: this.captions.Lockers.lbl_Time,
            displayNameId: 'tbl_hdr_time',
            sorting: true,
            searchable: false
          },
          {
            key: 'duration',
            displayName: this.captions.Lockers.duration,
            displayNameId: 'duration',
            sorting: true,
            searchable: false
          },
          {
            key: 'comments',
            displayName: this.captions.lbl_comments,
            displayNameId: 'comments',
            sorting: true,
            searchable: true
          },
        ]
      }
      
      getOutOfOrderHeaderOptions(){
        return[
            {
              key: 'lockerCode',
              displayName: this.captions.Lockers.lockerCode,
              displayNameId: 'lockerCode',
              sorting: true,
              searchable: true
            },
            {
              key: 'lockerName',
              displayName: this.captions.Lockers.lockerName,
              displayNameId: 'lockerName',
              sorting: true,
              searchable: true
            },
            {
              key: 'outofOrderQuantity',
              displayName: this.captions.Lockers.outoforder,
              displayNameId: 'outOfOrder',
              sorting: true,
            },
            {
              key: 'remainingQuantity',
              displayName: this.captions.Lockers.remaining,
              displayNameId: 'remaining',
              sorting: true,
            }
          ]
      }

      getTableOptions() {
        return {
          defaultSortOrder: SorTypeEnum.asc,
          defaultsortingColoumnKey: 'date',
          uniqueKey: 'date',
        }
      }

      getOutOfOrderTableOptions() {
        return {
            defaultSortOrder: SorTypeEnum.asc,
            defaultsortingColoumnKey: 'rentalItemName',
            uniqueKey: 'rentalItemName',
          }
      }
}