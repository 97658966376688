import { Component, Input, ViewEncapsulation } from "@angular/core";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

@Component({
  selector: "app-recent-searches",
  templateUrl: "./recent-searches.component.html",
  styleUrls: ["./recent-searches.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class RecentSearchesComponent  {
  captions: any;
  @Input() recentSearchResults;
  constructor(private localization: RetailLocalization) {
    this.captions = this.localization.captions.shop.GiftCard;
  }
}
