import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import * as _ from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { PostTypeLinking, RevenuePostingPrimaryType, RevenuePostTypes, SubPropertyModel,MappingScreen, PostingType } from '../../retail.modals';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { ButtonType, FeatureValue, GridType } from '../../shared/globalsContant';
import { AlertAction, AlertType } from '../../shared/shared.modal';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { PostTypeBusiness } from '../post-type-mapping/post-type-mapping.business';
import { PropertyInfo } from '../../shared/business/shared.modals';
import { FeatureName, RetailConstants } from '../../shared/service/retail.feature.flag.information.service';
import { PropertyFeature } from '../../shared/business/property-features.model';
import { PropertyFeaturesConfigurationService } from '../../sytem-config/payment-features-config/property-feature-config.service';
import { DialogOverviewExampleDialog } from 'src/app/retail/shared/dialog-popup/dialogPopup-componenet';
import { ButtonValue } from 'src/app/common/Models/ag-models';


@Component({
  selector: 'app-resort-finance-post-type-mapping',
  templateUrl: './resort-finance-post-type-mapping.component.html',
  styleUrls: ['./resort-finance-post-type-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResortFinancePostTypeMappingComponent implements OnInit, OnDestroy {
  useRetailInterface: boolean;
  captions: any;
  IsViewOnly = false;
  tableoptions: any[];
  dataSource = [];
  isValid = false;
  isDisabled = true;
  postTypeValues = [];
  selectedPostTypeValue = RevenuePostTypes.Discount; // Default selected value on load as per alphabet order
  searchText: string;
  searchPlaceholderValue: any;
  maxInputLength = 100;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  tableHeader: { title: any; jsonkey: string; sortable: boolean; sortcolumn?: string; sortcolumndatatype?: string; highlightbold?: boolean; type?: string; alignType?:string }[];
  commonCaptions: any;
  outlets: SubPropertyModel[];
  category: RevenuePostingPrimaryType[];
  postTypeMappingsData: PostTypeLinking[] = [];
  previousSelectedData: any;
  updatedSelectedData: any;
  selectedColumnName: any;
  OriginalPostTypeMappingsData: PostTypeLinking[];
  ResortFinancePostTypeMappingsData: PostTypeLinking[];
  functionalities: { [key: string]: boolean} = {};
  showOutletDetails = true;
  defaultOutlet: number ;
  floatLabel: string;
  floatLabelNever: string;
  pmsPropCode: string;  
  propertyList: PropertyInfo[] = [];  
  propertyDropDownList: PropertyInfo[] = []; 
  selectedProperty: PropertyInfo = {PropertyName : '', PropCode: ''};
  currentSelectedPostType; 
  isCopyFromPostTypeMapping : boolean = false;
  proceedButton: ButtonValue;
  propConfig: any = [];
  IsVersaFolioSameProperty: boolean = false;

  get MultiPropertyFeatureConfig() {
    let featureConfig: PropertyFeature;
    try {
      let propertyFeatureStr: any = sessionStorage.getItem("PropertyFeatureConfigurations");
      if (propertyFeatureStr) {
        let propertyFeatures: PropertyFeature[] = JSON.parse(propertyFeatureStr);
        featureConfig = propertyFeatures.find(p => p.featureName == FeatureName.MultiPMSRoomGroupCharge);
      }
    }
    catch (err) {
      console.log("Error Occurred while getting Multi Properties Feature value")
    }
    return featureConfig;
  }

  constructor(private dialog: MatDialog, private router: Router, private localization: RetailLocalization, private postTypeBusiness: PostTypeBusiness,
    private _rs: RetailSetupService,
    private utilities: RetailUtilities, private func: RetailFunctionalityBusiness, public propertyInfo: RetailPropertyInformation, private propertyFeatureservice: PropertyFeaturesConfigurationService) {
      this.floatLabel = this.localization.setFloatLabel;
      this.floatLabelNever = this.localization.setFloatLabelNever;
      this.propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
      this.IsVersaFolioSameProperty = this.propConfig?.FolioPropertyId == this.propConfig?.PMSPropertyID && !this.MultiPropertyFeatureConfig;
     }

  ngOnInit() {
    this.InitializeComponent();
  }

  async InitializeComponent(){
    this.captions = this.localization.captions.retailsetup;
    this.commonCaptions = this.localization.captions.common;
    this.proceedButton = {
      type: 'primary',
      label: this.commonCaptions.CopyFromPostTypeMapping,
      disabledproperty: false
    }
    this.postTypeValues = [
      { id: 2, description: this.captions.Discount },
      { id: 6, description: this.captions.Gratuity },
      { id: 3, description: this.captions.PaymentMethod },
      { id: 5, description: this.captions.ServiceCharge },
      { id: 1, description: this.captions.Settlement },
      { id: 4, description: this.captions.taxes },
      { id: 8, description: this.captions.Surcharges }
    ];
    this.searchPlaceholderValue = this.GetSearchPlaceHolderByPostType(RevenuePostTypes.Discount);
    this.defaultOutlet = this.propertyInfo.GetDefaultOutlet();
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
      this.showOutletDetails = this.functionalities.ShowOutletDetailsInPostTypeMapping ? this.functionalities.ShowOutletDetailsInPostTypeMapping:false;
      this.getData();
    });    
     this.IsViewOnly = this._rs.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.ResortFinancePostTypeMapping).view;
  }

  async getData() {
    let [outlets, category, postTypeMappings] = await Promise.all([this.postTypeBusiness.GetActiveOutlets(),
    this.postTypeBusiness.GetPrimaryTypeMapping(RevenuePostTypes.Discount,MappingScreen.ResortFinancePostTypeMapping),
    (this.postTypeBusiness.GetPostTypeMappingForResortFinance(PostingType.ResortFinance))]);
    
    this.outlets = outlets;
    this.category = category;
    if (!this.showOutletDetails && this.defaultOutlet === 0 ){
      this.defaultOutlet = await this.func.getDefaultOutlet();
      if (this.defaultOutlet > 0 ) {
        this.propertyInfo.SetDefaultOutlet(this.defaultOutlet);
      }
    }
    this.OriginalPostTypeMappingsData = this.formTableDataTemp(postTypeMappings);
    this.ResortFinancePostTypeMappingsData = _.cloneDeep(this.OriginalPostTypeMappingsData);
    this.postTypeMappingsData = this.formTableDataTemp(postTypeMappings);
    this.BindToGrid();
  }

  formTableDataTemp(postTypemappingsData: PostTypeLinking[] = []) {
    const OriginalTableData: PostTypeLinking[] = _.cloneDeep(postTypemappingsData);
    this.category.forEach(cat => {
      this.outlets.forEach(out => {
        if (Array.isArray(postTypemappingsData)) {
          let isPostTypeAvailable;
          if (this.selectedPostTypeValue === RevenuePostTypes.PaymentMethod) {
            isPostTypeAvailable = postTypemappingsData.find(o => o.outletId === out.subPropertyID && o.linkingId === cat.id && (o.linkType == cat.linkType));
          } else {
            isPostTypeAvailable = postTypemappingsData.find(o => o.outletId === out.subPropertyID && o.linkingId === cat.id && o.linkType === this.selectedPostTypeValue && o.postingType == PostingType.ResortFinance);
          }

          if (!isPostTypeAvailable) {
            OriginalTableData.push({
              id: 0,
              outletId: out.subPropertyID,
              postCode: '—',
              linkingId: cat.id,
              linkType: cat.linkType,
              propCode: this.pmsPropCode,
              postingType: PostingType.ResortFinance
            });
          }
        }
      });
    });
    return OriginalTableData;
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  BindToGrid() {
    const data = this.formTableData();
    this.tableoptions = [
      {
        TableHdrData: data[0],
        TablebodyData: data[1],
        pagination: false,
        sortable: true,
        CustomColumn: false,
        PlaceHoldertext: this.captions.Search,
        EnableActions: false,
        SelectRows: false,
        IsCommission: false,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: GridType.posttypemapping,
        Sortable: 'typecategory',
        TableId: GridType.posttypemapping,
        disableDelete: false,
        customHeader: false,
        pageTitle: 'posttypemapping',
        ServiceId: 'posttypemapping',
        IsViewOnly: this.IsViewOnly,
        IsReadOnly: this.IsViewOnly,
        TableDraggable: false,
        bufferCount: 20

      }
    ];
  }

  formTableData() {
    this.tableHeader = [{ title: this.GetTextByPostType(), jsonkey: 'typecategory', sortable: true, highlightbold: true }];
    this.dataSource = [];
    if (this.showOutletDetails) {
      this.outlets.forEach(res => {
        this.tableHeader.push({
          title: res.subPropertyName,
          jsonkey: res.subPropertyName,
          sortcolumn: res.subPropertyName + 'number',
          sortcolumndatatype: 'number',
          type: 'showOnHover',
          sortable: true,
          alignType: 'right'
        });
        return res;
      });
    }
    else {
      const outlet = this.defaultOutlet > 0 ? this.outlets.find(x => x.subPropertyID === this.defaultOutlet) : this.outlets[0];
      this.tableHeader.push({
        title: this.commonCaptions.postType,
        jsonkey: outlet.subPropertyName,
        sortcolumn: outlet.subPropertyName + 'number',
        sortcolumndatatype: 'number',
        type: 'showOnHover',
        sortable: true
      });
    }
    this.category.forEach(res => {
      let resultData: any = {};
      if (Array.isArray(this.postTypeMappingsData)) {
        this.postTypeMappingsData.forEach(data => {
          if (res.id === data.linkingId && res.linkType == data.linkType && ((data.postingType == PostingType.ResortFinance && data.id > 0) || data.id == 0)) {
            this.outlets.forEach(o => {
              if (this.selectedPostTypeValue === RevenuePostTypes.PaymentMethod && o.subPropertyID === data.outletId && (data.linkType === this.selectedPostTypeValue || data.linkType === RevenuePostTypes.Card)) {
                resultData.typecategory = res.name;
                resultData[o.subPropertyName] = data.postCode;
                resultData[o.subPropertyName + 'number'] = data.postCode && data.postCode !== '—' ? Number(data.postCode) : 0;
                resultData.id = data.id ? data.id : 0;
                resultData.category = res.name ? res.name : '';
                resultData.categoryId = data.linkingId ? data.linkingId : 0;
                resultData.outletId = data.outletId ? data.outletId : 0;
                resultData.linkType = data.linkType ? data.linkType : 0;
              }
              else if (o.subPropertyID === data.outletId && data.linkType === this.selectedPostTypeValue) {
                resultData.typecategory = res.name;
                if (!this.propertyInfo.IsVATEnabled) {
                  resultData[o.subPropertyName] = this.selectedPostTypeValue === RevenuePostTypes.Tax && res.outletId != o.subPropertyID ? "" : data.postCode;
                }
                else {
                  resultData[o.subPropertyName] = data.postCode;
                }
                resultData[o.subPropertyName + 'number'] = data.postCode && data.postCode !== '—' ? Number(data.postCode) : 0;
                resultData.id = data.id ? data.id : 0;
                resultData.category = res.name ? res.name : '';
                resultData.categoryId = data.linkingId ? data.linkingId : 0;
                resultData.outletId = data.outletId ? data.outletId : 0;
                resultData.linkType = data.linkType ? data.linkType : 0;
              }
            });
          }
        });
      }
      if (Object.keys(resultData).length) this.dataSource.push(resultData);
      resultData = {};
    });
    return [this.tableHeader, this.dataSource];
  }

  rowDataClicked(event) {
    this.openDialog(this.captions.SelectRevenuePostCode, 'RPTM', event);
    const outlet = this.outlets.filter(o => o.subPropertyName === event[1]);
    const oldData = this.postTypeMappingsData.filter(res => res.postCode === event[0][event[1]] && res.linkingId === event[0].categoryId && res.outletId === outlet[0].subPropertyID);
    this.previousSelectedData = oldData && oldData.length ? oldData : [event[0]];
    this.selectedColumnName = event[1];
  }

  resetValue() {
    this.searchText = '';
  }

  searchValue(event) {
    this.searchText = event.target.value;
  }

  openDialog(title, templateName, data) {
    const dialogRef = this.dialog.open(RetailPopupComponent, {
      width: '45%',
      height: '85%',
      disableClose: true,
      data: { headername: title, closebool: true, templatename: templateName, datarecord: data, LinkType: this.selectedPostTypeValue, isMulitPropertyFeatureEnabled: false, pmsPropCode: this.pmsPropCode, selectedPostType: this.selectedPostTypeValue },
      hasBackdrop: true,
      panelClass: ''
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if (result && templateName == 'RPTM') {
        this.updatedSelectedData = result;
        const filteredData = [];
        let postTypeIndex = null;
        if (!result.isAllCategory) {
          if (this.selectedPostTypeValue === RevenuePostTypes.PaymentMethod) {
            postTypeIndex = this.postTypeMappingsData.findIndex(m => m.linkType == this.previousSelectedData[0].linkType && m.linkingId === this.previousSelectedData[0].linkingId && m.outletId === this.previousSelectedData[0].outletId);
          }
          else {
            postTypeIndex = this.postTypeMappingsData.findIndex(m => m.linkType === this.selectedPostTypeValue && m.linkingId === this.previousSelectedData[0].linkingId && m.outletId === this.previousSelectedData[0].outletId);
          }
          if (postTypeIndex !== -1) {
            this.postTypeMappingsData[postTypeIndex].postCode = result.postCode ? result.postCode.toString() : '—';
            this.postTypeMappingsData[postTypeIndex].isUpdated = true;
            this.isDisabled = false;
            this.isValid = true;  
            this.postTypeMappingsData[postTypeIndex].postingType = PostingType.ResortFinance;
            this.postTypeMappingsData = _.cloneDeep(this.postTypeMappingsData);
          }
        }
        else {
          if (this.selectedPostTypeValue == RevenuePostTypes.PaymentMethod) {
            for (let i = 0; i < this.postTypeMappingsData.length; i++) {
              if ((this.postTypeMappingsData[i].linkType == RevenuePostTypes.PaymentMethod || this.postTypeMappingsData[i].linkType == RevenuePostTypes.Card) && this.postTypeMappingsData[i].outletId == this.previousSelectedData[0].outletId) {
                this.postTypeMappingsData[i].postCode = result.postCode ? result.postCode : '—';
                this.postTypeMappingsData[i].isUpdated = true;
              }
            }
          }
          else {
            for(let i=0;i<this.postTypeMappingsData.length;i++)
              {
                if(this.postTypeMappingsData[i].linkType==this.selectedPostTypeValue&&this.postTypeMappingsData[i].outletId==this.previousSelectedData[0].outletId)
                {
                  this.postTypeMappingsData[i].postCode=result.postCode?result.postCode:'—';
                  this.postTypeMappingsData[i].isUpdated=true;
                }
                this.isDisabled = false;
                this.isValid = true;
              }
              this.isDisabled=false;
              this.isValid=true; 
            }   

      console.log('ResortFinancePostTypeMappingComponent  -> openDialog -> this.updatedPostTypeMappings', this.postTypeMappingsData);
      this.BindToGrid();
      }
    }
    });
  }

  postTypeChange(event) {
    this.utilities.ToggleLoader(true);
    this.searchPlaceholderValue = this.GetSearchPlaceHolderByPostType(event.value);
    this.postTypeBusiness.GetPrimaryTypeMapping(event.value,MappingScreen.ResortFinancePostTypeMapping).then(res => {
      this.category = res;
      this.postTypeMappingsData = this.formTableDataTemp(this.postTypeMappingsData);
      this.BindToGrid();
      this.currentSelectedPostType = event.value;
      this.utilities.ToggleLoader(false);
    });
  }

  GetTextByPostType(value: number = this.selectedPostTypeValue) {
    switch (value) {
      case RevenuePostTypes.Settlement:
      case RevenuePostTypes.Discount:
      case RevenuePostTypes.ServiceCharge:
      case RevenuePostTypes.Gratuity:
        return this.captions.Category;
      case RevenuePostTypes.PaymentMethod:
        return this.captions.PaymentMethod;
      case RevenuePostTypes.Tax:
        return this.captions.Tax;
      case RevenuePostTypes.Surcharge:
        return this.captions.CardTypes;
    }
  }

  GetSearchPlaceHolderByPostType(value: number = this.selectedPostTypeValue) {
    switch (value) {
      case RevenuePostTypes.Settlement:
      case RevenuePostTypes.Discount:
      case RevenuePostTypes.ServiceCharge:
      case RevenuePostTypes.Gratuity:
        return this.captions.SearchByCategories;
      case RevenuePostTypes.PaymentMethod:
        return this.captions.SearchByPaymentMethod;
      case RevenuePostTypes.Tax:
        return this.captions.SearchByTax;
      case RevenuePostTypes.Surcharge:
        return this.captions.CardTypes;
    }
  }

  async savePostTypeMappings(event) {
    let updatedPostTypes = this.postTypeMappingsData.filter(s => s.isUpdated && s.postCode !== '—');
    if(!this.showOutletDetails)
      {
      const selectedPostTypes = updatedPostTypes;
      selectedPostTypes.forEach( uPost => {
        this.outlets.forEach( x =>
          {
          const outletId = this.defaultOutlet > 0 ? this.defaultOutlet : this.outlets[0].subPropertyID
          if(x.subPropertyID !== outletId)
            {
            let result = this.postTypeMappingsData.find(post => post.outletId ==x.subPropertyID && post.linkingId == uPost.linkingId && post.linkType == uPost.linkType);
            result.isUpdated = uPost.isUpdated;
            result.linkType = uPost.linkType;
            result.linkingId = uPost.linkingId;
            result.postCode= uPost.postCode;

            updatedPostTypes.push(result);

          }
        })
      })

    }
    if (this.isCopyFromPostTypeMapping) {
      await this.postTypeBusiness.DeletePostTypeMapping([0]);
    }
    else {
      let deletePostTypes = this.postTypeMappingsData.filter(s => s.isUpdated && s.id && s.postCode === '—').map(t => t.id);
      if (this.showOutletDetails && deletePostTypes.length > 0) {
        let deletedPostTypes: PostTypeLinking[] = [];
        const selectedPostTypeIdsForDelete = deletePostTypes;
        selectedPostTypeIdsForDelete.forEach(del => {
          deletedPostTypes.push(this.postTypeMappingsData.find(post => post.id == del));
        });

        deletedPostTypes.forEach(dPost => {
          this.outlets.forEach(x => {
            this.postTypeMappingsData.find(post => post.outletId == x.subPropertyID && post.linkingId == dPost.linkingId && post.linkType == dPost.linkType).isUpdated = dPost.isUpdated;
            this.postTypeMappingsData.find(post => post.outletId == x.subPropertyID && post.linkingId == dPost.linkingId && post.linkType == dPost.linkType).postCode = dPost.postCode;


          })
        })
      }

      if (deletePostTypes && deletePostTypes.length) {
        await this.postTypeBusiness.DeletePostTypeMapping(deletePostTypes);
      }
    }
    if (updatedPostTypes && updatedPostTypes.length) {
      await this.postTypeBusiness.CreatePostTypeMapping(updatedPostTypes);
    }
    let postTypeMappings = await this.postTypeBusiness.GetPostTypeMappingForResortFinance(PostingType.ResortFinance);
    this.OriginalPostTypeMappingsData = this.formTableDataTemp(postTypeMappings);
    this.ResortFinancePostTypeMappingsData = _.cloneDeep(this.OriginalPostTypeMappingsData);
    this.postTypeMappingsData = this.formTableDataTemp(postTypeMappings);
    this.BindToGrid();
    this.isValid = false;
    this.isCopyFromPostTypeMapping = false;
  }

  cancelPostTypeMappings() {
    if (this.isValid){
      this.utilities.showAlert(this.commonCaptions.saveChangesMessage, AlertType.Warning, ButtonType.YesNo, (res) => {
         if (res === AlertAction.YES) {
          this.postTypeMappingsData = this.isCopyFromPostTypeMapping ? this.formTableDataTemp(this.ResortFinancePostTypeMappingsData) : this.formTableDataTemp(this.OriginalPostTypeMappingsData);
          this.isCopyFromPostTypeMapping = false;
          this.BindToGrid();
          this.isValid = false;
         }
        });
     }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.isValid) {
      return this.confirm();
    }
    return true;
  }

  confirm(): Observable<boolean> {
    const confirmation = this.utilities.showAlert(this.commonCaptions.saveChangesMessage, AlertType.Warning, ButtonType.YesNo);
    return confirmation.afterClosed().pipe(map((a) => {
      if (a === AlertAction.YES) {
        return true;
      } else {
        this.isValid = true;
        return false;
      }
    }));
  }

  async ReloadPostTypesBasedOnPropertySelection(){
    this.utilities.ToggleLoader(true);
    let postTypeMappings = await this.postTypeBusiness.GetPostTypeMappingForResortFinance(PostingType.ResortFinance);
    
    this.OriginalPostTypeMappingsData = this.formTableDataTemp(postTypeMappings);
    this.postTypeMappingsData = this.formTableDataTemp(postTypeMappings);  
    this.BindToGrid();
    
    this.utilities.ToggleLoader(false);
  }

  copyAllFromPostTypeMapping() {
    this.openConfirmationDialog(this.commonCaptions.Confirmation, this.commonCaptions.CopyConfirmation);
  }

  openConfirmationDialog(title, data) {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      height: 'auto',
      width: '40%',
      disableClose: true,
      data: { headername: title, datarecord: data, buttonType: 1 },
      hasBackdrop: true,
      panelClass: ''
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "YES") {
        this.GetDataForCopyAll();
      }
    });
  }

  async GetDataForCopyAll() {
    this.utilities.ToggleLoader(true);
    this.isCopyFromPostTypeMapping = true;
    let postTypeMappings = await this.postTypeBusiness.GetPostTypeMapping();
    this.OriginalPostTypeMappingsData = this.formTableDataTemp(postTypeMappings);
    this.postTypeMappingsData = this.formTableDataTemp(postTypeMappings);
    this.postTypeMappingsData.map(x => {
      x.id = 0;
      x.isUpdated = true;
      x.postingType = PostingType.ResortFinance
    });
    this.BindToGrid();
    this.isDisabled = false;
    this.isValid = true;
    this.utilities.ToggleLoader(false);
  }
}
