<div class="floorplan-editor">
  <div class="floorplan-editor__content">
    <div class="floorplan-editor__content-header">
      <div class="floorplan-editor__content-header-viewby">
        <div *ngIf="!hideViewByFloors">
        <label class="floorplan-editor__content-header-name">{{'viewBy' | translate}}</label>
        <dynamic-form [config]="config" #form="dynamicForm"></dynamic-form>
        </div>
        <div class="floorplan-editor__content-floorplan" [ngClass]="{'floorplan-editor__no-padding': hideViewByFloors}">
          <!-- Multiple Layout Changes -->
           <label class="floorplan-editor__content-header-name">{{'floorPlanMenuText' | translate}}</label>
           <dynamic-form [config]="floorPlanSelctionConfig" #form="dynamicForm"></dynamic-form>

        <!-- <a (click)="addNewLayout(true)" class="icon-Group-7106 floorplan-editor__action-btn"></a>
        <a (click)="addNewLayout(false)"[disabled]="true" class="icon-Group-7105 floorplan-editor__action-btn"></a> -->
      </div>
      <div class="floorplan-editor__content-header-buttons" >
        <activities-app-button [buttontype]="addBtn"  (click)="addNewLayout(true)"></activities-app-button>
        <activities-app-button [buttontype]="editBtn"  (click)="addNewLayout(false)"></activities-app-button>
        <activities-app-button [buttontype]="deleteBtn"  (click)="deleteLayout()"></activities-app-button>
      </div>
      </div>
      <div class="floorplan-editor__content-header-actions" *ngIf="!fs.equal">
        <activities-app-button [buttontype]="saveData" (click)="save()"></activities-app-button>
        <activities-app-button [buttontype]="cancelData" (click)="cancel()"></activities-app-button>
      </div>
    </div>
    <div #layoutView class="floorplan-editor__layout-view" style="position: relative;">
      <!-- <ng-scrollbar track="horizontal"> -->
        <canvas id="editorCanvas" class="floorplan-editor__canvas-view" #editorCanvas></canvas>
        <div id="inline-btn" style="position:absolute;top: 0px; left: 0px"></div>
      <!-- </ng-scrollbar> -->
    </div>
  </div>
  <div class=" floorplan-editor__side-panel">
    <app-dashboard [dashboardData]="dashboardData" (createReservation)="action($event)"
      (tabChangeEvent)="tabChanged($event)"></app-dashboard>
</div>