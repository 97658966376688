import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentCheckoutWithTransaction, AppointmentLineNumber, TransactionDetail, Transaction } from '@app/common/shared/shared/business/shared.modals';
import { Host } from '@app/common/shared/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '@app/common/shared/shared/service/http-call.service';
import { CommonUtilities, RedirectToModules } from '@app/common/shared/shared/utilities/common-utilities';
import { RetailLocalization } from '@app/retail/common/localization/retail-localization';
import { UserSessionConfiguration } from '@app/retail/retail.modals';
import { RetailService } from '@app/retail/retail.service';
import { BaseResponse } from '@app/retail/shared/business/shared.modals';
import { DepositEventModel } from '@app/retail/shared/events/event.model';
import { RetailSharedVariableService } from '@app/retail/shared/retail.shared.variable.service';
import { RetailValidationService } from '@app/retail/shared/retail.validation.service';
import { CommonVariablesService, SelectedProducts, ServiceCharge } from '@app/retail/shared/service/common-variables.service';

import _ from 'lodash';
import { BookingBehavior, FinancialEffectType, Operations, PaymentGateways } from '../constants/commonenums';
import { RetailOpenItemDTO, ServiceChargeGratuity, ShopItem } from '../models/RestaurantDTO';
import { RetailUserClaimsDTO } from '../models/RolePermissionMappingDTO';
import { HttpService } from './http.service';
import { FinancialEffectResponse } from '../models/FinancialEffect.model';

@Injectable({
  providedIn: 'root'
})
export class seatRetailService {
  bookingAttemptData: any;
  cancelFinancialEffectData: any;
  operationType: any;
  oldReservation: any = {};
  constructor(private retailSharedService: RetailSharedVariableService, private utils: CommonUtilities, private localization: RetailLocalization,
    public httpService: HttpService, public http: HttpServiceCall, private route: Router,
    private _shopservice: CommonVariablesService, private retailValidationService: RetailValidationService,
    private _ams: RetailService) {
  }

  CheckOutCallback(transaction: Transaction, CheckOutAppointmentList: AppointmentLineNumber[]) {
    let appointments: AppointmentCheckoutWithTransaction[] = [];
    if (CheckOutAppointmentList && CheckOutAppointmentList.length > 0) {
      for (const element of CheckOutAppointmentList) {
        let transactionDetail: TransactionDetail = transaction.transactionDetails.find(x => x.lineNumber == element.LineNumber);
        if (transactionDetail) {
          let gratuity: number = 0, serviceCharge: number = 0;

          if (transactionDetail.serviceChargeGratuity && transactionDetail.serviceChargeGratuity.length > 0) {
            let gratuityObj = transactionDetail.serviceChargeGratuity.find(x => x.totalGratuity > 0)
            gratuity = gratuityObj && gratuityObj.totalGratuity > 0 ? gratuityObj.totalGratuity : 0;
            let serviceChargeObj = transactionDetail.serviceChargeGratuity.find(x => x.totalServiceCharge > 0)
            serviceCharge = serviceChargeObj && serviceChargeObj.totalServiceCharge > 0 ? serviceChargeObj.totalServiceCharge : 0;
          }

          appointments.push({
            appointmentId: element.appointmentId,
            gratuity: gratuity,
            serviceCharge: serviceCharge,
            tax: transactionDetail.tax,
            transactionDetailId: transactionDetail.id,
            transactionId: transaction.transactionData.id,
            //addOnId: element.addOnId,
            //retailTicketNumber: transaction.transactionData.retailTicketNumber
          });
        }
      }
      //  if (appointments && appointments.length > 0)
      //   this.AppointmentCheckoutWithTransaction(appointments);
    }
  }
  async SetSeatOpenItemToRetail(seatProduct: RetailOpenItemDTO[], OperationType?, TranactionId?, TicketNumber?, ContactId?, reservationAttemptData?: FinancialEffectResponse, financialData?: FinancialEffectResponse, membershipNumber?) {
    
  }


  // TranactionId : last retail transaction id
  //financialData : 
  async SetShopItems(seatProduct: ShopItem[], Payments, OperationType?, reservationId?, TranactionId?,
    TicketNumber?, ContactId?, reservationAttemptData?, financialData?, membershipNumber?,reason?, paidBy?) {
    this._shopservice.isOperationFromActivities = true;
    this.bookingAttemptData = _.cloneDeep(financialData);
   // if (OperationType == Operations.update || OperationType == Operations.create)
      //this._shopservice.depositFlag = true;
    let SeatItems: any[] = [];
    let depositAll: DepositEventModel[] = [];
    this.retailSharedService.SelecedSeatProducts = [];
    this._shopservice.isFirstInitialisation = false;
    // need to check the retailItems
    const userMachineConfig = this.getLoggedinUserSessionConfiguration();
    this.retailSharedService.SelectedOutletId = userMachineConfig?.defaultOutletId;
    this.retailSharedService.SelectedTerminalId = userMachineConfig?.defaultTerminalId;
      this.retailSharedService.returnReason = financialData?.RatePlan?.ReasonForNegotiation || '';

    if (OperationType) {
      this.operationType = OperationType;
    }
    if (seatProduct?.length > 0) {
 //     this.retailSharedService.isFromDeposit = true;
      this.retailSharedService.isFromCheckOut = true;
      this.retailSharedService.checkOutCallback = this.CheckOutCallback.bind(this);
      this.retailSharedService.RedirectModule = RedirectToModules.retail;
      this._ams.paymentProcessing = true;
      let val: number = 0;
      val = reservationAttemptData && reservationAttemptData.ReturnItem
        ? reservationAttemptData.ReturnItem.filter(t => !t.IsChecked).length
        : financialData && financialData.ReturnItem
          ? financialData.ReturnItem.filter(t => !t.IsChecked).length
          : 0; this._shopservice.SelectedPlayers = [];
      let guestIds = seatProduct.map(s=>s.ContactId);
      let contactMemberDataMapped = [];
      
      if(Payments?.length){
      Payments[0].Sessions.forEach(session => {
        session.GuestPrices.forEach(guestPrice => {
          if (guestIds && guestIds.some(guestId => guestId == guestPrice.GuestId) && guestPrice.MemberInfo) {
            let ap = guestPrice.MemberInfo;
            contactMemberDataMapped.push({ 'ContactId': guestPrice.GuestId, 'MemberId': ap.MemberId })
            this._shopservice.SelectedPlayers.push({
              playerId: ap.MemberId,
              playerLinkId: ap.MemberId,
              holes: ap.TotalMinutes,
              course: ap.CourseName || '',
              playerCategoryId: 3,
              rateType: 'Member',
              scheduledTeeTimeId: seatProduct[0].PartyId,
              scheduledDateTime: new Date().toString(),
              scheduledAPIDateTime: new Date().toString(),
              playerName: guestPrice.GuestName,
              playerType: ap.PlayerType,
              playerSlotPosition: 0,
              rateTypeId: 0,
              packageCode: '',
              greenFee: 0,
              cartFee: 0,
              entryFee: 0,
              cartFeeRetailItemId: 0,
              greenFeeRetailItemId: 0,
              entryFeeRetailItemId: 0,
              tournamentId: 0,
              otherItems: [],
              cartFeeRetailItem: undefined,
              greenFeeRetailItem: undefined,
              entryFeeRetailItem: undefined,
              deposits: [],
              walk: '',
              trail: '',
              greenFeeTax: 0,
              cartFeeTax: 0,
              entryFeeTax: 0,
              negotiateCartFeeRetailItem: null,
              negotiateCartFeeRetailItemId: 0,
              negotiateEntryFeeRetailItem: null,
              negotiateEntryFeeRetailItemId: 0,
              negotiateGreenFeeRetailItem: null,
              negotiateGreenFeeRetailItemId: 0,
              playerTypeId: 0
            });
          }
        });
      })
    }

    this._shopservice.SelectedPlayers.forEach((player) =>{
      player.holes = _.sumBy(this._shopservice.SelectedPlayers.filter(data => data.playerId == player.playerId),'holes')
    })

    this._shopservice.SelectedPlayers = _.uniqBy(this._shopservice.SelectedPlayers,'playerId')
    


      seatProduct?.forEach(x => {
        val++;
        let bookingAttemptId = x.ReservationAttemptId;
        SeatItems.push({
          ItemDescription: x.ItemDescription || 'Booking',
          ItemType: x.ItemType, // type of product - RetailItemType
          ProductName: x.ItemDescription || 'Booking',//seatProduct.Name,
          ServiceId: x.ItemId,// seatProduct.ServiceId, //partyid
          ProductPrice: x.UnitPrice,
          SalesPrice: x.UnitPrice,//seatProduct.SalesPrice, // same amount
          ExternalPOSItemId: x.ExternalPOSItemId,//seatProduct.ExternalPOSItemId, //
          ItemId: x.ItemId,//seatProduct.ItemId, // openitemid
          Noofitems: x.QuantitySold,
          isReturn :  x.IsReturn,
          Discount: x.Discount,
          DiscountPercentage: 0,
          DiscountTypeId: 0,
          isEditDisabled: true,
          payeeId: contactMemberDataMapped.find(guest => guest.ContactId == x.ContactId)?.MemberId,
          id: x.ItemId,
          isModificationRestricted: true,
          Commission: [],
          isCommissionable: true,
          netPrice: x.UnitPrice,
          isGroupingKey: false,
          isPackagedItem: false,
          PackageItemId: 0,
          MultiPack: false,
          ClientMultiPackId: 0,
          PackageGroupId: 0,
          isOpenPricedItem: true,
          isDeleteDisabled: true,
          playerName: x.GuestName,
          MaxTherapistForCommission: 1,
          CheckOutAppointmentId: 1,//seatProduct.Id,// orderid
          category: 0,//seatProduct.category,
          LineNumber: x.LineNumber,
          costPrice: x.UnitPrice,//seatProduct.costPrice,
          marginPercentage: 0,
          isRequestName: true,
          isRequireComments: false,
          itemComments: x.category,
          clientName: '',
          clientId: 0,
          allowEarn: false,
          discountComments: '',
          discountReason: 0,
          isDepositOnly: true,
          Index : x.LineNumber,
          ReservationAttemptId: bookingAttemptId,
          fromWidget: x.FromWidget,
          hideZeroPrice: x.hideZeroPrice,
          CartId: x.CartId,
          guestId: x.GuestId,
          ignoreTransaction: x.ignoreTransaction,
          TransDetailId: x.TransDetailId,
          Tax: x.TaxAmount,
          Gratuity: x.Gratuity || [],
          ServiceCharge: x.ServiceChargeGratuity ? this.formServiceCharges(x.ServiceChargeGratuity, userMachineConfig.userId) : []
        });
      }
      );
    }

    if (membershipNumber) {
      this._ams.clientSelect = 1;
      this.retailSharedService.memberCardNumber = (membershipNumber || 0).toString();
    }
    this._shopservice.selectedPayeeId = paidBy || (seatProduct?.length ? seatProduct[0].ContactId : ContactId);
    this.retailSharedService.payeeId = paidBy || (seatProduct?.length ? seatProduct[0].ContactId : ContactId);
    this.retailSharedService.guestId = paidBy || (seatProduct?.length ? seatProduct[0].ContactId : ContactId);
    this.retailSharedService.selectedProducts = _.cloneDeep(SeatItems.filter(item => { return !item.hideZeroPrice || item.ProductPrice != 0 }));;
    this._shopservice.selectedProducts = (SeatItems.filter(item => { return !item.hideZeroPrice || item.ProductPrice != 0 }));;
    this.retailSharedService.SelecedSeatProducts = _.cloneDeep(SeatItems.filter(item => { return !item.hideZeroPrice || item.ProductPrice != 0 }));;

    if ((!this.oldReservation?.fromWidget) && ((OperationType == Operations.update && TranactionId) || (OperationType == Operations.Refund && TranactionId) || (OperationType == Operations.cancel && TranactionId) || (OperationType == Operations.checkIn && TranactionId) || (OperationType == Operations.checkOut && TranactionId) || (OperationType == Operations.charge && TranactionId))) {
      this.retailSharedService.reOpenTransaction = true;
      this.retailSharedService.transactionId = TranactionId;
      this.retailSharedService.isFromRefund = true;
      this.retailValidationService.LockTransaction(TranactionId);
      let ProductId = parseInt(this.utils.GetPropertyInfo('ProductId'));
      let info = await this.getTransactionInfo(TranactionId);
      if (info && info.transactionData) {
        info.transactionData.ticketNumber = info.transactionData.retailTicketNumber;
        this._shopservice.selectedRetailItem = info.transactionData;
        this.retailSharedService.selectedRetailItem = info.transactionData;
        this.retailSharedService.selectedTransaction = info.transactionData;
      }
     
      this.retailSharedService.ticketNumber = this._shopservice.ticketNumber = TicketNumber;
      this.retailSharedService.guestId = this.retailSharedService.SelecedSeatProducts.length > 0 ? this.retailSharedService.SelecedSeatProducts[0].guestId : ContactId;
      this.retailSharedService.sectionName = this._shopservice.sectionName = "RTID";
      this.getTransactionDetails(TranactionId, 'Return', ProductId);
    } else {


      this.retailSharedService.settleOpenTransaction = false;
      this.retailSharedService.reOpenTransaction = false;
      this.retailSharedService.isReturnWithoutTicket = false;
      this.retailSharedService.depositArray = depositAll;
      this.retailSharedService.checkOutIds = seatProduct?.map(x => reservationId);
      this.retailSharedService.TaxValue = 20;
      this._shopservice.isCreateOperation = true;
      this.utils.RedirectTo(RedirectToModules.retail);
    }
  }


  getMemberId(partyId) { }

  public getLoggedinUserSessionConfiguration(): UserSessionConfiguration {
    let userSessionConfigKey: string = 'userSessionConfigInfo'
    let userSessionConfig: UserSessionConfiguration = JSON.parse(sessionStorage.getItem(userSessionConfigKey));
    if (sessionStorage.getItem(userSessionConfigKey) != null) {
      return {
        id: Number(userSessionConfig?.id),
        userId: Number(userSessionConfig?.userId),
        defaultOutletId: Number(userSessionConfig?.defaultOutletId),
        defaultCourseId: Number(userSessionConfig?.defaultCourseId),
        defaultPaymentDevice: userSessionConfig?.defaultPaymentDevice,
        defaultDeviceName: userSessionConfig?.defaultDeviceName,
        defaultTerminalId: Number(userSessionConfig?.defaultTerminalId),
        isIdtechSred: Boolean(userSessionConfig?.isIdtechSred),
        smallStickersPrinter: userSessionConfig?.smallStickersPrinter,
        hangingTicketsPrinter: userSessionConfig?.hangingTicketsPrinter,
        propertyId: Number(userSessionConfig?.propertyId)
      }
    }
    return null;
  }

  formServiceCharges(service, userId?) {
    let serviceItem: ServiceChargeGratuity[] = [];
    service.forEach(data => {
      serviceItem.push({
        Id: null,
        TransactionDetailId: null,
        TherapistId: userId,
        Percentage: data.ServiceChargePercent,
        PercOrAmount: data.ServiceChargePercent > 0 ? 1 : 2, //Percentage - 1 , Amount - 2
        PercentageId: 0,
        Amount: data.ServiceCharge,
        ServiceCharge: data.ServiceCharge,
        StaffType: "USER",
        additionalGratuity: 0,
        additionalGratuityPercent: 0,
        additionalServiceCharge: 0,
        additionalServiceChargePercent: 0,
      })
    })
    return serviceItem;

  }
  formServiceCharge(service, userId?) {
    let serviceItem: ServiceChargeGratuity[] = [];
    service.forEach(data => {
      serviceItem.push({
        Id: null,
        TransactionDetailId: null,
        TherapistId: userId,
        Percentage: null,
        PercentageId: null,
        PercOrAmount: null,
        Amount: data.Amount,
        ServiceCharge: data.Amount,
        StaffType: "USER",
        additionalGratuity: 0,
        additionalGratuityPercent: 0,
        additionalServiceCharge: 0,
        additionalServiceChargePercent: 0,
      })
    })
    return serviceItem;

  }
  getUserConfiguration(permission, userRoleId) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: permission.successCallback.bind(permission,),
      error: permission.errorCallback.bind(permission),
      callDesc: "GetUserRoleConfiguration",
      method: HttpMethod.Get,
      uriParams: { userRoleId },
      showError: false,
      extraParams: [false]
    });
  }

  updateUserClaims(permission, changedRoleClaims: RetailUserClaimsDTO[]) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: permission.successCallback.bind(permission),
      error: permission.errorCallback.bind(permission),
      callDesc: "UpdateUserRoles",
      method: HttpMethod.Put,
      body: changedRoleClaims,
      showError: false,
      extraParams: [false]
    });
  }

  getTransactionDetails(transactionId, action: string, productId: number) {
    this.http.CallApiWithCallback<boolean>({
      host: Host.retailPOS,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetTransactionDetails",
      method: HttpMethod.Get,
      uriParams: { transactionId: transactionId, productId: productId },
      showError: true,
      extraParams: [action]
    });
  }

  async getTransactionInfo(transactionId) {
    return await this.InvokeServiceCall("GetTransactionInfoById", Host.retailPOS, HttpMethod.Get, { transactionId: transactionId });
  }

  async InvokeServiceCall(CallDesc: string, host: Host, method: HttpMethod, uriParams?: any, body?: any): Promise<any> {
    var result: BaseResponse<any> = await this.http.CallApiAsync({
      callDesc: CallDesc,
      host: host,
      method: method,
      uriParams: uriParams,
      body: body
    });
    return result ? result.result : null;
  }

  updateLineNumberInSeatSelectedProducts(transactionDetails) {
    if (this.bookingAttemptData?.ReturnItem?.length && transactionDetails?.length) {
      let seatTransactionDetailIds = this.bookingAttemptData.ReturnItem.map(t => t.TransactionDetailId);
      let noOfRetainedShopItems = transactionDetails.filter(t => !seatTransactionDetailIds.includes(t.id)).length;
      this.retailSharedService.SelecedSeatProducts.forEach(s => s.LineNumber = s.LineNumber + (noOfRetainedShopItems || 0));
    }
  }

  async loadTransactionDetails(result, action: string) {
    this._shopservice.selectedProducts = [];
    this._shopservice.TaxValue = 0;
    this._shopservice.seatedProductsBookingAttemptId = this.bookingAttemptData;
    this.updateLineNumberInSeatSelectedProducts(result);
    await this._shopservice.LoadSelectedProducts(result, [], action);
    if (this.operationType == Operations.update || this.operationType == Operations.checkIn || this.operationType == Operations.checkOut || (this.operationType == Operations.charge && this.bookingAttemptData.ReturnItem && this.bookingAttemptData.ReturnItem.length > 0) ||
      ((this.operationType == Operations.cancel || this.operationType == Operations.Refund) && this.bookingAttemptData.PartyPaymentType == FinancialEffectType.PartialCharge || this.bookingAttemptData.PartyPaymentType == FinancialEffectType.PartialRefund)) {
      this._shopservice.isOperationFromActivities = true;
      this._shopservice.isCancelOperation = this.operationType == Operations.cancel || this.operationType == Operations.Refund;
      let selectedTransactionSeatProduct = this.bookingAttemptData.ReturnItem
      this.bookingAttemptData.ReturnItem = this.bookingAttemptData.ReturnItem.filter(listItem => listItem.IsChecked);
      this._shopservice.selectedProducts.forEach(prod => {
        if (this.bookingAttemptData.ReturnItem.find(data => prod.id == data.TransactionDetailId)?.IsChecked) { //needs to be checked
          prod.isEditableActivityItem = true;
        } else {
          let selectedProducts = this.bookingAttemptData.ReturnItem.filter(listItem => listItem.IsChecked);
          if (selectedProducts.length) {
            prod.isDeleteDisabled = true;
          }
        }
        if (selectedTransactionSeatProduct.length > 0) {
          selectedTransactionSeatProduct.forEach(product => {
            if (product.TransactionDetailId == prod.id)
              prod.isDeleteDisabled = true;
          });

        }
      })
    } else if ((this.operationType ==  Operations.cancel || this.operationType == Operations.Refund ) &&
      (this.bookingAttemptData.PartyPaymentType == FinancialEffectType.NoEffect || this.bookingAttemptData.PartyPaymentType == FinancialEffectType.FullRefund)) {
      this._shopservice.isOperationFromActivities = true;
      this._shopservice.isCancelOperation = true;
      this.bookingAttemptData.ReturnItem = this.bookingAttemptData.ReturnItem.filter(listItem => listItem.IsChecked);
      this._shopservice.selectedProducts.forEach(prod => {
        if (this.bookingAttemptData.ReturnItem.find(data => prod.id == data.TransactionDetailId)?.IsChecked) { //needs to be checked
          prod.isEditableActivityItem = true;
        } else {
          let selectedProducts = this.bookingAttemptData.ReturnItem.filter(listItem => listItem.IsChecked);
          if (selectedProducts.length) {
            prod.isDisabledActivityItem = true;
          }
        }
      })
    }
    if (this.oldReservation?.fromWidget) {
      this.route.navigate(['/shop/viewshop']);
    } else {
      this.route.navigate(['/shop/viewshop/shopItemDetails']);
    }

  }

  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): Promise<void> {
    if (callDesc == 'GetTransactionDetails') {
      let response = <any>result.result;
      await this.loadTransactionDetails(response, extraParams[0]);
    }
  }

  errorCallback() {
  }

  getOutlets(outlets, siteId) {
    this.http.CallApiWithCallback<any>({
      host: Host.retailManagement,
      success: outlets.successCallback.bind(outlets,),
      error: outlets.errorCallback.bind(outlets),
      callDesc: "GetOutlesForProperty",
      method: HttpMethod.Get,
      uriParams: { siteId },
      showError: false,
      extraParams: [false]
    });
  }

  GetOutletsAccessByProperty(outlets) {
    this.http.CallApiWithCallback<any>({
      host: Host.retailManagement,
      success: outlets.successCallback.bind(outlets),
      error: outlets.errorCallback.bind(outlets),
      callDesc: 'GetOutletsAccessByPropertyId',
      method: HttpMethod.Get,
      showError: false,
      extraParams: [false]
    });
  }
}
