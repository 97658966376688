import { Component, ElementRef, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { AppService } from '@app/app.service';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { SlotTimeFormatter } from '@pipes/slot-time-formatter.pipe';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { ToastrService } from 'ngx-toastr';
import { MatFormField } from '@angular/material/form-field';

@Component({
  selector: 'app-form-autocomplete',
  templateUrl: './form-autocomplete.component.html',
  styleUrls: ['./form-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormAutocompleteComponent implements OnInit {
  @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef<HTMLInputElement>;
  @ViewChild('trigger', { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild("formField") autoCompleteFormField: MatFormField;
  public FormControlName;
  config: FieldConfig;
  group: UntypedFormGroup;
  selectedOptions: any = [];
  autocompleteOptions: any[];
  showErrorText: boolean = false;
  toHighlight: string;
  appearance: boolean;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @ViewChild('multiChips') multiChips: ElementRef<HTMLInputElement>;
  constructor(private slotTimePipe: SlotTimeFormatter,public toastrService?: ToastrService) { }

  ngOnInit() {
    this.FormControlName = this.config.name;
    this.autocompleteOptions = this.config.options;
    this.appearance = this.config.appearance ? this.config.appearance : false;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOptions.push(event.option.viewValue);
    this.toHighlight = "";
    let x = this.FormControlName;
    this.group.patchValue({ x: '' })  
    if (this.config.cellClick) {
      this.config.cellClick(event);
    }
  }

  iconClick(event, index) {
    if ((this.config.cellClick || this.config.returnFunction) && !this.config.disabled) {
      if (!this.config.isChipsEnabled && !this.config.isEmailChipsEnabled) {
        this.config.returnFunction(event, index);
      }
      else {
        this.config.multiChipValues = [];
        this.group.controls[this.config.name].setValue([]);
      }
    }
  }

  validInput(event) {
  }

  filterOptions(event) {
    if (this.config.isDateTime) {
      this.autocompleteOptions = this.config.options.filter(option =>
        this.slotTimePipe.transform(option.DateTime).toLowerCase().includes(event.target.value.toLowerCase()));
    }
    else if (this.config.isChipsEnabled) {
      this.autocompleteOptions = this.config.options.filter(option =>
        option.value.toLowerCase().includes(event.target.value.toLowerCase()) || option.phoneNumber?.includes(event.target.value) || option.email?.includes(event.target.value)
        || option.firstName?.toLowerCase().includes(event.target.value.toLowerCase()) || option.lastName?.toLowerCase().includes(event.target.value.toLowerCase()));
    }
    else {
      const filterValue = event.target.value.toLowerCase();
      this.autocompleteOptions = this.config.options.filter(option => option && option.value.toLowerCase().indexOf(filterValue) != -1);
    }

  }

  ngAfterViewInit() {
    this.autocompleteOptions = this.config.options;
  }

  omit_Characters(event) {
    let key;
    key = event.charCode;
    return ((key > 47 && key < 58) || key == 45 || key == 46);
  }

  dateSelection(option) {
    this.config.cellClick(option);
  }
  displayFn(options, values) {
    if (options && values) {
      let result = this.config.options?.find(option => option.id === values);
      return result && result.value ? result.value : '';
    }
  }

  remove(value: string): void {
    const index = this.config.multiChipValues.indexOf(value);
    if (index >= 0) {
      this.config.multiChipValues.splice(index, 1);
      if(this.config.isEmailChipsEnabled){
        const emailArray: string[] = this.config.multiChipValues?.map(emailObj => emailObj.value);
        this.group.controls[this.config.name].setValue(emailArray);
      }else{
        this.group.controls[this.config.name].setValue(this.getMultiChipIds());
      }
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && this.isValidEmail(value)) {
      this.config.multiChipValues.push({ value: value });
      const emailArray: string[] = this.config.multiChipValues.map(emailObj => emailObj.value);
      this.group.controls[this.config.name].setValue(emailArray)
      event.chipInput!.clear();
    } else {
      this.toastrService.error(this.config.errorMessage, "", { timeOut: 5000, closeButton: true });
    }
  }

   isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  multiSelected(event: MatAutocompleteSelectedEvent): void {
    const index = this.config.multiChipValues.findIndex(value => value.id === event.option.value);
    if (index === -1)
      this.config.multiChipValues.push(this.autocompleteOptions.find(option => option.id === event.option.value));
    if (this.multiChips)
      this.multiChips.nativeElement.value = '';
    this.group.controls[this.config.name].setValue(this.getMultiChipIds());
  }

  getMultiChipIds() {
    let multiChipIds = [];
    if (this.config.multiChipValues && this.config.multiChipValues.length)
      this.config.multiChipValues?.map((value) => {
        multiChipIds.push(value.id);
      });
    return multiChipIds;
  }
  onFocusOut(event){
    if(this.config.onfocusOutClick)
        this.config.onfocusOutClick(event);
  }

}
