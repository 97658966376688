<div>
    <div class="memberInfo-scrollSize">
      <div class="reservation-notes">
        <div class="member-details__header seat-popover-header">
          {{'memberText' | translate}} {{'informationText' | translate}}
        </div>
        <!-- <div class="reservation-notes__content seat-popover-content">
          <div class="reservation-notes__notes-details seat-border-color"
            style="justify-content: space-between;gap:20px" *ngFor="let notes of guestNotesForParty">
            <div>{{notes.Text}}</div>
          </div>
        </div> -->
        <div class="member-details">
          <div class="member-info-container d-flex  pb-1 ">
            <div class="member-info-container__title font-bold">{{'type' | translate}}: </div>
            <div class="member-info-container__value"> &nbsp;{{memberDetails?.MembershipType || 'NA'}}</div>
          </div>
          <div class="member-info-container d-flex  pb-1">
            <div class="member-info-container__title font-bold">{{'Status' | translate}}: </div>
            <div class="member-info-container__value">&nbsp;{{(!memberDetails?.IsMembershipExpired && memberDetails?.MembershipStatus.toLowerCase() === "active") ? ('Active' | translate) : ('expired' | translate)}}</div>
          </div>
          <div class="member-info-container d-flex  pb-1">
            <div class="member-info-container__title font-bold">{{'CardNumber' | translate}}: </div>
            <div class="member-info-container__value"> &nbsp;{{memberDetails?.CardNo || 'NA'}}</div>
          </div>  
        </div>
      </div>
    </div>
  </div>