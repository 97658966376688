import { Component, Input, OnInit } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ShopDialogPopUp } from '../shop-dialog-popup/shop-dialog-popup.component';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailDataAwaiters } from '../../shared/events/awaiters/retail.data.awaiters';
import { RetailService } from '../../retail.service';
import { MemberBusinessService } from '../../shared/business/Member-business.service';
import { RetailCommunication } from '../../retailcommunication.service';
import { RetailRoutes } from '../../retail-route';
import { multiItemMultiPack, MultiPackGuestType, PayeeInfo } from '../../shared/business/shared.modals';
import { RedeemMultiGridData } from '../shop.modals';
import _ from 'lodash';
import { CommonVariablesService } from '../../shared/service/common-variables.service';
import { Product } from 'src/app/common/enums/shared-enums';
import { RetailItemDetail } from '../../retail.modals';
import { RetailSharedVariableService } from '../../shared/retail.shared.variable.service';

@Component({
  selector: 'app-redeem-multipack-popup',
  templateUrl: './redeem-multipack-popup.component.html',
  styleUrls: ['./redeem-multipack-popup.component.scss']
})
export class RedeemMultipackPopupComponent implements OnInit {
  @Input() datainput;
  EnableBtn = false;
  captions;
  shopCaption;
  clientTypes = [
    { id: 1, type: this._localization.captions.shop.Member },
    { id: 2, type: this._localization.captions.shop.Guest }
  ];
  clientSelect = 1;
  showExpired: UntypedFormControl;
  multipackResult = [];
  selectedPayee: PayeeInfo = null;
  selectedMultiPacks = [];

  constructor(private _localization: RetailLocalization,
    public dialogRef: MatDialogRef<ShopDialogPopUp>,
    private _utils: RetailUtilities,
    private _propertyInfo: RetailPropertyInformation,
    public _retailService: RetailService,
    public _memberService: MemberBusinessService,
    private _retailComm: RetailCommunication,
    private _commVarService: CommonVariablesService,
    private _retailSharedService: RetailSharedVariableService
  ) {
    this.shopCaption = _localization.captions.shop;
    this.captions = _localization.captions.shop.MultiPackRedeemPopup;
  }

  allAvailableMultipacks: RedeemMultiGridData[] = [];  

  ngOnInit(): void {
    this.showExpired = new UntypedFormControl(false);
    this.ResetSearchField();
    this._retailService.clientSelect = 1;
  }

  ResetSearchField(){
    this._retailService.labelRecords = [];
    this._retailService.recordsArray = [];
    this._retailService.multiClientInfo = [];
    this._commVarService.selectedPayeeId = 0;
    this._commVarService.memberCardNumber = "0";
    this.selectedPayee = null;
    this.showExpired.setValue(false);
    this.selectedMultiPacks = [];
  }

  clientTypesChange(e) {
    this.ResetSearchField();
    this._retailService.clientSelect = e.value;
    this.multipackResult = [];
    this.allAvailableMultipacks = [];
    this.showExpired.setValue(false);
    this.selectedMultiPacks = [];
  }

  receiveMessage($event) {
    this.makeSearchCall($event.searchValue, $event.requestUid);
  }


  async makeSearchCall(name: string, requestUid: string) {
    let guestInfo;    
    if (this._retailService.clientSelect == 1) {
      this._utils.ToggleLoader(true, this.shopCaption.MemberSelectionPopup.searchProgressMsg);
      guestInfo = await this._memberService.searchGuest(name, this._retailService.clientSelect);
    } else {
      this._utils.ToggleLoader(true, this.captions.lbl_searchingGuest);
      guestInfo = await RetailDataAwaiters.searchPayee(name, this._retailService.clientSelect, requestUid, false);
    }
    this._utils.ToggleLoader(false);
    this._retailService.clientsearchArray = guestInfo[1];
    this._retailService.guestInfo = guestInfo[0];

  }

  includeExpiredMultiPacks(includeExpired) {
    if (!includeExpired) {
      this.multipackResult = this.allAvailableMultipacks.filter(x => !x.isExpired)
    } else {
      this.multipackResult = this.allAvailableMultipacks;
    }
    this.multipackResult = _.sortBy(this.multipackResult, ['expiryDate', 'remainingCount']);
  }

  SelectMultiPack(multipack) {
    if (multipack.isExpired) return;
    multipack.isSelected = !multipack.isSelected;
    if (multipack.isSelected) {
      this.selectedMultiPacks.push(multipack);
    } else {
      this.selectedMultiPacks = this.selectedMultiPacks.filter(x => x.transactionDetailId != multipack.transactionDetailId);
    }
    this.EnableBtn = this.multipackResult.some(x => x.isSelected);
  }

  async selectPayeeFromSearch(payeeInfo: PayeeInfo) {
    if (payeeInfo) {
      console.log(payeeInfo);
      this.selectedMultiPacks = [];
      this.showExpired.setValue(false);
      this.selectedPayee = payeeInfo;
      const isFromApptOrTeetimePayment = this._retailSharedService.payeeId > 0
        && (this._retailSharedService.isFromCheckOut || this._retailSharedService.isFromClassCheckOut || this._retailSharedService.isFromEditTeeTime || this._commVarService.isFromUnpaidPlayer);
      this._commVarService.selectedPayeeId = isFromApptOrTeetimePayment ? this._retailSharedService.payeeId : payeeInfo.id;
      this._commVarService.memberCardNumber = this._retailService.clientSelect == 1 ? !isFromApptOrTeetimePayment ? payeeInfo.guestProfileId : this._retailSharedService.memberCardNumber : "0";

      try {
        const guestTypeMap = {
          [1]: {
            [Product.SPA]: MultiPackGuestType.SpaMember,
            [Product.GOLF]: MultiPackGuestType.GolfMember,
            [Product.RETAIL]: MultiPackGuestType.RetailMember
          },
          [2]: {
            [Product.SPA]: MultiPackGuestType.SpaGuest,
            [Product.GOLF]: MultiPackGuestType.GolfGuest,
            [Product.RETAIL]: MultiPackGuestType.RetailGuest
          }
        }
        this._utils.ToggleLoader(true, this.captions.lbl_searchingMultiPacks);
        let multiPackRes = await this._retailComm.putPromise<multiItemMultiPack[]>({
          route: RetailRoutes.GetMultiPacks,
          body: [{
            guestId: payeeInfo.guestProfileId,
            guestType: guestTypeMap[this._retailService.clientSelect][this._propertyInfo.ProductId]
          }]
        }, false);
        if (multiPackRes) {
          let retailItemIds = [];
          multiPackRes.map(m => m.clientMultiPacks?.map(c => retailItemIds.push(c.retailItemId)));
          retailItemIds = Array.from(new Set(...[retailItemIds]));
          const retailItemInfo = await this.GetRetailItemsByIdList(retailItemIds);
          this._utils.ToggleLoader(false);
          if (retailItemInfo) {
            this.multipackResult = this.buildClientRetailItems(payeeInfo, multiPackRes, retailItemInfo);            
            this.multipackResult = this.multipackResult.filter(x => !x.isExpired);
            //Auto-select multipack if there is only one available
            if (this.multipackResult.length == 1) this.SelectMultiPack(this.multipackResult[0]);
          }
        }
      } catch (err) {
        this._utils.ToggleLoader(false);
        console.error(err);
      }
    }
  }
  
  GetRetailItemsByIdList(retailItemIds) {
    return this._retailComm.putPromise<RetailItemDetail[]>(
      { route: RetailRoutes.GetRetailItemsByIdList, body: retailItemIds }, true);
  }

  calculateDiff(dateSent) {
    let currentDate = this._propertyInfo.CurrentDate;
    dateSent = new Date(dateSent);
    if (this.GetDateWithoutTime(dateSent).valueOf() >= this.GetDateWithoutTime(currentDate).valueOf()) {
      return 0;
    }
    else {
      return Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
    }
  }


  GetDateWithoutTime(input: Date) {
    if (typeof (input) == 'string') {
      input = this.getDate(input);
    }
    input = _.cloneDeep(input);
    input.setHours(0, 0, 0, 0);
    return input;
  }

  getDate(input: any): Date {
    return this._localization.getDate(input);
  }

  buildClientRetailItems(payeeInfo, clientMultipackItems: multiItemMultiPack[], retailItemInfo) {
    let data = [];
    clientMultipackItems.forEach(multipack => {
      multipack.clientMultiPacks = multipack.clientMultiPacks.filter(x => !x.isReturned);

      //Check If multipack is already redeemed in the cart
      const redeemedMultipackInCart = this._commVarService.selectedProducts.filter(item => item.ClientMultiPackId == multipack.clientMultiPacks[0].id
        && item.ItemDescription.includes(this._localization.captions.shop.RedeemingMultipack));
      const UNLIMITED_MULTIPACK = -1;
      if (redeemedMultipackInCart && redeemedMultipackInCart.length > 0 && multipack.clientMultiPacks[0].remaining != UNLIMITED_MULTIPACK) {
        multipack.clientMultiPacks[0].remaining -= redeemedMultipackInCart.length < multipack.clientMultiPacks[0].remaining ? redeemedMultipackInCart.length : multipack.clientMultiPacks[0].remaining;
      }

      if (multipack.clientMultiPacks?.length > 0 && multipack.clientMultiPacks[0].remaining != 0) {
        const gridObj = {
          name: retailItemInfo.find(x => x.id == multipack.clientMultiPacks[0].retailItemId)?.itemDescription,
          clientName: payeeInfo.name,
          soldDate: this._localization.LocalizeShortDate(multipack.clientMultiPacks[0].soldOn),
          expiryDate: this._localization.LocalizeShortDate(multipack.clientMultiPacks[0].expirationDate),
          remainingCount: multipack.clientMultiPacks[0].remaining,
          isExpired: this._localization.getDate(multipack.clientMultiPacks[0].expirationDate) < this._propertyInfo.CurrentDTTM,
          multipackData: multipack,
          retailItemId: multipack.clientMultiPacks[0].retailItemId,
          isSelected: false,
          transactionDetailId: multipack.transactionDetailId
        };
        this.allAvailableMultipacks.push(gridObj)
        if (!data.some(c => c.transactionDetailId == multipack.transactionDetailId)) {
          const dateDifference = this.calculateDiff(multipack.clientMultiPacks[0].expirationDate);
          if (dateDifference <= 365) {
            data.push(gridObj);
          }
        }
      }
    });
    let returnData = _.sortBy(data, ['expiryDate', 'remainingCount']);
    return returnData;
  }

  removeFromSearchBar() {
    this.multipackResult = this.allAvailableMultipacks = this.selectedMultiPacks = [];
    this.showExpired.setValue(false);
    this.selectedPayee = null;
  }

  RedeemMultiPack() {    
    this.dialogRef.close(this.selectedMultiPacks);
  }

  OnCancel() {
    this.ResetSearchField();
    this.dialogRef.close();
  }

}
