<section class="ShopTransaction h-100" *ngIf="this._ss.sectionName; else loader">
  <div class="order-header LW20">
    <span [attr.automationId]="'Lbl_shopItemDetails_goBack'" class="cellMidAlign"><i  aria-hidden="true" [ngClass]="{'prevent-action': _ams.paymentProcessing}" class="icon-left-arrow pr-2"
        (click)="GoBack(this._ss.sectionName)"></i></span>
    <div class="rwt-caption">{{ _ss.isFromRefund ? localization?.captions?.shop?.TeetimeRefund
      :localization?.captions?.shop?.ReturnwithTicket}}
      : </div>
    <span class="cellMidAlign"> {{ ticketNumber }}</span>
  </div>

  <section class="ShopItemDetail whiteBG" *ngIf="this._ss.sectionName=='RTID'">
    <!--  Retail transaction Item Detail -->
    <app-shop-details-section></app-shop-details-section>   
    <app-shop-service-items frompage=""></app-shop-service-items>
  </section>

  <section class="retailItemDetail  whiteBG" *ngIf="this._ss.sectionName=='RI'">
    <!-- Retail Item -->
    <div class="ST_custheight">
      <app-retail-items frompage="RT"></app-retail-items><!-- Retail Transaction -->
    </div>
  </section>
  <section class="retailItemDetail  whiteBG" *ngIf="this._ss.sectionName=='RIS'">
    <!-- Retail Item Summary -->
    <div class="ST_custheight">
      <div class="ST_leftPane">
        <app-shop-details-section></app-shop-details-section>
    <div *ngIf="!hideGuestSearchforBeo" class="client-details  radio-class">
      <mat-radio-group [attr.automationId]="'Rdo_shopItemDetails_clientType'" class="golf__radio" [(ngModel)]="_ams.clientSelect" [disabled]="_ams.paymentProcessing || IsAccurualEnabled">
        <mat-radio-button class="golf--form-radio-button" name="clienttype" [value]="type.id"
          *ngFor="let type of clientTypes">{{type.type}}</mat-radio-button>
      </mat-radio-group>
      <div [attr.automationId]="'Lbl_shopItemDetails_emailReceipt'" *ngIf="isEmailOnDemandProduct" class="float-right ag_mt--1" (click)="openEmailReceipt()">
        <i aria-hidden="true" class="icon-Email mr-2"></i>
        <a class="LW14 ag_link" href="javascript:void(0)">{{this.localization.captions.shop.emailReceipt}}</a>
      </div>
    </div>

    <div class="address-section-parent">

      <div class="address-section">
        <div class="address-client">
      
          <address class="LW14">
          </address>
        </div>

      </div>
    </div>
    <div class="search-tab pt-1" *ngIf="!hideGuestSearchforBeo">
      <span [attr.automationId]="'Lbl_shopItemDetails_payeeInfo'" (click)="openDialog('orderSummary')" class="LW12 uppercase text-color float-right pb-2 cursor golf__link"
        [ngClass]="{'button_invalid' : payeeInfo && payeeInfo.name, 'prevent-action': (_ams.paymentProcessing || IsAccurualEnabled)  }">{{AddGuest}}</span>
      <retail-chips-autocomplete-example [Datalimit]="1" [ngClass]="{'prevent-action': (_ams.paymentProcessing || IsAccurualEnabled)}"
        (messageEvent)="receiveMessage($event)" (selectedEvent)="selectPayeeFromSearch($event)"
        [Disabled]="(_ss.isAppointmentCheckOut || IsAccurualEnabled || _ss.memberCardNumber != '0')" (removedCompleteSearch)='deleteCompleteSearch()'
        (removedEvent)="removeFromSearchBar()"></retail-chips-autocomplete-example>
    </div>
        <app-shop-service-items frompage="RetailSummary" [surcharge]="surcharge"></app-shop-service-items>
      </div>
      <div class="ST_rightPane">
        <app-shop-serviceitem-summary (SurchargeUpdated)="surcharge = $event"></app-shop-serviceitem-summary>
      </div>
    </div>
  </section>
</section>
<ng-template #loader>
  <section class="ShopTransaction h-100">
    <div id="SICS-cover-spin">
      <div class="customspinnerimg">
        <img src="./assets/images/agil_favicon.ico">
      </div>
      <div id="vcart-custom-cover-message">{{localization.captions.shop.RefundInProgress}}</div>
    </div>
  </section>
</ng-template>