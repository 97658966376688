<section class="recent-search-section">
  <h4 class="form-label-primary">{{captions.RecentSearches}}</h4>
  <div *ngFor="let recentsearch of recentSearchResults" class="d-flex search-results">
    <span class="w40 align-self-center imgstyle mr10">
      <app-retail-img-thumbnail class="app-retail-img-thumbnail" [imageContent]="" [thumbnail]="true" imageType="giftcard"></app-retail-img-thumbnail>
    </span>

    <div class="d-block textellipsis">
      <div class="pb-2 textellipsis">{{recentsearch.CardNumber}}</div>
      <div class="textellipsis available-amount-container">{{captions.AvailableAmount}}:
        {{recentsearch.AvailableAmount  | Currency}}</div>
    </div>

  </div>
</section>
