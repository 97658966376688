import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { buttonTypes, ComponentTypes } from '@app/shared/constants/commonenums';
import { ContactDTO } from '@app/shared/models/RestaurantDTO';
import { FacadeService } from '@app/shared/services/facade.service';
import { PopupService } from '@popup-module/popup.service';
import { ButtonValue } from "@dynamicform/models/field-config.interface";
import { TranslateService } from '@ngx-translate/core';
import { seatRetailService } from '@app/shared/services/seatretail.service';
import moment from 'moment';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { CacheService } from '@app/core/services/cache.service';
import { CommonActivities, EventType, ItineraryApiResponse, ItineraryDTO, ItineraryNotifyRequestDTO, NotificationMode } from '@app/shared/models/InputContact';
import * as XLSX from "xlsx";
import { PhoneNumberFormatterPipe } from '@app/shared/pipes/phone-number-formatter.pipe';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatMenuTrigger } from '@angular/material/menu';
import { ToastrService } from 'ngx-toastr';
import { Utilities } from '@utilities/utilities';
import { EmailConfirmationPopupComponent } from '../email-confirmation-popup/email-confirmation-popup.component';
import { popupDialogDimension } from '@app/shared/constants/globalConstants';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';

@Component({
  selector: 'app-guest-itinerary',
  templateUrl: './guest-itinerary.component.html',
  styleUrls: ['./guest-itinerary.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GuestItineraryComponent extends Utilities  implements OnInit {
  
  constructor(private popupService: PopupService,private toastrService: ToastrService, public pf : PhoneNumberFormatterPipe, public cs: CacheService, public retailservice: seatRetailService, public dialog: MatDialog, private ts: TranslateService, public facadeService: FacadeService) {
    
    super(dialog);
    this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this.OperationCurrency = sett.General.OperationCurrency;
    })
  }
  
  public itineraryColumns: string[] = ["StartEndDateTime"];
  public activitiesColumns: string[] = ["activityName",'startDateTime','endDateTime','confirmationId','noOfGuests','status'];
  
  columnsToDisplayWithExpand = [...this.itineraryColumns, 'expand'];
  expandedElement = null;
  currentRow = -1
  
  @Input() data: ContactDTO;
  _settings
  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger ;
  itineraryGridDataSource = new MatTableDataSource<ItineraryDTO[]>() 
  itineraryGridData: ItineraryDTO[] = [];
  
  totalRecord: number = 0;
  maxRecord = 100;
  OperationCurrency: string;
  reportName =  this.ts.instant('Guest_Itinerary');
  itineraryUpComingButton: ButtonValue;
  itineraryPrintButton: ButtonValue;
  itinerarysendEmailButton: ButtonValue;
  itineraryUpPastButton: ButtonValue;
  itineraryUpAllgButton: ButtonValue;
  page = 0;
  currentRecored = 0;
  selectbtn:string;
  itinerayExportColumn = [] ;
  currentTab : number = 2;

  ngOnInit(): void {
    this.setDefaultButtonConfig();
    this.applyLanguage();
    this.bindItinerary();
  }

  ActivityIcon ='icon-Activity'
  PMSIcon ='icon-reserve_v1crs'
  productNameIconGroup : any = {
    'icon-spa' : ['spa','rG-Spa'],
    'icon-reserve_v1crs' : ['v1','versa','lms','Room','stay','V1-Stay','V1','lms-Stay'],
    'icon-golfing' : ['golf','rG-Golf'],
    'icon-Activity' :['Activities','Reserve','Riding','Game']
  }

  predictIcon(){

    
    let propertyNames= Object.getOwnPropertyNames(this.productNameIconGroup);
    this.itineraryGridData.forEach(f=>f.activities.forEach((a) => {
     
      if(f['iconsGroup'] == undefined){
        f['iconsGroup'] = [];
      }
      
      let icon = [];

      for(let i of propertyNames){
        if(icon.length){
          break;
        }
        for(let v of this.productNameIconGroup[i]){
          if( a.productName.toLowerCase() == (v as string).toLowerCase()){
            icon[0] = i;
            icon[1] = a.noOfGuests;
            break;
          }
        }
      }

      if(!icon.length){
        f['iconsGroup'] = MergeIconGroup(f['iconsGroup'],[this.PMSIcon,a.noOfGuests]);
      }else{

        //Predict Dining icon based on activity Name
        let dining  = ['Dining'];
        if(icon[0].toLowerCase() == this.ActivityIcon.toLowerCase()){
          for(let i of dining){
             if(a.activityName.toLocaleLowerCase().includes(i.toLocaleLowerCase())){
              icon[0] = 'icon-Dining';
              break;
             }
          }
        }
        f['iconsGroup'] = MergeIconGroup(f['iconsGroup'],icon);
      }      
  }));

  function MergeIconGroup(existin:[],newicon:any){

    if(existin == undefined || !existin.length){
      existin = [];
      existin.push(newicon as never);
      return existin;
    }

    let any :any = existin.find(f=>f[0]== newicon[0]);
    if(any){
      any[1] += newicon[1];
    }else{
      existin.push(newicon as never);
    }
    return existin;
  }
  }
  
  onChangeFiltertab(i:number){
    
    this.currentTab = i;
    this.itineraryUpComingButton.type = this.currentTab == 2 ? buttonTypes.actionPrimarySmall : buttonTypes.actionSecondarySmall;
    this.itineraryUpPastButton.type = this.currentTab == 1 ? buttonTypes.actionPrimarySmall : buttonTypes.actionSecondarySmall;
    this.itineraryUpAllgButton.type = this.currentTab == 0 ? buttonTypes.actionPrimarySmall : buttonTypes.actionSecondarySmall;
    let currentdate = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    
    if(i == 2){
      this.itineraryGridDataSource = new MatTableDataSource(this.itineraryGridData.filter(f=> new Date(f.startDateTime)  >= currentdate||  new Date(f.endDateTime)  >= currentdate) as []);
    }else if(i == 1){
      this.itineraryGridDataSource = new MatTableDataSource(this.itineraryGridData.filter(f=> new Date(f.startDateTime)  < currentdate &&  new Date(f.endDateTime)  < currentdate) as []);
    }else{
      this.itineraryGridDataSource = new MatTableDataSource(this.itineraryGridData as [])
    }
  }
  
  expandItineraryElement(e:any,element:any,i:any){
    this.expandedElement = this.expandedElement === element ? null : element; 
    e.stopPropagation()
    this.currentRow = this.currentRow == i ? -1 : i;
  }
  
  expandItineraryMatrowElement(e:any,element:any,i:any){
    this.expandedElement = this.expandedElement === element ? null : element
    this.currentRow = this.currentRow == i ? -1 : i;
  }

  expandMenu(){
    this.menu.openMenu();
  }

  setDefaultButtonConfig() {
    this.itinerarysendEmailButton = {
      type: buttonTypes.actionSecondarySmall,
      label: 'SEND EMAIL',
      disbaledproperity: false,
      customclass: 'action-bar__book-btn',
      icon : "icon-mail"
    };
    this.itineraryPrintButton = {
      type: buttonTypes.actionSecondarySmall,
      label: 'PRINT',
      disbaledproperity: false,
      customclass: 'action-bar__book-btn',
      icon : "icon-print"
    };
    this.itineraryUpComingButton = {
      type: buttonTypes.actionSecondarySmall,
      label: 'Upcoming',
      disbaledproperity: false,
      customclass: 'action-bar__book-btn itineraryUpComingButton',
    };
    this.itineraryUpPastButton = {
      type: buttonTypes.actionSecondarySmall,
      label: 'Past',
      disbaledproperity: false,
      customclass: 'action-bar__book-btn itineraryUpPastButton',
    };
    this.itineraryUpAllgButton = {
      type: buttonTypes.actionSecondarySmall,
      label: 'All',
      disbaledproperity: false,
      customclass: 'action-bar__book-btn itineraryUpAllgButton',
    };
  }
  
  bindItinerary(pageNumber = 0, pageDataType = 0) {
   
    this.facadeService.GetItineraryByProfile(this.data.CommonProfileTenantId ?? '',
      this.data.CommonGuestProfileUUID ?? '',
      this.data.Id, this.maxRecord, pageNumber, pageDataType)
      .subscribe((resonse) => {
        if (resonse?.Payload?.SharedItinerary) {
          let itineraryApiResponse = resonse.Payload.SharedItinerary as ItineraryApiResponse;

          this.totalRecord = itineraryApiResponse.TotalRecord;
          this.currentRecored += itineraryApiResponse.ItineraryDTO.length;
          this.itineraryGridData = this.itineraryGridData.concat(itineraryApiResponse.ItineraryDTO);

          if (this.currentRecored != this.totalRecord) {
            this.page += 1;
            this.bindItinerary(this.page, 1)
          }
          this.predictIcon();
          console.log(this.itineraryGridData);
          this.onChangeFiltertab(2);
        }
      })
  }

  sendEmail(e:ItineraryDTO){
    const componentDetails: ComponentDetails = {
      componentName: EmailConfirmationPopupComponent,
      popupType: '',
      dimensionType: 'small',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: [{
        "sendConfirmationEmail": true,
        showConfirmationNumber : false,
        "email":  this.data.EmailAddress,
        "firstName": this.data.FirstName,
        "lastName": this.data.LastName,
        "dialogTitle": this.ts.instant('Email Confirmation')
    }],
      popupTitle:this.ts.instant('Email Confirmation')
    };


    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: popupDialogDimension.actionDialogHeight,
      width: popupDialogDimension.actionDialogWidth,
      data: {
        title: this.ts.instant('Email Confirmation'),
        update:  'close',
        cancel: 'Send',
        componentDetails,
        from: ComponentTypes.GuestItinerary,
        back: false,
        standalone: true,
        showAction: true,
      },
    });

    let cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val && val.popupInput != null && val.popupInput.email != null && val.value == 1) {
        this.sendNotifyEmail(e,val.popupInput.email);
        dialogRef.close();
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      if (cancelSubscription) {
        cancelSubscription.unsubscribe();
      }
    });
  }

  sendNotifyEmail(e:any,email:string){
    let itineraryNotifyRequest : ItineraryNotifyRequestDTO = { 
      ItineraryNotifyRequest : {
        notificationMode : NotificationMode[NotificationMode.EMAIL], 
        eventType : EventType[EventType.ITINERARY_RENOTIFY],
        itineraryUuid :e.uuid,
        primaryGuestProfileId : e.profileUuids[0],
        contextId : e.businessContextIds[0],
        toEmailAddress : email,
        phoneNumber : this.data.PhoneNumber
      },
      TenantId : this.data.CommonProfileTenantId
       
    };
   this.facadeService.NotifyItinerary(itineraryNotifyRequest).subscribe((response) => {
      if (response) {
        this.toastrService.info(this.ts.instant('Email Sent Successfully'),"", { timeOut: 1000, closeButton: true });
      }
    })
  }

  propertyDateFormat(d: Date) {
    return (moment(d)).format(this.cs.settings.value.General.DateFormat) + ' ' + (moment(d)).format('LT')
  }

  public PrintItineraty(e: ItineraryDTO,i:any): void {
    if (i == 1) {
      this.exportPdf(e);
    } else if (i == 2) {
      this.exportToExcel(e)
    }
  }

  public fullName() {
    let name = '';
    if (this.data.FirstName) {
      name = this.data.FirstName;
    }
    if (this.data.LastName) {
      if (name.length > 0) {
        name += ' ' + this.data.LastName;
      } else {
        name = this.data.LastName;
      }
    }
    return name;
  }

  applyLanguage(){
    for(let c of ['Activity Name',  'Start Date/Time', 'End DateT/ime','Confirmation ID', 'Group Size', 'Status']){
      this.itinerayExportColumn.push(this.ts.instant(c));
    }
  }
  
  exportPdf(e:ItineraryDTO) {
    const doc = new jsPDF()

    this.addHeader(doc, this.ts.instant(this.reportName));
    let x = 15;
    let y = 25;
    doc.text(`${this.ts.instant('Name')} : ${this.fullName()}`, x, y);
    y += 5;
    doc.text(`${this.ts.instant('Phone Number')} : ${ (this.data.PhoneNumber == undefined|| null) ? '' : this.pf.transform(this.data.PhoneNumber,this.data.CountryId)}`, x, y);
    x = 150 
    doc.text(`${this.ts.instant('Start Date')} : ${this.propertyDateFormat(e.startDateTime)}`, x, y);
    x = 15;
    y += 5;
    doc.text(`${this.ts.instant('Email Address')} : ${this.data.EmailAddress||''}`, x, y);
    x = 150 
    doc.text(`${this.ts.instant('End Date')} : ${this.propertyDateFormat(e.endDateTime)}`, x, y);

    this.addPageNumber(doc);
    doc.setFontSize(10);
    autoTable(doc, {
      startY: 45,
      theme: 'grid',
      columns: this.itinerayExportColumn,
      columnStyles: {
        4:{
            halign: 'right'
          }
      },
      body: getPDFExportValue(e.activities, this.activitiesColumns, this.OperationCurrency, this._settings.General.DateFormat)
      
    })

    doc.save(this.fullName() + '.pdf')

    function getPDFExportValue(tb: CommonActivities[], displayedColumns: string[], currency: string, dateFormat): any {
      return tb.map(getReportColumn);

      function getReportColumn(item: CommonActivities) {
        return displayedColumns.map(getReportColumn)

        function getReportColumn(column) {
          return new Exporter().getReportColumn(item, column, currency, dateFormat);
        }
      }
    }

  }

  addPageNumber(doc: jsPDF): void {
    console.log('ss');
    for (let i = 0; i < doc.getNumberOfPages(); i++) {
      doc.setPage(i);
      let pageCurrent = doc.getCurrentPageInfo().pageNumber;
      doc.setFontSize(12);
      doc.text(`Page ${pageCurrent} of ${doc.getNumberOfPages()}`, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.height - 15, { align: "center" });
    }
  }

  addHeader(doc: jsPDF, text) {
    doc.setFontSize(17);
    doc.text(text, doc.internal.pageSize.getWidth() / 2, 15, { align: "center" });
    doc.setFontSize(10);
    doc.setTextColor(128, 128, 128);
  }

  exportToExcel(e:ItineraryDTO) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(ws, [[this.ts.instant(this.reportName)]]);

    XLSX.utils.sheet_add_aoa(ws, [[`${this.ts.instant('Name')} : ${this.fullName()}`]], { origin: "A2" });
    XLSX.utils.sheet_add_aoa(ws, [[`${this.ts.instant('Phone Number')} : ${this.pf.transform(this.data.PhoneNumber,this.data.CountryId)}`]], { origin: "A3" });
    XLSX.utils.sheet_add_aoa(ws, [[`${this.ts.instant('Email Address')} : ${this.data.EmailAddress}`]], { origin: "A4" });

    XLSX.utils.sheet_add_aoa(ws, [[`${this.ts.instant('Start Date')} : ${this.propertyDateFormat(e.startDateTime)}`]], { origin: "A5" });
    XLSX.utils.sheet_add_aoa(ws, [[`${this.ts.instant('End Date')} : ${this.propertyDateFormat(e.endDateTime)}`]], { origin: "A6" });


    XLSX.utils.sheet_add_json(ws, this.getExcelExportValue(e.activities, this.activitiesColumns),
      { origin: 'A8', skipHeader: false, dateNF: this._settings.General.DateFormat });

    XLSX.utils.book_append_sheet(wb, ws, this.ts.instant(this.reportName));

    XLSX.writeFile(wb, `${this.fullName()}.xlsx`);
  }

  getExcelExportValue(GuestItinerary: CommonActivities[], displayedColumns: string[]) {
    let out = [];
    for (let i of GuestItinerary) {
      let temp = {};
      for (let j = 0; j < displayedColumns.length ; j++) {
        temp[this.itinerayExportColumn[j]] = new Exporter().getReportColumn(i, displayedColumns[j], this.OperationCurrency, this._settings.General.DateFormat);
      }
      out.push(temp)
    }
    return out;
  }
}

export class Exporter {
  getReportColumn(item, column, currency, format) {
    if (column == 'noOfGuests') {
      if( item[column] == undefined || isNaN(item[column])){
        return '';
      }
      return (item[column] as number);
    } else if (column == 'startDateTime' || column == 'endDateTime') {
      return moment(item[column]).format(`${format} h:mm a`)
    }
    return item[column];
  }
}
