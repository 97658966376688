
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { CacheService } from '@app/core/services/cache.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { PopupService } from '@app/popup-module/popup.service';
import { CancelActivityPopupComponent } from '@app/shared/components/cancel-activity-popup/cancel-activity-popup.component';
import { BookingBehavior, buttonTypes, CancelActivityType, ClassType, ComponentTypes, FinancialEffectType, HeadlineActivityType, OperationResultState, Operations, PartyState, PartyType, PricingBy, ViewBy, Status, AttributeType, FinancialEffectAction, PaymentMethod, TimeLineAvailablityShowTypes, RolesAndPermissionsType } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PartyChargeState, PartyPrepaymentState, ReAssignAssetDTO } from '@app/shared/models/InputContact';
import { ActivityCustomizationAppliesTo, BookingChargeType, LayoutDTO, SelectionType, SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { PartyService } from '@app/shared/services/party.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { Subscription } from 'rxjs';
import { AlertType, ButtonType } from '@app/common/enums/shared-enums';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import moment from 'moment';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import _ from 'lodash';
import { AppService } from '@app/app.service';
import { DatePipe } from '@angular/common';
import { HttpService } from '@app/shared/services/http.service';
import { seatRetailService } from '@app/shared/services/seatretail.service';
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';
import { ActivityHeadlinesComponent } from '@app/shared/components/activity-headlines/activity-headlines/activity-headlines.component';
import { ToastrService } from 'ngx-toastr';
import DisplayOptions from '@data/activitiesFilterOptions.json';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CancelSessionPopupComponent } from '@app/shared/components/cancel-session-popup/cancel-session-popup.component';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { RolesAndPermissionPipe } from '@app/shared/pipes/RolesAndPermission.pipe';
import { BookingDetail } from '@app/activities-timeline/components/BookingDetail';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent extends BookingDetail implements OnInit, AfterViewInit, OnDestroy {
  @Input() _settings;
  @Input() filterData;
  @Input() actionEventChanges;
  @Output() bookingActions = new EventEmitter();
  activityData: any;
  sessionData: any;
  sessionDetail: any = {};
  @ViewChild('dataContainer') el: ElementRef;
  @Input() data: any;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  blockedSessionDetails: any;
  blockedSession: any;
  @ViewChild('searchConfigForm', { static: true }) searchConfigForm: DynamicFormComponent;
  subscriptions: Subscription = new Subscription();
  locations: any[];
  staffs: any[];
  showAll: any = {};
  showAddOns: any = {};
  attendees: any[] = [];
  standbyAttendees: any[] = [];
  searchAttendeesText: string = '';
  PartyState = PartyState;
  partyType = PartyType;
  financialPartyData: any;
  confirmSubscription: Subscription;
  standBySubscription: Subscription;
  partiessubscription: Subscription;
  cancelledPartySubscription: Subscription;
  warningInfoDialogRef: MatDialogRef<any>;
  selectedViewId: number;
  isRetailEnabled: boolean;
  addonForActivity: any = [];
  addonsForBooking: any = [];
  addonsForBookingPerLesson: any = [];
  rightArrowDisabled: boolean = false;
  leftArrowDisabled: boolean = true
  showAddOnIcon = false;
  showAddOnDetails = false;
  publicQuickNote: any;
  privateQuickNote: any;
  headlinesForActivityGrouped: any;
  ActivityAttributeType = AttributeType;
  notesReservation: any = [];
  bookingPaymentMethod = PaymentMethod;
  rolesAndPermissionsType = RolesAndPermissionsType;
  displayOptions = DisplayOptions;
  optionsForFilter = [PartyState.Pending, PartyState.Seated, PartyState.Cancelled, PartyState.Left];
  classDetailDate: Date;
  @ViewChild('addOnForBookingPopover') private addOnForBookingPopover: NgbPopover;
  _layout: LayoutDTO = {} as LayoutDTO;
  TimeLineAvailablityShowTypeEnum = TimeLineAvailablityShowTypes;
  preventLoadSettings: boolean;
  headlineRequestSucceeded: boolean;
  noActionRequired: boolean = false;
  partyWithAddon: any;
  isSessionCancelled: boolean = false;
  locationListGroup: any[] = [];
  LocationGroupDetails: any;
  _MESSAGES = MESSAGES;
  constructor(public partyService: PartyService, public apiService: ApiService, public ts: TranslateService, public retailservice: seatRetailService,
    public dashboardFunctions: DashboardFunctions, public cs: CacheService, public appService: AppService,
    public datePipe: DatePipe, protected dialog: MatDialog, public popupService: PopupService, public httpService: HttpService, private utilities: CommonUtilities, private permissionPipe: RolesAndPermissionPipe, public toastrService?: ToastrService
  ) {
    super(dialog, ts)
  }

  ngOnInit(): void {
    this.initialSessionDetails();
    this.noActionRequired = this.cs.isIframeEnabled; //When VCart is enabled, actions are not permitted
    this.selectedViewId = this.data?.selectedViewId || null;
    this.displayOptions.forEach(item => {
      item.isSelected = this.optionsForFilter.includes(item.id) ? true : false
    })
  }
  

  initialSessionDetails() {
    this.setBasicInfo();
    this.loadAddOnForActivity();
    this.loadCancelledSession();
    this.loadBlockedSession();
    this.loadLocationGroup();
    this.staffAndLocationMapping();
    this.loadSessionAvailability();
    this.loadHeadlines(this.activityData?.ActivityAttributes);
    this.setMappedCustomGuestFields(this.activityData?.Id);
    this.loadButtonConfig();
  }
  loadButtonConfig() {
    let isOpenOrPrivate = this.filterData.isOpenBooking || this.filterData.isPrivateLessonBooking;
    this.sessionDetail['showAvailability'] = !isOpenOrPrivate;
    this.sessionDetail['AllowBook'] = !this.blockedSession && !this.isSessionCancelled;
    this.sessionDetail['AllowCancel'] = !isOpenOrPrivate && !this.isSessionCancelled && !this.noActionRequired && this.activityData?.ClassType === ClassType.Session;
    this.sessionDetail['AllowBlock'] = !isOpenOrPrivate && !this.blockedSession && !this.noActionRequired && !this.isSessionCancelled;
    this.sessionDetail['AllowUnblock'] = !isOpenOrPrivate && this.blockedSession && !this.noActionRequired;
    this.sessionDetail['AllowReAssignAsset'] = !isOpenOrPrivate && this.LocationGroupDetails && !this.isSessionCancelled && this.activityData.ClassType === ClassType.Session;
    this.bookingActions.emit(this.sessionDetail);
  }
  changeEvent(eventName: string) {
    switch (eventName) {
      case this._MESSAGES.labels.book: {
        this.bookActivity();
        break
      }
      case this._MESSAGES.labels.cancelThisActivity: {
        if (this.permissionPipe.transform(RolesAndPermissionsType.CancelActivity)) {
          this.cancelSession();
        } else {
          this.toastrService.warning(this.ts.instant('accessDenied'), "", { timeOut: 5000, closeButton: true });
          return;
        }

        break;
      }
      case this._MESSAGES.labels.block: {
        this.blockSession(true)
        break;
      }
      case this._MESSAGES.labels.unBlock: {
        this.blockSession(false)
        break;
      } case this._MESSAGES.labels.ReAssignAsset: {
        let assetDTO: ReAssignAssetDTO = {
          ActivityId: this.activityData?.Id,
          ActivitySessionId: this.sessionData.ActivitySessionId,
          ActivitySessionDate: new Date(moment(this.classDetailDate).format('YYYY-MM-DD'))
        }
        this.subscriptions.add(this.apiService.ReAssignAsset(assetDTO).subscribe(data => {

        }));
      }

    }
  }
  setBasicInfo() {
    this.activityData = this._settings.SpecialMeals.find(activity => activity.Id == this.filterData.ActivityId);
    this.sessionData = this.activityData?.ActivitySessions?.find(session => session.ActivitySessionId == this.filterData.SessionId) || null;
    this.classDetailDate = this.filterData.weeklyViewDate || this.filterData.date || this.cs.headerDate;
    if (this.activityData && this.sessionData) {
      this.sessionDetail['Name'] = this.activityData.Name || ''
      this.sessionDetail['locationId'] = this.filterData.locationId || null;
      this.sessionDetail['instructorId'] = this.filterData.instructorId || null
      this.sessionDetail['SessionId'] = this.sessionData.ActivitySessionId || null
      this.sessionDetail['Id'] = this.activityData.Id || null,
        this.sessionDetail['startTime'] = new Date('1970-01-01 ' + this.sessionData?.StartTime),
        this.sessionDetail['endTime'] = new Date('1970-01-01 ' + this.sessionData?.EndTime);
      this.isSessionCancelled = this.sessionData.StatusCode == Status.Cancelled;
    } else {
      this.sessionDetail['Name'] = this.activityData?.Name || this.ts.instant('OpenBooking');
    }
  }
  loadSessionAvailability() {
    if (this.activityData && this.sessionData) {
      if (this.activityData.ClassType === ClassType.Session) {
        let sessionId = this.sessionData.ActivitySessionId;
        if (this.partyService.allWeekActivitySessions[sessionId]?.Available || this.partyService.allWeekActivitySessions[sessionId]?.Available == 0) {
          this.sessionDetail['available'] = this.partyService.allWeekActivitySessions[sessionId]?.Available;
        }
        else {
           this.sessionDetail['available'] = this.sessionDetail.available || 0;
        }

        if (this.partyService.allWeekActivitySessions[sessionId]?.Booked || this.partyService.allWeekActivitySessions[sessionId]?.Booked == 0) {
          this.sessionDetail['booked'] = this.partyService.allWeekActivitySessions[sessionId]?.Booked;
        }
        else {
           this.sessionDetail.booked = this.sessionDetail.booked || 0;
        }

      }
      else if (this.activityData.ClassType === ClassType.Class) {
        if (this.partyService.allWeekActivitySessions['group_' + this.sessionData.SessionGroupId]?.Available || this.partyService.allWeekActivitySessions['group_' + this.sessionData.SessionGroupId]?.Available == 0) {
          this.sessionDetail['available'] = this.partyService.allWeekActivitySessions['group_' + this.sessionData.SessionGroupId]?.Available;
        }
        else {
          this.sessionDetail['available'] = this.sessionDetail['available'] || 0;
        }

        if (this.partyService.allWeekActivitySessions['group_' + this.sessionData.SessionGroupId]?.Booked || this.partyService.allWeekActivitySessions['group_' + this.sessionData.SessionGroupId]?.Booked == 0) {
          this.sessionDetail['booked'] = this.partyService.allWeekActivitySessions['group_' + this.sessionData.SessionGroupId]?.Booked;
        }
        else {
          this.sessionDetail['booked'] = this.sessionDetail['booked'] || 0;
        }

      }

      if (this.sessionDetail.available <= 0) {
        this.sessionDetail.overbooked = this.sessionDetail.available * -1;
      }
      this.sessionDetail ={...this.sessionDetail}
    }
  }

  loadBlockedSession() {
    if (this.sessionData) {
      let activityCustomisation = this.partyService.activityBlockingChange$.value?.ActivityBlockingRule ? Object.values(this.partyService.activityBlockingChange$.value?.ActivityBlockingRule).filter(r => r.ActivityId == this.activityData.Id && (r.AppliesTo == ActivityCustomizationAppliesTo.HostApp || r.AppliesTo == ActivityCustomizationAppliesTo.HostAppAndWidget)) : null;
      this.blockedSession = activityCustomisation?.find(x => x.ActivitySessionIds.includes(this.sessionData.ActivitySessionId) && (moment(moment(this.classDetailDate).format("YYYY-MM-DD")) >= moment(x.StartDate)) && (moment(moment(this.classDetailDate).format("YYYY-MM-DD")) <= moment(x.EndDate)));
      if (this.blockedSession) {
        this.blockedSessionDetails = {
          blockedBy: this.partyService.getHostName(this.blockedSession.ActionBy) ? this.partyService.getHostName(this.blockedSession.ActionBy) : '-',
          blockedUntil: this.sessionDetail['endTime'],
          blockingReason: this.blockedSession.ActionReason ? this.blockedSession.ActionReason : '-'
        }
      }
    }
  }
  loadCancelledSession() {
    if (this.sessionData) {
      this.isSessionCancelled = this.sessionData?.StatusCode == Status.Cancelled && moment(this.sessionData.SessionDate).format('YYYY-MM-DD') == moment(this.classDetailDate).format('YYYY-MM-DD');
      if (this.isSessionCancelled) {
        this.sessionDetail.CancelledReason = this.sessionData.Reason;
        this.sessionDetail.CancelledBy = this.partyService.getHostName(this.sessionData.SessionCustomization?.ActionBy) || '-';
        this.sessionDetail.CancelledUntil = this.sessionDetail['endTime'];
      }
    }
  }
  loadLocationGroup() {
    if (this._settings.LocationGroups?.length && this.sessionData) {
      this.LocationGroupDetails = this._settings.LocationGroups.find(location => location.LocationGroupId == this.sessionData.LocationGroupId)?.Name || null;
    }
  }

  loadHeadlines(ActivityAttributes) {
    if (this.sessionData) {
      let activityAttributesForSession = ActivityAttributes ? ActivityAttributes : [];
      let publicArray = { name: "Public", values: this.getPrivateAndPublicHeadline(activityAttributesForSession.filter(x => x.AttributeType == AttributeType.PublicHeadlines)) || [] };
      let privateArray = { name: "Private", values: this.getPrivateAndPublicHeadline(activityAttributesForSession.filter(x => x.AttributeType == AttributeType.PrivateHeadlines)) || [] };
      this.publicQuickNote = publicArray;
      this.privateQuickNote = privateArray;
    }
  }
  getPrivateAndPublicHeadline(ActivityAttributes) {
    let headline;
    if (ActivityAttributes?.length) {
      let columnDate = this.filterData.date || new Date();
      headline = ActivityAttributes.find(x => x.ActivitySessionId == this.sessionData.ActivitySessionId && x.Description !== '' && moment(columnDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(columnDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"));
      if (!headline) {
        headline = ActivityAttributes.find(x => x.ActivitySessionId == this.sessionData.ActivitySessionId && x.Description == '' && moment(columnDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(columnDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"));
      }
      if (!headline) {
        headline = ActivityAttributes.find(x => x.ApplyForAllSessions && !x.IsDefault && moment(columnDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(columnDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"))
      }
      if (!headline) {
        headline = ActivityAttributes.find(x => x.IsDefault && !x.ActivitySessionId);
      }
    }
    return headline && headline.Description !== '' ? [headline] : null;
  }


  loadAddOnForActivity() {
    this.showAddOnDetails = false;
    this.addonsForBookingPerLesson = [];
    this.addonForActivity = [];
    let _addonsForActivitySelected = Utilities.getAddons(this._settings.Addons, this.activityData?.Id || null);
    _addonsForActivitySelected = _.orderBy(_addonsForActivitySelected, 'IsMandatory', 'desc')
    _addonsForActivitySelected.forEach(data => {
      let addOnItem = this._settings.Addons.find(addOn => (data.AddonId == addOn.AddonId))

      if (addOnItem && addOnItem.SelectionType == SelectionType.PerLesson) {
        let category = this._settings.Categories.find(category => category.Id == addOnItem.CategoryId)
        if (category) {
          data['CategoryColor'] = `rgba(${category.Color.R}, ${category.Color.G}, ${category.Color.B}, ${category.Color.A})`;
        }
        this.addonsForBookingPerLesson.push(data);
      }
      else {
        this.showAddOnIcon = true;
        this.addonForActivity.push(data);
      }

    })
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.partyService.activityBlockingChange$.subscribe(data => {
      if(data?.ActivityBlockingRule){
        this.loadBlockedSession();
        this.loadButtonConfig();
      }
    }));



  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.actionEventChanges?.currentValue) {
      this.changeEvent(changes.actionEventChanges.currentValue);
    }
    if(changes?._settings?.currentValue){
      this.initialSessionDetails();
    }
    if(changes?.ActivityBlockingRule?.currentValue){
      this.loadBlockedSession();
    }
  }
  staffAndLocationMapping() {
    let locationList = this.cs.locationListForMerchant || [];
    let tempStaffs: any[] = [];
    let tempLocations: any[] = [];
    if (this.sessionData) {
      this.sessionData?.SessionStaffMappings?.forEach(staff => {
        tempStaffs.push(this._settings.Servers.find(server => server.Id == staff.ServerId)?.Name || '');
      })
      this.sessionData.SessionLocationMappings?.forEach(location => {
        tempLocations.push(locationList.find(loc => loc.id == location.StandaloneTableId)?.value || '');
      })
    } else {

    }
    this.staffs = tempStaffs;
    this.locations = tempLocations;
  }

  blockSession(blockSession: boolean) {
    this.sessionDetail['weeklyViewDate'] = this.classDetailDate;
    this.partyService.promptBlockSession(this.sessionDetail, this.sessionData, blockSession)
  }
  bookActivity() {

    let locationList = Utilities.getRestaurantTables(this._layout.FloorPlans || this.cs.layout['_value'].FloorPlans);
    const staffTbaId = this._settings.Servers?.find(x => x.IsTemplate)?.Id;
    const locationTbaId = locationList?.find(x => x.IsTemplate)?.Id;
    let selectedData = {
      fromDetailComponent: true,
      SessionId: this.sessionData?.ActivitySessionId || null,
      SpecialMealId: this.activityData?.Id || null,
      locationId: this.sessionDetail?.locationId && this.sessionDetail?.locationId != locationTbaId ? this.sessionDetail?.locationId : null,
      instructorId: this.sessionDetail.instructorId != staffTbaId ? this.sessionDetail.instructorId : staffTbaId,
      selectedSlot: true,
      activityBookedDate: this.sessionDetail?.startTime,
      startTime: this.sessionDetail?.startTime,
      endTime: this.sessionDetail?.endTime,
      weeklyViewDate: this.classDetailDate
    }
    if (this.filterData.isOpenBooking) {
      this.dashboardFunctions.createOrEditOpenBooking(false, null)
    } else if (this.filterData.isPrivateLessonBooking) {
      this.dashboardFunctions.createOrEditPrivateActivityBooking(false, selectedData);
    } else {
      this.dashboardFunctions.createOrEditActivityBooking(false, selectedData);
    }
  }

  getStaffNames() {
    let staffName = '';
    this.staffs.forEach((staff, index) => {
      staffName = staffName + staff.fullName;
      if (index < (this.staffs.length - 3)) {
        staffName = staffName + ', ';
      } else if (index === this.staffs.length - 2) {
        staffName = staffName + ' and ';
      }
    });

    return staffName;
  }



  locationListUpdate(attendee) {
    let locationList = [];
    if (attendee.BookedSessions?.length) {
      let selectedSession = attendee.BookedSessions?.find(session => session.Id == attendee.BookedSessionId)
      let defaultLayouts = this.cs.layout.value?.FloorPlans?.find(floor => floor.IsDefault)?.StandaloneTables;
      selectedSession?.Locations?.forEach(location => {
        let loc = {
          Name: defaultLayouts?.find(table => table.Id == location)?.Name
        }
        locationList.push(loc);
      })
    }
    this.locationListGroup = locationList;
  }

  cancelPartyForSessionOrClass(partyInfo) {
    this.partyService.BookingBehaviour = BookingBehavior.ClassOrSession;
    this.dashboardFunctions.CancelPartyForSessionOrClass(partyInfo, this.sessionDetail.CancelledReason);
  }

  onClose() {
    this.close.emit();
  }

  isBySession(): boolean {
    return this.activityData.ClassType === ClassType.Session;
  }

  addHeadlinesForActivity(classDetail, headline, noteType, componentName) {
    const popUpMessage = [{
      dialogTitle: componentName == ComponentTypes.cancelHeadlines ? this.ts.instant('deleteHeadline') : headline ? this.ts.instant('updateHeadline') : this.ts.instant('createHeadline'),
      showAlert: false,
      activityDetails: this.sessionDetail,
      headlines: headline,
      isCancel: componentName == ComponentTypes.cancelHeadlines,
      noteType: noteType.name
    }];
    this.headlineRequestSucceeded = true;
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ActivityHeadlinesComponent, 'small', '', popUpMessage,
      popUpMessage[0].dialogTitle);
    let headlinePopup = this.openCustomPopup(componentDetails, componentName ? componentName : ComponentTypes.saveHeadlines, '650px', 'auto', false, popUpMessage[0].dialogTitle, popUpMessage[0].isCancel ? 'Yes' : 'Save',
      popUpMessage[0].isCancel ? 'No' : 'Cancel', true);
    let saveConfirmation;
    if (componentName !== ComponentTypes.cancelHeadlines) {
      saveConfirmation = this.popupService.confirmedAction$.subscribe(val => {
        if (saveConfirmation) {
          saveConfirmation.unsubscribe();
        }
        if (val == ComponentTypes.saveHeadlines && this.headlineRequestSucceeded) {
          this.headlineRequestSucceeded = false;
          let activityAtributes = {
            Id: headline?.IsDefault ? 0 : (headline?.ApplyForAllSessions && this.partyService.headlinesForm.value.headlineType == HeadlineActivityType.OnlyThis) ? 0 : (headline ? headline.Id : 0),
            PropertyId: Utilities.RestaurantId(),
            ActivityId: this.activityData.Id,
            ActivitySessionId: this.sessionData.ActivitySessionId,
            Description: this.partyService.headlinesForm.value.activityHeadlines,
            ApplyForAllSessions: this.partyService.headlinesForm.value.headlineType == HeadlineActivityType.OnlyThis ? false : true,
            AttributeType: noteType.name == 'Public' ? AttributeType.PublicHeadlines : AttributeType.PrivateHeadlines,
            StartDate: moment(this.classDetailDate).format('YYYY-MM-DD') + 'T' + moment(this.sessionDetail.startTime).format('HH:mm:ss'),
            EndDate: moment(this.classDetailDate).format('YYYY-MM-DD') + 'T' + moment(this.sessionDetail.endTime).format('HH:mm:ss'),
            IsDefault: false,
            CreatedBy: Utilities.getHostId()
          }
          headlinePopup.close();
          let ActivityAttributes = this.activityData?.ActivityAttributes;
          headline = ActivityAttributes.find(x => x.ApplyForAllSessions && activityAtributes.AttributeType == x.AttributeType && activityAtributes.Id != x.Id && moment(this.classDetailDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(this.classDetailDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"));
          if (headline && this.partyService.headlinesForm.value.headlineType == HeadlineActivityType.AllRemaining) {
            this.showHeadLineWarning(headline, activityAtributes, headlinePopup);
          } else {
            this.partyService.SaveHeadlines(activityAtributes).subscribe(data => {
              this.updateHeadlineSettings(data.Payload, activityAtributes.ActivityId);
            })
          }
        }
      });
    }
    let cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from == ComponentTypes.saveHeadlines || val.from == ComponentTypes.cancelHeadlines || val.from == ComponentTypes.headlineUpdateWarningMessage) {
        headlinePopup.close();
        this.UnSubscribePopUpAction(cancelSubscription);
        this.UnSubscribePopUpAction(saveConfirmation);
      }
    });
    if (componentName == ComponentTypes.cancelHeadlines) {
      this.headLineDeleteAction(headline, headlinePopup);
    }
    headlinePopup.afterClosed().subscribe(event => {
      this.UnSubscribePopUpAction(saveConfirmation);
      this.UnSubscribePopUpAction(cancelSubscription);
    })
  }
  headLineDeleteAction(headline, headlinePopup) {
    this.headlineRequestSucceeded = true;
    let deleteConfirmation = this.popupService.confirmedAction$.subscribe(val => {
      if (deleteConfirmation) {
        this.UnSubscribePopUpAction(deleteConfirmation)
      }
      if (val == ComponentTypes.cancelHeadlines && this.headlineRequestSucceeded) {
        this.headlineRequestSucceeded = false
        headlinePopup.close();
        let sessionId = this.partyService.headlinesForm.value.headlineType == HeadlineActivityType.OnlyThis ? this.sessionData.ActivitySessionId : null
        let Payload = {
          activityAttributeId: headline.Id,
          StartDate: moment(this.classDetailDate).format('YYYY-MM-DD') + 'T' + moment(this.sessionDetail.startTime).format('HH:mm:ss'),
          EndDate: moment(this.classDetailDate).format('YYYY-MM-DD') + 'T' + moment(this.sessionDetail.endTime).format('HH:mm:ss')
        }
        this.partyService.DeleteHeadlines(Payload, sessionId).subscribe(data => {
          this.updateHeadlineSettings(data.Payload, this.activityData.Id);
          this.toastrService.success(this.ts.instant("headlineDeleteSuccessMessage"), "", { timeOut: 1000, closeButton: true });
        })
      }
    })
    headlinePopup.afterClosed().subscribe(event => {
      this.UnSubscribePopUpAction(deleteConfirmation);
    })
  }

  showHeadLineWarning(headline, activityAtributes, headlinePopup) {
    this.headlineRequestSucceeded = true
    let msg = ''
    let notes = headline.Description
    this.ts.get('headlineUpdateWarningMessage', { notes }).subscribe(msgVal => {
      msg = msgVal;
    });
     this.showWarningInfoPopUp(msg, ComponentTypes.headlineUpdateWarningMessage, '300px', '450px', this.ts.instant('alert'));
    let healineUpdateConfirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val == ComponentTypes.headlineUpdateWarningMessage && this.headlineRequestSucceeded) {
        this.headlineRequestSucceeded = false;
        headlinePopup.close();
        if (healineUpdateConfirmSubscription) {
          healineUpdateConfirmSubscription.unsubscribe();
        }
        activityAtributes.Id = 0;
        this.partyService.SaveHeadlines(activityAtributes).subscribe(data => {
          this.updateHeadlineSettings(data.Payload, activityAtributes.ActivityId);
        })
      }
    })
  }

  showWarningInfoPopUp(message, componentType, popupHeight, popupWidth, title) {

    let cancelText = 'No';
    // let title = 'Confirm Check-in';
    //let msg = 'No card details collected to capture payment amount. Are you sure to checkin the attendee?'
    let noShowSet = false;
    let confirmCheckin = false;
    let updateText = 'Yes';
    let showAlert = false;
    let noShowFeePopUp = false;

    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: 'confirm', showAlert: showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    this.warningInfoDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: popupWidth,
      height: popupHeight,
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: componentType,
        back: false,
        standalone: true,
        showAlert: true
      }
    });


  }

  updateHeadlineSettings(activityAttributes, activityId) {
    if (activityAttributes) {
      this.preventLoadSettings = true;
      this.loadHeadlines(activityAttributes);
      let index = this._settings.SpecialMeals.findIndex(activity => activity.Id == activityId);
      this._settings.SpecialMeals[index].ActivityAttributes = activityAttributes;
      this.cs.settings.next(this._settings);
    }
  }


  deleteHeadlinesForActivity(classDetail, data, note) {
    this.addHeadlinesForActivity(classDetail, data, note, ComponentTypes.cancelHeadlines,);
  }

  cancelSession() {
    const restaurantDateTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    let selectedSession = this.sessionData
    let endTime = moment(this.classDetailDate).format('YYYY-MM-DD') + 'T' + selectedSession.EndTime;
    if (restaurantDateTime > new Date(endTime)) {
      this.toastrService.warning(this.ts.instant('sessionExpiredCancelMessage'), "", { timeOut: 5000, closeButton: true });
      return;
    }

    const componentDetails: ComponentDetails = {
      componentName: CancelSessionPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: {
        activityData: this.sessionDetail,
        sessionData: this.sessionData,
        cancellationDate: this.classDetailDate,
        bookings: [...this.attendees, ...this.standbyAttendees]
      }
    };
    const dialogRef = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      width: '550px',
      height: '450px',
      data: {
        title: 'cancelConfirmationMsg',
        update: 'Yes',
        cancel: 'No',
        componentDetails,
        from: ComponentTypes.cancelIndividualSession,
        standalone: true,
        showAlert: true,
      }
    });
  }


  UnSubscribePopUpAction(obj) {
    if (obj) {
      obj.unsubscribe();
    }
  }
  moveToShopScreen(attendee) {

    this.partyService.currentPartyId = attendee?.Id;
    if (Utilities.isUserHasAccessToRetail(this.appService.restaurantId)) {
      this.retailservice.SetShopItems([], null, null, attendee?.Id, null, null, attendee?.Contact?.Id, null, null, attendee?.Contact?.TrackMembershipNumber);
      this.partyService.bookingDetailViewPopupDialogRef?.close();
    } else {
      this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
    }
  }

  ngOnDestroy() {
    this.partiessubscription && this.partiessubscription.unsubscribe();
    this.cancelledPartySubscription && this.cancelledPartySubscription.unsubscribe();
    this.standBySubscription && this.standBySubscription.unsubscribe();
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

}

const MESSAGES = {
  labels: {
    cancelThisActivity: "CancelThisSession",
    unBlock: "Unblock",
    book: "book",
    block: "blockbuttontext",
    ReAssignAsset: "ReAssignAsset",
  }
}
