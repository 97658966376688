import { CommonModule } from '@angular/common';
import { Component, HostListener, Inject, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from '@app/core/services/loader.service';
import { ConfigTheme } from '@app/pos/const/global.const';
import { InputSelectComponent } from '@app/pos/form-components/input-select/input-select.component';
import { GenerateKeyRequest } from '@app/pos/interfaces/request';
import { WristbandDetailResponse } from '@app/pos/pages/home-page/home-page.component';
import { POSApiService } from '@app/pos/services/api.service';
import { UtilService } from '@app/pos/services/util.service';
import { CounterComponent } from '@app/pos/util-components/counter/counter.component';
import { DialogContainerComponent } from '@app/pos/util-components/dialog-container/dialog-container.component';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { environment } from '@environments/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GuestSearchGlobalComponent } from '../guest-search-global/guest-search-global.component';
import { CacheService } from '@app/core/services/cache.service';

@Component({
  selector: 'respos-wristband-mapping-container',
  standalone: true,
  imports: [CommonModule, FormsModule, CounterComponent, TranslateModule, InputSelectComponent, GuestSearchGlobalComponent, CommonModule, DialogContainerComponent],
  templateUrl: './wristband-mapping-container.component.html',
  styleUrls: ['./wristband-mapping-container.component.scss']
})
export class WristbandMappingContainerComponent implements OnInit, OnDestroy {

  dialogRef = inject(MatDialogRef);
  loaderService = inject(LoaderService);
  utilService = inject(UtilService);
  apiService = inject(POSApiService);
  cs = inject(CacheService);
  dashboardFunctions = inject(DashboardFunctions);
  translate = inject(TranslateService);
  wristbandInputDetail: WristbandDetailResponse;
  wristbandmappingurl: string = environment.BaseUrl;
  messageListner;
  encodeState: 'unknown' | 'initiated' | 'ready' | 'completed' | 'skipped' = 'unknown';
  screen: 'POS' | 'MODERNCLIENT' = 'POS';
  deviceForm: FormGroup;
  deviceList;
  generateKeySubscription: Subscription;
  dateFormat: string = "dd-MM-yyyy hh:mm a";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.wristbandInputDetail = data.wristbandInputDetail;
    this.wristbandmappingurl = this.wristbandInputDetail.WristbandURL + (this.screen === 'POS' ? `&colorCode=${ConfigTheme.POS}` : `&colorCode=${ConfigTheme.CLIENT}`);
    if (data.screen) {
      this.screen = data.screen;
    }

    if(this.cs.settings.value?.General?.DateFormat) {
      this.dateFormat = this.cs.settings.value?.General?.DateFormat + ' hh:mm a';
      this.dateFormat = this.dateFormat.replace('DD', 'dd');
    }
    // this.loaderService.isLoading.next(true);
    // this.intiateEncode();
  }

  ngOnInit(): void {
    if(this.data.encodingEnabled){
      this.deviceList = this.wristbandInputDetail.Devices.map(device => {
        return { name: device.DeviceName, subtext: device.DeviceCode, value: device.DeviceCode }
      })
      let selectedEncoder = localStorage.getItem("comtrol-encoder") || this.deviceList[0].value;
  
      this.deviceForm = new FormGroup({
        encoder: new FormControl(selectedEncoder)
      })
  
      if (localStorage.getItem("comtrol-encoder")) {
        this.intiateEncode();
      }

      this.deviceForm.valueChanges.subscribe(() => {
        if(this.encodeState === 'ready'){
          this.intiateEncode();
        }
      })
    }else{
      this.loaderService.isLoading.next(true);
    }
  }

  intiateEncode() {
    this.encodeState = 'initiated';
    const encoderSelectedValue = this.deviceForm.controls['encoder'].value;
    const encoderSelected = this.deviceList.find(({ value }) => value === encoderSelectedValue);
    const request: GenerateKeyRequest = {
      ReservationId: this.wristbandInputDetail.KeyRequestDTO.ReservationId,
      GroupSize: this.wristbandInputDetail.KeyRequestDTO.GroupSize,
      GuestFirstName: this.wristbandInputDetail.KeyRequestDTO.GuestFirstName,
      ItineraryStartDate: this.wristbandInputDetail.KeyRequestDTO.ItineraryStartDate,
      ItineraryEndDate: this.wristbandInputDetail.KeyRequestDTO.ItineraryEndDate,
      GuestLastName: this.wristbandInputDetail.KeyRequestDTO.GuestLastName,
      SelectedDevice: {
        DeviceCode: encoderSelected.value,
        DeviceName: encoderSelected.name
      }
    }
    this.generateKeySubscription = 
      this.apiService.generateEncoderKey(request).subscribe(() => {
        localStorage.setItem('comtrol-encoder', encoderSelectedValue)
          this.encodeState = 'ready';
      }, error => {
        this.encodeState = 'unknown';
      })

  }

  skipEncode(){
    if(this.generateKeySubscription){
      this.generateKeySubscription.unsubscribe();
    }
    this.encodeState = 'skipped';
  }

  cancelEncode(){
    if(this.generateKeySubscription){
      this.generateKeySubscription.unsubscribe();
    }
    this.encodeState = 'unknown';
  }

  startWristbandMapping(): void {
    this.encodeState = 'completed';
    this.loaderService.isLoading.next(true);
  }

  closeDialog() {
    this.dialogRef.close();
  }


  @HostListener('window:message', ['$event'])
  onMessage({ data }) {
    let _eventName = data?.data?.event_id;
    if (_eventName === 'iframe_loading') {
      this.loaderService.isLoading.next(true);
    } else if (_eventName === 'iframe_loaded') {
      this.loaderService.isLoading.next(false);
    } else if (_eventName === 'register_failed') {
      this.loaderService.isLoading.next(false);
      let ErrorMessage = data?.data?.message || 'Wristband registration failed!';
      this.screen === 'POS' ? this.utilService.showError([ErrorMessage]) : this.dashboardFunctions.showErrorPopUp(ErrorMessage, "auto");
    } else if (['register_sucess', 'register_cancelled'].includes(_eventName)) {
      if (_eventName === 'register_sucess') {
        let title = this.translate.instant('Registered Successfully!');
        let successMessage = this.translate.instant('Wristband registered successfully');
        this.screen === 'POS' ? this.utilService.showSuccessMessage(title, [successMessage]) : this.dashboardFunctions.showSuccessPopup(successMessage);
      }
      this.loaderService.isLoading.next(false);
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    if(this.generateKeySubscription){
      this.generateKeySubscription.unsubscribe();
    }
  }
}