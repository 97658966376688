import { DatePipe } from "@angular/common";
import { CacheService } from "@app/core/services/cache.service";
import { BookingBehavior, ClassType, FinancialEffectType, Operations, PartyType, PaymentGateways } from "@app/shared/constants/commonenums";
import { ItemType, PrePaymentMode, RetailOpenItemDTO, SettingsDTO } from "@app/shared/models/RestaurantDTO";
import { Utilities } from "@app/shared/utilities/utilities";
import _, { truncate } from "lodash";
import { seatRetailService } from "../seatretail.service";
import { FinancialEffectResponse } from "@app/shared/models/FinancialEffect.model";

export class SetRetailItems {

  constructor(private datePipe: DatePipe, private cs: CacheService, private retailservice: seatRetailService, private Settings) {
    this._newRetailOpenItemDTO = [];
    //this._settings=Settings;
  }

  private _newRetailOpenItemDTO: RetailOpenItemDTO[] = [];



  CreateBooking(party, ReservationAttemptData) {
    this.retailservice.SetShopItems(ReservationAttemptData.ShopItems, 
      ReservationAttemptData.ResponseDTOs[0].RatePlanTotal.Reservations,
      Operations.create, 
      party, 
      null, null, null, 
      null, null, party?.CartItemDetail?.Contact?.MemberInfo?.MemberId, null, ReservationAttemptData.PaidBy);
  }

  UpdateBooking(party, financialData: FinancialEffectResponse) {
    this._newRetailOpenItemDTO = financialData.ShopItems;
    this.retailservice.SetShopItems(financialData.ShopItems, financialData.RatePlan.Reservations,
      Operations.update,
      party.Id,
      financialData?.ReturnItem != null ? financialData?.ReturnItem[0]?.TransactionId : null,
      financialData?.ReturnItem != null ? financialData?.ReturnItem[0]?.TicketNumber : null,
      null, null,
      financialData,
      financialData.RatePlan.Reservations[0]?.Sessions[0]?.GuestPrices[0]?.MemberInfo?.MemberId);
  }

  productName(Transaction, Name) {
    let updatedName = Name;
    if (Transaction.TotalAmount && Transaction.CancellationAmount) {
      updatedName = `${Name} `;
    } else if (Transaction.TotalAmount) {
      updatedName = Name;
    }
    return updatedName;
  }

  CancelBooking(party, financialData) {

    this.retailservice.SetShopItems(financialData.ShopItems, financialData.RatePlan?.Reservations,
      Operations.cancel,
      party.Id,
      financialData?.ReturnItem != null ? financialData?.ReturnItem[0]?.TransactionId : null,
      financialData?.ReturnItem != null? financialData?.ReturnItem[0]?.TicketNumber : null,
      party.Contact.Id,null,
      financialData,
      financialData.RatePlan?.Reservations[0]?.Sessions[0]?.GuestPrices[0]?.MemberInfo?.MemberId);

  }

  RefundBooking(party, financialData , bookingContactId) {
    this.retailservice.SetShopItems(financialData.ShopItems, financialData.RatePlan?.Reservations,
      Operations.Refund,
      party.Id,
      financialData?.ReturnItem != null ? financialData?.ReturnItem[0]?.TransactionId : null,
      financialData?.ReturnItem != null? financialData?.ReturnItem[0]?.TicketNumber : null,
      (bookingContactId || party.Contact.Id),null,
      financialData,
      financialData.RatePlan?.Reservations[0]?.Sessions[0]?.GuestPrices[0]?.MemberInfo?.MemberId);

  }


  getRetailItems(party) {
    let settings;
    if (party.RestaurantId) {
      settings = this.Settings.value[party.RestaurantId].settings;
    } else if (party.PropertyId) {
      settings = this.Settings.value[party.PropertyId].settings;
    }
    if (party.SpecialMealId) {

      let sm = settings?.SpecialMeals?.filter(activity => activity.Id == party.SpecialMealId)[0];
      let retail = sm.RetailItemId ? settings.RetailItems.filter(r => r.Id == sm.RetailItemId)[0] : settings.RetailItems?.filter(r => r.IsDefault)[0];
      if (sm.PrePaymentMode == PrePaymentMode.DepositPaymentMode) {

        retail = this.getRetailDepositItem(settings);
      }

      return retail;
      // ExternalPOSItemId = retail.ExternalPOSItemId;
    } else {
      let retail = settings?.RetailItems?.filter(r => r.IsDefault)[0];
      return retail;
    }
  }


  getAddonRetailItems(party, addOnId) {
    let settings;
    if (party.RestaurantId) {
      settings = this.Settings.value[party.RestaurantId].settings;
    } else if (party.PropertyId) {
      settings = this.Settings.value[party.PropertyId].settings;
    }
    if (addOnId) {
      let sm = settings.Addons.find(addOnItem => addOnItem.AddonId == addOnId)

      let retail = sm.RetailItemId ? settings.RetailItems.filter(r => r.Id == sm.RetailItemId)[0] : settings.RetailItems.filter(r => r.IsDefault)[0];


      return retail;
      // ExternalPOSItemId = retail.ExternalPOSItemId;
    }
  }


  private getRetailDepositItem(settings): any {
    if (settings.RetailItems.find(r => (r.ItemType == ItemType.Deposit) || (r.ItemType == ItemType.CancellationFee) || (r.ItemType == ItemType.DepositAndCancellationFee))) {
      return settings.RetailItems.filter(r => (r.ItemType == ItemType.Deposit) || (r.ItemType == ItemType.CancellationFee) || (r.ItemType == ItemType.DepositAndCancellationFee))[0];
    }
  }

  private getGuestName(fName, lName) {
    let name = "";
    if (fName)
      name = fName;
    if (lName)
      name = name + " " + lName;
    return name.trim();
  }

  getPackageName(packageId) {
    return this.cs.availablePackages.find(pack => pack.Id == packageId)?.Name;
  }

  getRetailProductName(party, timeRange) {
    let settings;
    let Name = 'Open Booking';
    let CartItemDetail = party;
    let sm;
    if (party.RestaurantId) {
      settings = this.Settings.value[party.RestaurantId].settings;
    } else if (party.PropertyId) {
      settings = this.Settings.value[party.PropertyId].settings;
    }
    if (CartItemDetail && CartItemDetail.SpecialMealId) {
      sm = this.cs.specialMealListForMerchant.filter(activity => activity.Id == CartItemDetail.SpecialMealId)[0];
      let retail = sm.RetailItemId ? settings.RetailItems.filter(r => r.Id == sm.RetailItemId)[0] : settings.RetailItems.filter(r => r.IsDefault)[0];
      {
        Name = sm.Name;
      }
      if (sm.PrePaymentMode == PrePaymentMode.DepositPaymentMode) {
        Name = Name + ' (Deposit)';

        retail = this.getRetailDepositItem(settings);

      }

      // ExternalPOSItemId = retail.ExternalPOSItemId;
    }
    if (timeRange && sm?.ClassType != ClassType.Class) {
      let formatedDayValue = this.datePipe.transform(new Date(timeRange.Start), 'MMM d');
      let formatedStartTime = this.datePipe.transform(new Date(timeRange.Start), 'hh:mm a');
      let formatedEndTime = this.datePipe.transform(new Date(timeRange.End), 'hh:mm a');
      Name = `${Name} (${formatedDayValue}, ${formatedStartTime} - ${formatedEndTime})`;
    }
    else if (timeRange && sm?.ClassType == ClassType.Class) {
      let formatedDayStartValue = this.datePipe.transform(new Date(timeRange.Start), 'MMM d');
      let formatedDayEndValue = this.datePipe.transform(new Date(timeRange.End), 'MMM d');
      Name = `${Name} (${formatedDayStartValue} - ${formatedDayEndValue})`;

    }
    if (party.PackageId != null && party.PackageId > 0) {
      let packageName = this.getPackageName(party.PackageId);
      Name = Name + ' - ' + packageName;
    }

    return Name;
  }

  private getRetailOpenItem(party, amount, negotiatedAmount, item, retailItem, attemptDetailId, timeRange, Transaction, PackageDiscountedAmount, fromWidget, serviceCharges?, Tax?) {
    const openItemPayload = {} as RetailOpenItemDTO;
    /// let negotiatedAmount = ignoreNegotiatedAmt ? 0 : party.Slots.filter(slot => slot.Time == i)[0]?.NegotiatedAmount || 0;
    openItemPayload.PartyId = item.Id;
    openItemPayload.Id = party.Id || item.Id;
    openItemPayload.RatePlanAmount = Utilities.getRoundedNumber(amount + Number(negotiatedAmount || 0) + Number(PackageDiscountedAmount || 0));
    openItemPayload.ItemDescription = this.getRetailProductName(party, timeRange);
    openItemPayload.SalesPrice = Utilities.getRoundedNumber(amount + Number(negotiatedAmount || 0) + Number(PackageDiscountedAmount || 0));
    openItemPayload.ItemId = retailItem.RetailItemId;
    openItemPayload.Description = this.getRetailProductName(party, timeRange);
    openItemPayload.BookingType = party.BookingBehavior;
    openItemPayload.TransactionId = Transaction && Transaction.ReturnItem ? Transaction.ReturnItem[0]?.TransactionId : null;
    openItemPayload.TicketNumber = Transaction && Transaction.ReturnItem ? Transaction.ReturnItem[0]?.TicketNumber : null;
    openItemPayload.QuantitySold = 1;
    openItemPayload.ExternalPOSItemId = retailItem.ExternalPOSItemId;
    openItemPayload.GuestId = party?.Contact?.Id;
    openItemPayload.MemberId = party?.Contact?.TrackMembershipNumber;
    openItemPayload.MemberActive = party?.Contact?.MemberActive;
    openItemPayload.fromWidget = fromWidget;
    openItemPayload.GuestName = this.getGuestName(party?.CartItemDetail?.Contact?.FirstName, party?.CartItemDetail?.Contact?.LastName);
    openItemPayload.CartId = item.Id;
    openItemPayload.PayeeId = party?.Contact?.Id;
    openItemPayload.Tax = Tax;
    openItemPayload.BookingAttemptId = attemptDetailId;
    openItemPayload.hideZeroPrice = party.hideZeroPrice ? true : false;
    openItemPayload.TransDetailId = Transaction?.OldBooking?.TransDetailId;
    openItemPayload.serviceCharges = serviceCharges;
    openItemPayload.MemberRound = party.Contact.MemeberRoundRedeem ? [party.Contact.MemeberRoundRedeem] : [];
    this._newRetailOpenItemDTO.push(openItemPayload);
  }

  private getMinRateAdjustment(party, amount, negotiatedAmount, item, retailItem, attemptDetailId, timeRange, Transaction, PackageDiscountedAmount, fromWidget, serviceCharges?, Tax?) {
    const openItemPayload = {} as RetailOpenItemDTO;
    /// let negotiatedAmount = ignoreNegotiatedAmt ? 0 : party.Slots.filter(slot => slot.Time == i)[0]?.NegotiatedAmount || 0;
    openItemPayload.PartyId = item.Id;
    openItemPayload.Id = party.Id || item.Id;
    openItemPayload.RatePlanAmount = Utilities.getRoundedNumber(amount + Number(negotiatedAmount || 0) + Number(PackageDiscountedAmount || 0));
    openItemPayload.ItemDescription = "Min. Rate Adjustment";
    openItemPayload.SalesPrice = Utilities.getRoundedNumber(amount + Number(negotiatedAmount || 0) + Number(PackageDiscountedAmount || 0));
    openItemPayload.ItemId = retailItem.RetailItemId;
    openItemPayload.Description = "Min. Rate Adjustment";
    openItemPayload.BookingType = party.BookingBehavior;
    openItemPayload.TransactionId = Transaction && Transaction.ReturnItem ? Transaction.ReturnItem[0]?.TransactionId : null;
    openItemPayload.TicketNumber = Transaction && Transaction.ReturnItem ? Transaction.ReturnItem[0]?.TicketNumber : null;
    openItemPayload.QuantitySold = 1;
    openItemPayload.ExternalPOSItemId = retailItem.ExternalPOSItemId;
    openItemPayload.GuestId = party?.Contact?.Id;
    openItemPayload.MemberId = party?.Contact?.TrackMembershipNumber;
    openItemPayload.MemberActive = party?.Contact?.MemberActive;
    openItemPayload.fromWidget = fromWidget;
    openItemPayload.GuestName = this.getGuestName(party?.CartItemDetail?.Contact?.FirstName, party?.CartItemDetail?.Contact?.LastName);
    openItemPayload.CartId = item.Id;
    openItemPayload.PayeeId = party?.Contact?.Id;
    openItemPayload.Tax = Tax;
    openItemPayload.BookingAttemptId = attemptDetailId;
    openItemPayload.hideZeroPrice = party.hideZeroPrice ? true : false;
    openItemPayload.TransDetailId = Transaction?.OldBooking?.TransDetailId;
    openItemPayload.serviceCharges = serviceCharges;
    this._newRetailOpenItemDTO.push(openItemPayload);
  }

  private getAddOnItems(party, addOnItem, item, retailItem, Transaction, fromWidget, serviceCharges?) {
    const openItemPayload = {} as RetailOpenItemDTO;
    let freeaddon = this.getAddfreeAddon(party, addOnItem.AddonId);
    let value = fromWidget ? -1 : 1;
    if (!freeaddon) {
      /// let negotiatedAmount = ignoreNegotiatedAmt ? 0 : party.Slots.filter(slot => slot.Time == i)[0]?.NegotiatedAmount || 0;
      openItemPayload.PartyId = item.Id;
      openItemPayload.Id = party.Id || item.Id;
      openItemPayload.RatePlanAmount = Utilities.getRoundedNumber(value * (((addOnItem.TotalAmount || addOnItem.Price || 0) + (addOnItem.NegotiatedAmount || 0) + (addOnItem.AdditionalAmount || 0)) / (addOnItem.Quantity - (addOnItem.OverBooked || 0))))  //+ Number(negotiatedAmount || 0);
      openItemPayload.ItemDescription = this.getAddOnName(party, addOnItem.AddonId);
      openItemPayload.SalesPrice = Utilities.getRoundedNumber(value * (((addOnItem.TotalAmount || addOnItem.Price || 0) + (addOnItem.NegotiatedAmount || 0) + (addOnItem.AdditionalAmount || 0)) / (addOnItem.Quantity - (addOnItem.OverBooked || 0))))  //+ Number(negotiatedAmount || 0);
      openItemPayload.ItemId = retailItem.RetailItemId;
      openItemPayload.Description = this.getAddOnName(party, addOnItem.AddonId);
      openItemPayload.BookingType = party.BookingBehavior;
      openItemPayload.TransactionId = Transaction && Transaction.ReturnItem ? Transaction.ReturnItem[0]?.TransactionId : null;
      openItemPayload.TicketNumber = Transaction && Transaction.ReturnItem ? Transaction.ReturnItem[0]?.TicketNumber : null;
      openItemPayload.QuantitySold = addOnItem.Quantity - (addOnItem.OverBooked || 0);
      openItemPayload.ExternalPOSItemId = retailItem.ExternalPOSItemId;
      openItemPayload.GuestId = party?.Contact?.Id;
      openItemPayload.MemberId = party?.Contact?.TrackMembershipNumber;
      openItemPayload.MemberActive = party?.Contact?.MemberActive;
      openItemPayload.fromWidget = fromWidget;
      openItemPayload.GuestName = this.getGuestName(party?.CartItemDetail?.Contact?.FirstName, party?.CartItemDetail?.Contact?.LastName);
      openItemPayload.CartId = item.Id;
      openItemPayload.PayeeId = party?.Contact?.Id;
      openItemPayload.BookingAttemptId = addOnItem.AttemptDetailId;
      openItemPayload.hideZeroPrice = party.hideZeroPrice ? true : false;
      openItemPayload.TransDetailId = addOnItem.TransDetailId;
      openItemPayload.serviceCharges = serviceCharges;
      this._newRetailOpenItemDTO.push(openItemPayload);
    }
  }

  getAddOnName(party, addOnId) {
    let settings;
    if (party?.RestaurantId) {
      settings = this.Settings.value[party.RestaurantId].settings;
    } else if (party?.PropertyId) {
      settings = this.Settings.value[party.PropertyId].settings;
    }
    return (settings.Addons.find(addOnItem => addOnItem.AddonId == addOnId).AddonName || '')
  }
  getPropertySettings(party) {
    let settings;
    if (party?.RestaurantId) {
      settings = this.Settings.value[party.RestaurantId].settings;
    } else if (party?.PropertyId) {
      settings = this.Settings.value[party.PropertyId].settings;
    }
    return settings;
  }
  getAddfreeAddon(party, addOnId) {
    let settings;
    if (party.RestaurantId) {
      settings = this.Settings.value[party.RestaurantId].settings;
    } else if (party.PropertyId) {
      settings = this.Settings.value[party.PropertyId].settings;
    }
    let addons = settings.Addons.find(addOnItem => addOnItem.AddonId == addOnId);
    if (addons) {
      let isvisible = addons.AddonActivitiesMappings.find(addOnItem => addOnItem.IsVisible && addOnItem.ActivityId == party.SpecialMealId);
      if (!isvisible) {
        return true;
      }
    }
    return false;
  }

  CheckinOperation(Party, financialData, operationType) {
    this.retailservice.SetShopItems(financialData.ShopItems, financialData.RatePlan.Reservations,
      operationType,
      Party.Id,financialData?.ReturnItem != null ? financialData?.ReturnItem[0]?.TransactionId : null,
      financialData?.ReturnItem != null ? financialData?.ReturnItem[0]?.TicketNumber : null,
       null, null,
      financialData,
      financialData.RatePlan.Reservations[0]?.Sessions[0]?.GuestPrices[0]?.MemberInfo?.MemberId);

  }


  chargeParty(Party, financialData) {
    this.retailservice.SetShopItems(financialData.ShopItems, financialData.RatePlan.Reservations,
      Operations.charge,
      Party.Id, financialData?.ReturnItem != null ? financialData?.ReturnItem[0]?.TransactionId : null,
      financialData?.ReturnItem != null ? financialData?.ReturnItem[0]?.TicketNumber : null,
       null, null,
      financialData,
      financialData.RatePlan.Reservations[0]?.Sessions[0]?.GuestPrices[0]?.MemberInfo?.MemberId);
  }

  CheckoutOperation(Party, financialData) {
    this.retailservice.SetShopItems(financialData.ShopItems, financialData.RatePlan.Reservations,
      Operations.checkOut,
      Party.Id, financialData?.ReturnItem != null ? financialData?.ReturnItem[0]?.TransactionId : null,
      financialData?.ReturnItem != null ? financialData?.ReturnItem[0]?.TicketNumber : null,
       null, null,
      financialData,
      financialData.RatePlan.Reservations[0]?.Sessions[0]?.GuestPrices[0]?.MemberInfo?.MemberId);
  }

  AdditionalChargeOperation(Parties, financialData) {
    if (financialData && ((financialData.PartyPaymentType == FinancialEffectType.PartialRefund) || (financialData.PartyPaymentType == FinancialEffectType.FullRefund)) && (financialData.OldTotalAmount == financialData.PaymentAmount)) {
      Parties.forEach(party => {
        party.hideZeroPrice = false;
      });
    }

    if (financialData) {
      //Parties = [this.retailservice.oldReservation, ...Parties]
      if (financialData.OldBooking) {
        let selectedItem = Parties[0];
        let oldBookingAmount = financialData.OldBooking.OldTotalAmount + financialData.AdditionalCharge;
        this.getRetailOpenItem(selectedItem, oldBookingAmount, 0, selectedItem, this.getRetailDepositItem(this.getPropertySettings(selectedItem)), 0, null, financialData, null, false);
        financialData.OldBooking.SelectedAddOns?.forEach(addOnItem => {
          this.getAddOnItems(selectedItem, addOnItem, selectedItem, this.getAddonRetailItems(selectedItem, addOnItem.AddonId), financialData, false, addOnItem.ServiceCharges);
        });

      }

    }

    this.retailservice.SetSeatOpenItemToRetail(this._newRetailOpenItemDTO, Operations.checkOut, financialData?.ReturnItem != null ? financialData?.ReturnItem[0]?.TransactionId : null, financialData?.ReturnItem != null ? financialData?.ReturnItem[0]?.TicketNumber : null, null, financialData);


  }



}
