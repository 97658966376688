<div>
    <div *ngIf="showAddOnDetails; else loaderContainer" class="parties-grid__scrollSize">
      <div class="reservation-notes">
        <div class="reservation-notes__header seat-popover-header">
          {{'addons' | translate}}
        </div>
        <div class="reservation-notes__content seat-popover-content">
          <div class="reservation-notes__notes-details seat-border-color"
             *ngFor="let addOn of addonsForBooking">
            <div class="row-flex">
              <div>
                <span class="seat-secondary-text" *ngIf="showAddOnDetails">{{addOn.AddonCount}} x </span>
                <span class="seat-secondary-text">{{addOn.AddonName}}<span
                    class="seat-secondary-text" style="color:red" *ngIf="addOn.IsMandatory"> *</span></span>
              </div>
              <span *ngIf="showAddOnDetails">{{cs.settings.value.General.OperationCurrency}}{{addOn.TotalAmount || 0}}</span>
            </div>            
            <div class="overbooked-text" *ngIf="addOn.OverBooked">
              {{'Overbooked' | translate}}: {{addOn.OverBooked || 0}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #loaderContainer>
      <img class="loader-container" height="20" alt="" src="assets/images/seat-loader.svg">
    </ng-template>
  </div>