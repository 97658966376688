import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { urlConfig } from '@constants/url-config';
import { HttpService } from '@services/http.service';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  constructor(public hs: HttpService,private httpclient: HttpClient, public appService: AppService) {
  }

  getDashboardData(restaurantids: number[]): any {
    const postUrl = `${urlConfig.switchPropertyDashboardDataUrl}=${this.appService.restaurantId}`;
    return this.hs.post(postUrl, restaurantids);
  }

  applicationVersion():any{
    const url = `${urlConfig.applicationVersion}?propertyId=${this.appService.restaurantId}`;
    return this.hs.get(url);
  }

  downloadReleaseNotes():any{
    const url = `${urlConfig.downloadReleaseNotes}`;
    return this.httpclient.get(url, { responseType: 'blob' })
  }

}
