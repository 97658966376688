import { Component, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { buttonTypes } from '@constants/commonenums';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { JoinedTableDTO } from '@models/RestaurantDTO';
import { FloorplanService } from '@services/floorplan.service';
import { ISubscription, Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-editor-joined-tables',
  templateUrl: './editor-joined-tables.component.html',
  styleUrls: ['./editor-joined-tables.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditorJoinedTablesComponent implements OnInit, OnDestroy {
  joinedTablesArray: JoinedTableDTO[];
  editJoinTable: ButtonValue;
  deleteTableBtn: ButtonValue;
  maxPartySize = 51;
  joinedTableConfig: FieldConfig[];
  dataChangeSubscription: ISubscription;
  communalTableErrorSubscription: ISubscription;
  showCommunalTableError = false;
  selectedIndex = -1;
  tableColumns: string[] = ['standAlone', 'size', 'action'];
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  joinedTableData: MatTableDataSource<JoinedTableDTO>;
  subscriptions: Subscription = new Subscription();

  constructor(public fs: FloorplanService) { }

  ngOnInit() {
    this.joinedTableData = new MatTableDataSource(this.fs.floorPlan.JoinedTables);
    this.joinedTablesArray = this.fs.floorPlan.JoinedTables;
    const partySizeArray = this.fs.calculatePartySize(this.maxPartySize);
    this.editJoinTable = {
      type: buttonTypes.actionPrimarySmall,
      label: 'edit',
      customclass: 'editor-joined__edit',

    };
    this.deleteTableBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: 'delete',
      customclass: 'editor-joined__delete'
    };
    const maxPartySize: number = this.fs._settings.General.MaxPartySize;;
    this.joinedTableConfig = [
      // {
      //   type: 'select',
      //   name: 'minPartySize',
      //   label: 'minSize',
      //   options: partySizeArray,
      //   class: 'new-join__min-size',
      //   showErrorText: true,
      //   appearance: false,
      //   isTranslate: false,
      //   value: partySizeArray[0].id
      // },
      // {
      //   type: 'select',
      //   name: 'maxPartySize',
      //   label: 'maxSize',
      //   options: partySizeArray,
      //   class: 'new-join__max-size',
      //   showErrorText: true,
      //   appearance: false,
      //   isTranslate: false,
      //   value:partySizeArray[0].id
      // }
      {
        type: 'autocomplete',
        name: 'minPartySize',
        label: 'minSize',
        options: partySizeArray,
        class: 'new-join__min-size',
        showErrorText: true,
        value: partySizeArray[0].id,
        partySize: true,
        charLength: maxPartySize.toString().length,
      },
      {
        type: 'autocomplete',
        name: 'maxPartySize',
        label: 'maxSize',
        options: partySizeArray,
        class: 'new-join__max-size',
        showErrorText: true,
        appearance: false,
        isTranslate: false,
        value: partySizeArray[0].id,
        partySize: true,
        charLength: maxPartySize.toString().length,
      }
    ]

  }

  deleteJoinedTable(i) {
    this.fs.deleteJoinedTable$.next(i);
  }
  editJoinedTable(i) {
    this.fs.fromEdit = true;
    this.fs.editJoinedTable$.next(i);
    this.selectedIndex = i;
  }
  removeTable(id, i) {
    this.fs.removeTable$.next({ id: id, index: i });
  }

  ngAfterViewInit() {
    this.fs.joinedTablesForm = this.dynamicForm.form;
    this.dataChangeSubscription = this.fs.dataChanged$.subscribe(val => {
      this.joinedTablesArray = this.fs.floorPlan.JoinedTables;
      // this.joinedTablesArray.forEach(x=>{

      // })
      this.joinedTableData = new MatTableDataSource(this.fs.floorPlan.JoinedTables);
      if (this.selectedIndex >= 0) {
        this.fs.fromEdit = false;
        this.fs.editJoinedTable$.next(this.selectedIndex);
      }
    });
    this.communalTableErrorSubscription = this.fs.throwCommunalTableError$.subscribe(val => {
      if (val) {
        this.showCommunalTableError = true;
      }
      else {
        this.showCommunalTableError = false;
      }
    });
    this.subscriptions.add(this.fs.layoutChange$.subscribe(val => {
      this.joinedTablesArray = this.fs.floorPlan.JoinedTables;
    }));
  }

  ngOnDestroy() {
    if (this.dataChangeSubscription) {
      this.dataChangeSubscription.unsubscribe();
    }
    if (this.communalTableErrorSubscription) {
      this.communalTableErrorSubscription.unsubscribe();
    }
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}

@Pipe({
  name: 'getName'
})
export class GetNamePipe implements PipeTransform {
  constructor(private fs: FloorplanService) {

  }
  transform(id: number): any {
    let table = this.fs.floorPlan.StandaloneTables.filter(x => x.Id == id)[0];
    return table.Name;
  }

}
