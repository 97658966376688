import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Pipe, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { CacheService } from '@app/core/services/cache.service';
import { SmsProvider } from '@app/shared/models/InputContact';
import { OperationResultState } from '@constants/commonenums';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { PageRequest, PartyMessageDTO, PredefinedPartyMessageDTO, SettingsDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import { Subscription, pipe } from 'rxjs';

@Component({
  selector: 'app-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatViewComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  preDefinedMessageDropdown: FieldConfig;
  messageAreaConfig: FieldConfig;
  config: FieldConfig[] = [];
  @Input() messages: PredefinedPartyMessageDTO[];
  @Input() inputString: string;
  @Input() partyInfo: any;
  @Input() historyData: any;
  @Input() overlayDropdown = false;
  @Input() ContactId;
  @Input() preferredPageMethod;
  @Input() bulkPagingRequest;
  @Output() closePopover: EventEmitter<any> = new EventEmitter<any>();
  mail: PartyMessageDTO[];
  messageOptions: any = [];
  chatMessages: any = [];
  AllowBasedOnSmsProvider: any = true;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  showCustomMessageIconColor = '#c8c9ca';
  showCustomMessage = false;
  selected: string;
  subscriptions: Subscription = new Subscription();
  settings:SettingsDTO;
  // @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @Input() disableMessagePopup?: boolean;

  private styleElement: HTMLStyleElement;
  constructor(private ps: PartyService, private cdf: ChangeDetectorRef,private cs:CacheService , public individualGuestMessageFilter : IndividualGuestMessageFilter, private renderer: Renderer2) { }

  ngOnInit() {
    this.mail = this.partyInfo.Messages;
    this.AllowBasedOnSmsProvider = this.cs.settings.value.General.SMSProvider != SmsProvider.Platform_Kakao;
    this.partyInfo.Messages.forEach(msgs => {
      this.chatMessages.push({ id: msgs.Id, value: msgs.Text, IsIncoming: msgs.IsIncoming, CreatedAt: msgs.CreatedAt , ContactId: msgs.ContactId ,IsPagedIndividually: msgs.IsPagedIndividually});
    });
    this.subscriptions.add(this.ps.Parties$.subscribe(parties => {
      if (parties) {
        if (this.ps.selectedParty$.value && this.partyInfo && this.partyInfo.Id == this.ps.selectedParty$.value.Id) {
          this.loadMessage(parties);
        }
      }
    }));
    this.subscriptions.add(this.ps.StandbyParties$.subscribe(parties => {
      if (parties) {
        if (this.ps.selectedParty$.value && this.partyInfo && this.partyInfo.Id == this.ps.selectedParty$.value.Id) {
          this.loadMessage(parties);
        }
      }
    }));
            if (this.messageOptions && this.messageOptions.length > 0) {
      this.preDefinedMessageDropdown = {
        type: 'select',
        name: 'predefinedMsg',
        placeholder: '',
        options: this.messageOptions,
        appearance: true,
        value: this.messageOptions[0].id,
        class: 'chat-box__message-dropdown',
        panelClass: this.overlayDropdown ? 'tnslt-y-60' : '',
        isTooltip: true,
        disabled: this.disableMessagePopup
      };
    } else {
      this.showCustomMessage = this.AllowBasedOnSmsProvider;
    }
    this.messageAreaConfig = {
      type: 'textarea',
      name: 'messageArea',
      showErrorText: true,
      class: 'chat-box__message-box',
      disabled: this.disableMessagePopup,
      appearance: true,
      appearenceValue: 'standard'
    };
    if (this.showCustomMessage) {
      this.config.push(this.messageAreaConfig);
    } else {
      this.config.push(this.preDefinedMessageDropdown);
    }
    this.addPopoverStyle();
  }

  addPopoverStyle(){
    this.styleElement = this.renderer.createElement('style');
    if(this.styleElement){
      this.styleElement.innerHTML = `
        .popover {
          z-index: 1;
        }
      `;
    }
    this.renderer.appendChild(document.head, this.styleElement);
  }

  removePopoverStyle(){
    if (this.styleElement) {
      this.renderer.removeChild(document.head, this.styleElement);
    }
  }

  loadMessage(parties){
    if (parties.find(p => p.Id == this.ps.selectedParty$.value.Id)) {
      this.chatMessages = [];
      let partyDetails = this.ps.selectedParty$.value;
      if (partyDetails.Messages.length > 0) {
        partyDetails.Messages.forEach(msgs => {
          this.chatMessages.push({ id: msgs.Id, value: msgs.Text, IsIncoming: msgs.IsIncoming, CreatedAt: msgs.CreatedAt ,ContactId: msgs.ContactId ,msgs: msgs.IsPagedIndividually});
          this.scrollToMessages();
        });
        this.chatMessages =[...this.chatMessages]
      }
    }
  }
  
  showCustommessage() {
    this.showCustomMessage = !this.showCustomMessage;
    if (this.showCustomMessage) {
      this.selected = this.messageOptions;
      this.config = [];
      this.config.push(this.messageAreaConfig);
    } else {
      this.config = [];
      this.config.push(this.preDefinedMessageDropdown);
    }
  }
  ngAfterViewInit() {
    if (this.inputString === 'messages') {
      this.scrollToMessages();
    }
    this.cdf.detectChanges();
  }

  scrollToMessages() {
    const messageView = document.getElementById('messages-view');
    if (messageView) {
      messageView.scrollTop = messageView.scrollHeight - messageView.clientHeight;
    }
  }

  sendMessage() {
    if(!this.disableMessagePopup){
    let text = '';
      let code = '';
    if (this.showCustomMessage) {
      text = this.dynamicForm.form.controls.messageArea.value.trim();
    } else {
      const selectedId = this.dynamicForm.form.controls.predefinedMsg.value;
      const selectedOption = this.messageOptions.filter((message) => message.id === selectedId);
      if (selectedOption.length > 0) {
        text = selectedOption[0].value;
        code = this.messages.find(msg=>msg.Id == selectedId)?.Text?.Code || null;
      }
    }
    if (!text) {
      return;
    }
      if (this.bulkPagingRequest) {
        let request = {} as PageRequest;
        request.BookingId = this.partyInfo.Id;
        request.Message = text;
        request.templateCode = code;
        request.GuestInfo = this.bulkPagingRequest?.filter((msg) => msg.isValid);

        this.subscriptions.add(this.ps.bulkPartyMessage(request).subscribe((data) => {
          if (data.State === OperationResultState.Success) {

          }
        }))
      } else {
        if(!this.preferredPageMethod) {
          this.preferredPageMethod =  this.partyInfo?.PageMethod || this.partyInfo?.Contact?.PreferredPageMethod;
        }
        this.subscriptions.add(this.ps.postPartyMessage(this.partyInfo.Id, this.preferredPageMethod, text, code, this.ContactId || this.partyInfo?.Contact?.Id).subscribe((data) => {
          if (data.State === OperationResultState.Success) {
            if (this.showCustomMessage) {
              this.dynamicForm.form.controls.messageArea.setValue('');
            } else {
              this.dynamicForm.form.controls.predefinedMsg.setValue('');
            }
            // this.components.last.form.controls.messageArea.setValue('');
            this.ps.messageSent$.next(text);
          }
        }));
      }
  }
  }

  ngOnChanges() {
    if (this.inputString === 'messages') {
      this.mail = this.partyInfo.Messages;
    } else if (this.inputString === 'history') {
      this.historyData = this.historyData;
    }
    this.messageOptions = [];
    this.messages.forEach(msg => {
      if (this.partyInfo && this.partyInfo.Messages && this.partyInfo.Messages.length > 0 && msg.AppliesToResponses) {
        this.messageOptions.push({ id: msg.Id, value: msg.Text?.Message });
      }
      if (this.partyInfo && this.partyInfo.Messages && ( this.partyInfo.Messages.length === 0 || this.individualGuestMessageFilter.transform(this.partyInfo.Messages , this.ContactId || this.partyInfo?.Contact?.Id)?.length == 0) && msg.AppliesToInitial) {
        this.messageOptions.push({ id: msg.Id, value: msg.Text?.Message });
      }
    });
    if (this.preDefinedMessageDropdown) {
      this.preDefinedMessageDropdown.options = this.messageOptions;
    }
    // if(this.components && this.components.length > 0){
    //   this.components.first.form.valueChanges.subscribe(val =>{
    //     let index = this.messages.filter(x=> x.Id == val.messages)[0];
    //     this.components.last.form.controls.messageArea.setValue(index.Text);
    //   })
    // }
  }

  closeChat() {
    this.closePopover.emit();
  }

  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    this.removePopoverStyle();
  }
}


@Pipe({
  name: 'IndividualGuestMessage'
})
export class IndividualGuestMessageFilter {

  transform(Messages, contactId: number = null): any {
    if (contactId) {
      return Messages?.filter(Message =>  Message.ContactId && Message.ContactId == contactId) || [];
    } else {
      return  Messages?.filter(Message => !Message.ContactId ) || [];
    }
  }
}