import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActionMode, AlertAction, AlertType, ButtonType} from 'src/app/common/enums/shared-enums';
import { TableHeaderOptions, TableOptions, TableSearchHeader } from '../../Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { VIPTypeBusiness } from './vip-type.business';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { CreateVipTypeComponent } from './create-vip-type/create-vip-type.component';
import { MatDialog } from '@angular/material/dialog';
import { UserAccessBusiness } from '../../dataservices/authentication/useraccess.business';
import { UserAccessBreakPoints } from '../../constants/useraccess.constants';
import { CommonDataAwaiters } from '../../shared/events/awaiters/common.data.awaiters';

@Component({
  selector: 'app-vip-type',
  templateUrl: './vip-type.component.html',
  styleUrls: ['./vip-type.component.scss'],
  providers: [VIPTypeBusiness],
  encapsulation: ViewEncapsulation.None
})
export class VipTypeComponent implements OnInit {
  searchHeaderOption: TableSearchHeader;
  captions: any;
  options: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent = [];
  disableOption = true;
  searchText = '';
  originalData: [];
  IsEditModeEnabledFlag = false;
  isViewOnly = true;
  defaultSortingKey = 'listOrder';
  deleteMsgKey = 'name';
  includeInactive = false;
  tableoptions;
  onEditDisableFunction = true;

  constructor(private localization: Localization, private business: VIPTypeBusiness,
    private utils: CommonUtilities,
    private dialog: MatDialog,
    private _userAccessBusiness: UserAccessBusiness)
  {  }

  ngOnInit() {
    this.captions = this.localization.captions;
    this.generateTableData();
  }

  async generateTableData() {
    this.onEditDisableFunction = true;
    await this.ValidateBreakPoint();
    await this.getTableContent(this.includeInactive);
    let arrDataHeader = [
      { 'title': this.captions.VIPType_grid_hdr_Code, 'jsonkey': 'code', 'searchable': true, 'sortable': true },
      { 'title': this.captions.VIPType_grid_hdr_Name, 'jsonkey': 'name', 'searchable': true, 'sortable': true },
      { 'title': this.captions.VIPType_grid_hdr_ListOrder, 'jsonkey': 'listOrder', 'searchable': false, 'sortable': true },
      { 'title': this.captions.VIPType_grid_hdr_IsActive, 'jsonkey': 'isActive', 'searchable': false, 'sortable': true, "type": "toggle" },
    ]
    this.tableoptions = [
      {
        TableHdrData: arrDataHeader,
        TablebodyData: this.tableContent,
        pagination: false,
        sortable: true,
        editable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.lbl_searchVIPTypePlaceholder,
        EnableActions: true,
        SelectRows: false,
        Searchable: true,
        EditMoreOption: false,
        Sortable: 'listOrder',
        TableId: '',
        disableDelete: false,
        SelectRow: true,
        SelectOnlyRow: true,
        dropdownOptions: '',
        waitlistIcon: false,
        TableDraggable: true,
        sticky: false,
        DoneCancel: false,
        IsViewOnly: this.isViewOnly,
        disableEditButton: this.isViewOnly,
        isCopyEnabled : true,
        ServiceId : 'viptype'
      }
    ];
    // this.setTableOptions();
  }

  async getTableContent(includeInActive){
    await this.business.getTableContent(includeInActive).then( 
      x =>  this.tableContent = x
    );
  }

  async ValidateBreakPoint() {
    await this._userAccessBusiness.getUserAccess(await this.business.getVipTypeBreakpoint()).then(
      x => this.isViewOnly = x.isViewOnly
    );
  }

  openDialog() {
    this.dialog.open(CreateVipTypeComponent, {  
      width: '40%',
      maxWidth: "1000px;",
      height: "575px",
      hasBackdrop: true,
      disableClose:true,
      panelClass: 'ag_dialog--lg',
      data: {
        header: this.captions.lbl_createVIPTypeTitle,
        mode: ActionMode.create
      }
    }).afterClosed().subscribe(result => {
      this.IsEditModeEnabledFlag = false;
      this.generateTableData();
    });
  }

  async delete(id: any)
  {
    await this.business.deleteVipType(id).catch(
      x => this.utils.showError(this.localization.getError(x.error.errorCode))
    );
    this.generateTableData();
  }
  async deleteVipType(data) {
    let needConfirmation = await CommonDataAwaiters.GetConfirmationSetting();
    if(needConfirmation){
      this.utils.showAlert(this.captions.lbl_deletemsgsetup, AlertType.Warning, ButtonType.YesNo, async (res) => {
        if (res == AlertAction.YES) {
          await this.delete(data[0].id);
        }
      });
    }
    else{
      await this.delete(data[0].id);
    }
  }


  EditRecords(rowData){
    this.dialog.open(CreateVipTypeComponent, {
      width: '40%',
      maxWidth: "1000px;",
      height: "575px",
      hasBackdrop: true,
      panelClass: 'ag_dialog--lg',
      data: {
        header: this.captions.lbl_editVIPTypeTitle,
        data: rowData[0],
        mode: ActionMode.update,
        isViewOnly : this.isViewOnly
      }
    }).afterClosed().subscribe(result => {
      this.IsEditModeEnabledFlag = false;
      this.generateTableData();
    });
  }

  copyEvent(rowData){
    this.dialog.open(CreateVipTypeComponent, {
      width: '40%',
      maxWidth: "1000px;",
      height: "575px",
      hasBackdrop: true,
      panelClass: 'ag_dialog--lg',
      data: {
        header: this.captions.lbl_createVIPTypeTitle,
        data: rowData,
        mode: ActionMode.copy,
      }
    }).afterClosed().subscribe(result => {
      this.generateTableData();
    });
  }

  CancelEvent(event) {
    this.IsEditModeEnabledFlag = false;
    this.generateTableData();
  }


  async DoneEvent(event) {
    this.IsEditModeEnabledFlag = false;
    await this.business.updateVipType(event, event.id).catch(
     x => this.utils.showError(this.localization.getError(x.error.errorCode))
    );
    this.generateTableData();
  }

  searchChange(e) {
    this.searchText = e;
  }

  inactiveSliderChange(event){
    // this.IsEditModeEnabledFlag = true;
  }

  onToggleChange(e){
    this.includeInactive = e[0];
    this.generateTableData();
  }

  async dragDrop(e){
    await this.business.dragDrop(e[0], e[1] , this.includeInactive);
    this.generateTableData()
  }
}
