<section class="SSS">
  <form [formGroup]="returnTransitionDetails" class="shop-payment-summary order-summary" #paymentmethod>
    <ng-scrollbar>
      <div class="return-reason" [ngClass]="{'prevent-action': _ams.paymentProcessing}">
        <div>
          <div [class.disabled]="commentDisable" *ngIf="!PropertyInfo.UseRetailInterface">
            <mat-form-field [floatLabel]="floatLabel">
              <input matInput type="text" formControlName="receiptComment" [attr.automationId]="'Txt_ServiceItemSummary_receiptComment'"
                placeholder="{{localization.captions.shop.ReceiptComment}}" maxlength="100">
            </mat-form-field>
          </div>
        </div>        
      </div>
      <div class="gift-receipt" *ngIf="isGiftReceiptEnabled" [ngClass]="{'prevent-action': _ams.paymentProcessing}">
        <label class="LW14 payment-deatils-label mb-2">{{localization.captions.shop.PrintGiftReceipt}}</label>
        <app-retail-toggle-switch formControlName="printGiftReceipt" class="ml-1"
          [attr.automationId]="'Tog_orderSummary_PrintGiftReceipt'" (changeToggleEvent)="giftReceiptToggleChange($event)"></app-retail-toggle-switch>
      </div>
      <div class="Payment-details clearfix LW16 mb-4">
        <label class="LWB16">{{localization.captions.shop.PaymentDetails}}</label>
        <div class="clearfix pt-4"> <span class="float-left">{{localization.captions.shop.OriginalAmount}}
            ({{localization.currencySymbol}}) </span> <span
            class="float-right">{{localization.localizeCurrency(OriginalAmount.toString(), false) }}</span> </div>
        <div class="clearfix pt-3"> <span class="float-left">{{localization.captions.shop.RetainedAmount}}
            ({{localization.currencySymbol}}) </span> <span
            class="float-right">{{localization.localizeCurrency(this._shopservice.retainedItemTotal.toString(),
            false)}}</span>
        </div>
        <div class="clearfix pt-3"> <span class="float-left">{{localization.captions.shop.ReturnedAmount}}
            ({{localization.currencySymbol}}) </span> <span
            class="float-right">({{localization.localizeCurrency(this._shopservice.returnItemTotal.toString(),
            false)}})</span>
        </div>
        <div *ngIf = "_shopservice.selectedExchangeRetailProducts.length > 0" class="clearfix pt-3"> <span class="float-left">{{localization.captions.shop.ExchangedAmount}}
            ({{localization.currencySymbol}}) </span> <span
            class="float-right">{{localization.localizeCurrency(this._shopservice.exchangedItemTotal.toString(),
            false)}}</span>
        </div>

      </div>
      <!-- Refund to Original Settlement section-->
      <div *ngIf=" !PropertyInfo.UseRetailInterface && IsEnableRefundToOriginalSettlement"
        [class.disabled]="IsRefundStarted" class="complete-return-cont">
        <div class="form-label-normal return-original-lbl d-block">
          {{localization.captions.shop.RefundToOriginalSettlement}}</div>
        <app-retail-toggle-switch [attr.automationId]="'Tog_ServiceItemSummary_refund'" [formControl]="returnTransitionDetails.controls['refundToOriginal']"
          formControlName="refundToOriginal" (click)=OnRefundToOriginalToggle($event)></app-retail-toggle-switch>
      </div>
      <!--Payment method section-->
      <section class="example-section radio-class spa-row-payment-method payment-method"
        *ngIf="ShowSettlementOption && false">
        <span class="LW12 mb-2 d-block">{{localization.captions.shop.FullOrMultiplePayment}}</span>
        <mat-radio-group formControlName="paymentType" [disabled]="IsRefundToOriginalSettlement"
          class="golf__radio d-flex justify-content-between" [attr.automationId]="'Rdo_ServiceItemSummary_paymentType'">
          <mat-radio-button class="example-margin pr-2 golf--form-radio-button w-50" [value]="i"
            (change)="PaymentOption(paymentMethod)" *ngFor="let paymentMethod of paymentMethodType; let i = index"
            [disabled]="paymentMethod.disabled">{{paymentMethod.name}}</mat-radio-button>
        </mat-radio-group>
      </section>
      <!---Payment Row Section-->
      <div class="payment-row spa-row-payment-method">
        <div class="full-payment">
          <label class="LW12 label-color fullpayment-label">{{ AmountDueMsg }}</label>
          <span class="LWB14 blckclr mt-2 value-color">{{_sbs.FormatPrice(_shopservice.finalAmount)}}</span>
        </div>
        <ng-container *ngIf="!PropertyInfo.UseRetailInterface">
          <div class="full-payment">
            <label class="LW12 label-color fullpayment-label">{{ AmountPaidMsg }}</label>
            <span class="LWB14 mt-2 value-color">{{ _sbs.FormatPrice(_sbs.SettledAmt) }}</span>
          </div>
          <div class="full-payment">
            <label class="LW12 label-color fullpayment-label">{{localization.captions.shop.RemainingDue}}</label>
            <span class="LWB14 mt-2 value-color">{{ _sbs.FormatPrice(_sbs.remainingAmount) }}</span>
          </div>
        </ng-container>
      </div>
      <div class="shop-payment-type" [class.disabled]="!_sbs.ShowPaymentMethods || IsRefundToOriginalSettlement"
        *ngIf="!PropertyInfo.UseRetailInterface && _sbs.ShowPaymentMethods">
        <label class="shop-payment-label spa-row-payment-method LW12">{{localization.captions.shop.Paymentmethods}}
          <span class="label-bold"
            *ngIf="_sbs.isComponentAccessLinkingFilterApplied">({{localization.captions.shop.ComponentLinkAppliedBanner}})</span>
        </label>
        <div class="shop-payment-method" [class.disabled]="_sbs.isAPICallInProgress">
          <div #buttonView class="show-container">
            <div class="btn-div d-flex flex-wrap w-100">
              <!-- // Activities - retail integration changes
                     type.isReserveZeroTender && _sbs.remainingAmount != 0 added
                  -->
              <div *ngFor="let type of _sbs.paymentMethods | slice:0:4"
                [class.disabled]=" (_sbs.remainingAmount == 0 && !type.allowZeroPosting) || type.isDisabled || (type.isReserveZeroTender && _sbs.remainingAmount != 0)"
                class="payment-icon" (click)="selectpaymentMethod(type)">
                <i aria-hidden="true" class="payment-type icon-{{type.type}}"
                  [ngClass]="{'border-color': checkCurrentPaymentSelection(type), 'text-color': checkCurrentPaymentSelection(type)}"></i>
                <label class="LW14 value-color"
                  [ngClass]="{'font-weight-bold': checkCurrentPaymentSelection(type)}">{{type.paymentMethod}}</label>
              </div>
            </div>
            <div class="btn-div d-flex flex-wrap w-100"
              [ngClass]="_sbs.showAllPaymentMethods ? 'show-container_pc' : 'hide-container'">
              <!-- // Activities - retail integration changes
                     type.isReserveZeroTender && _sbs.remainingAmount != 0 added
                  -->
              <div *ngFor="let type of _sbs.paymentMethods | slice:4:_sbs.paymentMethods.length"
                [class.disabled]=" (_sbs.remainingAmount == 0 && !type.allowZeroPosting) || type.isDisabled || (type.isReserveZeroTender && _sbs.remainingAmount != 0)"
                class="payment-icon" (click)="selectpaymentMethod(type)">
                <i aria-hidden="true" class="payment-type icon-{{type.type}}"
                  [ngClass]="{'border-color': checkCurrentPaymentSelection(type), 'text-color': checkCurrentPaymentSelection(type)}"></i>
                <label class="LW14 value-color"
                  [ngClass]="{'font-weight-bold': checkCurrentPaymentSelection(type)}">{{type.paymentMethod}}</label>
              </div>
            </div>
          </div>
          <!-- CREDIT CARD ISSUER TYPE SELECTION RADIO GROUP SECTION -->
          <div *ngIf="ShowSurchargeSelection">
            <mat-radio-group formControlName="creditcardType" [attr.automationId]="'Rdo_ServiceItemSummary_creditCardType'">
              <div class="issuer-type-select-container">
                <div *ngFor="let cardIssuer of cardIssuerTypes" (click)="selectcardIssuer(cardIssuer)" class="issuerType"
                  [class.selected]="cardIssuer.checked">
                  <div class="issuer-icon-group">
                    <mat-radio-button [checked]="cardIssuer.checked" [value]="cardIssuer.type"></mat-radio-button>
                    <app-retail-card-type [type]="cardIssuer?.type"></app-retail-card-type>
                    <div>{{cardIssuer?.name}}</div>
                  </div>
                  <div *ngIf="cardIssuer?.isPercentage">{{cardIssuer?.surchargeValue}}%</div>
                  <div *ngIf="!cardIssuer?.isPercentage">{{cardIssuer?.surchargeValue | Currency}}</div>
                </div>
              </div>
            </mat-radio-group>
          </div>
        </div>

        <div class="toggle-section" [class.disabled]="!(_sbs.paymentMethods.length > 4)">
          <button [attr.automationId]="'Btn_ServiceItemSummary_paymentMethod'" class="toggle-arrow body-bgcolor" (click)="_sbs.showAllPaymentMethods = !_sbs.showAllPaymentMethods">
            <i aria-hidden="true" class="pointercursor icon-filled_up_arrow whitecolor"
              [ngClass]="!_sbs.showAllPaymentMethods ? 'default-arrow-position' : ''"></i>
          </button>
        </div>
      </div>
      <div class="shop-amount-tendered spa-row-payment-method" [class.surcharge-applied]="isSurchargeApplied"
        *ngIf="_sbs.partialPayment && _sbs.ShowTenderField && !IsRefundToOriginalSettlement">
        <mat-form-field class="w-100" [floatLabel]="floatLabel">
          <input [attr.automationId]="'Txt_ServiceItemSummary_amounttendered'" matInput placeholder="{{localization.captions.shop.AmountTendered}}" #amounttenderedField
            cAllowNegative="{{_sbs.IsRefundFlow ? 'true':'false'}}" RetailCurrencyFormatter
            formControlName="amounttendered" (keyup)="returnTenderedAmount()" required>
          <mat-error *ngIf="returnTransitionDetails['controls'].amounttendered.hasError('max')">
            {{localization.captions.shop.AmountTenderValidationMsg}}</mat-error>
          <mat-error *ngIf="returnTransitionDetails['controls'].amounttendered.hasError('required')">
            {{MissingAmountTenderMsg}}</mat-error>
          <mat-error *ngIf="returnTransitionDetails['controls'].amounttendered.hasError('cmsLimitExceeded')">
            {{_sbs.ShopCaptions.CMS.CMSRefundLimitReached}}</mat-error>
        </mat-form-field>
        <div class="surcharge-applied-indicator" *ngIf="isSurchargeApplied">
          <span #SCpopOverTarget>
            <i aria-hidden="true" class="icon-information i-info surcharge-info" id="surcharge-info"
              [popover]="surchargePopover" [popoverOnHover]="false" (onShown)="showSurchargePopover($event)"></i>
          </span>
          {{_sbs.ShopCaptions.lbl_Surcharge}} : {{surchargeAmount | Currency}}
        </div>
        <div class="surcharge-applied-indicator" *ngIf="TaxExemptByPaymentApplied > 0 && !_shopservice.isTaxExempted">
          <span #SCpopOverTarget *ngIf="!IsFolioPaymentMethod">
            <i aria-hidden="true" class="icon-information i-info surcharge-info" id="surcharge-info" [attr.automationId]="'Icn_payment_TaxExemptByPaymentApplied'"
              [popoverOnHover]="false"></i>
          </span>
          {{taxExemptCaption}} : {{TaxExemptByPaymentApplied | Currency}}
        </div>
        <div class="surcharge-applied-indicator" *ngIf="ExemptedDiscount > 0">
          <span #SCpopOverTarget *ngIf="!IsFolioPaymentMethod">
            <i aria-hidden="true" class="icon-information i-info surcharge-info" id="surcharge-info" [attr.automationId]="'Icn_payment_ExemptedDiscount'"
              [popoverOnHover]="false"></i>
          </span>
          {{_sbs.ShopCaptions.lbl_DiscountExemptByPaymentMethod}} : {{ExemptedDiscount | Currency}}
        </div>
      </div>

      <div class="shop-amount-tendered spa-row-payment-method" id="payComment"
        *ngIf="isEnablePaymentComment && !OriginalSettlementToggleEnabled">
        <mat-form-field class="w-100 txt-area-height" [floatLabel]="floatLabel">
          <textarea [required]="isPaymentCommentRequired" matInput [attr.automationId]="'Ta_ServiceItemSummary_comment'"
            placeholder="{{localization.captions.shop.PaymentComments}}" maxlength="30" formControlName="paymentComment"
            (blur)="_sbs.AutoScrollToBottom()"></textarea>
        </mat-form-field>
      </div>
      <!---credit card section-->
      <section class="select-device"
        *ngIf="!PropertyInfo.UseRetailInterface && (SelectedPaymentMethodEquals(PayMethodConst.CreditCard) || SelectedPaymentMethodEquals(PayMethodConst.IDTECH) || SelectedPaymentMethodEquals(PayMethodConst.CardOnFile))">
        <div class="device-type"
          *ngIf="SelectedPaymentMethodEquals(PayMethodConst.CreditCard) || SelectedPaymentMethodEquals(PayMethodConst.IDTECH) && !_sbs.cardDetailsBool">

          <mat-form-field class="terminal-select" *ngIf="_sbs.IsFolioPaymentMethod" [floatLabel]="floatLabel">
            <mat-select [attr.automationId]="'Dd_ServiceItemSummary_selectedTerminal'" placeholder="{{_sbs.ShopCaptions.SelectTerminal}}" formControlName="selectedTerminal" required>
              <mat-option *ngFor="let terminal of _sbs.terminalList" [value]="terminal.terminalId"
                (click)="changeTerminal(terminal)">
                {{ terminal.terminalId }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [floatLabel]="floatLabel">
            <mat-select [attr.automationId]="'Dd_ServiceItemSummary_selectedDevice'" placeholder="{{localization.captions.shop.SelectDevice}}" formControlName="selectedDevice"
              required>
              <mat-option *ngFor="let device of _sbs.deviceType" [value]="device.name" (click)="selectDevice(device)">
                {{ device.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="_sbs.showPreferredCardEntryBanner">
            <div class="preferred-selection-link" [attr.automationId]="'Lbl_ServiceItemSummary_clickHere'">{{_sbs.preferredInputSelectedBanner}}
              <span [class.link]="IsProceedBtnEnabled" [class.disabled]="!IsProceedBtnEnabled"
                (click)="proceedForAdyenGatewayPayment(true)">
                {{_sbs.ShopCaptions.clickHere}}</span>
              <span> {{_sbs.ShopCaptions.ToChange}}</span>
            </div>
          </div>
        </div>
        <div class="zip-code" *ngIf="_sbs.GatewayConfiguration?.isAVSActive">
          <mat-form-field class="zip-code-mat-field" [floatLabel]="floatLabel">
            <input matInput placeholder="{{localization.captions.shop.Zipcode}}" required formControlName="zipcode"
              [maxlength]="10" [attr.automationId]="'Txt_ServiceItemSummary_zipCode'">
            <mat-error>{{localization.captions.shop.ZipcodeRequired}}</mat-error>
          </mat-form-field>
        </div>
        <div class="card-details" *ngIf="_sbs.cardDetailsBool">
          <div class="LW12">{{localization.captions.shop.SelectedDevice}}</div>
          <label class="device-name blckclr">{{_sbs.deviceName}}</label>
          <mat-form-field class="d-block" [floatLabel]="floatLabel">
            <input matInput placeholder="{{localization.captions.shop.CardType}}" formControlName="cardtype"
              [disabled]="true" class="blckclr" [attr.automationId]="'Txt_ServiceItemSummary_cardType'">
          </mat-form-field>
          <mat-form-field class="d-block" [floatLabel]="floatLabel">
            <input matInput placeholder="{{localization.captions.shop.shopCardNumber}}" formControlName="cardnumber"
              [disabled]="true" class="blckclr" [attr.automationId]="'Txt_ServiceItemSummary_cardNumber'">
          </mat-form-field>
          <mat-form-field class="d-block" [floatLabel]="floatLabel">
            <input matInput placeholder="{{localization.captions.shop.TransactionAmount}}"
              formControlName="transactionnumber" [disabled]="true" class="blckclr" [attr.automationId]="'Txt_ServiceItemSummary_transactionNumber'">
          </mat-form-field>
        </div>
      </section>
      <!--Room charge section-->
      <section class="room-charge" *ngIf="(_sbs.IsRoomOrGroupCharge || _sbs.IsHotelCompPayment) && !PropertyInfo.UseRetailInterface">
        <div class="assign-rrom">
          <mat-radio-group formControlName="activityRoomSelection" class="golf__radio" [attr.automationId]="'Rdo_ServiceItemSummary_roomSelection'">
            <div class="room-details mb-2" *ngFor="let rooms of _sbs.roomDetailsArray">
              <div class="room-row LW14 radio-class">
                <label class="blckclr">{{localization.captions.shop.RoomChargeNumber}} # {{rooms.roomNo}}</label>
                <mat-radio-button [value]="rooms.roomNo" (change)="SelectRoom(rooms)">
                </mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </div>
        <app-guest-room-lookup (GuestRoomSelected)="SelectGuestRoom($event)" 
          [payeeInfo]="_shopservice?.selectedclient"
          [isReturnScreen]="true" 
          [profitCenter]="_sbs.profitCenter"
          [paymentMethodId]="_sbs.roomChargePostingPayMethodId" 
          [OriginalTenderId]="_sbs.selectedPaymentMethod"
          [AuthAmount]="this.AmountEntered"
          [GuestRoomNo]="_sbs.SelectedRoomNo" 
          [isDefaultLookUpByName]="_sbs.isDefaultRoomLookUpByName" 
          [selectRoomFromResult]="_sbs.selectRoomFromResult"
          [selectedPaymentTypeId]="_sbs.selectedpayment?.paymentTypeId"
          [SelectedPaymentMethod]="_sbs.selectedpayment"
          (IsCheckZoomEnabled)="_sbs.SetCheckZoomFlag($event)">
        </app-guest-room-lookup>
      </section>
      <!----Settlement History-->
      <section *ngIf=" _sbs.ShowSettlemtHist && _sbs.SettlementHistory.length > 0 && !PropertyInfo.UseRetailInterface"
        class="settlment-hist spa-row-payment-method">
        <label class="LW14">{{localization.captions.shop.SettlementHistory}}</label>
        <div *ngFor="let settlement of _sbs.SettlementHistory" class="LW14">
          <ng-container *ngIf="!settlement.isReversed">
            <div class="settlement-row">
              <div *ngIf="settlement.paymentReferenceComment" class="cash-label">{{settlement.paymentMethod}} |
                {{settlement.paymentReferenceComment}}</div>
              <div *ngIf="!settlement.paymentReferenceComment" class="cash-label">{{settlement.paymentMethod}}</div>

              <div>{{ _sbs.FormatPrice(settlement.amount)}}</div>
              <div [attr.automationId]="'Icn_ServiceItemSummary_reversePayment'" class="icon-Delete cursor" *ngIf="AllowPaymentReversal" (click)="ReversePayment(settlement)"></div>
            </div>
            <div
              *ngIf="settlement.paymentMethodId == PayMethodConst.CreditCard || settlement.paymentMethodId == PayMethodConst.CardOnFile || settlement.paymentMethodId == PayMethodConst.IDTECH"
              class="card-details-container">
              <mat-form-field class="d-block" [floatLabel]="floatLabel">
                <input matInput placeholder="{{localization.captions.shop.CardType}}" [value]="settlement.issuerType"
                  [disabled]="true" class="blckclr" [attr.automationId]="'Txt_ServiceItemSummary_shopcardType'">
              </mat-form-field>
              <mat-form-field class="d-block" [floatLabel]="floatLabel">
                <input matInput placeholder="{{localization.captions.shop.CardNumber}}" [value]="settlement.cardNumber"
                  [disabled]="true" class="blckclr" [attr.automationId]="'Txt_ServiceItemSummary_cardNo'">
              </mat-form-field>
            </div>
            <div *ngIf="utils.GetOriginalTenderId(settlement.paymentMethodId, settlement?.parentTenderId??0) == PayMethodConst.Cash && settlement.cashPaymenHandle">
              <div class="settlement-row">
                <div class="cash-label cashPaymentDue">{{localization.captions.shop.lbl_payment_received}}</div>
                <div>{{settlement.cashPaymenHandle.amtReceived | Currency}}</div>
              </div>
              <div class="settlement-row">
                <div class="cash-label cashPaymentDue"><b>{{localization.captions.shop.remainingChange}}</b></div>
                <div><b>{{settlement.cashPaymenHandle.amtBalanceDue | Currency}}</b></div>
              </div>
            </div>
          </ng-container>
        </div>  
      </section>
      <section class="cms-payment"
        *ngIf="(SelectedPaymentMethodEquals(PayMethodConst.CompRedemption) || SelectedPaymentMethodEquals(PayMethodConst.OfferRedemption)) && !PropertyInfo.UseRetailInterface && !_sbs.IsRefundFlow">
        <form [formGroup]="CMSForm">          
          <div class="rain-check-search">
            <mat-form-field class="rc-searchbox" [floatLabel]="floatLabelNever" [ngStyle]="{'width.ch': width}">
              <input type="text" [placeholder]="_sbs.ShopCaptions.CMS.searchPlaceHolder" matInput
                formControlName="cmsSearch" maxlength="25" autocomplete="off" (input)="resetPlayerPin()"
                (keydown.enter)="searchCMSPlayer(); $event.preventDefault()" [attr.automationId]="'Txt_ServiceItemSummary_cmsSearch'">
            </mat-form-field>
            <div class="icon-section">
              <i aria-hidden="true" class="icon-search icon-Search" (click)="searchCMSPlayer()"></i>
            </div>
          </div>
          <div *ngIf="IsCMSRequirePin">
            <mat-form-field class="cms-pin-field" [floatLabel]="floatLabelNever">
              <input [type]="hidePassword ? 'text' : 'password'" minlength="4" numMaxlength
                [placeholder]="_sbs.ShopCaptions.CMS.CmsPin" matInput formControlName="cmsPin" maxlength="10"
                (keydown.enter)="focusCMSSearch(); $event.preventDefault()" autocomplete="off" [attr.automationId]="'Txt_ServiceItemSummary_cmsPin'">
              <!-- <i matSuffix class="IC18" [ngClass]="hidePassword ? 'icon-password-show' : 'icon-password-hide '"
                (click)="hidePassword=!hidePassword"></i> -->
              <mat-error>
                {{_sbs.ShopCaptions.CMS.MissingCMSPinError}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="cms-search-result-cont" *ngIf="_sbs.selectedCMSPlayer.PatronId != ''">
            <div class="d-flex client-info-row">
              <div class="info-lbl">{{_sbs.ShopCaptions.CMS.GuestName}}:</div>
              <div>{{_sbs.selectedCMSPlayer.PlayerName}}</div>
            </div>
            <div class="d-flex client-info-row">
              <div class="info-lbl">{{_sbs.ShopCaptions.CMS.PatronId}}:</div>
              <div>{{_sbs.selectedCMSPlayer.PatronId}}</div>
            </div>
            <div
              *ngIf="SelectedPaymentMethodEquals(PayMethodConst.CompRedemption) && _sbs.cmsPlayerHandles.length > 0">
              <div class="balance-hdr">{{_sbs.ShopCaptions.CMS.Balance}}</div>
              <div>
                <mat-radio-group formControlName="cmsPaymentSelection" [attr.automationId]="'Rdo_ServiceItemSummary_cmsPayment'">
                  <div class="payment-type-row d-flex" *ngFor="let playerInfo of _sbs.cmsPlayerHandles">
                    <mat-radio-button (click)="selectCMSPaymentType(playerInfo,$event)" [value]="playerInfo.type">
                    </mat-radio-button>
                    <div class="d-flex cms-player-type">
                      <div class="flx-grw">{{_sbs.GetCMSHandleName(playerInfo.type)}}</div>
                      <div>{{playerInfo.balance | Currency}}</div>
                    </div>
                  </div>
                </mat-radio-group>
              </div>
            </div>
            <div *ngIf="SelectedPaymentMethodEquals(PayMethodConst.OfferRedemption)">
              <ng-container *ngIf="_sbs.Vouchers.length > 0">
                <div class="balance-hdr">{{_sbs.ShopCaptions.CMS.OffersAndVoucher}}</div>
                <div>
                  <mat-radio-group formControlName="voucherSelection" [attr.automationId]="'Rdo_ServiceItemSummary_voucherSelection'">
                    <div class="voucher-cont" *ngFor="let voucher of _sbs.Vouchers">
                      <mat-radio-button (click)="selectVoucher(voucher)" [value]="voucher.voucherId">
                      </mat-radio-button>
                      <div class="d-flex voucher-details">
                        <div class="flx-grw">
                          <div>{{voucher.name}}</div>
                          <div>({{voucher.voucherId}})</div>
                        </div>
                        <div>{{voucher.voucherAmount | Currency}}</div>
                      </div>
                    </div>
                  </mat-radio-group>
                </div>
              </ng-container>
              <ng-container *ngIf="_sbs.Vouchers.length == 0">
                <div class="no-offer-msg">{{_sbs.ShopCaptions.CMS.NoOffersAvailableMsg}}</div>
              </ng-container>
            </div>
          </div>
        </form>
      </section>
      <section class="gift-card-selection-section" *ngIf="_sbs.isGiftCardSelected && _sbs.remainingAmount != 0">
        <app-gift-card-payment-section [tenderId]="tenderId" [tender]="_sbs.selectedpayment" (selectGiftCard)="SelectGiftCard($event)"
          [isRefund]="_sbs.IsRefundFlow">
        </app-gift-card-payment-section>
      </section>

      <section class="shop-wallet-section">
        <div class="shop-amount-tendered" *ngIf="SelectedPaymentMethodEquals(PayMethodConst.Wallet) && _sbs.canShowWalletInput">
          <mat-form-field [floatLabel]="floatLabel">
            <input matInput #walletInputField minlength="4" maxlength="256" [attr.automationId]="'Txt_ServiceItemSummary_walletinput'"
                [placeholder]="_sbs.ShopCaptions.WalletNumber" formControlName="walletInput" 
                (keydown.enter)="focusWalletInput(); $event.preventDefault()" autocomplete="off" required>
              <mat-error *ngIf="returnTransitionDetails['controls'].walletInput.hasError('required')">
                {{localization.captions.shop.MissingWalletCardNumber}}</mat-error>
          </mat-form-field>
        </div>
      </section>

      <section *ngIf="SelectedPaymentMethodEquals(PayMethodConst.ARAcctPost)">
        <app-account-lookup [lookupInput]="accountLookupComponentInput" (AcctSelected)="SelectAccount($event)">
        </app-account-lookup>
      </section>
      <section
        *ngIf="SelectedPaymentMethodEquals(PayMethodConst.CompSlipRedemption) && !PropertyInfo.UseRetailInterface && !_sbs.IsRefundFlow">
        <app-comp-slip-lookup [patronId]="_shopservice?.selectedclient?.patronId"
          (VoucherSelected)="selectCompSlip($event)">
        </app-comp-slip-lookup>
      </section>
      <section *ngIf="SelectedPaymentMethodEquals(PayMethodConst.NonIntegratedCreditCard)">
        <div class="button-row text-center pt-3">
          <app-retail-capture-card [PaymentReferenceID]="nonIntegratedPaytransId" [showDeviceCapture]="false"
            [showIframeDetails]="true" [isShowPMAgentValidationMessage]="false"
            (NewPaymentReferenceID)="SavePaymentReferenceId($event)" (removeDetails)="removeCardReference()">
          </app-retail-capture-card>
        </div>
      
      </section>
      <!----Previous Settlement History-->
      <section *ngIf="_sbs.PrevSettlementHistory.length > 0 && !PropertyInfo.UseRetailInterface"
        class="settlment-hist spa-row-payment-method">
        <label class="LW14">{{localization.captions.shop.SettlementHistory}}</label>
        <div *ngFor="let settlement of _sbs.PrevSettlementHistory" class="LW14">
          <div class="settlement-row">
            <div class="cash-label">{{settlement.paymentMethod}}</div>
            <div>{{_sbs.FormatPrice(settlement.amount)}}</div>
          </div>
          <div *ngIf="settlement.paymentMethodId == PayMethodConst.CreditCard" class="card-details-container">
            <mat-form-field class="d-block" [floatLabel]="floatLabel">
              <input matInput placeholder="{{localization.captions.shop.CardType}}" [value]="settlement.issuerType"
                [disabled]="true" class="blckclr" [attr.automationId]="'Txt_ServiceItemSummary_issuerTypeNo'">
            </mat-form-field>
            <mat-form-field class="d-block" [floatLabel]="floatLabel">
              <input matInput placeholder="{{localization.captions.shop.CardNumber}}" [value]="settlement.cardNumber"
                [disabled]="true" class="blckclr" [attr.automationId]="'Txt_ServiceItemSummary_issuerTypeName'">
            </mat-form-field>
          </div>
        </div>
      </section>
      <div class="return-reason" [ngClass]="{'prevent-action': _ams.paymentProcessing}">
        <mat-form-field class="w-100" [floatLabel]="floatLabel">
          <input matInput formControlName="returnReason" maxlength="250" #returnReason
            placeholder="{{localization.captions.shop.ReturnReason}}" [required] ="_shopservice?.requiredReturnReason" [attr.automationId]="'Txt_ServiceItemSummary_return'">
          <mat-error>{{localization.captions.shop.MissingReturnReason}}</mat-error>
        </mat-form-field>
      </div>
      <div class="button-row text-center pt-3">
        <button mat-raised-button type="button" id="proceedBtn" [attr.automationId]="'Btn_ServiceItemSummary_proceed'"
          [ngClass]="EnableProcessButton() ? 'button--primary-save' : 'button--disabled'"   class="w-100"  [appDisableDoubleClick]="throttletime"
          (throttleClick)="InitiateTransactionEvent()">{{_sbs.paymentText}}</button>
        <div *ngIf="_sbs.AllowCancel" [class.disabled]="_sbs.isAPICallInProgress" [attr.automationId]="'Lbl_ServiceItemSummary_cancel'"
          class="cursor pull-right text-uppercase txtgray LW14 pt-3 d-block" (click)="Cancel()">
          {{localization.captions.shop.Cancel}}</div>
      </div>
    </ng-scrollbar>
    <popover-content class="order-summary-popover" #surchargePopover placement="auto" [animation]="true" container='body'
      [closeOnClickOutside]="true">
      <section class="morelegend">
        <div class="surcharge-popover">
          <div class="info-row" *ngIf="_sbs.selectedPaymentMethod == PayMethodConst.CardOnFile">
            <div>{{_sbs.ShopCaptions.lbl_cardType}}</div>
            <div>: {{selectedIssuerType?.name}}</div>
          </div>
          <div class="info-row">
            <div>{{_sbs.ShopCaptions.lbl_AmountTendered}}</div>
            <div>: {{AmtForSurchargeCalc| Currency}}</div>
          </div>          
        </div>
      </section>
    </popover-content>
  </form>
</section>