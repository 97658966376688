import { Injectable } from "@angular/core";
import { RetailPropertyInformation } from "../../common/services/retail-property-information.service";
import { HttpServiceCall } from "../../shared/service/http-call.service";
import { RetailRoutes } from "../../retail-route";
import { Host } from "../../shared/globalsContant";
import { HttpMethod } from "src/app/common/Models/http.model";
import { TipConfig, TipConfigAPI } from "../../shared/service/payment/payment-business.model";

@Injectable({ providedIn: 'root' })
export class TipConfigurationService {
    constructor(
        private _http: HttpServiceCall
        , private _propertyInfo: RetailPropertyInformation
    ) { }

    async GetTipConfiguration() {
        if (this._propertyInfo.UseRetailInterface) return;
        let tipConfigRes = await this._http.CallApiAsync<TipConfigAPI>({
            callDesc: RetailRoutes.GetTipConfig,
            host: Host.payment,
            method: HttpMethod.Get
        });
        return tipConfigRes.result;
    }

    async CreateOrUpdateTipConfiguration(tipConfig: TipConfigAPI) {
        if (this._propertyInfo.UseRetailInterface) return;
        const httpVerb = tipConfig.id ? HttpMethod.Put : HttpMethod.Post;
        const apiRoute = tipConfig.id > 0 ? RetailRoutes.UpdateTipConfig : RetailRoutes.CreateTipConfig;
        let tipConfigRes = await this._http.CallApiAsync<TipConfigAPI>({
            callDesc: apiRoute,
            host: Host.payment,
            method: httpVerb,
            body: tipConfig
        });
        tipConfigRes.result;
    }
}