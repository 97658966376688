<div class="table-list__search-by-view">
  <dynamic-form [config]="searchConfig" #searchConfigForm>
  </dynamic-form>
</div>
<div class="demo-tab-group__demo-tab-content">
    <div class="text-center tab_details w-100 h-100" id ="tab{{tab}}">
      <div #scrollingBlock id="tab-{{tab}}" class="h-100" (scroll)="scrollHandler($event)">
        <virtual-scroller [parentScroll]="scrollingBlock" #scroll [items]="tabName.data | partyDataFilter : searchString" class="demo-tab-group__tab-overflow" id="tabs-height-{{tab}}">
          <div *ngFor="let item of scroll.viewPortItems; trackBy: trackFunction;" draggable="true" (dragstart)="handleDragStart($event, item)" (dragend)="dragend($event)" id="table-data" [ngStyle]="{'cursor': (tabName.state == partyState.Reservation || tabName.state == partyState.WaitLists) ? 'pointer':''}" class="seat-grid-content table-row" >
            <!-- (click)="editReservationOrWaitlist(item,tabName)" -->
            
            <div class="seat-table-div" (click)="editReservationOrWaitlist(item,tabName)" [ngStyle]="{'background-color':tabName.state == partyState.Seated ? (item | partyStatusColor : _settings ) : ((tabName.state == partyState.Reservation||tabName.state == partyState.WaitLists) ? ((item | reservedTableStatusColor:  seatingParties)|  partyStatusColor : _settings ) : '')}">
              <span class="table-name-row"
               [ngStyle]="{'color':tabName.state == partyState.Seated ? 'white': ((tabName.state == partyState.Reservation || tabName.state == partyState.WaitLists) && ((item | reservedTableStatusColor: seatingParties)|  partyStatusColor : _settings )) != null ? 'white': ''}">
              <span *ngFor="let tableName of item.TableIds;last as isLast;first as isFirst">
                <i class="icon-icon" *ngIf="isFirst"></i>
                {{[tableName] | formattedTableName}}
                <span *ngIf="!isLast">/</span>
              </span>
              </span>
              <span *ngIf="item?.TableNames?.length == 0">
                -
              </span>
            </div>


            <div  *ngIf="showPackageWithName" class="align_left seat-table-div" style="gap: 40px;">
               <!-- Package Information -->
                <div   style="cursor: pointer;width:45px ;" >
                  <span class="table-name-row" *ngIf="item.RoomNumber" [autoClose]="'outside'" container="body"   [ngbPopover]="item.CommonPackageInfo ? packageInformation : ''" placement="bottom" (click)="dashboardFunctions.openPackage(item)"
                  [ngStyle]="{'color':tabName.state == partyState.Seated ? 'white': ((tabName.state == partyState.Reservation || tabName.state == partyState.WaitLists) && ((item | reservedTableStatusColor: seatingParties)|  partyStatusColor : _settings )) != null ? 'white': ''}">
                    <span >
                      <i class="icon-reserve_v1crs showPackage-with-room-number" ></i>
                      {{item.RoomNumber}}
                    </span>
                  </span>
                </div>


                <div  (click)="editReservationOrWaitlist(item,tabName)" [ngStyle]="{'width': tabName.state == partyState.Seated ? 'calc(100% - 230px)': 'calc(100% - 280px),
                (item.Contact?.FirstName + item.Contact?.LastName).length > 30 ? white-space: pre-wrap; : white-space: nowrap;'}"
                            title="{{item.Contact | formattedName}}" >
                            <span *ngIf="item.Contact?.FirstName || item.Contact?.LastName">{{item.Contact  | formattedName}}</span>
                            <span *ngIf="!(item.Contact?.FirstName || item.Contact?.LastName)">{{('un_Named' | translate)}}</span>
                </div>

            </div>

            <div *ngIf="!showPackageWithName"  class="align_left seat-table-div" (click)="editReservationOrWaitlist(item,tabName)" [ngStyle]="{'width': tabName.state == partyState.Seated ? 'calc(100% - 230px)': 'calc(100% - 280px)'}"
                title="{{item.Contact | formattedName}}" >
              <span *ngIf="item.Contact?.FirstName || item.Contact?.LastName">{{item.Contact  | formattedName}}</span>
              <span *ngIf="!(item.Contact?.FirstName || item.Contact?.LastName)">{{('un_Named' | translate)}}</span>
            </div>

            <!-- <div class="font_color_blue" *ngIf="tabName.state == partyState.WaitLists">{{ item.waitedTime }}/ {{item.quotedTime}}  </div>                                   -->
            <div [ngStyle]="{'background-color':(item | partyStatusColor : _settings ),color: (item | partyStatusColor : _settings ) ? 'white':'inherit'}" #partyStatusPopup="ngbPopover" popoverClass="party-status-popover"
            class="seat-table-div" (click)="triggerPartyStatusPopup(partyStatusPopup,tabName.state,$event, item)"
            triggers="manual" [ngbPopover]="partyStatus" placement="auto" [autoClose]="'outside'" container="body">
            <span><i class="icon-user pr-1"></i>{{item.Size}}</span></div>
 
            <div class="font_color_blue seat-table-div" (click)="editReservationOrWaitlist(item,tabName)" *ngIf="tabName.state == partyState.Reservation">{{item.ReservedFor | formatTime}}</div>
            <div class="font_color_blue seat-table-div" (click)="editReservationOrWaitlist(item,tabName)" *ngIf="tabName.state == partyState.Seated">{{item.SeatingTime | formatTime}}</div>
            <div class="font_color_blue seat-table-div" (click)="editReservationOrWaitlist(item,tabName)" *ngIf="tabName.state == partyState.Seated" 
              [ngStyle]="{'font-size': tabName.state == partyState.Seated ? '12px': '16px'}">{{item.SeatingTime | timeLapse: _settings}}</div>
            <div class="font_color_blue seat-table-div" (click)="editReservationOrWaitlist(item,tabName)" *ngIf="tabName.state == partyState.WaitLists && item.remainingTime > 0">{{ item.remainingTime | formatWaitTime }}/{{(item.totalSeatingTime * 60) | formatWaitTime}} </div>
            <div class="font_color_blue seat-table-div" (click)="editReservationOrWaitlist(item,tabName)" *ngIf="tabName.state == partyState.WaitLists && (item.remainingTime < 0 || item.quotedTime == -1)">{{ item.quotedTime + (-item.remainingTime)  | formatWaitTime }}/{{(item.totalSeatingTime * 60) | formatWaitTime}} </div>
            
            <div class="demo-tab-group__paging seat-table-div">
              <!-- <span *ngIf="item.PageMethod == 0" class="seat-icon-color" [class]="item.PageMethod | pageIcon "
                            [ngStyle]="{color:item.pageBackgroundColor}"></span>
                            <span *ngIf="item.PageMethod != 0" [class]="item.PageMethod | pageIcon"
                              [ngClass]="{'demo-tab-group__message-status': (item?.Messages?.length > 0)}"></span> -->
                              <!--  item.pageBackgroundColor -->
              <a data-toggle="popover" [ngStyle]="{'background-color': item | pageBackgroundColor : _settings, 'border-radius': '4px', 'color': (item | pageBackgroundColor : _settings) != '#E8E8E8' ? 'white' : 'black', 'padding': '5px 5px 5px 4px'}"
                *ngIf="(item.PageMethod == 3 || item.PageMethod == 7)" (click)="emailMessages(emailChat,item)" #emailChat="ngbPopover"
                [ngbPopover]="messages"  container="body"  popoverClass="email-popover" [autoClose]="false" triggers="manual" placement="auto"
                class="icon-envelope dashboard-party-info__guest-actions seat-border-color"></a>
              <a data-toggle="popover" [ngStyle]="{'background-color': item | pageBackgroundColor : _settings, 'border-radius': '4px', 'color': (item | pageBackgroundColor : _settings) != '#E8E8E8' ? 'white' : 'black', 'padding': '5px 5px 5px 4px'}"
                *ngIf="(item.PageMethod == 1 || item.PageMethod == 4 || item.PageMethod == 6)" (click)="mobileMessages(messageChat,item)" #messageChat="ngbPopover"
                [ngbPopover]="messages"  container="body"  popoverClass="message-popover" [autoClose]="false" triggers="manual" placement="auto"
                class="icon-SMS dashboard-party-info__guest-actions seat-border-color"></a>
              <a [ngStyle]="{'background-color': item | pageBackgroundColor : _settings, 'border-radius': '4px', 'color': (item | pageBackgroundColor : _settings) != '#E8E8E8' ? 'white' : 'black', 'padding': '5px 5px 5px 4px'}" *ngIf="item.PageMethod == 0" (click)="manualpage(item)"
                class="icon-Path-506 dashboard-party-info__guest-actions seat-border-color"></a>
              <a [ngStyle]="{'background-color': item | pageBackgroundColor : _settings, 'border-radius': '4px', 'color': (item | pageBackgroundColor : _settings) != '#E8E8E8' ? 'white' : 'black', 'padding': '5px 5px 5px 4px'}" *ngIf="(item.PageMethod == 2 || item.PageMethod == 5)"
                class="icon-ic_stay_current_portrait_24px dashboard-party-info__guest-actions seat-border-color"></a>
              <ng-template class="messages" class="arrow-up" #messages>
                <app-chat-view [messages]="predefinedMsgs$.asObservable() | async" [partyInfo]="party" [historyData]="history" [ContactId]="party?.Contact?.Id"
                  (closePopover)="closeMobileChat()" [inputString]="inputString" [disableMessagePopup]="disableMessagePopup">
                </app-chat-view>
              </ng-template>
            </div>
            <div class="demo-tab-group__actions seat-table-div" *ngIf="(tabName.state == partyState.Reservation || tabName.state == partyState.WaitLists) && !hidePartyActions">
              <button class=" icon-Group-587 rounded-icon demo-tab-group__icon-green-color" (click)="seatReservation(item,$event)"><span class="path1"></span><span class="path2"></span></button>
              <button class=" icon-Group-591 rounded-icon demo-tab-group__icon-grey-color" (click)="cancelParty(item,$event)"></button>
            </div>
            
          </div>
        </virtual-scroller>
      </div>
    </div>
    <tr *ngIf="tabName?.data?.length == 0">
      <td [colSpan]="8" class="seat-no-content-found">{{'noDataFound' | translate}}</td>
    </tr>
    <div class="demo-tab-group__party-status">
      <ng-template #partyStatus>
        <app-party-status-popup [hidden]="hidePartyActions" [party]="item" (partyStatusId)="seatStatusChanged($event)"></app-party-status-popup>
      </ng-template>
    </div>
</div>

<ng-template #packageInformation>
  <app-package-information [packageInfo]="dashboardFunctions.currentPackageInfo"></app-package-information>
</ng-template>
