<div class="shifts-area">
  <!-- <span class="shifts-select-label shifts-area__select-text seat-panel-header">{{'selectShift'|translate}}</span> -->
  <dynamic-form [config]="shiftSelectionConfig" #form="dynamicForm" >
  </dynamic-form>
  <mat-error class="shifts-area__errorblink" *ngIf="!isShiftisAvilable"> <span class="icon-notification"></span>
   {{ ('shiftnotfound')|translate}}</mat-error>
  <div class="shifts-area__data-container">
    <span *ngIf="showNavigation" (click)="navigateLeft()" class="icon-Path-490"></span>
    <div class="shifts-area__seating" #areaContainer>
      <div class="shifts-area__seating" *ngIf="selectedShiftType == 0">
        <div class="shifts-area__seating-area" *ngFor="let shift of shifts">
          <div id="{{shift.Id}}" class="shifts-area__eacharea"
          (click)=setSelected(shift) >
            <div class="shifts-area__area-abbreviation" >
              <img src="{{shift.imageUrl}}" class="fullimagemrg"/>
            </div>
            <label class="shifts-area__name rs-pt-10 seat-header-three">{{shift.Name}}</label>
            <label *ngIf="shift.DisplayedRange.Start" class="shifts-area__time rs-pt-10 seat-text-fields">{{shift.DisplayedRange.Start | localizedDate:'LT'}} - {{shift.DisplayedRange.End | localizedDate:'LT'}}</label>
            <svg id="svg_{{shift.Id}}"  class="shifts-area__checkmark position-absolute" viewBox="0 0 52 52">
              <circle class="shifts-area__checkmark-circle " cx="26" cy="26" r="25" fill="none" />
              <path class="shifts-area__checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
          </div>
        </div>
      </div>
      <form [formGroup]="selectShiftControl">

      <div class="shifts-area__seating" *ngIf="selectedShiftType == 1 || selectedShiftType == 2">
        <div class="shifts-area__seating-area p-3">
          <mat-form-field class="basic-input-field p-5" appearance="outline">
          <input matInput placeholder="{{'fromTimeText' | translate}}" [format]="24" [value]="fromTime"   [ngxTimepicker]="picker"  formControlName="fromTime">
          <ngx-material-timepicker   (timeSet)="timeChange($event, 'from')"  #picker [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn"></ngx-material-timepicker>
          <ng-template #cancelBtn ><button class="timepickerCustBtn timepicker-button">{{'cancel' | translate}}</button></ng-template>
          <ng-template #confirmBtn ><button class="timepickerCustBtn timepicker-button">{{'ok' | translate}}</button></ng-template>

           <!-- <mat-error
              *ngIf="group.controls[FormControlName].hasError('required') && config.showErrorText">
              {{config.errorMessage | translate}}
            </mat-error> -->
          </mat-form-field>
        </div>
        <div class="shifts-area__seating-area p-3">
          <mat-form-field class="basic-input-field p-5" appearance="outline">
            <input matInput placeholder="{{'toTimeText' | translate}}"  [format]="24" [value]="toTime"   [ngxTimepicker]="picker1"  formControlName="toTime">
              <ngx-material-timepicker (timeSet)="timeChange($event, 'to')"   #picker1 [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn"></ngx-material-timepicker>
              <ng-template #cancelBtn ><button class="timepickerCustBtn timepicker-button">{{'cancel' | translate}}</button></ng-template>
          <ng-template #confirmBtn ><button class="timepickerCustBtn timepicker-button">{{'ok' | translate}}</button></ng-template>

            <!-- <mat-error
              *ngIf="group.controls[FormControlName].hasError('required') && config.showErrorText">
              {{config.errorMessage | translate}}
            </mat-error> -->
            </mat-form-field>
        </div>
      
      </div>
    </form>
    
    </div>
    <span *ngIf="showNavigation" (click)="navigateRight()" class="icon-left-arrow1"></span>
  </div>
</div>