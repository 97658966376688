<section id="MtoS" class="h-100">
  <form autocomplete="off" name="AddTransferMS" [formGroup]="multipltosingleFG" class="h-100" container="body">
    <div class="grid-container ">
      <div class="w-100">
        <div class="pt-2">
          <mat-form-field class="mr-5 pointerevents-none" [floatLabel]="floatLabel">
            <mat-select [placeholder]="captions.retailsetup.fromOutlet"  id="fromOutlet" formControlName="fromOutlet" (selectionChange)="outletsSelected($event,'from')">
              <mat-option *ngFor='let FOutlet of fromOutlets' [value]='FOutlet.id'>{{FOutlet.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [floatLabel]="floatLabel">
            <mat-select [placeholder]="captions.retailsetup.toOutlet" id="toOutlet" formControlName="toOutlet" (selectionChange)="outletsSelected($event,'to')" required>
              <mat-option *ngFor='let TOutlet of toOutlets' [value]='TOutlet.id'>{{TOutlet.name}}</mat-option>
            </mat-select>
            <mat-error>
             {{captions.retailsetup.toOutletMissing}}
          </mat-error>
          </mat-form-field>
        </div>
        <div class="pt-2">
          <mat-form-field class="w415" [floatLabel]="floatLabel">
            <input matInput [placeholder]="captions.retailsetup.transferReason" type="text" [maxlength]="250" formControlName="transferReason" required>
            <mat-error>
              {{this._Localization.captions.common.Missing}} {{captions.retailsetup.transferReason}}
          </mat-error>
          </mat-form-field>
        </div>
        <div class="pt-2 clearfix">
          <div class="float-left w415">
            <mat-form-field class='w415' [floatLabel]="floatLabel">
              <input type="text" formControlName="searchTxt" class="LW14"  [placeholder]="captions.retailsetup.IDCsearch" matInput [matAutocomplete]="auto" (input)="filterFucntion($event)">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)='SelectedRecord($event.option)'>
                  <mat-option *ngFor="let option of searchFilter" [value]="option" class="CustOptionStyle">
                    <div class="LW14 text-capitalize"> {{ option.itemNumberColumn }} - {{ option.itemName }} </div>
                  </mat-option>
                </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="float-left ml-4 mt-2">
              <button class="btn btn-default LW14 text-capital button-height" type="button" (click)="addItemToTable()" [ngClass]="disableAdd? 'button--disabled':' body-bgcolor whitecolor body-bordercolor'" [disabled]="disableAdd">{{captions.common.ADD}}</button>
          </div>
          <div class="float-right mt-4">
              <label class="pr-3">{{captions.retailsetup.totalItemtoTransfer}} <span class="pl-2">{{this.checkedarrcount}}</span> </label> | <label class="pl-3"> {{captions.retailsetup.totalvalueofTransfer}}
              <span class="pl-2">{{this.transferquantity | Currency}}</span> </label>
          </div>
        </div> 
        <div class="w-100" id="mtosTableid">
        <ng-scrollbar>
            <table aria-describedby="" class="mtosTableclass w-100" #mtoscellref>
              <thead>
                <tr>
                  <th scope='col' >
                    <div class="th-inner">
                        <mat-checkbox [checked]="this.selectedtablerow.length == this.totalChecked && this.totalChecked>0" (change)="checkAll($event)"></mat-checkbox>
                    </div>
                  </th>
                  <th  scope='col' *ngFor="let thdata of TableHdrData;let hi=index"> 
                    <div (click)="sortingColoumns(thdata.jsonkey,selectedDefaultHeader == thdata.jsonkey)" [ngClass]="selectedDefaultHeader == thdata.jsonkey ? 'font-weight-bold'  : ''">
                      <span *ngIf="thdata.jsonkey != 'isInActive'">{{thdata.title}}</span>
                      <span *ngIf="thdata.jsonkey == 'isInActive'">{{captions.bookAppointment.Status}}</span>
                      <span class="pl-1">

                      <i aria-hidden="true" 
                     [ngClass]="selectedDefaultHeader == thdata.jsonkey && orderType == 'asc' ? 'icon-filled_up_arrow deg0 IC6': selectedDefaultHeader == thdata.jsonkey && orderType =='desc' ?
                     'icon-filled_up_arrow deg180 IC6': 'icon-sortArrow sort-icon position-absolute'"

                     id="sortArrow{{hi}}"
                      [ngStyle]="{'opacity':selectedDefaultHeader == thdata.jsonkey ? '1' : '0.3' }"></i>


                      </span></div>
                  </th>
                  <th  scope='col' class="text-align-center pr-4">
                    {{this.captions.retailsetup.transferqty}}
                  </th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let eachrow of selectedtablerow;let i=index">
                    <td>
                        <div class="th-inner">
                            <mat-checkbox  [checked]="eachrow.checkedFlag" (change)="togglecheck($event,eachrow)"></mat-checkbox>
                        </div>
                    </td>
                    <td *ngFor="let thdata of TableHdrData;let hi=index">
                        <span *ngIf="thdata.jsonkey != 'isInActive'"> {{eachrow[thdata.jsonkey]}}</span>
                        <span *ngIf="thdata.jsonkey == 'isInActive' && eachrow[thdata.jsonkey] == true">{{captions.retailsetup.Inactive}}</span>
                        <span *ngIf="thdata.jsonkey == 'isInActive' && eachrow[thdata.jsonkey] == false">{{captions.retailsetup.Active}}</span>
                    </td>
                    <td class="text-align-center pr-4">
                        <input type="text" maxlength="5" inputtype="nonnegative,nodecimal" (keyup)="dataChanged($event)" [disabled]="!eachrow.checkedFlag" (keyup)="addquamtity()" [(ngModel)]="eachrow.transferedQty" [ngClass]="{'input-inactive': ( eachrow.transferedQty <= 0  && eachrow.checkedFlag ) }"  [ngModelOptions]="{standalone: true}"  class=" transferquantity enabledbg LW12" >
                    </td>
                  </tr>
                  <tr *ngIf="selectedtablerow && selectedtablerow.length == 0">
                    <td [colSpan]="10" class="text-center">{{this._Localization.captions.common.NoDataFound}}</td>
                  </tr>
                </tbody>
            </table>
        </ng-scrollbar>
        </div>
      </div>
    </div>
    <mat-dialog-actions class=" pl-4">
      <button mat-raised-button type="button" (click)="transferdata()"
        class="LW14 text-capital" [disabled]="(multipltosingleFG.invalid || !checkedTableRowBool)? true : false" [ngClass]="(multipltosingleFG.invalid || !checkedTableRowBool)? 'button--disabled':' body-bgcolor whitecolor body-bordercolor'">{{this.captions.retailsetup.transfer}}</button>
      <button mat-raised-button type="button" (click)="close()">{{this.captions.retailsetup.CANCEL}}</button>
    </mat-dialog-actions>
  </form>
</section>
