<div class="cart">
  <div class="cart__header">
    <div class="cart__title">{{'CartSummary' | translate}}</div>
    <div class="cart__all-button">
      <dynamic-form [config]="searchconfig" #searchConfigForm>
      </dynamic-form>
      <!-- <activities-app-button *ngIf="cartSumaryDataList && cartSumaryDataList.length" [buttontype]="selectAllBtn"
        (valueChange)='selectAllChange(true)'></activities-app-button>
      <activities-app-button *ngIf="cartSumaryDataList && cartSumaryDataList.length" [buttontype]="unselectAllBtn"
        (valueChange)='selectAllChange(false)'></activities-app-button> -->
    </div>
    <div class="cart__right-container">
      <!-- <div class="cart__time-container" *ngIf="_as.showTimer">
        <div class="cart__focused-sub-header  cart__time-mins"
          [ngStyle]="_as.minutes === 0 && _as.seconds < 60 ?  {'color':'#B00020'} : {'color':'#F27106'}">
          <span class="cart__timer-number">{{_as.minutes}}</span>
          <span>{{'mins' | translate}}</span> <span class="cart__timer-number">{{_as.seconds}}</span>
          <span>{{'secs'
            |
            translate}}</span>
        </div>
        <div class="cart__time-left-text">{{'leftForBooking' | translate}}</div>
      </div> -->
      <div class="cart__clear-button" *ngIf="cartSumaryDataList && cartSumaryDataList.length">
        <activities-app-button [buttontype]="clearCartBtn" (valueChange)='clearCart()'></activities-app-button>
      </div>
    </div>
  </div>
  <div class="cart__content">
    <div class="cart__content--left" [class.full]="!showIframe">
      <div class="row cart__content-container">
        <div class="col-lg-9 col-md-9 cart__content-viewport">

      <mat-accordian  class="cart__grouped-cart">
        <mat-expansion-panel class="cart__expansion-panel" expanded *ngFor="let cartItem of (groupedCartSummaryDataList | searchDataGroupPipe : searchText);let index = index" hideToggle>
          <mat-expansion-panel-header class="cart__panel-header">
            <mat-panel-title >
              <mat-checkbox (click)="selectAllInGroup($event, cartItem)" [(ngModel)]="cartItem.allSelected"></mat-checkbox>
             <span class="seat-header-three cart__groupname"> {{cartItem.groupName}}</span>
            </mat-panel-title>
            <mat-panel-description class=" seat-grid-header cart__panel-description">
              <ng-container *ngIf="(cartItem.groupItems | availableCartItem) as availableCartItemDetail">
                  
                  <ng-container *ngIf="availableCartItemDetail.availableCartItemValue || availableCartItemDetail.isStandByOnly; else checkAvailabilityGroup" >
                      <div showExpireTime class="cart__timer-view" *ngIf="availableCartItemDetail.availableCartItemValue" [expireTime]="availableCartItemDetail.availableCartItemValue?.ExpireAt" [CartItem]="cartItem" [isAvailable]="availableCartItemDetail.availableCartItemValue?.isAvailable" [_settings]="cs.settings.value" (timerExpired)="timerExpired(cartItem)" >
                        <span class="cart__time-text" popoverClass="extend-timer-popup"
                              [ngbPopover]="extendTimer" #timerPopup="ngbPopover"
                              container="body" placement="bottom" [autoClose]="'outside'" 
                              (click)="$event.stopPropagation();"
                              *ngIf="availableCartItemDetail.availableCartItemValue.isAvailable && !cartItem.isLoading">
                          {{availableCartItemDetail.availableCartItemValue.CartItemDetail.Time}}
                        </span>
                        <span class="cart__group__loading_availability" *ngIf="cartItem.isLoading">
                          <img alt="Loading" src="assets/images/seat-loader.svg">
                        </span>
                        <ng-template #extendTimer let-item='cartItem'> 
                          <div class="extend-timer-header d-flex ">
                            <label for="" class="seat-header-four extend-timer-header-label">{{'lockFor' | translate}}</label>
                            <i class="icon-close pointer" (click)="closePopover()"></i>
                          </div>
                          <div class="extend-timer-container d-flex justify-content align-center">
                            <mat-form-field class="timer-input d-block mr-2">
                              <input type="text" min=1 [(ngModel)]="minutesExtended" maxlength=2 (keyup)="_keyUp($event)" numbersOnly matInput /> 
                            </mat-form-field>
                            <span>{{'mins' | translate}}</span>
                          </div>
                          <activities-app-button class="extend-timer-footer" [disabled]="!minutesExtended" (valueChange)="extendLockSessionTime(cartItem,minutesExtended, timerPopup)" [buttontype]="timerApplyButtonConfig"></activities-app-button>
                        </ng-template> 
                      </div>
                  </ng-container>

                  <ng-template #checkAvailabilityGroup>
                    <span  class="cart__check-available-text" (click)="extendLockSessionTime(cartItem,null);$event.stopPropagation();">{{'checkAvailability' | translate}}</span>
                  </ng-template>
              </ng-container>
              <i class="icon-Delete cart__delete-view" (click)="$event.stopPropagation();removeGroup($event,cartItem,index)"></i>
              <!-- <ngx-material-timepicker-toggle matSuffix [for]="toggleTimepicker" class="">
                            </ngx-material-timepicker-toggle>
              <ngx-material-timepicker #toggleTimepicker enableKeyboardInput="true" (timeSet)="extendLockSessionTime(cartItem,$event)"></ngx-material-timepicker> -->
                           <!-- {{cartItem.groupItems[0].lockExpired ? 'check Availability' : ('Expires At: ' + cartItem.expiresAt) }} -->
            <!-- <activities-app-button [buttontype]="selectAllBtnConfig" (valueChange)='$event.stopPropagation();selectAllInGroup(cartItem)'></activities-app-button>
            <activities-app-button [buttontype]="selectAllBtnConfig" (valueChange)='removeGroup($event,cartItem,index)'>r</activities-app-button>
            -->
          </mat-panel-description>
          
          </mat-expansion-panel-header>
          <ng-container
          *ngIf="(cartItem.groupItems) as cartSumaryDataListFiltered">
  
          <div class="">
            <div class="pannel cart-pannel pr-0">
              <ng-container>
              <div class="cart__items" *ngIf="cartSumaryDataListFiltered && cartSumaryDataListFiltered.length">
                <div class="cart__item" [class.selected]="item.selected" [class.packageAvailable]="item.packageAvailable"
                [class.disabled]="item.isExpired ? item.isExpired : (!item.isAvailable && !item.standbyOpenBooking && !item.StandBy && !item.CartItemDetail?.CartExpireTime)"
                  *ngFor="let item of cartSumaryDataListFiltered; let idx = index;" (click)="cartItemClicked(cartItem,item)">
                  <div class="cart__item__header-left">
                    <div class="cart__item-title">
                      {{item.CartItemDetail.PartyName}}
                      , <span class="icon icon-add-contact"></span> <span> {{item.CartItemDetail.Size}}</span>
                    </div>
                    <div class="cart__item-subtitle" [matTooltip]="item.CartItemDetail?.SpecialMeal">
                      {{item.CartItemDetail.SpecialMeal }}</div>
                    <div class="cart__item-instructor"
                      *ngIf="item && item.CartItemDetail && item.CartItemDetail.Instructors && item.CartItemDetail.Instructors.length">
                      {{'By' | translate}} &nbsp;
                      <span> {{item.CartItemDetail?.Instructors[0]}}</span>
                      <span class="cart__more-instructors" [matTooltip]="item.CartItemDetail?.Instructors | arrayJoin"
                        *ngIf="item.CartItemDetail?.Instructors.length>1">&nbsp;
                        +{{item.CartItemDetail?.Instructors.length - 1 }}</span>
                    </div>
                    <div class="cart__item-location"
                      *ngIf="item && item.CartItemDetail && item.CartItemDetail.Locations && item.CartItemDetail.Locations.length">
                      <span *ngIf="item && item.CartItemDetail.Instructors && item.CartItemDetail.Instructors.length">
                        &nbsp;&nbsp;|&nbsp;&nbsp;</span>
                      <span class="icon icon-placeholder"></span>
                      <span class="location_name" [matTooltip]="item.CartItemDetail?.Locations[0]">
                        {{item.CartItemDetail?.Locations[0]}}</span>
                      <span class="cart__more-locations" [matTooltip]="item.CartItemDetail?.Locations | arrayJoin"
                        *ngIf="item.CartItemDetail?.Locations.length>1">&nbsp;
                        +{{item.CartItemDetail?.Locations.length - 1 }}</span>
                    </div>
                    <br>
                    <div class="cart__item-date" *ngIf="item?.CartItemDetail?.BookingBehavior != BookingBehaviorConst.RentalBooking">{{item.CartItemDetail.StartDate | formatSettingsDate:
                      cs.settings.value.General.DateFormat}}
                      {{item.CartItemDetail.SpecialMealId && '- ' +( item.CartItemDetail.EndDate | formatSettingsDate:
                      cs.settings.value.General.DateFormat)}}
                    </div>
                    <div class="cart__item-date" *ngIf="item?.CartItemDetail?.BookingBehavior == BookingBehaviorConst.RentalBooking">
                      {{item?.CartItemDetail?.StartDate | formatSettingsDate: cs.settings.value.General.DateFormat}}
                      {{item?.CartItemDetail?.StartDate | localizedDate : 'LT'}} 
                      {{'- ' +( item.CartItemDetail.EndDate | formatSettingsDate: cs.settings.value.General.DateFormat)}}
                      {{item?.CartItemDetail?.EndDate | localizedDate : 'LT'}}
                     </div>
                    <div class="cart__item-session-count" *ngIf="item.CartItemDetail.TotalSessions > 0"> ,&nbsp;&nbsp;
                      {{item.CartItemDetail.TotalSessions}}
                      <span *ngIf="item?.CartItemDetail?.BookingBehavior != BookingBehaviorConst.RentalBooking">{{item.CartItemDetail.TotalSessions >1 ? ('Sessions' | translate) : ('Session' | translate)}}</span>
                      <span *ngIf="item?.CartItemDetail?.BookingBehavior == BookingBehaviorConst.RentalBooking"> {{item.CartItemDetail.TotalSessions>1 ? ('Rental items' | translate) : ('Rental item' | translate)}}</span></div>
                      <span *ngIf="item?.CartItemDetail?.BookingBehavior == BookingBehaviorConst.RentalBooking" class="icon-respos-info-2 pointer pl-1"
                      [autoClose]="'outside'" container="body" [ngbPopover]="RentalItemsNames"
                      popoverClass="payment-details" placement="auto" (click)="viewRentalItemNames($event, item)"></span>
                  </div>
                  <div class="cart__item__header-right">
                    <div class="cart__item-avatar" [ngStyle]="{'background-color': '' | randomColor}">
                      {{item.CartItemDetail.PartyName | initialFormatter}}
                    </div>
                    <div class="cart__item__availability" *ngIf="!item.isLoading">
                      <ng-container *ngIf="locked">
                        <div *ngIf="!item.isExpired">
                          <span class="available" *ngIf="item.isAvailable && !item.lockExpired && !item.StandBy; else unknown">{{'Available' |
                            translate}}</span>
                            <ng-template #unknown>
                              <span *ngIf="item.StandBy" class="standby-booking">{{'Standby' | translate}}</span>
                              <span *ngIf="!item.standbyOpenBooking && !item.StandBy && item.lockExpired; else unavailable" class="btn-text cart__check-available-text" (click)="lockCartItem(item, idx)">{{'checkAvailability' | translate}}</span>
                              <ng-template #unavailable>
                                <span *ngIf="!item.standbyOpenBooking && !item.StandBy">{{'Unavailable' | translate}}</span>
                              </ng-template>                                
                            </ng-template>
                        </div>
                        <span *ngIf="item.isExpired">{{'expired' |
                          translate}}</span>
                      </ng-container>
                    </div>
                    <div class="cart__item__status-loading" *ngIf="item.isLoading">
                      <div class="dot1"> </div>
                      <div class="dot2"></div>
                      <div class="dot3"></div>
                    </div>
                  </div>
                  <div  *ngIf="item?.RatePlan" class="cart__item-total">
                    <div class="cart__item-total-label"
                      *ngIf="item.RatePlanTotal && !item.RatePlanTotal.TotalDepositAmount; else cartCard">{{'findTotal' | translate}}
                      <span class="pl-1 icon-res-payment-Paid pointer"
                      *ngIf="(item?.RatePlan?.Total > 0)"
                        [autoClose]="'outside'" container="body" [ngbPopover]="AmountSplitList"
                        popoverClass="payment-details" placement="auto" (click)="updatePopOver($event,item)"></span>
                        <span class="icon-offer pl-1" *ngIf="item.CartItemDetail?.rateSummary?.OfferCode" [matTooltip]="item.CartItemDetail?.rateSummary?.OfferCode"></span>
                     
                    </div>
                    <ng-template #cartCard>
                      <div class="cart__item-total-label">
                        {{'findTotal' | translate}}
                   <span class="pl-1 icon-res-payment-Paid pointer"
                        [autoClose]="'outside'" container="body" [ngbPopover]="AmountSplitList"
                        popoverClass="payment-details" placement="auto" (click)="updatePopOver($event,item)"></span>
                        <span class="icon-offer pl-1" *ngIf="item.CartItemDetail?.rateSummary?.OfferCode" [matTooltip]="item.CartItemDetail?.rateSummary?.OfferCode"></span>

                      </div>
                   </ng-template>
                    <!-- <div class="cart__item-total-value"
                      *ngIf="item.RatePlanTotal && !item.RatePlanTotal.TotalDepositAmount">
                      <span *ngIf="item.RatePlanTotal">
                        #1: {{cs.operationCurrency}}
                        {{((item.RatePlanTotal.TotalRatePlan || 0) +(item.NegotiatedAmount || 0) + (item.RatePlanTotal.OverAllNegotiation || 0) +(item.RatePlanTotal.TotalServiceChargeAmount || 0) + (item.RatePlanTotal.TotalAddonServiceChargeAmount || 0) + (item.RatePlanTotal.TotalTaxOnServiceChargeAmount || 0) + (item.RatePlanTotal.TotalAddonTaxOnServiceChargeAmount || 0) +
                        (item.RatePlanTotal.TotalTaxAmount || 0) + (item.RatePlanTotal.TotalAddonAmount || 0) + (item.RatePlanTotal.TotalAddonTaxAmount || 0)) | currencyDecimalFormatter
                        }}
                      </span>
                    </div> -->
               <div class="cart__item-total-value">
                      <!-- <span *ngIf="!locked && !item.RatePlanTotal">Loading...</span> -->
                      <span *ngIf="item.CartItemDetail?.rateSummary">
                        {{cs.operationCurrency}}
                        {{(item?.RatePlan?.Total || 0) | currencyDecimalFormatter
                        }}
                      </span>
                    </div>
                    <div class="cart__item-total-label"
                      *ngIf="item.RatePlanTotal && item.RatePlanTotal.TotalDepositAmount">
                      {{'totalDepositValue' | translate}}</div>
                    <div class="cart__item-total-value"
                      *ngIf="item.RatePlanTotal && item.RatePlanTotal.TotalDepositAmount">
                      <!-- <span *ngIf="!locked && !item.RatePlanTotal">Loading...</span> -->
                      <span>{{cs.operationCurrency}} 
                        {{((item?.RatePlanTotal?.TotalDepositAmount || 0)) | currencyDecimalFormatter}}</span>
                    </div>
                  </div>
                  <div class="cart__item-footer">
                    <div class="cart__item-remove" (click)="removeCartItem($event, item)">{{'Remove' | translate}}</div>
                    |
                    <div class="cart__item-edit" (click)="editCartData($event, item)">{{'edit' | translate}}</div>
                  </div>
                  <ng-container *ngIf="item?.CartItemDetail?.BookingBehavior === BookingBehaviorConst.RentalBooking">
                    <!-- <div class="cart__item--rippon" [ngClass]="{'rental-bg' : item?.CartItemDetail?.BookingBehavior == BookingBehaviorConst.RentalBooking, 'activity-bg' : item?.CartItemDetail?.BookingBehavior != BookingBehaviorConst.RentalBooking}">
                      {{item?.CartItemDetail?.BookingBehavior == BookingBehaviorConst.RentalBooking ? 'Rental' : 'Activity' | translate}} 
                      <div class="white-triangle" [ngClass]="{'rental-triangle' : item?.CartItemDetail?.BookingBehavior == BookingBehaviorConst.RentalBooking, 'activity-triangle' : item?.CartItemDetail?.BookingBehavior != BookingBehaviorConst.RentalBooking}"></div>
                  </div> -->
                  <div class="cart__item--rippon rental-bg">
                    {{ 'Rental' | translate}}
                    <div class="white-triangle rental-triangle"></div>
                  </div>
                  </ng-container>
                </div>
              </div>
  
              <div class="cart__empty"
                *ngIf="(partyService.TotalCartBookingObj?.length == 0) && (!cartSumaryDataList || (cartSumaryDataList && !cartSumaryDataList.length))">
                {{'EmptycartMsg' | translate}}
              </div>
            </ng-container>
            </div>

          </div>

        </ng-container>
  




          
              </mat-expansion-panel>
      </mat-accordian>
      <div class="cart__empty"
      *ngIf="((groupedCartSummaryDataList | searchDataGroupPipe : searchText)?.length == 0)">
      {{'NoItemsfound' | translate}}
    </div>
      </div>
      <div class="col-lg-3 col-md-3 pannel pl-0">
        <app-package-booking (availableCartItems)="applyPackage($event)"></app-package-booking>
       
      </div>
      </div>
      <div class="cart__footer" *ngIf="cartSumaryDataList && cartSumaryDataList.length">
        <div class="cart__warningmessage" *ngIf="showCartLockWarning">{{'cartLockWarningMessage' | translate}}</div>
        <div class="cart__continue">
          <!-- <div class="cart__total-label">
            {{'WouldyouliketoContinue' | translate}}{{'?'}}
          </div> -->
        </div>
        <activities-app-button [buttontype]="continuebtn" (valueChange)='continueShopping()'></activities-app-button>
        <div class="cart__total">
          <div class="cart__payments d-flex align-items-center p-2" *ngIf="cartTotal > 0 ">
            <app-payee-info class="payee-info" (selectedPayeeCardInfo)="selectedPayeeCardInfo($event)" [payeeDetails]="this.payingGuest" [ratePlanObject]="payingGuest" [preSelectedCards]="preSelectedCards"></app-payee-info>
            <app-common-booking-payments (setPayment)="setPartyPaymentType($event)" (setRetailTokenId)="setRetailCardTokenId($event)" [defaultPaymentType]="defaultPaymentType" (authorizedCard)="selectedCard($event)" (removeCardList)="removeCardList($event)" (saveCardAgainstGuest)="saveCardAgainstGuest($event)"></app-common-booking-payments>
          </div>
          <div class="cart__total-label">
            {{'Total' | translate}} ({{numberOfItemsSelected}} {{'items' | translate}})
            <span *ngIf="cartTotal > 0" class="icon-res-payment-Paid pl-1 pointer" [autoClose]="'outside'" container="body"
              [ngbPopover]="AmountSplitList" popoverClass="payment-details" placement="auto"
              (click)="updatePopOver($event,null,'total')"></span>

          </div>
          <div class="cart__total-value">
            <span>{{cs.operationCurrency}} {{cartTotal | currencyDecimalFormatter}}</span>
            </div>  
        </div>
        <div>

        </div>
        <div class="cart__proceed" *ngIf="!showIframe && !showCartLockWarning">
          <activities-app-button [buttontype]="applyPromoCode" 
          [ngbPopover]="promoCodePopover" placement="top-right" [autoClose]="'outside'"  #applyPromoCodePopover="ngbPopover"></activities-app-button>
          <ng-template #promoCodePopover>
            <app-apply-promo-code [promoCodeEligibleProperty]="promoCodeEligibleProperty" [disableToggle]="true" (promoApplied)="promoCodeApplied($event)"></app-apply-promo-code>
            </ng-template>
          <activities-app-button [buttontype]="proceedBtn" (valueChange)='cartProceed($event)'></activities-app-button>
        </div>
        <div class="cart__proceed" *ngIf="!showIframe && showCartLockWarning">
          <activities-app-button [buttontype]="proceedPaymentBtn" (valueChange)='proceedForPayment()'></activities-app-button>
          <div class="cart__proceed-no">
            <activities-app-button [buttontype]="releaseBtn" (valueChange)='unlockCartItems()'></activities-app-button>
          </div>
        </div>
      </div>
    </div>
    <div class="cart__content--right" [hidden]="!showIframe">
      <div class="cart__payment" *ngIf="iframeURL">
        <iframe class="cart__payment__iframe" frameBorder="0" [src]="iframeURL"></iframe>
      </div>
    </div>
  </div>
</div>

<ng-template #cardView let-item='item'>

  <div class="cart__item__header-left">
    <div class="cart__item-title">
      {{item.CartItemDetail.PartyName}}
      , <span class="icon icon-add-contact"></span> <span> {{item.CartItemDetail.Size}}</span>
    </div>
    <div class="cart__item-subtitle" [matTooltip]="item.CartItemDetail?.SpecialMeal">
      {{item.CartItemDetail.SpecialMeal}}</div>
    <div class="cart__item-instructor"
      *ngIf="item && item.CartItemDetail && item.CartItemDetail.Instructors && item.CartItemDetail.Instructors.length">
      {{'By' | translate}} &nbsp;
      <span> {{item.CartItemDetail?.Instructors[0]}}</span>
      <span class="cart__more-instructors" [matTooltip]="item.CartItemDetail?.Instructors | arrayJoin"
        *ngIf="item.CartItemDetail?.Instructors.length>1">&nbsp;
        +{{item.CartItemDetail?.Instructors.length - 1 }}</span>
    </div>
    <div class="cart__item-location"
      *ngIf="item && item.CartItemDetail && item.CartItemDetail.Locations && item.CartItemDetail.Locations.length">
      <span *ngIf="item && item.CartItemDetail.Instructors && item.CartItemDetail.Instructors.length">
        &nbsp;&nbsp;|&nbsp;&nbsp;</span>
      <span class="icon icon-placeholder"></span>
      <span class="location_name" [matTooltip]="item.CartItemDetail?.Locations[0]">
        {{item.CartItemDetail?.Locations[0]}}</span>
      <span class="cart__more-locations" [matTooltip]="item.CartItemDetail?.Locations | arrayJoin"
        *ngIf="item.CartItemDetail?.Locations.length>1">&nbsp;
        +{{item.CartItemDetail?.Locations.length - 1 }}</span>
    </div>
    <br>
    <div class="cart__item-date" *ngIf="item?.CartItemDetail?.BookingBehavior != BookingBehaviorConst.RentalBooking">{{item.CartItemDetail.StartDate | formatSettingsDate:
      cs.settings.value.General.DateFormat}}
      {{item.CartItemDetail.SpecialMealId && '- ' +( item.CartItemDetail.EndDate | formatSettingsDate:
      cs.settings.value.General.DateFormat)}}
    </div>
      <div class="cart__item-date" *ngIf="item?.CartItemDetail?.BookingBehavior == BookingBehaviorConst.RentalBooking">
        {{item?.CartItemDetail?.StartDate | formatSettingsDate: cs.settings.value.General.DateFormat}}
        {{item?.CartItemDetail?.StartDate | localizedDate : 'LT'}}
        {{'- ' +( item.CartItemDetail.EndDate | formatSettingsDate: cs.settings.value.General.DateFormat)}}
        {{item?.CartItemDetail?.EndDate | localizedDate : 'LT'}}
      </div>
    <div class="cart__item-session-count" *ngIf="item.CartItemDetail.TotalSessions > 0"> ,&nbsp;&nbsp;
      {{item.CartItemDetail.TotalSessions}}
      <span *ngIf="item?.CartItemDetail?.BookingBehavior != BookingBehaviorConst.RentalBooking"> {{item.CartItemDetail.TotalSessions>1 ? ('Sessions' | translate) : ('Session' | translate)}}</span>
      <span *ngIf="item?.CartItemDetail?.BookingBehavior == BookingBehaviorConst.RentalBooking"> {{item.CartItemDetail.TotalSessions>1 ? ('Rental items' | translate) : ('Rental item' | translate)}}</span></div>
      <span *ngIf="item?.CartItemDetail?.BookingBehavior == BookingBehaviorConst.RentalBooking" class="icon-respos-info-2 pointer pl-1"
                      [autoClose]="'outside'" container="body" [ngbPopover]="RentalItemsNames"
                      popoverClass="payment-details" placement="auto" (click)="viewRentalItemNames($event, item)"></span>
  </div>
  <div class="cart__item__header-right">
    <div class="cart__item-avatar" [ngStyle]="{'background-color': '' | randomColor}">
      {{item.CartItemDetail.PartyName | initialFormatter}}
    </div>
    <div class="cart__item__availability" *ngIf="!item.isLoading">
      <ng-container *ngIf="locked">
        <div *ngIf="!item.isExpired">
          <span class="available" *ngIf="item.isAvailable ; else unavailable">{{'Available' |
            translate}}</span>
            <ng-template #unavailable>
              <span *ngIf="item.StandBy" class="standby-booking">{{'Standby' | translate}}</span>
              <!-- <span *ngIf="!item.standbyOpenBooking && !item.StandBy && item.lockExpired" class="btn-text" (click)="lockCartItem(item, idx)">{{'Check Availability'}}</span> -->
              <span *ngIf="!item.standbyOpenBooking && !item.StandBy">{{'Unavailable' | translate}}</span>
            </ng-template>
        </div>
        <span *ngIf="item.isExpired">{{'Expired' |
          translate}}</span>
      </ng-container>
    </div>
    <div class="cart__item__status-loading" *ngIf="item.isLoading">
      <div class="dot1"> </div>
      <div class="dot2"></div>
      <div class="dot3"></div>
    </div>
  </div>
  <div class="cart__item-total">
    <div class="cart__item-total-label"
      *ngIf="item.RatePlanTotal && !item.RatePlanTotal.TotalDepositAmount; else cartCard">{{'findTotal' | translate}}
      <span class="pl-1 icon-res-payment-Paid pointer"
        *ngIf="(item.RatePlanTotal.TotalTaxAmount && item.RatePlanTotal.TotalTaxAmount > 0) || (item.RatePlanTotal.TotalAddonAmount && item.RatePlanTotal.TotalAddonAmount > 0)"
        [autoClose]="'outside'" container="body" [ngbPopover]="AmountSplitList"
        popoverClass="payment-details" placement="auto" (click)="updatePopOver($event,item)"></span>
        <span class="icon-offer pl-1" *ngIf="item.CartItemDetail?.rateSummary?.OfferCode" [matTooltip]="item.CartItemDetail?.rateSummary?.OfferCode"></span>
    </div>
    <ng-template #cartCard>
      <div class="cart__item-total-label">
        {{'findTotal' | translate}}
        <span class="pl-1 icon-res-payment-Paid pointer"
        [autoClose]="'outside'" container="body" [ngbPopover]="AmountSplitList"
        popoverClass="payment-details" placement="auto" (click)="updatePopOver($event,item)"></span>
        <span class="icon-offer pl-1" *ngIf="item.CartItemDetail?.rateSummary?.OfferCode" [matTooltip]="item.CartItemDetail?.rateSummary?.OfferCode"></span>
      </div>
   </ng-template>
    <div class="cart__item-total-value"
      *ngIf="item.RatePlanTotal && !item.RatePlanTotal.TotalDepositAmount">
      <!-- <span *ngIf="!locked && !item.RatePlanTotal">Loading...</span> -->
      <span *ngIf="item.RatePlanTotal">
        {{cs.operationCurrency}}
        {{((item.RatePlanTotal.TotalRatePlan || 0) +(item.NegotiatedAmount || 0) + (item.RatePlanTotal.OverAllNegotiation || 0) +(item.RatePlanTotal.TotalServiceChargeAmount || 0) + (item.RatePlanTotal.TotalAddonServiceChargeAmount || 0) + (item.RatePlanTotal.TotalTaxOnServiceChargeAmount || 0) + (item.RatePlanTotal.TotalAddonTaxOnServiceChargeAmount || 0) +
        (item.RatePlanTotal.TotalTaxAmount || 0) + (item.RatePlanTotal.TotalAddonAmount || 0) + (item.RatePlanTotal.TotalAddonTaxAmount || 0)) | currencyDecimalFormatter
        }}
      </span>
    </div>
    <div class="cart__item-total-value"
      *ngIf="!item.RatePlanTotal">
      <!-- <span *ngIf="!locked && !item.RatePlanTotal">Loading...</span> -->
      <span *ngIf="item.CartItemDetail?.rateSummary">
        {{cs.operationCurrency}}
        {{((item.CartItemDetail.rateSummary.Amt || 0) + item.CartItemDetail.rateSummary.TaxAmt + item.CartItemDetail.rateSummary.SCAmt + item.CartItemDetail.rateSummary.TaxOnSCAmt + item.CartItemDetail.rateSummary.Discount) | currencyDecimalFormatter
        }}
      </span>
    </div>
    <div class="cart__item-total-label"
      *ngIf="item.RatePlanTotal && item.RatePlanTotal.TotalDepositAmount">
      {{'totalDepositValue' | translate}}</div>
    <div class="cart__item-total-value"
      *ngIf="item.RatePlanTotal && item.RatePlanTotal.TotalDepositAmount">
      <!-- <span *ngIf="!locked && !item.RatePlanTotal">Loading...</span> -->
      <span>{{cs.operationCurrency}} 
        {{((item.RatePlanTotal.TotalDepositAmount || 0)) | currencyDecimalFormatter}}</span>
    </div>
  </div>
  <div class="cart__item-footer">
    <div class="cart__item-remove" (click)="removeItem($event, item)">{{'Remove' | translate}}</div>
    |
    <div class="cart__item-edit" (click)="editCartData($event, item)">{{'edit' | translate}}</div>
  </div>
</ng-template>


<ng-template #AmountSplitList>
  <div>
    <!-- <ng-scrollbar> -->
    <div class="">
      <div class="reservation-notes" style="">
        <div class=" reservation-notes__header seat-popover-header" style="display: flex;height: 40px;align-items: center;padding: 0 5px;">
          {{'payments' | translate}}
        </div>
        <div class="p-2" style="
       flex-direction: column;
    display: flex;
    gap: 2px;
   ">
          <div class="reservation-notes__notes-details seat-border-color" style="    ">
            <span class="" >{{'subTotal' | translate}}<span class="seat-error-messages">{{packageDiscount !==0 ? '*' :''}}</span>: </span>
            <span class="seat-secondary-text" style="font-weight: 600;">
              {{this.cs.settings.value.General.OperationCurrency}} {{
subTotal |
              currencyDecimalFormatter
              }}
            </span>
          </div>
          <div class="reservation-notes__notes-details seat-border-color" *ngIf="tax > 0" style="   ">
            <span class="" >{{'tax' | translate}}<span class="seat-error-messages">{{packageDiscount !==0 ? '*' :''}}</span>: </span>
            <span class="seat-secondary-text" style="font-weight: 600;">
              {{this.cs.settings.value.General.OperationCurrency}} {{
tax |
              currencyDecimalFormatter
              }}
            </span>
          </div>

          <div class="reservation-notes__notes-details seat-border-color" *ngIf="packageDiscount != 0" style="   ">
            <span class="" >{{'packageDiscountAmt' | translate}}: </span>
            <span class="seat-secondary-text" style="font-weight: 600;">
              {{this.cs.settings.value.General.OperationCurrency}} {{packageDiscount | currencyDecimalFormatter }}
            </span>
          </div>




          <div class="reservation-notes__notes-details seat-border-color" *ngIf="serviceCharges > 0" style="  ">
            <span class="" >{{'serviceCharges' | translate}}: </span>
            <span class="seat-secondary-text" style="font-weight: 600;">
              {{this.cs.settings.value.General.OperationCurrency}} {{
serviceCharges |
              currencyDecimalFormatter
              }}
            </span>
          </div>
          <div class="reservation-notes__notes-details seat-border-color" *ngIf="serviceChargesWithTax > 0" style="   ">
            <span class="" >{{'serviceChargesTaxWithPlural' | translate}}: </span>
            <span class="seat-secondary-text" style="font-weight: 600;">
              {{this.cs.settings.value.General.OperationCurrency}} {{serviceChargesWithTax | currencyDecimalFormatter }}
            </span>
          </div>
          <div class="reservation-notes__notes-details seat-border-color" *ngIf="negotiatedAmount > 0" style=" ">
            <span class="seat-secondary-text" >{{'NegotiatedAmount' | translate}}: </span>
            <span class="seat-secondary-text" style="font-weight: 600;">
              {{this.cs.settings.value.General.OperationCurrency}} {{negotiatedAmount | currencyDecimalFormatter }}
            </span>
          </div>
          <div class="reservation-notes__notes-details seat-border-color"  *ngIf="promoDiscount" style="    
            ">
            <span class="seat-secondary-text" >{{'discountText' | translate}}: </span>
            <span class="seat-secondary-text" style="font-weight: 600;">
              {{this.cs.settings.value.General.OperationCurrency}} {{promoDiscount | currencyDecimalFormatter }}
            </span>
          </div>
          <div *ngIf="packageDiscount != 0">
            <hr class="mb-1 mt-1">
            <span>(<span class="seat-error-messages">{{packageDiscount !==0 ? '*' :''}}</span>{{'inclPackageDiscount' | translate}})</span>
          </div>
        </div>
      </div>
    </div>
    <!-- </ng-scrollbar> -->
  </div>
</ng-template>

<ng-template #RentalItemsNames>
  <div class=" reservation-notes__header seat-popover-header rental-item-detailed-view">
    {{'selectedItems' | translate}}
  </div>
  <ul *ngFor="let item of rentalItemNameList" class="rental-item-detailed-view__item">
    <li>
      <div>{{ 'Name' | translate }}: <span class="rental-item-detailed-view__item--value">{{ item?.Name }}</span></div>
      <div>{{ 'Quantity' | translate }}: <span class="rental-item-detailed-view__item--value">{{ item?.Quantity }}</span></div>
      <div>
        {{ 'Pickup' | translate }}: <span class="rental-item-detailed-view__item--value">{{ item?.Pickup?.Mode }}, </span><span class="rental-item-detailed-view__item--value">{{ item?.Pickup?.Location }}</span>
      </div>
      <div>
        {{ 'Drop' | translate }}: <span class="rental-item-detailed-view__item--value">{{ item?.Drop?.Mode }}, </span><span class="rental-item-detailed-view__item--value">{{ item?.Drop?.Location }}</span>
      </div>
    </li>
  </ul>
</ng-template>