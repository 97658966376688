import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivityType, ClassType, NotificationPreference, PartyState, PartyType, PricingBy, Status, buttonTypes } from '../constants/commonenums';
import { PartyService } from '../services/party.service';
import { CacheService } from '@app/core/services/cache.service';
import { MatAccordion } from '@angular/material/expansion';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { TranslateService } from '@ngx-translate/core';
import { PageMethod, PartyPrepaymentState } from '../models/InputContact';
import { Utilities } from '../utilities/utilities';
import { BookedSessionDTO, PartyEmailSendBehavior, SettingsDTO } from '../models/RestaurantDTO';
import { AppService } from '@app/app.service';
import sortBy from 'lodash/sortBy';
import { DynamicFormComponent } from '../dynamicform/dynamic-form/dynamic-form.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import DisplayOptions from '@data/activitiesFilterOptions.json';
import _ from 'lodash';
import { BookingHandlerService } from './booking-handler.service';
import { ActionSupportService } from '@app/activities-timeline/components/privatelesson-booking-detail/action-support.service';
import { PartyPaymentStatePipe } from '../pipes/PartyPaymentState.pipe';
import { merge } from 'rxjs';

@Component({
  selector: 'app-booking-manager',
  templateUrl: './booking-manager.component.html',
  styleUrls: ['./booking-manager.component.scss']
})
export class BookingManagerComponent implements OnInit {
  @Input() filterData: any;
  parties: any[] = [];
  standByParties: any[] = [];
  @ViewChild(MatAccordion) accordion: MatAccordion;
  filterList: any[] = [];
  showFilter: boolean = false;
  applyButton: ButtonValue;
  cancelButton: ButtonValue;
  closeButton: ButtonValue;
  blockButton: ButtonValue;
  bookButton: ButtonValue;
  unBlockButton: ButtonValue;
  sessionDetail: any;
  isRetailEnabled: boolean = false;
  enableWristband: boolean = false;
  _settings: SettingsDTO
  mappedGuestFields: any;
  noActionRequired: boolean = false;
  searchconfig: FieldConfig[];
  searchAttendeesText: string = '';
  subscriptions: Subscription = new Subscription();
  displayOptions = DisplayOptions;
  allowToBook: boolean = false;
  @ViewChild('searchConfigForm', { static: true }) searchConfigForm: DynamicFormComponent;
  @ViewChild('searchConfigFormContainer', { static: true }) searchFormElm: ElementRef;
  optionsForFilter = [PartyState.Pending, PartyState.Seated, PartyState.Cancelled, PartyState.Left];
  showReservationTimeColumn: boolean = false;
  showAssetIcon: boolean = false;
  _MESSAGES = MESSAGES;
  actionEventChanges: String;
  allReservations: any[] = [];
  expandMode: boolean = true;
  PartyState = PartyState;
  assignStaffButton: ButtonValue;

  constructor(
    public cs: CacheService,
    public partyService: PartyService,
    private ts: TranslateService,
    private appService: AppService,
    private bookingHandler: BookingHandlerService,
    public actionSupportService: ActionSupportService,
    public partyPaymentPipe: PartyPaymentStatePipe
  ) { }

  // Lifecycle Hooks
  ngOnInit(): void {
    this._settings = this.cs.settings?.value;
    this.noActionRequired = this.cs.isIframeEnabled;
    this.bookingHandler.openedTogglesIds = [];
    this.isRetailEnabled = Utilities.isRetailEnabledProperty(this._settings.General.RetailIntegrationDTO);
    this.enableWristband = this._settings?.MerchantSettings?.EnableWristband?.SettingValue === "true" || localStorage.getItem('enableWristband') === 'true';
    this.setMappedCustomGuestFields(this.filterData?.ActivityId, this.filterData?.isOpenBooking);
    this.bookingActionsButtonConfig();
    this.loadBookings();
    this.loadButtonconfig();
    this.loadBookingChanges();
  }

  ngAfterViewInit() {
    this.formValueChanges();
  }

  // Primary Functional Methods
  loadBookings() {
    let loadedParties = [];
    let allBookings = [...this.partyService.Parties$?.value, ...this.partyService.StandbyParties$?.value];
    this.showReservationTimeColumn = this.filterData.isOpenBooking || this.filterData.isPrivateLessonBooking;
    if (this.filterData.ClassType == ClassType.Session) {
      loadedParties = allBookings?.filter(party => {
        if (party.BookedSessions && party.SpecialMealId == this.filterData.ActivityId) {
          let ids = party.BookedSessions.map(field => field.ActivitySessionId);
          return ids.includes(this.filterData.SessionId);
        } else return false;
      });
    } else if (this.filterData.ClassType == ClassType.Class) {
      loadedParties = allBookings?.filter(party => party.SessionGroupId == this.filterData.SessionGroupId);
    } else if (this.filterData.isOpenBooking && !this.filterData?.allOpenBooking) {
      loadedParties = allBookings?.filter(party => this.filterData.partyIds.includes(party.Id));
    } else if (this.filterData.isPrivateLessonBooking) {
      loadedParties = allBookings?.filter(party => this.filterData.partyIds.includes(party.Id));
    } else if (this.filterData?.allOpenBooking) {
      loadedParties = allBookings?.filter(party => !party.SpecialMealId);
    }
    let reservations = [];
    loadedParties.forEach(party => {
      reservations.push(this.partyMapper(party));
    });
    this.allReservations = _.cloneDeep(reservations);
    let _standBy = [];
    let _parties = [];
    this.allReservations?.forEach(party => {
      if (party.Type == PartyType.StandBy) {
        _standBy.push(party);
      } else if (party.Type == PartyType.Reservation) {
        _parties.push(party);
      }
    })
    this.standByParties = _standBy;
    this.parties = _parties;
  }

  loadFilter() {
    let activities = this.cs.settings.value.SpecialMeals;
    let dayOfWeek = this.filterData.date?.getDay() || new Date().getDay();
    let _filterList = [];
    activities.forEach(activity => {
      if (activity.StatusCode == Status.Approved) {
        let data = {
          Name: activity?.Name,
          selected: false,
          Type: activity.ClassType == PricingBy.Class ? ActivityType.ClassBooking : ActivityType.SessionBooking,
          Id: activity.Id,
          ActivitySessions: this.getActivitySession(activity?.ActivitySessions || [], dayOfWeek),
        };
        _filterList.push(data);
      }
    });
    this.filterList = _filterList;
  }

  applyFilter() {
    let _parties = this.partyService.Parties$?.value;
    let _filerList = this.filterList.filter(item => item.selected);
    let activitySessionIds = this.filterList.map(item => item.ActivitySessions)?.flat()?.filter(session => session.selected)?.map(x => x.ActivitySessionId);
    let filteredParty = _parties.filter(item1 => _filerList.some(item2 => item2.Id === item1.SpecialMealId));
    filteredParty = filteredParty.filter(item1 => item1.BookedSessions?.find(item2 => activitySessionIds?.includes(item2.ActivitySessionId)));
    this.parties = sortBy(filteredParty, ['name']);
  }

  // Event Handlers and UI Methods
  selectSlot(slot) {
    slot['selected'] = !slot.selected;
  }

  selectActivity(value, activity) {
    activity.selected = value || false;
  }

  viewSessionDetails(session: any) {
    // Implement as needed
  }

  resetFilter() {
    // Implement as needed
  }

  close() {
    this.partyService.viewBookings = false;
    this.appService.sessionDataFilter = null;
  }

  // Helper and Utility Methods
  getActivitySession(ActivitySessions, _dayOfWeek) {
    return ActivitySessions?.filter(session => session.Dayofweek == _dayOfWeek);
  }

  loadButtonconfig() {
    this.searchconfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this.ts.instant('searchAttendee'),
        class: 'class-detail__search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearch.bind(this)
      }
    ];

    this.applyButton = {
      type: buttonTypes.actionPrimarySmall,
      label: this.ts.instant('ApplyFilter'),
      customclass: 'action-bar__add-class-btn',
    };
    this.cancelButton = {
      type: buttonTypes.actionSecondarySmall,
      label: this.ts.instant('Cancel'),
      customclass: 'action-bar__add-class-btn',
    };
    this.closeButton = {
      type: buttonTypes.actionSecondarySmall,
      label: this.ts.instant('Close'),
      customclass: 'action-bar__add-class-btn',
    };
    this.displayOptions.forEach(item => {
      item.isSelected = this.optionsForFilter.includes(item.id) ? true : false;
    });
    this.assignStaffButton = { label: this._MESSAGES.labels.AssignStaff, type: buttonTypes.actionSecondarySmall, customclass: 'class-detail__actions__btn width-auto', name: this._MESSAGES.labels.AssignStaff }

  }

  bookingActionsButtonConfig() {
    this.bookButton = {
      type: buttonTypes.actionSecondarySmall,
      label: this._MESSAGES.labels.book,
      disbaledproperity: false,
      customclass: 'action-bar__add-class-btn',
    };
    this.blockButton = {
      label: this._MESSAGES.labels.block,
      type: buttonTypes.actionFullOrange,
      customclass: 'class-detail__actions__btn',
      disbaledproperity: false,
    }
    this.unBlockButton = {
      label: this._MESSAGES.labels.unBlock,
      type: buttonTypes.actionSecondaryOrange,
      customclass: 'class-detail__actions__btn',
      disbaledproperity: false,
    }
  }

  bookingActions(eventName: string) {
    //Action name pass to child
    this.actionEventChanges = eventName;
    setTimeout(() => {
      this.actionEventChanges = null;
    }, 500)
  }
  bookingActionsController(sessionDetail: any) {
    this.sessionDetail = sessionDetail;
    this.showAssetIcon = sessionDetail.AllowReAssignAsset
    this.bookButton.disbaledproperity = !sessionDetail['AllowBook'];
    this.blockButton.disbaledproperity = !sessionDetail['AllowBlock'];
    this.unBlockButton.disbaledproperity = !sessionDetail['AllowUnblock'];
  }
  clearSearch(event) {
    this.searchConfigForm.form.get('searchText').setValue('');
  }

  partyMapper(selectedParty: any) {
    let party = Object.assign({}, selectedParty);

    let bookedSession = selectedParty.BookedSessions?.find(session => session.ActivitySessionId == this.filterData.SessionId && new Date(this.filterData?.weeklyViewDate || this.filterData.date).getDate() == new Date(session.BookedDate).getDate() ) || null;
    party.GuestName = (party?.Contact?.FirstName || '') + ' ' + (party?.Contact?.LastName || '');
    party.LocationName = party.TableIds?.length ? (party?.TableNames.length ? party?.TableNames[0] : this.cs.locationListForMerchant.find(table => table.id == party.TableIds[0])?.value) : this.ts.instant('TBA');
    party.State = this.bookingHandler.setBookedSessionState(party.State, bookedSession);
    party.showStandByActions = party.Type == PartyType.StandBy;
    party.Type = selectedParty.Type;
    party.showCheckInWithDues = this.showCheckInWithDuesOperation(party.State, party, bookedSession);
    party.showOptions = !this.noActionRequired;
    party.showCheckoutWithDues = this.showCheckoutWithDuesOperation(party.State, party, bookedSession);
    party.showCollectCharge = this.bookingHandler.showCollectChargeOperation(selectedParty.PrepaymentState, bookedSession, party.State) && !this.noActionRequired
    party.showRefund =  ( party.BookingContacts?.length > 1 && party.State == PartyState.Cancelled ) ? false : this.bookingHandler.showRefundOperation(selectedParty.PrepaymentState , bookedSession) && !this.noActionRequired;
    party.refundWithCancel = party.BookingContacts?.length == 1 ? this.bookingHandler.showRefundWithCancel(party.showRefund, party.State) : false;
    party.showCheckIn = this.showCheckInOperation(party.State, bookedSession);
    party.showCheckedOut = this.showCheckOutOperation(party.State, bookedSession)
    party.showCancelled = this.showCancelOption(party.State, party, bookedSession);
    party.showEditable = this.showEditableOption(party.State, bookedSession);
    party.showShopping = this.showShoppingOption(party.State, bookedSession);
    party.showWaivedOff = this.showWaivedOffOperation(party.State, null, party);
    party.showResend = this.showConfirmationPrompt(party.State, null);
    party.CheckInText = selectedParty.BookingContacts?.length > 1 ? this.ts.instant('CheckInAll') : this.ts.instant('CheckIn');
    party.CheckOutText = selectedParty.BookingContacts?.length > 1 ? this.ts.instant('CheckOutAll') : this.ts.instant('CheckOut');
    party.UnseatText = selectedParty.BookingContacts?.length > 1 ? this.ts.instant('UnseatAll') : this.ts.instant('Unseat');
    party.PackageName = this.cs.availablePackages.find(pack => pack.Id == party.PackageId)?.Name;
    party.BookedSessionId = bookedSession?.Id;
    party.isLocationGroupMapped = bookedSession?.Locations?.length || null;
    party.printWristBand = false;
    party.expanded = false;
    party.selected = false;
    party.showCheckOut = party.State === PartyState.Seated;
    party.showWristband = this.enableWristband && party.State != PartyState.Cancelled && party.State != PartyState.Left;
    party.isWristBandIssued = party?.GuestItineraryMapping?.some(item => item.WristbandId) || false;
    let _bookingContactIds: number[] = party.BookingContacts.map(({ Id }) => Id);
    if (party.isWristBandIssued) {
      party.IsActiveWristBand = party?.GuestItineraryMapping?.some(item => party.BookedSessionId === item.BookedSessionId && _bookingContactIds.includes(item.BookingContactId) && item.WristbandId && item.StatusCode === 10);
      party.WristbandId = party?.GuestItineraryMapping?.find(item => party.BookedSessionId === item.BookedSessionId && _bookingContactIds.includes(item.BookingContactId) && item.WristbandId && item.StatusCode === 10)?.WristbandId;

      if (!party.WristbandId) {
        party.WristbandId = _.cloneDeep(party?.GuestItineraryMapping || []).reverse().find(item => party.BookedSessionId === item.BookedSessionId && _bookingContactIds.includes(item.BookingContactId) && item.WristbandId)?.WristbandId;
      }
    }

    party['mappedContact'] = this.bookingContactMapper(party);
    if (party.showCancelled) {
      party.showCancelled = party.mappedContact?.filter(item => !this.showCancelOption(item.State, party, null) && item.State !== PartyState.Cancelled)?.length == 0;
    }
    party.disableCheckbox = party.State === PartyState.Cancelled || party.State === PartyState.Left || party.mappedContact?.length == 0;
    return party;
  }

  bookingContactMapper(selectedParty) {
    let _contacts = [];
    if (selectedParty.BookingContacts?.length > 1) {
      let _bookedSessions = selectedParty.BookedSessions?.find(field => field.ActivitySessionId == this.filterData.SessionId && new Date(this.filterData?.weeklyViewDate || this.filterData.date).getDate() == new Date(field.BookedDate).getDate() ) || null;
      selectedParty['PageRequest'] = []
      selectedParty.BookingContacts?.forEach(bookingContact => {
        let _mappedStates = bookingContact?.BookingContactStates?.find(_contact => _contact.BookedSessionId == _bookedSessions?.Id || !_contact.BookedSessionId) || null;
        if (_mappedStates) {
          bookingContact['bookingStates'] = _mappedStates;
          bookingContact['State'] = _mappedStates.State;
          bookingContact['Type'] = selectedParty.Type;
          bookingContact['PrepaymentState'] = _mappedStates.PaymentStatus;
          bookingContact['primaryGuest'] = bookingContact.ContactId === selectedParty.Contact?.Id ? 0 : 1;
          bookingContact['showCancelled'] = false;
          bookingContact['showEditable'] = false;
          bookingContact['showShopping'] = this.showShoppingOption(_mappedStates.State, null);
          bookingContact['showCheckInWithDues'] = this.showCheckInWithDuesOperation(_mappedStates.State, bookingContact, null);
          bookingContact['showOptions'] = !this.noActionRequired;
          bookingContact['showCheckoutWithDues'] = this.showCheckoutWithDuesOperation(_mappedStates.State, bookingContact, null);
          bookingContact['showCheckIn'] = this.showCheckInOperation(_mappedStates.State, null);
          bookingContact['showCheckedOut'] = this.showCheckOutOperation(_mappedStates.State, null);
          bookingContact['showCollectCharge'] = this.bookingHandler.showCollectChargeOperation(_mappedStates.PaymentStatus, null, bookingContact['State']) && !this.noActionRequired;
          bookingContact['showRefund'] = this.bookingHandler.showRefundOperation(_mappedStates.PaymentStatus) && !this.noActionRequired;
          bookingContact['refundWithCancel'] = this.bookingHandler.showRefundWithCancel(bookingContact['showRefund'], bookingContact['State']);
          bookingContact['showStandByActions'] = false;
          bookingContact['noChildActions'] = selectedParty.Type == PartyType.StandBy;
          bookingContact['contactStatesId'] = _mappedStates?.Id || null;
          bookingContact['CheckInText'] = this.ts.instant('CheckIn');
          bookingContact['CheckOutText'] = this.ts.instant('CheckOut');
          bookingContact['UnseatText'] = this.ts.instant('Unseat');
          bookingContact['disableCheckbox'] = _mappedStates.State == PartyState.Cancelled || _mappedStates.State == PartyState.Left;
          bookingContact['WaiverFormURL'] = bookingContact?.WaiverFormURL || null;
          bookingContact['ConfirmationCode'] = selectedParty.ConfirmationCode || null;
          bookingContact['RestaurantId'] = selectedParty.RestaurantId || Utilities.RestaurantId();
          bookingContact['EnablePaging'] = this.getPageRequestDetails(selectedParty, bookingContact).isValid;
          bookingContact['BookingContactAddonItems'] = selectedParty?.BookingContactAddonItems?.filter(item => item?.BookingContactId == _mappedStates?.BookingContactId || item?.BookingContactId == bookingContact?.bookingStates?.BookingContactId) || null;
          bookingContact['isWristBandIssued'] = selectedParty?.GuestItineraryMapping?.some(item => item.ContactId == bookingContact.ContactId && item.WristbandId) || false;
          if (bookingContact['isWristBandIssued']) {
            let [WristbandId, status] = this.getContactWristbandId(bookingContact, selectedParty?.GuestItineraryMapping, selectedParty.BookedSessionId);
            bookingContact['WristbandId'] = WristbandId;
            bookingContact['IsActiveWristBand'] = status;
          }
          // update party values below
          selectedParty['State'] = _bookedSessions ? _bookedSessions.SessionState : selectedParty.State;
          selectedParty['PageRequest'].push(this.getPageRequestDetails(selectedParty, bookingContact));
          selectedParty['selected'] = false;
          selectedParty['PrepaymentState'] = _bookedSessions ? _bookedSessions.PaymentStatus : selectedParty.PrepaymentState;
          bookingContact['showWaivedOff'] = this.showWaivedOffOperation(bookingContact['State'], _mappedStates, selectedParty);
          bookingContact['showResend'] = this.showConfirmationPrompt(bookingContact['State'], _mappedStates);
          selectedParty['OverBooked'] = _bookedSessions ? _bookedSessions.OverBooked : selectedParty.OverBooked;
          _contacts.push(bookingContact)
        }
      });
      return _contacts ? _.orderBy(_contacts, ['primaryGuest', 'Id']) : [];
    } else if (selectedParty.BookingContacts?.length == 1) {
      selectedParty['PageRequest'] = null
      let _bookedSessions = selectedParty.BookedSessions?.find(field => field.ActivitySessionId == this.filterData.SessionId && new Date(this.filterData?.weeklyViewDate || this.filterData.date).getDate() == new Date(field.BookedDate).getDate()) || null;
      this.getPartyCommonStatusMapping(selectedParty, _bookedSessions, selectedParty.BookingContacts[0], null);
      return [];
    } else {
      return [];
    }
  }

  getContactWristbandId(bookingContact, GuestItineraryMapping, bookedSessionId) {
    let wristbandGuestItineraryMappings = GuestItineraryMapping?.filter(item => item.BookingContactId == bookingContact.Id && item.BookedSessionId == bookedSessionId
       && item.WristbandId && item.StatusCode === 10);
    let guestItinerary;
    if (wristbandGuestItineraryMappings?.length > 1) {
      guestItinerary = wristbandGuestItineraryMappings.find(({ StatusCode }) => StatusCode === 10);
    }
    if (guestItinerary) {
      return [guestItinerary.WristbandId, true];
    }
    return wristbandGuestItineraryMappings?.length
      ? [wristbandGuestItineraryMappings[wristbandGuestItineraryMappings.length - 1].WristbandId, wristbandGuestItineraryMappings[wristbandGuestItineraryMappings.length - 1].StatusCode === 10]
      : [null, false];
  }

  getPartyCommonStatusMapping(selectedParty, _bookedSessions, bookingContact, _mappedStates) {
    selectedParty['OverBooked'] = _bookedSessions ? _bookedSessions.OverBooked : selectedParty.OverBooked;
    selectedParty['State'] = _bookedSessions ? _bookedSessions.SessionState : selectedParty.State;
    selectedParty['selected'] = false;
    selectedParty['PrepaymentState'] = _bookedSessions ? _bookedSessions.PaymentStatus : selectedParty.PrepaymentState;
    bookingContact['showWaivedOff'] = this.showWaivedOffOperation(bookingContact['State'], _mappedStates, selectedParty);
    selectedParty['WaiverFormURL'] = bookingContact.WaiverFormURL || null;
  }
  getPageRequestDetails(selectedParty, bookingContact) {
    let _isValid = false;
    let _pageMethod = selectedParty.NotificationPreference == NotificationPreference.GuestPageMethod ? bookingContact.PreferredPageMethod : selectedParty.PageMethod;
    if ((_pageMethod == PageMethod.Email || _pageMethod == PageMethod.Email2) && (bookingContact.EmailAddress)) {
      _isValid = true;
    } else if ((_pageMethod == PageMethod.Sms || _pageMethod == PageMethod.Sms2) && bookingContact.PhoneNumber) {
      _isValid = true;
    } else if ((_pageMethod == PageMethod.VoiceCall || _pageMethod == PageMethod.VoiceCall2) && bookingContact.PhoneNumber) {
      _isValid = true;
    } else {
      _isValid = false;
    }
    return { ContactId: bookingContact.ContactId, PageMethod: _pageMethod, isValid: _isValid };
  }

  showCancelOption(State, party, bookedSession) {
    let _state = bookedSession ? bookedSession.SessionState : State;
    return _state === PartyState.Pending && !party.showStandByActions && !this.noActionRequired;
  }

  showEditableOption(State, bookedSession) {
    let _state = bookedSession ? bookedSession.SessionState : State;
    return _state !== PartyState.Cancelled && _state !== PartyState.Left && !this.noActionRequired;
  }

  showCheckInOperation(State, bookedSession) {
    let _state = bookedSession ? bookedSession.SessionState : State;
    return _state === PartyState.Pending;
  }

  showCheckOutOperation(State, bookedSession) {
    let _state = bookedSession ? bookedSession.SessionState : State;
    return _state === PartyState.Seated;
  }

  showCheckInWithDuesOperation(State, party, bookedSession) {
    let _state = bookedSession ? bookedSession.SessionState : State;
    let paymentState = bookedSession ? bookedSession.PaymentStatus : party.PrepaymentState
    return _state === PartyState.Pending && this.partyService.validateAvailabilityForCheckInWithDues(paymentState);
  }
  showCheckoutWithDuesOperation(State, party, bookedSession) {
    let _state = bookedSession ? bookedSession.SessionState : State;
    let paymentState = bookedSession ? bookedSession.PaymentStatus : party.PrepaymentState
    return _state === PartyState.Seated && this.partyService.validateAvailabilityForCheckInWithDues(paymentState);
  }

  showWaivedOffOperation(State, bookedSession, party) {
    let _state = bookedSession ? bookedSession.State : State;
    let paymentState = bookedSession ? bookedSession.PaymentStatus : party.PrepaymentState;
    let paymentStatus = this.partyPaymentPipe.transform((bookedSession || party), paymentState, (bookedSession ? party : null));
    return _state !== PartyState.Cancelled && paymentStatus !== 'Waived__Off' && paymentStatus !== 'NA' && paymentState !== PartyPrepaymentState.None && !this.noActionRequired;
  }

  showConfirmationPrompt(State, bookedSession) {
    let _state = bookedSession ? bookedSession.State : State;
    let ConfirmationEmailSendBehavior = this._settings.General.HostConfirmationEmailSendBehavior != PartyEmailSendBehavior.Never;
    return _state !== PartyState.Cancelled;
  }

  showShoppingOption(State, bookedSession) {
    let _state = bookedSession ? bookedSession.SessionState : State;
    return this.isRetailEnabled && _state !== PartyState.Cancelled && _state !== PartyState.Left && !this.noActionRequired;
  }

  setMappedCustomGuestFields(id: number, isOpenBooking: boolean) {
    this.mappedGuestFields = [];
    if (this._settings) {
      let activityMapping = this._settings?.customGuestFieldsMappingValidations.find(({ ActivityId }) => ActivityId === id);

      if (activityMapping) {
        this.mappedGuestFields = activityMapping?.CustomFields.map(({ FieldId }) => FieldId);
      }

      if (isOpenBooking) {
        this.mappedGuestFields = this._settings?.customGuestFieldsMappingValidations?.flatMap(
          ({ CustomFields }) => CustomFields.map(({ FieldId }) => FieldId)
        );
      }
    }
  }

  filterReservations(item) {
    item.isSelected = !item.isSelected;
    let selectedOptions = this.displayOptions.filter(x => x.isSelected).map(x => x.id);

    if (selectedOptions && selectedOptions.length == 0) {
      item.isSelected = !item.isSelected;
      return;
    }
    this.filterReservationsBasedOnselection(selectedOptions);
  }

  filterReservationsBasedOnselection(selectedOptions) {
    this.optionsForFilter = [];
    selectedOptions.forEach(option => {
      switch (option) {
        case PartyState.Pending: {
          this.optionsForFilter.push(PartyState.Pending);
          break;
        }
        case PartyState.Seated: {
          this.optionsForFilter.push(PartyState.Seated);
          break;
        }
        case PartyState.Left: {
          this.optionsForFilter.push(PartyState.Left);
          break;
        }
        case PartyState.Cancelled: {
          this.optionsForFilter.push(PartyState.Cancelled);
          break;
        }
        case 5: {
          this.optionsForFilter.push(5); // Filter added for Exclusive Booking
          break;
        }
      }
    });
  }

  formValueChanges() {
    this.focusFirstInput();
    this.subscriptions.add(this.searchConfigForm?.form?.valueChanges
      .pipe(debounceTime(100),
        distinctUntilChanged())
      .subscribe((val: any) => {
        this.searchAttendeesText = val.searchText;
      }));
  }

  focusFirstInput(): void {
    // Get the native element of the parent container
    const parentElement = this.searchFormElm?.nativeElement;

    // Find the first input element within the parent container
    const firstInput = parentElement?.querySelector('input');

    // Focus the first input element if it exists
    if (firstInput) {
      firstInput.focus();
    }
  }

  loadBookingChanges() {
    this.subscriptions.add(
      merge(
        this.partyService.Parties$,
        this.partyService.StandbyParties$
      )
        .pipe(debounceTime(100)) // Add debounceTime to control rapid emissions
        .subscribe(() => {
          this.loadBookings();
          this._settings = { ...this._settings }
        })
    );

    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      if (sett) {
        this._settings = sett;
      }
    }));
  }

  assignInstructor() {
    let currentAssignedInstructor = this.allReservations[0]?.ServerId;
    this.actionSupportService.showAssignInstructor(this.allReservations[0], currentAssignedInstructor);
  }
}

const MESSAGES = {
  labels: {
    cancelThisActivity: "CancelThisSession",
    unBlock: "Unblock",
    book: "book",
    block: "blockbuttontext",
    ReAssignAsset: "ReAssignAsset",
    AssignStaff: "AssignStaff"
  }
}
