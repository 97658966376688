import { PartyType } from "../constants/commonenums";

export enum AuditLogOperationOptions {
  None = 0,
  Simplified = 1
}

export enum AuditableActionType {
  Create,
  Convert,
  Update,
  Remove,
  Cancel,
  NoShow,
  UndoNoShow,
  Seat,
  Unseat,
  Reseat,
  CompleteVisit,
  AssignTables,
  Move,
  SetStatus,
  OverrideDepartureTime,
  ChangePosition,
  SetPhoto,
  RemovePhoto,
  ChangeContact,
  OpenedSlotCheckSuccess,
  Merge,
  Confirm,
  Enable,
  Disable,
  SetWebReservable,
  SetNotWebReservable,
  ChargeNoShowFee,
  IgnoreNoShowFee,
  RefundNoShowFee,
  RefundSpecialMeal,
  ChargeSpecialMeal,
  AuthorizeSpecialMeal,
  IgnoreSpecialMealFee,
  RetailPayment,
  ActivityHeadLinesUpdated,
  ActivityHeadLinesRemoved,
  ActivityBlockUpdated
}

export enum AuditableEntityType {
  Party,
  Contact,
  ManualSlot,
  DefaultSlot,
  Shift,
  AutoSlot,
  TableBlockingRule,
  BillingPlan,
  BookedSession,
  BookedAddon,
  SecondaryContact,
  ContactCustomGuestField,
  Activity,
  Rateplan,
  Addon,
  ActivityCustomizations,
  Offer,
  AdvancedSettings,
  StaffBlockorBreakHours
}

export enum AuditLogItemDetailType {
  Table,
  Personal,
  Session,
  Payment,
  Other,
  LogisticPreference
}

export class SimpleAuditLogItemDetailDTO {
  public Name: string = null;
  public Value: string = null;
  public OriginalValue: string = null;
  public Type: AuditLogItemDetailType = null;
}

export class SimpleAuditLogItemDTO {
  public CreatedAt: Date = null;
  public Action: string = null;
  public ActionType: AuditableActionType = null;
  public Entity: string = null;
  public EntityType: AuditableEntityType = null;
  public User: string = null;
  public Details: SimpleAuditLogItemDetailDTO[] = [];
}

export class RestaurantAuditLogItemDTO {
  PartyId: number;
  PartyFullName: string;
  ActionDescription: string;
  PartyType: PartyType;
  ActionType: AuditableActionType
  Time: Date;
}
