import { Pipe, PipeTransform } from "@angular/core";
import { CacheService } from "@app/core/services/cache.service";

@Pipe({
  name: "negtiationRateFormatter",
})
export class NegotiationRateFormater implements PipeTransform {

  constructor(private CacheService: CacheService) {}

  transform(number: any): any {
    return Math.abs(number).toFixed(this.CacheService.getNumberOfFractionalDigits());
  }
}
