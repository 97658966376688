import { environment } from '@environments/environment';

export const urlConfig = {
  logoUrl: 'assets/images/Agilysys_Reserve_login-bg.png', // rGuest logo image url
  logoUrl_black: 'assets/images/Seat_logo_login_transparent.png',
  logoUrl_dark: 'assets/images/logo_lightBG.svg',
  noReservationsUrl: 'assets/images/no-reservation.png',
  loadRestaurantUrl: environment.BaseUrl + '/Restaurant/load?restaurantId=',
  localizeSettingsUrl: environment.BaseUrl + '/Restaurant/localize?',
  getDashboardDataURL: environment.BaseUrl + '/Restaurant/GetDashboardData',
  unlockRestaurantUrl: environment.BaseUrl + '/Merchant/unlock',
  authenticationUrl: environment.BaseUrl + '/Merchant/Authenticate',
  VerifyUserEmailOrMerchantIdUrl: environment.BaseUrl + '/Merchant/VerifyUserEmailOrMerchantId',
  UnlockWithAzureADB2CTokenUrl: environment.BaseUrl + '/Merchant/UnlockWithAzureADB2CToken',
  authenticationManagerUrl: environment.BaseUrl + '/Merchant/AuthenticateManager',
  loginUrl: environment.BaseUrl + '/Host/Login',
  logoutUrl: environment.BaseUrl + '/Host/Logout?restaurantId=',
  getMenuNameUrl: 'assets/data/sidemenu.json', // side menu json url
  englishLabelStringsURL: 'assets/i18n/en.json',
  frenchLabelStringURL: 'assets/i18n/fr.json',
  getwaitListsURL: environment.BaseUrl + '/WalkIns/GetWalkIns/restaurant/',
  getTableDetails: 'assets/tabledetails.json', // file path for getting table-details json
  restaurantsettingsURL: 'assets/restaurantsettings.json',
  getTimeDetailsUrl: 'assets/time_details.json',
  updateReservationURL: environment.BaseUrl + '/PartyReservation/Update',
  financialEffectForUpdatedReservation: environment.BaseUrl + '/PartyReservation/GetFinancialEffectForUpdatedReservationDTO',
  financialEffectForUpdatedActivity: environment.BaseUrl + '/Activities/GetFinancialEffectForUpdatedActivityDTO',
  getguestInfoUrl: environment.BaseUrl + '/Contact/get',
  getSlotsOperation: environment.BaseUrl + '/Restaurant/GetSlots',
  createManualSlot: environment.BaseUrl + '/Slot/Manual/Create',
  updateManualSlot: environment.BaseUrl + '/Slot/Manual/Update',
  removeManualSlot: environment.BaseUrl + '/Slot/Manual/Remove',
  setWebReservableManualSlot: environment.BaseUrl + '/Slot/Manual/SetWebReservable',
  getPredefinedNotesUrl: environment.BaseUrl + '/Reservation/GetPredefinedNotes/restaurant/',
  getContacts: environment.BaseUrl + '/Contact/GetContactList', // + this.restaurantId;
  getExContactSuggestionUrl: environment.BaseUrl + '/Contact/GetCommonContact',
  getCommonProfilepreference: environment.BaseUrl + '/Contact/GetCommonGuestProfilePreference',
  getExContactExSuggestionUrl: environment.BaseUrl + '/Contact/GetSuggestionsfromCommonGuestProfile',
  addContactsUrl: environment.BaseUrl + '/Contact/create',
  updateContactsUrl: environment.BaseUrl + '/Contact/Update',
  deleteContactsUrl: environment.BaseUrl + '/contacts/delete?restaurantId=', // + this.restaurantId;
  HideContactsFromSearchUrl: environment.BaseUrl + '/Contact/HideContactsFromSearch', // + this.restaurantId;
  getHistoryUrl: environment.BaseUrl + '/Contact/GetHistory',
  GetGuestItineraryUrl: environment.BaseUrl + '/Contact/GetItineraryByProfile',
  NotifyItinerary: environment.BaseUrl + '/Contact/NotifyItinerary',
  getWaiverFormUrl: environment.BaseUrl + '/DataMagine/GetWaiverFormDownload',
  getPartyAuditLogUrl: environment.BaseUrl + '/Audit/GetPartyAuditLog',
  getActivityAuditLogUrl: environment.BaseUrl + '/Audit/GetActivityAuditLog',
  partyStatusesGetURL: environment.BaseUrl + '/Party/GetStatuses?restaurantId=',
  overrideDepartureTimeUrl: environment.BaseUrl + '/Party/OverrideSeatingPartyDepartureTime',
  getServersUrl: environment.BaseUrl + '/SettingsServers/GetServers',
  getServerInfoUrl: 'assets/server_info.json',
  getSeatRecords: 'assets/reservations.json', // file path for getting seat-Records json
  getSeatDetails: 'assets/seatdetails.json', // file path for getting seat-details json
  getListDetails: 'assets/list.json', // file path for getting list-details json
  updateContactIsVIPIsFavoriteUrl: environment.BaseUrl + '/Contact/UpdateContactIsVIPIsFavorite/', // + this.restaurantId;
  PostOperationUrl: environment.BaseUrl + '/PostOperation',
  GetOperationUrl: environment.BaseUrl + '/GetOperation/requeststring/',
  // selectedGuestUrl: "assets/selectedguest.json",
  loadReservationsURL: environment.BaseUrl + '/Restaurant/GetReservations',
  loadDashboardData: environment.BaseUrl + '/Restaurant/GetDashboardData',
  // getSeatDetailsURL : environment.BaseUrl + "/Reservation/GetReservations/restaurant/",//file path for getting seat-details json
  // messagesURL : "assets/guestmessages.json",
  createOpenBookingURL: environment.BaseUrl + '/Activities/BookMultipleActivity',
  updateOpenBookingURL: environment.BaseUrl + '/Activities/UpdateOpenOrPrivateLessonBooking',
  UpdateStandByOpenBookingURL: environment.BaseUrl +'/Activities/UpdateStandByOpenBooking',
  createPrivateLessonBookingURL: environment.BaseUrl + '/Activities/CreateStandByPrivateLessonActivity',
  updatePrivateLessonBookingURL: environment.BaseUrl + '/Activities/UpdateStandByActivity',
  createSessionBookingURL: environment.BaseUrl + '/Activities/BookHostActivity',
  updateSessionBookingURL: environment.BaseUrl + '/Activities/UpdateHostActivity',
  cancelClassOrSessionBookingURL: environment.BaseUrl + '/Activities/CancelClassOrSession?restaurantId=',
  GetCancellationChargeURL: environment.BaseUrl + '/Activities/GetCancellationCharge?restaurantId=',
  createReservationURL: environment.BaseUrl + '/PartyReservation/Create',
  WildSearchEngageMemberURL: environment.BaseUrl + '/Contact/WildSearchEngageMember',
  GetEngageMemberByCardId: environment.BaseUrl + '/Contact/GetEngageMemberByCardId',
  lockTablesURL: environment.BaseUrl + '/SlotAuto/AcquireMultiple',
  releaseMultipleURL: environment.BaseUrl + '/SlotAuto/ReleaseMultiple',
  lockSessionsURL: environment.BaseUrl + '/Activities/LockSessions',
  unlockSessionsURL: environment.BaseUrl + '/Activities/UnLockSessions',
  GetRatePlanCalculatedURL:environment.BaseUrl + '/Party/GetRatePlanCalculated',
  GetTaxCalculatedURL: environment.BaseUrl + '/Party/GetTaxCalculated',
  GetSlotsForTablesURL: environment.BaseUrl + '/Restaurant/GetSlotsForTables',
  GetSlotsForActivityURL: environment.BaseUrl + '/NonOpenBookingSlot/GetAvailability',
  CheckInActivity: environment.BaseUrl + '/Activities/CheckInActivity',
  CheckOutActivity: environment.BaseUrl + '/Activities/CheckOutActivity',
  UndoCheckInActivity: environment.BaseUrl + '/Activities/ReInstateCheckedInReservation',
  CheckInOpenBookingURL: environment.BaseUrl + '/Activities/CheckInOpenBooking',
  CheckOutOpenBookingURL: environment.BaseUrl + '/Activities/CheckOutOpenBooking',
  CalculateAdditionalChargeURL: environment.BaseUrl + '/Activities/CalculateAdditionalCharge',
  CheckInPrivateLessonBookingURL: environment.BaseUrl + '/Activities/CheckInPrivateLessonBooking',
  CheckOutPrivateLessonBookingURL: environment.BaseUrl + '/Activities/CheckOutPrivateLessonBooking',
  CancelClassActivity: environment.BaseUrl + '/Lesson/CancelActivity',
  CancelSessionActivity: environment.BaseUrl + '/Lesson/CancelSession',
  seatingEstimateURL: environment.BaseUrl + '/Restaurant/GetSeatingEstimate',
  seatPartyURL: environment.BaseUrl + '/Party/Seat',
  reseatPartyURL: environment.BaseUrl + '/Party/Reseat',
  cancelReservationURL: environment.BaseUrl + '/PartyReservation/Cancel?restaurantId=',
  completeReservationURL: environment.BaseUrl + '/Reservation/CompleteReservation',
  createWalkInURL: environment.BaseUrl + '/PartyWalkIn/Create',
  updateWalkInURL: environment.BaseUrl + '/PartyWalkIn/Update',
  removeWalkInURL: environment.BaseUrl + '/PartyWalkIn/Remove?restaurantId=',
  seatedPartyStatusURL: environment.BaseUrl + '/Party/SetStatus',
  movePartyURL: environment.BaseUrl + '/Party/Move',
  updateContactPreferences: environment.BaseUrl + '/Contact/UpdateContactPreferredCommunication',
  postPartyMessageURL: environment.BaseUrl + '/PartyMessage/Page',
  postPartyPageBulkMessageURL: environment.BaseUrl + '/PartyMessage/PageBulk',
  sendConfirmationURL: environment.BaseUrl + '/PartyMessage/SendConfirmaionEmail',
  getStandaloneTableFutureStateURL: environment.BaseUrl + '/StandaloneTables/GetFutureState',
  postPageMultiplePartiesMessageURL: environment.BaseUrl + '/Party/Message/PageMultipleParties',
  clearPartyURL: environment.BaseUrl + '/Party/CompleteVisit',
  unseatPartyURL: environment.BaseUrl + '/Party/Unseat',
  noShowPartyURL: environment.BaseUrl + '/Party/NoShow',
  SignalrURL: environment.BaseUrl.slice(0, environment.BaseUrl.lastIndexOf('/')),
  getDayNote: environment.BaseUrl + '/Restaurant/Note/Get',
  setDayNote: environment.BaseUrl + '/Restaurant/Note/Set',
  slotAutoLockURL: environment.BaseUrl + '/SlotAuto/Lock',
  slotManualURL: environment.BaseUrl + '/SlotManual/Lock',
  slotManualUnlockURL: environment.BaseUrl + '/SlotManual/Unlock',
  slotAutoUnlockURL: environment.BaseUrl + '/SlotAuto/Unlock',
  getManualSlotAuditLogUrl: environment.BaseUrl + '/Audit/GetManualSlotAuditLog',
  offDutyURL: environment.BaseUrl + '/StandaloneTables/BlockAllForServer',
  assignServers: environment.BaseUrl + '/StandaloneTables/AssignServers',
  setServers: environment.BaseUrl + '/SettingsServers/Set',
  switchServers: environment.BaseUrl + '/StandaloneTables/ReassignServers',
  clearShift: environment.BaseUrl + '/StandaloneTables/BlockAll',
  getNoShowFeeURL: environment.BaseUrl + '/PartyReservation/GetNoShowFeeAmount',
  updateFloorPlan: environment.BaseUrl + '/SettingsLayout/Update',
  createFloorPlan: environment.BaseUrl + '/SettingsLayout/Create',
  preRemoveFloorPlan: environment.BaseUrl + '/SettingsLayout/PreRemove',
  removeFloorPlan: environment.BaseUrl + '/SettingsLayout/Remove',
  synchServers: environment.BaseUrl + '/SettingsServers/SyncServers',
  getBlockingRule: environment.BaseUrl + '/SettingsTableBlockingRules/Get',
  getTBRAuditLogUrl: environment.BaseUrl + '/Audit/GetTableBlockingRuleAuditLog',
  createBlockingRule: environment.BaseUrl + '/SettingsTableBlockingRules/Create',
  deleteBlockingRule: environment.BaseUrl + '/SettingsTableBlockingRules/Delete',
  updateBlockingRule: environment.BaseUrl + '/SettingsTableBlockingRules/Update',
  unblockBlockingRule: environment.BaseUrl + '/SettingsTableBlockingRules/Unblock',
  createSchedule: environment.BaseUrl + '/Schedule/Create',
  updateSchedule: environment.BaseUrl + '/Schedule/Update',
  getSchedule: environment.BaseUrl + '/Schedule/Get',
  getSchedules: environment.BaseUrl + '/Schedule/GetSchedules',
  getServerSchedules: environment.BaseUrl + '/Schedule/GetServerSchedules',
  deleteSchedule: environment.BaseUrl + '/Schedule/Remove',
  getShiftStats: environment.BaseUrl + '/RestaurantReport/ShiftStats',
  resetAllTables: environment.BaseUrl + '/Party/CompleteAllVisits',
  getForDate: environment.BaseUrl + '/Shift/GetForDate',
  assignTables: environment.BaseUrl + '/Party/AssignTables',
  getChanges: environment.BaseUrl + '/ChangeTracking/GetChanges',
  undoNoShow: environment.BaseUrl + '/PartyWalkIn/UndoNoShow',
  getPendingChargeURL: environment.BaseUrl + '/Party/GetPendingChargeParties',
  chargePartyURL: environment.BaseUrl + '/Party/ChargeParty',
  additionalChargePartyURL: environment.BaseUrl + '/Party/AdditionalChargeParty',
  getPartyPaymentStausURL: environment.BaseUrl + '/Party/GetPartyPaymentStaus',
  getPaymentSummaryByGuest: environment.BaseUrl + '/Party/GetPaymentSummaryByGuest',
  ignoreChargingPartyURL: environment.BaseUrl + '/Party/IgnoreChargingParty',
  ignoreChargingPartiesURL: environment.BaseUrl + '/Party/IgnoreChargingParties',
  getRolesURL: environment.BaseUrl + '/SettingsPermissions/Get',
  getUserRoleURL: environment.BaseUrl + '/Settings/Roles/Get',
  getFormCodeURL: environment.BaseUrl + '/Settings/FormCode/Get',
  updateFormCodeURL: environment.BaseUrl + '/Settings/FormCode/Update',
  deleteFormCodeURL: environment.BaseUrl + '/Settings/FormCode/Delete',
  addFormCodeURL: environment.BaseUrl + '/Settings/FormCode/Add',
  setFormCodeStatus: environment.BaseUrl + '/Settings/FormCode/SetFromCodeStatus',
  addUserRoleURL: environment.BaseUrl + '/Settings/Roles/Set',
  updateUserRoleURL: environment.BaseUrl + '/Settings/Roles/Update',
  deleteUserRoleURL: environment.BaseUrl + '/Settings/Roles/Delete',
  clonePermissinosURL: environment.BaseUrl + '/SettingsPermissions/ClonePermissions',
  customGuestFieldMappingsURL: environment.BaseUrl + '/CustomGuestFieldValidation/SaveCustomGuestField',
  getHostsURL: environment.BaseUrl + '/Settings/Hosts/Get',
  getOneHostsURL: environment.BaseUrl + '/Settings/Hosts/GetOne',
  createHostURL: environment.BaseUrl + '/Settings/Hosts/Create',
  updateHostURL: environment.BaseUrl + '/Settings/Hosts/Update',
  removeHostURL: environment.BaseUrl + '/Settings/Hosts/Remove',
  addPermissionURL: environment.BaseUrl + '/SettingsPermissions/AddPermissions',
  removePermissionURL: environment.BaseUrl + '/SettingsPermissions/RemovePermissions',
  getPartiesForBilling: environment.BaseUrl + '/Party/GetPartiesByContact',
  refundParty: environment.BaseUrl + '/Party/RefundParty',
  refundOverpayment: environment.BaseUrl + '/Party/RefundOverpayment',
  undoNoShowReservation: environment.BaseUrl + '/PartyReservation/UndoNoShow',
  CompleteAllVisits: environment.BaseUrl + '/Party/CompleteAllVisits',
  getPartyDetails: environment.BaseUrl + '/Party/Get',
  changeContact: environment.BaseUrl + '/Party/ChangeContact',
  UpdateSeatingPartySize: environment.BaseUrl + '/Party/UpdateSeatingPartySize',
  OpenCheck: environment.BaseUrl + '/Party/OpenCheck',
  MoveCheck: environment.BaseUrl + '/Party/MoveCheck',
  heartBeatUrl: environment.BaseUrl + '/Instance/Heartbeat',
  applicationVersion: environment.BaseUrl + '/Instance/ApplicationVersion',
  downloadReleaseNotes: environment.BaseUrl + '/Instance/DownloadReleaseNotes',
  switchPropertyDashboardDataUrl: environment.BaseUrl + '/Merchant/GetDashboard?restaurantId',
  getSchedulerJobs: environment.ReportsApiUrl.slice(0, environment.ReportsApiUrl.lastIndexOf('/')) + '/ReportScheduler/GetJobs',
  enableJob: environment.ReportsApiUrl.slice(0, environment.ReportsApiUrl.lastIndexOf('/')) + '/ReportScheduler/EnableJob',
  createJob: environment.ReportsApiUrl.slice(0, environment.ReportsApiUrl.lastIndexOf('/')) + '/ReportScheduler/CreateJob',
  removeJob: environment.ReportsApiUrl.slice(0, environment.ReportsApiUrl.lastIndexOf('/')) + '/ReportScheduler/RemoveJob',
  deleteJob: environment.ReportsApiUrl.slice(0, environment.ReportsApiUrl.lastIndexOf('/')) + '/ReportScheduler/DeleteJob',
  updateJob: environment.ReportsApiUrl.slice(0, environment.ReportsApiUrl.lastIndexOf('/')) + '/ReportScheduler/UpdateJob',
  forceRun: environment.ReportsApiUrl.slice(0, environment.ReportsApiUrl.lastIndexOf('/')) + '/ReportScheduler/ForceRun',
  getAvailableReports: environment.ReportsApiUrl.slice(0, environment.ReportsApiUrl.lastIndexOf('/')) + '/Configuration/GetAvailableReports',
  removeStandbyUrl: environment.BaseUrl + '/PartyStandBy/Remove?restaurantId=',
  createStandbyUrl: environment.BaseUrl + '/PartyStandBy/Create?restaurantId=',
  updateStandbyUrl: environment.BaseUrl + '/PartyStandBy/Update?restaurantId=',
  convertToReservationUrl: environment.BaseUrl + '/PartyStandBy/ConvertToReservation?restaurantId=',
  getCoverReport: environment.BaseUrl + '/RestaurantReport/Cover',
  reservationToStandbyUrl: environment.BaseUrl + '/PartyReservation/ConvertToStandBy?restaurantId=',
  waitTimesReportUrl: environment.BaseUrl + '/WaitTime/Get',
  setManualUrl: environment.BaseUrl + '/WaitTime/SetManual',
  resetManualUrl: environment.BaseUrl + '/WaitTime/ResetManual',
  getHotelListURL: environment.BaseUrl + '/Hotel/Get',
  getConciergeListURL: environment.BaseUrl + '/Concierge/Get',
  getConciergeURL: environment.BaseUrl + '/Concierge/GetOne',
  postHotelListURL: environment.BaseUrl + '/Hotel/UpdateBatch',
  postConciergeListURL: environment.BaseUrl + '/Concierge/UpdateBatch',
  postConciergeNewPhoneNumberURL: environment.BaseUrl + '/ConciergePhoneNumber/Create',
  postConciergeOldPhoneNumberURL: environment.BaseUrl + '/ConciergePhoneNumber/Update',
  getConciergePhoneNumberURL: environment.BaseUrl + '/ConciergePhoneNumber/Get',
  deleteConciergePhoneNumberURL: environment.BaseUrl + '/ConciergePhoneNumber/Delete',
  uploadLayoutImagesURL: environment.BaseUrl + '/FloorPlan/UploadLayoutImages',
  getLayoutImagesURL: environment.BaseUrl + '/FloorPlan/GetImages?restaurantId=',
  deleteLayoutImagesURL: environment.BaseUrl + '/FloorPlan/DeleteLayoutImages',
  uploadObjectImagesURL: environment.BaseUrl + '/FloorPlan/UploadObjectImages',
  deleteObjectImagesURL: environment.BaseUrl + '/FloorPlan/DeleteObjectImages',
  uploadCustomImagesURL: environment.BaseUrl + '/FloorPlan/UploadCustomImages',
  deleteCustomImagesURL: environment.BaseUrl + '/FloorPlan/DeleteCustomImages',
  saveEmailTemplateURL: environment.BaseUrl + '/SettingsEmailParser/SaveOTAEmailTemplates',
  getEmailTemplateURL: environment.BaseUrl + '/SettingsEmailParser/GetOTAEmailTemplates',
  getRestaurantAuditLogURL: environment.BaseUrl + '/Audit/GetRestaurantAuditLog',
  getTableAvailablityURL: environment.BaseUrl + '/Restaurant/GetTableAvailablity',
  getLatestRevisionUrl: environment.BaseUrl + '/ChangeTracking/GetLatestRevision',
  addGroupUrl: environment.signalR_AzureFunction_group_url + '/addToGroup',
  addMerchantRestGroupUrl: environment.signalR_AzureFunction_group_url + '/addToMerchantRestaurantGroup',
  removeGroupUrl: environment.signalR_AzureFunction_group_url + '/removeFromGroup',
  switchScreenUrl: environment.UseStandaloneSignalrService ? environment.StandaloneSignalRUrl + '/api/switchScreen' : environment.signalR_AzureFunction_group_url + '/switchScreen',
  getChangeSetForScreenNameUrl: environment.BaseUrl + '/ChangeTracking/GetChangesByScreen',
  resetRestaurantCache: environment.BaseUrl + '/Restaurant/resetRestaurantCache',
  loginViaTokenUrl: environment.BaseUrl + '/Host/LoginWithAuthorization',
  getAllBookings: environment.BaseUrl + '/Activities/GetAllBookedActivities',
  cancelActivityURL: environment.BaseUrl + '/Activities/CancelOpenBooking?restaurantId=',
  cancelMulipleActivityURL: environment.BaseUrl + '/Activities/CancelMultipleOpenBooking?restaurantId=',
  sendConfirmationForActivitiesURL: environment.BaseUrl + '/PartyMessage/SendConfirmaionEmailForActivities',
  UpdateFeedback: environment.BaseUrl + '/Contact/SetFeedBack',
  ConvertStandBytoReservationActivity: environment.BaseUrl + '/Activities/ConvertStandBytoReservationActivity?restaurantId=',
  rejectStandbyActivity: environment.BaseUrl + '/Activities/RejectStandByActivity',
  updateStandbyActivity: environment.BaseUrl + '/Activities/UpdateStandByActivity',
  getCartList: environment.BaseUrl + '/Cart/GetCartItems',
  lockCartItems: environment.BaseUrl + '/Cart/LockCart',
  extendCartLock: environment.BaseUrl + '/Booking/HoldItem',
  lockCartForMultipleProperties: environment.BaseUrl + '/Activities/LockCartForMultipleProperties',
  unlockCartItems: environment.BaseUrl + '/Cart/UnlockCart',
  unlockCartForMultipleProperties: environment.BaseUrl + '/Cart/UnlockCartForMultipleProperties',
  deleteCartItems: environment.BaseUrl + '/Cart/DeleteItemsFromCartOperation',
  addBookingtoCart: environment.BaseUrl + '/Cart/AddItemToCart',
  updateCart: environment.BaseUrl + '/Cart/UpdateCart',
  proceedCartBooking: environment.BaseUrl + '/Cart/RegisterCartBookActivityAttempts',
  rejectStandbyPrivateActivity: environment.BaseUrl + '/Activities/RejectPrivateLessonBooking',
  registerBookActivityAttempt: environment.BaseUrl + '/Reservation/RegisterBookActivityAttempt',
  getMultipleReservationAttemptStates: environment.BaseUrl + '/Reservation/GetMultipleReservationAttemptStates',
  getReservationAttemptState: environment.BaseUrl + '/Reservation/GetReservationAttemptState',
  updateReservationAttempt: environment.BaseUrl + '/Reservation/UpdateReservationAttempt',
  EngageBookingSummary: environment.BaseUrl + '/Contact/EngageBookingSummary',
  bookCartItems: environment.BaseUrl + '/Cart/BookCartItems',
  updateCartItems: environment.BaseUrl + '/Cart/UpdateCart',
  createRetail: environment.BaseUrl + '/Retail/CreateOrderDetails',
  getRetailTransaction: environment.BaseUrl + '/Retail/GetRetailTransaction',
  getRetailGuestInfo: environment.BaseUrl + '/Retail/GetRetailGuestInfo',
  saveRetailOrderDetails: environment.BaseUrl + '/Retail/CreateOrderDetails',
  cancelRetailOrderDetails: environment.BaseUrl + '/Retail/CancelReservation',
  financialEffectForCancelledActivity: environment.BaseUrl + '/Activities/GetFinancialEffectForCancelReservationDTO',
  setRetailTransaction: environment.BaseUrl + '/Retail/SetRetailTransaction',
  bookCartForMultipleProperties: environment.BaseUrl + '/Cart/BookCartForMultipleProperties',
  RegisterMultiplePropertyActivityReservationAttempts: environment.BaseUrl + '/Cart/RegisterMultiplePropertyActivityReservationAttempts',
  Purchase: environment.BaseUrl + '/Cart/Purchase',
  retailCheckInOrCheckOut:environment.BaseUrl + '/Retail/CheckInOrCheckOut',
  getDatesOverview: environment.BaseUrl + '/Restaurant/GetDatesOverview',
  savePackage: environment.BaseUrl + '/Package/SavePackage?restaurantId=',
  getPackageList: environment.BaseUrl + '/Package/GetPackages?restaurantId=',
  getPackageById: environment.BaseUrl + '/Package/GetPackage?restaurantId=',
  removePackage: environment.BaseUrl+ '/Package/Remove?restaurantId=',
  SetPackageStatus:environment.BaseUrl + '/Package/SetPackageStatus?restaurantId=',
  GetAllBookedPackageActivities: environment.BaseUrl + '/Package/GetAllBookedPackageActivities?propertyId=',
  CancelAllBookedPackageActivities: environment.BaseUrl + '/Package/CancelAllBookedPackageActivities?propertyId=',
  BlockSession: environment.BaseUrl + '/SettingsActivitieBlock/SaveActivitiesBlock',
  getActivitiesBlockData: environment.BaseUrl + '/SettingsActivitieBlock/GetAll',
  DeleteActivityBlock: environment.BaseUrl + '/SettingsActivitieBlock/DeleteActivityBlock',
  unblockSession: environment.BaseUrl + '/SettingsActivitieBlock/Unblock',
  SaveActivitiesAttribute: environment.BaseUrl + '/SettingsActivitiesAttributes/SaveActivitiesAttribute',
  DeleteActivityAttribute : environment.BaseUrl + '/SettingsActivitiesAttributes/Delete',
  GetLessonByIdAndRestaurant: environment.BaseUrl + '/ActivityCreation/GetLessonByIdAndRestaurant',
  GetAvailableServersByRestaurant: environment.BaseUrl + '/ActivityCreation/GetAvailableServers',
  SaveLesson: environment.BaseUrl+'/ActivityCreation/SaveLesson',
  SaveSessionGroups: environment.BaseUrl + '/ActivityCreation/SaveSessionGroups',
  GetLessonDetailsByRestaurant: environment.BaseUrl + '/ActivityCreation/GetLessonDetailsByRestaurant',
  RemoveLesson : environment.BaseUrl + '/ActivityCreation/Remove',
  CheckInstructorAvailability: environment.BaseUrl + '/ActivityCreation/CheckInstructorAvailability',
  GetActivityTemplates:environment.BaseUrl + '/ActivityCreation/GetActivityTemplates',
  GetShiftsForDateAuditLog: environment.BaseUrl + '/Audit/GetShiftsForDateAuditLog',
  DisableEnableAutoSlot: environment.BaseUrl +'/Slot/Auto/Disable',
  SetWebReservable: environment.BaseUrl +'/Shift/SetWebReservable',
  IsWebReservable: environment.BaseUrl +'/Shift/IsWebReservable',
  GetShiftAuditLog: environment.BaseUrl + '/Audit/GetShiftAuditLog',
  getretailOutlets: environment.BaseUrl + '/Retail/GetOutlets',
  modifySession: environment.BaseUrl + '/ActivityCreation/ModifySession',
  cancelSession: environment.BaseUrl + '/ActivityCreation/CancelSession',
  getAuditLog: environment.BaseUrl + '/Audit/GetAuditLog',
  // chartDetailsUrl:  "assets/chartdetails.json",
  retailPartyPayment : environment.BaseUrl + '/Retail/RetailPartyPayment', 
  getLanguages: environment.BaseUrl + '/Instance/GetLanguage',
  getLanguageConstants: environment.BaseUrl + '/Instance/GetLanguageConstant',
  slotAvailability: environment.BaseUrl + '/CrsAvailability/Get',
  crsSlotLock: environment.BaseUrl + '/CrsLock/Acquire',
  getFinancialEffectForUpdatedReservation: environment.BaseUrl + '/CrsReservation/GetFinancialEffectForUpdatedReservationDTO',
  crsReservationAttempt: environment.BaseUrl + '/CrsReservation/RegisterReservationAttempt',
  crsUpdateReservationAttempt: environment.BaseUrl + '/CrsReservation/UpdateReservationAttempt',
  crsUpdate: environment.BaseUrl + '/CrsReservation/Update',
  searchGuest: environment.BaseUrl + '/CrsGuest/Get',
  GetReservationAttemptState:  environment.BaseUrl + '/CrsReservation/GetReservationAttemptState',
  // viewUserDetailsUrl: environment.BaseUrl + "/contacts/get/restaurant/", // + this.restaurantId + "/contact/"; // + this.contactId;
  // getTableDetails : "assets/tabledetails.json",//file path for getting table-details json,
  GetSpecialMealByIdAndRestaurant: environment.BaseUrl + '/SpecialMeals/GetSpecialMealByIdAndRestaurant',
  GetSpecialMealsByRestaurant: environment.BaseUrl + '/SpecialMeals/GetSpecialMealsByRestaurant',
  DeleteSpecialMeal: environment.BaseUrl + '/SpecialMeals/DeleteSpecialMeal',
  SaveSpecialMeal: environment.BaseUrl + '/SpecialMeals/SaveSpecialMeal',
  AddOrModifyAppsettings:environment.BaseUrl + '/Appsettings/AddOrModifyAppsettings',
  DeleteAppsettings:environment.BaseUrl + 'Appsettings/DeleteAppsettings',
  // getTableDetails : "assets/tabledetails.json",//file path for getting table-details json
  GetPartyCartInfoURL: environment.BaseUrl + '/Party/GetPartyCartInfo',
  VCartHold:environment.BaseUrl + '/Booking/HoldItem',
  VCartBookCartForMultipleProperties: environment.BaseUrl + '/Booking/BookCartForMultipleProperties',
  VCartDeleteCartItems: environment.BaseUrl + '/Booking/DeleteItemsFromCart',
  getCommonGuestProfile: environment.BaseUrl + '/Booking/get',
  RegisterMultipleReservationAttempts: environment.BaseUrl + '/CrsCart/RegisterMultipleReservationAttempts',
  GetAppSettings: environment.BaseUrl + '/Appsettings/GetAppsettings',
  getRatePlanReCalculation: environment.BaseUrl + '/Party/RatePlanReCalculation',
  CalculateDiscount: environment.BaseUrl + '/Party/CalculateDiscount',
  financialEffectUrl: environment.BaseUrl + '/Activities/FinancialEffect',
  validateBooking: environment.BaseUrl + '/Party/ValidateBooking',
  CancelBookingFinancialEffectUrl: environment.BaseUrl + '/Activities/CancelBookingFinancialEffect',
  VCartSyncContact : environment.BaseUrl + '/Booking/SyncContact',
  GetBookedAddonItems: environment.BaseUrl + '/Party/GetAddonsBookedInfo',
  GetMerchantSettings: environment.BaseUrl + '/Settings/Merchantsetting/GetMerchantSetting',
  SetMerchantSettings: environment.BaseUrl + '/Settings/Merchantsetting/SetMerchantSetting',
  GetSeatingAreas: environment.BaseUrl+ '/SeatingAreas/Get',
  RemoveSeatingAreas: environment.BaseUrl + '/SeatingAreas/Remove',
  SetSeatingAreas: environment.BaseUrl + '/SeatingAreas/Set',
  getAllOffers: environment.BaseUrl + '/SettingsOffers/GetAllOffers',
  getOfferByOfferId: environment.BaseUrl + '/SettingsOffers/GetOffer',
  enableOffer: environment.BaseUrl + '/SettingsOffers/EnableOffer',
  saveOffer: environment.BaseUrl + '/SettingsOffers/SaveOffer',
  ApplyPackage: environment.BaseUrl + '/Party/ApplyPackage',
  ClearPackage: environment.BaseUrl + '/Party/ClearPackage',
  getLocationGroups: environment.BaseUrl + '/Settings/locationgroup/Get',
  saveLocationGroups: environment.BaseUrl + '/Settings/locationgroup/Save',  
  GetEligibleOffers: environment.BaseUrl +'/SettingsOffers/GetEligibleOffers',
  SetBookingState: environment.BaseUrl + '/Activities/SetBookingState',
  ReAssignAsset: environment.BaseUrl + '/Activities/ReAssignAsset',
  CreateCustomBlockorBreakHours: environment.BaseUrl + '/Schedule/CustomBreakHoursForStaff',
  RemoveBreakorBlockHours: environment.BaseUrl + '/Schedule/RemoveCustomBreakHoursForStaff',
  GetAllBreakorBlockHours: environment.BaseUrl + '/Schedule/BreakHoursForStaff',
  ValidateBookingType: environment.BaseUrl + '/Contact/ValidateBookingType',
  getPartyStayInfo: environment.BaseUrl + '/Contact/GetPartyStayInfo',
  getInstructorsToAssign: environment.BaseUrl + '/Activities/GetAvailableStaffForBooking',
  assignInstructor: environment.BaseUrl + '/Activities/AssignStaffToBooking',
  getCardInfoByContact: environment.BaseUrl + '/Contact/CreditCardInformation',
  getLocationByRevenueCenter: environment.BaseUrl + '/Rentals/GetLocationsByRevenueCentre',
  getFilterMasterData: environment.BaseUrl + '/Rentals/GetRentalCategories',
  getAssetAvailableAssets: environment.BaseUrl + '/Rentals/GetRentalAvailableAssetSlots',
  holdAssets: environment.BaseUrl + '/Rentals/HoldRentalAsset',
  releaseAssets: environment.BaseUrl + '/Rentals/ReleaseRentalHold',
  syncRentalItems: environment.BaseUrl + '/Rentals/SyncRentalItems',
  settingsButtonClick: environment.BaseUrl + '/Settings/Merchantsetting/HandleAdvanceSettingsButtonClick',
  getAllRentalItems: environment.BaseUrl + '/Rentals/AvailableRentalAssets',
  setFavouritesOnRentalItems: environment.BaseUrl + '/Rentals/SetFavouritesOnRentalItems',
  getRentalInventoryItems: environment.BaseUrl + '/Rentals/GetRentalInventoryItems',
  ComtrolGenerateKey: 'WristBand/GenerateKey'
}

export enum loaderDisabledUrls {
  getDashboardDataURL,
  addPermissionURL,
  removePermissionURL,
  // addContactsUrl,
  // updateContactsUrl,
  updateContactIsVIPIsFavoriteUrl,
  ignoreChargingPartyURL,
  // chargePartyURL,
  sendConfirmationURL,
  slotAutoLockURL,
  slotAutoUnlockURL,
  slotManualURL,
  slotManualUnlockURL,
    seatingEstimateURL,
  getLayoutImagesURL,
  getTableAvailablityURL,
  getHistoryUrl,
  addGroupUrl,
  addMerchantRestGroupUrl,
  getLatestRevisionUrl,
  removeGroupUrl,
  getChangeSetForScreenNameUrl,
  getPartyPaymentStausURL,
  loadReservationsURL,
  loadRestaurantUrl,
  logoutUrl,
  postPartyMessageURL,
  lockCartItems,
  getBlockingRule,
  unlockCartItems,
  unlockSessionsURL,
  releaseMultipleURL,
  EngageMemberAccrual,
  GetBookedAddonItems,
  ComtrolGenerateKey
};
export enum urlWithLoaders {
  getLayoutImagesURL,
  loadRestaurantUrl,
  getCartList,
  logoutUrl,
  loginUrl,
  updateReservationAttempt,
  createOpenBookingURL,
  lockCartForMultipleProperties,
  RegisterMultiplePropertyActivityReservationAttempts,
  GetRatePlanCalculatedURL,
  getRatePlanReCalculation,
  GetReservationAttemptState,
  updateSessionBookingURL,
  cancelRetailOrderDetails,
  bookCartForMultipleProperties,
  GetSlotsForActivityURL,
  GetSlotsForTablesURL,
  getExContactSuggestionUrl,
  getguestInfoUrl,
  updateHostURL,
  getHostsURL,
  getContacts,
  

}

export enum urlWithRetailHeaders {
  CalculateDiscount,
  GetRatePlanCalculatedURL,
  GetTaxCalculatedURL,
  RegisterMultiplePropertyActivityReservationAttempts,
  bookCartForMultipleProperties,
  financialEffectForUpdatedActivity,
  updateOpenBookingURL,
  updateSessionBookingURL,
  additionalChargePartyURL,
  lockCartItems,
  LockCartForMultipleProperties,
  UpdateStandByOpenBookingURL,
  updateStandbyActivity,
  CheckInActivity,
  CheckOutActivity,
  CheckInOpenBookingURL,
  CheckOutOpenBookingURL,
  CalculateAdditionalChargeURL,
  financialEffectUrl, 
  CancelBookingFinancialEffectUrl,
  ApplyPackage,
  ClearPackage,
  assignInstructor
}

export enum headerWithRestaurantIds{
  createOpenBookingURL,
  bookCartForMultipleProperties
}

