import { Injectable } from "@angular/core";
import { SorTypeEnum, ActionTypeEnum, aligment } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { Localization } from "src/app/common/localization/localization";
import { TableHeaderOptions} from "src/app/common/Models/ag-models";
import { NonIntegratedCreditCardState,NonIntegratedCreditCardPaymentStatus } from "src/app/retail/shared/globalsContant";
import { NonIntegratedTransaction } from "src/app/retail/shared/service/payment/payment-model";
import { PayeeInfo } from "src/app/retail/shared/business/shared.modals";
import { RetailDataAwaiters } from 'src/app/retail/shared/events/awaiters/retail.data.awaiters';

@Injectable()
export class ViewNonIntegratedPaymentsBusiness {
    captions: any;
    localCaptions:any;
    currencySymbol:any;
    constructor(private localization: Localization) {
        this.captions = this.localization.captions;
        this.localCaptions = this.localization.captions.shop.nonIntegratedPaymentScreen;
        this.currencySymbol = this.localization.currencySymbol;
    }
    public getTableOptions() {
        return {
            actions: [
            ],
            defaultsortingColoumnKey: 'ticketNumber',
            showTotalRecords: false,
            defaultSortOrder: SorTypeEnum.desc,
            columnFreeze: {
                firstColumn: true,
                lastColumn: true
            },
            isDragDisabled: false,
        };
    }

    getTableHeaders(showOutlet : boolean): TableHeaderOptions[] {
        let headers =  [
            {
                key: 'ticketNumber',
                displayNameId: 'tbl_hdr_ticket_no',
                displayName: this.localCaptions.lbl_ticket_no,
                sortingKey: 'ticketNumber',
                sorting: true,
                searchable: true
            },
            {
                key: 'transactionDTM',
                displayNameId: 'tbl_hdr_transactionDTM',
                displayName: this.localCaptions.lbl_transactionDTM,
                sortingKey: 'transactionDTM',
                sorting: true,
            },
            {
                key: 'clientName',
                displayNameId: 'tbl_hdr_clientName',
                displayName: this.localCaptions.lbl_clientName,
                sortingKey: 'clientName',
                sorting: true,
                searchable: true
            },
            {
                key: 'memberName',
                displayNameId: 'tbl_hdr_memberName',
                displayName: this.localCaptions.lbl_memberName,
                sortingKey: 'memberName',
                sorting: true,
                searchable: true
            },
            {
                key: 'cardNumber',
                displayNameId: 'tbl_hdr_cardNumber',
                displayName: this.localCaptions.lbl_cardNumber,
                sortingKey: 'cardNumber',
                searchable: true,
                sorting: true,
            },
            {
                key: 'cardType',
                displayName: this.localCaptions.lbl_cardType,

            },
            {
                key: 'issuerType',
                displayName: this.localCaptions.lbl_issuerType,

            },
            {
                key: 'outletName',
                displayName: this.localCaptions.lbl_outlet,

            },
            {
                key: 'soldBy',
                displayName: this.localCaptions.lbl_soldBy,

            },
            {
                key: 'amountPaid',
                displayName: this.localCaptions.lbl_amount_paid +' ' + '(' + this.currencySymbol + ')',

            },
            {
                key: 'status',
                displayName: this.localCaptions.lbl_status,
            },
            {
                key: 'updatedBy',
                displayName: this.localCaptions.lbl_updatedBy,

            },
            {
              key:'updatedDate',
              displayName: this.localCaptions.lbl_updatedDateTime,
            },
            {
                key: 'cardDetails',
                displayName: this.localCaptions.lbl_cardDetails,
                templateName: ActionTypeEnum.custom

            },
            {
                key: 'paymentComment',
                displayName: this.localCaptions.lbl_paymentComment,
                templateName: ActionTypeEnum.custom
            },
            {
                key: ActionTypeEnum.action,
                displayName: this.localCaptions.lbl_action,
                displayNameId: 'tbl_hdr_actions',
                templateName: ActionTypeEnum.dropdown,
                inputs: {
                    selectOptions: [
                        { id: NonIntegratedCreditCardState.Completed, value: '', viewValue: '' },
                        { id: NonIntegratedCreditCardState.Realized, value: 'Success', viewValue: 'Payment Sucess' },
                        { id: NonIntegratedCreditCardState.Failed, value: 'Failure', viewValue: 'Payment Failed' }
                    ]
                }
            }

        ]

        if(!showOutlet)        
            headers = headers.filter(item => item.key !== 'outletName');      

        return headers;
    }

    async getTableContent(transactions: NonIntegratedTransaction[], clerkList: any[], outletName: string,payeeInfo: PayeeInfo[], members: any[] = []) {
        let transactionGridData = [];
        if (transactions && transactions.length > 0) {
            let clientName:any;
            let memberName:any;
            if (payeeInfo.length === 0) {
                const GuestIds = transactions.map(x => x.guestId).filter((v, i, a) => a.indexOf(v) === i && v > 0);
                clientName = GuestIds.length > 0 ? await RetailDataAwaiters.getPayeeDetails(GuestIds) : []
                payeeInfo = clientName;
              }
          
              if (members.length === 0) {
                const memberIds = transactions.map(x => x.memberId).filter((v, i, a) => a.indexOf(v) === i && v > 0);
              }

            transactions.map((t ,index) => {
                let payee: PayeeInfo;
                if (payeeInfo && payeeInfo.length > 0) {
                    payee = payeeInfo.find(x => x.id == t.guestId);
                    clientName = payee && t.guestId > 0 ? payee.name : '';
                }

                if (members && members.length > 0) {
                    const member = members.find(x => Number(x.guestProfileId) === Number(transactions[index].memberId));
                    clientName = member ? '' : clientName;
                    memberName = member ? member.name : memberName;
                }

                const updatedDateTime = new Date(t.updatedDateTime);
                const isDefaultDate = updatedDateTime.getDate() === new Date(0).getDate();
                transactionGridData.push(
                    {
                        id: t.id,
                        isUpdated: false,
                        ticketNumber: t.sourceTypeId ?? this.captions.shop.GiftCard.NA,
                        transactionDTM: this.localization.LocalizeShortDate(t.transactionDateTime) + ' ' + this.localization.LocalizeTime(t.transactionDateTime, true) ,
                        clientName: payeeInfo.find(x => x.id == t.guestId)?.name ? payeeInfo.find(x => x.id == t.guestId)?.name :'' , 
                        memberName: members.find(x => Number(x.guestProfileId) === Number(t.memberId))?.name ? members.find(x => Number(x.guestProfileId) === Number(t.memberId)).name : '',
                        cardNumber: t.cardNumber,
                        cardType: t.cardType,
                        issuerType: t.issuerType,
                        outletName: outletName,
                        soldBy: clerkList.find(x => x.userId == t.userId)?.userName,
                        amountPaid: t.amount,
                        status: t.status,
                        cardDetails: t.accessUrl,
                        paymentComment: t.referenceComment,
                        updatedBy: t.updatedBy > 0 ? clerkList.find(x => x.userId == t.updatedBy)?.userName
                            : this.captions.shop.GiftCard.NA,
                        updatedDate: isDefaultDate ? '' :this.localization.LocalizeShortDate(t.updatedDateTime) + ' ' + this.localization.LocalizeTime(t.updatedDateTime, true),
                        action: NonIntegratedCreditCardState[t.status],
                        isDisabled:t.status === NonIntegratedCreditCardPaymentStatus.Reversed ? true : false
                    }
                );
            });
        }
        return transactionGridData;
    }
}