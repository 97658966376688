import { Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import { buttonTypes } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue } from '@app/shared/dynamicform/models/field-config.interface';
import { PopupService } from '@popup-module/popup.service';

@Component({
  selector: 'app-confirmation-messages-popup',
  templateUrl: './confirmation-messages-popup.component.html',
  styleUrls: ['./confirmation-messages-popup.component.scss']
})
export class ConfirmationMessagesPopupComponent implements OnInit {
  public dialogTitle: string;
  @Input() data: any;
  confirmationMessages: any[] = [];
  buttonValueOk: ButtonValue;
  isInitial = true;
  showAlert = false;
  constructor(private ps: PopupService, private cdf: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.buttonValueOk = {
      type: buttonTypes.actionSecondary,
      label: 'ok',
    };
    this.dialogTitle = this.data[0].dialogTitle;
    this.confirmationMessages = this.data[0].confirmationMessage;
    this.showAlert = this.data[0].showAlert ? this.data[0].showAlert : false;
  }

  close(eve) {
    this.ps.closeDialog$.next();
  }
  submit() {
  }

}
