import { Pipe, PipeTransform } from '@angular/core';
import { PartyPrepaymentState } from '../models/InputContact';
import { PartyState } from '../constants/commonenums';

@Pipe({
    name: 'partyPaymentState'
})
export class PartyPaymentStatePipe implements PipeTransform {
    constructor() { }
    transform(attendee: any, paymentState?: PartyPrepaymentState, party?: any): any {
        let _paymentState = paymentState ? paymentState : attendee.PrepaymentState;
        let state = '';
        let dueState = [PartyPrepaymentState.PartialPrepayment, PartyPrepaymentState.PaymentDuePending];
        let paidState = [PartyPrepaymentState.DepositCollected, PartyPrepaymentState.Prepaid];
        let refundState = [PartyPrepaymentState.Refunded, PartyPrepaymentState.CancellationFeeApplied];

        if ((_paymentState == PartyPrepaymentState.None
            || (_paymentState == PartyPrepaymentState.Refunded && attendee.State !== PartyState.NoShowed && attendee.State !== PartyState.Cancelled))
            && (((attendee.partyAmount < 0 || attendee.NegotiatedAmount < 0) && !attendee.PaidAmount)
                || (attendee.BookingAmounts?.some(BookingAmounts => (BookingAmounts.NegotiatedAmount < 0 || BookingAmounts.amount < 0) && !attendee.PaidAmount)))) {
            state = attendee.State !== PartyState.Cancelled ? 'Waived__Off' : 'NA';
        } else if ((_paymentState == PartyPrepaymentState.PrepaymentRequired || (_paymentState == PartyPrepaymentState.None && attendee.UnPaidRounds > 0)) && attendee.State !== PartyState.Cancelled) {
            state = 'Not-Paid'
        } else if (paidState.includes(_paymentState) || (_paymentState == PartyPrepaymentState.None && attendee.PaidRounds > 0)) {
            state = 'Paid'
        } else if (_paymentState == PartyPrepaymentState.None
            || (_paymentState == PartyPrepaymentState.PrepaymentRequired && attendee.State === PartyState.Cancelled)) {
            state = ((attendee.State !== undefined && attendee.State !== PartyState.Cancelled && attendee.NegotiatedAmount !== undefined && attendee.NegotiatedAmount < 0 ) || (party && party?.NegotiatedAmount < 0)) ? 'Waived__Off' : 'NA';
        } else if (dueState.includes(_paymentState)) {
            state = 'Due'
        } else if (refundState.includes(_paymentState)) {
            state = 'Refunded'
        } else if (_paymentState == PartyPrepaymentState.RefundDuePending) {
            state = 'Refund__pending'
        }
        return state;
    }
}