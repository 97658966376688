import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { CheckUserPermissionsComponent } from '@components/check-user-permissions/check-user-permissions.component';
import { ExplainUserPermissionsComponent } from '@components/explain-user-permissions/explain-user-permissions.component';
import { ComponentTypes, ValidationMessageType } from '@constants/commonenums';
import { popupDialogDimension } from '@constants/globalConstants';
import { LoaderService } from '@core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import { Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from '../components/confirmation-popup/confirmation-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { ConsentMessagePopupComponent } from '../components/consent-message-popup/consent-message-popup.component';

@Injectable()
export class UserWarningActions extends Utilities implements OnDestroy {
  confSubscription: Subscription;

  constructor(dialog: MatDialog, public appService: AppService, translateService: TranslateService,
    public popupService: PopupService, public partyService: PartyService, public ls: LoaderService) {
    super(dialog, translateService);
  }
  ComponentType: ComponentTypes;
  pinSubscription: Subscription;
  cancelSubscription: Subscription;
  confirmSubscription: Subscription;
  dialogRef: MatDialogRef<CustomPopupComponent>;
  failedAttempts: number = 0;
  subscriptions: Subscription = new Subscription();


  ShowUserActionPopup(result: any) {

    if (this.pinSubscription) {
      this.pinSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    let validationMessage = this.getValidationMessage(result);
    let isValidationDetails: boolean = false;
    validationMessage.forEach(message => {
      if (message.hasOwnProperty('Details') && message.Details?.length) {
        isValidationDetails = true;
        return;
      }
    })

    const popUpMessage = [{
      confirmationMessage: isValidationDetails ? '' : validationMessage.map(messageData => messageData?.Message || messageData),
      validationListMessage: isValidationDetails ? validationMessage : [],
      dialogTitle: isValidationDetails ? this.translateService.instant('areYouSureToContinue') :this.translateService.instant('Message'),
      showAlert: true
    }];
    let componentDetails: ComponentDetails;
    if (isValidationDetails) {
      componentDetails = Utilities.setComponentDetails(ConsentMessagePopupComponent, 'large', 'active', popUpMessage,
        popUpMessage[0].dialogTitle);
    }
    else {
      componentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'active', popUpMessage,
        popUpMessage[0].dialogTitle);
    }
    this.dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: isValidationDetails ? '700px' : '550px',
      height: isValidationDetails ? '500px' : '400px',
      data: {
        title: popUpMessage[0].dialogTitle,
        update: 'Yes',
        cancel: 'No',
        componentDetails,
        from: ComponentTypes.ConsentMessagesWarning,
        back: false,
        standalone: true,
        //showFooter: false
      }
    });
    this.popupService.restrictCloseDialog = true;

    this.confSubscription = this.popupService.confirmedAction$.subscribe((val) => {
      if (val == ComponentTypes.ConsentMessagesWarning) {
        this.ls.userAction$.next(true);
        this.dialogRef?.close();
        this.popupService.restrictCloseDialog = false;
        this.confSubscription?.unsubscribe();
      }
    });

    this.subscriptions.add(this.dialogRef.afterClosed().subscribe(() => {
      if (this.confSubscription) {
        this.confSubscription.unsubscribe();
      }
      if (this.pinSubscription) {
        this.pinSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
    }));
    this.cancelSubscription = this.popupService.cancelledAction$.subscribe((val) => {
      if (val.from == ComponentTypes.ConsentMessagesWarning) {
        this.ls.userActionCancelled$.next(true);
        this.confSubscription?.unsubscribe();
        this.popupService.restrictCloseDialog = false;
        this.dialogRef.close();
      }
    });
  }

  getValidationMessage(data) {
    let warningMessages = [];
    if (data.ValidationMessages.length) {
      warningMessages = data.ValidationMessages.filter(msg => msg.Type == ValidationMessageType.ConsentMessages);
    }
    return warningMessages || [];
  }

  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}
