import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports,OutletAndCategoryFilter, InventoryAvailabilityFilter } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from '../../business/report-business.service';
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

export class InventoryAvailabilitySummary extends BaseReport {

    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, private func: RetailFunctionalityBusiness
        , private propertyInfo: RetailPropertyInformation, public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        return {
            code: AllReports.InventoryAvailability,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: true,
            inActiveToggle: false,
            pageBreakToggle: false,
            layout: 'LANDSCAPE',
            allowFutureDate: true,
            restrictPastDate: true
        }; 
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate;
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let categoryCaption:string = this.business.getFilterCaption(this.business.FilterDataSource["categories"],customFilterData.category.map(x=>x.id));
        let itemtype:string = this.business.getFilterCaption(this.business.FilterDataSource["itemtype"],customFilterData.itemType.map(x=>x.id));

        return [
            { "pStartDate": toApi(this.startDate) },
            { "pEndDate": toApi(this.endDate) },
            { "pPropertyName": this.propertyName },
            { "pDate" : this.printedDate },
            { "pCategory":categoryCaption},
            { "pItemType":itemtype},
            { "pDetailView": customFilterData.DetailView === true}

            ];
    }


    protected formURIParams(): ReportParams[] {
        return [];
    }




    protected formFilters() {
            const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
            let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
            let toApi = this.localization.convertDateObjToAPIdate;
        const tempOutFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return JSON.stringify({
                startDate: toApi(this.startDate),
                endDate: toApi(this.endDate),
                categoryIds: customFilterData.category.map(x=>x.id),
                itemIds: customFilterData.category.map(x=>x.id),
                isDetailedReport: customFilterData.DetailView,
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            });
        }
            let filter: Partial<InventoryAvailabilityFilter> = {
                startDate: toApi(this.startDate),
                endDate: toApi(this.endDate),
                categoryIds: customFilterData.category.map(x=>x.id),
                itemType: customFilterData.itemType.map(x=>x.id),
                isDetailedReport: customFilterData.DetailView === true,
    
            }; 
            return JSON.stringify(filter);
    }
}


