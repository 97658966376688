import { FinancialEffectType, PaymentGateways } from "../constants/commonenums";

interface RatePlan {
    Addon: number;
    Discount: number;
    InvoiceLevel: number;
    Negotiated: number;
    NoShowFees: number;
    Paid: number;
    Reservations: any;
    ServiceCharge: number;
    ServiceChargeRecalculate: boolean;
    ServiceCharges: any[]
    SubTotal: number;
    Tax: number;
    TaxOnServiceChargeAmount: number;
    Taxes: any;
    Total: number;
    Unpaid: number;
}

export class FinancialEffectResponse{
    RatePlan: RatePlan;
    ShopItems: any;
    ReturnItem: any[];
    PartyPaymentType: FinancialEffectType;
    PaymentGateway: PaymentGateways;
    RefundOption: number;
}


export enum FinancialEffectAction
{
     Update,
     CheckIn,
     CheckOut,
     Cancel,
     Reprice,
     Refund,
     WaivedOff
}

export enum ReservationSourceType
{
    TableSaver,
    WebSite,
    Importer,
    CRS,
    MobileApp,
    OTA,
    VCart
}