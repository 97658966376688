import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { RetailLocalization } from '../localization/retail-localization';
import { ConfigRouteCommon } from 'src/app/common/shared/config/config-route-common';
@Injectable(
    {
        providedIn:'root'
    }
)
export class SPAConfig extends ConfigRouteCommon  {
   
    constructor(public http: HttpClient, public locale: RetailLocalization) {
        super(http, locale);
    }
}
