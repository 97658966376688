import { Processor } from "./processor";
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { CacheService } from '@app/core/services/cache.service';
import { PartyService } from "@app/shared/services/party.service";
import { Utilities } from '@app/shared/utilities/utilities';
import _, { uniqBy } from 'lodash';

export class RentalBookingAddonChangeProcessor implements Processor {

    private static instance: RentalBookingAddonChangeProcessor;
    private constructor(public cs: CacheService, public partyService: PartyService) {
    }

    public static Instance(_cs: CacheService, _ps: PartyService): RentalBookingAddonChangeProcessor {
        if (!RentalBookingAddonChangeProcessor.instance) {
            RentalBookingAddonChangeProcessor.instance = new RentalBookingAddonChangeProcessor(_cs, _ps);
        }
        return RentalBookingAddonChangeProcessor.instance;
    }

    Process(objectChange: ObjectChange, additionaldata: object, propertyId: number): void {
        let partiesList = [];
        if (objectChange) {
            partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.Parties$.value : this.cs.propertySettings.value[propertyId].reservations;
        }
        const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'BookingId')?.Value;
        const party = partiesList.filter(party => party.Id == partyId);
        if (party?.length) {
            const bookingId = objectChange.PropertyChanges.find(property => property.PropertyName === 'BookingContactAddonItemId').Value;
            let bookingIndex = party[0]?.BookingContactAddonItems.findIndex(booking => booking?.Id === bookingId);
            if (bookingIndex !== -1) {
                party[0].BookingContactAddonItems[bookingIndex].AddonsRentalNotes = {
                    PickUp: {
                        Mode: objectChange.PropertyChanges.find(property => property.PropertyName === 'PickUpPreference')?.Value,
                        LocationName: objectChange.PropertyChanges.find(property => property.PropertyName === 'PickUpLocation')?.Value,
                        Latitude: objectChange.PropertyChanges.find(property => property.PropertyName === 'PickUpAtLatitude')?.Value,
                        Longitude: objectChange.PropertyChanges.find(property => property.PropertyName === 'PickUpAtLongitude')?.Value
                    },
                    Drop: {
                        Mode: objectChange.PropertyChanges.find(property => property.PropertyName === 'DropPreference')?.Value,
                        LocationName: objectChange.PropertyChanges.find(property => property.PropertyName === 'DropLocation')?.Value,
                        Latitude: objectChange.PropertyChanges.find(property => property.PropertyName === 'DropAtLatitude')?.Value,
                        Longitude: objectChange.PropertyChanges.find(property => property.PropertyName === 'DropAtLongitude')?.Value
                    },
                    UnitPrice: 0,
                    RentalAssetCodes : objectChange.PropertyChanges.find(property => property.PropertyName === 'RentalAssetCodes')?.Value
                }
            }
        }
        partiesList = _.uniqBy(partiesList, 'Id');
        if (propertyId == Utilities.RestaurantId()) {
            this.partyService.Parties$.next(partiesList);
            this.partyService.partiesList = partiesList;
            this.cs.propertySettings.value[propertyId].reservations = partiesList;
        } else {
            this.cs.propertySettings.value[propertyId].reservations = partiesList;
        }
    }
}