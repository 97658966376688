<section [formGroup]="TransactionLogFormGrp">
  <mat-form-field [floatLabel]="floatLabel">
      <mat-select placeholder="Log Type" name="outlet" formControlName="logtype">
        <ng-container *ngFor="let outlet of outlets ">
          <mat-option [value]="outlet.id">
            {{outlet.description}}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

  <mat-form-field class="form-group" [floatLabel]="floatLabel">
      <input matInput placeholder="Transaction #" class=""
        formControlName="transactionid">
    </mat-form-field>

    <mat-form-field class="form-group" [floatLabel]="floatLabel">
        <input matInput placeholder="Client First name" class=""
          formControlName="firstname">
      </mat-form-field>
 
      <mat-form-field class="form-group" [floatLabel]="floatLabel">
          <input matInput placeholder="Client Last name" class=""
            formControlName="lastname">
        </mat-form-field>

        <mat-form-field [floatLabel]="floatLabel" class="form-group date-picker-width" appearance="legacy">
          <mat-label>{{captions.date}}</mat-label>
            <input matInput [matDatepicker]="picker" dateRestricter [placeholder]="placeHolderFormat" class="picker"
              formControlName="date" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="TransactionLogFormGrp.controls['date'].hasError('matDatepickerParse')">{{captions.dateFormat}}</mat-error>   
            <mat-error *ngIf="TransactionLogFormGrp.controls['date'].hasError('required') && !TransactionLogFormGrp.controls['date'].hasError('matDatepickerParse')">{{captions.Missing}} {{captions.date}}</mat-error>   
            <mat-error *ngIf="TransactionLogFormGrp.controls['date'].hasError('matDatepickerMin')">{{captions.minimum}} {{captions.date}}</mat-error>
            <mat-error *ngIf="TransactionLogFormGrp.controls['date'].hasError('matDatepickerMax')">{{captions.maximum}} {{captions.date}}</mat-error>
          </mat-form-field>

          <mat-form-field class="form-group" [floatLabel]="floatLabel">
              <input matInput placeholder="Appointment #" class=""
                formControlName="appointmentid">
            </mat-form-field>

              <mat-form-field [floatLabel]="floatLabel">
                  <mat-select placeholder="User ID" name="outlet" formControlName="userid">
                    <ng-container *ngFor="let outlet of outlets ">
                      <mat-option [value]="outlet.id">
                        {{outlet.description}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>

              <mat-form-field class="form-group" [floatLabel]="floatLabel">
                  <input matInput placeholder="Other scanned code" class=""
                    formControlName="otherscannedcode">
                </mat-form-field>
  </section>