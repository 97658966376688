<div class="vip_type--wrapper submodule-container">
    <!-- <app-table-search-header [searchHeaderOption]="searchHeaderOption" (searchChange)="searchChange($event)"
        (showInactive)="showInactiveToggler($event)" (createType)="createEvent($event)">
    </app-table-search-header> -->
    <div class="w-100 h-100">
        <app-common-table [options]="tableoptions" (deleteEvt)="deleteVipType($event)" (editEvt)="EditRecords($event)"
            [isOnEditDisableFunctionAvailable]="onEditDisableFunction" [isEditModeSetFromParent] = "IsEditModeEnabledFlag"
            (inactiveToggleEvtEmitter)="inactiveSliderChange($event)" (cancelEmitter)="CancelEvent($event)"
            (doneEvtEmitter)="DoneEvent($event)" (copyEmitter)="copyEvent($event)" (dragDropEvt)="dragDrop($event)">
            <div class="right_content--section">
                <div class="ag_mr--4">
                    <label class="LW12 label-color ag_mr--4 ag_mt--4">{{captions.lbl_showActive}}</label>
                    <app-toggle-switch [attr.automationId]="'Tog_vipType_showActive'"
                        [ngClass]="IsEditModeEnabledFlag ? 'ag_link--disabled' : ''"
                        (changeToggleEvent)="onToggleChange($event)">
                    </app-toggle-switch>
                </div>
                <button type="button" [attr.automationId]="'Btn_contactLog_createNewBtn'"
                    [disabled]="isViewOnly || IsEditModeEnabledFlag"
                    [ngClass]="isViewOnly || IsEditModeEnabledFlag ? 'ag_link--disabled opacity-spa' : ''"
                    class="body-bgcolor createNewBtn whitecolor text-capital" mat-raised-button
                    (click)="openDialog()">{{captions.btn_VIPType}}</button>
            </div>
        
        </app-common-table>
    </div>
</div>