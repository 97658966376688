<div class="class-detail">

  <div class="booking-pannel">

    <div class="session-details">
      <ng-scrollbar>
        <div class="class-detail__cancellation" *ngIf="isSessionCancelled">
          {{'sessionCancelled' | translate}}
        </div>
        <div class="basic-details">
          <!-- <div class="basic-info">
            <div class="icon-details">
              <span class="icon-open-book-2"></span>
            </div>
            <div class="basic-description">
              {{ sessionDetail?.Name}}
            </div>
          </div> -->
          <!-- <div class="basic-info" *ngIf="activityData?.Description">
            <div class="icon-details">
              <span class="icon-information-button"></span>
            </div>
            <div class="basic-description">
              <div class="description" matTooltip={{activityData.Description}}>
                {{activityData
                .Description || ('No Description' |
                translate)}}</div>
            </div>
          </div> -->
          <div class="basic-info">
            <div class="icon-details">
              <span class="icon-clock1"></span>
            </div>
            <div class="basic-description">
              {{classDetailDate | formatSettingsDate:
              _settings.General.DateFormat}} <span *ngIf="sessionDetail.startTime && sessionDetail.endTime">| {{
                sessionDetail.startTime | localizedDate : 'LT' }}
                - {{ sessionDetail.endTime | localizedDate : 'LT' }}</span>
            </div>
          </div>
          <div class="basic-info" *ngIf="staffs?.length">
            <div class="icon-details">
              <span class="icon-servant-outline"></span>
            </div>
            <div class="basic-description">

              <div class="class-detail__location text-muted data-content-view" *ngIf="staffs && staffs.length">
                <div *ngFor="let staff of staffs  | slice:0:(showAll.staff ? staffs.length : 1 )" [title]="staff">
                  <span class="trainer-name text-ellipsis">
                    {{staff}}
                  </span>
                </div>
                <div class="class-detail__slots-show-action" *ngIf="staffs && staffs.length > 1"
                  [ngbPopover]="staffPopover" placement="auto" [autoClose]="'outside'" container="body">
                  +{{staffs.length-1}}
                </div>
              </div>
            </div>
          </div>
          <div class="basic-info" *ngIf="locations?.length">
            <div class="icon-details">
              <span class="icon-Location"></span>
            </div>
            <div class="basic-description">
              <div class="class-detail__location text-muted">
                <div *ngFor="let location of locations  | slice:0:(showAll.location ? locations.length : 1 )"
                  [title]="location">
                  {{location}}
                </div>
                <div class="class-detail__slots-show-action text-muted ml-2" *ngIf="locations && locations.length > 1"
                  [ngbPopover]="locationPopover" placement="auto" [autoClose]="'outside'" container="body">
                  +{{locations.length-1}}
                </div>
              </div>
            </div>
          </div>
          <div class="basic-info" *ngIf="LocationGroupDetails">
            <div class="icon-details">
              <span class="icon-rntl-inventory"></span>
            </div>
            <div class="basic-description">
              <div class="class-detail__location text-muted" matTooltip="{{'Asset' | translate}}">
                <div class="inventory"><span class="icon  icon-rntl-inventory mr-1"></span> {{LocationGroupDetails}}
                </div>
              </div>
            </div>
          </div>
          <div class="basic-info" *ngIf="sessionDetail?.showAvailability">
            <div class="icon-details">
              <span class="icon-group"></span>
            </div>
            <div class="basic-description">
              <div
                matTooltip="{{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.AvailableCapacityByTotalCapacity  ? ('AvailableToBook' | translate) : ('Booked' | translate)}}">
               {{ partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.AvailableCapacityByTotalCapacity ? ('Available' | translate) : ('Booked' | translate)}} <span class="count">
                  {{ sessionDetail.available}}</span> /
                <span matTooltip="{{'TotalCapacity' | translate}}"> {{sessionDetail.available +
                  sessionDetail.booked}}
                  <span class="overbooked-text" *ngIf="sessionDetail.overbooked">({{'OverbookedBy' | translate}}
                    {{sessionDetail.overbooked}})</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="notes-view" *ngIf="sessionData">
          <div class="notes-container">
            <div class="notes-section">
              <ng-container *ngIf="publicQuickNote?.values?.length == 0">
                <div class="add-note"
                  (click)="!noActionRequired ? addHeadlinesForActivity(classDetail,'',publicQuickNote) : false">
                  <span class="headline-description ">
                    <i class="icon-public" matTooltip="{{'PublicQuickNote' | translate}}"></i>
                    {{'AddPublicQuickNote' | translate}}</span>
                  <span *ngIf="!noActionRequired" (click)="addHeadlinesForActivity(classDetail,'',publicQuickNote)"
                    class="icon-add-solid"></span>
                </div>
              </ng-container>
              <ng-container *ngIf="publicQuickNote?.values?.length > 0">
                <div class="headline-container" *ngFor="let data of publicQuickNote?.values">
                  <div class="headline-header">
                    <div class="headline-text">
                      <div class="headline-title">
                        <i class="icon-public" matTooltip="{{'PublicQuickNote' | translate}}"></i>
                        {{'PublicNote' | translate}}
                      </div>
                    </div>
                    <div class="headline-actions" *ngIf="!noActionRequired">
                      <i class="icon-ic_create_24px"
                        (click)="addHeadlinesForActivity(classDetail,data,publicQuickNote)"></i>
                      <i class="icon-Delete" (click)="deleteHeadlinesForActivity(classDetail,data,publicQuickNote)"></i>
                    </div>
                  </div>
                  <div class="headline-description pl-3">
                    <span *ngIf="data">
                      {{data.Description}}</span>
                  </div>
                </div>
              </ng-container>

            </div>
            <div class="notes-section">
              <ng-container *ngIf="privateQuickNote?.values?.length == 0">
                <div class="add-note"
                  (click)="!noActionRequired ? addHeadlinesForActivity(classDetail,'',privateQuickNote) : false">
                  <span class="headline-description ">
                    <i class="icon-public_off" matTooltip="{{'PrivateQuickNote' | translate}}"></i>
                    {{'AddPrivateQuickNote' | translate}}</span>
                  <span *ngIf="!noActionRequired" (click)="addHeadlinesForActivity(classDetail,'',privateQuickNote)"
                    class="icon-add-solid"></span>
                </div>
              </ng-container>
              <ng-container *ngIf="privateQuickNote?.values?.length > 0">
                <div class="headline-container" *ngFor="let data of privateQuickNote?.values">
                  <div class="headline-header">
                    <div class="headline-text">
                      <div class="headline-title">
                        <i class="icon-public_off" matTooltip="{{'PrivateQuickNote' | translate}}"></i>
                        {{'PrivateNote' | translate}}
                      </div>
                    </div>
                    <div class="headline-actions" *ngIf="!noActionRequired">
                      <i class="icon-ic_create_24px"
                        (click)="addHeadlinesForActivity(classDetail,data,privateQuickNote)"></i>
                      <i class="icon-Delete"
                        (click)="deleteHeadlinesForActivity(classDetail,data,privateQuickNote)"></i>
                    </div>
                  </div>
                  <div class="headline-description pl-3">
                    <span *ngIf="data">
                      {{data.Description}}</span>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="notes-section block-session pl-1" *ngIf="blockedSession && blockedSessionDetails">
              <div class="headline-header">
                <div class="headline-text">
                  <div class="headline-description d-flex">
                    <i class="icon-block_1" matTooltip="{{'blocks' | translate}}"></i>
                    <div class="class-detail__blocked-details" *ngIf="blockedSession && blockedSessionDetails" style="">
                      <div class="class-detail__blocked-data"><span>{{ 'blockedBy' | translate }} :
                        </span><span>{{blockedSessionDetails?.blockedBy}}</span></div>
                      <div class="class-detail__blocked-data"><span>{{'blockedUntil' | translate}} : </span><span>
                          {{blockedSessionDetails?.blockedUntil | localizedDate:'LT'}}</span></div>
                      <div class="class-detail__blocked-data"><span>{{'blockingreason' | translate}} : </span><span>
                          {{blockedSessionDetails?.blockingReason}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="notes-section cancel-session pl-1" *ngIf="isSessionCancelled && sessionDetail">
              <div class="headline-header">
                <div class="headline-text">
                  <div class="headline-description d-flex">
                    <span class="icon-booking-cancel-icon" matTooltip="{{'Cancelled' | translate}}"><span
                        class="path1"></span><span class="path2"></span></span>
                    <div class="class-detail__blocked-details">
                      <div class="class-detail__blocked-data"><span>{{ 'cancelledBy' | translate }} :
                        </span><span>{{this.sessionDetail?.CancelledBy}}</span></div>
                      <div class="class-detail__blocked-data"><span>{{'cancelledUntil' | translate}} : </span><span>
                          {{this.sessionDetail?.CancelledUntil | localizedDate:'LT'}}</span></div>
                      <div class="class-detail__blocked-data"><span>{{'cancelledReason' | translate}} : </span><span>
                          {{this.sessionDetail?.CancelledReason}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="addon-notes" *ngIf="addonForActivity.length > 0 && showAddOnIcon">
          <div class="addon-container">
            <div class="addon-title"><i class="icon icon-addons"></i> {{'addons' | translate}}</div>
            <div class="addon-description">
              <div class="reservation-notes__notes-details" *ngFor="let addOn of addonForActivity">
                <div>
                  <span>{{addOn.AddonId | getAddonName : _settings}}<span style="color:red" *ngIf="addOn.IsMandatory">
                      *</span></span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div *ngIf="addonsForBookingPerLesson?.length" class="shared-resources">
          <span class="icon-No-sahre2"><span class="path1"></span><span class="path2"></span><span
              class="path3"></span></span>
          <div class="shared-list">
            <div
              *ngFor="let addOn of addonsForBookingPerLesson  | slice:0:(showAddOns.addOn ? addonsForBookingPerLesson.length : 1 )">
              <div class="seat-label" [ngStyle]="{'color' : addOn.CategoryColor}">{{addOn.AddonId | getAddonName :
                _settings}}
              </div>
            </div>
          </div>
        </div>
        <div class="class-detail__slots-show-action"
          *ngIf="addonsForBookingPerLesson && addonsForBookingPerLesson.length > 1"
          (click)="showAddOns.addOn = !showAddOns.addOn">
          {{showAddOns.addOn ? ('ShowLess' | translate) : '+'+(addonsForBookingPerLesson.length-1)+'
          '+('accordionmoretext' | translate)}}
        </div>
      </ng-scrollbar>
    </div>


  </div>



</div>

<ng-template #staffPopover>
  <div class="staff-list" *ngFor="let staff of staffs">
    <div class="staff-name"> {{staff}}</div>
  </div>
</ng-template>


<ng-template #locationPopover>
  <div class="staff-list" *ngFor="let location of locations">
    <div class="staff-name"> {{location}}</div>
  </div>
</ng-template>