import { rGuestBaseService } from "./service/base.service";
import { EventEmitter } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CommonSharedPopupComponent } from "./common-popup/common-popup.component";
import { GuestFieldMetrics } from "@app/shared/constants/commonenums";


export const registerEvent: EventEmitter<rGuestBaseService> = new EventEmitter();
export const unregisterEvent: EventEmitter<rGuestBaseService> = new EventEmitter();

export const DashBoardRegisterEvent: EventEmitter<any> = new EventEmitter();
export const THERAPIST = 'THERAPIST';
export const USER = 'USER';
export const SERVICECHARGE = 'SERVICECHARGE';
export const GRATUITY = 'GRATUITY';
export const DEFAULT_SUBJECT_NAME_JOBSCHEDULER: string = "Job Scheduler";


export const DefaultGUID = "00000000-0000-0000-0000-000000000000";
export const DaysWithNumbers = [
  { id: 1, name: "Mon" },
  { id: 2, name: "Tue" },
  { id: 3, name: "Wed" },
  { id: 4, name: "Thu" },
  { id: 5, name: "Fri" },
  { id: 6, name: "Sat" },
  { id: 7, name: "Sun" },
]

export const enum PromptLevel {
  One = 1,
  Two,
  Three
}

export const enum PromptType {
  Delete = 1,
  UnsavedChanges
}
export const CACHE_MAXAGE_MINTUES: number = 1;

export const taxValue = 10;

export const GridTimeInterval = 15;

export const version: string = "1.0.1";

export const FILENAME: string = "spa"

export const url: string = "api/data";

// CompressionLimit is the saturation point to start the compression
// Size in KB
export const COMPRESSION_LIMIT = 500;

// ALLOWED_IMAGE_SIZE is the max file size allowed.
// Size in MB
export const ALLOWED_IMAGE_SIZE = 2;
export const IDTechCardSwipeTimeout = 45000;

export const NUMBER_VALUES: any = {
  "one": 1,
  "two": 2,
  "three": 3,
  "four": 4,
  "five": 5,
  "six": 6,
  "seven": 7,

}

export const PMSDATA = 'pmsdata';
export declare const setting: any;


export enum ButtonType {
  YesNo = 1,
  YesNoCancel = 2,
  OkCancel = 3,
  Ok = 4,
  SaveCancel = 5,
  Continue = 6,
  ContinueCancel = 7,
  AddCancel = 8,
  Custom = 11,
  SplitMoreAction = 12

}

export const enum ButtonOptions {
  Yes = 'yes',
  No = 'no',
  Cancel = 'cancel',
  Ok = 'ok',
  Save = 'save',
  Add = 'Add'
}
export const DepositRetailItemType = 6;

export enum GridOperationType {
  Create = 1,
  Edit,
  Delete,
  ListOrderDrag
}

export enum GridType {
  group = 1,
  location,
  therapist,
  medical,
  addons,
  equipment,
  spaServices,
  commission,
  spaPackage,
  appointmentsearch,
  spaPackageService,
  deletepackage,
  packageClasses,
  colorLink,
  lunchsetup,
  breakType,
  priceType,
  cancelReasons,
  password,
  locationMaintenance,
  equipmentMaintenance,
  therapistUnavailability,
  customfield,
  multipleAppointments,
  outlet,
  retail,
  quickSale,
  quickSaleCategories,
  creditcardterminal,
  categorygroup,
  commissionTemplate,
  retailtransactions,
  waitlist,
  roleSetup,
  userSetup,
  userRoleConfiguration,
  packageYielding,
  taxes,
  retailCategories,
  subCategory,
  retailSuppliers,
  retailOutlet,
  supplierDetails,
  unitOfMeasure,
  discountTypes,
  userMachineConfiguration,
  recieptConfiguration,
  templates,
  outletTerminal,
  common,
  Relationship
}

export enum RetailTransactions {
  mailorder = 1,
  reprintticket,
  opentransactions,
  correctvoid,
  pettycash,
  returnwithticket,
  returnwithoutticket,
  modifypostedcommission,
  modifypostedcommissionticketdetails
}
export enum PhoneType {
  Home = 1,
  Office,
  Mobile
}

export enum ActionType {
  recurring = 1,
  edit,
  cancel,
  copy,
  move,
  swap,
  print,
  recap,
  overbook,
  serviceaddon,
  linkmultipack,
  booklunch,
  changeservice,
  createtherapistbreak,
  modifytherapistbreak,
  undocheckin,
  checkin,
  checkout,
  adddeposit,
  delete,
  checkin_checkout,
  undocheckout,
  reinstate

}
export const enum ReceiptType {
  sales = 1,
  reprint = 2,
  void = 3,
  returnExchange = 4
}

export const enum Product {
  SPA = 1,
  RETAIL = 2,
  GOLF = 3,
  COMMON = 4,
  SNC =5,
  PMS = 6,
  ACCOUNTING = 10,
  FOLIO = 11
}

export const enum Host {
  image = "image",
  spaManagement = "spaManagement",
  schedule = "schedule",
  retailManagement = "retailManagement",
  retailPOS = "retailPOS",
  authentication = "authentication",
  reporting = "report",
  commission = "commission",
  payment = "payment",
  golfSchedule = "golfSchedule",
  golfManagement = "golfManagement",
  commonGateway = "commonGateway",
  Common = "Common",
  SalesCateringService = "SalesCateringService",
}
export const enum Module {
  client = "Client",
  appointment = "Appointment",
  home = "home"
}
export const enum ImgRefType {
  client = "CLIENT",
  therapist = "THERAPIST",
  retailItem = "RETAILITEM",
  guest = "GUEST"
}

export enum GenderPreference {
  none,
  enforceGender,
  preferMale,
  preferFemale,
  noPreference
}

export enum ActivityType {
  GOLF = "rG-Golf",
  PMSSTAY = "V1-Stay",
  SPA = "rG-Spa",
  SEAT = "rG-Seat",
  PMSSTAYLINKED = "V1-PMS" // this type indicates an activity has link to PMS stay in its date range.
}


export enum ActivityStatus {
  Reserved = "Reserved",
  CheckedIn = "CheckedIn",
  CheckedOut = "CheckedOut",
  NoShow = "NoShow",
  Cancelled = "Cancelled",
  Unknown = "Unknown"
}

export enum ReservePlatformStatus {
  Pending = "Pending",  //Reserved
  CheckIn = "Check-In",
  CheckOut = "Check-Out",
  Cancelled = "Cancelled",
}

export enum SeatPlatformStatus {
  Pending = "Pending",  //Reserved
  Seated = "Seated",  //CheckedIn
  Left = "Left",  //CheckedOut
  Cancelled = "Cancelled",
}

export class Config {
  value: any = 1;
}

export enum UtilityMoveOptions {
  therapistAppointments = 1,
  therapistLocation = 2,
  appointmentLocation = 3
}
export const enum AppointmentStatus {
  Scheduled = "RESV",
  Canceled = "CANC",
  Break = "BREAK",
  CheckIn = "CKIN",
  CheckOut = "CKOUT",
  NoShow = "NOSHOW",
  Temp = "TEMP"
}

export const enum ContactMode {
  Phone = 'Phone',
  Email = 'Email'
}

export const enum ContactType {
  Home = 'Home',
  Mobile = 'Mobile',
  Office = 'Office'
}

export const enum ReportBreakPoint {
  ClientItinerary = 1200,
  BatchPrintClientItinerary = 1205,
  AppointmentListingbyLocation = 1210,
  AppointmentListingbyStaffMember = 1215,
  AppointmentListingbyCustomFields = 1220,
  AppointmentListing = 1225,
  CancellationReport = 1230,
  NoShowReport = 1235,
  StaffMemberSummaryReport = 1240,
  CommissionReport = 1245,
  GratuityReport = 1250,
  ServiceChargeReport = 1255,
  TransactionLog = 8090,
  ReturnedItems = 8060,
  InventoryAudit = 8085,
  InventoryTransfer = 8080,
  InventoryDetail = 8070,
  InventorySummary = 8065,
  InventoryOnHand = 8736, /*temporary*/
  MultiPackItems = 8055,
  CorrectVoid = 8015,
  SaleByDiscountType = 8050,
  InventoryWash = 8075,
  SaleByItem = 8035,
  SalesByVendor = 8046,
  SaleByCategory = 8040,
  SaleBySubCategory = 8045,
  RevenueSummary = 8010,
  Shift = 8000,
  Transaction = 8005,
  AppointmentListingbyColorCode = 1260,
  StaffSchedule = 1265,
  GuestAppointments = 1270,
  AccrualReport = 8020
}

export const enum CommonBreakPoint{
  JastSoftStudio = 2315,
  PMSIntegrationHostConfiguration = 12091
}


export const enum SPAManagementBreakPoint {
  AddNewClientProfile = 600,
  EditClientProfile = 605,
  AccessClientMedicalHistory = 610,
  ViewPrivateMedicalCondition = 615,
  AddSOAPNotes = 620,
  EditSOAPNotes = 625,
  EditClientPreferences = 630,
  EditCustomField1 = 635,
  EditCustomField2 = 640,
  EditCustomField3 = 645,
  EditCustomField4 = 650,
  EditCustomField5 = 655,
  AddPriceType = 660,
  EquipmentMaintenance = 2400,
  LocationMaintenance = 2405,
  TherapistUnavailability = 2410,
  MoveMultipleApptTherapistAppointments = 2415,
  MoveMultipleApptTherapistLocation = 2420,
  MoveMultipleApptAppointmentLocation = 2425,
  DayEnd = 2430,
  CombineGuestRecords = 2435,
  DedupeGuestRecords = 12120,
  RemoveTempHold = 2440,
  CommissionClassSetup = 800,
  StaffCommissionSetup = 805,
  AddTherapistBreak = 2200,
  EditTherapistBreak = 2205,
  DeleteTherapistBreak = 2210,
  StaffSchedule = 2215,
  EditStaffSchedule = 2220,
  DeleteStaffSchedule = 2225,
  ServiceGroup = 2000,
  ServiceLocation = 2005,
  TherapistSetup = 2010,
  MedicationCondition = 2015,
  ServiceAddOn = 2020,
  ServiceEquipment = 2025,
  SpaService = 2030,
  SpaPackage = 2035,
  CancelReason = 2040,
  PackageClasses = 2045,
  LunchSetup = 2050,
  ColorLinking = 2055,
  BreakType = 2060,
  PriceType = 2065,
  CustomFields = 2070,
  PackageYielding = 2075,
  ServiceYielding = 2080,
  SystemSettings = 2085,
  MoveMultipleAppointments = 241524202425,
  Relationship = 2105
}



export const enum SPAScheduleBreakPoint {
  BookAppointment = 100,
  BookUnassignedTherapist = 105,
  BookSpaPackage = 110,
  BookExpressSpaPackage = 115,
  BookLunch = 120,
  ServiceAddOn = 125,
  EditAppointment = 130,
  CancelAppointment = 135,
  CheckInAppointment = 140,
  UndoCheckIn = 145,
  CheckOutAppointment = 150,
  CheckIn_CheckOutAppointment = 155,
  CopyAppointment = 160,
  MoveAppointment = 165,
  BookRecurringAppointments = 170,
  ChangeService = 175,
  ChangeService_PackageAppointment = 180,
  ChangeAppointmentStatus = 185,
  SwapAppointment = 190,
  OverbookTherapist = 195,
  OverbookLocation = 200,
  OverbookLunch = 205,
  AddDeposit = 210,
  LinkMultipack = 215,
  UnlinkMultipack = 220,
  ConvertAppointmentfromWaitlisttoScheduled = 225,
  RemoveDoNotMoveFlag = 230,
  EditCustomField1 = 235,
  EditCustomField2 = 240,
  EditCustomField3 = 245,
  EditCustomField4 = 250,
  EditCustomField5 = 255,
  EditAppointmentComments = 260,
  EditAppointmentServicePrice = 265,
  EditSetupTime = 270,
  EditBreakdownTime = 275,
  EditAppointmentDuration = 280,
  RecapAppointment = 285,
  PersonalInfoVisibleOnRecap = 290,
  AppointmentSearch = 400,
  ParkingLot = 405,
  AddWaitList = 410,
  ViewWaitList = 415,
  AppointmentConfirmation = 420,
  SettingServiceGroup = 2000,
  SettingServiceLocation = 2005,
  SettingTherapistSetup = 2010,
  SettingMedicationCondition = 2015,
  SettingServiceAddOn = 2020,
  SettingServiceEquipment = 2025,
  SettingSpaService = 2030,
  SettingSpaPackage = 2035,
  SettingCancelReason = 2040,
  SettingPackageClasses = 2045,
  SettingLunchSetup = 2050,
  SettingColorLinking = 2055,
  SettingBreakType = 2060,
  SettingPriceType = 2065,
  SettingCustomFields = 2070,
  SettingPackageYielding = 2075,
  SettingServiceYielding = 2080,
  SettingSystemSettings = 2085,
  UserSetup = 2300,
  UserRoleSetUp = 2305,
  UserRoleConfiguration = 2310,

   //DataMagine
   DATAMAGINE_CONFIGURATION = 85160,
   DATAMAGINE_SETTINGS = 85165,
   DOCUMENTCODES = 85175,
   DOCUMENTTYPES = 85170,


   DATAMAGINE_GENERATE_EFORM = 20785,

}

export const enum RetailBreakPoint {
  CreateTransaction = 3000,
  ApplyGratuity = 3005,
  ApplyServiceCharge = 3010,
  ApplyCommission = 3015,
  EditGratuity = 3020,
  EditServiceCharge = 3025,
  EditCommission = 3030,
  ApplyDiscount = 3035,
  ApplyTicketDiscount = 3155,
  OutletSetup = 7000,
  ItemSetup = 7005,
  QuickSaleKeys = 7010,
  UnitofMeasure = 7015,
  SplitCommissionTemplateSetup = 7020,
  ReOpenTransaction = 5000,
  VoidTransaction = 5005,
  CorrectTransaction = 5007,
  ReturnWithTicket = 5010,
  ReturnWithoutTicket = 5015,
  ModifyPostedCommission = 5020,
  ReprintTicket = 5025,
  RevenuePostingsLog = 5030,
  DataMagineLog = 5040,
  DiscountType = 7060,
  DiscountConfiguration = 7061,
  PaymentMethods = 7070,
  CategoryLinking = 7041,
  CategoryGroup = 7030,
  RetailCategories = 7035,
  RetailSubCategories = 7040,
  creditcardterminal = 7025,
  Taxconfiguration = 7050,
  Inventory = 8700,
  ItemTransfer = 8725,
  ReceiptConfiguration = 7080,
  TaxExempt = 3040,
  SettleToCreditCard = 3050,
  SettleToRoomCharge = 3055,
  SettleToCash = 3045,
  SettleToOthers = 3065,
  UserSessionConfiguration = 7085,
  StoreTerminal = 7090,
  ISSUEV1GIFTCARD=3075,
  ISSUEEXTERNALGIFTCARD=3080,
  SETTLETOGROUPCHARGE=3085,
  CustomFee = 7160
}

export const enum InventoryBreakPoint {
  UpdateQTYonHand = 8700,
  FreezeInventory = 8705,
  ReleaseInventory = 8710,
  FullInventoryUpdate = 8715,
  PartialInventoryUpdate = 8720,
  ManualInventory = 8721,
  InventoryManagement = 8722,
  ItemTransfer = 8725,
  PrintBarcodes = 8730,
}

export const enum ErrorConstants {
  TherpistAlreadyBlocked = 923,
  TherapistNotScheduled = 925,
  LocationNotAvailable = 931,
  LocationAlreadyBlocked = 933,
  LocationUnderMaintenance = 934,
  ClientCanBeOverBooked = 944,
  ClientNotAvailable = 942,
  ClientCannotBeOverBooked = 943,
  TherapistNotCertifiedForAddOn = 928,
  TempNotFound = 1001,
  TherapistIsOnCall = 100013
}

export const enum OperationType {
  None = 0,
  Create = 1,
  Edit = 2,
  Delete = 3
}

export type Partial<T> = {
  [P in keyof T]?: T[P];
}

export const allowedUrl: Array<any> = [false, 'report']
export const zipcodeformat: any = {
  'usFormat': '9999 9999 9999 9999',
  'uKFormat': '9999-9999-9999'
}

export enum noRecordsType {
  noRecords,
  noRecordsAfterSearch,
  noAppointmentsBooked
}

export enum ServiceChargeGratuityValueType {
  PreDefinedPercent = 0,
  CustomPercent,
  CustomAmount
}

export enum ServiceChargeGratuityPercentId {
  TenPercent = 1,
  FifteenPercent = 2,
  TwentyPercent = 3,
  TwentyFivePercent = 4
}

export enum PercentageAmount {
  Percentage = 1,
  Amount = 2,
  CustomPercentage = 3
}

export enum reservationApiUrl {
  GuestId = 0,
  ueserConfig = 1,
}

export const IDTechCardSwipeTimeOut: number = 30 //In Seconds

export enum SelectType {
  single = 1,
  multiple = 2
}

export const enum Maxlength {
  FLATAMOUNT = 12,
  PERCENTAGE = 3
}

export const CustomFieldKey = {
  CustomField1: 'CustomField1',
  CustomField2: 'CustomField2',
  CustomField3: 'CustomField3',
  CustomField4: 'CustomField4',
  CustomField5: 'CustomField5'
};


export enum ButtonAction {
  save = 'save',
  update = 'update',
  process = 'process',
  convert = 'convert',
  confirm = 'confirm',
  pay = 'pay',
  print = 'print',
  reinstate = 'reinstate',
  login = 'login'
}

export interface DialogCloseObj {
  type: string;
  dialogRef: MatDialogRef<CommonSharedPopupComponent>;
  selectedIndex?: number;
}

export const enum ElasticApm {
  UseApmServer = 'UseApmServer',
  ApmServerUrl = 'ApmServerUrl',
  DistributedTracingOrigins = 'DistributedTracingOrigins',
  ServiceName = 'PMS-UI',
  UseLogViewer = 'UseLogViewer'
}

export const enum GatewayConfigurationType {
  None = 0,
  CreditCard,
  GiftCard
}

export enum PMS_SYSTEM {
  V1 = "V1",
  STAY = "STAY",
  LMS = "LMS",
  COMTROL = "COMTROL",
  RESORT_SUITE = "RESORTSUITE",
  OPERA = "OPERA",
  INFOR = "INFOR",
  LIGHT_SPEED = "LIGHTSPEED",
  FOSSE = "FOSSE",
  FSPMS = "FSPMS",
  VISUALONE = "VisualOne",
  VERSA = "VERSA"
}

export const enum PropertyConfigurations {
  UiDefaultsSettingsValue = 'UiDefaultsSettingsValue'
}

// Get Machine Name
export const MACHINENAME_ID = 'MachineId';
export const MACHINENAME = 'MachineName';
export const TRANSACTION_BY_MACHINENAME = 'TRANSACTION_BY_MACHINENAME';
export const SELECTION_ON_LOGIN = 'SELECTION_ON_LOGIN';
export const PRINTERMANAGERURI='PrinterManagerURI';
export const MACHINE_PRINTER_CONFIGURATION= 'MachinePrinterConfig';
export const STAY = "Stay";
export const LMS = "LMS";
export const EXTERNAL = "External";
export const BOTH ="Both";
export const INTERNAL ="Internal";
export const GuestSearchSystem ="GuestSearchSystem";
export const PaymentMethodValue = {
  1: "Cash",
  2: "Card",
  3: "Room Charge",
  4: "Pending Settlement",
  5: "IDTech SRED Key",
  6: "Rain Check",
  7: "AR Account",
  8: "Redeem Point",
  9: "Credit Book",
  10: "Comp Redemption",
  11: "Offer Redemption",
  12: "V1 GiftCard",
  13: "3rd Party GiftCard",
  14: "V1 GiftCard IdTech",
  15: "3rd Party GiftCard IdTech",
  16: "A/R Account Posting",
  17: "Agilysys GiftCard",
  18: "Agilysys GiftCard IdTech",
  19: "Comp Slip Redemption",
  20: "Group Charge",
  21: "Post To Folio",
  22: "Card On File",
  23: "Authorize Payment",
  24: "Wallet",
  25: "Hotel Comp"
}

export const GuestMetricsinText =[GuestFieldMetrics.None,GuestFieldMetrics.Inches, GuestFieldMetrics.FeetInches]