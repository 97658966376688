import { Utilities } from '@app/shared/utilities/utilities';
import { ChangeAction } from '@constants/commonenums';
import { ObjectChange } from '@models/ChangeTrackingOperationResultDTO';
import { ReservationDTO } from '@models/InputContact';
import { PartyMessageDTO } from '@models/RestaurantDTO';
import { PartyService } from '@services/party.service';
import _ from 'lodash';
import { CacheService } from '../services/cache.service';
import { Processor } from './processor';

export class PartyEmailNoteChangeProcessor implements Processor {

    private static instance: PartyEmailNoteChangeProcessor;
    private constructor(public partyService: PartyService, public cs: CacheService) { }

    public static Instance(componentService: any = PartyService, cs: CacheService): PartyEmailNoteChangeProcessor {
        if (!PartyEmailNoteChangeProcessor.instance) {
            PartyEmailNoteChangeProcessor.instance = new PartyEmailNoteChangeProcessor(componentService, cs);
        }

        return PartyEmailNoteChangeProcessor.instance;
    }

    Process(objectChange: ObjectChange, additionaldata: object, propertyId: number): void {
        let partiesList = [];
        let standBy = false;
        if (objectChange) {
            const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'PartyId').Value;
            if (this.partyService.StandbyParties$.value.filter(party => party.Id == partyId).length > 0) {
                partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.StandbyParties$.value : this.cs.propertySettings.value[propertyId].StandbyParties;
                standBy = true;
            }
            else {
                partiesList = propertyId == Utilities.RestaurantId() ? this.partyService.Parties$.value : this.cs.propertySettings.value[propertyId].reservations;
                standBy = false;
            }
            if (objectChange.Action === ChangeAction.Created || objectChange.Action === ChangeAction.Updated) {
                const partyId = objectChange.PropertyChanges.find(property => property.PropertyName === 'PartyId').Value;
                const party = partiesList.find(party => party.Id == partyId);
                if (party) {
                    let emailNotes = objectChange.PropertyChanges.find(property => property.PropertyName === 'EmailNotes').Value;
                    party.EmailNotes = emailNotes || null;
                }
            }
        }
        partiesList = _.uniqBy(partiesList, 'Id');
        if (propertyId == Utilities.RestaurantId()) {
            if (standBy) {
                this.partyService.StandbyParties$.next(partiesList);
                this.partyService.standbyPartiesList = partiesList;
                this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
            } else {
                this.partyService.Parties$.next(partiesList);
                this.partyService.partiesList = partiesList;
                this.cs.propertySettings.value[propertyId].reservations = partiesList;
            }
        } else {
            if (standBy) {
                this.cs.propertySettings.value[propertyId].StandbyParties = partiesList;
            } else {
                this.cs.propertySettings.value[propertyId].reservations = partiesList;
            }
        }
    }
}
