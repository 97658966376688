import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCloseEnum } from 'src/app/common/enums/shared-enums';
import { ButtonValue, AgFieldConfig } from 'src/app/common/Models/ag-models';
import { AgDateConfig, SaveButtonObj } from 'src/app/common/Models/ag-models';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { NightAuditBusiness } from '../night-audit.business';
import { Product } from '../../Models/common.models';
import { PropertyInformation } from 'src/app/common/shared/services/property-information.service';
import { EventNotification } from '../../templates/notification-configuration/notification-configuration.model';

@Component({
  selector: 'app-schedule-email',
  templateUrl: './schedule-email.component.html',
  styleUrls: ['./schedule-email.component.scss'],
  providers: [NightAuditBusiness]
})
export class ScheduleEmailComponent implements OnInit {

  scheduleEmailForm: UntypedFormGroup;
  captions: any;
  DialogCloseEnum = DialogCloseEnum;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  startdateInputs: AgDateConfig;
  enddateInputs: AgDateConfig;
  buttonObj: SaveButtonObj;
  distributionList: { id: number; value: string; viewValue: string; }[];
  template: { id: number; value: string; viewValue: string; eventIds: number[] }[];
  successdistributionList: { id: number; value: string; viewValue: string; }[];
  successtemplate: { id: number; value: string; viewValue: string; eventIds: number[] }[];
  failuredistributionList: { id: number; value: string; viewValue: string; }[];
  failuretemplate: { id: number; value: string; viewValue: string; eventIds: number[] }[];
  hoursInput: AgFieldConfig;
  minutesInput: AgFieldConfig;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isSuccessStatusEnabled: boolean = false;
  isFailureStatusEnabled: boolean = false;
  productId: number;
  configTime: Date;
  floatLabel: string;

  constructor(private fb: UntypedFormBuilder, private localization: Localization, private property: PropertyInformation,
    private dialogRef: MatDialogRef<ScheduleEmailComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
  }
  ngOnInit(): void {
    this.initialize();
    this.scheduleEmailForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(_res => {
      this.actionButton.disabledproperty = !(this.scheduleEmailForm.valid && this.scheduleEmailForm.dirty);
      this.actionButton = { ...this.actionButton };
    });
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.LoadData();
  }

  initialize() {
    this.scheduleEmailForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      distributionList: ['', Validators.required],
      template: ['', Validators.required],
      hours: ['', Validators.required],
      minutes: ['', Validators.required],
      successStatus: '',
      failureStatus: '',
      successdistributionList: '',
      successtemplate: '',
      failuredistributionList: '',
      failuretemplate: '',
    });
    this.startdateInputs = {
      className: 'pr-3',
      errorMessage: this.captions.MissingStartDate,
      form: this.scheduleEmailForm,
      formControlName: 'startDate',
      placeHolder: this.captions.lbl_startDate,
      isDateRequired: true,
      minDate: this.property.CurrentDate
    };
    this.enddateInputs = {
      className: '',
      errorMessage: this.captions.MissingEndDate,
      form: this.scheduleEmailForm,
      formControlName: 'endDate',
      placeHolder: this.captions.lbl_endDate,
      isDateRequired: true,
      minDate: this.property.CurrentDate
    };
    this.hoursInput = {
      className: 'w-75 pr-3',
      form: this.scheduleEmailForm,
      formControlName: 'hours',
      placeHolderId: 'lbl_hours',
      placeHolder: this.captions.lbl_hours,
      maxlength: 2,
      isRequired: true,
      disabled: false,
      maxValue: 24,
      minValue: 0
    };
    this.minutesInput = {
      className: 'w-75',
      form: this.scheduleEmailForm,
      formControlName: 'minutes',
      placeHolderId: 'lbl_minutes',
      placeHolder: this.captions.lbl_minutes,
      maxlength: 2,
      isRequired: true,
      disabled: false,
      maxValue: 60,
      minValue: 0
    };
    this.actionButton = {
      type: 'primary',
      label: this.data?.mode == 'edit' ? this.captions.btn_update : this.captions.btn_save,      
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.btn_cancel,      
      disabledproperty: false
    }

  }

  async LoadData() {
    if (this.data) {
      this.LoadTemplateAndDistributionList();
      if (this.data?.mode == 'edit') {
        this.scheduleEmailForm.controls.startDate.setValue(this.data.formData.startDate)
        this.startdateInputs.minDate = this.data.formData.startDate;
        this.scheduleEmailForm.controls.endDate.setValue(this.data.formData.endDate)
        this.scheduleEmailForm.controls.distributionList.setValue(this.data.formData.distributionList)
        this.scheduleEmailForm.controls.template.setValue(this.data.formData.template)
        this.scheduleEmailForm.controls.hours.setValue(this.data.formData.hours)
        this.scheduleEmailForm.controls.minutes.setValue(this.data.formData.minutes)
        this.scheduleEmailForm.controls.successStatus?.setValue(this.data.formData.successStatus)
        if (this.data.formData.successStatus) {
          this.isSuccessStatusEnabled = this.data.formData.successStatus;
          this.scheduleEmailForm.controls.successdistributionList?.setValue(this.data.formData.successdistributionList)
          this.scheduleEmailForm.controls.successtemplate?.setValue(this.data.formData.successtemplate)
        }
        this.scheduleEmailForm.controls.failureStatus?.setValue(this.data.formData.failureStatus)
        if (this.data.formData.failureStatus) {
          this.isFailureStatusEnabled = this.data.formData.failureStatus;
          this.scheduleEmailForm.controls.failuredistributionList?.setValue(this.data.formData.failuredistributionList)
          this.scheduleEmailForm.controls.failuretemplate?.setValue(this.data.formData.failuretemplate)
        }

        this.scheduleEmailForm.updateValueAndValidity();
      }
    }
  }

  LoadTemplateAndDistributionList() {
    this.distributionList = this.data.distributionData;
    this.successdistributionList = this.data.distributionData;
    this.failuredistributionList = this.data.distributionData;
    switch (this.productId) {
      case Product.SPA:
        this.template = this.data.templateData.filter(a => a.eventIds.includes(EventNotification.SpaNightAuditReminder));
        this.successtemplate = this.data.templateData.filter(a => a.eventIds.includes(EventNotification.SpaNightAuditCompletion));
        this.failuretemplate = this.data.templateData.filter(a => a.eventIds.includes(EventNotification.SpaNightAuditFailure));
        break;
      case Product.RETAIL:
        this.template = this.data.templateData.filter(a => a.eventIds.includes(EventNotification.RetailNightAuditReminder));
        this.successtemplate = this.data.templateData.filter(a => a.eventIds.includes(EventNotification.RetailNightAuditCompletion));
        this.failuretemplate = this.data.templateData.filter(a => a.eventIds.includes(EventNotification.RetailNightAuditFailure));
        break;
      case Product.GOLF:
        this.template = this.data.templateData.filter(a => a.eventIds.includes(EventNotification.GolfNightAuditReminder));
        this.successtemplate = this.data.templateData.filter(a => a.eventIds.includes(EventNotification.GolfNightAuditCompletion));
        this.failuretemplate = this.data.templateData.filter(a => a.eventIds.includes(EventNotification.GolfNightAuditFailure));
        break;
      case Product.SNC:
        this.template = this.data.templateData.filter(a => a.eventIds.includes(EventNotification.NightAuditReminder));
        this.successtemplate = this.data.templateData.filter(a => a.eventIds.includes(EventNotification.NightAuditCompletion));
        this.failuretemplate = this.data.templateData.filter(a => a.eventIds.includes(EventNotification.NightAuditFailure));
    }
  }

  closePopUp(from) {
    if (from == 2) {
      this.dialogRef.close({
        from: from,
        form: this.scheduleEmailForm
      })
    } else {
      this.dialogRef.close();
    }
  }
  onAction(e) {
    this.closePopUp(DialogCloseEnum.Action);
  }
  onCancel(e) {
    this.closePopUp(DialogCloseEnum.Cancel);
  }
  onStartDateChanged(e) {
    let startDate = this.scheduleEmailForm.controls.startDate.value;
    this.enddateInputs.minDate = new Date(startDate);
    this.enddateInputs = {...this.enddateInputs};
  }
  onEndDateChanged(e){
    let endDate = this.scheduleEmailForm.controls.endDate.value;
    this.startdateInputs.maxDate = new Date(endDate);
    this.startdateInputs = {...this.startdateInputs};
  }
  successStatus(e) {
    this.isSuccessStatusEnabled = e.checked;
    if (e.checked) {
      this.scheduleEmailForm.controls.successdistributionList?.setValidators(Validators.required);
      this.scheduleEmailForm.controls.successdistributionList?.updateValueAndValidity();
      this.scheduleEmailForm.controls.successtemplate?.setValidators(Validators.required);
      this.scheduleEmailForm.controls.successtemplate?.updateValueAndValidity();
    }
    else {
      this.scheduleEmailForm.controls.successdistributionList?.clearValidators();
      this.scheduleEmailForm.controls.successdistributionList?.setValue('');
      this.scheduleEmailForm.controls.successdistributionList?.updateValueAndValidity();
      this.scheduleEmailForm.controls.successtemplate?.clearValidators();
      this.scheduleEmailForm.controls.successtemplate?.setValue('');
      this.scheduleEmailForm.controls.successtemplate?.updateValueAndValidity();
    }
  }
  failureStatus(e) {
    this.isFailureStatusEnabled = e.checked;
    if (e.checked) {
      this.scheduleEmailForm.controls.failuredistributionList?.setValidators(Validators.required);
      this.scheduleEmailForm.controls.failuredistributionList?.updateValueAndValidity();
      this.scheduleEmailForm.controls.failuretemplate?.setValidators(Validators.required);
      this.scheduleEmailForm.controls.failuretemplate?.updateValueAndValidity();
    }
    else {
      this.scheduleEmailForm.controls.failuredistributionList?.clearValidators();
      this.scheduleEmailForm.controls.failuredistributionList?.setValue('');
      this.scheduleEmailForm.controls.failuredistributionList?.updateValueAndValidity();
      this.scheduleEmailForm.controls.failuretemplate?.clearValidators();
      this.scheduleEmailForm.controls.failuretemplate?.setValue('');
      this.scheduleEmailForm.controls.failuretemplate?.updateValueAndValidity();
    }
  }
}
