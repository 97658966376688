<!-- displaying the sidebar menu names -->

<div class="aside h-100" #sideMenu [ngClass]="{'show-side-menu':_as.hideSideMenu}">
  <div class="aside__scroll-icon " [ngClass]="{'aside__scroll-up': showScroll,'aside_enable-up': disableUpArrow}"
    (click)="viewPrevMenu()">
    <span class="icon-Path-507"></span>
  </div>
  <div class="aside__menu-list">
    <ul>
      <li *ngFor="let item of menuNames.menus" class="aside__menu-header" (click)="setSelectedMenu(item.id);navigate($event,item);">
        <div *ngIf="item.id !== 9" class="aside__menu-content" [ngClass]="{'disabledMenu':item.link == '','aside__selected-menu':item.id == _as.selectedMenuId}">
          <a>
            <i *ngIf="(_as.isRetailLoggedinFailed) && item.id == 12" class="icon-exclamation aside__menu-warning"><i
              class="path1"></i><i class="path2"></i><i class="path3"></i></i>
              <i class="{{item.icon_name}} aside__menu-icon"></i>
            </a>
            <p class="aside__menu-label">{{ item.name | translate}}</p>
          </div>
        </li>
      </ul>
      <!-- <ng-container > -->
      <!-- <div *ngIf="item.id!==9" class="aside__menu"
        [ngClass]="{'disabledMenu':item.link == '','aside__selected-menu':item.id == _as.selectedMenuId}"
        (click)="setSelectedMenu(item.id)">
        <a class="aside__menu-name" (click)="navigate($event,item)">

          <i *ngIf="(_as.isRetailLoggedinFailed) && item.id == 12" class="icon-exclamation aside__menu-warning"><i
              class="path1"></i><i class="path2"></i><i class="path3"></i></i>
          <i class="{{item.icon_name}} aside__menu-icon"></i>
          <span class="aside__menu-text">{{ item.name | translate}}</span> -->
          <!-- <span id={{item.name}} class="aside__notification-text seat-notification green-color" *ngIf="item.id == countNotificationMenus.reservationMenuText">{{_as.reservationMenuCount}}</span>
      <span id={{item.name}} class="aside__notification-text seat-notification green-color" *ngIf="item.id == countNotificationMenus.waitListMenuText">{{_as.waitlistMenuCount}}</span> -->
        <!-- </a>
      </div> -->
    <!-- </ng-container> -->

  </div>
  <div class="aside__scroll-icon " [ngClass]="{'aside__scroll-down':showScroll,'aside_enable-up': disableDownArrow}"
    (click)="viewNextMenu()">
    <span class="icon-Path-507"></span>
  </div>
</div>
