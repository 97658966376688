<div >
    <div *ngIf="!showActivityCreation">
        <div *ngIf="showActivitiesList">
            <app-activity-table [tableData]="tableData" [selectedFilters]="filterMenu?.selection" [createButton]="createButton" [isEdit]="isEdit" [activities]="activities"
                (createEvent)="createActivity($event)" (removeLesson)="removeActivity($event)" (filterEvent)="setFilterItem($event)" [isTemplate]="false" [hideDateFilter]="false"></app-activity-table>
        </div>
    </div>
    <div *ngIf="showActivityCreation">
        <app-basic-setup [activityData]="basicSettings" (cancelEvent)="cancelActivity()" (saveEvent)="refreshActivity($event)" [isEdit]="isEdit" [isTemplate]="false" [currentRestaurantDateTime]="currentRestaurantDateTime"></app-basic-setup>
    </div>
</div>
 