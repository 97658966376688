import { cloneDeep } from 'lodash';
import { Directive, ElementRef, HostListener, Input, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities, InputTypeNumbers, InputTypeText } from 'src/app/common/shared/shared/utilities/common-utilities';
import { InputTypeValidator } from '../validators/inputtypeValidator';
import { ViewSettingClientBusiness } from '../shared/shared/common-functionalities/business/view-settings.business';



@Directive({
  selector: "[percentinputtype]"
})

export class PercentInputtypeDirective implements AfterViewInit {

  private listOfNumberTypes: string[] = [InputTypeNumbers.NUMBERS
    , InputTypeNumbers.ONLYPOSITIVE
    , InputTypeNumbers.ONLYNEGATIVE
    , InputTypeNumbers.NODECIMAL
    , InputTypeNumbers.DECIMAL
    , InputTypeNumbers.ROUNDOFF
    , InputTypeNumbers.PERCENT
    , InputTypeNumbers.POSITIVEDECIMAL
    , InputTypeNumbers.POSITIVEDECIMALORNUMERIC
    , InputTypeNumbers.NUMBERWITHSEPARATOR
    , InputTypeNumbers.PREMAXDECIMAL];

  private listOfTextType: string[] = [InputTypeText.CAP
    , InputTypeText.TEXT
    , InputTypeText.NOSPL
    , InputTypeText.NOSPACE
    , InputTypeText.EMAIL
    , InputTypeText.FIRST_CHAR_CAP
    , InputTypeText.NOPRESPACE
    , InputTypeText.WEBSITE
    , InputTypeText.RESERVEDCHAR,
      InputTypeText.HYPHENBRACES];

  constructor(private element: ElementRef, private control: NgControl, public localization: Localization, private utils: CommonUtilities, private business: ViewSettingClientBusiness) {
    // in retail private business: RetailService
    // in common shared  private business: ViewSettingClientBusiness

  }
  @Input() percentinputtype: string;
  @Input() skipInputValidation = false;
  @Input() showErrPopup = false;
  @Input() maxlength = 0;
  @Input() maxpredecimalValue;
  @Input()
  max?: string | number; // not in retail and common
  @Input()
  min?: string | number;
  private givenListType: string[];
  private negativeSign = '-';
  private DecimalSeperator: string = this.localization.decimalSeparator;
  private CommaSeperator = ',';

  includesArrayValue(baseArray: string[], inputArray: string[]): boolean {

    return inputArray?.every((value) => {
      return (baseArray.indexOf(value) >= 0);
    });

  }

  ngAfterViewInit() {
    const input = cloneDeep(this.element.nativeElement.value);
    const valueEntered: string = input;
    if (this.percentinputtype) {
      this.givenListType = this.percentinputtype.replace(/ /g, '').split(',');
      if (this.givenListType.includes(InputTypeNumbers.DECIMAL) || this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMAL) || this.givenListType.includes(InputTypeNumbers.PREMAXDECIMAL)) {
        this.addDecimalSeperator(valueEntered);
      }
    }
  }

  @HostListener('blur', ['$event']) onblur(event) {
    if (this.skipInputValidation) {
      return;
    }
    const e = event as KeyboardEvent;
    this.givenListType = this.percentinputtype?.replace(/ /g, '').split(',');
    if (this.includesArrayValue(this.listOfNumberTypes, this.givenListType)) {
      this.OnBlurFunction(e);
    }
    if (this.includesArrayValue(this.listOfTextType, this.givenListType)) {
      this.capitalizeblur(e);
      this.OnBlurFunction(e);
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    let valueEntered = this.localization.removeThousandSeparator(value);
    this.control.control.setValue(valueEntered, {emitEvent : false});
  }

  @HostListener("paste", ['$event']) blockPaste(e: ClipboardEvent) {
      e.preventDefault();
  }

  @HostListener('input', ['$event']) oninput(event) { // not in retail and shared common
    if (this.skipInputValidation) {
      return;
    }    
    if (!this.percentinputtype) {
      this.percentinputtype = '';
    }

    if (event.target.type === 'number' && this.givenListType.includes(InputTypeNumbers.ONLYPOSITIVE)) {
      let valueEntered = event.target.value;
      valueEntered = valueEntered ? parseInt(valueEntered) : '';
      if (valueEntered < 0) {
        this.control.control.setValue(0);
      }
    }

    if (this.givenListType.includes(InputTypeText.EMAIL)) {
      let valueEntered = event.target.value;
      valueEntered = valueEntered ? valueEntered : '';
      if (!this.validateEmail(valueEntered)) {
        if (valueEntered.trim() != '') {
          this.control.control.setErrors({ incorrect: true });
        }
      }
    }

    if (this.givenListType.includes(InputTypeText.WEBSITE)) {
      let valueEntered = event.target.value;
      valueEntered = valueEntered ? valueEntered : '';
      if (!this.validateWebsite(valueEntered)) {
        if (valueEntered.trim() != '') {
          this.control.control.setErrors({ incorrect: true });
        }
      }
    }

    if (this.givenListType.includes(InputTypeNumbers.PERCENT)) {
      let valueEntered = event.target.value;
      valueEntered = valueEntered ? valueEntered : '';
      // if (valueEntered == '') {
      //   this.control.control.setValue('0.00');
      // }
      valueEntered = this.localization.removeThousandSeparator(valueEntered);
      if (parseFloat(valueEntered.replace(this.DecimalSeperator,'.')) > 100) {
        // below two lines needed in retail and common shared
        if (this.showErrPopup) {
          let errorMessage = this.localization.replacePlaceholders(this.localization.captions.retailsetup.MaximumDiscountValue, ['MaxNumber'], [this.localization.localizePercentageDirective('100.00')]);
          this.business.openErrorDialog(errorMessage, this.localization.captions.common.Error, this.localization.captions.common.Error);
          this.control.control.setValue('0.00');
        } else {
          if (this.max && valueEntered.replace(this.DecimalSeperator,'.') > this.max) { // not in retail and common
            this.control.control.setErrors({ max: true });
          }
          if (this.min && valueEntered.replace(this.DecimalSeperator,'.') < this.min) { // not in retail and common
            this.control.control.setErrors({ min: true });
          }
        }
      }
    }  
  }

  @HostListener('keyup', ['$event']) onkeyup(event) {
    if (this.skipInputValidation) {
      return;
    }    
    if (!this.percentinputtype) {
      this.percentinputtype = '';
    }
    if (this.IsMaxLengthExceeded(event)) { // in retail and common
      event.preventDefault();
      return;
    }
    this.givenListType = this.percentinputtype.replace(/ /g, '').split(',');
    if (this.includesArrayValue(this.listOfTextType, this.givenListType)) {
      this.InputTextType(event);
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if (this.skipInputValidation) {
      return;
    }
    const e = event as KeyboardEvent;
    if (!this.percentinputtype) {
      this.percentinputtype = '';
    }
    this.givenListType = this.percentinputtype.replace(/ /g, '').split(',');
    if (this.includesArrayValue(this.listOfNumberTypes, this.givenListType)) {
      this.InputTypeNumberValidation(e);
    }
    if (this.includesArrayValue(this.listOfTextType, this.givenListType)) {
      this.InputTextType(e);
    }

    if (this.givenListType.includes(InputTypeNumbers.ROUNDOFF)) {
      this.RestrictRoundoff2(e);
    }
  }

  OnBlurFunction(e: KeyboardEvent) {
    const input = (e.target as HTMLInputElement);
    let valueEntered: string = input.value ? input.value : '';


    if (this.givenListType.includes(InputTypeNumbers.DECIMAL) || this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMAL) || this.givenListType.includes(InputTypeNumbers.PREMAXDECIMAL)) {
      this.addDecimalSeperator(valueEntered);
    }

    if (this.givenListType.includes(InputTypeNumbers.PERCENT)) {
      if (valueEntered == '') {
        this.control.control.setValue('0.00');
      }
      if (parseFloat(valueEntered.replace(this.DecimalSeperator,'.')) > 100) {
        // below two lines needed in retail and common shared
        if (this.showErrPopup) {
          let errorMessage = this.localization.replacePlaceholders(this.localization.captions.retailsetup.MaximumDiscountValue, ['MaxNumber'], [this.localization.localizePercentageDirective('100.00')]);
          this.business.openErrorDialog(errorMessage, this.localization.captions.common.Error, this.localization.captions.common.Error);
          this.control.control.setValue('0.00');
        } else {
          if (this.max && valueEntered.replace(this.DecimalSeperator,'.') > this.max) { // not in retail and common
            this.control.control.setErrors({ max: true });
          }
          if (this.min && valueEntered.replace(this.DecimalSeperator,'.') < this.min) { // not in retail and common
            this.control.control.setErrors({ min: true });
          }
        }
      }
    }

    if (this.givenListType.includes(InputTypeText.EMAIL)) {
      if (!this.validateEmail(valueEntered)) {
        if (valueEntered.trim() != '') {
          this.control.control.setErrors({ incorrect: true });
        }
      }
    }
    // not in retail and common shared
    //decimal weith maxlength
    if (this.givenListType.includes(InputTypeNumbers.PREMAXDECIMAL) || this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMAL)) {
      if (!valueEntered.includes(this.DecimalSeperator)) {
        let preDecimalValue = valueEntered.trim();
        this.maxrestrictor(preDecimalValue, this.maxpredecimalValue)

      } else {
        let afterSplited = valueEntered.trim().split(this.DecimalSeperator);
        this.maxrestrictor(afterSplited[0], this.maxpredecimalValue, afterSplited[1])

        const splitVal = valueEntered.split(this.DecimalSeperator);
        if (splitVal[1].length > 2) {
          let value: any = this.localization.currencyToSQLFormat(valueEntered);

          // value = value.customToFixed();
          value = value.toFixed(2);
          this.control.control.setValue(value);
          valueEntered = this.control.control.value;
        } else if (splitVal[1].length < 2) {
          const value = splitVal[1].length == 0 ? '00' : splitVal[1] + '0';
          const roundedValue = splitVal[0] + this.DecimalSeperator + value;
          this.control.control.setValue(roundedValue);
          valueEntered = this.control.control.value;
        }
      }
    }

    // RegEx Validation for the givenType
    this.regexValidator(valueEntered);

  }

  // not in retail and common shared
  //decimal weith maxlength (resitrctor)
  maxrestrictor(valueEntered, maxval, afterDecimalValue?) {
    if (valueEntered.length > maxval && valueEntered.charAt(0) !== '-') {
        valueEntered = valueEntered.slice(0, maxval);
    }
    const afterValue = afterDecimalValue ? afterDecimalValue : '00';
    this.control.control.setValue(valueEntered == '' ? '' : valueEntered.toString() + this.DecimalSeperator + afterValue);    
  } 

  regexValidator(valueEntered) {

    if (valueEntered.trim() != '') {

      // Validate only numbers & non negative types
      if (this.givenListType.includes(InputTypeNumbers.NUMBERS) && !this.givenListType.includes(InputTypeNumbers.DECIMAL)) {
        if (!this.ValidateNumbers(valueEntered)) {
          this.control.control.setValue('');
          this.control.control.setErrors({ incorrect: true });
        }
      } else if (this.givenListType.includes(InputTypeNumbers.ONLYPOSITIVE) && !this.givenListType.includes(InputTypeNumbers.DECIMAL)) {
        if (!this.ValidateNonNegative(valueEntered)) {
          this.control.control.setValue('');
          this.control.control.setErrors({ incorrect: true });
        }
      } else if (this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMAL)) {
        if (!this.ValidatePositiveDecimal(valueEntered)) {
          this.control.control.setValue('');
          this.control.control.setErrors({ incorrect: true });
        }
      } else if (this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMALORNUMERIC)) {
        valueEntered = valueEntered.indexOf(this.DecimalSeperator) !== -1 ? parseFloat(valueEntered).toFixed(2) : valueEntered;
        this.control.control.setValue(valueEntered);
        if (!this.ValidatePositiveDecimalNumbers(valueEntered)) {
          this.control.control.setValue('');
          this.control.control.setErrors({ incorrect: true });
        }
      } else if (this.givenListType.includes(InputTypeNumbers.NUMBERWITHSEPARATOR)) {
        const lastChar = valueEntered[valueEntered.length - 1];
        const firstChar = valueEntered[0];
        if (lastChar === this.CommaSeperator) {
          valueEntered = valueEntered.slice(0, -1);
          this.control.control.setValue(valueEntered);
        }
        if (firstChar === this.CommaSeperator) {
          valueEntered = valueEntered.substr(1);
          this.control.control.setValue(valueEntered);
        }
        if (!this.validateCommaSeparatedPattern(valueEntered)) {
          this.control.control.setValue('');
          this.control.control.setErrors({ incorrect: true });
        }
      }
    }
  }

  ValidatePositiveDecimalNumbers(text) {
    if (this.ValidatePositiveDecimal(text)) {
      return true;
    } else {
      return this.ValidateNonNegative(text);
    }
  }


  ValidatePositiveDecimal(text) {    
    const regx = new RegExp('^\\d+(' + this.DecimalSeperator + '\\d{2})$');
    return regx.test(String(text).toLowerCase());
  }

  ValidateNonNegative(text) {
    const regx = /^\d+$/;
    return regx.test(String(text).toLowerCase());
  }

  ValidateNumbers(text) {
    const reg = /^-?[\d]+$/;
    return reg.test(String(text).toLowerCase());
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateSomeSpecialCharacter(specialChar) {
    const re = /^([a-zA-Z0-9~._-]*)$/;
    return re.test(String(specialChar).toLowerCase());
  }

  validateWebsite(website) {
    const re = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    return re.test(String(website).toLowerCase());
  }

  validateCommaSeparatedPattern(text) {
    const reg = /^[\d]+(,[\d]+)*$/;
    return reg.test(String(text).toLowerCase());
  }

  InputTypeNumberValidation(e: KeyboardEvent): void {
    const input = (e.target as HTMLInputElement);
    let valueEntered: string = input.value;
    valueEntered = valueEntered ? valueEntered : '';
    const lastChar = valueEntered[valueEntered.length - 1];
    if (
      // Allow function keys
      (e.keyCode >= 112 && e.keyCode <= 123) ||
      // Allow down arrow if the value is greater than zero
      (e.keyCode === 40 && ((this.givenListType.includes(InputTypeNumbers.ONLYPOSITIVE) && parseInt(valueEntered) > 0) ||
      !this.givenListType.includes(InputTypeNumbers.ONLYPOSITIVE))) ||
      //allow minus - onlynumber,only negative
      (input.selectionStart == 0 && (this.givenListType.includes(InputTypeNumbers.NUMBERS) || this.givenListType.includes(InputTypeNumbers.ONLYNEGATIVE)) &&
        e.key == this.negativeSign && (valueEntered.indexOf(this.negativeSign) == -1 || input.selectionEnd == valueEntered.length)) ||
      // Decimal Seperator - Region
      (!this.givenListType.includes(InputTypeNumbers.NODECIMAL) && valueEntered.indexOf(this.DecimalSeperator) == -1 && this.DecimalSeperator == e.key) ||
      (this.givenListType.includes(InputTypeNumbers.NUMBERWITHSEPARATOR) && this.CommaSeperator == e.key && lastChar != this.CommaSeperator) ||
      //Allow default options like delete..
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode < 40)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105) ||
      //non negative , includes zero
      (this.givenListType.includes(InputTypeNumbers.ONLYPOSITIVE) && e.key == this.negativeSign)
    ) {
      e.preventDefault();
    }

  }

  omit_special_char(e) {
    // Allow function keys
    return (e.keyCode >= 112 && e.keyCode <= 123) ||
      //Allow default options like delete..
      [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||  // retail and common shared has 110
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      //Allow: Space
      (e.keyCode === 32) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39) ||
      !(/[^a-zA-Z0-9]/.test(e.key));     
  }

  allow_some_special_char(e) {
    // Allow function keys
    return (e.keyCode >= 112 && e.keyCode <= 123) ||
      //Allow default options like delete..
      [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||  // retail and common shared has 110
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      //Allow: Space
      // (e.keyCode === 32) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39) ||
      (/^([a-zA-Z0-9~._-]*)$/.test(e.key)); 
  }

  validHyphenBraces(text:string):boolean{
    const reg = /^[a-zA-Z0-9-()]+$/;
    return reg.test(text.toLowerCase());
  }

  RestrictRoundoff2(e: KeyboardEvent) {
    const input = (e.target as HTMLInputElement);
    let valueEntered: string = input.value;
    valueEntered = valueEntered ? valueEntered : '';
    if (valueEntered.indexOf(this.DecimalSeperator) >= 0) {
      const arr = valueEntered.split(this.DecimalSeperator);
      if (
        // Allow function keys
        (e.keyCode >= 112 && e.keyCode <= 123) ||
        //allow minus - onlynumber,only negative
        (input.selectionStart < 1 && (this.givenListType.includes(InputTypeNumbers.NUMBERS) || this.givenListType.includes(InputTypeNumbers.ONLYNEGATIVE)) &&
          e.key == this.negativeSign && (valueEntered.indexOf(this.negativeSign) == -1 || input.selectionEnd == valueEntered.length)) ||
        // Decimal Seperator - Region
        (!this.givenListType.includes(InputTypeNumbers.NODECIMAL) && valueEntered.indexOf(this.DecimalSeperator) == -1 && this.DecimalSeperator == e.key) ||
        //Allow default options like delete..
        [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39) || (input.selectionEnd > input.selectionStart) ||
        //allow input before decimal seperator
        (valueEntered.indexOf(this.DecimalSeperator) >= input.selectionStart)) {
        // let it happen, don't do anything
        return;
      }
      if (arr[1].length > (this.localization.fractionLength-1)) {
        e.preventDefault();
      }
    }
  }

  InputTextType(e: KeyboardEvent): void {
    if (this.givenListType.includes(InputTypeText.NOSPL)) {
      if (!this.omit_special_char(e)) {
        if (this.showErrPopup) {
          this.utils.showError(this.localization.captions.common.Specialcharactersarenotallowed);          
        }
        e.preventDefault();
        return;
      }
    }

    if (this.givenListType.includes(InputTypeText.HYPHENBRACES)) {
      let valueEntered = e.key;
      valueEntered = valueEntered ? valueEntered : '';
      if (!this.validHyphenBraces(valueEntered)) {       
        e.preventDefault();
        return;
      }
    }

    if (this.givenListType.includes(InputTypeText.RESERVEDCHAR)) {
      if (!this.allow_some_special_char(e)) {
        e.preventDefault();
        return;
      }
    }

    if (this.givenListType.includes(InputTypeText.TEXT)) {
      if (Number(e.key) > 0) {
        e.preventDefault();
        return;
      }
    }

    // uncomment for retail and common shared


    if (this.givenListType.includes(InputTypeText.CAP)) {
      let value = this.element.nativeElement.value;
      value = value ? value.toUpperCase() : "";
      this.control.control.setValue(value.toUpperCase());
    }
    if (this.givenListType.includes(InputTypeText.NOSPACE)) {
      if (e.which === 32) {
        e.preventDefault();
      }
    }
    if (this.givenListType.includes(InputTypeText.FIRST_CHAR_CAP)) {
      let value = this.element.nativeElement.value;
      value = value ? value : '';
      if (value.length > 0) {
        value = `${value[0].toUpperCase()}${value.slice(1, value.length)}`;
      }
      this.control.control.setValue(value);
    }
    if (this.givenListType.includes(InputTypeText.NOPRESPACE)) {
      let value = this.element.nativeElement.value;
      value = value ? value : '';
      if (e.which === 32 && value.trim().length === 0) {
        this.control.control.setValue(value.trim());
        e.preventDefault();
      }
    }
  }

  capitalizeblur(e: KeyboardEvent) {
    if (this.givenListType.includes(InputTypeText.CAP)) {
      let value = this.element.nativeElement.value;
      value = value ? value.toUpperCase() : '';
      this.control.control.setValue(value.toUpperCase());
    }
  }

  addDecimalSeperator(valueEntered) {
    if (!valueEntered) {
      return;
    }
    if (!valueEntered.includes(this.DecimalSeperator)) {
      this.control.control.setValue(this.localization.localizePercentageDirective(valueEntered.trim() == '' ? '' : valueEntered.toString() + this.DecimalSeperator + '00'));      
    } else {
      const splitVal = valueEntered.split(this.DecimalSeperator);
      if (splitVal[1].length > 2) {
        let value: any = this.localization.currencyToSQLFormat(valueEntered);
        value = value.toFixed(2);
        const localizedCurrency = this.localization.localizePercentageDirective(value);
        this.control.control.setValue(localizedCurrency);        
      } else if (splitVal[1].length <= 2) {
        const value = (splitVal[1].length == 2) ? splitVal[1] : splitVal[1].length == 0 ? '00' : splitVal[1] + '0';
        const roundedValue = splitVal[0] + '.' + value;
        this.control.control.setValue(this.localization.localizePercentageDirective(roundedValue));        
      }
    }
  }

  
  IsMaxLengthExceeded(e: KeyboardEvent) {
    let isLengthExceeded = false;
    if (e.key == this.localization.decimalSeparator || this.AllowDefaultKeys(e)) {
      return isLengthExceeded;
    }
    let input = (<HTMLInputElement>e.target);
    let value = input.value.toString().replace("-", "");
    if (value.indexOf(this.localization.decimalSeparator) == -1) { // validate non decimal when decimal separator not entered
      isLengthExceeded = value.length == this.maxpredecimalValue;
    }
    else if (input.selectionStart <= value.indexOf(this.localization.decimalSeparator)) // validate non decimal when decimal separator entered
    {
      let splitArray = value.split(this.localization.decimalSeparator);
      isLengthExceeded = splitArray[0].length == this.maxpredecimalValue;
    }
    return isLengthExceeded;
  }

  AllowDefaultKeys(e: KeyboardEvent) {
    if (  //Allow default options like delete..
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return true;
    }
    return false;
  }
}
