import { Component, Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SlotProcessor } from '../SlotProcessor';
import { PartyService } from '@app/shared/services/party.service';
import { TimelineDragHandlerService } from '@app/activities-timeline/services/timeline-scroll-handler.service';
import { BlockingrulePopupservice } from '@app/shared/components/create-update-blocking-tab-layout/blockingrulepopup.service';
import { SettingsService } from '@app/shared/services/settings.service';
import { TablesService } from '@app/shared/services/tables.service';
import { CacheService } from '@app/core/services/cache.service';
import { PopupService } from '@app/popup-module/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigDateFormatter } from '../activities-timeline-main.component';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
@Component({
  selector: 'app-timeline-column',
  templateUrl: './timeline-column.component.html',
  styleUrls: ['./timeline-column.component.scss']
})
export class TimelineColumnComponent extends SlotProcessor implements OnChanges {
  @Input() rowWidth: number;
  @Input() sessionWidth: number;
  @Input() sessionLength: number;
  @Input() expandMode: boolean;
  @Output() expandModeChange: EventEmitter<any> = new EventEmitter();
  defaultMaxRowDisplay: number;
  minSessionWidth: number;


  constructor(protected dialog: MatDialog,public partyService: PartyService,public dragHandlerService: TimelineDragHandlerService,
    blockingrule: BlockingrulePopupservice, 
     ps: PopupService, settingservice: SettingsService, tableService: TablesService,
     cs: CacheService, protected translateService: TranslateService, protected dateFormatter: ConfigDateFormatter,
     protected dashboardFunctions: DashboardFunctions
  ) { 
    super(dialog, blockingrule, ps, settingservice, tableService, cs, translateService, dateFormatter, dashboardFunctions);
  }

  ngOnInit(): void {
    this.setSessions();
    this.calculateWidth();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['rowData'] || changes['noOfBookings'] || changes['selectedViewId'] || changes['CurrentDateSelected'] || changes['sessionLength'] || changes['sessionWidth'] || changes['expandMode']) {
      this.setSessions();
      this.calculateWidth();
    }
  }

  changeExpandMode(){
    this.expandModeChange.emit(!this.expandMode);
  }

  calculateWidth() {
    this.showExpandButton = false;
    this.minSessionWidth = (this.sessionWidth < 150) ? this.sessionWidth : 150;
    let sessionRowDisplay = Math.round(this.sessionWidth / this.minSessionWidth);
    for(var i=0, n=this.allSessions.length; i<n; i++) {
      if(this.allSessions[i].group) {
        if(this.sessionWidth / this.allSessions[i].rows < this.minSessionWidth) {
          this.allSessions[i].defaultMaxRowDisplay = this.allSessions[i].rows - sessionRowDisplay;
          if(this.allSessions[i].defaultMaxRowDisplay > 0)
          this.showExpandButton = true;
        }
        else {
          this.minSessionWidth = this.sessionWidth / this.allSessions[i].rows;        }
      }
    }
  }

}

@Directive({
  selector: "[rowColumnGenerator]"
})

export class RowColumnGeneratorDirective implements OnChanges {

  @Input('rowColumnGenerator') timeArrayGenerated;
  @Input('rowWidth') rowWidth;

  constructor(private el: ElementRef) {
  }

  ngOnChanges(): void {
    let value = `repeat(${this.timeArrayGenerated},minmax(${this.rowWidth}px, ${this.rowWidth}px))`;
    this.el.nativeElement.style.gridTemplateRows = value;
  }
}

@Directive({
  selector: '[gridTemplateDirective]'
})
export class GridTemplateDirective {

  @Input('gridTemplateDirective') sessionRows: number;
  @Input('isExpandable') isExpandable: boolean = false;
  @Input('isExpanded') expandMode: boolean = false;
  @Input('defaultMaxRowDisplay') defaultMaxRowDisplay: number;
  @Input('sessionWidth') sessionWidth: number;

  constructor(private el: ElementRef) {
  }

  ngOnChanges() {
    if(this.isExpandable && !this.expandMode && this.defaultMaxRowDisplay) {
      let displayRows = this.sessionRows - this.defaultMaxRowDisplay;
      let eachSlotWidth = (this.sessionWidth / (this.sessionRows - this.defaultMaxRowDisplay)) - ((this.sessionWidth <= 150) ? 45 : 25)
      let value = `repeat(${displayRows}, ${eachSlotWidth}px) ${(this.sessionWidth - (eachSlotWidth * displayRows)) - 15}px`;
      this.el.nativeElement.style.gridTemplateColumns = value;
    }
    else {
      this.el.nativeElement.style.gridTemplateColumns = `none`;
    }
  }
}