import { CommonModule } from '@angular/common';
import { NgModule, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { ITabOutputDetails } from '@app/settings/models/common.interface';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { BlockingDateSelectionComponent } from '@components/create-update-blocking-tab-layout/blocking-date-selection/blocking-date-selection.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { BlockDialogType, ComponentTypes, PopupType, ReservationType, TableBlockBy, TableBlockingRuleFrequencyType } from '@constants/commonenums';
import { popupDialogDimension } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { ActivityCustomizationType, UnblockingTablesRequestDTO, UpdatedTableBlockingRuleDTO } from '@models/RestaurantDTO';
import { newTimeRangeDTO } from '@models/TimeRangeDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { TabConfig } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { SettingsService } from '@services/settings.service';
import { TablesService } from '@services/tables.service';
import { Utilities } from '@utilities/utilities';
import moment from 'moment';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { AdvanceBlockComponent } from './advance-block/advance-block.component';
import { BlockingTableSelectionComponent } from './blocking-table-selection/blocking-table-selection.component';
import { ScheduleSelectionComponent } from './schedule-selection/schedule-selection.component';
import { ShiftSelectionComponent } from './shift-selection/shift-selection.component';
import { DateRangeForBlockComponent } from '../block-activity/date-range-for-block/date-range-for-block.component';
import { ActivityAdvanceBlockSessionComponent } from '../block-activity/SessionSelection/session-selection.component';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { ActivityBlockInfo } from '@app/popup-module/components/services/advance-block.data';

// TODO: Add Angular decorator.
@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

export class BlockingrulePopupservice extends Utilities implements OnDestroy {
  confirmSubscription: ISubscription;
  cancelActionSubscription: ISubscription;
  selectedTablenames: string[] = [];
  subscriptions: Subscription = new Subscription();

  constructor(public ps: PartyService, public appService: AppService, public popupService: PopupService, public ss: SettingsService,
    public dialog: MatDialog, public ts: TranslateService, private cs: CacheService, private tableService: TablesService) {
    super(dialog);
  }

  submitBlockingrules(isEdit, editData) {
    this.EventUnSubscription();
    const reservationFormDetails = this.ps.reservationFormGroup.value;
    if (this.ps.reservationType === ReservationType.Blockingrule) {
      const requestedPayload = {} as UpdatedTableBlockingRuleDTO;
      requestedPayload.ShiftIds = (reservationFormDetails.selectedBlockingType == TableBlockBy.ByShift) ? reservationFormDetails.selectedShift : null;
      requestedPayload.TableIds = reservationFormDetails.selectedTable;
      requestedPayload.FrequencyType = reservationFormDetails.selectedFrequency;
      requestedPayload.BlockingReason = reservationFormDetails.blockingReason;
      requestedPayload.AllocationTypeId = reservationFormDetails.selectedBlockBy;
      requestedPayload.BlockBy = reservationFormDetails.selectedBlockingType;
      if (editData && isEdit) {
        let dateval = Utilities.formateDateString(reservationFormDetails.selectedDate).getDay();
        let val = Number.isNaN(NaN);
        requestedPayload.DayOfWeek = val ? new Date(reservationFormDetails.selectedDate).getDay() : dateval;
      }//(reservationFormDetails.selectedFrequency == TableBlockingRuleFrequencyType.EveryWeekDay) ? reservationFormDetails.selectedDate.getDay() : null;
      else
        requestedPayload.DayOfWeek = new Date(reservationFormDetails.selectedDate).getDay();//(reservationFormDetails.selectedFrequency == TableBlockingRuleFrequencyType.EveryWeekDay) ? reservationFormDetails.selectedDate.getDay() : null;
      if (reservationFormDetails.selectedBlockingType != TableBlockBy.ByShift) {
        requestedPayload.EffectiveRange = {} as newTimeRangeDTO[];
        requestedPayload.EffectiveRange = [];
        requestedPayload.EffectiveRange.push({ Start: reservationFormDetails.selectedFromTime, End: reservationFormDetails.selectedToTime });
      }
      else {
        requestedPayload.EffectiveRange = null;
      }
      if (editData && isEdit) {
        requestedPayload.Id = editData.Id;
        if (!reservationFormDetails.selectedBlockBy) {
          requestedPayload.AllocationTypeId = editData.AllocationTypeId;
        }
      }
      if (reservationFormDetails.selectedFrequency == TableBlockingRuleFrequencyType.TheOnlyDay) {
        const dates = this.getParsedDate(reservationFormDetails.selectedDate);
        requestedPayload.StartDate = dates;
        requestedPayload.EndDate = dates;
      }
      else {
        requestedPayload.StartDate = this.getParsedDate(reservationFormDetails.selectedStartDate);
        requestedPayload.EndDate = this.getParsedDate(reservationFormDetails.selectedEndDate);;
      }
      const floorPlan = this.cs.layout.value.FloorPlans;
      this.selectedTablenames = Utilities.getTableNamesFromStandaloneTables(reservationFormDetails.selectedTable, floorPlan);
      if (isEdit) {
        this.subscriptions.add(this.ss.UpdateBlocks(requestedPayload).subscribe((response) => {
          const message = this.ts.instant('blockingUpdateMsg');
          this.ShowNotification(response, message);
          this.ps.createReservationForm();
        }));

      } else {

        this.subscriptions.add(this.ss.SetQuickBlock(requestedPayload).subscribe((response) => {
          const selectedtableNames = this.getSortedTable();
          const msg = this.selectedTablenames.length > 1 ? this.ts.instant('blockingSuccessMsgs', {selectedtableNames: selectedtableNames}) : this.ts.instant('blockingSuccessMsg', {selectedtableNames: selectedtableNames});
          let message = '';
          //this.ts.get('blockingSuccessMsg', { selectedtableNames }).subscribe(msgVal => message = msgVal);
          this.ShowNotification(response, msg);
          this.ps.createReservationForm();
        }));
      }
    }
   
  }




  submitUnblockingrules() {
    const reservationFormDetails = this.ps.reservationFormGroup.value;
    if (this.ps.reservationType === ReservationType.Unblock) {
      const requestedPayload = {} as UnblockingTablesRequestDTO;
      requestedPayload.Date = this.getParsedDate(reservationFormDetails.selectedDate);
      requestedPayload.FrequencyType = reservationFormDetails.selectedFrequency;
      requestedPayload.TableIds = reservationFormDetails.selectedTable;
      const floorPlan = this.cs.layout.value.FloorPlans;
      this.selectedTablenames = Utilities.getTableNamesFromStandaloneTables(reservationFormDetails.selectedTable, floorPlan);
      this.EventUnSubscription();
      this.subscriptions.add(this.ss.SetUnblock(requestedPayload).subscribe((response) => {
        const selectedtableNames = this.getSortedTable();
        const msg = this.selectedTablenames.length > 1 ? 'unblockingSuccessMsgs' : 'unblockingSuccessMsg';
        let message = '';
        this.ts.get(msg, { selectedtableNames }).subscribe(msgVal => message = msgVal);
        this.ShowNotification(response, message);
        this.ps.createReservationForm();
      }));
    }
  }

  getParsedDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }


  getSortedTable() {
    const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })
    const tablenName = this.selectedTablenames.sort();
    return tablenName.sort((a, b) => collator.compare(a, b));

  }
  createTabsModalDetailsAdvanceedBlock(isDefault: boolean, isNext: boolean, isEdit: boolean, tabInputData: any) {
    this.setReservationType(1);
    this.ps.tabsModal = {
      tabs: this.getComponentDetailsforAdvancedBlock(),
      default: isDefault,
      componentInput: tabInputData,
      isNextButtonAvailable: isNext,
      isEditTab: isEdit,
      buttonText: "blockbuttontext"
    };
    this.openDialognew(1);
  }

  createTabsModalDetailsUnBlock(isDefault: boolean, isNext: boolean, isEdit: boolean, tabInputData: any) {
    this.setReservationType(2);
    this.ps.tabsModal = {
      tabs: this.getComponentDetailsforUnblock(),
      default: isDefault,
      componentInput: tabInputData,
      isNextButtonAvailable: isNext,
      isEditTab: isEdit,
      buttonText: "unblockbuttontext"
    };
    this.openDialognew(2);
  }


  EventSubscription() {
    this.confirmSubscription = this.popupService.confirmedAction$.subscribe((tabOutput: ITabOutputDetails) => {
      if (tabOutput.fromComponent === ComponentTypes.Unblock) {
        this.submitUnblockingrules();
      }
      else if (tabOutput.fromComponent === ComponentTypes.advancedBlock) {
        this.submitBlockingrules(tabOutput.isEdit, tabOutput.inputData);
      }
    });
    this.cancelActionSubscription = this.popupService.cancelledAction$.subscribe(data => {
      if (data.from == ComponentTypes.Unblock || data.from == ComponentTypes.advancedBlock) {
        this.ps.createReservationForm();
      }
    });

  }

  EventUnSubscription() {
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
    if (this.cancelActionSubscription) {
      this.cancelActionSubscription.unsubscribe();
    }
  }

  
  createTabsModalDetailsActivityBlock(isDefault: boolean, isNext: boolean, isEdit: boolean, tabInputData: any,dialogType: number) {
    this.ps.reservationType = ReservationType.ActivityBlock;
    this.ps.tabsModal = {
      tabs: this.getComponentDetailsforActivityBlock(dialogType),
      default: isDefault,
      componentInput: {data : tabInputData ,type: dialogType},
      isNextButtonAvailable: isNext,
      isEditTab: isEdit,
      buttonText: "blockbuttontext"
    };
    // this.popupService.confirmedAction$.subscribe((tabOutput: ITabOutputDetails) => {
    //   alert("Fd");
    //   if (tabOutput.fromComponent === ComponentTypes.Unblock) {
    //     this.submitUnblockingrules();
    //   }
    //   else if (tabOutput.fromComponent === ComponentTypes.advancedBlock) {
    //     this.submitBlockingrules(tabOutput.isEdit, tabOutput.inputData);
    //   }
    // });
    this.openDialognew(dialogType)
  }

getComponentDetailsforActivityBlock(dialogType){
  const tabcomponent: TabConfig[] = [
    {
      tabComponent: ActivityAdvanceBlockSessionComponent,
      enabledIcon: true,
      tabLabel: dialogType == BlockDialogType.block ? this.ts?.instant('blockActivityTabText') : this.ts?.instant('unblockActivityTabText') ,
      tabHeader: dialogType == BlockDialogType.block ? this.ts?.instant('blockActivityTabText'): this.ts?.instant('unblockActivityTabText'),
      tabIcon: 'event_busy',
      isDisabled: false
    },
    {
      tabComponent: DateRangeForBlockComponent,
      enabledIcon: true,
      tabLabel: this.ts?.instant('schedule'),
      tabHeader: this.ts?.instant('popUpTitleSeatDate'),
      tabIcon: 'Group-595',
      isDisabled: true
    }];
    return tabcomponent;
}


  openDialognew(val) {
    this.EventSubscription();
    const dialog = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      panelClass: 'blockingrule-panel',
      data: {
        selectedPopupType: PopupType.Table,
        title: 'Add popup',
        update: 'ok',
        cancel: 'cancel',
        componentDetails: this.getTabDetailsnew(),
        from: val == 1 ? ComponentTypes.advancedBlock : ComponentTypes.Unblock,
        back: true
      }
    });
    dialog.afterClosed().subscribe(() => {
      this.EventUnSubscription();
    })
  }

  setReservationType(val) {
    this.selectedTablenames = [];
    this.ps.reservationType = null;
    if (val == 1) {
      this.ps.reservationType = ReservationType.Blockingrule;
    } else {
      this.ps.reservationType = ReservationType.Unblock;
    }
  }
  getTabDetailsnew() {
    return {
      componentName: TabsComponent,
      dimensionType: 'large',
      popupType: 'tabs',
      tabs: this.ps.tabsModal,
      popUpDetails: {
        isStepper: true,
        eventName: 'notifyParent'
      }
    };
  }

  getComponentDetailsforAdvancedBlock() {
    const tabcomponent: TabConfig[] = [
      {
        tabComponent: BlockingDateSelectionComponent,
        enabledIcon: true,
        tabLabel: this.ts?.instant('schedule'),
        tabHeader: this.ts?.instant('popUpTitleSeatDate'),
        tabIcon: 'Group-595',
        isDisabled: false
      },
      {
        tabComponent: BlockingTableSelectionComponent,
        enabledIcon: true,
        tabLabel: this.ts?.instant('popUpTable'),
        tabHeader: this.ts?.instant('popuptitleseattable'),
        tabIcon: 'Group-592',
        isDisabled: false
      },
      {
        tabComponent: ScheduleSelectionComponent,
        enabledIcon: true,
        tabLabel: this.ts?.instant('frequency'),
        tabHeader: this.ts?.instant('scheduleTitle'),
        tabIcon: 'repeat',
        isDisabled: true
      },
      {
        tabComponent: ShiftSelectionComponent,
        enabledIcon: true,
        tabLabel: this.ts?.instant('Shift'),
        tabHeader: this.ts?.instant('shiftTitle'),
        tabIcon: 'recent-history',
        isDisabled: true
      }
    ];
    let advanceBlockTabLabel: string = 'BlockReason';
    if (this.ps.cs.settings.value != null && this.ps.cs.settings.value.PropertySetting != null &&
      this.ps.cs.settings.value.PropertySetting[0].IsAllocationEnabled && this.ps.cs.settings.value.AllocationType.length > 0) {
        advanceBlockTabLabel = "AllocationCode";
    }

    tabcomponent.push(
      {
        tabComponent: AdvanceBlockComponent,
        enabledIcon: true,
        tabLabel: advanceBlockTabLabel || 'BlockReason',
        tabHeader: 'BlockReason',
        tabIcon: 'recent-history',
        isDisabled: true
      })


    return tabcomponent;
  }

  getComponentDetailsforUnblock() {
    return [

      {
        tabComponent: BlockingDateSelectionComponent,
        enabledIcon: true,
        tabLabel: 'popUpDate',
        tabHeader: 'popUpTitleSeatDate',
        tabIcon: 'Group-595',
        isDisabled: false
      },
      {
        tabComponent: BlockingTableSelectionComponent,
        enabledIcon: true,
        tabLabel: 'popUpTable',
        tabHeader: 'popuptitleseattable',
        tabIcon: 'Group-592',
        isDisabled: false
      },
      {
        tabComponent: ScheduleSelectionComponent,
        enabledIcon: true,
        tabLabel: 'frequency',
        tabHeader: 'unblockTable',
        tabIcon: 'Group-595',
        isDisabled: true
      }

    ];
  }

  ShowNotification(response, message) {
    const confirmationMessages = Utilities.getConfirmationMessage(response, message);
    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', confirmationMessages,
      confirmationMessages[0].dialogTitle);
    let okbutton = this.ts.instant('ok');
    const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.commonconfirmmessage,
      popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeight, false, '', okbutton, '', true);
    this.subscriptions.add(dialogRef.afterClosed().subscribe((dialogCloseData) => {
      this.tableService.shiftSelectedForBlockingRule = 0;
      this.tableService.getBlockingRulesFortheDate(new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)));
    }));
  }
  ngOnDestroy() {
    this.ps.reservationType = null;
    this.EventUnSubscription();
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

}
