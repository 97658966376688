<app-create-modal *ngIf="_shopservice.ProductId != GolfId" [input]="createModalData" (clickEvent)="onCancel($event)">
    <router-outlet></router-outlet>
</app-create-modal>
<app-create-modal *ngIf="_shopservice.ProductId == GolfId && playerInfo?.length > 0" [input]="createModalData" (clickEvent)="onCancel($event)">
    <mat-tab-group (selectedTabChange)="selectedTabValue($event)" class="ag_container--padding-xs tab-section">
        <mat-tab [label]="captions.retailItems">
            <app-retail-items [from]="itemTypeEnum.retailItem"></app-retail-items>
        </mat-tab>
        <mat-tab *ngIf="featureFlagInformation.IsRentalEnabled" [label]="captions.retailsetup.PMSAddOnsRentalItem">
            <app-retail-items [from]="itemTypeEnum.PMSAddOnsRentalItem"></app-retail-items>
        </mat-tab>
    </mat-tab-group>
    <div class="ag_display--flex ag_footer--actions">
        <button [attr.automationId]="'Txt_viewRetailItems_save'" type="button" class="body-bgcolor whitecolor LW14 buy-button ml-auto" (click)="onSave($event)"
        [ngClass]="{'ag_section--disable' : (!_shopservice.isdisableUpdate &&_shopservice.selectedProducts.length == 0) || (_shopservice.isdisableUpdate && _shopservice.selectedProducts.length > 0)}"
        mat-raised-button>{{ addorUpdateLabel }}</button>
        <app-button [buttontype]="cancelButton" class="ag_ml--2" (valueChange)='onCancel($event)' [attr.automationId]="'Txt_viewRetailItems_save'">
        </app-button>
    </div>
</app-create-modal>