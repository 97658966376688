import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { AuditlogComponent } from '@components/auditlog/auditlog.component';
import { buttonTypes, ComponentTypes, Labels, ManualSlotSelection, ReservationType, RolesAndPermissionsType, SlottingMode, SlottingType } from '@constants/commonenums';
import { controlSettings, popupDialogDimension } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { UpdatedManualSlotDTO } from '@models/ManualSlotDTO';
import { ManualSlotIdDTO, SettingsDTO, ShiftDTO, SlotOutput, TableSuggestionMode } from '@models/RestaurantDTO';
import { AuditableEntityType } from '@models/SimpleAuditLogItemDTO';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SlotTimeFormatter } from '@pipes/slot-time-formatter.pipe';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import { cloneDeep, forEach, remove } from 'lodash';
import moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';
import { GeneralDataComponent } from '../general-data/general-data.component';
import { ManualSlotComponent } from '../manual-slot/manual-slot.component';
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';
import { AlertType, ButtonType } from '@app/shared/shared-models';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';

@Component({
  selector: 'app-wait-time',
  templateUrl: './wait-time.component.html',
  styleUrls: ['./wait-time.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class WaitTimeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() tabLabel: EventEmitter<any> = new EventEmitter();
  @Input() selectedActivity: any;
  @Input() isenableMultipleSlotSelection = false;
  @Input() setSlotsForAllShits = false;
  @Output() slotUpdateEvent: EventEmitter<any> = new EventEmitter();
  @Input() isEditCheckInAllowed: boolean;
  @Input() specialMealFilter:any;
  @Input() defaultValueForAllSlots:boolean;
  isReservationtypeReserve: boolean;
  isOpenBooking: boolean;
  timeArray: any[] = [this.ts.instant('auto'), '5' + " " + this.ts.instant("mins"), '10' + " " + this.ts.instant("mins"), '15' + " " + this.ts.instant("mins"), '20' + " " + this.ts.instant("mins"), '25' + " " + this.ts.instant("mins"), '30' + " " + this.ts.instant("mins"), '35' + " " + this.ts.instant("mins"),
    '40' + " " + this.ts.instant("mins"), '45' + " " + this.ts.instant("mins"), '50' + " " + this.ts.instant("mins"), '55' + " " + this.ts.instant("mins"), '60' + " " + this.ts.instant("mins"), '65' + " " + this.ts.instant("mins"), '70' + " " + this.ts.instant("mins"), '75' + " " + this.ts.instant("mins"), '80' + " " + this.ts.instant("mins"), '85' + " " + this.ts.instant("mins"), '90' + " " + this.ts.instant("mins"),
    '105' + " " + this.ts.instant("mins"), '120' + " " + this.ts.instant("mins"), '135' + " " + this.ts.instant("mins"), '150' + " " + this.ts.instant("mins"), '165' + " " + this.ts.instant("mins"), '180' + " " + this.ts.instant("mins")];
  selectedSlot: any;
  displayLegends: any;
  buttonType: any;
  autoWaitTime: ButtonValue;
  selectedIndex: number;
  slotType: string;
  config: FieldConfig[];
  allSlots: any[] = [];
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  customWaitTimeHoursConfig: FieldConfig[];
  customWaitTimeMinutesConfig: FieldConfig[];
  customSlotConfig: FieldConfig[];
  seatTime: string;
  autoText: string;
  isInitialOverBook = true;
  isPastReservation = false;
  subscriptions: Subscription = new Subscription();
  isNextEnabled = false;
  selectedShift: ShiftDTO[];
  selectedDate: any;
  slotsforallshift: any[];
  allSlotvalue: boolean = false;
  isOverBookSlotSelected: boolean = false;
  SlottingMode = SlottingMode;
  automaticNavigationForEdit = true;
  uniqueManualSlots: any[] = [];
  overbookButton: any;
  addToStandByButton: any;
  seatingArea: any;
  partySizeArray: any[];
  maxPartySizeAllowed: number = 50;
  manualSlotDialogRef: any;
  popupOutputSubscription: ISubscription;
  @ViewChildren('autoslot') private autoslot: QueryList<NgbPopover>;
  isMultiSlotSelection: boolean = true;
  RolesAndPermissionsType = RolesAndPermissionsType;
  _settings: SettingsDTO = {} as SettingsDTO;
  TableSuggestionMode = TableSuggestionMode;
  tableSuggestionMode: any;
  selectedShiftId:number = -1;
  
  

  constructor(public partyService: PartyService, private translateService: TranslateService, private _as: AppService,
    @Inject(COMPONENTINPUT) private data, private ps: PopupService, private slotTimePipe: SlotTimeFormatter,
    private ts: TranslateService, public cs: CacheService, private dialog: MatDialog, private datePipe: DatePipe, private utilities: CommonUtilities) {
    this.subscriptions.add(this.cs.settings.subscribe((settings) => {
      this.isMultiSlotSelection = settings.PropertySetting[0]?.EnableMultiSlotSelection;
    }));
    this.selectedDate = this.setUpSelectedDate();
    if (!this.selectedDate)
      this.selectedShift = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
    else
      this.selectedShift = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, this.selectedDate);

    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this.tableSuggestionMode = this._settings.General.TableSuggestionMode;
    }));
  }

  ngOnInit() {
    this.automaticNavigationForEdit = (this.cs.settings.value.PropertySetting[0].SlottingType == SlottingType.Dining) ? true : false;
    this.allSlots = [...this.timeArray];
    this.checkReservationOrWaitlist();
    this.autoText = this.ts.instant('auto');
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === GeneralDataComponent);
    this.displayLegends = [{ State: 9, name: this.ts.instant('availableForStandby') },
    { State: 8, name: this.ts.instant('partyStatusNotAvailableText') },
    { State: 7, name: this.ts.instant('partyStatusAvailableText') },
    { State: 11, name: this.ts.instant('mayBeOverbookedText') }];
    Promise.resolve().then(() => this._as.showLegends = true);
    this.buttonType = buttonTypes;
    this.subscriptions.add(this.partyService.slotAvailabilitySubject$.subscribe((slots) => {
      if (slots && slots.Payload && (this.data || this.partyService.selectedTimeSlot) && this.isReservationtypeReserve) {
        let selectedTime;
        if (this.cs.settings.value.General.SlottingMode == SlottingMode.Auto) {
          selectedTime = this.data ? this.partyService.reservedSlots?.filter((data) => data.DateTime === this.data.ReservedFor)[0] : this.partyService.selectedTimeSlot;
        }
        else {
          selectedTime = this.data ? this.partyService.reservedSlots?.filter((slot) => slot.PartyId === this.data.Id)[0] : this.partyService.selectedTimeSlot;
        }
        if ((this.partyService.reservationType === ReservationType.StandbyParties || this.partyService.reservationType === ReservationType.PrivateLessonBooking) && this.data) {
          const selectedStandbyTime = this.partyService.slots_holder.filter((data) => data.DateTime === this.data.WishedTime)[0];
          selectedStandbyTime ? this.selectedPrivateBookingResevationSlots(selectedStandbyTime , this.data.Duration) : this.reservationFormChange({ DateTime: this.data.WishedTime });
        }
        else {
          let isSameDate = false;
          if (this.partyService.reservationFormGroup.value.selectedDate && this.data) {
            let reservedDate = moment(new Date(this.data.ReservedFor)).format('YYYY-MM-DD');
            let reservationSelectedDate = moment(this.partyService.reservationFormGroup.value.selectedDate).format('YYYY-MM-DD');
            isSameDate = moment(reservedDate).isSame(reservationSelectedDate);
          }
          selectedTime ? this.setTime(selectedTime) : (isSameDate ? this.reservationFormChange({ DateTime: this.data.ReservedFor }) : this.reservationFormChange(''));
        }
        if ((this.cs.settings.value.PropertySetting[0].SlottingType == SlottingType.Dining && this.automaticNavigationForEdit) ||
          (this.cs.settings.value.PropertySetting[0].SlottingType == SlottingType.NonDining && this.automaticNavigationForEdit && !this.data.addOpenBooking)) {
          this.ps.formValid$.next(
            {
              isFormValid: this.partyService.reservationFormGroup.value.selectedTime ? true : false,
              currentTab: this.selectedIndex,
              gotoNextTab: true,
              tabsToNavigateCount: 1
            } as IFormValidDetails);
          this.automaticNavigationForEdit = false;
        }
      } else if (slots && !this.isReservationtypeReserve) {
        this.timeArray = [...this.allSlots];
        this.setAutoTimeValue();
      }
    }));
    this.autoWaitTime = {
      type: buttonTypes.actionSecondary,
      label: 'auto',
      disbaledproperity: false,
      customclass: 'time-btn'
    };
    this.allSlotvalue = this.defaultValueForAllSlots ? true : false;
    this.config = [{
      type: 'checkbox',
      name: 'allSlots',
      placeholder: this.ts.instant('allSlots'),
      value: this.defaultValueForAllSlots ? true : false,
      class: 'reservation-timeslot__all-slots-checkbox'
    },
    {
      type: 'select',
      label: 'Select Shift',
      name: 'shift',
      placeholder: 'Shift',
      options: [],
      class: 'reservation-timeslot__shift-selection',
      showErrorText: true,
      appearance: true,
      value: -1,
      // cellClick: (event) => { this.setSelectedShift(event) }
    }];
    this.customWaitTimeHoursConfig = [{
      type: 'select',
      label: this.ts.instant('hours'),
      name: 'hoursControl',
      placeholder: 'Hours',
      options: this.getHours(),
      class: 'reservation-timeslot__hours-selection',
      disabled: this.data && this.data.isPastReservation
    }];
    this.customWaitTimeMinutesConfig = [{
      type: 'select',
      label: this.ts.instant('minutes'),
      name: 'minutesControl',
      placeholder: 'Minutes',
      options: this.getMinutes(),
      class: 'reservation-timeslot__minutes-selection',
      disabled: this.data && this.data.isPastReservation
    }];
    this.setCustomSlotFormConfig();
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.partyService.partySlots$.subscribe((data) => {

      if (this.isReservationtypeReserve) {
        this.setSlots(this.components.first.form.get('allSlots').value);
        this.ShiftFilterSlots(this.selectedShiftId);
        if (!this.partyService.reservationFormGroup.value.selectedTime) {
          this.resetSlots();
          if (this.partyService.isOverrideAvailable) {
            this.clearData();
          }
        }
      } else {
        if (!this.partyService.reservationFormGroup.value.selectedTime) {
          this.timeArray = [...this.allSlots];
          this.setAutoTimeValue();
        }
      }

      if (this.cs.settings.value.General.SlottingMode == SlottingMode.Manual) {
        this.setManualSlots();
      }

      if(!this.partyService.allAvailableSlots.find(slot => slot.DateTime === this.partyService.reservationFormGroup.value.selectedTime?.DateTime)){
        this.partyService.reservationFormGroup?.controls?.selectedTime?.setValue("");
       if(this.ps?.reservationData?.selectedSlot) {this.ps.reservationData.selectedSlot = null};
      }

      this.updateSlotbyAvailability();
    }));
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedSize')?.valueChanges.subscribe(() => {
      if (this.partyService.isOverrideAvailable && !this.partyService.isEditData && this.isInitialOverBook && this.isReservationtypeReserve && !this.isPastReservation) {
        this.isInitialOverBook = false;
        this.resetSlots();
        this.partyService.reservationFormGroup.get('selectedTime').setValue('');
      } else if (!this.partyService.isOverrideAvailable && !this.isInitialOverBook && this.isReservationtypeReserve && !this.isPastReservation) {
        this.isInitialOverBook = true;
        this.resetSlots();
        this.partyService.reservationFormGroup.get('selectedTime').setValue('');
      }
      if (!this.isReservationtypeReserve) {
        if (this.components.last.value.minutesControl || this.components.first.value.hoursControl) {
          this.components.first.form.get('hoursControl').setValue('', { emitEvent: false });
          this.components.last.form.get('minutesControl').setValue('', { emitEvent: false });
          this.isNextEnabled = false;
          this.setAutoTimeValue();
          this.partyService.totalWaitimeMinutes = 0;
        }
        this.timeArray = [...this.allSlots];
      }
    }));
    if (this.partyService.reservationType === ReservationType.Reservation) {
      this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedDate').valueChanges.subscribe((val) => {
        var _date = (val) ? Utilities.Date(val) : null;
        this.selectedDate = (_date) ? _date : null;
        this.setShift();
        if (!this.partyService.reservationFormGroup.value.selectedTime) {
          this.clearSlotData();
        }
      }));
    }
    if (this.isReservationtypeReserve) {
      this.subscriptions.add(this.components.first.form.valueChanges.subscribe((data) => {
        this.setSlots(data.allSlots);
        if (this.allSlotvalue != data.allSlots && data.shift != -1) {
          let values = data.shift ? data.shift : this.config.filter(_config => _config.name == 'shift')[0].options[0].id
          this.components.first.form.controls.shift.setValue(values, { emitEvent: false });
        }
        this.allSlotvalue = (this.allSlotvalue != data.allSlots) ? data.allSlots : this.allSlotvalue;
        this.selectedShiftId = data.shift;
        this.ShiftFilterSlots(data.shift);
      }));
      this.subscriptions.add(this.components.last.form.valueChanges.subscribe((data) => {
        if (!data.overrideTimeControl) {
          if (!this.partyService.reservationFormGroup.value.selectedTime) {
            this.clearSlotData();
          }
        }
      }));
    }
    this.subscriptions.add(this.partyService.reservationFormGroup.get('selectedArea')?.valueChanges.subscribe(() => {
      this.config.filter(_config => _config.name == 'shift')[0].value = this.config.filter(_config => _config.name == 'shift')[0].options[0]?.id
    }))
    this.pushShifts();

    this.subscriptions.add(this.components.first.form.valueChanges.subscribe((data) => {
      if (!this.isReservationtypeReserve && (+this.partyService.reservationFormGroup.value.selectedSize > 0 || this.partyService.bookingSize > 0)) {
        let minutesString = "";
        let tempMinutes = this.components.last.value.minutesControl;
        let hours = data.hoursControl ? data.hoursControl : 0;
        let minutes = tempMinutes ? tempMinutes : 0;
        this.partyService.totalWaitimeMinutes = (hours * 60) + minutes;
        minutesString = this.partyService.totalWaitimeMinutes.toString() + " " + this.ts.instant("Mins");
        this.selectedSlot = minutesString;
        this.isNextEnabled = true;
        this.partyService.reservationFormGroup.get('selectedTime').setValue(minutesString, { emitEvent: false });
      }
    }));

    this.subscriptions.add(this.components.last.form.valueChanges.subscribe((data) => {
      if (!this.isReservationtypeReserve && ( +this.partyService.reservationFormGroup.value.selectedSize > 0 || this.partyService.bookingSize)) {
        let minutesString = "";
        let tempHours = this.components.first.value.hoursControl;
        let hours = tempHours ? tempHours : 0;
        let minutes = data.minutesControl ? data.minutesControl : 0;
        this.partyService.totalWaitimeMinutes = (hours * 60) + minutes;

        if (this.partyService.totalWaitimeMinutes == 0) {
          this.partyService.totalWaitimeMinutes = 180; //defaulting to hours
          this.components.first.form.get('hoursControl').setValue(3);
        }

        minutesString = this.partyService.totalWaitimeMinutes.toString() + " " + this.ts.instant("Mins");
        this.selectedSlot = minutesString;
        this.isNextEnabled = true;
        this.reservationFormChange(minutesString, this.selectedSlot);
        this.partyService.reservationFormGroup.markAsDirty();
        this.partyService.reservationFormGroup.updateValueAndValidity();
      }
    }));
    this.overbookButton = {
      type: buttonTypes.actionPrimary, label: 'partyStatusOverrideTimeText',
      actionName: 'Overbook', customclass: 'action-btn'
    };
    this.addToStandByButton = {
      type: buttonTypes.actionSecondary, label: 'addToStandby',
      actionName: 'Add to Standby', customclass: 'action-btn'
    };
    this.tableSuggestionMode = this.cs.settings.value.General.TableSuggestionMode;
  }

  updateSlotbyAvailability(){
    let _slotSelected = this.partyService.allAvailableSlots.find(slot => slot.DateTime === this.partyService.reservationFormGroup.value.selectedTime?.DateTime);
    if(_slotSelected){
      this.slotUpdateEvent.emit({slotData : _slotSelected , isManualSlot : false});
    }
  }

  setUpSelectedDate() {
    let selectedDate = null;
    if (this.data) {
      switch (this.partyService.reservationType) {
        case ReservationType.Reservation:
          selectedDate = Utilities.formateDateString(this.data.ReservedFor);
          break;
        case ReservationType.StandbyParties:
          selectedDate = Utilities.formateDateString(this.data.WishedTime);
          break;
        case ReservationType.PrivateLessonBooking:
          var date = (this.data.wishedTime) ? this.data.WishedTime : this.data.SeatingTime;
          selectedDate = Utilities.formateDateString(date);
          break;
        case ReservationType.Waitlist:
          selectedDate = Utilities.formateDateString(this.data.ArrivedAt);
          break;
      }
    }
    else
      selectedDate = this.partyService.reservationFormGroup.get('selectedDate').value;
    return selectedDate;
  }

  setManualSlots() {
    if (this.cs.settings.value.General.SlottingMode == SlottingMode.Manual) {
      this.uniqueManualSlots = [];
      this.partyService.manualSlotsGroup = [];
      if (!this.partyService.allAvailableSlots) {
        return;
      }
      const uniqueBtn = [...new Set(this.partyService.allAvailableSlots.map(item => Utilities.Date(item.DateTime).format("YYYY-MM-DDTHH:mm:ss")))];
      uniqueBtn.forEach(btn => {
        let slotGroup = this.partyService.allAvailableSlots.filter(slot => Utilities.Date(slot.DateTime).format("YYYY-MM-DDTHH:mm:ss") == btn);
        let selectedSize = +this.partyService.reservationFormGroup.value.selectedSize || this.partyService.bookingSize;
        if (!this.allSlotvalue) {
          slotGroup = slotGroup.filter((slot) => slot.PartyId || (selectedSize >= slot.LimitedMinPartySize && selectedSize <= slot.LimitedMaxPartySize && slot.OriginalMinPartySize > 0 && slot.OriginalMaxPartySize > 0));
        }
        if (slotGroup && slotGroup.length > 0) {
          const maxPastTime = this.cs.settings.value.General.MaxPastTimeForReservationsInMinutes;
          const currentTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
          let minutes = this.cs.settings.value.PropertySetting[0].ShowOngoingSlotsTill ? (this.cs.settings.value.PropertySetting[0].ShowOngoingSlotsTill * 1000) : 60000;
          const past1hr = new Date(currentTime.valueOf() - (maxPastTime * minutes));
          const slotTime = Utilities.parseDateString(slotGroup[0].DateTime);
          let data = {
            DateTime: btn,
            slotName: slotGroup[0].slotName,
            IsDisabled: slotTime >= past1hr ? false : true,
            IsOverRide: slotGroup[0].IsOverRide ? true : false,
            formattedTime: Utilities.Date(btn).format("hh:mm a"),
            slotCount: slotGroup.filter(slot => (!slot.IsDisabled || this.allSlotvalue) && !slot.IsOverRide && slot.LimitedMinPartySize != 0).length,
            buttonInfo: {
              type: buttonTypes.actionSecondary, label: Utilities.Date(btn).format("hh:mm a"),
              actionName: 'Add to Standby', customclass: 'action-btn',
              disbaledproperity: slotTime >= past1hr ? false : true
            }
          };
          this.uniqueManualSlots.push(data);
          let buttonGroup = {
            dateTime: btn,
            slots: this.setManualSlotGroupButtonConfig(slotGroup)
          };
          this.partyService.manualSlotsGroup.push(buttonGroup);
        }
      });
    }
  }

  setCustomSlotFormConfig() {
    this.setPartySize();
    this.customSlotConfig = [{
      type: 'select',
      label: 'minSize',
      name: 'minSize',
      options: this.partySizeArray,
      class: 'party-size',
      showErrorText: true,
      isTranslate: false,
      value: this.partySizeArray[1]
    },
    {
      type: 'select',
      label: 'maxSize',
      name: 'maxSize',
      options: this.partySizeArray,
      class: 'party-size',
      showErrorText: true,
      isTranslate: false,
      value: this.partySizeArray[3]
    },
    {
      type: 'switch',
      name: 'webReservable',
      inputType: 'text',
      label: 'webReservable',
      class: 'guest-view__vip-edit',
    },
    {
      type: 'select',
      label: 'seatingArea',
      name: 'seatingArea',
      options: this.seatingArea,
      class: 'seatingarea',
      showErrorText: true,
      isTranslate: false,
    }];
    this.setSeatingArea();
  }

  setPartySize() {
    this.partySizeArray = [];
    for (let i = 1; i <= this.maxPartySizeAllowed; i++) {
      this.partySizeArray.push({ id: i, value: i.toString() });
    }
  }

  setSeatingArea() {
    this.seatingArea = this.cs.settings.value.SeatingAreas;
    this.seatingArea.forEach((area) => { if (area.Id === null) { area.Id = -1; } return area; });
    this.seatingArea.unshift(this.seatingArea.splice(this.seatingArea.findIndex(item => item.Id === -1), 1)[0]);
    this.customSlotConfig[3].options = this.seatingArea.map(x => ({ id: x.Id, value: x.Name }));
    this.customSlotConfig[3].value = this.customSlotConfig[3].options.filter(x => x.id == -1)[0].id;
  }

  addSlot(slotDto: UpdatedManualSlotDTO) {
    this.subscriptions.add(this.partyService.createManualSlot(slotDto).subscribe(data => {
    }));
  }

  updateSlot(slotDto: UpdatedManualSlotDTO) {
    this.subscriptions.add(this.partyService.updateManualSlot(slotDto).subscribe(data => {
    }));
  }

  removeSlot(id: ManualSlotIdDTO) {
    let ids: ManualSlotIdDTO[] = [id];
    this.subscriptions.add(this.partyService.removeManualSlot(ids).subscribe(data => {
    }));
  }

  setWebReservable(id: ManualSlotIdDTO, webReservable: boolean) {
    let ids: ManualSlotIdDTO[] = [id];
    this.subscriptions.add(this.partyService.setWebReservableManualSlot(ids, webReservable).subscribe(data => {
    }));
  }

  getLockTime(slot) {
    const restaurantDateTime = Utilities.Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
    if (restaurantDateTime < Utilities.Date(slot.LockExpiresAt)) {
      const lockTime = Math.ceil((Utilities.Date(slot.LockExpiresAt).diff(restaurantDateTime, 'minutes')));
      slot.IsLocked = true;
      return `${this.ts.instant('lockFor')} ${lockTime} ${this.ts.instant("Mins")}`;
    } else {
      slot.IsLocked = false;
      return slot.LimitedMinPartySize + ' - ' + slot.LimitedMaxPartySize + ' ' + this.getSeatingAreaNameById(slot.SeatingAreaId);
    }
  }

  setManualSlotGroupButtonConfig(slots) {
    let buttons = [];
    slots.forEach(slot => {
      if (slot.LimitedMinPartySize != 0) {
        let data = slot;
        slot.IsLocked = false;
        data.buttonInfo = {
          type: slot.Id === (this.partyService.reservationFormGroup.value.selectedTime && this.partyService.reservationFormGroup.value.selectedTime.Id) ? buttonTypes.actionPrimary : slot.IsWebReservable ? buttonTypes.actionSecondary : buttonTypes.actionSecondarySmallRed,
          label: slot.LockExpiresAt ? this.getLockTime(slot) :
            (slot.LimitedMinPartySize > 0 ? slot.LimitedMinPartySize : slot.OriginalMinPartySize) + ' - ' + (slot.LimitedMaxPartySize > 0 ? slot.LimitedMaxPartySize : slot.OriginalMaxPartySize) + ' ' + this.getSeatingAreaNameById(slot.SeatingAreaId),
          actionName: '',
          customclass: 'action-btn',
          disbaledproperity: slot.IsLocked ? true : (slot.IsDisabled ? true : false)
        };
        buttons.push(data);
      }
    });
    return buttons;
  }

  getSeatingAreaNameById(id: number) {
    return (id > 0) ? this.cs.settings.value.SeatingAreas.find(area => area.Id == id).Name : "Any Section";
  }

  pushShifts() {
    if (this.config && this.isReservationtypeReserve) {
      this.config.filter(_config => _config.name == 'shift')[0].options = [];
      let val = [];
      if (this.selectedShift && this.selectedShift.length > 0) {
        this.config.filter(_config => _config.name == 'shift')[0].options.push({ id: -1, value: this.translateService.instant('All Shifts') });
        val.push({ id: -1, value: this.translateService.instant('All Shifts') });
      }
      else {
        remove(this.config, x => x.name === 'shift');
        if (this.isOpenBooking) {
          this.components.first.form.controls.shift.setValue(-1);
        }
        return;
      }
      const mappedOptions = this.selectedShift.map(x => ({ id: x.Id, value: x.Name }));
      mappedOptions.forEach(x => {
        this.config.filter(_config => _config.name == 'shift')[0].options.push(x);
        val.push(x);
      });
      this.config.filter(_config => _config.name == 'shift')[0].value = val[0].id;
      this.components.first.form.controls.shift.setValue(val[0].id);

    }
  }
  setShift() {
    if (!this.selectedDate)
      this.selectedShift = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
    else
      this.selectedShift = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, this.selectedDate);
    this.pushShifts();
  }
  setSelectedShift(eve) {
    let selectedshift = (eve.value != -1) ? this.selectedShift.filter(shift => shift.Id == eve.value) : null;
    let selectedShiftId = selectedshift && selectedshift.length > 0 ? selectedshift[0].Id : -1;
    this.ShiftFilterSlots(selectedShiftId);
  }

  ShiftFilterSlots(ShiftId) {
    let selectedshift = (ShiftId != -1) ? this.selectedShift.filter(shift => shift.Id == ShiftId) : null;

    if (selectedshift && selectedshift.length > 0) {
      let filteredslots: any[] = [];
      this.slotsforallshift?.forEach(slot => {
        if (Utilities.datetimeBetween(selectedshift[0].EffectiveRange.Start, selectedshift[0].EffectiveRange.End, slot.DateTime) && this.filterSlotBySpecailmeal(slot)) {
          filteredslots.push(slot);
        }
      })
      this.partyService.allAvailableSlots = filteredslots;
      this.setManualSlots();
    }
    else {
      if(this.specialMealFilter?.filterByMeal && this.specialMealFilter?.meal){
        let filteredslots: any[] = [];
        this.slotsforallshift?.forEach(slot => {
          if(this.filterSlotBySpecailmeal(slot)){
            filteredslots.push(slot);
          }
        })
        this.partyService.allAvailableSlots = filteredslots;
      }else{
        this.partyService.allAvailableSlots = this.slotsforallshift;
      }
   
      this.setManualSlots();
    }
  }
  filterSlotBySpecailmeal(slot) {
    if (this.allSlotvalue || !this.specialMealFilter?.filterByMeal || !this.specialMealFilter?.meal) {
      return true;
    } else {
      let slotValid = false;
      if (this.specialMealFilter?.filterByMeal && this.specialMealFilter?.meal && this.specialMealFilter.meal.ActivitySessions?.length) {
        let meal = this.specialMealFilter?.meal;
        let dayOfWeek = new Date(slot.DateTime).getDay();
        const time = moment(slot.DateTime.split('T')[1] ,  'hh:mm:ss');
        let sesionData = meal.ActivitySessions.find(session => session.Dayofweek == dayOfWeek);
        let startTime = moment(sesionData.StartTime , 'hh:mm:ss');
        let endTime = moment(sesionData.EndTime , 'hh:mm:ss');
      
        if (sesionData && time.isBetween( startTime,endTime ) || time.format('HH:mm:ss') == sesionData.StartTime) {
          slotValid = true;
        }else{
           slotValid = false;
        }
      } else {
        slotValid = true;
      }
      return slotValid;
    }
  }

  cellClick(e) {
    this.overrideValues(e);
  }

  clearData(event?) {
    if (this.components.last.form.get('overrideTimeControl'))
      this.components.last.form.get('overrideTimeControl').setValue('');
  }

  resetSlots() {
    this.slotType = '';
    this.selectedSlot = '';
  }

  setAutoTimeValue() {
    this.seatTime = '';
    let selectedTime = '';
    this.partyService.totalWaitimeMinutes = 0;
    this.components.first.form.get('hoursControl').setValue('', { emitEvent: false });
    this.components.last.form.get('minutesControl').setValue('', { emitEvent: false });

    if (!this.partyService.autoWaitTime.SeatingTime) {
      this.seatTime = this.ts.instant('cantEstimate');
    } else {
      const time = new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
      const totalWaitMins = Utilities.diffBetweenDatesInMinutes(new Date(this.partyService.autoWaitTime.SeatingTime), time);
      this.seatTime = totalWaitMins <= 0 ? this.seatTime = this.ts.instant('immediateSeating') :
        `${totalWaitMins} ${this.ts.instant("Mins")}`;
    }
    if (this.data) {
      const defaultValue = this.ts.instant('default');
      this.timeArray.shift();
      this.timeArray.unshift(this.autoText, defaultValue);
      this.selectedSlot = this.timeArray[1];
      selectedTime = this.timeArray[1];
    } else {
      this.selectedSlot = `${this.timeArray[0]} ${this.seatTime}`;
      selectedTime = this.timeArray[0];
    }
    this.reservationFormChange(selectedTime, this.selectedSlot);
    if (this.automaticNavigationForEdit && this.data) {
      this.ps.formValid$.next(
        {
          isFormValid: this.partyService.reservationFormGroup.value.selectedTime ? true : false,
          currentTab: this.selectedIndex,
          gotoNextTab: true,
          tabsToNavigateCount: 1
        } as IFormValidDetails);
      this.automaticNavigationForEdit = false;
      //this.ps.nextBtnEnabled$.next(true);
    }
  }

  clearSlotData() {
    this.resetSlots();
    this.reservationFormChange('');
  }

  setSlots(isSlotsAvailable) {

    if (this.partyService.slots_holder !== undefined) {
      this.partyService.slots_holder.sort((a, b) => Utilities.parseDateString(a.DateTime).getTime() - Utilities.parseDateString(b.DateTime).getTime());
    }
    if (!this.partyService.slots_holder) {
      return;
    }
    let newslots = this.partyService.slots_holder.filter((slot) => (!slot.IsDisabled || this.isEditCheckInAllowed) && (!slot.IsOverRide));
    let allslots = this.partyService.slots_holder.filter((slot) => !slot.IsDisabled && (slot.IsOverRide));
    if (isSlotsAvailable) {
      this.partyService.allAvailableSlots = this.partyService.slots_holder;
    } else {
      this.partyService.allAvailableSlots = newslots;
    }
    if ((this.data && this.data.isPastReservation)) {
      if (this.data) {
        this.partyService.allAvailableSlots = [...this.partyService.slots_holder];
      }
      this.isPastReservation = true;
    } else {
      this.isPastReservation = false;
    }
    this.slotsforallshift = cloneDeep(this.partyService.allAvailableSlots);
    //disabled slots
  }

  seatNow() {
    this.tabLabel.emit(Labels[Labels.partyStatusSeatText]);
  }

  reservationFormChange(selectedTime, slotTime? , skipAutoNavigate:boolean =  false) {
    this.partyService.reservationFormGroup?.get('selectedTime')?.setValue(selectedTime);
    if (this.selectedIndex >= 0 && slotTime) {
      this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = slotTime;
    } else {
      //this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = 'Time';
    }
    if (( !skipAutoNavigate && !this.cs.settings.value.PropertySetting || this.cs.settings.value.PropertySetting.length == 0 || this.cs.settings.value.PropertySetting[0].SlottingType == SlottingType.Dining)) {
      this.ps.formValid$.next(
        {
          isFormValid: this.partyService.reservationFormGroup.value.selectedTime ? true : false,
          currentTab: this.selectedIndex,
          gotoNextTab: this.isNextEnabled,
          tabsToNavigateCount: 1
        } as IFormValidDetails);
    }
    this.isNextEnabled = false;
  }

  checkReservationOrWaitlist() {
    this.isReservationtypeReserve = (this.partyService.reservationType === ReservationType.PrivateLessonBooking || this.partyService.reservationType === ReservationType.OpenBooking || this.partyService.reservationType === ReservationType.Reservation || this.partyService.reservationType === ReservationType.StandbyParties) ? true : false;
    this.isOpenBooking = this.partyService.reservationType === ReservationType.OpenBooking || this.partyService.reservationType === ReservationType.PrivateLessonBooking;  
  }
  selectedTimeReservation(data , isManualSlot?: boolean) { 
    if(!this.cs.settings.value.AdvanceBookingOptionDisabled){
    this.setTime(data , false);
    this.slotUpdateEvent.emit({slotData : data , isManualSlot : isManualSlot});
    } else{
      if (this.cs.settings.value.General.SlottingMode == SlottingMode.Auto && this.cs.settings.value.PropertySetting[0]?.SlottingType == SlottingType.Dining) {
        this.partyService.isStandbySlotSelected = data.IsOverRide;
      }
      const restaurantDateTime = Utilities.Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta));
      if ((data.LockedAt == null || (restaurantDateTime > Utilities.Date(data.LockExpiresAt))) && !data.IsDisabled && !this.isPastReservation && +this.partyService.reservationFormGroup.value.selectedSize > 0) {
        this.isNextEnabled = true;
        const partyDetails = this.getPartyDetailsForSeatingEstimate(data);
        if (this.partyService.reservationType == ReservationType.StandbyParties && this.data) {
          partyDetails.ReservedFor = this.data.WishedTime;
        }
        if (data.SeatingAreaId && data.SeatingAreaId > 0) {
          this.partyService.reservationFormGroup.get('selectedArea').setValue(data.SeatingAreaId);
        }
        this.partyService.getAutoWaitTime(this.partyService.reservationFormGroup.get('selectedSize').value, partyDetails);
  
        if (!this.partyService.isEditData && !data.IsOverRide && !this.partyService.isStandbySlotSelected) {
          this.setLockTime(data);
          if (this.partyService.previousSelectedSlotId) {
            const maxPastTime = this.cs.settings.value.General.MaxPastTimeForReservationsInMinutes;
            const currentTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
            const past1hr = new Date(currentTime.valueOf() - (maxPastTime * 60000));
            const previousSlots = this.partyService.slots_holder.find((slot) => slot.Id === this.partyService.previousSelectedSlotId);
            if (previousSlots) {
              const slotTime = Utilities.parseDateString(previousSlots.DateTime);
              previousSlots.IsDisabled = slotTime >= past1hr ? false : true;
            }
          }
          this.partyService.previousSelectedSlotId = data.Id;
        }
        this.partyService.isOverBookSlotSelected = data.IsOverRide && !this.data ? true : false;
        if ((this.partyService.reservationType == ReservationType.StandbyParties || this.partyService.reservationType == ReservationType.PrivateLessonBooking) && !data.IsOverRide && !this.partyService.isStandbySlotSelected) {
          this.partyService.standbyConversion = true;
        }
        this.partyService.isStandbySlotSelected = false;
        data.IsOverRide ? data.IsOverRide : this.clearData();
        this.setTime(data , false);
        this.partyService.reservationFormGroup.markAsDirty();
        this.partyService.reservationFormGroup.updateValueAndValidity();
      }
    }
  }

  setLockTime(data): boolean {
    this.partyService.slotMode = this.cs.settings.value.General.SlottingMode;
    const partySize = this.partyService.reservationFormGroup.value.selectedSize || this.partyService.bookingSize;
    let seatingareaval;

    if (this.partyService.reservationFormGroup.value && this.partyService.reservationFormGroup.value.selectedArea && this.partyService.reservationFormGroup.value.selectedArea.Id) {
      seatingareaval = this.partyService.reservationFormGroup.value.selectedArea.Id;
    } else if (this.partyService.reservationFormGroup.value && this.partyService.reservationFormGroup.value.selectedArea) {
      seatingareaval = this.partyService.reservationFormGroup.value.selectedArea;
    } else {
      seatingareaval = -1;
    }
    const Id = seatingareaval;
    const SeatingAreaId = Id === -1 ? null : Id;
    const lockIdToIgnore = this.partyService.slotLockId ? this.partyService.slotLockId : null;
    const slotTime = data.DateTime;
    const slotID = data.Id;
    const slotType = data.Type;
    if (this.partyService.slotMode === SlottingMode.Auto) {
      this.subscriptions.add(this.partyService.getAutoSlotLockTime(slotTime, partySize, SeatingAreaId, lockIdToIgnore).subscribe((slot) => {
        this.partyService.slotLockId = slot.Payload.SlotLockIdDTO.Id;
        this.partyService.slotUnlockTimerFunc();
        return true;

      }));
    } else if (this.partyService.slotMode === SlottingMode.Manual) {
      this.subscriptions.add(this.partyService.getManualSlotTime(partySize, slotTime, slotID, slotType, lockIdToIgnore).subscribe((slot) => {
        this.partyService.slotLockId = slot.Payload.SlotLockIdDTO.Id;
        this.partyService.slotUnlockTimerFunc();
        return true;
      }));
    }
    return;
  }

  SelectBookingReservationSlots(slot,event?) {
    if(event && (((slot.IsOverbookAvailable || slot.IsStandByBookingSlot) && !this.partyService.selectedOpenBookingSlots[slot.Id]))){
      event.preventDefault();
      event.stopPropagation();
    }
    else{
      if(this.partyService.isOverBookSlotSelected && !this.partyService.selectedOpenBookingSlots[slot.Id]){
        this.partyService.selectedOpenBookingSlots = {};
      }
      this.partyService.isOverBookSlotSelected = !this.partyService.isStandbySlotSelected && slot.IsOverbookAvailable ? true : false;
    var activityselected = this.selectedActivity
    if (activityselected){
      this.selectedPrivateBookingResevationSlots(slot);
    }
    else
      this.selectedOpenBookingResevationSlots(slot);
    }
  }

  selectedOpenBookingResevationSlots(slot) {
    var mins = this.cs.settings.value.General.TimeSlotUnitInMinutes;
    if (this.partyService.isEditData && !this.isenableMultipleSlotSelection) {
      if (this.isMultiSlotSelection) {
        var isContinuous: boolean = false;
        var allKeys = [];
        for (var key in this.partyService.selectedOpenBookingSlots) {
          if (this.partyService.selectedOpenBookingSlots[key] == true) {
            allKeys.push(key);
          }
        }
        var slotsToCheck = this.partyService.slots_holder.filter(x => allKeys.includes(x.Id.toString()));

        if (this.partyService.selectedOpenBookingSlots[slot.Id] == true) {
          var slotList = slotsToCheck.filter(x => x.Id != slot.Id);
          if (slotList.length == 1) {
            isContinuous = true;
          }
          for (var i = 0; i < slotList.length - 1; i++) {
            if (slotList[i]) {
              if (Math.abs(Utilities.diffBetweenDatesInMinutes(slotList[i].DateTime, slotList[i + 1].DateTime)) == mins) {
                isContinuous = true;
              }
              else {
                isContinuous = false;
                break;
              }
            }
          }
        }
        else {
          for (var key in this.partyService.selectedOpenBookingSlots) {
            if (this.partyService.selectedOpenBookingSlots[key] == true) {
              var slotValue = this.partyService.slots_holder.filter(x => x.Id == key);
              if (slotValue && slotValue.length > 0) {
                console.log(Utilities.diffBetweenDatesInMinutes(slotValue[0].DateTime, slot.DateTime));
                if (Math.abs(Utilities.diffBetweenDatesInMinutes(slotValue[0].DateTime, slot.DateTime)) == mins) {
                  isContinuous = true;
                }

              }
            }
          }
        }
        if (!isContinuous) {
          this.partyService.selectedOpenBookingSlots = {};
        }
      }
    }
    if (!this.isMultiSlotSelection) {
      this.partyService.selectedOpenBookingSlots = {};
    }
    this.partyService.selectedOpenBookingSlots[slot.Id] = !this.partyService.selectedOpenBookingSlots[slot.Id];
    this.slotUpdateEvent.emit(slot);
  }

  selectedPrivateBookingResevationSlots(slot , selectedDuration : number =  null) {
    this.setTime(slot,false);
    var maxduration = this.selectedActivity?.MaxDuration;
    var minduration = this.selectedActivity?.MinDuration;
    var mins = this.cs.settings.value.General.TimeSlotUnitInMinutes;
    let timediff = mins;
    let numberOfSlotsToBeSelected = (selectedDuration || minduration) / mins;
    let _continuousSlotsToBeSelected;
    if (this.isMultiSlotSelection) {
      _continuousSlotsToBeSelected = this.continuousSlotsToBeSelected(slot, mins, numberOfSlotsToBeSelected)
      if(!_continuousSlotsToBeSelected.length && !this.partyService.selectedOpenBookingSlots[slot.Id]){
        return;
      }
      var isContinuous: boolean = false;
      var allKeys = [];
      for (var key in this.partyService.selectedOpenBookingSlots) {
        if (this.partyService.selectedOpenBookingSlots[key] == true) {
          allKeys.push(key);
        }
      }
      var slotsToCheck = this.partyService.slots_holder.filter(x => allKeys.includes(x.Id.toString()));
      var slotList = slotsToCheck.filter(x => x.Id != slot.Id);

      if (this.partyService.selectedOpenBookingSlots[slot.Id] == true) {
        if (slotList.length == 1) {
          isContinuous = true;
        }
        for (var i = 0; i < slotList.length - 1; i++) {
          if (slotList[i]) {
            const diffInMins = Math.abs(Utilities.diffBetweenDatesInMinutes(slotList[i].DateTime, slot.DateTime))
            if (diffInMins == mins) {
              isContinuous = true;
              timediff = timediff + diffInMins;
            }
            else {
              isContinuous = false;
              break;
            }
          }
        }
      }
      else {
        for (var key in this.partyService.selectedOpenBookingSlots) {
          if (this.partyService.selectedOpenBookingSlots[key] == true) {
            var slotValue = this.partyService.slots_holder.filter(x => x.Id == key);
            if (slotValue && slotValue.length > 0) {
              const diffInMins = Math.abs(Utilities.diffBetweenDatesInMinutes(slotValue[0].DateTime, slot.DateTime));
              if (diffInMins == mins) {
                isContinuous = true;
                for (var i = 0; i < slotList.length - 1; i++) {
                  if (slotList[i]) {
                    const diffInSlotMins = Math.abs(Utilities.diffBetweenDatesInMinutes(slotList[i].DateTime, slotList[i + 1].DateTime))
                    if (diffInSlotMins == mins) {
                      timediff = timediff + diffInSlotMins;
                    } else {
                      timediff = mins;
                    }
                  }
                }
              }

            }
          }
        }
      }
      if (isContinuous && !this.isMultiSlotSelection) {
        timediff = timediff + mins;
        if (timediff <= maxduration) {
          this.partyService.selectedOpenBookingSlots[slot.Id] = !this.partyService.selectedOpenBookingSlots[slot.Id];
        }
        else {
          this.partyService.selectedOpenBookingSlots[slot.Id] = false;
        }
      } else {
        if (!this.isMultiSlotSelection || (this.partyService.isEditData && !this.partyService.isEditCart)) {
          this.partyService.selectedOpenBookingSlots = {};
        }
        if(!this.partyService.selectedOpenBookingSlots[slot.Id]){
          _continuousSlotsToBeSelected.forEach(currSlot => {     
            if(!this.partyService.selectedOpenBookingSlots[currSlot.Id])     {
              this.partyService.selectedOpenBookingSlots[currSlot.Id] = true;
            }
          });
        }else{
          this.partyService.selectedOpenBookingSlots[slot.Id] = !this.partyService.selectedOpenBookingSlots[slot.Id];
        }
      }
    } else {
      this.partyService.selectedOpenBookingSlots = {};
      this.partyService.selectedOpenBookingSlots[slot.Id] = !this.partyService.selectedOpenBookingSlots[slot.Id];
    }
    this.slotUpdateEvent.emit({slotData : slot});
    
  }

  continuousSlotsToBeSelected(slot, slotUnit, requiredNumberOfSlots: number): any[]{
    let slotIndex = this.partyService.slots_holder.findIndex(x => x.Id === slot.Id);
    let numberOfSlotsSelected = 1;
    let slotsToBeSelected = [slot];
    let prevSlot = this.partyService.slots_holder[slotIndex - 1]
    if(prevSlot && this.partyService.selectedOpenBookingSlots[prevSlot.Id]){
      const _diffInMins = Math.abs(Utilities.diffBetweenDatesInMinutes(prevSlot.DateTime, slot.DateTime));
      if(slotUnit === _diffInMins){
        return [slot];
      }
    }
    for (let slotHolderIndex = slotIndex + 1; slotHolderIndex < this.partyService.slots_holder.length && numberOfSlotsSelected < requiredNumberOfSlots; slotHolderIndex++) {
      const iterationprevSlot = this.partyService.slots_holder[slotHolderIndex - 1];
      const currSlot = this.partyService.slots_holder[slotHolderIndex];
      const _diffInMins = Math.abs(Utilities.diffBetweenDatesInMinutes(iterationprevSlot.DateTime, currSlot.DateTime));
      if(slotUnit === _diffInMins){
        numberOfSlotsSelected++;
        slotsToBeSelected.push(currSlot);
        continue;
      }
      return slotsToBeSelected;
    }
    return slotsToBeSelected;
  }

  setTime(data , skipAutoNavigate : boolean = false) {
    if (data) {
      const slotTime = this.slotTimePipe.transform(data.DateTime);
      this.slotType = data.slotName;
      this.selectedSlot = slotTime;
      let selectedSlot = this.partyService.manualSlotsGroup.filter((slot) => slot.dateTime === data.DateTime);
      if (selectedSlot.length > 0) {
        const customSelectedSlot = selectedSlot[0].slots.filter((slot) => slot.Id === data.Id);
        if (customSelectedSlot.length > 0) {
          customSelectedSlot[0].buttonInfo.type = buttonTypes.actionPrimary;
        }
      }
      if (this.partyService.isOverrideAvailable && this.components.last.form.get('overrideTimeControl') && this.partyService.isOverBookSlotSelected) {
        this.components.last.form.get('overrideTimeControl').setValue(slotTime);
      }
      this.reservationFormChange(data, slotTime , skipAutoNavigate);
    }
  }

  selectedTime(time) {
    if (!(this.data && this.data.isPastReservation) && (+this.partyService.reservationFormGroup.value.selectedSize > 0 || this.partyService.bookingSize)) {
      this.partyService.totalWaitimeMinutes = 0;
      this.components.first.form.get('hoursControl').setValue('', { emitEvent: false });
      this.components.last.form.get('minutesControl').setValue('', { emitEvent: false });
      this.selectedSlot = time === this.autoText ? `${this.autoText} ${this.seatTime}` : "Manual - " + time;
      this.isNextEnabled = true;
      this.reservationFormChange(time, this.selectedSlot);
      this.partyService.reservationFormGroup.markAsDirty();
      this.partyService.reservationFormGroup.updateValueAndValidity();
    }
  }

  showManualSlotAuditLog(slot) {
    const showAlert = false;
    const title = this.translateService.instant('auditlog');
    const popUpMessage = [{
      dialogTitle: title, showAlert, type: AuditableEntityType.ManualSlot, slot: slot
    }];
    const componentDetails: ComponentDetails = {
      componentName: AuditlogComponent,
      dimensionType: 'large',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: popupDialogDimension.createDialogHeight,
      width: popupDialogDimension.createDialogWidth,
      data: {
        title,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true,
        showAction: false
      }
    });
  }

  showSlotTime(item) {
    return this.slotTimePipe.transform(item);
  }

  overrideValues(overriddenTime , isOpenBooking? : boolean,event?) {
    if(Utilities.controlValidate(controlSettings.Overbook_Permission_Popup,this._as.PropertyType) && !this._as.hasPermission(RolesAndPermissionsType.Overbook)){
      this.utilities.showAlert(this.translateService.instant('OverbookPermissionDenied'), AlertType.AccessDenied, ButtonType.Ok);
      return;
    }
    if (!overriddenTime.IsDisabled) {
      this.slotType = overriddenTime.slotName;
      const slotTime = this.slotTimePipe.transform(overriddenTime.DateTime);
      this.selectedSlot = slotTime;
    
      this.partyService.isOverBookSlotSelected =  overriddenTime.IsOverRide && !this.data ? true : false;
      if(this.cs.settings.value.PropertySetting[0]?.SlottingType == SlottingType.Dining){
        this.partyService.isOverBookSlotSelected = true;
      }
      this.partyService.isStandbySlotSelected = false;
      this.partyService.standbyConversion = ((this.partyService.reservationType === ReservationType.StandbyParties || this.partyService.reservationType == ReservationType.PrivateLessonBooking) && this.data) ? true : false;
      this.selectedTimeReservation(overriddenTime);
      if(isOpenBooking){
        this.partyService.isOverBookSlotSelected = overriddenTime.IsOverbookAvailable ? true : false;
        this.partyService.selectedOpenBookingSlots ={};
        this.SelectBookingReservationSlots(overriddenTime);
      }
    }
  }
  overBookValues(slot){
    let overbooked = slot.bookingSize ? slot.bookingSize - slot.Availability.AvailableSlots : 0;
    slot.OverBooked = overbooked < 0 ? 0 : overbooked;
    slot.isOverbook = true;
    slot.isStandBy = false;
  }

  addToStandBy(slot: any, isOpenBooking?: boolean) {
    if (!slot.IsDisabled) {
      this.slotType = slot.slotName;
      const slotTime = this.slotTimePipe.transform(slot.DateTime);
      this.selectedSlot = slotTime;
      this.partyService.isStandbySlotSelected = true;
      this.partyService.isOverBookSlotSelected = false;
      this.partyService.standbyConversion = false;
      this.selectedTimeReservation(slot);
      this.partyService.reservationFormGroup.get('selectedSpecialMeal').setValue(null);
      if (isOpenBooking) {
        this.partyService.selectedOpenBookingSlots = {};
        this.SelectBookingReservationSlots(slot);
      }
    }
  }

  openManualSlot(index: number, slot) {
    if (this.popupOutputSubscription) {
      this.popupOutputSubscription.unsubscribe();
    }
    const title = this.datePipe.transform(slot.DateTime, 'EEEE, dd-MM-yyyy h:mm a');
    const componentDetails = {
      componentName: ManualSlotComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: { manualSlotIndex: index, selectedSlot: slot, showAllSlot: this.allSlotvalue },
      popupTitle: title
    };

    this.manualSlotDialogRef = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      height: '500px',
      width: '800px',
      data: {
        service: this.ps,
        title,
        update: '',
        cancel: '',
        componentDetails,
        back: false,
        from: ComponentTypes.commonconfirmmessage,
        standalone: true
      }
    });
    this.popupOutputSubscription = this.ps.popupOutputEvent$.subscribe((data: SlotOutput) => {
      if (data.actionName === ManualSlotSelection.slotSelection) {
        this.manualSlotDialogRef.close();
        this.partyService.isStandbySlotSelected = false;
        this.selectedTimeReservation(data.slot , true);
      } else if (data.actionName === ManualSlotSelection.standBy) {
        this.manualSlotDialogRef.close();
        this.partyService.isStandbySlotSelected = true;
        this.addToStandBy(data.slot);
      } else if (data.actionName === ManualSlotSelection.disableSlot) {
        this.manualSlotDialogRef.close();
      }
    });
    this.subscriptions.add(this.manualSlotDialogRef.afterClosed().subscribe(() => {
      this.popupOutputSubscription.unsubscribe();
    }));
  }

  getMinutes() {
    let minutes = [{ id: 0, value: 0 }];
    for (let i = 0; i < 11; i++) {
      minutes.push({ id: (i + 1) * 5, value: ((i + 1) * 5) })
    }
    return minutes;
  }

  getHours() {
    let hours = [];
    for (let i = 0; i < 9; i++) {
      hours.push({ id: (i + 3), value: (i + 3) })
    }
    return hours;
  }

  openOverbookSlots(popOverIndex , IsDisabled) {
    this.autoslot.forEach((autoSlotPopover, index) => {
      if (autoSlotPopover) {
        autoSlotPopover.close();
      }
      if (popOverIndex === index && !IsDisabled) {
          autoSlotPopover.open();
      }
    });
  }

  getPartyDetailsForSeatingEstimate(data) {
    let partyDetails: any = {};
    if (this.data) {
      partyDetails = cloneDeep(this.data);
      partyDetails.TableIds = [];
    }

    if (data) {
      let seatingareaval;
      if (data && data.SeatingAreaId && data.SeatingAreaId > 0) {
        seatingareaval = data.SeatingAreaId;
      } else if (this.partyService.reservationFormGroup.value && this.partyService.reservationFormGroup.value.selectedArea && this.partyService.reservationFormGroup.value.selectedArea.Id) {
        seatingareaval = this.partyService.reservationFormGroup.value.selectedArea.Id;
      } else if (this.partyService.reservationFormGroup.value && this.partyService.reservationFormGroup.value.selectedArea) {
        seatingareaval = this.partyService.reservationFormGroup.value.selectedArea?.Id;
      } else {
        seatingareaval = -1;
      }
      //let seatingareaval=this.partyService.reservationFormGroup.value.selectedArea ? this.partyService.reservationFormGroup.value.selectedArea.Id : -1;
      partyDetails.SeatingAreaId = seatingareaval,
        partyDetails.ReservedFor = data.DateTime,
        partyDetails.IsReservation = this.partyService.reservationType === ReservationType.Reservation,
        partyDetails.Size = this.partyService.reservationFormGroup.get('selectedSize').value,
        partyDetails.SeatingTypeId = (data.SeatingTypeId && data.SeatingTypeId > 0) ? data.SeatingTypeId : this.partyService.reservationFormGroup.value.selectedType?.Id ? this.partyService.reservationFormGroup.value.selectedType.Id : this.partyService.reservationFormGroup.value.selectedType
    }

    return partyDetails;
  }

  ngOnDestroy() {
    this.config.forEach(config => {
      config.cellClick && config.cellClick.bind(null);
    });
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    this._as.showLegends = false;

    this.partyService.allAvailableSlots = [];
    this.partyService.isOverBookSlotSelected = false;
    this.partyService.standbyConversion = false;
    this.partyService.isStandbySlotSelected = false;
    this.partyService.totalWaitimeMinutes = 0;
  }
}

@Pipe({ name: 'customWaitTime' })

export class CustomWaitTimePipe implements PipeTransform {
  constructor(private ts:TranslateService){}
  transform(time) {
    if (time === -1) {
      return '?';
    }
    if (time == 0) {
      return this.ts.instant('immediateSeating');
    }
    let days;
    let hours;
    let minutes;
    let seconds;
    days = Math.floor(time / 86400);
    time -= days * 86400;
    hours = Math.floor(time / 3600) % 24;
    time -= hours * 3600;
    minutes = Math.floor(time / 60) % 60;
    time -= minutes * 60;
    seconds = time % 60;
    let dayHours = 0;
    if (days.length > 0) {
      dayHours = (days * 24);
    }
    if (hours + dayHours > 0 || minutes > 0 || seconds > 0) {
      return [
        (hours + dayHours) > 0 ? (hours + dayHours) + ' hrs' : '',
        minutes > 0 ? (minutes) + ' ' + this.ts.instant("Mins") : '0 ' + this.ts.instant("Mins")
      ].join(' ');
    } else {
      return '0m';
    }

  }
}
