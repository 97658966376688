import {
    Directive,
    OnInit,
    ElementRef,
    Renderer2,
    HostListener,
    Output,
    EventEmitter,
    Input
} from "@angular/core";
import { Subscription } from "rxjs";
import { KeyboardService } from "./keyboard.service";
import { NgControl } from "@angular/forms";

@Directive({
    selector: "[appOskInput], input [formControlName]"
})
export class OskInputDirective implements OnInit {
    private keySubscription: Subscription;
    private backspaceSubscription: Subscription;
    private enterSubscription: Subscription;
    private measure: HTMLElement;

    @Input() maxlength: string;
    @Output('input') inputAction = new EventEmitter();
    @Output('keyup') keyup = new EventEmitter();
    
    isNumberOnly: boolean = false;

    constructor(private el: ElementRef, private keyboard: KeyboardService, public ngControl: NgControl) { }

    ngOnInit() {
        // TODO I'm sure there's an "Angular way" of doing this
        let thisStyle = window.getComputedStyle(this.el.nativeElement);
        this.measure = document.createElement("span");
        this.measure.style.position = "absolute";
        this.measure.style.right = "100%";
        this.measure.style.font = thisStyle.font;
        document.body.appendChild(this.measure);

        if(this.el?.nativeElement?.hasAttribute('numbersOnly')){
            this.isNumberOnly = true;
        }
    }

    @HostListener("focus")
    private onFocus() {
        // this.keyboard.appService.posView - Can be either null or true. To enable keyboard for Login and POS screens alone
        if (this.keyboard.enableKeyboard && this.keyboard.appService.posView != false) {
            this.keyboard.fireKeyboardRequested(true);
            this.subscribeToKeyboardEvents();
            setTimeout(() => {
                this.el.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
            }, 500);
        }
    }

    @HostListener("blur")
    private onBlur() {
        this.keyboard.fireKeyboardRequested(false);
        this.unsubscribeFromKeyboardEvents();
    }

    private subscribeToKeyboardEvents() {
        this.keySubscription = this.keyboard.keyPressed.subscribe(key =>
            this.onKey(key)
        );
        this.backspaceSubscription = this.keyboard.backspacePressed.subscribe(_ =>
            this.onBackspace()
        );
        this.enterSubscription = this.keyboard.enterPressed.subscribe(_ =>
            this.onEnter()
        );
    }

    private unsubscribeFromKeyboardEvents() {
        this.keySubscription?.unsubscribe();
        this.backspaceSubscription?.unsubscribe();
        this.enterSubscription?.unsubscribe();
    }

    private onKey(key: string) {
        // TODO Refactor this into a single method with the code in onBackspace
        let element = this.el.nativeElement;
        element.focus();
        let start = element.selectionStart,
            end = element.selectionEnd;

        let _value = this.ngControl.control.value ?? '';
        if(this.isNumberOnly && key && isNaN(+key)){
            return;
        }
        if(!this.maxlength || (_value.length < +this.maxlength)){
            this.measure.textContent = _value.substr(0, start) + key;
            _value =
                _value.substr(0, start) + key + _value.substr(end);
            element.selectionStart = element.selectionEnd = start + 1;
            this.ngControl.control.setValue(_value, { emitEvent: true });
            this.keyup.emit(key);
            this.inputAction.emit({ target: { value: _value } });
            this.updateScrollPosition();
        }

    }

    private onBackspace() {
        this.triggerBackspaceEvent();

        setTimeout(() => {
            let element = this.el.nativeElement;
            element.focus();
            let start = element.selectionStart,
                end = element.selectionEnd;

            if (start == 0 && end == 0) {
                return;
            }

            if (start == end) {
                start--;
            }

            let _value = this.ngControl.control.value;
            if (!_value) {
                return;
            }

            this.measure.textContent = _value.substr(0, start);
            _value = _value.substr(0, start) + _value.substr(end);
            element.selectionStart = element.selectionEnd = start;
            this.ngControl.control.setValue(_value, { emitEvent: true });
            this.inputAction.emit({ target: { value: _value } });
            this.updateScrollPosition();
        })
    }

    private updateScrollPosition() {
        let element = this.el.nativeElement;
        element.scrollLeft = this.measure.offsetWidth - (element.clientWidth - 10);
    }

    private onEnter() {
        // TODO
        // alert("Enter");
    }

    triggerBackspaceEvent() {
        const event = new KeyboardEvent('keydown', {
            key: 'Backspace',
            code: 'Backspace',
            which: 8,
            keyCode: 8,
            bubbles: true
        });

        this.el.nativeElement.dispatchEvent(event);
    }
}
