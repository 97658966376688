<section class="order-summary h-100 order-summary-wrapper newVersionWrapper">
  <div *ngIf="_ams.paymentProcessing" [ngClass]="{'order-header-overlay': _ams.paymentProcessing}"></div>
  <div class="order-header LW20">
    <div>
      <i aria-hidden="true" [ngClass]="{'prevent-action': _ams.paymentProcessing}" class="icon-left-arrow pr-2"
        (click)="ContinueShopping()" *ngIf="!_ss.settleOpenTransaction" [attr.automationId]="'Icn_orderSummary_leftArrow'"
        routerLink="/shop/viewshop"></i>{{localization.captions.shop.OrderSummary}}
        <span *ngIf="RetailTicketNumber != '' && RetailTicketNumber != undefined">: {{RetailTicketNumber}}</span>
    </div>
  </div>
  <div class="order-item-details">
    <div class="row-details" [ngClass]="_ams.paymentProcessing ? 'pt-0': 'pt-2'">
      <div class="client-inner">
        <div *ngIf="_ams.paymentProcessing" [ngClass]="{'payment-process': _ams.paymentProcessing}">
        </div>
        <div class="client-details  radio-class" *ngIf="beforeClinet && this.currentProductId != ProductSNCId"
          id="client-type">
          <label class="LW12 label-color">{{localization.captions.shop.ClientDetails}}</label>
             <!-- Activities - retail integration -->
          <mat-radio-group [attr.automationId]="'Rdo_orderSummary_clientDetails'" class="golf__radio" [(ngModel)]="_ams.clientSelect" [disabled]="_ams.paymentProcessing" (change)="clientTypesChange($event)"  [attr.automationId]="'Rdo_orderSummary_clienttype'">
            <mat-radio-button class="golf--form-radio-button" name="clienttype" [value]="type.id"
              *ngFor="let type of clientTypes">{{type.type}}</mat-radio-button>
          </mat-radio-group>
          <div *ngIf="showPlatformGuestSearch && _ams.clientSelect==2 && this.currentProductId != ProductSNCId" class="ag_display--inflex align-items-center" [formGroup]="clientSearchForm">
            <label class="LW14 blckclr mb-0">{{localization.captions.platformGuestSearch}}</label>
            <app-retail-toggle-switch formControlName="isPlatformGuestSearch" class="ml-1"  [attr.automationId]="'Tog_orderSummary_isPlatformGuestSearch'"
            (changeToggleEvent)="isPlatformGuestSearchChanged($event)"></app-retail-toggle-switch>
          </div>
          <div *ngIf="isEmailOnDemandProduct" class="float-right ag_mt--1" (click)="openEmailReceipt()"  [attr.automationId]="'Icn_orderSummary_emailReceipt'">
            <i aria-hidden="true" class="icon-Email mr-2"></i>
            <a [attr.automationId]="'Lbl_orderSummary_emailReceipt'" class="LW14 ag_link" href="javascript:void(0)">{{ShopCaptions.emailReceipt}}</a>
          </div>
        </div>

        <div class="address-section-parent" *ngIf="!beforeClinet && payeeInfo && payeeInfo.name" id="client-details">
          <div class="address-section">
            <div class="address-client">
              <address class="LW14">
                <span class="blckclr d-block" *ngIf="this.currentProductId == ProductSNCId">{{ _ss.isFromSncBeo ?
                  localization.captions.shop.ContactDetails : localization.captions.shop.BookingDetails}}</span>
                <span class="blckclr d-block"
                  *ngIf="this.currentProductId != ProductSNCId">{{localization.captions.shop.ClientDetails}}</span>
                  <div class="d-flex align-items-center justify-flx-start">
                    <div class="mr-2" *ngIf="(currentProductId == ProductSpaId) || (currentProductId === ProductGolfId) || (currentProductId === ProductRetailId) || (currentProductId === ProductPMSId && (_ss.memberCardNumber && _ss.memberCardNumber != '0'))">
                      <ag-img-thumbnail [memberImageUrl]="memberImageURL" [imageContent]="thumbnailImageData" [thumbnail]="false" imageType="client"></ag-img-thumbnail>
                    </div>
                    <div>
                      <div class="d-flex">
                        <span class="txtgray textoverflow font-bold">
                          <i aria-hidden="true" class="icon-Hotel_Guest mr-2" *ngIf="payeeInfo.playerCategoryId == playerCategory.HotelReservation"></i>
                          <ng-container *ngIf="this.currentProductId == ProductSNCId">{{payeeInfo.bookingId}} -
                          </ng-container><i aria-hidden="true" class="icon-hotel-reservation pr-1" *ngIf="payeeInfo.guesttype == 3"></i>{{payeeInfo.name}}
                          <i *ngIf="payeeInfo.vip" aria-hidden="true" class="icon-vip pr-2"></i>
                          <i aria-hidden="true" matTooltip="{{localization.captions.shop.Edit}}" [attr.automationId]="'Icn_orderSummary_edit'"
                            (click)="editClient(payeeInfo.id,(payeeInfo.guesttype&&payeeInfo.guesttype==2)?payeeInfo.guestId:payeeInfo.guestProfileId,payeeInfo?.platformGuestUuid)"
                            class="icon-Edit cursor" [ngClass]="{'prevent-action': _ams.paymentProcessing}"
                            *ngIf="!_ss.settleOpenTransaction && canEnableGuestEditOption && !_ss.isFromEditTeeTime"></i>
                        </span>
                        <span class="txtgray creditbookbalance mr-1"
                          *ngIf="displayCreditBookBalance && (_ss.memberCardNumber && _ss.memberCardNumber != '0') && payeeInfo.creditBookBalance != -1">{{localization.captions.shop.creditBookBalance}}
                          :
                          {{payeeInfo.creditBookBalance | Currency}} 
                          <span> | </span>
                          </span> 
                          <span class="txtgray"
                           *ngIf="payeeInfo.corpName"> {{payeeInfo.corpName}}
                           <span> | </span>
                          </span>
                          <span *ngIf="payeeInfo.membershipType" class="txtgray noWrap mr-1">
                            {{localization.captions.shop.membershipType}}: {{payeeInfo.membershipType}}
                            <span> | </span>
                          </span>
                          <span *ngIf="payeeInfo.discountType" class="txtgray noWrap">
                            {{localization.captions.shop.discountType}}: {{payeeInfo.discountType}}
                          </span>
                      </div>
                      <span class="txtgray d-block"
                        *ngIf="payeeInfo.patronId">{{localization.captions.bookAppointment.PatronID}} :
                        {{payeeInfo.patronId}}</span>
                      <span class="txtgray d-block" *ngIf="payeeInfo.address">{{payeeInfo.address}}</span>
                      <span class="txtgray d-block" *ngIf="payeeInfo.city">{{payeeInfo.city}}</span>
                      <span class="txtgray d-block" *ngIf="payeeInfo.country && payeeInfo.zip">{{payeeInfo.country +" "+
                        payeeInfo.zip}}</span>
                        <div *ngIf="this.payeeFolioInvoiceNumber">
                          <div *ngIf="PropertyInfo.IsUserHasFolioAccess">
                            <span class="txtgray">{{localization.captions.lbl_folio_number}} : </span><a class="ag_link ag_cursor--pointer underline" (click)="viewFolioInNewTab()">{{this.payeeFolioInvoiceNumber}}</a>
                          </div>
                          <div *ngIf="!PropertyInfo.IsUserHasFolioAccess" class="txtgray">{{localization.captions.lbl_folio_number}} : {{this.payeeFolioInvoiceNumber}}</div>
                        </div>
                    </div>
                  </div>
              </address>
            </div>
            <div class="float-right ag_mt--3" (click)="openEmailReceipt()" [attr.automationId]="'Icn_orderSummary_emailReceipt_1'">
              <i aria-hidden="true" class="icon-Email mr-2"></i>
              <a class="LW14 ag_link" href="javascript:void(0)">{{ShopCaptions.emailReceipt}}</a>
            </div>
          </div>
        </div>
        <div class="table-wrapper order-section-container" [ngClass]="!beforeClinet && payeeInfo && payeeInfo.name ? 'order-present-table-wrapper' : ''">
        <div class="search-tab pt-1" *ngIf="!(_ss.isFromSncBeo)" id="search-tab">
          <span (click)="openDialog('orderSummary')" *ngIf="_ams.clientSelect==2 && this._ss.ProductId!=3"
            class="LW12 uppercase text-color float-right pb-2 cursor golf__link" [attr.automationId]="'Lbl_orderSummary_openOrderSummary'"
            [ngClass]="{'button_invalid' : payeeInfo && payeeInfo.name, 'prevent-action': _ams.paymentProcessing  }">{{AddGuest}}</span>
            <span (click)="AddPlayer(localization.captions.shop.AddPlayer)" *ngIf="_ams.clientSelect==2 && this._ss.ProductId==3"
            class="LW12 uppercase text-color float-right pb-2 cursor golf__link ag_link--sm" [attr.automationId]="'Lbl_orderSummary_addPlayer'"
            [ngClass]="{'button_invalid' : payeeInfo && payeeInfo.name, 'prevent-action': _ams.paymentProcessing  }">{{AddGuest}}</span>
          <retail-chips-autocomplete-example [Datalimit]="1" [ngClass]="{'prevent-action': _ams.paymentProcessing}"
            (messageEvent)="receiveMessage($event)" (selectedEvent)="selectPayeeFromSearch($event)"
            [Disabled]="_ss.isAppointmentCheckOut && !isMultiMemberEnabled" (removedCompleteSearch)='deleteCompleteSearch()'
            (removedEvent)="removeFromSearchBar()"(playerSelect)="afterAddPlayer($event)" [automationId]="'orderSummary'"></retail-chips-autocomplete-example>
        </div>
        <label
          class="label-service LW16 blckclr d-block">{{localization.captions.shop.Items}}/{{localization.captions.shop.Services}}</label>
        <div class="button-row header-button" id="header-button">
          <button class="basic-btn border-color mr-2 text-uppercase LW14" [attr.automationId]="'Btn_orderSummary_remove'"
            [ngClass]="{ 'button_invalid' : (_ss.cancelFlag || _ss.depositFlag || depositFlag || _ss.settleOpenTransaction || this.disableRemove || _ams.paymentProcessing)}"
            (click)="RemovedData()" [disabled]="ValidateCheck() || this.disableRemove"
            mat-raised-button>{{localization.captions.shop.REMOVE}}</button>
          <button *ngIf="!PropertyInfo.UseRetailInterface" class="pull-right text-uppercase LW14 apply-btn"
            (click)="OpenApplyDiscountToAll()" [attr.automationId]="'Btn_orderSummary_applyDiscount'"
            [ngClass]="(enableApply && !_ams.paymentProcessing && !cancellationNoShowSelected) ? 'button--primary-save' : 'button--disabled'"
            mat-raised-button>{{localization.captions.shop.APPLYDISCOUNT}}</button>
            <button *ngIf="!PropertyInfo.UseRetailInterface" class="pull-right text-uppercase LW14 apply-btn"
            (click)="applyTicketDiscount()" [attr.automationId]="'Btn_orderSummary_applyTicketDiscount'" [disabled]="this._ss.depositFlag"
            [ngClass]="(_ams.paymentProcessing || this._ss.depositFlag || gridContainsCancellationNoShowSelected) ?  'button--disabled': 'button--primary-save' "
            mat-raised-button>{{localization.captions.shop.APPLYTICKETDISCOUNT}}</button>

            <div *ngIf="!PropertyInfo.UseRetailInterface" class="split-button btn-group splitbuttons" [ngClass]="(!_ams.paymentProcessing) ? '' : 'ag_section--disable'">
              <div #buttonGroup class='buttonGroup'>
                  <button mat-flat-button class='text-capital text LW14 body-bgcolor whitecolor'
                      color="primary" [attr.automationId]="'Btn_ShopServiceItem_ddSelection'">{{localization.captions.shop.MOREACTIONS}}</button>
                  <button mat-flat-button class='text-capital trigger' color="primary"
                  [attr.automationId]="'Btn_ShopServiceItem_menu'" [matMenuTriggerFor]="menu"><i aria-hidden="true" class='icon-Down-Arrow LW12 whitecolor'>&nbsp;</i></button>
              </div>
              <mat-menu  #menu="matMenu" class='golf-split-button-menu'>
                  <button mat-menu-item class='split-button-list' [ngClass]="IsTicketDiscountApplied ? '' : 'ag_section--disable'"
                  (click)="removeTicketDiscount()">{{localization.captions.shop.REMOVETICKETDISCOUNT}}</button>
                  <button mat-menu-item class='split-button-list' [ngClass]="enableRemoveItemDiscount? '' : 'ag_section--disable'"
                  (click)="removeItemsDiscounts()">{{localization.captions.shop.REMOVEITEMDISCOUNT}}</button>
              </mat-menu>
          </div>
        </div>
        <div class="teble-view" [ngClass]="!beforeClinet && payeeInfo && payeeInfo.name ? 'order-present-table-view' : ''">
          <ng-scrollbar   >
            <table aria-describedby="" id="customers" #popOverTarget [attr.automationId]="'Tbl_orderSummary_orderSummaryTable'">
              <thead>
                <tr class="blckClr headerSticky">
                  <th scope='col'>
                    <mat-checkbox [attr.automationId]="'Chk_shopOrderSummary_checkAll'" [disabled]="_ams.paymentProcessing" class="example-margin" [(ngModel)]="checkAll"
                      (change)="ListChecked($event)">
                    </mat-checkbox>
                  </th>
                  <th scope='col' class="LW14">{{localization.captions.shop.SNo}}</th>
                  <th scope='col' class="LW14">{{localization.captions.shop.Items}} /
                    {{localization.captions.shop.Services}}
                  </th>
                  <th scope='col' class="LW14 text-right">{{localization.captions.shop.UnitPrice}}
                    ({{localization.currencySymbol}})
                  </th>
                  <th scope='col' class="LW14 text-right">{{localization.captions.shop.Quantity}}</th>
                  <th scope='col' class="LW14 text-right">{{localization.captions.shop.Gratuity}}
                    ({{localization.currencySymbol}})
                  </th>
                  <th scope='col' class="LW14 text-right">{{localization.captions.shop.ServiceCharge}}
                    ({{localization.currencySymbol}})
                  </th>
                  <th scope='col' class="LW14 text-right"  *ngIf="!isRetailInterfaceEnabled">
                    {{localization.captions.shop.Discount}} ({{localization.currencySymbol}})</th>
                  <th scope='col' class="text-right LW14">
                    {{localization.captions.shop.Subtotal}}({{localization.currencySymbol}})</th>
                  <th></th>
                </tr>
              </thead>
              <tbody *ngIf="GridItems.length > 0">
                <tr class="order-summary-row" *ngFor="let ordersummary of GridItems | OrderSummaryPipe; index as i;">
                  <td>
                    <mat-checkbox [attr.automationId]="'Chk_orderSummary_checklist_{{i}}'" [disabled]="_ams.paymentProcessing || ordersummary.isDeleteDisabled || ordersummary.isModificationRestricted" class="example-margin"
                      [(ngModel)]="ordersummary.ischecked" (change)="ListrowChecked($event, ordersummary, GridItems)">
                    </mat-checkbox>
                  </td>
                  <td class="LW14 txtgray">{{i+ 1}}</td>
                  <td class="LW14 txtgray">
                    {{ordersummary.itemName}}
                    <label class="label-icon">
                      <i  [attr.automationId]="'Icn_orderSummary_itemname_{{i}}'"  aria-hidden="true" *ngFor="let charges of ordersummary.charges index as ind;"
                        [style.display]="(!charges.isApplied || ind == 0)  ? 'none' : ''"
                        class="icon-{{charges.alias}} iconborder blckclr i-info"
                        [ngClass]="{'button_invalid' : this.SettlementHistory.length > 0}"
                        (click)="positionPopover($event, ordersummary, i,charges)"></i>
                    </label>
                    <label class="player-label" *ngIf="ordersummary.playerName">
                      <i aria-hidden="true" class="icon-player"></i>
                      <span>{{ordersummary.playerName}}</span>
                    </label>
                    <label class="player-label" *ngIf="ordersummary.clientName">
                      <i aria-hidden="true" class="icon-profile"></i>
                      <span>{{ordersummary.clientName}}</span>
                    </label>
                  </td>
                  <td class="LW14 txtgray text-right">{{ShowUnitPrice(ordersummary.unitprice)}}</td>
                  <td class="LW14 txtgray text-right">
                    <input attr.automationId='Txt_orderSummary_OSunitprice_{{i}}'  type="text" maxlength="4" inputtype="nonnegative,nodecimal"
                      (input)="editQuantity('quantity',i,ordersummary);" [(ngModel)]="ordersummary.quantity" class="LW12"
                      [disabled]="_ams.paymentProcessing || ValidateCheck(ordersummary) || IsCustomFeeElement(ordersummary)">
                  </td>
                  <td class="LW14 txtgray text-right">{{PropertyInfo.UseRetailInterface ? ShowUnitGratuity(ordersummary) : getGratuity(ordersummary)}}</td>
                  <td class="LW14 txtgray text-right">{{ PropertyInfo.UseRetailInterface ? ShowUnitServiceCharge(ordersummary) : getServiceCharge(ordersummary) }}</td>
                  <td class="LW14 txtgray text-right" *ngIf="!isRetailInterfaceEnabled">{{ShowDiscount(ordersummary)}}
                  </td>
                  <td class="LW14 txtgray text-right">{{PropertyInfo.UseRetailInterface ? ShowSubTotal(ordersummary) : getSubTotal(ordersummary)}}</td>
                  <td>
                    <i [attr.automationId]="'Icn_orderSummary_paymentprocess_{{i}}'" aria-hidden="true" [ngClass]="{'prevent-action': _ams.paymentProcessing}"
                    [class.disabled]="(ordersummary.isGiftCardItem && this._ss.isReturnWithoutTicket) || IsChargesDisabled(ordersummary)" class="icon-More cursor blckclr"
                      [matMenuTriggerFor]="menu2" matTooltip="{{localization.captions.shop.More}}"></i>
                    <mat-menu #menu2="matMenu" class="matMenu" xPosition="before">
                      <button [attr.automationId]="'Btn_orderSummary_itemlistoption_{{i}}_{{j}}'" mat-menu-item *ngFor="let item of ordersummary.charges index as j;" class="matmenu-button"
                        [ngClass]="{'button_invalid': DisableMoreOption(item, ordersummary)}"
                        (click)="MenuItemClick(item,ordersummary,i)">
                        <span class="LW14">{{item.name}}</span>
                      </button>
                    </mat-menu>
                  </td>
                </tr>

                </tbody>
                <tbody *ngIf="GridItems.length <= 0">
                  <tr>
                    <td colspan="6" class="text-center">{{localization.captions.shop.NoDataFound}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3" class="LW14">
                      <div class="d-flex cstm-algns">
                        <span>{{localization.captions.shop.ShopMore}}</span>
                        <button [attr.automationId]="'Btn_shopOrderSummary_shopMoreValidatetrue'" class="basic-btn-custom border-color LW14 text-uppercase" *ngIf="ValidateCheck()"
                        (click)="ContinueShopping()"
                        [ngClass]="this.cancelFlag || this.depositFlag || _ams.paymentProcessing || this.lockerPayment ? 'button_invalid': 'button_valid'"
                        [disabled]="ValidateCheck()"
                        mat-raised-button>{{localization.captions.shop.CONTINUESHOPPING}}</button>
                      <button [attr.automationId]="'Btn_shopOrderSummary_shopMoreValidatefalse'" class="basic-btn-custom border-color LW14 text-uppercase" *ngIf="!ValidateCheck()"
                        (click)="ContinueShopping()"
                        [ngClass]="this.cancelFlag || this.depositFlag || _ams.paymentProcessing || this.lockerPayment ? 'button_invalid': 'button_valid'"
                        [disabled]="ValidateCheck()"
                        mat-raised-button>{{localization.captions.shop.CONTINUESHOPPING}}</button>
                      <button [attr.automationId]="'Btn_shopOrderSummary_shopMoreProduct3'" *ngIf="this._ss && this._ss.ProductId == 3"
                        class="basic-btn-custom border-color LW14 text-uppercase" (click)="ContinueBooking()"
                        [disabled]="ValidateCheck()"
                        [ngClass]="this.cancelFlag || this.depositFlag || _ams.paymentProcessing || this.lockerPayment ? 'button_invalid': 'button_valid'"
                        mat-raised-button>{{localization.captions.shop.CONTINUEBOOKING}}</button>
                      </div>
                    </td>
                    <td colspan="2"></td>
                    <td class="LW14">
                      <div *ngIf="!PropertyInfo.UseRetailInterface && !isVATEnabled && !_ss.isFromSncBeo" class=" d-flex align-items-center taxExemption">
                        <label class="LW14 blckclr mb-0">{{localization.captions.shop.TaxExemption}}</label>

                        <app-retail-toggle-switch [attr.automationId]="'Tog_shopOrderSummary_taxExemptChange'" [ngClass]="{'prevent-action': _ams.paymentProcessing}"
                          [formControl]="exempttax" class="ml-1" (click)="taxExemptChange()"></app-retail-toggle-switch>
                      </div>
                    </td>
                    <td colspan="2" class="label-div">
                      <label
                      class="LW14 txtgray">{{localization.captions.shop.Gratuity}} ({{localization.currencySymbol}})</label>
                      <label
                      class="LW14 txtgray">{{localization.captions.shop.ServiceCharge}} ({{localization.currencySymbol}})</label>
                      <label
                        class="LW14 txtgray">{{localization.captions.shop.SubTotal}} ({{localization.currencySymbol}})</label>
                      <label *ngIf="!PropertyInfo.UseRetailInterface "
                        class="LW14 txtgray">{{localization.captions.shop.ticketDiscount}} ({{localization.currencySymbol}})</label>
                      <label
                        class="LW14 txtgray">{{localization.captions.shop.Discount}} ({{localization.currencySymbol}})</label>
                        <label class="LW14 txtgray" *ngIf="!isVATEnabled && !PropertyInfo.UseRetailInterface && gratuityTax > 0">
                          {{localization.captions.shop.GratuityTax}} ({{localization.currencySymbol}})</label>
                        <label class="LW14 txtgray" *ngIf="!isVATEnabled && !PropertyInfo.UseRetailInterface && serviceChargeTax > 0">
                          {{localization.captions.shop.ServiceChargeTax}} ({{localization.currencySymbol}})</label>                          
                      <label class="LW14 txtgray" *ngIf="!isVATEnabled">
                        {{localization.captions.shop.Tax}} ({{localization.currencySymbol}})</label>
                      <label class="LW14 txtgray" *ngIf="!isVATEnabled && !PropertyInfo.UseRetailInterface && totalTaxOnTax != 0">
                        {{localization.captions.shop.TaxOnTax}} ({{localization.currencySymbol}})</label>
                      <label class="LW14 txtgray" *ngIf="!PropertyInfo.UseRetailInterface && SurchargeEnabled">
                        {{localization.captions.shop.lbl_Surcharge}} ({{localization.currencySymbol}})</label>
                      <label class="LW14 txtgray" *ngIf="isVATEnabled  && !PropertyInfo.UseRetailInterface">
                        {{localization.captions.setting.vat}} ({{localization.currencySymbol}})</label>
                      <label class="LW14 txtgray" *ngIf="isVATEnabled  && !PropertyInfo.UseRetailInterface && showServiceVAT">
                        {{localization.captions.setting.serviceChargeVat}} ({{localization.currencySymbol}})</label>
                      <label class="LWB14 blckclr">
                        {{localization.captions.shop.Total}} ({{localization.currencySymbol}})
                      </label>

                    </td>
                    <td colspan="3" class="data-div">
                      <label class="LW14 txtgray">{{ShowGratuity()}}</label>
                      <label class="LW14 txtgray">{{ShowServiceCharge()}}</label>
                      <label class="LW14 txtgray">{{PropertyInfo.UseRetailInterface ? ShowFinalSubTotal() :
                        FormatPrice(this.subTotal)}}</label>
                      <label *ngIf="!PropertyInfo.UseRetailInterface" class="LW14 txtgray">{{ShowCheckDiscount()}}</label>
                      <label class="LW14 txtgray">{{ShowFinalDiscount()}}</label>
                      <label class="LW14 txtgray" *ngIf="!isVATEnabled && !PropertyInfo.UseRetailInterface && gratuityTax > 0"
                        [ngClass]="exempttax.value ? 'button_invalid' : ''">{{FormatPrice((exempttax.value) ? 0 : gratuityTax)}}</label>
                      <label class="LW14 txtgray" *ngIf="!isVATEnabled && !PropertyInfo.UseRetailInterface && serviceChargeTax > 0"
                        [ngClass]="exempttax.value ? 'button_invalid' : ''">{{FormatPrice((exempttax.value) ? 0 :
                        serviceChargeTax)}}</label>                        
                      <label class="LW14 txtgray" *ngIf="!isVATEnabled"
                        [ngClass]="exempttax.value ? 'button_invalid' : ''">{{FormatPrice((exempttax.value) ? 0 : totalTaxValue)}}</label>
                      <label class="LW14 txtgray" *ngIf="!isVATEnabled && !PropertyInfo.UseRetailInterface && totalTaxOnTax != 0"
                        [ngClass]="exempttax.value ? 'button_invalid' : ''">{{FormatPrice((exempttax.value) ? 0 :totalTaxOnTax)}}</label>
                      <label class="LW14" *ngIf="!PropertyInfo.UseRetailInterface && SurchargeEnabled">{{FormatPrice(totalSurcharge)}}</label>
                      <label class="LW14 txtgray" *ngIf="isVATEnabled  && !PropertyInfo.UseRetailInterface"
                        [ngClass]="exempttax.value ? 'button_invalid' : ''">{{ShowFinalVATAmountTotal()}}</label>
                      <label class="LW14 txtgray" *ngIf="isVATEnabled  && !PropertyInfo.UseRetailInterface && showServiceVAT"
                       >{{FormatPrice(serviceVAT)}}</label>
                      <label class="LWB14 blckclr" *ngIf="!PropertyInfo.UseRetailInterface">{{exempttax.value ?
                        FormatPrice(this.totalAmountWithOutTax) : FormatPrice(totalAmount+totalSurcharge)}}</label>
                      <label class="LWB14 blckclr" *ngIf="PropertyInfo.UseRetailInterface">{{FormatPrice(this.totalAmount+ (
                        (exempttax.value) ? 0 : taxValue ))}}</label>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </ng-scrollbar>
            <!-- Popover starts  -->
            <!-- <popover-content id="discountPopover" #discountPopover placement="bottom" class="discount-popover"
              [style.left.px]="xPos" [style.top.px]="yPos" [ngClass]="[isRight ? 'right-aligned': 'left-aligned',isOpened?'custom-discount':'']">
              <app-apply-discount [ApplyDiscountInput]="applyDiscountInput" (discountEmitter)="applyDiscount($event)"
                (closeEmitter)="discountPopover.hide();"></app-apply-discount>
            </popover-content> -->
          </div>
        </div>
      </div>
      <app-payment [CreateRetailItemResponse]="CreateRetailItemResponse" [totalAmount]="TicketTotal"
        [payeeInfo]="payeeInfo" [SelectedTerminalId]="_ss.SelectedTerminalId" [SelectedOutletId]="_ss.SelectedOutletId"
        [roomDetailsArray]="roomDetailsArray" [GuaranteeMethodData]="_ss.GuaranteeMethodData"
        [GridItems]="GridItems" [isTaxExempted]="isTaxExempted" [recalculateTax]="shouldTaxRecalculate"
        [showRainCheckButtonIG]="showRainCheckButtonIG" [ResetRainCheckSelection]="ResetRainCheckSelection"  (RainCheckSelectionResetedEvent) = "OnRainCheckSelectionReseted($event)"
        [CreateCallValidationError]="isCreateTransactionValidationError"
        [isTicketCreated]="isTicketCreated" [IsExpireDateVisible] = "IsExpireDateVisible"
        (PaymentMethodSelection)="selectMethod($event)"
        (RecalculateTicketOnAmountChange) = "recalculateTicketBasedOnTender($event)"
        (BeginTransaction)="onPaymentTransactionClick()"
        (TransactionCompleted)="OnTransactionComplete($event)" (CloseTransactionClick)="PerformCloseTransaction()"
        (PaymentHistoryEmit)="MapsettlementHistory($event)" (Cancelled)="CancelOrder()"
        (RainCheckEvents)="HandleRaincheckEvents($event)"
        (SurchargeUpdated)="HandleSurchargeUpdates($event)"
        (TicketRecalculated)="updateOrderSummary()"
        (ApplyTipAsGratuity)="ApplyTipAsGratuity($event)"
        [isUseNewArCustomerSearch]="isUseNewArCustomerSearch">
        <form [formGroup]="transitionDetails" class="h-100 transition-details shop-payment-summary" paymentmethod>
          <div class="payment-details mt-2">
            <span class="LWB14 uppercase payment-deatils-label mb-2" [attr.automationId]="'Lbl_orderSummary_paymentDetails'"
              (click)="appoinmentCheck()">{{localization.captions.shop.PaymentDetails}}</span>
            <div [class.disabled]="commentDisable" class="mt-2" *ngIf="!PropertyInfo.UseRetailInterface">
              <mat-form-field [floatLabel]="floatLabel">
                <input matInput type="text" formControlName="receiptComment"
                  [attr.automationId]="'Txt_orderSummary_receiptComment'"
                  placeholder="{{localization.captions.shop.ReceiptComment}}" maxlength="100">
              </mat-form-field>
            </div>
            <div *ngIf="isGiftReceiptEnabled" class="printgift-width" [ngClass]="{'prevent-action': commentDisable}">
              <label class="LW14 payment-deatils-label mb-2">{{localization.captions.shop.PrintGiftReceipt}}</label>
              <app-retail-toggle-switch formControlName="printGiftReceipt" class="ml-1"
                [attr.automationId]="'Tog_orderSummary_PrintGiftReceipt'"></app-retail-toggle-switch>
            </div>
          </div>
        
        </form>
        <div [formGroup]="transitionDetails" class="return-reason" returnReason
          *ngIf="_ss.isReturnWithoutTicket && showReturnReason">
          <mat-form-field class="w-100" [floatLabel]="floatLabel">
            <input matInput placeholder="{{localization.captions.shop.ReturnReason}}" [required] ="_ss?.requiredReturnReason" #returnReason
              formControlName="returnReason" required [attr.automationId]="'Txt_orderSummary_returnReason'">
            <mat-error>{{localization.captions.shop.MissingReturnReason}}</mat-error>
          </mat-form-field>
        </div>
      </app-payment>
    </div>

  </div>
</section>
