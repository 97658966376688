<section class="newVersionWrapper view-non-integarted-payments-wrapper ag_height--100">
    <section class="view-non-integrated-content">
        <section class="view-non-integrated">
            <div class="page-header pl-3 pt-3">
                <div class="float-left d-inline-flex align-items-center retail-data">
                    <mat-form-field class="selectOutlet mr-3" [floatLabel]="floatLabel" [ngClass]="showOutlet == true ? '' : 'display-none' ">
                        <mat-select [attr.automationId]="'Dd_shopRetailTable_selectOutlet'" id="selectOutlet" #selectOutlet
                            [(ngModel)]="selectedOutlet" (selectionChange)="outletChange($event)"
                            placeholder="{{captions.shop.Outlet}}">
                            <mat-option *ngFor="let outlet of outletArray" [value]="outlet.subPropertyID">
                                {{ outlet.subPropertyName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                        <mat-form-field class="mr-3 date-picker-width" appearance="legacy"
                            [floatLabel]="floatLabel">
                            <mat-label>{{captions.shop.fromDate}}</mat-label>
                            <input [attr.automationId]="'Txt_shopRetailTable_fromDate'" matInput #fromDateModel="ngModel"
                                [matDatepicker]="picker" dateRestricter [placeholder]="placeholderFormat"
                                [(ngModel)]="fromDate" (dateChange)="onDateChange('startDate',$event)" autocomplete="off">
                            <mat-datepicker #picker></mat-datepicker>
                            <i [attr.automationId]="'Icn_shopRetailTable_fromDatePicker'" aria-hidden="true" matSuffix
                                class="icon-Calender cursor fs-xlarge position-relative d-inline-block"
                                (click)="picker.open()"></i>
                            <mat-error
                                *ngIf="fromDateModel.hasError('matDatepickerParse')">{{captions.common.dateFormat}}</mat-error>
                            <mat-error *ngIf="fromDateModel.hasError('matDatepickerMin')">{{captions.common.minimum}}
                                {{captions.shop.fromDate}}</mat-error>
                            <mat-error *ngIf="fromDateModel.hasError('matDatepickerMax')">{{captions.common.maximum}}
                                {{captions.shop.fromDate}}</mat-error>
                        </mat-form-field>
                        <mat-form-field class="d-inline-block datepicker-form-field date-picker-width" appearance="legacy"
                            [floatLabel]="floatLabel">
                            <mat-label>{{captions.shop.toDate}}</mat-label>
                            <input [attr.automationId]="'Txt_shopRetailTable_toDate'" matInput #toDateModel="ngModel"
                                dateRestricter [matDatepicker]="topicker" [(ngModel)]="toDate"
                                [placeholder]="placeholderFormat" (dateChange)="onDateChange('endDate',$event)"
                                autocomplete="off" [min]="fromDate">
                            <mat-datepicker #topicker></mat-datepicker>
                            <i [attr.automationId]="'Icn_shopRetailTable_toDatePicker'" aria-hidden="true" matSuffix
                                class="icon-Calender cursor fs-xlarge position-relative d-inline-block"
                                (click)="topicker.open()"></i>
                            <mat-error
                                *ngIf="toDateModel.hasError('matDatepickerParse')">{{captions.common.dateFormat}}</mat-error>
                            <mat-error *ngIf="toDateModel.hasError('matDatepickerMin')">{{captions.common.minimum}}
                                {{captions.shop.toDate}}</mat-error>
                            <mat-error *ngIf="toDateModel.hasError('matDatepickerMax')">{{captions.common.maximum}}
                                {{captions.shop.toDate}}</mat-error>
                        </mat-form-field>
                </div>
            </div>
           
                    <app-reprint-advanced-search [serviceID]="serviceID" [outlet]="selectedOutlet"
                    [isLoading]="isAdvancedSearchLoading" (search)="onAdvancedSearchClick($event)"
                    (close)="onAdvancedSearchClose()">
                </app-reprint-advanced-search>
                <div class="ml-2 mr-2 payment-refund-table">
                    <app-cdkvirtual [headerOptions]="headerOptions" [tableContent]="tableContent" [options]="tableOptions"
                        (EmittedData)='tableAction($event)' [searchOptions]="searchText" [childTemplate]="childTemplate"
                        [attr.automationId]="'Tbl_viewNonIntegratedPayments_viewNonIntegratedPayments'"></app-cdkvirtual>
                </div>

        </section>
    

            <div class='ag_footer--actions ag_display--flex'>
                <app-button [buttontype]='saveButton' [ngClass]=" enableSaveBtn ? 'button_valid' : 'button_invalid'"
                    (valueChange)='onAction()'></app-button>
                <app-button [buttontype]='cancelButton' (valueChange)='onCancel()'></app-button>
            </div>
            
    </section>
   
</section>
<ng-template #childTemplate let-element="element" let-key="key" let-index="idx">
    <ng-container [ngSwitch]="key">
        <ng-container *ngSwitchCase="'paymentComment'">
            <div class=m-1>
                <textarea [(ngModel)]="element.paymentComment" name="paymentComment"
                    (ngModelChange)="onPaymentCommentChange(element)" rows="4" cols="50">
                </textarea>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'cardDetails'">
            <div class="m-1">
                <a [href]="element.cardDetails" target="_blank" name="cardDetails"
                    class="ag_link golf__link spa-filter-link">{{captions.shop.GetCardDetail}}</a>
            </div>
        </ng-container>
    </ng-container>
</ng-template>