<div class="BGoverlay" (click)="closeMoreWidow()" *ngIf="this._ViewMoreServiceService.showViewMore"></div>
<section class="viewMoreSection pl-4 pr-4" id="ViewMoreSecID{{fromSection}}" container="body" [ngClass]="[(this.displayDataDetails.popupposition=='bottom') ? 'pt-15' : '' , isPopoverEnable ? '':'d-none']"
  *ngIf="this._ViewMoreServiceService.showViewMore" (mouseleave)="closeMoreWidow()" >
  <!-- Search Filter Section -->
  <i  aria-hidden="true" class="arrow up" *ngIf="this.displayDataDetails.popupposition=='top'"></i>
  <div class="clearfix h-100">
    <ng-scrollbar  >
      <button mat-raised-button class="clickbtntxtcss mb-2 p-0 body-bordercolor spaClickButton Wauto"
      [ngClass]=" {' body-bgcolor whitecolor': this.displayDataDetails.selecteddisplaydataArr.length ? this.displayDataDetails.selecteddisplaydataArr.indexOf(ServiceDetails[this.displayDataDetails.dispArrKey[0]])!=-1 : this.displayDataDetails.selecteddisplaydataArr == ServiceDetails[this.displayDataDetails.dispArrKey[0]]}"
        *ngFor="let ServiceDetails of this.displayDataDetails.disaplydataArr | slice:this.displayDataDetails.SDcount:this.displayDataDetails.disaplydataArr.length; let i = index"
        value="{{ServiceDetails.code}}" [matTooltip]="ServiceDetails[this.displayDataDetails.dispArrKey[1]] "
        (click)="emitOnClick($event,this.displayDataDetails.section,ServiceDetails)" [disabled]="enbdisdisabledflag">
        {{ServiceDetails[this.displayDataDetails.dispArrKey[1]] }}
      </button>
    </ng-scrollbar>
  </div>
  <i  aria-hidden="true" class="arrow down"  *ngIf="this.displayDataDetails.popupposition=='bottom'"></i>
</section>
