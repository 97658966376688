
import { PropertySettingDataService } from 'src/app/common/dataservices/authentication/propertysetting.data.service';
import { UI, API } from 'src/app/common/Models/property-settings.model';
import {
  PROPERTY_INFO,
  PROPERTY_DATE,
  USER_SETTINGS,
  PROPERTY_CONFIGURATION_SETTINGS, USER_INFO, USER_SESSION, JWT_TOKEN
} from 'src/app/app-constants';
import { Injectable } from '@angular/core';
import { CommonUtilities } from '../shared/shared/utilities/common-utilities';
import { Localization } from '../localization/localization';
import { ChangePropertySevice } from './change-property.service';
import { MsGraphApiCommunication } from '../communication/services/ms-graph.service';
import { ManageSessionService } from 'src/app/login/manage-session.service';
import { CommonPropertyInformation } from '../shared/services/common-property-information.service';
import { Product, PropertyInformation } from '../Models/common.models';
import { PropertySetting } from '../shared/shared/business/view-settings.modals';
import { UserdefaultsInformationService } from './userdefaults-information.service';
import { MachinePrinterConfigDataService } from '../dataservices/machine-printer-configuration.data-services';
import { API as mpc } from "src/app/common/Models/printer-default-configuration.model";
import { AuthenticationService } from '../shared/services/authentication.service';
import { AuthorizeTokenBySession, ConfigKeys, FeatureName } from '../shared/retail.modals';
import { environment } from 'src/environments/environment';
import { CommonBreakPoint, STAY } from '../shared/shared/globalsContant';
import { UserAccessModel } from '../dataservices/authentication/useraccess-model.model';
import { PropertyInformationDataService } from '../dataservices/authentication/property-information.data.service';
import { NO_OF_DECIMAL_DIGITS, PMSSSYSTEM } from '../constants';
import { SessionManagerService }  from 'src/app/common/shared/services/session-manager.service'
import { JasperServerCommonDataService } from 'src/app/common/dataservices/jasperServerCommon.data.service';
import { UICache } from './cache/cache-constants';
import { StorageService } from 'src/app/common/shared/services/session-utilization.service';
import { ProductApplicationType } from '../shared/shared.modal';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class PropertyService {
  isGoogleApiLoaded: boolean = false;
  private autoLogOff: any = false;
  private logOffAfter = 1;
  constructor(
    private sessionService: ManageSessionService,
    private PropertySettingService: PropertySettingDataService,
    private propertyInfo: CommonPropertyInformation,
    private userDefaultsService: UserdefaultsInformationService,
    private graphClient: MsGraphApiCommunication,
    private utils: CommonUtilities,
    private changePropertySevice: ChangePropertySevice,
    private localize: Localization,
    private _machinePrinterConfigDataService: MachinePrinterConfigDataService,
    private authentication: AuthenticationService,
    private propertyInformationDataService : PropertyInformationDataService,
    private sessionManagerService:  SessionManagerService,
    private jasperServerCommon: JasperServerCommonDataService,
    public storageservice:StorageService
  ) { }

  SetUserInfo(userDetails) {
    const userProperties = userDetails.userProperties ? userDetails.userProperties : {};
    const language = userDetails.userLoginInfo && userDetails.userLoginInfo.languageCode;
    const userInfo = `userId=${userDetails.userLoginInfo.userId};
                        userName=${userDetails.userLoginInfo.userName};
                        firstName=${userDetails.userLoginInfo.firstName};
                        lastName=${userDetails.userLoginInfo.lastName};
                        roleId=${userProperties ? userProperties[0].roleId : 1};
                        roleName=${userProperties ? userProperties[0].roleName : ''};
                        language=${language};
                        changePropertyEnabled=${userDetails.userLoginInfo.isPropertyChangeAllow};
                      `;
   
    sessionStorage.setItem(USER_INFO, userInfo);
  }

  async UpdateUserSessionInfo(propertyDetails, userDetails, userSession?: string, propertityConfig: API.PropertyConfigurationSettings<any> = null) {
    this.userDefaultsService.syncDefaultValues(userDetails.userLoginInfo.userId);
    await this.setpropertyvalues(propertyDetails, userDetails, userSession, propertityConfig);
    this.setPropertyDateforAccounting();
  }

  setUserSession(usersessionId: string) {
    sessionStorage.setItem(USER_SESSION, String(usersessionId));
   
    this.updateSessionIdInLocalStorage(usersessionId);
  }

  async GetPropertyConfigDetailsByUserId(userId: number | string): Promise<UI.PropertyConfiguration[]> {
    const propertyConfigDetails = await this.PropertySettingService.GetAllPropertyConfigurationSettingsByUserId({
      configurationName: PROPERTY_CONFIGURATION_SETTINGS,
      userId
    } as API.PropertyConfigurationSettings<any>);
    return this.mapToUIPropertyConfiguration(propertyConfigDetails);
  }
  async SetExternalPMSConfigFlag()
  {
    let pmsSystem = this.localize.GetPropertyConfig(PMSSSYSTEM)
    sessionStorage.setItem("isExternalPMSIntegrated",pmsSystem && pmsSystem.toLowerCase() != STAY.toLowerCase() ? "true" : "false");
  }

  private async setpropertyvalues(Selectedproperty: any, userDetails, userSessionId, propertityConfig: API.PropertyConfigurationSettings<any> = null) {
    const result = userDetails.userProperties.find(
      item => item.propertyCode === Selectedproperty.id
    );
    const userInfo = userDetails.userLoginInfo;
    this.setPropertyInfoInSession(result, userInfo);
    this.changePropertySevice.SetPropertyName(result.propertyName);
    if (!userSessionId) {
      userSessionId = await this.sessionService.createSession();
    }
    this.setUserSession(String(userSessionId));
    // await this.sessionService.multiproperyLogin(userSessionId);
    await this.SetPropertyInfo(result, propertityConfig);
    await this.setUserSettings(result, userInfo);
    this.propertyInfo.SetPropertyDate(
      this.utils.getDate(result.propertyDate),
      false
    );
    this.localize.SetLocaleBasedProperties();
    this.UpdateUserRole(result);
    await this.setAuthorizeTokenBySession();
    this.changePropertySevice.SetPropertyChanged(true);
   // const jaspersoftUri = await this.PropertySettingService.GetJasperSoftServerUri();
    await this.setJasperAttributes(result?.roleId);
  }

  async setJasperAttributes(roleId?: any) {
    let prodId = this.localize.GetPropertyInfo('ProductId');
    try {
      await this.SetJasperSoftServerMenuAccess();

      let jasperServerURL: string = await this.jasperServerCommon.GetJasperServerBaseURL();
      const jasperAccess = await this.PropertySettingService.getUserAccessDetails(CommonBreakPoint.JastSoftStudio, roleId);
      // Add other product if want to implement a Role Attribute
      if (jasperAccess.isAllow && (Number(prodId) == Product.SNC || Number(prodId) == Product.PMS || Number(prodId) == Product.ACCOUNTING || Number(prodId) == Product.SPA || Number(prodId) == Product.GOLF || Number(prodId) == Product.RETAIL)) {
        this.PropertySettingService.UpdateRoleAndAttributeToUser();
        sessionStorage.setItem("jaspersofturi", jasperServerURL);
      }
    }
    catch (error) {
      console.log('Error Occurred While Jasper Role Update');
    }
  }

  async setAuthorizeTokenBySession() {
    let prodId = this.localize.GetPropertyInfo('ProductId');
    if (Number(prodId) == Product.SPA || Number(prodId) == Product.GOLF) {
    try {
      const enableResortFinance = sessionStorage.getItem('enableResortFinance');
      if (enableResortFinance?.toLowerCase() == 'false')
      {
        return;
      }
      this.resetAccFolioDetails();
      const propConfig :  { [key: string]: string } = JSON.parse(sessionStorage.getItem('propConfig'));
      if(!propConfig) { return ; }

      const IsAccountingInSameProperty : boolean = propConfig[AuthorizeTokenBySession.IsAccountingInSameProperty]?.toLowerCase() == 'true' || false;
      const accPropertyId = propConfig[AuthorizeTokenBySession.AccountingPropertyId];
        if(accPropertyId) {
          let accRequest: any = {
            Client: this.utils.GetPropertyInfo('userName'),
            TenantId: Number(this.utils.GetPropertyInfo('TenantId')),
            PropertyId: Number(propConfig[AuthorizeTokenBySession.AccountingPropertyId]),
            TenantCode: '',
            ProductId: Product.ACCOUNTING,
            ClientSecret: sessionStorage.getItem('userSession')
         }
         const authResponseForAccounting = await this.authentication.AuthorizeBySession(accRequest);
          if(authResponseForAccounting !=null && authResponseForAccounting?.result != null)
          {
            sessionStorage.setItem(AuthorizeTokenBySession.AccUserSession, authResponseForAccounting.result.sessionId);
            sessionStorage.setItem(AuthorizeTokenBySession.AccJwt, authResponseForAccounting.result.token);
          }
        }

      const IsFolioInSameProperty : boolean = propConfig[AuthorizeTokenBySession.IsFolioInSameProperty]?.toLowerCase() == 'true' || false;
      const folPropertyId = propConfig[AuthorizeTokenBySession.FolioPropertyId];
        if(folPropertyId) {
          let folRequest: any = {
            Client: this.utils.GetPropertyInfo('userName'),
            TenantId: Number(this.utils.GetPropertyInfo('TenantId')),
            PropertyId: Number(propConfig[AuthorizeTokenBySession.FolioPropertyId]),
            TenantCode: '',
            ProductId: Product.Folio,
            ClientSecret: sessionStorage.getItem('userSession')
         }
        const authResponseForFolio = await this.authentication.AuthorizeBySession(folRequest);
         if(authResponseForFolio !=null && authResponseForFolio?.result != null)
         {
           sessionStorage.setItem(AuthorizeTokenBySession.FolioUserSession, authResponseForFolio.result.sessionId);
           sessionStorage.setItem(AuthorizeTokenBySession.FolioJwt, authResponseForFolio.result.token);
         }
        }

    } catch (ex) {
          console.log(ex);
      }
    }
  }

  resetAccFolioDetails() {
    sessionStorage.removeItem(AuthorizeTokenBySession.FolioUserSession);
    sessionStorage.removeItem(AuthorizeTokenBySession.FolioJwt);
    sessionStorage.removeItem(AuthorizeTokenBySession.AccUserSession);
    sessionStorage.removeItem(AuthorizeTokenBySession.AccJwt);
  }


  async SetPropertyInfo(result: any, propertityConfig: API.PropertyConfigurationSettings<any> = null) {
    const propertyId: number = Number(result.propertyId);
    if (result.productId === Product.ACCOUNTING) {
      this.setcurrentPopertyDate(result.propertyDate, propertyId);
    }
    this.propertyInfo.SetPropertyDate(result.propertyDate);
    this.propertyInfo.SetPropertyId(propertyId);
    this.SetPropertyConfiguration();
    this.SetADMConfig();
    this.isGoogleApiLoaded = false;
    await this.SetPropertyApiConfiguration(propertyId, propertityConfig);
    await this.setEatecConfig();
    if (result.productId === Product.PMS || result.productId === Product.ACCOUNTING) {
      if (result.productId === Product.ACCOUNTING) {
        this.setRoutesfromTenant();
      }
      await this.SetDefaultOutlet();
    }
  }

  setcurrentPopertyDate(existingDate: Date, propertyId: number) {
    let currentdatetimeforacct = new Date();
    sessionStorage.setItem('currentdatetimeforacct', currentdatetimeforacct.toString());
    currentdatetimeforacct.setHours(0, 0, 0, 0);
    if (currentdatetimeforacct.toDateString() != new Date(existingDate).toDateString()) {
      this.PropertySettingService.UpdatePropertySettingDateforaccounting(this.localize.convertDateToAPIdate(currentdatetimeforacct), propertyId);
    }
  }

  setPropertyDateforAccounting() {
    let datetime = sessionStorage.getItem('currentdatetimeforacct')
    if (datetime != null)
      this.propertyInfo.SetPropertyDate(this.localize.getDate(datetime))
  }

  setRoutesfromTenant() {
    try {
      let propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
      if (propConfig) {
        let propConfigkeys = Object.keys(propConfig).filter(x => x.toLocaleLowerCase().includes("actuir"));
        let envkeys = Object.keys(environment).filter(x => !x.includes("production"));
        envkeys.forEach(x => {
          let propkeytoreplace = propConfigkeys.find(pc => pc.toLocaleLowerCase().replace("actuir", "") == x.toLocaleLowerCase())
          if (propkeytoreplace) {
            environment[x] = propConfig[propkeytoreplace];
          }
        });
        this.PropertySettingService.reCreateRetailCommunicationInstance();
      }
    }
    catch (e) {
      console.error(e)
    }
  }

  async setUserSettings(properties, userInfo) {
    /*To get the daysout value*/
    const userSettings = `
              AutoLogOff=${properties ? properties['autoLogOff'] : ''};
              LogOffAfter=${properties ? properties['logOffAfter'] : ''};`;
   
    sessionStorage.setItem(USER_SETTINGS, userSettings);
    this.setAutoLogOff();
  }

  UpdateUserRole(userSelectedProperty) {
    let user = sessionStorage.getItem('_userInfo');
    let userInfoArr = user.split(';');
    userInfoArr = userInfoArr.map((item) => {
      if (item.includes('roleId')) {
        return userSelectedProperty && userSelectedProperty.roleId ? 'roleId=' + userSelectedProperty.roleId : item;
      } else if (item.includes('roleName')) {
        return userSelectedProperty && userSelectedProperty.roleName ? 'roleName=' + userSelectedProperty.roleName : item;
      } else {
        return item;
      }
    });
    user = userInfoArr.join(';');
    sessionStorage.setItem('_userInfo', user);
    this.changePropertySevice.SetRoleName(userSelectedProperty.roleName);
  }


  async SetPropertyConfiguration() {
    const propertityConfig = await this.PropertySettingService.getAllPropertySetting(this.propertyInfo.PropertyId);
    this.propertyInfo.SetPropertySetting(propertityConfig as PropertySetting);
  }

  async SetADMConfig() {
    const isAdmEnabled = await this.PropertySettingService.getIsAdmEnabled(this.propertyInfo.PropertyId);
    this.propertyInfo.SetADMSetting(isAdmEnabled);
  }

  async SetJasperSoftServerMenuAccess(){
    let prodId =  this.localize.GetPropertyInfo('ProductId');
    if(Number(prodId) == Product.SNC || Number(prodId) == Product.PMS || Number(prodId) == Product.ACCOUNTING || Number(prodId) == Product.GOLF || Number(prodId) == Product.SPA || Number(prodId) == Product.RETAIL) { // Add other product if want to implement a breakpoint
      let existingValue = sessionStorage.getItem("showJasperSoftServerMenu");
      if(existingValue == null){
        let jasperSoftStudioMenuAccess : UserAccessModel.BreakPointResult = await this.PropertySettingService.getUserAccess(CommonBreakPoint.JastSoftStudio,false);
        let accessLevel : string = jasperSoftStudioMenuAccess.isAllow ? 'true' : 'false';
        sessionStorage.setItem("showJasperSoftServerMenu", accessLevel);
      }

      } else{
      sessionStorage.setItem("showJasperSoftServerMenu", 'false');
    }

  }

  async setEatecConfig() {
    const propInfoId = Number(this.localize.GetsessionStorageValue('propertyInfo', 'PropertyId'));
    const features = await this.PropertySettingService.getPropertyFeatures(propInfoId);
    if (features != null && features.length) {
      const eatecFeature = features.find(x => x.featureName === FeatureName.EnhancedInventory);
      if (eatecFeature != null && eatecFeature.isActive) {
        sessionStorage.setItem('isEatecEnabled', 'true');
        this.PropertySettingService.getFeatureConfiguration(eatecFeature.id, eatecFeature.moduleId).then((featureconfigurations) => {
          if (featureconfigurations != null && featureconfigurations.length > 0) {
            const eatecUser = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EatecTenantUser);
            const uri = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EatecURI);
            if (eatecUser && eatecUser.configurationValue && uri && uri.configurationValue) {
              this.propertyInfo.SetEatecURI(uri.configurationValue);
            } else {
              this.propertyInfo.SetEatecURI('');
            }
          } else {
            this.propertyInfo.SetEatecURI('');
          }
        });
        await this.setEatecToken();
      }
      else {
        sessionStorage.setItem('isEatecEnabled', 'false');
        this.propertyInfo.SetEatecURI('');
      }
    }
  }
  async setEatecToken() {
    try {
      const token = await this.authentication.getEatecToken();
      sessionStorage.setItem('eatecJwt', token.result);
    } catch (ex) {
      console.log(ex);
    }
  }

  async SetDefaultOutlet() {
    let defaultOutlet = await this.PropertySettingService.getDefaultOutlet();
    if (defaultOutlet > 0) {
      this.propertyInfo.SetDefaultOutlet(defaultOutlet);
      // To create Default Outlet User Access For PMS
      await this.PropertySettingService.CheckandCreateOutletAccess(defaultOutlet, Number(this.propertyInfo.GetPropertyInfoByKey('UserId')));
    }
    // // To create Default Outlet if not created for property PMS
    // else {
    //   await this.PropertySettingService.syncDefaultOutlet();
    // }
  }


  async SetPropertyApiConfiguration(properId?: number, propertityConfig: API.PropertyConfigurationSettings<any> = null) {
    if (!this.isGoogleApiLoaded) {
      let sessionPropertyId: number = properId;
      if (this.propertyInfo.hasOwnProperty('propertyId')) {
        sessionPropertyId = this.propertyInfo.PropertyId;
      }

      if(propertityConfig == null)
      {
        propertityConfig = await this.PropertySettingService.GetAllPropertyConfigurationSettings({
          configurationName: PROPERTY_CONFIGURATION_SETTINGS,
          propertyId: sessionPropertyId,
          productId: 0
        } as API.PropertyConfigurationSettings<any>);
      }
      this.SetNoOfDecimalDigits(propertityConfig);

      let WebCommunicationProxyVersionCheck = sessionStorage.getItem('WebCommunicationProxyVersionCheck');
      if(WebCommunicationProxyVersionCheck == null)
      {
        this.GetWebCommunicationProxyVersion();
      }

      if ((propertityConfig != null) && (Object.keys(propertityConfig.configValue).length > 0)) {
        var window: any;
        if (window && window.google) {
          window.google = {};
        }
        this.updateOutlookInfo(propertityConfig.configValue.clientId);
        this.propertyInfo.SetPropertyConfiguration(propertityConfig);
        const language = this.localize.GetsessionStorageValue('_userInfo', 'language') || 'en-US';
        if (propertityConfig.configValue.GoogleMapApiKey) {
          this.generateGoogleMapApi(propertityConfig.configValue.GoogleMapApiKey, language);
          this.isGoogleApiLoaded = true;
        }
        this.graphClient.Init();
      } else if((propertityConfig != null)) {
        this.propertyInfo.SetPropertyConfiguration(propertityConfig);
      }
    }
if(this.sessionService['isGoogleApiReset']){
  this.sessionService['isGoogleApiReset'].subscribe((res)=>{
    if (res){
      this.isGoogleApiLoaded = false;
    }
  })
}
   this.sessionManagerService.isGoogleApiResetSession.subscribe((res)=>{
       if (res){
        this.isGoogleApiLoaded = false;
      }
    })
    this.SetExternalPMSConfigFlag();
  }

  setAutoLogOff() {
    this.autoLogOff = this.localize.GetUserSettings('AutoLogOff');
    const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
    if (this.autoLogOff === 'true') {
      this.sessionService.resetOnTrigger = true;
      this.logOffAfter = +this.localize.GetUserSettings('LogOffAfter');
      this.sessionService.startTimer(this.logOffAfter, tokenDuration);
    } else {
      this.sessionService.resetOnTrigger = false;
      this.sessionService.stopTimer();
    }
  }

  generateGoogleMapApi(apiKey: string, language: string) {
    const existingScript = document.getElementById('googleMaps');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = this.propertyInfo.GetGoogleMapApiUrl(apiKey, language);
      script.id = 'googleMaps';
      document.body.appendChild(script);
    }
  };

  updateOutlookInfo(clientId: string) {
    if (this.graphClient.clientAuthenticated) {
      const currentPropertyInfo = this.propertyInfo.GetPropertyConfiguration();
      if (!clientId || (currentPropertyInfo && currentPropertyInfo.clientId !== clientId)) {
        this.graphClient.signOutOfGraph();
      }
    } else {
      this.storageservice.removestoragekey('tempState');
    }
  }

  mapToUIPropertyConfiguration(propertyConfigDetails: API.PropertyConfigurationSettings<any>[]): UI.PropertyConfiguration[] {
    return propertyConfigDetails.map(x => {
      return {
        productId: x.productId,
        propertyId: x.propertyId,
        googleMapApiKey: x.configValue.GoogleMapApiKey,
        clientId: x.configValue.clientId,
        hostName: x.configValue.hostName || window.location.origin
      };
    });
  }

  public updatePropertyInfoInLocalStorage(propertyInformation: PropertyInformation) {
    const userSessionDetails = this.sessionService.GetUserSessionsInfo();
    const propertyInfo = userSessionDetails.userProperties.find(x => x.propertyId === propertyInformation.propertyId);
    if (propertyInfo) {
      propertyInfo.propertyName = propertyInformation.propertyName;
      propertyInfo.languageCode = propertyInformation.languageCode;
      propertyInfo.tenantId = propertyInformation.tenantId;
    }
    this.sessionService.SetUserSessionsInfo(userSessionDetails);
  }

  public updatePropertyInfoInSessionStorage() {
    const userSessionDetails = this.sessionService.GetUserSessionsInfo();
    const properDetails = userSessionDetails.userProperties.find(x => x.propertyId === this.getPropertyId());
    const userInfo = userSessionDetails.userLoginInfo;
    this.setPropertyInfoInSession(properDetails, userInfo);
  }

  public updateOtherPropertyInfo() {
    this.changeTitle();
    this.changePropertySevice.SetPropertyName();
    this.localize.SetLocaleBasedProperties();
  }

  public isChangePropertyEnabled(): boolean {
    return (this.localize.GetsessionStorageValue('_userInfo', 'changePropertyEnabled') === 'true');
  }

  public changeTitle() {
    this.sessionService.changeTitle();
  }

  private setPropertyInfoInSession(result, userInfo) {
    const userLanguageCode =
      userInfo != null && userInfo.languageCode != ''
        ? userInfo.languageCode
        : result.languageCode;
    const PropertyValues =
      'MaximumDecimalPlaces=' +
      result.maximumDecimalPlaces +
      '; PropDateFormat=' +
      result.propDateFormat +
      '; MincharSearch=' +
      result.minCharSearch +
      '; Language=' +
      result.languageCode +
      '; PropertyCode=' +
      result.propertyCode +
      '; SubPropertyCode=' +
      result.subPropertyCode +
      '; Currency=' +
      result.currencyCode +
      '; TenantId=' +
      result.tenantId +
      '; userName=' +
      userInfo.userName +
      '; UserId=' +
      userInfo.userId +
      '; RoleId=' +
      result.roleId +
      '; PropertyId=' +
      result.propertyId +
      '; SubPropertyId=' +
      result.subPropertyId +
      '; PlatformTenantId=' +
      result.platformTenantId +
      '; PropertyDate=' +
      result.propertyDate +
      '; TimeZone=' +
      result.timeZone +
      '; PropertyName=' +
      result.propertyName +
      '; UserLanguage=' +
      userLanguageCode +
      '; ProductId=' +
      result.productId +
      '; PlatformPropertyId=' +
      result.platformPropertyId +
      '; PropertyEmail=' +
      result['email'] +
      '; UserEmail=' +
      userInfo.email +
      '; ArrivalTime=' +
      result.arrivalTime +
      '; CheckOutTime=' +
      result.checkOutTime +
      '; PropTimeFormat=' +
      result.propTimeFormat +
      '; LanguageId='+
      result.languageId + 
      '; PlatFormExtendedSearchRequired='+ 
      result.platFormExtendedSearchRequired
      ';';
    sessionStorage.setItem(PROPERTY_INFO, PropertyValues);
    sessionStorage.setItem(PROPERTY_DATE, result.propertyDate);
  }

  private updateSessionIdInLocalStorage(sessionId: string) {
    const userSession = this.sessionService.GetUserSessionsInfo();
    userSession.userProperties.find(x => x.propertyId === this.getPropertyId()).sessionId = sessionId;
    this.sessionService.SetUserSessionsInfo(userSession);
  }

  private getPropertyId() {
    return Number(this.localize.GetsessionStorageValue('propertyInfo', 'PropertyId'));
  }


  async setProductApplicationType(){
    let productApplicationType : ProductApplicationType = await this.PropertySettingService.getGetProductApplicationType();
    if(productApplicationType === undefined && productApplicationType != null){
      productApplicationType = ProductApplicationType.SinglePms_Snc;
    }
      sessionStorage.setItem('ProductApplicationType',productApplicationType.toString());

  }


  async SetMachinePrinterConfigForMachine(machineId: number) {
    const result = await this._machinePrinterConfigDataService.getMachinePrinterConfigurationByMachineId(machineId);
    if (result) {
      this.localize.SetMachinePrinterConfig(result);
    }
  }
  private GetMachinePrinterConfig(machineId: number): Promise<mpc.MachinePrinterConfiguration[]> {
    return this._machinePrinterConfigDataService.getMachinePrinterConfigurationByMachineId(machineId);
  }

  SetNoOfDecimalDigits(propertyConfig: any) {
    var noOfDecimalDigits: string = '2';
    if (propertyConfig != null && propertyConfig.configValue != undefined && propertyConfig.configValue[NO_OF_DECIMAL_DIGITS] != undefined) {
      noOfDecimalDigits = propertyConfig.configValue[NO_OF_DECIMAL_DIGITS];
    }
    sessionStorage.setItem('noOfDecimalDigits', noOfDecimalDigits);
  }

  async GetWebCommunicationProxyVersion(){
    const WebProxyCheck = await this.PropertySettingService.GetWebCommunicationProxyVersion();
    this.propertyInfo.SetWebCommunicationProxyVersionCheck(WebProxyCheck)
  }

    Checkfordeployment(){
     
      let PPV = this.localize.getLocalCookie('userProductVersion_Previous');
      let CPV =  this.storageservice.getstoragekey('userProductVersion');
      if(PPV && (PPV!=CPV)){
       
        this.localize.setLocalCookie('userProductVersion_Previous',CPV);
        window.location.reload();
      }else if(!PPV){
       
        this.localize.setLocalCookie('userProductVersion_Previous',CPV);
      }
    }

    async readUICacheJsonData(): Promise<UICache>{
      return await this.PropertySettingService.getUICacheJSON();
    }
}
