import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, Input, OnChanges } from '@angular/core';
import { Subscription, Subject, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { RetailFeatureFlagInformationService } from '../service/retail.feature.flag.information.service';
import { ARPostingBusinessService } from '../business/AR-Posting.business.service';
import { AlertType} from '../shared.modal';
import { RetailUtilities } from '../utilities/retail-utilities';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CommonVariablesService } from '../service/common-variables.service';
import { ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ArAccount, ArAcctSearchType, ARSenderCode, LookUpInput } from '../service/payment/payment-business.model';
import { PaymentMethods } from '../business/shared.modals';

@Component({
  selector: 'app-account-lookup',
  templateUrl: './account-lookup.component.html',
  styleUrls: ['./account-lookup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountLookupComponent implements OnInit, OnDestroy, OnChanges {
  subscription: Subscription;
  searchTextChanged = new Subject<string>();
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  ARAccountList: ArAccount[] = [];
  selectedArAccountName: any;
  message: any;
  AccountSearchForm: UntypedFormGroup;
  captions: any;
  searchTriggered: boolean = false;
  searchBoxPlaceHolder: string = "";
  searchTypeList: any[] = [];
  placeHolderTxt;
  filteredAccts: any[] = [];
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;
  isStay:boolean=false;
  separatorKeysCodes = [ENTER];
  @ViewChild('acctSearch') acctSearch: ElementRef;
  @ViewChild('trigger') trigger: MatAutocompleteTrigger;
  @Output() AcctSelected = new EventEmitter<any>();
  @Input() lookupInput: LookUpInput;
  @Input() isView: boolean;
  @Input() reset: boolean = false;
  @Input() automation;
  selectedAccountList: any[] = [];
  selectedArAccount: ArAccount;
  floatLabelNever: string;
  @Input() throttletime = 3000;


  constructor(
    private _featureFlagInfo: RetailFeatureFlagInformationService,
    private _arPostingBusiness: ARPostingBusinessService,
    private _utilities: RetailUtilities,
    private _localization: RetailLocalization,
    private _ss: CommonVariablesService,
    private fb: UntypedFormBuilder
  ) {
    this.floatLabelNever = this._localization.setFloatLabelNever;
    this.InitDefaultFormValues();
    this.AccountSearchForm.controls.acctSearch.valueChanges.subscribe(x => this.searchEvent(x));
  }

  ngOnChanges() {
    this.PrefillARAccountValue();
    if (this.reset) {
      this.ResetSearchList();
      if(this.trigger){
        this.trigger.closePanel();
      }
    }
  }

  ngOnDestroy(): void {
    this._ss.arAccountName = this._ss.arAccountNumber = "";
  }

  ngOnInit() {
    this.initializeSearchSubscription();
  }

  initializeSearchSubscription() {
    this.subscription = this.searchTextChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      takeUntil(this.destroyed$)).subscribe(searchKey => {
        if (searchKey && searchKey.length >= 3 && this.AccountSearchForm.controls.searchType.value == ArAcctSearchType.SearchByName)
          this.searchArAccount(searchKey);
          else {
            this.ARAccountList = [];
            this.selectedAccountList = [];
          }
      });
  }

  InitDefaultFormValues() {
    this.captions = this._localization.captions.shop;
    this.isStay= this._featureFlagInfo.SenderCode.toUpperCase().trim()===ARSenderCode.STAY;
    this.searchBoxPlaceHolder =  this.isStay?this.captions.SearchARAcctNumberName:this.captions.SearchARAcctName;
    this.placeHolderTxt = {
      [ArAcctSearchType.SearchByName]: this.captions.SearchARAcctName,
      [ArAcctSearchType.SearchByNumber]: this.captions.SearchARAcctNumber
    };
    this.searchTypeList = [
      {
        id: ArAcctSearchType.SearchByName,
        name: this.captions.ARAcctName
      },
      {
        id: ArAcctSearchType.SearchByNumber,
        name: this.captions.ARAcctNumber
      }
    ];
    this.AccountSearchForm = this.fb.group({
      searchType: ArAcctSearchType.SearchByName,
      acctSearch: "",
      accountSelection: ""
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add
    if ((value || '').trim()) {
      this.selectedAccountList.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.AccountSearchForm.controls.acctSearch.setValue(null);
  }

  remove(obj: any): void {
    const index = this.selectedAccountList.indexOf(obj);
    if (index >= 0) {
      this.selectedAccountList.splice(index, 1);
      this.ResetSearchList();
    }
  }

  searchEvent(arg) {
    this.searchTextChanged.next(arg);
  }

  selectSeachType(type) {
    if (!this.isView) {
      this.searchBoxPlaceHolder = this.placeHolderTxt[type.id];
      this.ResetSearchList();
    }
  }

  async searchArAccount(searchKey) {
    const searchType: ArAcctSearchType = this.AccountSearchForm.controls.searchType.value;
    if (searchKey && (searchType == ArAcctSearchType.SearchByNumber || searchKey.length >= 3) && this.selectedAccountList.length == 0) {
      let tenderId = this.lookupInput?.selectedPaymentMethod ? this.lookupInput?.selectedPaymentMethod : PaymentMethods.ARAcctPost; 
      let response = this._arPostingBusiness.AccountLookUp(tenderId, searchKey, searchType, this.lookupInput?.profitCenter??0);
      response.then((res) => {
        this.searchTriggered = true;
        if (res.successStatus && res.result && res.result.length > 0) {
          this.ARAccountList = res.result;
          if (this.trigger)
            this.trigger.openPanel();
        } else {
          this._utilities.showAlert(this._localization.captions.common.NoRecordFound, AlertType.Info);
          this.ResetSearchList();
        }
      }).catch((err) => {
        this.ResetSearchList();
        this._arPostingBusiness.HandleARPostError(err.error);
      });
    }
    if (!searchKey || (searchType == ArAcctSearchType.SearchByName && searchKey.length < 3))
      this.ResetSearchList();
  }

  ResetSearchList() {
    this.AcctSelected.emit(null);
    this.AccountSearchForm.controls.accountSelection.patchValue("");
    this.AccountSearchForm.controls.acctSearch.patchValue("");
    this.ARAccountList = [];
    this.selectedAccountList = [];
    this.selectedArAccount = null;
    if (this.acctSearch && this.acctSearch.nativeElement) {
      this.acctSearch.nativeElement.value = "";
    }
  }

  selectAccount(acct) {
    let arAcct = this.ARAccountList.find(x => x.accountName == acct.option.value);
    if (acct && arAcct) {
      this.AcctSelected.emit(arAcct);
      this.selectedArAccount = arAcct;
      this.selectedAccountList.push(acct.option.value);
    }
  }

  PrefillARAccountValue() {
    if (this.lookupInput && this.lookupInput.arAccountNumber && this.lookupInput.arAccountName) {
      this.selectedAccountList = [];
      this.selectedArAccount = {
        accountName: this.lookupInput.arAccountName,
        accountNumber: this.lookupInput.arAccountNumber,
        isEmittedForPrefill: true
      };
      this.selectedAccountList.push(this.lookupInput.arAccountName);
      this.AcctSelected.emit(this.selectedArAccount);
    } else {
      this.selectedAccountList = [];
      this.selectedArAccount = null;
    }
  }
}
