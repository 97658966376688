import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import * as _ from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { PostTypeLinking, RevenuePostingPrimaryType, RevenuePostTypes, SubPropertyModel, MappingScreen, PostingType, ExternalPostTypeMapping, OperaMapping, PaymentMappings } from '../../retail.modals';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { ButtonType, FeatureValue, GridType } from '../../shared/globalsContant';
import { AlertAction, AlertType } from '../../shared/shared.modal';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { PostTypeBusiness } from './post-type-mapping.business';
import { PropertyInfo } from '../../shared/business/shared.modals';
import { FeatureName, PMS_SYSTEM_NAMES, RetailConstants, RetailFeatureFlagInformationService } from '../../shared/service/retail.feature.flag.information.service';
import { PropertyFeature } from '../../shared/business/property-features.model';
import { PropertyFeaturesConfigurationService } from '../../sytem-config/payment-features-config/property-feature-config.service';
import { PMSCommunicationReceiverGateway, PMSIntegrationHostConfiguration } from 'src/app/common/components/pms-integration-setup/pms-integration-setup.model';
import { CommonApiRoutes } from 'src/app/common/common-route';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { FinancialBinType } from '../../shared/business/FinancialBin-business'; 



@Component({
  selector: 'app-post-type-mapping',
  templateUrl: './post-type-mapping.component.html',
  styleUrls: ['./post-type-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class PostTypeMappingComponent implements OnInit, OnDestroy {
  useRetailInterface: boolean;
  captions: any;
  IsViewOnly = false;
  tableoptions: any[];
  propConfig: any = [];
  dataSource = [];
  isValid = false;
  isDisabled = true;
  postTypeValues = [];
  selectedPostTypeValue = RevenuePostTypes.Discount; // Default selected value on load as per alphabet order
  searchText: string;
  searchPlaceholderValue: any;
  maxInputLength = 100;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  tableHeader: { title: any; jsonkey: string; sortable: boolean; sortcolumn?: string; sortcolumndatatype?: string; highlightbold?: boolean; type?: string; alignType?: string }[];
  commonCaptions: any;
  outlets: SubPropertyModel[];
  category: RevenuePostingPrimaryType[];
  postTypeMappingsData: PostTypeLinking[] = [];
  previousSelectedData: any;
  updatedSelectedData: any;
  selectedColumnName: any;
  OriginalPostTypeMappingsData: PostTypeLinking[];
  functionalities: { [key: string]: boolean } = {};
  showOutletDetails = true;
  defaultOutlet: number;
  floatLabel: string;
  floatLabelNever: string;
  pmsPropCode: string;
  propertyList: PropertyInfo[] = [];
  propertyDropDownList: PropertyInfo[] = [];
  selectedProperty: PropertyInfo = { PropertyName: '', PropCode: '' };
  currentSelectedPostType;
  isInternal: boolean = false;
  @Output() DataEmitter = new EventEmitter();
  @Input() intgrationsetupValues: any;
  set myValue(value) {
    this.isInternal = value?.toggleVal;
  }

  get MultiPropertyFeatureConfig() {
    let featureConfig: PropertyFeature;
    try {
      let propertyFeatureStr: any = sessionStorage.getItem("PropertyFeatureConfigurations");
      if (propertyFeatureStr) {
        let propertyFeatures: PropertyFeature[] = JSON.parse(propertyFeatureStr);
        featureConfig = propertyFeatures.find(p => p.featureName == FeatureName.MultiPMSRoomGroupCharge);
      }
    }
    catch (err) {
      console.log("Error Occurred while getting Multi Properties Feature value")
    }
    return featureConfig;
  }

  get IsMulitPropertyFeatureEnabled() {
    return this.MultiPropertyFeatureConfig?.isActive || (this.propConfig?.EnableMultiplePMSHost && (this.propConfig?.EnableMultiplePMSHost?.toLowerCase() ?? 'false') == 'true') 
  }

  get IsExternalPosting() {
    return this.intgrationsetupValues?.postTypeMappingJson?.PaymentPostTypeMapping ? true : false;
  }
  
  constructor(private dialog: MatDialog,
    private router: Router,
    private _http: HttpServiceCall,
    private localization: RetailLocalization,
    private postTypeBusiness: PostTypeBusiness,
    private _rs: RetailSetupService,
    private utilities: RetailUtilities,
    private func: RetailFunctionalityBusiness,
    public propertyInfo: RetailPropertyInformation,
    private propertyFeatureservice: PropertyFeaturesConfigurationService,
    private _featureConfig: RetailFeatureFlagInformationService,) {
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
    this.propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
  }
  ngOnInit() {
    this.InitializeComponent();
  }

  async InitializeComponent() {
    this.captions = this.localization.captions.retailsetup;
    this.commonCaptions = this.localization.captions.common;
    this.postTypeValues = [
      { id: 2, description: this.captions.Discount },
      { id: 6, description: this.captions.Gratuity },
      { id: 3, description: this.captions.PaymentMethod },
      { id: 5, description: this.captions.ServiceCharge },
      { id: 1, description: this.captions.Settlement },
      { id: 4, description: this.captions.taxes },
      { id: 8, description: this.captions.Surcharges }
    ];
    await this.GetMultiPropertyConfigs();
    this.searchPlaceholderValue = this.GetSearchPlaceHolderByPostType(RevenuePostTypes.Discount);
    this.defaultOutlet = this.propertyInfo.GetDefaultOutlet();
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
      this.showOutletDetails = this.functionalities.ShowOutletDetailsInPostTypeMapping ? this.functionalities.ShowOutletDetailsInPostTypeMapping : false;
      if (this.propConfig?.PMSSystem?.toLowerCase() === PMS_SYSTEM_NAMES.OPERA_HTNG.toLowerCase() 
      || this.propConfig?.PMSSystem?.toLowerCase() === PMS_SYSTEM_NAMES.OPERA_REST.toLowerCase() 
      || this.IsExternalPosting) {
        //Remove Surcharge
        this.postTypeValues = this.postTypeValues.filter(item => item.description !== this.captions.Surcharges);
      }

      if (this.IsExternalPosting) {
        this.getDataForExternalPTM()
      }
      else {
        this.getData();
      }

    });
    this.IsViewOnly = this._rs.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.PostTypeMapping).view;
  }

  async getData() {
    let [outlets, category, postTypeMappings] = await Promise.all([this.postTypeBusiness.GetActiveOutlets(),
    this.postTypeBusiness.GetPrimaryTypeMapping(RevenuePostTypes.Discount, MappingScreen.PostTypeMapping),
    (this.IsMulitPropertyFeatureEnabled ? this.postTypeBusiness.GetMultiPropertyPostTypeMapping(this.pmsPropCode) : this.postTypeBusiness.GetPostTypeMapping())]);

    this.outlets = outlets;
    this.category = category;
    if (!this.showOutletDetails && this.defaultOutlet === 0) {
      this.defaultOutlet = await this.func.getDefaultOutlet();
      if (this.defaultOutlet > 0) {
        this.propertyInfo.SetDefaultOutlet(this.defaultOutlet);
      }
    }
    this.OriginalPostTypeMappingsData = this.formTableDataTemp(postTypeMappings);
    this.postTypeMappingsData = this.formTableDataTemp(postTypeMappings);
    this.BindToGrid();
  }

  async getDataForExternalPTM() {

    let postTypeMappingss;
    try {

      if (this.intgrationsetupValues?.postTypeMappingJson?.PaymentPostTypeMapping) {
        postTypeMappingss = JSON.parse(this.intgrationsetupValues?.postTypeMappingJson?.PaymentPostTypeMapping);
      }

    } catch (error) {

      console.error('Error parsing PaymentPostTypeMapping JSON:', error);
      this.getData();
      return;
    }

    if (!postTypeMappingss) {
      this.getData();
      return;
    }

    let [outlets, category] = await Promise.all([this.postTypeBusiness.GetActiveOutlets(),
    this.postTypeBusiness.GetPrimaryTypeMapping(RevenuePostTypes.Discount, MappingScreen.PostTypeMapping)]);
    try {
      let postTypeMappings: PostTypeLinking[] = [];
      this.outlets = outlets;
      this.category = category;
      postTypeMappingss.forEach(x => {
        let outletId = x.OutletId;
        x.Mappings?.forEach(element => {
          let postTypeLinking: PostTypeLinking = {
            id: 0,
            outletId: outletId,
            linkingId: element.POS_FinancialBinId,
            linkType: this.GetRevenuePostTypeByFinancialBinType(element.POS_FinancialBinType),
            postCode: element.OPERA_TransactionCode ? element.OPERA_TransactionCode.toString() : '_',
            isUpdated: false,
            postingType: PostingType.Default,
            propCode: '0'

          }
          postTypeMappings.push(postTypeLinking);
        });
        x.PaymentMappings?.forEach(element => {
          let postTypeLinking: PostTypeLinking = {
            id: 0,
            outletId: outletId,
            linkingId: element.LinkingId,
            linkType: element.POS_CC_IssuerType ? RevenuePostTypes.Card : RevenuePostTypes.PaymentMethod,
            postCode: element.OPERA_PostingMaster ? element.OPERA_PostingMaster : '_',
            isUpdated: false,
            postingType: PostingType.Default,
            propCode: '0'
          }
          postTypeMappings.push(postTypeLinking);
        });
      })

      if (!this.showOutletDetails && this.defaultOutlet === 0) {
        this.defaultOutlet = await this.func.getDefaultOutlet();
        if (this.defaultOutlet > 0) {
          this.propertyInfo.SetDefaultOutlet(this.defaultOutlet);
        }
      }
      this.OriginalPostTypeMappingsData = this.formTableDataTemp(postTypeMappings);
      this.postTypeMappingsData = this.formTableDataTemp(postTypeMappings);
      this.BindToGrid();
    }
    catch (error) {
      console.error('Error in getDataForExternalPTM:', error);
    }

  }
  GetFinancialTypeByRevenuePostType(revenuePostType){
    switch (revenuePostType) {
      case RevenuePostTypes.Settlement:
        return FinancialBinType.Sales;
      case RevenuePostTypes.Discount:
        return FinancialBinType.Discount;
      case RevenuePostTypes.Tax:
        return FinancialBinType.Tax;
      case RevenuePostTypes.ServiceCharge:
        return FinancialBinType.ServiceCharge;
      case RevenuePostTypes.Gratuity:
        return FinancialBinType.Gratuity;
      default:
        return null;
    }   
  }
  GetRevenuePostTypeByFinancialBinType(financialBinType){
    switch (financialBinType) {
      case FinancialBinType.Sales:
        return RevenuePostTypes.Settlement;
      case FinancialBinType.Discount:
        return RevenuePostTypes.Discount;
      case FinancialBinType.Tax:
        return RevenuePostTypes.Tax;
      case FinancialBinType.Gratuity:
        return RevenuePostTypes.Gratuity;
      case FinancialBinType.ServiceCharge:
        return RevenuePostTypes.ServiceCharge;
      default:
        return null;
    }
  }
  formTableDataTemp(postTypemappingsData: PostTypeLinking[] = []) {
    const OriginalTableData: PostTypeLinking[] = _.cloneDeep(postTypemappingsData);
    this.category.forEach(cat => {
      this.outlets.forEach(out => {
        if (Array.isArray(postTypemappingsData)) {
          let isPostTypeAvailable;
          if (this.selectedPostTypeValue === RevenuePostTypes.PaymentMethod) {
            isPostTypeAvailable = postTypemappingsData.find(o => o.outletId === out.subPropertyID && o.linkingId === cat.id && (o.linkType == cat.linkType));
          } else {
            isPostTypeAvailable = postTypemappingsData.find(o => o.outletId === out.subPropertyID && o.linkingId === cat.id && o.linkType === this.selectedPostTypeValue && o.postingType == PostingType.Default);
          }

          if (!isPostTypeAvailable) {
            OriginalTableData.push({
              id: 0,
              outletId: out.subPropertyID,
              postCode: '—',
              linkingId: cat.id,
              linkType: cat.linkType,
              propCode: this.pmsPropCode,
              postingType: PostingType.Default
            });
          }
        }
      });
    });
    return OriginalTableData;
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  BindToGrid() {
    const data = this.formTableData();
    this.tableoptions = [
      {
        TableHdrData: data[0],
        TablebodyData: data[1],
        pagination: false,
        sortable: true,
        CustomColumn: false,
        PlaceHoldertext: this.captions.Search,
        EnableActions: false,
        SelectRows: false,
        IsCommission: false,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: GridType.posttypemapping,
        Sortable: 'typecategory',
        TableId: GridType.posttypemapping,
        disableDelete: false,
        customHeader: false,
        pageTitle: 'posttypemapping',
        ServiceId: 'posttypemapping',
        IsViewOnly: this.IsViewOnly,
        IsReadOnly: this.IsViewOnly,
        TableDraggable: false,
        bufferCount: 20

      }
    ];
  }

  formTableData() {
    this.tableHeader = [{ title: this.GetTextByPostType(), jsonkey: 'typecategory', sortable: true, highlightbold: true }];
    this.dataSource = [];
    if (this.showOutletDetails) {
      this.outlets.forEach(res => {
        this.tableHeader.push({
          title: res.subPropertyName,
          jsonkey: res.subPropertyName,
          sortcolumn: res.subPropertyName + 'number',
          sortcolumndatatype: 'number',
          type: 'showOnHover',
          sortable: true,
          alignType: 'right'
        });
        return res;
      });
    }
    else {
      const outlet = this.defaultOutlet > 0 ? this.outlets.find(x => x.subPropertyID === this.defaultOutlet) : this.outlets[0];
      this.tableHeader.push({
        title: this.commonCaptions.postType,
        jsonkey: outlet.subPropertyName,
        sortcolumn: outlet.subPropertyName + 'number',
        sortcolumndatatype: 'number',
        type: 'showOnHover',
        sortable: true
      });
    }
    this.category.forEach(res => {
      let resultData: any = {};
      if (Array.isArray(this.postTypeMappingsData)) {
        this.postTypeMappingsData.forEach(data => {
          if (res.id === data.linkingId && res.linkType == data.linkType) {
            this.outlets.forEach(o => {
              if (this.selectedPostTypeValue === RevenuePostTypes.PaymentMethod && o.subPropertyID === data.outletId && (data.linkType === this.selectedPostTypeValue || data.linkType === RevenuePostTypes.Card)) {
                resultData.typecategory = res.name;
                resultData[o.subPropertyName] = data.postCode;
                resultData[o.subPropertyName + 'number'] = data.postCode && data.postCode !== '—' ? Number(data.postCode) : 0;
                resultData.id = data.id ? data.id : 0;
                resultData.category = res.name ? res.name : '';
                resultData.categoryId = data.linkingId ? data.linkingId : 0;
                resultData.outletId = data.outletId ? data.outletId : 0;
                resultData.linkType = data.linkType ? data.linkType : 0;
              }
              else if (o.subPropertyID === data.outletId && data.linkType === this.selectedPostTypeValue) {
                resultData.typecategory = res.name;
                if (!this.propertyInfo.IsVATEnabled) {
                  resultData[o.subPropertyName] = this.selectedPostTypeValue === RevenuePostTypes.Tax && res.outletId != o.subPropertyID ? "" : data.postCode;
                }
                else {
                  resultData[o.subPropertyName] = data.postCode;
                }
                resultData[o.subPropertyName + 'number'] = data.postCode && data.postCode !== '—' ? Number(data.postCode) : 0;
                resultData.id = data.id ? data.id : 0;
                resultData.category = res.name ? res.name : '';
                resultData.categoryId = data.linkingId ? data.linkingId : 0;
                resultData.outletId = data.outletId ? data.outletId : 0;
                resultData.linkType = data.linkType ? data.linkType : 0;
              }
            });
          }
        });
      }
      if (Object.keys(resultData).length) this.dataSource.push(resultData);
      resultData = {};
    });
    return [this.tableHeader, this.dataSource];
  }

  rowDataClicked(event) {
    if (this.intgrationsetupValues?.toggleVal === 'External') {
      this.openDialog(this.captions.SelectRevenuePostCode, 'EPTM', event);
    }
    else {
      this.openDialog(this.captions.externalPMSDetails, "PTM", event);
    }
    const outlet = this.outlets.filter(o => o.subPropertyName === event[1]);
    const oldData = this.postTypeMappingsData.filter(res => res.postCode === event[0][event[1]] && res.linkingId === event[0].categoryId && res.outletId === outlet[0].subPropertyID && res.linkType === event[0].linkType);
    this.previousSelectedData = oldData && oldData.length ? oldData : [event[0]];
    this.selectedColumnName = event[1];
  }

  resetValue() {
    this.searchText = '';
  }

  searchValue(event) {
    this.searchText = event.target.value;
  }

  openDialog(title, templateName, data) {
    const dialogRef = this.dialog.open(RetailPopupComponent, {
      width: '45%',
      height: '85%',
      disableClose: true,
      data: { headername: title, closebool: true, templatename: templateName, datarecord: data, LinkType: this.selectedPostTypeValue, isMulitPropertyFeatureEnabled: this.IsMulitPropertyFeatureEnabled, pmsPropCode: this.pmsPropCode, selectedPostType: this.selectedPostTypeValue },
      hasBackdrop: true,
      panelClass: ''
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if (result && templateName == 'PTM') {
        this.updatedSelectedData = result;
        const filteredData = [];
        let postTypeIndex = null;
        if (!result.isAllCategory) {
          if (this.selectedPostTypeValue === RevenuePostTypes.PaymentMethod) {
            postTypeIndex = this.postTypeMappingsData.findIndex(m => m.linkType == this.previousSelectedData[0].linkType && m.linkingId === this.previousSelectedData[0].linkingId && m.outletId === this.previousSelectedData[0].outletId);
          }
          else {
            postTypeIndex = this.postTypeMappingsData.findIndex(m => m.linkType === this.selectedPostTypeValue && m.linkingId === this.previousSelectedData[0].linkingId && m.outletId === this.previousSelectedData[0].outletId);
          }
          if (postTypeIndex !== -1) {
            this.postTypeMappingsData[postTypeIndex].postCode = result.postCode ? result.postCode : '—';
            this.postTypeMappingsData[postTypeIndex].isUpdated = true;
            this.isDisabled = false;
            this.isValid = true;
          }
        }
        else {
          if (this.selectedPostTypeValue == RevenuePostTypes.PaymentMethod) {
            for (let i = 0; i < this.postTypeMappingsData.length; i++) {
              if ((this.postTypeMappingsData[i].linkType == RevenuePostTypes.PaymentMethod || this.postTypeMappingsData[i].linkType == RevenuePostTypes.Card) && this.postTypeMappingsData[i].outletId == this.previousSelectedData[0].outletId) {
                this.postTypeMappingsData[i].postCode = result.postCode ? result.postCode : '—';
                this.postTypeMappingsData[i].isUpdated = true;
              }
            }
          }
          else {
            for (let i = 0; i < this.postTypeMappingsData.length; i++) {
              if (this.postTypeMappingsData[i].linkType == this.selectedPostTypeValue && this.postTypeMappingsData[i].outletId == this.previousSelectedData[0].outletId) {
                this.postTypeMappingsData[i].postCode = result.postCode ? result.postCode : '—';
                this.postTypeMappingsData[i].isUpdated = true;
              }
            }
          }
          this.isDisabled = false;
          this.isValid = true;
        }
      }
      else if (result && templateName == 'EPTM') {
        this.updatedSelectedData = result;
        const filteredData = [];
        let postTypeIndex = null;
        if (!result.value.applyForOutletsForTheSamePaymentMethod && !result.value.applyForAllPaymentMethodsForTheSameOutlet) {
          if (this.previousSelectedData[0] && this.previousSelectedData[0].linkType == RevenuePostTypes.PaymentMethod) {
            postTypeIndex = this.postTypeMappingsData.findIndex(m => (m.linkType == RevenuePostTypes.PaymentMethod) && m.linkingId === this.previousSelectedData[0].linkingId && m.outletId === this.previousSelectedData[0].outletId);
          }
          if (this.previousSelectedData[0] && this.previousSelectedData[0].linkType == RevenuePostTypes.Card) {
            postTypeIndex = this.postTypeMappingsData.findIndex(m => (m.linkType == RevenuePostTypes.Card) && m.linkingId === this.previousSelectedData[0].linkingId && m.outletId === this.previousSelectedData[0].outletId);
          }

          else {
            postTypeIndex = this.postTypeMappingsData.findIndex(m => m.linkType === this.selectedPostTypeValue && m.linkingId === this.previousSelectedData[0].linkingId && m.outletId === this.previousSelectedData[0].outletId);
          }
          if (postTypeIndex !== -1) {
            this.postTypeMappingsData[postTypeIndex].postCode = result.value.transactioncode ? result.value.transactioncode : '—';
            this.postTypeMappingsData[postTypeIndex].isUpdated = true;
          }
          this.isDisabled = false;
          this.isValid = true;
        }
        else {
          if (this.selectedPostTypeValue == RevenuePostTypes.PaymentMethod) {
            for (let i = 0; i < this.postTypeMappingsData.length; i++) {
              if ((this.postTypeMappingsData[i].linkType == this.selectedPostTypeValue || this.postTypeMappingsData[i].linkType == RevenuePostTypes.Card) && result.value.applyForOutletsForTheSamePaymentMethod && result.value.applyForAllPaymentMethodsForTheSameOutlet) {
                this.postTypeMappingsData[i].postCode = result.value.transactioncode ? result.value.transactioncode : '—';
                this.postTypeMappingsData[i].isUpdated = true;
              }
              else if ((this.postTypeMappingsData[i].linkType == this.selectedPostTypeValue || this.postTypeMappingsData[i].linkType == RevenuePostTypes.Card) && !result.value.applyForOutletsForTheSamePaymentMethod && result.value.applyForAllPaymentMethodsForTheSameOutlet && this.postTypeMappingsData[i].outletId == this.previousSelectedData[0].outletId) {
                this.postTypeMappingsData[i].postCode = result.value.transactioncode ? result.value.transactioncode : '—';
                this.postTypeMappingsData[i].isUpdated = true;
              }
              else if ((this.postTypeMappingsData[i].linkType == this.selectedPostTypeValue || this.postTypeMappingsData[i].linkType == RevenuePostTypes.Card) && result.value.applyForOutletsForTheSamePaymentMethod && !result.value.applyForAllPaymentMethodsForTheSameOutlet && this.postTypeMappingsData[i].linkingId === this.previousSelectedData[0].linkingId) {
                this.postTypeMappingsData[i].postCode = result.value.transactioncode ? result.value.transactioncode : '—';
                this.postTypeMappingsData[i].isUpdated = true;
              }
            }
          }
          else {
            for (let i = 0; i < this.postTypeMappingsData.length; i++) {
              if ((this.postTypeMappingsData[i].linkType == this.selectedPostTypeValue) && result.value.applyForOutletsForTheSamePaymentMethod && result.value.applyForAllPaymentMethodsForTheSameOutlet) {
                this.postTypeMappingsData[i].postCode = result.value.transactioncode ? result.value.transactioncode : '—';
                this.postTypeMappingsData[i].isUpdated = true;
              }
              else if ((this.postTypeMappingsData[i].linkType == this.selectedPostTypeValue) && !result.value.applyForOutletsForTheSamePaymentMethod && result.value.applyForAllPaymentMethodsForTheSameOutlet && this.postTypeMappingsData[i].outletId == this.previousSelectedData[0].outletId) {
                this.postTypeMappingsData[i].postCode = result.value.transactioncode ? result.value.transactioncode : '—';
                this.postTypeMappingsData[i].isUpdated = true;
              }
              else if ((this.postTypeMappingsData[i].linkType == this.selectedPostTypeValue) && result.value.applyForOutletsForTheSamePaymentMethod && !result.value.applyForAllPaymentMethodsForTheSameOutlet && this.postTypeMappingsData[i].linkingId === this.previousSelectedData[0].linkingId) {
                this.postTypeMappingsData[i].postCode = result.value.transactioncode ? result.value.transactioncode : '—';
                this.postTypeMappingsData[i].isUpdated = true;
              }
            }
          }
          this.isDisabled = false;
          this.isValid = true;
        }
      }

      console.log('PostTypeMappingComponent -> openDialog -> this.updatedPostTypeMappings', this.postTypeMappingsData);
      this.BindToGrid();
    });
  }

  postTypeChange(event) {
    this.utilities.ToggleLoader(true);
    this.searchPlaceholderValue = this.GetSearchPlaceHolderByPostType(event.value);
    this.postTypeBusiness.GetPrimaryTypeMapping(event.value, MappingScreen.PostTypeMapping).then(res => {
      this.category = res;
      this.postTypeMappingsData = this.formTableDataTemp(this.postTypeMappingsData);
      this.BindToGrid();
      this.currentSelectedPostType = event.value;
      this.utilities.ToggleLoader(false);
    });
  }

  GetTextByPostType(value: number = this.selectedPostTypeValue) {
    switch (value) {
      case RevenuePostTypes.Settlement:
      case RevenuePostTypes.Discount:
      case RevenuePostTypes.ServiceCharge:
      case RevenuePostTypes.Gratuity:
        return this.captions.Category;
      case RevenuePostTypes.PaymentMethod:
        return this.captions.PaymentMethod;
      case RevenuePostTypes.Tax:
        return this.captions.Tax;
      case RevenuePostTypes.Surcharge:
        return this.captions.CardTypes;
    }
  }

  GetSearchPlaceHolderByPostType(value: number = this.selectedPostTypeValue) {
    switch (value) {
      case RevenuePostTypes.Settlement:
      case RevenuePostTypes.Discount:
      case RevenuePostTypes.ServiceCharge:
      case RevenuePostTypes.Gratuity:
        return this.captions.SearchByCategories;
      case RevenuePostTypes.PaymentMethod:
        return this.captions.SearchByPaymentMethod;
      case RevenuePostTypes.Tax:
        return this.captions.SearchByTax;
      case RevenuePostTypes.Surcharge:
        return this.captions.CardTypes;
    }
  }

  async savePostTypeMappings(event) {
    if (this.intgrationsetupValues?.toggleVal === 'External') {
      let externalPMSPostTypeMappingList: ExternalPostTypeMapping[] = [];
      let rawMappingData = this.postTypeMappingsData.filter(x => x.linkType !== RevenuePostTypes.PaymentMethod &&
        x.linkType !== RevenuePostTypes.Surcharge && x.linkType !== RevenuePostTypes.Card);
      let rawPaymentMappingData = this.postTypeMappingsData.filter(x => x.linkType === RevenuePostTypes.PaymentMethod || x.linkType === RevenuePostTypes.Card)
      let mappingByOutlet = _.groupBy(rawMappingData, item => item.outletId);
      let [paymentMethod, cardIssuer] = await Promise.all([this.postTypeBusiness.GetPaymentMethods(), this.postTypeBusiness.GetCardIssuers()]);
      for (let outletId in mappingByOutlet) {

        if (mappingByOutlet.hasOwnProperty(outletId)) {
          let externalPMSPostTypeMapping: ExternalPostTypeMapping = {};
          let outletDetails = this.outlets.find(x => x.subPropertyID === Number(outletId))
          externalPMSPostTypeMapping.OutletId = Number(outletId);
          externalPMSPostTypeMapping.OutletName = outletDetails?.subPropertyName;
          externalPMSPostTypeMapping.ProfitCenter = outletDetails?.profitCenter;
          externalPMSPostTypeMapping.HostId = this.intgrationsetupValues?.postTypeMappingJson?.hostId != 0 ? this.intgrationsetupValues.postTypeMappingJson.hostId : 1;
          externalPMSPostTypeMapping.Mappings = [];
          externalPMSPostTypeMapping.PaymentMappings = [];
          let group = mappingByOutlet[outletId];

          group?.forEach(item => {
            if (Number(item.postCode)) {
              let operaMapping: OperaMapping = {};
              operaMapping.OPERA_TransactionCode = Number(item.postCode);
              operaMapping.POS_FinancialBinType = this.GetFinancialTypeByRevenuePostType(item.linkType);
              operaMapping.POS_FinancialBinId = item.linkingId;

              externalPMSPostTypeMapping.Mappings.push(operaMapping);
            }
          });
          let paymentMappingByOutlet = rawPaymentMappingData.filter(x => x.outletId === Number(outletId));
          let defaultPaymentMapping: PaymentMappings = {
            OPERA_PaymentId: 0,
            OPERA_PostingMaster: "",
            POS_TenderId: 3,
            LinkingId: 0
          }
          externalPMSPostTypeMapping.PaymentMappings.push(defaultPaymentMapping);
          paymentMappingByOutlet?.forEach(payment => {
            if (Number(payment.postCode)) {
              let tenderId = payment.linkType === RevenuePostTypes.Card ? 2 : paymentMethod.find(x => x.id === payment.linkingId)?.paymentTypeId;
              let paymentMapping: PaymentMappings = {};
              paymentMapping.OPERA_PaymentId = tenderId;
              paymentMapping.POS_TenderId = tenderId;
              paymentMapping.OPERA_PostingMaster = payment.postCode;
              paymentMapping.POS_CC_IssuerType = cardIssuer.find(x => x.id == payment.linkingId)?.issuerType ?? "";
              paymentMapping.LinkingId = payment.linkingId;
              externalPMSPostTypeMapping.PaymentMappings.push(paymentMapping);
            }
          })
          externalPMSPostTypeMappingList.push(externalPMSPostTypeMapping);
        }

      }

      this.DataEmitter.emit({
        data: externalPMSPostTypeMappingList,
        from: 1
      });
    }
    else {
      let updatedPostTypes = this.postTypeMappingsData.filter(s => s.isUpdated && s.postCode !== '—');
      if (!this.showOutletDetails) {
        const selectedPostTypes = updatedPostTypes;
        selectedPostTypes.forEach(uPost => {
          this.outlets.forEach(x => {
            const outletId = this.defaultOutlet > 0 ? this.defaultOutlet : this.outlets[0].subPropertyID
            if (x.subPropertyID !== outletId) {
              let result = this.postTypeMappingsData.find(post => post.outletId == x.subPropertyID && post.linkingId == uPost.linkingId && post.linkType == uPost.linkType);
              result.isUpdated = uPost.isUpdated;
              result.linkType = uPost.linkType;
              result.linkingId = uPost.linkingId;
              result.postCode = uPost.postCode;

              updatedPostTypes.push(result);

            }
          })
        })

      }
      let deletePostTypes = this.postTypeMappingsData.filter(s => s.isUpdated && s.id && s.postCode === '—').map(t => t.id);
      if (this.showOutletDetails && deletePostTypes.length > 0) {
        let deletedPostTypes: PostTypeLinking[] = [];
        const selectedPostTypeIdsForDelete = deletePostTypes;
        selectedPostTypeIdsForDelete.forEach(del => {
          deletedPostTypes.push(this.postTypeMappingsData.find(post => post.id == del));
        });

        deletedPostTypes.forEach(dPost => {
          this.outlets.forEach(x => {
            this.postTypeMappingsData.find(post => post.outletId == x.subPropertyID && post.linkingId == dPost.linkingId && post.linkType == dPost.linkType).isUpdated = dPost.isUpdated;
            this.postTypeMappingsData.find(post => post.outletId == x.subPropertyID && post.linkingId == dPost.linkingId && post.linkType == dPost.linkType).postCode = dPost.postCode;


          })
        })
      }

      if (deletePostTypes && deletePostTypes.length) {
        await this.postTypeBusiness.DeletePostTypeMapping(deletePostTypes);
      }
      if (updatedPostTypes && updatedPostTypes.length) {
        await this.postTypeBusiness.CreatePostTypeMapping(updatedPostTypes);
      }
      let postTypeMappings = await (this.IsMulitPropertyFeatureEnabled ? this.postTypeBusiness.GetMultiPropertyPostTypeMapping(this.pmsPropCode) : this.postTypeBusiness.GetPostTypeMapping());
      this.OriginalPostTypeMappingsData = this.formTableDataTemp(postTypeMappings);
      this.postTypeMappingsData = this.formTableDataTemp(postTypeMappings);
      this.BindToGrid();

      this.isValid = false;
    }



  }

  cancelPostTypeMappings() {
    if (this.isValid) {
      this.utilities.showAlert(this.commonCaptions.saveChangesMessage, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.postTypeMappingsData = this.formTableDataTemp(this.OriginalPostTypeMappingsData);
          this.DataEmitter.emit({
            data: this.postTypeMappingsData,
            from: 0
          });
          this.BindToGrid();
          this.isValid = false;
        }
      });
    }
    else {
      this.dialog.closeAll()
    }

  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.isValid) {
      return this.confirm();
    }
    return true;
  }

  confirm(): Observable<boolean> {
    const confirmation = this.utilities.showAlert(this.commonCaptions.saveChangesMessage, AlertType.Warning, ButtonType.YesNo);
    return confirmation.afterClosed().pipe(map((a) => {
      if (a === AlertAction.YES) {
        return true;
      } else {
        this.isValid = true;
        return false;
      }
    }));
  }

  async GetMultiPropertyConfigs() {
    if (this.IsMulitPropertyFeatureEnabled) {
      this.currentSelectedPostType = this.postTypeValues?.find(f => f.id == this.selectedPostTypeValue) ?? RevenuePostTypes.Discount;

      if (this._featureConfig.SkipPMAgent) {
        //Rewamped PMS Integration Flow Multi Property 
        const pmsIntegrationHostConfiguration = await this.InvokeGetAllPMSIntegrationHostConfigurationsAPICall();
        this.propertyList = [];
        if (pmsIntegrationHostConfiguration?.length > 0) {
          pmsIntegrationHostConfiguration.map(c => {
            let configKeySplit = c.configurationKey;
            let propertyInd = Number(c?.hostId ?? 0)
            let configKey = c?.configurationKey?.toString();
            if (!this.propertyList[propertyInd]) {
              this.propertyList.push({ [configKey]: c.configurationValue })
            } else {
              this.propertyList[propertyInd][configKey] = c.configurationValue
            }
            if (this.propertyList && this.propertyList[propertyInd] && !this.propertyList[propertyInd]?.pMSIntegrationHostId) {
              this.propertyList[propertyInd].pMSIntegrationHostId = String(c?.hostId ?? 0);
            }
          });
          this.propertyDropDownList = _.cloneDeep(this.propertyList);
          this.SelectDefaultProperty();
        }
      }
      else {
        if (this.MultiPropertyFeatureConfig && this.MultiPropertyFeatureConfig?.id && this.MultiPropertyFeatureConfig?.moduleId) {
          let config = await this.propertyFeatureservice.getFeatureConfiguration(this.MultiPropertyFeatureConfig.id, this.MultiPropertyFeatureConfig.moduleId);
          console.log(config);
          this.propertyList = [];
          if (config?.length > 0) {
            config.map(c => {
              let configKeySplit = c.configurationKey.split(".");
              let propertyInd = Number(configKeySplit[1]) - 1, configKey = configKeySplit[2];
              if (!this.propertyList[propertyInd]) {
                this.propertyList.push({ [configKey]: c.configurationValue })
              } else {
                this.propertyList[propertyInd][configKey] = c.configurationValue
              }
              if (!this.propertyList[propertyInd]["multiPMSPropertyIndex"]) {
                this.propertyList[propertyInd].multiPMSPropertyIndex = `${FeatureValue.Property}.${propertyInd + 1}`
              }
            });
            this.propertyDropDownList = _.cloneDeep(this.propertyList);
            this.SelectDefaultProperty();
          }
        }
      }
      console.log(this.propertyList);
    }
  }

  SelectDefaultProperty() {
    if (!this.IsMulitPropertyFeatureEnabled) return
    if (this.propertyList.length > 0) {
      this.selectedProperty = this.propertyList[0];
      this.selectedProperty = { ...this.selectedProperty };
      this.selectProperty(this.selectedProperty.PropCode)
    }
  }

  selectProperty(propCode) {
    if (propCode) {
      this.selectedProperty = this.propertyList?.find(f => f.PropCode == propCode);
      this.pmsPropCode = propCode;
      this.ReloadPostTypesBasedOnPropertySelection();
    }
  }

  async ReloadPostTypesBasedOnPropertySelection() {
    this.utilities.ToggleLoader(true);
    let postTypeMappings = await (this.IsMulitPropertyFeatureEnabled ? this.postTypeBusiness.GetMultiPropertyPostTypeMapping(this.pmsPropCode) : this.postTypeBusiness.GetPostTypeMapping());

    this.OriginalPostTypeMappingsData = this.formTableDataTemp(postTypeMappings);
    this.postTypeMappingsData = this.formTableDataTemp(postTypeMappings);
    this.BindToGrid();

    this.utilities.ToggleLoader(false);
  }


  async InvokeGetAllPMSIntegrationHostConfigurationsAPICall(): Promise<PMSIntegrationHostConfiguration[]> {
    this.utilities.ToggleLoader(true);
    let response: PMSIntegrationHostConfiguration[] = [];
    const pmsCommunicationReceiverURI = this.GetPMSCommunicationReceiverURL(this.propConfig);
    if (pmsCommunicationReceiverURI) {
      this.utilities.ToggleLoader(true);
      let URI = pmsCommunicationReceiverURI + CommonApiRoutes.GetAllPMSIntegrationHostConfigurations;

      await this._http.InvokeApiAsync<any>(URI, HttpMethod.Get).then(t => {
        response = t.result;
      });
      this.utilities.ToggleLoader(false);
    }
    this.utilities.ToggleLoader(false);
    return response;
  }

  GetPMSCommunicationReceiverURL(propConfig) {
    var propConfigUpperCase = {}
    Object.entries(propConfig)?.forEach(([k, v]) => { propConfigUpperCase[k?.toUpperCase()] = v })
    propConfig = propConfigUpperCase

    let pmsCommunicationReceiverURI: String = '';
    if (propConfig && propConfig.PMSCOMMUNICATIONRECEIVER && propConfig?.PMSCOMMUNICATIONRECEIVERDOMAIN) {
      pmsCommunicationReceiverURI = `${propConfig.PMSCOMMUNICATIONRECEIVERDOMAIN}/${PMSCommunicationReceiverGateway}/${propConfig.PMSCOMMUNICATIONRECEIVER}`;
    }
    return pmsCommunicationReceiverURI;
  }


}
