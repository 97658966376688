import { Pipe, PipeTransform } from '@angular/core';
import { PartyPrepaymentState } from '../models/InputContact';
import { PartyState, TimeLineAvailablityShowTypes } from '../constants/commonenums';
import { PartyService } from "@app/shared/services/party.service";

@Pipe({
    name: 'sessionAvailability'
})
export class SessionAvailabilityPipe implements PipeTransform {
    constructor(public partyService: PartyService) { }
    transform(session, numerationType) {
        let count = 0;
        if (numerationType == 'numerator') {
            switch (this.partyService.TimeLineAvailablityShowType) {
                case TimeLineAvailablityShowTypes.AvailableCapacityByTotalCapacity: {
                    count = session ? (session.Available || session.available || 0) : 0;
                    break;
                }
                case TimeLineAvailablityShowTypes.BookedCapacityByAvailableCapacity: {
                    count = session ? (session.Booked || session.booked || 0) : 0;
                    break;
                }
                case TimeLineAvailablityShowTypes.BookedCapacityByTotalCapacity: {
                    count = session ? (session.Booked || session.booked || 0) : 0;
                    break;
                } default: {
                    count = 0;
                    break;
                }
            }
        } else if (numerationType == 'denominator') {
            switch (this.partyService.TimeLineAvailablityShowType) {
                case TimeLineAvailablityShowTypes.AvailableCapacityByTotalCapacity: {
                    count = session ? (session.Available || session.available || 0) + (session.Booked || session.booked || 0) : 0;
                    break;
                }
                case TimeLineAvailablityShowTypes.BookedCapacityByAvailableCapacity: {
                    count = session ? (session.Available || session.available || 0) : 0;
                    break;
                }
                case TimeLineAvailablityShowTypes.BookedCapacityByTotalCapacity: {
                    count = session ? ((session.Available || session.available || 0) + (session.Booked || session.booked || 0)) : 0
                    break;
                } default: {
                    count = 0;
                    break;
                }
            }
        }
        return count;
    }
}