import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ClassType, DayOfWeek, Status } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { SessionLocationMappingDTO, SessionStaffMappingDTO } from '@app/shared/models/RestaurantDTO';
import { SettingsService } from '@app/shared/services/settings.service';
import { ActivityCreateFunctions } from '@app/shared/utilities/activity-create-functions';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-session-mapping',
  templateUrl: './session-mapping.component.html',
  styleUrls: ['./session-mapping.component.scss']
})
export class SessionMappingComponent implements OnInit, OnDestroy {
  @Input() sessionData;
  @Input() locations;
  @Input() staffs;
  @Input() allocationList;
  @Input() calendarDetails;
  @Input() classType;
  @Input() sessionGroups;
  @Input() isTemplate;
  @Input() isModifySingleSession: boolean;
  @Input() locationGroup;
  @Output() sessionChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('sessionDetails', { static: true }) sessionDetailForm: DynamicFormComponent;
  sessionDetailsConfig: FieldConfig[]
  noInstructorsWarning : boolean = false;
  disableFormFields : boolean ;
  statusMaster = Status;
  cancelledHostName: string;
  hasBookedReservationWarning: boolean = false;
  subscriptions: Subscription = new Subscription();


  constructor(private ts: TranslateService, private activityCreateFunctions: ActivityCreateFunctions, public ss: SettingsService,) { }

  ngOnInit() {
    this.disableFormFields = (this.sessionData?.IsBooked || this.sessionData?.StatusCode == Status.Cancelled) || false;
    this.loadConfig();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.staffs) {
      if (this.sessionDetailsConfig?.length) {
        let staffIndex = this.sessionDetailsConfig.findIndex(x => x.name == 'Instructor')
        this.sessionDetailsConfig[staffIndex].options = this.staffs = _.orderBy(changes.staffs.currentValue, ['IsTemplate', 'value'], ['desc', 'asc']);
      }
    }

    if (changes.allocationList) {
      if (this.sessionDetailsConfig?.length) {
        let allocationIndex = this.sessionDetailsConfig.findIndex(x => x.name == 'AllocationTypeId')
        if(allocationIndex > -1) this.sessionDetailsConfig[allocationIndex].options = this.allocationList = changes.allocationList.currentValue;
      }
    }

    if (changes.locations) {
      if (this.sessionDetailsConfig?.length) {
        let locationIndex = this.sessionDetailsConfig.findIndex(x => x.name == 'location')
        if(locationIndex > -1) this.sessionDetailsConfig[locationIndex].options = this.locations = _.orderBy(changes.locations.currentValue, ['IsTemplate', 'value'], ['desc', 'asc']);
      }
    }

    if (changes.classType) {
      this.loadConfig();
    }
    if (changes?.sessionGroups?.currentValue?.length) {
      let index = this.sessionDetailsConfig.findIndex(config => config.name == 'SessionGroupId');
      if(index > -1) this.sessionDetailsConfig[index].options = changes.sessionGroups.currentValue.map(group => { return { id: group.Id, value: group.Name } })

      if(changes.sessionGroups.currentValue.find(x => x.Id == this.sessionDetailsConfig[index].value)){
        this.sessionDetailForm?.form?.controls?.SessionGroupId.setValue(this.sessionDetailsConfig[index].value);
      } else if(!changes.sessionGroups.currentValue.find(x => x.Id == this.sessionDetailForm?.value?.SessionGroupId )){
        this.sessionDetailForm?.form?.controls?.SessionGroupId.setValue(null);
      }
    }

    this.sessionDetailForm?.form?.updateValueAndValidity();
  }
  ngAfterViewInit() {
    this.formValueChanges();
    if(this.isModifySingleSession) this.mapSessionData(this.sessionDetailForm?.form?.getRawValue() || {});
  }
  formValueChanges() {
    this.subscriptions.add(this.sessionDetailForm.form.valueChanges.subscribe(data => {
      this.hasBookedReservationWarning = true;
      this.mapSessionData(this.sessionDetailForm.form.getRawValue());
    }))
    Object.keys(this.sessionDetailForm.form.controls).forEach((key, value) => {
      this.subscriptions.add(this.sessionDetailForm.form.controls[key].valueChanges.pipe(debounceTime(100),
        distinctUntilChanged()).subscribe(data => {
          if ((key == 'StartTime' || key == 'EndTime') && this.classType !== ClassType.Duration && !this.isTemplate) {
            let calendarDetails = this.isModifySingleSession ? this.calendarDetails : this.calendarDetails?.form?.getRawValue();
            if (calendarDetails) {
              let calendar = {
                StartDate: calendarDetails.StartDate,
                EndDate: calendarDetails.EndDate,
                StartTime: this.sessionDetailForm.value.StartTime,
                EndTime: this.sessionDetailForm.value.EndTime,
                DayOfWeek: this.sessionData.Dayofweek,
              }
              this.activityCreateFunctions.getAvailableInstructorsByRestaurant(calendar, (callbackData) => {
                let instructorIndex = this.sessionDetailsConfig.findIndex(type => type.name == 'Instructor');
                if (callbackData?.length) {
                  this.sessionDetailsConfig[instructorIndex].options = this.staffs = _.orderBy(callbackData, ['IsTemplate', 'value'], ['desc', 'asc']).map(options => { return { id: options.Id, value: options.Name, IsTemplate: options.IsTemplate } });
                  this.noInstructorsWarning = false;
                } else {
                  this.noInstructorsWarning = true;
                  this.sessionDetailsConfig[instructorIndex].options = [];
                }
              });
            }
          }
      }))
    });

    if (this.isModifySingleSession) {
      const startTime = this.sessionDetailForm.form.controls.StartTime.value;
      this.sessionDetailForm.form.controls.StartTime.setValue(null)
      this.sessionDetailForm.form.updateValueAndValidity();
      setTimeout(() => {
        this.sessionDetailForm.form.controls.StartTime.setValue(startTime, { emitEvent: true });
        this.sessionDetailForm.form.updateValueAndValidity();
      }, 100);
    }
    
  }
  mapSessionData(formValue) {
    this.sessionData.AllocationTypeId = (formValue.AllocationTypeId || formValue.AllocationTypeId == 0) ? formValue.AllocationTypeId : this.sessionGroups.find(group => group.Id == formValue?.SessionGroupId)?.SelectedAllocationId;
    this.sessionData.StartTime = this.classType == ClassType.Duration ? '00:00:00' : formValue.StartTime ? moment(formValue.StartTime, ["hh:mm A"]).format("HH:mm") + ":00" : '00:00:00';
    this.sessionData.EndTime = this.classType == ClassType.Duration ? '00:00:00' : formValue.EndTime ? moment(formValue.EndTime, ["hh:mm A"]).format("HH:mm")+ ":00" : '00:00:00';
    this.sessionData.SessionLocationMappings = this.getSessionLocationMappings(formValue.location) || [];
    this.sessionData.SessionStaffMappings = this.getSessionStaffMappings(formValue.Instructor) || [];
    this.sessionData.SessionGroupId =  formValue.SessionGroupId;
    this.sessionData.LocationGroupId = formValue.LocationGroupId > 0 ? formValue.LocationGroupId : null;
    let index = this.ss.activitySessionList.findIndex(session => session.tempId == this.sessionData.tempId);
    this.ss.activitySessionList[index] = this.sessionData;
    this.sessionChange.emit(this.isModifySingleSession ? this.sessionData : true);
  }
  getSessionStaffMappings(staffs: any[]) {
    if (staffs.length) {
      return staffs.map(staff => {
        return {
          ActivitySessionId: this.sessionData.ActivitySessionId,
          Id:0,
          ServerId: staff
        } as SessionStaffMappingDTO
      })
    }
  }
  getSessionLocationMappings(locations) {
    if (locations?.length) {
      return locations.map(loc => {
        return {
          ActivitySessionId: this.sessionData.ActivitySessionId,
          Id:0,
          StandaloneTableId: loc
        } as SessionLocationMappingDTO
      })
    }
  }

  loadConfig() {

    this.sessionDetailsConfig = [
      {
        type: 'select',
        name: 'AllocationTypeId',
        label: this.ts.instant('allocationReasonText'),
        options: this.allocationList?.length ? this.allocationList : [],
        value: this.sessionData?.AllocationTypeId || 0,
        appearance: false,
        validation:  this.isTemplate ? [] :  [Validators.required],
        isTranslate: true,
        disableErrorStateMatcher: true,
        class: 'w-100',
       
        disabled:this.disableFormFields || (!this.isModifySingleSession && this.sessionData.SessionDate),
      }, {
        type: 'select',
        name: 'SessionGroupId',
        label: this.ts.instant('SelectGroup'),
        options: this.sessionGroups?.map(group => { return { id: group.Id, value: group.Name } }) || [],
        value: this.sessionData?.SessionGroupId || null,
        appearance: false,
        validation: this.isTemplate ? [] :  [Validators.required],
        isTranslate: true,
        disableErrorStateMatcher: true,
        class: 'w-100',
       
        disabled:this.disableFormFields || this.isModifySingleSession,
      },
      {
        type: 'timepicker',
        name: 'StartTime',
        placeholder: this.ts.instant('starttime'),
        appearance: false,
        label: this.ts.instant('starttime'),
        class: "reservation-details__date w-100",
        validation: this.isTemplate ? [] :  [Validators.required],
        // Temporary fix for time format due to version not support with chrome and edge
        // value: this.sessionData.StartTime ? moment(this.sessionData.StartTime, ["HH:mm"]).format("hh:mm A") : null,
        value: this.sessionData.StartTime ? moment(this.sessionData.StartTime, ["HH:mm"]).format("HH:mm") : null,
        cellClick: this.setEndTime.bind(this),
        disabled:this.disableFormFields || (!this.isModifySingleSession && this.sessionData.SessionDate),
        // minDate:(data && data.StartDate) ? (moment(data.StartDate).format('YYYY MM DD') >= moment(new Date()).format('YYYY MM DD'))  ? new Date(data.StartDate) : new Date() : new Date(),
      },
      {
        type: 'timepicker',
        name: 'EndTime',
        placeholder: this.ts.instant('endtime'),
        appearance: false,
        label: this.ts.instant('endtime'),
        class: "reservation-details__date w-100",
        validation:  this.isTemplate ? [] :  [Validators.required],
        // value: this.sessionData.EndTime ? moment(this.sessionData.EndTime, ["HH:mm"]).format("hh:mm A") : null,
        value: this.sessionData.EndTime ? moment(this.sessionData.EndTime, ["HH:mm"]).format("HH:mm") : null,
        cellClick: this.setStartTime.bind(this),
        disabled:this.disableFormFields || (!this.isModifySingleSession && this.sessionData.SessionDate),
        // minDate:(data && data.StartDate) ? (moment(data.StartDate).format('YYYY MM DD') >= moment(new Date()).format('YYYY MM DD'))  ? new Date(data.StartDate) : new Date() : new Date(),
      },
      {
        type: 'select',
        name: 'location',
        label: this.ts.instant('location'),
        options: this.locations?.length ? this.locations : [],
        value: this.sessionData.SessionLocationMappings?.map(location => { return location.StandaloneTableId }) || [],
        appearance: false,
        validation:  this.isTemplate ? [] :  [Validators.required],
        isTranslate: true,
        selectMultipleOptions: true,
        disableErrorStateMatcher: true,
        class: 'w-100',
        selectAscendingSortOrder:true,
       
        disabled: (!this.isModifySingleSession && this.sessionData.SessionDate),
        orderFields: { fields: ['IsTemplate', 'value'], orders: ['desc', 'asc']}
      },
      {
        type: 'select',
        name: 'Instructor',
        label: this.ts.instant('Instructor'),
        options: this.staffs?.length ? this.staffs : [],
        value: this.sessionData?.SessionStaffMappings?.map(staff => { return staff.ServerId }) || [],
        appearance: false,
        validation:  this.isTemplate ? [] :  [Validators.required],
        isTranslate: true,
        selectMultipleOptions: true,
        disableErrorStateMatcher: true,
        class: 'w-100',
        selectAscendingSortOrder:true,
        allowTBAWithOptionSelection: this.classType == ClassType.Duration,
        disabled: (!this.isModifySingleSession && this.sessionData.SessionDate),
        orderFields: { fields: ['IsTemplate', 'value'], orders: ['desc', 'asc']}
      }
    ]
    if (this.classType == ClassType.Session && this.locationGroup?.length) {
      let options = this.locationGroup?.map(location => { return { id: location.LocationGroupId, value: location.Name } }) || []
      options.unshift({id:-1 , value: this.ts.instant('None')});
      this.sessionDetailsConfig.push({
        type: 'select',
        name: 'LocationGroupId',
        label: this.ts.instant('locationGroup'),
        options: options,
        value: this.sessionData?.LocationGroupId || -1,
        appearance: false,
        isTranslate: true,
        class: 'w-100',
        disabled: (!this.isModifySingleSession && this.sessionData.SessionDate),
      })
    }

    if(this.sessionData?.StatusCode == Status.Cancelled){
      this.cancelledHostName =this.activityCreateFunctions?.cs.settings.value.Hosts.find(user => user.Id == this.sessionData?.SessionCustomization.ActionBy)?.Name || ''
    }

    if (this.classType == ClassType.Session) {
      this.sessionDetailsConfig = this.sessionDetailsConfig.filter(config => config.name !== 'SessionGroupId');
    } else if (this.classType == ClassType.Class) {
      this.sessionDetailsConfig = this.sessionDetailsConfig.filter(config => config.name !== 'AllocationTypeId');
    } else if (this.classType == ClassType.Duration) {
      this.sessionDetailsConfig = this.sessionDetailsConfig.filter(config => config.name == 'location' || config.name == 'Instructor');
      let isTemplateIndex = this.sessionDetailsConfig[0].options.findIndex(option => option.IsTemplate);
      if(isTemplateIndex >= 0)
        this.sessionDetailsConfig[0].options.splice(isTemplateIndex, 1);
    }

  }

setStartTime(time){
    let data = this.sessionDetailForm.form.value;
     if(this.ss.getMilliSeconds(data.StartTime) > this.ss.getMilliSeconds(time)){
      this.sessionDetailForm?.form?.controls?.StartTime.setValue(time);
     }
   
  }

setEndTime(time){
  let data = this.sessionDetailForm.form.value;
    if(this.ss.getMilliSeconds(data.StartTime) < this.ss.getMilliSeconds(time)){
    this.sessionDetailForm?.form?.controls?.EndTime.setValue(time);
    }
  
}


  ngOnDestroy(): void {
    if(this.subscriptions){
      this.subscriptions.unsubscribe();
    }
  }

}


export function formValidation(classType, activitySessionList, sessionAvailableDaysOfWeek?, saveValidation?: boolean , translateService?: TranslateService) {

  let response;
  let InvalidSessionList = [];
  let InvalidSessionDays = [];
  let warningMessgaes = '';
  let requiredFieldValidation: boolean = !sessionAvailableDaysOfWeek ? true : false;
  let scrollTop: boolean = false;

  if (activitySessionList?.length) {

    if (!sessionAvailableDaysOfWeek) {
      sessionAvailableDaysOfWeek = [];
      for (let i = 0; i < Object.keys(DayOfWeek).length / 2; i++) {
        sessionAvailableDaysOfWeek.push({
          id: i,
          dayName: DayOfWeek[i],
        });
      }
    }
    let locationGroupWarning = false;
    if (classType == ClassType.Class) {
      InvalidSessionList = activitySessionList.filter(item => !item.EndTime || !item.StartTime || item.SessionLocationMappings.length == 0 || item.SessionStaffMappings.length == 0)
    } else if (classType == ClassType.Session) {
      InvalidSessionList = activitySessionList.filter(item => !item.EndTime || !item.StartTime || item.SessionLocationMappings.length == 0 || item.SessionStaffMappings.length == 0)
      if(!InvalidSessionList?.length && activitySessionList?.find(item => item.LocationGroupId)){
        InvalidSessionList  = activitySessionList.filter(item => !item.LocationGroupId || item.LocationGroupId < 0 );
        locationGroupWarning = InvalidSessionList?.length > 0;
      }
    } else if (classType == ClassType.Duration) {
      InvalidSessionList = activitySessionList.filter(item => item.SessionLocationMappings.length == 0 || item.SessionStaffMappings.length == 0)
    }
    InvalidSessionList = _.unionBy(InvalidSessionList, 'Dayofweek');
    let InvalidDays = [];
    InvalidSessionList.forEach((item, index) => {
      let day = sessionAvailableDaysOfWeek.find(day => day.id == item.Dayofweek)?.dayName
      if (day)
        InvalidSessionList.length - 1 !== index ? InvalidDays.push(translateService.instant(day) + ',') : InvalidDays.push(translateService.instant(day) + '.');
    })
    InvalidSessionDays = InvalidDays;
    if (InvalidSessionList?.length) {
      if (saveValidation) {
        scrollTop = true;
        requiredFieldValidation = true
      }
      warningMessgaes = locationGroupWarning ? 'locationGroupSelection' : 'sessionValidation';
    } else {
      warningMessgaes = '';
      requiredFieldValidation = false;
    }

    response ={
      result: InvalidSessionList?.length ? false : true
    }

  } else {
    if (saveValidation) {
      scrollTop = true;
    }
    warningMessgaes = 'sessionsEmptyWarningMessage';
    requiredFieldValidation = true;
    // return false;
  }

  return response = {
    result: response?.result,
    warningMessgaes,
    requiredFieldValidation,
    scrollTop,
    InvalidSessionDays
  }
} 
