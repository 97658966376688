import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import * as globalConst from '@app/shared/constants/globalConstants';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { ComponentTypes } from '@app/shared/constants/commonenums';
import { Utilities } from '@app/shared/utilities/utilities';
import { MatDialog } from '@angular/material/dialog';
import { NgxImageCompressService } from 'ngx-image-compress';
import { FloorplanService } from '@app/shared/services/floorplan.service';
import { ImageActionsComponent } from '../image-actions/image-actions.component';
import { PopupService } from '@app/popup-module/popup.service';
import { Subscription } from 'rxjs/Subscription';
import { FloorPlanImagesDto, InputImages } from '@app/shared/models/FloorPlanImageInputDto';
import { CacheService } from '@app/core/services/cache.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ConfirmationMessagesPopupComponent } from '@app/shared/components/confirmation-messages-popup/confirmation-messages-popup.component';

export interface ImgContents {
  name: string;
  imgURL: string;
}

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent extends Utilities implements OnInit, OnChanges, OnDestroy {
  availableFileExtensions = globalConst.fileExtensions;
  maxImageSize = globalConst.MaxFileSize;
  maxFileCountToUpload = 200;
  imageLibrary: ImgContents[] = [];
  uploadImages: string[] = [];
  displayImages: InputImages[] = [];
  @Input() isLayout = false;
  subscriptions: Subscription = new Subscription();
  cancelSubscription: Subscription;
  confirmSubscription: Subscription;
  isEditGallery = false;
  isDefaultImage: boolean;
  layoutImages: InputImages[] = [];
  customImages: InputImages[] = [];
  @Output() customObject:EventEmitter<any> = new EventEmitter();
  constructor(public domSanitizer: DomSanitizer, private ts: TranslateService, dialog: MatDialog,
              public fs: FloorplanService, private ps: PopupService,private cs: CacheService) {
    super(dialog);
   }

  ngOnInit(): void {
    this.subscriptions.add(this.cs.restaurantImages.subscribe((images: FloorPlanImagesDto) => {
      if (images) {
        this.layoutImages = images.LayoutImages;
        this.customImages = images.CustomImages;
        if (this.isLayout) {
          this.displayImages = this.layoutImages;
        } else {
          this.displayImages = this.customImages;
        }

        if (this.fs.floorPlan.ImageId && !this.isEditGallery) {
          this.displayImages.forEach((image) => {
            if (image.ImageId === this.fs.floorPlan.ImageId) {
              image.isImageSelected = true;
            } else {
              image.isImageSelected = false;
            }
          });
        }
      }
    }));
    this.subscriptions.add(this.fs.layoutChange$.subscribe((data) => {
      if (this.fs.floorPlan.ImageId && !this.isEditGallery) {
        this.displayImages.forEach((image) => {
          if (image.ImageId === this.fs.floorPlan.ImageId) {
            image.isImageSelected = true;
          } else {
             image.isImageSelected = false;
          }
        });
      } else {
        const layoutImage = this.displayImages.filter((img) => img.isImageSelected);
        if (layoutImage.length > 0) {
          layoutImage.forEach((img) => img.isImageSelected = false);
        }
      }
    }));
  }

  ngOnChanges() { }

  async selectFile(selectedFiles) {
    this.uploadImages = [];
    const selectedFilesCount = selectedFiles.target.files.length;
    const fileCount = selectedFilesCount + this.displayImages.length;
    const allAvailableFiles = selectedFiles.target.files;
    if (fileCount > this.maxFileCountToUpload) {
      this.openErrorPopup(`${this.ts.instant('imageUploadError')}`, this.ts.instant('imageUploadError'));
    } else {
      let duplicateFilecount = 0;
      let maxFileSizeExceededCount = 0;
      let duplicateFileNames = '';
      let maxSizeExceededFileNames = '';
      for (let i = 0; i < selectedFilesCount; i++) {
        // Need confirmation on this logic
        // if (this.imageLibrary.filter((img) => img.name === allAvailableFiles[i].name).length > 0) {
        //   duplicateFilecount++;
        //   duplicateFileNames = duplicateFileNames.length > 0 ?
        //               `${duplicateFileNames}, ${allAvailableFiles[i].name.split('.')[0]}` : allAvailableFiles[i].name.split('.')[0];
        // }
        if (allAvailableFiles[i].size > this.maxImageSize) {
          maxFileSizeExceededCount++;
          maxSizeExceededFileNames = maxSizeExceededFileNames.length > 0 ?
                      `${maxSizeExceededFileNames}, ${allAvailableFiles[i].name.split('.')[0]}` : allAvailableFiles[i].name.split('.')[0];
        }
      }
      // if (duplicateFilecount > 0) {
      //   const confirmationMessage = `${this.ts.instant('duplicateFileError')} "${duplicateFileNames}"
      // ${this.ts.instant('alreadyExists')}`;
      //   const dialogTitle = this.ts.instant('duplicateFileError');
      //   this.openErrorPopup(confirmationMessage, dialogTitle);
      // } else
      if (maxFileSizeExceededCount > 0) {
        this.openErrorPopup(`${this.ts.instant('fileSizeError')} "${maxSizeExceededFileNames}" ${this.ts.instant('file2MB')}`,
                              this.ts.instant('fileSizeError'));
        return;
      } else {
        for (let i = 0; i < selectedFilesCount; i++) {
          if (allAvailableFiles.length > 0 && allAvailableFiles[i].size <= this.maxImageSize) {
            const blobImg = window.URL.createObjectURL(allAvailableFiles[i]);
            await this.getBase64(allAvailableFiles[i]).then((file: string) => {
              const photo = file.replace(/^data:image.+;base64,/, '');
              this.uploadImages.push(photo);
            });
            // await this.imageCompress.compressFile(blobImg, -1, 75, 50).then(
            //   result => {
            //     const photo = result.replace(/^data:image.+;base64,/, '');
            //     this.uploadImages.push(photo);
            //   });
          }
        }
      }
    }
    this.fs.customObjectImageActionTriggered = true;
    this.uploadLayoutImages();
    const fileInput = document.getElementById('add-images') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(
        error
      );
      console.log("reader.onerror", reader.onerror);
    });
  }
  uploadLayoutImages() {
    if (this.isLayout) {
      this.fs.uploadLayoutImages(this.uploadImages);
    } else {
      this.fs.uploadCustomImages(this.uploadImages);
    }

  }
  openErrorPopup(confirmationMessage, dialogTitle) {
    const popUpMessage = [{
      confirmationMessage,
      dialogTitle,
      showAlert: true
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small',
            'action', popUpMessage, '');
    this.openCustomPopup(componentDetails, ComponentTypes.floorPlanImage, '450px', 'auto', true, '', 'Ok',
      'Cancel', true);
  }

  applyImage(image: InputImages) {
    if (!this.isEditGallery && this.isLayout) {
      this.displayImages.forEach((img) => {img.isImageSelected = false;})
      image.isImageSelected = true;
      image.ImageId == null ? this.isDefaultImage = true : this.isDefaultImage = false;
      this.fs.applySelectedImage$.next({image, isLayoutSelected: this.isLayout});
    } else if (!this.isEditGallery && !this.isLayout) {
      this.displayImages.forEach((img) => {
        if (img.ImageId == image.ImageId) {
          img.isImageSelected = !img.isImageSelected
        } else {
          img.isImageSelected = false;
        }
      })
      image.isImageSelected ? this.customObject.emit(image) : this.customObject.emit();
    } else {
      if (image.ImageId == null) {
        this.isDefaultImage = !this.isDefaultImage;
      } else {
        this.isDefaultImage = false;
        image.isImageSelected = !image.isImageSelected;
      }
      image.isImageSelected ? this.fs.selectedImageIds.push(image.ImageId) :
                              this.fs.selectedImageIds = this.fs.selectedImageIds.filter(imgID => imgID !== image.ImageId);
    }
  }

  viewAll() {
    const popupInput = this.displayImages;
    const componentInfo = Utilities.setComponentDetails(ImageActionsComponent, 'small', 'active', popupInput);
    const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.imageGallery,
                      '500px', '600px', false, this.ts.instant('layoutImages'), this.ts.instant('apply'), this.ts.instant('deleteText'), false);

    this.cancelSubscription = this.ps.cancelledAction$.subscribe((data) => {
      if (data.from === ComponentTypes.imageGallery && data.value === 1) {
        this.fs.deleteLayoutImages(this.fs.selectedImageIds);
      }
    });
    this.confirmSubscription = this.ps.confirmedAction$.subscribe((data) => {
      if (data.from === ComponentTypes.imageGallery) {
        console.log(this.fs.selectedImageIds);
        if (this.fs.selectedImageIds.length > 0) {
          const selectedImage = this.displayImages.filter((img) => img.ImageId === this.fs.selectedImageIds[0]);
          if (selectedImage && selectedImage.length > 0) {
            this.applyImage(selectedImage[0]);
          }
        }
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (this.confirmSubscription) {this.confirmSubscription.unsubscribe();}
      if(this.cancelSubscription){this.cancelSubscription.unsubscribe();}
    })
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  editGallery() {
    this.isEditGallery = !this.isEditGallery;
    this.displayImages.forEach((image) => {
      image.isImageSelected = false;
    });
  }

  cancel() {
    this.isEditGallery = !this.isEditGallery;
    this.displayImages.forEach((image) => {
      if (image.ImageId == this.fs.floorPlan.ImageId) {
        image.isImageSelected = true;
      } else {
        image.isImageSelected = false;
      }
    });
    this.fs.selectedImageIds = [];
  }

  deleteImages() {
    const discardChanges: string = this.ts.instant('discardChangesMsg');
    const title: string = this.ts.instant('alert');
    const mappedMsg: string = this.isLayout ? this.ts.instant('layoutImageDeleteConfirmMsg') :  this.ts.instant('cutomImageDeleteConfirmMsg');
    let multipleMsgs = [];
    multipleMsgs.push(discardChanges);
    multipleMsgs.push(mappedMsg);
    let mapped;
    if (this.isLayout) {
      mapped = this.fs.checkLayoutMappings();
    } else if (!this.isLayout) {
      mapped = this.fs.checkCustomImageMappings();
    }

    if (!this.fs.equal && mapped)
    {
      this.openConfirmationPopup(multipleMsgs, title, ConfirmationMessagesPopupComponent);
    }
    else if(!this.fs.equal)
    {
      this.openConfirmationPopup(discardChanges, title, ConfirmationPopupComponent);
    }
    else if(mapped)
    {
      this.openConfirmationPopup(mappedMsg, title, ConfirmationPopupComponent);
    }
    else
    {
      this.delete();
    }
  }

  openConfirmationPopup(confirmationMessage, title, component: any) {
    const noText: string = this.ts.instant('notext');
    const yesText: string = this.ts.instant('yestext');
    const popUpMessage = [{
      confirmationMessage: confirmationMessage,
      dialogTitle: title,
      showAlert: true
    }];
    let dialogRef;
    if (this.isLayout) {
      const componentDetails: ComponentDetails = Utilities.setComponentDetails(component, 'small',
      'active', popUpMessage, '');
      dialogRef  = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '450px',
        height: 'auto',
        data: {
          title: popUpMessage[0].dialogTitle,
          update: yesText,
          cancel: noText,
          componentDetails,
          from: ComponentTypes.floorPlanImage,
          back: false,
          standalone: true,
          showAlert: true
        }
      });
    } else {
      const componentDetails: ComponentDetails = Utilities.setComponentDetails(component, 'small',
        'action', popUpMessage, popUpMessage[0].dialogTitle);
        dialogRef = this.openCustomPopup(componentDetails, ComponentTypes.guestCharges,
        globalConst.popupDialogDimension.actionDialogWidth, globalConst.popupDialogDimension.actionDialogHeight, false, this.ts.instant('error'), this.ts.instant('ok'), '', true);
    }
    const confirmSubscription = this.ps.confirmedAction$.subscribe((val) => {
      if (this.isLayout) {
        if(!this.fs.equal) {
          this.fs.discardChanges$.next();
        }
        this.delete();
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (confirmSubscription) {
        confirmSubscription.unsubscribe();
      }
    });
  }

  delete() {
    if (this.fs.selectedImageIds.length > 0 && this.isLayout) {
      this.fs.deleteLayoutImages(this.fs.selectedImageIds).subscribe((data) => {
        if (data.State === 0) {
          this.fs.selectedImageIds = [];
          if (this.isEditGallery) {
            this.displayImages.forEach((img) => {
              img.isImageSelected = false;
            })
          }
        }
      });
    } else if (this.fs.selectedImageIds.length > 0 && !this.isLayout) {
      this.fs.deleteCustomImages(this.fs.selectedImageIds).subscribe((data) => {
        if (data.State === 0) {
          this.fs.selectedImageIds = [];
          if (this.isEditGallery) {
            this.displayImages.forEach((img) => {
              img.isImageSelected = false;
            })
          }
        }
      });
    }
  }
}
