<form [formGroup]="FormGrp" autocomplete="off">
  <div class="pin-section" *ngIf="IsCMSRequirePin && !compDetailsFetched && !showLoader">
    <mat-form-field class="cms-pin-field" [floatLabel]="floatLabelNever">
      <input [type]="hidePassword ? 'text' : 'password'" name="cmsPin" [placeholder]="shopCaptions.CMS.CmsPin" matInput
        formControlName="patronPin" name="patronPin" numMaxlength maxlength="10"
        (keydown.enter)="getPlayerInformation(); $event.preventDefault()" autocomplete="off">
      <mat-error>
        {{shopCaptions.CMS.MissingCMSPinError}}
      </mat-error>
    </mat-form-field>
  </div>
</form>
<ng-container *ngIf="showLoader; else playerWorthInfoSec">
  <div *ngIf="currentProductId!=versaProductId" id="cover-spin-retail">
    <div class="customspinnerimg">
      <img src="./assets/images/agil_favicon.ico" style="width: 100%;">
    </div>
    <div id="default-message">{{captions.lbl_processing}}</div>
  </div>

  <div *ngIf="currentProductId==versaProductId" id="cover-spin-retail" class="giffy-loader">
    <div class="customspinnerimg">
      <ng-container>
        <ng-container>
          <img class="defautTheme-ldr" alt="No Data" src="./assets/images/Versa-Generic-Loader-Versa.gif">
          <img class="spaTheme-ldr" alt="No Data" src="./assets/images/Versa-Generic-Loader-Spa.gif">
          <img class="golfTheme-ldr" alt="No Data" src="./assets/images/Versa-Generic-Loader-Golf.gif">
          <img class="blackwhiteTheme-ldr" alt="No Data" src="./assets/images/Versa-Generic-Loader-BW.gif">
          <img class="darkTheme-ldr" alt="No Data" src="./assets/images/Versa-Generic-Loader-Dark.gif">
        </ng-container>
      </ng-container>
      <div id="default-message">{{captions.lbl_processing}}</div>
    </div>
  </div>
</ng-container>
<ng-template #playerWorthInfoSec>
  <section class="player-worth-details h-100" *ngIf="compDetailsFetched">
    <div class="content">
      <div class="content-data" *ngIf = 'showPoints'>
        <span>{{appointmentsCaptions.PointsValue}}</span> - <span>{{playerWorthInfo.pointValue}}</span>
      </div>
      <div class="content-data" *ngIf = 'showComps'>
        <span>{{appointmentsCaptions.CompValue}}</span> - <span>{{playerWorthInfo.compValue}}</span>
      </div>
      <div class="content-data" *ngIf = 'NoViewAccess'>
        <div>          
        <span>{{allCaptions.BreakPointAccessDeniedMsg}}</span>
        </div>
        <div>
        <span>{{allCaptions.lbl_breakpoint}}: {{captions.breakpoint_player_points}} </span>
        </div>
        <div>          
        <span>{{allCaptions.lbl_breakpoint}}: {{captions.breakpoint_player_comps}}</span>
        </div>
      </div>
    </div>
  </section>
</ng-template>
<div class="btn-section">
  <button [disabled]="!AllowPatronLookup || showLoader" mat-button *ngIf="IsCMSRequirePin && !compDetailsFetched"
    [ngClass]="AllowPatronLookup && !showLoader? 'button--primary-save' : 'button--disabled'"
    class="spa-primary-default w150" (click)="getPlayerInformation()">{{shopCaptions.CMS.PlayerWorthGetDetailsBtn | uppercase}}
  </button>
  <button [disabled]="showLoader" mat-button
    [ngClass]="!showLoader? 'button--primary-save' : 'button--disabled'" class="spa-primary-default w150"
    (click)="CloseDialog()">{{allCaptions.Close | uppercase}}
  </button>
</div>