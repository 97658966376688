import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, of, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { RevenuePostingBusiness } from './retail-revenue-posting-business';
import { RevenuePostingSearchRequest, CloseRevenuePosting } from './revenue-posting';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import _ from 'lodash';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { RetailBreakPoint } from '../../../shared/globalsContant';
import { MatDialog } from '@angular/material/dialog';
import { VoidReasonComponent } from '../void-reason/void-reason.component';


@Component({
  selector: 'app-retail-revenue-posting-logs',
  templateUrl: './retail-revenue-posting-logs.component.html',
  styleUrls: ['./retail-revenue-posting-logs.component.scss'],
  providers: [RevenuePostingBusiness],
  encapsulation: ViewEncapsulation.None
})
export class RetailRevenuePostingLogsComponent implements OnInit {
  captions: any;
  revenueForm: UntypedFormGroup;
  searchText = '';
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  originalData$: Promise<any[]>;
  originalData: Observable<any[]>;
  count: number = 0;
  tableContent: Observable<any[]>;
  retryButton: ButtonValue;
  closeButton: ButtonValue;
  cancelButton: ButtonValue;
  searchButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  advanceForm: UntypedFormGroup;
  userList: any[] = [];
  paymentMethods: any[] = [];
  isAllSelected: boolean;
  isBatchProcessPending: boolean = false;
  refreshButton: ButtonValue;
  batchInProgressMsg: string = '';
  floatLabel: string;
  commonCaptions: any;



  constructor(private fb: UntypedFormBuilder, private localization: RetailLocalization, private utilities: RetailUtilities,
              private revenuePostingBusiness: RevenuePostingBusiness,  public propertyInfo: RetailPropertyInformation,
              public sharedservice: RetailSharedVariableService, private breakPoint:BreakPointAccess, public dialog: MatDialog) {
    this.captions = this.localization.captions.shop;
    this.commonCaptions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.formGenerator();
    this.tableOptions = this.revenuePostingBusiness.getTableOptions();
    this.headerOptions = this.revenuePostingBusiness.getHeaderOptions();

    this.retryButton = {
      type: 'primary',
      label: this.captions.retry,
      disabledproperty: true
    };
    
    this.closeButton = {
      type: 'primary',
      label: this.captions.Close,
      disabledproperty: true
    };
    this.refreshButton = {
      type: 'primary',
      label: this.captions.TransactionLogScreen.Refresh,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.reset,
      disabledproperty: true
    };
    this.searchButton = {
      type: 'primary',
      label: this.captions.search,
      disabledproperty: false
    };
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.reset,
      disabledproperty: true
    };
  

    this.batchInProgressMsg = this.captions.TransactionLogScreen.BatchInProgressBannerLbl;
    this.advanceForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.searchButton.disabledproperty = false;
      this.searchButton = {...this.searchButton};
      this.cancelButton.disabledproperty = !( this.advanceForm.valid && this.advanceForm.dirty);
      this.cancelButton = {...this.cancelButton};
    });
    this.getMasterData();
  }

  async getMasterData(){
    this.utilities.ToggleLoader(true, this.commonCaptions.lbl_processing);
    const result = await Promise.all([this.revenuePostingBusiness.getClerkInfo(),
                   this.revenuePostingBusiness.getPaymentMethodsByProductId()]);
    this.userList = result[0];
    this.paymentMethods = result[1];
    await this.isBatchProcessingPending();
    if (this.sharedservice.isFromAudit){
      this.getRoomRevenueData();
    }else {
      this.getRevenuePostLogs(true);
    }
  }

 ngOnDestroy(): void {
  this.sharedservice.isFromAudit = false;
  if (this.destroyed$) {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
 }

  async refreshGrid($event){
    this.retryButton.disabledproperty = true;
    this.refreshButton.disabledproperty = true;
    this.closeButton.disabledproperty = true;
    await this.isBatchProcessingPending();
    try {
        this.getRevenuePostLogs(true);
    } catch (ex) {
      this.utilities.ToggleLoader(false);
      this.DisableActionsForBatchRetry(true);
    }
  }

  async isBatchProcessingPending(){
    const result = await this.revenuePostingBusiness.isBatchProcessingPending() ;
    this.isBatchProcessPending = result;
    this.refreshButton.disabledproperty = !result;
    if (result) {
        this.DisableActionsForBatchRetry(result);
      }
    this.utilities.ToggleLoader(false);
  }

  DisableActionsForBatchRetry(disable: boolean){
    this.retryButton.disabledproperty = disable;
    this.retryButton = {...this.retryButton};
    this.closeButton.disabledproperty = disable;
    this.closeButton = {...this.closeButton};
  }


  formGenerator() {
    this.revenueForm = this.fb.group({
      fromDate : this.propertyInfo.CurrentDate,
      toDate : this.propertyInfo.CurrentDate,
      tableData : ''
    });
    this.advanceForm = this.fb.group({
      ticketNumber : '',
      user : '',
      failedAmount: ''
    });
  }

  fromDate(e) {
    const startDate = this.localization.getDate(this.revenueForm.controls.fromDate.value);
    const endDate = this.localization.getDate(this.revenueForm.controls.toDate.value);
    if (startDate >= endDate) {
      this.revenueForm.controls.toDate.setValue(startDate);
    }
    this.searchButton.disabledproperty = false;
  }

  toDate(e) {
    const startDate = this.localization.getDate(this.revenueForm.controls.fromDate.value);
    const endDate = this.localization.getDate(this.revenueForm.controls.toDate.value);
    if (startDate >= endDate) {
      this.revenueForm.controls.fromDate.setValue(endDate);
    }
    this.searchButton.disabledproperty = false;
  }

  getRevenuePostLogs(isFromRefresh: boolean = false, isFromDayEnd: boolean = false){
    this.utilities.ToggleLoader(true, this.commonCaptions.lbl_processing);
    let clerkIds = this.advanceForm.controls.user.value ?
    this.userList.filter(x => (x.userName.toLowerCase().includes(this.advanceForm.controls.user.value.toLowerCase()))) : [];
    if (clerkIds.length === 0 && this.advanceForm.controls.user.value) {
     clerkIds = [{id : 0}];
    }
    const request: RevenuePostingSearchRequest = {
      ticketNumber: this.advanceForm.controls.ticketNumber.value,
      startDate: this.localization.ConvertDateToISODateTime(this.revenueForm.controls.fromDate.value),
      endDate: this.localization.ConvertDateToISODateTime(this.revenueForm.controls.toDate.value),
      amount: this.advanceForm.controls.failedAmount.value,
      clerkIdList:  clerkIds ? clerkIds.map(x => x.id) : [],
      isAllSelected: false,
      id: [],
      transactionId: [],
    };
    this.originalData$ = this.revenuePostingBusiness.getTabledata(request, this.userList, this.paymentMethods, isFromDayEnd);
    this.originalData$.then(response => {
      this.count = response?.length;
      response = _.orderBy(response, 'ticketNumber', 'asc');
      if (this.isBatchProcessPending){
        this.headerOptions[0].isCheckboxDisabled = true;
        response.forEach(row => row.isCheckBoxDisabled = true);
      }else{
        this.headerOptions[0].isCheckboxDisabled = false;
        response.forEach(row => row.isCheckBoxDisabled = false);
      }
      this.originalData = of(response);
      this.tableContent = this.originalData;
      this.revenueForm.get('tableData').setValue(response);
      if (!isFromRefresh){
        this.refreshGrid(true);
        this.searchButton.disabledproperty = false;
      }else {
        this.DisableActionsForBatchRetry(true);
        this.utilities.ToggleLoader(false);
      }
    });
  }

  getTableData() {
    this.searchButton.disabledproperty = false;
    this.searchButton = {...this.searchButton};
    try{
      this.refreshGrid(true);
    }
    catch (ex) {
        this.utilities.ToggleLoader(false);
        this.searchButton.disabledproperty = false;
        console.log(ex);
    }
  }

  getRoomRevenueData() {
    this.searchButton.disabledproperty = false;
    this.searchButton = {...this.searchButton};
    try{
      this.getRevenuePostLogs(true, true);
    }
    catch (ex) {
        this.utilities.ToggleLoader(false);
        this.searchButton.disabledproperty = false;
        console.log(ex);
    }
  }

  tableAction(e) {
    switch (e.fromType) {
      case FromTypeEnum.allcheckbox:
        this.isAllSelected = e.value;
        this.settableData(e);
        this.retryButton.disabledproperty = !e.value;
        this.closeButton.disabledproperty = !e.value;
        break;
      case FromTypeEnum.rowcheck:
        this.settableData(e);
        const datalength = this.revenueForm.get('tableData').value;
        this.isAllSelected = datalength && datalength?.length === datalength.filter(x => x.checked)?.length;
        this.retryButton.disabledproperty = e.checkedData.length > 0 ? false : true;
        this.closeButton.disabledproperty = e.checkedData.length > 0 ? false : true;
        break;
      default:
        this.retryButton.disabledproperty = true;
        this.closeButton.disabledproperty = true;
    }
  }

  retry(e) {
    const data = this.revenueForm.get('tableData').value.filter(x => x.checked);
    this.postRevenue(data.map(x => x.id) , data.map(x => x.transactionId));
   
  }

  CloseRevenuePostings(request: CloseRevenuePosting) {
    this.utilities.ToggleLoader(true,this.commonCaptions.lbl_processing);
    try{
      this.revenuePostingBusiness.CloseRevenuePostings(request).then(x => {
        this.getTableData();
      });
    }
    catch (ex) {
      this.utilities.ToggleLoader(false);
    }
  }

 close(e) {
    const dialogRef = this.dialog.open(VoidReasonComponent, {
      height: 'auto',
      width: '40%',
      data: { headername: this.localization.captions.shop.ReasonForClose, closebool: true },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(resultData => {
      if (resultData.action.toLowerCase() === 'ok') {
        const request :CloseRevenuePosting = {
          ids : this.revenueForm.get('tableData').value.filter(x => x.checked).map(x => x.id),
          closeComment : resultData.reason
        }
       this.CloseRevenuePostings(request);
      }
    });
  }

  tableClose(e, key) {
    const dialogRef = this.dialog.open(VoidReasonComponent, {
      height: 'auto',
      width: '40%',
      data: { headername: this.localization.captions.shop.ReasonForClose, closebool: true },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(resultData => {
      if (resultData.action.toLowerCase() === 'ok') {
        const request :CloseRevenuePosting = {
          ids : [e.id],
          closeComment : resultData.reason
        }
       this.CloseRevenuePostings(request);
      }
    });
  }

  tableRetry(e, key) {
    this.postRevenue([e.id] , [e.transactionId]);
  }


  postRevenue(ids: number[], transactionIds: number[]) {
    const clerkIds = this.advanceForm.controls.user.value ?
    this.userList.filter(x => (x.userName.toLowerCase().includes(this.advanceForm.controls.user.value.toLowerCase()))) : [];
    const request: RevenuePostingSearchRequest = {
      startDate: this.localization.ConvertDateToISODateTime(this.revenueForm.controls.fromDate.value),
      endDate: this.localization.ConvertDateToISODateTime(this.revenueForm.controls.toDate.value),
      id: ids,
      transactionId: transactionIds,
      isAllSelected: this.isAllSelected,
      clerkIdList: clerkIds ? clerkIds.map(x => x.id) : [],
      ticketNumber: this.advanceForm.controls.ticketNumber.value,
      amount: this.advanceForm.controls.failedAmount.value,
     };
    this.utilities.ToggleLoader(true,this.commonCaptions.lbl_processing);
    try{
      this.revenuePostingBusiness.PostRevenue(request).then(x => {
        console.log(x);
        this.getTableData();
    });
    }
    catch (ex) {
      this.utilities.ToggleLoader(false);
    }
  }

  cancel(e) {
   this.advanceForm.reset();
   this.revenueForm.get('fromDate').setValue(this.propertyInfo.CurrentDate);
   this.revenueForm.get('toDate').setValue(this.propertyInfo.CurrentDate);
   this.retryButton.disabledproperty = true;
   this.closeButton.disabledproperty = true;
   this.tableContent = of([]);
   this.count = 0;
   this.revenueForm.get('tableData').setValue(this.tableContent);
   this.searchButton.disabledproperty = false;
  }

  settableData(e) {
    this.revenueForm.markAsDirty();
    this.revenueForm.get('tableData').setValue(e.array);
  }

  search(e) {
    this.refreshGrid(true);
  }

}
