import { Injectable } from "@angular/core";
import { AssignInstructorComponent } from "../assign-instructor/assign-instructor.component";
import { ComponentDetails } from "@app/popup-module/models/popup.interface";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CustomPopupComponent } from "@app/popup-module/components/custom-popup/custom-popup.component";
import { ComponentTypes } from "@app/shared/constants/commonenums";
import { TranslateService } from "@ngx-translate/core";
import { Utilities } from "@app/shared/utilities/utilities";
import { HttpService } from "@app/shared/services/http.service";
import { urlConfig } from "@app/shared/constants/url-config";
import { Observable, of } from "rxjs";
import { PartyDTO } from "@app/shared/models/RestaurantDTO";
import { ReservationDTO } from "@app/shared/models/InputContact";

@Injectable({ providedIn: 'root' })
export class ActionSupportService {

  constructor(protected dialog: MatDialog, private ts: TranslateService, private httpService: HttpService) { }


  assignInstructorDialogRef: MatDialogRef<any>;

  showAssignInstructor(reservation: number, currentAssignedInstructor) {
    const componentDetails = Utilities.setComponentDetails(AssignInstructorComponent, 'large', 'active', {
      reservation,
      currentAssignedInstructor
    });

    this.assignInstructorDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '90%',
      height: '90%',
      data: {
        title: this.ts.instant('assignserverbuttontext'),
        update: this.ts.instant('assignserverbuttontext'),
        cancel: this.ts.instant('cancel'),
        componentDetails,
        from: ComponentTypes.AssignInstructor,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    let assignInstructorDialogRefSubcribe = this.assignInstructorDialogRef.afterClosed().subscribe(response => {
      assignInstructorDialogRefSubcribe.unsubscribe();
    })
  }

  getInstructorsToAssign(reservationId: number): Observable<{Payload: AssignInstructorDTO[]}> {
    return this.httpService.get(`${urlConfig.getInstructorsToAssign}?propertyId=${Utilities.RestaurantId()}&reservationId=${reservationId}`);
    // return of({ Payload: instructors })
  }
  assignInstructor(instructorId: number, reservationId: number): Observable<any> {
    return this.httpService.post(`${urlConfig.assignInstructor}?propertyId=${Utilities.RestaurantId()}&reservationId=${reservationId}`, [instructorId]);
    // return of({ ChangeSet: {} })
  }
}

export interface AssignInstructorDTO {
  Id: number;
  Name: string;
  IsTemplate?: boolean;
  IsAvailable: boolean;
  OverlapBookings: {
    ActivityName: string, StartTime: string, EndTime: string
  }[]
}