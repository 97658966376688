import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { PopupService } from '@app/popup-module/popup.service';
import { CancelActivityPopupComponent } from '@app/shared/components/cancel-activity-popup/cancel-activity-popup.component';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { RejectStanbyComponent } from '@app/shared/components/reject-stanby/reject-stanby.component';
import { AttributeType, BookingBehavior, buttonTypes, ClassType, ComponentTypes, FinancialEffectAction, FinancialEffectType, HeadlineActivityType, OperationResultState, Operations, PartyState, PartyType, PaymentMethod, PricingBy, RolesAndPermissionsType } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PartyChargeState, PartyPrepaymentState } from '@app/shared/models/InputContact';
import { BookingChargeType, SelectionType, SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { HttpService } from '@app/shared/services/http.service';
import { PartyService } from '@app/shared/services/party.service';
import { seatRetailService } from '@app/shared/services/seatretail.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { round } from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import _ from 'lodash';
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';
import { AlertType, ButtonType } from '@app/common/enums/shared-enums';
import { ActivityHeadlinesComponent } from '@app/shared/components/activity-headlines/activity-headlines/activity-headlines.component';
import { ToastrService } from 'ngx-toastr';
import { AuditableEntityType } from '@app/shared/models/SimpleAuditLogItemDTO';
import { AuditlogComponent } from '@app/shared/components/auditlog/auditlog.component';
import { popupDialogDimension } from '@app/shared/constants/globalConstants';
import DisplayOptions from '@data/activitiesFilterOptions.json';
import { BookingDetail } from '../BookingDetail';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ActionSupportService } from './action-support.service';
import { EngageMemberByCardIdRequestDTO } from '@app/shared/models/EngageMemberByCardIdRequestDTO';
@Component({
  selector: 'privatelesson-booking-detail',
  templateUrl: './privatelesson-booking-detail.component.html',
  styleUrls: ['./privatelesson-booking-detail.component.scss']
})
export class PrivateLessonBookingDetailComponent extends BookingDetail implements OnInit, AfterViewInit, OnDestroy {
  @Input() data;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  classDetail: any;
  public buttonControls: ButtonValue[];
  searchconfig: FieldConfig[];
  @ViewChild('searchConfigForm', { static: true }) searchConfigForm: DynamicFormComponent;
  subscriptions: Subscription = new Subscription();
  locations: any[];
  staffs: any[];
  showAll: any = {};
  attendees: any[] = [];
  standbyAttendees: any[] = [];
  searchAttendeesText: string = '';
  PartyState = PartyState;
  partyType = PartyType;
  financialPartyData: any;
  confirmSubscription: Subscription;
  standBySubscription: Subscription;
  partiessubscription: Subscription;
  cancelledPartySubscription: Subscription;
  warningInfoDialogRef: MatDialogRef<any>;
  dueState = [PartyPrepaymentState.PartialPrepayment ,PartyPrepaymentState.PaymentDuePending ];
  paidState = [PartyPrepaymentState.DepositCollected , PartyPrepaymentState.Prepaid ];
  refundState = [PartyPrepaymentState.Refunded, PartyPrepaymentState.CancellationFeeApplied];
  financialEffectAction = FinancialEffectAction;
  isRetailEnabled: boolean;
  memberDetails:any;
  @Input() selectedViewId: number;
  addonsForActivity: any = [];
  addonsForBooking: any = [];
  addonsForBookingPerLesson: any = [];
  showAddOnIcon = false;
  showAddOnDetails = false;
  addonsForActivitySelected = [];
  notesReservation: any = [];
  headlinesForActivity: any;
  ActivityAttributeType = AttributeType;
  PartyPrepaymentState = PartyPrepaymentState;
  bookingPaymentMethod = PaymentMethod;
  displayOptions = DisplayOptions;
  optionsForFilter = [PartyState.Pending, PartyState.Seated, PartyState.Cancelled, PartyState.Left];
  classDetailDate: Date;
  @ViewChild('addOnForBookingPopover') private addOnForBookingPopover: NgbPopover;
  _MESSAGES = MESSAGES;
  noActionRequired: boolean = false;
  partyWithAddon: any;
  rolesAndPermissionsType = RolesAndPermissionsType;
  assignStaffButton: ButtonValue;

  guestNotesForParty:any;
  popupCloseAction:boolean = false;
  partyIntialized: boolean = false;
  @HostListener('click', ['$event'])
  handleClick(event: any) {
    if(event?.target?.className && !event?.target?.className?.includes('chat-info-container') && this.partyService.isChatPopoverOpened){
      this.popupCloseAction = !this.popupCloseAction;
    }
  }
  constructor(public partyService: PartyService, public apiService: ApiService, public ts: TranslateService,
    public dashboardFunctions: DashboardFunctions, public cs: CacheService, public appService: AppService,
    public datePipe: DatePipe, protected dialog: MatDialog, public popupService: PopupService, public httpService: HttpService,
    public retailservice: seatRetailService, private utilities: CommonUtilities, private toastrService: ToastrService, public actionSupportService: ActionSupportService
    // public actvitybkngform: ActivityBookingFormComponent
  ) {
    super(dialog, ts)
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
    }));
    this.subscriptions.add(this.cs.activitiesData.subscribe(activities => {
      if (activities && this.classDetail) {
        this.partiessubscription && this.partiessubscription.unsubscribe();
        this.updateClassDetail(activities);
      }
    }))
  }

  ngOnInit(): void {
    this.classDetail = this.data?.classDetail;
    if (this.classDetail?.weeklyViewDate) {
      this.classDetailDate = new Date(this.classDetail?.weeklyViewDate);
    }
    else {
      this.classDetailDate = this.cs.headerDate;
    }
    this.noActionRequired = this.cs.isIframeEnabled;
    this.staffAndLocationMapping();
    this.setMappedCustomGuestFields(this.classDetail.Id);
    this.addonsForActivitySelected = Utilities.getAddons(this._settings.Addons, this.classDetail.Id)
    this.loadAddOnForActivity();
    this.loadHeadlines();
    this.displayOptions.forEach(item => {
      item.isSelected = this.optionsForFilter.includes(item.id) ? true : false
    })
    this.setButtonControls();
    this.searchconfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this.ts.instant('searchAttendee'),
        class: 'class-detail__search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearch.bind(this)
      }
    ];

    this.assignStaffButton = { label: this._MESSAGES.labels.AssignStaff, type: buttonTypes.actionSecondarySmall, customclass: 'class-detail__actions__btn width-auto',name: this._MESSAGES.labels.AssignStaff }
  }
  setButtonControls() {
    this.buttonControls = [
      { label: this._MESSAGES.labels.CancelThisActivity, type: buttonTypes.actionSecondarySmall, customclass: 'class-detail__actions__btn width-auto',name: this._MESSAGES.labels.CancelThisActivity },
      { label: this._MESSAGES.labels.book, type: buttonTypes.actionPrimarySmall, customclass: 'class-detail__actions__btn',name: this._MESSAGES.labels.book }
    ];
    const restaurantDateTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    this.isRetailEnabled = Utilities.isRetailEnabledProperty(this._settings.General.RetailIntegrationDTO);
    if (new Date(this.classDetail.ClassEndDate) < restaurantDateTime) {
      this.buttonControls[0].disbaledproperity = true;
      this.buttonControls[1].disbaledproperity = true;
    }
    if (Utilities.isRetailEnabledProperty(this._settings.General.RetailIntegrationDTO)) {
      this.buttonControls[0].disbaledproperity = true;
    }

  }

  collectDues(attendee) {
    this.partyService.ratePlanObject = null;
    this.partyService.chargeGuest(attendee, false);
  }

  assignInstructor(){
    let currentAssignedInstructor = this.staffs.length && this.staffs[0].Id;
    this.actionSupportService.showAssignInstructor(this.classDetail.Bookings[0], currentAssignedInstructor);
  }

  updateClassDetail(activities) {
    var selectedClass = activities.ByClass.find(({ Id: classId }) => classId === this.classDetail.Id);
    if (selectedClass) {
      this.classDetail.Sessions = [...selectedClass.Sessions];
      if (!this.classDetail.SessionId) {
        var sessionSelectedId = this.classDetail.Sessions?.length ? this.classDetail.Sessions[0].SessionId : null;
      }
      var selectedSession = selectedClass.Sessions.find(session => session.SessionId === (this.classDetail.SessionId || sessionSelectedId))
      if (selectedSession) {
        this.classDetail.startTime = new Date('1970-01-01 ' + selectedSession.StartTime);
        this.classDetail.endTime = new Date('1970-01-01 ' + selectedSession.EndTime);
        this.classDetail.available = selectedSession.Available;
        this.classDetail.booked = selectedSession.Booked;
        this.staffAndLocationMapping();
      }
    } else {
      this.onClose();
    }
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.searchConfigForm.form.valueChanges
      .pipe(debounceTime(100),
        distinctUntilChanged())
      .subscribe((val: any) => {
        this.searchAttendeesText = val.searchText;
      }));
    this.subscriptions.add(this.partyService.Parties$.subscribe(reservations => {
      if(this.partyIntialized){
        let partyID = this.classDetail?.Bookings[0]?.partiesList[0]?.Id;
        let party = reservations.find(reservation => reservation.Id === partyID);

        if(party){
          this.classDetail.Bookings[0].partiesList = [party];
        }
      }
      this.staffAndLocationMapping();
      this.partyIntialized = true;
    }))
  }

  staffAndLocationMapping() {
    let partyIds: any[] = [];
    partyIds = this.classDetail.Bookings[0].partiesList;
    let mappedStaffs = this.classDetail.Bookings[0].partiesList.map(party => party.ServerId);
    this.staffs = this._settings.Servers.filter(server => mappedStaffs.includes(server.Id));
    let mappedLocation = this.classDetail.Bookings[0].partiesList.map(party => party.TableIds).flat();
    this.locations = this.cs.locationListForMerchant.filter(location => mappedLocation.includes(location.id));
    //setting full name
    if (this.staffs && this.staffs.length) {
      this.staffs = this.staffs.map(instrctorObj => {
        instrctorObj.fullName = instrctorObj['Name'] ? instrctorObj['Name'] : (instrctorObj['FirstName'] && instrctorObj['FirstName'] != "undefined" ? instrctorObj['FirstName'] : '') + (instrctorObj['LastName'] && instrctorObj['LastName'] != "undefined" ? " " + instrctorObj['LastName'] : '')
        return instrctorObj;
      });
    }
    // if(this.staffs?.length === 1 && this.staffs[0].IsTemplate){
    //   this.assignStaffAction = true;
    // }else{
    //   this.assignStaffAction = false;
    // }
    this.loadAttendees(partyIds.filter(attendee => (attendee.State == PartyState.Pending || attendee.State == PartyState.Seated || attendee.State == PartyState.Left || attendee.State == PartyState.Cancelled)));
  }

  loadAttendees(partyIds: any[]) {
    if (!this.classDetail.IsForStandbyReservations && this.classDetail.ClassType !== ClassType.Duration) {
      let partiesForActivity = [...this.partyService.Parties$.value, ...this.partyService.CancelledReservations$.value]
      this.setReservedAttendees(partiesForActivity, partyIds);
    } else {
      this.standBySubscription = this.partyService.StandbyParties$.subscribe(parties => {
        this.setReservedAttendees(this.partyService.Parties$.value, partyIds);
        this.standbyAttendees = [];
        parties.forEach(party => {
          let partySelected = partyIds.find(partyId => partyId.Id === party.Id);
          if (partySelected) {

            party.SessionAmount = partySelected.Amount;
            if (this.classDetail.ClassType == PricingBy.Session) {
              party.State = partySelected.State;
            }
            this.classDetail.startTime = party.SeatingTime;
            this.classDetail.endTime = party.DepartureTime;
            party['Duration'] = this.classDetail.Bookings[0].Duration;
            party['PackageName'] = this.cs.availablePackages.find(pack => pack.Id == party.PackageId)?.Name;
             //Set partyAmount 
            let bookingAmount = this.partyService.getBookingAmount(party);
            party['partyAmount'] = bookingAmount.partyAmount;
            this.setCardIcon(party);

            if (partySelected.BookedSessionId) {
              const selectedSession = party.BookedSessions?.filter(session => session.Id === partySelected.BookedSessionId)[0];
              party.BookedSessionId = partySelected.BookedSessionId;
              party.isConfirmedReservation = selectedSession.SessionType === PartyType.Reservation ? true : false;
            } else {
              party.isConfirmedReservation = party.Type === PartyType.Reservation ? true : false;
            }
            party.GuestName = party.Contact.FirstName + (party.Contact.LastName || '')
            if (party.isConfirmedReservation) {
              this.attendees.push(party);
            } else {
              if (Array.isArray(party.Contact)) {
                party.Contact = party.Contact[0];
              }
              party.isPast = this.isPastReservation(party);
              this.standbyAttendees.push(party);
            }
          }
        });
      });
    }
  }

  setReservedAttendees(parties, partyIds) {
    this.attendees = [];
    parties.forEach(party => {
      let partySelected = partyIds.find(partyId => partyId.Id === party.Id);
      if (partySelected) {
        party.BookedSessionId = partySelected.BookedSessionId;
        if(partyIds.length === 1){
          this.classDetail.startTime = party.StartTime;
          this.classDetail.endTime = party.EndTime;
        }
        party.SessionAmount = partySelected.Amount;
        party.checkInWithDues = this.partyService.validateAvailabilityForCheckInWithDues(party)
        let selectedActivity = this._settings.SpecialMeals.filter(activity => activity.Id == party.SpecialMealId)[0];
        if (selectedActivity.ClassType == PricingBy.Session) {
          party.State = partySelected.State;
        }
        party.isConfirmedReservation = this.classDetail.IsForStandbyReservations;
        this.setCardIcon(party);
        party.GuestName = party.Contact?.FirstName + (party.Contact?.LastName || '')
        this.attendees.push(party);
      }

      //Set partyAmount 
      let bookingAmount = this.partyService.getBookingAmount(party);
      party['partyAmount'] = bookingAmount.partyAmount;

    });
  }

  setCardIcon(party) {
    if (((party.RatePlanAmount > 0 || party.AddonItemAmount > 0) && party.PrepaymentState == PartyPrepaymentState.PrepaymentRequired && !party.CreditCardId)
      || (!party.CreditCardId && party.PrepaymentState == PartyPrepaymentState.DeferredPayment && party.ChargeState == PartyChargeState.Pending && party.RatePlanAmount > 0)) {
      party.notpaid = true;
      party.paid = false;
      party.authorized = false;
      party.paymentdue = false;
    }
    else if ((party.CreditCardId && (party.RatePlanAmount > 0 || party.AddonItemAmount > 0) && (party.PrepaymentState == PartyPrepaymentState.DepositCollected || party.PrepaymentState == PartyPrepaymentState.PaymentDuePending))) {
      party.paid = false;
      party.notpaid = false;
      party.authorized = false;
      party.paymentdue = true;
    }
    else if ((party.CreditCardId && (party.RatePlanAmount > 0 || party.AddonItemAmount > 0) && (party.PrepaymentState == PartyPrepaymentState.Prepaid || (party.PrepaymentState == PartyPrepaymentState.DeferredPayment && party.ChargeState == PartyChargeState.Charged)) || (party.RatePlanAmount > 0 && (party.RatePlanAmount + party.NegotiatedAmount == 0)))) {
      party.paid = true;
      party.notpaid = false;
      party.authorized = false;
      party.paymentdue = false;
    }
    else if (party.CreditCardId && (party.RatePlanAmount > 0 || party.AddonItemAmount > 0) && party.PrepaymentState == PartyPrepaymentState.DeferredPayment && party.ChargeState == PartyChargeState.Pending) {
      party.authorized = true;
      party.notpaid = false;
      party.paid = false;
      party.paymentdue = false;
    }
    else if (party.CreditCardId && party.NoShowFeeAmount > 0) {
      party.authorized = true;
      party.notpaid = false;
      party.paid = false;
      party.paymentdue = false;
    }
  }


  showWarningInfoPopUp(message, componentType, popupHeight, popupWidth, title, popupType = 'active') {

    let cancelText = popupType == 'active' ? 'No' : '';
    // let title = 'Confirm Check-in';
    //let msg = 'No card details collected to capture payment amount. Are you sure to checkin the attendee?'
    let noShowSet = false;
    let confirmCheckin = false;
    let updateText = popupType == 'active' ? 'Yes' : 'Ok';
    let showAlert = popupType != 'active';
    let noShowFeePopUp = false;

    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: title, showAlert: showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: popupType,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    this.warningInfoDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: popupWidth,
      height: popupHeight,
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: componentType,
        back: false,
        standalone: true,
        showAlert: true,
        showAction: true,
      }
    });


  }


  updateAddOnDetails(activity) {
    this.partyWithAddon = activity;
  }

  loadHeadlines() {
    let activityAttributesForSession = this._settings.SpecialMeals.find(activity => activity.Id == this.classDetail.Id)?.ActivityAttributes;
    let publicArray = { name: "Public", values: this.getPrivateAndPublicHeadline(activityAttributesForSession.filter(x => x.AttributeType == AttributeType.PublicHeadlines), this.classDetail) || [] };
    let privateArray = { name: "Private", values: this.getPrivateAndPublicHeadline(activityAttributesForSession.filter(x => x.AttributeType == AttributeType.PrivateHeadlines), this.classDetail) || [] };
    this.headlinesForActivity = [publicArray, privateArray];
  }


  addHeadlinesForActivity(classDetail, headline, noteType, componentName) {
    const popUpMessage = [{
      dialogTitle: componentName == ComponentTypes.cancelHeadlines ? this.ts.instant('deleteHeadline') : headline ? this.ts.instant('updateHeadline') : this.ts.instant('createHeadline'),
      showAlert: false,
      activityDetails: classDetail,
      headlines: headline,
      isCancel: componentName == ComponentTypes.cancelHeadlines,
      noteType: noteType.name
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ActivityHeadlinesComponent, 'small', '', popUpMessage,
      popUpMessage[0].dialogTitle);
    let headlinePopup = this.openCustomPopup(componentDetails, componentName ? componentName : ComponentTypes.saveHeadlines, '650px', 'auto', false, popUpMessage[0].dialogTitle, popUpMessage[0].isCancel ? 'Yes' : 'Save',
      popUpMessage[0].isCancel ? 'No' : 'Cancel', true);
    let headlinesSaveConfirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (headlinesSaveConfirmSubscription) {
        headlinesSaveConfirmSubscription.unsubscribe();
      }
      if (val == ComponentTypes.saveHeadlines) {
        let activityAtributes = {
          Id: ((headline?.IsDefault || headline?.ApplyForAllSessions) && this.partyService.headlinesForm.value.headlineType == HeadlineActivityType.OnlyThis) ? 0 : (headline ? headline.Id : 0),
          PropertyId: Utilities.RestaurantId(),
          ActivityId: classDetail.Id,
          ActivitySessionId: classDetail.SessionId,
          Description: this.partyService.headlinesForm.value.activityHeadlines,
          ApplyForAllSessions: this.partyService.headlinesForm.value.headlineType == HeadlineActivityType.OnlyThis ? false : true,
          AttributeType: noteType.name == 'Public' ? AttributeType.PublicHeadlines : AttributeType.PrivateHeadlines,
          StartDate: classDetail.weeklyViewDate ? (moment(classDetail.weeklyViewDate).format('YYYY-MM-DD') + 'T' + moment(classDetail.startTime).format('HH:mm:ss')) : (moment(this.cs.headerDate).format('YYYY-MM-DD') + 'T' + moment(classDetail.startTime).format('HH:mm:ss')),
          EndDate: classDetail.weeklyViewDate ? (moment(classDetail.weeklyViewDate).format('YYYY-MM-DD') + 'T' + moment(classDetail.endTime).format('HH:mm:ss')) : (moment(this.cs.headerDate).format('YYYY-MM-DD') + 'T' + moment(classDetail.endTime).format('HH:mm:ss')),
          IsDefault: false,
          CreatedBy: Utilities.getHostId()
        }
        headlinePopup.close();
        let ActivityAttributes = this._settings.SpecialMeals.find(activity => activity.Id == this.classDetail.Id)?.ActivityAttributes;
        let columnDate = classDetail.weeklyViewDate || this.cs.headerDate
        headline = ActivityAttributes.find(x => x.ApplyForAllSessions && activityAtributes.AttributeType == x.AttributeType && activityAtributes.Id != x.Id && moment(columnDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(columnDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"));
        if (headline && this.partyService.headlinesForm.value.headlineType == HeadlineActivityType.AllRemaining) {
          let msg = ''
          let notes = headline.Description
          this.ts.get('headlineUpdateWarningMessage', { notes }).subscribe(msgVal => {
            msg = msgVal;
          });
          this.showWarningInfoPopUp(msg, ComponentTypes.headlineUpdateWarningMessage, '300px', '450px', this.ts.instant('alert'));
          let healineUpdateConfirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
            if (val == ComponentTypes.headlineUpdateWarningMessage) {
              headlinePopup.close();
              if (healineUpdateConfirmSubscription) {
                healineUpdateConfirmSubscription.unsubscribe();
              }
              activityAtributes.Id = 0;
              this.partyService.SaveHeadlines(activityAtributes).subscribe(data => {
                this.UnSubscribePopUpAction(headlinesSaveConfirmSubscription);
                this.updateHeadlineSettings(data.Payload, activityAtributes.ActivityId);
              })
            }
          })
        } else {
          this.partyService.SaveHeadlines(activityAtributes).subscribe(data => {
            this.UnSubscribePopUpAction(headlinesSaveConfirmSubscription);
            this.updateHeadlineSettings(data.Payload, activityAtributes.ActivityId);
          })
        }
        headlinesSaveConfirmSubscription.unsubscribe();
      }
    });
    let cancelPopUpCloseSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from == ComponentTypes.saveHeadlines || val.from == ComponentTypes.cancelHeadlines || val.from == ComponentTypes.headlineUpdateWarningMessage) {
        headlinePopup.close();
        this.UnSubscribePopUpAction(cancelPopUpCloseSubscription);
      }
    });
    let headlinesDeleteConfirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (headlinesDeleteConfirmSubscription) {
        this.UnSubscribePopUpAction(headlinesDeleteConfirmSubscription)
      }
      if (val == ComponentTypes.cancelHeadlines) {
        headlinePopup.close();
        this.UnSubscribePopUpAction(headlinesDeleteConfirmSubscription);
        let sessionId = this.partyService.headlinesForm.value.headlineType == HeadlineActivityType.OnlyThis ? classDetail.SessionId : null
        this.partyService.DeleteHeadlines(headline.Id, sessionId).subscribe(data => {
          this.updateHeadlineSettings(data.Payload, classDetail.Id);
          this.toastrService.success(this.ts.instant("headlineDeleteSuccessMessage"), "", { timeOut: 1000, closeButton: true });
        })
      }
    })

    headlinePopup.afterClosed().subscribe(event => {
      this.UnSubscribePopUpAction(headlinesDeleteConfirmSubscription);
      this.UnSubscribePopUpAction(cancelPopUpCloseSubscription);
      this.UnSubscribePopUpAction(headlinesSaveConfirmSubscription);
    })

  }
  updateHeadlineSettings(activityAttributes, activityId) {
    if (activityAttributes) {
      let index = this._settings.SpecialMeals.findIndex(activity => activity.Id == activityId);
      this._settings.SpecialMeals[index].ActivityAttributes = activityAttributes;
      this.cs.settings.next(this._settings);
    }
  }


  deleteHeadlinesForActivity(classDetail, data, note) {
    this.addHeadlinesForActivity(classDetail, data, note, ComponentTypes.cancelHeadlines);
  }

  getPrivateAndPublicHeadline(ActivityAttributes, session) {
    let headline;
    if (ActivityAttributes?.length) {
      let columnDate = session?.weeklyViewDate || this.cs.headerDate
      headline = ActivityAttributes.find(x => x.ActivitySessionId == session.SessionId && x.Description !== '');
      if (!headline) {
        headline = ActivityAttributes.find(x => x.ActivitySessionId == session.SessionId);
      }
      if (!headline) {
        headline = ActivityAttributes.find(x => x.ApplyForAllSessions && !x.IsDefault && moment(columnDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(columnDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"))
      }
      if (!headline) {
        headline = ActivityAttributes.find(x => x.IsDefault && !x.ActivitySessionId);
      }
    }
    return headline && headline.Description !== '' ? [headline] : null;
  }

  loadAddOnForActivity() {
    this.showAddOnDetails = false;
    this.addonsForBookingPerLesson = [];
    this.addonsForBooking = [];
    this.addonsForActivitySelected = _.orderBy(this.addonsForActivitySelected, 'IsMandatory', 'desc')
    this.addonsForActivitySelected.forEach(data => {
      let addOnItem = this._settings.Addons.find(addOn => (data.AddonId == addOn.AddonId))

      if (addOnItem && addOnItem.SelectionType == SelectionType.PerLesson) {
        let category = this._settings.Categories.find(category => category.Id == addOnItem.CategoryId)
        if (category) {
          data['CategoryColor'] = `rgba(${category.Color.R}, ${category.Color.G}, ${category.Color.B}, ${category.Color.A})`;
        }
        this.addonsForBookingPerLesson.push(data);
      }
      else {
        this.showAddOnIcon = true;
        this.addonsForBooking.push(data);
        this.addonsForActivity.push(data);
      }

    })
  }

  confirmClick(attendee) {
    let confirmBooking = false;
    let selectedActivity = this._settings.SpecialMeals.filter(activity => activity.Id == attendee.SpecialMealId)[0];
    if(attendee.PackageId){
      attendee.PackageDetails = this.partyService.getDiscountPercentForCartItem(selectedActivity?.Id , attendee.PackageId)
    }
    if (selectedActivity) {
      let msg = this.ts.instant('areYouSureYouWanttoConfirm');
      this.showWarningInfoPopUp(msg, ComponentTypes.ConfirmBooking, '250px', '450px', this.ts.instant('confirmReservation'))
      this.subscriptions.add(this.warningInfoDialogRef.afterClosed().subscribe(event => {
        this.UnSubscribePopUpAction(confirmInPopUpConfirmationSubscription);
      }));

      let confirmInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.ConfirmBooking) {
          confirmBooking = true;
          attendee.BookingBehavior = BookingBehavior.PrivateLesson;
          /*  var slots=this.getSlotsObj();
           attendee.Slot = attendee.Slots[0]; */
          this.partyService.convertActivityStandbyToReservation(attendee, false, null);
          confirmInPopUpConfirmationSubscription.unsubscribe();
        }
      });
    }
  }
  getSlotsObj() {
    let { selectedOpenBookingSlots, slots_holder } = this.partyService;
    let resSlots = [];
    let slots = Object.entries(selectedOpenBookingSlots)
      .filter(arr => arr[1]);
    for (let slotI = 0; slotI < slots.length; slotI++) {
      let itm = slots[slotI];
      let currentSlotIndex = slots_holder.findIndex(slot_holder => slot_holder.Id == itm[0]);
      var slot = slots_holder[currentSlotIndex];
      // if (slot.PartyId && slot.PartyId && this.data.ReservedFor != slot.DateTime) {
      //   continue;
      // }
      var resSlot = {
        LocalTime: slot.DateTime,
        Duration: this._settings.General.TimeSlotUnitInMinutes,
        EndTime: this.calculateEndTime(slots_holder[currentSlotIndex].DateTime, this._settings.General.TimeSlotUnitInMinutes),
        PartyId: (slot.PartyId) ? slot.PartyId : null
      }

      for (let i = (currentSlotIndex + 1); slots_holder[i]; i++) {
        let slotDateTime = new Date(slots_holder[i].DateTime);
        let defaultDateTime = new Date(slots_holder[currentSlotIndex].DateTime);
        let mins: any = (slotDateTime.getTime() - defaultDateTime.getTime()) / (60 * 1000);
        if (slots.find(slot => slot[1] && +slot[0] == slots_holder[i].Id && mins <= resSlot.Duration)) {
          resSlot.Duration += this._settings.General.TimeSlotUnitInMinutes;
          slotI += 1;
        } else {
          break;
        }
      }
      resSlot.EndTime = this.calculateEndTime(resSlot.LocalTime, resSlot.Duration)
      resSlots.push(resSlot);
    }

    return resSlots;
  }

  calculateEndTime(startTimeStr, duration) {
    var startTime = new Date(startTimeStr);
    var startMins = startTime.getMinutes();
    startTime.setMinutes(startMins + duration);
    return startTime;
  }

  rejectClick(attendee) {
    let rejectBooking = false;
    this.partyService.cancelBookingContactObj = attendee?.Contact;
    let selectedActivity = this._settings.SpecialMeals.filter(activity => activity.Id == attendee.SpecialMealId)[0];
    if (selectedActivity) {
      const componentDetails: ComponentDetails = {
        componentName: RejectStanbyComponent,
        dimensionType: 'small',
        popupType: 'active',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: '',
        popupTitle: this.ts.instant('rejectionReason'),
      };
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '450px',
        height: '350px',
        data: {
          title: this.ts.instant('rejectionReason'),
          update: 'Yes',
          cancel: 'No',
          componentDetails,
          from: ComponentTypes.RejectBooking,
          back: false,
          standalone: true,
          showAlert: true
        }
      });
      this.subscriptions.add(dialogRef.afterClosed().subscribe(data => {
        if (rejectBooking) {
          alert('reject Click');
        }
        this.UnSubscribePopUpAction(rejectInPopUpConfirmationSubscription);
      }));

      let rejectInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.RejectBooking) {
          this.partyService.rejectPrivateStandActivity(this.partyService.ReasonForReject, attendee, attendee.Contact.EmailAddress, attendee.BookingId);
          rejectInPopUpConfirmationSubscription.unsubscribe();
        }
      });
    }
  }
  isPastReservation(party) {
    let currentTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    if (party.WishedTime) {
      //var time = party.DepartureTime.split('T');
      // var sessionDateTime = Utilities.getRestaurantDateTime(Utilities.AppendDateWithTime(time[0], time[1]).toString());
      if (moment(currentTime).diff(moment(party.DepartureTime), 'minute') >= 0) {
        return true;
      }
    }
    return false;
  }

  getDateinRestaurantDateTimeOffset(datetime: any) {
    const offset = Number(this._settings.General.DaylightDelta);
    const d = new Date(datetime);
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    const nd = new Date(utc + (3600000 * offset));
    return nd;
  }

  checkInWithDues(attendee) {
    if(!this.isStaffAssigned()){
      this.showStaffAssignError();
      return;
    }
    if (Utilities.isUserHasAccessToRetail(this.appService.restaurantId)) {
      attendee.FinancialEffectId = null;
      let selectedActivity = this._settings?.SpecialMeals?.find(activity => activity.Id == attendee.SpecialMealId) || null;
      this.validateCheckinTime(attendee, selectedActivity, () => {
        this.dashboardFunctions.CheckIn(attendee, false, true , selectedActivity);
      })
    } else {
      this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
    }
  }

  validateCheckinTime(attendee, selectedActivity, callbackFn){
    let seatingTime = new Date(attendee.StartTime);
    let currentTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    let scheduleTimeStatus = this.isScheduleTimeArrived(selectedActivity, seatingTime);
      if(scheduleTimeStatus === 'block'){
        // let checkIntime = moment(sessionDateTime).diff(currentTime, 'days') == 0 ? this.datePipe.transform(checkInStartTime, "hh:mm a") : this.datePipe.transform(checkInStartTime, "DD-MMM-yyyy hh:mm a");
        // let msg = '';
        // this.ts.get('CheckInAlert', { sessionTime, checkIntime }).subscribe(msgVal => msg = msgVal);
        // this.showCheckInfoPopUp(msg)
        callbackFn();
      } else if(scheduleTimeStatus === 'warn'){
        let msg = '';
        let formattedSeatingTime = moment(seatingTime).diff(currentTime, 'days') == 0 ? moment(seatingTime).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm a") : moment(seatingTime).locale(this.dashboardFunctions.getLocaleCode()).format("DD-MMM-yyyy hh:mm a");

        this.ts.get('CheckInConfirm', { sessionTime: formattedSeatingTime }).subscribe(msgVal => msg = msgVal);
        this.showWarningInfoPopUp(msg, ComponentTypes.CheckinSession, '300px', '400px', 'Confirm Check-in');
        let confirmCheckIn = false;
        this.subscriptions.add(this.warningInfoDialogRef?.afterClosed().subscribe(event => {
          if (confirmCheckIn) {
            callbackFn();
          }
          this.UnSubscribePopUpAction(confirmCheckInPopUpConfirmationSubscription);
        }));
        let confirmCheckInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
          if (val === ComponentTypes.CheckinSession) {
            confirmCheckIn = true;
          }
        });
      } else {
        callbackFn();
      }
  }

  isScheduleTimeArrived(selectedActivity, seatingTime): 'block' | 'warn' | 'allow'{

    let currentTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    let diffMin = moment(seatingTime).diff(currentTime, 'minute');
    if (selectedActivity.CheckInCutOff && diffMin > selectedActivity.CheckInCutOff) {
      return 'block';
    } else if(!selectedActivity.CheckInCutOff && diffMin > 0){
      return 'warn'
    } 
    return 'allow';
  }

  getCheckinStartTime(seatingTime, selectedActivity){
    if(!selectedActivity?.CheckInCutOff){
      return null;
    }
    return moment(seatingTime.setMinutes(seatingTime.getMinutes() - (selectedActivity?.CheckInCutOff || 0)));
  }



  checkInClick(attendee) {
    if(!this.isStaffAssigned()){
      this.showStaffAssignError();
      return;
    }
    //this.dashboardFunctions.CheckIn(attendee, true);
    let selectedActivity = this._settings?.SpecialMeals?.find(activity => activity.Id == attendee.SpecialMealId) || null;

    this.validateCheckinTime(attendee, selectedActivity, () => {
      this.dashboardFunctions.CheckIn(attendee, true , false , selectedActivity);
    })
  }

  showStaffAssignError(){
    this.dashboardFunctions.showErrorPopUp('Assign Staff to checkin', 'auto')
  }

  isStaffAssigned(){
    return this.staffs?.length === 1 && !this.staffs[0].IsTemplate;
  }

  // getActivityName(activityId): string {
  //   return this._settings.SpecialMeals.find(activity => activity.Id == activityId).Name;
  // }

  checkOutClick(attendee) {
    // this.apiService.checkOut(attendee.Id, attendee.SpecialMealId, attendee.BookedSessionId).subscribe(response => {
    //   //this.attendees.find(attendeeSrc => attendee.Id === attendeeSrc.Id).State = this.PartyState.Left;
    //   if (response.Payload && response.Payload.PaymentState && response.Payload.PaymentState == 1 && response.Payload.PaymentAmount > 0 && response.Payload.PaymentType == FinancialEffectType.PartialRefund) {// Refund
    //     this.partyService.openConfirmationDialog(response, "A refund for " + this._settings.General.OperationCurrency + response.Payload.PaymentAmount + " has been successfully processed and checked out the attendee for " + (attendee.SessionGroupId > 0 ? "Class" + this.partyService.getActivityName(attendee.SpecialMealId) : " this session"), null);
    //   }
    //   else if (response.Payload && response.Payload.PaymentState && response.Payload.PaymentState == 1 && response.Payload.PaymentAmount > 0 && response.Payload.PaymentType == FinancialEffectType.PartialCharge) {// Charge
    //     this.partyService.openConfirmationDialog(response, " The attendee is successfully charged for pending " + this._settings.General.OperationCurrency + response.Payload.PaymentAmount + " and checked out of " + (attendee.SessionGroupId > 0 ? "Class" + this.partyService.getActivityName(attendee.SpecialMealId) : " this session"), null);
    //   }
    //   else {
    //     this.partyService.openConfirmationDialog(response, "The attendee is successfully checked out of " + (attendee.SessionGroupId > 0 ? "Class " + this.partyService.getActivityName(attendee.SpecialMealId) : " this session"), null);
    //   }
    // });   

    this.partyService.checkOutOpenOrPrivateBooking(attendee);

  }

  changeEvent(name) {
    switch (name) {
      case this._MESSAGES.labels.book: {
        if (this.staffs.length > 1) {
          //Remove booking confirmation screen 
          // this.getBookConfirmation();
          this.bookClass();
        } else {
          this.bookClass();
        }
        break;
      }
      case this._MESSAGES.labels.CancelThisActivity: {
        this.cancelActivity();
        break;
      }
    }
  }
  bookClass() {
    var tabInputData = {
      lessonId: this.classDetail.Id,
      fromDetailComponent: true,
      locationId: this.classDetail.locationId,
      instructorId: this.classDetail.instructorId,
      weeklyViewDate: this.classDetail.weeklyViewDate
    }
    this.dashboardFunctions.createOrEditPrivateActivityBooking(false, tabInputData);
  }

  getBookConfirmation() {
    const noText: string = this.ts.instant('notext');
    const yesText: string = this.ts.instant('yestext');

    const msg = 'This session is jointly hosted by ' + this.getStaffNames() + '. Do you want to proceed?'

    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm'
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '280px',
      data: {
        title: popUpMessage[0].dialogTitle,
        update: yesText,
        cancel: noText,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: false
      }
    });
    this.confirmSubscription = this.popupService.confirmedAction$.subscribe((val) => {
      this.bookClass();
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe(() => {
      if (this.confirmSubscription) {
        this.confirmSubscription.unsubscribe();
      }
    }));

  }

  undoCheckIn(attendee){
    this.partyService.reinstateReservation(attendee);
  }

  fetchMemberDetails(membershipId,event,popover){
    let {SiteId, TenantId} = this._settings.General.EngageIntegrationDTO;

    let req = new EngageMemberByCardIdRequestDTO(membershipId, TenantId, SiteId);

    this.subscriptions.add(this.partyService.api.getEngageMemberByCardId(req).subscribe(memberInfo =>{
      this.memberDetails = memberInfo?.ProfileInfoGetByCardId?.ProfileValue;
      popover.open();
    }));
    
    event.stopPropagation();
    event.preventDefault()
  }

  getStaffNames() {
    let staffName = '';
    this.staffs.forEach((staff, index) => {
      staffName = staffName + staff.fullName;
      if (index < (this.staffs.length - 3)) {
        staffName = staffName + ', ';
      } else if (index === this.staffs.length - 2) {
        staffName = staffName + ' and ';
      }
    });

    return staffName;
  }

  clearSearch(event) {
    this.searchConfigForm.form.get('searchText').setValue('');
  }

  editParty(partyInfo) {
    if ((partyInfo.State == PartyState.Seated || (this.classDetail.IsForStandbyReservations && partyInfo.ReservedFor && (moment(moment(partyInfo.ReservedFor).format('YYYY-MM-DDTHH:mm')).diff(moment(moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)).format('YYYY-MM-DDTHH:mm')), 'minutes') <= 0))) && (partyInfo.State == PartyState.Seated && !this.dashboardFunctions.checkEditCheckedInReservationsConfig() || ((partyInfo.State == PartyState.Left || partyInfo.State == PartyState.Pending) && !this.dashboardFunctions.checkBookPastDaysReservationsConfig()))) {
      this.dashboardFunctions.editGuest(partyInfo.Contact.Id, partyInfo.Id);
    } else {
      this.subscriptions.add(this.partyService.getPartyInfo(partyInfo.Id).subscribe(response => {
        if (response.Payload) {
          this.retailservice.oldReservation = _.cloneDeep(response.Payload);
          response.Payload['isConfirmedReservation'] = partyInfo.isConfirmedReservation;
          response.Payload['isStandByLessonBooked'] = this.classDetail.IsForStandbyReservations;
          response.Payload['addPrivateLessonBooking'] = partyInfo.isConfirmedReservation ? null : true;
          response.Payload['bookingRatePlan'] = response.Payload;
          this.dashboardFunctions.createOrEditPrivateActivityBooking(true, response.Payload);
        }
      }));
    }
  }

  cancelPartyForSessionOrClass(partyInfo) {
    this.partyService.BookingBehaviour = BookingBehavior.PrivateLesson;
    this.dashboardFunctions.CancelPartyForSessionOrClass(partyInfo);
  }

  onClose() {
    this.close.emit();
  }

  isBySession(): boolean {
    return this.classDetail.ClassType === ClassType.Session;
  }

  cancelActivity() {
    let cancelReservation = false;


    const configOptions = null;
    const confirmationMessage =
      'Are you sure want to cancel "' + this.classDetail.Name + '"?';

    const popUpMessage = [{
      confirmationMessage: confirmationMessage,
      dialogTitle: 'confirm',
      showAlert: false,
      displayOptions: false,
      configOptions: configOptions
    }];

    const componentDetails: ComponentDetails = {
      componentName: CancelActivityPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: popUpMessage[0].displayOptions ? '350px' : '250px',
      data: {
        title: 'cancelConfirmationMsg',
        update: 'Yes',
        cancel: 'No',
        componentDetails,
        from: ComponentTypes.CancelActivityBooking,
        back: false,
        standalone: true,
        showAlert: true
      }
    });
    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      if (cancelReservation && this.classDetail) {
        const dateTime = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)).format("YYYY-MM-DDThh:mm:ss.SSS");
        this.apiService.cancelClassActivity(dateTime, this.classDetail.Id, false).subscribe(response => {
          if (response.State == OperationResultState.Success
            && response.Payload) {
            this.showConfirmationWarningPopup(response.Payload, false);
          } else {
            cancelReservation = false;
            this.onClose();
          }
        })
      }
    }));
    let cancelPopUpConfirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.CancelActivityBooking) {
        cancelReservation = true;
        this.partyService.Operations = 3;
        this.UnSubscribePopUpAction(cancelPopUpConfirmSubscription);
      }
    });
    let cancelPopUpCloseSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.CancelActivityBooking) {
        cancelReservation = false;
        this.UnSubscribePopUpAction(cancelPopUpCloseSubscription);
      }
    });
  }


  showPartyWaiverForm(party) {
    this.subscriptions.add(this.partyService.getWaiverFormUrl(party.ConfirmationCode, party.BookingReferenceId, null).subscribe(data => {
      if (data.State == OperationResultState.Success) {
        if (!(data.Payload)) { 
          window.open(party.WaiverFormURL, 'Waiver Form');
        }
        else {
          const binaryString = window.atob(data.Payload);
          const bytes = new Uint8Array(binaryString.length);
          const binaryToBlob = bytes.map((byte, i) => binaryString.charCodeAt(i));
          const blob = new Blob([binaryToBlob], { type: 'application/pdf' });
          this.downloadFile(blob, "123.pdf");
        }
      }
    }));
  }

  private downloadFile(blob: any, fileName: string): void {

    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    window.open(url, '_blank');

    // rewoke URL after 15 minutes
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 15 * 60 * 1000);
  }

  showConfirmationWarningPopup(message, cancelAllSessions) {
    let cancelReservation = false;
    const displayOptions: boolean = false;

    const confirmationMessage = message;

    const popUpMessage = [{
      confirmationMessage: confirmationMessage,
      dialogTitle: 'confirm',
      showAlert: true,
      displayOptions: displayOptions
    }];

    const componentDetails: ComponentDetails = {
      componentName: CancelActivityPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '550px',
      height: '350px',
      data: {
        title: 'cancelConfirmationMsg',
        update: 'Proceed',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.CancelActivityBooking,
        back: false,
        standalone: true,
        showAlert: true
      }
    });
    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      if (cancelReservation && this.classDetail) {
        const dateTime = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)).format("YYYY-MM-DDThh:mm:ss.SSS");
        this.apiService.cancelClassActivity(dateTime, this.classDetail.Id, true).subscribe(response => {
          cancelReservation = false;
          this.onClose();
        })
      }
    }));
    let cancelPopUpConfirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.CancelActivityBooking) {
        cancelReservation = true;
        this.UnSubscribePopUpAction(cancelPopUpConfirmSubscription);
      }
    });
    let cancelPopUpCloseSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.CancelActivityBooking) {
        cancelReservation = false;
        this.UnSubscribePopUpAction(cancelPopUpCloseSubscription);
      }
    });
  }

  UnSubscribePopUpAction(obj) {
    if (obj) {
      obj.unsubscribe();
    }
  }


  moveToShopScreen(attendee) {
    this.partyService.currentPartyId = attendee?.Id;
    if (Utilities.isUserHasAccessToRetail(this.appService.restaurantId)) {
      this.retailservice.SetShopItems([], null, null, attendee?.Id, null,null, attendee?.Contact?.Id,null,null,attendee?.Contact?.TrackMembershipNumber)
      this.partyService.bookingDetailViewPopupDialogRef?.close();
    } else {
      this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
    }
  }

  financialDetails(attendee) {
    this.financialPartyData = attendee;
    }

  reservationNotes(attendee) {
    this.notesReservation = this.dashboardFunctions.getReservationNotes(attendee, BookingBehavior.PrivateLesson);
    this.guestNotesForParty = attendee.Contact.Notes;
  }

  filterReservations(item) {
    item.isSelected = !item.isSelected;
    let selectedOptions = this.displayOptions.filter(x => x.isSelected).map(x => x.id);

    if (selectedOptions && selectedOptions.length == 0) {
      item.isSelected = !item.isSelected;
      return;
    }
    this.filterReservationsBasedOnselection(selectedOptions);
  }


  filterReservationsBasedOnselection(selectedOptions) {
    this.optionsForFilter = [];
    selectedOptions.forEach(option => {
      switch (option) {
        case PartyState.Pending: {
          this.optionsForFilter.push(PartyState.Pending);
          break;
        }
        case PartyState.Seated: {
          this.optionsForFilter.push(PartyState.Seated);
          break;
        }
        case PartyState.Left: {
          this.optionsForFilter.push(PartyState.Left);
          break;
        }
        case PartyState.Cancelled: {
          this.optionsForFilter.push(PartyState.Cancelled);
          break;
        }
      }
    })

  }

  ngOnDestroy() {
    this.partiessubscription && this.partiessubscription.unsubscribe();
    this.cancelledPartySubscription && this.cancelledPartySubscription.unsubscribe();
    this.standBySubscription && this.standBySubscription.unsubscribe();
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}

const MESSAGES = {
  labels: {
    CancelThisActivity: "CancelThisActivity",
    AssignStaff: "Assign Instructor",
    book: "book"
  }
}
