import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchActiveWristband'
})
export class SearchActiveWristbandPipe implements PipeTransform {

    //Returns any active wristband mapped in the reservation

  transform(bookingContacts: any[], searchText): any {
    if(!bookingContacts?.length){
      return [];
    }
    return searchText && bookingContacts.find(bookingContact => bookingContact.WristbandId === searchText && bookingContact.IsActiveWristBand) ? "yes" : "no";
  }

}
