<div class="confirmation-popup">
    <!-- Non-Email Confirmation Section -->
    <div *ngIf="!forConfirmationEmail">
      <!-- Alert Message -->
      <div *ngIf="showAlert" class="confirmation-popup__show-alert">
        <i class="icon-warning seat-icon-color alert-icon"></i>
      </div>
  
      <!-- Checkmark and Confirmation Message -->
      <div class="confirmation-popup__checkiconParent" *ngIf="hideTitle">
        <svg class="confirmation-popup__checkmark" viewBox="0 0 52 52">
          <circle cx="26" cy="26" r="25" fill="white" />
          <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
        <span
          class="confirmation-popup__message seat-alert-messages"
          id="confirmationMsg">
          {{ confirmationMessage }}</span>
      </div>
  
      <!-- API Response Section -->
      <div class="confirmation-popup__apiresponsediv" *ngIf="!hideTitle">
        <p
          class="confirmation-popup__message seat-alert-messages"
          id="confirmationMsg">
          {{ confirmationMessage }}
        </p>
      </div>
    </div>
  
    <!-- Email Confirmation Section -->
    <div  class="confirmation-popup__email-message">
      <!-- Confirmation Code -->
      <div
        *ngIf="showConfirmationNumber"
        class="confirmation-popup__guest-mail-details">
        <p class="seat-labels">{{ 'confirmationcode' | translate }}</p>
        <span class="seat-text-fields">{{ data[0].confirmationCode }}</span>
      </div>
  
      <!-- Guest Name -->
      <div class="confirmation-popup__guest-mail-details">
        <p class="seat-labels">{{ 'guestName' | translate }}</p>
        <span class="seat-text-fields">
          {{ data[0].firstName }} {{ data[0].lastName }}
        </span>
      </div>
  
      <!-- Email Input -->
      <div class="confirmation-popup__guest-mail-details">

        <dynamic-form [config]="emailConfig" #form="dynamicForm"></dynamic-form>

      </div>
    </div>
  
    <!-- List of Items Section -->
    <div *ngIf="listOfItem?.length > 0" class="confirmation-popup__item-list">
      <ng-scrollbar track="vertical">
        <div
          class="confirmation-popup__dataList"
          *ngFor="let data of listOfItem; let i = index"
          [title]="data">
          <span>{{ data }}</span>
          <!-- Divider Line -->
          <div
            *ngIf="listOfItem?.length - 1 != i"
            class="confirmation-popup__divider"></div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
  