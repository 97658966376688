import { AfterViewInit, Component, DoCheck, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { LoaderService } from '@app/core/services/loader.service';
import { InputImages } from '@app/shared/models/FloorPlanImageInputDto';
import { TablesService } from '@app/shared/services/tables.service';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { EditorFloorsComponent } from '@components/dashboard/editor-view/editor-floors/editor-floors.component';
import { EditorJoinedTablesComponent } from '@components/dashboard/editor-view/editor-joined-tables/editor-joined-tables.component';
import { EditorTablesComponent } from '@components/dashboard/editor-view/editor-tables/editor-tables.component';
import { NewFloorPlanComponent } from '@components/new-floor-plan/new-floor-plan.component';
import { buttonTypes, ComponentTypes, EditorStatus, LayoutUpdateStatus, OperationResultState, PartyState, ShapeTypeEnum, TableBlockBy, TableBlockingRuleFrequencyType } from '@constants/commonenums';
import { ActivitiesVenue, controlSettings, popupDialogDimension } from '@constants/globalConstants';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { LayoutConfig } from '@models/global.interface';
import { ReservationDTO } from '@models/InputContact';
import { FloorPlanDTO, LabelDTO, LayoutDTO, SettingsDTO, ShapeDTO, StandaloneTableDTO, TableShapeType, WallDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails, TabsModel } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { FloorplanService } from '@services/floorplan.service';
import { LayoutFunctions } from '@utilities/layout-functions';
import { Utilities } from '@utilities/utilities';
import { fabric } from 'fabric';
import {cloneDeep, maxBy, minBy, omit, isEqual, differenceBy} from 'lodash';
import { isString } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { EditorLayoutComponent } from '../dashboard/editor-view/editor-layout/editor-layout.component';
import { ExceptionPopupComponent } from '../exception-popup/exception-popup.component';

@Component({
  selector: 'app-layout-editor',
  templateUrl: './layout-editor.component.html',
  styleUrls: ['./layout-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LayoutEditorComponent extends Utilities implements OnInit, OnDestroy, DoCheck, AfterViewInit {
  @Input() layoutConfig: LayoutConfig;
  @ViewChild('layoutView', { static: true }) layoutView: ElementRef;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  @Input() settings: any;
  propertyChanges = ['Walls', 'Shapes', 'Labels'];
  canvas: any;
  minPinRadius = 25;
  minRadius = 25;
  bCircleRadius = 52;
  strokeColor = 'gray';
  fillColor = '#fff';
  minHeight = 50;
  minWidth = 50;
  selectedGridValue = 10;
  selectedIndex: any;
  selectedTabIndex = 0;
  tableObj: any = [];
  showUpdate = false;
  config: FieldConfig[];
  minObjectHeight: number = 20;
  minObjectWidth: number = 20;
  floorPlanSelctionConfig: FieldConfig[];
  initialScaleFactor: number = 1;
  mouseMoved = false;
  freeDrawing = true;
  maxPartySizeAllowed = 51;
  selectedFloorPlanId: any = null;
  leftMinValue = 0;
  topMinValue = 0;
  assignFloorPlanId: any;
  activeObjects: any;
  count = 0;
  joinedTablesArray = [];
  joinTable = false;
  joinedTables = [];
  notSettingDate = true;
  floorPlanViews: any;
  rectangle: any;
  circle: any;
  ellipse: any;
  tables: StandaloneTableDTO[];
  floorPlan: any;
  floorPlanOptions: any;
  dashboardData: any;
  editorTabs: TabsModel;
  saveData: ButtonValue;
  cancelData: ButtonValue;
  assignFloor = false;
  addNewLayoutClicked = false;
  objectsSelected: any = [];
  groupedObject: any;
  addBtn: ButtonValue;
  editBtn: ButtonValue;
  deleteBtn: ButtonValue;
  layoutFloorPlans: FloorPlanDTO[];
  selectedLayout: number;
  arrayToBeCompared: FloorPlanDTO[];
  fromNoDiscardChanges = false;
  previousSelectedLayoutId: number;
  _setting: SettingsDTO = {} as SettingsDTO;
  _layout: LayoutDTO = {} as LayoutDTO;
  blockingRule = [];
  nonCommunalTableConversion = false;
  communalTableConversion = false;
  communalIcon: any;
  labels: LabelDTO[];
  walls: WallDTO[];
  seatedPartiesForNonCommunal: ReservationDTO[];
  seatingAreas: any;
  shapes: ShapeDTO[];
  x: any;
  lineCounter = 0;
  y: any;
  layoutData: any;
  heightValue: number;
  widthValue: number;
  scaleFactorX: number = 1;
  scaleFactorY: number = 1;
  scaleFactor: number = 1;
  adjustvalueleft = 0;
  adjustvaluetop = 0;
  drawingObject = {
    type: "",
    background: "",
    border: ""
  };
  roof = null;
  roofPoints = [];
  lines = [];
  ignoreSeatingParties = false;
  selectedLayoutID = { value: null };
  isPartyMappedToTable = false;
  holdRerender = false;
  subscriptions: Subscription = new Subscription();
  labelsCreated: number;
  seatingTypes: any;
  _clipboard;
  currentSelectedObject: any;
  layoutImages: InputImages[] = [];
  objectImages: InputImages[] = [];
  basicRectangle: fabric.Rect;
  basicCircle: fabric.Circle;
  pin: fabric.Circle;
  image: any;
  customImages: InputImages[] = [];
  hideViewByFloors = false;
  isGridlinesEnabled: boolean;
  isInitial = true;
  isBufferTimeEnabled: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setCanvasResizeValue();
  }

  constructor(private appService: AppService, dialog: MatDialog, private popupService: PopupService, public fs: FloorplanService,
    private cs: CacheService, private toaster: ToastrService, private ts: TranslateService, private layoutFunctions: LayoutFunctions, private ls: LoaderService, private tableservice?: TablesService) {
    super(dialog);
  }

  ngOnInit() {
    this.hideViewByFloors = Utilities.controlValidate(controlSettings.Layout_ViewByFloors_Hide,this.appService.PropertyType);
    this.isBufferTimeEnabled = Utilities.controlValidate(controlSettings.Layout_Show_BufferTime , this.appService.PropertyType);
    this.subscriptions.add(this.cs.restaurantImages.subscribe((images) => {
      if (images) {
        this.layoutImages = images.LayoutImages;
        this.objectImages = images.ObjectImages;
        this.customImages = images.CustomImages;
        if (this.canvas && !this.fs.customObjectImageActionTriggered && !this.fs.customImagesActionTriggered) {
          this.canvas.clear();
          this.clearTableSizeElement();
          let defaultFloorPlanData = this._layout.FloorPlans.filter(x => x.IsDefault == true)[0];
          let defaultFloorPlanID = defaultFloorPlanData.Id;

          if (this.selectedLayoutID.value && this.selectedLayoutID.value != defaultFloorPlanID) {
            this.setData();
            this.floorPlanSelctionConfig[0].value = this.selectedLayoutID.value;
            this.setSelectedLayout(this.selectedLayoutID);
          } else {
            this.tables = defaultFloorPlanData.StandaloneTables;
            this.labels = defaultFloorPlanData.Labels;
            this.walls = defaultFloorPlanData.Walls;
            this.shapes = defaultFloorPlanData.Shapes;
            this.setData();
            this.isInitial = true;
            this.setDefaultFloor(this.tables, this.labels, this.walls, this.shapes);
            this.generateTableSize();
            this.isInitial = false;
            let index = this.arrayToBeCompared.findIndex(x => x.Id == this.floorPlan.Id);
            this.arrayToBeCompared.splice(index, 1, cloneDeep(this.floorPlan));
          }
        }
        this.fs.customObjectImageActionTriggered = false;
        this.fs.customImagesActionTriggered = false;
      }
    }))
    this.fs.isNewlyaddedLayout = false;
    this.setActions();
    //this.setData();
    this.setDashBoardData();
  }

  setCanvasResizeValue() {
    //   const height = this.element.nativeElement.clientHeight > this.heightValue ? this.element.nativeElement.clientHeight : this.heightValue;
    //   const width = this.element.nativeElement.clientWidth > this.widthValue ? this.element.nativeElement.clientWidth : this.widthValue;
    //   let scaleFactorY =this.element.nativeElement.clientHeight/this.heightValue;
    //   let scaleFactorX=this.element.nativeElement.clientWidth/this.widthValue;
    //   let scaleFactor = scaleFactorX >= scaleFactorY ? scaleFactorY : scaleFactorX;
    //   let calculatedWidth = width*scaleFactor;
    //  // this.canvas.setZoom(scaleFactor);
    //   this.canvas.setHeight(height*scaleFactorY);
    //   this.canvas.setWidth(width*scaleFactorX);
    //   let lines = this.canvas.getObjects().filter(x => x.name == 'line')
    //   lines.forEach(element => {
    //     this.canvas.remove(element);
    //   });
    //   this.canvas.renderAll();
    //   this.setGridLines();
  }

  setData() {
    this.config = [
      {
        type: 'select',
        name: 'viewBy',
        placeholder: '',
        options: [],
        class: 'floorplan-editor__floorplan-view',
        showErrorText: true,
        appearance: true,
        isTranslate: false,
        cellClick: this.setSelectedFloorPlan.bind(this)

      }];

    this.floorPlanSelctionConfig = [{
      type: 'select',
      name: 'floorPlan',
      placeholder: '',
      options: this.layoutFloorPlans ? this.layoutFloorPlans.map(x => { return { id: x.Id, value: x.Name } }) : [],
      class: 'floorplan-editor__floorplan-selection',
      showErrorText: true,
      appearance: true,
      isTranslate: true,
      cellClick: this.setSelectedLayout.bind(this)
    }];
    this.labelsCreated = 0;
    this.layoutFloorPlans = cloneDeep(this._layout.FloorPlans);
    this.arrayToBeCompared = cloneDeep(this._layout.FloorPlans);
    this.floorPlan = this.layoutFloorPlans.filter(x => x.IsDefault == true)[0];
    this.selectedLayout = this.floorPlan.Id;
    this.fs.floorPlan = this.floorPlan;
    this.floorPlanViews = this.floorPlan.FloorPlanViews;
    this.floorPlanOptions = this.floorPlanViews.map(x => { return { id: x.Id, value: x.Name } });
    this.floorPlanOptions.push({ id: -1, value: this.ts.instant('viewAllFloors') });
    this.config[0].options = this.floorPlanOptions;
    this.config[0].value = this.floorPlanOptions.filter(x => x.id == -1)[0].id;
    this.selectedFloorPlanId = -1;
    this.floorPlanSelctionConfig[0].options = this.layoutFloorPlans.map(x => { return { id: x.Id, value: x.Name } });
    this.floorPlanSelctionConfig[0].value = this.layoutFloorPlans.filter(x => x.IsDefault == true)[0].Id;
    this.tables = this.floorPlan.StandaloneTables;
    this.labels = this.floorPlan.Labels;
    this.shapes = this.floorPlan.Shapes;
    this.walls = this.floorPlan.Walls;
    this.joinedTablesArray = this.floorPlan.JoinedTables;
    this.seatingAreas = this._setting.SeatingAreas.map(x => ({ id: x.Id, value: x.Name }));
    this.seatingAreas = this.seatingAreas.filter(sa => sa.id != -1);
    this.seatingTypes = this._setting.SeatingTypes.map(x => ({
      id: x.Id, value: x.Description,
      color: x.Color ? this.RGBAToHexA(x.Color.R, x.Color.G, x.Color.B) : null
    }));
    this.seatingTypes = this.seatingTypes.filter(st => st.id != -1);
    if (this.fs.selectedTableBeforeAction$.value) {
      const objs = this.canvas.getObjects();
      const selectedTbObject = objs.filter(obj => obj.Id == this.fs.selectedTableBeforeAction$.value.Id);
      if (selectedTbObject.length > 0) {
        this.setSeletedObject(selectedTbObject[0]);
        this.setTableSettingsForm(selectedTbObject[0]);
      } else {
        this.fs.onSelectionCleared.next(true);
      }
    }
    this.AddEmptyFloorPlan();
  }

  setTableSettingsForm(tableDetails) {
    this.fs.tableSettingsForm.controls.tableNumber.setValue(tableDetails.Name, { emitEvent: false });
    if(this.isBufferTimeEnabled){
      this.fs.tableSettingsForm.controls.bufferTimeInMinutes.setValue(tableDetails.BufferTimeInMinutes, { emitEvent: false });
    }
    this.fs.tableSettingsForm.controls.seatingArea.setValue(tableDetails.SeatingAreaId, { emitEvent: false });
    this.fs.tableSettingsForm.controls.availableForReservation.setValue(tableDetails.IsAvailableForReservations, { emitEvent: false });
    this.fs.tableSettingsForm.controls.minPartySize.setValue(tableDetails.MinPartySize, { emitEvent: false });
    this.fs.tableSettingsForm.controls.maxPartySize.setValue(tableDetails.MaxPartySize, { emitEvent: false });
    this.fs.tableSettingsForm.controls.communalTable.setValue(tableDetails.IsCommunalTable, { emitEvent: false });
    if(this.isBufferTimeEnabled){
    this.fs.tableSettingsForm.controls.bufferTimeInMinutes.setValue(tableDetails.BufferTimeInMinutes, { emitEvent: false });
    }
    if(this.fs.tableSettingsForm?.controls?.LocationGroupId){
      this.fs.tableSettingsForm.controls.LocationGroupId.setValue(tableDetails.LocationGroupId ? tableDetails.LocationGroupId : -1  );
    }
    if (this.fs.tableSettingsForm.controls.seatingType)
      this.fs.tableSettingsForm.controls.seatingType.setValue(tableDetails.SeatingTypeId, { emitEvent: false });
  }

  setSeletedObject(data) {
    let objs = this.canvas.getObjects();
    let that = this;
    if (data) {
      objs.forEach(element => {
        if (element.Id && element.Id == data.Id) {
          that.canvas.setActiveObject(element);
        }
      });
      this.canvas.renderAll();
    }
  }

  setActions() {
    this.saveData = {
      type: buttonTypes.actionPrimarySmall,
      label: 'Save',
      customclass: "floorplan-editor__save-btn"
    };
    this.cancelData = {
      type: buttonTypes.actionSecondarySmall,
      label: 'Cancel',
      customclass: "floorplan-editor__cancel-btn"
    };
    this.addBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: '',
      icon: "icon-Group-7106",
      customclass: "floorplan-editor__add-btn",
      disbaledproperity: this.isActivityProperty()
    };
    this.editBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: '',
      icon: "icon-Group-7105",
      customclass: "floorplan-editor__edit-btn",
      disbaledproperity: true
    };
    this.deleteBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: '',
      icon: "icon-ic_delete_24px",
      customclass: "floorplan-editor__delete-btn",
      disbaledproperity: true
    };
  }

  setDashBoardData() {
    this.appService.showDashboard = true;
    this.dashboardData = [{ partyInfo: null, countInfo: [], status: [], messages: [], actions: [], preDefinedMessages: [], editorDetails: [] }];
    this.editorTabs = {
      tabs: [
        {
          tabComponent: EditorTablesComponent,
          tabLabel: 'tablesMenuText',
        },
        {
          tabComponent: EditorLayoutComponent,
          tabLabel: 'layoutMenuText'
        },
        {
          tabComponent: EditorJoinedTablesComponent,
          tabLabel: 'joinedTables',
        },
        {
          tabComponent: EditorFloorsComponent,
          tabLabel: 'floors',
        }],
      default: true
    };
    if (Utilities.controlValidate(controlSettings.Layout_FloorsTab_Hide, this.appService.PropertyType)) {
      this.editorTabs.tabs = this.editorTabs.tabs.splice(0, 3);
    }
    this.dashboardData.editorDetails = [this.editorTabs];
    const eve = { index: 0 };
    this.tabChanged(eve);
  }

  emptyFloorMinSize() {
    if (Utilities.controlValidate(controlSettings.Location_layoutEditor_emptyFloor_minSize_1, this.appService)) {
      return 1;
    } 
    return 2;
  }

  emptyFloorMaxSize() {
    if (Utilities.controlValidate(controlSettings.Location_layoutEditor_emptyFloor_maxSize_1, this.appService)) {
      return 1;
    } 
    return 4;
  }
  
  AddEmptyFloorPlan() {
    let emptyFloorPlan: FloorPlanDTO;
    let nextFloorPlanViewId = this.getNextFloorPlanViewId();
    let minPartySize = this.emptyFloorMinSize();
    let maxPartySize = this.emptyFloorMaxSize();
    const newTable1 = {
      Angle: 0,
      FloorPlanViewId: nextFloorPlanViewId,
      Height: 100,
      Id: 1,
      IsAvailableForBooking: false,
      IsAvailableForReservations: true,
      IsCommunalTable: false,
      IsNew: true,
      Left: 0,
      MaxPartySize: maxPartySize,
      MinPartySize: minPartySize,
      LocationGroupId:null,
      Name: "1",
      Price: null,
      RotationAngle: 0,
      SeatingArea: this.seatingAreas[0].value,
      SeatingAreaId: this.seatingAreas[0].id,
      SeatingType: (this.seatingTypes && this.seatingTypes.length > 0) ? this.seatingTypes[0].value : null,
      SeatingTypeId: (this.seatingTypes && this.seatingTypes.length > 0) ? this.seatingTypes[0].id : null,
      ServerId: null,
      ShapeType: 0,
      Top: 0,
      Width: 100,
      fill: "#000000",
      label: null,
      opacity: 0.7,
      originX: null,
      originY: null,
      radius: 0,
      scaleX: 1,
      scaleY: 1,
      stroke: null,
      strokeWidth: 0,
      rx: null,
      ry: null,
      BufferTimeInMinutes: 0
    }
    const newTable2 = {
      Angle: 0,
      FloorPlanViewId: nextFloorPlanViewId,
      Height: 100,
      Id: 2,
      IsAvailableForBooking: false,
      IsAvailableForReservations: true,
      IsCommunalTable: false,
      IsNew: true,
      Left: 110,
      MaxPartySize: maxPartySize,
      MinPartySize: minPartySize,
      LocationGroupId:null,
      Name: "2",
      Price: null,
      RotationAngle: 0,
      SeatingArea: this.seatingAreas[0].value,
      SeatingAreaId: this.seatingAreas[0].id,
      SeatingType: (this.seatingTypes && this.seatingTypes.length > 0) ? this.seatingTypes[0].value : null,
      SeatingTypeId: (this.seatingTypes && this.seatingTypes.length > 0) ? this.seatingTypes[0].id : null,
      ServerId: null,
      ShapeType: 0,
      Top: 0,
      Width: 100,
      fill: "#000000",
      label: null,
      opacity: 0.7,
      originX: null,
      originY: null,
      radius: 0,
      scaleX: 1,
      scaleY: 1,
      stroke: null,
      strokeWidth: 0,
      rx: null,
      ry: null,
      BufferTimeInMinutes: 0
    }
    const newTable3 = {
      Angle: 0,
      FloorPlanViewId: nextFloorPlanViewId,
      Height: 100,
      Id: 3,
      IsAvailableForBooking: false,
      IsAvailableForReservations: true,
      IsCommunalTable: false,
      IsNew: true,
      Left: 220,
      MaxPartySize: maxPartySize,
      MinPartySize: minPartySize,
      LocationGroupId:null,
      Name: "3",
      Price: null,
      RotationAngle: 0,
      SeatingArea: this.seatingAreas[0].value,
      SeatingAreaId: this.seatingAreas[0].id,
      SeatingType: (this.seatingTypes && this.seatingTypes.length > 0) ? this.seatingTypes[0].value : null,
      SeatingTypeId: (this.seatingTypes && this.seatingTypes.length > 0) ? this.seatingTypes[0].id : null,
      ServerId: null,
      ShapeType: 0,
      Top: 0,
      Width: 100,
      fill: "#000000",
      label: null,
      opacity: 0.7,
      originX: null,
      originY: null,
      radius: 0,
      scaleX: 1,
      scaleY: 1,
      stroke: null,
      strokeWidth: 0,
      rx: null,
      ry: null,
      BufferTimeInMinutes: 0
    }
    const newTable4 = {
      Angle: 0,
      FloorPlanViewId: nextFloorPlanViewId,
      Height: 100,
      Id: 4,
      IsAvailableForBooking: false,
      IsAvailableForReservations: true,
      IsCommunalTable: false,
      IsNew: true,
      Left: 0,
      MaxPartySize: maxPartySize,
      MinPartySize: minPartySize,
      LocationGroupId:null,
      Name: "4",
      Price: null,
      RotationAngle: 0,
      SeatingArea: this.seatingAreas[0].value,
      SeatingAreaId: this.seatingAreas[0].id,
      SeatingType: (this.seatingTypes && this.seatingTypes.length > 0) ? this.seatingTypes[0].value : null,
      SeatingTypeId: (this.seatingTypes && this.seatingTypes.length > 0) ? this.seatingTypes[0].id : null,
      ServerId: null,
      ShapeType: 1,
      Top: 110,
      Width: 100,
      fill: "#000000",
      label: null,
      opacity: 0.7,
      originX: null,
      originY: null,
      radius: 50,
      scaleX: 1,
      scaleY: 1,
      stroke: null,
      strokeWidth: 0,
      rx: null,
      ry: null,
      BufferTimeInMinutes: 0
    }
    const newTable5 = {
      Angle: 0,
      FloorPlanViewId: nextFloorPlanViewId,
      Height: 100,
      Id: 5,
      IsAvailableForBooking: false,
      IsAvailableForReservations: true,
      IsCommunalTable: false,
      IsNew: true,
      Left: 0,
      MaxPartySize: maxPartySize,
      MinPartySize: minPartySize,
      LocationGroupId:null,
      Name: "5",
      Price: null,
      RotationAngle: 0,
      SeatingArea: this.seatingAreas[0].value,
      SeatingAreaId: this.seatingAreas[0].id,
      SeatingType: (this.seatingTypes && this.seatingTypes.length > 0) ? this.seatingTypes[0].value : null,
      SeatingTypeId: (this.seatingTypes && this.seatingTypes.length > 0) ? this.seatingTypes[0].id : null,
      ServerId: null,
      ShapeType: 1,
      Top: 220,
      Width: 100,
      fill: "#000000",
      label: null,
      opacity: 0.7,
      originX: null,
      originY: null,
      radius: 0,
      scaleX: 1,
      scaleY: 1,
      stroke: null,
      strokeWidth: 0,
      rx: null,
      ry: null,
      BufferTimeInMinutes: 0
    }
    emptyFloorPlan = {
      Id: -1,
      Name: "Empty Plan",
      IsDefault: false,
      IsRemoved: false,
      Dimensions: [],
      Dates: [],
      Labels: [],
      Walls: [],
      Shapes: [],
      JoinedTables: [],
      FloorPlanViews: [{ Id: nextFloorPlanViewId, Name: "Main" }],
      StandaloneTables: [],
      ImageId: null
    };
    emptyFloorPlan.StandaloneTables.push(newTable1);
    emptyFloorPlan.StandaloneTables.push(newTable2);
    emptyFloorPlan.StandaloneTables.push(newTable3);
    emptyFloorPlan.StandaloneTables.push(newTable4);
    emptyFloorPlan.StandaloneTables.push(newTable5);
    let joinedTable = {
      Id: 0,
      MinPartySize: 2,
      MaxPartySize: 4,
      StandaloneTableIds: [1, 2],
      LayoutId: -1,
      IsNew: false,
    }
    emptyFloorPlan.JoinedTables.push(joinedTable);
    this.arrayToBeCompared.push(emptyFloorPlan);

  }
  addNewLayout(data) {
    if (this.isActivityProperty()) {
      return true;
    }
    this.previousSelectedLayoutId = this.selectedLayout ? cloneDeep(this.selectedLayout) : this.selectedLayout == 0 ? cloneDeep(this.selectedLayout) : cloneDeep(this._layout.FloorPlans.filter(x => x.IsDefault == true)[0].Id);
    if (data) {
      if (!this.fs.equal) {
        this.addNewLayoutClicked = true;
        this.openDiscardChangesPopup();
      }
      else {
        this.OpenAddNewLayoutDialogue(data)
      }
    } else {
      this.OpenAddNewLayoutDialogue(data)
    }
  }

  openDiscardChangesPopup() {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant('discardChangesMsg'),
      dialogTitle: this.ts.instant('confirm')
    }];
    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, ' ', '', popUpMessage, '');
    const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.saveChangesFloor,
      '300px', '250px', false, this.ts.instant('confirm'), 'Yes', 'No', false);
    let confirmActionSubscription: ISubscription = null;
    let cancelActionSubscription: ISubscription = null;
    confirmActionSubscription = this.popupService.confirmedAction$.subscribe((val) => {
      if (val == ComponentTypes.saveChangesFloor) {
        this.discardChanges();
      }
    });
    cancelActionSubscription = this.popupService.cancelledAction$.subscribe((val) => {
      this.addNewLayoutClicked = false;
      if (val.from == ComponentTypes.saveChangesFloor) {
        this.fromNoDiscardChanges = true;
        this.floorPlanSelctionConfig[0].value = this.previousSelectedLayoutId;
        this.floorPlan = cloneDeep(this.layoutFloorPlans.filter(x => x.Id == this.previousSelectedLayoutId)[0]);
        let eve = { value: this.previousSelectedLayoutId };
        this.setSelectedLayout(eve);
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (confirmActionSubscription) { confirmActionSubscription.unsubscribe(); }
      if (cancelActionSubscription) { cancelActionSubscription.unsubscribe(); }
    })
  }

  OpenAddNewLayoutDialogue(data) {
    if (!data && this.floorPlan.IsDefault) { return; }
    // if (!data && this.floorPlan.IsDefault && !this.propertyLayoutSettings) { return; } //need to revisit the code for theatre kind of properties
    if (this.fs.newFloorForm) {
      this.fs.newFloorForm.controls.basedOn.enable();
      this.fs.newFloorForm.controls.basedOn.setValue(this.floorPlan.BasedOn && this.floorPlan.Id > 0 ? this.floorPlan.BasedOn : this.floorPlan.Id);
      if (Utilities.controlValidate(controlSettings.Location_layoutEditor_edit_default_floorName, this.appService.PropertyType) && !data) {
        if (this.floorPlan.Name == '<default>') {
          this.fs.newFloorForm.controls.floorPlan.setValue(this.floorPlan.Name.slice(1,8));
        } else {
          let name = this.floorPlan.Name.split('-')[0];
          this.fs.newFloorForm.controls.floorPlan.setValue(name);
        }
      } else {
        this.fs.newFloorForm.controls.floorPlan.setValue(this.floorPlan.Name);
      }
      
    }
    const dataToSet = data ? '' : this.fs.newFloorForm.value;
    const popUpMessage = {
      dialogTitle: 'confirm',
      inputData: dataToSet,
      layout: this.layoutFloorPlans
    };
    const componentInfo = Utilities.setComponentDetails(NewFloorPlanComponent, ' ', '', popUpMessage, '');
    const dialogRef = this.openCustomPopup(componentInfo, data ? ComponentTypes.newFloorPlanAdd : ComponentTypes.newFloorPlanEdit,
      '500px', '353px', false, data ? this.ts.instant('addNewFloor') : this.ts.instant('editFloorPlan'), data ? 'Save' : 'update', 'cancel', false);
    let confirmActionSubscription: ISubscription = null;
    let cancelActionSubscription: ISubscription = null;
    confirmActionSubscription = this.popupService.confirmedAction$.subscribe((val) => {
      if (val == ComponentTypes.newFloorPlanAdd) {
        this.addNewLayoutClicked = false;
        this.labelsCreated = 0;
        const toBeClonedFloorPlan = cloneDeep(this.arrayToBeCompared.filter(x => x.Id == this.fs.newFloorForm.controls.basedOn.value))[0];
        toBeClonedFloorPlan['BasedOn'] = cloneDeep(toBeClonedFloorPlan.Id);
        toBeClonedFloorPlan.Id = 0;
        toBeClonedFloorPlan.Dates = [];
        toBeClonedFloorPlan.JoinedTables.forEach(x => {
          x.LayoutId = toBeClonedFloorPlan.Id;
          x.IsNew = true;
        });
        let newFloorId = this.getNextFloorPlanViewId();
        toBeClonedFloorPlan.FloorPlanViews.forEach(x => {
          toBeClonedFloorPlan.StandaloneTables.forEach(function (st) {
            if (st.FloorPlanViewId == x.Id) {
              st.FloorPlanViewId = newFloorId;
              st.IsNew = true;
              st.ServerId = null;
            }
          });
          x.Id = newFloorId++;
        });
        toBeClonedFloorPlan.Name = this.fs.newFloorForm.controls.floorPlan.value;
        toBeClonedFloorPlan.IsDefault = false;
        this.layoutFloorPlans.push(cloneDeep(toBeClonedFloorPlan));
        this.floorPlanSelctionConfig[0].options = this.layoutFloorPlans.map(x => { return { id: x.Id, value: x.Name } });
        this.arrayToBeCompared.push(cloneDeep(toBeClonedFloorPlan));
        let eve = { value: toBeClonedFloorPlan.Id };
        this.setSelectedLayout(eve);
      } else if (val == ComponentTypes.newFloorPlanEdit) {
        if (this.floorPlan.IsDefault && Utilities.controlValidate(controlSettings.Location_layoutEditor_edit_default_floorName, this.appService.PropertyType)) {
          this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].Name = `${this.fs.newFloorForm.controls.floorPlan.value}- <default>`;
        } else {
          this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].Name = this.fs.newFloorForm.controls.floorPlan.value;
        }
        this.floorPlanSelctionConfig[0].options = this.layoutFloorPlans.map(x => { return { id: x.Id, value: x.Name } });
        this.floorPlan = this.fs.floorPlan = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0];
      }
    });
    cancelActionSubscription = this.popupService.cancelledAction$.subscribe((val) => {
      this.addNewLayoutClicked = false;
    })
    dialogRef.afterClosed().subscribe(() => {
      if (confirmActionSubscription) { confirmActionSubscription.unsubscribe(); }
      if (cancelActionSubscription) { cancelActionSubscription.unsubscribe(); }
    })
  }

  tabChanged(eve) {
    this.fs.joinTable = false;
    this.fs.selectedType.next('');
    this.assignFloor = false;
    this.fs.selectedTableBeforeAction$.next(null);
    if (this.canvas) {
      this.canvas.discardActiveObject();
    }
    const objs = this.canvas.getObjects();
    objs.forEach(element => {
      this.settingUnselectedColor(element);
    });
    switch (eve.index) {
      case 0:
        this.fs.onSelectionCleared.next(true);
        this.selectedTabIndex = 0;
        this.dashboardData ? this.dashboardData.actions = [] : '';
        fabric.Object.prototype.set({
          cornerColor: '#1da664',
          cornerStrokeColor: '#1da664',
          cornerStyle: 'circle',
          transparentCorners: false,
          rotatingPointOffset: 20,
          cornerSize: 12,
          hasBorders: false,
          lockScalingFlip: true,
          lockMovementX: false,
          lockMovementY: false,
          flipX: false,
          flipY: false,
          lockScalingX: false,
          lockScalingY: false,
          hasControls: true,
          objectCaching: false
        });
        this.setActive();
        break;
      case 1:
        this.selectedTabIndex = 1;
        this.canvas.hoverCursor = 'pointer';
        this.dashboardData ? this.dashboardData.actions = [] : '';
        fabric.Object.prototype.set({
          borderColor: '#1da664',
          cornerColor: '#1da664',
          cornerStrokeColor: '#1da664',
          cornerStyle: 'circle',
          transparentCorners: false,
          rotatingPointOffset: 20,
          cornerSize: 12,
          hasBorders: false,
          lockScalingFlip: true,
          lockMovementX: true,
          lockMovementY: true,
          flipX: false,
          flipY: false,
          lockScalingX: true,
          lockScalingY: true,
          objectCaching: false
        });
        this.setInActive();
        break;
      case 2:
        this.selectedTabIndex = 2;
        this.dashboardData.actions = [{
          type: buttonTypes.actionPrimary, label: 'newJoinedTables', actionName: EditorStatus.NewJoinedTables,
          customclass: 'action-btn'
        }];
        this.canvas.hoverCursor = 'pointer';
        fabric.Object.prototype.set({
          borderColor: '#1da664',
          cornerColor: '#1da664',
          cornerStrokeColor: '#1da664',
          cornerStyle: 'circle',
          transparentCorners: false,
          rotatingPointOffset: 20,
          cornerSize: 12,
          hasBorders: false,
          lockScalingFlip: true,
          lockMovementX: true,
          lockMovementY: true,
          flipX: false,
          flipY: false,
          lockScalingX: true,
          lockScalingY: true,
          objectCaching: false
        });
        this.setInActive();
        break;
      case 3:
        this.selectedTabIndex = 3;
        this.assignFloor = true;

        this.dashboardData.actions = this.isActivityProperty() ? [] : [
          // {
          //   type: buttonTypes.actionSecondary, label: 'assignText',
          //   actionName: EditorStatus.AssignTables, customclass: 'action-btn'
          // },
          {
            type: buttonTypes.actionPrimary, label: 'addFloorText', actionName: EditorStatus.AddNewFloor,
            customclass: 'action-btn'
          }];
        this.canvas.hoverCursor = 'pointer';

        fabric.Object.prototype.set({
          cornerColor: '#1da664',
          cornerStrokeColor: '#1da664',
          cornerStyle: 'circle',
          transparentCorners: false,
          rotatingPointOffset: 20,
          cornerSize: 12,
          hasBorders: false,
          lockScalingFlip: true,
          lockMovementX: true,
          lockMovementY: true,
          flipX: false,
          flipY: false,
          lockScalingX: true,
          lockScalingY: true,
          objectCaching: false
        });
        this.setInActive();
        this.fs.selectedFloorId = null;
        break;
    }
    this.dashboardData = { ...this.dashboardData };
    this.canvas.renderAll();
  }


  setSelectedLayout(eve) {
    this.selectedLayoutID.value = eve.value;
    if (this.fromNoDiscardChanges) {
      this.selectedLayout = eve.value;
      this.fromNoDiscardChanges = false;
      let currentFloorPlan = this.layoutFloorPlans.filter(x => x.Id == this.previousSelectedLayoutId)[0];
      this.tables = currentFloorPlan.StandaloneTables;
      this.labels = currentFloorPlan.Labels;
      this.shapes = currentFloorPlan.Shapes;
      this.walls = currentFloorPlan.Walls;
      this.canvas.clear();
      this.clearTableSizeElement();
      this.setDefaultFloor(this.tables, this.labels, this.walls, this.shapes);
      this.generateTableSize();
    }
    else {
      this.previousSelectedLayoutId = this.selectedLayout ? cloneDeep(this.selectedLayout) : this.selectedLayout == 0 ? cloneDeep(this.selectedLayout) : cloneDeep(this._layout.FloorPlans.filter(x => x.IsDefault == true)[0].Id);
      const previousLayout = cloneDeep(this.arrayToBeCompared.filter(x => x.Id == this.previousSelectedLayoutId)[0]);
      this.selectedLayout = eve.value;
      const currentLayout = this.layoutFloorPlans.filter(x => x.Id == this.previousSelectedLayoutId)[0];
      const arraysEqual = isEqual(previousLayout, currentLayout);
      if (!this.fs.equal) {
        this.openDiscardChangesPopup();
      }
      else {
        let selectedLayoutData = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0];
        if (selectedLayoutData) {
          this.tables = selectedLayoutData.StandaloneTables;
          this.labels = selectedLayoutData.Labels;
          this.walls = selectedLayoutData.Walls;
          this.shapes = selectedLayoutData.Shapes;
          this.floorPlan = this.fs.floorPlan = selectedLayoutData;
          this.joinedTablesArray = this.floorPlan.JoinedTables;
          this.canvas.clear();
          this.clearTableSizeElement();
          this.setFloorData(this.selectedLayout);
        }

      }
      this.floorPlan = this.fs.floorPlan = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0]
    }
  }

  clearTableSizeElement() {
    let btn = document.getElementById('inline-btn');
    if (btn && btn.childNodes)
    document.getElementById('inline-btn').innerHTML = '';
  }
  onFloorPlanViewNameChanges(index, eve) {
    this.floorPlan.FloorPlanViews[index].Name = eve.target.innerText;
  }

  setSelectedFloorPlan(eve) {
    let tables = [];
    let lables = [];
    let shapes = [];
    let walls = [];
    this.canvas.clear();
    this.clearTableSizeElement();
    this.selectedFloorPlanId = eve.value;
    let floorPlan = cloneDeep(this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout))[0];
    if (eve.value != -1) {
      tables = cloneDeep(floorPlan.StandaloneTables.filter(x => x.FloorPlanViewId == eve.value));
      lables = cloneDeep(floorPlan.Labels.filter(x => x.FloorPlanViewId == eve.value));
      shapes = cloneDeep(floorPlan.Shapes.filter(x => x.FloorPlanViewId == eve.value));
      walls = cloneDeep(floorPlan.Walls.filter(x => x.FloorPlanViewId == eve.value));
    }
    else {
      tables = cloneDeep(floorPlan.StandaloneTables);
      lables = cloneDeep(floorPlan.Labels);
      shapes = cloneDeep(floorPlan.Shapes);
      walls = cloneDeep(floorPlan.Walls);
    }
    if (tables.length > 0 || lables.length > 0 || shapes.length > 0 || walls.length > 0) {
      this.canvas.clear();
      this.setDefaultFloor(tables, lables, walls, shapes);
      this.generateTableSize();
    }
    else {
      this.canvas.clear();
    }
    let objs = this.canvas.getObjects();
    if (eve.value != -1) {
      objs.forEach(element => {
        element.set({
          selectable: false
        })
      });
    }
    this.fs.selectedTabIndex$.next(0);
    if (this.isGridlinesEnabled) {
      this.setGridLines(this.selectedGridValue);
    }
  }

  dataUpdate() {
    const objs = this.canvas.getObjects();
    objs.forEach(element => {
      if (element.Id) {
        const shape = element.getObjects().filter(x => x.name == 'shape')[0];
        const seats = element.getObjects().filter(x => x.name == 'seat')[0];
        let shapetypetable = shape.get('type');
        const table = this.floorPlan.StandaloneTables.filter(x => x.Id == element.Id)[0]
        table.Height = (shapetypetable == TableShapeType.Image || shapetypetable == ShapeTypeEnum.Image) ? (shape.imgHeight) * element.scaleY : (shape.height) * element.scaleY;
        table.Width = (shapetypetable == TableShapeType.Image || shapetypetable == ShapeTypeEnum.Image) ? (shape.imgWidth) * element.scaleX : (shape.width) * element.scaleX;
        table.scaleX = 1;
        table.scaleY = 1;
        table.Left = element.left - (table.Width / 2);
        table.Top = element.top - (table.Height / 2);
        let tableHeight = (shapetypetable == TableShapeType.Image || shapetypetable == ShapeTypeEnum.Image) ? (shape.imgHeight) * element.scaleY : (shape.height) * element.scaleY;
        let tableWidth = (shapetypetable == TableShapeType.Image || shapetypetable == ShapeTypeEnum.Image) ? (shape.imgWidth) * element.scaleX : (shape.width) * element.scaleX;
        if (shapetypetable == ShapeTypeEnum.Circle) {
          let newtableHeight = tableWidth;
          if (tableHeight > tableWidth) {
            newtableHeight = tableWidth;
          } else {
            newtableHeight = tableHeight;
          }
          table.radius = newtableHeight / 2;
          table.Height = newtableHeight;
          table.Width = newtableHeight;
        } else {
          table.Height = tableHeight;
          table.Width = tableWidth;
        }
        if (shapetypetable == ShapeTypeEnum.Pin) {
          table.radius = table.Width / 2;
        }
        let matrix = shape.calcTransformMatrix();
        table.Left = matrix[4] - (tableWidth / 2);
        table.Top = matrix[5] - (tableHeight / 2);
        table.RotationAngle = element.angle == element.RotationAngle ? element.angle : ( element.angle == 0 ? element.RotationAngle : element.angle);
        table.Angle = table.RotationAngle;
      }
      else if (element.name == 'area') {
        const shape = this.floorPlan.Shapes.filter(x => x.id == element.data)[0];
        if (shape) {
          shape.Left = element.left;
          shape.Top = element.top;
          shape.Height = element.height * element.scaleY;
          shape.Width = element.width * element.scaleX;
        }

      }
      else if (element.name == 'label') {
        const label = this.floorPlan.Labels.filter(x => x.id == element.data)[0];
        if (label) {
          label.Text = element.text;
          let findstringlength = element.text.length;
          let getwidth = 11;
          let getHeight = 11;
          let angles = element.angle;
          if ((angles >= 0 && angles <= 20) || (angles >= 160 && angles <= 200) || (angles >= 340 && angles <= 360)) {
            getwidth = (findstringlength * 10) / 2;
          } else {
            getHeight = (findstringlength * 10) / 2;
          }
          label.Left = element.left - getwidth;
          label.Top = element.top - getHeight;
        }
      }
      else if (element.name == 'wall') {
        const wall = this.floorPlan.Walls.filter(x => x.id == element.data)[0];
        let matrix = [];
        matrix = element.calcTransformMatrix();
        var translatedPoints = element.get('points').map(function (p) {
          return {
            x: p.x - element.pathOffset.x,
            y: p.y - element.pathOffset.y
          };
        });
        for (var i = 0; i < translatedPoints.length; i++) {
          translatedPoints[i].x = matrix[0] * translatedPoints[i].x + matrix[2] * translatedPoints[i].y + matrix[4];
          translatedPoints[i].y = matrix[1] * translatedPoints[i].x + matrix[3] * translatedPoints[i].y + matrix[5];
        }
        let newarray = [];
        translatedPoints.forEach(el => {
          newarray.push({ X: el.x, Y: el.y });
        });
        wall.Points = newarray;
      }
    });
  }

  save() {
    this.fs.isNewlyaddedLayout = false;
    this.fs.selectedType.next('');
    let floorPlan = this.floorPlan;
    this.holdRerender = false;
    this.fs.joinTable = false;
    this.fs.onSelectionCleared.next(true);
    if(this.isBufferTimeEnabled){
    let invalidBufferTime = this.fs.floorPlan.StandaloneTables?.filter(({BufferTimeInMinutes}) => BufferTimeInMinutes && BufferTimeInMinutes % 5);
    if(invalidBufferTime.length){
      let errorMessage = this.ts.instant('Buffer Time should be divisible of 5 for the locations') + ' ' + invalidBufferTime.map(({Name}) => Name).join(',');
      this.ShowErrorNotification(errorMessage);
      return;
    }
  }
    let unAssignedObjects = [];
    const tablesWithoutId = this.floorPlan.StandaloneTables.filter(x => x.FloorPlanViewId == null);
    unAssignedObjects = unAssignedObjects.concat(tablesWithoutId);
    const wallssWithoutId = this.floorPlan.Walls.filter(x => x.FloorPlanViewId == null);
    unAssignedObjects = unAssignedObjects.concat(wallssWithoutId);
    const labelsWithoutId = this.floorPlan.Labels.filter(x => x.FloorPlanViewId == null);
    unAssignedObjects = unAssignedObjects.concat(labelsWithoutId);
    const shapesWithoutId = this.floorPlan.Shapes.filter(x => x.FloorPlanViewId == null);
    unAssignedObjects = unAssignedObjects.concat(shapesWithoutId);
    if (this.isActivityProperty()) {
      floorPlan.StandaloneTables.forEach(x => {
        x.IsAvailableForBooking = true;
        x.IsAvailableForReservations = true;
        x.IsCommunalTable = false;
      })
    }
    if ((tablesWithoutId.length + wallssWithoutId.length + labelsWithoutId.length + shapesWithoutId.length) > 0) {
      this.openPopUp(unAssignedObjects);
    }
    else {
      this.dataUpdate();
      let id = this._layout.FloorPlans.findIndex(x => x.Id == floorPlan.Id);
      if (floorPlan.Dimensions) {
        floorPlan.Dimensions[0] = { height: this.canvas.height, width: this.canvas.width };
      }
      if (id == -1) {
        delete floorPlan['BasedOn'];
        floorPlan.Id = 0;
        floorPlan.JoinedTables.forEach(x => {
          x.Id = 0;
          x.LayoutId = 0;
          x.IsNew = true;
        })
        this.fs.isNewlyaddedLayout = true;
        if (this.nonCommunalTableConversion) {
          this.subscriptions.add(this.fs.completeAllVisits(this.seatedPartiesForNonCommunal).subscribe(val => {
            if (val.State == OperationResultState.Success) {
              this.subscriptions.add(this.fs.createFloor(floorPlan).subscribe(val => {
                if (val.State == OperationResultState.Success) {
                  this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].Id = val.Payload;
                  this.arrayToBeCompared.filter(x => x.Id == this.selectedLayout)[0].Id = val.Payload;
                  this.selectedLayout = val.Payload;
                  this.fs.equal = true;
                  let index = this.arrayToBeCompared.findIndex(x => x.Id == floorPlan.Id);
                  this.arrayToBeCompared.splice(index, 1, cloneDeep(floorPlan));
                  let eve = { value: val.Payload };
                  this.setSelectedLayout(eve);
                  const updateMsg = this.ts.instant('floorplansave');
                  this.ShowNotification(val, updateMsg);
                  this.fs.selectedTabIndex$.next(0);
                }
              }));
            }
          }));
        }
        else {
          this.subscriptions.add(this.fs.createFloor(floorPlan).subscribe(val => {
            if (val.State == OperationResultState.Success) {
              this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].Id = val.Payload;
              this.arrayToBeCompared.filter(x => x.Id == this.selectedLayout)[0].Id = val.Payload;
              this.selectedLayout = val.Payload;
              this.fs.equal = true;
              let index = this.arrayToBeCompared.findIndex(x => x.Id == floorPlan.Id);
              this.arrayToBeCompared.splice(index, 1, cloneDeep(floorPlan));
              let eve = { value: val.Payload };
              this.setSelectedLayout(eve);
              const updateMsg = this.ts.instant('floorplansave');
              this.ShowNotification(val, updateMsg);
              this.fs.selectedTabIndex$.next(0);
            }
          }));
        }
      }
      else {
        if (this.nonCommunalTableConversion) {
          this.subscriptions.add(this.fs.completeAllVisits(this.seatedPartiesForNonCommunal).subscribe(val => {
            if (val.State == OperationResultState.Success) {
              this.subscriptions.add(this.fs.updateFloor(floorPlan, this.ignoreSeatingParties).subscribe(val => {
                if (val.State == OperationResultState.Success) {
                  this.fs.equal = true;
                  if (val.Payload) {
                    this.holdRerender = true;
                    this.showConfirmationForClearingParties(val.Payload);
                  } else {
                    let index = this.arrayToBeCompared.findIndex(x => x.Id == floorPlan.Id);
                    this.arrayToBeCompared.splice(index, 1, cloneDeep(floorPlan));
                    const updateMsg = this.ts.instant('floorplanupdate');
                    this.ShowNotification(val, updateMsg);
                    this.fs.selectedTabIndex$.next(0);
                  }
                }
              }));
            }
          }));
        }
        else {
          this.subscriptions.add(this.fs.updateFloor(floorPlan, this.ignoreSeatingParties).subscribe(val => {
            if (val.State == OperationResultState.Success) {
              this.fs.equal = true;
              this.isInitial = true;
              if (val.Payload) {
                this.holdRerender = true;
                this.showConfirmationForClearingParties(val.Payload);
              } else {
                let index = this.arrayToBeCompared.findIndex(x => x.Id == floorPlan.Id);
                this.arrayToBeCompared.splice(index, 1, cloneDeep(floorPlan));
                const updateMsg = this.ts.instant('floorplanupdate');
                // this.ShowNotification(val, updateMsg);
                this.fs.selectedTabIndex$.next(0);
              }
            }
          }));
        }
      }
      this.ignoreSeatingParties = false;
      this.isPartyMappedToTable = false;
      if (!this.holdRerender) {
        if (this.fs.selectedTableBeforeAction$.value && this.selectedTabIndex == 0) {
          this.setSelectedTableDetails(this.fs.selectedTableBeforeAction$.value);
        } else {
          const tabIndex = { index: 0 }; //defaulting to first Tab
          this.tabChanged(tabIndex);
          this.fs.selectedTabIndex$.next(0);
        }
      }
    }
  }

  showConfirmationForClearingParties(payloadMessage) {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant('deleteOccupiedTableConfirmationPart1') + payloadMessage + this.ts.instant('deleteOccupiedTableConfirmationPart2'),
      dialogTitle: this.ts.instant('confirm'),
      showAlert: true
    }];
    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', '', popUpMessage,
      popUpMessage[0].dialogTitle);
    const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.deleteTable,
      popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeight, false, popUpMessage[0].dialogTitle, 'ok', 'cancel', true);
    let confirmActionSubscription: ISubscription = null;
    let cancelActionSubscription: ISubscription = null;
    confirmActionSubscription = this.popupService.confirmedAction$.subscribe((val) => {
      if (val == ComponentTypes.deleteTable) {
        this.ignoreSeatingParties = true;
        this.isPartyMappedToTable = false;
        this.save();
      }
    });
    cancelActionSubscription = this.popupService.cancelledAction$.subscribe((val) => {
      this.addNewLayoutClicked = false;
      if (val.from == ComponentTypes.deleteTable) {
        this.ignoreSeatingParties = false;
        this.isPartyMappedToTable = true;
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (confirmActionSubscription) { confirmActionSubscription.unsubscribe(); }
      if (cancelActionSubscription) { cancelActionSubscription.unsubscribe(); }
    })
  }

  ShowNotification(response, message) {
    const confirmationMessages = Utilities.getConfirmationMessage(response, message);
    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', confirmationMessages,
      confirmationMessages[0].dialogTitle);
    const okbutton = this.ts.instant('ok');
    const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.commonconfirmmessage,
      popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeight, false, '', okbutton, '', true);
    this.subscriptions.add(dialogRef.afterClosed().subscribe((dialogCloseData) => {
    }));
  }
  ShowErrorNotification(message) {
    const ErrorMessages = [{
      ErrorDetails:{
        ErrorMessage:"",
        Error:[{msg: message}]
      },
      dialogTitle:"Error",
      ConfirmationPopup: true,
      ErrorType:true
    }];
    if (ErrorMessages) {
      const componentInfo = Utilities.setComponentDetails(ExceptionPopupComponent, 'small', 'action', ErrorMessages,
        ErrorMessages[0].dialogTitle);
      const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.resultprocess,
        popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeight, false, '', '', '', false);
      let confirmationSubscription = this.popupService.confirmedAction$.subscribe(() => {
        dialogRef.close();
      });
      this.subscriptions.add(dialogRef.afterClosed().subscribe((dialogCloseData) => {
        if (confirmationSubscription) { confirmationSubscription.unsubscribe(); }
      }));
    }
  }
  openPopUp(value) {
    this.toaster.error("Highlighted objects are not assigned to floorplan", '', {
      timeOut: 3000
    });
    let objs = this.canvas.getObjects()
    value.forEach(element => {
      if (element.Id) {
        let data = objs.filter(x => x.Id == element.Id)[0];
        data._objects.forEach(obj => {
          if (obj.name == 'shape') {
            obj.set({
              stroke: 'red',
              strokeWidth: 2
            })
          }
          if (obj.name == 'seat') {
            obj.set({
              stroke: 'red',
            })
          }
        });
      }
      else {
        let obj = objs.filter(x => x.data == element.id && x.name == element.name)[0];
        if (obj.name == 'label') {
          obj.set({
            fill: 'red'
          });
        }
        else {
          obj.set({
            stroke: 'red'
          })
        }
      }
    });
    this.canvas.renderAll();
  }

  cancel() {
    this.labelsCreated = 0;
    this.fs.selectedType.next('');
    this.fs.equal = true;
    this.holdRerender = false;
    this.isPartyMappedToTable = false;
    this.ignoreSeatingParties = false;
    this.canvas.discardActiveObject();
    if (this.selectedLayout == 0) {
      let index = this.layoutFloorPlans.findIndex(x => x.Id == this.selectedLayout);
      this.layoutFloorPlans.splice(index, 1);
      let indexInArrayTobeCompared = this.arrayToBeCompared.findIndex(x => x.Id == this.selectedLayout);
      this.arrayToBeCompared.splice(indexInArrayTobeCompared, 1);
      this.selectedLayout = cloneDeep(this._layout.FloorPlans.filter(x => x.IsDefault == true)[0].Id);
    }
    let floorPlan = this.getInitialFloorPlan();
    let fpIndex = this.layoutFloorPlans.findIndex(fp => fp.Id == this.selectedLayout);
    this.layoutFloorPlans[fpIndex] = floorPlan;
    this.setFloorData(this.selectedLayout);
    this.fs.selectedTabIndex$.next(0);
    this.fs.selectedTableBeforeAction$.next(null);
    if (this.fs.equal)
    this.fs.applyNewFloorChanges$.next();
  }

  assignToFloor() {
    const objs = this.canvas.getActiveObjects();
    let value;
    this.objectsSelected.forEach(element => {
      if (element.Id != undefined) {
        this.floorPlan.StandaloneTables.filter(x => x.Id == element.Id)[0].FloorPlanViewId = this.fs.selectedFloorId;
      }
      else if (element.name == 'area') {
        value = element.data != null ? element.data : element.id;
        this.floorPlan.Shapes.filter(x => x.id == value)[0].FloorPlanViewId = this.fs.selectedFloorId;
      }
      else if (element.name == 'wall') {
        value = element.data != null ? element.data : element.id;
        this.floorPlan.Walls.filter(x => x.id == value)[0].FloorPlanViewId = this.fs.selectedFloorId;
      }
      else if (element.name == 'label') {
        value = element.data != null ? element.data : element.id;
        this.floorPlan.Labels.filter(x => x.id == value)[0].FloorPlanViewId = this.fs.selectedFloorId;
      }
    });
  }

  setFloorData(eve) {
    this.selectedLayout = eve;
    let data = cloneDeep(this.arrayToBeCompared.filter(x => x.Id == eve)[0]);
    let layout = this.layoutFloorPlans.filter(x => x.Id == eve)[0];
    layout = data;
    const tabIndex = { index: 0 }; //defaulting to first Tab
    this.tabChanged(tabIndex);
    this.floorPlan = data;
    this.fs.floorPlan = this.floorPlan;
    this.floorPlan = this.fs.floorPlan = this.layoutFloorPlans.filter(x => x.Id == eve)[0];
    this.fs.dataChanged$.next('changed');
    this.fs.floorPlan.FloorPlanViews = this.floorPlanViews = this.floorPlan.FloorPlanViews;
    this.floorPlanOptions = this.floorPlanViews.map(x => { return { id: x.Id, value: x.Name } });
    this.joinedTablesArray = this.floorPlan.JoinedTables;
    this.fs.layoutChange$.next(this.selectedLayout);
    this.floorPlanOptions.push({ id: -1, value: this.ts.instant('viewAllFloors') });
    this.config[0].options = this.floorPlanOptions;
    if (!Utilities.controlValidate(controlSettings.Location_layoutEditor_hide_viewBy, this.appService.PropertyType)) {
      this.components.first.form.controls.viewBy.setValue(this.floorPlanOptions.filter(x => x.id == -1)[0].id);
      this.selectedFloorPlanId = this.components.first.form.controls.viewBy.value;
    }
    this.floorPlanSelctionConfig[0].options = this.layoutFloorPlans.map(x => { return { id: x.Id, value: x.Name } });
    this.components.last.form.controls.floorPlan.setValue(this.selectedLayout);
    this.tables = this.floorPlan.StandaloneTables;
    this.labels = this.floorPlan.Labels;
    this.walls = this.floorPlan.Walls;
    this.shapes = this.floorPlan.Shapes;
    this.canvas.clear();
    this.setDefaultFloor(this.tables, this.labels, this.walls, this.shapes);
    this.generateTableSize();
    this.fs.selectedTabIndex$.next(0);
    let index = this.arrayToBeCompared.findIndex(x => x.Id == this.floorPlan.Id);
    this.arrayToBeCompared.splice(index, 1, cloneDeep(this.floorPlan));
  }

  selectedFloor(floor) {
    this.assignFloorPlanId = floor.Id;
  }

  getTableName() {
    let index = 1
    let name = this.tables.length + index;
    while (this.floorPlan.StandaloneTables.some(table => table.Name.toString() === name.toString())) {
      index++;
      name = this.tables.length + index;
    }
    return name;
  }

  addTableGroupAdd(that, object, element, fromclone, data?) {
    let blocked = false;
    that.tableObj = [];
    that.tableObj.push(object);
    if ((element && element.ServerId == null && element.ShapeType !== TableShapeType.Pin) || (!element && (object.type !== TableShapeType.Pin && object.type !== ShapeTypeEnum.Pin))) {
      let lineData = this.layoutFunctions.serverUnAssignedLines(object, data);
      this.tableObj.push(lineData.leftLine);
      this.tableObj.push(lineData.rightLine);
    }
    let statusColorData = element && !fromclone && this.selectedLayout != 0 ? this.layoutFunctions.getStatusColor(element, this.layoutConfig, this.cs.settings.value) : { stroke: '#3B4532', fillColor: element && element.IsCommunalTable ? '#4f483e' : '#f2f2f2', numberColor: '#4f483e', textColor: '#444444', partyData: null, partyColor: 'black' };
    object.set('fill', '#f2f2f2');
    if (element && !element.IsCommunalTable) {
      object.set('fill', statusColorData.fillColor);
    }
    if (object.type == TableShapeType.Pin || object.type == ShapeTypeEnum.Pin) {
      if (element) {
        const seatingType = that.seatingTypes.filter(seatingType => seatingType.id == element.SeatingTypeId);
        seatingType && seatingType.length > 0 ? object.set('fill', seatingType[0].color) : object.set('fill', '#f2f2f2');
      } else {
        object.set('fill', that.seatingTypes.length > 0 ? that.seatingTypes[0].color : '#f2f2f2');
      }
    }
    if ((object.type == TableShapeType.Image || object.type == ShapeTypeEnum.Image)) {
      object.set('stroke', statusColorData.fillColor);
      object.set('strokeWidth', 0);
    } else if (element && element.ImageId && (object.type == TableShapeType.CircleBasic || object.type == ShapeTypeEnum.BasicCircle ||
      object.type == TableShapeType.RectangleBasic || object.type == ShapeTypeEnum.BasicRectangle)) {
      object.set({
        fill: '#fff',
        stroke: '#666',
        strokeWidth: 1
      });
    }
    that.groupedObject = new fabric.Group(that.tableObj, {
      top: object.top,
      left: object.left,
      objectCaching: false,
      originX: "center",
      originY: "center",
      name: 'object'
    });
    if (element) {
      const tableIndex = this.blockingRule.findIndex(x => x == element.Id);
      if (tableIndex != -1 && statusColorData.partyData == null) {
        blocked = true;
        statusColorData.textColor = 'black';
      }
    }
    this.selectedLayout = this.selectedLayout ? this.selectedLayout : this.selectedLayout == 0 ? this.selectedLayout : this._layout.FloorPlans.filter(x => x.IsDefault == true)[0].Id;
    let id;
    if (this.layoutFloorPlans) {
      id = maxBy(this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].StandaloneTables, 'Id');
    } else {
      id = maxBy(that.tables, 'Id');
    }
    const idToBeSet = id ? id['Id'] + 1 : 0;
    const name = element ? element.Name : this.getTableName();
    const imageId = element ? element.ImageId : (object.type == TableShapeType.Image || object.type == ShapeTypeEnum.Image) ? this.fs.customObjectImage.ImageId : null;
    that.groupedObject.set({
      SeatingType: element ? element.SeatingType : this.seatingTypes.length > 0 ? this.seatingTypes[0].value : null,
      SeatingTypeId: element ? element.SeatingTypeId : this.seatingTypes.length > 0 ? this.seatingTypes[0].id : null,
      SeatingArea: element ? element.SeatingArea : this.seatingTypes.length > 0 ? this.setSeatingArea(this.seatingTypes[0].id)[0].Name : this.setSeatingArea(null)[0].value, //this.seatingAreas[0].value,
      SeatingAreaId: element ? element.SeatingAreaId : this.seatingTypes.length > 0 ? this.setSeatingArea(this.seatingTypes[0].id)[0].Id : this.setSeatingArea(null)[0].id, //this.seatingAreas[0].id,
      ServerId: element ? element.ServerId : null,
      MaxPartySize: element ? element.MaxPartySize : (Utilities.controlValidate(controlSettings.Location_layoutEditor_emptyFloor_maxSize_1, this.appService.PropertyType) ? 1 : 2),
      MinPartySize: element ? element.MinPartySize : 1,
      LocationGroupId: element ? element.LocationGroupId : null,
      Name: element ? element.Name : name,
      Price: element ? element.Price : null,
      IsAvailableForBooking: element ? element.IsAvailableForBooking : true,
      IsAvailableForReservations: element ? element.IsAvailableForReservations : true,
      IsCommunalTable: element ? fromclone ? false : element.IsCommunalTable : false,
      FloorPlanViewId: element ? element.FloorPlanViewId : null,
      Id: element ? (fromclone ? idToBeSet : element.Id) : idToBeSet,
      Party: element ? statusColorData.partyData ? statusColorData.partyData : that.setParty(element, blocked) : {},
      radius: element && element.radius > 0 ? element.radius : object.width / 2,
      Angle: element ? element.Angle : 0,
      ShapeType: element ? element.ShapeType : object.type,
      Top: element ? element.Top : object.top,
      Left: element ? element.Left : object.left,
      RotationAngle: element ? element.RotationAngle : 0,
      ImageId: imageId,
      BufferTimeInMinutes: element? element.BufferTimeInMinutes : 0
    });
    if (!element || fromclone) {
      const newTable = {
        Angle: 0,
        FloorPlanViewId: Utilities.controlValidate(controlSettings.Location_layoutEditor_set_default_floorplanViewId, this.appService.PropertyType) ? this.floorPlan.FloorPlanViews[0].Id : null,
        Height: object.height,
        Id: idToBeSet,
        IsAvailableForBooking: true,
        IsAvailableForReservations: fromclone ? element.IsAvailableForReservations : true,
        IsCommunalTable: false,
        IsNew: true,
        Left: that.groupedObject.left,
        MaxPartySize: fromclone ? element.MaxPartySize : ( Utilities.controlValidate(controlSettings.Location_layoutEditor_emptyFloor_maxSize_1, this.appService.PropertyType) ? 1 : 2),
        MinPartySize: fromclone ? element.MinPartySize : 1,
        LocationGroupId: fromclone ? element.LocationGroupId : null,
        Name: name,
        Price: fromclone ? element.Price : null,
        SeatingType: fromclone ? element.SeatingType : this.seatingTypes.length > 0 ? this.seatingTypes[0].value : null,
        SeatingTypeId: fromclone ? element.SeatingTypeId : this.seatingTypes.length > 0 ? this.seatingTypes[0].id : null,
        SeatingArea: fromclone ? element.SeatingArea : this.seatingTypes.length > 0 ? this.setSeatingArea(this.seatingTypes[0].id)[0].Name : this.setSeatingArea(null)[0].value, //this.seatingAreas[0].value,
        SeatingAreaId: fromclone ? element.SeatingAreaId : this.seatingTypes.length > 0 ? this.setSeatingArea(this.seatingTypes[0].id)[0].Id : this.setSeatingArea(null)[0].id, //this.seatingAreas[0].id
        ServerId: null,
        ShapeType: fromclone ? element.ShapeType : object.type,
        Top: that.groupedObject.top,
        Width: object.width,
        fill: 'gray',
        label: null,
        opacity: 1,
        originX: 'center',
        originY: 'center',
        scaleX: 1,
        scaleY: 1,
        stroke: null,
        strokeWidth: 0,
        radius: that.groupedObject.width / 2,
        RotationAngle: fromclone ? element.angle : data ? data.angle : 0,
        rx: null,
        ry: null,
        ImageId: fromclone ? element.ImageId : (object.type == TableShapeType.Image || object.type == ShapeTypeEnum.Image) ? this.fs.customObjectImage.ImageId : null,
        BufferTimeInMinutes: 0
      };
      this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].StandaloneTables.push(newTable);
      this.tables = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].StandaloneTables;
      this.floorPlan = this.fs.floorPlan = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0];
    }
    that.canvas.add(that.groupedObject);
    that.groupedObject.setCoords();

    that.groupedObject.set({
      width: that.groupedObject.width
    })
    if (object.type == 0 || object.type == ShapeTypeEnum.Rectangle) {

      let seatData = that.layoutFunctions.drawRectangleSeat(that.groupedObject, that, '#3B4532');
      seatData.forEach(seat => {
        that.groupedObject.addWithUpdate(seat);
        seat.moveTo(that.groupedObject.size() - 5)
      });
    }
    else if (object.type == 1 || object.type == ShapeTypeEnum.Circle) {

      let seatData = that.layoutFunctions.drawCircleSeat(that.groupedObject, that, '#3B4532');
      seatData.forEach(seat => {
        that.groupedObject.addWithUpdate(seat);
        seat.moveTo(that.groupedObject.size() - 5)
      });
      that.groupedObject.setControlsVisibility({
        ml: false,
        mt: false,
        mr: false,
        mb: false,
      });
    }
    if (object.type == TableShapeType.Image || object.type == ShapeTypeEnum.Image) {
      that.groupedObject.set({
        hasRotatingPoint: true,
        lockScalingX: true,
        lockScalingY: true
      })
    } else if (object.type == TableShapeType.CircleBasic || object.type == ShapeTypeEnum.BasicCircle) {
      that.groupedObject.set({
        hasRotatingPoint: true,
        lockScalingX: true,
        lockScalingY: true
      })
    } else if (object.type == TableShapeType.Pin || object.type == ShapeTypeEnum.Pin) {
      that.groupedObject.set({
        hasRotatingPoint: false
      })
    } else {
      that.groupedObject.set({
        hasRotatingPoint: true
      })
    }
    const text1 = new fabric.Text(that.groupedObject.Name.toString(), {
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 'bold',
      fill: '#fff',
      originX: 'center',
      originY: 'center',
      name: 'text',
      top: that.groupedObject.top,
      left: that.groupedObject.left,
      objectCaching: false,
      angle: fromclone ? -element.angle : 0,
    });
    const numberCircle = new fabric.Circle({
      fill: element && element.IsCommunalTable || (statusColorData.partyData != null) ? statusColorData.fillColor : statusColorData.numberColor,
      radius: 15,
      top: that.groupedObject.top,
      left: that.groupedObject.left,
      originX: 'center',
      originY: 'center',
      name: 'textCircle',
      objectCaching: false,
      opacity: .8
    });
    // if (!this.isInitial) {
    //   if (element && element.RotationAngle != 0) {
    //     that.groupedObject.rotate(element.RotationAngle);
    //   }
    //   if (element && element.RotationAngle) {
    //     that.groupedObject.set({
    //       angle: element.RotationAngle
    //     })
    //   }
    //   else if (data) {
    //     that.groupedObject.rotate(data.angle)
    //   }
    // }
    that.groupedObject.addWithUpdate();
    let pinObject;
    if (object.type == TableShapeType.Pin || object.type == ShapeTypeEnum.Pin) {
      pinObject = new fabric.Text('+', {
        textAlign: 'center',
        fill: '#fff',
        name: 'pin',
        top: that.groupedObject.top,
        left: that.groupedObject.left,
        originX: 'center',
        originY: 'center',
        fontWeight: 'bold',
        objectCaching: false,
        fontSize: object.radius
      });
    }
    if (object.type !== TableShapeType.Pin && object.type !== ShapeTypeEnum.Pin) {
      that.groupedObject.addWithUpdate(numberCircle);
      that.groupedObject.addWithUpdate(text1);
      // that.groupedObject.addWithUpdate(tableNumber);
    } else {
      that.groupedObject.addWithUpdate(pinObject);
    }
    that.count++;
    if ((object.type !== TableShapeType.Pin && object.type !== ShapeTypeEnum.Pin)) {
      text1.moveTo(that.groupedObject.size() - 1);
    }
    if (element && element.scaleX) {
      let tempScaleX = element.scaleX;
      if (tempScaleX == 0 || tempScaleX <= 0.1) {
        tempScaleX = 1;
      }
      that.groupedObject.set({
        scaleX: tempScaleX
      })
    }
    if (element && element.scaleY) {
      let tempScaleY = element.scaleY;
      if (tempScaleY == 0 || tempScaleY <= 0.1) {
        tempScaleY = 1;
      }
      that.groupedObject.set({
        scaleY: tempScaleY
      })
    }
    that.groupedObject.setCoords();
    if (object.type == TableShapeType.Image || object.type == ShapeTypeEnum.Image) {
      if (element && element.Angle) {
        that.groupedObject.set({
          angle: element.Angle
        })
      }
    }
    if(fromclone){
      that.groupedObject.set({
        angle: element.angle
      })
    }
    if (fromclone || !element) {
      that.canvas.setActiveObject(that.groupedObject);
      that.drawObject({ target: that.groupedObject }, that);
    }
  }

  createTableSizeElement(tableNumber, groupedObject, that) {
    let btn = document.getElementById('inline-btn');
    if (document.getElementById(`tableNumber_${groupedObject.Name}_${tableNumber}`)) {
      let tableElement = document.getElementById(`tableNumber_${groupedObject.Name}_${tableNumber}`);
      btn.removeChild(tableElement);
    }
    const tableElement = document.createElement("span");
    const node = document.createTextNode(tableNumber);
    tableElement.id = `tableNumber_${groupedObject.Name}_${tableNumber}`;
    tableElement.className = 'floorplan-editor__tableSize';
    tableElement.appendChild(node);
    
    btn.appendChild(tableElement);
    let tableHeight = 40;
    let tableWidth = 40;
    let absCoords = that.getAbsoluteCoords(groupedObject);
    tableElement.style.position = 'absolute';
    let circleRadius = groupedObject._objects.find(centreCircle => centreCircle.name == 'textCircle')
    tableElement.style.left = groupedObject.left - 10 + 'px'
    tableElement.style.top = groupedObject.top  - (circleRadius.radius * 2.5) + 'px';
    tableElement.style.width = '20px';
    tableElement.style.height = '20px';
    tableElement.style.textAlign = 'center';
  }

  setParty(table, status) {
    let party = [];
    if (table) {
      this.layoutConfig.reservations.forEach((reservation) => {
        if (reservation.TableIds.includes(table.Id)) {
          party.push(reservation);
        }
      });
    }
    return party;
  }

  setDefaultFloor(tables?, labels?, walls?, shapes?) {
    this.tabChanged({ index: 0 });
    const color = '#fff';
    const strokeColor = 'gray';
    const that = this;
    labels.forEach((element, index) => {
      element.id = index;
      element.name = 'label';
    });
    walls.forEach((element, index) => {
      element.id = index;
      element.name = 'wall';
    });
    shapes.forEach((element, index) => {
      element.id = index;
      element.name = 'area';
    });
    tables = cloneDeep(tables);
    labels = cloneDeep(labels);
    walls = cloneDeep(walls);
    shapes = cloneDeep(shapes);
    let basicTables = this.layoutFunctions.findminObject(tables, labels, walls, shapes);
    let topMinValue = minBy(basicTables, 'totalminDataHeight');
    let leftMinValue = minBy(basicTables, 'totalminDataWidth');
    const negativeCoords = basicTables.filter(x => x.totalminDataHeight < 0 || x.totalminDataWidth < 0);
    this.topMinValue = Math.abs(topMinValue['totalminDataHeight']);
    this.leftMinValue = Math.abs(leftMinValue['totalminDataWidth']);
    if (negativeCoords.length > 0  && !this.floorPlan.ImageId) {
      // negative values
      let adjustedTopValue = topMinValue['totalminDataHeight'] < 0 ? Math.abs(topMinValue['totalminDataHeight']) : 0;;
      let adjustedLeftValue = leftMinValue['totalminDataWidth'] < 0 ? Math.abs(leftMinValue['totalminDataWidth']) : 0;
      tables.forEach(element => {
        element.Top = element.Top + Math.abs(adjustedTopValue);
        element.Left = element.Left + Math.abs(adjustedLeftValue);
      });
      labels.forEach(element => {
        element.Top = element.Top + Math.abs(adjustedTopValue);
        element.Left = element.Left + Math.abs(adjustedLeftValue);
      });
      walls.forEach(element => {
        element.Points.forEach(point => {
          point['X'] = point['X'] + adjustedLeftValue;
          point['Y'] = point['Y'] + adjustedTopValue;
        });
      });
      shapes.forEach(element => {
        element.Top = element.Top + Math.abs(adjustedTopValue);
        element.Left = element.Left + Math.abs(adjustedLeftValue);
      });
      // find new min val
      basicTables = this.layoutFunctions.findminObject(tables, labels, walls, shapes);
      topMinValue = minBy(basicTables, 'totalminDataHeight');
      leftMinValue = minBy(basicTables, 'totalminDataWidth');
      this.topMinValue = Math.abs(topMinValue['totalminDataHeight']);
      this.leftMinValue = Math.abs(leftMinValue['totalminDataWidth']);
    }
    let adjustedTopValue = 0;
    let adjustedLeftValue = 0;
    if (this.topMinValue > 50 && this.floorPlan.ImageId == null) {
      adjustedTopValue = -1 * (this.topMinValue - 30);
    }
    if (this.leftMinValue > 50 && this.floorPlan.ImageId == null) {
      adjustedLeftValue = -1 * (this.leftMinValue - 30);
    }
    this.adjustvalueleft = adjustedLeftValue;
    this.adjustvaluetop = adjustedTopValue;

    tables.forEach(element => {
      element.Top = element.Top + (element.Height / 2) + adjustedTopValue;
      element.Left = element.Left + (element.Width / 2) + adjustedLeftValue;
    });
    labels.forEach(element => {
      let findstringlength = element.Text.length;
      let getwidth = 11;
      let getHeight = 11;
      let angles = element.Angle;
      if ((angles >= 0 && angles <= 20) || (angles >= 160 && angles <= 200) || (angles >= 340 && angles <= 360)) {
        getwidth = (findstringlength * 10) / 2;
      } else {
        getHeight = (findstringlength * 10) / 2;
      }
      element.Top = element.Top + adjustedTopValue + getHeight;
      element.Left = element.Left + adjustedLeftValue + getwidth;
    });
    walls.forEach(element => {
      element.Points.forEach(point => {
        point['X'] = point['X'] + adjustedLeftValue;
        point['Y'] = point['Y'] + adjustedTopValue;
      });
    });
    shapes.forEach(element => {
      element.Top = element.Top + adjustedTopValue;
      element.Left = element.Left + adjustedLeftValue;
    });
    basicTables = this.layoutFunctions.findmaxObject(tables, labels, walls, shapes);
    const topValue = maxBy(basicTables, 'totalDataHeight');
    const leftValue = maxBy(basicTables, 'totalDataWidth');
    this.heightValue = topValue['totalDataHeight'] + 25;
    this.widthValue = leftValue['totalDataWidth'] + 25;
    let scaleFactor;
    if (this.canvas) {
      this.scaleFactorY = this.layoutView.nativeElement.clientHeight / this.heightValue;
      this.scaleFactorX = this.layoutView.nativeElement.clientWidth / this.widthValue;
      if (this.scaleFactorX < 1 && this.scaleFactorY < 1) {
        scaleFactor = this.scaleFactorX <= this.scaleFactorY ? this.scaleFactorX : this.scaleFactorY;
      }
      else {
        scaleFactor = this.scaleFactorX >= this.scaleFactorY ? this.scaleFactorY : this.scaleFactorX;
      }
      this.scaleFactor = scaleFactor;
      this.canvas.setHeight(this.layoutView.nativeElement.clientHeight);
      this.canvas.setWidth(this.layoutView.nativeElement.clientWidth);
    }
    tables.forEach(element => {
      if (element.ShapeType != undefined) {

        let type;
        switch (element.ShapeType) {
          case 0:
            type = ShapeTypeEnum.Rectangle
            break;
          case 1:
            type = this.layoutFunctions.checkTableiscircle(element);
            break;
          case 2:
            type = ShapeTypeEnum.Pin;
            break;
          case 3:
            type = ShapeTypeEnum.BasicRectangle
            break;
          case 4:
            type = ShapeTypeEnum.BasicCircle;
            break;
          case 8:
            const images = this.customImages.filter((img) => img.ImageId == element.ImageId);
            if (images && images.length == 1) {
              type = ShapeTypeEnum.Image;
            }
            else {
              type = ShapeTypeEnum.BasicRectangle //temp fix
            }
            break;
        }
        let adjustheightval = element.Height < 15 ? 15 : element.Height;
        let adjustwidthtval = element.Width < 15 ? 15 : element.Width;

        if (type == 'rect') {
          this.rectangle = new fabric.Rect({
            left: element.Left,
            top: element.Top,
            height: adjustheightval,
            width: adjustwidthtval,
            rx: 2,
            ry: 2,
          });
          this.rectangle.set({
            name: 'shape',
          });

          this.addTableGroupAdd(this, this.rectangle, element, false);
        }
        else if (type == 'circle') {
          this.circle = new fabric.Circle({
            left: element.Left,
            top: element.Top,
            height: adjustheightval,
            width: adjustwidthtval,
            radius: (adjustwidthtval / 2) > 15 ? (adjustwidthtval / 2) : 15,
          });
          this.circle.set({
            name: 'shape',
          });
          this.circle.setControlsVisibility({
            'ml': false,
            'mt': false,
            'mr': false,
            'mb': false,
          });
          this.addTableGroupAdd(this, this.circle, element, false);
        }
        else if (type == ShapeTypeEnum.Ellipse) {
          this.ellipse = new fabric.Ellipse({
            left: element.Left,
            top: element.Top,
            rx: element.Width / 2,
            ry: element.Height / 2
          });
          this.ellipse.set({
            name: 'shape',
          });
          this.ellipse.setControlsVisibility({

            'ml': false,
            'mt': false,
            'mr': false,
            'mb': false,

          });
          this.addTableGroupAdd(this, this.ellipse, element, false);
        } else if (type == ShapeTypeEnum.BasicRectangle) {
          this.basicRectangle = new fabric.Rect({
            left: element.Left,
            top: element.Top,
            height: adjustheightval,
            width: adjustwidthtval,
            rx: 2,
            ry: 2
          });
          this.basicRectangle.set({
            name: 'shape',
            type: 'brect'
          });
          this.addTableGroupAdd(this, this.basicRectangle, element, false);
        } else if (type == ShapeTypeEnum.BasicCircle) {
          this.basicCircle = new fabric.Circle({
            left: element.Left,
            top: element.Top,
            height: adjustheightval,
            width: adjustwidthtval,
            radius: this.bCircleRadius,
            strokeWidth: 1,
          });
          this.basicCircle.set({
            name: 'shape',
            type: 'bcircle'
          });
          this.basicCircle.setControlsVisibility({
            'ml': false,
            'mt': false,
            'mr': false,
            'mb': false,
          });
          this.addTableGroupAdd(this, this.basicCircle, element, false);
        } else if (type == ShapeTypeEnum.Pin) {
          this.pin = new fabric.Circle({
            left: element.Left,
            top: element.Top,
            height: adjustheightval,
            width: adjustwidthtval,
            radius: (adjustwidthtval / 2) > 15 ? (adjustwidthtval / 2) : 15
          });
          this.pin.set({
            name: 'shape',
            type: 'pin'
          });
          this.pin.setControlsVisibility({
            'ml': false,
            'mt': false,
            'mr': false,
            'mb': false,
          });
          this.addTableGroupAdd(this, this.pin, element, false);
        } else if (type == ShapeTypeEnum.Image) {
          const image = document.createElement('img');
          const images = this.customImages.filter((img) => img.ImageId == element.ImageId);
          if (images && images.length == 1) {
            image.src = 'data:image/png;base64,' + images[0].bytes;
            this.image = new fabric.Image(image, {
              left: element.Left,
              top: element.Top,
              scaleX: adjustwidthtval / image.width,
              scaleY: adjustheightval / image.height
            })
            this.image.set({
              name: 'shape',
              type: 'image',
              imgWidth: adjustwidthtval,
              imgHeight: adjustheightval
            });
            this.image.setControlsVisibility({
              'ml': false,
              'mt': false,
              'mr': false,
              'mb': false,
            });
            this.addTableGroupAdd(this, this.image, element, false);
          }
        }
      }
    });
    if (labels) {
      labels.forEach((element, index) => {
        this.addLabel(element, index);
      });
    }
    if (shapes) {
      shapes.forEach((element, index) => {
        this.addShape(element, index);
      });

    }
    if (walls) {
      walls.forEach((element, index) => {
        this.addWall(element, index);
      });
    }
    const objs = this.canvas.getObjects();
    let _that = this;
    tables.forEach(table => {
      this.layoutFunctions.setTableIcons(table, objs, that.canvas, that.blockingRule);
      // if (this.isInitial) {
        let canvasObject = objs.filter(canvaObj => canvaObj.Id == table.Id);
        if (canvasObject.length && table.RotationAngle != 0) {
          canvasObject[0].rotate(table.RotationAngle);
          canvasObject[0]._objects.forEach(element => {
            if (element.name == 'textCircle' || element.name == 'text') {
              element.set({
                angle: -table.RotationAngle
              })
            }
          });
        }
      // }
    });
    if (this.floorPlan.ImageId) {
      if (this.layoutImages.length > 0) {
        this.setBackgroundImage();
      }
      if (this.floorPlan.Dimensions && this.floorPlan.Dimensions.length > 0 && this.floorPlan.Dimensions[0].Width > 0 && this.floorPlan.Dimensions[0].Height > 0) {
        this.scaleFactorX = this.layoutView.nativeElement.clientWidth / this.floorPlan.Dimensions[0].Width;
        this.scaleFactorY = this.layoutView.nativeElement.clientHeight / this.floorPlan.Dimensions[0].Height;
        this.canvas.getObjects().forEach(element => {
          element.set({
            scaleX: element.scaleX * _that.scaleFactorX,
            scaleY: element.scaleY * _that.scaleFactorY,
            top: element.top * _that.scaleFactorY,
            left: element.left * _that.scaleFactorX
          })
          element.setCoords();
        });
      }
      else {
        this.layoutFunctions.scaleObjects(this.canvas, this.scaleFactorX, this.scaleFactorY, scaleFactor, this.floorPlan.BasedOn)
      }
    } else {
      this.layoutFunctions.scaleObjects(this.canvas, this.scaleFactorX, this.scaleFactorY, scaleFactor, this.floorPlan.BasedOn);
    }
    objs.forEach(object => {
      object.set({
        lockMovementX: false,
        lockMovementY: false,
        hasBorders: false,
      })
      const isTableOccupied = this.layoutFunctions.checkTableIsOccupied(object, this.layoutConfig);
      if (object.ImageId && (object.ShapeType == TableShapeType.RectangleBasic || object.ShapeType == TableShapeType.CircleBasic)) {
        this.setObjectImage(object);
      }
      if (isTableOccupied && object.ImageId && object.ShapeType == TableShapeType.Image) {
        this.setImageBorder(object);
      }
    });
  }

  setImageBorder(table) {
    let statusColorData = this.layoutFunctions.getStatusColor(table, this.layoutConfig, this.layoutConfig.settings);
    let imageRectangleObject = new fabric.Rect({
      width: table.width + 10,
      height: table.height + 10,
      originX: 'center',
      originY: 'center',
      name: 'imageBorder',
      top: table.top,
      left: table.left,
      stroke: statusColorData.fillColor,
      strokeWidth: 3,
      fill: '#fff',
      scaleX: table.scaleX,
      scaleY: table.scaleY,
    });
    table.addWithUpdate(imageRectangleObject);
    imageRectangleObject.moveTo(0);
  }

  setObjectImage(table) {
    let selectedTableObject = this.canvas.getObjects().filter(tableObj => tableObj.Id == table.Id);
    let tableImage = this.objectImages.filter((img) => img.ImageId == table.ImageId);
    let statusColorData = this.layoutFunctions.getStatusColor(table, this.layoutConfig, this.layoutConfig.settings);
    let isTableOccupied = this.layoutFunctions.checkTableIsOccupied(selectedTableObject[0], this.layoutConfig);
    if (selectedTableObject && selectedTableObject.length > 0 && tableImage.length > 0) {
      let compressedPhoto;
      fabric.Image.fromURL('data:image/png;base64,' + tableImage[0].bytes, (img) => {
        compressedPhoto = img;
        let tableObject;
          let shapedObject = selectedTableObject[0]._objects.filter(ele => ele.name == 'shape')[0];
          if (selectedTableObject[0].ShapeType == 4) {
              compressedPhoto.set({
                  top: selectedTableObject[0].top ,
                  left: selectedTableObject[0].left ,
                  clipTo: (ctx) => {
                      ctx.arc(0, 0, selectedTableObject[0].radius*(img.width/shapedObject.width), 0, Math.PI * 2, true);
                  },
                  originX: 'center',
                  originY: 'center',
                  name: 'image',
                  scaleX: ((shapedObject.width) / img.width) * (selectedTableObject[0].scaleX),
                  scaleY: ((shapedObject.height) / img.height) * (selectedTableObject[0].scaleY),
              });
              if (isTableOccupied) {
                tableObject = new fabric.Ellipse({
                  rx: (selectedTableObject[0].width/2) +10 ,
                  ry: (selectedTableObject[0].height/2) + 10,
                  name: 'seatObject',
                  fill: '#fff',
                  stroke: statusColorData.fillColor,
                  strokeWidth: 3,
                  top: selectedTableObject[0].top,
                  left: selectedTableObject[0].left,
                  originX: 'center',
                  originY: 'center',
                  scaleX:selectedTableObject[0].scaleX,
                  scaleY:selectedTableObject[0].scaleY,
                });
              }
          } else {
              compressedPhoto.set({
                  scaleX: ((shapedObject.width) / img.width) * (selectedTableObject[0].scaleX),
                  scaleY: ((shapedObject.height) / img.height) * (selectedTableObject[0].scaleY),
                  top: selectedTableObject[0].top ,
                  left: selectedTableObject[0].left ,
                  originX: 'center',
                  originY: 'center',
                  name: 'image',
                  width: img.width,
                  height: img.height
              });
              if (isTableOccupied) {
                tableObject = new fabric.Rect({
                  width: selectedTableObject[0].width + 10,
                  height: selectedTableObject[0].height + 10,
                  originX: 'center',
                  originY: 'center',
                  name: 'imageBorder',
                  top: selectedTableObject[0].top,
                  left: selectedTableObject[0].left,
                  stroke: statusColorData.fillColor,
                  strokeWidth: 3,
                  fill: '#fff',
                  scaleX:selectedTableObject[0].scaleX,
                  scaleY:selectedTableObject[0].scaleY,
                })
          }
        }
        let allObjects = selectedTableObject[0]._objects.filter(ele => ele.name == 'textCircle' ||
          ele.name == 'tableNumber' || ele.name == 'text' || ele.name == 'serverLines').length;
        let objectPositionLength = allObjects > 3 ? (allObjects + 1) : allObjects;
        selectedTableObject[0].addWithUpdate(compressedPhoto);
        compressedPhoto.moveTo(selectedTableObject[0].size() - objectPositionLength);
        if (tableObject) {
          selectedTableObject[0].addWithUpdate(tableObject);
          tableObject.moveTo(0);
        }
        selectedTableObject[0].setCoords();
        this.canvas.renderAll();
      })
    }
  }

  setBackgroundImage() {
    let selectedImage = this.layoutImages.filter((img: InputImages) => img.ImageId === this.floorPlan.ImageId);
    if (selectedImage && selectedImage.length > 0) {
      this.canvas.setBackgroundImage('', this.canvas.renderAll.bind(this.canvas));
      fabric.Image.fromURL('data:image/png;base64,' + selectedImage[0].bytes, (img) => {

        this.canvas.setBackgroundImage(img,
          this.canvas.renderAll.bind(this.canvas), {
          scaleX: ((this.canvas.width) / img.width),
          scaleY: ((this.canvas.height) / img.height)
        });
      });
    }
  }

  setCanvasDimensions(objects, val, scaleFactor) {
    for (let i in objects) {
      let scaleX = objects[i].scaleX;
      let scaleY = objects[i].scaleY;
      let left = objects[i].left;
      let top = objects[i].top;

      let tempScaleX = scaleX * scaleFactor;
      let tempScaleY = scaleY * scaleFactor;
      let tempLeft = left * scaleFactor;
      let tempTop = top * scaleFactor;
      objects[i].scaleX = tempScaleX;
      objects[i].scaleY = tempScaleY;
      if (val == 'left' || val == 'both') {
        objects[i].left = tempLeft;
      }
      if (val == 'top' || val == 'both') {
        objects[i].top = tempTop;
      }
      objects[i].setCoords();
    }
    this.canvas.renderAll();
  }

  setCanvasDimensions1(tables, val) {
    tables = this.canvas.getObjects();
    const negativeCoords = tables.filter(x => x.top < 0 || x.left < 0);
    if (negativeCoords.length > 0) {
      let basicTables = cloneDeep(tables);

      const topMinValue = minBy(basicTables, 'top');
      const leftMinValue = minBy(basicTables, 'left');
      this.topMinValue = Math.abs(topMinValue['top']) + topMinValue['height'] / 2 + 14;
      this.leftMinValue = Math.abs(leftMinValue['left']) + leftMinValue['width'] / 2 + 14;
      tables.forEach(element => {
        element.set('top', element.top + Math.abs(this.topMinValue));
        element.set('left', element.left + Math.abs(this.leftMinValue));
      });
      basicTables = cloneDeep(tables);
      basicTables.forEach(element => {
        element.set('totalDataHeight', (element.height) + Math.abs(element.top));
        element.set('totalDataWidth', (element.width) + Math.abs(element.left));
      });
      const topValue = maxBy(basicTables, 'totalDataHeight');
      const leftValue = maxBy(basicTables, 'totalDataWidth');
      this.heightValue = topValue['totalDataHeight'];
      this.widthValue = leftValue['totalDataWidth'];
    }
    else {
      const ff = cloneDeep(tables);
      const topMinValue = minBy(tables, 'top');
      const leftMinValue = minBy(tables, 'left');
      let adjustedTopValue = 0;
      let adjustedLeftValue = 0;
      if ((topMinValue['top'] - topMinValue['height'] / 2 - 14) < 0) {
        adjustedTopValue = Math.abs(topMinValue['top'] - topMinValue['height'] / 2 - 14);
      }
      if ((leftMinValue['left'] - leftMinValue['width'] / 2 - 7) < 0) {
        adjustedLeftValue = Math.abs(leftMinValue['left'] - leftMinValue['width'] / 2 - 14);
      }
      tables.forEach(element => {
        element.set('top', element.top + adjustedTopValue);
        element.set('left', element.left + adjustedLeftValue);
        element.set('totalDataHeight', element.height + element.top);
        element.set('totalDataWidth', element.width + element.left);
      });
      const topValue = maxBy(tables, 'totalDataHeight');
      const leftValue = maxBy(tables, 'totalDataWidth');

      this.heightValue = topValue['totalDataHeight'];
      this.widthValue = leftValue['totalDataWidth'];
    }
    const height = this.layoutView.nativeElement.clientHeight > this.heightValue ? this.layoutView.nativeElement.clientHeight : this.heightValue;
    const width = this.layoutView.nativeElement.clientWidth > this.widthValue ? this.layoutView.nativeElement.clientWidth : this.widthValue;
    let scaleFactor = 1;
    if (this.canvas) {
      let scaleFactorY = this.layoutView.nativeElement.clientHeight / this.heightValue;
      let scaleFactorX = this.layoutView.nativeElement.clientWidth / this.widthValue;
      if (scaleFactorX < 1 && scaleFactorY < 1) {
        scaleFactor = scaleFactorX <= scaleFactorY ? scaleFactorX : scaleFactorY;
      }
      else {
        scaleFactor = scaleFactorX >= scaleFactorY ? scaleFactorY : scaleFactorX;
      }
      this.canvas.setHeight(height * scaleFactorY);
      this.canvas.setWidth(width * scaleFactorX);
    }
    let objects = this.canvas.getObjects();
    for (let i in objects) {
      objects[i].scaleY = objects[i].scaleY / this.initialScaleFactor;
      objects[i].scaleX = objects[i].scaleX / this.initialScaleFactor;
      let scaleX = objects[i].scaleX;
      let scaleY = objects[i].scaleY;
      let left = objects[i].left;
      let top = objects[i].top;

      let tempScaleX = scaleX * scaleFactor;
      let tempScaleY = scaleY * scaleFactor;
      let tempLeft = left * scaleFactor;
      let tempTop = top * scaleFactor;

      objects[i].scaleX = tempScaleX;
      objects[i].scaleY = tempScaleY;
      if (val == 'left' || val == 'both') {
        objects[i].left = objects[i].left / this.initialScaleFactor;
        objects[i].left = tempLeft;
      }
      else if (val == 'top' || val == 'both') {
        objects[i].top = objects[i].top / this.initialScaleFactor;
        objects[i].top = tempTop;
      }
      objects[i].setCoords();
    }
    this.initialScaleFactor = cloneDeep(scaleFactor);
    this.canvas.renderAll();
  }

  setCanvasObjectsScale(factor) {
    let objects = this.canvas.getObjects().filter(x => x.name != 'line')
    for (let i in objects) {
      objects[i].scaleY = objects[i].scaleY / this.initialScaleFactor;
      objects[i].scaleX = objects[i].scaleX / this.initialScaleFactor;
      objects[i].left = objects[i].left / this.initialScaleFactor;
      objects[i].top = objects[i].top / this.initialScaleFactor;
      let scaleX = objects[i].scaleX;
      let scaleY = objects[i].scaleY;
      let left = objects[i].left;
      let top = objects[i].top;
      let tempScaleX = scaleX * factor;
      let tempScaleY = scaleY * factor;
      let tempLeft = left * factor;
      let tempTop = top * factor;
      objects[i].scaleX = tempScaleX;
      objects[i].scaleY = tempScaleY;
      objects[i].left = tempLeft;
      objects[i].top = tempTop;
      objects[i].setCoords();
    }
    this.initialScaleFactor = cloneDeep(factor);
    this.canvas.renderAll();
  }

  calculateDimensionsForCanvas() {
    this.heightValue = this.canvas.getHeight();
    this.widthValue = this.canvas.getWidth();
    let scaleFactorY = this.layoutView.nativeElement.clientHeight / this.heightValue;
    let scaleFactorX = this.layoutView.nativeElement.clientWidth / this.widthValue;
    let scaleFactor = scaleFactorX >= scaleFactorY ? scaleFactorY : scaleFactorX;
    let objects = this.canvas.getObjects();
    for (let i in objects) {
      if (objects[i].name != 'line') {
        let scaleX = objects[i].scaleX;
        let scaleY = objects[i].scaleY;
        let left = objects[i].left;
        let top = objects[i].top;

        let tempScaleX = scaleX * scaleFactor;
        let tempScaleY = scaleY * scaleFactor;
        let tempLeft = left * scaleFactor;
        let tempTop = top * scaleFactor;

        objects[i].scaleX = tempScaleX;
        objects[i].scaleY = tempScaleY;
        objects[i].left = tempLeft;
        objects[i].top = tempTop;

        objects[i].setCoords();
      }
    }
    this.canvas.renderAll();
  }


  action(eve) {
    switch (eve.actionName) {
      case EditorStatus.AddNewFloor:
        this.addNewFloor();
        break;
      case EditorStatus.AssignTables:
        this.assignToFloor();
        break;
      case EditorStatus.DeleteTable:
        this.deleteObject();
        break;
      case EditorStatus.AddJoinTables:
        this.JoinTable();
        break;
      case EditorStatus.UpdateJoinTable:
        this.updateJoinTable();
        break;
      case EditorStatus.NewJoinedTables:
        this.addJointable();
        break;
      case EditorStatus.CancelJoinTables:
        this.cancelJoinTable();
        break;
      case EditorStatus.CloneTable:
        this.cloneTable();
        break;
    }
  }

  addLabel(obj, index) {
    obj.id = index;
    obj.name = 'label';
    this.canvas.add(new fabric.Text(obj.Text.toString(), {
      fontFamily: 'Roboto Light',
      left: obj.Left,
      top: obj.Top,
      fontSize: 20,
      fontWeight: 'bold',
      fill: '#0000008A',
      data: index,
      name: 'label',
      angle: obj.Angle,
      originX: 'center',
      originY: 'center'
    }));

    this.canvas.renderAll();
  }
  addShape(element, index) {
    element.id = index;
    element.name = 'area';
    let shape;
    let newcolor = element.Color ? "rgba(" + element.Color.R + "," + element.Color.G + "," + element.Color.B + "," + element.Color.A + ")" : '#3B4532'
    if (element.ShapeType != undefined) {
      let type;
      switch (element.ShapeType) {
        case 0:
          type = ShapeTypeEnum.Rectangle
          break;
        case 1:
          type = this.layoutFunctions.checkTableiscircle(element);
          break;
        case 2:
          type = this.layoutFunctions.checkTableiscircle(element);
          break
      }
      if (type == ShapeTypeEnum.Rectangle) {
        shape = new fabric.Rect({
          left: element.Left,
          top: element.Top,
          height: element.Height,
          width: element.Width,
          stroke: newcolor.toString(),
          strokeWidth: 2,
          strokeDashArray: [8, 3],
          data: index,
          name: 'area',
          fill: 'transparent',
          angle: element.Rotation,
          perPixelTargetFind: true,
        });
      } else if (type == ShapeTypeEnum.Circle) {

        shape = new fabric.Circle({
          radius: (element.Width > element.Height ? element.Height : element.Width) / 2,
          left: element.Left,
          top: element.Top,
          height: element.Height,
          width: element.Width,
          stroke: newcolor,
          strokeWidth: 2,
          strokeDashArray: [8, 3],
          data: index,
          angle: element.Rotation,
          name: 'area',
          fill: 'transparent',
          perPixelTargetFind: true,
        });
      } else if (type == ShapeTypeEnum.Ellipse) {

        shape = new fabric.Ellipse({
          left: element.Left,
          top: element.Top,
          rx: element.Width / 2,
          ry: element.Height / 2,
          stroke: newcolor,
          strokeWidth: 2,
          strokeDashArray: [8, 3],
          data: index,
          angle: element.Rotation,
          name: 'area',
          fill: 'transparent',
          perPixelTargetFind: true,
        });

      }
    }
    this.canvas.add(shape)
    this.canvas.sendToBack(shape);
    this.canvas.renderAll();
  }

  addWall(element, index) {
    element.id = index;
    element.name = 'wall'
    let linesArray = []
    element.Points.forEach(point => {
      linesArray.push({ x: point.X, y: point.Y })
    });

    let wall = new fabric.Polyline(linesArray, {
      stroke: '#3B4532',
      strokeWidth: 2,
      fill: 'transparent',
      data: index,
      name: 'wall',
      perPixelTargetFind: true
    });
    this.canvas.add(wall)
    this.canvas.sendToBack(wall);
    this.canvas.renderAll();
  }

  addNewLabel() {
    let labelsAvailable = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].Labels;
    let labels = labelsAvailable.length > 0 ? maxBy(labelsAvailable, 'id') : { id: 0 };
    const newText = {
      Text: this.ts.instant('newLabel'),
      Left: 560 + (this.labelsCreated * 20),
      Top: 320 + (this.labelsCreated * 20),
      FloorPlanViewId: null,
      id: labels['id'] + 1,
      name: "label",
      Angle: 0
    };

    let newTextLabel = new fabric.Text(this.ts.instant('newLabel'), {
      fontFamily: 'Roboto Light',
      left: 560 + (this.labelsCreated * 20),
      top: 320 + (this.labelsCreated * 20),
      fontSize: 20,
      fontWeight: 'bold',
      fill: '#0000008A',
      lockScalingX: true,
      lockScalingY: true,
      data: labels['id'] + 1,
      name: "label",
      angle: 0,
      originX: 'center',
      originY: 'center',
    });
    newTextLabel.set({
      hasControls: true,
      hasRotatingPoint: true,
      hasBorders: true
    });
    newTextLabel.setControlsVisibility({
      ml: false,
      mt: false,
      mb: false,
      mr: false,
      tl: false,
      tr: false,
      bl: false,
      br: false
    });
    this.canvas.add(newTextLabel);
    this.canvas.setActiveObject(newTextLabel);
    this.canvas.renderAll();
    this.labelsCreated++;
    this.fs.labelSelected$.next(newText);
    this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].Labels.push(newText);
    this.floorPlan = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0];
    this.fs.labelSettingsForm.controls.labelText.setValue(newText.Text);
    this.dashboardData.actions = [
      {
        type: buttonTypes.actionSecondary, label: 'deleteText', actionName: EditorStatus.DeleteTable,
        customclass: 'action-btn'
      },
      {
        type: buttonTypes.actionPrimary, label: 'cloneText',
        actionName: EditorStatus.CloneTable, customclass: 'action-btn'
      }
    ];
    this.dashboardData = { ...this.dashboardData };
  }

  addNewFloor() {
    const id = this.getNextFloorPlanViewId();
    this.floorPlan.FloorPlanViews.push({ Id: id, Name: this.getFloorplanName() });
    this.floorPlan.StandaloneTables.filter(table => table.FloorPlanViewId == null).forEach(tableValue => {
      tableValue.FloorPlanViewId = id;
    });
    this.floorPlan.Shapes.filter(table => table.FloorPlanViewId == null).forEach(tableValue => {
      tableValue.FloorPlanViewId = id;
    });
    this.floorPlan.Labels.filter(table => table.FloorPlanViewId == null).forEach(tableValue => {
      tableValue.FloorPlanViewId = id;
    });
    this.floorPlan.Walls.filter(table => table.FloorPlanViewId == null).forEach(tableValue => {
      tableValue.FloorPlanViewId = id;
    });
    this.fs.floorPlan.FloorPlanViews = this.floorPlan.FloorPlanViews;
    this.config[0].options = this.fs.floorPlan ? this.fs.floorPlan.FloorPlanViews.map(x => { return { id: x.Id, value: x.Name } }) : [];
    this.config[0].options.push({ id: -1, value: this.ts.instant('viewAllFloors') });
    this.fs.dataChanged$.next('changed');
  }

  getInitialFloorPlan() {
    let floorPlan = cloneDeep(this.layoutData.layout.FloorPlans.filter(x => x.Id == this.selectedLayout));
    return floorPlan[0];
  }

  getNextFloorPlanViewId() {
    let floorPlanViewsList = [];
    floorPlanViewsList = cloneDeep(this.floorPlan.FloorPlanViews);
    this.arrayToBeCompared.forEach(function (layout) { layout.FloorPlanViews.forEach(function (floorPlan) { floorPlanViewsList.push(floorPlan) }) });
    let maxId = maxBy(floorPlanViewsList, 'Id').Id;
    return ++maxId;
  }

  getFloorplanName() {
    let index = 1;
    let floorName = "New Floor " + index;
    while (this.floorPlan.FloorPlanViews.some(f => f.Name == floorName)) {
      index++;
      floorName = "New Floor " + index;
    }
    return floorName;
  }

  cloneTable() {
    this.Copy();
  }

  Copy() {
    let that = this;
    that._clipboard = that.canvas.getActiveObject();
    this.Paste();
  }

  Paste() {
    let that = this;
    this.count = this.canvas.getObjects().length;
    let clonedObj = cloneDeep(that._clipboard);
    that.canvas.discardActiveObject();
    clonedObj.set({
      id: null,
      evented: true,
      Name: that.getTableName().toString()
    });
    const color = '#f2f2f2';
    const strokeColor = 'gray';
    if (clonedObj.type === 'activeSelection') {
      clonedObj.canvas = that.canvas;
      clonedObj.forEachObject(function (obj, index) {
        if (obj.Id) {
          let type;
          switch (obj.ShapeType) {
            case 0:
              type = ShapeTypeEnum.Rectangle
              break;
            case 1:
              type = ShapeTypeEnum.Circle
              break;
            case 2:
              type = ShapeTypeEnum.Pin;
              break
            case 3:
              type = ShapeTypeEnum.BasicRectangle
              break;
            case 4:
              type = ShapeTypeEnum.BasicCircle;
              break;
            case 8:
              type = ShapeTypeEnum.Image;
              break;
          }
          obj.set({
            ServerId: null,
            Name: that.getTableName().toString(),
            FloorPlanViewId: null,
            type: obj.ShapeType,
            Price: obj.Price,
            SeatingArea: obj.SeatingAreaId,
            SeatingType: obj.SeatingTypeId,
            angle: obj.angle
          })
          let shape = obj._objects.filter(x => x.name == 'shape')[0];
          if (type == ShapeTypeEnum.Rectangle) {
            let rectangle = new fabric.Rect({
              left: that.canvas._previousPointer.x + (index * 10),
              top: that.canvas._previousPointer.y + (index * 10),
              width: shape.width,
              height: shape.height,
              fill: color,
              rx: 2,
              ry: 2
            });
            rectangle.set({
              name: 'shape',
            });
            that.addTableGroupAdd(that, rectangle, obj, true)
          } else if (type == ShapeTypeEnum.Circle) {
            let circle = new fabric.Circle({
              left: that.canvas._previousPointer.x + (index * 10),
              top: that.canvas._previousPointer.y + (index * 10),
              width: shape.width,
              height: shape.height,
              radius: shape.width / 2,
              fill: color
            });
            circle.set({
              name: 'shape',
            });
            circle.setControlsVisibility({

              ml: false,
              mt: false,
              mr: false,
              mb: false,

            });
            that.addTableGroupAdd(that, circle, obj, true);
            for (var i = 0; i < obj._objects.length; i++) {
              if (obj._objects[i].name == 'text') {
                obj._objects[i].set({ 'text': obj.Name })
              }
            }
          } else if (type == ShapeTypeEnum.BasicRectangle) {
            let basicRectangle = new fabric.Rect({
              left: that.canvas._previousPointer.x + (index * 10),
              top: that.canvas._previousPointer.y + (index * 10),
              width: shape.width,
              height: shape.height,
              fill: color,
              rx: 2,
              ry: 2
            });
            basicRectangle.set({
              name: 'shape',
              type: 'brect'
            });
            const tableObjectDetails = this.floorPlan.StandaloneTables.filter(x => x.Id == obj.Id);
            if (obj.ImageId == null && tableObjectDetails && tableObjectDetails.length > 0) {
              obj.ImageId = tableObjectDetails[0].ImageId;
            } else {
              obj.ImageId = clonedObj.ImageId
            }
            that.addTableGroupAdd(that, basicRectangle, obj, true)
          } else if (type == ShapeTypeEnum.BasicCircle) {
            let basicCircle = new fabric.Circle({
              left: that.canvas._previousPointer.x + (index * 10),
              top: that.canvas._previousPointer.y + (index * 10),
              width: shape.width,
              height: shape.height,
              radius: that.bCircleRadius,
              fill: color
            });
            basicCircle.set({
              name: 'shape',
              type: 'bcircle'
            });
            basicCircle.setControlsVisibility({
              ml: false,
              mt: false,
              mr: false,
              mb: false,
            });
            const tableObjectDetails = this.floorPlan.StandaloneTables.filter(x => x.Id == obj.Id);
            if (obj.ImageId == null && tableObjectDetails && tableObjectDetails.length > 0) {
              obj.ImageId = tableObjectDetails[0].ImageId;
            } else {
              obj.ImageId = clonedObj.ImageId
            }
            that.addTableGroupAdd(that, basicCircle, obj, true);
            for (var i = 0; i < obj._objects.length; i++) {
              if (obj._objects[i].name == 'text') {
                obj._objects[i].set({ 'text': obj.Name })
              }
            }
          } else if (type == ShapeTypeEnum.Pin) {
            let pin = new fabric.Circle({
              left: that.canvas._previousPointer.x + (index * 10),
              top: that.canvas._previousPointer.y + (index * 10),
              width: shape.width,
              height: shape.height,
              radius: shape.width / 2,
              fill: color
            });
            pin.set({
              name: 'shape',
              type: 'pin'
            });
            pin.setControlsVisibility({
              ml: false,
              mt: false,
              mr: false,
              mb: false,
            });
            that.addTableGroupAdd(that, pin, obj, true);
            for (var i = 0; i < obj._objects.length; i++) {
              if (obj._objects[i].name == 'text') {
                obj._objects[i].set({ 'text': obj.Name })
              }
            }
          } else if (type == ShapeTypeEnum.Image) {
            const img = document.createElement('img');
            const images = that.customImages.filter((img) => img.ImageId == obj.ImageId);
            let imagebytes = null;
            if (images && images.length == 1) {
              imagebytes = images[0].bytes
            }
            img.src = 'data:image/png;base64,' + imagebytes;
            let image: any = new fabric.Image(img, {
              left: obj.left + 20,
              top: obj.top + 20,
              width: shape.width,
              height: shape.height,
              scaleY: shape.scaleY,
              scaleX: shape.scaleX
            });
            image.set({
              name: 'shape',
              imgWidth: shape.imgWidth,
              imgHeight: shape.imgHeight
            });
            that.addTableGroupAdd(that, image, obj, true);
          }
        } else if (obj.name == 'label') {
          obj.clone(function (clonedObj1) {
            let labelsAvailable = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0].Labels;
            let labels = labelsAvailable.length > 0 ? maxBy(labelsAvailable, 'id') : { id: 0 };
            const newText = {
              Text: obj.text,
              Left: clonedObj.left + 20,
              Top: clonedObj.top + 20,
              FloorPlanViewId: null,
              id: labels["id"] + 1,
              name: "label",
              Angle: clonedObj1.angle
            };
            clonedObj1.set({
              left: clonedObj.left + 20,
              top: clonedObj.top + 20,
              FloorPlanViewId: null,
              name: "label",
              Text: obj.text,
              data: labels["id"] + 1,
              fill: '#0000008A',
              originX: 'center',
              originY: 'center'
            })
            that.canvas.add(clonedObj1);
            that.canvas.renderAll();

            that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0].Labels.push(newText);
            that.floorPlan = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0];
            that.fs.labelSettingsForm.controls.labelText.setValue(newText.Text);
            that.fs.labelSelected$.next(newText);
          });

        } else if (obj.name == 'area') {
          obj.clone(function (clonedObj1) {
            let shapesAvailable = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0].Shapes;
            let shape = shapesAvailable.length > 0 ? maxBy(shapesAvailable, 'id') : { id: 0 };
            let newArea = that.layoutFunctions.createNewArea(clonedObj1, shape, true)
            clonedObj1.set({
              FloorPlanViewId: null,
              left: clonedObj.left + 20,
              top: clonedObj.top + 20,
              Rotation: 0,
              ShapeType: 0,
              width: obj.width,
              height: obj.height,
              data: shape['id'] + 1,
              name: "area"
              , stroke: '#3B4532',
            })
            that.canvas.add(clonedObj1);
            that.canvas.sendToBack(clonedObj1);
            that.canvas.renderAll();
            that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0].Shapes.push(newArea);
          });


          that.floorPlan = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0];

        } else if (obj.name == 'wall') {
          obj.clone(function (clonedObj1) {
            let wallsAvailable = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0].Walls;
            let wall = wallsAvailable.length > 0 ? maxBy(wallsAvailable, 'id') : { id: 0 };
            let point = [];
            clonedObj1.points.forEach(element => {
              point.push({ X: element.x, Y: element.y })
            });
            const newWall = {
              FloorPlanViewId: null,
              Points: point,
              id: wall['id'] + 1,
              name: "wall",
              Top: clonedObj.top + 15,
              Left: clonedObj.left + 15
            };
            clonedObj1.set({
              FloorPlanViewId: null,
              Points: obj.points,
              data: wall['id'] + 1,
              top: clonedObj.top + 15,
              left: clonedObj.left + 15,
              name: "wall",
              stroke: '#3B4532',
            })
            that.canvas.add(clonedObj1);
            that.canvas.sendToBack(clonedObj1);
            that.canvas.renderAll();
            that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0].Walls.push(newWall);
          });
          that.floorPlan = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0];
        }
      });
      clonedObj.setCoords();
    } else {
      if (this._clipboard.Id != undefined) {
        let type;
        switch (this._clipboard.ShapeType) {
          case 0:
            type = ShapeTypeEnum.Rectangle
            break;
          case 1:
            type = ShapeTypeEnum.Circle
            break;
          case 2:
            type = ShapeTypeEnum.Pin;
            break;
          case 3:
            type = ShapeTypeEnum.BasicRectangle;
            break;
          case 4:
            type = ShapeTypeEnum.BasicCircle;
            break;
          case 8:
            type = ShapeTypeEnum.Image;
            break;
        }
        let obj = clonedObj;
        obj.set({
          ServerId: null,
          FloorPlanViewId: null,
          type: obj.ShapeType,
          Price: obj.Price,
          SeatingArea: obj.SeatingAreaId,
          SeatingType: obj.SeatingTypeId
        })
        let shape = obj._objects.filter(x => x.name == 'shape')[0];
        if (type == ShapeTypeEnum.Rectangle) {
          let rectangle = new fabric.Rect({
            left: obj.left + 20,
            top: obj.top + 20,
            height: shape.height,
            width: shape.width,
            fill: color,
            type: type,
            rx: 2,
            ry: 2
          });
          rectangle.set({
            name: 'shape',
          });
          that.addTableGroupAdd(that, rectangle, obj, true)
        } else if (type == ShapeTypeEnum.Circle) {
          let circle = new fabric.Circle({
            left: obj.left + 20,
            top: obj.top + 20,
            height: shape.height,
            width: shape.width,
            fill: color,
            type: type,
            radius: shape.width / 2
          });
          circle.set({
            name: 'shape',

          });
          circle.setControlsVisibility({

            ml: false,
            mt: false,
            mr: false,
            mb: false,

          });
          that.addTableGroupAdd(that, circle, obj, true)
        } else if (type == ShapeTypeEnum.BasicRectangle) {
          let basicRectangle = new fabric.Rect({
            left: obj.left + 20,
            top: obj.top + 20,
            height: shape.height,
            width: shape.width,
            fill: color,
            type: type,
            rx: 2,
            ry: 2
          });
          basicRectangle.set({
            name: 'shape',
            type: 'brect'
          });
          const tableObjectDetails = this.floorPlan.StandaloneTables.filter(x => x.Id == obj.Id);
          if (obj.ImageId == null && tableObjectDetails && tableObjectDetails.length > 0) {
            obj.ImageId = tableObjectDetails[0].ImageId;
          } else {
            obj.ImageId = clonedObj.ImageId;
          }
          that.addTableGroupAdd(that, basicRectangle, obj, true)
        } else if (type == ShapeTypeEnum.BasicCircle) {
          let circle = new fabric.Circle({
            left: obj.left + 20,
            top: obj.top + 20,
            height: shape.height,
            width: shape.width,
            fill: color,
            type: type,
            radius: that.bCircleRadius
          });
          circle.set({
            name: 'shape',
            type: 'bcircle'
          });
          circle.setControlsVisibility({
            ml: false,
            mt: false,
            mr: false,
            mb: false,
          });
          const tableObjectDetails = this.floorPlan.StandaloneTables.filter(x => x.Id == obj.Id);
          if (obj.ImageId == null && tableObjectDetails && tableObjectDetails.length > 0) {
            obj.ImageId = tableObjectDetails[0].ImageId;
          } else {
            obj.ImageId = clonedObj.ImageId;
          }
          that.addTableGroupAdd(that, circle, obj, true)
        } else if (type == ShapeTypeEnum.Pin) {
          let pin = new fabric.Circle({
            left: obj.left + 20,
            top: obj.top + 20,
            height: shape.height,
            width: shape.width,
            fill: color,
            type: type,
            radius: shape.width / 2
          });
          pin.set({
            name: 'shape',
            type: 'pin'
          });
          pin.setControlsVisibility({
            ml: false,
            mt: false,
            mr: false,
            mb: false,
          });
          that.addTableGroupAdd(that, pin, obj, true)
        } else if (type == ShapeTypeEnum.Image) {
          const img = document.createElement('img');
          const images = that.customImages.filter((img) => img.ImageId == obj.ImageId);
          let imagebytes = null;
          if (images && images.length == 1) {
            imagebytes = images[0].bytes
          }
          img.src = 'data:image/png;base64,' + imagebytes;
          let image: any = new fabric.Image(img, {
            left: obj.left + 20,
            top: obj.top + 20,
            width: shape.width,
            height: shape.height,
            scaleY: shape.scaleY,
            scaleX: shape.scaleX
          });
          image.set({
            name: 'shape',
            imgWidth: shape.imgWidth,
            imgHeight: shape.imgHeight
          });
          that.addTableGroupAdd(that, image, obj, true)
        }
        for (var i = 0; i < obj._objects.length; i++) {
          if (obj._objects[i].name == 'text') {
            obj._objects[i].set({ 'text': obj.Name })
          }
        }
      }
      that.floorPlan = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0];
      that.canvas.renderAll();
    }
    if (that._clipboard.name == "label") {
      this._clipboard.clone(function (clonedObj) {
        let labelsAvailable = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0].Labels;
        let labels = labelsAvailable.length > 0 ? maxBy(labelsAvailable, 'id') : { id: 0 };
        const newText = {
          Text: clonedObj.text,
          Left: clonedObj.left + 20,
          Top: clonedObj.top + 20,
          FloorPlanViewId: null,
          id: labels["id"] + 1,
          name: "label",
          Angle: clonedObj.angle

        };
        clonedObj.set({
          left: clonedObj.left + 20,
          top: clonedObj.top + 20,
          FloorPlanViewId: null,
          name: "label",
          Text: clonedObj.text,
          data: labels["id"] + 1,
          fill: '#0000008A',
          angle: clonedObj.angle,
          originX: 'center',
          originY: 'center'
        })
        that.canvas.add(clonedObj)
        that.canvas.setActiveObject(clonedObj);
        that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0].Labels.push(newText);
        that.floorPlan = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0];
        that.drawObject({ target: clonedObj }, that);
        that.canvas.renderAll();
      });
    }
    if (that._clipboard.name == "area") {
      this._clipboard.clone(function (clonedObj) {
        let shapesAvailable = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0].Shapes;
        let shape = shapesAvailable.length > 0 ? maxBy(shapesAvailable, 'id') : { id: 0 };
        let newArea = that.layoutFunctions.createNewArea(clonedObj, shape, true)
        clonedObj.set({
          FloorPlanViewId: null,
          left: clonedObj.left + 20,
          top: clonedObj.top + 20,
          Rotation: 0,
          ShapeType: 0,
          width: clonedObj.width,
          height: clonedObj.height,
          data: shape['id'] + 1,
          name: "area"
          , stroke: '#3B4532',
          perPixelTargetFind: true,
        })
        that.canvas.add(clonedObj);
        that.canvas.setActiveObject(clonedObj);
        that.canvas.sendToBack(clonedObj);
        that.drawObject({ target: clonedObj }, that);
        that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0].Shapes.push(newArea);
        that.floorPlan = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0];
        that.canvas.renderAll();
      });
    }
    if (that._clipboard.name == "wall") {
      this._clipboard.clone(function (clonedObj) {
        let wallsAvailable = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0].Walls;
        let wall = wallsAvailable.length > 0 ? maxBy(wallsAvailable, 'id') : { id: 0 };
        let point = [];
        clonedObj.points.forEach(element => {
          point.push({ X: element.x, Y: element.y })
        });
        const newWall = {
          FloorPlanViewId: null,
          Points: point,
          id: wall['id'] + 1,
          name: "wall",
          Top: clonedObj.top + 20,
          Left: clonedObj.left + 20
        };
        clonedObj.set({
          FloorPlanViewId: null,
          Points: clonedObj.points,
          data: wall['id'] + 1,
          top: clonedObj.top + 20,
          left: clonedObj.left + 20,
          name: "wall",
          stroke: '#3B4532',
          perPixelTargetFind: true,
        })
        that.canvas.add(clonedObj);
        that.canvas.setActiveObject(clonedObj);
        that.canvas.sendToBack(clonedObj);
        that.drawObject({ target: clonedObj }, that);
        that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0].Walls.push(newWall);
        that.floorPlan = that.layoutFloorPlans.filter(x => x.Id == that.selectedLayout)[0];
        that.canvas.renderAll();
      });
    }
    let objs = this.canvas.getObjects();
    objs.forEach((object) => {
      if (object.ImageId && clonedObj.Name == object.Name) {
        this.setObjectImage(object);
      }
    })
  }

  calculatePartySize() {
    const partySizeArray = [];
    for (let i = 1; i <= this.maxPartySizeAllowed; i++) {
      partySizeArray.push({ id: i, value: i.toString() });
    }
    partySizeArray[this.maxPartySizeAllowed - 1].value = partySizeArray[this.maxPartySizeAllowed - 1].value + '+';
    return partySizeArray;
  }

  setSeatingArea(Id) {
    const seatingTypesMapping = this.fs._settings.SeatingTypeSeatingAreaMappings;
    let selectedAreaSelected = [];
    if (Id) {
      const filteredSeatingType = seatingTypesMapping.filter(x => Id == x.SeatingTypeId);
      const seatingAreas = this.fs._settings.SeatingAreas;

      seatingAreas.forEach(element => {
        if (filteredSeatingType.filter(x => x.SeatingAreaId == element.Id).length > 0)
          selectedAreaSelected.push(element);
      });
    }
    else {
      if (this.seatingAreas.filter(x => x.id != -1).length > 0) {
        selectedAreaSelected.push(this.seatingAreas.filter(x => x.id != -1)[0]);
      }
      else {
        selectedAreaSelected.push({ id: null, value: null })
      }
    }
    return selectedAreaSelected;
  }

  removeJoinedTable(id, index) {
    this.joinedTables.splice(index, 1);
    const objs = this.canvas.getObjects();
    objs.forEach(object => {
      if (object.Id == id) {
        this.settingUnselectedColor(object);
      }
    });
    this.canvas.renderAll();
    this.joinButtonEnableSetting();
  }

  joinButtonEnableSetting() {
    if (this.joinedTables.length > 1) {
      this.dashboardData.actions[1].disbaledproperity = false;
    } else {
      this.dashboardData.actions[1].disbaledproperity = true;
    }
    this.dashboardData = { ...this.dashboardData };
  }

  settingUnselectedColor(object) {
    if (object._objects) {
      object._objects.forEach(element => {
        if (element.name == 'seat') {
          element.set({
            stroke: '#3B4532'
          });
        }
        if (element.name == 'shape' &&
          (element.type == TableShapeType.Image || element.type == ShapeTypeEnum.Image
            || element.type == TableShapeType.CircleBasic || element.type == ShapeTypeEnum.BasicCircle
            || element.type == TableShapeType.RectangleBasic || element.type == ShapeTypeEnum.BasicRectangle)) {
          element.set({
            stroke: '#666',
            strokeWidth: 3
          });
        } else if (element.name == 'shape') {
          element.set({
            stroke: null
          })
        }
      });
    }
    else {
      if (object.name == 'label') {
        object.set({
          fill: '#3B4532'
        })
      }
      else if (object.name == 'area' || object.name == 'wall') {
        object.set({
          stroke: '#3B4532'
        })
      }
    }
  }

  settingSelectedColor(object) {
    if (object._objects) {
      object._objects.forEach(element => {
        if (element.name == 'shape' &&
        (element.type == TableShapeType.Image || element.type == ShapeTypeEnum.Image
        || element.type == TableShapeType.CircleBasic || element.type == ShapeTypeEnum.BasicCircle
        || element.type == TableShapeType.RectangleBasic || element.type == ShapeTypeEnum.BasicRectangle)) {
          element.set({
            stroke: '#1DA664',
            strokeWidth: element.type == TableShapeType.Image || element.type == ShapeTypeEnum.Image ? 35 : 3
          });
        } else if (element.name == 'shape' || element.name == 'seat') {
          element.set({
            stroke: '#1DA664'
          });
        }
      });
    }
    else {
      if (object.name) {
        if (object.name == 'label') {
          object.set({
            fill: '#1DA664'
          })
        }
        else {
          if (object.name == 'wall' || object.name == 'area') {
            object.set({
              stroke: '#1DA664'
            })
          }
        }
      }
    }
  }

  cancelJoinTable() {
    this.fs.joinTable = false;
    this.showUpdate = false;
    const eve = { index: 2 };
    this.removeHightLightColor();
    this.tabChanged(eve)
    this.undoSelection();
  }

  removeHightLightColor() {
    this.canvas.getObjects().filter(x => x.name == 'highlight').forEach(element => {
      this.canvas.remove(element)
    });
  }

  setActive() {
    if (this.canvas) {
      const objs = this.canvas.getObjects();
      objs.forEach(element => {
        element.set({
          hasControls: true,
          lockMovementX: false,
          lockMovementY: false,
          hasBorders: true
        });
        if (element._objects) {
          this.settingUnselectedColor(element)
        }
        if (element.name == 'line') {
          element.set({
            'selection': false
          });
        }
      });
      this.canvas.set({
        selection: true
      })
      this.canvas.renderAll();
    }
  }

  setInActive() {
    if (this.canvas) {
      const objs = this.canvas.getObjects();
      objs.forEach(element => {
        element.set({
          hasControls: false,
          lockMovementX: true,
          lockMovementY: true,
          hasBorders: false
        });
        if (element._objects && !this.fs.joinTable) {
          this.settingUnselectedColor(element)
        }
      });
      this.canvas.set({
        selection: false
      })
      this.canvas.renderAll();
    }
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.fs.joinedTablesForm.valueChanges.subscribe(val => {
      if (val.minPartySize && val.maxPartySize) {
        val.minPartySize = val.minPartySize.toString().length == 0 || val.minPartySize < 1 ? 1 : Number(val.minPartySize);
        val.maxPartySize = val.maxPartySize.toString().length == 0 || val.maxPartySize < 1 ? 1 : Number(val.maxPartySize);
      }
      else {
        val.minPartySize = 1;
        val.maxPartySize = 1;
      }
      if (val.maxPartySize < val.minPartySize) {
        let maxValChanged = false;
        maxValChanged = this.fs.joinedTablesForm.controls.maxPartySize.dirty;

        if (maxValChanged) {
          this.fs.joinedTablesForm.controls.minPartySize.setValue(val.maxPartySize, { emitEvent: false });
        }
        else {
          this.fs.joinedTablesForm.controls.maxPartySize.setValue(val.minPartySize, { emitEvent: false });
        }
      }
      this.fs.joinedTablesForm.reset(this.fs.joinedTablesForm.value, { emitEvent: false })
    }));
    this.subscriptions.add(this.fs.discardChanges$.subscribe(val => {
      this.discardChanges();
    }));
    this.subscriptions.add(this.fs.selectedType.subscribe(val => {
      if (val != '') {
        this.canvas.defaultCursor = 'crosshair';
      }
      else {
        this.canvas.defaultCursor = 'default'
        this.fs.toolIndex = -1;
      }
      if (val && this.selectedFloorPlanId != -1) {
        this.fs.toolIndex = -1;
        this.canvas.defaultCursor = 'default'
        this.showErrorPopUp("alertMsgToSelectAllFloors", "alert", "450px", "350px")
      }
    }));
    this.subscriptions.add(this.fs.setFloorGridLines$.subscribe(data => {
      if (data) {
        this.setGridLines(this.selectedGridValue);
      }
      else {
        this.removeGridLines();
      }
    }));
    this.subscriptions.add(this.fs.gridSliderValue$.subscribe(data => {
      if (data) {
        this.removeGridLines();
        this.selectedGridValue = data;
        this.setGridLines(data)
      }
    }))
    this.subscriptions.add(this.fs.tableSettingsForm.valueChanges.subscribe(val => {
      if (val.minPartySize && val.maxPartySize) {
        val.minPartySize = val.minPartySize.toString().length == 0 || val.minPartySize < 1 ? 1 : Number(val.minPartySize);
        val.maxPartySize = val.maxPartySize.toString().length == 0 || val.maxPartySize < 1 ? 1 : Number(val.maxPartySize);
      } else {
        val.minPartySize = 1;
        val.maxPartySize = 1;
      }
      if (this.notSettingDate) {
        let maxPartySize = null;
        let minPartySize = null;
        const canvasObject = this.canvas.getActiveObjects();
        this.activeObjects = cloneDeep(canvasObject);
        const standaloneTables = this.floorPlan.StandaloneTables;
        if (canvasObject) {
          canvasObject.forEach(object => {
            const table = standaloneTables.filter(x => x.Id == object.Id)[0];
            let btn = document.getElementById('inline-btn');
            const oldElementSize = object.Party.Size && object.Party.State == 1 ? object.Party.Size : 0;
            const oldTableNumberText = object.IsCommunalTable ? (oldElementSize + '/' + object.MaxPartySize.toString()) : object.MaxPartySize.toString();
            if (document.getElementById(`tableNumber_${object.Name}_${oldTableNumberText}`)) {
              let tableElement = document.getElementById(`tableNumber_${object.Name}_${oldTableNumberText}`);
              btn.removeChild(tableElement);
            }
            if (table && val.LocationGroupId) {
              table.LocationGroupId = val.LocationGroupId < 0 ? null : val.LocationGroupId;
            }
            if (table && table.SeatingAreaId) {
              table.SeatingAreaId = val.seatingArea ? val.seatingArea : null;
            }
            if (table && table.SeatingTypeId) {
              table.SeatingTypeId = val.seatingType ? val.seatingType : null;
            }
            if (table && table.BufferTimeInMinutes != undefined) { //Buffertime can be zero as well
              table.BufferTimeInMinutes = val.bufferTimeInMinutes ? Number(val.bufferTimeInMinutes) : 0;
            }
            if (canvasObject.length == 1) {
              table.Name = val.tableNumber;
            }
            table.IsAvailableForReservations = val.availableForReservation;
            let seatmaxValChanged = table.MaxPartySize != val.maxPartySize ? true : false;

            if (val.maxPartySize < val.minPartySize) {
              let setMaxPartySize = false;
              let maxValChanged = false;
              maxValChanged = table.MaxPartySize != val.maxPartySize ? true : false;
              if (maxValChanged) {
                setMaxPartySize = val.maxPartySize < val.minPartySize ? true : false
              } else {
                setMaxPartySize = val.minPartySize > val.maxPartySize ? false : true
              }
              if (setMaxPartySize) {
                table.MaxPartySize = val.maxPartySize;
                table.MinPartySize = val.maxPartySize;
                object.MaxPartySize = val.maxPartySize;
                object.MinPartySize = val.maxPartySize;
              } else {
                table.MaxPartySize = val.minPartySize;
                table.MinPartySize = val.minPartySize;
                object.MaxPartySize = val.minPartySize;
                object.MinPartySize = val.minPartySize;
              }
            } else {
              table.MaxPartySize = val.maxPartySize;
              table.MinPartySize = val.minPartySize;
              object.MaxPartySize = val.maxPartySize;
              object.MinPartySize = val.minPartySize;
            }
            maxPartySize = table.MaxPartySize;
            minPartySize = table.MinPartySize;
            if (canvasObject.length == 1 && seatmaxValChanged && (object.ShapeType !== 0 && object.ShapeType !== ShapeTypeEnum.Rectangle)) {
              // canvasObject[0].rotate(-canvasObject[0].angle)
              canvasObject[0].addWithUpdate();
              object._objects = object._objects.filter(x => x.name != 'seat')
              object.addWithUpdate();

              const tableType = object._objects.filter(x => x.name == 'shape')[0];
              if (tableType.type == '0' || tableType.type == ShapeTypeEnum.Rectangle) {
                let seatData = this.layoutFunctions.drawRectangleSeat(object, this, '#3B4532');
                seatData.forEach(seat => {
                  object.addWithUpdate(seat);
                });
              }
              else if (tableType.type == '1' || tableType.type == ShapeTypeEnum.Circle) {
                let seatData = this.layoutFunctions.drawCircleSeat(object, this, '#3B4532');
                seatData.forEach(seat => {
                  object.addWithUpdate(seat);
                });
              }
              // object.rotate(canvasObject[0].angle);
              object.addWithUpdate();
            } 
            else if (canvasObject.length == 1 && seatmaxValChanged) {
              this.canvas.remove(object);
              let editedTable = cloneDeep(standaloneTables.filter(x => x.Id == object.Id)[0]);
              // editedTable.Angle = object.angle;
              // editedTable.RotationAngle = object.angle;
              // editedTable.Left = editedTable.Left + (editedTable.Width / 2) + this.adjustvalueleft;
              // editedTable.Top = editedTable.Top + (editedTable.Height / 2) + this.adjustvaluetop;
              editedTable.Left = object.left;
              editedTable.Top = object.top;
              this.setTableConfiguration(editedTable);
              // this.layoutFunctions.scaleObject(this.groupedObject, this.scaleFactorX, this.scaleFactorY, this.scaleFactor);
              this.groupedObject.rotate(object.angle);
              this.groupedObject._objects.forEach(element => {
                if (element.name == 'textCircle' || element.name == 'text') {
                  element.set({
                    angle: -object.angle
                  })
                }
              });
              // this.groupedObject.rotate(-object.angle);
              // this.groupedObject.addWithUpdate();
              this.groupedObject.set({
                angle: object.angle,
                Angle: object.angle,
                RotationAngle: object.angle
              });
              this.canvas.setActiveObject(this.groupedObject);
              this.dashboardData.actions = [{
                type: buttonTypes.actionSecondary, label: 'deleteText', actionName: EditorStatus.DeleteTable,
                customclass: 'action-btn'
              },
              {
                type: buttonTypes.actionPrimary, label: 'cloneText',
                actionName: EditorStatus.CloneTable, customclass: 'action-btn'
              }];
              this.dashboardData = { ...this.dashboardData };          
              this.fs.onSelectionCleared.next(false);
              let activeObject = this.canvas.getActiveObject();
              this.notSettingDate = false;
              this.fs.tableSettingsForm.controls.tableNumber.setValue(activeObject.Name);
              if (this.fs.tableSettingsForm.controls.seatingType)
                this.fs.tableSettingsForm.controls.seatingType.setValue(activeObject.SeatingTypeId);
              this.fs.tableSettingsForm.controls.seatingArea.setValue(activeObject.SeatingAreaId);
              this.fs.tableSettingsForm.controls.availableForReservation.setValue(activeObject.IsAvailableForReservations);
              this.fs.tableSettingsForm.controls.minPartySize.setValue(activeObject.MinPartySize);
              this.fs.tableSettingsForm.controls.maxPartySize.setValue(activeObject.MaxPartySize);
              this.fs.tableSettingsForm.controls.communalTable.setValue(activeObject.IsCommunalTable);
             if(this.isBufferTimeEnabled){
              this.fs.tableSettingsForm.controls.bufferTimeInMinutes.setValue(activeObject.BufferTimeInMinutes);
              }
              if(this.fs.tableSettingsForm?.controls?.LocationGroupId){
                this.fs.tableSettingsForm.controls.LocationGroupId.setValue(activeObject.LocationGroupId ? activeObject.LocationGroupId : -1  );
              }
              this.fs.selectedTableBeforeAction$.next(activeObject);
              this.notSettingDate = true;
              this.fs.selectedSeatingTypeId.next(activeObject.SeatingTypeId);
            }
            table.SeatingAreaId = object.SeatingAreaId = val.seatingArea ? val.seatingArea : null;
            table.SeatingTypeId = object.SeatingTypeId = val.seatingType ? val.seatingType : null;
            if (canvasObject.length == 1) {
              if (canvasObject[0]._objects) {
                if (canvasObject[0].ShapeType == TableShapeType.Pin && canvasObject[0].ShapeType == ShapeTypeEnum.Pin) {
                  const data = canvasObject[0]._objects.filter(x => x.name == 'text')[0];
                  data.set({
                    text: isString(val.tableNumber) ? val.tableNumber : new Number(val.tableNumber).toString()
                  })
                }
                if (canvasObject[0].ShapeType == TableShapeType.Pin || canvasObject[0].ShapeType == ShapeTypeEnum.Pin) {
                  let data = canvasObject[0]._objects.filter(x => x.name == 'shape')[0];
                  let pinColor = this.seatingTypes.filter(x => x.id == val.seatingType);
                  if (pinColor && pinColor.length > 0) {
                    data.set({
                      fill: pinColor[0].color
                    });
                  }
                }
              }
              object.Name = val.tableNumber;
              table.Name = val.tableNumber;
            }
            object.IsAvailableForReservations = val.availableForReservation;
            if (object.IsCommunalTable != val.communalTable) {
              let floorPlan = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0];
              let isPresentInJoined = false;
              floorPlan.JoinedTables.forEach(joinedTable => {
                if (joinedTable.StandaloneTableIds.includes(table.Id)) {
                  isPresentInJoined = true;
                }
              })
              if (isPresentInJoined) {
                this.fs.joinedToCommunalError$.next({ value: true, name: table.Name });
                this.fs.tableSettingsForm.controls.communalTable.setValue(!val.communalTable, { emitEvent: false });
              }
              else {
                let communalReservations = [];
                this.layoutConfig.reservations.forEach((reservation) => {
                  if (reservation.TableIds.includes(table.Id)) {
                    communalReservations.push(reservation);
                  }
                });
                if (communalReservations.length > 0) {
                  let confirmationMsg;
                  let componentType;
                  let seatedParties = communalReservations.filter(x => x.State == PartyState.Seated);
                  let reservationsMapped = communalReservations.filter(x => x.State == PartyState.Pending);
                  if (seatedParties.length > 1) {
                    confirmationMsg = this.ts.instant('seatedPartyMsg');
                    componentType = ComponentTypes.nonCommunalTableConversion;
                    this.seatedPartiesForNonCommunal = seatedParties;
                  }
                  else if (seatedParties.length == 1) {
                    confirmationMsg = this.ts.instant('alreadySeatedMsg');
                    componentType = ComponentTypes.communalTableConversion;
                  }
                  else if (reservationsMapped.length > 0) {
                    confirmationMsg = this.ts.instant('futureReservationError');
                    componentType = ComponentTypes.communalTableConversion;
                  }
                  const popUpMessage = [{
                    confirmationMessage: confirmationMsg,
                    dialogTitle: this.ts.instant('discardChanges'),
                    inputData: '',
                    layout: this.layoutFloorPlans
                  }];
                  const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, ' ', '', popUpMessage, '');
                  const dialogRef = this.openCustomPopup(componentInfo, componentType,
                    '500px', '353px', false, this.ts.instant('discardChanges'), 'ok', 'cancel', false);
                  this.fs.tableSettingsForm.controls.communalTable.setValue(!val.communalTable, { emitEvent: false });

                  let confirmActionSubscription: ISubscription = null;
                  let cancelActionSubscription: ISubscription = null;
                  confirmActionSubscription = this.popupService.confirmedAction$.subscribe((val) => {
                    if (val == ComponentTypes.communalTableConversion) {
                      this.communalTableConversion = true;
                    } else if (val == ComponentTypes.nonCommunalTableConversion) {
                      this.nonCommunalTableConversion = true;
                    }
                  });
                  cancelActionSubscription = this.popupService.cancelledAction$.subscribe(() => {
                    this.addNewLayoutClicked = false;
                  });
                  this.subscriptions.add(dialogRef.afterClosed().subscribe(data => {
                    if (this.communalTableConversion) {
                      object.IsCommunalTable = val.communalTable;
                      table.IsCommunalTable = val.communalTable;
                      this.fs.tableSettingsForm.controls.communalTable.setValue(val.communalTable, { emitEvent: false });
                      let size = 0;
                      seatedParties.forEach(x => {
                        size = size + x.Size;
                      })
                      this.setCommunalParty(canvasObject, object, size);
                      this.communalTableConversion = false;
                      this.canvas.renderAll();
                    }
                    else if (this.nonCommunalTableConversion) {
                      object.IsCommunalTable = val.communalTable;
                      table.IsCommunalTable = val.communalTable;
                      this.canvas.renderAll();
                      this.save()
                    }
                    if (confirmActionSubscription) {confirmActionSubscription.unsubscribe();}
                    if (cancelActionSubscription) {cancelActionSubscription.unsubscribe();}
                  }));
                }
                else {
                  object.IsCommunalTable = val.communalTable;
                  table.IsCommunalTable = val.communalTable;
                  this.setCommunalParty(canvasObject, object, 0);
                  this.canvas.renderAll();
                }
              }
            }
            object.MaxPartySize = table.MaxPartySize;
            let maxSizeParties = []
            this.layoutConfig.reservations.forEach((reservation) => {
              if (reservation.State == 1 && reservation.TableIds.includes(table.Id)) {
                maxSizeParties.push(reservation);
              }
            });
            let size = 0;
            maxSizeParties.forEach(x => {
              size = size + x.Size;
            })
            this.setSize(canvasObject, object, size);
            object.MinPartySize = table.MinPartySize;
            object.LocationGroupId = table.LocationGroupId;
            if(this.isBufferTimeEnabled){
              object.BufferTimeInMinutes = table.BufferTimeInMinutes;
            }
            if (canvasObject[0].ShapeType != undefined && canvasObject[0].ShapeType != TableShapeType.Pin && canvasObject[0].ShapeType != ShapeTypeEnum.Pin) {
              const elementSize = object.Party.Size && object.Party.State == 1 ? object.Party.Size : 0;
              const tableNumberText = object.IsCommunalTable ? (elementSize + '/' + object.MaxPartySize.toString()) : object.MaxPartySize.toString();
              this.createTableSizeElement(tableNumberText, object, this);
            }
          });
          this.fs.tableSettingsForm.controls.maxPartySize.setValue(maxPartySize, { emitEvent: false });
          this.fs.tableSettingsForm.controls.minPartySize.setValue(minPartySize, { emitEvent: false });
          this.canvas.renderAll();
        }
      }
    }));
    this.subscriptions.add(this.fs.labelSettingsForm.valueChanges.subscribe(val => {
      const canvasObject = this.canvas.getActiveObjects();
      this.activeObjects = cloneDeep(canvasObject);
      const lables = this.floorPlan.Labels;
      this.floorPlan;
      if (canvasObject && canvasObject.length == 1) {
        canvasObject.forEach(object => {
          const label = lables.filter(x => x.id == object.data)[0];
          if (label) {
            label.Text = val.labelText ? val.labelText : this.ts.instant('newLabel');
            object.text = val.labelText ? val.labelText : this.ts.instant('newLabel');
          }
        })
      }
      this.canvas.renderAll();
    }));
    this.subscriptions.add(this.fs.deleteJoinedTable$.subscribe(val => {
      this.deleteJoinedTable(val);
    }));
    this.subscriptions.add(this.fs.editJoinedTable$.subscribe(val => {
      this.editJoinedTable(val);
    }));
    this.subscriptions.add(this.fs.removeTable$.subscribe(val => {
      this.removeJoinedTable(val.id, val.index);
    }));
    this.subscriptions.add(this.fs.objectDeleted$.subscribe(val => {
      this.config[0].options = this.fs.floorPlan ? this.fs.floorPlan.FloorPlanViews.map(x => { return { id: x.Id, value: x.Name } }) : [];
      this.config[0].options.push({ id: -1, value: this.ts.instant('viewAllFloors') });
      if (val != 'nameChangeEvent') {
        let objs = this.canvas.getObjects();
        objs.forEach(element => {
          this.settingUnselectedColor(element);
        });
        this.canvas.renderAll();
      }
    }));
    this.subscriptions.add(this.fs.selectedFloor$.subscribe(val => {
      this.setSelectedFloorObjects(val);
    }));
    this.subscriptions.add(this.fs.drawLabel$.subscribe(val => {
      if (this.selectedFloorPlanId == -1) {
        this.addNewLabel();
      }
    }));
    this.subscriptions.add(this.fs.deleteSelectedFloorPlanTables$.subscribe(val => {
      if (val.value == true) {
        this.deleteSelectedFloorPlanTables();
      }
      if (this.components.first.form.controls.viewBy.value == val.id) {
        this.components.first.form.controls.viewBy.setValue(this.floorPlanOptions.filter(x => x.id == -1)[0].id);
        this.setSelectedFloorPlan({ value: -1 });
      }
    }));
    this.subscriptions.add(this.fs.selectAllObjects$.subscribe(val => {
      this.selectAllObjects(val);
    }));
    this.subscriptions.add(this.fs.applySelectedImage$.subscribe((data) => {
      if (!data.isLayoutSelected) {
        let selectedTableObject = this.canvas.getObjects().filter(table => table.Id == this.currentSelectedObject.Id);
        let selectedTable = this.floorPlan.StandaloneTables.filter((table) => table.Id == this.currentSelectedObject.Id);
        if (selectedTable && selectedTable.length > 0) {
          selectedTable[0].ImageId = data.image.ImageId;
        }
        if (selectedTableObject.length > 0 && data.image && data.image.ImageId) {
          let compressedPhoto;
          fabric.Image.fromURL('data:image/png;base64,' + data.image.bytes, (img) => {
            compressedPhoto = img;
            let shapedObject = selectedTableObject[0]._objects.filter(ele => ele.name == 'shape')[0];
            let scaleFactorX = 1;
            let scaleFactorY = 1;
            if (selectedTableObject[0].ImageId) {
              scaleFactorX = this.scaleFactorX;
              scaleFactorY = this.scaleFactorY;
            }

            if (selectedTableObject[0].ShapeType == 4) {
              compressedPhoto.set({
                top: selectedTableObject[0].top ,
                left: selectedTableObject[0].left ,
                clipTo: (ctx) => {
                    ctx.arc(0, 0, selectedTableObject[0].radius*(img.height/shapedObject.height), 0, Math.PI * 2, true);
                },
                originX: 'center',
                originY: 'center',
                name: 'image',
                width: img.width,
                height: img.height,
                scaleX: ((shapedObject.width) / img.width) * scaleFactorX,
                scaleY: ((shapedObject.height) / img.height) * scaleFactorY,
              })
            } else {
              compressedPhoto.set({
                scaleX: ((shapedObject.width) / img.width) * scaleFactorX,
                scaleY: ((shapedObject.height) / img.height) * scaleFactorY,
                top: selectedTableObject[0].top,
                left: selectedTableObject[0].left,
                originX: 'center',
                originY: 'center',
                name: 'image',
                width: img.width,
                height: img.height,
                angle: selectedTableObject[0].angle
              });
            }
            let allObjects = selectedTableObject[0]._objects.filter(ele => ele.name == 'textCircle' ||
              ele.name == 'tableNumber' || ele.name == 'text' || ele.name == 'serverLines').length;
            let objectPositionLength = allObjects > 3 ? (allObjects + 1) : allObjects;
            let isImageObjectAvailable = selectedTableObject[0]._objects.filter(ele => ele.name == 'image');
            if (isImageObjectAvailable && isImageObjectAvailable.length > 0) {
              isImageObjectAvailable.forEach((objimage) => {
                selectedTableObject[0].removeWithUpdate(objimage);
              })
            }
            selectedTableObject[0].addWithUpdate(compressedPhoto);
            compressedPhoto.moveTo(selectedTableObject[0].size() - objectPositionLength);
            this.canvas.renderAll();
          });
        } else if (selectedTableObject.length > 0 && data.image.ImageId == null) {
          selectedTableObject[0].ImageId = null;
          let imageObjects = selectedTableObject[0]._objects.filter(x => x.name === 'image');
          if (imageObjects.length > 0) {
            imageObjects.forEach(imgObj => {
              selectedTableObject[0].removeWithUpdate(imgObj);
            });
            this.canvas.renderAll();
          }
        }
      } else {
        if (data.image && data.image.bytes == '') {
          this.canvas.backgroundImage = null;
          this.floorPlan.ImageId = null;
          this.canvas.renderAll();
        } else {
          this.uploadLayoutImage(data.image);
        }
      }
    }))
    this.ls.showMessage.next(false);
  }

  selectAllObjects(val: boolean) {
    let objs = this.canvas.getObjects();
    objs.forEach(element => {
      val ? this.settingSelectedColor(element) : this.settingUnselectedColor(element);
    });
    this.canvas.renderAll();
  }

  uploadLayoutImage(image: InputImages, fileName?) {
    fabric.Image.fromURL('data:image/png;base64,' + image.bytes, (img) => {
      this.canvas.setBackgroundImage(img,
        this.canvas.renderAll.bind(this.canvas), {
        scaleX: ((this.canvas.width) / img.width),
        scaleY: ((this.canvas.height) / img.height)
      });
    });
    this.floorPlan.ImageId = image.ImageId;
  }

  deleteLayout() {
    if (!this.floorPlan.IsDefault && this.floorPlan.Id > 0) {
      const popUpMessage = [{
        confirmationMessage: this.ts.instant('deleteConfirmMsg'),
        dialogTitle: this.ts.instant('deleteFloor')
      }];
      const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, ' ', '', popUpMessage, '');
      const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.deleteFloorPlan,
        '300px', '250px', false, this.ts.instant('deleteFloor'), 'Yes', 'No', false);
      let confirmActionSubscription: ISubscription = null;
      let cancelActionSubscription: ISubscription = null;
      confirmActionSubscription = this.popupService.confirmedAction$.subscribe((val) => {
        if (val == ComponentTypes.deleteFloorPlan) {
          this.deleteConfirmedLayout();
        }
      });
      cancelActionSubscription = this.popupService.cancelledAction$.subscribe((val) => {
        this.addNewLayoutClicked = false;
      });
      dialogRef.afterClosed().subscribe(() => {
        if (confirmActionSubscription) { confirmActionSubscription.unsubscribe(); }
        if (cancelActionSubscription) { cancelActionSubscription.unsubscribe(); }
      })
    }
  }
  deleteConfirmedLayout() {
    let index = this.layoutFloorPlans.findIndex(x => x.Id == this.selectedLayout);
    this.subscriptions.add(this.fs.removeFloorPlan(this.layoutFloorPlans[index]).subscribe(val => {
      if (val.State == OperationResultState.Success) {
        this.selectedLayout = this.layoutFloorPlans.filter(x => x.IsDefault == true)[0].Id;
        let eve = { value: this.selectedLayout };
        this.setSelectedLayout(eve);
        const deleteMsg = this.ts.instant('floorplandelete');
        this.ShowNotification(val, deleteMsg);
      }
    }));
  }

  deleteTables(type) {
    let objs = this.canvas.getActiveObjects();
    objs.forEach(element => {
      if (element.Id != undefined) {
        const index = this.floorPlan.StandaloneTables.findIndex(x => x.Id == element.Id);
        let otherObjsWithSameId = this.canvas._objects.filter(x => x.name.includes(element.Id));
        if (otherObjsWithSameId.length) {
          otherObjsWithSameId.forEach(otherObj => this.canvas.remove(otherObj));
        }
        this.floorPlan.StandaloneTables.splice(index, 1);
        let btn = document.getElementById('inline-btn');
        const oldElementSize = element.Party.Size && element.Party.State == 1 ? element.Party.Size : 0;
        const oldTableNumberText = element.IsCommunalTable ? (oldElementSize + '/' + element.MaxPartySize.toString()) : element.MaxPartySize.toString();
        if (document.getElementById(`tableNumber_${element.Name}_${oldTableNumberText}`)) {
          let tableElement = document.getElementById(`tableNumber_${element.Name}_${oldTableNumberText}`);
          btn.removeChild(tableElement);
        }
        this.canvas.remove(element);
      }
      else if (element.name == 'area') {
        const index = this.floorPlan.Shapes.findIndex(x => x.id == element.data);
        this.floorPlan.Shapes.splice(index, 1);
        this.canvas.remove(element);
      }
      else if (element.name == 'label') {
        const index = this.floorPlan.Labels.findIndex(x => x.id == element.data);
        this.floorPlan.Labels.splice(index, 1);
        this.canvas.remove(element);
      }
      else if (element.name == 'wall') {
        const index = this.floorPlan.Walls.findIndex(x => x.id == element.data);
        this.floorPlan.Walls.splice(index, 1);
        this.canvas.remove(element);
      }
    });
    this.canvas.discardActiveObject();
  }

  deleteSelectedFloorPlanTables() {
    this.objectsSelected = this.floorPlan.StandaloneTables.filter(x => x.FloorPlanViewId == this.fs.selectedFloorId);
    let othersSelected = this.floorPlan.Walls.filter(x => x.FloorPlanViewId == this.fs.selectedFloorId);
    othersSelected = othersSelected.concat(this.floorPlan.Shapes.filter(x => x.FloorPlanViewId == this.fs.selectedFloorId));
    othersSelected = othersSelected.concat(this.floorPlan.Labels.filter(x => x.FloorPlanViewId == this.fs.selectedFloorId));
    let objs = this.canvas.getObjects();
    objs = objs.filter(obj => this.objectsSelected.some(objselected => obj.Id === objselected.Id));
    let others = this.canvas.getObjects().filter(obj => othersSelected.some(objselected => (obj.data == objselected.id && obj.name == objselected.name)))
    objs.forEach(element => {
      this.canvas.remove(element);
    });
    others.forEach(element => {
      this.canvas.remove(element);
    });
  }


  setCommunalParty(canvasObject, object, size) {
    canvasObject.forEach(element => {
      if (element._objects) {
        const communalIcon = element._objects.filter(x => x.name == 'communal')[0];
        const tableNumber = element._objects.filter(x => x.name == 'tableNumber')[0];
        if (communalIcon)
          communalIcon.set({ opacity: object.IsCommunalTable ? 1 : 0 });
        const tableNumberText = object.IsCommunalTable ? (size + '/' + object.MaxPartySize.toString()) : object.MaxPartySize.toString();
        if (tableNumber) {
          tableNumber.set({
            text: tableNumberText
          });
        }
      }
    });
  }

  setSize(canvasObject, object, seatedParty) {
    canvasObject.forEach(element => {
      if (element._objects) {
        const tableNumber = element._objects.filter(x => x.name == 'tableNumber')[0];
        const tableNumberText = object.IsCommunalTable ? (seatedParty + ' / ' + object.MaxPartySize.toString()) : object.MaxPartySize.toString();
        if (tableNumber) {
          tableNumber.set({
            text: tableNumberText
          });
        }
        element.addWithUpdate();
      }
    });
  }

  setSelectedFloorObjects(val) {
    this.objectsSelected = this.floorPlan.StandaloneTables.filter(x => x.FloorPlanViewId == val);
    this.objectsSelected = this.objectsSelected.concat(this.floorPlan.Walls.filter(x => x.FloorPlanViewId == val));
    this.objectsSelected = this.objectsSelected.concat(this.floorPlan.Shapes.filter(x => x.FloorPlanViewId == val));
    this.objectsSelected = this.objectsSelected.concat(this.floorPlan.Labels.filter(x => x.FloorPlanViewId == val));
    const objs = this.canvas.getObjects();
    objs.forEach(element => {
      if (element._objects || element.data != undefined) {
        this.settingUnselectedColor(element);
      }
      let index = -1
      if (element.Id) {
        index = this.objectsSelected.findIndex(x => x.Id == element.Id)
      }
      else {
        if (element.name) {
          index = this.objectsSelected.findIndex(x => (x.id == element.data && x.name == element.name));
        }
      }
      if (index != -1) {
        this.settingSelectedColor(element);
      }
    });
    this.canvas.renderAll();
  }

  deleteObject() {
    const objs = this.canvas.getActiveObjects();
    let partyMapped;
    this.layoutConfig.reservations.forEach((reservation) => {
      if (reservation.TableIds.includes(objs[0].Id)) {
        partyMapped = true;
        this.isPartyMappedToTable = true;
      }
    });
    this.deleteTables('');
  }

  setCanvas() {
    if (this.canvas) {
      this.canvas.dispose();
    }
    this.canvas = new fabric.Canvas('editorCanvas', {
      backgroundColor: 'black'
    });
    this.canvas.hoverCursor = 'pointer';
    this.canvas.preserveObjectStacking = false;
    this.canvas.renderOnAddRemove = true;
    let _that = this;
    this.canvas.on({
      'mouse:down'(event) {
        if (!(_that.selectedTabIndex == 0 && _that.selectedFloorPlanId != -1)) {
          _that.drawObject(event, _that);
        }
      },
      'mouse:move'(event) {
        _that.onMouseMove(event, _that);
      },
      'mouse:up'(event) {
        _that.onMouseUp(event, _that);
      },
      'mouse:dblclick'(event) {
        _that.onMouseDoubleClick(event, _that);
      },
      'selection:cleared'(event) {
        _that.onSelectionCleared(event, _that);
      },
      'text:changed'(event) {
        _that.onObjectModified(event, _that);
      },
      'object:rotating'(event) {
        _that.onObjectRotating(event, _that)
      },
      'object:scaling'(event) {
        _that.onObjectScaling(event, _that)
      },
      'object:scaled'(event) {
        _that.onObjectScaled(event, _that)
      },
      'selection:created'(event) {
        _that.objectSelection(event, _that);
      },
      'object:moving'(event) {
        _that.objectMoving(event, _that);
      },
      'object:moved'(event) {
        _that.objectMoved(event, _that);
      }
    });
  }

  getAbsoluteCoords(object) {
    return {
      left: object.left + (object.width/2),
      top: object.top + (object.height/2)
    };
  }

  objectMoving(event, _that) {
    if (event.target != null && event.target.Id) {
      this.joinedLayerRemoval(event.target)
    }
    this.showHelperLines(event, _that);
    if (event.target.ShapeType != undefined && event.target.ShapeType !== ShapeTypeEnum.Pin.toLowerCase() && event.target.ShapeType !== TableShapeType.Pin) {
      let element = event.target;
      const size = element.Party.Size && element.Party.State == 1 ? element.Party.Size : 0;
      const tableNumberText = element.IsCommunalTable ? (size + '/' + element.MaxPartySize.toString()) : element.MaxPartySize.toString();
      this.createTableSizeElement(tableNumberText, element, this);
    }
    // if (event.target.left > this.canvas.getWidth()) {
    //   this.canvas.setZoom(this.canvas.getZoom() / 0.8)
    // }
  }

  showHelperLines(event, _that) {
    if (this.fs.nearestObject && event.target != null) {
      let objs = this.canvas.getObjects().filter(x => x.name == 'wall' || x.name == 'object' || x.name == 'area' || x.name == 'label');
      let similarObjectsAvailableLeftSides = objs.filter(x => (Math.trunc(x.left - ((x.width * x.scaleX) / 2)) == Math.trunc(event.target.left - ((event.target.width * event.target.scaleX) / 2)) && x.Id != event.target.Id));
      let similarObjectsAvailableRightSide = objs.filter(x => (Math.trunc(x.left + (x.width * x.scaleX / 2)) == Math.trunc(event.target.left + (event.target.width * event.target.scaleX / 2)) && x.Id != event.target.Id));
      let similarObjectsAvailableTopSides = objs.filter(x => (Math.trunc(x.top - ((x.height * x.scaleY) / 2)) == Math.trunc(event.target.top - ((event.target.height * event.target.scaleY) / 2)) && x.Id != event.target.Id));
      let similarObjectsAvailableBottomSides = objs.filter(x => (Math.trunc(x.top + (x.height * x.scaleY / 2)) == Math.trunc(event.target.top + (event.target.height * event.target.scaleY / 2)) && x.Id != event.target.Id));

      if (similarObjectsAvailableLeftSides.length > 0) {
        similarObjectsAvailableLeftSides.forEach(element => {
          let similarObjectAvailableLeftSideLine = new fabric.Line([element.left - (element.width * element.scaleX / 2), element.top,
          event.target.left - (event.target.width * event.target.scaleX / 2), event.target.top],
            {
              type: 'line', name: 'similarObjectAvailableLeftSideLine', strokeWidth: 1, stroke: 'blue', selectable: false,
              hoverCursor: "pointer"
            });
          this.canvas.add(similarObjectAvailableLeftSideLine);
          this.canvas.sendToBack(similarObjectAvailableLeftSideLine)
        });
      }
      else {
        let nearObjects = this.canvas.getObjects().filter(x => x.name == 'similarObjectAvailableLeftSideLine')
        nearObjects.forEach(element => {
          this.canvas.remove(element);
        });

      }
      if (similarObjectsAvailableRightSide.length > 0) {
        similarObjectsAvailableRightSide.forEach(element => {
          let similarObjectAvailableRightSideLine = new fabric.Line([element.left + (element.width * element.scaleX / 2), element.top,
          event.target.left + (event.target.width * event.target.scaleX / 2), event.target.top],
            {
              type: 'line', name: 'similarObjectsAvailableRightSide', strokeWidth: 1, stroke: 'blue', selectable: false,
              hoverCursor: "pointer"
            });
          this.canvas.add(similarObjectAvailableRightSideLine);
          this.canvas.sendToBack(similarObjectAvailableRightSideLine)
        });
      }
      else {
        let nearObjects = this.canvas.getObjects().filter(x => x.name == 'similarObjectsAvailableRightSide')
        nearObjects.forEach(element => {
          this.canvas.remove(element);
        });

      }
      if (similarObjectsAvailableTopSides.length > 0) {
        similarObjectsAvailableTopSides.forEach(element => {
          let similarObjectAvailableTopSideLine = new fabric.Line([element.left, element.top - (element.height * element.scaleY / 2),
          event.target.left, event.target.top - (event.target.height * event.target.scaleY / 2)],
            {
              type: 'line', name: 'similarObjectAvailableTopSideLine', strokeWidth: 1, stroke: 'blue', selectable: false,
              hoverCursor: "pointer"
            });
          this.canvas.add(similarObjectAvailableTopSideLine);
          this.canvas.sendToBack(similarObjectAvailableTopSideLine)
        });
      }
      else {
        let nearObjects = this.canvas.getObjects().filter(x => x.name == 'similarObjectAvailableTopSideLine')
        nearObjects.forEach(element => {
          this.canvas.remove(element);
        });

      }
      if (similarObjectsAvailableBottomSides.length > 0) {
        similarObjectsAvailableBottomSides.forEach(element => {
          let similarObjectsAvailableBottomSides = new fabric.Line([element.left, element.top + (element.height * element.scaleY / 2),
          event.target.left, event.target.top + (event.target.height * event.target.scaleY / 2)],
            {
              type: 'line', name: 'similarObjectsAvailableBottomSides', strokeWidth: 1, stroke: 'blue', selectable: false,
              hoverCursor: "pointer"
            });
          this.canvas.add(similarObjectsAvailableBottomSides);
          this.canvas.sendToBack(similarObjectsAvailableBottomSides)
        });
      }
      else {
        let nearObjects = this.canvas.getObjects().filter(x => x.name == 'similarObjectsAvailableBottomSides')
        nearObjects.forEach(element => {
          this.canvas.remove(element);
        });

      }

      let similarObjectAvailableCenterVertical = objs.filter(x => (Math.trunc(x.left) == Math.trunc(event.target.left) && x.Id != event.target.Id));
      if (similarObjectAvailableCenterVertical.length > 0) {
        similarObjectAvailableCenterVertical.forEach(element => {
          let similarObjectAvailableLeftSideLine = new fabric.Line([element.left, element.top, event.target.left, event.target.top],
            {
              type: 'line', name: 'similarObjectAvailableCenterVertical', strokeWidth: 1, stroke: 'red', selectable: false,
              hoverCursor: "pointer"
            });
          this.canvas.add(similarObjectAvailableLeftSideLine);
          this.canvas.sendToBack(similarObjectAvailableLeftSideLine)
        });
      }
      else {
        let nearObjects = this.canvas.getObjects().filter(x => x.name == 'similarObjectAvailableCenterVertical')
        nearObjects.forEach(element => {
          this.canvas.remove(element);
        });

      }
      let similarObjectAvailableCenterHorizontal = objs.filter(x => (Math.trunc(x.top) == Math.trunc(event.target.top) && x.Id != event.target.Id));
      if (similarObjectAvailableCenterHorizontal.length > 0) {
        similarObjectAvailableCenterHorizontal.forEach(element => {
          let similarObjectAvailableLeftSideLine = new fabric.Line([element.left, element.top, event.target.left, event.target.top],
            {
              type: 'line', name: 'similarObjectAvailableCenterHorizontal', strokeWidth: 1, stroke: 'red', selectable: false,
              hoverCursor: "pointer"
            });
          this.canvas.add(similarObjectAvailableLeftSideLine);
          this.canvas.sendToBack(similarObjectAvailableLeftSideLine)
        });
      }
      else {
        let nearObjects = this.canvas.getObjects().filter(x => x.name == 'similarObjectAvailableCenterHorizontal')
        nearObjects.forEach(element => {
          this.canvas.remove(element);
        });

      }
      this.canvas.renderAll();
    }
  }

  objectMoved(obj, _that) {
    if (obj.target != null) {
      const objs = this.canvas.getActiveObjects();
      if (obj.target.Id) {
        this.joinedLayerAddition(obj.target)
      }
      let canvasObjects = this.canvas.getObjects();
      canvasObjects.forEach(object => {
        object.set({
          lockMovementX: false,
          lockMovementY: false,
          hasBorders: false,
        })
      });
      objs.forEach(element => {
        if (element.Id) {
          const table = this.floorPlan.StandaloneTables.filter(x => x.Id == element.Id)[0];
          table.Left = element.left;
          table.Top = element.top;
        }
        else if (element.name == 'area') {
          const shape = this.floorPlan.Shapes.filter(x => x.id == element.data)[0];
          shape.Left = element.left;
          shape.Top = element.top;
        }
        else if (element.name == 'label') {
          const label = this.floorPlan.Labels.filter(x => x.id == element.data)[0];
          label.Text = element.text;
          label.Left = element.left;
          label.Top = element.top;
        }
        else if (element.name == 'wall') {
          const wall = this.floorPlan.Walls.filter(x => x.id == element.data)[0];
          let matrix = [];
          matrix = element.calcTransformMatrix();
          var translatedPoints = element.get('points').map(function (p) {
            return {
              x: p.x - element.pathOffset.x,
              y: p.y - element.pathOffset.y
            };
          });
          for (var i = 0; i < translatedPoints.length; i++) {
            translatedPoints[i].x = matrix[0] * translatedPoints[i].x + matrix[2] * translatedPoints[i].y + matrix[4];
            translatedPoints[i].y = matrix[1] * translatedPoints[i].x + matrix[3] * translatedPoints[i].y + matrix[5];
          }
          wall.Points = translatedPoints;
        }
        if (obj.target.ShapeType != undefined && obj.target.ShapeType !== ShapeTypeEnum.Pin.toLowerCase() && obj.target.ShapeType !== TableShapeType.Pin) {
          const size = element.Party.Size && element.Party.State == 1 ? element.Party.Size : 0;
          const tableNumberText = element.IsCommunalTable ? (size + '/' + element.MaxPartySize.toString()) : element.MaxPartySize.toString();
          this.createTableSizeElement(tableNumberText, element, this);
        }
      });
    }
  }

  setCanvasObjectsValue(topValue, leftValue, value) {
    let objs = this.canvas.getObjects();
    objs.forEach(element => {
      if (value) {
        element.top = element.top - (element.top - topValue) > 0 ? element.top - (element.top - topValue) : 0;
        element.left = element.left - (element.left - leftValue) > 0 ? element.left - (element.left - leftValue) : 0;
      }
      else {
        element.top = element.top + topValue;
        element.left = element.left + leftValue;
        let tempScaleX = element.scaleX / 1.1;
        let tempScaleY = element.scaleY / 1.1;
        element.scaleX = tempScaleX;
        element.scaleY = tempScaleY;
      }
    });
    this.canvas.renderAll();
  }

  onObjectModified(obj, that) {
    if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Text.toLowerCase()) {
    }
  }

  setGridLines(data) {
    const grid = data;
    for (let i = 0; i < (this.canvas.width / grid); i++) {
      let verticalLine = new fabric.Line([i * grid, 0, i * grid, this.canvas.height], { type: 'line', name: 'line', stroke: '#70707033', selectable: false, hoverCursor: "pointer" });
      this.canvas.add(verticalLine);
      this.canvas.sendToBack(verticalLine);
    }
    for (let i = 0; i < (this.canvas.height / grid); i++) {
      let horizontalLine = new fabric.Line([0, i * grid, this.canvas.width, i * grid], { type: 'line', name: 'line', stroke: '#70707033', selectable: false, hoverCursor: "pointer" });
      this.canvas.add(horizontalLine);
      this.canvas.sendToBack(horizontalLine);
    }
    this.isGridlinesEnabled = true;
    this.canvas.renderAll();
  }

  removeGridLines() {
    let gridLines = this.canvas.getObjects().filter(x => x.name == 'line');
    gridLines.forEach(line => {
      this.canvas.remove(line);
    });
    this.isGridlinesEnabled = false;
  }

  onSelectionCleared(event, that) {
    this.currentSelectedObject = null;
    if (this.selectedTabIndex == 0) {
      this.dashboardData.actions = [];
      this.dashboardData = { ...this.dashboardData };
    }
    this.fs.onSelectionCleared.next(true);
  }

  setcanvasRectValues() {
    this.canvas.renderAll();
  }

  ngDoCheck() {
    let flootOmittedval = omit(cloneDeep(this.floorPlan), ['Id', 'id', 'name', "id"]);
    let currentfloor = cloneDeep(this.arrayToBeCompared.filter(x => x.Id == this.selectedLayout)[0]);
    let originalomitval = omit(currentfloor, ['Id', 'id', 'name', "id"]);
    this.fs.equal = isEqual( omit(flootOmittedval,['SeatingTypeId','LocationGroupId']), omit(originalomitval)) && this.floorPlan.Id > 0;
    let isdefault = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0] && this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].IsDefault;
    if (Utilities.controlValidate(controlSettings.Location_layoutEditor_enable_edit, this.appService.PropertyType)) {
      this.editBtn.disbaledproperity = false;
    } else {
      this.editBtn.disbaledproperity = isdefault ? true : false;
    }
    this.deleteBtn.disbaledproperity = isdefault || this.floorPlan.Id == 0 ? true : false;
  }

  onObjectRotating(event, that) {
    let obj = event.target;
    let availabelObjects = this.canvas.getObjects().filter(x => x.Id == obj.Id)[0];
    if (availabelObjects._objects) {
      const table = this.floorPlan.StandaloneTables.filter(x => x.Id == obj.Id)[0];
      table.Angle = obj.angle;
      table.RotationAngle = obj.angle;
      availabelObjects._objects.forEach(element => {
        let shape =availabelObjects._objects.filter(x => x.name == 'shape');
        if (element.name == 'textCircle' || element.name == 'text') {
          element.set({
            angle: -obj.angle
          })
        } else if (element.name == 'tableNumber') {
          var absCoords = that.getAbsoluteCoords(shape[0]);
          element.set({
            top: (absCoords.top - element.height / 2 ) - 10,
            left: (absCoords.left - element.width / 2 ),
            originX: 'top',
            originY: 'left',
            textalign: 'center',
            angle: -obj.angle
          })
        }
      });
      availabelObjects.setCoords();
      that.canvas.renderAll();
    }

    if (event.target != null) {
      const objs = this.canvas.getActiveObjects();
      objs.forEach(element => {
        if (element.Id) {
        }
        else if (element.name == 'label') {
          const label = this.floorPlan.Labels.filter(x => x.id == element.data)[0];
          label.Text = element.text;
          label.Left = element.left;
          label.Top = element.top;
          label.RotationAngle = element.angle;
          label.Angle = element.angle;
        }
      });
    }

  }
  onObjectScalingDuplicate(object, that) {
    let obj = object.target;
    if (obj._objects) {
      let availabelObjects = this.canvas.getObjects().filter(x => x.Id == obj.Id);
      availabelObjects.forEach(element => {
        element.hasControls = false;
        const partySizeText = element._objects[5].textLines[0];
        const tableNumberText = element._objects[7].textLines[0];
        element._objects.splice(5, 3);
        const tableNumber = this.layoutFunctions.createTableNumberText(element, tableNumberText, this.layoutConfig, this.selectedLayout);
        const numberCircle = this.layoutFunctions.createTableNumberCircle(element, this.layoutConfig, this.selectedLayout);
        const partySize = this.layoutFunctions.createPartSizeNumber(element, this.layoutConfig, this.selectedLayout, partySizeText, numberCircle);
        element.addWithUpdate(partySize);
        element.addWithUpdate(numberCircle);
        element.addWithUpdate(tableNumber);
        element.hasControls = true;
      });

    }
  }

  onObjectScaling(object, that) {
    let obj = object.target;
    if (obj._objects) {
      if (obj != null && obj.Id) {
        this.joinedLayerRemoval(obj);
      }
      let table = this.floorPlan.StandaloneTables.filter(x => x.Id == obj.Id)[0];
      let availabelObjects = this.canvas.getObjects().filter(x => x.Id == obj.Id);
      let availabelObjectShape = obj._objects.filter(x => x.name == "shape")[0];
      let data = {
        height: availabelObjectShape.height * obj.scaleY, angle: obj.angle + obj.RotationAngle, width: availabelObjectShape.width * obj.scaleX, top: obj.top,
        left: obj.left, objScaleX: obj.scaleX, objScaleY: obj.scaleY
      };
      data.height = data.height < this.minObjectHeight ? 20 : data.height;
      data.width = data.width < this.minObjectWidth ? 20 : data.width;
      if (obj.ShapeType == TableShapeType.Image) {
        data['ImageId'] = availabelObjectShape.ImageId;
        data['scaleX'] = availabelObjectShape.scaleX;
        data['scaleY'] = availabelObjectShape.scaleY;
        let posX = object.pointer.x;
        let posY = object.pointer.y;
        data['imgWidth'] = (Math.abs((posX - data.left))) > that.minWidth ? (Math.abs((posX - data.left))) : that.minWidth;
        data['imgHeight'] = (Math.abs((posY - data.top))) > that.minHeight ? (Math.abs((posY - data.top))) : that.minHeight;
      }
      table.angle = obj.angle;
      availabelObjects.forEach(element => {
        this.canvas.remove(element);
      });

      this.canvas.requestRenderAll();
      this.setTableConfiguration(table, data);
      let activeObject = this.canvas.getObjects().filter(x => x.Id == obj.Id)[0];
      activeObject.rotate(obj.angle);
      activeObject._objects.forEach(element => {
        if (element.name == 'textCircle' || element.name == 'text') {
          element.set({
            angle: -obj.angle
          })
        }
      });
      if (obj.ShapeType !== undefined && obj.ShapeType !== ShapeTypeEnum.Pin.toLowerCase() && obj.ShapeType !== TableShapeType.Pin) {
        const size = activeObject.Party.Size && activeObject.Party.State == 1 ? activeObject.Party.Size : 0;
        const tableNumberText = activeObject.IsCommunalTable ? (size + '/' + activeObject.MaxPartySize.toString()) : activeObject.MaxPartySize.toString();
        this.createTableSizeElement(tableNumberText, activeObject, this);
      }
      this.canvas.setActiveObject(activeObject);
      if (obj != null && obj.Id) {
        this.joinedLayerAddition(obj);
      }
    }
  }

  joinedLayerRemoval(obj) {
    if (obj.Party.TableIds && obj.Party.TableIds.length > 0) {
      obj.Party.TableIds.forEach(element => {
        let joinedLayer = this.canvas.getObjects().filter(x => x.name == 'joinedLayer_' + element);
        if (joinedLayer.length > 0) {
          joinedLayer.forEach(layer => {
            this.canvas.remove(layer);
            let joinedIcon = this.canvas.getObjects().filter(x => x.name == 'join_' + element);
            this.canvas.remove(joinedIcon[0]);
          });
        }
      });
    }
  }

  joinedLayerAddition(obj) {
    if (obj.Party.TableIds && obj.Party.TableIds.length > 1) {
      obj.Party.TableIds.forEach(element => {
        let joinedLayer = this.canvas.getObjects().filter(x => x.name == 'joinedLayer_' + element);
        if (joinedLayer.length > 0) {
          joinedLayer.forEach(layer => {
            this.canvas.remove(joinedLayer[0]);
            let joinedIcon = this.canvas.getObjects().filter(x => x.name == 'join_' + element);
            this.canvas.remove(joinedIcon[0]);
          });
        }
        this.layoutFunctions.setJoinedLayer(this.canvas.getObjects().filter(x => x.Id == element)[0], this.canvas);
      });
    }
  }

  onObjectScaled(object, that) {
    let obj = object;
    if (obj.target != null) {
      const objs = this.canvas.getActiveObjects();
      objs.forEach(element => {
        if (element.Id) {
          const table = this.floorPlan.StandaloneTables.filter(x => x.Id == element.Id)[0];
          table.Width = element.width * element.scaleX;
          table.Height = element.height * element.scaleY;
        }
        else if (element.name == 'area') {
          const shape = this.floorPlan.Shapes.filter(x => x.id == element.data)[0];
          shape.Width = element.width * element.scaleX;
          shape.Height = element.height * element.scaleY;
        }
        else if (element.name == 'label') {
          const label = this.floorPlan.Labels.filter(x => x.id == element.data)[0];
          label.Text = element.text;
          label.Left = element.left;
          label.Top = element.top;
        }
        else if (element.name == 'wall') {
          const wall = this.floorPlan.Walls.filter(x => x.id == element.data)[0];
          let matrix = [];
          matrix = element.calcTransformMatrix();
          var translatedPoints = element.get('points').map(function (p) {
            return {
              x: p.x - element.pathOffset.x,
              y: p.y - element.pathOffset.y
            };
          });
          for (var i = 0; i < translatedPoints.length; i++) {
            translatedPoints[i].x = matrix[0] * translatedPoints[i].x + matrix[2] * translatedPoints[i].y + matrix[4];
            translatedPoints[i].y = matrix[1] * translatedPoints[i].x + matrix[3] * translatedPoints[i].y + matrix[5];
          }
          wall.Points = translatedPoints;

        }
      });
    }
  }
  drawObject(obj, that) {
    this.fs.selectedTableBeforeAction$.next(null);
    this.fs.joinedToCommunalError$.next({ value: false, name: '' });

    if (obj.target != null) {
      this.currentSelectedObject = obj.target;
      this.fs.onSelectionCleared.next(true);
      if (this.selectedTabIndex == 0) {
        if (obj.target.Id != undefined) {
          if (obj.target.ShapeType == 1) {
            obj.target.setControlsVisibility({
              ml: false,
              mt: false,
              mb: false,
              mr: false
            })
          }
        }
        else if (obj.target.name == "label") {
          obj.target.set({
            hasControls: true,
            hasRotatingPoint: true,
            hasBorders: true
          });
          obj.target.setControlsVisibility({
            ml: false,
            mt: false,
            mb: false,
            mr: false,
            tl: false,
            tr: false,
            bl: false,
            br: false
          });
          this.canvas.renderAll();
          this.fs.labelSettingsForm.controls.labelText.setValue(obj.target.text);
          this.fs.labelSelected$.next(obj.target);
        }
        else if (obj.target.name == "wall") {
          obj.target.set({
            hasControls: false,
            hasBorders: true
          })
          this.canvas.renderAll();
        }
        if (obj.target.Id != undefined) {
          this.fs.tableSelected$.next(obj.target);
          this.dashboardData.actions = [{
            type: buttonTypes.actionSecondary, label: 'deleteText', actionName: EditorStatus.DeleteTable,
            customclass: 'action-btn'
          },
          {
            type: buttonTypes.actionPrimary, label: 'cloneText',
            actionName: EditorStatus.CloneTable, customclass: 'action-btn'
          }];
        }
        else if (obj.target.name != 'line') {
          this.dashboardData.actions = [
            {
              type: buttonTypes.actionSecondary, label: 'deleteText', actionName: EditorStatus.DeleteTable,
              customclass: 'action-btn'
            },
            {
              type: buttonTypes.actionPrimary, label: 'cloneText',
              actionName: EditorStatus.CloneTable, customclass: 'action-btn'
            }
          ];
        }
        this.dashboardData = { ...this.dashboardData };
      }
      if (that.fs.joinTable) {
        if (obj.target.Id != undefined) {
          if (!obj.target.IsCommunalTable) {
            if (that.joinedTables.length > 0) {
              const index = that.joinedTables.findIndex(x => x == obj.target.Id);
              if (index == -1) {
                that.settingSelectedColor(obj.target);
                that.joinedTables.push(obj.target.Id);
                that.canvas.renderAll();
              } else {
                that.settingUnselectedColor(obj.target);
                that.joinedTables.splice(index, 1);
                that.canvas.renderAll();
              }
            } else {
              const table = this.tables.filter(x => x.Id == obj.target.Id)[0];
              that.settingSelectedColor(obj.target);
              that.joinedTables.push(table.Id);
              that.canvas.renderAll();
            }
            this.fs.throwCommunalTableError$.next(false);
            that.fs.joinedTables = that.joinedTables;
            that.joinButtonEnableSetting();
          }

          else {
            this.fs.throwCommunalTableError$.next(true);
          }
        }
      }
      else if (that.assignFloor && this.fs.selectedFloorId != null) {
        let index = -1;
        if (obj.target.Id != undefined) {
          index = that.objectsSelected.findIndex(x => x.Id == obj.target.Id);
        }
        else {
          if (obj.target.data != undefined) {
            index = that.objectsSelected.findIndex(x => (x.name == obj.target.name && (x.data == obj.target.data || x.id == obj.target.data)));
          }
        }
        if (index == -1) {
          that.objectsSelected.push(obj.target);
          that.settingSelectedColor(obj.target);
          that.canvas.renderAll();
        }
        else {
          that.objectsSelected.splice(index, 1);
          if (obj.target.Id != undefined) {
            this.floorPlan.StandaloneTables.filter(x => x.Id == obj.target.Id)[0].FloorPlanViewId = null;
          }
          else if (obj.target.name == 'area') {
            this.floorPlan.Shapes.filter(x => x.id == obj.target.data)[0].FloorPlanViewId = null;
          }
          else if (obj.target.name == 'wall') {
            this.floorPlan.Walls.filter(x => x.id == obj.target.data)[0].FloorPlanViewId = null;
          }
          else if (obj.target.name == 'label') {
            this.floorPlan.Labels.filter(x => x.id == obj.target.data)[0].FloorPlanViewId = null;
          }
          that.settingUnselectedColor(obj.target);
          that.canvas.renderAll();
        }
        this.assignToFloor();
      } else {
        if (this.canvas.getActiveObjects().length > 1) {
          this.fs.onSelectionCleared.next(true);
        }
        else {
          if (obj.target.Id) {
            this.fs.onSelectionCleared.next(false);
            obj.target = this.canvas.getActiveObject();

            this.notSettingDate = false;
            this.fs.tableSettingsForm.controls.tableNumber.setValue(obj.target.Name);
            if (this.fs.tableSettingsForm.controls.seatingType)
              this.fs.tableSettingsForm.controls.seatingType.setValue(obj.target.SeatingTypeId);
            this.fs.tableSettingsForm.controls.seatingArea.setValue(obj.target.SeatingAreaId);
            this.fs.tableSettingsForm.controls.availableForReservation.setValue(obj.target.IsAvailableForReservations);
            this.fs.tableSettingsForm.controls.minPartySize.setValue(obj.target.MinPartySize);
            this.fs.tableSettingsForm.controls.maxPartySize.setValue(obj.target.MaxPartySize);
            this.fs.tableSettingsForm.controls.communalTable.setValue(obj.target.IsCommunalTable);
            if(this.fs.tableSettingsForm?.controls?.LocationGroupId){
              this.fs.tableSettingsForm.controls.LocationGroupId.setValue(obj.target.LocationGroupId ? obj.target.LocationGroupId : -1  );
            }
            if(this.isBufferTimeEnabled){
              this.fs.tableSettingsForm.controls.bufferTimeInMinutes?.setValue(obj.target.BufferTimeInMinutes);
            }
            this.fs.selectedTableBeforeAction$.next(obj.target);
            this.notSettingDate = true;
            this.fs.selectedSeatingTypeId.next(obj.target.SeatingTypeId);
          }
        }
      }
    } else {
      if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Rectangle.toLowerCase()) {
        that.isMouseDown = true;
        if (that.freeDrawing) {
          that.rectangle = new fabric.Rect({
            left: that.divPos?.left > 14 ? that.divPos?.left : 14,
            top: that.divPos?.top > 14 ? that.divPos?.top : 14,
            fill: '#f2f2f2',
            rx: 2,
            ry: 2
          });
          that.rectangle.set({
            name: 'shape',
            maxpartysize: 2
          });
          that.refRectangle = that.rectangle;  // **Reference of rectangle object
        }
      } else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Circle.toLowerCase()) {
        that.isMouseDown = true;
        if (that.freeDrawing) {
          that.circle = new fabric.Circle({
            left: that.divPos?.left > 14 ? that.divPos?.left : 14,
            top: that.divPos?.top > 14 ? that.divPos?.top : 14,
            radius: that.minRadius,
            fill: '#f2f2f2'
          });
          that.circle.setControlsVisibility({
            ml: false,
            mt: false,
            mr: false,
            mb: false,
          });
          that.circle.set({
            name: 'shape',
            minpartysize: 4
          });
          that.refCircle = that.circle;  // **Reference of rectangle object
        }
      }
      else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Text.toLowerCase()) {
        that.isMouseDown = true;
      }
      else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Area.toLowerCase()) {
        that.isMouseDown = true;
        if (that.freeDrawing) {
          that.area = new fabric.Rect({
            left: that.divPos?.left,
            top: that.divPos?.top,
            fill: 'none',
            stroke: '#3B4532',
            strokeWidth: 2,
            strokeDashArray: [8, 3],
            fillRule: 'none',


          });
          that.area.set({
            Name: 'area',
            FloorPlanViewId: null
          });
          that.refArea = that.area;  // **Reference of rectangle object
        }
      }
      else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Wall.toLowerCase()) {
        that.isMouseDown = true;
        that.drawingObject.type = 'roof';
        if (that.drawingObject.type == "roof") {
          that.canvas.selection = false;
          that.setStartingPoint(event); // set x,y
          let data = that.Point(that.x, that.y);
          that.roofPoints.push(data);
          let points = [that.x, that.y, that.x, that.y];
          that.lines.push(new fabric.Line(points, {
            strokeWidth: 2,
            selectable: false,
            stroke: '#3B4532',

          }));
          that.canvas.add(that.lines[that.lineCounter]);
          that.lineCounter++;
        }
      } else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.BasicRectangle.toLowerCase()) {
        that.isMouseDown = true;
        if (that.freeDrawing) {
          that.basicRectangle = new fabric.Rect({
            left: that.divPos?.left > 14 ? that.divPos?.left : 14,
            top: that.divPos?.top > 14 ? that.divPos?.top : 14,
            fill: '#f2f2f2',
            rx: 2,
            ry: 2
          });
          that.basicRectangle.set({
            name: 'shape',
            maxpartysize: 2
          });
          that.basicRefRectangle = that.basicRectangle;
        }
      } else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.BasicCircle.toLowerCase()) {
        that.isMouseDown = true;
        if (that.freeDrawing) {
          that.basicCircle = new fabric.Circle({
            left: that.divPos?.left > 14 ? that.divPos?.left : 14,
            top: that.divPos?.top > 14 ? that.divPos?.top : 14,
            radius: that.bCircleRadius,
            fill: '#f2f2f2',
            strokeWidth: 1,
          });
          that.basicCircle.setControlsVisibility({
            ml: false,
            mt: false,
            mr: false,
            mb: false
          });
          that.basicCircle.set({
            name: 'shape',
            minpartysize: 4
          });
          that.basicRefCircle = that.basicCircle;
        }
      } else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Pin.toLowerCase()) {
        that.pin = new fabric.Circle({
          left: that.divPos?.left > 14 ? that.divPos?.left : 14,
          top: that.divPos?.top > 14 ? that.divPos?.top : 14,
          radius: that.minPinRadius,
          fill: this.seatingTypes.length > 0 ? this.seatingTypes[0].color : '#f2f2f2',
          strokeWidth: 1,
          stroke: this.seatingTypes.length > 0 ? this.seatingTypes[0].color : '#f2f2f2'
        });
        that.pin.setControlsVisibility({
          ml: false,
          mt: false,
          mr: false,
          mb: false
        });
        that.pin.set({
          name: 'shape',
          minpartysize: 4
        });
        that.refPin = that.pin;
        that.refPin.type = TableShapeType.Pin;
        that.addTableGroupAdd(that, that.refPin);
      } else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Image.toLowerCase()) {
        const image = document.createElement('img');
        image.src = 'data:image/png;base64,' + this.fs.customObjectImage.bytes;
        that.isMouseDown = true;
        if (that.freeDrawing) {
          that.image = new fabric.Image(image, {
            left: that.divPos?.left > 14 ? that.divPos?.left : 14,
            top: that.divPos?.top > 14 ? that.divPos?.top : 14,
            width: image.width,
            height: image.height
          });
          that.image.setControlsVisibility({
            ml: false,
            mt: false,
            mr: false,
            mb: false
          });
          that.image.set({
            name: 'shape',
            minpartysize: 4
          });
          that.refImage = that.image;
        }
      }
    }
    if (obj.target && obj.target.ShapeType !== undefined && obj.target.ShapeType !== ShapeTypeEnum.Pin.toLowerCase() && obj.target.ShapeType !== TableShapeType.Pin) {
      const size = obj.target.Party.Size && obj.target.Party.State == 1 ? obj.target.Party.Size : 0;
      const tableNumberText = obj.target.IsCommunalTable ? (size + '/' + obj.target.MaxPartySize.toString()) : obj.target.MaxPartySize.toString();
      this.createTableSizeElement(tableNumberText, obj.target, this);
    }
  }

  RGBAToHexA(r, g, b) {
    r = Number(r).toString(16);
    g = Number(g).toString(16);
    b = Number(b).toString(16);
    if (r.length == 1)
      r = "0" + r;
    if (g.length == 1)
      g = "0" + g;
    if (b.length == 1)
      b = "0" + b;
    return "#" + r + g + b;
  }
  setStartingPoint(options) {
    var offset = this.canvas._offset;
    this.x = options.e ? (options.e.pageX - offset.left) : (options.pageX - offset.left);
    this.y = options.e ? (options.e.pageY - offset.top) : (options.pageY - offset.top);
  }

  onMouseDoubleClick(obj, that) {
    if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Text.toLowerCase()) {
      that.isMouseDown = false;
    }
    else if (this.mouseMoved && that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Wall.toLowerCase()) {
      that.lines.forEach(function (value, index, ar) {
        that.canvas.remove(value);
      });
      that.roof = that.makeRoof(that.roofPoints);
      that.roof.set({
        fill: '',
        strokeWidth: 2,
        stroke: '#3B4532'

      })
      if (that.isMouseDown && that.mouseMoved) {
        that.isMouseDown = false;
        that.mouseMoved = false;
        let wallsAvailable = this.floorPlan.Walls;
        let walls = wallsAvailable.length > 0 ? maxBy(wallsAvailable, 'id') : { id: 0 };
        that.roof.set({
          data: walls['id'] + 1,
          name: "wall",
          FloorPlanViewId: null,
          perPixelTargetFind: true
        });
        let point = [];
        this.roofPoints.forEach(element => {
          point.push({ X: element.x, Y: element.y })
        });
        const newWall = {
          FloorPlanViewId: null,
          Points: point,
          id: walls['id'] + 1,
          name: "wall"
        };
        this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].Walls.push(newWall);
      }
      this.floorPlan = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0];
      that.canvas.add(that.roof);
      that.canvas.sendToBack(that.roof);
      that.canvas.renderAll();
      that.canvas.selection = true;
      that.roofPoints = [];
      that.lines = [];
      that.lineCounter = 0;
      that.mouseMoved = false;
    }
  }

  makeRoof(roofPoints) {
    if (roofPoints) {
      var left = this.findLeftPaddingForRoof(roofPoints);
      var top = this.findTopPaddingForRoof(roofPoints);
      var roof = new fabric.Polyline(roofPoints, {
        fill: 'rgba(0,0,0,0)',
        stroke: '#3B4532'
      });
      roof.set({
        left: left,
        top: top,

      });

      return roof;
    }
  }
  findLeftPaddingForRoof(roofPoints) {
    var result = 999999;
    for (var i = 0; i < this.lineCounter; i++) {
      if (roofPoints[i].x < result) {
        result = roofPoints[i].x;
      }
    }
    return Math.abs(result);
  }


  findTopPaddingForRoof(roofPoints) {
    var result = 999999;
    for (var f = 0; f < this.lineCounter; f++) {
      if (roofPoints[f].y < result) {
        result = roofPoints[f].y;
      }
    }
    return Math.abs(result);
  }

  Point(x, y) {
    this.x = x;
    this.y = y;
    return ({ x: this.x, y: this.y })
  }

  JoinTable() {
    this.joinTable = false;
    let formData;
    const joinedTable = {
      Id: this.joinedTablesArray.length > 0 ? maxBy(this.joinedTablesArray, 'Id')['Id'] + 1 : 0, IsNew: true, LayoutId: this.floorPlan.Id, MaxPartySize: this.fs.joinedTablesForm.controls.maxPartySize.value,
      MinPartySize: this.fs.joinedTablesForm.controls.minPartySize.value, StandaloneTableIds: []
    };
    this.joinedTables.forEach(element => {
      joinedTable.StandaloneTableIds.push(element);
    });
    this.joinedTablesArray.push(joinedTable);
    const objs = this.canvas.getObjects();
    this.joinedTablesArray.forEach(joinedTable => {
      joinedTable.StandaloneTableIds.forEach(table => {
        const tables = objs.filter(x => x.Id == table);
        if (tables.length > 0) {
          if (table == tables[0].Id) {
            const data = tables[0]._objects.filter(x => x.name == 'join');
            if (data.length == 0) {
            }
          }
        }
      });

      this.undoSelection();
    });
    this.fs.joinTable = false;
    this.fs.floorPlan = this.floorPlan;
    this.fs.floorPlan.JoinedTables = this.joinedTablesArray;
    this.fs.layoutChange$.next(this.selectedLayout);
    this.fs.dataChanged$.next('change');
    this.removeHightLightColor();
    this.canvas.renderAll();
    const eve = { index: 2 };
    this.tabChanged(eve);
  }

  undoSelection() {
    const objs = this.canvas.getObjects();
    objs.forEach(object => {
      if (object._objects) {
        this.settingUnselectedColor(object);
      }
    });
    this.canvas.renderAll();
  }

  deleteJoinedTable(i) {
    this.joinedTablesArray.splice(i, 1);
    this.fs.dataChanged$.next(true);
  }

  editJoinedTable(i) {
    if (this.fs.fromEdit) {
      this.joinedTables = [];
      let ObjectsInSelectedFloor = [];
      let tableCount = 0;
      const objs = this.canvas.getObjects();
      this.joinedTablesArray = this.floorPlan.JoinedTables;
      this.joinedTablesArray[i].StandaloneTableIds.forEach(element => {
        this.joinedTables.push(element);
        const obj = objs.filter(x => x.Id == element)[0];
        if (obj) {
          tableCount++;
          ObjectsInSelectedFloor.push(obj);
        }
      });
      this.canvas.renderAll();
      if (!(this.selectedFloorPlanId != -1 && tableCount != this.joinedTablesArray[i].StandaloneTableIds.length)) {

        ObjectsInSelectedFloor.forEach(obj => {
          this.settingSelectedColor(obj);
        })
        this.canvas.renderAll();
        this.fs.joinedTablesForm.controls.maxPartySize.setValue(this.joinedTablesArray[i].MaxPartySize);
        this.fs.joinedTablesForm.controls.minPartySize.setValue(this.joinedTablesArray[i].MinPartySize);
        this.fs.joinTable = true;
        this.selectedIndex = i;
        this.showUpdate = true;
        this.fs.joinedTables = this.joinedTables;

        this.dashboardData.actions = [{
          type: buttonTypes.actionSecondary, label: 'cancel', actionName: EditorStatus.CancelJoinTables,
          customclass: 'action-btn'
        },
        {
          type: buttonTypes.actionPrimary, label: 'update', actionName: EditorStatus.UpdateJoinTable,
          customclass: 'action-btn'
        }];
        this.dashboardData = { ...this.dashboardData };
      }
    }
  }

  updateJoinTable() {
    this.joinedTablesArray[this.selectedIndex].StandaloneTableIds = [];
    this.joinedTablesArray[this.selectedIndex].MaxPartySize = this.fs.joinedTablesForm.controls.maxPartySize.value;
    this.joinedTablesArray[this.selectedIndex].MinPartySize = this.fs.joinedTablesForm.controls.minPartySize.value;
    const StandaloneTableIds = [];

    this.joinedTables.forEach(element => {
      StandaloneTableIds.push(element);
    });
    this.joinedTablesArray[this.selectedIndex].StandaloneTableIds = StandaloneTableIds;
    const objs = this.canvas.getObjects();
    this.joinedTablesArray.forEach(joinedTable => {
      joinedTable.StandaloneTableIds.forEach(table => {
        objs.forEach(object => {
          if (table.name == object.name) {
          }
        });
      });
    });
    this.removeHightLightColor();
    this.canvas.renderAll();
    this.undoSelection();
    this.showUpdate = false;
    this.fs.joinTable = false;
    const eve = { index: 2 };
    this.tabChanged(eve);
  }

  onMouseMove(obj, that) {
    that.divPos = {
      left: obj.pointer.x,
      top: obj.pointer.y
    };
    if (!that.isMouseDown) {
      if (obj.target != null) {
        let element = obj.target;
      }
      return;
    }
    if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Rectangle.toLowerCase()) {
      if (that.freeDrawing) {
        const posX = that.divPos?.left;
        const posY = that.divPos?.top;
        that.mouseMoved = true;
        if (typeof that.rectangle !== 'undefined') {
          that.refRectangle?.set({
            width: (Math.abs((posX - that.rectangle.get('left')))) > that.minWidth ? (Math.abs((posX - that.rectangle.get('left')))) : that.minWidth,
            height: (Math.abs((posY - that.rectangle.get('top')))) > that.minHeight ? (Math.abs((posY - that.rectangle.get('top')))) : that.minHeight,
          });
        }

        that.canvas.renderAll();
      }
    } else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Circle.toLowerCase()) {
      if (that.freeDrawing) {
        const posX = that.divPos?.left;
        const posY = that.divPos?.top;
        that.mouseMoved = true;
        if (typeof that.refCircle !== 'undefined') {
          that.refCircle.set({
            radius: Math.abs((posX - that.refCircle.get('left')) / 2) > that.minRadius ? Math.abs((posX - that.refCircle.get('left')) / 2) : that.minRadius
          });
        }
        that.canvas.renderAll();
      }
    }
    else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Text.toLowerCase()) {
    }
    else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Area.toLowerCase()) {
      if (that.freeDrawing) {
        const posX = that.divPos?.left;
        const posY = that.divPos?.top;
        that.mouseMoved = true;
        if (typeof that.area !== 'undefined') {
          that.refArea.set({
            width: (Math.abs((posX - that.area.get('left')))) > that.minWidth ? (Math.abs((posX - that.area.get('left')))) : that.minWidth,
            height: (Math.abs((posY - that.area.get('top')))) > that.minHeight ? (Math.abs((posY - that.area.get('top')))) : that.minHeight,
          });
        }

        that.canvas.renderAll();
      }
    }
    else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Wall.toLowerCase()) {
      if (that.lines[0] !== null && that.lines[0] !== undefined && that.drawingObject.type == "roof") {
        that.setStartingPoint(obj);
        that.mouseMoved = true;
        that.lines[that.lineCounter - 1].set({
          x2: that.x,
          y2: that.y
        });
        that.canvas.renderAll();
      }
    } else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.BasicRectangle.toLowerCase()) {
      if (that.freeDrawing) {
        const posX = that.divPos?.left;
        const posY = that.divPos?.top;
        that.mouseMoved = true;
        if (typeof that.basicRectangle !== 'undefined') {
          that.basicRefRectangle.set({
            width: (Math.abs((posX - that.basicRectangle.get('left')))) > that.minWidth ? (Math.abs((posX - that.basicRectangle.get('left')))) : that.minWidth,
            height: (Math.abs((posY - that.basicRectangle.get('top')))) > that.minHeight ? (Math.abs((posY - that.basicRectangle.get('top')))) : that.minHeight,
          });
        }
        that.canvas.renderAll();
      }
    } else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.BasicCircle.toLowerCase()) {
      if (that.freeDrawing) {
        const posX = that.divPos?.left;
        const posY = that.divPos?.top;
        that.mouseMoved = true;
        if (typeof that.basicRefCircle !== 'undefined') {
          that.basicRefCircle.set({
            radius: that.bCircleRadius
          });
        }
        that.canvas.renderAll();
      }
    } else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Pin.toLowerCase()) {
      if (that.freeDrawing) {
        const posX = that.divPos?.left;
        const posY = that.divPos?.top;
        that.mouseMoved = true;
        if (typeof that.refPin !== 'undefined') {
          that.refPin.set({
            radius: Math.abs((posX - that.refPin.get('left')) / 2) > that.minPinRadius ? Math.abs((posX - that.refPin.get('left')) / 2) : that.minPinRadius
          });
        }
        that.canvas.renderAll();
      }
    } else if (that.fs.selectedType.value.toLowerCase() == ShapeTypeEnum.Image.toLowerCase()) {
      if (that.freeDrawing) {
        const posX = that.divPos?.left;
        const posY = that.divPos?.top;
        that.mouseMoved = true;
        if (typeof that.image !== 'undefined') {
          let imgWidth = (Math.abs((posX - that.image.get('left')))) > that.minWidth ? (Math.abs((posX - that.image.get('left')))) : that.minWidth;
          let imgHeight = (Math.abs((posY - that.image.get('top')))) > that.minHeight ? (Math.abs((posY - that.image.get('top')))) : that.minHeight;
          that.refImage.set({
            imgWidth,
            imgHeight,
            scaleX: imgWidth / that.image.width,
            scaleY: imgHeight / that.image.height
          });
        }
        that.canvas.renderAll();
      }
    }
  }

  onMouseUp(obj, that) {
    if (that.fs.selectedType.value == ShapeTypeEnum.Rectangle.toLowerCase()) {

      if (that.isMouseDown && that.mouseMoved) {
        that.mouseMoved = false;
        that.refRectangle.type = 0;
        that.addTableGroupAdd(that, that.refRectangle);

      }
      that.isMouseDown = false;
    } else if (that.fs.selectedType.value == ShapeTypeEnum.Circle.toLowerCase()) {
      if (that.isMouseDown && that.mouseMoved) {
        that.mouseMoved = false;
        that.refCircle.type = 1;
        that.addTableGroupAdd(that, that.refCircle);
      }
      that.isMouseDown = false;
    } else if (this.fs.selectedType.value == ShapeTypeEnum.Text.toLowerCase()) {
      if (that.isMouseDown) {
        that.isMouseDown = false;
        that.mouseMoved = false;
      }
    } else if (this.fs.selectedType.value == ShapeTypeEnum.Area.toLowerCase()) {
      if (that.isMouseDown && that.mouseMoved) {
        let shapesAvailable = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].Shapes
        let shapes = shapesAvailable.length > 0 ? maxBy(shapesAvailable, 'id') : { id: 0 };
        let newArea = this.layoutFunctions.createNewArea(that.refArea, shapes, false);

        that.refArea.set({
          data: shapes['id'] + 1,
          name: "area",
          fill: 'transparent',
          perPixelTargetFind: true,
          targetFindTolerance: 2
        })
        this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].Shapes.push(newArea);
        this.floorPlan = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0];
        that.canvas.add(that.refArea);
        that.canvas.sendToBack(that.refArea);
        that.isMouseDown = false;
        that.mouseMoved = false;
      }
    } else if (this.fs.selectedType.value == ShapeTypeEnum.BasicRectangle.toLowerCase()) {
      if (that.isMouseDown && that.mouseMoved) {
        that.mouseMoved = false;
        that.basicRefRectangle.type = 3;
        that.addTableGroupAdd(that, that.basicRefRectangle);
      }
      that.isMouseDown = false;
    } else if (this.fs.selectedType.value == ShapeTypeEnum.BasicCircle.toLowerCase()) {
      if (that.isMouseDown && that.mouseMoved) {
        that.mouseMoved = false;
        that.basicRefCircle.type = 4;
        that.addTableGroupAdd(that, that.basicRefCircle);
      }
      that.isMouseDown = false;
    } else if (this.fs.selectedType.value == ShapeTypeEnum.Image.toLowerCase()) {
      if (that.isMouseDown && that.mouseMoved) {
        that.mouseMoved = false;
        that.refImage.type = TableShapeType.Image;
        that.addTableGroupAdd(that, that.refImage);
      }
      that.isMouseDown = false;
    }
    if (obj.target && obj.target.ShapeType !== undefined && obj.target.ShapeType !== ShapeTypeEnum.Pin.toLowerCase() && obj.target.ShapeType !== TableShapeType.Pin) {
      const size = obj.target.Party.Size && obj.target.Party.State == 1 ? obj.target.Party.Size : 0;
      const tableNumberText = obj.target.IsCommunalTable ? (size + '/' + obj.target.MaxPartySize.toString()) : obj.target.MaxPartySize.toString();
      this.createTableSizeElement(tableNumberText, obj.target, this);
    }
    let similarObjectAvailableCenterVertical = this.canvas.getObjects().filter(x => x.name == 'similarObjectAvailableCenterVertical');
    similarObjectAvailableCenterVertical.forEach(element => {
      this.canvas.remove(element);
    });
    let similarObjectAvailableCenterHorizontal = this.canvas.getObjects().filter(x => x.name == 'similarObjectAvailableCenterHorizontal');
    similarObjectAvailableCenterHorizontal.forEach(element => {
      this.canvas.remove(element);
    });
    let similarObjectAvailableRightSideLine = this.canvas.getObjects().filter(x => x.name == 'similarObjectsAvailableRightSide');
    similarObjectAvailableRightSideLine.forEach(element => {
      this.canvas.remove(element);
    });
    let similarObjectAvailableLeftSideLine = this.canvas.getObjects().filter(x => x.name == 'similarObjectAvailableLeftSideLine');
    similarObjectAvailableLeftSideLine.forEach(element => {
      this.canvas.remove(element);
    });
    let similarObjectAvailableBottomSideLine = this.canvas.getObjects().filter(x => x.name == 'similarObjectsAvailableBottomSides');
    similarObjectAvailableBottomSideLine.forEach(element => {
      this.canvas.remove(element);
    });
    let similarObjectAvailableTopSideLine = this.canvas.getObjects().filter(x => x.name == 'similarObjectAvailableTopSideLine');
    similarObjectAvailableTopSideLine.forEach(element => {
      this.canvas.remove(element);
    });
  }

  objectSelection(e, that) {
    if (e.target.type === 'activeSelection') {
      that.canvas.discardActiveObject();
    }
    if (e.target != null && e.target.Id && !this.fs.joinTable) {
      let element = that.canvas.getObjects().find(x => x.Id == e.target.Id);
      that.canvas.setActiveObject(element);
      this.fs.tableSelected$.next(e.target);
      this.dashboardData.actions = [{
        type: buttonTypes.actionSecondary, label: 'deleteText', actionName: EditorStatus.DeleteTable,
        customclass: 'action-btn'
      },
      {
        type: buttonTypes.actionPrimary, label: 'cloneText',
        actionName: EditorStatus.CloneTable, customclass: 'action-btn'
      }];
      this.dashboardData = { ...this.dashboardData };
      this.notSettingDate = false;
      this.fs.tableSettingsForm.controls.tableNumber.setValue(e.target.Name);
      if (this.fs.tableSettingsForm.controls.seatingType)
        this.fs.tableSettingsForm.controls.seatingType.setValue(e.target.SeatingTypeId);
      this.fs.tableSettingsForm.controls.seatingArea.setValue(e.target.SeatingAreaId);
      this.fs.tableSettingsForm.controls.availableForReservation.setValue(e.target.IsAvailableForReservations);
      this.fs.tableSettingsForm.controls.minPartySize.setValue(e.target.MinPartySize);
      this.fs.tableSettingsForm.controls.maxPartySize.setValue(e.target.MaxPartySize);
      this.fs.tableSettingsForm.controls.communalTable.setValue(e.target.IsCommunalTable);
      if(this.isBufferTimeEnabled){
        this.fs.tableSettingsForm.controls.bufferTimeInMinutes?.setValue(e.target.BufferTimeInMinutes);
      }
      if(this.fs.tableSettingsForm?.controls?.LocationGroupId){
        this.fs.tableSettingsForm.controls.LocationGroupId.setValue(e.target.LocationGroupId ? e.target.LocationGroupId : -1  );
      }
      this.fs.selectedTableBeforeAction$.next(e.target);
      this.notSettingDate = true;
      this.fs.selectedSeatingTypeId.next(e.target.SeatingTypeId);
    }
  }

  addJointable() {
    const initialPartySize = 1;
    this.fs.selectedType.next('');
    this.fs.joinTable = true;
    this.joinedTables = this.fs.joinedTables = [];
    this.fs.joinedTablesForm.controls.minPartySize.setValue(initialPartySize);
    this.fs.joinedTablesForm.controls.maxPartySize.setValue(initialPartySize);
    this.dashboardData.actions = [{
      type: buttonTypes.actionSecondary, label: 'cancel', actionName: EditorStatus.CancelJoinTables,
      customclass: 'action-btn'
    },
    {
      type: buttonTypes.actionPrimary, label: 'addText', actionName: EditorStatus.AddJoinTables,
      customclass: 'action-btn'
    }];
    this.dashboardData = { ...this.dashboardData };
    this.joinButtonEnableSetting();
  }

  setSelectedTableDetails(tableData) {
    const objs = this.canvas.getObjects();
    const selectedTbObject = objs.filter(obj => obj.Id == tableData.Id);
    if (selectedTbObject.length > 0) {
      this.setSeletedObject(selectedTbObject[0]);
      this.setTableSettingsForm(selectedTbObject[0]);
      this.dashboardData.actions = [{
        type: buttonTypes.actionSecondary, label: 'deleteText', actionName: EditorStatus.DeleteTable,
        customclass: 'action-btn'
      },
      {
        type: buttonTypes.actionPrimary, label: 'cloneText',
        actionName: EditorStatus.CloneTable, customclass: 'action-btn'
      }];
      this.fs.onSelectionCleared.next(false);
      this.fs.selectedTableBeforeAction$.next(tableData);
    } else {
      this.fs.onSelectionCleared.next(true);
    }
  }

  ngOnChanges() {
    if(this.layoutConfig){
      this.layoutConfig.floor[0].StandaloneTables = this.layoutConfig.floor[0].StandaloneTables.filter(table => !table.IsTemplate);
      this.layoutConfig.floor[0].StandaloneTables.forEach(table =>{
        table.LocationGroupId = table.LocationGroupId || null;
        table.SeatingTypeId = table.SeatingTypeId || null;
      })
    }
    this.fs.isNewlyaddedLayout = false;
    if (this.layoutConfig.layout && !this.holdRerender && this.layoutConfig.type == LayoutUpdateStatus.New) {
      this.layoutData = cloneDeep(this.layoutConfig);
      this._layout = this.layoutConfig.layout;
      this._setting = this.layoutConfig.settings;
      this.fs._settings = this.layoutConfig.settings;
      this.setCanvas();
      this.canvas.clear();
      this.clearTableSizeElement();
      this.blockingRule = [];
      let defaultFloorPlanData = this._layout.FloorPlans.filter(x => x.IsDefault == true)[0];
      let defaultFloorPlanID = defaultFloorPlanData.Id;
      this.setTableBlocking();
      // this.layoutConfig.blockingRules.forEach(element => {
      //   element.TableIds.forEach(table => {
      //     this.blockingRule.push(table);
      //   });
      // });
      let lastSelectedTableData = cloneDeep(this.fs.selectedTableBeforeAction$.value);

      if (this.selectedLayoutID.value && this.selectedLayoutID.value != defaultFloorPlanID) {
        this.setData();
        this.floorPlanSelctionConfig[0].value = this.selectedLayoutID.value;
        this.setSelectedLayout(this.selectedLayoutID);
      } else {
        this.tables = defaultFloorPlanData.StandaloneTables.filter(table => !table.IsTemplate);
        this.labels = defaultFloorPlanData.Labels;
        this.walls = defaultFloorPlanData.Walls;
        this.shapes = defaultFloorPlanData.Shapes;
        this.setData();
        this.setDefaultFloor(this.tables, this.labels, this.walls, this.shapes);
        this.generateTableSize();
        this.isInitial = false;
        let index = this.arrayToBeCompared.findIndex(x => x.Id == this.floorPlan.Id);
        this.arrayToBeCompared.splice(index, 1, cloneDeep(this.floorPlan));
      }

      if (lastSelectedTableData && this.selectedTabIndex == 0) {
        this.setSelectedTableDetails(lastSelectedTableData);
      }
      this.fs.dataChanged$.next('changed');
    }

    else if (this.layoutConfig && this.layoutConfig.type == LayoutUpdateStatus.Update) {
      const that = this;
      let defaultFloorPlanData = this._layout.FloorPlans.filter(x => x.IsDefault == true)[0];
      let defaultFloorPlanID = defaultFloorPlanData.Id;

      if (this.selectedLayoutID.value && this.selectedLayoutID.value != defaultFloorPlanID) {
        this.layoutConfig.floor = this.layoutConfig.layout.FloorPlans.filter(x => x.Id == this.selectedLayoutID.value);
      }
      const canvasObjects = this.canvas.getObjects();
      this.layoutFunctions.blockingRuleChanges(canvasObjects, this.layoutConfig, this.layoutData, this.blockingRule);
      let tablesToBeAdded = this.layoutFunctions.tableChanges(this.layoutConfig, this.layoutData, this.canvas);
      if (tablesToBeAdded.length > 0) {
        tablesToBeAdded.forEach(element => {
          if (that.canvas) {
            let alreadyAvailableTable = that.canvas.getObjects().filter(x => x.Id == element.Id);
            alreadyAvailableTable.forEach(availableTable => {
              that.canvas.remove(availableTable);
            });
          }
          let data = { height: element.Height * element.scaleY, angle: element.Angle, width: element.Width * element.scaleX, top: element.Top, left: element.Left };
          this.setTableConfiguration(element, data);
        });
      }
      this.partyDataChanges();
      this.dataChanges(this.layoutConfig, this.layoutData);
      this.tabChanged({ index: 0 })
      this.layoutFloorPlans = cloneDeep(this.layoutConfig.layout.FloorPlans);
      this._layout = this.layoutConfig.layout;
      this.arrayToBeCompared = cloneDeep(this.layoutConfig.layout.FloorPlans);
      this.floorPlan = this.fs.floorPlan = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0]
      this.tables = this.floorPlan.StandaloneTables.filter(table => !table.IsTemplate);
      this.labels = this.floorPlan.Labels;
      this.shapes = this.floorPlan.Shapes;
      this.walls = this.floorPlan.Walls;
      this.canvas.renderAll();
      this.layoutData = cloneDeep(this.layoutConfig)
    }

    if (!(this.arrayToBeCompared.find(x => x.Id == -1))) {
      this.AddEmptyFloorPlan();
    }

    if (this.isGridlinesEnabled) {
      this.setGridLines(this.selectedGridValue);
    }
  }

  generateTableSize() {
    const divElement = document.getElementById('inline-btn');
    divElement.innerHTML = '';
    const objs = this.canvas.getObjects();
    objs.forEach(object => {
      if (object.ShapeType != undefined) {
        const size = object.Party.Size && object.Party.State == 1 ? object.Party.Size : 0;
        const tableNumberText = object.IsCommunalTable ? (size + '/' + object.MaxPartySize.toString()) : object.MaxPartySize.toString();
        if (object.ShapeType !== TableShapeType.Pin && object.ShapeType !== ShapeTypeEnum.Pin)
        this.createTableSizeElement(tableNumberText, object, this);
      }
    })
  }
  checkisTimeBetween(starttime, endtime, timetocheck) {
    let sTime = starttime.split(":");
    let eTime = endtime.split(":");
    let StartSlottime = new Date(timetocheck).getHours() + (new Date(timetocheck).getMinutes() / 60);
    let getStartval = Number(sTime[0]) + (Number(sTime[1]) / 60);
    let getEndval = Number(eTime[0]) + (Number(eTime[1]) / 60);

    if (getStartval <= StartSlottime && getEndval >= StartSlottime) {
      return true;
    } else {
      return false;
    }
  }

  setTableBlocking() {
    this.blockingRule = [];
    let currentdatetime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    if (this.layoutConfig.blockingRules) {
      this.layoutConfig.blockingRules.forEach(rule => {
        if (rule.Frequency.Type == TableBlockingRuleFrequencyType.EveryDay) {
          if (rule.Shifts) {
            rule.TableIds.forEach(table => {
              this.blockingRule.push(table)
            });
          } else {
            if (this.checkisTimeBetween(rule.EffectiveRange[0].Start, rule.EffectiveRange[0].End, currentdatetime)) {
              rule.TableIds.forEach(table => {
                this.blockingRule.push(table)
              });
            }

          }

        }
        else if (rule.Frequency.Type == TableBlockingRuleFrequencyType.EveryWeekDay) {
          let shifts = this.cs.settings.value.Shifts.filter(shift => shift.DayOfWeek == rule.Frequency.DayOfWeek);
          if (rule.BlockBy == TableBlockBy.ByShift) {
            if (shifts.length == rule.Shifts.length) {
              rule.TableIds.forEach(table => {
                this.blockingRule.push(table)
              });
            }
            else {
              rule.Shifts.forEach(shift => {
                if (this.tableservice.restaurantShiftId == shift.Id) {
                  rule.TableIds.forEach(table => {
                    this.blockingRule.push(table)
                  });
                }
              })
            }
          }
          else {
            if (rule.DayOfWeek == currentdatetime.getDate() && this.checkisTimeBetween(rule.EffectiveRange[0].Start, rule.EffectiveRange[0].End, currentdatetime)) {
              rule.TableIds.forEach(table => {
                this.blockingRule.push(table)
              });
            }
          }
        }
        else if (rule.Frequency.Type == TableBlockingRuleFrequencyType.TheOnlyDay) {
          let DateSelected = new Date(rule.Frequency.Date);
          let todaysShifts = Utilities.getRestaurantShiftForDay(this.cs.settings.value.Shifts, DateSelected)
          if (rule.BlockBy == TableBlockBy.ByShift) {
            if (todaysShifts.length == rule.Shifts.length) {
              rule.TableIds.forEach(table => {
                this.blockingRule.push(table)
              });
            }
            else {
              rule.Shifts.forEach(shift => {
                if (this.tableservice.restaurantShiftId == shift.Id) {
                  rule.TableIds.forEach(table => {
                    this.blockingRule.push(table)
                  });
                }
              })
            }
          }
          else {
            if (this.checkisTimeBetween(rule.EffectiveRange[0].Start, rule.EffectiveRange[0].End, currentdatetime)) {
              rule.TableIds.forEach(table => {
                this.blockingRule.push(table)
              });
            }
          }
        }
      });
    }
  }

  dataChanges(layoutConfig, layoutData) {
    let that = this;
    const objs = that.canvas.getObjects();
    this.propertyChanges.forEach(val => {
      let availableData;
      switch (val) {
        case 'Labels':
          availableData = that.canvas.getObjects().filter(x => x.name == 'label');
          availableData.forEach(element => {
            that.canvas.remove(element)
          });
          this.floorPlan.Labels.forEach((element, index) => {
            this.addLabel(element, index);
          });
          break;
        case 'Walls':
          availableData = that.canvas.getObjects().filter(x => x.name == 'wall');
          availableData.forEach(element => {
            that.canvas.remove(element)
          });
          this.floorPlan.Walls.forEach((element, index) => {
            this.addWall(element, index);
          });
          break;
        case 'Shapes':
          availableData = that.canvas.getObjects().filter(x => x.name == 'area');
          availableData.forEach(element => {
            that.canvas.remove(element)
          });
          this.floorPlan.Shapes.forEach((element, index) => {
            this.addShape(element, index);
          });
          break;
      }
    });
    that.canvas.renderAll();
  }

  setTableConfiguration(element, data?) {
    if (element.ShapeType != undefined) {
      let type;
      switch (element.ShapeType) {
        case 0:
          type = ShapeTypeEnum.Rectangle
          break;
        case 1:
          type = this.layoutFunctions.checkTableiscircle(element);
          break;
        case 2:
          type = ShapeTypeEnum.Pin;
          break
        case 3:
          type = ShapeTypeEnum.BasicRectangle;
          break;
        case 4:
          type = ShapeTypeEnum.BasicCircle;
          break;
        case 8:
          type = ShapeTypeEnum.Image;
          break;
      }
      if (type == 'rect') {
        const party = null;
        this.rectangle = new fabric.Rect({
          left: data ? data.left : element.Left,
          top: data ? data.top : element.Top,
          height: data ? data.height : element.Height,
          width: data ? data.width : element.Width,
          fill: '#fff',
          name: 'shape',
          rx: 2,
          ry: 2,
        });
        this.rectangle.set({
          name: 'shape'
        });
        this.tableObj = [];
        this.addTableGroupAdd(this, this.rectangle, element, party, data);
      } else if (type == 'circle') {
        const party = null;
        this.circle = new fabric.Circle({
          left: data ? data.left : element.Left,
          top: data ? data.top : element.Top,
          height: data ? data.height : element.Height,
          width: data ? data.height : element.Width,
          radius: data ? (data.width / 2) : (element.Width / 2),
          fill: '#fff'
        });
        this.circle.set({
          name: 'shape'
        });
        this.addTableGroupAdd(this, this.circle, element, party, data)
      } else if (type == ShapeTypeEnum.Ellipse) {
        this.ellipse = new fabric.Ellipse({
          left: data ? data.left : element.Left,
          top: data ? data.top : element.Top,
          ry: data ? (data.height / 2) : element.Height / 2,
          rx: data ? (data.width / 2) : element.Width / 2
        });
        this.ellipse.set({
          name: 'shape',
        });
        this.ellipse.setControlsVisibility({
          'ml': false,
          'mt': false,
          'mr': false,
          'mb': false,
        });
        this.addTableGroupAdd(this, this.ellipse, element, false);
      } else if (type == ShapeTypeEnum.BasicRectangle) {
        const party = null;
        this.basicRectangle = new fabric.Rect({
          left: data ? data.left : element.Left,
          top: data ? data.top : element.Top,
          height: data ? data.height : element.Height,
          width: data ? data.width : element.Width,
          fill: '#fff',
          name: 'shape',
          rx: 2,
          ry: 2
        });
        this.basicRectangle.set({
          name: 'shape',
          type: 'brect'
        });
        this.tableObj = [];
        this.addTableGroupAdd(this, this.basicRectangle, element, party, data);
      } else if (type == ShapeTypeEnum.BasicCircle) {
        const party = null;
        this.basicCircle = new fabric.Circle({
          left: data ? data.left : element.Left,
          top: data ? data.top : element.Top,
          height: data ? data.height : element.Height,
          width: data ? data.height : element.Width,
          radius: this.bCircleRadius,
          fill: '#fff'
        });
        this.basicCircle.set({
          name: 'shape',
          type: 'bcircle'
        });
        this.addTableGroupAdd(this, this.basicCircle, element, party, data)
      } else if (type == ShapeTypeEnum.Pin) {
        const party = null;
        this.pin = new fabric.Circle({
          left: data ? data.left : element.Left,
          top: data ? data.top : element.Top,
          height: data ? data.height : element.Height,
          width: data ? data.height : element.Width,
          radius: data ? (data.width / 2) : (element.Width / 2),
          fill: this.seatingTypes.length > 0 ? this.seatingTypes[0].color : '#fff',
        });
        this.pin.set({
          name: 'shape',
          type: 'pin'
        });
        this.addTableGroupAdd(this, this.pin, element, party, data)
      } else if (type == ShapeTypeEnum.Image) {
        const party = null;
        const image = document.createElement('img');
        let imageId = data ? data.ImageId : element.ImageId;
        let images = this.customImages.filter((img) => img.ImageId == imageId);
        let imagebytes;
        if (images && images.length == 1) {
          imagebytes = images[0].bytes;
        }
        image.src = data ? 'data:image/png;base64,' + imagebytes : 'data:image/png;base64,' + imagebytes;
        this.image = new fabric.Image(image, {
          top: data ? data.top : element.top,
          left: data ? data.left : element.left,
          scaleX: data ? data.imgWidth / image.width : element.scaleX,
          scaleY: data ? data.imgHeight / image.height : element.scaleY
        });
        this.image.set({
          name: 'shape',
          type: 'image',
          imgWidth: data ? data.imgWidth : element.imgWidth,
          imgHeight: data ? data.imgHeight : element.imgHeight
        });
        this.addTableGroupAdd(this, this.image, element, party, data)
      }
      this.layoutFunctions.setTableIcons(element, this.canvas.getObjects(), this.canvas, this.blockingRule);
    }
    let objs = this.canvas.getObjects();
    let isImageAvailable = false;
    objs.forEach(object => {
      object.set({
        lockMovementX: false,
        lockMovementY: false,
        hasBorders: false,
      })
      if(object.Id == element.Id){
      const isTableOccupied = this.layoutFunctions.checkTableIsOccupied(element, this.layoutConfig);
      if (element.ImageId && (element.ShapeType == TableShapeType.RectangleBasic || element.ShapeType == TableShapeType.CircleBasic)) {
        this.setObjectImage(object);
      }
      if (isTableOccupied && object.ImageId ) {
      }
    }
    });

    if (!isImageAvailable)
      this.canvas.renderAll();
  }
  partyDataChanges() {
    const that = this;
    const reservationChange = differenceBy(this.layoutConfig.reservations, this.layoutData.reservations, 'Id');
    const reservationRemoval = differenceBy(this.layoutData.reservations, this.layoutConfig.reservations, 'Id');
    if (reservationChange.length > 0) {
      reservationChange.forEach(data => {
        data['TableIds'].forEach(tableId => {
          this.canvas.remove(that.canvas.getObjects().filter(x => x.Id == tableId)[0]);
          let value = this.layoutConfig.floor[0].StandaloneTables.filter(x => x.Id == tableId)[0];
          if (value) {
            this.setTableConfiguration(value);
          }
        });
      });
    }
    if (reservationRemoval.length > 0) {
      reservationRemoval.forEach(data => {
        data['TableIds'].forEach(tableId => {
          this.canvas.remove(that.canvas.getObjects().filter(x => x.Id == tableId)[0]);
          let value = this.layoutConfig.floor[0].StandaloneTables.filter(x => x.Id == tableId)[0];
          if (value) {
            this.setTableConfiguration(value);
          }
        });
      })
    }
  }

  showErrorPopUp(errorMessage, title, width, height) {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant(errorMessage),
      dialogTitle: this.ts.instant(title),
      showAlert: true
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'action',

      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: this.ts.instant(title)
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: width,
      height: height,
      data: {
        title: this.ts.instant(title),
        showAction: true,
        update: 'ok',
        componentDetails,
        from: ComponentTypes.layoutEditorAlert,
      }
    });
  }
  discardChanges() {
    this.labelsCreated = 0;
    let index = this.layoutFloorPlans.findIndex(x => x.Id == this.previousSelectedLayoutId);
    if (index >= 0) {
      this.layoutFloorPlans.splice(index, 1, cloneDeep(this.arrayToBeCompared.filter(x => x.Id == this.previousSelectedLayoutId))[0]);
    }
    if (this.previousSelectedLayoutId == 0) {
      this.layoutFloorPlans.splice(index, 1);
      let indexInArrayTobeCompared = this.arrayToBeCompared.findIndex(x => x.Id == this.previousSelectedLayoutId);
      this.arrayToBeCompared.splice(indexInArrayTobeCompared, 1);
      this.selectedLayout = this.selectedLayout ? cloneDeep(this.selectedLayout) : this.selectedLayout == 0 ? cloneDeep(this._layout.FloorPlans.filter(x => x.IsDefault == true)[0].Id) : cloneDeep(this._layout.FloorPlans.filter(x => x.IsDefault == true)[0].Id);
    }
    this.floorPlan = this.fs.floorPlan = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0];
    if (this.addNewLayoutClicked) {
      this.addNewLayoutClicked = false;
      this.OpenAddNewLayoutDialogue(true);
    }
    this.tables = this.layoutFloorPlans.filter(x => x.Id == this.selectedLayout)[0].StandaloneTables;
    this.canvas.clear();
    this.clearTableSizeElement();
    this.setFloorData(this.selectedLayout);
  }

  isActivityProperty() {
    return ActivitiesVenue.find(x => x == this._setting.PropertyType) ? true : false;
  }

  ngOnDestroy() {
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
    this.fs.selectedTabIndex$.next(null);
    this.fs.selectedTableBeforeAction$.next(null);
    this.fs.equal = true;
    if (this.canvas) {
      this.canvas.removeListeners();
      this.canvas.discardActiveObject();
      this.canvas.dispose();
    }
  }
}
