import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Inject, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewServerComponent } from '@app/servers/new-server/new-server.component';
import { IFormValidDetails, ITabOutputDetails } from '@app/settings/models/common.interface';
import { BookingBehavior, buttonTypes, ComponentTypes, ReservationType } from '@constants/commonenums';
import { ButtonValue } from '@dynamicform/models/field-config.interface';
import { LayoutConfig } from '@models/global.interface';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { TabConfig, TabsModel } from '@popup-module/models/popup.interface';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { BehaviorSubject } from 'rxjs';
import { ThemeService } from 'ng2-charts';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { ActivitiesVenue } from '@app/shared/constants/globalConstants';
import { AppService } from '@app/app.service';
import { Utilities } from '@utilities/utilities';
import { CacheService } from "@app/core/services/cache.service";
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-custom-tabs',
  templateUrl: './custom-tabs.component.html',
  styleUrls: ['./custom-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomTabsComponent extends Utilities implements OnInit, OnDestroy {
  @ViewChild('comp', { read: ViewContainerRef }) dynamicComponent: ViewContainerRef;
  @Input() tabsConfig: TabsModel;
  @Input() fromComponent: any;
  @Input() additionalInfo: any;
  @Input() selectedTabIndex: any;
  @Output() tabIndexChange = new EventEmitter();
  @Input() fromCart: any;
  @Input() showAddToCart: boolean = false;
  dynamicComponentInjector: Injector;
  reservationType = ReservationType;
  currentTabIndex = 0;
  tabIndex = 0;
  buttonValueNext: ButtonValue;
  buttonValueAddCart: ButtonValue;
  buttonValueBuyItem: ButtonValue
  buttonValueCancel: ButtonValue;
  buttonValueSave: ButtonValue;
  buttonValueClose: ButtonValue;
  nextButtonSubscription: ISubscription;
  previousSubscription: ISubscription;
  confirmedSubscription: ISubscription;
  cancelSubscription: ISubscription;
  componentTypes = ComponentTypes;
  slotSubscription: any;
  isDialogButtonsAvailable = false;
  isNextButtonAvailable = false;
  isHeaderAvailable = true;
  isPreviousButtonAvailable = true;
  isCancelButtonAvailable = false;
  fromBackButton: boolean = true;
  isShowConfirmbtn: boolean = true;
  showSaveButton: boolean = false;
  subscriptions: Subscription = new Subscription();
  freezeTab: boolean;
  showAddtoCartPopover = true;
  warningInfoDialogRef: MatDialogRef<any>;
  hideSaveAction: boolean = false;

  set dynamicComponentData(componentInput) {
    this.dynamicComponentInjector = Injector.create([
      { provide: COMPONENTINPUT, useValue: componentInput }
    ], this.tabInjector);
  }
  changedTabIndex = 0;
  constructor(public partyService: PartyService, private componentRef: ComponentFactoryResolver, private ps: PopupService, public _as: AppService, @Inject(MAT_DIALOG_DATA) public dialogData,
    private tabInjector: Injector, public dialog: MatDialog, public dialogRef: MatDialogRef<NewServerComponent>, private ts: TranslateService, private changeDetector: ChangeDetectorRef, public cs: CacheService, private utilities: CommonUtilities) {
    super(dialog);
  }

  /**
   * @param $event;
   * @function seletedTabChange($event)
   * @description On Select the change Event Function
   */

  seletedTabChange(event) {
    this.ps.changedTabIndex = event.index;
    this.tabIndex = event.index;
    this.buttonActions();
    this.tabIndexChange.emit(event);
    this.partyService.tabChange$.next(event.index);
    this.partyService.tabChangeEvent$.next({index: event.index, from: this.fromComponent});
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }


  ngOnInit() {
    this.ps.dialogPopupTittle = this.tabsConfig.tabs[0].tabHeader;
    this.dynamicComponentData = this.tabsConfig.componentInput;
    this.ps.additionalInfo = this.additionalInfo;
    if (this.tabsConfig.tabValue) {
      this.tabsConfig.tabs[0].tabLabel = this.partyService.reservationFormGroup.value.selectedSize;
    }
    this.ps.changedTabIndex = 0;
    this.loadTabsAction();
    this.loadButtonConfig();
    this.tabIndexChange.emit(0);
  }
  ngAfterViewInit() {
    this.ps.tabsActions$.subscribe((tabs: IFormValidDetails[]
    ) => {
      if (tabs?.length !== this.tabsConfig.tabs.length) {
        this.loadTabsAction();
      }

      if (tabs?.length == this.tabsConfig.tabs.length) {
        this.setTabDetails(tabs);
      }
    });
    this.ps.nextEvent$.subscribe((value : boolean) => {
      if(value){
        this.next();
      }
    })
    this.ps.previousEvent$.subscribe((value: boolean) => {
      if(value)
      this.previous();
    });
  }

  setTabDetails(tabs: IFormValidDetails[]) {
    this.buttonValueNext.disbaledproperity = !tabs[this.tabIndex].gotoNextTab;
    this.showAddToCart = this.tabIndex == this.tabsConfig?.tabs.length - 1 && this.ps.tabsActionData[this.tabIndex].showAddToCart;
    this.buttonValueSave.label = this.ps.tabsActionData[this.tabIndex].actionButtonText ? this.ts.instant(this.ps.tabsActionData[this.tabIndex].actionButtonText) : this.ts.instant('Save') ;
    this.buttonValueCancel.disbaledproperity = !tabs[this.tabIndex].gotoPreviousTab;
    if (this.showSaveButton) {
      this.buttonValueSave.disbaledproperity = !tabs[this.tabIndex].gotoNextTab;
      this.buttonValueAddCart.disbaledproperity = !tabs[this.tabIndex].gotoNextTab;
    }
    this.hideSaveAction =  this.ps.tabsActionData[this.tabIndex].hideSaveAction;
    let disableAllTabs = false;
    if (this.ps.tabsActionData.length === this.tabsConfig.tabs.length) {
      this.ps.tabsActionData.forEach((tab, index) => {
        if (this.tabIndex == index) {
          this.tabsConfig.tabs[index].isDisabled = false;
        } else {
          if (index !== 0 && this.ps.tabsActionData[index - 1].gotoNextTab && !disableAllTabs) {
            this.tabsConfig.tabs[index].isDisabled = false;
          } else if (index > this.tabIndex) {
            disableAllTabs = true;
            this.tabsConfig.tabs[index].isDisabled = true;
          }
        }

      });
    }
    if(this.tabsConfig.selectedTabNumber){

      if (this.tabsConfig.selectedTabNumber) {
        this.tabsConfig.selectedTabNumber--;
        this.ps.nextEvent$.next(true);
      }
     
    }
  }

  loadTabsAction() {
    this.ps.tabsActionData = [];
    this.tabsConfig.tabs.forEach((tab) => {
      this.ps.tabsActionData.push({
        gotoNextTab: false,
        gotoPreviousTab: true,
        nextValidations: true,
      } as IFormValidDetails)
    })
  }
  loadButtonConfig() {
    this.buttonValueNext = {
      type: buttonTypes.actionPrimary,
      label: this.ts.instant('Next'),
      customclass: 'next-btn',
      disbaledproperity: this.ps.nextBtnEnabled$
    };
    this.buttonValueSave = {
      type: buttonTypes.actionPrimary,
      label: this.ts.instant('Save'),
      customclass: 'next-btn',
      disbaledproperity: this.ps.nextBtnEnabled$
    }
    this.buttonValueCancel = {
      type: buttonTypes.actionSecondary,
      label: this.ts.instant('Cancel'),
      customclass: 'cancel-btn',
      disbaledproperity: this.ps.previousButtonEnabled$
    };
    this.buttonValueAddCart = {
      type: buttonTypes.actionPrimary,
      label: this.ts.instant('AddToCart'),
      customclass: 'next-btn',
      disbaledproperity: this.ps.nextBtnEnabled$
    }
  }

  next() {
    if (!this.ps.tabsActionData[this.tabIndex].nextValidations) {
      this.ps.warningAction$.next(true);
    } else {
      if (this.ps.changedTabIndex < this.tabsConfig.tabs.length - 1) {
        this.tabIndex = this.ps.changedTabIndex + 1;
      }
      this.changeDetector.detectChanges();
      this.buttonActions();
    }
  }
  save() {
    let parentService = this.dialogData.service ?  this.dialogData.service  : this.ps ;
      parentService.confirmedAction$.next({
      label: this.buttonValueNext.label,
      isEdit: this.tabsConfig.isEditTab,
      inputData: this.tabsConfig.componentInput,
      fromComponent: this.fromComponent
    } as ITabOutputDetails);
  }
  buttonActions() {
    this.showSaveButton = this.tabIndex == this.tabsConfig?.tabs.length - 1;
    this.buttonValueCancel.label = this.tabIndex == 0 ? this.ts.instant('Cancel') : this.ts.instant('Previous');
    if(this.tabIndex == 0) {
      this.ps.previousButtonEnabled$.next(true);
    }
  }
  previous() {
    this.tabIndex = this.ps.changedTabIndex - 1;
    this.buttonActions();
    if(this.tabIndex === 0) {
      this.ps.previousButtonEnabled$.next(true);
    }
    if (this.tabIndex < 0) {
      this.ps.closeDialog$.next();
    }
  }

  tabNavigation() {
    this.nextButtonSubscription = this.ps.nextBtnEnabled$.subscribe((data) => {
      this.buttonValueNext.disbaledproperity = data;
    });
    this.previousSubscription = this.ps.previousButtonEnabled$.subscribe((data) => {
      this.buttonValueCancel.disbaledproperity = data;
    });
  }
  onCartGroupSelect(cartGroupData){
    this.addToCart(false, cartGroupData)
  }

  addToCart(val, cartGroupData) {
    let cartDetails = {
      inputData: this.tabsConfig.componentInput,
      outputData: cartGroupData,
      fromComponent: this.fromComponent,
      isEdit: val
    }
    this.ps.addToCartAction$.next(cartDetails);
  }

  checkActivityProperty(): boolean{
    return ActivitiesVenue.find(x=>x == this._as.PropertyType) ? true : false;
  }

  ngOnDestroy() {
    this.ps.tabsActionData = [];
    if (this.nextButtonSubscription) {
      this.nextButtonSubscription.unsubscribe();
    }
    if (this.previousSubscription) {
      this.previousSubscription.unsubscribe();
    }
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

}
