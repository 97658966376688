import { Injectable } from '@angular/core';
import { IGiftcardActions, GiftCardCaptureResponse, GiftCardGuestDetails } from './IGiftcardActions';
import { Device, GiftCardPMRequest } from '../../service/payment/payment-model';
import { PayAgentService } from '../../service/payagent.service';
import { CommonVariablesService } from '../../service/common-variables.service';
import { HttpServiceCall } from '../../service/http-call.service';
import { PaymentMethods } from '../shared.modals';
import { RetailFeatureFlagInformationService, SearchTypes } from '../../service/retail.feature.flag.information.service';
import { SaleRequest } from '../../service/payment/payment-business.model';
import { Localization } from 'src/app/common/localization/localization';
import { RetailUtilities } from '../../utilities/retail-utilities';

@Injectable()
export class ExternalGiftcardBusinessService implements IGiftcardActions {

    constructor(
        private _payAgentService: PayAgentService
        , public _ss: CommonVariablesService
        , private localization: Localization
        , private http: HttpServiceCall
        , private utils: RetailUtilities
        , private featureFlagInfo: RetailFeatureFlagInformationService
    ) { }

    GetDevices(): Promise<Device[]> {
        throw new Error("Method not implemented.");
    }

    CaptureGiftCard(selectedDevice: Device, isIDTech: boolean, encryptedData?: string): Promise<GiftCardCaptureResponse> {
        throw new Error("Method not implemented.");
    }

    RechargeGiftCard(cardNumber: string, retailTicketNumber: string, amount: number, handle: string, tendedId: PaymentMethods) {
        let RequestBody: GiftCardPMRequest = {
            handle: handle,
            Amount: {
                RequestAmount: String(amount)
            },
            InquirerInfo: {
                TenderId: String(tendedId),
                TerminalId: "0",
                OrderNumber: retailTicketNumber,
                Profitcenter: "0",
                ZoneOffset: this.featureFlagInfo.ZoneOffset,
                id: cardNumber,
                EmployeeId: "0"
            }
        }
        return this._payAgentService.LoadCard(RequestBody, this._ss.SelectedOutletId);
    }

    ReverseRecharge(giftcardNumber: string, retailTicketNumber: string, amount: number, transId?: number, giftCardHandle?: string, tendedId?: PaymentMethods) {
        let RequestBody: SaleRequest = this.ConstructSaleRequest(
            giftCardHandle,
            retailTicketNumber,
            giftcardNumber,
            amount,
            tendedId            
        );
        
        /**
         * Load Reversal has been implemented for SkipPMAgent flow
         * hence Sale call has been restricted
         * */ 
        
        if(this._ss.IsSkipPMAgent)return;

        return this._ss.PaymentProcessor.RequestSale(RequestBody, this._ss.SelectedOutletId);
    }

    PerformCashback(giftcardNumber: string, retailTicketNumber: string, cashBackAmt: number, handle: string, tendedId: PaymentMethods, zipcode?: string) {
        const RequestBody = this.ConstructSaleRequest(
            handle,
            retailTicketNumber,
            giftcardNumber,
            cashBackAmt,
            tendedId,
            zipcode
        );
        return this._payAgentService.RequestSale(RequestBody, this._ss.SelectedOutletId);
    }

    IssueGiftCard(cardNumber: string, retailTicketNumber: string, amount: number, guestDetails: GiftCardGuestDetails, handle: string, tendedId: PaymentMethods, isCardActive?: boolean) {
        let RequestBody: GiftCardPMRequest = {
            handle: handle,
            Amount: {
                RequestAmount: String(amount)
            },
            InquirerInfo: {
                TenderId: String(tendedId),
                TerminalId: "0",
                OrderNumber: retailTicketNumber,
                Profitcenter: "0",
                ZoneOffset: this.featureFlagInfo.ZoneOffset,
                id: cardNumber,
                EmployeeId: "0",
                customer: guestDetails.firstName + " " + guestDetails.lastName
            }
        }
        const ReqMethodToBeCalled = isCardActive ? "LoadCard" : "IssueCard";
        return this._payAgentService[ReqMethodToBeCalled](RequestBody, this._ss.SelectedOutletId);
    }

    ActivateGiftCard(cardNumber: string, handle: string, tendedId: PaymentMethods) {
        let RequestBody: GiftCardPMRequest = {
            handle: handle,
            Amount: {
                RequestAmount: String(1) //Passing 1 since rGuest has a min amt validation
            },
            InquirerInfo: {
                TenderId: String(tendedId),
                TerminalId: "0",
                OrderNumber: String(this.utils.GetRandomNumber(7)),
                Profitcenter: "0",
                ZoneOffset: this.featureFlagInfo.ZoneOffset,
                id: cardNumber,
                EmployeeId: "0",
                doActivateOnly: true //Indicates PMAgent will only do activation of the card
            }
        }
        return this._payAgentService.IssueCard(RequestBody, this._ss.SelectedOutletId);
    }

    /**
     * @description Returns the SaleRequest body for Giftcard sale
     * @param giftCardHandle 
     * @param cardNumber 
     * @param saleAmount 
     * @param tenderId 
     * @param checkNumber 
     */
    private ConstructSaleRequest(giftCardHandle: string, retailTicketNumber: string, cardNumber: string, saleAmount: number, tendedId: PaymentMethods, zipcode: string = "") {
        return {
            handle: giftCardHandle,
            amount: {
                requestAmount: saleAmount
            },
            searchType: SearchTypes.None,
            inquirerInfo: {
                tenderId: String(tendedId),
                terminalId: "0",
                orderNumber: retailTicketNumber,
                profitCenter: "0",
                zoneOffset: this.featureFlagInfo.ZoneOffset,
                id: cardNumber,
                employeeId: "0",
                customer: "0",
                isPartialTenderAllowed: true,
                zipcode: zipcode,
                clientId: "",
                postingId: " - #" + retailTicketNumber                
            }
        };
    }

    ReturnGiftCard(giftcardNumber: string, amount: number, handle?: string, tenderId?: PaymentMethods) {
        throw new Error('Method not implemented.');
    }

}