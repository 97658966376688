import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { AppService } from '@app/app.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { LocaleService } from '@app/shared/services/locale.service';
import { ReservationType } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { SettingsDTO } from '@models/RestaurantDTO';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { Utilities } from '@utilities/utilities';
import { format } from 'date-fns';
import _ from 'lodash';
import { ISubscription, Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateSelectionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() tabLabel: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatCalendar) datePicker: MatCalendar<Date>;
  waitDate: Date;
  datePipe: DatePipe;
  selectedIndex: number;
  tabSubscription: ISubscription;
  subscriptions: Subscription = new Subscription();
  date: Date;
  _settings: SettingsDTO;
  isSpecialMealApplicable = false;
  maxResDate: Date;
  constructor(public partyService: PartyService, @Inject(COMPONENTINPUT) private data, private ps: PopupService, cs: CacheService, private as: AppService,private dateAdapter:DateAdapter<any>, private ls:LocaleService) {
    this.datePipe = new DatePipe(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_userLocale`));
    this.subscriptions.add(cs.settings.subscribe(_stng => {
      this._settings = _stng
    }));
    this.waitDate = new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta));
  }

  ngOnInit() {
    this.dateAdapter.setLocale(this.ls.defaultLocale())
    this.date = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    this.selectedIndex = this.partyService.tabsModal.tabs.findIndex(x => x.tabComponent === DateSelectionComponent);
    const selectedDate = this.data ? new Date(this.data.ReservedFor) : Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    this.reservationFormChange(selectedDate);
    this.subscriptions.add(this.datePicker.selectedChange.subscribe(date => {
      this.tabLabel.emit(this.datePipe.transform(date, 'MMM dd'));
      this.reservationFormChange(date);
      this.waitDate = date;
    }));
    // API Method Call to set the slots in time tab if the Size changes
    if (this.partyService.reservationType === ReservationType.Reservation) {
      this.partyService.getTimeDetails(this.as._headerDate);
    }
    if (this.data && this.data.SpecialMealId) {
      const specialMeals = _.cloneDeep(this._settings.SpecialMeals);
      const selectedSpecialMeals = specialMeals.filter((meal) => meal.Id === this.data.SpecialMealId);
      if (selectedSpecialMeals.length > 0 && selectedSpecialMeals[0].StartDate && selectedSpecialMeals[0].EndDate) {
        const formattedStartDate = format(selectedSpecialMeals[0].StartDate, 'MM/DD/YYYY');
        const formattedEndDate = format(selectedSpecialMeals[0].EndDate, 'MM/DD/YYYY');
        const reservationDate = format(this.partyService.reservationFormGroup.value.selectedDate, 'MM/DD/YYYY');
        this.isSpecialMealApplicable = reservationDate >= formattedStartDate && reservationDate <= formattedEndDate;
        if (this.isSpecialMealApplicable) {
          this.maxResDate = new Date(selectedSpecialMeals[0].EndDate);
        }
      }
    }
  }

  setSelectedDate(date) {
    this.reservationFormChange(date);
    this.partyService.reservationFormGroup.markAsDirty();
    this.partyService.reservationFormGroup.updateValueAndValidity();
    if (this.selectedIndex >= 0) {
      // API Method Call to set the slots in time tab if the date changes
      this.partyService.reservationFormGroup.get('selectedTime').setValue('');
      this.partyService.getTimeDetails(this.as._headerDate);
    }
  }

  ngAfterViewInit() {
    this.tabSubscription = this.partyService.tabChange$.subscribe((data) => {
      if (data === this.selectedIndex) {
        this.ps.formValid$.next(
          {
            isFormValid: this.partyService.reservationFormGroup.value.selectedDate ? true : false,
            currentTab: this.selectedIndex
          } as IFormValidDetails);
      }
    });
  }

  ngOnDestroy() {
    this.datePicker.selectedChange.unsubscribe();
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.tabSubscription) {
      this.subscriptions.unsubscribe();
    }
  }

  reservationFormChange(selectedDate) {
    this.partyService.reservationFormGroup.get('selectedDate').setValue(selectedDate);
    if (this.selectedIndex >= 0) {
      this.partyService.tabsModal.tabs[this.selectedIndex].tabLabel = this.datePipe.transform(selectedDate, 'MMM dd');
    }
    this.ps.formValid$.next(
      {
        isFormValid: this.partyService.reservationFormGroup.value.selectedDate ? true : false,
        currentTab: this.selectedIndex
      } as IFormValidDetails);
  }
}
