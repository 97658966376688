<div id="cust-header-id">
    <app-dialog-header [title]="captions.editCustomFeeTitle" (closeDialog)="close()"></app-dialog-header>
</div>
<div class="edit-custom-fee-wrapper ag_container--padding-md">
    <form class="edit-custom-fee-popover-body" autocomplete="off" [formGroup]="editCustomFeeForm">
        <label for="" class="text-calitalise d-block form-label-normal">{{captions.lbl_customFee}}</label>
        <mat-radio-group [attr.automationId]="'Rdo_AddWaitlist_clientpriority'" class="golf__radio" formControlName="isPercentage">
          <mat-radio-button class="mr-5 golf--form-radio-button" *ngFor="let item of priority" [value]="item.id">
            {{item.name}}
          </mat-radio-button>
        </mat-radio-group>
        <ng-container>
            <div class="ag_w--50 pr-4" *ngIf="this.editCustomFeeForm.controls.isPercentage.value == 1">
              <ag-input class="ag_w--50" formControlName="percentValue" type="PERCENTAGE" [inputs]="percentageConfig" max="100"
                [placeholder]="captions.lbl_percentage" required automationId="'Txt_createCustomFee_amount'"></ag-input>
            </div>
          </ng-container>
          <ng-container>
            <div class="ag_w--50 pr-4" *ngIf="this.editCustomFeeForm.controls.isPercentage.value == 2">
              <ag-input class="ag_w--50" formControlName="flatAmount" type="CURRENCY"
                [placeholder]="captions.lbl_amount" required automationId="'Txt_createCustomFee_amount'"></ag-input>
            </div>
          </ng-container>
    </form>
</div>
<div class="edit-custom-fee-popover-footer">
  <button mat-button class="mt-2" [disabled]="!isFormValid()" [ngClass]="!isFormValid() ? 'button--disabled' : 'button--primary-save'" (click)="apply()">{{captions.customFee_apply}}</button>
  <button mat-button (click)="close()" class="text-capital ml-2">{{captions.customFee_Cancel}}</button>
</div>