<div class="crs__reservation-card"  [class.notable-card]="!tableName">
    <div *ngIf="tableName" class="crs__reservation-card-table seat-secondary-bg-color">
        <span *ngIf="reservation.TableNames.length > 1; else table" class="icon-link"
            [ngStyle]="{'background-color': (reservation.State | backgroundColorFormatter : 0 : null)}"
            [matTooltip]="tableName">
        </span>
        <ng-template #table>
            <span [ngStyle]="{'background-color': (reservation.State | backgroundColorFormatter : 0 : null)}"
                [matTooltip]="tableName">
                {{tableName}}
            </span>
        </ng-template>
    </div>
    <div class="crs__reservation-card-data">
        <div class="crs__reservation-card-name">
            <ng-container *ngIf="primaryText=='contact'">{{reservation.Contact | formattedName}}</ng-container>
            <ng-container *ngIf="primaryText=='property'">{{reservation.RestaurantName}}</ng-container>
            <strong *ngIf="specialMealName" [matTooltip]="specialMealName">
                - {{specialMealName}}
            </strong>
        </div>
        <div class="crs__reservation-card-datetime">

            <span class="crs__reservation-card--size-icon" *ngIf="reservation.Size">
                <i class="icon_set icon-add-contact seat-icon-color"></i>&nbsp;{{reservation.Size}}
            </span>

            <strong class="crs__reservation-card--time"> {{(reservation.ReservedFor || reservation.SeatingTime) |
                localizedDate: 'LT'}}</strong>
            <span *ngIf="showDate">, {{(reservation.ReservedFor || reservation.SeatingTime) | localizedDate: (dateFormat
                || 'll')}}</span>

        </div>
    </div>

    <div class="crs__reservation-card__finance" *ngIf="reservation.paymentStatus" >
        <span [class]="ICONS[reservation.paymentStatus]" [autoClose]="'outside'" container="body"
            [placement]="['right-center', 'bottom', 'left-center' , 'auto']" [ngbPopover]="financialStatusPopover"
            (click)="financialDetails(reservation, reservation.paymentStatus, $event)"></span>
    </div>
</div>

<ng-template #financialStatusPopover>
    <div class="financial-details">
        <div class="financial-details__header seat-popover-header">
            {{financialDetail.header}}
        </div>
        <div class="financial-details__content seat-popover-content">
            <div *ngIf="IsCharged" class="financial-details__paid">
                <table>
                    <tr>
                        <td>{{'datetime' | translate}}</td>
                        <td class="rs-pl-3">:</td>
                        <td class="rs-pl-5">
                            {{financialDetail.date | localizedDate: 'LT'}} / {{financialDetail.date | localizedDate: 'D
                            MMM, ddd'}}
                        </td>
                    </tr>
                    <tr>
                        <td>{{'accordionstatustext' | translate}}</td>
                        <td class="rs-pl-3">:</td>
                        <td class="rs-pl-5">{{OperationCurrency}}{{financialDetail.amount}}
                            {{financialDetail.status}}</td>
                    </tr>
                </table>
            </div>
            <div *ngIf="!IsCharged" class="financial-details__notPaid">
                <span>{{financialDetail.status}}</span>
            </div>
        </div>
    </div>
</ng-template>