import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { COMPONENTINPUT, PopupService } from "@app/popup-module/popup.service";

@Component({
    selector: 'app-crs',
    templateUrl: './crs-update-confirmation.component.html',
    styleUrls: ['./crs-update-confirmation.component.scss']
})
export class CRSUpdateConfirmationComponent implements OnInit {
    @Input() data: any;

    constructor(private popupService: PopupService, public dialogRef: MatDialogRef<CRSUpdateConfirmationComponent>) {
        
    }

    ngOnInit(): void {
        this.popupService.restrictCloseDialog = true;
        this.popupService.cancelledAction$.subscribe(cancelled => {
            this.dialogRef.close({ action: "cancelled" });
        })
        this.popupService.confirmedAction$.subscribe(confirmed => {
            this.dialogRef.close({ action: "confirmed" });
        })
    }

}