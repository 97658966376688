<section class="newVersionWrapper locker-wrapper">
    <div class="locker--top-section bodyWrap" [formGroup]="lockerForm">
        <app-simple-search class='mr-3' [placeholder]="searchPlaceHolder"
        (searchChange)="searchValueChange($event)">
        </app-simple-search>
        <!-- <i class="icon-px_Filter2-eatec boxed-icon" (click)="filterChange()"></i> -->
        <span class="mr-2 ml-4" *ngIf="!isOutOfOrder">{{captions.lbl_dateTime}}</span>
        <app-ag-date-picker *ngIf="!isOutOfOrder" class="mr-3" [inputs]="dateInput" (datePickerChange)="dateChange($event)">
        </app-ag-date-picker>
        <app-ag-time-picker *ngIf="!isOutOfOrder" class="mr-3"  [inputs]="timeInput" (timeChangeEvent)="timeChange($event)"></app-ag-time-picker>
        <app-button class="ag_mt--3" *ngIf="!isOutOfOrder" [buttontype]='todayButton' (valueChange)='todayClick()'></app-button>
        <app-button class="ag_mt--3" *ngIf="!isOutOfOrder" [buttontype]='searchButton' (valueChange)='search()'></app-button>
        <div class="ag_display--flex ml-auto">
            <label class="mr-2 mt-3">{{captions.lbl_filterby}}</label>
            <div class="ag_display--flex mt-1 pb-1">
               <div class="button-type" (click)="inUseClick()" [ngClass]="{'clicked' : isInUse}">{{captions.lbl_inUse}}</div>
               <div class="button-type" (click)="onAvailable()" [ngClass]="{'clicked' : isAvailable}">{{captions.lbl_available}}</div>
               <div class="button-type" (click)="onOutOfOrder()" [ngClass]="{'clicked' : isOutOfOrder}">{{captions.lbl_outOfOrder}}</div>
            </div>
        </div>
    </div>
    <div *ngIf="isInUse" class="locker--table">
        <app-cdkvirtual [options]="tableOptions" [headerOptions]="headerOptions" [tableContent]="tableContent"  [searchOptions]="searchText">
        </app-cdkvirtual>
    </div>
    <div *ngIf="isAvailable" class="locker--available-section">
        <div *ngFor="let item of availableItem" class="locker-available--item-card">
            <div class="left-sect mr-2">
                <div class="shop-image">

                    <app-retail-img-thumbnail *ngIf="item.image" [imageContent]="item.image"
                      class="image app-retail-img-thumbnail" [thumbnail]="false" imageType="retailItem">
                    </app-retail-img-thumbnail>
    
                    <span *ngIf="!item.image">
                      <span class="icon-emptyshop"><span class="path1"></span><span class="path2"></span><span
                          class="path3"></span><span class="path4"></span><span class="path5"></span><span
                          class="path6"></span><span class="path7"></span><span class="path8"></span><span
                          class="path9"></span><span class="path10"></span><span class="path11"></span><span
                          class="path12"></span><span class="path13"></span><span class="path14"></span></span>
                    </span>
    
                  </div>
            </div>
            <div class="right-sect">
                <div class="font-bold item-name">{{item.lockerName}}</div>
                <div class="locker-available--number mt-1">{{item.lockerCode}}</div>
                <div class="available">{{captions.lbl_available}} - {{item.availableQuantity}}/{{item.totalQuantity}}</div>
            </div>
        </div>
        <div class="ag_text--center w-100" *ngIf="!availableItem || (availableItem && availableItem.length == 0)">
            {{captions.bookAppointment.NoDataFound}}
        </div>


    </div>
    <div *ngIf="isOutOfOrder" class="locker--table">
        <app-cdkvirtual [headerOptions]="headerOptions" [tableContent]="tableContent" [options]="tableOptions" >
        </app-cdkvirtual>
    </div>
</section>
