import { SettingsDTO, PageMethod } from '@models/RestaurantDTO';
import { CustomFieldsDTO } from '@models/InputContact';
import { FieldConfig } from '@dynamicform/models/field-config.interface';
import { fieldType } from '@constants/globalConstants';
import { environment } from '@environments/environment';
import { Utilities } from '@utilities/utilities';
import * as globals from '@constants/globalConstants';
import { Validators } from '@angular/forms';
import { ReservationType } from '@app/shared/constants/commonenums';

export class CustomGuestFields {
    customFields: any;
    customGuestFieldsMappingValidations: any[];
    _settings: SettingsDTO = {} as SettingsDTO;
    isEditGuest = false;
    specialMealSelectAfterGuestEdit: boolean = false;
    dropDownFieldOption: { dropDownCustomFieldId: any, options: [] };
    dropDownFieldOptions = [];
    guestConfig: FieldConfig[] = [];
    isAddGuest = false;
    fieldType: string[] = ['Text Field', 'Drop Down'];
    mobileRequired = false;
    mobile2Required = false;
    SecondaryGuestFieldVisibility = { "GuestNames": false, "PrimaryContacts": false, "SecondaryContacts": false, "MembershipDetails": false, "SpecialOccasions": false, "ContactNotes": false, "CustomGuestFields": false }


    constructor(public partyService, public PropertyType) { }


    setCustomFields(hideFields: boolean , IsSystemGenerated : boolean = false) {
        this.customFields = this._settings.CustomGuestFields;
        if (this.customFields.length > 0) {
            for (const [index, customField] of this.customFields.entries()) {
                let fieldConfig: FieldConfig;
                if (this.showCustomFieldBasedOnActivity(customField.Id)) {
                    if (!this.isEditGuest || this.specialMealSelectAfterGuestEdit) {
                        switch (customField.FieldType) {
                            case fieldType[0]:
                                fieldConfig = {
                                    type: 'input',
                                    name: `customField${index}`,
                                    inputType: 'text',
                                    label: customField.FieldName,
                                    placeholder: customField.FieldDescription,
                                    containerClass: 'guest-view__custom-fields-edit',
                                    id: customField.Id,
                                    validation: IsSystemGenerated ? [] : this.customValidationRequired(customField),
                                    isStarSymbolRequired: IsSystemGenerated ? false : ( this.customValidationRequired(customField)?.length > 0 ? true : false),
                                    isReadOnly: (environment.SEAProduction == true && environment.MerchantId == this._settings.General.MerchantId && customField.FieldName == 'Total Spend') ? true : false,
                                    isHidden: hideFields
                                };
                                break;
                            case fieldType[1]:
                                const fieldOptions = customField.FieldOptions.split(',').map((option, i) => {
                                    return { id: i + 1, value: option };
                                });
                                this.dropDownFieldOption = { dropDownCustomFieldId: customField.Id, options: fieldOptions };
                                this.dropDownFieldOptions.push(this.dropDownFieldOption);
                                // fieldOptions.splice(0, 0, { id: 0, value: 'None' });
                                let customFieldValidation = IsSystemGenerated ? [] : this.customValidationRequired(customField);
                                fieldConfig = {
                                    type: 'select',
                                    label: customField.FieldName,
                                    name: `customField${index}`,
                                    placeholder: customField.FieldDescription,
                                    options: fieldOptions,
                                    containerClass: 'guest-view__custom-fields-edit',
                                    id: customField.Id,
                                    validation: customFieldValidation,
                                    isStarSymbolRequired: customFieldValidation?.length > 0,
                                    isReadOnly: (environment.SEAProduction == true && environment.MerchantId == this._settings.General.MerchantId && customField.FieldName == 'Total Spend') ? true : false,
                                    isHidden: hideFields,
                                    highlightRequired: customFieldValidation?.length > 0
                                };
                                break;
                        }
                        //if(this.isEditGuest || this.isAddGuest)
                        this.guestConfig.push(fieldConfig);
                        if (this.isAddGuest || this.specialMealSelectAfterGuestEdit) {
                            this.guestConfig.forEach((config: FieldConfig) => {
                                if (config.containerClass?.includes('-edit')) {
                                    config.containerClass = config.containerClass.slice(0, -5);
                                    if (config.name == 'membershipCode' && this.checkMemberCodeEditability()) {
                                        config.containerClass = config.containerClass + " " + "guest-view__show-membership"
                                    }
                                }
                            });
                            this.setPointerEventForMembershipNumber();
                        }
                    } else {
                        this.guestConfig.forEach((config: FieldConfig) => {
                            if (config.containerClass?.includes('-edit')) {
                                config.containerClass = config.containerClass.slice(0, -5);
                            }
                            if (config.name == 'membershipCode' && this.checkMemberCodeEditability()) {
                                config.containerClass = config.containerClass + " " + "guest-view__show-membership"
                            }
                        });
                    }
                }
            }
            this.guestConfig = [...this.guestConfig];
        }
    }

    showCustomFieldBasedOnActivity(customFieldId) {
        let selectedActivityId = this.partyService.selectedSpecialMealId ? this.partyService.selectedSpecialMealId : -1;
        let activitiesCustomGuestFields = this.customGuestFieldsMappingValidations;
        if (activitiesCustomGuestFields.find(activity => activity.ActivityId == selectedActivityId)) {
            let activeActivity = activitiesCustomGuestFields.find(activity => activity.ActivityId == selectedActivityId)
            if (activeActivity.CustomFields.filter(field => field.FieldId === customFieldId)?.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false
        }
    }

    customValidationRequired(field) {
        if (this.customFieldMandatory(field)) {
            return [Validators.required];
        } else {
            return [];
        }
    }
    customFieldMandatory(field) {
        let activitiesCustomGuestFields = this.customGuestFieldsMappingValidations;
        let selectedActivityId = this.partyService.selectedSpecialMealId ? this.partyService.selectedSpecialMealId : -1;
        let CustomFields = activitiesCustomGuestFields.find(activity => activity.ActivityId == selectedActivityId)?.CustomFields
        if (CustomFields.filter(customField => customField.FieldId === field.Id)?.length > 0) {
            let validations = CustomFields.find(customField => customField.FieldId === field.Id)?.Validations
            return (validations && validations.length > 0)
        } else {
            return false;
        }
    }
    checkMemberCodeEditability() {
        if ((Utilities.controlValidate(globals.controlSettings.MembershipCode_Visible, this.PropertyType)) && this._settings.General.UseMembershipNumber) {
            return true;
        }
        return false;
    }

    setPointerEventForMembershipNumber() {
        let tempGuestConfig = this.guestConfig.filter(x => x.name === 'membershipCode');
        if (tempGuestConfig.length > 0 && (this.isAddGuest || this.isEditGuest) && !this.checkMemberCodeEditability()) {
            tempGuestConfig[0].containerClass = 'guest-view__memberCode pointerEvent';
        }
    }

    setDateToCurrentYear(date: Date) {
        date = new Date(date);
        let month = date.getMonth();
        let day = date.getDate();
        let dateInCurrentYear = new Date(new Date().getFullYear(), month, day);

        return dateInCurrentYear;
    }
    formCustomFieldRequestPayload(data, guestId, contactGuestFields, previousContactCustomField) {
        let customFieldArr = [];
        for (const [index, customField] of this.customFields.entries()) {
            const customFields: CustomFieldsDTO = {} as CustomFieldsDTO;
            const customFieldName = `customField${index}`;
            if (data[customFieldName] != null || data[customFieldName] != undefined) {
                if (customField.FieldType == this.fieldType[1]) {
                    const customFieldOption = this.dropDownFieldOptions.filter(dropDownField => dropDownField.dropDownCustomFieldId == customField.Id);
                    let customFieldOptionValueArr = [];
                    let customFieldOptionValue = '';
                    if (customFieldOption.length > 0) {
                        customFieldOptionValueArr = customFieldOption[0].options.filter(option => option.id == data[customFieldName]);
                        if (customFieldOptionValueArr.length > 0) {
                            customFieldOptionValue = customFieldOptionValueArr[0].value == "None" ? null : customFieldOptionValueArr[0].value;
                        }
                        customFields.CustomFieldValue = customFieldOptionValue;
                    }
                } else {
                    customFields.CustomFieldValue = data[customFieldName];
                }
            }
            customFields.ContactId = guestId;
            customFields.CustomFieldId = customField.Id;
            if (contactGuestFields && contactGuestFields.length > 0) {
                const tempObj = contactGuestFields.filter(contactField => contactField.CustomFieldId === customField.Id)[0];
                if (tempObj) {
                    customFields.Id = tempObj.Id;
                }
            }
            customFields.CustomFieldName = customField.FieldName;
            if (customFields.CustomFieldId && (customFields.CustomFieldValue == null || customFields.CustomFieldValue == undefined) && previousContactCustomField && previousContactCustomField.length) {
                customFields.CustomFieldValue = previousContactCustomField.find(item => item.CustomFieldId == customFields.CustomFieldId)?.CustomFieldValue
            }
            customFieldArr.push(customFields);
        }
        return customFieldArr;
    }
    getGuestFieldVisibilityMapping(specialMealId?) {
        if (this.partyService.reservationType == ReservationType.OpenBooking || this.partyService.reservationType == ReservationType.Waitlist || this.partyService.reservationType == ReservationType.Reservation || this.partyService.reservationType == ReservationType.StandbyParties) {
            let propertySettings = this._settings?.PropertySetting[0];
            this.SecondaryGuestFieldVisibility.ContactNotes = propertySettings?.ContactNotes,
                this.SecondaryGuestFieldVisibility.CustomGuestFields = propertySettings?.CustomGuestFields,
                this.SecondaryGuestFieldVisibility.PrimaryContacts = propertySettings?.PrimaryContacts,
                this.SecondaryGuestFieldVisibility.GuestNames = propertySettings?.GuestNames,
                this.SecondaryGuestFieldVisibility.SpecialOccasions = propertySettings?.SpecialOccasions,
                this.SecondaryGuestFieldVisibility.MembershipDetails = propertySettings?.MembershipDetails
            this.SecondaryGuestFieldVisibility.SecondaryContacts = propertySettings?.SecondaryContacts

        } else if (specialMealId) {
            let specialMeal = this._settings.SpecialMeals.find(meal => meal.Id == specialMealId);
            this.SecondaryGuestFieldVisibility.ContactNotes = specialMeal?.ContactNotes,
                this.SecondaryGuestFieldVisibility.CustomGuestFields = specialMeal?.CustomGuestFields,
                this.SecondaryGuestFieldVisibility.PrimaryContacts = specialMeal?.PrimaryContacts,
                this.SecondaryGuestFieldVisibility.GuestNames = specialMeal?.GuestNames,
                this.SecondaryGuestFieldVisibility.SpecialOccasions = specialMeal?.SpecialOccasions,
                this.SecondaryGuestFieldVisibility.MembershipDetails = specialMeal?.MembershipDetails
            this.SecondaryGuestFieldVisibility.SecondaryContacts = specialMeal?.SecondaryContacts
        }
    }
    requireFliedValidation(RequiredFields, fieldConfig, contactPreference) {
        const phoneIndex = fieldConfig.findIndex(x => x.name.toLowerCase() === 'mobile' || x.name === 'PhoneNumber');
        const phoneIndex2 = fieldConfig.findIndex(x => x.name.toLowerCase() === 'phone' || x.name === 'PhoneNumber2');
        const emailIndex = fieldConfig.findIndex(x => x.name.toLowerCase() === 'email' || x.name == 'EmailAddress');
        const emailIndex2 = fieldConfig.findIndex(x => x.name === 'secondaryEmail' || x.name === 'EmailAddress2');
        const emailPattern = [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]
        switch (RequiredFields) {
            case 1: {
                fieldConfig[phoneIndex].validation = [Validators.required];
                fieldConfig[phoneIndex].showErrorText = true;
                fieldConfig[phoneIndex].errorMessage = 'guestBookDetailsMobileError';
                this.mobileRequired = true;
                this.mobile2Required = false;
                contactPreference.pageMethod = PageMethod.Sms;
                break;
            }
            case 2: {
                fieldConfig[emailIndex].validation = emailPattern;
                fieldConfig[emailIndex].showErrorText = true;
                fieldConfig[emailIndex].errorMessage = 'guestBookDetailsEmailError';
                this.mobileRequired = false;
                this.mobile2Required = false;
                contactPreference.pageMethod = PageMethod.Email;
                break;
            }
            case 3: {
                fieldConfig[phoneIndex].validation = [Validators.required];
                fieldConfig[phoneIndex].showErrorText = true;
                fieldConfig[phoneIndex].errorMessage = 'guestBookDetailsMobileError';
                fieldConfig[emailIndex].validation = emailPattern;
                fieldConfig[emailIndex].showErrorText = true;
                fieldConfig[emailIndex].errorMessage = 'guestBookDetailsEmailError';
                this.mobileRequired = true;
                this.mobile2Required = false;
                contactPreference.pageMethod = PageMethod.Sms;
                break;
            }
            case 4: {
                fieldConfig[phoneIndex2].validation = [Validators.required];
                fieldConfig[phoneIndex2].showErrorText = true;
                fieldConfig[phoneIndex2].errorMessage = 'secondaryPhoneError';
                this.mobileRequired = false;
                this.mobile2Required = true;
                contactPreference.pageMethod = PageMethod.Sms2;
                break;
            }
            case 5: {
                fieldConfig[emailIndex2].validation = emailPattern;
                fieldConfig[emailIndex2].showErrorText = true;
                fieldConfig[emailIndex2].errorMessage = 'secondaryEmailError';
                this.mobileRequired = false;
                this.mobile2Required = false;
                contactPreference.pageMethod = PageMethod.Email2;
                break;
            }
            case 6: {
                fieldConfig[phoneIndex2].validation = [Validators.required];
                fieldConfig[phoneIndex2].showErrorText = true;
                fieldConfig[phoneIndex2].errorMessage = 'secondaryPhoneError';
                fieldConfig[emailIndex2].validation = emailPattern;
                fieldConfig[emailIndex2].showErrorText = true;
                fieldConfig[emailIndex2].errorMessage = 'secondaryEmailError';
                this.mobileRequired = false;
                this.mobile2Required = true;
                contactPreference.pageMethod = PageMethod.Sms2;
                break;
            }
            case 7: {
                fieldConfig[phoneIndex].validation = [Validators.required];
                fieldConfig[phoneIndex].showErrorText = true;
                fieldConfig[phoneIndex].errorMessage = 'guestBookDetailsMobileError';
                fieldConfig[phoneIndex2].validation = [Validators.required];
                fieldConfig[phoneIndex2].showErrorText = true;
                fieldConfig[phoneIndex2].errorMessage = 'secondaryPhoneError';
                this.mobileRequired = true;
                this.mobile2Required = true;
                contactPreference.pageMethod = PageMethod.Sms;
                break;
            }
            case 8: {
                fieldConfig[phoneIndex].validation = [Validators.required];
                fieldConfig[phoneIndex].showErrorText = true;
                fieldConfig[phoneIndex].errorMessage = 'guestBookDetailsMobileError';
                fieldConfig[emailIndex2].validation = emailPattern;
                fieldConfig[emailIndex2].showErrorText = true;
                fieldConfig[emailIndex2].errorMessage = 'secondaryEmailError';
                this.mobileRequired = true;
                this.mobile2Required = false;
                contactPreference.pageMethod = PageMethod.Sms;
                break;
            }
            case 9: {
                fieldConfig[phoneIndex2].validation = [Validators.required];
                fieldConfig[phoneIndex2].showErrorText = true;
                fieldConfig[phoneIndex2].errorMessage = 'secondaryPhoneError';
                fieldConfig[emailIndex].validation = emailPattern;
                fieldConfig[emailIndex].showErrorText = true;
                fieldConfig[emailIndex].errorMessage = 'guestBookDetailsEmailError';
                this.mobileRequired = false;
                this.mobile2Required = true;
                contactPreference.pageMethod = PageMethod.Email;
                break;
            }
            case 10: {
                fieldConfig[emailIndex].validation = emailPattern;
                fieldConfig[emailIndex].showErrorText = true;
                fieldConfig[emailIndex].errorMessage = 'guestBookDetailsEmailError';
                fieldConfig[emailIndex2].validation = emailPattern;
                fieldConfig[emailIndex2].showErrorText = true;
                fieldConfig[emailIndex2].errorMessage = 'secondaryEmailError';
                this.mobileRequired = false;
                this.mobile2Required = false;
                contactPreference.pageMethod = PageMethod.Email;
                break;
            }
            case 11: {
                fieldConfig[emailIndex].validation = emailPattern;
                fieldConfig[emailIndex].showErrorText = true;
                fieldConfig[emailIndex].errorMessage = 'guestBookDetailsEmailError';
                fieldConfig[phoneIndex].validation = [Validators.required];
                fieldConfig[phoneIndex].showErrorText = true;
                fieldConfig[phoneIndex].errorMessage = 'guestBookDetailsMobileError';
                fieldConfig[phoneIndex2].validation = [Validators.required];
                fieldConfig[phoneIndex2].showErrorText = true;
                fieldConfig[phoneIndex2].errorMessage = 'secondaryPhoneError';
                this.mobileRequired = true;
                this.mobile2Required = true;
                contactPreference.pageMethod = PageMethod.Sms;
                break;
            }
            case 12: {
                fieldConfig[emailIndex].validation = emailPattern;
                fieldConfig[emailIndex].showErrorText = true;
                fieldConfig[emailIndex].errorMessage = 'guestBookDetailsEmailError';
                fieldConfig[emailIndex2].validation = emailPattern;
                fieldConfig[emailIndex2].showErrorText = true;
                fieldConfig[emailIndex2].errorMessage = 'secondaryEmailError';
                fieldConfig[phoneIndex].validation = [Validators.required];
                fieldConfig[phoneIndex].showErrorText = true;
                fieldConfig[phoneIndex].errorMessage = 'guestBookDetailsMobileError';
                this.mobileRequired = true;
                this.mobile2Required = false;
                contactPreference.pageMethod = PageMethod.Sms;
                break;
            }
            case 13: {
                fieldConfig[emailIndex].validation = emailPattern;
                fieldConfig[emailIndex].showErrorText = true;
                fieldConfig[emailIndex].errorMessage = 'guestBookDetailsEmailError';
                fieldConfig[emailIndex2].validation = emailPattern;
                fieldConfig[emailIndex2].showErrorText = true;
                fieldConfig[emailIndex2].errorMessage = 'secondaryEmailError';
                fieldConfig[phoneIndex2].validation = [Validators.required];
                fieldConfig[phoneIndex2].showErrorText = true;
                fieldConfig[phoneIndex2].errorMessage = 'secondaryPhoneError';
                this.mobileRequired = false;
                this.mobile2Required = true;
                contactPreference.pageMethod = PageMethod.Email;
                break;
            }
            case 14: {
                fieldConfig[phoneIndex].validation = [Validators.required];
                fieldConfig[phoneIndex].showErrorText = true;
                fieldConfig[phoneIndex].errorMessage = 'guestBookDetailsMobileError';
                fieldConfig[emailIndex2].validation = emailPattern;
                fieldConfig[emailIndex2].showErrorText = true;
                fieldConfig[emailIndex2].errorMessage = 'secondaryEmailError';
                fieldConfig[phoneIndex2].validation = [Validators.required];
                fieldConfig[phoneIndex2].showErrorText = true;
                fieldConfig[phoneIndex2].errorMessage = 'secondaryPhoneError';
                this.mobileRequired = true;
                this.mobile2Required = true;
                contactPreference.pageMethod = PageMethod.Sms2;
                break;
            }
            case 15: {
                fieldConfig[phoneIndex].validation = [Validators.required];
                fieldConfig[phoneIndex].showErrorText = true;
                fieldConfig[phoneIndex].errorMessage = 'guestBookDetailsMobileError';
                fieldConfig[emailIndex2].validation = emailPattern;
                fieldConfig[emailIndex2].showErrorText = true;
                fieldConfig[emailIndex2].errorMessage = 'secondaryEmailError';
                fieldConfig[phoneIndex2].validation = [Validators.required];
                fieldConfig[phoneIndex2].showErrorText = true;
                fieldConfig[phoneIndex2].errorMessage = 'secondaryPhoneError';
                fieldConfig[emailIndex].validation = emailPattern;
                fieldConfig[emailIndex].showErrorText = true;
                fieldConfig[emailIndex].errorMessage = 'guestBookDetailsEmailError';
                this.mobileRequired = true;
                this.mobile2Required = true;
                contactPreference.pageMethod = PageMethod.Sms;
                break;
            }
            case 16: {
                fieldConfig[emailIndex].validation = emailPattern;
                fieldConfig[emailIndex].showErrorText = true;
                fieldConfig[emailIndex].errorMessage = 'guestBookDetailsEmailError';
                fieldConfig[phoneIndex].validation = [Validators.required];
                fieldConfig[phoneIndex].showErrorText = true;
                fieldConfig[phoneIndex].errorMessage = 'guestBookDetailsMobileError';
                fieldConfig[emailIndex2].validation = emailPattern;
                fieldConfig[emailIndex2].showErrorText = true;
                fieldConfig[emailIndex2].errorMessage = 'secondaryEmailError';
                fieldConfig[phoneIndex2].validation = [Validators.required];
                fieldConfig[phoneIndex2].showErrorText = true;
                fieldConfig[phoneIndex2].errorMessage = 'secondaryPhoneError';
                this.mobileRequired = true;
                this.mobile2Required = true;
                contactPreference.pageMethod = PageMethod.Manual;
                break;
            }
        }
        return {
            fieldConfig: fieldConfig,
            contactPreference: contactPreference
        }
    }
    removeValidationForHiddenField(fieldConfig) {
        if (!this.SecondaryGuestFieldVisibility.PrimaryContacts) {
            const phoneIndex = fieldConfig.findIndex(x => x.name.toLowerCase() === 'mobile' || x.name === 'PhoneNumber');
            const emailIndex = fieldConfig.findIndex(x => x.name.toLowerCase() === 'email' || x.name == 'EmailAddress');
            fieldConfig[phoneIndex].validation = [];
            fieldConfig[phoneIndex].showErrorText = false;
            fieldConfig[emailIndex].validation = [];
            fieldConfig[emailIndex].showErrorText = false;
        }
        if (!this.SecondaryGuestFieldVisibility.SecondaryContacts) {
            const phoneIndex2 = fieldConfig.findIndex(x => x.name.toLowerCase() === 'phone' || x.name === 'PhoneNumber2');
            const emailIndex2 = fieldConfig.findIndex(x => x.name === 'secondaryEmail' || x.name === 'EmailAddress2');
            fieldConfig[phoneIndex2].validation = [];
            fieldConfig[phoneIndex2].showErrorText = false;
            fieldConfig[emailIndex2].validation = [];
            fieldConfig[emailIndex2].showErrorText = false;
        }
        return fieldConfig
    }

}