import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { PhoneNumberFormatterPipe } from '@app/shared/pipes/phone-number-formatter.pipe';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { ContactDTO, MemberInfoBase, SettingType, SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { FacadeService } from '@services/facade.service';
import { GuestBookService } from '@services/guestbook.service';
import { PartyService } from '@services/party.service';
import { Subscription } from 'rxjs/Subscription';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EmailSelectorPipe, phoneNumberSelectorPipe } from '../guest-selection/guest-selection.component';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { EngageMemberByCardIdRequestDTO } from '@app/shared/models/EngageMemberByCardIdRequestDTO';
import { EventIntializers } from '@app/shared/constants/globalConstants';
import { CartUtilities } from '@app/common/components/menu/vcart/cart.utilities';
import _ from 'lodash';
import { buttonTypes, ComponentTypes, InvoiceLevel, ValidationMessageType } from '@app/shared/constants/commonenums';
import { BookingTypeHandler } from '@app/shared/utilities/BookingTypeHandler';
import moment from 'moment';
import { PopupService } from '@app/popup-module/popup.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';


@Component({
  selector: 'app-guest-search',
  templateUrl: './guest-search.component.html',
  styleUrls: ['./guest-search.component.scss']
})
export class GuestSearchComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() allowGuestSelect: boolean;
  @Input() bookingData: any;
  @Input() selectedGuestData: any;
  @Input() hasPrimary: boolean;
  searchValue: any;
  guestContacts: any = [];
  componentDetails: ComponentDetails = null;
  isStayConfirmationEnabled = false;
  settingType = SettingType;
  configGuestSearch: FieldConfig[];
  configGuestSearchBy: FieldConfig[];
  @ViewChild('configGuestSearchByForm', { static: true }) configGuestSearchByForm: DynamicFormComponent;
  @ViewChild('configGuestSearchForm', { static: true }) configGuestSearchForm: DynamicFormComponent;
  createEditGuestSubscription;

  roomnumbersearch = false;
  hasRoomNUmberSearch = false;
  hasCGPSearch = false;
  searchType = 1;
  isRoomNumberSearchEnabled: boolean = false;
  isPMSSearchEnabled: boolean = false
  subscription: Subscription = new Subscription();
  _settings: SettingsDTO = {} as SettingsDTO;
  isMembershipAvailable = false;
  partyID: any = null;
  pageNumber: number = 1;
  showMore = false;
  memberShipArr;
  ExternalGuests: any[]
  _merchantSetting: any;
  platformGuestSearchEnable: boolean = false;
  _individualMemberPaymentAllowed = false;
  buttonSearch: ButtonValue;
  autoSearchEnabled: boolean = true;
  hideSearchIconForVcart: boolean = false;

  @Output() guestSelect = new EventEmitter<ContactDTO>();

  constructor(public facadeService: FacadeService, public guestBookService: GuestBookService, private apiService: ApiService, private partyService: PartyService,private ds: DashboardFunctions,
    public cs: CacheService, private ts: TranslateService, private emailSelector: EmailSelectorPipe, private phoneSelector: phoneNumberSelectorPipe, private cu: CartUtilities, private bh: BookingTypeHandler, private ps: PopupService) {
    this.subscription.add(cs.settings.subscribe(sett => {
      this._settings = sett;
      this._merchantSetting = this._settings.MerchantSettings;
      this._individualMemberPaymentAllowed = this._settings.PropertySetting[0].InvoiceLevel === InvoiceLevel.IndividualGuest;
      if(!this._merchantSetting?.AutoSearchEnabled || this._merchantSetting?.AutoSearchEnabled?.SettingValue == SettingType.ENABLE) {
        this.autoSearchEnabled = true;
      }else {
        this.autoSearchEnabled = false;
      }
      this.searchType = this.cs.isIframeEnabled ? 5 : (Number(this._merchantSetting?.DefaultGuestSearchOption?.SettingValue) || 1);
      this.setSearchProperty();
      this.isMembershipAvailable = this._settings.General.UseMembershipNumber;
    }));
  }

  ngOnInit() {
    this.hasRoomNUmberSearch = this._settings.HasRoomNumberSearch;
    this.hasCGPSearch = this._settings.HasCGPSearch;
    let optionval = this.getsupportedoption();
    this.searchType = this.cs.isIframeEnabled ? 5 : this.searchType;
    if( this.searchType == 5 && this.cs.isIframeEnabled){
      this.hideSearchIconForVcart = true;
    }
    if (!optionval.find(option => option.id == this.searchType)) {
      this.searchType = 1;
      this.setSearchProperty()
    }
    this.configGuestSearchBy = [
      {
        type: 'radio',
        name: 'byguestSearch',
        inputType: 'text',
        class: 'reservation-guest__search-field-input',
        options: optionval,
        cellClick: this.setGuestSearch.bind(this),
        value:this.searchType //If Vcart is enabled we set default value is cart guest (5)
      }
    ];
    this.configGuestSearch = [
      {
        type: 'input',
        name: 'guestSearch',
        inputType: 'text',
        label: this.loadSearchLabel(this.searchType),
        class: 'reservation-guest__search-field-input',
        isHidden: this.cs.isIframeEnabled,
        blurClick: this.blurClick.bind(this)
      }
    ];
    this.buttonSearch = {
      type: buttonTypes.actionPrimary,
      label: '',
      customclass: 'reservation-guest__search-guest-btn',
      icon: 'icon-search'
    };
    this.mapExternalResourceGuest();
  }

  setSearchProperty() {
    this.isPMSSearchEnabled = this.platformGuestSearchEnable = (this.searchType == 7) ? true : false;
    this.isStayConfirmationEnabled = this.searchType == 6 ? true : false;
    this.isRoomNumberSearchEnabled = this.searchType == 3? true: false;
  }

  setGuestSearch(eve) {
    this.guestContacts = [];
    this.memberShipArr = [];
    this.searchType = eve.value;
    this.configGuestSearchForm.form.controls.guestSearch.setValue('', { emitEvent: false })
    this.isRoomNumberSearchEnabled = false;
    this.configGuestSearch[0].label = this.loadSearchLabel(this.searchType)
    this.setSearchProperty();
    this.isPMSSearchEnabled = eve.value == 7 ? true : false;
    this.isStayConfirmationEnabled = eve.value == 6 ? true : false;
    if (eve.value == 3)
      this.isRoomNumberSearchEnabled = true;
    if (this.searchType == 5) {
      this.configGuestSearch[0].isHidden = true;
      this.hideSearchIconForVcart = true;
      this.guestContacts = this.ExternalGuests;
    }
    else {
      this.hideSearchIconForVcart = false;
      this.configGuestSearch[0].isHidden = false;
    }
  }

  loadSearchLabel(value) {
    switch (value) {
      case 1:
        return 'selectGuestSearchText'
      case 4:
        return 'searchbyphoneandemail'
      case 3:
        return 'searchByRoomText'
      case 7:
        return 'searchPMSLabel'
      case 6:
        return 'searchStayConfirmationLabel'
    }
  }

  getsupportedoption() {
    const radioarray = [{ id: 1, value: this.ts.instant("reserveText") }];
    if (this._settings.General?.EngageIntegrationDTO?.IsEnabled) {
      radioarray.push({ id: 4, value: this.ts.instant("memberText") })
    }
    this.hasRoomNUmberSearch = this._settings.HasRoomNumberSearch; this.hasCGPSearch = this._settings.HasCGPSearch;
    if (this.hasRoomNUmberSearch || this._settings.IsOhipEnabled) {
      radioarray.push(
        { id: 3, value: this.ts.instant("RoomNo") },
        { id: 6, value: this.ts.instant("stayConfirmation") }
      );
    }

    if (this.hasCGPSearch || this.hasRoomNUmberSearch || this._settings.IsOhipEnabled) {
      radioarray.push({ id: 7, value: this.ts.instant("pmsSearch") })
    }
    if (this.cs.isIframeEnabled) {
      radioarray.push({ id: 5, value: this.ts.instant("cartGuest") });
    }
    
    return radioarray;
  }

  ngAfterViewInit() {
    this.formChange();
  }

  formChange() {
    this.configGuestSearchForm.form?.valueChanges.pipe(debounceTime(600), distinctUntilChanged()).subscribe(value => {
      if (value.guestSearch) {
        this.guestBookService.searchStringForNewGuest = value.guestSearch;
        this.searchValue = value.guestSearch;
        if(this.autoSearchEnabled) {
          this.searchContacts(value.guestSearch, this.isRoomNumberSearchEnabled);
        }
      }
    });
  }
  platFormToggleChange() {
    if (this.searchValue)
      this.searchContacts(this.searchValue, this.isRoomNumberSearchEnabled);
  }

  /* function to search the guest book based on the input text */
  searchContacts(value, isRoomNumberSearchEnabled) {
    let searchValue = value;
    if (this.searchType == 4) {
      this.memberSearchHandler(value);
    } else if (searchValue && (searchValue.length >= 2)) {
      this.subscription.add(this.facadeService.getContactSuggestion(searchValue, isRoomNumberSearchEnabled, 0, 10, this.platformGuestSearchEnable, this.isStayConfirmationEnabled, this.isPMSSearchEnabled).subscribe((data) => {
        if (data) {
          this.guestContacts = [];
          data.forEach(contact => {
            contact.modifiedEmail = this.emailSelector.transform(contact.EmailAddress, contact.EmailAddress2, searchValue, false);
            contact.modifiedPhone = this.phoneSelector.transform(contact.PhoneNumber, contact.PhoneNumber2, contact.CountryPhoneCode, contact.CountryPhoneCode2, searchValue, false);
          });
          this.guestContacts = this.guestContacts.concat(data);
          this.showMore = data && data.length > 10;
        }
      }));
    } else {
      this.guestContacts = [];
    }
  }

  memberSearchHandler(searchText: string) {
    if (searchText && searchText.length) {
      this.apiService.wildSearchEngageMember(searchText).subscribe(response => {
        this.memberShipArr = response.Payload;
      })
    }
  }

  /* function to search the guest book based on the input text */
  searchContactsfromCGP(firstName?, lastName?, phoneNumber?, emailAddress?, membershipCode?, pageNumber?, pageSize?) {
    this.subscription.add(this.facadeService.getContactExSuggestion(firstName, lastName, phoneNumber, emailAddress, membershipCode, pageNumber, pageSize).subscribe((data) => {
      if (data) {
        this.guestContacts = [];
        data.forEach(contact => {
          contact.modifiedEmail = this.emailSelector.transform(contact.EmailAddress, contact.EmailAddress2, null, false);
          contact.modifiedPhone = this.phoneSelector.transform(contact.PhoneNumber, contact.PhoneNumber2, contact.CountryPhoneCode, contact.CountryPhoneCode2, null, false);
        });
        this.guestContacts = this.guestContacts.concat(data);
        this.showMore = data && data.length > 10;
      }
    }));
  }
  showMoreContacts() {
    this.pageNumber += 10;
    let pageSize = 10;
    if (this.searchValue && this.searchValue.length >= 2) {
      this.subscription.add(this.facadeService.getContactSuggestion(this.searchValue, this.isRoomNumberSearchEnabled, this.pageNumber, pageSize).subscribe((data) => {
        if (data) {
          this.guestContacts = [];
          if (data.length == 0 || data.length < 10) {
            this.showMore = false;
          } else {
            this.showMore = true;
          }

          data.forEach(contact => {
            contact.modifiedEmail = this.emailSelector.transform(contact.EmailAddress, contact.EmailAddress2, this.searchValue, false);
            contact.modifiedPhone = this.phoneSelector.transform(contact.PhoneNumber, contact.PhoneNumber2, contact.CountryPhoneCode, contact.CountryPhoneCode2, this.searchValue, false);
          });
          this.guestContacts = this.guestContacts.concat(data);
        }
      }));
    } else {
      this.guestContacts = [];
    }
  }

  getCommonGuestProfile(commonGuestProfile) {
    this.subscription.add(this.facadeService.getCommonProfileContact(commonGuestProfile.primaryGuestId, commonGuestProfile.primaryGuestUuId).subscribe(data => {
      this.setguestDetails(data.Payload);
    }));
  }


  getStayContact(item) {
    this.guestBookService.createGuestHandler(false, null, false, EventIntializers.CREATEGUESTFORRESERVATION, item, EventIntializers.CREATEGUESTFORRESERVATION);
    this.fetchContactDetails(item);
  }

  memberInfo: MemberInfoBase;
  getMemberDetails(contact) {
    if (!this.allowGuestSelect) {
      return;
    }
    let engageIntegrationData = this._settings.General.EngageIntegrationDTO;
    let obj: EngageMemberByCardIdRequestDTO = new EngageMemberByCardIdRequestDTO(contact.CardNo, engageIntegrationData?.TenantId, engageIntegrationData?.SiteId);
    this.memberInfo = new MemberInfoBase(contact.CardNo);
    this.apiService.getEngageMemberByCardId(obj).subscribe((memberResponse) => {
      if (memberResponse.SuccessCode) {
        this.setMemberDetails(memberResponse);
        let createContactReq = this.createContactReq(memberResponse.ProfileInfoGetByCardId.ProfileValue);
        this.guestBookService.createGuestHandler(false, null, false, EventIntializers.CREATEGUESTFORRESERVATION, createContactReq, EventIntializers.CREATEGUESTFORRESERVATION);
        this.fetchContactDetails(contact);
      }
    })
  }



  fetchContactDetails(data) {
    this.createEditGuestSubscription = this.guestBookService.createEditGuestSubscription$.subscribe((response) => {
      let contactId;
      if (response.Payload) {
        contactId = response.Payload.ContactId || response.Payload.Id;
      } else if (response.Change?.ChangeSet?.AdditionalData[0]?.Id) {
        contactId = response.Change?.ChangeSet?.AdditionalData[0]?.Id;
      }
      data.Id = contactId;
      data.SearchType = this.searchType;
      this.createEditGuestSubscription.unsubscribe();
      let payload = this.bh.formValidationDTO(data, this.bookingData.FromDate, this.bookingData.ToDate, this.selectedGuestData?.bookingType.Id)
      this.bh.validateBookingTypeForGuest([payload]);
      if (this.validateInvoice()) {
        let subscriptions = this.bh.proceedValidation.subscribe(info => {
          subscriptions.unsubscribe();
          if (info.state == 'valid') {
            let contactSubscription = this.guestBookService.getContact(contactId).subscribe(({ Payload: contact }) => {
              contactSubscription.unsubscribe();
              // contact.MemberInfo = this.memberInfo;
              contact.SearchType = this.searchType;
              contact.Staystartdate = data.Staystartdate;
              contact.StayEnddate = data.StayEnddate;
              contact.StayConfirmationCode = data.StayConfirmationNumber || null;
              this.setguestDetails(contact);
            })
          }
          else if (info.state == 'invalid') {
            this.ps.previousEvent$.next(true)
          }
        })
      }
      else {
        this.guestBookService.getContact(contactId).subscribe(({ Payload: contact }) => {
          // contact.MemberInfo = this.memberInfo;
          contact.SearchType = this.searchType;
          contact.Staystartdate = data.Staystartdate;
          contact.StayEnddate = data.StayEnddate;
          contact.StayConfirmationCode = data.StayConfirmationNumber || null;
          this.setguestDetails(contact);
        })
      }

    })
  }




  setMemberDetails(memberResponse) {
    if (memberResponse?.ProfileInfoGetByCardId?.ProfileValue) {
      let profileValue = memberResponse.ProfileInfoGetByCardId.ProfileValue;

      this.memberInfo.IsMembershipExpired = profileValue.IsMembershipExpired;
      this.memberInfo.MembershipType = profileValue.MembershipType
      this.memberInfo.PlayerType = profileValue.PlayerType;

      this.memberInfo.RateType = profileValue.RateType;
      this.memberInfo.RateTypeAfterRoundsUsed = profileValue.RateTypeAfterRoundsUsed;
      this.memberInfo.RateTypeMembershipExpiry = profileValue.RateTypeMembershipExpiry;
      this.memberInfo.ConsumedMinutes = 0;
      this.memberInfo.CourseName = "";
      this.memberInfo.Bucket = memberResponse.GolfPoints;
    }

  }

  createContactReq(memberShipdetail) {
    let contact = {
      FirstName: memberShipdetail.FName,
      LastName: memberShipdetail.LName,
      EmailAddress: memberShipdetail?.EmailList[0]?.EmailId,
      PhoneNumber: memberShipdetail?.PhoneList[0]?.PhoneNumber,
      CommonGuestProfileUUID : memberShipdetail?.UUID,
      CountryId: this.partyService.getCountryId(memberShipdetail?.PhoneList[0]?.PhoneCode),
      PIILastUsedDate: null,
      Birthday: memberShipdetail.Dob,
      ContactCustomFields: [],
      Notes: [],
      VisitStats: [],
      MarketingOptedIn: null,
      MemberAllocationTypesIds: [],
      TrackMembershipNumber: memberShipdetail.CardNo,
      RateType: memberShipdetail.RateType,
      MembershipType: memberShipdetail.MembershipType,
      MemberRateTypeAfterRoundsUsed: memberShipdetail.RateTypeAfterRoundsUsed,
      MemberRateTypeMembershipExpiry: memberShipdetail.RateTypeMembershipExpiry,
      IsMemberActive: !memberShipdetail.IsMembershipExpired,
      MemberInformation: this.ds.memberInfoMapper(memberShipdetail)
    }
    return contact;
  }


  getGuestDetails(contactId, fullContact?) {
    if (!this.allowGuestSelect) {
      return;
    }
    if (fullContact?.isFromExternalResource || this.platformGuestSearchEnable || !contactId) {
      if (this.platformGuestSearchEnable && fullContact.CommonGuestProfileUUID || (!contactId && fullContact.CommonGuestProfileUUID)) {
        fullContact.primaryGuestId = '';
        fullContact.primaryGuestUuId = fullContact.CommonGuestProfileUUID;
      }
      else if (this.hasRoomNUmberSearch && !fullContact.isFromExternalResource) {
        fullContact.primaryGuestId = fullContact.GuestprofileServiceId || '';
      }
      this.getCommonGuestProfile(fullContact);
    } else {
      if (this.searchType !== 2 && contactId) {

        // this.guestBookService.stayPayload = [{
        //   StayStartdate: fullContact?.Staystartdate || null,
        //   StayEnddate:fullContact?.StayEnddate || null,
        //   SearchType : this.searchType,
        //   BookingStartDate: moment(this.ps.reservationFormGroup.value.selectedTime.DateTime).format('YYYY-MM-DD'),
        //   BookingEndDate: moment(this.ps.reservationFormGroup.value.selectedTime.DateTime).format('YYYY-MM-DD'),
        //   BookingTypeId: null,
        //   ActivityId: this.ps.selectedSpecialMealId || null,
        //   ValidateStayInfo: true,
        //   ContactId: contactId
        // }];
        fullContact.SearchType = this.searchType;
        if (this.validateInvoice()) {
          let payload = this.bh.formValidationDTO(fullContact, this.bookingData.FromDate, this.bookingData.ToDate, this.selectedGuestData?.bookingType.Id)
          this.bh.validateBookingTypeForGuest([payload]);
          let subscriptions = this.bh.proceedValidation.subscribe(info => {
            subscriptions.unsubscribe()
            if (info.state == 'valid') {
              let contactSubscription = this.facadeService.getBasicContactInformation(contactId, this.partyID).subscribe(data => {
                contactSubscription.unsubscribe();
                this.setguestDetails(data.Payload);
              });
            }
            else if (info.state == 'invalid') {
              this.ps.previousEvent$.next(true)
            }
          })
        }
        else {
          this.subscription.add(this.facadeService.getContact(contactId, this.partyID).subscribe(data => {
            this.setguestDetails(data.Payload);
          }));
        }
      }
      else {
        let modifycontact = fullContact;
        if (fullContact.Id == 0) {
          modifycontact.Id = null;
        }
        this.setguestDetails(modifycontact);
      }
    }
  }




  validateInvoice() {
    return (this._individualMemberPaymentAllowed || (!this._individualMemberPaymentAllowed && (this.selectedGuestData?.primaryGuest || !this.hasPrimary)))
  }

  setguestDetails(data) {
    this.guestSelect.emit(data);
  }

  // Map VCart guest list
  mapExternalResourceGuest() {

    let guests = this.cu.getCartGuests()?.map(x => {
      return {
        primaryGuestId: x.guestId,
        primaryGuestUuId: x.platformGuestUuid,
        FirstName: x.firstName,
        LastName: x.lastName,
        isFromExternalResource: true,
      }
    });
    let cartGuests = [];
    if (guests && guests.length) {
      cartGuests.push(...guests);
    }
    this.ExternalGuests = _.uniqBy(cartGuests, 'primaryGuestUuId');
    this.guestContacts = this.ExternalGuests;
  }

  searchGuest(event) {
    if(this.searchValue && !this.autoSearchEnabled) {
      let roomNoSearch = this.searchType == 3;
      this.searchContacts(this.searchValue, roomNoSearch);
    }else {
      this.guestContacts = [];
      this.memberShipArr = [];
    }
  }

  blurClick(event) {
    if(event?.keyCode == 13 && !this.autoSearchEnabled) {
      this.searchValue = event.target.value;
      let roomNoSearch = this.searchType == 3;
      this.searchContacts(this.searchValue, roomNoSearch);
    }else if(event?.keyCode == 13 && !this.autoSearchEnabled){
      this.guestContacts = [];
      this.memberShipArr = [];
    }
  }


  ngOnDestroy() {
    if (this.subscription) { this.subscription.unsubscribe(); }
    if (this.createEditGuestSubscription) {
      this.createEditGuestSubscription.unsubscribe();
    }
  }
}
