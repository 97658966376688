import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertAction } from 'src/app/common/Models/common.models';
import { Localization } from 'src/app/common/localization/localization';
import { AgInputFieldConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { ButtonType } from '../../../shared/globalsContant';
import { AlertType, GridOperationType } from 'src/app/retail/shared/shared.modal';

@Component({
  selector: 'app-create-tier-level',
  templateUrl: './create-tier-level.component.html',
  styleUrls: ['./create-tier-level.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateTierLevelComponent implements OnInit {

  constructor(
  @Inject(MAT_DIALOG_DATA) public dialogData: any,
  private localization: Localization,
  private fb: UntypedFormBuilder,
  private _utilities: RetailUtilities,
  private dialogRef: MatDialogRef<CreateTierLevelComponent>) { 
    this.header = this.dialogData.header;
    this.captions = this.localization.captions;
    this.dataInput = dialogData;
  }

  header: string;
  captions: any;
  tierLevelForm: UntypedFormGroup;
  @Output() nextEmit = new EventEmitter();
  cancelButton: ButtonValue;
  saveButton: ButtonValue;
  tierCodeConfig: AgInputFieldConfig;
  tierNameConfig: AgInputFieldConfig
  descriptionConfig: AgInputFieldConfig
  discountTypeInput: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  dataInput: any;

  ngOnInit(): void {
    this.formGenerator();
    this.tierLevelForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.saveButton.disabledproperty = !(this.tierLevelForm.dirty && this.tierLevelForm.valid)
    });

  }

  formGenerator(){
    this.tierLevelForm = this.fb.group({
      tierCode: '',
      tierName: '',
      description:'',
      discountType:['',Validators.required]
    })
    this.discountTypeInput = [{id:0,value: "test",viewValue: "Test"}]
    this.saveButton = {
      type: "primary",
      label: this.dialogData.mode == GridOperationType.Edit ? this.captions.btn_update : this.captions.btn_save,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "tertiary",
      label: this.captions.btn_cancel,
    };
    this.tierCodeConfig = {
      className: 'ag_form-control--2',
      form: this.tierLevelForm,
      formControlName: 'tierCode',
      placeHolder: this.captions.tierCode,
      maxlength: 10,
      showRequired: true,
      errorMessage: this.captions.missing_tierCode,
      inputType: 'noprespace,nospecailchar',
      automationId: 'Txt_CreateTier_codeText'
    };
    this.tierNameConfig = {
      className: 'ag_form-control--2',
      form: this.tierLevelForm,
      formControlName: 'tierName',
      placeHolder: this.captions.tierName,
      showRequired: true,
      errorMessage: this.captions.missing_tierName,
      inputType: 'noprespace,nospecailchar',
      automationId: 'Txt_CreateTier_tierName'
    };
    this.descriptionConfig = {
      className: 'ag_form-control--2',
      form: this.tierLevelForm,
      formControlName: 'description',
      placeHolder: this.captions.description,
      showRequired: false,
      inputType: 'noprespace,nospecailchar',
      automationId: 'Txt_CreateTier_description'
    };
    this.patchValues();
  }

  patchValues() {
    if (this.dataInput.mode === GridOperationType.Edit) {
      this.tierLevelForm.patchValue({
        id: this.dataInput.data[0].id,
        tierCode: this.dataInput.data[0].tierCode,
        tierName: this.dataInput.data[0].tierName,
        description: this.dataInput.data[0].description,
        discountType: this.dataInput.data[0].discountType,
      });
    }
   this.tierLevelForm.markAsUntouched();
   this.tierLevelForm.markAsPristine();
  }

  save(e){
    this.dialogRef.close();
  }

  cancel(eve) {
    if (this.tierLevelForm.dirty) {
      this._utilities.showAlert(this.captions.lbl_Cancel_Msg, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res == AlertAction.YES) {
          this.dialogRef.close();
        }
      })
    }
    else {
      this.dialogRef.close();
    }
  }

}
