<div class="retail-CustomDataTable retail-CustomDataTable-theme" id="table_{{SelectedSettingId}}"
  (window:resize)="viewCheckedFlag = false;calcShowMore();">
  <form class="w-100 h-100" [formGroup]="table">
    <div class="page-header" id="pageHeader" (window:resize)="calculateWidth()">
      <mat-form-field *ngIf="options ? options[0].Searchable : false" class="spa-searchbox searchpt custSearchbox"
      [floatLabel]="floatLabelNever" id="searchInput">
        <input attr.automationId='Srch_{{automationId}}_retailCodeSetupSearch' #tableInput matInput autocomplete="off" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
          type="text" placeholder="{{captions.common.SearchBy}} {{PlaceHoldertext}}" value="" (keypress)="currentPage=1"
          (ngModelChange)="searchChange();" [disabled]="isEditModeSet">
        <i aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor"
          (click)="clearText();"></i>
      </mat-form-field>
      <!--Added the following for Retail Pages-->
      <div *ngIf="customHeader" class="custom-retail-inputs">
        <app-retail-table-header (buttonClick)="onButtonClick()" (inputChange)="checkValid($event)"
          (validToggleState)="isValidRoleName = true;" (buttonCancelClick)="onButtonCancelClick($event)"
          (searchInputChange)="searchInputValue($event)" (validSelectState)="isValidRoleName = true;"
          (resetSearchValue)="clearSearchText()" (searchKeyPress)="currentPage=1" [headerName]="pageTitle"
          [setupFormGroup]="formGroupName" [isViewOnly]="IsViewOnly" [maxInputLength]="retailHeaderMaxLength"
          [minInputLength]="retailHeaderMinLength" [setupName]="setupName" [errorText]="errorMessage"
          [enableToggleButton]="enableToggleButton" [active]="captions.setting.Active"
          [isValidRoleName]="isValidRoleName" [headerButtonName]="customHeaderButton"
          [isCancelButtonAvailable]="isCancelAvailable" [searchText]="searchText"
          [cancelButtonName]="captions.retailsetup.CANCEL" [searchPlaceholderValue]="PlaceHoldertext"
          [isEditFlag]="isEditModeSet" [inlineEditFlag]="inlineEditOn" [enableTerminal]="enableTerminal"
          [enableMultiSelect]="enableMultiSelect" [selectedOptionPlacholder]="selectedOptionPlacholder"
          [selectOptions]="selectOptions" [enableSpecialChar]="enableSpecialChar" [headerOptions]="headerOptions"
          [terminalID]="captions.setting.TerminalID" [terminalMissing]="captions.setting.MissingTerminalID"
          [skipValidation]="skipValidationHeaderComp" [showValidationErrPopup]="showErrForValidationHeaderComp"
          [isAddButtonDisable]="isAddButtonDisable" [enableReceiptToggleButton]="enableReceiptToggleButton"
          [receiptToggleCaption]="receiptToggleCaption" [invalidGuidErrMsg]="captions.setting.InvalidGuid"
          [automationId]="automationId">
        </app-retail-table-header>
      </div>
      <!--- end -->
    </div>
    <div class="fixed-table-container" id="fixed-table-container"
      [ngClass]="{'noSort': bodyArray.length < 1, 'custom-table-height': customHeader, 'table-height': !customHeader}">
      
      <label *ngIf="functionalities[ShowNoOfRecordInGrid]" class="ag_w--100 total-count-label text mr-2">
        {{totalMsg}} </label>
  
      <div class="w-100 h-100 grid-table" id="SPACustomTable" [ngClass]="[isScrollAvailable ? '': 'dynamic-scrollbar', functionalities[ShowNoOfRecordInGrid]? 'setHeightWithLabel' : '']">
        <virtual-scroller #scroll
          [items]="(bodyArray | filterPipe: searchText : hdrkeyArray | sortPipe : orderType : selectedDefaultHeader : overriddenSortColumn : sortColumnDataType : groupSort : groupSortType : groupSortJsonKey)"
          style="height: calc(100% - 5px)" (vsEnd)="scrollEndHandler($event)" [ngClass]="{'hideScroll':hideScroll,'scrollReset':freezeScroll}">
          <table aria-describedby="" class="table mb-0 quicksale-setup-table" attr.automationId='Tbl_{{automationId}}_retailTable'>
            <thead #header>
              <tr id="SPAHeaderRow">
                <th scope='col' *ngIf="RadiobuttonOption" class="cursorDefault radio-button">{{RadioButtonColumnName}}
                </th>
                <th scope='col' *ngIf="EditMoreOption && SelectRow" class="pr-3 table-checkbox noellipsis">
                  <div class="th-inner">
                    <mat-checkbox attr.automationId='Chk_{{automationId}}_checkAll'
                      [disabled]="isReadOnly || ((bodyArray | filterPipe: searchText : hdrkeyArray).length <= 0)"
                      (change)="RowSelect($event,bodyArray,'All')" formControlName="IsCheckAll"></mat-checkbox>
                  </div>
                </th>
                <th scope='col' *ngFor="let hdrCnt of hdrArray;let hi=index" id="header{{hi}}"
                  [ngClass]="[hdrCnt.alignType=='right' ? 'text-right' : hdrCnt.alignType=='center' ? 'text-center':'',hdrCnt.sortable != undefined ? (hdrCnt.sortable ? '': 'pointerevents-none') : '']"
                  [class.cursordefault]="hdrCnt.sortable != undefined ? (hdrCnt.sortable ? false: true) : false"
                  [class.cursor]="hdrCnt.sortable != undefined ? (hdrCnt.sortable ? true: false) : true"
                  (click)="sortingFunc(hdrCnt.jsonkey,hi,'Frmtable', hdrCnt.sortcolumn, hdrCnt.sortcolumndatatype,hdrCnt.groupSort,hdrCnt.groupSortType,hdrCnt.groupSortJsonKey)">
                  <div class="th-inner"
                    [ngClass]="((bodyArray | filterPipe: searchText : hdrkeyArray).length != 0 && (selectedDefaultHeader == hdrCnt.jsonkey) && (hdrCnt.sortable != undefined ? hdrCnt.sortable : (selectedDefaultHeader == hdrCnt.jsonkey && bodyArray.length > 0)) ) ? 'font-weight':''">
                    {{hdrCnt.title}}
                    <span class="pl-1"
                      *ngIf="(hdrCnt.jsonkey != '' && hdrCnt.sortable == true) && ((bodyArray | filterPipe: searchText : hdrkeyArray).length != 0)">
                      <i aria-hidden="true" id="sortArrow{{hi}}" attr.automationId='Icn_{{automationId}}_sort{{hi}}'
                        *ngIf="hdrCnt.jsonkey != '' && hdrCnt.sortable != undefined ? hdrCnt.sortable : true && (bodyArray | filterPipe: searchText : hdrkeyArray).length != 0"
                        [ngStyle]="{'opacity':selectedDefaultHeader == hdrCnt.jsonkey && !isEditModeSet ? '1' : '0.3' }"
                        [ngClass]="selectedDefaultHeader == hdrCnt.jsonkey && orderType == 'asc' ? 'icon-filled_up_arrow deg0 IC6': selectedDefaultHeader == hdrCnt.jsonkey && orderType =='desc' ?
                        'icon-filled_up_arrow deg180 IC6': 'icon-sortArrow sort-icon'">
                      </i>
                    </span>
                  </div>
                </th>
                <th scope='col' style="width: 25px;" *ngIf="SelectedSettingId != GridType.retail"></th>
                <th scope='col' *ngIf="!isRoleSetUpReadOnly && EnableActions"
                  [ngClass]="{'column-sticky': sticky, 'minwidth177':DoneCancel, 'minwidth100':!DoneCancel}">
                  <div class="th-inner  ">{{captions.setting.Actions}}</div>
                </th>
              </tr>
              <tr class="dummy-header"></tr>
            </thead>
            <tbody class=" h-100" flex formArrayName="tablebody" #container>
              <tr draggable [dragEnabled]="blnDraggable" [dragData]="bodyCnt" [dragHandle]="'.draggable'"
                class="cust-row" [dropEnabled]="blnDraggable" droppable
                (OnDragOver)="onDragOver($event,bodyCnt,bodyArray)" (onDrop)="onItemDrop($event,bodyCnt,bodyArray)"
                [ngClass]="{'highlight' :((NewerData[0] === bodyCnt) || (SelectedData === bodyCnt) || bodyCnt['isEdit']), 'onEdit':isEdit, 'editRow':editableRow == bodyCnt.id, 'rowDisabled': (enableRowCheck | enablerow : bi : options : editEvent) }"
                id="{{bodyCnt.id}}" *ngFor="let bodyCnt of scroll.viewPortItems;let bi=index;" [formGroupName]="bi">
                <td *ngIf="RadiobuttonOption">
                  <mat-radio-group attr.automationId='Rdo_{{automationId}}_radiobuttonGolf' class="radio-group golf__radio" name="radioButtonGrp">
                    <mat-radio-button [disabled]="bodyCnt.radioDisabled"
                      class="radio-button golf--form-radio-button suppress-z" [checked]="bodyCnt.radioSelected === true"
                      [value]="bodyCnt.radioSelected" (click)="radioClick(bodyCnt,bi)">
                    </mat-radio-button>
                  </mat-radio-group>
                </td>
                <td *ngIf="EditMoreOption && SelectRow " class="pr-3 table-checkbox noellipsis">
                  <mat-checkbox attr.automationId='Chk_{{automationId}}_checkboxRetailTable{{bi}}' class="suppress-z" (change)="RowSelect($event,bodyCnt,'Single')"
                    [checked]="(SelectedData.indexOf(bodyCnt) != -1)" [disabled]="isReadOnly"></mat-checkbox>
                </td>
                <td *ngFor="let hdrCnt of hdrArray;let hi=index"
                  [ngClass]="[hdrCnt.alignType=='right' ? 'text-right ' : 'hello',hdrCnt.alignType=='center' ? 'text-center' : '']"
                  class="table-data">
                  <span class="content d-block textellipsis" id="content"
                    [matTooltipDisabled]="bodyCnt[hdrCnt.jsonkey] ? hdrCnt.jsonkey == 'inactive' || hdrCnt.jsonkey == 'active'|| (hdrCnt.type && hdrCnt.type == 'toggle') || (hdrCnt.jsonkey == 'phoneDetails') || (bodyCnt[hdrCnt.jsonkey].toString()).length < 25 : true"
                    matTooltip="{{hdrCnt.datatype && hdrCnt.datatype == 'money' ? localization.localizeCurrency(bodyCnt[hdrCnt.jsonkey], false):bodyCnt[hdrCnt.jsonkey]}}"
                    matTooltipClass="tooltipClass" [ngClass]="hdrCnt.alignType=='right' ? 'float-right ':''">
                    <div class="toggle__switch"
                      [ngClass]="IsViewOnly || isRoleSetUpReadOnly || hdrCnt.jsonkey == 'isInActive' ? 'button_invalid' : ''"
                      *ngIf="hdrCnt.jsonkey == 'inactive' || hdrCnt.jsonkey == 'active' || (hdrCnt.type && hdrCnt.type == 'toggle')">
                      <ui-switch attr.automationId='Tog_{{automationId}}_toggleSwitch{{hi}}' class="LW14 switch-toggle ui-switch" [(ngModel)]="bodyCnt[hdrCnt.jsonkey]"
                        [ngModelOptions]="{standalone: true}" [disabled]="disableChange(hdrArray,hdrCnt.jsonkey,bodyCnt,bi)"
                        size="small" checkedLabel={{localization.captions.common.Yes}}
                        uncheckedLabel={{localization.captions.common.No}}
                        (change)="showInactiveRoles(bodyCnt[hdrCnt.jsonkey], bodyCnt, bi)" [name]="toggleDisplayText">
                      </ui-switch>

                    </div>
                    <!-- For quick sale items -->
                    <span class='quick-sale-select'
                      *ngIf="hdrCnt.jsonkey == 'category' && SelectedSettingId == GridType.quickSale">
                      <mat-form-field  class="mt-1" [floatLabel]="floatLabelNever" [ngClass]="{hidePlaceHolder: bodyCnt.category != ''}"
                        class="suppress-z">
                        <mat-select attr.automationId='Dd_{{automationId}}_category{{bi}}' (selectionChange)="optionChange($event, SelectedSettingId,bodyCnt,'category')"
                          [(value)]="bodyCnt[hdrCnt.jsonkey]">
                          <mat-option [value]="option.viewValue" *ngFor="let option of dropdownOptions">
                            {{option.viewValue}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </span>
                    <span *ngIf="!(CheckInnerBoolValue | checkinnerbool : bodyCnt[hdrCnt.jsonkey])">
                      <span
                        *ngIf="hdrCnt.datatype && hdrCnt.datatype == 'decimal' && !(IfBooleanCheck | ifboolean : bodyCnt[hdrCnt.jsonkey])">
                        {{bodyCnt[hdrCnt.jsonkey] | loaddecimalvalue}}
                      </span>
                      <span
                        *ngIf="hdrCnt.datatype && hdrCnt.datatype == 'money' && !(IfBooleanCheck | ifboolean : bodyCnt[hdrCnt.jsonkey])">
                        {{bodyCnt[hdrCnt.jsonkey] | Currency}}
                      </span>
                      <span
                        *ngIf="hdrCnt.datatype && hdrCnt.datatype == 'percentage' && !(IfBooleanCheck | ifboolean : bodyCnt[hdrCnt.jsonkey])">
                        {{bodyCnt[hdrCnt.jsonkey] | Currency : false}}%
                      </span>
                      <span *ngIf="hdrCnt.jsonkey == 'taxAmount'">
                        <span *ngIf="bodyCnt['taxAmountType'] == 'perc'">
                          {{bodyCnt[hdrCnt.jsonkey]}}%
                        </span>
                        <span *ngIf="bodyCnt['taxAmountType'] == 'amount'">
                          {{bodyCnt[hdrCnt.jsonkey] | Currency}}
                        </span>
                      </span>
                      <span [ngClass]="IsViewOnly || isEditModeSet ? 'button_invalid' : ''"
                        *ngIf="SelectedSettingId != 'markdown_add' && !hdrCnt.datatype && !(IfBooleanCheck | ifboolean : bodyCnt[hdrCnt.jsonkey]) && hdrCnt.jsonkey != 'printQty' && hdrCnt.jsonkey != 'category' && hdrCnt.jsonkey != 'taxAmount'">
                        <span attr.automationId='Lbl_{{automationId}}_retailTableLabel{{hi}}' [ngClass]="{'anchor-like': hdrCnt.type == 'clickable', 'font-bold': hdrCnt.highlightbold}"
                          (click)="lblclick(bodyCnt, bi , 'clickable', hdrCnt.jsonkey)">
                          {{(SetColumnGridValue | setcolumnvalue : bodyCnt : hdrCnt.jsonkey)}}
                          <span
                            *ngIf="hdrCnt.type == 'showOnHover' && (SetColumnGridValue | setcolumnvalue : bodyCnt : hdrCnt.jsonkey) != '—'"
                            class="retail-table-icon--display">
                            <i attr.automationId='Icn_{{automationId}}_edit{{hi}}' aria-hidden="true" class="icon-edit-new pl-1 cursor"
                              (click)="lblclick(bodyCnt, bi , 'showOnHover', hdrCnt.jsonkey)"></i>
                          </span>
                          <span
                            *ngIf="hdrCnt.type == 'showOnHover' && (SetColumnGridValue | setcolumnvalue : bodyCnt : hdrCnt.jsonkey) == '—'"
                            class="retail-table-icon--display">
                            <i attr.automationId='Lbl_{{automationId}}_add{{hi}}' aria-hidden="true" class="icon-add pl-1 cursor"
                              (click)="lblclick(bodyCnt, bi , 'showOnHover', hdrCnt.jsonkey)"></i>
                          </span>
                        </span>
                      </span>
                      <span
                        *ngIf="SelectedSettingId == 'markdown_add' && hdrCnt.datatype != 'money' &&  hdrCnt.datatype != 'percentage'">
                        <span attr.automationId='Lbl_{{automationId}}_retailTableLabel{{hi}}' [ngClass]="{'anchor-like': hdrCnt.type == 'clickable'}"
                          (click)="lblclick(bodyCnt, bi , hdrCnt.type, hdrCnt.jsonkey)">
                          {{bodyCnt[hdrCnt.jsonkey]}}
                        </span>
                      </span>
                    </span>
                    <!-- For supplier show more and show less -->
                    <span *ngIf="hdrCnt.jsonkey == 'phoneDetails' && SelectedSettingId == GridType.retailSuppliers"
                      class="noellipsis">
                      <!-- Ellipsis will be shown -->
                      <div *ngIf="showLess[bi]" class="float-left left textellipsis">
                        <label *ngFor="let phone of bodyCnt[hdrCnt.jsonkey];let Index=index;">{{phone.PhoneNumber}}<span
                            *ngIf="Index!= (bodyCnt[hdrCnt.jsonkey].length -1)">, </span></label>
                      </div>
                      <!-- Value will be in listed  -->
                      <div *ngIf="!showLess[bi]" class="float-left left pt-1">
                        <label class="pt-1 d-block" *ngFor="let phone of bodyCnt[hdrCnt.jsonkey];let Index=index;">
                          {{phone.PhoneNumber}}
                        </label>
                      </div>
                      <div attr.automationId='Lbl_{{automationId}}_showMore{{hi}}' class="float-right right anchor-like showMoreClass pos-absolute "
                        [ngStyle]="{'visibility':!showItem[bi]  ? 'hidden' : 'visible' }"
                        [ngClass]="showText[bi].includes('Show Less')?'bottom-0 right-0':'right-0'"
                        (click)="showMore(bi)">
                        {{showText[bi]}}
                      </div>
                    </span>
                    <!-- For supplier need to show - if value is null -->
                    <span *ngIf="hdrCnt.jsonkey == 'ap' && SelectedSettingId == GridType.retailSuppliers">
                      {{bodyCnt[hdrCnt.jsonkey] == "" ? '-' :bodyCnt[hdrCnt.jsonkey] }}
                    </span>
                    <span *ngIf="hdrCnt.jsonkey == 'printQty'">
                        <input class="qtyInput" attr.automationId='Txt_{{automationId}}_PrintQuantity{{bi}}' [(ngModel)]="bodyCnt['printQty']"
                        [ngModelOptions]="{standalone: true}" type="text" inputtype="nonnegative,onlynumber,nodecimal" matInput 
                        [maxlength]="3" >
                    </span>
                  </span>
                </td>
                <td style="width: 25px" *ngIf="SelectedSettingId != GridType.retail"></td>
                <td *ngIf="!isRoleSetUpReadOnly && EnableActions && !(SelectedSettingId == GridType.password)"
                  [ngClass]="{'column-sticky': sticky,'minwidth177':DoneCancel}" class="noellipsis">

                  <i attr.automationId='Btn_{{automationId}}_Print{{bi}}' aria-hidden="true" class="icon-print cursor"
                    [ngClass]="(IsViewOnly && IsRetailCodeSetup)||disableEditButton || bodyCnt.isDefault || isEditModeSet ? 'button_invalid' : ''"
                    *ngIf="SelectedSettingId == GridType.discountTypes &&editable && !table.value.tablebody[bi].printQty" title="{{captions.common.Print}}"
                    (click)="editRow($event,bodyCnt,'print',options[0].ServiceId)"></i>
                  <!-- Icon Edit -->
                  <i attr.automationId='Icn_{{automationId}}_editRow{{bi}}' aria-hidden="true" class="icon-Edit cursor"
                    [ngClass]="(IsViewOnly && IsRetailCodeSetup)||disableEditButton  || isEditModeSet ? 'button_invalid' : ''"
                    *ngIf="!table.value.tablebody[bi]?.donecancel" title="{{captions.common.Edit}}"
                    (click)="editRow($event,bodyCnt,'edit',options[0].ServiceId)">
                  </i>
                  <!-- Icon Copy -->
                  <i attr.automationId='Icn_{{automationId}}_copy{{bi}}' aria-hidden="true" class="icon-copy cursor"
                    [ngClass]="(IsViewOnly && IsRetailCodeSetup)|| disableEditButton  || isEditModeSet ? 'button_invalid' : ''"
                    *ngIf="!table.value.tablebody[bi]?.donecancel && showCopyOption && bodyCnt.isDefault && !methodsNotEligibleForCopy.includes(bodyCnt.paymentTypeId)" title="{{captions.common.Copy}}"
                    (click)="editRow($event,bodyCnt,'copy',options[0].ServiceId)">
                  </i>
                  <!-- Icon Delete -->                  
                  <i attr.automationId='Icn_{{automationId}}_delete{{bi}}' aria-hidden="true"
                    *ngIf="(!disableDelete || (!bodyCnt.isDefault || isDeleteEnableForDefault) || (bodyCnt.isDefault && bodyCnt.parentMethodId > 0)) && !table.value.tablebody[bi]?.donecancel"
                    class="icon-Delete cursor" [ngClass]="IsViewOnly || (bodyCnt.isDefault && !isDeleteEnableForDefault) ||  isEditModeSet ? 'button_invalid' : ''"
                    title="{{captions.common.Delete}}" [appDisableDoubleClick]="throttleTime" (throttleClick)="DeleteRecords(bodyCnt)"></i>
                  <i attr.automationId='Icn_{{automationId}}_drag{{bi}}' aria-hidden="true" class="icon-DragAndDrop icon-drag-drop movecursor draggable"
                    [ngClass]="{'button_invalid': IsViewOnly || bodyArray.length == 1 || isEditModeSet}"
                    *ngIf="blnDraggable && !table.value.tablebody[bi]?.donecancel" title="{{captions.common.Drag}}"></i>
                  <span *ngIf="table.value.tablebody[bi]?.donecancel" class="done" (click)="Done(bodyCnt, bi)">
                    <i attr.automationId='Icn_{{automationId}}_done{{bi}}' aria-hidden="true" class="icon-done pr-1"></i>{{captions.common.cDone}}</span>
                  <span *ngIf="table.value.tablebody[bi]?.donecancel" class="cancel" (click)="Cancel(bodyCnt, bi)">
                    <i attr.automationId='Icn_{{automationId}}_cancel{{bi}}' aria-hidden="true" class="icon-Cancel pr-1"></i>{{captions.common.cCancel}}</span>
                </td>
              </tr>
              <tr *ngIf="(bodyArray | filterPipe: searchText : hdrkeyArray).length == 0 && !isDataLoading">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+1"
                  class="text-center">
                  {{captions.common.NoDataFound}} </td>
              </tr>
              <!-- <tr *ngIf="isDataLoading">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+2 : originalHdrKeyArray.length+1"
                  class="text-center">{{captions.common.loadingData}}</td>
              </tr> -->
            </tbody>
          </table>
        </virtual-scroller>
      </div>
    </div>
  </form>
</div>