import * as GlobalConst from '../../../shared/globalsContant';
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { AgDropdownConfig, ButtonValue, DropdownOptions } from 'src/app/common/Models/ag-models';
import { ShopAddItemBusiness } from './shop-add-item.business';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertAction, ButtonType, DialogCloseEnum } from 'src/app/common/enums/shared-enums';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { RetailItemType } from 'src/app/retail/retail.modals';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { cloneDeep } from 'lodash';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { AlertType } from 'src/app/retail/shared/shared.modal';
import { ShopAddItemModal } from '../../shop.modals';
import { HttpMethod, HttpServiceCall } from 'src/app/retail/shared/service/http-call.service';



@Component({
  selector: 'app-shop-add-item',
  templateUrl: './shop-add-item.component.html',
  styleUrls: ['./shop-add-item.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ShopAddItemComponent implements OnInit {
  data: ShopAddItemModal;
  shopaddItemCaptions: any;
  shopCaptions: any;
  addButton: ButtonValue;
  cancelButton: ButtonValue;
  quantityInput: AgDropdownConfig;
  shopInfo: UntypedFormGroup;
  postTypeOptions: Promise<DropdownOptions[]>;
  datesView: any;
  startDate: Date;
  endDate :Date;
  optEndDate: Date;
  placeHolderFormat: string;
  invStatus: any;
  captionsCommon: any;
  dialogCloseEnum = DialogCloseEnum;
  currencySymbol: string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedProductsData = [];

  itemType = RetailItemType;
  prodPrice: number;
  defaultOutlet: number;
  quantityMinValue :number=1;
  floatLabel: string;
  showInventoryStatus: boolean = false;


  constructor(
    public localization: Localization,
    @Inject(MAT_DIALOG_DATA) data: ShopAddItemModal,
    private fb: UntypedFormBuilder,
    private business: ShopAddItemBusiness,
    private utilities: RetailUtilities,
    public propertyInfo: RetailPropertyInformation,
    private propertyInformation: RetailPropertyInformation,
    private dialogRef: MatDialogRef<ShopAddItemComponent>
  ) {
    this.data = data;
    this.showInventoryStatus = this.data.products.useInventory;
    this.shopaddItemCaptions = this.localization.captions.shop.addItems;
    this.shopCaptions = this.localization.captions.shop;
    this.captionsCommon = this.localization.captions.common;
    this.currencySymbol = this.localization.currencySymbol;
    this.defaultOutlet = this.propertyInfo.GetDefaultOutlet();
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.placeHolderFormat = this.localization.inputDateFormat;
    this.setStartDateAndEndDate();
    this.formGenerator();
    this.initializeinputs();
    this.fetchInventoryItems(this.data.products);
    this.shopInfo.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.addButton.disabledproperty = !(this.shopInfo.valid && this.shopInfo.dirty);
      this.addButton = { ...this.addButton };
    });
  }

  initializeinputs() {
    this.addButton = {
      type: 'primary',
      label: this.shopaddItemCaptions.add,
      disabledproperty: this.data?.editFlag ? true : false
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.shopaddItemCaptions.cancel,
      disabledproperty: false
    };
  }

  formGenerator() {
    this.prodPrice = this.data.products.ProductPrice;
    let quantity = this.data.products.Noofitems;
    let rentHours = 0;
    let rentTypeFlag = false;
    if (this.data.products.ItemType === this.itemType.RentalItem) {
      this.prodPrice = this.data.products.perDayRentalRate;
    }
    if (this.data.editFlag) {
      this.prodPrice = this.data.productPrice;
      quantity = this.data.quantity;
      rentHours = this.data.rentHours;
      rentTypeFlag = this.data.rentTypeFlag;
    }
    this.shopInfo = this.fb.group({
      quantitySelect: [''],
      priceSelect: [''],
      commentsSelect :[''],
      items: this.fb.array([
        this.fb.group({
          startDate: [this.startDate, Validators.required],
          endDate: [this.endDate, Validators.required],
          quantity: [quantity, [Validators.required,Validators.min(this.quantityMinValue)]],
          price: [this.prodPrice, Validators.required],
          total: this.prodPrice  * (this.getnumberofnights(this.startDate,this.endDate)),
          hours: rentHours,
          rentType: rentTypeFlag,
          rentTypeLabel: rentTypeFlag ? this.shopCaptions.lbl_hourly : this.shopCaptions.lbl_daily,
          minStartDate: this.startDate,
          maxStartDate: this.optEndDate,
          minEndDate: this.startDate,
          maxEndDate: this.optEndDate,
          comments : this.data ? this.data.comments : ''
        })
      ]),
      typeToggler: 'list',
    });
    this.calculateTotal(this.shopInfo.get('items')['controls'][0]);
    this.shopInfo.controls.commentsSelect.disable();
  }

  private async fetchInventoryItems(product) {
    const invResponse = await this.business.InvokeServiceCallAsync('GetOutletItemWithStatus',
      GlobalConst.Host.retailManagement, HttpMethod.Get, { outletId: this.defaultOutlet }, []);
    const dateDiff = this.localization.getDatesForGivenRange(this.data.stayarrivalDate, this.data.staydepartureDate);
    const stayResponse = await this.business.InvokeServiceCallAsync('GetItemInfoByItemIdAndDateRange',
      GlobalConst.Host.reservation, HttpMethod.Put, { itemId: product.ItemId }, dateDiff.map(f => this.localization.convertDateObjToAPIdate(f.dateObj)));
    this.data.inventory = {
      invResponse: invResponse.result.item.find(x => x.itemId === product.ItemId),
      stayResponse: stayResponse.result
    };
    this.invStatus = this.business.getInventoryData(this.data);
  }

  setStartDateAndEndDate() {
    const currentDate = this.localization.getDate(this.propertyInformation.CurrentDate);
    if (this.data.stayarrivalDate < currentDate) {
      this.startDate = this.getDateObj(currentDate);
    } else {
      this.startDate = this.getDateObj(this.data.stayarrivalDate);
    }
    if (this.data.staydepartureDate < currentDate) {
      this.endDate = this.getDateObj(currentDate);
    } else {
      let inventoryEndDate: Date = cloneDeep(this.data.staydepartureDate);
      if(this.data?.editFlag == undefined || !this.data?.editFlag)
        inventoryEndDate.setDate(inventoryEndDate.getDate() - 1);
      if (this.data.staydepartureDate?.getDate() === this.data.stayarrivalDate?.getDate()) {
        this.endDate = this.getDateObj(this.data.staydepartureDate);
      }
      else if(inventoryEndDate< this.startDate) {
        this.endDate = cloneDeep(this.startDate)
      }
      else {
        this.endDate = this.getDateObj(inventoryEndDate);
      }
      this.optEndDate = this.getDateObj(this.data.staydepartureDate);
    }
  }

  getDateObj(inputDate){
    let newDate = this.utilities.GetDateWithoutTime(cloneDeep(inputDate));
    return this.localization.getDate(newDate);
  }

  toggleAvailabilityType(arg) {
    console.log(arg);
    this.shopInfo.get('typeToggler').setValue(arg);
    this.datesView = arg;
  }
  add(e, i) {
    const formArr = this.shopInfo.get('items') as UntypedFormArray;
    let nextDate: Date = cloneDeep(formArr.value[i].endDate);
    nextDate.setDate(nextDate.getDate() + 1);
    if (nextDate <= this.endDate) {
      const data = this.fb.group({
        startDate: [nextDate, Validators.required],
        endDate: [this.endDate, Validators.required],
        quantity: [1, [Validators.required,Validators.min(this.quantityMinValue)]],
        price: [this.prodPrice, Validators.required],
        total: this.prodPrice * (this.getnumberofnights(nextDate,this.endDate)),
        hours: 0,
        rentType: false,
        rentTypeLabel: this.shopCaptions.lbl_daily,
        minStartDate: nextDate,
        maxStartDate: this.optEndDate,
        minEndDate: nextDate,
        maxEndDate: this.optEndDate,
        comments : ''
      });
      formArr.push(data);
    } else {
      this.utilities.showAlert(this.shopaddItemCaptions.InvalidEndDate, AlertType.Warning, GlobalConst.ButtonType.Ok);
    }
  }

  removeItem(e, i) {
    this.shopInfo.markAsDirty();
    const formArr = this.shopInfo.get('items') as UntypedFormArray;
    formArr.removeAt(i);
  }

  onAddAction(event) {
    this.calculateOverSale();
  }

  calculateOverSale() {
    let itemStatus = this.data?.inventory?.stayResponse;
    let totalQuantity = 0;
    if (this.data.products.ItemType === RetailItemType.RetailItemRetailPOSOnly) {
      totalQuantity = this.data?.inventory?.invResponse?.quantity ?? 0;
    }
    let dates = [];
    const formArr = this.shopInfo.get('items') as UntypedFormArray;
    for (let i = cloneDeep(formArr.value[0].startDate); i <= formArr.value[0].endDate; i.setDate(i.getDate() + 1)) {
      let item = itemStatus.find(x => this.localization.LocalizeDate(x.stayDate) === this.localization.LocalizeDate(i));
      let totalActualCount = formArr.value[0].quantity;
      if (item !== null && item !== undefined) {
        totalActualCount = item.resvStatus + item.inHouseStatus + formArr.value[0].quantity
      }
      if (this.data.products.ItemType === RetailItemType.RentalItem) {
        totalQuantity = (this.data?.inventory?.invResponse?.initialQuantity) ?? 0;
      }
      if (totalActualCount > totalQuantity) {
        dates.push(this.localization.LocalizeDate(i));
      }
    }
    if (dates.length > 0) {
      this.utilities.showAlert(this.shopaddItemCaptions.lbl_oversaleAlertPopUp, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.selectedProductsData = this.shopInfo.get('items').value;
          this.closePopUp(this.dialogCloseEnum.Action);
        }
      });
    }
    else {
      this.selectedProductsData = this.shopInfo.get('items').value;
      this.closePopUp(this.dialogCloseEnum.Action);
    }
  }

  closePopUp(from) {
    this.dialogRef.close({
      from,
      data: this.selectedProductsData
    });
  }

  onCancel(event) {
    this.closePopUp(this.dialogCloseEnum.Cancel);
  }

  showQuantity(e) {
    if (e[0]) {
      this.shopInfo.controls.quantitySelect.enable();
    } else {
      this.shopInfo.controls.quantitySelect.disable();
      this.changeQuantity();
    }
  }

  switchRentType(e, item) {
    item.controls.rentType.setValue(e[0]);
    if (e[0]) {
      item.controls.rentTypeLabel.setValue(this.shopCaptions.lbl_hourly);
      item.controls.price.setValue(this.data.products.perHourRentalRate);
      item.controls.hours.setValue(1);
    } else {
      item.controls.rentTypeLabel.setValue(this.shopCaptions.lbl_daily);
      item.controls.price.setValue(this.data.products.perDayRentalRate);
      item.controls.hours.setValue(0);
    }
    this.calculateTotal(item);
  }

  showPrice(e) {
    if (e[0]) {
      this.shopInfo.controls.priceSelect.enable();
    } else {
      this.shopInfo.controls.priceSelect.disable();
      this.changePrice();
    }
  }
showComments(e){
  if (e[0]) {
    this.shopInfo.controls.commentsSelect.enable();
  } else {
    this.shopInfo.controls.commentsSelect.disable();
    
  }
}
  changeQuantity() {
    const formArr = this.shopInfo.get('items') as UntypedFormArray;
    for (let i = 0; i < formArr.length; i++) {
      formArr.at(i).patchValue({
        quantity: this.shopInfo.get('quantitySelect').value,
        total : formArr.at(i).get('price').value * this.shopInfo.get('quantitySelect').value * (this.getnumberofnights(formArr.at(i).get('startDate').value,formArr.at(i).get('endDate').value))
      });
    }
  }
  changedates() {
    const formArr = this.shopInfo.get('items') as UntypedFormArray;
    for (let i = 0; i < formArr.length; i++) {
      formArr.at(i).patchValue({
        total : formArr.at(i).get('price').value *  formArr.at(i).get('quantity').value * (this.getnumberofnights(formArr.at(i).get('startDate').value,formArr.at(i).get('endDate').value))
      });
    }
  }

  changePrice() {
    const formArr = this.shopInfo.get('items') as UntypedFormArray;
    for (let i = 0; i < formArr.length; i++) {
      formArr.at(i).patchValue({
        price: this.shopInfo.get('priceSelect').value,
        total : this.shopInfo.get('priceSelect').value * formArr.at(i).get('quantity').value * (this.getnumberofnights(formArr.at(i).get('startDate').value,formArr.at(i).get('endDate').value))
      });
    }
  }
  setComments(e) {
    const formArr = this.shopInfo.get('items') as UntypedFormArray;
    for (let i = 0; i < formArr.length; i++) {
      formArr.at(i).patchValue({
        comments: this.shopInfo.get('commentsSelect').value,
       });
    }
  }
  calculateTotal(item) {
    let total = item.value.quantity * item.value.price;
    if (item.value.rentType) {
      total *= item.value.hours;
    }
    else total *= (this.getnumberofnights(item.value.startDate,item.value.endDate));
    item.controls.total.setValue(total);
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  startDateChanged(arg, obj) {
    if (this.isStartDateExceedsEndDate(obj)) {
      obj.controls.endDate.setValue(obj.controls.startDate.value);
    }
    obj.controls.minEndDate.setValue(obj.controls.startDate.value);
    this.changedates();
  }
  endDateChanged(arg, obj) {
    this.changedates();
  }

  isStartDateExceedsEndDate(obj): boolean {
    const startDate = obj.controls.startDate.value;
    const endDate = obj.controls.endDate.value;
    return this.resetTime(startDate) > this.resetTime(endDate);
  }

  resetTime(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }
  getnumberofnights(startDate : Date,endDate :Date) {
   return this.localization.getDateDifference(startDate,endDate) +1;
  }
}
