import { cloneDeep } from 'lodash';
import { Localization } from '../../localization/localization';
import { PropertySetting, PaymentConfiguration } from '../shared/business/view-settings.modals';
import {  ReplaySubject } from 'rxjs';
import { PROPERTY_DATE, USE_RETAIL_INTERFACE, VAT_ENABLED, PROPERTY_INFO } from 'src/app/app-constants';
import { GoogleApiRoutes, PropertyApiRoutes } from '../../common-route';
import _ from 'lodash';
import { RetailConstants } from '../../constants';

export abstract class CommonPropertyInformation {

    private readonly payAgentURI: string = 'PAYAGENTURI';
    protected _currentDate: Date;
    protected _useRetailInterface: boolean;
    protected _VATEnabled: boolean;
    protected _paymentConfiguration: PaymentConfiguration[];
    protected _authorizeConfiguration: any[];
    protected _propertyId: number;
    protected _producId: number;
    public _appointmentConfigurations: any;
    public _appointmentConfigurations$: ReplaySubject<any> = new ReplaySubject();
    public _eatecConfigURI: string;

    constructor(public localization: Localization) {
    }

    public get CurrentDate() {
        this.setDate();
        return cloneDeep(this._currentDate);
    }

    public get UseRetailInterface() {
        return cloneDeep(this._useRetailInterface) || (sessionStorage.getItem("useRetailInterface")?.toLowerCase() === "true");
    }

    public get SkipPMAgent() {
        const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
        return ((propConfig?.SkipPMAgent ?? '').trim().toLowerCase() == 'true');
    }

    public get IsEatecEnabled() {
        return sessionStorage.getItem('isEatecEnabled')?.toLowerCase() === "true";
    }

    public get IsEatecAsMaster() {
        return sessionStorage.getItem('isEatecAsMaster')?.toLowerCase() === "true";
    }
    public get UseGiftCardInterface() {
        let giftCardConfig: string = sessionStorage.getItem('giftCardConfiguration');
        if (giftCardConfig && JSON.parse(giftCardConfig)) {
            const config = JSON.parse(giftCardConfig);
            return config.activateGiftCardInterface;
        }
    }

    public get IsRetailIC() {
        let propConfig = sessionStorage.getItem('propConfig') ? JSON.parse(sessionStorage.getItem('propConfig')) : null;
        return propConfig ? (propConfig.EnableRetailIC?.toLowerCase() == 'true' ? true : false) : false;
    }

    public get isResortFinanceEnabled() {
        return sessionStorage.getItem('enableResortFinance') ? sessionStorage.getItem('enableResortFinance').toLowerCase() === "true" : false;
    }

    public get PropertyId() {
        const propertyId = this.GetPropertyInfoByKey('PropertyId');
        if (propertyId) {
            this._propertyId = Number(propertyId);
        }
        return cloneDeep(this._propertyId);
    }

    public get ProductId() {
        const productId = this.GetPropertyInfoByKey('ProductId');
        if (productId) {
            this._producId = Number(productId);
        }
        return cloneDeep(this._producId);
    }

    public get IsVATEnabled() {
        return sessionStorage.getItem("VATEnabled")?.toLowerCase() === "true";
    }

    public get IsVersaIntegration() {
        let isVersaEnabled = false;
        let confStr = sessionStorage.getItem("propConfig");
        if (confStr) {
            const enablePMSConfigKey = "EnablePMSIntegration";
            const pmsSystem = "PMSSystem";
            let config = JSON.parse(confStr);
            isVersaEnabled = (config[enablePMSConfigKey] ? config[enablePMSConfigKey]?.toLowerCase() == 'true' : false) || (config[pmsSystem] ? config[pmsSystem]?.toUpperCase() == RetailConstants.VERSA : false);
        }

        return isVersaEnabled;
    }

    public get IsResortFinanceRevenue() {
        let isResortFinanceRevenue = false;
        let confStr = sessionStorage.getItem("propConfig");
        if (confStr) {
            const revenueSystem = "RevenueSystem";
            let config = JSON.parse(confStr);
            isResortFinanceRevenue = config[revenueSystem] ? config[revenueSystem]?.toUpperCase() == 'RESORTFINANCE' : false;
        }
        return isResortFinanceRevenue;
    }

    public set UpdateDate(newDate: Date) {
        this._currentDate = this.localization.getDate(newDate);
        this._currentDate.setHours(0, 0, 0, 0);
    }

    public get CurrentDTTM() {
        const now = this.localization.getCurrentDate();
        return new Date(this._currentDate.getFullYear(), this._currentDate.getMonth(), this._currentDate.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
    }

    public get IsAcesMembershipEnabled() {
        let feature = JSON.parse(sessionStorage.getItem('PropertyFeatureConfigurations'));
        let aces  = feature && feature.find(x => x.featureName == FeatureName.ACES_Membership) || {};
        return aces.isActive || false;
    }
    
    public SetPropertyDate(newDate: Date, updatelocalstore: boolean = true) {
        this.UpdateDate = newDate;
        if (updatelocalstore) {
            sessionStorage.setItem(PROPERTY_DATE, cloneDeep(this._currentDate).toString());
           
        }
    }

    public GetPayAgentConfigURIValue(outletId: number = 0): string {
        let payConfigValue = '';
        let paymentConfiguration = this._paymentConfiguration;
        if(!paymentConfiguration || paymentConfiguration.length == 0) {
            paymentConfiguration = JSON.parse(sessionStorage.getItem('paymentConfiguration'));
        }
        if (paymentConfiguration) {
            let payConfig = paymentConfiguration.find(r => r.propertyId == this._propertyId && r.outletId == outletId && r.configKey == this.payAgentURI);
            if (!payConfig) {
                payConfigValue = paymentConfiguration.find(r => r.propertyId == 0 && r.outletId == 0 && r.configKey == this.payAgentURI)?.configValue;
            } else {
                payConfigValue = payConfig.configValue;
            }
        }
        return payConfigValue?.length ? payConfigValue.substring(0, payConfigValue.length - 1) : payConfigValue;
    }

    public IsAuthorizeEnabled() {
        let authorizeConfiguration = this._authorizeConfiguration;
        if(!authorizeConfiguration || authorizeConfiguration == undefined) {
            authorizeConfiguration = JSON.parse(sessionStorage.getItem('authorizeConfiguration'));
        }
        if (authorizeConfiguration) {
            let payConfig = authorizeConfiguration.find(r => r.configurationKey == 'Enabled');
            if (payConfig) {
                return payConfig.configurationValue.toLowerCase() == 'true' ? true : false;
            } else {
                return false;
            }
        }
    }

    public GetAuthorizeIntegrationConfig() {
        let authorizeConfiguration = this._authorizeConfiguration;
        if(!authorizeConfiguration) {
            authorizeConfiguration = JSON.parse(sessionStorage.getItem('authorizeConfiguration'));
        }
        return authorizeConfiguration;
    }
   

    public SetPropertyId(propertyId: number) {
        this._propertyId = propertyId;
    }

    public SetPropertySetting(settings: PropertySetting) {
        if (settings) {
            this._useRetailInterface = settings.activateRetailInterface;
            this._VATEnabled = settings.vatEnabled;
        } else {
            this._useRetailInterface = false;
            this._VATEnabled = false;
        }
        sessionStorage.setItem(USE_RETAIL_INTERFACE, cloneDeep(this._useRetailInterface).toString());
        sessionStorage.setItem(VAT_ENABLED, cloneDeep(this._VATEnabled).toString());
        sessionStorage.setItem('productVersion', settings.productVersion);
        sessionStorage.setItem('userProductVersion', settings.userProductVersion);
        sessionStorage.setItem('newDate',settings.propertyDate);

     
    }

    public SetADMSetting(isAdmEnabled: boolean) {
        sessionStorage.setItem('IsADMEnabled', isAdmEnabled.toString());
    }

    public SetPaymentConfiguration(payConfig: PaymentConfiguration[]) {
        this._paymentConfiguration = [];
        if (payConfig && payConfig.length > 0) {
            this._paymentConfiguration = payConfig;
        }
        sessionStorage.setItem('paymentConfiguration', cloneDeep(JSON.stringify(this._paymentConfiguration)));
   
    }

    public SetSupportedPMAgentVersion(supportedPMAgentVersion) {
        sessionStorage.setItem('supportedPMAgentVersion', _.cloneDeep(JSON.stringify(supportedPMAgentVersion)));
    }

    public SetDefaultOutlet(defaultOutlet: number) {
        if (defaultOutlet > 0) {
            sessionStorage.setItem('defaultOutlet', cloneDeep(defaultOutlet).toString());
         
        }
    }

    public SetDefaultOutletProfitCenterId(defaultOutletProfitCenterId: number) {
        if (defaultOutletProfitCenterId > 0) {
            sessionStorage.setItem('defaultOutletProfitCenterId', cloneDeep(defaultOutletProfitCenterId).toString());
        }
    }

    public GetDefaultOutlet(): number {
        return sessionStorage.getItem('defaultOutlet') ? Number(sessionStorage.getItem('defaultOutlet')) : 0;
    }

    public GetPaymentConfigValueByKey(configKey: string, outletId: number = 0): string {
        let payConfigValue = '';
        let payConfig = this._paymentConfiguration.find(r => r.propertyId == this._propertyId && r.outletId == outletId && r.configKey == configKey);
        if (!payConfig) { // Get default pay configuration
            payConfigValue = this._paymentConfiguration.find(r => r.propertyId == 0 && r.outletId == 0 && r.configKey == configKey).configValue;
        } else {
            payConfigValue = payConfig.configValue;
        }
        return payConfigValue;
    }

    public GetPMAgentVersion(): string {
       return sessionStorage.getItem('supportedPMAgentVersion');
    }


    public SetDefaultDataOnLoad() {
        // Setting propety Date
        this.setDate();

        // Setting Retail-Interface switch value
        const retailSwitch: string = sessionStorage.getItem(USE_RETAIL_INTERFACE);
        if ((retailSwitch && retailSwitch.toLowerCase() == 'true')) {
            this._useRetailInterface = true;
        } else {
            this._useRetailInterface = false;
        }

      
        //VAT Enable
        const VATEnableSwitch: string = sessionStorage.getItem('VATEnabled');
        if (VATEnableSwitch && VATEnableSwitch.toLowerCase() == 'true') {
            this._VATEnabled = true;
        } else {
            this._VATEnabled = false;
        }

        // Set PropertyId
        const propertyId = this.GetPropertyInfoByKey('PropertyId');
        if (propertyId) {
            this._propertyId = Number(propertyId);
        }

      
    }

    public setDate() {
        const propertyData: any = sessionStorage.getItem(PROPERTY_DATE);
        if (propertyData) {
            this._currentDate = this.localization.getDate(propertyData);
        } else {
            this._currentDate = this.localization.getCurrentDate();
        }
        this._currentDate.setHours(0, 0, 0, 0);
    }

    public GetPropertyInfoByKey(name: string) {
        const nameEQ = name + '=';
        const propertyInfo = sessionStorage.getItem(PROPERTY_INFO);
        if (propertyInfo != null) {
            const ca = propertyInfo.split(';');

            for (let info of ca) {
                let c = info.trim();
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    //retail
   

    public IsPaymentConfigExist(): boolean {
        return this._paymentConfiguration.length > 0;
    }

   

    //common
    public AppointmentConfigurations() {
        return cloneDeep(this._appointmentConfigurations);
    }

    public SetAppointmentConfigurations(config: any) {
        this._appointmentConfigurations = config;
        this._appointmentConfigurations$.next(this._appointmentConfigurations);
    }

  
    
     public SetPropertyConfiguration(settings) {
        sessionStorage.setItem('propConfig', JSON.stringify(settings.configValue));
    
    }

    public GetPropertyConfiguration() {
        const config = sessionStorage.getItem('propConfig');
        return config && JSON.parse(config);
    }

    public GetEnablemachineTransaction() {
        return sessionStorage.getItem('enableMachineTransaction') ? sessionStorage.getItem('enableMachineTransaction') : false;
    }

    public GetEnableResortFinance() {
        let enableResortFinance: boolean = false;
        let enableResortFinanceFlag = sessionStorage.getItem('enableResortFinance') === 'true';
        const config = sessionStorage.getItem('propConfig');
    
        if (enableResortFinanceFlag && config) {
            let propConfig = JSON.parse(config);
            if (propConfig != null) {
                // Convert keys to lowercase for case insensitive check
                const keys = Object.keys(propConfig).map(key => key.toLowerCase());
                if (keys.includes('foliopropertyid')) {
                    enableResortFinance = true;
                }
            }
        }
    
        return enableResortFinance.toString();
    }
    

    public GetChangePropertyUrlForNewTab(hostName: string, token: string, propertyId: number, propertyName: string, routeParam: string) {
        return PropertyApiRoutes.changePropertyNewTab.replace('{hostName}', hostName).
                replace('{token}', token).replace('{propertyId}', propertyId.toString()).
                replace('{propertyName}', propertyName).replace('{routeParam}', routeParam);
    }

    public GetChangePropertyUrlForSameTab(token: string, propertyId: number, propertyName: string, routeParam: string) {
        return PropertyApiRoutes.changePropertySameTab;
    }
    
    public GetGoogleMapApiUrl(apiKey: string, language: string) {
        return GoogleApiRoutes.MapApi.replace('{apiKey}', apiKey).replace('{language}', language);
    }

    public SetEatecURI(eatecConfiguration: string) {
        this._eatecConfigURI = null;
        if (eatecConfiguration) {
            this._eatecConfigURI =  eatecConfiguration ;
        }
        sessionStorage.setItem('EIURI', _.cloneDeep(this._eatecConfigURI));
    }
    public GetMPRCurrentProperty(propertyId :number) : any{
        const multipropsession = this.localization.GetMultiPropertySessions<any>();
        let property = null;
        if(multipropsession.length > 0){
            property = multipropsession.find(x=>x.propertyId == propertyId);
        }
        return property;

    }
    public GetMPRCurrentPropertyDate(propertyId :number) : string{
        const property = this.GetMPRCurrentProperty(propertyId);
        return property?.propertyDate;

    }

    public SetWebCommunicationProxyVersionCheck(versionCheck : string){
        sessionStorage.setItem('WebCommunicationProxyVersionCheck', versionCheck);
    }
}
export const FeatureName = {
    ACES_Membership: "ACES Membership"
}