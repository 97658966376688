<div class="cardInfo">
    <div class="d-flex align-center mb-2" *ngIf="retailEnabled">
        <app-retail-capture-card [PaymentReferenceID]="PaymentReferenceID" (displayCardData)="cardInfo($event)" (removeDetails)="removeCard($event)"
            [isShowPMAgentValidationMessage]="false" (NewPaymentReferenceID)="SaveReferenceId($event)" [hideCardDetail]="true">
        </app-retail-capture-card>
    </div>
    <div class="card-view d-flex flex-wrap flex-column">
        <h3 class="seat-header-four">{{'cardDetails' | translate}}</h3>
        <ng-container *ngFor="let card of (guestBookService.creditCardDetails | cardStatusPipe);let i = index"
            class="new-card position-relative">
            <div class="card-details d-flex flex-wrap w-50 position-relative" >
                <div class="cvv">
                    <app-retail-card-type [type]="card?.CardIssuer"></app-retail-card-type>
                </div>
                <div class="card-number w-100 d-flex flex-column">
                    <span class="seat-grid-content seat-alert-messages pt-2 card-number-data">{{card.MaskedCardNumber}}</span>
                </div>
                <div class="cvv-data d-flex  w-100 pt-2 justify-content-between">
                    <div class="d-flex flex-column">
                        <span class="seat-labels">{{'expiryDate' | translate}}</span>
                        <span class="seat-grid-content">{{card.ExpiryDate | formatExpiryDate}}</span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="seat-labels">{{'cardHolder' | translate}}</span>
                        <span class="seat-grid-content">{{card.CardHolder}}</span>
                    </div>
                   
                   
                </div>

                <i [ngClass]="!card.IsDefault ? 'seat-icon-color icon-Card-empty-1' : 'icon-Card-filled seat-green pointer'" class="card-icon pointer" (click)="setDefaultCard(card,i)" matTooltip="{{'setAsDefault' | translate}}"
                   ></i>
                    <!-- <i *ngIf="card.IsDefault" class=" card-icon " (click)="setDefaultCard(card)" matTooltip="{{'setAsDefault' | translate}}"
                    ></i> -->
                <i class="icon-Delete delete-icon seat-icon-color" matTooltip="{{'deleteText' | translate}}"
                    (click)="removeCard(null,i,card)"></i>
            </div>
        </ng-container>
        <ng-container *ngIf="!(guestBookService.creditCardDetails | cardStatusPipe : status).length" class="new-card">
            <span class="card-details w-100">{{'NoGuests' | translate}}</span>
        </ng-container>
    </div>
</div>