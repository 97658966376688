<div class="common-filter">
    <div class="filter-icon-holder">
        <a [ngbPopover]="filterPopover" placement="bottom" [autoClose]="'outside'" class="icon-Filter" [ngClass]="{'indicators': selectedSubMenu?.length > 0 }">
        </a>
        <ng-template #filterPopover>
            <div class="menu-items">
            <div *ngFor="let menu of menuList">
                <div class="filter-name"> {{menu.menuName}}</div>
                <div class="sub-menu" *ngFor="let item of menu.subMenu">
                    <mat-checkbox [(ngModel)]="item.selected"
                        (change)="filterSelection($event , item)">{{item.name}}</mat-checkbox>
                </div>
            </div>
        </div>

        </ng-template>
    </div>
</div>